import { styled } from "@linaria/react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { fromDesktopMl } from "../../styles/breakpointsAndMediaQueries.styles";
import Spacing from "../layout/Spacing";
import OnlyPhonesDiv from "../mediaQueries/OnlyPhones";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import PagesAndActionsIllustration from "./decorations/PagesAndActionsIllustration";
import FourLinkChainIcon from "./decorations/FourLinkChainIcon";
import { NoBreak } from "../typography/Nowrap";

const Inner = styled.div``;

const Header = styled.div`
  text-align: center;
  h2 {
    span {
      display: block;
    }
  }
  p {
    max-width: 32em;
    margin-left: auto;
    margin-right: auto;
    ${fromDesktopMl} {
      max-width: ${widthInGrid(6)};
    }
  }
`;

const WorkflowsFuelCollabSection = () => {
  return (
    <PageSection>
      <Inner>
        <Header>
          <FourLinkChainIcon />
          <Spacing size="lg" />
          <SectionHeading2>
            <span>Workflows fuel </span>
            <Serif>smart collaboration</Serif>
          </SectionHeading2>
          <Spacing size="lg" />
          <p>
            Your team serves colleagues from around the company.{" "}
            <NoBreak>Keep those end users</NoBreak> in the loop with slick,
            on-brand experiences – all powered by our best-in-class automation.
          </p>
        </Header>
        <OnlyPhonesDiv>
          <Spacing size="sectionPadding" />
        </OnlyPhonesDiv>
        <Spacing size="sectionPadding" />
        <PagesAndActionsIllustration />
      </Inner>
    </PageSection>
  );
};

export default WorkflowsFuelCollabSection;
