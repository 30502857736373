import { styled } from "@linaria/react";
import {
  getWidthPxInMaxGrid,
  widthInGrid,
} from "../../../constants/globalGrid.constants";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import {
  breakpoints,
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromDesktopSm,
  fromTablet,
  mediaFrom,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import RigidDotGrid, {
  RigidDotGridLayer,
} from "../../decorations/RigidDotGrid";
import {
  colors,
  getBrandColorTheme,
  withOpacity,
} from "../../../styles/colors.styles";
import { fontSerifDisplayRules, serif } from "../../../styles/fonts.styles";
import { ReportV2LargeChapterNumber1Dots } from "./ReportV2LargeChapterNumbers/dots/ReportV2LargeChapterNumber1Dots";
import { ReportV2LargeChapterNumber2Dots } from "./ReportV2LargeChapterNumbers/dots/ReportV2LargeChapterNumber2Dots";
import { ReportV2LargeChapterNumber3Dots } from "./ReportV2LargeChapterNumbers/dots/ReportV2LargeChapterNumber3Dots";
import { ReportV2LargeChapterNumber4Dots } from "./ReportV2LargeChapterNumbers/dots/ReportV2LargeChapterNumber4Dots";
import { ReportV2LargeChapterNumber5Dots } from "./ReportV2LargeChapterNumbers/dots/ReportV2LargeChapterNumber5Dots";
import { PageThemeControllerPoint } from "../../site/PageThemeController";
import {
  ReportV2FullDarkSectionNavControlPoint,
  ReportV2NavPurpleControlPoint,
} from "./ReportV2CommonNavControlPoints";
import {
  fromReportV2DesktopDesign,
  inReportV2MobileDesign,
} from "./reportsV2.helpers";
import { cx } from "linaria";
import { ReportV2LargeChapterNumber1Default } from "./ReportV2LargeChapterNumbers/default/ReportV2LargeChapterNumber1Default";
import { ReportV2LargeChapterNumber2Default } from "./ReportV2LargeChapterNumbers/default/ReportV2LargeChapterNumber2Default";
import { ReportV2LargeChapterNumber3Default } from "./ReportV2LargeChapterNumbers/default/ReportV2LargeChapterNumber3Default";
import { ReportV2LargeChapterNumber4Default } from "./ReportV2LargeChapterNumbers/default/ReportV2LargeChapterNumber4Default";
import { ReportV2LargeChapterNumber5Default } from "./ReportV2LargeChapterNumbers/default/ReportV2LargeChapterNumber5Default";

const fromWidestWith24Offset = mediaFrom(breakpoints.desktopLg + 24);
const fromWidestWith64Offset = mediaFrom(breakpoints.desktopLg + 64);

const ReportV2HeaderSectionContainer = styled.header`
  position: relative;
  color: ${colors.white};
  z-index: 1;
  --sectionRadius: 0px;
  --offsetLeft: 0px;
  --offsetRight: 0px;
  ${inReportV2MobileDesign} {
    background-color: var(--mobileDesignBackgroundColor);
  }
  ${fromReportV2DesktopDesign} {
    --sectionRadius: 1.2rem;
    --offsetLeft: -50vw;
    --offsetRight: calc(${rSize("widerPageMargin", -0.5, true)} - 4.8rem);
  }
  ${fromDesktopMd} {
    --offsetLeft: calc(
      ${[
        widthInGrid(2, 1, -1, undefined, true),
        rSize("pageMargin", -1, true),
        rSize("widerPageMargin", 0.5, true),
      ].join(" + ")}
    );
    --offsetRight: ${rSize("widerPageMargin", -0.5)};
  }
  ${fromWidestWith24Offset} {
    --offsetLeft: calc(
      ${[
        widthInGrid(2, 1, -1, undefined, true),
        rSize("pageMargin", -1, true),
      ].join(" + ")}
    );
    --offsetRight: ${rSize("widerPageMargin", -1)};
  }
  ${fromWidestWith64Offset} {
    --offsetLeft: calc(
      ${[
        widthInGrid(2, 1, -1, undefined, true),
        rSize("pageMargin", -1, true),
        rSize("widerPageMargin", -0.5, true),
      ].join(" + ")}
    );
    --offsetRight: ${rSize("widerPageMargin", -1.5)};
  }
`;

const BackdropLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: var(--offsetLeft);
  right: var(--offsetRight);
  border-radius: var(--sectionRadius);
  overflow: hidden;
  ${fromReportV2DesktopDesign} {
    background-color: var(--desktopDesignBackgroundColor);
  }
  svg {
    position: absolute;
    ${inReportV2MobileDesign} {
      left: 50vw;
      bottom: 2em;
      transform: translateX(-10%);
      [data-style="default"] & {
        opacity: 0.5;
      }
      [data-style="dots"] & {
        opacity: 0.2;
      }
      width: 320px;
      height: auto;
    }
    ${fromTablet} {
      left: 75vw;
    }
    ${fromReportV2DesktopDesign} {
      left: auto;
      right: 75vw;
      transform: translateX(0);
      bottom: 1em;
    }
    ${fromDesktopSm} {
      right: 80vw;
    }
    ${fromDesktopMd} {
      left: 0;
      transform: translateX(-31.5%);
    }
  }
`;

const Inner = styled.div`
  position: relative;
  border-radius: 0 var(--sectionRadius) var(--sectionRadius) 0;
  margin-top: calc(var(--sectionRadius) * -1);
  margin-right: var(--offsetRight);
  padding-top: 5em;
  padding-bottom: 6em;
  ${inReportV2MobileDesign} {
    padding-left: 1.5em;
    padding-right: 1.5em;
    ${RigidDotGridLayer} {
      display: none;
    }
  }
  ${fromTablet} {
    padding-left: 3em;
  }
  ${fromReportV2DesktopDesign} {
    padding-left: 7.2em;
    padding-right: calc(${rSize("pageMargin")});
    background-color: var(--desktopDesignBackgroundColor);
  }
  ${fromTablet} {
    padding-top: 6em;
    padding-bottom: 9em;
  }
  ${fromDesktopMd} {
    padding-left: ${getWidthPxInMaxGrid(2, true)};
    padding-top: 9em;
    padding-bottom: 12em;
  }
`;

const Content = styled.div`
  position: relative;
  margin-right: ${rSize("widerPageMargin", -1)};
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  h2 {
    font-family: ${serif};
    font-weight: 300;
    font-size: 4.2rem;
    line-height: 100%;
    ${fromTablet} {
      ${fontSerifDisplayRules};
      font-size: 4.8rem;
      font-weight: 250;
      letter-spacing: -0.01em;
      ${fromDesktop} {
        font-size: 6.4rem;
        letter-spacing: -0.02em;
        font-weight: 200;
      }
    }
    ${fromDesktopMd} {
      font-size: 7.2rem;
      font-weight: 1650;
    }
    span {
      ${fromTablet} {
        display: block;
      }
    }
  }
  p {
    max-width: ${getWidthPxInMaxGrid(5, true)};
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

const IconWrap = styled.div`
  ${inReportV2MobileDesign} {
    margin-top: 3em;
    .hasNoChapterNumber & {
      text-align: right;
      padding-right: 1em;
    }
  }
  ${fromReportV2DesktopDesign} {
    margin-top: 2em;
    text-align: right;
    margin-bottom: -2em;
  }
  ${fromDesktopMl} {
    position: absolute;
    bottom: -1em;
    right: 0;
  }
`;

const LargeChapterNumbers = {
  default: [
    ReportV2LargeChapterNumber1Default,
    ReportV2LargeChapterNumber2Default,
    ReportV2LargeChapterNumber3Default,
    ReportV2LargeChapterNumber4Default,
    ReportV2LargeChapterNumber5Default,
  ],
  dots: [
    ReportV2LargeChapterNumber1Dots,
    ReportV2LargeChapterNumber2Dots,
    ReportV2LargeChapterNumber3Dots,
    ReportV2LargeChapterNumber4Dots,
    ReportV2LargeChapterNumber5Dots,
  ],
};

const ReportV2ChapterHeaderSection = (
  props: PropsWithChildren<{
    id?: string;
    chapterNumber?: number;
    largeChapterNumberStyle?: "default" | "dots";
    color: string;
    icon: ReactNode;
  }>
) => {
  const theme = getBrandColorTheme(props.color);
  const LargeChapterNumber = props.chapterNumber
    ? LargeChapterNumbers[props.largeChapterNumberStyle || "default"][
        props.chapterNumber - 1
      ]
    : null;
  const isDarkTheme = theme.name === "dark";
  return (
    <>
      <ReportV2FullDarkSectionNavControlPoint
        color={isDarkTheme ? "purple" : theme.name}
      />

      <ReportV2HeaderSectionContainer
        id={props.id}
        data-style={props.largeChapterNumberStyle ?? "default"}
        className={cx(
          "ReportV2Section",
          "ReportV2ChapterHeaderSection",
          props.chapterNumber ? "hasChapterNumber" : "hasNoChapterNumber"
        )}
        style={
          {
            "--mobileDesignBackgroundColor": theme.c600,
          } as CSSProperties
        }
      >
        <BackdropLayer
          style={
            {
              "--desktopDesignBackgroundColor": theme.c500,
            } as CSSProperties
          }
        >
          {LargeChapterNumber ? <LargeChapterNumber /> : null}
        </BackdropLayer>
        <Inner
          style={
            {
              "--desktopDesignBackgroundColor": theme.c700,
            } as CSSProperties
          }
        >
          <RigidDotGrid
            color={isDarkTheme ? withOpacity(theme.white, 0.1) : theme.c600}
          />
          <Content>
            {props.chapterNumber && (
              <p>
                <strong>Chapter {props.chapterNumber}</strong>
              </p>
            )}
            {props.children}
            <IconWrap>{props.icon}</IconWrap>
          </Content>
        </Inner>
        <PageThemeControllerPoint backgroundColor={theme.c50} />
      </ReportV2HeaderSectionContainer>

      {!isDarkTheme && <ReportV2NavPurpleControlPoint />}
    </>
  );
};

export default ReportV2ChapterHeaderSection;
