import { useState } from "react";
import { DatoCmsPartner } from "../../../graphql-types";
import { StoryDescriptor } from "../../types/helper.types";
import Button from "../forms/Button";
import SpaceChildren from "../layout/SpaceChildren";
import StoryCardWithEmbed from "../library/StoryCardWithEmbed";
import { AlignCenter } from "../typography/Align";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import { PageSectionInner } from "../reusableSections/PageSection";
import Slideshow from "../basic/Slideshow";
import { styled } from "@linaria/react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import {
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";

type Props = {
  id?: string;
  partner: Pick<DatoCmsPartner, "name" | "slug" | "storyLibraryTag">;
  stories: StoryDescriptor[];
};

const PartnerPageFeaturedStoriesSection = styled.section``;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-template-areas: "prev heading next";
  text-align: center;
  align-items: center;
  h2 {
    grid-area: heading;
  }
  .prev {
    grid-area: prev;
  }
  .next {
    grid-area: next;
  }
`;

const NavButton = styled.button`
  appearance: none;
  width: 3.75em;
  height: 3.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${colorsV4.purple100};
  color: ${colorsV4.purple};
  border: 0;
  &:hover {
    filter: brightness(1.05);
  }
  &:active {
    background-color: ${colorsV4.purple300};
  }
  svg {
    display: block;
    ${uptoTablet} {
      width: 18px;
      height: 18px;
    }
    ${fromTablet} {
      width: 22px;
      height: 22px;
    }
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const PartnerPageFeaturedStories = ({ id, partner, stories }: Props) => {
  const storiesToRender = stories.slice(0, 6);
  const utmTags = `utm_campaign=partner-landing-page&utm_source=${partner.slug}`;
  const [prevFn, setPrevFn] = useState(() => () => {});
  const [nextFn, setNextFn] = useState(() => () => {});
  const [index, setIndex] = useState(0);
  return (
    <PartnerPageFeaturedStoriesSection id={id ?? "examples"}>
      <SpaceChildren size="xl">
        <PageSectionInner>
          <Header>
            <SectionHeading2>
              Use case <Serif>examples</Serif>
            </SectionHeading2>
            <NavButton onClick={prevFn} className="prev" disabled={index === 0}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.9393 0.93934L1.93934 11.9393L0.87868 13L1.93934 14.0607L12.9393 25.0607L15.0607 22.9393L6.62132 14.5H26V11.5H6.62132L15.0607 3.06066L12.9393 0.93934Z"
                />
              </svg>
            </NavButton>
            <NavButton
              onClick={nextFn}
              className="next"
              disabled={index === storiesToRender.length - 1}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.0607 25.0607L24.0607 14.0607L25.1213 13L24.0607 11.9393L13.0607 0.93934L10.9393 3.06066L19.3787 11.5L0 11.5V14.5L19.3787 14.5L10.9393 22.9393L13.0607 25.0607Z"
                />
              </svg>
            </NavButton>
          </Header>
        </PageSectionInner>
        <Slideshow
          totalSlides={storiesToRender.length}
          registerIndex={setIndex}
          registerPrevFn={setPrevFn}
          registerNextFn={setNextFn}
          children={scrollTo => (
            <>
              {storiesToRender.map((story, i) => (
                <div
                  key={story.id}
                  onClick={() => scrollTo(i)}
                  className={css`
                    display: flex;
                    align-items: stretch;
                    > * {
                      flex: 1 1 100%;
                    }
                  `}
                >
                  <StoryCardWithEmbed
                    story={story}
                    layout="split"
                    pillText={`Example ${
                      stories.length === 1 ? "use case" : i + 1
                    }`}
                    flexibleEmbedRatio
                    disableFullScreen
                  />
                </div>
              ))}
            </>
          )}
        />
        {stories.length > storiesToRender.length && (
          <AlignCenter>
            <Button
              to={`/library/stories/?s=${
                partner.name || partner.name
              }&${utmTags}`}
            >
              View more examples
            </Button>
          </AlignCenter>
        )}
      </SpaceChildren>
    </PartnerPageFeaturedStoriesSection>
  );
};

export default PartnerPageFeaturedStories;
