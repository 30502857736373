import { styled } from "@linaria/react";
import { YouTubeEmbed } from "../components/blocks/VideoBlock.block";
import { GridCanvas } from "../components/decorations/GridCanvas";
import Spacing from "../components/layout/Spacing";
import SEO from "../components/utilities/SEO";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import { serif } from "../styles/fonts.styles";
import {
  fromTablet,
  fromTabletLg,
  uptoTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { widthInGrid } from "../constants/globalGrid.constants";
import { Separator } from "../components/utilities/Hr";
import BookADemoIcon from "../components/illustrations/spots/BookADemoIcon";
import { useOnMount } from "../utils/lifeCycle.utils";
import { captureAnalyticsEvent } from "../utils/analyticsEvent";
import { widerPageSectionMaxWidthStyle } from "../components/layout/WiderPageSection";

const Wrap = styled.div`
  position: relative;
`;

const Inner = styled.div`
  position: relative;
  ${widerPageSectionMaxWidthStyle()};
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
  ${fromTablet} {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
  }
  display: grid;
  padding-top: ${rSize("xxl")};
  padding-bottom: 4em;
  grid-template-areas: "embed" "details";
  ${fromTabletLg} {
    grid-template-columns: minmax(18em, ${widthInGrid(4)}) minmax(auto, 1fr);
    grid-template-areas: "details embed";
    padding-bottom: 8em;
  }
  grid-gap: ${rSize("gap")};
`;

const Details = styled.div`
  grid-area: details;
  ${fromTabletLg} {
    padding-right: ${rSize("gap")};
  }
  svg {
    ${uptoTabletLg} {
      display: none;
    }
  }
  h1 {
    font-family: ${serif};
    font-weight: 400;
    font-size: 2.8rem;
    margin-top: 0.75em;
    margin-bottom: 0.25em;
    line-height: 1.1;
    ${fromTabletLg} {
      font-weight: 300;
      font-size: 4.8rem;
      letter-spacing: -0.01em;
    }
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
  hr {
    ${uptoTabletLg} {
      display: none;
    }
    ${fromTabletLg} {
      margin-top: ${rSize("lg")};
      margin-bottom: ${rSize("lg")};
      margin-right: ${rSize("gap", -2)};
    }
  }
  p,
  li {
    font-size: 1.4rem;
    ${fromTabletLg} {
      font-size: 1.6rem;
    }
    line-height: 1.3;
  }
  ul {
    padding-left: 1em;
  }
  li {
    + li {
      margin-top: 0.5em;
    }
  }
`;

const EmbedWrap = styled.div`
  grid-area: embed;
  iframe {
    width: 100%;
    aspect-ratio: 16/9.35;
    border-radius: ${rSize("radius")};
    overflow: hidden;
    ${fromTabletLg} {
      min-height: 400px;
    }
  }
`;

const RecordedDemoPage: PageComponent = props => {
  useOnMount(() => {
    captureAnalyticsEvent("visitedRecordedDemoPage");
  });
  return (
    <Page {...props} textColor={colors.purple800} navThemeColor="purple">
      <SEO
        title="Recorded demo"
        // image={ogImage}
        description="Watch a 6-minute product demo to get a quick, technical tour of the Tines platform, a walkthrough of a popular security workflow, and an introduction to templates."
      />
      <Wrap>
        <GridCanvas
          backgroundColor={colors.purple50}
          color={colors.purple100}
          addBorderBottom
        />
        <SiteNavHeightPlaceholder />
        <Inner>
          <Details>
            <BookADemoIcon />
            <h1>Watch a short product demo</h1>
            <Separator />
            <p>
              <strong>What to expect:</strong>
            </p>
            <ul>
              <li>Quick, technical demo of the Tines platform</li>
              <li>Walkthrough of a popular security workflow</li>
              <li>Introduction to pre-built templates</li>
            </ul>
          </Details>
          <EmbedWrap>
            {/* cspell:disable */}
            <YouTubeEmbed id="0kGN3Mjcx8k" />
            {/* cspell:enable */}
          </EmbedWrap>
        </Inner>
      </Wrap>
      <Spacing size="sectionPadding" />
    </Page>
  );
};

export default RecordedDemoPage;
