export const LogoCrowdstrike = () => (
  <svg width="182" height="47" viewBox="0 0 182 47" fill="currentColor">
    <title>Crowdstrike</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8404 29.9763V29.9799L28.8448 29.971C28.8433 29.9728 28.8418 29.9745 28.8404 29.9763ZM28.8404 29.6507V29.9763C27.7728 31.2924 26.3223 32.2244 24.7008 32.6364C23.0519 31.2752 21.3239 30.0176 19.5263 28.8705C19.0166 28.5272 18.4781 28.176 17.9113 27.8065C16.8622 27.1225 15.7161 26.3753 14.4753 25.4968C14.4714 25.4221 14.4617 25.3491 14.4519 25.276C14.4394 25.1813 14.4268 25.0864 14.4268 24.9872C14.4268 20.45 17.963 17.0629 22.6311 17.0629C25.0323 17.04 27.3167 18.1228 28.8536 20.0125V20.3417L26.132 22.7366H25.815C24.9953 21.8113 23.8376 21.2756 22.6179 21.2573C21.6492 21.2422 20.7164 21.6327 20.0355 22.3386C19.3546 23.0444 18.9846 24.0044 19.0112 24.9962C18.9847 25.9857 19.353 26.9435 20.0309 27.6489C20.7089 28.3543 21.6382 28.7466 22.6046 28.7351C23.8251 28.7157 24.9829 28.1783 25.8018 27.2513H26.1188L28.8404 29.6507ZM16.1359 29.9003C16.1342 29.8983 16.1327 29.8963 16.131 29.8943H16.1266L16.1359 29.9003ZM20.5745 32.6725C18.8326 32.2857 17.269 31.309 16.1359 29.9003C16.2576 29.9794 16.3755 30.0586 16.4933 30.1378C16.6747 30.2595 16.8559 30.3814 17.0514 30.5032C18.1612 31.2698 19.3502 31.9644 20.5745 32.6725Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7857 28.6325L45.5001 31.653L45.398 31.9836C44.8377 32.4427 44.1254 32.6873 43.3941 32.6718C41.5385 32.6718 40.5087 32.2235 39.2191 30.2174C39.2011 30.1884 39.1826 30.1584 39.1634 30.1274C38.5243 29.0939 37.2309 27.0023 36.2131 27.0023H35.7817V32.3865H31.0732V17.0625H38.1707C42.2529 17.0625 44.4517 19.0414 44.4517 22.1841C44.4671 24.0347 43.3069 25.7012 41.5385 26.3683C42.035 26.6831 42.4445 27.1123 42.7307 27.6181C42.7444 27.6395 42.7582 27.6611 42.7722 27.683C43.0506 28.1192 43.3784 28.6325 43.8997 28.6325C44.119 28.6408 44.3357 28.5839 44.5213 28.4695L44.7857 28.6325ZM39.6267 22.4269C39.6398 22.8479 39.4672 23.2544 39.1528 23.5431C38.8378 23.8325 38.4106 23.9758 37.9798 23.9366H35.781V20.8256H37.9798C38.4224 20.8033 38.854 20.9649 39.1677 21.2703C39.4811 21.5754 39.6478 21.9954 39.6267 22.4269Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.821 17.0625C58.5828 17.0625 62.1463 20.3986 62.1463 24.8675C62.1463 29.3141 58.5784 32.6725 53.821 32.6725C49.0635 32.6725 45.5 29.3364 45.5 24.8675C45.5 20.3986 49.0591 17.0625 53.821 17.0625ZM55.7064 28.1631C56.8676 27.4765 57.5606 26.2129 57.5137 24.8675C57.5606 23.5221 56.8676 22.2585 55.7064 21.5719C54.5453 20.8854 53.1011 20.8854 51.9399 21.5719C50.7788 22.2585 50.0857 23.5221 50.1327 24.8675C50.0857 26.2129 50.7788 27.4765 51.9399 28.1631C53.1011 28.8496 54.5453 28.8496 55.7064 28.1631Z"
    />
    <path d="M84.1426 17.0625H79.9345L77.3311 25.7107L74.5674 17.0625H71.899L69.1614 25.66L66.5535 17.0625H62.3455L62.1465 17.4219L67.5352 32.6725H70.2036L73.2311 24.1718L76.2844 32.6725H78.9572L84.3416 17.4219L84.1426 17.0625Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.3066 17.0625C98.0549 17.0625 100.975 20.1827 100.975 24.8468L100.988 24.8375C100.988 29.5477 98.0852 32.6725 93.3196 32.6725H86.561V17.0625H93.3066ZM93.1272 28.8288C95.0632 28.8288 96.4709 27.4102 96.4718 24.8675V24.8698V24.8652V24.8675C96.4709 22.3203 95.0632 20.9062 93.1272 20.9062H90.961V28.8288H93.1272Z"
    />
    <path d="M126.143 20.9051H130.951V17.0625H116.524V20.9051H121.333V32.6725H126.143V20.9051Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.956 17.0625C142.922 17.0625 145.059 19.0823 145.059 22.2827L145.076 22.2643C145.076 24.3072 143.963 25.7229 142.314 26.5207L145.378 32.3128L145.171 32.6725H140.601L137.749 27.1894H136.658V32.6725H132.061V17.0625H138.956ZM140.388 22.5299C140.402 22.9592 140.234 23.374 139.929 23.6684C139.623 23.9635 139.207 24.1089 138.787 24.0674H136.657V20.8993H138.787C139.218 20.8751 139.638 21.0392 139.944 21.3506C140.248 21.6615 140.41 22.0902 140.388 22.5299Z"
    />
    <path d="M147.598 17.0625H152.037V32.6725H147.598V17.0625Z" />
    <path d="M163.058 24.052L168.288 17.4219L168.075 17.0625H163.149L158.877 22.6099V17.0625H154.256V32.6725H158.877V27.8715L159.716 26.8626L163.64 32.6725H168.474L168.683 32.3131L163.058 24.052Z" />
    <path d="M181.931 20.9051V17.0625H170.902V32.6725H182V28.8299H175.313V26.669H180.351V23.066H175.313V20.9051H181.931Z" />
    <path d="M108.957 32.6725C112.415 32.6725 115.415 31.0551 115.415 27.9268C115.415 24.5231 112.392 23.6389 109.721 22.9013C108.675 22.5991 107.601 22.2525 107.601 21.5104C107.601 20.9772 108.198 20.6528 109.101 20.6528C110.652 20.6528 111.961 21.5771 112.628 22.1814H112.961L115.202 19.6353V19.3109C114.012 18.0401 111.628 17.0625 109.008 17.0625C105.338 17.0625 102.792 18.9643 102.792 21.7193C102.792 24.6831 105.597 25.9806 107.981 26.6071C109.531 27.0248 110.582 27.0959 110.582 27.9047C110.582 28.4867 109.842 28.8333 108.698 28.8333C107.245 28.8333 105.481 27.9446 104.625 27.1181H104.292L102.098 29.7576V30.0819C103.486 31.5883 106.028 32.6503 108.957 32.6503" />
    <path d="M31.0729 47.1672C29.9741 44.6713 27.7676 41.4685 19.1239 36.8919C15.1425 34.6831 8.33162 31.308 2.21924 24.8672C2.77086 27.1908 5.61353 32.2976 17.8472 38.6766C21.2237 40.5055 26.9535 42.2416 31.0729 47.1672Z" />
    <path d="M29.9634 42.7075C28.9563 39.7453 27.1401 35.9539 18.5023 30.3221C14.2974 27.484 8.12567 23.9054 0 14.8325C0.58102 17.2803 3.15042 23.6527 16.0878 31.9229C20.3401 34.8896 25.8231 36.721 29.9634 42.7075Z" />
  </svg>
);
