import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { WorkbenchPhishingSampleUseCaseSection } from "./WorkbenchPhishingSampleUseCaseSection";
import Spacing from "../layout/Spacing";
import { WorkbenchUseCaseKaleidoscope } from "./WorkbenchUseCaseKaleidoscope";
import { WorkbenchForMSSPSampleUseCaseSection } from "./WorkbenchForMSSPSampleUseCaseSection";
import { serif } from "../../styles/fonts.styles";
import BookADemoButton from "../utilities/BookADemoButton";
import icon from "./illustrations/WorkbenchPageSeeItInActionSectionIcon.svg";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPathsPainter";
import { colors } from "../../styles/colors.styles";
import { FromTabletLg } from "../mediaQueries/UptoTabletLg";
import { widerPageSectionMaxWidth } from "../layout/WiderPageSection";
import FromDesktop from "../mediaQueries/FromDesktop";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { WorkbenchBusinessImpactSection } from "./WorkbenchBusinessImpactSection";

const Wrap = styled.div`
  position: relative;
`;

const Header = styled.header`
  text-align: center;
  position: relative;
  p {
    max-width: 33em;
    margin-left: auto;
    margin-right: auto;
  }
  > * {
    + * {
      margin-top: 1.8rem;
    }
  }
`;

const Footer = styled.footer`
  text-align: center;
  position: relative;
  p {
    font-family: ${serif};
    font-size: 2rem;
    ${fromTablet} {
      font-size: 2.2rem;
    }
    letter-spacing: -0.02rem;
    max-width: 36em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const WorkbenchUseCaseKaleidoscopeWrap = styled.div`
  max-width: ${widerPageSectionMaxWidth}px;
  margin-left: auto;
  margin-right: auto;
`;

const WorkbenchPageSeeItInActionSection = () => {
  return (
    <Wrap>
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-close-left"
        index={3}
        color={colors.light200}
        top={0}
        left="50%"
      />
      <ConnectorPathWaypoint
        id="workbench-page-explained-section-close-right"
        index={3}
        color={colors.light200}
        top={0}
        right="50%"
      />

      <ConnectorPathWaypoint
        id="workbench-page-see-it-in-section-open-left"
        index={0}
        color={colors.light200}
        top={0}
        left="50%"
      />
      <ConnectorPathWaypoint
        id="workbench-page-see-it-in-section-open-right"
        index={0}
        color={colors.light200}
        top={0}
        right="50%"
      />

      <PageSection relative>
        <Header>
          <img src={icon} />
          <SectionHeading2 lighter>See it in action</SectionHeading2>
          <p>
             Tines Workbench is highly flexible and can be adapted to various
            security use cases based on your team’s specific needs.
          </p>
          <FromTabletLg>
            <Spacing size="xl" />
          </FromTabletLg>

          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-open-left"
            index={1}
            color={colors.purple100}
            bottom={0}
            left="50%"
            radius={18}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-open-left"
            index={2}
            color={colors.purple100}
            bottom={0}
            left={72}
            radius={24}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-open-left"
            index={3}
            color={colors.purple100}
            bottom={-32}
            left={72}
          />

          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-open-right"
            index={1}
            color={colors.purple100}
            bottom={0}
            right="50%"
            radius={18}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-open-right"
            index={2}
            color={colors.purple100}
            bottom={0}
            right={72}
            radius={24}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-open-right"
            index={3}
            color={colors.purple100}
            bottom={-32}
            right={72}
          />
        </Header>
        <Spacing size="subsectionMargin" />
        <WorkbenchPhishingSampleUseCaseSection />
      </PageSection>

      <Spacing size="subsectionMargin" />

      <WorkbenchUseCaseKaleidoscopeWrap>
        <WorkbenchUseCaseKaleidoscope />
      </WorkbenchUseCaseKaleidoscopeWrap>

      <Spacing size="subsectionMargin" />

      <PageSection relative>
        <WorkbenchForMSSPSampleUseCaseSection />
      </PageSection>

      <Spacing size="sectionMargin" />

      <WorkbenchBusinessImpactSection />

      <Spacing size="subsectionMargin" />

      <PageSection relative>
        <Footer>
          <p>
            Workbench isn’t just another security copilot. It can help any team
            from security and IT to HR, business operations, product, and
            engineering. Talk to our team to learn more about how you can put
            Tines Workbench into practice.
          </p>
          <Spacing size="lg" />
          <BookADemoButton darker />
          <FromDesktop>
            <Spacing size="subsectionMargin" />
          </FromDesktop>

          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-close-left"
            index={0}
            color={colors.purple100}
            bottom={32}
            left={72}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-close-left"
            index={1}
            color={colors.purple100}
            bottom={0}
            left={72}
            radius={24}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-close-left"
            index={2}
            color={colors.purple100}
            bottom={0}
            left="50%"
            radius={18}
          />

          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-close-right"
            index={0}
            color={colors.purple100}
            bottom={32}
            right={72}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-close-right"
            index={1}
            color={colors.purple100}
            bottom={0}
            right={72}
            radius={24}
          />
          <ConnectorPathWaypoint
            id="workbench-page-see-it-in-section-close-right"
            index={2}
            color={colors.purple100}
            bottom={0}
            right="50%"
            radius={18}
          />
        </Footer>
      </PageSection>
    </Wrap>
  );
};

export default WorkbenchPageSeeItInActionSection;
