import { flashNotice } from "../../../utils/notice.utils";
import { Paths } from "../../../utils/pathBuilders.utils";

const getUrl = () => `${window.location.origin}${Paths.reportSoc2023Access()}`;

const copySOC2023ReportAccessPageUrl = () => {
  try {
    navigator.clipboard.writeText(getUrl());
    flashNotice("✅ Report URL copied.");
  } catch (e) {
    flashNotice("Failed to copy the URL, please try copying manually.");
  }
};
export const shareSOC2023AccessPage = () => {
  const url = getUrl();
  if ("canShare" in window.navigator && window.navigator.canShare({ url })) {
    window.navigator.share({ url });
  } else {
    copySOC2023ReportAccessPageUrl();
  }
};
