export const PaperScrollTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5.5C6 5.22386 6.22386 5 6.5 5H10.5C10.7761 5 11 5.22386 11 5.5C11 5.77614 10.7761 6 10.5 6H6.5C6.22386 6 6 5.77614 6 5.5ZM6 7.5C6 7.22386 6.22386 7 6.5 7H9.5C9.77614 7 10 7.22386 10 7.5C10 7.77614 9.77614 8 9.5 8H6.5C6.22386 8 6 7.77614 6 7.5ZM6.5 9C6.22386 9 6 9.22386 6 9.5C6 9.77614 6.22386 10 6.5 10H10.5C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9H6.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.5C4 4.77614 3.77614 5 3.5 5C3.22386 5 3 4.77614 3 4.5C3 4.22386 3.22386 4 3.5 4C3.77614 4 4 4.22386 4 4.5ZM4 5.91465C3.84361 5.96992 3.67532 6 3.5 6C2.67157 6 2 5.32843 2 4.5C2 3.67157 2.67157 3 3.5 3H4H5H5.5H12C13.1046 3 14 3.89543 14 5V12C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16H12H8.23594H7.73244H7H6C4.89543 16 4 15.1046 4 14V5.91465ZM5 12.2676V4.5C5 4.32468 4.96992 4.15639 4.91465 4H5H5.5H12C12.5523 4 13 4.44772 13 5V12H8.23594H7.73244H7H6C5.63571 12 5.29417 12.0974 5 12.2676ZM5 14C5 14.5523 5.44772 15 6 15C6.55228 15 7 14.5523 7 14C7 13.4477 6.55228 13 6 13C5.44772 13 5 13.4477 5 14ZM7.73244 15H12H13.7324H14C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13H7.73244C7.90261 13.2942 8 13.6357 8 14C8 14.3643 7.90261 14.7058 7.73244 15Z"
    />
  </svg>
);
