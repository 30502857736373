import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  createElement,
} from "react";
import { isDevelopment } from "../../environment";
import {
  isArray,
  isNonArrayObject,
  isString,
} from "../../utils/typeChecks.utils";
import { preventWidows } from "../../utils/typography.utils";

type Props = PropsWithChildren<{
  as?: keyof HTMLElementTagNameMap | "fragment";
  className?: string;
  onClick?: React.MouseEventHandler;
}>;

// if the children contains any of the following commonly used element types, it cannot be wrapped in a <p> tag. In this event, if `props.as` is not provided, it will use <div> as a fallback.
const commonBlockLevelTagsToAppearAsChildren = [
  "div",
  "p",
  "ul",
  "ol",
  "li",
  "h1",
  "h2",
  "h3",
  "h4",
  "details",
  "figure",
  "section",
  "article",
  "address",
  "blockquote",
];
const childrenCanBeWrappedWithParagraphTag = (children: ReactNode) => {
  return (
    !children ||
    isString(children) ||
    isNonArrayObject(children) ||
    (isArray(children) &&
      !children.some(
        c =>
          c instanceof Object &&
          "type" in c &&
          isString((c as ReactElement).type) &&
          commonBlockLevelTagsToAppearAsChildren.includes(
            (c as ReactElement).type as string
          )
      ))
  );
};

const AvoidWidows: React.FC<Props> = props => {
  const children = isString(props.children)
    ? preventWidows(props.children.trim())
    : props.children;
  if (
    isDevelopment &&
    props.as === "fragment" &&
    (props.className || props.onClick)
  ) {
    // eslint-disable-next-line no-console
    console.error(
      "You have used fragment as the element type of <AvoidWidows />. Fragments only supports children and key as props."
    );
  }
  const childrenIsArray = isArray(props.children);
  const shouldUseFragment =
    props.as === "fragment" || (!isString(props.children) && !childrenIsArray);
  const shouldUseP =
    !props.as && childrenCanBeWrappedWithParagraphTag(props.children);
  const shouldUseDiv = !props.as && !shouldUseP;
  switch (true) {
    case shouldUseFragment:
      return <React.Fragment children={children} />;
    case shouldUseP:
      return <p>{children}</p>;
    case shouldUseDiv:
      return <div>{children}</div>;
    default:
      return createElement(props.as ?? "p", {
        ...props,
        children,
      });
  }
};

export default AvoidWidows;
