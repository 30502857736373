import { styled } from "@linaria/react";
import { fromPhoneLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { DemoTextPlaceholder } from "./DemoTextPlaceholder";

const DemoStoryNotificationCardOuterContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  padding: 1.5em 1em 2.5em 2.5em;
  z-index: 2;
`;

const DemoStoryNotificationCardWrap = styled.div`
  background-color: ${withOpacity(colorsV4.canvas600)};
  @supports (backdrop-filter: blur(1em)) {
    background-color: ${withOpacity(colorsV4.canvas600, 0.5)};
    backdrop-filter: blur(1em);
  }
  border: 1px solid ${withOpacity(colorsV4.canvas900, 0.75)};
  box-shadow: 0 0.5em 2em rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  ${fromPhoneLg} {
    font-size: 1.3rem;
  }
  padding: 0.825em;
  border-radius: 1em;
  opacity: 0;
  width: 25em;
  @keyframes DemoStoryPagerDutyNotificationCardEnter {
    from {
      transform: translate(50%, 0);
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  animation: DemoStoryPagerDutyNotificationCardEnter 1s 0.375s
    cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  display: flex;
  align-items: flex-start;
`;

const Content = styled.div`
  line-height: 1.15;
  margin-left: 0.825em;
  h3 {
    font-size: inherit;
  }
  div {
    margin-top: 0.3em;
    display: flex;
    > * {
      display: block;
      + * {
        margin-left: 0.3em;
      }
    }
  }
`;

const IconWrap = styled.div`
  > * {
    width: 3em;
    height: 3em;
  }
`;

export type DemoStoryNotificationConfig = {
  icon: React.FC;
  heading: string;
  body: string;
};

const DemoStoryNotificationCard = (props: {
  notification: DemoStoryNotificationConfig;
}) => {
  return (
    <DemoStoryNotificationCardOuterContainer>
      <DemoStoryNotificationCardWrap>
        <IconWrap>
          <props.notification.icon />
        </IconWrap>
        <Content>
          <h3>{props.notification.heading}</h3>
          <p>{props.notification.body}</p>
          <div>
            <DemoTextPlaceholder width="4em" />
            <DemoTextPlaceholder width="7em" />
            <DemoTextPlaceholder width="5em" />
          </div>
          <div>
            <DemoTextPlaceholder width="6em" />
            <DemoTextPlaceholder width="4em" />
          </div>
        </Content>
      </DemoStoryNotificationCardWrap>
    </DemoStoryNotificationCardOuterContainer>
  );
};

export default DemoStoryNotificationCard;
