export const FedModernizeSecurityApproachIcon = () => (
  <svg width="150" height="150" viewBox="0 0 150 150" fill="none">
    <path
      d="M90.9361 125.597C121.636 125.597 146.523 120.281 146.523 113.724C146.523 107.167 121.636 101.852 90.9361 101.852C60.2367 101.852 35.3496 107.167 35.3496 113.724C35.3496 120.281 60.2367 125.597 90.9361 125.597Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
    />
    <path
      d="M26.5 99.1416C26.5 106.295 48.1577 112.095 74.8733 112.095C101.59 112.095 123.247 106.295 123.247 99.1416L123.122 71.249C123.122 44.6016 101.521 23 74.8733 23C48.2271 23 26.6245 44.6016 26.6245 71.249L26.5 99.1416Z"
      fill="#EADFF8"
      stroke="#8D75E6"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
    />
    <path
      d="M123.247 99.1372C123.247 91.9827 101.59 86.1824 74.8733 86.1824C48.1577 86.1824 26.5 91.9827 26.5 99.1372C26.5 100.854 26.5 109.655 26.5 111.374C26.5 118.528 48.1577 124.327 74.8733 124.327C101.59 124.327 123.247 118.528 123.247 111.374C123.247 109.655 123.247 100.854 123.247 99.1372Z"
      fill="#7F69CE"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
    />
    <path
      d="M74.8733 112.09C101.59 112.09 123.247 106.291 123.247 99.136C123.247 91.9815 101.59 86.1824 74.8733 86.1824C48.1577 86.1824 26.5 91.9815 26.5 99.136C26.5 106.291 48.1577 112.09 74.8733 112.09Z"
      fill="#A990F5"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
    />
    <path
      d="M119.035 94.9965C119.035 89.9111 99.2608 85.7896 74.8694 85.7896C50.478 85.7896 30.7051 89.9111 30.7051 94.9965C30.7051 95.7078 30.7051 98.4271 30.7051 99.1396C30.7051 104.224 50.478 108.346 74.8694 108.346C99.2608 108.346 119.035 104.224 119.035 99.1396C119.035 98.4271 119.035 95.7078 119.035 94.9965Z"
      fill="#25A871"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
    />
    <path
      d="M74.8694 104.203C99.262 104.203 119.035 100.081 119.035 94.9965C119.035 89.9111 99.262 85.7896 74.8694 85.7896C50.478 85.7896 30.7051 89.9111 30.7051 94.9965C30.7051 100.081 50.478 104.203 74.8694 104.203Z"
      fill="#87D1A3"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
    />
    <path
      d="M79.8495 94.5866C91.7254 94.5866 101.351 93.091 101.351 91.2446C101.351 89.3994 91.7254 87.9038 79.8495 87.9038C67.9747 87.9038 58.3486 89.3994 58.3486 91.2446C58.3486 93.091 67.9747 94.5866 79.8495 94.5866Z"
      fill="#4D3E78"
    />
    <path
      d="M57.1465 90.6967C57.1465 92.5898 65.1249 94.1237 74.9664 94.1237C84.808 94.1237 92.7864 92.5898 92.7864 90.6967V79.7307C92.7864 77.8376 84.808 76.3037 74.9664 76.3037C65.1249 76.3037 57.1465 77.8376 57.1465 79.7307V90.6967Z"
      fill="#A990F5"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M68.4565 93.7583V85.0052C68.4565 82.602 70.4047 80.6538 72.808 80.6538C75.2112 80.6538 77.1594 82.602 77.1594 85.0052V93.914C77.1594 93.914 74.7801 94.0733 72.7361 94.0517C70.7208 94.0302 68.4565 93.7583 68.4565 93.7583Z"
      fill="#F486B8"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.187 93.7431V84.99C70.187 83.237 71.224 81.7259 72.7183 81.0374"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.9664 83.3274C84.808 83.3274 92.7864 81.7923 92.7864 79.8992C92.7864 78.0073 84.808 76.4722 74.9664 76.4722C65.1249 76.4722 57.1465 78.0073 57.1465 79.8992C57.1465 81.7923 65.1249 83.3274 74.9664 83.3274Z"
      fill="#C2AAFA"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M89.5252 79.2941C89.5252 80.4221 83.0076 81.3357 74.9681 81.3357C66.9287 81.3357 60.4111 80.4221 60.4111 79.2941V52.8348C60.4111 51.7068 66.9287 50.7932 74.9681 50.7932C83.0076 50.7932 89.5252 51.7068 89.5252 52.8348V79.2941Z"
      fill="#D6EDD9"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M75.7886 81.2841H71.333L75.9598 54.0393H80.4142L75.7886 81.2841Z"
      fill="white"
    />
    <path
      d="M89.5252 79.2941C89.5252 80.4221 83.0076 81.3357 74.9681 81.3357C66.9287 81.3357 60.4111 80.4221 60.4111 79.2941V52.8348C60.4111 51.7068 66.9287 50.7932 74.9681 50.7932C83.0076 50.7932 89.5252 51.7068 89.5252 52.8348V79.2941Z"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M89.5252 72.8987C89.5252 74.0255 83.0076 74.9403 74.9681 74.9403C66.9287 74.9403 60.4111 74.0255 60.4111 72.8987"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M89.5252 65.9761C89.5252 67.104 83.0076 68.0177 74.9681 68.0177C66.9287 68.0177 60.4111 67.104 60.4111 65.9761"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M89.5252 59.0535C89.5252 60.1802 83.0076 61.0951 74.9681 61.0951C66.9287 61.0951 60.4111 60.1802 60.4111 59.0535"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M92.7876 49.0174C92.7876 47.7098 84.808 46.6489 74.9676 46.6489C65.1261 46.6489 57.1465 47.7098 57.1465 49.0174C57.1465 49.5419 57.1465 51.2961 57.1465 51.8206C57.1465 53.1293 65.1261 54.1891 74.9676 54.1891C84.808 54.1891 92.7876 53.1293 92.7876 51.8206C92.7876 51.2961 92.7876 49.5419 92.7876 49.0174Z"
      fill="#A990F5"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M74.9664 51.3847C84.808 51.3847 92.7864 50.325 92.7864 49.0174C92.7864 47.7098 84.808 46.6489 74.9664 46.6489C65.1249 46.6489 57.1465 47.7098 57.1465 49.0174C57.1465 50.325 65.1249 51.3847 74.9664 51.3847Z"
      fill="#D7C4FA"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M80.543 80.8621V53.6184"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M77.9286 49.0734C82.8967 49.0734 86.9248 48.555 86.9248 47.9167C86.9248 47.2785 82.8967 46.7612 77.9286 46.7612C72.9605 46.7612 68.9336 47.2785 68.9336 47.9167C68.9336 48.555 72.9605 49.0734 77.9286 49.0734Z"
      fill="#4D3E78"
    />
    <path
      d="M64.2236 80.4765V53.6184"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M82.0563 47.531C82.0563 48.3824 78.8819 48.659 74.9664 48.659C71.0508 48.659 67.8765 48.3824 67.8765 47.531C67.8765 46.6797 71.0508 43.0754 74.9664 43.0754C78.8819 43.0754 82.0563 46.6797 82.0563 47.531Z"
      fill="#D6EDD9"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M75.6974 42.5C75.6974 42.9047 75.3693 42.9742 74.9657 42.9742C74.561 42.9742 74.2329 42.9035 74.2329 42.5L74.2329 35.8747C74.2329 35.4702 74.561 35.1426 74.9657 35.1426C75.3693 35.1426 75.6974 35.4702 75.6974 35.8747V42.5Z"
      fill="#D6EDD9"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M74.9648 36.775C75.8186 36.775 76.5107 36.0829 76.5107 35.2289C76.5107 34.3752 75.8186 33.6831 74.9648 33.6831C74.1111 33.6831 73.4189 34.3752 73.4189 35.2289C73.4189 36.0829 74.1111 36.775 74.9648 36.775Z"
      fill="#F486B8"
      stroke="#4D3E78"
      strokeWidth="1.09505"
      strokeMiterlimit="10"
    />
    <path
      d="M64.1348 53.9591C64.1348 53.9591 81.0986 54.9015 89.3224 53.188V54.9865C89.3224 54.9865 70.5601 55.6714 64.1348 53.9591Z"
      fill="#4D3E78"
    />
    <path
      d="M68.9053 85.0677L70.1901 84.9959L70.5338 83.3147L68.9053 83.189V85.0677Z"
      fill="#4D3E78"
    />
    <path
      d="M65.5632 96.094C65.5632 97.198 62.0895 98.0925 57.8052 98.0925C53.5196 98.0925 50.0459 97.198 50.0459 96.094C50.0459 94.9911 53.5196 94.0967 57.8052 94.0967C62.0895 94.0967 65.5632 94.9911 65.5632 96.094Z"
      fill="#4D3E78"
    />
    <path
      d="M59.5079 94.1033L57.6124 92.9191V78.6997C57.6555 78.6459 54.0082 80.7737 54.0082 80.7737V92.9191L51.6876 94.35C50.5129 95.1019 49.8076 95.4612 49.8076 95.7653C49.8076 96.5113 52.4946 97.1148 55.8103 97.1148C59.1259 97.1148 61.8117 96.5113 61.8117 95.7653C61.8117 95.4612 60.5796 94.708 59.5079 94.1033Z"
      fill="#1F7A57"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
    />
    <path
      d="M60.8487 69.8619C61.0978 69.238 61.2391 68.5603 61.2391 67.8478C61.2391 64.8507 58.8083 62.4211 55.8124 62.4211C52.8164 62.4211 50.3845 64.8507 50.3845 67.8478C50.3845 68.5603 50.5258 69.238 50.7748 69.8619C48.6291 71.4221 47.2305 73.9523 47.2305 76.8081C47.2305 81.5475 51.073 85.39 55.8124 85.39C60.5517 85.39 64.3942 81.5475 64.3942 76.8081C64.3942 73.9523 62.9957 71.4221 60.8487 69.8619Z"
      fill="#87D1A3"
      stroke="#4D3E78"
      strokeWidth="1.36882"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.9331 85.1721V86.3791C53.9331 86.3791 54.4624 87.5873 55.6706 87.5873C56.8776 87.5873 57.7852 86.9084 57.7852 86.9084L57.6331 85.1721C57.6331 85.1721 54.8396 85.9277 53.9331 85.1721Z"
      fill="#4D3E78"
    />
    <path
      d="M40.5731 52.8328C40.1899 52.8328 39.8032 52.7477 39.4367 52.5693C38.1459 51.9407 37.6083 50.3876 38.2357 49.0968C38.371 48.8178 41.6412 42.1745 48.1623 36.4198C49.24 35.4694 50.8841 35.5726 51.8324 36.6497C52.7832 37.7261 52.6814 39.3702 51.6037 40.3197C45.8956 45.3561 42.9416 51.3132 42.9129 51.3731C42.4614 52.2963 41.5346 52.8328 40.5731 52.8328Z"
      fill="#FCF9F5"
    />
  </svg>
);
