export const DemoPageDecoBottomRight = () => (
  <svg
    width="254"
    height="254"
    viewBox="0 0 254 254"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="127" cy="127" r="126.5" fill="#32274B" stroke="#222027" />
    <mask id="path-2-inside-1_166_11070" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122 247C190.483 247 246 190.812 246 121.5C246 78.3688 224.502 40.3195 191.76 17.729C229.024 39.8616 254 80.5141 254 127C254 197.14 197.14 254 127 254C71.02 254 23.4998 217.781 6.59375 167.499C24.7238 214.055 69.5604 247 122 247Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122 247C190.483 247 246 190.812 246 121.5C246 78.3688 224.502 40.3195 191.76 17.729C229.024 39.8616 254 80.5141 254 127C254 197.14 197.14 254 127 254C71.02 254 23.4998 217.781 6.59375 167.499C24.7238 214.055 69.5604 247 122 247Z"
      fill="#6956A8"
    />
    <path
      d="M191.76 17.729L192.27 16.8692L191.192 18.5521L191.76 17.729ZM6.59375 167.499L7.52559 167.136L5.64589 167.818L6.59375 167.499ZM245 121.5C245 190.271 189.92 246 122 246V248C191.047 248 247 191.353 247 121.5H245ZM191.192 18.5521C223.673 40.962 245 78.7082 245 121.5H247C247 78.0295 225.332 39.677 192.328 16.9059L191.192 18.5521ZM191.249 18.5888C228.223 40.5487 253 80.8814 253 127H255C255 80.1469 229.826 39.1745 192.27 16.8692L191.249 18.5888ZM253 127C253 196.588 196.588 253 127 253V255C197.692 255 255 197.692 255 127H253ZM127 253C71.4624 253 24.3149 217.068 7.54161 167.181L5.64589 167.818C22.6846 218.495 70.5777 255 127 255V253ZM122 246C69.9921 246 25.5132 213.326 7.52559 167.136L5.66191 167.862C23.9343 214.783 69.1287 248 122 248V246Z"
      fill="#222027"
      mask="url(#path-2-inside-1_166_11070)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M239.652 81.7541C230.986 55.4631 213.957 33.0437 191.76 17.729C215.109 31.5972 233.634 52.7368 244.213 78.0265C242.77 79.3573 241.247 80.6025 239.652 81.7541Z"
      fill="#222027"
    />
    <path
      d="M158.637 72.518C158.362 73.4318 157.404 73.9428 156.502 73.6589C155.6 73.3801 155.101 72.4096 155.376 71.4958C155.657 70.582 156.609 70.0709 157.511 70.3549C158.413 70.6388 158.917 71.6042 158.637 72.518Z"
      fill="#F8F4F0"
    />
    <path
      d="M234.762 103.356C234.309 104.843 232.75 105.679 231.283 105.22C229.816 104.76 228.99 103.18 229.444 101.694C229.897 100.207 231.456 99.3704 232.923 99.8299C234.39 100.289 235.216 101.869 234.762 103.356Z"
      fill="#F8F4F0"
    />
    <path
      d="M116.336 71.6643C116.056 72.5781 115.103 73.084 114.201 72.8053C113.3 72.5213 112.8 71.5559 113.076 70.6421C113.356 69.7283 114.308 69.2172 115.21 69.5012C116.112 69.7851 116.616 70.7506 116.336 71.6643Z"
      fill="#F8F4F0"
    />
    <path
      d="M97.9525 226.715C97.6316 227.769 96.526 228.362 95.4817 228.037C94.4424 227.712 93.8568 226.591 94.1778 225.533C94.4987 224.475 95.604 223.886 96.6483 224.211C97.6927 224.537 98.2735 225.657 97.9525 226.715Z"
      fill="#F8F4F0"
    />
    <path
      d="M122.025 58.5934C121.704 59.6466 120.598 60.2402 119.554 59.915C118.515 59.5898 117.929 58.4695 118.25 57.4112C118.571 56.3528 119.676 55.7643 120.721 56.0895C121.765 56.4147 122.346 57.5351 122.025 58.5934Z"
      fill="#F8F4F0"
    />
    <path
      d="M130.107 218.103C129.827 219.017 128.875 219.528 127.973 219.244C127.071 218.966 126.572 217.995 126.847 217.081C127.127 216.167 128.08 215.656 128.982 215.94C129.883 216.224 130.388 217.19 130.107 218.103Z"
      fill="#F8F4F0"
    />
    <path
      d="M143.018 106.141C143.018 107.7 141.77 108.96 140.237 108.96C138.703 108.96 137.455 107.695 137.455 106.141C137.455 104.587 138.703 103.317 140.237 103.317C141.77 103.317 143.018 104.582 143.018 106.141Z"
      fill="#F8F4F0"
    />
    <path
      d="M178.443 213.652C177.989 215.139 176.431 215.975 174.964 215.516C173.496 215.056 172.671 213.477 173.124 211.99C173.578 210.503 175.137 209.667 176.604 210.126C178.071 210.586 178.896 212.165 178.443 213.652Z"
      fill="#F8F4F0"
    />
    <path
      d="M211.264 155.205C210.591 157.414 208.278 158.648 206.098 157.967C203.918 157.285 202.7 154.942 203.373 152.732C204.045 150.522 206.358 149.283 208.538 149.97C210.718 150.651 211.936 152.995 211.264 155.205Z"
      fill="#F8F4F0"
    />
    <path
      d="M200.472 98.7533L205.215 103.591L199.046 106.332L195.974 112.403L191.496 107.308L184.848 106.218L188.246 100.328L187.216 93.5804L193.793 95.0415L199.805 91.9594L200.472 98.7533Z"
      fill="#C2AAFA"
      stroke="#32274B"
      strokeWidth="2.06504"
      strokeLinejoin="round"
    />
    <path
      d="M111.171 132.636L115.693 133.266L113.478 137.313L114.282 141.865L109.801 140.984L105.778 143.163L105.222 138.575L101.931 135.366L106.069 133.416L108.063 129.253L111.171 132.636Z"
      fill="#FDA2CB"
      stroke="#32274B"
      strokeWidth="2.06504"
      strokeLinejoin="round"
    />
    <path
      d="M143.811 168.883C143.576 169.652 142.771 170.08 142.012 169.843C141.253 169.605 140.83 168.79 141.065 168.026C141.299 167.256 142.104 166.828 142.858 167.065C143.617 167.303 144.04 168.119 143.806 168.888L143.811 168.883Z"
      fill="#F8F4F0"
    />
    <path
      d="M158.143 161.667C157.822 162.72 156.716 163.313 155.672 162.988C154.633 162.663 154.047 161.543 154.368 160.484C154.689 159.426 155.794 158.837 156.839 159.163C157.883 159.488 158.464 160.608 158.143 161.667Z"
      fill="#F8F4F0"
    />
    <path
      d="M49.6891 195.668C49.2357 197.155 47.6769 197.992 46.2046 197.532C44.7374 197.073 43.9122 195.493 44.3656 194.006C44.819 192.519 46.3777 191.683 47.8448 192.142C49.312 192.602 50.1375 194.181 49.6841 195.668H49.6891Z"
      fill="#F8F4F0"
    />
    <path
      d="M227.919 133.437C227.639 134.35 226.686 134.862 225.784 134.578C224.883 134.299 224.383 133.328 224.659 132.414C224.939 131.501 225.891 130.99 226.793 131.274C227.695 131.558 228.199 132.523 227.919 133.437Z"
      fill="#F8F4F0"
    />
    <path
      d="M195.383 67.7334C194.93 69.2203 193.371 70.0566 191.904 69.5971C190.437 69.1377 189.611 67.5579 190.065 66.071C190.518 64.5842 192.077 63.7479 193.544 64.2073C195.011 64.6668 195.837 66.2466 195.383 67.7334Z"
      fill="#F8F4F0"
    />
  </svg>
);
