import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import { Paths } from "../../utils/pathBuilders.utils";
import ContentCardGeneric from "../contentCards/ContentCardGeneric";
import { DatoCmsContentCard } from "../../../graphql-types";
import casesIcon from "./icons/casesIcon.svg";
import appsIcon from "./icons/appsIcon.svg";
import giftIcon from "./icons/giftIcon.svg";
import treeOfStarsIcon from "./icons/treeOfStarsIcon.svg";
import { colors, withOpacity } from "../../styles/colors.styles";
import Spacing from "../layout/Spacing";
import {
  fromDesktopSm,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";

const BackdropLayer = styled.div`
  position: absolute;
  top: 15rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${withOpacity(colors.purple200, 0.5)};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.6rem;
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopSm} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  min-height: 36rem;
  position: relative;
  img {
    ${onlyPhones} {
      margin-top: 1em;
      max-width: 64px;
      height: auto;
    }
  }
`;

export const ProductPageContentCardsSection = () => {
  return (
    <PageSection relative before={<BackdropLayer />}>
      <Grid>
        <ContentCardGeneric
          overrides={
            {
              heading: "Automation-first case management",
              description:
                "Automation for the threats you know, cases for the ones you don’t.",
              linkUrl: Paths.cases(),
              color: "purple",
              linkLabel: "Explore cases",
              colorMode: "500",
              icon: {
                url: casesIcon,
              },
            } as DatoCmsContentCard
          }
        />
        <ContentCardGeneric
          overrides={
            {
              heading: "Foster a culture of automation",
              description:
                "Build apps with Tines to collaborate faster and more effectively while securing your business.",
              linkUrl: Paths.buildApps(),
              color: "orange",
              linkLabel: "Apps in Tines",
              icon: {
                url: appsIcon,
              },
            } as DatoCmsContentCard
          }
        />
        <ContentCardGeneric
          overrides={
            {
              heading: "Differentiated by our speed",
              description:
                "Read about all the latest Tines features and updates.",
              linkUrl: Paths.whatsNew(),
              color: "pink",
              linkLabel: "See what’s new",
              icon: {
                url: giftIcon,
              },
            } as DatoCmsContentCard
          }
        />
        <ContentCardGeneric
          overrides={
            {
              heading: "Power users, powerful stories",
              description:
                "Discover how companies – from Fortune 10 to startups – apply Tines to transform the way their teams operate.",
              linkUrl: Paths.caseStudies(),
              color: "green",
              linkLabel: "Read case studies",
              icon: {
                url: treeOfStarsIcon,
              },
            } as DatoCmsContentCard
          }
        />
      </Grid>
      <Spacing size="sectionPadding" />
    </PageSection>
  );
};
