import { styled } from "@linaria/react";
import { FormEvent, useState } from "react";
import {
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { scrollToHash } from "../../../utils/anchorLinkScroll.utils";
import { emailIsLegitimate } from "../../../utils/checkEmailLegitimacy.utils";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { getCookie } from "../../../utils/cookies.utils";
import { reportErrorSilently } from "../../../utils/error.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { resolveAfter, runAfter } from "../../../utils/promises.utils";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../../utils/urlQueryParams.utils";
import { zendeskAttachmentUploadHandler } from "../../../utils/zendesk.utils";
import FileUploader, { UploadedFileMeta } from "../../basic/FileUploader";
import Button from "../../forms/Button";
import Textarea, { TextareaStyled } from "../../forms/Textarea";
import TextInput, { TextInputStyled } from "../../forms/TextInput";
import SpotIllustration from "../../illustrations/SpotIllustration";
import GlobalSearch, {
  GlobalSearchResultsContainer,
} from "../../search/GlobalSearch";
import PrivacyNotice from "../../utilities/PrivacyNotice";
import { getFormMetaInfo } from "../../../utils/form.utils";

const FormWrap = styled.div`
  ${TextInputStyled}, ${TextareaStyled} {
    display: block;
    border-radius: 2px;
    border-color: transparent;
    background-color: ${withOpacity(colors.white, 0.9)};
    ${darkModeLinariaCSS(
      `
        background-color: ${withOpacity(colors.dark900, 0.8)};
        border-color: transparent;
      `
    )}
    &:focus {
      border-color: ${colors.purple};
    }
    &[type="search"] {
      appearance: none;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
  }
  form {
    > * {
      + * {
        margin-top: 2px;
      }
    }
  }
  ${GlobalSearchResultsContainer} {
    border-radius: 2px;
    background-color: ${withOpacity(colors.white, 0.7)};
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    border-top: 2px solid ${withOpacity(colors.gray, 0.15)};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark900, 0.6)};`
    )};
    &:empty {
      padding: 0;
      border-top: 0;
    }
  }
`;

const FileUploaderSection = styled.div`
  text-align: left;
  border-radius: 2px;
  padding: 1.25em;
  background-color: ${withOpacity(colors.white, 0.9)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.dark900, 0.8)};`
  )}
  > * + * {
    margin-top: 0.75em;
  }
`;

const SuccessMessageContainer = styled.div`
  background-color: ${colors.purple100};
  border: 1px solid ${colors.purple200};
  padding: 1em 1.5em 2.5em 1.5em;
  border-radius: ${rSize("radius")};
  text-align: center;
  ${darkModeLinariaCSS(`background-color: ${colors.dark900};`)}
  h2 {
    font-size: 2rem;
  }
`;

const SubmitRow = styled.footer`
  border-radius: 2px 2px 0.5em 0.5em;
  background-color: ${withOpacity(colors.white, 0.9)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.dark900, 0.8)};`
  )}
  padding: 1.25em;
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 18em);
    grid-gap: ${rSize("gap")};
    align-items: center;
    > * {
      margin: 0;
    }
    p {
      text-align: right;
    }
  }
`;

const SuccessMessage = () => (
  <SuccessMessageContainer>
    <SpotIllustration name="paperPlane" size="150" />
    <h2>Thanks for your query, we’ll be in touch.</h2>
  </SuccessMessageContainer>
);

export const ContactSupportGeneralQueryForm = () => {
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const searchFormState = useState(() => ({
    query: "",
  }));
  const formState = useState(() => ({
    email: "",
    body: "",
    attachmentId: "",
  }));
  useOnMount(() => {
    const { email, subject, body } = getUrlQueryParams();
    const autoEmail = email || getCookie("email_address");
    if (autoEmail || body) {
      const newState = { ...formState[0] };
      if (autoEmail) newState.email = autoEmail;
      if (body) newState.body = body;
      formState[1](newState);
    }
    if (subject) {
      searchFormState[1]({
        query: subject,
      });
      runAfter(() => {
        removeUrlQueryParam("subject", "replaceState");
        setUrlQueryParam("s", subject, "replaceState");
      });
    }
  });
  const handleFileUploaded = (details: UploadedFileMeta) => {
    formState[1]({
      ...formState[0],
      attachmentId: details.id,
    });
  };
  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setAwaitingResponse(true);
    const [form] = formState;
    if (!(await emailIsLegitimate(form.email)).valid) {
      window.alert("Please enter a valid email address.");
      setAwaitingResponse(false);
      return;
    }
    const url =
      "https://hq.tines.io/webhook/597537d8a60765249d5b3514bfef47cc/dad0d76ba8fb6e82f6cf73c0984acdff";
    const payload = {
      form: {
        subject: searchFormState[0].query,
        email: formState[0].email,
        body: formState[0].body,
        attachmentId: formState[0].attachmentId,
      },
      ...getFormMetaInfo(),
    };
    try {
      await fetch(url, {
        method: "post",
        body: JSON.stringify(payload),
      });
      setHasSubmitted(true);
      await resolveAfter();
      await scrollToHash({ useHash: "#support", doNotPushState: true });
    } catch (e) {
      reportErrorSilently(e);
      window.alert(`Hmm, something wasn't quite right. Please try again.`);
    } finally {
      setAwaitingResponse(false);
    }
  };
  return (
    <FormWrap>
      {hasSubmitted ? (
        <SuccessMessage />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={submit}>
          <GlobalSearch
            formState={searchFormState}
            inline
            placeholder="What's your question?"
            perPage={5}
            openInNewTab
            doNotKeepQueryInUrl
          />
          <TextInput
            type="email"
            formState={formState}
            name="email"
            placeholder="Email *"
            required
          />
          <Textarea
            formState={formState}
            name="body"
            rows={8}
            placeholder="Describe your issue"
            required
          />
          <FileUploaderSection>
            <p>
              <strong>Attach a file</strong> (optional)
            </p>
            <FileUploader
              fileUploadHandler={zendeskAttachmentUploadHandler}
              onFileUploaded={handleFileUploaded}
            />
          </FileUploaderSection>
          <SubmitRow>
            <Button type="submit" loading={awaitingResponse} width="8em">
              Submit
            </Button>
            <PrivacyNotice />
          </SubmitRow>
        </form>
      )}
    </FormWrap>
  );
};
