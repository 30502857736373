import { styled } from "@linaria/react";
import pagesIllo from "../../../static/images/product-features/tines-feature-illustration-pages.svg";
import { ColorThemeDisplayNameV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ValidProductFeatureCard } from "../../utils/datocms.utils";
import PagesIcon15 from "../icons/productFeatures/PagesIcon15";
import GroupsIcon15 from "../icons/productFeatures/GroupsIcon15";
import { PlaceholderIcon15 } from "../icons/productFeatures/PlaceholderIcon15";
import SendToStoryIcon15 from "../icons/productFeatures/SendToStoryIcon15";
import { cx } from "linaria";
import {
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { CopyToCurlIcon15 } from "../icons/productFeatures/CopyToCurl15";
import { RecordsIcon } from "../icons/productFeatures/productFeatureIcons";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";

type Props = {
  record: ValidProductFeatureCard;
  textOnly?: boolean;
};

type ProductFeatureCardDivProps = {
  backgroundColor: string;
};

const ProductFeatureCardDiv = styled.figure<ProductFeatureCardDivProps>`
  background-color: ${p => p.backgroundColor};
  margin: 0;
  color: ${colors.white};
  border-radius: 1em;
  font-size: 1.4rem;
  &.withIllustration {
    ${fromTabletMd} {
      grid-column-start: start;
      grid-column-end: end;
    }
  }
  img {
    width: 100%;
    aspect-ratio: 648 / 300;
    ${uptoTabletMd} {
      display: none;
    }
  }
  figcaption {
    padding: ${rSize("gap")};
    max-width: 27.5em;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-gap: 0.75em;
    h4 {
      font-weight: 700;
      font-size: inherit;
      padding: 0;
      line-height: 1.4;
      margin-top: -0.2em;
    }
    p {
      line-height: 1.375;
      margin-right: ${rSize("gap")};
      margin-top: 0.4em;
    }
  }
  > img + figcaption {
    ${fromTabletMd} {
      margin-top: ${rSize("gap", -1)};
    }
  }
`;

const ProductFeatureCard = (props: Props) => {
  const def = defs.find(d => d.name === props.record.featureName);
  const color = def?.allowColorOverride
    ? props.record.color || def.defaultColor
    : def?.defaultColor || "Purple";
  const title = def?.name ?? props.record.featureName;
  const description = def?.description ?? "";
  const Icon = def?.icon ?? PlaceholderIcon15;
  return (
    <ProductFeatureCardDiv
      backgroundColor={getBrandColorTheme(color).c500}
      className={cx(
        "ProductFeatureCard",
        props.textOnly ? "textOnly" : "withIllustration"
      )}
    >
      {!props.textOnly && <img src={def?.imgSrc} />}
      <figcaption>
        <Icon />
        <div>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </figcaption>
    </ProductFeatureCardDiv>
  );
};

type ProductFeatureDef = {
  name: string;
  imgSrc: string;
  defaultColor: ColorThemeDisplayNameV4;
  allowColorOverride: boolean;
  description: string;
  icon: React.FC;
};

const defs: ProductFeatureDef[] = [
  {
    name: "Pages",
    imgSrc: pagesIllo,
    defaultColor: "Orange",
    allowColorOverride: true,
    description:
      "Interact with and share insights from the workflow through simple, elegant webpages.",
    icon: PagesIcon15,
  },
  {
    name: "Send to story",
    imgSrc: "",
    defaultColor: "Purple",
    allowColorOverride: true,
    description:
      "Reuse sets of actions anywhere, even across different teams in your company.",
    icon: SendToStoryIcon15,
  },
  {
    name: "Groups",
    imgSrc: "",
    defaultColor: "Purple",
    allowColorOverride: true,
    description:
      "Collect actions into groups to better organize complex workflows.",
    icon: GroupsIcon15,
  },
  {
    name: "Records",
    imgSrc: "",
    defaultColor: "Purple",
    allowColorOverride: true,
    description:
      "Create custom reports by capturing builder-defined parameters across workflows.",
    icon: RecordsIcon,
  },
  {
    name: "cURL to Tines",
    imgSrc: "",
    defaultColor: "Pink",
    allowColorOverride: true,
    description:
      "Generate pre-configured actions by copying a cURL command from a services API docs.",
    icon: CopyToCurlIcon15,
  },
];
export default ProductFeatureCard;
