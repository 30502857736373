import dayjs from "dayjs";
import { useSiteContext } from "../../context/site.context";
import { MMMMDYYYY } from "../../utils/time.utils";

type Props = {
  value?: string | null;
  format?: string;
  className?: string;
  displayInTimezone?: string | null;
  readAsTimezone?: string | null;
};

const DateTimeFormatter = (props: Props) => {
  const siteContext = useSiteContext();
  const d = props.readAsTimezone
    ? dayjs.tz(
        props.value?.replace("T", "").split("+")[0],
        props.readAsTimezone
      )
    : dayjs(props.value);
  const converted = dayjs(d).tz(
    props.displayInTimezone ?? (siteContext.timezone || "UTC")
  );
  const machineTime = converted.format();
  return (
    <time className={props.className} dateTime={machineTime}>
      {converted.format(props.format ?? MMMMDYYYY)}
    </time>
  );
};

export default DateTimeFormatter;
