import ComboFontHeading from "../../../typography/ComboFontHeading";
import PillLegacy from "../../../basic/PillLegacy";
import ReportCard from "../../ReportCard";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import DonutChart from "../../charts/DonutChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import Spacing from "../../../layout/Spacing";
import ReportChartContainer from "../../ReportChartContainer";

const MHChapter4 = () => {
  return (
    <ReportSection
      id="alcohol-and-drug-use"
      themeName="warmBlack"
      header={
        <>
          <PillLegacy color="warmBlack">Part Four</PillLegacy>
          <ComboFontHeading
            snippets={[
              [
                <>
                  Alcohol and
                  <br />
                </>,
              ],
              [<>Drug Use</>, "serif"],
            ]}
          />
          <p>
            Managing workplace stress should be done through healthy approaches.
            But too often individuals turn to unhealthy ways to manage stress
            and poor mental health. What frequency, if any, are respondents
            using alcohol and non-prescription medication in their daily lives?
          </p>
        </>
      }
    >
      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>19% are consuming over three drinks daily</h3>
        <p>
          When it comes to alcohol use, 18.7% of our respondents are consuming
          more than three drinks daily, while 19.6% are consuming more than
          three drinks in a day once a week. 17.5% say they consume more than
          three drinks in a day once a month, 13.8% do so once a quarter, and
          13.5% do so once per year. 16.9% say they never consume that much in
          a day.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            How often do you have more than three alcoholic drinks in a day?
          </h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Daily", 192],
              ["Weekly", 201],
              ["Monthly", 180],
              ["Quarterly", 142],
              ["Once per year", 139],
              ["Never", 173],
            ]}
            donutPercentage={0.375}
            height={325}
            colors={[
              colorsV4.pink,
              colorsV4.purple,
              colorsV4.orange,
              colorsV4.green,
              colorsV4.grey,
              colorsV4.warmBlack,
            ]}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>
          38% are taking non-prescription medication either daily or weekly
        </h3>
        <p>
          When it comes to taking non-prescription medication, 20.8% of
          respondents do so daily, while 17.2% do so weekly. 15.4% take
          non-prescription medication monthly, 16.2% take it quarterly, and
          13.7% take it once per year. 16.7% never take non-prescription
          medication.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>How often do you take medication not prescribed by a doctor?</h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Never", 171],
              ["Daily", 214],
              ["Weekly", 177],
              ["Monthly", 158],
              ["Quarterly", 166],
              ["Once per year", 141],
            ]}
            donutPercentage={0.375}
            height={325}
            colors={[
              colorsV4.grey,
              colorsV4.pink,
              colorsV4.green,
              colorsV4.orange,
              colorsV4.warmBlack,
              colorsV4.purple,
            ]}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportSummaryCard>
        <h3>Summary</h3>
        <p>
          We know that two of the unhealthy ways of dealing with stress,
          burnout, frustrations, and other mental health issues are by consuming
          alcohol or utilizing non-prescription medication (which could range
          from taking an Advil for a headache to heavy drug use). 19% of
          respondents are consuming three or more drinks per day — a worrying
          statistic considering the implications of this kind of level of
          alcohol use. Additionally, 38% are taking non-prescription medication
          either daily or weekly. Of those who reported having significant
          levels of stress at work, the number rises to 41% who are consuming
          three or more drinks per day either every day or once a week, and 42%
          who are taking non-prescription medication either daily or weekly.
        </p>
        <Spacing size="1em" />
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default MHChapter4;
