import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromTablet,
  fromTabletLg,
  onlyPhones,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Button from "../forms/Button";
import PageSection from "./PageSection";
import { useState } from "react";
import TextInput, { TextInputSet } from "../forms/TextInput";
import { reportErrorSilently } from "../../utils/error.utils";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import InfoBox from "../general/InfoBox";
import { TooltipContainer } from "../general/InfoTooltip";
import PrivacyNotice from "../utilities/PrivacyNotice";
import { SmallPrint14 } from "../typography/SmallPrint";
import Spacing from "../layout/Spacing";
import AllCaps from "../typography/AllCaps";
import { colors } from "../../styles/colors.styles";
import RocketIconPinkBorders from "../illustrations/spots/RocketIconPinkBorders";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { font } from "../../styles/fonts.styles";
import { Link } from "gatsby";
import { getFormMetaInfo } from "../../utils/form.utils";

const StartupProgramSectionInner = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: "illustration" "header";
  ${fromTabletLg} {
    grid-gap: ${rSize("lg")};
  }
  background-color: ${colors.pink100};
  color: ${colors.pink700};
  border-radius: ${rSize("radius")};
  text-align: center;
  font-weight: 500;
  border: 1px solid currentColor;
  > * {
    padding: 3em ${rSize("lg")};
    ${fromTabletLg} {
      padding-top: 5em;
      padding-bottom: 5em;
    }
  }
  ${fromTabletLg} {
    grid-template-areas: "illustration header";
    text-align: left;
  }
  header {
    grid-area: header;
    ${uptoTabletLg} {
      margin-bottom: 1em;
    }
  }
  figure {
    grid-area: illustration;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ${uptoTabletLg} {
      padding: 3em 0 0 0;
      svg {
        width: 12rem;
        height: 12rem;
      }
    }
    ${fromTabletLg} {
      width: ${getWidthPxInMaxGrid(3, true)};
      padding: 1em 0 1.5em;
      border-right: 1px dashed currentColor;
    }
  }
  h2 {
    font-family: ${font("serif")};
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 1.05;
    ${fromTabletLg} {
      font-size: 3.6rem;
      letter-spacing: -0.01em;
    }
    ${fromDesktop} {
      font-size: 4.2rem;
    }
  }
`;

const HeaderTextBlock = styled.div`
  > * {
    + * {
      margin-top: 2rem;
    }
  }
`;

const DataPointSet = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  margin-bottom: 1.5em;
  ${onlyPhones} {
    display: inline-grid;
  }
`;

const DataPoint = styled.div`
  h3 {
    margin-top: 2.4rem;
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    opacity: 0.8;
    ${fromTablet} {
      font-size: 1.4rem;
    }
  }
  p {
    font-size: 1.6rem;
    font-weight: 700;
    ${onlyPhones} {
      margin-top: 1rem;
      line-height: 1.5;
    }
    ${fromTablet} {
      margin-top: 0.5rem;
      line-height: 1.25;
    }
    ${fromDesktop} {
      font-size: 2rem;
    }
    strong {
      white-space: nowrap;
      font-weight: inherit;
      ${onlyPhones} {
        display: block;
      }
    }
    span {
      opacity: 0.5;
      margin: 0 0.5em;
      ${onlyPhones} {
        display: none;
      }
    }
  }
  ${TooltipContainer} {
    margin-top: -0.2em;
  }
`;

const StartupProgramSection = () => {
  const [showForm, setShowForm] = useState(false);
  const showApplicationForm = () => {
    setShowForm(true);
  };
  return (
    <PageSection id="startup-program">
      <StartupProgramSectionInner>
        <header>
          <HeaderTextBlock>
            <AllCaps>Startup program</AllCaps>
            <h2>
              Are you an
              <br />
              early-stage company?
            </h2>
            <p>
              Our heavily-discounted Startup Program is perfect for small,
              growing teams. Get a fully-featured, production-grade Tines tenant
              at a fraction of the usual cost.
            </p>
          </HeaderTextBlock>
          <DataPointSet>
            <DataPoint>
              <h3>Eligibility</h3>
              <p>
                <strong>{"<"} 100 employees</strong>
                <span>•</span>
                <strong>{"<"} $50M raised</strong>
                <span>•</span>
                <strong>{"<"} 5 years old</strong>
              </p>
            </DataPoint>
          </DataPointSet>
          {showForm ? (
            <StartupProgramForm />
          ) : (
            <div>
              <Button
                color="pink"
                darker
                onClick={showApplicationForm}
                width="10em"
              >
                Apply now
              </Button>
            </div>
          )}
          <Spacing size="1.5em" />
          <SmallPrint14>
            * Excludes services companies (e.g. managed services providers,
            consultancies, holding companies, etc).{" "}
            <Link to="/blog/announcing-the-tines-startup-program">
              Read more
            </Link>
            .
          </SmallPrint14>
        </header>
        <figure>
          <RocketIconPinkBorders />
        </figure>
      </StartupProgramSectionInner>
    </PageSection>
  );
};

const StartupProgramFormContainer = styled.div`
  margin-top: 2em;
  padding-top: 2em;
  border-top: 2px ${withOpacity(colorsV4.white, 0.2)} solid;
  h3 {
    font-size: inherit;
  }
  input {
    background-color: ${withOpacity(colorsV4.pink300, 0.2)};
    border-color: ${colorsV4.pink300};
    &:hover,
    &:focus {
      border-color: ${colorsV4.white};
    }
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const StartupProgramForm = () => {
  const formState = useState(() => ({
    name: "",
    company: "",
    email: "",
    phone: "",
  }));
  const [form] = formState;
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState<string | null>(null);

  const submit = async () => {
    setValidationMessage("");
    setHasError(null);
    setAwaitingResponse(true);

    const validateForm = async () => {
      if (!form.email.includes("@")) return "Please enter a valid email.";
      if (!(await emailIsLegitimate(form.email)).valid)
        return "Please use a valid business or personal email to continue.";
      if (!form.name)
        return "Please enter your preferred name before continuing.";
      if (!form.company)
        return "Please enter your company name before continuing.";
      return true;
    };

    const validationResult = await validateForm();
    if (typeof validationResult === "string") {
      setValidationMessage(validationResult);
      setAwaitingResponse(false);
      return;
    }
    const payload = {
      ...formState[0],
      ...getFormMetaInfo(),
    };
    fetch(
      "https://hq.tines.io/webhook/ffbdfcb931089a0d3dbecd429a0ea737/fcd1531b762c95a40ce017a1733b3046",
      {
        method: "post",
        body: JSON.stringify(payload),
      }
    )
      .then(() => {
        setSuccess(true);
      })
      .catch(e => {
        reportErrorSilently(e);
        setHasError("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setAwaitingResponse(false);
      });
  };
  return (
    <StartupProgramFormContainer>
      {success ? (
        <SuccessMessage />
      ) : (
        <>
          <h3>Enter your details:</h3>
          <TextInputSet>
            <TextInput
              formState={formState}
              name="name"
              placeholder="Name *"
              required
              autoFocus
            />
            <TextInput
              formState={formState}
              name="company"
              placeholder="Company *"
              required
            />
            <TextInput
              formState={formState}
              name="email"
              placeholder="Email *"
              required
            />
            <TextInput
              formState={formState}
              name="phone"
              placeholder="Phone (Optional)"
            />
          </TextInputSet>
          <Button
            color="pink"
            darker
            onClick={submit}
            loading={awaitingResponse}
            width="9em"
          >
            Submit
          </Button>
          {hasError && (
            <InfoBox color="pink">An error occurred. Please try again.</InfoBox>
          )}
          {validationMessage && (
            <InfoBox color="white">{validationMessage}</InfoBox>
          )}
          <PrivacyNotice linkColor={colors.pink700} />
        </>
      )}
    </StartupProgramFormContainer>
  );
};

const SuccessMessage = () => {
  return (
    <InfoBox color="white">
      <h3>Thanks for your application!</h3>
      <p>We will be in touch as soon as possible.</p>
    </InfoBox>
  );
};

export default StartupProgramSection;
