import { styled } from "@linaria/react";
import { navigate } from "gatsby";
import { cover } from "polished";
import { useState } from "react";
import Button from "../../components/forms/Button";
import { readCookieConsentState } from "../../components/gdpr/gdpr";
import LoadingIndicator from "../../components/utilities/LoadingIndicator";
import PrivacyNotice from "../../components/utilities/PrivacyNotice";
import PageSection from "../../components/reusableSections/PageSection";
import SEO from "../../components/utilities/SEO";
import SiteNavHeightPlaceholder from "../../components/site/SiteNavHeightPlaceholder";
import Spacing from "../../components/layout/Spacing";
import TextInput from "../../components/forms/TextInput";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { getCookie, setCookie } from "../../utils/cookies.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  KnownDocumentName,
  recordDocumentEvent,
} from "../../utils/documents.utils";
import { reportErrorSilently } from "../../utils/error.utils";
import img from "../../../static/images/reports/soc2023/tines-voice-of-the-soc-2023.png";
import coverImage from "../../../static/images/reports/soc2023/tines-voice-of-the-soc-2023-cover.png";
import { serif } from "../../styles/fonts.styles";
import RigidDotGrid from "../../components/decorations/RigidDotGrid";
import { Paths } from "../../utils/pathBuilders.utils";
import { getFormMetaInfo } from "../../utils/form.utils";

const AccessPageHeader = styled.header`
  position: relative;
  background-color: ${colors.purple50};
`;

const AccessPageHeaderInner = styled.div`
  position: relative;
  ${maxPageContentWidthStyleObject};
  padding-top: ${rSize("sectionPadding")};
  h1 {
    font-family: ${serif};
    font-size: 3.8rem;
    letter-spacing: -0.01em;
    font-weight: 400;
    ${fromTablet} {
      font-size: 4.8rem;
      font-weight: 300;
    }
    ${fromDesktop} {
      font-size: 5.6rem;
      letter-spacing: -0.02em;
    }
    ${fromDesktopMd} {
      font-size: 6.4rem;
    }
  }
`;

const AccessPageContentGrid = styled.div`
  ${uptoTabletMd} {
    text-align: center;
  }
  ${fromTabletMd} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${rSize("gap")};
    align-items: center;
  }
  ${fromDesktop} {
    align-items: start;
  }
`;

const ReportThumbContainer = styled.div`
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  ${fromTablet} {
    max-width: 275px;
  }
  ${fromTabletMd} {
    max-width: unset;
    grid-column-end: span 5;
    padding-right: ${rSize("gap")};
  }
  ${fromDesktop} {
    height: 0;
    padding-bottom: 0;
    padding-right: ${widthInGrid(1)};
  }
`;
const ReportThumb = styled.figure`
  margin: 0;
  position: relative;
  width: 100%;
  img {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 4px;
    z-index: 2;
    background-color: ${colors.purple900};
    aspect-ratio: 595 / 842;
    pointer-events: none;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    border-radius: 4px;
    position: absolute;
    ${cover()}
  }
  &:before {
    z-index: 1;
    transform: translate(3px, 3px);
    border-bottom-right-radius: 5px;
    background-color: ${colors.purple200};
  }
  &:after {
    z-index: 0;
    transform: translate(6px, 6px);
    border-bottom-right-radius: 6px;
    background-color: ${colors.purple100};
  }
`;

const AccessPageContentGridTextContent = styled.div`
  ${fromTabletMd} {
    grid-column-start: 6;
    grid-column-end: span 7;
  }
  font-weight: 400;
  ${fromTablet} {
    font-size: 1.4rem;
    font-weight: 500;
  }
  p {
    strong {
      font-weight: 700;
    }
  }
  h3 {
    font-size: inherit;
    font-weight: 700;
    margin-top: 0;
    ${fromTabletMd} {
      margin-top: 1em;
    }
  }
  ul {
    margin-top: 1em;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }
  li {
    margin-top: 0.5em;
    position: relative;
    padding-left: 1em;
    &:before {
      content: "–";
      position: absolute;
      left: 0;
    }
  }
`;

const MobileListBox = styled.div`
  text-align: left;
  ${uptoTabletMd} {
    margin-top: 2em;
    padding: 2em;
    background-color: ${colors.purple100};
    border-radius: 0.5em;
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

const LoadingIndicatorBlock = styled.div`
  padding: 5em 3em;
  text-align: center;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // make sure we push footer out of sight
  min-height: 600px;
  > * {
    margin-left: auto;
    margin-right: auto;
  }
`;

const TextInputSet = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  border-radius: 0.5em;
  border: 0;
  > input {
    position: relative;
    border-radius: 0;
    margin-top: -2px;
    grid-column-end: span 2;
    border-color: ${colors.purple50};
    &:hover,
    &:focus {
      z-index: 1;
      border-color: ${colors.purple300};
    }
    &:first-child {
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
    &:last-child {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }
    ${fromTabletMd} {
      &:nth-child(1) {
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0;
        grid-column-end: span 1;
        margin-right: -2px;
      }
      &:nth-child(2) {
        border-top-left-radius: 0;
        border-top-right-radius: 0.5em;
        grid-column-end: span 1;
        margin-left: -2px;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        max-width: calc(100% - 2px);
      }
    }
  }
`;

const PageAccessSOCReport: PageComponent = props => {
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [showAccessForm, setShowAccessForm] = useState(false);

  const formState = useState(() => ({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    company: "",
    ...getFormMetaInfo(),
  }));

  const goToReport = () => {
    navigate(Paths.reportSoc2023());
  };

  const submit = async () => {
    const [form] = formState;

    setAwaitingResponse(true);

    if (!(await emailIsLegitimate(form.email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }

    const cookieConsentState = readCookieConsentState();
    if (cookieConsentState?.personalization) {
      setCookie("email_address", form.email, { days: 30 });
      setCookie("company", form.company, { days: 30 });
      setCookie("first_name", form.firstName, { days: 30 });
      setCookie("last_name", form.lastName, { days: 30 });
    }

    try {
      await recordDocumentEvent({
        type: "access",
        identifier: KnownDocumentName.soc2023,
        form,
      });
    } catch (e) {
      reportErrorSilently(e);
    } finally {
      goToReport();
    }
  };

  useOnMount(() => {
    const email = getCookie("email_address");
    if (email && email.includes("@")) {
      goToReport();
    } else {
      setShowAccessForm(true);
    }
  });

  return (
    <Page {...props} textColor={colors.purple800} navThemeColor="purple">
      <SEO
        title="Report: Voice of the SOC 2023"
        description="Discover insights from 900 security professionals across the globe. Understand the biggest challenges facing security teams today, and access research-based recommendations for leaders."
        image={img}
        noindex
      />
      {showAccessForm ? (
        <>
          <AccessPageHeader>
            <RigidDotGrid color={colors.purple200} />
            <SiteNavHeightPlaceholder withSpacingTop />
            <AccessPageHeaderInner>
              <AccessPageContentGrid>
                <ReportThumbContainer>
                  <ReportThumb>
                    <img src={coverImage} />
                  </ReportThumb>
                </ReportThumbContainer>
                <AccessPageContentGridTextContent>
                  <p>
                    <strong>2023 Report</strong>
                  </p>
                  <Spacing size="gap" />
                  <h1>Voice of the SOC</h1>
                  <Spacing size="gap" />
                  <p>
                    Discover insights from 900 security professionals across the
                    globe. Understand the biggest challenges facing security
                    teams today, and access research-based recommendations for
                    leaders.
                  </p>
                  <MobileListBox>
                    <h3>What’s in the report:</h3>
                    <ul>
                      <li>
                        Actionable takeaways for leaders to use in 2024 planning
                      </li>
                      <li>
                        Data on where security teams are spending (and wasting!)
                        time
                      </li>
                      <li>Insights on job satisfaction and burnout</li>
                      <li>
                        Blockers that prevent security teams from doing their
                        best work
                      </li>
                      <li>
                        Biggest opportunities for automation, according to
                        security pros
                      </li>
                    </ul>
                  </MobileListBox>

                  <Spacing size="4em" />
                </AccessPageContentGridTextContent>
              </AccessPageContentGrid>
            </AccessPageHeaderInner>
          </AccessPageHeader>

          <PageSection>
            <AccessPageContentGrid>
              <AccessPageContentGridTextContent>
                <Spacing size="4em" />
                <h2>Read the report now</h2>
                <Spacing size="2em" />
                <TextInputSet>
                  <TextInput
                    formState={formState}
                    name="firstName"
                    placeholder="First name *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="lastName"
                    placeholder="Last name *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="email"
                    type="email"
                    placeholder="Email *"
                    required
                    className="fullWidth"
                  />
                  <TextInput
                    formState={formState}
                    name="jobTitle"
                    placeholder="Job Title *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="company"
                    placeholder="Company *"
                    required
                    onEnter={() => {
                      submit();
                    }}
                  />
                </TextInputSet>
                <Spacing size="2em" />
                <Button
                  loading={awaitingResponse}
                  onClick={submit}
                  padding="1em"
                  fullWidth
                >
                  Access report
                </Button>
                <Spacing size=".5em" />
                <PrivacyNotice />
              </AccessPageContentGridTextContent>
            </AccessPageContentGrid>
          </PageSection>
        </>
      ) : (
        <LoadingIndicatorBlock>
          <LoadingIndicator delayInSeconds={1} />
        </LoadingIndicatorBlock>
      )}

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageAccessSOCReport;
