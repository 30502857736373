import { PageProps } from "gatsby";
import Page from "../../templates/Page.template";
import SEO from "../../components/utilities/SEO";
import { colors } from "../../styles/colors.styles";
import HeroSection, {
  HeroSectionDescription,
} from "../../components/sections/HeroSection";
import AllCaps from "../../components/typography/AllCaps";
import Spacing from "../../components/layout/Spacing";
import PageSection from "../../components/reusableSections/PageSection";
import PartnerDirectory from "../../components/partners/PartnerDirectory";
import { ExplosionCTASection } from "../../components/reusableSections/ExplosionCTASection";
import { styled } from "@linaria/react";
import { GridCanvas } from "../../components/decorations/GridCanvas";
import { PartnerDirectoryIcon } from "../../components/illustrations/spots/PartnerDirectoryIcon";
import {
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import ogImage from "../../../static/images/og/tines-partner-directory.png";
import { fluidFontSize } from "../../styles/helpers.styles";

const BackdropWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40em;
  overflow: hidden;
  border-bottom: 1px solid ${colors.purple100};
`;

const SplitHeader = styled.div`
  ${uptoTablet} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    svg {
      margin-bottom: 2em;
    }
  }
  ${fromTablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    p {
      margin-left: 0;
    }
  }
  h1 {
    ${fluidFontSize(36, 52)};
  }
`;

export const PagePartnerDirectory = (props: PageProps) => {
  const description =
    "Choose from hundreds of trusted partners that can help you automate your most critical workflows and focus on the work that matters most.";
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title="Tines partner directory"
        description={description}
        image={ogImage}
      />

      <BackdropWrap>
        <GridCanvas color={colors.purple100} parallax />
      </BackdropWrap>

      <HeroSection textColor={colors.purple800} contentRelative>
        <SplitHeader>
          <div>
            <AllCaps as="p">Find a partner</AllCaps>
            <Spacing size="1em" />
            <h1>Tines partner directory</h1>
            <HeroSectionDescription>{description}</HeroSectionDescription>
          </div>
          <PartnerDirectoryIcon />
        </SplitHeader>
      </HeroSection>
      <Spacing size="xl" />
      <PageSection relative>
        <PartnerDirectory />
      </PageSection>
      <Spacing size="sectionMargin" />
      <ExplosionCTASection />
    </Page>
  );
};

export default PagePartnerDirectory;
