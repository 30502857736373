import { styled } from "@linaria/react";
import { PropsWithChildren, ReactNode } from "react";
import { getWidthPxInMaxGrid } from "../../../constants/globalGrid.constants";
import { ReportV2StatTableWrap } from "./ReportV2StatTable";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { cx } from "linaria";
import { reportV2BodyTextParagraphStyle } from "./ReportV2TextBlock";
import { ReportV2SectionSubheading } from "./ReportV2SectionSubheading";
import {
  fromReportV2DesktopDesign,
  inReportV2MobileDesign,
} from "./reportsV2.helpers";
import { fromDesktopMd } from "../../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  icon?: ReactNode;
  iconAlign?: "start" | "center" | "end";
};

const ReportV2StatTableHeaderWrap = styled.header`
  + ${ReportV2StatTableWrap} {
    margin-top: ${rSize("lg")};
    &.hasHighlightedRows {
      margin-top: 3em;
      ${fromDesktopMd} {
        margin-top: ${rSize("gap", 5)};
      }
    }
  }
  &.hasIcon {
    display: grid;
    grid-gap: 2em;
    ${inReportV2MobileDesign} {
      grid-template-areas: "icon" "text";
      justify-content: center;
      text-align: center;
    }
    ${fromReportV2DesktopDesign} {
      grid-gap: ${rSize("gap")};
      grid-template-columns: minmax(0, 1fr) auto;
      grid-template-areas: "text icon";
    }
  }
`;

const Inner = styled.div`
  grid-area: text;
  max-width: ${getWidthPxInMaxGrid(6)};
  p:not(${ReportV2SectionSubheading}) {
    ${reportV2BodyTextParagraphStyle}
    max-width: 35em;
  }
`;

const IconWrap = styled.div`
  grid-area: icon;
  ${fromReportV2DesktopDesign} {
    align-self: end;
  }
  .start & {
    padding-top: 2.4rem;
    align-self: start;
  }
  .center & {
    align-self: center;
  }
`;

const ReportV2SectionHeader = (props: PropsWithChildren<Props>) => {
  return (
    <ReportV2StatTableHeaderWrap
      className={cx(props.icon && "hasIcon", props.iconAlign)}
    >
      <Inner>{props.children}</Inner>
      {props.icon && <IconWrap>{props.icon}</IconWrap>}
    </ReportV2StatTableHeaderWrap>
  );
};

export default ReportV2SectionHeader;
