import { styled } from "@linaria/react";
import { cx } from "linaria";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
} from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { useViewportSize } from "../../utils/windowEvents.utils";
import { whenDesktopNav, whenMobileNav } from "../site/SiteNav";
import SiteNavHeightPlaceholder from "../site/SiteNavHeightPlaceholder";
import { ReactNode } from "react";
import { colors } from "../../styles/colors.styles";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";

type Props = React.PropsWithChildren<{
  className?: string;
  backgroundColor?: BrandColorNameV4 | string;
  textColor?: BrandColorNameV4 | string;
  fullWidthInside?: boolean;
  backgroundPattern?: ReactNode;
}>;

const HeroSectionWithInsetContainer = styled.section<Props>`
  display: flex;
  position: relative;
  width: 100%;
  ${widerPageSectionMaxWidthStyle()}
  height: auto;
  font-weight: 500;
  ${whenMobileNav} {
    text-align: center;
  }
  .CornerDotSet {
    ${onlyPhones} {
      display: none;
    }
  }
`;

export const HeroSectionBackgroundLayer = styled.div<Props>`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: ${p =>
    p.backgroundColor ? colorV4(p.backgroundColor) : colorsV4.purple};
  margin: 0 ${rSize("widerPageMargin")} ${rSize("widerPageMargin")};
  width: calc(100% - ${rSize("widerPageMargin", 2)});
  ${fromTablet} {
    border-radius: ${rSize("radius")};
  }
`;

const Inner = styled.div`
  &.conformToMaxWidth {
    ${maxPageContentWidthStyleObject};
  }
  &.fullWidth {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
    width: 100%;
  }
  h1 {
    line-height: 1.1;
    font-weight: 600;
    ${fluidFontSize(48, 72)};
    em {
      font-family: ${font("serif")};
      font-style: inherit;
      font-weight: 400;
    }
    ${fromTablet} {
      line-height: 1;
      letter-spacing: -0.01em;
    }
  }
`;

export const ContentWrapper = styled.div<Props>`
  flex: 1 1 auto;
  margin-bottom: ${rSize("widerPageMargin")};
  position: relative;
  color: ${p => (p.textColor ? colorV4(p.textColor) : colors.lightest)};
  ${whenDesktopNav} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2em;
  }
`;

const HeroSectionWithInset = (props: Props) => {
  useViewportSize();

  return (
    <>
      <SiteNavHeightPlaceholder />
      <HeroSectionWithInsetContainer {...props}>
        <HeroSectionBackgroundLayer
          className={cx("BackgroundLayer", props.className)}
          backgroundColor={props.backgroundColor}
          textColor={props.textColor}
        >
          {props.backgroundPattern}
        </HeroSectionBackgroundLayer>
        <Inner
          className={cx(
            props.fullWidthInside ? "fullWidth" : "conformToMaxWidth"
          )}
        >
          <ContentWrapper {...props}>{props.children}</ContentWrapper>
        </Inner>
      </HeroSectionWithInsetContainer>
    </>
  );
};

export const ColumnedHeroSectionContent = styled.div<{
  mobilePaddingTop?: string | number;
  alignItems?: string;
  gridTemplateColumns?: string;
  gap?: string;
}>`
  display: grid;
  grid-gap: 3em;
  text-align: center;
  ${whenMobileNav} {
    padding-top: ${p => p.mobilePaddingTop ?? "4.5em"};
    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${whenDesktopNav} {
    align-items: ${p => p.alignItems ?? "start"};
    grid-gap: ${p => p.gap ?? rSize("gap")};
    padding-top: ${rSize("sectionPadding")};
    grid-template-columns: ${p =>
      p.gridTemplateColumns ?? "minmax(auto, 1fr) auto"};
    text-align: left;
  }
  h1 {
    line-height: 1.1;
    font-weight: 600;
    ${fluidFontSize(48, 72)};
    ${fromTablet} {
      line-height: 1;
      letter-spacing: -0.01em;
    }
  }
  p {
    font-size: 1.8rem;
  }
`;

export const SingleColumnHeroSectionContent = styled.div<{
  paddingBottom?: string | number;
}>`
  text-align: center;
  padding-top: 4em;
  padding-bottom: ${p => p.paddingBottom ?? `5.5em`};
  ${whenDesktopNav} {
    padding-top: 6em;
    padding-bottom: ${p =>
      p.paddingBottom ?? `calc(${rSize("sectionPadding")} + 1.5em)`};
  }
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: ${widthInGrid(8)};
  }
`;

export default HeroSectionWithInset;
