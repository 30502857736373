import { styled } from "@linaria/react";
import { BrandColorNameV4 } from "../../styles/colorsV4.styles";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import { isNumber } from "../../utils/typeChecks.utils";

type Props = {
  className?: string;
  color?: string | BrandColorNameV4;
  opacity?: number;
  centered?: boolean;
  lineWidth?: number;
  size?: string | number;
  delayInSeconds?: number;
};

export const LoadingIndicatorWrapper = styled.div<Props>`
  position: ${p => (p.centered ? "absolute" : "")};
  top: ${p => (p.centered ? "50%" : "")};
  left: ${p => (p.centered ? "50%" : "")};
  transform: ${p => (p.centered ? "translate(-50%, -50%)" : "")};
  transform-origin: center;
  opacity: 0;
  @keyframes LoadingIndicatorWrapperEnter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation-name: LoadingIndicatorWrapperEnter;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
  animation-delay: ${p => `${p.delayInSeconds ?? 0}s`};
`;

export const LoadingIndicatorSpinner = styled.svg<Props>`
  width: ${p => valueWithOptionalUnit(p.size ?? 42)};
  height: ${p => valueWithOptionalUnit(p.size ?? 42)};
  vertical-align: middle;
  transform-origin: center;
  animation: LoadingIndicatorSpin 2s linear infinite;
  circle {
    fill: none;
    stroke: ${p => p.color ?? "currentColor"};
    stroke-opacity: ${p => p.opacity ?? 0.5};
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: LoadingIndicatorRingStretch 1.45s ease-in-out infinite;
  }
  @keyframes LoadingIndicatorSpin {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes LoadingIndicatorRingStretch {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -128px;
    }
  }
`;
const LoadingIndicator = (props: Props) => {
  let size = props.size ?? 24;
  if (/\dpx/.test(`${size}`)) size = parseFloat(`${size}`);
  const lineWidth =
    props.lineWidth ?? (isNumber(size) ? size : 42) <= 24 ? 4 : 3;
  return (
    <LoadingIndicatorWrapper
      className={props.className}
      centered={props.centered}
      delayInSeconds={props.delayInSeconds}
    >
      <LoadingIndicatorSpinner
        viewBox="25 25 50 50"
        color={props.color}
        size={props.size}
      >
        <circle cx="50" cy="50" r={25 - lineWidth} strokeWidth={lineWidth} />
      </LoadingIndicatorSpinner>
    </LoadingIndicatorWrapper>
  );
};

export default LoadingIndicator;
