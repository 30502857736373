/* eslint-disable unused-imports/no-unused-vars */
import { createContext, useContext } from "react";
import { DemoStoryMockEventEmitter } from "./demoStoryMockEventEmitter";

export type DemoStoryContextValue = {
  ready: boolean;
  hasDropped: boolean;
  setHasDropped: (v: boolean) => void;
  isDragging: boolean;
  setIsDragging: (v: boolean) => void;
  isBouncingBack: boolean;
  setIsBouncingBack: (v: boolean) => void;
  shouldShowNotification: boolean;
  setShouldShowNotification: (v: boolean) => void;
  eventEmitters: DemoStoryMockEventEmitter[];
  setEventEmitters: (v: DemoStoryMockEventEmitter[]) => void;
  reset: () => void;
};

const defaultDemoStoryContext: DemoStoryContextValue = {
  ready: false,
  hasDropped: false,
  setHasDropped: v => {},
  isDragging: false,
  setIsDragging: v => {},
  isBouncingBack: false,
  setIsBouncingBack: v => {},
  eventEmitters: [],
  shouldShowNotification: false,
  setShouldShowNotification: v => {},
  setEventEmitters: v => {},
  reset: () => {},
};

export const DemoStoryContext = createContext(defaultDemoStoryContext);
export const useDemoStoryContext = () => useContext(DemoStoryContext);
