import { styled } from "@linaria/react";
import gsap from "gsap";
import { cx } from "linaria";
import { useRef } from "react";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { runAfter } from "../../../utils/promises.utils";

const PagesSpotIconAnimatedContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin-top: -6px;
  &.dark {
    [stroke="#32313B"] {
      stroke: ${colorsV4.warmBlack900};
    }
    [fill="#32313B"] {
      fill: ${colorsV4.warmBlack900};
    }
  }
`;

const CardsContainer = styled.div`
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  perspective: 500px;
  > * {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform-origin: center bottom;
    opacity: 0;
    transform: translateX(-50%);
    &:nth-child(1) {
      z-index: 1;
    }
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(3) {
      z-index: 3;
    }
  }
`;
const ShadowContainer = styled.div`
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  > * {
    opacity: 0;
  }
`;

const CardWidth = 67;
const CardHeight = 91;

const PagesSpotIconAnimated = (props: {
  dark?: boolean;
  shortLoop?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const shadowContainerRef = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    if (!cardsContainerRef.current || !shadowContainerRef.current)
      return () => {};
    const tl = gsap.timeline().pause().repeat(-1);
    const cards = Array.from(cardsContainerRef.current.querySelectorAll("svg"));
    const [bottom, middle, top] = cards;
    const shadow = shadowContainerRef.current.querySelector("svg");
    tl.set(cards, {
      x: -CardWidth / 2,
      y: CardHeight / 3,
      rotateX: 30,
      scale: 0.8,
      opacity: 0,
      rotateZ: 0,
    });
    tl.set(bottom, {
      z: 10,
    });
    tl.set(middle, {
      z: 20,
    });
    tl.set(top, {
      z: 30,
    });
    tl.set(shadow, {
      scale: 0.8,
      opacity: 0,
    });
    tl.to(
      cards,
      {
        opacity: 1,
        duration: 0.2,
        stagger: 0.15,
      },
      0
    );
    tl.to(
      shadow,
      {
        opacity: 1,
        duration: 0.2,
      },
      0
    );
    tl.to(
      shadow,
      {
        scale: 0.8,
        ease: "expo.out",
        duration: 1,
      },
      0
    );
    tl.to(
      shadow,
      {
        scale: 1,
        ease: "expo.out",
        duration: 1,
      },
      0.7
    );
    tl.to(
      cards,
      {
        x: -CardWidth / 2,
        y: 0,
        rotateX: 0,
        scale: 1,
        ease: "expo.out",
        duration: 1,
        stagger: 0.1,
      },
      0
    );
    tl.to(
      bottom,
      {
        x: -CardWidth / 2 + 5,
        y: 0,
        rotateZ: 14,
        duration: 0.8,
        ease: "back.inOut",
      },
      0.5
    );
    tl.to(
      middle,
      {
        x: -CardWidth / 2,
        y: 2,
        rotateZ: -1,
        duration: 0.8,
        ease: "back.inOut",
      },
      0.5
    );
    tl.to(
      top,
      {
        x: -CardWidth / 2 - 5,
        y: 4,
        rotateZ: -14,
        duration: 0.8,
        ease: "back.inOut",
      },
      0.5
    );
    const numberOfBobs = props.shortLoop ? 4 : 100;
    Array(numberOfBobs)
      .fill(0)
      .map((n, i) => i + 1)
      .forEach(time => {
        [top, middle, bottom].forEach((card, index) => {
          tl.to(
            card,
            {
              y: (index - 2) * -2 + (time % 2 === 0 ? 1 : -1) * 2,
              duration: 1,
              ease: "sine.inOut",
            },
            time - index * 0.1
          );
        });
        tl.to(
          shadow,
          {
            scale: time % 2 === 0 ? 0.9 : 1,
            ease: "sine.inOut",
            duration: 1,
          },
          time
        );
      });
    tl.to(
      cards,
      {
        x: -CardWidth / 2,
        y: CardHeight / 4,
        rotateX: 30,
        scale: 0.6,
        ease: "expo.inOut",
        duration: 1,
        stagger: 0.1,
      },
      numberOfBobs + 1 - 0.25
    );
    tl.to(
      cards,
      {
        opacity: 0,
        duration: 0.2,
        stagger: 0.1,
      },
      numberOfBobs + 1.2 - 0.25
    );
    tl.to(
      shadow,
      {
        opacity: 0,
        scale: 0.3,
        duration: 1,
        ease: "expo.inOut",
      },
      numberOfBobs + 1 - 0.25
    );
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) runAfter(() => tl.play(), 1000);
      else tl.pause().seek(0);
    });
    if (ref.current) intersectionObserver.observe(ref.current);
    return () => {
      intersectionObserver.disconnect();
      tl.kill();
    };
  });
  return (
    <PagesSpotIconAnimatedContainer
      className={cx(props.dark && "dark")}
      ref={ref}
    >
      <ShadowContainer ref={shadowContainerRef}>
        <Shadow />
      </ShadowContainer>
      <CardsContainer ref={cardsContainerRef}>
        <PinkCard />
        <GreenCard />
        <PurpleCard />
      </CardsContainer>
    </PagesSpotIconAnimatedContainer>
  );
};

const PurpleCard = () => (
  <svg width="67" height="91" viewBox="0 0 67 91" fill="none">
    <rect
      x="5.61292"
      y="29.3953"
      width="55.3548"
      height="55.9429"
      rx="6.00505"
      fill="#8578E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0084 1C5.48092 1 1 5.48092 1 11.0084L1 79.9916C1 85.5191 5.48092 90 11.0084 90H55.9916C61.5191 90 66 85.5191 66 79.9916V11.0084C66 5.48092 61.5191 1 55.9916 1L11.0084 1ZM11.6179 29.3952C8.30144 29.3952 5.61288 32.0838 5.61288 35.4003L5.61288 79.3331C5.61288 82.6496 8.30143 85.3381 11.6179 85.3381H54.9627C58.2792 85.3381 60.9677 82.6496 60.9677 79.3331V35.4003C60.9677 32.0838 58.2792 29.3952 54.9627 29.3952H11.6179Z"
      fill="#D4CFFC"
    />
    <path
      d="M1.75063 11.0084C1.75063 5.89548 5.89548 1.75063 11.0084 1.75063V0.249369C5.06636 0.249369 0.249369 5.06636 0.249369 11.0084H1.75063ZM1.75063 79.9916L1.75063 11.0084H0.249369L0.249369 79.9916H1.75063ZM11.0084 89.2494C5.89548 89.2494 1.75063 85.1045 1.75063 79.9916H0.249369C0.249369 85.9336 5.06636 90.7506 11.0084 90.7506V89.2494ZM55.9916 89.2494H11.0084V90.7506H55.9916V89.2494ZM65.2494 79.9916C65.2494 85.1045 61.1045 89.2494 55.9916 89.2494V90.7506C61.9336 90.7506 66.7506 85.9336 66.7506 79.9916H65.2494ZM65.2494 11.0084V79.9916H66.7506V11.0084H65.2494ZM55.9916 1.75063C61.1045 1.75063 65.2494 5.89548 65.2494 11.0084H66.7506C66.7506 5.06636 61.9336 0.249369 55.9916 0.249369V1.75063ZM11.0084 1.75063L55.9916 1.75063V0.249369L11.0084 0.249369V1.75063ZM6.36352 35.4003C6.36352 32.4984 8.716 30.1459 11.6179 30.1459V28.6446C7.88688 28.6446 4.86225 31.6692 4.86225 35.4003H6.36352ZM6.36352 79.3331L6.36352 35.4003H4.86225L4.86225 79.3331H6.36352ZM11.6179 84.5875C8.716 84.5875 6.36352 82.235 6.36352 79.3331H4.86225C4.86225 83.0641 7.88687 86.0887 11.6179 86.0887V84.5875ZM54.9627 84.5875H11.6179V86.0887H54.9627V84.5875ZM60.2171 79.3331C60.2171 82.235 57.8646 84.5875 54.9627 84.5875V86.0887C58.6937 86.0887 61.7184 83.0641 61.7184 79.3331H60.2171ZM60.2171 35.4003V79.3331H61.7184V35.4003H60.2171ZM54.9627 30.1459C57.8646 30.1459 60.2171 32.4984 60.2171 35.4003H61.7184C61.7184 31.6692 58.6937 28.6446 54.9627 28.6446V30.1459ZM11.6179 30.1459H54.9627V28.6446H11.6179V30.1459Z"
      fill="#32313B"
    />
    <rect
      x="31.1297"
      y="13.4942"
      width="28.8655"
      height="5.10212"
      rx="2.55106"
      fill="#8578E6"
      stroke="#32313B"
      strokeWidth="1.25502"
    />
    <circle
      cx="15.7557"
      cy="16.0159"
      r="8.9758"
      fill="#ECEAFF"
      stroke="#32313B"
      strokeWidth="1.50126"
    />
    <circle
      cx="15.7558"
      cy="16.0158"
      r="3.95571"
      fill="#8578E6"
      stroke="#32313B"
      strokeWidth="1.50126"
    />
  </svg>
);
const GreenCard = () => (
  <svg
    width="67"
    height="91"
    viewBox="0 0 67 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5.61292"
      y="29.3953"
      width="55.3548"
      height="55.9429"
      rx="6"
      fill="#45BE8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 1C5.47715 1 1 5.47715 1 11L1 80C1 85.5229 5.47715 90 11 90H56C61.5228 90 66 85.5229 66 80V11C66 5.47715 61.5228 1 56 1L11 1ZM11.6129 29.3953C8.2992 29.3953 5.61291 32.0815 5.61291 35.3953L5.61291 79.3381C5.61291 82.6518 8.2992 85.3381 11.6129 85.3381H54.9678C58.2815 85.3381 60.9678 82.6518 60.9678 79.3381V35.3953C60.9678 32.0815 58.2815 29.3953 54.9678 29.3953H11.6129Z"
      fill="#C0E9D8"
    />
    <path
      d="M1.75 11C1.75 5.89136 5.89137 1.75 11 1.75V0.25C5.06294 0.25 0.25 5.06294 0.25 11H1.75ZM1.75 80L1.75 11H0.25L0.25 80H1.75ZM11 89.25C5.89137 89.25 1.75 85.1086 1.75 80H0.25C0.25 85.9371 5.06294 90.75 11 90.75V89.25ZM56 89.25H11V90.75H56V89.25ZM65.25 80C65.25 85.1086 61.1086 89.25 56 89.25V90.75C61.9371 90.75 66.75 85.9371 66.75 80H65.25ZM65.25 11V80H66.75V11H65.25ZM56 1.75C61.1086 1.75 65.25 5.89137 65.25 11H66.75C66.75 5.06294 61.9371 0.25 56 0.25V1.75ZM11 1.75L56 1.75V0.25L11 0.25V1.75ZM6.36291 35.3953C6.36291 32.4958 8.71342 30.1453 11.6129 30.1453V28.6453C7.88499 28.6453 4.86291 31.6673 4.86291 35.3953H6.36291ZM6.36291 79.3381L6.36291 35.3953H4.86291V79.3381H6.36291ZM11.6129 84.5881C8.71341 84.5881 6.36291 82.2376 6.36291 79.3381H4.86291C4.86291 83.066 7.88499 86.0881 11.6129 86.0881V84.5881ZM54.9678 84.5881H11.6129V86.0881H54.9678V84.5881ZM60.2178 79.3381C60.2178 82.2376 57.8672 84.5881 54.9678 84.5881V86.0881C58.6957 86.0881 61.7178 83.066 61.7178 79.3381H60.2178ZM60.2178 35.3953V79.3381H61.7178V35.3953H60.2178ZM54.9678 30.1453C57.8672 30.1453 60.2178 32.4958 60.2178 35.3953H61.7178C61.7178 31.6673 58.6957 28.6453 54.9678 28.6453V30.1453ZM11.6129 30.1453H54.9678V28.6453H11.6129V30.1453Z"
      fill="#32313B"
    />
    <rect
      x="6.65518"
      y="12.8815"
      width="28.8413"
      height="5.80952"
      rx="2.90476"
      fill="#45BE8B"
      stroke="#32313B"
      strokeWidth="1.25397"
    />
    <rect
      x="41.8893"
      y="5.78491"
      width="17.9365"
      height="17.9365"
      rx="8.96825"
      fill="#45BE8B"
      stroke="#32313B"
      strokeWidth="1.5"
    />
    <path
      d="M46.4686 14.7532L49.917 18.2016L55.5599 12.5587"
      stroke="white"
      strokeWidth="1.88095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const PinkCard = () => (
  <svg width="67" height="91" viewBox="0 0 67 91" fill="none">
    <rect
      x="5.61292"
      y="37.4476"
      width="55.3548"
      height="47.8905"
      rx="6"
      fill="#F48EBA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 1C5.47715 1 1 5.47715 1 11L1 80C1 85.5228 5.47715 90 11 90H56C61.5228 90 66 85.5228 66 80V11C66 5.47715 61.5228 1 56 1L11 1ZM11.6129 37.4476C8.2992 37.4476 5.61291 40.1339 5.61291 43.4476L5.61291 79.3381C5.61291 82.6518 8.2992 85.3381 11.6129 85.3381H54.9677C58.2815 85.3381 60.9677 82.6518 60.9677 79.3381L60.9677 43.4476C60.9677 40.1339 58.2815 37.4476 54.9677 37.4476H11.6129Z"
      fill="#FFCCE2"
    />
    <path
      d="M1.75 11C1.75 5.89136 5.89137 1.75 11 1.75V0.25C5.06294 0.25 0.25 5.06294 0.25 11H1.75ZM1.75 80L1.75 11H0.25L0.25 80H1.75ZM11 89.25C5.89137 89.25 1.75 85.1086 1.75 80H0.25C0.25 85.9371 5.06294 90.75 11 90.75V89.25ZM56 89.25H11V90.75H56V89.25ZM65.25 80C65.25 85.1086 61.1086 89.25 56 89.25V90.75C61.9371 90.75 66.75 85.9371 66.75 80H65.25ZM65.25 11V80H66.75V11H65.25ZM56 1.75C61.1086 1.75 65.25 5.89137 65.25 11H66.75C66.75 5.06294 61.9371 0.25 56 0.25V1.75ZM11 1.75L56 1.75V0.25L11 0.25V1.75ZM6.36291 43.4476C6.36291 40.5481 8.71341 38.1976 11.6129 38.1976V36.6976C7.88499 36.6976 4.86291 39.7197 4.86291 43.4476H6.36291ZM6.36291 79.3381L6.36291 43.4476H4.86291L4.86291 79.3381H6.36291ZM11.6129 84.5881C8.71341 84.5881 6.36291 82.2376 6.36291 79.3381H4.86291C4.86291 83.066 7.88499 86.0881 11.6129 86.0881V84.5881ZM54.9677 84.5881H11.6129V86.0881H54.9677V84.5881ZM60.2177 79.3381C60.2177 82.2376 57.8672 84.5881 54.9677 84.5881V86.0881C58.6957 86.0881 61.7177 83.066 61.7177 79.3381H60.2177ZM60.2177 43.4476L60.2177 79.3381H61.7177L61.7177 43.4476H60.2177ZM54.9677 38.1976C57.8672 38.1976 60.2177 40.5481 60.2177 43.4476H61.7177C61.7177 39.7197 58.6957 36.6976 54.9677 36.6976V38.1976ZM11.6129 38.1976H54.9677V36.6976H11.6129V38.1976Z"
      fill="#32313B"
    />
    <rect
      x="5.52417"
      y="10.4119"
      width="55.5555"
      height="17.9365"
      rx="6.03"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="1.5"
    />
    <rect
      x="10.6451"
      y="14.6778"
      width="23.0645"
      height="8.15079"
      rx="4.0754"
      fill="white"
    />
    <path
      d="M46.1552 16.5587L49.9171 20.3206L53.679 16.5587"
      stroke="white"
      strokeWidth="2.19444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Shadow = () => (
  <svg width="76" height="9" viewBox="0 0 76 9" fill="none">
    <ellipse cx="38" cy="4.5" rx="38" ry="4.5" fill="#32313B" />
  </svg>
);

export default PagesSpotIconAnimated;
