export const StepsToFlagIcon = () => (
  <svg
    width="63"
    height="88"
    viewBox="0 0 63 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7528 87.625C23.4057 87.625 29.6096 86.1085 29.6096 84.2378C29.6096 82.367 23.4057 80.8505 15.7528 80.8505C8.0999 80.8505 1.896 82.367 1.896 84.2378C1.896 86.1085 8.0999 87.625 15.7528 87.625Z"
      fill="#32313B"
    />
    <path
      d="M27.4935 80.225C35.1464 80.225 41.3503 78.7085 41.3503 76.8377C41.3503 74.967 35.1464 73.4505 27.4935 73.4505C19.8406 73.4505 13.6367 74.967 13.6367 76.8377C13.6367 78.7085 19.8406 80.225 27.4935 80.225Z"
      fill="#32313B"
    />
    <path
      d="M38.5394 72.5664C46.1923 72.5664 52.3962 71.0498 52.3962 69.1791C52.3962 67.3084 46.1923 65.7918 38.5394 65.7918C30.8865 65.7918 24.6826 67.3084 24.6826 69.1791C24.6826 71.0498 30.8865 72.5664 38.5394 72.5664Z"
      fill="#32313B"
    />
    <path
      d="M48.8553 65.1169C56.5082 65.1169 62.7121 63.6004 62.7121 61.7297C62.7121 59.8589 56.5082 58.3424 48.8553 58.3424C41.2024 58.3424 34.9985 59.8589 34.9985 61.7297C34.9985 63.6004 41.2024 65.1169 48.8553 65.1169Z"
      fill="#32313B"
    />
    <path
      d="M34.5352 61.055C34.5352 62.8872 39.2032 64.3726 44.9612 64.3726C50.7193 64.3726 55.3869 62.8872 55.3869 61.055V25.2482C55.3869 23.4162 50.7193 21.9312 44.9612 21.9312C39.2034 21.9312 34.5352 23.4162 34.5352 25.2482V61.055Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M44.961 28.5661C50.7191 28.5661 55.3869 27.0808 55.3869 25.2487C55.3869 23.4165 50.7191 21.9312 44.961 21.9312C39.203 21.9312 34.5352 23.4165 34.5352 25.2487C34.5352 27.0808 39.203 28.5661 44.961 28.5661Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M24.0605 68.6093C24.0605 70.4416 28.7286 71.9269 34.4866 71.9269C40.2447 71.9269 44.9123 70.4416 44.9123 68.6093V40.3336C44.9123 38.5018 40.2447 37.0168 34.4866 37.0168C28.7288 37.0168 24.0605 38.5018 24.0605 40.3336V68.6093Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M34.4864 43.6517C40.2444 43.6517 44.9123 42.1664 44.9123 40.3342C44.9123 38.5021 40.2444 37.0168 34.4864 37.0168C28.7284 37.0168 24.0605 38.5021 24.0605 40.3342C24.0605 42.1664 28.7284 43.6517 34.4864 43.6517Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M12.709 76.1452C12.709 77.9775 17.3768 79.4628 23.1346 79.4628C28.8924 79.4628 33.5603 77.9775 33.5603 76.1452V57.4212C33.5603 55.5892 28.8924 54.104 23.1346 54.104C17.3768 54.104 12.709 55.5892 12.709 57.4212V76.1452Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M23.1346 60.7388C28.8925 60.7388 33.5603 59.2536 33.5603 57.4214C33.5603 55.5893 28.8925 54.104 23.1346 54.104C17.3767 54.104 12.709 55.5893 12.709 57.4214C12.709 59.2536 17.3767 60.7388 23.1346 60.7388Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M1.43115 83.8249C1.43115 85.6572 6.09875 87.1426 11.8568 87.1426C17.6146 87.1426 22.2824 85.6572 22.2824 83.8249V71.4632C22.2824 69.6314 17.6146 68.146 11.8568 68.146C6.09875 68.146 1.43115 69.6314 1.43115 71.4632V83.8249Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M11.8568 74.7804C17.6147 74.7804 22.2824 73.2953 22.2824 71.4632C22.2824 69.6312 17.6147 68.146 11.8568 68.146C6.09887 68.146 1.43115 69.6312 1.43115 71.4632C1.43115 73.2953 6.09887 74.7804 11.8568 74.7804Z"
      fill="#D2CCFF"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
    />
    <path
      d="M46.4917 25.9426C48.7091 25.9426 50.5068 25.4125 50.5068 24.7587C50.5068 24.1049 48.7091 23.5748 46.4917 23.5748C44.2742 23.5748 42.4766 24.1049 42.4766 24.7587C42.4766 25.4125 44.2742 25.9426 46.4917 25.9426Z"
      fill="#32313B"
    />
    <path
      d="M45.5959 23.7767C45.5959 24.7547 44.9583 24.9762 44.1717 24.9762C43.3849 24.9762 42.7476 24.7547 42.7476 23.7767V3.14563C42.7476 2.16786 43.3849 1.375 44.1717 1.375C44.9583 1.375 45.5959 2.16786 45.5959 3.14563V23.7767Z"
      fill="#FAE1EC"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.5962 3.14661V11.599L55.6395 7.04146L45.5962 3.14661Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="1.08136"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
