export const GartnerLogo20 = () => (
  <svg width="89" height="20" viewBox="0 0 89 20" fill="#002856">
    <path d="M88.4211 6.31572L88.1508 9.38701H86.8432C84.7597 9.38701 83.6788 10.9898 83.6788 12.5338V19.4736H80V6.31572H83.4957L83.5742 7.96046C84.3064 6.83599 85.5879 6.31572 87.1745 6.31572H88.4211Z" />
    <path d="M63.6758 19.4736H60.1075V12.6859C60.1075 11.2612 59.9725 9.47623 57.9226 9.47623C55.9402 9.47623 55.1473 10.8682 55.1473 12.4812V19.4654H51.579V6.63505H54.8942L54.9701 8.27262C55.6787 7.17544 57.0031 6.31572 58.9518 6.31572C62.5539 6.31572 63.6842 8.81302 63.6842 11.4004L63.6758 19.4736Z" />
    <path d="M50.0001 16.6404L49.7574 19.6826C48.9649 19.9042 48.148 20.0149 47.3231 19.9979C44.0961 19.9979 43.158 18.4385 43.158 15.3026V2.63118H46.5871V6.39766H49.992L49.7574 9.51651H46.5871V15.0214C46.5871 16.2825 46.9106 16.9557 48.1399 16.9557C48.7708 16.9643 49.4016 16.8535 50.0001 16.6404Z" />
    <path d="M41.579 6.31572L41.317 9.38701H40.4039C38.5141 9.38701 37.5692 10.9898 37.5692 12.5338V19.4736H34.2106V6.31572H37.3866L37.4501 7.96046C38.125 6.84439 39.2922 6.31572 40.7373 6.31572H41.579Z" />
    <path d="M17.8947 18.0738C15.5193 19.3528 13.1438 20.0837 10.0068 19.9923C4.19641 19.8179 0 16.1885 0 10.0759C0 3.98816 4.23779 0.0930086 10.0151 0.00165105C12.9451 -0.0398751 15.5606 0.69929 17.6133 2.51814L15.1385 5.01801C13.8721 3.93002 12.0098 3.29883 9.77506 3.39849C6.14149 3.56459 3.9481 6.22227 3.89844 9.85996C3.84878 13.8049 6.26564 16.5291 10.0399 16.6287C11.8195 16.6702 13.0858 16.4377 14.1867 15.9394V11.9114H10.2634L10.5283 8.59757H17.8865L17.8947 18.0738Z" />
    <path d="M78.4211 14.4064H68.7952C69.0688 16.0711 70.3788 17.0783 72.0038 17.1282C73.5874 17.1699 74.4662 16.6954 75.627 15.5134L77.9153 17.2531C76.2903 19.2674 74.3336 19.9999 72.1033 19.9999C68.1899 19.9999 65.2632 17.2531 65.2632 13.1578C65.2632 9.06255 68.1816 6.31572 71.9623 6.31572C76.1327 6.31572 78.4128 8.85446 78.4128 13.3493L78.4211 14.4064ZM74.9057 11.6679C74.7979 10.0198 73.5708 8.97931 71.8711 9.0459C70.1798 9.11249 69.0273 10.2279 68.7952 11.6679H74.9057Z" />
    <path d="M31.579 11.1359V19.6837H28.4374L28.3618 18.3936C27.4042 19.5755 25.8417 19.9999 24.2289 19.9999C21.8097 19.9999 19.5248 18.8181 19.4744 16.0631C19.4072 11.7351 24.3969 11.4272 27.5302 11.4272H28.1602V11.2524C28.1602 9.82082 27.2025 9.04677 25.4049 9.04677C24.0945 9.05509 22.8429 9.54616 21.8853 10.4367L19.8356 8.37261C21.4821 7.01595 23.5653 6.28351 25.6989 6.31681C29.7814 6.31681 31.579 8.18117 31.579 11.1359ZM28.1602 13.9907H27.6646C25.9845 13.9907 23.0277 14.0739 23.0361 15.8218C23.0529 18.2854 28.1602 17.4947 28.1602 14.5317V13.9907Z" />
    <path d="M85.585 18.149C85.6074 17.3197 86.1986 16.6737 86.9019 16.6999C87.6127 16.7261 88.1664 17.4157 88.144 18.2363C88.1215 19.0482 87.5529 19.6854 86.8645 19.6854C86.1537 19.6854 85.585 19.0132 85.585 18.1927C85.585 18.1839 85.585 18.1665 85.585 18.149ZM86.8645 19.9997C87.7325 19.9822 88.4358 19.1529 88.4208 18.1315C88.4059 17.1189 87.695 16.2983 86.8196 16.3158C85.9591 16.3332 85.2632 17.1451 85.2632 18.149C85.2558 19.1616 85.9591 19.991 86.8271 19.9997C86.842 19.9997 86.857 19.9997 86.8645 19.9997ZM86.5278 18.2974H86.8495L87.3284 19.214H87.6352L87.1264 18.2799C87.3957 18.245 87.5903 18.0704 87.5903 17.6951C87.5903 17.276 87.3808 17.0927 86.9468 17.0927H86.2584V19.214H86.5353L86.5278 18.2974ZM86.5278 18.0268V17.3721H86.9019C87.0964 17.3721 87.2985 17.4157 87.2985 17.6863C87.2985 18.0093 87.089 18.0355 86.857 18.0355L86.5278 18.0268Z" />
  </svg>
);
