export const MagicCrystalBallIcon = () => (
  <svg width="71" height="56" viewBox="0 0 71 56" fill="none">
    <path
      d="M40.1675 52.7851C51.0617 52.7851 59.8932 51.256 59.8932 49.3698C59.8932 47.4835 51.0617 45.9545 40.1675 45.9545C29.2734 45.9545 20.4419 47.4835 20.4419 49.3698C20.4419 51.256 29.2734 52.7851 40.1675 52.7851Z"
      fill="#32274B"
    />
    <path
      d="M34.6301 51.6337C30.5281 51.6337 26.6475 51.3207 23.7031 50.752C21.7827 50.3811 17.2856 49.5129 17.2856 46.1989C17.2856 42.885 21.7828 42.0164 23.7031 41.6455C26.6475 41.0769 30.5282 40.7638 34.6301 40.7638C38.732 40.7638 42.6125 41.0769 45.557 41.6455C47.4774 42.0164 51.9745 42.885 51.9745 46.1989C51.9745 49.5129 47.4772 50.3811 45.5569 50.752C42.6125 51.3207 38.7321 51.6337 34.6301 51.6337ZM28.5308 46.1989C30.2662 46.3601 32.3089 46.465 34.6301 46.465C36.9514 46.465 38.994 46.36 40.7293 46.1989C38.994 46.0374 36.9514 45.9328 34.6301 45.9328C32.3089 45.9328 30.2662 46.0374 28.5308 46.1989Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6301 46.9495C30.5281 46.9495 26.6475 46.6363 23.7031 46.0677C21.7827 45.6967 17.2856 44.8283 17.2856 41.5144C17.2856 38.2005 21.7828 37.3319 23.7031 36.9611C26.6475 36.3925 30.5282 36.0793 34.6301 36.0793C38.732 36.0793 42.6125 36.3925 45.557 36.9611C47.4774 37.3319 51.9745 38.2005 51.9745 41.5144C51.9745 44.8283 47.4772 45.6967 45.5569 46.0677C42.6125 46.6363 38.7321 46.9495 34.6301 46.9495ZM28.5308 41.5144C30.2662 41.6757 32.3089 41.7804 34.6301 41.7804C36.9514 41.7804 38.994 41.6755 40.7293 41.5144C38.994 41.353 36.9514 41.2483 34.6301 41.2483C32.3089 41.2483 30.2662 41.3531 28.5308 41.5144Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6296 42.1724C43.4657 42.1724 50.6288 40.743 50.6288 38.9798C50.6288 37.2166 43.4657 35.7872 34.6296 35.7872C25.7935 35.7872 18.6304 37.2166 18.6304 38.9798C18.6304 40.743 25.7935 42.1724 34.6296 42.1724Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.818333"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.7102 40.064C45.5403 40.064 50.2665 39.2203 50.2665 38.1796C50.2665 37.1389 45.5403 36.2953 39.7102 36.2953C33.8801 36.2953 29.1538 37.1389 29.1538 38.1796C29.1538 39.2203 33.8801 40.064 39.7102 40.064Z"
      fill="#32274B"
    />
    <path
      d="M34.6299 39.1051C45.0532 39.1051 53.503 30.6553 53.503 20.232C53.503 9.80866 45.0532 1.35889 34.6299 1.35889C24.2066 1.35889 15.7568 9.80866 15.7568 20.232C15.7568 30.6553 24.2066 39.1051 34.6299 39.1051Z"
      fill="#FFCCE2"
      stroke="#32274B"
      strokeWidth="0.818333"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.1411 10.4313C50.1408 13.1835 51.3205 16.5701 51.3205 20.232C51.3205 20.8297 51.2891 21.4198 51.2277 22.0014"
      stroke="#F8F7FC"
      strokeWidth="1.09111"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.0747 7.2124C45.6316 7.65976 46.1591 8.14203 46.654 8.65594"
      stroke="#F8F7FC"
      strokeWidth="1.09111"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.6431 46.9345C33.6431 46.9345 39.9028 47.0836 43.8522 46.3383C47.8017 45.5933 51.0805 43.8794 51.0805 43.8794L51.5276 44.9225C51.5275 44.9225 48.9939 48.4248 33.6431 46.9345Z"
      fill="#32274B"
    />
    <path
      d="M23.8726 35.7395C26.9244 37.8607 30.6316 39.105 34.6298 39.105C38.6279 39.105 42.3351 37.8607 45.387 35.7396C42.3046 35.3314 38.6068 35.0933 34.6298 35.0933C30.6529 35.0933 26.9549 35.3313 23.8726 35.7395Z"
      fill="#B8E5D2"
      stroke="#32274B"
      strokeWidth="0.818333"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.9511 8.11951L30.2884 10.5643L32.7334 11.9016L30.2884 13.2389L28.9511 15.6838L27.6139 13.2389L25.1689 11.9016L27.6139 10.5643L28.9511 8.11951Z"
      fill="#F8F7FC"
    />
    <path
      d="M33.8949 16.0936L34.8373 17.8169L36.5606 18.7594L34.8373 19.7019L33.8949 21.4251L32.9523 19.7019L31.229 18.7594L32.9523 17.8169L33.8949 16.0936Z"
      fill="#F8F7FC"
    />
    <path
      d="M11.9528 54.9716C15.9133 54.9716 19.1238 54.3156 19.1238 53.5065C19.1238 52.6973 15.9133 52.0414 11.9528 52.0414C7.99233 52.0414 4.78174 52.6973 4.78174 53.5065C4.78174 54.3156 7.99233 54.9716 11.9528 54.9716Z"
      fill="#32274B"
    />
    <path
      d="M3.94727 53.1551C3.94727 54.003 6.48492 54.6903 9.61545 54.6903C12.746 54.6903 15.2835 54.003 15.2835 53.1551V44.1806C15.2835 43.3328 12.7459 42.6455 9.61545 42.6455C6.48506 42.6455 3.94727 43.3328 3.94727 44.1806V53.1551Z"
      fill="#FBB598"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.61532 45.7158C12.7457 45.7158 15.2834 45.0284 15.2834 44.1806C15.2834 43.3327 12.7457 42.6454 9.61532 42.6454C6.48494 42.6454 3.94727 43.3327 3.94727 44.1806C3.94727 45.0284 6.48494 45.7158 9.61532 45.7158Z"
      fill="#FFD2BF"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.347 44.7713C13.9477 44.7713 15.2453 44.3896 15.2453 43.9188C15.2453 43.4481 13.9477 43.0664 12.347 43.0664C10.7463 43.0664 9.44873 43.4481 9.44873 43.9188C9.44873 44.3896 10.7463 44.7713 12.347 44.7713Z"
      fill="#32274B"
    />
    <path
      d="M12.7902 40.6797C12.7902 42.4875 11.4467 43.953 9.78962 43.953C8.13249 43.953 6.78906 42.4875 6.78906 40.6797C6.78906 38.8718 9.78962 34.8149 9.78962 34.8149C9.78962 34.8149 12.7902 38.8718 12.7902 40.6797Z"
      fill="#FFF0D9"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.1794 54.9716C67.1398 54.9716 70.3504 54.3156 70.3504 53.5065C70.3504 52.6973 67.1398 52.0414 63.1794 52.0414C59.2189 52.0414 56.0083 52.6973 56.0083 53.5065C56.0083 54.3156 59.2189 54.9716 63.1794 54.9716Z"
      fill="#32274B"
    />
    <path
      d="M55.1738 53.1551C55.1738 54.003 57.7115 54.6903 60.8419 54.6903C63.9724 54.6903 66.5101 54.003 66.5101 53.1551V44.1806C66.5101 43.3328 63.9725 42.6455 60.8419 42.6455C57.7116 42.6455 55.1738 43.3328 55.1738 44.1806V53.1551Z"
      fill="#FBB598"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.8419 45.7158C63.9723 45.7158 66.5099 45.0284 66.5099 44.1806C66.5099 43.3327 63.9723 42.6454 60.8419 42.6454C57.7115 42.6454 55.1738 43.3327 55.1738 44.1806C55.1738 45.0284 57.7115 45.7158 60.8419 45.7158Z"
      fill="#FFD2BF"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.4007 44.7031C64.1144 44.7031 65.5035 44.2757 65.5035 43.7484C65.5035 43.2211 64.1144 42.7937 62.4007 42.7937C60.687 42.7937 59.2979 43.2211 59.2979 43.7484C59.2979 44.2757 60.687 44.7031 62.4007 44.7031Z"
      fill="#32274B"
    />
    <path
      d="M63.4577 40.816C63.4577 42.6239 62.1142 44.0893 60.4571 44.0893C58.8 44.0893 57.4565 42.6239 57.4565 40.816C57.4565 39.0082 60.4571 34.9513 60.4571 34.9513C60.4571 34.9513 63.4577 39.0082 63.4577 40.816Z"
      fill="#FFF0D9"
      stroke="#32274B"
      strokeWidth="0.681944"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
