import { styled } from "@linaria/react";

const MagnifierG = styled.g`
  @keyframes MagnifierMove {
    0% {
      transform: translate(-2px, -2px);
    }
    25% {
      transform: translate(2px, -2px);
    }
    50% {
      transform: translate(2px, 2px);
    }
    75% {
      transform: translate(-2px, 2px);
    }
    100% {
      transform: translate(-2px, -2px);
    }
  }
  animation: MagnifierMove 3s infinite;
`;

export const DemoIconCheckingAvailability = () => (
  <svg width="83" height="72" viewBox="0 0 83 72" fill="none">
    <path
      d="M19 2.5H1V20.5H19V2.5Z"
      fill="#EADFF8"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <rect x="1" y="2.5" width="18" height="5" fill="#A990F5" stroke="#4D3E78" />
    <rect x="8.5" y="10" width="3" height="3" fill="#D7C4FA" />
    <rect x="8.5" y="15" width="3" height="3" fill="#D7C4FA" />
    <rect x="3.5" y="10" width="3" height="3" fill="#D7C4FA" />
    <rect x="3.5" y="15" width="3" height="3" fill="#D7C4FA" />
    <rect x="13.5" y="10" width="3" height="3" fill="#D7C4FA" />
    <rect x="13.5" y="12" width="3" height="3" fill="#C2AAFA" />
    <path
      d="M38.6991 10.2223C35.6146 8.21082 31.9316 7.04102 27.9744 7.04102C17.1157 7.04102 8.3125 15.844 8.3125 26.7029C8.3125 30.7295 9.5239 34.4728 11.6009 37.5901H38.6991V10.2223Z"
      fill="#EADFF8"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <rect x="4.5" width="2" height="5" rx="1" fill="#4D3E78" />
    <rect x="13.5" width="2" height="5" rx="1" fill="#4D3E78" />
    <rect x="20.5" y="27" width="6" height="6" fill="#D7C4FA" />
    <rect x="12.5" y="27" width="6" height="6" fill="#D7C4FA" />
    <rect x="28.5" y="19" width="6" height="6" fill="#D7C4FA" />
    <rect x="20.5" y="19" width="6" height="6" fill="#D7C4FA" />
    <rect x="28.5" y="11" width="6" height="6" fill="#D7C4FA" />
    <rect x="20.5" y="11" width="6" height="6" fill="#D7C4FA" />
    <rect x="12.5" y="19" width="6" height="6" fill="#D7C4FA" />
    <path
      d="M12.5 17C12.5 13.6863 15.1863 11 18.5 11V11V17H12.5V17Z"
      fill="#D7C4FA"
    />
    <MagnifierG>
      <path
        d="M48.1679 14.4816C39.5657 5.41751 25.2441 5.04312 16.1801 13.6454C7.11578 22.2478 6.74091 36.5694 15.3434 45.6334C23.9457 54.6979 38.2677 55.0724 47.3317 46.4699C56.396 37.8678 56.7699 23.5461 48.1679 14.4816ZM19.2511 41.9253C12.697 35.0189 12.982 24.1074 19.8884 17.5533C26.7947 10.9989 37.706 11.2844 44.2604 18.1906C50.8144 25.0966 50.5291 36.0082 43.6229 42.5626C36.717 49.1167 25.8052 48.8312 19.2511 41.9253Z"
        fill="#6956A8"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M45.1856 11.9402C36.5833 2.87623 22.2618 2.50119 13.1973 11.1038C4.13321 19.706 3.75866 34.028 12.3611 43.0921C20.9635 52.1561 35.2853 52.5307 44.3494 43.9283C53.4136 35.326 53.788 21.0043 45.1856 11.9402ZM16.2688 39.3835C9.7146 32.4775 10.0001 21.5658 16.9061 15.0117C23.8122 8.45729 34.7237 8.74262 41.2778 15.6487C47.8319 22.5549 47.5469 33.4664 40.6408 40.0208C33.7348 46.5749 22.8229 46.2897 16.2688 39.3835Z"
        fill="#D7C4FA"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M41.6846 46.1407L44.3929 48.849C44.3929 48.849 48.9156 48.4969 50.9062 45.6126C52.8967 42.7283 52.328 39.6409 52.328 39.6409L49.5656 36.7566C49.5656 36.7566 49.1426 38.5413 46.1938 41.9971C43.5939 45.0439 41.6846 46.1407 41.6846 46.1407Z"
        fill="#6956A8"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M59.4604 48.8529C60.8493 49.9759 61.0644 52.0118 59.9413 53.4008C58.8184 54.7892 56.7822 55.0047 55.3937 53.8818L47.8117 47.7501C46.4231 46.6271 46.2077 44.591 47.3308 43.2022C48.454 41.813 50.4902 41.5982 51.8787 42.7212L59.4604 48.8529Z"
        fill="#D7C4FA"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M76.3548 60.4364C78.4377 62.1213 78.7606 65.1759 77.0761 67.2586C75.3912 69.3416 72.3372 69.6648 70.254 67.9799L56.4547 56.8197C54.372 55.1353 54.0488 52.0809 55.7336 49.9977C57.4182 47.9148 60.4726 47.5915 62.5554 49.2765L76.3548 60.4364Z"
        fill="#6956A8"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M46.898 43.5679C46.898 43.5679 45.0565 46.222 45.4899 48.4969L47.6023 47.8469C47.6023 47.8469 45.7606 45.7888 46.898 43.5679Z"
        fill="#4D3E78"
      />
      <path
        d="M25.6387 10.6356L30.5135 12.8292C30.5135 12.8292 36.2009 12.748 39.9382 14.8604C39.9382 14.8604 37.5008 11.773 32.8697 10.7168C28.2386 9.66059 25.6387 10.6356 25.6387 10.6356Z"
        fill="#4D3E78"
      />
    </MagnifierG>
  </svg>
);
