import { graphql, useStaticQuery } from "gatsby";
import PageSection from "../reusableSections/PageSection";
import { styled } from "@linaria/react";
import {
  fromTablet,
  fromTabletLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Button from "../forms/Button";
import {
  DatoCmsFeatureHighlight,
  EnterprisePageFeaturedHighlightsQuery,
} from "../../../graphql-types";
import { FeatureHighlightCard } from "../general/FeatureHighlightCard";
import Spacing from "../layout/Spacing";
import { SectionHeading2 } from "../typography/SectionHeading2";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTablet} {
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  header {
    ${onlyPhones} {
      text-align: center;
      padding-bottom: 2em;
    }
  }
`;

export const EnterprisePageFeatureHighlightsGridSection = () => {
  const queryResult = useStaticQuery<EnterprisePageFeaturedHighlightsQuery>(
    enterprisePageFeaturedHighlightsQuery
  );
  return (
    <PageSection>
      <Grid>
        <header>
          <SectionHeading2 lighter>
            Build, run, and monitor your most important workflows
          </SectionHeading2>
          <Spacing size="gap" />
          <Button to="/product" darker appearance="outlined">
            Explore the platform
          </Button>
        </header>
        {queryResult.data?.featureHighlights?.map((f, i) => (
          <FeatureHighlightCard key={i} card={f as DatoCmsFeatureHighlight} />
        ))}
      </Grid>
    </PageSection>
  );
};

export const enterprisePageFeaturedHighlightsQuery = graphql`
  query EnterprisePageFeaturedHighlights {
    data: datoCmsEnterprisePage {
      featureHighlights {
        title
        linkLabel
        linkPath
        image {
          url
          width
          height
        }
      }
    }
  }
`;

export default EnterprisePageFeatureHighlightsGridSection;
