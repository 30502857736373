import {
  PNG_SPOT_ILLUSTRATIONS,
  SpotIllustrationName,
} from "./spots/PNGSpotIllustrations";
import { makeSrcSet2x } from "../../utils/srcset.utils";
import { styled } from "@linaria/react";

type Props = {
  className?: string;
  name: SpotIllustrationName;
  size?: string | number;
};

const Image = styled.img`
  aspect-ratio: 1 / 1;
`;

const SpotIllustration = (props: Props) => {
  const def = PNG_SPOT_ILLUSTRATIONS[props.name];
  const size = props.size ?? "150";
  return (
    <Image
      className={props.className}
      src={`/images/spot-illustrations/${def.slug}.png`}
      srcSet={makeSrcSet2x(
        `/images/spot-illustrations/${def.slug}-150w.png`,
        `/images/spot-illustrations/${def.slug}.png`
      )}
      alt={def.alt}
      width={size}
      height={size}
    />
  );
};

export default SpotIllustration;
