/* eslint-disable @typescript-eslint/no-misused-promises */
import { styled } from "@linaria/react";
import { css, cx } from "linaria";
import {
  MouseEvent,
  PropsWithChildren,
  createElement,
  useLayoutEffect,
  useRef,
} from "react";
import { makeSlug } from "../../utils/string.utils";
import { onlyPhones } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { reportErrorSilently } from "../../utils/error.utils";
import { makeHeadingLink } from "../../utils/linkedHeadings.utils";
import { flashNotice } from "../../utils/notice.utils";

const LinkedHeading = (
  props: PropsWithChildren<{
    id?: string | null;
    className?: string;
    level?: 1 | 2 | 3 | 4 | 5 | 6;
    plainText?: string;
    html?: string;
    scrollOffsetY?: number;
  }>
) => {
  const { level = 2, children, plainText = `${props.children}` } = props;
  let id = props.id ?? makeSlug(plainText);
  if (/^\d/.test(id)) id = `_${id}`;
  const scrollToHeadingAndCopyLink = (e?: MouseEvent) => {
    e?.preventDefault();
    scrollToHash({
      useHash: `#${id}`,
    });
    const url = new URL(window.location.href);
    url.searchParams.forEach((value, key) => url.searchParams.delete(key));
    url.hash = id;
    const urlWithHeadingAnchor = url.toString();
    try {
      navigator.clipboard.writeText(urlWithHeadingAnchor);
      flashNotice(`Copied link to <strong>"${plainText}"</strong>.`);
    } catch (e) {
      reportErrorSilently(e);
      flashNotice("Failed to copy link, please try copying manually.");
    }
  };
  const ref = useRef<HTMLElement>(null);
  useLayoutEffect(() => {
    if (props.html && ref.current) {
      makeHeadingLink(ref.current);
    }
  });
  return createElement(`h${level}`, {
    id,
    ref,
    className: cx(props.className, LinkedHeadingStyle),
    dangerouslySetInnerHTML: props.html ? { __html: props.html } : undefined,
    "data-plaintext": plainText,
    children: props.html ? undefined : (
      <span>
        <span>{children}</span> 
        <HeadingLink href={`#${id}`} onClick={scrollToHeadingAndCopyLink}>
          <LinkedHeadingLinkSymbol />
        </HeadingLink>
      </span>
    ),
  });
};

export const HeadingLink = styled.a`
  opacity: 0;
  color: var(--ac, ${colorsV4.purple});
  padding: 5px;
  ${onlyPhones} {
    display: none;
  }
  svg {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
  }
`;

export const headingLinkStyle = css`
  opacity: 0;
  color: var(--ac, ${colorsV4.purple});
  padding: 5px;
  ${onlyPhones} {
    display: none;
  }
  svg {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
  }
`;

export const LinkedHeadingStyle = css`
  position: relative;
  &:hover {
    ${HeadingLink}, a.heading-link {
      opacity: 1;
    }
  }
`;

export const LinkedHeadingLinkSymbol = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M13.5438 10.456C12.7247 9.63723 11.614 9.17728 10.4558 9.17728C9.29766 9.17728 8.18692 9.63723 7.36781 10.456L4.27881 13.544C3.45969 14.3631 2.99951 15.4741 2.99951 16.6325C2.99951 17.7909 3.45969 18.9019 4.27881 19.721C5.09793 20.5401 6.2089 21.0003 7.36731 21.0003C8.52572 21.0003 9.63669 20.5401 10.4558 19.721L11.9998 18.177"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4561 13.544C11.2752 14.3628 12.3859 14.8227 13.5441 14.8227C14.7022 14.8227 15.8129 14.3628 16.6321 13.544L19.7211 10.456C20.5402 9.63687 21.0004 8.52591 21.0004 7.36749C21.0004 6.20908 20.5402 5.09811 19.7211 4.27899C18.9019 3.45987 17.791 2.99969 16.6326 2.99969C15.4741 2.99969 14.3632 3.45987 13.5441 4.27899L12.0001 5.82299"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkedHeading;
