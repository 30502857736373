import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import BostonOfficeImage from "../../images/tines-office-boston-wework.jpg";
import DublinOfficeImage from "../../images/tines-office-dublin-huckletree-exterior.jpg";
import SFOfficeImage from "../../images/tines-office-san-francisco.jpg";
import RemoteWorkImage from "../../images/tines-work-remote.jpg";
import {
  fromDesktopMd,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PillLegacy, { PillTagStrong } from "../basic/PillLegacy";
import PageSection from "./PageSection";
import { Serif } from "../typography/Serif";

type Props = {
  onSelectOfficeFilter?: (officeId: string) => void;
  dublinOfficeId?: string | null;
  bostonOfficeId?: string | null;
};

const TinesBenefitsSectionInner = styled.div`
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${uptoTabletMd} {
    grid-auto-flow: row;
  }
  ${fromTabletMd} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, minmax(0, auto));
    grid-template-areas:
      "header header header header header header dublin dublin dublin boston boston boston"
      "header header header header header header sf sf sf remote remote remote"
      "header header header header header header . . . . . .";
  }
  ${fromDesktopMd} {
    grid-template-areas:
      "header header header header . . dublin dublin dublin boston boston boston"
      "header header header header . . sf sf sf remote remote remote"
      "header header header header . . . . . . . .";
  }
`;

const Header = styled.header`
  ${uptoTabletMd} {
    padding-bottom: ${rSize("xl")};
  }
  ${fromTabletMd} {
    grid-area: header;
    padding-right: ${rSize("gap")};
  }
  ${fromDesktopMd} {
    padding-right: 0;
  }
  > * {
    + * {
      margin-top: 2em;
    }
  }
`;

const OfficeLocationSet = styled.div`
  display: inline-block;
  margin-top: ${rSize("gap", 2)};
  > * {
    + * {
      margin-top: 1.5em;
    }
  }
  a {
    display: block;
    text-decoration: none;
  }
`;

const OfficeLocationInner = styled.div`
  display: grid;
  grid-gap: 1.25em;
  grid-auto-flow: column;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  strong {
    font-weight: 600;
  }
  span {
    color: ${colors.purple};
  }
`;

const OfficeImageCard = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1em;
  border-radius: ${rSize("radius")};
  ${PillTagStrong} {
    font-size: 1.2rem;
  }
`;

const OfficeLocationsSection = (props: Props) => {
  return (
    <PageSection id="tines-benefits">
      <TinesBenefitsSectionInner>
        <Grid>
          <Header>
            <h2
              className={css`
                ${fluidFontSize(38, 52)}
              `}
            >
              <Serif>
                Office <br />
                locations
              </Serif>
            </h2>
            <p>
              Our employees are trusted to decide where and how they work: from
              home, at one of our hub offices, or a bit of both.
            </p>

            <OfficeLocationSet>
              <OfficeLocationInner>
                <div>
                  <p>
                    <strong>Dublin</strong>
                  </p>
                  <p>Our European headquarters</p>
                </div>
              </OfficeLocationInner>
              <OfficeLocationInner>
                <div>
                  <p>
                    <strong>Boston</strong>
                  </p>
                  <p>Our US headquarters</p>
                </div>
              </OfficeLocationInner>
              <OfficeLocationInner>
                <div>
                  <p>
                    <strong>San Francisco</strong>
                  </p>
                  <p>Our West Coast hub</p>
                </div>
              </OfficeLocationInner>
            </OfficeLocationSet>
          </Header>

          <OfficeImageCard
            className={css`
              aspect-ratio: 2 / 1;
              background-color: ${colors.green100};
              background-image: url(${DublinOfficeImage});
              background-size: cover;
              background-position: bottom;
              ${fromTabletMd} {
                grid-area: dublin;
                aspect-ratio: 1 / 1;
                background-position: center;
              }
            `}
          >
            <PillLegacy color="green" appearance="filled">
              Dublin
            </PillLegacy>
          </OfficeImageCard>
          <OfficeImageCard
            className={css`
              aspect-ratio: 2 / 1;
              background-color: ${colors.orange100};
              background-image: url(${BostonOfficeImage});
              background-size: cover;
              background-position: center;
              ${fromTabletMd} {
                aspect-ratio: 1 / 1;
                grid-area: boston;
              }
            `}
          >
            <PillLegacy color="orange" appearance="filled">
              Boston
            </PillLegacy>
          </OfficeImageCard>
          <OfficeImageCard
            className={css`
              aspect-ratio: 1 / 1;
              background-color: ${colors.pink100};
              background-image: url(${SFOfficeImage});
              background-size: cover;
              background-position: center;
              ${fromTabletMd} {
                grid-area: sf;
              }
            `}
          >
            <PillLegacy color="purple" appearance="filled">
              San Francisco
            </PillLegacy>
          </OfficeImageCard>
          <OfficeImageCard
            className={css`
              aspect-ratio: 1 / 1;
              background-color: ${colors.pink100};
              background-image: url(${RemoteWorkImage});
              background-size: cover;
              background-position: center;
              ${fromTabletMd} {
                grid-area: remote;
              }
            `}
          >
            <PillLegacy color="pink" appearance="filled">
              Remote
            </PillLegacy>
          </OfficeImageCard>
        </Grid>
      </TinesBenefitsSectionInner>
    </PageSection>
  );
};

export default OfficeLocationsSection;
