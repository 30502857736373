import { styled } from "@linaria/react";

const Wrap = styled.div`
  svg {
    &:first-child {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translate(75%, -25%);
    }
    &:last-child {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-75%, -75%);
    }
  }
`;

const PageHeroHeadlineClouds = () => {
  return (
    <Wrap>
      <CloudsLeft />
      <CloudsRight />
    </Wrap>
  );
};

const CloudsLeft = () => (
  <svg width="300" height="158" viewBox="0 0 300 158" fill="none">
    <path
      d="M129.304 35.2462C128.54 35.2462 127.793 35.3147 127.064 35.4004C124.129 22.5174 112.71 12.9065 99.0686 12.9065C98.4238 12.9065 97.7961 12.9579 97.1683 13.0093C90.8227 5.65981 81.4739 1 71.0562 1C56.1763 1 43.502 10.4567 38.5816 23.7509C34.8998 21.6094 30.6411 20.3588 26.0771 20.3588C12.2321 20.3588 1 31.6999 1 45.6794C1 59.6588 12.2321 71 26.0771 71H129.304C139.076 71 147 62.9995 147 53.1317C147 43.2638 139.076 35.2633 129.304 35.2633V35.2462Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M71 54C61.7791 56.5005 54.1809 62.7706 50 71H57.4073C59.8509 63.9462 64.6809 57.9748 70.9809 54H71Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M120.252 66.2537C114.749 54.8686 103.082 47 89.5681 47C72.126 47 57.7562 60.1143 55.7728 77.0171C54.0617 76.5314 52.2533 76.2594 50.3866 76.2594C39.6724 76.2594 31 84.9246 31 95.6297C31 106.335 39.6724 115 50.3866 115H116.46C130.014 115 141 104.023 141 90.4811C141 78.2217 131.997 68.0606 120.233 66.2537H120.252Z"
      fill="#FFC8A3"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M274.29 106.776C271.531 106.776 268.892 107.259 266.373 108.104C260.795 98.9884 250.779 92.8915 239.384 92.8915C238.664 92.8915 237.944 92.8915 237.164 92.9518C231.047 72.7293 212.394 58 190.323 58C166.153 58 146.061 75.6268 142.102 98.8677C141.802 98.8677 141.502 98.8677 141.202 98.8677C127.348 98.8677 115.833 108.707 113.134 121.807C104.137 122.41 97 130.137 97 139.373C97 148.609 104.737 157 114.333 157C118.771 157 268.052 156.638 274.29 156.638C287.964 156.638 299 145.47 299 131.767C299 118.064 287.904 106.896 274.29 106.896V106.776Z"
      fill="#FFE5CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
  </svg>
);

const CloudsRight = () => (
  <svg width="245" height="192" viewBox="0 0 245 192" fill="none">
    <path
      d="M151.042 14.0246C147.34 6.32286 139.492 1 130.4 1C118.667 1 109 9.87143 107.665 21.3057C106.514 20.9771 105.298 20.7931 104.042 20.7931C96.8342 20.7931 91 26.6549 91 33.8966C91 41.1383 96.8342 47 104.042 47H148.492C157.609 47 165 39.5743 165 30.4137C165 22.1206 158.943 15.2469 151.029 14.0246H151.042Z"
      fill="#FFE5CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M218.415 66.5536C217.205 66.5536 215.996 66.64 214.829 66.8128C209.516 61.3696 202.085 58 193.877 58C183.552 58 174.437 63.3568 169.21 71.4352C167.525 71.0464 165.754 70.8304 163.983 70.8304C151.282 70.8304 141 81.112 141 93.8129C141 106.514 151.282 116.795 163.983 116.795H218.458C232.325 116.795 243.558 105.563 243.558 91.6961C243.558 77.8288 232.325 66.5968 218.458 66.5968L218.415 66.5536Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M82.2319 74.3806C81.0238 74.3806 79.8341 74.4717 78.681 74.6356C73.4278 69.2976 66.1064 66 58.0162 66C47.821 66 38.8523 71.247 33.6907 79.1721C32.025 78.7895 30.3045 78.5708 28.5107 78.5708C16.0094 78.5708 5.86914 88.6639 5.86914 101.107C5.86914 113.551 16.0094 123.644 28.5107 123.644H82.2319C95.9047 123.644 106.978 112.621 106.978 99.0121C106.978 85.4028 95.9047 74.3806 82.2319 74.3806Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M144.464 92.8536C134.616 78.6977 118.198 69.4062 99.565 69.4062C71.231 69.4062 47.9488 90.9042 45.2765 118.396C41.3412 116.611 36.9666 115.609 32.3541 115.609C15.0389 115.609 1 129.582 1 146.817C1 164.052 15.0389 178.026 32.3541 178.026H142.688C166.337 178.026 185.501 158.951 185.501 135.412C185.501 111.874 167.288 93.7827 144.482 92.8536H144.464Z"
      fill="#FFD8CF"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M135.605 158.914C135.605 158.914 142.176 170.191 140.785 178.044L132.273 178.244C132.273 178.244 137.49 164.944 135.623 158.914H135.605Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M167.193 135.297C167.193 135.297 169.335 145.335 184.106 145.335L185.296 138.868L167.193 135.315V135.297Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M111.59 140.456C100.04 140.456 90.3028 148.253 87.4108 158.838C86.276 158.583 85.1046 158.437 83.8965 158.437C75.0559 158.437 67.8809 165.579 67.8809 174.379C67.8809 183.178 75.0559 190.32 83.8965 190.32H111.59C125.427 190.32 136.648 179.152 136.648 165.379C136.648 151.606 125.427 140.438 111.59 140.438V140.456Z"
      fill="#FFE5CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M175.397 113.971C180.375 113.971 184.677 116.868 186.708 121.058C191.376 121.405 195.055 125.267 195.055 130.004C195.055 134.741 191.01 138.985 186.031 138.985H175.397C168.46 138.985 162.822 133.392 162.822 126.469C162.822 119.546 168.441 113.953 175.397 113.953V113.971Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeLinejoin="round"
    />
  </svg>
);

export default PageHeroHeadlineClouds;
