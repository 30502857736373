import { Link } from "gatsby";
import { DatoCmsLibraryTool } from "../../../graphql-types";
import { css } from "linaria";
import { withOpacity } from "../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { rSize } from "../../styles/responsiveSizes.styles";
import { Paths } from "../../utils/pathBuilders.utils";
import LibraryToolImageWithDarkMode, {
  LibraryToolImageWithDarkModeWrap,
} from "./LibraryToolImageWithDarkMode";
import { colors } from "../../styles/colors.styles";

type Props = {
  tool: DatoCmsLibraryTool;
};

const ToolEntryCss = css`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  grid-template-areas: "logo" "heading";
  background-color: ${withOpacity(colors.light300, 0.4)};
  border-radius: ${rSize("radius")};
  padding: 1.5em;
  text-decoration: none;
  font-weight: 600;
  aspect-ratio: 1/1;
  font-size: 1.4rem;
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.dark500, 0.8)};`
  )}
  &:hover {
    background-color: ${withOpacity(colors.purple, 0.1)};
  }
  header {
    grid-area: heading;
    text-align: center;
    padding-top: 0.75em;
  }
  ${LibraryToolImageWithDarkModeWrap} {
    grid-area: logo;
    align-self: center;
    justify-self: center;
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const ToolEntry = ({ tool }: Props) => {
  return (
    <Link className={ToolEntryCss} to={Paths.library.tool(tool.slug!)}>
      <header>
        <p>{tool.name}</p>
      </header>
      <LibraryToolImageWithDarkMode tool={tool} />
    </Link>
  );
};

export default ToolEntry;
