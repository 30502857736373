export const FedNetworksIcon = () => (
  <svg
    width="103"
    height="62"
    viewBox="0 0 103 62"
    fill="none"
    strokeMiterlimit="10"
  >
    <path
      d="M69.3488 50.0274C68.8152 50.0274 68.2816 49.824 67.8745 49.417L50.5679 32.1103C49.7534 31.2959 49.7534 29.9757 50.5679 29.1615C51.3823 28.3472 52.7023 28.3472 53.5167 29.1615L70.8234 46.4679C71.6378 47.2822 71.6378 48.6025 70.8234 49.417C70.416 49.824 69.8828 50.0274 69.3488 50.0274Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M93.0681 39.605C98.0008 39.605 102 35.6063 102 30.6736C102 25.7409 98.0008 21.7422 93.0681 21.7422C88.1355 21.7422 84.1367 25.7409 84.1367 30.6736C84.1367 35.6063 88.1355 39.605 93.0681 39.605Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M88.9503 28.3242C88.4167 28.3242 87.8831 28.1207 87.4757 27.7137L70.1694 10.407C69.355 9.59279 69.355 8.27246 70.1694 7.45821C70.9839 6.64414 72.3038 6.64414 73.1183 7.45821L90.425 24.7647C91.239 25.5789 91.239 26.8993 90.425 27.7137C90.0175 28.1207 89.4839 28.3242 88.9503 28.3242Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M72.232 0.902344C77.1648 0.902344 81.1632 4.90098 81.1632 9.83375C81.1632 14.7665 77.1648 18.7652 72.232 18.7652C67.2988 18.7652 63.3008 14.7665 63.3008 9.83375C63.3008 4.90098 67.2988 0.902344 72.232 0.902344Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M52.0148 32.3007C52.5487 32.3007 53.082 32.0973 53.4894 31.6903L70.796 14.3836C71.6101 13.5692 71.6101 12.249 70.796 11.4348C69.9812 10.6207 68.6616 10.6207 67.8468 11.4348L50.5405 28.7412C49.7261 29.5555 49.7261 30.8758 50.5405 31.6903C50.9476 32.0971 51.4812 32.3007 52.0148 32.3007Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M35.4758 61.3795C39.967 61.3795 43.6079 60.6326 43.6079 59.7113C43.6079 58.7899 39.967 58.043 35.4758 58.043C30.9846 58.043 27.3438 58.7899 27.3438 59.7113C27.3438 60.6326 30.9846 61.3795 35.4758 61.3795Z"
      fill="#32313B"
    />
    <path
      d="M47.2512 28.3242C46.7176 28.3242 46.184 28.1207 45.7766 27.7137L28.4699 10.407C27.6559 9.59279 27.6559 8.27246 28.4699 7.45821C29.2848 6.64414 30.6043 6.64414 31.4192 7.45821L48.7254 24.7647C49.5399 25.5789 49.5399 26.8993 48.7254 27.7137C48.3184 28.1207 47.7848 28.3242 47.2512 28.3242Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M30.5527 0.902344C35.4855 0.902344 39.4839 4.90098 39.4839 9.83375C39.4839 14.7665 35.4855 18.7652 30.5527 18.7652C25.6195 18.7652 21.6211 14.7665 21.6211 9.83375C21.6211 4.90098 25.6195 0.902344 30.5527 0.902344Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M9.7961 32.3007C10.3297 32.3007 10.8633 32.0973 11.2707 31.6903L28.577 14.3836C29.3915 13.5692 29.3915 12.249 28.577 11.4348C27.7626 10.6207 26.4426 10.6207 25.6282 11.4348L8.32149 28.7412C7.50742 29.5555 7.50742 30.8758 8.32149 31.6903C8.7289 32.0971 9.2625 32.3007 9.7961 32.3007Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M9.70896 39.6054C14.6418 39.6054 18.6406 35.6066 18.6406 30.6738C18.6406 25.741 14.6418 21.7422 9.70896 21.7422C4.77617 21.7422 0.777344 25.741 0.777344 30.6738C0.777344 35.6066 4.77617 39.6054 9.70896 39.6054Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M30.1925 53.5218C29.6589 53.5218 29.1253 53.3182 28.7179 52.9112L11.4116 35.6047C10.5972 34.7903 10.5972 33.4699 11.4116 32.6555C12.2261 31.8414 13.546 31.8414 14.3605 32.6555L31.6671 49.9621C32.4812 50.7766 32.4812 52.0969 31.6671 52.9112C31.2597 53.3182 30.7261 53.5218 30.1925 53.5218Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M30.5527 42.582C35.4855 42.582 39.4839 46.5809 39.4839 51.5133C39.4839 56.4464 35.4855 60.445 30.5527 60.445C25.6195 60.445 21.6211 56.4464 21.6211 51.5133C21.6211 46.5809 25.6195 42.582 30.5527 42.582Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M10.75 34.9824L11.7925 39.5173L13.9818 38.3706L10.75 34.9824Z"
      fill="#32313B"
    />
    <path
      d="M29.2541 13.8184L28.1074 18.3015L25.7617 17.4151L29.2541 13.8184Z"
      fill="#32313B"
    />
    <path
      d="M43.8691 35.4805L32.7788 46.5706C31.9644 47.3848 31.9644 48.7051 32.7788 49.5196C33.1862 49.9266 33.7194 50.13 34.2534 50.13C34.787 50.13 35.3206 49.9266 35.7277 49.5196L46.8728 38.3744C45.6576 37.6592 44.6277 36.6656 43.8691 35.4805Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M43.861 35.4805C43.861 35.4805 45.7445 38.0309 46.8647 38.3744L45.2044 39.8995C45.2044 39.8995 43.6059 38.2314 43.3281 36.0768L43.861 35.4805Z"
      fill="#32313B"
    />
    <path
      d="M59.5272 35.4805C59.5272 35.4805 57.6436 38.0309 56.5234 38.3744L58.1837 39.8995C58.1837 39.8995 59.7822 38.2314 60.0604 36.0768L59.5272 35.4805Z"
      fill="#32313B"
    />
    <path
      d="M36.7279 18.6324C36.7279 18.6324 38.6045 16.4775 38.8825 15.0876L38.1876 14.7402C38.1876 14.7402 36.6581 16.7559 35.4766 17.4511L36.7279 18.6324Z"
      fill="#32313B"
    />
    <path
      d="M78.4347 18.6324C78.4347 18.6324 80.3114 16.4775 80.5896 15.0876L79.8942 14.7402C79.8942 14.7402 78.3651 16.7559 77.1836 17.4511L78.4347 18.6324Z"
      fill="#32313B"
    />
    <path
      d="M35.4609 50.204L39.1099 49.1612C39.1099 49.1612 38.7451 47.7017 38.2238 47.0762L35.4609 50.204Z"
      fill="#32313B"
    />
    <path
      d="M71.4063 14.1836L70.2944 18.2843C70.2944 18.2843 69.1128 18.2841 68.0703 17.3806L71.4063 14.1836Z"
      fill="#32313B"
    />
    <path
      d="M51.3886 39.6054C56.3214 39.6054 60.3203 35.6066 60.3203 30.6738C60.3203 25.741 56.3214 21.7422 51.3886 21.7422C46.4559 21.7422 42.457 25.741 42.457 30.6738C42.457 35.6066 46.4559 39.6054 51.3886 39.6054Z"
      fill="#F0946C"
      stroke="#32313B"
    />
    <path
      d="M76.0655 61.3795C80.5566 61.3795 84.1974 60.6326 84.1974 59.7113C84.1974 58.7899 80.5566 58.043 76.0655 58.043C71.5744 58.043 67.9336 58.7899 67.9336 59.7113C67.9336 60.6326 71.5744 61.3795 76.0655 61.3795Z"
      fill="#32313B"
    />
    <path
      d="M72.232 42.582C77.1648 42.582 81.1632 46.5809 81.1632 51.5133C81.1632 56.4464 77.1648 60.445 72.232 60.445C67.2988 60.445 63.3008 56.4464 63.3008 51.5133C63.3008 46.5809 67.2988 42.582 72.232 42.582Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M85.4111 35.2734L75.1733 45.5112C74.3589 46.3255 74.3589 47.6456 75.1733 48.4601C75.5808 48.8671 76.114 49.0707 76.648 49.0707C77.1816 49.0707 77.7151 48.8671 78.1222 48.4601L88.3353 38.2468C87.1423 37.4998 86.1378 36.4804 85.4111 35.2734Z"
      fill="#F5D0E0"
      stroke="#32313B"
    />
    <path
      d="M85.4145 35.2734L84.6289 36.2125C84.6289 36.2125 85.4892 38.4112 86.8275 39.2715L87.9747 38.3156L85.4145 35.2734Z"
      fill="#32313B"
    />
    <path
      d="M77.6992 49.2276L80.8624 49.0072L79.9443 46.8242L77.6992 49.2276Z"
      fill="#32313B"
    />
    <path
      d="M84.5625 27.5797L88.4179 28.679L85.2951 25.9863L84.5625 27.5797Z"
      fill="#32313B"
    />
  </svg>
);
