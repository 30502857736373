import { styled } from "@linaria/react";
import { BrandColorNameV4, colorV4 } from "../../styles/colorsV4.styles";
import ComboFontHeading from "../typography/ComboFontHeading";
import Spacing from "../layout/Spacing";
import ReportSectionHeader from "./ReportSectionHeader";
import ReportCard from "./ReportCard";
import { ReportBaseSectionStyle } from "./ReportBaseStyles";
import ReportSection from "./ReportSection";
import NumberedListCardGrid from "./NumberedListCardGrid";
import { css } from "linaria";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { PropsWithChildren } from "react";
import TinesKey from "../misc/TinesKey";

const ReportKeyFindingsSectionContainer = styled.section<{
  color?: BrandColorNameV4;
}>`
  ${ReportBaseSectionStyle};
  background-color: ${p => colorV4(p.color ?? "purple", 50)};
  h3 {
    color: ${p => colorV4(p.color ?? "purple")};
  }
`;

const ReportKeyFindingsSection = (
  props: PropsWithChildren<{
    openingParagraph: string;
    color?: BrandColorNameV4;
  }>
) => {
  return (
    <ReportSection id="key-findings" fullWidthWhenSmall>
      <ReportKeyFindingsSectionContainer color={props.color}>
        <ReportSectionHeader icon={<TinesKey color={props.color} />}>
          <ComboFontHeading snippets={[[<>Key findings</>]]} />
        </ReportSectionHeader>
        <Spacing size="gap" />
        <ReportCard
          centered
          className={css`
            font-size: 1.4rem;
            padding-top: 2em;
            padding-bottom: 2em;
            ${fromTablet} {
              padding-top: 2.5em;
              padding-bottom: 2.5em;
            }
          `}
        >
          <h3>{props.openingParagraph}</h3>
        </ReportCard>
        <Spacing size="gap" />
        <NumberedListCardGrid accentColor={colorV4(props.color ?? "purple")}>
          {props.children}
        </NumberedListCardGrid>
      </ReportKeyFindingsSectionContainer>
    </ReportSection>
  );
};

export default ReportKeyFindingsSection;
