import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { Radar } from "./Radar";
import { EscherWindow } from "./EscherWindow";
import { Flower } from "./Flower";
import { FlowerVine } from "./FlowerVine";
import { LeftSidebarContents } from "./LeftSidebarContents";
import { BrowserTab } from "./BrowserTab";
import TinesLogoIcon from "../../misc/TinesLogoIcon";
import { BrowserTrafficLights } from "./BrowserTrafficLights";
import { AppTopBarStoryRunToggle } from "./AppTopBarStoryRunToggle";
import { AppTopBarRightButtons } from "./AppTopBarRightButtons";
import { DashboardContents } from "./DashboardContents";
import TextPlaceholder from "./TextPlaceholder";
import { StickyNoteBottomRight } from "./StickyNoteBottomRight";
import { AppTopBarUserIconList } from "./AppTopBarUserIconList";
import { AppRightSidebarContents } from "./AppRightSidebarContents";
import CasesCardStack from "./CasesCardStack";
import { AppStoryboard } from "./AppStoryboard";
import FormulasCapsule, { FormulasCapsuleShadow } from "./FormulasCapsule";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopSm,
  fromTablet,
  fromTabletLg,
  onlyPhones,
  uptoDesktop,
  uptoTablet,
  uptoTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { useRef, useState } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { useViewportSize } from "../../../utils/window.utils";
import { cx } from "linaria";
import ClockAndBellAndCursors from "./ClockAndBellAndCursors";

const mobileWidth = 480;
const mobileHeight = 530;

export const homeHeroIllustrationMobileRatio = `${mobileWidth} / ${mobileHeight}`;

const IllustrationContainer = styled.div`
  position: relative;
  ${uptoTablet} {
    max-width: 100%;
    aspect-ratio: ${homeHeroIllustrationMobileRatio};
  }
`;

const IllustrationTransformLayer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const IllustrationMobilePositioner = styled.div`
  ${onlyPhones} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${mobileWidth}px;
    height: ${mobileHeight}px;
  }
`;

const Illustration = styled.div`
  position: relative;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 72px;
  user-select: none;
  max-width: 708px;
  margin-left: auto;
  margin-right: auto;
  ${uptoTablet} {
    width: ${mobileWidth}px;
    height: ${mobileHeight}px;
    transform: scale(1.3);
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    .ready & {
      opacity: 1;
      transform: scale(1);
      pointer-events: auto;
    }
  }
  ${uptoDesktop} {
    padding-left: 40px;
  }
`;

const BrowserPositioner = styled.div`
  position: relative;
`;

const Browser = styled.div`
  position: relative;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple800};
  border-bottom-width: 2px;
  padding: 0px 5px 5px;
  border-radius: 12px 12px 16px 16px;
  z-index: 3;
`;

const BrowserTitleBar = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 16px 0;
  svg {
    display: block;
    &:nth-child(2) {
      margin-top: 6px;
    }
  }
  > * + * {
    margin-left: 12px;
  }
`;

const BrowserViewport = styled.div`
  border: 1px solid ${colors.purple};
  height: 372px;
  ${fromTablet} {
    min-height: 325px;
    height: 450px;
    max-height: calc(var(--vh, 100vh) - 400px);
  }
  border-radius: 8px 8px 12px 12px;
  background-color: ${colors.white};
`;

const App = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  color: ${colors.purple800};
`;

const AppTopBar = styled.div`
  border-bottom: 1px solid ${colors.purple50};
  padding: 10px 16px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  svg {
    display: block;
  }
`;

const AppTopBarStart = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
`;

const AppTopBarCenter = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.purple50};
  padding: 0 0.5em;
`;

const AppTopBarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * + * {
    margin-left: 8px;
  }
  ${onlyPhones} {
    > svg {
      display: none;
    }
  }
`;

const AppBody = styled.div`
  display: grid;
  align-items: stretch;
  overflow: hidden;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  ${fromTablet} {
    grid-template-columns: auto minmax(auto, 66.46fr) minmax(100px, 26.46fr);
  }
`;

const AppLeftSidebar = styled.div`
  border-right: 1px solid ${colors.purple50};
  padding-top: 2px;
`;

const AppRightSidebar = styled.div`
  ${onlyPhones} {
    display: none;
  }
  color: ${colors.purple50};
  border-left: 1px solid ${colors.purple50};
  padding: 16px;
`;

const CasesCardStackPositioner = styled.div`
  position: absolute;
  bottom: 24px;
  ${onlyPhones} {
    left: 50%;
    transform: translateX(-50%);
    width: 340px;
    z-index: 4;
  }
  ${fromTablet} {
    left: 64px;
  }
`;

const Dashboard = styled.div`
  position: absolute;
  bottom: 72px;
  right: 0;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple200};
  padding: 10px;
  border-radius: 12px;
  z-index: 3;
  overflow: hidden;
  ${fromTablet} {
    z-index: 6;
    bottom: 0;
    right: 0;
    border-radius: 9px;
    padding: 18px;
    max-width: 50%;
    width: 320px;
    height: 180px;
  }
  ${fromTabletLg} {
    height: 190px;
  }
  ${fromDesktop} {
    height: auto;
  }
  ${fromDesktopSm} {
    padding: 24px;
    height: 170px;
  }
  ${fromDesktopMd} {
    padding: 24px;
    height: 180px;
  }

  ${fromTablet} {
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 48px;
      background-image: linear-gradient(
        to top,
        ${withOpacity(colors.purple100, 0.8)},
        ${withOpacity(colors.purple100, 0)}
      );
    }
  }
  ${fromDesktop} {
    &:after {
      display: none;
    }
  }
  ${fromDesktopSm} {
    &:after {
      display: block;
    }
  }
`;

export const getHomeIllustrationScalar = () =>
  parseFloat(
    `${
      document
        .getElementById("IllustrationTransformLayer")
        ?.style.transform.match(/scale\(([^)]+)\)/)?.[1]
    }`
  ) || 1;

export const HomeHeroIllustration = () => {
  const [ready, setReady] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const illustrationRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const { vw } = useViewportSize();
  useOnMount(() => {
    const observer = new ResizeObserver(entries => {
      if (!illustrationRef.current) return;
      const widthScalar = entries[0].contentRect.width / mobileWidth;
      const heightScalar = (entries[0].contentRect.height - 24) / mobileHeight;
      setScale(Math.min(Math.min(widthScalar, heightScalar), 1));
      setReady(true);
    });
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
  });
  return (
    <IllustrationContainer ref={containerRef} className={cx(ready && "ready")}>
      <IllustrationTransformLayer
        id="IllustrationTransformLayer"
        style={{
          transform: vw < 768 ? `scale(${scale})` : undefined,
        }}
      >
        <IllustrationMobilePositioner>
          <Illustration ref={illustrationRef}>
            <BrowserPositioner>
              <Browser>
                <BrowserTitleBar>
                  <BrowserTrafficLights />
                  <BrowserTab />
                </BrowserTitleBar>
                <BrowserViewport>
                  <App>
                    <AppTopBar>
                      <AppTopBarStart>
                        <TinesLogoIcon size={14} />
                        <AppTopBarStoryRunToggle />
                      </AppTopBarStart>
                      <AppTopBarCenter>
                        <TextPlaceholder opacity={0.5}>
                          Security workflow with Tines
                        </TextPlaceholder>
                      </AppTopBarCenter>
                      <AppTopBarRight>
                        <AppTopBarUserIconList />
                        <AppTopBarRightButtons />
                      </AppTopBarRight>
                    </AppTopBar>
                    <AppBody>
                      <AppLeftSidebar>
                        <LeftSidebarContents />
                      </AppLeftSidebar>
                      <AppStoryboard />
                      <AppRightSidebar>
                        <AppRightSidebarContents />
                      </AppRightSidebar>
                    </AppBody>
                  </App>
                </BrowserViewport>
              </Browser>
              <FormulasCapsules>
                <FormulasCapsule
                  details={
                    <>
                      <p>
                        Converts text into a SHA-256 hash using a hash message
                        authentication code (HMAC).
                      </p>
                      <p>
                        Syntax: <code>HMAC_SHA256(text, secret)</code>
                      </p>
                    </>
                  }
                >
                  HMAC_SHA256()
                </FormulasCapsule>
                <FormulasCapsule
                  details={
                    <>
                      <p>
                        Creates a ZIP archive containing a given file or files.
                      </p>
                      <p>
                        Syntax: <code>ZIP(files, [filename], [password])</code>
                      </p>
                    </>
                  }
                >
                  ZIP()
                </FormulasCapsule>
                <FormulasCapsule
                  details={
                    <>
                      <p>Parses CSV-formatted text into an array of arrays.</p>
                      <p>
                        Syntax: <code>CSV_PARSE(text)</code>
                      </p>
                    </>
                  }
                >
                  CSV_PARSE()
                </FormulasCapsule>
                <FormulasCapsule
                  details={
                    <>
                      <p>Creates a custom, reusable function.</p>
                      <p>
                        Syntax:{" "}
                        <code>LAMBDA([param1, param2, …,] calculation)</code>
                      </p>
                    </>
                  }
                >
                  LAMBDA()
                </FormulasCapsule>
                <FormulasCapsule
                  details={
                    <>
                      <p>
                        Divides the input text into an array using the delimiter
                        as a separator.
                      </p>
                      <p>
                        Syntax: <code>SPLIT(text, delimiter)</code>
                      </p>
                    </>
                  }
                >
                  SPLIT()
                </FormulasCapsule>
              </FormulasCapsules>
              <EscherWindowPositioner>
                <EscherWindow />
              </EscherWindowPositioner>
              <RadarPositioner>
                <Radar />
              </RadarPositioner>
              <FlowerVinePositioner>
                <FlowerVine />
              </FlowerVinePositioner>
              <FlowerPositioner>
                <Flower />
              </FlowerPositioner>
              <StickyNoteBottomRightPositioner>
                <StickyNoteBottomRight />
              </StickyNoteBottomRightPositioner>
              <ClockAndBellAndCursors />
            </BrowserPositioner>
            <CasesCardStackPositioner>
              <CasesCardStack />
            </CasesCardStackPositioner>
            <Dashboard>
              <DashboardContents />
            </Dashboard>
          </Illustration>
        </IllustrationMobilePositioner>
      </IllustrationTransformLayer>
    </IllustrationContainer>
  );
};

const FormulasCapsules = styled.div`
  > * {
    position: absolute;
    &:nth-child(1) {
      top: 12.5%;
      left: -64px;
      ${uptoTabletLg} {
        left: -42px;
      }
      ${FormulasCapsuleShadow} {
        transform: translateX(5px);
      }
    }
    &:nth-child(2) {
      top: calc(12.5% + 40px);
      left: -80px;
      ${uptoTabletLg} {
        left: -32px;
      }
      ${FormulasCapsuleShadow} {
        transform: translateX(4px);
      }
    }
    &:nth-child(3) {
      bottom: 33%;
      left: -42px;
      ${FormulasCapsuleShadow} {
        transform: translateX(3px);
      }
      ${onlyPhones} {
        display: none;
      }
    }
    &:nth-child(4) {
      bottom: calc(100% + 18px);
      right: 20%;
      ${FormulasCapsuleShadow} {
        transform: translateX(-2px);
      }
      ${onlyPhones} {
        display: none;
      }
    }
    &:nth-child(5) {
      bottom: calc(55% - 34px);
      left: calc(100% - 7px);
      ${FormulasCapsuleShadow} {
        transform: translateX(-4px);
      }
      ${uptoTabletLg} {
        left: calc(100% - 16px);
      }
    }
  }
`;

const EscherWindowPositioner = styled.div`
  position: absolute;
  right: calc(100% - 32px);
  bottom: -38px;
  ${onlyPhones} {
    transform: scale(0.7);
    right: calc(100% - 38px);
    bottom: -42px;
  }
`;

const RadarPositioner = styled.div`
  position: absolute;
  right: 100%;
  top: 38%;
  transform-origin: right;
  ${onlyPhones} {
    transform: scale(0.9);
  }
`;

const FlowerPositioner = styled.div`
  position: absolute;
  left: calc(100% - 12px);
  bottom: 55%;
  z-index: 5;
`;

const FlowerVinePositioner = styled.div`
  position: absolute;
  left: calc(100% - 56px);
  bottom: calc(55% - 62px);
  z-index: 0;
`;

const StickyNoteBottomRightPositioner = styled.div`
  position: absolute;
  right: -12px;
  bottom: 29%;
  z-index: 10;
`;
