import { styled } from "@linaria/react";
import { DatoCmsSolutionPage } from "../../../../graphql-types";
import { uptoDesktop } from "../../../styles/breakpointsAndMediaQueries.styles";
import { PageHeroProductFrameClouds } from "./PageHeroProductFrameClouds";
import { PageHeroProductFrameWires } from "./PageHeroProductFrameWires";
import { SolutionPageIllustrationType } from "./SolutionPageIllustrationType.enum";

type Props = {
  page: DatoCmsSolutionPage;
};

const Wrap = styled.div`
  ${uptoDesktop} {
    display: none;
  }
`;

const defs = {
  [SolutionPageIllustrationType.Clouds]: PageHeroProductFrameClouds,
  // [SolutionPageIllustrationType.Portals]: PageHeroHeadlinePortals,
  [SolutionPageIllustrationType.ChartAndWires]: PageHeroProductFrameWires,
};

const PageHeroProductFrameDecorationDispatcher = (props: Props) => {
  const Decoration = defs[props.page.pageIllustrationType as keyof typeof defs];
  return <Wrap>{Decoration ? <Decoration /> : null}</Wrap>;
};

export default PageHeroProductFrameDecorationDispatcher;
