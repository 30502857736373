import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import { PageComponent } from "../types/gatsby.types";
import { CSSProperties, styled } from "@linaria/react";
import Page from "./Page.template";
import LibraryStoryTable from "../components/library/LibraryStoryTable";
import { LayoutWithSidebar } from "../components/layout/LayoutWithSidebar";
import LibrarySidebar from "../components/library/LibrarySidebar";
import WithLibraryState from "../components/library/WithLibraryState";
import LibraryPageHero from "../components/library/LibraryPageHero";
import LibraryEmptyState from "../components/library/LibraryEmptyState";
import FileArchiveIcon from "../components/illustrations/spots/FileArchiveIcon";
import { getBrandColorTheme, withOpacity } from "../styles/colors.styles";
import { onlyPhones } from "../styles/breakpointsAndMediaQueries.styles";
import { useRankedStoriesAndTools } from "../utils/library.utils";
import seoImage from "../../static/images/og/tines-library.png";
import {
  AdminToolBarContent,
  AdminToolbarButton,
  AdminToolbarSeparator,
  EditInDatoCMSButton,
} from "../components/site/AdminToolbar";
import { useSiteContext } from "../context/site.context";
import { LoadingStateBanner } from "../components/utilities/LoadingIndicatorBanner";

const BodyContent = styled.div`
  ${onlyPhones} {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const LibraryDirectoryStoriesPage: PageComponent = props => {
  const siteContext = useSiteContext();
  const theme = getBrandColorTheme("purple");

  const { awaitingData, error, stories, tools } = useRankedStoriesAndTools();

  return (
    <Page {...props}>
      <SEO
        title="Stories | Tines library"
        description="Ready-made automation workflows that you can use in one click. Use these Stories as a starting point for fully custom playbooks or inspiration for your use cases."
        image={seoImage}
      />
      {!siteContext.library.storyToRenderInLightbox && (
        <AdminToolBarContent>
          <AdminToolbarButton
            onClick={() => {
              window.open(process.env.GATSBY_LIBRARY_TENANT_URL);
            }}
          >
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M4.05685 0C2.755 0 1.60392 0.844762 1.21431 2.08611L0.142822 5.5L1.21431 8.91389C1.60392 10.1552 2.755 11 4.05685 11H6.94308C8.24493 11 9.39601 10.1552 9.78562 8.91389L10.8571 5.5L9.78562 2.08611C9.39601 0.844762 8.24493 0 6.94308 0H4.05685ZM6.94312 0.858118C7.86973 0.858118 8.68902 1.45938 8.96632 2.34292L9.22902 3.1799C8.70055 2.74365 8.03134 2.49811 7.33189 2.49806L3.66023 2.49796C2.96549 2.49801 2.30058 2.74027 1.77379 3.17103L2.0337 2.34292C2.311 1.45938 3.13029 0.858118 4.0569 0.858118H6.94312ZM1.97199 4.19327L1.30481 5.07096L9.68723 5.07096L9.02013 4.19337C8.61922 3.66596 7.9946 3.35622 7.33183 3.35617H3.64023C2.98494 3.3624 2.36885 3.67119 1.97199 4.19327ZM9.68587 5.92908L9.02007 6.80587C8.61913 7.33387 7.99406 7.64396 7.3308 7.64391L3.66122 7.64382C2.99796 7.64387 2.37288 7.33377 1.97194 6.80577L1.30622 5.92908L9.68587 5.92908ZM2.03364 8.65708C2.31095 9.54062 3.13024 10.1419 4.05685 10.1419H6.94307C7.86968 10.1419 8.68896 9.54062 8.96627 8.65708L9.22916 7.81949C8.70045 8.25626 8.03073 8.50209 7.33074 8.50203L3.66128 8.50194C2.96596 8.50199 2.30051 8.25943 1.77346 7.82811L2.03364 8.65708Z" />
            </svg>
            <span>Go to library tenant</span>
          </AdminToolbarButton>
          <AdminToolbarSeparator />
          <EditInDatoCMSButton
            shortcut="command+control+shift+e"
            itemType="story"
          />
        </AdminToolBarContent>
      )}
      <WithLibraryState
        awaitingData={awaitingData}
        error={error}
        entries={stories}
        entryType="story"
        tools={tools}
        children={p => {
          return (
            <LayoutWithSidebar
              noPageMarginOnPhones
              style={
                {
                  "--ac10": withOpacity(theme.c500, 0.1),
                  "--ac20": withOpacity(theme.c500, 0.2),
                  "--ac30": withOpacity(theme.c500, 0.3),
                  "--ac": theme.c500,
                  "--ac100": theme.c100,
                  "--ac10020": withOpacity(theme.c100, 0.2),
                  "--ac10060": withOpacity(theme.c100, 0.6),
                  "--ac300": theme.c300,
                  "--ac700": theme.c700,
                } as CSSProperties
              }
            >
              <LibrarySidebar state={p.state} />
              <BodyContent>
                <LibraryPageHero
                  collection={{
                    tagline: "Stories",
                    description:
                      "Discover pre-built examples of Tines in action to accelerate your story building or fuel your imagination on what to build next.",
                  }}
                  libraryState={p.state}
                  icon={<FileArchiveIcon />}
                  compact
                  totalCount={stories.length}
                />
                <Spacing />
                <LoadingStateBanner
                  loading={p.state.awaitingData}
                  error={p.state.error}
                />
                {p.state.error && p.entries.length === 0 && <Spacing />}
                {!p.state.awaitingData &&
                  (p.entries.length > 0 ? (
                    <LibraryStoryTable
                      stories={p.entries}
                      tools={tools}
                      state={p.state}
                    />
                  ) : (
                    <LibraryEmptyState state={p.state} />
                  ))}
                <Spacing size="lg" />
              </BodyContent>
            </LayoutWithSidebar>
          );
        }}
      />
      <Spacing size="xl" />
    </Page>
  );
};

export default LibraryDirectoryStoriesPage;
