import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import { Separator } from "../../utilities/Hr";
import { zIndex } from "../../../styles/zIndexes.styles";
import LinkToAnchor from "../../basic/LinkToAnchor";
import { CSSProperties, PropsWithChildren } from "react";
import { serif } from "../../../styles/fonts.styles";
import {
  DocumentIDMap,
  recordDocumentEvent,
} from "../../../utils/documents.utils";
import { Paths } from "../../../utils/pathBuilders.utils";
import {
  fromReportV2DesktopDesign,
  inReportV2MobileDesign,
} from "../components/reportsV2.helpers";
import { cx } from "linaria";
import { hideScrollbarsCSS } from "../../../utils/scroll.utils";
import { fromDesktopMd } from "../../../styles/breakpointsAndMediaQueries.styles";
import { shareCisoPerspectivesAIAccessPage } from "./cisoPerspectives.utils";
import AllCaps from "../../typography/AllCaps";

const NavAside = styled.aside`
  color: ${colors.dark500};
  transition: top 0.25s, max-height 0.25s;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  ${inReportV2MobileDesign} {
    display: none;
  }
  z-index: ${zIndex("PageSidebar")};
  position: sticky;
  background-color: ${colors.white};
  border-radius: 0.8rem;
  padding: 1.5em 1.5em 1em 1.5em;
  top: calc(var(--pageTopFixedElementsHeight, 0px) + 1em);
  max-height: calc(100vh - var(--pageTopFixedElementsHeight, 0px) - 1em);
  overflow: auto;
`;

const Header = styled.header`
  padding-bottom: 0.5em;
  a {
    text-decoration: none;
  }
`;

const LinkToTopTextLabel = styled.div`
  margin-top: 0.5em;
  span {
    display: block;
    font-family: ${serif};
    font-weight: 400;
    font-size: 1.8rem;
    letter-spacing: -0.01em;
    line-height: 1.2;
    ${fromDesktopMd} {
      font-size: 2.2rem;
    }
  }
`;

const LinkList = styled.div`
  a {
    display: flex;
    align-items: flex-start;
    appearance: none;
    text-decoration: none;
    padding: 0.4em 0.4em 0.4em 0;
    border: 0;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.2;
    &:hover,
    &.current {
      color: var(--HighlightColor, ${colors.purple});
    }
    > span {
      flex: 1 1 auto;
      ${inReportV2MobileDesign} {
        display: none;
      }
    }
  }
`;

const Footer = styled.footer`
  a,
  button {
    appearance: none;
    text-decoration: none;
    display: flex;
    width: 100%;
    padding: 0.5em 0.5em 0.5em 0.325em;
    border: 0;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;
    &:hover {
      color: ${colors.purple};
    }
    svg {
      flex: 0 0 auto;
      margin-right: 0.675em;
    }
  }
`;

const ColorDotDiv = styled.div<{ color: string }>`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.color};
  width: 1.5em;
  height: 1.25em;
  margin-right: 0.375em;
  span {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    display: block;
    background-color: currentColor;
  }
  &.outlined {
    span {
      background-color: transparent;
      border: 1px solid currentColor;
    }
  }
`;

const ColorDot = (props: { color: string; outlined?: boolean }) => (
  <ColorDotDiv className={cx(props.outlined && "outlined")} color={props.color}>
    <span></span>
  </ColorDotDiv>
);

const ChapterList = styled.ol`
  ${inReportV2MobileDesign} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  padding: 0;
  margin: 0;
  list-style: none;
  counter-reset: chapter-list;
  li {
    counter-increment: chapter-list;
  }
`;

const ChapterListItem = (
  props: PropsWithChildren<{ color: string; highlightColor?: string }>
) => (
  <li
    style={
      {
        "--ac": props.color,
        "--HighlightColor": props.highlightColor ?? props.color,
      } as CSSProperties
    }
  >
    {props.children}
  </li>
);

const ChapterNumberWrap = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.25em;
  ${inReportV2MobileDesign} {
    margin-left: 0.125em;
    margin-right: 0.125em;
  }
  ${fromReportV2DesktopDesign} {
    margin-right: 0.375em;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
    width: 1.25em;
    height: 1.25em;
    background-color: var(--ac);
    color: ${colors.white};
    &:before {
      font-size: 80%;
      content: counter(chapter-list);
    }
  }
`;

const ChapterNumber = () => (
  <ChapterNumberWrap>
    <span></span>
  </ChapterNumberWrap>
);

export const CisoAINav = () => {
  const handleDownloadButtonClick = () => {
    recordDocumentEvent({
      type: "download",
      id: DocumentIDMap.cisoPerspectiveAI2023,
    });
  };

  return (
    <NavAside className={hideScrollbarsCSS}>
      <Header>
        <LinkToAnchor to="#">
          <AllCaps as="p">CISO perspectives</AllCaps>
          <LinkToTopTextLabel>
            <span>Separating the reality of AI from the hype</span>
          </LinkToTopTextLabel>
        </LinkToAnchor>
      </Header>
      <Separator margin="1em 0" />
      <LinkList>
        <LinkToAnchor to="#introduction">
          <ColorDot color={colors.yellow300} />
          <span>Introduction</span>
        </LinkToAnchor>
        <LinkToAnchor to="#key-findings">
          <ColorDot color={colors.purple} />
          <span>Key findings</span>
        </LinkToAnchor>

        <ChapterList>
          <ChapterListItem color={colors.pink}>
            <LinkToAnchor to="#satisfaction-with-ai-adoption">
              <ChapterNumber />
              <span>Satisfaction with AI adoption</span>
            </LinkToAnchor>
          </ChapterListItem>
          <ChapterListItem color={colors.orange}>
            <LinkToAnchor to="#challenges-in-ai-adoption">
              <ChapterNumber />
              <span>Challenges in AI adoption</span>
            </LinkToAnchor>
          </ChapterListItem>
          <ChapterListItem color={colors.green}>
            <LinkToAnchor to="#opportunities-in-ai-adoption">
              <ChapterNumber />
              <span>Opportunities in AI adoption</span>
            </LinkToAnchor>
          </ChapterListItem>
        </ChapterList>

        <LinkToAnchor to="#summary">
          <ColorDot color={colors.purple} />
          <span>Summary</span>
        </LinkToAnchor>
      </LinkList>
      <Separator margin="1em 0" />
      <Footer>
        <button onClick={shareCisoPerspectivesAIAccessPage}>
          <ShareIcon />
          <span>Share report</span>
        </button>
        <a
          download
          onClick={handleDownloadButtonClick}
          href={Paths.cisoPerspectivesAI.pdf()}
        >
          <DownloadIcon />
          <span>Download PDF</span>
        </a>
      </Footer>
    </NavAside>
  );
};

const ShareIcon = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      d="M1.5 7V9.5C1.5 10.6046 2.39543 11.5 3.5 11.5H9.5C10.6046 11.5 11.5 10.6046 11.5 9.5V7"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M6.5 8V1M6.5 1L3.5 4M6.5 1L9.5 4"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);

const DownloadIcon = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      d="M1.5 11.5H11.5"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M6.5 2L6.5 9M6.5 9L9.5 6M6.5 9L3.5 6"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);
