import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import { LogoJamf32 } from "../thirdPartyLogos/LogoJamf32";
import LogoGitLab from "../thirdPartyLogos/LogoGitLab";
import { serif } from "../../styles/fonts.styles";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import LogoMcKesson from "../thirdPartyLogos/LogoMcKesson";
import { css, cx } from "linaria";
import LogoMars20h from "../thirdPartyLogos/LogoMars20h";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  mediaFromTo,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${mediaFromTo("tablet", "tabletLg")} {
    display: block;
    columns: 2;
    > * {
      break-inside: avoid;
      margin-bottom: 1em;
    }
  }
  ${fromTabletLg} {
    display: grid;
    grid-gap: ${rSize("gap")};
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: stretch;
    grid-template-areas:
      "header header c6 ."
      ". c3 c6 c9"
      "c1 c3 c7 c10"
      "c1 c4 c7 c11"
      "c2 c5 c7 c11"
      "c2 c5 c8 c11"
      ".  c5 . c11";
    > * {
      &:nth-child(2) {
        grid-area: c1;
      }
      &:nth-child(3) {
        grid-area: c2;
      }
      &:nth-child(4) {
        grid-area: c3;
      }
      &:nth-child(5) {
        grid-area: c4;
      }
      &:nth-child(6) {
        grid-area: c5;
      }
      &:nth-child(7) {
        grid-area: c6;
      }
      &:nth-child(8) {
        grid-area: c7;
      }
      &:nth-child(9) {
        grid-area: c8;
      }
      &:nth-child(10) {
        grid-area: c9;
      }
      &:nth-child(11) {
        grid-area: c10;
      }
      &:nth-child(12) {
        grid-area: c11;
        align-self: start;
      }
    }
  }
  ${mediaFromTo("phoneLg", "desktop")} {
    grid-gap: 0.75em;
  }
`;

const Header = styled.header`
  padding-bottom: ${rSize("gap")};
  text-align: center;
  ${fromTablet} {
    text-align: left;
  }
  ${fromTabletLg} {
    grid-area: header;
  }
`;

const Quote = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-rows: minmax(auto, 1fr) auto;
  background-color: ${colors.purple50};
  color: ${colors.purple700};
  padding: ${rSize("gap")};
  border: 1px solid ${colors.purple200};
  border-radius: ${rSize("radius")};
`;

const QuoteBody = styled.div`
  font-family: ${serif};
  p {
    line-height: inherit;
    font-weight: inherit;
  }
  &.md {
    font-size: 1.8rem;
    line-height: 1.25;
    font-weight: 400;
  }
  &.lg {
    font-size: 2.4rem;
    line-height: 1.1;
    font-weight: 300;
    letter-spacing: -0.015em;
    ${fromDesktopMd} {
      font-size: 3.2rem;
    }
  }
`;

const QuoteFooter = styled.footer`
  font-size: 1.4rem;
  svg {
    display: block;
  }
`;

const NumberCard = styled.div`
  background-color: ${colors.purple};
  padding: ${rSize("gap")};
  border: 1px solid ${colors.purple600};
  border-radius: ${rSize("radius")};
  color: ${colors.white};
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-gap: 0.75em;
  align-items: center;
  font-weight: 500;
  strong {
    display: block;
    font-family: ${serif};
    font-size: 4.2rem;
    font-weight: 300;
    letter-spacing: -0.015em;
    ${fromDesktopMd} {
      font-size: 5.4rem;
    }
  }
  ${mediaFromTo("phoneLg", "desktopMd")} {
    display: block;
    span {
      display: block;
    }
  }
`;

export const EnterprisePageSocialProofSection = () => {
  return (
    <PageSection>
      <Grid>
        <Header>
          <SectionHeading2 lighter blockSpan>
            <span>Enterprise scalability</span> <span>and resiliency</span>
          </SectionHeading2>
        </Header>

        <Quote
          className={css`
            background-color: ${colors.purple};
            color: ${colors.white};
            --ac: ${colors.purple};
          `}
        >
          <QuoteBody className="md">
            <p>
              <ArrowIcon inverted /> Tines handles in 20 seconds what our XSOAR
              handles on even the most anomalous high day. Pretty impressive
              scaling.
            </p>
          </QuoteBody>
          <QuoteFooter>
            <p>Tines vs. existing XSOAR performance at a Fortune 500 company</p>
          </QuoteFooter>
        </Quote>

        <Quote>
          <QuoteBody className="lg">
            <p>
              <ArrowIcon /> 1K+ hours saved per year
            </p>
          </QuoteBody>
          <QuoteFooter>
            <LogoGitLab />
          </QuoteFooter>
        </Quote>

        <Quote
          className={css`
            background-color: ${colors.orange50};
            color: ${colors.orange700};
            border-color: ${colors.orange200};
          `}
        >
          <QuoteBody className="lg">
            <p>
              <ArrowIcon /> 20x faster time to build a workflow compared to
              Python
            </p>
          </QuoteBody>
          <QuoteFooter>
            <LogoJamf32 />
          </QuoteFooter>
        </Quote>

        <NumberCard
          className={css`
            background-color: ${colors.orange};
            border-color: ${colors.orange600};
          `}
        >
          <strong>+4K</strong>{" "}
          <span>average action runs per second for a single story</span>
        </NumberCard>

        <Quote
          className={css`
            background-color: ${colors.green50};
            color: ${colors.green700};
            border-color: ${colors.green200};
          `}
        >
          <QuoteBody className="md">
            <p>
              <QuoteIcon /> Previously, it would take them 20–30 minutes to get
              data from internal and external tools and put together a
              portfolio, but with Tines, this was done in seconds.
            </p>
          </QuoteBody>
          <QuoteFooter>
            <LogoOakRidge />
          </QuoteFooter>
        </Quote>

        <Quote
          className={css`
            background-color: ${colors.pink50};
            color: ${colors.pink700};
            border-color: ${colors.pink200};
          `}
        >
          <QuoteBody className="md">
            <p>
              <QuoteIcon /> Beyond the product and product teams’ agility and
              speed, the level of support from Tines is the benchmark we hold
              all of our vendors to.
            </p>
          </QuoteBody>
          <QuoteFooter>
            <LogoSnowflake />
          </QuoteFooter>
        </Quote>

        <Quote>
          <QuoteBody className="lg">
            <p>
              <ArrowIcon /> 1.5 analysts per week freed to tackle other work
            </p>
          </QuoteBody>
          <QuoteFooter>
            <LogoMcKesson />
          </QuoteFooter>
        </Quote>

        <NumberCard
          className={css`
            background-color: ${colors.green};
            border-color: ${colors.green600};
          `}
        >
          <strong>20K</strong>{" "}
          <span>
            and counting
            <br /> tenants worldwide
          </span>
        </NumberCard>

        <Quote
          className={css`
            background-color: ${colors.green50};
            color: ${colors.green700};
            border-color: ${colors.green200};
          `}
        >
          <QuoteBody className="lg">
            <p>
              <ArrowIcon /> 91.4% faster time to correlate alerts
            </p>
          </QuoteBody>
          <QuoteFooter>
            <LogoSnowflake />
          </QuoteFooter>
        </Quote>

        <NumberCard
          className={css`
            background-color: ${colors.pink};
            border-color: ${colors.pink600};
          `}
        >
          <strong>26K</strong>{" "}
          <span>
            and counting
            <br /> builders
          </span>
        </NumberCard>

        <Quote
          className={css`
            background-color: ${colors.pink50};
            color: ${colors.pink700};
            border-color: ${colors.pink200};
          `}
        >
          <QuoteBody className="md">
            <p>
              <QuoteIcon /> Tines gives us an opportunity to bring automation
              into areas of the organization that just don’t have developers to
              implement automation in the more traditional [code] way.
            </p>
          </QuoteBody>
          <QuoteFooter>
            <LogoMars20h />
          </QuoteFooter>
        </Quote>
      </Grid>
    </PageSection>
  );
};

const IconWrap = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.1em;
  height: 1.1em;
  background-color: currentColor;
  border-radius: 50%;
  vertical-align: middle;
  transform: translateY(-0.1em);
  position: relative;
  margin-right: 0.075em;
  svg {
    width: 45%;
    height: auto;
    color: ${colors.white};
    display: block;
  }
  &.inverted {
    svg {
      color: var(--ac);
    }
  }
`;

const ArrowIcon = (props: { inverted?: boolean }) => (
  <IconWrap className={cx(props.inverted && "inverted")}>
    <svg width="11" height="12" viewBox="0 0 11 12" fill="currentColor">
      <path d="M4.85592 12V2.4233L1.17436 6.11651L0.265625 5.21942L5.49669 0L10.7161 5.21942L9.83067 6.11651L6.13747 2.4233V12H4.85592Z" />
    </svg>
  </IconWrap>
);
const QuoteIcon = (props: { inverted?: boolean }) => (
  <IconWrap className={cx(props.inverted && "inverted")}>
    <svg width="11" height="12" viewBox="0 0 11 12" fill="currentColor">
      <path d="M5.38385 4.70587H5.0652C4.47342 4.70587 4.08649 5.20661 4.08649 6.18532V8.14275H2.26562V6.11704C2.26562 4.18237 3.28986 3.13538 4.9514 3.13538H5.38385V4.70587ZM8.91178 4.70587H8.59313C8.00135 4.70587 7.61441 5.20661 7.61441 6.18532V8.14275H5.81631V6.11704C5.81631 4.18237 6.84054 3.13538 8.47932 3.13538H8.91178V4.70587Z" />
    </svg>
  </IconWrap>
);
