export const ReportV2LargeChapterNumber1Default = () => (
  <svg
    width="370"
    height="400"
    viewBox="0 0 370 400"
    fill="currentColor"
    fillOpacity={0.5}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.96 360.932L148.937 360.909C163.762 360.573 174.99 359.563 182.661 357.898C190.22 355.922 195.722 352.168 199.275 346.678C202.833 341.179 204.685 332.633 204.685 320.885V75.7368H147.96V66.8541L148.731 66.6727C165.902 62.6324 180.178 57.9267 191.574 52.5651C202.934 46.8833 212.615 39.3695 220.627 30.0225L220.926 29.6733H228.97V320.885C228.97 332.286 230.651 340.863 233.903 346.725C237.149 352.237 241.834 355.957 248.001 357.909C254.307 359.566 263.159 360.573 274.596 360.91L275.566 360.938V370H147.96V360.932ZM247.723 358.87C241.308 356.844 236.412 352.961 233.035 347.221C229.659 341.144 227.97 332.365 227.97 320.885V30.6733H221.386C213.283 40.1276 203.491 47.7247 192.01 53.4648C180.53 58.8672 166.18 63.5943 148.96 67.6462V74.7368H205.685V320.885C205.685 332.702 203.828 341.481 200.114 347.221C196.4 352.961 190.66 356.844 182.894 358.87C175.128 360.559 163.817 361.572 148.96 361.909V369H274.566V361.909C263.086 361.572 254.138 360.559 247.723 358.87Z"
    />
  </svg>
);
