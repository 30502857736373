export const ComputerTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M3.5 3C3.5 2.72386 3.72386 2.5 4 2.5H14C14.2761 2.5 14.5 2.72386 14.5 3V10.5H3.5V3Z"
      stroke="currentColor"
    />
    <rect opacity="0.4" x="5" y="3" width="6" height="6" fill="currentColor" />
    <rect opacity="0.4" x="4" y="12" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="6" y="12" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="7" y="13" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="7" y="11" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="6" y="14" width="3" height="1" fill="currentColor" />
    <path opacity="0.4" d="M12 12H13.6042L14 14H12V12Z" fill="currentColor" />
    <rect
      opacity="0.4"
      x="10"
      y="14"
      width="1"
      height="1"
      fill="currentColor"
    />
    <rect opacity="0.4" x="5" y="13" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="5" y="11" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="4" y="14" width="1" height="1" fill="currentColor" />
    <rect
      opacity="0.4"
      x="10"
      y="12"
      width="1"
      height="1"
      fill="currentColor"
    />
    <rect opacity="0.4" x="8" y="12" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="9" y="13" width="1" height="1" fill="currentColor" />
    <rect opacity="0.4" x="9" y="11" width="1" height="1" fill="currentColor" />
    <path
      d="M1.91308 14.8419L3.36038 10.5H14.6396L16.0869 14.8419C16.1948 15.1657 15.9539 15.5 15.6126 15.5H2.38743C2.04615 15.5 1.80516 15.1657 1.91308 14.8419Z"
      stroke="currentColor"
    />
    <rect x="12" y="8" width="1" height="1" fill="currentColor" />
    <rect x="6" y="4" width="2" height="1" fill="currentColor" />
  </svg>
);
