import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { PropsWithChildren } from "react";
import { getElementBoundingBoxFromPage } from "../../utils/boundingRect.utils";
import { debounce } from "../../utils/debounce.utils";
import { SiteNavThemingConfig } from "./SiteNav";
import { isEqual } from "lodash-es";
import { rangesIntersect } from "../../utils/math.utils";
import { isDevelopment } from "../../environment";
import { font } from "../../styles/fonts.styles";

type Props = SiteNavThemingConfig & {
  asControlPoint?: boolean;
};

const DEBUG = isDevelopment ? false : false;

const className = "SiteNavThemeControllerSection";

type Controller = {
  element: HTMLDivElement;
  nextController: Controller | null;
  config: SiteNavThemingConfig | null;
  height: number;
  isPointController: boolean;
  isVisible: boolean;
  updateBoundingBox: () => ReturnType<typeof getElementBoundingBoxFromPage>;
  boundingBox: ReturnType<typeof getElementBoundingBoxFromPage>;
  dispose: () => void;
};

const offset = 44;

const getAllControllerEntries = () => {
  return Array.from(document.querySelectorAll<HTMLDivElement>(`.${className}`))
    .map(element => {
      if (!element) return null;
      let isVisible = false;
      const observer = new IntersectionObserver(
        entries => {
          isVisible = entries[0].isIntersecting;
        },
        { rootMargin: `-${offset}px` }
      );
      let boundingBox = getElementBoundingBoxFromPage(element);
      observer.observe(element);
      const controller: Controller = {
        element,
        nextController: null,
        get config() {
          try {
            return JSON.parse(
              decodeURIComponent(element.getAttribute("data-config") ?? "")
            ) as SiteNavThemingConfig;
          } catch (e) {
            return null;
          }
        },
        get height() {
          return element.clientHeight;
        },
        get isPointController() {
          return element.clientHeight === 0;
        },
        get isVisible() {
          if (controller.isPointController) {
            if (controller.nextController)
              return rangesIntersect(
                [boundingBox.top, controller.nextController.boundingBox.top],
                [
                  window.scrollY + offset,
                  window.scrollY + offset + window.innerHeight,
                ]
              );
            else return boundingBox.top < window.scrollY + offset;
          } else return isVisible;
        },
        updateBoundingBox() {
          const newBoundingBox = getElementBoundingBoxFromPage(element);
          boundingBox = newBoundingBox;
          return boundingBox;
        },
        get boundingBox() {
          return boundingBox;
        },
        dispose: () => {
          observer.disconnect();
        },
      };
      return controller;
    })
    .filter(i => i) as Controller[];
};

let lastController = null as null | SiteNavThemeController;

export const createSiteNavThemeController = (o: {
  onUpdate: (config: SiteNavThemingConfig | null) => void;
}) => {
  // eslint-disable-next-line no-console
  if (DEBUG) console.log("creating new theme controller");
  let entries = getAllControllerEntries();
  if (entries.length === 0) return null;
  const setupEntries = () => {
    entries.forEach((entry, i) => {
      const next = entries[i + 1];
      if (next) entry.nextController = next;
    });
  };
  setupEntries();
  // eslint-disable-next-line no-console
  if (DEBUG) console.log(entries);
  let currentTheme: SiteNavThemingConfig | null = null;
  const handleScroll = () => {
    const firstVisibleSection = entries.find(s => {
      return s.isVisible;
    });
    const config = firstVisibleSection?.config ?? null;
    if (!isEqual(currentTheme, config)) {
      o.onUpdate(config);
      currentTheme = config;
      if (DEBUG) {
        entries.forEach(e =>
          e.element.classList.toggle("current", e === firstVisibleSection)
        );
      }
    }
  };
  const handleResize = debounce(() => {
    entries.forEach(section => section.updateBoundingBox());
  });
  const dispose = () => {
    o.onUpdate(null);
    entries.forEach(s => s.dispose());
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("scroll", handleScroll);
  };
  const handleRecalc = () => {
    entries.forEach(s => s.dispose());
    entries = getAllControllerEntries();
    setupEntries();
  };
  setTimeout(handleResize);
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
  window.addEventListener("recalcsitenavthemecontrollers", handleRecalc);
  handleScroll();
  setTimeout(handleScroll);
  const s = {
    get entries() {
      return entries;
    },
    dispose,
  };
  if (lastController) lastController.dispose();
  lastController = s;
  return s;
};

type SiteNavThemeController = ReturnType<typeof createSiteNavThemeController>;

export const recalcSiteNavThemeControllers = () => {
  window.dispatchEvent(new Event("recalcsitenavthemecontrollers"));
};

const ControllerWrap = styled.div`
  position: relative;
  &[data-is-control-point="true"] {
    height: 0;
  }
`;

const DebugInfo = styled.div`
  position: absolute;
  color: ${colors.white};
  background: ${withOpacity(colors.black, 0.5)};
  font-size: 10px;
  font-family: ${font("monospace")};
  white-space: nowrap;
  z-index: 1000000000;
  &:before {
    content: attr(data-index);
  }
  .current & {
    background: ${withOpacity(colors.green, 0.9)};
  }
`;

const SiteNavThemeControllerSection = (props: PropsWithChildren<Props>) => {
  const {
    navThemeColor = "neutral",
    colorNavAsDarkTheme = false,
    mobileNavBgColor: mobileBackgroundColor,
  } = props;
  const themingConfig: SiteNavThemingConfig = {
    navThemeColor,
    colorNavAsDarkTheme,
    mobileNavBgColor: mobileBackgroundColor,
  };
  if (props.navLogoColor) {
    themingConfig.navLogoColor = props.navLogoColor;
  }
  return (
    <ControllerWrap
      className={className}
      data-is-control-point={props.asControlPoint}
      data-config={encodeURIComponent(JSON.stringify(themingConfig))}
    >
      {DEBUG && (
        <DebugInfo>
          {props.asControlPoint && <p>[control point]</p>}
          <p>navThemeColor: {navThemeColor}</p>
          <p>colorNavAsDarkTheme: {colorNavAsDarkTheme ? "true" : "false"}</p>
          <p>mobileBackgroundColor: {mobileBackgroundColor}</p>
        </DebugInfo>
      )}
      {props.children}
    </ControllerWrap>
  );
};

export const SiteNavThemeControllerPoint = (props: SiteNavThemingConfig) => {
  return <SiteNavThemeControllerSection {...props} asControlPoint />;
};

export default SiteNavThemeControllerSection;
