export const AutoCelloIcon = () => (
  <svg
    width="69"
    height="108"
    viewBox="0 0 69 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0722 47.1327C13.215 45.8285 15.1309 45.4247 15.8661 45.1753C16.5329 44.9495 17.1548 44.5578 17.6522 43.9903C19.1384 42.2943 18.9687 39.7146 17.2725 38.2282C15.5763 36.7419 12.9964 36.9119 11.5105 38.608C11.013 39.1755 10.7064 39.8435 10.5697 40.5338C10.4192 41.2959 10.2705 43.248 9.12774 44.5522C7.98494 45.8564 6.06897 46.2601 5.33356 46.5096C4.66683 46.7356 4.04494 47.127 3.54767 47.6946C2.06131 49.3906 2.23117 51.9705 3.92718 53.4566C5.6236 54.9432 8.20307 54.7731 9.68943 53.0769C10.1871 52.5091 10.4933 51.8416 10.6298 51.151C10.7805 50.3888 10.9294 48.4368 12.0722 47.1327Z"
      fill="#80405B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8256 43.783C9.85934 42.9733 8.92155 40.7226 9.7323 38.7564C10.5422 36.7903 12.7929 35.8529 14.7593 36.6633L31.5179 43.6239C33.484 44.434 34.4213 46.6849 33.6112 48.6507C32.8007 50.617 30.5502 51.5542 28.5838 50.7442L11.8256 43.783Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.672 47.8407C35.3727 49.7462 35.2067 52.6696 33.3012 54.37C31.3953 56.0707 28.4723 55.9047 26.7716 53.9992L21.1336 47.6819C19.4329 45.7765 19.599 42.853 21.5044 41.1524C23.4101 39.4517 26.3333 39.6177 28.034 41.5234L33.672 47.8407Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.4267 57.4221C10.7654 59.5215 9.33804 61.4982 7.23868 61.8364C5.13891 62.1753 3.16276 60.7479 2.82444 58.6481L1.36656 50.0297C1.02804 47.9305 2.45603 45.9536 4.55499 45.6152C6.65456 45.2771 8.63092 46.7045 8.96903 48.8039L10.4267 57.4221Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.6593 16.7239H35.9795V19.2864H41.6593V16.7239Z"
      fill="#B35980"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.6983 4.31299C42.6983 5.47578 41.756 6.41841 40.5931 6.41841H37.0471C35.8845 6.41841 34.9419 5.47578 34.9419 4.31299C34.9419 3.1504 35.8845 2.20776 37.0471 2.20776H40.5931C41.756 2.20776 42.6983 3.1504 42.6983 4.31299Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.9795 16.7237H38.4705H39.1687H41.6593L40.5798 4.27661L38.8197 4.28509L37.0597 4.27661L35.9795 16.7237Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M67.8525 103.592C67.8525 105.608 57.9822 107.244 45.8061 107.244C33.6306 107.244 23.7595 105.608 23.7595 103.592C23.7595 101.576 33.6306 99.9397 45.8061 99.9397C57.9822 99.9397 67.8525 101.576 67.8525 103.592Z"
      fill="#32313B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.0302 71.3115C64.0302 71.3115 57.1789 69.5147 55.9915 63.7672C56.3446 61.826 57.2625 59.577 58.9879 56.9765C58.9879 56.9765 58.9701 55.7287 58.9879 53.3835C58.9879 53.3835 54.3662 51.5629 53.9457 46.6609C53.2262 38.2693 48.9037 32.3745 38.8193 32.3745C28.7347 32.3745 24.4117 38.2693 23.6921 46.6609C23.2724 51.5631 18.6501 53.3835 18.6501 53.3835C18.658 55.2015 18.6501 56.9765 18.6501 56.9765C20.3752 59.577 21.2938 61.826 21.6471 63.7676C20.4592 69.5145 13.6079 71.3115 13.6079 71.3115V74.9045C13.6079 74.9045 14.2488 75.7302 14.5037 77.3527C14.4855 78.1015 14.4867 78.9528 14.5417 79.8815C14.3859 83.3759 14.2714 90.4088 19.911 96.474C27.334 104.457 36.0875 104.555 38.8193 104.555C41.5506 104.555 50.304 104.457 57.7271 96.474C63.3663 90.4092 63.252 83.3757 63.0967 79.8815C63.151 78.9528 63.1518 78.1016 63.1341 77.3525C63.3894 75.7304 64.0302 74.9047 64.0302 74.9047V71.3115Z"
      fill="#80405B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M63.0498 74.5327C63.1848 72.399 64.0303 71.3113 64.0303 71.3113C64.0303 71.3113 49.6037 67.5293 58.9879 53.3835C58.9879 53.3835 54.3662 51.5629 53.9457 46.6609C53.2262 38.2693 48.9037 32.3745 38.8193 32.3745C28.7347 32.3745 24.4117 38.2693 23.6921 46.6609C23.2724 51.5631 18.6501 53.3835 18.6501 53.3835C28.0342 67.5295 13.6079 71.3113 13.6079 71.3113C13.6079 71.3113 14.4538 72.399 14.5881 74.5327C14.72 76.6117 13.0198 85.4694 19.9111 92.8804C27.334 100.864 36.0875 100.962 38.8193 100.962C41.5506 100.962 50.304 100.864 57.7271 92.8804C64.6182 85.4696 62.9186 76.6117 63.0498 74.5327Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.5827 103.374C40.5827 104.619 39.7934 106.896 38.8197 106.896C37.8453 106.896 37.0564 104.619 37.0564 103.374C37.0564 102.129 37.8453 101.965 38.8197 101.965C39.7934 101.965 40.5827 102.129 40.5827 103.374Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.8231 81.1396C36.6904 81.1396 34.8262 81.5515 34.8262 81.5515C34.8262 81.5515 36.9865 88.0578 36.9865 93.1814C36.9865 99.1733 37.9762 100.768 37.9762 100.768L38.7995 100.758L39.6226 100.768C39.6226 100.768 40.612 99.1733 40.612 93.1814C40.612 88.0578 42.7726 81.5515 42.7726 81.5515C42.7726 81.5515 40.9558 81.1396 38.8231 81.1396Z"
      fill="#B35980"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.3633 56.478C35.3633 56.478 37.2692 56.0428 38.8195 56.0428C40.3703 56.0428 42.2758 56.478 42.2758 56.478L39.5499 10.0991L38.8199 10.1088L38.0906 10.0991L35.3633 56.478Z"
      fill="#B35980"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.5814 68.8207C46.5814 69.7015 45.8672 70.4153 44.9866 70.4153H36.5655C35.6849 70.4153 34.9707 69.7017 34.9707 68.8207C34.9707 67.9398 35.6849 67.2258 36.5655 67.2258H44.9866C45.8672 67.2258 46.5814 67.9398 46.5814 68.8207Z"
      fill="#32313B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.689 68.1825C45.689 69.0637 44.975 69.7773 44.0942 69.7773H33.545C32.6644 69.7773 31.9502 69.0637 31.9502 68.1825C31.9502 67.3021 32.6642 66.5879 33.545 66.5879H44.0942C44.975 66.5879 45.689 67.3021 45.689 68.1825Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.9839 58.0742C30.6355 58.0742 30.2758 58.1681 30.1108 58.2148C27.5394 58.9411 27.0215 63.2344 26.7212 67.6321C26.566 69.894 25.9524 71.4906 24.8959 72.3777C24.885 72.3868 24.8741 72.3947 24.8625 72.4038C24.7898 71.4365 23.9841 70.6745 22.9985 70.6745C21.965 70.6745 21.127 71.5123 21.127 72.546C21.127 73.5797 21.9648 74.4175 22.9985 74.4175C23.4453 74.4175 23.7842 74.3927 24.0936 74.3C24.5192 74.1721 24.913 74.0186 25.686 73.3804C27.0369 72.2658 27.8133 70.361 27.9942 67.7191C28.2857 63.4543 28.7834 61.0348 29.6654 59.9959C29.8694 60.526 30.3822 60.9027 30.9841 60.9027C31.7652 60.9027 32.398 60.2691 32.398 59.4885C32.3978 58.7072 31.765 58.0742 30.9839 58.0742Z"
      fill="#32313B"
    />
    <path
      d="M46.6566 58.0742C47.0047 58.0742 47.364 58.1681 47.5296 58.2148C50.1008 58.9411 50.6185 63.2344 50.9192 67.6321C51.0737 69.894 51.6879 71.4906 52.7439 72.3777C52.7554 72.3868 52.7663 72.3947 52.7774 72.4038C52.8499 71.4365 53.6562 70.6745 54.6417 70.6745C55.6756 70.6745 56.5132 71.5123 56.5132 72.546C56.5132 73.5797 55.6758 74.4175 54.6417 74.4175C54.1947 74.4175 53.8562 74.3927 53.5461 74.3C53.121 74.1721 52.7271 74.0186 51.9537 73.3804C50.6029 72.2658 49.8265 70.361 49.6459 67.7191C49.3545 63.4543 48.8566 61.0348 47.9748 59.9959C47.7712 60.526 47.2583 60.9027 46.6564 60.9027C45.8754 60.9027 45.2422 60.2691 45.2422 59.4885C45.2424 58.7072 45.8754 58.0742 46.6566 58.0742Z"
      fill="#32313B"
    />
    <path
      d="M40.9312 9.05741C40.9312 9.99217 39.9859 10.75 38.8203 10.75C37.6543 10.75 36.7092 9.99237 36.7092 9.05741V5.61934C36.7092 4.68458 37.6543 3.92676 38.8203 3.92676C39.9859 3.92676 40.9312 4.68458 40.9312 5.61934V9.05741Z"
      fill="#32313B"
    />
    <path
      d="M40.9312 6.13066C40.9312 7.06541 39.9859 7.82324 38.8203 7.82324C37.6543 7.82324 36.7092 7.06561 36.7092 6.13066V2.69258C36.7092 1.75782 37.6543 1 38.8203 1C39.9859 1 40.9312 1.75782 40.9312 2.69258V6.13066Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.0945 81.5514L38.6091 10.6895"
      stroke="#32313B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.5447 81.5515L38.9519 10.2136"
      stroke="#32313B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.5034 81.5511L38.7274 10.4546"
      stroke="#32313B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.1376 81.5514L38.8447 9.63452"
      stroke="#32313B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.5447 9.62676C36.5447 9.88529 36.3353 10.0945 36.0772 10.0945H33.6712C33.4127 10.0945 33.2036 9.88509 33.2036 9.62676C33.2036 9.36843 33.4127 9.15918 33.6712 9.15918H36.077C36.3353 9.15918 36.5447 9.36863 36.5447 9.62676Z"
      fill="#32313B"
    />
    <path
      d="M32.7692 10.9967C33.5258 10.9967 34.139 10.3834 34.139 9.6269C34.139 8.87037 33.5258 8.25708 32.7692 8.25708C32.0127 8.25708 31.3994 8.87037 31.3994 9.6269C31.3994 10.3834 32.0127 10.9967 32.7692 10.9967Z"
      fill="#32313B"
    />
    <path
      d="M36.1055 13.7913C36.1055 14.0496 35.8961 14.2591 35.6378 14.2591H33.2322C32.9737 14.2591 32.7644 14.0496 32.7644 13.7913C32.7644 13.5329 32.9737 13.3235 33.2322 13.3235H35.6378C35.8961 13.3237 36.1055 13.5329 36.1055 13.7913Z"
      fill="#32313B"
    />
    <path
      d="M32.3302 15.1614C33.0869 15.1614 33.7003 14.548 33.7003 13.7914C33.7003 13.0348 33.0869 12.4214 32.3302 12.4214C31.5736 12.4214 30.9602 13.0348 30.9602 13.7914C30.9602 14.548 31.5736 15.1614 32.3302 15.1614Z"
      fill="#32313B"
    />
    <path
      d="M41.0947 9.627C41.0947 9.88554 41.3042 10.0948 41.5623 10.0948H43.9681C44.2266 10.0948 44.4359 9.88534 44.4359 9.627C44.4359 9.36867 44.2266 9.15942 43.9681 9.15942H41.5623C41.3042 9.15942 41.0947 9.36888 41.0947 9.627Z"
      fill="#32313B"
    />
    <path
      d="M44.8705 10.9969C45.6271 10.9969 46.2405 10.3835 46.2405 9.62686C46.2405 8.87022 45.6271 8.25684 44.8705 8.25684C44.1139 8.25684 43.5005 8.87022 43.5005 9.62686C43.5005 10.3835 44.1139 10.9969 44.8705 10.9969Z"
      fill="#32313B"
    />
    <path
      d="M41.5339 13.7915C41.5339 14.0498 41.7434 14.2593 42.0015 14.2593H44.4073C44.6658 14.2593 44.8749 14.0498 44.8749 13.7915C44.8749 13.5332 44.6658 13.3237 44.4073 13.3237H42.0015C41.7434 13.3239 41.5339 13.5332 41.5339 13.7915Z"
      fill="#32313B"
    />
    <path
      d="M45.3097 15.1614C46.0664 15.1614 46.6797 14.548 46.6797 13.7914C46.6797 13.0348 46.0664 12.4214 45.3097 12.4214C44.5531 12.4214 43.9397 13.0348 43.9397 13.7914C43.9397 14.548 44.5531 15.1614 45.3097 15.1614Z"
      fill="#32313B"
    />
    <path
      d="M4.43602 66.9751C4.51237 66.9751 4.58993 66.9656 4.66688 66.9458L64.5232 51.1343C65.0175 51.0073 65.315 50.5036 65.1879 50.0095C65.0611 49.5153 64.5561 49.2184 64.0633 49.3448L4.20698 65.1565C3.71274 65.2835 3.41522 65.7869 3.54226 66.2811C3.64952 66.6982 4.02479 66.9751 4.43602 66.9751Z"
      fill="#B35980"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M64.6779 53.0115C64.6779 53.0115 42.1668 58.4924 37.1341 59.766C32.1014 61.0395 10.21 67.4081 10.21 67.4081"
      stroke="#FAE1EC"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.4354 51.3438C64.4354 51.3438 63.6002 52.7804 65.217 53.1654"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.52566 68.1104C6.6933 68.7701 7.36386 69.1684 8.02332 69.0007L11.157 68.2035C11.8161 68.0359 12.2148 67.3657 12.0469 66.706C11.8791 66.0468 11.2089 65.6485 10.5497 65.8161L7.41598 66.6129C6.75652 66.781 6.35781 67.4511 6.52566 68.1104Z"
      fill="#32313B"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M37.5971 82.5053C38.0294 82.5053 38.3798 82.1549 38.3798 81.7226C38.3798 81.2904 38.0294 80.9399 37.5971 80.9399C37.1649 80.9399 36.8145 81.2904 36.8145 81.7226C36.8145 82.1549 37.1649 82.5053 37.5971 82.5053Z"
      fill="#32313B"
    />
    <path
      d="M39.9699 82.5053C40.4022 82.5053 40.7526 82.1549 40.7526 81.7226C40.7526 81.2904 40.4022 80.9399 39.9699 80.9399C39.5377 80.9399 39.1873 81.2904 39.1873 81.7226C39.1873 82.1549 39.5377 82.5053 39.9699 82.5053Z"
      fill="#32313B"
    />
    <path
      d="M42.2421 82.6569C42.6744 82.6569 43.0248 82.3065 43.0248 81.8742C43.0248 81.442 42.6744 81.0916 42.2421 81.0916C41.8099 81.0916 41.4595 81.442 41.4595 81.8742C41.4595 82.3065 41.8099 82.6569 42.2421 82.6569Z"
      fill="#32313B"
    />
    <path
      d="M35.476 82.758C35.9083 82.758 36.2587 82.4075 36.2587 81.9753C36.2587 81.543 35.9083 81.1926 35.476 81.1926C35.0438 81.1926 34.6934 81.543 34.6934 81.9753C34.6934 82.4075 35.0438 82.758 35.476 82.758Z"
      fill="#32313B"
    />
    <path
      d="M12.7285 43.978L11.5671 44.8364L13.0315 46.1998L14.8493 45.3919L12.7285 43.978Z"
      fill="#32313B"
    />
    <path
      d="M7.1791 64.7234C9.45627 64.7234 11.3023 62.8774 11.3023 60.6002C11.3023 58.3231 9.45627 56.4771 7.1791 56.4771C4.90192 56.4771 3.05591 58.3231 3.05591 60.6002C3.05591 62.8774 4.90192 64.7234 7.1791 64.7234Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.14655 68.6338C7.31581 69.3234 6.89387 70.0196 6.20412 70.1889C5.51436 70.3581 4.81815 69.936 4.64889 69.246L3.99528 66.5821C3.82623 65.8926 4.24816 65.1964 4.93792 65.0271C5.62767 64.8579 6.3241 65.2796 6.49315 65.9695L7.14655 68.6338Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.54646 68.4624C9.71572 69.152 9.29379 69.8482 8.60403 70.0179C7.91427 70.1867 7.21805 69.765 7.04879 69.075L6.39518 66.4107C6.22613 65.7212 6.64807 65.025 7.33783 64.8557C8.02759 64.6864 8.724 65.1086 8.89305 65.7981L9.54646 68.4624Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0191 66.8887C14.1884 67.5783 13.7664 68.2745 13.0767 68.4441C12.3869 68.613 11.6907 68.1913 11.5214 67.5013L10.8678 64.837C10.6988 64.1474 11.1207 63.4512 11.8105 63.282C12.5002 63.1127 13.1966 63.5349 13.3657 64.2244L14.0191 66.8887Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8455 67.8672C12.0148 68.5568 11.5928 69.253 10.9031 69.4227C10.2133 69.5915 9.51712 69.1698 9.34787 68.4798L8.69426 65.8155C8.5252 65.126 8.94713 64.4297 9.63689 64.2605C10.3266 64.0912 11.0231 64.5134 11.1921 65.2029L11.8455 67.8672Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4864 62.1473C14.0283 64.3903 12.7528 66.6224 10.6379 67.1334L7.14892 67.976C5.0342 68.4868 2.8805 67.0825 2.339 64.8403C1.79709 62.5973 3.07259 60.3649 5.18751 59.8539L8.67607 59.0112C10.7908 58.5002 12.9445 59.9048 13.4864 62.1473Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6541 41.8571C31.5744 41.8571 32.3204 41.111 32.3204 40.1907C32.3204 39.2705 31.5744 38.5244 30.6541 38.5244C29.7338 38.5244 28.9878 39.2705 28.9878 40.1907C28.9878 41.111 29.7338 41.8571 30.6541 41.8571Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6529 41.1C30.2766 41.1 29.9248 40.8643 29.7943 40.489C29.7883 40.4717 29.1728 38.7332 27.8279 37.0344C26.0991 34.8514 24.0202 33.7444 21.6481 33.7444C16.0949 33.7444 14.508 39.0518 14.4433 39.2778C14.3052 39.7593 13.8027 40.039 13.321 39.9027C12.839 39.766 12.5585 39.2661 12.694 38.7837C12.7132 38.7153 14.7011 31.9268 21.6481 31.9268C24.58 31.9268 27.2223 33.3188 29.2892 35.9522C30.8093 37.8888 31.4839 39.8132 31.5119 39.8942C31.676 40.3687 31.4241 40.8861 30.9498 41.0501C30.8515 41.0839 30.7513 41.1 30.6529 41.1Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M10.5372 45.42C11.6798 44.1158 13.5958 43.7121 14.331 43.4626C14.9977 43.2366 15.6196 42.8452 16.1171 42.2774C17.6032 40.5816 17.4336 38.0017 15.7374 36.5154C14.0411 35.029 11.4613 35.1991 9.97511 36.8951C9.47783 37.4628 9.17124 38.1306 9.0347 38.8211C8.88403 39.5832 8.73536 41.5353 7.59236 42.8395C6.44977 44.1437 4.53381 44.5474 3.7982 44.7969C3.13167 45.0229 2.50978 45.4143 2.01251 45.9819C0.52615 47.6779 0.696019 50.2576 2.39203 51.7439C4.08845 53.2305 6.66811 53.0602 8.15407 51.3642C8.65195 50.7965 8.95815 50.1287 9.09469 49.4383C9.24537 48.6761 9.39422 46.724 10.5372 45.42Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1465 40.0912L12.1094 38.9406L13.0876 38.333L14.1029 38.8763L14.1399 40.0272L13.1617 40.6348L12.1465 40.0912Z"
      fill="#80405B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.90991 48.6615L4.50818 47.6775L5.65905 47.7033L6.21267 48.7134L5.61422 49.6975L4.46333 49.6716L3.90991 48.6615Z"
      fill="#80405B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
