export const SuccessSpotIcon = () => (
  <svg
    width="103"
    height="103"
    viewBox="0 0 103 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M59.534 94.7522C78.9698 94.7522 94.7256 92.2155 94.7256 89.0864C94.7256 85.9572 78.9698 83.4205 59.534 83.4205C40.0983 83.4205 24.3425 85.9572 24.3425 89.0864C24.3425 92.2155 40.0983 94.7522 59.534 94.7522Z"
      fill="#32274B"
      stroke="#32274B"
    />
    <path
      d="M78.6922 85.6808C78.6922 82.479 66.3866 79.8826 51.2056 79.8826C36.0257 79.8826 23.7192 82.4788 23.7192 85.6808C23.7192 86.2892 23.7192 88.5644 23.7192 89.1732C23.7192 92.3755 36.0255 94.9716 51.2056 94.9716C66.3866 94.9716 78.6922 92.3757 78.6922 89.1732C78.6922 88.5642 78.6922 86.2889 78.6922 85.6808Z"
      fill="#88D0A3"
      stroke="#32274B"
    />
    <path
      d="M78.6922 85.6808C78.6922 88.8833 66.3866 91.4797 51.2056 91.4797C36.0257 91.4797 23.7192 88.8835 23.7192 85.6808C23.7192 82.479 36.0255 79.8826 51.2056 79.8826C66.3866 79.8826 78.6922 82.4788 78.6922 85.6808Z"
      fill="#D6EDD9"
      stroke="#32274B"
    />
    <path
      d="M60.2511 84.9222C60.2511 85.3746 57.6056 85.7414 54.3418 85.7414C51.0776 85.7414 48.432 85.3746 48.432 84.9222C48.432 84.4704 51.0776 84.1031 54.3418 84.1031C57.6056 84.1031 60.2511 84.4704 60.2511 84.9222Z"
      fill="#32274B"
      stroke="#32274B"
    />
    <path
      d="M55.6363 83.7009C55.6363 85.3608 53.6513 85.6243 51.2035 85.6243C48.7551 85.6243 46.7714 85.3608 46.7714 83.7009V47.6201C46.7714 45.9599 48.7551 44.6141 51.2035 44.6141C53.6513 44.6141 55.6363 45.9601 55.6363 47.6201V83.7009Z"
      fill="#D6EDD9"
      stroke="#32274B"
    />
    <path
      d="M55.2092 65.9026C52.3729 66.7755 48.8814 67.2119 47.1356 66.4253V61.9388L55.2092 60.6653V65.9026Z"
      fill="#32274B"
    />
    <path
      d="M81.946 9.45518C79.9368 8.13204 77.56 7.80596 75.3992 8.3566C74.6119 8.55699 70.5493 9.41582 69.353 9.91435C67.8956 10.5225 66.5974 11.5569 65.6632 12.9757L51.7088 34.1686L43.1128 25.6261C41.1513 22.6463 37.5828 21.3623 34.3057 22.1617C33.5023 22.3576 28.9382 23.3059 27.5348 24.043C27.2957 24.1685 27.0608 24.307 26.83 24.4586C23.041 26.9539 21.9918 32.0488 24.4865 35.8379L41.9875 57.9043C43.4337 60.1004 45.7535 61.3741 48.1856 61.5729C48.1881 61.5729 48.1908 61.5736 48.193 61.5736C48.3042 61.5828 48.4154 61.589 48.5272 61.5939C48.5668 61.5955 48.6064 61.5971 48.645 61.5982C48.7202 61.6004 48.7949 61.6011 48.8696 61.6011C48.9448 61.6011 49.0199 61.6004 49.0948 61.5982C49.1344 61.5971 49.1738 61.5955 49.2129 61.5939C49.3243 61.5892 49.4352 61.5828 49.5466 61.5736C49.5495 61.5736 49.5515 61.5729 49.5549 61.5729C49.6638 61.564 49.773 61.5526 49.881 61.5396C51.0364 61.3985 56.5407 60.0232 57.4895 59.5471C58.7333 58.9231 59.8348 57.9739 60.6552 56.7281L84.2891 20.8349C86.7845 17.0456 85.7352 11.9503 81.946 9.45518Z"
      fill="#25A871"
      stroke="#32274B"
    />
    <path
      d="M77.0431 10.6311C73.2534 8.13598 68.1584 9.18514 65.6632 12.975L48.8698 38.4789L38.2103 26.8017C35.7147 23.0119 30.6202 21.9625 26.83 24.4578C23.041 26.9531 21.9918 32.048 24.4865 35.8371L41.9875 57.9035C43.4337 60.0996 45.7536 61.3733 48.1856 61.5721C48.1881 61.5721 48.1908 61.5728 48.193 61.5728C48.3042 61.582 48.4153 61.5883 48.5272 61.5932C48.5668 61.5947 48.6064 61.5963 48.6451 61.5974C48.7202 61.5997 48.7949 61.6003 48.8696 61.6003C48.9448 61.6003 49.0199 61.5997 49.0948 61.5974C49.1344 61.5963 49.1738 61.5947 49.2129 61.5932C49.3243 61.5885 49.4352 61.582 49.5466 61.5728C49.5495 61.5728 49.5516 61.5721 49.5549 61.5721C51.9868 61.3731 54.3064 60.0996 55.7528 57.9035L79.3864 22.0104C81.8818 18.2212 80.8331 13.1264 77.0431 10.6311Z"
      fill="#88D0A3"
      stroke="#32274B"
    />
  </svg>
);
