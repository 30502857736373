import { css } from "linaria";
import { createElement } from "react";
import { DatoCmsBlockList, DatoCmsListItem } from "../../../graphql-types";
import ListItemBlock from "./ListItem.block";

type Props = {
  record: DatoCmsBlockList;
};

const blockListStyle = css`
  margin: 0;
  padding: 0 0 0 1.5em;
  > li {
    + li {
      padding-top: 1.5em;
    }
  }
`;

const BlockList = (props: Props) => {
  return createElement(
    props.record.listType === "Numbered list" ? "ol" : "ul",
    {
      className: blockListStyle,
      children: (
        <>
          {((props.record.listItems ?? []) as DatoCmsListItem[]).map(li => (
            <ListItemBlock key={li.id} record={li} />
          ))}
        </>
      ),
    }
  );
};

export default BlockList;
