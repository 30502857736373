export const SlackCommunityTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22647 4.01516C7.07828 3.3673 7 2.69279 7 2H7.6H10.4H11C11 4.76142 13.2386 7 16 7L16 7.59998V10.4L16 11C12.9502 11 10.2547 9.48302 8.62688 7.16245C7.97147 6.22811 7.48914 5.16349 7.22647 4.01516ZM7 16C7 13.8567 7.7492 11.8884 9 10.3427C8.60084 9.84939 8.15061 9.39916 7.65733 9C6.11161 10.2508 4.1433 11 2 11C4.76142 11 7 13.2386 7 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.083 3H8.06189C8.51314 6.61872 11.3813 9.48686 15 9.93811V7.91704C12.4875 7.49552 10.5045 5.5125 10.083 3ZM10.3427 9C9.8494 8.60084 9.39916 8.15061 9 7.65733C8.60084 8.15061 8.15061 8.60084 7.65733 9C8.15061 9.39916 8.60084 9.84939 9 10.3427C9.39916 9.84939 9.8494 9.39916 10.3427 9ZM11.1891 9.60762C9.50328 10.8784 8.33617 12.8005 8.0619 15H10.083C10.4077 13.0646 11.6589 11.4434 13.3649 10.6081C12.59 10.3712 11.8596 10.0325 11.1891 9.60762ZM16 11V10V8V7C13.2386 7 11 4.76142 11 2H10H8H7C7 4.76142 4.76142 7 2 7V8V10V11C4.76142 11 7 13.2386 7 16L8.00001 16H10H11C11 13.2386 13.2386 11 16 11ZM7.04781 15.0666C7.0501 15.0444 7.05248 15.0222 7.05493 15C7.05493 15 7.05493 15 7.05493 15C7.05248 15.0222 7.0501 15.0444 7.04781 15.0666ZM7.3919 13.3649C7.62882 12.59 7.96751 11.8595 8.39237 11.1891C7.94162 10.5911 7.4089 10.0584 6.81091 9.60762C6.14046 10.0325 5.40999 10.3712 4.63508 10.6081C5.83238 11.1943 6.80567 12.1676 7.3919 13.3649ZM7.3919 4.63508C7.62882 5.40999 7.96751 6.14045 8.39238 6.81091C7.12162 8.49673 5.19945 9.66384 3 9.93811V7.91704C4.93537 7.59235 6.55657 6.34113 7.3919 4.63508Z"
    />
  </svg>
);
