export const CaseStudyTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.1"
      d="M5 5C5 4.44772 5.44772 4 6 4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C5.44771 16 5 15.5523 5 15V5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3H4L4 14H13C13.5523 14 14 13.5523 14 13V4C14 3.44772 13.5523 3 13 3ZM4 2C3.44772 2 3 2.44772 3 3V14C3 14.5523 3.44772 15 4 15H13C14.1046 15 15 14.1046 15 13V4C15 2.89543 14.1046 2 13 2H4Z"
    />
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2.75V7.39645C8.5 7.61917 8.76929 7.73071 8.92678 7.57322L10.3232 6.17678C10.4209 6.07915 10.5791 6.07915 10.6768 6.17678L12.0732 7.57322C12.2307 7.73071 12.5 7.61917 12.5 7.39645V2.75C12.5 2.61193 12.3881 2.5 12.25 2.5H8.75C8.61193 2.5 8.5 2.61193 8.5 2.75ZM5 3V14H6L6 3L5 3Z"
    />
  </svg>
);
