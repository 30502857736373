import { useRef } from "react";
import DemoFormMessageView from "./DemoFormMessageView";
import { DemoIconCheckingAvailability } from "./DemoIconCheckingAvailability";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";
import { styled } from "@linaria/react";
import LoadingIndicator from "../utilities/LoadingIndicator";

const messages = [
  "Thank you for submitting your request. We’re looking for an available slot.",
  "Almost ready to book.",
  "Pulling up our product expert’s calendar.",
];

const Content = styled.div`
  padding-bottom: 1em;
  min-height: 40rem;
`;

const Heading = styled.h3`
  position: relative;
  height: 4em;
  div {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
`;

const LoadingIndicatorWrap = styled.div`
  position: absolute;
  bottom: 6em;
  left: 50%;
  transform: translateX(-50%);
`;

const DemoCheckingAvailabilityView = () => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  useOnMount(() => {
    const timeline = gsap.timeline({
      repeat: -1,
    });
    headingRef.current?.childNodes.forEach((msg, i) => {
      timeline.set(msg, { opacity: 0, y: 6 }, 0);
      const enterTime = i * 3;
      const exitTime = (i + 1) * 3 - 0.3;
      timeline.fromTo(
        msg,
        {
          opacity: 0,
          y: 6,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.3,
        },
        enterTime
      );
      timeline.fromTo(
        msg,
        {
          opacity: 1,
          y: 0,
        },
        {
          opacity: 0,
          y: 6,
          duration: 0.3,
        },
        exitTime
      );
    });
    return () => {
      timeline.kill();
    };
  });
  return (
    <DemoFormMessageView dataTest="demo-form-checking-availability-screen">
      <Content>
        <DemoIconCheckingAvailability />
        <Heading ref={headingRef}>
          {messages.map(m => (
            <div key={m}>{m}</div>
          ))}
        </Heading>
        <LoadingIndicatorWrap>
          <LoadingIndicator size={24} delayInSeconds={1} />
        </LoadingIndicatorWrap>
      </Content>
    </DemoFormMessageView>
  );
};

export default DemoCheckingAvailabilityView;
