import NoWrap from "../../../typography/Nowrap";
import ReportIntroSection from "../../ReportIntroSection";

const SOCIntroSection = () => {
  return (
    <ReportIntroSection
      blockquote={
        <p>Security teams are being prevented from doing their best work.</p>
      }
    >
      <p>
        While under-staffing and low budgets have always been challenges for any
        type of team, security teams are uniquely affected by repetitive, manual
        tasks, which in turn keep them from working on higher-impact projects
        that contribute to their organization’s overall security posture.
      </p>
      <p>
        In my fifteen years of being a security practitioner, working on
        incident response and leading security teams, I witnessed over and over
        again that in the SOC, there's too much work and not enough staff. More
        specifically, I saw overworked analysts so consumed with tedious,
        repetitive tasks that it led not only to burnout, but to human error
        that could cost a company millions.
      </p>
      <p>
        The analysts I worked with weren’t the only ones facing these
        challenges, and I wasn’t the only security leader trying to find
        solutions to help my team do their best work.
      </p>
      <p>
        While we generally know the day-to-day struggles analysts face, there’s
        a lack of current data to give us a clear picture of what it’s like to
        be an analyst today. So we conducted a survey that aimed to understand
        them better: “Voice of the SOC Analyst.”
      </p>
      <p>
        We found that while SOC teams are passionate and engaged in what they
        do, they’re challenged with endless manual tasks, understaffed teams,
        inefficient processes, and too many alerts — all preventing them from
        doing more <NoWrap>high-quality</NoWrap> work.
      </p>
      <p>
        Our goal with this project is to help security leaders recognize what
        they can do to streamline their processes, decrease burnout, increase
        retention, and create better overall work environments for
        their analysts.
      </p>
      <p>
        We hope you utilize these findings and data as you make your way through
        2022.
      </p>
    </ReportIntroSection>
  );
};

export default SOCIntroSection;
