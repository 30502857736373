import { styled } from "@linaria/react";
import { css, cx } from "linaria";
import { DatoCmsPerson } from "../../../graphql-types";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromTablet,
  fromTabletLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import PersonPhotoIcon, { PersonPhotoIconContainer } from "./PersonPhotoIcon";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

const PersonEntryWrap = css`
  display: grid;
  text-decoration: none;
  grid-template-columns: auto minmax(auto, 1fr);
  align-items: center;
  &.framed {
    background-color: ${colors.purple50};
    padding: 1em;
    border: 1px solid ${colors.purple200};
    border-radius: ${rSize("radius")};
  }
  grid-gap: 0.5em;
  font-weight: 500;
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${fromTablet} {
    grid-gap: 0.75em;
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
  ${onlyPhones} {
    ${PersonPhotoIconContainer} {
      width: 4.2rem;
    }
  }
`;

const PersonDetails = styled.div`
  > * {
    display: block;
  }
  strong {
    font-size: 1.4rem;
  }
  span {
    font-size: 1.2rem;
  }
  ${fromTabletLg} {
    strong {
      font-size: 1.6rem;
    }
    span {
      font-size: 1.4rem;
    }
  }
`;

export const PersonEntry = (props: {
  person: DatoCmsPerson;
  framed?: boolean;
}) => {
  const className = cx(PersonEntryWrap, props.framed && "framed");
  const inner = (
    <>
      <PersonPhotoIcon person={props.person} size={64} />
      <PersonDetails>
        <strong>{props.person.fullName} </strong>
        <span>{props.person.title || props.person.team}</span>
      </PersonDetails>
    </>
  );
  return props.person.linkedin ? (
    <a className={className} href={props.person.linkedin} {...externalLinkAttr}>
      {inner}
    </a>
  ) : (
    <div className={className}>{inner}</div>
  );
};
