import { StoryRecord } from "../../../../types/tines.types";
import { SlackIcon } from "../notificationIcons/SlackIcon";

// cspell:disable

export const makeGPTDemoStory = () => ({
  dropZoneActionNameMatcher: "Reply in Slack",
  notification: {
    icon: SlackIcon,
    heading: "Slack",
    body: "New message received",
  },
  maxEvents: 7,
  possibleTriggerValues: ["Code execution failed", "Code works"] as [
    string,
    string
  ],
  inspectable: true,
  story: {
    standardLibVersion: "17",
    actionRuntimeVersion: "4",
    agents: [
      {
        name: "Receive prompt",
        position: { x: -855, y: 675 },
        type: "webhook",
      },
      {
        name: "Code works",
        position: {
          x: -720,
          y: 930,
        },
        type: "trigger",
      },
      {
        name: "Code execution failed",
        position: { x: -930, y: 930 },
        type: "trigger",
      },
      {
        name: "Add context",
        position: { x: -855, y: 735 },
        type: "eventTransformation",
      },
      {
        name: "Generate code with ChatGPT",
        position: { x: -855, y: 795 },
        type: "httpRequest",
      },
      {
        name: "Run code in sandbox",
        position: { x: -855, y: 855 },
        type: "httpRequest",
      },
      {
        name: "Retry",
        position: { x: -930, y: 1020 },
        type: "httpRequest",
      },
      {
        name: "Reply in Slack",
        position: {
          x: -720,
          y: 1020,
        },
        type: "httpRequest",
      },
    ],
    links: [
      { sourceIdentifier: 5, receiverIdentifier: 1 },
      { sourceIdentifier: 3, receiverIdentifier: 4 },
      { sourceIdentifier: 0, receiverIdentifier: 3 },
      { sourceIdentifier: 4, receiverIdentifier: 5 },
      { sourceIdentifier: 1, receiverIdentifier: 7 },
      { sourceIdentifier: 5, receiverIdentifier: 2 },
      { sourceIdentifier: 2, receiverIdentifier: 6 },
    ],
    diagramNotes: [],
  } as unknown as StoryRecord,
});

// cspell:enable
