import { styled } from "@linaria/react";
import { useRef } from "react";
import { defaultChartColorSeriesV4 } from "../../../styles/colorsV4.styles";
import { onChartLibraryReady } from "../../../utils/charts.utils";
import { debounce } from "../../../utils/debounce.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { pc } from "../../../utils/math.utils";

type Props = {
  header: [string, string];
  data: [string, number][];
  colors?: string[];
  height?: number;
  seoTitle?: string;
  chartArea?: google.visualization.ChartArea;
  hAxisTitle?: string;
  vAxisTitle?: string;
  legend?: google.visualization.BarChartOptions["legend"];
  sortByValue?: boolean;
  total?: number;
};

const BarChartContainer = styled.div<{ height?: number }>`
  height: ${p => `${p.height ?? 200}px`};
  font-weight: 500;
  g[clip-path] {
    /* vertical grid lines */
    > g:nth-child(1) {
      opacity: 0.5;
    }
    > g:nth-child(2) {
      /* the bar */
      rect {
        rx: 4;
      }
      /* the shadow effect around the bar when hovered */
      rect[stroke="#000000"]:nth-child(2) {
        rx: 5;
      }
      rect[stroke="#000000"]:nth-child(3) {
        rx: 6;
      }
      rect[stroke="#000000"]:nth-child(4) {
        rx: 7;
      }
      /* focus ring when a bar is clicked */
      rect[stroke="#ffffff"] {
        rx: 3;
      }
    }
  }
  /** line separating vertical axis from bars */
  rect[width="1"][fill="#333333"] {
    fill-opacity: 0;
  }
  /** vertical axis text labels */
  text[text-anchor="end"][fill="#222222"] {
    transform: translateX(-5px);
  }
  /** annotations on top of bars */
  text[text-anchor="end"][fill="#404040"] {
    fill: white;
  }
`;

const BarChart = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const drawChart = () => {
      const data = props.total
        ? props.data.map(d => {
            const [name, value] = d;
            return [name, value, pc(value, props.total!)] as [
              string,
              number,
              ...unknown[]
            ];
          })
        : props.data;
      const dataWithHeader = google.visualization.arrayToDataTable([
        props.total ? [...props.header, { role: "annotation" }] : props.header,
        ...(props.sortByValue ? [...data].sort((a, b) => b[1] - a[1]) : data),
      ]);
      const chart = new google.visualization.BarChart(ref.current!);
      const draw = () =>
        chart.draw(dataWithHeader, {
          colors: props.colors ?? defaultChartColorSeriesV4,
          fontName: "Roobert, sans-serif",
          // title: props.seoTitle,
          chartArea: props.chartArea ?? {
            top: 12,
            bottom: 38,
            right: 0,
            left: "38%",
          },
          backgroundColor: "transparent",
          hAxis: {
            title: props.hAxisTitle,
            minValue: 0,
          },
          vAxis: {
            title: props.vAxisTitle,
          },
          titleTextStyle: {
            color: "transparent",
          },
          legend: props.legend ?? "none",
        });
      const debounced = debounce(draw, { fireImmediately: true });
      window.addEventListener("resize", debounced);
      draw();
      return () => window.removeEventListener("resize", debounced);
    };
    return onChartLibraryReady(drawChart);
  });
  return <BarChartContainer ref={ref} height={props.height} />;
};

export default BarChart;
