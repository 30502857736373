const LogoFelicis = () => {
  return (
    <svg width="115" height="34" viewBox="0 0 115 34" fill="currentColor">
      <title>Felicis</title>
      <path d="M77.394 9.35887C77.3889 9.14459 77.4253 8.93141 77.5009 8.73181C77.5765 8.5322 77.6901 8.35016 77.8347 8.19637C77.9793 8.04257 78.1522 7.9201 78.3433 7.83612C78.5343 7.75216 78.7397 7.70835 78.9473 7.7073C79.1608 7.69942 79.3735 7.736 79.5729 7.81487C79.7725 7.89373 79.9545 8.01326 80.1082 8.16633C80.2619 8.3194 80.384 8.50286 80.4676 8.70576C80.5511 8.90867 80.5941 9.12686 80.5941 9.34731C80.5941 9.56776 80.5511 9.78596 80.4676 9.98885C80.384 10.1918 80.2619 10.3752 80.1082 10.5283C79.9545 10.6814 79.7725 10.8009 79.5729 10.8798C79.3735 10.9586 79.1608 10.9952 78.9473 10.9873C78.7414 10.9868 78.5377 10.9441 78.3479 10.8618C78.158 10.7794 77.9859 10.6591 77.8415 10.5076C77.6971 10.3562 77.5832 10.1767 77.5063 9.9795C77.4296 9.78231 77.3913 9.57137 77.394 9.35887Z" />
      <path d="M40.8203 7.74723C40.8203 7.25765 41.0525 6.98869 41.372 6.98869H54.4903C54.5632 6.98868 54.6355 7.00363 54.7028 7.03263C54.7701 7.06164 54.8312 7.10416 54.8825 7.1577C54.9338 7.21124 54.9744 7.27477 55.0017 7.34458C55.029 7.4144 55.0427 7.48912 55.0419 7.56442V9.24541C55.0419 9.39644 54.9839 9.54128 54.8804 9.64806C54.7769 9.75485 54.6365 9.81484 54.4903 9.81484H43.8129V16.7489H51.0601C51.205 16.7537 51.3425 16.8152 51.4451 16.921C51.5475 17.0266 51.6072 17.1688 51.6118 17.3184V18.9678C51.6072 19.1174 51.5475 19.2594 51.4451 19.3652C51.3425 19.471 51.205 19.5324 51.0601 19.5372H43.8129V26.4755C43.8077 26.6248 43.7481 26.7667 43.6457 26.8724C43.5432 26.978 43.4059 27.0396 43.2612 27.045H41.372C41.2533 27.0482 41.1364 27.0135 41.0376 26.9454C40.9388 26.8773 40.8629 26.7791 40.8203 26.6646V7.74723Z" />
      <path d="M60.3612 12.6074C63.8484 12.6074 66.4929 15.2781 66.4929 18.9657C66.4929 19.2052 66.4623 19.6864 66.4338 19.9259C66.4223 20.0862 66.3533 20.2363 66.2404 20.347C66.1275 20.4577 65.9788 20.521 65.823 20.5248H56.5565C56.585 22.3844 58.2115 24.307 60.5648 24.307C62.1058 24.307 63.0341 23.7376 63.8484 23.1388C64.1396 22.9286 64.4002 22.8382 64.6322 23.1975L65.418 24.4584C65.65 24.7588 65.7661 25.0278 65.359 25.3871C64.4002 26.2654 62.627 27.1059 60.3898 27.1059C56.3182 27.1059 53.6719 23.8069 53.6719 19.8482C53.68 15.9421 56.3224 12.6074 60.3612 12.6074ZM63.6184 18.3059C63.5593 16.6249 62.2809 15.1541 60.3063 15.1541C58.185 15.1541 56.9066 16.5619 56.703 18.3059H63.6184Z" />
      <path d="M77.4897 13.5719C77.4957 13.3992 77.5647 13.2353 77.6829 13.1129C77.8011 12.9906 77.9596 12.9188 78.1268 12.9121H79.7554C79.9213 12.9238 80.0772 12.9971 80.1948 13.1184C80.3123 13.2397 80.3833 13.4008 80.3946 13.5719V26.1078C80.3881 26.2806 80.3187 26.4445 80.2002 26.5668C80.0817 26.6892 79.9229 26.7609 79.7554 26.7675H78.1268C77.958 26.7659 77.7966 26.6959 77.6774 26.5724C77.5582 26.4489 77.4907 26.2821 77.4897 26.1078V13.5719Z" />
      <path d="M89.9242 12.6074C92.0169 12.6074 93.5558 13.4185 94.8099 15.0069C94.8674 15.0789 94.9103 15.1621 94.9357 15.2516C94.9612 15.3411 94.9686 15.4349 94.9578 15.5275C94.9469 15.6201 94.918 15.7095 94.8725 15.7901C94.8272 15.8708 94.7663 15.9411 94.6938 15.9967L93.6434 16.9905C93.2647 17.3498 93.0041 17.0809 92.7721 16.8414C92.1614 16.1522 91.0865 15.4609 90.0117 15.4609C87.7153 15.4609 85.9401 17.3813 85.9401 19.8398C85.9401 22.2983 87.6827 24.2523 89.92 24.2523C91.6625 24.2523 92.3628 23.2333 93.0876 22.542C93.1408 22.4737 93.2071 22.4177 93.2824 22.3774C93.3576 22.337 93.4403 22.3134 93.525 22.3079C93.6096 22.3024 93.6944 22.3152 93.7741 22.3452C93.8536 22.3754 93.9263 22.4225 93.9874 22.4831L94.8872 23.2333C95.2069 23.5317 95.3534 23.8636 95.1213 24.223C94.557 25.12 93.7819 25.8548 92.868 26.359C91.9542 26.8631 90.9317 27.12 89.8956 27.1059C86.1478 27.1059 83.0393 24.0464 83.0393 19.8776C83.0393 15.7907 86.2048 12.6074 89.9242 12.6074Z" />
      <path d="M97.5945 13.5719C97.6011 13.399 97.6705 13.2351 97.789 13.1128C97.9074 12.9905 98.0662 12.9188 98.2337 12.9121H99.8623C100.028 12.9238 100.184 12.9971 100.302 13.1184C100.419 13.2397 100.49 13.4008 100.502 13.5719V26.1078C100.495 26.2806 100.426 26.4445 100.307 26.5668C100.189 26.6892 100.03 26.7609 99.8623 26.7675H98.2337C98.0646 26.7659 97.9031 26.6959 97.7835 26.5725C97.664 26.4492 97.5961 26.2823 97.5945 26.1078V13.5719Z" />
      <path d="M103.069 25.6875C102.95 25.619 102.861 25.5075 102.818 25.3747C102.774 25.242 102.78 25.0973 102.835 24.9689L103.445 23.6788C103.592 23.3783 103.968 23.288 104.317 23.4981C105.102 23.9477 106.061 24.5171 107.659 24.5171C108.791 24.5171 109.43 23.9477 109.43 23.1681C109.43 22.2394 108.412 21.6384 106.554 20.7686C104.492 19.8104 102.922 18.6674 102.922 16.3917C102.922 14.6518 104.113 12.6095 107.368 12.6095C109.227 12.6095 110.625 13.181 111.291 13.6307C111.449 13.7369 111.565 13.8981 111.618 14.085C111.671 14.272 111.658 14.4723 111.58 14.6498L111.116 15.6101C111.077 15.6881 111.024 15.7574 110.958 15.8135C110.893 15.8697 110.817 15.9116 110.736 15.9367C110.655 15.9619 110.569 15.9697 110.485 15.9597C110.4 15.9498 110.318 15.9223 110.245 15.8789C109.345 15.4394 108.364 15.2043 107.368 15.1898C106.206 15.1898 105.654 15.7886 105.654 16.419C105.654 17.3184 106.613 17.8583 107.948 18.4572C110.418 19.5372 112.278 20.4681 112.278 23.0463C112.278 25.2148 110.477 27.1059 107.486 27.1059C105.909 27.1057 104.368 26.6113 103.069 25.6875Z" />
      <path d="M97.4704 9.35887C97.4653 9.14459 97.5017 8.93141 97.5773 8.73181C97.653 8.5322 97.7665 8.35016 97.9111 8.19637C98.0557 8.04257 98.2286 7.9201 98.4197 7.83612C98.6107 7.75216 98.8161 7.70835 99.0238 7.7073C99.2373 7.69942 99.4499 7.736 99.6495 7.81487C99.8489 7.89373 100.031 8.01326 100.185 8.16633C100.338 8.3194 100.46 8.50286 100.544 8.70576C100.628 8.90867 100.671 9.12686 100.671 9.34731C100.671 9.56776 100.628 9.78596 100.544 9.98885C100.46 10.1918 100.338 10.3752 100.185 10.5283C100.031 10.6814 99.8489 10.8009 99.6495 10.8798C99.4499 10.9586 99.2373 10.9952 99.0238 10.9873C98.8178 10.9868 98.6141 10.9441 98.4243 10.8618C98.2345 10.7794 98.0624 10.6591 97.918 10.5076C97.7735 10.3562 97.6596 10.1767 97.5827 9.9795C97.506 9.78231 97.4677 9.57137 97.4704 9.35887Z" />
      <path d="M74.7251 26.4209C74.7253 26.5143 74.7065 26.6066 74.6701 26.6922C74.6338 26.7776 74.5807 26.8544 74.5141 26.9177C74.4474 26.9809 74.3688 27.0291 74.2833 27.0594C74.1976 27.0896 74.1069 27.1011 74.0167 27.0932C72.2443 26.9294 70.9042 26.1267 69.9962 24.6852C69.3651 23.6682 69.0496 22.203 69.0496 20.2894V9.08573C69.0496 8.99742 69.0665 8.90999 69.0992 8.82842C69.132 8.74683 69.1798 8.67271 69.2403 8.61027C69.3009 8.54783 69.3726 8.4983 69.4517 8.46452C69.5308 8.43072 69.6155 8.41333 69.701 8.41333H71.295C71.3805 8.41333 71.4652 8.43072 71.5442 8.46452C71.6233 8.4983 71.6951 8.54783 71.7556 8.61027C71.8161 8.67271 71.8641 8.74683 71.8968 8.82842C71.9296 8.90999 71.9465 8.99742 71.9465 9.08573V20.2894C71.9465 21.5922 72.1202 22.5287 72.4676 23.0988C72.8109 23.6942 73.3721 24.0444 74.1511 24.1494C74.3084 24.1698 74.4532 24.2483 74.5587 24.3703C74.6644 24.4924 74.7234 24.6499 74.7251 24.8135V26.4209Z" />
      <path d="M29.1577 19.0014C27.4701 19.7222 25.1658 19.5099 18.0671 16.4799C21.0067 9.15296 22.4907 7.32069 24.1743 6.60416C25.8579 5.88765 28.0422 6.50331 28.9948 8.8798C29.2642 9.49421 29.4406 10.1476 29.518 10.8171C29.5333 11.0567 29.5855 11.2922 29.6728 11.5147C29.7643 11.7357 29.8886 11.9406 30.0412 12.122C30.4471 12.6499 30.7726 13.2388 31.0061 13.8681C31.9609 16.2489 30.8434 18.2807 29.1577 19.0014Z" />
      <path d="M9.49663 27.4063C11.1822 26.6856 12.6662 24.8533 15.6039 17.5305C8.5093 14.4984 6.20282 14.2883 4.51725 14.9986C2.83167 15.7088 1.71406 17.7511 2.66678 20.1277C2.90127 20.7586 3.22882 21.3483 3.63782 21.8759C3.79119 22.0572 3.9162 22.2621 4.00833 22.4831C4.09374 22.7063 4.14591 22.9414 4.16303 23.1808C4.23839 23.8537 4.41348 24.5106 4.68215 25.1286C5.62876 27.5072 7.80901 28.1271 9.49663 27.4063Z" />
      <path d="M19.8665 31.6256C20.4777 31.382 21.0494 31.044 21.5623 30.6234C21.7374 30.4652 21.9352 30.3362 22.1486 30.241C22.3649 30.153 22.5927 30.0991 22.8245 30.0812C23.4754 30.0062 24.1111 29.8283 24.7095 29.5537C27.012 28.5683 27.6146 26.3179 26.9163 24.576C26.2181 22.8341 24.4408 21.3044 17.3483 18.2723C14.4088 25.5972 14.2011 27.9779 14.9054 29.7178C15.6099 31.4575 17.5641 32.609 19.8665 31.6256Z" />
      <path d="M6.75461 9.42403C7.45287 11.1638 9.23005 12.6956 16.3225 15.7277C19.2683 8.40493 19.4738 6.02424 18.7755 4.28442C18.0773 2.54459 16.1087 1.39103 13.8063 2.3744C13.195 2.61756 12.6232 2.95555 12.1106 3.37668C11.9343 3.53406 11.7359 3.66299 11.5223 3.75912C11.3064 3.84779 11.0783 3.90096 10.8464 3.9167C10.1942 3.99241 9.55703 4.17099 8.95726 4.44621C6.65893 5.43168 6.05636 7.6821 6.75461 9.42403Z" />
    </svg>
  );
};

export default LogoFelicis;
