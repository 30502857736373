export const PortalsIllustration = () => (
  <svg
    width="870"
    height="496"
    viewBox="0 0 870 496"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M268.99 154.583C297.445 135.894 316.363 114.417 311.244 106.614C306.126 98.8103 278.909 107.635 250.454 126.325C221.999 145.015 203.081 166.491 208.199 174.295C213.318 182.098 240.534 173.273 268.99 154.583Z"
      fill="#EADFF8"
    />
    <path
      d="M268.99 154.583C297.445 135.894 316.363 114.417 311.244 106.614C306.126 98.8103 278.909 107.635 250.454 126.325C221.999 145.015 203.081 166.491 208.199 174.295C213.318 182.098 240.534 173.273 268.99 154.583Z"
      fill="#FFC8A3"
    />
    <path
      d="M453.213 474.125C481.458 436.537 438.121 356.232 356.418 294.758C274.716 233.285 185.586 213.922 157.342 251.51C129.097 289.098 172.434 369.403 254.136 430.877C335.839 492.35 424.969 511.713 453.213 474.125Z"
      fill="#C1AAF7"
    />
    <path
      d="M155.049 255.012C185.192 220.513 272.281 240.056 352.296 300.281C434.004 361.752 476.801 441.769 448.552 479.359"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M423.393 210.293L404.112 220.215C395.343 210.593 394.247 189.504 394.247 189.504C390.809 185.815 377.357 174.398 382.041 168.814C389.464 159.94 398.582 169.761 402.368 174.298C406.155 178.885 414.475 186.164 415.92 191.149C417.365 196.135 423.393 210.343 423.393 210.343V210.293Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M410.29 225.549C410.29 225.549 440.731 199.973 439.984 194.838C439.237 189.703 432.411 169.213 428.724 163.829C425.037 158.444 413.827 143.787 410.489 141.693C407.151 139.599 400.873 139.649 399.379 149.62C397.884 159.591 411.436 178.037 411.436 178.037C410.938 183.92 417.016 199.325 417.016 199.325C413.229 205.507 410.29 225.499 410.29 225.499V225.549Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M457.024 216.925C457.024 216.925 464.996 203.962 475.209 192.346C485.423 180.78 486.668 159.791 486.967 152.961C487.266 146.131 484.924 134.016 484.924 134.016C476.305 131.025 473.515 136.907 469.928 144.037C466.341 151.166 465.942 165.674 465.942 165.674C459.067 173.102 451.593 190.302 451.593 190.302C439.736 196.983 429.173 209.147 429.173 209.147L457.024 216.925Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M268.345 413.601C268.345 413.601 289.569 440.323 296.743 426.065C303.918 411.856 363.505 314.839 377.904 296.492C392.302 278.146 438.388 251.423 451.989 244.145C471.619 233.675 479.641 226.945 483.676 221.66C488.908 214.78 503.506 191.897 508.388 180.131C513.271 168.365 518.303 154.206 518.303 154.206C518.303 154.206 507.84 144.335 498.523 156.948C492.545 165.125 483.726 183.621 483.726 183.621C471.669 185.565 450.993 207.8 450.993 207.8C419.306 199.973 392.751 207.8 392.751 207.8C392.751 207.8 382.039 201.22 373.719 194.539C365.398 187.858 374.117 161.535 376.758 153.608C379.398 145.631 382.388 123.945 378.253 115.07C374.117 106.196 360.914 109.537 356.68 116.317C352.445 123.047 346.366 133.417 342.978 149.022C339.541 164.676 334.11 181.876 315.676 209.396C297.242 236.916 304.864 250.875 301.078 263.339C297.291 275.802 261.718 330.094 236.359 348.64C210.95 367.236 237.953 393.261 237.953 393.261L253.896 404.079L268.295 413.651L268.345 413.601Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M353.79 131.822C353.69 121.402 358.772 115.719 358.772 115.719C366.594 114.123 373.918 115.868 374.516 120.056C375.114 124.294 371.577 135.611 371.577 135.611C366.744 138.203 353.79 131.822 353.79 131.822Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M369.685 185.665C370.432 193.492 377.657 209.595 377.657 209.595"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M392.901 164.078L397.784 170.46L402.118 174.498L411.933 180.231L414.773 181.826L415.869 186.313C415.869 186.313 405.905 186.662 397.784 179.483C389.663 172.304 387.67 165.474 387.67 165.474C387.67 165.474 389.713 163.031 392.851 164.028L392.901 164.078Z"
      fill="#483C72"
    />
    <mask
      id="mask0_215_1794"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="148"
      y="232"
      width="314"
      height="262"
    >
      <path
        d="M453.216 474.126C481.46 436.538 438.124 356.232 356.421 294.759C274.719 233.285 185.589 213.922 157.345 251.51C129.1 289.098 172.437 369.403 254.139 430.877C335.842 492.351 424.972 511.714 453.216 474.126Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_215_1794)">
      <path
        d="M196.504 398.296C215.536 373.419 257.885 295.894 257.885 295.894C282.846 293.9 344.675 331.391 353.444 343.556C362.213 355.72 229.686 578.921 229.686 578.921L116.54 519.793C116.54 519.793 157.992 448.7 196.504 398.296Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M481.436 123.955C493.421 83.8291 481.444 44.8133 454.684 36.8104C427.925 28.8076 396.517 54.8482 384.533 94.9737C372.548 135.099 384.525 174.115 411.284 182.118C438.044 190.121 469.452 164.08 481.436 123.955Z"
      fill="#FA9589"
    />
    <path
      d="M515.116 226.945C524.031 226.945 531.258 207.861 531.258 184.319C531.258 160.778 524.031 141.693 515.116 141.693C506.201 141.693 498.974 160.778 498.974 184.319C498.974 207.861 506.201 226.945 515.116 226.945Z"
      fill="#FEAF7F"
    />
    <mask
      id="mask1_215_1794"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="379"
      y="34"
      width="103"
      height="150"
    >
      <path
        d="M409.094 181.478C435.798 190.751 465.293 163.131 477.051 122.699C488.71 82.5158 478.048 43.8783 452.489 36.2505C426.93 28.6227 396.539 54.9461 384.531 95.0294C372.524 135.113 383.933 172.703 409.094 181.478Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_215_1794)">
      <path
        d="M418.212 58.137C418.212 58.137 419.956 64.7677 420.454 69.9526C420.953 75.1873 422.198 81.2696 423.394 83.3137C424.59 85.4076 426.533 87.8505 422.198 92.6864C417.864 97.5722 418.91 102.059 418.362 105.898C417.864 109.737 418.262 113.725 409.494 116.816C400.874 119.857 389.066 113.376 389.066 113.376V66.4627L418.212 58.137Z"
        fill="#EADFF8"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M418.512 78.8268C418.512 80.123 417.466 81.1201 416.221 81.1201C414.975 81.1201 413.929 80.0731 413.929 78.8268C413.929 77.5804 414.975 76.5334 416.221 76.5334C417.466 76.5334 418.512 77.5804 418.512 78.8268Z"
        fill="#8D75E6"
      />
      <path
        d="M438.343 203.215L420.307 175.645L438.343 203.215Z"
        fill="#EADFF8"
      />
      <path
        d="M438.343 203.215L420.307 175.645"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M423.942 46.3713L386.027 47.8171L375.664 60.1811C373.372 59.2339 370.881 70.202 368.241 70.202C357.678 70.202 360.817 82.0176 360.817 92.6367C360.817 103.256 369.386 111.831 379.999 111.831C389.415 111.831 397.187 105.051 398.831 96.1265C399.429 93.4842 399.23 91.0912 398.831 89.2465C398.532 87.5515 397.984 85.9561 397.237 84.4106C396.091 80.821 395.145 74.8883 400.625 73.9411C407.251 72.7944 408.846 79.9237 408.846 79.9237C409.244 75.6361 410.141 64.8675 416.867 60.7295C425.038 55.6942 423.992 46.471 423.992 46.471L423.942 46.3713Z"
        fill="#97436E"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M519.003 172.604L462.704 177.34C462.704 177.34 447.259 127.635 421.949 119.708C405.209 114.423 387.472 111.831 387.472 111.831L377.757 112.778C366.696 113.875 357.878 114.772 348.76 121.153L340.091 129.08L330.974 187.111L336.803 214.881L380.846 237.166L407.899 225.549L439.138 203.763C439.138 203.763 443.522 210.942 457.373 210.942C464.049 210.942 522.939 197.382 522.939 197.382C522.939 197.382 530.263 183.771 519.053 172.604H519.003Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M519.001 172.603L462.702 177.339C462.702 177.339 453.883 137.605 429.321 127.484C413.926 121.103 401.819 147.227 401.819 147.227L439.036 203.812C439.036 203.812 443.42 210.991 457.271 210.991C463.947 210.991 522.837 197.431 522.837 197.431C522.837 197.431 530.161 183.82 518.951 172.653L519.001 172.603Z"
      fill="#8D75E6"
    />
    <path
      d="M418.509 78.826C418.509 80.1223 417.463 81.1194 416.218 81.1194C414.972 81.1194 413.926 80.0724 413.926 78.826C413.926 77.5797 414.972 76.5327 416.218 76.5327C417.463 76.5327 418.509 77.5797 418.509 78.826Z"
      fill="#483C72"
    />
    <mask
      id="mask2_215_1794"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="491"
      y="141"
      width="41"
      height="86"
    >
      <path
        d="M511.433 226.945C522.384 226.945 531.262 207.861 531.262 184.32C531.262 160.778 522.384 141.694 511.433 141.694C500.481 141.694 491.604 160.778 491.604 184.32C491.604 207.861 500.481 226.945 511.433 226.945Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_215_1794)">
      <path
        d="M558.862 170.21C558.862 170.21 561.453 171.755 561.603 174.348C561.752 176.94 558.763 178.835 558.763 178.835C558.763 178.835 561.254 179.782 561.603 181.876C562.001 184.418 559.809 186.114 559.809 186.114C559.809 186.114 560.507 186.313 560.606 188.955C560.706 191.598 557.019 193.243 555.176 193.741C553.332 194.24 550.442 194.988 549.097 195.087C547.752 195.187 544.314 195.337 541.574 195.387C538.136 195.387 534.948 194.689 532.008 194.14C529.069 193.642 513.076 195.686 513.076 195.686L511.432 176.492C511.432 176.492 521.496 174.348 528.67 172.104C531.56 171.207 536.99 166.421 539.282 165.224C541.524 164.028 545.51 163.081 545.51 163.081L554.378 162.283C555.973 162.034 559.46 161.884 561.553 161.585C563.894 161.286 568.129 160.638 572.663 160.039C575.603 159.641 577.346 160.937 577.546 162.383C577.895 165.324 574.905 165.972 572.115 166.62C569.674 167.219 558.813 170.21 558.813 170.21H558.862Z"
        fill="#EADFF8"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M425.186 183.372C420.502 183.871 415.819 183.522 411.285 182.126C384.531 174.099 372.524 135.112 384.531 94.9793C396.538 54.8462 427.926 28.8219 454.68 36.8485C481.435 44.8752 493.442 83.8617 481.435 123.995C476.951 139.051 469.727 152.113 461.008 162.134L459.015 164.726"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M498.974 174.249C500.568 155.154 507.045 141.644 514.867 141.644C523.885 141.644 531.258 160.738 531.258 184.269C531.258 207.801 523.935 226.895 514.867 226.895C508.54 226.895 502.86 216.575 500.12 202.965"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M516.958 226.396C509.584 223.156 503.905 205.557 503.905 184.319C503.905 163.081 509.535 145.482 516.958 142.241"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M370.979 306.214C443.171 365.89 479.741 438.828 453.235 474.125C424.986 511.716 335.854 492.372 254.146 430.901C172.437 369.38 129.092 289.064 157.341 251.473C179.263 222.258 237.954 227.443 301.377 259.999"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M376.81 153.559C379.45 145.582 382.439 123.895 378.304 115.021L376.81 153.559Z"
      fill="#EADFF8"
    />
    <path
      d="M376.81 153.559C379.45 145.582 382.439 123.895 378.304 115.021"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M411.933 183.073L413.029 187.56C413.029 187.56 422.445 187.011 426.78 185.316L425.185 183.372L411.933 183.073Z"
      fill="#483C72"
    />
    <path
      d="M436.496 204.809C436.496 204.809 438.29 213.135 452.738 214.63C452.738 214.63 458.169 214.78 465.642 212.885L493.891 205.257L496.781 201.269L436.496 204.859V204.809Z"
      fill="#483C72"
    />
    <path
      d="M479.493 133.317C479.493 133.317 477.5 145.681 466.539 160.538V155.104C466.539 155.104 476.852 139.001 478.197 133.367H479.493V133.317Z"
      fill="#483C72"
    />
    <path
      d="M439.037 203.762C439.037 203.762 443.421 210.941 457.272 210.941C463.948 210.941 522.838 197.38 522.838 197.38C522.838 197.38 530.162 183.77 518.952 172.603L462.653 177.339C462.653 177.339 458.268 153.857 438.29 131.373"
      fill="#8D75E6"
    />
    <path
      d="M439.037 203.762C439.037 203.762 443.421 210.941 457.272 210.941C463.948 210.941 522.838 197.38 522.838 197.38C522.838 197.38 530.162 183.77 518.952 172.603L462.653 177.339C462.653 177.339 458.268 153.857 438.29 131.373"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M456.225 158.843C464.096 149.121 470.673 136.857 474.808 122.848C486.815 82.7148 474.808 43.7283 448.054 35.7017"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M439.586 204.51L420.255 175.594L439.586 204.51Z" fill="#D7C4FA" />
    <path
      d="M439.586 204.51L420.255 175.594"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M370.978 306.213L381.889 315.586L379.099 321.818L367.092 311.947L370.978 306.213Z"
      fill="#483C72"
    />
    <path
      d="M499.869 202.565L502.16 211.589L507.242 210.342L505.2 201.418L499.869 202.565Z"
      fill="#483C72"
    />
    <path
      d="M312.041 110.533C304.169 105.947 279.457 114.572 253.55 131.472C228.738 147.625 211.001 166.072 209.955 175.893"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M233.521 119.06C217.528 112.479 215.834 103.654 215.834 103.654C211.849 105.2 208.411 102.607 206.069 98.6191C203.728 94.6806 207.913 90.8916 207.913 90.8916C207.913 90.8916 206.468 88.6481 206.916 84.1612C207.514 78.1786 212.447 78.1786 212.447 78.1786C212.845 76.6829 212.546 74.2401 216.084 72.894C219.621 71.5479 223.557 72.4951 223.557 72.4951C223.557 72.4951 209.108 67.061 207.564 65.8644C203.13 62.3247 206.518 55.5943 210.603 56.3422C212.945 56.7909 221.066 59.5329 221.066 59.5329L237.607 64.3688C237.607 64.3688 248.319 67.9085 251.657 69.0053C254.995 70.1021 256.988 73.5919 257.137 76.0348C257.287 78.4777 259.628 85.2081 259.977 88.9472C260.326 92.6864 262.767 102.657 263.664 105.399C267.002 123.646 275.372 138.353 275.073 139.351C273.578 144.785 271.287 147.876 262.319 150.518C253.201 153.16 247.422 153.16 247.422 153.16C245.678 148.275 233.571 119.06 233.571 119.06H233.521Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M225.398 73.1435L221.662 71.947"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M212.698 77.3811C212.698 80.9208 213.694 84.7098 215.388 88.4987C217.63 93.5341 220.619 94.4813 222.812 93.8332C223.459 93.6338 223.958 93.2848 224.356 92.886"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M207.715 90.5425C211.003 96.4752 215.736 97.3227 217.928 96.6746C220.121 96.0764 220.37 93.8329 220.37 93.8329"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M215.434 103.804C219.519 101.112 216.031 96.8247 216.031 96.8247"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M231.926 118.462C231.926 118.462 232.972 118.811 234.517 119.359C236.709 120.157 236.808 123.098 236.808 123.098"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M224.803 94.082C224.106 92.387 222.362 93.7829 221.166 93.8328C220.12 93.8826 219.971 94.2815 219.672 94.8797C219.423 95.478 218.875 96.1759 217.28 96.5249C215.636 96.8739 216.184 97.4223 217.23 98.6188C218.277 99.8153 216.981 101.51 216.732 102.308C216.483 103.106 215.736 103.455 216.085 104.851C216.433 106.247 215.835 105 215.835 105C219.572 103.953 218.875 100.065 218.875 100.065C221.067 99.5661 221.017 96.3754 221.017 96.3754C223.608 96.5249 224.654 94.0322 224.654 94.0322C224.654 94.0322 225.401 95.7771 224.704 94.0322L224.803 94.082Z"
      fill="#483C72"
    />
    <path
      d="M183.349 50.8584L261.47 74.5893L266.353 52.4538L183.349 50.8584Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M195.407 53.8999L264.311 61.5277L265.507 56.3926L195.407 53.8999Z"
      fill="#483C72"
    />
    <path
      d="M236.41 109.538C220.218 101.81 223.357 84.0619 224.453 80.1234C225.549 76.135 225.698 70.5513 224.752 64.9177C223.805 59.2342 224.851 52.7531 227.94 51.4569C231.428 50.0111 233.321 51.5067 235.115 54.3484C237.257 57.8383 239.947 65.6156 241.592 69.4545C242.987 72.7947 247.271 83.2643 248.915 86.3054C250.56 89.2967 252.503 95.2793 252.503 95.2793"
      fill="#EADFF8"
    />
    <path
      d="M236.41 109.538C220.218 101.81 223.357 84.0619 224.453 80.1234C225.549 76.135 225.698 70.5513 224.752 64.9177C223.805 59.2342 224.851 52.7531 227.94 51.4569C231.428 50.0111 233.321 51.5067 235.115 54.3484C237.257 57.8383 239.947 65.6156 241.592 69.4545C242.987 72.7947 247.271 83.2643 248.915 86.3054C250.56 89.2967 252.503 95.2793 252.503 95.2793"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M266.155 24.6346L256.141 26.7784L256.539 8.28223L167.956 52.2542L273.08 56.9904L306.212 33.9575L266.105 24.6845L266.155 24.6346Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M167.956 52.2544L256.141 26.7786"
      stroke="#483C72"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M241.941 68.7065C241.941 68.7065 242.539 70.5512 242.838 70.9002C243.087 71.2491 257.286 76.3842 257.286 76.3842L256.589 73.343C256.888 73.3929 241.941 68.7065 241.941 68.7065Z"
      fill="#483C72"
    />
    <path
      d="M206.666 57.9378L205.719 58.8352L224.751 67.0114L224.054 63.1726L206.666 57.8879V57.9378Z"
      fill="#483C72"
    />
    <mask
      id="mask3_215_1794"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="207"
      y="103"
      width="106"
      height="75"
    >
      <path
        d="M268.989 154.584C297.444 135.895 316.362 114.418 311.244 106.615C306.125 98.8113 278.909 107.636 250.453 126.326C221.998 145.016 203.08 166.492 208.199 174.296C213.317 182.099 240.534 173.274 268.989 154.584Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_215_1794)">
      <path
        d="M249.313 170.41C248.068 162.283 236.608 144.685 239.996 140.995C245.676 134.714 269.989 125.341 275.071 124.992C280.153 124.643 300.829 188.507 300.829 188.507L258.68 207.103C258.68 207.103 251.904 186.961 249.363 170.46L249.313 170.41Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M267.349 116.267C289.321 104.551 307.406 100.313 311.342 106.794C316.175 114.821 296.943 136.408 268.445 155.004C239.947 173.6 212.943 182.175 208.11 174.148C204.025 167.318 217.875 150.318 239.448 134.065"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M269.589 115.121L271.881 120.755L268.344 122.649L266.401 116.766L269.589 115.121Z"
      fill="#483C72"
    />
    <path
      d="M743.041 297.598C804.902 264.771 843.048 215.523 828.241 187.597C813.434 159.672 751.283 163.645 689.421 196.472C627.56 229.298 589.414 278.547 604.221 306.472C619.028 334.397 681.179 330.424 743.041 297.598Z"
      fill="#FAA3CA"
    />
    <path
      d="M607.54 311.085C593.252 281.398 631.372 233.81 693.189 200.969C749.094 171.299 805.28 165.214 826.456 184.951"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M672.125 70.6821L619.076 57.9654C616.693 54.3234 617.32 51.3103 619.693 50.2965C622.066 49.2826 633.22 52.3644 638.131 53.6769C643.041 54.9893 653.267 58.6971 659.916 60.8841C666.565 63.071 672.076 70.6935 672.076 70.6935L672.125 70.6821Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M697.397 233.059C680.461 244.02 662.316 241.237 662.316 241.237C662.316 241.237 645.705 206.409 641.521 199.654C637.337 192.9 613.679 175.686 609.342 170.247C605.004 164.808 600.252 148.152 598.95 144.104C597.648 140.057 593.366 135.731 592.832 131.248C592.299 126.765 589.333 117.116 588.064 104.87C587.475 99.2736 588.301 92.9382 591.694 92.3519C601.933 90.6303 607.194 101.127 608.426 104.218C610.729 109.927 610.771 116.469 612.964 119.951C615.157 123.433 623.913 132.244 627.925 134.534C631.938 136.824 643.299 145.181 643.299 145.181C643.299 145.181 653.117 138.797 657.457 134.151C661.748 129.516 667.731 120.288 661.727 114.726C655.724 109.163 645.33 93.1064 645.33 93.1064C639.03 94.831 623.075 92.6635 623.075 92.6635C623.075 92.6635 606.541 99.6409 600.5 94.1383C598.225 92.058 596.114 90.2464 596.003 88.8902C595.678 85.7409 598.683 85.2451 601.246 84.3915C603.81 83.5379 627.289 76.0676 627.289 76.0676C624.278 75.4387 618.569 72.6744 618.569 72.6744C608.035 71.5979 597.982 62.2698 599.25 56.9574C600.518 51.6449 611.862 54.2218 618.66 56.1693C625.458 58.1168 645.812 61.8183 651.06 62.3865C656.308 62.9546 670.69 67.5878 676.551 72.9788C682.363 78.381 692.12 88.8531 697.564 93.1127C703.008 97.3723 704.715 104.039 703.274 114.971C701.832 125.904 699.846 151.4 699.731 167.143C699.604 182.837 717.174 210.582 717.174 210.582C717.174 210.582 714.441 222.123 697.457 233.096L697.397 233.059Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M627.264 75.9704C627.264 75.9704 642.969 71.7972 647.265 70.6934C651.512 69.6008 655.906 72.211 658.879 75.3062C661.791 78.3642 672.108 88.6034 674.657 90.9783C677.194 93.3047 684.513 99.0214 684.513 99.0214"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M641.315 143.698C646.304 146.886 650.821 147.83 655.573 145.391C660.325 142.952 667.204 136.69 667.204 136.69"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M589.406 97.9017C589.406 97.9017 587.547 104.632 589.462 108.895C591.377 113.158 593.31 112.452 596.188 112.292C599.067 112.133 602.035 108.626 601.642 106.721C601.25 104.816 599.252 98.8825 597.148 98.1956C595.033 97.4602 589.406 97.9017 589.406 97.9017Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M580.824 13.03L594.31 91.9463L597.968 92.9363L584.217 12.4437L580.824 13.03Z"
      fill="#BC5689"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M606.379 11.8332C606.379 11.8332 618.189 7.85134 621.397 2.95706L624.921 24.6597C624.921 24.6597 621.793 31.4296 609.904 33.5359L585.155 37.9746L581.63 16.272L606.379 11.8332Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M589.306 42.5849L585.843 41.6568L584.926 38.0845L588.298 37.719L589.306 42.5849Z"
      fill="#483C72"
    />
    <mask
      id="mask4_215_1794"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="597"
      y="159"
      width="233"
      height="165"
    >
      <path
        d="M743.039 297.563C804.901 264.736 841.676 212.904 825.18 181.793C808.684 150.681 745.162 152.071 683.301 184.897C621.44 217.723 584.664 269.555 601.16 300.667C617.656 331.779 681.178 330.389 743.039 297.563Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_215_1794)">
      <path
        d="M695.243 334.203C673.865 294.291 667.594 268.929 654.477 243.166C657.18 224.26 710.009 202.679 720.923 201.875C731.837 201.071 819.999 369.671 819.999 369.671L739.672 417.475C739.672 417.475 711.852 377.581 695.231 334.154L695.243 334.203Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M705.361 188.662C761.635 163.205 814.733 162.089 828.253 187.604C843.052 215.535 804.906 264.767 743.089 297.607C681.212 330.41 619.114 334.393 604.266 306.474C592.776 284.836 613.129 250.349 651.728 220.618"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M598.755 85.125L599.591 92.8781L598.003 92.4302L596.921 86.2539L598.755 85.125Z"
      fill="#483C72"
    />
    <path
      d="M714.008 191.043L713.032 188.606L711.511 186.169L705.722 188.606L706.696 191.531C706.696 191.531 707.737 192.376 708.159 193.967C708.159 193.967 709.586 192.896 711.571 191.972L714.008 191.043Z"
      fill="#483C72"
    />
    <path
      d="M112.446 221.161C121.787 215.6 118.433 192.715 104.955 170.046C91.4774 147.377 72.9793 133.508 63.6385 139.069C54.2978 144.63 57.6516 167.515 71.1294 190.184C84.6073 212.853 103.105 226.722 112.446 221.161Z"
      fill="#D6EDD9"
    />
    <path
      d="M61.6113 140.781C71.4761 138.587 88.0669 151.949 100.423 172.738C112.779 193.528 116.615 214.467 109.989 222.094"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.235 138.387C125.235 138.387 128.324 138.587 129.719 140.98C131.114 143.373 129.171 146.464 129.171 146.464C129.171 146.464 131.911 146.165 133.206 147.959C134.801 150.103 133.505 152.746 133.505 152.746C133.505 152.746 134.252 152.596 135.548 155.039C136.843 157.482 134.253 160.672 132.758 162.019C131.263 163.315 128.971 165.359 127.726 166.107C126.48 166.854 123.391 168.55 120.9 169.896C117.761 171.541 114.423 172.339 111.484 173.236C108.494 174.133 94.6438 183.556 94.6438 183.556L84.1313 166.505C84.1313 166.505 92.4516 159.775 98.0815 154.341C100.373 152.147 103.163 145.168 104.708 142.974C106.252 140.83 109.491 138.088 109.491 138.088L117.363 133.202C118.758 132.255 121.897 130.46 123.74 129.164C125.783 127.768 129.42 125.226 133.356 122.533C135.897 120.789 138.139 121.138 138.986 122.434C140.68 125.026 138.188 127.02 135.946 128.915C133.954 130.61 125.285 138.487 125.285 138.487L125.235 138.387Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask5_215_1794"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="59"
      y="136"
      width="62"
      height="86"
    >
      <path
        d="M114.426 219.98C124.831 213.786 122.339 190.387 108.862 167.718C95.3837 145.049 76.0229 131.694 65.618 137.888C55.2132 144.082 57.7043 167.481 71.1822 190.15C84.6601 212.819 104.021 226.174 114.426 219.98Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_215_1794)">
      <path
        d="M55.9811 181.76C62.9562 177.672 76.9562 162.167 80.643 163.364C86.7712 165.308 97.8815 180.315 98.7783 184.104C99.6751 187.893 46.2658 228.125 46.2658 228.125L25.0913 202.949C25.0913 202.949 41.9312 190.036 56.0309 181.81L55.9811 181.76Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M108.095 175.58C118.956 196.319 121.049 216.012 112.479 221.147C103.113 226.731 84.6287 212.871 71.1767 190.187C57.7247 167.503 54.3368 144.67 63.7034 139.086C70.9276 134.749 83.6821 142.127 95.3405 156.236"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M135.1 126.622C135.1 126.622 134.552 126.323 134.203 125.824C133.854 125.326 133.904 124.728 133.904 124.728"
      fill="#EADFF8"
    />
    <path
      d="M135.1 126.622C135.1 126.622 134.552 126.323 134.203 125.824C133.854 125.326 133.904 124.728 133.904 124.728"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M123.443 139.634L121.4 140.581"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M132.558 155.088L133.554 152.695"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.797 175.031L103.263 177.823L104.409 180.067L109.092 177.574L107.797 175.031Z"
      fill="#483C72"
    />
  </svg>
);
