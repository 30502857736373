import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import { colors } from "../../../styles/colors.styles";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import RigidDotGrid from "../../decorations/RigidDotGrid";
import { ActionCard } from "../../decorations/storyboard/ActionCard";
import PagePreviewCard from "../../decorations/storyboard/PagePreviewCard";
import gsap from "gsap";
import LoadingIndicator from "../../utilities/LoadingIndicator";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";

const canvasWidth = 24 * 18;
const canvasHeight = 24 * 14;

const AppStoryboardViewport = styled.div`
  position: relative;
  overflow: hidden;
`;
const CanvasPositioner = styled.div`
  width: ${canvasWidth}px;
  height: ${canvasHeight}px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const AppStoryboardCanvas = styled.div`
  position: relative;
  width: ${canvasWidth}px;
  height: ${canvasHeight}px;
  transform-origin: 50% 50%;
  transform: scale(1.1);
  ${fromTablet} {
    transform-origin: 50% 35%;
  }
  opacity: 0;
  transition: 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const LoadingIndicatorWrapper = styled.div`
  position: absolute;
  top: calc(42.5% - 12px);
  left: calc(50% - 12px);
  opacity: 0.25;
`;

export const AppStoryboard = () => {
  const [ready, setReady] = useState(false);
  const viewportRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  useOnMount(() => {
    const observer = new ResizeObserver(entries => {
      const widthScalar = entries[0].contentRect.width / canvasWidth;
      const heightScalar = (entries[0].contentRect.height - 24) / canvasHeight;
      setScale(Math.min(Math.min(widthScalar, heightScalar), 1));
    });
    if (viewportRef.current) observer.observe(viewportRef.current);
    setReady(true);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <AppStoryboardViewport ref={viewportRef}>
      <CanvasPositioner>
        <AppStoryboardCanvas
          style={
            ready
              ? {
                  transform: `scale(${scale})`,
                  opacity: 1,
                }
              : undefined
          }
        >
          <RigidDotGrid
            cellSize={24}
            dotSize={1}
            color={colors.purple100}
            offset={24 * -10}
            gridXOrigin="start"
          />
          <ActionCard
            id="home-hero-email-action"
            type="receive-email"
            title="Receive email"
            hasOutgoingLink
            topByGridCell={1.5}
            leftByGridCell={1.5}
            eventCount="26k"
          />
          <ActionCard
            id="home-hero-trigger-action"
            type="trigger"
            title="Has attachment?"
            hasIncomingLink
            hasOutgoingLink
            topByGridCell={4.5}
            leftByGridCell={1.5}
            eventCount="25k"
          />
          <ActionCard
            id="home-hero-sts-action"
            type="send-to-story"
            title="Check file safety"
            hasIncomingLink
            hasOutgoingLink
            topByGridCell={7.5}
            leftByGridCell={1.5}
            eventCount="25k"
          />
          <ActionCard
            id="home-hero-webhook-action"
            type="webhook"
            title="Receive EML file"
            hasOutgoingLink
            topByGridCell={5.5}
            leftByGridCell={10.5}
            eventCount="8k"
          />
          <ActionCard
            id="home-hero-ai-action"
            type="ai"
            title="Analyze email"
            hasIncomingLink
            hasOutgoingLink
            topByGridCell={11.5}
            leftByGridCell={4.5}
            eventCount="33k"
          />
          <PagePreviewCard
            id="home-hero-page-preview-card"
            color="green"
            hasOutgoingLink
            topByGridCell={-3.5}
            leftByGridCell={8.5}
            widthByGridCell={8}
          />
          <ActionLinks>
            <LinkOne />
            <LinkTwo />
            <LinkThree />
            <LinkFour />
            <LinkFive />
            <LinkSix />
          </ActionLinks>
        </AppStoryboardCanvas>
      </CanvasPositioner>
      {!ready && (
        <LoadingIndicatorWrapper>
          <LoadingIndicator size={24} />
        </LoadingIndicatorWrapper>
      )}
    </AppStoryboardViewport>
  );
};

const flashBranch = (...config: (string | [string, number])[]) => {
  config.forEach((c, i) => {
    const id = typeof c === "string" ? c : c[0];
    const brightness = typeof c === "string" ? 1.5 : c[1];
    const el = document.getElementById(id);
    gsap.fromTo(
      el,
      { filter: "brightness(1)" },
      {
        filter: `brightness(${brightness})`,
        duration: 0.1,
        delay: i * 0.05,
      }
    );
    gsap.to(el, {
      filter: "brightness(1)",
      duration: 2,
      delay: i * 0.05 + 0.1,
    });
  });
};

export const flashWebhookBranch = () => {
  flashBranch(
    ["home-hero-webhook-action-icon-container", 1.4],
    "home-hero-webhook-outgoing-link",
    "home-hero-ai-action-icon-container",
    "home-hero-sts-outgoing-link"
  );
};

export const flashEmailBranch = () => {
  flashBranch(
    "home-hero-email-action-icon-container",
    "home-hero-email-outgoing-link",
    ["home-hero-trigger-action-icon-container", 1.3],
    "home-hero-trigger-outgoing-link",
    "home-hero-sts-action-icon-container",
    "home-hero-et-outgoing-link",
    "home-hero-ai-action-icon-container",
    "home-hero-sts-outgoing-link"
  );
};
export const flashPageBranch = () => {
  flashBranch(
    ["home-hero-page-preview-card", 1.05],
    ["home-hero-page-outgoing-link", 1.15],
    "home-hero-ai-action-icon-container",
    "home-hero-sts-outgoing-link"
  );
};

const ActionLinks = styled.div`
  > svg {
    display: block;
    position: absolute;
    &:nth-child(1) {
      top: ${24 * 3.5 - 3}px;
      left: ${24 * 2.5 - 1}px;
    }
    &:nth-child(2) {
      top: ${24 * 6.5 - 3}px;
      left: ${24 * 2.5 - 1}px;
    }
    &:nth-child(3) {
      top: ${24 * 9.5 - 3}px;
      left: ${24 * 2.5 - 1}px;
    }
    &:nth-child(4) {
      top: ${24 * 7.5 - 3}px;
      left: ${24 * 5.5 - 1}px;
    }
    &:nth-child(5) {
      top: ${24 * 13.5 - 3}px;
      left: ${24 * 5.5 - 1}px;
    }
    &:nth-child(6) {
      top: ${24 * 3.5}px;
      left: ${24 * 5.5 - 1}px;
    }
  }
`;

const LinkOne = () => (
  <svg
    id="home-hero-email-outgoing-link"
    width="2"
    height="32"
    viewBox="0 0 2 32"
    fill="none"
  >
    <line
      x1="1"
      y1="4.37115e-08"
      x2="0.999999"
      y2="32"
      stroke="url(#paint0_linear_1662_1803)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1662_1803"
        x1="0.999985"
        y1="3"
        x2="0.999985"
        y2="28.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E269A4" />
        <stop offset="1" stopColor={colors.green400} />
      </linearGradient>
    </defs>
  </svg>
);

const LinkTwo = () => (
  <svg
    id="home-hero-trigger-outgoing-link"
    width="2"
    height="32"
    viewBox="0 0 2 32"
    fill="none"
  >
    <line
      x1="1"
      y1="0"
      x2="1"
      y2="32"
      stroke="url(#paint0_linear_1662_1804)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1662_1804"
        x1="1"
        y1="4"
        x2="1"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={colors.green400} />
        <stop offset="1" stopColor={colors.purple} />
      </linearGradient>
    </defs>
  </svg>
);

const LinkThree = () => (
  <svg
    id="home-hero-et-outgoing-link"
    width="74"
    height="56"
    viewBox="0 0 74 56"
    fill="none"
  >
    <path
      d="M1 0.5V22C1 29.732 7.26801 36 15 36H65C69.4183 36 73 39.5817 73 44V56"
      stroke="url(#paint0_linear_1662_1805)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1662_1805"
        x1="1"
        y1="4"
        x2="73"
        y2="52"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={colors.purple} />
        <stop offset="1" stopColor={colors.yellow400} />
      </linearGradient>
    </defs>
  </svg>
);

const LinkFour = () => (
  <svg
    id="home-hero-webhook-outgoing-link"
    width="146"
    height="104"
    viewBox="0 0 146 104"
    fill="none"
  >
    <path
      d="M145 0V34C145 57.196 126.196 76 103 76H11C5.47715 76 1 80.4772 1 86V104"
      stroke="url(#paint0_linear_1662_1806)"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1662_1806"
        x1="121"
        y1="3"
        x2="1"
        y2="102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={colors.teal400} />
        <stop offset="1" stopColor={colors.yellow400} />
      </linearGradient>
    </defs>
  </svg>
);

const LinkFive = () => (
  <svg
    id="home-hero-sts-outgoing-link"
    width="2"
    height="300"
    viewBox="0 0 2 300"
    fill="none"
  >
    <line
      x1="1"
      y1="0"
      x2="1"
      y2="300"
      stroke="url(#paint0_linear_1662_1809)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1662_1809"
        x1="1"
        y1="4"
        x2="1"
        y2="100"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={colors.yellow400} />
        <stop offset="0.4" stopColor={colors.yellow400} stopOpacity={0.2} />
        <stop offset="1" stopColor={colors.yellow400} stopOpacity={0.25} />
      </linearGradient>
    </defs>
  </svg>
);

const LinkSix = () => (
  <svg
    id="home-hero-page-outgoing-link"
    width="98"
    height="197"
    viewBox="0 0 98 197"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M97 0V126C97 143.673 82.6731 158 65 158H11C5.47715 158 1 162.477 1 168V197"
      stroke="url(#paint0_linear_1668_1848)"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1668_1848"
        x1="97"
        y1="3.5"
        x2="9.49999"
        y2="188.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BADEC0" />
        <stop offset="0.45" stopColor="#D8C384" stopOpacity="0.38" />
        <stop offset="1" stopColor="#F5A847" stopOpacity="0.15" />
      </linearGradient>
    </defs>
  </svg>
);
