import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import {
  PageTree,
  TreePage,
  getBreadcrumbsFromTree,
} from "../utils/tree.utils";
import { graphql } from "gatsby";
import { ApiPageByIdQuery, DatoCmsApiPage } from "../../graphql-types";
import VotingBanner from "../components/hub/VotingBanner";
import Spacing from "../components/layout/Spacing";
import {
  HubPageHeader,
  WiderArticlePageBody,
} from "../components/hub/HubPageComponents";
import StandardArticle from "../components/articles/StandardArticle";
import { processAPIPageHtml } from "../utils/apiPage.utils";
import PageBreadCrumbs from "../components/basic/PageBreadCrumbs";
import Page from "./Page.template";
import ogImage from "../../static/images/og/tines-api.png";
import { useEffect, useState } from "react";
import { useSiteContext } from "../context/site.context";
import MarkdownIt from "markdown-it";
import MarkdownItFrontMatter from "markdown-it-front-matter";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { PlugTwoToneIcon } from "../components/icons/twoTone/PlugTowToneIcon";
import VersionSelect from "../components/hub/api/VersionSelect";

const md = MarkdownIt({
  html: true,
}).use(MarkdownItFrontMatter, () => {});

export const VERSION_REGEX_MATCHER = /(\d-\d)/;

const APIPageTemplate: PageComponent<{
  pageContext: { tree: PageTree };
  data: ApiPageByIdQuery;
}> = props => {
  const page = props.data.page as DatoCmsApiPage;
  const siteContext = useSiteContext();
  const { location } = props;
  const levels = getBreadcrumbsFromTree(
    location.pathname,
    {
      title: "API",
      path: "/api",
    },
    props.pageContext.tree
  );
  const html = md.render(page.markdownContent ?? "");
  const [processedHtmlBlocks, setProcessedHtmlBlocks] = useState(
    processAPIPageHtml(html, siteContext.isClient)
  );
  useEffect(() => {
    const blocks = processAPIPageHtml(html, siteContext.isClient);
    setProcessedHtmlBlocks(blocks);
  }, [html, siteContext.isClient]);

  const resource = props.pageContext.tree.find(
    item => item.title === levels[1]?.title
  );
  const findSubResource = (children: TreePage[], i: number) => {
    return children.find(item => item.title === levels[i]?.title);
  };
  const sectionCount = levels.length;
  let targetResource = resource;
  for (let i = 2; i < sectionCount - 1; i++) {
    if (targetResource?.treeChildren) {
      targetResource = findSubResource(targetResource.treeChildren, i);
    }
  }
  const versions = targetResource?.treeChildren.filter(
    child => child.title === page.title
  );
  const versionOptions =
    versions?.map(version => {
      const matches = version.markdownSourcePath.match(VERSION_REGEX_MATCHER);
      const versionLabel =
        matches && matches.length > 0 ? matches[0].replace("-", ".") : "1.0";
      return {
        value: version.path + "/",
        label: versionLabel,
      };
    }) ?? [];

  const asideHeader =
    levels[1]?.title == "Cases" ? (
      <VersionSelect
        versionOptions={versionOptions}
        value={location.pathname}
      />
    ) : null;

  return (
    <Page {...props}>
      <SEO
        title={`${page.title} | API | Tines`}
        image={ogImage}
        description="Browse the capabilities and endpoints of our comprehensive REST API."
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="apiPage"
        recordId={page.id}
      />
      <WiderArticlePageBody
        generateTableOfContents
        supportsColorThemes
        outlineAsideHeader={asideHeader}
      >
        <PageBreadCrumbs icon={<PlugTwoToneIcon />} levels={levels} />
        <HubPageHeader>
          <h1>{page.title}</h1>
        </HubPageHeader>
        <StandardArticle>
          {processedHtmlBlocks.map((block, i) =>
            typeof block === "string" ? (
              <div
                className="ArticleBlock"
                key={i}
                dangerouslySetInnerHTML={{ __html: block }}
              />
            ) : (
              <div className="ArticleBlock" key={i}>
                {block}
              </div>
            )
          )}
        </StandardArticle>
        <Spacing size="lg" />
        <VotingBanner />
      </WiderArticlePageBody>
    </Page>
  );
};

export const apiPageByIdQuery = graphql`
  query ApiPageById($id: String) {
    page: datoCmsApiPage(originalId: { eq: $id }) {
      id: originalId
      title
      slug
      meta {
        updatedAt
      }
      markdownContent
    }
  }
`;
export default APIPageTemplate;
