import { styled } from "@linaria/react";
import {
  SITE_NAV_BAR_HEIGHT,
  SITE_NAV_BAR_HEIGHT_MOBILE,
  whenDesktopNav,
} from "./SiteNav";

const SiteNavHeightPlaceholder = styled.div`
  box-sizing: content-box;
  height: ${SITE_NAV_BAR_HEIGHT_MOBILE}px;
  pointer-events: none;
  ${whenDesktopNav} {
    height: ${SITE_NAV_BAR_HEIGHT}px;
  }
`;

export default SiteNavHeightPlaceholder;
