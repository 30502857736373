import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import { GRID_MAX_WIDTH } from "../../constants/globalGrid.constants";
import { useSiteContext } from "../../context/site.context";
import {
  breakpoints,
  fromDesktop,
  fromDesktopMd,
  fromPhoneLg,
  fromTablet,
  onlyPhones,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { flashNotice } from "../../utils/notice.utils";
import Button from "../forms/Button";
import CookieConsentManager from "./CookieConsentManager";
import {
  acceptAllCookies,
  enableFeaturesBasedOnCookieConsent,
  saveCookieConsentState,
} from "./gdpr";
import { zIndex } from "../../styles/zIndexes.styles";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";

const CookieConsentBannerDiv = styled.div`
  display: grid;
  z-index: ${zIndex("CookieConsentBanner")};
  transform: translateZ(0);
  font-size: 12px;
  ${onlyPhones} {
    grid-template-areas: "header" "manager" "controls";
    grid-gap: 14px;
    border-radius: 12px 12px 0 0;
  }
  ${fromTablet} {
    align-items: baseline;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "header controls" "manager manager";
    grid-gap: 0 14px;
    border-radius: 14px 14px 0 0;
    max-width: calc(100% - 48px);
  }
  ${fromDesktop} {
    font-size: 14px;
    max-width: calc(100% - 200px);
  }
  ${fromDesktopMd} {
    width: calc(100% - 200px);
    max-width: ${maxPageContentWidth}px;
  }
  background-color: #2a2a32;
  color: ${colorsV4.white};
  padding: 14px;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${GRID_MAX_WIDTH + 28}px;
  width: 100%;
  @media screen and (min-width: ${breakpoints.tablet + 56}px) {
    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      width: 14px;
      height: 14px;
    }
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 -6.11959e-07L14 14L0 14C7.73199 14 14 7.73199 14 -6.11959e-07Z' fill='%232A2A32'/%3E%3C/svg%3E%0A");
      right: 100%;
    }
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 14H0V0C0 7.73199 6.26801 14 14 14Z' fill='%232A2A32'/%3E%3C/svg%3E%0A");
      left: 100%;
    }
  }
`;
const Header = styled.div`
  grid-area: header;
  padding-left: 14px;
  p {
    a {
      color: ${colorsV4.purple300};
      font-weight: 600;
      text-decoration: none;
      &:hover {
        color: ${colorsV4.purple100};
      }
    }
  }
`;
const Controls = styled.div`
  grid-area: controls;
  ${uptoPhoneLg} {
    display: grid;
  }
  ${fromPhoneLg} {
    display: flex;
    flex-direction: row-reverse;
  }
`;
const Manager = styled.div`
  grid-area: manager;
  ${fromTablet} {
    padding-top: 14px;
  }
`;
const TextButton = styled.button`
  appearance: none;
  border: 0;
  background-color: transparent;
  color: ${colorsV4.purple300};
  font-weight: 600;
  text-decoration: none;
  padding: 1em;
  cursor: pointer;
  &:hover {
    color: ${colorsV4.purple100};
  }
  ${fromTablet} {
    padding: 0.5em 1em;
  }
`;

const CookieConsentBanner = () => {
  const siteContext = useSiteContext();
  const {
    cookieConsentState,
    setCookieConsentState,
    setShouldDisplayCookieConsentManager,
  } = siteContext;
  const { hasReviewed } = cookieConsentState;
  const formState = useState(cookieConsentState);
  const formStateRef = useRef(formState[0]);
  formStateRef.current = formState[0];
  const [shouldShowManager, setShouldShowManager] = useState(hasReviewed);
  const openManager = () => {
    setShouldShowManager(true);
  };
  const save = () => {
    const state = { ...formStateRef.current, hasReviewed: true };
    setCookieConsentState(state);
    saveCookieConsentState(state);
    setShouldDisplayCookieConsentManager(false);
    flashNotice("Your preferences have been saved.");
    enableFeaturesBasedOnCookieConsent(siteContext, state, cookieConsentState);
  };
  const acceptAllManually = () => {
    const newState = acceptAllCookies(siteContext, true);
    enableFeaturesBasedOnCookieConsent(siteContext, newState);
    setShouldDisplayCookieConsentManager(false);
    flashNotice("Your preferences have been saved.");
  };
  return (
    <CookieConsentBannerDiv>
      <Header>
        <p>
          Tines uses cookies to offer you a better experience.
          {/* See <Link to="/cookie-notice">Cookie Notice</Link> for details. */}
        </p>
      </Header>
      <Controls>
        {shouldShowManager ? (
          <>
            <Button onClick={save}>Save preferences</Button>
          </>
        ) : (
          <>
            <Button appearance="filled-white" onClick={acceptAllManually}>
              Accept all
            </Button>
            <TextButton onClick={openManager}>More options</TextButton>
          </>
        )}
      </Controls>
      {shouldShowManager && (
        <Manager>
          <CookieConsentManager formState={formState} />
        </Manager>
      )}
    </CookieConsentBannerDiv>
  );
};

export default CookieConsentBanner;
