import React from "react";
import { DatoCmsPerson } from "../../../graphql-types";
import { PartialAndNullable } from "../../types/helper.types";

type Props = {
  as?: keyof HTMLElementTagNameMap | "fragment";
  person: PartialAndNullable<DatoCmsPerson>;
  className?: string;
};

const PersonTitleWithOrgName = (props: Props) => {
  const children = [props.person.title, props.person.organization?.name]
    .filter(i => i)
    .join(", ");
  return props.as === "fragment"
    ? React.Fragment({ children })
    : React.createElement(props.as ?? "p", {
        className: props.className,
        children,
      });
};

export default PersonTitleWithOrgName;
