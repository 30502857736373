export const TinesExplainedTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3H5V14H6V3ZM4 14.7778V16H13C14.1046 16 15 15.1046 15 14V5C15 4.44772 14.5523 4 14 4H12V5H14V7.5V10H12V12.1978C12 12.6665 11.6745 13.0723 11.2169 13.174L4 14.7778Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 13.7534V3.80218L11 2.24662V5.00001V5.50001V9.50001V10V12.1978L4 13.7534ZM12 10V12.1978C12 12.6665 11.6745 13.0723 11.2169 13.174L7.5 14H13V10H12ZM3 15V13.9756V3.00001L10.7831 1.27043C11.4076 1.13165 12 1.60687 12 2.24662V3.00001H13C13.5523 3.00001 14 3.44772 14 4.00001V5.08536C14.5826 5.29128 15 5.8469 15 6.50001V8.50001C15 9.15312 14.5826 9.70873 14 9.91465V14C14 14.5523 13.5523 15 13 15H4H3ZM12 4.00001V5.00001H13V4.00001H12ZM12 6.00001V9.00001H13.5C13.7761 9.00001 14 8.77615 14 8.50001V6.50001C14 6.22386 13.7761 6.00001 13.5 6.00001H12Z"
    />
    <path opacity="0.5" d="M11 11H13C13.5523 11 14 10.5523 14 10V9H11V11Z" />
  </svg>
);
