export const SOC2023ConclusionIllustrationRight = () => (
  <svg
    width="526"
    height="230"
    viewBox="0 0 526 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M429.56 215.765C447.149 215.765 461.408 213.121 461.408 209.859C461.408 206.597 447.149 203.953 429.56 203.953C411.97 203.953 397.711 206.597 397.711 209.859C397.711 213.121 411.97 215.765 429.56 215.765Z"
      fill="#222027"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M448.878 209.182C450.164 211.017 436.887 215.774 422.099 215.774C407.311 215.774 393.934 210.943 395.319 209.182C398.426 205.235 422.099 176.07 422.099 176.07C422.099 176.07 445.987 205.06 448.878 209.182Z"
      fill="#E269A4"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M367.174 152.232C381.298 152.232 392.748 149.9 392.748 147.022C392.748 144.145 381.298 141.812 367.174 141.812C353.05 141.812 341.6 144.145 341.6 147.022C341.6 149.9 353.05 152.232 367.174 152.232Z"
      fill="#222027"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M381.669 146.359C382.765 148.644 371.982 152.127 360.032 152.127C348.081 152.127 337.367 148.676 338.395 146.359C340.413 141.811 360.032 105.598 360.032 105.598C360.032 105.598 379.484 141.811 381.669 146.359Z"
      fill="#FDBD74"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M381.669 117.092C382.793 119.364 373.501 123.032 361.552 123.032C349.602 123.032 337.181 119.318 338.396 117.092C340.414 113.394 360.033 76.332 360.033 76.332C360.033 76.332 379.72 113.158 381.669 117.092Z"
      fill="#A990F5"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M379.782 87.8285C380.975 90.0032 370.941 95.2881 360.033 95.2881C349.127 95.2881 339.196 90.0674 340.283 87.8285C342.125 84.0329 360.033 56.418 360.033 56.418C360.033 56.418 378.22 84.9793 379.782 87.8285Z"
      fill="#FD975D"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M351.425 94.5499C351.425 94.5499 363.761 99.8577 371.363 97.9931L369.787 94.1191C369.787 94.1191 358.453 96.271 351.425 94.5499Z"
      fill="#222027"
    />
    <path
      d="M351.187 122.63C351.187 122.63 363.526 127.938 371.128 126.073L369.55 122.199C369.55 122.199 358.216 124.352 351.187 122.63Z"
      fill="#222027"
    />
    <path
      d="M145.231 206.788C145.213 206.788 145.196 206.788 145.179 206.788C146.839 205.772 147.752 204.682 147.752 203.546C147.752 198.449 129.605 194.27 106.511 193.855C111.571 192.862 114.607 191.596 114.607 190.216C114.607 187.033 89.061 185.441 69.1649 185.441C61.9064 185.441 54.4654 185.653 47.9336 186.076C44.5897 184.556 36.9924 183.49 28.1433 183.49C16.2058 183.49 6.52664 185.425 6.52664 187.813C6.52664 190.201 16.2058 192.135 28.1433 192.135C29.3436 192.135 30.5124 192.11 31.6597 192.074C39.9837 194.153 60.5411 195.697 74.6323 195.945C64.3083 197.729 57.6858 200.468 57.6858 203.546C57.6858 208.252 73.1486 212.175 93.6959 213.076C90.0157 214.552 87.9489 216.207 87.9489 217.956C87.9489 224.123 113.595 229.124 145.232 229.124C176.866 229.124 202.513 224.123 202.513 217.956C202.513 211.79 176.865 206.788 145.231 206.788Z"
      fill="#FDA2CB"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M174.248 142.693C183.012 142.693 190.116 141.753 190.116 140.594C190.116 139.436 183.012 138.496 174.248 138.496C165.484 138.496 158.38 139.436 158.38 140.594C158.38 141.753 165.484 142.693 174.248 142.693Z"
      fill="#222027"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M169.526 101.758L159.698 128.244L149.87 101.758L140.043 128.244L130.215 101.758C130.215 101.758 116.873 139.075 116.873 140.191C116.873 141.31 122.847 142.845 130.215 142.845C134.109 142.845 137.604 142.871 140.043 142.133C142.484 142.871 145.976 142.845 149.87 142.845C153.767 142.845 157.257 142.871 159.698 142.133C162.139 142.871 165.629 142.845 169.526 142.845C176.897 142.845 182.869 141.308 182.869 140.191C182.868 139.075 169.526 101.758 169.526 101.758Z"
      fill="#FDBD74"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.1484 192.671C43.262 192.671 54.7034 190.988 54.7034 188.912C54.7034 186.836 43.262 185.152 29.1484 185.152C15.0347 185.152 3.59332 186.836 3.59332 188.912C3.59332 190.988 15.0347 192.671 29.1484 192.671Z"
      fill="#222027"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.7076 153.327C30.7076 154.891 31.9318 160.146 39.6273 167.704C47.1691 175.11 47.746 182.419 47.746 187.574C47.746 189.89 37.2821 191.769 24.3724 191.769C11.4639 191.769 1 189.89 1 187.574C1 182.417 1.57686 175.11 9.11742 167.704C16.8154 160.146 18.0396 154.716 18.0396 153.327C18.0396 151.938 16.8154 146.507 9.11742 138.947C1.57686 131.54 1 124.23 1 119.078C1 116.759 11.4639 114.885 24.3724 114.885C37.2821 114.885 47.746 116.759 47.746 119.078C47.746 124.231 47.1691 131.542 39.6273 138.947C31.9306 146.506 30.7076 151.761 30.7076 153.327Z"
      fill="#FFD8CF"
      stroke="#222027"
      strokeMiterlimit="10"
    />
    <path
      d="M24.3724 122.551C36.8402 122.551 46.9474 120.844 46.9474 118.738C46.9474 116.631 36.8402 114.924 24.3724 114.924C11.9045 114.924 1.79725 116.631 1.79725 118.738C1.79725 120.844 11.9045 122.551 24.3724 122.551Z"
      fill="#8D75E6"
      stroke="#222027"
      strokeMiterlimit="10"
    />
    <path
      d="M24.3724 191.762C36.8402 191.762 46.9474 190.055 46.9474 187.949C46.9474 185.842 36.8402 184.135 24.3724 184.135C11.9045 184.135 1.79725 185.842 1.79725 187.949C1.79725 190.055 11.9045 191.762 24.3724 191.762Z"
      fill="#8D75E6"
      stroke="#222027"
      strokeMiterlimit="10"
    />
    <path
      d="M24.3723 188.999C27.2855 188.999 29.6471 188.506 29.6471 187.897C29.6471 187.288 27.2855 186.795 24.3723 186.795C21.4592 186.795 19.0976 187.288 19.0976 187.897C19.0976 188.506 21.4592 188.999 24.3723 188.999Z"
      fill="#222027"
      stroke="#222027"
      strokeMiterlimit="10"
    />
    <path
      d="M525.38 147.238H422C422 147.238 423.967 134.903 436.038 131.231C448.109 127.558 448.633 126.311 452.963 121C452.963 121 454.734 128.476 447.453 133.987C442.877 137.449 445.091 143.04 451.913 138.317C458.734 133.594 464.417 128.048 471.658 130.051C480.908 132.609 487.402 127.886 488.976 121.786C488.976 121.786 493.107 130.248 487.009 134.972C482.694 138.312 485.5 143.565 491.797 138.843C498.095 134.12 502.949 134.184 510.426 137.333C517.079 140.133 519.083 147.238 525.38 147.238Z"
      fill="#FE9589"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M267.309 170.04C283.418 170.04 296.476 168.132 296.476 165.777C296.476 163.422 283.418 161.514 267.309 161.514C251.2 161.514 238.142 163.422 238.142 165.777C238.142 168.132 251.2 170.04 267.309 170.04Z"
      fill="#222027"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M284.718 165.253C284.718 167.67 273.647 170.215 259.991 170.215C246.335 170.215 235.265 167.67 235.265 165.253C235.265 162.836 259.991 1 259.991 1C259.991 1 284.718 162.836 284.718 165.253Z"
      fill="#C2AAFA"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
