import {
  Note,
  RuntimeNoteRecord,
  StoryRecord,
} from "../../../types/tines.types";
import { getPositionInViewport } from "./storyboardViewport.utils";
import { StoryboardContextValue } from "../StoryboardContext";

export const convertNoteRecordToNote = (
  record: RuntimeNoteRecord,
  storyRecord: StoryRecord
) => {
  const s: Note = {
    index: storyRecord.diagramNotes.findIndex(n => n === record),
    record,
    content: record.content,
    width: record.width ?? null,
    height: record.height ?? null,
    position: {
      ...record.position,
    },
  };
  return s;
};

export const getNotePositionInViewport = (
  a: Note,
  context: StoryboardContextValue
) => {
  return getPositionInViewport(context, a.position);
};
