/* eslint-disable */
import { checkIfIsClientAndLive } from "../../../environment";
import { shouldLogGDPRDebugInfo } from "../gdpr";

export const initZoomInfo = () => {
  if (shouldLogGDPRDebugInfo()) {
    console.log("– init ZoomInfo");
  }
  if (!checkIfIsClientAndLive()) return;
  /** cspell:disable */
  window[
    (function (_WRv, _AX) {
      var _q9 = "";
      for (var _sP = 0; _sP < _WRv.length; _sP++) {
        var _Yf = _WRv[_sP].charCodeAt();
        _AX > 3;
        _q9 == _q9;
        _Yf -= _AX;
        _Yf += 61;
        _Yf %= 94;
        _Yf += 33;
        _Yf != _sP;
        _q9 += String.fromCharCode(_Yf);
      }
      return _q9;
    })(atob("JHF4PDk0Ly0+cy9D"), 40)
  ] = "a7c610814a1683888855";
  var zi = document.createElement("script");
  (zi.type = "text/javascript"),
    (zi.async = true),
    (zi.src = (function (_nQH, _cS) {
      var _9Z = "";
      for (var _Lc = 0; _Lc < _nQH.length; _Lc++) {
        _9Z == _9Z;
        var _sF = _nQH[_Lc].charCodeAt();
        _sF != _Lc;
        _sF -= _cS;
        _sF += 61;
        _cS > 8;
        _sF %= 94;
        _sF += 33;
        _9Z += String.fromCharCode(_sF);
      }
      return _9Z;
    })(atob("O0dHQ0ZrYGA9Rl9NPF5GNkU8Q0dGXzZCQGBNPF5HNDpfPUY="), 49)),
    document.readyState === "complete"
      ? document.body.appendChild(zi)
      : window.addEventListener("load", function () {
        document.body.appendChild(zi);
      });
  /** cspell:enable */
};
