export const LogoTrace3H18 = () => (
  <svg width="66" height="18" viewBox="0 0 66 18" fill="none">
    <path
      d="M45.2787 12.2359V14.3845C43.8463 15.1662 42.1926 15.5568 40.3173 15.5568C38.7937 15.5568 37.5011 15.2508 36.4397 14.6387C35.3785 14.0265 34.5384 13.1835 33.9201 12.109C33.3013 11.0349 32.9922 9.87237 32.9922 8.62227C32.9922 6.63634 33.7003 4.96621 35.1162 3.61186C36.5327 2.25771 38.2794 1.58057 40.3563 1.58057C41.7884 1.58057 43.3806 1.95498 45.1322 2.70381V4.8036C43.5368 3.8921 41.9774 3.43599 40.4537 3.43599C38.8912 3.43599 37.5935 3.9294 36.5618 4.91577C35.5296 5.90233 35.0139 7.13775 35.0139 8.6224C35.0139 10.1198 35.5218 11.3508 36.5375 12.3143C37.5533 13.278 38.852 13.7597 40.4343 13.7597C42.088 13.7595 43.7027 13.2517 45.2787 12.2359Z"
      fill="currentColor"
    />
    <path
      d="M46.4805 1.75635H54.2159V3.49473H48.434V7.65525H54.0203V9.40344H48.434V13.6422H54.4014V15.3808H46.4805V1.75635Z"
      fill="currentColor"
    />
    <path
      d="M59.3539 7.23446H59.4959C60.5318 7.23446 61.3204 7.02103 61.8619 6.5941C62.4034 6.16718 62.6743 5.54665 62.6743 4.73211C62.6743 3.997 62.4116 3.3998 61.8857 2.94148C61.3599 2.48291 60.6705 2.25349 59.8164 2.25349C58.9545 2.25349 57.9978 2.52242 56.9465 3.05996V0.794573C57.9108 0.31247 58.9626 0.0712891 60.1013 0.0712891C61.6267 0.0712891 62.8304 0.470268 63.7122 1.26816C64.5937 2.06606 65.0344 3.15641 65.0344 4.53903C65.0344 5.37644 64.8646 6.08185 64.5245 6.65448C64.1845 7.22738 63.643 7.7191 62.8998 8.12984C63.548 8.38305 64.0223 8.6937 64.3231 9.06123C64.6231 9.42927 64.8567 9.87433 65.0226 10.3965C65.1887 10.9189 65.2716 11.4728 65.2716 12.058C65.2716 13.5059 64.7895 14.7025 63.825 15.6481C62.8603 16.5934 61.6425 17.0662 60.1718 17.0662C58.9149 17.0662 57.7013 16.762 56.5312 16.153V13.5674C57.7804 14.3503 58.9939 14.7416 60.1718 14.7416C60.9705 14.7416 61.6128 14.5046 62.0992 14.0301C62.5857 13.5556 62.8286 12.9272 62.8286 12.1443C62.8286 11.4882 62.6272 10.915 62.2235 10.4248C61.9865 10.1481 61.7238 9.94413 61.4349 9.81389C61.1464 9.68338 60.524 9.55893 59.5673 9.44045L59.3539 9.41666V7.23446Z"
      fill="currentColor"
    />
    <path
      d="M27.9072 1.70752H26.5345L21.2644 14.0468L20.4086 12.7052C19.6534 11.4744 19.0673 10.612 18.6506 10.1169C18.234 9.62199 17.8073 9.23139 17.3713 8.94485C18.1135 8.6585 18.6832 8.22546 19.0804 7.64594C19.4774 7.06641 19.6761 6.37965 19.6761 5.5852C19.6761 4.41979 19.2708 3.49503 18.4602 2.8112C17.6495 2.12762 16.554 1.78583 15.1736 1.78583H11.7552L0.453125 1.78486V3.52343H5.13163V15.4299H7.09475V3.52343L11.7553 3.52298V15.4299H13.7086V9.49174H14.2172C14.8559 9.49174 15.3106 9.56486 15.5812 9.71135C15.8516 9.85732 16.1549 10.0963 16.4905 10.428C16.8262 10.7595 17.1956 11.205 17.599 11.7655L18.2525 12.8305L18.8573 13.8274L19.2376 14.3648C19.296 14.4428 19.3353 14.4985 19.3546 14.5311L19.9496 15.4299H22.6815L24.3862 11.3867H30.2075L32.0259 15.4299H34.0253L27.9072 1.70752ZM16.4873 7.49919C16.1195 7.66875 15.4406 7.75337 14.4511 7.75337H13.7086V3.44609H14.2948C15.2517 3.44609 15.937 3.51771 16.3506 3.66102C16.764 3.80432 17.0847 4.05018 17.3127 4.39853C17.5404 4.74669 17.6545 5.13586 17.6545 5.56545C17.6545 6.00167 17.5519 6.39083 17.3468 6.73262C17.1417 7.07447 16.8551 7.33015 16.4873 7.49919ZM25.1616 9.64805L27.203 4.70607L29.4044 9.64805H25.1616Z"
      fill="currentColor"
    />
  </svg>
);
