export const AIPortalIcon = () => (
  <svg
    width="60"
    height="84"
    viewBox="0 0 60 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2157 83C19.3494 83 14.5939 82.3121 14.5939 81.4635C14.5939 80.6149 19.3494 79.927 25.2157 79.927C31.0819 79.927 35.8374 80.6149 35.8374 81.4635C35.8374 82.3121 31.0819 83 25.2157 83Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.352 21.659C36.3518 22.7447 37.2321 23.6255 38.3183 23.6253L49.0535 23.6691C50.1397 23.6695 51.0202 22.7888 51.02 21.7028C51.02 20.6168 50.1395 19.7363 49.0537 19.7363L38.3185 19.6924C37.2321 19.6924 36.3518 20.573 36.352 21.659Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.1852 28.4578C27.1852 29.5436 28.0655 30.4244 29.1517 30.4244L43.576 30.4682C44.6622 30.4686 45.5427 29.5879 45.5425 28.5017C45.5425 27.4157 44.662 26.5354 43.576 26.5352L29.1517 26.4913C28.0655 26.4913 27.1852 27.3718 27.1852 28.4578Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.0705 37.8968C28.0705 40.1256 29.8773 41.9327 32.1066 41.9327H43.489C45.7178 41.9327 47.5245 40.1256 47.5249 37.897C47.5245 35.6681 45.7178 33.8612 43.489 33.8614H32.1066C29.8773 33.8612 28.0705 35.6681 28.0705 37.8968Z"
      fill="#5EBC89"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.1994 30.6897C49.1994 32.044 50.2975 33.1421 51.6521 33.1421H56.5898C57.9442 33.1421 59.0425 32.0442 59.0425 30.6897C59.0425 29.3351 57.9444 28.2368 56.5896 28.2368H51.6521C50.2975 28.2368 49.1994 29.3349 49.1994 30.6897Z"
      fill="#FA975F"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.7444 49.2251C36.7444 50.4561 37.7425 51.454 38.9738 51.454H46.7985C48.0297 51.454 49.0278 50.4563 49.0278 49.2251C49.0278 47.9934 48.0297 46.9957 46.7985 46.9955H38.9738C37.7425 46.9955 36.7444 47.9934 36.7444 49.2251Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.493 13.7427C31.493 15.5772 32.9799 17.0642 34.8145 17.0642H39.171C41.0058 17.0642 42.4929 15.5772 42.4927 13.7427C42.4929 11.9077 41.0053 10.4208 39.1708 10.4208H34.8149C32.9801 10.4206 31.493 11.9077 31.493 13.7427Z"
      fill="#FBBD74"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.525 56.5499C28.525 58.3845 30.0121 59.8714 31.8467 59.8714H36.2032C38.0378 59.8714 39.5251 58.3845 39.5249 56.5499C39.5251 54.7149 38.0376 53.228 36.203 53.228H31.8469C30.0121 53.2278 28.525 54.7149 28.525 56.5499Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.509 1C22.8068 1 24.0544 1.64328 25.2167 2.82454C26.3787 1.64287 27.6259 1.00021 28.9241 1.00021C35.2133 1 40.311 16.0576 40.311 34.6324C40.311 53.2068 35.2133 68.2646 28.9241 68.2646C27.6259 68.2646 26.3787 67.6214 25.2167 66.4401C24.0542 67.6216 22.807 68.2644 21.509 68.2646C15.22 68.2646 10.1217 53.2068 10.1219 34.6322C10.1217 16.0579 15.2198 1 21.509 1Z"
      fill="#FBBD74"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.2969 61.7671C17.3784 61.7671 14.2018 49.6185 14.2018 34.6324C14.2018 19.6463 17.3784 7.49768 21.2969 7.49768C25.2155 7.49768 28.3921 19.6463 28.3921 34.6324C28.3921 49.6185 25.2155 61.7671 21.2969 61.7671Z"
      fill="#E49307"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.3921 34.6324C28.3921 45.3595 26.7644 54.6322 24.4021 59.0364C22.0398 54.632 20.4117 45.3593 20.4119 34.6326C20.4117 23.9056 22.0396 14.6329 24.4021 10.2283C26.7644 14.6329 28.3921 23.9054 28.3921 34.6324Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M16.3051 27.6146C13.1543 27.6146 10.6 25.0604 10.6 21.9095C10.6 18.7587 13.1543 16.2044 16.3051 16.2044C19.456 16.2044 22.0103 18.7587 22.0103 21.9095C22.0103 25.0604 19.456 27.6146 16.3051 27.6146Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.8067 37.1529C6.90044 37.1529 5.35511 35.6075 5.35511 33.7013C5.35511 31.795 6.90044 30.2497 8.8067 30.2497C10.713 30.2497 12.2583 31.795 12.2583 33.7013C12.2583 35.6075 10.713 37.1529 8.8067 37.1529Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M26.9375 32.1025C27.4317 32.1023 27.9102 32.1683 28.3655 32.2906C28.3829 33.0623 28.3921 33.8433 28.3921 34.6324C28.3921 37.5497 28.2712 40.359 28.0481 42.9928C27.69 43.0663 27.318 43.1053 26.9375 43.1055C23.8994 43.1053 21.436 40.6422 21.436 37.604C21.4362 34.5652 23.8994 32.1023 26.9375 32.1025Z"
      fill="#5EBC89"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.6277 12.7375C17.6128 12.7852 17.6 12.827 17.5895 12.8621H23.5108L24.2923 10.2504L22.1164 7.51599C21.4037 7.41937 20.7674 7.68942 20.2065 8.16565C19.6339 8.65183 19.1471 9.34746 18.7518 10.0649C18.3571 10.7813 18.0568 11.5133 17.8552 12.0669C17.7544 12.3436 17.6785 12.5752 17.6277 12.7375Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.204866"
    />
    <path
      d="M20.9802 50.7385C19.7922 50.7385 18.8291 49.7754 18.8291 48.5874C18.8291 47.3994 19.7922 46.4363 20.9802 46.4363C22.1683 46.4363 23.1313 47.3994 23.1313 48.5874C23.1313 49.7754 22.1683 50.7385 20.9802 50.7385Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10.7797 54.7454C9.50684 54.7454 8.47497 53.7135 8.47497 52.4407C8.47497 51.1678 9.50684 50.1359 10.7797 50.1359C12.0526 50.1359 13.0845 51.1678 13.0845 52.4407C13.0845 53.7135 12.0526 54.7454 10.7797 54.7454Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.6319 27.5723L14.4599 30.6692C15.7852 30.5874 16.6923 29.8272 17.2755 29.0679C17.5751 28.6778 17.7873 28.2901 17.9247 27.9997C17.985 27.8724 18.0307 27.764 18.0629 27.6827C17.8546 27.7088 17.5862 27.7186 17.2947 27.7186C16.9077 27.7186 16.4709 27.7012 16.0612 27.6781C15.6513 27.655 15.2675 27.6261 14.9862 27.603C14.8456 27.5914 14.7305 27.5813 14.6506 27.5741L14.6319 27.5723Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.204866"
    />
    <path
      d="M19.9385 50.6183C19.95 50.6457 19.9634 50.676 19.979 50.7089C20.0508 50.861 20.1673 51.0662 20.3447 51.2769C20.6837 51.6794 21.2476 52.1054 22.1602 52.2185L22.0809 50.948L19.9385 50.6183Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.204866"
    />
    <path
      d="M13.5672 41.3248C13.3791 41.8017 12.8348 42.3458 12.3581 42.5335L10.3791 43.3142C9.90173 43.5027 9.90173 43.8108 10.3791 43.9985L12.3581 44.7794C12.8348 44.9671 13.3791 45.5112 13.5672 45.989L14.348 47.9676C14.536 48.4441 14.8439 48.4441 15.032 47.9676L15.8125 45.989C16.0008 45.5112 16.5443 44.9671 17.0213 44.7794L19.0009 43.9985C19.4782 43.8108 19.4782 43.5027 19.0009 43.3142L17.0213 42.5335C16.5443 42.3458 16.0008 41.8017 15.8125 41.3248L15.032 39.3454C14.8439 38.8688 14.536 38.8688 14.348 39.3454L13.5672 41.3248Z"
      fill="#FFD29F"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.52145 22.5689C3.38296 22.9206 2.98183 23.3216 2.63049 23.4596L1.17225 24.0351C0.820697 24.1736 0.820697 24.4008 1.17225 24.5393L2.63049 25.1143C2.98183 25.2528 3.38296 25.6542 3.52145 26.0059L4.09673 27.4635C4.23522 27.8149 4.462 27.8149 4.60069 27.4635L5.17575 26.0059C5.31445 25.6542 5.71496 25.2528 6.06631 25.1143L7.52476 24.5393C7.87651 24.4008 7.87651 24.1736 7.52476 24.0351L6.06631 23.4596C5.71496 23.3216 5.31445 22.9206 5.17575 22.5689L4.60069 21.1109C4.462 20.7595 4.23522 20.7595 4.09673 21.1109L3.52145 22.5689Z"
      fill="#FBBD74"
      stroke="#32274B"
      strokeWidth="1.02433"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
