import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import WorkbenchArrowIcon from "./illustrations/WorkbenchArrowIcon.svg";
import WorkbenchCelloIcon from "./illustrations/WorkbenchCelloIcon.svg";
import WorkbenchPortalStackIcon from "./illustrations/WorkbenchPortalStackIcon.svg";
import WorkbenchFlashlightIcon from "./illustrations/WorkbenchFlashlightIcon.svg";
import WorkbenchComplianceIcon from "./illustrations/WorkbenchComplianceIcon.svg";
import { colors } from "../../styles/colors.styles";
import {
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../styles/fonts.styles";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { NoBreak } from "../typography/Nowrap";

const Header = styled.header`
  position: relative;
  ${uptoTabletMd} {
    text-align: center;
    + * {
      margin-top: 2em;
    }
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const ImpactCard = styled.div`
  display: grid;
  border-radius: ${rSize("radius")};
  padding: ${rSize("gap")};
  grid-gap: ${rSize("gap")};
  text-align: left;
  ${uptoTabletMd} {
    grid-template-columns: 7.5em minmax(0, 1fr);
    grid-template-areas: "icon text";
    align-items: center;
  }
  ${fromTabletMd} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: "text" "icon";
  }
  &.purple {
    background-color: ${colors.purple50};
    color: ${colors.purple800};
    border: 1px solid ${colors.purple200};
    ${uptoTabletMd} {
      figure {
        background-color: ${colors.purple100};
        border: 1px solid ${colors.purple200};
      }
    }
  }
  &.pink {
    background-color: ${colors.pink50};
    color: ${colors.pink800};
    border: 1px solid ${colors.pink200};
    ${uptoTabletMd} {
      figure {
        background-color: ${colors.pink100};
        border: 1px solid ${colors.pink200};
      }
    }
  }
  &.green {
    background-color: ${colors.green50};
    color: ${colors.green800};
    border: 1px solid ${colors.green200};
    ${uptoTabletMd} {
      figure {
        background-color: ${colors.green100};
        border: 1px solid ${colors.green200};
      }
    }
  }
  &.orange {
    background-color: ${colors.orange50};
    color: ${colors.orange800};
    border: 1px solid ${colors.orange200};
    ${uptoTabletMd} {
      figure {
        background-color: ${colors.orange100};
        border: 1px solid ${colors.orange200};
      }
    }
  }
  &.blue {
    background-color: ${colors.blue50};
    color: ${colors.blue800};
    border: 1px solid ${colors.blue200};
    ${uptoTabletMd} {
      figure {
        background-color: ${colors.blue100};
        border: 1px solid ${colors.blue200};
      }
    }
  }
  > div {
    grid-area: text;
    > * + * {
      margin-top: 0.5em;
    }
  }
  figure {
    grid-area: icon;
    margin: 0;
    display: flex;
    ${uptoTabletMd} {
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 1em;
      aspect-ratio: 1;
    }
    ${fromTabletMd} {
      align-items: flex-end;
      justify-content: flex-end;
    }
    img {
      display: block;
      ${uptoTabletMd} {
        height: auto;
        max-width: 6em;
      }
      ${fromTabletMd} {
        margin-bottom: -3%;
      }
    }
  }
  h3 {
    ${uptoTabletMd} {
      font-size: inherit;
    }
    ${fromTabletMd} {
      font-family: ${serif};
      font-weight: 400;
      max-width: 10.5em;
      font-size: 2.4rem;
      letter-spacing: -0.02em;
    }
    span {
      display: block;
    }
  }
  p {
    font-size: 1.4rem;
    line-height: 1.2;
    max-width: 16em;
  }
`;

export const WorkbenchBusinessImpactSection = () => (
  <PageSection>
    <ContentGrid>
      <Header>
        <SectionHeading2 lighter>
          Experience <NoBreak>the impact</NoBreak>
        </SectionHeading2>
      </Header>
      <ImpactCard className="purple">
        <div>
          <h3>
            <span>Accelerate</span> <span>AI adoption</span>
          </h3>
          <p>Secure, practical AI for teams in IT, security and beyond.</p>
        </div>
        <figure>
          <img src={WorkbenchArrowIcon} />
        </figure>
      </ImpactCard>
      <ImpactCard className="blue">
        <div>
          <h3>
            <span>Increase</span> <span>operational efficiency</span>
          </h3>
          <p>Switch context less, and upskill your whole team.</p>
        </div>
        <figure>
          <img src={WorkbenchCelloIcon} />
        </figure>
      </ImpactCard>
      <ImpactCard className="orange">
        <div>
          <h3>
            <span>Improve interoperability</span>{" "}
            <span>of your tech stack</span>
          </h3>
          <p>Bring your data and tools together in a unified view.</p>
        </div>
        <figure>
          <img src={WorkbenchPortalStackIcon} />
        </figure>
      </ImpactCard>
      <ImpactCard className="pink">
        <div>
          <h3>
            <span>Mitigate risk</span> <span>faster</span>
          </h3>
          <p>Respond to incidents faster and from a single interface.</p>
        </div>
        <figure>
          <img src={WorkbenchFlashlightIcon} />
        </figure>
      </ImpactCard>
      <ImpactCard className="green">
        <div>
          <h3>
            <span>Maintain</span> <span>compliance better</span>
          </h3>
          <p>Track compliance (and compliance requests) across your systems.</p>
        </div>
        <figure>
          <img src={WorkbenchComplianceIcon} />
        </figure>
      </ImpactCard>
    </ContentGrid>
  </PageSection>
);
