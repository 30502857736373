import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import PageSection from "../reusableSections/PageSection";
import { DatoCmsCaseStudy } from "../../../graphql-types";
import CaseStudyBookCardLink from "../general/CaseStudyBookCardLink";
import Button from "../forms/Button";
import Spacing from "../layout/Spacing";
import { Paths } from "../../utils/pathBuilders.utils";
import {
  fromDesktop,
  fromPhoneLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromTabletMd} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${fromDesktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Footer = styled.footer`
  text-align: center;
`;

const CustomersPageCaseStudyGridSection = (props: {
  caseStudies: DatoCmsCaseStudy[];
}) => {
  return (
    <PageSection id="case-studies">
      <Grid>
        {props.caseStudies.slice(0, 9).map(c => (
          <CaseStudyBookCardLink caseStudy={c} key={c.slug} />
        ))}
      </Grid>
      <Spacing size="xl" />
      <Footer>
        <Button to={Paths.caseStudiesAll()} appearance="outlined">
          See all case studies
        </Button>
      </Footer>
    </PageSection>
  );
};

export default CustomersPageCaseStudyGridSection;
