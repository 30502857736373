import { styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { renderInPortal } from "../../utils/portals.utils";
import { runAfter } from "../../utils/promises.utils";
import DisablePageScroll from "../utilities/DisablePageScroll";

type Props = {
  onShouldClose?: () => void;
};

const Lightbox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(0);
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @supports not (backdrop-filter: blur(2em)) {
    background-color: ${withOpacity(colors.dark900, 0.75)};
  }
  @supports (backdrop-filter: blur(2em)) {
    background-color: ${withOpacity(colors.dark900, 0.5)};
    backdrop-filter: blur(2em) brightness(0.6);
  }
  @keyframes BackdropEnter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes BackdropExit {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  animation: BackdropEnter 1s;
  .willClose & {
    animation: BackdropExit 0.3s;
  }
`;
const Frame = styled.div`
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  border-radius: ${rSize("sm")};
  box-shadow: 0 1em 3em rgba(0, 0, 0, 0.2);
  background-color: ${colors.dark900};
  @keyframes FrameEnter {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes FrameExit {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.98);
    }
  }
  animation: FrameEnter 0.3s;
  width: 1280px;
  height: auto;
  aspect-ratio: 1280 / 720;
  max-width: calc(100% - 2em);
  max-height: calc(100% - 2em);
  .willClose & {
    animation: FrameExit 0.3s;
  }
  iframe {
    display: block;
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

const FullscreenVideoEmbedLightbox = (props: PropsWithChildren<Props>) => {
  const [willClose, setWillClose] = useState(false);
  const close = () => {
    if (willClose) return;
    setWillClose(true);
    runAfter(() => {
      props.onShouldClose?.();
    }, 300);
  };
  useHotkeys("escape", close);
  return renderInPortal(
    <Lightbox className={cx(willClose && "willClose")}>
      <Backdrop onClick={close} />
      <Frame>{props.children}</Frame>
      <DisablePageScroll />
    </Lightbox>
  );
};

export default FullscreenVideoEmbedLightbox;
