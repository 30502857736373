export const AboutPageIllustration = () => (
  <svg
    width="976"
    height="555"
    viewBox="0 0 976 555"
    fill="none"
    strokeLinejoin="round"
    strokeLinecap="round"
    strokeMiterlimit="10"
  >
    <path
      d="M294.96 171.469C326.577 150.703 347.597 126.84 341.91 118.17C336.222 109.499 305.982 119.305 274.365 140.071C242.748 160.838 221.728 184.701 227.415 193.371C233.102 202.041 263.343 192.235 294.96 171.469Z"
      fill="#EADFF8"
    />
    <path
      d="M294.96 171.469C326.577 150.703 347.597 126.84 341.91 118.17C336.222 109.499 305.982 119.305 274.365 140.071C242.748 160.838 221.728 184.701 227.415 193.371C233.102 202.041 263.343 192.235 294.96 171.469Z"
      fill="#FFC8A3"
    />
    <path
      d="M499.651 526.518C531.034 484.754 482.883 395.526 392.102 327.222C301.321 258.918 202.288 237.403 170.905 279.168C139.523 320.932 187.674 410.16 278.455 478.464C369.236 546.768 468.269 568.283 499.651 526.518Z"
      fill="#C1AAF7"
    />
    <path
      d="M168.359 283.062C201.851 244.729 298.617 266.444 387.522 333.36C478.309 401.661 525.862 490.569 494.474 532.337"
      stroke="#26242C"
    />
    <path
      d="M466.52 233.372L445.096 244.396C435.353 233.705 434.135 210.273 434.135 210.273C430.315 206.174 415.369 193.488 420.572 187.284C428.821 177.424 438.951 188.337 443.158 193.378C447.366 198.474 456.61 206.561 458.216 212.101C459.821 217.64 466.52 233.428 466.52 233.428V233.372Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <path
      d="M451.958 250.322C451.958 250.322 485.782 221.905 484.952 216.2C484.121 210.494 476.537 187.727 472.441 181.744C468.344 175.762 455.889 159.476 452.18 157.149C448.471 154.823 441.496 154.878 439.835 165.957C438.174 177.036 453.232 197.532 453.232 197.532C452.678 204.068 459.432 221.185 459.432 221.185C455.224 228.054 451.958 250.267 451.958 250.267V250.322Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <path
      d="M503.887 240.741C503.887 240.741 512.744 226.339 524.092 213.432C535.441 200.58 536.825 177.259 537.157 169.67C537.489 162.081 534.887 148.62 534.887 148.62C525.31 145.297 522.21 151.833 518.224 159.755C514.239 167.676 513.796 183.796 513.796 183.796C506.156 192.05 497.853 211.161 497.853 211.161C484.677 218.583 472.941 232.1 472.941 232.1L503.887 240.741Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <path
      d="M294.242 459.269C294.242 459.269 317.824 488.961 325.796 473.118C333.768 457.33 399.976 349.533 415.974 329.148C431.973 308.763 483.179 279.072 498.292 270.984C520.103 259.351 529.015 251.873 533.499 246.001C539.312 238.357 555.532 212.931 560.957 199.858C566.382 186.785 571.973 171.053 571.973 171.053C571.973 171.053 560.348 160.085 549.996 174.099C543.353 183.184 533.555 203.735 533.555 203.735C520.158 205.896 497.185 230.602 497.185 230.602C461.977 221.905 432.471 230.602 432.471 230.602C432.471 230.602 420.569 223.29 411.324 215.867C402.079 208.444 411.767 179.196 414.701 170.388C417.635 161.525 420.957 137.428 416.362 127.568C411.767 117.708 397.097 121.419 392.392 128.953C387.686 136.431 380.933 147.953 377.168 165.292C373.349 182.686 367.315 201.797 346.832 232.374C326.35 262.952 334.819 278.462 330.612 292.311C326.405 306.159 286.879 366.484 258.702 387.09C230.47 407.753 260.474 436.668 260.474 436.668L278.188 448.689L294.187 459.325L294.242 459.269Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <path
      d="M389.181 146.182C389.071 134.605 394.717 128.29 394.717 128.29C403.408 126.517 411.546 128.456 412.21 133.109C412.875 137.818 408.944 150.392 408.944 150.392C403.574 153.273 389.181 146.182 389.181 146.182Z"
      fill="#D7C4FA"
      stroke="#26242C"
    />
    <path
      d="M406.844 206.008C407.674 214.705 415.701 232.597 415.701 232.597"
      stroke="#26242C"
    />
    <path
      d="M432.641 182.024L438.066 189.115L442.882 193.602L453.787 199.972L456.943 201.745L458.161 206.73C458.161 206.73 447.089 207.118 438.066 199.141C429.042 191.164 426.828 183.575 426.828 183.575C426.828 183.575 429.098 180.861 432.585 181.969L432.641 182.024Z"
      fill="#26242C"
    />
    <mask
      id="mask0_1431_54575"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="161"
      y="257"
      width="349"
      height="291"
    >
      <path
        d="M499.655 526.518C531.038 484.754 482.886 395.526 392.106 327.222C301.325 258.918 202.292 237.403 170.909 279.168C139.527 320.932 187.678 410.16 278.459 478.464C369.24 546.768 468.273 568.283 499.655 526.518Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1431_54575)">
      <path
        d="M214.42 442.263C235.567 414.622 282.621 328.484 282.621 328.484C310.355 326.268 379.055 367.924 388.798 381.44C398.541 394.957 251.288 642.957 251.288 642.957L125.57 577.259C125.57 577.259 171.628 498.267 214.42 442.263Z"
        fill="#8D75E6"
        stroke="#26242C"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M531.012 137.443C544.329 92.8594 531.021 49.5086 501.288 40.6165C471.556 31.7244 436.658 60.6584 423.342 105.242C410.025 149.826 423.333 193.177 453.066 202.069C482.798 210.961 517.696 182.027 531.012 137.443Z"
      fill="#FA9589"
    />
    <path
      d="M568.432 251.873C578.338 251.873 586.368 230.668 586.368 204.511C586.368 178.353 578.338 157.148 568.432 157.148C558.526 157.148 550.496 178.353 550.496 204.511C550.496 230.668 558.526 251.873 568.432 251.873Z"
      fill="#FEAF7F"
    />
    <mask
      id="mask1_1431_54575"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="418"
      y="38"
      width="114"
      height="166"
    >
      <path
        d="M450.631 201.356C480.303 211.66 513.075 180.971 526.139 136.047C539.093 91.3988 527.247 48.4682 498.848 39.9929C470.449 31.5175 436.681 60.7657 423.34 105.303C409.998 149.84 422.675 191.607 450.631 201.356Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1431_54575)">
      <path
        d="M460.763 64.3125C460.763 64.3125 462.701 71.6799 463.254 77.4409C463.808 83.2573 465.192 90.0155 466.521 92.2866C467.849 94.6132 470.008 97.3275 465.192 102.701C460.376 108.129 461.538 113.115 460.929 117.38C460.376 121.646 460.819 126.077 451.076 129.512C441.499 132.891 428.379 125.689 428.379 125.689V73.5633L460.763 64.3125Z"
        fill="#EADFF8"
        stroke="#26242C"
        strokeLinejoin="round"
      />
      <path
        d="M461.097 87.2981C461.097 88.7384 459.934 89.8463 458.55 89.8463C457.166 89.8463 456.004 88.683 456.004 87.2981C456.004 85.9133 457.166 84.75 458.55 84.75C459.934 84.75 461.097 85.9133 461.097 87.2981Z"
        fill="#8D75E6"
      />
      <path
        d="M483.129 225.508L463.09 194.875L483.129 225.508Z"
        fill="#EADFF8"
      />
      <path
        d="M483.129 225.508L463.09 194.875"
        stroke="#26242C"
        strokeLinejoin="round"
      />
      <path
        d="M467.131 51.2344L425.004 52.8408L413.489 66.5786C410.943 65.5261 408.175 77.7128 405.241 77.7128C393.505 77.7128 396.992 90.8413 396.992 102.64C396.992 114.439 406.514 123.967 418.305 123.967C428.768 123.967 437.404 116.433 439.231 106.518C439.895 103.582 439.673 100.923 439.231 98.8735C438.898 96.9901 438.29 95.2174 437.459 93.5002C436.186 89.5118 435.134 82.9199 441.224 81.8674C448.586 80.5934 450.358 88.5147 450.358 88.5147C450.8 83.7508 451.797 71.7857 459.27 67.1879C468.349 61.5931 467.186 51.3452 467.186 51.3452L467.131 51.2344Z"
        fill="#97436E"
        stroke="#26242C"
        strokeLinejoin="round"
      />
      <path
        d="M572.753 191.494L510.199 196.757C510.199 196.757 493.038 141.529 464.916 132.721C446.315 126.849 426.608 123.969 426.608 123.969L415.813 125.021C403.524 126.24 393.725 127.237 383.595 134.327L373.963 143.135L363.832 207.614L370.309 238.469L419.245 263.23L449.305 250.323L484.014 226.116C484.014 226.116 488.886 234.093 504.275 234.093C511.693 234.093 577.127 219.025 577.127 219.025C577.127 219.025 585.264 203.903 572.809 191.494H572.753Z"
        fill="#8D75E6"
        stroke="#26242C"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M572.749 191.49L510.194 196.752C510.194 196.752 500.396 152.603 473.105 141.358C455.999 134.267 442.547 163.294 442.547 163.294L483.899 226.166C483.899 226.166 488.771 234.143 504.16 234.143C511.578 234.143 577.012 219.076 577.012 219.076C577.012 219.076 585.149 203.953 572.694 191.545L572.749 191.49Z"
      fill="#8D75E6"
    />
    <path
      d="M461.093 87.2981C461.093 88.7384 459.93 89.8463 458.546 89.8463C457.163 89.8463 456 88.683 456 87.2981C456 85.9133 457.163 84.75 458.546 84.75C459.93 84.75 461.093 85.9133 461.093 87.2981Z"
      fill="#26242C"
    />
    <mask
      id="mask2_1431_54575"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="542"
      y="157"
      width="45"
      height="95"
    >
      <path
        d="M564.341 251.873C576.509 251.873 586.374 230.668 586.374 204.511C586.374 178.353 576.509 157.148 564.341 157.148C552.173 157.148 542.309 178.353 542.309 204.511C542.309 230.668 552.173 251.873 564.341 251.873Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_1431_54575)">
      <path
        d="M617.041 188.835C617.041 188.835 619.919 190.552 620.085 193.433C620.251 196.313 616.93 198.418 616.93 198.418C616.93 198.418 619.698 199.471 620.085 201.797C620.528 204.622 618.092 206.506 618.092 206.506C618.092 206.506 618.868 206.727 618.978 209.663C619.089 212.599 614.992 214.427 612.944 214.981C610.896 215.535 607.685 216.366 606.191 216.477C604.696 216.587 600.876 216.754 597.831 216.809C594.012 216.809 590.469 216.033 587.203 215.424C583.937 214.87 566.167 217.141 566.167 217.141L564.34 195.814C564.34 195.814 575.522 193.433 583.494 190.94C586.704 189.943 592.739 184.625 595.285 183.295C597.776 181.966 602.205 180.913 602.205 180.913L612.058 180.027C613.83 179.75 617.705 179.584 620.03 179.252C622.632 178.919 627.337 178.199 632.375 177.534C635.641 177.091 637.579 178.531 637.8 180.138C638.187 183.406 634.866 184.126 631.766 184.846C629.053 185.511 616.985 188.835 616.985 188.835H617.041Z"
        fill="#EADFF8"
        stroke="#26242C"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M468.509 203.458C463.305 204.012 458.102 203.624 453.064 202.073C423.337 193.155 409.996 149.836 423.337 105.244C436.678 60.6514 471.554 31.7356 501.281 40.6541C531.008 49.5726 544.35 92.8909 531.008 137.483C526.026 154.212 517.999 168.726 508.312 179.86L506.097 182.74"
      stroke="#26242C"
    />
    <path
      d="M550.496 193.322C552.268 172.106 559.464 157.094 568.155 157.094C578.175 157.094 586.368 178.31 586.368 204.456C586.368 230.602 578.23 251.818 568.155 251.818C561.125 251.818 554.814 240.351 551.769 225.229"
      stroke="#26242C"
    />
    <path
      d="M570.48 251.263C562.287 247.663 555.977 228.109 555.977 204.511C555.977 180.913 562.232 161.358 570.48 157.758"
      stroke="#26242C"
    />
    <path
      d="M408.281 339.952C488.495 406.259 529.128 487.301 499.677 526.52C468.289 568.288 369.254 546.795 278.467 478.494C187.68 410.137 139.518 320.897 170.906 279.13C195.264 246.668 260.475 252.429 330.946 288.602"
      stroke="#26242C"
    />
    <path
      d="M414.762 170.335C417.696 161.472 421.017 137.376 416.422 127.516L414.762 170.335Z"
      fill="#EADFF8"
    />
    <path
      d="M414.762 170.335C417.696 161.472 421.017 137.376 416.422 127.516"
      stroke="#26242C"
    />
    <path
      d="M453.785 203.125L455.003 208.11C455.003 208.11 465.466 207.501 470.282 205.618L468.51 203.457L453.785 203.125Z"
      fill="#26242C"
    />
    <path
      d="M481.078 227.277C481.078 227.277 483.071 236.528 499.125 238.189C499.125 238.189 505.159 238.356 513.463 236.251L544.851 227.775L548.061 223.344L481.078 227.332V227.277Z"
      fill="#26242C"
    />
    <path
      d="M528.854 147.844C528.854 147.844 526.64 161.582 514.461 178.089V172.051C514.461 172.051 525.92 154.159 527.415 147.899H528.854V147.844Z"
      fill="#26242C"
    />
    <path
      d="M483.901 226.112C483.901 226.112 488.772 234.089 504.162 234.089C511.58 234.089 577.013 219.022 577.013 219.022C577.013 219.022 585.151 203.899 572.695 191.491L510.14 196.753C510.14 196.753 505.269 170.663 483.07 145.68"
      fill="#8D75E6"
    />
    <path
      d="M483.901 226.112C483.901 226.112 488.772 234.089 504.162 234.089C511.58 234.089 577.013 219.022 577.013 219.022C577.013 219.022 585.151 203.899 572.695 191.491L510.14 196.753C510.14 196.753 505.269 170.663 483.07 145.68"
      stroke="#26242C"
    />
    <path
      d="M502.997 176.207C511.743 165.405 519.05 151.778 523.645 136.212C536.986 91.6196 523.645 48.3013 493.918 39.3828"
      stroke="#26242C"
    />
    <path d="M484.51 226.949L463.031 194.82L484.51 226.949Z" fill="#D7C4FA" />
    <path d="M484.51 226.949L463.031 194.82" stroke="#26242C" />
    <path
      d="M408.279 339.953L420.402 350.367L417.302 357.292L403.961 346.323L408.279 339.953Z"
      fill="#26242C"
    />
    <path
      d="M551.492 224.782L554.039 234.808L559.685 233.423L557.415 223.508L551.492 224.782Z"
      fill="#26242C"
    />
    <path
      d="M342.796 122.529C334.049 117.433 306.592 127.016 277.805 145.795C250.237 163.742 230.53 184.238 229.367 195.151"
      stroke="#26242C"
    />
    <path
      d="M255.551 132C237.781 124.688 235.899 114.883 235.899 114.883C231.47 116.6 227.651 113.72 225.049 109.288C222.447 104.912 227.097 100.702 227.097 100.702C227.097 100.702 225.492 98.2094 225.99 93.2239C226.654 86.5766 232.135 86.5766 232.135 86.5766C232.577 84.9148 232.245 82.2004 236.176 80.7048C240.106 79.2091 244.479 80.2616 244.479 80.2616C244.479 80.2616 228.426 74.2237 226.71 72.8942C221.783 68.9612 225.547 61.483 230.086 62.3139C232.688 62.8124 241.712 65.8591 241.712 65.8591L260.09 71.2324C260.09 71.2324 271.992 75.1654 275.701 76.384C279.41 77.6027 281.625 81.4803 281.791 84.1946C281.957 86.909 284.559 94.3872 284.946 98.5417C285.334 102.696 288.046 113.775 289.043 116.822C292.752 137.096 302.052 153.438 301.72 154.545C300.059 160.583 297.512 164.018 287.548 166.954C277.417 169.89 270.996 169.89 270.996 169.89C269.058 164.461 255.606 132 255.606 132H255.551Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <path d="M246.523 80.9857L242.371 79.6562" stroke="#26242C" />
    <path
      d="M232.414 85.6875C232.414 89.6205 233.521 93.8305 235.403 98.0404C237.895 103.635 241.216 104.688 243.652 103.968C244.371 103.746 244.925 103.358 245.368 102.915"
      stroke="#26242C"
    />
    <path
      d="M226.875 100.312C230.529 106.904 235.788 107.846 238.223 107.126C240.659 106.461 240.936 103.969 240.936 103.969"
      stroke="#26242C"
    />
    <path
      d="M235.453 115.052C239.992 112.061 236.117 107.297 236.117 107.297"
      stroke="#26242C"
    />
    <path
      d="M253.777 131.336C253.777 131.336 254.94 131.724 256.656 132.333C259.092 133.219 259.202 136.488 259.202 136.488"
      stroke="#26242C"
    />
    <path
      d="M245.863 104.242C245.088 102.359 243.15 103.91 241.822 103.965C240.659 104.021 240.493 104.464 240.161 105.129C239.884 105.793 239.275 106.569 237.504 106.957C235.677 107.344 236.286 107.954 237.448 109.283C238.611 110.613 237.172 112.496 236.895 113.382C236.618 114.269 235.788 114.657 236.175 116.208C236.563 117.759 235.898 116.374 235.898 116.374C240.05 115.21 239.275 110.89 239.275 110.89C241.711 110.336 241.656 106.791 241.656 106.791C244.534 106.957 245.697 104.187 245.697 104.187C245.697 104.187 246.527 106.126 245.752 104.187L245.863 104.242Z"
      fill="#26242C"
    />
    <path
      d="M199.805 56.2266L286.606 82.5942L292.031 57.9992L199.805 56.2266Z"
      fill="#5EBC89"
      stroke="#26242C"
    />
    <path
      d="M213.203 59.6016L289.763 68.0769L291.092 62.3713L213.203 59.6016Z"
      fill="#222027"
    />
    <path
      d="M258.761 121.422C240.769 112.835 244.257 93.1151 245.475 88.7389C246.693 84.3074 246.859 78.1032 245.807 71.8437C244.755 65.5287 245.918 58.3274 249.35 56.8872C253.225 55.2808 255.329 56.9426 257.321 60.1001C259.702 63.9777 262.691 72.6192 264.518 76.8845C266.068 80.5959 270.829 92.2287 272.656 95.6078C274.482 98.9315 276.641 105.579 276.641 105.579"
      fill="#EADFF8"
    />
    <path
      d="M258.761 121.422C240.769 112.835 244.257 93.1151 245.475 88.7389C246.693 84.3074 246.859 78.1032 245.807 71.8437C244.755 65.5287 245.918 58.3274 249.35 56.8872C253.225 55.2808 255.329 56.9426 257.321 60.1001C259.702 63.9777 262.691 72.6192 264.518 76.8845C266.068 80.5959 270.829 92.2287 272.656 95.6078C274.482 98.9315 276.641 105.579 276.641 105.579"
      stroke="#26242C"
    />
    <path
      d="M291.81 27.0834L280.683 29.4653L281.126 8.91406L182.699 57.7718L299.505 63.0343L336.318 37.4421L291.755 27.1388L291.81 27.0834Z"
      fill="#5EBC89"
      stroke="#26242C"
    />
    <path d="M182.699 57.7752L280.683 29.4688" stroke="#26242C" />
    <path
      d="M264.906 76.0469C264.906 76.0469 265.571 78.0965 265.903 78.4842C266.179 78.872 281.957 84.5776 281.957 84.5776L281.182 81.1985C281.514 81.2539 264.906 76.0469 264.906 76.0469Z"
      fill="#26242C"
    />
    <path
      d="M225.712 64.0866L224.66 65.0837L245.807 74.1684L245.032 69.903L225.712 64.0312V64.0866Z"
      fill="#26242C"
    />
    <mask
      id="mask3_1431_54575"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="226"
      y="115"
      width="117"
      height="82"
    >
      <path
        d="M294.96 171.485C326.577 150.719 347.597 126.856 341.91 118.185C336.222 109.515 305.982 119.321 274.365 140.087C242.748 160.853 221.728 184.716 227.415 193.386C233.102 202.057 263.343 192.251 294.96 171.485Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_1431_54575)">
      <path
        d="M273.096 189.067C271.712 180.038 258.979 160.484 262.744 156.385C269.055 149.405 296.069 138.991 301.716 138.603C307.362 138.215 330.336 209.175 330.336 209.175L283.503 229.838C283.503 229.838 275.974 207.458 273.151 189.123L273.096 189.067Z"
        fill="#8D75E6"
        stroke="#26242C"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M293.137 128.898C317.55 115.88 337.645 111.171 342.018 118.373C347.388 127.291 326.02 151.277 294.355 171.939C262.69 192.601 232.686 202.129 227.317 193.21C222.777 185.621 238.167 166.732 262.137 148.673"
      stroke="#26242C"
    />
    <path
      d="M295.625 127.625L298.171 133.885L294.241 135.99L292.082 129.453L295.625 127.625Z"
      fill="#26242C"
    />
    <path
      d="M821.681 330.376C890.416 293.902 932.8 239.181 916.348 208.153C899.896 177.125 830.839 181.54 762.104 218.013C693.369 254.487 650.985 309.208 667.437 340.236C683.889 371.264 752.946 366.849 821.681 330.376Z"
      fill="#FAA3CA"
    />
    <path
      d="M671.126 345.36C655.251 312.374 697.607 259.498 766.292 223.009C828.409 190.042 890.837 183.28 914.367 205.211"
      stroke="#26242C"
    />
    <path
      d="M742.887 78.2482L683.944 64.1185C681.295 60.0719 681.993 56.724 684.629 55.5975C687.265 54.4709 699.659 57.8952 705.115 59.3535C710.571 60.8118 721.934 64.9315 729.321 67.3615C736.709 69.7914 742.833 78.2608 742.833 78.2608L742.887 78.2482Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <path
      d="M770.97 258.664C752.152 270.843 731.991 267.751 731.991 267.751C731.991 267.751 713.534 229.053 708.885 221.548C704.237 214.043 677.95 194.916 673.131 188.873C668.311 182.83 663.031 164.324 661.584 159.826C660.138 155.328 655.379 150.522 654.787 145.541C654.194 140.56 650.898 129.839 649.488 116.232C648.835 110.014 649.751 102.975 653.522 102.323C664.899 100.41 670.744 112.073 672.113 115.508C674.672 121.851 674.719 129.12 677.155 132.989C679.592 136.858 689.321 146.648 693.779 149.192C698.237 151.736 710.861 161.022 710.861 161.022C710.861 161.022 721.77 153.929 726.591 148.766C731.359 143.616 738.007 133.364 731.337 127.183C724.666 121.003 713.117 103.161 713.117 103.161C706.118 105.078 688.39 102.669 688.39 102.669C688.39 102.669 670.018 110.422 663.307 104.308C660.778 101.997 658.433 99.9837 658.309 98.4768C657.948 94.9776 661.287 94.4267 664.136 93.4782C666.984 92.5298 693.072 84.2295 693.072 84.2295C689.726 83.5307 683.383 80.4592 683.383 80.4592C671.679 79.2631 660.508 68.8986 661.918 62.9959C663.327 57.0931 675.931 59.9564 683.484 62.1203C691.037 64.2841 713.653 68.3969 719.484 69.0282C725.316 69.6595 741.295 74.8075 747.807 80.7974C754.266 86.7999 765.107 98.4356 771.155 103.168C777.204 107.901 779.101 115.308 777.5 127.456C775.898 139.603 773.691 167.932 773.563 185.424C773.422 202.862 792.944 233.69 792.944 233.69C792.944 233.69 789.908 246.513 771.036 258.705L770.97 258.664Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <path
      d="M693.042 84.1262C693.042 84.1262 710.492 79.4893 715.265 78.2628C719.984 77.0489 724.866 79.9491 728.169 83.3882C731.406 86.7859 742.868 98.1629 745.7 100.802C748.52 103.387 756.651 109.738 756.651 109.738"
      stroke="#26242C"
    />
    <path
      d="M708.653 159.377C714.197 162.919 719.216 163.967 724.496 161.257C729.776 158.548 737.419 151.59 737.419 151.59"
      stroke="#26242C"
    />
    <path
      d="M650.979 108.494C650.979 108.494 648.914 115.972 651.041 120.709C653.169 125.446 655.316 124.66 658.515 124.484C661.714 124.307 665.012 120.409 664.575 118.293C664.139 116.176 661.92 109.584 659.582 108.821C657.231 108.003 650.979 108.494 650.979 108.494Z"
      fill="#D7C4FA"
      stroke="#26242C"
    />
    <path
      d="M641.441 14.1919L656.426 101.877L660.491 102.977L645.211 13.5404L641.441 14.1919Z"
      fill="#BC5689"
      stroke="#26242C"
    />
    <path
      d="M669.836 12.8591C669.836 12.8591 682.959 8.43481 686.522 2.99672L690.439 27.1107C690.439 27.1107 686.963 34.6329 673.753 36.9732L646.254 41.9051L642.338 17.7911L669.836 12.8591Z"
      fill="#FBBD74"
      stroke="#26242C"
    />
    <path
      d="M650.867 47.0317L647.019 46.0005L646 42.0312L649.747 41.6252L650.867 47.0317Z"
      fill="#26242C"
    />
    <mask
      id="mask4_1431_54575"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="664"
      y="185"
      width="274"
      height="179"
    >
      <path
        d="M825.902 326.447C900.065 286.652 949.246 231.225 935.75 202.647C922.253 174.07 851.191 183.164 777.027 222.96C702.864 262.755 653.683 318.182 667.179 346.76C680.676 375.337 751.738 366.243 825.902 326.447Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_1431_54575)">
      <path
        d="M758.567 369.763C736.585 328.265 731.703 300.675 718.362 273.775C723.89 251.979 792.006 218.804 805.658 216.077C819.311 213.35 909.506 389.008 909.506 389.008L804.215 456.373C804.215 456.373 774.227 415.969 758.558 369.71L758.567 369.763Z"
        fill="#8D75E6"
        stroke="#26242C"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M779.811 209.31C842.352 181.086 901.336 179.79 916.325 207.997C932.732 238.874 890.3 293.374 821.593 329.771C752.82 366.127 683.834 370.605 667.373 339.741C654.635 315.821 677.284 277.65 720.196 244.719"
      stroke="#26242C"
    />
    <path
      d="M661.365 94.2951L662.293 102.91L660.529 102.412L659.327 95.5495L661.365 94.2951Z"
      fill="#26242C"
    />
    <path
      d="M789.423 211.978L788.339 209.27L786.649 206.563L780.216 209.27L781.299 212.52C781.299 212.52 782.455 213.46 782.924 215.228C782.924 215.228 784.51 214.037 786.715 213.011L789.423 211.978Z"
      fill="#26242C"
    />
    <path
      d="M121.023 245.448C131.402 239.269 127.675 213.842 112.7 188.654C97.7243 163.466 77.1708 148.056 66.7922 154.234C56.4135 160.413 60.14 185.841 75.1154 211.029C90.0908 236.217 110.644 251.627 121.023 245.448Z"
      fill="#D6EDD9"
    />
    <path
      d="M64.5391 156.136C75.5 153.699 93.9342 168.544 107.663 191.644C121.392 214.743 125.654 238.009 118.292 246.484"
      stroke="#26242C"
    />
    <path
      d="M135.233 153.479C135.233 153.479 138.665 153.701 140.215 156.36C141.765 159.019 139.606 162.453 139.606 162.453C139.606 162.453 142.651 162.121 144.09 164.115C145.862 166.497 144.422 169.433 144.422 169.433C144.422 169.433 145.253 169.266 146.692 171.981C148.131 174.695 145.253 178.24 143.592 179.736C141.931 181.176 139.385 183.447 138.001 184.278C136.617 185.109 133.185 186.993 130.417 188.488C126.929 190.316 123.22 191.203 119.954 192.2C116.633 193.197 101.243 203.666 101.243 203.666L89.5625 184.721C89.5625 184.721 98.8073 177.243 105.063 171.205C107.609 168.768 110.709 161.013 112.425 158.575C114.141 156.193 117.74 153.147 117.74 153.147L126.486 147.718C128.036 146.666 131.524 144.671 133.572 143.231C135.842 141.68 139.883 138.855 144.256 135.864C147.079 133.925 149.571 134.313 150.512 135.753C152.394 138.633 149.626 140.849 147.135 142.954C144.921 144.838 135.288 153.59 135.288 153.59L135.233 153.479Z"
      fill="#EADFF8"
      stroke="#26242C"
    />
    <mask
      id="mask5_1431_54575"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="61"
      y="151"
      width="70"
      height="95"
    >
      <path
        d="M123.222 244.134C134.783 237.252 132.015 211.254 117.04 186.066C102.065 160.878 80.5526 146.038 68.9916 152.921C57.4306 159.803 60.1986 185.802 75.174 210.99C90.1494 236.177 111.661 251.017 123.222 244.134Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_1431_54575)">
      <path
        d="M58.2829 201.67C66.033 197.127 81.5886 179.9 85.6851 181.229C92.4941 183.39 104.839 200.063 105.835 204.273C106.832 208.483 47.4881 253.186 47.4881 253.186L23.9609 225.212C23.9609 225.212 42.6719 210.865 58.3383 201.725L58.2829 201.67Z"
        fill="#8D75E6"
        stroke="#26242C"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M116.19 194.799C128.258 217.843 130.583 239.724 121.062 245.43C110.654 251.634 90.1164 236.234 75.1697 211.03C60.2231 185.825 56.4587 160.455 66.866 154.251C74.8929 149.431 89.0646 157.63 102.018 173.306"
      stroke="#26242C"
    />
    <path
      d="M146.195 140.402C146.195 140.402 145.586 140.069 145.198 139.516C144.811 138.962 144.866 138.297 144.866 138.297"
      fill="#EADFF8"
    />
    <path
      d="M146.195 140.402C146.195 140.402 145.586 140.069 145.198 139.516C144.811 138.962 144.866 138.297 144.866 138.297"
      stroke="#26242C"
    />
    <path d="M133.242 154.859L130.973 155.912" stroke="#26242C" />
    <path d="M143.367 172.034L144.474 169.375" stroke="#26242C" />
    <path
      d="M115.858 194.195L110.82 197.297L112.094 199.79L117.297 197.02L115.858 194.195Z"
      fill="#26242C"
    />
  </svg>
);
