const LogoAddition = () => {
  return (
    <svg width="192" height="24" viewBox="0 0 192 24" fill="currentColor">
      <title>Addition</title>
      <path d="M88.1465 1.73096H90.846V21.6401H88.1465V1.73096Z" />
      <path d="M129.939 1.73096H132.639V21.6401H129.939V1.73096Z" />
      <path d="M101.72 2.4415V1.73096H119.068V2.4415H111.756V21.6401H109.057V2.4415H101.72Z" />
      <path d="M11.4341 1.73169H8.45052L9.37402 4.04262L2.33789 21.6409H3.20761L5.48881 15.9357H14.127L16.4075 21.6409H19.3911L11.4341 1.73169ZM5.81479 15.1198L9.80888 5.12991L13.801 15.1198H5.81479Z" />
      <path d="M46.6139 8.03111C46.2109 6.84643 45.6068 5.78398 44.8008 4.8451C43.9948 3.9069 42.9777 3.15271 41.7495 2.58387C40.5212 2.01504 39.0925 1.73096 37.4619 1.73096H30.3887V21.6401H37.4613C39.0732 21.6401 40.488 21.3561 41.7076 20.7872C42.9259 20.2184 43.9423 19.4649 44.759 18.526C45.5736 17.5871 46.1877 16.5253 46.6 15.34C47.0129 14.1553 47.2194 12.9371 47.2194 11.6852C47.2187 10.434 47.0169 9.21579 46.6139 8.03111ZM37.3783 20.9296H33.3723V2.4415H37.3783C41.1818 2.4415 44.2657 6.57981 44.2657 11.6852C44.2657 16.7906 41.1818 20.9296 37.3783 20.9296Z" />
      <path d="M75.415 8.03111C75.012 6.84643 74.4079 5.78398 73.6019 4.8451C72.7959 3.9069 71.7788 3.15271 70.5506 2.58387C69.3223 2.01504 67.8936 1.73096 66.263 1.73096H59.1904V21.6401H66.263C67.875 21.6401 69.2898 21.3561 70.5094 20.7872C71.7277 20.2184 72.7441 19.4649 73.5607 18.526C74.3753 17.5871 74.9894 16.5253 75.4017 15.34C75.8147 14.1553 76.0212 12.9371 76.0212 11.6852C76.0205 10.434 75.818 9.21579 75.415 8.03111ZM66.1794 20.9296H62.1734V2.4415H66.1794C69.9829 2.4415 73.0668 6.57981 73.0668 11.6852C73.0668 16.7906 69.9829 20.9296 66.1794 20.9296Z" />
      <path d="M189.118 1.73096V21.6401H188.129L176.156 5.25141V21.6401H175.331V4.12181L173.584 1.73096H176.553L188.292 17.7987V1.73096H189.118Z" />
      <path d="M154.828 1.24707C156.644 1.24707 158.23 1.54525 159.587 2.1423C160.944 2.74001 162.072 3.52643 162.971 4.50292C163.869 5.47941 164.547 6.59357 165.007 7.84474C165.465 9.09657 165.695 10.3759 165.695 11.6842C165.695 12.9924 165.465 14.2725 165.007 15.5237C164.547 16.7755 163.869 17.889 162.971 18.8655C162.072 19.842 160.944 20.6284 159.587 21.2261C158.229 21.8231 156.643 22.1213 154.828 22.1213C153.011 22.1213 151.419 21.8231 150.053 21.2261C148.687 20.6284 147.549 19.842 146.641 18.8655C145.733 17.889 145.054 16.7748 144.605 15.5237C144.155 14.2718 143.932 12.9924 143.932 11.6842C143.932 10.3759 144.155 9.0959 144.605 7.84474C145.054 6.5929 145.733 5.47941 146.641 4.50292C147.549 3.52643 148.687 2.74001 150.053 2.1423C151.419 1.54593 153.011 1.24707 154.828 1.24707ZM154.815 1.96097C150.593 1.96097 147.17 6.31553 147.17 11.6869C147.17 17.0582 150.593 21.4128 154.815 21.4128C159.037 21.4128 162.46 17.0582 162.46 11.6869C162.46 6.31553 159.037 1.96097 154.815 1.96097Z" />
    </svg>
  );
};

export default LogoAddition;
