import { first, last } from "lodash-es";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { Action, Note } from "../../../types/tines.types";
import { XY } from "../../../utils/geometry.utils";

export const GRID_SIZE = 15;
export const gridUnit = (input: number) => input * GRID_SIZE;

export const MAX_ACTION_WIDTH = gridUnit(15);
export const MIN_ACTION_WIDTH = gridUnit(7);
export const MIN_ACTION_HEIGHT = gridUnit(3);

export const NOTES_MAX_HEIGHT = gridUnit(38);

export const FORM_WIDTH = gridUnit(18);
export const FORM_HEIGHT = gridUnit(16);

export const STORYBOARD_BG_COLOR = {
  lightMode: colorsV4.canvas300,
  darkMode: colorsV4.warmBlack900,
};

export const stretchToGridUnit = (
  v: number,
  compensateForPixelRounding?: boolean
) => {
  return (
    Math.ceil((v + (compensateForPixelRounding ? 1 : 0)) / GRID_SIZE) *
    GRID_SIZE
  );
};

export const getStoryboardElementGroupMeasurements = (
  actions: Action[],
  notes: Note[]
) => {
  const allElements = [...actions, ...notes];

  const elementsSortedByX = [...allElements].sort(
    (a, b) => a.position.x - b.position.x
  );
  const elementsSortedByY = [...allElements].sort(
    (a, b) => a.position.y - b.position.y
  );
  const elementsSortedByEndX = [...allElements].sort(
    (a, b) => a.position.x + (a.width ?? 0) - (b.position.x + (b.width ?? 0))
  );
  const elementsSortedByEndY = [...allElements].sort(
    (a, b) => a.position.y + (a.height ?? 0) - (b.position.y + (b.height ?? 0))
  );
  const topMostElement = first(elementsSortedByY)!;
  const bottomMostElement = last(elementsSortedByEndY)!;
  const leftMostElement = first(elementsSortedByX)!;
  const rightMostElement = last(elementsSortedByEndX)!;

  const actionsSortedByX = [...actions].sort(
    (a, b) => a.position.x - b.position.x
  );
  const actionsSortedByY = [...actions].sort(
    (a, b) => a.position.y - b.position.y
  );
  const actionsSortedByEndX = [...actions].sort(
    (a, b) => a.position.x + a.width! - (b.position.x + b.width!)
  );
  const actionsSortedByEndY = [...actions].sort(
    (a, b) => a.position.y + a.height! - (b.position.y + b.height!)
  );

  const topMostAction = first(actionsSortedByY)!;
  const bottomMostAction = last(actionsSortedByEndY)!;
  const leftMostAction = first(actionsSortedByX)!;
  const rightMostAction = last(actionsSortedByEndX)!;

  const xStart = leftMostElement?.position.x ?? 0;
  const yStart = topMostElement?.position.y ?? 0;

  const boundingBox = [
    { x: xStart, y: yStart },
    {
      x: rightMostElement
        ? rightMostElement.position.x +
          (rightMostElement.width ?? MAX_ACTION_WIDTH)
        : 0,
      y: bottomMostElement
        ? bottomMostElement.position.y + (bottomMostElement.height ?? 0)
        : 0,
    },
  ] as [XY, XY];

  const width = boundingBox[1].x - boundingBox[0].x;
  const height = boundingBox[1].y - boundingBox[0].y;

  return {
    boundingBox,
    width,
    height,
    center: {
      x: width / 2 + xStart,
      y: height / 2 + yStart,
    },
    element: {
      topMost: topMostElement,
      bottomMost: bottomMostElement,
      leftMost: leftMostElement,
      rightMost: rightMostElement,
    },
    action: {
      topMost: topMostAction,
      bottomMost: bottomMostAction,
      leftMost: leftMostAction,
      rightMost: rightMostAction,
    },
  };
};

export type StoryboardElementGroupMeasurements = ReturnType<
  typeof getStoryboardElementGroupMeasurements
>;
