import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import {
  fromDesktopMd,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { valueWithOptionalUnit } from "../../../utils/css.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { snapToNearestMultiplesOfValue } from "../../../utils/math.utils";
import { useStateWithRef } from "../../../utils/stateWithRef.hook";
import FlexibleDotGrid, {
  DEFAULT_CELL_SIZE,
} from "../../decorations/FlexibleDotGrid";
import SpotIllustration from "../../illustrations/SpotIllustration";
import PageSection from "../../reusableSections/PageSection";
import { colors } from "../../../styles/colors.styles";

const AWSPageIntroSectionInner = styled.div`
  position: relative;
`;

const ContentGrid = styled.div<{ gridCellSize: number }>`
  position: relative;
  display: grid;
  grid-gap: ${p => `${p.gridCellSize}px`};
  padding-top: ${p => `${p.gridCellSize * 2}px`};
  padding-bottom: ${p => `${p.gridCellSize * 2}px`};
  ${fromTabletMd} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: ". block block block block block block .";
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ". . block block block block block block block block . .";
  }
  > * {
    background-color: ${colors.lightest};
  }
`;

type BlockProps = { gridCellSize: number; minHeight: string | number };
const Block = styled.div<BlockProps>`
  padding-bottom: ${p => `${p.gridCellSize}px`};
  box-sizing: content-box;
  min-height: ${p => valueWithOptionalUnit(p.minHeight, "auto")};
  padding-top: ${p => `${p.gridCellSize * 0.5}px`};
  ${fromTabletMd} {
    grid-area: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${fromDesktopMd} {
    padding-bottom: ${p => `${p.gridCellSize * 1.5}px`};
  }
`;

const BlockContent = styled.div`
  text-align: center;
  ${mediaFromTo("phoneLg", "desktop")} {
    padding-left: 2em;
    padding-right: 2em;
  }
  > * {
    + * {
      margin-top: ${rSize("md")};
    }
  }
  p {
    ${fromTabletLg} {
      max-width: ${widthInGrid(6)};
    }
  }
  em {
    font-style: inherit;
    font-family: ${font("serif")};
    font-size: 125%;
  }
`;

const AWSPageIntroSection = () => {
  const [gridCellSizeRef, setGridCellSize, gridCellSize] =
    useStateWithRef(DEFAULT_CELL_SIZE);
  const contentGridRef = useRef<HTMLDivElement>(null);
  const blockContentRef = useRef<HTMLDivElement>(null);
  const [blockMinHeight, setBlockMinHeight] = useState<string | number>(
    "unset"
  );
  useOnMount(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      setBlockMinHeight(
        snapToNearestMultiplesOfValue(
          entry.target.clientHeight,
          gridCellSizeRef.current,
          "ceil"
        )
      );
    });
    if (blockContentRef.current)
      resizeObserver.observe(blockContentRef.current);
    return () => resizeObserver.disconnect();
  });
  return (
    <PageSection>
      <AWSPageIntroSectionInner>
        <FlexibleDotGrid
          cover
          onFittedCellSizeRecalc={setGridCellSize}
          heightCalculationRef={contentGridRef}
        />
        <ContentGrid ref={contentGridRef} gridCellSize={gridCellSize}>
          <Block gridCellSize={gridCellSize} minHeight={blockMinHeight}>
            <BlockContent ref={blockContentRef}>
              <SpotIllustration name="skyTunnel" />
              <p>
                <em>
                  Cloud security is a fast-changing and dynamic environment
                  which results in many teams struggling to know what is serious
                  and what is noise.
                </em>
              </p>
              <p>
                Tines, built exclusively on AWS, helps teams quickly remediate
                known threats while effectively triaging the new. Providing a
                user friendly interface to build automation Stories, teams are
                able to quickly create new workflows, utilizing native AWS APIs
                to create solutions based on their team’s needs. Tines also is
                packaged with thousands of Action templates to utilize threat
                detection services outside of the AWS ecosystem.
              </p>
            </BlockContent>
          </Block>
        </ContentGrid>
      </AWSPageIntroSectionInner>
    </PageSection>
  );
};

export default AWSPageIntroSection;
