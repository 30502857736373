export const SendToStoryIcon = {
  sendToStory: () => (
    <svg
      className="Icon SendToStoryIcon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8 5.49999C6.8 6.21796 6.21797 6.79999 5.5 6.79999C4.78203 6.79999 4.2 6.21796 4.2 5.49999C4.2 4.78202 4.78203 4.19999 5.5 4.19999C6.21797 4.19999 6.8 4.78202 6.8 5.49999ZM6.1 7.92752C7.19093 7.65879 8 6.67387 8 5.49999C8 4.11928 6.88071 2.99999 5.5 2.99999C4.11929 2.99999 3 4.11928 3 5.49999C3 6.67387 3.80907 7.65879 4.9 7.92752V12.0725C3.80907 12.3412 3 13.3261 3 14.5C3 15.8807 4.11929 17 5.5 17C6.88071 17 8 15.8807 8 14.5C8 13.3261 7.19093 12.3412 6.1 12.0725V7.92752ZM6.8 14.5C6.8 15.218 6.21797 15.8 5.5 15.8C4.78203 15.8 4.2 15.218 4.2 14.5C4.2 13.782 4.78203 13.2 5.5 13.2C6.21797 13.2 6.8 13.782 6.8 14.5ZM14.5 15.8C15.218 15.8 15.8 15.218 15.8 14.5C15.8 13.782 15.218 13.2 14.5 13.2C13.782 13.2 13.2 13.782 13.2 14.5C13.2 15.218 13.782 15.8 14.5 15.8ZM14.5 17C15.8807 17 17 15.8807 17 14.5C17 13.3261 16.1909 12.3412 15.1 12.0725V9.80118C15.1 8.16862 14.4515 6.60293 13.2971 5.44854L11.6817 3.83314L13.1873 4.22482H14.5V3.02482H10.6248H10.0248V3.62482V7.5H11.2248V6.31352L10.8323 4.68081L12.4486 6.29707C13.3779 7.22642 13.9 8.48688 13.9 9.80118V12.0724C12.8091 12.3412 12 13.3261 12 14.5C12 15.8807 13.1193 17 14.5 17Z"
      />
    </svg>
  ),
};
