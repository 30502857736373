export const LifebuoyTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.39241 3H5C3.89543 3 3 3.89543 3 5V5.39241C2.66852 5.94253 2.41056 6.54195 2.23972 7.17707C2.08541 6.81567 2 6.41781 2 6V5C2 3.34315 3.34315 2 5 2H6C6.41781 2 6.81567 2.08541 7.17707 2.23972C6.54195 2.41056 5.94253 2.66852 5.39241 3ZM10.8229 2.23972C11.458 2.41056 12.0575 2.66852 12.6076 3H13C14.1046 3 15 3.89543 15 5V5.39241C15.3315 5.94253 15.5894 6.54195 15.7603 7.17707C15.9146 6.81567 16 6.41781 16 6V5C16 3.34315 14.6569 2 13 2H12C11.5822 2 11.1843 2.08541 10.8229 2.23972ZM15.7603 10.8229C15.5894 11.458 15.3315 12.0575 15 12.6076V13C15 14.1046 14.1046 15 13 15H12.6076C12.0575 15.3315 11.458 15.5894 10.8229 15.7603C11.1843 15.9146 11.5822 16 12 16H13C14.6569 16 16 14.6569 16 13V12C16 11.5822 15.9146 11.1843 15.7603 10.8229ZM7.17707 15.7603C6.54195 15.5894 5.94253 15.3315 5.39241 15H5C3.89543 15 3 14.1046 3 13V12.6076C2.66852 12.0575 2.41056 11.458 2.23972 10.8229C2.08541 11.1843 2 11.5822 2 12V13C2 14.6569 3.34315 16 5 16H6C6.41781 16 6.81567 15.9146 7.17707 15.7603Z"
      fill="currentColor"
    />
    <circle cx="9" cy="9" r="6.5" stroke="currentColor" />
    <circle cx="9" cy="9" r="3.5" stroke="currentColor" />
    <path
      opacity="0.7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.57905 2.14033C7.95765 2.34746 7.5793 2.97588 7.68698 3.62197L7.83559 4.51362C7.93046 5.08283 8.42293 5.50002 8.99999 5.50002C9.57704 5.50002 10.0695 5.08283 10.1644 4.51362L10.313 3.62197C10.4207 2.97588 10.0423 2.34746 9.42092 2.14033C9.14769 2.04925 8.85228 2.04925 8.57905 2.14033ZM8.99999 12.5C8.42293 12.5 7.93046 12.9172 7.83559 13.4864L7.68698 14.3781C7.5793 15.0242 7.95765 15.6526 8.57905 15.8597C8.85228 15.9508 9.14769 15.9508 9.42092 15.8597C10.0423 15.6526 10.4207 15.0242 10.313 14.3781L10.1644 13.4864C10.0695 12.9172 9.57704 12.5 8.99999 12.5ZM13.4864 10.1644C12.9172 10.0695 12.5 9.57707 12.5 9.00002C12.5 8.42296 12.9172 7.93049 13.4864 7.83562L14.378 7.68701C15.0241 7.57933 15.6525 7.95769 15.8597 8.57908C15.9508 8.85232 15.9508 9.14772 15.8597 9.42095C15.6525 10.0423 15.0241 10.4207 14.378 10.313L13.4864 10.1644ZM2.1403 9.42095C2.34743 10.0423 2.97585 10.4207 3.62194 10.313L4.51359 10.1644C5.0828 10.0695 5.49999 9.57707 5.49999 9.00002C5.49999 8.42296 5.0828 7.93049 4.51359 7.83562L3.62194 7.68701C2.97585 7.57933 2.34743 7.95769 2.1403 8.57908C2.04922 8.85232 2.04922 9.14772 2.1403 9.42095Z"
      fill="currentColor"
    />
  </svg>
);
