import { styled } from "@linaria/react";
import { DatoCmsToolsSection } from "../../../../graphql-types";
import ToolEntry from "../ToolEntry";
import LibraryCollectionPageSectionHeader from "./LibraryCollectionPageSectionHeader";
import { rSize } from "../../../styles/responsiveSizes.styles";
import ToolsIcon from "../../illustrations/spots/ToolsIcon";

type Props = {
  section: DatoCmsToolsSection;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-gap: ${rSize("gap")};
`;

const ToolsSection = ({ section }: Props) => {
  return (
    <div id="tools">
      <LibraryCollectionPageSectionHeader
        section={{
          ...section,
          pill: section.pill || "Tools",
          linkTextLabel: "All tools",
          linkUrl: "/library/tools",
        }}
        imageOverride={<ToolsIcon />}
      />
      <Grid>
        {section.tools?.map((t, i) => (
          <ToolEntry tool={t!} key={i} />
        ))}
      </Grid>
    </div>
  );
};

export default ToolsSection;
