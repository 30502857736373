import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromTabletLg,
  fromTabletMd,
  uptoTabletLg,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { GridCanvas } from "../decorations/GridCanvas";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import enterpriseAiPortalIcon from "./enterpriseAiPortalIcon.svg";
import enterpriseFloatingShieldedBuildingIcon from "./enterpriseFloatingShieldedBuildingIcon.svg";
import enterpriseFloatingStatBuildingsIcon from "./enterpriseFloatingStatBuildingsIcon.svg";
import Spacing from "../layout/Spacing";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import Button from "../forms/Button";
import { serif } from "../../styles/fonts.styles";

const SectionWrap = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  max-width: ${maxPageContentWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding: ${rSize("sectionPadding")} ${rSize("pageMargin")} 0
    ${rSize("pageMargin")};
  ${uptoTabletLg} {
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  border: 1px solid ${colors.purple100};
  position: absolute;
  left: ${rSize("widerPageMargin")};
  right: ${rSize("widerPageMargin")};
  top: 0;
  bottom: 0;
  ${fromTabletLg} {
    border-radius: ${rSize("radius")};
    bottom: 18em;
  }
`;

const Inner = styled.div`
  position: relative;
`;

const Header = styled.header`
  text-align: center;
  h2 {
    max-width: 10em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ThreeItemGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Column = styled.div`
  text-align: center;
  ${fromTabletMd} {
    text-align: left;
  }
  > * {
    ${fromTabletMd} {
      &:not(img) {
        padding-right: 1em;
      }
    }
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  h3 {
    font-size: 2rem;
    font-weight: 600;
    ${fromTabletMd} {
      padding-right: 1em;
    }
    ${fromDesktop} {
      font-size: 2.4rem;
    }
  }
  p,
  li {
    font-weight: 500;
    line-height: 1.3;
  }
  ul,
  ol {
    margin-top: 0.75em;
    padding-left: 1.5em;
    text-align: left;
    ${uptoTabletMd} {
      background-color: ${colors.purple50};
      border: 1px solid ${colors.purple100};
      padding: 0.75em 1em 1em 2em;
      border-radius: ${rSize("radius")};
    }
  }
  li {
    margin-top: 0.33em;
  }
`;

const OakRidgeBlock = styled.div`
  position: relative;
  display: grid;
  grid-gap: ${rSize("gap")};
  color: ${colors.green800};
  margin-top: ${rSize("cardSectionPadding")};
  text-align: center;
  ${fromTabletMd} {
    text-align: left;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    &:before {
      content: "";
      position: absolute;
      top: ${rSize("cardSectionPadding", -1)};
      left: ${rSize("cardSectionPadding", -1)};
      right: ${rSize("cardSectionPadding", -1)};
      bottom: ${rSize("cardSectionPadding", -1)};
      background-color: ${colors.lightest};
      border-radius: ${rSize("radius")};
      border: 1px solid ${colors.green100};
    }
  }
`;

const OakRidgeBlockHeader = styled.header`
  position: relative;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  svg {
    display: block;
    height: 4.6rem;
    width: auto;
    ${uptoTabletMd} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const OakRidgeBlockHeaderTextContent = styled.div`
  > * {
    + * {
      margin-top: 1.6rem;
    }
  }
  h3 {
    font-family: ${serif};
    font-weight: 400;
    letter-spacing: -0.015em;
    font-size: 2.4rem;
    ${fromDesktop} {
      font-size: 3.4rem;
      margin-right: 1em;
    }
  }
  p {
    ${fromDesktop} {
      margin-right: 2em;
    }
  }
`;

const BeforeAfterCardSet = styled.div`
  position: relative;
  display: grid;
  max-width: ${getWidthPxInMaxGrid(8)};
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-areas: "before after";
  text-align: left;
  ${uptoTabletMd} {
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
    margin-top: 2em;
  }
  ${fromTabletMd} {
    grid-column-end: span 2;
  }
`;

const BeforeAfterCard = styled.div`
  background-color: ${colors.green50};
  border: 1px solid ${colors.green300};
  padding: 0 ${rSize("pageMargin")} ${rSize("pageMargin")};
  ${fromTabletMd} {
    padding: 0 ${rSize("lg")} ${rSize("xl")};
  }
  &.before {
    grid-area: before;
    margin-right: -1.2rem;
    margin-top: 6.4rem;
    padding-right: calc(${rSize("lg")} + 0.5em);
    ${fromTabletMd} {
      border-top-left-radius: ${rSize("radius")};
      border-bottom-left-radius: ${rSize("radius")};
    }
    ${fromDesktop} {
      padding-right: calc(${rSize("lg")} + ${rSize("gap")});
    }
  }
  &.after {
    grid-area: after;
    margin-left: -1.2rem;
    padding-left: ${rSize("lg")};
    margin-bottom: 6.4rem;
    position: relative;
    ${fromTabletMd} {
      border-top-right-radius: ${rSize("radius")};
      border-bottom-right-radius: ${rSize("radius")};
    }
    &:before {
      content: "";
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='64' viewBox='0 0 24 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 64L0 0.0146539L24 0V64Z' fill='%2387D1A3'/%3E%3C/svg%3E%0A");
      width: 2.4rem;
      height: 6.4rem;
      position: absolute;
      top: calc(100% + 1px);
      left: -1px;
    }
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;
    ${fromTabletMd} {
      font-size: 1.8rem;
      font-family: ${serif};
      font-weight: 400;
      letter-spacing: -0.015em;
      min-height: 10em;
      line-height: 1.4;
    }
    ${fromDesktop} {
      font-size: 2.2rem;
    }
    svg {
      vertical-align: middle;
      margin-right: 0.15em;
    }
  }
`;

const BeforeAfterCardTag = styled.div`
  border-bottom-left-radius: 0.33em;
  border-bottom-right-radius: 0.33em;
  display: inline-block;
  border: 1px solid transparent;
  border-top-width: 0;
  padding: 0.5em 0.75em;
  font-weight: 600;
  background-color: ${colors.green100};
  border-color: ${colors.green300};
  font-size: 1.3rem;
  ${fromDesktop} {
    font-size: 1.4rem;
  }
  + * {
    margin-top: 1em;
  }
`;

export const EnterprisePageBeforeAfterSection = () => (
  <SectionWrap>
    <Backdrop>
      <GridCanvas color={colors.purple100} parallax />
    </Backdrop>
    <Inner>
      <Header>
        <SectionHeading2 lighter>
          Tines changes the way teams operate
        </SectionHeading2>
      </Header>
      <Spacing size="sectionPadding" />
      <ThreeItemGrid>
        <Column>
          <img
            src={enterpriseFloatingStatBuildingsIcon}
            alt=""
            width={68}
            height={90}
          />
          <h3>Improve operational efficiency and ROI on tech investments</h3>
          <p>
            Work through tech debt with your existing resources to modernize
            your infrastructure. Tines customers find their teams:
          </p>
          <ul>
            <li>Take on more projects</li>
            <li>Communicate better across stakeholders</li>
            <li>Get rid of software bloat</li>
          </ul>
        </Column>
        <Column>
          <img src={enterpriseAiPortalIcon} alt="" width={68} height={90} />
          <h3>Accelerate your AI strategic goals</h3>
          <p>
            Implementations of AI and LLMs are plagued with delays created by
            security and privacy concerns. AI in Tines is:
          </p>
          <ul>
            <li>Private and secure by default</li>
            <li>
              Free from training, logging, networking, and storage via AWS
              Bedrock
            </li>
          </ul>
        </Column>
        <Column>
          <img
            src={enterpriseFloatingShieldedBuildingIcon}
            alt=""
            width={68}
            height={90}
          />
          <h3>Mitigate risk and reinforce your security posture</h3>
          <p>
            Stop vendor lock-in and single points of failure. With Tines, you
            gain:
          </p>
          <ul>
            <li>Agility to your evolving ecosystem</li>
            <li>Adherence to compliance and corporate policies</li>
          </ul>
        </Column>
      </ThreeItemGrid>

      <Spacing size="sectionPadding" />

      <OakRidgeBlock>
        <OakRidgeBlockHeader>
          <LogoOakRidge />
          <OakRidgeBlockHeaderTextContent>
            <h3>Going from manual silos to collaborative and agile teams</h3>
            <p>
              Teams, like Oak Ridge National Laboratory, remove silos and single
              points of failure and are now innovating on new projects in a
              highly collaborative way.
            </p>
            <Button
              to="/case-studies/oak-ridge-national-laboratory/"
              appearance="outlined"
              color="green"
              darker
            >
              Read the case study
            </Button>
          </OakRidgeBlockHeaderTextContent>
        </OakRidgeBlockHeader>
        <BeforeAfterCardSet>
          <BeforeAfterCard className="before">
            <BeforeAfterCardTag>Before using Tines</BeforeAfterCardTag>
            <p>
              <QuoteIcon /> It was very one-off and manual. Writing individual
              scripts to try to automate many different applications … plus
              managing them as people move in and out.
            </p>
          </BeforeAfterCard>
          <BeforeAfterCard className="after">
            <BeforeAfterCardTag>After using Tines</BeforeAfterCardTag>
            <p>
              <QuoteIcon /> With Tines, the other teams now have the power to
              build the playbooks themselves. They don’t have to wait on the
              engineering team anymore. The power is now in their hands.
            </p>
          </BeforeAfterCard>
        </BeforeAfterCardSet>
      </OakRidgeBlock>
    </Inner>
  </SectionWrap>
);

const QuoteIconSvg = styled.svg`
  vertical-align: middle;
  width: 1em;
  height: 1em;
  transform: translateY(-0.1em);
`;

const QuoteIcon = () => (
  <QuoteIconSvg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17618 0C2.76517 0 0 2.82767 0 6.31578V17.6842C0 21.1723 2.76516 24 6.17618 24H17.8238C21.2348 24 24 21.1723 24 17.6842V6.31579C24 2.82767 21.2348 0 17.8238 0H6.17618ZM11.4864 10.5616H11.8038V8.84195H11.2748C9.56033 8.84195 8.52323 9.92466 8.52323 11.7928V13.8946H10.5763V11.9415C10.5763 11.0286 10.9149 10.5616 11.4864 10.5616ZM15.1691 10.5616H15.4867V8.84195H14.9575C13.222 8.84195 12.1848 9.92466 12.1848 11.7928V13.8946H14.2378V11.9415C14.2378 11.0286 14.5765 10.5616 15.1691 10.5616Z"
    />
  </QuoteIconSvg>
);
