import { graphql } from "gatsby";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import Page from "./Page.template";
import { PageComponent } from "../types/gatsby.types";
import { ArticleCategoryPageQuery, DatoCmsArticle } from "../../graphql-types";
import ArticleIndexGrid from "../components/articles/ArticleIndexGrid";
import { LayoutWithSidebar } from "../components/layout/LayoutWithSidebar";
import StandardSidebar from "../components/layout/StandardSidebar";
import BlogCategoryNav from "../components/blog/BlogCategoryNav";
import GlobalSearchBar from "../components/search/GlobalSearchBar";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { PaginatedListNav } from "../components/basic/ListWithPagination";

const ArticleCategoryPageTemplate: PageComponent<{
  data: ArticleCategoryPageQuery;
  pageContext: {
    slug: string;
    page: number;
    perPage: number;
    totalNumOfPages: number;
    totalEntries: number;
    fromIndex: number;
  };
}> = props => {
  const isIndex = !props.pageContext.slug;
  const categoryName = isIndex ? "Blog" : props.data.category?.name ?? "Blog";
  const defaultDesc =
    "Update from Tines; the smart, secure workflow builder for your whole team.";
  return (
    <Page {...props}>
      <SEO
        title={categoryName}
        description={
          isIndex
            ? defaultDesc
            : props.data.category?.description ?? defaultDesc
        }
        image={`/images/og/tines-default-${isIndex ? 500 : 200}.png`}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType={isIndex ? "article" : "articleCategory"}
        recordId={props.data.category?.id}
      />
      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <GlobalSearchBar showShortcutHint />
          <BlogCategoryNav />
        </StandardSidebar>
        <ArticleIndexGrid
          articles={props.data.articles.nodes as DatoCmsArticle[]}
          showFirstPostAsFeatured={props.pageContext.page === 1}
          includeNewsletterCta={props.pageContext.page === 1}
        />
        {props.pageContext.totalNumOfPages > 1 && (
          <>
            <Spacing size="lg" />
            <PaginatedListNav
              page={props.pageContext.page}
              totalEntries={props.pageContext.totalEntries}
              perPage={props.pageContext.perPage}
              linkFactory={p =>
                `/blog${
                  !isIndex && props.data.category?.slug
                    ? `/${props.data.category.slug}`
                    : ""
                }${p === 1 ? "" : `/page/${p}`}`
              }
            />
          </>
        )}
      </LayoutWithSidebar>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const articleCategoryPageQuery = graphql`
  query ArticleCategoryPage($slug: String, $fromIndex: Int, $perPage: Int) {
    category: datoCmsArticleCategory(slug: { eq: $slug }) {
      id: originalId
      name
      slug
      description
    }
    articles: allDatoCmsArticle(
      sort: { datePublished: DESC }
      skip: $fromIndex
      limit: $perPage
      filter: {
        categories: { elemMatch: { slug: { eq: $slug } } }
        hiddenInBlogIndex: { ne: true }
      }
    ) {
      nodes {
        title
        slug
        datePublished
        excerpt
        coverImage {
          url
          width
          height
        }
        categories {
          slug
        }
      }
    }
  }
`;

export default ArticleCategoryPageTemplate;
