import { lighten } from "polished";
import { CSSProperties } from "react";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import LinkToAnchor from "../../basic/LinkToAnchor";
import Spacing from "../../layout/Spacing";
import UptoDesktop from "../../mediaQueries/UptoDesktop";
import NoWrap from "../../typography/Nowrap";
import { Serif } from "../../typography/Serif";
import ReportNav from "../ReportNav";
import AllCaps from "../../typography/AllCaps";

const NoCodePlaybookNav = () => {
  return (
    <ReportNav
      accentColor="purple"
      beforeHeading={
        <>
          <LinkToAnchor to="#" style={{ textDecoration: "none" }}>
            <AllCaps children="Playbook" />
          </LinkToAnchor>
          <Spacing size="1em" />
        </>
      }
      heading={
        <>
          <span>Essential Guide to </span>
          <span>
            <NoWrap>No-Code</NoWrap> Automation
          </span>{" "}
          <Serif>for Security Teams</Serif>
        </>
      }
      headingFontSize="2.15rem"
    >
      <ul>
        <UptoDesktop>
          <li>
            <LinkToAnchor to="#" className="cover-link">
              <i></i>
              <span>Cover</span>
            </LinkToAnchor>
          </li>
        </UptoDesktop>
        <li>
          <LinkToAnchor to="#a-word-from-eoin-hinchy">
            <i></i>
            <span>A word from Eoin Hinchy</span>
          </LinkToAnchor>
        </li>
      </ul>
      <hr />
      <ol>
        <li>
          <LinkToAnchor
            to="#introduction"
            style={{ "--ThemeColorPrimary": colorsV4.purple } as CSSProperties}
          >
            <i></i>
            <span>Introduction</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#how-to-build-a-no-code-security-automation-program"
            style={{ "--ThemeColorPrimary": colorsV4.orange } as CSSProperties}
          >
            <i></i>
            <span>
              How to build a no-code automation program for your security team
            </span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#five-common-misconceptions"
            style={{ "--ThemeColorPrimary": colorsV4.pink } as CSSProperties}
          >
            <i></i>
            <span>Five common misconceptions</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#five-best-practices"
            style={{ "--ThemeColorPrimary": colorsV4.green } as CSSProperties}
          >
            <i></i>
            <span>Five best practices</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#preparing-for-the-future"
            style={
              {
                "--ThemeColorPrimary": lighten(0.1, colorsV4.warmBlack),
              } as CSSProperties
            }
          >
            <i></i>
            <span>Preparing for the future</span>
          </LinkToAnchor>
        </li>
      </ol>
      <hr />
      <ul>
        <li>
          <LinkToAnchor
            to="#conclusion"
            style={{ "--ThemeColorPrimary": colorsV4.purple } as CSSProperties}
          >
            <i></i>
            <span>Conclusion</span>
          </LinkToAnchor>
        </li>
      </ul>
    </ReportNav>
  );
};

export default NoCodePlaybookNav;
