import { styled } from "@linaria/react";
import React from "react";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { extractNoteVideoUrl } from "../utils/extractNoteVideoUrl.utils";

type Props = {
  url: string;
  fallback?: JSX.Element;
  onLoad?: () => void;
  onError?: () => void;
};

const VideoFrame = styled.div`
  background-color: ${colorsV4.warmBlack900};
  color: ${colorsV4.white};
  border: 1px solid ${withOpacity(colorsV4.grey, 0.15)};
  border-radius: 0.5em;
  aspect-ratio: 16/9;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em;
  font-size: 1.2rem;
  svg {
    margin-bottom: 0.5em;
  }
  p {
    opacity: 0;
    font-weight: 500;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
`;

export default function Video({ url, fallback, onLoad, onError }: Props) {
  const embedUrl = extractNoteVideoUrl(url);
  useOnMount(() => {
    if (embedUrl) onLoad?.();
    else onError?.();
  });
  if (embedUrl) {
    return (
      <VideoFrame>
        <div>
          <svg width="33" height="33" viewBox="0 0 33 33" fill="currentColor">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33ZM23.3543 17.3532C23.9922 16.9632 23.9922 16.0367 23.3543 15.6468L13.5216 9.63591C12.8552 9.22857 12 9.70812 12 10.4891V22.5108C12 23.2918 12.8552 23.7714 13.5216 23.3641L23.3543 17.3532Z"
            />
          </svg>
        </div>
      </VideoFrame>
    );
  }

  return fallback || null;
}
