export const CampingTentTwoToneIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      opacity="0.2"
      d="M9.64897 6.85559C9.79539 6.70536 10.0133 6.66926 10.1941 6.74729C12.5545 9.31978 14.746 12.3574 15.7591 14.8505C15.8875 15.1666 15.6476 15.5 15.3063 15.5H11.2035C11.5712 15.498 11.8114 15.1125 11.6503 14.7812L9.86652 11.1154C9.19911 12.0262 8.5188 13.7825 8.17357 14.8884C8.07728 15.1968 8.31109 15.4982 8.63371 15.5H3.3895C4.5903 12.7758 7.09122 9.47994 9.64897 6.85559Z"
    />
    <path d="M9.00001 1.5C9.27615 1.5 9.50001 1.72386 9.50001 2H11.7586C12.2931 2 12.5608 2.64628 12.1829 3.02426L11.7071 3.5L12.1829 3.97574C12.5608 4.35372 12.2931 5 11.7586 5H9.50001V5.34266C9.57498 5.38661 9.64541 5.44126 9.70911 5.50662C12.3978 8.26531 15.0545 11.7884 16.2223 14.6622C16.494 15.3308 15.9766 16 15.3063 16H11.5H6.50001H2.69373C2.02342 16 1.50606 15.3308 1.77775 14.6622C2.94556 11.7884 5.60222 8.26531 8.29091 5.50662C8.35461 5.44126 8.42505 5.38661 8.50001 5.34266V4.4V2.6V2C8.50001 1.72386 8.72387 1.5 9.00001 1.5ZM9.50001 4H10.7929L10.7172 3.92426C10.4829 3.68995 10.4829 3.31005 10.7172 3.07574L10.7929 3H9.50001V4ZM9.00001 6.2118C6.36688 8.91489 3.82562 12.3064 2.71999 15H6.11132C6.56813 13.2395 7.08083 11.7904 8.35547 9.60723C8.64361 9.11372 9.35641 9.11372 9.64455 9.60723C10.9192 11.7904 11.4319 13.2395 11.8887 15H15.28C14.1744 12.3064 11.6331 8.91489 9.00001 6.2118ZM10.8546 15C10.4602 13.5272 10.0038 12.2707 9.00001 10.4928C7.99626 12.2707 7.53983 13.5272 7.14541 15H10.8546Z" />
  </svg>
);
