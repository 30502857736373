import { color, colors } from "../../../styles/colors.styles";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import {
  ReportV2FullDarkSectionNavControlPoint,
  ReportV2NavGreenControlPoint,
  ReportV2NavOrangeControlPoint,
  ReportV2NavPinkControlPoint,
  ReportV2NavPurpleControlPoint,
} from "../components/ReportV2CommonNavControlPoints";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import { DumbbellIcon } from "./icons/DumbbellIcon";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import Spacing from "../../layout/Spacing";
import { ReportV2Heading4 } from "../components/ReportV2Heading4";
import ReportV2ContentCard from "../components/ReportV2ContentCard";
import AllCaps from "../../typography/AllCaps";
import { HatchingDinoEggIcon } from "../../home/spotIcons/HatchingDinoEggIcon";
import { GreenCheckIcon } from "./icons/GreenCheckIcon";
import { KaputtRobotIcon } from "./icons/KaputtRobot";
import { LogoElastic45h } from "../../thirdPartyLogos/LogoElastic45h";
import { Sans } from "../../typography/Sans";
import Button from "../../forms/Button";
import { ReportV2BeforeAfterSplit } from "../components/ReportV2BeforeAfterSplit";
import {
  ReportV2Blockquote,
  ReportV2BlockquoteBody,
} from "../components/ReportV2Blockquote";
import { AlignCenterOnMobile } from "../../typography/Align";
import {
  NegativeIndentLeftDiv,
  NegativeIndentLeftP,
} from "../../typography/NegativeIndentLeft";

export const GuideSecurityChapter1 = () => {
  return (
    <section>
      <ReportV2FullDarkSectionNavControlPoint />

      <ReportV2ChapterHeaderSection
        id="why-do-we-need-workflow-automation"
        color="purple"
        chapterNumber={1}
        icon={<DumbbellIcon />}
      >
        <h2>
          <span>Why do we need</span> <span>workflow automation?</span>
        </h2>
        <ReportV2TextBlock largerText>
          <Spacing size=".125em" />
          <ReportV2Blockquote>
            <ReportV2BlockquoteBody>
              <p>
                “A workflow approach that enables automated tasks (e.g., the
                automation of steps in a marketing campaign or a sales process)
                to be performed.”
              </p>
            </ReportV2BlockquoteBody>
            <p>
              – from Gartner’s{" "}
              <a
                href="https://www.gartner.com/en/information-technology/glossary/workflow-management"
                {...externalLinkAttr}
              >
                definition
              </a>{" "}
              of automated workflow management
            </p>
          </ReportV2Blockquote>
          <Spacing size="1.5em" />
          <p>
            Workflow automation makes it possible for everyone on the team,
            regardless of their technical skill, to build, run, and monitor
            their most important workflows. Traditionally, this meant the
            introduction of coding and scripting. But this is rapidly being
            replaced by no-code and natural language solutions like{" "}
            <a href="/" target="_blank">
              Tines
            </a>
            . These solutions offer a visual interface for building workflows.
          </p>

          <p>
            Dragging and dropping actions into a workflow, team members can turn
            processes built on repetitive, manual tasks into hands-off workflows
            that only loop them in when their judgment is required. By embracing
            workflow automation, teams can operate more effectively, mitigate
            risk, reduce tech debt, and focus on the work that matters most.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2NavPurpleControlPoint />

      <ReportV2Section id="evolution" backgroundColor={colors.lightest}>
        <ReportV2SectionHeader icon={<HatchingDinoEggIcon />}>
          <ReportV2SectionHeadingMedium>Evolution</ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />

        <ReportV2ContentCard backgroundColor={colors.purple25} largerText>
          <AllCaps as="p">Phase 1</AllCaps>
          <ReportV2Heading4>Automation as a feature</ReportV2Heading4>
          <p>
            Security automation started as a feature of larger software
            solutions like RSA Archer that could automate tasks like data
            collection and reporting in a single dashboard. It allowed for
            little customization for organization-specific needs, meaning that
            automation wasn't available across all workflows, but only for what
            the tool’s features allowed.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard backgroundColor={colors.purple50} largerText>
          <AllCaps as="p">Phase 2</AllCaps>
          <ReportV2Heading4>Emergence of SOAR tools</ReportV2Heading4>
          <p>
            As demands on security teams became more complex, so did the number
            of technologies and solutions needed. Increased tools came with
            increased alerts, placing further strain on the team’s time.
            First-generation SOAR tools addressed these needs, but building
            workflows capable of handling a variety of tools and use cases
            proved challenging and costly. While these platforms created a
            single place to manage and monitor workflows, they still relied
            heavily on a deep understanding of code to power them.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.purple100}
          color={colors.purple900}
          largerText
        >
          <AllCaps as="p">Phase 3</AllCaps>
          <ReportV2Heading4>Low-code and no-code automation</ReportV2Heading4>
          <p>
            Low-code and no-code automation reduced the need for coding skills,
            allowing non-developers to build automated workflows using simple
            drag-and-drop interfaces. Suddenly, automation was accessible to
            everyone on the team.
          </p>
          <p>
            There was just one problem. No-code automation platforms varied
            wildly in capability, from lightweight no-code platforms like
            Zapier, which are great for basic tasks, and by extension
            non-technical teams, to platforms like{" "}
            <a href="/" target="_blank">
              Tines
            </a>{" "}
            which can handle the massive complexity that security teams
            encounter. This led to what’s known as automation sprawl, when
            various teams implement their own automation solutions without
            proper coordination, resulting in siloed processes, integration
            challenges, and a lack of visibility.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={color("purple", 150)}
          color={colors.purple900}
          largerText
        >
          <AllCaps as="p">Phase 4</AllCaps>
          <ReportV2Heading4>Natural language workflows</ReportV2Heading4>
          <p>
            The evolution of AI and LLMs marks a natural progression in workflow
            automation accessibility. Moving from low code to no code to natural
            language allows everyone on the security team to start building
            quickly. The learning curve has flattened, and the user’s ability to
            build accelerated. The best workflow automation platforms allow
            users to employ a private and secure language model to help them
            build their workflows. They can also author data transformation with
            all the power of code, without the need to read or write it.
          </p>

          <p>
            With workflow automation, the person closest to the problem has
            everything they need to build the solution. Barriers to entry have
            never been lower and the potential impact across our security teams
            has never been greater. And it’s not just good news for the
            less-technical team members. Natural language is the ideal way to
            work together as a team because it allows everyone to literally
            speak the same language.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.purple800}
          color={colors.white}
          largerText
        >
          <AllCaps as="p">What’s next?</AllCaps>
          <ReportV2Heading4>Hyperautomation</ReportV2Heading4>
          <p>
            With more and more automation programs reaching an advanced stage,
            new security challenges emerge. When automation is siloed across
            different departments, it not only makes it harder to manage the
            organization’s security program, it can create new vulnerabilities
            as less-technical employees embrace the technology. This is where
            hyperautomation comes in.
          </p>

          <p>
            Described by Gartner as, “the orchestrated use of multiple
            technologies, tools or platforms, including artificial intelligence
            (AI) and machine learning…”, hyperautomation allows organizations to
            automate at the highest level of effectiveness. It provides security
            teams with critical visibility over automated processes across teams
            and departments, and the opportunity to build workflows that connect
            multiple departments. The result? Robust, integrated processes that
            optimize resources and make it easier to improve security across the
            organization.
          </p>
        </ReportV2ContentCard>
      </ReportV2Section>

      <ReportV2NavGreenControlPoint />

      <ReportV2Section
        id="benefits"
        backgroundColor={colors.green100}
        color={colors.green800}
        accentColor={colors.green}
      >
        <ReportV2SectionHeader icon={<GreenCheckIcon />}>
          <ReportV2SectionHeadingMedium>Benefits</ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.green50}
          color={colors.green800}
          largerText
        >
          <AllCaps as="p">Benefit 1</AllCaps>
          <ReportV2Heading4>Incident readiness</ReportV2Heading4>
          <p>
            When a security incident occurs, the demands on security teams are
            huge. A calm and well-functioning team beats an overworked one every
            time.
          </p>

          <p>
            But creating this kind of working environment is easier said than
            done. According to{" "}
            <a href="/reports/voice-of-the-soc-2023" target="_blank">
              the Voice of the SOC report
            </a>
            , 81% of security practitioners say their workload has never been
            higher.
          </p>

          <p>
            In damage-control moments, minutes and even seconds count
            enormously, and already having automated workflows in place frees
            your team to turn their full attention to responding to and
            resolving the incident. They can also collect information and
            context about an incident in seconds, and know when to alert a human
            for more critical decision-making.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.lightest}
          color={colors.green800}
          largerText
        >
          <AllCaps as="p">Benefit 2</AllCaps>
          <ReportV2Heading4>Faster time to value</ReportV2Heading4>
          <p>
            With workflow automation, processes that typically took days or
            weeks to complete can be reduced to minutes or even seconds, thus
            significantly increasing time to value. Workflow automation also
            reduces project management needs, communication burdens, unnecessary
            feedback loops, and other extra steps that can be condensed with
            automated workflows. With{" "}
            <a
              href="https://newsroom.bt.com/cybercrime-more-than-500-potential-attacks-clocked-every-second/"
              {...externalLinkAttr}
            >
              over 500 cyber attacks being logged every second globally
            </a>
            , increasing speed across the security team is crucial.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.green50}
          color={colors.green800}
          largerText
        >
          <AllCaps as="p">Benefit 3</AllCaps>
          <ReportV2Heading4>Significantly improved retention</ReportV2Heading4>
          <p>
            <a href="/reports/voice-of-the-soc-2023" target="_blank">
              The Voice of the SOC report
            </a>{" "}
            found that 63% of practitioners experience some level of burnout,
            and that spending time on manual tasks is the most frustrating
            aspect of their job.
          </p>

          <p>
            No one wants to do boring and menial work, and team members who burn
            out through mind-numbing toil simply leave their jobs. When
            low-level tasks are automated, security teams can focus on the
            high-impact work that drew them to the profession in the first
            place, whether that’s threat hunting, risk management, or incident
            response.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.lightest}
          color={colors.green800}
          largerText
        >
          <AllCaps as="p">Benefit 4</AllCaps>
          <ReportV2Heading4>Fewer mistakes</ReportV2Heading4>
          <p>
            Mundane work isn’t just bad for humans – humans are bad at it, too.
            Hours of menial, repetitive work increases the likelihood of error,
            which increases the chances of a security incident.{" "}
            <a
              href="https://www.gartner.com/en/newsroom/press-releases/2023-02-22-gartner-predicts-nearly-half-of-cybersecurity-leaders-will-change-jobs-by-2025"
              {...externalLinkAttr}
            >
              Gartner
            </a>{" "}
            predicted that lack of talent or human failure will cause more than
            50% of significant cyber incidents by 2025.
          </p>

          <p>
            Automated workflows function predictably and consistently, reducing
            false positives and false negatives. Workflow automation also
            reduces error because the practitioners who are most familiar with
            the processes are the ones actually building, running, and
            monitoring the workflows. The data backs this one up – in a study by{" "}
            <a
              href="https://proedge.pwc.com/resources/download-a-robot-for-every-worker-an-idc-white-paper"
              {...externalLinkAttr}
            >
              IDC
            </a>
            , 79% of respondents said that automation helped them reduce errors
            or mistakes.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.green50}
          color={colors.green800}
          largerText
        >
          <AllCaps as="p">Benefit 5</AllCaps>
          <ReportV2Heading4>A culture of secure automation</ReportV2Heading4>
          <p>
            When we remove the need to rely on other teams when building
            workflows, the potential for automation across the organization
            grows exponentially. For example, a security analyst builds a
            Slack-based chatbot for a threat intelligence workflow, which can
            then be used by their colleague in IT for their employee offboarding
            workflow.
          </p>

          <p>
            I’ve witnessed many teams with access to workflow automation have
            that light bulb moment and realize, ‘We could automate this!’, then
            immediately start building the new workflow, allowing for more
            innovation and quicker application.
          </p>
        </ReportV2ContentCard>
      </ReportV2Section>

      <ReportV2NavPinkControlPoint />

      <ReportV2Section
        id="common-misconceptions"
        backgroundColor={colors.lightest}
        color={colors.pink800}
        accentColor={colors.pink}
      >
        <ReportV2SectionHeader icon={<KaputtRobotIcon />}>
          <ReportV2SectionHeadingMedium>
            Common misconceptions
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.pink50}
          color={colors.pink800}
          largerText
        >
          <AllCaps as="p">Misconception 1</AllCaps>
          <ReportV2Heading4>
            <NegativeIndentLeftDiv>
              “I could just write a script for this”
            </NegativeIndentLeftDiv>
          </ReportV2Heading4>
          <p>
            You <em>could</em> just write a script – if you know how to. But
            security practitioners often don't have that skill, meaning they
            have to outsource their automation to others. Additionally, the easy
            part with code is writing it the first time. The hard part is the
            deployment, security upgrades, maintenance, versioning, and downtime
            that comes afterward. This is especially challenging when your best
            team members move on to other organizations (and at least some of
            them will, very soon – 55% of security practitioners say they’re
            likely to switch jobs in the next year.)
          </p>

          <p>
            The right workflow automation platform encourages collaboration and
            ensures that any number of team members can step in when required.
            Technical users who do know code can instead focus on the output of
            the overall workflow, rather than the process of coding it.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.pink100}
          color={colors.pink800}
          largerText
        >
          <AllCaps as="p">Misconception 2</AllCaps>
          <ReportV2Heading4>
            <NegativeIndentLeftDiv>
              “This isn’t powerful enough for our workflow.”
            </NegativeIndentLeftDiv>
          </ReportV2Heading4>
          <p>
            Workflow automation platforms provide security teams with the
            building blocks they need to power their most important workflows,
            from simple unrecognized login alerts to complex, all-encompassing
            vulnerability management.
          </p>
          <p>
            There's no limit to how complex the workflow can be or how many
            steps can be automated – if you can imagine it, you can do it.
          </p>
          <p>
            Ideally, your platform allows your workflows to scale automatically
            to meet your specific requirements. And it has a robust set of trust
            and security capabilities – role-based access control, audit logs,
            version history, error handling, credential management, and
            approval-based change control – to keep your workflows secure.{" "}
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.pink50}
          color={colors.pink800}
          largerText
        >
          <AllCaps as="p">Misconception 3</AllCaps>
          <ReportV2Heading4>
            <NegativeIndentLeftDiv>
              “Automation means replacing team members.”
            </NegativeIndentLeftDiv>
          </ReportV2Heading4>
          <p>From what I've seen, this very rarely happens in practice.</p>

          <p>
            Firstly, there’s always more work to do and bigger problems to
            solve. The security landscape is ever-changing and these teams need
            to constantly adjust and improve in order to keep pace.{" "}
          </p>

          <p>
            It’s far more common to see benefits for team members once they
            start building their own workflows. They gain a valuable new skill,
            the ability to create efficiencies across the security team and
            enhance key processes.
          </p>

          <p>
            As they begin to automate their repetitive, manual tasks, it frees
            them up to focus their skills and attention on high-impact work like
            improving the organization's security posture.
          </p>

          <p>
            Additionally, because of the ease of using workflow automation,
            builders can maintain and evolve their own workflows, which is
            especially beneficial as processes, tools, and threats continue to
            evolve.
          </p>

          <p>
            Automation simply unlocks the potential of team members – and team
            members who are engaged in and excited by their work stick around.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.pink100}
          color={colors.pink800}
          largerText
        >
          <AllCaps as="p">Misconception 4</AllCaps>
          <ReportV2Heading4>
            <NegativeIndentLeftDiv>
              “Automation will implement rash decisions during remediation.”
            </NegativeIndentLeftDiv>
          </ReportV2Heading4>
          <p>
            Automation isn't necessarily all or nothing, as many may assume. The
            best workflow automation platforms make it easy to put a human in
            the loop for important decisions. The same is true for any
            AI-powered capabilities within these platforms.
          </p>

          <p>
            Instead of automating black and white remediation actions like
            blocking an account after a suspicious login, these workflows ask
            the affected user or an analyst for their input first. This can
            easily be facilitated through automated Slack messages or chatbots –
            "Did you recently log in from a certain location?" – and
            implementing actions based on their response.
          </p>
        </ReportV2ContentCard>

        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.pink50}
          color={colors.pink800}
          largerText
        >
          <AllCaps as="p">Misconception 5</AllCaps>
          <ReportV2Heading4>
            <NegativeIndentLeftDiv>
              “Managing integrations is painful.”
            </NegativeIndentLeftDiv>
          </ReportV2Heading4>
          <p>
            We've reached a point where teams are turning away from
            multi-product platforms towards laser-focused tools that provide
            best-in-class solutions, like JIRA, Slack, and others.
          </p>

          <p>
            This means that connecting the tools in your stack has never been
            more important. When your organization’s tools – custom and
            off-the-shelf – talk to each other, you can maximize your data and
            resources.
          </p>

          <p>
            Best-in-class workflow automation platforms make it easy to
            integrate across your tech stack.
          </p>
        </ReportV2ContentCard>
      </ReportV2Section>

      <ReportV2NavOrangeControlPoint />

      <ReportV2Section
        id="case-study-elastic"
        backgroundColor={colors.orange100}
        color={colors.orange800}
        accentColor={colors.orange}
      >
        <ReportV2SectionHeader icon={<LogoElastic45h />} iconAlign="center">
          <ReportV2SectionHeadingMedium>
            <span>Case study</span>
            <Sans>Elastic</Sans>
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />
        <ReportV2TextBlock limitWidth largerText centerOnMobile>
          <p>
            Elastic is the leading platform for search-powered solutions. The
            InfoSec team at Elastic deals with a high influx of alerts, noise,
            and false positives, but, until recently, they carried out little to
            no automation. Let’s look at what happened when they started using
            workflow automation platform{" "}
            <a href="/" target="_blank">
              Tines
            </a>
            .
          </p>
        </ReportV2TextBlock>

        <Spacing size="lg" />

        <ReportV2BeforeAfterSplit>
          <ReportV2ContentCard
            backgroundColor={colors.orange800}
            color={colors.white}
            largerText
          >
            <div>
              <AllCaps as="h5">Before Tines</AllCaps>
              <ul>
                <li>
                  Little to no automation on InfoSec team, automation was done
                  through time-consuming Python scripts
                </li>
                <li>Unscalable systems for alert management</li>
              </ul>
            </div>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.orange}
            color={colors.white}
            largerText
          >
            <div>
              <AllCaps as="h5">After Tines</AllCaps>
              <ul>
                <li>
                  49 automated workflows and counting, for threat detection,
                  alert investigation, and more
                </li>
                <li>Scalable processes</li>
                <li>Reduced alert fatigue </li>
                <li>
                  750 days of analyst time recovered annually for more impactful
                  work
                </li>
              </ul>
            </div>
          </ReportV2ContentCard>
        </ReportV2BeforeAfterSplit>

        <Spacing size="xl" />

        <ReportV2TextBlock limitWidth largerText centerOnMobile>
          <AllCaps as="p">Key workflow</AllCaps>
          <ReportV2Heading4>Alert investigation and triage</ReportV2Heading4>
          <p>
            The Elastic team uses this workflow to create, enrich, and
            distribute Elastic SIEM alerts to their analysts via Slack. One week
            after kicking off this workflow, the team found that they had
            processed the same amount of work that would have taken them 93 days
            previously.
          </p>
          <p>
            <strong>In their own words:</strong>
          </p>
          <ReportV2Blockquote className="orange">
            <ReportV2BlockquoteBody>
              <NegativeIndentLeftP>
                “Tines is doing the work of at least 3 FTEs and, most
                importantly, is allowing us to be more thorough in the work that
                we do.”
              </NegativeIndentLeftP>
            </ReportV2BlockquoteBody>
            <footer>
              <p>
                – <strong>Daniel Gallagher</strong>, Security Automation
                Engineer
              </p>
            </footer>
          </ReportV2Blockquote>
        </ReportV2TextBlock>

        <Spacing size="lg" />

        <AlignCenterOnMobile>
          <Button
            href="/case-studies/elastic"
            color="orange"
            darker
            appearance="outlined"
            {...externalLinkAttr}
          >
            Read the full case study <sup>↗</sup>
          </Button>
        </AlignCenterOnMobile>
      </ReportV2Section>
    </section>
  );
};
