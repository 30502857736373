export const CloudTopLarge = () => (
  <svg width="648" height="427" viewBox="0 0 648 427">
    <path
      d="M371.5 186C371.5 207.805 367.738 228.731 360.828 248.164C360.419 249.313 360.889 250.418 361.678 251.078C362.465 251.737 363.639 252.01 364.707 251.439C375.372 245.735 387.558 242.5 400.5 242.5C424.672 242.5 446.209 253.784 460.129 271.375C460.814 272.239 461.976 272.572 463.014 272.197C471.439 269.157 480.525 267.5 490 267.5C490.163 267.5 490.325 267.5 490.487 267.501C491.584 267.508 492.564 266.814 492.925 265.78C503.76 234.756 533.281 212.5 568 212.5C611.907 212.5 647.5 248.093 647.5 292C647.5 335.907 611.907 371.5 568 371.5C567.837 371.5 567.675 371.5 567.513 371.499C566.416 371.492 565.436 372.186 565.075 373.22C554.24 404.244 524.719 426.5 490 426.5C462.987 426.5 439.12 413.028 424.753 392.433C424.122 391.529 422.982 391.128 421.924 391.439C415.13 393.431 407.94 394.5 400.5 394.5C392.846 394.5 385.456 393.369 378.49 391.264C377.487 390.961 376.403 391.3 375.742 392.106C367.03 402.726 353.806 409.5 339 409.5C320.836 409.5 305.052 399.305 297.061 384.322C296.517 383.302 295.361 382.755 294.226 382.998C289.64 383.982 284.881 384.5 280 384.5C262.247 384.5 246.095 377.647 234.045 366.441C233.414 365.854 232.527 365.619 231.686 365.832C217.072 369.533 201.766 371.5 186 371.5C83.5512 371.5 0.5 288.449 0.5 186C0.5 83.5512 83.5512 0.5 186 0.5C288.449 0.5 371.5 83.5512 371.5 186Z"
      fill="#EADFF8"
      stroke="#C2AAFA"
    />
  </svg>
);

export const CloudTopSmallRight = () => (
  <svg width="82" height="32" viewBox="0 0 82 32">
    <path
      d="M26.3841 27.5075C23.6355 29.9894 19.9948 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C19.9948 0.5 23.6355 2.01056 26.3841 4.49246C27.5308 5.52786 29.3512 5.51258 30.4828 4.46282C33.1405 1.99711 36.6516 0.5 40.5 0.5C44.5331 0.5 48.196 2.14431 50.8942 4.82476C52.0598 5.9826 54.045 5.99866 55.227 4.85582C58.0164 2.15872 61.814 0.5 66 0.5C74.5604 0.5 81.5 7.43959 81.5 16C81.5 24.5604 74.5604 31.5 66 31.5C61.814 31.5 58.0164 29.8413 55.227 27.1442C54.045 26.0013 52.0598 26.0174 50.8942 27.1752C48.196 29.8557 44.5331 31.5 40.5 31.5C36.6516 31.5 33.1405 30.0029 30.4828 27.5372C29.3512 26.4874 27.5308 26.4721 26.3841 27.5075Z"
      fill="#EADFF8"
      stroke="#C2AAFA"
    />
  </svg>
);

export const CloudTopSmallLeft = () => (
  <svg width="82" height="32" viewBox="0 0 82 32">
    <path
      d="M26.3841 27.5075C23.6355 29.9894 19.9948 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C19.9948 0.5 23.6355 2.01056 26.3841 4.49246C27.5308 5.52786 29.3512 5.51258 30.4828 4.46282C33.1405 1.99711 36.6516 0.5 40.5 0.5C44.5331 0.5 48.196 2.14431 50.8942 4.82476C52.0598 5.9826 54.045 5.99866 55.227 4.85582C58.0164 2.15872 61.814 0.5 66 0.5C74.5604 0.5 81.5 7.43959 81.5 16C81.5 24.5604 74.5604 31.5 66 31.5C61.814 31.5 58.0164 29.8413 55.227 27.1442C54.045 26.0013 52.0598 26.0174 50.8942 27.1752C48.196 29.8557 44.5331 31.5 40.5 31.5C36.6516 31.5 33.1405 30.0029 30.4828 27.5372C29.3512 26.4874 27.5308 26.4721 26.3841 27.5075Z"
      fill="#EADFF8"
      stroke="#C2AAFA"
    />
  </svg>
);
