export const UFOIcon = () => (
  <svg
    width="124"
    height="92"
    viewBox="0 0 124 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M122.386 31.7859C122.386 28.1509 109.697 20.9215 91.6194 15.0252C84.4324 12.6813 80.5385 11.6242 75.2429 5.3365C71.579 0.98665 67.5325 1.43709 61.9314 1.43709C61.9314 1.43709 61.9314 1.43709 61.9311 1.43709C56.3301 1.43709 52.2833 0.98665 48.6201 5.3365C43.3242 11.6242 39.4298 12.6813 32.2431 15.0252C14.1655 20.9215 1.47632 28.1509 1.47632 31.7859C1.47632 37.1558 28.5429 41.5095 61.9311 41.5095C61.9314 41.5095 61.9314 41.5095 61.9314 41.5095C95.3194 41.5095 122.386 37.1558 122.386 31.7859Z"
      fill="#D2CCFF"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.9309 41.5096C95.3192 41.5096 122.386 37.1563 122.386 31.7861C122.386 26.4159 95.3192 22.0625 61.9309 22.0625C28.5426 22.0625 1.47607 26.4159 1.47607 31.7861C1.47607 37.1563 28.5426 41.5096 61.9309 41.5096Z"
      fill="#8173E6"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.931 41.3388C94.127 41.3388 120.227 38.0284 120.227 33.9448C120.227 29.8612 94.127 26.5508 61.931 26.5508C29.735 26.5508 3.63501 29.8612 3.63501 33.9448C3.63501 38.0284 29.735 41.3388 61.931 41.3388Z"
      fill="#6359B3"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M65.1698 41.8288C88.0579 41.8288 106.612 39.7165 106.612 37.1107C106.612 34.505 88.0579 32.3926 65.1698 32.3926C42.2817 32.3926 23.7272 34.505 23.7272 37.1107C23.7272 39.7165 42.2817 41.8288 65.1698 41.8288Z"
      fill="black"
    />
    <path
      d="M100.204 37.6733C100.204 34.5596 83.0683 32.0352 61.9311 32.0352C40.7936 32.0352 23.6581 34.5596 23.6581 37.6733C23.6581 38.7998 28.0626 38.5464 29.5152 41.2156C30.552 43.1208 44.0283 45.1422 61.9311 45.1422C79.8335 45.1422 92.9375 43.5304 94.3469 41.2156C96.0657 38.3917 100.204 38.7998 100.204 37.6733Z"
      fill="#8173E6"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.9313 26.1863C63.3602 26.1863 64.5185 25.0279 64.5185 23.599C64.5185 22.1701 63.3602 21.0117 61.9313 21.0117C60.5024 21.0117 59.344 22.1701 59.344 23.599C59.344 25.0279 60.5024 26.1863 61.9313 26.1863Z"
      fill="#FFD18C"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.1528 26.7839C42.5817 26.7839 43.7401 25.6256 43.7401 24.1966C43.7401 22.7677 42.5817 21.6094 41.1528 21.6094C39.7239 21.6094 38.5656 22.7677 38.5656 24.1966C38.5656 25.6256 39.7239 26.7839 41.1528 26.7839Z"
      fill="#FFD18C"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.2832 28.4284C22.7121 28.4284 23.8705 27.2701 23.8705 25.8412C23.8705 24.4123 22.7121 23.2539 21.2832 23.2539C19.8543 23.2539 18.6959 24.4123 18.6959 25.8412C18.6959 27.2701 19.8543 28.4284 21.2832 28.4284Z"
      fill="#FFD18C"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.96788 33.0866C5.39679 33.0866 6.55515 31.9283 6.55515 30.4994C6.55515 29.0705 5.39679 27.9121 3.96788 27.9121C2.53898 27.9121 1.38062 29.0705 1.38062 30.4994C1.38062 31.9283 2.53898 33.0866 3.96788 33.0866Z"
      fill="#FFD18C"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M82.8629 26.7839C84.2918 26.7839 85.4502 25.6256 85.4502 24.1966C85.4502 22.7677 84.2918 21.6094 82.8629 21.6094C81.434 21.6094 80.2756 22.7677 80.2756 24.1966C80.2756 25.6256 81.434 26.7839 82.8629 26.7839Z"
      fill="#FFD18C"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M102.732 28.4284C104.161 28.4284 105.32 27.2701 105.32 25.8412C105.32 24.4123 104.161 23.2539 102.732 23.2539C101.303 23.2539 100.145 24.4123 100.145 25.8412C100.145 27.2701 101.303 28.4284 102.732 28.4284Z"
      fill="#FFD18C"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M120.048 33.0866C121.477 33.0866 122.635 31.9283 122.635 30.4994C122.635 29.0705 121.477 27.9121 120.048 27.9121C118.619 27.9121 117.46 29.0705 117.46 30.4994C117.46 31.9283 118.619 33.0866 120.048 33.0866Z"
      fill="#FFD18C"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.931 45.1423C79.8337 45.1423 94.3467 43.3843 94.3467 41.2157C94.3467 39.0471 79.8337 37.2891 61.931 37.2891C44.0283 37.2891 29.5153 39.0471 29.5153 41.2157C29.5153 43.3843 44.0283 45.1423 61.931 45.1423Z"
      fill="#6359B3"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.5615 18.8199C32.0711 17.1813 46.2849 16.1755 61.9308 16.1758C77.314 16.1755 91.3126 17.1476 101.768 18.7377"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.957 85.7812L81.8734 41.7742C81.8734 41.1146 72.9451 40.5801 61.9309 40.5801C50.9172 40.5801 41.9884 41.1146 41.9884 41.7742L22.9046 85.7812C22.7584 85.95 22.6818 86.1213 22.6818 86.2947C22.6818 88.9524 40.2541 91.1067 61.9307 91.1067C83.6076 91.1067 101.18 88.9521 101.18 86.2947C101.18 86.1213 101.103 85.95 100.957 85.7812Z"
      fill="#B8E5D2"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M61.9309 91.1064C83.6075 91.1064 101.18 88.952 101.18 86.2944C101.18 83.6368 83.6075 81.4824 61.9309 81.4824C40.2543 81.4824 22.682 83.6368 22.682 86.2944C22.682 88.952 40.2543 91.1064 61.9309 91.1064Z"
      fill="#81D8B4"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.3198 11.5505C46.8099 9.66178 54.0264 8.51562 61.931 8.51562C69.6935 8.51562 76.7923 9.62094 82.2442 11.4487"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
