import { styled } from "@linaria/react";
import { LearningPathWithProgress } from "../../../utils/university.utils";

type Props = {
  learningPathWithProgress: LearningPathWithProgress;
};

const ProgressMap = styled.div`
  display: block;
  > span {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 3px;
    background-color: var(--ac25);
    margin-right: 3px;
    &.completed {
      background-color: var(--ac);
    }
  }
`;

const UniLearningPathProgressMap = (props: Props) => {
  return (
    <ProgressMap>
      {props.learningPathWithProgress.tree.children.map(lesson => (
        <span
          className={lesson.completionTime ? "completed" : ""}
          key={lesson.id}
        />
      ))}
    </ProgressMap>
  );
};

export default UniLearningPathProgressMap;
