type Props = {
  filled?: boolean;
};

const ThumbsUpIcon = (props: Props) => {
  return (
    <svg
      data-name="thumbs-up"
      data-filled={props.filled}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      {props.filled ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9795 4.84133C11.7582 5.23064 12.25 6.02646 12.25 6.897V9.75H18C18.6904 9.75 19.25 10.3096 19.25 11C19.25 11.6904 18.6904 12.25 18 12.25H16.6714C16.3786 12.25 16.3244 12.6668 16.6074 12.7417L17.8846 13.0797L17.2658 17.5267C17.1175 18.593 16.1518 19.3505 15.0809 19.2407L8.14797 18.5296C8.09117 18.5238 8.03589 18.5133 7.98249 18.4985C7.86183 19.3473 7.13214 20 6.25 20H5.75C4.7835 20 4 19.2165 4 18.25V9.75C4 8.7835 4.7835 8 5.75 8H6.25C7.17184 8 7.92721 8.71277 7.99504 9.61727C9.38552 9.07775 9.71411 7.0108 9.77107 5.59893C9.79526 4.9993 10.4428 4.57295 10.9795 4.84133ZM5.5 9.75C5.5 9.61193 5.61193 9.5 5.75 9.5H6.25C6.38807 9.5 6.5 9.61193 6.5 9.75V18.25C6.5 18.3881 6.38807 18.5 6.25 18.5H5.75C5.61193 18.5 5.5 18.3881 5.5 18.25V9.75Z"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3149 4.1705C10.2352 3.63062 9.06523 4.48918 9.02168 5.5687C8.98986 6.35736 8.87029 7.28161 8.55917 7.98425C8.35595 8.44321 8.10578 8.74646 7.77691 8.89435C7.47718 8.36062 6.9057 8 6.25 8H5.75C4.7835 8 4 8.7835 4 9.75V18.25C4 19.2165 4.7835 20 5.75 20H6.25C6.86526 20 7.40636 19.6825 7.71839 19.2024C7.83129 19.2383 7.94937 19.2632 8.07145 19.2757L15.0044 19.9868C16.4769 20.1378 17.8047 19.0962 18.0087 17.6301L18.6688 12.8854C19.4445 12.6103 20 11.87 20 11C20 9.89543 19.1046 9 18 9H13V6.89699C13 5.74238 12.3477 4.68686 11.3149 4.1705ZM6.5 9.75C6.5 9.61193 6.38807 9.5 6.25 9.5H5.75C5.61193 9.5 5.5 9.61193 5.5 9.75V18.25C5.5 18.3881 5.61193 18.5 5.75 18.5H6.25C6.38807 18.5 6.5 18.3881 6.5 18.25V17.5361C6.5 17.5357 6.5 17.5353 6.5 17.5348L6.5 9.75ZM8 17.5353C8.00025 17.6633 8.09713 17.7705 8.22449 17.7835L15.1574 18.4946C15.8267 18.5632 16.4303 18.0898 16.523 17.4234L17.1384 13H16.75C16.3358 13 16 12.6642 16 12.25C16 11.8358 16.3358 11.5 16.75 11.5H18C18.2761 11.5 18.5 11.2761 18.5 11C18.5 10.7239 18.2761 10.5 18 10.5H12.25C11.8358 10.5 11.5 10.1642 11.5 9.75V6.89699C11.5 6.31054 11.1687 5.77442 10.6441 5.51214C10.6384 5.50931 10.6361 5.50905 10.6311 5.50946C10.6234 5.51012 10.6071 5.51377 10.5864 5.52692C10.5406 5.55619 10.5217 5.59821 10.5205 5.62916C10.4863 6.47634 10.3575 7.62761 9.93074 8.59155C9.5766 9.39136 8.96918 10.1457 8 10.405L8 17.5353Z"
        />
      )}
    </svg>
  );
};

export default ThumbsUpIcon;
