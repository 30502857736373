export const DumbbellIcon = () => (
  <svg
    width="112"
    height="99"
    viewBox="0 0 112 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.371 62.19C108.597 62.1809 111.201 57.9432 111.186 52.7248C111.171 47.5064 108.544 43.2833 105.318 43.2924C102.092 43.3014 99.4891 47.5391 99.5038 52.7575C99.5184 57.9759 102.145 62.199 105.371 62.19Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M99.7958 66.7161C104.568 66.7023 108.419 60.4328 108.396 52.7127C108.374 44.9927 104.487 38.7456 99.7147 38.7595C94.9423 38.7733 91.0916 45.0428 91.114 52.7629C91.1364 60.4829 95.0234 66.73 99.7958 66.7161Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M92.792 72.5416C99.5526 72.5416 105.033 63.6759 105.033 52.7395C105.033 41.803 99.5526 32.9373 92.792 32.9373C86.0314 32.9373 80.5508 41.803 80.5508 52.7395C80.5508 63.6759 86.0314 72.5416 92.792 72.5416Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M84.6261 27.1143C83.6848 27.1143 80.0486 27.1143 79.1073 27.1143C70.3586 27.1143 63.2664 38.587 63.2664 52.7394C63.2664 66.8922 70.3586 78.3647 79.1073 78.3647C80.0486 78.3647 83.6848 78.3647 84.6261 78.3647C93.3748 78.3647 100.467 66.8919 100.467 52.7394C100.467 38.5868 93.3748 27.1143 84.6261 27.1143Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M79.1073 78.3646C87.856 78.3646 94.9483 66.8918 94.9483 52.7393C94.9483 38.5869 87.856 27.1141 79.1073 27.1141C70.3586 27.1141 63.2664 38.5869 63.2664 52.7393C63.2664 66.8918 70.3586 78.3646 79.1073 78.3646Z"
      fill="#7F69CE"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M76.6041 58.767C77.9611 58.767 79.0612 56.7493 79.0612 54.2603C79.0612 51.7713 77.9611 49.7535 76.6041 49.7535C75.2471 49.7535 74.147 51.7713 74.147 54.2603C74.147 56.7493 75.2471 58.767 76.6041 58.767Z"
      fill="#7F69CE"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M76.0436 57.3C75.8638 57.3002 75.6819 57.2846 75.4985 57.2525C75.4184 57.2383 67.1852 55.8204 55.3997 55.8204C43.501 55.8204 32.3962 57.2587 32.2853 57.2733C30.5765 57.4973 29.0103 56.2937 28.7861 54.5856C28.562 52.877 29.7656 51.3105 31.4739 51.0864C31.9429 51.0247 43.0973 49.5806 55.3995 49.5806C67.7698 49.5806 76.2267 51.0449 76.5807 51.107C78.2775 51.4061 78.7692 53.1456 78.4704 54.8426C78.2043 56.3563 77.5292 57.3 76.0436 57.3Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.9506 27.1143C34.0093 27.1143 30.3731 27.1143 29.4318 27.1143C20.6831 27.1143 13.5906 38.587 13.5906 52.7394C13.5906 66.8922 20.6831 78.3647 29.4318 78.3647C30.3731 78.3647 34.0093 78.3647 34.9506 78.3647C43.6993 78.3647 50.7918 66.8919 50.7918 52.7394C50.7918 38.5868 43.6993 27.1143 34.9506 27.1143Z"
      fill="#7F69CE"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.4315 78.3646C38.1802 78.3646 45.2725 66.8918 45.2725 52.7393C45.2725 38.5869 38.1802 27.1141 29.4315 27.1141C20.6828 27.1141 13.5906 38.5869 13.5906 52.7393C13.5906 66.8918 20.6828 78.3646 29.4315 78.3646Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M25.7302 76.291C32.4908 76.291 37.9714 67.4252 37.9714 56.4888C37.9714 45.5524 32.4908 36.6866 25.7302 36.6866C18.9696 36.6866 13.489 45.5524 13.489 56.4888C13.489 67.4252 18.9696 76.291 25.7302 76.291Z"
      fill="#32313B"
    />
    <path
      d="M26.6331 32.9376C25.7547 32.9376 22.3906 32.9376 21.5124 32.9376C14.7516 32.9376 9.271 41.8032 9.271 52.7395C9.271 63.6759 14.7516 72.5417 21.5124 72.5417C22.3906 72.5417 25.7547 72.5417 26.6331 72.5417C33.3936 72.5417 38.8743 63.6761 38.8743 52.7395C38.8743 41.8032 33.3936 32.9376 26.6331 32.9376Z"
      fill="#7F69CE"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M21.5122 72.5417C28.2728 72.5417 33.7534 63.676 33.7534 52.7395C33.7534 41.8031 28.2728 32.9374 21.5122 32.9374C14.7516 32.9374 9.271 41.8031 9.271 52.7395C9.271 63.676 14.7516 72.5417 21.5122 72.5417Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.8326 69.9983C22.605 69.9983 26.4738 63.7398 26.4738 56.0197C26.4738 48.2996 22.605 42.0411 17.8326 42.0411C13.0602 42.0411 9.19141 48.2996 9.19141 56.0197C9.19141 63.7398 13.0602 69.9983 17.8326 69.9983Z"
      fill="#32313B"
    />
    <path
      d="M18.6326 38.7611C17.8867 38.7611 15.0583 38.7611 14.3126 38.7611C9.53995 38.7611 5.67114 45.0196 5.67114 52.7394C5.67114 60.4597 9.53995 66.718 14.3126 66.718C15.0583 66.718 17.8867 66.718 18.6326 66.718C23.405 66.718 27.2735 60.4597 27.2735 52.7394C27.2735 45.0196 23.405 38.7611 18.6326 38.7611Z"
      fill="#7F69CE"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M14.3123 66.718C19.0847 66.718 22.9535 60.4596 22.9535 52.7394C22.9535 45.0193 19.0847 38.7609 14.3123 38.7609C9.53994 38.7609 5.67114 45.0193 5.67114 52.7394C5.67114 60.4596 9.53994 66.718 14.3123 66.718Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.6333 65.0685C14.8593 65.0685 17.4745 60.838 17.4745 55.6194C17.4745 50.4009 14.8593 46.1704 11.6333 46.1704C8.40738 46.1704 5.79224 50.4009 5.79224 55.6194C5.79224 60.838 8.40738 65.0685 11.6333 65.0685Z"
      fill="#32313B"
    />
    <path
      d="M11.7541 43.2906C11.1694 43.2906 8.97776 43.2906 8.3936 43.2906C5.16752 43.2906 2.55225 47.5211 2.55225 52.7394C2.55225 57.958 5.16728 62.1885 8.3936 62.1885C8.97776 62.1885 11.1694 62.1885 11.7541 62.1885C14.9799 62.1885 17.5952 57.958 17.5952 52.7394C17.5952 47.5211 14.9799 43.2906 11.7541 43.2906Z"
      fill="#7F69CE"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M8.39335 62.1885C11.6193 62.1885 14.2345 57.958 14.2345 52.7394C14.2345 47.5209 11.6193 43.2904 8.39335 43.2904C5.16739 43.2904 2.55225 47.5209 2.55225 52.7394C2.55225 57.958 5.16739 62.1885 8.39335 62.1885Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.16416 57.1671C8.52119 57.1671 9.62127 55.1493 9.62127 52.6602C9.62127 50.171 8.52119 48.1532 7.16416 48.1532C5.80713 48.1532 4.70703 50.171 4.70703 52.6602C4.70703 55.1493 5.80713 57.1671 7.16416 57.1671Z"
      fill="#7F69CE"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M9.08005 52.7395C9.08005 54.5951 8.29573 56.0995 6.44005 56.0995H4.16005C2.30437 56.0995 0.800049 54.5951 0.800049 52.7395C0.800049 50.884 2.30437 49.3795 4.16005 49.3795H6.44005C8.29597 49.3795 9.08005 50.884 9.08005 52.7395Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M75 57.2603L78.36 56.8602C78.36 56.8602 77.8075 58.834 76.6039 58.767C75.4003 58.7001 75 57.2603 75 57.2603Z"
      fill="#32313B"
    />
    <path
      d="M64.1199 60.3004C64.1199 60.3004 71.7999 58.6204 75.9598 58.3804L75.7997 57.1804L63.6399 56.3003L64.1199 60.3004Z"
      fill="#32313B"
    />
    <path
      d="M65.0353 4.9552L58.1835 1L51.3325 4.9552L49.1218 6.79744V14.7081L55.9729 18.6633L62.8237 14.7081L65.0353 12.8658V4.9552Z"
      fill="#E269A4"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.332 4.9552L58.1831 1L65.0348 4.9552V12.8658L58.1831 16.821L51.332 12.8658V4.9552Z"
      fill="#FDA2CB"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.1218 14.7081L51.3325 12.8659"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M55.9727 18.6634L57.9339 16.6774"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.1833 13.6218C58.9123 13.6218 59.5033 13.0308 59.5033 12.3018C59.5033 11.5728 58.9123 10.9818 58.1833 10.9818C57.4543 10.9818 56.8633 11.5728 56.8633 12.3018C56.8633 13.0308 57.4543 13.6218 58.1833 13.6218Z"
      fill="#A54B7A"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.9907 18.5001V49.2201"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.8354 12.2706C58.6305 12.2706 59.3587 12.9285 58.9207 15.184C58.4399 17.6598 57.6354 18.507 56.8398 18.507"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.1335 48.9801C57.5063 48.9801 57.8084 50.6522 57.8084 52.7152C57.8084 54.7778 57.506 56.4501 57.1335 56.4501"
      stroke="#32313B"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.918 98.8513C76.2112 98.8513 91.0407 97.7258 91.0407 96.3373C91.0407 94.9489 76.2112 93.8233 57.918 93.8233C39.6249 93.8233 24.7954 94.9489 24.7954 96.3373C24.7954 97.7258 39.6249 98.8513 57.918 98.8513Z"
      fill="#32313B"
    />
  </svg>
);
