import { styled } from "@linaria/react";
import HeroSection from "../../../components/sections/HeroSection";
import SEO from "../../../components/utilities/SEO";
import { colors } from "../../../styles/colors.styles";
import Page from "../../../templates/Page.template";
import { PageComponentProps } from "../../../types/gatsby.types";
import { GuideToWorkflowAutomationSecurity2024CoverDesign } from "../../../components/reportsV2/guides/SecurityEditionCoverDesign";
import ReportV2PageContent from "../../../components/reportsV2/components/ReportV2PageContent";
import { ReportV2PageLayout } from "../../../components/reportsV2/components/ReportV2PageLayout";
import { GuideSecurityNav } from "../../../components/reportsV2/guides/GuideSecurityNav";
import { GuideSecurityIntroSection } from "../../../components/reportsV2/guides/GuideSecurityIntroSection";
import { ExplosionCTASection } from "../../../components/reusableSections/ExplosionCTASection";
import Spacing from "../../../components/layout/Spacing";
import { ReportV2NavPurpleControlPoint } from "../../../components/reportsV2/components/ReportV2CommonNavControlPoints";
import { PageThemeControllerPoint } from "../../../components/site/PageThemeController";
import { GuideSecurityChapter1 } from "../../../components/reportsV2/guides/GuideSecurityChapter1";
import { GuideSecurityChapter2 } from "../../../components/reportsV2/guides/GuideSecurityChapter2";
import Button from "../../../components/forms/Button";
import { css } from "linaria";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { GuideSecurityConclusionSection } from "../../../components/reportsV2/guides/GuideSecurityConclusionSection";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";
import { GuideSecurityAICalloutSection } from "../../../components/reportsV2/guides/GuideSecurityAICalloutSection";
import ogImage from "../../../../static/images/og/tines-whitepaper-essential-guide-to-workflow-automation-for-security-teams.png";
import { Paths } from "../../../utils/pathBuilders.utils";
import {
  DocumentIDMap,
  recordDocumentEvent,
} from "../../../utils/documents.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";

const HiddenTitleGroup = styled.div`
  display: none;
`;

const CoverDesignWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const CoverDesignWrapInner = styled.div`
  position: relative;
  svg {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  &:after {
    ${fromTablet} {
      content: "";
      top: 100%;
      position: absolute;
      left: calc(50% - 1px);
      width: 2px;
      background-image: linear-gradient(
        to bottom,
        ${colors.purple},
        ${colors.purple50}
      );
      height: ${rSize("sectionMargin")};
    }
  }
`;

const PageWhitepaperGuideToWorkflowAutomationSecurity = (
  props: PageComponentProps
) => {
  useOnMount(() => {
    recordDocumentEvent({
      type: "view",
      id: DocumentIDMap.essentialGuideSecurity2023,
    });
  });
  const handleDownloadButtonClick = () => {
    recordDocumentEvent({
      type: "download",
      id: DocumentIDMap.essentialGuideSecurity2023,
    });
  };

  return (
    <Page
      {...props}
      textColor={colors.purple800}
      navThemeColor="purple"
      navLogoColor={colors.dark500}
      mobileNavBgColor={colors.purple}
    >
      <SEO
        title="The essential guide to workflow automation for security teams"
        description="What are the biggest benefits of workflow automation for security teams? Find out in our essential guide, which also includes case studies and best practices."
        image={ogImage}
      />

      <ReportV2NavPurpleControlPoint />
      <PageThemeControllerPoint backgroundColor={colors.lightest} />

      <ReportV2PageContent>
        <HeroSection backdrop="dots" contentRelative>
          <HiddenTitleGroup>
            <h1>The essential guide to workflow automation</h1>
            <p>for security teams</p>
          </HiddenTitleGroup>
          <CoverDesignWrap>
            <CoverDesignWrapInner>
              <GuideToWorkflowAutomationSecurity2024CoverDesign />
              <Button
                appearance="outlined"
                href={Paths.guideSecurity.pdf()}
                download
                onClick={handleDownloadButtonClick}
                className={css`
                  ${fromTablet} {
                    position: absolute;
                    top: 92%;
                    left: 56%;
                  }
                `}
              >
                Download PDF
              </Button>
            </CoverDesignWrapInner>
          </CoverDesignWrap>
        </HeroSection>
        <ReportV2PageLayout nav={<GuideSecurityNav />}>
          <GuideSecurityIntroSection />
          <GuideSecurityAICalloutSection />
          <GuideSecurityChapter1 />
          <GuideSecurityChapter2 />
          <GuideSecurityConclusionSection />
        </ReportV2PageLayout>
      </ReportV2PageContent>

      <ReportV2NavPurpleControlPoint />
      <PageThemeControllerPoint backgroundColor={colors.lightest} />

      <Spacing size="sectionMargin" />

      <ExplosionCTASection heading="Workflow automation for security teams" />
    </Page>
  );
};

export default PageWhitepaperGuideToWorkflowAutomationSecurity;
