import { DatoCmsLatestStoriesSection } from "../../../../graphql-types";
import LibraryCollectionPageSectionHeader from "./LibraryCollectionPageSectionHeader";
import { StoryDescriptor } from "../../../types/helper.types";
import { StoryGrid } from "../StoryGrid";
import dayjs from "dayjs";
import { LatestStoriesIcon } from "../../illustrations/spots/LatestStoriesIcon";

export const LatestStoriesSection = ({
  section,
  stories,
}: {
  section: DatoCmsLatestStoriesSection;
  stories: StoryDescriptor[];
}) => {
  const storiesToShow = stories
    .sort((a, b) => dayjs(b.timeFirstPublished).diff(a.timeFirstPublished))
    .slice(0, parseInt(`${section.maxNumberOfStories ?? 9}`));
  return (
    <div id="latest">
      <LibraryCollectionPageSectionHeader
        section={{
          pill: "Freshly baked",
          heading: "Latest stories",
          color: "pink",
          linkUrl: `?view=all&sort=latest`,
          linkTextLabel: "See more",
        }}
        imageOverride={<LatestStoriesIcon />}
      />
      <StoryGrid stories={storiesToShow} />
    </div>
  );
};
