export const ClapperboardTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      d="M5 9H16V14C16 15.6569 14.6569 17 13 17H7C5.89543 17 5 16.1046 5 15V9Z"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0735 0.962347L2.48235 4.06818L2.99999 6.00003L2.99999 8L15 8L15 6L3.0001 6L14.5911 2.8942L14.0735 0.962347Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1416 1.47998L14.0735 0.962341L14.5911 2.89419L12.1416 1.47998ZM10.7274 3.92947L8.27792 2.51526L10.2098 1.99762L12.6593 3.41183L10.7274 3.92947ZM4.41421 3.55053L6.8637 4.96475L8.79555 4.44711L6.34606 3.03289L4.41421 3.55053ZM2.48236 4.06817L4.93185 5.48238L3.00002 6.00001L5 7.99999H4V8.99999V14C4 14.5523 4.44772 15 5 15H13C13.5523 15 14 14.5523 14 14V8.99999V7.99999H15V8.99999V14C15 15.1046 14.1046 16 13 16H5C3.89543 16 3 15.1046 3 14V8.99999V7.99999L3 6.00002L2.48236 4.06817ZM15 7.99999L13 5.99999H15V7.99999ZM13 7.99999L11 5.99999H9L11 7.99999H13ZM9 7.99999L7 5.99999H5L7 7.99999H9ZM7.5 9.5L11.5 11.5L7.5 13.5V9.5Z"
    />
  </svg>
);
