import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import TinesDublinOfficeImage from "../../images/tines-office-dublin-huckletree-interior.jpg";
import {
  fromDesktop,
  fromTabletMd,
  uptoDesktop,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { openIntercomMessenger } from "../../utils/intercom.utils";
import InfoBox from "../general/InfoBox";
import PseudoLink from "../basic/PseudoLink";
import StaticImageFrame from "../basic/StaticImageFrame";
import PageSection from "./PageSection";

const ContactSectionInner = styled.div`
  display: grid;
  grid-gap: ${rSize("cardSectionPadding")} ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: 5fr 7fr;
  }
  ${fromDesktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Content = styled.div`
  background-color: ${colors.purple50};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  ${fromDesktop} {
    padding: 2.5em;
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
  table {
    border-collapse: collapse;
  }
  h3 {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: 700;
    margin-top: 1.5em;
    + * {
      margin-top: 0.5em;
    }
    svg {
      flex: 0 0 auto;
      margin-right: 0.5em;
    }
  }
  a {
    ${inParagraphLinkStyle()};
  }
  th {
    font-weight: 600;
    text-align: left;
    padding-left: 0;
    padding-right: 1em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    font-weight: 500;
  }
  td {
    vertical-align: top;
    padding-left: 0;
    padding-right: 1em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  address {
    font-style: inherit;
    line-height: 1.4;
  }
  strong {
    font-weight: 500;
    ${fromDesktop} {
      white-space: nowrap;
    }
  }
`;

const Emails = styled.p`
  line-height: 1.75;
`;

const AddressGrid = styled.div`
  padding-top: 1em;
  ${uptoDesktop} {
    > * {
      + * {
        margin-top: 1em;
      }
    }
  }
  ${fromDesktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
  h4 {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 80%;
    font-weight: 700;
    color: ${colors.purple};
    margin-bottom: 0.75em;
  }
`;

const ContactSection = () => {
  return (
    <PageSection>
      <ContactSectionInner>
        <StaticImageFrame
          backgroundColor={colors.purple50}
          src={TinesDublinOfficeImage}
          cover
          intrinsicWidth={785}
          intrinsicHeight={755}
          borderRadius={rSize("radius")}
          className={css`
            ${uptoTabletMd} {
              aspect-ratio: 2 / 1.25;
            }
            img {
              height: 100%;
            }
          `}
        />
        <Content>
          <InfoBox>
            <p>
              The fastest way to reach us is to{" "}
              <PseudoLink onClick={openIntercomMessenger}>
                send us a message
              </PseudoLink>
              .
            </p>
          </InfoBox>
          <h3>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#8578E6">
              <path d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM19 6L12.5 10.47C12.348 10.5578 12.1755 10.604 12 10.604C11.8245 10.604 11.652 10.5578 11.5 10.47L5 6H19Z" />
            </svg>
            Email
          </h3>
          <Emails>
            General questions:{" "}
            <a href="mailto:hello@tines.com?subject=Contact%20from%20website">
              hello@tines.com
            </a>
            <br />
            Analyst enquiries:{" "}
            <a href="mailto:analysts@tines.com?subject=Contact%20from%20website">
              analysts@tines.com
            </a>
            <br />
            Press enquiries:{" "}
            <a href="mailto:pr@tines.com?subject=Contact%20from%20website">
              pr@tines.com
            </a>
          </Emails>

          <h3>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#8578E6">
              <path d="M16.364 17.364L10 23.728L3.636 17.364C2.37734 16.1054 1.52019 14.5017 1.17293 12.7559C0.82567 11.0101 1.00391 9.20047 1.6851 7.55595C2.36629 5.91142 3.51984 4.50582 4.99988 3.51689C6.47992 2.52796 8.21998 2.00012 10 2.00012C11.78 2.00012 13.5201 2.52796 15.0001 3.51689C16.4802 4.50582 17.6337 5.91142 18.3149 7.55595C18.9961 9.20047 19.1743 11.0101 18.8271 12.7559C18.4798 14.5017 17.6227 16.1054 16.364 17.364ZM10 13C10.5304 13 11.0391 12.7893 11.4142 12.4143C11.7893 12.0392 12 11.5305 12 11C12 10.4696 11.7893 9.9609 11.4142 9.58583C11.0391 9.21076 10.5304 9.00004 10 9.00004C9.46957 9.00004 8.96086 9.21076 8.58579 9.58583C8.21072 9.9609 8 10.4696 8 11C8 11.5305 8.21072 12.0392 8.58579 12.4143C8.96086 12.7893 9.46957 13 10 13Z" />
            </svg>
            Office addresses
          </h3>
          <AddressGrid>
            <div>
              <h4>Dublin</h4>
              <address>
                <strong>Tines Security Services Limited</strong>
                <br />
                The Academy
                <br />
                42 Pearse St
                <br />
                Dublin, D02 YX88
                <br />
                Ireland
              </address>
            </div>
            <div>
              <h4>Boston</h4>
              <address>
                <strong>Tines Automation Inc.</strong>
                <br />
                1 Lincoln Street
                <br />
                Floor 31
                <br />
                Boston, MA 02111
                <br />
                United States
              </address>
            </div>
            <div>
              <h4>San Francisco</h4>
              <address>
                <strong>Tines Automation Inc.</strong>
                <br />
                2 Embarcadero Center
                <br />
                8th Floor
                <br />
                San Francisco, CA 94111
                <br />
                United States
              </address>
            </div>
          </AddressGrid>
        </Content>
      </ContactSectionInner>
    </PageSection>
  );
};

export default ContactSection;
