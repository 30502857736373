import { styled } from "@linaria/react";
import { getWidthPxValueInMaxGrid } from "../../../constants/globalGrid.constants";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { ImpactSectionsHeadingTrafficLights } from "./ImpactSectionsHeadingTrafficLights";
import { useRef, useState } from "react";
import gsap from "gsap";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";

const WindowRange = styled.span`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  user-select: none;
  > b {
    font-weight: inherit;
    position: relative;
    color: ${colors.purple400};
    @supports (-webkit-text-stroke: 0.5px ${colors.purple200}) {
      color: ${withOpacity(colors.purple400, 0.2)};
      -webkit-text-stroke: 0.5px ${colors.purple200};
    }
  }
`;

const WindowFrame = styled.span`
  display: block;
  background-color: ${colors.purple};
  border: 1px solid ${colors.purple400};
  position: absolute;
  width: 7.375em;
  top: -45px;
  ${fromTablet} {
    top: -38px;
    width: ${(getWidthPxValueInMaxGrid(6) - 48) / 64}em;
  }
  left: -0.1em;
  bottom: -0.1em;
  border-radius: 0.6rem;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  b {
    content: "annoying work,";
    position: absolute;
    font-weight: inherit;
    bottom: calc(0.1em - 1px);
    left: calc(0.1em - 1px);
  }
`;

export const ImpactSectionsHeadingWindowRange = () => {
  const [ready, setReady] = useState(false);
  const windowFrameRef = useRef<HTMLSpanElement>(null);
  const handleClose = () => {
    if (!windowFrameRef.current) return;
    gsap.to(windowFrameRef.current, {
      scale: 0,
      duration: 0.3,
      ease: "power2.out",
    });
    gsap.to(windowFrameRef.current.querySelector("b"), {
      opacity: 0,
      duration: 0.2,
    });
  };
  useOnMount(() => {
    setReady(true);
  });
  return (
    <WindowRange>
      <b>annoying work,</b>
      <WindowFrame ref={windowFrameRef}>
        <ImpactSectionsHeadingTrafficLights onClose={handleClose} />
        {ready && <b>annoying work,</b>}
      </WindowFrame>
    </WindowRange>
  );
};
