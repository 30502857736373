import { Story, StoryRecord } from "../../../types/tines.types";
import { convertLinkRecordToLink } from "./actionLinks.utils";
import { convertActionRecordToAction } from "./actions.utils";
import { getStoryboardElementGroupMeasurements } from "./storyboard.utils";
import { convertNoteRecordToNote } from "./notes.utils";
import { makeSlug } from "../../../utils/string.utils";
import {
  StoryDescriptor,
  StoryIconDescriptor,
} from "../../../types/helper.types";

export const createStoryFromRecord = (record: StoryRecord) => {
  const actions = record.agents.map(a =>
    convertActionRecordToAction(a, record)
  );
  const links = record.links.map((a, i) =>
    convertLinkRecordToLink(a, actions, i)
  );
  actions.forEach(action => {
    const inputLinks = links.filter(l => l.receiver === action);
    const outputLinks = links.filter(l => l.source === action);
    action.inputLinks.push(...inputLinks);
    action.outputLinks.push(...outputLinks);
    action.inputs.push(...inputLinks.map(l => l.source));
    action.outputs.push(...outputLinks.map(l => l.receiver));
  });
  const notes = record.diagramNotes.map(n =>
    convertNoteRecordToNote(n, record)
  );
  const heads = actions.filter(a => a.inputs.length === 0);
  const s: Story = {
    record,
    actions,
    heads,
    links,
    notes,
    measurements: getStoryboardElementGroupMeasurements(actions, notes),
  };
  return s;
};

export const createStaticStoryMeta = (partial: {
  id: string;
  slug?: string;
  color: "purple" | "orange" | "pink" | "green";
  name: string;
  description?: string;
  icons?: StoryIconDescriptor[];
  tags?: string[];
}): StoryDescriptor => {
  return {
    description: "",
    guid: "",
    datoCmsRecordId: "",
    slug: partial.slug ?? makeSlug(partial.name),
    isPublished: true,
    isPublic: true,
    isUnlisted: false,
    isPrivate: false,
    ranking: 1,
    numberOfActions: 0,
    timeCreated: "",
    timeFirstPublished: "",
    tags: partial.tags ?? [],
    tools: [],
    features: [],
    icons: [],
    internalAuthors: [],
    communityAuthors: [],
    gallery: [],
    ...partial,
  };
};

export const isCommunityStory = (story?: StoryDescriptor | null) =>
  story &&
  (story.communityAuthors.length > 0 || story.tags.includes("Community"));
