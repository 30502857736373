import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
} from "../../styles/colorsV4.styles";

type Props = {
  color?: BrandColorNameV4;
};

const TinesKey = (props: Props) => {
  const accent = colorV4(props.color ?? "purple");
  const darker = colorV4(props.color ?? "purple", 700);
  const stroke = colorsV4.warmBlack;
  return (
    <svg width="300" height="300" viewBox="0 0 300 300" fill="none">
      <path
        d="M52.0452 179.571C45.9726 182.239 43.2123 189.326 45.8806 195.399C48.5489 201.472 55.6354 204.231 61.7081 201.563C67.7815 198.895 70.541 191.809 67.8726 185.735C65.2044 179.663 58.1187 176.903 52.0452 179.571ZM59.1773 195.803C56.2858 197.074 52.911 195.76 51.6403 192.867C50.3697 189.976 51.6846 186.601 54.576 185.331C57.4682 184.06 60.8422 185.374 62.1128 188.266C63.3833 191.158 62.0694 194.532 59.1773 195.803Z"
        fill={darker}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M48.4668 181.143C42.3945 183.811 39.6347 190.898 42.3029 196.971C44.9713 203.044 52.0573 205.803 58.1296 203.135C64.2031 200.467 66.9629 193.381 64.2945 187.307C61.6262 181.235 54.5402 178.475 48.4668 181.143ZM55.5988 197.375C52.7074 198.646 49.3334 197.332 48.0627 194.439C46.792 191.548 48.1061 188.174 50.9976 186.903C53.8901 185.632 57.2638 186.947 58.5344 189.838C59.8055 192.73 58.4914 196.104 55.5988 197.375Z"
        fill={accent}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M257.005 125.637L248.983 107.38L213.472 122.984L221.493 141.24L227.049 138.799L229.606 144.618L224.05 147.059L228.41 156.982L242.657 150.723L236.154 135.925L243.174 132.841L249.676 147.638L263.922 141.379L259.561 131.456L254.005 133.897L251.449 128.078L257.005 125.637Z"
        fill={darker}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M252.669 127.542L244.647 109.286L209.136 124.889L217.158 143.145L222.714 140.704L225.27 146.523L219.714 148.964L224.075 158.887L238.32 152.628L231.818 137.831L238.839 134.746L245.34 149.544L259.586 143.284L255.226 133.361L249.67 135.802L247.113 129.983L252.669 127.542Z"
        fill={accent}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M145.714 152.029L144.248 150.676C144.149 150.584 142.777 149.322 140.61 147.646C137.911 142.313 133.636 135.046 127.986 129.171C116.36 117.084 105.836 118.002 99.0553 120.901L72.6119 132.203C66.3388 134.884 58.9281 141.614 59.2451 158.447C59.392 166.206 61.167 174.211 63.2453 180.549C62.9411 183.367 62.9022 185.307 62.9002 185.444L62.8656 187.439L64.3318 188.794C64.4297 188.885 65.8019 190.146 67.9704 191.822C70.668 197.157 74.9432 204.422 80.5938 210.299C92.2201 222.384 102.744 221.467 109.524 218.568L135.968 207.266C142.241 204.584 149.652 197.855 149.334 181.022C149.188 173.262 147.412 165.258 145.335 158.921C145.639 156.102 145.677 154.163 145.679 154.025L145.714 152.029ZM136.074 160.998C135.082 167.076 132.953 173.931 129.162 175.551L91.3042 191.731C87.5126 193.352 81.0871 190.153 76.009 186.67L136.074 160.998ZM72.5058 178.472C73.497 172.393 75.6253 165.538 79.4168 163.918L117.274 147.738C121.066 146.117 127.491 149.316 132.569 152.799L72.5058 178.472ZM76.1145 140.4L102.558 129.098C111.173 125.416 118.997 131.644 124.816 139.136C120.676 138.042 116.98 138.168 113.771 139.54L75.9138 155.721C72.9349 156.994 70.4423 159.314 68.4438 162.636C67.4306 152.981 68.8173 143.519 76.1145 140.4ZM132.464 199.069L106.021 210.371C97.4071 214.052 89.5823 207.825 83.7628 200.333C87.9017 201.427 91.5994 201.3 94.8084 199.929L132.666 183.748C135.644 182.475 138.137 180.155 140.136 176.833C141.149 186.488 139.762 195.948 132.464 199.069Z"
        fill={darker}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M256.56 102.829C258.23 106.631 258.073 110.378 255.805 111.374L137.387 163.405C133.586 165.075 129.149 163.347 127.479 159.546C125.808 155.744 127.536 151.307 131.338 149.636L249.906 97.5395C251.872 96.6759 254.89 99.0273 256.56 102.829Z"
        fill={accent}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M139.17 154.905L137.703 153.551C137.605 153.459 136.233 152.198 134.065 150.522C131.367 145.188 127.092 137.921 121.441 132.047C109.816 119.96 99.2916 120.877 92.5112 123.776L66.0676 135.078C59.7942 137.76 52.3837 144.489 52.7007 161.323C52.8473 169.082 54.6234 177.086 56.7009 183.424C56.3968 186.242 56.3579 188.183 56.3557 188.32L56.3212 190.315L57.7873 191.67C57.8858 191.76 59.2576 193.022 61.4258 194.697C64.1239 200.031 68.3985 207.298 74.0497 213.174C85.6754 225.26 96.1988 224.342 102.98 221.443L129.424 210.142C135.696 207.46 143.108 200.731 142.789 183.898C142.643 176.138 140.868 168.134 138.79 161.796C139.094 158.978 139.133 157.038 139.134 156.901L139.17 154.905ZM129.53 163.873C128.538 169.951 126.409 176.807 122.617 178.427L84.7595 194.607C80.9679 196.227 74.5428 193.029 69.4651 189.545L129.53 163.873ZM65.9615 181.347C66.9528 175.268 69.0811 168.414 72.8729 166.793L110.73 150.613C114.522 148.993 120.947 152.191 126.026 155.675L65.9615 181.347ZM69.5706 143.275L96.0141 131.973C104.629 128.292 112.453 134.52 118.273 142.011C114.133 140.917 110.436 141.044 107.227 142.416L69.3695 158.596C66.391 159.869 63.8976 162.19 61.8995 165.511C60.8863 155.857 62.2732 146.395 69.5706 143.275ZM125.92 201.944L99.4769 213.246C90.8628 216.928 83.0379 210.7 77.2186 203.209C81.3571 204.303 85.0551 204.176 88.2637 202.804L126.122 186.624C129.1 185.35 131.593 183.03 133.592 179.709C134.604 189.363 133.218 198.824 125.92 201.944Z"
        fill={accent}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M256.008 111.285C258.264 110.293 258.741 106.407 257.069 102.605C255.399 98.8023 252.215 96.5239 249.957 97.5157C247.699 98.5076 247.224 102.394 248.894 106.196C250.566 109.999 253.75 112.277 256.008 111.285Z"
        fill={darker}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
      />
      <path
        d="M62.5836 152.711L68.9519 149.913C68.9519 149.913 69.8785 143.661 74.243 141.243C74.243 141.243 68.784 143.642 67.5245 144.195C66.2651 144.748 63.1115 148.972 62.5836 152.711Z"
        fill={stroke}
      />
      <path
        d="M67.6096 175.55L73.9775 172.753C73.9775 172.753 74.9041 166.501 79.2687 164.082C79.2687 164.082 73.8096 166.481 72.5501 167.034C71.2907 167.588 68.1376 171.812 67.6096 175.55Z"
        fill={stroke}
      />
      <path
        d="M69.7593 189.384L76.198 186.555L81.3288 190.061L74.89 192.89L69.7593 189.384Z"
        fill={stroke}
      />
      <path
        d="M91.4672 207.816L86.8515 203.582C86.8515 203.582 78.8384 204.347 77.1263 203.68C77.1265 203.68 83.8075 213.186 91.4672 207.816Z"
        fill={stroke}
      />
      <path
        d="M139.026 162.61L145.853 159.61L147.403 165.166L140.789 168.072L139.026 162.61Z"
        fill={stroke}
      />
      <path
        d="M130.259 184.662L133.591 179.708L133.66 183.422L130.259 184.662Z"
        fill={stroke}
      />
      <path
        d="M111.254 140.829L115.628 138.907L117.687 141.566L111.254 140.829Z"
        fill={stroke}
      />
      <path
        d="M153.718 156.229C153.782 156.926 153.789 157.515 153.751 157.971C152.408 156.929 150.153 154.248 148.24 149.896C146.328 145.544 145.88 142.069 146.02 140.376C146.543 140.782 147.206 141.44 147.932 142.346L154.324 139.537C151.018 134.82 146.814 131.668 142.941 133.37C137.526 135.749 138.659 145.025 142.004 152.637C145.348 160.249 151.414 167.357 156.83 164.978C160.479 163.375 161.151 158.638 160.182 153.391L153.719 156.23L153.718 156.229Z"
        fill={accent}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.684 153.608C159.748 154.304 159.756 154.893 159.717 155.35C158.374 154.307 156.119 151.627 154.207 147.274C152.294 142.922 151.846 139.447 151.986 137.754C152.51 138.16 153.172 138.818 153.898 139.724L160.29 136.915C156.984 132.198 152.78 129.046 148.907 130.748C143.492 133.127 144.625 142.403 147.97 150.015C151.314 157.628 157.381 164.736 162.795 162.357C166.444 160.753 167.118 156.017 166.147 150.769L159.684 153.609V153.608Z"
        fill={accent}
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M251.683 127.951L246.928 130.04L249.4 135.665L254.155 133.576L251.683 127.951Z"
        fill={stroke}
      />
      <path
        d="M236.31 135.568L231.555 137.657L233.708 142.56L238.463 140.47L236.31 135.568Z"
        fill={stroke}
      />
      <path
        d="M251.953 113.324L247.198 115.414L248.806 119.077L253.563 116.988L251.953 113.324Z"
        fill={stroke}
      />
      <path
        d="M165.802 150.932L159.113 153.871L160.086 156.084L166.774 153.145L165.802 150.932Z"
        fill={stroke}
      />
    </svg>
  );
};

export default TinesKey;
