const GroupsIcon15 = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08102 0.56018C7.34691 0.43746 7.65324 0.437459 7.91913 0.56018L13.4646 3.11963C14.2392 3.47713 14.2392 4.57805 13.4646 4.93555L7.91913 7.495C7.65324 7.61772 7.34691 7.61772 7.08102 7.495L1.53554 4.93555C0.760951 4.57805 0.760946 3.47714 1.53554 3.11963L7.08102 0.56018ZM7.50007 1.68841L2.43186 4.02759L7.50007 6.36677L12.5683 4.02759L7.50007 1.68841ZM0.961598 7.26284C1.10781 6.96547 1.4674 6.84293 1.76477 6.98914L7.50004 9.80899L13.2353 6.98915C13.5327 6.84294 13.8923 6.96548 14.0385 7.26286C14.1847 7.56023 14.0621 7.91982 13.7648 8.06603L7.94126 10.9293C7.66302 11.0661 7.33706 11.0661 7.05881 10.9293L1.2353 8.06601C0.937932 7.91981 0.81539 7.56021 0.961598 7.26284ZM1.76477 10.4891C1.4674 10.3429 1.10781 10.4655 0.961598 10.7628C0.81539 11.0602 0.937932 11.4198 1.2353 11.566L7.05881 14.4293C7.33706 14.5661 7.66302 14.5661 7.94126 14.4293L13.7648 11.566C14.0621 11.4198 14.1847 11.0602 14.0385 10.7629C13.8923 10.4655 13.5327 10.3429 13.2353 10.4891L7.50004 13.309L1.76477 10.4891Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GroupsIcon15;
