import ReactMarkdown from "react-markdown";
import { DatoCmsImageAndTextRepeaterSection } from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import AllCaps from "../../typography/AllCaps";
import WithCustomInlineFormatting from "../../typography/WithCustomInlineFormatting";
import { colors } from "../../../styles/colors.styles";
import { styled } from "@linaria/react";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  fromDesktop,
  fromTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";
import VideoPlayer from "../../basic/VideoPlayer";
import { inParagraphLinkStyle } from "../../../styles/links.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-areas: "text" "figure";
  ${fromTabletMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;
    &[data-text-position="left"] {
      grid-template-areas: "text figure";
    }
    &[data-text-position="right"] {
      grid-template-areas: "figure text";
    }
  }
  ${fromDesktop} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    &[data-text-position="left"] {
      grid-template-areas: "text figure figure";
    }
    &[data-text-position="right"] {
      grid-template-areas: "figure figure text";
    }
  }
`;

const TextContent = styled.div`
  grid-area: text;
  text-align: center;
  ${fromTabletMd} {
    text-align: left;
    padding-right: ${rSize("lg")};
    [data-text-position="right"] & {
      padding-left: ${rSize("gap")};
    }
  }
  h3 {
    font-size: 3.2rem;
    font-family: ${serif};
    font-weight: 400;
    letter-spacing: -0.015em;
  }
  > * {
    + * {
      margin-top: 1rem;
    }
  }
`;

const TextContentBody = styled.div`
  a {
    ${inParagraphLinkStyle("currentColor")}
  }
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  ul,
  ol {
    padding-left: 1.5em;
  }
  li {
    + li {
      margin-top: 0.5em;
    }
  }
`;

const Figure = styled.figure`
  grid-area: figure;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: ${rSize("radius")};
  overflow: hidden;
  > * {
    width: 100%;
    display: block;
  }
`;

const colorMap = {
  Purple: { bg: colors.purple100, border: colors.purple300 },
  Green: { bg: colors.green100, border: colors.green300 },
  Orange: { bg: colors.orange100, border: colors.orange300 },
  Pink: { bg: colors.pink100, border: colors.pink300 },
  Dark: { bg: colors.dark500, border: colors.dark700 },
  Light: { bg: colors.light200, border: colors.light400 },
} as const;

export const LPImageAndTextRepeaterSection = ({
  section,
}: {
  section: DatoCmsImageAndTextRepeaterSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Grid data-text-position={section.textPosition}>
        <TextContent>
          {section.preHeadingTag && (
            <AllCaps as="p">{section.preHeadingTag}</AllCaps>
          )}
          <WithCustomInlineFormatting as="h3">
            {section.title}
          </WithCustomInlineFormatting>
          {section.body && (
            <TextContentBody>
              <ReactMarkdown>{section.body}</ReactMarkdown>
            </TextContentBody>
          )}
        </TextContent>
        {section.visual?.video21?.video && (
          <Figure
            style={{
              backgroundColor:
                colorMap[
                  (section.visual.color ?? "Purple") as keyof typeof colorMap
                ]?.bg,
              borderColor:
                colorMap[
                  (section.visual.color ?? "Purple") as keyof typeof colorMap
                ]?.border,
            }}
          >
            <VideoPlayer
              datoAssetVideo={section.visual.video21.video}
              autoPlay
              loop
              controls={false}
            />
          </Figure>
        )}
      </Grid>
    </PageSection>
  );
};
