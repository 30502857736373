export const BrowserTab = () => (
  <svg
    width="120"
    height="31"
    viewBox="0 0 120 31"
    fill="none"
    style={{ marginBottom: -2 }}
  >
    <path
      d="M4 8C4 3.58172 7.58172 0 12 0H108C112.418 0 116 3.58172 116 8V30H4V8Z"
      fill="#8D75E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 29C2.20914 29 4 27.2091 4 25V29H0Z"
      fill="#8D75E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 30C2.76142 30 5 27.7614 5 25V30H0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 29C117.791 29 116 27.2091 116 25V29H120Z"
      fill="#8D75E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 30C117.239 30 115 27.7614 115 25V30H120Z"
      fill="white"
    />
    <path
      d="M5 8C5 4.13401 8.13401 1 12 1H108C111.866 1 115 4.13401 115 8V31H5V8Z"
      fill="white"
    />
    <rect
      opacity="0.5"
      x="17"
      y="12"
      width="40"
      height="10"
      rx="2"
      fill="#F3ECF7"
    />
    <circle opacity="0.5" cx="100" cy="17" r="5" fill="#F3ECF7" />
  </svg>
);
