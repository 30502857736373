const LogoSophos35 = () => {
  return (
    <svg width="90" height="35" viewBox="0 0 90 16" fill="currentColor">
      <title>Sophos</title>
      <path d="M0.914894 12.6924H7.61178C8.77889 12.6924 9.418 12.5832 9.79267 12.3432C10.2553 12.0587 10.5198 11.58 10.5198 11.0124C10.5198 10.3355 10.1669 9.74585 9.57267 9.46181C9.26467 9.28743 8.69089 9.19979 7.98601 9.19979H5.27623C3.51445 9.19979 2.36867 8.89418 1.598 8.23934C0.716672 7.47486 0.210449 6.29643 0.210449 5.0308C0.210449 3.17552 1.24556 1.56024 2.76556 1.0362C3.42645 0.795753 4.19712 0.731018 5.91578 0.731018H13.1851V3.32833H6.62023C5.23223 3.3499 5.122 3.3499 4.65978 3.45912C4.04334 3.61149 3.66867 4.17912 3.66867 4.94273C3.66867 5.59844 3.97667 6.12204 4.506 6.36204C4.94645 6.55889 5.29889 6.60204 6.35667 6.60204H8.71356C10.8727 6.60204 12.0398 6.92968 12.8993 7.80294C13.5602 8.45733 14.0011 9.74585 14.0011 11.0124C14.0011 12.562 13.274 14.002 12.1287 14.7009C11.402 15.1589 10.4771 15.2897 8.29534 15.2897H0.914894V12.6924Z" />
      <path d="M22.3936 0.620911C20.3229 0.620911 18.5163 1.12337 17.3709 2.04023C15.9172 3.1755 15.1465 5.29325 15.1465 8.0874C15.1465 10.0294 15.5638 11.624 16.4238 12.824C17.5692 14.4618 19.7949 15.3791 22.6145 15.3791C24.5972 15.3791 26.2718 14.8977 27.3736 14.0249C28.8274 12.8689 29.6438 10.7715 29.6438 8.21731L29.6372 7.80072L29.6194 7.39073L29.59 6.99176L29.5505 6.60027L29.4989 6.21847L29.4349 5.84856L29.3616 5.49053L29.2767 5.14396L29.2305 4.97486L29.1812 4.80972L29.1296 4.64634L29.0758 4.48913L29.0203 4.33236L28.9607 4.18043L28.8985 4.03335L28.8336 3.88714L28.7683 3.74799L28.6994 3.61059L28.6278 3.47716L28.554 3.34901L28.4767 3.2235L28.3989 3.10152L28.3176 2.98526L28.234 2.87341C27.1096 1.42943 25.0154 0.620911 22.3936 0.620911ZM22.4149 3.08655C25.0367 3.08655 26.2043 4.61596 26.2043 8.02046C26.2043 11.3823 25.0145 12.9319 22.4149 12.9319C19.7718 12.9319 18.5816 11.3814 18.5816 7.97687L18.5834 7.83331L18.5852 7.69151L18.5874 7.55235L18.5932 7.41495L18.5994 7.28064L18.6056 7.14721L18.6136 7.0173L18.6234 6.88915L18.6349 6.76277L18.6469 6.64078L18.6607 6.52144L18.6763 6.40342L18.6923 6.28716L18.71 6.17267L18.73 6.06258L18.75 5.95248L18.7723 5.84679L18.7963 5.74463L18.8203 5.64246L18.8465 5.54382L18.874 5.44694L18.9016 5.35226L18.9309 5.26198L18.9629 5.17347L18.9967 5.08715L19.0305 5.0026L19.0665 4.92201L19.1043 4.84143L19.1416 4.7648L19.1812 4.6917L19.2229 4.62036L19.2647 4.5521C19.8598 3.61235 20.9834 3.08655 22.4149 3.08655Z" />
      <path d="M31.3171 0.731018V15.2906H34.4434V10.2025H39.4683C41.1425 10.2025 41.9331 9.98586 42.7927 9.26541C43.806 8.41461 44.4234 6.99574 44.4234 5.46721C44.4234 4.17956 43.9611 2.97823 43.102 2.06138C42.1331 0.991718 41.2287 0.731018 38.6514 0.731018H31.3171ZM34.4434 3.32877H38.7611C39.2678 3.32877 39.6647 3.37148 39.9069 3.45912C40.5905 3.69912 40.9874 4.39755 40.9874 5.35756C40.9874 6.16564 40.7443 6.86451 40.3483 7.19214L40.3167 7.21945L40.2847 7.24631L40.2509 7.27229L40.2176 7.29827L40.1816 7.32161L40.146 7.34363L40.1083 7.36521L40.0687 7.38547L40.0287 7.4044L39.9869 7.42422L39.9451 7.44051L39.9016 7.45769L39.8554 7.47354L39.8096 7.48631L39.762 7.5004L39.7123 7.51405L39.6611 7.52506L39.6096 7.53431L39.5558 7.544L39.5003 7.55369L39.4429 7.56161L39.3851 7.56954L39.3234 7.57571L39.2616 7.58363L39.198 7.58804L39.1305 7.592L39.0629 7.5964L38.9936 7.60037L38.9216 7.60169L38.8478 7.60389H38.7723L38.6945 7.60565H34.4425V3.32877H34.4434Z" />
      <path d="M57.7954 15.2888H54.667V9.28699H49.0056V15.2888H45.8772V0.731018H49.0056V6.68968H54.667V0.731018H57.7954V15.2888Z" />
      <path d="M66.9163 0.620911C64.8461 0.620911 63.0394 1.12337 61.8937 2.04023C60.4399 3.1755 59.667 5.29325 59.667 8.0874C59.667 10.0294 60.0861 11.624 60.9466 12.824C62.0906 14.4618 64.3163 15.3791 67.1368 15.3791C69.1203 15.3791 70.7932 14.8977 71.8937 14.0249C73.3474 12.8689 74.1634 10.7715 74.1634 8.21731L74.1572 7.80072L74.1399 7.39073L74.1105 6.99176L74.0706 6.60027L74.0185 6.21847L73.955 5.84856L73.8812 5.49053L73.7977 5.14396L73.7501 4.97486L73.7026 4.80972L73.6514 4.64634L73.5959 4.48913L73.5403 4.33236L73.4803 4.18043L73.4186 4.03335L73.355 3.88714L73.2874 3.74799L73.2181 3.61059L73.147 3.47716L73.0732 3.34901L72.9959 3.2235L72.9186 3.10152L72.8359 2.98526L72.7523 2.87341C71.6297 1.42943 69.5386 0.620911 66.9163 0.620911ZM66.939 3.08655C69.5594 3.08655 70.7279 4.61596 70.7279 8.02046C70.7279 11.3823 69.5377 12.9319 66.939 12.9319C64.2959 12.9319 63.1057 11.3814 63.1057 7.97687V7.83331L63.1074 7.69151L63.1119 7.55235L63.1154 7.41495L63.1217 7.28064L63.1306 7.14721L63.1386 7.0173L63.1479 6.88915L63.1572 6.76277L63.1692 6.64078L63.1848 6.52144L63.1986 6.40342L63.2141 6.28716L63.2323 6.17267L63.2514 6.06258L63.2723 5.95248L63.2941 5.84679L63.3177 5.74463L63.3421 5.64246L63.3683 5.54382L63.3959 5.44694L63.4234 5.35226L63.4545 5.26198L63.4857 5.17347L63.5177 5.08715L63.5514 5.0026L63.587 4.92201L63.6252 4.84143L63.6626 4.7648L63.7025 4.6917L63.7443 4.62036L63.7888 4.5521C64.3825 3.61235 65.5066 3.08655 66.939 3.08655Z" />
      <path d="M76.0146 12.6924H82.7115C83.8782 12.6924 84.5168 12.5832 84.8919 12.3432C85.3537 12.0587 85.6186 11.58 85.6186 11.0124C85.6186 10.3355 85.2662 9.74585 84.6715 9.46181C84.3626 9.28743 83.7906 9.19979 83.0857 9.19979H80.3755C78.6133 9.19979 77.4675 8.89418 76.6968 8.23934C75.8159 7.47486 75.3088 6.29643 75.3088 5.0308C75.3088 3.17552 76.3435 1.56024 77.8644 1.0362C78.5248 0.795753 79.2968 0.731018 81.0146 0.731018H88.2844V3.32833H81.7195C80.3315 3.3499 80.2213 3.3499 79.7595 3.45912C79.1422 3.61149 78.7675 4.17912 78.7675 4.94273C78.7675 5.59844 79.0764 6.12204 79.6062 6.36204C80.0453 6.55889 80.3977 6.60204 81.4559 6.60204H83.8128C85.9715 6.60204 87.139 6.92968 87.9995 7.80294C88.6595 8.45733 89.0995 9.74541 89.0995 11.0119C89.0995 12.5616 88.3728 14.0016 87.2271 14.7005C86.5004 15.1585 85.5751 15.2892 83.3937 15.2892H76.0142V12.6924H76.0146Z" />
    </svg>
  );
};

export default LogoSophos35;
