export const UnevenPlatformsIcon = () => (
  <svg
    width="112"
    height="104"
    viewBox="0 0 112 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1195 94.2288H38.1147C29.73 94.2288 22.9079 87.4073 22.9079 79.022V41.3674C22.9079 40.1678 23.881 39.1953 25.0803 39.1953C26.2803 39.1953 27.2528 40.1678 27.2528 41.3674V79.0217C27.2528 85.0113 32.1253 89.8836 38.1147 89.8836H40.1195C41.3191 89.8836 42.2919 90.8567 42.2919 92.056C42.2919 93.2562 41.3191 94.2288 40.1195 94.2288Z"
      fill="#FABE64"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M61.475 102.67C77.879 102.67 91.177 99.409 91.177 95.3859C91.177 91.3629 77.879 88.1016 61.475 88.1016C45.071 88.1016 31.7729 91.3629 31.7729 95.3859C31.7729 99.409 45.071 102.67 61.475 102.67Z"
      fill="#10352E"
    />
    <path
      d="M74.2166 92.1269H78.3443C86.729 92.1269 93.5512 85.3054 93.5512 76.9201V51.2854C93.5512 50.0858 92.578 49.1133 91.3787 49.1133C90.1788 49.1133 89.2063 50.0858 89.2063 51.2854V76.9201C89.2063 82.9098 84.3337 87.782 78.3443 87.782H74.2166C73.017 87.782 72.0442 88.7546 72.0442 89.9545C72.0442 91.1544 73.017 92.1269 74.2166 92.1269Z"
      fill="#FABE64"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M80.7582 88.5541C80.7582 83.7907 69.655 79.9297 55.9601 79.9297C42.264 79.9297 31.1612 83.7907 31.1612 88.5541C31.1612 89.7105 31.1612 92.8669 31.1612 94.0239C31.1612 98.7866 42.264 102.648 55.9601 102.648C69.655 102.648 80.7582 98.7866 80.7582 94.0239C80.7582 92.8669 80.7582 89.7108 80.7582 88.5541Z"
      fill="#D66B99"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M55.9595 97.1785C69.6553 97.1785 80.7578 93.3172 80.7578 88.5541C80.7578 83.791 69.6553 79.9297 55.9595 79.9297C42.2638 79.9297 31.1612 83.791 31.1612 88.5541C31.1612 93.3172 42.2638 97.1785 55.9595 97.1785Z"
      fill="#F38EBA"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6487 94.629C62.5084 94.629 66.448 93.5096 66.448 92.1289C66.448 90.7482 62.5084 89.6289 57.6487 89.6289C52.789 89.6289 48.8494 90.7482 48.8494 92.1289C48.8494 93.5096 52.789 94.629 57.6487 94.629Z"
      fill="#10352E"
    />
    <path
      d="M64.0443 90.4715C68.904 90.4715 72.8435 89.3525 72.8435 87.9721C72.8435 86.5917 68.904 85.4727 64.0443 85.4727C59.1846 85.4727 55.245 86.5917 55.245 87.9721C55.245 89.3525 59.1846 90.4715 64.0443 90.4715Z"
      fill="#10352E"
    />
    <path
      d="M50.0956 26.7181C50.0956 21.9551 38.9928 18.0938 25.2979 18.0938C11.6017 18.0938 0.498932 21.9551 0.498932 26.7181C0.498932 27.8742 0.498932 30.2342 0.498932 31.3909C0.498932 36.1534 11.6017 40.015 25.2979 40.015C38.9928 40.015 50.0956 36.1537 50.0956 31.3909C50.0956 30.2342 50.0956 27.8742 50.0956 26.7181Z"
      fill="#F0946C"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M25.2973 35.3419C38.993 35.3419 50.0956 31.4808 50.0956 26.7178C50.0956 21.9549 38.993 18.0938 25.2973 18.0938C11.6015 18.0938 0.498932 21.9549 0.498932 26.7178C0.498932 31.4808 11.6015 35.3419 25.2973 35.3419Z"
      fill="#FFD18C"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M110.738 50.805C110.738 46.9581 101.772 43.8398 90.7117 43.8398C79.6509 43.8398 70.6846 46.9581 70.6846 50.805C70.6846 51.7386 70.6846 53.6452 70.6846 54.5797C70.6846 58.4254 79.6509 61.544 90.7117 61.544C101.772 61.544 110.738 58.4254 110.738 54.5797C110.738 53.6449 110.738 51.7386 110.738 50.805Z"
      fill="#F0946C"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M90.7111 57.7684C101.772 57.7684 110.738 54.6504 110.738 50.8041C110.738 46.9579 101.772 43.8398 90.7111 43.8398C79.6508 43.8398 70.6846 46.9579 70.6846 50.8041C70.6846 54.6504 79.6508 57.7684 90.7111 57.7684Z"
      fill="#FFD18C"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M27.2023 39.8379H22.4026V44.8376H27.2023V39.8379Z"
      fill="#10352E"
    />
    <path
      d="M93.7378 61.9492H88.9381V65.3492H93.7378V61.9492Z"
      fill="#10352E"
    />
    <path
      d="M40.1278 89.523C40.1278 90.7283 43.0055 91.7047 46.5546 91.7047C50.1036 91.7047 52.981 90.728 52.981 89.523V72.3276C52.981 71.1229 50.1036 70.1465 46.5546 70.1465C43.0055 70.1465 40.1278 71.1232 40.1278 72.3276V89.523Z"
      fill="#49B889"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M46.5543 74.5088C50.1035 74.5088 52.9807 73.5322 52.9807 72.3276C52.9807 71.123 50.1035 70.1465 46.5543 70.1465C43.005 70.1465 40.1278 71.123 40.1278 72.3276C40.1278 73.5322 43.005 74.5088 46.5543 74.5088Z"
      fill="#81D8B4"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M55.7266 87.1715C55.7266 88.3762 58.6043 89.3527 62.1534 89.3527C65.7024 89.3527 68.5798 88.3759 68.5798 87.1715V63.9292C68.5798 62.7245 65.7024 61.748 62.1534 61.748C58.6043 61.748 55.7266 62.7248 55.7266 63.9292V87.1715Z"
      fill="#8173E6"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M62.1531 66.1103C65.7023 66.1103 68.5795 65.1338 68.5795 63.9292C68.5795 62.7246 65.7023 61.748 62.1531 61.748C58.6038 61.748 55.7266 62.7246 55.7266 63.9292C55.7266 65.1338 58.6038 66.1103 62.1531 66.1103Z"
      fill="#B0A6F9"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M26.8124 29.7221C31.6721 29.7221 35.6117 28.6029 35.6117 27.2224C35.6117 25.8418 31.6721 24.7227 26.8124 24.7227C21.9527 24.7227 18.0131 25.8418 18.0131 27.2224C18.0131 28.6029 21.9527 29.7221 26.8124 29.7221Z"
      fill="#10352E"
    />
    <path
      d="M18.4947 26.4235C18.4947 27.6282 21.3724 28.6049 24.9214 28.6049C28.4705 28.6049 31.3479 27.6282 31.3479 26.4235V3.18144C31.3479 1.97643 28.4705 1 24.9214 1C21.3724 1 18.4947 1.97673 18.4947 3.18144V26.4235Z"
      fill="#D66B99"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M24.9211 5.3623C28.4703 5.3623 31.3476 4.38576 31.3476 3.18115C31.3476 1.97654 28.4703 1 24.9211 1C21.3719 1 18.4947 1.97654 18.4947 3.18115C18.4947 4.38576 21.3719 5.3623 24.9211 5.3623Z"
      fill="#FDB2D2"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M48.5975 91.5778C48.5975 92.7831 51.4749 93.7595 55.0239 93.7595C58.573 93.7595 61.4504 92.7828 61.4504 91.5778V80.1675C61.4504 78.9628 58.573 77.9863 55.0239 77.9863C51.4749 77.9863 48.5975 78.9631 48.5975 80.1675V91.5778Z"
      fill="#FABE64"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M55.0239 82.3498C58.5731 82.3498 61.4504 81.373 61.4504 80.1681C61.4504 78.9631 58.5731 77.9863 55.0239 77.9863C51.4747 77.9863 48.5975 78.9631 48.5975 80.1681C48.5975 81.373 51.4747 82.3498 55.0239 82.3498Z"
      fill="#FFD18C"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M93.6438 52.3334C98.5035 52.3334 102.443 51.2143 102.443 49.8337C102.443 48.4532 98.5035 47.334 93.6438 47.334C88.7841 47.334 84.8445 48.4532 84.8445 49.8337C84.8445 51.2143 88.7841 52.3334 93.6438 52.3334Z"
      fill="#10352E"
    />
    <path
      d="M84.5931 49.2834C84.5931 50.4881 87.4705 51.4649 91.0193 51.4649C94.5686 51.4649 97.446 50.4881 97.446 49.2834V37.8729C97.446 36.6678 94.5686 35.6914 91.0193 35.6914C87.4705 35.6914 84.5931 36.6681 84.5931 37.8729V49.2834Z"
      fill="#49B889"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M91.019 40.0562C94.5682 40.0562 97.4454 39.0796 97.4454 37.8748C97.4454 36.67 94.5682 35.6934 91.019 35.6934C87.4697 35.6934 84.5925 36.67 84.5925 37.8748C84.5925 39.0796 87.4697 40.0562 91.019 40.0562Z"
      fill="#81D8B4"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
  </svg>
);
