export type SvgPathCmdMarker =
  | "M"
  | "L"
  | "C"
  | "A"
  | "Z"
  | "H"
  | "V"
  | "S"
  | "Q"
  | "T";
export type SvgPathCmdParams = {
  radiusX: number;
  radiusY: number;
  rotation: number;
  largeArc: number;
  sweep: number;
  x: number;
  y: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};
export type SvgPathCmd = {
  index: number;
  marker: SvgPathCmdMarker;
  degrees: string;
  chunk: string;
  radius: number;
  overlap: boolean;
  params: SvgPathCmdParams;
};
export type SvgPathCmdParamName = keyof SvgPathCmdParams;
type XY = {
  x: number;
  y: number;
};

/**
 * Round the params of each command to the given number of decimals.
 * This function modifies the object in place.
 * @param {array} cmds Sequence of commands
 * @param {number} round Number of decimal place to be rounded
 * @returns {array} Sequence of commands with their params rounded
 */
export function roundParams(el: SvgPathCmd, round: number) {
  Object.keys(el.params).forEach(key => {
    Reflect.set(
      el.params,
      key,
      Reflect.get(el.params, key) &&
        parseFloat(el.params[key as SvgPathCmdParamName].toFixed(round))
    );
  });

  return el;
}

/**
 * Get previous element in array, wrapping if index is out of bounds and skipping if the command is 'Z'
 * @param {any} e Command object
 * @param {number} i Current index
 * @param {array} a Array being iterated
 * @returns {any} Previous element that doesn't have a 'Z' marker
 */
export function getPreviousNoZ(
  e: SvgPathCmd,
  i: number,
  a: SvgPathCmd[]
): SvgPathCmd {
  const counter = i - 1;
  const previous = a[mod(counter, a.length)];

  if (previous.marker !== "Z") {
    return previous;
  } else {
    return getPreviousNoZ(e, counter, a);
  }
}

/**
 * Get next element in array, wrapping if index is out of bounds and skipping if the command is 'Z'
 * @param {any} e Command object
 * @param {number} i Current index
 * @param {array} a Array being iterated
 * @returns {any} Next element that doesn't have a 'Z' marker
 */
export function getNextNoZ(
  e: SvgPathCmd,
  i: number,
  a: SvgPathCmd[]
): SvgPathCmd {
  const counter = i + 1;
  const next = a[mod(counter, a.length)];

  if (next.marker === "Z") {
    return getNextNoZ(e, counter, a);
  } else {
    return next;
  }
}

/**
 * Iterate through an array and convert all commands to absolute.
 * This function should be used as argument in a map() call.
 * @param {any} el Current element in this iteration
 * @param {number} index Current iteration index
 * @param {array} arr Array being iterated
 */
export function convertToAbsolute(
  el: SvgPathCmd,
  index: number,
  arr: SvgPathCmd[]
) {
  // get previous item or create one empty if it doesn't exist
  const prev = arr[index - 1] || { values: { x: 0, y: 0 } };

  // only need to test lowercase (relative) commands
  if (el.marker === el.marker.toLowerCase()) {
    // convert all to uppercase
    el.marker = el.marker.toUpperCase() as SvgPathCmdMarker;
    switch (el.marker) {
      case "M": // move to x,y
        el.params.x += prev.params.x;
        el.params.y += prev.params.y;
        break;
      case "L": // line to x,y
      case "A":
        el.params.x += prev.params.x;
        el.params.y += prev.params.y;
        break;
      case "H": // horizontalTo x
        el.marker = "L";
        el.params.x += prev.params.x;
        el.params.y = prev.params.y;
        break;
      case "V": // verticalTo y
        el.marker = "L";
        el.params.x = prev.params.x;
        el.params.y += prev.params.y;
        break;
      case "C": // beziér curve x1 y1, x2 y2, x y
        el.params.x += prev.params.x;
        el.params.y += prev.params.y;
        el.params.x1 += prev.params.x;
        el.params.y1 += prev.params.y;
        el.params.x2 += prev.params.x;
        el.params.y2 += prev.params.y;
        break;
      case "S":
        el.params.x += prev.params.x;
        el.params.y += prev.params.y;
        el.params.x2 += prev.params.x;
        el.params.y2 += prev.params.y;
        break;
      case "Q":
        el.params.x += prev.params.x;
        el.params.y += prev.params.y;
        el.params.x1 += prev.params.x;
        el.params.y1 += prev.params.y;
        break;
      case "T":
        el.params.x += prev.params.x;
        el.params.y += prev.params.y;
        break;
      case "Z":
        break;
    }
    // H/V uppercase need to be converted too. Convert to L and add missing value
  } else if (el.marker === el.marker.toUpperCase()) {
    switch (el.marker) {
      case "H": // horizontalTo x
        el.marker = "L";
        el.params.y = prev.params.y;
        break;
      case "V": // verticalTo y
        el.marker = "L";
        el.params.x = prev.params.x;
        break;
    }
  }

  /*
    'Z' commands don't have any coordinate but we are cloning the
    start coordinates defined by this subpath initial 'M' so it's
    easier to do the stitching later.
  */
  if (el.marker === "Z") {
    // find previous 'M' recursively
    const rec = (arr: SvgPathCmd[], i: number): SvgPathCmd => {
      if (arr[i].marker === "M") {
        return arr[i];
      } else {
        return rec(arr, i - 1);
      }
    };
    const mBefore = rec(arr, index);
    el.params.x = mBefore.params.x;
    el.params.y = mBefore.params.y;
  }

  return el;
}

/**
 * Takes one marker and an array of numbers and creates one or more command objects with the right
 * properties based on the given marker. Some markers allow for multiple coordinates for one single command.
 * This function takes care of splitting multiple coordinates per command and generating the
 * @param {string} marker Letter of the command being generated
 * @param {array} params Array of numbers to be splitted and parsed into the right properties
 * @returns {array} Array of commands. Most of the time will have only one item
 */
export function newCommands(marker: SvgPathCmdMarker, params: number[]) {
  const cmds = [];

  switch (marker.toUpperCase()) {
    case "M": // moveTo x,y
      for (let i = 0; i < params.length; i += 2) {
        let m;
        if (marker === marker.toUpperCase()) {
          m = i === 0 ? "M" : "L";
        } else {
          m = i === 0 ? "m" : "l";
        }
        cmds.push({
          marker: m,
          params: {
            x: params[i],
            y: params[i + 1],
          },
        });
      }
      break;
    case "L": // lineTo x,y
      for (let i = 0; i < params.length; i += 2) {
        cmds.push({
          marker,
          params: {
            x: params[i],
            y: params[i + 1],
          },
        });
      }
      break;
    case "H": // horizontalTo x
      for (let i = 0; i < params.length; i++) {
        cmds.push({
          marker,
          params: {
            x: params[i],
            y: 0,
          },
        });
      }
      break;
    case "V": // verticalTo y
      for (let i = 0; i < params.length; i++) {
        cmds.push({
          marker,
          params: {
            x: 0,
            y: params[i],
          },
        });
      }
      break;
    case "C": // cubic beziér curve x1 y1, x2 y2, x y
      for (let i = 0; i < params.length; i += 6) {
        cmds.push({
          marker,
          params: {
            x1: params[i],
            y1: params[i + 1],
            x2: params[i + 2],
            y2: params[i + 3],
            x: params[i + 4],
            y: params[i + 5],
          },
        });
      }
      break;
    case "S":
      for (let i = 0; i < params.length; i += 4) {
        cmds.push({
          marker,
          params: {
            x2: params[i],
            y2: params[i + 1],
            x: params[i + 2],
            y: params[i + 3],
          },
        });
      }
      break;
    case "Q":
      for (let i = 0; i < params.length; i += 4) {
        cmds.push({
          marker,
          params: {
            x1: params[i],
            y1: params[i + 1],
            x: params[i + 2],
            y: params[i + 3],
          },
        });
      }
      break;
    case "T":
      for (let i = 0; i < params.length; i += 2) {
        cmds.push({
          marker,
          params: {
            x: params[i],
            y: params[i + 1],
          },
        });
      }
      break;
    case "A":
      for (let i = 0; i < params.length; i += 7) {
        cmds.push({
          marker,
          params: {
            radiusX: params[i],
            radiusY: params[i + 1],
            rotation: params[i + 2],
            largeArc: params[i + 3],
            sweep: params[i + 4],
            x: params[i + 5],
            y: params[i + 6],
          },
        });
      }
      break;
    case "Z":
      cmds.push({
        marker,
        params: {
          // values will be overridden later by convertToAbsolute()
          x: 0,
          y: 0,
        },
      });
      break;
  }
  return cmds as unknown as SvgPathCmd[];
}

/**
 * Takes an index and a length and returns the index wrapped if out of bounds.
 * @param {number} x Index
 * @param {number} m Length
 * @returns {number} Index or wrapped index if out bounds
 */
export function mod(x: number, m: number) {
  return ((x % m) + m) % m;
}

/**
 * Compares the given element with it's predecessor and checks if their end position is the same.
 * If it is, add a boolean 'overlap' property to the element. This function modifies the array elements in place
 * @param {any} el Command object
 * @param {number} index Current iteration index
 * @param {array} array Array being iterated
 * @returns {any} Command object
 */
export function markOverlapped(
  el: SvgPathCmd,
  index: number,
  array: SvgPathCmd[]
): SvgPathCmd {
  // Skip the first moveTo command and any other that's not a lineTo.
  if (index !== 0 && el.marker === "L") {
    // It seems we have a lineTo here. Get the immediate previous command
    const previous = array[index - 1];
    // …and check if the x, y coordinates are equals.
    const overlap = ["x", "y"].every(key => {
      // If x AND y overlap, this command should be skipped
      return (
        Math.round(
          Math.abs(
            previous.params[key as SvgPathCmdParamName] -
              el.params[key as SvgPathCmdParamName]
          )
        ) === 0
      );
    });

    if (overlap) {
      el.overlap = true;
    }
  }

  return el;
}

/**
 * Similar purpose as markOverlapped().
 * Recursively marks trailing commands that have the same end coordinate as the initial 'M'.
 * This function modifies the array in place.
 * @param {array} cmds Commands array
 * @param {number} index Optional start index counting backwards. Usually the last index from the array
 */
export function reverseMarkOverlapped(cmds: SvgPathCmd[], counter: number) {
  const overlap = ["x", "y"].every(key => {
    // If x AND y overlap, this command should be skipped
    return (
      Math.round(
        Math.abs(
          cmds[counter].params[key as SvgPathCmdParamName] -
            cmds[0].params[key as SvgPathCmdParamName]
        )
      ) === 0
    );
  });

  if (cmds[counter].marker === "L" && overlap) {
    cmds[counter].overlap = true;
    reverseMarkOverlapped(cmds, counter - 1);
  }

  if (cmds[counter].marker === "Z") {
    reverseMarkOverlapped(cmds, counter - 1);
  }
}

/**
 * Calculates the distance between the current command and
 * it's direct neighbors and returns the nearest distance
 * @param {any} el current command
 * @param {any} previous previous command
 * @param {any} next next command
 * @returns {number} the distance to teh nearest command
 */
export function shortestSide(
  el: SvgPathCmd,
  previous: SvgPathCmd,
  next: SvgPathCmd
) {
  const nxtSide = getDistance(el.params, next.params);
  const prvSide = getDistance(previous.params, el.params);
  return Math.min(prvSide, nxtSide);
}

/**
 * Calculates the angle between two points
 * @param {any} p1 Object with x and y properties
 * @param {any} p2 Object with x and y properties
 * @returns {number} Angle in radians
 */
export function getAngle(p1: XY, p2: XY) {
  return Math.atan2(p2.x - p1.x, p2.y - p1.y);
}

/**
 * Calculates the distance between two points
 * @param {any} p1 Object with x and y properties
 * @param {any} p2 Object with x and y properties
 * @returns {number} Distance between points
 */
export function getDistance(p1: XY, p2: XY) {
  const xDiff = p1.x - p2.x;
  const yDiff = p1.y - p2.y;

  return Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2));
}

/**
 * Calculates the length of the opposite side
 * of a given angle using the hypothenuse
 * @param {number} angle Angle in radians
 * @param {number} hip Hypothenuse
 * @returns {number} Length of the opposite side
 */
export function getOppositeLength(angle: number, hip: number) {
  return Math.sin(angle) * hip;
}

/**
 * Calculates the length of the adjacent side
 * of a given angle using the hypothenuse
 * @param {number} angle Angle in radians
 * @param {number} hip Hypothenuse
 * @returns {number} Length of the adjacent side
 */
export function getAdjacentLength(angle: number, hip: number) {
  return Math.cos(angle) * hip;
}

/**
 * Calculates the adjacent side of the given
 * angle using the angle's opposite side
 * @param {number} angle Angle in radians
 * @param {number} opposite opposite side
 * @returns {number} Length of the adjacent side
 */
export function getTangentLength(angle: number, opposite: number) {
  const a = opposite / Math.tan(angle);
  if (a === Infinity || a === -Infinity || isNaN(a)) {
    return opposite;
  }

  return a;
}

/**
 * Calculates the opposite side of the given
 * angle using the angle's adjacent side
 * @param {number} angle Angle in radians
 * @param {number} adjacent adjacent side
 * @returns {number} Length of the opposite side
 */
export function getTangentNoHyp(angle: number, adjacent: number) {
  return adjacent * Math.tan(angle);
}

/**
 * Calculates the length that should be used to shorten the
 * distance between commands based on the given radius value
 * @param {number} angle Angle in radians between points
 * @param {number} r Radius of the arc that should fit inside the triangle
 * @returns {any} Object containing offset and the arc's sweepFlag
 */
export function getOffset(angle: number, r: number) {
  let offset;
  let sweepFlag = 0;
  const degrees = angle * (180 / Math.PI);

  // sharp angles
  if ((degrees < 0 && degrees >= -180) || (degrees > 180 && degrees < 360)) {
    offset = getTangentLength(angle / 2, -r);
    // obtuse angles
  } else {
    offset = getTangentLength(angle / 2, r);
    sweepFlag = 1;
    if (offset === Infinity) {
      offset = r;
    }
  }

  return {
    offset,
    sweepFlag,
  };
}

// /**
//  * Originally taken from: http://bl.ocks.org/balint42/8c9310605df9305c42b3
//  * @brief De Casteljau's algorithm splitting n-th degree Bezier curve
//  * @returns {array}
//  */
// export function bsplit(points, t0) {
//   const n = points.length - 1; // number of control points
//   const b = []; // coefficients as in De Casteljau's algorithm
//   const res1 = []; // first curve resulting control points
//   const res2 = []; // second curve resulting control points
//   const t1 = 1 - t0;

//   // multiply point with scalar factor
//   const pf = function (p, f) {
//     const res = [];
//     for (let i = 0; i < p.length; i++) {
//       res.push(f * p[i]);
//     }
//     return res;
//   };
//   // add points as vectors
//   const pp = function (p1, p2) {
//     const res = [];
//     for (let i = 0; i < Math.min(p1.length, p2.length); i++) {
//       res.push(p1[i] + p2[i]);
//     }
//     return res;
//   };

//   // set original coefficients: b[i][0] = points[i]
//   for (let i = 0; i <= n; i++) {
//     points[i] = typeof points[i] == "object" ? points[i] : [points[i]];
//     b.push([points[i]]);
//   }

//   // get all coefficients
//   for (let j = 1; j <= n; j++) {
//     for (let i = 0; i <= n - j; i++) {
//       b[i].push(pp(pf(b[i][j - 1], t1), pf(b[i + 1][j - 1], t0)));
//     }
//   }
//   // set result: res1 & res2
//   for (let j = 0; j <= n; j++) {
//     res1.push(b[0][j]);
//     res2.push(b[j][n - j]);
//   }

//   return [res1, res2];
// }

/**
 * Concatenates commands in a string and ensures that each
 * value from each command is printed in the right order
 * @param {array} cmds Array of svg commands
 * @returns {string} String containing all commands formatted ready for the 'd' Attribute
 */
export function commandsToSvgPath(cmds: SvgPathCmd[]) {
  // when writing the commands back, the relevant values should be written in this order
  const valuesOrder = [
    "radiusX",
    "radiusY",
    "rotation",
    "largeArc",
    "sweep",
    "x1",
    "y1",
    "x2",
    "y2",
    "x",
    "y",
  ];

  return cmds
    .map(cmd => {
      // defaults for empty string, so Z will output no values
      let d = "";
      // filter any command that's not Z
      if (cmd.marker !== "Z") {
        // get all values from current command
        const cmdKeys = Object.keys(cmd.params);
        // filter the valuesOrder array for only the values that appear in the current command.
        // We do this because valuesOrder guarantees that the relevant values will be in the right order
        d = valuesOrder
          .filter(v => cmdKeys.indexOf(v) !== -1)
          // replace the key with it's value
          .map(key => cmd.params[key as SvgPathCmdParamName])
          // and stringify everything together with a comma in-between values
          .join();
      }
      return `${cmd.marker}${d}`;
    })
    .join("")
    .trim();
}
