type Props = {};

const MailIcon = (props: Props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="9"
        fill="url(#paint0_linear_1829_27507)"
      />
      <g filter="url(#filter0_d_1829_27507)">
        <rect
          x="7"
          y="11"
          width="26"
          height="17"
          rx="2"
          fill="url(#paint1_linear_1829_27507)"
        />
      </g>
      <path
        opacity="0.5"
        d="M33 27L23.7917 20M7 27L16.2083 20"
        stroke="url(#paint2_linear_1829_27507)"
      />
      <path
        d="M7 12L18.6434 22.7478C19.4096 23.455 20.5904 23.455 21.3566 22.7478L33 12"
        stroke="url(#paint3_radial_1829_27507)"
      />
      <defs>
        <filter
          id="filter0_d_1829_27507"
          x="5"
          y="10"
          width="30"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1829_27507"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1829_27507"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1829_27507"
          x1="12.5"
          y1="-5"
          x2="20"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5E4D9A" />
          <stop offset="1" stopColor="#B0A6F9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1829_27507"
          x1="20"
          y1="11"
          x2="20"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCF9F5" />
          <stop offset="1" stopColor="#CFCCE5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1829_27507"
          x1="20"
          y1="5.43851"
          x2="20"
          y2="26.5508"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.791667" stopColor="#B7B1C6" />
          <stop offset="1" stopColor="#AEABC9" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_1829_27507"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 23) rotate(-90) scale(11 23.8333)"
        >
          <stop offset="0.751877" stopColor="#B6B1C5" />
          <stop offset="1" stopColor="#A8A5C5" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default MailIcon;
