import buildingShieldedByTines from "../../images/illustration-building-shielded-by-tines.png";
import buildingShieldedByTines2x from "../../images/illustration-building-shielded-by-tines@2x.png";
import carEnteringTunnel from "../../images/illustration-car-entering-tunnel.png";
import cityscape from "../../images/illustration-cityscape.png";
import cityscape2x from "../../images/illustration-cityscape@2x.png";
import elevator from "../../images/illustration-elevator.png";
import elevator2x from "../../images/illustration-elevator@2x.png";
import toolboxHologram from "../../images/illustration-toolbox-hologram.png";
import toolboxHologram2x from "../../images/illustration-toolbox-hologram@2x.png";

export const PNG_ILLUSTRATIONS = {
  elevator: {
    src: elevator,
    src2x: elevator2x,
    width: 367,
    height: 453,
    alt: `An illustration of an elevator that visualizes Tines' no-code automation.`,
  },
  toolboxHologram: {
    src: toolboxHologram,
    src2x: toolboxHologram2x,
    width: 579,
    height: 497,
    alt: `An illustration of a hologram of various tools as a metaphor of how Tines can help you in various different workflows.`,
  },
  cityscape: {
    src: cityscape,
    src2x: cityscape2x,
    width: 848,
    height: 575,
    alt: `An illustration of a vibrant city scene to illustrate how Tines can help build workflows for a wide range of use cases.`,
  },
  carEnteringTunnel: {
    src: carEnteringTunnel,
    src2x: carEnteringTunnel,
    width: 548,
    height: 480,
    alt: `An illustration of a futuristic car entering a tunnel, to illustrate the Tines Tunnel service.`,
  },
  buildingShieldedByTines: {
    src: buildingShieldedByTines,
    src2x: buildingShieldedByTines2x,
    width: 652,
    height: 451,
    alt: `An illustration of a futuristic building, representing an enterprise shielded by Tines.`,
  },
} as const;

export type PngIllustrationName = keyof typeof PNG_ILLUSTRATIONS;
