import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { defaultChartColorSeriesV4 } from "../../../styles/colorsV4.styles";
import { capitalize } from "../../../utils/string.utils";

type Props = {
  data: [label: ReactNode, value: number, color?: string][];
  textAlign?: "left" | "right";
  labelMinHeight?: string | number;
  sortByValue?: boolean;
};

const Bar = styled.div``;

const SegmentLabelSet = styled.div`
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.2rem;
  margin-top: 0.25em;
`;
const SegmentLabel = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  text-align: left;
  &:last-child {
    text-align: right;
  }
  &:only-child {
    text-align: left;
  }
`;
const PercentageDisplay = styled.span`
  font-weight: 500;
  opacity: 0.5;
`;

const SegmentBarSet = styled.div`
  display: flex;
`;
const SegmentBar = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 1em;
  &:first-child {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }
  &:last-child {
    text-align: right;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
`;

const PercentageBar = (props: Props) => {
  const sorted = props.sortByValue
    ? [...props.data].sort((a, b) => b[1] - a[1])
    : [...props.data];
  const total = sorted.reduce((curr, next) => curr + next[1], 0);
  return (
    <Bar className={`align${capitalize(props.textAlign ?? "left")}`}>
      <SegmentBarSet>
        {sorted.map((d, i) => {
          const percentage = `${(d[1] / total) * 100}%`;
          return (
            <SegmentBar
              key={i}
              style={{
                flexBasis: percentage,
                backgroundColor: d[2] ?? defaultChartColorSeriesV4[i],
              }}
            />
          );
        })}
      </SegmentBarSet>
      <SegmentLabelSet style={{ minHeight: props.labelMinHeight }}>
        {sorted.map((d, i) => {
          const percentage = `${(d[1] / total) * 100}%`;
          const percentageRounded = `${Math.round((d[1] / total) * 100)}%`;
          return (
            <SegmentLabel
              key={i}
              style={{
                flexBasis: percentage,
              }}
            >
              <strong
                style={{
                  color: d[2] ?? defaultChartColorSeriesV4[i],
                }}
              >
                {d[0]}
              </strong>{" "}
              <PercentageDisplay>{percentageRounded}</PercentageDisplay>
            </SegmentLabel>
          );
        })}
      </SegmentLabelSet>
    </Bar>
  );
};

export default PercentageBar;
