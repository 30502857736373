import { styled } from "@linaria/react";
import { DatoCmsPartner } from "../../../graphql-types";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { fromTabletLg } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Button, { ButtonGroup } from "../forms/Button";
import SpotIllustration from "../illustrations/SpotIllustration";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import BookADemoButton from "../utilities/BookADemoButton";

type Props = { partner: DatoCmsPartner };

const Footer = styled.footer`
  position: relative;
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  background-color: ${colorsV4.pink};
  color: ${colorsV4.white};
  text-align: center;
`;

const FooterInner = styled.div`
  img {
    margin-top: -2em;
    margin-bottom: -0.5em;
  }
  ${fromTabletLg} {
    max-width: ${widthInGrid(8)};
    margin-left: auto;
    margin-right: auto;
    h2 {
      ${fluidFontSize(24, 36)};
      letter-spacing: -0.01em;
    }
  }
`;

const PartnerPageCTASection = ({ partner }: Props) => {
  const utmTags = `utm_campaign=partner-landing-page&utm_source=${partner.slug}&utm_medium=website`;
  return (
    <PageSection>
      <Footer>
        <FooterInner>
          <SpotIllustration name="chainWithTwoRings" />
          <WithCustomInlineFormatting as="h2">
            {partner.valueProposition}
          </WithCustomInlineFormatting>
          <Spacing size="lg" />
          <ButtonGroup>
            <Button
              href={`/community-edition?${utmTags}`}
              title="Sign up"
              appearance="filled-white"
              color="pink"
            />
            <BookADemoButton
              appearance="outlined"
              children="Book a demo"
              color="white"
              leadSource="Technology Partner"
              campaignId="701MI000008beI9YAI"
            />
          </ButtonGroup>
        </FooterInner>
      </Footer>
    </PageSection>
  );
};

export default PartnerPageCTASection;
