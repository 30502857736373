import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";

export const MockInputField = styled.div`
  display: flex;
  align-items: center;
  height: 1.375em;
  width: 100%;
  background-color: ${colors.purple50};
  opacity: 0.5;
  border-radius: 0.2em;
  padding: 0 0.5em;
`;
