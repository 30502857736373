import { cx } from "linaria";
import { styled } from "linaria/react";
import React, { CSSProperties, useRef } from "react";
import { whenUserActOnCookieBanner } from "../components/gdpr/gdpr";
import { useSiteContext } from "../context/site.context";
import { loadGlobalStyles } from "../styles/globals.styles";
import { PageComponentProps } from "../types/gatsby.types";
import { flash404Notice } from "../utils/404.utils";
import { reportPageView } from "../utils/analytics.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import { initDayJS } from "../utils/time.utils";
import { getUrlQueryParams } from "../utils/urlQueryParams.utils";
import PageTheme, { PageThemeProps } from "../components/utilities/PageTheme";
import {
  PageThemeController,
  createPageThemeController,
} from "../components/site/PageThemeController";
import { colors } from "../styles/colors.styles";

loadGlobalStyles();
initDayJS();

type Props = PageComponentProps & {
  identifier?: string;
  className?: string;
  style?: CSSProperties;
  clipOverflow?: boolean;
} & PageThemeProps;

const Container = styled.div`
  position: relative;
`;

const PageInner = styled.div`
  &.clipOverflow {
    overflow: hidden;
  }
`;

const Page = (props: Props) => {
  const siteContext = useSiteContext();
  const siteContextRef = useRef(siteContext);
  siteContextRef.current = siteContext;

  const themeControllerRef = useRef<PageThemeController>(null);

  useOnMount(() => {
    const pagePath = location.pathname + location.search + location.hash;
    const options = {
      page_title: document.title,
      page_location: location.href,
      page_path: pagePath,
    };
    whenUserActOnCookieBanner(() => {
      reportPageView(options);
    });
    if ("redirected-from-404" in getUrlQueryParams()) {
      flash404Notice();
    }

    themeControllerRef.current = createPageThemeController({
      pathname: location.pathname,
      backgroundColor: props.backgroundColor ?? colors.lightest,
      textColor: props.textColor ?? colors.dark500,
    });

    return () => {
      themeControllerRef.current?.dispose();
    };
  });

  return (
    <Container
      className={cx(props.className)}
      data-identifier={props.identifier}
      style={props.style}
    >
      <PageTheme key={siteContext.location.pathname} {...props} />
      <PageInner className={cx(props.clipOverflow && "clipOverflow")}>
        {props.children}
      </PageInner>
    </Container>
  );
};

export default Page;
