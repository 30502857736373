import { styled } from "@linaria/react";
import React, { useEffect, useRef } from "react";
import { useSiteContext } from "../../context/site.context";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { runAfter } from "../../utils/promises.utils";
import { PageTree, TreePage } from "../../utils/tree.utils";
import { ChevronIconDown } from "../icons/misc/ChevronIconDown";
import LinkToAnchor from "../basic/LinkToAnchor";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { scrollIntoViewIfNotVisible } from "../../utils/scroll.utils";
import NavLink from "../basic/NavLink";
import { TreePageWithCompletionTime } from "../../utils/university.utils";
import GlobalSearchBar from "../search/GlobalSearchBar";
import Spacing from "../layout/Spacing";
import SmartLink from "../basic/SmartLink";
import { isSameOrigin } from "../../utils/urls.utils";

type Props = {
  tree: PageTree;
};

const HubPageSidebarWrap = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  ${onlyPhones} {
    position: relative;
  }
  ${fromTablet} {
    font-size: 1.4rem;
    padding-top: 0.5em;
  }
  nav {
    margin: 0 0 0.375em 0;
    details {
      padding-left: 0;
      summary {
        padding: 0;
        list-style-type: none;
        margin-bottom: 2px;
        &::-webkit-details-marker {
          display: none;
        }
      }
      svg {
        transform: rotate(0);
        flex: 0 0 auto;
        margin-left: 0.5em;
        transition: transform 0.1s;
      }
      > div {
        padding-left: 0.75em;
      }
      &[open] > summary {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  a {
    text-decoration: none;
    padding: 0.375em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;
    min-height: 2.8rem;
    border-radius: 0.75em;
    ${fromTablet} {
      min-height: 2.4rem;
      border-radius: 0.5em;
    }
    svg {
      opacity: 0.375;
    }
    &:hover {
      background-color: ${withOpacity(colorsV4.purple, 0.1)};
    }
    &.active {
      color: ${colorsV4.purple};
    }
    > * {
      pointer-events: none;
    }
  }
  code {
    font-size: 90%;
    @supports (-webkit-line-clamp: 1) {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

const HubPageSidebar = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const siteContext = useSiteContext();
  useEffect(() => {
    runAfter(() => {
      siteContext.toggleSidebar(false);
      const activeLinks = Array.from(
        ref.current?.querySelectorAll<HTMLAnchorElement>(`a.active`) ?? []
      );
      activeLinks.forEach((activeLink, i) => {
        let el = activeLink?.parentElement;
        while (el && el.nodeName !== "NAV") {
          if (el.nodeName === "DETAILS") {
            (el as HTMLDetailsElement).open = true;
          }
          el = el.parentElement;
        }
        if (i === activeLinks.length - 1)
          runAfter(() => {
            scrollIntoViewIfNotVisible(
              activeLink,
              document.querySelector(".SiteSidebarAside") as HTMLElement
            );
          });
      });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteContext.location.pathname]);
  return (
    <HubPageSidebarWrap ref={ref}>
      <GlobalSearchBar showShortcutHint />
      <Spacing />
      <nav>
        {props.tree.map(page => (
          <HubPageSidebarItem key={page.id} page={page} />
        ))}
      </nav>
    </HubPageSidebarWrap>
  );
};

const CheckIconWrap = styled.div`
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.completed {
    background-color: ${colorsV4.purple};
  }
  .active & {
  }
`;

const CheckIcon = (props: { completed: boolean }) => (
  <CheckIconWrap className={props.completed ? "completed" : ""}>
    {props.completed && (
      <svg width="10" height="8" viewBox="0 0 10 8" fill="none">
        <path d="M1 3.05882L3.96296 6L9 1" stroke="white" strokeWidth="1.5" />
      </svg>
    )}
  </CheckIconWrap>
);

const HubPageSidebarItem = (props: { page: TreePage }) => {
  const { page } = props;
  const isInUniArea = page.path.match(/^\/university/);
  const parentLinkAttributes = {
    className: page.hasNoContent ? "hasNoContent" : "",
    to: page.path,
    title: page.shortTitle || page.title,
    partiallyActive: true,
    children: (
      <>
        <span>
          {isInUniArea && (
            <CheckIcon
              completed={!!(page as TreePageWithCompletionTime).completionTime}
            />
          )}
          {page.id.includes("_formulas-function-") ? (
            <code>{page.title}</code>
          ) : (
            <>
              {page.title}
              {page.redirectToUrl ? (
                isSameOrigin(page.redirectToUrl) ? (
                  " →"
                ) : (
                  <>
                     <sup>↗</sup>
                  </>
                )
              ) : (
                ""
              )}
            </>
          )}
        </span>
        {page.treeChildren.length > 0 && <ChevronIconDown />}
      </>
    ),
    onClick: (e?: React.MouseEvent) => {
      if (page.hasNoContent) e?.preventDefault();
      const anchor = e?.target as HTMLAnchorElement;
      const summary = anchor.parentNode as HTMLDivElement;
      if (summary.nodeName !== "SUMMARY") return;
      const details = summary?.parentNode as HTMLDetailsElement;
      const open = details.open;
      runAfter(() => {
        if (details.open === open) details.open = !details.open;
      });
    },
  };
  const parentLink = page.redirectToUrl ? (
    <SmartLink src={page.redirectToUrl}>
      {parentLinkAttributes.children}
    </SmartLink>
  ) : page.path.includes("#") && !page.hasNoContent ? (
    <LinkToAnchor {...parentLinkAttributes} />
  ) : (
    <NavLink {...parentLinkAttributes} exact={!page.treeChildren.length} />
  );

  const isInApiDocs = page.path.match(/^\/api/);
  // Ensure only unique children are displayed in the sidebar
  const filteredTreeChildren = isInApiDocs
    ? page.treeChildren.filter(obj => !obj.slug.endsWith("-0"))
    : page.treeChildren;

  return page.treeChildren.length > 0 ? (
    <details>
      <summary>{parentLink}</summary>
      <div>
        {filteredTreeChildren.map(child => (
          <HubPageSidebarItem key={child.id} page={child} />
        ))}
      </div>
    </details>
  ) : (
    parentLink
  );
};

export default HubPageSidebar;
