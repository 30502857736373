import { styled } from "@linaria/react";
import { cx } from "linaria";
import KeyboardShortcut, {
  KeyboardShortcutKbd,
} from "../utilities/KeyboardShortcut";
import SearchIcon13 from "../icons/misc/SearchIcon13";
import TextInput, { TextInputStyled } from "../forms/TextInput";
import { useSiteContext } from "../../context/site.context";
import { colors, withOpacity } from "../../styles/colors.styles";
import { UseStateReturnType } from "../../types/helper.types";
import React, { FormEvent, KeyboardEvent } from "react";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { copyToClipboard } from "../../utils/clipboard.utils";
import { onlyPhones } from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  className?: string;
  formState?: UseStateReturnType<{ query: string }>;
  withIcon?: boolean;
  higherContrast?: boolean;
  autoFocus?: boolean;
  onEscape?: () => void;
  showShortcutHint?: boolean;
  placeholder?: string;
  loading?: boolean;
  showOpenInNewTabButton?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onQueryUpdate?: (query: string) => void;
};

export const GlobalSearchBarContainer = styled.div`
  position: relative;
  min-width: 120px;
  background-clip: border-box;
  display: flex;
  align-items: stretch;
  &.functional.isNotOnSearchPage {
  }
  &.presentational {
    cursor: text;
    border: 1px solid transparent;
    > * {
      pointer-events: none;
    }
    &:hover {
      input {
        border-color: ${colors.purple};
      }
    }
  }
  ${TextInputStyled} {
    border: 0;
    background-color: ${withOpacity(colors.light400, 0.4)};
    border-radius: 0.5em;
    line-height: 1.5;
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark500, 0.8)};`
    )}
    &:focus {
      outline: none;
    }
  }
  &.higherContrast {
    ${TextInputStyled} {
      background-color: ${colors.white};
      border-radius: 0.5em;
      ${darkModeLinariaCSS(`background-color: ${colors.darkest};`)}
      &:hover {
        border-color: ${colors.purple};
      }
    }
  }
  &.withIcon {
    ${TextInputStyled} {
      padding-left: 3.25em;
    }
    .SearchIcon {
      position: absolute;
      left: 1.5em;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }
  &.showShortcutHint {
    ${TextInputStyled} {
      padding-right: 1em;
    }
  }
  ${KeyboardShortcutKbd} {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const OpenInNewTabButton = styled.a`
  padding: 0.5em 2.4rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  border-left: 1px solid var(--borderColor);
  &:hover {
    color: ${colors.purple600};
    background-color: ${withOpacity(colors.purple, 0.05)};
  }
`;

const ShareButton = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
  padding: 0.5em 2.4rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  border-left: 1px solid var(--borderColor);
  cursor: pointer;
  ${onlyPhones} {
    display: none;
  }
  &:hover {
    color: ${colors.purple600};
    background-color: ${withOpacity(colors.purple, 0.05)};
  }
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;

const GlobalSearchBar = (props: Props) => {
  const siteContext = useSiteContext();
  const presentational = !props.formState;
  const isOnSearchPage = siteContext.location.pathname.match(/^\/search/);
  const showSearch = () => {
    if (presentational) {
      siteContext.toggleGlobalSearch(true);
    }
  };
  const query = props.formState?.[0].query ?? "";
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const prev = query;
    const next = (e.target as HTMLInputElement).value;
    props.formState?.[1]({
      ...props.formState[0],
      query: next,
    });
    if (prev !== next) props.onQueryUpdate?.(next);
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Tab" && presentational) return;
    if (presentational) showSearch();
    props.onKeyDown?.(e);
  };
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      props.onEscape?.();
    }
    if (e.key === "Tab" && presentational) return;
    props.onKeyUp?.(e);
  };
  const copyUrl = () => {
    copyToClipboard(window.location.href, "Copied page URL to clipboard.");
  };
  return (
    <GlobalSearchBarContainer
      className={cx(
        props.className,
        presentational ? "presentational" : "functional",
        props.withIcon && "withIcon",
        props.higherContrast && "higherContrast",
        props.showShortcutHint && "showShortcutHint",
        isOnSearchPage ? "isOnSearchPage" : "isNotOnSearchPage"
      )}
      onClick={showSearch}
    >
      <TextInput
        type="search"
        placeholder={props.placeholder ?? "Search"}
        autoFocus={props.autoFocus}
        formState={props.formState}
        name={props.formState ? "query" : undefined}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        width="100%"
        borderRadius="1em"
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck="false"
      />
      {props.withIcon && <SearchIcon13 />}
      {props.showShortcutHint && (
        <KeyboardShortcut cmdOrCtrl lighter={props.higherContrast}>
          K
        </KeyboardShortcut>
      )}
      {!presentational && props.showOpenInNewTabButton && (
        <OpenInNewTabButton
          href={`/search${query ? `?s=${query}` : ""}`}
          {...externalLinkAttr}
        >
          <span>
            Open in new tab <sup>↗</sup>
          </span>
        </OpenInNewTabButton>
      )}
      {!presentational && isOnSearchPage && (
        <ShareButton onClick={copyUrl}>
          <svg width="13" height="14" viewBox="0 0 13 14" fill="currentColor">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.98891 0.431262C6.70777 0.189579 6.29223 0.189579 6.01109 0.431262L2.44859 3.49376C2.13448 3.76378 2.09874 4.23731 2.36876 4.55141C2.63878 4.86552 3.11231 4.90126 3.42641 4.63124L5.75 2.63377V8.9375C5.75 9.35171 6.08579 9.6875 6.5 9.6875C6.91421 9.6875 7.25 9.35171 7.25 8.9375V2.63377L9.57359 4.63124C9.88769 4.90126 10.3612 4.86552 10.6312 4.55141C10.9013 4.23731 10.8655 3.76378 10.5514 3.49376L6.98891 0.431262ZM1.75 8.53125C1.75 8.11704 1.41421 7.78125 1 7.78125C0.585786 7.78125 0.25 8.11704 0.25 8.53125V10.5C0.25 12.0188 1.48122 13.25 3 13.25H10C11.5188 13.25 12.75 12.0188 12.75 10.5V8.53125C12.75 8.11704 12.4142 7.78125 12 7.78125C11.5858 7.78125 11.25 8.11704 11.25 8.53125V10.5C11.25 11.1904 10.6904 11.75 10 11.75H3C2.30964 11.75 1.75 11.1904 1.75 10.5V8.53125Z"
            />
          </svg>
          <span>Share this page</span>
        </ShareButton>
      )}
    </GlobalSearchBarContainer>
  );
};

export default GlobalSearchBar;
