import { styled } from "@linaria/react";
import { Bell } from "./Bell";
import { useRef } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { colors } from "../../../styles/colors.styles";
import gsap from "gsap";
import CustomEase from "gsap/CustomEase";

const Wrapper = styled.div`
  position: relative;
  > svg {
    &:first-child {
      position: relative;
      z-index: 2;
    }
    &:last-child {
      position: absolute;
      top: 27px;
      left: 5px;
    }
  }
`;

const BellPositioner = styled.div`
  position: absolute;
  top: 22px;
  left: 9px;
  z-index: 1;
`;

export const ClockWithBell = (props: {
  onReady: (dragBellFn: () => () => Promise<void>) => void;
}) => {
  return (
    <Wrapper>
      <ClockFace />
      <BellPositioner>
        <Bell onReady={props.onReady} />
      </BellPositioner>
      <Shadow />
    </Wrapper>
  );
};

const ClockHand = styled.g`
  opacity: 0;
  transform-origin: 22.5px 22.5px;
`;

const ClockFace = () => {
  const minuteHand = useRef<SVGPathElement>(null);
  const hourHand = useRef<SVGPathElement>(null);
  const secondHand = useRef<SVGPathElement>(null);
  useOnMount(() => {
    let isFirstTick = true;
    gsap.set([hourHand.current, minuteHand.current, secondHand.current], {
      scale: 0,
    });
    const tick = () => {
      const now = new Date();
      const hour = now.getHours() % 12;
      const minute = now.getMinutes();
      const second = now.getSeconds();
      const hourDeg = hour * 30 + minute * 0.5;
      const minuteDeg = minute * 6 + second * 0.1;
      const secondDeg = second === 0 ? 360 : second * 6;
      (isFirstTick ? gsap.to : gsap.set)(hourHand.current, {
        rotate: hourDeg,
        opacity: 1,
        scale: 1,
        transformOrigin: "center",
        duration: 0.3,
        ease: "power3.out",
      });
      (isFirstTick ? gsap.to : gsap.set)(minuteHand.current, {
        rotate: minuteDeg,
        opacity: 1,
        scale: 1,
        transformOrigin: "center",
        duration: 0.6,
        ease: "power3.out",
      });
      if (isFirstTick) {
        const duration = Math.min(1, second / 60 + 0.3);
        gsap.to(secondHand.current, {
          opacity: 1,
          scale: 1,
          duration: Math.max(duration * 0.3, 0.15),
        });
        gsap.to(secondHand.current, {
          rotate: secondDeg,
          opacity: 1,
          scale: 1,
          transformOrigin: "center",
          duration,
          ease: "power3.out",
          onComplete: () => {
            if (secondDeg === 360) gsap.set(secondHand.current, { rotate: 0 });
          },
        });
      } else {
        gsap.to(secondHand.current, {
          rotate: secondDeg,
          transformOrigin: "center",
          duration: 0.1,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          ease: CustomEase.create(
            "custom",
            "M0,0 C0.107,0.901 0.397,1.265 0.575,1.42 0.701,1.529 0.914,1.353 1,1 "
          ),
          onComplete: () => {
            if (secondDeg === 360) gsap.set(secondHand.current, { rotate: 0 });
          },
        });
      }
      isFirstTick = false;
    };
    tick();
    const interval = setInterval(tick, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <svg width="45" height="45" viewBox="0 0 45 45">
      <circle cx="22.5" cy="22.5" r="22" fill="#FFAF7E" stroke="#4C1812" />
      <circle cx="22.5" cy="22.5" r="17" fill="#803218" stroke="#4C1812" />
      <ClockHand ref={hourHand}>
        <circle cx="22.5" cy="22.5" r="22" fill="transparent" />
        <path
          d="M21 23C21 23.2761 21.2239 23.5 21.5 23.5H23.5C23.7761 23.5 24 23.2761 24 23V16.5H25C25.1733 16.5 25.3342 16.4103 25.4253 16.2629C25.5164 16.1155 25.5247 15.9314 25.4472 15.7764L22.9472 10.7764C22.8625 10.607 22.6894 10.5 22.5 10.5C22.3106 10.5 22.1375 10.607 22.0528 10.7764L19.5528 15.7764C19.4753 15.9314 19.4836 16.1155 19.5747 16.2629C19.6658 16.4103 19.8267 16.5 20 16.5H21V23Z"
          fill="#FFC8A3"
          stroke="#4C1812"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </ClockHand>
      <ClockHand ref={minuteHand}>
        <circle cx="22.5" cy="22.5" r="22" fill="transparent" />
        <path
          d="M21 23C21 23.2761 21.2239 23.5 21.5 23.5H23.5C23.7761 23.5 24 23.2761 24 23V13.5H25C25.1733 13.5 25.3342 13.4103 25.4253 13.2629C25.5164 13.1155 25.5247 12.9314 25.4472 12.7764L22.9472 7.77639C22.8625 7.607 22.6894 7.5 22.5 7.5C22.3106 7.5 22.1375 7.607 22.0528 7.77639L19.5528 12.7764C19.4753 12.9314 19.4836 13.1155 19.5747 13.2629C19.6658 13.4103 19.8267 13.5 20 13.5H21V23Z"
          fill="#FFC8A3"
          stroke="#4C1812"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </ClockHand>
      <ClockHand ref={secondHand}>
        <circle cx="22.5" cy="22.5" r="22" fill="transparent" />
        <path
          d="M22.5 23L22.5 8.5"
          stroke={colors.orange900}
          strokeWidth={3}
          strokeLinecap="round"
        />
        <path
          d="M22.5 23L22.5 8.5"
          stroke={colors.light100}
          strokeLinecap="round"
        />
      </ClockHand>
      <circle cx="22.5" cy="22.5" r="3" fill="#FFC8A3" stroke="#4C1812" />
    </svg>
  );
};

const Shadow = () => (
  <svg width="42" height="20" viewBox="0 0 42 20" fill="none">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.246796 2.18846C2.00802 13.6506 12.7247 21.5146 24.1876 19.7535V19.7508C34.2376 18.2067 41.5215 9.77736 41.9776 0H0.0253906C0.0608528 0.725011 0.134129 1.45522 0.246796 2.18846Z"
      fill="#32274B"
    />
  </svg>
);
