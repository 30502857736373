import { styled } from "@linaria/react";
import CenteredQuoteSection from "../general/CenteredQuoteSection";
import Spacing from "../layout/Spacing";
import { LogoJamf42 } from "../thirdPartyLogos/LogoJamf42";
import { AISubsection } from "./AISubsection";
import { ConnectAnythingSubsection } from "./ConnectAnythingSubsection";
import { DemoVideoSubsection } from "./DemoVideoSubsection";
import { EightActionsSubsection } from "./EightActionsSubsection";
import {
  BuildRunMonitorAfterHeading,
  BuildRunMonitorHeading,
  BuildRunMonitorSection,
  BuildRunMonitorSectionHeader,
  BuildRunMonitorSectionHeaderIllo,
} from "./_ProductPageSharedComponents";
import sectionIllustration from "./illustrations/buildSectionIllo.svg";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";

const Illo = styled.img`
  ${fromTablet} {
    margin-top: -10%;
  }
`;

export const ProductPageBuildSection = () => (
  <BuildRunMonitorSection id="build" theme="purple">
    <BuildRunMonitorSectionHeader>
      <BuildRunMonitorHeading heading="Build" />
      <BuildRunMonitorAfterHeading maxWidth="21em">
        Build any workflow or process – regardless of complexity. Get up and
        running in minutes, not days or weeks. No code. No custom development.
        Fast, flexible and secure by design.
      </BuildRunMonitorAfterHeading>
      <BuildRunMonitorSectionHeaderIllo>
        <Illo src={sectionIllustration} />
      </BuildRunMonitorSectionHeaderIllo>
    </BuildRunMonitorSectionHeader>

    <Spacing size="subsectionMargin" />

    <DemoVideoSubsection />
    <Spacing size="subsectionMargin" />

    <EightActionsSubsection />
    <Spacing size="subsectionMargin" />

    <AISubsection />
    <Spacing size="subsectionMargin" />

    <ConnectAnythingSubsection />
    <Spacing size="subsectionMargin" />

    <CenteredQuoteSection
      // cspell:disable-next-line
      sourceName="Andrew Katz"
      sourceWorkTitle="Senior Information Security Engineer"
      sourceCompanyLogo={<LogoJamf42 />}
      caseStudySlug="jamf"
      color="purple"
      darker
    >
      <p>
        I was impressed by how fast it was to drag and drop actions and
        configure them. It was significantly faster than writing the same thing
        in Python. That speed was the biggest thing for me.
      </p>
    </CenteredQuoteSection>
    <Spacing size="xl" />
  </BuildRunMonitorSection>
);
