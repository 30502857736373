import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
  uptoTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";
import { withOpacity } from "../../../styles/colorsV4.styles";
import { font } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";

export const ReportV2Blockquote = styled.blockquote`
  background-color: ${withOpacity(colors.white, 0.075)};
  border: 1px solid ${withOpacity(colors.white, 0.15)};
  margin: 2em 0;
  border-radius: ${rSize("radius")};
  padding: 1.5em 1em;
  max-width: 38em;
  ${uptoTabletLg} {
    text-align: center;
  }
  ${fromTablet} {
    padding: 2em;
  }
  &.green {
    background-color: ${withOpacity(colors.green, 0.075)};
    border: 1px solid ${withOpacity(colors.green, 0.15)};
  }
  &.purple {
    background-color: ${withOpacity(colors.purple, 0.075)};
    border: 1px solid ${withOpacity(colors.purple, 0.15)};
  }
  &.orange {
    background-color: ${withOpacity(colors.orange, 0.075)};
    border: 1px solid ${withOpacity(colors.orange, 0.15)};
  }
  &.pink {
    background-color: ${withOpacity(colors.pink, 0.075)};
    border: 1px solid ${withOpacity(colors.pink, 0.15)};
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ReportV2BlockquoteBody = styled.div`
  font-family: ${font("serif")};
  padding-bottom: 1em;
  p {
    ${onlyPhones} {
      font-size: 1.8rem !important;
      line-height: 1.4 !important;
    }
    ${fromTablet} {
      font-size: 2rem !important;
      line-height: 1.3 !important;
    }
  }
`;
