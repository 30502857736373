import { styled } from "@linaria/react";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";

export const ReportV2SectionSubheading = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  max-width: 28.5em;
  ${fromTablet} {
    font-size: 2rem;
  }
`;
