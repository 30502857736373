import { styled } from "@linaria/react";
import dayjs from "dayjs";
import { darken } from "polished";
import { DatoCmsProductUpdate } from "../../../graphql-types";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { flashNotice } from "../../utils/notice.utils";
import StandardArticleStructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";
import StandardArticle from "../articles/StandardArticle";
import StaticImageFrame, {
  StaticImageFrameFigure,
} from "../basic/StaticImageFrame";
import Spacing from "../layout/Spacing";
import FromTabletDiv from "../mediaQueries/FromTablet";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import { Hr } from "../utilities/Hr";
import HubProductUpdateContributorIconList, {
  HubProductUpdateContributorIconListWrap,
} from "./HubProductUpdateContributorIconList";
import { colors } from "../../styles/colors.styles";
import React, { memo } from "react";

type Props = {
  entry: DatoCmsProductUpdate;
  onCategoryIconClick?: (categoryId: string) => void;
};

const ProductUpdateFullEntry = memo(function ProductUpdateFullEntry({
  entry,
  onCategoryIconClick,
}: Props) {
  const image = entry.category?.image;
  const copyLink = () => {
    const link = `${process.env.GATSBY_SITE_URL}/whats-new/${entry.slug}`;
    try {
      navigator.clipboard.writeText(link);
      flashNotice(`Copied link to <strong>"${entry.title}"</strong>.`);
      scrollToHash({ useHash: `#${entry.slug!}`, doNotPushState: true });
    } catch (e) {
      flashNotice(
        `Your browser does not support copying to clipboard. Please copy the URL manually.`
      );
    }
  };
  return (
    <HubProductUpdateFullEntryCard>
      <CardHeader>
        {image && (
          <ImageWrap
            onClick={() => {
              if (entry.category?.id) onCategoryIconClick?.(entry.category?.id);
            }}
          >
            <StaticImageFrame
              src={image.url}
              intrinsicWidth={image.width}
              intrinsicHeight={image.height}
              renderedWidth={image.width}
              renderedHeight={image.height}
              aspectRatio={`${image.width}/${image.height}`}
            />
          </ImageWrap>
        )}
        <div>
          <h2 className="ProductUpdateArticleTitle" id={entry.slug!}>
            <WithCustomInlineFormatting as="span">
              {entry.title}
            </WithCustomInlineFormatting>
          </h2>
          <MetaLine>
            <ClickToCopyButton onClick={copyLink}>
              <time dateTime={entry.date}>
                {dayjs(`${entry.date}`).format("MMMM Do, YYYY")}
              </time>
            </ClickToCopyButton>
          </MetaLine>
        </div>
      </CardHeader>
      <CardBody>
        <FromTabletDiv>
          <Hr />
          <Spacing size="gap" />
        </FromTabletDiv>
        <StandardArticle>
          <StandardArticleStructuredTextWithCustomBlocks
            value={entry.content}
            lazyLoadImages
          />
        </StandardArticle>
      </CardBody>
      <CardFooter>
        <HubProductUpdateContributorIconList entry={entry} />
      </CardFooter>
    </HubProductUpdateFullEntryCard>
  );
});

const HubProductUpdateFullEntryCard = styled.section`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "header" "body" "footer";
  max-width: 100%;
  ${fromTablet} {
    grid-template-columns: minmax(200px, 1fr) auto;
    grid-template-areas: "header footer" "body body";
  }
  grid-gap: ${rSize("gap")};
  background-color: ${withOpacity(colorsV4.canvas700, 0.4)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colorsV4.warmBlack, 0.8)};`
  )}
  border-radius: ${rSize("radius")};
  padding: 1em;
  ${fromTablet} {
    padding: 2em;
  }
  h2 {
    scroll-margin-top: calc(var(--pageTopFixedElementsHeight, 0px) + 2em);
  }
`;

const ClickToCopyButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  text-align: center;
  transition: 0.1s;
  cursor: pointer;
  font-weight: 500;
  color: inherit;
  padding: 0;
  svg {
    display: block;
    width: 0.875em;
    height: 0.875em;
    margin-right: 0.25em;
  }
  time {
    color: inherit;
  }
  &:hover {
    color: ${colorsV4.purple};
  }
`;

const CardHeader = styled.header`
  grid-area: header;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-gap: 1em;
  ${fromTablet} {
    align-items: center;
  }
  h2 {
    font-size: 1.8rem;
    ${onlyPhones} {
      padding-right: 1.5em;
      margin-top: 0.25em;
    }
    ${fromTablet} {
      font-family: ${font("serif")};
      font-weight: 400;
      font-size: 2.4rem;
    }
    * + & {
      margin-top: ${rSize("gap")} !important;
    }
    code {
      color: ${darken(0.05, colorsV4.green)};
      ${darkModeLinariaCSS(`color: ${colorsV4.green};`)}
      font-size: 95%;
    }
    &:hover {
      ${ClickToCopyButton} {
        opacity: 1;
      }
    }
  }
`;

const MetaLine = styled.div`
  margin-top: 0.5em;
  font-weight: 500;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
`;

const ImageWrap = styled.div`
  overflow: hidden;
  position: relative;
  width: 3em;
  height: 3em;
  border-radius: 0.5em;
  transform: translate3d(0, 0, 0);
  background-color: ${withOpacity(colors.gray, 0.2)};
  ${fromTablet} {
    width: 4em;
    height: 4em;
  }
  ${StaticImageFrameFigure} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    max-width: unset;
    height: 120%;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
    }
  }
  cursor: pointer;
  &:hover {
    filter: brightness(1.025);
  }
`;

const CardBody = styled.div`
  grid-area: body;
  max-width: 100%;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    max-width: 75rem;
  }
`;

const CardFooter = styled.footer`
  ${fromTablet} {
    grid-area: footer;
    text-align: right;
    padding-top: 0.75em;
  }
  ${HubProductUpdateContributorIconListWrap} {
    ${fromTablet} {
      margin-left: auto;
    }
    margin-right: -0.1em;
  }
`;

export default ProductUpdateFullEntry;
