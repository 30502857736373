/* eslint-disable @typescript-eslint/no-misused-promises */
import { styled } from "@linaria/react";
import { isStructuredText } from "datocms-structured-text-utils";
import { Link } from "gatsby";
import React, { PropsWithChildren } from "react";
import { StructuredText } from "react-datocms";
import {
  DatoCmsArticle,
  DatoCmsCaseStudy,
  DatoCmsDocsPage,
  DatoCmsEvent,
  DatoCmsPage,
  DatoCmsProductUpdate,
  DatoCmsStructuredTextBlock,
} from "../../../graphql-types";
import { colors, withOpacity } from "../../styles/colors.styles";
import {
  blockRendererFactory,
  createDocsLinkFromRecursiveTreeQuery,
  customNodeRulesFactory,
} from "../../utils/datocms.utils";
import StandardArticle from "./StandardArticle";
import { isDevelopment } from "../../environment";
import InfoBox from "../general/InfoBox";

export type StructuredTextWithCustomBlocksOptions = {
  linkedHeadingMaxLevel?: 2 | 3 | 4 | 5 | 6 | "off";
  lazyLoadImages?: boolean;
};

type Props = StructuredTextWithCustomBlocksOptions & {
  className?: string;
  value:
    | DatoCmsArticle["content"]
    | DatoCmsCaseStudy["content"]
    | DatoCmsEvent["content"]
    | DatoCmsPage["content"]
    | DatoCmsDocsPage["content"]
    | DatoCmsProductUpdate["content"]
    | DatoCmsStructuredTextBlock["structuredText"];
};

const MissingRecordLinkHighlight = styled.mark`
  background-color: ${withOpacity(colors.orange, 0.3)};
  color: inherit;
`;

const StandardArticleStructuredTextWithCustomBlocks = (
  props: PropsWithChildren<Props> & {
    centered?: boolean;
  }
) => {
  return (
    <StandardArticle className={props.className} centered={props.centered}>
      <StructuredTextWithCustomBlocks {...props} asFragment />
    </StandardArticle>
  );
};

export const StructuredTextWithCustomBlocks = (
  props: PropsWithChildren<Props> & {
    asFragment?: boolean;
  }
) => {
  const contents = (
    <>
      {isStructuredText(props.value) ? (
        <StructuredText
          data={props.value}
          renderBlock={blockRendererFactory({
            lazyLoadImages: props.lazyLoadImages,
            linkedHeadingMaxLevel: props.linkedHeadingMaxLevel,
          })}
          renderLinkToRecord={({ record, children }) => {
            switch (record.__typename) {
              case "DatoCmsDocsPage":
                return (
                  <Link
                    to={createDocsLinkFromRecursiveTreeQuery(
                      record as unknown as DatoCmsDocsPage
                    )}
                    title={`${record.title}`}
                  >
                    {children}
                  </Link>
                );
              default:
                return (
                  <MissingRecordLinkHighlight data-type={record.__typename}>
                    {`${record.title}`}
                  </MissingRecordLinkHighlight>
                );
            }
          }}
          renderInlineRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsDocsPage":
                return (
                  <Link
                    to={createDocsLinkFromRecursiveTreeQuery(
                      record as unknown as DatoCmsDocsPage
                    )}
                    title={`${record.title}`}
                  >
                    {`${record.title}`}
                  </Link>
                );
              default:
                return (
                  <MissingRecordLinkHighlight data-type={record.__typename}>
                    {`${record.title}`}
                  </MissingRecordLinkHighlight>
                );
            }
          }}
          customNodeRules={customNodeRulesFactory({
            linkedHeadingMaxLevel: props.linkedHeadingMaxLevel,
          })}
        />
      ) : isDevelopment ? (
        <InfoBox color={colors.orange}>
          Unrecognized structured text object
        </InfoBox>
      ) : null}
      {props.children}
    </>
  );
  return props.asFragment ? (
    contents
  ) : (
    <div className={props.className}>{contents}</div>
  );
};

export default StandardArticleStructuredTextWithCustomBlocks;
