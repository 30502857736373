import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromTablet,
  onlyPhones,
  onlyTablets,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import Button, { ButtonGroupCenterFlex } from "../forms/Button";
import BookADemoButton from "../utilities/BookADemoButton";
import Spacing from "../layout/Spacing";
import { FedZeroTrustIllustration } from "./iconsAndIllustrations/FedZeroTrustIllustration";

const Inner = styled.div`
  border-radius: ${rSize("radius")};
  border: 1px solid ${colors.green200};
  text-align: center;
  padding: 3em 1em 0 1em;
  --offsetBottom: 72px;
  ${fromTablet} {
    padding: 6em 4em 0 4em;
    --offsetBottom: 96px;
  }
  ${fromDesktop} {
    --offsetBottom: 162px;
  }
  margin-bottom: var(--offsetBottom);
  h2 {
    ${fromDesktop} {
      max-width: ${widthInGrid(8)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const IllustrationWrap = styled.div`
  position: relative;
  margin-top: 4em;
  svg {
    margin-bottom: calc(var(--offsetBottom) * -1);
    max-width: 100%;
    height: auto;
  }
  [stroke] {
    ${onlyPhones} {
      stroke-width: 2;
    }
    ${onlyTablets} {
      stroke-width: 1.5;
    }
  }
`;

export const FedPageEndCTASection = () => (
  <PageSection>
    <Inner>
      <SectionHeading2 lighter>
        By security practitioners,
        <br />
        for security teams
      </SectionHeading2>
      <Spacing size="xl" />
      <ButtonGroupCenterFlex>
        <Button darker to="/product">
          Explore the product
        </Button>
        <BookADemoButton appearance="outlined" darker />
      </ButtonGroupCenterFlex>

      <IllustrationWrap>
        <FedZeroTrustIllustration />
      </IllustrationWrap>
    </Inner>
  </PageSection>
);
