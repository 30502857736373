export const HeartTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      opacity="0.2"
      d="M14.6266 5.38354C11.4304 4.72302 10.6513 7.4138 10.6513 7.4138C10.6513 7.4138 9.82373 4.73469 6.63712 5.44126C4.41047 5.93541 3.41962 8.54649 5.29478 11.6298C7.19234 14.7496 10.125 16.4042 10.7136 16.4042C11.1827 16.4042 14.2035 14.6984 16.0563 11.5518C17.888 8.44114 16.8602 5.84486 14.6266 5.38354Z"
      fill="currentColor"
    />
    <path
      d="M13.257 3.22787C9.77024 2.5073 8.92027 5.44269 8.92027 5.44269C8.92027 5.44269 8.01751 2.52003 4.54121 3.29083C2.11213 3.82991 1.03121 6.67836 3.07684 10.042C5.1469 13.4453 8.34615 15.2504 8.98829 15.2504C9.5 15.2504 12.7955 13.3896 14.8167 9.95691C16.8149 6.56343 15.6937 3.73112 13.257 3.22787Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
