import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import PersonPhotoIcon, {
  PersonPhotoIconContainer,
} from "../../general/PersonPhotoIcon";
import { NoBreak } from "../../typography/Nowrap";
import { ReportV2SectionSubheading } from "../components/ReportV2SectionSubheading";
import PhotoIconEoinHinchy from "../../../images/tines-photo-icon-eoin-hinchy.jpg";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import { ReportV2Footnotes } from "../components/ReportV2Footnotes";

const Header = styled.header`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    grid-template-columns: minmax(0, 1fr);
    justify-content: center;
    text-align: center;
    ${PersonPhotoIconContainer} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-auto-flow: column;
    align-items: center;
    grid-template-areas: "text photo";
    ${PersonPhotoIconContainer} {
      grid-area: photo;
    }
  }
`;

const HeaderInner = styled.div`
  ${fromTablet} {
    grid-area: text;
  }
`;

export const CisoAIIntro = () => {
  return (
    <ReportV2Section
      id="introduction"
      backgroundColor={colors.yellow100}
      accentColor={colors.orange600}
      color={colors.orange800}
    >
      <Header>
        <PersonPhotoIcon size="7em" src={PhotoIconEoinHinchy} />
        <HeaderInner>
          <ReportV2SectionHeadingMedium>
            A word from <NoBreak>Eoin Hinchy</NoBreak>
          </ReportV2SectionHeadingMedium>
          <ReportV2SectionSubheading>
            CEO and Co-Founder, Tines
          </ReportV2SectionSubheading>
        </HeaderInner>
      </Header>

      <hr />

      <ReportV2TextBlock columned largerText>
        <p>
          The explosion of AI has ignited both excitement and apprehension
          across various industries. While AI is undeniably having a positive
          impact on engineering and customer service teams, cybersecurity and IT
          practitioners remain cautious. Concerns about data privacy, the
          inflexibility of disparate tools, and the sensitive nature of many
          mission-critical workflows—which, more often than not, require some
          level of human oversight—fuel a deep mistrust of LLMs by these teams.
        </p>

        <p>
          Before starting Tines, I spent 15 years as a security leader at some
          of the world’s most targeted companies. So, I know firsthand just how
          frustrating and demoralizing it is to be burned by solutions that
          excel during vendor demonstrations but fall short when faced with
          real-world problems. Not only were these ‘solutions’ a complete waste
          of my budget, time, and mental load, but this post-demo disappointment
          happened much too often for my liking. That’s one of the reasons we
          took our time to launch{" "}
          <a href="/product/ai/" target="_blank">
            AI in Tines
          </a>{" "}
          - we’re evangelical about only shipping products and features that add
          value for our customers.
        </p>

        <p>
          We never want to contribute to any sort of hype and ultimately fail to
          deliver on our mission to power the world’s most important workflows.
          We’re very proud of our{" "}
          <a href="/blog/introducing-ai-in-tines/" target="_blank">
            AI features
          </a>{" "}
          and we’re just getting started - we have some very exciting launches
          to come! In the meantime, to better understand the mood around AI's
          true potential and separate the signal from the noise, we conducted a
          pulse survey among 53 Chief Information Security Officers (CISOs) from
          the US, UK, Australia, and the Netherlands between June 27th and July
          5th, 2024. Respondents came from{" "}
          <a href="https://www.svci.io/" {...externalLinkAttr}>
            Silicon Valley CISO Investments
          </a>
          * and Pollfish using organic sampling. This report explores their
          experiences, concerns, and challenges as they incorporate AI into
          their scaling security operations.
        </p>
      </ReportV2TextBlock>
      <hr />
      <ReportV2Footnotes>
        * Silicon Valley CISO Investments are investors in Tines.
      </ReportV2Footnotes>
    </ReportV2Section>
  );
};
