import { styled } from "@linaria/react";
import { cx } from "linaria";
import { rSize } from "../../styles/responsiveSizes.styles";
import { EventDirectoryItem } from "../../types/helper.types";
import EventDirectoryItemLink from "../events/EventDirectoryItemLink";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { AlignCenter } from "../typography/Align";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";

type Props = {
  events: EventDirectoryItem[];
};

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")} ${rSize("lg")};
  &.split {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const PartnerPageRelatedEventsSection = (props: Props) => {
  return (
    <PageSection>
      <AlignCenter>
        <SectionHeading2>
          Related <Serif>events</Serif>
        </SectionHeading2>
      </AlignCenter>
      <Spacing size="xl" />
      <Grid
        className={cx(props.events.length === 1 ? "single-column" : "split")}
      >
        {props.events.map(e => (
          <EventDirectoryItemLink
            key={e.id}
            item={e}
            featured={props.events.length === 1}
          />
        ))}
      </Grid>
    </PageSection>
  );
};

export default PartnerPageRelatedEventsSection;
