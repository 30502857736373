import { styled } from "@linaria/react";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";

export const DemoTextPlaceholderSpan = styled.span`
  background-image: linear-gradient(
    125deg,
    ${withOpacity(colorsV4.grey, 0.2)},
    ${withOpacity(colorsV4.grey, 0.1)}
  );
  border-radius: 0.2em;
  display: inline-block;
  height: 1em;
`;

export const DemoTextPlaceholder = (p: { width: string | number }) => (
  <DemoTextPlaceholderSpan style={{ width: p.width }} />
);
