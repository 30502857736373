import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { isTinesComBuildOnMain } from "../../environment";
import { aspectRatioChild } from "../../styles/aspectRatio.styles";
import { withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { StoryDescriptor } from "../../types/helper.types";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { resolveAfter, runAfter } from "../../utils/promises.utils";
import GenericIframe, {
  GenericIframeWrapper,
} from "../utilities/GenericIframe";
import LoadingIndicator from "../utilities/LoadingIndicator";
import gsap from "gsap";
import { clearAnimatedProps } from "../../utils/animations.utils";
import { getScrollbarWidth } from "../../utils/scrollbar.utils";
import { CustomEase } from "gsap/CustomEase";
import {
  hideIntercomButton,
  showIntercomButton,
} from "../../utils/intercom.utils";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import {
  fromDesktopMd,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import miniatureApp from "../../../static/images/miniature-app.png";
import { colors } from "../../styles/colors.styles";
import { zIndex } from "../../styles/zIndexes.styles";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
gsap.registerPlugin(CustomEase);

type Props = {
  story: StoryDescriptor;
  frameless?: boolean;
  flexibleRatio?: boolean;
  disableFullScreen?: boolean;
};

const FullScreenWrapper = styled.div``;

const FullScreenModeBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${withOpacity(colors.lightest, 0.5)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.darkest, 0.5)};`
  )}
  opacity: 0;
`;

export const StoryEmbedFrameInner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: calc(${rSize("radius")} - 1px);
  overflow: hidden;
  transform: translateZ(0);
  background-color: inherit;
`;

export const StoryEmbedFrameWrapper = styled.div`
  position: relative;
  border-radius: ${rSize("radius")};
  overflow: hidden;
  border: 1px solid ${colors.light400};
  ${darkModeLinariaCSS(`border-color: ${colors.dark500}`)}
  box-sizing: border-box;
  aspect-ratio: 3 / 4;
  ${fromTablet} {
    aspect-ratio: 16 / 9;
  }
  &.flexibleRatio {
    aspect-ratio: unset;
    min-height: 245px;
    ${fromTablet} {
      min-height: 480px;
    }
    ${fromDesktopMd} {
      min-height: 600px;
    }
  }
  @supports not (aspect-ratio: 16 / 9) {
    min-height: 245px;
    ${fromTablet} {
      min-height: 480px;
    }
    ${fromDesktopMd} {
      min-height: 600px;
    }
  }
  &.inline {
    position: relative;
    ${StoryEmbedFrameInner} {
      ${aspectRatioChild()};
    }
  }
  &.fullScreen {
    ${FullScreenWrapper} {
      z-index: ${zIndex("Modal")};
    }
    ${StoryEmbedFrameInner} {
      flex: 1 1 auto;
    }
  }
  &.frameless {
    border: 0;
  }
`;

const Header = styled.header<{ frameless?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${colors.light200};
  color: ${colors.dark500};
  border-bottom: 1px solid ${colors.light400};
  ${darkModeLinariaCSS(`
    background-color: ${colors.dark500};
    color: ${colors.white};
    border-bottom-color: ${colors.dark500};
  `)}
  text-align: center;
  padding: 0.5em;
  flex: 0 0 auto;
  .frameless & {
    background-color: transparent;
    height: 0;
    border: 0;
    padding: 0;
  }
  > * {
    flex: 0 0 auto;
  }
  ${onlyPhones} {
    overflow: hidden;
  }
`;
const HeaderContent = styled.div`
  flex: 1 1 auto;
  font-weight: 600;
  color: ${withOpacity(colors.dark500, 0.75)};
  ${darkModeLinariaCSS(`
    color: ${colors.white};
  `)}
`;

const FullScreenToggle = styled.button`
  appearance: none;
  background-color: ${colors.black};
  color: ${colors.white};
  border-radius: 50%;
  border: 0 ${colors.purple} solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  padding: 0;
  .frameless & {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 1;
  }
  &:hover {
    opacity: 0.8;
  }
  svg {
    display: block;
  }
`;

const EmbedWrapper = styled.div`
  flex: 1 1 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  ${GenericIframeWrapper} {
    border-radius: 0;
  }
`;

const Overlay = styled.div`
  background-color: transparent;
  border-radius: inherit;
  pointer-events: none;
`;

export const StoryEmbedFrameContentHolder = styled.div`
  background-color: ${colors.white};
  color: ${colors.dark500};
  ${darkModeLinariaCSS(`
    background-color: ${colors.dark500};
    color: ${colors.white};
  `)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${rSize("radius")};
  padding: 1.5em;
  ${cover()};
  ${aspectRatioChild()};
  p {
    max-width: 38em;
    font-weight: 500;
    text-align: center;
    opacity: 0.8;
  }
`;

const Explainer = styled.div`
  background-color: ${colors.dark500};
  color: ${colors.white};
  font-weight: 500;
  text-align: center;
  position: absolute;
  max-width: 90%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1em;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-size: 1.4rem;
  kbd {
    background-color: ${withOpacity(colors.white, 0.2)};
    text-transform: uppercase;
    font-size: 75%;
    font-weight: 700;
    padding: 0em 0.5em;
    border-radius: 0.25em;
    letter-spacing: 0.03em;
  }
`;

const NonLiveSiteNotice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  ${onlyPhones} {
    padding-left: 1em;
    padding-right: 1em;
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
  img {
    border: 1px solid var(--BorderColor);
    border-radius: 1em;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
const ease = CustomEase.create(
  "custom",
  "M0,0,C0.298,-0.002,0.222,0.446,0.32,0.718,0.408,0.962,0.557,1,1,1"
);

const StoryEmbedFrame = (props: Props) => {
  const [ready, setReady] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);
  const fullScreenRef = useRef(fullScreen);
  fullScreenRef.current = fullScreen;
  const [animatingClose, setAnimatingClose] = useState(false);
  const animatingCloseRef = useRef(animatingClose);
  animatingCloseRef.current = animatingClose;

  const [shouldShowUIHint, setShouldShowUIHint] = useState(true);

  const ref = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useHotkeys("space", e => {
    e.preventDefault();
  });

  const animateOpen = async () => {
    if (fullScreenRef.current) return;
    const boundingBox = outerRef.current!.getBoundingClientRect();
    const scrollbarWidth = getScrollbarWidth();
    const vw = window.innerWidth - scrollbarWidth;
    const vh = window.innerHeight;
    const from = {
      position: "fixed",
      top: boundingBox.top + 2,
      left: boundingBox.left + 2,
      right: vw - boundingBox.right + 2,
      bottom: vh - boundingBox.bottom + 2,
    };
    hideIntercomButton();
    setFullScreen(true);
    await resolveAfter();
    gsap.set(wrapperRef.current, {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    });
    gsap.set(innerRef.current, from);
    gsap.to(innerRef.current, {
      top: 16,
      left: 16,
      right: 16,
      bottom: 16,
      ease,
    });
    gsap.fromTo(backdropRef.current, { opacity: 0 }, { opacity: 1 });
  };

  const animateClose = () => {
    if (!fullScreenRef.current || animatingCloseRef.current) return;
    setAnimatingClose(true);
    const boundingBox = outerRef.current!.getBoundingClientRect();
    const scrollbarWidth = getScrollbarWidth();
    const vw = window.innerWidth - scrollbarWidth;
    const vh = window.innerHeight;
    gsap.fromTo(
      backdropRef.current,
      { opacity: 1 },
      { opacity: 0, duration: 0.75 }
    );
    const borderWidth = props.frameless ? 0 : 2;
    gsap.to(innerRef.current, {
      top: boundingBox.top + borderWidth,
      left: boundingBox.left + borderWidth,
      right: vw - boundingBox.right + borderWidth,
      bottom: vh - boundingBox.bottom + borderWidth,
      ease,
      duration: 0.75,
      onComplete: () => {
        clearAnimatedProps(wrapperRef.current, "position");
        setAnimatingClose(false);
        setFullScreen(false);
        clearAnimatedProps(innerRef.current, [
          "position",
          "top",
          "left",
          "bottom",
          "right",
        ]);
        showIntercomButton();
      },
    });
  };

  const toggleFullScreen = () => {
    if (!innerRef.current || !wrapperRef.current) return;
    const newValue = !fullScreen;
    if (newValue) {
      animateOpen();
    } else {
      animateClose();
    }
  };

  useOnMount(() => {
    const hideUIHintIn5Seconds = () => {
      runAfter(() => {
        setShouldShowUIHint(false);
      }, 5000);
    };
    const resizeHandler = () => {
      const isMobile = window.innerWidth < 768;
      if (isMobile) setShouldShowUIHint(false);
      else hideUIHintIn5Seconds();
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") animateClose();
    };
    const messageHandler = (e: MessageEvent<string>) => {
      if (e.data === "KEYDOWN_ESCAPE") animateClose();
    };
    window.addEventListener("keydown", keydownHandler, { capture: true });
    window.addEventListener("message", messageHandler);
    if (window.innerWidth < 768) {
      setReady(true);
      return;
    }
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) hideUIHintIn5Seconds();
    });
    (async () => {
      setReady(true);
      await resolveAfter(100);
      ref.current && observer.observe(ref.current);
    })();
    return () => {
      observer.disconnect();
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("keydown", keydownHandler);
      window.removeEventListener("message", messageHandler);
    };
  });

  return (
    <StoryEmbedFrameWrapper
      className={cx(
        fullScreen ? "fullScreen" : "inline",
        props.frameless && "frameless",
        props.flexibleRatio && "flexibleRatio"
      )}
      ref={outerRef}
    >
      {ready ? (
        <FullScreenWrapper ref={wrapperRef}>
          {fullScreen && (
            <FullScreenModeBackdrop
              ref={backdropRef}
              onClick={toggleFullScreen}
            />
          )}
          <StoryEmbedFrameInner ref={innerRef}>
            <Header frameless={props.frameless}>
              {props.frameless ? null : (
                <>
                  <span style={{ width: 32 }} />
                  <HeaderContent>
                    <p>Explore the story interactively</p>
                  </HeaderContent>
                </>
              )}
              {!props.disableFullScreen && (
                <div>
                  <FullScreenToggle onClick={toggleFullScreen}>
                    {fullScreen ? (
                      <svg width="14" height="14" viewBox="0 0 14 14">
                        <path
                          d="M7.99962 5.99998V5.49998V0.999977H8.99962V4.29287L12.6461 0.646423L13.3532 1.35353L9.70673 4.99998H12.9996L12.9996 5.99998H8.49962H7.99962ZM6.00004 7.99988V8.49988V12.9999H5.00004V9.70699L1.35359 13.3534L0.646484 12.6463L4.29293 8.99988H1.00004L1.00004 7.99988H5.50004H6.00004Z"
                          fill={colors.white}
                        />
                      </svg>
                    ) : (
                      <svg width="12" height="12" viewBox="0 0 12 12">
                        <path
                          d="M12 0V0.5V5H11V1.70711L7.35355 5.35355L6.64645 4.64645L10.2929 1L7 1V0H11.5H12ZM0 12V11.5V7H1L1 10.2929L4.64645 6.64645L5.35355 7.35355L1.70711 11L5 11V12L0.5 12H0Z"
                          fill={colors.white}
                        />
                      </svg>
                    )}
                  </FullScreenToggle>
                </div>
              )}
            </Header>
            <EmbedWrapper>
              {isTinesComBuildOnMain ? (
                <>
                  <GenericIframe
                    src={`${process.env.GATSBY_LIBRARY_TENANT_URL}/embedded_stories/${props.story.guid}`}
                    backgroundColor="transparent"
                    width="100%"
                    height="100%"
                    innerRef={iframeRef}
                  />
                  <Overlay>
                    {shouldShowUIHint && (
                      <Explainer ref={ref}>
                        <p>
                          Drag to pan around. Use <kbd>+</kbd> <kbd>-</kbd> to
                          control zoom level.
                        </p>
                      </Explainer>
                    )}
                  </Overlay>
                </>
              ) : (
                <NonLiveSiteNotice>
                  <img src={miniatureApp} width={168} height={116} />
                  <p>This story embed will only load on www.tines.com.</p>
                </NonLiveSiteNotice>
              )}
            </EmbedWrapper>
          </StoryEmbedFrameInner>
        </FullScreenWrapper>
      ) : (
        <>
          <StoryEmbedFrameContentHolder>
            <LoadingIndicator />
          </StoryEmbedFrameContentHolder>
        </>
      )}
    </StoryEmbedFrameWrapper>
  );
};

export default StoryEmbedFrame;
