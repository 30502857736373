const SkyTunnelIcon = () => {
  return (
    <svg width="143" height="219" viewBox="0 0 143 219" fill="none">
      <path
        d="M140.018 184.148C140.018 171.447 113.543 161.152 80.884 161.152C48.2254 161.152 21.75 171.448 21.75 184.148C21.75 185.84 21.75 192.312 21.75 194.004C21.75 206.704 48.2254 217 80.884 217C113.543 217 140.018 206.704 140.018 194.004C140.018 192.312 140.018 185.839 140.018 184.148Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
      />
      <path
        d="M80.884 207.144C113.543 207.144 140.018 196.848 140.018 184.148C140.018 171.448 113.543 161.152 80.884 161.152C48.2252 161.152 21.75 171.448 21.75 184.148C21.75 196.848 48.2252 207.144 80.884 207.144Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
      />
      <path
        d="M76.681 195.638C95.7044 200.183 112.099 199.795 113.3 194.77C114.501 189.745 100.052 181.987 81.029 177.441C62.0055 172.896 45.6107 173.284 44.41 178.309C43.2093 183.334 57.6576 191.092 76.681 195.638Z"
        fill="#32313B"
      />
      <path
        d="M80.8832 181.068C82.8177 181.068 84.6287 180.785 86.1855 180.293V31.5171C86.1855 28.5892 83.812 26.2148 80.8832 26.2148C77.9544 26.2148 75.5801 28.5892 75.5801 31.5171V180.294C77.1381 180.785 78.9483 181.068 80.8832 181.068Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5305 155.488C66.9705 154.295 66.9697 151.934 67.4428 150.274C67.8397 148.882 69.4466 145.104 75.404 142.741V107.637C65.7733 109.219 57.3834 112.719 50.4164 118.079C42.6789 124.032 37.2335 131.935 34.6691 140.932C30.1815 156.679 35.3495 173.021 47.8365 182.565C55.6021 188.5 66.4705 192.333 80.1394 193.957C85.997 194.654 91.0816 194.79 94.1691 194.79C95.8715 194.79 96.9672 194.748 97.2499 194.736L98.2586 160.606C90.1362 160.955 73.6792 159.423 68.5305 155.488Z"
        fill="#FABE64"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M97.7438 194.821C104.103 194.821 109.258 187.192 109.258 177.781C109.258 168.371 104.103 160.742 97.7438 160.742C91.3846 160.742 86.2295 168.371 86.2295 177.781C86.2295 187.192 91.3846 194.821 97.7438 194.821Z"
        fill="#FABE64"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
      />
      <path
        d="M98.4327 189.339C102.367 189.339 105.557 184.163 105.557 177.779C105.557 171.394 102.367 166.219 98.4327 166.219C94.4982 166.219 91.3086 171.394 91.3086 177.779C91.3086 184.163 94.4982 189.339 98.4327 189.339Z"
        fill="#B38847"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
      />
      <path
        d="M91.8446 179.316C91.8446 179.316 97.7254 169.961 104.006 170.763C104.006 170.763 99.9971 163.413 95.052 167.823C90.1077 172.233 91.8446 179.316 91.8446 179.316Z"
        fill="#32313B"
      />
      <path
        d="M119.389 74.7065C113.362 71.1378 105.992 68.4861 98.1884 65.6792C78.1912 58.4853 63.9237 52.4529 62.4755 40.2883C62.4122 39.751 62.3268 39.0358 62.2538 38.1914H28.1387C28.2249 40.1644 28.3837 42.2019 28.635 44.316C30.4761 59.7823 38.714 72.5635 53.119 82.3041C63.7946 89.5237 75.9376 93.8918 86.6524 97.7463C89.6165 98.8128 93.3736 100.164 96.5818 101.468C95.7275 101.951 94.7191 102.456 93.5372 102.966C91.0526 104.038 88.52 104.828 86.3626 105.392V140.191C89.5864 139.682 97.6788 138.157 106.147 134.635C129.189 125.05 133.92 109.432 134.689 100.975C135.251 94.7863 133.559 88.8241 129.793 83.7334C127.257 80.3031 123.854 77.3506 119.389 74.7065Z"
        fill="#FABE64"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.2715 94.1836V101.554L86.097 107.749L86.6539 97.7422L75.2715 94.1836Z"
        fill="#32313B"
      />
      <path
        d="M117.64 14.6461C114.036 7.16398 106.382 2 97.5206 2C86.0801 2 76.655 10.6116 75.3604 21.7049C74.2382 21.3806 73.0542 21.2041 71.8286 21.2041C64.8059 21.2041 59.1123 26.897 59.1123 33.9205C59.1123 40.9431 64.8059 46.6368 71.8286 46.6368C76.3038 46.6368 91.7376 46.6368 97.5206 46.6368C100.955 46.6368 112.232 46.6368 115.168 46.6368C124.055 46.6368 131.258 39.4332 131.258 30.5466C131.258 22.5012 125.353 15.8353 117.64 14.6461Z"
        fill="#E9E6FF"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M86.4968 45.9409H75.0029V54.2269H86.4968V45.9409Z"
        fill="#32313B"
      />
      <path
        d="M59.4415 28.7625C58.523 28.7625 57.6201 28.8319 56.7368 28.959C52.7356 24.8803 47.1638 22.3477 40.9987 22.3477C33.2355 22.3477 26.4141 26.3638 22.4846 32.4278C21.2192 32.132 19.9022 31.97 18.5466 31.97C9.02495 31.97 1.30664 39.6887 1.30664 49.21C1.30664 58.7317 9.02495 66.45 18.5466 66.45C22.4846 66.45 36.5781 66.45 40.9987 66.45C44.4435 66.45 56.2481 66.45 59.4415 66.45C69.8484 66.45 78.2852 58.0132 78.2852 47.6063C78.2852 37.1989 69.8484 28.7625 59.4415 28.7625Z"
        fill="#E9E6FF"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M37.3154 66.5209H59.4412C59.4412 66.5209 66.4502 66.7883 70.1917 63.3135C70.1917 63.3135 66.7172 72.9358 56.8279 72.9358C46.9377 72.9358 41.5925 72.9358 41.5925 72.9358L37.3154 66.5209Z"
        fill="#32313B"
      />
      <path
        d="M108.016 85.7678C108.016 85.7678 109.084 90.178 112.559 90.178C116.034 90.178 133.808 90.178 133.808 90.178C133.808 90.178 132.071 84.8324 129.933 84.1641C129.933 84.1641 129.264 84.832 125.523 84.832C121.78 84.832 108.016 85.7678 108.016 85.7678Z"
        fill="#32313B"
      />
      <path
        d="M126.255 56.6868C119.455 56.6868 113.735 61.2902 112.03 67.5504C111.366 67.4016 110.677 67.317 109.967 67.317C104.759 67.317 100.537 71.5384 100.537 76.7469C100.537 81.9554 104.759 86.1768 109.967 86.1768C112.797 86.1768 122.775 86.1768 126.255 86.1768C134.398 86.1768 141 79.5755 141 71.4322C141 63.2889 134.398 56.6868 126.255 56.6868Z"
        fill="#E9E6FF"
        stroke="#32313B"
        strokeWidth="2.00465"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SkyTunnelIcon;
