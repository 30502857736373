import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2SectionSubheading } from "../components/ReportV2SectionSubheading";
import PhotoIconEoinHinchy from "../../../images/tines-photo-icon-eoin-hinchy.jpg";
import PersonPhotoIcon, {
  PersonPhotoIconContainer,
} from "../../general/PersonPhotoIcon";
import { NoBreak } from "../../typography/Nowrap";
import Spacing from "../../layout/Spacing";

const Header = styled.header`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    grid-template-columns: minmax(0, 1fr);
    justify-content: center;
    text-align: center;
    ${PersonPhotoIconContainer} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-auto-flow: column;
    align-items: center;
    grid-template-areas: "text photo";
    ${PersonPhotoIconContainer} {
      grid-area: photo;
    }
  }
`;

const HeaderInner = styled.div`
  ${fromTablet} {
    grid-area: text;
  }
`;

export const GuideSecurityIntroSection = () => {
  return (
    <ReportV2Section
      id="a-word-from-eoin-hinchy"
      backgroundColor={colors.purple50}
    >
      <Header>
        <PersonPhotoIcon size="7em" src={PhotoIconEoinHinchy} />
        <HeaderInner>
          <ReportV2SectionHeadingMedium>
            A word from <NoBreak>Eoin Hinchy</NoBreak>
          </ReportV2SectionHeadingMedium>
          <ReportV2SectionSubheading>
            CEO and Co-Founder, Tines
          </ReportV2SectionSubheading>
        </HeaderInner>
      </Header>

      <Spacing size="lg" />

      <ReportV2TextBlock columned largerText>
        <p>
          In my 15 years as a security practitioner, both working on incident
          response and overseeing security teams, I saw a major problem: too
          much work and not enough staff. More specifically, I saw overworked
          staff doing repetitive, mundane tasks leading not only to burnout, but
          to human error that could cost a company millions.
        </p>

        <p>
          What we needed was a way to get away from monotonous tasks, and focus
          on projects that could add value to the organization, and put security
          analyst and engineer skills to better use.
        </p>

        <p>
          The solution? Workflow automation, which gives teams the tools to
          automate processes like phishing attack responses, suspicious logins,
          vulnerability management, and even employee onboarding and offboarding
          with a few drag-and-drop actions.
        </p>

        <p>
          Workflow automation has the potential to save teams 1000s of hours of
          work, freeing them up for high-impact projects, and improving total
          productivity.
        </p>

        <p>
          And with recent technology advancements, this potential has never been
          greater. AI is uniquely well-suited to workflow automation. Of course,
          there are very real concerns around privacy, security, and usability.
          But it’s exciting to see that, when done correctly, implementing AI in
          workflow automation can make workflows easier and faster to build and
          run, while keeping your data private and secure.
        </p>

        <p>
          We wrote this guide to serve as the ultimate resource on workflow
          automation. We’ll run through its evolution, explain why this
          technology is critical for security teams, outline how you can bring
          the power of workflow automation to your team, and share best
          practices. I hope it inspires you to embrace workflow automation as a
          solution to your team’s biggest security challenges.
        </p>
      </ReportV2TextBlock>
    </ReportV2Section>
  );
};
