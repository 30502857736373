import { styled } from "@linaria/react";

export const Hr = styled.hr<{ margin?: string | number; opacity?: number }>`
  background-color: currentColor;
  opacity: ${p => p.opacity ?? 0.1};
  height: 1px;
  border: none;
  display: block;
  margin: ${p => p.margin ?? 0};
`;

export const Separator = Hr;
