export const FlagTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      d="M14.4325 6H7.5C7.22386 6 7 6.22386 7 6.5V11.5C7 11.7761 7.22386 12 7.5 12H14.4325C14.8564 12 15.088 11.5056 14.8166 11.1799L13.2667 9.32009C13.1122 9.13467 13.1122 8.86533 13.2667 8.67991L14.8166 6.82009C15.088 6.49443 14.8564 6 14.4325 6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.5C6 3.77614 5.77614 4 5.5 4C5.22386 4 5 3.77614 5 3.5C5 3.22386 5.22386 3 5.5 3C5.77614 3 6 3.22386 6 3.5ZM6.91465 4C6.96992 3.84361 7 3.67532 7 3.5C7 2.67157 6.32843 2 5.5 2C4.67157 2 4 2.67157 4 3.5C4 4.15311 4.4174 4.70873 5 4.91465L5 10.5L5 10.5007V13.063C4.13739 13.285 3.5 14.0681 3.5 15H3C2.72386 15 2.5 15.2239 2.5 15.5C2.5 15.7761 2.72386 16 3 16H3.5H4.5H6.5H7.5H8.5C8.77614 16 9 15.7761 9 15.5C9 15.2239 8.77614 15 8.5 15H7.5C7.5 14.0681 6.86261 13.285 6 13.063V11H14C14.194 11 14.3705 10.8878 14.4528 10.7121C14.5351 10.5364 14.5083 10.3289 14.3841 10.1799L12.1509 7.5L14.3841 4.82009C14.5083 4.67105 14.5351 4.46361 14.4528 4.28792C14.3705 4.11223 14.194 4 14 4H6.91465ZM12.9325 10H6V5H12.9325L11.1159 7.17991C10.9614 7.36533 10.9614 7.63467 11.1159 7.82009L12.9325 10ZM6.5 15C6.5 14.4477 6.05228 14 5.5 14C4.94772 14 4.5 14.4477 4.5 15H6.5Z"
    />
  </svg>
);
