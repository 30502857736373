import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { serif } from "../../styles/fonts.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { widthInGrid } from "../../constants/globalGrid.constants";
import Button from "../forms/Button";
import { Link } from "gatsby";
import { css } from "linaria";
import Spacing from "../layout/Spacing";
import {
  fromDesktopMd,
  fromDesktopMl,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  mediaFromTo,
  onlyPhones,
  uptoTablet,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Inner = styled.div`
  background-color: ${colors.light100};
  border: 1px solid ${colors.light300};
  border-radius: ${rSize("radius")};
  padding: ${rSize("xl")};
  ${fromTabletLg} {
    padding: ${rSize("xl")} ${widthInGrid(1, 1)} ${widthInGrid(1, 1)};
  }
`;

const Header = styled.header`
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${uptoTabletLg} {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  h2 {
    font-family: ${serif};
    font-size: 2.8rem;
    font-weight: 400;
    ${uptoTablet} {
      margin-bottom: 0.75em;
    }
    ${mediaFromTo("tablet", "desktopMd")} {
      margin-right: 0.75em;
    }
    ${fromDesktopMd} {
      font-weight: 300;
      font-size: 3.6rem;
      letter-spacing: -0.02em;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${fromDesktopMl} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const libraryCollectionCard = css`
  border-radius: ${rSize("radius")};
  padding: 1em;
  text-decoration: none;
  color: ${colors.white};
  font-family: ${serif};
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  font-size: 1.8rem;
  ${mediaFromTo("desktopMl", "desktopLg")} {
    font-size: 1.6rem;
  }
  p {
    line-height: 1.2;
  }
  img {
    max-width: 128px;
    align-self: end;
    justify-self: end;
    transform: translate(18px, 12px);
  }
  &:hover {
    filter: brightness(1.05);
  }
`;

const FedLibrarySuggestionsSection = () => {
  return (
    <PageSection>
      <Inner>
        <Header>
          <h2>See more practical examples</h2>
          <Button appearance="outlined" darker to="/library">
            Visit library →
          </Button>
        </Header>
        <Spacing size="lg" />
        <Grid>
          <Link
            className={libraryCollectionCard}
            to="/library/use-cases/incidents-and-alerts"
            style={{ backgroundColor: colors.orange }}
          >
            <p>Incidents and alerts</p>
            <img src="https://www.datocms-assets.com/55802/1692371310-tines-library-incidents-and-alerts.svg" />
          </Link>
          <Link
            className={libraryCollectionCard}
            to="/library/threat-intelligence"
            style={{ backgroundColor: colors.purple }}
          >
            <p>Threat intelligence</p>
            <img src="https://www.datocms-assets.com/55802/1692363878-tines-library-threat-intelligence.svg" />
          </Link>
          <Link
            className={libraryCollectionCard}
            to="/library/phishing-attack-response"
            style={{ backgroundColor: colors.pink }}
          >
            <p>Phishing attack response</p>
            <img src="https://www.datocms-assets.com/55802/1692364394-tines-library-phishing-attack-response.svg" />
          </Link>
          <Link
            className={libraryCollectionCard}
            to="/library/compliance"
            style={{ backgroundColor: colors.green }}
          >
            <p>Compliance</p>
            <img src="https://www.datocms-assets.com/55802/1692969106-tines-library-compliance.svg" />
          </Link>
          <Link
            className={libraryCollectionCard}
            to="/library/vulnerability-management"
            style={{ backgroundColor: colors.orange }}
          >
            <p>Vulnerability management</p>
            <img src="https://www.datocms-assets.com/55802/1692721952-tines-library-vulnerability-management.svg" />
          </Link>
        </Grid>
      </Inner>
    </PageSection>
  );
};

export default FedLibrarySuggestionsSection;
