import { styled } from "@linaria/react";
import { DatoCmsCaseStudy } from "../../../graphql-types";
import {
  breakpoints,
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  fromTabletMd,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../styles/fonts.styles";
import StandardArticleStructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { GRID_GAP, widthInGrid } from "../../constants/globalGrid.constants";
import MaxPageContentWidth from "../layout/MaxPageContentWidth";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  PullQuoteBlockSource,
  PullQuoteBlockquote,
  PullQuoteBlockquoteInner,
  PullQuoteDoubleQuoteMarkWrapper,
} from "../blocks/PullQuote.block";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { getElementBoundingBoxFromPage } from "../../utils/boundingRect.utils";
import {
  StructuredTextBlockBackdrop,
  StructuredTextBlockContainer,
} from "../blocks/StructuredTextBlock.block";
import { useViewportSize } from "../../utils/window.utils";
import { StoryEmbedWrapper } from "../blocks/StoryEmbed.block";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";
import { useDocumentDimensions } from "../../utils/document.utils";

type ContentSectionProps = {
  accent: string;
  accentLighter: string;
  accentDarker: string;
};

const CaseStudyPageContentOuter = styled.div``;

const LineSvgDefs = styled.svg`
  position: fixed;
  top: -24px;
  left: -24px;
  pointer-events: none;
  opacity: 0;
`;
const LinesContainer = styled.div`
  position: absolute;
  top: calc(var(--intercomBannerHeight) * -1);
  left: 0;
  z-index: 1;
  svg {
    position: absolute;
  }
`;

const Inner = styled.div<ContentSectionProps>`
  h2 {
    text-wrap: pretty; // supported in Chrome but not in safari
    &:not(:first-child) {
      margin-top: ${rSize("xl")};
    }
    > span {
      font-size: 2.2rem;
      font-family: ${font("serif")};
      font-weight: 400;
      line-height: 1.1;
      color: ${p => p.accentDarker};
      ${fromDesktop} {
        display: block;
        border-radius: 0.5em;
        background-color: ${p => p.accentLighter};
        padding: 0.5em 0.5em 0.5em 0.75em;
        position: absolute;
        right: calc(100% + ${rSize("gap", 2)});
        text-align: right;
        width: ${widthInGrid(4, 1, 0, `- ${GRID_GAP * 2}px`)};
        max-width: max-content;
      }
      ${fromTabletMd} {
        font-size: 2.4rem;
      }
      > * {
        color: inherit;
        font-weight: inherit;
      }
      a {
        position: absolute;
        right: 100%;
        padding-right: 0.5em;
      }
    }
  }
  ${StructuredTextBlockContainer} {
    &.startsWithHeading {
      &:not(:first-child) {
        margin-top: ${rSize("sectionPadding")};
      }
      &:not(:last-child) {
        margin-bottom: ${rSize("sectionPadding")};
      }
      &.withBackdrop {
        ${fromDesktop} {
          padding-left: 0;
          padding-bottom: ${rSize("xl")};
          margin-right: ${widthInGrid(1, 1, -1)};
          padding-right: ${widthInGrid(1, 1)};
        }
        ${StructuredTextBlockBackdrop} {
          ${fromDesktop} {
            margin-left: ${widthInGrid(4, 1, -1)};
          }
        }
      }
    }
  }
  ${StoryEmbedWrapper} {
    &.showInFullPageWidth {
      ${fromDesktop} {
        position: relative;
        margin-top: 4em;
        margin-left: ${widthInGrid(4, 1, -1)};
        margin-right: ${widthInGrid(1, 1, -1)};
        margin-bottom: 4em;
        z-index: 2;
        background-color: ${colors.lightest};
      }
    }
  }

  ${PullQuoteBlockquote}[data-color="Warm Black"] {
    background-color: transparent;
    background-image: linear-gradient(
      160deg,
      ${withOpacity(colorsV4.warmBlack, 0.7)},
      ${withOpacity(colorsV4.warmBlack, 0.2)}
    );
    padding: 1em;
    border-radius: 2.4rem;
    ${fromDesktop} {
      margin-top: 4em;
      margin-left: ${widthInGrid(4, 1, -1)};
      margin-right: ${widthInGrid(1, 1, -1)};
      margin-bottom: 4em;
    }
    ${PullQuoteBlockquoteInner} {
      background-color: ${colorsV4.warmBlack};
      color: ${colorsV4.canvas};
      ${fromDesktop} {
        padding-top: 4em;
        padding-bottom: 4em;
        padding-left: ${widthInGrid(4, 1, undefined, "- 1em")};
        padding-right: ${widthInGrid(1, 1, undefined, "- 1em")};
      }
      ${fromDesktopMd} {
        padding-right: ${widthInGrid(2, 1)};
      }
    }
    ${PullQuoteDoubleQuoteMarkWrapper} {
      ${uptoDesktop} {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 2.4rem;
        svg {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
      ${fromDesktop} {
        margin: 0;
        position: absolute;
        right: calc(100% + ${rSize("gap", 1.5)} + 0.6rem);
        width: 8.1rem;
        height: 8.1rem;
      }
      ${fromDesktopMl} {
        right: calc(100% + ${rSize("gap", 2)} + 0.6rem);
      }
      background-color: ${p => p.accent};
      border-radius: 0.75em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ${PullQuoteBlockSource} {
      text-align: left;
    }
  }
`;

const ContentSection = styled.div`
  position: relative;
  ${fromDesktop} {
    padding-left: ${widthInGrid(4, 1)};
    padding-right: ${widthInGrid(1, 1)};
  }
  &.shaded {
    margin-top: ${rSize("xl")};
    padding-top: ${rSize("xl")};
    padding-bottom: calc(${rSize("xl")} + 1em);
    margin-bottom: ${rSize("xl")};
    background-color: ${colorsV4.canvas600};
    border-radius: 1em;
    ${fromTablet} {
      border-radius: 2em;
    }
  }
`;

type Props = {
  caseStudy: DatoCmsCaseStudy;
};

const h2Selectors = [
  ".HeaderContentHeaderCell",
  ".CaseStudyPageContent h2 > span",
  ".PullQuote[data-color='Warm Black'] .PullQuoteDoubleQuoteMark",
];

const CaseStudyPageContent = ({ caseStudy }: Props) => {
  const theme = getBrandColorTheme(caseStudy.color);
  const [lines, setLines] = useState<ReactNode[]>([]);
  const { vw } = useViewportSize();
  const { documentHeight } = useDocumentDimensions();
  const drawLines = useCallback(() => {
    try {
      if (vw < breakpoints.desktop) {
        setLines([]);
        return;
      }
      const elements = Array.from(
        document.querySelectorAll<HTMLDivElement | HTMLSpanElement>(
          h2Selectors.join(", ")
        )
      )
        .map(element => ({
          element,
          rect: getElementBoundingBoxFromPage(element),
        }))
        .sort((a, b) => a.rect.top - b.rect.top);
      const headerCellRect = elements[0].rect;
      const x = headerCellRect.left + headerCellRect.width / 2 - 19 / 2;
      const lines = elements.slice(1).map((curr, i) => {
        const prev = elements[i];
        const prevOffset = prev.rect;
        const currOffset = curr.rect;
        const y = Math.floor(prevOffset.bottom);
        const width = 19;
        const height = Math.ceil(currOffset.top + 1) - y;
        const gradientStart =
          i === 0 || prev.element.classList.contains("PullQuoteDoubleQuoteMark")
            ? "primary"
            : "secondary";
        const gradientEnd = curr.element.classList.contains(
          "PullQuoteDoubleQuoteMark"
        )
          ? "primary"
          : "secondary";
        const bothPrimary =
          gradientStart === "primary" && gradientEnd === "primary";
        const bothSecondary =
          gradientStart === "secondary" && gradientEnd === "secondary";
        const rectFill = bothPrimary
          ? theme.c500
          : bothSecondary
          ? theme.c100
          : `url(#linear-gradient-${gradientStart}-${gradientEnd})`;
        const svg = (
          <svg
            key={i}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            style={{
              top: y,
              left: x,
            }}
          >
            <use xlinkHref={`#line-terminal-${gradientStart}-top`} />
            <use
              xlinkHref={`#line-terminal-${gradientEnd}-bottom`}
              y={height - 9}
            />
            <rect
              x={width / 2 - 1.5}
              width={3}
              height={height}
              fill={rectFill}
            />
          </svg>
        );
        return svg;
      });
      setLines(lines);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [theme.c100, theme.c500, vw]);

  useEffect(drawLines, [drawLines, documentHeight]);

  useOnMount(() => {
    document.fonts.ready.then(drawLines);
  });
  return (
    <MaxPageContentWidth className="CaseStudyPageContent">
      <LineSvgDefs width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <symbol
            id="line-terminal-primary-top"
            width="19"
            height="8"
            viewBox="0 0 19 8"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 0V8C8 3.58172 4.41828 0 0 0H8ZM11 0V8C11 3.58172 14.5817 0 19 0H11Z"
              gradientTransform="rotate(90)"
              fill={theme.c500}
            />
          </symbol>
          <symbol
            id="line-terminal-secondary-top"
            width="19"
            height="8"
            viewBox="0 0 19 8"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 0V8C8 3.58172 4.41828 0 0 0H8ZM11 0V8C11 3.58172 14.5817 0 19 0H11Z"
              gradientTransform="rotate(90)"
              fill={theme.c100}
            />
          </symbol>
          <symbol
            id="line-terminal-primary-bottom"
            width="19"
            height="8"
            viewBox="0 0 19 8"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 8C14.5817 8 11 4.41828 11 2.54292e-07L11 8L19 8ZM0 8C4.41828 8 8 4.41828 8 -9.61651e-07L8 8L0 8Z"
              fill={theme.c500}
            />
          </symbol>
          <symbol
            id="line-terminal-secondary-bottom"
            width="19"
            height="8"
            viewBox="0 0 19 8"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 8C14.5817 8 11 4.41828 11 2.54292e-07L11 8L19 8ZM0 8C4.41828 8 8 4.41828 8 -9.61651e-07L8 8L0 8Z"
              fill={theme.c100}
            />
          </symbol>
          <linearGradient
            id="linear-gradient-primary-secondary"
            gradientTransform="rotate(90)"
          >
            <stop offset="0%" stopColor={theme.c500} />
            <stop offset="100%" stopColor={theme.c100} />
          </linearGradient>
          <linearGradient
            id="linear-gradient-secondary-primary"
            gradientTransform="rotate(90)"
          >
            <stop offset="0%" stopColor={theme.c100} />
            <stop offset="100%" stopColor={theme.c500} />
          </linearGradient>
        </defs>
      </LineSvgDefs>
      <LinesContainer>{lines}</LinesContainer>
      <CaseStudyPageContentOuter>
        <Inner
          accentLighter={theme.c100}
          accentDarker={theme.c700}
          accent={theme.c500}
        >
          <ContentSection>
            <StandardArticleStructuredTextWithCustomBlocks
              value={caseStudy.content}
            />
          </ContentSection>
        </Inner>
      </CaseStudyPageContentOuter>
    </MaxPageContentWidth>
  );
};

export default CaseStudyPageContent;
