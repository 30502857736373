import { styled } from "@linaria/react";
import { PropsWithChildren, ReactNode } from "react";
import { words } from "../../../utils/string.utils";
import { maxPageContentWidthStyleObject } from "../../../styles/maxPageContentWidth.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import {
  fromReportV2DesktopDesign,
  inReportV2MobileDesign,
} from "./reportsV2.helpers";
import { zIndex } from "../../../styles/zIndexes.styles";
import {
  fromDesktopMd,
  mediaFromTo,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const Layout = styled.div`
  ${fromReportV2DesktopDesign} {
    display: grid;
    ${maxPageContentWidthStyleObject}
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "${words("sidebar", 1)} ${words("body", 3)}";
    grid-gap: ${rSize("gap")};
    position: relative;
    margin-left: -4.8rem;
  }
  ${fromDesktopMd} {
    margin-left: auto;
  }
`;

const SidebarColumn = styled.div`
  ${inReportV2MobileDesign} {
    position: sticky;
    top: calc(var(--pageTopFixedElementsHeight, 0px) + 0.5em);
    left: 0;
    right: 0;
    z-index: ${zIndex("PageSidebar")};
    margin-bottom: 0.75em;
  }
  ${mediaFromTo("tablet", "tabletLg")} {
    display: flex;
    justify-content: center;
    top: calc(var(--pageTopFixedElementsHeight, 0px) - 0.5em);
  }
  ${fromReportV2DesktopDesign} {
    grid-area: sidebar;
    padding-bottom: ${rSize("xl")};
  }
`;

const BodyColumn = styled.div`
  ${fromReportV2DesktopDesign} {
    grid-area: body;
    padding-top: ${rSize("xl")};
    margin-left: ${widthInGrid(1, 1, -1)};
    margin-right: ${rSize("pageMargin", -1)};
    padding-right: ${rSize("widerPageMargin")};
  }
`;

export const ReportV2PageLayout = (
  props: PropsWithChildren<{ nav: ReactNode }>
) => {
  return (
    <Layout>
      <SidebarColumn>{props.nav}</SidebarColumn>
      <BodyColumn>{props.children}</BodyColumn>
    </Layout>
  );
};
