import { styled } from "@linaria/react";
import { DatoCmsPercentageStatCardsSection } from "../../../../graphql-types";
import { rSize } from "../../../styles/responsiveSizes.styles";
import PageSection from "../../reusableSections/PageSection";
import {
  brandColorThemeVar,
  getBrandColorThemeVariables,
} from "../../../styles/colors.styles";
import WithCustomInlineFormatting from "../../typography/WithCustomInlineFormatting";
import { font, serif } from "../../../styles/fonts.styles";
import {
  fromDesktopMd,
  fromTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Card = styled.div`
  background-color: ${brandColorThemeVar(100)};
  color: ${brandColorThemeVar(700)};
  border: 1px solid ${brandColorThemeVar(400)};
  padding: ${rSize("gap")} ${rSize("gap")} ${rSize("lg")};
  border-radius: ${rSize("radius")};
  text-align: center;
  display: grid;
  grid-template-areas: "percentage" "heading";
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-rows: minmax(auto, 128px) minmax(auto, 1fr);
  }
  ${fromDesktopMd} {
    grid-template-rows: minmax(auto, 256px) minmax(auto, 1fr);
  }
  h3 {
    grid-area: heading;
    font-family: ${font("sans")};
    font-weight: 500;
    line-height: 1.25;
    em {
      font-weight: 700;
    }
  }
`;

export const LPPercentageStatCardsSection = ({
  section,
}: {
  section: DatoCmsPercentageStatCardsSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Grid>
        {section.cards?.map((card, i) => (
          <Card key={i} style={getBrandColorThemeVariables(card?.color)}>
            <WithCustomInlineFormatting as="h3">
              {card?.heading}
            </WithCustomInlineFormatting>
            <Percentage value={card?.percentage ?? 0}></Percentage>
          </Card>
        ))}
      </Grid>
    </PageSection>
  );
};

const PercentageWrap = styled.div`
  grid-area: percentage;
  position: relative;
  padding: 1em;
  svg {
    width: auto;
    height: 100%;
    circle:nth-child(2) {
      transform-origin: center;
      transform-box: fill-box;
      transform: rotate(-90deg);
    }
  }
  span {
  }
`;

const Percentage = (props: { value: number }) => {
  const circumference = 2 * Math.PI * 58;
  return (
    <PercentageWrap>
      <svg width={120} height={120} viewBox="0 0 120 120">
        <circle
          cx={60}
          cy={60}
          r={58}
          stroke={brandColorThemeVar(200)}
          strokeWidth={4}
          fill="none"
        />
        <circle
          cx={60}
          cy={60}
          r={58}
          stroke={brandColorThemeVar(500)}
          strokeWidth={4}
          fill="none"
          strokeDasharray={`${(circumference * props.value) / 100} ${
            (circumference * (100 - props.value)) / 100
          }`}
          strokeLinecap="round"
        />
        <text
          x={60}
          y={60 + 38 / 3.25}
          textAnchor="middle"
          fontSize="38"
          fontFamily={serif}
          fontWeight={200}
          letterSpacing="-0.01em"
          fill={brandColorThemeVar(700)}
        >
          {Math.round(props.value)}%
        </text>
      </svg>
    </PercentageWrap>
  );
};
