/* eslint-disable no-console */
/* cspell:disable */

import { checkIfIsClientAndLive } from "../../../environment";
import { UnknownObject } from "../../../types/helper.types";
import { shouldLogGDPRDebugInfo } from "../gdpr";

declare global {
  interface Window {
    dataLayer: UnknownObject[];
    excludeGtagPaths?: RegExp[];
    gtag: (...params: unknown[]) => unknown;
  }
}

export const initGoogleTagManager = () => {
  if (shouldLogGDPRDebugInfo()) {
    console.log("– init Google Tag Manager");
  }
  if (!checkIfIsClientAndLive()) return;
  (function (w, d, l, i) {
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName("script")[0],
      j = d.createElement("script"),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode?.insertBefore(j, f);
  })(window, document, "dataLayer", "GTM-5Z8VZQ5");
};
