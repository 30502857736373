import { styled } from "@linaria/react";
import { cx } from "linaria";
import { CSSProperties, PropsWithChildren } from "react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = PropsWithChildren<{
  id?: string;
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  accentColor?: string;
  linkColor?: string;
  paddingOnDesktop?: string | number;
  centered?: boolean;
  largerHeading?: boolean;
}>;

export const ReportCardContainer = styled.div<{
  backgroundColor?: string;
  textColor?: string;
  accentColor?: string;
  paddingOnDesktop?: string | number;
}>`
  position: relative;
  background-color: ${p => p.backgroundColor ?? colorsV4.white};
  color: ${p => p.textColor ?? "inherit"};
  padding: 2em 1.5em;
  max-width: 100%;
  font-size: 1.6rem;
  border-radius: 1em;
  text-align: left;
  ${fromTablet} {
    padding: 3.2rem;
  }
  ${fromDesktop} {
    padding: ${p => p.paddingOnDesktop ?? "1.5em"};
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
  h3,
  h4 {
    color: ${p => p.accentColor ?? "var(--ThemeColorTextHighlight)"};
    + * {
      margin-top: 1em;
    }
  }
  &.largerHeading {
    h3 {
      font-size: 2.4rem;
      color: inherit;
      margin-bottom: 1em;
    }
    ${fromTablet} {
      padding: 4.8rem;
      h3 {
        font-size: 2.8rem;
        max-width: 20em;
        margin-bottom: 1em;
      }
      h4,
      p {
        max-width: 33.33em;
      }
      &.centered {
        h3,
        h4,
        p {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    ${fromDesktop} {
      padding: ${p => p.paddingOnDesktop ?? "2.5em"};
    }
    ${fromDesktopMd} {
      padding: ${widthInGrid(1)};
    }
  }
  &.centered {
    text-align: center;
  }
  blockquote {
    background-color: ${withOpacity(colorsV4.canvas600, 0.5)};
    margin: 1.5em 0;
    padding: 1.5em;
    border-radius: 1em;
    font-family: ${font("serif")};
    font-size: 1.8rem;
    ${fromTablet} {
      font-size: 2rem;
      padding: 2em 1em;
      margin: 2.5em 0;
    }
    p {
      line-height: 1.5;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    a {
      ${inParagraphLinkStyle(
        "var(--LinkColor, var(--ThemeColorTextHighlight))"
      )};
    }
  }
`;

const ReportCard = (props: Props) => {
  return (
    <ReportCardContainer
      id={props.id}
      className={cx(
        props.className,
        props.largerHeading && "largerHeading",
        props.centered && "centered"
      )}
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      accentColor={props.accentColor}
      paddingOnDesktop={props.paddingOnDesktop}
      style={
        {
          "--LinkColor": props.linkColor,
        } as CSSProperties
      }
    >
      {props.children}
    </ReportCardContainer>
  );
};

export const ReportCardInnerAligner = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(auto, 1fr) auto;
`;

export const ReportCardColumnedInner = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromDesktop} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  header {
    > * + * {
      margin-top: 1em;
    }
    ${fromDesktop} {
      padding-right: 1em;
    }
  }
  h3 {
    font-size: 2rem;
  }
  h4,
  .ReportChartContainer h4 {
    color: inherit;
    font-size: inherit;
  }
`;

export default ReportCard;
