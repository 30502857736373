import { styled } from "@linaria/react";
import Button, { ButtonGroupCenterFlex } from "../forms/Button";
import { GalaxyGateIcon } from "../illustrations/spots/GalaxyGateIcon";
import PageSection from "../reusableSections/PageSection";
import BookADemoButton from "../utilities/BookADemoButton";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { font } from "../../styles/fonts.styles";
import {
  fromDesktop,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { Paths } from "../../utils/pathBuilders.utils";
import SignUpButton from "../utilities/SignUpButton";

type Props = {
  heading?: string;
  body?: string;
  primaryAction?: "tourTheProduct" | "bookADemo" | "careers";
  wider?: boolean;
};

const Card = styled.div`
  text-align: center;
  background-color: ${colors.purple100};
  color: ${colors.purple800};
  border: 1px solid ${colors.purple300};
  padding: 3em 0 4em;
  border-radius: ${rSize("radius")};
  ${fromTablet} {
    padding: 4em 2em 5em;
  }
  h2 {
    font-family: ${font("serif")};
    margin-left: auto;
    margin-right: auto;
    max-width: 13em;
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: -0.01em;
    ${onlyPhones} {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
    ${fromTablet} {
      font-size: 3.6rem;
    }
    ${fromDesktop} {
      font-size: 4.2rem;
    }
  }
  p {
    max-width: 30em;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    ${onlyPhones} {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
  }
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
`;

const StandardCTASection = (props: Props) => {
  return (
    <PageSection theme="purple" wider={props.wider}>
      <Card>
        <GalaxyGateIcon />
        <h2>{props.heading ?? "Built by you, powered by Tines"}</h2>
        <p>
          {props.body ??
            "Talk to one of our experts to learn the unique ways your business can leverage Tines."}
        </p>
        <ButtonGroupCenterFlex>
          {props.primaryAction === "careers" ? (
            <Button to={Paths.careers()} appearance="outlined" darker>
              Careers at Tines
            </Button>
          ) : (
            <>
              {props.primaryAction === "bookADemo" ? (
                <>
                  <BookADemoButton darker />
                  <SignUpButton appearance="outlined" darker />
                </>
              ) : (
                <>
                  <Button to={Paths.product()} darker>
                    Tour the product
                  </Button>
                  <BookADemoButton appearance="outlined" darker />
                </>
              )}
            </>
          )}
        </ButtonGroupCenterFlex>
      </Card>
    </PageSection>
  );
};

export default StandardCTASection;
