export const BookWithLightbulbTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      d="M6.34141 7H5V16H13V11H11C10.7606 11 10.5408 10.9159 10.3687 10.7756C8.49101 10.2463 6.9868 8.82599 6.34141 7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9.04166C14 8.6847 14.1967 8.36235 14.4768 8.14113C15.4046 7.4086 16 6.27384 16 5C16 2.79086 14.2091 1 12 1C9.79086 1 8 2.79086 8 5H3V14.5C3 15.3284 3.67157 16 4.5 16H12V13H13C13.5523 13 14 12.5523 14 12V9.04166ZM13.8571 7.35629C14.555 6.80524 15 5.95488 15 5C15 3.34315 13.6569 2 12 2C10.3431 2 9 3.34315 9 5C9 5.95488 9.44499 6.80524 10.1429 7.35629C10.5907 7.70986 10.9848 8.28334 10.9996 9C10.9997 9 10.9994 9 10.9996 9L13 9C13.0001 9 12.9999 9 13 9C13.0148 8.28334 13.4093 7.70986 13.8571 7.35629ZM11 13C10.4477 13 10 12.5523 10 12V9.04166C10 8.6847 9.80335 8.36235 9.52319 8.14113C8.84693 7.60717 8.34723 6.85948 8.12602 6H4V13H11ZM13 10H11V12H13V10ZM4.5 14C4.22386 14 4 14.2239 4 14.5C4 14.7761 4.22386 15 4.5 15H11V14H4.5Z"
    />
  </svg>
);
