import { cx } from "linaria";
import { maxPageContentWidthCss } from "../../styles/maxPageContentWidth.styles";
import { PropsWithChildren } from "react";

type Props = {
  className?: string;
};

const MaxPageContentWidth = ({
  className,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div className={cx(maxPageContentWidthCss, className)}>{children}</div>
  );
};

export default MaxPageContentWidth;
