import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
  uptoTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { font } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2SectionSubheading } from "../components/ReportV2SectionSubheading";
import PhotoIconEoinHinchy from "../../../images/tines-photo-icon-eoin-hinchy.jpg";
import PersonPhotoIcon, {
  PersonPhotoIconContainer,
} from "../../general/PersonPhotoIcon";
import { NoBreak } from "../../typography/Nowrap";

const Header = styled.header`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    grid-template-columns: minmax(0, 1fr);
    justify-content: center;
    text-align: center;
    ${PersonPhotoIconContainer} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-auto-flow: column;
    align-items: center;
    grid-template-areas: "text photo";
    ${PersonPhotoIconContainer} {
      grid-area: photo;
    }
  }
`;

const HeaderInner = styled.div`
  ${fromTablet} {
    grid-area: text;
  }
`;

const Blockquote = styled.blockquote`
  column-span: all;
  font-family: ${font("serif")};
  background-color: ${withOpacity(colors.white, 0.075)};
  margin: 2em 0;
  border-radius: ${rSize("radius")};
  font-size: 1.8rem;
  padding: 1.5em 1em;
  ${uptoTabletLg} {
    text-align: center;
  }
  ${fromTablet} {
    padding: 2em;
    font-size: 2rem;
  }
`;

export const SOC2023IntroSection = () => {
  return (
    <ReportV2Section
      id="a-word-from-eoin-hinchy"
      backgroundColor={colors.purple900}
      color={colors.white}
    >
      <Header>
        <PersonPhotoIcon size="7em" src={PhotoIconEoinHinchy} />
        <HeaderInner>
          <ReportV2SectionHeadingMedium>
            A word from <NoBreak>Eoin Hinchy</NoBreak>
          </ReportV2SectionHeadingMedium>
          <ReportV2SectionSubheading>
            CEO and Co-Founder, Tines
          </ReportV2SectionSubheading>
        </HeaderInner>
      </Header>

      <Blockquote>
        <p>Security teams are getting restless.</p>
      </Blockquote>

      <ReportV2TextBlock columned largerText>
        <p>
          Before founding Tines, I spent 15 years in the SOC leading teams
          charged with protecting organizations from ever-evolving threats. Over
          that time, the challenge facing SOC analysts became harder, not
          easier: workloads are increasing, but teams aren’t growing alongside
          them. SOC analysts are burning out as a result of tedious and
          repetitive tasks. In the best-case scenario, staff will leave in
          search of new opportunities and leave their previous organizations
          scrambling to replace them. In the worst case, their burnout will lead
          to human error that could cost a company millions.
        </p>

        <p>
          Last year, we examined these issues in detail in our first “Voice of
          the SOC Analyst” report. Our survey found that while SOC teams were
          passionate and engaged in what they do, they were plagued by endless
          manual tasks, inefficient processes, and overwhelming alert fatigue —
          all preventing them from focusing on high-impact work. The same holds
          true in 2023.
        </p>

        <p>
          For the second edition of the “Voice of the SOC,” Tines surveyed 900
          security professionals. We expanded the scope beyond the United States
          to include Europe and sought perspectives from security leaders up to
          and including the C-suite, rather than just analysts.
        </p>

        <p>
          Like many, security teams have felt the added pressure of economic
          instability over the past 12 months. They were asked to do more with
          less, as business leaders scrutinized every line on the balance sheet.
        </p>

        <p>
          This year’s data reveals that overall job satisfaction in the SOC
          remains high — security practitioners love the work they do. However,
          burnout is taking its toll. Leaders continue to feel their teams are
          understaffed and don’t have access to the tools that could automate
          the most mundane aspects of their work. The bottom line: more than
          half of respondents, across job levels, say they’re likely to switch
          jobs in the coming year.
        </p>

        <p>
          This should be an alarm bell to business leaders. With both
          cyberattacks and skill shortages increasing, staff retention in the
          SOC is mission critical. The following report digs into the factors
          that undermine morale and offers practical solutions to help alleviate
          burnout and empower staff to do their best work.
        </p>

        <p>
          We hope you find it useful in your SOC in 2023 and as you plan for
          2024.
        </p>
      </ReportV2TextBlock>
    </ReportV2Section>
  );
};
