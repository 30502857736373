import { styled } from "@linaria/react";
import chroma from "chroma-js";
import gsap from "gsap";
import { cx } from "linaria";
import { useRef, useState } from "react";
import { uptoPhoneLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { Action } from "../../../types/tines.types";
import { clearAnimatedProps } from "../../../utils/animations.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { runAfter } from "../../../utils/promises.utils";
import { useViewportSize } from "../../../utils/window.utils";
import Spacing from "../../layout/Spacing";
import {
  StoryboardDecorationComponentProps,
  useStoryboardContext,
} from "../../storyboard/StoryboardContext";
import ConfettiScreen from "../ConfettiScreen";
import GradientBorder from "../GradientBorder";
import { useDemoStoryContext } from "./DemoStoryContext";
import { createDemoStoryMockEventEmitter } from "./demoStoryMockEventEmitter";

const DemoStoryDropZoneWrap = styled.div`
  background-color: transparent;
  color: ${withOpacity(colorsV4.purple, 0.8)};
  border: 2px dashed transparent;
  border-radius: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @keyframes dropzoneGlow {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  transform: scale(1.05);
  transition: transform 0.2s;
  opacity: 0;
  ${uptoPhoneLg} {
    border-color: ${withOpacity(colorsV4.purple, 0.2)};
  }
  &.dragging {
    animation: dropzoneGlow 1s infinite alternate-reverse forwards;
    transition: 0.1s;
    background-color: ${withOpacity(colorsV4.orange, 0.2)};
    color: ${withOpacity(colorsV4.orange, 0.8)};
    border-color: ${withOpacity(colorsV4.orange, 0.2)};
    transform: scale(1);
    opacity: 1;
    &:hover {
      background-color: ${withOpacity(colorsV4.orange, 0.3)};
      transform: scale(1.05);
    }
  }
  &.dropped {
    background-color: ${withOpacity(colorsV4.blue, 0.2)};
    color: ${withOpacity(colorsV4.blue, 0.8)};
    border-color: ${withOpacity(colorsV4.blue, 0.2)};
    opacity: 1;
    pointer-events: none;
  }
  &.hidden {
    @keyframes dropzoneExit {
      from {
        opacity: 0.75;
        transform: scale(1.05);
      }
      to {
        opacity: 0;
        transform: scale(0.5);
      }
    }
    animation: dropzoneExit 0.25s forwards;
  }
`;

const ArrowSvg = styled.svg`
  @keyframes dropzoneArrowBob {
    from {
      transform: translateY(-3px);
    }
    to {
      transform: translateY(3px);
    }
  }
  .dragging & {
    animation: dropzoneArrowBob 1s infinite alternate-reverse forwards;
  }
  path {
    fill: ${withOpacity(colorsV4.purple, 0.2)};
    .dragging & {
      fill: ${withOpacity(colorsV4.orange, 0.2)};
    }
    .dropped & {
      fill: ${withOpacity(colorsV4.blue, 0.2)};
    }
  }
`;

const DemoStoryDropZone = (
  props: StoryboardDecorationComponentProps & {
    maxEvents: number;
    dropZoneActionNameMatcher: string;
    possibleTriggerValues: [string, string];
  }
) => {
  const demoContext = useDemoStoryContext();
  const { vw } = useViewportSize();
  const ref = useRef<HTMLDivElement>(null);
  const [tl] = useState(
    gsap.timeline({
      paused: true,
    })
  );
  const storyContext = useStoryboardContext();
  useOnMount(() => {
    const handleWindowResize = () => {
      storyContext.resetView();
    };
    const handleWindowPointerUp = (e: PointerEvent) => {
      if (
        !demoContext.isDragging ||
        demoContext.hasDropped ||
        demoContext.isBouncingBack ||
        !ref.current
      )
        return;
      const boundingBox = ref.current.getBoundingClientRect();
      if (
        e.clientX < boundingBox.left - 24 ||
        e.clientX > boundingBox.right + 24 ||
        e.clientY < boundingBox.top - 24 ||
        e.clientY > boundingBox.bottom + 24
      ) {
        return;
      }
      tl.clear();
      tl.seek(0);
      const scheduleActionFlashAnimation = (
        action: Action,
        animationDelay = 0
      ) => {
        const actionEl = document.querySelector(
          `.Storyboard[data-id="${storyContext.id}"] .ActionEntry[data-action-index="${action.index}"]`
        );
        if (!actionEl) return;
        action.outputLinks.forEach((link, i) => {
          const linkEl = document.querySelector<SVGPathElement>(
            `.Storyboard[data-id="${storyContext.id}"] .StoryboardActionLink[data-link-index="${link.index}"] path`
          );
          tl.fromTo(
            linkEl,
            {
              filter: "brightness(1.25)",
            },
            {
              filter: "brightness(1)",
              duration: 1.375,
              onComplete: () => {
                clearAnimatedProps(linkEl, ["filter"]);
              },
            },
            animationDelay + 0.025
          );
          gsap.set(linkEl, { filter: "brightness(1)", scale: 1 });
          if (link.receiver)
            scheduleActionFlashAnimation(
              link.receiver,
              animationDelay + 0.05 + i * 0.01
            );
        });
        tl.fromTo(
          actionEl,
          {
            filter: "brightness(1.25)",
          },
          {
            filter: "brightness(1)",
            duration: 1.375,
            onComplete: () => {
              clearAnimatedProps(actionEl, ["filter"]);
            },
          },
          animationDelay
        );
        gsap.set(actionEl, { filter: "brightness(1)", scale: 1 });
      };
      if (demoContext.isDragging) {
        demoContext.setHasDropped(true);
        runAfter(() => {
          storyContext.story.heads.forEach((headAction, i) => {
            scheduleActionFlashAnimation(headAction, i * 0.075);
          });
          tl.play();
          demoContext.eventEmitters.forEach(emitter => emitter.dispose());
          demoContext.setEventEmitters(
            storyContext.story.heads.map(headAction => {
              return createDemoStoryMockEventEmitter({
                demoContext,
                storyContext,
                initialAction: headAction,
                maxEvents: props.maxEvents,
                dropZoneActionNameMatcher: props.dropZoneActionNameMatcher,
                possibleTriggerValues: props.possibleTriggerValues,
              });
            })
          );
        });
      }
    };
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("pointerup", handleWindowPointerUp);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("pointerup", handleWindowPointerUp);
      tl.kill();
      demoContext.eventEmitters.forEach(emitter => emitter.dispose());
    };
  });

  return (
    <DemoStoryDropZoneWrap
      className={cx(
        "DemoStoryDropZone",
        demoContext.hasDropped && "hidden",
        demoContext.isDragging && "dragging",
        demoContext.hasDropped && "dropped"
      )}
      style={props.style}
      ref={ref}
    >
      {(demoContext.isDragging || demoContext.hasDropped) && (
        <>
          <ArrowSvg width="20" height="19" viewBox="0 0 20 19" fill="none">
            <path
              d="M12.5 0.5H7.5C6.94772 0.5 6.5 0.947715 6.5 1.5V6.5C6.5 7.05228 6.05228 7.5 5.5 7.5H1.91421C1.02331 7.5 0.577142 8.57714 1.20711 9.20711L9.29289 17.2929C9.68342 17.6834 10.3166 17.6834 10.7071 17.2929L18.7929 9.20711C19.4229 8.57714 18.9767 7.5 18.0858 7.5H14.5C13.9477 7.5 13.5 7.05228 13.5 6.5V1.5C13.5 0.947715 13.0523 0.5 12.5 0.5Z"
              stroke="currentColor"
            />
          </ArrowSvg>
          <Spacing size=".5em" />
          <svg width="48" height="12" viewBox="0 0 48 12" fill="none">
            <path
              d="M3.05259 2.74684C3.59341 1.10747 5.12494 0 6.85122 0H41.1488C42.8751 0 44.4066 1.10747 44.9474 2.74684L46.7003 8.06013C47.3405 10.001 45.895 12 43.8513 12H4.14872C2.10496 12 0.65946 10.001 1.29975 8.06013L3.05259 2.74684Z"
              fill={colorsV4.white}
              fillOpacity={0.6}
            />
            <path
              d="M6.85122 0.25H41.1488C42.7672 0.25 44.203 1.28825 44.71 2.82517L46.4628 8.13846C47.0498 9.9176 45.7247 11.75 43.8513 11.75H4.14872C2.27527 11.75 0.950232 9.91759 1.53716 8.13845L3.29 2.82516C3.79703 1.28825 5.23283 0.25 6.85122 0.25Z"
              stroke="currentColor"
              strokeOpacity="0.6"
              strokeWidth="0.5"
            />
            <path
              d="M3.81142 7.40345L4.84213 4.10518C5.23352 2.85275 6.39342 2 7.70557 2H12.0901C13.3424 2 14.2867 3.13775 14.0559 4.36858L13.4588 7.55287C13.1928 8.97178 11.9539 10 10.5102 10H5.72038C4.3711 10 3.40896 8.69131 3.81142 7.40345Z"
              fill="currentColor"
            />
            <path
              d="M28 5H17C16.4477 5 16 4.55228 16 4C16 3.44772 16.4477 3 17 3H28C28.5523 3 29 3.44772 29 4C29 4.55228 28.5523 5 28 5Z"
              fill="currentColor"
            />
            <path
              opacity="0.5"
              d="M15.1941 7.8356L15.3607 6.8356C15.4411 6.35341 15.8583 6 16.3471 6H32.6529C33.1417 6 33.5589 6.35341 33.6393 6.8356L33.8059 7.8356C33.9075 8.44513 33.4375 9 32.8195 9H16.1805C15.5625 9 15.0925 8.44513 15.1941 7.8356Z"
              fill="currentColor"
            />
            <path
              opacity="0.5"
              d="M34.8607 8.1644L34.6941 7.1644C34.5925 6.55487 35.0625 6 35.6805 6H42.2792C42.7097 6 43.0918 6.27543 43.2279 6.68377L43.5613 7.68377C43.7771 8.3313 43.2951 9 42.6126 9H35.8471C35.3583 9 34.9411 8.64659 34.8607 8.1644Z"
              fill="currentColor"
            />
          </svg>
        </>
      )}
      {demoContext.isDragging && (
        <GradientBorder
          colors={
            demoContext.hasDropped
              ? [colorsV4.blue, chroma(colorsV4.blue).brighten(1.5).hex()]
              : [colorsV4.orange, chroma(colorsV4.yellow).brighten(1).hex()]
          }
          animateIn
          spin
        />
      )}
      {demoContext.hasDropped && (
        <>
          <ConfettiScreen amount={vw > 768 ? 100 : 75} />
          <ConfettiScreen targetRef={ref} amount={vw > 768 ? 75 : 45} />
        </>
      )}
    </DemoStoryDropZoneWrap>
  );
};

export default DemoStoryDropZone;
