import { styled } from "@linaria/react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { useCallback, useRef, useState } from "react";
import { clamp } from "lodash-es";
import gsap from "gsap";
import { flashPageBranch } from "./AppStoryboard";

const BellSvg = styled.svg`
  transform-origin: center top;
  touch-action: none;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;
const ClapperGroup = styled.g``;

export const Bell = (props: {
  onReady: (dragBellFn: () => () => Promise<void>) => void;
}) => {
  const [ready, setReady] = useState(false);
  const readyRef = useRef(ready);
  readyRef.current = ready;
  const bellRef = useRef<SVGSVGElement>(null);
  const clapperRef = useRef<SVGGElement>(null);
  const tweens = useRef<ReturnType<typeof gsap["to"]>[]>([]);
  const rotateTo = useCallback(
    (deg: number, duration = 0.01) =>
      new Promise(resolve => {
        gsap.to(bellRef.current, {
          rotate: deg,
          duration,
          onComplete: resolve,
        });
        gsap.to(clapperRef.current, {
          rotate: deg * 0.3,
          transformOrigin: "13.5px 41px",
          duration: 0.3,
        });
      }),
    []
  );
  const drag = useCallback(
    (delta: number, duration = 0.01) =>
      new Promise(resolve => {
        gsap.to(bellRef.current, {
          y: Math.min(delta, 5),
          duration,
          onComplete: resolve,
        });
      }),
    []
  );

  const bounceBack = useCallback(() => {
    tweens.current.push(
      gsap.to(bellRef.current, {
        rotate: 0,
        ease: "elastic",
        duration: 1.35,
      }),
      gsap.to(bellRef.current, {
        y: 0,
        ease: "back",
        duration: 0.3,
      }),
      gsap.to(clapperRef.current, {
        rotate: 0,
        ease: "elastic",
        duration: 2,
      })
    );
    flashPageBranch();
  }, []);
  const wiggle = useCallback(() => {
    const deg =
      (10 * (Math.random() - 0.5) + 5) * Math.sign(Math.random() - 0.5);
    const currentDeg = parseInt(
      `${gsap.getProperty(bellRef.current, "rotate")}`
    );
    rotateTo(currentDeg + deg, Math.abs((deg - currentDeg) * 0.02) + 0.1).then(
      () => {
        bounceBack();
      }
    );
  }, [bounceBack, rotateTo]);
  const [grabbing, setGrabbing] = useState(false);
  const handlePointerDown = useCallback(
    (e: React.PointerEvent) => {
      if (!readyRef.current) return;
      setGrabbing(true);
      e.preventDefault();
      tweens.current.forEach(t => {
        t.kill();
      });
      tweens.current.length = 0;
      let totalDeltaX = 0;
      let totalDeltaY = 0;
      let prevClientX = e.clientX;
      let prevClientY = e.clientY;
      const handlePointerMove = (e: PointerEvent) => {
        e.preventDefault();
        const deltaX = e.clientX - prevClientX;
        const deltaY = e.clientY - prevClientY;
        prevClientX = e.clientX;
        prevClientY = e.clientY;
        totalDeltaX += deltaX;
        totalDeltaY += deltaY;
        rotateTo(clamp(totalDeltaX, -60, 60) * -0.5);
        drag(clamp(totalDeltaY * 0.25, 0, 6));
      };
      const end = () => {
        window.removeEventListener("pointermove", handlePointerMove);
        window.removeEventListener("pointerup", end);
        window.removeEventListener("blur", end);
        setGrabbing(false);
        if (Math.abs(totalDeltaX) < 5) {
          wiggle();
        } else {
          bounceBack();
        }
      };
      window.addEventListener("pointermove", handlePointerMove);
      window.addEventListener("pointerup", end, { once: true });
      window.addEventListener("blur", end, { once: true });
    },
    [rotateTo, drag, wiggle, bounceBack]
  );

  useOnMount(() => {
    props.onReady(() => async () => {
      drag(5, 0.35);
      await rotateTo(-17, 0.35);
      bounceBack();
      setReady(true);
    });
    return () => {};
  });

  return (
    <>
      {grabbing && <style children={`:root { cursor: grabbing }`} />}
      <BellSvg
        width="27"
        height="68"
        viewBox="0 0 27 68"
        fill="none"
        ref={bellRef}
        onPointerDown={handlePointerDown}
      >
        <rect
          x="12.0264"
          y="0.891907"
          width="3"
          height="36.4169"
          fill="#FD975D"
          stroke="#803218"
        />
        <path
          d="M17.6328 39C17.6328 39.6092 17.2624 40.222 16.5374 40.7053C15.816 41.1863 14.7905 41.5 13.6328 41.5C12.4751 41.5 11.4496 41.1863 10.7282 40.7053C10.0032 40.222 9.63281 39.6092 9.63281 39C9.63281 38.3908 10.0032 37.778 10.7282 37.2947C11.4496 36.8137 12.4751 36.5 13.6328 36.5C14.7905 36.5 15.816 36.8137 16.5374 37.2947C17.2624 37.778 17.6328 38.3908 17.6328 39Z"
          fill="#FFC8A3"
          stroke="#803218"
        />
        <ClapperGroup ref={clapperRef}>
          <circle cx="13.5254" cy="60.5" r="5" fill="transparent" />
          <circle
            cx="13.5254"
            cy="60.5"
            r="4"
            fill="#FFAF7E"
            stroke="#803218"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.15234 59.4328C9.63149 57.4627 11.4075 56 13.525 56C16.0103 56 18.025 58.0147 18.025 60.5C18.025 61.0241 17.9354 61.5274 17.7707 61.9952C17.6449 61.9984 17.5188 62 17.3922 62C14.3315 62 11.4923 61.0517 9.15234 59.4328Z"
            fill="#803218"
          />
        </ClapperGroup>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6354 39C8.94097 39 5.13539 42.8056 5.13539 47.5V48.5021C5.13539 50.6042 4.47295 52.6529 3.24219 54.357L1.73707 56.441C0.913912 57.5808 1.49843 59.1676 2.88813 59.3806C8.94913 60.3097 18.0872 60.3094 24.1613 59.3797C25.5519 59.1669 26.1377 57.5789 25.3141 56.4384L24.0286 54.6586C22.7978 52.9544 22.1354 50.9058 22.1354 48.8036V47.5C22.1354 42.8056 18.3298 39 13.6354 39Z"
          fill="#FFAF7E"
        />
        <path
          d="M1.73707 56.441L2.14241 56.7338L2.14241 56.7338L1.73707 56.441ZM2.88813 59.3806L2.96389 58.8864L2.96389 58.8864L2.88813 59.3806ZM24.1613 59.3797L24.237 59.874L24.237 59.874L24.1613 59.3797ZM25.3141 56.4384L24.9087 56.7312L24.9087 56.7312L25.3141 56.4384ZM24.0286 54.6586L24.4339 54.3658L24.0286 54.6586ZM3.24219 54.357L2.83685 54.0643L3.24219 54.357ZM5.63539 47.5C5.63539 43.0817 9.21712 39.5 13.6354 39.5V38.5C8.66483 38.5 4.63539 42.5294 4.63539 47.5H5.63539ZM5.63539 48.5021V47.5H4.63539V48.5021H5.63539ZM2.14241 56.7338L3.64752 54.6498L2.83685 54.0643L1.33173 56.1483L2.14241 56.7338ZM2.96389 58.8864C1.98181 58.7358 1.51512 57.6023 2.14241 56.7338L1.33173 56.1483C0.312703 57.5592 1.01506 59.5993 2.81237 59.8748L2.96389 58.8864ZM24.0857 58.8855C18.0616 59.8075 8.97461 59.8078 2.96389 58.8864L2.81237 59.8748C8.92365 60.8116 18.1128 60.8113 24.237 59.874L24.0857 58.8855ZM24.9087 56.7312C25.5363 57.6001 25.0689 58.735 24.0857 58.8855L24.237 59.874C26.035 59.5988 26.7391 57.5576 25.7194 56.1457L24.9087 56.7312ZM23.6233 54.9513L24.9087 56.7312L25.7194 56.1457L24.4339 54.3658L23.6233 54.9513ZM21.6354 47.5V48.8036H22.6354V47.5H21.6354ZM13.6354 39.5C18.0537 39.5 21.6354 43.0817 21.6354 47.5H22.6354C22.6354 42.5294 18.606 38.5 13.6354 38.5V39.5ZM24.4339 54.3658C23.2647 52.7469 22.6354 50.8007 22.6354 48.8036H21.6354C21.6354 51.0109 22.331 53.162 23.6233 54.9513L24.4339 54.3658ZM4.63539 48.5021C4.63539 50.4991 4.00607 52.4453 2.83685 54.0643L3.64752 54.6498C4.93983 52.8604 5.63539 50.7093 5.63539 48.5021H4.63539Z"
          fill="#803218"
        />
      </BellSvg>
    </>
  );
};
