/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
import axios from "axios";
import { isDevelopment } from "../environment";
import { GeolocationCoords } from "../types/helper.types";

export const getUserGeolocation = () =>
  new Promise<
    | (GeolocationCoords & { city?: string; state?: string; country?: string })
    | null
  >(resolve => {
    if (navigator.geolocation) {
      try {
        if (isDevelopment) console.info("requesting user geolocation...");
        navigator.geolocation.getCurrentPosition(async position => {
          const { latitude, longitude } = position.coords;
          // const [latitude, longitude] = [51.3398584, 12.3755424];
          if (isDevelopment)
            console.info(
              `user geolocation: lat ${latitude}, lng ${longitude}.`
            );
          const { data } = await axios.get<{
            lat: number;
            lng: number;
            city: string;
            state: string;
            country: string;
          }>(`/api/utils/geocode?latitude=${latitude}&longitude=${longitude}`);
          resolve(data);
        });
      } catch (e) {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
