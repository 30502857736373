import { EventDirectoryItem } from "../../types/helper.types";

const KnownPrimaryTypeMap = {
  "tines-experience": "Tines Experience",
  bootcamp: "Bootcamp",
  "tines-roadshow": "Roadshow",
  presentation: "Presentation",
  workshop: "Workshop",
  rsa: "RSA",
  conference: "Conference",
  "customer-event": "Customer Event",
  "partner-event": "Partner event",
};

export const EventDirectoryItemPrimaryCategory = (props: {
  item: EventDirectoryItem;
}) => {
  const { type, categories } = props.item;
  const primaryType = categories.find(c => c.slug in KnownPrimaryTypeMap);
  const primaryTypeName =
    type === "webinar"
      ? "Webinar"
      : primaryType
      ? KnownPrimaryTypeMap[
          primaryType.slug as keyof typeof KnownPrimaryTypeMap
        ] ?? "Event"
      : "Event";
  return <>{primaryTypeName}</>;
};
