import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { useState } from "react";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";

type Props = {
  src?: string | null;
  src2x?: string | null;
  title?: string;
  alt?: string | null;
  cover?: boolean;
  gridArea?: string;
  className?: string;
  borderRadius?: string | number;
  aspectRatio?: string;
  backgroundColor?: string;
  intrinsicWidth?: string | number | null;
  intrinsicHeight?: string | number | null;
  renderedWidth?: string | number | null;
  renderedHeight?: string | number | null;
  overflow?: string;
  lazy?: boolean;
  fadeInOnLoad?: boolean;
};

export const StaticImageFrameFigure = styled.figure<Props>`
  display: block;
  margin: 0;
  padding: 0;
  border-radius: ${p => valueWithOptionalUnit(p.borderRadius)};
  overflow: ${p => p.overflow ?? "hidden"};
  background-color: ${p => p.backgroundColor ?? "transparent"};
  aspect-ratio: ${p => p.aspectRatio ?? ""};
  grid-area: ${p => p.gridArea ?? ""};
  transform: translateZ(0);
  width: ${p => valueWithOptionalUnit(p.renderedWidth, "")};
  height: ${p => valueWithOptionalUnit(p.renderedHeight, "")};
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: ${p => (p.fadeInOnLoad ? 0 : 1)};
    transition: ${p => (p.fadeInOnLoad ? "opacity .1s" : "none")};
    object-fit: ${p => (p.cover ? "cover" : "unset")};
    width: ${p => (p.cover ? "100%" : "auto")};
    max-width: ${p => (p.cover ? "unset" : "100%")};
    height: ${p => (p.cover ? "100%" : "unset")};
  }
  &.loaded {
    img {
      opacity: 1;
    }
  }
`;

const StaticImageFrame = (props: Props) => {
  const [loaded, setLoaded] = useState<null | boolean>(null);
  const [shouldSetSrc, setShouldSetSrc] = useState(
    !props.lazy && !props.fadeInOnLoad
  );
  const handleLoad = () => {
    setLoaded(true);
  };
  useOnMount(() => {
    if (props.lazy || props.fadeInOnLoad) {
      /**
       * the purpose of this is to set onLoad handler before setting the src,
       * so that when the browser already has the images in cache the onload would still fire.
       * */
      setShouldSetSrc(true);
    }
  });
  const src = props.src ?? props.src2x ?? "";
  const srcSet = props.src2x ? `${props.src} 1x, ${props.src2x} 2x` : undefined;
  return (
    <StaticImageFrameFigure
      className={cx(props.className, loaded && "loaded")}
      borderRadius={props.borderRadius}
      backgroundColor={props.backgroundColor}
      aspectRatio={props.aspectRatio}
      gridArea={props.gridArea}
      cover={props.cover}
      fadeInOnLoad={props.fadeInOnLoad}
      intrinsicWidth={props.intrinsicWidth}
      intrinsicHeight={props.intrinsicHeight}
      renderedWidth={props.renderedWidth}
      renderedHeight={props.renderedHeight}
      overflow={props.overflow}
    >
      {(props.src || props.src2x) && (
        <img
          className={css`
            display: block;
          `}
          style={{
            aspectRatio: props.aspectRatio,
          }}
          src={shouldSetSrc ? src : undefined}
          srcSet={shouldSetSrc ? srcSet : undefined}
          title={props.title}
          alt={props.alt ?? undefined}
          data-width={props.intrinsicWidth}
          width={props.intrinsicWidth ?? undefined}
          height={props.intrinsicHeight ?? undefined}
          loading={props.lazy ? "lazy" : undefined}
          onLoad={handleLoad}
        />
      )}
    </StaticImageFrameFigure>
  );
};

export default StaticImageFrame;
