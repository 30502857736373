import { styled } from "@linaria/react";
import {
  fromPhoneLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { UseStateReturnType } from "../../types/helper.types";
import Toggle from "../forms/Toggle";
import { CookieConsentState } from "./gdpr";

type Props = {
  formState: UseStateReturnType<CookieConsentState>;
};

const Configurator = styled.div`
  display: grid;
  grid-gap: 14px;
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromTabletMd} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const CookieConsentManager = (props: Props) => {
  return (
    <Configurator>
      <CookieConsentOption>
        <OptionHeader>
          <h3>Essential</h3>
          <Toggle formState={props.formState} name="essential" disabled />
        </OptionHeader>
        <p>Required for the site to function. Always On.</p>
      </CookieConsentOption>
      <CookieConsentOption>
        <OptionHeader>
          <h3>Personalization</h3>
          <Toggle formState={props.formState} name="personalization" />
        </OptionHeader>
        <p>
          Used to remember preference selections and provide enhanced features.
        </p>
      </CookieConsentOption>
      <CookieConsentOption>
        <OptionHeader>
          <h3>Analytics</h3>
          <Toggle formState={props.formState} name="analytics" />
        </OptionHeader>
        <p>Used to measure usage and improve your experience.</p>
      </CookieConsentOption>
      <CookieConsentOption>
        <OptionHeader>
          <h3>Marketing</h3>
          <Toggle formState={props.formState} name="marketing" />
        </OptionHeader>
        <p>Used for more relevant marketing.</p>
      </CookieConsentOption>
    </Configurator>
  );
};

const CookieConsentOption = styled.div`
  background-color: ${withOpacity(colorsV4.white, 0.1)};
  padding: 14px;
  border-radius: 12px;
  h3 {
    font-size: 16px;
    font-weight: 700;
  }
  p {
    opacity: 0.8;
    line-height: 1.25;
  }
`;
const OptionHeader = styled.div`
  display: grid;
  grid-gap: 14px;
  grid-template-columns: minmax(auto, 1fr) auto;
  padding-bottom: 0.5em;
`;

export default CookieConsentManager;
