import { styled } from "@linaria/react";
import { css, cx } from "linaria";
import { useState } from "react";
import { DatoCmsWebinar } from "../../../graphql-types";
import { brandColorThemeVar, colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { getCookie, setCookie } from "../../utils/cookies.utils";
import { reportIntent } from "../../utils/intentReporter.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import VideoBlock, { VideoBlockInner } from "../blocks/VideoBlock.block";
import Button from "../forms/Button";
import { readCookieConsentState } from "../gdpr/gdpr";
import LoadingIndicator from "../utilities/LoadingIndicator";
import PrivacyNotice from "../utilities/PrivacyNotice";
import Spacing from "../layout/Spacing";
import TextInput, { TextInputSet } from "../forms/TextInput";
import { getFormMetaInfo } from "../../utils/form.utils";

type Props = {
  webinar: DatoCmsWebinar;
};

const WebinarVideoBlockContainer = styled.div`
  position: relative;
  border-radius: 1em;
  background-color: ${brandColorThemeVar(900)};
  color: ${colors.white};
  overflow: hidden;
  > * {
    opacity: 0;
    transition: opacity 0.3s;
  }
  &.ready {
    background-color: ${colors.black};
    > * {
      opacity: 1;
    }
  }
  ${VideoBlockInner} {
    border-radius: 0;
  }
`;

const AccessForm = styled.div`
  background-color: ${brandColorThemeVar(800)};
  color: ${colors.white};
  text-align: center;
  padding: ${rSize("lg")};
  h3 {
    margin-bottom: 0.5em;
  }
  input {
    background-color: ${brandColorThemeVar(800)};
    border-color: ${brandColorThemeVar(700)};
    &:hover,
    &:focus {
      z-index: 1;
      border-color: ${brandColorThemeVar(600)};
    }
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-gap: 0.5em;
  margin: 2em 0;
`;

const WebinarVideoBlock = (props: Props) => {
  const formState = useState(() => ({
    ["firstName"]: "",
    ["lastName"]: "",
    company: "",
    email: "",
  }));

  const [ready, setReady] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showAccessForm, setShowAccessForm] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);

  const submit = async () => {
    const [form] = formState;
    if (
      !form.firstName ||
      !form.lastName ||
      !form.company ||
      !form.email?.includes("@")
    ) {
      window.alert("Please double check your details and try again.");
      return;
    }
    setAwaitingResponse(true);
    if (!(await emailIsLegitimate(form.email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }
    const url =
      "https://hq.tines.io/webhook/29764276558dfa1bab6dd9f7cec5ab6b/f8feff332fb1e76e96ab5a54a838ccbd";
    await fetch(url, {
      method: "post",
      body: JSON.stringify({
        form: form,
        webinarId: props.webinar.id,
        ...getFormMetaInfo(),
      }),
    });
    setAwaitingResponse(false);
    const cookieConsentState = readCookieConsentState();
    if (cookieConsentState?.personalization) {
      setCookie("email_address", form.email, { days: 30 });
      setCookie("company", form.company, { days: 30 });
      setCookie("first_name", form.firstName, { days: 30 });
      setCookie("last_name", form.lastName, { days: 30 });
    }
    setShowAccessForm(false);
    setShowVideo(true);
  };

  useOnMount(() => {
    const email = getCookie("email_address");
    if (email && email.includes("@")) {
      reportIntent(`Visited on-demand webinar "${props.webinar.title}"`);
      setShowVideo(true);
    } else {
      setShowAccessForm(true);
    }
    setReady(true);
  });

  return (
    <WebinarVideoBlockContainer className={cx(ready && "ready")}>
      {!showAccessForm && !showVideo && (
        <div
          className={css`
            position: relative;
            opacity: 1;
            &:before {
              display: block;
              content: "";
              padding-top: 56.25%;
            }
          `}
        >
          <LoadingIndicator centered color={colors.white} />
        </div>
      )}
      {showAccessForm && (
        <AccessForm className="WebinarVideoAccessForm">
          <Icon />
          <h3>Register for this on-demand webinar</h3>
          <p>You'll be able to watch instantly.</p>
          <FormGrid>
            <TextInputSet>
              <TextInput
                formState={formState}
                name="firstName"
                placeholder="First name *"
                required
              />
              <TextInput
                formState={formState}
                name="lastName"
                placeholder="Last name *"
                required
              />
              <TextInput
                formState={formState}
                name="email"
                placeholder="Email *"
                required
                type="email"
              />
              <TextInput
                formState={formState}
                name="company"
                placeholder="Company *"
                required
                onEnter={() => {
                  submit();
                }}
              />
            </TextInputSet>
          </FormGrid>
          <Button
            onClick={submit}
            type="button"
            loading={awaitingResponse}
            name="submit"
          >
            Watch now
          </Button>
          <Spacing size=".75em" />
          <PrivacyNotice />
          <Spacing size=".25em" />
        </AccessForm>
      )}
      {showVideo && (
        <VideoBlock
          url={props.webinar.recordedVideoLink}
          title={props.webinar.title}
          dataName="webinar-video-frame"
        />
      )}
    </WebinarVideoBlockContainer>
  );
};

export default WebinarVideoBlock;

const Icon = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M147.597 117.911C147.597 119.717 128.402 121.18 104.392 121.18C80.3826 121.18 60.9189 119.717 60.9189 117.911C60.9189 116.106 80.3826 114.642 104.392 114.642C128.402 114.642 147.597 116.106 147.597 117.911Z"
      fill={brandColorThemeVar(900)}
    />
    <path
      d="M118.784 53.1996L37.3987 53.1417C36.4055 53.1409 35.5041 53.5274 34.8346 54.1582C34.1659 54.7893 31.8138 57.1383 31.1816 57.8067C30.55 58.4746 30.162 59.3759 30.1615 60.3691L30.1216 116.48C30.1199 118.546 31.7936 120.223 33.8596 120.224L115.246 120.282C116.238 120.283 117.14 119.896 117.809 119.265C118.478 118.634 120.831 116.285 121.463 115.617C122.095 114.948 122.482 114.048 122.483 113.054L122.523 56.9429C122.524 54.8771 120.851 53.2012 118.784 53.1996Z"
      fill="#4A485B"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M118.989 116.544C118.987 118.61 117.312 120.284 115.246 120.282L33.8599 120.224C31.7938 120.223 30.1201 118.546 30.1218 116.481L30.1617 60.3694C30.1628 58.3031 31.8393 56.6297 33.9048 56.6311L115.291 56.6886C117.357 56.69 119.031 58.3662 119.029 60.4326L118.989 116.544Z"
      fill="#4A485B"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M83.607 92.7157C83.6074 91.736 82.8571 91.4489 82.8571 91.4489L70.6605 83.6343C70.6605 83.6343 69.7969 83.0372 68.8266 83.4952C68.1286 83.8253 68.1779 84.8992 68.1779 84.8992L68.1684 98.7064C68.1684 98.7064 68.1037 99.9488 69.0658 100.498C70.0278 101.048 71.0894 100.434 71.0894 100.434L82.8594 93.9796C82.8598 93.9793 83.6064 93.6779 83.607 92.7157Z"
      fill="#FCF9F5"
    />
    <path
      d="M122.867 48.8077L33.246 48.744L29.6272 52.8307L29.6194 64.0856L119.24 64.1493L122.859 58.6556L122.867 48.8077Z"
      fill="#4A485B"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M119.244 52.8627H29.6233V64.1175H119.244V52.8627Z"
      fill="#4A485B"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M120.065 31.5831L118.106 20.5L31.3581 36.0746L27.2419 39.5852L29.2011 50.6677L117.453 35.0654L120.065 31.5831Z"
      fill="#4A485B"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M115.486 23.9777L27.2371 39.5806L29.1964 50.6627L117.445 35.0598L115.486 23.9777Z"
      fill="#4A485B"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.3215 64.0715L50.2686 64.0656L56.3905 52.8862L64.4435 52.8919L58.3215 64.0715Z"
      fill="#FCF9F5"
    />
    <path
      d="M76.3657 64.0847L68.313 64.0786L74.4349 52.8989L82.4876 52.9043L76.3657 64.0847Z"
      fill="#FCF9F5"
    />
    <path
      d="M94.4091 64.0976L86.3564 64.0914L92.4789 52.9115L100.531 52.9177L94.4091 64.0976Z"
      fill="#FCF9F5"
    />
    <path
      d="M112.454 64.1102L104.401 64.1043L110.523 52.9244L118.576 52.9303L112.454 64.1102Z"
      fill="#FCF9F5"
    />
    <path
      d="M109.21 36.6716L117.158 35.3806L109.331 25.321L101.382 26.6121L109.21 36.6716Z"
      fill="#FCF9F5"
    />
    <path
      d="M91.3991 39.5647L99.348 38.2742L91.5198 28.2144L83.5713 29.5057L91.3991 39.5647Z"
      fill="#FCF9F5"
    />
    <path
      d="M73.5889 42.4578L81.5372 41.1671L73.7088 31.1078L65.7605 32.3985L73.5889 42.4578Z"
      fill="#FCF9F5"
    />
    <path
      d="M55.7778 45.351L63.7264 44.0599L55.8983 34.0004L47.9497 35.2917L55.7778 45.351Z"
      fill="#FCF9F5"
    />
    <path
      d="M119.244 52.8627H29.6233V64.1175H119.244V52.8627Z"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M118.784 53.1996L122.867 48.8077"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M119.035 64.3895H29.9817V66.4186H119.035V64.3895Z"
      fill={brandColorThemeVar(900)}
    />
    <path
      d="M115.486 23.9777L27.2371 39.5806L29.1964 50.6627L117.445 35.0598L115.486 23.9777Z"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M119.034 66.4503L122.499 62.2746L122.5 60.007L119.24 64.1493L119.034 66.4503Z"
      fill={brandColorThemeVar(900)}
    />
    <path
      d="M27.0073 50.1975L27 60.444C26.9983 62.7692 28.8825 64.6562 31.208 64.6576L53.0106 64.6731L53.019 53.2576L39.1324 40.0537L27.0149 40.045L27.0073 50.1975Z"
      fill="#F69267"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.3733 47.2076C34.1485 47.2076 34.7769 46.5792 34.7769 45.804C34.7769 45.0288 34.1485 44.4004 33.3733 44.4004C32.5981 44.4004 31.9697 45.0288 31.9697 45.804C31.9697 46.5792 32.5981 47.2076 33.3733 47.2076Z"
      fill="#FBB598"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.3645 59.8401C34.1397 59.8401 34.7681 59.2117 34.7681 58.4366C34.7681 57.6614 34.1397 57.033 33.3645 57.033C32.5893 57.033 31.9609 57.6614 31.9609 58.4366C31.9609 59.2117 32.5893 59.8401 33.3645 59.8401Z"
      fill="#FBB598"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.2776 59.8492C47.0528 59.8492 47.6812 59.2208 47.6812 58.4456C47.6812 57.6704 47.0528 57.042 46.2776 57.042C45.5024 57.042 44.874 57.6704 44.874 58.4456C44.874 59.2208 45.5024 59.8492 46.2776 59.8492Z"
      fill="#FBB598"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M115.494 23.9826L118.106 20.5"
      stroke={brandColorThemeVar(900)}
      strokeWidth="1.40359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M73.3711 52.8506L82.7783 48.3656L48.6709 48.3414L53.019 52.6957L73.3711 52.8506Z"
      fill={brandColorThemeVar(900)}
    />
    <rect
      x="35.7443"
      y="70.5497"
      width="77.7592"
      height="43.7922"
      rx="1.54395"
      stroke="#FCF9F5"
      strokeWidth="1.40359"
    />
  </svg>
);
