import { DatoCmsLibraryTool } from "../../../graphql-types";
import { StoryDescriptor } from "../../types/helper.types";
import { Link } from "gatsby";
import ListWithPagination from "../basic/ListWithPagination";
import { Paths } from "../../utils/pathBuilders.utils";
import React from "react";
import { useSiteContext } from "../../context/site.context";
import {
  LibraryTableAuthorCell,
  LibraryTableCellWithToolIcons,
  LibraryTableCommunityTag,
  LibraryTablePlainTextCell,
  LibraryTableRowTr,
  LibraryTableSortableColumnHeading,
  LibraryTableStoryIconCell,
  LibraryTableTagCell,
  LibraryTableWrap,
} from "./LibraryTableComponents";
import { LibraryTableToolIcon } from "./LibraryTableToolIcon";
import StoryCommunityAuthorsDisplay from "./StoryCommunityAuthorsDisplay";
import { isCommunityStory } from "../storyboard/utils/story.utils";
import { useViewportSize } from "../../utils/window.utils";
import StoryEntry from "./StoryEntry";
import { styled } from "@linaria/react";
import { LibraryState } from "./WithLibraryState";
import StoryInternalAuthorsDisplay from "./StoryInternalAuthorsDisplay";
import { OpenBookTwoToneIcon } from "../icons/twoTone/OpenBookTwoToneIcon";

type Props = {
  stories: StoryDescriptor[];
  tools: DatoCmsLibraryTool[];
  state: LibraryState;
};

const MobileGrid = styled.div`
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const LibraryStoryTable = (props: Props) => {
  const { vw } = useViewportSize();
  return (
    <LibraryTableWrap>
      <ListWithPagination
        defaultPerPage={15}
        allowCustomPerPageCount
        entries={props.stories}
        scrollToHashOnNavigation="#find-and-filter"
        children={entries => {
          if (vw >= 768) {
            const containsCommunityStories = entries.some(isCommunityStory);
            return (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <LibraryTableSortableColumnHeading
                      currentSort={props.state.sort}
                      asc="nameAsc"
                      desc="nameDesc"
                      onChange={props.state.setSort}
                    >
                      Story
                    </LibraryTableSortableColumnHeading>
                    <th>Works with</th>
                    <LibraryTableSortableColumnHeading
                      currentSort={props.state.sort}
                      asc="simplerStoriesFirst"
                      desc="complexStoriesFirst"
                      onChange={props.state.setSort}
                    >
                      No. of actions
                    </LibraryTableSortableColumnHeading>
                    {/* <th>Features</th> */}
                    <th>Author</th>
                    {containsCommunityStories && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {entries.map(s => (
                    <StoryRow
                      key={s.id}
                      story={s}
                      tools={props.tools}
                      alwaysRenderCommunityCells={containsCommunityStories}
                    />
                  ))}
                </tbody>
              </table>
            );
          } else {
            return (
              <MobileGrid>
                {entries.map(e => (
                  <StoryEntry key={e.id} story={e} />
                ))}
              </MobileGrid>
            );
          }
        }}
      />
    </LibraryTableWrap>
  );
};

const StoryRow = ({
  story,
  tools,
  alwaysRenderCommunityCells,
}: {
  story: StoryDescriptor;
  tools: DatoCmsLibraryTool[];
  alwaysRenderCommunityCells: boolean;
}) => {
  const siteContext = useSiteContext();
  const url = Paths.library.story(story.id, story.slug);
  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
      return;
    }
    e.preventDefault();
    siteContext.library.viewStoryInLightbox(story);
    return;
  };
  const isCommunity = isCommunityStory(story);
  return (
    <LibraryTableRowTr>
      <LibraryTableStoryIconCell>
        <OpenBookTwoToneIcon />
      </LibraryTableStoryIconCell>
      <th>
        <Link to={url} onClick={handleClick}>
          {story.name}
        </Link>
      </th>
      <LibraryTableCellWithToolIcons>
        {story.tools.map((t, i) => (
          <LibraryTableToolIcon tool={t} key={i} allTools={tools} withToolTip />
        ))}
      </LibraryTableCellWithToolIcons>
      <LibraryTablePlainTextCell>
        {story.numberOfActions}
      </LibraryTablePlainTextCell>
      {/* <LibraryTablePlainTextCell>
        {story.features.join(", ")}
      </LibraryTablePlainTextCell> */}
      <LibraryTableAuthorCell>
        {story.communityAuthors.length > 0 && (
          <StoryCommunityAuthorsDisplay story={story} compact />
        )}
        {story.internalAuthors.length > 0 && (
          <StoryInternalAuthorsDisplay story={story} />
        )}
      </LibraryTableAuthorCell>
      {isCommunity ||
        (alwaysRenderCommunityCells && (
          <LibraryTableTagCell>
            {isCommunity && (
              <LibraryTableCommunityTag>Community</LibraryTableCommunityTag>
            )}
          </LibraryTableTagCell>
        ))}
    </LibraryTableRowTr>
  );
};

export default LibraryStoryTable;
