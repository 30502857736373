import { styled } from "@linaria/react";
import { cx } from "linaria";
import { useRef, useState } from "react";
import {
  aspectRatioChild,
  aspectRatioParent,
} from "../../styles/aspectRatio.styles";
import { ValidVideoBlock } from "../../utils/datocms.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { getVideoMetaFromUrl } from "../../utils/videoEmbed.utils";
import GenericIframe from "../utilities/GenericIframe";
import VideoPlayer from "../basic/VideoPlayer";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  record?: ValidVideoBlock;
  url?: string | null;
  title?: string | null;
  dataName?: string;
};

const VideoBlockFigure = styled.figure`
  margin: 0;
`;

export const VideoBlockInner = styled.div`
  position: relative;
  background-color: ${colors.dark500};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  transform: translateZ(0);
  &.usingIframe {
    ${aspectRatioParent(16 / 9)};
    iframe {
      display: block;
      width: 100%;
      height: 100%;
      ${aspectRatioChild()};
    }
  }
  > * {
    opacity: 0;
    transition: 0.1s;
    ${VideoBlockFigure}.ready & {
      opacity: 1;
    }
  }
`;

const VideoBlockCaption = styled.figcaption`
  margin-top: 1em;
  opacity: 0.8;
  margin-bottom: 1em;
  text-align: center;
  font-weight: 500;
`;

const VideoBlock = (props: Props) => {
  const [ready, setReady] = useState(false);
  const externalUrl = props.record?.url ?? props.url;
  const title = props.record?.title ?? props.title;
  const meta = getVideoMetaFromUrl(externalUrl);
  const DefaultExternalEmbed = () => (
    <GenericIframe src={externalUrl} title={title} width="100%" height="100%" />
  );
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setReady(true);
        observer.disconnect();
      }
    });
    ref.current && observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  });
  return (
    <VideoBlockFigure
      className={cx("VideoBlock", ready && "ready")}
      data-name={props.dataName}
      style={{
        aspectRatio: `${props.record?.videoFile?.width ?? 1280} / ${
          props.record?.videoFile?.height ?? 720
        }`,
      }}
      ref={ref}
    >
      <VideoBlockInner
        className={cx(!props.record?.videoFile?.video && "usingIframe")}
      >
        {ready
          ? (() => {
              if (props.record?.videoFile?.video) {
                return (
                  <VideoPlayer
                    datoAssetVideo={props.record.videoFile.video}
                    autoPlay={props.record.autoplay ?? false}
                    loop={props.record.loop ?? false}
                  />
                );
              }
              switch (meta.host) {
                case "youtube":
                  if (meta.id) {
                    return <YouTubeEmbed id={meta.id} title={title} />;
                  }
                  return <DefaultExternalEmbed />;
                case "loom":
                  if (meta.id) {
                    return <LoomEmbed id={meta.id} title={title} />;
                  }
                  return <DefaultExternalEmbed />;
                default:
                  return <DefaultExternalEmbed />;
              }
            })()
          : null}
      </VideoBlockInner>
      {props.record?.title && (
        <VideoBlockCaption>▲  {props.record.title}</VideoBlockCaption>
      )}
    </VideoBlockFigure>
  );
};

export const YouTubeEmbed = (props: { id: string; title?: string | null }) => {
  return (
    <iframe
      src={`https://www.youtube.com/embed/${props.id}`}
      title={props.title ?? "YouTube video player"}
      frameBorder="0"
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
  );
};

export const LoomEmbed = (props: { id: string; title?: string | null }) => {
  return (
    <iframe
      src={`https://www.loom.com/embed/${props.id}`}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
};

export default VideoBlock;
