import ContentCardBase, { ContentCardSize } from "./_ContentCardBase";
import { css } from "linaria";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import { PrimaryColorName } from "../../styles/colors.styles";
import { Paths } from "../../utils/pathBuilders.utils";

const ContentCardSlackCommunityStyle = css``;

const ContentCardSlackCommunity = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  return (
    <ContentCardBase
      className={ContentCardSlackCommunityStyle}
      link={Paths.slackCommunity()}
      color={(overrides?.color ?? "orange") as PrimaryColorName}
      variant="50"
      large={large}
      size={(overrides?.size ?? "1/3") as ContentCardSize}
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Join the discussion"}</strong>
          </p>
          <h4>{overrides?.heading || "Slack community"}</h4>
          <ReactMarkdown>
            {overrides?.description ||
              "A forum for Tines customers, partners and the wider community to share ideas and look for help."}
          </ReactMarkdown>
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Join now"}</strong>
        </p>
      </header>
      <figure>
        <SlackCommunityIcon />
      </figure>
    </ContentCardBase>
  );
};

export default ContentCardSlackCommunity;

const SlackCommunityIcon = () => (
  <svg
    width="79"
    height="79"
    viewBox="0 0 79 79"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M47.8142 73.0698C46.1731 73.0698 44.8381 73.0914 43.7542 73.1322V78.0246C44.8381 78.0655 46.1731 78.087 47.8142 78.087C59.6675 78.087 69.2764 76.9638 69.2764 75.5784C69.2764 74.193 59.6677 73.0698 47.8142 73.0698Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M47.7526 1H34.241L30.6415 5.37791C30.6415 11.3307 28.8314 16.8674 25.7247 21.4898C20.3106 26.9189 12.7638 30.2892 4.41435 30.2892L0.814697 34.6673V47.9349C15.4769 47.9349 28.4368 40.7837 36.2793 29.8407C43.4008 22.2573 47.7526 12.1282 47.7526 1Z"
      fill="#5178B3"
      stroke="#32313B"
    />
    <path
      d="M78.1991 30.6073C61.7262 30.6073 47.7529 17.1759 47.7529 1L34.2582 1.01307L30.6167 5.49038C30.6167 28.9933 50.4693 47.4948 74.4041 47.4948L78.1989 43.6571V30.6073H78.1991Z"
      fill="#2A7857"
      stroke="#32313B"
    />
    <path
      d="M48.092 72.9986C48.092 49.4955 28.1559 30.3892 4.22094 30.3892L0.939941 34.685V47.9527C17.4132 47.9527 30.7672 61.0657 30.7672 77.2416H44.2784L48.092 72.9986Z"
      fill="#D67C56"
      stroke="#32313B"
    />
    <path d="M44.1528 5.37792L47.7525 1" stroke="#32313B" />
    <path
      d="M78.1996 30.6077C63.7558 30.6077 50.9645 37.5481 43.0893 48.2161C35.642 55.8584 31.0662 66.2211 31.0662 77.6335H44.5774L48.3724 73.1643C48.3724 67.0825 50.2603 61.4342 53.4918 56.7524C58.875 51.5534 66.2576 48.3448 74.4045 48.3448L78.1992 43.8756V30.6077H78.1996Z"
      fill="#B35980"
      stroke="#32313B"
    />
    <path
      d="M0.939941 48.0348C17.4132 48.0348 30.7672 61.8472 30.7672 78.0229H44.2784C44.2784 54.5199 24.8747 35.4663 0.939941 35.4663V48.0348Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M74.4061 35.0767C50.4713 35.0767 31.0676 54.1304 31.0676 77.6333H44.5789C44.5789 61.4575 57.9331 48.3446 74.4061 48.3446V35.0767Z"
      fill="#FF6565"
      stroke="#32313B"
    />
    <path
      d="M30.6416 5.37793C30.6416 21.5539 17.2876 34.6672 0.814697 34.6672V47.9347C24.7495 47.9347 44.153 28.881 44.153 5.37812H30.6416V5.37793Z"
      fill="#6DA2F0"
      stroke="#32313B"
    />
    <path d="M74.4038 35.0769L78.1986 30.6077" stroke="#32313B" />
    <path
      d="M30.814 5.49121C30.814 28.9941 50.2175 48.0478 74.1523 48.0478V35.2384C57.6794 35.2384 44.3254 21.6671 44.3254 5.49121H30.814Z"
      fill="#49B889"
      stroke="#32313B"
    />
    <path d="M74.4038 35.0775L77.9939 30.6941" stroke="#32313B" />
    <path
      d="M27.3727 44.3264L31.1 39.502L29.1116 37.7444L23.7935 41.788L27.3727 44.3264Z"
      fill="#32313B"
    />
    <path
      d="M49.7191 40.8674L46.1335 45.0173L51.1278 41.7476L49.7191 40.8674Z"
      fill="#32313B"
    />
  </svg>
);
