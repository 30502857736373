import { styled } from "@linaria/react";
import { graphql, useStaticQuery } from "gatsby";
import {
  fromDesktop,
  fromDesktopLg,
  fromPhoneLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { DatoCmsPerson, LeadershipQuery } from "../../../graphql-types";
import PersonCard, { PersonCardContainer } from "../general/PersonCard";

const LeadershipSlideSectionContainer = styled.section`
  overflow: hidden;
`;

const Inner = styled.div`
  ${maxPageContentWidthStyleObject};
`;

const Header = styled.header`
  padding-bottom: ${rSize("xl")};
  ${onlyPhones} {
    text-align: center;
  }
`;

const LeadershipPersonCardGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromDesktop} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${fromDesktopLg} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${PersonCardContainer} {
    aspect-ratio: 3/4;
  }
`;

const LeadershipSlideSection = () => {
  const leadershipQueryResult: LeadershipQuery =
    useStaticQuery(leadershipQuery);
  const allLeadershipMembers = (leadershipQueryResult.leadership?.members ??
    []) as DatoCmsPerson[];
  return (
    <LeadershipSlideSectionContainer>
      <Inner>
        <Header>
          <h2>Our leadership</h2>
        </Header>
        <LeadershipPersonCardGrid>
          {allLeadershipMembers.map(person => (
            <PersonCard key={person.id} person={person} />
          ))}
        </LeadershipPersonCardGrid>
      </Inner>
    </LeadershipSlideSectionContainer>
  );
};

const leadershipQuery = graphql`
  query Leadership {
    leadership: datoCmsLeadership {
      members {
        id: originalId
        name
        middleName
        surname
        title
        linkedin
        photoIcon {
          url
        }
        photoPortrait {
          url
        }
      }
    }
  }
`;

export default LeadershipSlideSection;
