import { styled } from "@linaria/react";
import { ColorThemeNameV4 } from "../../styles/colorsV4.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { CSSProperties, ReactNode, RefObject } from "react";
import {
  PrimaryColorName,
  color,
  getBrandColorThemeVariables,
} from "../../styles/colors.styles";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";
import { cx } from "linaria";

type Props = React.PropsWithChildren<{
  className?: string;
  id?: string | null;
  centered?: boolean;
  backgroundColor?: string | PrimaryColorName;
  foregroundColor?: string | PrimaryColorName;
  clipOverflow?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
  before?: ReactNode;
  style?: CSSProperties;
  theme?: ColorThemeNameV4;
  wider?: boolean;
  relative?: boolean;
}>;

export const PageSectionInner = styled.div`
  ${maxPageContentWidthStyleObject}
`;

const PageSectionContainer = styled.section<Props>`
  background-color: ${p =>
    p.backgroundColor ? color(p.backgroundColor) : "none"};
  color: ${p => (p.foregroundColor ? color(p.foregroundColor) : "inherit")};
  text-align: ${p => (p.centered ? "center" : "inherit")};
  overflow: ${p => (p.clipOverflow ? "hidden" : "unset")};
  &.relative {
    position: relative;
  }
  &.wider {
    ${PageSectionInner} {
      ${widerPageSectionMaxWidthStyle()};
      padding-left: ${rSize("pageMargin")};
      padding-right: ${rSize("pageMargin")};
      ${fromTablet} {
        padding-left: ${rSize("widerPageMargin")};
        padding-right: ${rSize("widerPageMargin")};
      }
    }
  }
`;

const PageSection = (props: Props) => {
  const {
    id,
    style,
    className,
    wider,
    relative,
    theme,
    before,
    children,
    ...restOfProps
  } = props;
  return (
    <PageSectionContainer
      id={id ?? undefined}
      ref={props.innerRef}
      {...restOfProps}
      className={cx(className, wider && "wider", relative && "relative")}
      style={{
        ...style,
        ...(theme ? getBrandColorThemeVariables(theme) : null),
      }}
    >
      {before}
      <PageSectionInner>{children}</PageSectionInner>
    </PageSectionContainer>
  );
};

export default PageSection;
