export const BuildIcon = () => (
  <svg
    width="83"
    height="70"
    viewBox="0 0 83 70"
    fill="none"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M24.3798 62.5886H40.2478L31.3064 69.2302H13.84L24.3798 62.5886Z"
      fill="#32313B"
    />
    <path
      d="M58.887 62.5886H82.8505L73.7925 69.2302H48.3472L58.887 62.5886Z"
      fill="#32313B"
    />
    <path
      d="M30.3777 41.4395H10.3643L1 47.7806V68.9112H21.0134L30.3777 62.5698V41.4395Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path d="M1 47.7806H21.0134V68.9112" stroke="#32313B" strokeWidth="0.8" />
    <path
      d="M21.0134 47.7806L30.3778 41.4395"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M73.7749 41.4113H53.7612L44.3969 47.7527V68.8829H64.4102L73.7749 62.5416V41.4113Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M64.4101 68.8829V47.7527H44.3967"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M64.4102 47.7527L73.7748 41.4113"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M64.3045 21.7031H19.6901L10.3646 28.0443V47.7528H54.9791L64.3045 41.4114V21.7031Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M54.9793 47.7529V28.0444H10.3647"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M54.9794 28.0443L64.3047 21.7031"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M52.0237 1.00003H32.01L22.6459 7.34127V27.8999H42.6591L52.0237 21.5588V1.00003Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M42.6592 27.9001V7.34149H22.646"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M42.6592 7.3413L52.0238 1.00006"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M20.9613 47.9971V50.9895L30.4509 51.4621V47.7214L20.9613 47.9971Z"
      fill="#32313B"
    />
  </svg>
);

export const MarketIcon = () => (
  <svg
    width="79"
    height="88"
    viewBox="0 0 79 88"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M32.9107 88C47.2884 88 58.9439 86.6013 58.9439 84.8759C58.9439 83.1505 47.2884 81.7518 32.9107 81.7518C18.5329 81.7518 6.87744 83.1505 6.87744 84.8759C6.87744 86.6013 18.5329 88 32.9107 88Z"
      fill="#32313B"
    />
    <path
      d="M8.93765 60.9195C11.1289 63.5953 11.722 66.7333 10.2623 67.9286C8.80277 69.1238 4.86924 69.1349 2.67802 66.4587C0.486795 63.7832 0.867651 59.4336 2.32719 58.2384C3.78673 57.0433 6.74609 58.2434 8.93765 60.9195Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M20.6462 60.8562L30.0671 52.5271C30.0671 52.5271 33.6656 58.2484 29.488 61.9423L25.0648 65.8531L20.6462 60.8562Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M36.5988 68.6923L30.6911 57.7537C29.9865 56.7417 27.6177 57.1737 26.8216 57.7283L24.7083 59.2C24.4568 59.3753 24.2776 59.622 24.1721 59.9091C24.0091 60.3544 23.1618 62.2967 23.0592 62.6022C22.8514 63.2181 22.9748 64.0091 23.4454 64.6858L30.5064 74.8217C31.2115 75.8336 32.4275 76.2044 33.223 75.6499L35.3361 74.1783C35.5764 74.0104 35.7514 73.7774 35.8578 73.5063C36.0357 73.0541 36.9448 70.987 37.0323 70.6119C37.1687 70.0234 37.0125 69.5091 36.5988 68.6923Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M35.5012 71.3417C36.2062 72.3532 36.1319 73.6233 35.3363 74.1776L33.2232 75.6493C32.4276 76.2035 31.2115 75.8329 30.5065 74.8211L23.4456 64.6851C22.741 63.6734 22.815 62.4038 23.6103 61.8499L25.7238 60.3778C26.5197 59.8234 28.8887 59.3912 29.5933 60.4032L35.5012 71.3417Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M33.4365 71.8047C33.6458 72.4166 33.32 73.0819 32.7086 73.2913C32.0974 73.5011 31.4315 73.1753 31.2224 72.5638C31.0127 71.9522 31.3378 71.2868 31.9499 71.0775C32.5614 70.8676 33.2266 71.1933 33.4365 71.8047Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M35.0978 61.2534C35.0978 61.2534 32.5243 59.5371 31.7138 57.8203L30.2155 59.831L29.8076 61.256L32.0105 64.9515L34.6898 62.6789L35.0978 61.2534Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M29.8076 61.2563L32.0105 64.9518L34.6898 62.6792C34.6898 62.6792 32.116 60.9627 31.3055 59.2459L29.8076 61.2563Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M20.4507 62.3597L29.6608 53.7979C29.6608 53.7979 33.4015 59.4271 29.3171 63.2242L24.9926 67.2447L20.4507 62.3597Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M9.01889 48.3318C8.12907 49.0684 5.33862 51.3773 4.44863 52.114C2.64677 53.6047 -1.39977 58.0002 4.10718 64.6555C9.61466 71.3109 14.6894 68.158 16.4912 66.6673C17.3814 65.9309 20.1717 63.6217 21.0617 62.8855C22.8637 61.3943 21.6283 56.9277 18.3028 52.9086C14.9775 48.8899 10.8209 46.8409 9.01889 48.3318Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M28.1979 16.0134C28.1979 16.0134 26.0379 34.7294 9.11189 48.735C7.99883 49.6562 9.68717 53.5337 12.8824 57.3955C16.0782 61.2578 19.5715 63.6414 20.6846 62.7203C37.6104 48.7147 56.4001 50.0954 56.4001 50.0954L42.2991 33.0542L28.1979 16.0134Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M50.0915 26.6056C57.8792 36.0172 61.2949 46.0442 57.7209 49.0016C54.1467 51.959 44.9369 46.7269 37.1488 37.3153C29.3607 27.9037 25.9445 17.8771 29.5187 14.9195C33.0927 11.9622 42.3034 17.1942 50.0915 26.6056Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M32.7637 31.2443C34.0062 33.2459 35.4764 35.2946 37.1485 37.3153C38.8206 39.3361 40.5582 41.1633 42.2918 42.7587C44.8448 37.4136 48.4268 35.5696 48.4268 35.5696L44.7004 31.0663L40.9739 26.5631C40.9739 26.5631 38.4921 29.7365 32.7637 31.2443Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M48.4189 35.5704C49.5657 34.6215 48.8271 31.8361 46.7691 29.3492C44.7111 26.8622 42.1132 25.6154 40.9664 26.5644C39.8196 27.5133 40.5582 30.2987 42.6161 32.7857C44.6741 35.2726 47.2721 36.5194 48.4189 35.5704Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M58.4374 49.8647C56.3252 51.6124 52.7832 51.2241 48.4642 48.7708C44.4633 46.498 40.1392 42.6836 36.2881 38.0295C32.4368 33.3756 29.4992 28.4139 28.0151 24.0584C26.4134 19.3568 26.6947 15.8048 28.8071 14.057C30.9191 12.3091 34.4612 12.6974 38.7798 15.1507C42.7808 17.4235 47.1053 21.238 50.9564 25.8919C54.8075 30.5459 57.7449 35.5074 59.229 39.8629C60.8307 44.5648 60.5496 48.1167 58.4374 49.8647ZM30.8278 16.499C28.2925 18.5968 30.7223 27.284 38.2621 36.3959C45.8018 45.5077 53.8814 49.5202 56.4163 47.4224C58.9514 45.3246 56.522 36.637 48.9822 27.5253C41.4423 18.4135 33.3631 14.4014 30.8278 16.499Z"
      fill="#B8E5D2"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M35.1772 16.7477C35.1772 16.7477 30.5975 20.0917 33.7501 29.2811C33.7501 29.2811 26.9528 19.5831 30.8251 16.4993C32.9219 14.8295 35.1772 16.7477 35.1772 16.7477Z"
      fill="#32313B"
    />
    <path
      d="M57.161 42.8319C57.161 42.8319 53.2516 46.9391 44.5483 42.6216C44.5483 42.6216 53.2834 50.619 56.8433 47.1792C58.7707 45.3168 57.161 42.8319 57.161 42.8319Z"
      fill="#32313B"
    />
    <path
      d="M21.314 62.5493C21.314 62.5493 29.3708 57.9987 31.4467 58.141L30.8947 55.9305C30.8947 55.9305 23.2622 60.8223 21.314 62.5493Z"
      fill="#32313B"
    />
    <path
      d="M32.2944 64.9336L33.0416 66.4329L34.59 64.6861L33.7887 63.4651L32.2944 64.9336Z"
      fill="#32313B"
    />
    <path
      d="M45.3748 9.72993L42.5696 5.85857L44.5199 1.49351L49.2748 1L52.0802 4.87137L50.1298 9.23642L45.3748 9.72993Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M60.9453 31.5882L52.9341 29.2706L50.6165 37.2815L58.6277 39.5991L60.9453 31.5882Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M72.1301 21.1611L62.6355 26.6708L57.1257 17.1762L72.1301 21.1611Z"
      fill="#FF6565"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M65.0334 35.133L70.4022 31.406L75.6057 35.3607L73.4527 41.5314L66.9185 41.3905L65.0334 35.133Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M40.8781 20.02C40.6679 20.9555 39.7393 21.5434 38.8039 21.3332L38.7697 21.3255C37.8344 21.1153 37.2466 20.1868 37.4566 19.2513L38.824 13.1645C39.0339 12.229 39.9626 11.6411 40.8979 11.8513L40.9319 11.8589C41.8675 12.069 42.4553 12.9976 42.2451 13.9329L40.8781 20.02Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M55.9115 47.05C54.9685 46.8747 54.3466 45.9688 54.5217 45.026L54.5278 44.9918C54.7031 44.049 55.6091 43.4267 56.5517 43.602L62.6854 44.7411C63.628 44.9163 64.2504 45.8219 64.0752 46.7645L64.0688 46.7988C63.8936 47.7419 62.9877 48.3639 62.0451 48.1889L55.9115 47.05Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M47.3801 21.9124C47.333 22.2343 47.3245 22.567 47.3598 22.9055C47.5733 24.9648 49.3165 26.495 51.3442 26.4858C50.1665 24.9754 48.5931 23.2215 47.3801 21.9124Z"
      fill="#32313B"
    />
    <path
      d="M58.0477 10.56C57.5824 11.3986 56.526 11.701 55.6875 11.236L55.657 11.2191C54.8187 10.7539 54.5158 9.69728 54.9809 8.85903L58.0069 3.40367C58.4724 2.56523 59.5285 2.26232 60.367 2.72737L60.3979 2.74455C61.2367 3.20994 61.5391 4.2664 61.074 5.10466L58.0477 10.56Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M74.9157 27.8C74.0033 28.0958 73.0243 27.5964 72.7282 26.6845L72.7176 26.6515C72.4216 25.7395 72.921 24.7601 73.8331 24.4643L76.2935 23.813C77.2055 23.5172 78.1844 24.0161 78.4807 24.928L78.4915 24.9615C78.7876 25.8737 78.2879 26.8527 77.3762 27.1487L74.9157 27.8Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M50.2513 24.5037C52.4564 24.5037 54.2439 22.7162 54.2439 20.5111C54.2439 18.3061 52.4564 16.5186 50.2513 16.5186C48.0463 16.5186 46.2588 18.3061 46.2588 20.5111C46.2588 22.7162 48.0463 24.5037 50.2513 24.5037Z"
      fill="#6DA2F0"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M55.9492 39.1312L56.2711 39.8105L59.8822 42.2865L59.0874 39.4763L55.9492 39.1312Z"
      fill="#32313B"
    />
  </svg>
);

export const SellIcon = () => (
  <svg
    width="63"
    height="58"
    viewBox="0 0 63 58"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M41.3001 57.9161C52.7624 57.9161 62.0544 57.182 62.0544 56.2764C62.0544 55.3708 52.7624 54.6366 41.3001 54.6366C29.8379 54.6366 20.5459 55.3708 20.5459 56.2764C20.5459 57.182 29.8379 57.9161 41.3001 57.9161Z"
      fill="#32313B"
    />
    <path
      d="M32.882 57.2543C48.4162 57.2543 61.0092 44.6614 61.0092 29.1272C61.0092 13.593 48.4162 1.00002 32.882 1.00002C17.3478 1.00002 4.75488 13.593 4.75488 29.1272C4.75488 44.6614 17.3478 57.2543 32.882 57.2543Z"
      fill="#B36847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M41.7922 4.07459H45.2956"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.4652 6.8838H49.9554"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.8491 10.4414H53.694"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M51.4149 13.9989H56.3775"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.8042 17.5561H58.4857"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.1565 21.1139H59.7323"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.3324 24.6711H60.4995"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.6915 28.229H60.8659"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.226 31.7865H60.8108"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.6915 35.344H60.3077"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M54.7294 38.9012H59.189"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.8051 42.459H57.5284"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M50.4528 46.0163H55.3547"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.3524 49.5738H51.9686"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.5415 53.1313H47.5599"
      stroke="#FABE64"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.882 57.2543C48.4162 57.2543 61.0092 44.6614 61.0092 29.1272C61.0092 13.593 48.4162 1.00002 32.882 1.00002C17.3478 1.00002 4.75488 13.593 4.75488 29.1272C4.75488 44.6614 17.3478 57.2543 32.882 57.2543Z"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M29.1273 57.2546C44.6616 57.2546 57.2546 44.6616 57.2546 29.1273C57.2546 13.593 44.6616 1 29.1273 1C13.593 1 1 13.593 1 29.1273C1 44.6616 13.593 57.2546 29.1273 57.2546Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M48.3784 12.3798C50.0951 14.3772 51.4733 16.6743 52.4234 19.1818"
      stroke="#FFF0D9"
      strokeWidth="1.76404"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.1269 29.1273V38.5513H25.8489C23.6571 38.5513 21.8802 40.3278 21.8802 42.5199C21.8802 44.7115 23.6571 46.488 25.8489 46.488H29.1269V57.2547"
      stroke="#D67C56"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M29.127 1.18795V10.6121H32.4052C34.5969 10.6121 36.3738 12.3886 36.3738 14.5804C36.3738 16.7721 34.5969 18.5488 32.4052 18.5488H29.127V29.3154"
      stroke="#D67C56"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M29.2208 29.2215H19.7969V25.9434C19.7969 23.7517 18.0203 21.975 15.8284 21.975C13.6365 21.975 11.86 23.7517 11.86 25.9434V29.2215H1.09338"
      stroke="#D67C56"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M57.1836 29.2212H47.7367V32.4995C47.7367 34.6912 45.9602 36.4678 43.7683 36.4678C41.5764 36.4678 39.7998 34.6912 39.7998 32.4995V29.2212H29.0336"
      stroke="#D67C56"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M29.1273 57.2546C44.6616 57.2546 57.2546 44.6616 57.2546 29.1273C57.2546 13.593 44.6616 1 29.1273 1C13.593 1 1 13.593 1 29.1273C1 44.6616 13.593 57.2546 29.1273 57.2546Z"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M46.2667 11.0442C46.7375 11.0442 47.1192 10.6625 47.1192 10.1916C47.1192 9.72082 46.7375 9.33913 46.2667 9.33913C45.7959 9.33913 45.4142 9.72082 45.4142 10.1916C45.4142 10.6625 45.7959 11.0442 46.2667 11.0442Z"
      fill="#FFF0D9"
    />
    <path
      d="M29.1269 52.0861C41.8067 52.0861 52.0857 41.8071 52.0857 29.1273C52.0857 16.4475 41.8067 6.16845 29.1269 6.16845C16.4471 6.16845 6.16809 16.4475 6.16809 29.1273C6.16809 41.8071 16.4471 52.0861 29.1269 52.0861Z"
      stroke="#B36847"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M7.75685 29.1274C7.75685 16.7137 17.6097 6.60285 29.9214 6.18397C29.6572 6.17499 29.3928 6.16874 29.1269 6.16874C16.4472 6.16874 6.16809 16.4476 6.16809 29.1274C6.16809 41.8073 16.4472 52.0862 29.1269 52.0862C29.3928 52.0862 29.6572 52.08 29.9214 52.0711C17.6098 51.6521 7.75685 41.5414 7.75685 29.1274Z"
      fill="#B36847"
    />
    <path
      d="M57.2544 29.2074H61.0092"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const EnableIcon = () => (
  <svg
    width="82"
    height="72"
    viewBox="0 0 82 72"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M34.8069 67.7826L80.9834 60.2542L67.5042 53.3762L34.8069 67.7826Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M1 53.6653L34.807 67.7826L34.621 16.1434L1 53.6653Z"
      fill="#FDA2CB"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M34.8069 67.7826L67.5042 53.3762L34.6208 16.1434L34.8069 67.7826Z"
      fill="#A54B7A"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M45.0591 66.2993L58.299 71.3946L56.955 51.998L45.0591 66.2993Z"
      fill="#FDA2CB"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M58.2991 71.3946L69.5288 65.5792L56.9551 51.998L58.2991 71.3946Z"
      fill="#A54B7A"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M58.2993 71.3945L75.2256 67.5605L69.529 65.5791L58.2993 71.3945Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M35.9203 17.1233C35.9203 17.8414 35.3382 18.4236 34.6201 18.4236C33.9018 18.4236 33.3198 17.8414 33.3198 17.1233V2.30007C33.3198 1.58195 33.902 0.999977 34.6201 0.999977C35.3382 0.999977 35.9203 1.58195 35.9203 2.30007V17.1233Z"
      fill="#E9E6FF"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path
      d="M46.558 10.2988H32.9363V2.62117H46.558L42.905 6.45988L46.558 10.2988Z"
      fill="#FFAF7E"
      stroke="#32313B"
      strokeWidth="0.8"
    />
    <path d="M35.9694 10.2173H33.0986V11.906H35.9694V10.2173Z" fill="#32313B" />
    <path
      d="M34.4146 18.8612L40.5635 23.1344L36.0821 17.6105L34.4146 18.8612Z"
      fill="#32313B"
    />
  </svg>
);
