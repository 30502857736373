export const KaputtRobotIcon = () => (
  <svg
    width="98"
    height="57"
    viewBox="0 0 98 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3404 36.338C11.21 32.4143 8.91069 29.632 7.20474 30.1232C6.67674 30.2753 4.93524 30.941 4.45914 31.0781C2.75319 31.5697 2.28669 35.1488 3.41694 39.0727C4.54749 42.9964 6.84684 45.7786 8.55264 45.2873C9.08064 45.1352 10.8221 44.4697 11.2982 44.3324C13.0043 43.8409 13.4708 40.262 12.3404 36.338Z"
      fill="#FDBD74"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.2944 44.3306C13.0003 43.8392 13.467 40.2601 12.3367 36.3364C11.2064 32.4127 8.90727 29.6304 7.20136 30.1218C5.49546 30.6132 5.02881 34.1923 6.15907 38.116C7.28933 42.0396 9.5885 44.822 11.2944 44.3306Z"
      fill="#E49307"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.57959 39.677C8.57959 39.677 9.43339 42.7951 12.5408 42.688C12.5408 42.688 13.3326 35.3353 11.511 31.7993C11.511 31.7993 10.1298 32.5085 9.54049 34.0087C8.95129 35.509 8.57959 39.677 8.57959 39.677Z"
      fill="#4A1D3B"
    />
    <path
      d="M15.9861 40.9453C15.9264 40.9453 15.8664 40.943 15.8056 40.9384C14.649 40.8488 13.6168 39.9358 12.8994 38.3671C12.3376 37.139 11.9917 35.5604 11.9503 34.0364C11.9301 33.2891 11.9787 32.5861 12.0915 31.9348C9.33071 33.1805 11.0157 38.8502 11.034 38.9084C11.2462 39.5863 10.8688 40.3075 10.191 40.5197C9.51341 40.7321 8.7919 40.3546 8.57965 39.6769C8.55445 39.5963 7.96255 37.6819 7.9387 35.5408C7.90375 32.3948 9.151 30.2228 11.4508 29.425C12.057 29.2147 12.6468 29.099 13.2121 29.0768C14.0365 27.9026 15.2355 27.1555 16.726 26.9585C19.5549 26.5846 22.1197 27.9992 24.1419 31.0492C25.5559 33.182 26.2171 35.3884 26.2447 35.4811C26.446 36.1621 26.0571 36.8774 25.3761 37.0787C24.6957 37.28 23.9809 36.892 23.7789 36.2119C23.7571 36.139 21.5478 28.9177 17.0628 29.5081C16.6542 29.5619 16.3096 29.6914 16.0194 29.8757C16.3492 30.1043 16.6584 30.3815 16.9432 30.7058C18.9561 32.998 19.2748 37.2607 18.2061 39.3982C17.7105 40.3898 16.9084 40.9453 15.9861 40.9453ZM14.6832 32.0854C14.6187 32.3809 14.577 32.6774 14.5512 32.9615C14.4394 34.193 14.6067 35.5649 15.0099 36.7256C15.3195 37.6169 15.6828 38.1064 15.8959 38.2928C16.3392 37.5397 16.4974 34.8293 15.4128 32.9678C15.1948 32.5937 14.9529 32.3 14.6832 32.0854Z"
      fill="#F486B8"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M84.1661 48.4058C85.3326 47.413 85.0337 46.6004 83.5019 46.6004H37.1192C35.5874 46.6004 34.334 47.8537 34.334 49.3856V52.586C34.334 54.1178 35.5874 55.3712 37.1192 55.3712H73.197C74.729 55.3712 76.9367 54.5586 78.1032 53.5658L84.1661 48.4058Z"
      fill="#4A1D3B"
    />
    <path
      d="M84.1661 48.4058C85.3326 47.413 85.0337 46.6004 83.5019 46.6004H37.1192C35.5874 46.6004 34.334 47.8537 34.334 49.3856V52.586C34.334 54.1178 35.5874 55.3712 37.1192 55.3712H73.197C74.729 55.3712 76.9367 54.5586 78.1032 53.5658L84.1661 48.4058Z"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.1834 14.3809H33.9372C31.9893 14.3809 30.3715 15.3232 28.8967 16.3645C25.9594 18.4379 21.4095 21.6152 20.0706 22.9541C18.7314 24.2933 17.9031 26.1431 17.9031 28.1866V40.5652C17.9031 44.6519 21.2161 47.9648 25.303 47.9648H28.9369C28.8165 48.2764 28.7476 48.6139 28.7476 48.968V52.5859C28.7476 54.124 29.9946 55.3711 31.5328 55.3711H60.3192C61.0618 55.3711 61.7362 55.0804 62.2356 54.6068C62.7706 54.0992 70.419 48.6533 70.9228 48.149C71.4267 47.645 71.7387 46.9486 71.7387 46.1797V45.8384C73.5558 44.5577 78.9538 40.7848 80.2809 39.5228C81.699 38.1748 82.5832 36.2701 82.5832 34.159V21.7807C82.5832 17.6938 79.27 14.3809 75.1834 14.3809Z"
      fill="#E269A4"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M66.549 20.7869H25.303C21.2161 20.7869 17.9031 24.0998 17.9031 28.1866V40.5652C17.9031 44.6519 21.2161 47.9648 25.303 47.9648H28.9369C28.8165 48.2764 28.7476 48.6139 28.7476 48.968V52.5859C28.7476 54.124 29.9946 55.3711 31.5328 55.3711H60.3192C61.8576 55.3711 63.1045 54.124 63.1045 52.5859V48.968C63.1045 48.614 63.0358 48.2765 62.9152 47.9648H66.5491C70.6359 47.9648 73.9491 44.6519 73.9491 40.5652V28.1866C73.9489 24.0998 70.6357 20.7869 66.549 20.7869Z"
      fill="#F486B8"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.2302 36.6805C79.495 36.6805 80.5203 33.5214 80.5203 29.6245C80.5203 25.7276 79.495 22.5685 78.2302 22.5685C76.9655 22.5685 75.9402 25.7276 75.9402 29.6245C75.9402 33.5214 76.9655 36.6805 78.2302 36.6805Z"
      fill="#4A1D3B"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.2302 22.5687C77.8003 22.5687 77.398 22.9346 77.0542 23.57C77.7215 24.803 78.1684 27.0525 78.1684 29.6246C78.1684 32.1966 77.7215 34.4465 77.0542 35.6793C77.398 36.3144 77.8003 36.6806 78.2302 36.6806C79.4951 36.6806 80.5204 33.5213 80.5204 29.6246C80.5204 25.7279 79.4951 22.5687 78.2302 22.5687Z"
      fill="#CB5D94"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.6667 19.5906C53.8455 19.5906 58.0437 18.3436 58.0437 16.8054C58.0437 15.2672 53.8455 14.0202 48.6667 14.0202C43.488 14.0202 39.2898 15.2672 39.2898 16.8054C39.2898 18.3436 43.488 19.5906 48.6667 19.5906Z"
      fill="#4A1D3B"
    />
    <path
      d="M55.3825 16.2206C55.3825 17.7872 51.6415 19.0568 47.0269 19.0568C42.412 19.0568 38.6711 17.7872 38.6711 16.2206C38.6711 14.6541 41.8909 10.778 47.0269 10.778C52.1629 10.778 55.3825 14.6541 55.3825 16.2206Z"
      fill="#FDBD74"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.1902 12.4213C45.1902 12.4213 48.6978 14.3296 53.3397 13.4011L50.7403 11.3586C50.7403 11.3586 48.9039 12.7926 45.1902 12.4213Z"
      fill="#4A1D3B"
    />
    <path
      d="M47.0268 12.7631C49.999 12.7631 52.4084 10.3537 52.4084 7.38155C52.4084 4.4094 49.999 2 47.0268 2C44.0547 2 41.6453 4.4094 41.6453 7.38155C41.6453 10.3537 44.0547 12.7631 47.0268 12.7631Z"
      fill="#FDBD74"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.9355 42.0985C36.3147 42.0985 39.8647 38.5485 39.8647 34.1694C39.8647 29.7902 36.3147 26.2402 31.9355 26.2402C27.5564 26.2402 24.0063 29.7902 24.0063 34.1694C24.0063 38.5485 27.5564 42.0985 31.9355 42.0985Z"
      fill="#4A1D3B"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.5371 40.1716C36.0842 40.1716 39.7704 36.4854 39.7704 31.9383C39.7704 27.3911 36.0842 23.7049 31.5371 23.7049C26.9899 23.7049 23.3037 27.3911 23.3037 31.9383C23.3037 36.4854 26.9899 40.1716 31.5371 40.1716Z"
      fill="#CB5D94"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.1438 41.4574C34.6909 41.4574 38.3771 37.7712 38.3771 33.2241C38.3771 28.6769 34.6909 24.9907 30.1438 24.9907C25.5966 24.9907 21.9104 28.6769 21.9104 33.2241C21.9104 37.7712 25.5966 41.4574 30.1438 41.4574Z"
      fill="#F486B8"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.1438 38.7986C33.2225 38.7986 35.7182 36.3029 35.7182 33.2242C35.7182 30.1455 33.2225 27.6497 30.1438 27.6497C27.0651 27.6497 24.5693 30.1455 24.5693 33.2242C24.5693 36.3029 27.0651 38.7986 30.1438 38.7986Z"
      fill="#FDBD74"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.0803 39.023L31.8012 41.1854C31.8012 41.1854 36.1268 40.1945 37.4784 36.86L35.4059 35.5985C35.4059 35.5985 33.243 38.2117 31.0803 39.023Z"
      fill="#4A1D3B"
    />
    <path
      d="M60.0095 42.0985C64.3886 42.0985 67.9386 38.5485 67.9386 34.1694C67.9386 29.7902 64.3886 26.2402 60.0095 26.2402C55.6303 26.2402 52.0803 29.7902 52.0803 34.1694C52.0803 38.5485 55.6303 42.0985 60.0095 42.0985Z"
      fill="#4A1D3B"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.6105 40.1716C64.1577 40.1716 67.8439 36.4854 67.8439 31.9383C67.8439 27.3911 64.1577 23.7049 59.6105 23.7049C55.0634 23.7049 51.3772 27.3911 51.3772 31.9383C51.3772 36.4854 55.0634 40.1716 59.6105 40.1716Z"
      fill="#CB5D94"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.2177 41.4574C62.7649 41.4574 66.4511 37.7712 66.4511 33.2241C66.4511 28.6769 62.7649 24.9907 58.2177 24.9907C53.6706 24.9907 49.9844 28.6769 49.9844 33.2241C49.9844 37.7712 53.6706 41.4574 58.2177 41.4574Z"
      fill="#F486B8"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.2178 38.7986C61.2964 38.7986 63.7922 36.3029 63.7922 33.2242C63.7922 30.1455 61.2964 27.6497 58.2178 27.6497C55.1391 27.6497 52.6433 30.1455 52.6433 33.2242C52.6433 36.3029 55.1391 38.7986 58.2178 38.7986Z"
      fill="#FDBD74"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.1543 39.023L59.8752 41.1854C59.8752 41.1854 64.2007 40.1945 65.5522 36.86L63.4798 35.5985C63.4798 35.5985 61.317 38.2117 59.1543 39.023Z"
      fill="#4A1D3B"
    />
    <path
      d="M60.3109 50.9637C60.3109 51.9891 59.4796 52.8206 58.4541 52.8206H33.2944C32.269 52.8206 31.4377 51.9891 31.4377 50.9637V48.4569C31.4377 47.4315 32.269 46.6001 33.2944 46.6001H58.4542C59.4798 46.6001 60.3111 47.4315 60.3111 48.4569V50.9637H60.3109Z"
      fill="#FFE0CC"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M63.0961 50.6854L67.9237 47.9L62.9155 47.9648L63.0961 50.6854Z"
      fill="#4A1D3B"
    />
    <path
      d="M34.8286 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.9358 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.0432 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.1504 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.2578 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.3652 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.4727 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.5796 46.7064V52.5996"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.7163 42.5864L47.6337 46.5287L50.8479 42.7682L38.7163 42.5864Z"
      fill="#4A1D3B"
    />
    <path
      d="M44.6733 35.3826L38.7163 42.5865L43.8973 44.0054L50.6301 42.5865L44.6733 35.3826Z"
      fill="#CB5D94"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.6729 35.3826L43.897 44.0054"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M87.8466 28.1691C87.2584 27.9128 86.6794 27.7523 86.1174 27.6864C85.3858 26.4522 84.2479 25.6151 82.777 25.304C80.3709 24.7947 78.0595 25.6263 76.026 27.7116C75.9705 28.3203 75.9402 28.9611 75.9402 29.6246C75.9402 30.4277 75.9843 31.1984 76.0645 31.9176C77.2503 29.9009 79.4043 27.2189 82.2448 27.8199C82.648 27.9051 82.9816 28.0607 83.2569 28.2668C82.9102 28.4694 82.5807 28.7217 82.2718 29.0234C80.0884 31.1537 79.4424 35.3793 80.3434 37.5927C80.7883 38.6859 81.6175 39.3129 82.6182 39.3129C85.0126 39.3129 86.6595 35.9205 86.9935 32.7287C87.0714 31.9851 87.0769 31.2804 87.0147 30.6224C89.656 32.072 87.5539 37.6014 87.532 37.6568C87.2682 38.3162 87.589 39.0645 88.2483 39.3282C88.4049 39.3908 88.5664 39.4205 88.7254 39.4205C89.2357 39.4205 89.7186 39.1146 89.9197 38.6118C89.9511 38.5337 90.6886 36.6704 90.8773 34.5374C91.1542 31.4036 90.078 29.142 87.8466 28.1691ZM83.7357 35.1743C83.3583 36.039 82.9584 36.4989 82.7317 36.6686C82.3476 35.8833 82.3987 33.1691 83.6235 31.3965C83.8696 31.0404 84.1333 30.7662 84.4189 30.573C84.4605 30.8724 84.4792 31.1715 84.4831 31.4571C84.4995 32.6933 84.2272 34.0481 83.7357 35.1743Z"
      fill="#F486B8"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.7505 27.332L76.8755 26.4749L77.4113 29.9038L76.1336 31.9595L75.7505 27.332Z"
      fill="#4A1D3B"
    />
    <path
      d="M82.6809 28.6628C82.6809 28.6628 84.4578 27.3408 86.4279 27.7326"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M83.9038 31.0352C83.9038 31.0352 85.2435 29.187 87.4413 30.9267"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M84.5208 30.6694L84.665 31.7505C84.665 31.7505 86.515 32.2071 86.8994 32.9037L87.0676 30.7654C87.0676 30.7654 85.7461 29.9487 84.5208 30.6694Z"
      fill="#4A1D3B"
    />
    <path
      d="M96.6779 29.3223C96.2579 29.0844 94.8481 28.1961 94.4147 27.9504C93.2821 27.3087 90.6847 29.7517 88.6132 33.4071C86.5414 37.0626 85.7803 40.5463 86.9132 41.1881C87.3331 41.426 88.7431 42.3143 89.1763 42.56C90.3091 43.2016 92.9065 40.7587 94.978 37.1033C97.0495 33.4478 97.8106 29.964 96.6779 29.3223Z"
      fill="#E49307"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M89.1763 42.5654C90.309 43.2074 92.9068 40.7645 94.9786 37.109C97.0505 33.4536 97.8118 29.9698 96.6791 29.3278C95.5464 28.6858 92.9486 31.1287 90.8767 34.7842C88.8049 38.4397 88.0436 41.9235 89.1763 42.5654Z"
      fill="#FDBD74"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.6407 28.5441C13.6407 28.5441 12.1499 30.1938 11.9705 32.9652"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.5323 33.2242C14.5323 33.2242 14.3249 30.2803 16.6544 29.593"
      stroke="#4A1D3B"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.9688 33.3869C33.9688 33.8633 33.5827 34.2494 33.1063 34.2494H27.1813C26.7049 34.2494 26.3188 33.8633 26.3188 33.3869C26.3188 32.9105 26.7049 32.5244 27.1813 32.5244H33.1063C33.5827 32.5244 33.9688 32.9105 33.9688 33.3869Z"
      fill="#4A1D3B"
    />
    <path
      d="M62.169 33.3869C62.169 33.8633 61.7829 34.2494 61.3065 34.2494H55.3815C54.9051 34.2494 54.519 33.8633 54.519 33.3869C54.519 32.9105 54.9051 32.5244 55.3815 32.5244H61.3065C61.7829 32.5244 62.169 32.9105 62.169 33.3869Z"
      fill="#4A1D3B"
    />
  </svg>
);
