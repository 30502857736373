import { Link } from "gatsby";
import { useState } from "react";
import { EventDirectoryItem } from "../../types/helper.types";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { Paths } from "../../utils/pathBuilders.utils";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import EventDirectoryItemDetailsPanel from "../events/EventDirectoryItemDetailsPanel";
import EventDirectoryItemEntryNeue from "../events/EventDirectoryItemEntryNeue";
import CustomerCenterPageSection from "./CustomerCenterPageSection";
import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import { fromDesktopSm } from "../../styles/breakpointsAndMediaQueries.styles";

const ThreeSplit = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromDesktopSm} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const CustomerCenterEventsSection = (props: {
  eventDirectoryItems: EventDirectoryItem[];
}) => {
  const { eventDirectoryItems } = props;

  const [selectedEvent, selectEvent] = useState<EventDirectoryItem | null>(
    null
  );
  const handleSelectEvent = (event: EventDirectoryItem | null) => {
    if (!event) {
      clearEventSelection();
      return;
    }
    selectEvent(event);
    setUrlQueryParam("event", event.slug);
  };
  const handleSelectEventThroughEntry = (item: EventDirectoryItem | null) => {
    if (!item) {
      clearEventSelection();
      return;
    }
    handleSelectEvent(item);
  };
  const clearEventSelection = () => {
    selectEvent(null);
    removeUrlQueryParam("event");
  };

  useOnMount(() => {
    const { event } = getUrlQueryParams();
    if (event) {
      const item = eventDirectoryItems.find(i => i.slug === event);
      if (item) handleSelectEvent(item);
      else selectEvent(null);
    }
  });

  return (
    <CustomerCenterPageSection
      title="Upcoming events"
      id="events"
      headerLink={<Link to={Paths.events()}>See all →</Link>}
    >
      <ThreeSplit>
        {eventDirectoryItems.slice(0, 3).map(event => {
          return (
            <EventDirectoryItemEntryNeue
              key={event.id}
              item={event}
              onSelect={handleSelectEvent}
            />
          );
        })}
      </ThreeSplit>

      {selectedEvent && (
        <EventDirectoryItemDetailsPanel
          allItems={eventDirectoryItems}
          item={selectedEvent}
          onShouldClose={clearEventSelection}
          onSelectItem={handleSelectEventThroughEntry}
        />
      )}
    </CustomerCenterPageSection>
  );
};
