import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { makeSrcSet2x } from "../../utils/srcset.utils";
import Button from "../forms/Button";
import { ColoredCardLg } from "../general/ColoredCard";
import Spacing from "../layout/Spacing";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import CornerDotSet from "../utilities/CornerDotSet";
import PageSection from "./PageSection";
import ctaElevatorImage from "../../images/tines-elevator-illustration-black.png";
import ctaElevatorImage2x from "../../images/tines-elevator-illustration-black@2x.png";
import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromPhoneLg,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";
import BookADemoButton from "../utilities/BookADemoButton";

type Props = {};

const CTAGrid = styled.div`
  ${onlyPhones} {
    text-align: center;
    padding-top: 3em;
    padding-bottom: 2em;
  }
  ${fromTablet} {
    display: grid;
    align-items: center;
    grid-gap: ${rSize("md")};
    grid-template-columns: minmax(auto, 1fr) auto;
  }
`;

const CTAGridHeader = styled.header`
  ${onlyPhones} {
    margin-bottom: 2em;
  }
`;

const CTAImageHolder = styled.figure`
  margin: 0;
  padding: 0;
  img {
    max-width: 100%;
    aspect-ratio: 337 / 416;
    ${onlyPhones} {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${onlyPhones} {
    width: 250px;
    min-width: 250px;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTablet} {
    min-width: 175px;
    max-width: 245px;
    text-align: right;
  }
  ${fromTabletMd} {
    max-width: 300px;
  }
  ${fromDesktop} {
    min-width: 200px;
  }
  picture {
    ${fromTablet} {
      transform: translateX(10%);
    }
  }
`;

const CtaButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${fromPhoneLg} {
    flex-direction: row;
  }
  ${fromTablet} {
    justify-content: flex-start;
  }
`;

const NoCodeNoPythonCTASection = (props: Props) => {
  return (
    <PageSection>
      <ColoredCardLg backgroundColor={colorsV4.warmBlack}>
        <CornerDotSet
          color={withOpacity(colorsV4.canvas, 0.5)}
          offset={rSize("cardSectionPadding", 0.5)}
          className={css`
            ${onlyPhones} {
              display: none;
            }
          `}
        />
        <CTAGrid>
          <CTAGridHeader>
            <SectionHeading2>
              No code means,
              <br />
              <Serif>no code.</Serif>
            </SectionHeading2>
            <Spacing size="1.25em" />
            <p>
              Python code restricts your team’s agility to scale your security
              processes. Discover what no-code automation can do for you.
            </p>
            <Spacing size="1.25em" />
            <CtaButtonGroup>
              <BookADemoButton children="Book a demo" />
              <Spacing inline size="1em" />
              <Button
                href={`/community-edition?utm_campaign=project-clarity-2022&utm_source=email-why-switch&utm_medium=email`}
                title="Sign up"
                appearance="outlined"
                color="white"
              />
            </CtaButtonGroup>
          </CTAGridHeader>
          <CTAImageHolder>
            <img
              src={ctaElevatorImage}
              srcSet={makeSrcSet2x(ctaElevatorImage, ctaElevatorImage2x)}
            />
          </CTAImageHolder>
        </CTAGrid>
      </ColoredCardLg>
    </PageSection>
  );
};

export default NoCodeNoPythonCTASection;
