import gsap from "gsap";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Action } from "../../types/tines.types";
import { clearAnimatedProps } from "../../utils/animations.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import ActionEntry from "./ActionEntry";
import FormActionEntry from "./FormActionEntry";
import { stretchToGridUnit } from "./utils/storyboard.utils";
import { useStoryboardContext } from "./StoryboardContext";
import { getActionPositionInStoryboardViewport } from "./utils/actions.utils";

type Props = {
  action: Action;
  isSelected?: boolean;
};

const StoryboardAction = ({ action, isSelected }: Props) => {
  const context = useStoryboardContext();
  const positionInViewport = getActionPositionInStoryboardViewport(
    action,
    context
  );
  const ref = useRef<HTMLDivElement>(null);
  const shouldBeHidden = context?.options.actionShouldBeHidden(action) ?? false;
  const [wasHidden] = useState(shouldBeHidden);
  useEffect(() => {
    if (!shouldBeHidden && wasHidden && context.ready) {
      gsap.fromTo(
        ref.current,
        {
          opacity: 0,
          scale: 1.25,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 0.25,
          onComplete: () => {
            clearAnimatedProps(ref.current, ["opacity", "scale"]);
          },
        }
      );
    }
  }, [context.ready, shouldBeHidden, wasHidden]);
  useOnMount(() => {
    if (!action.width) {
      action.width = action.record.width = stretchToGridUnit(
        ref.current?.clientWidth ?? 0,
        true
      );
    }
    if (!action.height) {
      action.height = action.record.height = stretchToGridUnit(
        ref.current?.clientHeight ?? 0
      );
    }
  });
  const attr = {
    action,
    isSelected: isSelected,
    withLinkTerminals: true,
    innerRef: ref,
    style: {
      position: "absolute",
      left: positionInViewport.x,
      top: positionInViewport.y,
      width: action.width,
      height: action.height,
    } as CSSProperties,
    onClick: () =>
      context.options.inspectable && context.updateActionSelection(action),
  };
  if (action.type === "form") return <FormActionEntry {...attr} />;
  return <ActionEntry {...attr} />;
};

export default StoryboardAction;
