import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Spacing from "../layout/Spacing";
import {
  fromTablet,
  onlyPhones,
  onlyTablets,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../styles/fonts.styles";
import PageSection from "../reusableSections/PageSection";
import { css } from "linaria";
import { CasesIllustration } from "./illustrations/CasesIllustration";
import { GridCanvas } from "../decorations/GridCanvas";
import recordsGraphics from "./illustrations/tines-cases-records-illustration.svg";
import dashboardsGraphics from "./illustrations/tines-cases-dashboards-reporting-illustration.svg";
import MultiplayerCursor from "../misc/MultiplayerCursor";

const CasesDesc = styled.p`
  position: relative;
  text-align: center;
  font-family: ${serif};
  font-size: 1.7rem;
  ${onlyPhones} {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  ${fromTablet} {
    font-size: 2.2rem;
  }
  max-width: 25em;
  margin-left: auto;
  margin-right: auto;
`;

const TopGridWrap = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const CardFigureWrap = styled.div`
  border-top: 1px solid ${colors.purple400};
  border-left: 1px solid ${colors.purple400};
  margin-left: ${rSize("lg")};
  border-radius: 1.8rem 0 0 0;
  position: relative;
  overflow: hidden;
`;

const TopGridCard = styled.div`
  text-align: left;
  border-radius: ${rSize("radius")};
  overflow: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  position: relative;
  header {
    position: relative;
    padding: ${rSize("lg")};
    z-index: 1;
  }
  h3 {
    font-size: 1.8rem;
    margin-bottom: 0.5em;
  }
  p {
    max-width: 33rem;
    font-size: 1.6rem;
    font-weight: 500;
    ${onlyTablets} {
      font-size: 1.4rem;
    }
  }
  &:nth-child(1) {
    background-color: ${colors.purple200};
    color: ${colors.purple700};
    position: relative;
    ${CardFigureWrap} {
      background-color: ${colors.white};
    }
  }
  &:nth-child(2) {
    background-color: ${colors.purple800};
    ${CardFigureWrap} {
      background-color: ${colors.purple900};
      border-color: ${colors.purple700};
    }
  }
`;

const ImgWrap = styled.div`
  aspect-ratio: 511/598;
  img {
    display: block;
    max-width: 100%;
  }
`;

const CasesMainFeatureGridSection = () => {
  return (
    <PageSection
      className={css`
        margin-top: ${rSize("sectionPadding", -1)};
      `}
    >
      <CasesIllustration />
      <Spacing size="sectionPadding" />
      <CasesDesc>
        Investigate, remediate, and report on an incident in a collaborative
        workspace with Tines’ powerful, intuitive case management.
      </CasesDesc>
      <Spacing size="sectionPadding" />
      <TopGridWrap>
        <TopGrid>
          <TopGridCard>
            <GridCanvas color={colors.purple300} parallax />
            <header>
              <h3>Records</h3>
              <p>
                Capture data across workflows to report on this in a consistent,
                structured format.
              </p>
            </header>
            <CardFigureWrap>
              <ImgWrap>
                <img src={recordsGraphics} />
              </ImgWrap>
              <MultiplayerCursor
                color="purple"
                style={{
                  top: "40%",
                  left: "66%",
                }}
              />
              <MultiplayerCursor
                color="pink"
                style={{
                  top: "15%",
                  right: "17%",
                }}
              />
            </CardFigureWrap>
            <MultiplayerCursor
              color="orange"
              className={css`
                top: 75%;
                left: 3em;
                transform: scaleX(-1);
                ${fromTablet} {
                  left: 3.75em;
                }
              `}
            />
          </TopGridCard>
          <TopGridCard>
            <GridCanvas color={colors.purple700} parallax />
            <header>
              <h3>Dashboards</h3>
              <p>
                Monitor and report on workflow performance with cases and
                records in one location.
              </p>
            </header>
            <CardFigureWrap>
              <ImgWrap>
                <img src={dashboardsGraphics} />
              </ImgWrap>
            </CardFigureWrap>
          </TopGridCard>
        </TopGrid>
      </TopGridWrap>
    </PageSection>
  );
};

export default CasesMainFeatureGridSection;
