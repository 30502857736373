import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { GRID_MAX_WIDTH } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromTablet,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = React.PropsWithChildren<{
  nav: ReactNode;
}>;

const ReportSectionFrameArticle = styled.article`
  ${fromDesktop} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${rSize("gap")};
    grid-template-areas: "nav content content content";
    max-width: calc(${rSize("lg")} * 2 + ${GRID_MAX_WIDTH}px);
    padding-left: ${rSize("lg")};
    padding-right: ${rSize("lg")};
    margin-left: auto;
    margin-right: auto;
  }
  hr {
    background-color: currentColor;
    opacity: 0.1;
    height: 2px;
    border: none;
    display: block;
    margin: 1em 0;
  }
`;

const NavContainer = styled.div`
  ${uptoDesktop} {
    position: sticky;
    top: var(--pageTopFixedElementsHeight, 0px);
    padding: 0.5em;
    z-index: 1;
  }
  ${fromDesktop} {
    grid-area: nav;
  }
`;

const ContentContainer = styled.div`
  ${fromTablet} {
    > * {
      + * {
        margin-top: ${rSize("gap")};
      }
    }
  }
  ${fromDesktop} {
    grid-area: content;
  }
`;

const ReportSectionFrame = (props: Props) => {
  return (
    <section>
      <ReportSectionFrameArticle>
        <NavContainer>{props.nav}</NavContainer>
        <ContentContainer>{props.children}</ContentContainer>
      </ReportSectionFrameArticle>
    </section>
  );
};

export default ReportSectionFrame;
