/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable camelcase */

import { checkIfIsClientAndLive, isBuildTime } from "./environment";
import { getLocalStorageItem } from "./utils/localStorage.utils";

/**
 * Adapted from intercom integration page.
 */
export const initIntercom = () => {
  if (isBuildTime) return;
  if (!checkIfIsClientAndLive()) return;
  if (getLocalStorageItem("DISABLE_INTERCOM") === "TRUE") return;
  window.intercomSettings = {
    app_id: "am0g137l",
  };
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://widget.intercom.io/widget/am0g137l`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
};
