import { MutableRefObject } from "react";

export const generateTableOfContents = (
  ref: MutableRefObject<HTMLDivElement | null>,
  includeLevels?: ("h2" | "h3" | "h4" | "h5" | "h6")[],
  mainHeading?: string
) => {
  const article = ref.current ?? null;
  const title = (article?.querySelector("h1") ??
    document.querySelector(".ArticlePageHeader h1") ??
    document.querySelector(
      ".StandardPageHeader h1"
    )) as HTMLTitleElement | null;
  const headings = Array.from(
    article?.querySelectorAll<HTMLHeadingElement>(
      includeLevels?.join(",") ?? "h2, h3"
    ) ?? []
  );
  const toc = headings
    .map(h => ({
      text: h.getAttribute("data-plaintext") || h.innerText,
      anchor: `#${h.getAttribute("id") ?? ""}`,
      level: parseInt(h.tagName[1]),
    }))
    .filter(h => h.anchor !== "#");
  if (title)
    toc.unshift({
      text: mainHeading ?? title.innerText,
      anchor: "#",
      level: 1,
    });
  return toc;
};

export type TableOfContents = ReturnType<typeof generateTableOfContents>;
