import { useState } from "react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import PageTheme from "./PageTheme";

type Props = {
  darker?: boolean;
  lightsOut?: boolean;
};

export const DARK_MODE = "DARK_MODE";
export const DARK_MODE_CLASS = `.${DARK_MODE}`;

const DarkModeInstances = new Set<string>();

/** @deprecated */
const DarkMode = (p: Props) => {
  const [id] = useState(new Date().toUTCString());
  useOnMount(() => {
    DarkModeInstances.add(id);
    document.documentElement.classList.add(DARK_MODE);
    return () => {
      DarkModeInstances.delete(id);
      if (DarkModeInstances.size === 0) {
        document.documentElement.classList.remove(DARK_MODE);
      }
    };
  });
  return (
    <>
      <PageTheme
        backgroundColor={
          p.lightsOut
            ? colorsV4.warmBlack1000
            : p.darker
            ? colorsV4.warmBlack900
            : colorsV4.warmBlack
        }
        textColor={colorsV4.white}
      />
    </>
  );
};

export default DarkMode;
