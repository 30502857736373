import { styled } from "@linaria/react";
import { shade } from "polished";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import ReactMarkdown from "react-markdown";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ReactNode } from "react";
import { BrandColorNameV4 } from "../../styles/colorsV4.styles";

type Props = {
  color?: BrandColorNameV4 | string;
  quoteBody: string;
  icon: ReactNode;
  sourceName: string;
  sourceSubheading: string;
};

const QuoteCardFullColorContainer = styled.blockquote<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${p => p.color ?? colors.purple};
  padding: 2em;
  margin: 0;
  border-radius: 1em;
  color: ${colors.white};
  text-align: center;
  font-weight: 500;
  ${fromDesktop} {
    border-radius: ${rSize("radius")};
    padding: 3.8rem;
  }
  > * {
    + * {
      margin-top: 1.6rem;
      ${fromDesktop} {
        margin-top: 2rem;
      }
    }
  }
`;
const QuoteBody = styled.div`
  max-width: 38em;
  margin-left: auto;
  margin-right: auto;
  ${fromTablet} {
    font-size: 1.8rem;
  }
  ${fromDesktop} {
    font-size: 2rem;
  }
`;
const BlockquoteCite = styled.cite`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  line-height: 1.19;
  font-size: inherit;
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
`;
const CiteInner = styled.div`
  > * {
    + * {
      margin-top: 0.35em;
    }
  }
`;

const SourceLogoImgWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 3.2rem;
  margin-top: 1em;
  svg {
    height: 100%;
    width: auto;
  }
`;

const QuoteCardFullColor = (props: Props) => {
  return (
    <QuoteCardFullColorContainer {...props}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
        <rect
          width="48"
          height="48"
          rx="24"
          fill={shade(0.2, props.color ?? colors.purple)}
        />
        <path
          d="M14.1412 22.4427L17.5803 16.5199C17.7671 16.1981 18.1112 16 18.4834 16H20.8581C21.653 16 22.1565 16.8528 21.7725 17.5488L19.3042 22.0225C19.2202 22.1747 19.1762 22.3458 19.1762 22.5196C19.1762 23.0879 19.6369 23.5486 20.2052 23.5486H21.5827C22.1594 23.5486 22.627 24.0162 22.627 24.5929V31.347C22.627 31.9237 22.1594 32.3913 21.5827 32.3913H15.0443C14.4676 32.3913 14 31.9237 14 31.347V22.9671C14 22.7829 14.0487 22.602 14.1412 22.4427Z"
          fill={colors.white}
        />
        <path
          d="M25.3563 22.4427L28.7954 16.5199C28.9822 16.1981 29.3263 16 29.6985 16H32.0732C32.8681 16 33.3716 16.8528 32.9876 17.5488L30.5193 22.0225C30.4354 22.1747 30.3913 22.3458 30.3913 22.5196C30.3913 23.0879 30.852 23.5486 31.4203 23.5486H32.7978C33.3745 23.5486 33.8421 24.0162 33.8421 24.5929V31.347C33.8421 31.9237 33.3745 32.3913 32.7978 32.3913H26.2594C25.6827 32.3913 25.2151 31.9237 25.2151 31.347V22.9671C25.2151 22.7829 25.2638 22.602 25.3563 22.4427Z"
          fill={colors.white}
        />
      </svg>

      <QuoteBody>
        <ReactMarkdown>{props.quoteBody ?? ""}</ReactMarkdown>
      </QuoteBody>

      <BlockquoteCite>
        <CiteInner>
          <div>
            <strong>{props.sourceName}</strong>
          </div>
          <div>{props.sourceSubheading}</div>
          <SourceLogoImgWrapper>{props.icon}</SourceLogoImgWrapper>
        </CiteInner>
      </BlockquoteCite>
    </QuoteCardFullColorContainer>
  );
};

export default QuoteCardFullColor;
