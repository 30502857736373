import ReportSection from "../ReportSection";
import ReportSummaryCard from "../ReportSummaryCard";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import LightbulbIcon from "../../misc/LightbulbIcon";
import Spacing from "../../layout/Spacing";
import ReportTinesCTACard from "../ReportTinesCTACard";
import NoWrap from "../../typography/Nowrap";
import { Serif } from "../../typography/Serif";

const NoCodePlaybookConclusionSection = () => {
  return (
    <ReportSection id="conclusion" darkMode>
      <ReportSummaryCard
        backgroundColor={colorsV4.warmBlack}
        textColor={colorsV4.white}
      >
        <LightbulbIcon color={colorsV4.purple} centered />
        <Spacing size="1em" />
        <h2>
          The future of security is{" "}
          <NoWrap>
            <Serif>no-code automation</Serif>
          </NoWrap>
        </h2>
        <blockquote>
          <p>
            No-code automation isn't simply taking the monotonous tasks off of
            your team's plate, but giving your team the power and accessibility
            to get creative with what they automate and evolve the efficiency of
            their workflows. Not only will your security operations become more
            streamlined, but also your team can focus more time and energy on
            scaling the security posture of your organization, too — a key goal
            for any security team in 2022.
          </p>
        </blockquote>
      </ReportSummaryCard>
      <ReportTinesCTACard />
    </ReportSection>
  );
};

export default NoCodePlaybookConclusionSection;
