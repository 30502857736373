import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { colors } from "../../styles/colors.styles";
import { cx } from "linaria";

const WorkbenchDemoTemplateOrStoryEntryWrap = styled.div`
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-template-areas: "icon text status";
  padding: 0.5em;
  align-items: center;
`;
const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.8rem;
  border: 1px solid ${colors.light300};
  svg {
    display: block;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Name = styled.div``;
const Subheading = styled.div`
  font-size: 1rem;
  opacity: 0.5;
`;
const Status = styled.div`
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  i,
  b {
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${colors.gray};
    .connected & {
      background-color: ${colors.green};
    }
  }
  i {
    opacity: 0.2;
    width: 100%;
    height: 100%;
  }
  b {
    opacity: 0.5;
    width: 38%;
    height: 38%;
  }
`;

export const WorkbenchDemoTemplateOrStoryEntry = (props: {
  icon: ReactNode;
  name: string;
  subheading: string;
  connected?: boolean;
}) => (
  <WorkbenchDemoTemplateOrStoryEntryWrap
    className={cx(props.connected && "connected")}
  >
    <IconBox>{props.icon}</IconBox>
    <Text>
      <Name>{props.name}</Name>
      <Subheading>{props.subheading}</Subheading>
    </Text>
    <Status>
      <i></i>
      <b></b>
    </Status>
  </WorkbenchDemoTemplateOrStoryEntryWrap>
);
