import React, { useState } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { resolveAfter } from "../../../utils/promises.utils";

type Props = {
  url: string;
  alt?: string;
  fallback?: JSX.Element;
  onLoad?: () => void;
  onError?: () => void;
};

export type ImageLoadState = null | "loaded" | "failed";

export default function Image({ url, alt, fallback, onLoad, onError }: Props) {
  const [loadState, setLoadState] = useState<ImageLoadState>(null);

  useOnMount(() => {
    const handleLoad = async () => {
      setLoadState("loaded");
      await resolveAfter(100);
      onLoad?.();
    };
    const handleError = () => {
      setLoadState("failed");
      onError?.();
    };
    const image = new global.Image();
    image.onload = handleLoad;
    image.onerror = handleError;
    image.src = url;
  });

  return loadState === "loaded" ? (
    <img src={url} alt={alt} />
  ) : (
    fallback || null
  );
}
