import { useRef } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import gsap from "gsap";
import { styled } from "@linaria/react";

const Container = styled.div`
  display: inline-block;
  position: relative;
  overflow: visible;
  width: 66px;
  height: 85px;
`;

const Svg = styled.svg`
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
`;

export const EscherFrameIcon = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<SVGGElement>(null);
  const shadowRef = useRef<SVGPathElement>(null);

  useOnMount(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: "top bottom",
        end: "bottom top",
        invalidateOnRefresh: true,
      },
    });
    tl.fromTo(
      frameRef.current,
      { rotate: 10, transformOrigin: "33px 34px" },
      {
        rotate: -10,
        scale: 0.9,
        duration: 1,
      }
    );
    tl.fromTo(
      frameRef.current,
      { y: 0, transformOrigin: "33px 34px" },
      {
        y: -17,
        duration: 0.5,
      },
      0
    );
    tl.fromTo(
      shadowRef.current,
      { scale: 1, transformOrigin: "25px 3px" },
      {
        scale: 0.975,
        duration: 0.5,
      },
      0
    );
    tl.to(
      frameRef.current,
      {
        y: -12,
        duration: 0.5,
      },
      0.5
    );
    tl.to(
      shadowRef.current,
      {
        scale: 1,
        duration: 0.5,
      },
      0.5
    );
    return () => {
      tl.kill();
    };
  });
  return (
    <Container ref={containerRef}>
      <Svg
        width="66"
        height="85"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      >
        <g ref={frameRef}>
          <path
            d="M11.1372 68.3553L58.9541 58.2657L64.7154 48.1491L55.2025 1L7.84121 10.6811L1.01953 19.9575L11.1372 68.3591V68.3553ZM21.6683 34.3443L19.7466 24.8173L42.0645 20.2543L44.9547 34.5794L46.6199 42.8422L24.3021 47.4053L21.6683 34.3443Z"
            fill="#EB81AF"
            stroke="#32313B"
          />
          <path
            d="M46.6237 42.8421L54.8006 41.1695L48.5569 10.2378L42.0645 20.2542L46.6199 42.8421H46.6237Z"
            fill="#D46294"
            stroke="#32313B"
          />
          <path d="M64.7118 48.1514L17.8711 57.7284" stroke="#32313B" />
          <path
            d="M52.6843 30.6904L45.6367 37.9551L46.6244 42.8418L54.8012 41.1692L52.6881 30.6865L52.6843 30.6904Z"
            fill="#32313B"
          />
          <path
            d="M19.7462 24.8179L11.5693 26.4905L17.8704 57.7305L24.3016 47.4097L19.7462 24.8217V24.8179Z"
            fill="#D976A2"
            stroke="#32313B"
          />
          <path d="M1.01953 19.9574L48.5569 10.2378" stroke="#32313B" />
          <path
            d="M13.9389 38.2364L20.9865 30.9717L19.7462 24.8208L11.5693 26.4934L13.9389 38.2402V38.2364Z"
            fill="#32313B"
          />
          <path
            d="M1 19.9575L7.82168 10.6811L55.183 1L64.692 48.1529L17.8551 57.73L24.2825 47.4053L54.7772 41.1696L48.5374 10.2379L1 19.9575Z"
            fill="#F5D0E0"
            stroke="#32313B"
          />
        </g>
      </Svg>
      <Svg width="66" height="85">
        <path
          ref={shadowRef}
          d="M37.263 84.9998C50.3589 84.9998 60.9781 83.9438 60.9781 82.6374C60.9781 81.3309 50.3627 80.2749 37.263 80.2749C24.1632 80.2749 13.5479 81.3309 13.5479 82.6374C13.5479 83.9438 24.1632 84.9998 37.263 84.9998Z"
          fill="#32313B"
        />
      </Svg>
    </Container>
  );
};
