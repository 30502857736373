import { GreenhouseJob } from "../../../graphql-types";

type Props = {
  job: Partial<GreenhouseJob>;
};

const GreenhouseJobEntryLocationDisplay = (props: Props) => {
  const firstOfficeName = (props.job.offices?.[0]?.name ?? "").trim();
  const locationName = (props.job.location?.name ?? "").trim();
  const locationNameDisplay = (
    (locationName?.includes(",")
      ? locationName?.split(",")[0]
      : locationName) ?? ""
  ).trim();
  const shouldShowLocation =
    !!locationName &&
    locationNameDisplay &&
    !firstOfficeName?.includes(locationNameDisplay);
  const shouldShowOfficeName =
    !shouldShowLocation ||
    ((props.job.offices?.length === 0 || props.job.offices?.length === 1) &&
      firstOfficeName &&
      !locationNameDisplay?.includes(firstOfficeName));
  return (
    <>
      {shouldShowOfficeName && firstOfficeName}
      {shouldShowLocation ? (
        <>
          {shouldShowOfficeName && shouldShowLocation ? "," : ""}{" "}
          {locationNameDisplay}
        </>
      ) : null}
    </>
  );
};

export default GreenhouseJobEntryLocationDisplay;
