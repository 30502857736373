import {
  DatoCmsStoryDigest,
  DatoCmsStoryGridSection,
} from "../../../../graphql-types";
import LibraryCollectionPageSectionHeader from "./LibraryCollectionPageSectionHeader";
import { StoryGrid } from "../StoryGrid";
import { convertDatoRecordToStoryDescriptor } from "../../../utils/story.utils";
import { makeSlug } from "../../../utils/string.utils";

export const StoryGridSection = ({
  section,
}: {
  section: DatoCmsStoryGridSection;
}) => {
  return (
    <div id={makeSlug(section.heading)}>
      <LibraryCollectionPageSectionHeader section={section} />
      {section.stories && (
        <StoryGrid
          stories={section.stories.map(s =>
            convertDatoRecordToStoryDescriptor(s as DatoCmsStoryDigest)
          )}
        />
      )}
    </div>
  );
};
