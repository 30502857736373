import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../../styles/colors.styles";
import Spacing from "../../layout/Spacing";
import { Separator } from "../../utilities/Hr";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2SectionSubheading } from "../components/ReportV2SectionSubheading";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { reportV2BodyTextParagraphStyle } from "../components/ReportV2TextBlock";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";
import { ThreeDGlasses } from "./icons/ThreeDGlasses";

const Header = styled.header`
  ${onlyPhones} {
    display: grid;
    grid-template-areas: "icon" "text";
    grid-gap: 2em;
  }
  ${fromTablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const HeaderInner = styled.div`
  ${onlyPhones} {
    grid-area: text;
  }
  ${fromTablet} {
    padding-right: 2em;
  }
`;

const IconWrap = styled.div`
  ${onlyPhones} {
    grid-area: icon;
  }
  svg {
    flex: 0 0 auto;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
  > * {
    background-color: ${withOpacity(colors.purple900, 0.15)};
    padding: ${rSize("lg")} ${rSize("xl")} ${rSize("lg")} ${rSize("lg")};
    border-radius: ${rSize("radius")};
  }
  b {
    display: block;
    font-family: ${serif};
    font-weight: 400;
    margin-bottom: 1.4rem;
    font-size: 1.8rem;
    ${fromTabletMd} {
      font-size: 2.2rem;
    }
  }
  h3 {
    font-size: 1.8rem;
    line-height: 1.2;
    ${fromTabletMd} {
      font-size: 2.2rem;
    }
    span {
      ${fromDesktopMd} {
        display: block;
      }
    }
    * + & {
      margin-top: 1.4rem;
    }
    + * {
      margin-top: 1.4rem;
    }
  }
  p {
    ${reportV2BodyTextParagraphStyle}
  }
`;

export const CisoAIKeyFindings = () => (
  <ReportV2Section
    id="key-findings"
    backgroundColor={colors.purple}
    color={colors.white}
    backdropDotGridColor={colors.purple600}
  >
    <Header>
      <HeaderInner>
        <ReportV2SectionHeadingMedium>
          Key findings
        </ReportV2SectionHeadingMedium>
        <ReportV2SectionSubheading>
          Here are a few of the insights we learned from the Chief Information
          Security Officers we surveyed.
        </ReportV2SectionSubheading>
      </HeaderInner>
      <IconWrap>
        <ThreeDGlasses />
      </IconWrap>
    </Header>

    <Spacing size="xl" />
    <Separator />
    <Spacing size="xl" />

    <ContentGrid>
      <div>
        <b>#1</b>
        <h3>
          94% are concerned about the increased pressure on their security teams
          due to AI adoption.
        </h3>
      </div>
      <div>
        <b>#2</b>
        <h3>66% consider data privacy a challenge to AI adoption.</h3>
      </div>
      <div>
        <b>#3</b>
        <h3>49% view inflexible technologies as a challenge to AI adoption.</h3>
      </div>
      <div>
        <b>#4</b>
        <h3>
          74% believe faster decision-making by AI systems will introduce more
          benefits to the SOC than risks.
        </h3>
      </div>
      <div>
        <b>#5</b>
        <h3>51% say AI will make it easier to hire entry-level employees.</h3>
      </div>
    </ContentGrid>
  </ReportV2Section>
);
