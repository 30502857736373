export const LibraryTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C5.44772 4 5 4.44771 5 5V13H6.5V8.5C6.5 7.11929 7.61929 6 9 6C10.3807 6 11.5 7.11929 11.5 8.5V13H13V5C13 4.44772 12.5523 4 12 4H6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 14H13.5C13.7761 14 14 13.7761 14 13.5V3.5C14 3.22386 13.7761 3 13.5 3H4.5C4.22386 3 4 3.22386 4 3.5V13.5C4 13.7761 4.22386 14 4.5 14H5V13H6V12V11H6.5V8.5C6.5 7.11929 7.61929 6 9 6C10.3807 6 11.5 7.11929 11.5 8.5V11H12V12V13H13V14ZM12 14H11H7H6V15H12V14ZM10.5 11V8.5C10.5 7.67157 9.82843 7 9 7C8.17157 7 7.5 7.67157 7.5 8.5V11H10.5ZM7.5 12H7V13H11V12H10.5H7.5ZM4.5 15H5V16H6H12H13V15H13.5C14.3284 15 15 14.3284 15 13.5V3.5C15 2.67157 14.3284 2 13.5 2H4.5C3.67157 2 3 2.67157 3 3.5V13.5C3 14.3284 3.67157 15 4.5 15Z"
    />
  </svg>
);
