import { PropsWithChildren, createElement, useRef } from "react";
import { copyToClipboard } from "../../utils/clipboard.utils";

const ClickToCopy = (
  props: PropsWithChildren<{
    className?: string;
    as?: keyof HTMLElementTagNameMap;
    successMessage?: string;
    successMessageBuilder?: (content: string) => string;
    title?: string;
    contentToCopy?: string;
  }>
) => {
  const ref = useRef<HTMLDivElement>(null);
  const copy = () => {
    const text = props.contentToCopy ?? ref.current?.innerText;
    if (!text) return;
    copyToClipboard(
      text,
      props.successMessageBuilder
        ? props.successMessageBuilder(text)
        : props.successMessage
    );
  };
  return createElement(props.as ?? "div", {
    ref,
    onClick: copy,
    className: props.className,
    title: props.title,
    children: props.children,
  });
};

export default ClickToCopy;
