import { styled } from "@linaria/react";
import { css } from "linaria";
import {
  fromDesktopMd,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import SpotIllustration from "../illustrations/SpotIllustration";
import Spacing from "../layout/Spacing";
import { SectionHeading2 } from "../typography/SectionHeading2";
import PageSection from "./PageSection";
import { Paths } from "../../utils/pathBuilders.utils";
import { colors, withOpacity } from "../../styles/colors.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "header blank ." "header . ." " . . .";
  }
  header {
    ${fromTabletMd} {
      grid-area: header;
      padding-top: ${rSize("gap")};
      h2 {
        margin-right: -100%;
      }
    }
    ${uptoTabletMd} {
      text-align: center;
      h2 {
        max-width: 9em;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  > span {
    display: none;
    ${fromTabletMd} {
      display: block;
      grid-area: blank;
    }
  }
  > a {
    display: grid;
    text-decoration: none;
    grid-template-columns: auto minmax(0, 1fr);
    background-color: ${colors.purple600};
    ${uptoTabletMd} {
      align-items: center;
      min-height: 9em;
    }
    ${fromTabletMd} {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: minmax(0, 1fr) auto;
    }
    grid-gap: ${rSize("gap")};
    padding: ${rSize("gap")};
    border-radius: ${rSize("radius")};
    &:hover {
      background-color: ${withOpacity(colors.purple600, 0.85)};
    }
    img {
      display: inline-block;
      width: 8rem;
      height: 8rem;
      ${fromTabletMd} {
        width: 10rem;
        height: 10rem;
      }
      margin-top: -0.5rem;
    }
    h3 {
      line-height: 1;
    }
    p {
      font-size: 1.4rem;
      opacity: 0.6;
      margin-top: 0.75em;
      font-weight: 500;
      max-width: 16em;
      line-height: 1.25;
      ${fromDesktopMd} {
        line-height: 1.4;
      }
    }
  }
`;

const SupportOptionsGridSection = () => {
  return (
    <PageSection>
      <Grid>
        <header>
          <SectionHeading2 className="serif blockSpan">
            <span>With you, </span>
            <span>every step of the way</span>
          </SectionHeading2>
          <Spacing size="1em" />
          <p>Our team is here to support you through your entire experience.</p>
          <Spacing size="3em" />
        </header>
        <span />

        <a
          href={Paths.supportReferenceGuide()}
          target="_blank"
          rel="noreferrer"
        >
          <SpotIllustration
            name="retroMobilePhone"
            className={css`
              ${fromTabletMd} {
                margin-left: -3rem;
              }
            `}
          />
          <div>
            <h3>Support</h3>
            <p>
              Get in touch with our award-winning support and services teams to
              help build smart workflows.
            </p>
          </div>
        </a>

        <a href={Paths.library.home()} target="_blank" rel="noreferrer">
          <SpotIllustration
            name="storyLibrary"
            className={css`
              ${fromTabletMd} {
                margin-left: -1.5rem;
              }
            `}
          />
          <div>
            <h3>Library</h3>
            <p>
              Inspiration through expertly built and curated examples of Tines
              in action.
            </p>
          </div>
        </a>
        <a href={Paths.docs()} target="_blank" rel="noreferrer">
          <SpotIllustration
            name="lightbulbOnBook"
            className={css`
              ${fromTabletMd} {
                margin-left: -1.5rem;
              }
            `}
          />
          <div>
            <h3>Docs</h3>
            <p>Review technical documentation on the platform and APIs.</p>
          </div>
        </a>

        <a href={Paths.slackCommunity()} target="_blank" rel="noreferrer">
          <SpotIllustration
            name="slackCommunity"
            className={css`
              ${fromTabletMd} {
                margin-left: -1.5rem;
              }
            `}
          />
          <div>
            <h3>Community</h3>
            <p>
              Interact with other users or our team through our Slack community.
            </p>
          </div>
        </a>

        <a href={Paths.university()} target="_blank" rel="noreferrer">
          <SpotIllustration
            name="university"
            className={css`
              ${fromTabletMd} {
                margin-left: -1rem;
              }
            `}
          />
          <div>
            <h3>University</h3>
            <p>
              Learn the platform capabilities and expand the ways you apply
              Tines through this curated program.
            </p>
          </div>
        </a>

        <a href={Paths.getCertified()} target="_blank" rel="noreferrer">
          <SpotIllustration
            name="certBadgeCustomer"
            className={css`
              ${fromTabletMd} {
                margin-left: -0.75rem;
              }
            `}
          />
          <div>
            <h3>Get certified</h3>
            <p>
              Gain a deep understanding of the platform and demonstrate your
              expertise.
            </p>
          </div>
        </a>

        {/* <a
          className="light"
          href={Paths.bootcamp()}
          target="_blank"
          rel="noreferrer"
        >
          <SpotIllustration name="bootcamp" />
          <div>
            <h3>Bootcamp</h3>
            <p>
              Regular hands-on training sessions to refresh your understanding
              of the basics.
            </p>
          </div>
        </a> */}
      </Grid>
    </PageSection>
  );
};

export default SupportOptionsGridSection;
