import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";
import AllCaps from "../typography/AllCaps";
import illoLeft from "./illos/customerCenterHeaderIllustrationLeft.svg";
import illoRight from "./illos/customerCenterHeaderIllustrationRight.svg";
import CustomerCenterHeroIlloMobile from "./illos/CustomerCenterHeroIlloMobile.svg";
import RigidDotGrid from "../decorations/RigidDotGrid";
import { colors } from "../../styles/colors.styles";

const CustomerCenterPageHeaderWrap = styled.header`
  position: relative;
  display: grid;
  ${widerPageSectionMaxWidthStyle()};
  grid-template-columns: minmax(0, 1fr);
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
  padding-bottom: ${rSize("md")};
  grid-gap: ${rSize("layoutWithSidebarGap")};
  ${fromTabletLg} {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
    grid-template-columns: ${rSize("sidebarWidth")} minmax(0, 1fr);
    padding-top: 2em;
  }
`;

const RigidDotGridPositioner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -9.6rem;
`;

const HeaderContent = styled.div`
  position: relative;
  display: grid;
  grid-gap: ${rSize("gap")};
  align-items: center;
  ${uptoTabletLg} {
    text-align: center;
    margin-bottom: 2em;
  }
  ${fromTabletLg} {
    grid-template-columns: minmax(0, 1fr) auto;
  }
`;

const HeaderContentText = styled.div`
  > * {
    + * {
      margin-top: 2rem;
    }
  }
  h1 {
    font-family: ${serif};
    font-size: 3.6rem;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1;
    ${fromTablet} {
      font-size: 5.2rem;
      font-weight: 300;
      letter-spacing: -0.02em;
    }
    ${fromDesktop} {
      font-size: 6.4rem;
    }
  }
  p {
    max-width: 33em;
    ${uptoTabletLg} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const IllustrationLeftSlot = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
`;
const IllustrationLeft = styled.div`
  aspect-ratio: 275 / 336;
  height: 120px;
  ${fromTabletLg} {
    height: 250px;
  }
  ${fromDesktopMd} {
    height: 336px;
  }
  background-image: url(${illoLeft});
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(-90px);
`;

const IllustrationRightSlot = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
`;

const IllustrationRight = styled.div`
  aspect-ratio: 457 / 336;
  height: 120px;
  ${fromTabletLg} {
    height: 250px;
  }
  ${fromDesktopMd} {
    height: 336px;
  }
  background-image: url(${illoRight});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(28px);
`;

const IllustrationMobile = styled.img`
  ${uptoTabletLg} {
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
  ${fromTabletLg} {
    display: none;
  }
`;

export const CustomerCenterPageHero = ({
  description,
}: {
  description: string;
}) => {
  return (
    <CustomerCenterPageHeaderWrap>
      <RigidDotGridPositioner>
        <RigidDotGrid color={colors.light400} cellSize={48} />
      </RigidDotGridPositioner>

      <IllustrationLeftSlot>
        <IllustrationLeft />
      </IllustrationLeftSlot>
      <HeaderContent>
        <HeaderContentText>
          <IllustrationMobile src={CustomerCenterHeroIlloMobile} />
          <AllCaps as="div">Welcome</AllCaps>
          <h1>Customer center</h1>
          <p>{description}</p>
        </HeaderContentText>
        <IllustrationRightSlot>
          <IllustrationRight />
        </IllustrationRightSlot>
      </HeaderContent>
    </CustomerCenterPageHeaderWrap>
  );
};
