export const GalaxyGateIcon = () => (
  <svg
    width="58"
    height="95"
    viewBox="0 0 58 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.28516 31.4928C6.28516 18.8721 16.5166 8.64062 29.1373 8.64062C41.7584 8.64062 51.9897 18.8721 51.9897 31.4928V71.7424H6.28516V31.4928Z"
      fill="#32313B"
    />
    <path
      d="M42.9736 46.9885C43.7479 46.1546 43.6998 44.8514 42.8663 44.0769L34.3759 36.1912C33.9645 35.8089 33.4387 35.6274 32.9178 35.6414L32.9123 35.6417C32.8536 35.6433 30.0568 35.8536 30.0568 35.8536L30.0564 35.854C29.528 35.8724 29.0068 36.0922 28.6183 36.5102L24.3633 41.0913C23.5888 41.9249 23.637 43.2284 24.4706 44.0027L32.9609 51.8889C33.3984 52.2949 33.9648 52.4746 34.5164 52.4333L34.5161 52.4336L37.3481 52.2231V52.2228C37.8531 52.189 38.3471 51.97 38.7186 51.5697L42.9736 46.9885Z"
      fill="#B35980"
      stroke="#32313B"
      strokeWidth="0.883729"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.8725 51.781C35.0978 52.6143 33.7943 52.6626 32.961 51.8883L24.4706 44.002C23.6369 43.2277 23.5888 41.9242 24.3633 41.0907L28.6183 36.5096C29.3926 35.6759 30.6958 35.628 31.5295 36.4025L40.0198 44.2882C40.8535 45.0625 40.9018 46.366 40.1275 47.1997L35.8725 51.781Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.883729"
      strokeMiterlimit="10"
    />
    <path
      d="M18.1878 32.8582L19.1711 33.3961L18.1878 33.9339L17.65 34.917L17.1123 33.9339L16.1289 33.3961L17.1123 32.8582L17.65 31.875L18.1878 32.8582Z"
      fill="#F0F4FA"
    />
    <path
      d="M26.5835 28.6903L27.2428 29.0509L26.5835 29.4113L26.2228 30.0704L25.8624 29.4113L25.2031 29.0509L25.8624 28.6903L26.2228 28.0312L26.5835 28.6903Z"
      fill="#F0F4FA"
    />
    <path
      d="M33.1189 17.3396L33.6771 17.645L33.1189 17.9506L32.813 18.5089L32.5079 17.9506L31.9492 17.645L32.5079 17.3396L32.813 16.7812L33.1189 17.3396Z"
      fill="#F0F4FA"
    />
    <path
      d="M33.3426 59.6848L33.9654 60.0256L33.3426 60.3664L33.0023 60.9887L32.6616 60.3664L32.0391 60.0256L32.6616 59.6848L33.0023 59.0625L33.3426 59.6848Z"
      fill="#F0F4FA"
    />
    <path
      d="M26.4926 39.7968C26.193 38.6822 24.4144 38.1919 22.5197 38.7015C20.6255 39.2109 19.3331 40.5271 19.6331 41.6415C19.7057 41.9129 19.9723 42.9034 20.0449 43.1749C20.3447 44.2893 22.1233 44.7796 24.0176 44.2702C25.9116 43.7605 27.2045 42.4444 26.9048 41.3299C26.8319 41.0584 26.5654 40.0681 26.4926 39.7968Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.883729"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.6086 42.7298C25.5027 42.2204 26.7954 40.9042 26.4957 39.79C26.1961 38.6758 24.4176 38.1855 22.5235 38.6949C20.6293 39.2042 19.3367 40.5204 19.6363 41.6347C19.9359 42.7489 21.7144 43.2392 23.6086 42.7298Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.883729"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.2802 41.6719C27.2802 41.6719 27.2995 44.6101 25.765 45.5149L24.4648 44.0039C24.4648 44.0039 26.2537 43.3967 27.2802 41.6719Z"
      fill="#32313B"
    />
    <path
      d="M24.8818 62.6817L26.4797 56.8227L24.8735 54.4863L19.795 51.1562L15.0586 54.957L17.2096 60.6358L18.8157 62.9722L24.8818 62.6817Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
    />
    <path
      d="M17.2096 60.6358L15.0586 54.957L19.795 51.1562L24.8735 54.4863L23.2755 60.3456L17.2096 60.6358Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
    />
    <path
      d="M23.2695 60.3438L24.8758 62.6798"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
    />
    <path
      d="M21.7615 23.0893L19.4262 22.4901L18.7773 20.1679L20.464 18.4453L22.7994 19.0443L23.4479 21.3662L21.7615 23.0893Z"
      fill="#C3DE6F"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
    />
    <path
      d="M39.5719 27.5883C39.2434 27.1313 38.0413 25.4608 37.7124 25.0038C36.3281 23.0799 33.6462 22.643 31.7227 24.0273L38.5958 33.5781C40.5191 32.1938 40.9566 29.5119 39.5719 27.5883Z"
      fill="#5178B3"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
    />
    <path
      d="M31.7227 24.0272C33.6462 22.6429 36.3281 23.08 37.7124 25.0037C39.0967 26.9274 38.6592 29.6092 36.7359 30.9938L31.7227 24.0272Z"
      fill="#6DA2F0"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
    />
    <path
      d="M54.063 71.7154L49.4676 77.074L47.0482 71.7154V29.9397C47.0482 19.9697 38.9368 11.8586 28.9669 11.8586C18.997 11.8586 10.886 19.9697 10.886 29.9397V71.7152L8.46649 77.0738L3.87109 71.7152V29.9397C3.87109 16.1019 15.1291 4.84375 28.9671 4.84375C42.805 4.84375 54.0632 16.1019 54.0632 29.9397V71.7154H54.063Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.4202 77.0852H49.4666V29.7198C49.4666 18.4156 40.27 9.21919 28.9659 9.21919C17.6617 9.21919 8.46528 18.4156 8.46528 29.7198V77.0852H0.511719V29.7198C0.511719 14.03 13.2763 1.26562 28.9659 1.26562C44.6556 1.26562 57.4202 14.0302 57.4202 29.7198V77.0852Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.2661 78.9062H30.7212H27.2301H13.685L8.84375 92.591V94.3588H49.1072L49.0676 92.4535L44.2661 78.9062Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.2661 78.9062H30.7212H27.2301H13.685L8.84375 92.591H27.2301H30.7212H49.1072L44.2661 78.9062Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.706984"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
