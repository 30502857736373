import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { colors, withOpacity } from "../../styles/colors.styles";
import {
  breakpoints,
  fromDesktop,
  fromDesktopLg,
  fromDesktopMd,
  fromDesktopSm,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import { cx } from "linaria";
import { font, serif } from "../../styles/fonts.styles";
import { Link } from "gatsby";
import AllCaps from "../typography/AllCaps";
import gsap from "gsap";
import { homeFindYourFlowSectionTeams as teams } from "./findYourFlow/teamsAndUseCasesConfig";
import { useViewportSize } from "../../utils/window.utils";
import {
  getOffsetInScrollParent,
  hideScrollbarsCSS,
} from "../../utils/scroll.utils";
import { scrollEase } from "../../utils/gsap.utils";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { makeRandomId } from "../../utils/id.utils";
import { removeOneFromArray } from "../../utils/array.utils";
import {
  getXYFromMouseOrTouchEvent,
  isTouchEvent,
} from "../../utils/touch.utils";
import { UseCaseKaleidoscope } from "./findYourFlow/UseCaseKaleidoscope";

gsap.registerPlugin(ScrollToPlugin);

const whenSlideshowLayout = uptoTabletMd;
const whenFoldedLayout = fromTabletMd;

const Positioner = styled.div`
  ${onlyPhones} {
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
  }
  ${fromTablet} {
    margin-left: ${rSize("widerPageMargin", -1)};
    margin-right: ${rSize("widerPageMargin", -1)};
  }
  ${whenFoldedLayout} {
    margin-left: 0;
    margin-right: 0;
  }
  ${fromDesktopLg} {
    padding-left: ${rSize("widerPageMargin", 2)};
    padding-right: ${rSize("widerPageMargin", 2)};
  }
`;

const Wrap = styled.div`
  position: relative;
  overflow: hidden;
  ${whenFoldedLayout} {
    display: grid;
    grid-template-columns: 9fr 5fr;
  }
  ${fromTabletLg} {
    grid-template-columns: 4fr 3fr;
  }
  ${fromDesktopMd} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Start = styled.div`
  position: relative;
  z-index: 1;
  ${whenSlideshowLayout} {
    text-align: center;
  }
  ${whenFoldedLayout} {
    display: grid;
    grid-template-columns: 180px 2fr;
    background-color: ${colors.purple600};
    border: 1px solid ${colors.purple400};
    border-radius: 0.8rem 0 0 0.8rem;
    margin-right: -1px;
    margin-top: 3.6rem;
    border-right: 1px solid ${colors.purple400};
  }
  ${fromDesktop} {
    grid-template-columns: 200px 2fr;
  }
  ${fromDesktopSm} {
    grid-template-columns: 232px 2fr;
  }
`;

const Index = styled.div`
  ${whenSlideshowLayout} {
    padding: 0 1em;
  }
  ${whenFoldedLayout} {
    border-right: 1px solid ${colors.purple400};
  }
`;

const Header = styled.header`
  padding: 2.4rem 0;
  ${whenFoldedLayout} {
    padding: 3.6rem 2.8rem;
    border-bottom: 1px solid ${colors.purple400};
    user-select: none;
  }
  ${fromDesktopSm} {
    padding: 4rem 3.8rem;
  }
  h2 {
    ${whenSlideshowLayout} {
      line-height: 1.1;
      letter-spacing: -0.01em;
      font-family: ${font("serif")};
      font-weight: 400;
      text-align: center;
      font-size: 3.2rem;
    }
    ${whenFoldedLayout} {
      text-transform: uppercase;
      font-size: 1.15rem;
      font-weight: 600;
      letter-spacing: 0.1em;
    }
  }
`;

const MobilePaginator = styled.div`
  ${whenSlideshowLayout} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em auto 0 auto;
    width: 110px;
    background-color: ${colors.purple400};
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 2px 5px;
    position: relative;
    &:before,
    &:after {
      content: "";
      width: 5px;
      height: 5px;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
    }
    &:before {
      right: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 5C2.76172 5 5 2.76141 5 0V5H0Z' fill='%23A990F5'/%3E%3C/svg%3E%0A");
    }
    &:after {
      left: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 5C2.23858 5 0 2.76142 0 0V5H5Z' fill='%23A990F5'/%3E%3C/svg%3E%0A");
    }
  }
  ${whenFoldedLayout} {
    display: none;
  }
`;

const PaginatorItem = styled.button`
  display: flex;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    background-color: ${colors.purple500};
    padding: 0;
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }
  &.active {
    &:before {
      background-color: ${colors.white};
    }
  }
`;

const ListAreaOuter = styled.div`
  ${whenSlideshowLayout} {
    display: flex;
    justify-content: center;
  }
`;

const ListArea = styled.div`
  user-select: none;
  font-size: 14px;
  ${whenSlideshowLayout} {
    border: 1px solid ${colors.purple400};
    border-radius: 3em;
    overflow: hidden;
    position: relative;
    margin: auto;
    user-select: none;
  }
  ${onlyPhones} {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 48px;
      border-radius: 0 3em 3em 0;
      background-image: linear-gradient(
        to left,
        ${colors.purple},
        ${withOpacity(colors.purple, 0)}
      );
      pointer-events: none;
      z-index: 1;
      transition: opacity 0.3s;
      .hasReachedEnd & {
        opacity: 0;
      }
    }
  }
  ${whenFoldedLayout} {
    font-size: 16px;
    padding-top: 1em;
    padding-bottom: 8em;
  }
  ${fromDesktop} {
    padding-bottom: 9em;
  }
  ${fromDesktopMd} {
    padding-bottom: 10em;
  }
`;

const ListAreaMobileScrollContainer = styled.div`
  ${whenSlideshowLayout} {
    overflow: auto;
    position: relative;
    background-color: ${colors.purple};
  }
`;

const ActiveItemBackdrop = styled.div`
  position: absolute;
  top: 0;
  ${whenSlideshowLayout} {
    bottom: 0;
    opacity: 0;
    transition: 0.1s;
    .active & {
      opacity: 1;
    }
  }
  ${whenFoldedLayout} {
    top: 0.1em;
    height: 2.8em;
  }
  left: 0;
  right: 0;
  background-color: ${withOpacity(colors.purple400, 0.3)};
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  pointer-events: none;
  svg {
    position: absolute;
    top: 50%;
    right: -1px;
    transform: translateY(-50%);
  }
`;

const ActiveItemProgress = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${withOpacity(colors.purple400, 0.5)};
  transition: opacity 0.2s;
`;

const ActiveItemTriangle = () => (
  <svg width="15" height="30" viewBox="0 0 15 30" fill="none">
    <path
      d="M1 15L16 0V30L1 15Z"
      fill={colors.purple600}
      stroke={colors.purple400}
    />
  </svg>
);

const List = styled.div`
  position: relative;
  font-size: 1.4rem;
  ${whenSlideshowLayout} {
    display: inline-flex;
  }
  ${fromDesktop} {
    font-size: 1.6rem;
  }
  &:hover {
    ${ActiveItemProgress} {
      opacity: 0;
    }
  }
`;

const ListItem = styled.button`
  appearance: none;
  position: relative;
  display: flex;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  font-weight: 500;
  width: auto;
  cursor: pointer;
  white-space: nowrap;
  ${whenSlideshowLayout} {
    border-radius: 2em;
    padding: 0.75em 1.25em;
    flex: 0 0 auto;
    overflow: hidden;
    font-weight: 600;
  }
  ${whenFoldedLayout} {
    width: 100%;
    padding: 0 2.8rem;
    height: 3em;
  }
  ${fromDesktopSm} {
    padding: 0 3.8rem;
  }
  &.inactive {
    ${whenSlideshowLayout} {
      opacity: 0.5;
    }
    &:hover {
      background-color: ${withOpacity(colors.purple400, 0.15)};
      ${whenSlideshowLayout} {
        opacity: 1;
      }
    }
  }
  svg {
    ${whenSlideshowLayout} {
      display: none;
    }
    ${whenFoldedLayout} {
      position: relative;
      flex: 0 0 auto;
      margin-right: 1em;
    }
  }
  span {
    position: relative;
    display: block;
  }
  &.mobileBackToStartButton {
    ${whenSlideshowLayout} {
      width: calc(100vw - 8.5em);
      justify-content: flex-end;
      opacity: 0.5;
      transition: 0.2s;
      &:active {
        transition: 0.05s;
        opacity: 0.3;
      }
    }
    ${whenFoldedLayout} {
      display: none;
    }
  }
`;

const DescriptionPanel = styled.div`
  position: relative;
  ${whenSlideshowLayout} {
    overflow: hidden;
    margin-top: 1em;
    padding-bottom: 7em;
    user-select: none;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  ${whenFoldedLayout} {
    min-height: 20em;
  }
`;

const TeamDescriptionCardSet = styled.div`
  ${whenSlideshowLayout} {
    display: inline-flex;
    align-items: flex-start;
    > * {
      + * {
        margin-left: 5em;
      }
    }
    &:after {
      display: block;
      content: "";
      width: 100vw;
    }
  }
  ${fromTablet} {
    margin-left: ${rSize("pageMargin")};
    margin-right: ${rSize("pageMargin")};
  }
  ${whenFoldedLayout} {
    transform: none !important;
  }
`;

const TeamDescriptionCardOuter = styled.div`
  ${whenSlideshowLayout} {
    flex: 0 0 calc(100vw - 9em);
    padding: 1em;
  }
`;

const TeamDescriptionCard = styled.div`
  display: grid;
  grid-gap: 2em;
  text-align: left;
  ${whenSlideshowLayout} {
    grid-template-rows: minmax(auto, 1fr) auto;
    position: relative;
    background-color: ${colors.purple600};
    border: 1px solid ${colors.purple400};
    border-radius: 0.8rem;
    padding: 0.75em 0.875em 0.875em 0.875em;
    &:before {
      content: "";
      display: block;
      width: 26px;
      height: 11px;
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='11' viewBox='0 0 26 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.253 0.960046L20.3536 8.06065C20.4474 8.15442 20.5 8.2816 20.5 8.4142V9.49997C20.5 9.77611 20.2762 9.99997 20 9.99997H5.79883C5.52269 9.99997 5.29883 9.77611 5.29883 9.49997L5.29883 8.41421C5.29883 8.2816 5.35151 8.15442 5.44527 8.06065L12.5459 0.960045C12.7411 0.764783 13.0577 0.764783 13.253 0.960046Z' fill='%237F69CE' stroke='%23A990F5'/%3E%3Crect y='8' width='26' height='3' fill='%237F69CE'/%3E%3C/svg%3E%0A");
      position: absolute;
      bottom: calc(100% - 3px);
      pointer-events: none;
      left: 0.75em;
      transform: translateY(100%) scale(0);
      transform-origin: center 6px;
      transition: 0.5s;
      opacity: 0;
    }
    .active & {
      &:before {
        transform: translateY(0) scale(1);
        opacity: 1;
      }
    }
  }
  ${whenFoldedLayout} {
    grid-template-rows: minmax(auto, 1fr) minmax(3em, auto);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s;
    padding: 2.2rem;
    .inactive & {
      opacity: 0;
      z-index: 1;
      pointer-events: none;
    }
    .active & {
      opacity: 1;
      z-index: 1;
    }
  }
  ${fromDesktop} {
    padding: 2.8rem;
    padding-right: 3.8rem;
  }
`;

const TeamDescriptionCardContent = styled.div`
  h3 {
    display: none;
  }
`;

const TeamDescriptionCardFooter = styled.div`
  user-select: none;
`;

const SpotIconPositioner = styled.div`
  display: flex;
  ${whenSlideshowLayout} {
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2em;
    left: calc(100% - 1.5em);
    background-color: ${colors.purple400};
    border: 1px solid ${colors.purple300};
    border-radius: 1em;
    aspect-ratio: 1;
    padding: 1em;
    width: 100px;
    height: 100px;
    svg {
      transform: translateX(var(--offsetMobile));
      max-width: 100%;
      max-height: 100%;
    }
  }
  ${whenFoldedLayout} {
    height: 72px;
    align-items: flex-end;
    margin-left: var(--offset);
    margin-bottom: ${rSize("gap")};
    transform: none !important;
  }
`;

const Description = styled.p`
  font-family: ${serif};
  font-size: 1.8rem;
  letter-spacing: -0.01em;
  line-height: 1.25;
  ${fromPhoneLg} {
    font-size: 2rem;
  }
  ${fromTablet} {
    font-size: 2.4rem;
    max-width: 15em;
    margin-right: -1em;
    line-height: 1.2;
    text-wrap: balance; // supported in Chrome but not in safari
  }
  ${whenSlideshowLayout} {
    margin-right: 1.25em;
  }
  ${whenFoldedLayout} {
    letter-spacing: -0.02em;
    font-weight: 300;
    max-width: 9.5em;
  }
  ${fromTabletLg} {
    font-size: 2.8rem;
    margin-right: 0;
  }
  ${fromTabletLg} {
    font-size: 3rem;
  }
  ${fromDesktop} {
    font-size: 3.4rem;
    max-width: 9em;
  }
`;

const ExploreLinkWrap = styled.div`
  ${onlyPhones} {
    font-size: 1.4rem;
  }
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  a {
    font-weight: 600;
    text-decoration: none;
    span {
      display: inline-block;
      transition: transform 0.2s;
    }
    &:hover {
      text-decoration-color: currentColor;
      span {
        transform: translateX(2px);
      }
    }
    .inactive & {
      pointer-events: none;
    }
  }
`;

const End = styled.div`
  position: relative;
  ${whenSlideshowLayout} {
    height: 16em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  ${whenFoldedLayout} {
    margin-left: -10.8rem;
    margin-bottom: 3.6rem;
    border-radius: 0 0.8rem 0.8rem 0;
    background-color: ${colors.purple700};
    border: 1px solid ${colors.purple400};
  }
`;

const DiagonalLine = styled.svg`
  display: none;
  ${whenFoldedLayout} {
    display: block;
    position: absolute;
    top: -0.5px;
    left: -1px;
  }
`;

const River = styled.div`
  position: relative;
  height: 100%;
`;

const getDescriptionPanelOffset = (index: number) => {
  return (
    getOffsetInScrollParent(`[data-description-panel-id="${index}"]`).x * -1
  );
};

const touchScrollThreshold = 9;

export const HomeFindYourFlowSection = () => {
  const [ready, setReady] = useState(false);
  const [currentIndexRef, setCurrentIndex, currentIndex] = useStateWithRef(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const visibilityDetectionRef = useRef<HTMLDivElement>(null);
  const activeItemProgressRef = useRef<HTMLDivElement>(null);

  const [isAutoplayingRef, setIsAutoplaying] = useStateWithRef(false);

  const { vw } = useViewportSize();

  const tweenRef = useRef<ReturnType<typeof gsap["fromTo"]>>(
    null
  ) as unknown as MutableRefObject<ReturnType<typeof gsap["fromTo"]>>;

  const listAreaMobileScrollContainerRef = useRef<HTMLDivElement>(null);
  const descriptionPanelRef = useRef<HTMLDivElement>(null);
  const descriptionScrollableTrackRef = useRef<HTMLDivElement>(null);

  const scrollToCurrentPanel = useCallback(
    (initiator: "swipe" | "programmatic") => {
      setTimeout(() => {
        if (window.innerWidth < breakpoints.tabletMd) {
          gsap.fromTo(
            descriptionScrollableTrackRef.current,
            {
              x: gsap.getProperty(descriptionScrollableTrackRef.current, "x"),
            },
            {
              x: getDescriptionPanelOffset(currentIndexRef.current),
              duration: initiator === "swipe" ? 1 : 1.3,
              ease: initiator === "swipe" ? "expo.out" : scrollEase,
            }
          );
          descriptionScrollableTrackRef.current
            ?.querySelectorAll(".spotIconPositioner")
            .forEach((icon, i) => {
              gsap.to(icon, {
                x:
                  i > currentIndexRef.current
                    ? 64
                    : i < currentIndexRef.current
                    ? -64
                    : 0,
                duration: initiator === "swipe" ? 1 : 1.3,
                ease: initiator === "swipe" ? "expo.out" : scrollEase,
              });
            });
          containerRef.current
            ?.querySelectorAll(".UseCaseKaleidoscope")
            .forEach((el, i) => {
              gsap.to(el, {
                x:
                  i > currentIndexRef.current
                    ? 14
                    : i < currentIndexRef.current
                    ? -14
                    : 0,
                duration: initiator === "swipe" ? 1.5 : 1.8,
                ease: initiator === "swipe" ? "expo.out" : scrollEase,
              });
            });
        }
      }, 0.001);
    },
    [currentIndexRef]
  );

  const scrollToListItem = useCallback(
    (initiator: "swipe" | "programmatic") => {
      setTimeout(() => {
        if (window.innerWidth < breakpoints.tabletMd) {
          gsap.to(listAreaMobileScrollContainerRef.current, {
            scrollTo: {
              x: `[data-list-item-id="${currentIndexRef.current}"]`,
            },
            duration: 0.7,
            ease: scrollEase,
          });
          scrollToCurrentPanel(initiator);
        }
      });
    },
    [currentIndexRef, scrollToCurrentPanel]
  );

  const animateProgressBar: () => void = useCallback(() => {
    if (!isAutoplayingRef.current) return;
    const next = () => {
      setCurrentIndex(
        currentIndexRef.current >= teams.length - 1
          ? 0
          : currentIndexRef.current + 1
      );
      animateProgressBar();
    };
    tweenRef.current?.kill();

    tweenRef.current = gsap.fromTo(
      activeItemProgressRef.current,
      { scaleX: 0, transformOrigin: "left" },
      {
        scaleX: 1,
        duration: 15,
        ease: "linear",
        onComplete: () => {
          if (isAutoplayingRef.current) next();
        },
      }
    );
  }, [isAutoplayingRef, setCurrentIndex, currentIndexRef]);

  const goToIndex = useCallback(
    (i: number, initiator: "swipe" | "programmatic") => {
      setCurrentIndex(i);
      animateProgressBar();
      scrollToListItem(initiator);
    },
    [animateProgressBar, scrollToListItem, setCurrentIndex]
  );

  const pause = useCallback(() => {
    tweenRef.current?.seek(0);
    tweenRef.current?.pause();
    setIsAutoplaying(false);
  }, [setIsAutoplaying]);

  const resume = () => {
    tweenRef.current?.play();
  };

  const [hasActiveInteractionRef, setHasActiveInteraction] = useStateWithRef<
    string[]
  >([]);
  const totalDeltaXRef = useRef(0);

  const handleDescriptionPanelPointerDown = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      if (window.innerWidth >= breakpoints.tabletMd) return;
      if (hasActiveInteractionRef.current.length > 0) return;
      const id = makeRandomId();
      totalDeltaXRef.current = 0;
      setHasActiveInteraction([...hasActiveInteractionRef.current, id]);
      setTimeout(() => {
        pause();
        setIsAutoplaying(false);
      });
      let prevClientX = getXYFromMouseOrTouchEvent(e).x;
      const handlePointerMove = (e: MouseEvent | TouchEvent) => {
        const x = getXYFromMouseOrTouchEvent(e).x;
        const deltaX = x - prevClientX;
        prevClientX = x;
        totalDeltaXRef.current += deltaX;
        if (Math.abs(totalDeltaXRef.current) > touchScrollThreshold)
          e.preventDefault();
        const currentX = gsap.getProperty(
          descriptionScrollableTrackRef.current,
          "x"
        ) as number;
        gsap.to(descriptionScrollableTrackRef.current, {
          x: currentX + deltaX,
          duration: 0.001,
        });
      };
      let hasEnded = false;
      const end = (e: Event) => {
        if (hasEnded) return;
        hasEnded = true;
        window.removeEventListener("mousemove", handlePointerMove);
        window.removeEventListener("touchmove", handlePointerMove);
        window.removeEventListener("touchend", end);
        window.removeEventListener("touchcancel", end);
        window.removeEventListener("mouseup", end);
        window.removeEventListener("blur", end);
        if (Math.abs(totalDeltaXRef.current) < 24) {
          gsap.to(descriptionScrollableTrackRef.current, {
            x:
              parseFloat(
                `${gsap.getProperty(
                  descriptionScrollableTrackRef.current,
                  "x"
                )}`
              ) - totalDeltaXRef.current,
            duration: (0.2 * Math.abs(totalDeltaXRef.current)) / 24,
            ease: "power3.out",
          });
          setHasActiveInteraction(
            removeOneFromArray(hasActiveInteractionRef.current, id)
          );
          totalDeltaXRef.current = 0;
        } else {
          e.preventDefault();
          if (totalDeltaXRef.current > 0) {
            goToIndex(Math.max(currentIndexRef.current - 1, 0), "swipe");
          } else {
            goToIndex(
              Math.min(currentIndexRef.current + 1, teams.length - 1),
              "swipe"
            );
          }
          setTimeout(() => {
            totalDeltaXRef.current = 0;
            setHasActiveInteraction(
              removeOneFromArray(hasActiveInteractionRef.current, id)
            );
          }, 500);
        }
      };
      if (isTouchEvent(e)) {
        window.addEventListener("touchmove", handlePointerMove, {
          passive: false,
        });
        window.addEventListener("touchend", end, { once: true });
        window.addEventListener("touchcancel", end, { once: true });
      } else {
        window.addEventListener("mousemove", handlePointerMove, {
          passive: false,
        });
        window.addEventListener("mouseup", end, { once: true });
      }
      window.addEventListener("blur", end, { once: true });
    },
    [
      currentIndexRef,
      goToIndex,
      hasActiveInteractionRef,
      pause,
      setHasActiveInteraction,
      setIsAutoplaying,
    ]
  );

  const handleNextDescriptionCardClick = () => {
    if (
      hasActiveInteractionRef.current &&
      Math.abs(totalDeltaXRef.current) > touchScrollThreshold
    )
      return;
    setCurrentIndex(currentIndexRef.current + 1);
    animateProgressBar();
    scrollToCurrentPanel("programmatic");
  };

  useOnMount(() => {
    const observer = new IntersectionObserver(entries => {
      if (
        entries[0].isIntersecting &&
        window.innerWidth >= breakpoints.tabletMd
      ) {
        setIsAutoplaying(true);
        setTimeout(() => {
          animateProgressBar();
        });
      }
    });
    observer.observe(visibilityDetectionRef.current!);
    const handleResize = () => {
      scrollToCurrentPanel("programmatic");
    };
    window.addEventListener("resize", handleResize);
    setReady(true);
    return () => {
      observer.disconnect();
      tweenRef.current?.kill();
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <PageSection wider>
      <Positioner>
        <Wrap
          className={cx(
            currentIndexRef.current === teams.length - 1 && "hasReachedEnd"
          )}
          ref={containerRef}
        >
          <Start>
            <Index>
              <Header>
                <h2>Find your flow</h2>
              </Header>
              <MobilePaginator>
                {teams.map((t, i) => {
                  return (
                    <PaginatorItem
                      key={t.name}
                      className={cx(i === currentIndex ? "active" : "inactive")}
                      onClick={() => goToIndex(i, "programmatic")}
                    />
                  );
                })}
              </MobilePaginator>
              <ListAreaOuter>
                <ListArea>
                  <ListAreaMobileScrollContainer
                    className={hideScrollbarsCSS}
                    ref={listAreaMobileScrollContainerRef}
                  >
                    <List
                      onPointerEnter={pause}
                      onPointerLeave={resume}
                      ref={visibilityDetectionRef}
                    >
                      {ready && vw >= breakpoints.tabletMd && (
                        <ActiveItemBackdrop
                          style={{
                            transform: `translateY(${
                              currentIndexRef.current *
                              (3 * (vw >= breakpoints.desktop ? 16 : 14))
                            }px)`,
                          }}
                        >
                          <ActiveItemProgress ref={activeItemProgressRef} />
                          <ActiveItemTriangle />
                        </ActiveItemBackdrop>
                      )}
                      {teams.map((t, i) => {
                        return (
                          <ListItem
                            key={t.name}
                            className={cx(
                              i === currentIndex ? "active" : "inactive"
                            )}
                            onClick={() => goToIndex(i, "programmatic")}
                            data-list-item-id={i}
                          >
                            {ready &&
                              i === currentIndex &&
                              vw < breakpoints.tabletMd && (
                                <ActiveItemBackdrop>
                                  <ActiveItemProgress
                                    ref={activeItemProgressRef}
                                  />
                                </ActiveItemBackdrop>
                              )}
                            {t.icon}
                            <span>{t.name}</span>
                          </ListItem>
                        );
                      })}
                      <ListItem
                        className="mobileBackToStartButton"
                        onClick={() => goToIndex(0, "programmatic")}
                      >
                        ← Back to start
                      </ListItem>
                    </List>
                  </ListAreaMobileScrollContainer>
                </ListArea>
              </ListAreaOuter>
            </Index>
            <DescriptionPanel
              ref={descriptionPanelRef}
              onMouseDown={handleDescriptionPanelPointerDown}
              onTouchStart={handleDescriptionPanelPointerDown}
            >
              <TeamDescriptionCardSet ref={descriptionScrollableTrackRef}>
                {teams.map((t, i) => (
                  <TeamDescriptionCardOuter
                    key={t.name}
                    className={cx(i === currentIndex ? "active" : "inactive")}
                    data-description-panel-id={i}
                    onClick={
                      i === currentIndexRef.current + 1
                        ? handleNextDescriptionCardClick
                        : undefined
                    }
                  >
                    <TeamDescriptionCard>
                      <TeamDescriptionCardContent>
                        <h3>{t.name}</h3>
                        <SpotIconPositioner
                          className={cx(
                            "spotIconPositioner",
                            i === currentIndex ? "active" : "inactive"
                          )}
                          style={
                            {
                              "--offset": `${t.spotIconOffsetLeft}px`,
                              "--offsetMobile": `${t.spotIconOffsetLeftMobile}px`,
                            } as CSSProperties
                          }
                        >
                          {t.spotIcon}
                        </SpotIconPositioner>
                        <Description>{t.description}</Description>
                      </TeamDescriptionCardContent>
                      {i !== 0 && (
                        <TeamDescriptionCardFooter>
                          <ExploreLinkWrap>
                            <AllCaps as="p">Explore</AllCaps>
                            <p>
                              <Link to={`/solutions/${t.name.toLowerCase()}`}>
                                {t.name} solutions <span>→</span>
                              </Link>
                            </p>
                          </ExploreLinkWrap>
                        </TeamDescriptionCardFooter>
                      )}
                    </TeamDescriptionCard>
                  </TeamDescriptionCardOuter>
                ))}
              </TeamDescriptionCardSet>
            </DescriptionPanel>
          </Start>
          <End>
            <DiagonalLine width="108" height="36" viewBox="0 0 108 36">
              <path
                d="M108 35.5L0.5 0V35.5H108Z"
                fill={colors.purple800}
                stroke={colors.purple400}
              />
            </DiagonalLine>
            <River>
              {teams.map((t, i) => (
                <UseCaseKaleidoscope
                  key={i}
                  active={i === currentIndex}
                  team={t}
                />
              ))}
            </River>
          </End>
        </Wrap>
      </Positioner>
    </PageSection>
  );
};
