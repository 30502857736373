export const LogoMyFitnessPal = () => (
  <svg
    width="132"
    height="21"
    viewBox="0 0 132 21"
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <title>MyFitnessPal</title>
    <path d="M10.7622 4.45576C10.1114 4.8235 9.5519 5.33306 9.12501 5.94662C8.93565 5.35067 8.54546 4.83881 8.02105 4.49822C7.41385 4.14458 6.71793 3.97265 6.01593 4.00284C5.3975 4.00276 4.78902 4.15853 4.24671 4.45576C3.64786 4.79696 3.1448 5.28379 2.78415 5.87114V4.34725H-0.13623V15.6372H2.87852V8.84815C2.87467 8.53198 2.93736 8.21849 3.06251 7.92816C3.17249 7.65836 3.33258 7.41184 3.5343 7.20161C3.74372 6.98046 3.99566 6.80393 4.27501 6.68261C4.53974 6.58016 4.82152 6.52893 5.10537 6.53164C5.29486 6.53243 5.48292 6.5643 5.66209 6.626C5.83476 6.68655 5.98795 6.79248 6.10556 6.93263C6.24123 7.10728 6.33922 7.30804 6.39336 7.52242C6.47158 7.84661 6.50648 8.17979 6.49715 8.51318V15.6372H9.51189V8.71602C9.51168 8.43267 9.57284 8.15263 9.69116 7.89513C9.80913 7.63949 9.96857 7.40514 10.163 7.20161C10.3741 6.98032 10.6276 6.80383 10.9084 6.68261C11.1715 6.58019 11.4517 6.52895 11.7341 6.53164C11.9182 6.53107 12.1014 6.55973 12.2766 6.61657C12.4479 6.67724 12.5995 6.78322 12.7153 6.92323C12.8544 7.09629 12.9542 7.29753 13.0079 7.51295C13.0914 7.83963 13.1311 8.17599 13.1258 8.51318V15.6372H16.1453V8.08857C16.1453 6.64802 15.8543 5.60693 15.2725 4.96529C14.9288 4.63003 14.5172 4.37232 14.0655 4.20956C13.6139 4.04679 13.1326 3.98274 12.654 4.02172C11.9956 4.02074 11.3473 4.18283 10.7669 4.49351" />
    <path d="M25.5521 4.34717L23.1177 11.7637L20.471 4.34717H16.9844L21.6552 15.1417L21.3107 16.0853C21.1986 16.4775 20.9836 16.8326 20.688 17.1138C20.4068 17.3192 20.0639 17.4223 19.7161 17.4063C19.2874 17.4065 18.8606 17.3494 18.4469 17.2365L17.9516 19.4822C18.3264 19.6075 18.7116 19.6991 19.1027 19.7558C19.4703 19.8046 19.8407 19.8299 20.2115 19.8314C20.7283 19.8456 21.2434 19.764 21.7306 19.5907C22.1299 19.4411 22.4897 19.2023 22.7827 18.8925C23.1111 18.5242 23.383 18.1092 23.5895 17.6611C23.8207 17.1893 24.0613 16.609 24.3207 15.9627L28.8452 4.34717H25.5521Z" />
    <path d="M33.5396 0.247386C33.0792 0.394387 32.656 0.638994 32.2988 0.964509C31.9325 1.306 31.65 1.72739 31.4731 2.19588C31.2594 2.77047 31.1569 3.3805 31.1712 3.9934V4.3614H29.4067V6.72036H31.1712V15.6372H34.186V6.70621H36.7997V4.34725H34.186V3.9132C34.1602 3.69007 34.1898 3.46404 34.272 3.25505C34.3543 3.04605 34.4868 2.86053 34.6577 2.71486C34.965 2.49286 35.3355 2.37544 35.7145 2.37988C36.157 2.38327 36.5974 2.4403 37.0261 2.54973L37.4366 0.346462C37.0405 0.219692 36.6327 0.132766 36.2194 0.0869769C35.8434 0.0447704 35.4654 0.0227219 35.0871 0.0209262C34.5626 0.0158726 34.0406 0.0922689 33.5396 0.247386Z" />
    <path d="M38.5786 2.91773H41.6783V0.195496H38.5786V2.91773Z" />
    <path d="M38.6206 15.6371H41.6354V4.34717H38.6206V15.6371Z" />
    <path d="M44.6693 1.34192V4.34723H42.9048V6.70618H44.6693V11.9808C44.6693 13.3804 44.9681 14.376 45.5657 14.9673C46.1633 15.5586 47.0786 15.8542 48.3115 15.8542C48.6887 15.8535 49.0653 15.8283 49.4391 15.7788C49.8516 15.7233 50.2588 15.6333 50.6564 15.5098L50.227 13.2782C50.0267 13.3376 49.8214 13.3787 49.6137 13.4009C49.4014 13.4009 49.1985 13.4339 48.9957 13.4339C48.6532 13.4462 48.318 13.3339 48.052 13.1178C47.9097 12.9553 47.8034 12.7645 47.74 12.558C47.6766 12.3515 47.6575 12.1339 47.6841 11.9194V6.72977H50.2695V4.37081H47.6841V1.34192H44.6693Z" />
    <path d="M56.3084 4.45573C55.6618 4.78905 55.1162 5.2891 54.7278 5.90413V4.34722H51.8027V15.6372H54.8175V8.84813C54.813 8.52988 54.8825 8.21495 55.0204 7.92814C55.1463 7.65917 55.317 7.41348 55.5252 7.20152C55.7578 6.99295 56.0242 6.82542 56.3131 6.70617C56.6314 6.58184 56.9715 6.52248 57.3133 6.53161C57.7574 6.51122 58.1915 6.66725 58.521 6.96565C58.8183 7.25342 58.9929 7.796 58.9929 8.58863V15.6372H62.0076V8.17341C62.0076 6.73291 61.6931 5.67767 61.064 5.00772C60.4349 4.33778 59.4914 4.00281 58.2333 4.00281C57.5762 4.01192 56.9301 4.17342 56.3461 4.4746" />
    <path d="M67.065 7.77717C67.1702 7.48954 67.3304 7.22512 67.5368 6.99872C67.7511 6.76909 68.0116 6.58741 68.3011 6.46559C68.6369 6.33118 68.9963 6.26541 69.3579 6.27215C69.6827 6.24498 70.0096 6.28871 70.3158 6.4003C70.6219 6.5119 70.9005 6.68872 71.132 6.9185C71.5439 7.43294 71.7505 8.08174 71.7118 8.7396H66.8669C66.8791 8.41616 66.9461 8.09708 67.065 7.79604V7.77717ZM67.1499 4.41802C66.4766 4.67381 65.8689 5.07678 65.3712 5.5975C64.8614 6.14039 64.4695 6.78292 64.2201 7.48463C63.934 8.29971 63.795 9.15917 63.8096 10.0229C63.8096 11.929 64.2815 13.3993 65.225 14.4341C66.1686 15.4689 67.5714 15.9895 69.4333 15.9958C70.9841 15.9958 72.1652 15.6671 72.9765 15.0097C73.8307 14.2747 74.3739 13.2426 74.4956 12.1224H71.566C71.349 13.132 70.6461 13.6368 69.4568 13.6368C69.0967 13.6634 68.735 13.608 68.3992 13.4748C68.0634 13.3416 67.762 13.1341 67.5179 12.8678C67.053 12.2557 66.8101 11.5037 66.8291 10.7353H74.6234V9.51805C74.6234 7.82908 74.1887 6.4876 73.3207 5.4937C72.4527 4.49979 71.1569 4.00284 69.4333 4.00284C68.6559 3.99347 67.8833 4.12782 67.1546 4.39914" />
    <path d="M78.9591 4.26704C78.4111 4.41372 77.8914 4.65134 77.4214 4.97001C77.0137 5.25272 76.675 5.62361 76.4304 6.05513C76.1899 6.508 76.0683 7.01445 76.0765 7.52711C76.0406 7.97674 76.1111 8.42851 76.2825 8.84573C76.4539 9.26301 76.7213 9.63399 77.0627 9.92854C77.9715 10.545 79.0186 10.9275 80.1105 11.042L81.6627 11.3014C82.0739 11.3333 82.463 11.4991 82.7712 11.7732C82.9599 11.979 83.0629 12.249 83.0594 12.5281C83.0705 12.7038 83.0318 12.8791 82.9488 13.0342C82.8652 13.1893 82.7401 13.318 82.5874 13.4056C82.1043 13.6196 81.5763 13.712 81.049 13.6746C80.5162 13.6892 79.9861 13.5862 79.4975 13.3726C79.2653 13.2417 79.0704 13.0539 78.9308 12.8269C78.7912 12.6 78.7117 12.3413 78.7 12.0752H75.8174C75.8347 12.7382 76.0164 13.3866 76.3454 13.9624C76.6412 14.4516 77.0462 14.8652 77.5299 15.1701C78.0317 15.4809 78.5866 15.6968 79.1671 15.807C79.8009 15.9338 80.4457 15.997 81.0919 15.9958C82.7712 15.9958 84.0124 15.6702 84.819 15.0522C85.2074 14.749 85.5183 14.3587 85.7271 13.9127C85.9365 13.4668 86.0374 12.9779 86.0222 12.4856C86.0304 12.0074 85.9689 11.5304 85.8383 11.0702C85.7181 10.6706 85.4948 10.3095 85.1915 10.0229C84.8431 9.68921 84.4306 9.42929 83.9793 9.25859C83.3455 9.02562 82.689 8.85803 82.0214 8.75851L80.606 8.52257C80.1478 8.46956 79.7048 8.32512 79.304 8.09796C79.1906 8.00189 79.1015 7.88047 79.0441 7.74356C78.9868 7.60665 78.9626 7.45814 78.9736 7.3101C78.9757 7.18177 79.0082 7.05571 79.0676 6.94209C79.145 6.80147 79.257 6.68292 79.3931 6.59768C79.5846 6.47877 79.7953 6.39415 80.0158 6.34764C80.3441 6.27513 80.68 6.24187 81.0165 6.24856C81.559 6.20967 82.1002 6.34165 82.5639 6.62599C82.9157 6.91825 83.1562 7.3233 83.2432 7.77245L85.9323 7.428C85.8515 6.96504 85.7098 6.51465 85.5128 6.08815C85.3152 5.66536 85.0214 5.29515 84.6538 5.00775C84.2115 4.66618 83.707 4.41453 83.1679 4.26704C82.4388 4.0719 81.6855 3.98136 80.9315 3.99812C80.266 3.99025 79.6025 4.07278 78.9591 4.24345" />
    <path d="M90.1735 4.26703C89.6254 4.41371 89.1057 4.65133 88.6358 4.97C88.2218 5.25626 87.8797 5.63403 87.6358 6.07399C87.3953 6.52687 87.2729 7.0333 87.2819 7.54596C87.2494 7.99372 87.3234 8.4428 87.4969 8.8567C87.671 9.27059 87.9398 9.63778 88.2819 9.92852C89.1907 10.5451 90.2378 10.9275 91.3297 11.042L92.8771 11.3015C93.2883 11.3333 93.6774 11.499 93.9856 11.7732C94.1756 11.9785 94.28 12.2484 94.2779 12.5281C94.289 12.7038 94.251 12.8791 94.1673 13.0342C94.0844 13.1893 93.9593 13.3179 93.8066 13.4057C93.3221 13.6197 92.7921 13.7119 92.2634 13.6746C91.7319 13.6891 91.2032 13.5861 90.716 13.3726C90.4838 13.2423 90.2882 13.0548 90.1486 12.8277C90.009 12.6006 89.9302 12.3415 89.9192 12.0751H87.0317C87.0532 12.7376 87.2349 13.3849 87.5597 13.9623C87.8569 14.4506 88.2619 14.8639 88.7443 15.1701C89.2467 15.4801 89.8017 15.696 90.3815 15.807C91.0166 15.9338 91.6628 15.997 92.311 15.9958C93.9904 15.9958 95.2309 15.6702 96.0333 15.0522C96.4224 14.7496 96.7348 14.3594 96.9442 13.9134C97.1543 13.4675 97.2559 12.9783 97.2414 12.4857C97.2496 12.0073 97.1874 11.5304 97.0568 11.0703C96.9352 10.6715 96.7127 10.3108 96.4107 10.0229C96.0527 9.69431 95.6325 9.44088 95.175 9.27744C94.5392 9.04523 93.8812 8.8777 93.2122 8.77736L91.7969 8.54149C91.3387 8.48917 90.8957 8.34473 90.4941 8.11687C90.3808 8.02123 90.291 7.90001 90.2322 7.7631C90.1742 7.62612 90.1493 7.4774 90.1597 7.32895C90.1638 7.20027 90.1977 7.07428 90.2585 6.96094C90.3345 6.8209 90.4451 6.70241 90.5792 6.61655C90.772 6.49687 90.9848 6.41221 91.2067 6.36651C91.5349 6.294 91.8708 6.26074 92.2074 6.26743C92.7534 6.22334 93.2993 6.35207 93.7686 6.63543C94.119 6.92816 94.3574 7.33324 94.4431 7.7819L97.137 7.43745C97.0561 6.97449 96.9152 6.52408 96.7175 6.09758C96.5198 5.67362 96.2241 5.30312 95.8543 5.01718C95.4189 4.67288 94.9206 4.41794 94.3864 4.26703C93.6559 4.07164 92.9019 3.9811 92.1459 3.99811C91.4803 3.99088 90.8169 4.0734 90.1735 4.24344" />
    <path d="M103.611 13.4198C103.285 13.3205 102.986 13.1478 102.738 12.915C102.452 12.6134 102.249 12.2428 102.148 11.8393C102.009 11.2633 101.948 10.6716 101.964 10.0795V9.73511C101.951 9.19329 102.019 8.65257 102.167 8.131C102.29 7.73914 102.509 7.38406 102.804 7.0978C103.041 6.89687 103.311 6.73738 103.601 6.626C103.904 6.52257 104.224 6.47152 104.544 6.47503C104.869 6.4729 105.189 6.53546 105.488 6.65902C105.792 6.79129 106.056 7.00119 106.253 7.26761C106.489 7.59533 106.661 7.96527 106.757 8.35747C106.891 8.9022 106.953 9.46212 106.941 10.0229C106.951 10.5702 106.887 11.1163 106.748 11.6459C106.643 12.0408 106.463 12.4122 106.22 12.7404C106.013 13.008 105.741 13.219 105.432 13.3538C105.134 13.4836 104.813 13.5495 104.488 13.5471C104.185 13.5481 103.884 13.5084 103.591 13.4292L103.611 13.4198ZM101.907 5.83811V4.34725H98.9824V19.8361H101.997V14.4719C102.396 15.0114 102.936 15.4296 103.559 15.6797C104.091 15.8796 104.655 15.9835 105.224 15.9863C105.93 15.9968 106.63 15.8484 107.271 15.5523C107.87 15.2662 108.396 14.8484 108.81 14.3304C109.249 13.7682 109.579 13.1279 109.782 12.4432C110.01 11.649 110.122 10.8257 110.112 9.99932C110.112 8.13729 109.725 6.67318 108.951 5.60694C108.173 4.54069 107.007 4.00756 105.441 4.00756C104.745 3.98751 104.055 4.14505 103.437 4.46532C102.819 4.78559 102.292 5.25803 101.907 5.83811Z" />
    <path d="M114.754 13.3254C114.626 13.2171 114.524 13.0808 114.457 12.927C114.39 12.7732 114.359 12.6061 114.367 12.4385C114.363 12.2108 114.399 11.9843 114.471 11.7685C114.555 11.5516 114.703 11.3655 114.895 11.2354C115.167 11.0558 115.469 10.9279 115.787 10.8579C116.289 10.7429 116.801 10.678 117.316 10.6645L118.651 10.5985V11.1835C118.665 11.5847 118.576 11.9829 118.392 12.3394C118.227 12.6405 118.001 12.9028 117.726 13.1084C117.171 13.4851 116.509 13.6737 115.839 13.6462C115.454 13.6702 115.072 13.5565 114.763 13.3254H114.754ZM113.513 4.79071C113.084 5.07964 112.723 5.45842 112.455 5.90078C112.187 6.34315 112.018 6.83851 111.961 7.35252L114.721 7.61203C114.891 6.70618 115.575 6.24854 116.763 6.24854C117.243 6.23899 117.714 6.37558 118.113 6.64013C118.316 6.81139 118.475 7.03013 118.573 7.27699C118.672 7.52385 118.708 7.79123 118.679 8.05551V8.61696L117.24 8.67826C116.529 8.70991 115.82 8.78234 115.117 8.89527C114.463 8.99769 113.828 9.19479 113.23 9.48028C112.686 9.73917 112.218 10.1341 111.871 10.6267C111.51 11.1881 111.331 11.847 111.357 12.5139C111.342 13.0381 111.446 13.5587 111.659 14.0378C111.837 14.4561 112.112 14.8263 112.461 15.1182C112.81 15.3994 113.213 15.6065 113.645 15.7269C114.106 15.8554 114.582 15.9205 115.061 15.9203C115.804 15.9519 116.542 15.7893 117.202 15.4485C117.766 15.1226 118.274 14.7067 118.703 14.2171V15.6324H121.533V8.51316C121.542 7.87562 121.46 7.24022 121.288 6.62598C121.136 6.09867 120.863 5.61407 120.491 5.2106C120.086 4.79827 119.588 4.4906 119.038 4.3142C118.327 4.09062 117.585 3.98542 116.84 4.00281C115.667 3.94358 114.503 4.21671 113.48 4.79071" />
    <path d="M123.798 15.6372H126.812V0.195496H123.798V15.6372Z" />
    <path d="M129.115 1.72881V0.492717H129.464V0.181335H128.388V0.492717H128.742V1.72881H129.115ZM129.988 1.72881V0.700306L130.431 1.72881H130.611L131.05 0.700306V1.72881H131.403V0.181335H130.96L130.521 1.21927L130.087 0.181335H129.615V1.72881H129.988Z" />
  </svg>
);
