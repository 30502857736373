import { css } from "linaria";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

export const CourseIconAdvanced = () => (
  <svg
    width="94"
    height="99"
    viewBox="0 0 94 99"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    className={css`
      --shadow-color: ${colorsV4.purple700};
      ${darkModeLinariaCSS(`--shadow-color: ${colorsV4.warmBlack900};`)}
    `}
  >
    <path
      d="M92.9458 76.9125H34.6541L10.3716 97.8892H71.6695L92.9458 76.9125Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M87.8718 70.2153H29.5794L5.29736 91.1924V97.7978H66.5955L87.8718 76.8208V70.2153Z"
      fill="#8578E6"
      stroke="var(--shadow-color)"
    />
    <path
      d="M87.8718 70.2153H29.5794L5.29736 91.1924H66.5955L87.8718 70.2153Z"
      fill="#B0A6F9"
      stroke="var(--shadow-color)"
    />
    <path d="M66.5955 97.7978V91.1924" stroke="var(--shadow-color)" />
    <path
      d="M57.5641 79.7461H27.2546V88.4499H48.7317L57.5641 79.7461Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M74.4247 79.7461H44.1152V88.4499H65.5926L74.4247 79.7461Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M66.6354 70.6248H36.3262V79.3286H57.8032L66.6354 70.6248Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M83.4962 70.6248H53.187V79.3286H74.6641L83.4962 70.6248Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M48.5235 49.1177H31.7548C31.4176 49.1177 31.1105 49.2445 30.8776 49.4538C30.6107 49.694 22.6693 57.3971 22.4325 57.6342C22.1946 57.8721 22.0483 58.1995 22.0483 58.5618V78.0172C22.0483 78.7417 22.6356 79.3287 23.3598 79.3287H40.1296C40.4809 79.3287 40.7993 79.1909 41.0352 78.9659C41.2861 78.7268 49.2677 71.0242 49.5082 70.7523C49.7115 70.5214 49.835 70.2179 49.8355 69.8849V50.4294C49.8353 49.7046 49.2483 49.1177 48.5235 49.1177Z"
      fill="#D67C56"
      stroke="var(--shadow-color)"
    />
    <path
      d="M41.4411 78.0172C41.4411 78.7417 40.8536 79.3286 40.1294 79.3286H23.3596C22.6353 79.3286 22.0481 78.7417 22.0481 78.0172V58.5617C22.0481 57.8375 22.6353 57.2502 23.3596 57.2502H40.1294C40.8536 57.2502 41.4411 57.8375 41.4411 58.5617V78.0172Z"
      fill="#FFD18C"
      stroke="var(--shadow-color)"
    />
    <path d="M41.0479 57.6263L49.5166 49.5741" stroke="var(--shadow-color)" />
    <path
      d="M76.5123 49.1177H59.7436C59.4064 49.1177 59.0992 49.2445 58.8664 49.4538C58.5995 49.694 50.6581 57.3971 50.4213 57.6342C50.1834 57.8721 50.0371 58.1995 50.0371 58.5618V78.0172C50.0371 78.7417 50.6243 79.3287 51.3488 79.3287H68.1184C68.4697 79.3287 68.788 79.1909 69.024 78.9659C69.2749 78.7268 77.2565 71.0242 77.4969 70.7523C77.7003 70.5214 77.8238 70.2179 77.8243 69.8849V50.4294C77.824 49.7046 77.2371 49.1177 76.5123 49.1177Z"
      fill="#8578E6"
      stroke="var(--shadow-color)"
    />
    <path
      d="M69.43 78.0172C69.43 78.7417 68.8421 79.3286 68.1179 79.3286H51.3484C50.6239 79.3286 50.0366 78.7417 50.0366 78.0172V58.5617C50.0366 57.8375 50.6239 57.2502 51.3484 57.2502H68.1179C68.8421 57.2502 69.43 57.8375 69.43 58.5617V78.0172Z"
      fill="#B0A6F9"
      stroke="var(--shadow-color)"
    />
    <path d="M69.0364 57.6263L77.5051 49.5741" stroke="var(--shadow-color)" />
    <path
      d="M48.5235 29.4465H31.7548C31.4176 29.4465 31.1105 29.5737 30.8776 29.7829C30.6107 30.0231 22.6693 37.7263 22.4325 37.9634C22.1946 38.2013 22.0483 38.5287 22.0483 38.8909V56.6403C22.0483 57.3648 22.6356 57.9515 23.3598 57.9515H40.1296C40.4809 57.9515 40.7993 57.814 41.0352 57.5887C41.2861 57.3499 49.2677 49.6473 49.5082 49.3751C49.7115 49.1442 49.835 48.8407 49.8355 48.5077V30.7586C49.8353 30.0338 49.2483 29.4465 48.5235 29.4465Z"
      fill="#968BEC"
      stroke="var(--shadow-color)"
    />
    <path
      d="M41.4411 56.6404C41.4411 57.3649 40.8536 57.9515 40.1294 57.9515H23.3596C22.6353 57.9515 22.0481 57.3649 22.0481 56.6404V38.8909C22.0481 38.1667 22.6353 37.5795 23.3596 37.5795H40.1294C40.8536 37.5795 41.4411 38.1667 41.4411 38.8909V56.6404Z"
      fill="#B0A6F9"
      stroke="var(--shadow-color)"
    />
    <path d="M41.0479 37.9555L49.5166 29.9027" stroke="var(--shadow-color)" />
    <path
      d="M76.5123 36.6074H59.7436C59.4064 36.6074 59.0992 36.7346 58.8664 36.9438C58.5995 37.184 50.6581 44.8869 50.4213 45.1242C50.1834 45.3622 50.0371 45.6895 50.0371 46.0518V56.6403C50.0371 57.3648 50.6243 57.9515 51.3488 57.9515H68.1184C68.4697 57.9515 68.788 57.814 69.024 57.5887C69.2749 57.3499 77.2565 49.6473 77.4969 49.375C77.7003 49.1441 77.8238 48.8407 77.8243 48.5077V37.9195C77.824 37.1947 77.2371 36.6074 76.5123 36.6074Z"
      fill="#EBA34D"
      stroke="var(--shadow-color)"
    />
    <path
      d="M69.43 56.6403C69.43 57.3648 68.8421 57.9515 68.1179 57.9515H51.3484C50.6239 57.9515 50.0366 57.3648 50.0366 56.6403V46.0518C50.0366 45.3276 50.6239 44.7404 51.3484 44.7404H68.1179C68.8421 44.7404 69.43 45.3276 69.43 46.0518V56.6403Z"
      fill="#96BEFA"
      stroke="var(--shadow-color)"
    />
    <path d="M69.0364 45.1164L77.5051 37.0636" stroke="var(--shadow-color)" />
    <path
      d="M39.4517 58.2389H22.6831C22.3458 58.2389 22.0387 58.3657 21.8058 58.575C21.5389 58.8152 13.5976 66.5183 13.3607 66.7554C13.1228 66.9934 12.9766 67.3207 12.9766 67.6833V87.1385C12.9766 87.863 13.5638 88.4499 14.2883 88.4499H31.0578C31.4091 88.4499 31.7275 88.3124 31.9634 88.0871C32.2143 87.848 40.1959 80.1457 40.4364 79.8735C40.6398 79.6426 40.7632 79.3391 40.7638 79.0061V59.5506C40.7635 58.8261 40.1765 58.2389 39.4517 58.2389Z"
      fill="#7266CD"
      stroke="var(--shadow-color)"
    />
    <path
      d="M32.3699 87.1385C32.3699 87.863 31.782 88.4499 31.0578 88.4499H14.2883C13.5638 88.4499 12.9766 87.863 12.9766 87.1385V67.6833C12.9766 66.9588 13.5638 66.3718 14.2883 66.3718H31.0578C31.782 66.3718 32.3699 66.9588 32.3699 67.6833V87.1385Z"
      fill="#B0A6F9"
      stroke="var(--shadow-color)"
    />
    <path d="M31.9761 66.7478L40.4448 58.6951" stroke="var(--shadow-color)" />
    <path
      d="M76.5123 15.5159H59.7436C59.4064 15.5159 59.0992 15.643 58.8664 15.852C58.5995 16.0921 50.6581 23.7953 50.4213 24.0327C50.1834 24.2703 50.0371 24.598 50.0371 24.9602V44.4157C50.0371 45.1402 50.6243 45.7269 51.3488 45.7269H68.1184C68.4697 45.7269 68.788 45.5894 69.024 45.3641C69.2749 45.1253 77.2565 37.4227 77.4969 37.1505C77.7003 36.9196 77.8238 36.6161 77.8243 36.2831V16.8279C77.824 16.1031 77.2371 15.5159 76.5123 15.5159Z"
      fill="#968BEC"
      stroke="var(--shadow-color)"
    />
    <path
      d="M69.43 44.4157C69.43 45.1402 68.8421 45.7269 68.1179 45.7269H51.3484C50.6239 45.7269 50.0366 45.1402 50.0366 44.4157V24.9603C50.0366 24.2361 50.6239 23.6488 51.3484 23.6488H68.1179C68.8421 23.6488 69.43 24.2361 69.43 24.9603V44.4157Z"
      fill="#D4CFFC"
      stroke="var(--shadow-color)"
    />
    <path d="M69.0364 24.0248L77.5051 15.972" stroke="var(--shadow-color)" />
    <path
      d="M68.0425 61.5509H51.2739C50.9367 61.5509 50.6292 61.6777 50.3964 61.887C50.1295 62.1272 42.1884 69.8304 41.9516 70.0677C41.7136 70.3054 41.5674 70.633 41.5674 70.995V85.6332C41.5674 86.3577 42.1546 86.9444 42.8788 86.9444H59.6486C59.9999 86.9444 60.3183 86.8069 60.5543 86.5819C60.8051 86.3428 68.7868 78.6402 69.0272 78.368C69.2306 78.1373 69.3537 77.8336 69.3546 77.5006V62.8627C69.3546 62.1379 68.7676 61.5509 68.0425 61.5509Z"
      fill="#EB994D"
      stroke="var(--shadow-color)"
    />
    <path
      d="M60.9604 85.6332C60.9604 86.3577 60.3726 86.9443 59.6486 86.9443H42.8788C42.1546 86.9443 41.5674 86.3577 41.5674 85.6332V70.9949C41.5674 70.2707 42.1546 69.6835 42.8788 69.6835H59.6486C60.3726 69.6835 60.9604 70.2707 60.9604 70.9949V85.6332Z"
      fill="#FFBD59"
      stroke="var(--shadow-color)"
    />
    <path d="M60.5669 70.0595L69.0359 62.0073" stroke="var(--shadow-color)" />
    <path
      d="M39.4517 47.0137H22.6831C22.3458 47.0137 22.0387 47.1408 21.8058 47.3501C21.5389 47.5902 13.5976 55.2934 13.3607 55.5305C13.1228 55.7684 12.9766 56.0958 12.9766 56.458V65.2927C12.9766 66.0172 13.5638 66.6041 14.2883 66.6041H31.0578C31.4091 66.6041 31.7275 66.4663 31.9634 66.2413C32.2143 66.0023 40.1959 58.2997 40.4364 58.0274C40.6398 57.7968 40.7632 57.493 40.7638 57.16V48.3257C40.7635 47.6009 40.1765 47.0137 39.4517 47.0137Z"
      fill="#968BEC"
      stroke="var(--shadow-color)"
    />
    <path
      d="M32.3699 65.2927C32.3699 66.0172 31.782 66.6042 31.0578 66.6042H14.2883C13.5638 66.6042 12.9766 66.0172 12.9766 65.2927V56.4581C12.9766 55.7339 13.5638 55.1466 14.2883 55.1466H31.0578C31.782 55.1466 32.3699 55.7339 32.3699 56.4581V65.2927Z"
      fill="#D4CFFC"
      stroke="var(--shadow-color)"
    />
    <path d="M31.9761 55.5226L40.4448 47.4698" stroke="var(--shadow-color)" />
    <path
      d="M68.0425 38.5162H51.2739C50.9367 38.5162 50.6292 38.6431 50.3964 38.8523C50.1295 39.0925 42.1884 46.7954 41.9516 47.0328C41.7136 47.2704 41.5674 47.5981 41.5674 47.9603V69.0735C41.5674 69.798 42.1546 70.385 42.8788 70.385H59.6486C59.9999 70.385 60.3183 70.2471 60.5543 70.0219C60.8051 69.7831 68.7868 62.0805 69.0272 61.8082C69.2306 61.5776 69.3537 61.2739 69.3546 60.9409V39.828C69.3546 39.1032 68.7676 38.5162 68.0425 38.5162Z"
      fill="#7266CD"
      stroke="var(--shadow-color)"
    />
    <path
      d="M60.9604 69.0738C60.9604 69.7983 60.3726 70.3852 59.6486 70.3852H42.8788C42.1546 70.3852 41.5674 69.7983 41.5674 69.0738V47.9606C41.5674 47.2361 42.1546 46.6492 42.8788 46.6492H59.6486C60.3726 46.6492 60.9604 47.2361 60.9604 47.9606V69.0738Z"
      fill="#B0A6F9"
      stroke="var(--shadow-color)"
    />
    <path d="M60.5669 47.0252L69.0359 38.9724" stroke="var(--shadow-color)" />
    <path
      d="M69.0435 1L61.16 8.08384L50.8475 22.0527C50.2951 22.7049 51.0373 23.4977 51.7618 23.4977H68.5313C68.8936 23.4977 69.2215 23.3512 69.4589 23.1138C69.6963 22.8765 77.1055 16.2671 77.3423 16.0297C77.5797 15.7923 77.7268 15.4647 77.7268 15.1024L69.0435 1Z"
      fill="#7266CD"
      stroke="var(--shadow-color)"
    />
    <path
      d="M69.8434 22.1863C69.8434 22.9108 69.2559 23.4977 68.5314 23.4977H51.7619C51.0374 23.4977 50.0443 23.0064 50.7468 21.9022L61.1601 8.08411L69.8434 22.1863Z"
      fill="#B0A6F9"
      stroke="var(--shadow-color)"
    />
    <path
      d="M35.6477 50.5798C35.6477 50.6395 35.6076 50.7898 35.3117 51.0068C35.0261 51.2162 34.5809 51.4257 33.9857 51.6104C32.8014 51.9777 31.1347 52.2126 29.272 52.2126C27.4092 52.2126 25.7425 51.9777 24.5582 51.6104C23.9631 51.4257 23.5178 51.2162 23.2323 51.0068C22.9364 50.7898 22.8962 50.6395 22.8962 50.5798C22.8962 50.5202 22.9364 50.3699 23.2323 50.1529C23.5178 49.9435 23.9631 49.734 24.5582 49.5493C25.7425 49.182 27.4092 48.9471 29.272 48.9471C31.1347 48.9471 32.8014 49.182 33.9857 49.5493C34.5809 49.734 35.0261 49.9435 35.3117 50.1529C35.6076 50.3699 35.6477 50.5202 35.6477 50.5798Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M26.0646 51.7043C31.7624 51.7043 36.3814 47.0853 36.3814 41.3875C36.3814 35.6897 31.7624 31.0707 26.0646 31.0707C20.3668 31.0707 15.7478 35.6897 15.7478 41.3875C15.7478 47.0853 20.3668 51.7043 26.0646 51.7043Z"
      fill="#FFBD59"
      stroke="var(--shadow-color)"
    />
  </svg>
);
