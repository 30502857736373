import { flashNotice } from "../../../utils/notice.utils";
import { Paths } from "../../../utils/pathBuilders.utils";

const getAccessPageUrl = () =>
  `${window.location.origin}${Paths.guideSecurity.accessPage()}`;

const copyGuideSecurityWhitepaperAccessPageUrl = () => {
  try {
    navigator.clipboard.writeText(getAccessPageUrl());
    flashNotice("✅ Guide URL copied.");
  } catch (e) {
    flashNotice("Failed to copy the URL, please try copying manually.");
  }
};
export const shareGuideSecurityAccessPage = () => {
  const url = getAccessPageUrl();
  if ("canShare" in window.navigator && window.navigator.canShare({ url })) {
    window.navigator.share({ url });
  } else {
    copyGuideSecurityWhitepaperAccessPageUrl();
  }
};
