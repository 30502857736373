/* eslint-disable @typescript-eslint/no-misused-promises */
import { styled } from "@linaria/react";
import axios from "axios";
import { useRef, useState } from "react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { getCookie } from "../../utils/cookies.utils";
import { reportErrorSilently } from "../../utils/error.utils";
import { when } from "../../utils/promises.utils";
import PseudoLink from "../basic/PseudoLink";
import Button from "../forms/Button";
import Textarea from "../forms/Textarea";
import Spacing from "../layout/Spacing";
import LoadingIndicator from "../utilities/LoadingIndicator";
import { ProductIconButton } from "../icons/misc/ProductIconButtonOrLink";
import ThumbsDownIcon from "../icons/misc/ThumbsDownIcon";
import ThumbsUpIcon from "../icons/misc/ThumbsUpIcon";
import { Link } from "gatsby";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import { useSiteContext } from "../../context/site.context";
import { cx } from "linaria";
import chroma from "chroma-js";

const VotingBannerDiv = styled.div`
  padding: 1.5em;
  border-radius: 0.5em;
  font-weight: 500;
  background-color: ${colors.light200};
  ${darkModeLinariaCSS(`background-color: ${colors.dark500}`)};
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.compact {
    padding: 0.5em 1.25em;
    &.hasForm {
      padding: 0.5em 1.25em 1.25em;
    }
    width: 100%;
    background-color: ${withOpacity(colors.white, 0.4)};
    ${darkModeLinariaCSS(
      `background-color: var(--ac60020, ${withOpacity(colors.dark900, 0.8)})`
    )};
    .community & {
      background-color: var(
        --ac75,
        ${chroma.mix(colors.purple50, colors.purple100).hex()}
      );
      ${darkModeLinariaCSS(
        `background-color: var(--ac60020, ${withOpacity(colors.dark800, 0.5)})`
      )};
    }
    font-size: 1.4rem;
    p {
      font-weight: 600;
    }
  }
`;

const Actions = styled.div`
  padding-left: 1em;
`;

const ButtonSet = styled.div`
  display: flex;
  .compact & {
    margin-right: -0.5em;
  }
  button {
    &:hover {
      color: var(--ac, ${colors.purple});
    }
  }
`;

const SubmittingLoader = styled.div`
  display: flex;
`;

const FormFooterFlex = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 0.5em;
  grid-template-columns: auto minmax(0, 1fr);
  p {
    text-align: right;
  }
  a {
    ${inParagraphLinkStyle()}
  }
  .Button {
    font-size: inherit;
  }
`;

type VoteType = "👍" | "👎";

const VotingBanner = (props: {
  compact?: boolean;
  url?: string;
  text?: string;
}) => {
  const { location } = useSiteContext();
  const [id, setId] = useState("");
  const idRef = useRef(id);
  idRef.current = id;
  const formState = useState({
    feedback: "",
  });
  const [lastVoted, setLastVoted] = useState<VoteType | "">("");
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false);
  const [isChangingVote, setIsChangingVote] = useState(false);

  const bannerRef = useRef<HTMLDivElement>(null);

  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const awaitingResponseRef = useRef(awaitingResponse);
  awaitingResponseRef.current = awaitingResponse;
  const [hasError, setHasError] = useState(false);

  const focusOnTextarea = async () => {
    await when(() => !!bannerRef.current?.querySelector("textarea"));
    bannerRef.current?.querySelector("textarea")?.focus();
  };

  const clearFeedbackForm = () => {
    formState[1]({ feedback: "" });
  };

  const reset = () => {
    setHasSubmittedFeedback(false);
    setId("");
    setLastVoted("");
    clearFeedbackForm();
  };

  const vote = async (type: VoteType, includeFeedback = false) => {
    if (!includeFeedback && type !== lastVoted) setIsChangingVote(true);
    setAwaitingResponse(true);
    setLastVoted(type);
    const hasFeedback = includeFeedback && !!formState[0].feedback.trim();
    const email = getCookie("email_address");
    focusOnTextarea();
    try {
      const id = idRef.current;
      const { data } = await axios.post<{ id: string }>("/api/utils/vote", {
        id,
        vote: type,
        email,
        pageTitle: document.title.replace(" | Tines", ""),
        url: props.url || window.location.pathname,
        feedback: includeFeedback ? formState[0].feedback.trim() : "",
      });
      setId(data.id);
      setLastVoted(type);
      if (includeFeedback) {
        clearFeedbackForm();
        setHasSubmittedFeedback(hasFeedback);
      }
    } catch (e) {
      setHasError(true);
      reportErrorSilently(e);
    } finally {
      setAwaitingResponse(false);
      setIsChangingVote(false);
    }
  };

  const unvote = async () => {
    reset();
    const id = idRef.current;
    if (!id) return;
    setAwaitingResponse(true);
    try {
      await axios.post("/api/utils/unvote", {
        id,
      });
    } catch (e) {
      reportErrorSilently(e);
    } finally {
      setAwaitingResponse(false);
    }
  };

  const lastVotedUp = lastVoted === "👍";
  const lastVotedDown = lastVoted === "👎";

  const handleThumbsUp = async () => {
    await when(() => !awaitingResponseRef.current);
    if (lastVotedUp) {
      await unvote();
    } else {
      setHasSubmittedFeedback(false);
      await vote("👍");
    }
  };
  const handleThumbsDown = async () => {
    await when(() => !awaitingResponseRef.current);
    if (lastVotedDown) {
      await unvote();
    } else {
      setHasSubmittedFeedback(false);
      await vote("👎");
    }
  };
  const submitFeedback = async () => {
    if (!lastVoted) return;
    await vote(lastVoted, true);
  };

  return (
    <VotingBannerDiv
      className={cx(
        "HubVotingBanner",
        props.compact && "compact",
        lastVoted && "hasForm"
      )}
      ref={bannerRef}
    >
      <header>
        <div>
          {isChangingVote ? (
            <SubmittingLoader>
              <LoadingIndicator size="1.2em" />
                Submitting your vote...
            </SubmittingLoader>
          ) : hasError ? (
            "An error occurred. Please try again later!"
          ) : (
            <>
              {hasSubmittedFeedback || lastVoted
                ? hasSubmittedFeedback
                  ? `${
                      lastVotedUp ? "🎉" : "🤝"
                    }  Thanks! Your feedback has been received.`
                  : "Thanks for your feedback."
                : props.text ?? "Was this helpful?"}
            </>
          )}
        </div>
        <Actions>
          {hasSubmittedFeedback ? (
            <PseudoLink onClick={reset}>Clear</PseudoLink>
          ) : (
            <ButtonSet>
              <ProductIconButton onClick={handleThumbsUp}>
                <ThumbsUpIcon filled={lastVotedUp} />
              </ProductIconButton>
              <ProductIconButton onClick={handleThumbsDown}>
                <ThumbsDownIcon filled={lastVotedDown} />
              </ProductIconButton>
            </ButtonSet>
          )}
        </Actions>
      </header>
      {lastVoted && (
        <div>
          {hasSubmittedFeedback ? null : (
            <>
              <Spacing size="1em" />
              <Textarea
                formState={formState}
                name="feedback"
                rows={3}
                placeholder="Tell us more..."
              />
              <Spacing size=".5em" />
              <FormFooterFlex>
                <Button
                  loading={!isChangingVote && awaitingResponse}
                  onClick={submitFeedback}
                  width={props.compact ? "8em" : "9em"}
                  disabled={
                    !id || isChangingVote || !formState[0].feedback.trim()
                  }
                >
                  Submit
                </Button>
                <p>
                  Need help?{" "}
                  <Link
                    to={`/contact-support?${[
                      formState[0].feedback
                        ? `s=${encodeURIComponent(formState[0].feedback)}`
                        : "",
                      `body=${encodeURIComponent(`Page: ${location.href}`)}`,
                    ]
                      .filter(i => i)
                      .join("&")}`}
                  >
                    Contact support
                  </Link>
                  .
                </p>
              </FormFooterFlex>
            </>
          )}
        </div>
      )}
    </VotingBannerDiv>
  );
};

export default VotingBanner;
