import { styled } from "@linaria/react";
import { FormEvent, useState } from "react";
import { ArticleLikePageContent } from "../components/articles/ArticleLikePageContent";
import StandardArticle from "../components/articles/StandardArticle";
import Button from "../components/forms/Button";
import Select from "../components/forms/Select";
import Textarea from "../components/forms/Textarea";
import TextInput from "../components/forms/TextInput";
import Toggle from "../components/forms/Toggle";
import SpotIllustration from "../components/illustrations/SpotIllustration";
import Spacing from "../components/layout/Spacing";
import PageSection from "../components/reusableSections/PageSection";
import StandardPageHeader from "../components/reusableSections/StandardPageHeader";
import SEO from "../components/utilities/SEO";
import { externalLinkAttr } from "../constants/externalLinks.constants";
import { fromTablet } from "../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../styles/colorsV4.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { scrollToHash } from "../utils/anchorLinkScroll.utils";
import { getFormMetaInfo } from "../utils/form.utils";

const PageGDPRRequest: PageComponent = props => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const handleSubmitSuccess = () => {
    setHasSubmitted(true);
    scrollToHash({
      useHash: "#",
      doNotPushState: true,
    });
  };
  return (
    <Page {...props}>
      <SEO title="GDPR request" noindex />
      <StandardPageHeader pageTitle="GDPR request" centered largerPadding />
      <Spacing size="sectionPadding" />
      <PageSection>
        <ArticleLikePageContent>
          <StandardArticle>
            {hasSubmitted ? (
              <SuccessMessage />
            ) : (
              <Form onSubmitSuccess={handleSubmitSuccess} />
            )}
          </StandardArticle>
        </ArticleLikePageContent>
      </PageSection>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

const SuccessMessageContainer = styled.div`
  background-color: ${colorsV4.canvas700};
  padding: 1em 1.5em 2.5em 1.5em;
  border-radius: 1.25em;
  text-align: center;
  h2 {
    font-size: 2rem;
  }
`;

const SuccessMessage = () => (
  <SuccessMessageContainer className="EventRegistrationFormSuccessMessage">
    <SpotIllustration name="paperPlane" size="150" />
    <h2>Thanks for submitting, we’ll be in touch.</h2>
  </SuccessMessageContainer>
);

const FormContainer = styled.form`
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const InputGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
  }
`;
const Footnote = styled.p`
  color: ${withOpacity(colorsV4.warmBlack, 0.5)};
  font-size: 1.4rem;
`;

const Form = (props: { onSubmitSuccess: () => void }) => {
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [error, setError] = useState<null | unknown>(null);
  const formState = useState(() => ({
    purpose: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    country: "",
    updateRequestNotes: "",
    declaration: false,
    ...getFormMetaInfo(),
  }));
  const submit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setError(null);
      setAwaitingResponse(true);
      await fetch(
        "https://hq.tines.io/webhook/d22321411000f7490d29e6f0007a65a8/7ca18b21e6782deadf2bfe2b49083137",
        {
          method: "POST",
          body: JSON.stringify(formState[0]),
        }
      );
      props.onSubmitSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setAwaitingResponse(false);
    }
  };
  const [form] = formState;
  const canSubmit = !!(
    !!form.declaration &&
    form.purpose &&
    form.firstName &&
    form.lastName &&
    form.email &&
    form.company &&
    form.country &&
    (form.purpose === "update" ? !!form.updateRequestNotes : true)
  );
  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <FormContainer onSubmit={submit}>
      <p>
        If you would like to exercise your rights with regard to your personal
        information in our role as a data controller (or business), please
        complete this request form. For requests made via this form, Tines will
        only process based on its role as a business controller. By specifying
        the Customer company, you agree to permit Tines to share your request
        with the Customer company you list.
      </p>
      <hr />
      <p>
        <strong>My request is for: *</strong>
      </p>
      <Select
        formState={formState}
        name="purpose"
        options={[
          { value: "", label: "Please select", disabled: true },
          { value: "export", label: "Export my data" },
          { value: "update", label: "Update my data" },
          { value: "delete", label: "Delete my data" },
        ]}
        required
      />
      <p>(Each request type requires a separate form submission.)</p>
      <hr />
      <InputGrid>
        <TextInput
          formState={formState}
          name="firstName"
          placeholder="First name *"
          required
        />
        <TextInput
          formState={formState}
          name="lastName"
          placeholder="Last name *"
          required
        />
        <TextInput
          formState={formState}
          name="email"
          placeholder="Email *"
          required
          type="email"
        />
        <TextInput
          formState={formState}
          name="company"
          placeholder="Company *"
          required
        />

        <TextInput
          formState={formState}
          name="country"
          placeholder="Country *"
          required
        />
      </InputGrid>
      {formState[0].purpose === "update" && (
        <div>
          <hr />
          <strong>What information would you like to update? *</strong>
          <Spacing />
          <Textarea
            formState={formState}
            name="updateRequestNotes"
            required
            rows={5}
          />
        </div>
      )}
      <hr />
      <p>
        <strong>Declaration *</strong>
      </p>
      <Toggle formState={formState} name="declaration">
        I declare that all information provided here is true and correct and
        that I am authorized under law to make this request under penalty of
        perjury. I understand that I will receive an email to verify my identity
        for the request and Tines may require further information to carry out
        my request.
      </Toggle>
      <hr />
      <Button
        fullWidth
        loading={awaitingResponse}
        disabled={!canSubmit}
        type="submit"
        height="4em"
      >
        {error ? "Retry" : "Submit request"}
      </Button>
      <Footnote>
        * By submitting, I agree to the processing and international transfer of
        my personal data by Tines as described in the{" "}
        <a href="/privacy" {...externalLinkAttr}>
          Privacy Notice <sup>↗</sup>
        </a>
        .
      </Footnote>
    </FormContainer>
  );
};

export default PageGDPRRequest;
