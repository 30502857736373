import { styled } from "@linaria/react";
import {
  fromDesktopMl,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { DatoCmsPartner } from "../../../graphql-types";
import { isValidFeatureBlockWithIcon } from "../../utils/datocms.utils";
import DevUnknownBlock from "../blocks/DevUnknownBlock.block";
import FeatureBlockWithIcon from "../blocks/FeatureBlockWithIcon.block";
import PageSection from "../reusableSections/PageSection";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import Spacing from "../layout/Spacing";
import { AlignCenter } from "../typography/Align";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";

type Props = {
  partner: DatoCmsPartner;
};

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  text-align: center;
  ${fromTabletLg} {
    grid-template-columns: repeat(2, 1fr);
    &.col-1 {
      grid-template-columns: repeat(1, 1fr);
    }
    &.col-3,
    &.col-5,
    &.col-6 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  > * {
    &:nth-child(1) {
      background-color: ${withOpacity(colorsV4.purple100, 0.75)};
    }
    &:nth-child(2) {
      background-color: ${withOpacity(colorsV4.orange100, 0.75)};
    }
    &:nth-child(3) {
      background-color: ${withOpacity(colorsV4.green100, 0.75)};
    }
    &:nth-child(4) {
      background-color: ${withOpacity(colorsV4.pink100, 0.75)};
    }
    border: 0;
    > * {
      + * {
        margin-top: 0;
      }
    }
  }
  h3 {
    font-weight: 700;
    font-size: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 16em;
  }
  p {
    font-size: 1.4rem;
    margin-bottom: 1em;
    ${fromDesktopMl} {
      font-size: 1.6rem;
      max-width: 25em;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const PartnerPageBenefitsSection = (props: Props) => {
  return (
    <PageSection>
      <AlignCenter>
        <SectionHeading2>
          Key <Serif>benefits</Serif>
        </SectionHeading2>
      </AlignCenter>
      <Spacing size="xl" />
      <Grid className={`col-${props.partner.benefits?.length ?? 3}`}>
        {props.partner.benefits?.map((record, i) =>
          isValidFeatureBlockWithIcon(record) ? (
            <FeatureBlockWithIcon key={record.id} record={record} />
          ) : (
            <DevUnknownBlock key={record?.id ?? i} record={record!} />
          )
        )}
      </Grid>
    </PageSection>
  );
};

export default PartnerPageBenefitsSection;
