export const SOC2023ConclusionIllustrationLeft = () => (
  <svg
    width="250"
    height="257"
    viewBox="0 0 250 257"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78.674 82.8133L86.0483 80.6308C87.2376 80.2788 88.4573 81.0831 88.6022 82.3149L88.6185 82.4539C88.6915 83.0738 88.4707 83.6923 88.0218 84.126L86.8669 85.2416C86.4593 85.6354 85.9037 85.8377 85.3383 85.7983L78.9522 85.3533C77.8986 85.2798 77.3689 84.046 78.0414 83.2315C78.2059 83.0322 78.4262 82.8866 78.674 82.8133Z"
      fill="#222027"
    />
    <path
      d="M175.256 227.9C170.915 227.9 167.034 229.843 164.412 232.897C161.79 229.843 157.91 227.9 153.569 227.9C145.674 227.9 139.274 234.301 139.274 242.195C139.274 250.088 145.674 256.488 153.569 256.488C157.911 256.488 170.916 256.488 175.256 256.488C183.152 256.488 189.551 250.087 189.551 242.195C189.551 234.299 183.152 227.9 175.256 227.9Z"
      fill="#E9F3E7"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M212.365 9.21386C214.555 9.21386 216.595 9.83336 218.341 10.8923C220.643 5.10093 226.285 1 232.898 1C241.553 1 248.567 8.01687 248.567 16.6702C248.567 25.3236 241.552 32.339 232.898 32.339C228.497 32.339 216.194 32.339 212.365 32.339C205.978 32.339 200.801 27.1611 200.801 20.7772C200.801 14.3918 205.978 9.21386 212.365 9.21386Z"
      fill="#FCF9F5"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M142.3 157.444C139.45 157.444 136.729 157.969 134.194 158.883C129.767 143.927 115.934 133.014 99.5437 133.014C96.9982 133.014 94.5142 133.278 92.1172 133.778C83.9107 118.766 67.9747 108.578 49.6582 108.578C22.9523 108.578 1.30127 130.23 1.30127 156.934C1.30127 183.641 22.9523 205.291 49.6582 205.291C59.6392 205.291 134.683 205.291 142.3 205.291C155.513 205.291 166.225 194.581 166.225 181.367C166.225 168.156 155.513 157.444 142.3 157.444Z"
      fill="#FCF9F5"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.4492 83.4082C68.4473 86.4906 66.2467 108.153 73.5337 131.793C80.8207 155.433 94.8367 172.097 104.839 169.015C114.839 165.932 117.04 144.268 109.753 120.629C102.466 96.9905 88.4512 80.3257 78.4492 83.4082ZM101.3 157.54C96.0877 159.146 87.5392 146.424 82.2052 129.12C76.8712 111.818 76.7723 96.4865 81.9862 94.88C87.1987 93.275 95.7472 105.998 101.081 123.303C106.414 140.607 106.511 155.935 101.3 157.54Z"
      fill="#A990F5"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M86.701 80.8629C76.699 83.9469 74.4985 105.608 81.7854 129.249C89.0724 152.889 103.087 169.553 113.089 166.471C123.091 163.389 125.29 141.724 118.003 118.085C110.717 94.4467 96.7014 77.782 86.701 80.8629ZM109.552 154.996C104.338 156.603 95.7894 143.88 90.4554 126.576C85.1214 109.274 85.0225 93.9427 90.2364 92.3347C95.4474 90.7313 103.997 103.454 109.331 120.758C114.667 138.063 114.763 153.391 109.552 154.996Z"
      fill="#C2AAFA"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M93.9407 106.063L101.244 102.046C100.58 101.076 90.6958 84.7136 87.4652 96.5207C90.8508 100.532 92.4432 102.428 93.9407 106.063Z"
      fill="#222027"
    />
    <path
      d="M80.9526 175.047C80.9526 162.642 91.0071 152.588 103.412 152.588C115.816 152.588 125.87 162.642 125.87 175.047"
      fill="#FCF9F5"
    />
    <path
      d="M80.9526 175.047C80.9526 162.642 91.0071 152.588 103.412 152.588C115.816 152.588 125.87 162.642 125.87 175.047"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M121.049 136.596C120.073 140.196 116.36 142.323 112.76 141.346L97.5487 137.218C93.9487 136.242 91.8216 132.529 92.7981 128.929L96.9261 113.718C97.9026 110.118 101.614 107.991 105.215 108.968L120.427 113.097C124.027 114.072 126.154 117.785 125.177 121.384L121.049 136.596Z"
      fill="#FE9589"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M144.754 106.522L143.864 93.0552L155.08 85.5508L167.188 91.5117L168.079 104.977L156.863 112.481L144.754 106.522Z"
      fill="#FDBD74"
      stroke="#222027"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M104.344 139.389L104.719 143.514L112.968 148.389C113.482 145.55 113.771 143.953 112.76 141.339L104.344 139.389Z"
      fill="#222027"
    />
  </svg>
);
