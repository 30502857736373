import { styled } from "@linaria/react";
import gsap from "gsap";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import { RefObject } from "react";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import img from "../../../../static/images/build-apps/tines-human-touching-machine-illustration.png";
import img2x from "../../../../static/images/build-apps/tines-human-touching-machine-illustration@2x.png";
import { makeSrcSet2x } from "../../../utils/srcset.utils";
import {
  fromTabletMd,
  uptoDesktopMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { blueprintColors } from "../buildAppsPageConstants";
import { mix } from "polished";
import { rSize } from "../../../styles/responsiveSizes.styles";

gsap.registerPlugin(MotionPathPlugin);

type Props = {
  cellSize?: number;
  outputX?: number;
  withSolarSystem?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
};

const backdropColor = mix(0.5, colorsV4.orange, colorsV4.orange300);

const IllustrationWrap = styled.div`
  width: 180px;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  img {
    display: block;
    position: relative;
  }
`;

const ImgWrap = styled.div`
  overflow: hidden;
  border-radius: 50%;
  background-color: ${backdropColor};
  transform: translate3d(0, 0, 0);
  ${uptoTabletMd} {
    transform: scale(0.75);
  }
`;

const SolarSystemWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  ${fromTabletMd} {
    transform: translate(-50%, -50%);
  }
  path {
    ${uptoTabletMd} {
      stroke-width: 2;
    }
  }
`;

const Halo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: ${withOpacity(colorsV4.white, 0.5)};
  pointer-events: none;
  animation: HaloPulse 4s infinite alternate-reverse;
  @keyframes HaloPulse {
    from {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.5;
    }
    to {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 1;
    }
  }
`;

const Link = styled.div`
  position: absolute;
  bottom: calc(100% - 2px);
  left: ${180 / 2 - 10.5}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(${rSize("cardSectionPadding")} + 2px);
  ${uptoDesktopMd} {
    display: none;
  }
  div {
    width: 3px;
    flex: 1 1 auto;
    background-image: linear-gradient(
      to bottom,
      ${colorsV4.orange700},
      ${backdropColor}
    );
  }
`;

const HumanTouchingMachineIllustration = ({
  cellSize,
  outputX,
  withSolarSystem,
  containerRef,
}: Props) => {
  return (
    <IllustrationWrap
      style={{
        position: cellSize ? "absolute" : "relative",
        top: cellSize ? cellSize * 24 : 0,
        left: cellSize && outputX ? cellSize * (outputX - 4) : 0,
      }}
    >
      {withSolarSystem && (
        <SolarSystemWrap>
          <SolarSystem containerRef={containerRef} />
        </SolarSystemWrap>
      )}
      <ImgWrap>
        <Halo />
        <IllustrationContent />
      </ImgWrap>

      <Link>
        <div />
        <svg width="21" height="12" viewBox="0 0 21 12" fill={backdropColor}>
          <path d="M21 10.5002C15.384 10.0002 12 6.51991 12 1L12 11L12 -3.93403e-07L9 -5.24537e-07L9 11L8.99999 1C8.99999 6.51991 5.616 10.0002 -8.16608e-07 10.5002L9 11L9 12L12 12L12 11L21 10.5002Z" />
        </svg>
      </Link>
    </IllustrationWrap>
  );
};

const IllustrationContent = () => (
  <img src={img} srcSet={makeSrcSet2x(img, img2x)} />
);

const timeScalar = 1 / 6;
const color = blueprintColors.orange.fg;

const SolarSystem = (props: { containerRef?: RefObject<HTMLDivElement> }) => {
  useOnMount(() => {
    const vw = window.innerWidth;
    const solarSystemConfigs = {
      earthMoonSystem: {
        target: "earth-moon-system",
        orbit: "orbit--earth",
        orbitalPeriod: 365,
        startingPosition: 0.05,
      },
      moon: {
        target: "moon",
        orbit: "orbit--moon",
        orbitalPeriod: 27,
        startingPosition: 0.95,
      },
      mars: {
        target: "planet--mars",
        orbit: "orbit--mars",
        orbitalPeriod: 687,
        startingPosition: 0.46,
      },
      jupiter: {
        target: "planet--jupiter",
        orbit: "orbit--jupiter",
        orbitalPeriod: 4333,
        startingPosition: vw >= 768 ? 0.94 : 0.25,
      },
      saturn: {
        target: "planet--saturn",
        orbit: "orbit--saturn",
        orbitalPeriod: 10759,
        startingPosition: vw >= 768 ? 0.38 : 0.19,
      },
    };
    const tweens: gsap.core.Tween[] = [];
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        tweens.forEach(t => {
          t.resume();
        });
        intersectionObserver.disconnect();
      }
    });
    if (props.containerRef?.current) {
      intersectionObserver.observe(props.containerRef.current);
    }
    Object.values(solarSystemConfigs).forEach(config => {
      const duration = config.orbitalPeriod * timeScalar;
      const tween = gsap
        .to(`#${config.target}`, {
          duration: duration,
          motionPath: {
            path: `#${config.orbit}`,
            align: `#${config.orbit}`,
            alignOrigin: [0.5, 0.5],
          },
          repeat: -1,
          ease: "linear",
        })
        .seek(config.startingPosition * duration)
        .pause();
      tweens.push(tween);
    });
    return () => {
      intersectionObserver.disconnect();
      tweens.forEach(t => {
        t.kill();
      });
    };
  });
  return (
    <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none">
      <path
        id="orbit--earth"
        d="M805.5 640C805.5 731.403 731.403 805.5 640 805.5C548.597 805.5 474.5 731.403 474.5 640C474.5 548.597 548.597 474.5 640 474.5C731.403 474.5 805.5 548.597 805.5 640Z"
        stroke={color}
        strokeDasharray="6 6"
      />
      <g id="earth-moon-system">
        <circle id="planet--earth" cx="800" cy="683" r="7" fill={color} />
        <path
          id="orbit--moon"
          d="M822.5 682.5C822.5 695.203 812.203 705.5 799.5 705.5C786.797 705.5 776.5 695.203 776.5 682.5C776.5 669.797 786.797 659.5 799.5 659.5C812.203 659.5 822.5 669.797 822.5 682.5Z"
          stroke={color}
          strokeDasharray="6 6"
        />
        <circle id="moon" cx="820.5" cy="674.5" r="2.5" fill={color} />
      </g>
      <path
        id="orbit--mars"
        d="M904.5 640C904.5 786.079 786.079 904.5 640 904.5C493.921 904.5 375.5 786.079 375.5 640C375.5 493.921 493.921 375.5 640 375.5C786.079 375.5 904.5 493.921 904.5 640Z"
        stroke={color}
        strokeDasharray="6 6"
      />
      <circle id="planet--mars" cx="385" cy="711" r="10" fill={color} />
      <path
        id="orbit--jupiter"
        d="M1032.5 640C1032.5 856.772 856.772 1032.5 640 1032.5C423.228 1032.5 247.5 856.772 247.5 640C247.5 423.228 423.228 247.5 640 247.5C856.772 247.5 1032.5 423.228 1032.5 640Z"
        stroke={color}
        strokeDasharray="6 6"
      />
      <circle id="planet--jupiter" cx="1005" cy="498" r="20" fill={color} />
      <path
        id="orbit--saturn"
        d="M1243.5 640C1243.5 973.304 973.304 1243.5 640 1243.5C306.696 1243.5 36.5 973.304 36.5 640C36.5 306.696 306.696 36.5 640 36.5C973.304 36.5 1243.5 306.696 1243.5 640Z"
        stroke={color}
        strokeDasharray="6 6"
      />
      <g id="planet--saturn">
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 1033C240.539 1033 258 1050.46 258 1072C258 1093.54 240.539 1111 219 1111C197.461 1111 180 1093.54 180 1072C180 1050.46 197.461 1033 219 1033ZM219 1049.61C231.367 1049.61 241.393 1059.63 241.393 1072C241.393 1084.37 231.367 1094.39 219 1094.39C206.633 1094.39 196.607 1084.37 196.607 1072C196.607 1059.63 206.633 1049.61 219 1049.61Z"
          fill={color}
        />
        <circle r="16" transform="matrix(1 0 0 -1 219 1072)" fill={color} />
      </g>
    </svg>
  );
};

export default HumanTouchingMachineIllustration;
