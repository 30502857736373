import { styled } from "@linaria/react";
import { navigate } from "gatsby";
import { css } from "linaria";
import { cover } from "polished";
import { useState } from "react";
import Button from "../../components/forms/Button";
import ComboFontHeading from "../../components/typography/ComboFontHeading";
import { readCookieConsentState } from "../../components/gdpr/gdpr";
import LoadingIndicator from "../../components/utilities/LoadingIndicator";
import PrivacyNotice from "../../components/utilities/PrivacyNotice";
import PageSection from "../../components/reusableSections/PageSection";
import SEO from "../../components/utilities/SEO";
import SiteNavHeightPlaceholder from "../../components/site/SiteNavHeightPlaceholder";
import Spacing from "../../components/layout/Spacing";
import TextInput from "../../components/forms/TextInput";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromTablet,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { getCookie, setCookie } from "../../utils/cookies.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  KnownDocumentName,
  recordDocumentEvent,
} from "../../utils/documents.utils";
import { reportErrorSilently } from "../../utils/error.utils";
import AllCaps from "../../components/typography/AllCaps";
import { getFormMetaInfo } from "../../utils/form.utils";

const AccessPageHeader = styled.header`
  background-color: ${colorsV4.canvas550};
`;

const AccessPageHeaderInner = styled.div`
  ${maxPageContentWidthStyleObject};
  padding-top: ${rSize("sectionPadding")};
`;

const AccessPageContentGrid = styled.div`
  ${uptoTabletMd} {
    text-align: center;
  }
  ${fromTabletMd} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${rSize("gap")};
    align-items: center;
  }
  ${fromDesktop} {
    align-items: start;
  }
`;

const ReportThumbContainer = styled.div`
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  ${fromTablet} {
    max-width: 275px;
  }
  ${fromTabletMd} {
    max-width: unset;
    grid-column-end: span 5;
    padding-right: ${rSize("gap")};
  }
  ${fromDesktop} {
    height: 0;
    padding-bottom: 0;
    padding-right: ${widthInGrid(1)};
  }
`;
const ReportThumb = styled.figure`
  margin: 0;
  position: relative;
  img {
    position: relative;
    display: block;
    max-width: 100%;
    border-radius: 4px;
    border: 1px solid ${colorsV4.grey300};
    z-index: 2;
    aspect-ratio: ${(595 / 842) * 100}%;
    pointer-events: none;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    border-radius: 4px;
    border: 1px solid ${colorsV4.grey300};
    position: absolute;
    ${cover()}
  }
  &:before {
    z-index: 1;
    transform: translate(3px, 3px);
    border-bottom-right-radius: 5px;
    background-color: #f7f3ee;
  }
  &:after {
    z-index: 0;
    transform: translate(6px, 6px);
    border-bottom-right-radius: 6px;
    background-color: #f0ede8;
  }
`;

const AccessPageContentGridTextContent = styled.div`
  ${fromTabletMd} {
    grid-column-start: 6;
    grid-column-end: span 7;
  }
`;

const LoadingIndicatorBlock = styled.div`
  padding: 5em 3em;
  text-align: center;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // make sure we push footer out of sight
  min-height: 600px;
  > * {
    margin-left: auto;
    margin-right: auto;
  }
`;

const TextInputSet = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  border-radius: 1em;
  border: 0;
  > input {
    position: relative;
    border-radius: 0;
    margin-top: -2px;
    grid-column-end: span 2;
    &:hover,
    &:focus {
      z-index: 1;
    }
    &:first-child {
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
    }
    &:last-child {
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
    }
    ${fromTabletMd} {
      &:nth-child(1) {
        border-top-left-radius: 1em;
        border-top-right-radius: 0;
        grid-column-end: span 1;
        margin-right: -2px;
      }
      &:nth-child(2) {
        border-top-left-radius: 0;
        border-top-right-radius: 1em;
        grid-column-end: span 1;
        margin-left: -2px;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        max-width: calc(100% - 2px);
      }
    }
  }
`;

const PageAccessSOCReport: PageComponent = props => {
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [showAccessForm, setShowAccessForm] = useState(false);

  const formState = useState(() => ({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    company: "",
    ...getFormMetaInfo(),
  }));

  const goToReport = () => {
    navigate("/reports/voice-of-the-soc-analyst");
  };

  const submit = async () => {
    const [form] = formState;

    setAwaitingResponse(true);

    if (!(await emailIsLegitimate(form.email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }

    const cookieConsentState = readCookieConsentState();
    if (cookieConsentState?.personalization) {
      setCookie("email_address", form.email, { days: 30 });
      setCookie("company", form.company, { days: 30 });
      setCookie("first_name", form.firstName, { days: 30 });
      setCookie("last_name", form.lastName, { days: 30 });
    }

    try {
      await recordDocumentEvent({
        type: "access",
        identifier: KnownDocumentName.soc2022,
        form,
      });
    } catch (e) {
      reportErrorSilently(e);
    } finally {
      goToReport();
    }
  };

  useOnMount(() => {
    const email = getCookie("email_address");
    if (email && email.includes("@")) {
      goToReport();
    } else {
      setShowAccessForm(true);
    }
  });

  return (
    <Page {...props}>
      <SEO
        title="Report: Voice of the SOC Analyst"
        description="Discover insights and recommendations from our survey of almost 500 security analysts – and the takeaways for leadership."
        image="/images/og/tines-report-voice-of-the-soc-analyst.png"
        noindex
      />
      {showAccessForm ? (
        <>
          <AccessPageHeader>
            <SiteNavHeightPlaceholder withSpacingTop />
            <AccessPageHeaderInner>
              <AccessPageContentGrid>
                <ReportThumbContainer>
                  <ReportThumb>
                    <img src="/images/voice-of-the-soc-analyst-report-cover.png" />
                  </ReportThumb>
                </ReportThumbContainer>
                <AccessPageContentGridTextContent>
                  <AllCaps children="2022 Report" />
                  <Spacing size="gap" />
                  <ComboFontHeading
                    snippets={[
                      [
                        <>
                          Voice of the
                          <br />
                        </>,
                        "serif",
                      ],
                      ["SOC Analyst"],
                    ]}
                    className={css`
                      line-height: 1;
                      ${fluidFontSize(48, 64)};
                    `}
                  />
                  <Spacing size="gap" />
                  <p>
                    Discover insights and recommendations from our survey of
                    almost 500 security analysts – and the takeaways for
                    leadership.
                  </p>
                  <Spacing size="4em" />
                </AccessPageContentGridTextContent>
              </AccessPageContentGrid>
            </AccessPageHeaderInner>
          </AccessPageHeader>

          <PageSection>
            <AccessPageContentGrid>
              <AccessPageContentGridTextContent>
                <Spacing size="4em" />
                <h2>Read the report now</h2>
                <Spacing size="2em" />
                <TextInputSet>
                  <TextInput
                    formState={formState}
                    name="firstName"
                    placeholder="First name *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="lastName"
                    placeholder="Last name *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="email"
                    type="email"
                    placeholder="Email *"
                    required
                    className="fullWidth"
                  />
                  <TextInput
                    formState={formState}
                    name="jobTitle"
                    placeholder="Job Title *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="company"
                    placeholder="Company *"
                    required
                  />
                </TextInputSet>
                <Spacing size="2em" />
                <Button
                  loading={awaitingResponse}
                  onClick={submit}
                  padding="1em"
                  fullWidth
                >
                  Access report
                </Button>
                <Spacing size=".5em" />
                <PrivacyNotice />
              </AccessPageContentGridTextContent>
            </AccessPageContentGrid>
          </PageSection>
        </>
      ) : (
        <LoadingIndicatorBlock>
          <LoadingIndicator delayInSeconds={1} />
        </LoadingIndicatorBlock>
      )}

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageAccessSOCReport;
