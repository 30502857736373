import { styled } from "@linaria/react";
import RedirectHandler from "../components/utilities/RedirectHandler";
import SEO from "../components/utilities/SEO";
import { rSize } from "../styles/responsiveSizes.styles";
import { PageComponent } from "../types/gatsby.types";
import Page from "./Page.template";
import { PageThemeProps } from "../components/utilities/PageTheme";

const Wrapper = styled.div`
  padding: ${rSize("sectionPadding")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  min-height: 100vh;
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const ClientRedirectPageTemplate: PageComponent<
  {
    pageContext: {
      title?: string;
      description?: string;
      ogImage?: string;
      to: string;
      message?: string;
      hub?: boolean;
      delay?: number;
    };
  } & PageThemeProps
> = props => {
  return (
    <Page {...props}>
      <SEO
        title={props.pageContext.title ?? "Redirecting..."}
        description={props.pageContext.description}
        image={props.pageContext.ogImage}
        noindex
      />
      <Wrapper>
        <RedirectHandler
          to={props.pageContext.to}
          delay={props.pageContext.delay}
        />
        {props.pageContext.message && <p>{props.pageContext.message}</p>}
      </Wrapper>
    </Page>
  );
};

export default ClientRedirectPageTemplate;
