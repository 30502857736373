import { styled } from "@linaria/react";
import { ValidHtmlWysiwygBlock } from "../../utils/datocms.utils";
import DangerouslySetHtmlContent from "../utilities/DangerouslySetHtmlContent";

type Props = {
  record?: ValidHtmlWysiwygBlock;
};

const HtmlWysiwygBlockDiv = styled.div``;

const HtmlWysiwygBlock = (props: Props) => {
  return (
    <HtmlWysiwygBlockDiv>
      <DangerouslySetHtmlContent html={props.record?.content ?? ""} />
    </HtmlWysiwygBlockDiv>
  );
};

export default HtmlWysiwygBlock;
