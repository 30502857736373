import ComboFontHeading from "../../../typography/ComboFontHeading";
import PillLegacy from "../../../basic/PillLegacy";
import ReportCard, { ReportCardInnerAligner } from "../../ReportCard";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import DonutChart from "../../charts/DonutChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import Spacing from "../../../layout/Spacing";
import ColumnedAndAvoidBreaksInParagraphs from "../../../layout/Columned";
import { styled } from "@linaria/react";
import { fromTablet } from "../../../../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";
import ReportChartContainer from "../../ReportChartContainer";

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const MHChapter3 = () => {
  return (
    <ReportSection
      id="mental-health-at-work"
      themeName="pink"
      header={
        <>
          <PillLegacy color="pink">Part Three</PillLegacy>
          <ComboFontHeading
            snippets={[
              [
                <>
                  Mental health
                  <br />
                </>,
                "serif",
              ],
              [<>at work</>],
            ]}
          />
          <p>
            Given the amount of time spent at work, an individual’s daily
            duties, workplace culture, colleague relationships, office politics,
            and more can negatively impact mental health if they don’t have ways
            of dealing with the dynamics in a healthy way. Our respondents told
            us about how the stress and situations in their workplaces are
            affecting their mental health, and also how their mental health is
            impacting their productivity. Are they finding ways to handle the
            stress, or is the workplace overwhelming them?
          </p>
        </>
      }
    >
      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>66% have some significant level of stress at work</h3>
        <p>
          Stress levels at work can significantly impact an individual’s
          wellbeing, and it seems that our respondents are under a fair amount
          of stress with their daily work. 66% say they have some significant
          level of stress at work: 22.2% say they’re very stressed, 23% say
          they’re fairly stressed, and 20.8% say they’re somewhat stressed.
          17.6% report that they’re not very stressed, and 16.3% report they’re
          not stressed at all.
        </p>
        <p>
          Overall, US respondents say they’re more stressed at work than
          European respondents, with 68.6% of US respondents saying they have
          some significant level of stress at work, while 63.5% of European
          respondents say the same.
        </p>
        <p>
          Additionally, 68.2% of respondents 35 or older report having some
          significant level of stress at work, while 64.2% of respondents 34 or
          younger report the same.
        </p>
        <p>
          Finally, 67.5% of senior level respondents report having some
          significant level of stress at work, while 67.7% of entry level
          respondents say the same.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            When it comes to your daily work, how stressed are you currently?
          </h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Very stressed", 228],
              ["Fairly stressed", 236],
              ["Somewhat", 214],
              ["Not very stressed", 181],
              ["Not at all", 168],
            ]}
            donutPercentage={0.375}
            height={325}
            colors={[
              colorsV4.warmBlack,
              colorsV4.orange,
              colorsV4.pink,
              colorsV4.green,
              colorsV4.purple,
            ]}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>63% say their stress levels are rising</h3>
        <p>
          When it comes to stress, the levels are rising. For 37.3% of
          respondents, their stress has stayed the same. However, for 62.7%,
          stress levels have gotten worse.
        </p>
        <p>
          Stress levels have gotten slightly worse for younger respondents as
          well. For those 34 and younger, 63.5% report that their stress levels
          have gotten worse, while of those 35 and older, 61.9% reported
          the same.
        </p>
        <p>
          Stress levels have also gotten worse for entry level respondents, with
          63.4% reporting that their stress levels have gotten worse, while
          62.7% of senior level respondents say the same.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            When it comes to work-related stress, how have your stress levels
            changed over the past 12 months?
          </h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["It has stayed the same", 383],
              ["It has increased or gotten worse", 378 + 266],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.pink, colorsV4.warmBlack]}
            height={325}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>64% say their mental health affects their productivity</h3>

        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            For our respondents, mental health is having an impact on
            productivity, as over a quarter (23.9%) of respondents say that
            their mental health has “very often” affected their ability to get
            their work done. Another 21.3% said it “fairly often” impacts their
            work, and an additional 19.2% says it “sometimes” does. For 20.5%,
            their mental health “doesn’t very often” affect their productivity,
            and 15.2% say it never does.
          </p>
          <p>
            For those who responded “very often,” 67% say their stress levels
            have increased, and 70.2% say they experience some significant level
            of stress in their daily work. For those who responded “never,”
            64.1% also say their stress levels have increased, and 54.4% say
            they experience some significant level of stress in their daily work
            — meaning it might not be the stress level but the ability to
            manage stress.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>

        <h4>
          During the past 12 months, how often has mental health affected your
          ability to get work done?
        </h4>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Very often", 245],
              ["Fairly often", 219],
              ["Sometimes", 197],
              ["Not very often", 210],
              ["Never", 156],
            ]}
            donutPercentage={0.375}
            colors={[
              colorsV4.warmBlack,
              colorsV4.orange,
              colorsV4.pink,
              colorsV4.green,
              colorsV4.purple,
            ]}
            height={325}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>64% say their work affects their mental health</h3>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            What about the reverse — is work impacting mental health? Nearly a
            quarter (22.4%) of respondents say that their work “very often”
            affects their mental health. Another 23.2% say work “fairly often”
            does, and an additional 18.6% said work “sometimes” affects their
            mental health. For 19.6%, work “doesn’t very often” affect their
            mental health, and 16.3% say it never does.
          </p>
          <p>
            For those who responded “very often,” 64.8% say their stress levels
            have increased, and 77% say they experience some significant level
            of stress in their daily work. For those who responded “never,”
            67.6% also say their stress levels have increased, and 57.6% say
            they experience some significant level of stress in their daily work
            — meaning it might not be the stress level but the ability to manage
            their mental health.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            During the past 12 months, how often has your work affected your
            mental health?
          </h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Very often", 230],
              ["Fairly often", 238],
              ["Sometimes", 191],
              ["Not very often", 201],
              ["Never", 167],
            ]}
            donutPercentage={0.375}
            colors={[
              colorsV4.warmBlack,
              colorsV4.orange,
              colorsV4.pink,
              colorsV4.green,
              colorsV4.purple,
            ]}
            height={325}
          />
        </ReportChartContainer>
      </ReportCard>

      <Grid>
        <ReportCard
          backgroundColor={colorsV4.pink}
          textColor={colorsV4.white}
          accentColor={colorsV4.white}
          className={css`
            h3 {
              font-size: 2.8rem;
            }
            p {
              font-weight: 500;
            }
          `}
        >
          <h3>
            Situations affecting
            <br />
            mental health
          </h3>
          <p>
            Knowing the connection between work impacting mental health and vice
            versa, respondents provided information about how they’re feeling
            and reacting to various situations at work that may impact their
            mental health. Here’s what they replied.
          </p>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              1. In the last month, how often have you been upset at work
              because of something that happened unexpectedly?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 222],
                ["Fairly often", 194],
                ["Sometimes", 223],
                ["Almost never", 200],
                ["Never", 188],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.warmBlack,
                colorsV4.orange,
                colorsV4.pink,
                colorsV4.green,
                colorsV4.purple,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              2. In the last month, how often have you felt that you were unable
              to control the important tasks or projects at work?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 221],
                ["Fairly often", 235],
                ["Sometimes", 207],
                ["Almost never", 179],
                ["Never", 185],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.warmBlack,
                colorsV4.orange,
                colorsV4.pink,
                colorsV4.green,
                colorsV4.purple,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              3. In the last month, how often have you felt nervous and stressed
              at work?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 236],
                ["Fairly often", 232],
                ["Sometimes", 203],
                ["Almost never", 203],
                ["Never", 153],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.warmBlack,
                colorsV4.orange,
                colorsV4.pink,
                colorsV4.green,
                colorsV4.purple,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              4. In the last month, how often have you felt confident about your
              ability to handle problems at work?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 223],
                ["Fairly often", 243],
                ["Sometimes", 206],
                ["Almost never", 178],
                ["Never", 177],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.purple,
                colorsV4.green,
                colorsV4.pink,
                colorsV4.orange,
                colorsV4.warmBlack,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              5. In the last month, how often have you been able to control
              irritations at work?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 218],
                ["Fairly often", 230],
                ["Sometimes", 204],
                ["Almost never", 201],
                ["Never", 174],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.purple,
                colorsV4.green,
                colorsV4.pink,
                colorsV4.orange,
                colorsV4.warmBlack,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              6. In the last month, how often have you felt that you were on top
              of things at work?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 241],
                ["Fairly often", 253],
                ["Sometimes", 207],
                ["Almost never", 186],
                ["Never", 140],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.purple,
                colorsV4.green,
                colorsV4.pink,
                colorsV4.orange,
                colorsV4.warmBlack,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              7. In the last month, how often have you felt burned at work?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 233],
                ["Fairly often", 220],
                ["Sometimes", 228],
                ["Almost never", 158],
                ["Never", 188],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.warmBlack,
                colorsV4.orange,
                colorsV4.pink,
                colorsV4.green,
                colorsV4.purple,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              8. In the last month, how often have you been angered by things at
              work that were outside of your control?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 215],
                ["Fairly often", 235],
                ["Sometimes", 194],
                ["Almost never", 209],
                ["Never", 174],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.warmBlack,
                colorsV4.orange,
                colorsV4.pink,
                colorsV4.green,
                colorsV4.purple,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>

        <ReportCard>
          <ReportCardInnerAligner>
            <h4>
              9. In the last month, how often have you felt difficulties at work
              were piling up so high that you could not overcome them?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Very often", 225],
                ["Fairly often", 219],
                ["Sometimes", 215],
                ["Almost never", 191],
                ["Never", 177],
              ]}
              legendPosition="right"
              donutPercentage={0.375}
              colors={[
                colorsV4.warmBlack,
                colorsV4.orange,
                colorsV4.pink,
                colorsV4.green,
                colorsV4.purple,
              ]}
            />
          </ReportCardInnerAligner>
        </ReportCard>
      </Grid>

      <ReportSummaryCard>
        <blockquote>
          <p>
            Stress levels are not only rising, but they’re also pervasive.
            Two-thirds of respondents are feeling the pressure at work: 66% say
            they have some significant level of stress at work, 63% say their
            stress levels are rising.
          </p>
        </blockquote>
        <p>
          Of those who report some level of stress at work, 64% also report that
          their mental health has affected their productivity, and 64% say their
          work has impacted their mental health — showing a correlation between
          stress levels at work and mental health.
        </p>
        <p>
          But does high stress always lead to mental health concerns? Those who
          replied “never” for their mental health impacting their productivity
          and for their work impacting their mental health still experience high
          levels of stress and have had stress levels that have increased. This
          suggests that for some, the levels of stress at work do not negatively
          impact their mental health because they’ve found healthy and
          productive ways to manage it.
        </p>
        <p>
          Having different levels of coping with or not coping with workplace
          issues is likely the reason for such varied responses to the different
          work situations respondents encounter. For example, 41% said they were
          often upset at work because of something that happened unexpectedly,
          while 38% said they weren’t. 43% said they often felt difficulties at
          work were piling up so high that they could not overcome them, while
          36% didn’t. 46% said they have often felt nervous and stressed at
          work, while 35% said they haven’t.
        </p>
        <p>
          It's unlikely that the reasons for these differences are because some
          are more “strong-willed” than others. The difference is likely how
          individuals are managing their mental health, as improved mental
          health can help individuals with stress, feeling overwhelmed, getting
          angry, or other reactions to work. As we saw above, there is a segment
          of our respondents who are experiencing rising stress at work, yet
          their mental health is not being impacted.
        </p>
        <Spacing size="1em" />
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default MHChapter3;
