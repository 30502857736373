import { css } from "linaria";
import { colors } from "../../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

export const CommunityEditionIcon = () => (
  <svg
    width="111"
    height="92"
    viewBox="0 0 111 92"
    fill="none"
    className={css`
      --shadow-color: ${colors.dark500};
      ${darkModeLinariaCSS(`--shadow-color: ${colors.dark900};`)}
    `}
  >
    <path
      d="M77.2884 85.6526L73.0805 90.4985H38.4412V85.6526H77.2884Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M19.7646 57.7508H19.7646C23.5347 57.7506 26.9293 58.1447 29.3652 58.7722C30.5873 59.0869 31.5374 59.4528 32.1691 59.8375C32.8326 60.2416 32.9906 60.5671 32.9906 60.7543C32.9906 60.9415 32.8327 61.2669 32.1691 61.671C31.5375 62.0555 30.5874 62.4213 29.3653 62.736C26.9294 63.3632 23.5349 63.7573 19.7646 63.7573C15.994 63.7573 12.5995 63.3633 10.1638 62.736C8.94184 62.4214 7.99181 62.0557 7.36025 61.6711C6.69682 61.2671 6.53886 60.9416 6.53886 60.7543C6.53886 60.5671 6.69684 60.2415 7.36026 59.8375C7.9918 59.4529 8.94182 59.087 10.1638 58.7723C12.5995 58.1449 15.9939 57.7508 19.7646 57.7508Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M22.7924 57.2208L19.4285 55.1184V29.8796C19.5071 29.786 13.0342 33.562 13.0342 33.562V55.1184L8.91332 57.6572C6.83018 58.9933 5.57812 59.6288 5.57812 60.1676C5.57812 61.4917 10.3473 62.5649 16.2313 62.5649C22.1144 62.5649 26.8832 61.4917 26.8832 60.1676C26.8828 59.6286 24.6946 58.2916 22.7924 57.2208Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M25.1689 14.2052C25.6121 13.0986 25.8617 11.896 25.8617 10.6308C25.8617 5.31142 21.5493 1 16.2304 1C10.912 1 6.59778 5.31142 6.59778 10.6308C6.59778 11.8957 6.84876 13.0986 7.291 14.2052C3.48121 16.9729 1 21.4628 1 26.5333C1 34.944 7.81911 41.7641 16.2304 41.7641C24.6422 41.7641 31.4618 34.944 31.4618 26.5333C31.4618 21.4628 28.9796 16.9732 25.1689 14.2052Z"
      fill="#49B889"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.5431 43.5559C13.4994 43.4836 13.4655 43.422 13.4412 43.3756V42.2485C13.7954 42.3648 14.1772 42.43 14.5537 42.4642C15.2966 42.5317 16.1082 42.4859 16.8472 42.4C17.5895 42.3137 18.2772 42.1846 18.7781 42.0776C18.8486 42.0625 18.9155 42.0479 18.9784 42.0338L19.1639 44.1657C18.9959 44.2687 18.7484 44.4081 18.4378 44.5479C17.8134 44.8289 16.9529 45.1032 15.9808 45.1032C15.108 45.1032 14.4709 44.6711 14.0316 44.2025C13.8123 43.9685 13.6501 43.7329 13.5431 43.5559Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M109.761 66.8655L101.002 80.7376H49.6971V66.8655H109.761Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M96.2028 33.6675L71.8192 15.0215L47.4352 33.6675L40.7285 43.0567V78.4374H89.4964L96.2028 69.0479V33.6675Z"
      fill="#8578E6"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M40.7285 43.0567V78.4374H89.4964V43.0567L65.112 24.4102L40.7285 43.0567Z"
      fill="#8578E6"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M65.1113 24.4104L71.8185 15.0215"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M89.4941 43.0566L96.2017 33.6675"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M68.1148 68.2793H24.4331L21.584 72.3943V90.4367H65.2661L68.1148 86.3214V68.2793Z"
      fill="#F48EBA"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M21.584 72.3945H65.2661V90.4367"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M65.2656 72.3943L68.1144 68.2793"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
    />
    <path
      d="M69.6893 59.9515V47.9266H81.7142V59.9515H69.6893Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M48.617 59.9515V47.9266H60.6419V59.9515H48.617Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
  </svg>
);
