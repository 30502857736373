import { Link } from "gatsby";
import PageSection from "../reusableSections/PageSection";
import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import CurlToTinesIllustration from "./CurlToTinesIllustration";
import { HalfADogFacingRight } from "../illustrations/spots/HalfADogFacingRight";
import Button from "../forms/Button";
import Spacing from "../layout/Spacing";
import { cover } from "polished";
import { useState } from "react";
import { zIndex } from "../../styles/zIndexes.styles";
import TextInput from "../forms/TextInput";
import Textarea from "../forms/Textarea";
import { getCookie } from "../../utils/cookies.utils";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import InfoBox from "../general/InfoBox";
import { openIntercomMessenger } from "../../utils/intercom.utils";
import PseudoLink from "../basic/PseudoLink";
import SpotIllustration from "../illustrations/SpotIllustration";
import { renderInPortal } from "../../utils/portals.utils";
import { getFormMetaInfo } from "../../utils/form.utils";

const Inner = styled.figure`
  margin: 0;
  background-color: ${colors.lightest};
  border: 1px dashed ${colors.purple200};
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  display: grid;
  grid-gap: ${rSize("gap")};
  ${uptoTabletLg} {
    grid-template-areas: "icon" "heading" "p" "figure";
    text-align: center;
  }
  ${fromTabletLg} {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
    grid-template-rows: auto minmax(auto, 1fr) auto;
    grid-template-areas: "heading p" "heading figure" "icon figure";
  }
  h2 {
    font-family: ${serif};
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: -0.01em;
    font-size: 2.4rem;
    align-self: center;
    ${uptoTabletLg} {
      max-width: 16em;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5em;
    }
    ${fromTabletLg} {
      font-size: 3.2rem;
      max-width: 10em;
    }
  }
  > p {
    grid-area: p;
    max-width: 27em;
    ${uptoTabletLg} {
      margin-top: 2em;
      margin-bottom: 1em;
      border-top: 1px solid ${colors.purple100};
      padding-top: 1.5em;
    }
    ${fromTabletLg} {
      margin-bottom: 1em;
    }
    a {
      font-weight: 700;
    }
  }
`;

const HeadingGroup = styled.div`
  grid-area: heading;
`;

const IconWrap = styled.div`
  grid-area: icon;
  align-self: center;
  ${uptoTabletLg} {
    margin: 1em 0 1em 1em;
  }
  ${fromTabletLg} {
    align-self: end;
  }
`;

const FigureWrap = styled.div`
  grid-area: figure;
`;

const ModalLayer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: grid;
  align-items: center;
  padding: 1em;
  z-index: ${zIndex("Modal")};
  overflow: auto;
  ${fromTablet} {
    padding: 1.75em;
  }
  ${fromDesktopMd} {
    padding: 2em;
  }
`;

const ModalBackdrop = styled.div`
  ${cover()};
  position: fixed;
  background-color: ${withOpacity(colors.lightest, 0.6)};
  .community & {
    background-color: ${withOpacity(colors.black, 0.6)};
  }
`;

const Modal = styled.div`
  position: relative;
  background-color: ${colors.lightest};
  border-radius: ${rSize("radius")};
  border: 1px solid ${colors.purple200};
  padding: ${rSize("md")};
  ${fromTablet} {
    width: 62rem;
  }
  max-width: 100%;
  margin: 3em auto 4em;
  box-shadow: 0 1em 8em rgba(0, 0, 0, 0.05);
`;

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  h3 {
    font-family: ${serif};
    font-weight: 400;
    font-size: 2.8rem;
  }
`;

const CloseButton = styled.button`
  appearance: none;
  background-color: ${colors.purple50};
  backdrop-filter: blur(1em);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  svg {
    display: block;
    width: 10px;
    height: 10px;
  }
`;

const ModalBody = styled.div`
  > p {
    margin-right: 4em;
  }
  label {
    font-weight: 600;
    font-size: 1.4rem;
    display: block;
    margin-bottom: 0.5em;
  }
`;

const SuccessMessageWrap = styled.div`
  background-color: ${colors.green50};
  border: 1px solid ${colors.green200};
  color: ${colors.green800};
  padding: ${rSize("lg")};
  text-align: center;
  border-radius: 0.75em;
  margin-top: 0.5em;
`;

const submissionUrl =
  "https://hq.tines.io/webhook/01f5012cec6cfcf8050a7aba4649881b/32c2a3934b4340c69c25529ae4eb304e";

export const ProductExplorerEmptyResultsSection = (props: {
  query: string;
}) => {
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const formState = useState(() => ({
    email: "",
    productName: "",
    apiDocumentationLink: "",
    comment: "",
  }));
  const [form, setForm] = formState;
  const showModal = () => {
    const email = getCookie("email_address");
    setForm({
      ...form,
      email: email && !form.email ? email : form.email,
      productName:
        props.query && !form.productName ? props.query : form.productName,
    });
    setShouldRenderModal(true);
  };
  const closeModal = () => {
    setShouldRenderModal(false);
  };

  const formIsValid = () => {
    return !!(
      form.productName &&
      form.email.includes("@") &&
      form.apiDocumentationLink &&
      form.email
    );
  };
  const submit = async () => {
    if (!formIsValid) {
      window.alert("Please fill in all required fields.");
      return;
    }
    setIsSubmitting(true);
    try {
      if (!(await emailIsLegitimate(form.email)).valid) {
        setIsSubmitting(false);
        window.alert(
          "Please use a valid business or personal email to continue."
        );
        return;
      }
      await fetch(submissionUrl, {
        method: "post",
        body: JSON.stringify({
          form: form,
          ...getFormMetaInfo(),
        }),
      });
      setSuccess(true);
    } catch (e) {
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formUI = (
    <>
      <p>
        Are we missing a product? Send us the link to their API documentation
        and we will add it for you.
      </p>
      <Spacing size="1em" />
      <label>Product name *</label>
      <TextInput
        formState={formState}
        name="productName"
        autoFocus
        disabled={isSubmitting}
      />

      <Spacing size="1em" />
      <label>Link to API Documentation *</label>
      <TextInput
        formState={formState}
        name="apiDocumentationLink"
        type="url"
        disabled={isSubmitting}
      />

      <Spacing size="1em" />
      <label>Your email *</label>
      <TextInput
        formState={formState}
        name="email"
        type="email"
        disabled={isSubmitting}
      />

      <Spacing size="1em" />
      <label>Add a comment</label>
      <Textarea
        formState={formState}
        name="comment"
        rows={3}
        disabled={isSubmitting}
      />

      {error && (
        <>
          <Spacing size="1em" />
          <InfoBox>
            <p>
              An error occurred, please try again. If this keeps happening, you
              can also submit a request{" "}
              <PseudoLink onClick={openIntercomMessenger}>
                by sending us a message
              </PseudoLink>
              .
            </p>
          </InfoBox>
        </>
      )}

      <Spacing size="1em" />
      <Button
        onClick={submit}
        darker
        fullWidth
        height="3.5em"
        loading={isSubmitting}
      >
        Submit
      </Button>
    </>
  );

  const SuccessMessage = () => (
    <SuccessMessageWrap>
      <SpotIllustration name="paperPlane" />
      <Spacing size="1em" />
      <h3>Thanks for submitting! We will be in touch.</h3>
      <Spacing size="2em" />
      <Button
        onClick={closeModal}
        width="8em"
        darker
        appearance="outlined"
        color="green"
      >
        Close
      </Button>
    </SuccessMessageWrap>
  );

  return (
    <PageSection relative>
      <Inner>
        <HeadingGroup>
          <h2>It looks like we don’t have a page for this product yet.</h2>
          <Spacing size="md" />
          <Button darker onClick={showModal}>
            Let us know
          </Button>
        </HeadingGroup>
        <p>
          If the product has an API, you can easily connect to it in Tines using
          our <Link to="/docs/actions/types/http-request">HTTP request</Link>{" "}
          actions, or make use of our{" "}
          <Link to="/docs/actions/shortcuts#import-from-curl">
            cURL to Tines
          </Link>{" "}
          functionality.
        </p>
        <FigureWrap>
          <CurlToTinesIllustration />
        </FigureWrap>
        <IconWrap>
          <HalfADogFacingRight />
        </IconWrap>
      </Inner>

      {shouldRenderModal &&
        renderInPortal(
          <ModalLayer>
            <ModalBackdrop />
            <Modal>
              <ModalHeader>
                <h3>Tell us more</h3>
                <CloseButton onClick={closeModal}>
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    stroke={colors.purple800}
                    strokeWidth="3"
                  >
                    <path d="M18 1L1 18M1 1L18 18" />
                  </svg>
                </CloseButton>
              </ModalHeader>
              <Spacing size="1em" />
              <ModalBody>{success ? <SuccessMessage /> : formUI}</ModalBody>
            </Modal>
          </ModalLayer>
        )}
    </PageSection>
  );
};
