import { styled } from "@linaria/react";
import { CSSProperties } from "linaria";
import { RefObject } from "react";
import {
  ACTION_TYPES,
  ActionTypeName,
} from "../../../constants/actionTypes.constants";
import {
  fromDesktop,
  fromPhoneLg,
  uptoPhoneLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";

type Props = {
  action: ActionTypeName;
  innerRef?: RefObject<HTMLDivElement>;
};

export const DemoActionButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 5rem;
  ${uptoPhoneLg} {
    aspect-ratio: 1;
  }
  ${fromPhoneLg} {
    height: 8rem;
  }
  ${fromDesktop} {
    font-size: 1.2rem;
    height: 10rem;
  }
  background-color: ${colors.white};
  text-align: center;
  span {
    display: block;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 0.5em;
    font-weight: 500;
    ${uptoPhoneLg} {
      display: none;
    }
  }
  svg {
    display: block;
    color: var(--color);
  }
`;

const DemoActionButton = (props: Props) => {
  const def = ACTION_TYPES[props.action];
  return (
    <DemoActionButtonWrap
      style={{ "--color": def.color } as CSSProperties}
      ref={props.innerRef}
    >
      <def.Icon />
      <span>{def.name}</span>
    </DemoActionButtonWrap>
  );
};

export default DemoActionButton;
