export const ExplosionTwoToneIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M6.9968 2.92135L8.31972 4.1672L9.69038 2.97406L10.6036 2.17907L10.7453 3.38156L10.9579 5.1863L12.7748 5.15334L13.9854 5.13138L13.321 6.1436L12.3238 7.66276L13.7368 8.80541L14.6783 9.56675L13.5187 9.91507L12.7549 10.1445L11.7783 10.4378L12.2568 11.3382L13.8318 14.3019L15 16.5L13.0472 14.9564L10.3717 12.8415L9.57664 12.213L9.08589 13.0998L8.69673 13.803L8.11045 14.8624L7.56605 13.7809L6.74901 12.1577L5.05294 12.8101L3.92286 13.2448L4.20101 12.0663L4.61848 10.2977L2.89987 9.70728L1.75476 9.31387L2.72532 8.58994L2.95645 8.41754L4.72918 8.34282L3.87043 8.98336L4.94341 9.35199L5.79976 9.6462L5.59174 10.5275L5.3311 11.6317L6.39001 11.2243L7.23512 10.8993L7.64224 11.7081L8.15234 12.7215L8.70169 11.7288L9.2723 10.6977L10.1968 11.4285L11.8791 12.7583L10.8953 10.9071L10.3232 9.83076L11.4906 9.4801L12.5772 9.15373L11.695 8.44034L10.9909 7.87099L11.4878 7.11402L12.1104 6.16556L10.976 6.18613L10.0707 6.20256L9.96477 5.30329L9.83204 4.17655L8.9763 4.92146L8.29333 5.51597L7.63414 4.89519L6.8082 4.11738L6.63149 5.23807L6.49045 6.1325L5.58646 6.08067L4.45378 6.01574L4.7905 6.57526L3.16384 5.81164L2.62064 4.909L3.82946 4.9783L5.64369 5.08231L5.92674 3.28727L6.11533 2.09125L6.9968 2.92135Z" />
    <path
      opacity="0.7"
      d="M16.8284 2.41421L15.4142 1L14 2.41421L15.4142 3.82843L16.8284 2.41421ZM2.13303 7.96045L1.73573 5.90305L7.44863 8.04636L2.13303 7.96045Z"
    />
    <path
      opacity="0.5"
      d="M2 2.5L4 1.5L5 3.5L2 2.5ZM8.3432 7.02926L7.31924 5.80979L7.38525 7.40078L5.7934 7.44102L7.07845 8.38138L6.1174 9.65103L7.65381 9.23264L8.04726 10.7756L8.67809 9.31355L10.1298 9.96798L9.37998 8.56318L10.7967 7.83625L9.23094 7.54658L9.54594 5.98568L8.3432 7.02926Z"
    />
  </svg>
);
