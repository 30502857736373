import { styled } from "@linaria/react";
import { css } from "linaria";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import {
  fromDesktop,
  onlyPhones,
  onlyTablets,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
} from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import FromDesktop from "../mediaQueries/FromDesktop";
import LinkToAnchor from "../basic/LinkToAnchor";

const Nav = styled.nav`
  transition: color 0.1s, background-color 1s;
  ${uptoDesktop} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    font-size: 1.2rem;
    background-color: ${colorsV4.canvas};
  }
  ${onlyPhones} {
    border-radius: 0.5em;
  }
  ${onlyTablets} {
    margin-bottom: ${rSize("widerPageMargin")};
  }
  ${fromDesktop} {
    position: sticky;
    font-size: 1.4rem;
    top: var(--pageTopFixedElementsHeight, 0px);
    transition: top 0.25s;
    background-color: ${colorsV4.canvas550};
    border-radius: ${rSize("radius")};
    padding: ${rSize("gap")};
  }
  hr {
    margin: 1em 0;
    ${uptoDesktop} {
      display: none;
    }
  }
  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style: none;
    ${uptoDesktop} {
      display: inherit;
      align-items: inherit;
      justify-content: center;
    }
  }
  li {
    counter-increment: key-finding-list;
    a {
      display: flex;
      font-weight: 500;
      text-decoration: none;
      line-height: 1.2;
      transition: color 0.1s, background-color 0.1s;
      ${uptoDesktop} {
        align-items: center;
        justify-content: center;
        width: 2.5em;
        height: 2.5em;
        border-radius: 50%;
      }
      ${fromDesktop} {
        align-items: baseline;
        padding: 0.375em 0;
        background-color: transparent !important;
      }
      i {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        width: 1.375em;
        height: 1.375em;
        font-weight: 700;
        text-align: center;
        border-radius: 50%;
        transform-origin: left;
        line-height: 1;
        &:before {
          ${fromDesktop} {
            transform: translateY(0.5px);
          }
        }
        ${fromDesktop} {
          transform: scale(0.8);
        }
      }
      span {
        transition: colors 0.1s;
      }
      ${fromDesktop} {
        &:hover {
          span {
            color: var(--ThemeColorPrimary, var(--ac)) !important;
          }
        }
      }
      + * {
        margin-top: 0.5em;
      }
      span {
        margin-left: 0.2em;
        ${uptoDesktop} {
          display: none;
        }
      }
    }
  }
  ul {
    li a {
      i {
        &:before {
          content: "●";
        }
        background-color: transparent;
        color: var(--ThemeColorPrimary, var(--ac));
      }
      &.cover-link {
        i {
          &:before {
            content: "○";
          }
        }
      }
    }
  }
  ol {
    counter-reset: nav-major-section-list;
    li a i {
      &:before {
        content: counter(key-finding-list);
      }
      background-color: var(--ThemeColorPrimary, var(--ac));
      color: ${colorsV4.white};
    }
  }
`;

const Heading = styled.header<{ fontSize?: string }>`
  display: block;
  border-radius: 1em;
  margin-bottom: 0.5em;
  p {
    font-size: ${p => p.fontSize ?? "2.4rem"};
    line-height: 1.15;
    font-weight: 600;
    > * {
      display: block;
    }
  }
`;

const ReportNav = (
  props: PropsWithChildren<{
    beforeHeading?: ReactNode;
    heading: ReactNode;
    accentColor?: BrandColorNameV4;
    headingFontSize?: string;
  }>
) => {
  const accentColor = colorV4(props.accentColor ?? "purple");
  return (
    <Nav
      className="ReportNav"
      data-accent-color={accentColor}
      style={
        {
          "--ac": accentColor,
        } as CSSProperties
      }
    >
      <FromDesktop>
        {props.beforeHeading}
        <LinkToAnchor
          to="#"
          className={css`
            text-decoration: none;
            display: block;
            &:hover {
              opacity: 0.9;
            }
          `}
        >
          <Heading fontSize={props.headingFontSize}>
            <p>{props.heading}</p>
          </Heading>
        </LinkToAnchor>
      </FromDesktop>
      <hr />
      {props.children}
    </Nav>
  );
};

export default ReportNav;
