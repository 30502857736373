import PageSection from "./PageSection";
import illo from "../../../static/images/pricing/tines-pricing-model-illustrated.svg";
import { styled } from "@linaria/react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromTablet,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import Spacing from "../layout/Spacing";
import { colors } from "../../styles/colors.styles";
import AllCaps from "../typography/AllCaps";

const Inner = styled.figure`
  margin: 0;
  padding: 0;
  background-color: ${colors.purple600};
  padding: ${rSize("gap")};
  display: grid;
  grid-gap: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  ${uptoTabletMd} {
    text-align: center;
  }
  ${fromTabletMd} {
    grid-template-columns: repeat(2, 1fr);
  }
  align-items: center;
  img {
    width: 100%;
  }
  h2 {
    font-size: 2.8rem;
    ${fromTabletMd} {
      font-size: 3.2rem;
    }
  }
  figcaption {
    padding: ${rSize("lg")} ${rSize("gap")};
    p {
      font-size: 1.4rem;
      ${fromTablet} {
        font-size: 1.6rem;
      }
    }
    a {
      font-weight: 600;
      text-decoration-color: ${withOpacity(colors.white, 0.175)};
      &:hover {
        text-decoration-color: ${withOpacity(colors.white, 0.4)};
      }
    }
  }
`;

const PricingModelIllustratedSection = () => {
  return (
    <PageSection>
      <Inner>
        <img src={illo} />
        <figcaption>
          <AllCaps>Paid plans</AllCaps>
          <Spacing />
          <h2>Simple and scalable</h2>
          <Spacing />
          <p>
            Start with our platform fee with advanced features and capacity for
            your business. Then use add-ons to expand that capacity when and how
            you need.{" "}
          </p>
          <Spacing size=".5em" />
          <p>
            <a
              href="https://explained.tines.com/en/articles/9620399-understanding-tines-pricing-and-packaging"
              {...externalLinkAttr}
            >
              Read more <sup>↗</sup>
            </a>
          </p>
          <Spacing />
        </figcaption>
      </Inner>
    </PageSection>
  );
};

export default PricingModelIllustratedSection;
