import { styled } from "@linaria/react";
import { useRef } from "react";
import {
  colorsV4,
  defaultChartColorSeriesV4,
} from "../../../styles/colorsV4.styles";
import { onChartLibraryReady } from "../../../utils/charts.utils";
import { valueWithOptionalUnit } from "../../../utils/css.utils";
import { debounce } from "../../../utils/debounce.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";

type Props = {
  header: [entryHeader: string, valueHeader: string];
  data: [label: string, value: number][];
  colors?: string[];
  donutPercentage?: number;
  height?: number;
  seoTitle?: string;
  maxWidth?: number;
  sortByValue?: boolean;
  legendPosition?: "bottom" | "right";
  chartArea?: google.visualization.PieChartOptions["chartArea"];
};

const DonutChartContainer = styled.figure<{
  height?: number;
  maxWidth?: number;
}>`
  margin: 0;
  padding: 0;
  height: ${p => valueWithOptionalUnit(p.height, 220)};
  max-width: ${p => valueWithOptionalUnit(p.maxWidth, "100%")};
  /* margin-left: -12px; */
  text {
    font-weight: 500;
  }
  .google-visualization-tooltip {
    pointer-events: none;
  }
  [fill="#0011cc"] {
    fill: ${colorsV4.purple};
  }
  /** move legends down a bit */
  defs[id*="ABSTRACT_RENDERER"] + g {
    transform: translateY(10px);
  }
`;

const DonutChart = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const drawChart = () => {
      const data = google.visualization.arrayToDataTable([
        props.header,
        ...(props.sortByValue
          ? props.data.sort((a, b) => b[1] - a[1])
          : props.data),
      ]);
      const chart = new google.visualization.PieChart(ref.current!);
      const draw = () =>
        chart.draw(data, {
          pieSliceBorderColor: "transparent",
          colors: props.colors ?? defaultChartColorSeriesV4,
          pieHole: props.donutPercentage ?? 0.5,
          fontName: "Roobert, sans-serif",
          // title: props.seoTitle,
          chartArea:
            props.chartArea ??
            (props.legendPosition === "right"
              ? { top: 18, left: 0, right: 0, bottom: 18 }
              : {
                  top: 18,
                  left: 0,
                  right: 0,
                  bottom: 42,
                }),
          backgroundColor: "transparent",
          titleTextStyle: {
            color: "transparent",
          },
          legend: {
            position: props.legendPosition ?? "bottom",
          },
        });
      const debounced = debounce(draw, { fireImmediately: true });
      window.addEventListener("resize", debounced);
      draw();
      return () => window.removeEventListener("resize", debounced);
    };
    return onChartLibraryReady(drawChart);
  });
  return (
    <DonutChartContainer
      ref={ref}
      height={props.height}
      maxWidth={props.maxWidth}
    />
  );
};

export default DonutChart;
