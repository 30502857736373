import { useRef, useState } from "react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import StoryEmbed from "../blocks/StoryEmbed.block";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import goldCupIcon from "./stickerIcons/goldCup.stickerIcon.svg";
import lightningIcon from "./stickerIcons/lightning.stickerIcon.svg";
import gsap from "gsap";
import ConfettiScreen from "../decorations/ConfettiScreen";
import { DatoCmsYdwwt } from "../../../graphql-types";
import { convertDatoRecordToStoryDescriptor } from "../../utils/story.utils";
import SpaceChildren from "../layout/SpaceChildren";
import ReactMarkdown from "react-markdown";

const YDWWTGrandPrizeSection = (props: { ydwwt: DatoCmsYdwwt }) => {
  const [shouldThrowConfetti, setShouldThrowConfetti] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const winnerRecord = props.ydwwt.winners?.[0];
  const runnerUpRecord = props.ydwwt.winners?.[1];
  const winner = winnerRecord?.story
    ? convertDatoRecordToStoryDescriptor(winnerRecord.story)
    : null;
  const runnerUp = runnerUpRecord?.story
    ? convertDatoRecordToStoryDescriptor(runnerUpRecord.story)
    : null;
  useOnMount(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#winner",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        toggleActions: "restart pause resume pause",
        invalidateOnRefresh: true,
      },
    });
    gsap.set(document.documentElement, {
      "--PageBackgroundColor": colorsV4.warmBlack900,
    });
    tl.to(
      document.documentElement,
      {
        "--PageBackgroundColor": colorsV4.warmBlack900,
        duration: 0.001,
      },
      0
    );
    tl.to(
      document.documentElement,
      {
        "--PageBackgroundColor": "#2d2944",
        duration: 0.2,
      },
      0.001
    );
    tl.to(
      document.documentElement,
      {
        "--PageBackgroundColor": colorsV4.warmBlack900,
        duration: 0.2,
      },
      0.8
    );
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setShouldThrowConfetti(true);
          observer.disconnect();
        }
      },
      { rootMargin: "-200px" }
    );
    ref.current && observer.observe(ref.current);
    return () => {
      tl.kill();
      observer.disconnect();
      document.documentElement.style.removeProperty("--PageBackgroundColor");
    };
  });
  return (
    <PageSection centered>
      <img src={goldCupIcon} />
      <Spacing size="lg" />
      <SectionHeading2 className="smaller">
        <Serif>Grand Prize</Serif>
      </SectionHeading2>
      <Spacing size="sectionPadding" />

      <SpaceChildren size="sectionMargin">
        {winner && (
          <div id="winner" ref={ref}>
            <StoryEmbed
              fullWidth
              withBorder
              withLinkToStoryPage
              doNotApplyCommunityStyling
              descriptor={Object.assign(winner, {
                name: winnerRecord?.name || winner.name,
                color: winnerRecord?.color ?? "purple",
              })}
              hideTools
              description={
                winnerRecord?.description ? (
                  <ReactMarkdown>{winnerRecord?.description}</ReactMarkdown>
                ) : undefined
              }
            />
          </div>
        )}
        {runnerUp && (
          <div id="runner-up">
            <img src={lightningIcon} />
            <Spacing size="lg" />
            <SectionHeading2 className="smaller">
              <Serif>Runner-up</Serif>
            </SectionHeading2>

            <Spacing size="sectionPadding" />

            <StoryEmbed
              fullWidth
              withBorder
              withLinkToStoryPage
              doNotApplyCommunityStyling
              descriptor={Object.assign(runnerUp, {
                name: runnerUpRecord?.name || runnerUp.name,
                color: runnerUpRecord?.color ?? "green",
              })}
              hideTools
              description={
                runnerUpRecord?.description ? (
                  <ReactMarkdown>{runnerUpRecord?.description}</ReactMarkdown>
                ) : undefined
              }
            />
          </div>
        )}
      </SpaceChildren>

      {winner && shouldThrowConfetti && <ConfettiScreen amount={150} />}
    </PageSection>
  );
};

export default YDWWTGrandPrizeSection;
