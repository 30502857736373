const ReceiveEmailIcon20 = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.2L15.5 5.2C16.218 5.2 16.8 5.78203 16.8 6.5L16.8 6.68423L10 10.6704L3.2 6.68424L3.2 6.5C3.2 5.78203 3.78203 5.2 4.5 5.2ZM3.2 7.84339L3.2 13.25C3.2 14.106 3.89396 14.8 4.75 14.8L15.25 14.8C16.106 14.8 16.8 14.106 16.8 13.25L16.8 7.84338L10.2529 11.6813L10 11.8296L9.74717 11.6813L3.2 7.84339ZM2 6.5C2 5.11929 3.11929 4 4.5 4L15.5 4C16.8807 4 18 5.11929 18 6.5L18 13.25C18 14.7688 16.7688 16 15.25 16L4.75 16C3.23122 16 2 14.7688 2 13.25L2 6.5Z"
      />
    </svg>
  );
};

export default ReceiveEmailIcon20;
