import { graphql, navigate } from "gatsby";
import SEO from "../components/utilities/SEO";
import Page from "./Page.template";
import { PageComponent } from "../types/gatsby.types";
import HeroSection, {
  HeroSectionDescription,
} from "../components/sections/HeroSection";
import { colors } from "../styles/colors.styles";
import AllCaps from "../components/typography/AllCaps";
import Spacing from "../components/layout/Spacing";
import {
  DatoCmsLibraryTool,
  DatoCmsProduct,
  DatoCmsProductCategory,
  DatoCmsProductFamily,
  SolutionsByProductIndexPageQueryQuery,
} from "../../graphql-types";
import { useState } from "react";
import ProductExplorerSearchBar from "../components/productExplorer/ProductExplorerSearchBar";
import PageSection from "../components/reusableSections/PageSection";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import ProductExplorerEntry, {
  ProductExplorerEntryGrid,
} from "../components/productExplorer/ProductExplorerEntry";
import { styled } from "@linaria/react";
import { ProductExplorerPageSectionHeader } from "../components/productExplorer/ProductExplorerPageSectionHeader";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { BuildYourOwnConnectionsSection } from "../components/productExplorer/BuildYourOwnConnectionsSection";
import { formatAsSearchString } from "../utils/search.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import { SearchableEntry } from "../types/helper.types";
import { ProductExplorerPageHeaderGraphics } from "../components/productExplorer/ProductExplorerPageHeaderGraphics";
import { fromTabletLg } from "../styles/breakpointsAndMediaQueries.styles";
import { FromTablet } from "../components/mediaQueries/UptoTablet";
import { ProductExplorerEmptyResultsSection } from "../components/productExplorer/ProductExplorerEmptyResultsSection";
import { first } from "lodash-es";
import Button from "../components/forms/Button";
import { AlignCenter } from "../components/typography/Align";
import { scrollToHash } from "../utils/anchorLinkScroll.utils";
import {
  getUrlQueryParam,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../utils/urlQueryParams.utils";
import ProductExplorerCategoriesCardGrid from "../components/productExplorer/ProductExplorerCategoriesCardGrid";
import { ProductExplorerIcon } from "../components/illustrations/spots/ProductExplorerIcon";
import ProductExplorerFamiliesCardGrid from "../components/productExplorer/ProductExplorerFamiliesCardGrid";
import { SolutionByProductPageCustomerLogoSection } from "../components/productExplorer/SolutionByProductPageCustomerLogoSection";
import { VendorHouseIcon } from "../components/productExplorer/icons/VendorHouseIcon";
import { StacksOfBlocksIcon } from "../components/productExplorer/icons/StacksOfBlocksIcon";
import ogImage from "../../static/images/og/tines-solutions-by-product.png";
import { reportIntent } from "../utils/intentReporter.utils";

const SpacingAfterHeader = styled.div`
  height: 3em;
  ${fromTabletLg} {
    height: 5em;
  }
`;
const focusOnSearchBar = () => {
  scrollToHash({
    useHash: "#product-explorer-search-bar",
    doNotPushState: true,
  });
  document.getElementById("product-explorer-search-bar")?.focus();
};

type ProductEntry = SearchableEntry<
  DatoCmsProduct & {
    family?: DatoCmsProductFamily | null;
  }
>;

const PageSolutionsByProduct: PageComponent<{
  data: SolutionsByProductIndexPageQueryQuery;
}> = props => {
  const [products] = useState(
    (props.data.products.nodes as ProductEntry[])
      .filter(p => (p.hasActionTemplates || p.hasStories) && !!p.icon?.url)
      .sort((a, b) => {
        const an = a.name?.trim().toLowerCase() ?? "";
        const bn = b.name?.trim().toLowerCase() ?? "";
        return an > bn ? 1 : an < bn ? -1 : 0;
      })
  );
  const families = props.data.families.nodes
    .filter(p => (p.hasActionTemplates || p.hasStories) && !!p.icon?.url)
    .sort((a, b) => {
      return a.position! - b.position!;
    })
    .map(f => ({
      ...f,
      products: (f.products ?? []).map(
        p => products.find(product => product.id === p?.id)!
      ),
    })) as unknown as DatoCmsProductFamily[];
  products.forEach(p => {
    const family = families.find(f =>
      (f.products ?? []).find(product => product?.id === p.id)
    );
    p.family = family;
  });
  const categories = (
    props.data.categories.nodes as DatoCmsProductCategory[]
  ).sort((a, b) => a.position! - b.position!);
  const description =
    "If it offers an API, Tines connects with it. From mainstream solutions to niche and internal tools, Tines is an integrator across your entire stack.";
  const formState = useState(() => ({
    query: "",
    category: "",
  }));
  const formattedQuery = formatAsSearchString(formState[0].query);
  const productsOfPartners = products.filter(p => {
    return !!props.data.partners.nodes.find(
      partner => partner.name === p.name || partner.name === p.family?.name
    );
  });
  useOnMount(() => {
    products.forEach(p => {
      p.name = p.name?.trim();
      p.__searchString = formatAsSearchString(
        [p.name ?? "", p.family?.name].filter(i => i).join("_")
      );
    });
    if (getUrlQueryParam("category")) {
      formState[1]({
        ...formState[0],
        category: getUrlQueryParam("category")!,
      });
    }
    return () => {
      removeUrlQueryParam("category");
    };
  });
  const selectedCategory = categories.find(
    c => c.slug === formState[0].category
  );
  const productsInSelectedCategory = selectedCategory
    ? products.filter(p =>
        p.categories?.some(c => c?.id === selectedCategory.id)
      )
    : products;
  const results = formattedQuery
    ? productsInSelectedCategory.filter(p => {
        return p.__searchString.includes(formattedQuery);
      })
    : selectedCategory
    ? productsInSelectedCategory
    : [];
  const handleProductExplorerSearchBarEnter = () => {
    const firstResult = first(results);
    if (firstResult) navigate(`/solutions/products/${firstResult.slug}`);
  };
  useOnMount(() => {
    reportIntent(`Viewed Solutions by Product page`);
  });
  return (
    <Page {...props} textColor={colors.purple800} navThemeColor="purple">
      <SEO
        title="Solutions by product"
        image={ogImage}
        description={description}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="productExplorer"
        // cspell:disable
        recordId="LGa48CEPQjixiP-Oh-iI8g"
        // cspell:enable
        label="Manage product explorer in CMS"
      />
      <ProductExplorerPageHeaderGraphics />

      <HeroSection contentRelative>
        <AllCaps as="p">Solutions by product</AllCaps>
        <h1>Connect anything</h1>
        <HeroSectionDescription wider>{description}</HeroSectionDescription>
      </HeroSection>
      <SpacingAfterHeader />
      <PageSection>
        <ProductExplorerSearchBar
          formState={formState}
          categories={categories}
          onEnter={handleProductExplorerSearchBarEnter}
          onCategoryChange={slug => {
            formState[1]({
              ...formState[0],
              category: slug,
            });
            setUrlQueryParam("category", slug);
          }}
        />
      </PageSection>

      {formattedQuery || selectedCategory ? (
        <>
          <Spacing size="xl" />
          {results.length > 0 ? (
            <PageSection relative>
              <ProductExplorerEntryGrid>
                {results.map(p => (
                  <ProductExplorerEntry product={p} key={p.id} />
                ))}
              </ProductExplorerEntryGrid>
            </PageSection>
          ) : (
            <ProductExplorerEmptyResultsSection query={formState[0].query} />
          )}
        </>
      ) : (
        <DefaultPageContent
          products={products}
          categories={categories}
          families={families.filter(p => p.hasActionTemplates || p.hasStories)}
          productsOfPartners={productsOfPartners}
          topProducts={
            (props.data.index?.topProducts ?? []) as DatoCmsProduct[]
          }
          onSelectCategory={c => {
            formState[1]({
              ...formState[0],
              category: `${c.slug ?? ""}`,
            });
            setUrlQueryParam("category", `${c.slug ?? ""}`);
            focusOnSearchBar();
          }}
        />
      )}

      <Spacing size="sectionMargin" />
      <ExplosionCTASection />
    </Page>
  );
};

export default PageSolutionsByProduct;

const DefaultPageContent = (props: {
  products: DatoCmsProduct[];
  categories: DatoCmsProductCategory[];
  topProducts: DatoCmsProduct[];
  productsOfPartners: DatoCmsProduct[];
  families: DatoCmsProductFamily[];
  onSelectCategory: (category: DatoCmsProductCategory) => void;
}) => {
  const [viewAll, _setViewAll] = useState(false);
  const topProducts = (props.topProducts ?? [])
    .map(tp => {
      return props.products.find(p => p.id === tp?.id);
    })
    .filter(i => i) as DatoCmsLibraryTool[];
  useOnMount(() => {
    const viewAllPersisted = sessionStorage.getItem("productExplorerViewAll");
    if (viewAllPersisted)
      _setViewAll(viewAllPersisted === "true" ? true : false);
  });
  const setViewAll = (value: boolean) => {
    sessionStorage.setItem("productExplorerViewAll", value ? "true" : "false");
    _setViewAll(value);
  };
  return (
    <>
      <Spacing size="xxl" />
      <PageSection relative>
        <ProductExplorerPageSectionHeader className="alignCenter">
          <div>
            <h2>{viewAll ? "All" : "Top"} products</h2>
            {!viewAll && (
              <FromTablet>
                <p>
                  Discover the top platforms users integrate through Tines,
                  building connections between systems in minutes and delivering
                  valuable workflows within hours.
                </p>
              </FromTablet>
            )}
          </div>
          <div>
            {viewAll ? (
              <Button
                appearance="outlined"
                darker
                onClick={() => {
                  setViewAll(false);
                }}
              >
                View top products
              </Button>
            ) : (
              <ProductExplorerIcon />
            )}
          </div>
        </ProductExplorerPageSectionHeader>
        <Spacing size="lg" />
        <ProductExplorerEntryGrid>
          {(viewAll ? props.products : topProducts).map(p => (
            <ProductExplorerEntry product={p} key={p.id} />
          ))}
        </ProductExplorerEntryGrid>
        {!viewAll && (
          <>
            <Spacing size="xl" />
            <AlignCenter>
              <Button
                appearance="outlined"
                darker
                onClick={() => {
                  setViewAll(true);
                  focusOnSearchBar();
                }}
              >
                View all products
              </Button>
            </AlignCenter>

            <Spacing size="sectionPadding" />

            <ProductExplorerPageSectionHeader className="alignCenter">
              <div>
                <h2>By vendor</h2>
              </div>
              <div>
                <VendorHouseIcon />
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            <ProductExplorerFamiliesCardGrid families={props.families} />

            {/* <Spacing size="sectionPadding" />

            <ProductExplorerPageSectionHeader className="alignCenter">
              <div>
                <h2>Partnered with Tines</h2>
              </div>
              <div>
                <HoldingHandsIcon />
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="lg" />
            <ProductExplorerEntryGrid>
              {props.productsOfPartners.map(p => (
                <ProductExplorerEntry product={p} key={p.id} />
              ))}
            </ProductExplorerEntryGrid> */}

            <Spacing size="sectionPadding" />

            <ProductExplorerPageSectionHeader className="alignCenter">
              <div>
                <h2>By category</h2>
              </div>
              <div>
                <StacksOfBlocksIcon />
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            <ProductExplorerCategoriesCardGrid
              products={props.products}
              categories={props.categories}
              onSelectCategory={props.onSelectCategory}
            />
          </>
        )}
      </PageSection>

      <Spacing size="sectionMargin" />
      <BuildYourOwnConnectionsSection />

      <Spacing size="sectionMargin" />
      <SolutionByProductPageCustomerLogoSection />
    </>
  );
};

export const solutionsByProductIndexPageQuery = graphql`
  query SolutionsByProductIndexPageQuery {
    index: datoCmsProductExplorer {
      topProducts {
        id: originalId
      }
    }
    products: allDatoCmsProduct(
      sort: { name: ASC }
      filter: { hidden: { ne: true } }
    ) {
      nodes {
        id: originalId
        name
        slug
        hasActionTemplates
        hasStories
        icon {
          url
        }
        categories {
          id: originalId
        }
      }
    }
    categories: allDatoCmsProductCategory {
      nodes {
        id: originalId
        name
        slug
        position
      }
    }
    families: allDatoCmsProductFamily(filter: { hidden: { ne: true } }) {
      nodes {
        id: originalId
        name
        slug
        position
        hasActionTemplates
        hasStories
        icon {
          url
        }
        products {
          id: originalId
        }
      }
    }
    partners: allDatoCmsPartner {
      nodes {
        slug
        name
      }
    }
  }
`;
