import { styled } from "@linaria/react";
import { css } from "linaria";
import { colorsV4, withOpacity } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import ComboFontHeading from "../../../typography/ComboFontHeading";
import CornerDotSet from "../../../utilities/CornerDotSet";
import Spacing from "../../../layout/Spacing";
import ReportCard from "../../ReportCard";
import ReportSectionHeader from "../../ReportSectionHeader";
import NumberedListCardGrid from "../../NumberedListCardGrid";
import { ReportBaseSectionStyle } from "../../ReportBaseStyles";
import ReportSection from "../../ReportSection";

const SOCSummaryActionableTakeawaysContainer = styled.div`
  ${ReportBaseSectionStyle};
  position: relative;
  background-color: ${colorsV4.purple};
  color: ${colorsV4.white};
`;

const SOCSummaryActionableTakeaways = () => {
  return (
    <ReportSection
      id="actionable-takeaways"
      themeName="purple"
      fullWidthWhenSmall
    >
      <SOCSummaryActionableTakeawaysContainer>
        <CornerDotSet
          color={withOpacity(colorsV4.white, 0.7)}
          offset={rSize("gap")}
        />
        <ReportSectionHeader
          icon="blocksOnCar"
          backgroundColor={withOpacity(colorsV4.white, 0.075)}
        >
          <ComboFontHeading
            snippets={[
              [<>Actionable takeaways for</>],
              [<>security team leaders</>, "serif"],
            ]}
          />
        </ReportSectionHeader>
        <Spacing size="gap" />
        <ReportCard backgroundColor={withOpacity(colorsV4.white, 0.075)}>
          <p>
            As we’ve seen in this report, SOC teams are passionate yet
            challenged. They’re satisfied and engaged with their work, yet
            endless manual tasks, understaffed teams, inefficient processes, and
            too many alerts are stifling their ability to do more high-quality,
            creative work. They’re stuck doing repetitive tasks today, unable to
            proactively work on preparing their organization’s security posture
            for tomorrow.
          </p>
          <p>
            What can SOC leaders do to improve their teams in 2022? Here are
            four ways forward.
          </p>
        </ReportCard>
        <Spacing size="gap" />
        <NumberedListCardGrid
          className={css`
            h4 {
              font-size: 1.9rem;
            }
          `}
        >
          <li>
            <h4>
              Improving time spent
              <br /> on reporting
            </h4>
            <p>
              Since reporting is the task taking up most of an analyst’s time,
              start there. Reporting is absolutely necessary in order to
              document case notes and inventory team performance, but it’s
              responsive — collecting what happened after the fact — rather than
              proactive. Alongside the fact that teams feel understaffed (the
              greatest challenge experienced) and slowed down by a lack of
              people (the number one thing preventing teams from doing good
              work), streamlining the reporting processes through automation
              will allow your analysts to do their real job: analysis.
            </p>
          </li>
          <li>
            <h4>
              Make triage
              <br /> enjoyable
            </h4>
            <p>
              Triaging is the top task analysts enjoy the least, which is no
              surprise if they’re being bogged down by deduplicating repeated
              alerts (“too many alerts” being in the top five challenges
              analysts face), dealing with similar cases again and again, and
              facing too much sheer noise overall. By having automated workflows
              address the bulk of low-value and duplicate alerts, triage can
              become enjoyable again: fun, high-quality, and high-impact
              detective work. Remember, too, that advanced triage was one of the
              tasks analysts would automate if given the chance.
            </p>
          </li>
          <li style={{ letterSpacing: "-0.01em" }}>
            <h4>Increase retention by measuring and minimizing burnout</h4>
            <p>
              Security team leaders want to see their team happy, passionate,
              and doing their best work — and don’t like to hear that their
              teams are burned out and looking to leave the jobs they’re
              currently in. In addition to the metrics mentioned above to
              measure performance, security leaders need to count burnout as a
              key metric, too. What could increase their engagement and keep
              them around? Having the ability to automate tedious manual tasks,
              providing them with more flexible, best-in-breed tools with
              advanced capabilities, and hiring more people to mitigate the
              workload.
            </p>
          </li>
          <li>
            <h4>
              It’s time for
              <br />
              no-code automation
            </h4>
            <p>
              Automation has been hyped and sold in security for decades, yet
              teams continue to suffer under the pull of manual work. As we saw
              above, the most frustrating aspect of being a SOC analyst is
              “spending time on manual work,” with one in four analysts spending
              over 75% of their time on “tedious manual work.” This is the
              reason why “learning to code” is cited as the skill analysts will
              most need to be successful, as they believe that learning
              development skills may help to overcome the never-ending toil.
            </p>
          </li>
        </NumberedListCardGrid>
      </SOCSummaryActionableTakeawaysContainer>
    </ReportSection>
  );
};

export default SOCSummaryActionableTakeaways;
