import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import { fromDesktopMl } from "../../styles/breakpointsAndMediaQueries.styles";

const View = styled.div`
  @keyframes ViewEnter {
    from {
      opacity: 0;
      transform: translateY(6px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  animation: ViewEnter 0.3s;
  padding: 4em 1.5em 1.5em;
  text-align: center;
  height: 100%;
  ${fromDesktopMl} {
    padding: 4em 2em 2em;
  }
  h3 {
    font-size: 1.8rem;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.25;
    text-wrap: balance; // supported in Chrome but not in safari
  }
  p {
    font-size: 1.4rem;
  }
`;

const DemoFormMessageView = (
  props: PropsWithChildren<{
    dataTest?: string;
  }>
) => {
  return <View>{props.children}</View>;
};

export default DemoFormMessageView;
