import { styled } from "@linaria/react";
import { ValidDataCardGridBlock } from "../../utils/datocms.utils";
import { DatoCmsDataCard } from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import { font } from "../../styles/fonts.styles";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";

type Props = {
  record?: ValidDataCardGridBlock;
};

const DataCardGridDiv = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  &[data-columns="2"] {
    grid-template-columns: repeat(2, 1fr);
  }
  &[data-columns="3"] {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const DataCardGrid = ({ record }: Props) => {
  const cards = (record?.cards ?? []) as DatoCmsDataCard[];
  const columns = cards.length > 2 && cards.length % 3 === 0 ? 3 : 2;
  return (
    <DataCardGridDiv className="DataCardGrid" data-columns={columns}>
      {record?.cards?.map((c, i) => (
        <DataCard card={c as DatoCmsDataCard} key={i} />
      ))}
    </DataCardGridDiv>
  );
};

type DataCardDivProps = {
  backgroundColor: string;
  color: string;
};

const DataCardDiv = styled.div<DataCardDivProps>`
  background-color: ${p => p.backgroundColor};
  color: ${p => p.color};
  padding: ${rSize("gap")};
  border-radius: 1em;
  > span {
    display: block;
    &:first-child {
      font-weight: 400;
      font-family: ${font("serif")};
      font-size: 2.6rem;
      margin-top: 0.5em;
      line-height: 1;
      ${fromTabletLg} {
        font-size: 3.2rem;
      }
      ${fromDesktopMd} {
        font-size: 3.8rem;
      }
    }
    &:nth-child(2) {
      font-size: 1.4rem;
      font-weight: 500;
      ${fromTablet} {
        font-size: 1.5rem;
      }
      line-height: 1.2;
      max-width: 20em;
      margin-top: 0.4em;
    }
  }
`;

const DataCard = (props: { card: DatoCmsDataCard }) => {
  const theme = getBrandColorTheme(props.card.color);
  return (
    <DataCardDiv
      backgroundColor={
        props.card.color === "Neutral" ? colors.light100 : theme.c100
      }
      color={theme.c800}
    >
      <span>{props.card.data}</span>
      <span>{props.card.label}</span>
    </DataCardDiv>
  );
};

export default DataCardGrid;
