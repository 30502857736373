import ComboFontHeading from "../../../typography/ComboFontHeading";
import PillLegacy from "../../../basic/PillLegacy";
import SpotIllustration from "../../../illustrations/SpotIllustration";
import ReportSecondarySection from "../../ReportSecondarySection";
import ReportChartContainer from "../../ReportChartContainer";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import Spacing from "../../../layout/Spacing";
import NoWrap from "../../../typography/Nowrap";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import LeaderboardChart, {
  LeaderboardChartItem,
} from "../../charts/LeaderboardChart";
import BarChart from "../../charts/BarChart";
import { css } from "linaria";

const SOCChapter3 = () => {
  return (
    <ReportSection
      id="what-prevents-good-work"
      themeName="orange"
      header={
        <>
          <div>
            <SpotIllustration name="brokenEgg" />
          </div>
          <PillLegacy color="orange">Part Three</PillLegacy>
          <ComboFontHeading
            snippets={[
              [
                <>
                  What prevents
                  <br />
                </>,
              ],
              [<>good work</>, "serif"],
            ]}
          />
          <p>
            Everyone wants to do their best work, especially highly-engaged
            security analysts. But as we saw in the past section, processes that
            are both tedious and unenjoyable — processes that could be
            streamlined and automated — are taking up most or all of
            an analyst’s time. What are some other challenges or barriers that
            SOC teams encounter?
          </p>
        </>
      }
    >
      <ReportSecondarySection centered>
        <ComboFontHeading
          snippets={[
            [
              <>
                Top five challenges
                <br />
              </>,
            ],
            [<>analysts face</>, "serif"],
          ]}
          as="h3"
        />
        <p>
          In order to understand analysts’ pain points so that team leaders can
          work towards better solutions, we asked respondents to choose their
          primary <NoWrap>day-to-day</NoWrap> challenges.
        </p>
        <Spacing size=".1em" />

        <LeaderboardChart columns={2}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.orange}
            textInCircle="13.9%"
          >
            <h4>#1: Being understaffed</h4>
            <p>
              The majority said their biggest challenge was being understaffed.
              We’ve seen this appear multiple times now in this report from our
              respondents: Too much work and not enough people to do it.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.purple}
            textInCircle="10.5%"
          >
            <h4>#2: Time spent on manual tasks</h4>
            <p>
              Their second biggest day-to-day challenge is time spent on manual
              tasks. Which, as we saw above, include tasks like reporting,
              monitoring, and detection. Having this be a challenge specifically
              means that manual tasks are eating away at their day at the
              expense of more impactful activities.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.green}
            textInCircle="8.6%"
          >
            <h4>#3: Poor visibility into the environment</h4>
            <p>
              Another challenge is poor visibility into the environment or a
              lack of tools that allow them to see threats across networks and
              devices which could put them on more proactive footing.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.pink}
            textInCircle="8.1%"
          >
            <h4>#4: Poor processes</h4>
            <p>
              They also stated poor processes, which, as one of their day-to-day
              challenges, which can cause frustration, lack of engagement, and
              burnout as well.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.blue}
            textInCircle="7.3%"
          >
            <h4>#5: Too many alerts</h4>
            <p>
              Finally, too many alerts is their fifth challenge — too many
              alerts in general, and too many false positives, which can cause
              alert fatigue and time taken from true threats and lead to
              everyone’s worst nightmare: false negatives.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>

        <Spacing size=".5em" />

        <ReportChartContainer centered>
          <h4>What is your biggest challenge day to day?</h4>
          <BarChart
            header={["Challenge", "Respondents"]}
            sortByValue
            data={[
              ["Understaffed", 65],
              ["Time spent on manual tasks", 49],
              ["Poor visibility into our environment", 40],
              ["Poor processes", 38],
              ["Teams are very siloed", 22],
              ["Too many alerts", 34],
              ["Tools don't integrate", 17],
              ["High staff turnover rate", 15],
              ["Lack of training", 23],
              ["Too much data, not enough information", 14],
              ["Boredom", 27],
              ["Leadership issues", 26],
              ["Too many reporting requirements", 21],
              ["Restrictive SLAs", 9],
              ["Compliance", 23],
              ["Clunky, outdated, or misconfigured tools", 23],
              ["Too many logs", 13],
              ["System downtime", 9],
            ]}
            total={468}
            height={700}
            colors={[colorsV4.orange]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>

      <ReportSecondarySection spacing="1em" centered>
        <ComboFontHeading
          snippets={[
            "Lack of ",
            ["people, skills, and budget", "serif"],
            "are inhibiting SOC teams",
          ]}
          as="h3"
          className={css`
            line-height: 1.2;
            max-width: 15em !important;
          `}
        />
        <Spacing size="1px" />
        <p>
          Overall, what prevents the SOC team from doing their best work? Our
          respondents (selecting all that applied) said a lack of people
          (45.5%), lack of skills (44.2%), and lack of budget (44.2%) are all
          factors holding them back from doing their best work.
        </p>
        <p>
          They also cited a lack of effective tools (38.9%), a lack of buy-in
          from management (34.4%), and interpersonal challenges between team
          members (33.1%).
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>As a team, what prevents you from doing your best work?</h4>
          <BarChart
            header={["Answer", "Respondents"]}
            data={[
              ["Lack of people", 213],
              ["Lack of skills", 207],
              ["Lack of budget", 207],
              ["Lack of effective tools", 182],
              [
                "Lack of buy-in from management or the rest of the organization",
                161,
              ],
              ["Interpersonal challenges between team members", 155],
              ["None of the above", 63],
            ]}
            sortByValue
            total={468}
            height={330}
            colors={[colorsV4.orange]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>

      <ReportSecondarySection centered spacing="1.5em">
        <ComboFontHeading
          snippets={[
            [
              <>
                Top five things
                <br />
              </>,
            ],
            [<>that frustrate analysts the most</>, "serif"],
          ]}
          as="h3"
        />
        <p>
          We’re seeing that frustration from having to do the same actions over
          and over again leads to questions about why the process is not more
          streamlined and prevents analysts from doing the best work.
        </p>
        <LeaderboardChart columns={2}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.orange}
            textInCircle="50.6%"
          >
            <h4>#1: Spending time on manual work</h4>
            <p>
              For our respondents, the most frustrating aspect of their day is
              spending time on manual work, like reporting, monitoring, and
              detection, as we saw above.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.purple}
            textInCircle="36.8%"
          >
            <h4>#2: High false positive rates</h4>
            <p>
              The second most frustrating aspect is high false positive rates,
              which take time to investigate, and can divert energy from
              true positives.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.green}
            textInCircle="34.4%"
          >
            <h4>
              #3: Too many different consoles and tools to investigate incidents
            </h4>
            <p>
              Another frustrating aspect of their job is having too many
              different consoles and tools to investigate incidents, which could
              lead to gaps in response or inefficient processes.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.pink}
            textInCircle="33.8%"
          >
            <h4>#4: Inaccurate or incomplete attribution</h4>
            <p>
              They also stated that inaccurate or incomplete attribution is
              another frustration they face, forcing them to take time and
              energy to seek out more context for alerts.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.blue}
            textInCircle="29.7%"
          >
            <h4>#5: Slow or delayed log file ingestion and processing</h4>
            <p>
              Finally, they’re frustrated with slow or delayed log file
              ingestion and processing creating lags on real time response.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>

        <Spacing size=".5em" />

        <ReportChartContainer centered>
          <h4>What are the most frustrating aspects of your work?</h4>
          <BarChart
            header={["Answer", "Respondents"]}
            sortByValue
            data={[
              ["Spending time on manual work", 237],
              ["Inaccurate or incomplete attribution", 158],
              ["High false positive rates", 172],
              [
                "Too many different consoles/tools to investigate incidents",
                161,
              ],
              ["Slow or delayed log file ingestion and processing", 139],
              ["Lack of space for logs", 129],
              ["Poor integration of different security tools", 127],
              [
                "Lack of board support for different log types and systems",
                116,
              ],
              [
                "Lack of unified query language to access all data across all monitored systems",
                112,
              ],
              ["High cost of security and log management software", 111],
              ["Our SIEM", 91],
              ["Toxic work environment/personnel issues", 79],
            ]}
            total={468}
            height={700}
            colors={[colorsV4.orange]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>
      <ReportSummaryCard>
        <h3>Summary</h3>
        <p>
          Security analysts want to do their best work, but they’re stymied by a
          number of factors, including understaffed teams, too much time spent
          on manual tasks, a lack of tools that allow them full visibility into
          their environment, poor processes, and simply too many alerts.
        </p>
        <p>
          It’s a matter of needing better tools and better processes — not a
          work-harder approach checking off more manual tasks, but a
          work-smarter approach that automates and streamlines tasks,
          essentially restructuring security analysts’ time commitments. This
          refocuses them on the proactive, higher-value efforts we mentioned
          before and is what ultimately keeps the organization safe.
        </p>
        <p>
          As an added bonus, implementing better tools and processes will also
          address all of the areas of frustration that currently dog analysts
          and that can contribute to higher retention rates as well.
        </p>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default SOCChapter3;
