import { styled } from "@linaria/react";
import { useEffect, useRef, useState } from "react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { getActionDOMElInDiagram } from "./utils/actions.utils";
import { gridUnit } from "./utils/storyboard.utils";
import { useStoryboardContext } from "./StoryboardContext";
import { ActionLink } from "../../types/tines.types";
import drawLinkSvgPath from "./utils/drawLinkSvgPath.utils";
import ActionLinkGradientDef from "./StoryboardGradientDef";
import { clearAnimatedProps } from "../../utils/animations.utils";
import gsap from "gsap";

type Props = {
  link: ActionLink;
};

const LinkPath = styled.path`
  fill: none;
`;

const StoryboardActionLink = (props: Props) => {
  const context = useStoryboardContext();
  const { boundingBox } = context.storyboard;
  const { source, receiver } = props.link;
  const [sourceActionHeight, setSourceActionHeight] = useState(gridUnit(3));
  const gradientId = `s${context.id}g${source.index}-${receiver.index}`;
  const disabled = source.disabled || receiver.disabled;
  const shouldBeHidden =
    context.options.actionShouldBeHidden(source) ||
    context.options.actionShouldBeHidden(receiver);
  const [wasHidden] = useState(shouldBeHidden);
  useEffect(() => {
    setSourceActionHeight(
      getActionDOMElInDiagram(context, source)?.clientHeight ?? gridUnit(3)
    );
  }, [context, source, source.name, source.guid]);
  const linkRef = useRef<SVGPathElement>(null);
  useEffect(() => {
    if (!shouldBeHidden && wasHidden && context.ready) {
      const lineLength = linkRef.current?.getTotalLength() ?? 100;
      gsap.set(linkRef.current, {
        strokeDashoffset: lineLength,
        strokeDasharray: lineLength,
      });
      gsap.to(linkRef.current, {
        strokeDashoffset: 0,
        duration: lineLength / 200 + 0.2,
        ease: "sine.inOut",
        onComplete: () => {
          clearAnimatedProps(linkRef.current, [
            "stroke-dasharray",
            "stroke-dashoffset",
          ]);
        },
      });
    }
  }, [context.ready, shouldBeHidden, wasHidden]);
  return shouldBeHidden ? null : (
    <g className="StoryboardActionLink" data-link-index={props.link.index}>
      <ActionLinkGradientDef id={gradientId} link={props.link} />
      <LinkPath
        ref={linkRef}
        data-disabled={disabled}
        d={drawLinkSvgPath(
          {
            x: source.position.x - boundingBox[0].x + gridUnit(1.5),
            y: source.position.y - boundingBox[0].y + sourceActionHeight,
          },
          {
            x: receiver.position.x - boundingBox[0].x + gridUnit(1.5),
            y: receiver.position.y - boundingBox[0].y,
          }
        )}
        stroke={disabled ? colorsV4.grey : `url('#${gradientId}')`}
        strokeWidth={2}
      />
    </g>
  );
};

export default StoryboardActionLink;
