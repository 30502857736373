import { styled } from "@linaria/react";
import { css } from "linaria";
import {
  fromDesktopMd,
  fromTablet,
  onlyPhones,
} from "../../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { font } from "../../../../styles/fonts.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import { useOnMount } from "../../../../utils/lifeCycle.utils";
import NoWrap from "../../../typography/Nowrap";
import ReportCard from "../../ReportCard";
import PercentageBar from "../../charts/PercentageBar";

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    margin-top: 1em;
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const SOCChartDemographics = () => {
  useOnMount(() => {});
  return (
    <ReportCard
      className={css`
        ${onlyPhones} {
          padding-top: 2em;
          text-align: center;
        }
      `}
    >
      <p>
        <strong
          className={css`
            display: block;
            font-size: 48px;
            font-family: ${font("serif")};
            font-weight: 400;
            line-height: 1;
            ${fromTablet} {
              font-size: 64px;
            }
          `}
        >
          468
        </strong>
        <strong
          className={css`
            font-size: 18px;
            ${onlyPhones} {
              margin-bottom: 2em;
            }
          `}
        >
          full-time security analysts
        </strong>
      </p>
      <div
        className={css`
          h4 {
            margin-bottom: 0.25em;
            font-size: 1.4rem;
            color: inherit;
            text-align: left;
            + * {
              margin-top: 0.25em;
            }
          }
          > * + h4 {
            margin-top: 0.5em;
          }
        `}
      >
        <h4>Gender</h4>
        <PercentageBar
          data={[
            ["Male", 262, colorsV4.purple],
            ["Female", 206, colorsV4.orange],
          ]}
          labelMinHeight="2em"
        />
        <h4>Age</h4>
        <PercentageBar
          data={[
            [<NoWrap key="label">18–24</NoWrap>, 58, colorsV4.orange],
            [<NoWrap key="label">25–34</NoWrap>, 93, colorsV4.green],
            [<NoWrap key="label">35–44</NoWrap>, 272, colorsV4.purple],
            [<NoWrap key="label">45–54</NoWrap>, 26, colorsV4.yellow],
            [<NoWrap key="label">&gt; 54</NoWrap>, 19, colorsV4.pink],
          ]}
          textAlign="right"
          labelMinHeight="2em"
        />
        <Grid>
          <div>
            <h4>Country</h4>
            <PercentageBar
              data={[["🇺🇸 United States", 168]]}
              labelMinHeight="2em"
            />
          </div>
          <div>
            <h4>Employment Status</h4>
            <PercentageBar
              data={[["Employed for wages", 168]]}
              labelMinHeight="2em"
            />
          </div>
        </Grid>
        <h4>Number of employees in company</h4>
        <PercentageBar
          data={[
            ["501-1K", 248],
            ["1k-5k", 141],
            ["> 5k", 79],
          ]}
          labelMinHeight="2em"
        />
      </div>
    </ReportCard>
  );
};

export default SOCChartDemographics;
