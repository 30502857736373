import { styled } from "@linaria/react";
import { DatoCmsPerson } from "../../../graphql-types";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { formatAsFullName } from "../../utils/string.utils";
import PersonPhotoIcon, { PersonPhotoIconPlaceholder } from "./PersonPhotoIcon";
import { Tooltip, TooltipWrap } from "../basic/Tooltip";

type Props = {
  person: DatoCmsPerson;
  size?: number;
};

const PersonIconWithToolTip = ({ person, size }: Props) => {
  const name = formatAsFullName(person.name, person.surname);
  return (
    <PersonIconWithToolTipContainer>
      {person.linkedin ? (
        <>
          <a href={person.linkedin} {...externalLinkAttr}>
            <PersonPhotoIcon person={person} size={size ?? 32} />
          </a>
          <Tooltip>
            <a href={person.linkedin} {...externalLinkAttr}>
              {name}
            </a>
          </Tooltip>
        </>
      ) : (
        <>
          <PersonPhotoIcon person={person} size={size ?? 32} />
          <Tooltip>
            <span>{name}</span>
          </Tooltip>
        </>
      )}
    </PersonIconWithToolTipContainer>
  );
};

export const PersonIconWithToolTipContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-bottom: 0.35em;
  &:hover {
    ${TooltipWrap} {
      display: block;
    }
  }
  ${PersonPhotoIconPlaceholder} {
    font-size: 1.4rem;
  }
`;

export default PersonIconWithToolTip;
