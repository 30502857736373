import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import Spacing from "../../layout/Spacing";
import { Separator } from "../../utilities/Hr";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2SectionSubheading } from "../components/ReportV2SectionSubheading";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { reportV2BodyTextParagraphStyle } from "../components/ReportV2TextBlock";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";
import { ScannerIcon } from "./icons/ScannerIcon";

const Header = styled.header`
  ${onlyPhones} {
    display: grid;
    grid-template-areas: "icon" "text";
    grid-gap: 2em;
  }
  ${fromTablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const HeaderInner = styled.div`
  ${onlyPhones} {
    grid-area: text;
  }
  ${fromTablet} {
    padding-right: 2em;
  }
`;

const IconWrap = styled.div`
  ${onlyPhones} {
    grid-area: icon;
  }
  svg {
    flex: 0 0 auto;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-gap: 3em ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: 1fr 1fr;
  }
  > * {
    ${fromDesktopMd} {
      padding-right: 4.2rem;
    }
  }
  b {
    display: block;
    font-family: ${serif};
    font-weight: 400;
    margin-bottom: 1.4rem;
    font-size: 2.2rem;
  }
  h3 {
    font-size: 2.2rem;
    span {
      ${fromDesktopMd} {
        display: block;
      }
    }
    * + & {
      margin-top: 1.4rem;
    }
    + * {
      margin-top: 1.4rem;
    }
  }
  p {
    ${reportV2BodyTextParagraphStyle}
  }
`;

export const SOC2023KeyFindingsSection = () => (
  <ReportV2Section
    id="key-findings"
    backgroundColor={colors.purple}
    color={colors.white}
    backdropDotGridColor={colors.purple600}
  >
    <Header>
      <HeaderInner>
        <ReportV2SectionHeadingMedium>
          Key findings
        </ReportV2SectionHeadingMedium>
        <ReportV2SectionSubheading>
          Here are a few of the insights we learned from the security
          professionals we surveyed:
        </ReportV2SectionSubheading>
      </HeaderInner>
      <IconWrap>
        <ScannerIcon />
      </IconWrap>
    </Header>

    <Spacing size="xl" />
    <Separator />
    <Spacing size="xl" />

    <ContentGrid>
      <div>
        <b>#1</b>
        <h3>63% of practitioners experience some level of burnout.</h3>
        <p>
          With more than 80% saying their workloads have increased in the past
          year, the problem is only getting worse.
        </p>
      </div>
      <div>
        <b>#2</b>
        <h3>55% say they’re likely to switch jobs in the next year.</h3>
        <p>
          Organizations could increase retention by increasing salaries,
          supplying modern tools with advanced capabilities, hiring more staff,
          and investing in solutions that automate tedious, manual tasks.
        </p>
      </div>
      <div>
        <b>#3</b>
        <h3>
          Spending time on manual work is the most frustrating aspect of the
          job.
        </h3>
        <p>
          If respondents had to spend less time on manual tasks, they would most
          likely use that time to research and evaluate new tools, develop more
          advanced detection rules, and integrate more systems and logs.
        </p>
      </div>
      <div>
        <b>#4</b>
        <h3>
          There’s hope in <span>automation.</span>
        </h3>
        <p>
          Nine out of ten security teams are automating at least some of their
          work, and 93% of respondents believe that more automation would
          improve their work-life balance. Respondents expect automation to help
          their teams increase productivity, save time, and optimize performance
          and reliability.
        </p>
      </div>
      <div>
        <b>#5</b>
        <h3>
          Security practitioners are <span>learning to code.</span>
        </h3>
        <p>
          Security teams now consider learning to code — along with computer
          forensics and malware analysis techniques — most important to succeed,
          likely because of coding’s key role in automation. No-code security
          solutions could provide similar benefits as organizations automate
          repetitive tasks.
        </p>
      </div>
    </ContentGrid>
  </ReportV2Section>
);
