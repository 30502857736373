import { styled } from "@linaria/react";
import { DatoCmsLibraryCollection } from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import CollectionEntry from "./CollectionEntry";
import {
  fromDesktopMd,
  fromTabletLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { LibraryCollectionType } from "../../utils/libraryCollectionType.enum";

type Props = {
  collections: DatoCmsLibraryCollection[];
};

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-auto-rows: 1fr;
  &[data-columns="2"] {
    ${fromTabletLg} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &[data-columns="4"] {
    grid-template-columns: repeat(2, 1fr);
    ${fromTabletMd} {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &[data-columns="5"] {
    grid-template-columns: repeat(2, 1fr);
    ${fromTabletMd} {
      grid-template-columns: repeat(3, 1fr);
    }
    ${fromDesktopMd} {
      grid-template-columns: repeat(5, 1fr);
    }
  }
`;

const CollectionsGrid = (props: Props) => {
  const containsTeams = props.collections?.some(
    c => c?.collectionType === LibraryCollectionType.team
  );
  const columns = containsTeams
    ? props.collections.length % 5 === 0
      ? 5
      : 4
    : 2;
  return (
    <Grid data-columns={columns}>
      {props.collections?.map(c => (
        <CollectionEntry collection={c} key={c.id} />
      ))}
    </Grid>
  );
};

export default CollectionsGrid;
