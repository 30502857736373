import { css } from "@linaria/core";
import PageSection from "../components/reusableSections/PageSection";
import StandardPageHeader from "../components/reusableSections/StandardPageHeader";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import StandardArticle from "../components/articles/StandardArticle";
import { externalLinkAttr } from "../constants/externalLinks.constants";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { sortBy } from "lodash-es";
import InfoTooltip from "../components/general/InfoTooltip";

type SubProcessor = {
  name: string;
  nature: string;
  location: string;
  securityMeasures: string;
  context?: string;
};

const subProcessors: SubProcessor[] = [
  {
    name: "Amazon Web Services, Inc.",
    nature: "Cloud infrastructure and data",
    location: "Ireland",
    securityMeasures:
      "https://docs.aws.amazon.com/whitepapers/latest/aws-overview/security-and-compliance.html",
  },
  {
    name: "Google LLC",
    nature: "Cloud infrastructure and data",
    location: "USA",
    securityMeasures: "https://pki.goog",
  },
  {
    name: "Intercom, Inc.",
    nature: "User database management",
    location: "USA",
    securityMeasures: "https://www.intercom.com/security",
  },
  {
    name: "Zendesk, Inc.",
    nature: "Customer support",
    location: "USA",
    securityMeasures: "https://www.zendesk.com/trust-center",
  },
  {
    name: "Panther Labs, Inc.",
    nature: "Security information and event management",
    location: "Ireland",
    securityMeasures: "https://panther.com/company/trust",
  },
  {
    name: "Snowflake, Inc.",
    nature: "Data warehouse services",
    location: "Ireland",
    securityMeasures:
      "https://www.snowflake.com/product/security-and-trust-center/",
  },
  {
    name: "Hound Technology, Inc. d/b/a Honeycomb",
    nature: "Monitoring",
    location: "USA",
    securityMeasures: "https://www.honeycomb.io/security",
  },
  {
    name: "Fivetran, Inc.",
    nature: "Data warehouse services",
    location: "EU",
    securityMeasures: "https://fivetran.com/docs/security",
  },
  {
    name: "Cloudflare, Inc.",
    nature: "Network tunnelling",
    location: "Ireland & USA",
    securityMeasures: "https://www.cloudflare.com/trust-hub/",
    context: "For tunnel customers only",
  },
  {
    name: "Functional Software, Inc. d/b/a Sentry",
    nature: "Monitoring",
    location: "USA",
    securityMeasures: "https://sentry.io/trust",
  },
];

const PageSubProcessors: PageComponent = props => (
  <Page {...props}>
    <SEO title="Tines sub-processors" />
    <StandardPageHeader pageTitle="Tines sub-processors" largerPadding />
    <Spacing size="sectionPadding" />
    <PageSection>
      <StandardArticle
        fullWidth
        className={css`
          font-size: 1.6rem;
          line-height: 1.25;
        `}
      >
        <table>
          <thead>
            <tr>
              <th>Sub-processor</th>
              <th>Nature and purposes of processing & transfer</th>
              <th>Categories of personal data & frequency of processing</th>
              <th>Location of processing (and, where applicable, transfer)</th>
              <th>Frequency and duration of Processing</th>
              <th>Security measures</th>
            </tr>
          </thead>
          <tbody>
            {sortBy(subProcessors, "name").map(subProcessor => {
              return (
                <tr key={subProcessor.name}>
                  <th>
                    {subProcessor.name}

                    {subProcessor.context && (
                      <InfoTooltip>{subProcessor.context}</InfoTooltip>
                    )}
                  </th>
                  <td>{subProcessor.nature}</td>
                  <td>As set out in customer DPA</td>
                  <td>{subProcessor.location}</td>
                  <td>Continuous and ongoing</td>
                  <td style={{ maxWidth: 235 }}>
                    <a
                      href={subProcessor.securityMeasures}
                      {...externalLinkAttr}
                    >
                      {subProcessor.securityMeasures}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </StandardArticle>
    </PageSection>
    <Spacing size="sectionPadding" />
  </Page>
);

export default PageSubProcessors;
