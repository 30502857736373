import { VideoFrameWithCustomPlayButton } from "./VideoFrameWithCustomPlayButton";
import productDemoVideoPreview from "../../../static/demoVideos/tines-product-demo-preview-1652w.mp4";
import productDemoVideo from "../../../static/demoVideos/tines-product-demo-1652w.mp4";
import mainDemoVideoPreview from "../../../static/demoVideos/tines-main-demo-with-eoin-hinchy-preview.mp4";
import mainDemoVideo from "../../../static/demoVideos/tines-main-demo-with-eoin-hinchy.mp4";

const videos = {
  homePage: {
    videoSrc: mainDemoVideo,
    subtitleSrc: "",
    previewVideoLoopSrc: mainDemoVideoPreview,
    width: 1920,
    height: 1080,
  },
  productPage: {
    videoSrc: productDemoVideo,
    subtitleSrc: "",
    previewVideoLoopSrc: productDemoVideoPreview,
    width: 1652,
    height: 1020,
  },
} as const;

export const ProductDemoVideoFrame = (props: {
  version: "homePage" | "productPage";
}) => {
  return <VideoFrameWithCustomPlayButton {...videos[props.version]} />;
};
