import { mix } from "polished";
import { colorsV4 } from "../../styles/colorsV4.styles";

export const blueprintColors = {
  canvas: {
    bg: colorsV4.canvas,
    fg: mix(0.65, colorsV4.grey, colorsV4.canvas),
  },
  orange: {
    bg: colorsV4.orange,
    fg: colorsV4.orange700,
  },
};
