import SEO from "../components/utilities/SEO";

import Page from "./Page.template";
import { PageComponentProps } from "../types/gatsby.types";
import YDWWTHero from "../components/ydwwt/YDWWTHero";
import { colors } from "../styles/colors.styles";
import YDWWTCategoriesSection from "../components/ydwwt/YDWWTCategoriesSection";
import YDWWTFaqSection from "../components/ydwwt/YDWWTFaqSection";
import YDWWTPrizesSection from "../components/ydwwt/YDWWTPrizesSection";
import YDWWTEnterCompetitionSection from "../components/ydwwt/YDWWTEnterCompetitionSection";
import { graphql } from "gatsby";
import { DatoCmsYdwwt, YdwwtBySlugQuery } from "../../graphql-types";
import defaultOgImage from "../../static/images/og/you-did-what-with-tines.png";
import { YDWWT_SUBMISSION_MODAL_NAME } from "../components/modals/modalNames";
import YDWWTSubmissionModal from "../components/ydwwt/YDWWTSubmissionModal";
import { useSiteContext } from "../context/site.context";
import { YDWWTInspirationsSection } from "../components/ydwwt/YDWWTInspirationsSection";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import SpaceChildren from "../components/layout/SpaceChildren";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import YDWWTGrandPrizeSection from "../components/ydwwt/YDWWTGrandPrizeSection";
import YDWWTCategoryWinnersSection from "../components/ydwwt/YDWWTCategoryWinnersSection";
import YDWWTCommunityEditionCTASection from "../components/ydwwt/YDWWTCommunityEditionCTASection";
const defaultDescription =
  "Use Tines to automate something. Fun or Serious. Simple or Complex. Surprise us. Surprise yourself! You can enter as often as you like and the best thing is: everybody gets a prize!";

const YDWWTLandingPage = (
  props: PageComponentProps<{
    data: YdwwtBySlugQuery;
  }>
) => {
  const siteContext = useSiteContext();
  const ydwwt = props.data.ydwwt as DatoCmsYdwwt;
  const asLandingPage = !ydwwt.renderPageAsAnnouncementPage;
  const asAnnouncementPage = !!ydwwt.renderPageAsAnnouncementPage;
  return (
    <Page
      {...props}
      browserThemeColor={colors.dark900}
      backgroundColor={colors.dark900}
      textColor={colors.white}
    >
      <SEO
        title={ydwwt.seoTitle || `You did WHAT with Tines?!: ${ydwwt.name}`}
        description={ydwwt.seoDescription || defaultDescription}
        image={ydwwt.pagePreviewImage?.url || defaultOgImage}
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="ydwwt"
        recordId={ydwwt.id}
      />

      <SpaceChildren size="sectionMargin">
        <YDWWTHero ydwwt={ydwwt} />
        {asLandingPage && <YDWWTCategoriesSection ydwwt={ydwwt} />}
        {asAnnouncementPage && (
          <>
            <YDWWTGrandPrizeSection ydwwt={ydwwt} />
            <YDWWTCategoryWinnersSection ydwwt={ydwwt} />
            <YDWWTCommunityEditionCTASection />
          </>
        )}
        {asLandingPage && (
          <>
            <YDWWTPrizesSection ydwwt={ydwwt} />
            <YDWWTInspirationsSection ydwwt={ydwwt} />
            <YDWWTEnterCompetitionSection ydwwt={ydwwt} />
            <YDWWTFaqSection ydwwt={ydwwt} />
          </>
        )}

        <ExplosionCTASection againstDarkBackground />
      </SpaceChildren>

      {siteContext.modal === YDWWT_SUBMISSION_MODAL_NAME && (
        <YDWWTSubmissionModal ydwwt={ydwwt} />
      )}
    </Page>
  );
};

export default YDWWTLandingPage;

export const ydwwtBySlugQuery = graphql`
  query YdwwtBySlug($slug: String) {
    ydwwt: datoCmsYdwwt(slug: { eq: $slug }) {
      id: originalId
      slug
      name
      seoTitle
      seoDescription
      pagePreviewImage {
        url
      }
      renderPageAsAnnouncementPage
      announcementPageHeading
      announcementPageMainCopy {
        blocks
        value
      }
      categories {
        id: originalId
        name
        color
        description {
          blocks
          value
        }
        icon {
          url
        }
      }
      winners {
        name
        description
        color
        story {
          storyId
          descriptor
          internalAuthors {
            name
            surname
            linkedin
            organization {
              name
            }
          }
          communityAuthors {
            name
            surname
            linkedin
            organization {
              name
            }
          }
          videoIntroEmbedUrl
          gallery {
            url
            width
            height
          }
        }
      }
      categoryWinners {
        id: originalId
        color
        name
        description {
          blocks
          value
        }
        icon {
          url
        }
        winners {
          id: originalId
          name
          description
          color
          story {
            storyId
            descriptor
            internalAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            communityAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            videoIntroEmbedUrl
            gallery {
              url
              width
              height
            }
          }
        }
      }
      dateEnd
      dateStart
      dateWinnerAnnouncement
      examplesSectionCopy {
        blocks
        value
      }
      inspirationSectionCopy {
        blocks
        value
      }
      inspirationSectionExampleStories {
        storyId
        descriptor
        internalAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        communityAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        videoIntroEmbedUrl
        gallery {
          url
          width
          height
        }
      }
      faq {
        id: originalId
        answer {
          blocks
          value
        }
        question {
          blocks
          value
        }
      }
      heroSectionBodyCopy {
        blocks
        value
      }
      heroSectionSubheading
      prizes {
        id: originalId
        color
        prizeType
        summary {
          blocks
          value
        }
        image {
          url
        }
      }
      prizesSectionCopy {
        blocks
        value
      }
    }
  }
`;
