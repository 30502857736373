import { styled } from "@linaria/react";
import { DatoCmsSolutionPage } from "../../../graphql-types";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";
import InteractiveStoryDemo from "../decorations/interactiveStoryDemo/InteractiveStoryDemo";
import BrowserChrome from "../misc/BrowserChrome";
import { useEffect, useState } from "react";
import { useViewportSize } from "../../utils/window.utils";
import {
  fromDesktop,
  fromTablet,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import SolutionPageAllcapsHeading from "./SolutionPageAllcapsHeading";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import Spacing from "../layout/Spacing";
import PageHeroHeadlineDecorationDispatcher from "./decorations/PageHeroHeadlineDecorationDispatcher";
import PageHeroProductFrameDecorationDispatcher from "./decorations/PageHeroProductFrameDecorationDispatcher";
import PageIconDispatcher from "./decorations/PageIconDispatcher";
import { isVideoSrc } from "../../utils/fileType.utils";
import VideoAutoplayWhenVisible from "../basic/VideoAutoplayWhenVisible";
import {
  DemoStoryType,
  makeDemoStoryConfig,
} from "../decorations/interactiveStoryDemo/demoStories/_makeDemoStoryConfig";
import HeroSection, { HeroSectionDescription } from "../sections/HeroSection";
import { preventWidows } from "../../utils/typography.utils";

type Props = {
  page: DatoCmsSolutionPage;
};

const HeaderWrap = styled.div``;

export const SolutionPageHeadlineWrap = styled.div`
  position: relative;
  h1 {
    ${fromTablet} {
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const ProductFrameWrap = styled.div`
  position: relative;
  margin-top: 4em;
  ${fromDesktop} {
    margin-top: 6em;
  }
`;

const FeaturedProductImageOrVideoFrame = styled.div`
  background-color: ${colors.light100};
  border: 0.8rem solid ${colors.light200};
  border-radius: 0.75em;
  overflow: hidden;
  ${fromTabletMd} {
    border-width: 1.4rem;
    border-radius: 1.25em;
  }
  video,
  img {
    display: block;
    width: 100%;
    aspect-ratio: inherit;
  }
`;

const SolutionPageHero = ({ page }: Props) => {
  const [storyDemoKey, setStoryDemoKey] = useState("large");
  const { vw } = useViewportSize();

  useEffect(() => {
    if (vw > 768) setStoryDemoKey("large");
    else setStoryDemoKey("small");
  }, [vw]);

  const theme = getBrandColorTheme(page.color || page.libraryCollection?.color);

  const { featuredProductImageOrVideo: media } = page;

  const demoStoryConfig = makeDemoStoryConfig(page.slug as DemoStoryType);

  return (
    <HeroSection
      backdrop="grid"
      backdropColor={theme.c100}
      backdropParallax
      contentRelative
    >
      <HeaderWrap>
        <PageIconDispatcher page={page} />
        <SolutionPageAllcapsHeading as="p">
          {page.name}
          {page.name === "IT" && " Solutions"}
        </SolutionPageAllcapsHeading>
        <Spacing size="1.5em" />
        <SolutionPageHeadlineWrap>
          <WithCustomInlineFormatting as="h1">
            {page.headline}
          </WithCustomInlineFormatting>
          <Spacing size=".5em" />
          <HeroSectionDescription centered>
            {preventWidows(page.description ?? "")}
          </HeroSectionDescription>
          <PageHeroHeadlineDecorationDispatcher page={page} />
        </SolutionPageHeadlineWrap>
      </HeaderWrap>
      <ProductFrameWrap>
        {media?.url ? (
          <FeaturedProductImageOrVideoFrame
            style={{
              aspectRatio: `${media.width}/${media.height}`,
            }}
          >
            {isVideoSrc(media.url) ? (
              <VideoAutoplayWhenVisible src={media.url} />
            ) : (
              <img src={media.url} />
            )}
          </FeaturedProductImageOrVideoFrame>
        ) : (
          <BrowserChrome width={1100} borderStyle="solid" noTitleBar>
            <InteractiveStoryDemo
              key={storyDemoKey}
              story={demoStoryConfig.story}
              dropZoneActionNameMatcher={
                demoStoryConfig.dropZoneActionNameMatcher
              }
              notification={demoStoryConfig.notification}
              maxEvents={demoStoryConfig.maxEvents}
              possibleTriggerValues={demoStoryConfig.possibleTriggerValues}
              inspectable={demoStoryConfig.inspectable}
            />
          </BrowserChrome>
        )}
        <PageHeroProductFrameDecorationDispatcher page={page} />
      </ProductFrameWrap>
    </HeroSection>
  );
};

export default SolutionPageHero;
