import { Link } from "gatsby";
import { DatoCmsLibraryTool } from "../../../graphql-types";
import { styled } from "@linaria/react";
import { Tooltip, TooltipWrap } from "../basic/Tooltip";
import LibraryToolImageWithDarkMode from "./LibraryToolImageWithDarkMode";

const ToolIconWrap = styled.div`
  position: relative;
  &:hover {
    ${TooltipWrap} {
      display: block;
    }
  }
`;
export const LibraryTableToolIcon = (p: {
  tool: string | DatoCmsLibraryTool;
  allTools?: DatoCmsLibraryTool[];
  withToolTip?: boolean;
}) => {
  const tool =
    typeof p.tool === "string"
      ? p.allTools!.find(
          t =>
            t.name === `${p.tool}` ||
            (typeof p.tool === "string"
              ? t.tags
                  ?.toLowerCase()
                  .split(/\s*\n\s*/)
                  .includes(p.tool.toLowerCase())
              : false)
        )
      : p.tool;

  return tool ? (
    <Link to={`/library/tools/${tool.slug}`}>
      {tool.image?.url ? (
        <ToolIconWrap>
          <LibraryToolImageWithDarkMode tool={tool} />
          {p.withToolTip && (
            <Tooltip>
              <span>{tool.name}</span>
            </Tooltip>
          )}
        </ToolIconWrap>
      ) : (
        tool.name
      )}
    </Link>
  ) : null;
};
