import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import React, { CSSProperties, RefObject } from "react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { Action } from "../../types/tines.types";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import ActionIcon from "./ActionIcon";
import { MAX_ACTION_WIDTH, gridUnit } from "./utils/storyboard.utils";
import { useStoryboardContext } from "./StoryboardContext";

type Props = {
  action: Action;
  style?: CSSProperties;
  withLinkTerminals?: boolean;
  isSelected?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
  onClick?: () => void;
};

export const ActionEntryContainer = styled.div`
  background-color: ${colors.white};
  color: ${colors.dark500};
  ${darkModeLinariaCSS(`
    color: ${colors.white};
    background-color: ${colors.dark900};
  `)}
  max-width: ${MAX_ACTION_WIDTH}px;
  font-size: 12px;
  line-height: 15px;
  overflow-wrap: anywhere;
  border-radius: 14px;
  display: inline-flex;
  align-items: stretch;
  box-sizing: border-box;
  transform: translateZ(0);
  min-height: ${gridUnit(3)}px;
  padding: 0 4px;
  transition: opacity 0.2s;
  &:before {
    content: "";
    position: absolute;
    ${cover()};
    background-color: var(--ac10);
    border: 1px solid var(--ac20);
    border-radius: inherit;
  }
  &.inspectable {
    cursor: pointer;
    &:hover {
      &:before {
        border-color: var(--ac40);
        background-color: var(--ac20);
      }
    }
  }
  .hasSelection & {
    opacity: 0.6;
  }
  &.selected {
    transition: opacity 0.05s;
    opacity: 1;
    box-shadow: 0 0 2px 2px var(--ac40), 0 0 2px 2px var(--ac40);
    &:before {
      border-color: var(--ac40);
      background-color: var(--ac20);
    }
  }
  > * {
    position: relative;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

const IconWrapOuter = styled.div`
  flex: 0 0 auto;
  padding: 4px 0;
  width: 37px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  .IconWrapTop {
    position: absolute;
    left: 0;
    top: 0;
  }
  .IconWrapBottom {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

const IconWrapInner = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  .ActionIcon {
    position: relative;
  }
`;
const Label = styled.div`
  padding: 0.7em 0.375em 0.7em 0.425em;
  .useMarketingStyles & {
    padding: 0.7em 0.375em;
  }
`;
const TypeName = styled.div`
  color: var(--ac);
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.04em;
  white-space: nowrap;
  .useMarketingStyles & {
    letter-spacing: 0;
  }
`;
const ActionName = styled.div`
  font-weight: 500;
`;
const IncomingLinkTerminal = styled.div`
  position: absolute;
  left: 0;
  top: -1px;
  svg {
    display: block;
    fill: var(--ac);
  }
`;
const OutgoingLinkTerminal = styled.div`
  position: absolute;
  left: 0;
  bottom: -1px;
  svg {
    display: block;
    fill: var(--ac);
  }
`;

const IconBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  /* border-radius: 16px; // where the smoothed-out corner radius ends, equivalent of non-smoothed radius of 10px. (Smooth factor is iOS's 60 in Figma) */
  background-color: var(--ac);
`;

function ActionEntry({
  action,
  style,
  withLinkTerminals,
  isSelected,
  innerRef,
  onClick,
}: Props) {
  const context = useStoryboardContext();
  const color = action.disabled ? colors.gray : action.color;
  const shouldBeHidden = context?.options.actionShouldBeHidden(action) ?? false;
  const shouldShowInputTerminal =
    context &&
    action.inputs.length > 0 &&
    !action.inputs.every(a => context.options.actionShouldBeHidden(a));
  const shouldShowOutputTerminal =
    context &&
    action.outputs.length > 0 &&
    !action.outputs.every(a => context.options.actionShouldBeHidden(a));
  return (
    <ActionEntryContainer
      className={cx(
        "ActionEntry",
        context.options.inspectable && "inspectable",
        isSelected && "selected",
        shouldBeHidden && "hidden"
      )}
      data-action-index={action.index}
      data-action-guid={action.guid}
      data-disabled={action.disabled}
      ref={innerRef}
      onClick={onClick}
      style={
        {
          "--ac": color,
          "--ac10": withOpacity(color, 0.1),
          "--ac20": withOpacity(color, 0.2),
          "--ac40": withOpacity(color, 0.4),
          ...style,
        } as CSSProperties
      }
    >
      <IconWrapOuter className="ActionEntryIcon">
        {withLinkTerminals && (
          <>
            {shouldShowInputTerminal && (
              <IncomingLinkTerminal className="IncomingLinkTerminal">
                <svg width="37" height="7" viewBox="0 0 37 7" fill={color}>
                  <path d="M19.5 0.999999L19.5 2C19.5 3.65685 20.8431 5.00391 22.5 5.00391L18.5 7L14.5 5.00391C16.1569 5.00391 17.5 3.65685 17.5 2L17.5 0.999999L17.5002 0.999999L18.5002 -3.49675e-07L19.5001 0.999907L19.5 0.999999Z" />
                </svg>
              </IncomingLinkTerminal>
            )}
            {shouldShowOutputTerminal && (
              <OutgoingLinkTerminal className="OutgoingLinkTerminal">
                <svg width="37" height="7" viewBox="0 0 37 7" fill={color}>
                  <path d="M17.5 6L17.5 5C17.5 3.34315 16.1569 1.99609 14.5 1.99609L18.5 0L22.5 1.99609C20.8431 1.99609 19.5 3.34315 19.5 5L19.5 6H19.4998L18.4998 7L17.4999 6.00009L17.5 6Z" />
                </svg>
              </OutgoingLinkTerminal>
            )}
          </>
        )}
        <IconWrapInner>
          <IconBackdrop />
          <ActionIcon type={action.type} />
        </IconWrapInner>
      </IconWrapOuter>
      <Label>
        <TypeName>{action.typeName}</TypeName>
        <ActionName>{action.name}</ActionName>
      </Label>
      <EventBubble />
    </ActionEntryContainer>
  );
}

const EventBubbleDot = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(30%, -30%, 0);
  background-color: var(--ac);
  border-radius: 1em;
  color: ${colors.white};
  padding: 1px 4px;
  font-weight: 600;
  font-size: 10px;
  transition: 0.2s;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  line-height: 1;
  min-height: 16px;
  &:empty {
    transform: scale(0);
    opacity: 0;
  }
`;

// eslint-disable-next-line import/no-named-as-default-member
const EventBubble = React.memo(function EventBubble() {
  return <EventBubbleDot className="EventBubble" />;
});

export default ActionEntry;
