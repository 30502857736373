import { colors } from "../../styles/colors.styles";
import { Link } from "gatsby";
import { css } from "linaria";
import { SearchPlainIcon } from "../icons/plain/SearchPlainIcon";

const StyledButton = css`
  position: relative;
  appearance: none;
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5em;
  align-items: center;
  border-radius: 5em;
  border: 1px solid ${colors.purple200};
  color: ${colors.purple800};
  background-color: ${colors.lightest};
  padding: 0.5em 1em 0.5em 0.75em;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    border: 1px solid ${colors.purple300};
    background-color: ${colors.purple50};
  }
  &:active {
    background-color: ${colors.purple100};
  }
  > * {
    display: block;
  }
  svg {
    width: 18px;
    height: 18px;
  }
  span {
    position: relative;
    top: 0.04em;
  }
`;

const ProductExplorerPseudoSearchButton = () => {
  return (
    <div>
      <Link className={StyledButton} to="/solutions/products">
        <SearchPlainIcon />
        <span>Solutions by product</span>
      </Link>
    </div>
  );
};

export default ProductExplorerPseudoSearchButton;
