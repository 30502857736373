import { styled } from "@linaria/react";
import { css, cx } from "linaria";
import {
  DatoCmsArticle,
  DatoCmsExternalResource,
  DatoCmsPartner,
  DatoCmsWebinar,
} from "../../../graphql-types";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import ArticleEntryLink from "../articles/ArticleEntryLink";
import ExternalResourceCard from "../general/ExternalResourceCard";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { AlignCenter } from "../typography/Align";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import WebinarEntryLink from "../webinars/WebinarEntryLink";

type Props = {
  partner: DatoCmsPartner;
  articles: DatoCmsArticle[];
  webinars: DatoCmsWebinar[];
  externalResources: DatoCmsExternalResource[];
};

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("lg")} ${rSize("gap")};
`;

const PartnerPageResourcesSection = ({
  articles,
  webinars,
  externalResources,
}: Props) => {
  const allRecords = [...articles, ...webinars, ...externalResources];
  const onlyOneRecord = allRecords.length === 1;
  const multiplesOfThree = allRecords.length % 3 === 0;
  const maxColumns = onlyOneRecord ? 1 : multiplesOfThree ? 3 : 2;
  return (
    <PageSection>
      <AlignCenter>
        <SectionHeading2>
          Useful <Serif>resources</Serif>
        </SectionHeading2>
      </AlignCenter>
      <Spacing size="xl" />
      <Grid
        className={cx(
          css`
            ${fromTabletMd} {
              &.col-1 {
                grid-template-columns: repeat(1, minmax(0, 1fr));
              }
              &.col-2 {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                h3,
                p {
                  padding-right: 2.5em;
                }
              }
              &.col-3 {
                grid-template-columns: repeat(3, minmax(0, 1fr));
              }
            }
          `,
          `col-${maxColumns}`
        )}
      >
        {articles.map(article => (
          <ArticleEntryLink
            key={article.slug}
            record={article}
            featured={onlyOneRecord}
          />
        ))}
        {webinars.map(webinar => (
          <WebinarEntryLink
            key={webinar.slug}
            webinar={webinar}
            featured={onlyOneRecord}
          />
        ))}
        {externalResources.map(extRes => (
          <ExternalResourceCard
            record={extRes}
            key={extRes.id}
            featured={onlyOneRecord}
          />
        ))}
      </Grid>
    </PageSection>
  );
};

export default PartnerPageResourcesSection;
