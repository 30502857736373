import { styled } from "@linaria/react";
import { Link, navigate } from "gatsby";
import { css, cx } from "linaria";
import React, { CSSProperties, ReactNode, useRef, useState } from "react";
import {
  fromDesktop,
  fromTabletMd,
  uptoDesktop,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { StoryDescriptor } from "../../types/helper.types";
import StoryIconArray from "./StoryIconArray";
import { isTinesComBuildOnMain } from "../../environment";
import RigidDotGrid, { RigidDotGridLayer } from "../decorations/RigidDotGrid";
import { PillTagStrong } from "../basic/PillLegacy";
import StoryCommunityAuthorsDisplay from "./StoryCommunityAuthorsDisplay";
import { useSiteContext } from "../../context/site.context";
import { Paths } from "../../utils/pathBuilders.utils";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";
import { isCommunityStory } from "../storyboard/utils/story.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { resolveAfter } from "../../utils/promises.utils";

type Props = {
  story: StoryDescriptor;
  withDescription?: boolean;
  noDotGrid?: boolean;
  hideTags?: boolean;
  hideTools?: boolean;
  preHeading?: string;
  size?: "small" | "medium" | "large";
  square?: boolean;
  withArrow?: boolean;
  description?: ReactNode;
  alwaysOpenLink?: boolean;
  overrides?: Partial<StoryDescriptor>;
  doNotClampDescription?: boolean;
  doNotApplyCommunityStyling?: boolean;
};

export const StoryEntryInner = styled.div`
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-template-areas: "figure" "content";
  text-align: left;
  flex: 1 1 100%;
  max-width: 35em;
  .square & {
    aspect-ratio: 1/1;
  }
  h3 {
    font-size: 2rem;
    margin-bottom: 0.5em;
    font-weight: 600;
    text-wrap: balance; // supported in Chrome but not in safari
    ${fromDesktop} {
      font-size: 2.2rem;
    }
  }
  &.smallerTitle {
    h3 {
      ${fromDesktop} {
        font-size: 1.8rem;
      }
    }
  }
  p {
    font-size: 1.4rem;
  }
  .medium & {
    min-height: 20em;
  }
  .large & {
    max-width: 46em;
    h3 {
      font-size: 2.2rem;
      ${fromTabletMd} {
        font-size: 2.8rem;
      }
      ${fromDesktop} {
        font-size: 3.2rem;
      }
    }
  }
`;

const Content = styled.div`
  grid-area: content;
  padding: 0.75em 1.5em 1.5em 1.5em;
  > * {
    .medium & {
      max-width: 42rem;
    }
    + * {
      margin-top: 0.6rem;
    }
  }
  .withArrow & {
    padding-right: 3em;
    ${uptoTabletMd} {
      h3 {
        margin-right: -1em;
      }
    }
    ${fromTabletMd} {
      padding-right: 4.5em;
    }
    svg.arrow {
      position: absolute;
      right: 1.25em;
      bottom: 1.5em;
      opacity: 0.5;
      transition: 0.1s;
    }
  }
`;

const PreHeading = styled.div`
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.1em;
  font-weight: 600;
  margin-bottom: 1em;
`;

const IconArrayWrap = styled.div`
  grid-area: figure;
  margin: 0;
  position: relative;
  padding: 1.5em 1.5em 0.75em 1.5em;
  > * {
    justify-content: flex-start;
  }
`;

export const StoryEntryWrapper = css`
  display: flex;
  align-items: stretch;
  position: relative;
  border-radius: ${rSize("radius")};
  cursor: pointer;
  overflow: hidden;
  background-color: var(--BackgroundLightMode);
  color: var(--TextLightMode);
  text-decoration: none;
  border: 1px solid transparent;
  transition: 0s;
  ${RigidDotGridLayer} {
    opacity: 0.3;
  }
  [data-color-scheme="dark"] & {
    background-color: var(--BackgroundDarkMode);
    color: var(--TextDarkMode);
    ${RigidDotGridLayer} {
      opacity: 0.15;
    }
  }
  &.community {
    border: 1px solid var(--BorderColor);
  }
  &:hover {
    border-color: var(--BorderColorHover);
  }
  @media (prefers-color-scheme: dark) {
    [data-color-scheme="auto"] & {
      background-color: var(--BackgroundDarkMode);
      color: var(--TextDarkMode);
      ${RigidDotGridLayer} {
        opacity: 0.15;
      }
    }
  }
  ${uptoDesktop} {
    text-align: center;
  }
  a {
    text-decoration: none;
    > * {
      pointer-events: none;
    }
  }
  &.draft {
    border: 1px dashed var(--ac);
  }
  ${PillTagStrong} {
    color: inherit;
  }
  &:hover {
    svg.arrow {
      transform: translateX(0.25em);
    }
  }
`;

export const StoryEntryDescription = styled.div`
  &.clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  font-size: 1.4rem;
  p {
    font-size: inherit;
    + p {
      margin-top: 0.25em;
    }
  }
`;

const DevTag = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 1.2rem;
  color: ${colors.orange};
  margin: 0 0.5em 0.5em 0;
  background-color: ${colors.orange};
  border-radius: 1em;
  color: ${colors.white};
  padding: 0.2em 0.75em;
`;

const AuthorLine = styled.p`
  font-size: 1.4rem;
`;

const StoryEntry = (p: Props) => {
  const story = { ...p.story, ...p.overrides };
  const link = Paths.library.story(story.id, story.slug);
  const siteContext = useSiteContext();
  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(link, "_blank");
      return;
    }
    if (!p.alwaysOpenLink) {
      e.preventDefault();
      siteContext.library.viewStoryInLightbox(story);
      return;
    }
    if ((e.target as HTMLElement).tagName === "A") return;
    else navigate(link);
  };
  const theme = getBrandColorTheme(story.color);
  const isCommunity = isCommunityStory(story);
  const applyCommunityStyling = isCommunity && !p.doNotApplyCommunityStyling;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [
    shouldUseSmallerFontSizeForTitle,
    setShouldUseSmallerFontSizeForTitle,
  ] = useState(false);
  useOnMount(() => {
    const checkSize = async () => {
      setShouldUseSmallerFontSizeForTitle(false);
      await resolveAfter();
      setShouldUseSmallerFontSizeForTitle(
        !!(
          wrapperRef.current &&
          wrapperRef.current.clientHeight < wrapperRef.current.scrollHeight
        )
      );
    };
    const handleResize = () => {
      checkSize();
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <Link
      to={link}
      onClick={handleClick}
      className={cx(
        "StoryEntry",
        StoryEntryWrapper,
        !story.isPublished && "draft",
        p.size ?? "small",
        p.withArrow && "withArrow",
        p.square && "square",
        applyCommunityStyling && "community"
      )}
      color={story.color}
      style={
        {
          "--ac": theme.c500,
          "--ac50": theme.c50,
          "--ac600": theme.c600,
          "--ac900": theme.c900,
          "--BorderColor": withOpacity(theme.c500, 0.15),
          "--BorderColorHover": withOpacity(
            theme.c500,
            applyCommunityStyling ? 0.4 : 0.2
          ),
          "--BackgroundLightMode": withOpacity(
            applyCommunityStyling ? theme.c50 : theme.c500,
            0.2
          ),
          "--BackgroundDarkMode": withOpacity(
            applyCommunityStyling ? theme.c950 : theme.c700,
            0.2
          ),
          "--TextLightMode": theme["c700"],
          "--TextDarkMode": theme.c300,
        } as CSSProperties
      }
    >
      {!p.noDotGrid && (
        <RigidDotGrid color={theme.c500} cellSize={26} dotSize={1} />
      )}
      <StoryEntryInner
        ref={wrapperRef}
        className={cx(shouldUseSmallerFontSizeForTitle && "smallerTitle")}
      >
        <Content>
          {!isTinesComBuildOnMain && (
            <>
              {story.isUnlisted && <DevTag>Unlisted</DevTag>}
              {!story.isPublic && <DevTag>Private</DevTag>}
            </>
          )}
          {p.preHeading && <PreHeading>{p.preHeading}</PreHeading>}
          <h3>{story.name}</h3>
          {(p.withDescription ||
            p.description ||
            (story.tools.length > 0 && !p.hideTools)) &&
            (p.description ||
              story.description ||
              (story.tools.length > 0 && !p.hideTools)) && (
              <StoryEntryDescription
                className={cx(!p.doNotClampDescription && "clamp")}
              >
                {p.description !== undefined ? (
                  p.description
                ) : p.withDescription ? (
                  <p>{story.description}</p>
                ) : null}
                {!p.hideTools && story.tools.length > 0 && (
                  <p>
                    <strong>Tools:</strong> {story.tools.join(", ")}
                  </p>
                )}
              </StoryEntryDescription>
            )}
          {story.communityAuthors.length > 0 && (
            <AuthorLine>
              <StoryCommunityAuthorsDisplay story={story} />
            </AuthorLine>
          )}
          {p.withArrow && (
            <svg
              className="arrow"
              width="26"
              height="16"
              viewBox="0 0 26 16"
              fill="none"
            >
              <path
                d="M17.2354 1L24.0001 8L17.2354 15"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M1 8H24"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          )}
        </Content>

        <IconArrayWrap>
          <StoryIconArray story={story} />
        </IconArrayWrap>
      </StoryEntryInner>
    </Link>
  );
};

export default StoryEntry;
