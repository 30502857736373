import { styled } from "@linaria/react";
import {
  DatoCmsButton,
  DatoCmsLargeHeadingSection,
} from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import WithCustomInlineFormatting from "../../typography/WithCustomInlineFormatting";
import { LPButtonGroup } from "../LPButtonGroup";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { serif } from "../../../styles/fonts.styles";
import { ButtonGroupCenteredOnMobile } from "../../forms/Button";
import {
  fromDesktopSm,
  fromTablet,
  fromTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  align-items: center;
  grid-template-areas: "figure" "text";
  text-align: center;
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(3, 1fr));
    grid-template-areas: "text figure";
  }
`;

const TextContent = styled.div`
  grid-area: text;
  text-align: center;
  ${fromTabletMd} {
    text-align: left;
  }
  h2 {
    font-family: ${serif};
    font-size: 34px;
    font-weight: 400;
    letter-spacing: -0.015em;
    line-height: 1;
    ${fromTablet} {
      font-size: 52px;
      max-width: 13em;
      font-weight: 300;
    }
    ${fromDesktopSm} {
      font-size: 58px;
    }
  }
  p {
    max-width: 40em;
  }
  > * + * {
    margin-top: ${rSize("gap")};
  }
`;

const Figure = styled.figure`
  grid-area: figure;
  text-align: center;
  margin: 0;
  ${fromTabletMd} {
    text-align: right;
  }
`;

export const LPLargeHeadingSection = ({
  section,
}: {
  section: DatoCmsLargeHeadingSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Grid>
        <TextContent>
          <WithCustomInlineFormatting as="h2">
            {section.heading}
          </WithCustomInlineFormatting>
          {section.body && (
            <WithCustomInlineFormatting as="p">
              {section.body}
            </WithCustomInlineFormatting>
          )}
          {!!section.ctaButtons?.length && (
            <ButtonGroupCenteredOnMobile>
              <LPButtonGroup buttons={section.ctaButtons as DatoCmsButton[]} />
            </ButtonGroupCenteredOnMobile>
          )}
        </TextContent>
        <Figure>
          {section.illustration?.imageWithNoPadding?.url && (
            <img src={section.illustration.imageWithNoPadding.url} />
          )}
        </Figure>
      </Grid>
    </PageSection>
  );
};
