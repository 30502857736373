import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { cx } from "linaria";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { colors } from "../../styles/colors.styles";
import {
  fromPhoneLg,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { MobileHiddenSpan } from "../mediaQueries/MobileHidden";

export type LibraryPageViewType = "featured" | "all";

type Props = {
  view: LibraryPageViewType;
  entryType: string;
  handleViewSwitch: (view: LibraryPageViewType) => void;
  totalCount: number;
  canSwitchView?: boolean;
};

const LibraryPageViewSwitcher = (props: Props) => {
  return (
    <ViewSwitcher>
      {props.canSwitchView && (
        <ViewSwitcherButton
          view={props.view}
          forView="featured"
          onClick={props.handleViewSwitch}
        >
          <StarIcon />
          <span>Featured</span>
        </ViewSwitcherButton>
      )}
      <ViewSwitcherButton
        view={props.view}
        forView="all"
        onClick={props.handleViewSwitch}
      >
        <ListIcon />
        <span>
          All <MobileHiddenSpan>{props.entryType} </MobileHiddenSpan>
          {props.totalCount ? (
            <ItemCountBadge>{props.totalCount}</ItemCountBadge>
          ) : null}
        </span>
      </ViewSwitcherButton>
    </ViewSwitcher>
  );
};

const ViewSwitcher = styled.div`
  display: flex;
  position: relative;
  ${onlyPhones} {
    justify-content: center;
  }
  ${fromTablet} {
    margin-top: -0.5em;
  }
  > * {
    + * {
      margin-left: 2px;
    }
  }
`;

const ItemCountBadge = styled.b`
  background-color: var(--ac10020, ${withOpacity(colors.purple100, 0.2)});
  color: inherit;
  margin-left: 0.25em;
  ${darkModeLinariaCSS(`
    background-color: ${withOpacity(colors.light400, 0.2)}; 
  `)}
  [data-appearance="lighter"] & {
    background-color: var(--ac30, ${withOpacity(colors.purple100, 0.6)});
    color: var(--ac700, ${withOpacity(colors.dark500, 0.7)});
    ${darkModeLinariaCSS(`
      background-color: var(--ac900, ${withOpacity(colors.dark500, 0.8)});
      color: inherit;
    `)}
  }
  .selected & {
    background-color: var(--ac20, ${withOpacity(colors.purple, 0.2)});
    color: var(--ac700, ${withOpacity(colors.dark500, 0.7)});
    ${darkModeLinariaCSS(`
      color:  var(--ac100, ${withOpacity(colors.lightest, 0.7)});
    `)}
  }
  font-size: 1.2rem;
  position: relative;
  padding: 0.2em 0.5em;
  border-radius: 1.5em;
  transform: translateY(-0.125em);
  margin-left: 0.25em;
  vertical-align: middle;
`;

const ViewSwitcherButtonButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  --ViewSwitcherTabBg: ${withOpacity(colors.lightest, 0.1)};
  background-color: var(--ViewSwitcherTabBg);
  color: ${colors.lightest};
  ${darkModeLinariaCSS(`
    --ViewSwitcherTabBg: ${withOpacity(colors.dark800, 0.5)};
  `)}
  [data-appearance="lighter"] & {
    --ViewSwitcherTabBg: var(--ac30, ${withOpacity(colors.purple, 0.3)});
    color: var(--ac700, ${colors.purple700});
    ${darkModeLinariaCSS(`
      color: inherit;
    `)}
  }
  &:hover {
    --ViewSwitcherTabBg: ${withOpacity(colors.lightest, 0.2)};
    ${darkModeLinariaCSS(`
      --ViewSwitcherTabBg: ${withOpacity(colors.dark800, 0.4)};
    `)}
  }
  font-weight: 600;
  padding: 1em 1.25em;
  border: 0;
  font-size: 1.4rem;
  height: 45px;
  min-width: 125px;
  ${fromPhoneLg} {
    min-width: 150px;
  }
  border-top-left-radius: 0.75em;
  border-top-right-radius: 0.75em;
  position: relative;
  cursor: pointer;
  > span {
    margin-left: 0.25em;
  }
  svg {
    display: block;
  }
  .RoundCornerLeft {
    position: absolute;
    right: 100%;
    bottom: 0;
    color: var(--ViewSwitcherTabBg);
  }
  .RoundCornerRight {
    position: absolute;
    left: 100%;
    bottom: 0;
    color: var(--ViewSwitcherTabBg);
  }
  &.selected {
    z-index: 1;
    --ViewSwitcherTabBg: var(--ac50, ${withOpacity(colors.lightest, 0.7)});
    color: var(--ac700);
    cursor: default;
    ${darkModeLinariaCSS(`
      --ViewSwitcherTabBg: var(--ac800, ${withOpacity(colors.dark800, 0.6)}); 
      color: var(--ac100, ${colors.purple100});
    `)}
    .neutralColor & {
      --ViewSwitcherTabBg: ${withOpacity(colors.light400, 0.7)};
      color: inherit;
      ${darkModeLinariaCSS(`
        --ViewSwitcherTabBg: ${withOpacity(colors.dark800, 0.6)}; 
          color: inherit;
      `)}
    }
    &[data-for="featured"] {
      --ViewSwitcherTabBg: ${colors.lightest};
      color: var(--ac600);
      ${darkModeLinariaCSS(`
        --ViewSwitcherTabBg: ${colors.dark900};
      `)}
    }
  }
`;

const ViewSwitcherButton = (
  props: PropsWithChildren<{
    view: LibraryPageViewType;
    forView: LibraryPageViewType;
    onClick: (view: LibraryPageViewType) => void;
  }>
) => {
  return (
    <ViewSwitcherButtonButton
      className={cx(props.view === props.forView && "selected")}
      data-for={props.forView}
      onClick={() => props.onClick(props.forView)}
    >
      {props.children}
      <svg
        className="RoundCornerLeft"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C4.41828 8 8 4.41828 8 0V8H0Z"
        />
      </svg>
      <svg
        className="RoundCornerRight"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 -3.49691e-07C-1.93129e-07 4.41828 3.58172 8 8 8L-3.49691e-07 8L0 -3.49691e-07Z"
        />
      </svg>
    </ViewSwitcherButtonButton>
  );
};

const StarIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d="M6.83967 2.5767C7.10979 2.02938 7.89025 2.02938 8.16036 2.5767L9.16825 4.6189C9.27551 4.83624 9.48285 4.98688 9.7227 5.02173L11.9764 5.34921C12.5804 5.43698 12.8216 6.17924 12.3845 6.60527L10.7537 8.19489C10.5802 8.36407 10.501 8.60781 10.5419 8.84669L10.9269 11.0913C11.0301 11.6928 10.3987 12.1516 9.85845 11.8676L7.84269 10.8078C7.62816 10.695 7.37187 10.695 7.15734 10.8078L5.14158 11.8676C4.60134 12.1516 3.96994 11.6928 4.07311 11.0913L4.45809 8.84669C4.49906 8.60781 4.41987 8.36407 4.24631 8.19489L2.61552 6.60527C2.17846 6.17924 2.41964 5.43698 3.02364 5.34921L5.27733 5.02173C5.51718 4.98688 5.72452 4.83624 5.83179 4.6189L6.83967 2.5767Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

const ListIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5C3.55228 5 4 4.55228 4 4C4 3.44772 3.55228 3 3 3ZM3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8C3.55228 8 4 7.55228 4 7C4 6.44772 3.55228 6 3 6ZM5 4C5 3.58579 5.33579 3.25 5.75 3.25H11.25C11.6642 3.25 12 3.58579 12 4C12 4.41421 11.6642 4.75 11.25 4.75H5.75C5.33579 4.75 5 4.41421 5 4ZM5.75 6.25C5.33579 6.25 5 6.58579 5 7C5 7.41421 5.33579 7.75 5.75 7.75H11.25C11.6642 7.75 12 7.41421 12 7C12 6.58579 11.6642 6.25 11.25 6.25H5.75ZM5 10C5 9.58579 5.33579 9.25 5.75 9.25H11.25C11.6642 9.25 12 9.58579 12 10C12 10.4142 11.6642 10.75 11.25 10.75H5.75C5.33579 10.75 5 10.4142 5 10ZM3 9C2.44772 9 2 9.44771 2 10C2 10.5523 2.44772 11 3 11C3.55228 11 4 10.5523 4 10C4 9.44771 3.55228 9 3 9Z"
      fill="currentColor"
    />
  </svg>
);

export default LibraryPageViewSwitcher;
