export const HouseOfCardsIcon = () => (
  <svg
    width="100"
    height="92"
    viewBox="0 0 100 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.4772 34.1733H68.3635L56.6984 4.16211H37.8121L49.4772 34.1733Z"
      fill="#FFC8A3"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M61.7509 28.34C62.0424 29.3048 61.4651 29.7856 60.3049 29.7856H52.8596C52.0611 29.7856 51.4654 29.4012 51.0519 28.34L43.9333 10.7476C43.4978 9.92678 44.1897 9.30176 44.9883 9.30176H53.1865C53.985 9.30176 54.363 10.0228 54.6323 10.7476L61.7509 28.34Z"
      stroke="#E16520"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.788 25.084H25.1982L39.7787 1H58.6651L44.788 25.084Z"
      fill="#E269A4"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.7883 23.1944H28.8204L41.0807 3.25586H54.382L42.7883 23.1944Z"
      stroke="#FFBFDB"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M69.3195 35.3346L39.9913 23.1318H20.677V25.1691L52.4096 37.3719H69.3195V35.3346Z"
      fill="#FDA2CB"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.4098 37.3717H69.3195L39.9913 25.1689H20.677L52.4098 37.3717Z"
      fill="#B34680"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.2089 91.3785C77.6104 91.3785 99.8237 90.5155 99.8237 89.4509C99.8237 88.3864 77.6104 87.5234 50.2089 87.5234C22.8074 87.5234 0.594025 88.3864 0.594025 89.4509C0.594025 90.5155 22.8074 91.3785 50.2089 91.3785Z"
      fill="#863518"
    />
    <path
      d="M71.8917 89.2973H90.7781L79.113 59.2861H60.2266L71.8917 89.2973Z"
      fill="#FFC8A3"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M84.6717 84.1867C84.9632 85.1514 84.3862 85.6323 83.2259 85.6323H75.7805C74.9819 85.6323 74.3862 85.2479 73.9727 84.1867L66.8541 66.5942C66.4186 65.7735 67.1105 65.1484 67.9091 65.1484H76.1071C76.9057 65.1484 77.2838 65.8695 77.5529 66.5942L84.6717 84.1867Z"
      stroke="#E16520"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M67.1152 89.2973H48.2289L59.894 59.2861H78.7803L67.1152 89.2973Z"
      fill="#FD975D"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M65.0909 87.1449H51.5269L61.5527 61.3516H75.1164L65.0909 87.1449Z"
      stroke="#FFE0CC"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M76.052 67.1568L81.1009 64.0714L79.1589 59.6143L76.052 67.1568Z"
      fill="#863518"
    />
    <path
      d="M47.8963 89.2973H66.7826L55.1175 59.2861H36.2312L47.8963 89.2973Z"
      fill="#FDA2CB"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M60.7898 84.1867C61.0813 85.1514 60.5043 85.6323 59.344 85.6323H51.8986C51.1 85.6323 50.5043 85.2479 50.0908 84.1867L42.9722 66.5942C42.5367 65.7735 43.2286 65.1484 44.0272 65.1484H52.2254C53.024 65.1484 53.4019 65.8695 53.6712 66.5942L60.7898 84.1867Z"
      stroke="#B34680"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.1199 89.2973H24.2335L35.8986 59.2861H54.785L43.1199 89.2973Z"
      fill="#E269A4"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.9459 87.2923H27.3822L37.4077 61.499H50.9717L40.9459 87.2923Z"
      stroke="#FFBFDB"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.0566 67.1568L57.1052 64.0714L55.1633 59.6143L52.0566 67.1568Z"
      fill="#863518"
    />
    <path
      d="M24.2339 89.2973H43.1203L31.4552 59.2861H12.5688L24.2339 89.2973Z"
      fill="#FDA2CB"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.6056 84.1867C36.8969 85.1514 36.3199 85.6323 35.1596 85.6323H27.7144C26.9158 85.6323 26.3201 85.2479 25.9066 84.1867L18.788 66.5942C18.3525 65.7735 19.0444 65.1484 19.843 65.1484H28.0412C28.8398 65.1484 29.2177 65.8695 29.487 66.5942L36.6056 84.1867Z"
      stroke="#B34680"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.4575 89.2973H0.571136L12.2362 59.2861H31.1226L19.4575 89.2973Z"
      fill="#FD975D"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.3942 67.1568L33.4429 64.0714L31.5011 59.6143L28.3942 67.1568Z"
      fill="#863518"
    />
    <path
      d="M70.6736 56.8128H87.9302L68.6737 36.3398H49.7874L70.6736 56.8128Z"
      fill="#FDA2CB"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M72.5434 54.0797C73.5619 54.0797 78.3022 54.0797 79.2834 54.0797C80.9312 54.0797 81.1551 53.2989 80.4499 52.5419C79.7446 51.7849 69.7973 41.4163 69.1701 40.7497C68.192 39.7103 67.1552 39.415 65.9145 39.415C64.6742 39.415 58.9901 39.415 57.9529 39.415C56.8602 39.415 56.8788 40.1549 57.8974 41.1364C58.5955 41.8092 69.5403 52.4374 70.1624 53.0731C70.7845 53.7088 71.3213 54.0797 72.5434 54.0797Z"
      stroke="#B34680"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.8218 56.8139H35.9355L55.7124 41.9697H73.4135L54.8218 56.8139Z"
      fill="#FD975D"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.8979 54.6354H42.08L56.4862 43.748H67.5627L53.8979 54.6354Z"
      stroke="#FFE0CC"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.6941 56.8132H49.5804L41.2107 26.8018H22.3243L30.6941 56.8132Z"
      fill="#FFC8A3"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M81.5609 57.1934H8.08923V59.4166H81.5609V57.1934Z"
      fill="#FD975D"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.6402 52.4863C43.8768 53.456 43.3547 53.9319 42.1944 53.9319H34.7489C33.9504 53.9319 33.3547 53.5475 32.9412 52.4863L28.0527 34.1187C27.6172 33.2979 28.3091 32.6729 29.1077 32.6729H37.3057C38.1043 32.6729 38.4824 33.3939 38.7515 34.1187L43.6402 52.4863Z"
      stroke="#E16520"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M81.5608 57.1934H32.0859V59.4166H81.5608V57.1934Z"
      fill="#FFC8A3"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.1597 56.8132H12.2733L21.9918 26.8018H40.8781L31.1597 56.8132Z"
      fill="#E269A4"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.0957 54.7042H15.532L23.6109 28.9111H37.1749L29.0957 54.7042Z"
      stroke="#FFBFDB"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.5417 87.1449H3.97778L14.0035 61.3516H27.5675L17.5417 87.1449Z"
      stroke="#FFE0CC"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.3422 59.3008V56.9678"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.0112 36.4821L42.8123 32.7477L41.3305 26.998L38.0112 36.4821Z"
      fill="#863518"
    />
    <path
      d="M63.8548 50.1158L76.2282 44.637L73.8132 41.8174L63.8548 50.1158Z"
      fill="#863518"
    />
    <path
      d="M39.9914 25.1691V23.1318"
      stroke="#863518"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.0096 15.3075L58.2767 8.75511L56.7864 4.53125L50.0096 15.3075Z"
      fill="#863518"
    />
  </svg>
);
