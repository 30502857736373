import { styled } from "@linaria/react";
import { css } from "linaria";
import StandardArticle from "../../components/articles/StandardArticle";
import Spacing from "../../components/layout/Spacing";
import PageSection from "../../components/reusableSections/PageSection";
import FlexibleDotGrid from "../../components/decorations/FlexibleDotGrid";
import SEO from "../../components/utilities/SEO";
import {
  GRID_MAX_WIDTH_PX,
  widthInGrid,
} from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import OGImage from "../../../static/images/og/tines-formulas.png";
import MaxWidth from "../../components/layout/MaxWidth";
import { Serif } from "../../components/typography/Serif";
import FormulasPageTimelineSection from "../../components/productPages/formulas/FormulasPageTimelineSection";
import FormulasPageFunctionsGridSection from "../../components/productPages/formulas/FormulasPageFunctionsGridSection";
import FormulasPageHowItWorksSection from "../../components/productPages/formulas/FormulasPageHowItWorksSection";
import { Link } from "gatsby";
import { FormulaPillKaleidoscope } from "../../components/productPages/formulas/FormulaPillKaleidoscope";
import { colors } from "../../styles/colors.styles";
import AllCaps from "../../components/typography/AllCaps";
import HeroSection from "../../components/sections/HeroSection";
import SignUpButton from "../../components/utilities/SignUpButton";

const HeroInner = styled.div`
  text-align: center;
  ${uptoTablet} {
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
  }
  ${fromTablet} {
    width: 100%;
    max-width: ${GRID_MAX_WIDTH_PX};
    margin-left: auto;
    margin-right: auto;
  }
`;

const PageTitle = styled.h1``;

const OpeningParagraphs = styled.div`
  margin: 0;
  font-family: ${font("serif")};
  font-size: 1.7rem;
  text-align: center;
  padding-left: ${rSize("cardSectionPadding")};
  padding-right: ${rSize("cardSectionPadding")};
  ${fromTabletMd} {
    font-size: 2rem;
  }
  ${fromDesktopMd} {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: ${widthInGrid(1)};
    text-align: left;
    p {
      + p {
        margin-top: 0;
      }
    }
  }
`;

const articleStyle = css`
  h2 {
    margin-bottom: 1.25em;
    ${fromDesktop} {
      font-size: 3.2rem;
    }
  }
`;

const PageFormulas: PageComponent = props => {
  return (
    <Page
      {...props}
      browserThemeColor={colors.darkest}
      backgroundColor={colors.darkest}
      textColor={colors.white}
    >
      <SEO
        title="Formulas"
        ogUrl="formulas"
        image={OGImage}
        description="Tines formulas. A refreshingly familiar way to interact with data throughout an automated workflow."
      />

      <HeroSection
        backdrop="dots"
        backdropColor={colors.dark600}
        largeHeading
        contentRelative
      >
        <HeroInner>
          <AllCaps>Get to know</AllCaps>
          <Spacing size="lg" />
          <PageTitle>Formulas</PageTitle>
          <Spacing size="xl" />
          <FormulaPillKaleidoscope shadeColor={colors.darkest} />
        </HeroInner>
      </HeroSection>

      <Spacing size="sectionPadding" />

      <PageSection>
        <MaxWidth maxWidth={GRID_MAX_WIDTH_PX}>
          <StandardArticle className={articleStyle}>
            <OpeningParagraphs>
              <p>
                Many security automation tools and SOAR platforms <em>claim</em>{" "}
                to be no-code. But once you need to do something tricky, like
                precisely assembling a piece of text or modifying the structure
                of data between APIs, you’ll either find yourself blocked, or
                needing to write code.
              </p>
              <p>
                Introducing <i>formulas</i>. You already know how to use them,
                and with our in-built library of hundreds of functions, you can
                instantly tap into the power you need.
              </p>
            </OpeningParagraphs>
          </StandardArticle>
        </MaxWidth>
      </PageSection>

      <Spacing size="sectionPadding" />

      <FormulasPageTimelineSection />

      <Spacing size="sectionPadding" />

      <FormulasPageHowItWorksSection />

      <Spacing size="sectionPadding" />

      <FormulasPageFunctionsGridSection />

      <Spacing size="sectionPadding" />

      <SummarySection />

      <Spacing size="sectionMargin" />
    </Page>
  );
};

const SummaryCard = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: ${rSize("radius")};
  background-color: ${colorsV4.warmBlack900};
  padding: 4em 0;
  svg {
    position: relative;
  }
  h2 {
    ${fromTabletMd} {
      font-size: 4.2rem;
    }
  }
`;

const keycapLinkStyle = css`
  display: inline-block;
  text-decoration: none;
  &:hover {
    filter: brightness(1.04);
  }
  &:active {
    transform: translateY(2px);
    filter: brightness(0.96);
  }
`;

const SummarySection = () => (
  <PageSection>
    <SummaryCard>
      <FlexibleDotGrid
        color={withOpacity(colorsV4.canvas, 0.075)}
        cover
        dotSize={2}
        desiredCellSize={24}
        coverInset={-8}
      />

      <Link className={keycapLinkStyle} to="/docs/formulas">
        <svg width="78" height="82" viewBox="0 0 78 82" fill="none">
          <path
            d="M76.6403 68.2229C76.6403 75.2794 70.9194 80.9999 63.8629 80.9999H13.777C6.72048 80.9999 1 75.2794 1 68.2229V14.0483C1 6.99173 6.72048 1.27124 13.777 1.27124H63.8629C70.9194 1.27124 76.6403 6.99173 76.6403 14.0483V68.2229V68.2229Z"
            fill="#6359B3"
            stroke="#18171C"
            strokeWidth="1.9296"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M67.3783 51.5555C67.3783 56.8839 63.0587 61.2035 57.7303 61.2035H19.9102C14.5818 61.2035 10.2622 56.8839 10.2622 51.5555V10.648C10.2622 5.3196 14.5818 1 19.9102 1H57.7303C63.0587 1 67.3783 5.3196 67.3783 10.648V51.5555Z"
            fill="#8173E6"
            stroke="#18171C"
            strokeWidth="1.9296"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M10.262 65.1401L4.53223 77.0376"
            stroke="#18171C"
            strokeWidth="1.9296"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M10.2622 51.5554C10.2622 51.5554 10.2622 57.6657 10.2622 65.1402L19.136 61.1698"
            stroke="#18171C"
            strokeWidth="1.9296"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M67.3799 65.1401L73.1097 77.0376"
            stroke="#18171C"
            strokeWidth="1.9296"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M67.3797 51.5554C67.3797 51.5554 67.3797 57.6657 67.3797 65.1402L58.5059 61.1698"
            stroke="#18171C"
            strokeWidth="1.9296"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M28.2065 43.8371C29.3643 43.8371 30.3291 43.8371 32.4517 43.8371C36.2148 43.8371 36.7944 40.8466 37.469 38.4342C37.9811 36.6042 41.3282 25.313 42.0997 22.9974C42.7001 21.1944 43.2574 19.5242 45.38 19.5242C47.5025 19.5242 50.4938 19.5242 50.4938 19.5242"
            stroke="#E9E6FF"
            strokeWidth="3.08736"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M33.6201 28.3038H47.7834"
            stroke="#E9E6FF"
            strokeWidth="3.08736"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </svg>
      </Link>

      <Spacing size="md" />
      <h2>
        Try <Serif>formulas</Serif> now
      </h2>
      <Spacing size="lg" />
      <FormulaPillKaleidoscope />
      <Spacing size="lg" />
      <SignUpButton />
    </SummaryCard>
  </PageSection>
);

export default PageFormulas;
