import { styled } from "@linaria/react";
import { css } from "linaria";
import PillLegacy from "../../components/basic/PillLegacy";
import SpotIllustration from "../../components/illustrations/SpotIllustration";
import Spacing from "../../components/layout/Spacing";
import FromTabletDiv from "../../components/mediaQueries/FromTablet";
import NoCodePlaybookChapter1 from "../../components/reports/playbook-no-code/NoCodePlaybookChapter1";
import NoCodePlaybookChapter2 from "../../components/reports/playbook-no-code/NoCodePlaybookChapter2";
import NoCodePlaybookChapter3 from "../../components/reports/playbook-no-code/NoCodePlaybookChapter3";
import NoCodePlaybookChapter4 from "../../components/reports/playbook-no-code/NoCodePlaybookChapter4";
import NoCodePlaybookChapter5 from "../../components/reports/playbook-no-code/NoCodePlaybookChapter5";
import NoCodePlaybookConclusionSection from "../../components/reports/playbook-no-code/NoCodePlaybookConclusionSection";
import NoCodePlaybookIntroSection from "../../components/reports/playbook-no-code/NoCodePlaybookIntroSection";
import NoCodePlaybookNav from "../../components/reports/playbook-no-code/NoCodePlaybookNav";
import ReportSectionFrame from "../../components/reports/ReportSectionFrame";
import HeroSectionWithInset, {
  ColumnedHeroSectionContent,
} from "../../components/reusableSections/HeroSectionWithInset";
import { whenDesktopNav } from "../../components/site/SiteNav";
import { Serif } from "../../components/typography/Serif";
import SEO from "../../components/utilities/SEO";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  KnownDocumentName,
  recordDocumentEvent,
} from "../../utils/documents.utils";
import ogImage from "../../../static/images/og/tines-playbook-no-code-automation-for-security-teams.png";
import { colors } from "../../styles/colors.styles";
import InfoBox from "../../components/general/InfoBox";
import { Link } from "gatsby";
import { Paths } from "../../utils/pathBuilders.utils";

const HeroTextContentWrap = styled.div`
  ${whenDesktopNav} {
    max-width: 58rem;
  }
`;
const HeroIllustrationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${whenDesktopNav} {
    margin: 2em;
  }
`;

const Halo = styled.div`
  position: absolute;
  top: -3em;
  right: -3em;
  bottom: -3em;
  left: -3em;
  border-radius: 50%;
  background-color: ${withOpacity(colorsV4.white, 0.1)};
  pointer-events: none;
  animation: HaloPulse 10s infinite alternate-reverse;
  @keyframes HaloPulse {
    from {
      transform: scale(1);
      opacity: 0.5;
    }
    to {
      transform: scale(0.8);
      opacity: 1;
    }
  }
`;

const IllustrationBubble = styled.div`
  background-color: ${withOpacity(colorsV4.canvas550, 0.15)};
  padding: 3em;
  border-radius: 50%;
  img {
    position: relative;
    display: block;
    ${fromTablet} {
      width: 200px;
      height: 200px;
    }
  }
`;

const PagePlaybook: PageComponent = props => {
  const description =
    "This playbook contains everything you need to know about what no-code automation is, why it's a critical skill for those at the forefront of security operations, and how to bring the power of no-code automation to your security team.";
  const scrollToIntro = () => {
    scrollToHash({
      useHash: "#a-word-from-eoin-hinchy",
      doNotPushState: true,
    });
  };
  useOnMount(() => {
    recordDocumentEvent({
      type: "view",
      identifier: KnownDocumentName.playbook2022,
    });
  });

  return (
    <Page {...props} footerTextColor={colors.white}>
      <SEO
        title="Essential Guide to No-Code Automation for Security Teams"
        description={description}
        image={ogImage}
      />
      <style
        children=":root {
          transition: background-color 1s;
        }"
      />
      <HeroSectionWithInset>
        <ColumnedHeroSectionContent gap="0" alignItems="center">
          <HeroTextContentWrap>
            <PillLegacy color="purple" children="Playbook" />
            <Spacing size="md" />
            <h2
              className={css`
                line-height: 1.05;
                ${fluidFontSize(32, 58)};
                > * {
                  display: block;
                }
                ${onlyPhones} {
                  margin-top: 0.375em;
                }
              `}
            >
              Essential Guide to
              <span>
                No-Code Automation <Serif>for Security Teams</Serif>
              </span>
            </h2>

            <Spacing size="2em" />

            <InfoBox color={colors.white} accentColor={colors.white}>
              This content is no longer being updated. You'll find our latest
              version of the guide - the essential guide to workflow automation
              for security teams -{" "}
              <Link to={Paths.guideSecurity.accessPage()}>here</Link>.
            </InfoBox>
          </HeroTextContentWrap>
          <HeroIllustrationWrap>
            <Halo />
            <IllustrationBubble>
              <SpotIllustration name="openBookWithBlocks" />
            </IllustrationBubble>
          </HeroIllustrationWrap>
        </ColumnedHeroSectionContent>
        <Spacing size="sectionPadding" />
        <Spacing size="lg" />
      </HeroSectionWithInset>

      <FromTabletDiv>
        <Spacing size="lg" />
      </FromTabletDiv>

      <ReportSectionFrame nav={<NoCodePlaybookNav />}>
        <NoCodePlaybookIntroSection />
        <NoCodePlaybookChapter1 />
        <NoCodePlaybookChapter2 />
        <NoCodePlaybookChapter3 />
        <NoCodePlaybookChapter4 />
        <NoCodePlaybookChapter5 />
        <NoCodePlaybookConclusionSection />
      </ReportSectionFrame>

      <Spacing size="sectionPadding" />
    </Page>
  );
};

export default PagePlaybook;
