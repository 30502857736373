import { css } from "linaria";
import ContentCardBase, { ContentCardSize } from "./_ContentCardBase";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import { PrimaryColorName } from "../../styles/colors.styles";
import { Paths } from "../../utils/pathBuilders.utils";

const ContentCardLibrarySimpleStyle = css`
  figure {
    svg {
      transform: translateY(6%);
    }
  }
`;

export const ContentCardLibrarySimple = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => (
  <ContentCardBase
    className={ContentCardLibrarySimpleStyle}
    large={large}
    color={(overrides?.color ?? "purple") as PrimaryColorName}
    link={Paths.library.home()}
    variant={overrides?.colorMode === "light" ? "50" : "500"}
    size={(overrides?.size ?? "1/3") as ContentCardSize}
  >
    <header>
      <div>
        <p>
          <strong>{overrides?.preHeadingTag || "Library"}</strong>
        </p>
        <h4>{overrides?.heading || "Tines library"}</h4>
        <ReactMarkdown>
          {overrides?.description ||
            "Inspiration for your next build with our library of pre-built examples of Tines in action."}
        </ReactMarkdown>
      </div>
      <p>
        <strong>{overrides?.linkLabel || "Explore the library"}</strong>
      </p>
    </header>
    <figure>
      {overrides?.icon?.url ? (
        <img src={overrides.icon.url} />
      ) : (
        <LibraryIcon />
      )}
    </figure>
  </ContentCardBase>
);

const LibraryIcon = () => (
  <svg
    width="74"
    height="87"
    viewBox="0 0 74 87"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M39.5376 78.0163H64.6715L72.6606 69.6506H39.5376V78.0163Z"
      fill="#4D3E78"
      stroke="#4D3E78"
    />
    <path
      d="M49.8973 76.2746L49.9999 70.35H63.4579C63.7636 70.35 64.0602 70.246 64.2989 70.0551V70.0551C64.6185 69.7995 64.8046 69.4125 64.8046 69.0032V4.4631C64.8046 2.55048 63.2541 1 61.3415 1H11.7205C11.7205 1 7.63216 1.79955 3.94244 4.87437C1.2263 7.1382 0.911621 9.15627 0.911621 9.15627V77.8074H54.609L56.6729 76.2748L49.8973 76.2746Z"
      fill="#6359B3"
      stroke="#4D3E78"
    />
    <path
      d="M62.672 3.07422H11.5761L4.80156 7.22264L3.34961 74.0122H56.4041L62.672 67.7896V3.07422Z"
      fill="#F0EBE5"
      stroke="#4D3E78"
    />
    <path
      d="M56.6729 10.7819C56.6729 8.86933 55.1224 7.31885 53.2098 7.31885H4.8011L2.86337 8.0133L0.911621 9.15607V77.807H54.609L56.6729 75.9698V10.7819Z"
      fill="#6359B3"
    />
    <path
      d="M56.6729 10.7819C56.6729 8.86933 55.1224 7.31885 53.2098 7.31885H4.8011L2.86337 8.0133L0.911621 9.15607V77.807H54.609L56.6729 75.9698V10.7819Z"
      fill="#6359B3"
    />
    <path
      d="M4.8011 6.84664C4.54031 6.84664 4.32889 7.05805 4.32889 7.31885C4.32889 7.57964 4.54031 7.79106 4.8011 7.79106V6.84664ZM0.911621 77.807H0.439412C0.439412 78.0678 0.650827 78.2792 0.911621 78.2792L0.911621 77.807ZM54.609 77.807V78.2792C54.7247 78.2792 54.8365 78.2367 54.9229 78.1597L54.609 77.807ZM56.6729 75.9698L56.9868 76.3225C57.0875 76.2329 57.1451 76.1045 57.1451 75.9698H56.6729ZM53.2098 6.84664H4.8011V7.79106H53.2098V6.84664ZM0.439412 9.15607V77.807H1.38383V9.15607H0.439412ZM0.911621 78.2792H54.609V77.3348H0.911621V78.2792ZM54.9229 78.1597L56.9868 76.3225L56.3589 75.6171L54.295 77.4543L54.9229 78.1597ZM57.1451 75.9698V10.7819H56.2006V75.9698H57.1451ZM53.2098 7.79106C54.8616 7.79106 56.2006 9.13012 56.2006 10.7819H57.1451C57.1451 8.60853 55.3832 6.84664 53.2098 6.84664V7.79106Z"
      fill="#4D3E78"
    />
    <path
      d="M52.8774 9.15674H0.911621V77.8079H52.8774C53.8337 77.8079 54.609 77.0326 54.609 76.0763V10.8883C54.609 9.93198 53.8337 9.15674 52.8774 9.15674Z"
      fill="#9A8EF5"
      stroke="#4D3E78"
    />
    <path
      d="M38.0279 59.303C38.0279 59.303 38.0279 41.8652 38.0279 39.9951C38.0279 38.1245 38.4298 36.4701 31.1958 33.3222C24.1554 30.2584 19.5293 33.59 19.5293 33.59L19.5293 59.4539L38.0279 59.303Z"
      fill="#FFE1B3"
    />
    <path
      d="M48.531 14.2749H6.70415C6.226 14.2749 5.83838 14.6625 5.83838 15.1407V71.9019C5.83838 72.38 6.226 72.7677 6.70415 72.7677H48.531C49.0092 72.7677 49.3968 72.38 49.3968 71.9019V15.1407C49.3968 14.6625 49.0092 14.2749 48.531 14.2749Z"
      stroke="#FBB598"
    />
    <path
      d="M12.1778 62.1924L9.00617 64.9873V69.92L5.83449 72.7148V77.807L2.6626 80.602V85.9575H31.9516L35.0811 83.1998H35.1233L38.2948 80.4052L41.4667 77.6105V62.1924H12.1778Z"
      fill="#81D8B4"
      stroke="#4D3E78"
    />
    <path
      d="M41.1814 62.7607L38.1488 64.9488V70.2352L35.2791 72.5758V77.1822L31.9565 80.5806L32.1076 85.9423L41.3962 77.6108L41.5357 63.5679L41.1814 62.7607Z"
      fill="#6359B3"
    />
    <path d="M31.8448 80.939H2.79004V85.5267H31.8448V80.939Z" fill="#8578E6" />
    <path
      d="M31.5796 80.5804H3.03467L6.20635 77.7107H34.827L31.5796 80.5804Z"
      fill="#B0A6F9"
    />
    <path
      d="M31.9514 80.6018H2.6626V85.9573H31.9514V80.6018Z"
      stroke="#4D3E78"
    />
    <path
      d="M34.751 72.5H6.20605L9.37754 69.6304H37.9984L34.751 72.5Z"
      fill="#B0A6F9"
    />
    <path
      d="M38.1873 65.0239H9.13232V69.6115H38.1873V65.0239Z"
      fill="#8578E6"
    />
    <path d="M34.8459 72.8965H5.79102V77.484H34.8459V72.8965Z" fill="#8578E6" />
    <path d="M5.83496 72.7148H35.0853" stroke="#4D3E78" />
    <path
      d="M37.9238 64.8727H9.37842L12.5503 62.0034H41.1708L37.9238 64.8727Z"
      fill="#EB81AF"
    />
    <path d="M38.2945 64.9875H9.00586" stroke="#4D3E78" />
    <path
      d="M41.4667 77.6104L48.8014 77.8069L31.9517 85.9573L41.4667 77.6104Z"
      fill="#4D3E78"
      stroke="#4D3E78"
    />
    <path
      d="M12.1778 62.1924L9.00617 64.9873V69.92L5.83449 72.7148V77.807L2.6626 80.602V85.9575H31.9516L35.0811 83.1998H35.1233L38.2948 80.4052L41.4667 77.6105V62.1924H12.1778Z"
      stroke="#4D3E78"
    />
    <path
      d="M41.4667 62.1924L38.2948 64.9873V69.92L35.1233 72.7148V77.807L31.9517 80.602V85.9575"
      stroke="#4D3E78"
    />
    <path d="M5.83496 77.8074H35.1238" stroke="#4D3E78" />
    <path d="M9.00586 69.9204H38.2945" stroke="#4D3E78" />
    <path
      d="M37.6961 62.0034V59.4539L19.8085 59.477L16.3061 62.0034H12.2681L9.00586 64.8727H38.2945L41.4664 62.0034H37.6961Z"
      fill="#FFD18C"
      stroke="#4D3E78"
    />
    <path
      d="M26.7972 29.0603C18.6958 29.0603 12.1284 35.628 12.1284 43.7292L12.1284 62.0038H16.3058V44.207C16.3058 38.4128 21.0031 33.7156 26.7972 33.7156C32.5914 33.7156 37.2887 38.4128 37.2887 44.207V62.0038H41.4661V43.7292C41.4661 35.628 34.8987 29.0603 26.7972 29.0603Z"
      fill="#D4CFFC"
      stroke="#4D3E78"
    />
    <path
      d="M19.8091 59.2854V36.6765C19.8091 36.6765 16.3066 38.6437 16.3066 45.1852C16.3066 51.7267 16.3066 61.8119 16.3066 61.8119L19.8091 59.2854Z"
      fill="#6359B3"
      stroke="#4D3E78"
    />
    <path
      d="M16.4937 43.8508L19.6083 42.3437L19.809 36.6765C19.809 36.6763 16.4937 39.3296 16.4937 43.8508Z"
      fill="#4D3E78"
    />
    <path d="M55.8272 8.67486L62.7116 3.1228" stroke="#4D3E78" />
  </svg>
);
