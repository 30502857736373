import { styled } from "@linaria/react";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import { cx } from "linaria";

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  defaultCursor?: boolean;
};

export const PseudoLinkSpan = styled.span`
  ${inParagraphLinkStyle()};
  &.defaultCursor {
    cursor: default;
  }
  &.pointer {
    cursor: pointer;
  }
`;

const PseudoLink = (props: Props) => {
  return (
    <PseudoLinkSpan
      className={cx(
        props.className,
        props.defaultCursor || !props.onClick ? "defaultCursor" : "pointer"
      )}
      {...props}
    />
  );
};

export default PseudoLink;
