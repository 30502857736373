import { DatoCmsCenteredQuoteSection } from "../../../../graphql-types";
import CenteredQuoteSection from "../../general/CenteredQuoteSection";
import { BlendedDarkGreyLogoImage } from "../../misc/BlendedDarkGreyLogoImage";

export const LPCenteredQuoteSection = ({
  section,
}: {
  section: DatoCmsCenteredQuoteSection;
}) => {
  const logo = section.quote?.sourceOrganization?.logoDarkGray;
  return (
    <CenteredQuoteSection
      id={section.anchorOnPage}
      children={section.quote?.body}
      sourceName={section.quote?.sourceName}
      sourceWorkTitle={section.quote?.sourceSubheading}
      sourceCompanyLogo={
        logo?.url ? (
          <BlendedDarkGreyLogoImage
            className="logo"
            alt={`${section.quote?.sourceOrganization?.name ?? ""} Logo`}
            src={logo.url}
            width={logo.width}
            height={logo.height}
          />
        ) : null
      }
    />
  );
};
