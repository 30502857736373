import { styled } from "@linaria/react";

type Props = {
  className?: string;
  inputColor: string;
  outputColor: string;
  inputOffset?: string | number;
  outputOffset?: string | number;
  largerOutputTerminal?: boolean;
};

export const VerticalActionLinkWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  svg {
    flex: 0 0 auto;
  }
`;
const Line = styled.div`
  width: 3px;
  flex: 1 1 auto;
`;

const VerticalActionLink = (props: Props) => {
  return (
    <VerticalActionLinkWrap
      className={props.className}
      style={{
        marginTop: props.inputOffset,
        marginBottom: props.outputOffset,
      }}
    >
      <svg width="13" height="5" viewBox="0 0 13 5" fill={props.inputColor}>
        <path d="M5 5C5 2.23858 2.76142 0 0 0H5H8H13C10.2386 0 8 2.23858 8 5H5Z" />
      </svg>

      <Line
        style={{
          backgroundImage: `linear-gradient(to bottom, ${props.inputColor}, ${props.outputColor})`,
        }}
      />
      {props.largerOutputTerminal ? (
        <svg
          width="27"
          height="12"
          viewBox="0 0 27 12"
          fill={props.outputColor}
        >
          <path d="M0 12C6.62742 12 12 6.62742 12 0H15C15 6.62742 20.3726 12 27 12H15L12 12H0Z" />
        </svg>
      ) : (
        <svg width="13" height="5" viewBox="0 0 13 5" fill={props.outputColor}>
          <path d="M0.000106812 5C2.76153 5 5.00011 2.76142 5.00011 0H8.00011C8.00011 2.76142 10.2387 5 13.0001 5H8.00011L5.00011 5H0.000106812Z" />
        </svg>
      )}
    </VerticalActionLinkWrap>
  );
};

export default VerticalActionLink;
