import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";

export const ReportV2TextWithChart = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: "chart" "text";
  ${fromTablet} {
    min-height: 12em;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "text chart";
    align-items: center;
  }
  > svg {
    grid-area: chart;
    ${onlyPhones} {
      width: auto;
    }
  }
  p {
    max-width: 28em;
  }
`;
