import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopMd,
  fromPhoneLg,
  fromTabletLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../styles/fonts.styles";
import { colors } from "../../styles/colors.styles";
import Button, { ButtonGroupCenterFlex } from "../forms/Button";
import Spacing from "../layout/Spacing";
import BookADemoButton from "../utilities/BookADemoButton";
import { css } from "linaria";
import { Paths } from "../../utils/pathBuilders.utils";

const WorkbenchPageHeroSectionWrap = styled.header`
  position: relative;
  user-select: none;
  ${fromTabletLg} {
    pointer-events: none;
  }
`;

const Content = styled.div`
  text-align: center;
  padding-top: 9.25em;
  position: relative;
  h1 {
    text-align: center;
    max-width: 5em;
    position: relative;
    font-size: 6.8rem;
    letter-spacing: -0.04em;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    ${fromPhoneLg} {
      font-size: 8.4rem;
    }
    ${fromTabletMd} {
      font-size: 10rem;
    }
    ${fromTabletLg} {
      font-size: 12rem;
    }
    ${fromDesktop} {
      font-size: 14rem;
    }
    ${fromDesktopMd} {
      font-weight: 500;
      font-size: 18.4rem;
    }
    &:before,
    &:after {
      content: "Workbench";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
    span {
      display: block;
      position: relative;
      -webkit-text-fill-color: ${colors.lightest};
      -webkit-text-stroke: 1px ${colors.green};
      ${fromPhoneLg} {
        -webkit-text-stroke: 1.2px ${colors.green};
      }
      ${fromDesktopMd} {
        -webkit-text-stroke: 1.5px ${colors.green};
      }
      z-index: 3;
    }
    &:after {
      -webkit-text-fill-color: ${colors.lightest};
      -webkit-text-stroke: 1px ${colors.purple};
      ${fromPhoneLg} {
        -webkit-text-stroke: 1.2px ${colors.purple};
      }
      ${fromDesktopMd} {
        -webkit-text-stroke: 1.5px ${colors.purple};
      }
      z-index: 2;
      transform: translateX(-0.045em);
    }
    &:before {
      -webkit-text-fill-color: ${colors.lightest};
      -webkit-text-stroke: 1px ${colors.pink};
      ${fromPhoneLg} {
        -webkit-text-stroke: 1.2px ${colors.pink};
      }
      ${fromDesktopMd} {
        -webkit-text-stroke: 1.5px ${colors.pink};
      }
      z-index: 1;
      transform: translateX(-0.09em);
    }
  }
  h2 {
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.25;
    letter-spacing: -0.015em;
    font-size: 1.6rem;
    max-width: 20em;
    margin-top: 1em;
    ${fromTabletMd} {
      font-weight: 400;
      font-family: ${serif};
      font-size: 1.8rem;
      margin-top: 0.5em;
      max-width: 33em;
    }
    ${fromDesktopMd} {
      font-size: 2.4rem;
    }
  }
`;

export const WorkbenchPageHero = () => {
  return (
    <WorkbenchPageHeroSectionWrap>
      <Content>
        <h1>
          <span>Workbench</span>
        </h1>
        <h2>
          A Tines-powered AI chat interface where you can take action and access
          proprietary data in real-time, privately and securely.
        </h2>
        <Spacing size="2.25em" />
        <ButtonGroupCenterFlex
          className={css`
            > * {
              pointer-events: all;
            }
          `}
        >
          <Button href={Paths.signup_workbench()} darker>
            Try Workbench free
          </Button>
          {/* <SignUpButton darker /> */}
          <BookADemoButton appearance="outlined" darker />
        </ButtonGroupCenterFlex>
      </Content>
    </WorkbenchPageHeroSectionWrap>
  );
};
