import { styled } from "@linaria/react";
import { uptoDesktop } from "../../styles/breakpointsAndMediaQueries.styles";

const FromDesktop = styled.div`
  ${uptoDesktop} {
    display: none;
  }
`;
export const FromDesktopG = styled.g`
  ${uptoDesktop} {
    display: none;
  }
`;
export const FromDesktopSpan = styled.span`
  ${uptoDesktop} {
    display: none;
  }
`;

export default FromDesktop;
