import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { serif } from "../../styles/fonts.styles";
import { ButtonGroupCenteredUptoDesktop } from "../forms/Button";
import Spacing from "../layout/Spacing";
import BookADemoButton from "../utilities/BookADemoButton";
import SignUpButton from "../utilities/SignUpButton";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";

const Inner = styled.div`
  text-align: center;
  p {
    font-size: 1.8rem;
    font-family: ${serif};
    max-width: 15em;
    ${fromTabletMd} {
      font-size: 2.4rem;
      font-weight: 400;
      max-width: 24em;
    }
    line-height: 1.2;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const HomeEndingParagraphSection = () => (
  <PageSection relative>
    <Inner>
      <p>
        From SOAR to incident response, Tines breaks down barriers across
        systems with fewer duplicate efforts, unnecessary alerts, and
        information silos.
      </p>
      <Spacing size="lg" />
      <ButtonGroupCenteredUptoDesktop>
        <BookADemoButton appearance="filled-white" width="8em" />
        <SignUpButton appearance="outlined" color="white" width="8em" />
      </ButtonGroupCenteredUptoDesktop>
    </Inner>
  </PageSection>
);
