export const CourseIconDefault = () => (
  <svg
    width="94"
    height="61"
    viewBox="0 0 94 61"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M92.6485 38.8855H34.3566L10.0743 59.8622H71.3722L92.6485 38.8855Z"
      fill="#665DAC"
      stroke="#665DAC"
    />
    <path
      d="M88.5744 32.1885H30.282L6 53.1655V59.771H67.2982L88.5744 38.7939V32.1885Z"
      fill="#8578E6"
      stroke="#665DAC"
    />
    <path
      d="M88.5744 32.1885H30.282L6 53.1655H67.2982L88.5744 32.1885Z"
      fill="#B0A6F9"
      stroke="#665DAC"
    />
    <path d="M67.2981 59.771V53.1655" stroke="#665DAC" />
    <path
      d="M71.7096 37.1584H41.4004V45.8626H62.8778L71.7096 37.1584Z"
      fill="#665DAC"
      stroke="#665DAC"
    />
    <path
      d="M62.4841 15.6515H45.7154C45.3781 15.6515 45.0707 15.7786 44.8379 15.9876C44.571 16.2278 36.6296 23.9309 36.3928 24.1683C36.1548 24.4062 36.0083 24.7336 36.0083 25.0959V44.5513C36.0083 45.2758 36.5956 45.8628 37.3201 45.8628H54.0896C54.4412 45.8628 54.7592 45.725 54.9952 45.5C55.2461 45.2609 63.2277 37.5583 63.4681 37.2861C63.6715 37.0554 63.795 36.7517 63.7955 36.4187V16.9635C63.7955 16.2385 63.2088 15.6515 62.4841 15.6515Z"
      fill="#8578E6"
      stroke="#665DAC"
    />
    <path
      d="M55.4016 44.5511C55.4016 45.2756 54.8138 45.8626 54.0896 45.8626H37.3201C36.5956 45.8626 36.0083 45.2756 36.0083 44.5511V25.0956C36.0083 24.3714 36.5956 23.7842 37.3201 23.7842H54.0896C54.8138 23.7842 55.4016 24.3714 55.4016 25.0956V44.5511Z"
      fill="#BCB4FF"
      stroke="#665DAC"
    />
    <path d="M55.0084 24.1602L63.4771 16.1074" stroke="#665DAC" />
    <path d="M55.0083 23.8352L63.477 15.7825" stroke="#665DAC" />
    <path
      d="M54.6017 1L46.7182 8.08412L36.4056 22.0527C35.8535 22.7052 36.5958 23.4977 37.3203 23.4977H54.0898C54.4518 23.4977 54.7797 23.3512 55.0171 23.1138C55.2547 22.8765 62.6634 16.2674 62.9008 16.03C63.1382 15.7926 63.2853 15.4647 63.2853 15.1024L54.6017 1Z"
      fill="#EB994D"
      stroke="#665DAC"
    />
    <path
      d="M55.4015 22.1863C55.4015 22.9108 54.8137 23.4975 54.0895 23.4975H37.32C36.5955 23.4975 35.6024 23.0061 36.3049 21.9019L46.7179 8.08386L55.4015 22.1863Z"
      fill="#FFBD59"
      stroke="#665DAC"
    />
  </svg>
);
