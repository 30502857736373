import { GridCanvas } from "../../components/decorations/GridCanvas";
import CasesMainFeatureGridSection from "../../components/cases/CasesMainFeatureGridSection";
import CasesPageCTASection from "../../components/cases/CasesPageCTASection";
import CasesPageHero from "../../components/cases/CasesPageHero";
import CasesPageWhyTinesSection from "../../components/cases/CasesPageWhyTinesSection";
import CasesTwoSplitExplainerSection from "../../components/cases/CasesTwoSplitExplainerSection";
import Spacing from "../../components/layout/Spacing";
import SEO from "../../components/utilities/SEO";
import { colors } from "../../styles/colors.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import ogImage from "../../../static/images/og/tines-cases.png";
import { CasesPageAISection } from "../../components/cases/CasesPageAISection";
import { ExplosionCTASection } from "../../components/reusableSections/ExplosionCTASection";
import CasesPageContentCardsSection from "../../components/cases/CasesPageContentCardsSection";
import { AdminToolBarWithEditInDatoCmsButton } from "../../components/site/AdminToolbar";
import { ConnectorPathsPainter } from "../../components/decorations/ConnectorPathsPainter";
import { css } from "linaria";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { reportIntent } from "../../utils/intentReporter.utils";

const PageCases: PageComponent = props => {
  useOnMount(() => {
    reportIntent("Viewed Cases page");
  });
  return (
    <Page
      {...props}
      browserThemeColor={colors.purple}
      backgroundColor={colors.purple}
      theme={colors.white}
      colorNavAsDarkTheme
      navThemeColor="purple"
      alwaysUseColorScheme="light"
      textColor={colors.white}
      mobileNavBgColor={colors.purple}
    >
      <SEO
        title="Cases"
        image={ogImage}
        description="Automation for the threats you know, cases for the ones you don’t."
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="casesPage"
        /* cspell:disable */
        recordId="Wk7bwlLhQ2CmVcrzWKsxHw"
        /* cspell:enable */
        label="Edit in CMS"
      />

      <GridCanvas
        fixed
        parallax
        color={colors.purple600}
        className={css`
          z-index: -1;
        `}
      />

      <ConnectorPathsPainter />

      <CasesPageHero />
      <CasesMainFeatureGridSection />
      <Spacing size="sectionMargin" />
      <CasesTwoSplitExplainerSection />
      <Spacing size="sectionMargin" />
      <CasesPageAISection />
      <Spacing size="sectionMargin" />
      <CasesPageWhyTinesSection />
      <Spacing size="sectionMargin" />
      <CasesPageCTASection />
      <Spacing size="sectionPadding" />
      <CasesPageContentCardsSection />
      <Spacing size="sectionMargin" />
      <ExplosionCTASection />
    </Page>
  );
};

export default PageCases;
