import { styled } from "@linaria/react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { ReactNode, useRef, useState } from "react";
import gsap from "gsap";
import { runAfter } from "../../utils/promises.utils";

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > * {
    + * {
      margin-top: 2em;
    }
  }
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
  > * {
    margin-right: 2em;
  }
`;

export const LPMultiRowLogoMarquee = ({ logos }: { logos: ReactNode[] }) => {
  const [ready, setReady] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    setReady(true);
    const tweens = [] as GSAPTween[];
    runAfter(() => {
      tweens.push(
        ...Array.from(ref.current?.childNodes ?? []).map((c, i) => {
          const row = c as HTMLDivElement;
          return gsap.to(row, {
            x: "-50%",
            duration: row.clientWidth / 25 - i * 7,
            repeat: -1,
            ease: "linear",
          });
        })
      );
    });
    return () => {
      tweens.forEach(tween => {
        tween.kill();
      });
    };
  });
  const cutOffIndex = logos.length > 9 ? Math.floor(logos.length / 2) : 0;
  const RowOneContents = () => (
    <>{cutOffIndex ? logos.slice(0, cutOffIndex) : logos}</>
  );
  const RowTwoContents = () =>
    cutOffIndex ? <>{logos.slice(cutOffIndex)}</> : null;
  return (
    <Container ref={ref}>
      <Row>
        <RowOneContents />
        {ready && <RowOneContents />}
      </Row>
      <Row>
        <RowTwoContents />
        {ready && <RowTwoContents />}
      </Row>
    </Container>
  );
};
