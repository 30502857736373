export const DemoPageDecoLeft = () => (
  <svg
    width="129"
    height="259"
    viewBox="0 0 129 259"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="65" y="167" width="28" height="49" fill="#32274B" />
    <path
      d="M76.009 183.152L77.5569 186.287L81.018 186.79L78.5135 189.232L79.1048 192.68L76.009 191.051L72.9132 192.68L73.5045 189.232L71 186.79L74.4611 186.287L76.009 183.152Z"
      fill="#F1A0D0"
      stroke="#4D3E78"
      strokeWidth="0.753236"
      strokeLinejoin="round"
    />
    <path
      d="M87.2843 171L87.7946 171.77L88.5667 172.282L87.7946 172.795L87.2843 173.567L86.7721 172.795L86 172.282L86.7721 171.77L87.2843 171Z"
      fill="#FAF9F8"
    />
    <path
      d="M85.8115 197L86.9319 198.689L88.621 199.811L86.9319 200.932L85.8115 202.621L84.6892 200.932L83 199.811L84.6892 198.689L85.8115 197Z"
      fill="#FAF9F8"
    />
    <path
      d="M103.394 224.473V158.71H54.7627V224.473H103.394ZM66.3679 167.552H92.8939V215.631H66.3679V167.552Z"
      fill="#FDA2CB"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M115 239.394V143.789H43.7109V239.394H115ZM60.2897 160.368H98.9736V222.815H60.2897V160.368Z"
      fill="#E269A4"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M122.736 249.341V133.842H36.5264V249.341H122.736ZM51.4473 147.105H107.815V235.525H51.4473V147.105Z"
      fill="#CB5D94"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M127.71 257.63V125H31V257.63H127.71ZM42.0525 136.053H116.657V246.025H42.0525V136.053Z"
      fill="#A54B7A"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M42.0527 136.053L66.3683 167.552"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M92.8945 167.552L116.657 136.053"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M66.3683 215.631L42.0527 246.025"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M92.8945 215.631L116.657 246.025"
      stroke="#4D3E78"
      strokeLinejoin="round"
    />
    <path
      d="M1.87576 144.203H117.279V155.737H1.87576V144.203Z"
      fill="#4D3E78"
    />
    <path
      d="M0.999664 19.3262H118.164V151.145H0.999664V19.3262Z"
      fill="#C2AAFA"
      stroke="#4D3E78"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9792 28.8043H108.18V141.668H10.9792V28.8043Z"
      fill="#F8F4F0"
      stroke="#4D3E78"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.7479 117.801L72.8854 121.036L84 131.601L80.9795 135L69.8648 124.414L67.0023 127.649L62.5011 118.825L58 110L66.8641 113.911L75.7282 117.821L75.7479 117.801Z"
      fill="#FCF9F5"
      stroke="#4D3E78"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.0273 19.3263L59.5795 3.88782L26.1316 19.3263"
      stroke="#6956A8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.0303 5.33615C64.0303 2.94136 62.0889 1 59.6941 1C57.2993 1 55.358 2.94136 55.358 5.33615C55.358 7.73094 57.2993 9.67235 59.6941 9.67235C62.0889 9.67235 64.0303 7.73094 64.0303 5.33615Z"
      fill="#C2AAFA"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M11.2958 28.5663H108.148V31.2164H11.2958V28.5663Z"
      fill="#4D3E78"
    />
    <path
      d="M66.7203 40.6813H98.6582V73.6978L66.7203 40.6813Z"
      fill="#FDA2CB"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M81.0498 75.4718C81.0498 65.6109 73.0554 57.6121 63.1901 57.6121C53.3248 57.6121 45.3304 65.6065 45.3304 75.4718C45.3304 85.3372 53.3248 93.3315 63.1901 93.3315C73.0554 93.3315 81.0498 85.3372 81.0498 75.4718Z"
      fill="#E269A4"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M28.6879 86.6771L82.248 97.8372L80.5814 105.836L27.0213 94.6758L28.6879 86.6771Z"
      fill="#FDA2CB"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0415 75.4721L40.4072 60.6367L35.0498 42.0858L28.9087 43.8599L33.3241 59.1487L23.133 71.378L28.0415 75.4721Z"
      fill="#C2AAFA"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M32.1848 119.766V133.174H19.8587V119.982V107H32.3785H44.9512V119.766H32.1848Z"
      fill="#5ABE89"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
  </svg>
);
