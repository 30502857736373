import { styled } from "@linaria/react";
import { graphql } from "gatsby";
import { BenefitsChecklistContent } from "../components/reusableSections/BenefitsSection";
import Button from "../components/forms/Button";
import { APPLY_FOR_JOB_MODAL_NAME } from "../components/modals/modalNames";
import PageSection from "../components/reusableSections/PageSection";
import StandardPageHeader from "../components/reusableSections/StandardPageHeader";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import StandardArticle, {
  StandardArticleContainer,
} from "../components/articles/StandardArticle";
import { getWidthPxInMaxGrid } from "../constants/globalGrid.constants";
import { useSiteContext } from "../context/site.context";
import {
  fromDesktopMd,
  fromTabletLg,
  uptoTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../styles/colorsV4.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { PageComponentProps } from "../types/gatsby.types";
import { GreenhouseJob, JobListingByIdQuery } from "../../graphql-types";
import { decodeHTML } from "../utils/html.utils";
import Page from "./Page.template";
import ApplyForJobModal from "../components/modals/ApplyForJobModal";
import GreenhouseJobEntryLocationDisplay from "../components/general/GreenhouseJobEntryLocationDisplay";
import { SmallPrintBlack14 } from "../components/typography/SmallPrint";
import AllCaps from "../components/typography/AllCaps";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("sectionPadding")} ${rSize("gap")};
  ${uptoTabletLg} {
    grid-auto-flow: row;
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "main main main main main main main aside aside aside aside aside";
  }
  > * {
    > h2 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: ${rSize("xl")};
    }
  }
`;

const MainColumn = styled.div`
  ${fromTabletLg} {
    grid-area: main;
    padding-right: ${rSize("lg")};
  }
  ${fromDesktopMd} {
    padding-right: ${getWidthPxInMaxGrid(1, true)};
  }
  .content-intro {
    padding-bottom: ${rSize("xl")};
    border-bottom: 2px solid ${colorsV4.canvas700};
    margin-bottom: ${rSize("xl")};
  }
  .content-conclusion {
    font-size: 85%;
    margin-top: ${rSize("xl")};
    border-top: 2px solid ${colorsV4.canvas700};
    padding-top: ${rSize("xl")};
  }
  ${StandardArticleContainer} {
    padding-bottom: ${rSize("xl")};
    border-bottom: 2px solid ${colorsV4.canvas700};
  }
`;
const AsideColumn = styled.aside`
  ${fromTabletLg} {
    grid-area: aside;
  }
`;

const DPNSmallPrint = () => (
  <SmallPrintBlack14>
    * By applying for this job listing, you agree to our{" "}
    <a
      href="/data-protection-notice-for-recruitment-and-job-applications"
      target="_blank"
    >
      Data Protection Notice <sup>↗</sup>
    </a>{" "}
    for recruitment and job applications.
  </SmallPrintBlack14>
);

const JobListingPage = (
  props: PageComponentProps<{
    data: JobListingByIdQuery;
  }>
) => {
  const siteContext = useSiteContext();
  const locationName = props.data.job?.location?.name?.replace(
    "Dublin, Dublin",
    "Dublin"
  );
  const description = `We are hiring a ${props.data.job?.title} in ${
    props.data.job?.location?.name?.split(",")[0]
  }. Learn more about the role and our mission to improve the lives of security teams.`;
  const openApplyForJobModal = () =>
    siteContext.openModal(APPLY_FOR_JOB_MODAL_NAME);
  const ApplyButton = () => (
    <>
      <Button
        children="Apply now"
        width="9.25em"
        onClick={openApplyForJobModal}
      />
      <Spacing />
      <DPNSmallPrint />
    </>
  );

  return (
    <Page {...props}>
      <SEO
        title={`${props.data.job?.title} | Careers at Tines`}
        description={description}
        image={props.data.images?.nodes[0]?.url}
        keywords={`Tines, Careers, Jobs, ${props.data.job?.title}, Opening Positions, ${locationName}`}
      />
      <StandardPageHeader
        beforeTitle={
          <div>
            <AllCaps>Join the team</AllCaps>
            <Spacing size="1.25em" />
          </div>
        }
        pageTitle={props.data.job?.title ?? "Careers at Times"}
      >
        <p>
          <strong>Location: </strong>
          <GreenhouseJobEntryLocationDisplay
            job={props.data.job as GreenhouseJob}
          />
        </p>
        <p>
          <strong>
            Department
            {props.data.job?.departments &&
            props.data.job?.departments.length > 1
              ? "s"
              : ""}
            :{" "}
          </strong>
          {props.data.job?.departments
            ?.filter(d => d)
            .map(dept => (
              <span key={dept!.name}>{dept!.name}</span>
            ))}
        </p>
        <Spacing size="md" />
        <ApplyButton />
      </StandardPageHeader>
      <Spacing size="sectionPadding" />

      <PageSection>
        <Grid>
          <AsideColumn>
            <h2>Our Benefits</h2>
            <BenefitsChecklistContent />
          </AsideColumn>
          <MainColumn>
            <h2>Job Details</h2>
            <StandardArticle html={decodeHTML(props.data.job?.content ?? "")} />
            <Spacing size="xl" />
            <ApplyButton />
          </MainColumn>
        </Grid>
      </PageSection>

      <Spacing size="sectionMargin" />

      <ExplosionCTASection />

      {siteContext.modal === APPLY_FOR_JOB_MODAL_NAME && <ApplyForJobModal />}
    </Page>
  );
};

export const jobListingByIdQuery = graphql`
  query JobListingById($gh_Id: Float, $imageBasenameRegex: String) {
    job: greenhouseJob(gh_Id: { eq: $gh_Id }) {
      id
      title
      gh_Id
      content
      departments {
        name
      }
      location {
        name
      }
      offices {
        name
      }
    }
    images: allDatoCmsAsset(
      filter: { basename: { regex: $imageBasenameRegex } }
    ) {
      nodes {
        id
        url
      }
    }
  }
`;

export default JobListingPage;
