import { styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren } from "react";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import CornerDotSet from "../utilities/CornerDotSet";
import { ReportBaseSectionStyle } from "./ReportBaseStyles";

type Props = PropsWithChildren<{
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  cornerDotsColor?: string;
  standalone?: boolean;
}>;

export const ReportSummaryCardContainer = styled.section<{
  backgroundColor?: string;
  textColor?: string;
  centered?: boolean;
  standalone?: boolean;
}>`
  ${ReportBaseSectionStyle};
  position: relative;
  background-color: ${p => p.backgroundColor ?? colorsV4.white};
  color: ${p => p.textColor ?? "inherit"};
  font-size: 1.6rem;
  text-align: center;
  ${onlyPhones} {
    border-radius: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    &.standalone {
      margin-top: ${rSize("gap")};
      margin-left: 1em;
      margin-right: 1em;
    }
  }
  ${fromTablet} {
    margin-top: ${rSize("gap")};
    border-radius: 1em;
    margin-left: 0;
    margin-right: 0;
    &.standalone {
      border-radius: ${rSize("radius")};
    }
  }
  h3,
  h4,
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 58rem;
  }
`;

const Inner = styled.div`
  > * + * {
    margin-top: 1em;
  }
  h2 {
    font-size: 2.8rem;
    ${fromTablet} {
      font-size: 3.6rem;
    }
    margin: 0 1em 1em;
  }
  h3 {
    font-size: 2.4rem;
    ${fromTablet} {
      font-size: 3rem;
    }
    margin-top: 0 1em 1em;
  }
  h4 {
    color: var(--ThemeColorTextHighlight);
    font-size: 1.8rem;
  }
  blockquote {
    margin-left: 1em;
    margin-right: 1em;
    ${fromTablet} {
      margin-left: 2em;
      margin-right: 2em;
    }
    padding: 0;
    font-family: ${font("serif")};
    font-size: 2rem;
    p {
      line-height: 1.5;
    }
  }
`;

const ReportSummaryCard = (props: Props) => {
  return (
    <ReportSummaryCardContainer
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      className={cx(props.className, props.standalone && "standalone")}
    >
      <CornerDotSet
        color={props.cornerDotsColor ?? colorsV4.canvas700}
        offset={rSize("gap")}
      />
      <Inner>{props.children}</Inner>
    </ReportSummaryCardContainer>
  );
};

export default ReportSummaryCard;
