import FullscreenMessage from "../components/utilities/FullscreenMessage";
import RedirectHandler from "../components/utilities/RedirectHandler";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";

const PageSignup: PageComponent = props => {
  return (
    <FullscreenMessage>
      <SEO title="Sign up" noindex />
      <RedirectHandler
        to="https://login.tines.com/saml_idp/choose_tenant?sign_up=1"
        message={<p>Redirecting you to login.tines.com...</p>}
      />
    </FullscreenMessage>
  );
};

export default PageSignup;
