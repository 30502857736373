import { styled } from "@linaria/react";
import { css, cx } from "linaria";
import { cover, lighten } from "polished";
import { useRef, useState } from "react";
import {
  fromDesktopMd,
  fromDesktopMl,
  fromPhoneLg,
  fromTablet,
  onlyPhones,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { StoryDescriptor } from "../../types/helper.types";
import { runAfter } from "../../utils/promises.utils";
import { useVisibilityObserver } from "../../utils/useSizeAndVisibilityObserver.utils";
import Flippable from "../basic/Flippable";
import BrowserChrome, {
  BrowserChromeHeader,
  BrowserChromeInner,
  BrowserChromeOuter,
} from "../misc/BrowserChrome";
import StoryEmbedFrame, {
  StoryEmbedFrameInner,
  StoryEmbedFrameWrapper,
} from "../library/StoryEmbedFrame";
import StoryImportButton from "../library/StoryImportButton";
import { blueprintColors } from "./buildAppsPageConstants";

type Props = {
  story: StoryDescriptor;
  fillParent?: boolean;
};

const EmbedOuter = styled.div`
  text-align: center;
  z-index: 6;
  &.fillParent {
    width: 100%;
    height: 100%;
  }
  ${onlyPhones} {
    margin-left: -10px;
    margin-right: -10px;
  }
  ${BrowserChromeOuter} {
    &:before {
      display: block;
      z-index: 1;
      content: "";
      ${cover()};
      background-image: linear-gradient(
        180deg,
        #c7c7c7 0%,
        rgba(199, 199, 199, 0) 77.56%
      );
      border-radius: calc(0.75em - 1px);
      ${fromPhoneLg} {
        border-radius: calc(1em - 1px);
      }
      ${fromDesktopMd} {
        border-radius: calc(1.25em - 1px);
      }
    }
    &:after {
      display: block;
      z-index: 2;
      content: "";
      ${cover(1)};
      background-image: linear-gradient(
        157.41deg,
        #f5f2ee 1.83%,
        #fffdfb 65.88%
      );

      border-radius: calc(0.75em - 1px);
      ${fromPhoneLg} {
        border-radius: calc(1em - 1px);
      }
      ${fromDesktopMd} {
        border-radius: calc(1.25em - 1px);
      }
    }
  }
  ${BrowserChromeHeader} {
    z-index: 3;
    circle {
      stroke: ${blueprintColors.canvas.fg};
    }
  }
  ${BrowserChromeInner} {
    z-index: 3;
    ${fromTablet} {
      border-radius: 1.4rem;
    }
  }
`;

const AppFrame = styled.div`
  border-radius: 0.75em;
  ${fromTablet} {
    border-radius: 1.4rem;
  }
  overflow: hidden;
  text-align: center;
  background-image: linear-gradient(
    150deg,
    ${lighten(0.3, colorsV4.warmBlack)} -1.27%,
    ${withOpacity(lighten(0.3, colorsV4.warmBlack), 0.1)} 99.89%
  );
  height: 58rem;
  ${fromTablet} {
    height: 62rem;
  }
  ${fromDesktopMl} {
    height: 72rem;
  }
  transform: translate3d(0, 0, 0);
  iframe {
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${colorsV4.warmBlack};
    color: ${colorsV4.white};
    border: 0;
    @keyframes AppIFrameEnter {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: AppIFrameEnter 0.5s;
  }
`;

const StoryboardFrame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  text-align: center;
  border-radius: 1rem;
  ${fromTablet} {
    border-radius: 1.4rem;
  }
  overflow: hidden;
  background-image: linear-gradient(
    150deg,
    ${lighten(0.3, colorsV4.warmBlack)} -1.27%,
    ${withOpacity(lighten(0.3, colorsV4.warmBlack), 0.1)} 99.89%
  );
  ${StoryEmbedFrameWrapper},
  ${StoryEmbedFrameInner} {
    border-radius: 0;
  }
  ${StoryEmbedFrameInner} {
    background-color: transparent;
  }
`;

const StoryboardFrameInner = styled.div`
  background-color: ${colorsV4.white};
  @media (prefers-color-scheme: dark) {
    background-color: ${colorsV4.warmBlack};
  }
  color: ${colorsV4.white};
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
  border-radius: 1.4rem;
  overflow: hidden;
  > * {
    flex: 1 1 100%;
  }
`;

const StoryboardImportPrompt = styled.div`
  background-color: ${colorsV4.orange};
  color: ${colorsV4.white};
  border-radius: 1.5em 1.5em 0.5em 1.5em;
  padding: 1.5em;
  position: absolute;
  bottom: 1em;
  right: 1em;
  width: 25rem;
  text-align: left;
  font-size: 1.4rem;
  h3 {
    font-size: 2rem;
    font-family: ${font("serif")};
    font-weight: 400;
    line-height: 1.2;
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
  transform-origin: bottom right;
  opacity: 0;
  @keyframes StoryboardImportPromptEnter {
    from {
      transform: scale(0.6);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  animation: StoryboardImportPromptEnter 0.25s 3s
    cubic-bezier(0, 0.68, 0.59, 1.23) forwards;
  .Button {
    font-size: inherit;
  }
  ${uptoTabletMd} {
    display: none;
  }
`;

const SpecialButton = styled.div`
  appearance: none;
  background-color: ${colorsV4.orange};
  color: ${colorsV4.white};
  padding: 0.75em 1.25em;
  border-radius: 2em;
  font-weight: 500;
  font-size: 1.6rem;
  border: 2px solid ${colorsV4.orange300};
  transition: 0.1s;
  position: relative;
  transform-origin: center -4px;
  min-width: 8em;
  &:before {
    display: block;
    content: "";
    width: 30px;
    height: 13px;
    position: absolute;
    bottom: calc(100% - 2px);
    left: calc(50% - 15px);
    background-image: ${`url("data:image/svg+xml,%3Csvg width='30' height='13' viewBox='0 0 30 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.9853 9C24.394 9 22.8679 8.36786 21.7426 7.24264L17.1213 2.62132C15.9497 1.44975 14.0503 1.44975 12.8787 2.62132L8.25736 7.24264C7.13214 8.36786 5.60602 9 4.01472 9H0V11H30V9H25.9853Z' fill='${encodeURIComponent(
      colorsV4.orange300
    )}'/%3E%3Cpath d='M24.4853 11.0002C22.894 11.0002 21.3679 10.3681 20.2426 9.24289L15.7071 4.70735C15.3166 4.31683 14.6834 4.31683 14.2929 4.70735L9.75736 9.24288C8.63214 10.3681 7.10602 11.0002 5.51472 11.0002H0V13.0002H30V11.0002H24.4853Z' fill='${encodeURIComponent(
      colorsV4.orange
    )}'/%3E%3C/svg%3E%0A")`};
  }
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
    transform: scale(0.95);
  }
  &.hidden {
    opacity: 0;
    transform: scale(0);
    transition: 0.1s;
  }
`;

const CuriosityButtonWrap = styled.button`
  appearance: none;
  background-color: transparent;
  color: inherit;
  border: 0;
  border-radius: 0;
  margin-top: 1.5em;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0.001;
  transform-origin: center -4px;
  &.visible {
    @keyframes CuriosityButtonEnter {
      from {
        transform: translate(-50%, 0) scale(0.1);
        opacity: 0.001;
      }
      to {
        transform: translate(-50%, 0) scale(1);
        opacity: 1;
      }
    }
    animation: CuriosityButtonEnter 0.15s 1s ease forwards;
  }
  ${onlyPhones} {
    display: none;
  }
  &:focus {
    outline: none;
  }
`;

const CloseButtonWrap = styled.button`
  appearance: none;
  background-color: transparent;
  color: inherit;
  border: 0;
  border-radius: 0;
  margin-top: 1.5em;
  position: absolute;
  top: 100%;
  left: 50%;
  opacity: 0;
  transform-origin: center -4px;
  transform: translate(-50%, 0) scale(0);
  .flipped & {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
    transition: opacity 0.2s 0.75s, transform 0.2s 0.75s;
  }
`;

const publicAppUrl =
  "https://library.tines.com/pages/1cbd362629e579c5b94c4ea2b28ebde1/";

const PagesFlippableDemo = ({ story, fillParent }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [flipped, setFlipped] = useState(false);
  const [shouldRenderImportPrompt, setShouldRenderImportPrompt] =
    useState(false);
  const [hasClickedOnImportLink, setHasClickedOnImportLink] = useState(false);
  const flipOpen = () => {
    setFlipped(true);
    setShouldRenderImportPrompt(true);
  };
  const flipClose = () => setFlipped(false);
  const hideImportPrompt = () => {
    runAfter(() => {
      setHasClickedOnImportLink(true);
    });
  };

  const { visible: frameVisible } = useVisibilityObserver(ref);
  const { visible: buttonVisible } = useVisibilityObserver(buttonRef);

  return (
    <EmbedOuter className={cx(fillParent && "fillParent")} ref={ref}>
      <Flippable
        A={
          <BrowserChrome
            width={1100}
            titleBarContent="Try a live demo below!"
            children={
              <AppFrame>
                {frameVisible && <iframe src={publicAppUrl} />}
              </AppFrame>
            }
            backgroundColor={colorsV4.canvas700}
          />
        }
        B={
          <>
            <BrowserChrome
              width={1100}
              fillParent
              children={
                <StoryboardFrame>
                  <StoryboardFrameInner>
                    <StoryEmbedFrame
                      story={story}
                      frameless
                      disableFullScreen
                    />
                  </StoryboardFrameInner>
                  {shouldRenderImportPrompt && !hasClickedOnImportLink && (
                    <StoryboardImportPrompt>
                      <h3>Curious to see the configuration details?</h3>
                      <StoryImportButton
                        story={story}
                        fullWidth
                        appearance="filled-white"
                        color="orange"
                        onClick={hideImportPrompt}
                      />
                    </StoryboardImportPrompt>
                  )}
                </StoryboardFrame>
              }
              backgroundColor={colorsV4.canvas700}
              className={css`
                ${BrowserChromeHeader} {
                  flex-direction: row-reverse;
                }
              `}
            />
            <CloseButtonWrap onClick={flipClose}>
              <SpecialButton>Close</SpecialButton>
            </CloseButtonWrap>
          </>
        }
        sideBSizeGetter={({ aw, ah }) => ({
          width: aw,
          height: ah,
        })}
        flipped={flipped}
        fillParent
      />

      <CuriosityButtonWrap
        ref={buttonRef}
        className={cx(buttonVisible && "visible")}
        onClick={flipOpen}
      >
        <SpecialButton className={cx(flipped && "hidden")}>
          How was this built?
        </SpecialButton>
      </CuriosityButtonWrap>
    </EmbedOuter>
  );
};

export default PagesFlippableDemo;
