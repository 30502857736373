import { Link } from "gatsby";
import { DatoCmsBeginnerStoriesSection } from "../../../../graphql-types";
import LibraryCollectionPageSectionHeader from "./LibraryCollectionPageSectionHeader";
import { StoryDescriptor } from "../../../types/helper.types";
import { StoryGrid } from "../StoryGrid";
import { CourseIconBeginner } from "../../hub/university/CourseIconBeginner";

export const BeginnerStoriesSection = ({
  section,
  stories,
}: {
  section: DatoCmsBeginnerStoriesSection;
  stories: StoryDescriptor[];
}) => {
  const storiesToShow = stories
    .filter(s => s.tags.includes("Beginner"))
    .slice(0, parseInt(`${section.maxNumberOfStories ?? 3}`));
  return (
    <div id="beginner">
      <LibraryCollectionPageSectionHeader
        section={{
          pill: "Get started fast",
          heading: "Learners welcome",
          color: "green",
          description: (
            <>
              Step through beginner to advanced topics as you explore our
              tailored courses on <Link to="/university">Tines University</Link>
              .
            </>
          ),
        }}
        imageOverride={<CourseIconBeginner />}
      />
      <StoryGrid stories={storiesToShow} />
    </div>
  );
};
