import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { GridCanvas } from "../../decorations/GridCanvas";
import pagesAndPromptsIlloContentsSvg from "./pagesAndPromptsIlloContents.svg";

const PagesAndPromptsIlloCardDiv = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${colors.green600};
  border: 1px solid ${colors.green400};
  display: flex;
  justify-content: center;
  padding-top: 3em;
  aspect-ratio: 1 / 1;
  border-radius: ${rSize("radius")};
`;

export const PagesAndPromptsIlloCard = () => {
  return (
    <PagesAndPromptsIlloCardDiv>
      <GridCanvas color={colors.green} parallax />
      <PagesAndPromptsIlloContents />
      <TrafficLights />
    </PagesAndPromptsIlloCardDiv>
  );
};

const TrafficLightsSvg = styled.svg`
  position: absolute;
  top: 1.8rem;
  left: 1.8rem;
`;

const TrafficLights = () => (
  <TrafficLightsSvg width="51" height="12" viewBox="0 0 51 12" fill="none">
    <circle
      cx="6.5"
      cy="6"
      r="5.5"
      fill={colors.green}
      stroke={colors.green400}
    />
    <circle
      cx="25.5"
      cy="6"
      r="5.5"
      fill={colors.green}
      stroke={colors.green400}
    />
    <circle
      cx="44.5"
      cy="6"
      r="5.5"
      fill={colors.green}
      stroke={colors.green400}
    />
  </TrafficLightsSvg>
);

const PagesStack = styled.img`
  display: block;
  max-width: 90%;
  position: relative;
  height: auto;
`;
const PagesAndPromptsIlloContents = () => (
  <PagesStack src={pagesAndPromptsIlloContentsSvg} />
);
