import { styled } from "@linaria/react";
import { css } from "linaria";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorV4, colorsV4 } from "../../styles/colorsV4.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  GreenhouseDepartment,
  GreenhouseJob,
  GreenhouseOffice,
} from "../../../graphql-types";
import { UseStateReturnType } from "../../types/helper.types";
import { moveItemToNewIndex } from "../../utils/array.utils";
import Button from "../forms/Button";
import ComboFontHeading from "../typography/ComboFontHeading";
import GreenhouseJobEntryLink from "../general/GreenhouseJobEntryLink";
import Select from "../forms/Select";
import PageSection from "./PageSection";
import { Serif } from "../typography/Serif";

type Props = {
  filterState: UseStateReturnType<{
    officeId: string;
    departmentId: string;
  }>;
  jobs: Partial<GreenhouseJob>[];
  departments: Partial<GreenhouseDepartment>[];
  offices: Partial<GreenhouseOffice>[];
};

const Header = styled.header`
  display: grid;
  grid-gap: ${rSize("gap")};
  align-items: center;
  ${fromTabletMd} {
    grid-template-columns: minmax(0, 1fr) auto;
  }
`;

const HeadingGroup = styled.div``;
const FilterSelectSet = styled.div`
  display: grid;
  grid-gap: 0.5em;
  ${fromPhoneLg} {
    grid-auto-flow: column;
  }
`;

const Content = styled.div`
  margin-top: 2.4em;
`;

const DepartmentJobGroup = styled.div`
  + * {
    margin-top: ${rSize("xl")};
  }
  > header {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  h3 {
    color: ${colorsV4.purple};
    font-size: 2.2rem;
    ${fromDesktopMd} {
      font-size: 2.4rem;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid ${colorV4("canvas", 700)};
    border-bottom: 1px solid ${colorV4("canvas", 700)};
    > * {
      + * {
        border-top: 1px solid ${colorV4("canvas", 700)};
      }
    }
  }
`;

const EmptyJobsListNotice = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  p {
    font-size: 2.2rem;
    opacity: 0.5;
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const OpenPositionsSection = (props: Props) => {
  const { departments, jobs, offices } = props;

  const clearFilters = () => {
    props.filterState[1]({
      officeId: "",
      departmentId: "",
    });
  };

  const officeGroups = offices
    .filter(office => !office.parent_id)
    .map(parentOffice => ({
      parentOffice,
      offices: offices.filter(child => child.parent_id === parentOffice.gh_Id),
    }));

  const irelandGroup = officeGroups.find(
    g => g.parentOffice.name === "Ireland"
  );
  const northAmericaGroup = officeGroups.find(
    g => g.parentOffice.name === "North America"
  );

  if (northAmericaGroup)
    moveItemToNewIndex(officeGroups, northAmericaGroup, 0, true);
  if (irelandGroup) moveItemToNewIndex(officeGroups, irelandGroup, 0, true);

  const selectedOfficeId = props.filterState[0].officeId;
  const selectedDepartmentId = props.filterState[0].departmentId;

  const jobsFilteredByDepartments = selectedDepartmentId
    ? props.jobs.filter(job =>
        job.departments?.find(dept => `${dept?.gh_Id}` === selectedDepartmentId)
      )
    : props.jobs;

  const filteredJobs = selectedOfficeId
    ? jobsFilteredByDepartments.filter(job =>
        job.offices?.find(
          office =>
            `${office?.gh_Id}` === selectedOfficeId ||
            `${office?.parent_id}` === selectedOfficeId
        )
      )
    : jobsFilteredByDepartments;

  const jobsGroupedByDepartments = departments
    .map(department => ({
      department,
      jobs: filteredJobs.filter(job =>
        job.departments?.find(dept => dept?.gh_Id === department.gh_Id)
      ),
    }))
    .filter(dept => dept.jobs.length > 0);

  const departmentHasAnyJobsAtSelectedOffice = (
    dept: Partial<GreenhouseDepartment>
  ) => {
    if (!selectedOfficeId) return dept.jobs && dept.jobs.length > 0;
    const jobsInThisDept = jobs.filter(j =>
      j.departments?.find(d => d?.gh_Id === dept.gh_Id)
    );
    return jobsInThisDept?.some(job =>
      job.offices?.some(
        office =>
          `${office?.gh_Id}` === selectedOfficeId ||
          `${office?.parent_id}` === selectedOfficeId
      )
    );
  };

  const departmentFilterOptions = [
    {
      label: "All departments",
      value: "",
    },
    ...departments.map(dept => ({
      label: dept.name ?? "Unnamed Department",
      value: `${dept.gh_Id}`,
      disabled: !departmentHasAnyJobsAtSelectedOffice(dept),
    })),
  ];

  return (
    <PageSection id="open-positions">
      <Header>
        <HeadingGroup>
          <ComboFontHeading
            className={css`
              ${fluidFontSize(38, 48)}
            `}
          >
            <Serif>Open positions</Serif>
          </ComboFontHeading>
        </HeadingGroup>
        <FilterSelectSet>
          {/* <Select
            id="open-positions-office-filter-select"
            formState={props.filterState}
            name="officeId"
            options={[
              {
                label: "All locations",
                value: "",
              },
            ]}
            optgroups={officeFilterOptions}
            minWidth="22.25rem"
          /> */}
          <Select
            id="open-positions-department-filter-select"
            formState={props.filterState}
            name="departmentId"
            options={departmentFilterOptions}
            minWidth="22.25rem"
          />
        </FilterSelectSet>
      </Header>
      <Content>
        {jobsGroupedByDepartments.map(dept => {
          return (
            <DepartmentJobGroup key={dept.department.gh_Id}>
              <header>
                <h3>{dept.department.name}</h3>
              </header>
              <ul>
                {dept.jobs.map((job, i) => (
                  <li key={job.id ?? i}>
                    <GreenhouseJobEntryLink job={job} />
                  </li>
                ))}
              </ul>
            </DepartmentJobGroup>
          );
        })}
        {jobsGroupedByDepartments.length === 0 && (
          <EmptyJobsListNotice>
            {props.filterState[0].officeId ||
            props.filterState[0].departmentId ? (
              <>
                <p>No open positions matching your criteria.</p>
                <Button title="See all positions" onClick={clearFilters}>
                  See all positions
                </Button>
              </>
            ) : (
              <p>
                There are currently no open positions. Please check back another
                time!
              </p>
            )}
          </EmptyJobsListNotice>
        )}
      </Content>
    </PageSection>
  );
};

export default OpenPositionsSection;
