import dayjs from "dayjs";
import { EventDirectoryItem } from "../../types/helper.types";
import { eventIsAllDayOrMultiDayEvent } from "../../utils/events.utils";
import {
  getReadableTimezoneName,
  getStandardTimezoneFromReadable,
} from "../../utils/time.utils";

type Props = {
  item: EventDirectoryItem;
  showTimeOnSeparateLine?: boolean;
  displayTimezone?: boolean;
};

const EventDirectoryItemDates = (props: Props) => {
  const timezoneId =
    props.item.place?.timezone?.id ||
    (props.item.timezone
      ? getStandardTimezoneFromReadable(props.item.timezone)
      : null);
  const timezoneName = props.item.place?.timezone?.name
    ? props.item.place.timezone.name.replace(" Daylight ", " ")
    : timezoneId
    ? getReadableTimezoneName(timezoneId)
    : "";
  const isVirtualEvent =
    props.item.type === "webinar" ||
    props.item.categories.find(c => c.slug === "virtual");
  const shouldDisplayTimezone =
    (props.displayTimezone || isVirtualEvent) && timezoneName;
  const currentYear = `${new Date().getFullYear()}`;
  let start = dayjs(props.item.timeStart).tz();
  let end = dayjs(props.item.timeEnd || props.item.timeStart).tz();
  if (timezoneId) {
    start = start.tz(timezoneId);
    end = end.tz(timezoneId);
  }
  const startYear = start.format("YYYY");
  const endYear = end.isValid() ? end.format("YYYY") : startYear;
  const startsInThisYear = startYear === currentYear;
  const endsInThisYear = endYear === currentYear;
  const startDate = start.format(
    startsInThisYear ? "ddd MMM D" : "ddd MMM D, YYYY"
  );
  const startDateFull = start.format(
    startsInThisYear ? "dddd, MMMM D" : "dddd MMMM D, YYYY"
  );
  const endDate = end.isValid()
    ? end.format(endsInThisYear ? "MMM D" : "MMM D, YYYY")
    : "";
  const startTime = start.format("h:mm A");
  const endTime = end.isValid() ? end.format("h:mm A") : "";
  const startAndEndInSameDay = end.isValid() ? start.isSame(end, "date") : true;
  const timeDisplay = [
    startTime,
    startTime !== endTime
      ? ` → ${!startAndEndInSameDay ? endDate : ""} ${endTime}`
      : "",
  ]
    .filter(i => i)
    .join(" ")
    .replace(/ /g, " ");
  return (
    <span>
      {eventIsAllDayOrMultiDayEvent(props.item) ? (
        [
          startAndEndInSameDay ? startDateFull : startDate,
          startAndEndInSameDay ? startYear : `→ ${endDate}`,
          shouldDisplayTimezone ? `(${timezoneName})` : "",
        ]
          .filter(i => i)
          .join(" ")
      ) : props.showTimeOnSeparateLine ? (
        <div>
          <div>{startDate},</div>
          <div>{timeDisplay}</div>
          {shouldDisplayTimezone && <div>{timezoneName}</div>}
        </div>
      ) : (
        [
          `${startDate},`,
          timeDisplay,
          shouldDisplayTimezone ? `(${timezoneName})` : "",
        ]
          .filter(i => i)
          .join(" ")
      )}
    </span>
  );
};

export default EventDirectoryItemDates;
