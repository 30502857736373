import Button, { ButtonAppearance, ButtonProps } from "../forms/Button";
import { Paths } from "../../utils/pathBuilders.utils";

type Props = ButtonProps & {
  label?: string;
  leadSource?: string;
  campaignId?: string;
  darkMode?: boolean;
  appearance?: ButtonAppearance;
  width?: string;
  paramsString?: string;
};

const BookADemoButton = (props: Props) => {
  return (
    <Button
      {...props}
      name="book-a-demo"
      to={Paths.bookADemo(props.paramsString)}
    >
      {props.label || "Book a demo"}
    </Button>
  );
};

export default BookADemoButton;
