import { styled } from "@linaria/react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import PseudoLink, { PseudoLinkSpan } from "../basic/PseudoLink";
import Spacing from "../layout/Spacing";
import { useOpenStoryRequestModalFunction } from "../modals/StoryRequestModal";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { getUrlQueryParams } from "../../utils/urlQueryParams.utils";
import { colors } from "../../styles/colors.styles";
import { useState } from "react";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../utils/sessionStorage.utils";

export const LibraryStoryRequestPromptCardWrap = styled.button`
  appearance: none;
  display: block;
  text-align: left;
  color: inherit;
  border: 0;
  width: 100%;
  background-color: ${colors.light100};
  cursor: pointer;
  font-size: 1.4rem;
  &:hover {
    background-color: ${withOpacity(colors.purple, 0.15)};
  }
  ${darkModeLinariaCSS(`
    background-color: ${colors.dark500};
    &:hover {
      background-color: ${withOpacity(colors.purple, 0.15)};
    }
  `)}
  border-radius: 1em;
  padding: 1.25em;
  text-decoration: none;
  h3 {
    font-size: 1.6rem;
  }
  p {
    opacity: 0.8;
    line-height: 1.2;
  }
  ${PseudoLinkSpan} {
    color: ${colors.purple};
  }
`;

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  appearance: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: -0.25em;
  svg {
    display: block;
  }
  width: 1.25em;
  height: 1.25em;
  opacity: 0.5;
  border-radius: 0;
  &:hover {
    opacity: 1;
  }
`;

const storageKey = "hideLibraryStoryRequestPromptCard";

const LibraryStoryRequestPromptCard = () => {
  const openModal = useOpenStoryRequestModalFunction();
  const [hidden, setHidden] = useState(false);
  const hide = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setHidden(true);
    setSessionStorageItem(storageKey, "true");
  };
  useOnMount(() => {
    if (getSessionStorageItem(storageKey) === "true") {
      setHidden(true);
    }
    const urlParams = getUrlQueryParams<{
      modal?: string;
    }>();
    if (urlParams.modal === "request-story") {
      openModal();
    }
  });
  return hidden ? null : (
    <LibraryStoryRequestPromptCardWrap onClick={openModal}>
      <Header>
        <h3>Submit a story or an idea</h3>
        <CloseButton onClick={hide}>
          <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
            <path d="M8 1L1 8M1 1L8 8" stroke="currentColor" />
          </svg>
        </CloseButton>
      </Header>
      <Spacing size=".5em" />
      <p>We'd love to hear your ideas or see what you've created.</p>
      <Spacing size=".5em" />
      <PseudoLink>
        <strong>Get in touch →</strong>
      </PseudoLink>
    </LibraryStoryRequestPromptCardWrap>
  );
};

export default LibraryStoryRequestPromptCard;
