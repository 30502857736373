import { styled } from "@linaria/react";
import { UseStateReturnType } from "../../types/helper.types";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
} from "../../utils/array.utils";
import { colors } from "../../styles/colors.styles";
import { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useOnMount } from "../../utils/lifeCycle.utils";

type Option<D> = {
  value: string;
  data: D;
};

type Props<D> = {
  state: UseStateReturnType<string[]>;
  options: Option<D>[];
  LabelRenderer: React.FC<{
    options: Option<D>[];
    selection: string[];
  }>;
  OptionRenderer: React.FC<{
    option: Option<D>;
    selected: boolean;
  }>;
};

const MultiselectDropdownWrap = styled.div`
  position: relative;
`;
const LabelWrap = styled.div``;
const DropdownPositioner = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10000;
`;
const DropdownWrap = styled.div`
  border: 1px solid ${colors.purple50};
  background-color: ${colors.lightest};
  border-radius: 0 3px 3px 3px;
  overflow: hidden;
  min-width: 18em;
  max-height: min(80vh, 450px);
  overflow: auto;
`;
const OptionWrap = styled.div``;

function MultiselectDropdown<D>(props: Props<D>) {
  const [shouldRenderDropdown, setShouldRenderDropdown] = useState(false);
  const shouldRenderDropdownRef = useRef(shouldRenderDropdown);
  shouldRenderDropdownRef.current = shouldRenderDropdown;

  const [value, setValue] = props.state;
  const makeOptionClickHandler = (o: Option<D>) => () => {
    setValue(
      value.includes(o.value)
        ? removeOneFromArray(value, o.value)
        : addOneToArrayIfNew(value, o.value)
    );
  };
  const closeDropdown = () => {
    setShouldRenderDropdown(shouldRenderDropdown);
  };
  const handleLabelPointerDown = () => {
    if (!shouldRenderDropdownRef.current) {
      setShouldRenderDropdown(true);
    } else {
      setTimeout(() => {
        setShouldRenderDropdown(false);
      }, 50);
    }
  };
  useHotkeys("escape", () => {
    closeDropdown();
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const handlePointerDown = (e: PointerEvent) => {
      if (!shouldRenderDropdownRef.current) return;
      const clickedOutsideOfDropdown =
        e.target && !dropdownRef.current?.contains(e.target as HTMLDivElement);
      if (clickedOutsideOfDropdown)
        setTimeout(() => {
          closeDropdown();
        }, 50);
    };
    window.addEventListener("pointerdown", handlePointerDown, {
      capture: true,
    });
    return () => {
      window.removeEventListener("pointerdown", handlePointerDown, {
        capture: true,
      });
    };
  });
  return (
    <MultiselectDropdownWrap>
      <LabelWrap onPointerDown={handleLabelPointerDown}>
        <props.LabelRenderer options={props.options} selection={value} />
      </LabelWrap>
      {shouldRenderDropdown && (
        <DropdownPositioner ref={dropdownRef}>
          <DropdownWrap>
            {props.options.map((o, i) => (
              <OptionWrap key={i} onClick={makeOptionClickHandler(o)}>
                <props.OptionRenderer
                  option={o}
                  selected={value.includes(o.value)}
                />
              </OptionWrap>
            ))}
          </DropdownWrap>
        </DropdownPositioner>
      )}
    </MultiselectDropdownWrap>
  );
}

export default MultiselectDropdown;
