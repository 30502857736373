import { styled } from "@linaria/react";
import { cx } from "linaria";
import {
  BrandColorNameV4,
  CommonColorNameV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import { BrandThemeName, color, colors } from "../../styles/colors.styles";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";

export type PillProps = React.PropsWithChildren<{
  className?: string;
  fontSize?: string | number;
  color?:
    | BrandThemeName
    | BrandColorNameV4
    | CommonColorNameV4
    | "transparent"
    | "inherit";
  appearance?:
    | "filled-light"
    | "outlined"
    | "filled"
    | "filled-white"
    | "translucent";
}>;

export const PillTagStrong = styled.strong<PillProps>`
  display: inline-block;
  &.filled-light {
    border: 1px solid transparent;
    background-color: ${p => color(p.color ?? "purple", 50)};
    color: ${p => color(p.color ?? "purple", 600)};
  }
  &.outlined {
    background-color: "transparent";
    border: 1px solid currentColor;
    color: ${p => color(p.color ?? "inherit", 700)};
  }
  &.filled {
    background-color: ${p => color(p.color ?? "purple", 500)};
    border: 1px solid transparent;
    color: ${colors.lightest};
  }
  &.filled-white {
    background-color: ${colors.lightest};
    border: 1px solid transparent;
    color: ${p => color(p.color ?? "purple", 700)};
  }
  &.translucent {
    border: 1px solid transparent;
    background-color: ${p => withOpacity(color(p.color ?? "purple"), 0.2)};
    color: ${p => color(p.color ?? "purple", 500)};
  }
  font-size: ${p => p.fontSize ?? "12px"};
  ${fromTablet} {
    font-size: ${p => p.fontSize ?? "14px"};
  }
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5em 1em;
  letter-spacing: 0.05em;
  vertical-align: middle;
  line-height: 1;
`;

/** deprecated */
const PillLegacy = (props: PillProps) => {
  return (
    <PillTagStrong
      {...props}
      className={cx(
        "Pill",
        props.appearance ?? "filled-light",
        props.className
      )}
    >
      {props.children}
    </PillTagStrong>
  );
};

export default PillLegacy;
