import { lighten } from "polished";
import { CSSProperties } from "react";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import LinkToAnchor from "../../basic/LinkToAnchor";
import UptoDesktop from "../../mediaQueries/UptoDesktop";
import { Serif } from "../../typography/Serif";
import ReportNav from "../ReportNav";

const MHNav = () => {
  return (
    <ReportNav
      accentColor="orange"
      heading={[
        <>
          <Serif>State of</Serif>
          <span>Mental Health in</span>
          <span>Cybersecurity</span>
        </>,
      ]}
      headingFontSize="2.2rem"
    >
      <ul>
        <UptoDesktop>
          <li>
            <LinkToAnchor to="#" className="cover-link">
              <i></i>
              <span>Cover</span>
            </LinkToAnchor>
          </li>
        </UptoDesktop>
        <li>
          <LinkToAnchor to="#introduction">
            <i></i>
            <span>A word from Eoin Hinchy</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor to="#key-findings">
            <i></i>
            <span>Key findings</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor to="#methodology-and-participant-demographics">
            <i></i>
            <span>Methodology and participant demographics</span>
          </LinkToAnchor>
        </li>
      </ul>
      <hr />
      <ol>
        <li>
          <LinkToAnchor
            to="#general-physical-health"
            style={{ "--ThemeColorPrimary": colorsV4.purple } as CSSProperties}
          >
            <i></i>
            <span>General physical health</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#general-mental-health"
            style={{ "--ThemeColorPrimary": colorsV4.orange } as CSSProperties}
          >
            <i></i>
            <span>General mental health</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#mental-health-at-work"
            style={{ "--ThemeColorPrimary": colorsV4.pink } as CSSProperties}
          >
            <i></i>
            <span>Mental health at work</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#alcohol-and-drug-use"
            style={
              {
                "--ThemeColorPrimary": lighten(0.1, colorsV4.warmBlack),
              } as CSSProperties
            }
          >
            <i></i>
            <span>Alcohol and drug use</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor
            to="#employer-resources"
            style={{ "--ThemeColorPrimary": colorsV4.green } as CSSProperties}
          >
            <i></i>
            <span>Employer resources</span>
          </LinkToAnchor>
        </li>
      </ol>
      <hr />
      <ul>
        <li>
          <LinkToAnchor
            to="#prioritizing-mental-health-today"
            style={{ "--ThemeColorPrimary": colorsV4.green } as CSSProperties}
          >
            <i></i>
            <span>Prioritizing mental health today</span>
          </LinkToAnchor>
        </li>
      </ul>
    </ReportNav>
  );
};

export default MHNav;
