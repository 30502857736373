export const MiniPylons = () => (
  <svg
    width="19"
    height="29"
    viewBox="0 0 19 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_360_12635)">
      <path
        d="M9.5 2.5C9.5 1.39543 10.3954 0.5 11.5 0.5C12.6046 0.5 13.5 1.39543 13.5 2.5V30.5H9.5V2.5Z"
        fill="#C2AAFA"
        stroke="#6956A8"
      />
      <path
        d="M6.5 11.5C6.5 10.1193 7.61929 9 9 9C10.3807 9 11.5 10.1193 11.5 11.5V32H6.5V11.5Z"
        fill="#6956A8"
      />
      <path
        d="M5.5 10.5C5.5 9.39543 6.39543 8.5 7.5 8.5C8.60457 8.5 9.5 9.39543 9.5 10.5V30.5H5.5V10.5Z"
        fill="#C2AAFA"
        stroke="#6956A8"
      />
      <rect
        x="2.5"
        y="23.5"
        width="14"
        height="8"
        rx="1.5"
        fill="#C2AAFA"
        stroke="#6956A8"
      />
      <rect
        x="0.5"
        y="26.5"
        width="18"
        height="5"
        rx="1.5"
        fill="#C2AAFA"
        stroke="#6956A8"
      />
    </g>
    <defs>
      <clipPath id="clip0_360_12635">
        <rect width="19" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
