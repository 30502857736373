import { ReportV2PageLayout } from "../../components/reportsV2/components/ReportV2PageLayout";
import SOC2023HeroSection from "../../components/reportsV2/soc2023/SOC2023HeroSection";
import { SOC2023IntroSection } from "../../components/reportsV2/soc2023/SOC2023IntroSection";
import { SOC2023KeyFindingsSection } from "../../components/reportsV2/soc2023/SOC2023KeyFindingsSection";
import { SOC2023MethodologySection } from "../../components/reportsV2/soc2023/SOC2023MethodologySection";
import { SOC2023Nav } from "../../components/reportsV2/soc2023/SOC2023Nav";
import SEO from "../../components/utilities/SEO";
import { colors } from "../../styles/colors.styles";
import Page from "../../templates/Page.template";
import { PageComponentProps } from "../../types/gatsby.types";
import { SOC2023Chapter1 } from "../../components/reportsV2/soc2023/SOC2023Chapter1";
import {
  ReportV2HalfDarkSectionNavControlPoint,
  ReportV2NavPurpleControlPoint,
} from "../../components/reportsV2/components/ReportV2CommonNavControlPoints";
import { SOC2023Chapter2 } from "../../components/reportsV2/soc2023/SOC2023Chapter2";
import ReportV2PageContent from "../../components/reportsV2/components/ReportV2PageContent";
import { SOC2023Chapter3 } from "../../components/reportsV2/soc2023/SOC2023Chapter3";
import { SOC2023Chapter4 } from "../../components/reportsV2/soc2023/SOC2023Chapter4";
import { SOC2023Chapter5 } from "../../components/reportsV2/soc2023/SOC2023Chapter5";
import { SOC2023ActionableTakeawaysSection } from "../../components/reportsV2/soc2023/SOC2023ActionableTakeawaysSection";
import { SOC2023ConclusionSection } from "../../components/reportsV2/soc2023/SOC2023ConclusionSection";
import { SiteFooterBackdrop } from "../../components/sections/SiteFooter";
import { GridCanvas } from "../../components/decorations/GridCanvas";
import { css } from "linaria";
import {
  SiteNavThemeControllerPoint,
  recalcSiteNavThemeControllers,
} from "../../components/site/SiteNavThemeController";
import { PageThemeControllerPoint } from "../../components/site/PageThemeController";
import img from "../../../static/images/reports/soc2023/tines-voice-of-the-soc-2023.png";
import { styled } from "@linaria/react";
import {
  fromReportV2DesktopDesign,
  inReportV2MobileDesign,
} from "../../components/reportsV2/components/reportsV2.helpers";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";

const BottomSpacing = styled.div`
  height: 5em;
  ${fromTablet} {
    height: 14em;
  }
`;

const VoiceOfTheSoc2024 = (props: PageComponentProps) => {
  return (
    <Page
      {...props}
      textColor={colors.purple800}
      navThemeColor="purple"
      navLogoColor={colors.dark500}
      mobileNavBgColor={colors.purple}
      footerTextColor={colors.white}
    >
      <SEO
        title="Report: Voice of the SOC 2023"
        description="Discover insights from 900 security professionals across the globe. Understand the biggest challenges facing security teams today, and access research-based recommendations for leaders."
        image={img}
      />

      <ReportV2NavPurpleControlPoint />
      <PageThemeControllerPoint backgroundColor={colors.lightest} />

      <ReportV2PageContent>
        <SOC2023HeroSection />
        <ReportV2PageLayout nav={<SOC2023Nav />}>
          <ReportV2HalfDarkSectionNavControlPoint />

          <SOC2023IntroSection />
          <SOC2023KeyFindingsSection />

          <ReportV2NavPurpleControlPoint />

          <SOC2023MethodologySection />

          <SOC2023Chapter1 />
          <SOC2023Chapter2 />
          <SOC2023Chapter3 />
          <SOC2023Chapter4 />
          <SOC2023Chapter5 />

          <ReportV2NavPurpleControlPoint />

          <SOC2023ActionableTakeawaysSection />

          <ReportV2HalfDarkSectionNavControlPoint />

          <SOC2023ConclusionSection />
        </ReportV2PageLayout>
      </ReportV2PageContent>

      <BottomSpacing />

      <SiteFooterBackdrop>
        <GridCanvas
          backgroundColor={colors.purple900}
          color={colors.purple800}
          className={css`
            ${inReportV2MobileDesign} {
              svg {
                display: none;
              }
            }
            top: -14em;
            height: calc(100% + 14em);
            ${fromReportV2DesktopDesign} {
              top: -55em;
              height: calc(100% + 55em);
            }
          `}
          htmlChildren={() => (
            <SiteNavThemeControllerPoint
              navThemeColor="purple"
              colorNavAsDarkTheme
              navLogoColor={colors.white}
            />
          )}
          onReady={recalcSiteNavThemeControllers}
        />
      </SiteFooterBackdrop>
    </Page>
  );
};

export default VoiceOfTheSoc2024;
