export const VendorHouseIcon = () => (
  <svg
    width="79"
    height="81"
    viewBox="0 0 79 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.9492 61.781H15.2661V73.8709H68.7094L77.9492 61.781Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M65.413 22.1712L37.253 0.637695L9.0931 22.1712L1.34839 33.0134V73.8711H57.6672L65.413 63.0285V22.1712Z"
      fill="#D67C56"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M1.34839 33.0141V73.8727H57.6672V33.0141L29.5075 11.4812L1.34839 33.0141Z"
      fill="#FFD18C"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M29.5054 11.4805L37.2509 0.637695"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6653 33.0116L65.4111 22.1694"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M22.9769 40.0726C22.3565 34.9934 27.7994 30.1504 35.1372 29.2531C42.4736 28.3565 48.9235 31.7471 49.5432 36.8258C49.6163 37.419 49.6052 38.0096 49.518 38.5921L43.2871 45.9107C41.5557 46.7696 39.5532 47.3799 37.3845 47.645C30.048 48.5425 23.5979 45.153 22.9769 40.0726Z"
      fill="#FAF7F3"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.1165 39.8096C25.5928 43.7025 30.9678 46.2499 37.1222 45.4964C43.2759 44.7449 47.8791 40.9794 47.4025 37.0862C46.9268 33.1931 41.5515 30.6457 35.3979 31.3981C29.2433 32.15 24.6414 35.9164 25.1165 39.8096Z"
      fill="#EB81AF"
    />
    <path
      d="M49.5158 38.5904L43.2849 45.909C43.2849 45.909 42.7056 43.7022 44.9696 41.0036C47.3447 38.1717 49.5158 38.5904 49.5158 38.5904Z"
      fill="#BFBAB2"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.056 58.4633C45.8945 59.2582 43.7091 59.4184 43.4077 60.1399C43.0968 60.8822 44.5103 62.552 44.0688 63.2036C43.6211 63.8665 41.5419 63.1782 40.9793 63.7354C40.4122 64.296 41.0785 66.3806 40.4172 66.8201C39.7539 67.2579 38.0948 65.8296 37.3573 66.1314C36.6249 66.432 36.4456 68.6151 35.6588 68.7668C34.8872 68.9181 33.9025 66.9637 33.0974 66.9602C32.3163 66.9554 31.3133 68.904 30.5189 68.7414C29.7236 68.5796 29.5627 66.3948 28.8421 66.0929C28.0986 65.7829 26.4295 67.1955 25.7765 66.7547C25.1134 66.307 25.8025 64.2283 25.2459 63.6657C24.6857 63.0979 22.5997 63.7645 22.1612 63.1023C21.7219 62.4401 23.1507 60.7811 22.8495 60.0427C22.5485 59.3114 20.3662 59.1308 20.2138 58.3453C20.0632 57.5724 22.017 56.5878 22.0214 55.7831C22.0244 55.0023 20.0786 53.9995 20.2397 53.2047C20.4017 52.4094 22.5861 52.2483 22.8885 51.5274C23.1989 50.7849 21.7852 49.1151 22.2267 48.4628C22.6752 47.7989 24.7535 48.4871 25.3161 47.9322C25.8831 47.3714 25.2187 45.2866 25.88 44.8474C26.5418 44.409 28.2004 45.8371 28.939 45.535C29.67 45.2349 29.8508 43.0518 30.6358 42.8999C31.4092 42.7493 32.3916 44.7023 33.1976 44.7073C33.9791 44.7108 34.9826 42.7642 35.7775 42.9259C36.5727 43.0879 36.7332 45.2726 37.4534 45.574C38.1968 45.8848 39.866 44.4722 40.5172 44.9129C41.1823 45.3601 40.4945 47.4386 41.0505 48.0014C41.6111 48.5681 43.6949 47.9027 44.1344 48.5657C44.5727 49.2274 43.1439 50.8861 43.4462 51.6233C43.7461 52.3555 45.9297 52.5355 46.0825 53.3226C46.2329 54.0947 44.2798 55.0782 44.2754 55.8827C44.2706 56.6646 46.218 57.6672 46.056 58.4633Z"
      fill="#FAF7F3"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M42.3412 57.7084C42.2263 58.2749 40.6685 58.3901 40.454 58.9025C40.2338 59.4323 41.2394 60.6224 40.9251 61.087C40.6055 61.5589 39.1244 61.0683 38.7244 61.4654C38.3203 61.8647 38.7949 63.3502 38.3229 63.6625C37.851 63.9747 36.668 62.9583 36.1437 63.1731C35.6225 63.3867 35.4933 64.9414 34.9343 65.0513C34.3828 65.1581 33.6823 63.7661 33.1095 63.763C32.5523 63.7595 31.8374 65.1473 31.2709 65.032C30.7037 64.9166 30.5905 63.3598 30.0756 63.1453C29.5463 62.9233 28.3572 63.9311 27.8925 63.6168C27.4201 63.2983 27.9109 61.8163 27.514 61.4157C27.1143 61.0116 25.6281 61.486 25.3166 61.0134C25.0038 60.5414 26.0224 59.3605 25.8067 58.8342C25.5924 58.3135 24.0375 58.1845 23.9285 57.6244C23.8212 57.0745 25.2128 56.3729 25.2159 55.7994C25.219 55.2425 23.8328 54.5284 23.9473 53.9621C24.0634 53.3952 25.6191 53.2818 25.8339 52.7669C26.0552 52.2376 25.0484 51.0481 25.363 50.583C25.6828 50.1106 27.1631 50.6014 27.5641 50.2045C27.9686 49.8056 27.4941 48.3197 27.966 48.0069C28.4371 47.6952 29.6196 48.7131 30.1444 48.4977C30.6658 48.2834 30.7947 46.7286 31.3551 46.6187C31.9055 46.5116 32.6055 47.9038 33.1795 47.9078C33.7371 47.9108 34.4503 46.523 35.0172 46.6379C35.5842 46.7542 35.6987 48.3101 36.2122 48.5257C36.7411 48.7462 37.9311 47.7394 38.3969 48.054C38.868 48.3721 38.379 49.8542 38.775 50.2551C39.174 50.6589 40.6591 50.1854 40.9721 50.6565C41.2847 51.1287 40.2666 52.3103 40.4818 52.8357C40.6954 53.3578 42.2503 53.4856 42.36 54.0451C42.4668 54.5954 41.0761 55.2966 41.0724 55.8699C41.0682 56.4281 42.4572 57.1415 42.3412 57.7084Z"
      fill="#8173E6"
    />
    <path
      d="M29.5367 45.6552L23.9699 42.3581L25.4642 41.8202C26.4129 41.4794 26.5049 40.7393 25.6695 40.1772L14.7476 32.8184L10.2088 37.5727L7.5042 46.7041C7.21743 47.671 7.7601 48.1833 8.70863 47.842L10.2033 47.3048L8.00966 53.3918C7.63314 54.4342 8.24608 54.9569 9.36973 54.5535L16.2576 52.078L17.8421 56.4879L23.9244 54.3033L22.3391 49.8924L29.2274 47.4173C30.3506 47.0137 30.4901 46.2208 29.5367 45.6552Z"
      fill="#FAF7F3"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.598 45.7264L20.3357 42.6088L22.6868 41.7645C23.3571 41.5235 23.423 40.9994 22.831 40.6006L14.3221 34.8677C13.7309 34.4687 13.0796 34.7034 12.8769 35.3874L9.96325 45.224C9.76142 45.9089 10.1449 46.271 10.8166 46.0295L13.167 45.1852L11.0935 50.938C10.84 51.6406 11.253 51.9929 12.0098 51.721L17.2546 49.836L18.6578 53.7445L21.5485 52.7056L20.1448 48.7973L25.3879 46.9132C26.1462 46.6407 26.2396 46.1072 25.598 45.7264Z"
      fill="#49B889"
    />
    <path
      d="M10.2092 37.5713C10.2092 37.5713 12.5471 37.9253 13.863 36.5055C15.2756 34.9824 14.6697 33.1057 14.6697 33.1057L10.2092 37.5713Z"
      fill="#BFBAB2"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M76.8476 76.9724C76.8476 78.7332 71.1254 80.16 64.0641 80.16C57.0041 80.16 51.2815 78.733 51.2815 76.9724C51.2815 75.2111 57.0041 73.7832 64.0641 73.7832C71.1254 73.783 76.8476 75.2111 76.8476 76.9724Z"
      fill="#4D3E78"
    />
    <path
      d="M66.8755 74.5568L63.7517 72.6046V49.173C63.8248 49.0861 57.8148 52.5911 57.8148 52.5911V72.6046L53.9885 74.9617C52.0547 76.2018 50.8938 76.7922 50.8938 77.2926C50.8938 78.5213 55.3208 79.5173 60.7833 79.5173C66.2455 79.5173 70.6729 78.5213 70.6729 77.2926C70.6731 76.7922 68.6417 75.5506 66.8755 74.5568Z"
      fill="#B35980"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M57.7297 69.7915C58.645 69.9456 59.6806 70.0338 60.7776 70.0338C61.8947 70.0338 62.9474 69.9425 63.8745 69.7829V65.8215C62.9474 65.6617 61.8944 65.571 60.7776 65.571C59.6806 65.571 58.645 65.6584 57.7297 65.8127V69.7915Z"
      fill="#4D3E78"
    />
    <path
      d="M68.7777 57.1514C69.3309 56.7265 69.6418 56.2501 69.6418 55.7486C69.6418 54.8082 68.5553 52.2574 66.8174 50.0331C67.3122 49.7306 67.5943 49.386 67.5943 49.0205C67.5943 47.8095 62.5286 39.5701 60.7436 39.5701C58.9589 39.5701 53.8938 47.8095 53.8938 49.0205C53.8938 49.386 54.1747 49.7306 54.6703 50.0338C52.9324 52.2574 51.8464 54.8085 51.8464 55.7486C51.8464 56.2501 52.1564 56.7265 52.7096 57.1509C50.8088 59.7688 49.6414 62.5893 49.6414 63.6781C49.6414 65.9251 54.6121 67.7471 60.7436 67.7471C66.8757 67.7471 71.8464 65.9251 71.8464 63.6781C71.8464 62.5891 70.6792 59.7688 68.7777 57.1514Z"
      fill="#FABE64"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
  </svg>
);
