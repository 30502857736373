import { styled } from "@linaria/react";
import { ValidInArticleStoryGrid } from "../../utils/datocms.utils";
import SmartLink from "../basic/SmartLink";
import { useState } from "react";
import { DatoCmsStoryDigest } from "../../../graphql-types";
import { convertDatoRecordToStoryDescriptor } from "../../utils/story.utils";
import { rSize } from "../../styles/responsiveSizes.styles";
import { cx } from "linaria";
import {
  fromDesktopMl,
  fromDesktopSm,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import StoryEntry from "../library/StoryEntry";
import { useOnMount } from "../../utils/lifeCycle.utils";
import axios from "axios";
import { StoryDescriptor } from "../../types/helper.types";
import { uniqBy } from "lodash-es";
import LoadingIndicator from "../utilities/LoadingIndicator";
import { colors, withOpacity } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import InfoBox from "../general/InfoBox";

type Props = {
  record: ValidInArticleStoryGrid;
};

const InArticleStoryGridWrap = styled.div`
  &.withHeader {
    * + & {
      &:before {
        content: "";
        background-color: currentColor;
        opacity: 0.075;
        height: 2px;
        display: block;
        margin: ${rSize("xl")} 0;
      }
    }
    &:not(:last-child) {
      &:after {
        content: "";
        background-color: currentColor;
        opacity: 0.075;
        height: 2px;
        display: block;
        margin: ${rSize("xl")} 0;
      }
    }
  }
`;

const Header = styled.header`
  display: grid;
  ${onlyPhones} {
    grid-template-areas: "image" "headerInner";
  }
  ${fromTablet} {
    grid-template-columns: minmax(66.67%, 1fr) minmax(33.33%, auto);
    grid-template-areas: "headerInner image";
  }
  align-items: center;
  + * {
    margin-top: ${rSize("lg")};
  }
`;

const HeaderInner = styled.div`
  grid-area: headerInner;
  h3 {
    font-size: 2.4rem;
    ${fromTablet} {
      font-size: 2.8rem;
    }
    line-height: 1.1;
    + p {
      margin-top: 1em;
    }
  }
`;

const Preheading = styled.p`
  b {
    font-weight: 600;
  }
`;

const ImageWrap = styled.div`
  grid-area: image;
  ${fromTablet} {
    padding-left: ${rSize("lg")};
  }
  padding-bottom: 1em;
  a {
    display: block;
    text-decoration: none;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromDesktopSm} {
    grid-template-columns: 1fr 1fr;
  }
  ${fromDesktopMl} {
    > * {
      aspect-ratio: 1/1;
    }
  }
`;

const LoadingIndicatorWrap = styled.div`
  background-color: ${colors.purple50};
  border: 2px solid ${colors.purple100};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.purple800, 0.5)};
     border-color: ${withOpacity(colors.purple800, 0.8)};`
  )}
  color: ${colors.purple};
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rSize("radius")};
`;

const InArticleStoryGrid = (props: Props) => {
  const { record } = props;
  const [ready, setReady] = useState(
    !props.record.collection?.id && !props.record.tool?.id
  );
  const [stories, setStories] = useState<StoryDescriptor[]>([
    ...((record.stories ?? []) as DatoCmsStoryDigest[]).map(
      convertDatoRecordToStoryDescriptor
    ),
  ]);
  const [error, setError] = useState("");
  useOnMount(() => {
    if (ready) return;
    (async function getDynamicLatestStories() {
      try {
        const storiesFetched = [];
        const numOfStoriesToFetch =
          (record.maxNumberOfStories ?? 4) - (record.stories?.length ?? 0);
        if (numOfStoriesToFetch === 0) return;
        const numOfStoriesFromCollections = !props.record.collection?.id
          ? 0
          : props.record.tool?.id
          ? Math.round(numOfStoriesToFetch / 2)
          : numOfStoriesToFetch;
        const numOfStoriesFromTools =
          numOfStoriesToFetch - numOfStoriesFromCollections;
        if (numOfStoriesFromCollections > 0) {
          const latestStoriesFromCollection = record.collection?.id
            ? (
                await axios.get<StoryDescriptor[]>(
                  `/api/library/collection?id=${record.collection.id}&count=${numOfStoriesFromCollections}`
                )
              ).data
            : [];
          storiesFetched.push(...latestStoriesFromCollection);
        }
        if (numOfStoriesFromTools > 0) {
          const latestStoriesFromTool = record.tool?.id
            ? (
                await axios.get<StoryDescriptor[]>(
                  `/api/library/tool?slug=${record.tool.slug}&count=${numOfStoriesFromTools}`
                )
              ).data
            : [];
          storiesFetched.push(...latestStoriesFromTool);
        }
        setStories(uniqBy([...stories, ...storiesFetched], e => e.id));
      } catch (e) {
        setError(
          "Error loading stories. To try again, please reload the page."
        );
      } finally {
        setReady(true);
      }
    })();
  });
  const img = record.image?.url ? (
    <img
      src={record.image.url}
      style={{
        aspectRatio: `${record.image.width}/${record.image.height}`,
      }}
    />
  ) : null;
  return (
    <InArticleStoryGridWrap
      className={cx("InArticleStoryGrid", record.heading && "withHeader")}
    >
      {(record.preheading || record.heading) && (
        <Header>
          <HeaderInner>
            {record.preheading && (
              <Preheading>
                <b>{record.preheading}</b>
              </Preheading>
            )}
            <h3>{record.heading}</h3>
            {record.description && <p>{record.description}</p>}
            {record.linkTextLabel && record.linkUrl && (
              <p>
                <SmartLink src={record.linkUrl}>
                  {record.linkTextLabel}
                </SmartLink>
              </p>
            )}
          </HeaderInner>
          {record.image?.url && (
            <ImageWrap>
              {record.linkUrl ? (
                <SmartLink src={record.linkUrl}>{img}</SmartLink>
              ) : (
                img
              )}
            </ImageWrap>
          )}
        </Header>
      )}
      {error ? (
        <InfoBox color="orange">{error}</InfoBox>
      ) : ready ? (
        <Grid>
          {stories.map(s => (
            <StoryEntry story={s} key={s.id} />
          ))}
        </Grid>
      ) : (
        <LoadingIndicatorWrap>
          <LoadingIndicator size={24} />
        </LoadingIndicatorWrap>
      )}
    </InArticleStoryGridWrap>
  );
};

export default InArticleStoryGrid;
