import { styled } from "@linaria/react";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";
import gfm from "remark-gfm";
import shortid from "shortid";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { resolveAfter, when } from "../../../utils/promises.utils";
import { NoteOrAssetRenderStateObject } from "../NoteEntry";

import Code from "./Code";
import Image from "./Image";
import MarkdownLink from "./Link";
import Video from "./Video";

interface Props {
  markdown: string;
  onLoad?: () => Promise<void>;
}

const Styles = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  overflow-wrap: break-word;

  h1 {
    font-size: 13px;
    font-weight: 500;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 11px;
    font-weight: 500;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }

  p,
  img {
    margin-bottom: 16px;
  }

  p:last-child,
  img:last-child,
  *:only-child {
    margin-bottom: 0;
  }

  pre {
    background-color: ${withOpacity(colorsV4.grey, 0.05)};
    border: 1px solid ${withOpacity(colorsV4.grey, 0.05)};
    border-radius: 5px;
    padding: 10px;
    max-height: 100%;
    overflow: hidden;

    // We'd like a border-radius here but applying
    // one triggers a bizarre Chrome rendering bug that
    // results in blurred text rendering in the parent.
    // See #6196 for more context.
    // border-radius: 4px;
  }

  img {
    border-radius: 4px;
  }

  img {
    pointer-events: none;
  }

  img,
  iframe {
    width: 100%;
  }

  ul,
  ol {
    padding-inline-start: 22px;
  }

  li.task-list-item {
    list-style-type: none;

    input {
      margin-left: -20px;
      margin-right: 4px;
      vertical-align: middle;
    }
  }
`;

export default function Markdown({ markdown, onLoad }: Props) {
  const [assetRenderStates] = useState<NoteOrAssetRenderStateObject[]>([]);
  const createRenderStateObject = (target?: string) => {
    const s = {
      id: shortid(),
      target,
      state: "pending",
    } as NoteOrAssetRenderStateObject;
    assetRenderStates.push(s);
    return s;
  };
  // A custom anchor component that attempts to render the anchor as
  // an image, then a video and falls back to rendering as an anchor.
  const [AutoAnchor] = useState(
    () =>
      function AutoAnchor({ href, ...props }: JSX.IntrinsicElements["a"]) {
        const [stateObject] = useState(() => createRenderStateObject(href));
        const handleComplete = () => {
          stateObject.state = "done";
        };
        if (!href) handleComplete();
        return href ? (
          <Image
            url={href}
            onLoad={handleComplete}
            onError={handleComplete}
            fallback={
              <Video
                url={href}
                onLoad={handleComplete}
                fallback={<MarkdownLink href={href} {...props} />}
              />
            }
          />
        ) : null;
      }
  );

  // A custom image component that attempts to render the image as
  // a video and falls back to rendering as an image.
  const [AutoImage] = useState(
    () =>
      function AutoImage({ src, ...props }: JSX.IntrinsicElements["img"]) {
        const [stateObject] = useState(() => createRenderStateObject(src));
        const handleComplete = () => {
          stateObject.state = "done";
        };
        if (!src) handleComplete();
        return src ? (
          <Video
            url={src}
            onLoad={handleComplete}
            fallback={
              <Image
                url={src}
                {...props}
                onLoad={handleComplete}
                onError={handleComplete}
              />
            }
          />
        ) : null;
      }
  );

  useOnMount(() => {
    (async () => {
      await resolveAfter(100);
      await when(() => assetRenderStates.every(r => r.state === "done"));
      onLoad?.();
    })();
  });

  return (
    <Styles>
      <ReactMarkdown
        components={{ a: AutoAnchor, img: AutoImage, code: Code }}
        remarkPlugins={[gfm, breaks]}
        linkTarget="_blank"
      >
        {markdown}
      </ReactMarkdown>
    </Styles>
  );
}
