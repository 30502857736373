import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import Spacing from "../layout/Spacing";
import { graphql, useStaticQuery } from "gatsby";
import {
  DatoCmsContentCard,
  HomeFeaturedContentCardsQuery,
} from "../../../graphql-types";
import { ContentCardDispatcher } from "../contentCards/_ContentCardDispatcher";
import {
  fromDesktopSm,
  fromPhoneLg,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

const BackdropLayer = styled.div`
  position: absolute;
  top: 15rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.purple600};
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.5em;
  ${fromPhoneLg} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopSm} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .ContentCard {
    &.large {
      min-height: 22.5rem;
      ${fromPhoneLg} {
        min-height: 32rem;
      }
      ${fromTablet} {
        min-height: 40rem;
      }
    }
    &[data-size="2/3"] {
      grid-column-end: span 2;
    }
    ${fromDesktopSm} {
      &[data-size="1/3"] {
        grid-column-end: span 4;
      }
      &[data-size="1/2"] {
        grid-column-end: span 6;
      }
      &[data-size="2/3"] {
        grid-column-end: span 8;
      }
    }
  }
`;

const HomeContentGridSection = () => {
  const data = useStaticQuery<HomeFeaturedContentCardsQuery>(
    homeFeaturedContentCardsQuery
  );
  return (
    <PageSection relative before={<BackdropLayer />}>
      <Inner>
        {data.home?.featuredContentCards?.map((card, i) => (
          <ContentCardDispatcher
            card={card as DatoCmsContentCard}
            key={i}
            large
          />
        ))}
      </Inner>
      <Spacing size="sectionMargin" />
    </PageSection>
  );
};

export const homeFeaturedContentCardsQuery = graphql`
  query HomeFeaturedContentCards {
    home: datoCmsHomepage {
      featuredContentCards {
        preset
        linkToRecord {
          ... on DatoCmsArticle {
            id: originalId
            slug
            title
            model {
              apiKey
            }
          }
          ... on DatoCmsWebinar {
            id: originalId
            slug
            title
            colorTheme
            category {
              slug
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsCaseStudy {
            id: originalId
            color
            slug
            title
            customer {
              logoDarkGray {
                url
                width
                height
              }
              logoWhite {
                url
                width
                height
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsPodcast {
            id: originalId
            applePodcastsLink
            color
            episodeNumber
            title
            spotifyLink
            season {
              seasonNumber
              title
            }
            guestSpeaker {
              name
              surname
              title
              organization {
                name
              }
            }
            guestSpeakerImageTransparent {
              url
              width
              height
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsDocument {
            id: originalId
            title
            slug
            description {
              value
            }
            category {
              name
              slug
            }
            previewImage {
              url
              width
              height
            }
            previewImageBackgroundColor {
              hex
            }
            previewImageShadowColor {
              hex
            }
            model {
              apiKey
            }
          }
        }
        linkLabel
        linkUrl
        preHeadingTag
        heading
        description
        size
        color
        colorMode
        backgroundImage {
          url
        }
        icon {
          url
          width
          height
        }
      }
    }
  }
`;

export default HomeContentGridSection;
