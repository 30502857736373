import { styled } from "@linaria/react";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingLarge } from "../components/ReportV2SectionHeadingLarge";
import { CompassIcon } from "./icons/CompassIcon";
import Spacing from "../../layout/Spacing";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { reportV2BodyTextParagraphStyle } from "../components/ReportV2TextBlock";
import { PageThemeControllerPoint } from "../../site/PageThemeController";
import { colors } from "../../../styles/colors.styles";
import { serif } from "../../../styles/fonts.styles";
import {
  fromPhoneLg,
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const Header = styled.header`
  position: relative;
  p {
    ${reportV2BodyTextParagraphStyle}
    max-width: 40em;
  }
`;

const HeadingAndIconGroup = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    grid-template-areas: "icon" "text";
  }
  ${fromPhoneLg} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "text icon";
  }
  align-items: end;
  h2 {
    max-width: 10em;
  }
`;

const HeadingWrap = styled.div`
  grid-area: text;
`;
const IconWrap = styled.div`
  grid-area: icon;
`;

const List = styled.div`
  section {
    + section {
      &:before {
        content: "";
        display: block;
        height: 1px;
        margin: ${rSize("xl")} 0;
        background-color: ${colors.purple200};
      }
    }
    header {
      p,
      h3 {
        font-size: 2.4rem;
      }
      p {
        font-family: ${serif};
      }
      > * {
        + * {
          margin-top: 0.25em;
        }
      }
    }
    > div {
      margin-top: 1.5em;
      ${fromTablet} {
        columns: 2;
        column-gap: ${rSize("gap")};
      }
      p {
        ${fromTablet} {
          max-width: 32rem;
        }
        ${reportV2BodyTextParagraphStyle}
        break-inside: avoid;
      }
    }
  }
`;

export const SOC2023ActionableTakeawaysSection = () => {
  return (
    <ReportV2Section id="actionable-takeaways">
      <PageThemeControllerPoint backgroundColor={colors.purple50} />
      <Header>
        <HeadingAndIconGroup>
          <HeadingWrap>
            <ReportV2SectionHeadingLarge>
              Actionable takeaways for leaders
            </ReportV2SectionHeadingLarge>
          </HeadingWrap>
          <IconWrap>
            <CompassIcon />
          </IconWrap>
        </HeadingAndIconGroup>
        <Spacing size="lg" />
        <p>
          The 2023 Voice of the SOC found that security teams continue to
          experience burnout amid relentless cyberattacks, internal pressures,
          and limited resources. Security professionals want to pursue
          high-impact work, but they’re being held back by growing workloads,
          shrinking budgets, and a worsening skills shortage. The findings are
          consistent: regardless of location, company size, in-person or remote,
          security professionals are feeling the pressure and are looking for an
          escape hatch. Limited resources and increasing external threats will
          continue to pose problems for the foreseeable future. To that end,
          here are four actionable takeaways that can help SOC teams stay ahead
          of the challenge.
        </p>
      </Header>
      <Spacing size="xl" />
      <List>
        <section>
          <header>
            <p>#1</p>
            <h3>Make more out of your resources</h3>
          </header>
          <div>
            <p>
              Organizations large and small are facing the pressures of a down
              economy, with many teams adjusting to hiring freezes or reductions
              in force. Meanwhile, security threats are only increasing, leaving
              smaller teams left to tackle a growing problem. There is good
              news: the most monotonous tasks in a SOC analyst’s day are also
              those that can be automated most easily.
            </p>
            <p>
              The greatest challenges security practitioners face on a regular
              basis include too much data and not enough information, too much
              time spent communicating, and too many reporting requirements.
              Automation can solve many of the most repetitive and error-prone
              aspects of data collection, communication, and reporting,
              including building workflows across systems and business units.
              Unique workflow builds can automate internal and external
              communications tasks, as well as data enrichment and reporting,
              increasing a team’s productivity and freeing up SOC analysts to
              focus on more valuable work.
            </p>
          </div>
        </section>

        <section>
          <header>
            <p>#2</p>
            <h3>Tackle burnout at the source</h3>
          </header>
          <div>
            <p>
              Nearly two-thirds (63%) of survey respondents indicated they were
              burnt out, and this ongoing problem often leads directly to
              employee churn. More than half (53%) of respondents said the most
              frustrating aspect of their work was spending time on manual
              tasks. Organizations can’t afford to ignore the problem of
              burnout. Otherwise, they’ll risk greater consequences when they
              have to replace valuable team members.
            </p>
            <p>
              The only way to alleviate burnout is by increasing resources, and
              SOCs have two options: hire more staff or adopt better tools.
              Increasing the size of the team will naturally spread out the
              workload. However, advanced tools and automation can effectively
              increase the productivity of each employee without having to
              invest in new hires. If they didn’t have to spend as much time on
              manual tasks, security practitioners say they’d develop advanced
              detection tools, integrate more systems and logs, and research new
              tools that could improve their organization’s security posture.
            </p>
          </div>
        </section>

        <section>
          <header>
            <p>#3</p>
            <h3>Prioritize retention to avoid the skills shortage</h3>
          </header>
          <div>
            <p>
              The cybersecurity industry continues to labor under a significant
              skills shortage: there simply aren’t enough qualified
              professionals to meet the needs of today’s organizations.
              Minimizing employee churn is mission critical. If a highly skilled
              employee leaves, it will be difficult — and expensive — to replace
              them.
            </p>
            <p>
              More than nine in 10 respondents (93%) said that automation in
              their workplace would improve their work-life balance. Whether by
              paying them more or making their jobs easier, organizations need
              to do what it takes to keep SOC analysts happy and onboard.
            </p>
          </div>
        </section>

        <section>
          <header>
            <p>#4</p>
            <h3>Identify ways to break down silos</h3>
          </header>
          <div>
            <p>
              Our survey respondents consistently pointed to communication and
              data collection as pain points in their day-to-day work, and these
              challenges are exacerbated by silos between departments and
              business units. Organizations can make life easier for their SOC —
              and improve security outcomes in the process — by streamlining
              workflows between departments.
            </p>
            <p>
              Smart, secure workflow automation can effectively break down
              silos, simplifying communication and making data easier to access
              and act on. With simple interfaces and a low technological barrier
              to entry, SOC teams can quickly adapt to the new platforms and
              streamline their operations.
            </p>
          </div>
        </section>
      </List>
    </ReportV2Section>
  );
};
