import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import { colors } from "../../../styles/colors.styles";
import Spacing from "../../layout/Spacing";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import {
  ReportV2FullDarkSectionNavControlPoint,
  ReportV2NavPinkControlPoint,
} from "../components/ReportV2CommonNavControlPoints";
import ReportV2ContentCard, {
  ReportV2ContentCardGrid,
} from "../components/ReportV2ContentCard";
import { ReportV2Heading5 } from "../components/ReportV2Heading5";
import { ReportV2LargePrint } from "../components/ReportV2LargePrint";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import CisoAILineStylePercentageChart from "./CisoAILineStylePercentageChart";
import { BrokenRobotIcon } from "./icons/BrokenRobotIcon";

export const CisoAIChapter1 = () => {
  return (
    <section>
      <ReportV2FullDarkSectionNavControlPoint />

      <ReportV2ChapterHeaderSection
        id="satisfaction-with-ai-adoption"
        color="pink"
        chapterNumber={1}
        icon={<BrokenRobotIcon />}
      >
        <h2>
          <span>Satisfaction with</span> <span>AI adoption</span>
        </h2>
        <ReportV2TextBlock largerText>
          <Spacing size=".125em" />
          <p>
            One of the primary objectives of our survey was to gauge CISOs’
            satisfaction with their teams’ progress in adopting AI technologies.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2NavPinkControlPoint />

      <ReportV2Section
        color={colors.pink800}
        backgroundColor={colors.pink100}
        accentColor={colors.pink600}
      >
        <ReportV2LargePrint>
          <p>
            An impressive 83% of respondents reported a generally positive
            outlook on their team’s progress in adopting AI with 28% being very
            satisfied. But there is still a clear desire to tackle the various
            challenges and concerns associated with AI adoption.
          </p>
        </ReportV2LargePrint>

        <Spacing size="lg" />

        <ReportV2Heading5>
          Increasing pressure on security teams
        </ReportV2Heading5>

        <Spacing size="lg" />

        <ReportV2ContentCardGrid>
          <ReportV2ContentCard largerText alignTop>
            <CisoAILineStylePercentageChart
              total={53}
              value={50}
              color={colors.pink600}
            />
            <p>
              A combined total of 94% of respondents expressed some level of
              concern the pressure on their security team will increase as AI
              adoption grows within their organization. Overall, many indicated
              data privacy concerns (66%) and inflexible technologies (49%) as
              challenges faced by their teams.
            </p>
          </ReportV2ContentCard>
          <ReportV2ContentCard largerText alignTop>
            <CisoAILineStylePercentageChart
              total={53}
              value={33}
              color={colors.pink600}
            />
            <p>
              With 63% of analysts reporting burnout in our{" "}
              <a href="/reports/voice-of-the-soc-2023" target="_blank">
                Voice of the SOC 2023
              </a>{" "}
              report, and more pressure on the horizon from AI, these responses
              highlight the need for additional resources, training, and
              planning to ensure that security teams are equipped to
              successfully implement AI technologies and remove bottlenecks.
            </p>
          </ReportV2ContentCard>
        </ReportV2ContentCardGrid>

        <Spacing size="lg" />

        <ReportV2TextBlock largerText limitWidth columned>
          <p>
            It’s been reported that the immense pressure of the job has led to
            many{" "}
            <a
              href="https://www.sdxcentral.com/articles/analysis/the-great-ciso-resignation-why-security-leaders-are-quitting-in-droves/2023/05/"
              {...externalLinkAttr}
            >
              CISOs resigning
            </a>
            . To alleviate additional burdens, CISOs should involve their teams
            in decision-making when exploring new technologies.
          </p>

          <p>
            Misaligned priorities are one of the challenges we’ll explore next.
            A collaborative approach can bridge knowledge gaps and ensure a
            successful implementation plan.
          </p>
        </ReportV2TextBlock>
      </ReportV2Section>
    </section>
  );
};
