import { styled } from "@linaria/react";
import { useState } from "react";
import { useSiteContext } from "../../context/site.context";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Button from "../forms/Button";
import Spacing from "../layout/Spacing";
import Textarea from "../forms/Textarea";
import TextInput, { TextInputSet } from "../forms/TextInput";
import Modal, {
  ModalBackdrop,
  ModalCloseButton,
  useOpenModalFunction,
} from "../modals/Modal";
import { css, cx } from "linaria";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { darken } from "polished";
import PseudoLink from "../basic/PseudoLink";
import FileUploader, {
  UploadedFileMeta,
  fileUploaderBase64UploadHandler,
} from "../basic/FileUploader";
import InfoBox from "../general/InfoBox";
import { openIntercomMessenger } from "../../utils/intercom.utils";
import SpaceChildren from "../layout/SpaceChildren";
import { AlignCenter } from "../typography/Align";
import { YDWWT_SUBMISSION_MODAL_NAME } from "../modals/modalNames";
import YDWWTWhatSticker from "./YDWWTWhatSticker";
import { serif } from "../../styles/fonts.styles";
import { DatoCmsYdwwt } from "../../../graphql-types";
import Multiselect, { MultiselectWrapper } from "../forms/Multiselect";
import { Paths } from "../../utils/pathBuilders.utils";
import { Link } from "gatsby";
import { getFormMetaInfo } from "../../utils/form.utils";

const WhatWrapper = styled.div`
  font-size: 3.8rem;
  ${fromTablet} {
    font-size: 4.6rem;
  }
`;

const ModalInner = styled.div`
  background-color: ${colors.dark800};
  padding: ${rSize("cardSectionPadding")};
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  font-size: 1.6rem;
  h2 {
    font-size: 2.4rem;
    line-height: 1.25;
    font-family: ${serif};
    font-weight: 400;
    ${fromTablet} {
      font-size: 3.2rem;
    }
  }
  img {
    margin-top: -1.5em;
    align-self: center;
  }
  p {
    max-width: 33em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ConsentSelector = styled.div`
  background-color: ${colors.dark900};
  text-align: left;
  border: 1px solid ${colors.dark500};
  border-radius: 0.5em;
  font-size: 1.4rem;
  font-weight: 500;
`;
const ConsentQuestion = styled.div`
  padding: 1em;
  font-weight: 600;
`;
const ConsentOptionSeparator = styled.div`
  border-bottom: 1px solid ${colors.dark500};
`;

const ConsentOptionCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  border-radius: 3px;
  border: 1px solid ${colors.dark500};
  background-color: ${colors.darkest};
  &:before {
    content: "";
    display: none;
    aspect-ratio: 11/9;
    width: 11px;
    max-width: 75%;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.34783L4.40541 7L10 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: contain;
  }
`;

const ConsentOption = styled.button`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-gap: 0.5em;
  align-items: center;
  appearance: none;
  width: 100%;
  background-color: transparent;
  text-align: left;
  color: inherit;
  border: 0;
  padding: 1em;
  border-radius: 0;
  &.selected {
    background-color: ${colors.purple700};
    ${ConsentOptionCheckbox} {
      background-color: ${colors.purple};
      border-color: ${colors.purple800};
      &:before {
        display: block;
      }
    }
  }
`;

const CategorySelectionSection = styled.div`
  header {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-gap: 1em;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 600;
    > div {
      max-width: 28em;
    }
    button {
      appearance: none;
      background-color: transparent;
      border-radius: 0;
      color: ${colors.purple};
      font-weight: 700;
      border: 0;
      padding: 0;
      cursor: pointer;
      &:hover {
        color: ${colors.purple300};
      }
    }
  }
  ${MultiselectWrapper} {
    border: 1px solid ${colors.dark500};
    background-color: ${colors.dark900};
    font-size: 1.4rem;
  }
`;

const Footnote = styled.p`
  text-align: left;
  font-size: 1.4rem;
  color: ${withOpacity(colors.white, 0.7)};
  font-weight: 500;
  margin: 1em auto 0 0 !important;
  max-width: unset !important;
  a {
    color: ${colors.white};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const submissionUrl =
  "https://hq.tines.io/webhook/e3dd3bf6179fe118e6fbd6dbdc6d9bc1/54465419a57e6621fbefb8230bff1062";

const SuccessScreen = () => {
  const siteContext = useSiteContext();
  const closeModal = () => {
    siteContext.openModal(null);
  };
  return (
    <AlignCenter>
      <Spacing size="1em" />
      <h3>Thanks for submitting! We will be in touch.</h3>
      <Spacing size="2em" />
      <Button onClick={closeModal} width="8em">
        Close
      </Button>
    </AlignCenter>
  );
};

export const useOpenYDWWTSubmissionModalFunction = () =>
  useOpenModalFunction(YDWWT_SUBMISSION_MODAL_NAME);

const YDWWTSubmissionModal = (props: { ydwwt: DatoCmsYdwwt }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const formState = useState({
    name: "",
    email: "",
    company: "",
    videoLink: "",
    consent: null as null | boolean,
    description: "",
    categories: [] as string[],
  });
  const [form] = formState;
  const [attachmentFileType, setAttachmentFileType] = useState("");
  const [attachmentFileName, setAttachmentFileName] = useState("");
  const [attachmentBase64, setAttachmentBase64] = useState("");

  const handleFileUpload = (meta: UploadedFileMeta) => {
    setAttachmentFileType(meta.type);
    setAttachmentFileName(meta.fileName);
    setAttachmentBase64(meta.base64 ?? meta.url);
  };
  const selectAllCategories = () => {
    formState[1]({
      ...formState[0],
      categories: (props.ydwwt.categories ?? []).map(cat => `${cat!.name}`),
    });
  };
  const formIsComplete = !!(
    attachmentBase64 &&
    form.name &&
    form.email.includes("@") &&
    form.consent !== null
  );
  const submit = async () => {
    if (attachmentFileType && attachmentFileType !== "application/json") {
      window.alert("Please attach a JSON file.");
      return;
    }
    try {
      setIsSubmitting(true);
      await fetch(submissionUrl, {
        method: "post",
        body: JSON.stringify({
          ydwwt: {
            id: props.ydwwt.id,
            slug: props.ydwwt.slug,
            name: props.ydwwt.name,
          },
          form: {
            ...form,
          },
          attachment: {
            fileName: attachmentFileName || null,
            base64: attachmentBase64 || null,
          },
          ...getFormMetaInfo(),
        }),
      });
      setSuccess(true);
    } catch (e) {
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      name={YDWWT_SUBMISSION_MODAL_NAME}
      width="670px"
      height="auto"
      maxHeight="unset"
      borderRadius={rSize("radius")}
      colorScheme="dark"
      className={cx(
        css`
          background-color: transparent;
          ${ModalBackdrop} {
            background-color: ${withOpacity(colors.white, 0.4)};
            ${darkModeLinariaCSS(`
              background-color: ${withOpacity(
                darken(0.1, colors.dark800),
                0.4
              )};
            `)}
          }
          textarea,
          input {
            background-color: ${colors.dark900};
            border-color: ${colors.dark500};
            ${darkModeLinariaCSS(`
              background-color: ${colors.dark900};
              border-color: ${colors.dark500};
            `)}
          }
        `
      )}
    >
      <ModalInner>
        <Spacing size="2em" />
        <WhatWrapper>
          <YDWWTWhatSticker />
        </WhatWrapper>
        <Spacing size="2em" />

        {success ? (
          <SuccessScreen />
        ) : (
          <>
            <h2>Enter competition</h2>

            <Spacing size="2em" />

            <SpaceChildren size=".75em">
              <FileUploader
                className={css`
                  width: 100%;
                `}
                fileUploadHandler={fileUploaderBase64UploadHandler}
                onFileUploaded={handleFileUpload}
                sizeLimitInMB={1}
                accepts="application/json"
                placeholder="Upload your story JSON file"
              />
              <Textarea
                formState={formState}
                name="description"
                rows={4}
                placeholder={"Tell us about your story..."}
              />
              <TextInputSet
                className={css`
                  width: 100%;
                `}
              >
                <TextInput
                  formState={formState}
                  name="name"
                  placeholder="Your name"
                  required
                />
                <TextInput
                  formState={formState}
                  name="email"
                  placeholder="Email"
                  type="email"
                  required
                />
                <TextInput
                  formState={formState}
                  name="company"
                  placeholder="Company (Optional)"
                />
                <TextInput
                  formState={formState}
                  name="videoLink"
                  placeholder="Video link (Optional)"
                  type="url"
                />
              </TextInputSet>
            </SpaceChildren>

            <Spacing size="1em" />

            <CategorySelectionSection>
              <header>
                <div>
                  If you had to classify your story based on our categories,
                  where might it fit?
                </div>
                <button type="button" onClick={selectAllCategories}>
                  Select all
                </button>
              </header>
              <Spacing size=".5em" />
              <Multiselect
                formState={formState}
                name="categories"
                options={(props.ydwwt.categories ?? []).map(cat => ({
                  label: `${cat!.name}`,
                  value: `${cat!.name}`,
                }))}
              />
            </CategorySelectionSection>

            <Spacing size="1em" />

            <ConsentSelector>
              <ConsentQuestion>
                Does Tines have your permission to reference your name and
                company in our community library?
              </ConsentQuestion>
              <ConsentOptionSeparator />
              <ConsentOption
                type="button"
                className={cx(formState[0].consent === true && "selected")}
                onClick={() => formState[1]({ ...formState[0], consent: true })}
              >
                <ConsentOptionCheckbox />
                <div>
                  Yes - I give Tines permission to use my name and company.
                </div>
              </ConsentOption>
              <ConsentOptionSeparator />
              <ConsentOption
                type="button"
                className={cx(formState[0].consent === false && "selected")}
                onClick={() =>
                  formState[1]({ ...formState[0], consent: false })
                }
              >
                <ConsentOptionCheckbox />
                <div>
                  No - I do not give Tines permission to use my name and
                  company.
                </div>
              </ConsentOption>
            </ConsentSelector>

            <Spacing size="1em" />

            {error && (
              <>
                <InfoBox>
                  <p>
                    An error occurred, please try again. If this keeps
                    happening, you can also submit a request{" "}
                    <PseudoLink onClick={openIntercomMessenger}>
                      by sending us a message
                    </PseudoLink>
                    .
                  </p>
                </InfoBox>
                <Spacing size="1em" />
              </>
            )}

            <Button
              loading={isSubmitting}
              fullWidth
              onClick={submit}
              disabled={!formIsComplete}
            >
              Submit
            </Button>
            <Footnote>
              By filling out this form you agree to the{" "}
              <Link to={Paths.ydwwtTerms()}>promotional terms</Link> for the
              YDWWT competition, and the general terms in our{" "}
              <Link to={Paths.privacy()}>privacy notice</Link>.
            </Footnote>
          </>
        )}
        <ModalCloseButton
          className={css`
            position: absolute;
            top: 0;
            right: 1em;
          `}
        />
      </ModalInner>
    </Modal>
  );
};

export default YDWWTSubmissionModal;
