import { styled } from "@linaria/react";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import QuoteCardFullColor from "../../general/QuoteCardFullColor";
import PageSection from "../../reusableSections/PageSection";
import LogoOpenTable from "../../thirdPartyLogos/LogoOpenTable";
import { LogoKnowBe4 } from "../../thirdPartyLogos/LogoKnowBe4";
import { colors } from "../../../styles/colors.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: 1fr 1fr;
  }
`;

const AWSPageQuotesSection = () => {
  return (
    <PageSection>
      <Grid>
        <QuoteCardFullColor
          quoteBody="Whether it’s EDR, traffic behavior analysis, firewall management, IDS, phishing simulations, or anything else we use, Tines is very easy to plug into everything, get the alerts we want, and have it process them. That takes hours off our work."
          color={colors.orange}
          sourceName="Joel Perez-Sanchez"
          sourceSubheading="Security Engineer"
          icon={<LogoOpenTable />}
        />
        <QuoteCardFullColor
          quoteBody="Tines is very intuitive in pretty much every aspect; the platform is just really easy to use, so it does a really good job at saving time. The time saved pays for itself, in my opinion."
          color={colors.purple}
          sourceName="Dylan White"
          sourceSubheading="Information Security Engineer"
          icon={<LogoKnowBe4 />}
        />
      </Grid>
    </PageSection>
  );
};

export default AWSPageQuotesSection;
