import { styled } from "@linaria/react";
import AllCaps from "../typography/AllCaps";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import WorkbenchPhishingIllustration from "./illustrations/WorkbenchPhishingIllustration.svg";
import Button from "../forms/Button";
import { useState } from "react";
import { cx } from "linaria";
import {
  WorkbenchChatCodeBlock,
  WorkbenchChatMessage,
  WorkbenchChatStack,
} from "./WorkbenchChatStack";
import { hideScrollbarsCSS } from "../../utils/scroll.utils";
import {
  MagnifierWithEyesIcon,
  MailTruckIcon,
  TinesIcon,
  WorkbenchFlashlightIcon,
} from "./illustrations/WorkbenchDemoTemplateOrStoryIcons";
import PseudoLink from "../basic/PseudoLink";
import { RightHandPointingLeft } from "../icons/hands/RightHandPointingLeft";
import {
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Ribbon = styled.div`
  display: grid;
  ${uptoTabletLg} {
    text-align: center;
  }
  ${fromTabletLg} {
    grid-template-columns: ${getWidthPxInMaxGrid(4)} minmax(0, 1fr);
  }
`;

const RibbonHeader = styled.header`
  position: relative;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple300};
  padding: 2.8rem;
  font-size: 1.4rem;
  border-top-left-radius: ${rSize("radius")};
  ${uptoTabletLg} {
    border-top-right-radius: ${rSize("radius")};
  }
  ${fromTabletLg} {
    padding-right: 10rem;
    transform: translateY(-24px);
    border-bottom-left-radius: ${rSize("radius")};
  }
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
  h3 {
    font-size: 2.6rem;
    font-family: ${serif};
    font-weight: 400;
    line-height: 1.1;
  }
  p,
  li {
    font-weight: 500;
    line-height: 1.2;
  }
  ul {
    padding-left: 1.5em;
    text-align: left;
    ${uptoTabletLg} {
      background-color: ${colors.purple100};
      padding: 1em 1em 1em 2em;
      border-radius: 0.5em;
      border: 1px solid ${colors.purple200};
      max-width: 29em;
      margin-left: auto;
      margin-right: auto;
    }
    li + li {
      margin-top: 0.5em;
    }
  }
  ${fromTabletLg} {
    &:after {
      content: "";
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='71' height='23' viewBox='0 0 71 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71 23H0L71 0V23Z' fill='%23C2AAFA'/%3E%3C/svg%3E%0A");
      width: 7.1rem;
      height: 2.3rem;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const RibbonContent = styled.div`
  position: relative;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple300};
  ${uptoTabletLg} {
    border-bottom-left-radius: ${rSize("radius")};
    border-top: 0;
  }
  ${fromTabletLg} {
    margin-left: -7.2rem;
    border-top-right-radius: ${rSize("radius")};
  }
  border-bottom-right-radius: ${rSize("radius")};
  height: 55rem;
`;

const StartScreen = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 6.4rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10rem;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s, transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  .hasStarted & {
    opacity: 0;
    transform: scale(0.9);
    pointer-events: none;
  }
  img {
    display: block;
  }
  > * {
    + * {
      margin-top: ${rSize("md")};
    }
  }
  p {
    font-weight: 600;
  }
`;

const ButtonGroup = styled.div`
  position: relative;
`;

const ChatUIWrap = styled.div`
  grid-area: chat;
  overflow: hidden;
  text-align: center;
  border-bottom-right-radius: 1.2rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-content: end;
  justify-content: end;
  height: 100%;
`;

const HandAnimator = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  padding-left: 0.75em;
  svg {
    @keyframes handBounce {
      from {
        transform: translate(0.25em, -50%);
      }
      to {
        transform: translate(-0.25em, -50%);
      }
    }
    animation: handBounce 0.5s ease infinite alternate-reverse;
  }
`;

const messages: WorkbenchChatMessage[] = [
  {
    type: "text",
    sender: "user",
    contents:
      "Hey, case #10922 has a suspicious phishing email attached in one of its comments. Can you extract the details of the email?",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          Sure! I’ll retrieve the contents of the email attachment from case
          10922 and extract the details. To do this, we’ll need to go through a
          few steps using the available Tines tools.
        </p>
        <p>First, let’s get the details of case 10922:</p>
      </>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <TinesIcon />,
    name: "Get a case",
    subheading: "Tines",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          I can see that there is indeed an email attachment in one of the
          activities. The relevant activity is:
        </p>
        <pre
          children={`{
  "id": 98021,
  "file": {
    "url": "https://example.tines.com/api/v2/cases/10922/files/98021/download",
    "filename": "I need you right now Conor.eml"
  },
  "user": {
    "user_id": "226",
    "email": "security-automation@company.com",
  },
  "created_at": "2024-09-10T13:15:33Z",
  "activity_type": "FILE_ATTACHED"
}`}
        ></pre>
        <p>Now, let’s retrieve the contents of this file:</p>
      </>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <TinesIcon />,
    name: "Get a case file contents",
    subheading: "Tines",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          I have successfully retrieved the contents of the email attachment.
          Here's a summary of the email:
        </p>
        <ul>
          <li>Email Details:</li>
          <li>From: eoinhinchy934890224@gmail.com</li>
          <li>To: conor@company.com</li>
          <li>Subject: I need you right now Conor</li>
        </ul>
        <p>The body of the email reads:</p>
        <WorkbenchChatCodeBlock>
          <p>Hello Conor</p>
          <p>
            I want you to handle a quick task for me. Kindly reconfirm with your
            WhatsApp number for more efficient coordination.
          </p>
          <p>Kind regards,</p>
          <p>Eoin Hinchy</p>
          <p>Sent from my iPhone</p>
        </WorkbenchChatCodeBlock>
        <p>
          Now, I’ll extract further details from the email for you using the EML
          file. Let me do that for you now.
        </p>
      </>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <MagnifierWithEyesIcon />,
    name: "Extract details from EML file",
    subheading: "Tines",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          I’ve successfully extracted the details from the email. Here are the
          results from the additional analysis:
        </p>
        <p>Header Analysis:</p>
        <ul>
          <li>SPF: Pass</li>
          <li>DKIM: Pass</li>
          <li>DMARC: Pass</li>
          <li>Initial IP: 1.2.3.4</li>
          <li>Sender Location: Mountain View, United States</li>
          <li>Organization: Google LLC</li>
          <li>IP Sender Reputation: Bad</li>
          <li>Recent Spam Activity: Identified spam in the past 48 hours</li>
        </ul>
        <p>
          The email appears to be from a Gmail account, but there are some
          concerning elements:
        </p>
        <ul>
          <li>
            The sender’s email address (eoinhinchy934890224@gmail.com) seems
            suspicious.
          </li>
          <li>
            The subject line “I need you right now Conor” is urgent and
            personal, which can be a red flag for phishing attempts.
          </li>
          <li>
            Despite passing SPF, DKIM and DMARK checks, the IP sender reputation
            is marked as “Bad.”
          </li>
          <li>
            There has been identified spam activity from this sender in the past
            48 hours.
          </li>
        </ul>

        <p>
          Given these details, this email shows signs of being potentially
          malicious or a phishing attempt. It would be advisable to treat this
          email with caution and not interact with any links or attachments it
          might contain without further verification.
        </p>

        <p>
          Would you like me to perform any additional analysis or take any
          specific actions regarding this email?
        </p>
      </>
    ),
  },
  {
    type: "text",
    sender: "user",
    contents: "Analyse this email with Sublime Security",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <p>
        I’ll use the Sublime Security tool to perform a more detailed analysis
        of the email. This will give us additional insights into potential
        threats or indicators of compromise.
      </p>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <WorkbenchFlashlightIcon />,
    name: "Analyze email using Sublime Security",
    subheading: "Tines",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          Based on the analysis using Sublime Security, we’ve uncovered an
          important finding. Let me summarize the results for you:
        </p>
        <ul>
          <li>
            Detection: Business Email Compromise (BEC) attempt from untrusted
            sender
          </li>
          <li>Severity: Medium</li>
        </ul>
        <p>
          This analysis confirms our initial suspicions and provides more
          concrete evidence that this email is indeed a potential threat. A
          Business Email Compromise attempt is a sophisticated form of phishing
          where the attacker tries to deceive the recipient into taking some
          action, often financial in nature.
        </p>
        <p>Given this new information, I recommend the following actions:</p>
        <ol>
          <li>Update the Tines case with this new information.</li>
          <li>Escalate the priority of the case if possible.</li>
          <li>
            Consider implementing immediate protective measures, such as
            blocking the sender's email address.
          </li>
          <li>
            Inform relevant team members about this potential BEC attempt.
          </li>
        </ol>
        <p>
          Would you like me to update the Tines case with this new information?
        </p>
      </>
    ),
  },
  {
    type: "text",
    sender: "user",
    contents: "Email the target to alert them this might be a phishing attack",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          Sounds good, I’ll draft an email to alert the target (
          <PseudoLink>conor@example.com</PseudoLink>) about the potential
          phishing attack. I’ll use the Tines email function to create this
          alert.
        </p>
      </>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <MailTruckIcon />,
    name: "Draft and send an email",
    subheading: "Tines",
    requiresConfirmation: true,
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          I’ve successfully sent an email alert to the target (
          <PseudoLink>conor@example.com</PseudoLink>) about the potential
          phishing attack. Here’s a summary of the action taken:
        </p>
        <ul>
          <li>To: conor@company.com</li>
          <li>Subject: URGENT: Potential Phishing Attack Alert</li>
          <li>Status: Sent successfully</li>
        </ul>
        <p>The email includes:</p>
        <ol>
          <li>
            Details of the suspicious email (sender, subject, and received date)
          </li>
          <li>
            Identification of the email as a potential Business Email Compromise
            (BEC) attempt
          </li>
          <li>Key points about why the email is suspicious</li>
          <li>Actions for the recipient to take</li>
          <li>A warning not to interact with the suspicious email</li>
          <li>
            Instructions to contact the IT security team if they've already
            interacted with the email
          </li>
        </ol>
        <p>
          The alert has been sent, and the target should now be aware of the
          potential threat. This action helps to mitigate the risk of the
          phishing attempt being successful.
        </p>
        <p>
          Would you like me to take any further actions, such as updating the
          Tines case with this new action, or do you have any other concerns
          about this situation?
        </p>
      </>
    ),
  },
  {
    type: "text",
    sender: "user",
    contents: "Add a comment to the case please",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <p>
        I’ll add a comment to the Tines case to document the actions we’ve taken
        and the current status of the situation. I’ll use the Tines comment on a
        case function to do this.
      </p>
    ),
  },
  {
    sender: "ai",
    type: "command",
    icon: <TinesIcon />,
    name: "Comment on a case",
    subheading: "Tines",
  },
  {
    type: "text",
    sender: "ai",
    contents: (
      <>
        <p>
          I've successfully added a comment to the Tines case. Here's a summary
          of the action taken:
        </p>
        <ul>
          <li>Case ID: 10922</li>
          <li>Comment Added: Yes</li>
          <li>Comment Created At: 2024-09-09T09:17:01Z</li>
        </ul>
        <p>The comment includes:</p>
        <ol>
          <li>
            Details of the further analysis performed using Sublime Security
          </li>
          <li>Information about the alert email sent to the target</li>
          <li>Suggested next steps for handling the situation</li>
        </ol>
        <p>
          The comment has been successfully added to the case, providing a clear
          record of the actions taken and the current status of the
          investigation.
        </p>
        <p>
          Is there anything else you'd like me to do regarding this case or any
          other aspects of the situation you'd like me to address?
        </p>
      </>
    ),
  },
  {
    sender: "user",
    type: "text",
    contents: "Thank you I think we are all good",
  },
  {
    sender: "ai",
    type: "text",
    contents: (
      <p>
        Glad I could help. If you have any more questions or need further
        assistance, feel free to ask.
      </p>
    ),
  },
];

export const WorkbenchPhishingSampleUseCaseSection = () => {
  const [hasStarted, setHasStarted] = useState(false);

  return (
    <Ribbon className={cx(hasStarted && "hasStarted")}>
      <RibbonHeader>
        <AllCaps as="div">Sample Use case</AllCaps>
        <h3>Phishing triage and response</h3>
        <p>
          This example demonstrates how Tines Workbench can help security teams:
        </p>
        <ul>
          <li>Reduce manual effort in handling reported phishing attempts</li>
          <li>Accelerate response times to potential threats</li>
          <li>Ensure consistent handling of incidents</li>
          <li>Improve overall security posture through automation</li>
        </ul>
      </RibbonHeader>
      <RibbonContent>
        <StartScreen>
          <img src={WorkbenchPhishingIllustration} width="196" height="196" />
          <p>Welcome to Workbench</p>
          <ButtonGroup>
            <Button
              darker
              width="12rem"
              onClick={() => {
                setHasStarted(true);
              }}
            >
              Have a play
            </Button>
            <HandAnimator>
              <RightHandPointingLeft />
            </HandAnimator>
          </ButtonGroup>
        </StartScreen>
        {hasStarted && (
          <ChatUIWrap className={hideScrollbarsCSS}>
            <WorkbenchChatStack
              messages={messages}
              withMessageComposer
              padding="1.5em"
              animated
            />
          </ChatUIWrap>
        )}
      </RibbonContent>
    </Ribbon>
  );
};
