export const FedDevicesIcon = () => (
  <svg width="100" height="75" viewBox="0 0 100 75" fill="none">
    <path
      d="M82.0183 9.72355C82.0296 7.63507 80.6845 5.69665 78.5854 5.048L71.1423 2.74738C68.573 1.95295 65.8465 3.39216 65.0522 5.96126L62.7516 13.4046C62.6013 13.8905 62.5311 14.3819 62.5336 14.8648C62.5372 15.6119 62.5319 18.8833 62.5331 19.3621C62.5368 21.4379 63.8782 23.3592 65.9657 24.0046L73.4088 26.3052C75.9781 27.0992 78.7046 25.6606 79.4993 23.0909L81.7999 15.6474C81.943 15.1845 82.0136 14.7168 82.0179 14.2562C82.0251 13.485 82.0159 10.1919 82.0183 9.72355Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M79.4994 18.5814C78.7046 21.1509 75.9783 22.5897 73.409 21.7956L65.9659 19.495C63.3966 18.7006 61.9573 15.9739 62.7518 13.4046L65.0524 5.96126C65.8468 3.39216 68.5731 1.95295 71.1424 2.74738L78.5856 5.048C81.1553 5.84223 82.5945 8.56936 81.8 11.1378L79.4994 18.5814Z"
      fill="#C2AAFA"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M74.3066 21.9307V26.5458"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.8242 19.9873V24.6022"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.6809 10.5592C40.6809 5.14573 36.2918 0.756836 30.8784 0.756836C25.4646 0.756836 21.0757 5.14573 21.0757 10.5592C21.0757 11.3562 21.0757 14.3888 21.0757 15.1861C21.0757 20.5997 25.4646 24.9886 30.8784 24.9886C36.2918 24.9886 40.6809 20.5997 40.6809 15.1861C40.6809 14.3888 40.6809 11.3562 40.6809 10.5592Z"
      fill="#B35980"
      stroke="#763359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.8782 20.3614C36.292 20.3614 40.6807 15.9727 40.6807 10.5589C40.6807 5.14509 36.292 0.756348 30.8782 0.756348C25.4644 0.756348 21.0757 5.14509 21.0757 10.5589C21.0757 15.9727 25.4644 20.3614 30.8782 20.3614Z"
      fill="#EB81AF"
      stroke="#763359"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M74.0409 35.0196C73.1025 21.2029 61.1417 10.7643 47.326 11.7021C33.5115 12.6409 23.0715 24.6015 24.0095 38.417C24.6192 47.398 29.8878 54.9507 37.295 58.8804C37.2938 58.9017 37.2887 58.921 37.2887 58.9423V73.208C37.2887 73.8273 37.7907 74.3299 38.4102 74.3299H59.6404C60.2597 74.3299 60.7618 73.8273 60.7618 73.208V58.9423C60.7618 58.9223 60.757 58.9037 60.7558 58.8841C69.2337 54.3909 74.7331 45.2103 74.0409 35.0196Z"
      fill="#8D75E6"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M74.0409 31.0837C73.1025 17.2673 61.1417 6.82861 47.326 7.76658C33.5115 8.70496 23.0715 20.6654 24.0095 34.4809C24.6192 43.4626 29.8878 51.0152 37.295 54.9445C37.2938 54.9657 37.2887 54.9851 37.2887 55.007V69.2721C37.2887 69.8913 37.7907 70.3942 38.4102 70.3942H59.6404C60.2597 70.3942 60.7618 69.8913 60.7618 69.2721V55.007C60.7618 54.9867 60.757 54.968 60.7558 54.9482C69.2337 50.4554 74.7331 41.2747 74.0409 31.0837Z"
      fill="#B0A6F9"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M69.2726 31.4068C70.0319 42.5897 61.5826 52.2696 50.3997 53.0287C39.2172 53.7888 29.537 45.3391 28.7776 34.157C28.0183 22.9749 36.4678 13.2939 47.6499 12.5347C58.8324 11.7752 68.5131 20.2249 69.2726 31.4068Z"
      fill="#11352E"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6266 61.4123C57.6266 58.6874 55.4178 56.478 52.6927 56.478H45.3576C42.6328 56.478 40.4238 58.6874 40.4238 61.4123C40.4238 61.626 40.4238 62.4599 40.4238 62.6736C40.4238 65.3989 42.6328 67.6081 45.3576 67.6081H52.6927C55.4178 67.6081 57.6266 65.3989 57.6266 62.6736C57.6266 62.4599 57.6266 61.626 57.6266 61.4123Z"
      fill="#8D75E6"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6266 62.6738C57.6266 65.3991 55.4178 67.6082 52.6927 67.6082H45.3576C42.6328 67.6082 40.4238 65.3991 40.4238 62.6738C40.4238 59.9487 42.6328 57.7397 45.3576 57.7397H52.6927C55.4178 57.7397 57.6266 59.9487 57.6266 62.6738Z"
      fill="#B0A6F9"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M45.7725 64.8077C46.8876 64.8077 47.7915 63.9037 47.7915 62.7886C47.7915 61.6735 46.8876 60.7695 45.7725 60.7695C44.6574 60.7695 43.7534 61.6735 43.7534 62.7886C43.7534 63.9037 44.6574 64.8077 45.7725 64.8077Z"
      fill="#49B889"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M52.2779 64.8077C53.3929 64.8077 54.2967 63.9039 54.2967 62.7889C54.2967 61.6739 53.3929 60.77 52.2779 60.77C51.1629 60.77 50.259 61.6739 50.259 62.7889C50.259 63.9039 51.1629 64.8077 52.2779 64.8077Z"
      fill="#FFD18C"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M45.7725 64.0601C46.8876 64.0601 47.7915 63.1561 47.7915 62.041C47.7915 60.9259 46.8876 60.022 45.7725 60.022C44.6574 60.022 43.7534 60.9259 43.7534 62.041C43.7534 63.1561 44.6574 64.0601 45.7725 64.0601Z"
      fill="#49B889"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M52.2779 64.0597C53.3929 64.0597 54.2967 63.1558 54.2967 62.0408C54.2967 60.9258 53.3929 60.022 52.2779 60.022C51.1629 60.022 50.259 60.9258 50.259 62.0408C50.259 63.1558 51.1629 64.0597 52.2779 64.0597Z"
      fill="#E269A4"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M69.2726 31.4068C70.0319 42.5897 61.5826 52.2696 50.3997 53.0287C39.2172 53.7888 29.537 45.3391 28.7776 34.157C28.0183 22.9749 36.4678 13.2939 47.6499 12.5347C58.8324 11.7752 68.5131 20.2249 69.2726 31.4068Z"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M47.6498 15.2464C58.8323 14.4866 68.5129 22.9359 69.2726 34.1184C69.2731 34.128 69.2739 34.1382 69.2743 34.148C69.3337 33.2472 69.3353 32.3327 69.2726 31.4068C68.5129 20.2247 58.8323 11.7752 47.6498 12.5348C36.4781 13.2941 28.0337 22.9579 28.7759 34.128C29.437 24.145 37.3934 15.9426 47.6498 15.2464Z"
      fill="#8D75E6"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M60.7556 58.8838V54.9476L65.6625 51.7539L65.773 55.2487C65.773 55.2487 62.6232 57.973 60.7556 58.8838Z"
      fill="#4D3E78"
    />
    <path
      d="M40.926 60.2461C40.926 60.2461 42.6111 57.9089 45.8176 57.746H48.1006V56.3872L45.3578 56.4783C45.3578 56.4783 41.6868 56.9307 40.926 60.2461Z"
      fill="#4D3E78"
    />
    <path
      d="M19.9137 29.0745L8.61723 24.9502L1.20312 34.4197V38.6704L7.91834 48.6468L19.4825 45.3433L19.9137 32.302V29.0745Z"
      fill="#2A7857"
      stroke="#195642"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.91834 44.397L1.20312 34.4197L8.61723 24.9502L19.9137 29.0745L19.4825 41.0935L7.91834 44.397Z"
      fill="#49B889"
      stroke="#195642"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.91821 48.318V44.0234"
      stroke="#195642"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.4164 26.9856C65.2394 28.7886 65.757 30.7679 65.8995 32.8623C66.5324 42.1817 59.4903 50.2488 50.1707 50.8821C40.8512 51.5153 32.784 44.4734 32.1506 35.1541C31.5177 25.8351 38.56 17.7668 47.8788 17.1343C51.7203 16.8733 55.3497 17.9167 58.3331 19.8863"
      fill="#195642"
    />
    <path
      d="M64.4164 26.9856C65.2394 28.7886 65.757 30.7679 65.8995 32.8623C66.5324 42.1817 59.4903 50.2488 50.1707 50.8821C40.8512 51.5153 32.784 44.4734 32.1506 35.1541C31.5177 25.8351 38.56 17.7668 47.8788 17.1343C51.7203 16.8733 55.3497 17.9167 58.3331 19.8863"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.7874 36.7055L43.5055 27.7185L53.1474 26.4448L59.0716 34.1589L55.3535 43.1455L45.7114 44.4191L39.7874 36.7055Z"
      fill="#1F5941"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.7874 32.8352L43.5055 23.8479L53.1474 22.5747L59.0716 30.2886L55.3535 39.275L45.7114 40.5486L39.7874 32.8352Z"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.7874 32.8354V36.7057"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.7114 40.5488V44.4193"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M55.3535 39.2749V43.1454"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.0715 30.2886V34.1589"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.1472 22.5747V26.4448"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.5054 23.8481V27.7186"
      stroke="#49B889"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M98.6401 33.2949L85.7007 25.1548L77.5605 38.0942V42.6066L90.5004 50.7468L98.6401 37.8072V33.2949Z"
      fill="#B74D1A"
      stroke="#803218"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M98.6285 33.2947L85.6895 25.1543L77.5491 38.0932L90.4882 46.2336L98.6285 33.2947Z"
      fill="#FFAF7E"
      stroke="#803218"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M90.5002 50.7466V46.2344"
      stroke="#803218"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M61.8018 24.108C62.4408 24.108 62.9589 23.59 62.9589 22.951C62.9589 22.312 62.4408 21.7939 61.8018 21.7939C61.1628 21.7939 60.6448 22.312 60.6448 22.951C60.6448 23.59 61.1628 24.108 61.8018 24.108Z"
      fill="#E269A4"
    />
  </svg>
);
