export const Crane = () => (
  <svg
    width="128"
    height="73"
    viewBox="0 0 128 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_340_12560)">
      <path
        d="M14 8.5C31.2 23.3 51.1667 20.6667 59 17.5C72 25 88.5 32 103.5 25.5C108 51.5 125 71 141 77.5"
        stroke="#C2AAFA"
      />
      <path
        d="M21 4L29 12L35.5 5.5L44.5 14.5L52.5 6.5L63.5 17.5L72.5 8.5L84.5 20.5L94.5 10.5L108 24L120 12L131 23"
        stroke="#B74D1A"
        strokeWidth="2"
      />
      <path
        d="M130 4H122.005C121.45 4 121.002 4.45047 121.005 5.0045L121.137 34.5045C121.14 35.055 121.587 35.5 122.137 35.5H130C130.552 35.5 131 35.0523 131 34.5V5C131 4.44772 130.552 4 130 4Z"
        fill="#FD975D"
        stroke="#B74D1A"
      />
      <path
        d="M130 4H127.5C126.948 4 126.5 4.44772 126.5 5V34.5C126.5 35.0523 126.948 35.5 127.5 35.5H130C130.552 35.5 131 35.0523 131 34.5V5C131 4.44772 130.552 4 130 4Z"
        fill="#F47E3F"
        stroke="#B74D1A"
      />
      <mask id="path-5-inside-1_340_12560" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 1.33643L17.1569 1.50619L124 9.91538V13.9277L19 5.66362V7.79801L124 24.8119V28.8641L16.6801 11.4743L15 11.202V9.50002V3.50002V1.33643Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1.33643L17.1569 1.50619L124 9.91538V13.9277L19 5.66362V7.79801L124 24.8119V28.8641L16.6801 11.4743L15 11.202V9.50002V3.50002V1.33643Z"
        fill="#F47E3F"
      />
      <path
        d="M17.1569 1.50619L17.0785 2.50311L17.1569 1.50619ZM15 1.33643L15.0785 0.339509L14 0.254627V1.33643H15ZM124 9.91538H125V8.99099L124.078 8.91846L124 9.91538ZM124 13.9277L123.922 14.9247L125 15.0095V13.9277H124ZM19 5.66362L19.0785 4.6667L18 4.58182V5.66362H19ZM19 7.79801H18V8.64902L18.84 8.78514L19 7.79801ZM124 24.8119H125V23.9609L124.16 23.8248L124 24.8119ZM124 28.8641L123.84 29.8512L125 30.0392V28.8641H124ZM16.6801 11.4743L16.5201 12.4614H16.5201L16.6801 11.4743ZM15 11.202H14V12.053L14.84 12.1892L15 11.202ZM17.2354 0.509272L15.0785 0.339509L14.9215 2.33334L17.0785 2.50311L17.2354 0.509272ZM124.078 8.91846L17.2354 0.509272L17.0785 2.50311L123.922 10.9123L124.078 8.91846ZM125 13.9277V9.91538H123V13.9277H125ZM18.9215 6.66054L123.922 14.9247L124.078 12.9308L19.0785 4.6667L18.9215 6.66054ZM20 7.79801V5.66362H18V7.79801H20ZM124.16 23.8248L19.16 6.81089L18.84 8.78514L123.84 25.799L124.16 23.8248ZM125 28.8641V24.8119H123V28.8641H125ZM16.5201 12.4614L123.84 29.8512L124.16 27.8769L16.84 10.4871L16.5201 12.4614ZM14.84 12.1892L16.5201 12.4614L16.84 10.4871L15.16 10.2149L14.84 12.1892ZM14 9.50002V11.202H16V9.50002H14ZM14 3.50002V9.50002H16V3.50002H14ZM14 1.33643V3.50002H16V1.33643H14Z"
        fill="#B74D1A"
        mask="url(#path-5-inside-1_340_12560)"
      />
      <path d="M9.5 8V58.5" stroke="#B74D1A" />
      <rect
        x="8.5"
        y="9.5"
        width="2"
        height="6"
        rx="0.5"
        fill="#FD975D"
        stroke="#B74D1A"
      />
      <path
        d="M16 1H14.1456C13.5396 1 13.0729 1.53468 13.1548 2.13511L14.3821 11.1351C14.4496 11.6306 14.8728 12 15.3729 12H16C16.5523 12 17 11.5523 17 11V2C17 1.44772 16.5523 1 16 1Z"
        fill="#F47E3F"
        stroke="#B74D1A"
      />
      <path
        d="M14 1H4C3.44772 1 3 1.44772 3 2V8.52274C3 8.99117 3.32517 9.39683 3.78237 9.49877L13.7824 11.7285C14.4071 11.8678 15 11.3925 15 10.7525V2C15 1.44772 14.5523 1 14 1Z"
        fill="#FD975D"
        stroke="#B74D1A"
      />
      <rect
        x="8.5"
        y="55.5"
        width="2"
        height="5"
        rx="0.5"
        fill="#FD975D"
        stroke="#B74D1A"
      />
      <path
        d="M9.28571 61V61.5564C9.28571 62.1087 9.76775 62.5406 10.2292 62.8441C10.6628 63.1292 11 63.6313 11 64.3722C11 65.9286 8.42857 66.9662 7 64.6316"
        stroke="#B74D1A"
      />
    </g>
    <defs>
      <clipPath id="clip0_340_12560">
        <rect width="128" height="73" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
