import {
  SvgPathCmd,
  SvgPathCmdMarker,
  commandsToSvgPath,
  convertToAbsolute,
  getAdjacentLength,
  getAngle,
  getNextNoZ,
  getOffset,
  getOppositeLength,
  getPreviousNoZ,
  getTangentNoHyp,
  markOverlapped,
  newCommands,
  reverseMarkOverlapped,
  roundParams,
  shortestSide,
} from "./utils";

/**
 * SVG path round corner algorithm adapted from
 * https://github.com/jaapvanhoek/svg-round-corners
 */

/**
 * Parses the given command string and generates an array of parsed commands.
 * This function normalizes all relative commands into absolute commands and
 * transforms h, H, v, V to L commands
 * @param {string} str Raw string from 'd' Attribute
 * @returns {array} Array of normalized commands
 */
export function parsePath(str: string) {
  const markerRegEx = /[MmLlSsQqLlHhVvCcSsQqTtAaZz]/g;
  const digitRegEx = /-?[0-9]*\.?\d+/g;

  return [...str.matchAll(markerRegEx)]
    .map(match => {
      return { marker: match[0] as SvgPathCmdMarker, index: match.index! };
    })
    .reduceRight((acc, cur) => {
      const chunk = str.substring(
        cur.index,
        acc.length ? acc[acc.length - 1].index : str.length
      );
      return acc.concat([
        {
          marker: cur.marker,
          index: cur.index,
          chunk: chunk.length > 0 ? chunk.substr(1, chunk.length - 1) : chunk,
        } as SvgPathCmd,
      ]);
    }, [] as SvgPathCmd[])
    .reverse()
    .flatMap(cmd => {
      const paramsRaw = cmd.chunk.match(digitRegEx);
      const params = paramsRaw ? paramsRaw.map(parseFloat) : [];
      return newCommands(cmd.marker, params);
    })
    .map(convertToAbsolute);
}

/**
 * Iterates through an array of normalized commands and insert arcs where applicable.
 * This function modifies the array in place.
 * @param {array} _cmds Array with commands to be modified
 * @param {number | number[]} r Expected radius of the arcs.
 * @param {number} round Number of decimal digits to round values
 * @returns {array} Sequence of commands containing arcs in place of corners
 */
export function roundCommands(
  cmds: SvgPathCmd[],
  _r: number | number[],
  round = 3
) {
  const subpaths: SvgPathCmd[][] = [];
  const newCmds: unknown[] = [];

  if (round) {
    cmds.forEach(el => roundParams(el, round));
    // roundValues(cmds, round);
  }

  cmds
    // split sub paths
    .forEach(e => {
      if (e.marker === "M") {
        subpaths.push([]);
      }
      subpaths[subpaths.length - 1].push(e);
    });

  subpaths.forEach(subPathCmds => {
    subPathCmds
      // We are only excluding lineTo commands that may be overlapping
      .map(markOverlapped);

    reverseMarkOverlapped(subPathCmds, subPathCmds.length - 1);

    // filter the overlap from the subPathCommands and the corners
    subPathCmds = subPathCmds.filter(el => !el.overlap);

    const radiuses = (
      (typeof _r === "number" // when r is a number create an array filled with r so that it's always the same input
        ? Array(subPathCmds.length).fill(_r)
        : // when the length of r is less then the commands fill up with a default of 0 to match the length
          Object.assign([], Array(subPathCmds.length).fill(0), _r)) as number[]
    ).filter((el, i) => subPathCmds[i] && !subPathCmds[i].overlap);

    // is this an open or closed path? don't add arcs to start/end.
    const closedPath = subPathCmds[subPathCmds.length - 1].marker == "Z";
    subPathCmds.map((el, i, arr) => {
      const largeArcFlag = 0;
      const prev = getPreviousNoZ(el, i, arr);
      const next = getNextNoZ(el, i, arr);
      const anglePrv = getAngle(el.params, prev.params);
      const angleNxt = getAngle(el.params, next.params);
      const angle = angleNxt - anglePrv; // radians
      const degrees = angle * (180 / Math.PI);
      // prevent arc crossing the next command
      const shortest = shortestSide(el, prev, next);
      const maxRadius = Math.abs(getTangentNoHyp(angle / 2, shortest / 2));
      const radius = Math.min(radiuses[i], maxRadius);

      const o = getOffset(angle, radius);
      const offset = o.offset;
      const sweepFlag = o.sweepFlag;

      const openFirstOrLast = (i == 0 || i == arr.length - 1) && !closedPath;
      switch (el.marker) {
        case "M": // moveTo x,y
        case "L": // lineTo x,y
          /* eslint-disable no-case-declarations */
          const prevPoint = [
            el.params.x + getOppositeLength(anglePrv, offset),
            el.params.y + getAdjacentLength(anglePrv, offset),
          ];

          /* eslint-disable no-case-declarations */
          const nextPoint = [
            el.params.x + getOppositeLength(angleNxt, offset),
            el.params.y + getAdjacentLength(angleNxt, offset),
          ];

          // there only need be a curve if and only if the next marker is a corner
          if (!openFirstOrLast) {
            newCmds.push({
              marker: el.marker,
              params: {
                x: parseFloat(prevPoint[0].toFixed(3)),
                y: parseFloat(prevPoint[1].toFixed(3)),
              },
            });
          } else {
            newCmds.push({
              marker: el.marker,
              params: el.params,
            });
          }

          if (
            !openFirstOrLast &&
            (next.marker === "L" || next.marker === "M")
          ) {
            newCmds.push({
              marker: "A",
              radius: radius,
              params: {
                radiusX: parseFloat(radius.toFixed(3)),
                radiusY: parseFloat(radius.toFixed(3)),
                rotation: degrees,
                largeArc: largeArcFlag,
                sweep: sweepFlag,
                x: parseFloat(nextPoint[0].toFixed(3)),
                y: parseFloat(nextPoint[1].toFixed(3)),
              },
            });
          }
          break;
        // case 'H': // horizontalTo x. Transformed to L in utils
        // case 'V': // verticalTo y. Transformed to L in utils
        case "C": // cubic beziér: x1 y1, x2 y2, x y
        case "S": // short beziér: x2 y2, x y
        case "Q": // quadratic beziér: x1 y1, x y
        case "T": // short quadratic beziér: x y
        case "A": // arc: rx ry x-axis-rotation large-arc-flag sweep-flag x y
        case "Z": // close path
          newCmds.push({ marker: el.marker, params: el.params });
          break;
      }
    });
  });

  return {
    path: commandsToSvgPath(newCmds as SvgPathCmd[]),
    commands: newCmds as SvgPathCmd[],
  };
}

/**
 * This is a shorthand for parsePath() and roundCommands().
 * You get the end result in one function call.
 * @param {string} str Raw string with commands from the path element
 * @param {number} r Expected radius of the arcs.
 * @param {number} round Number of decimal digits to round values
 * @returns {array} New commands sequence with rounded corners
 */
export function roundCorners(str: string, r: number, round: 3) {
  return roundCommands([...parsePath(str)], r, round);
}

export function createRoundedPathD(
  waypoints: { x: number; y: number; radius: number }[]
) {
  return roundCommands(
    waypoints.map((p, i) => ({
      marker: i === 0 ? "M" : "L",
      params: { x: p.x, y: p.y },
    })) as SvgPathCmd[],
    waypoints.map(p => p.radius)
  ).path;
}
