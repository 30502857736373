import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import {
  ACTION_TYPES,
  ActionTypeName,
} from "../../constants/actionTypes.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { withSmoothCornerRadius25Percent } from "../../styles/smoothCornerRadius.styles";
import ActionIcon, { ActionIconRect } from "../basic/ActionIcon";

type Props = {
  fillParentContainer?: boolean;
  withTooltip?: boolean;
  type: ActionTypeName;
};

const ActionIconCubeWrap = styled.div`
  position: relative;
  display: block;
  text-decoration: none;
  overflow: hidden;
  border-radius: 25%;
  transform: translate3d(0, 0, 0);
  ${withSmoothCornerRadius25Percent()};
  user-select: none;
  aspect-ratio: 1/1;
  &.selfContained {
    width: 6.4rem;
    max-width: 100%;
  }
  ${ActionIconRect} {
    display: flex;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: none;
  }
  svg {
    max-width: 50%;
    max-height: 50%;
    transition: transform 0.1s;
  }
  ${fromTabletLg} {
    svg {
      width: 50%;
      height: auto;
      aspect-ratio: 1/1;
    }
    .fillParentContainer {
      svg {
        width: 38%;
      }
    }
  }
  &.withTooltip {
    ${fromDesktop} {
      &:hover {
        svg {
          transform: scale(0.9);
        }
        strong {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  strong {
    display: none;
    ${cover()}
    color: ${colorsV4.white};
    opacity: 0;
    transition: 0.1s;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    font-size: 1.2rem;
    pointer-events: none;
    transform: scale(0.8);
    ${fromDesktop} {
      display: flex;
    }
    ${fromDesktopMd} {
      font-size: 1.4rem;
      padding: 1em;
      span {
        max-width: 5em;
      }
    }
  }
`;

const ActionIconCube = (p: Props) => {
  const def = ACTION_TYPES[p.type];
  return (
    <ActionIconCubeWrap
      className={cx(
        p.withTooltip && "withTooltip",
        p.fillParentContainer ? "fillParentContainer" : "selfContained"
      )}
    >
      <ActionIcon type={p.type} size="4em" iconSize="2.6rem" />
      {p.withTooltip && (
        <strong style={{ backgroundColor: def.color }}>
          <span>{def.name}</span>
        </strong>
      )}
    </ActionIconCubeWrap>
  );
};

export default ActionIconCube;
