export const ProductExplorerTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <circle opacity="0.2" cx="10" cy="10.5" r="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C10 4.55228 9.55228 5 9 5C8.44772 5 8 4.55228 8 4C8 3.44772 8.44772 3 9 3C9.55228 3 10 3.44772 10 4ZM11 4C11 4.93192 10.3626 5.71497 9.5 5.93699V7.05001C10.6411 7.28164 11.5 8.29052 11.5 9.5C11.5 9.82243 11.439 10.1306 11.3278 10.4136L12.8783 11.3439C13.1982 11.1268 13.5843 11 14 11C15.1046 11 16 11.8954 16 13C16 14.1046 15.1046 15 14 15C12.8954 15 12 14.1046 12 13C12 12.6797 12.0753 12.377 12.2092 12.1086L10.7827 11.2527C10.3292 11.714 9.69798 12 9 12C8.30202 12 7.67085 11.714 7.2173 11.2527L5.79085 12.1086C5.9247 12.377 6 12.6797 6 13C6 14.1046 5.10457 15 4 15C2.89543 15 2 14.1046 2 13C2 11.8954 2.89543 11 4 11C4.41571 11 4.80179 11.1268 5.12167 11.3439L6.67219 10.4136C6.56104 10.1306 6.5 9.82243 6.5 9.5C6.5 8.29052 7.35888 7.28164 8.5 7.05001V5.93699C7.63739 5.71497 7 4.93192 7 4C7 2.89543 7.89543 2 9 2C10.1046 2 11 2.89543 11 4ZM7.5 9.5C7.5 8.67157 8.17157 8 9 8C9.82843 8 10.5 8.67157 10.5 9.5C10.5 10.3284 9.82843 11 9 11C8.17157 11 7.5 10.3284 7.5 9.5ZM5 13C5 13.5523 4.55228 14 4 14C3.44772 14 3 13.5523 3 13C3 12.4477 3.44772 12 4 12C4.55228 12 5 12.4477 5 13ZM14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14Z"
    />
  </svg>
);
