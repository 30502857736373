import { DatoCmsPerson } from "../../../graphql-types";
import { PartialAndNullable } from "../../types/helper.types";

type Props = {
  person: PartialAndNullable<DatoCmsPerson>;
};

const PersonFullName = (props: Props) => {
  return (
    <span>
      {props.person.fullName ? (
        <span>{props.person.fullName}</span>
      ) : (
        <>
          <span>{props.person.name ?? ""} </span>
          {props.person.middleName && <span>{props.person.middleName} </span>}
          {props.person.surname && <span>{props.person.surname}</span>}
        </>
      )}
    </span>
  );
};

export default PersonFullName;
