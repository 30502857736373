export const LogoCode42 = () => (
  <svg width="104" height="25" viewBox="0 0 104 25" fill="currentColor">
    <title>Code42</title>
    <path d="M92.9739 16.5549H91.791V18.3623H88.7818V16.5549H83.5938V13.7988L87.1258 6.53125H90.471L86.9058 13.7988H88.7818V11.6696H91.791V13.7988H92.9739V16.5549Z" />
    <path d="M94.8173 16.1653L98.7374 12.0253C99.3974 11.3155 99.8706 10.7738 99.8706 10.1989C99.8706 9.6406 99.4991 9.2195 98.8225 9.2195C98.0797 9.2195 97.5545 9.70921 97.2351 10.4544L94.6328 9.00185C95.4277 7.14238 97.0672 6.28125 98.7894 6.28125C100.919 6.28125 102.896 7.61553 102.896 10.0333C102.896 11.5214 102.085 12.6356 101.004 13.7688L99.3974 15.4414H103.1V18.3655H94.8173V16.1653Z" />
    <path d="M27.3047 12.4397C27.3047 8.93369 29.959 6.23438 33.5337 6.23438C35.8781 6.23438 37.8677 7.46693 38.7218 9.33823L37.4419 10.0716C36.8031 8.67345 35.3104 7.75081 33.5337 7.75081C30.9053 7.75081 28.8661 9.81137 28.8661 12.4421C28.8661 15.0728 30.903 17.1333 33.5337 17.1333C35.334 17.1333 36.7795 16.187 37.5365 14.8126L38.84 15.5696C37.8701 17.4409 35.8545 18.6498 33.5337 18.6498C29.959 18.6451 27.3047 15.9457 27.3047 12.4397Z" />
    <path d="M40.3828 12.4397C40.3828 8.93369 43.1555 6.23438 46.6118 6.23438C50.0705 6.23438 52.8408 8.93605 52.8408 12.4397C52.8408 15.9434 50.0682 18.6451 46.6118 18.6451C43.1555 18.6451 40.3828 15.9457 40.3828 12.4397ZM51.2794 12.4397C51.2794 9.81138 49.2425 7.74845 46.6118 7.74845C43.9835 7.74845 41.9442 9.80901 41.9442 12.4397C41.9442 15.0704 43.9811 17.131 46.6118 17.131C49.2425 17.131 51.2794 15.0681 51.2794 12.4397Z" />
    <path d="M67.1479 1.78125V18.3627H65.5841V16.0419C64.5905 17.6293 62.9061 18.6466 60.7982 18.6466C57.4578 18.6466 54.6875 15.9449 54.6875 12.4413C54.6875 8.93762 57.4601 6.23594 60.7982 6.23594C62.9061 6.23594 64.5881 7.25557 65.5841 8.84062V1.78125H67.1479ZM65.5865 12.4413C65.5865 9.81294 63.5496 7.75001 60.9189 7.75001C58.2905 7.75001 56.2513 9.81057 56.2513 12.4413C56.2513 15.072 58.2882 17.1325 60.9189 17.1325C63.5496 17.1325 65.5865 15.0696 65.5865 12.4413Z" />
    <path d="M81.3394 12.487C81.3394 12.7236 81.3158 12.9838 81.2921 13.2204H70.8923C71.2235 15.6358 73.0948 17.1286 75.6072 17.1286C77.4785 17.1286 78.7821 16.2509 79.4208 15.1154L80.7953 15.9197C79.8017 17.5308 77.9753 18.6451 75.5836 18.6451C71.841 18.6451 69.2812 16.0167 69.2812 12.4397C69.2812 8.95734 71.7913 6.23438 75.4629 6.23438C79.0896 6.23438 81.3394 9.24123 81.3394 12.487ZM70.8923 11.7063H79.7284C79.3972 9.10165 77.5022 7.75081 75.4653 7.75081C72.9765 7.74845 71.1999 9.38318 70.8923 11.7063Z" />
    <path d="M11.161 20.9233C12.7863 20.9233 14.3595 21.148 15.8499 21.5715C14.8114 22.1748 11.1516 24.2897 11.1516 24.2897C11.1516 24.2897 7.76145 22.3333 6.4532 21.5762C7.95308 21.1504 9.53103 20.9233 11.161 20.9233ZM5.54002 4.08157C5.56604 3.98694 5.5897 3.89231 5.61336 3.79768C4.59846 4.38438 0.898438 6.52065 0.898438 6.52065C0.898438 6.52065 0.898438 10.779 0.898438 11.9595C2.01743 10.8736 3.00631 9.6174 3.82249 8.20269C4.58899 6.87787 5.15677 5.49391 5.54002 4.08157ZM21.4118 16.4C21.4141 17.1925 21.4141 18.3659 21.4141 18.3659C21.4141 18.3659 20.288 19.0165 19.7155 19.3477C17.1085 18.1625 14.2128 17.5024 11.161 17.5024C8.10922 17.5024 5.21118 18.1625 2.60414 19.3501C1.97722 18.9881 0.898438 18.3683 0.898438 18.3683C0.898438 18.3683 0.898438 17.3061 0.898438 16.4118C2.92351 14.9687 4.71438 13.1376 6.15038 10.9517C6.3704 10.6157 6.58331 10.2703 6.78913 9.91785C8.31503 7.27295 9.19272 4.4317 9.46952 1.57861C10.016 1.2616 11.1563 0.601562 11.1563 0.601562C11.1563 0.601562 12.3321 1.2829 12.8691 1.59281C13.1459 4.44353 14.0212 7.28242 15.5471 9.92258C17.0683 12.5604 19.0863 14.7369 21.4118 16.4ZM14.3666 14.2945C13.716 13.4546 13.1199 12.5651 12.5805 11.6306C12.5734 11.6188 12.5663 11.607 12.5615 11.5975C12.0269 10.6702 11.5632 9.72149 11.1658 8.75627C10.7636 9.73332 10.2904 10.6938 9.75104 11.6259C9.21165 12.5604 8.61312 13.4523 7.96017 14.2945C9.00346 14.1502 10.0751 14.0792 11.161 14.0792C12.2493 14.0792 13.3209 14.1525 14.3666 14.2945ZM16.7205 3.81187C16.7749 4.02952 16.8341 4.24717 16.9003 4.46482C17.2788 5.74705 17.8135 7.00089 18.509 8.20742C19.3205 9.61504 20.3022 10.8641 21.4141 11.9429C21.4141 10.391 21.4141 6.51828 21.4141 6.51828C21.4141 6.51828 17.7118 4.38438 16.7205 3.81187Z" />
  </svg>
);
