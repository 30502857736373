export const RSSTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H11.1707C11.0602 3.31278 11 3.64936 11 4H5C4.44772 4 4 4.44772 4 5V13C4 13.5523 4.44772 14 5 14H13C13.5523 14 14 13.5523 14 13V7C14.3506 7 14.6872 6.93985 15 6.82929V13C15 14.1046 14.1046 15 13 15H5C3.89543 15 3 14.1046 3 13V5C3 3.89543 3.89543 3 5 3ZM7.30975 8.22051C6.74377 8.02437 6.14096 7.95786 5.54584 8.02587C5.27148 8.05722 5.02365 7.86023 4.9923 7.58587C4.96095 7.31151 5.15794 7.06369 5.4323 7.03233C6.1762 6.94732 6.92971 7.03047 7.63718 7.27563C8.34466 7.5208 8.98804 7.92173 9.51982 8.44882C10.0516 8.97592 10.4582 9.61572 10.7097 10.321C10.9611 11.0263 11.0509 11.779 10.9725 12.5236C10.9436 12.7983 10.6975 12.9974 10.4229 12.9685C10.1483 12.9396 9.94909 12.6935 9.978 12.4189C10.0407 11.8232 9.96888 11.221 9.76773 10.6568C9.56658 10.0926 9.24129 9.58074 8.81586 9.15906C8.39043 8.73739 7.87572 8.41664 7.30975 8.22051ZM5.6043 10.0395C5.92873 9.97405 6.26434 9.99011 6.58104 10.0863C6.89773 10.1824 7.18562 10.3557 7.41888 10.5905C7.65213 10.8253 7.82347 11.1143 7.91753 11.4316C8.01159 11.7489 8.02543 12.0846 7.95781 12.4086C7.90139 12.679 8.07479 12.9438 8.3451 13.0002C8.61542 13.0567 8.88029 12.8833 8.93671 12.613C9.03815 12.127 9.01739 11.6234 8.8763 11.1474C8.73521 10.6714 8.4782 10.2379 8.12831 9.8857C7.77843 9.53349 7.3466 9.27362 6.87156 9.12939C6.39651 8.98516 5.8931 8.96108 5.40646 9.0593C5.13577 9.11394 4.96063 9.37766 5.01526 9.64834C5.0699 9.91903 5.33362 10.0942 5.6043 10.0395ZM6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z"
      fill="currentColor"
    />
    <circle opacity="0.5" cx="14" cy="4" r="2" fill="currentColor" />
  </svg>
);
