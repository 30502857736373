import { styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren } from "react";
import { valueWithOptionalUnit } from "../../utils/css.utils";

type Props = {
  maxWidth: string | number;
  centered?: boolean;
};

export const MaxWidthContainer = styled.div<Props>`
  width: 100%;
  max-width: ${p => valueWithOptionalUnit(p.maxWidth)};
  &.centered {
    margin-left: auto;
    margin-right: auto;
  }
`;

const MaxWidth = (props: PropsWithChildren<Props>) => {
  return (
    <MaxWidthContainer
      className={cx(props.centered && "centered")}
      maxWidth={props.maxWidth}
    >
      {props.children}
    </MaxWidthContainer>
  );
};

export default MaxWidth;
