import { styled } from "@linaria/react";
import { StructuredText } from "datocms-structured-text-utils";
import { Link } from "gatsby";
import { css } from "linaria";
import { DatoCmsArticle, DatoCmsPartner } from "../../../graphql-types";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
  uptoPhoneLg,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { isEmptyStructuredText } from "../../utils/datocms.utils";
import StandardArticleStructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";
import Button, { ButtonGroupCenteredOnMobile } from "../forms/Button";
import Spacing from "../layout/Spacing";
import TinesLogo from "../misc/TinesLogo";
import PageSection from "../reusableSections/PageSection";
import { stripCustomInlineFormattingSyntax } from "../typography/WithCustomInlineFormatting";
import BookADemoButton from "../utilities/BookADemoButton";
import PartnerLogo from "./PartnerLogo";
import AllCaps from "../typography/AllCaps";
import { Paths } from "../../utils/pathBuilders.utils";
import { GridCanvas } from "../decorations/GridCanvas";
import SiteNavHeightPlaceholder from "../site/SiteNavHeightPlaceholder";

type Props = {
  partner: DatoCmsPartner;
  hasStories?: boolean;
};

const MultiplicationContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
  align-items: stretch;
  grid-gap: 0.75em;
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    border: 1px solid ${colors.purple100};
    color: ${colors.dark500};
    border-radius: 0.75em;
    padding: 0 1.5em;
    ${fromTablet} {
      padding: 1.5em 2em;
      border-radius: 1.25em;
    }
    ${fromDesktop} {
      border-radius: 2em;
    }
  }
`;

const FramedPartnerLogoLink = styled.a`
  display: flex;
  padding: 0;
  > * {
    background-color: transparent;
  }
  &:hover {
    background-color: ${colors.pink50};
  }
`;

const MultiplyIconContainer = styled.div`
  width: 3em;
  padding: 0.25em;
  ${fromTablet} {
    padding: 1em;
    width: 6.5em;
  }
  ${fromDesktop} {
    width: 9em;
    height: 9em;
  }
  background-color: ${colors.white};
  color: ${colors.pink600};
  border: 1px solid ${colors.purple100};
  svg {
    width: 45%;
    height: 45%;
    ${fromTablet} {
      width: 30%;
      height: 30%;
    }
  }
`;

const TinesLogoContainer = styled.div`
  svg {
    ${onlyPhones} {
      max-width: 103px;
      max-height: 32px;
    }
  }
`;

const Header = styled.header`
  position: relative;
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  ${onlyPhones} {
    padding-top: 3em;
  }
  color: ${colors.purple800};
  text-align: center;
`;

const HeaderInner = styled.div`
  ${fromTabletLg} {
    max-width: ${widthInGrid(10)};
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    ${fluidFontSize(32, 52)};
    letter-spacing: -0.01em;
    font-family: ${font("serif")};
    font-weight: 400;
    text-wrap: balance; // supported in Chrome but not in safari
    ${fromDesktopMd} {
      max-width: ${widthInGrid(8)};
      margin-left: auto;
      margin-right: auto;
    }
    em {
      font-family: ${font("serif")};
      font-style: normal;
      font-weight: 400;
    }
  }
  p {
    ${fromDesktopMd} {
      max-width: ${widthInGrid(6)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const framedPartnerLogoStyle = css`
  &:hover {
    background-color: transparent;
  }
  ${uptoTablet} {
    padding: 0.5em 1em;
    aspect-ratio: 8 / 5;
  }
  ${fromTablet} {
    aspect-ratio: unset;
    img {
      &.wide {
        max-width: 100%;
      }
      &.regular {
        max-width: 50%;
      }
    }
  }
  ${fromTabletMd} {
    img {
      &.wide {
        max-width: 80%;
      }
    }
  }
  ${fromDesktop} {
    img {
      &.wide {
        max-width: 20rem;
      }
    }
  }
`;

const PartnerPageHero = ({ partner, hasStories }: Props) => {
  const utmTags = `utm_campaign=partner-landing-page&utm_source=${partner.slug}&utm_medium=website`;
  return (
    <PageSection style={{ position: "relative" }}>
      <GridCanvas color={colors.pink100} addBorderBottom />
      <Header>
        <HeaderInner>
          <SiteNavHeightPlaceholder />
          <Link to={Paths.partners()} style={{ textDecoration: "none" }}>
            <AllCaps>Partners</AllCaps>
          </Link>
          <Spacing size="1.75em" />
          {partner.landingPageTitle ? (
            <h1>
              {stripCustomInlineFormattingSyntax(partner.landingPageTitle)}
            </h1>
          ) : (
            <h1>{`${partner.name} × Tines`}</h1>
          )}
          <Spacing size="3em" />

          <MultiplicationContainer>
            <FramedPartnerLogoLink
              href={partner.websiteUrl ?? ""}
              title={`Visit website`}
              {...externalLinkAttr}
            >
              <PartnerLogo
                partner={partner}
                className={framedPartnerLogoStyle}
              />
            </FramedPartnerLogoLink>
            <MultiplyIconContainer>
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path
                  d="M41 3L3 41M3 3L41 41"
                  stroke="currentColor"
                  strokeWidth="6"
                />
              </svg>
            </MultiplyIconContainer>
            <TinesLogoContainer>
              <TinesLogo height={48} />
            </TinesLogoContainer>
          </MultiplicationContainer>

          <Spacing size="3em" />

          <ButtonGroupCenteredOnMobile
            className={css`
              ${uptoPhoneLg} {
                margin-left: -2em;
                margin-right: -2em;
              }
            `}
          >
            {hasStories && (
              <Button
                children="View examples"
                onClick={() => scrollToHash({ useHash: `#examples` })}
                darker
              />
            )}
            <Button
              href={`/community-edition?${utmTags}`}
              title="Sign up"
              appearance={hasStories ? "outlined" : "filled-white"}
              darker
              width="7em"
            />
            <BookADemoButton
              appearance="outlined"
              darker
              children="Book a demo"
              leadSource="Technology Partner"
              campaignId="701MI000008beI9YAI"
            />
          </ButtonGroupCenteredOnMobile>

          {!isEmptyStructuredText(partner.overview as StructuredText) && (
            <>
              <Spacing size="2.5em" />
              <StandardArticleStructuredTextWithCustomBlocks
                value={partner.overview as DatoCmsArticle["content"]}
              />
              <Spacing size="1em" />
            </>
          )}
        </HeaderInner>
      </Header>
    </PageSection>
  );
};

export default PartnerPageHero;
