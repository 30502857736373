import { styled } from "@linaria/react";
import { zIndex } from "../../styles/zIndexes.styles";
import {
  BrandThemeName,
  brandColorThemeVar,
  colors,
  getBrandColorThemeVariables,
  withOpacity,
} from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { PropsWithChildren } from "react";
import { renderInPortal } from "../../utils/portals.utils";
import { rSize } from "../../styles/responsiveSizes.styles";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  color?: BrandThemeName;
  dataTest?: string;
};

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${zIndex("Dialog")};
  background-color: ${withOpacity(colors.lightest, 0.5)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.darkest, 0.5)};`
  )}
  display: grid;
  min-height: 100%;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  align-items: center;
  padding: 1.5em;
  ${fromTablet} {
    padding: 2em;
  }
`;

const DialogWrap = styled.div`
  font-size: 1.4rem;
  width: 40em;
  max-width: 100%;
  background-color: ${colors.lightest};
  color: ${brandColorThemeVar(800)};
  border: 1px solid ${brandColorThemeVar(400)};
  ${darkModeLinariaCSS(`background-color: ${colors.dark600}`)}
  margin-left: auto;
  margin-right: auto;
  border-radius: ${rSize("radius")};
  padding: 2em;
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.06);
`;

const Dialog = (props: PropsWithChildren<Props>) => {
  return renderInPortal(
    <DialogOverlay
      style={getBrandColorThemeVariables(props.color)}
      data-test={props.dataTest}
    >
      <DialogWrap>{props.children}</DialogWrap>
    </DialogOverlay>
  );
};

export default Dialog;
