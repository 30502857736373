import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromTabletLg,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import PageSection from "../reusableSections/PageSection";
import { CertificationBadgeTwoToneIcon } from "../icons/twoTone/CertificationBadgeTwoToneIcon";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { w } from "../../utils/string.utils";
import { AIPageHeading2 } from "./_AIPageComponents";
import Spacing from "../layout/Spacing";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPathsPainter";

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  grid-template-areas: "icon" "header" "lists";
  ${fromDesktop} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-areas: ${`". ${w("header", 4)} ${w("icon", 2)} ${w(
      "lists",
      5
    )}"`};
    padding-bottom: ${rSize("xl")};
  }
  ${fromDesktopMl} {
    grid-template-areas: ${`". . ${w("header", 3)} ${w("icon", 2)} ${w(
      "lists",
      4
    )} ."`};
  }
`;

const IconContainer = styled.div`
  grid-area: icon;
  justify-self: center;
  background-color: ${colors.lightest};
  color: ${colors.yellow400};
  border: 2px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  svg {
    width: 3.6rem;
    height: 3.6rem;
  }
`;

const Header = styled.header`
  grid-area: header;
  ${uptoDesktop} {
    text-align: center;
  }
  p {
    font-size: 1.4rem;
  }
`;

const Lists = styled.div`
  grid-area: lists;
  margin-top: 0.5em;
  white-space: nowrap;
  ${uptoDesktop} {
    padding-left: 0;
    display: grid;
    grid-gap: 2em;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    max-width: 27em;
  }
  ${fromDesktop} {
    padding-left: 0;
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: 1em 2em;
  }
  ul {
    list-style: none;
    font-weight: 500;
    font-size: 1.4rem;
    ${fromDesktopMd} {
      font-size: 1.3rem;
    }
    ${fromDesktopMd} {
      font-size: 1.4rem;
    }
    margin: 0;
    padding: 0;
    li {
      + li {
        margin-top: 1em;
        ${fromTabletLg} {
          margin-top: 1.5em;
        }
      }
      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.5em;
        transform: translateY(-0.1em);
      }
    }
  }
`;

const GreenList = styled.ul`
  li {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23B0E0BD' stroke='%2387D1A3'/%3E%3Cpath d='M5.5 9L8 11.5L12.5 6' stroke='%23239667' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
`;

const RedList = styled.ul`
  li {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23FFC8A3' stroke='%23FFAF7E'/%3E%3Cpath d='M12 6L6 12M6 6L12 12' stroke='%23E16521' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
`;

const ConnectorWaypointsPositioner = styled.div`
  position: absolute;
  top: ${rSize("sectionMargin", -1)};
  left: 0;
  right: 0;
  bottom: 0;
`;

export const AIPageTrustSection = () => {
  return (
    <PageSection relative>
      <ConnectorWaypointsPositioner>
        <ConnectorPathWaypoint
          id="ai-page-trust-section-center-line"
          index={0}
          color={colors.yellow400}
          top={rSize("xxl")}
          left="50%"
        />
        <ConnectorPathWaypoint
          id="ai-page-trust-section-center-line"
          index={1}
          color={colors.yellow400}
          bottom="0"
          left="50%"
          radius={0}
        />
      </ConnectorWaypointsPositioner>
      <Grid>
        <IconContainer>
          <CertificationBadgeTwoToneIcon />
        </IconContainer>

        <Header>
          <AIPageHeading2>Taking your trust seriously</AIPageHeading2>
          <Spacing />
          <p>
            The value of AI depends on your ability to trust it. We take your
            trust extremely seriously, so we’ve made privacy and security
            central to our approach.
          </p>
        </Header>

        <Lists>
          <GreenList>
            <li>Stateless</li>
            <li>Private</li>
            <li>In-region</li>
            <li>Tenant-scoped</li>
          </GreenList>
          <RedList>
            <li>No networking</li>
            <li>No training</li>
            <li>No storage</li>
            <li>No logging</li>
          </RedList>
        </Lists>
      </Grid>
    </PageSection>
  );
};
