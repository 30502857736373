export const HttpRequestIcon = {
  httpRequest: () => (
    <svg
      className="Icon HttpRequestIcon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.851 7.00312C14.2914 4.70554 12.2199 3 9.75 3C6.93431 3 4.63633 5.21659 4.50585 8.00001L4.5 8C2.84315 8 1.5 9.34315 1.5 11C1.5 12.6569 2.84315 14 4.5 14H9.4L13.8 17.1429L15 18V16.5253V15.2V14C16.933 14 18.5 12.433 18.5 10.5C18.5 8.567 16.933 7 15 7C14.9501 7 14.9004 7.00105 14.851 7.00312ZM13.8 15.6682V14V12.8H15C16.2703 12.8 17.3 11.7703 17.3 10.5C17.3 9.22974 16.2703 8.2 15 8.2C14.9669 8.2 14.934 8.2007 14.9012 8.20206L13.918 8.24324L13.6851 7.28712C13.2534 5.51464 11.6537 4.2 9.75 4.2C7.57823 4.2 5.80515 5.90995 5.70453 8.0562L5.65086 9.20113L4.50467 9.20001L4.5 9.2C3.50589 9.2 2.7 10.0059 2.7 11C2.7 11.9941 3.50589 12.8 4.5 12.8H9.4H9.78456L10.0975 13.0235L13.8 15.6682Z"
      />
    </svg>
  ),
};
