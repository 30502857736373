import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromTabletLg,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../../styles/fonts.styles";

export const ReportV2Heading4 = styled.h4`
  font-size: 2.2rem !important;
  ${fromTabletLg} {
    font-size: 2.4rem !important;
  }
  ${fromDesktopMd} {
    font-size: 2.6rem !important;
  }
  &.centeredOnMobile {
    ${onlyPhones} {
      text-align: center;
    }
  }
  line-height: 1.1;
  letter-spacing: -0.01em;
  font-family: ${font("serif")};
  font-weight: 400 !important;
  * + & {
    margin-top: 1.2rem;
  }
  + * {
    margin-top: 1.2rem;
  }
  span {
    display: block;
  }
`;
