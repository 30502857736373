import { styled } from "@linaria/react";
import {
  DatoCmsSolutionPage,
  DatoCmsSolutionPageWorkflowStep,
} from "../../../graphql-types";
import PageSection from "../reusableSections/PageSection";
import SolutionPageAllcapsHeading from "./SolutionPageAllcapsHeading";
import { rSize } from "../../styles/responsiveSizes.styles";
import { cx } from "linaria";
import { fontSerifDisplayRules, serif } from "../../styles/fonts.styles";
import { colors } from "../../styles/colors.styles";
import {
  fromDesktop,
  fromTabletLg,
  mediaFromTo,
  onlyPhones,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import { isVideoSrc } from "../../utils/fileType.utils";
import {
  ShadableOrAdjacentSectionSpacer,
  ShadableSectionWrap,
} from "./SolutionPageSharedComponents";
import { CSSProperties, useRef } from "react";
import VideoAutoplayWhenVisible from "../basic/VideoAutoplayWhenVisible";

const asList = uptoTabletLg;
const asList2Col = mediaFromTo("tablet", "tabletLg");
const asSlides = fromTabletLg;

type Props = {
  page: DatoCmsSolutionPage;
  shaded?: boolean;
};

const MobileTopSpacer = styled.div`
  ${onlyPhones} {
    height: 5em;
  }
`;

const TopSpacer = styled.div`
  height: 3em;
`;

const Content = styled.div`
  position: relative;
  ${asList} {
    text-align: center;
  }
`;

const Header = styled.div`
  padding-bottom: 3em;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${asSlides} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "stepList media" "description media";
  }
`;

const StepArrow = styled.span`
  display: block;
  position: relative;
  width: 1em;
  height: 1em;
  user-select: none;
  ${asList} {
    display: none;
  }
  > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    &:first-child {
      &:before {
        content: "";
        background-color: ${colors.lightest};
        .shaded & {
          background-color: ${colors.purple50};
        }
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        @keyframes StepArrowPassage {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(100%);
          }
        }
        .active & {
          animation: StepArrowPassage var(--TransitionDuration) linear;
        }
        .paused & {
          animation-play-state: paused;
        }
      }
    }
    &:last-child {
      opacity: 0.25;
    }
  }
`;

const StepList = styled.div`
  ${asList} {
    display: grid;
    grid-gap: 2em;
  }
  ${asList2Col} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3em ${rSize("gap")};
  }
  ${asSlides} {
    grid-area: stepList;
  }
  padding-bottom: 2em;
`;

const StepListItem = styled.div`
  ${asList2Col} {
    align-self: stretch;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
  }
  button {
    display: flex;
    appearance: none;
    text-align: inherit;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: inherit;
    line-height: 1.1;
    position: relative;
    letter-spacing: -0.01em;
    font-family: ${serif};
    font-size: 2.8rem;
    width: 100%;
    ${asSlides} {
      overflow: hidden;
      ${fontSerifDisplayRules}
      font-size: 4.8rem;
      cursor: pointer;
      font-weight: 300;
      &:hover {
        color: ${colors.purple600};
      }
    }
    ${fromDesktop} {
      font-size: 6.4rem;
      font-weight: 250;
      letter-spacing: -0.02em;
    }
    > * {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    h3 {
      flex: 1 1 100%;
      font-size: inherit;
      font-weight: inherit;
      transform: translateX(0);
      transition-duration: 1s;
      ${asList} {
        margin-top: 1.5em;
        margin-bottom: 0.75em;
      }
    }
    ${StepArrow} {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition-duration: 0.6s;
    }
    ${asSlides} {
      &.active {
        span {
          transform: translateX(0);
        }
        h3 {
          transform: translateX(0.875em);
        }
      }
    }
  }
  p {
    margin-top: 0.5em;
    ${asList} {
      margin-left: 1em;
      margin-right: 1em;
    }
    ${asSlides} {
      display: none;
    }
    br {
      display: block;
      width: 100%;
      height: 0.5em;
      content: " ";
    }
    em {
      font-family: inherit;
      font-weight: 600;
    }
  }
`;

const DescriptionWrap = styled.div`
  ${asList} {
    display: none;
  }
  ${asSlides} {
    grid-area: description;
    padding-bottom: 3em;
  }
  position: relative;
  p {
    position: absolute;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.6s, transform 0.6s;
    transform: scale(0.95);
    max-width: 25em;
    &.active {
      opacity: 1;
      user-select: auto;
      pointer-events: auto;
      transform: scale(1);
    }
    br {
      display: block;
      width: 100%;
      height: 0.5em;
      content: " ";
    }
  }
  em {
    font-family: inherit;
    font-weight: 600;
  }
`;

const MobileMediaItemWrap = styled.div`
  ${asSlides} {
    display: none;
  }
  border-radius: ${rSize("radius")};
  overflow: hidden;
  margin-top: 1em;
  background-color: ${colors.purple100};
  span {
    min-height: 25rem;
  }
  > * {
    display: block;
  }
  video {
    transform: scale(1.25);
    &[src*="records.mp4"] {
      transform: scale(1.5);
    }
  }
`;

const MediaWrap = styled.div`
  ${asList} {
    display: none;
  }
  ${asSlides} {
    grid-area: media;
  }
  border-radius: ${rSize("radius")};
  aspect-ratio: 5/4;
  overflow: hidden;
  position: relative;
  span,
  video,
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.6s;
    user-select: none;
    pointer-events: none;
    background-color: ${colors.purple100};
    border-radius: inherit;
    &.active {
      opacity: 1;
    }
  }
  video {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%) scale(1.25);
    &[src*="records.mp4"] {
      transform: translate(-50%, -50%) scale(1.5);
    }
  }
`;

const BottomSpacer = styled.div`
  ${asSlides} {
    height: 3em;
  }
`;

const MobileBottomSpacer = styled.div`
  ${onlyPhones} {
    height: 5em;
  }
`;

const transitionDuration = 15000;

const SolutionPageWorkflowSection = ({ page, shaded }: Props) => {
  const [activeIndexRef, setActiveIndex] = useStateWithRef(0);
  const [rotationStartedRef, setRotationStarted] = useStateWithRef(false);
  const [visibleRef, setVisible] = useStateWithRef(false);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const totalSteps = page.workflowSteps?.length ?? 0;
  const goToIndex = (i: number) => {
    setActiveIndex(i);
  };
  const startRotation = () => {
    setRotationStarted(true);
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (totalSteps === 0) return;
    intervalRef.current = setInterval(() => {
      const newIndex = activeIndexRef.current + 1;
      goToIndex(newIndex > totalSteps - 1 ? 0 : newIndex);
    }, transitionDuration);
  };
  useOnMount(() => {
    const observer = new IntersectionObserver(entries => {
      const visible = entries[0].isIntersecting;
      setVisible(visible);
      if (visible && !intervalRef.current) startRotation();
    });
    ref.current && observer.observe(ref.current);
    return () => {
      observer.disconnect();
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  });
  const isPrev = (i: number) =>
    i === activeIndexRef.current - 1 ||
    (i === totalSteps - 1 && activeIndexRef.current === 0);
  const isNext = (i: number) =>
    i === activeIndexRef.current + 1 ||
    (i === 0 && activeIndexRef.current === totalSteps - 1);
  const getSlideClasses = (i: number) =>
    cx(
      rotationStartedRef.current && i === activeIndexRef.current && "active",
      isPrev(i) && "prev",
      isNext(i) && "next"
    );
  return (
    <PageSection>
      {shaded ? (
        <>
          <MobileTopSpacer />
          <ShadableOrAdjacentSectionSpacer />
        </>
      ) : (
        <TopSpacer />
      )}
      <ShadableSectionWrap
        className={cx(shaded && "shaded", visibleRef.current && "visible")}
        ref={ref}
        style={
          {
            "--TransitionDuration": `${transitionDuration}ms`,
          } as CSSProperties
        }
      >
        <Content>
          <Header>
            <SolutionPageAllcapsHeading>
              {page.workflowSectionHeadline || "Smart, secure workflows"}
            </SolutionPageAllcapsHeading>
          </Header>
          <Grid>
            <StepList>
              {page.workflowSteps?.map((step, i) => (
                <StepListItem key={i} className={getSlideClasses(i)}>
                  <button
                    className={getSlideClasses(i)}
                    onClick={() => {
                      goToIndex(i);
                      startRotation();
                    }}
                  >
                    <StepArrow>
                      <span>→</span>
                      <span>→</span>
                    </StepArrow>
                    <h3>{step?.name}</h3>
                  </button>
                  <WithCustomInlineFormatting as="p">
                    {step?.description || "This step has no description"}
                  </WithCustomInlineFormatting>
                  <MobileMediaItemWrap>
                    <MediaItem step={step as DatoCmsSolutionPageWorkflowStep} />
                  </MobileMediaItemWrap>
                </StepListItem>
              ))}
            </StepList>
            <DescriptionWrap>
              {page.workflowSteps?.map((step, i) => (
                <WithCustomInlineFormatting
                  as="p"
                  key={i}
                  className={getSlideClasses(i)}
                >
                  {step?.description || "This step has no description"}
                </WithCustomInlineFormatting>
              ))}
            </DescriptionWrap>
            <MediaWrap>
              {page.workflowSteps?.map((step, i) => (
                <MediaItem
                  key={i}
                  step={step as DatoCmsSolutionPageWorkflowStep}
                  className={getSlideClasses(i)}
                />
              ))}
            </MediaWrap>
          </Grid>
          <BottomSpacer />
          <ShadableOrAdjacentSectionSpacer />
        </Content>
      </ShadableSectionWrap>
      {shaded && <MobileBottomSpacer />}
    </PageSection>
  );
};

const MediaItem = ({
  step,
  className,
}: {
  className?: string;
  step: DatoCmsSolutionPageWorkflowStep;
}) => {
  const media = step?.media;
  const mediaUrl = media?.url;
  return mediaUrl ? (
    isVideoSrc(mediaUrl) ? (
      <VideoAutoplayWhenVisible src={mediaUrl} className={className} />
    ) : (
      <img src={mediaUrl} className={className} />
    )
  ) : (
    <span className={className} />
  );
};

export default SolutionPageWorkflowSection;
