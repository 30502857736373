import { styled } from "@linaria/react";
import { graphql } from "gatsby";
import { css, cx } from "linaria";
import { useRef, useState } from "react";
import { DatoCmsWebinar, WebinarAttendPageQuery } from "../../graphql-types";
import CountdownStopwatch from "../components/webinars/CountdownStopwatch";
import FullscreenMessage from "../components/utilities/FullscreenMessage";
import LoadingIndicator from "../components/utilities/LoadingIndicator";
import PillLegacy from "../components/basic/PillLegacy";
import SpotIllustration from "../components/illustrations/SpotIllustration";
import RedirectHandler from "../components/utilities/RedirectHandler";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import { PageComponent } from "../types/gatsby.types";
import { uniq } from "../utils/array.utils";
import { getCookie } from "../utils/cookies.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import {
  isLiveWebinarOrJustEnded,
  isPastWebinar,
} from "../utils/webinar.utils";
import axios from "axios";
import { reportErrorSilently } from "../utils/error.utils";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import { runAfter } from "../utils/promises.utils";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";

const reportAttendanceToWebhookUrl =
  "https://hq.tines.io/webhook/6a24fc44975647379bcdf6374050209d/d17bca5715c821f276ad5cfd4f2be8ce";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
  line-height: 1.25;
  > * + * {
    margin-top: 0.75em;
  }
`;

const WebinarAttendPageTemplate: PageComponent<{
  data: WebinarAttendPageQuery;
}> = props => {
  const [dataFetched, setDataFetched] = useState(false);
  const dataFetchedRef = useRef(dataFetched);
  dataFetchedRef.current = dataFetched;

  const [webinar, setWebinar] = useState(props.data.webinar as DatoCmsWebinar);
  const webinarRef = useRef(webinar);
  webinarRef.current = webinar;

  const [webinarInfoIncomplete, setWebinarInfoIncomplete] = useState<
    boolean | null
  >(null);

  const [tooEarly, setTooEarly] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);

  const category = Object.assign(
    {
      singularLowercase: "webinar",
      pluralLowercase: "webinars",
      singularTitlecase: "Webinar",
      pluralTitlecase: "Webinars",
    },
    webinarRef.current.category
  );

  const reportAttendance = () => {
    const source = new URL(window.location.href);
    const emailsToReport = uniq(
      [getCookie("email_address"), source.searchParams.get("email")].filter(
        i => i
      )
    ) as string[];
    emailsToReport.forEach(email => {
      fetch(reportAttendanceToWebhookUrl, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          webinarId: webinarRef.current.id,
        }),
      });
    });
  };

  const fetchLatestWebinarInfo = async () => {
    try {
      const { data } = await axios.get(
        `/api/webinar?id=${webinarRef.current.id}`
      );
      setWebinar(data as DatoCmsWebinar);
    } catch (e) {
      reportErrorSilently(e);
    } finally {
      setDataFetched(true);
    }
  };

  useOnMount(() => {
    let alive = true;
    let timeCheckerIntervalHandle: unknown = undefined;
    let dataFetcherIntervalHandle: unknown = undefined;
    let hasReportedAttendance = false;
    runAfter(async () => {
      await fetchLatestWebinarInfo();
      const checkIfShouldRedirect = () => {
        const { zoomLink, liveStreamLinkYoutube } = webinarRef.current;
        if (!dataFetchedRef.current) return;
        const isIncompleteZoomEvent = !zoomLink;
        const isIncompleteLiveStreamEvent = !liveStreamLinkYoutube;
        if (isIncompleteZoomEvent && isIncompleteLiveStreamEvent) {
          setWebinarInfoIncomplete(true);
          // check again after the next webinar data refetch
          return;
        } else {
          setWebinarInfoIncomplete(false);
        }
        const hasEnded = isPastWebinar(webinarRef.current);
        const isLive = isLiveWebinarOrJustEnded(webinarRef.current);
        const tooEarly = !hasEnded && !isLive;
        if (tooEarly) setTooEarly(true);
        if (isLive) setIsLive(true);
        if (hasEnded) setHasEnded(true);
        if (isLive) {
          if (!hasReportedAttendance) reportAttendance();
          hasReportedAttendance = true;
          clearInterval(timeCheckerIntervalHandle as number);
          clearInterval(dataFetcherIntervalHandle as number);
        }
      };
      if (!alive) return;
      timeCheckerIntervalHandle = setInterval(checkIfShouldRedirect, 1000);
      dataFetcherIntervalHandle = setInterval(() => {
        fetchLatestWebinarInfo();
      }, 5000);
    }, 1000);

    return () => {
      alive = false;
      clearInterval(timeCheckerIntervalHandle as number);
      clearInterval(dataFetcherIntervalHandle as number);
    };
  });

  const getWebinarPageUrl = () => {
    return `/${webinarRef.current.category?.slug ?? "webinars"}/${
      webinarRef.current.slug
    }`;
  };

  const Message = {
    WebinarInfoIncomplete: () => (
      <>
        <SpotIllustration name="sevenBuildingBlocksSquares" />
        <h3 data-message-title>
          This {category?.singularLowercase} is under preparation
        </h3>
        <p data-message-body>Please check back later.</p>
      </>
    ),
    TooEarly: () => {
      return (
        <>
          <CountdownStopwatch time={webinarRef.current.timeStart} />
          <PillLegacy
            className={cx(
              "starting-soon-tag",
              css`
                font-size: 12px;
              `
            )}
          >
            Starting soon
          </PillLegacy>
          <h3 data-message-title>{webinarRef.current.title}</h3>
          <p data-message-body>
            We’ll automatically redirect you to the event when it begins
            shortly.
          </p>
        </>
      );
    },
    RedirectToDestination: () => (
      <RedirectHandler
        to={webinarRef.current.zoomLink || getWebinarPageUrl()}
        message={
          <>
            <h3 data-message-title>{webinarRef.current.title}</h3>
            <Spacing size=".2em" />
            <p data-message-body data-redirect-to-destination-message-body>
              Taking you to the {category.singularLowercase}...
            </p>
          </>
        }
      />
    ),
    EventEndedScreen: () => (
      <RedirectHandler
        to={getWebinarPageUrl()}
        message={
          <>
            <h3 data-message-title>
              The {category.singularLowercase} has ended.
            </h3>
            <Spacing size=".2em" />
            <p data-message-body>
              Redirecting you to the {category.singularLowercase} page...
            </p>
          </>
        }
      />
    ),
    Loading: () => (
      <>
        <LoadingIndicator size={24} />
        <Spacing size=".2em" />
        <p data-message-body>One moment...</p>
      </>
    ),
  };

  return (
    <FullscreenMessage darkMode>
      <SiteNavHeightPlaceholder withSpacingTop />
      <SEO title={`${webinarRef.current.title}`} noindex />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="webinar"
        recordId={webinar.id}
      />
      <Container
        className="WebinarAttendPageScreen"
        data-webinar-id={webinar.id}
        data-fetched={dataFetched}
      >
        {dataFetched ? (
          <>
            {webinarInfoIncomplete ? (
              <Message.WebinarInfoIncomplete />
            ) : isLive ? (
              <Message.RedirectToDestination />
            ) : hasEnded ? (
              <Message.EventEndedScreen />
            ) : tooEarly ? (
              <Message.TooEarly />
            ) : (
              <Message.Loading />
            )}
          </>
        ) : (
          <Message.Loading />
        )}
      </Container>
    </FullscreenMessage>
  );
};

export const webinarAttendPageQuery = graphql`
  query WebinarAttendPage($slug: String) {
    webinar: datoCmsWebinar(slug: { eq: $slug }) {
      id: originalId
      title
      slug
      timeStart
      timeEnd
      timezone
      isPrivate
      zoomLink
      recordedVideoLink
      liveStreamLinkYoutube
      category {
        id: originalId
        slug
        name
        singularLowercase
        pluralLowercase
        singularTitlecase
        pluralTitlecase
      }
    }
  }
`;

export default WebinarAttendPageTemplate;
