export const RatingStarsFourPointEight = () => (
  <svg width="72" height="12" viewBox="0 0 72 12" fill="none">
    <path
      d="M4.83444 0.971123C5.14434 0.343204 6.03973 0.343203 6.34963 0.971122L7.42319 3.14636C7.54626 3.39571 7.7841 3.56853 8.05926 3.60852L10.4598 3.95734C11.1528 4.05803 11.4295 4.9096 10.928 5.39837L9.19097 7.09155C8.99185 7.28563 8.901 7.56527 8.94804 7.83936L9.35806 10.2301C9.47644 10.9203 8.75207 11.4466 8.13228 11.1207L5.98517 9.99197C5.73905 9.8626 5.44502 9.8626 5.1989 9.99197L3.05181 11.1207C2.43202 11.4466 1.70763 10.9203 1.826 10.2301L2.23605 7.83936C2.28306 7.56527 2.1922 7.28563 1.99308 7.09155L0.256053 5.39837C-0.24537 4.9096 0.031321 4.05803 0.72427 3.95734L3.12479 3.60852C3.39996 3.56853 3.63784 3.39571 3.7609 3.14636L4.83444 0.971123Z"
      fill="currentColor"
    />
    <path
      d="M20.0383 0.97094C20.3482 0.343021 21.2436 0.343019 21.5535 0.970939L22.627 3.14618C22.7501 3.39553 22.988 3.56835 23.2631 3.60833L25.6637 3.95715C26.3566 4.05784 26.6333 4.90941 26.1319 5.39818L24.3948 7.09137C24.1957 7.28544 24.1049 7.56509 24.1519 7.83917L24.5619 10.23C24.6803 10.9201 23.9559 11.4464 23.3361 11.1205L21.189 9.99178C20.9429 9.86241 20.6489 9.86241 20.4028 9.99178L18.2557 11.1205C17.6359 11.4464 16.9115 10.9202 17.0299 10.23L17.4399 7.83917C17.4869 7.56509 17.3961 7.28544 17.1969 7.09137L15.4599 5.39818C14.9585 4.90941 15.2352 4.05784 15.9281 3.95715L18.3286 3.60833C18.6038 3.56835 18.8417 3.39553 18.9648 3.14618L20.0383 0.97094Z"
      fill="currentColor"
    />
    <path
      d="M35.2414 0.971001C35.5513 0.343082 36.4467 0.343081 36.7566 0.971L37.8302 3.14624C37.9532 3.39559 38.1911 3.56841 38.4662 3.6084L40.8668 3.95721C41.5598 4.0579 41.8364 4.90947 41.335 5.39824L39.5979 7.09143C39.3988 7.28551 39.308 7.56515 39.355 7.83924L39.765 10.23C39.8834 10.9202 39.1591 11.4465 38.5393 11.1206L36.3922 9.99185C36.146 9.86247 35.852 9.86247 35.6059 9.99185L33.4588 11.1206C32.839 11.4465 32.1146 10.9202 32.233 10.23L32.643 7.83924C32.69 7.56515 32.5992 7.28551 32.4001 7.09143L30.663 5.39824C30.1616 4.90947 30.4383 4.0579 31.1313 3.95721L33.5318 3.6084C33.8069 3.56841 34.0448 3.39559 34.1679 3.14624L35.2414 0.971001Z"
      fill="currentColor"
    />
    <path
      d="M50.4453 0.971001C50.7552 0.343082 51.6506 0.343081 51.9605 0.971L53.034 3.14624C53.1571 3.39559 53.3949 3.56841 53.6701 3.6084L56.0707 3.95721C56.7636 4.0579 57.0403 4.90947 56.5388 5.39824L54.8018 7.09143C54.6027 7.28551 54.5118 7.56515 54.5589 7.83924L54.9689 10.23C55.0873 10.9202 54.3629 11.4465 53.7431 11.1206L51.596 9.99185C51.3499 9.86247 51.0559 9.86247 50.8097 9.99185L48.6626 11.1206C48.0429 11.4465 47.3185 10.9202 47.4368 10.23L47.8469 7.83924C47.8939 7.56515 47.803 7.28551 47.6039 7.09143L45.8669 5.39824C45.3655 4.90947 45.6422 4.0579 46.3351 3.95721L48.7356 3.6084C49.0108 3.56841 49.2487 3.39559 49.3717 3.14624L50.4453 0.971001Z"
      fill="currentColor"
    />
    <path
      d="M68.8022 4.10332C68.3641 4.03967 67.9855 3.76451 67.7896 3.36764L66.7161 1.19241C66.5896 0.936104 66.2241 0.936112 66.0976 1.19241L65.0241 3.36764C64.8282 3.76457 64.4495 4.03967 64.0115 4.10332L61.611 4.45214C61.3281 4.49324 61.2152 4.84082 61.4199 5.04032L63.1569 6.73351C63.4739 7.04247 63.6185 7.48763 63.5437 7.92388L63.1336 10.3147C63.0853 10.5964 63.381 10.8112 63.6339 10.6782L65.7811 9.54939C66.1728 9.34346 66.6408 9.34348 67.0326 9.5494L69.1798 10.6782C69.4327 10.8112 69.7283 10.5964 69.68 10.3147L69.27 7.92387M68.8022 4.10332L68.8741 3.60852M68.8022 4.10332L71.2027 4.45214C71.4856 4.49324 71.5985 4.84081 71.3938 5.04031L69.6568 6.7335C69.3398 7.04246 69.1952 7.48762 69.27 7.92387M69.27 7.92387C69.27 7.92389 69.27 7.92385 69.27 7.92387Z"
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.9998 5.32125V4.26457C61.1248 4.10673 61.3088 3.99084 61.5385 3.95746L63.939 3.60864C64.2142 3.56866 64.452 3.39583 64.5751 3.14648L65.6487 0.971245C65.9585 0.343326 66.8539 0.343325 67.1638 0.971244L68.2374 3.14648C68.3605 3.39583 68.5983 3.56866 68.8735 3.60864L68.9998 3.62699V6.86017L69.4998 9.86017L69.1646 11.2009C69.0915 11.1849 69.0183 11.1586 68.9465 11.1209L66.7994 9.99209C66.5533 9.86272 66.2592 9.86272 66.0131 9.99209L63.866 11.1209C63.2462 11.4467 62.5218 10.9205 62.6402 10.2303L63.0503 7.83948C63.0973 7.5654 63.0064 7.28575 62.8073 7.09167L61.0703 5.39849C61.0448 5.37363 61.0213 5.34784 60.9998 5.32125Z"
      fill="currentColor"
    />
  </svg>
);
