import { DatoCmsButton } from "../../../graphql-types";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import Button, { ButtonAppearance } from "../forms/Button";

export const LPButtonGroup = (props: { buttons: DatoCmsButton[] }) => {
  const handleClick = (button: DatoCmsButton) => {
    if (button.link?.endsWith("#book-a-demo-form")) {
      scrollToHash({
        useHash: "#book-a-demo-form",
        onScrollComplete: () => {
          document
            .querySelector<HTMLInputElement>('#book-a-demo-form [name="email"]')
            ?.focus();
        },
      });
    }
  };
  return (
    <>
      {props.buttons.map((button, i) => (
        <Button
          key={i}
          to={`${button?.link}`}
          appearance={button?.appearance as ButtonAppearance}
          darker
          onClick={() => handleClick(button)}
        >
          {button?.label}
        </Button>
      ))}
    </>
  );
};
