import { styled } from "@linaria/react";
import gsap from "gsap";
import { cx } from "linaria";
import { useMemo, useState } from "react";
import { isBrowser } from "../../../environment";
import { withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { Action, RuntimeStoryRecord } from "../../../types/tines.types";
import { clearAnimatedProps } from "../../../utils/animations.utils";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { gridUnit } from "../../storyboard/utils/storyboard.utils";
import {
  StoryboardContextValue,
  StoryboardDecorationComponentProps,
} from "../../storyboard/StoryboardContext";
import StoryboardViewer from "../../storyboard/StoryboardViewer";
import StoryboardViewport from "../../storyboard/StoryboardViewport";
import { useDemoStoryContext } from "./DemoStoryContext";
import DemoStoryDropZone from "./DemoStoryDropZone";
import { colors } from "../../../styles/colors.styles";
import StoryboardActionInspector from "../../storyboard/StoryboardActionInspector";
import { uptoDesktopMd } from "../../../styles/breakpointsAndMediaQueries.styles";

const StoryboardViewerContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};
  border-bottom-right-radius: inherit;
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.dark500, 0.5)};`
  )}
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: stretch;
  &.shaded {
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2em;
      background-image: linear-gradient(
        to top,
        ${colors.lightest},
        ${withOpacity(colors.lightest, 0)}
      );
      pointer-events: none;
      z-index: 1;
    }
  }
`;

export const StoryboardViewportWrap = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: ${rSize("md", 0.5)};
`;

const InspectorWrap = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  padding: 1em;
  border-left: 1px solid ${colors.light100};
  width: 30rem;
  ${uptoDesktopMd} {
    display: none;
  }
`;

const DemoStoryboardWithSidebar = (props: {
  shaded?: boolean;
  inspectable?: boolean;
  story: RuntimeStoryRecord;
  dropZoneActionNameMatcher: string;
  maxEvents: number;
  possibleTriggerValues: [string, string];
}) => {
  const demoContext = useDemoStoryContext();
  const [story] = useState(props.story);
  const actionToRenderAsDropzone = story.agents.find(a =>
    a.name.includes(props.dropZoneActionNameMatcher)
  )!;
  const [tl] = useState(
    gsap.timeline({
      paused: true,
    })
  );
  const handleStoryboardReady = (context: StoryboardContextValue) => {
    const actionsScheduled = new Set<Action>();
    const scheduleActionEnterAnimation = (
      action: Action,
      animationDelay = 0
    ) => {
      if (actionsScheduled.has(action)) return;
      const actionEl = document.querySelector(
        `.Storyboard[data-id="${context.id}"] .ActionEntry[data-action-index="${action.index}"]:not(.hidden)`
      );
      if (!actionEl) return;
      action.outputLinks.forEach((link, i) => {
        const linkEl = document.querySelector<SVGPathElement>(
          `.Storyboard[data-id="${context.id}"] .StoryboardActionLink[data-link-index="${link.index}"] path`
        );
        if (window.innerWidth > 768) {
          const lineLength = linkEl?.getTotalLength() ?? 100;
          tl.to(
            linkEl,
            {
              strokeDashoffset: 0,
              duration: lineLength / 1000 + 0.2,
              ease: "sine.inOut",
              onComplete: () => {
                clearAnimatedProps(linkEl, [
                  "stroke-dasharray",
                  "stroke-dashoffset",
                ]);
              },
            },
            animationDelay + 0.1
          );
          gsap.set(linkEl, {
            strokeDashoffset: lineLength,
            strokeDasharray: lineLength,
          });
        } else {
          // don't animate drawing the line on mobile, it's buggy on iOS.
          tl.fromTo(
            linkEl,
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.35,
              onComplete: () => {
                clearAnimatedProps(linkEl, ["opacity"]);
              },
            },
            animationDelay + 0.1
          );
          gsap.set(linkEl, { opacity: 0 });
        }
        if (link.receiver)
          scheduleActionEnterAnimation(
            link.receiver,
            animationDelay + 0.05 + i * 0.1
          );
      });
      tl.fromTo(
        actionEl,
        { opacity: 0, scale: 1.6 },
        {
          opacity: 1,
          scale: 1,
          duration: 0.35,
          ease: "expo.out",
          onComplete: () => {
            clearAnimatedProps(actionEl, ["opacity", "transform"]);
          },
        },
        animationDelay
      );
      gsap.set(actionEl, { opacity: 0 });
    };
    context.story.heads.forEach((headAction, i) => {
      scheduleActionEnterAnimation(headAction, i * 0.075);
    });
    const dropzoneEl = document.querySelector(
      `.Storyboard[data-id="${context.id}"] .DemoStoryDropZone`
    );
    tl.fromTo(
      dropzoneEl,
      {
        opacity: 0,
        scale: 1.1,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.75,
        ease: "expo.out",
      },
      "+=1"
    );
    gsap.set(dropzoneEl, { opacity: 0 });
    tl.play();
  };
  useOnMount(() => {
    return () => {
      tl.kill();
    };
  });
  const Dropzone = useMemo(
    () =>
      function Dropzone(p: StoryboardDecorationComponentProps) {
        return (
          <DemoStoryDropZone
            {...p}
            maxEvents={props.maxEvents}
            dropZoneActionNameMatcher={props.dropZoneActionNameMatcher}
            possibleTriggerValues={props.possibleTriggerValues}
          />
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <StoryboardViewer
      story={story}
      inspectable={props.inspectable}
      children={context => (
        <StoryboardViewerContainer
          data-id={context.id}
          className={cx(
            context.selectedActionId !== null && "hasSelection",
            props.shaded && "shaded"
          )}
        >
          <StoryboardViewportWrap ref={context.ref} data-ready={context.ready}>
            <StoryboardViewport onReady={handleStoryboardReady} />
          </StoryboardViewportWrap>
          {props.inspectable && (
            <InspectorWrap>
              <StoryboardActionInspector withIcon />
            </InspectorWrap>
          )}
        </StoryboardViewerContainer>
      )}
      delay={300}
      useMarketingStyles
      actionShouldBeHidden={
        demoContext.hasDropped
          ? undefined
          : a => a.name.includes(props.dropZoneActionNameMatcher)
      }
      storyboardDecorations={[
        {
          name: "dropzone",
          x: actionToRenderAsDropzone.position.x - gridUnit(1),
          get y() {
            return (
              actionToRenderAsDropzone.position.y -
              gridUnit(isBrowser && window.innerWidth > 768 ? 1 : 2)
            );
          },
          get width() {
            return isBrowser && window.innerWidth > 768
              ? gridUnit(16)
              : gridUnit(12);
          },
          get height() {
            return gridUnit(isBrowser && window.innerWidth > 768 ? 5 : 7);
          },
          component: Dropzone,
        },
      ]}
    />
  );
};

export default DemoStoryboardWithSidebar;
