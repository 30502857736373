import { cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import {
  DatoCmsArticle,
  DatoCmsArticleCategory,
  DatoCmsPerson,
} from "../../../graphql-types";
import AuthorDisplay from "./AuthorDisplay";
import Spacing from "../layout/Spacing";
import ArticleLikePageTitleSans from "./ArticleLikePageTitle";
import PillListLegacy from "../basic/PillListLegacy";
import { preventWidows } from "../../utils/typography.utils";
import { Pill } from "../basic/Pill";

type Props = {
  className?: string;
  entry: DatoCmsArticle;
};

const ArticlePageHeaderContainer = styled.header`
  padding-top: 1px;
`;

const ArticlePageHeader = (props: Props) => {
  const authors = (props.entry.authors ?? []) as DatoCmsPerson[];
  const categories = (props.entry.categories ?? []) as DatoCmsArticleCategory[];
  const title = props.entry.title ?? "";
  return (
    <ArticlePageHeaderContainer
      className={cx("ArticlePageHeader", props.className)}
    >
      {categories.length > 0 && (
        <>
          <PillListLegacy>
            {categories.map((c, i) => (
              <Link to={`/blog/${c.slug}`} key={c.slug}>
                <Pill allCaps larger>
                  {c.name}
                </Pill>
              </Link>
            ))}
          </PillListLegacy>
          <Spacing size="gap" />
        </>
      )}
      <ArticleLikePageTitleSans
        className={cx(title.length > 100 ? "long" : "")}
      >
        {preventWidows(title)}
      </ArticleLikePageTitleSans>
      {authors.length > 0 && (
        <>
          <Spacing size="gap" />
          <AuthorDisplay
            authors={authors}
            showAuthorWorkTitle={props.entry.showAuthorWorkTitle}
          />
        </>
      )}
    </ArticlePageHeaderContainer>
  );
};

export default ArticlePageHeader;
