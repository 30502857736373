import { styled } from "@linaria/react";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";

const OnlyPhonesDiv = styled.div`
  ${fromTablet} {
    display: none;
  }
`;
export const OnlyPhonesSpan = styled.span`
  ${fromTablet} {
    display: none;
  }
`;

export default OnlyPhonesDiv;
