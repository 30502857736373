/* eslint-disable @typescript-eslint/no-misused-promises */
import { styled } from "@linaria/react";
import { StructuredText } from "datocms-structured-text-utils";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  DatoCmsProductUpdate,
  ProductUpdateCategoriesQuery,
} from "../../../graphql-types";
import { getPlainTextContentFromStructuredText } from "../../../scripts/structuredText";
import {
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { getFirstVisibleElement } from "../../utils/html.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { makeSlug } from "../../utils/string.utils";
import PageBreadCrumbs from "../basic/PageBreadCrumbs";
import { WiderArticlePageBody } from "../hub/HubPageComponents";
import ProductUpdateFullEntry from "../hub/ProductUpdateFullEntry";
import ProductUpdateTimeline, {
  ProductUpdateTimelineEntry,
} from "../hub/ProductUpdateTimeline";
import {
  SITE_SIDEBAR_PORTAL_ID,
  getSiteSidebarPortalDestination,
} from "../site/SiteSidebar";
import SEO from "../utilities/SEO";
import whatsNewImage from "../../../static/images/og/tines-whats-new.png";
import { resolveAfter, when } from "../../utils/promises.utils";
import axios from "axios";
import dayjs from "dayjs";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import StandardSidebar from "../layout/StandardSidebar";
import Spacing from "../layout/Spacing";
import { LoadingStateBanner } from "../utilities/LoadingIndicatorBanner";
import ColorSchemeToggle from "../utilities/ColorSchemeToggle";
import { stripCustomInlineFormattingSyntax } from "../typography/WithCustomInlineFormatting";
import { throttle } from "lodash-es";
import { graphql, useStaticQuery } from "gatsby";
import Select from "../forms/Select";
import { css } from "linaria";
import {
  getUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import { scrollToTop } from "../../utils/scroll.utils";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import LoadingIndicator from "../utilities/LoadingIndicator";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { colors } from "../../styles/colors.styles";
import { GiftTwoToneIcon } from "../icons/twoTone/GiftTwoToneIcon";

type Props = {
  id?: string;
  entries: DatoCmsProductUpdate[];
  timeline: ProductUpdateTimelineEntry[];
};

const WhatsNewPageContentWrap = styled.div`
  padding-top: 1em;
  ${fromTablet} {
    padding-top: 0;
  }
  padding-bottom: ${rSize("gap")};
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
`;

const SidebarContent = styled.div`
  text-align: left;
`;

const TimelineLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
`;

const perPage = 12;

type RequestState = "queued" | "fetching" | "fetched" | "error";
type PageObject = {
  request: RequestState;
  entries: DatoCmsProductUpdate[];
  categoryId: string;
};

const sortEntries = <
  T extends {
    title?: string | null | undefined;
    date?: string | null | undefined;
  }
>(
  entries: T[]
) =>
  entries.sort((a, b) => {
    if (a.date === b.date)
      return `${a.title}` > `${b.title}`
        ? 1
        : `${a.title}` < `${b.title}`
        ? -1
        : 0;
    return dayjs(`${b.date}`).diff(`${a.date}`, "seconds");
  });

const ProductUpdatePageContent = (props: Props) => {
  const [timelineRef, setTimeline] = useStateWithRef([...props.timeline]);

  const categoriesQueryResult: ProductUpdateCategoriesQuery = useStaticQuery(
    productUpdateCategoriesQuery
  );
  const categories = categoriesQueryResult.categories.nodes;
  const categoryFilterFormState = useState({ categoryId: "" });

  const selectedCategoryId = categoryFilterFormState[0].categoryId;
  const selectedCategoryIdRef = useRef(selectedCategoryId);
  selectedCategoryIdRef.current = selectedCategoryId;

  const selectedCategorySlug =
    categories.find(c => c.id === selectedCategoryId)?.slug ?? "";
  const selectedCategorySlugRef = useRef(selectedCategorySlug);
  selectedCategorySlugRef.current = selectedCategorySlug;

  const pageObjectsRef = useRef<PageObject[]>([
    {
      request: "fetched",
      entries: props.entries,
      categoryId: "",
    },
  ]);

  const hasFinishedInitialLoadRef = useRef(false);

  const [hasReachedEndRef, setHasReachedEnd] = useStateWithRef(false);
  const [, setLastFetchedPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const shouldFocusOnEntryAfterFetchRef = useRef("");

  const setPageState = (page: number, state: RequestState) => {
    const index = page - 1;
    pageObjectsRef.current[index] = pageObjectsRef.current[index] || {
      request: state,
      entries: [],
    };
    pageObjectsRef.current[index].request = state;
  };

  const preloadToEntry = (slug: string) => {
    const index = timelineRef.current.findIndex(t => t.slug === slug);
    const preloadToPage = Math.ceil((index + 1) / perPage) + 1;

    shouldFocusOnEntryAfterFetchRef.current = slug;

    if (!hasFinishedInitialLoadRef.current) {
      pageObjectsRef.current = [];
    }
    pageObjectsRef.current[preloadToPage - 1] = {
      request: "queued",
      entries: props.entries,
      categoryId: selectedCategoryIdRef.current,
    };

    for (let page = 1; page <= preloadToPage; page++) {
      if (!pageObjectsRef.current[page - 1]) setPageState(page, "queued");
    }
    processQueue();
  };

  const setPageEntries = (page: number, entries: DatoCmsProductUpdate[]) => {
    const index = page - 1;
    pageObjectsRef.current[index] = pageObjectsRef.current[index] || {
      request: "fetched",
      entries,
    };
    pageObjectsRef.current[index].request = "fetched";
    pageObjectsRef.current[index].entries = entries;
  };

  const queueNextPage = () => {
    if (pageObjectsRef.current.some(p => p.request === "fetching")) return;
    const lastQueuedPageIndex = pageObjectsRef.current.findIndex(
      p => p === undefined
    );
    const nextPage =
      lastQueuedPageIndex === -1
        ? pageObjectsRef.current.length + 1
        : lastQueuedPageIndex + 2;
    if (pageObjectsRef.current[nextPage - 1]) return;
    setPageState(nextPage, "queued");
    processQueue();
  };

  const updateFetchState = () => {
    setIsFetching(pageObjectsRef.current.some(p => p.request === "fetching"));
  };

  const updateTimeline = (newEntries: DatoCmsProductUpdate[]) => {
    const newTimeline = [...timelineRef.current];
    newEntries.forEach(e => {
      const timelineEntry = {
        id: e.id ?? "",
        title: e.title ?? "",
        slug: e.slug ?? "",
        date: e.date ?? "",
        category: { slug: e.category?.slug ?? "" },
      };
      const existing = newTimeline.find(t => t.id === e.id);
      if (existing) {
        Object.assign(existing, timelineEntry);
      } else {
        newTimeline.unshift(timelineEntry);
      }
    });
    sortEntries(newTimeline);
    if (JSON.stringify(timelineRef.current) !== JSON.stringify(newTimeline)) {
      setTimeline(newTimeline);
    }
  };

  const fetchPage = async (page: number) => {
    setPageState(page, "fetching");
    updateFetchState();
    try {
      const categoryId = selectedCategoryIdRef.current;
      const { data } = await axios.get<{
        entries: DatoCmsProductUpdate[];
        page: number;
      }>(
        `/api/product-updates/list?page=${page}&perPage=${perPage}${
          categoryId ? `&categoryId=${categoryId}` : ""
        }`
      );
      if (selectedCategoryIdRef.current !== categoryId) return;
      if (data.entries.length === 0) setHasReachedEnd(true);
      setPageEntries(data.page, data.entries);
      updateTimeline(data.entries);
      if (
        pageObjectsRef.current.every(
          p => p.request === "fetched" || p.request === "error"
        )
      ) {
        onAllRequestCompleted(
          hasFinishedInitialLoadRef.current ? undefined : 0
        );
        setLastFetchedPage(data.page);
        hasFinishedInitialLoadRef.current = true;
      } else {
        processQueue();
      }
    } catch (e) {
      setPageState(page, "error");
    } finally {
      setPageState(page, "fetched");
      updateFetchState();
    }
  };

  const onAllRequestCompleted = (duration?: number) => {
    const shouldFocusOnEntryAfterFetch =
      shouldFocusOnEntryAfterFetchRef.current;
    if (shouldFocusOnEntryAfterFetch) {
      setTimeout(async () => {
        const entryIdHash = `#${shouldFocusOnEntryAfterFetch}`;
        if (!document.querySelector(entryIdHash)) {
          // for browsers (really just safari) that haven't rendered the updated DOM in time
          await when(() => !!document.querySelector(entryIdHash));
        }
        scrollToHash({
          useHash: entryIdHash,
          doNotPushState: true,
          duration,
          offsetY:
            (document.querySelector(".SiteNav")?.clientHeight ?? 0) * -1 - 48,
        });
      });
      shouldFocusOnEntryAfterFetchRef.current = "";
    }
  };

  const processQueue = () => {
    const nextToFetch = pageObjectsRef.current.find(
      p => p?.request === "queued"
    );
    if (nextToFetch) fetchPage(pageObjectsRef.current.indexOf(nextToFetch) + 1);
  };

  const entries = sortEntries(
    pageObjectsRef.current.reduce((prev, curr) => {
      if (!curr) return prev;
      prev.push(
        ...curr.entries.filter(
          newEntry =>
            !prev.find(existingEntry => existingEntry.id === newEntry.id)
        )
      );
      return prev;
    }, [] as DatoCmsProductUpdate[])
  );

  const { id } = props;
  const pageEntry = id ? entries.find(e => e.id === id) : null;

  const [sidebarPortalDestination, setSidebarPortalDestination] =
    useState<HTMLElement | null>(null);
  const [activeLinkTitle, setActiveLinkTitle] = useState("");
  const [activeLinkPath, setActiveLinkPath] = useState("");

  const highlightActiveItemInTimelineAndUpdateURL = throttle(async () => {
    const headings = Array.from(
      document.documentElement.querySelectorAll<HTMLHeadingElement>(
        ".ProductUpdateArticleTitle"
      ) ?? []
    );
    const hash = window.location.hash.replace(/#/, "");
    const firstVisibleHeading = getFirstVisibleElement(headings, 160);
    const headingSlug = hash || firstVisibleHeading?.getAttribute("id");
    if (!headingSlug) return;
    if (hash && !document.getElementById(hash)) {
      await when(() => !!document.getElementById(hash));
    }
    const currentPath = window.location.pathname;
    const newPath = `/whats-new/${headingSlug}${
      selectedCategorySlugRef.current
        ? `?category=${selectedCategorySlugRef.current}`
        : ""
    }`;
    if (currentPath !== newPath) {
      window.history.replaceState(null, "", newPath);
    }
    const activeLinkInTimeline = document.documentElement.querySelector(
      `#${SITE_SIDEBAR_PORTAL_ID} [href$="#${headingSlug}"]`
    );
    if (activeLinkInTimeline) {
      Array.from(
        document.documentElement.querySelectorAll("a.active") ?? []
      ).forEach(a => a.classList.remove("active"));
      activeLinkInTimeline.classList.add("active");
    }
    const title = activeLinkInTimeline?.querySelector("h4")?.innerText ?? "";
    const path = `#${makeSlug(title)}`;
    setActiveLinkTitle(title);
    setActiveLinkPath(path);
  }, 300);

  const plainTextTitle = stripCustomInlineFormattingSyntax(
    activeLinkTitle || (pageEntry?.title ?? "")
  );

  const ogTitle = plainTextTitle
    ? `${plainTextTitle} | What’s New | Tines`
    : "What’s New";

  const ogImage = pageEntry?.category?.image?.url ?? whatsNewImage;

  const focusedEntryStructuredTextWithOnlyFirstNode = pageEntry
    ? {
        ...(pageEntry.content ?? {}),
        value: {
          schema: "dast",
          document: {
            type: "root",
            children: [
              (
                pageEntry.content as StructuredText
              ).value.document.children.find(c => c.type === "paragraph"),
            ],
          },
        },
      }
    : null;

  const defaultDescription =
    "Read about all the latest Tines features and updates.";

  const ogDescription = pageEntry
    ? pageEntry.content
      ? (
          getPlainTextContentFromStructuredText(
            focusedEntryStructuredTextWithOnlyFirstNode as StructuredText
          ) as string | null | undefined
        )?.trim() ||
        plainTextTitle ||
        defaultDescription
      : defaultDescription
    : defaultDescription;

  useOnMount(() => {
    setSidebarPortalDestination(getSiteSidebarPortalDestination());

    const categoryInUrlParams = getUrlQueryParam("category");
    const categoryId =
      categories.find(c => c.slug === categoryInUrlParams)?.id ?? "";
    if (categoryInUrlParams) {
      categoryFilterFormState[1]({ categoryId });
      updateCategory();
    } else {
      const slugInUrl =
        window.location.pathname.match(/\/whats-new\/([^/]+)/)?.[1] ?? "";
      hasFinishedInitialLoadRef.current = !slugInUrl;
      preloadToEntry(slugInUrl);
    }

    const handleScroll = () => {
      if (window.scrollY > 32) {
        highlightActiveItemInTimelineAndUpdateURL();
      }
      const shouldQueueNextPage =
        !hasReachedEndRef.current &&
        window.scrollY >
          document.documentElement.scrollHeight -
            window.innerHeight -
            (document.getElementById("site-footer")?.clientHeight ?? 0);
      if (shouldQueueNextPage) queueNextPage();
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    highlightActiveItemInTimelineAndUpdateURL();
  });

  const hasError =
    !isFetching && pageObjectsRef.current.some(p => p.request === "error");

  const handleTimelineEntryClick = (entry: ProductUpdateTimelineEntry) => {
    if (entries.find(e => e.id === entry.id)) return;
    preloadToEntry(entry.slug ?? "");
    scrollToHash({
      useHash: "#fetchStatusBoxContainer",
      doNotPushState: true,
    });
  };

  const updateCategory = async () => {
    await resolveAfter();
    setUrlQueryParam("category", selectedCategorySlugRef.current);
    scrollToTop();
    setTimeline([]);
    pageObjectsRef.current.length = 0;
    setHasReachedEnd(false);
    setLastFetchedPage(0);
    setIsFetching(false);
    queueNextPage();
  };

  const categorySelector = (
    <Select
      formState={categoryFilterFormState}
      name="categoryId"
      className={css`
        ${fromDesktopMd} {
          margin-right: -2em;
        }
        ${darkModeLinariaCSS(`
          select {
            border-color: ${colors.dark500}
          }
        `)}
      `}
      options={[
        { label: "View all updates", value: "" },
        ...categories.map(c => ({
          label: `${c.name}`,
          value: `${c.id}`,
        })),
      ]}
      onValueHadChanged={() => {
        updateCategory();
      }}
    />
  );

  return (
    <>
      <SEO title={ogTitle} image={ogImage} description={ogDescription} />
      {sidebarPortalDestination &&
        createPortal(
          <StandardSidebar>
            <SidebarContent>
              <h1>What’s new</h1>
              <Spacing size="gap" />
              <p>Read about all the latest Tines features and updates.</p>
              <Spacing size="gap" />
              {categorySelector}
              <Spacing size="gap" />
              {timelineRef.current.length === 0 ? (
                <TimelineLoading>
                  <LoadingIndicator size={24} />
                </TimelineLoading>
              ) : (
                <ProductUpdateTimeline
                  entries={timelineRef.current}
                  focusedEntry={pageEntry as ProductUpdateTimelineEntry | null}
                  onTimelineEntryClick={handleTimelineEntryClick}
                />
              )}
            </SidebarContent>
          </StandardSidebar>,
          sidebarPortalDestination
        )}
      <WiderArticlePageBody>
        <PageBreadCrumbs
          mobileOnly
          icon={<GiftTwoToneIcon />}
          levels={[
            { title: "What’s new", path: "/whats-new" },
            ...(activeLinkTitle && activeLinkPath
              ? [{ title: activeLinkTitle, path: activeLinkPath }]
              : []),
          ]}
        />
        <WhatsNewPageContentWrap>
          {entries.map(entry => (
            <ProductUpdateFullEntry
              key={entry.id}
              entry={entry}
              onCategoryIconClick={categoryId => {
                categoryFilterFormState[1]({
                  categoryId,
                });
                updateCategory();
              }}
            />
          ))}
          <LoadingStateBanner
            id="fetchStatusBoxContainer"
            stickToBottomWhenLoading
            loading={isFetching}
            error={hasError ? "Error loading more updates." : undefined}
            children={
              hasReachedEndRef.current ? "You have reached the end." : undefined
            }
          />
        </WhatsNewPageContentWrap>
        <ColorSchemeToggle invisible />
      </WiderArticlePageBody>
    </>
  );
};

export const productUpdateCategoriesQuery = graphql`
  query ProductUpdateCategories {
    categories: allDatoCmsProductUpdateCategory(sort: { name: ASC }) {
      nodes {
        id: originalId
        name
        slug
      }
    }
  }
`;

export default ProductUpdatePageContent;
