import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { styled } from "@linaria/react";
import {
  getWidthPxInMaxGrid,
  widthInGrid,
} from "../../constants/globalGrid.constants";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  CSSProperties,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { cx } from "linaria";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopSm,
  fromTabletMd,
  onlyPhones,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import gsap from "gsap";
import { AutoCelloIcon } from "../cases/icons/AutoCelloIcon";
import imgSecurity from "../../../static/images/medium-illustrations/tines-illo-security-operations--framed.svg";
import imgGRC from "../../../static/images/medium-illustrations/tines-illo-compliance--framed.svg";
import imgIncidentsAndAlerts from "../../../static/images/medium-illustrations/tines-illo-incidents-and-alerts--framed.svg";
import imgAssetManagement from "../../../static/images/medium-illustrations/tines-illo-asset-management--framed.svg";
import imgEmployeeLifecycleManagement from "../../../static/images/medium-illustrations/tines-illo-employee-lifecycle-management--framed.svg";
import imgChatOps from "../../../static/images/medium-illustrations/tines-illo-chatops--framed.svg";
import imgZeroTrust from "../../../static/images/medium-illustrations/tines-illo-zero-trust--framed.svg";

const defs = [
  {
    title: "Security",
    icon: imgSecurity,
    bg: colors.purple50,
    bgDarker: colors.purple100,
    fg: colors.purple800,
    primary: colors.purple,
    border: colors.purple100,
    borderDarker: colors.purple200,
    description:
      "Across security operations, threat intelligence, vulnerability management, and IAM, SOC teams are improving their operational efficiency and reinforcing their overall security posture with Tines.",
  },
  {
    title: "Governance, Risk and Compliance",
    icon: imgGRC,
    bg: colors.green50,
    bgDarker: colors.green100,
    fg: colors.green800,
    primary: colors.green,
    border: colors.green100,
    borderDarker: colors.green200,
    description:
      "Organizations maintain consistent compliance standards, risk assessments, and gain real-time visibility into their GRC posture, ultimately reducing manual effort and improving overall security governance.",
  },
  {
    title: "Managing incidents and alerts",
    icon: imgIncidentsAndAlerts,
    bg: colors.red50,
    bgDarker: colors.red100,
    fg: colors.red800,
    primary: colors.red,
    border: colors.red100,
    borderDarker: colors.red200,
    description:
      "The flexible, drag-and-drop interface enables efficient triage, investigation, and remediation of security incidents and alerts across various tools and systems.",
  },
  {
    title: "System integration",
    icon: imgAssetManagement,
    bg: colors.orange50,
    bgDarker: colors.orange100,
    fg: colors.orange800,
    primary: colors.orange,
    border: colors.orange100,
    borderDarker: colors.orange200,
    description:
      "Remove silos across your tech by securely integrating across internal and external systems. If it has an API, Tines integrates with it.",
  },
  {
    title: "Employee lifecycle management",
    icon: imgEmployeeLifecycleManagement,
    bg: colors.pink50,
    bgDarker: colors.pink100,
    fg: colors.pink800,
    primary: colors.pink,
    border: colors.pink100,
    borderDarker: colors.pink200,
    description:
      "From an employee's first day to shift management to their last, customers manage these repetitive, important processes and work better together at all stages of the employee journey.",
  },
  {
    title: "ChatOps",
    icon: imgChatOps,
    bg: colors.purple50,
    bgDarker: colors.purple100,
    fg: colors.purple800,
    primary: colors.purple,
    border: colors.purple100,
    borderDarker: colors.purple200,
    description:
      "Increase transparency without the headache of more messages and emails. Enable your teams to reduce their response time without distracting from their high-impact work.",
  },
  {
    title: "Zero trust",
    icon: imgZeroTrust,
    bg: colors.green50,
    bgDarker: colors.green100,
    fg: colors.green800,
    primary: colors.green,
    border: colors.green100,
    borderDarker: colors.green200,
    description:
      "Automate and orchestrate rapid response to security events, seamless system integration, and managing critical security processes like continuous authentication, access control, and threat detection across distributed environments.",
  },
];

const Inner = styled.div`
  margin-top: -3.2rem;
  h2 {
    ${fromDesktop} {
      max-width: ${widthInGrid(8)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Desc = styled.p`
  max-width: ${widthInGrid(6)};
  font-size: 1.6rem;
  margin: auto;
`;

const Layout = styled.div`
  ${fromTabletMd} {
    display: grid;
    grid-template-columns: 8.5em minmax(0, 1fr);
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopMd} {
    grid-template-columns: ${getWidthPxInMaxGrid(2)} minmax(0, 1fr);
  }
`;

const TabList = styled.div`
  ${uptoTabletMd} {
    display: none;
  }
  ${fromTabletMd} {
    display: grid;
    position: relative;
    grid-gap: 0.75em;
    grid-template-rows: repeat(7, auto);
    z-index: 10;
    font-size: 13px;
    font-weight: 600;
  }
  ${fromDesktop} {
    font-size: 14px;
  }
  ${fromDesktopMd} {
    font-size: 16px;
    font-weight: 500;
  }
`;

const Chevron = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + ${rSize("gap")} + 2px);
  transform: translate(-100%, -50%);
  transition: 0.2s;
  svg {
    display: block;
    transition: opacity 0.5s, transform 0.2s;
    path {
      &:nth-child(1) {
        fill: var(--border);
      }
      &:nth-child(2) {
        fill: var(--bg);
      }
    }
  }
`;

const Tab = styled.button`
  position: relative;
  appearance: none;
  margin: 0;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  background-color: var(--bg);
  cursor: pointer;
  padding: 0.6em 0.8em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  min-height: 5.4rem;
  &:hover {
    border-color: var(--borderDarker);
  }
  &.inactive {
    ${Chevron} {
      opacity: 0;
      svg {
        transform: translateX(10px);
      }
    }
  }
  &.active {
    background-color: var(--bgDarker);
  }
`;

const Contents = styled.div`
  position: relative;
`;

const ContentCard = styled.figure`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "heading" "desc" "img";
  grid-gap: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  ${uptoTabletMd} {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  ${fromTabletMd} {
    grid-template-columns: 2fr minmax(38%, 1fr);
    grid-template-rows: minmax(auto, 1fr) minmax(0, auto);
    grid-template-areas: "heading icon" "desc desc";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    transition: opacity 0.5s;
    &.inactive {
      opacity: 0;
      z-index: 1;
      pointer-events: none;
    }
    &.active {
      opacity: 1;
      z-index: 1;
    }
  }
  ${fromDesktopSm} {
    grid-template-areas: "heading ." "desc icon";
  }
  ${fromDesktopMd} {
    padding-top: 4em;
  }
`;

const ContentCardHeading = styled.h3`
  ${fromTabletMd} {
    grid-area: heading;
  }
  font-family: ${serif};
  line-height: 1.15;
  font-size: 2.4rem;
  font-weight: 400;
  ${onlyPhones} {
    max-width: 9em;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromDesktopMd} {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.02em;
    font-size: 3.6rem;
    max-width: 9em;
  }
`;

const ContentCardDesc = styled.div`
  ${fromTabletMd} {
    grid-area: desc;
  }
  align-self: end;
  p {
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 500;
    max-width: 33em;
  }
`;

const ContentCardIconWrap = styled.div`
  pointer-events: none;
  img {
    aspect-ratio: 1/1;
    max-width: 60%;
    margin: 0 -10% -10%;
  }
  ${fromTabletMd} {
    grid-area: icon;
    text-align: right;
    img {
      max-width: 100%;
      margin: -15% -9% -20% -15%;
      height: auto;
    }
  }
  ${fromDesktopMd} {
    align-self: end;
    img {
      width: 275px;
      margin: -15% -9% -20% -15%;
    }
  }
`;

const ContentCardNextButton = styled.button`
  ${uptoTabletMd} {
    display: none;
  }
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  display: block;
  position: absolute;
  left: calc(100% + 0.5px);
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  svg {
    display: block;
  }
  circle:first-of-type {
    transition: 0.1s;
  }
  &:hover {
    circle:first-of-type {
      fill: var(--buttonHover);
    }
  }
`;

const ProgressCircle = styled.circle`
  stroke-dasharray: 191.54; /* 2 * π * radius = 2 * 3.14 * 31.5 = 191.54 */
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  transform-origin: 38.5px 43.5px;
  stroke: var(--primary);
  opacity: 0;
  .autoplaying .active & {
    opacity: 0.5;
    stroke-width: 2;
  }
`;

const EnterpriseUseCasesSection = () => {
  const [currentIndexRef, setCurrentIndex, currentIndex] = useStateWithRef(0);

  const visibilityDetectionRef = useRef<HTMLDivElement>(null);

  const [hasManuallyPaused, setHasManuallyPaused] = useState(false);
  const [isAutoplaying, setIsAutoplaying] = useState(false);
  const isAutoplayingRef = useRef(isAutoplaying);
  isAutoplayingRef.current = isAutoplaying;

  const tweenRef = useRef<GSAPTween>(null) as MutableRefObject<GSAPTween>;

  const next = () => {
    setCurrentIndex(
      currentIndexRef.current >= defs.length - 1
        ? 0
        : currentIndexRef.current + 1
    );
    if (isAutoplayingRef.current) startAnimation();
  };

  const pause = () => {
    tweenRef.current?.pause();
    setIsAutoplaying(false);
  };

  const startAnimation = () => {
    if (window.innerWidth < 768) {
      pause();
      return;
    }
    setTimeout(() => {
      tweenRef.current?.kill();
      tweenRef.current = gsap.fromTo(
        visibilityDetectionRef.current?.querySelector(
          ".active .progressCircle"
        ) ?? null,
        {
          strokeDashoffset: 191.54,
        },
        {
          strokeDashoffset: 0,
          duration: 10,
          ease: "linear",
          onComplete: () => {
            if (isAutoplayingRef.current) next();
          },
        }
      );
    });
  };

  const goToIndex = (i: number) => {
    setIsAutoplaying(false);
    setCurrentIndex(i);
  };

  const resume = () => {
    setIsAutoplaying(true);
    startAnimation();
  };

  const handleNextButtonClick = () => {
    setIsAutoplaying(false);
    setHasManuallyPaused(true);
    next();
  };
  const handleNextButtonPointerEnter = () => {
    pause();
  };
  const handleNextButtonPointerLeave = () => {
    if (hasManuallyPaused) return;
    else resume();
  };

  useOnMount(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsAutoplaying(true);
        startAnimation();
      } else {
        pause();
      }
    });
    observer.observe(visibilityDetectionRef.current!);
    return () => {
      observer.disconnect();
      tweenRef.current?.kill();
    };
  });

  useEffect(() => {});

  return (
    <PageSection centered className={cx(isAutoplaying && "autoplaying")}>
      <Inner>
        <AutoCelloIcon />
        <Spacing size="1em" />
        <SectionHeading2 lighter>
          A platform for organization-wide orchestration and automation
        </SectionHeading2>
        <Spacing size="md" />
        <Desc>
          Tines is powerful enough to handle the most complex security and IT
          processes without compromising on user experience.
        </Desc>

        <Spacing size="xl" />

        <Layout>
          <TabList>
            {defs.map((d, i) => (
              <Tab
                key={i}
                className={cx(i === currentIndex ? "active" : "inactive")}
                onClick={() => goToIndex(i)}
                style={
                  {
                    "--bg": d.bg,
                    "--bgDarker": d.bgDarker,
                    color: d.fg,
                    "--border": d.border,
                    "--borderDarker": d.borderDarker,
                  } as CSSProperties
                }
              >
                <div>{d.title}</div>
                <Chevron>
                  <svg width="11" height="33" viewBox="0 0 11 33" fill="none">
                    <path d="M11 0.91095C10.4053 1.45914 10.0327 2.24473 10.0327 3.11738V3.55808C10.0327 4.33568 9.73083 5.08278 9.19072 5.64208L1.53376 13.5711C-0.000838481 15.1602 -0.000837766 17.7367 1.53376 19.3258L9.19073 27.2548C9.73083 27.8141 10.0327 28.5612 10.0327 29.3387V30.1174C10.0327 30.99 10.4053 31.7756 11 32.3238V0.91095Z" />
                    <path d="M11 4.52261C10.9122 5.14618 10.6296 5.73029 10.1873 6.18836L2.36321 14.2904C1.21226 15.4823 1.21226 17.4146 2.36321 18.6065L10.1873 26.7085C10.6296 27.1665 10.9122 27.7506 11 28.3742V4.52261Z" />
                  </svg>
                </Chevron>
              </Tab>
            ))}
          </TabList>

          <Contents ref={visibilityDetectionRef}>
            {defs.map((d, i) => (
              <ContentCard
                key={i}
                className={cx(i === currentIndex ? "active" : "inactive")}
                style={
                  {
                    backgroundColor: d.bg,
                    color: d.fg,
                    "--border": d.border,
                    "--primary": d.primary,
                    "--buttonHover": d.borderDarker,
                  } as CSSProperties
                }
              >
                <ContentCardHeading>{d.title}</ContentCardHeading>
                <ContentCardDesc>
                  <p>{d.description}</p>
                </ContentCardDesc>
                {d.icon && (
                  <ContentCardIconWrap>
                    <img src={d.icon} />
                  </ContentCardIconWrap>
                )}
                <ContentCardNextButton
                  onClick={handleNextButtonClick}
                  onPointerEnter={handleNextButtonPointerEnter}
                  onPointerLeave={handleNextButtonPointerLeave}
                >
                  <svg width="75" height="88" viewBox="0 0 75 88" fill="none">
                    <path
                      d="M37.9 0H31.5C29.2909 0 27.5 1.79086 27.5 4V5.52178C27.5 7.29446 26.3242 8.83489 24.6706 9.4737C10.8226 14.8235 1 28.2647 1 44C1 59.7353 10.8226 73.1765 24.6706 78.5263C26.3242 79.1651 27.5 80.7055 27.5 82.4782V84C27.5 86.2091 29.2909 88 31.5 88H37.9C37.9552 88 38 87.9552 38 87.9V85C38 82.7909 39.7972 81.0227 41.9937 80.787C60.55 78.7955 75 63.0852 75 44C75 24.9148 60.55 9.20455 41.9937 7.21303C39.7972 6.97729 38 5.20914 38 3V0.1C38 0.0447715 37.9552 0 37.9 0Z"
                      fill={d.border}
                    />
                    <path
                      d="M36.9 0H4.5C2.01472 0 0 2.01472 0 4.5V44V83.5C0 85.9853 2.01472 88 4.5 88H36.9C36.9552 88 37 87.9539 37 87.8987V84.5C37 82.0147 39.024 80.028 41.4923 79.7373C59.8082 77.5804 74 62.4028 74 44C74 25.5972 59.8082 10.4196 41.4923 8.26265C39.024 7.97199 37 5.98528 37 3.5V0.101319C37 0.0460904 36.9552 0 36.9 0Z"
                      fill={d.bg}
                    />
                    <circle cx="38.5" cy="43.5" r="31.5" fill={d.bgDarker} />
                    <ProgressCircle
                      className="progressCircle"
                      cx="38.5"
                      cy="43.5"
                      r="30.5"
                      fill="transparent"
                    />
                    <path
                      d="M47 45.4082L39.5 53L32 45.4082L33.3354 43.8716L38.524 49.1236L38.524 34L40.476 34L40.476 49.1236L45.6646 43.8716L47 45.4082Z"
                      fill={d.fg}
                    />
                  </svg>
                </ContentCardNextButton>
              </ContentCard>
            ))}
          </Contents>
        </Layout>
      </Inner>
    </PageSection>
  );
};

export default EnterpriseUseCasesSection;
