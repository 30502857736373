import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktopSm,
  fromPhoneLg,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { graphql, useStaticQuery } from "gatsby";
import {
  DatoCmsContentCard,
  EnterprisePageFeaturedContentCardsQuery,
} from "../../../graphql-types";
import { ContentCardDispatcher } from "../contentCards/_ContentCardDispatcher";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopSm} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  > * {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      grid-row-end: span 2;
      min-height: 22.5rem;
      ${fromPhoneLg} {
        min-height: 30rem;
      }
      ${fromDesktopSm} {
        min-height: 22.5rem;
      }
    }
    &:nth-child(4),
    &:nth-child(5) {
      ${fromDesktopSm} {
        figure {
          margin-top: -0.75em;
          img {
            transform: translate(0.5em, 0.5em);
          }
        }
      }
    }
  }
`;

const EnterpriseRelatedContentSection = () => {
  const queryResult = useStaticQuery<EnterprisePageFeaturedContentCardsQuery>(
    enterprisePageFeaturedContentCardsQuery
  );
  return (
    <PageSection>
      <Grid>
        {queryResult.data?.featuredContentCards?.map((c, i) => (
          <ContentCardDispatcher card={c as DatoCmsContentCard} key={i} />
        ))}
      </Grid>
    </PageSection>
  );
};

export const enterprisePageFeaturedContentCardsQuery = graphql`
  query EnterprisePageFeaturedContentCards {
    data: datoCmsEnterprisePage {
      featuredContentCards {
        preset
        linkToRecord {
          ... on DatoCmsArticle {
            id: originalId
            slug
            title
            model {
              apiKey
            }
          }
          ... on DatoCmsWebinar {
            id: originalId
            slug
            title
            colorTheme
            category {
              slug
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsCaseStudy {
            id: originalId
            color
            slug
            title
            customer {
              logoDarkGray {
                url
                width
                height
              }
              logoWhite {
                url
                width
                height
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsPodcast {
            id: originalId
            applePodcastsLink
            color
            episodeNumber
            title
            spotifyLink
            season {
              seasonNumber
              title
            }
            guestSpeaker {
              name
              surname
              title
              organization {
                name
              }
            }
            guestSpeakerImageTransparent {
              url
              width
              height
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsDocument {
            id: originalId
            title
            slug
            category {
              name
              slug
            }
            previewImage {
              url
              width
              height
            }
            previewImageBackgroundColor {
              hex
            }
            previewImageShadowColor {
              hex
            }
            model {
              apiKey
            }
          }
        }
        linkLabel
        linkUrl
        preHeadingTag
        heading
        description
        size
        color
        colorMode
        backgroundImage {
          url
        }
        icon {
          url
          width
          height
        }
      }
    }
  }
`;

export default EnterpriseRelatedContentSection;
