import { graphql } from "gatsby";
import { PageComponent } from "../types/gatsby.types";
import Page from "./Page.template";
import {
  DatoCmsActionTemplate,
  DatoCmsArticle,
  DatoCmsCaseStudy,
  DatoCmsLibraryTool,
  DatoCmsProduct,
  DatoCmsProductFamily,
  DatoCmsStoryDigest,
  ProductFamilyPageQuery,
} from "../../graphql-types";
import { colors, withOpacity } from "../styles/colors.styles";
import SEO from "../components/utilities/SEO";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { ProductExplorerPageHeaderGraphics } from "../components/productExplorer/ProductExplorerPageHeaderGraphics";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import Spacing from "../components/layout/Spacing";
import PageSection from "../components/reusableSections/PageSection";
import ProductExplorerEntry, {
  ProductExplorerEntryGrid,
} from "../components/productExplorer/ProductExplorerEntry";
import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopSm,
  fromPhoneLg,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../styles/fonts.styles";
import ProductExplorerPseudoSearchButton from "../components/productExplorer/ProductExplorerPseudoSearchButton";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import { rSize } from "../styles/responsiveSizes.styles";
import Button, {
  ButtonGroupCenteredUptoTabletMd,
} from "../components/forms/Button";
import BookADemoButton from "../components/utilities/BookADemoButton";
import SignUpButton from "../components/utilities/SignUpButton";
import { SolutionByProductPageCustomerLogoSection } from "../components/productExplorer/SolutionByProductPageCustomerLogoSection";
import ContentCardArticle from "../components/contentCards/ContentCardArticle";
import ContentCardBlog from "../components/contentCards/ContentCardBlog";
import ContentCardCaseStudies from "../components/contentCards/ContentCardCaseStudies";
import { ContentCardCaseStudy } from "../components/contentCards/ContentCardCaseStudy";
import ContentCardDocs from "../components/contentCards/ContentCardDocs";
import { ContentCardLibrary } from "../components/contentCards/ContentCardLibrary";
import ContentCardUniversitySimple from "../components/contentCards/ContentCardUniversitySimple";
import { ProductExplorerPageSectionHeader } from "../components/productExplorer/ProductExplorerPageSectionHeader";
import product from "../pages/product";
import StoryEmbed from "../components/blocks/StoryEmbed.block";
import { BlocksOnShelvesIcon } from "../components/illustrations/spots/BlocksOnShelvesIcon";
import { StoryGrid } from "../components/library/StoryGrid";
import ActionTemplateBrowser from "../components/productExplorer/ActionTemplateBrowser";
import { BuildYourOwnConnectionsSection } from "../components/productExplorer/BuildYourOwnConnectionsSection";
import { css, cx } from "linaria";
import OnlyPhonesDiv from "../components/mediaQueries/OnlyPhones";
import { PurpleMapIcon } from "../components/productExplorer/icons/PurpleMapIcon";
import fallbackOgImage from "../../static/images/og/tines-solutions-by-product.png";
import { convertDatoRecordToStoryDescriptor } from "../utils/story.utils";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";

const HeaderSectionInner = styled.div`
  display: grid;
  text-align: center;
  grid-template-rows: auto auto;
  grid-template-areas: "icons" "text";
  grid-gap: 2em;
`;
const HeaderSectionTextContent = styled.div`
  grid-area: text;
  > div:first-child {
    a {
      text-decoration: none;
    }
  }
  > * + * {
    margin-top: ${rSize("gap")};
  }
`;
const PageHeading = styled.h1`
  font-family: ${serif};
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: -0.015em;
  ${fromTabletMd} {
    font-size: 6.4rem;
    font-weight: 300;
    line-height: 1;
  }
  ${fromDesktop} {
    font-size: 7.2rem;
    line-height: 0.98;
    letter-spacing: -0.0175em;
  }
`;

const Description = styled.p`
  font-size: 1.6rem;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  max-width: 42em;
  a {
    text-decoration: none;
    background-image: linear-gradient(
      to top,
      transparent 0,
      transparent 0.1em,
      ${withOpacity(colors.purple800, 0.5)} 0.1em,
      ${withOpacity(colors.purple800, 0.5)} calc(0.1em + 1px),
      transparent calc(0.1em + 1px),
      transparent 100%
    );
    &:hover {
      color: ${colors.purple600};
      background-image: linear-gradient(
        to top,
        transparent 0,
        transparent 0.1em,
        ${withOpacity(colors.purple600, 0.7)} 0.1em,
        ${withOpacity(colors.purple600, 0.7)} calc(0.1em + 1px),
        transparent calc(0.1em + 1px),
        transparent 100%
      );
    }
  }
`;

const ProductFamilyIconBubble = styled.div`
  width: 9rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple200};
  border-radius: 50%;
  z-index: 2;
  ${onlyPhones} {
    margin: -1em auto 2em;
  }
  img {
    width: 4.2rem;
    height: auto;
  }
`;

const RelatedContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopSm} {
    grid-template-columns: repeat(4, 1fr);
  }
  min-height: 22.5rem;
  ${fromPhoneLg} {
    min-height: 30rem;
  }
  ${fromDesktopSm} {
    min-height: 36rem;
  }
`;
const ProductGridSectionContentOuter = styled.div`
  position: relative;
  padding-bottom: 3em;
  ${fromTablet} {
    padding-bottom: 5em;
  }
  ${fromDesktop} {
    padding-bottom: 6em;
  }
`;

const ProductGridSectionContentInner = styled.div`
  &:before {
    ${fromTabletMd} {
      content: "";
      border: 1px solid ${colors.purple200};
      border-radius: ${rSize("radius")};
      position: absolute;
      pointer-events: none;
      top: 4.5rem;
      bottom: 0;
      left: 0;
      right: 0;
      left: calc(
        ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
      );
      right: calc(
        ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
      );
    }
  }
`;

const ProductGridSectionHeadingWrap = styled.div`
  display: inline-block;
  h2 {
    padding-left: 1em;
    padding-right: 1em;
    background-color: ${colors.lightest};
    margin-left: -1em;
    display: inline-block;
  }
`;

const getDescriptionWithWebsiteLink = (f: DatoCmsProductFamily) => {
  const regex = new RegExp(`\\b${f.name}\\b`);
  return f.website
    ? (f.description ?? "").replace(
        regex,
        `<a href="${f.website}" target="_blank" rel="noreferrer noopener">${f.name}</a>`
      )
    : f.description ?? "";
};

const ProductFamilyPageTemplate: PageComponent<{
  data: ProductFamilyPageQuery;
}> = props => {
  const family = props.data.productFamily as DatoCmsProductFamily;
  const products = family.products as DatoCmsProduct[];
  const tool = props.data.tool as DatoCmsLibraryTool;
  const stories = ((family.firstSixStories ?? []) as DatoCmsStoryDigest[])
    .map(convertDatoRecordToStoryDescriptor)
    .filter(s => !s.isPrivate && !s.isUnlisted);
  const shouldRenderLibrarySection = stories.length > 0;
  const actionTemplates = family.actionTemplates as DatoCmsActionTemplate[];
  const latestRelatedArticle = props.data
    .latestRelatedArticle as DatoCmsArticle | null;
  const latestRelatedCaseStudy = props.data
    .latestRelatedCaseStudy as DatoCmsCaseStudy | null;
  const caseStudy = props.data.caseStudy as DatoCmsCaseStudy | null;
  const caseStudyToShow = caseStudy ?? latestRelatedCaseStudy;
  useOnMount(() => {
    reportIntent(`Viewed product family "${family.name}" in product explorer`);
  });
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title={`Tines × ${family.name}`}
        image={family.pagePreviewImage?.url ?? fallbackOgImage}
        description={family.description}
        noindex={family.noindex}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="productFamily"
        recordId={family.id}
      />
      <ProductExplorerPageHeaderGraphics
        light
        className={css`
          z-index: 3;
        `}
      />

      <PageSection
        relative
        centered
        className={css`
          z-index: 4;
        `}
      >
        <SiteNavHeightPlaceholder />
        <Spacing size="1em" />
        <HeaderSectionInner>
          <HeaderSectionTextContent>
            {family.icon?.url && (
              <OnlyPhonesDiv>
                <ProductFamilyIconBubble>
                  <img src={family.icon.url} />
                </ProductFamilyIconBubble>
              </OnlyPhonesDiv>
            )}
            <ProductExplorerPseudoSearchButton />
            <PageHeading>{family.name} × Tines</PageHeading>
            <Description
              dangerouslySetInnerHTML={{
                __html: getDescriptionWithWebsiteLink(family),
              }}
            />
            <ButtonGroupCenteredUptoTabletMd>
              <BookADemoButton appearance="filled" darker width="8em" />
              <SignUpButton appearance="outlined" darker width="8em" />
            </ButtonGroupCenteredUptoTabletMd>
          </HeaderSectionTextContent>
        </HeaderSectionInner>
      </PageSection>

      <Spacing size="sectionPadding" />

      <PageSection relative>
        <ProductGridSectionContentOuter>
          <ProductGridSectionContentInner>
            <ProductExplorerPageSectionHeader
              className={cx(
                "alignCenter",
                css`
                  z-index: 2;
                  ${onlyPhones} {
                    display: none;
                  }
                `
              )}
            >
              <ProductGridSectionHeadingWrap>
                <h2>Browse {family.name} products</h2>
              </ProductGridSectionHeadingWrap>
              <div>
                {family.icon?.url && (
                  <ProductFamilyIconBubble>
                    <img src={family.icon.url} />
                  </ProductFamilyIconBubble>
                )}
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            <ProductExplorerEntryGrid>
              {products.map(p => (
                <ProductExplorerEntry product={p} key={p.id} />
              ))}
            </ProductExplorerEntryGrid>
          </ProductGridSectionContentInner>
        </ProductGridSectionContentOuter>
      </PageSection>

      {actionTemplates.length > 0 && (
        <>
          <Spacing size="subsectionMargin" />
          <PageSection>
            <ProductExplorerPageSectionHeader>
              <div>
                <h2>Pre-built templates</h2>
                <p>
                  With Tines, you can easily take any action that has a defined
                  API. We've already pre-built some of the most popular ones for
                  you, so you can build quickly.
                </p>
              </div>
              <div>
                <BlocksOnShelvesIcon />
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            <ActionTemplateBrowser
              actionTemplates={actionTemplates}
              family={family}
              showProductName
            />
          </PageSection>
        </>
      )}

      <Spacing size="xxl" />
      <BuildYourOwnConnectionsSection />

      {shouldRenderLibrarySection && (
        <>
          <Spacing size="subsectionMargin" />
          <PageSection>
            <ProductExplorerPageSectionHeader>
              <div>
                <h2>Full workflow examples</h2>
                <p>
                  Explore pre-built workflows for {product.name}. Use them for
                  inspiration or as a starting point to build your custom
                  automation solution.
                </p>
              </div>
              <div>
                {tool ? (
                  <Button
                    to={`/library/tools/${tool.slug}`}
                    appearance="outlined"
                    darker
                  >
                    Explore all →
                  </Button>
                ) : (
                  <PurpleMapIcon />
                )}
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            {stories.length === 1 ? (
              <StoryEmbed descriptor={stories[0]} fullWidth />
            ) : (
              <StoryGrid stories={stories} />
            )}
          </PageSection>
        </>
      )}

      <Spacing size="subsectionMargin" />
      <PageSection>
        <ProductExplorerPageSectionHeader>
          <div>
            <h2>Explore more resources</h2>
            <p>
              Check out our blogs
              {latestRelatedArticle ? ` that mention ${product.name}` : ""},
              browse our learning paths, and more.
            </p>
          </div>
        </ProductExplorerPageSectionHeader>
        <Spacing size="xl" />
        <RelatedContentGrid>
          {latestRelatedArticle ? (
            <ContentCardArticle
              article={latestRelatedArticle}
              displayDefaultBlogIcon
            />
          ) : (
            <ContentCardBlog overrides={{ color: "orange" }} />
          )}
          {caseStudyToShow ? (
            <ContentCardCaseStudy caseStudy={caseStudyToShow} />
          ) : (
            <ContentCardCaseStudies />
          )}
          <ContentCardUniversitySimple overrides={{ color: "pink" }} />
          {shouldRenderLibrarySection ? (
            <ContentCardDocs />
          ) : (
            <ContentCardLibrary overrides={{ colorMode: "light" }} />
          )}
        </RelatedContentGrid>
      </PageSection>

      <Spacing size="subsectionMargin" />
      <SolutionByProductPageCustomerLogoSection />

      <Spacing size="subsectionMargin" />
      <ExplosionCTASection />
    </Page>
  );
};

export default ProductFamilyPageTemplate;

export const productFamilyPageQuery = graphql`
  query ProductFamilyPage($slug: String) {
    productFamily: datoCmsProductFamily(slug: { eq: $slug }) {
      id: originalId
      name
      slug
      description
      website
      noindex
      icon {
        url
        width
        height
      }
      pagePreviewImage {
        url
      }
      products {
        id: originalId
        name
        slug
        icon {
          url
          width
          height
        }
      }
      actionTemplates {
        name
        key
        product {
          id: originalId
        }
      }
      firstSixStories {
        id: originalId
        storyId
        descriptor
        internalAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        communityAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        videoIntroEmbedUrl
        gallery {
          url
          width
          height
        }
      }
    }
    tool: datoCmsLibraryTool(slug: { eq: $slug }) {
      id: originalId
      slug
    }
    latestRelatedArticle: datoCmsArticle(
      tags: { elemMatch: { slug: { eq: $slug } } }
      categories: { elemMatch: { slug: { in: ["news", "tutorials"] } } }
    ) {
      id: originalId
      slug
      title
    }
    latestRelatedCaseStudy: datoCmsCaseStudy(
      tags: { elemMatch: { slug: { eq: $slug } } }
    ) {
      id: originalId
      color
      slug
      title
      customer {
        logoWhite {
          url
          width
          height
        }
      }
    }
    caseStudy: datoCmsCaseStudy(customer: { slug: { eq: $slug } }) {
      id: originalId
      color
      slug
      title
      customer {
        logoWhite {
          url
          width
          height
        }
      }
    }
  }
`;
