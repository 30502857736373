import { styled } from "@linaria/react";
import { PropsWithChildren, ReactNode } from "react";
import { serif } from "../../styles/fonts.styles";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import Spacing from "../layout/Spacing";

type Props = { title: string; id: string; headerLink?: ReactNode };

const CustomerCenterPageSectionWrap = styled.section``;
const Header = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: baseline;
  grid-gap: 1em;
  h2 {
    font-family: ${serif};
    font-size: 2.4rem;
    font-weight: 400;
    ${fromTablet} {
      font-size: 2.8rem;
      letter-spacing: -0.01rem;
    }
  }
`;
const HeaderEnd = styled.div`
  text-align: right;
  font-size: 1.4rem;
  a {
    text-decoration: none;
    font-weight: 600;
    &:hover {
      color: ${colors.purple600};
    }
  }
`;

const Children = styled.div``;

const CustomerCenterPageSection = (props: PropsWithChildren<Props>) => {
  return (
    <CustomerCenterPageSectionWrap
      className="CustomerCenterPageSection"
      id={props.id}
    >
      <Header>
        <h2>{props.title}</h2>
        {props.headerLink && <HeaderEnd>{props.headerLink}</HeaderEnd>}
      </Header>
      <Spacing size="md" />
      <Children>{props.children}</Children>
    </CustomerCenterPageSectionWrap>
  );
};

export default CustomerCenterPageSection;
