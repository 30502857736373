export const ReportV2LargeChapterNumber3Dots = () => (
  <svg width="370" height="400" viewBox="0 0 370 400" fill="currentColor">
    <path
      d="M134.074 106.394C135.472 106.394 136.606 105.26 136.606 103.862C136.606 102.464 135.472 101.33 134.074 101.33C132.676 101.33 131.542 102.464 131.542 103.862C131.542 105.26 132.676 106.394 134.074 106.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M184.714 187.417C186.112 187.417 187.246 186.283 187.246 184.885C187.246 183.487 186.112 182.353 184.714 182.353C183.315 182.353 182.182 183.487 182.182 184.885C182.182 186.283 183.315 187.417 184.714 187.417Z"
      fillOpacity={0.3}
    />
    <path
      d="M194.841 187.417C196.239 187.417 197.373 186.283 197.373 184.885C197.373 183.487 196.239 182.353 194.841 182.353C193.442 182.353 192.309 183.487 192.309 184.885C192.309 186.283 193.442 187.417 194.841 187.417Z"
      fillOpacity={0.3}
    />
    <path
      d="M215.096 177.289C216.495 177.289 217.628 176.155 217.628 174.757C217.628 173.359 216.495 172.225 215.096 172.225C213.698 172.225 212.564 173.359 212.564 174.757C212.564 176.155 213.698 177.289 215.096 177.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.225 177.289C226.624 177.289 227.757 176.155 227.757 174.757C227.757 173.359 226.624 172.225 225.225 172.225C223.827 172.225 222.693 173.359 222.693 174.757C222.693 176.155 223.827 177.289 225.225 177.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M235.352 167.161C236.751 167.161 237.884 166.028 237.884 164.629C237.884 163.231 236.751 162.097 235.352 162.097C233.954 162.097 232.82 163.231 232.82 164.629C232.82 166.028 233.954 167.161 235.352 167.161Z"
      fillOpacity={0.3}
    />
    <path
      d="M245.48 157.033C246.879 157.033 248.012 155.9 248.012 154.501C248.012 153.103 246.879 151.969 245.48 151.969C244.082 151.969 242.948 153.103 242.948 154.501C242.948 155.9 244.082 157.033 245.48 157.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M255.608 146.905C257.007 146.905 258.14 145.772 258.14 144.373C258.14 142.975 257.007 141.841 255.608 141.841C254.21 141.841 253.076 142.975 253.076 144.373C253.076 145.772 254.21 146.905 255.608 146.905Z"
      fillOpacity={0.3}
    />
    <path
      d="M255.608 187.416C257.007 187.416 258.14 186.283 258.14 184.885C258.14 183.486 257.007 182.353 255.608 182.353C254.21 182.353 253.076 183.486 253.076 184.885C253.076 186.283 254.21 187.416 255.608 187.416Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 106.394C267.134 106.394 268.268 105.26 268.268 103.862C268.268 102.464 267.134 101.33 265.736 101.33C264.338 101.33 263.204 102.464 263.204 103.862C263.204 105.26 264.338 106.394 265.736 106.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 116.521C267.134 116.521 268.268 115.388 268.268 113.989C268.268 112.591 267.134 111.458 265.736 111.458C264.338 111.458 263.204 112.591 263.204 113.989C263.204 115.388 264.338 116.521 265.736 116.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 126.649C267.134 126.649 268.268 125.516 268.268 124.117C268.268 122.719 267.134 121.585 265.736 121.585C264.338 121.585 263.204 122.719 263.204 124.117C263.204 125.516 264.338 126.649 265.736 126.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 136.777C267.134 136.777 268.268 135.644 268.268 134.245C268.268 132.847 267.134 131.713 265.736 131.713C264.338 131.713 263.204 132.847 263.204 134.245C263.204 135.644 264.338 136.777 265.736 136.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 197.544C267.134 197.544 268.268 196.41 268.268 195.012C268.268 193.614 267.134 192.48 265.736 192.48C264.338 192.48 263.204 193.614 263.204 195.012C263.204 196.41 264.338 197.544 265.736 197.544Z"
      fillOpacity={0.3}
    />
    <path
      d="M275.864 177.289C277.262 177.289 278.396 176.155 278.396 174.757C278.396 173.358 277.262 172.225 275.864 172.225C274.466 172.225 273.332 173.358 273.332 174.757C273.332 176.155 274.466 177.289 275.864 177.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M275.864 197.544C277.262 197.544 278.396 196.41 278.396 195.012C278.396 193.614 277.262 192.48 275.864 192.48C274.466 192.48 273.332 193.614 273.332 195.012C273.332 196.41 274.466 197.544 275.864 197.544Z"
      fillOpacity={0.3}
    />
    <path
      d="M285.992 167.161C287.39 167.161 288.524 166.028 288.524 164.629C288.524 163.231 287.39 162.097 285.992 162.097C284.594 162.097 283.46 163.231 283.46 164.629C283.46 166.028 284.594 167.161 285.992 167.161Z"
      fillOpacity={0.3}
    />
    <path
      d="M296.12 157.033C297.518 157.033 298.652 155.9 298.652 154.501C298.652 153.103 297.518 151.969 296.12 151.969C294.721 151.969 293.588 153.103 293.588 154.501C293.588 155.9 294.721 157.033 296.12 157.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M306.247 136.777C307.645 136.777 308.779 135.644 308.779 134.245C308.779 132.847 307.645 131.713 306.247 131.713C304.848 131.713 303.715 132.847 303.715 134.245C303.715 135.644 304.848 136.777 306.247 136.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M113.818 86.2392C115.216 86.2392 116.35 85.1056 116.35 83.7073C116.35 82.3089 115.216 81.1753 113.818 81.1753C112.42 81.1753 111.286 82.3089 111.286 83.7073C111.286 85.1056 112.42 86.2392 113.818 86.2392Z"
      fillOpacity={0.3}
    />
    <path
      d="M113.818 96.3662C115.216 96.3662 116.35 95.2326 116.35 93.8342C116.35 92.4358 115.216 91.3022 113.818 91.3022C112.42 91.3022 111.286 92.4358 111.286 93.8342C111.286 95.2326 112.42 96.3662 113.818 96.3662Z"
      fillOpacity={0.3}
    />
    <path
      d="M123.946 65.9834C125.344 65.9834 126.478 64.8498 126.478 63.4514C126.478 62.053 125.344 60.9194 123.946 60.9194C122.548 60.9194 121.414 62.053 121.414 63.4514C121.414 64.8498 122.548 65.9834 123.946 65.9834Z"
      fillOpacity={0.3}
    />
    <path
      d="M123.946 76.1103C125.344 76.1103 126.478 74.9767 126.478 73.5784C126.478 72.18 125.344 71.0464 123.946 71.0464C122.548 71.0464 121.414 72.18 121.414 73.5784C121.414 74.9767 122.548 76.1103 123.946 76.1103Z"
      fillOpacity={0.3}
    />
    <path
      d="M134.074 55.8545C135.472 55.8545 136.606 54.7209 136.606 53.3225C136.606 51.9241 135.472 50.7905 134.074 50.7905C132.676 50.7905 131.542 51.9241 131.542 53.3225C131.542 54.7209 132.676 55.8545 134.074 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M134.074 96.3662C135.472 96.3662 136.606 95.2326 136.606 93.8342C136.606 92.4358 135.472 91.3022 134.074 91.3022C132.676 91.3022 131.542 92.4358 131.542 93.8342C131.542 95.2326 132.676 96.3662 134.074 96.3662Z"
      fillOpacity={0.3}
    />
    <path
      d="M144.202 45.7275C145.6 45.7275 146.734 44.5939 146.734 43.1955C146.734 41.7972 145.6 40.6636 144.202 40.6636C142.804 40.6636 141.67 41.7972 141.67 43.1955C141.67 44.5939 142.804 45.7275 144.202 45.7275Z"
      fillOpacity={0.3}
    />
    <path
      d="M144.202 86.2392C145.6 86.2392 146.734 85.1056 146.734 83.7073C146.734 82.3089 145.6 81.1753 144.202 81.1753C142.804 81.1753 141.67 82.3089 141.67 83.7073C141.67 85.1056 142.804 86.2392 144.202 86.2392Z"
      fillOpacity={0.3}
    />
    <path
      d="M154.329 45.7275C155.727 45.7275 156.861 44.5939 156.861 43.1955C156.861 41.7972 155.727 40.6636 154.329 40.6636C152.93 40.6636 151.797 41.7972 151.797 43.1955C151.797 44.5939 152.93 45.7275 154.329 45.7275Z"
      fillOpacity={0.3}
    />
    <path
      d="M154.329 76.1103C155.727 76.1103 156.861 74.9767 156.861 73.5784C156.861 72.18 155.727 71.0464 154.329 71.0464C152.93 71.0464 151.797 72.18 151.797 73.5784C151.797 74.9767 152.93 76.1103 154.329 76.1103Z"
      fillOpacity={0.3}
    />
    <path
      d="M164.458 35.5996C165.856 35.5996 166.99 34.466 166.99 33.0676C166.99 31.6692 165.856 30.5356 164.458 30.5356C163.059 30.5356 161.926 31.6692 161.926 33.0676C161.926 34.466 163.059 35.5996 164.458 35.5996Z"
      fillOpacity={0.3}
    />
    <path
      d="M164.458 65.9834C165.856 65.9834 166.99 64.8498 166.99 63.4514C166.99 62.053 165.856 60.9194 164.458 60.9194C163.059 60.9194 161.926 62.053 161.926 63.4514C161.926 64.8498 163.059 65.9834 164.458 65.9834Z"
      fillOpacity={0.3}
    />
    <path
      d="M184.714 25.4716C186.112 25.4716 187.246 24.338 187.246 22.9397C187.246 21.5413 186.112 20.4077 184.714 20.4077C183.315 20.4077 182.182 21.5413 182.182 22.9397C182.182 24.338 183.315 25.4716 184.714 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M194.841 25.4716C196.239 25.4716 197.373 24.338 197.373 22.9397C197.373 21.5413 196.239 20.4077 194.841 20.4077C193.442 20.4077 192.309 21.5413 192.309 22.9397C192.309 24.338 193.442 25.4716 194.841 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M194.841 55.8545C196.239 55.8545 197.373 54.7209 197.373 53.3225C197.373 51.9241 196.239 50.7905 194.841 50.7905C193.442 50.7905 192.309 51.9241 192.309 53.3225C192.309 54.7209 193.442 55.8545 194.841 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M204.969 25.4716C206.368 25.4716 207.501 24.338 207.501 22.9397C207.501 21.5413 206.368 20.4077 204.969 20.4077C203.571 20.4077 202.438 21.5413 202.438 22.9397C202.438 24.338 203.571 25.4716 204.969 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M204.969 55.8545C206.368 55.8545 207.501 54.7209 207.501 53.3225C207.501 51.9241 206.368 50.7905 204.969 50.7905C203.571 50.7905 202.438 51.9241 202.438 53.3225C202.438 54.7209 203.571 55.8545 204.969 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M215.096 25.4716C216.495 25.4716 217.628 24.338 217.628 22.9397C217.628 21.5413 216.495 20.4077 215.096 20.4077C213.698 20.4077 212.564 21.5413 212.564 22.9397C212.564 24.338 213.698 25.4716 215.096 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M215.096 55.8545C216.495 55.8545 217.628 54.7209 217.628 53.3225C217.628 51.9241 216.495 50.7905 215.096 50.7905C213.698 50.7905 212.564 51.9241 212.564 53.3225C212.564 54.7209 213.698 55.8545 215.096 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.225 25.4716C226.624 25.4716 227.757 24.338 227.757 22.9397C227.757 21.5413 226.624 20.4077 225.225 20.4077C223.827 20.4077 222.693 21.5413 222.693 22.9397C222.693 24.338 223.827 25.4716 225.225 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M235.352 25.4716C236.751 25.4716 237.884 24.338 237.884 22.9397C237.884 21.5413 236.751 20.4077 235.352 20.4077C233.954 20.4077 232.82 21.5413 232.82 22.9397C232.82 24.338 233.954 25.4716 235.352 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M245.48 65.9834C246.879 65.9834 248.012 64.8498 248.012 63.4514C248.012 62.053 246.879 60.9194 245.48 60.9194C244.082 60.9194 242.948 62.053 242.948 63.4514C242.948 64.8498 244.082 65.9834 245.48 65.9834Z"
      fillOpacity={0.3}
    />
    <path
      d="M255.608 76.1103C257.007 76.1103 258.14 74.9767 258.14 73.5784C258.14 72.18 257.007 71.0464 255.608 71.0464C254.21 71.0464 253.076 72.18 253.076 73.5784C253.076 74.9767 254.21 76.1103 255.608 76.1103Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 35.5996C267.134 35.5996 268.268 34.466 268.268 33.0676C268.268 31.6692 267.134 30.5356 265.736 30.5356C264.338 30.5356 263.204 31.6692 263.204 33.0676C263.204 34.466 264.338 35.5996 265.736 35.5996Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 96.3662C267.134 96.3662 268.268 95.2326 268.268 93.8342C268.268 92.4358 267.134 91.3022 265.736 91.3022C264.338 91.3022 263.204 92.4358 263.204 93.8342C263.204 95.2326 264.338 96.3662 265.736 96.3662Z"
      fillOpacity={0.3}
    />
    <path
      d="M285.992 45.7275C287.39 45.7275 288.524 44.5939 288.524 43.1955C288.524 41.7972 287.39 40.6636 285.992 40.6636C284.594 40.6636 283.46 41.7972 283.46 43.1955C283.46 44.5939 284.594 45.7275 285.992 45.7275Z"
      fillOpacity={0.3}
    />
    <path
      d="M296.12 55.8545C297.518 55.8545 298.652 54.7209 298.652 53.3225C298.652 51.9241 297.518 50.7905 296.12 50.7905C294.721 50.7905 293.588 51.9241 293.588 53.3225C293.588 54.7209 294.721 55.8545 296.12 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M306.247 76.1103C307.645 76.1103 308.779 74.9767 308.779 73.5784C308.779 72.18 307.645 71.0464 306.247 71.0464C304.848 71.0464 303.715 72.18 303.715 73.5784C303.715 74.9767 304.848 76.1103 306.247 76.1103Z"
      fillOpacity={0.3}
    />
    <path
      d="M103.69 318.522C105.089 318.522 106.222 317.388 106.222 315.99C106.222 314.592 105.089 313.458 103.69 313.458C102.292 313.458 101.158 314.592 101.158 315.99C101.158 317.388 102.292 318.522 103.69 318.522Z"
      fillOpacity={0.3}
    />
    <path
      d="M113.818 338.777C115.216 338.777 116.35 337.644 116.35 336.245C116.35 334.847 115.216 333.713 113.818 333.713C112.42 333.713 111.286 334.847 111.286 336.245C111.286 337.644 112.42 338.777 113.818 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M134.074 359.033C135.472 359.033 136.606 357.9 136.606 356.501C136.606 355.103 135.472 353.969 134.074 353.969C132.676 353.969 131.542 355.103 131.542 356.501C131.542 357.9 132.676 359.033 134.074 359.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M144.202 318.521C145.6 318.521 146.734 317.388 146.734 315.989C146.734 314.591 145.6 313.458 144.202 313.458C142.804 313.458 141.67 314.591 141.67 315.989C141.67 317.388 142.804 318.521 144.202 318.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M154.329 328.649C155.727 328.649 156.861 327.516 156.861 326.117C156.861 324.719 155.727 323.585 154.329 323.585C152.93 323.585 151.797 324.719 151.797 326.117C151.797 327.516 152.93 328.649 154.329 328.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M164.458 338.777C165.856 338.777 166.99 337.644 166.99 336.245C166.99 334.847 165.856 333.713 164.458 333.713C163.059 333.713 161.926 334.847 161.926 336.245C161.926 337.644 163.059 338.777 164.458 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M174.585 348.905C175.983 348.905 177.117 347.772 177.117 346.373C177.117 344.975 175.983 343.841 174.585 343.841C173.186 343.841 172.053 344.975 172.053 346.373C172.053 347.772 173.186 348.905 174.585 348.905Z"
      fillOpacity={0.3}
    />
    <path
      d="M184.714 379.289C186.112 379.289 187.246 378.155 187.246 376.757C187.246 375.359 186.112 374.225 184.714 374.225C183.315 374.225 182.182 375.359 182.182 376.757C182.182 378.155 183.315 379.289 184.714 379.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M194.841 359.033C196.239 359.033 197.373 357.9 197.373 356.501C197.373 355.103 196.239 353.969 194.841 353.969C193.442 353.969 192.309 355.103 192.309 356.501C192.309 357.9 193.442 359.033 194.841 359.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M235.352 359.033C236.751 359.033 237.884 357.9 237.884 356.501C237.884 355.103 236.751 353.969 235.352 353.969C233.954 353.969 232.82 355.103 232.82 356.501C232.82 357.9 233.954 359.033 235.352 359.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M235.352 379.289C236.751 379.289 237.884 378.155 237.884 376.757C237.884 375.359 236.751 374.225 235.352 374.225C233.954 374.225 232.82 375.359 232.82 376.757C232.82 378.155 233.954 379.289 235.352 379.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M255.608 348.905C257.007 348.905 258.14 347.772 258.14 346.373C258.14 344.975 257.007 343.841 255.608 343.841C254.21 343.841 253.076 344.975 253.076 346.373C253.076 347.772 254.21 348.905 255.608 348.905Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 338.777C267.134 338.777 268.268 337.644 268.268 336.245C268.268 334.847 267.134 333.713 265.736 333.713C264.338 333.713 263.204 334.847 263.204 336.245C263.204 337.644 264.338 338.777 265.736 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M275.864 318.522C277.262 318.522 278.396 317.388 278.396 315.99C278.396 314.592 277.262 313.458 275.864 313.458C274.466 313.458 273.332 314.592 273.332 315.99C273.332 317.388 274.466 318.522 275.864 318.522Z"
      fillOpacity={0.3}
    />
    <path
      d="M275.864 328.649C277.262 328.649 278.396 327.516 278.396 326.117C278.396 324.719 277.262 323.585 275.864 323.585C274.466 323.585 273.332 324.719 273.332 326.117C273.332 327.516 274.466 328.649 275.864 328.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M123.946 288.239C125.344 288.239 126.478 287.106 126.478 285.707C126.478 284.309 125.344 283.175 123.946 283.175C122.548 283.175 121.414 284.309 121.414 285.707C121.414 287.106 122.548 288.239 123.946 288.239Z"
      fillOpacity={0.3}
    />
    <path
      d="M184.714 207.216C186.112 207.216 187.246 206.082 187.246 204.684C187.246 203.285 186.112 202.152 184.714 202.152C183.315 202.152 182.182 203.285 182.182 204.684C182.182 206.082 183.315 207.216 184.714 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M194.841 207.216C196.239 207.216 197.373 206.082 197.373 204.684C197.373 203.285 196.239 202.152 194.841 202.152C193.442 202.152 192.309 203.285 192.309 204.684C192.309 206.082 193.442 207.216 194.841 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M204.969 207.216C206.368 207.216 207.501 206.082 207.501 204.684C207.501 203.285 206.368 202.152 204.969 202.152C203.571 202.152 202.438 203.285 202.438 204.684C202.438 206.082 203.571 207.216 204.969 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M215.096 207.216C216.495 207.216 217.628 206.082 217.628 204.684C217.628 203.285 216.495 202.152 215.096 202.152C213.698 202.152 212.564 203.285 212.564 204.684C212.564 206.082 213.698 207.216 215.096 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M245.48 217.344C246.879 217.344 248.012 216.21 248.012 214.812C248.012 213.413 246.879 212.28 245.48 212.28C244.082 212.28 242.948 213.413 242.948 214.812C242.948 216.21 244.082 217.344 245.48 217.344Z"
      fillOpacity={0.3}
    />
    <path
      d="M255.608 227.472C257.007 227.472 258.14 226.338 258.14 224.94C258.14 223.541 257.007 222.408 255.608 222.408C254.21 222.408 253.076 223.541 253.076 224.94C253.076 226.338 254.21 227.472 255.608 227.472Z"
      fillOpacity={0.3}
    />
    <path
      d="M265.736 237.599C267.134 237.599 268.268 236.465 268.268 235.067C268.268 233.669 267.134 232.535 265.736 232.535C264.338 232.535 263.204 233.669 263.204 235.067C263.204 236.465 264.338 237.599 265.736 237.599Z"
      fillOpacity={0.3}
    />
    <path
      d="M275.864 247.728C277.262 247.728 278.396 246.594 278.396 245.196C278.396 243.797 277.262 242.664 275.864 242.664C274.466 242.664 273.332 243.797 273.332 245.196C273.332 246.594 274.466 247.728 275.864 247.728Z"
      fillOpacity={0.3}
    />
    <path
      d="M285.992 207.216C287.39 207.216 288.524 206.082 288.524 204.684C288.524 203.285 287.39 202.152 285.992 202.152C284.594 202.152 283.46 203.285 283.46 204.684C283.46 206.082 284.594 207.216 285.992 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M285.992 278.11C287.39 278.11 288.524 276.977 288.524 275.578C288.524 274.18 287.39 273.046 285.992 273.046C284.594 273.046 283.46 274.18 283.46 275.578C283.46 276.977 284.594 278.11 285.992 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M285.992 288.239C287.39 288.239 288.524 287.106 288.524 285.707C288.524 284.309 287.39 283.175 285.992 283.175C284.594 283.175 283.46 284.309 283.46 285.707C283.46 287.106 284.594 288.239 285.992 288.239Z"
      fillOpacity={0.3}
    />
    <path
      d="M296.12 207.216C297.518 207.216 298.652 206.082 298.652 204.684C298.652 203.285 297.518 202.152 296.12 202.152C294.721 202.152 293.588 203.285 293.588 204.684C293.588 206.082 294.721 207.216 296.12 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M306.247 217.344C307.645 217.344 308.779 216.21 308.779 214.812C308.779 213.413 307.645 212.28 306.247 212.28C304.848 212.28 303.715 213.413 303.715 214.812C303.715 216.21 304.848 217.344 306.247 217.344Z"
      fillOpacity={0.3}
    />
    <path
      d="M316.376 237.6C317.774 237.6 318.908 236.466 318.908 235.068C318.908 233.669 317.774 232.536 316.376 232.536C314.977 232.536 313.844 233.669 313.844 235.068C313.844 236.466 314.977 237.6 316.376 237.6Z"
      fillOpacity={0.3}
    />
    <path
      d="M326.503 278.11C327.901 278.11 329.035 276.977 329.035 275.578C329.035 274.18 327.901 273.046 326.503 273.046C325.104 273.046 323.971 274.18 323.971 275.578C323.971 276.977 325.104 278.11 326.503 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M326.503 288.239C327.901 288.239 329.035 287.106 329.035 285.707C329.035 284.309 327.901 283.175 326.503 283.175C325.104 283.175 323.971 284.309 323.971 285.707C323.971 287.106 325.104 288.239 326.503 288.239Z"
      fillOpacity={0.3}
    />
    <path
      d="M326.503 298.366C327.901 298.366 329.035 297.233 329.035 295.834C329.035 294.436 327.901 293.302 326.503 293.302C325.104 293.302 323.971 294.436 323.971 295.834C323.971 297.233 325.104 298.366 326.503 298.366Z"
      fillOpacity={0.3}
    />
    <path
      d="M296.12 76.1113C297.518 76.1113 298.652 74.9777 298.652 73.5793C298.652 72.181 297.518 71.0474 296.12 71.0474C294.721 71.0474 293.588 72.181 293.588 73.5793C293.588 74.9777 294.721 76.1113 296.12 76.1113Z"
      fill="white"
    />
    <path
      d="M103.69 308.394C105.089 308.394 106.222 307.26 106.222 305.862C106.222 304.463 105.089 303.33 103.69 303.33C102.292 303.33 101.158 304.463 101.158 305.862C101.158 307.26 102.292 308.394 103.69 308.394Z"
      fill="white"
    />
    <path
      d="M113.818 106.394C115.216 106.394 116.35 105.26 116.35 103.862C116.35 102.463 115.216 101.33 113.818 101.33C112.42 101.33 111.286 102.463 111.286 103.862C111.286 105.26 112.42 106.394 113.818 106.394Z"
      fill="white"
    />
    <path
      d="M123.946 106.394C125.344 106.394 126.478 105.26 126.478 103.862C126.478 102.463 125.344 101.33 123.946 101.33C122.548 101.33 121.414 102.463 121.414 103.862C121.414 105.26 122.548 106.394 123.946 106.394Z"
      fill="white"
    />
    <path
      d="M184.714 197.545C186.112 197.545 187.246 196.411 187.246 195.013C187.246 193.615 186.112 192.481 184.714 192.481C183.315 192.481 182.182 193.615 182.182 195.013C182.182 196.411 183.315 197.545 184.714 197.545Z"
      fill="white"
    />
    <path
      d="M194.841 197.545C196.239 197.545 197.373 196.411 197.373 195.013C197.373 193.615 196.239 192.481 194.841 192.481C193.442 192.481 192.309 193.615 192.309 195.013C192.309 196.411 193.442 197.545 194.841 197.545Z"
      fill="white"
    />
    <path
      d="M204.969 187.417C206.368 187.417 207.501 186.283 207.501 184.885C207.501 183.487 206.368 182.353 204.969 182.353C203.571 182.353 202.438 183.487 202.438 184.885C202.438 186.283 203.571 187.417 204.969 187.417Z"
      fill="white"
    />
    <path
      d="M204.969 197.545C206.368 197.545 207.501 196.411 207.501 195.013C207.501 193.615 206.368 192.481 204.969 192.481C203.571 192.481 202.438 193.615 202.438 195.013C202.438 196.411 203.571 197.545 204.969 197.545Z"
      fill="white"
    />
    <path
      d="M215.096 187.417C216.495 187.417 217.628 186.283 217.628 184.885C217.628 183.487 216.495 182.353 215.096 182.353C213.698 182.353 212.564 183.487 212.564 184.885C212.564 186.283 213.698 187.417 215.096 187.417Z"
      fill="white"
    />
    <path
      d="M215.096 197.545C216.495 197.545 217.628 196.411 217.628 195.013C217.628 193.615 216.495 192.481 215.096 192.481C213.698 192.481 212.564 193.615 212.564 195.013C212.564 196.411 213.698 197.545 215.096 197.545Z"
      fill="white"
    />
    <path
      d="M225.225 187.417C226.624 187.417 227.757 186.283 227.757 184.885C227.757 183.487 226.624 182.353 225.225 182.353C223.827 182.353 222.693 183.487 222.693 184.885C222.693 186.283 223.827 187.417 225.225 187.417Z"
      fill="white"
    />
    <path
      d="M225.225 197.545C226.624 197.545 227.757 196.411 227.757 195.013C227.757 193.615 226.624 192.481 225.225 192.481C223.827 192.481 222.693 193.615 222.693 195.013C222.693 196.411 223.827 197.545 225.225 197.545Z"
      fill="white"
    />
    <path
      d="M235.352 177.289C236.751 177.289 237.884 176.155 237.884 174.757C237.884 173.359 236.751 172.225 235.352 172.225C233.954 172.225 232.82 173.359 232.82 174.757C232.82 176.155 233.954 177.289 235.352 177.289Z"
      fill="white"
    />
    <path
      d="M235.352 187.417C236.751 187.417 237.884 186.283 237.884 184.885C237.884 183.487 236.751 182.353 235.352 182.353C233.954 182.353 232.82 183.487 232.82 184.885C232.82 186.283 233.954 187.417 235.352 187.417Z"
      fill="white"
    />
    <path
      d="M235.352 197.545C236.751 197.545 237.884 196.411 237.884 195.013C237.884 193.615 236.751 192.481 235.352 192.481C233.954 192.481 232.82 193.615 232.82 195.013C232.82 196.411 233.954 197.545 235.352 197.545Z"
      fill="white"
    />
    <path
      d="M245.48 167.162C246.879 167.162 248.012 166.028 248.012 164.63C248.012 163.231 246.879 162.098 245.48 162.098C244.082 162.098 242.948 163.231 242.948 164.63C242.948 166.028 244.082 167.162 245.48 167.162Z"
      fill="white"
    />
    <path
      d="M245.48 177.289C246.879 177.289 248.012 176.155 248.012 174.757C248.012 173.359 246.879 172.225 245.48 172.225C244.082 172.225 242.948 173.359 242.948 174.757C242.948 176.155 244.082 177.289 245.48 177.289Z"
      fill="white"
    />
    <path
      d="M245.48 187.417C246.879 187.417 248.012 186.283 248.012 184.885C248.012 183.487 246.879 182.353 245.48 182.353C244.082 182.353 242.948 183.487 242.948 184.885C242.948 186.283 244.082 187.417 245.48 187.417Z"
      fill="white"
    />
    <path
      d="M245.48 197.544C246.879 197.544 248.012 196.411 248.012 195.012C248.012 193.614 246.879 192.48 245.48 192.48C244.082 192.48 242.948 193.614 242.948 195.012C242.948 196.411 244.082 197.544 245.48 197.544Z"
      fill="white"
    />
    <path
      d="M255.608 157.034C257.007 157.034 258.14 155.9 258.14 154.502C258.14 153.103 257.007 151.97 255.608 151.97C254.21 151.97 253.076 153.103 253.076 154.502C253.076 155.9 254.21 157.034 255.608 157.034Z"
      fill="white"
    />
    <path
      d="M255.608 167.162C257.007 167.162 258.14 166.028 258.14 164.63C258.14 163.231 257.007 162.098 255.608 162.098C254.21 162.098 253.076 163.231 253.076 164.63C253.076 166.028 254.21 167.162 255.608 167.162Z"
      fill="white"
    />
    <path
      d="M255.608 177.289C257.007 177.289 258.14 176.155 258.14 174.757C258.14 173.359 257.007 172.225 255.608 172.225C254.21 172.225 253.076 173.359 253.076 174.757C253.076 176.155 254.21 177.289 255.608 177.289Z"
      fill="white"
    />
    <path
      d="M255.608 197.544C257.007 197.544 258.14 196.411 258.14 195.012C258.14 193.614 257.007 192.48 255.608 192.48C254.21 192.48 253.076 193.614 253.076 195.012C253.076 196.411 254.21 197.544 255.608 197.544Z"
      fill="white"
    />
    <path
      d="M265.736 146.906C267.134 146.906 268.268 145.772 268.268 144.374C268.268 142.975 267.134 141.842 265.736 141.842C264.338 141.842 263.204 142.975 263.204 144.374C263.204 145.772 264.338 146.906 265.736 146.906Z"
      fill="white"
    />
    <path
      d="M265.736 157.034C267.134 157.034 268.268 155.9 268.268 154.502C268.268 153.103 267.134 151.97 265.736 151.97C264.338 151.97 263.204 153.103 263.204 154.502C263.204 155.9 264.338 157.034 265.736 157.034Z"
      fill="white"
    />
    <path
      d="M265.736 167.162C267.134 167.162 268.268 166.028 268.268 164.63C268.268 163.231 267.134 162.098 265.736 162.098C264.338 162.098 263.204 163.231 263.204 164.63C263.204 166.028 264.338 167.162 265.736 167.162Z"
      fill="white"
    />
    <path
      d="M265.736 177.289C267.134 177.289 268.268 176.155 268.268 174.757C268.268 173.359 267.134 172.225 265.736 172.225C264.338 172.225 263.204 173.359 263.204 174.757C263.204 176.155 264.338 177.289 265.736 177.289Z"
      fill="white"
    />
    <path
      d="M275.864 106.394C277.262 106.394 278.396 105.26 278.396 103.862C278.396 102.463 277.262 101.33 275.864 101.33C274.466 101.33 273.332 102.463 273.332 103.862C273.332 105.26 274.466 106.394 275.864 106.394Z"
      fill="white"
    />
    <path
      d="M275.864 116.522C277.262 116.522 278.396 115.388 278.396 113.99C278.396 112.592 277.262 111.458 275.864 111.458C274.466 111.458 273.332 112.592 273.332 113.99C273.332 115.388 274.466 116.522 275.864 116.522Z"
      fill="white"
    />
    <path
      d="M275.864 126.65C277.262 126.65 278.396 125.516 278.396 124.118C278.396 122.72 277.262 121.586 275.864 121.586C274.466 121.586 273.332 122.72 273.332 124.118C273.332 125.516 274.466 126.65 275.864 126.65Z"
      fill="white"
    />
    <path
      d="M275.864 136.778C277.262 136.778 278.396 135.644 278.396 134.246C278.396 132.847 277.262 131.714 275.864 131.714C274.466 131.714 273.332 132.847 273.332 134.246C273.332 135.644 274.466 136.778 275.864 136.778Z"
      fill="white"
    />
    <path
      d="M275.864 146.906C277.262 146.906 278.396 145.772 278.396 144.374C278.396 142.975 277.262 141.842 275.864 141.842C274.466 141.842 273.332 142.975 273.332 144.374C273.332 145.772 274.466 146.906 275.864 146.906Z"
      fill="white"
    />
    <path
      d="M275.864 157.034C277.262 157.034 278.396 155.9 278.396 154.502C278.396 153.103 277.262 151.97 275.864 151.97C274.466 151.97 273.332 153.103 273.332 154.502C273.332 155.9 274.466 157.034 275.864 157.034Z"
      fill="white"
    />
    <path
      d="M275.864 167.162C277.262 167.162 278.396 166.028 278.396 164.63C278.396 163.231 277.262 162.098 275.864 162.098C274.466 162.098 273.332 163.231 273.332 164.63C273.332 166.028 274.466 167.162 275.864 167.162Z"
      fill="white"
    />
    <path
      d="M285.992 106.394C287.39 106.394 288.524 105.26 288.524 103.862C288.524 102.463 287.39 101.33 285.992 101.33C284.594 101.33 283.46 102.463 283.46 103.862C283.46 105.26 284.594 106.394 285.992 106.394Z"
      fill="white"
    />
    <path
      d="M285.992 116.522C287.39 116.522 288.524 115.388 288.524 113.99C288.524 112.592 287.39 111.458 285.992 111.458C284.594 111.458 283.46 112.592 283.46 113.99C283.46 115.388 284.594 116.522 285.992 116.522Z"
      fill="white"
    />
    <path
      d="M285.992 126.65C287.39 126.65 288.524 125.516 288.524 124.118C288.524 122.72 287.39 121.586 285.992 121.586C284.594 121.586 283.46 122.72 283.46 124.118C283.46 125.516 284.594 126.65 285.992 126.65Z"
      fill="white"
    />
    <path
      d="M285.992 136.778C287.39 136.778 288.524 135.644 288.524 134.246C288.524 132.847 287.39 131.714 285.992 131.714C284.594 131.714 283.46 132.847 283.46 134.246C283.46 135.644 284.594 136.778 285.992 136.778Z"
      fill="white"
    />
    <path
      d="M285.992 146.906C287.39 146.906 288.524 145.772 288.524 144.374C288.524 142.975 287.39 141.842 285.992 141.842C284.594 141.842 283.46 142.975 283.46 144.374C283.46 145.772 284.594 146.906 285.992 146.906Z"
      fill="white"
    />
    <path
      d="M285.992 157.034C287.39 157.034 288.524 155.9 288.524 154.502C288.524 153.103 287.39 151.97 285.992 151.97C284.594 151.97 283.46 153.103 283.46 154.502C283.46 155.9 284.594 157.034 285.992 157.034Z"
      fill="white"
    />
    <path
      d="M296.12 106.394C297.518 106.394 298.652 105.26 298.652 103.862C298.652 102.463 297.518 101.33 296.12 101.33C294.721 101.33 293.588 102.463 293.588 103.862C293.588 105.26 294.721 106.394 296.12 106.394Z"
      fill="white"
    />
    <path
      d="M296.12 116.522C297.518 116.522 298.652 115.388 298.652 113.99C298.652 112.592 297.518 111.458 296.12 111.458C294.721 111.458 293.588 112.592 293.588 113.99C293.588 115.388 294.721 116.522 296.12 116.522Z"
      fill="white"
    />
    <path
      d="M296.12 126.65C297.518 126.65 298.652 125.516 298.652 124.118C298.652 122.72 297.518 121.586 296.12 121.586C294.721 121.586 293.588 122.72 293.588 124.118C293.588 125.516 294.721 126.65 296.12 126.65Z"
      fill="white"
    />
    <path
      d="M296.12 136.778C297.518 136.778 298.652 135.644 298.652 134.246C298.652 132.847 297.518 131.714 296.12 131.714C294.721 131.714 293.588 132.847 293.588 134.246C293.588 135.644 294.721 136.778 296.12 136.778Z"
      fill="white"
    />
    <path
      d="M296.12 146.906C297.518 146.906 298.652 145.772 298.652 144.374C298.652 142.975 297.518 141.842 296.12 141.842C294.721 141.842 293.588 142.975 293.588 144.374C293.588 145.772 294.721 146.906 296.12 146.906Z"
      fill="white"
    />
    <path
      d="M306.247 106.394C307.645 106.394 308.779 105.26 308.779 103.862C308.779 102.463 307.645 101.33 306.247 101.33C304.848 101.33 303.715 102.463 303.715 103.862C303.715 105.26 304.848 106.394 306.247 106.394Z"
      fill="white"
    />
    <path
      d="M306.247 116.521C307.645 116.521 308.779 115.388 308.779 113.989C308.779 112.591 307.645 111.458 306.247 111.458C304.848 111.458 303.715 112.591 303.715 113.989C303.715 115.388 304.848 116.521 306.247 116.521Z"
      fill="white"
    />
    <path
      d="M306.247 126.649C307.645 126.649 308.779 125.516 308.779 124.117C308.779 122.719 307.645 121.585 306.247 121.585C304.848 121.585 303.715 122.719 303.715 124.117C303.715 125.516 304.848 126.649 306.247 126.649Z"
      fill="white"
    />
    <path
      d="M123.946 86.2392C125.344 86.2392 126.478 85.1056 126.478 83.7073C126.478 82.3089 125.344 81.1753 123.946 81.1753C122.548 81.1753 121.414 82.3089 121.414 83.7073C121.414 85.1056 122.548 86.2392 123.946 86.2392Z"
      fill="white"
    />
    <path
      d="M123.946 96.3672C125.344 96.3672 126.478 95.2336 126.478 93.8352C126.478 92.4368 125.344 91.3032 123.946 91.3032C122.548 91.3032 121.414 92.4368 121.414 93.8352C121.414 95.2336 122.548 96.3672 123.946 96.3672Z"
      fill="white"
    />
    <path
      d="M134.074 65.9834C135.472 65.9834 136.606 64.8498 136.606 63.4514C136.606 62.053 135.472 60.9194 134.074 60.9194C132.676 60.9194 131.542 62.053 131.542 63.4514C131.542 64.8498 132.676 65.9834 134.074 65.9834Z"
      fill="white"
    />
    <path
      d="M134.074 76.1113C135.472 76.1113 136.606 74.9777 136.606 73.5793C136.606 72.181 135.472 71.0474 134.074 71.0474C132.676 71.0474 131.542 72.181 131.542 73.5793C131.542 74.9777 132.676 76.1113 134.074 76.1113Z"
      fill="white"
    />
    <path
      d="M134.074 86.2392C135.472 86.2392 136.606 85.1056 136.606 83.7073C136.606 82.3089 135.472 81.1753 134.074 81.1753C132.676 81.1753 131.542 82.3089 131.542 83.7073C131.542 85.1056 132.676 86.2392 134.074 86.2392Z"
      fill="white"
    />
    <path
      d="M144.202 55.8554C145.6 55.8554 146.734 54.7218 146.734 53.3235C146.734 51.9251 145.6 50.7915 144.202 50.7915C142.804 50.7915 141.67 51.9251 141.67 53.3235C141.67 54.7218 142.804 55.8554 144.202 55.8554Z"
      fill="white"
    />
    <path
      d="M144.202 65.9834C145.6 65.9834 146.734 64.8498 146.734 63.4514C146.734 62.053 145.6 60.9194 144.202 60.9194C142.804 60.9194 141.67 62.053 141.67 63.4514C141.67 64.8498 142.804 65.9834 144.202 65.9834Z"
      fill="white"
    />
    <path
      d="M144.202 76.1113C145.6 76.1113 146.734 74.9777 146.734 73.5793C146.734 72.181 145.6 71.0474 144.202 71.0474C142.804 71.0474 141.67 72.181 141.67 73.5793C141.67 74.9777 142.804 76.1113 144.202 76.1113Z"
      fill="white"
    />
    <path
      d="M154.329 55.8554C155.727 55.8554 156.861 54.7218 156.861 53.3235C156.861 51.9251 155.727 50.7915 154.329 50.7915C152.93 50.7915 151.797 51.9251 151.797 53.3235C151.797 54.7218 152.93 55.8554 154.329 55.8554Z"
      fill="white"
    />
    <path
      d="M154.329 65.9834C155.727 65.9834 156.861 64.8498 156.861 63.4514C156.861 62.053 155.727 60.9194 154.329 60.9194C152.93 60.9194 151.797 62.053 151.797 63.4514C151.797 64.8498 152.93 65.9834 154.329 65.9834Z"
      fill="white"
    />
    <path
      d="M164.458 45.7275C165.856 45.7275 166.99 44.5939 166.99 43.1955C166.99 41.7972 165.856 40.6636 164.458 40.6636C163.059 40.6636 161.926 41.7972 161.926 43.1955C161.926 44.5939 163.059 45.7275 164.458 45.7275Z"
      fill="white"
    />
    <path
      d="M164.458 55.8554C165.856 55.8554 166.99 54.7218 166.99 53.3235C166.99 51.9251 165.856 50.7915 164.458 50.7915C163.059 50.7915 161.926 51.9251 161.926 53.3235C161.926 54.7218 163.059 55.8554 164.458 55.8554Z"
      fill="white"
    />
    <path
      d="M174.585 35.5996C175.983 35.5996 177.117 34.466 177.117 33.0676C177.117 31.6692 175.983 30.5356 174.585 30.5356C173.186 30.5356 172.053 31.6692 172.053 33.0676C172.053 34.466 173.186 35.5996 174.585 35.5996Z"
      fill="white"
    />
    <path
      d="M174.585 45.7275C175.983 45.7275 177.117 44.5939 177.117 43.1955C177.117 41.7972 175.983 40.6636 174.585 40.6636C173.186 40.6636 172.053 41.7972 172.053 43.1955C172.053 44.5939 173.186 45.7275 174.585 45.7275Z"
      fill="white"
    />
    <path
      d="M174.585 55.8554C175.983 55.8554 177.117 54.7218 177.117 53.3235C177.117 51.9251 175.983 50.7915 174.585 50.7915C173.186 50.7915 172.053 51.9251 172.053 53.3235C172.053 54.7218 173.186 55.8554 174.585 55.8554Z"
      fill="white"
    />
    <path
      d="M184.714 35.5996C186.112 35.5996 187.246 34.466 187.246 33.0676C187.246 31.6692 186.112 30.5356 184.714 30.5356C183.315 30.5356 182.182 31.6692 182.182 33.0676C182.182 34.466 183.315 35.5996 184.714 35.5996Z"
      fill="white"
    />
    <path
      d="M184.714 45.7275C186.112 45.7275 187.246 44.5939 187.246 43.1955C187.246 41.7972 186.112 40.6636 184.714 40.6636C183.315 40.6636 182.182 41.7972 182.182 43.1955C182.182 44.5939 183.315 45.7275 184.714 45.7275Z"
      fill="white"
    />
    <path
      d="M184.714 55.8554C186.112 55.8554 187.246 54.7218 187.246 53.3235C187.246 51.9251 186.112 50.7915 184.714 50.7915C183.315 50.7915 182.182 51.9251 182.182 53.3235C182.182 54.7218 183.315 55.8554 184.714 55.8554Z"
      fill="white"
    />
    <path
      d="M194.841 35.5996C196.239 35.5996 197.373 34.466 197.373 33.0676C197.373 31.6692 196.239 30.5356 194.841 30.5356C193.442 30.5356 192.309 31.6692 192.309 33.0676C192.309 34.466 193.442 35.5996 194.841 35.5996Z"
      fill="white"
    />
    <path
      d="M194.841 45.7275C196.239 45.7275 197.373 44.5939 197.373 43.1955C197.373 41.7972 196.239 40.6636 194.841 40.6636C193.442 40.6636 192.309 41.7972 192.309 43.1955C192.309 44.5939 193.442 45.7275 194.841 45.7275Z"
      fill="white"
    />
    <path
      d="M204.969 35.5996C206.368 35.5996 207.501 34.466 207.501 33.0676C207.501 31.6692 206.368 30.5356 204.969 30.5356C203.571 30.5356 202.438 31.6692 202.438 33.0676C202.438 34.466 203.571 35.5996 204.969 35.5996Z"
      fill="white"
    />
    <path
      d="M204.969 45.7275C206.368 45.7275 207.501 44.5939 207.501 43.1955C207.501 41.7972 206.368 40.6636 204.969 40.6636C203.571 40.6636 202.438 41.7972 202.438 43.1955C202.438 44.5939 203.571 45.7275 204.969 45.7275Z"
      fill="white"
    />
    <path
      d="M215.096 35.5996C216.495 35.5996 217.628 34.466 217.628 33.0676C217.628 31.6692 216.495 30.5356 215.096 30.5356C213.698 30.5356 212.564 31.6692 212.564 33.0676C212.564 34.466 213.698 35.5996 215.096 35.5996Z"
      fill="white"
    />
    <path
      d="M215.096 45.7275C216.495 45.7275 217.628 44.5939 217.628 43.1955C217.628 41.7972 216.495 40.6636 215.096 40.6636C213.698 40.6636 212.564 41.7972 212.564 43.1955C212.564 44.5939 213.698 45.7275 215.096 45.7275Z"
      fill="white"
    />
    <path
      d="M225.225 35.5996C226.624 35.5996 227.757 34.466 227.757 33.0676C227.757 31.6692 226.624 30.5356 225.225 30.5356C223.827 30.5356 222.693 31.6692 222.693 33.0676C222.693 34.466 223.827 35.5996 225.225 35.5996Z"
      fill="white"
    />
    <path
      d="M225.225 45.7275C226.624 45.7275 227.757 44.5939 227.757 43.1955C227.757 41.7972 226.624 40.6636 225.225 40.6636C223.827 40.6636 222.693 41.7972 222.693 43.1955C222.693 44.5939 223.827 45.7275 225.225 45.7275Z"
      fill="white"
    />
    <path
      d="M225.225 55.8554C226.624 55.8554 227.757 54.7218 227.757 53.3235C227.757 51.9251 226.624 50.7915 225.225 50.7915C223.827 50.7915 222.693 51.9251 222.693 53.3235C222.693 54.7218 223.827 55.8554 225.225 55.8554Z"
      fill="white"
    />
    <path
      d="M235.352 35.5996C236.751 35.5996 237.884 34.466 237.884 33.0676C237.884 31.6692 236.751 30.5356 235.352 30.5356C233.954 30.5356 232.82 31.6692 232.82 33.0676C232.82 34.466 233.954 35.5996 235.352 35.5996Z"
      fill="white"
    />
    <path
      d="M235.352 45.7275C236.751 45.7275 237.884 44.5939 237.884 43.1955C237.884 41.7972 236.751 40.6636 235.352 40.6636C233.954 40.6636 232.82 41.7972 232.82 43.1955C232.82 44.5939 233.954 45.7275 235.352 45.7275Z"
      fill="white"
    />
    <path
      d="M235.352 55.8554C236.751 55.8554 237.884 54.7218 237.884 53.3235C237.884 51.9251 236.751 50.7915 235.352 50.7915C233.954 50.7915 232.82 51.9251 232.82 53.3235C232.82 54.7218 233.954 55.8554 235.352 55.8554Z"
      fill="white"
    />
    <path
      d="M245.48 35.5996C246.879 35.5996 248.012 34.466 248.012 33.0676C248.012 31.6692 246.879 30.5356 245.48 30.5356C244.082 30.5356 242.948 31.6692 242.948 33.0676C242.948 34.466 244.082 35.5996 245.48 35.5996Z"
      fill="white"
    />
    <path
      d="M245.48 45.7275C246.879 45.7275 248.012 44.5939 248.012 43.1955C248.012 41.7972 246.879 40.6636 245.48 40.6636C244.082 40.6636 242.948 41.7972 242.948 43.1955C242.948 44.5939 244.082 45.7275 245.48 45.7275Z"
      fill="white"
    />
    <path
      d="M245.48 55.8554C246.879 55.8554 248.012 54.7218 248.012 53.3235C248.012 51.9251 246.879 50.7915 245.48 50.7915C244.082 50.7915 242.948 51.9251 242.948 53.3235C242.948 54.7218 244.082 55.8554 245.48 55.8554Z"
      fill="white"
    />
    <path
      d="M255.608 35.5996C257.007 35.5996 258.14 34.466 258.14 33.0676C258.14 31.6692 257.007 30.5356 255.608 30.5356C254.21 30.5356 253.076 31.6692 253.076 33.0676C253.076 34.466 254.21 35.5996 255.608 35.5996Z"
      fill="white"
    />
    <path
      d="M255.608 45.7275C257.007 45.7275 258.14 44.5939 258.14 43.1955C258.14 41.7972 257.007 40.6636 255.608 40.6636C254.21 40.6636 253.076 41.7972 253.076 43.1955C253.076 44.5939 254.21 45.7275 255.608 45.7275Z"
      fill="white"
    />
    <path
      d="M255.608 55.8554C257.007 55.8554 258.14 54.7218 258.14 53.3235C258.14 51.9251 257.007 50.7915 255.608 50.7915C254.21 50.7915 253.076 51.9251 253.076 53.3235C253.076 54.7218 254.21 55.8554 255.608 55.8554Z"
      fill="white"
    />
    <path
      d="M255.608 65.9834C257.007 65.9834 258.14 64.8498 258.14 63.4514C258.14 62.053 257.007 60.9194 255.608 60.9194C254.21 60.9194 253.076 62.053 253.076 63.4514C253.076 64.8498 254.21 65.9834 255.608 65.9834Z"
      fill="white"
    />
    <path
      d="M265.736 45.7275C267.134 45.7275 268.268 44.5939 268.268 43.1955C268.268 41.7972 267.134 40.6636 265.736 40.6636C264.338 40.6636 263.204 41.7972 263.204 43.1955C263.204 44.5939 264.338 45.7275 265.736 45.7275Z"
      fill="white"
    />
    <path
      d="M265.736 55.8554C267.134 55.8554 268.268 54.7218 268.268 53.3235C268.268 51.9251 267.134 50.7915 265.736 50.7915C264.338 50.7915 263.204 51.9251 263.204 53.3235C263.204 54.7218 264.338 55.8554 265.736 55.8554Z"
      fill="white"
    />
    <path
      d="M265.736 65.9834C267.134 65.9834 268.268 64.8498 268.268 63.4514C268.268 62.053 267.134 60.9194 265.736 60.9194C264.338 60.9194 263.204 62.053 263.204 63.4514C263.204 64.8498 264.338 65.9834 265.736 65.9834Z"
      fill="white"
    />
    <path
      d="M265.736 76.1113C267.134 76.1113 268.268 74.9777 268.268 73.5793C268.268 72.181 267.134 71.0474 265.736 71.0474C264.338 71.0474 263.204 72.181 263.204 73.5793C263.204 74.9777 264.338 76.1113 265.736 76.1113Z"
      fill="white"
    />
    <path
      d="M265.736 86.2392C267.134 86.2392 268.268 85.1056 268.268 83.7073C268.268 82.3089 267.134 81.1753 265.736 81.1753C264.338 81.1753 263.204 82.3089 263.204 83.7073C263.204 85.1056 264.338 86.2392 265.736 86.2392Z"
      fill="white"
    />
    <path
      d="M275.864 45.7275C277.262 45.7275 278.396 44.5939 278.396 43.1955C278.396 41.7972 277.262 40.6636 275.864 40.6636C274.466 40.6636 273.332 41.7972 273.332 43.1955C273.332 44.5939 274.466 45.7275 275.864 45.7275Z"
      fill="white"
    />
    <path
      d="M275.864 55.8554C277.262 55.8554 278.396 54.7218 278.396 53.3235C278.396 51.9251 277.262 50.7915 275.864 50.7915C274.466 50.7915 273.332 51.9251 273.332 53.3235C273.332 54.7218 274.466 55.8554 275.864 55.8554Z"
      fill="white"
    />
    <path
      d="M275.864 65.9834C277.262 65.9834 278.396 64.8498 278.396 63.4514C278.396 62.053 277.262 60.9194 275.864 60.9194C274.466 60.9194 273.332 62.053 273.332 63.4514C273.332 64.8498 274.466 65.9834 275.864 65.9834Z"
      fill="white"
    />
    <path
      d="M275.864 76.1113C277.262 76.1113 278.396 74.9777 278.396 73.5793C278.396 72.181 277.262 71.0474 275.864 71.0474C274.466 71.0474 273.332 72.181 273.332 73.5793C273.332 74.9777 274.466 76.1113 275.864 76.1113Z"
      fill="white"
    />
    <path
      d="M275.864 86.2392C277.262 86.2392 278.396 85.1056 278.396 83.7073C278.396 82.3089 277.262 81.1753 275.864 81.1753C274.466 81.1753 273.332 82.3089 273.332 83.7073C273.332 85.1056 274.466 86.2392 275.864 86.2392Z"
      fill="white"
    />
    <path
      d="M275.864 96.3672C277.262 96.3672 278.396 95.2336 278.396 93.8352C278.396 92.4368 277.262 91.3032 275.864 91.3032C274.466 91.3032 273.332 92.4368 273.332 93.8352C273.332 95.2336 274.466 96.3672 275.864 96.3672Z"
      fill="white"
    />
    <path
      d="M285.992 55.8554C287.39 55.8554 288.524 54.7218 288.524 53.3235C288.524 51.9251 287.39 50.7915 285.992 50.7915C284.594 50.7915 283.46 51.9251 283.46 53.3235C283.46 54.7218 284.594 55.8554 285.992 55.8554Z"
      fill="white"
    />
    <path
      d="M285.992 65.9834C287.39 65.9834 288.524 64.8498 288.524 63.4514C288.524 62.053 287.39 60.9194 285.992 60.9194C284.594 60.9194 283.46 62.053 283.46 63.4514C283.46 64.8498 284.594 65.9834 285.992 65.9834Z"
      fill="white"
    />
    <path
      d="M285.992 76.1113C287.39 76.1113 288.524 74.9777 288.524 73.5793C288.524 72.181 287.39 71.0474 285.992 71.0474C284.594 71.0474 283.46 72.181 283.46 73.5793C283.46 74.9777 284.594 76.1113 285.992 76.1113Z"
      fill="white"
    />
    <path
      d="M285.992 86.2392C287.39 86.2392 288.524 85.1056 288.524 83.7073C288.524 82.3089 287.39 81.1753 285.992 81.1753C284.594 81.1753 283.46 82.3089 283.46 83.7073C283.46 85.1056 284.594 86.2392 285.992 86.2392Z"
      fill="white"
    />
    <path
      d="M285.992 96.3672C287.39 96.3672 288.524 95.2336 288.524 93.8352C288.524 92.4368 287.39 91.3032 285.992 91.3032C284.594 91.3032 283.46 92.4368 283.46 93.8352C283.46 95.2336 284.594 96.3672 285.992 96.3672Z"
      fill="white"
    />
    <path
      d="M296.12 65.9834C297.518 65.9834 298.652 64.8498 298.652 63.4514C298.652 62.053 297.518 60.9194 296.12 60.9194C294.721 60.9194 293.588 62.053 293.588 63.4514C293.588 64.8498 294.721 65.9834 296.12 65.9834Z"
      fill="white"
    />
    <path
      d="M296.12 86.2392C297.518 86.2392 298.652 85.1056 298.652 83.7073C298.652 82.3089 297.518 81.1753 296.12 81.1753C294.721 81.1753 293.588 82.3089 293.588 83.7073C293.588 85.1056 294.721 86.2392 296.12 86.2392Z"
      fill="white"
    />
    <path
      d="M296.12 96.3672C297.518 96.3672 298.652 95.2336 298.652 93.8352C298.652 92.4368 297.518 91.3032 296.12 91.3032C294.721 91.3032 293.588 92.4368 293.588 93.8352C293.588 95.2336 294.721 96.3672 296.12 96.3672Z"
      fill="white"
    />
    <path
      d="M306.247 86.2392C307.645 86.2392 308.779 85.1056 308.779 83.7073C308.779 82.3089 307.645 81.1753 306.247 81.1753C304.848 81.1753 303.715 82.3089 303.715 83.7073C303.715 85.1056 304.848 86.2392 306.247 86.2392Z"
      fill="white"
    />
    <path
      d="M306.247 96.3672C307.645 96.3672 308.779 95.2336 308.779 93.8352C308.779 92.4368 307.645 91.3032 306.247 91.3032C304.848 91.3032 303.715 92.4368 303.715 93.8352C303.715 95.2336 304.848 96.3672 306.247 96.3672Z"
      fill="white"
    />
    <path
      d="M113.818 308.394C115.216 308.394 116.35 307.26 116.35 305.862C116.35 304.463 115.216 303.33 113.818 303.33C112.42 303.33 111.286 304.463 111.286 305.862C111.286 307.26 112.42 308.394 113.818 308.394Z"
      fill="white"
    />
    <path
      d="M113.818 318.522C115.216 318.522 116.35 317.389 116.35 315.99C116.35 314.592 115.216 313.458 113.818 313.458C112.42 313.458 111.286 314.592 111.286 315.99C111.286 317.389 112.42 318.522 113.818 318.522Z"
      fill="white"
    />
    <path
      d="M113.818 328.65C115.216 328.65 116.35 327.516 116.35 326.118C116.35 324.72 115.216 323.586 113.818 323.586C112.42 323.586 111.286 324.72 111.286 326.118C111.286 327.516 112.42 328.65 113.818 328.65Z"
      fill="white"
    />
    <path
      d="M123.946 308.394C125.344 308.394 126.478 307.26 126.478 305.862C126.478 304.463 125.344 303.33 123.946 303.33C122.548 303.33 121.414 304.463 121.414 305.862C121.414 307.26 122.548 308.394 123.946 308.394Z"
      fill="white"
    />
    <path
      d="M123.946 318.522C125.344 318.522 126.478 317.389 126.478 315.99C126.478 314.592 125.344 313.458 123.946 313.458C122.548 313.458 121.414 314.592 121.414 315.99C121.414 317.389 122.548 318.522 123.946 318.522Z"
      fill="white"
    />
    <path
      d="M123.946 328.65C125.344 328.65 126.478 327.516 126.478 326.118C126.478 324.72 125.344 323.586 123.946 323.586C122.548 323.586 121.414 324.72 121.414 326.118C121.414 327.516 122.548 328.65 123.946 328.65Z"
      fill="white"
    />
    <path
      d="M123.946 338.778C125.344 338.778 126.478 337.644 126.478 336.246C126.478 334.847 125.344 333.714 123.946 333.714C122.548 333.714 121.414 334.847 121.414 336.246C121.414 337.644 122.548 338.778 123.946 338.778Z"
      fill="white"
    />
    <path
      d="M123.946 348.906C125.344 348.906 126.478 347.772 126.478 346.374C126.478 344.975 125.344 343.842 123.946 343.842C122.548 343.842 121.414 344.975 121.414 346.374C121.414 347.772 122.548 348.906 123.946 348.906Z"
      fill="white"
    />
    <path
      d="M134.074 308.394C135.472 308.394 136.606 307.26 136.606 305.862C136.606 304.463 135.472 303.33 134.074 303.33C132.676 303.33 131.542 304.463 131.542 305.862C131.542 307.26 132.676 308.394 134.074 308.394Z"
      fill="white"
    />
    <path
      d="M134.074 318.522C135.472 318.522 136.606 317.389 136.606 315.99C136.606 314.592 135.472 313.458 134.074 313.458C132.676 313.458 131.542 314.592 131.542 315.99C131.542 317.389 132.676 318.522 134.074 318.522Z"
      fill="white"
    />
    <path
      d="M134.074 328.65C135.472 328.65 136.606 327.516 136.606 326.118C136.606 324.72 135.472 323.586 134.074 323.586C132.676 323.586 131.542 324.72 131.542 326.118C131.542 327.516 132.676 328.65 134.074 328.65Z"
      fill="white"
    />
    <path
      d="M134.074 338.778C135.472 338.778 136.606 337.644 136.606 336.246C136.606 334.847 135.472 333.714 134.074 333.714C132.676 333.714 131.542 334.847 131.542 336.246C131.542 337.644 132.676 338.778 134.074 338.778Z"
      fill="white"
    />
    <path
      d="M134.074 348.906C135.472 348.906 136.606 347.772 136.606 346.374C136.606 344.975 135.472 343.842 134.074 343.842C132.676 343.842 131.542 344.975 131.542 346.374C131.542 347.772 132.676 348.906 134.074 348.906Z"
      fill="white"
    />
    <path
      d="M144.202 328.649C145.6 328.649 146.734 327.516 146.734 326.117C146.734 324.719 145.6 323.585 144.202 323.585C142.804 323.585 141.67 324.719 141.67 326.117C141.67 327.516 142.804 328.649 144.202 328.649Z"
      fill="white"
    />
    <path
      d="M144.202 338.777C145.6 338.777 146.734 337.644 146.734 336.245C146.734 334.847 145.6 333.713 144.202 333.713C142.804 333.713 141.67 334.847 141.67 336.245C141.67 337.644 142.804 338.777 144.202 338.777Z"
      fill="white"
    />
    <path
      d="M144.202 348.905C145.6 348.905 146.734 347.772 146.734 346.373C146.734 344.975 145.6 343.841 144.202 343.841C142.804 343.841 141.67 344.975 141.67 346.373C141.67 347.772 142.804 348.905 144.202 348.905Z"
      fill="white"
    />
    <path
      d="M144.202 359.033C145.6 359.033 146.734 357.9 146.734 356.501C146.734 355.103 145.6 353.969 144.202 353.969C142.804 353.969 141.67 355.103 141.67 356.501C141.67 357.9 142.804 359.033 144.202 359.033Z"
      fill="white"
    />
    <path
      d="M154.329 338.777C155.727 338.777 156.861 337.644 156.861 336.245C156.861 334.847 155.727 333.713 154.329 333.713C152.93 333.713 151.797 334.847 151.797 336.245C151.797 337.644 152.93 338.777 154.329 338.777Z"
      fill="white"
    />
    <path
      d="M154.329 348.905C155.727 348.905 156.861 347.772 156.861 346.373C156.861 344.975 155.727 343.841 154.329 343.841C152.93 343.841 151.797 344.975 151.797 346.373C151.797 347.772 152.93 348.905 154.329 348.905Z"
      fill="white"
    />
    <path
      d="M154.329 359.033C155.727 359.033 156.861 357.9 156.861 356.501C156.861 355.103 155.727 353.969 154.329 353.969C152.93 353.969 151.797 355.103 151.797 356.501C151.797 357.9 152.93 359.033 154.329 359.033Z"
      fill="white"
    />
    <path
      d="M154.329 369.161C155.727 369.161 156.861 368.028 156.861 366.629C156.861 365.231 155.727 364.097 154.329 364.097C152.93 364.097 151.797 365.231 151.797 366.629C151.797 368.028 152.93 369.161 154.329 369.161Z"
      fill="white"
    />
    <path
      d="M164.458 348.905C165.856 348.905 166.99 347.772 166.99 346.373C166.99 344.975 165.856 343.841 164.458 343.841C163.059 343.841 161.926 344.975 161.926 346.373C161.926 347.772 163.059 348.905 164.458 348.905Z"
      fill="white"
    />
    <path
      d="M164.458 359.033C165.856 359.033 166.99 357.9 166.99 356.501C166.99 355.103 165.856 353.969 164.458 353.969C163.059 353.969 161.926 355.103 161.926 356.501C161.926 357.9 163.059 359.033 164.458 359.033Z"
      fill="white"
    />
    <path
      d="M164.458 369.161C165.856 369.161 166.99 368.028 166.99 366.629C166.99 365.231 165.856 364.097 164.458 364.097C163.059 364.097 161.926 365.231 161.926 366.629C161.926 368.028 163.059 369.161 164.458 369.161Z"
      fill="white"
    />
    <path
      d="M174.585 359.033C175.983 359.033 177.117 357.9 177.117 356.501C177.117 355.103 175.983 353.969 174.585 353.969C173.186 353.969 172.053 355.103 172.053 356.501C172.053 357.9 173.186 359.033 174.585 359.033Z"
      fill="white"
    />
    <path
      d="M174.585 369.161C175.983 369.161 177.117 368.028 177.117 366.629C177.117 365.231 175.983 364.097 174.585 364.097C173.186 364.097 172.053 365.231 172.053 366.629C172.053 368.028 173.186 369.161 174.585 369.161Z"
      fill="white"
    />
    <path
      d="M184.714 359.033C186.112 359.033 187.246 357.9 187.246 356.501C187.246 355.103 186.112 353.969 184.714 353.969C183.315 353.969 182.182 355.103 182.182 356.501C182.182 357.9 183.315 359.033 184.714 359.033Z"
      fill="white"
    />
    <path
      d="M184.714 369.161C186.112 369.161 187.246 368.028 187.246 366.629C187.246 365.231 186.112 364.097 184.714 364.097C183.315 364.097 182.182 365.231 182.182 366.629C182.182 368.028 183.315 369.161 184.714 369.161Z"
      fill="white"
    />
    <path
      d="M194.841 369.161C196.239 369.161 197.373 368.028 197.373 366.629C197.373 365.231 196.239 364.097 194.841 364.097C193.442 364.097 192.309 365.231 192.309 366.629C192.309 368.028 193.442 369.161 194.841 369.161Z"
      fill="white"
    />
    <path
      d="M194.841 379.289C196.239 379.289 197.373 378.155 197.373 376.757C197.373 375.359 196.239 374.225 194.841 374.225C193.442 374.225 192.309 375.359 192.309 376.757C192.309 378.155 193.442 379.289 194.841 379.289Z"
      fill="white"
    />
    <path
      d="M204.969 369.161C206.368 369.161 207.501 368.028 207.501 366.629C207.501 365.231 206.368 364.097 204.969 364.097C203.571 364.097 202.438 365.231 202.438 366.629C202.438 368.028 203.571 369.161 204.969 369.161Z"
      fill="white"
    />
    <path
      d="M204.969 379.289C206.368 379.289 207.501 378.155 207.501 376.757C207.501 375.359 206.368 374.225 204.969 374.225C203.571 374.225 202.438 375.359 202.438 376.757C202.438 378.155 203.571 379.289 204.969 379.289Z"
      fill="white"
    />
    <path
      d="M215.096 369.161C216.495 369.161 217.628 368.028 217.628 366.629C217.628 365.231 216.495 364.097 215.096 364.097C213.698 364.097 212.564 365.231 212.564 366.629C212.564 368.028 213.698 369.161 215.096 369.161Z"
      fill="white"
    />
    <path
      d="M215.096 379.289C216.495 379.289 217.628 378.155 217.628 376.757C217.628 375.359 216.495 374.225 215.096 374.225C213.698 374.225 212.564 375.359 212.564 376.757C212.564 378.155 213.698 379.289 215.096 379.289Z"
      fill="white"
    />
    <path
      d="M225.225 369.161C226.624 369.161 227.757 368.028 227.757 366.629C227.757 365.231 226.624 364.097 225.225 364.097C223.827 364.097 222.693 365.231 222.693 366.629C222.693 368.028 223.827 369.161 225.225 369.161Z"
      fill="white"
    />
    <path
      d="M225.225 379.289C226.624 379.289 227.757 378.155 227.757 376.757C227.757 375.359 226.624 374.225 225.225 374.225C223.827 374.225 222.693 375.359 222.693 376.757C222.693 378.155 223.827 379.289 225.225 379.289Z"
      fill="white"
    />
    <path
      d="M235.352 369.161C236.751 369.161 237.884 368.028 237.884 366.629C237.884 365.231 236.751 364.097 235.352 364.097C233.954 364.097 232.82 365.231 232.82 366.629C232.82 368.028 233.954 369.161 235.352 369.161Z"
      fill="white"
    />
    <path
      d="M245.48 359.034C246.879 359.034 248.012 357.9 248.012 356.502C248.012 355.103 246.879 353.97 245.48 353.97C244.082 353.97 242.948 355.103 242.948 356.502C242.948 357.9 244.082 359.034 245.48 359.034Z"
      fill="white"
    />
    <path
      d="M245.48 369.162C246.879 369.162 248.012 368.028 248.012 366.63C248.012 365.231 246.879 364.098 245.48 364.098C244.082 364.098 242.948 365.231 242.948 366.63C242.948 368.028 244.082 369.162 245.48 369.162Z"
      fill="white"
    />
    <path
      d="M255.608 359.034C257.007 359.034 258.14 357.9 258.14 356.502C258.14 355.103 257.007 353.97 255.608 353.97C254.21 353.97 253.076 355.103 253.076 356.502C253.076 357.9 254.21 359.034 255.608 359.034Z"
      fill="white"
    />
    <path
      d="M255.608 369.162C257.007 369.162 258.14 368.028 258.14 366.63C258.14 365.231 257.007 364.098 255.608 364.098C254.21 364.098 253.076 365.231 253.076 366.63C253.076 368.028 254.21 369.162 255.608 369.162Z"
      fill="white"
    />
    <path
      d="M265.736 348.906C267.134 348.906 268.268 347.772 268.268 346.374C268.268 344.975 267.134 343.842 265.736 343.842C264.338 343.842 263.204 344.975 263.204 346.374C263.204 347.772 264.338 348.906 265.736 348.906Z"
      fill="white"
    />
    <path
      d="M265.736 359.034C267.134 359.034 268.268 357.9 268.268 356.502C268.268 355.103 267.134 353.97 265.736 353.97C264.338 353.97 263.204 355.103 263.204 356.502C263.204 357.9 264.338 359.034 265.736 359.034Z"
      fill="white"
    />
    <path
      d="M265.736 369.162C267.134 369.162 268.268 368.028 268.268 366.63C268.268 365.231 267.134 364.098 265.736 364.098C264.338 364.098 263.204 365.231 263.204 366.63C263.204 368.028 264.338 369.162 265.736 369.162Z"
      fill="white"
    />
    <path
      d="M275.864 338.778C277.262 338.778 278.396 337.644 278.396 336.246C278.396 334.847 277.262 333.714 275.864 333.714C274.466 333.714 273.332 334.847 273.332 336.246C273.332 337.644 274.466 338.778 275.864 338.778Z"
      fill="white"
    />
    <path
      d="M275.864 348.906C277.262 348.906 278.396 347.772 278.396 346.374C278.396 344.975 277.262 343.842 275.864 343.842C274.466 343.842 273.332 344.975 273.332 346.374C273.332 347.772 274.466 348.906 275.864 348.906Z"
      fill="white"
    />
    <path
      d="M275.864 359.034C277.262 359.034 278.396 357.9 278.396 356.502C278.396 355.103 277.262 353.97 275.864 353.97C274.466 353.97 273.332 355.103 273.332 356.502C273.332 357.9 274.466 359.034 275.864 359.034Z"
      fill="white"
    />
    <path
      d="M285.992 308.394C287.39 308.394 288.524 307.26 288.524 305.862C288.524 304.463 287.39 303.33 285.992 303.33C284.594 303.33 283.46 304.463 283.46 305.862C283.46 307.26 284.594 308.394 285.992 308.394Z"
      fill="white"
    />
    <path
      d="M285.992 318.522C287.39 318.522 288.524 317.389 288.524 315.99C288.524 314.592 287.39 313.458 285.992 313.458C284.594 313.458 283.46 314.592 283.46 315.99C283.46 317.389 284.594 318.522 285.992 318.522Z"
      fill="white"
    />
    <path
      d="M285.992 328.65C287.39 328.65 288.524 327.516 288.524 326.118C288.524 324.72 287.39 323.586 285.992 323.586C284.594 323.586 283.46 324.72 283.46 326.118C283.46 327.516 284.594 328.65 285.992 328.65Z"
      fill="white"
    />
    <path
      d="M285.992 338.778C287.39 338.778 288.524 337.644 288.524 336.246C288.524 334.847 287.39 333.714 285.992 333.714C284.594 333.714 283.46 334.847 283.46 336.246C283.46 337.644 284.594 338.778 285.992 338.778Z"
      fill="white"
    />
    <path
      d="M285.992 348.906C287.39 348.906 288.524 347.772 288.524 346.374C288.524 344.975 287.39 343.842 285.992 343.842C284.594 343.842 283.46 344.975 283.46 346.374C283.46 347.772 284.594 348.906 285.992 348.906Z"
      fill="white"
    />
    <path
      d="M285.992 359.034C287.39 359.034 288.524 357.9 288.524 356.502C288.524 355.103 287.39 353.97 285.992 353.97C284.594 353.97 283.46 355.103 283.46 356.502C283.46 357.9 284.594 359.034 285.992 359.034Z"
      fill="white"
    />
    <path
      d="M296.12 308.394C297.518 308.394 298.652 307.26 298.652 305.862C298.652 304.463 297.518 303.33 296.12 303.33C294.721 303.33 293.588 304.463 293.588 305.862C293.588 307.26 294.721 308.394 296.12 308.394Z"
      fill="white"
    />
    <path
      d="M296.12 318.522C297.518 318.522 298.652 317.389 298.652 315.99C298.652 314.592 297.518 313.458 296.12 313.458C294.721 313.458 293.588 314.592 293.588 315.99C293.588 317.389 294.721 318.522 296.12 318.522Z"
      fill="white"
    />
    <path
      d="M296.12 328.65C297.518 328.65 298.652 327.516 298.652 326.118C298.652 324.72 297.518 323.586 296.12 323.586C294.721 323.586 293.588 324.72 293.588 326.118C293.588 327.516 294.721 328.65 296.12 328.65Z"
      fill="white"
    />
    <path
      d="M296.12 338.778C297.518 338.778 298.652 337.644 298.652 336.246C298.652 334.847 297.518 333.714 296.12 333.714C294.721 333.714 293.588 334.847 293.588 336.246C293.588 337.644 294.721 338.778 296.12 338.778Z"
      fill="white"
    />
    <path
      d="M296.12 348.906C297.518 348.906 298.652 347.772 298.652 346.374C298.652 344.975 297.518 343.842 296.12 343.842C294.721 343.842 293.588 344.975 293.588 346.374C293.588 347.772 294.721 348.906 296.12 348.906Z"
      fill="white"
    />
    <path
      d="M306.247 308.394C307.645 308.394 308.779 307.26 308.779 305.862C308.779 304.463 307.645 303.33 306.247 303.33C304.848 303.33 303.715 304.463 303.715 305.862C303.715 307.26 304.848 308.394 306.247 308.394Z"
      fill="white"
    />
    <path
      d="M306.247 318.522C307.645 318.522 308.779 317.389 308.779 315.99C308.779 314.592 307.645 313.458 306.247 313.458C304.848 313.458 303.715 314.592 303.715 315.99C303.715 317.389 304.848 318.522 306.247 318.522Z"
      fill="white"
    />
    <path
      d="M306.247 328.649C307.645 328.649 308.779 327.516 308.779 326.117C308.779 324.719 307.645 323.585 306.247 323.585C304.848 323.585 303.715 324.719 303.715 326.117C303.715 327.516 304.848 328.649 306.247 328.649Z"
      fill="white"
    />
    <path
      d="M306.247 338.777C307.645 338.777 308.779 337.644 308.779 336.245C308.779 334.847 307.645 333.713 306.247 333.713C304.848 333.713 303.715 334.847 303.715 336.245C303.715 337.644 304.848 338.777 306.247 338.777Z"
      fill="white"
    />
    <path
      d="M316.376 308.394C317.774 308.394 318.908 307.26 318.908 305.862C318.908 304.463 317.774 303.33 316.376 303.33C314.977 303.33 313.844 304.463 313.844 305.862C313.844 307.26 314.977 308.394 316.376 308.394Z"
      fill="white"
    />
    <path
      d="M316.376 318.522C317.774 318.522 318.908 317.389 318.908 315.99C318.908 314.592 317.774 313.458 316.376 313.458C314.977 313.458 313.844 314.592 313.844 315.99C313.844 317.389 314.977 318.522 316.376 318.522Z"
      fill="white"
    />
    <path
      d="M103.69 298.367C105.089 298.367 106.222 297.233 106.222 295.835C106.222 294.436 105.089 293.303 103.69 293.303C102.292 293.303 101.158 294.436 101.158 295.835C101.158 297.233 102.292 298.367 103.69 298.367Z"
      fill="white"
    />
    <path
      d="M113.818 298.367C115.216 298.367 116.35 297.233 116.35 295.835C116.35 294.436 115.216 293.303 113.818 293.303C112.42 293.303 111.286 294.436 111.286 295.835C111.286 297.233 112.42 298.367 113.818 298.367Z"
      fill="white"
    />
    <path
      d="M123.946 298.367C125.344 298.367 126.478 297.233 126.478 295.835C126.478 294.436 125.344 293.303 123.946 293.303C122.548 293.303 121.414 294.436 121.414 295.835C121.414 297.233 122.548 298.367 123.946 298.367Z"
      fill="white"
    />
    <path
      d="M134.074 288.239C135.472 288.239 136.606 287.105 136.606 285.707C136.606 284.308 135.472 283.175 134.074 283.175C132.676 283.175 131.542 284.308 131.542 285.707C131.542 287.105 132.676 288.239 134.074 288.239Z"
      fill="white"
    />
    <path
      d="M134.074 298.367C135.472 298.367 136.606 297.233 136.606 295.835C136.606 294.436 135.472 293.303 134.074 293.303C132.676 293.303 131.542 294.436 131.542 295.835C131.542 297.233 132.676 298.367 134.074 298.367Z"
      fill="white"
    />
    <path
      d="M225.225 207.216C226.624 207.216 227.757 206.082 227.757 204.684C227.757 203.285 226.624 202.152 225.225 202.152C223.827 202.152 222.693 203.285 222.693 204.684C222.693 206.082 223.827 207.216 225.225 207.216Z"
      fill="white"
    />
    <path
      d="M235.352 207.216C236.751 207.216 237.884 206.082 237.884 204.684C237.884 203.285 236.751 202.152 235.352 202.152C233.954 202.152 232.82 203.285 232.82 204.684C232.82 206.082 233.954 207.216 235.352 207.216Z"
      fill="white"
    />
    <path
      d="M245.48 207.216C246.879 207.216 248.012 206.083 248.012 204.684C248.012 203.286 246.879 202.152 245.48 202.152C244.082 202.152 242.948 203.286 242.948 204.684C242.948 206.083 244.082 207.216 245.48 207.216Z"
      fill="white"
    />
    <path
      d="M255.608 207.216C257.007 207.216 258.14 206.083 258.14 204.684C258.14 203.286 257.007 202.152 255.608 202.152C254.21 202.152 253.076 203.286 253.076 204.684C253.076 206.083 254.21 207.216 255.608 207.216Z"
      fill="white"
    />
    <path
      d="M255.608 217.344C257.007 217.344 258.14 216.211 258.14 214.812C258.14 213.414 257.007 212.28 255.608 212.28C254.21 212.28 253.076 213.414 253.076 214.812C253.076 216.211 254.21 217.344 255.608 217.344Z"
      fill="white"
    />
    <path
      d="M265.736 207.216C267.134 207.216 268.268 206.083 268.268 204.684C268.268 203.286 267.134 202.152 265.736 202.152C264.338 202.152 263.204 203.286 263.204 204.684C263.204 206.083 264.338 207.216 265.736 207.216Z"
      fill="white"
    />
    <path
      d="M265.736 217.344C267.134 217.344 268.268 216.211 268.268 214.812C268.268 213.414 267.134 212.28 265.736 212.28C264.338 212.28 263.204 213.414 263.204 214.812C263.204 216.211 264.338 217.344 265.736 217.344Z"
      fill="white"
    />
    <path
      d="M265.736 227.472C267.134 227.472 268.268 226.339 268.268 224.94C268.268 223.542 267.134 222.408 265.736 222.408C264.338 222.408 263.204 223.542 263.204 224.94C263.204 226.339 264.338 227.472 265.736 227.472Z"
      fill="white"
    />
    <path
      d="M275.864 207.216C277.262 207.216 278.396 206.083 278.396 204.684C278.396 203.286 277.262 202.152 275.864 202.152C274.466 202.152 273.332 203.286 273.332 204.684C273.332 206.083 274.466 207.216 275.864 207.216Z"
      fill="white"
    />
    <path
      d="M275.864 217.344C277.262 217.344 278.396 216.211 278.396 214.812C278.396 213.414 277.262 212.28 275.864 212.28C274.466 212.28 273.332 213.414 273.332 214.812C273.332 216.211 274.466 217.344 275.864 217.344Z"
      fill="white"
    />
    <path
      d="M275.864 227.472C277.262 227.472 278.396 226.339 278.396 224.94C278.396 223.542 277.262 222.408 275.864 222.408C274.466 222.408 273.332 223.542 273.332 224.94C273.332 226.339 274.466 227.472 275.864 227.472Z"
      fill="white"
    />
    <path
      d="M275.864 237.6C277.262 237.6 278.396 236.466 278.396 235.068C278.396 233.669 277.262 232.536 275.864 232.536C274.466 232.536 273.332 233.669 273.332 235.068C273.332 236.466 274.466 237.6 275.864 237.6Z"
      fill="white"
    />
    <path
      d="M285.992 217.344C287.39 217.344 288.524 216.211 288.524 214.812C288.524 213.414 287.39 212.28 285.992 212.28C284.594 212.28 283.46 213.414 283.46 214.812C283.46 216.211 284.594 217.344 285.992 217.344Z"
      fill="white"
    />
    <path
      d="M285.992 227.472C287.39 227.472 288.524 226.339 288.524 224.94C288.524 223.542 287.39 222.408 285.992 222.408C284.594 222.408 283.46 223.542 283.46 224.94C283.46 226.339 284.594 227.472 285.992 227.472Z"
      fill="white"
    />
    <path
      d="M285.992 237.6C287.39 237.6 288.524 236.466 288.524 235.068C288.524 233.669 287.39 232.536 285.992 232.536C284.594 232.536 283.46 233.669 283.46 235.068C283.46 236.466 284.594 237.6 285.992 237.6Z"
      fill="white"
    />
    <path
      d="M285.992 247.727C287.39 247.727 288.524 246.593 288.524 245.195C288.524 243.797 287.39 242.663 285.992 242.663C284.594 242.663 283.46 243.797 283.46 245.195C283.46 246.593 284.594 247.727 285.992 247.727Z"
      fill="white"
    />
    <path
      d="M285.992 257.855C287.39 257.855 288.524 256.721 288.524 255.323C288.524 253.925 287.39 252.791 285.992 252.791C284.594 252.791 283.46 253.925 283.46 255.323C283.46 256.721 284.594 257.855 285.992 257.855Z"
      fill="white"
    />
    <path
      d="M285.992 267.983C287.39 267.983 288.524 266.849 288.524 265.451C288.524 264.053 287.39 262.919 285.992 262.919C284.594 262.919 283.46 264.053 283.46 265.451C283.46 266.849 284.594 267.983 285.992 267.983Z"
      fill="white"
    />
    <path
      d="M285.992 298.367C287.39 298.367 288.524 297.233 288.524 295.835C288.524 294.436 287.39 293.303 285.992 293.303C284.594 293.303 283.46 294.436 283.46 295.835C283.46 297.233 284.594 298.367 285.992 298.367Z"
      fill="white"
    />
    <path
      d="M296.12 217.344C297.518 217.344 298.652 216.211 298.652 214.812C298.652 213.414 297.518 212.28 296.12 212.28C294.721 212.28 293.588 213.414 293.588 214.812C293.588 216.211 294.721 217.344 296.12 217.344Z"
      fill="white"
    />
    <path
      d="M296.12 227.472C297.518 227.472 298.652 226.339 298.652 224.94C298.652 223.542 297.518 222.408 296.12 222.408C294.721 222.408 293.588 223.542 293.588 224.94C293.588 226.339 294.721 227.472 296.12 227.472Z"
      fill="white"
    />
    <path
      d="M296.12 237.6C297.518 237.6 298.652 236.466 298.652 235.068C298.652 233.669 297.518 232.536 296.12 232.536C294.721 232.536 293.588 233.669 293.588 235.068C293.588 236.466 294.721 237.6 296.12 237.6Z"
      fill="white"
    />
    <path
      d="M296.12 247.727C297.518 247.727 298.652 246.593 298.652 245.195C298.652 243.797 297.518 242.663 296.12 242.663C294.721 242.663 293.588 243.797 293.588 245.195C293.588 246.593 294.721 247.727 296.12 247.727Z"
      fill="white"
    />
    <path
      d="M296.12 257.855C297.518 257.855 298.652 256.721 298.652 255.323C298.652 253.925 297.518 252.791 296.12 252.791C294.721 252.791 293.588 253.925 293.588 255.323C293.588 256.721 294.721 257.855 296.12 257.855Z"
      fill="white"
    />
    <path
      d="M296.12 267.983C297.518 267.983 298.652 266.849 298.652 265.451C298.652 264.053 297.518 262.919 296.12 262.919C294.721 262.919 293.588 264.053 293.588 265.451C293.588 266.849 294.721 267.983 296.12 267.983Z"
      fill="white"
    />
    <path
      d="M296.12 278.111C297.518 278.111 298.652 276.977 298.652 275.579C298.652 274.18 297.518 273.047 296.12 273.047C294.721 273.047 293.588 274.18 293.588 275.579C293.588 276.977 294.721 278.111 296.12 278.111Z"
      fill="white"
    />
    <path
      d="M296.12 288.239C297.518 288.239 298.652 287.105 298.652 285.707C298.652 284.308 297.518 283.175 296.12 283.175C294.721 283.175 293.588 284.308 293.588 285.707C293.588 287.105 294.721 288.239 296.12 288.239Z"
      fill="white"
    />
    <path
      d="M296.12 298.367C297.518 298.367 298.652 297.233 298.652 295.835C298.652 294.436 297.518 293.303 296.12 293.303C294.721 293.303 293.588 294.436 293.588 295.835C293.588 297.233 294.721 298.367 296.12 298.367Z"
      fill="white"
    />
    <path
      d="M306.247 227.472C307.645 227.472 308.779 226.338 308.779 224.94C308.779 223.541 307.645 222.408 306.247 222.408C304.848 222.408 303.715 223.541 303.715 224.94C303.715 226.338 304.848 227.472 306.247 227.472Z"
      fill="white"
    />
    <path
      d="M306.247 237.6C307.645 237.6 308.779 236.466 308.779 235.068C308.779 233.669 307.645 232.536 306.247 232.536C304.848 232.536 303.715 233.669 303.715 235.068C303.715 236.466 304.848 237.6 306.247 237.6Z"
      fill="white"
    />
    <path
      d="M306.247 247.728C307.645 247.728 308.779 246.594 308.779 245.196C308.779 243.797 307.645 242.664 306.247 242.664C304.848 242.664 303.715 243.797 303.715 245.196C303.715 246.594 304.848 247.728 306.247 247.728Z"
      fill="white"
    />
    <path
      d="M306.247 257.855C307.645 257.855 308.779 256.722 308.779 255.323C308.779 253.925 307.645 252.792 306.247 252.792C304.848 252.792 303.715 253.925 303.715 255.323C303.715 256.722 304.848 257.855 306.247 257.855Z"
      fill="white"
    />
    <path
      d="M306.247 267.983C307.645 267.983 308.779 266.85 308.779 265.451C308.779 264.053 307.645 262.919 306.247 262.919C304.848 262.919 303.715 264.053 303.715 265.451C303.715 266.85 304.848 267.983 306.247 267.983Z"
      fill="white"
    />
    <path
      d="M306.247 278.111C307.645 278.111 308.779 276.978 308.779 275.579C308.779 274.181 307.645 273.047 306.247 273.047C304.848 273.047 303.715 274.181 303.715 275.579C303.715 276.978 304.848 278.111 306.247 278.111Z"
      fill="white"
    />
    <path
      d="M306.247 288.239C307.645 288.239 308.779 287.106 308.779 285.707C308.779 284.309 307.645 283.175 306.247 283.175C304.848 283.175 303.715 284.309 303.715 285.707C303.715 287.106 304.848 288.239 306.247 288.239Z"
      fill="white"
    />
    <path
      d="M306.247 298.367C307.645 298.367 308.779 297.234 308.779 295.835C308.779 294.437 307.645 293.303 306.247 293.303C304.848 293.303 303.715 294.437 303.715 295.835C303.715 297.234 304.848 298.367 306.247 298.367Z"
      fill="white"
    />
    <path
      d="M316.376 247.728C317.774 247.728 318.908 246.594 318.908 245.196C318.908 243.797 317.774 242.664 316.376 242.664C314.977 242.664 313.844 243.797 313.844 245.196C313.844 246.594 314.977 247.728 316.376 247.728Z"
      fill="white"
    />
    <path
      d="M316.376 257.855C317.774 257.855 318.908 256.722 318.908 255.323C318.908 253.925 317.774 252.792 316.376 252.792C314.977 252.792 313.844 253.925 313.844 255.323C313.844 256.722 314.977 257.855 316.376 257.855Z"
      fill="white"
    />
    <path
      d="M316.376 267.983C317.774 267.983 318.908 266.85 318.908 265.451C318.908 264.053 317.774 262.919 316.376 262.919C314.977 262.919 313.844 264.053 313.844 265.451C313.844 266.85 314.977 267.983 316.376 267.983Z"
      fill="white"
    />
    <path
      d="M316.376 278.111C317.774 278.111 318.908 276.978 318.908 275.579C318.908 274.181 317.774 273.047 316.376 273.047C314.977 273.047 313.844 274.181 313.844 275.579C313.844 276.978 314.977 278.111 316.376 278.111Z"
      fill="white"
    />
    <path
      d="M316.376 288.239C317.774 288.239 318.908 287.106 318.908 285.707C318.908 284.309 317.774 283.175 316.376 283.175C314.977 283.175 313.844 284.309 313.844 285.707C313.844 287.106 314.977 288.239 316.376 288.239Z"
      fill="white"
    />
    <path
      d="M316.376 298.367C317.774 298.367 318.908 297.234 318.908 295.835C318.908 294.437 317.774 293.303 316.376 293.303C314.977 293.303 313.844 294.437 313.844 295.835C313.844 297.234 314.977 298.367 316.376 298.367Z"
      fill="white"
    />
  </svg>
);
