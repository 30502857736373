/* eslint-disable @typescript-eslint/no-misused-promises */
import { PageComponent } from "../types/gatsby.types";
import ProductUpdatePageContent from "../components/productUpdates/ProductUpdatePageContent";
import Page from "./Page.template";
import { ProductUpdateTimelineEntry } from "../components/hub/ProductUpdateTimeline";

const WhatsNewPage: PageComponent<{
  pageContext: {
    timeline: ProductUpdateTimelineEntry[];
  };
}> = props => {
  return (
    <Page {...props}>
      <ProductUpdatePageContent
        entries={[]}
        timeline={props.pageContext.timeline}
      />
    </Page>
  );
};

export default WhatsNewPage;
