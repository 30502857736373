import { styled } from "@linaria/react";
import { cx } from "linaria";
import { ReactNode, useState } from "react";
import { rSize } from "../../styles/responsiveSizes.styles";
import { StoryDescriptor } from "../../types/helper.types";
import { ValidStoryEmbed } from "../../utils/datocms.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import LoadingIndicator from "../utilities/LoadingIndicator";
import StoryCardWithEmbed, {
  StoryCardWithEmbedDiv,
} from "../library/StoryCardWithEmbed";
import { BrandColorNameV4, colorsV4 } from "../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { getStoryMetaById } from "../../utils/library.utils";
import { DARK_MODE_CLASS } from "../utilities/DarkMode";
import { convertDatoRecordToStoryDescriptor } from "../../utils/story.utils";

type Props = {
  storyId?: string;
  record?: ValidStoryEmbed;
  fullWidth?: boolean;
  color?: BrandColorNameV4;
  descriptor?: StoryDescriptor;
  overrides?: Partial<StoryDescriptor>;
  darkMode?: boolean;
  withBorder?: boolean;
  hideTools?: boolean;
  withLinkToStoryPage?: boolean;
  description?: ReactNode;
  asLightbox?: boolean;
  noCloseButton?: boolean;
  pillText?: string;
  disableFullScreen?: boolean;
  utmCampaign?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
  doNotApplyCommunityStyling?: boolean;
};

export const StoryEmbedWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  ${StoryCardWithEmbedDiv} {
    min-height: 400px;
  }
  /* &.asLightbox {
    ${StoryCardWithEmbedDiv} {
    }
  } */
`;

const MessageWrapper = styled.div`
  padding: ${rSize("cardSectionPadding")} ${rSize("xl")};
  background-color: ${colorsV4.canvas600};
  ${darkModeLinariaCSS(`background-color: ${colorsV4.warmBlack};`)}
  ${DARK_MODE_CLASS} & {
    background-color: ${colorsV4.warmBlack};
  }
  border-radius: ${rSize("radius")};
  text-align: center;
  p {
    font-weight: 500;
    opacity: 0.5;
  }
  > * + * {
    margin-top: 1em;
  }
`;

const StoryEmbed = (p: Props) => {
  const [ready, setReady] = useState(!!p.descriptor || !!p.record?.story);
  const [story, setStory] = useState<null | StoryDescriptor>(
    p.descriptor
      ? { ...p.descriptor, ...p.overrides }
      : p.record?.story
      ? {
          ...convertDatoRecordToStoryDescriptor(p.record.story),
          ...p.overrides,
        }
      : null
  );
  const [notFound, setNotFound] = useState(false);
  useOnMount(() => {
    (async () => {
      if (ready) return;
      try {
        let storyId = p.storyId;
        if (!storyId) {
          const match = p.record?.storyUrl?.match(
            /\/(?:story-)?library\/(?:stories\/)?(\d+)\/([^/]+)\/?/
          );
          if (!match) {
            setNotFound(true);
            return;
          }
          storyId = match[1]!;
        }
        const storyMatchedById = await getStoryMetaById(storyId);
        if (storyMatchedById) setStory({ ...storyMatchedById, ...p.overrides });
        else setNotFound(true);
      } catch (e) {
        setNotFound(true);
      } finally {
        setReady(true);
      }
    })();
  });
  return (
    <StoryEmbedWrapper
      className={cx(
        p.record?.fullWidth && "showInFullPageWidth",
        p.asLightbox && "asLightbox",
        p.darkMode && DARK_MODE_CLASS
      )}
    >
      {ready ? (
        story ? (
          <StoryCardWithEmbed
            story={story}
            layout={p.fullWidth || p.record?.fullWidth ? "split" : "centered"}
            pillText={p.pillText || p.record?.pillText}
            overrideColor={
              p.color ||
              (p.record?.overrideColor
                ? (p.record.overrideColor.toLowerCase() as
                    | BrandColorNameV4
                    | null
                    | undefined)
                : undefined)
            }
            description={p.description}
            withBorder={p.withBorder}
            withLinkToStoryPage={p.withLinkToStoryPage}
            asLightbox={p.asLightbox}
            noCloseButton={p.noCloseButton}
            hideTools={p.hideTools}
            disableFullScreen={p.disableFullScreen || p.asLightbox}
            doNotApplyCommunityStyling={p.doNotApplyCommunityStyling}
            utmCampaign={p.utmCampaign ?? p.record?.utmCampaign}
            utmMedium={p.utmMedium ?? p.record?.utmMedium}
            utmSource={p.utmSource ?? p.record?.utmSource}
          />
        ) : notFound ? (
          <MessageWrapper>
            <p>This story does not exist.</p>
          </MessageWrapper>
        ) : (
          <MessageWrapper>
            <p>Something went wrong.</p>
          </MessageWrapper>
        )
      ) : (
        <MessageWrapper>
          <LoadingIndicator />
          <p>Loading story...</p>
        </MessageWrapper>
      )}
    </StoryEmbedWrapper>
  );
};

export default StoryEmbed;
