export const MagicCrystalBallTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <circle opacity="0.3" cx="9.5" cy="8.5" r="4.5" fill="currentColor" />
    <circle cx="9" cy="8" r="5.5" stroke="currentColor" />
    <rect x="3.5" y="13.5" width="11" height="2" rx="1" stroke="currentColor" />
    <path
      d="M3 5.22727C4.5 5.22727 5 4.63636 5 3C5 4.63636 5.5 5.22727 7 5.22727C5.5 5.22727 5 6.86364 5 8.5C5 6.86364 4.5 5.22727 3 5.22727Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 7H11M10 6V8.5" stroke="currentColor" strokeLinecap="round" />
  </svg>
);
