import { styled } from "@linaria/react";
import { ActionType } from "../../types/tines.types";
import TransformIcon20 from "./icons/Transform.icon.20";
import { FormIcon16 } from "./icons/Form.icon.16";
import GroupIcon20 from "./icons/Group.icon.20";
import HTTPRequestIcon20 from "./icons/HTTPRequest.icon.20";
import ReceiveEmailIcon20 from "./icons/ReceiveEmail.icon.20";
import SendEmailIcon20 from "./icons/SendEmail.icon.20";
import SendToStoryIcon20 from "./icons/SendToStory.icon.20";
import TriggerIcon20 from "./icons/Trigger.icon.20";
import WebhookIcon20 from "./icons/Webhook.icon.20";

type Props = {
  type: ActionType;
};

const ActionIconComponents = {
  webhook: WebhookIcon20,
  eventTransformation: TransformIcon20,
  trigger: TriggerIcon20,
  httpRequest: HTTPRequestIcon20,
  sendToStory: SendToStoryIcon20,
  email: SendEmailIcon20,
  imap: ReceiveEmailIcon20,
  group: GroupIcon20,
  form: FormIcon16,
} as const;

const ActionIconWrap = styled.div`
  svg {
    display: block;
    color: white;
  }
`;

const ActionIcon = (props: Props) => {
  const Icon =
    ActionIconComponents[props.type as keyof typeof ActionIconComponents];
  return (
    <ActionIconWrap className="ActionIcon">
      {Icon ? <Icon /> : null}
    </ActionIconWrap>
  );
};

export default ActionIcon;
