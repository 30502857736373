export const PlaceholderIcon15 = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d="M10.7586 11.5005H4.24138C3.82991 11.5005 3.4995 11.1671 3.4995 10.7586V4.24138C3.4995 3.82828 3.82828 3.4995 4.24138 3.4995H10.7586C11.1671 3.4995 11.5005 3.82991 11.5005 4.24138V10.7586C11.5005 11.1655 11.1655 11.5005 10.7586 11.5005Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
