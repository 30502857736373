import { colors } from "../../../styles/colors.styles";
import Spacing from "../../layout/Spacing";
import ReportChapterSummary from "../components/ReportChapterSummary";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import ReportV2Section from "../components/ReportV2Section";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2SectionSubheading } from "../components/ReportV2SectionSubheading";
import ReportV2StatTable from "../components/ReportV2StatTable";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { HouseOfCardsIcon } from "./icons/HouseOfCardsIcon";
import { SquareWheelIcon } from "./icons/SquareWheelIcon";
import { TorchIcon } from "./icons/TorchIcon";

export const SOC2023Chapter3 = () => {
  return (
    <section id="chapter-3-barriers-to-good-work">
      <ReportV2ChapterHeaderSection
        chapterNumber={3}
        largeChapterNumberStyle="dots"
        color="orange"
        icon={<TorchIcon />}
      >
        <h2>
          Barriers to <span>good work</span>
        </h2>
        <ReportV2TextBlock largerText>
          <p>
            Security teams want to do their best work — they’re passionate about
            protecting their organizations and highly engaged in their roles.
          </p>
          <p>
            Let’s take a look at some of the other obstacles that SOC teams
            encounter.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2Section
        backgroundColor={colors.orange100}
        color={colors.orange800}
      >
        <ReportV2SectionHeader>
          <ReportV2SectionHeadingMedium>
            Top three challenges
          </ReportV2SectionHeadingMedium>
          <ReportV2SectionSubheading>
            We wanted to better understand the primary pain points for SOC
            teams, so we asked respondents to rank their teams’ top day-to-day
            challenges. The following ranked in the top five most frequently.
          </ReportV2SectionSubheading>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="orange"
          items={[
            {
              percentage: 37,
              heading: "Too much data, not enough information",
              description:
                "Security teams are drowning in data but struggle to turn that data into actionable insights.",
            },
            {
              percentage: 36,
              heading: "Time spent on manual tasks",
              description:
                "As we’ve seen elsewhere, tedious tasks — like reporting, monitoring, and detection — are a daily challenge.",
            },
            {
              percentage: 34,
              heading: "Too many reporting requirements",
              description:
                "Reporting is one of the least popular tasks, likely because of its arduous requirements.",
            },
            { percentage: 31, heading: "Too many logs" },
            { percentage: 30, heading: "Compliance" },
            { percentage: 30, heading: "Too many alerts" },
            { percentage: 29, heading: "System downtime" },
            { percentage: 28, heading: "High staff turnover rate" },
            { percentage: 28, heading: "Lack of training" },
            { percentage: 28, heading: "Understaffed" },
            { percentage: 26, heading: "Tools don’t integrate" },
            { percentage: 26, heading: "Poor processes" },
            {
              percentage: 25,
              heading: "Clunky, outdated, or misconfigured tools",
            },
            { percentage: 25, heading: "Leadership issues" },
            { percentage: 23, heading: "Restrictive SLAs" },
            { percentage: 23, heading: "Teams are very siloed" },
            { percentage: 22, heading: "Poor visibility into our environment" },
            { percentage: 17, heading: "Boredom" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.lightest}
        color={colors.orange800}
      >
        <ReportV2SectionHeader icon={<HouseOfCardsIcon />} iconAlign="start">
          <ReportV2SectionHeadingMedium>
            Lack of time, budget, and effective tools are inhibiting SOC teams
          </ReportV2SectionHeadingMedium>
          <Spacing />
          <p>
            Overall, what prevents the SOC team from doing their best work? In a
            word: resources. Our respondents, selecting all that applied, said a
            lack of time (42%) was the top factor holding them back, followed by
            lack of budget (39%), lack of effective tools (39%), and lack of
            people (35%).
          </p>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="orange"
          borderColor={colors.orange50}
          rowHighlightBorderColor={colors.orange100}
          numberOfRowsToHighlight={3}
          items={[
            { percentage: 42, heading: "Lack of time" },
            { percentage: 39, heading: "Lack of budget" },
            { percentage: 39, heading: "Lack of effective tools" },
            { percentage: 35, heading: "Lack of people" },
            {
              percentage: 31,
              heading:
                "Lack of buy-in from management or the rest of the organization",
            },
            { percentage: 31, heading: "Lack of skills" },
            {
              percentage: 31,
              heading: "Interpersonal challenges between team members",
            },
            { percentage: 1, heading: "Other" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.orange100}
        color={colors.orange800}
      >
        <ReportV2SectionHeader icon={<SquareWheelIcon />}>
          <ReportV2SectionHeadingMedium>
            Top five things that frustrate security teams the most
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="orange"
          items={[
            {
              percentage: 53,
              heading: "Spending time on manual work",
              description:
                "No one likes doing tedious work, and a majority of respondents feel, or their team feels, frustrated by repetitive, manual tasks.",
            },
            {
              percentage: 49,
              heading:
                "Too many different consoles/tools to investigate incidents",
              description:
                "Tool consolidation was a trend this year across the tech industry, but its effects haven’t yet taken hold in security. Fragmented toolsets could lead to gaps in an organization’s response.",
            },
            {
              percentage: 47,
              heading: "High cost of security and log management software",
              description: "All those tools don’t come cheap.",
            },
            {
              percentage: 45,
              heading:
                "Lack of unified query language to access all data across all monitored systems",
              description:
                "Security teams are swapping screens, tools, and languages to access all their distributed data.",
            },
            {
              percentage: 44,
              heading: "Poor integration of different security tools",
              description:
                "When tools don’t integrate well, it creates unnecessary friction within teams and between business units.",
            },
            { percentage: 43, heading: "Inaccurate or incomplete attribution" },
            { percentage: 42, heading: "High false positive rates" },
            {
              percentage: 42,
              heading: "Slow or delayed log file ingestion and processing",
            },
            {
              percentage: 41,
              heading:
                "Lack of broad support for different log types and systems",
            },
            {
              percentage: 34,
              heading: "Toxic work environment/personnel issues",
            },
            { percentage: 34, heading: "Lack of space for logs" },
            { percentage: 27, heading: "Our SIEM" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.lightest}
        color={colors.orange800}
      >
        <ReportChapterSummary>
          <p>
            SOC teams identified three clear challenges preventing them from
            doing their best work: too much data, too many tedious tasks, and
            too many reporting requirements. These pain points are amplified by
            a lack of time, budget, tools, and people.
          </p>
          <p>
            Automation offers a path forward for security leaders to remove
            obstacles and refocus their teams on proactive, high-impact work.
            No-code tools can help teams catch up quickly, keeping their
            organizations safe and possibly keeping their teams intact as well.
          </p>
        </ReportChapterSummary>
      </ReportV2Section>
    </section>
  );
};
