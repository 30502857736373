export const ReportV2LargeChapterNumber4Default = () => (
  <svg
    width="370"
    height="400"
    viewBox="0 0 370 400"
    fill="currentColor"
    fillOpacity={0.5}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.503 360.929L170.482 360.909C186.999 360.572 199.414 359.562 207.767 357.894C216.019 355.91 221.838 352.145 225.376 346.678L225.387 346.661L225.398 346.645C229.274 341.154 231.293 332.623 231.293 320.885V274.782H67.1943V265.35L240.904 29.6733H255.578V257.588H317.368V274.782H255.578V320.885C255.578 332.286 257.258 340.863 260.511 346.725C263.756 352.237 268.442 355.957 274.609 357.909C280.914 359.566 289.767 360.573 301.203 360.91L302.174 360.938V370H169.503V360.929ZM274.33 358.87C267.915 356.844 263.019 352.961 259.643 347.221C256.266 341.144 254.578 332.365 254.578 320.885V273.782H316.368V258.588H254.578V30.6733H241.409L68.1943 265.679V273.782H232.293V320.885C232.293 332.702 230.267 341.481 226.215 347.221C222.501 352.961 216.423 356.844 207.982 358.87C199.541 360.559 187.048 361.572 170.503 361.909V369H301.174V361.909C289.694 361.572 280.746 360.559 274.33 358.87ZM232.293 68.6591L92.5052 258.588H232.293V68.6591ZM231.293 71.7048L94.4829 257.588H231.293V71.7048Z"
    />
  </svg>
);
