import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { PropsWithChildren } from "react";

export const TooltipWrap = styled.div`
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: transparent;
  z-index: 1;
`;

const TooltipInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  background-color: ${colors.dark900};
  color: ${colors.white};
  &:hover {
    filter: brightness(1.1);
  }
  ${darkModeLinariaCSS(`
    background-color: ${colors.lightest};
    color: ${colors.dark900};
  `)}
  a {
    color: inherit;
    font-weight: 600;
    text-decoration: none;
    > * + * {
      margin-left: 0.5em;
    }
    svg {
      display: block;
    }
  }
  > * {
    display: flex;
    align-items: center;
    padding: 0.6em 1em;
  }
  border-radius: 2em;
  white-space: nowrap;
  &:before {
    display: block;
    content: "";
    width: 8px;
    height: 4px;
    background-image: ${`url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0H0L4 4L8 0Z' fill='${encodeURIComponent(
      colors.dark900
    )}'/%3E%3C/svg%3E%0A")`};
    ${darkModeLinariaCSS(`
      background-image: ${`url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0H0L4 4L8 0Z' fill='${encodeURIComponent(
        colors.lightest
      )}'/%3E%3C/svg%3E%0A")`};
    `)}
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Tooltip = (props: PropsWithChildren<{}>) => {
  return (
    <TooltipWrap>
      <TooltipInner>{props.children}</TooltipInner>
    </TooltipWrap>
  );
};
