import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import Spacing from "../../layout/Spacing";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import {
  ReportV2FullDarkSectionNavControlPoint,
  ReportV2NavGreenControlPoint,
} from "../components/ReportV2CommonNavControlPoints";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { HoneycombIcon } from "./icons/HoneycombIcon";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";
import {
  ReportV2Blockquote,
  ReportV2BlockquoteBody,
} from "../components/ReportV2Blockquote";
import ReportV2ContentCard from "../components/ReportV2ContentCard";
import { ReportV2TextWithChart } from "../components/ReportV2TextWithChart";

const ChapterSubheading = styled.p`
  ${fromTablet} {
    font-size: 2rem !important;
    font-family: ${serif};
  }
`;

export const CisoAIChapter3 = () => {
  return (
    <section>
      <ReportV2FullDarkSectionNavControlPoint />

      <ReportV2ChapterHeaderSection
        id="opportunities-in-ai-adoption"
        color="green"
        chapterNumber={3}
        icon={<HoneycombIcon />}
      >
        <h2>
          <span>Opportunities in</span> <span>AI adoption</span>
        </h2>
        <ReportV2TextBlock largerText>
          <Spacing size=".125em" />
          <ChapterSubheading>
            Despite the challenges they encounter, CISOs remain optimistic about
            AI's potential to enhance security operations.
          </ChapterSubheading>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2NavGreenControlPoint />

      <ReportV2Section
        color={colors.green800}
        backgroundColor={colors.green100}
      >
        <ReportV2ContentCard largerText>
          <ReportV2TextWithChart>
            <p>
              Despite the concerns and challenges, there is a general sense of
              optimism about AI.{" "}
              <b>
                A significant 74% of respondents believe that the benefits of
                faster decision-making by AI systems outweigh the risks.
              </b>
            </p>
            <Chart1 />
          </ReportV2TextWithChart>
        </ReportV2ContentCard>

        <Spacing size="gap" />

        <ReportV2ContentCard largerText>
          <ReportV2TextWithChart>
            <p>
              <b>
                Just 25% of respondents are worried that incorrect decisions
                made by AI could slow down threat detection or containment of
                benign activities.
              </b>{" "}
              This highlights the necessity for humans to maintain full control
              over AI systems through diligent oversight and continuous
              monitoring to ensure their reliability and accuracy.
            </p>
            <Chart2 />
          </ReportV2TextWithChart>
        </ReportV2ContentCard>

        <Spacing size="gap" />

        <ReportV2ContentCard largerText>
          <ReportV2TextWithChart>
            <p>
              <b>
                While the talent shortage remains a challenge, 51% of CISOs
                surveyed think AI will make it easier for their organization to
                hire entry-level employees.
              </b>{" "}
              AI-enhanced workflows will allow new hires to concentrate on more
              strategic activities, accelerating their professional growth.
            </p>
            <Chart3 />
          </ReportV2TextWithChart>
        </ReportV2ContentCard>
      </ReportV2Section>
    </section>
  );
};

const MaybeUseBlockquoteWhenApproved = () => (
  <ReportV2Blockquote>
    <ReportV2BlockquoteBody>
      <p>
        “It’s so important that the CISO gets a seat at the table, in terms of
        creating an AI Center of Excellence, especially when we're thinking
        about the increased security risks. It's not just about the bad actors
        knocking on our door. It's also securing the LLM (large language model).
        It's also securing the automation behind it. It's also securing the
        people and the holistic aspect of the AI lifecycle.”
      </p>
    </ReportV2BlockquoteBody>
    <p>
      {/* @cspell:disable */}
      <strong>Gina Yacone</strong>,<br />
      {/* @cspell:enable */}
      CISO (Advisory) for the Mountain States Region,
      <br />
      Trace3
    </p>
  </ReportV2Blockquote>
);

const Chart1 = () => (
  <svg
    width="123"
    height="123"
    viewBox="0 0 123 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M123 61.5C123 95.4655 95.4655 123 61.5 123C27.5345 123 0 95.4655 0 61.5C0 27.5345 27.5345 0 61.5 0C95.4655 0 123 27.5345 123 61.5ZM12.3 61.5C12.3 88.6724 34.3276 110.7 61.5 110.7C88.6724 110.7 110.7 88.6724 110.7 61.5C110.7 34.3276 88.6724 12.3 61.5 12.3C34.3276 12.3 12.3 34.3276 12.3 61.5Z"
      fill="#D6EDD9"
    />
    <mask id="path-2-inside-1_1_2366" fill="white">
      <path d="M61.5 5.5C61.5 2.46244 63.9674 -0.0255354 66.9928 0.245745C77.0563 1.1481 86.7729 4.51965 95.2649 10.0978C105.294 16.6856 113.177 26.0632 117.942 37.0754C122.707 48.0876 124.147 60.2533 122.084 72.0736C120.021 83.894 114.545 94.8527 106.332 103.6C98.1177 112.347 87.5244 118.5 75.8569 121.301C64.1894 124.102 51.9573 123.429 40.6676 119.364C29.3779 115.3 19.5239 108.021 12.3194 98.4258C6.21899 90.3009 2.24398 80.8151 0.711512 70.8281C0.250799 67.8257 2.57893 65.207 5.6105 65.0163L6.90793 64.9346C9.93951 64.7439 12.5205 67.0565 13.0482 70.0479C14.3874 77.6387 17.4967 84.8357 22.1555 91.0407C27.9191 98.717 35.8024 104.54 44.8341 107.791C53.8658 111.043 63.6515 111.581 72.9855 109.341C82.3195 107.1 90.7941 102.177 97.3653 95.1797C103.936 88.1822 108.317 79.4152 109.967 69.9589C111.618 60.5026 110.466 50.7701 106.654 41.9603C102.841 33.1506 96.535 25.6485 88.5119 20.3783C82.0267 16.1183 74.6486 13.467 66.9887 12.6071C63.9701 12.2682 61.5 9.83757 61.5 6.8L61.5 5.5Z" />
    </mask>
    <path
      d="M61.5 5.5C61.5 2.46244 63.9674 -0.0255354 66.9928 0.245745C77.0563 1.1481 86.7729 4.51965 95.2649 10.0978C105.294 16.6856 113.177 26.0632 117.942 37.0754C122.707 48.0876 124.147 60.2533 122.084 72.0736C120.021 83.894 114.545 94.8527 106.332 103.6C98.1177 112.347 87.5244 118.5 75.8569 121.301C64.1894 124.102 51.9573 123.429 40.6676 119.364C29.3779 115.3 19.5239 108.021 12.3194 98.4258C6.21899 90.3009 2.24398 80.8151 0.711512 70.8281C0.250799 67.8257 2.57893 65.207 5.6105 65.0163L6.90793 64.9346C9.93951 64.7439 12.5205 67.0565 13.0482 70.0479C14.3874 77.6387 17.4967 84.8357 22.1555 91.0407C27.9191 98.717 35.8024 104.54 44.8341 107.791C53.8658 111.043 63.6515 111.581 72.9855 109.341C82.3195 107.1 90.7941 102.177 97.3653 95.1797C103.936 88.1822 108.317 79.4152 109.967 69.9589C111.618 60.5026 110.466 50.7701 106.654 41.9603C102.841 33.1506 96.535 25.6485 88.5119 20.3783C82.0267 16.1183 74.6486 13.467 66.9887 12.6071C63.9701 12.2682 61.5 9.83757 61.5 6.8L61.5 5.5Z"
      fill="#25A871"
      stroke="#129764"
      strokeWidth="2"
      mask="url(#path-2-inside-1_1_2366)"
    />
    <path
      d="M51.2461 50.0927V50.5385C48.5713 54.6879 42.0214 62.5408 42.0214 73H39.3809C39.3809 63.9468 45.2449 57.2598 48.8456 52.4932H39.4152C37.6663 52.4932 36.7404 52.9733 36.6032 54.8594H36.1574V50.0927H51.2461ZM61.5616 49.6126V65.49H65.6081V66.5873H61.5616V69.8451C61.5616 72.0055 62.5218 72.4513 64.6479 72.5542V73H55.0118V72.5542C57.5151 72.4513 59.1612 72.0055 59.1612 69.8451V66.5873H48.2219V65.7986L60.6357 49.6126H61.5616ZM59.1612 53.4534L49.8336 65.49H59.1612V53.4534ZM71.388 60.1747C68.9189 60.1747 67.1357 57.9114 67.1357 54.8937C67.1357 51.8759 68.9189 49.6126 71.388 49.6126C73.7541 49.6126 75.6059 51.8759 75.6059 54.8937C75.6059 57.9114 73.7541 60.1747 71.388 60.1747ZM71.1479 73H70.1877L82.8073 50.0927H83.7675L71.1479 73ZM71.388 59.1802C72.4167 59.1802 73.4112 57.8085 73.4112 54.8937C73.4112 51.9788 72.4167 50.5728 71.388 50.5728C70.3249 50.5728 69.2619 51.9788 69.2619 54.8937C69.2619 57.8085 70.3249 59.1802 71.388 59.1802ZM82.6359 73.4801C80.2011 73.4801 78.3836 71.2168 78.3836 68.1648C78.3836 65.1813 80.2011 62.9181 82.6359 62.9181C85.002 62.9181 86.8538 65.1813 86.8538 68.1648C86.8538 71.2168 85.002 73.4801 82.6359 73.4801ZM82.6359 72.4856C83.6646 72.4856 84.6934 71.0796 84.6934 68.1648C84.6934 65.2499 83.6646 63.8439 82.6359 63.8439C81.5728 63.8439 80.5097 65.2499 80.5097 68.1648C80.5097 71.0796 81.5728 72.4856 82.6359 72.4856Z"
      fill="#129764"
    />
  </svg>
);

const Chart2 = () => (
  <svg
    width="123"
    height="123"
    viewBox="0 0 123 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M123 61.5C123 95.4655 95.4655 123 61.5 123C27.5345 123 0 95.4655 0 61.5C0 27.5345 27.5345 0 61.5 0C95.4655 0 123 27.5345 123 61.5ZM12.3 61.5C12.3 88.6724 34.3276 110.7 61.5 110.7C88.6724 110.7 110.7 88.6724 110.7 61.5C110.7 34.3276 88.6724 12.3 61.5 12.3C34.3276 12.3 12.3 34.3276 12.3 61.5Z"
      fill="#D6EDD9"
    />
    <mask id="path-2-inside-1_1177_571" fill="white">
      <path d="M61.5 5.50001C61.5 2.46244 63.9673 -0.0255166 66.9927 0.245777C73.1862 0.801153 79.2705 2.29366 85.035 4.68141C92.4966 7.77208 99.2763 12.3021 104.987 18.0129C110.698 23.7237 115.228 30.5034 118.319 37.965C120.706 43.7295 122.199 49.8138 122.754 56.0073C123.026 59.0327 120.538 61.5 117.5 61.5L116.2 61.5C113.162 61.5 110.732 59.03 110.393 56.0114C109.879 51.4347 108.724 46.9436 106.955 42.672C104.482 36.7028 100.858 31.279 96.2897 26.7103C91.721 22.1417 86.2972 18.5177 80.328 16.0451C76.0564 14.2758 71.5653 13.1209 66.9886 12.6071C63.97 12.2682 61.5 9.83757 61.5 6.8L61.5 5.50001Z" />
    </mask>
    <path
      d="M61.5 5.50001C61.5 2.46244 63.9673 -0.0255166 66.9927 0.245777C73.1862 0.801153 79.2705 2.29366 85.035 4.68141C92.4966 7.77208 99.2763 12.3021 104.987 18.0129C110.698 23.7237 115.228 30.5034 118.319 37.965C120.706 43.7295 122.199 49.8138 122.754 56.0073C123.026 59.0327 120.538 61.5 117.5 61.5L116.2 61.5C113.162 61.5 110.732 59.03 110.393 56.0114C109.879 51.4347 108.724 46.9436 106.955 42.672C104.482 36.7028 100.858 31.279 96.2897 26.7103C91.721 22.1417 86.2972 18.5177 80.328 16.0451C76.0564 14.2758 71.5653 13.1209 66.9886 12.6071C63.97 12.2682 61.5 9.83757 61.5 6.8L61.5 5.50001Z"
      fill="#25A871"
      stroke="#129764"
      strokeWidth="2"
      mask="url(#path-2-inside-1_1177_571)"
    />
    <path
      d="M42.3613 49.6126C46.3736 49.6126 48.8083 52.0817 48.8083 56.0253C48.8083 60.9634 43.3558 64.7013 37.046 71.0453H44.9676C47.8481 71.0453 48.3282 70.6681 49.5284 67.2046H49.9742L49.597 73H34.8513V71.7312L39.5837 66.9303C44.0417 62.438 46.1678 59.4888 46.1678 56.6769C46.1678 53.5905 44.3846 51.9102 41.2297 51.9102C38.3149 51.9102 36.7717 53.2819 35.7429 55.0651L35.2971 54.8594C36.223 52.0817 38.5549 49.6126 42.3613 49.6126ZM66.1013 50.0927V52.7675H55.6421L54.4762 60.3804C55.6764 59.2145 57.5282 58.2543 59.6886 58.2543C63.7351 58.2543 67.2672 61.3406 67.2672 65.4557C67.2672 70.0509 64.3524 73.4801 59.3114 73.4801C54.3047 73.4801 52.0414 71.0453 51.527 68.302L54.3733 67.0331C54.2361 69.8794 56.3966 72.5199 59.7572 72.5199C62.9464 72.5199 64.8325 69.7079 64.8325 66.7588C64.8325 63.1238 62.5349 60.4147 58.3169 60.4147C56.7738 60.4147 54.922 61.2377 53.756 61.9922L53.1388 61.7178L54.8877 50.0927H66.1013ZM73.0768 60.1747C70.6078 60.1747 68.8246 57.9114 68.8246 54.8937C68.8246 51.8759 70.6078 49.6126 73.0768 49.6126C75.443 49.6126 77.2948 51.8759 77.2948 54.8937C77.2948 57.9114 75.443 60.1747 73.0768 60.1747ZM72.8368 73H71.8766L84.4962 50.0927H85.4564L72.8368 73ZM73.0768 59.1802C74.1056 59.1802 75.1001 57.8085 75.1001 54.8937C75.1001 51.9788 74.1056 50.5728 73.0768 50.5728C72.0138 50.5728 70.9507 51.9788 70.9507 54.8937C70.9507 57.8085 72.0138 59.1802 73.0768 59.1802ZM84.3247 73.4801C81.8899 73.4801 80.0725 71.2168 80.0725 68.1648C80.0725 65.1813 81.8899 62.9181 84.3247 62.9181C86.6909 62.9181 88.5427 65.1813 88.5427 68.1648C88.5427 71.2168 86.6909 73.4801 84.3247 73.4801ZM84.3247 72.4856C85.3535 72.4856 86.3822 71.0796 86.3822 68.1648C86.3822 65.2499 85.3535 63.8439 84.3247 63.8439C83.2616 63.8439 82.1986 65.2499 82.1986 68.1648C82.1986 71.0796 83.2616 72.4856 84.3247 72.4856Z"
      fill="#129764"
    />
  </svg>
);

const Chart3 = () => (
  <svg
    width="123"
    height="123"
    viewBox="0 0 123 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M123 61.5C123 95.4655 95.4655 123 61.5 123C27.5345 123 0 95.4655 0 61.5C0 27.5345 27.5345 0 61.5 0C95.4655 0 123 27.5345 123 61.5ZM12.3 61.5C12.3 88.6724 34.3276 110.7 61.5 110.7C88.6724 110.7 110.7 88.6724 110.7 61.5C110.7 34.3276 88.6724 12.3 61.5 12.3C34.3276 12.3 12.3 34.3276 12.3 61.5Z"
      fill="#D6EDD9"
    />
    <mask id="path-2-inside-1_1_2366" fill="white">
      <path d="M61.5 5.50001C61.5 2.46244 63.9673 -0.0255173 66.9927 0.245776C73.3469 0.815565 79.5853 2.37168 85.4806 4.86801C93.0673 8.08058 99.9312 12.7848 105.665 18.7014C111.398 24.6179 115.885 31.6262 118.857 39.3101C121.83 46.9941 123.228 55.1969 122.97 63.4318C122.711 71.6666 120.8 79.7655 117.351 87.2476C113.902 94.7297 108.984 101.442 102.89 106.987C96.7965 112.532 89.6508 116.796 81.8773 119.526C75.8369 121.647 69.5132 122.809 63.1357 122.978C60.0992 123.059 57.793 120.421 57.9837 117.389L58.0654 116.092C58.2561 113.06 60.8738 110.79 63.9077 110.641C68.6365 110.409 73.3168 109.496 77.8018 107.921C84.0206 105.737 89.7372 102.326 94.6122 97.8899C99.4872 93.4539 103.421 88.0837 106.181 82.0981C108.94 76.1124 110.469 69.6333 110.676 63.0454C110.883 56.4575 109.764 49.8952 107.386 43.7481C105.008 37.601 101.419 31.9943 96.8318 27.2611C92.245 22.5278 86.7538 18.7645 80.6844 16.1944C76.3071 14.3409 71.6934 13.1353 66.9886 12.6071C63.97 12.2682 61.5 9.83757 61.5 6.8L61.5 5.50001Z" />
    </mask>
    <path
      d="M61.5 5.50001C61.5 2.46244 63.9673 -0.0255173 66.9927 0.245776C73.3469 0.815565 79.5853 2.37168 85.4806 4.86801C93.0673 8.08058 99.9312 12.7848 105.665 18.7014C111.398 24.6179 115.885 31.6262 118.857 39.3101C121.83 46.9941 123.228 55.1969 122.97 63.4318C122.711 71.6666 120.8 79.7655 117.351 87.2476C113.902 94.7297 108.984 101.442 102.89 106.987C96.7965 112.532 89.6508 116.796 81.8773 119.526C75.8369 121.647 69.5132 122.809 63.1357 122.978C60.0992 123.059 57.793 120.421 57.9837 117.389L58.0654 116.092C58.2561 113.06 60.8738 110.79 63.9077 110.641C68.6365 110.409 73.3168 109.496 77.8018 107.921C84.0206 105.737 89.7372 102.326 94.6122 97.8899C99.4872 93.4539 103.421 88.0837 106.181 82.0981C108.94 76.1124 110.469 69.6333 110.676 63.0454C110.883 56.4575 109.764 49.8952 107.386 43.7481C105.008 37.601 101.419 31.9943 96.8318 27.2611C92.245 22.5278 86.7538 18.7645 80.6844 16.1944C76.3071 14.3409 71.6934 13.1353 66.9886 12.6071C63.97 12.2682 61.5 9.83757 61.5 6.8L61.5 5.50001Z"
      fill="#25A871"
      stroke="#129764"
      strokeWidth="2"
      mask="url(#path-2-inside-1_1_2366)"
    />
    <path
      d="M52.1047 50.0927V52.7675H41.6455L40.4796 60.3804C41.6798 59.2145 43.5316 58.2543 45.692 58.2543C49.7385 58.2543 53.2706 61.3406 53.2706 65.4557C53.2706 70.0509 50.3558 73.4801 45.3148 73.4801C40.3081 73.4801 38.0449 71.0453 37.5305 68.302L40.3767 67.0331C40.2396 69.8794 42.4 72.5199 45.7606 72.5199C48.9498 72.5199 50.8359 69.7079 50.8359 66.7588C50.8359 63.1238 48.5383 60.4147 44.3203 60.4147C42.7772 60.4147 40.9254 61.2377 39.7595 61.9922L39.1422 61.7178L40.8911 50.0927H52.1047ZM60.7949 69.4336C60.7949 71.9369 62.1666 72.4513 64.2241 72.5542V73H54.7594V72.5542C56.7827 72.4513 58.3944 71.9369 58.3944 69.4336V52.2189H54.7594V51.7388C56.9541 51.3615 59.046 50.5728 60.3491 49.6126H60.7949V69.4336ZM70.0149 60.1747C67.5459 60.1747 65.7627 57.9114 65.7627 54.8937C65.7627 51.8759 67.5459 49.6126 70.0149 49.6126C72.3811 49.6126 74.2329 51.8759 74.2329 54.8937C74.2329 57.9114 72.3811 60.1747 70.0149 60.1747ZM69.7749 73H68.8147L81.4343 50.0927H82.3945L69.7749 73ZM70.0149 59.1802C71.0437 59.1802 72.0382 57.8085 72.0382 54.8937C72.0382 51.9788 71.0437 50.5728 70.0149 50.5728C68.9519 50.5728 67.8888 51.9788 67.8888 54.8937C67.8888 57.8085 68.9519 59.1802 70.0149 59.1802ZM81.2628 73.4801C78.8281 73.4801 77.0106 71.2168 77.0106 68.1648C77.0106 65.1813 78.8281 62.9181 81.2628 62.9181C83.629 62.9181 85.4808 65.1813 85.4808 68.1648C85.4808 71.2168 83.629 73.4801 81.2628 73.4801ZM81.2628 72.4856C82.2916 72.4856 83.3204 71.0796 83.3204 68.1648C83.3204 65.2499 82.2916 63.8439 81.2628 63.8439C80.1998 63.8439 79.1367 65.2499 79.1367 68.1648C79.1367 71.0796 80.1998 72.4856 81.2628 72.4856Z"
      fill="#129764"
    />
  </svg>
);
