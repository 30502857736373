import { styled } from "@linaria/react";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";

export const ReportV2LargePrint = styled.div`
  font-family: ${serif};
  font-size: 1.8rem;
  letter-spacing: -0.01em;
  line-height: 1.1;
  max-width: 23em;
  ${fromTablet} {
    font-size: 2.4rem;
    letter-spacing: -0.02em;
  }
`;
