import Button from "../components/forms/Button";
import LargeGenericCard, {
  LargeGenericCardGrid,
} from "../components/general/LargeGenericCard";
import LinkToAnchor from "../components/basic/LinkToAnchor";
import TinesInThePressSection from "../components/reusableSections/TinesInThePressSection";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import TinesLogo from "../components/misc/TinesLogo";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { LayoutWithSidebar } from "../components/layout/LayoutWithSidebar";
import StandardSidebar, {
  StandardSidebarLinkList,
} from "../components/layout/StandardSidebar";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";

const PageNewsroom: PageComponent = props => {
  const description =
    "See media coverage, download brand assets, or make a press inquiry.";

  return (
    <Page {...props}>
      <SEO
        title="Newsroom"
        description={description}
        image="/images/og/tines-newsroom.png"
      />
      <AdminToolBarWithEditInDatoCmsButton itemType="mediaMention" />
      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <Icon />
          <h1>Newsroom</h1>
          <StandardSidebarLinkList>
            <LinkToAnchor to="#tines-in-the-press" className="active">
              Tines in the press
            </LinkToAnchor>
            <LinkToAnchor to="#brand-assets">Brand assets</LinkToAnchor>
            <LinkToAnchor to="#press-inquiries">Press inquiries</LinkToAnchor>
          </StandardSidebarLinkList>
        </StandardSidebar>
        <div>
          <TinesInThePressSection />
          <Spacing size="sectionMargin" />

          <LargeGenericCardGrid>
            <LargeGenericCard
              id="brand-assets"
              backgroundColor={colors.dark500}
              textColor={colors.white}
              figureBackgroundColor={colors.purple}
              figureSlot={<TinesLogo height={52} />}
              heading="Tines brand"
              content={
                <>
                  <p>Download official Tines logos and other brand assets.</p>
                </>
              }
              footer={
                <Button
                  appearance="filled-white"
                  href="https://live.standards.site/tines"
                  target="_blank"
                  download
                >
                  Brand site <sup>↗</sup>
                </Button>
              }
            />
            <LargeGenericCard
              id="press-inquiries"
              backgroundColor={colors.white}
              textColor={colors.dark500}
              figureBackgroundColor={colors.purple50}
              borderColor={colors.light400}
              figureSlot={
                <img src="/images/photos/tines-boston-office-wework.jpg" />
              }
              heading="Press inquiries"
              content={
                <>
                  <p>
                    For all press inquiries, please reach out to us at{" "}
                    <a
                      href="mailto:press@tines.com?subject=Press%20Inquiry"
                      title="Contact us via e-mail for any press inquiries"
                    >
                      press@tines.com
                    </a>
                    .
                  </p>
                </>
              }
              footer={<Button to="/contact">Get in touch</Button>}
            />
          </LargeGenericCardGrid>
        </div>
      </LayoutWithSidebar>

      <Spacing size="sectionMargin" />
    </Page>
  );
};

const Icon = () => (
  <svg
    width="114"
    height="111"
    viewBox="0 0 114 111"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M101.301 96.0364C99.1409 96.9044 96.4866 96.8377 95.0651 96.8377C93.6431 96.8377 9.82935 96.8377 9.82935 96.8377L35.6352 76.1992C35.6352 76.1992 106.466 76.1992 108.848 76.1992C111.23 76.1992 112.912 75.9234 113.778 77.5592C114.408 78.7489 113.535 79.9992 113.153 80.6242C112.77 81.249 105.76 91.9355 105.76 91.9355C105.76 91.9355 103.518 95.1457 101.301 96.0364Z"
      fill="#32313B"
    />
    <path
      d="M10.4458 96.298C13.3161 96.298 15.643 93.548 15.643 90.1558C15.643 86.7635 13.3161 84.0135 10.4458 84.0135C7.57542 84.0135 5.24854 86.7635 5.24854 90.1558C5.24854 93.548 7.57542 96.298 10.4458 96.298Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M80.6851 96.298C83.5553 96.298 85.8821 93.548 85.8821 90.1558C85.8821 86.7635 83.5553 84.0135 80.6851 84.0135C77.8148 84.0135 75.488 86.7635 75.488 90.1558C75.488 93.548 77.8148 96.298 80.6851 96.298Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M84.4045 21.5596H27.7196C23.2189 21.5596 18.0711 22.5643 14.3669 27.419H14.3625L3.32571 40.4483C3.15447 40.7003 2.99176 40.9588 2.83824 41.2236L2.80829 41.2715L2.8109 41.2711C1.73974 43.1367 1.125 45.2983 1.125 47.6042V47.7663V53.2458V91.1524H88.7677L97.1451 77.8488V39.9423V34.4627V34.3006C97.1454 27.264 91.441 21.5596 84.4045 21.5596Z"
      fill="#7266CC"
    />
    <path
      d="M1.12549 53.2457V47.7661V47.6041C1.12549 40.5669 6.83033 34.8625 13.867 34.8625H76.0271C83.0642 34.8625 88.7682 40.5669 88.7682 47.6041V47.7661V53.2457V91.1522H1.12549V53.2457Z"
      fill="#B0A6F9"
    />
    <path
      d="M84.4045 21.5596H27.7196C23.2189 21.5596 18.0711 22.5643 14.3669 27.419H14.3625L3.32571 40.4483C3.15447 40.7003 2.99176 40.9588 2.83824 41.2236L2.80829 41.2715L2.8109 41.2711C1.73974 43.1367 1.125 45.2983 1.125 47.6042V47.7663V53.2458V91.1524H88.7677L97.1451 77.8488V39.9423V34.4627V34.3006C97.1454 27.264 91.441 21.5596 84.4045 21.5596Z"
      stroke="#32313B"
    />
    <path
      d="M88.7682 91.1522V53.2457V47.7661V47.6041C88.7682 40.5669 83.0642 34.8625 76.0271 34.8625H13.867C6.83033 34.8625 1.12549 40.5669 1.12549 47.6041V47.7661V53.2457"
      stroke="#32313B"
    />
    <path
      d="M88.7682 91.1522V53.2457V47.7661V47.6041C88.7682 40.5669 83.0642 34.8625 76.0271 34.8625H13.867C6.83033 34.8625 1.12549 40.5669 1.12549 47.6041V47.7661V53.2457"
      stroke="#32313B"
    />
    <path
      d="M23.0503 23.1464C22.3221 23.3546 21.4716 23.9519 21.4716 23.9519L15.9069 30.1873C15.9069 30.1873 15.2944 30.9855 16.3179 31.6225C17.3413 32.2594 18.6115 32.0684 18.6115 32.0684H74.0413C74.0413 32.0684 76.2803 32.1621 77.0987 31.8771C78.5641 31.3675 78.9969 30.5778 78.9969 30.5778L83.2927 25.0283C83.2927 25.0283 84.153 23.9169 82.9275 23.2074C82.0199 22.6819 81.6691 22.7383 79.8957 22.7383C78.1224 22.7383 25.9972 22.7383 25.9972 22.7383C25.9972 22.7383 24.3666 22.7705 23.0503 23.1464Z"
      fill="#32313B"
    />
    <path
      d="M91.3149 70.0764C91.3149 70.0764 92.4567 77.124 95.8032 79.1714L96.9841 77.3994L96.6301 74.1317L91.3149 70.0764Z"
      fill="#32313B"
    />
    <path
      d="M99.9623 60.9028C99.3434 60.9028 94.8034 60.9028 94.1845 60.9028C92.8364 60.9028 91.7434 63.8644 91.7434 67.5172C91.7434 71.1703 92.8364 74.1318 94.1845 74.1318C94.8034 74.1318 99.3434 74.1318 99.9623 74.1318C101.31 74.1318 102.403 71.1703 102.403 67.5172C102.403 63.8644 101.31 60.9028 99.9623 60.9028Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M99.9621 74.1317C101.31 74.1317 102.403 71.1702 102.403 67.5171C102.403 63.8639 101.31 60.9025 99.9621 60.9025C98.614 60.9025 97.5212 63.8639 97.5212 67.5171C97.5212 71.1702 98.614 74.1317 99.9621 74.1317Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M14.7762 93.1482H5.9572L5.32715 91.1014H15.2486L14.7762 93.1482Z"
      fill="#32313B"
    />
    <path
      d="M85.1727 93.1482H76.3537L75.7236 91.1014H85.6453L85.1727 93.1482Z"
      fill="#32313B"
    />
    <path d="M16.8054 27.4761H83.4194" stroke="#7266CC" strokeWidth="1.53085" />
    <path
      d="M79.9506 1.22925H25.7754L24.2441 2.7601V26.2261H78.4193L79.9506 24.6953V1.22925Z"
      fill="#E1EBFA"
      stroke="#32313B"
    />
    <path d="M78.4193 26.226V2.76001H24.2441" stroke="#32313B" />
    <path d="M78.4192 2.7601L79.9505 1.22925" stroke="#32313B" />
    <path
      d="M76.536 5.68054H22.3604L20.4558 7.79946V31.2655H74.631L76.536 29.1463V5.68054Z"
      fill="#E1EBFA"
      stroke="#32313B"
    />
    <path d="M74.631 31.2655V7.79944H20.4558" stroke="#32313B" />
    <path d="M74.6311 7.79946L76.5361 5.68054" stroke="#32313B" />
    <path
      d="M47.4921 11.4763H25.3772V21.225H47.4921V11.4763Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path d="M52.0959 11.4763H71.503" stroke="#32313B" />
    <path d="M52.0959 14.7263H71.503" stroke="#32313B" />
    <path d="M52.0959 17.9755H71.503" stroke="#32313B" />
    <path d="M52.0959 21.225H71.503" stroke="#32313B" />
    <path d="M25.3772 24.4746H71.5029" stroke="#32313B" />
    <path d="M25.3772 27.7244H71.5029" stroke="#32313B" />
    <path
      d="M93.3337 56.9776C93.3337 56.9776 93.3337 46.189 93.3337 42.5438C93.3337 40.402 92.7297 36.951 90.8999 34.2029"
      stroke="#32313B"
      strokeWidth="2.18693"
      strokeLinecap="round"
    />
    <path
      d="M100.259 38.4471C100.259 37.3967 97.6033 36.513 93.9916 36.249L90.103 40.6841V44.534C90.6336 44.5618 91.1812 44.5766 91.7419 44.5766C96.446 44.5766 100.259 43.5562 100.259 42.2974C100.259 41.8699 100.259 38.8751 100.259 38.4471Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M93.9916 36.2494L90.103 40.6845C90.6336 40.7118 91.1812 40.7267 91.7419 40.7267C96.446 40.7267 100.259 39.7063 100.259 38.4473C100.259 37.3971 97.6033 36.5134 93.9916 36.2494Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M97.0974 48.0204C97.0974 48.0204 89.5525 48.2072 89.5525 44.2208L97.2918 43.9794L97.0974 48.0204Z"
      fill="#32313B"
    />
    <path
      d="M33.4447 110.777C41.0572 110.777 47.2283 109.008 47.2283 106.827C47.2283 104.646 41.0572 102.877 33.4447 102.877C25.8323 102.877 19.6611 104.646 19.6611 106.827C19.6611 109.008 25.8323 110.777 33.4447 110.777Z"
      fill="#32313B"
    />
    <path
      d="M41.3696 82.7058H29.3731H17.3767C17.3767 90.7533 22.8445 92.998 22.8445 96.7881C22.8445 100.578 19.2616 102.014 19.2616 106.613C19.2616 108.127 23.7888 109.354 29.3731 109.354C34.9579 109.354 39.4846 108.127 39.4846 106.613C39.4846 102.014 35.9018 100.578 35.9018 96.7881C35.9018 92.9982 41.3696 90.7533 41.3696 82.7058Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M29.3731 86.6887C35.9986 86.6887 41.3696 84.9055 41.3696 82.7059C41.3696 80.5062 35.9986 78.723 29.3731 78.723C22.7477 78.723 17.3767 80.5062 17.3767 82.7059C17.3767 84.9055 22.7477 86.6887 29.3731 86.6887Z"
      fill="#BBD5FA"
      stroke="#32313B"
    />
    <path
      d="M30.9313 85.1473C36.6979 85.1473 41.3726 83.7848 41.3726 82.104C41.3726 80.4232 36.6979 79.0607 30.9313 79.0607C25.1647 79.0607 20.49 80.4232 20.49 82.104C20.49 83.7848 25.1647 85.1473 30.9313 85.1473Z"
      fill="#32313B"
    />
    <path
      d="M29.3732 84.4359C33.2968 84.4359 36.7034 83.7615 38.4186 82.7723C38.8299 81.3996 39.058 79.9105 39.058 78.3532C39.058 71.523 34.7218 64.0852 29.3728 64.0852C24.0237 64.0852 19.6875 71.523 19.6875 78.3532C19.6875 79.9103 19.9152 81.3987 20.3265 82.7715C22.042 83.7613 25.4483 84.4359 29.3732 84.4359Z"
      fill="#E1EBFA"
      stroke="#32313B"
    />
  </svg>
);

export default PageNewsroom;
