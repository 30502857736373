import { styled } from "@linaria/react";

const Wrap = styled.div`
  svg {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 100%;
    transform: translate(75%, 50%);
  }
`;

export const PageHeroProductFrameClouds = () => {
  return (
    <Wrap>
      <BottomClouds />
    </Wrap>
  );
};
const BottomClouds = () => (
  <svg
    width="190"
    height="86"
    viewBox="0 0 190 86"
    fill="none"
    strokeLinejoin="round"
  >
    <path
      d="M22.2115 42.0954C23.1267 42.0954 24.0215 42.1777 24.896 42.2805C28.4143 26.8209 42.1011 15.2878 58.4521 15.2878C59.2249 15.2878 59.9773 15.3495 60.7298 15.4112C68.3358 6.59178 79.5415 1 92.0285 1C109.864 1 125.056 12.348 130.954 28.301C135.367 25.7313 140.471 24.2305 145.942 24.2305C162.537 24.2305 176 37.8399 176 54.6153C176 71.3906 162.537 85 145.942 85H22.2115C10.4974 85 1 75.3994 1 63.558C1 51.7166 10.4974 42.116 22.2115 42.116V42.0954Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M151 45C151 45 153.998 59.0543 174.677 59.0543L176.343 49.9993L151 45.0255V45Z"
      fill="#483C72"
    />
    <path
      d="M161.604 15.0255C168.574 15.0255 174.596 19.0811 177.44 24.9477C183.975 25.4323 189.125 30.8398 189.125 37.4716C189.125 44.1034 183.462 50.0465 176.492 50.0465H161.604C151.892 50.0465 144 42.2158 144 32.5232C144 22.8306 151.867 15 161.604 15V15.0255Z"
      fill="#FFE5CA"
      stroke="#483C72"
    />
  </svg>
);
