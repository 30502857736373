import StoryboardAction from "./StoryboardAction";
import { useStoryboardContext } from "./StoryboardContext";
import StoryboardNote from "./StoryboardNote";
import StoryboardDecoration from "./StoryboardDecoration";

function StoryboardHtmlLayer() {
  const context = useStoryboardContext();
  return (
    <div>
      {context.story.actions.map(a => (
        <StoryboardAction
          action={a}
          key={a.index}
          isSelected={context.selectedActionId === a.index}
        />
      ))}
      {context.story.notes.map(n => (
        <StoryboardNote note={n} key={n.index} />
      ))}
      {context.storyboard.decorations.map(deco => (
        <StoryboardDecoration def={deco} key={deco.name} />
      ))}
    </div>
  );
}

export default StoryboardHtmlLayer;
