import { css } from "linaria";
import {
  DatoCmsCollectionsGridSection,
  DatoCmsStoryGridSection,
} from "../../../../graphql-types";
import { styled } from "@linaria/react";
import {
  getBrandColorThemeC4,
  getStandardColorThemeNameV4,
} from "../../../styles/colorsV4.styles";
import { font } from "../../../styles/fonts.styles";
import PillLegacy from "../../basic/PillLegacy";
import WithCustomInlineFormatting from "../../typography/WithCustomInlineFormatting";
import { CSSProperties, ReactNode } from "react";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  fromTabletLg,
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { isString } from "../../../utils/typeChecks.utils";
import { preventWidows } from "../../../utils/typography.utils";
import SmartLink from "../../basic/SmartLink";

type Props = {
  section: Omit<
    DatoCmsStoryGridSection | DatoCmsCollectionsGridSection,
    "description" | "id" | "children" | "internal" | "meta" | "originalId"
  > & {
    description?: ReactNode;
  };
  imageOverride?: ReactNode;
};

const HeaderTextContent = styled.div`
  ${uptoTabletMd} {
    text-align: center;
    grid-area: textContent;
    padding-bottom: 1em;
  }
`;

const IllustrationWrap = styled.div`
  ${uptoTabletMd} {
    grid-area: image;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromTabletMd} {
    flex: 0 0 auto;
  }
`;

const Header = styled.div`
  &[data-layout="simple"] {
    padding-bottom: 1.75em;
    ${HeaderTextContent} {
      display: flex;
      align-items: center;
    }
    h2 {
      flex: 1 1 100%;
      font-size: 2.4rem;
      font-weight: 400;
      font-family: ${font("serif")};
      text-align: left;
    }
  }
  &[data-layout="complex"] {
    ${uptoTabletMd} {
      display: grid;
      grid-template-areas: "image" "textContent";
      grid-gap: 1.5em;
    }
    ${fromTabletMd} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: ${rSize("lg")};
    }
    + * {
      margin-top: ${rSize("lg")};
    }
    ${HeaderTextContent} {
      > * {
        + * {
          margin-top: 1.8rem;
        }
      }
    }
    h2 {
      max-width: 15em;
      font-size: 2.8rem;
      text-wrap: balance; // supported in Chrome but not in safari
      ${fromTabletLg} {
        font-size: 3.4rem;
      }
    }
    ${IllustrationWrap} {
      flex: 0 0 auto;
    }
  }
`;

const moreLinkCss = css`
  color: var(--ac);
  text-decoration: none;
  font-weight: 600;
  flex: 0 0 auto;
  white-space: nowrap;
  font-size: 1.4rem;
`;

const DescriptionCss = css`
  max-width: 26em;
  font-size: 1.6rem;
  a {
    color: var(--ac);
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const LibraryCollectionPageSectionHeader = ({
  section,
  imageOverride,
}: Props) => {
  const { image } = section;
  const isComplexLayout =
    section.pill || section.description || section.image?.url;
  const { linkUrl } = section;
  const url = linkUrl?.replace(/^https?:\/\/www\.tines\.com\//, "/");
  return (
    <Header
      data-layout={isComplexLayout ? "complex" : "simple"}
      style={
        {
          "--ac": getBrandColorThemeC4(section.color).primary,
        } as CSSProperties
      }
    >
      <HeaderTextContent>
        {section.pill && (
          <PillLegacy
            color={getStandardColorThemeNameV4(section.color)}
            appearance="translucent"
          >
            {section.pill}
          </PillLegacy>
        )}
        <WithCustomInlineFormatting as="h2">
          {preventWidows(`${section.heading}`)}
        </WithCustomInlineFormatting>
        {section.description &&
          (isString(section.description) ? (
            <WithCustomInlineFormatting className={DescriptionCss} as="p">
              {section.description}
            </WithCustomInlineFormatting>
          ) : (
            <p className={DescriptionCss}>{section.description}</p>
          ))}
        {section.linkTextLabel && url && (
          <p>
            <SmartLink src={url} className={moreLinkCss}>
              {section.linkTextLabel} →
            </SmartLink>
          </p>
        )}
      </HeaderTextContent>
      {(imageOverride || image?.url) && (
        <IllustrationWrap>
          {imageOverride ??
            (image?.url && (
              <img
                width={`${image.width}`}
                height={`${image.height}`}
                style={{ aspectRatio: `${image.width}/${image.height}` }}
                src={image.url}
              />
            ))}
        </IllustrationWrap>
      )}
    </Header>
  );
};

export default LibraryCollectionPageSectionHeader;
