export const ControlPanelIcon = () => (
  <svg
    width="105"
    height="113"
    viewBox="0 0 105 113"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M104.457 66.5017C104.457 74.1998 98.2157 80.4405 90.5178 80.4405H20.6642C12.9658 80.4405 6.72507 74.1998 6.72507 66.5017V20.5992C6.72507 12.9009 12.9658 6.66016 20.6642 6.66016H90.5178C98.2157 6.66016 104.457 12.9009 104.457 20.5992V66.5017Z"
      fill="#10352E"
      stroke="#10352E"
    />
    <path
      d="M99.2315 66.5017C99.2315 74.1998 92.9905 80.4405 85.2927 80.4405H15.4387C7.74063 80.4405 1.49994 74.1998 1.49994 66.5017V20.5992C1.49994 12.9009 7.74063 6.66016 15.4387 6.66016H85.2927C92.9905 6.66016 99.2315 12.9009 99.2315 20.5992V66.5017Z"
      fill="#1F5941"
      stroke="#10352E"
    />
    <path
      d="M99.2316 60.4721C99.2316 68.1705 92.9906 74.4112 85.2928 74.4112H15.4389C7.74075 74.4112 1.50006 68.1705 1.50006 60.4721V14.5697C1.50006 6.87156 7.74075 0.630859 15.4389 0.630859H85.2928C92.9906 0.630859 99.2316 6.87156 99.2316 14.5697V60.4721Z"
      fill="#49B889"
      stroke="#10352E"
    />
    <path
      d="M21.7518 54.4822C21.7518 58.0386 18.8687 60.9217 15.3123 60.9217C11.7562 60.9217 8.87338 58.0386 8.87338 54.4822V14.2696C8.87338 10.7132 11.7562 7.83008 15.3123 7.83008C18.8687 7.83008 21.7518 10.7132 21.7518 14.2696V54.4822Z"
      fill="#1F5941"
      stroke="#10352E"
    />
    <path
      d="M43.5471 54.4822C43.5471 58.0386 40.6643 60.9217 37.1079 60.9217C33.5518 60.9217 30.6684 58.0386 30.6684 54.4822V14.2696C30.6684 10.7132 33.5518 7.83008 37.1079 7.83008C40.6643 7.83008 43.5471 10.7132 43.5471 14.2696V54.4822Z"
      fill="#1F5941"
      stroke="#10352E"
    />
    <path
      d="M21.7518 56.8887C21.7518 60.4451 18.8687 63.3282 15.3123 63.3282C11.7562 63.3282 8.87338 60.4451 8.87338 56.8887V16.6758C8.87338 13.1194 11.7562 10.2363 15.3123 10.2363C18.8687 10.2363 21.7518 13.1194 21.7518 16.6758V56.8887Z"
      fill="#B8E5D2"
      stroke="#10352E"
    />
    <path
      d="M43.5471 56.8887C43.5471 60.4451 40.6643 63.3282 37.1079 63.3282C33.5518 63.3282 30.6684 60.4451 30.6684 56.8887V16.6758C30.6684 13.1194 33.5518 10.2363 37.1079 10.2363C40.6643 10.2363 43.5471 13.1194 43.5471 16.6758V56.8887Z"
      fill="#B8E5D2"
      stroke="#10352E"
    />
    <path
      d="M17.0396 54.2382C17.0396 55.1925 16.2659 55.9661 15.312 55.9661C14.3577 55.9661 13.5838 55.1925 13.5838 54.2382V19.3294C13.5838 18.3752 14.3577 17.6016 15.312 17.6016C16.2659 17.6016 17.0396 18.3752 17.0396 19.3294V54.2382Z"
      fill="#10352E"
      stroke="#10352E"
    />
    <path
      d="M38.8356 54.2382C38.8356 55.1925 38.062 55.9661 37.108 55.9661C36.1538 55.9661 35.3799 55.1925 35.3799 54.2382V19.3294C35.3799 18.3752 36.1538 17.6016 37.108 17.6016C38.062 17.6016 38.8356 18.3752 38.8356 19.3294V54.2382Z"
      fill="#10352E"
      stroke="#10352E"
    />
    <path
      d="M65.3522 54.4822C65.3522 58.0386 62.469 60.9217 58.9127 60.9217C55.3565 60.9217 52.4734 58.0386 52.4734 54.4822V14.2696C52.4734 10.7132 55.3565 7.83008 58.9127 7.83008C62.469 7.83008 65.3522 10.7132 65.3522 14.2696V54.4822Z"
      fill="#1F5941"
      stroke="#10352E"
    />
    <path
      d="M65.3522 56.8887C65.3522 60.4451 62.469 63.3282 58.9127 63.3282C55.3565 63.3282 52.4734 60.4451 52.4734 56.8887V16.6758C52.4734 13.1194 55.3565 10.2363 58.9127 10.2363C62.469 10.2363 65.3522 13.1194 65.3522 16.6758V56.8887Z"
      fill="#B8E5D2"
      stroke="#10352E"
    />
    <path
      d="M60.6408 54.2382C60.6408 55.1925 59.8674 55.9661 58.9132 55.9661C57.9587 55.9661 57.185 55.1925 57.185 54.2382V19.3294C57.185 18.3752 57.9587 17.6016 58.9132 17.6016C59.8674 17.6016 60.6408 18.3752 60.6408 19.3294V54.2382Z"
      fill="#10352E"
      stroke="#10352E"
    />
    <path
      d="M40.6096 30.3984H33.6066V44.6979H40.6096V30.3984Z"
      fill="#6359B3"
      stroke="#10352E"
    />
    <path
      d="M40.6096 26.4512H33.6066V41.9833H40.6096V26.4512Z"
      fill="#8173E6"
      stroke="#10352E"
    />
    <path
      d="M62.4418 37.5488H55.3847V51.8483H62.4418V37.5488Z"
      fill="#B35980"
      stroke="#10352E"
    />
    <path
      d="M62.4418 33.6016H55.3847V49.1337H62.4418V33.6016Z"
      fill="#EB81AF"
      stroke="#10352E"
    />
    <path
      d="M18.8624 20.416H11.7611V34.7155H18.8624V20.416Z"
      fill="#B36847"
      stroke="#10352E"
    />
    <path
      d="M18.8624 16.4707H11.7611V32.0028H18.8624V16.4707Z"
      fill="#FABE64"
      stroke="#10352E"
    />
    <path
      d="M84.2355 43.529C87.9407 43.529 90.9443 40.5254 90.9443 36.8202C90.9443 33.115 87.9407 30.1113 84.2355 30.1113C80.5303 30.1113 77.5266 33.115 77.5266 36.8202C77.5266 40.5254 80.5303 43.529 84.2355 43.529Z"
      fill="#666573"
      stroke="#10352E"
    />
    <path
      d="M79.0106 28.7385C80.5148 27.7636 82.3094 27.1973 84.2355 27.1973C87.2741 27.1973 89.9841 28.6056 91.747 30.8055"
      stroke="#81D8B4"
      strokeLinecap="round"
    />
    <path
      d="M84.2355 40.0611C86.9771 40.0611 89.1996 37.8386 89.1996 35.097C89.1996 32.3553 86.9771 30.1328 84.2355 30.1328C81.4938 30.1328 79.2713 32.3553 79.2713 35.097C79.2713 37.8386 81.4938 40.0611 84.2355 40.0611Z"
      fill="#135B43"
      stroke="#10352E"
    />
    <path
      d="M83.4092 30.6621L84.0985 33.6028"
      stroke="#B0E0BD"
      strokeLinecap="round"
    />
    <path
      d="M84.2355 40.0611C86.9771 40.0611 89.1996 37.8386 89.1996 35.097C89.1996 32.3553 86.9771 30.1328 84.2355 30.1328C81.4938 30.1328 79.2713 32.3553 79.2713 35.097C79.2713 37.8386 81.4938 40.0611 84.2355 40.0611Z"
      stroke="#10352E"
    />
    <path
      d="M84.2354 63.1501C87.9406 63.1501 90.9443 60.1465 90.9443 56.4413C90.9443 52.7361 87.9406 49.7324 84.2354 49.7324C80.5302 49.7324 77.5266 52.7361 77.5266 56.4413C77.5266 60.1465 80.5302 63.1501 84.2354 63.1501Z"
      fill="#666573"
      stroke="#10352E"
    />
    <path
      d="M79.0105 48.3596C80.5148 47.3847 82.3094 46.8184 84.2355 46.8184C87.2741 46.8184 89.984 48.2267 91.7469 50.4266"
      stroke="#81D8B4"
      strokeLinecap="round"
    />
    <path
      d="M84.2355 59.6822C86.9771 59.6822 89.1996 57.4597 89.1996 54.7181C89.1996 51.9764 86.9771 49.7539 84.2355 49.7539C81.4938 49.7539 79.2713 51.9764 79.2713 54.7181C79.2713 57.4597 81.4938 59.6822 84.2355 59.6822Z"
      fill="#135B43"
      stroke="#10352E"
    />
    <path
      d="M88.7373 54.4043L85.7367 54.7528"
      stroke="#B0E0BD"
      strokeLinecap="round"
    />
    <path
      d="M84.2355 59.6822C86.9771 59.6822 89.1996 57.4597 89.1996 54.7181C89.1996 51.9764 86.9771 49.7539 84.2355 49.7539C81.4938 49.7539 79.2713 51.9764 79.2713 54.7181C79.2713 57.4597 81.4938 59.6822 84.2355 59.6822Z"
      stroke="#10352E"
    />
    <path
      d="M84.2355 24.4099C87.9407 24.4099 90.9443 21.4062 90.9443 17.701C90.9443 13.9958 87.9407 10.9922 84.2355 10.9922C80.5303 10.9922 77.5266 13.9958 77.5266 17.701C77.5266 21.4062 80.5303 24.4099 84.2355 24.4099Z"
      fill="#666573"
      stroke="#10352E"
    />
    <path
      d="M84.2352 21.4336C86.9767 21.4336 89.1991 19.2112 89.1991 16.4697C89.1991 13.7283 86.9767 11.5059 84.2352 11.5059C81.4937 11.5059 79.2713 13.7283 79.2713 16.4697C79.2713 19.2112 81.4937 21.4336 84.2352 21.4336Z"
      fill="#135B43"
      stroke="#10352E"
    />
    <path
      d="M87.4408 13.7715L85.0757 15.774"
      stroke="#B0E0BD"
      strokeLinecap="round"
    />
    <path
      d="M84.2355 21.4322C86.9771 21.4322 89.1996 19.2097 89.1996 16.4681C89.1996 13.7264 86.9771 11.5039 84.2355 11.5039C81.4938 11.5039 79.2713 13.7264 79.2713 16.4681C79.2713 19.2097 81.4938 21.4322 84.2355 21.4322Z"
      stroke="#10352E"
    />
    <path
      d="M78.7612 9.80764C80.3151 8.73071 82.2018 8.09961 84.2351 8.09961C87.2737 8.09961 89.9837 9.50794 91.7466 11.7078"
      stroke="#81D8B4"
      strokeLinecap="round"
    />
    <path
      d="M67.9772 111.53C77.1896 111.53 84.6577 104.926 84.6577 96.7798C84.6577 88.6333 77.1896 82.0293 67.9772 82.0293C58.7649 82.0293 51.2968 88.6333 51.2968 96.7798C51.2968 104.926 58.7649 111.53 67.9772 111.53Z"
      fill="#10352E"
      stroke="#10352E"
    />
    <path
      d="M65.8077 110.232C74.6947 110.232 81.899 103.939 81.899 96.1765C81.899 88.4139 74.6947 82.1211 65.8077 82.1211C56.9207 82.1211 49.7164 88.4139 49.7164 96.1765C49.7164 103.939 56.9207 110.232 65.8077 110.232Z"
      fill="#1F5941"
      stroke="#10352E"
    />
    <path
      d="M65.8075 104.982C71.9939 104.982 77.009 100.602 77.009 95.1982C77.009 89.7946 71.9939 85.4141 65.8075 85.4141C59.621 85.4141 54.6059 89.7946 54.6059 95.1982C54.6059 100.602 59.621 104.982 65.8075 104.982Z"
      fill="#49B889"
      stroke="#10352E"
    />
    <path
      d="M65.8076 97.7448C68.3201 97.7448 70.3568 95.862 70.3568 93.5394C70.3568 91.2168 68.3201 89.334 65.8076 89.334C63.2951 89.334 61.2584 91.2168 61.2584 93.5394C61.2584 95.862 63.2951 97.7448 65.8076 97.7448Z"
      fill="#10352E"
      stroke="#10352E"
    />
    <path
      d="M68.7023 93.4699C68.7023 95.0687 67.4062 96.3648 65.8074 96.3648C64.2085 96.3648 62.9124 95.0687 62.9124 93.4699V79.4086C62.9124 77.8098 64.2085 76.5137 65.8074 76.5137C67.4062 76.5137 68.7023 77.8098 68.7023 79.4086V93.4699Z"
      fill="#F5D0E0"
      stroke="#10352E"
    />
    <path
      d="M65.808 86.7185C73.1932 86.7185 79.18 80.7317 79.18 73.3466C79.18 65.9614 73.1932 59.9746 65.808 59.9746C58.4229 59.9746 52.4361 65.9614 52.4361 73.3466C52.4361 80.7317 58.4229 86.7185 65.808 86.7185Z"
      fill="#FF6565"
      stroke="#10352E"
    />
    <path
      d="M78.9674 96.9844C78.9674 96.9844 79.5493 99.6 81.8697 101.047V98.7257C81.8697 98.7257 81.5861 97.9963 80.1284 97.5648C79.8265 97.2058 78.9674 96.9844 78.9674 96.9844Z"
      fill="#10352E"
    />
    <ellipse
      cx="85.0338"
      cy="78.4519"
      rx="2.64033"
      ry="2.69022"
      fill="#10352E"
    />
    <path
      d="M86.5132 92.7265V78.6411C86.5132 77.8717 85.8895 77.248 85.1202 77.248C84.3508 77.248 83.7271 77.8717 83.7271 78.6411V92.7343C83.7271 94.1191 82.6044 95.2418 81.2196 95.2418H80.999C80.1976 95.2418 79.5479 95.8915 79.5479 96.6929C79.5479 97.4943 80.1976 98.144 80.999 98.144H81.0958C84.0877 98.144 86.5132 95.7185 86.5132 92.7265Z"
      fill="#F67269"
      stroke="#10352E"
    />
  </svg>
);
