import { styled } from "@linaria/react";
import React, { CSSProperties, useEffect, useRef } from "react";
import { useSiteContext } from "../../../context/site.context";
import { colorV4, withOpacity } from "../../../styles/colorsV4.styles";
import { runAfter } from "../../../utils/promises.utils";
import { PageTree } from "../../../utils/tree.utils";
import { ChevronIconDown } from "../../icons/misc/ChevronIconDown";
import LinkToAnchor from "../../basic/LinkToAnchor";
import {
  fromDesktop,
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { scrollIntoViewIfNotVisible } from "../../../utils/scroll.utils";
import NavLink from "../../basic/NavLink";
import {
  LearningPathWithProgress,
  TreePageWithCompletionTime,
  getKnownLearningPathConfig,
  useUniTreeWithProgress,
} from "../../../utils/university.utils";
import { css } from "linaria";
import UniCompletionCheckIcon from "./UniCompletionCheckIcon";
import { font } from "../../../styles/fonts.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { Link } from "gatsby";

type Props = {
  tree: PageTree;
  learningPathSlug?: string;
};

const UniLessonPageSidebarWrap = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  ${onlyPhones} {
    position: relative;
  }
  ${fromTablet} {
    font-size: 1.4rem;
    padding-top: 0.5em;
    padding-right: 1em;
  }
  ${fromDesktop} {
    font-size: 1.6rem;
  }
  nav {
    margin: 0 0 0.375em 0;
  }
`;

const TreeList = styled.div`
  details {
    padding-left: 0;
    summary {
      padding: 0;
      list-style-type: none;
      margin-bottom: 2px;
      &::-webkit-details-marker {
        display: none;
      }
    }
    .ChevronIconDown {
      transform: rotate(0);
      flex: 0 0 auto;
      margin-left: 0.5em;
      transition: transform 0.1s;
    }
    > div {
      padding-left: 0.75em;
    }
    &[open] > summary {
      .ChevronIconDown {
        transform: rotate(180deg);
      }
    }
  }
  a {
    text-decoration: none;
    padding: 0.375em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;
    min-height: 2.8rem;
    border-radius: 0.75em;
    font-size: 1.4rem;
    ${fromTablet} {
      min-height: 2.4rem;
      border-radius: 0.5em;
    }
    .ChevronIconDown {
      opacity: 0.375;
    }
    &:hover {
      background-color: var(--ac10);
    }
    &.active {
      color: var(--ac);
    }
    > * {
      pointer-events: none;
    }
  }
  code {
    font-size: 90%;
    @supports (-webkit-line-clamp: 1) {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

const UniLessonPageSidebar = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const siteContext = useSiteContext();
  const uniTreeWithProgress = useUniTreeWithProgress(props.tree);
  const pathTree = uniTreeWithProgress.find(
    learningPath => learningPath.tree.path.slug === props.learningPathSlug
  );
  useEffect(() => {
    runAfter(() => {
      siteContext.toggleSidebar(false);
      const activeLinks = Array.from(
        ref.current?.querySelectorAll<HTMLAnchorElement>(`a.active`) ?? []
      );
      activeLinks.forEach((activeLink, i) => {
        let el = activeLink?.parentElement;
        while (el && el.nodeName !== "NAV") {
          if (el.nodeName === "DETAILS") {
            (el as HTMLDetailsElement).open = true;
          }
          el = el.parentElement;
        }
        if (i === activeLinks.length - 1)
          runAfter(() => {
            scrollIntoViewIfNotVisible(
              activeLink,
              document.querySelector(".SiteSidebarAside") as HTMLElement
            );
          });
      });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteContext.location.pathname]);
  const knownConfig = getKnownLearningPathConfig(pathTree?.tree.path.id);
  const colorName = knownConfig?.color ?? "purple";
  return (
    <UniLessonPageSidebarWrap
      ref={ref}
      style={
        {
          "--ac": colorV4(colorName),
          "--ac10": withOpacity(colorV4(colorName), 0.1),
          "--ac15": withOpacity(colorV4(colorName), 0.15),
          "--ac25": withOpacity(colorV4(colorName), 0.25),
          "--ac50": withOpacity(colorV4(colorName), 0.5),
          "--acLighter": colorV4(colorName, 300),
          "--acDarker": colorV4(colorName, 700),
        } as CSSProperties
      }
    >
      <nav>
        {pathTree && <UniLearningPathSidebarHeader path={pathTree} />}
        <TreeList>
          {pathTree?.tree.path.treeChildren.map(page => (
            <UniLessonPageSidebarItem
              key={page.id}
              page={page as TreePageWithCompletionTime}
            />
          ))}
        </TreeList>
      </nav>
    </UniLessonPageSidebarWrap>
  );
};

const SidebarItemInner = styled.span`
  display: flex;
  align-items: center;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;

const UniLessonPageSidebarItem = (props: {
  page: TreePageWithCompletionTime;
}) => {
  const { page } = props;
  const parentLinkAttributes = {
    className: page.hasNoContent ? "hasNoContent" : "",
    to: page.path,
    title: page.shortTitle || page.title,
    partiallyActive: true,
    children: (
      <>
        <SidebarItemInner>
          <UniCompletionCheckIcon
            completed={
              page.treeChildren.length === 0
                ? page.completionTime
                  ? 1
                  : 0
                : page.treeChildren.filter(
                    t => (t as TreePageWithCompletionTime).completionTime
                  ).length / page.treeChildren.length
            }
          />
          {page.id.includes("_formulas-function-") ? (
            <code>{page.title}</code>
          ) : (
            page.title
          )}
        </SidebarItemInner>
        {page.treeChildren.length > 0 && <ChevronIconDown />}
      </>
    ),
    onClick: (e?: React.MouseEvent) => {
      if (page.hasNoContent) e?.preventDefault();
      const anchor = e?.target as HTMLAnchorElement;
      const summary = anchor.parentNode as HTMLDivElement;
      if (summary.nodeName !== "SUMMARY") return;
      const details = summary?.parentNode as HTMLDetailsElement;
      const open = details.open;
      runAfter(() => {
        if (details.open === open) details.open = !details.open;
      });
    },
  };
  const parentLink =
    page.path.includes("#") && !page.hasNoContent ? (
      <LinkToAnchor {...parentLinkAttributes} />
    ) : (
      <NavLink {...parentLinkAttributes} exact={!page.treeChildren.length} />
    );
  return page.treeChildren.length > 0 ? (
    <details>
      <summary>{parentLink}</summary>
      <div>
        {page.treeChildren.map(child => (
          <UniLessonPageSidebarItem
            key={child.id}
            page={child as TreePageWithCompletionTime}
          />
        ))}
      </div>
    </details>
  ) : (
    parentLink
  );
};

const uniLearningPathSidebarHeaderLinkStyle = css`
  display: block;
  text-decoration: none;
  padding: 1em;
  border-radius: 0.5em;
  background-color: var(--ac15);
  margin-bottom: 1em;
  margin-left: 1em;
  min-height: 6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--acDarker);
  ${darkModeLinariaCSS(`color: var(--acLighter)`)}
  &:hover {
    background-color: var(--ac25);
  }
  p {
    line-height: 1.25;
  }
  svg {
    width: 64px;
    margin-top: -1.25em;
    margin-bottom: -1em;
  }
`;

const TinesUniTag = styled.p`
  font-size: 1.2rem;
`;

const LearningPathTitle = styled.p`
  font-size: 2rem;
  font-family: ${font("serif")};
  font-weight: 400;
`;

const UniLearningPathSidebarHeader = (props: {
  path: LearningPathWithProgress;
}) => {
  const learningPath = props.path.tree.path;
  const config = getKnownLearningPathConfig(props.path.tree.path.id);
  return (
    <Link
      className={uniLearningPathSidebarHeaderLinkStyle}
      to={learningPath.path}
    >
      <div>
        <TinesUniTag>Tines University</TinesUniTag>
        <LearningPathTitle>{learningPath.title}</LearningPathTitle>
      </div>
      {config?.icon && <config.icon />}
    </Link>
  );
};

export default UniLessonPageSidebar;
