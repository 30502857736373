import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import img from "./decorations/BuildAppsIllustration.svg";
import Spacing from "../layout/Spacing";
import { blueprintColors } from "./buildAppsPageConstants";
import Button from "../forms/Button";
import { fromDesktopMl } from "../../styles/breakpointsAndMediaQueries.styles";
import { widthInGrid } from "../../constants/globalGrid.constants";

const Spacer = styled.div`
  height: ${rSize("sectionMargin", 0.5)};
`;

const CultureOfCyberSecuritySectionInner = styled.div`
  background-color: ${blueprintColors.canvas.bg};
  border: 1px solid ${blueprintColors.canvas.fg};
  padding: 0 ${rSize("cardSectionPadding")} ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  text-align: center;
  h2 {
    span {
      display: block;
    }
  }
  p {
    max-width: 35em;
    margin-left: auto;
    margin-right: auto;
    ${fromDesktopMl} {
      max-width: ${widthInGrid(6)};
    }
  }
`;

const Figure = styled.div`
  display: inline-block;
  img {
    max-width: 80%;
  }
`;

const CultureOfCyberSecuritySection = () => {
  return (
    <PageSection>
      <Spacer />
      <CultureOfCyberSecuritySectionInner>
        <Spacing size="cardSectionPadding" />
        <Figure>
          <img src={img} />
        </Figure>
        <Spacing size="md" />
        <SectionHeading2>
          <span>Cultivate a culture </span>
          <Serif>of cybersecurity</Serif>
        </SectionHeading2>
        <Spacing size="md" />
        <p>
          Apps make it easy for end-users to be vigilant while reinforcing
          security-conscious decisions-making. Offer visibility into the
          security process by sharing outcomes and necessary next steps with the
          end-user.
        </p>
        <Spacing size="xl" />
        <Button to="/product">Meet the product</Button>
        <Spacing size="sm" />
      </CultureOfCyberSecuritySectionInner>
    </PageSection>
  );
};

export default CultureOfCyberSecuritySection;
