import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ProductDemoVideoFrame } from "../general/ProductDemoVideoFrame";
import {
  ProductPageH3,
  ProductPageSubSection,
  ProductPageSubSectionP,
} from "./_ProductPageSharedComponents";
import {
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

const SplitOneThree = styled.div`
  ${uptoTablet} {
    text-align: center;
    header {
      + * {
        margin-top: 2em;
      }
      * {
        ${uptoTablet} {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    p {
      max-width: 17em;
    }
  }
  ${fromTablet} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${rSize("gap")};
    > * {
      &:first-child {
        grid-column-end: span 3;
        padding-right: 1em;
      }
      &:last-child {
        grid-column-end: span 9;
      }
    }
  }
`;

export const DemoVideoSubsection = () => (
  <ProductPageSubSection id="watch-a-demo">
    <SplitOneThree>
      <header>
        <ProductPageH3>Watch a demo</ProductPageH3>
        <ProductPageSubSectionP>
          Take a quick product tour with this one-minute video.
        </ProductPageSubSectionP>
      </header>
      <ProductDemoVideoFrame version="productPage" />
    </SplitOneThree>
  </ProductPageSubSection>
);
