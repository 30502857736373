export type FontType = keyof typeof fonts;

export const fonts = {
  system:
    '-apple-system,BlinkMacSystemFont,Arial,Roboto,Ubuntu,"Helvetica Neue",sans-serif',
  sans: 'Roobert,-apple-system,BlinkMacSystemFont,Arial,Roboto,Ubuntu,"Helvetica Neue",sans-serif',
  serif: "Reckless,Georgia,serif",
  serifDisplay: "'Reckless Neue VF','Reckless Neue',Georgia,serif",
  monospace: "'JetBrains Mono',menlo,monospace",
};

export const font = (name: FontType) => fonts[name];

export const serif = font("serif");

export const fontSerifDisplayRules = `
  font-family: ${font("serifDisplay")};
  font-feature-settings: "ss02", "ss08";
`;

export const resetFontDisplayRulesForSerif = `
  font-family: ${font("serif")};
  font-feature-settings: normal;
`;
