import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { inDarkMode } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import TinesLogo from "../misc/TinesLogo";
import DarkMode from "./DarkMode";

type Props = React.PropsWithChildren<{
  darkMode?: boolean;
}>;

const Container = styled.div<{ alwaysUseDarkMode?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background-color: ${p =>
    p.alwaysUseDarkMode ? colorsV4.warmBlack900 : colorsV4.canvas};
  color: ${p => (p.alwaysUseDarkMode ? colorsV4.white : colorsV4.warmBlack)};
  padding: 1em 1em 3em 1em;
  text-align: center;
  ${inDarkMode} {
    background-color: ${colorsV4.warmBlack900};
    color: ${colorsV4.white};
  }
  p {
    opacity: 0.75;
    font-weight: 500;
    font-size: 1.4rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
`;

const Footer = styled.footer`
  color: ${withOpacity(colorsV4.grey, 0.3)};
`;

const FullscreenMessage = (props: Props) => {
  return (
    <Container alwaysUseDarkMode={props.darkMode}>
      {props.darkMode && <DarkMode darker />}
      <Content>{props.children}</Content>
      <Footer>
        <Link to="/">
          <TinesLogo height={24} />
        </Link>
      </Footer>
    </Container>
  );
};

export default FullscreenMessage;
