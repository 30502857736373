import { styled } from "@linaria/react";

const Wrap = styled.div`
  svg {
    &:first-child {
      position: absolute;
      right: 100%;
      top: 40%;
      transform: translate(50%, -50%);
    }
    &:last-child {
      position: absolute;
      left: 100%;
      top: 40%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const PageHeroHeadlinePalace = () => (
  <Wrap>
    <Left />
    <Right />
  </Wrap>
);

const Left = () => (
  <svg width="242" height="301" viewBox="0 0 242 301" fill="none">
    <path
      d="M180.601 299.573C214.111 299.573 241.276 289.557 241.276 277.202C241.276 264.847 214.111 254.831 180.601 254.831C147.091 254.831 119.926 264.847 119.926 277.202C119.926 289.557 147.091 299.573 180.601 299.573Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M225.624 263.839C225.624 252.827 200.486 243.901 169.487 243.901C138.489 243.901 113.351 252.827 113.351 263.839V278.562C113.351 289.574 138.489 298.5 169.487 298.5C200.486 298.5 225.624 289.574 225.624 278.562V263.839Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M169.487 283.777C200.491 283.777 225.624 274.85 225.624 263.839C225.624 252.828 200.491 243.901 169.487 243.901C138.484 243.901 113.351 252.828 113.351 263.839C113.351 274.85 138.484 283.777 169.487 283.777Z"
      fill="#A88FEF"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M169.487 276.139C193.809 276.139 213.526 270.279 213.526 263.052C213.526 255.824 193.809 249.964 169.487 249.964C145.165 249.964 125.448 255.824 125.448 263.052C125.448 270.279 145.165 276.139 169.487 276.139Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M128.784 268.051C135.391 272.806 151.128 276.139 169.487 276.139C187.845 276.139 203.583 272.795 210.19 268.051C203.583 263.307 187.845 259.964 169.487 259.964C151.128 259.964 135.391 263.307 128.784 268.051Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M77.2734 272.223C77.2734 273.93 81.6816 275.311 87.1296 275.311C92.5776 275.311 96.9858 273.93 96.9858 272.223C96.9858 270.515 92.5776 269.135 87.1296 269.135C81.6816 269.135 77.2734 270.515 77.2734 272.223Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M95.6861 273.746C93.9964 272.816 90.8013 272.182 87.1296 272.182C83.4579 272.182 80.2628 272.816 78.5623 273.746C77.7392 273.296 77.2734 272.775 77.2734 272.223C77.2734 270.515 81.6816 269.135 87.1296 269.135C92.5776 269.135 96.9858 270.515 96.9858 272.223C96.9858 272.775 96.5092 273.296 95.6969 273.746H95.6861Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M86.9244 275.3C90.1845 275.3 92.8272 274.666 92.8272 271.589C92.8272 255.966 106.279 243.267 122.829 243.267C139.379 243.267 152.831 255.966 152.831 271.589C152.831 274.666 155.473 275.3 158.734 275.3C161.994 275.3 164.637 274.666 164.637 271.589C164.637 249.821 145.877 232.122 122.829 232.122C99.7807 232.122 81.0215 249.821 81.0215 271.589C81.0215 274.666 83.6642 275.3 86.9244 275.3Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M32.9521 271.804C32.9521 273.522 37.3712 274.902 42.83 274.902C48.2888 274.902 52.7078 273.522 52.7078 271.804C52.7078 270.087 48.2888 268.717 42.83 268.717C37.3712 268.717 32.9521 270.097 32.9521 271.804Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M51.4189 273.328C49.7185 272.397 46.5233 271.763 42.83 271.763C39.1366 271.763 35.9415 272.397 34.241 273.328C33.4179 272.878 32.9521 272.357 32.9521 271.804C32.9521 270.097 37.3712 268.717 42.83 268.717C48.2888 268.717 52.7078 270.097 52.7078 271.804C52.7078 272.357 52.2421 272.878 51.4189 273.328Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M42.9158 274.646C46.1868 274.646 48.8404 274.646 48.8404 271.569C48.8404 249.75 30.027 232 6.92454 232C3.65358 232 1 234.505 1 237.583C1 240.66 3.65358 243.175 6.92454 243.175C23.5067 243.175 37.0021 255.905 37.0021 271.569C37.0021 274.646 39.6449 274.646 42.9158 274.646Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M164.776 29.2564C170.699 29.2564 175.501 28.1552 175.501 26.7968C175.501 25.4384 170.699 24.3372 164.776 24.3372C158.853 24.3372 154.051 25.4384 154.051 26.7968C154.051 28.1552 158.853 29.2564 164.776 29.2564Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M159.985 28.9708C167.709 28.9708 173.971 22.7093 173.971 14.9854C173.971 7.26148 167.709 1 159.985 1C152.261 1 146 7.26148 146 14.9854C146 22.7093 152.261 28.9708 159.985 28.9708Z"
      fill="#FFE0CC"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M89.6854 151.68C107.448 151.68 121.847 148.502 121.847 144.583C121.847 140.663 107.448 137.486 89.6854 137.486C71.9229 137.486 57.5234 140.663 57.5234 144.583C57.5234 148.502 71.9229 151.68 89.6854 151.68Z"
      fill="#483C72"
    />
    <path
      d="M112.237 139.138C112.237 135.414 99.4242 132.401 83.6185 132.401C67.8128 132.401 55 135.414 55 139.138V144.773C55 148.497 67.8128 151.51 83.6185 151.51C99.4242 151.51 112.237 148.497 112.237 144.773V139.138Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M83.6185 145.874C99.4241 145.874 112.237 142.858 112.237 139.138C112.237 135.417 99.4241 132.401 83.6185 132.401C67.813 132.401 55 135.417 55 139.138C55 142.858 67.813 145.874 83.6185 145.874Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M112.237 125.674C112.237 121.95 99.4242 118.937 83.6185 118.937C67.8128 118.937 55 121.95 55 125.674V131.309C55 135.033 67.8128 138.046 83.6185 138.046C99.4242 138.046 112.237 135.033 112.237 131.309V125.674Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M83.6185 132.41C99.4241 132.41 112.237 129.394 112.237 125.674C112.237 121.953 99.4241 118.937 83.6185 118.937C67.813 118.937 55 121.953 55 125.674C55 129.394 67.813 132.41 83.6185 132.41Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M112.237 112.2C112.237 108.477 99.4242 105.464 83.6185 105.464C67.8128 105.464 55 108.477 55 112.2V117.836C55 121.56 67.8128 124.573 83.6185 124.573C99.4242 124.573 112.237 121.56 112.237 117.836V112.2Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M83.6185 118.937C99.4241 118.937 112.237 115.921 112.237 112.2C112.237 108.48 99.4241 105.464 83.6185 105.464C67.813 105.464 55 108.48 55 112.2C55 115.921 67.813 118.937 83.6185 118.937Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M112.237 98.7367C112.237 95.013 99.4242 92 83.6185 92C67.8128 92 55 95.013 55 98.7367V104.372C55 108.096 67.8128 111.109 83.6185 111.109C99.4242 111.109 112.237 108.096 112.237 104.372V98.7367Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M83.6185 105.473C99.4241 105.473 112.237 102.457 112.237 98.7367C112.237 95.0161 99.4241 92 83.6185 92C67.813 92 55 95.0161 55 98.7367C55 102.457 67.813 105.473 83.6185 105.473Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
  </svg>
);

export const Right = () => (
  <svg width="196" height="260" viewBox="0 0 196 260" fill="none">
    <path
      d="M82.3014 141.932C87.7593 141.932 92.1838 140.459 92.1838 138.642C92.1838 136.824 87.7593 135.351 82.3014 135.351C76.8435 135.351 72.4189 136.824 72.4189 138.642C72.4189 140.459 76.8435 141.932 82.3014 141.932Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M90.89 140.259C89.1946 139.266 85.9822 138.597 82.3014 138.597C78.6206 138.597 75.4082 139.266 73.7128 140.259C72.8985 139.779 72.4189 139.222 72.4189 138.642C72.4189 136.824 76.8471 135.363 82.3014 135.363C87.7557 135.363 92.1838 136.824 92.1838 138.642C92.1838 139.233 91.7154 139.779 90.89 140.259Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M1 138.642C1 140.46 5.42814 141.932 10.8936 141.932C16.3591 141.932 20.7761 140.46 20.7761 138.642C20.7761 136.824 16.3479 135.362 10.8936 135.362C5.42814 135.362 1 136.824 1 138.642Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M19.4822 140.259C17.7868 139.266 14.5744 138.597 10.8936 138.597C7.21278 138.597 4.00043 139.266 2.29387 140.259C1.47963 139.779 1 139.222 1 138.642C1 136.824 5.42814 135.362 10.8936 135.362C16.3591 135.362 20.7761 136.824 20.7761 138.642C20.7761 139.233 20.3076 139.779 19.4822 140.259Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M10.6816 141.91C13.9497 141.91 16.6044 141.229 16.6044 137.961C16.6044 121.353 30.1118 107.857 46.709 107.857C63.3062 107.857 76.8249 121.364 76.8249 137.961C76.8249 141.229 79.4795 141.91 82.7476 141.91C86.0158 141.91 88.6816 141.229 88.6816 137.961C88.6816 114.817 69.8536 96 46.7202 96C23.5868 96 4.75879 114.817 4.75879 137.961C4.75879 141.229 7.41344 141.91 10.6816 141.91Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M162.61 139.166C162.61 141.007 158.127 142.49 152.605 142.49C147.084 142.49 142.611 141.007 142.611 139.166C142.611 137.326 147.084 135.854 152.605 135.854C158.127 135.854 162.61 137.337 162.61 139.166Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M143.917 140.806C145.634 139.802 148.88 139.122 152.606 139.122C156.331 139.122 159.577 139.802 161.295 140.806C162.12 140.326 162.6 139.769 162.6 139.166C162.6 137.326 158.116 135.854 152.595 135.854C147.073 135.854 142.601 137.337 142.601 139.166C142.601 139.769 143.08 140.326 143.906 140.806H143.917Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M152.527 142.223C149.214 142.223 146.526 142.223 146.526 138.932C146.526 115.531 165.566 96.4911 188.967 96.4911C192.28 96.4911 194.957 99.1681 194.957 102.481C194.957 105.794 192.269 108.47 188.967 108.47C172.181 108.47 158.517 122.123 158.517 138.921C158.517 142.223 155.829 142.211 152.527 142.211V142.223Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M89.7215 258.739C100.991 258.739 110.128 256.646 110.128 254.063C110.128 251.481 100.991 249.387 89.7215 249.387C78.4515 249.387 69.3154 251.481 69.3154 254.063C69.3154 256.646 78.4515 258.739 89.7215 258.739Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M80.598 258.196C95.2877 258.196 107.196 246.288 107.196 231.598C107.196 216.908 95.2877 205 80.598 205C65.9083 205 54 216.908 54 231.598C54 246.288 65.9083 258.196 80.598 258.196Z"
      fill="#EBEDCB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M80 20.5992C96.5685 20.5992 110 17.2413 110 13.0992C110 8.95704 96.5685 5.59918 80 5.59918C63.4315 5.59918 50 8.95704 50 13.0992C50 17.2413 63.4315 20.5992 80 20.5992Z"
      fill="#483C72"
    />
    <path
      d="M104.237 7.73676C104.237 4.01303 91.4242 1 75.6185 1C59.8128 1 47 4.01303 47 7.73676V13.3724C47 17.0961 59.8128 20.1092 75.6185 20.1092C91.4242 20.1092 104.237 17.0961 104.237 13.3724V7.73676Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M75.6185 14.4735C91.4241 14.4735 104.237 11.4574 104.237 7.73676C104.237 4.01616 91.4241 1 75.6185 1C59.813 1 47 4.01616 47 7.73676C47 11.4574 59.813 14.4735 75.6185 14.4735Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
  </svg>
);
