import { styled } from "@linaria/react";

const ExternalLinkIconSvg = styled.svg`
  display: inline-block;
  margin-left: 0.2em;
  transform: translate(0, -0.4em);
  transition: transform 0.1s;
  @media (hover: hover) {
    a:hover & {
      transform: translate(0.1em, -0.5em);
    }
  }
`;
export const ExternalLinkIcon = () => (
  <ExternalLinkIconSvg width="8" height="8" fill="currentColor">
    <path d="M3.00008 0.25H2.25008V1.75H3.00008H5.18941L0.969745 5.96967L0.439415 6.5L1.50008 7.56066L2.03041 7.03033L6.25008 2.81066V5V5.75H7.75008V5V1C7.75008 0.585786 7.41429 0.25 7.00008 0.25H3.00008Z" />
  </ExternalLinkIconSvg>
);
