import { styled } from "@linaria/react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { EventDirectoryItem } from "../../types/helper.types";
import Button from "../forms/Button";
import EventDirectoryItemMap from "./EventDirectoryItemMap";

type Props = {
  item: EventDirectoryItem;
};
const Container = styled.div``;
const Header = styled.header`
  padding-bottom: 1em;
  ${fromTablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;
const AddressWrapper = styled.div`
  ${onlyPhones} {
    padding-bottom: 1em;
  }
`;
const EventLocationAddress = styled.address`
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  > * {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
`;

const EventDirectoryItemAddressAndMap = (props: Props) => {
  const { place } = props.item;
  if (!place) return null;
  const { address } = place;
  if (!address) return null;
  const cityAndState = address
    ? [address.city, address.state].filter(i => i).join(", ")
    : "";
  const hasAddress = !!(
    address &&
    (address.street || cityAndState || address.country)
  );
  const googleMapsLink =
    place.url ??
    (place.coordinates
      ? `https://maps.google.com/?q=${place.coordinates.lat},${place.coordinates.lng}`
      : "");
  return (
    <Container>
      <Header>
        {hasAddress && (
          <AddressWrapper>
            <p>
              <strong>Address</strong>
            </p>
            <EventLocationAddress>
              {address.locationName && <p>{address.locationName}</p>}
              {address.street && <p>{address.street}</p>}
              {cityAndState && <p>{cityAndState}</p>}
              {address.country && <p>{address.country}</p>}
            </EventLocationAddress>
          </AddressWrapper>
        )}
        {googleMapsLink && (
          <Button
            href={googleMapsLink}
            appearance="outlined"
            {...externalLinkAttr}
          >
            Google Maps ↗
          </Button>
        )}
      </Header>
      <EventDirectoryItemMap item={props.item} />
    </Container>
  );
};

export default EventDirectoryItemAddressAndMap;
