import UpcomingEvents from "../components/events/UpcomingEvents";
import SEO from "../components/utilities/SEO";
import Page from "./Page.template";
import { PageComponent } from "../types/gatsby.types";
import { EventDirectoryItem, GeolocationCoords } from "../types/helper.types";
import { useState } from "react";

const PageUpcomingEvents: PageComponent<{
  pageContext: {
    items: EventDirectoryItem[];
  };
}> = props => {
  const [items, setItems] = useState(props.pageContext.items);
  const calculateDistances = (userCoords: GeolocationCoords) => {
    const itemsWithDistances = items.map(i => ({
      ...i,
      distanceFromUser: i.place?.coordinates
        ? google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(
              i.place.coordinates.lat,
              i.place.coordinates.lng
            ),
            new google.maps.LatLng(userCoords.lat, userCoords.lng)
          )
        : null,
    }));
    setItems(itemsWithDistances);
  };
  return (
    <Page {...props}>
      <SEO
        title="Upcoming events"
        description="Stay up to date with and register for events Tines is attending across the world."
        image="/images/og/tines-events.png"
      />
      <UpcomingEvents
        items={items}
        onRetrievingUserGeolocation={calculateDistances}
      />
    </Page>
  );
};

export default PageUpcomingEvents;
