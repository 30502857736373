import { styled } from "@linaria/react";
import { uptoDesktop } from "../../styles/breakpointsAndMediaQueries.styles";

const Container = styled.div`
  svg {
    max-width: 100%;
    height: auto;
    display: block;
    ${uptoDesktop} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const G2AndGartnerSetCompact = () => {
  return (
    <Container>
      <svg width="419" height="36" viewBox="0 0 419 36" fill="none">
        <path
          d="M280 13.2856L279.731 16.3423H278.43C276.356 16.3423 275.28 17.9375 275.28 19.4741V26.3809H271.619V13.2856H275.098L275.176 14.9225C275.905 13.8034 277.18 13.2856 278.759 13.2856H280Z"
          fill="#002856"
        />
        <path
          d="M255.373 26.3809H251.821V19.6255C251.821 18.2076 251.687 16.4311 249.647 16.4311C247.674 16.4311 246.885 17.8164 246.885 19.4217V26.3727H243.333V13.6035H246.633L246.708 15.2332C247.414 14.1413 248.732 13.2856 250.671 13.2856C254.256 13.2856 255.381 15.7711 255.381 18.3461L255.373 26.3809Z"
          fill="#002856"
        />
        <path
          d="M241.762 23.5612L241.52 26.5889C240.732 26.8094 239.919 26.9196 239.098 26.9027C235.886 26.9027 234.952 25.3507 234.952 22.2297V9.61865H238.365V13.3672H241.754L241.52 16.4712H238.365V21.9498C238.365 23.205 238.687 23.875 239.911 23.875C240.538 23.8835 241.166 23.7732 241.762 23.5612Z"
          fill="#002856"
        />
        <path
          d="M233.381 13.2856L233.12 16.3423H232.211C230.331 16.3423 229.39 17.9375 229.39 19.4741V26.3809H226.048V13.2856H229.209L229.272 14.9225C229.943 13.8118 231.105 13.2856 232.543 13.2856H233.381Z"
          fill="#002856"
        />
        <path
          d="M209.81 24.9878C207.445 26.2607 205.081 26.9881 201.959 26.8971C196.176 26.7236 192 23.1115 192 17.0279C192 10.9692 196.218 7.09257 201.967 7.00164C204.883 6.96031 207.487 7.69596 209.529 9.50614L207.066 11.9941C205.806 10.9113 203.953 10.2831 201.729 10.3823C198.112 10.5476 195.929 13.1926 195.88 16.813C195.83 20.7392 198.236 23.4503 201.992 23.5495C203.763 23.5909 205.024 23.3594 206.119 22.8635V18.8546H202.215L202.478 15.5566H209.801L209.81 24.9878Z"
          fill="#002856"
        />
        <path
          d="M270.048 21.3378H260.468C260.74 22.9946 262.044 23.997 263.661 24.0467C265.237 24.0881 266.112 23.6159 267.267 22.4396L269.544 24.1709C267.927 26.1757 265.98 26.9047 263.76 26.9047C259.865 26.9047 256.952 24.1709 256.952 20.0952C256.952 16.0194 259.857 13.2856 263.62 13.2856C267.77 13.2856 270.039 15.8123 270.039 20.2857L270.048 21.3378ZM266.549 18.6123C266.442 16.9721 265.22 15.9366 263.529 16.0028C261.846 16.0691 260.699 17.1792 260.468 18.6123H266.549Z"
          fill="#002856"
        />
        <path
          d="M223.429 18.0828V26.5899H220.302L220.227 25.306C219.274 26.4822 217.719 26.9047 216.113 26.9047C213.706 26.9047 211.432 25.7284 211.382 22.9866C211.315 18.6792 216.281 18.3728 219.399 18.3728H220.026V18.1988C220.026 16.7741 219.073 16.0037 217.284 16.0037C215.98 16.012 214.734 16.5007 213.781 17.387L211.741 15.3327C213.38 13.9825 215.453 13.2536 217.576 13.2867C221.64 13.2867 223.429 15.1422 223.429 18.0828ZM220.026 20.9241H219.533C217.861 20.9241 214.918 21.0069 214.926 22.7464C214.943 25.1983 220.026 24.4114 220.026 21.4625V20.9241Z"
          fill="#002856"
        />
        <path
          d="M277.177 25.0626C277.2 24.2372 277.788 23.5943 278.488 23.6203C279.196 23.6464 279.747 24.3328 279.724 25.1495C279.702 25.9575 279.136 26.5917 278.451 26.5917C277.743 26.5917 277.177 25.9227 277.177 25.106C277.177 25.0973 277.177 25.08 277.177 25.0626ZM278.451 26.9045C279.315 26.8871 280.015 26.0617 280 25.0452C279.985 24.0374 279.277 23.2207 278.406 23.2381C277.55 23.2554 276.857 24.0634 276.857 25.0626C276.85 26.0704 277.55 26.8958 278.414 26.9045C278.428 26.9045 278.443 26.9045 278.451 26.9045ZM278.116 25.2103H278.436L278.913 26.1225H279.218L278.711 25.1929C278.98 25.1581 279.173 24.9844 279.173 24.6108C279.173 24.1938 278.965 24.0113 278.533 24.0113H277.848V26.1225H278.123L278.116 25.2103ZM278.116 24.9409V24.2893H278.488C278.682 24.2893 278.883 24.3328 278.883 24.6021C278.883 24.9236 278.674 24.9496 278.443 24.9496L278.116 24.9409Z"
          fill="#002856"
        />
        <path
          d="M133.46 24.3145V22.2425H128.448V20.6325L133.292 14.5145H135.308V20.6325H136.568V22.2425H135.308V24.3145H133.46ZM130.282 20.6325H133.46V16.7265L130.282 20.6325ZM137.812 22.4945H139.674V24.3145H137.812V22.4945ZM144.986 24.4265C142.928 24.4265 141.332 23.2785 141.332 21.4445C141.332 20.2965 142.046 19.4425 142.998 19.0365C142.284 18.6165 141.752 17.9025 141.752 17.0345C141.752 15.4945 143.082 14.4025 144.986 14.4025C146.89 14.4025 148.22 15.4945 148.22 17.0345C148.22 17.9025 147.688 18.6165 146.96 19.0365C147.954 19.4425 148.626 20.2965 148.626 21.4445C148.626 23.2785 147.044 24.4265 144.986 24.4265ZM144.986 18.3085C145.896 18.3085 146.498 17.8465 146.498 17.1045C146.498 16.3625 145.896 15.9005 144.986 15.9005C144.062 15.9005 143.488 16.3625 143.488 17.1045C143.488 17.8465 144.062 18.3085 144.986 18.3085ZM144.986 22.8305C146.106 22.8305 146.806 22.2985 146.806 21.4025C146.806 20.5205 146.106 19.9605 144.986 19.9605C143.88 19.9605 143.138 20.5205 143.138 21.4025C143.138 22.2985 143.88 22.8305 144.986 22.8305Z"
          fill="#4D3E78"
        />
        <path
          d="M392.46 24.3145V22.2425H387.448V20.6325L392.292 14.5145H394.308V20.6325H395.568V22.2425H394.308V24.3145H392.46ZM389.282 20.6325H392.46V16.7265L389.282 20.6325ZM396.812 22.4945H398.674V24.3145H396.812V22.4945ZM403.986 24.4265C401.928 24.4265 400.332 23.2785 400.332 21.4445C400.332 20.2965 401.046 19.4425 401.998 19.0365C401.284 18.6165 400.752 17.9025 400.752 17.0345C400.752 15.4945 402.082 14.4025 403.986 14.4025C405.89 14.4025 407.22 15.4945 407.22 17.0345C407.22 17.9025 406.688 18.6165 405.96 19.0365C406.954 19.4425 407.626 20.2965 407.626 21.4445C407.626 23.2785 406.044 24.4265 403.986 24.4265ZM403.986 18.3085C404.896 18.3085 405.498 17.8465 405.498 17.1045C405.498 16.3625 404.896 15.9005 403.986 15.9005C403.062 15.9005 402.488 16.3625 402.488 17.1045C402.488 17.8465 403.062 18.3085 403.986 18.3085ZM403.986 22.8305C405.106 22.8305 405.806 22.2985 405.806 21.4025C405.806 20.5205 405.106 19.9605 403.986 19.9605C402.88 19.9605 402.138 20.5205 402.138 21.4025C402.138 22.2985 402.88 22.8305 403.986 22.8305Z"
          fill="#4D3E78"
        />
        <g clipPath="url(#clip0_2719_11979)">
          <path
            d="M18.0596 23.2596C19.0288 24.8956 19.9872 26.513 20.9449 28.1287C16.7041 31.2868 10.1061 31.6684 5.22168 28.0316C-0.39917 23.8431 -1.32301 16.6963 1.68631 11.5517C5.14746 5.63438 11.6244 4.32572 15.7947 5.28531C15.6819 5.52363 13.1842 10.5638 13.1842 10.5638C13.1842 10.5638 12.9867 10.5764 12.8751 10.5785C11.6424 10.6293 10.7244 10.9083 9.74034 11.4031C8.66075 11.9511 7.73557 12.7479 7.04566 13.724C6.35576 14.7 5.92213 15.8256 5.78269 17.0023C5.63719 18.1956 5.80683 19.4053 6.27559 20.5171C6.67194 21.4571 7.23258 22.2919 7.98419 22.9967C9.13718 24.079 10.5092 24.7491 12.0997 24.9709C13.6058 25.1812 15.0542 24.973 16.4115 24.3096C16.9206 24.0611 17.3537 23.7867 17.86 23.4103C17.9245 23.3696 17.9817 23.3181 18.0596 23.2596Z"
            fill="#E14F4C"
          />
          <path
            d="M18.0713 8.91187C17.8252 8.67635 17.5971 8.45906 17.3701 8.24036C17.2347 8.10999 17.1042 7.97436 16.9655 7.84714C16.9158 7.80122 16.8574 7.73849 16.8574 7.73849C16.8574 7.73849 16.9046 7.64106 16.9248 7.60111C17.1903 7.08276 17.6065 6.7039 18.1001 6.40249C18.646 6.06667 19.2819 5.8958 19.9276 5.91148C20.7538 5.92725 21.522 6.12737 22.1702 6.6664C22.6487 7.06418 22.8941 7.56886 22.9373 8.17132C23.0094 9.18769 22.577 9.96609 21.7184 10.5093C21.2139 10.8289 20.6699 11.076 20.1244 11.3687C19.8235 11.5302 19.5662 11.6722 19.2722 11.9645C19.0135 12.2578 19.0009 12.5343 19.0009 12.5343L22.9092 12.5294V14.2226H16.8765C16.8765 14.2226 16.8765 14.1069 16.8765 14.0589C16.8535 13.2269 16.9533 12.4439 17.3449 11.6883C17.7052 10.9951 18.2652 10.4876 18.9379 10.0968C19.456 9.79576 20.0015 9.53956 20.5207 9.23991C20.841 9.05521 21.0673 8.7843 21.0655 8.39142C21.0655 8.05426 20.8133 7.75461 20.453 7.66104C19.6033 7.43814 18.7386 7.79386 18.2889 8.55018C18.2234 8.66058 18.1563 8.77028 18.0713 8.91187Z"
            fill="#E14F4C"
          />
          <path
            d="M25.6271 21.4598L22.3335 15.9272H15.8158L12.501 21.5169H19.0666L22.3065 27.0232L25.6271 21.4598Z"
            fill="#E14F4C"
          />
        </g>
        <path
          d="M47.8344 13.9711C48.1443 13.3432 49.0397 13.3432 49.3496 13.9711L50.4232 16.1463C50.5463 16.3957 50.7841 16.5685 51.0593 16.6085L53.4598 16.9573C54.1528 17.058 54.4295 17.9096 53.928 18.3983L52.191 20.0915C51.9919 20.2856 51.901 20.5652 51.948 20.8393L52.3581 23.2301C52.4764 23.9202 51.7521 24.4466 51.1323 24.1207L48.9852 22.9919C48.739 22.8626 48.445 22.8626 48.1989 22.9919L46.0518 24.1207C45.432 24.4466 44.7076 23.9203 44.826 23.2301L45.2361 20.8393C45.2831 20.5652 45.1922 20.2856 44.9931 20.0915L43.2561 18.3983C42.7546 17.9096 43.0313 17.058 43.7243 16.9573L46.1248 16.6085C46.4 16.5685 46.6378 16.3957 46.7609 16.1463L47.8344 13.9711Z"
          fill="#4D3E78"
        />
        <path
          d="M63.0384 13.9709C63.3483 13.343 64.2437 13.343 64.5536 13.9709L65.6272 16.1462C65.7502 16.3955 65.9881 16.5684 66.2632 16.6083L68.6638 16.9572C69.3567 17.0578 69.6334 17.9094 69.132 18.3982L67.3949 20.0914C67.1958 20.2854 67.105 20.5651 67.152 20.8392L67.562 23.23C67.6804 23.9201 66.956 24.4464 66.3362 24.1205L64.1891 22.9918C63.943 22.8624 63.649 22.8624 63.4029 22.9918L61.2558 24.1205C60.636 24.4464 59.9116 23.9202 60.03 23.23L60.44 20.8392C60.487 20.5651 60.3962 20.2854 60.197 20.0914L58.46 18.3982C57.9586 17.9094 58.2353 17.0578 58.9282 16.9572L61.3288 16.6083C61.6039 16.5684 61.8418 16.3955 61.9649 16.1462L63.0384 13.9709Z"
          fill="#4D3E78"
        />
        <path
          d="M78.2415 13.971C78.5514 13.3431 79.4468 13.3431 79.7567 13.971L80.8303 16.1462C80.9534 16.3956 81.1912 16.5684 81.4663 16.6084L83.8669 16.9572C84.5599 17.0579 84.8366 17.9095 84.3351 18.3982L82.5981 20.0914C82.3989 20.2855 82.3081 20.5652 82.3551 20.8392L82.7651 23.23C82.8835 23.9202 82.1592 24.4465 81.5394 24.1206L79.3923 22.9918C79.1461 22.8625 78.8521 22.8625 78.606 22.9918L76.4589 24.1206C75.8391 24.4465 75.1147 23.9202 75.2331 23.23L75.6431 20.8392C75.6901 20.5652 75.5993 20.2855 75.4002 20.0914L73.6631 18.3982C73.1617 17.9095 73.4384 17.0579 74.1314 16.9572L76.5319 16.6084C76.807 16.5684 77.0449 16.3956 77.168 16.1462L78.2415 13.971Z"
          fill="#4D3E78"
        />
        <path
          d="M93.4453 13.971C93.7552 13.3431 94.6506 13.3431 94.9605 13.971L96.034 16.1462C96.1571 16.3956 96.3949 16.5684 96.6701 16.6084L99.0707 16.9572C99.7636 17.0579 100.04 17.9095 99.5388 18.3982L97.8018 20.0914C97.6027 20.2855 97.5118 20.5652 97.5589 20.8392L97.9689 23.23C98.0873 23.9202 97.3629 24.4465 96.7431 24.1206L94.596 22.9918C94.3499 22.8625 94.0559 22.8625 93.8097 22.9918L91.6626 24.1206C91.0429 24.4465 90.3185 23.9202 90.4368 23.23L90.8469 20.8392C90.8939 20.5652 90.803 20.2855 90.6039 20.0914L88.8669 18.3982C88.3655 17.9095 88.6422 17.0579 89.3351 16.9572L91.7356 16.6084C92.0108 16.5684 92.2487 16.3956 92.3717 16.1462L93.4453 13.971Z"
          fill="#4D3E78"
        />
        <path
          d="M110.789 16.3676L110.789 16.3677C110.985 16.7645 111.364 17.0397 111.802 17.1033L114.203 17.4521C114.485 17.4932 114.598 17.8408 114.394 18.0403L114.394 18.0403L112.657 19.7335C112.34 20.0425 112.195 20.4876 112.27 20.9239L112.68 23.3147L112.68 23.3147C112.728 23.5964 112.433 23.8112 112.18 23.6782L112.18 23.6782L110.032 22.5494L110.032 22.5494C109.641 22.3435 109.173 22.3435 108.781 22.5494L108.781 22.5494L106.634 23.6782L106.634 23.6782C106.381 23.8112 106.085 23.5964 106.133 23.3147L106.543 20.9239L106.543 20.9239C106.618 20.4876 106.474 20.0425 106.157 19.7335C106.157 19.7335 106.157 19.7335 106.157 19.7335L104.42 18.0403C104.215 17.8408 104.328 17.4932 104.611 17.4521L107.011 17.1033C107.449 17.0397 107.828 16.7646 108.024 16.3676L109.097 14.1924C109.224 13.9361 109.589 13.9361 109.716 14.1924L110.789 16.3676Z"
          stroke="#4D3E78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104 18.3212V17.2645C104.125 17.1067 104.309 16.9908 104.538 16.9574L106.939 16.6086C107.214 16.5686 107.452 16.3958 107.575 16.1465L108.649 13.9712C108.959 13.3433 109.854 13.3433 110.164 13.9712L111.237 16.1465C111.36 16.3958 111.598 16.5686 111.873 16.6086L112 16.627V19.8601L112.5 22.8601L112.165 24.2008C112.091 24.1849 112.018 24.1586 111.947 24.1208L109.799 22.9921C109.553 22.8627 109.259 22.8627 109.013 22.9921L106.866 24.1208C106.246 24.4467 105.522 23.9204 105.64 23.2302L106.05 20.8394C106.097 20.5654 106.006 20.2857 105.807 20.0916L104.07 18.3985C104.045 18.3736 104.021 18.3478 104 18.3212Z"
          fill="#4D3E78"
        />
        <path
          d="M306.834 13.9711C307.144 13.3432 308.04 13.3432 308.35 13.9711L309.423 16.1463C309.546 16.3957 309.784 16.5685 310.059 16.6085L312.46 16.9573C313.153 17.058 313.429 17.9096 312.928 18.3983L311.191 20.0915C310.992 20.2856 310.901 20.5652 310.948 20.8393L311.358 23.2301C311.476 23.9202 310.752 24.4466 310.132 24.1207L307.985 22.9919C307.739 22.8626 307.445 22.8626 307.199 22.9919L305.052 24.1207C304.432 24.4466 303.708 23.9203 303.826 23.2301L304.236 20.8393C304.283 20.5652 304.192 20.2856 303.993 20.0915L302.256 18.3983C301.755 17.9096 302.031 17.058 302.724 16.9573L305.125 16.6085C305.4 16.5685 305.638 16.3957 305.761 16.1463L306.834 13.9711Z"
          fill="#4D3E78"
        />
        <path
          d="M322.038 13.9709C322.348 13.343 323.244 13.343 323.554 13.9709L324.627 16.1462C324.75 16.3955 324.988 16.5684 325.263 16.6083L327.664 16.9572C328.357 17.0578 328.633 17.9094 328.132 18.3982L326.395 20.0914C326.196 20.2854 326.105 20.5651 326.152 20.8392L326.562 23.23C326.68 23.9201 325.956 24.4464 325.336 24.1205L323.189 22.9918C322.943 22.8624 322.649 22.8624 322.403 22.9918L320.256 24.1205C319.636 24.4464 318.912 23.9202 319.03 23.23L319.44 20.8392C319.487 20.5651 319.396 20.2854 319.197 20.0914L317.46 18.3982C316.959 17.9094 317.235 17.0578 317.928 16.9572L320.329 16.6083C320.604 16.5684 320.842 16.3955 320.965 16.1462L322.038 13.9709Z"
          fill="#4D3E78"
        />
        <path
          d="M337.242 13.971C337.551 13.3431 338.447 13.3431 338.757 13.971L339.83 16.1462C339.953 16.3956 340.191 16.5684 340.466 16.6084L342.867 16.9572C343.56 17.0579 343.837 17.9095 343.335 18.3982L341.598 20.0914C341.399 20.2855 341.308 20.5652 341.355 20.8392L341.765 23.23C341.884 23.9202 341.159 24.4465 340.539 24.1206L338.392 22.9918C338.146 22.8625 337.852 22.8625 337.606 22.9918L335.459 24.1206C334.839 24.4465 334.115 23.9202 334.233 23.23L334.643 20.8392C334.69 20.5652 334.599 20.2855 334.4 20.0914L332.663 18.3982C332.162 17.9095 332.438 17.0579 333.131 16.9572L335.532 16.6084C335.807 16.5684 336.045 16.3956 336.168 16.1462L337.242 13.971Z"
          fill="#4D3E78"
        />
        <path
          d="M352.445 13.971C352.755 13.3431 353.651 13.3431 353.96 13.971L355.034 16.1462C355.157 16.3956 355.395 16.5684 355.67 16.6084L358.071 16.9572C358.764 17.0579 359.04 17.9095 358.539 18.3982L356.802 20.0914C356.603 20.2855 356.512 20.5652 356.559 20.8392L356.969 23.23C357.087 23.9202 356.363 24.4465 355.743 24.1206L353.596 22.9918C353.35 22.8625 353.056 22.8625 352.81 22.9918L350.663 24.1206C350.043 24.4465 349.318 23.9202 349.437 23.23L349.847 20.8392C349.894 20.5652 349.803 20.2855 349.604 20.0914L347.867 18.3982C347.365 17.9095 347.642 17.0579 348.335 16.9572L350.736 16.6084C351.011 16.5684 351.249 16.3956 351.372 16.1462L352.445 13.971Z"
          fill="#4D3E78"
        />
        <path
          d="M369.789 16.3676L369.789 16.3677C369.985 16.7645 370.364 17.0397 370.802 17.1033L373.203 17.4521C373.485 17.4932 373.598 17.8408 373.394 18.0403L373.394 18.0403L371.657 19.7335C371.34 20.0425 371.195 20.4876 371.27 20.9239L371.68 23.3147L371.68 23.3147C371.728 23.5964 371.433 23.8112 371.18 23.6782L371.18 23.6782L369.032 22.5494L369.032 22.5494C368.641 22.3435 368.173 22.3435 367.781 22.5494L367.781 22.5494L365.634 23.6782L365.634 23.6782C365.381 23.8112 365.085 23.5964 365.133 23.3147L365.543 20.9239L365.543 20.9239C365.618 20.4876 365.474 20.0425 365.157 19.7335C365.157 19.7335 365.157 19.7335 365.157 19.7335L363.42 18.0403C363.215 17.8408 363.328 17.4932 363.611 17.4521L366.011 17.1033C366.449 17.0397 366.828 16.7646 367.024 16.3676L368.097 14.1924C368.224 13.9361 368.589 13.9361 368.716 14.1924L369.789 16.3676Z"
          stroke="#4D3E78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363 18.3212V17.2645C363.125 17.1067 363.309 16.9908 363.538 16.9574L365.939 16.6086C366.214 16.5686 366.452 16.3958 366.575 16.1465L367.649 13.9712C367.959 13.3433 368.854 13.3433 369.164 13.9712L370.237 16.1465C370.36 16.3958 370.598 16.5686 370.873 16.6086L371 16.627V19.8601L371.5 22.8601L371.165 24.2008C371.091 24.1849 371.018 24.1586 370.946 24.1208L368.799 22.9921C368.553 22.8627 368.259 22.8627 368.013 22.9921L365.866 24.1208C365.246 24.4467 364.522 23.9204 364.64 23.2302L365.05 20.8394C365.097 20.5654 365.006 20.2857 364.807 20.0916L363.07 18.3985C363.045 18.3736 363.021 18.3478 363 18.3212Z"
          fill="#4D3E78"
        />
        <line x1="171.5" y1="2.18557e-08" x2="171.5" y2="36" stroke="#D7C4FA" />
        <defs>
          <clipPath id="clip0_2719_11979">
            <rect
              width="25.6286"
              height="25.6286"
              fill="white"
              transform="translate(0 5)"
            />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
};
