import { styled } from "@linaria/react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type P = { backgroundColor?: string; color?: string; borderColor?: string };

export const ColoredCardLg = styled.div<P>`
  position: relative;
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  background-color: ${p => p.backgroundColor ?? colorsV4.purple};
  border: ${p => (p.borderColor ? `2px solid ${p.borderColor}` : "none")};
  color: ${p => p.color ?? colorsV4.white};
`;

export const ColoredCardMd = styled.div<P>`
  position: relative;
  border-radius: ${rSize("radius")};
  padding: ${rSize("lg")};
  background-color: ${p => p.backgroundColor ?? colorsV4.purple};
  border: ${p => (p.borderColor ? `2px solid ${p.borderColor}` : "none")};
  color: ${p => p.color ?? colorsV4.white};
`;
