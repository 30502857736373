import { styled } from "@linaria/react";
import {
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

export const ShadableSectionWrap = styled.div`
  position: relative;
  padding-top: 3em;
  padding-bottom: 3em;
  ${fromTabletLg} {
    padding-top: 5em;
    padding-bottom: 5em;
  }
  &.shaded {
    ${uptoTabletLg} {
      background-color: ${colors.purple50};
      margin-left: ${rSize("pageMargin", -1)};
      margin-right: ${rSize("pageMargin", -1)};
      padding-left: ${rSize("pageMargin")};
      padding-right: ${rSize("pageMargin")};
    }
    ${fromTabletLg} {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: calc(${rSize("pageMargin", -1)} + ${rSize("lg")});
        bottom: 0;
        right: calc(${rSize("pageMargin", -1)} + ${rSize("lg")});
        background-color: ${colors.purple50};
        border-radius: ${rSize("radius")};
      }
    }
  }
`;

export const ShadableOrAdjacentSectionSpacer = styled.div`
  ${fromTabletLg} {
    height: 10em;
  }
`;
