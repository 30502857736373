const PagesIcon15 = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49997 1C3.11928 1 2 2.11928 2 3.49997V11.5C2 12.8807 3.11928 14 4.49997 14H10.5C11.8807 14 13 12.8807 13 11.5V3.49997C13 2.11928 11.8807 1 10.5 1H4.49997ZM2.99999 3.49997C2.99999 2.67156 3.67156 1.99999 4.49997 1.99999H10.5C11.3284 1.99999 12 2.67156 12 3.49997V11.5C12 12.3284 11.3284 13 10.5 13H4.49997C3.67156 13 2.99999 12.3284 2.99999 11.5V3.49997ZM5.49999 6.99998H9.49999C9.77614 6.99998 10 7.22385 10 7.49999V10.5C10 10.7761 9.77614 11 9.49999 11H5.49999C5.22385 11 4.99998 10.7761 4.99998 10.5V7.49999C4.99998 7.22385 5.22385 6.99998 5.49999 6.99998ZM3.99999 7.49999C3.99999 6.67157 4.67157 5.99999 5.49999 5.99999H9.49999C10.3284 5.99999 11 6.67157 11 7.49999V10.5C11 11.3284 10.3284 12 9.49999 12H5.49999C4.67157 12 3.99999 11.3284 3.99999 10.5V7.49999ZM4.49999 4.24999C4.49999 3.83578 4.83578 3.49999 5.24999 3.49999C5.66421 3.49999 5.99999 3.83578 5.99999 4.24999C5.99999 4.66421 5.66421 4.99999 5.24999 4.99999C4.83578 4.99999 4.49999 4.66421 4.49999 4.24999ZM7.49999 3.75001C7.22385 3.75001 6.99999 3.97386 6.99999 4.25C6.99999 4.52614 7.22385 4.74999 7.49999 4.74999H9.5C9.77614 4.74999 9.99999 4.52614 9.99999 4.25C9.99999 3.97386 9.77614 3.75001 9.5 3.75001H7.49999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PagesIcon15;
