import { styled } from "@linaria/react";
import { cx } from "linaria";
import { shade } from "polished";
import { useRef } from "react";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  brandColorThemeVar,
  colors,
  withOpacity,
} from "../../styles/colors.styles";
import { sansObliqueFontFaces } from "../../styles/fontFaces/sansOblique.fontFaces";
import { font } from "../../styles/fonts.styles";
import { includeGlobalStyles } from "../../styles/globals.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { useApplyPrismCodeHighlight } from "../../utils/prism.utils";
import { widthInGrid } from "../../constants/globalGrid.constants";

type Props = React.PropsWithChildren<{
  centered?: boolean;
  className?: string;
  html?: string | null;
  fullWidth?: boolean;
}>;

includeGlobalStyles(sansObliqueFontFaces);

export const StandardArticleContainer = styled.article`
  line-height: 1.625;
  font-size: 1.6rem;
  font-weight: 400;
  ${fromDesktopMd} {
    font-size: 1.8rem;
  }
  > *,
  .ArticleBlock > * {
    + *:not(blockquote, h2, hr) {
      margin-top: 1em;
    }
    + *:not(
        .ImageBlock,
        .VideoBlock,
        .PullQuote,
        .CTABlock,
        .DataCardGrid,
        .ProductFeatureCard,
        .ProductFeatureCardGrid
      ) {
      margin-top: 1em;
    }
    + blockquote,
    + .ImageBlock,
    + .VideoBlock,
    + .PullQuote,
    + .CTABlock,
    + .DataCardGrid,
    + .ProductFeatureCard,
    + .ProductFeatureCardGrid,
    + .InArticleStoryGrid,
    + .TwoColumnLayoutBlock {
      margin-top: ${rSize("lg")};
      + * {
        margin-top: ${rSize("lg")};
      }
    }
    + h2 {
      margin-top: 1.5em;
    }
  }
  h1 {
    font-size: 160%;
  }
  h2 {
    font-size: 140%;
    line-height: 1.25;
    strong:only-child {
      font-weight: inherit;
    }
  }
  h3 {
    padding-top: 0.5em;
    line-height: 1.15;
  }
  h2,
  h3 {
    a {
      text-decoration-thickness: 1px;
      color: ${brandColorThemeVar(500)};
    }
  }
  h4 {
    padding-top: 0.5em;
    font-size: inherit;
  }
  h5 {
    padding-top: 0.5em;
    font-size: inherit;
    opacity: 0.75;
  }
  h6 {
    padding-top: 0.5em;
    font-size: inherit;
    opacity: 0.5;
  }
  p {
    line-height: inherit;
    font-weight: 400;
    ${fromDesktop} {
      font-weight: 400;
    }
    + p {
      margin-top: 1em;
    }
  }
  b,
  strong {
    font-weight: 600;
  }
  p,
  th,
  td,
  li {
    a {
      ${inParagraphLinkStyle()};
      word-wrap: break-word;
    }
  }
  ul,
  ol {
    ol,
    ul {
      margin-top: 0.5em;
      padding-left: 1em;
    }
    li + li {
      margin-top: 0.5em;
    }
    &:last-child {
      margin-bottom: 0.5em;
    }
  }
  ol {
    > li > ol {
      list-style-type: lower-alpha;
      > li > ol {
        list-style-type: lower-roman;
      }
    }
  }
  blockquote:not(.PullQuote) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-left: 2px solid ${brandColorThemeVar(500)};
    padding: 1em 1.5em;
    ${fromTablet} {
      padding: 1em 2.5em;
    }
    + * {
      margin-top: ${rSize("lg")};
    }
  }
  hr {
    border-top: 2px solid currentColor;
    opacity: 0.1;
    margin: 2.5em 0;
    background-color: transparent;
  }
  table {
    width: 100%;
    height: auto !important;
    border-collapse: collapse;
    border: 0px solid transparent;
    th,
    td {
      border: 0px solid transparent;
    }
    margin-bottom: 2em;
    &.wider {
      ${fromTablet} {
        margin-left: ${widthInGrid(1, 1, -1)};
        margin-right: ${widthInGrid(1, 1, -1)};
        width: ${widthInGrid(2, 2, -1, "100%")};
        margin-top: ${rSize("xl")};
        margin-bottom: ${rSize("xl")};
        thead,
        tbody:first-child {
          tr:first-child {
            th,
            td {
              padding-top: 1.5em;
              border-top: 2px solid ${withOpacity(colors.gray, 0.4)};
            }
          }
        }
        tbody:last-child {
          tr:last-child {
            th,
            td {
              padding-bottom: 1.5em;
              border-bottom: 2px solid ${withOpacity(colors.gray, 0.4)};
            }
          }
        }
      }
      ${fromTabletLg} {
        margin-left: ${widthInGrid(2, 1, -1)};
        margin-right: ${widthInGrid(2, 1, -1)};
        width: ${widthInGrid(4, 2, -1, "100%")};
      }
      .hasRightColumn & {
        ${fromTablet} {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
      }
    }
    * + & {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }
    + * {
      margin-top: 2em;
    }
    &.split {
      table-layout: fixed;
      th,
      td {
        width: calc(50% - ${rSize("gap", 0.5, true)});
        &:first-child {
          padding-right: ${rSize("gap", 0.5)};
          padding-left: 0;
        }
        &:last-child {
          padding-left: ${rSize("gap", 0.5)};
          padding-right: 0;
        }
      }
    }
    &.split-with-row-heading {
      ${onlyPhones} {
        margin-left: ${rSize("pageMargin", -1)};
        margin-right: ${rSize("pageMargin", -1)};
        width: calc(100% + ${rSize("pageMargin", 2)});
        font-size: 1.4rem;
        line-height: 1.25;
      }
      ${fromTablet} {
        table-layout: fixed;
        font-size: 1.6rem;
      }
      th,
      td {
        &:first-child {
          padding-right: ${rSize("gap", 0.5)};
          width: 8em;
          ${onlyPhones} {
            padding-left: 1em;
          }
        }
        &:nth-child(2) {
          padding-right: ${rSize("gap", 0.5)};
          padding-left: 0;
          width: 48%;
        }
        &:last-child {
          padding-left: ${rSize("gap", 0.5)};
          padding-right: 0.5em;
          width: 52%;
        }
      }
    }
    ul {
      padding-left: 1.25em;
    }
  }
  th {
    text-align: left;
  }
  thead {
    th,
    td {
      font-weight: 700;
      line-height: 1.25;
      padding-top: 0.75em;
      padding-right: 0.75em;
      padding-bottom: 0.75em;
      border-bottom: 2px solid ${withOpacity(colors.gray, 0.3)};
    }
  }
  tbody {
    &:first-child {
      tr {
        &:first-child {
          font-weight: 700;
        }
      }
    }
    th,
    td {
      vertical-align: top;
      padding-top: 0.75em;
      padding-right: 0.75em;
      padding-bottom: 0.75em;
      border-bottom: 1px solid ${withOpacity(colors.gray, 0.3)};
      > * {
        + * {
          margin-top: 0.5em;
        }
      }
      img {
        border-radius: 0.5em;
      }
    }
    tr {
      &:last-child {
        th,
        td {
          border-bottom: 0;
        }
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  table,
  p,
  blockquote,
  li {
    img {
      max-width: 100%;
      border-radius: ${rSize("radius")};
    }
    code {
      font-family: ${font("monospace")} !important;
      font-size: 90%;
      background-color: ${withOpacity(colors.green, 0.1)};
      color: ${colors.green700};
      padding: 0.1em 0.3em;
      border-radius: 0.35em;
      ${darkModeLinariaCSS(`color: ${colors.green};`)}
    }
  }
  code {
    font-family: ${font("monospace")} !important;
    font-size: 90%;
    text-shadow: none !important;
    font-variant-ligatures: none;
    overflow-wrap: anywhere;
  }
  pre {
    margin: 0.5em 0;
    padding: 1em;
    &:has(.line-numbers-rows) {
      padding-left: 3.5em;
    }
    border-radius: ${rSize("radius")};
    overflow: auto;
    font-family: ${font("monospace")} !important;
    max-width: 100%;
    font-size: 1.3rem;
    text-shadow: none;
    border: 0;
    background-color: ${colors.light100};
    font-variant-ligatures: none;
    ${darkModeLinariaCSS(`
      background-color: ${withOpacity(colors.dark600, 0.5)};
    `)}
    &.prismjs-ignore {
      padding-left: 1em;
    }
    code {
      color: inherit;
      background-color: transparent;
      ${darkModeLinariaCSS(`color: inherit;`)}
    }
  }
  pre[data-language] {
    line-height: 1.5;
  }
  pre[class*="language-"] {
    text-shadow: none !important;
    font-size: 1.3rem;
    color: inherit;
    code {
      color: inherit;
      font-size: inherit;
    }
    border: 0;
    background-color: ${colors.light100};
    ${darkModeLinariaCSS(`
      background-color: ${withOpacity(colors.dark600, 0.5)};
    `)}
  }
  div.code-toolbar {
    > .toolbar {
      right: 0.5em;
      > .toolbar-item {
        > span,
        > button {
          border: 1px solid transparent;
          background-color: ${colors.purple};
          color: ${colors.white};
          border-radius: 5px;
          box-shadow: none;
          font-weight: 700;
          font-size: 1.2rem;
          cursor: pointer;
          height: 1.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: capitalize;
          &:hover {
            background-color: ${colors.purple600};
            color: ${colors.white};
          }
          &:focus {
            border-color: ${colors.purple400};
            background-color: ${colors.purple600};
            color: ${colors.white};
          }
        }
        button {
          span {
            transform: translateY(-0.05em);
          }
        }
        + * {
          margin-left: 0.25em;
        }
      }
    }
  }
  .line-numbers-rows {
    line-height: inherit;
    > span:before {
      padding-right: 0.5em;
    }
  }
  pre {
    .line-numbers-rows {
      opacity: 0.8;
      border-right-color: transparent;
      > span:before {
        color: ${colors.gray};
      }
    }
    .token.constant,
    .token.deleted,
    .token.property,
    .token.script {
      color: ${colors.dark600};
      ${darkModeLinariaCSS(`color: ${colors.light100};`)}
    }
    .token.cdata,
    .token.comment,
    .token.doctype,
    .token.prolog {
      color: ${colors.gray};
    }
    .token.tag,
    .token.class-name,
    .token.builtin,
    .token.entity {
      color: ${colors.purple700};
      ${darkModeLinariaCSS(`color: ${colors.purple};`)}
    }
    .token.variable,
    .token.attr-name,
    .token.parameter {
      color: ${colors.green700};
      ${darkModeLinariaCSS(`color: ${colors.green};`)}
    }
    .token.string {
      color: ${colors.orange};
      ${darkModeLinariaCSS(`color: ${colors.orange};`)}
    }
    .token.function {
      color: ${shade(0.1, colors.pink)};
      ${darkModeLinariaCSS(`color: ${colors.pink};`)}
    }
    .token.attr-value,
    .token.keyword,
    .token.boolean,
    .token.regex {
      color: ${colors.pink700};
      ${darkModeLinariaCSS(`color: ${colors.pink};`)}
    }
    .token.number,
    .token.symbol {
      color: ${shade(0.1, colors.yellow)};
    }
    .language-css .token.string,
    .style .token.string,
    .token.entity,
    .token.operator {
      background: transparent;
      color: ${colors.orange700};
    }
    .token.url {
      background: ${withOpacity(colors.purple, 0.1)};
    }
    .token.interpolation {
      color: ${colors.green700};
      ${darkModeLinariaCSS(`color: ${colors.green};`)}
    }
  }
`;

const StandardArticle = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useApplyPrismCodeHighlight(ref);
  const className = cx(
    "StandardArticle",
    props.centered && "centered",
    props.fullWidth && "fullWidth",
    props.className
  );
  return props.html ? (
    <StandardArticleContainer
      ref={ref}
      className={className}
      dangerouslySetInnerHTML={{
        __html: props.html,
      }}
    />
  ) : (
    <StandardArticleContainer
      ref={ref}
      className={className}
      children={props.children}
    />
  );
};

export default StandardArticle;
