import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { cx } from "linaria";
import { useSiteContext } from "../../context/site.context";
import { colors, withOpacity } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { PageTree, TreePage, flattenTree } from "../../utils/tree.utils";
import { ArrowLeftIcon } from "../icons/misc/ArrowLeftIcon";
import { ArrowRightIcon } from "../icons/misc/ArrowRightIcon";

type Props = {
  tree: PageTree;
  doNotShowNext?: boolean;
};

const HubPrevNextNavWrap = styled.nav`
  display: grid;
  &.hasBoth {
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: ${rSize("gap")};
  align-items: stretch;
  .prev {
    grid-template-columns: auto minmax(auto, 1fr);
  }
  .next {
    text-align: right;
    grid-template-columns: minmax(auto, 1fr) auto;
  }
  a,
  > span {
    display: grid;
    grid-gap: 0.5em;
    text-decoration: none;
    background-color: ${colors.light200};
    ${darkModeLinariaCSS(`background-color: ${colors.dark500}`)};
    padding: 1em;
    border-radius: 0.5em;
    align-items: center;
    min-height: 8rem;
    font-weight: 500;
  }
  a {
    &[href^="/docs/formulas/functions/"] span {
      font-family: ${font("monospace")};
    }
    &:hover {
      background-color: var(--ac15, ${withOpacity(colors.purple, 0.15)});
      ${darkModeLinariaCSS(
        `background-color: var(--ac15, ${withOpacity(colors.purple, 0.15)})`
      )};
      color: var(--ac, ${colors.purple});
    }
  }
`;

const HubPrevNextNav = (props: Props) => {
  const { pathname } = useSiteContext().location;
  const flattenedTree = flattenTree(props.tree);
  const currentPageIndex = flattenedTree.findIndex(
    page => page.path.replace(/\/$/, "") === pathname.replace(/\/$/, "")
  );
  let prev: TreePage | undefined, next: TreePage | undefined;
  if (currentPageIndex !== -1) {
    let prevIndex = currentPageIndex - 1;
    while (flattenedTree[prevIndex] && flattenedTree[prevIndex].hasNoContent) {
      prevIndex--;
    }
    prev = flattenedTree[prevIndex];
    if (!props.doNotShowNext) {
      let nextIndex = currentPageIndex + 1;
      while (
        flattenedTree[nextIndex] &&
        flattenedTree[nextIndex].hasNoContent
      ) {
        nextIndex++;
      }
      next = flattenedTree[nextIndex];
    }
  }
  return prev || next ? (
    <HubPrevNextNavWrap className={cx(prev && next && "hasBoth")}>
      {prev && (
        <Link className="prev" to={prev.path}>
          <ArrowLeftIcon />
          <span>{prev.title}</span>
        </Link>
      )}
      {next && (
        <Link className="next" to={next.path}>
          <span>{next.title}</span>
          <ArrowRightIcon />
        </Link>
      )}
    </HubPrevNextNavWrap>
  ) : null;
};

export default HubPrevNextNav;
