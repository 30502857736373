import { styled } from "@linaria/react";
import { RuntimeStoryRecord } from "../../types/tines.types";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import StoryboardActionInspector from "./StoryboardActionInspector";
import ClickToCopy from "../utilities/ClickToCopy";
import { css, cx } from "linaria";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import StoryboardViewer from "./StoryboardViewer";
import StoryboardViewport from "./StoryboardViewport";

type Props = {
  className?: string;
  story: RuntimeStoryRecord;
  aspectRatio?: string;
  enableWheelPan?: boolean;
  id?: string | number;
};

const StoryboardViewerContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${rSize("radius")};
  background-color: ${colorsV4.canvas550};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colorsV4.warmBlack, 0.5)};`
  )}
  padding: 1em;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: stretch;
  grid-gap: 1em;
`;

export const StoryboardViewportWrap = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: ${rSize("md", 0.5)};
  aspect-ratio: 4/3;
`;

const InspectorWrap = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
`;

const copyButtonCss = css`
  appearance: none;
  background-color: ${colorsV4.purple};
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
  ${fromTablet} {
    font-size: 1.4rem;
  }
  color: ${colorsV4.white};
  border-radius: 0.5em;
  border: 0;
  position: absolute;
  top: 2em;
  right: 2em;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

/** Currently supports displaying actions in copied Tines story partial json from clipboard */
const StoryboardViewerStacked = (props: Props) => {
  return (
    <StoryboardViewer
      id={props.id}
      story={props.story}
      aspectRatio={props.aspectRatio}
      inspectable
      children={context => (
        <StoryboardViewerContainer
          data-id={context.id}
          className={cx(
            props.className,
            context.selectedActionId !== null && "hasSelection"
          )}
        >
          <StoryboardViewportWrap
            ref={context.ref}
            data-ready={context.ready}
            style={{
              aspectRatio: props.aspectRatio,
            }}
          >
            <StoryboardViewport />
          </StoryboardViewportWrap>
          <InspectorWrap>
            <StoryboardActionInspector copiable />
          </InspectorWrap>
          {context.story.record.jsonString && (
            <ClickToCopy
              className={copyButtonCss}
              as="button"
              contentToCopy={context.story.record.jsonString}
              successMessage={`Copied to clipboard. Try pasting ${
                context.story.actions.length > 1 ? "them" : "it"
              } in your Tines story!`}
            >
              Copy Action{context.story.actions.length > 1 ? "s" : ""}
            </ClickToCopy>
          )}
        </StoryboardViewerContainer>
      )}
      enableDragPan
      enableManualZoom
    />
  );
};

export default StoryboardViewerStacked;
