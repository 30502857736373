/* eslint-disable @typescript-eslint/no-misused-promises */
import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import React, { useRef, useState } from "react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { readFileAsBase64String } from "../../utils/file.utils";
import Button from "../forms/Button";
import { FileIcon } from "../icons/misc/FileIcon";
import LoadingIndicator from "../utilities/LoadingIndicator";

const defaultFileUploadAccepts = [
  // cspell:disable
  "image/png",
  "image/gif",
  "image/jpeg",
  "video/*",
  "text/plain",
  "text/csv",
  "application/pdf",
  "application/msword",
  "application/json",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // cspell:enable
].join(",");

export type UploadedFileMeta = {
  id: string;
  fileName: string;
  type: string;
  url: string;
  base64?: string;
};

export const fileUploaderBase64UploadHandler = async (file: File) => {
  const { base64 } = await readFileAsBase64String(file);
  return {
    id: file.name,
    fileName: file.name,
    type: file.type,
    url: base64,
    base64,
  };
};

type Props = {
  className?: string;
  accepts?: string;
  fileUploadHandler: (file: File) => Promise<UploadedFileMeta>;
  fileDeletionHandler?: (file: UploadedFileMeta) => Promise<void>;
  onFileUploaded: (details: UploadedFileMeta) => void | Promise<void>;
  sizeLimitInMB?: number;
  placeholder?: string;
};

const FileUploaderContainer = styled.div`
  position: relative;
  border-radius: 0.5em;
  overflow: hidden;
`;

const InputArea = styled.div`
  position: relative;
  height: 8rem;
  background-color: ${withOpacity(colors.purple, 0.2)};
  color: ${colors.purple};
  border: 1px dashed ${withOpacity(colors.purple, 0.6)};
  border-radius: inherit;
  &:hover {
    border-color: ${withOpacity(colors.purple, 0.3)};
  }
  .dragOver & {
    background-color: ${withOpacity(colors.purple, 0.35)};
    border-color: ${colors.purple};
    ${darkModeLinariaCSS(`
      background-color: ${withOpacity(colors.purple, 0.35)};
      border-color: ${colors.purple};
      &:hover {
        border-color: ${colors.purple};
      }
    `)}
  }
  .hasFile & {
    background-color: ${withOpacity(colors.green, 0.15)};
    border-color: ${colors.green};
    color: ${colors.green};
    ${darkModeLinariaCSS(`
      background-color: ${withOpacity(colors.green, 0.15)};
      border-color: ${colors.green};
      &:hover {
        border-color: ${colors.green};
      }
    `)}
    input:hover + span {
      background-color: ${withOpacity(colors.green, 0.3)};
    }
  }
  .hasError & {
    background-color: ${withOpacity(colors.orange, 0.15)};
    border-color: ${colors.orange};
    ${darkModeLinariaCSS(`
      background-color: ${withOpacity(colors.orange, 0.15)};
      border-color: ${colors.orange};
      &:hover {
        border-color: ${colors.orange};
      }
    `)}
    input:hover + span {
      background-color: ${withOpacity(colors.orange, 0.3)};
    }
  }
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  small {
    opacity: 0.6;
    font-size: 1.4rem;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
    &:hover + span {
      background-color: ${withOpacity(colors.purple, 0.3)};
    }
  }
`;

const TextLabel = styled.div`
  flex: 1 1 100%;
  z-index: 1;
  position: relative;
  pointer-events: none;
  font-weight: 500;
`;

const HoverEffectOverlay = styled.span`
  ${cover()};
  pointer-events: none;
`;

const UploadDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 1.25em;
  p {
    margin: 0;
    display: grid;
    align-items: center;
    grid-template-columns: auto minmax(0, 1fr);
    grid-gap: 1em;
    font-weight: 500;
    text-align: left;
    margin-left: 0 !important;
    margin-right: 0 !important;
    span {
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

const LoadingIndicatorWrap = styled.div`
  ${cover()};
  background-color: ${colors.light400};
  ${darkModeLinariaCSS(`background-color: ${colors.dark500};`)}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileUploader = (props: Props) => {
  const limit = props.sizeLimitInMB ?? 5;
  const [lastUpload, setLastUpload] = useState<UploadedFileMeta | null>(null);
  const [dragOver, setDragOver] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<null | unknown>(null);
  const ref = useRef<HTMLInputElement | null>(null);
  const handleDragOver = () => setDragOver(true);
  const handleDragLeave = () => setDragOver(false);
  const handleDragEnd = () => setDragOver(false);
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDragOver(false);
    setLastUpload(null);
    handleFile((e.target as HTMLInputElement).files?.[0] ?? null);
  };
  const handleFile = async (file: File | null) => {
    if (!file) return;
    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > limit) {
      setError("The file you have selected is too large.");
      return;
    }
    try {
      setUploading(true);
      setError(null);
      const result = await props.fileUploadHandler(file);
      if (!result.base64) {
        result.base64 = (await readFileAsBase64String(file)).base64;
      }
      setLastUpload(result);
      props.onFileUploaded(result);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError(e);
    } finally {
      if (ref.current) ref.current.value = "";
      setUploading(false);
    }
  };
  return (
    <FileUploaderContainer
      className={cx(
        props.className,
        dragOver && "dragOver",
        lastUpload && "hasFile",
        error ? "hasError" : null
      )}
    >
      <InputArea
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragEnd}
      >
        {!uploading && (
          <TextLabel>
            {lastUpload ? (
              <UploadDetails>
                <p>
                  <FileIcon /> <span>{lastUpload.fileName}</span>
                </p>
                <Button color="inherit" appearance="outlined">
                  Replace
                </Button>
              </UploadDetails>
            ) : (
              <>
                <p>
                  {error
                    ? typeof error == "string"
                      ? error
                      : "An error occurred; please try again."
                    : props.placeholder ??
                      "Choose a file or drag and drop here"}
                </p>
                <p>
                  <small>Size limit: {limit} MB</small>
                </p>
              </>
            )}
          </TextLabel>
        )}
        <input
          type="file"
          onChange={handleInputChange}
          accept={props.accepts ?? defaultFileUploadAccepts}
          ref={ref}
        />
        <HoverEffectOverlay />
      </InputArea>
      {uploading && (
        <LoadingIndicatorWrap>
          <LoadingIndicator size={24} />
        </LoadingIndicatorWrap>
      )}
    </FileUploaderContainer>
  );
};

export default FileUploader;
