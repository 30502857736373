export const LogoHemato = () => (
  <svg width="133" height="19" viewBox="0 0 133 19" fill="currentColor">
    <path d="M38.2678 4.56694V1.56845H23.3014V18.1531H38.2678V15.1552H26.8461V11.31H36.6504V8.31221H26.8461V4.56694H38.2678Z" />
    <path d="M16.6162 18.1524V1.56912H13.0708V8.31222H4.07599V1.56912H0.529907V18.1524H4.07599V11.3107H13.0708V18.1524H16.6162Z" />
    <path d="M123.177 15.3527C119.928 15.3527 117.659 13.0943 117.659 9.86001C117.659 6.62703 119.928 4.36801 123.177 4.36801C126.426 4.36801 128.694 6.62703 128.694 9.86001C128.694 13.0943 126.426 15.3527 123.177 15.3527ZM123.177 1.26959C117.711 1.26959 114.039 4.72241 114.039 9.86001C114.039 14.9983 117.711 18.4504 123.177 18.4504C128.642 18.4504 132.315 14.9983 132.315 9.86001C132.315 4.72241 128.642 1.26959 123.177 1.26959Z" />
    <path d="M79.1641 4.90381L81.8494 11.6702H76.452L79.1641 4.90381ZM83.0819 14.5694L84.5994 18.1528H89.8543V15.8812H87.4554L81.12 1.56824H77.3574L70.0161 18.1528H73.7027L75.2209 14.5694H83.0819Z" />
    <path d="M64.9302 18.1524V1.56912H59.8086L54.6237 13.5677L49.4122 1.56912H44.3159V18.1524H47.6874V5.16984L53.3146 18.1524H55.7569L61.3848 5.16984V18.1524H64.9302Z" />
    <path d="M96.7247 4.48437L100.33 5.80541L109.904 2.29663L106.298 0.975586L96.7247 4.48437Z" />
    <path d="M90.7564 2.29663L94.6303 3.71626L98.2356 2.39455L94.3611 0.975586L90.7564 2.29663Z" />
    <path d="M99.4785 9.95055V7.3078L89.5757 3.67911V6.32185L99.4785 9.95055Z" />
    <path d="M96.9943 10.8624V17.6927L99.4758 18.6014V11.7717L96.9943 10.8624Z" />
    <path d="M105.366 5.77416V8.41691L111.083 6.3211V3.67969L105.366 5.77416Z" />
    <path d="M101.182 7.30823V18.6014L103.664 17.6927V6.3989L101.182 7.30823Z" />
  </svg>
);
