import { Link } from "gatsby";
import { DatoCmsLibraryCollection } from "../../../graphql-types";
import { withOpacity } from "../../styles/colorsV4.styles";
import { CSSProperties } from "react";
import { css } from "linaria";
import { rSize } from "../../styles/responsiveSizes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { font } from "../../styles/fonts.styles";
import { styled } from "@linaria/react";
import { fromTabletLg } from "../../styles/breakpointsAndMediaQueries.styles";
import TeamCollectionImage from "./TeamCollectionImage";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";
import { Paths } from "../../utils/pathBuilders.utils";
import { LibraryCollectionType } from "../../utils/libraryCollectionType.enum";

type Props = {
  collection: DatoCmsLibraryCollection;
};

const collectionEntryCss = css`
  display: block;
  border-radius: ${rSize("radius")};
  text-decoration: none;
  &[data-type="${LibraryCollectionType.team}"] {
    padding: 1em;
    background-color: var(--ac10, ${withOpacity(colors.purple, 0.1)});
    color: var(--ac700, ${colors.purple700});
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: "." "header";
    grid-gap: 1em;
    ${darkModeLinariaCSS(`color: var(--ac300, ${colors.purple300})`)}
    header {
      grid-area: header;
      text-align: center;
      align-self: center;
      justify-self: center;
    }
    h3 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    img {
      max-width: 100%;
    }
  }
  &[data-type="${LibraryCollectionType.topLevel}"],
  &[data-type="${LibraryCollectionType.productFeature}"],
  &[data-type="${LibraryCollectionType.useCase}"] {
    position: relative;
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-areas: "header" "footer";
    grid-gap: ${rSize("gap")};
    padding: ${rSize("lg")};
    background-color: var(--ac, ${colors.purple});
    ${darkModeLinariaCSS(
      `background-color: var(--ac600, ${colors.purple600});`
    )}
    color: ${colors.white};
    min-height: 25rem;
    font-size: 1.4rem;
    overflow: hidden;
    header {
      grid-area: header;
      width: 18em;
      max-width: 60%;
      > * {
        + * {
          margin-top: 0.5em;
        }
      }
    }
    h3 {
      font-size: 2.4rem;
      ${fromTabletLg} {
        font-size: 2.8rem;
      }
      font-family: ${font("serif")};
      font-weight: 400;
    }
    footer {
      grid-area: footer;
      font-weight: 500;
    }
  }
`;

const IllustrationWrap = styled.div`
  position: absolute;
  right: 1em;
  bottom: 0;
  transform: translate(5%, 2%);
  max-width: 40%;
  height: 85%;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  img {
    align-items: flex-end;
    max-width: 100%;
    height: auto;
    margin-top: -0.5em;
  }
`;

const CollectionEntry = ({ collection }: Props) => {
  const theme = getBrandColorTheme(collection.color);
  const { image } = collection;
  return (
    <Link
      className={collectionEntryCss}
      to={Paths.library.collection(collection.collectionType, collection.slug)}
      data-type={collection.collectionType}
      style={
        {
          "--ac10": withOpacity(theme.c500, 0.1),
          "--ac20": withOpacity(theme.c500, 0.2),
          "--ac300": theme.c300,
          "--ac": theme.c500,
          "--ac600": theme.c600,
          "--ac700": theme.c700,
        } as CSSProperties
      }
    >
      <header>
        <h3>{collection.name}</h3>
        {/* {collection.collectionType === LibraryCollectionType.useCase && (
          <p>
            <AvoidWidows as="fragment">{collection.description}</AvoidWidows>
          </p>
        )} */}
      </header>
      {collection.collectionType === LibraryCollectionType.team && (
        <TeamCollectionImage collection={collection} />
      )}
      {(collection.collectionType === LibraryCollectionType.useCase ||
        collection.collectionType === LibraryCollectionType.topLevel ||
        collection.collectionType === LibraryCollectionType.productFeature) && (
        <>
          {image?.url && (
            <IllustrationWrap>
              <img
                width={`${image.width}`}
                height={`${image.height}`}
                src={image.url}
                style={{ aspectRatio: `${image.width} / ${image.height}` }}
              />
            </IllustrationWrap>
          )}
          <footer>
            <p>
              <strong>View stories →</strong>
            </p>
          </footer>
        </>
      )}
    </Link>
  );
};

export default CollectionEntry;
