import { styled } from "@linaria/react";
import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useRef, useState } from "react";
import { initSentry } from "../../@initSentry";
import { QueryClient, QueryClientProvider } from "react-query";
import { ISiteContext, SiteContext } from "../../context/site.context";
import {
  isBrowser,
  isDevelopment,
  isTinesComBuildOnMain,
} from "../../environment";
import {
  ColorScheme,
  SystemColorScheme,
  getPersistedColorScheme,
  readColorScheme,
} from "../../utils/colorScheme.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { getScrollbarWidth } from "../../utils/scrollbar.utils";
import { STORAGE_KEYS } from "../../utils/storageKeys.utils";
import { UI, useOnWindowResize } from "../../utils/window.utils";
import GlobalSearch from "../search/GlobalSearch";
import HashChangeAutoScrollController from "../utilities/HashChangeAutoScrollController";
import PreviewModeTag from "../utilities/PreviewModeTag";
import {
  initCookieControls,
  makeDefaultCookieConsentState,
} from "../gdpr/gdpr";
import { initIntercom } from "../../@intercom";
import CookieConsentBanner from "../gdpr/CookieConsentBanner";
import { guessBrowserTimezone } from "../../utils/time.utils";
import { PageProps } from "gatsby";
import { PageTree } from "../../utils/tree.utils";
import { cx } from "linaria";
import SiteFooter from "../sections/SiteFooter";
import { Helmet } from "react-helmet";
import { setCookie } from "../../utils/cookies.utils";
import SiteSidebar from "./SiteSidebar";
import { runAfter } from "../../utils/promises.utils";
import { initAlgoliaAnalytics } from "../../utils/algolia.utils";
import { zIndex } from "../../styles/zIndexes.styles";
import { listenForDemoRequestSuccessEvents } from "../../utils/demoRequests.utils";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import { detectAndWatchForTouchSupport } from "../../utils/touch.utils";
import { StoryDescriptor } from "../../types/helper.types";
import StoryLightbox from "../library/StoryLightbox";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import SiteNav from "./SiteNav";
import { LayoutWithSidebar } from "../layout/LayoutWithSidebar";
import AdminToolbar from "./AdminToolbar";
import Spacing from "../layout/Spacing";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage.utils";
import { captureAnalyticsEvent } from "../../utils/analyticsEvent";
import { recordIntercomBannerHeight } from "../../utils/intercom.utils";
import MockIntercomButton from "../misc/MockIntercomButton";
import { useHotkeys } from "react-hotkeys-hook";
import { isMac } from "../../utils/platform.utils";
import { AlwaysOnThirdPartyScripts } from "../gdpr/thirdPartyServices/AlwaysOnThirdPartyScripts";

type Props = React.PropsWithChildren<
  PageProps<
    {},
    {
      alwaysUseColorScheme?: SystemColorScheme | null;
      supportsColorSchemes?: boolean;
      tree?: PageTree;
      hasSidebar?: boolean;
      simplifyHeader?: boolean;
      simplifyFooter?: boolean;
      learningPath?: {
        slug: string;
        title: string;
      };
    }
  >
>;

initSentry();

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase);

const queryClient = new QueryClient();

const SiteWrapper = (props: Props) => {
  const [isClient, setIsClient] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [cookieConsentState, setCookieConsentState] = useState(
    makeDefaultCookieConsentState()
  );
  const [
    shouldDisplayCookieConsentManager,
    setShouldDisplayCookieConsentManager,
  ] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [modal, openModal] = useState<string | null>(null);
  const [colorScheme, setColorScheme] = useState(readColorScheme());
  const [shouldShowGlobalSearch, setShouldShowGlobalSearch] = useState(false);
  const [gdprApplicable, setGdprApplicable] = useState<boolean>(false);
  const [librarySearchQueryRef, setLibrarySearchQuery] = useStateWithRef("");
  const [storyToRenderInLightbox, viewStoryInLightbox] =
    useState<null | StoryDescriptor>(null);
  const [showGlobalGridRef, setShowGlobalGrid, showGlobalGrid] =
    useStateWithRef(false);
  const toggleGlobalGrid = () => {
    const newValue = !showGlobalGridRef.current;
    setShowGlobalGrid(newValue);
    setLocalStorageItem("GlobalGridVisibility", newValue);
  };

  const siteContext: ISiteContext = {
    isClient,
    isFirstLoad,
    timezone,
    setTimezone: (value: string) => {
      setTimezone(value);
      if (isBrowser) setLocalStorageItem(STORAGE_KEYS.preferredTimezone, value);
    },
    supportsColorSchemes: props.pageContext.supportsColorSchemes ?? false,
    alwaysUseColorScheme: props.pageContext.alwaysUseColorScheme ?? null,
    colorScheme,
    setColorScheme: (colorScheme: ColorScheme) => {
      setCookie("preferred-color-scheme", colorScheme);
      setColorScheme(colorScheme);
    },
    location: props.location,
    cookieConsentState,
    setCookieConsentState,
    shouldDisplayCookieConsentManager,
    setShouldDisplayCookieConsentManager,
    gdprApplicable,
    setGdprApplicable,
    hasSidebar: !!props.pageContext.hasSidebar || !!props.pageContext.tree,
    sidebarOpen,
    toggleSidebar: (value?: boolean) => {
      if (typeof value !== "undefined") setSidebarOpen(value);
      else setSidebarOpen(!sidebarOpen);
    },
    modal,
    openModal,
    library: {
      get query() {
        return librarySearchQueryRef.current;
      },
      setQuery: (query: string) => {
        setUrlQueryParam("s", query);
        setLibrarySearchQuery(query);
      },
      clearQuery: () => {
        removeUrlQueryParam("s");
        setLibrarySearchQuery("");
      },
      storyToRenderInLightbox,
      viewStoryInLightbox,
      closeStoryLightbox: () => viewStoryInLightbox(null),
    },
    showGlobalGrid,
    toggleGlobalGrid,
    shouldShowGlobalSearch,
    setShouldShowGlobalSearch: () => {
      if (location.pathname.match(/^\/search/)) return;
      setShouldShowGlobalSearch(true);
    },
    toggleGlobalSearch: (value?: boolean) => {
      const newValue =
        typeof value !== "undefined" ? value : !shouldShowGlobalSearch;
      if (!newValue) setLibrarySearchQuery("");
      setShouldShowGlobalSearch(newValue);
    },
  };
  const siteContextRef = useRef(siteContext);
  siteContextRef.current = siteContext;

  if (isBrowser) {
    if (isDevelopment) {
      Reflect.set(window, "siteContext", siteContext);
      document.documentElement.classList.add("DEV");
    }
  }

  useOnWindowResize(() => {
    UI.vw = window.innerWidth;
    UI.vh = window.innerHeight;
  });

  useHotkeys(isMac ? "command+k" : "ctrl+k", () => {
    setShouldShowGlobalSearch(true);
  });
  useHotkeys("/", e => {
    e.preventDefault();
    setShouldShowGlobalSearch(true);
  });

  useOnMount(() => {
    setIsClient(true);
    const tz =
      getLocalStorageItem<string | null>(STORAGE_KEYS.preferredTimezone) ??
      guessBrowserTimezone() ??
      "Europe/Dublin";
    siteContext.setTimezone(tz);
    setShowGlobalGrid(getLocalStorageItem("GlobalGridVisibility") ?? false);
    const persistedColorScheme = getPersistedColorScheme() || colorScheme;
    setColorScheme(persistedColorScheme);
    runAfter(async () => {
      await initCookieControls(siteContextRef.current);
      initIntercom();
      initAlgoliaAnalytics();
    });
    const recordScrollbarWidth = () => {
      document.documentElement.style.setProperty(
        "--ScrollbarWidth",
        `${getScrollbarWidth()}px`
      );
    };

    const handleResize = () => {
      recordScrollbarWidth();
      recordIntercomBannerHeight();
    };

    window.addEventListener("resize", handleResize);
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);

    let documentBodyStyleMutationObserver: MutationObserver;

    try {
      documentBodyStyleMutationObserver = new MutationObserver(() => {
        recordIntercomBannerHeight();
      });
      documentBodyStyleMutationObserver.observe(document.body, {
        attributes: true,
        attributeFilter: ["style"],
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("MutationObserver not supported in this browser.");
    }

    handleScroll();
    handleResize();

    setTimeout(() => {
      setIsFirstLoad(false);
    }, 1000);

    detectAndWatchForTouchSupport();

    const disposeDemoRequestSuccessListener =
      listenForDemoRequestSuccessEvents();

    (function initSearch() {
      if (siteContext.location.pathname === "/contact-support") return;
      if (siteContext.location.pathname === "/partners") return;
      if (siteContext.location.pathname === "/partners/directory") return;
      if (siteContext.location.pathname.match(/^\/library/)) return;
      const urlParams = getUrlQueryParams();
      const s = urlParams.s;
      const redirectedFrom404 = urlParams["redirected-from-404"]?.replace(
        /-/g,
        " "
      );
      if (s || redirectedFrom404) siteContext.setShouldShowGlobalSearch(true);
    })();

    captureAnalyticsEvent("visitedSite");

    return () => {
      disposeDemoRequestSuccessListener();
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      documentBodyStyleMutationObserver?.disconnect();
    };
  });

  return (
    <SiteContext.Provider value={siteContext}>
      <AlwaysOnThirdPartyScripts />
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <html
            className={cx(
              siteContext.supportsColorSchemes && "supports-color-schemes",
              scrolled && "scrolled"
            )}
            data-client={isClient}
            data-path={props.location.pathname}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </Helmet>
        <LayoutOuter
          className={cx(
            props.pageContext.simplifyHeader && "simpleHeader",
            props.pageContext.simplifyFooter && "simpleFooter"
          )}
        >
          <SiteNav />
          {siteContext.hasSidebar ? (
            <>
              <LayoutWithSidebar>
                <SiteSidebar
                  tree={props.pageContext.tree}
                  learningPath={props.pageContext.learningPath}
                />
                <Content>{props.children}</Content>
              </LayoutWithSidebar>
              <Spacing size="xl" />
            </>
          ) : (
            <Content>{props.children}</Content>
          )}
          <SiteFooter />
          {!isTinesComBuildOnMain && <PreviewModeTag />}
          {siteContext.library.storyToRenderInLightbox && <StoryLightbox />}
          <GlobalPortal id="globalPortal" />
          {isBrowser && <HashChangeAutoScrollController />}
          {shouldShowGlobalSearch && (
            <GlobalSearch
              autoFocus
              showOpenInNewTabButton
              showSuggestionsWhenEmpty
            />
          )}
          {shouldDisplayCookieConsentManager && <CookieConsentBanner />}
          {isDevelopment && <MockIntercomButton />}
          <AdminToolbar />
        </LayoutOuter>
      </QueryClientProvider>
    </SiteContext.Provider>
  );
};

const GlobalPortal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  left: 0;
  overflow: visible;
  z-index: ${zIndex("GlobalPortal")};
`;

const LayoutOuter = styled.div`
  position: relative;
`;

const Content = styled.div``;

export default SiteWrapper;
