export const SearchTwoToneIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path
      opacity="0.15"
      d="M17.3333 12C17.3333 14.9455 14.9455 17.3333 12 17.3333C9.05448 17.3333 6.66666 14.9455 6.66666 12C6.66666 9.05447 9.05448 6.66666 12 6.66666C14.9455 6.66666 17.3333 9.05447 17.3333 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 16C13.6122 16 16 13.6122 16 10.6667C16 7.72115 13.6122 5.33333 10.6667 5.33333C7.72115 5.33333 5.33333 7.72115 5.33333 10.6667C5.33333 13.6122 7.72115 16 10.6667 16ZM10.6667 17.3333C12.2675 17.3333 13.7366 16.7691 14.8858 15.8286L16.3349 17.2777C16.3197 17.5518 16.4168 17.831 16.6262 18.0404L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L18.0404 16.6262C17.831 16.4168 17.5518 16.3197 17.2777 16.3349L15.8286 14.8858C16.7691 13.7366 17.3333 12.2675 17.3333 10.6667C17.3333 6.98477 14.3486 4 10.6667 4C6.98477 4 4 6.98477 4 10.6667C4 14.3486 6.98477 17.3333 10.6667 17.3333Z"
    />
  </svg>
);
