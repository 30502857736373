import { styled } from "@linaria/react";

const WorkbenchAITypingIndicatorContainer = styled.div`
  height: 15px;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.1s;
  &.visible {
    opacity: 0.75;
  }
  > * {
    @keyframes Bounce {
      0% {
        transform: translateY(0);
      }
      25% {
        transform: translateY(-2px);
      }
      75% {
        transform: translateY(1px);
      }
      100% {
        transform: translateY(0);
      }
    }
    animation-name: Bounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  svg {
    @keyframes SvgRotate {
      0% {
        transform: none;
      }
      100% {
        transform: rotate(360deg);
      }
    }
    animation-name: SvgRotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-delay: inherit;
    transform-origin: center;
  }
`;
const RectGroup = styled.div``;
const CircleGroup = styled.div`
  animation-delay: 0.25s;
  margin-left: 2px;
`;
const TriangleGroup = styled.div`
  margin-left: 1px;
  animation-delay: 0.5s;
`;

export const WorkbenchAITypingIndicator = () => {
  return (
    <WorkbenchAITypingIndicatorContainer className="WorkbenchAITypingIndicator">
      <RectGroup>
        <svg width="5" height="7" viewBox="0 0 5 7" fill="none">
          <rect opacity="0.4" y="1" width="5" height="5" fill="currentColor" />
        </svg>
      </RectGroup>
      <CircleGroup>
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none">
          <circle
            opacity="0.4"
            cx="3.5"
            cy="3.5"
            r="2.75"
            fill="currentColor"
          />
        </svg>
      </CircleGroup>
      <TriangleGroup>
        <svg width="6" height="7" viewBox="0 0 6 7" fill="none">
          <path opacity="0.4" d="M3 0.5L0 6H6L3 0.5Z" fill="currentColor" />
        </svg>
      </TriangleGroup>
    </WorkbenchAITypingIndicatorContainer>
  );
};
