import { styled } from "@linaria/react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { CasesGridCanvasChildren, GridCanvas } from "../decorations/GridCanvas";
import { useState } from "react";

const CasesGridCanvasWithSpotlightContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  overflow: hidden;
`;

const CasesGridCanvasWithSpotlight = (p: {
  id: string;
  color?: string;
  parallax?: boolean;
  childrenTopLayer?: CasesGridCanvasChildren;
  childrenBottomLayer?: CasesGridCanvasChildren;
}) => {
  const [tick, setTick] = useState(0);
  const remount = () => {
    setTick(Math.random());
  };
  return (
    <CasesGridCanvasWithSpotlightContainer onDoubleClick={remount} key={tick}>
      <GridCanvas
        color={colorsV4.purple700}
        parallax={p.parallax}
        animateIn={!p.parallax}
        svgChildren={p.childrenBottomLayer}
      />
      <GridCanvas
        color={p.color}
        maskId={p.id}
        parallax={p.parallax}
        animateIn={!p.parallax}
        svgChildren={p.childrenTopLayer}
      />
    </CasesGridCanvasWithSpotlightContainer>
  );
};

export default CasesGridCanvasWithSpotlight;
