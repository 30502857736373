export const MailTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.3"
      d="M3 14L15 14L15 15C15 16.1046 14.1046 17 13 17L5 17C3.89543 17 3 16.1046 3 15L3 14Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.0001 5L14.0001 5C14.5524 5 15.0001 5.44772 15.0001 6L15.0001 6.20134L9.0001 9.43212L3.0001 6.20135L3.0001 6C3.0001 5.44772 3.44782 5 4.0001 5ZM2.0001 6.48951C1.99997 6.49622 1.99997 6.50291 2.0001 6.5096L2.0001 13C2.0001 14.1046 2.89553 15 4.0001 15L14.0001 15C15.1047 15 16.0001 14.1046 16.0001 13L16.0001 6.50919C16.0002 6.50278 16.0002 6.49635 16.0001 6.48992L16.0001 6C16.0001 4.89543 15.1047 4 14.0001 4L4.0001 4C2.89553 4 2.0001 4.89543 2.0001 6L2.0001 6.48951ZM15.0001 7.3371L15.0001 13C15.0001 13.5523 14.5524 14 14.0001 14L4.0001 14C3.44782 14 3.0001 13.5523 3.0001 13L3.0001 7.33711L8.76305 10.4402L9.0001 10.5679L9.23715 10.4402L15.0001 7.3371Z"
    />
  </svg>
);
