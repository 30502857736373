export const OrigamiIcon = () => (
  <svg width="109" height="115" viewBox="0 0 109 115" fill="none">
    <path
      d="M45.5292 4.45342C45.2994 5.03556 44.6354 5.70027 44.0532 5.92942L41.6365 6.88247C41.054 7.11203 41.054 7.48837 41.6365 7.71773L44.0532 8.67078C44.6354 8.90034 45.2994 9.56483 45.5292 10.147L46.482 12.5633C46.7118 13.1457 47.0877 13.1457 47.3172 12.5633L48.2701 10.147C48.4998 9.56483 49.1637 8.90034 49.746 8.67078L52.1627 7.71773C52.7452 7.48817 52.7452 7.11183 52.1627 6.88247L49.746 5.92942C49.1635 5.70027 48.4998 5.03556 48.2701 4.45342L47.3172 2.03686C47.0875 1.45451 46.7116 1.45451 46.482 2.03686L45.5292 4.45342Z"
      fill="#FDA2CB"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.78777 71.2055C7.41695 72.1451 6.34511 73.2179 5.40554 73.5878L1.50487 75.126C0.564651 75.4965 0.564651 76.104 1.50487 76.4742L5.40554 78.0124C6.34511 78.3829 7.41695 79.4555 7.78777 80.3951L9.32569 84.2952C9.69651 85.2351 10.3033 85.2351 10.6738 84.2952L12.2117 80.3951C12.5825 79.4555 13.654 78.3829 14.5939 78.0124L18.4945 76.4742C19.4348 76.1037 19.4348 75.4962 18.4945 75.126L14.5939 73.5878C13.6537 73.2179 12.5825 72.1451 12.2117 71.2055L10.6738 67.305C10.3029 66.3651 9.69619 66.3651 9.32569 67.305L7.78777 71.2055Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.6901 39.9889L108.06 26.8853L62.5927 59.1587L51.6901 39.9889Z"
      fill="#F0A848"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M60.4367 28.2836L102.719 13.0875L63.3563 58.6503L60.4367 28.2836Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.7674 60.7801L19.504 32.4917L19.4489 27.6868L54.8208 41.1791L43.7674 60.7801Z"
      fill="#F0A848"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M61.3015 52.7786L80.9458 93.6108L58.044 84.8704L61.3015 52.7786Z"
      fill="#E49307"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M65.4771 72.9931L37.4808 57.2749L60.4524 28.1422L65.4771 72.9931Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M63.2442 51.8977L81.3794 93.5317L59.6659 88.9878L63.2442 51.8977Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.1099 32.4443C31.615 32.5237 10.0697 40.3856 10.0697 40.3856L19.45 27.6868L32.1099 32.4443Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.1123 33.4524L23.8149 37.4307L22.6327 36.2333L30.1123 33.4524Z"
      fill="#483C72"
    />
    <path
      d="M56.152 114.026C38.3546 114.026 23.9269 113.167 23.9269 112.108C23.9269 111.049 38.3546 110.19 56.152 110.19C73.9493 110.19 88.377 111.049 88.377 112.108C88.377 113.167 73.9493 114.026 56.152 114.026Z"
      fill="#483C72"
      stroke="#483C72"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M60.907 75.8773L58.807 74.477L59.5072 70.0435L61.374 70.7433L60.907 75.8773Z"
      fill="#483C72"
    />
  </svg>
);
