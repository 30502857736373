import { styled } from "@linaria/react";
import {
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";

const UptoTabletMd = styled.div`
  ${fromTabletMd} {
    display: none;
  }
`;
export const FromTabletMd = styled.div`
  ${uptoTabletMd} {
    display: none;
  }
`;

export default UptoTabletMd;
