import { styled } from "@linaria/react";
import { cx } from "linaria";
import React, { PropsWithChildren, ReactNode, useRef } from "react";
import {
  fromDesktop,
  fromDesktopLg,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import PageOutline from "../basic/PageOutline";
import { hideScrollbarsCSS } from "../../utils/scroll.utils";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";
import { LayoutWithSidebarWrap } from "../layout/LayoutWithSidebar";
import { font } from "../../styles/fonts.styles";
import { StandardArticleContainer } from "../articles/StandardArticle";

const HubPageHeaderWrap = styled.header`
  padding-top: 0.5em;
  padding-bottom: ${rSize("xl")};
  h1 {
    font-family: ${font("serif")};
    font-weight: 400;
    font-size: 3.8rem;
    letter-spacing: -0.01em;
    ${fromTablet} {
      font-size: 4.2rem;
    }
    ${fromDesktop} {
      font-size: 4.6rem;
      line-height: 1.125;
    }
    code {
      word-break: break-all;
    }
  }
  p {
    a {
      ${inParagraphLinkStyle()}
    }
  }
`;

const HubPageHeaderInner = styled.div`
  max-width: 90rem;
  > * {
    + * {
      margin-top: 1.8rem;
    }
  }
  .centered & {
    margin-left: auto;
    margin-right: auto;
  }
  .fullWidth & {
    max-width: unset;
  }
`;

export const HubPageHeader = (
  props: PropsWithChildren<{
    className?: string;
    fullWidth?: boolean;
    centered?: boolean;
  }>
) => {
  return (
    <HubPageHeaderWrap
      className={cx(
        props.className,
        props.centered && "centered",
        props.fullWidth && "fullWidth"
      )}
    >
      <HubPageHeaderInner>{props.children}</HubPageHeaderInner>
    </HubPageHeaderWrap>
  );
};

export const WiderPageBodyWrap = styled.div`
  padding-bottom: 2em;
  ${LayoutWithSidebarWrap} & {
    ${fromTablet} {
      padding-left: 0;
      padding-right: 0;
    }
  }
  ${fromTablet} {
    padding-bottom: ${rSize("widerPageMargin")};
  }
  ${StandardArticleContainer} {
    ${fromDesktopMd} {
      font-size: 1.6rem;
    }
  }
  &.largerText {
    ${StandardArticleContainer} {
      ${fromDesktopMd} {
        font-size: 1.8rem;
      }
    }
  }
  &.hasRightColumn {
    ${fromTabletMd} {
      display: grid;
      grid-gap: ${rSize("gap", 2)};
      grid-template-columns: minmax(0, 1fr) 16rem;
      align-items: start;
      ${widerPageSectionMaxWidthStyle()};
      &.wider {
        ${widerPageSectionMaxWidthStyle()};
      }
    }
    ${fromDesktop} {
      grid-template-columns: minmax(0, 1fr) 22.5rem;
    }
    ${fromDesktopLg} {
      grid-template-columns: minmax(0, 1fr) 28rem;
    }
  }
`;

export const WiderPageBodyInner = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .fullWidth & {
    ${widerPageSectionMaxWidthStyle()};
  }
  .wider & {
    ${widerPageSectionMaxWidthStyle()};
  }
`;

export const WiderPageBodyOutlineAside = styled.aside`
  font-weight: 500;
  font-size: 1.6rem;
  position: sticky;
  overflow: auto;
  ${uptoTabletMd} {
    display: none;
  }
  ${fromTablet} {
    font-size: 1.4rem;
    top: calc(var(--pageTopFixedElementsHeight, 0px) + 2em);
    max-height: calc(100vh - var(--pageTopFixedElementsHeight, 0px) - 2em);
    transition: top 0.25s, max-height 0.25s;
    .largerGapOnTop & {
      top: calc(var(--pageTopFixedElementsHeight, 0px) + 4em);
      max-height: calc(100vh - var(--pageTopFixedElementsHeight, 0px) - 4em);
    }
  }
  ${fromDesktop} {
    font-size: 1.6rem;
  }
`;

export const WiderArticlePageBody = (
  props: React.PropsWithChildren<{
    className?: string;
    generateTableOfContents?: boolean;
    addRightMargin?: boolean;
    fullWidth?: boolean;
    wider?: boolean;
    largerText?: boolean;
    includeLevelsInToc?: ("h2" | "h3" | "h4" | "h5" | "h6")[];
    tocMainHeading?: string;
    supportsColorThemes?: boolean;
    outlineAsideHeader?: ReactNode;
  }>
) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <WiderPageBodyWrap
      className={cx(
        (props.generateTableOfContents ||
          props.addRightMargin ||
          props.includeLevelsInToc) &&
          "hasRightColumn",
        props.fullWidth && "fullWidth",
        props.wider && "wider",
        props.largerText && "largerText",
        props.className
      )}
      ref={ref}
    >
      <WiderPageBodyInner>{props.children}</WiderPageBodyInner>
      {(props.generateTableOfContents || props.includeLevelsInToc) && (
        <WiderPageBodyOutlineAside className={hideScrollbarsCSS}>
          {props.outlineAsideHeader}
          <PageOutline
            mainHeading={props.tocMainHeading}
            bodyRef={ref}
            includeLevels={props.includeLevelsInToc}
            highlightCurrentHeadings
            fullWidthLinks
            supportsColorThemes={props.supportsColorThemes}
          />
        </WiderPageBodyOutlineAside>
      )}
    </WiderPageBodyWrap>
  );
};
