import StandardIconSet from "../components/icons/standard/_index";
import { colors } from "../styles/colors.styles";

export type ActionTypeName = keyof typeof ACTION_TYPES;

export const ACTION_TYPES = {
  webhook: {
    name: "Webhook",
    slug: "webhook",
    color: colors.teal,
    border: colors.teal600,
    Icon: StandardIconSet.webhook,
    description:
      "Kicks off a workflow when something happens in an external system.",
  },
  httpRequest: {
    name: "HTTP request",
    slug: "httpRequest",
    color: colors.blue400,
    border: colors.blue,
    Icon: StandardIconSet.httpRequest,
    description:
      "Send or receive data from another system at any point in your workflow.",
  },
  receiveEmail: {
    name: "Receive email",
    slug: "receiveEmail",
    color: colors.red400,
    border: colors.red,
    Icon: StandardIconSet.receiveEmail,
    description:
      "Use an incoming email to initiate a workflow, or as a data source to bring into your processes.",
  },
  sendEmail: {
    name: "Send email",
    slug: "sendEmail",
    color: colors.pink400,
    border: colors.pink,
    Icon: StandardIconSet.sendEmail,
    description: "Share information or outcomes from your workflow via email.",
  },
  ai: {
    name: "AI",
    slug: "ai",
    color: colors.yellow300,
    border: colors.yellow,
    Icon: StandardIconSet.ai,
    description:
      "Run an LLM within the context of your workflow in a private and secure environment.",
  },
  transform: {
    name: "Transform",
    slug: "transform",
    color: colors.orange400,
    border: colors.orange,
    Icon: StandardIconSet.transform,
    description: "Structure and manipulate data at any point in your workflow.",
  },
  trigger: {
    name: "Trigger",
    slug: "trigger",
    color: colors.green400,
    border: colors.green,
    Icon: StandardIconSet.trigger,
    description:
      "Make decisions based on the data in your workflow before continuing.",
  },
  sendToStory: {
    name: "Send to story",
    slug: "sendToStory",
    color: colors.purple,
    border: colors.purple600,
    Icon: StandardIconSet.sendToStory,
    description: "Reuse a workflow by nesting it within other workflows.",
  },
} as const;

export const actionTypeNames = Object.keys(ACTION_TYPES) as ActionTypeName[];
export const actionTypeDefs = Object.values(ACTION_TYPES);
