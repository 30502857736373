const TransformIcon20 = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0333 2.17409C12.9198 1.98831 12.7177 1.875 12.5 1.875C12.2823 1.875 12.0803 1.98831 11.9667 2.17409L8.81097 7.33804C8.24944 7.11976 7.6387 7 7 7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17C9.71964 17 11.9321 14.8287 11.9985 12.125H18C18.2261 12.125 18.4346 12.0029 18.5451 11.8057C18.6557 11.6085 18.6512 11.367 18.5333 11.1741L13.0333 2.17409ZM11.8729 10.875H16.8856L12.5 3.69858L9.91136 7.93456C10.8841 8.63237 11.596 9.67061 11.8729 10.875ZM9.07835 8.81821C9.88064 9.34334 10.4722 10.1637 10.6988 11.125H7.66865L9.07835 8.81821ZM8.41166 8.47085L6.68002 11.3045C6.60929 11.4202 6.60657 11.5651 6.67293 11.6834C6.73928 11.8017 6.86435 11.875 7 11.875H10.798C10.7993 11.9165 10.8 11.9582 10.8 12C10.8 14.0987 9.09868 15.8 7 15.8C4.90132 15.8 3.2 14.0987 3.2 12C3.2 9.90132 4.90132 8.2 7 8.2C7.49884 8.2 7.97523 8.29612 8.41166 8.47085Z"
      />
    </svg>
  );
};

export default TransformIcon20;
