import { styled } from "@linaria/react";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

const ColumnedAndAvoidBreaksInParagraphs = styled.div<{ columns?: number }>`
  > * {
    + * {
      margin-top: 1em;
    }
  }
  ${fromTablet} {
    columns: ${p => p.columns ?? 2};
    column-gap: ${rSize("md")};
    text-align: left;
    > * {
      break-inside: avoid;
    }
  }
`;

export const Columned = styled.div<{ columns?: number }>`
  > * {
    + * {
      margin-top: 1em;
    }
  }
  ${fromTablet} {
    columns: ${p => p.columns ?? 2};
    column-gap: ${rSize("md")};
    text-align: left;
  }
`;

export default ColumnedAndAvoidBreaksInParagraphs;
