/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { styled } from "@linaria/react";
import SEO from "../components/utilities/SEO";
import {
  fromDesktop,
  fromTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";

import {
  LayoutWithSidebar,
  LayoutWithSidebarContent,
} from "../components/layout/LayoutWithSidebar";
import StandardSidebar from "../components/layout/StandardSidebar";
import Spacing from "../components/layout/Spacing";
import { color } from "../styles/colors.styles";
import LegalLink from "../components/legal/LegalLink";
import { Paths } from "../utils/pathBuilders.utils";
import { LegalIcon } from "../components/illustrations/spots/LegalIcon";
import { useSiteContext } from "../context/site.context";
import { resolveAfter } from "../utils/promises.utils";
import { preventWidows } from "../utils/typography.utils";
import OnlyPhonesDiv from "../components/mediaQueries/OnlyPhones";
import { Separator } from "../components/utilities/Hr";
import { Link } from "@reach/router";

const Section = styled.div`
  + * {
    margin-top: ${rSize("lg")};
  }
`;

const Heading = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  + * {
    margin-top: 0.5em;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: ${color("750")};
  + * {
    margin-top: ${rSize("md")};
  }
`;

const LegalGridSection = styled.div<{ columnCount?: number }>`
  display: grid;
  grid-gap: ${rSize("gap")};
  align-items: stretch;
  ${fromTabletLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktop} {
    grid-template-columns: ${p =>
      `repeat(${p.columnCount ?? 3}, minmax(0, 1fr))`};
  }
`;

const PageLegal: PageComponent<{}> = props => {
  const description = "Find all of our terms and policies here.";

  const {
    cookieConsentState,
    setCookieConsentState,
    setShouldDisplayCookieConsentManager,
  } = useSiteContext();
  const openCookieConsentManager = async () => {
    setShouldDisplayCookieConsentManager(false);
    setCookieConsentState({
      ...cookieConsentState,
      hasReviewed: true,
    });
    await resolveAfter();
    setShouldDisplayCookieConsentManager(true);
  };

  const structure = [
    {
      heading: "Data and privacy",
      description:
        "The security and privacy of our users’ and customers’ data is our highest priority.",
      links: [
        {
          title: "Privacy notice",
          description: "How we handle your data.",
          link: Paths.privacy(),
        },
        {
          title: "Data deletion or update",
          description: "Make a request relating to your data.",
          link: Paths.gdprRequest(),
        },
        {
          title: "Sub-processors",
          description: "Who processes your data on our behalf.",
          link: Paths.subProcessors(),
        },
        {
          title: "Cookies policy",
          description: "Which cookies we use, and why.",
          link: Paths.cookiesPolicy(),
        },
        {
          title: "Cookie settings",
          description: "Manage your cookie settings.",
          onClick: openCookieConsentManager,
        },
      ],
    },
    {
      heading: "General terms",
      description:
        "These are the basic terms for all users. Select the option that applies to your usage or contract.",
      links: [
        {
          title: "Community Edition",
          description: "For users of our forever-free plan.",
          link: Paths.termsCE(),
        },
        {
          title: "Public sector customers",
          description: "For US-based public sector customers.",
          link: Paths.termsPublicSector(),
        },
        {
          title: "All other customers",
          description: "For all other paying customers.",
          link: Paths.termsGeneral(),
        },
      ],
    },
    {
      heading: "Specific offering terms",
      description:
        "These additional terms apply based on where your Tines tenant is hosted, or if you engage us for professional services",
      links: [
        {
          title: "Cloud services addendum",
          description: "For customers hosted on the Tines cloud.",
          link: Paths.cloudServiceAddendum(),
        },
        {
          title: "Self-hosted addendum",
          description:
            "For customers who self-host on their own infrastructure.",
          link: Paths.selfHostedAddendum(),
        },
        {
          title: "Professional services addendum",
          description:
            "For customers who have purchased one of our services offerings.",
          link: Paths.professionalServicesAddendum(),
        },
      ],
    },
    {
      heading: "Supplementary terms",
      description:
        "These terms only apply if explicitly mentioned in your general or specific offering terms, or in your order form.",
      links: [
        {
          title: "Support services policy",
          description: "For cloud & self-hosted customers.",
          link: Paths.supportServicesPolicy(),
        },
        {
          title: "Support reference guide",
          description: "For cloud & self-hosted customers.",
          link: Paths.supportReferenceGuide(),
        },
        {
          title: "Information security addendum",
          description: "For cloud & self-hosted customers.",
          link: Paths.informationSecurityAddendum(),
        },
        {
          title: "Data processing addendum",
          description: "For cloud customers.",
          link: Paths.dataProcessingAddendum(),
        },
        {
          title: "Service level agreement",
          description: "For cloud customers.",
          link: Paths.serviceLevelAgreement(),
        },
        {
          title: "Marketing reference agreement",
          description: "For reference customers.",
          link: Paths.marketingReferenceAgreement(),
        },
        {
          title: "Fair use policy",
          description: "For cloud customers.",
          link: Paths.fairUsePolicy(),
        },
      ],
    },
    {
      heading: "Partner terms",
      description:
        "For strategic partners such as resellers and service providers.",
      links: [
        {
          title: "MSP agreement",
          description:
            "For managed service providers using Tines for the benefit of third parties.",
          link: Paths.mspAgreement(),
        },
        {
          title: "One-time reseller agreement",
          description: "For limited resellers of Tines’ offerings.",
          link: Paths.oneTimeResellerAgreement(),
        },
      ],
    },
  ];

  return (
    <Page {...props}>
      <SEO title="Legal" description={description} />
      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <LegalIcon />
          <h1>Legal</h1>
          <p>{preventWidows(description)}</p>
          <p>
            <Link to="/legal-prior-versions">See prior versions.</Link>
          </p>
        </StandardSidebar>

        <LayoutWithSidebarContent>
          <OnlyPhonesDiv>
            <Separator margin="1em 0 3em" />
          </OnlyPhonesDiv>
          {structure.map(({ heading, description, links }) => (
            <Section key={heading}>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <LegalGridSection>
                {links.map(({ title, description, link, onClick }) => (
                  <LegalLink
                    key={title}
                    title={title}
                    link={link}
                    description={description}
                    onClick={onClick}
                  />
                ))}
              </LegalGridSection>
            </Section>
          ))}
        </LayoutWithSidebarContent>
      </LayoutWithSidebar>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageLegal;
