import { styled } from "@linaria/react";
import { ClockWithBell } from "./ClockWithBell";
import HeroCursorSet from "./HeroCursorSet";
import { useState } from "react";

const ClockPositioner = styled.div`
  position: absolute;
  right: 18px;
  top: -26px;
  z-index: 5;
`;

const ClockAndBellAndCursors = () => {
  const [dragBellFn, setDragBellFn] = useState<(() => Promise<void>) | null>(
    null
  );
  return (
    <>
      <ClockPositioner>
        <ClockWithBell
          onReady={fn => {
            setDragBellFn(fn);
          }}
        />
      </ClockPositioner>
      {dragBellFn && <HeroCursorSet dragBellFn={dragBellFn} />}
    </>
  );
};

export default ClockAndBellAndCursors;
