import PlaceholderImage from "../../images/placeholder-1200-630.png";
import StaticImageFrame from "../basic/StaticImageFrame";

type Props = {
  className?: string;
  borderRadius?: string | number;
};
const PlaceholderImageFrame = (props: Props) => {
  return (
    <StaticImageFrame
      src={PlaceholderImage}
      className={props.className}
      borderRadius={props.borderRadius}
    />
  );
};

export default PlaceholderImageFrame;
