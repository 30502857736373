import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { tint } from "polished";
import React, { ReactNode, createElement } from "react";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
  uptoDesktop,
  uptoDesktopMd,
  uptoTabletLg,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  CommonColorNameV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import LoadingIndicator from "../utilities/LoadingIndicator";
import { brandColorThemeVar, color, colors } from "../../styles/colors.styles";

export type ButtonAppearance =
  | "outlined"
  | "filled"
  | "filled-white"
  | "transparent";

export type ButtonProps = {
  name?: string;
  className?: string;
  to?: string;
  href?: string;
  title?: string;
  children?: ReactNode;
  type?: HTMLButtonElement["type"];
  target?: HTMLAnchorElement["target"];
  style?: React.CSSProperties;
  padding?: string | number;
  width?: string | number;
  fullWidth?: boolean;
  rel?: string;
  onClick?: () => void | Promise<void>;
  borderRadius?: string | number;
  height?: string | number;
  disabled?: boolean;
  appearance?: ButtonAppearance;
  color?: BrandColorNameV4 | CommonColorNameV4 | string;
  download?: boolean;
  darker?: boolean;
  loading?: boolean;
  allowWrap?: boolean;
  asSpan?: boolean;
  dataTest?: string;
};

const buttonWrapper = css`
  // force safari to use this value
  appearance: none !important;
  display: inline-flex;
  align-items: stretch;
  justify-content: stretch;
  color: inherit;
  text-decoration: none;
  border: none;
  background: transparent;
  padding: 0;
  border-radius: 0;
  user-select: none;
  font-size: 1.4rem;
  &.fullWidth {
    display: flex;
  }
`;

const ButtonTextSpan = styled.span`
  display: block;
  transition: opacity 0.1s;
`;

const ButtonInner = styled.span<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  flex: 1 1 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: ${p => valueWithOptionalUnit(p.borderRadius, ".5em")};
  width: 100%;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  min-height: 2.75em;
  min-width: 6.5em;
  line-height: 1;
  padding: ${p => p.padding ?? ".75em"};
  font-size: inherit;
  &.allowWrap {
    white-space: normal;
  }
  &.filled {
    background-color: ${p =>
      p.color
        ? color(p.color, p.darker ? 800 : 500)
        : brandColorThemeVar(p.darker ? 800 : 500)};
    color: ${colors.white};
    border-color: transparent;
    &:hover {
      filter: brightness(1.1);
    }
  }
  &.transparent {
    color: ${p => (p.color ? color(p.color, 600) : "var(--ac, inherit)")};
    border-color: transparent;
    &:hover {
      background-color: ${p =>
        p.color
          ? withOpacity(color(p.color, 500), 0.1)
          : brandColorThemeVar(10)};
    }
  }
  &.filled-white {
    background-color: ${colors.lightest};
    color: ${p => color(p.color, 700)};
    border-color: transparent;
    &:hover {
      background-color: ${withOpacity(colors.lightest, 0.9)};
    }
  }
  &.outlined {
    background-color: transparent;
    color: ${p =>
      p.color
        ? color(p.color, p.darker ? 800 : 500)
        : brandColorThemeVar(p.darker ? 800 : 500)};
    border-color: currentColor;
    &:hover {
      background-color: ${p =>
        p.color
          ? withOpacity(color(p.color, 500), 0.1)
          : brandColorThemeVar(10)};
    }
  }
  ${fromTablet} {
    padding: ${p => p.padding ?? "0 1em"};
  }
  &:focus {
    box-shadow: 0 0 2px 2px ${tint(0.1, colors.green)};
  }
  &:active {
    filter: brightness(0.9);
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.loading {
    opacity: 0.8;
    pointer-events: none;
    ${ButtonTextSpan} {
      opacity: 0;
    }
  }
`;

const Button = (props: ButtonProps) => {
  const tag = props.asSpan
    ? "span"
    : props.target || props.href
    ? "a"
    : "button";
  const onClick = (e: React.MouseEvent) => {
    if (props.disabled) return;
    if (props.href?.[0] === "#") {
      e.preventDefault();
      scrollToHash({ useHash: props.href });
    }
    props.onClick?.();
  };
  const commonAttr = {
    className: cx(
      "Button",
      props.className,
      buttonWrapper,
      props.fullWidth && "fullWidth"
    ),
    children: (
      <ButtonInner
        className={cx(
          "ButtonInner",
          props.appearance ?? "filled",
          props.loading && "loading",
          props.allowWrap && "allowWrap"
        )}
        color={props.color}
        darker={props.darker}
        borderRadius={props.borderRadius}
        width={props.width}
        fullWidth={props.fullWidth}
        padding={props.padding}
        disabled={props.disabled}
      >
        <ButtonTextSpan>{props.children ?? props.title}</ButtonTextSpan>
        {props.loading && <LoadingIndicator centered size={24} />}
      </ButtonInner>
    ),
    name: props.name,
    title: props.title,
    type: props.type ?? (tag === "button" ? "button" : undefined),
    target: props.target,
    href: props.href,
    rel: props.rel,
    disabled: props.disabled,
    download: props.download,
    onClick,
    "data-test": props.dataTest,
    style: {
      width: valueWithOptionalUnit(
        props.width ?? (props.fullWidth ? "100%" : "auto")
      ),
      height: props.height ? valueWithOptionalUnit(props.height) : undefined,
    },
  };
  return props.to && !props.disabled ? (
    <Link {...commonAttr} to={props.to} />
  ) : (
    createElement(tag, {
      ...commonAttr,
    })
  );
};

export const ButtonGroup = styled.div`
  ${uptoTabletMd} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > * + * {
      margin-top: 0.5em;
    }
  }
  ${fromTabletMd} {
    > * + * {
      margin-left: 0.5em;
    }
  }
`;
export const ButtonGroupKeepFlexOnMobile = styled.div`
  display: flex;
  justify-content: start;
  > * + * {
    margin-left: 0.5em;
  }
`;
export const ButtonGroupCenteredOnMobile = styled.div`
  ${onlyPhones} {
    display: flex;
    justify-content: center;
    > * {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
  ${fromTablet} {
    > * + * {
      margin-left: 0.5em;
    }
  }
`;

export const ButtonGroupCenteredUptoTabletMd = styled.div`
  ${uptoTabletMd} {
    display: flex;
    justify-content: center;
    > * {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
  ${fromTabletMd} {
    > * + * {
      margin-left: 0.5em;
    }
  }
`;

export const ButtonGroupCenteredUptoTabletLg = styled.div`
  ${uptoTabletLg} {
    display: flex;
    justify-content: center;
    > * {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
  ${fromTabletLg} {
    > * + * {
      margin-left: 0.5em;
    }
  }
`;

export const ButtonGroupCenteredUptoDesktop = styled.div`
  ${uptoDesktop} {
    display: flex;
    justify-content: center;
    > * {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
  ${fromDesktop} {
    > * + * {
      margin-left: 0.5em;
    }
  }
`;

export const ButtonGroupCenteredUptoDesktopMd = styled.div`
  ${uptoDesktopMd} {
    display: flex;
    justify-content: center;
    > * {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
  ${fromDesktopMd} {
    > * + * {
      margin-left: 0.5em;
    }
  }
`;

export const ButtonGroupCenterFlex = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
`;

export const ButtonGroupStackedOnMobile = styled.div<{
  color?: ButtonProps["color"];
}>`
  ${uptoTabletMd} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    max-width: 20em;
    margin-left: auto;
    margin-right: auto;
    > * {
      ${ButtonInner} {
        border-radius: 1em 1em 0 0;
        display: flex;
        justify-content: space-between;
        text-align: left;
        &:after {
          content: "→";
        }
        &.outlined {
          border-color: ${p =>
            p.color ? withOpacity(color(p.color, 500), 0.5) : "currentColor"};
          &:hover {
            border-color: currentColor;
          }
        }
      }
      + * {
        margin-top: -2px;
        ${ButtonInner} {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          &.outlined {
            border-color: ${p =>
              p.color ? withOpacity(color(p.color, 500), 0.5) : "currentColor"};
            border-top-color: transparent;
            &:hover {
              border-color: currentColor;
            }
          }
        }
      }
      &:last-child {
        ${ButtonInner} {
          border-radius: 0 0 1em 1em;
        }
      }
    }
  }
  ${fromTabletMd} {
    > * + * {
      margin-left: 1em;
    }
  }
`;

export default Button;
