import { styled } from "@linaria/react";
import { cx } from "linaria";
import { DatoCmsPerson, DatoCmsProductUpdate } from "../../../graphql-types";
import PersonIconWithToolTip, {
  PersonIconWithToolTipContainer,
} from "../general/PersonIconWithToolTip";

type Props = {
  entry: DatoCmsProductUpdate;
};

const HubProductUpdateContributorIconList = (props: Props) => {
  const contributors = (props.entry.contributors ?? []) as DatoCmsPerson[];
  const size = contributors.length > 6 ? 24 : 32;
  return !!contributors.length ? (
    <HubProductUpdateContributorIconListWrap
      className={cx(size === 24 && "withMaxWidth")}
    >
      {contributors.map(c => (
        <PersonIconWithToolTip person={c} key={c.id} size={size} />
      ))}
    </HubProductUpdateContributorIconListWrap>
  ) : null;
};

export const HubProductUpdateContributorIconListWrap = styled.div`
  ${PersonIconWithToolTipContainer} {
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    padding-left: 0.1em;
    padding-right: 0.1em;
  }
  &.withMaxWidth {
    max-width: 275px;
  }
`;

export default HubProductUpdateContributorIconList;
