import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";

export const EventBubble = styled.div`
  background-color: var(--ac);
  color: ${colors.white};
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  padding: 0.3em 0.6em 0.25em;
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  border-radius: 2em;
  font-feature-settings: "tnum" 1;
`;
