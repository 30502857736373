import { styled } from "@linaria/react";
import {
  DatoCmsContentCard,
  DatoCmsContentCardsSection,
} from "../../../../graphql-types";
import {
  fromDesktopSm,
  fromPhoneLg,
  fromTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import PageSection from "../../reusableSections/PageSection";
import { ContentCardDispatcher } from "../../contentCards/_ContentCardDispatcher";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopSm} {
    grid-template-columns: repeat(4, 1fr);
  }
  > * {
    min-height: 22.5rem;
    ${fromPhoneLg} {
      min-height: 30rem;
    }
    ${fromDesktopSm} {
      min-height: 34rem;
    }
  }
`;

export const LPContentCardsSection = ({
  section,
}: {
  section: DatoCmsContentCardsSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Grid>
        {section.cards?.map((c, i) => (
          <ContentCardDispatcher card={c as DatoCmsContentCard} key={i} />
        ))}
      </Grid>
    </PageSection>
  );
};
