import ComboFontHeading from "../../../typography/ComboFontHeading";
import PillLegacy from "../../../basic/PillLegacy";
import ReportCard from "../../ReportCard";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import DonutChart from "../../charts/DonutChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import Spacing from "../../../layout/Spacing";
import ColumnedAndAvoidBreaksInParagraphs from "../../../layout/Columned";
import BarChart from "../../charts/BarChart";
import { sumOfArray } from "../../../../utils/math.utils";
import ReportChartContainer from "../../ReportChartContainer";

const MHChapter1 = () => {
  return (
    <ReportSection
      id="general-physical-health"
      themeName="purple"
      header={
        <>
          <PillLegacy color="purple">Part One</PillLegacy>
          <ComboFontHeading
            snippets={[
              [
                <>
                  General
                  <br />
                </>,
              ],
              [<>physical health</>, "serif"],
            ]}
          />
          <p>
            Glasses of water per day. Hours of sleep per night. Getting up from
            your desk to walk around. Eating healthy. Good physical health is a
            necessity not only for overall well-being, but better physical
            health can also improve mental health. To begin, respondents told us
            how they would rank their current physical health.
          </p>
        </>
      }
    >
      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>
          Half rank themselves as being in excellent or very good
          physical health
        </h3>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            Respondents rate themselves as in pretty good physical health, with
            half (50.1%) saying they’re in excellent physical health (25.6%) or
            very good physical health (24.5%). The remainder said their health
            was good (17.3%), fair (14.8%), or poor (17.7%).
          </p>
          <p>
            In looking at the breakdown between regions, 46.8% of European
            respondents say they’re either in excellent physical health (23%) or
            very good physical health (23.8%). 53.6% of US respondents say
            they’re either in excellent physical health (28.3%) or very good
            physical health (25.3%).
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
        <Spacing size="1em" />
        <ReportChartContainer centered>
          <h4>How would you rate your physical health?</h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Excellent", 263],
              ["Very good", 252],
              ["Good", 178],
              ["Fair", 152],
              ["Poor", 182],
            ]}
            donutPercentage={0.375}
            height={325}
            colors={[
              colorsV4.purple,
              colorsV4.green,
              colorsV4.pink,
              colorsV4.orange,
              colorsV4.warmBlack,
            ]}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>
          42% are getting the recommended eight hours of sleep or more per night
        </h3>
        <p>
          Respondents are somewhat scattered on the amount of sleep they get per
          night. While the largest segment (17.5%) said they sleep seven hours
          each night, 11.5% are only getting four hours of sleep, while 13.5%
          are getting ten hours of sleep. Overall, 41.9% are getting the
          recommended eight hours of sleep per night, or more. The remaining
          58.1% are getting seven hours or fewer per night.
        </p>
        <Spacing size="1em" />
        <ReportChartContainer centered>
          <h4>
            Please estimate the average number of hours you sleep on a
            nightly basis:
          </h4>
          <BarChart
            header={["Hours", "Respondents"]}
            data={[
              ["10 hr", 139],
              ["9 hr", 135],
              ["8 hr", 156],
              ["7 hr", 180],
              ["6 hr", 144],
              ["5 hr", 155],
              ["4 hr", 118],
            ]}
            total={sumOfArray([139, 135, 156, 180, 144, 155, 118])}
            height={400}
            chartArea={{ top: 12, bottom: 38, right: 0, left: "15%" }}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportSummaryCard>
        <blockquote>
          <p>
            Considering responses are self-ranked, and therefore may be skewed
            higher, we found that about half consider themselves in either
            excellent or very good physical health.
          </p>
        </blockquote>
        <p>
          Additionally, 42% are getting the recommended daily eight hours of
          sleep or more per night. However, that means that half of respondents
          rank their physical health as good, fair, or poor, and 58% of
          respondents are sleeping seven hours or fewer per night. This, of
          course, can negatively impact work productivity and mental health.
        </p>
        <Spacing size="1em" />
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default MHChapter1;
