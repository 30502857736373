import { useState } from "react";
import { useOnMount } from "./lifeCycle.utils";

export const useDocumentDimensions = () => {
  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(768);
  useOnMount(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);
    });
    resizeObserver.observe(document.documentElement);
    return () => {
      resizeObserver.disconnect();
    };
  });
  return { documentWidth: width, documentHeight: height };
};

export function observeDocumentSize(
  callback: (width?: number, height?: number) => void
) {
  const target = document.documentElement; // Observing the entire document

  // Create a ResizeObserver to monitor size changes
  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.target === target) {
        const { width, height } = entry.contentRect;
        callback(width, height);
      }
    }
  });

  // Start observing the document's root element (html)
  resizeObserver.observe(target);

  // Optionally, return the observer to allow disconnection later if needed
  return resizeObserver;
}
