export const PlatformsIllustration = () => (
  <svg width="909" height="463" viewBox="0 0 909 463" fill="none">
    <path
      d="M485.28 329.09C477.83 329.09 471.32 333.04 467.66 338.94C464.01 333.04 457.49 329.09 450.04 329.09C442.59 329.09 436.08 333.04 432.42 338.94C428.77 333.04 422.25 329.09 414.8 329.09C403.35 329.09 394.07 338.37 394.07 349.82C394.07 361.27 403.35 370.55 414.8 370.55C422.25 370.55 428.77 366.6 432.42 360.7C436.07 366.6 442.59 370.55 450.04 370.55C457.49 370.55 464 366.6 467.66 360.7C471.31 366.6 477.83 370.55 485.28 370.55C496.73 370.55 506.01 361.27 506.01 349.82C506.01 338.37 496.73 329.09 485.28 329.09Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M745.83 336.66C768.38 336.66 786.66 318.38 786.66 295.83C786.66 273.28 768.38 255 745.83 255C723.28 255 705 273.28 705 295.83C705 318.38 723.28 336.66 745.83 336.66Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M863.991 117.11H615.971L488.311 290.36L492.791 302.1H740.801L871.461 125.45L863.991 117.11Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M736.331 290.36H488.311L615.971 117.11H863.991L736.331 290.36Z"
      fill="#88D0A3"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path d="M740.7 301.74L736.23 290" stroke="#483C72" strokeMiterlimit="10" />
    <path
      d="M705.923 262.488C712.736 255.271 715.407 246.728 711.889 243.407C708.371 240.086 699.996 243.244 693.183 250.461C686.37 257.678 683.7 266.221 687.218 269.542C690.736 272.863 699.111 269.705 705.923 262.488Z"
      fill="#483C72"
    />
    <path
      d="M713.421 236.15C716.101 245.54 710.661 255.33 701.271 258.01C691.881 260.69 682.091 255.25 679.411 245.86C676.731 236.47 685.861 204.04 685.861 204.04C685.861 204.04 710.741 226.76 713.411 236.15H713.421Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M809.721 98.2601L766.971 90.3101L759.011 133.06L767.321 144.44L810.081 152.4L818.041 109.64L809.721 98.2601Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M775.288 101.685L767.325 144.44L810.08 152.402L818.043 109.648L775.288 101.685Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M775.281 101.69L766.971 90.3101"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M809.451 152.43H780.861L763.971 174.53H792.551L809.451 152.43Z"
      fill="#483C72"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M620.83 169.01H372.81L245.15 342.26L249.63 354H497.65L628.31 177.36L620.83 169.01Z"
      fill="#EE86B7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M493.17 342.26H245.15L372.81 169.01H620.83L493.17 342.26Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M497.65 354L493.17 342.26"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M566.94 290.43L627.55 178.59L545.43 289.64L566.94 290.43Z"
      fill="#483C72"
    />
    <path
      d="M593.146 187.987C598.154 182.681 600.117 176.401 597.531 173.96C594.945 171.518 588.788 173.84 583.78 179.145C578.772 184.45 576.809 190.73 579.395 193.172C581.981 195.613 588.138 193.292 593.146 187.987Z"
      fill="#483C72"
    />
    <path
      d="M598.69 171.01C600.66 177.91 596.66 185.11 589.76 187.08C582.86 189.05 575.66 185.05 573.69 178.15C571.72 171.25 578.43 147.4 578.43 147.4C578.43 147.4 596.72 164.1 598.69 171.01Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M146.48 425.14C164.242 425.14 178.64 410.741 178.64 392.98C178.64 375.218 164.242 360.82 146.48 360.82C128.719 360.82 114.32 375.218 114.32 392.98C114.32 410.741 128.719 425.14 146.48 425.14Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M377.67 216.43H129.65L2 389.68L6.47 401.42H254.49L385.15 224.78L377.67 216.43Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M250.01 389.68H2L129.65 216.43H377.67L250.01 389.68Z"
      fill="#C1AAF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M320.061 342.25L380.671 230.41L298.551 341.47L320.061 342.25Z"
      fill="#483C72"
    />
    <path
      d="M115.04 343.191C123.859 333.85 127.315 322.792 122.761 318.493C118.207 314.194 107.366 318.281 98.548 327.623C89.7297 336.964 86.273 348.022 90.8272 352.321C95.3815 356.62 106.222 352.533 115.04 343.191Z"
      fill="#483C72"
    />
    <path
      d="M302.55 254.648C309.363 247.431 312.034 238.888 308.516 235.567C304.998 232.246 296.623 235.404 289.81 242.621C282.997 249.838 280.327 258.381 283.845 261.702C287.363 265.023 295.738 261.865 302.55 254.648Z"
      fill="#483C72"
    />
    <path
      d="M128.521 312.41L123.071 320.06L122.981 329.46L114.071 332.44L108.481 340L99.5205 337.17L90.5505 340L84.9605 332.44L76.0505 329.46L75.9705 320.06L70.5205 312.41L75.9705 304.76L76.0505 295.36L84.9705 292.38L90.5505 284.82L99.5205 287.65L108.481 284.82L114.071 292.38L122.981 295.36L123.071 304.76L128.521 312.41Z"
      fill="#88D0A3"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M287.37 195.88L298.03 188.7L300.21 201.37L313.06 201.83L307.37 213.35L317.5 221.28L306.12 227.27L309.66 239.63L296.93 237.79L292.53 249.86L283.32 240.89L272.65 248.07L270.47 235.4L257.63 234.94L263.31 223.41L253.19 215.49L264.56 209.5L261.03 197.14L273.75 198.98L278.16 186.91L287.37 195.88Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M254.491 401.42L250.011 389.68"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M215.671 312.132C218.504 307.225 218.769 302.073 216.263 300.627C213.757 299.18 209.428 301.985 206.595 306.892C203.762 311.799 203.497 316.95 206.003 318.397C208.509 319.844 212.838 317.039 215.671 312.132Z"
      fill="#483C72"
    />
    <path
      d="M459.102 248.002C461.936 243.095 462.201 237.944 459.694 236.497C457.188 235.05 452.86 237.855 450.026 242.762C447.193 247.669 446.928 252.82 449.434 254.267C451.941 255.714 456.269 252.909 459.102 248.002Z"
      fill="#483C72"
    />
    <path
      d="M668.362 189.112C671.195 184.205 671.46 179.054 668.954 177.607C666.448 176.16 662.119 178.965 659.286 183.872C656.453 188.78 656.188 193.931 658.694 195.378C661.2 196.825 665.529 194.02 668.362 189.112Z"
      fill="#483C72"
    />
    <path
      d="M719.42 58.3801L724.14 60.1601L729.79 56.3501L727.64 54.8201L719.42 58.3801Z"
      fill="#483C72"
    />
    <path
      d="M402.23 396.5C398.37 396.5 394.78 397.62 391.75 399.55C388.72 397.62 385.13 396.5 381.27 396.5C377.41 396.5 373.82 397.62 370.79 399.55C367.76 397.62 364.17 396.5 360.31 396.5C349.52 396.5 340.77 405.25 340.77 416.04C340.77 426.83 349.52 435.58 360.31 435.58C364.17 435.58 367.76 434.46 370.79 432.53C373.82 434.46 377.42 435.58 381.27 435.58C385.12 435.58 388.72 434.46 391.75 432.53C394.78 434.46 398.38 435.58 402.23 435.58C413.02 435.58 421.77 426.83 421.77 416.04C421.77 405.25 413.02 396.5 402.23 396.5Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M590.07 333.81C584.52 333.81 579.55 336.29 576.2 340.2C572.85 336.3 567.88 333.81 562.33 333.81C552.24 333.81 544.05 342 544.05 352.09C544.05 362.18 552.23 370.37 562.33 370.37C567.88 370.37 572.85 367.89 576.2 363.98C579.55 367.88 584.52 370.37 590.07 370.37C600.17 370.37 608.35 362.19 608.35 352.09C608.35 341.99 600.17 333.81 590.07 333.81Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M253.67 436.61C249.14 436.61 245.18 439.01 242.96 442.6C240.74 439.01 236.78 436.61 232.25 436.61C227.72 436.61 223.76 439.01 221.54 442.6C219.32 439.01 215.36 436.61 210.82 436.61C203.86 436.61 198.22 442.25 198.22 449.22C198.22 456.19 203.86 461.83 210.82 461.83C215.35 461.83 219.31 459.43 221.54 455.84C223.76 459.43 227.72 461.83 232.25 461.83C236.78 461.83 240.74 459.43 242.96 455.84C245.18 459.43 249.14 461.83 253.67 461.83C260.63 461.83 266.28 456.19 266.28 449.22C266.28 442.26 260.64 436.61 253.67 436.61Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M665.41 349.17C670.281 349.17 674.23 345.221 674.23 340.35C674.23 335.479 670.281 331.53 665.41 331.53C660.539 331.53 656.59 335.479 656.59 340.35C656.59 345.221 660.539 349.17 665.41 349.17Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M522.13 421.82C527.001 421.82 530.95 417.871 530.95 413C530.95 408.129 527.001 404.18 522.13 404.18C517.258 404.18 513.31 408.129 513.31 413C513.31 417.871 517.258 421.82 522.13 421.82Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M212.71 308.97C211.05 308.97 209.45 308.18 208.45 306.79C208.32 306.61 195.11 288.51 174.65 273.19C147.93 253.18 122.05 247.08 97.74 255.05L94.46 245.06C121.9 236.06 151.89 242.94 181.16 264.94C192.73 273.63 201.99 283 208.19 289.95C209.64 268.1 213.63 222.96 223.67 177.65C231.9 140.52 242.53 110.45 255.28 88.2799C271.78 59.5999 291.99 43.8699 315.36 41.5299C338.6 39.2099 360.79 48.4999 381.31 69.1599C397.23 85.1699 412.2 108.1 425.82 137.29C438.23 163.89 447.28 191.02 453 210.45C455.25 194.21 459.07 172.76 465.45 150.63C473.09 124.13 482.83 102.23 494.41 85.5399C509.28 64.1099 527.24 51.1499 547.81 47.0399C584.71 39.6599 617.58 60.7699 642.86 108.08C652.42 125.97 659.15 144.24 663.48 157.95C671.69 120.87 693.68 50.9099 744.24 38.6799L746.71 48.8999C685.74 63.6499 669.87 181.03 669.72 182.21C669.39 184.75 667.28 186.68 664.73 186.79C662.17 186.89 659.91 185.15 659.37 182.65C659.29 182.29 651.38 146.26 633.49 112.87C610.31 69.5699 582.17 50.8899 549.87 57.3599C509.01 65.5299 486.9 114.18 475.55 153.55C462.98 197.16 460.53 239.24 460.5 239.66C460.35 242.28 458.3 244.4 455.68 244.61C453.06 244.82 450.69 243.07 450.12 240.51C449.68 238.54 405.3 43.0999 316.4 52.0099C270.64 56.5899 246.29 124.1 233.93 179.94C220.22 241.81 217.97 303.3 217.94 303.91C217.86 306.15 216.37 308.1 214.22 308.76C213.72 308.91 213.2 308.99 212.69 308.99L212.71 308.97Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M206.07 287.73C206.07 287.73 214.61 297.45 217.97 303.89"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M452.13 207.29C452.13 207.29 459.21 231.27 460.53 239.65"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M662.69 155.5C662.69 155.5 668.39 173.67 669.74 182.2"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M723.41 28.53C724.37 28.64 724.86 31.3 724.49 34.47C724.13 37.64 723.05 40.13 722.09 40.02C721.13 39.91 707.78 32.56 707.78 32.56C707.78 32.56 722.45 28.42 723.41 28.53Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M753.35 44.81C752.23 44.51 751.95 41.91 752.73 39.02C753.51 36.12 755.05 34.02 756.17 34.32C757.29 34.62 775.07 45.02 775.07 45.02C775.07 45.02 754.47 45.11 753.35 44.81Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M742.17 60.25C754.657 60.25 764.78 50.1272 764.78 37.64C764.78 25.1529 754.657 15.03 742.17 15.03C729.682 15.03 719.56 25.1529 719.56 37.64C719.56 50.1272 729.682 60.25 742.17 60.25Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M741.46 19.54C741.87 20.63 739.92 22.36 737.11 23.42C734.3 24.47 731.69 24.44 731.28 23.36C730.87 22.27 728.99 1.76001 728.99 1.76001C728.99 1.76001 741.05 18.46 741.46 19.55V19.54Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M759.68 30.51C758.86 31.03 756.8 29.28 755.08 26.59C753.35 23.9 752.61 21.3 753.43 20.78C754.25 20.26 769.23 17.49 769.23 17.49C769.23 17.49 760.5 29.98 759.68 30.5V30.51Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M723.72 44.4401C724.46 43.8201 726.72 45.3101 728.77 47.7601C730.81 50.2101 731.87 52.7101 731.12 53.3301C730.38 53.9501 714.5 59.6601 714.5 59.6601C714.5 59.6601 722.97 45.0601 723.72 44.4401Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M741.09 57.8101C740.88 56.9601 743.31 55.6501 746.51 54.8701C749.71 54.0901 752.47 54.1501 752.67 55.0001C752.88 55.8501 749.48 67.1401 749.48 67.1401C749.48 67.1401 741.29 58.6601 741.09 57.8201V57.8101Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M753.23 38.6C753.23 38.6 747.96 43.5 744.79 43.5C741.62 43.5 739.05 40.93 739.05 37.76C739.05 34.59 741.62 32.02 744.79 32.02C747.96 32.02 753.23 38.6 753.23 38.6Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M100.76 248.6C101.6 251.42 100.37 254.27 98.0199 254.98C95.6599 255.68 93.0699 253.97 92.2299 251.15C91.3899 248.33 92.6199 245.47 94.9799 244.77C97.3399 244.07 99.9299 245.78 100.77 248.6H100.76Z"
      fill="#EADFF8"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M208.601 389.43H221.801L253.651 345.71H241.161L208.601 389.43Z"
      fill="#483C72"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M297.771 293.78H288.311L244.761 295.13L217.001 250.55H207.541L209.181 303.38L164.311 331.32H173.771L217.321 329.98L234.951 358.28H244.411L253.651 345.71L252.901 321.72L297.771 293.78Z"
      fill="#FA9589"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M226.601 329.69L244.411 358.28L253.651 345.71L252.901 321.72L297.771 293.78L244.941 295.42L217.001 250.55L218.631 303.38L173.771 331.32L226.601 329.69Z"
      fill="#FFB6AB"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M504.48 287.111C505.69 287.811 510.58 281.611 515.39 273.271C520.2 264.931 523.12 257.601 521.92 256.901C520.71 256.201 515.82 262.391 511.01 270.731C506.2 279.071 503.28 286.401 504.49 287.101L504.48 287.111Z"
      fill="#483C72"
    />
    <path
      d="M457.93 160.39C459.67 166.33 521.42 257.94 521.42 257.94C521.42 257.94 520.35 148.53 518.6 142.58C516.86 136.64 501.86 135.8 485.11 140.72C468.36 145.64 456.19 154.44 457.93 160.38V160.39Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M491.447 162.233C508.198 157.316 520.362 148.509 518.617 142.564C516.871 136.618 501.877 135.784 485.126 140.702C468.375 145.619 456.211 154.425 457.956 160.371C459.702 166.317 474.696 167.15 491.447 162.233Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeLinejoin="round"
    />
  </svg>
);
