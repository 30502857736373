import { styled } from "@linaria/react";
import { DatoCmsProduct, DatoCmsProductCategory } from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import { useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  products: DatoCmsProduct[];
  categories: DatoCmsProductCategory[];
  onSelectCategory: (category: DatoCmsProductCategory) => void;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ProductExplorerCategoriesCardGrid = (props: Props) => {
  return (
    <Grid>
      {props.categories.map(c => (
        <CategoryCard
          category={c}
          key={c.id}
          products={props.products.filter(p =>
            p.categories?.find(cat => cat && cat.id === c.id)
          )}
          onSelect={() => {
            props.onSelectCategory(c);
          }}
        />
      ))}
    </Grid>
  );
};

const CategoryCardWrap = styled.button`
  appearance: none;
  text-align: left;
  background-color: ${colors.purple100};
  color: ${colors.purple700};
  border: 1px solid ${colors.purple300};
  padding: 1em;
  ${fromTablet} {
    padding: 1.25em;
  }
  cursor: pointer;
  border-radius: ${rSize("radius")};
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(3.6em, auto) minmax(auto, 1fr);
  grid-gap: 0.5em;
  overflow: hidden;
  width: auto;
  h3 {
    font-size: 1.4rem;
    line-height: 1.2;
    ${fromDesktopMd} {
      font-size: 1.6rem;
    }
  }
  &:nth-child(1) {
    background-color: ${colors.purple100};
    color: ${colors.purple700};
    border-color: ${colors.purple300};
    &:hover {
      border-color: ${colors.purple600};
    }
  }
  &:nth-child(2) {
    background-color: ${colors.orange100};
    color: ${colors.orange700};
    border-color: ${colors.orange300};
    &:hover {
      border-color: ${colors.orange600};
    }
  }
  &:nth-child(3) {
    background-color: ${colors.green100};
    color: ${colors.green700};
    border-color: ${colors.green300};
    &:hover {
      border-color: ${colors.green600};
    }
  }
  &:nth-child(4) {
    background-color: ${colors.pink100};
    color: ${colors.pink700};
    border-color: ${colors.pink300};
    &:hover {
      border-color: ${colors.pink600};
    }
  }
  &:nth-child(5) {
    background-color: ${colors.green100};
    color: ${colors.green700};
    border-color: ${colors.green300};
    &:hover {
      border-color: ${colors.green600};
    }
  }
  &:nth-child(6) {
    background-color: ${colors.pink100};
    color: ${colors.pink700};
    border-color: ${colors.pink300};
    &:hover {
      border-color: ${colors.pink600};
    }
  }
  &:nth-child(7) {
    background-color: ${colors.purple100};
    color: ${colors.purple700};
    border-color: ${colors.purple300};
    &:hover {
      border-color: ${colors.purple600};
    }
  }
  &:nth-child(8) {
    background-color: ${colors.orange100};
    color: ${colors.orange700};
    border-color: ${colors.orange300};
    &:hover {
      border-color: ${colors.orange600};
    }
  }
  &:nth-child(9) {
    background-color: ${colors.pink100};
    color: ${colors.pink700};
    border-color: ${colors.pink300};
    &:hover {
      border-color: ${colors.pink600};
    }
  }
  &:nth-child(10) {
    background-color: ${colors.purple100};
    color: ${colors.purple700};
    border-color: ${colors.purple300};
    &:hover {
      border-color: ${colors.purple600};
    }
  }
`;

const CategoryProductIconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1em;
  margin-left: -2em;
  margin-right: -2em;
`;

const CategoryProductIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff5;
  border-radius: 50%;
  aspect-ratio: 1;
  img {
    width: 1.8rem;
    max-width: 50%;
  }
`;

const CategoryCard = (props: {
  category: DatoCmsProductCategory;
  products: DatoCmsProduct[];
  onSelect: () => void;
}) => {
  const [productsToShow, setProductsToShow] = useState<string[]>([]);
  useOnMount(() => {
    const randomizedProducts = [
      ...props.products.filter(p => !!p.icon?.url).map(p => p.icon!.url!),
    ].sort(() => Math.random() - 0.5);
    setProductsToShow(
      Array(15)
        .fill(null)
        .map((c, i) => {
          return randomizedProducts[i];
        })
    );
  });
  return (
    <CategoryCardWrap onClick={props.onSelect}>
      <h3>{props.category.name?.replace("&", "and")}</h3>
      <CategoryProductIconGrid>
        {productsToShow.map((url, i) => (
          <CategoryProductIcon key={i}>
            <img src={url} />
          </CategoryProductIcon>
        ))}
      </CategoryProductIconGrid>
    </CategoryCardWrap>
  );
};

export default ProductExplorerCategoriesCardGrid;
