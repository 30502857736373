export const LogoRecordedFuture = () => (
  <svg width="208" height="26" viewBox="0 0 208 26" fill="currentColor">
    <title>Recorded Future</title>
    <path d="M31.3823 4.26758H37.8347C41.8234 4.26758 43.9351 6.52494 43.9351 9.85158C43.9351 12.2278 42.7033 13.6535 40.6502 14.4851L44.5803 21.7325H41.0608L37.1894 14.4851H34.4912V21.7325H31.3823V4.26758ZM37.3654 11.8119C39.6531 11.8119 40.7089 11.1585 40.7089 9.37635C40.7089 7.83184 39.6531 6.94077 37.3067 6.94077H34.4325V11.8713L37.3654 11.8119Z" />
    <path d="M57.6021 14.9601C57.6021 10.98 60.007 8.18799 63.7611 8.18799C66.9286 8.18799 69.0989 10.0295 69.3336 12.8809H66.4007C66.2247 11.574 65.1102 10.6236 63.8198 10.683C61.9427 10.683 60.7109 12.2869 60.7109 14.9007C60.7109 17.5145 61.9427 19.1778 63.8198 19.1778C65.1102 19.2372 66.2247 18.2867 66.4007 16.9798H69.3336C69.0989 19.7718 67.0459 21.7322 63.8198 21.7322C59.8897 21.7322 57.6021 18.9402 57.6021 14.9601Z" />
    <path d="M70.0967 14.9601C70.0967 10.9206 72.5016 8.18799 76.3144 8.18799C80.1271 8.18799 82.5321 10.9206 82.5321 14.9601C82.5321 18.9996 80.1271 21.7322 76.3144 21.7322C72.5016 21.7322 70.0967 18.9996 70.0967 14.9601ZM79.3646 14.9601C79.3646 12.5839 78.3087 10.6236 76.2557 10.6236C74.2027 10.6236 73.1469 12.5245 73.1469 14.9601C73.1469 17.3957 74.1441 19.2372 76.2557 19.2372C78.3674 19.2372 79.3646 17.3957 79.3646 14.9601Z" />
    <path d="M83.3526 8.60404H86.2855V10.802H86.4028C86.8134 9.3763 88.1039 8.36642 89.5703 8.42583C89.9223 8.42583 90.2742 8.48523 90.6848 8.48523V11.3366C90.2156 11.2178 89.7463 11.1584 89.277 11.1584C87.7519 11.0396 86.4615 12.2277 86.3441 13.7722V14.0098V21.7324H83.2939L83.3526 8.60404Z" />
    <path d="M90.9785 15.198C90.9785 10.8021 93.3248 8.48528 96.3164 8.48528C97.9001 8.42588 99.3666 9.31695 100.012 10.8021H100.129V4.26758H103.179V21.7325H100.188V19.6533H100.012C99.5425 20.6038 98.5453 21.7325 96.3164 21.7325C93.3248 21.7325 90.9785 19.5345 90.9785 15.198ZM100.246 15.198C100.246 12.6436 99.1906 11.0397 97.1962 11.0397C95.2019 11.0397 94.146 12.7624 94.146 15.198C94.146 17.6336 95.2019 19.4751 97.1962 19.4751C99.1906 19.4751 100.246 17.7524 100.246 15.198Z" />
    <path d="M117.609 15.198C117.609 10.8021 119.955 8.48528 122.947 8.48528C124.53 8.42588 125.997 9.31695 126.642 10.8021H126.759V4.26758H129.81V21.7325H126.818V19.6533H126.642C126.173 20.6038 125.176 21.7325 122.947 21.7325C119.897 21.7325 117.609 19.5345 117.609 15.198ZM126.818 15.198C126.818 12.6436 125.762 11.0397 123.768 11.0397C121.774 11.0397 120.718 12.7624 120.718 15.198C120.718 17.6336 121.774 19.4751 123.768 19.4751C125.762 19.4751 126.818 17.7524 126.818 15.198Z" />
    <path d="M136.731 4.26758H147.818V6.94077H139.899V11.8119H147.055V14.4851H139.84V21.7325H136.731V4.26758Z" />
    <path d="M156.734 8.604H159.784V21.673H156.851V19.3562H156.734C156.147 20.8413 154.798 21.673 152.862 21.673C150.34 21.673 148.521 20.069 148.521 16.9206V8.604H151.572V16.4454C151.572 18.1087 152.569 19.178 154.035 19.178C155.56 19.178 156.792 17.9305 156.792 16.386C156.792 16.3266 156.792 16.2672 156.792 16.2078L156.734 8.604Z" />
    <path d="M168.172 11.0393H165.591V17.8113C165.591 19.0588 166.177 19.3559 166.999 19.3559C167.292 19.3559 167.585 19.2965 167.878 19.237L168.406 21.6726C167.82 21.8508 167.233 21.9696 166.588 21.9696C164.3 22.029 162.541 20.841 162.541 18.346V11.0393H160.722V8.66308H162.541V5.51465H165.591V8.66308H168.172V11.0393Z" />
    <path d="M177.792 8.604H180.842V21.673H177.909V19.3562H177.733C177.147 20.8413 175.798 21.673 173.862 21.673C171.34 21.673 169.58 20.069 169.58 16.9206V8.604H172.63V16.4454C172.63 18.1087 173.627 19.178 175.094 19.178C176.619 19.178 177.851 17.9305 177.851 16.386C177.851 16.3266 177.851 16.2672 177.851 16.2078L177.792 8.604Z" />
    <path d="M182.777 8.60404H185.71V10.802H185.828C186.238 9.3763 187.529 8.36642 188.995 8.42583C189.347 8.42583 189.758 8.48523 190.11 8.48523V11.3366C189.64 11.2178 189.171 11.1584 188.702 11.1584C187.177 11.0396 185.886 12.2277 185.769 13.7722V14.0098V21.7324H182.719L182.777 8.60404Z" />
    <path d="M202.311 6.94109C202.311 5.33717 203.601 4.03027 205.185 4.03027C206.769 4.03027 208.059 5.33717 208.059 6.94109C208.059 8.545 206.769 9.8519 205.185 9.8519C203.542 9.8519 202.311 8.545 202.311 6.94109ZM207.472 6.94109C207.472 5.63419 206.417 4.56491 205.126 4.56491C203.836 4.56491 202.78 5.63419 202.78 6.94109C202.78 8.24798 203.836 9.31726 205.126 9.31726C206.417 9.31726 207.472 8.24798 207.472 6.94109ZM204.188 5.57479H205.478C205.947 5.57479 206.299 5.93121 206.299 6.40645C206.299 6.70347 206.123 7.05989 205.83 7.1787L206.417 8.24798H205.889L205.419 7.29751H204.716V8.24798H204.246L204.188 5.57479ZM205.302 6.88168C205.537 6.88168 205.771 6.64406 205.771 6.40645C205.771 6.40645 205.771 6.40645 205.771 6.34704C205.771 6.10943 205.595 5.87181 205.361 5.87181H205.302H204.657V6.82228H205.302V6.88168Z" />
    <path d="M190.168 15.2574C190.168 11.218 192.573 8.48535 196.269 8.48535C199.436 8.48535 202.193 10.5051 202.193 15.0792V16.0297H193.16C193.16 18.2871 194.509 19.3563 196.445 19.3563C197.735 19.3563 198.791 19.0593 199.202 17.9306H202.193C201.665 20.188 199.671 21.7919 196.445 21.7919C192.573 21.7325 190.168 19.3563 190.168 15.2574ZM199.26 13.9506C199.319 12.3466 198.146 10.9803 196.562 10.8615C196.503 10.8615 196.445 10.8615 196.328 10.8615C194.626 10.8615 193.219 12.2278 193.219 13.9506H199.26Z" />
    <path d="M104.528 15.2574C104.528 11.218 106.933 8.48535 110.628 8.48535C113.796 8.48535 116.553 10.5051 116.553 15.0792V16.0297H107.519C107.519 18.2871 108.868 19.3563 110.804 19.3563C112.095 19.3563 113.151 19.0593 113.561 17.9306H116.553C116.025 20.188 114.03 21.7919 110.804 21.7919C106.933 21.7325 104.528 19.3563 104.528 15.2574ZM113.62 13.9506C113.678 12.3466 112.505 10.9803 110.922 10.8615C110.863 10.8615 110.804 10.8615 110.687 10.8615C108.986 10.8615 107.578 12.2278 107.578 13.9506H113.62Z" />
    <path d="M44.6978 15.2574C44.6978 11.218 47.1027 8.48535 50.7981 8.48535C53.9657 8.48535 56.7226 10.5051 56.7226 15.0792V16.0297H47.748C47.748 18.2871 49.0971 19.3563 51.0328 19.3563C52.3232 19.3563 53.3791 19.0593 53.7897 17.9306H56.7812C56.2533 20.188 54.259 21.7919 51.0328 21.7919C47.1027 21.7325 44.6978 19.3563 44.6978 15.2574ZM53.8483 13.9506C53.907 12.3466 52.7339 10.9803 51.1501 10.8615C51.0914 10.8615 51.0328 10.8615 50.9155 10.8615C49.2144 10.8615 47.8066 12.2278 47.8066 13.9506H53.8483Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4919 11.1582C12.436 11.1582 11.5562 12.0493 11.5562 13.1185C11.5562 14.1878 12.436 15.0789 13.4919 15.0789C14.5477 15.0789 15.4276 14.1878 15.4276 13.1185C15.4276 12.0493 14.5477 11.1582 13.4919 11.1582Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9303 11.1582C23.8745 11.1582 22.9946 12.0493 22.9946 13.1185C22.9946 14.1878 23.8745 15.0789 24.9303 15.0789C25.9862 15.0789 26.866 14.1878 26.866 13.1185C26.866 12.0493 25.9862 11.1582 24.9303 11.1582Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.93619 11.1582C0.880353 11.1582 0.000488281 12.0493 0.000488281 13.1185C0.000488281 14.1878 0.880353 15.0789 1.93619 15.0789C2.99203 15.0789 3.87189 14.1878 3.87189 13.1185C3.87189 12.0493 2.99203 11.1582 1.93619 11.1582Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8491 24.1078V18.3456C11.8491 17.4546 12.553 16.7417 13.4329 16.7417C14.3127 16.7417 15.0166 17.4546 15.0166 18.3456V24.1078C15.0166 24.9989 14.3127 25.7118 13.4329 25.7118C12.553 25.7712 11.8491 25.0583 11.8491 24.1078Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5977 21.1976V5.03966C17.539 4.1486 18.2429 3.37634 19.1228 3.31694C20.0026 3.25753 20.7652 3.97039 20.8238 4.86145V5.03966V21.1382V21.1976C20.7652 22.0887 20.0026 22.7422 19.1228 22.7422C18.3015 22.6827 17.6563 22.0293 17.5977 21.1976Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10059 21.1979V5.03995C6.10059 4.14889 6.80448 3.43604 7.68434 3.43604C8.56421 3.43604 9.2681 4.14889 9.2681 5.03995V21.1385V21.1979C9.2681 22.089 8.56421 22.8018 7.68434 22.8018C6.80448 22.8018 6.10059 22.089 6.10059 21.1979Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8491 7.59368V1.83146C11.8491 0.940391 12.553 0.227539 13.4329 0.227539C14.3127 0.227539 15.0166 0.940391 15.0166 1.83146V7.59368V7.65308C15.0166 8.54415 14.3127 9.257 13.4329 9.257C12.553 9.257 11.8491 8.54415 11.8491 7.59368Z"
    />
  </svg>
);
