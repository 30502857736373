import { Action, ActionLink, LinkRecord } from "../../../types/tines.types";

export const convertLinkRecordToLink = (
  record: LinkRecord,
  actions: Action[],
  index: number
) => {
  const s: ActionLink = {
    record,
    source: actions[record.sourceIdentifier],
    receiver: actions[record.receiverIdentifier],
    index,
  };
  return s;
};
