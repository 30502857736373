import { styled } from "@linaria/react";
import { ReactNode } from "react";
import {
  fromDesktop,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { PrimaryColorName, color } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { PillTagStrong } from "../basic/PillLegacy";
import { TooltipContainer } from "./InfoTooltip";

type Props = {
  icon?: ReactNode;
  color: PrimaryColorName;
  heading: ReactNode;
  description: ReactNode;
  details: ReactNode;
  ctaButton: ReactNode;
  tag: string;
};

type P = {
  color: PrimaryColorName;
};

const PricingPlanCardContainer = styled.div<P>`
  display: grid;
  position: relative;
  grid-auto-flow: "row";
  background-color: ${p => color(p.color, 100)};
  border: ${p => `1px solid ${color(p.color, 800)}`};
  border-radius: ${rSize("radius")};
  color: ${p => color(p.color, 800)};
  overflow: hidden;
  font-size: 1.4rem;
  ${fromTablet} {
    font-size: 1.6rem;
  }
  .Button {
    align-self: end;
    justify-self: start;
  }
`;

const Content = styled.div<P>`
  display: grid;
  grid-gap: ${rSize("gap")} 0.5em;
  grid-template-columns: minmax(0, 3fr) minmax(auto, 2fr);
  grid-template-areas: "heading icon" "description icon" "content content";
  padding: ${rSize("lg")};
  padding-top: calc(${rSize("lg")} + 1em);
  font-weight: 500;
`;

const IconContainer = styled.div`
  grid-area: icon;
  margin-top: ${12 * -0.25}rem;
  ${fromTabletLg} {
    margin-top: ${14.5 * -0.25}rem;
  }
  margin-right: -1rem;
  margin-bottom: 1em;
  grid-area: icon;
  text-align: right;
  svg {
    display: block;
    width: 12rem;
    height: 12rem;
    margin-left: auto;
    ${fromTabletLg} {
      width: 14.5rem;
      height: 14.5rem;
    }
  }
`;
const Heading = styled.h3`
  grid-area: heading;
  font-weight: 400;
  font-family: ${font("serif")};
  line-height: 1;
  font-size: 2.8rem;
  ${fromTablet} {
    font-size: 3.2rem;
  }
  ${fromDesktop} {
    font-size: 3.6rem;
    letter-spacing: -0.0075em;
  }
  span {
    display: block;
  }

  ${PillTagStrong} {
    margin-left: 0.5em;
    margin-bottom: 0.4rem;
    font-size: 1.2rem;
    ${uptoPhoneLg} {
      margin-top: 0.5em;
    }
    ${fromPhoneLg} {
      margin-top: -0.3em;
    }
  }
`;

const Tag = styled.span<P>`
  position: absolute;
  top: 0;
  left: ${rSize("lg")};
  padding: 0.5em 0.75em;
  background-color: ${p => color(p.color, 200)};
  color: ${p => color(p.color, 700)};
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 0 0 0.5em 0.5em;
`;

const Description = styled.div`
  grid-area: description;
  line-height: 1.25;
`;

const Details = styled.div`
  grid-area: content;
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1.6;
    > li {
      white-space: nowrap;
      display: inline-block;
      &:after {
        content: "•";
        margin-left: 0.5em;
        margin-right: 0.5em;
        opacity: 0.5;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
  ${TooltipContainer} {
    padding-bottom: 0.1em;
  }
`;

const Footer = styled.div`
  padding: 0 ${rSize("lg")} ${rSize("lg")};
  font-size: 1.6rem;
`;

const PricingPlanCard = (props: Props) => {
  return (
    <PricingPlanCardContainer color={props.color}>
      <Content color={props.color}>
        <IconContainer>{props.icon}</IconContainer>
        <Heading>{props.heading}</Heading>
        <Description>{props.description}</Description>
        <Details>{props.details}</Details>
        <Tag color={props.color}>{props.tag}</Tag>
      </Content>
      <Footer>{props.ctaButton}</Footer>
    </PricingPlanCardContainer>
  );
};

export default PricingPlanCard;
