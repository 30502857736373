import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  uptoDesktopMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";

const YesOrNoFieldContainer = styled.div`
  background-color: ${colorsV4.canvas700};
  padding: 1em;
  border: 1px solid ${colorsV4.canvas700};
  border-radius: 0.5em;
  overflow: hidden;
  text-align: left;
  font-size: 1.4rem;
  .DARK_MODE & {
    background-color: ${colorsV4.warmBlack900};
    border: 1px solid ${colorsV4.warmBlack};
  }
  ${fromDesktopMd} {
    padding: 0;
    display: grid;
    align-items: stretch;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-gap: 1em;
  }
  > p {
    font-weight: 500;
    line-height: 1.2;
    max-width: 100% !important;
    margin-left: 0 !important;
    font-size: inherit;
    ${uptoDesktopMd} {
      margin-bottom: 1em;
    }
    ${fromDesktopMd} {
      padding: 1em;
      align-self: center;
    }
  }
`;

const YesOrNoFieldOptionSet = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  overflow: hidden;
  ${uptoDesktopMd} {
    border-radius: 0.75em;
  }
  ${fromDesktopMd} {
    align-items: stretch;
  }
`;

const YesOrNoOption = styled.label`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  span {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colorsV4.canvas300};
    padding: 0.75em;
    min-width: 6em;
    .DARK_MODE & {
      background-color: ${colorsV4.warmBlack};
    }
    ${fromDesktopMd} {
      min-width: 4.5em;
    }
    font-weight: 500;
    text-align: center;
    color: ${colorsV4.grey};
    &:hover {
      color: ${colorsV4.warmBlack};
      .DARK_MODE & {
        color: ${colorsV4.white};
      }
    }
  }
  input[value="yes"]:checked + span {
    background-color: ${colorsV4.green};
    color: ${colorsV4.canvas300};
  }
  input[value="no"]:checked + span {
    background-color: ${colorsV4.warmBlack};
    color: ${colorsV4.canvas300};
    .DARK_MODE & {
      background-color: ${colorsV4.purple};
    }
  }
  input {
    opacity: 0;
    position: absolute;
  }
`;

const defaultOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
] as const;

export const YesOrNoField = (props: {
  question: string;
  value: string;
  setValue: (v: string) => void;
  options?: [
    { value: string; label: string },
    { value: string; label: string }
  ];
}) => {
  const { question, value, setValue } = props;
  const [option1, option2] = props.options ?? defaultOptions;
  return (
    <YesOrNoFieldContainer className="YesOrNoField">
      <p>{question}</p>
      <YesOrNoFieldOptionSet>
        <YesOrNoOption>
          <input
            type="radio"
            value={option1.value}
            checked={value === option1.value}
            onChange={e => setValue(e.target.value)}
            name={question}
          />
          <span>{option1.label}</span>
        </YesOrNoOption>
        <YesOrNoOption>
          <input
            type="radio"
            value={option2.value}
            checked={value === option2.value}
            onChange={e => setValue(e.target.value)}
            name={question}
          />
          <span>{option2.label}</span>
        </YesOrNoOption>
      </YesOrNoFieldOptionSet>
    </YesOrNoFieldContainer>
  );
};

export default YesOrNoField;
