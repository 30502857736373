export const readFileAsBase64String = (file: File) =>
  new Promise<{ type: string; base64: string }>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      if (!e.target?.result) throw Error("Failed to read file.");
      resolve({ type: file.type, base64: `${e.target.result}` });
    };
  });

export const readFileAsBinaryString = async (file: File) =>
  await file.arrayBuffer();

export const base64toBlob = (
  data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = Buffer.from(data, "base64").toString();
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
