import { CheckMarkIcons } from "./checkMark.icons";
import { SendEmailIcon } from "./sendEmail.icon";
import { TransformIcon } from "./transform.icon";
import { HttpRequestIcon } from "./httpRequest.icon";
import { ReceiveEmailIcon } from "./receiveEmail.icon";
import { SearchIcon } from "./search.icon";
import { SendToStoryIcon } from "./sendToStory.icon";
import { TriggerIcon } from "./trigger.icon";
import { WebhookIcon } from "./webhook.icon";
import { AIIcon } from "./ai.icon";

const StandardIconSet = {
  ...CheckMarkIcons,
  ...SendEmailIcon,
  ...TransformIcon,
  ...HttpRequestIcon,
  ...ReceiveEmailIcon,
  ...SearchIcon,
  ...SendToStoryIcon,
  ...TriggerIcon,
  ...WebhookIcon,
  ...AIIcon,
} as const;

export default StandardIconSet;

export type IconName = keyof typeof StandardIconSet;
