export const GreenCheckIcon = () => (
  <svg
    width="58"
    height="62"
    viewBox="0 0 58 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.6162 61.35C46.2543 61.35 57.3101 59.5701 57.3101 57.3743C57.3101 55.1786 46.2543 53.3986 32.6162 53.3986C18.9782 53.3986 7.92236 55.1786 7.92236 57.3743C7.92236 59.5701 18.9782 61.35 32.6162 61.35Z"
      fill="#11352E"
      stroke="#11352E"
      strokeWidth="0.784702"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.0594 54.9847C46.0594 52.738 37.4246 50.9161 26.7721 50.9161C16.1203 50.9161 7.48486 52.7378 7.48486 54.9847C7.48486 55.4116 7.48486 57.0081 7.48486 57.4353C7.48486 59.6823 16.1202 61.5041 26.7721 61.5041C37.4246 61.5041 46.0594 59.6825 46.0594 57.4353C46.0594 57.0079 46.0594 55.4114 46.0594 54.9847Z"
      fill="#88D0A3"
      stroke="#11352E"
      strokeWidth="0.784702"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.0594 54.9847C46.0594 57.2319 37.4246 59.0538 26.7721 59.0538C16.1203 59.0538 7.48486 57.2321 7.48486 54.9847C7.48486 52.738 16.1202 50.9161 26.7721 50.9161C37.4246 50.9161 46.0594 52.7378 46.0594 54.9847Z"
      fill="#D6EDD9"
      stroke="#11352E"
      strokeWidth="0.784702"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.1194 54.4523C33.1194 54.7698 31.2631 55.0271 28.9728 55.0271C26.6824 55.0271 24.8259 54.7698 24.8259 54.4523C24.8259 54.1353 26.6824 53.8776 28.9728 53.8776C31.2631 53.8776 33.1194 54.1353 33.1194 54.4523Z"
      fill="#11352E"
      stroke="#11352E"
      strokeWidth="0.784702"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.8811 53.5953C29.8811 54.7601 28.4882 54.945 26.7706 54.945C25.0526 54.945 23.6606 54.7601 23.6606 53.5953V28.2775C23.6606 27.1125 25.0526 26.1682 26.7706 26.1682C28.4882 26.1682 29.8811 27.1127 29.8811 28.2775V53.5953Z"
      fill="#D6EDD9"
      stroke="#11352E"
      strokeWidth="0.784702"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.5814 41.4731H23.5V38.3249L30 37L29.5814 41.4731Z"
      fill="#11352E"
    />
    <path
      d="M48.3427 1.49713C46.9329 0.568686 45.265 0.339872 43.7488 0.726254C43.1964 0.86687 40.3457 1.46951 39.5062 1.81933C38.4835 2.24604 37.5726 2.97188 36.9171 3.96749L27.1253 18.8385L21.0935 12.8442C19.7171 10.7533 17.2131 9.85236 14.9135 10.4133C14.3498 10.5507 11.1472 11.2162 10.1624 11.7334C9.99462 11.8215 9.82983 11.9186 9.66786 12.025C7.00909 13.776 6.27288 17.351 8.02342 20.0099L20.3039 35.4938C21.3187 37.0348 22.9465 37.9286 24.6531 38.0681C24.6548 38.0681 24.6567 38.0686 24.6583 38.0686C24.7363 38.075 24.8143 38.0794 24.8927 38.0828C24.9205 38.0839 24.9483 38.085 24.9755 38.0858C25.0282 38.0874 25.0806 38.0879 25.133 38.0879C25.1858 38.0879 25.2385 38.0874 25.2911 38.0858C25.3188 38.085 25.3465 38.0839 25.3739 38.0828C25.4521 38.0796 25.5299 38.075 25.6081 38.0686C25.6101 38.0686 25.6115 38.0681 25.6139 38.0681C25.6903 38.0618 25.7669 38.0538 25.8427 38.0447C26.6535 37.9457 30.5159 36.9807 31.1816 36.6466C32.0544 36.2087 32.8273 35.5426 33.403 34.6685L49.9868 9.4823C51.7378 6.8233 51.0016 3.24793 48.3427 1.49713Z"
      fill="#25A871"
      stroke="#11352E"
      strokeWidth="0.784702"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.9023 2.32236C42.2431 0.571557 38.6679 1.30775 36.9171 3.96707L25.1332 21.8632L17.6534 13.6693C15.9023 11.01 12.3274 10.2736 9.66787 12.0246C7.0091 13.7755 6.27287 17.3506 8.02341 20.0094L20.3039 35.4934C21.3187 37.0344 22.9465 37.9281 24.6531 38.0677C24.6548 38.0677 24.6567 38.0681 24.6583 38.0681C24.7363 38.0746 24.8143 38.079 24.8927 38.0824C24.9205 38.0835 24.9483 38.0846 24.9755 38.0854C25.0282 38.087 25.0806 38.0874 25.133 38.0874C25.1858 38.0874 25.2385 38.087 25.2911 38.0854C25.3188 38.0846 25.3465 38.0835 25.3739 38.0824C25.4521 38.0791 25.5299 38.0746 25.6081 38.0681C25.6101 38.0681 25.6115 38.0677 25.6139 38.0677C27.3203 37.928 28.948 37.0344 29.9629 35.4934L46.5466 10.3072C48.2976 7.64836 47.5617 4.07332 44.9023 2.32236Z"
      fill="#88D0A3"
      stroke="#11352E"
      strokeWidth="0.784702"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
