import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import {
  fromDesktop,
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PillLegacy, { PillTagStrong } from "../basic/PillLegacy";
import PlaceholderImageFrame from "../general/PlaceholderImageFrame";
import { EventDirectoryItem } from "../../types/helper.types";
import StaticImageFrame from "../basic/StaticImageFrame";
import EventDirectoryItemDates from "./EventDirectoryItemDates";
import EventDirectoryItemLocation from "./EventDirectoryItemLocation";

type Props = {
  item: EventDirectoryItem;
  featured?: boolean;
  showFeaturedTag?: boolean;
};

const linkStyle = css`
  text-decoration: none;
  width: 100%;
  > * {
    + * {
      margin-top: 2rem;
    }
  }
`;

const Inner = styled.div<Props>`
  position: relative;
  > * + * {
    ${uptoTablet} {
      margin-top: 2rem;
    }
    ${fromTablet} {
      margin-top: ${p => (p.featured ? 0 : "2rem")};
    }
  }
`;

const Header = styled.header`
  grid-area: header;
  > * + * {
    margin-top: 1rem;
  }
`;
const featuredEntryLink = css`
  ${fromTablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "image header";
    grid-gap: ${rSize("gap")};
    align-items: start;
    ${Header} {
      > * + * {
        margin-top: 1.6rem;
      }
    }
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "image image header";
  }
`;

const CoverImage = styled.div<Props>`
  grid-area: image;
  overflow: hidden;
  border-radius: 1em;
  ${fromTablet} {
    border-radius: ${p => (p.featured ? "1.5em" : "1em")};
  }
  transform: translateZ(0);
`;

const Heading = styled.h3<{ featured?: boolean }>`
  font-size: 2.2rem;
  line-height: ${p => (p.featured ? 1.15 : 1.2)};
  font-weight: 600;
  ${fromDesktop} {
    font-size: ${p => (p.featured ? "2.8rem" : "2.2rem")};
  }
  ${fromDesktopMl} {
    font-size: ${p => (p.featured ? "3.2rem" : "2.4rem")};
  }
`;

const EventDateTime = styled.p`
  font-weight: 600;
`;
const EvenLocation = styled.p`
  margin-bottom: -3px;
  color: ${withOpacity(colorsV4.warmBlack, 0.625)};
  font-weight: 600;
`;
const PillList = styled.div`
  margin-top: -0.25em;
  margin-left: -0.25em;
  > * {
    margin-top: 0.5em;
    margin-right: 0.5em;
  }
  ${PillTagStrong} {
    padding: 0.25em 0.75em;
  }
`;

const Excerpt = styled.div`
  line-height: 1.4;
  code {
    font-family: ${font("monospace")} !important;
    color: ${colorsV4.orange700};
    font-size: 95%;
    background-color: ${withOpacity(colorsV4.orange, 0.1)};
    padding: 0.1em 0.4em;
    border-radius: 0.35em;
  }
`;

const EventDirectoryItemLink = (props: Props) => {
  const { type, place, categories } = props.item;
  const isBootcamp = !!categories.find(c => c.slug === "bootcamp");
  const isVirtual =
    type === "webinar" || !!categories.find(c => c.slug === "virtual");
  return (
    <Link
      className={linkStyle}
      to={`/events/${props.item.slug ?? ""}`}
      title={props.item.name}
    >
      <Inner
        {...props}
        className={props.featured ? featuredEntryLink : undefined}
      >
        <CoverImage {...props}>
          {props.item.coverImageUrl ? (
            <StaticImageFrame
              src={props.item.coverImageUrl}
              aspectRatio="1200/630"
            />
          ) : (
            <PlaceholderImageFrame />
          )}
        </CoverImage>
        <Header>
          {isVirtual ? (
            <PillList>
              {isBootcamp ? (
                <PillLegacy color="green">Bootcamp</PillLegacy>
              ) : (
                type === "webinar" && <PillLegacy>Webinar</PillLegacy>
              )}
              <PillLegacy color="orange">Virtual</PillLegacy>
            </PillList>
          ) : (
            <PillLegacy>Event</PillLegacy>
          )}
          <Heading featured={props.featured}>
            {props.item.name ?? "Untitled Article"}
          </Heading>
          <EventDateTime>
            <EventDirectoryItemDates item={props.item} />
          </EventDateTime>
          {place && (
            <EvenLocation>
              <EventDirectoryItemLocation item={props.item} />
            </EvenLocation>
          )}
          <Excerpt>{props.item.excerpt}</Excerpt>
        </Header>
      </Inner>
    </Link>
  );
};

export default EventDirectoryItemLink;
