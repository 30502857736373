import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { rSize } from "../../styles/responsiveSizes.styles";
import Spacing from "../layout/Spacing";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import {
  fromTablet,
  fromTabletLg,
  onlyTablets,
} from "../../styles/breakpointsAndMediaQueries.styles";
import momentumIcon from "./illustrations/MomentumIcon.svg";
import shieldedBuildingIcon from "./illustrations/ShieldedBuildingIcon.svg";
import dartBoardIcon from "./illustrations/DartBoardIcon.svg";

const Header = styled.header`
  text-align: center;
  max-width: 33em;
  margin-left: auto;
  margin-right: auto;
`;

const Grid = styled.div`
  display: grid;
  padding-top: 2em;
  grid-gap: 5em;
  ${fromTablet} {
    padding-top: 1em;
    grid-gap: ${rSize("gap")};
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CardContent = styled.div`
  max-width: 15em;
  h3 {
    font-size: 2.6rem;
    font-family: ${serif};
    letter-spacing: -0.01rem;
    font-weight: 400;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 1.6rem;
    ${onlyTablets} {
      font-size: 1.4rem;
    }
  }
`;

const GridItem = styled.div`
  &:nth-child(3) {
    ${CardContent} {
      ${fromTabletLg} {
        max-width: 17em;
      }
    }
  }
`;

const Card = styled.div`
  display: grid;
  align-items: end;
  position: relative;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple300};
  padding: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  min-height: 20rem;
  ${fromTablet} {
    min-height: 24rem;
  }
`;

const CardIcon = styled.div`
  position: absolute;
  bottom: 100%;
  transform: translateY(3em);
  right: 2em;
  transform-origin: bottom right;
  ${onlyTablets} {
    transform: scale(0.8) translateY(3em);
  }
`;

export const ProServWhyTinesSection = () => {
  return (
    <PageSection>
      <Header>
        <SectionHeading2 lighter>Why professional services?</SectionHeading2>
        <Spacing />
        <p>
          The service isn't complete until you achieve your project outcomes.
          Uniquely positioned to set you up for success.
        </p>
      </Header>
      <Spacing size="sectionPadding" />
      <Grid>
        <GridItem>
          <Card>
            <CardContent>
              <h3>Momentum</h3>
              <p>
                Realize near immediate time to value with our professional
                services experts.
              </p>
            </CardContent>
            <CardIcon>
              <img
                src={momentumIcon}
                width={109}
                height={91}
                style={{ aspectRatio: 109 / 91 }}
              />
            </CardIcon>
          </Card>
        </GridItem>
        <GridItem>
          <Card>
            <CardContent>
              <h3>Transparency</h3>
              <p>
                Each initiative is tracked by our project management team with
                weekly milestone reports.
              </p>
            </CardContent>
            <CardIcon>
              <img
                src={shieldedBuildingIcon}
                width={101}
                height={95}
                style={{ aspectRatio: 101 / 95 }}
              />
            </CardIcon>
          </Card>
        </GridItem>
        <GridItem>
          <Card>
            <CardContent>
              <h3>Goal-oriented</h3>
              <p>
                Everything about the program, including any modifications, are
                aligned with your goals and timelines.
              </p>
            </CardContent>
            <CardIcon>
              <img
                src={dartBoardIcon}
                width={84}
                height={88}
                style={{ aspectRatio: 84 / 88 }}
              />
            </CardIcon>
          </Card>
        </GridItem>
      </Grid>
    </PageSection>
  );
};
