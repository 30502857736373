import { css } from "linaria";
import ContentCardBase from "./_ContentCardBase";
import { DatoCmsCaseStudy, DatoCmsContentCard } from "../../../graphql-types";
import { PrimaryColorName } from "../../styles/colors.styles";
import { stripCustomInlineFormattingSyntax } from "../typography/WithCustomInlineFormatting";
import ReactMarkdown from "react-markdown";
import {
  fromTablet,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { styled } from "@linaria/react";

export const ContentCardCaseStudyStyle = css`
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  ${fromTablet} {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 6px;
      bottom: 0;
      width: 4px;
      height: 100%;
      background-color: black;
      mix-blend-mode: color-burn;
      opacity: 0.1;
      .large & {
        left: 8px;
        width: 5px;
        opacity: 0.175;
      }
    }
  }
  &:after {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='36' height='56' viewBox='0 0 36 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 53.263V0H36V53.263C36 54.8604 34.2197 55.8131 32.8906 54.9271L19.1094 45.7396C18.4376 45.2917 17.5624 45.2917 16.8906 45.7396L3.1094 54.9271C1.78029 55.8131 0 54.8604 0 53.263Z' fill='%23000000'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    position: absolute;
    top: 0;
    mix-blend-mode: color-burn;
    opacity: 0.175;
    width: 20px;
    height: 32px;
    right: 16px;
    ${fromTablet} {
      width: 28px;
      height: 44px;
      right: 20px;
    }
    .large & {
      ${fromTabletMd} {
        width: 36px;
        height: 57px;
        right: 24px;
      }
    }
  }
  figure {
    img {
      max-width: 100%;
      height: auto;
      max-height: unset;
    }
  }
  &.compact {
    figure {
      max-width: 8em;
      justify-self: flex-end;
    }
  }
  &.large {
    figure {
      max-width: calc(100% - 3em);
      ${fromTablet} {
        max-width: calc(100% - 9em);
      }
    }
  }
`;

const Floater = styled.div`
  float: right;
  width: 2.5em;
  height: 1em;
`;

export const ContentCardCaseStudy = ({
  caseStudy,
  overrides,
  large,
}: {
  caseStudy: DatoCmsCaseStudy;
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  const color = (caseStudy.color?.toLowerCase() ??
    "purple") as PrimaryColorName;
  const logo =
    overrides?.colorMode === "light"
      ? caseStudy.customer?.logoDarkGray
      : caseStudy.customer?.logoWhite;
  return (
    <ContentCardBase
      className={ContentCardCaseStudyStyle}
      link={`/case-studies/${caseStudy.slug}`}
      color={color}
      variant={
        overrides?.colorMode === "light"
          ? "200"
          : color === "purple"
          ? "700"
          : "600"
      }
      large={large}
      size="1/3"
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Case study"}</strong>
          </p>
          <h4>
            <Floater />
            {overrides?.heading ||
              stripCustomInlineFormattingSyntax(caseStudy.title)}
          </h4>
          {overrides?.description && (
            <ReactMarkdown>{overrides.description}</ReactMarkdown>
          )}
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Read now"}</strong>
        </p>
      </header>
      <figure>
        {logo?.url && (
          <img
            src={logo.url}
            style={{ aspectRatio: `${logo.width}/${logo.height}` }}
          />
        )}
      </figure>
    </ContentCardBase>
  );
};
