import { css } from "linaria";
import { NumericRange } from "../types/helper.types";
import { rangesIntersect } from "./math.utils";
import gsap from "gsap";
import { scrollEase } from "./gsap.utils";

export const hideScrollbarsCSS = css`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export function getScrollParent(
  node?: HTMLElement | SVGElement
): HTMLElement | null {
  if (!node) return null;
  const isElement = node instanceof HTMLElement;

  if (isElement) {
    const overflowY = window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

    if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }
  }

  return node.parentNode
    ? getScrollParent(node.parentNode as HTMLElement) ?? document.body
    : document.body;
}

export const elementIsVisibleInScrollParent = (options: {
  el: HTMLElement | null;
  scrollParent?: HTMLElement | null;
  visibleHeightRangeOffsetTop?: number;
  visibleHeightRangeOffsetBottom?: number;
}) => {
  const { el } = options;
  if (!el) return false;
  const scrollParent = options.scrollParent ?? getScrollParent(el);
  if (!scrollParent) return false;
  const parentHeight =
    scrollParent === document.body
      ? window.innerHeight
      : scrollParent.clientHeight;
  const visibleHeightRange = [
    scrollParent.scrollTop + (options.visibleHeightRangeOffsetTop ?? 0),
    scrollParent.scrollTop +
      parentHeight +
      (options.visibleHeightRangeOffsetBottom ?? 0),
  ] as NumericRange;
  const childHeightRange = [
    el.offsetTop,
    el.offsetTop + el.clientHeight,
  ] as NumericRange;
  return rangesIntersect(visibleHeightRange, childHeightRange);
};

export const scrollIntoViewIfNotVisible = (
  el: HTMLElement,
  scrollParent?: HTMLElement | null
) => {
  if (elementIsVisibleInScrollParent({ el, scrollParent })) return;
  gsap.to(scrollParent ?? getScrollParent(el), {
    duration: 0.5,
    ease: scrollEase,
    scrollTo: {
      y: el.offsetTop - 100,
    },
  });
};

export const scrollToTop = (duration?: number) => {
  gsap.to(window, {
    duration: duration ?? Math.min(window.scrollY, 225) / 1000,
    ease: "expo.out",
    scrollTo: {
      y: 0,
    },
  });
};

export function getOffsetInScrollParent(_: HTMLElement | string | null) {
  const offset = { x: 0, y: 0 };
  if (!_) return offset;
  let el = typeof _ === "string" ? document.querySelector<HTMLElement>(_) : _;
  if (!el) return offset;
  let parent = el.offsetParent;
  while (parent !== null) {
    offset.x += el?.offsetLeft ?? 0;
    offset.y += el?.offsetTop ?? 0;
    el = parent as HTMLElement | null;
    parent = el?.offsetParent as HTMLElement | null;
  }
  return offset;
}
