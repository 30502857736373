import { styled } from "@linaria/react";
import { StoryDescriptor } from "../../types/helper.types";
import SolutionPageAllcapsHeading from "./SolutionPageAllcapsHeading";
import { Paths } from "../../utils/pathBuilders.utils";
import StoryEntry, { StoryEntryInner } from "../library/StoryEntry";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { rSize } from "../../styles/responsiveSizes.styles";
import Spacing from "../layout/Spacing";
import { colors } from "../../styles/colors.styles";
import { hideScrollbarsCSS } from "../../utils/scroll.utils";
import { ShadableOrAdjacentSectionSpacer } from "./SolutionPageSharedComponents";
import {
  fromDesktopLg,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { DatoCmsSolutionPage } from "../../../graphql-types";
import Button from "../forms/Button";
import { serif } from "../../styles/fonts.styles";
import MaxPageContentWidth from "../layout/MaxPageContentWidth";
import { useViewportSize } from "../../utils/window.utils";
import { useRef } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";

type Props = {
  page: DatoCmsSolutionPage;
  stories: StoryDescriptor[];
  padTop?: boolean;
};

const TopSpacer = styled.div`
  height: 3em;
`;

const Container = styled.div``;

const Inner = styled.div`
  padding: ${rSize("xl")} 0;
  ${mediaFromTo("phone", "phoneSm")} {
    ${StoryEntryInner} {
      aspect-ratio: 1/1;
    }
  }
  grid-auto-flow: column;
  grid-auto-columns: ${widthInGrid(4)};
  padding: ${rSize("xl")} 0;
  ${StoryEntryInner} {
    aspect-ratio: 1/1;
  }
`;

const Header = styled.header`
  text-align: center;
  p {
    ${onlyPhones} {
      font-size: 1.4rem;
      max-width: 20em;
    }
    max-width: 25em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const StoryGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${StoryEntryInner} {
    aspect-ratio: auto;
  }
  ${fromTabletMd} {
    grid-template-columns: 1fr 1fr;
  }
  ${fromDesktopMd} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${fromDesktopLg} {
    ${StoryEntryInner} {
      aspect-ratio: 1/1;
    }
  }
`;

const SolutionPageLibrarySection = (props: Props) => {
  const collection = props.page.libraryCollection;
  const link = collection?.slug
    ? Paths.library.collection(collection.collectionType, collection.slug)
    : Paths.library.home();
  return (
    <div>
      {props.padTop ? <ShadableOrAdjacentSectionSpacer /> : <TopSpacer />}
      <Container className={hideScrollbarsCSS}>
        <Inner>
          <MaxPageContentWidth>
            <Header>
              <LibraryIcon />
              <Spacing size="lg" />
              <SolutionPageAllcapsHeading>
                Tines library
              </SolutionPageAllcapsHeading>
              <Spacing size="md" />
              <p>
                Hundreds of ready-made examples of Tines in action: accelerate
                your build; or fuel your imagination.
              </p>
            </Header>
          </MaxPageContentWidth>
          <Spacing size="xxl" />
          {props.page.useCases && (
            <>
              <UseCaseKaleidoscope useCases={props.page.useCases} />
              <Spacing size="xxl" />
            </>
          )}
          <ButtonWrap>
            <Button darker to={link}>
              Explore the Library
            </Button>
          </ButtonWrap>
          <Spacing size="xxl" />

          <MaxPageContentWidth>
            <StoryGrid>
              {props.stories.map(s => (
                <StoryEntry story={s} key={s.id} />
              ))}
            </StoryGrid>
          </MaxPageContentWidth>
        </Inner>
      </Container>
    </div>
  );
};

const UseCaseKaleidoscopeWrap = styled.div`
  position: relative;
  overflow: hidden;
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
`;

const UseCaseKaleidoscope = (props: { useCases: string }) => {
  const lines = props.useCases.split("\n");
  return (
    <UseCaseKaleidoscopeWrap>
      {lines.map((line, i) => (
        <UseCaseLine key={i} string={line} index={i} />
      ))}
    </UseCaseKaleidoscopeWrap>
  );
};

const UseCaseLineWrap = styled.div`
  font-size: 2.4rem;
  font-family: ${serif};
  font-weight: 400;
  display: flex;
  justify-content: center;
  letter-spacing: -0.01em;
  ${fromTablet} {
    font-size: 2.8rem;
  }
  ${fromTabletLg} {
    font-size: 3.6rem;
  }
`;

const UseCaseLineInner = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
`;

const UseCaseEntryWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  white-space: nowrap;
  align-items: center;
`;

const UseCaseEntryIcon = styled.div`
  width: 0.25em;
  height: 0.25em;
  background-color: ${colors.purple100};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 0.75em;
`;

const UseCaseLine = (props: { string: string; index: number }) => {
  const words = props.string.split(/,\s/);
  const ref = useRef<HTMLDivElement>(null);
  const direction = props.index % 2 === 1 ? 1 : -1;
  const { vw } = useViewportSize();
  useOnMount(() => {
    const tween = gsap.fromTo(
      ref.current,
      {
        x: `${direction * 15 + props.index * 5}vw`,
      },
      {
        x: `${props.index * 5}vw`,
        scrollTrigger: {
          trigger: ref.current,
          scrub: 1,
          start: "top bottom",
          end: "bottom top",
          invalidateOnRefresh: true,
        },
      }
    );
    return () => {
      tween.kill();
    };
  });
  const renderWords = () =>
    words.map((w, i) => (
      <UseCaseEntryWrap key={i}>
        <UseCaseEntryIcon />
        <div>{w}</div>
      </UseCaseEntryWrap>
    ));
  return (
    <UseCaseLineWrap>
      <UseCaseLineInner ref={ref}>
        {renderWords()}
        {renderWords()}
        {vw >= 1920 && (
          <>
            {renderWords()}
            {renderWords()}
          </>
        )}
      </UseCaseLineInner>
    </UseCaseLineWrap>
  );
};

const LibraryIcon = () => (
  <svg
    width="110"
    height="110"
    viewBox="0 0 110 110"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M62.6843 96.4885H91.3619L100.478 86.9434H62.6843V96.4885Z"
      fill="#4D3E78"
      stroke="#4D3E78"
    />
    <path
      d="M74.5045 94.5013L74.6217 87.7414H89.9771C90.3259 87.7414 90.6644 87.6227 90.9368 87.4048V87.4048C91.3015 87.1132 91.5138 86.6716 91.5138 86.2047V12.4C91.5138 10.3086 89.8184 8.61328 87.7271 8.61328H30.945C30.945 8.61328 26.2802 9.52556 22.0703 13.0339C18.9712 15.6169 18.6121 17.9196 18.6121 17.9196V96.2502H79.8806L82.2355 94.5015L74.5045 94.5013Z"
      fill="#6359B3"
      stroke="#4D3E78"
    />
    <path
      d="M89.08 10.98H30.7797L23.05 15.7133L21.3933 91.92H81.9283L89.08 84.82V10.98Z"
      fill="#EDE9E3"
      stroke="#4D3E78"
    />
    <path
      d="M82.2355 19.6099C82.2355 17.5186 80.5401 15.8232 78.4488 15.8232H23.05L20.8391 16.6156L18.6121 17.9195V96.2499H79.8806L82.2355 94.1537V19.6099Z"
      fill="#6359B3"
    />
    <path
      d="M82.2355 19.6099C82.2355 17.5186 80.5401 15.8232 78.4488 15.8232H23.05L20.8391 16.6156L18.6121 17.9195V96.2499H79.8806L82.2355 94.1537V19.6099Z"
      fill="#6359B3"
    />
    <path
      d="M23.05 15.3232C22.7739 15.3232 22.55 15.5471 22.55 15.8232C22.55 16.0994 22.7739 16.3232 23.05 16.3232V15.3232ZM18.6121 96.2499H18.1121C18.1121 96.5261 18.336 96.7499 18.6121 96.7499V96.2499ZM79.8806 96.2499V96.7499C80.0032 96.7499 80.1215 96.7049 80.213 96.6234L79.8806 96.2499ZM82.2355 94.1537L82.5679 94.5271C82.6745 94.4323 82.7355 94.2964 82.7355 94.1537H82.2355ZM78.4488 15.3232H23.05V16.3232H78.4488V15.3232ZM18.1121 17.9195V96.2499H19.1121V17.9195H18.1121ZM18.6121 96.7499H79.8806V95.7499H18.6121V96.7499ZM80.213 96.6234L82.5679 94.5271L81.903 93.7802L79.5481 95.8765L80.213 96.6234ZM82.7355 94.1537V19.6099H81.7355V94.1537H82.7355ZM78.4488 16.3232C80.264 16.3232 81.7355 17.7947 81.7355 19.6099H82.7355C82.7355 17.2425 80.8163 15.3232 78.4488 15.3232V16.3232Z"
      fill="#4D3E78"
    />
    <path
      d="M77.9873 17.9194H18.6121V96.2501H77.9873C79.0329 96.2501 79.8806 95.4024 79.8806 94.3568V19.8128C79.8806 18.7671 79.0329 17.9194 77.9873 17.9194Z"
      fill="#9A8EF5"
      stroke="#4D3E78"
    />
    <path
      d="M60.961 75.1369C60.961 75.1369 60.961 55.2405 60.961 53.1066C60.961 50.9723 61.4196 49.0847 53.1657 45.4929C45.1326 41.9971 39.8542 45.7985 39.8542 45.7985L39.8542 75.3091L60.961 75.1369Z"
      fill="#FFE1B3"
    />
    <path
      d="M72.9867 23.7598H25.18C24.6572 23.7598 24.2333 24.1836 24.2333 24.7064V89.5531C24.2333 90.0759 24.6572 90.4998 25.18 90.4998H72.9867C73.5095 90.4998 73.9333 90.0759 73.9333 89.5531V24.7064C73.9333 24.1836 73.5095 23.7598 72.9867 23.7598Z"
      stroke="#FFAF7E"
      strokeWidth="2"
    />
    <path
      d="M31.4667 78.4341L27.8478 81.6231V87.2513L24.2289 90.4401V96.2503L20.6098 99.4393V105.55H54.0285L57.5992 102.403H57.6474L61.266 99.2149L64.8851 96.0261V78.4341H31.4667Z"
      fill="#81D8B4"
      stroke="#4D3E78"
    />
    <path
      d="M64.5599 79.082L61.0997 81.5786V87.6104L57.8254 90.281V95.5369L54.0344 99.4144L54.2068 105.532L64.805 96.0259L64.9641 80.003L64.5599 79.082Z"
      fill="#6359B3"
    />
    <path
      d="M53.9056 99.8237H20.7543V105.058H53.9056V99.8237Z"
      fill="#8D75E6"
    />
    <path
      d="M53.6038 99.4144H21.0342L24.6531 96.1401H57.3092L53.6038 99.4144Z"
      fill="#C2AAFA"
    />
    <path d="M54.0282 99.439H20.6098V105.55H54.0282V99.439Z" stroke="#4D3E78" />
    <path
      d="M57.2227 90.1947H24.6531L28.2717 86.9204H60.9281L57.2227 90.1947Z"
      fill="#C2AAFA"
    />
    <path
      d="M61.1433 81.6646H27.9918V86.8989H61.1433V81.6646Z"
      fill="#8D75E6"
    />
    <path
      d="M57.3307 90.6475H24.1791V95.8818H57.3307V90.6475Z"
      fill="#8D75E6"
    />
    <path d="M24.2289 90.4395H57.6034" stroke="#4D3E78" />
    <path
      d="M60.8418 81.4921H28.2717L31.8908 78.2183H64.5466L60.8418 81.4921Z"
      fill="#EB81AF"
    />
    <path d="M61.266 81.6226H27.8478" stroke="#4D3E78" />
    <path
      d="M64.8851 96.0259L73.2539 96.2501L54.0284 105.55L64.8851 96.0259Z"
      fill="#4D3E78"
      stroke="#4D3E78"
    />
    <path
      d="M31.4667 78.4341L27.8478 81.6231V87.2513L24.2289 90.4401V96.2503L20.6098 99.4393V105.55H54.0285L57.5992 102.403H57.6474L61.266 99.2149L64.8851 96.0261V78.4341H31.4667Z"
      stroke="#4D3E78"
    />
    <path
      d="M64.8851 78.4341L61.266 81.6231V87.2513L57.6473 90.4401V96.2503L54.0284 99.4393V105.55"
      stroke="#4D3E78"
    />
    <path d="M24.2289 96.25H57.6473" stroke="#4D3E78" />
    <path d="M27.8478 87.251H61.266" stroke="#4D3E78" />
    <path
      d="M60.5832 78.2181V75.3091L40.1736 75.3354L36.1773 78.2181H31.57L27.8478 81.4919H61.266L64.8851 78.2181H60.5832Z"
      fill="#FFD18C"
      stroke="#4D3E78"
    />
    <path
      d="M48.148 40.6299C38.9043 40.6299 31.4109 48.1235 31.4109 57.367L31.4109 78.2183H36.1773V57.9122C36.1773 51.3011 41.5369 45.9415 48.148 45.9415C54.7592 45.9415 60.1187 51.3011 60.1187 57.9122V78.2183H64.8851V57.367C64.8851 48.1235 57.3917 40.6299 48.148 40.6299Z"
      fill="#EADFF8"
      stroke="#4D3E78"
    />
    <path
      d="M40.1736 75.1165V49.3198C40.1736 49.3198 36.1774 51.5644 36.1774 59.0282C36.1774 66.4921 36.1774 77.9992 36.1774 77.9992L40.1736 75.1165Z"
      fill="#6359B3"
      stroke="#4D3E78"
    />
    <path
      d="M36.3908 57.5056L39.9446 55.7861L40.1736 49.3198C40.1736 49.3196 36.3908 52.3469 36.3908 57.5056Z"
      fill="#4D3E78"
    />
    <path d="M81.27 17.37L89.1252 11.0352" stroke="#4D3E78" />
  </svg>
);

export default SolutionPageLibrarySection;
