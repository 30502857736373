import { Link } from "gatsby";
import { cx } from "linaria";
import { PropsWithChildren } from "react";
import { useSiteContext } from "../../context/site.context";
import { VERSION_REGEX_MATCHER } from "../../templates/APIPage.template";

type Props = PropsWithChildren<{
  className?: string;
  to: string;
  exact?: boolean;
  onClick?: () => void;
}>;

const NavLink = ({ className, to, exact, onClick, children }: Props) => {
  const { pathname, hash } = useSiteContext().location;
  const regex = exact
    ? new RegExp(`^${to.replace(/\//g, "\\/")}$`)
    : new RegExp(`^${to.replace(/\//g, "\\/")}`);
  const path = `${pathname}${hash}`;
  // if path ends with version, remove to check if it is active
  const isVersioned = path.match(VERSION_REGEX_MATCHER);
  const truncatedPath = isVersioned
    ? path.split(`-${isVersioned[0]}`)[0]
    : path;
  const isActive = regex.test(truncatedPath.replace(/\/$/, ""));

  return (
    <Link
      className={cx(className, isActive && "active")}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default NavLink;
