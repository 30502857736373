import { styled } from "@linaria/react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { PNGIllustration } from "../illustrations/PNGIllustration";
import Spacing from "../layout/Spacing";
import TinesLogo from "../misc/TinesLogo";
import { Serif } from "../typography/Serif";
import BookADemoButton from "../utilities/BookADemoButton";
import CornerDotSet from "../utilities/CornerDotSet";
import { ReportBaseSectionStyle } from "./ReportBaseStyles";
import SignUpButton from "../utilities/SignUpButton";

const ReportTinesCTACardWrap = styled.div`
  ${ReportBaseSectionStyle}
  background-color: ${colorsV4.purple};
  color: ${colorsV4.canvas};
  position: relative;
  font-weight: 500;
  ${onlyPhones} {
    padding: 1em 1.5em 2em 1.5em;
    margin-top: 2em;
    border-radius: 1em;
  }
  ${fromTablet} {
    padding: 4em;
  }
  ${fromDesktopMd} {
    padding: ${widthInGrid(1)};
  }
`;

const Inner = styled.div`
  h2 {
    ${fluidFontSize(32, 42)};
    span {
      display: block;
    }
  }
  blockquote {
    padding: 0;
    margin: 1em;
    font-size: 1.8rem;
    ${fromTablet} {
      font-size: 2rem;
      margin: 1em 3em;
    }
    text-align: center;
    font-family: ${font("serif")};
    p {
      line-height: 1.5;
    }
  }
`;

const CTAGrid = styled.div`
  font-size: 1.6rem;
  ${onlyPhones} {
    text-align: center;
    padding-top: 3em;
    padding-bottom: 2em;
  }
  ${fromTablet} {
    display: grid;
    align-items: center;
    grid-gap: ${rSize("md")};
    grid-template-columns: auto auto;
    padding-bottom: 1em;
  }
`;

const CTAGridHeader = styled.header`
  ${onlyPhones} {
    margin-bottom: 2em;
  }
`;

const IllustrationHolder = styled.figure`
  text-align: right;
  margin: 0;
  padding: 0;
  ${onlyPhones} {
    width: 250px;
    min-width: 250px;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTablet} {
    min-width: 175px;
    max-width: 245px;
  }
  ${fromTabletMd} {
    min-width: 250px;
    max-width: 400px;
  }
  picture {
    ${fromTablet} {
      margin-top: -1em;
      transform: translateX(10%);
    }
  }
`;

const ButtonGroup = styled.div`
  white-space: nowrap;
  margin-top: 1em;
  > * {
    + * {
      margin-left: 0.5em;
      ${fromTablet} {
        margin-left: 1em;
      }
    }
  }
`;

const ReportTinesCTACard = () => {
  return (
    <ReportTinesCTACardWrap>
      <CornerDotSet
        color={withOpacity(colorsV4.canvas, 0.7)}
        offset={rSize("gap")}
      />
      <Inner>
        <CTAGrid>
          <CTAGridHeader>
            <TinesLogo />
            <Spacing size="2em" />
            <h2>
              <span>
                No-code automation <Serif>for</Serif>
              </span>{" "}
              <Serif>
                <span>security&nbsp;teams</span>
              </Serif>
            </h2>

            <Spacing size="1.25em" />
            <p>
              The world’s best companies – from startups to the Fortune 10 –
              trust Tines with their mission-critical security workflows.
            </p>
            <Spacing size="1.25em" />
            <ButtonGroup>
              <SignUpButton appearance="filled-white">Sign up</SignUpButton>
              <BookADemoButton appearance="outlined" color="white" />
            </ButtonGroup>
          </CTAGridHeader>
          <IllustrationHolder>
            <PNGIllustration name="elevator" />
          </IllustrationHolder>
        </CTAGrid>
      </Inner>
    </ReportTinesCTACardWrap>
  );
};

export default ReportTinesCTACard;
