import { styled } from "@linaria/react";
import { DatoCmsFeatureHighlight } from "../../../graphql-types";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import SmartLink from "../basic/SmartLink";
import { font } from "../../styles/fonts.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import {
  fromDesktopMd,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Card = styled.div`
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple300};
  border-radius: ${rSize("radius")};
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  overflow: hidden;
  grid-gap: ${rSize("gap")};
  width: 100%;
  max-width: 386px;
  ${onlyPhones} {
    margin-left: auto;
    margin-right: auto;
  }
  header {
    padding: ${rSize("gap")};
    h3 {
      font-family: ${font("serif")};
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 1.1;
      max-width: 12em;
      ${onlyPhones} {
        margin-top: 0.5em;
        margin-left: auto;
        margin-right: auto;
      }
      + p {
        margin-top: 0.5em;
      }
    }
    p {
      a {
        ${inParagraphLinkStyle("currentColor")}
      }
    }
  }
`;

const ImageContainer = styled.div`
  display: grid;
  height: 294px;
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;
  position: relative;
  ${fromTablet} {
    height: 250px;
  }
  ${fromDesktopMd} {
    height: 294px;
  }
  img {
    display: block;
    position: absolute;
    height: 100%;
    width: auto;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
`;

export const FeatureHighlightCard = ({
  card,
}: {
  card: DatoCmsFeatureHighlight;
}) => {
  return (
    <Card>
      <header>
        <WithCustomInlineFormatting as="h3">
          {card.title}
        </WithCustomInlineFormatting>
        {card.linkLabel && card.linkPath && (
          <p>
            <SmartLink src={card.linkPath}>{card.linkLabel} →</SmartLink>
          </p>
        )}
      </header>
      {card.image?.url && (
        <ImageContainer>
          <img src={card.image.url} alt="" />
        </ImageContainer>
      )}
    </Card>
  );
};
