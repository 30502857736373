import ReportIntroSection from "../../ReportIntroSection";

const MHIntroSection = () => {
  return (
    <ReportIntroSection
      blockquote={
        <p>Security teams are not immune from mental health issues.</p>
      }
    >
      <p>
        In my fifteen years of being a security practitioner, working on
        incident response and leading security teams, I saw firsthand how
        burnout impacted my team. The increased pressures of the past few years,
        remote work, the talent shortage, job duty frustrations, and other
        challenges are creating environments where mental health is
        wearing thin.
      </p>
      <p>
        But what are team leaders doing to increase the conversation around
        mental health and provide resources and tools to their staff in order
        for them to do their best work? Are security team members managing their
        mental health so that they’re unencumbered by the frustrations of their
        job? Or are they burdened with their workplace’s rising stress?
      </p>
      <p>
        Since May is Mental Health Awareness Month, we wanted to better
        understand the current state of mental health and wellness amongst
        cybersecurity practitioners. We surveyed 1,027 members of security teams
        from both the United States and Europe to examine their daily struggles,
        how they rank their mental health, and if they’re approaching workplace
        situations and scenarios in healthy ways.
      </p>
      <p>
        Security leaders want to see their teams succeed and thrive. Let these
        findings help you better understand the current state of your team’s
        mental health and wellness so you can make proactive improvements this
        Mental Health Awareness Month.
      </p>
    </ReportIntroSection>
  );
};

export default MHIntroSection;
