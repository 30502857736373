import { styled } from "@linaria/react";
import { StoryAuthor, StoryDescriptor } from "../../types/helper.types";
import { ReactNode } from "react-markdown/lib/ast-to-react";
import { Fragment } from "react";
import { uniqBy } from "lodash-es";
import { cx } from "linaria";
import { externalLinkAttr } from "../../constants/externalLinks.constants";

type Props = {
  story: StoryDescriptor;
  compact?: boolean;
  linkToLinkedInIfAvailable?: boolean;
};

const AuthorSetWrap = styled.span``;

const Wrap = styled.span`
  &.someOrgHasMultipleAuthors {
    ${AuthorSetWrap} {
      display: block;
    }
  }
`;

type AuthorsGroupedByOrg = { org: string; authors: StoryAuthor[] };

const StoryCommunityAuthorsDisplay = ({
  story,
  compact,
  linkToLinkedInIfAvailable,
}: Props) => {
  const { communityAuthors } = story;
  const groupedByOrg = [] as AuthorsGroupedByOrg[];
  communityAuthors.forEach(a => {
    const org = groupedByOrg.find(
      o => o.org === a.organization?.name || ""
    ) ?? {
      org: a.organization?.name || "",
      authors: [],
    };
    org.authors.push(a);
    if (!groupedByOrg.includes(org)) groupedByOrg.push(org);
  });
  const components = groupedByOrg.map(a => (
    <AuthorSet
      set={a}
      key={a.org}
      linkToLinkedInIfAvailable={linkToLinkedInIfAvailable}
    />
  ));
  const hasMultipleOrgs = groupedByOrg.length > 0;
  const someOrgHasMultipleAuthors = groupedByOrg.some(
    org => org.authors.length > 1
  );
  const hasOnlyOrgName = groupedByOrg.every(org =>
    org.authors.every(
      a =>
        (!a.name && !a.surname && !!a.organization?.name) ||
        (a.name === a.organization?.name && !!a.name)
    )
  );
  return (
    <Wrap
      className={cx(
        hasMultipleOrgs && "hasMultipleOrgs",
        someOrgHasMultipleAuthors && "someOrgHasMultipleAuthors"
      )}
    >
      {!compact && (
        <>
          <span>
            {hasMultipleOrgs && someOrgHasMultipleAuthors
              ? "Authors:"
              : hasOnlyOrgName
              ? "Created by"
              : "By"}
          </span>{" "}
        </>
      )}
      {components.reduce(
        (arr, next, i) => [
          ...arr,
          hasMultipleOrgs && someOrgHasMultipleAuthors ? null : i > 0 &&
            i <= components.length ? (
            <Fragment key={`and-${i}`}> and </Fragment>
          ) : null,
          next,
        ],
        [] as ReactNode[]
      )}
    </Wrap>
  );
};

const AuthorSet = ({
  set,
  linkToLinkedInIfAvailable,
}: {
  set: AuthorsGroupedByOrg;
  linkToLinkedInIfAvailable?: boolean;
}) => {
  const authors = uniqBy(
    set.authors.map(a => ({
      fullName: [a.name, a.surname]
        .map(n => `${n?.trim()}`)
        .filter(i => i)
        .join(" "),
      linkedIn: a.linkedin,
    })),
    a => a.fullName
  ).filter(i => i);
  const company = set.org;
  const shouldShowName = authors.length > 0;
  const nameComponents = authors.map((a, i) =>
    a.linkedIn && linkToLinkedInIfAvailable ? (
      <a key={i} href={a.linkedIn} {...externalLinkAttr}>
        {a.fullName}
      </a>
    ) : (
      <strong key={i}>{a.fullName}</strong>
    )
  );
  const shouldShowCompanyName = company !== authors.join(" ");
  return (
    <AuthorSetWrap>
      {shouldShowName && (
        <>
          {nameComponents.reduce(
            (arr, next, i) => [
              ...arr,
              i > 0 && i <= nameComponents.length ? (
                <Fragment key={`and-${i}`}> and </Fragment>
              ) : null,
              next,
            ],
            [] as ReactNode[]
          )}
        </>
      )}{" "}
      {company && (
        <>
          {shouldShowName && shouldShowCompanyName ? <span>at</span> : ""}
          {shouldShowCompanyName && (
            <>
              {" "}
              <strong>{company}</strong>
            </>
          )}
        </>
      )}
    </AuthorSetWrap>
  );
};

export default StoryCommunityAuthorsDisplay;
