export const CompassTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.18942 3.27313L7.70458 3.13509L8.07268 4.50885L7.55752 4.64689L7.18942 3.27313ZM5.76883 3.93707L6.23071 3.6704L6.94182 4.90208L6.47994 5.16875L5.76883 3.93707ZM3.93726 5.76901L3.67059 6.23089L4.90227 6.94201L5.16894 6.48012L3.93726 5.76901ZM3.13516 7.70471L3.2732 7.18955L4.64696 7.55765L4.50892 8.07281L3.13516 7.70471ZM4.50892 9.92731L3.13516 10.2954L3.2732 10.8106L4.64696 10.4425L4.50892 9.92731ZM3.67041 11.7692L4.90209 11.0581L5.16876 11.5199L3.93708 12.231L3.67041 11.7692ZM6.48043 12.8311L5.76932 14.0627L6.2312 14.3294L6.94231 13.0977L6.48043 12.8311ZM7.18958 14.7264L7.55767 13.3527L8.07283 13.4907L7.70474 14.8645L7.18958 14.7264ZM10.4425 13.3531L9.92737 13.4911L10.2955 14.8648L10.8106 14.7268L10.4425 13.3531ZM11.058 13.0978L11.5199 12.8311L12.231 14.0628L11.7692 14.3295L11.058 13.0978ZM13.098 11.0576L12.8314 11.5195L14.063 12.2306L14.3297 11.7688L13.098 11.0576ZM13.3527 10.4424L13.4907 9.92722L14.8645 10.2953L14.7264 10.8105L13.3527 10.4424ZM14.7269 7.18939L13.3531 7.55749L13.4911 8.07265L14.8649 7.70456L14.7269 7.18939ZM12.8311 6.47992L14.0628 5.76881L14.3294 6.23069L13.0978 6.9418L12.8311 6.47992ZM11.7691 3.6703L11.058 4.90198L11.5199 5.16864L12.231 3.93696L11.7691 3.6703ZM9.92722 4.50891L10.2953 3.13515L10.8105 3.27318L10.4424 4.64695L9.92722 4.50891Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 15L9.01529 15H8.98471L9 15ZM9.26667 13.5778V14.9942C10.7226 14.9305 12.0438 14.348 13.05 13.4269L12.0488 12.4257L12.426 12.0486L13.4271 13.0498C14.3481 12.0436 14.9305 10.7225 14.9942 9.26667H13.5778V8.73334H14.9942C14.9305 7.27738 14.348 5.95612 13.4269 4.94991L12.4257 5.9511L12.0486 5.57397L13.0497 4.5728C12.0436 3.65191 10.7224 3.06949 9.26667 3.00582V4.42222H8.73334V3.00582C7.2775 3.06949 5.95633 3.65195 4.95014 4.57291L5.95151 5.57428L5.57439 5.9514L4.57302 4.95003C3.652 5.95623 3.06949 7.27744 3.00582 8.73334H4.42222V9.26667H3.00582C3.0695 10.7227 3.65212 12.044 4.57329 13.0503L5.57466 12.0489L5.95179 12.426L4.95045 13.4274C5.9566 14.3482 7.27764 14.9305 8.73334 14.9942V13.5778H9.26667ZM9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16ZM8.62473 9.65001C8.98372 9.85726 9.44274 9.73427 9.65 9.37529L10.299 9.75001L6.69512 13.4773C6.51421 13.6644 6.20346 13.4849 6.27504 13.2347L7.70097 8.25001L8.35001 8.62474C8.14275 8.98372 8.26575 9.44275 8.62473 9.65001ZM7.70098 8.24998L11.3049 4.52273C11.4858 4.33563 11.7966 4.51504 11.725 4.76527L10.2991 9.74998L9.65002 9.37526C9.85728 9.01628 9.73428 8.55725 9.3753 8.34999C9.01632 8.14273 8.55729 8.26573 8.35003 8.62471L7.70098 8.24998Z"
    />
  </svg>
);
