/* eslint-disable no-console */
/* cspell:disable */

import { checkIfIsClientAndLive } from "../../../environment";
import { shouldLogGDPRDebugInfo } from "../gdpr";

declare global {
  interface Window {
    _linkedin_partner_id: string;
    _linkedin_data_partner_ids: string[];
  }
}

export const initLinkedInInsight = () => {
  if (shouldLogGDPRDebugInfo()) {
    console.log("– init LinkedIn Insight");
  }
  if (!checkIfIsClientAndLive()) return;
  window._linkedin_partner_id = "1870106";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
  (function () {
    const s = document.getElementsByTagName("script")[0];
    const b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode?.insertBefore(b, s);
  })();
};
