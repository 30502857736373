export const CheckMarkIcons = {
  checkMark: () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.17362 12L10.1735 17L18.9999 7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  checkMarkBold: () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.17362 12L10.1735 17L18.9999 7"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
