import { styled } from "@linaria/react";
import { cx } from "linaria";
import { colorsV4 } from "../../styles/colorsV4.styles";

type Props = {
  color?: string;
  centered?: boolean;
};

const LightbulbIconSvg = styled.svg`
  &.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const LightbulbIcon = (props: Props) => {
  return (
    <LightbulbIconSvg
      className={cx(props.centered && "centered")}
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill={props.color ?? colorsV4.purple}
    >
      <rect opacity="0.03" width="158" height="158" rx="79" />
      <rect x="55" y="55" width="48" height="48" rx="24" />
      <rect opacity="0.1" x="35" y="35" width="88" height="88" rx="44" />
      <path
        d="M79 93C80.4667 93 81.6667 91.785 81.6667 90.3H76.3333C76.3333 91.785 77.5333 93 79 93ZM75 88.95H83C83.7333 88.95 84.3333 88.3425 84.3333 87.6C84.3333 86.8575 83.7333 86.25 83 86.25H75C74.2667 86.25 73.6667 86.8575 73.6667 87.6C73.6667 88.3425 74.2667 88.95 75 88.95ZM79 66C73.48 66 69 70.536 69 76.125C69 81.282 72.5467 84.036 74.0267 84.9H83.9733C85.4533 84.036 89 81.282 89 76.125C89 70.536 84.52 66 79 66Z"
        fill="white"
      />
    </LightbulbIconSvg>
  );
};

export default LightbulbIcon;
