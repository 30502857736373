/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
/* cspell:disable */

import { checkIfIsClientAndLive } from "../../../environment";
import { loadScript } from "../../../utils/script.utils";
import { shouldLogGDPRDebugInfo } from "../gdpr";

// https://analytics.google.com/analytics/web/#/p280796219/reports/intelligenthome
const ga4Id = "G-DBTFK7XLQG";

export const initGtagForGoogleAnalytics = () => {
  if (shouldLogGDPRDebugInfo()) {
    console.log("– init GTAG");
  }
  if (!checkIfIsClientAndLive()) return;
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  window.gtag("config", ga4Id, { send_page_view: false });
  loadScript({
    src: `https://www.googletagmanager.com/gtag/js?id=${ga4Id}`,
  });
};
