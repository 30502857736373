import { styled } from "@linaria/react";
import { onlyPhones } from "../../styles/breakpointsAndMediaQueries.styles";

const FromTabletDiv = styled.div`
  ${onlyPhones} {
    display: none;
  }
`;
export const FromTabletSpan = styled.span`
  ${onlyPhones} {
    display: none;
  }
`;

export default FromTabletDiv;
