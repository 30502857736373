import { colors } from "../../../styles/colors.styles";

export const defaultChartColorSeries = [
  { hex: colors.purple, darker: colors.purple700 },
  { hex: colors.orange400, darker: colors.orange700 },
  { hex: colors.green400, darker: colors.green700 },
  { hex: colors.pink, darker: colors.pink700 },
  { hex: colors.blue400, darker: colors.blue700 },
  { hex: colors.yellow400, darker: colors.yellow700 },
  { hex: colors.purple300, darker: colors.purple700 },
  { hex: colors.green300, darker: colors.green700 },
  { hex: colors.blue300, darker: colors.blue700 },
  { hex: colors.orange300, darker: colors.orange700 },
  { hex: colors.gray, darker: colors.dark500 },
  { hex: colors.lime400, darker: colors.lime700 },
  { hex: colors.pink300, darker: colors.pink700 },
];
