import { styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren } from "react";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { isMac } from "../../utils/platform.utils";

type Props = PropsWithChildren<{
  cmdOrCtrl?: boolean;
  lighter?: boolean;
}>;

export const KeyboardShortcutKbd = styled.kbd`
  background-color: ${colorsV4.canvas700};
  color: ${colorsV4.grey};
  border-radius: 0.375em;
  padding: 0.2em 0.5em;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colorsV4.black, 0.35)};`
  )}
  &.lighter {
    background-color: ${colorsV4.canvas550};
    ${darkModeLinariaCSS(`background-color: ${colorsV4.warmBlack};`)}
  }
`;

const KeyboardShortcut = (props: Props) => {
  return (
    <KeyboardShortcutKbd className={cx(props.lighter && "lighter")}>
      {props.cmdOrCtrl ? (isMac ? "⌘" : "Ctrl + ") : ""}
      {props.children}
    </KeyboardShortcutKbd>
  );
};

export default KeyboardShortcut;
