import { styled } from "@linaria/react";
import { fromDesktop } from "../../styles/breakpointsAndMediaQueries.styles";

const UptoDesktop = styled.div`
  ${fromDesktop} {
    display: none;
  }
`;
export const UptoDesktopSpan = styled.span`
  ${fromDesktop} {
    display: none;
  }
`;

export default UptoDesktop;
