import {
  Action,
  ActionBase,
  ActionRecord,
  ActionType,
  ActionTypeName,
  ActionTypeToTypeNameMap,
  StoryRecord,
} from "../../../types/tines.types";
import Point from "../../../utils/geometry.utils";
import { round } from "../../../utils/math.utils";
import { getPositionInViewport } from "./storyboardViewport.utils";
import { StoryboardContextValue } from "../StoryboardContext";
import { colors } from "../../../styles/colors.styles";

export const convertActionRecordToAction = (
  record: ActionRecord,
  storyRecord: StoryRecord
) => {
  const action: ActionBase = {
    index: storyRecord.agents.findIndex(a => a === record),
    record,
    type: record.type,
    description: record.description ?? "",
    typeName: getActionTypeName(record.type),
    color: getActionColorFromType(record.type),
    name: record.name,
    disabled: record.disabled,
    guid: record.guid,
    position: record.position,
    width: record.width ?? null,
    height: record.height ?? null,
    options: record.options,
    inputs: [],
    outputs: [],
    inputLinks: [],
    outputLinks: [],
  };
  return action;
};

export const ActionTypeNameToColorName: Record<ActionType, string> = {
  eventTransformation: colors.orange400,
  trigger: colors.green400,
  webhook: colors.teal400,
  httpRequest: colors.blue400,
  email: colors.pink400,
  imap: colors.red400,
  sendToStory: colors.purple,
  group: colors.purple,
  groupInput: colors.purple,
  groupOutput: colors.purple,
  form: colors.purple,
};

export const getActionTypeName = (type: ActionType): ActionTypeName =>
  ActionTypeToTypeNameMap[type];

export const getActionColorFromType = (type: ActionType) =>
  ActionTypeNameToColorName[type];

export const getActionPositionInStoryboardViewport = (
  el: Action,
  context: StoryboardContextValue
) => {
  return getPositionInViewport(context, {
    x: el.position.x,
    y: el.position.y,
  });
};

export const getActionCenterPositionInFilmViewport = (
  el: Action,
  context: StoryboardContextValue
) => {
  const center = Point.add(getActionPositionInStoryboardViewport(el, context), {
    x: round(0 + (el.width ?? 0) / 2),
    y: round(0 + (el.height ?? 0) / 2),
  });
  return center;
};

export const getActionDOMElInDiagram = (
  context: StoryboardContextValue,
  action: Action
) => {
  return document.querySelector<HTMLDivElement>(
    `.Storyboard[data-id="${context.id}"] [data-action-index="${action.index}"]`
  );
};
