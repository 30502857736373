import { styled } from "@linaria/react";
import {
  PrimaryColorName,
  SecondaryColorName,
  color,
  colors,
} from "../../../styles/colors.styles";

export const ActionLinkOutgoingTerminalSvg = styled.svg`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ActionLinkOutgoingTerminal = (props: {
  id: string;
  color?: PrimaryColorName | SecondaryColorName;
}) => (
  <ActionLinkOutgoingTerminalSvg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    id={props.id}
  >
    <circle
      cx="7"
      cy="7"
      r="6.25"
      fill={color(props.color, 400)}
      stroke={color(props.color, 200)}
      strokeWidth="1.5"
    />
    <path
      d="M6.64645 8.64645L4.85355 6.85355C4.53857 6.53857 4.76165 6 5.20711 6H8.79289C9.23835 6 9.46143 6.53857 9.14645 6.85355L7.35355 8.64645C7.15829 8.84171 6.84171 8.84171 6.64645 8.64645Z"
      fill={colors.white}
    />
  </ActionLinkOutgoingTerminalSvg>
);

export const ActionLinkOutgoingGooeyBitSvg = styled.svg`
  position: absolute;
  top: 100%;
  left: calc(50% - 4px);
`;

export const ActionLinkOutgoingGooeyBit = (props: {
  id: string;
  color?: PrimaryColorName | SecondaryColorName;
}) => (
  <ActionLinkOutgoingGooeyBitSvg
    id={props.id}
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill={color(props.color, 400)}
  >
    <path d="M0 0C1.65686 0 3 1.34315 3 3V4H5V3C5 1.34315 6.34314 0 8 0H0Z" />
  </ActionLinkOutgoingGooeyBitSvg>
);

export const ActionLinkIncomingGooeyBitSvg = styled.svg`
  position: absolute;
  bottom: 100%;
  left: calc(50% - 4px);
`;

export const ActionLinkIncomingGooeyBit = (props: {
  id: string;
  color?: PrimaryColorName | SecondaryColorName;
}) => (
  <ActionLinkIncomingGooeyBitSvg
    id={props.id}
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill={color(props.color, 400)}
  >
    <path d="M0 4C1.65686 4 3 2.65685 3 1V0H5V1C5 2.65685 6.34314 4 8 4H0Z" />
  </ActionLinkIncomingGooeyBitSvg>
);
