export const ProtectedBuildingIcon = () => (
  <svg
    width="114"
    height="83"
    viewBox="0 0 114 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.6191 81.5965C93.7356 81.5965 113.286 77.4209 113.286 72.2699C113.286 67.1189 93.7356 62.9434 69.6191 62.9434C45.5027 62.9434 25.9521 67.1189 25.9521 72.2699C25.9521 77.4209 45.5027 81.5965 69.6191 81.5965Z"
      fill="#32274B"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M19 60.8144C19 66.4339 36.0136 70.9904 57.0005 70.9904C77.9883 70.9904 95.001 66.4339 95.001 60.8144L94.9031 38.9028C94.9031 17.9696 77.9338 1 57.0005 1C36.0681 1 19.0978 17.9696 19.0978 38.9028L19 60.8144Z"
      fill="#A990F5"
      stroke="#6956A8"
      strokeMiterlimit="10"
    />
    <path
      d="M95.001 60.811C95.001 55.1906 77.9883 50.634 57.0005 50.634C36.0136 50.634 19 55.1906 19 60.811C19 62.1599 19 69.0736 19 70.4235C19 76.0439 36.0136 80.5995 57.0005 80.5995C77.9883 80.5995 95.001 76.0439 95.001 70.4235C95.001 69.0736 95.001 62.1599 95.001 60.811Z"
      fill="#7F69CE"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M57.0005 70.986C77.9883 70.986 95.001 66.4304 95.001 60.81C95.001 55.1896 77.9883 50.634 57.0005 50.634C36.0136 50.634 19 55.1896 19 60.81C19 66.4304 36.0136 70.986 57.0005 70.986Z"
      fill="#A990F5"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M91.6928 57.5581C91.6928 53.5632 76.1589 50.3254 56.9978 50.3254C37.8367 50.3254 22.3037 53.5632 22.3037 57.5581C22.3037 58.1169 22.3037 60.2531 22.3037 60.8128C22.3037 64.8068 37.8367 68.0455 56.9978 68.0455C76.1589 68.0455 91.6928 64.8068 91.6928 60.8128C91.6928 60.2531 91.6928 58.1169 91.6928 57.5581Z"
      fill="#25A871"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M56.9978 64.7908C76.1598 64.7908 91.6928 61.5521 91.6928 57.5581C91.6928 53.5632 76.1598 50.3254 56.9978 50.3254C37.8367 50.3254 22.3037 53.5632 22.3037 57.5581C22.3037 61.5521 37.8367 64.7908 56.9978 64.7908Z"
      fill="#87D1A3"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M60.9099 57.2361C70.2393 57.2361 77.8012 56.0612 77.8012 54.6107C77.8012 53.1612 70.2393 51.9863 60.9099 51.9863C51.5814 51.9863 44.0195 53.1612 44.0195 54.6107C44.0195 56.0612 51.5814 57.2361 60.9099 57.2361Z"
      fill="#32274B"
    />
    <path
      d="M43.0752 54.1804C43.0752 55.6676 49.3428 56.8726 57.074 56.8726C64.8052 56.8726 71.0728 55.6676 71.0728 54.1804V45.5659C71.0728 44.0788 64.8052 42.8738 57.074 42.8738C49.3428 42.8738 43.0752 44.0788 43.0752 45.5659V54.1804Z"
      fill="#A990F5"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M51.96 56.5855V49.7093C51.96 47.8215 53.4904 46.291 55.3783 46.291C57.2662 46.291 58.7966 47.8215 58.7966 49.7093V56.7078C58.7966 56.7078 56.9275 56.8329 55.3218 56.816C53.7387 56.7991 51.96 56.5855 51.96 56.5855Z"
      fill="#F486B8"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.3193 56.5735V49.6974C53.3193 48.3203 54.1339 47.1332 55.3079 46.5923"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.074 48.3913C64.8052 48.3913 71.0728 47.1854 71.0728 45.6983C71.0728 44.212 64.8052 43.0061 57.074 43.0061C49.3428 43.0061 43.0752 44.212 43.0752 45.6983C43.0752 47.1854 49.3428 48.3913 57.074 48.3913Z"
      fill="#C2AAFA"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M68.5107 45.2229C68.5107 46.109 63.3907 46.8267 57.0752 46.8267C50.7596 46.8267 45.6396 46.109 45.6396 45.2229V24.4373C45.6396 23.5512 50.7596 22.8335 57.0752 22.8335C63.3907 22.8335 68.5107 23.5512 68.5107 24.4373V45.2229Z"
      fill="#D6EDD9"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M57.7199 46.7862H54.2197L57.8544 25.3835H61.3536L57.7199 46.7862Z"
      fill="white"
    />
    <path
      d="M68.5107 45.2229C68.5107 46.109 63.3907 46.8267 57.0752 46.8267C50.7596 46.8267 45.6396 46.109 45.6396 45.2229V24.4373C45.6396 23.5512 50.7596 22.8335 57.0752 22.8335C63.3907 22.8335 68.5107 23.5512 68.5107 24.4373V45.2229Z"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M68.5107 40.1987C68.5107 41.0839 63.3907 41.8025 57.0752 41.8025C50.7596 41.8025 45.6396 41.0839 45.6396 40.1987"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M68.5107 34.7607C68.5107 35.6468 63.3907 36.3646 57.0752 36.3646C50.7596 36.3646 45.6396 35.6468 45.6396 34.7607"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M68.5107 29.3225C68.5107 30.2077 63.3907 30.9263 57.0752 30.9263C50.7596 30.9263 45.6396 30.2077 45.6396 29.3225"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M71.0737 21.4385C71.0737 20.4113 64.8052 19.5779 57.0749 19.5779C49.3437 19.5779 43.0752 20.4113 43.0752 21.4385C43.0752 21.8505 43.0752 23.2286 43.0752 23.6406C43.0752 24.6687 49.3437 25.5012 57.0749 25.5012C64.8052 25.5012 71.0737 24.6687 71.0737 23.6406C71.0737 23.2286 71.0737 21.8505 71.0737 21.4385Z"
      fill="#A990F5"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M57.074 23.2982C64.8052 23.2982 71.0728 22.4657 71.0728 21.4385C71.0728 20.4113 64.8052 19.5779 57.074 19.5779C49.3428 19.5779 43.0752 20.4113 43.0752 21.4385C43.0752 22.4657 49.3428 23.2982 57.074 23.2982Z"
      fill="#D7C4FA"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path d="M61.4541 46.4545V25.0527" stroke="#32274B" strokeMiterlimit="10" />
    <path
      d="M59.4002 21.4824C63.3029 21.4824 66.4673 21.0751 66.4673 20.5737C66.4673 20.0724 63.3029 19.666 59.4002 19.666C55.4974 19.666 52.334 20.0724 52.334 20.5737C52.334 21.0751 55.4974 21.4824 59.4002 21.4824Z"
      fill="#32274B"
    />
    <path d="M48.6348 46.1516V25.0527" stroke="#32274B" strokeMiterlimit="10" />
    <path
      d="M62.6441 20.2707C62.6441 20.9395 60.1504 21.1568 57.0745 21.1568C53.9985 21.1568 51.5049 20.9395 51.5049 20.2707C51.5049 19.6019 53.9985 16.7705 57.0745 16.7705C60.1504 16.7705 62.6441 19.6019 62.6441 20.2707Z"
      fill="#D6EDD9"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6475 16.3186C57.6475 16.6365 57.3897 16.6911 57.0727 16.6911C56.7548 16.6911 56.4971 16.6356 56.4971 16.3186L56.4971 11.1139C56.4971 10.7962 56.7548 10.5388 57.0727 10.5388C57.3897 10.5388 57.6475 10.7962 57.6475 11.1139V16.3186Z"
      fill="#D6EDD9"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M57.0728 11.8212C57.7435 11.8212 58.2872 11.2775 58.2872 10.6066C58.2872 9.93603 57.7435 9.39233 57.0728 9.39233C56.4021 9.39233 55.8584 9.93603 55.8584 10.6066C55.8584 11.2775 56.4021 11.8212 57.0728 11.8212Z"
      fill="#F486B8"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M48.5645 25.3204C48.5645 25.3204 61.8907 26.0607 68.3511 24.7146V26.1275C68.3511 26.1275 53.612 26.6655 48.5645 25.3204Z"
      fill="#32274B"
    />
    <path
      d="M52.3125 49.7584L53.3218 49.7019L53.5918 48.3812L52.3125 48.2825V49.7584Z"
      fill="#32274B"
    />
    <path
      d="M49.687 58.4203C49.687 59.2876 46.9581 59.9903 43.5925 59.9903C40.2259 59.9903 37.4971 59.2876 37.4971 58.4203C37.4971 57.554 40.2259 56.8513 43.5925 56.8513C46.9581 56.8513 49.687 57.554 49.687 58.4203Z"
      fill="#32274B"
    />
    <path
      d="M44.9298 56.8565L43.4407 55.9262V44.7559C43.4746 44.7136 40.6094 46.3851 40.6094 46.3851V55.9262L38.7864 57.0502C37.8636 57.641 37.3096 57.9232 37.3096 58.1621C37.3096 58.7481 39.4204 59.2222 42.0251 59.2222C44.6297 59.2222 46.7396 58.7481 46.7396 58.1621C46.7396 57.9232 45.7717 57.3315 44.9298 56.8565Z"
      fill="#1F7A57"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M45.9832 37.8132C46.1789 37.3232 46.2899 36.7907 46.2899 36.2311C46.2899 33.8766 44.3803 31.968 42.0268 31.968C39.6733 31.968 37.7628 33.8766 37.7628 36.2311C37.7628 36.7907 37.8738 37.3232 38.0695 37.8132C36.3838 39.0389 35.2852 41.0265 35.2852 43.27C35.2852 46.9931 38.3037 50.0116 42.0268 50.0116C45.7499 50.0116 48.7685 46.9931 48.7685 43.27C48.7685 41.0265 47.6698 39.0389 45.9832 37.8132Z"
      fill="#87D1A3"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.5508 49.8403V50.7885C40.5508 50.7885 40.9666 51.7376 41.9157 51.7376C42.8638 51.7376 43.5769 51.2043 43.5769 51.2043L43.4574 49.8403C43.4574 49.8403 41.2629 50.4339 40.5508 49.8403Z"
      fill="#32274B"
    />
    <path
      d="M30.0557 24.4358C29.7547 24.4358 29.4509 24.369 29.163 24.2288C28.149 23.735 27.7267 22.515 28.2196 21.5009C28.3259 21.2818 30.8948 16.063 36.0176 11.5422C36.8642 10.7956 38.1557 10.8767 38.9007 11.7228C39.6476 12.5685 39.5676 13.86 38.721 14.6059C34.2369 18.5623 31.9163 23.2421 31.8938 23.2891C31.5391 24.0144 30.8111 24.4358 30.0557 24.4358Z"
      fill="#FCF9F5"
    />
  </svg>
);
