import { styled } from "@linaria/react";
import { useState } from "react";
import { rSize } from "../../styles/responsiveSizes.styles";
import { reportErrorSilently } from "../../utils/error.utils";
import { getGreenhouseJobIdFromCurrentLocation } from "../../utils/greenhouse.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { setUrlQueryParam } from "../../utils/urlQueryParams.utils";
import InfoBox from "./InfoBox";
import LoadingIndicator, {
  LoadingIndicatorWrapper,
} from "../utilities/LoadingIndicator";

export const GreenhouseEmbedContainer = styled.div`
  min-height: 400px;
  position: relative;
  ${LoadingIndicatorWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ErrorContainer = styled.div`
  padding: 1em ${rSize("lg")};
`;

const GreenhouseEmbed = () => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  useOnMount(() => {
    const jobId = getGreenhouseJobIdFromCurrentLocation();
    const script = document.createElement("script");
    if (jobId) setUrlQueryParam("gh_jid", jobId, "replaceState");
    script.src = "https://boards.greenhouse.io/embed/job_board/js?for=tines";
    script.type = "text/javascript";
    script.onload = e => {
      setReady(true);
      // dispatch an artificial 'load' event to trigger greenhouse's iframe autoload
      window.dispatchEvent(new Event("load"));
    };
    script.onerror = e => {
      reportErrorSilently(e);
      setError(e);
    };
    document.head.append(script);
    return () => {
      window.history.pushState(null, document.title, window.location.pathname);
    };
  });

  return (
    <GreenhouseEmbedContainer>
      <>
        {!ready && <LoadingIndicator centered />}
        {!error && (
          <>
            <style
              type="text/css"
              children={`#grnhse_iframe { width: 100% !important; }`}
            />
            <div id="grnhse_app" />
          </>
        )}
        {error && (
          <ErrorContainer>
            <InfoBox>
              An error occurred while loading job details. Please try again
              later.
            </InfoBox>
          </ErrorContainer>
        )}
      </>
    </GreenhouseEmbedContainer>
  );
};

export default GreenhouseEmbed;
