import { styled } from "@linaria/react";
import {
  DatoCmsButton,
  DatoCmsFeaturedVideoSection,
} from "../../../../graphql-types";
import { getWidthPxInMaxGrid } from "../../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMl,
  fromPhoneLg,
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";
import { serif } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { GridCanvas } from "../../decorations/GridCanvas";
import { ButtonGroupCenteredUptoDesktop } from "../../forms/Button";
import Spacing from "../../layout/Spacing";
import PageSection from "../../reusableSections/PageSection";
import { SectionHeading2 } from "../../typography/SectionHeading2";
import { LPButtonGroup } from "../LPButtonGroup";
import { VideoFrameWithCustomPlayButton } from "../../general/VideoFrameWithCustomPlayButton";

const Inner = styled.div`
  position: relative;
  ${uptoTabletMd} {
    margin-top: -3em;
  }
`;

const Contents = styled.div`
  position: relative;
  ${uptoTabletMd} {
    padding-top: 6em;
  }
`;

const Header = styled.header`
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  p {
    max-width: 22em;
    ${fromPhoneLg} {
      max-width: 33em;
    }
    margin-left: auto;
    margin-right: auto;
  }
`;

const ProductDemoVideoFramePositioner = styled.div`
  ${fromTabletMd} {
    margin-left: ${getWidthPxInMaxGrid(1, true)};
    margin-right: ${getWidthPxInMaxGrid(1, true)};
  }
`;

const Footer = styled.footer`
  > * {
    + * {
      margin-top: ${rSize("lg")};
    }
  }
  padding: 4.5em 2em 6em;
  ${fromDesktop} {
    padding: 5em 2em;
  }
  p {
    font-size: 1.6rem;
    line-height: 1.3;
    ${fromTabletMd} {
      font-size: 2.4rem;
      line-height: 1.2;
      font-family: ${serif};
      font-weight: 400;
    }
    max-width: 28em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const GridCanvasWrap = styled.div`
  position: absolute;
  top: 0;
  left: -0.5em;
  right: -0.5em;
  bottom: 0;
  ${fromPhoneLg} {
    left: -1em;
    right: -1em;
  }
  ${fromTabletMd} {
    top: 33%;
    left: 0;
    right: 0;
  }
  border: 1px solid ${colors.purple100};
  border-radius: ${rSize("radius")};
  ${fromDesktopMl} {
    margin-left: ${rSize("gap", -1)};
    margin-right: ${rSize("gap", -1)};
  }
`;

export const LPFeaturedVideoSection = ({
  section,
}: {
  section: DatoCmsFeaturedVideoSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage} centered>
      <Inner>
        <GridCanvasWrap>
          <GridCanvas color={colors.purple100} parallax animateIn />
        </GridCanvasWrap>
        <Contents>
          <Header>
            <SectionHeading2 lighter>{section.heading}</SectionHeading2>
            {section.descriptionAfterHeading && (
              <p>{section.descriptionAfterHeading}</p>
            )}
          </Header>
          <Spacing size="xl" />
          <ProductDemoVideoFramePositioner>
            {section.video?.video?.streamingUrl && (
              <VideoFrameWithCustomPlayButton
                videoSrc={section.video.video.streamingUrl}
                thumbnailSrc={section.video.video.thumbnailUrl}
                width={section.video.width}
                height={section.video.height}
              />
            )}
          </ProductDemoVideoFramePositioner>
          {!!(section.textAfterVideo || section.ctaButtons?.length) && (
            <Footer>
              {section.textAfterVideo && <p>{section.textAfterVideo}</p>}
              {!!section.ctaButtons?.length && (
                <ButtonGroupCenteredUptoDesktop>
                  <LPButtonGroup
                    buttons={section.ctaButtons as DatoCmsButton[]}
                  />
                </ButtonGroupCenteredUptoDesktop>
              )}
            </Footer>
          )}
        </Contents>
      </Inner>
    </PageSection>
  );
};
