import { clamp, round } from "lodash-es";
import { StoryboardContextValue } from "../StoryboardContext";
import { getPositionInViewport } from "./storyboardViewport.utils";
import { gridUnit } from "./storyboard.utils";

export const getStoryboardPilotViewCameraParameters = (
  context: StoryboardContextValue,
  defaultZoom = 1
) => {
  const widthScalar =
    context.viewport.width /
    (context.story.measurements.width + gridUnit(5) * defaultZoom);
  const heightScalar =
    context.viewport.height /
    (context.story.measurements.height + gridUnit(5) * defaultZoom);
  const zoom = round(
    clamp(Math.min(widthScalar, heightScalar), 0, defaultZoom),
    3
  );
  return {
    zoom,
    position: getPositionInViewport(context, context.story.measurements.center),
  };
};
