import { styled } from "@linaria/react";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { rSize } from "../../styles/responsiveSizes.styles";
import { casesPageDarkPurple30 } from "./casesPageConstants";
import {
  fromTablet,
  onlyPhones,
  onlyTablets,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { CasesPageThunderboltIcon } from "./icons/CasesPageThunderboltIcon";
import { CasesPageYarnBallIcon } from "./icons/CasesPageYarnBallIcon";
import { CasesPageFunnelIcon } from "./icons/CasesPageFunnelIcon";
import { CasesPageStatBuildingIcon } from "./icons/CasesPageStatBuildingIcon";
import { CasesMediumIllustration } from "./illustrations/CasesMediumIllustration";
import Spacing from "../layout/Spacing";
import { color, colors } from "../../styles/colors.styles";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import { GridCanvas } from "../decorations/GridCanvas";

const SectionWrap = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  max-width: ${maxPageContentWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding: 5rem ${rSize("pageMargin")} ${rSize("sectionPadding")};
`;

const Backdrop = styled.div`
  background-color: ${colors.purple};
  display: none;
  ${fromTablet} {
    display: block;
    border-radius: ${rSize("radius")};
  }
  border: 1px solid ${colors.purple300};
  position: absolute;
  left: ${rSize("widerPageMargin")};
  right: ${rSize("widerPageMargin")};
  top: 0;
  bottom: 0;
`;

const Inner = styled.div`
  position: relative;
  header {
    text-align: center;
    padding-bottom: 2em;
    ${fromTablet} {
      padding-bottom: 4em;
    }
    svg {
      margin-top: -14rem;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  > * {
    background-color: ${casesPageDarkPurple30};
    border: 1px solid ${colors.purple400};
    padding: 3em 2em;
    border-radius: ${rSize("radius")};
    display: grid;
    grid-template-rows: auto auto minmax(0, 1fr);
    grid-template-areas: "icon" "heading" "desc";
    grid-gap: 1em;
    ${fromTablet} {
      padding: ${rSize("lg")};
      grid-template-rows: auto minmax(0, 1fr) auto;
      grid-template-areas: "heading" "desc" "icon";
    }
  }
  svg {
    grid-area: icon;
    ${onlyPhones} {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.25em;
    }
    ${onlyTablets} {
      transform: scale(0.9);
      transform-origin: bottom right;
    }
    ${fromTablet} {
      align-self: end;
      justify-self: end;
    }
  }
  h3 {
    grid-area: heading;
    font-size: 2.4rem;
  }
  p {
    grid-area: desc;
    max-width: 25em;
    line-height: 1.4;
    min-height: 4.2em;
    font-size: 1.4rem;
    ${onlyPhones} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const CasesPageWhyTinesSection = () => {
  return (
    <SectionWrap>
      <Backdrop>
        <GridCanvas color={color("purple", 450)} parallax />
      </Backdrop>
      <Inner>
        <header>
          <CasesMediumIllustration />
          <Spacing size="lg" />
          <SectionHeading2 lighter>Why Tines cases?</SectionHeading2>
        </header>
        <Grid>
          <div>
            <h3>Fast</h3>
            <p>
              This is automation-first case management: create a case from
              within your existing workflow. From creation to search to
              correlating information – the experience feels instantaneous.
            </p>
            <CasesPageThunderboltIcon />
          </div>
          <div>
            <h3>Flexible</h3>
            <p>
              Cases don’t presume a way of working, it responds to yours. Start
              from a template, fine tune to your own preferences; or both. Cases
              connects to any workflow, record, page, or API.
            </p>
            <CasesPageYarnBallIcon />
          </div>
          <div>
            <h3>Powerful</h3>
            <p>
              Define advanced, nuanced data tracking across stories and cases.
              Aggregate into a dashboard to track SLAs, open cases, MTTR and
              more.
            </p>
            <CasesPageFunnelIcon />
          </div>
          <div>
            <h3>Collaborative</h3>
            <p>
              Mitigate risks with teams from a single location with intuitive
              components. Cases provide real-time visibility into data that can
              be analyzed, aggregated and reported to share with key
              stakeholders.
            </p>
            <CasesPageStatBuildingIcon />
          </div>
        </Grid>
      </Inner>
    </SectionWrap>
  );
};

export default CasesPageWhyTinesSection;
