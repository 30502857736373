export const ProductTemplatesIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M9.5194 2.07398L7.07547 4.51792C6.80942 4.78396 6.80942 5.21531 7.07547 5.48135L9.5194 7.92529C9.78545 8.19133 10.2168 8.19133 10.4828 7.92529L12.9268 5.48135C13.1928 5.21531 13.1928 4.78396 12.9268 4.51792L10.4828 2.07398C10.2168 1.80794 9.78545 1.80794 9.5194 2.07398Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5194 7.07398L12.0755 9.51792C11.8094 9.78396 11.8094 10.2153 12.0755 10.4813L14.5194 12.9253C14.7854 13.1913 15.2168 13.1913 15.4828 12.9253L17.9268 10.4814C18.1928 10.2153 18.1928 9.78396 17.9268 9.51792L15.4828 7.07398C15.2168 6.80793 14.7854 6.80794 14.5194 7.07398Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5194 7.07477L2.07547 9.51871C1.80942 9.78476 1.80942 10.2161 2.07547 10.4821L4.5194 12.9261C4.78545 13.1921 5.21679 13.1921 5.48284 12.9261L7.92677 10.4821C8.19282 10.2161 8.19282 9.78476 7.92677 9.51871L5.48284 7.07477C5.21679 6.80873 4.78545 6.80873 4.5194 7.07477Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5194 12.0748L7.07547 14.5187C6.80942 14.7848 6.80942 15.2161 7.07547 15.4821L9.5194 17.9261C9.78545 18.1921 10.2168 18.1921 10.4828 17.9261L12.9268 15.4821C13.1928 15.2161 13.1928 14.7848 12.9268 14.5187L10.4828 12.0748C10.2168 11.8087 9.78545 11.8087 9.5194 12.0748Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
