import { styled } from "@linaria/react";
import { DatoCmsActionTemplate, DatoCmsProduct } from "../../../graphql-types";
import { colors } from "../../styles/colors.styles";

type Props = {
  actionTemplate: DatoCmsActionTemplate;
  product?: DatoCmsProduct;
  showProductName?: boolean;
};

const ActionTemplateEntryWrap = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple100};
  border-radius: 12px;
  padding: 3px;
`;

const IconWrap = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 4.8rem;
  min-height: 4.8rem;
  background-color: ${colors.light100};
  border: 1px solid ${colors.light200};
  border-radius: 9px;
  img {
    max-width: 2.4rem;
    height: auto;
  }
`;

const Label = styled.div`
  padding: 0.5em 1em;
  font-size: 1.4rem;
  line-height: 1.2;
`;

const ProductName = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
  opacity: 0.6;
`;
const TemplateName = styled.div`
  font-weight: 500;
`;

const ActionTemplateEntry = (props: Props) => {
  return (
    <ActionTemplateEntryWrap>
      <IconWrap>
        {props.product?.icon?.url && <img src={props.product.icon.url} />}
      </IconWrap>
      <Label>
        {props.showProductName && (
          <ProductName>{props.product?.name}</ProductName>
        )}
        <TemplateName>{props.actionTemplate.name}</TemplateName>
      </Label>
    </ActionTemplateEntryWrap>
  );
};

export default ActionTemplateEntry;
