import { ReactNode, useRef, useState } from "react";
import { Action, RuntimeStoryRecord } from "../../types/tines.types";
import { XY } from "../../utils/geometry.utils";
import { createStoryFromRecord } from "./utils/story.utils";
import {
  StoryboardContext,
  StoryboardContextValue,
  StoryboardDecorationDef,
} from "./StoryboardContext";
import gsap from "gsap";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  getStoryboardElementGroupMeasurements,
  gridUnit,
} from "./utils/storyboard.utils";
import { when } from "../../utils/promises.utils";
import { clamp } from "lodash-es";
import { NoteOrAssetRenderStateObject } from "./NoteEntry";
import { getStoryboardPilotViewCameraParameters } from "./utils/storyboardCamera.utils";

type Props = {
  className?: string;
  story: RuntimeStoryRecord;
  aspectRatio?: string;
  inspectable?: boolean;
  enableWheelPan?: boolean;
  enableDragPan?: boolean;
  enableManualZoom?: boolean;
  useMarketingStyles?: boolean;
  delay?: number;
  id?: string | number;
  children: (context: StoryboardContextValue) => ReactNode;
  storyboardDecorations?: StoryboardDecorationDef[];
  actionShouldBeHidden?: (action: Action) => boolean;
  defaultZoom?: number;
  inset?:
    | number
    | {
        top?: number;
        bottom?: number;
        left?: number;
        right?: number;
      };
};

const defaultInset = gridUnit(36);

/** Currently supports displaying actions in copied Tines story partial json from clipboard */
const StoryboardViewer = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [viewportWidth, setViewportWidth] = useState(1280);
  const [viewportHeight, setViewportHeight] = useState(720);

  const [insetTop] = useState(
    !props.inset
      ? defaultInset
      : typeof props.inset === "number"
      ? props.inset
      : props.inset.top ?? defaultInset
  );
  const [insetBottom] = useState(
    !props.inset
      ? defaultInset
      : typeof props.inset === "number"
      ? props.inset
      : props.inset.bottom ?? defaultInset
  );
  const [insetLeft] = useState(
    !props.inset
      ? defaultInset
      : typeof props.inset === "number"
      ? props.inset
      : props.inset.left ?? defaultInset
  );
  const [insetRight] = useState(
    !props.inset
      ? defaultInset
      : typeof props.inset === "number"
      ? props.inset
      : props.inset.right ?? defaultInset
  );

  const viewportWidthRef = useRef(viewportWidth);
  viewportWidthRef.current = viewportWidth;

  const viewportHeightRef = useRef(viewportHeight);
  viewportHeightRef.current = viewportHeight;

  const [ready, setReady] = useState(false);
  const readyRef = useRef(ready);
  readyRef.current = ready;

  const story = createStoryFromRecord(props.story);
  const [focusX, setFocusX] = useState(story.measurements.center.x);
  const focusXRef = useRef(focusX);
  focusXRef.current = focusX;
  const [focusY, setFocusY] = useState(story.measurements.center.y);
  const focusYRef = useRef(focusY);
  focusYRef.current = focusY;

  const [zoom, setZoom] = useState(props.defaultZoom ?? 1);
  const zoomRef = useRef(zoom);
  zoomRef.current = zoom;

  const [noteRenderStates] = useState<NoteOrAssetRenderStateObject[]>([]);

  const [selectedActionId, setSelectedActionId] = useState<number | null>(null);

  const context: StoryboardContextValue = {
    id: props.id ?? 0,
    viewport: {
      get width() {
        return viewportWidthRef.current;
      },
      get height() {
        return viewportHeightRef.current;
      },
    },
    ref,
    story,
    inset: {
      top: insetTop,
      bottom: insetBottom,
      left: insetLeft,
      right: insetRight,
    },
    storyboard: {
      get width() {
        return (
          context.story.measurements.width +
          context.inset.left +
          context.inset.right
        );
      },
      get height() {
        return (
          context.story.measurements.height +
          context.inset.top +
          context.inset.bottom
        );
      },
      get center() {
        return {
          x: context.story.measurements.center.x + context.inset.left,
          y: context.story.measurements.center.y + context.inset.top,
        };
      },
      get boundingBox() {
        return [
          {
            x: context.story.measurements.boundingBox[0].x - context.inset.left,
            y: context.story.measurements.boundingBox[0].y - context.inset.top,
          },
          {
            x:
              context.story.measurements.boundingBox[1].x + context.inset.right,
            y:
              context.story.measurements.boundingBox[1].y +
              context.inset.bottom,
          },
        ] as [XY, XY];
      },
      decorations: props.storyboardDecorations ?? [],
    },
    noteRenderStates,
    get ready() {
      return readyRef.current;
    },
    setReady: () => {
      // console.info("StoryboardViewer ready", story);
      setReady(true);
    },
    options: {
      delay: props.delay ?? 0,
      inspectable: props.inspectable ?? false,
      useMarketingStyles: props.useMarketingStyles ?? false,
      enableWheelPan: props.enableWheelPan ?? false,
      enableDragPan: props.enableDragPan ?? false,
      enableManualZoom: props.enableManualZoom ?? false,
      actionShouldBeHidden: props.actionShouldBeHidden ?? (() => false),
    },
    focus: {
      get x() {
        return focusXRef.current;
      },
      set x(v) {
        setFocusX(v);
      },
      get y() {
        return focusYRef.current;
      },
      set y(v) {
        setFocusY(v);
      },
    },
    setFocus: (xy: XY) => {
      setFocusX(Math.round(xy.x));
      setFocusY(Math.round(xy.y));
    },
    get zoom() {
      return zoomRef.current;
    },
    set zoom(v) {
      setZoom(v);
    },
    setZoom: v => {
      setZoom(v);
    },
    panToPosition: (position: XY, animate?: boolean) => {
      gsap.to(context.focus, {
        ...position,
        ease: "expo.out",
        duration: animate ? 1 : 0,
      });
    },
    zoomTo: (level: number, animate?: boolean) => {
      gsap.to(context, {
        zoom: clamp(level, 0.3, 10),
        ease: "expo.out",
        duration: animate ? 1 : 0,
      });
    },
    resetView: (animate?: boolean) => {
      story.measurements = getStoryboardElementGroupMeasurements(
        story.actions,
        story.notes
      );
      const pilotViewCameraParams = getStoryboardPilotViewCameraParameters(
        contextRef.current,
        props.defaultZoom
      );
      contextRef.current.panToPosition(pilotViewCameraParams.position, animate);
      contextRef.current.zoomTo(pilotViewCameraParams.zoom, animate);
    },
    selectedActionId,
    updateActionSelection: (action?: Action | null) => {
      setSelectedActionId(action?.index ?? null);
    },
  };

  const contextRef = useRef(context);
  contextRef.current = context;

  useOnMount(() => {
    const handler = (entries: ResizeObserverEntry[]) => {
      setViewportWidth(entries[0].target.clientWidth);
      setViewportHeight(entries[0].target.clientHeight);
    };
    const observer = new ResizeObserver(handler);
    observer.observe(ref.current!);
    when(
      () => context.ready,
      () => {
        context.resetView(false);
      }
    );
    return () => {
      observer.disconnect();
    };
  });

  return (
    <StoryboardContext.Provider value={context}>
      {props.children(context)}
    </StoryboardContext.Provider>
  );
};

export default StoryboardViewer;
