import { styled } from "@linaria/react";
import { DatoCmsSolutionPage } from "../../../graphql-types";
import PageSection from "../reusableSections/PageSection";
import {
  ShadableOrAdjacentSectionSpacer,
  ShadableSectionWrap,
} from "./SolutionPageSharedComponents";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import SolutionPageAllcapsHeading from "./SolutionPageAllcapsHeading";
import { cx } from "linaria";
import { fontSerifDisplayRules } from "../../styles/fonts.styles";
import {
  fromDesktop,
  fromDesktopLg,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  mediaFromTo,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { preventWidows } from "../../utils/typography.utils";
import { fluidFontSize } from "../../styles/helpers.styles";
import { words } from "../../utils/string.utils";
import SmartLink from "../basic/SmartLink";

type Props = {
  page: DatoCmsSolutionPage;
  shaded?: boolean;
};

const MobileTopSpacer = styled.div`
  ${uptoTabletLg} {
    height: 7em;
  }
`;

const TopSpacer = styled.div`
  ${fromTabletLg} {
    height: 5em;
  }
`;

const Content = styled.div`
  position: relative;
  ${uptoTabletLg} {
    text-align: center;
  }
`;

const Header = styled.header`
  padding-bottom: ${rSize("lg")};
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: 1fr;
  grid-template-areas: "description" "quote" "dataCard";
  ${fromTabletLg} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "description quote" "dataCard quote";
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "${words("description", 5)} ${words("quote", 7)}" "${words(
        "dataCard",
        5
      )} ${words("quote", 7)}";
  }
`;

const DescriptionWrap = styled.div`
  grid-area: description;
  font-size: 1.6rem;
  ${fromTabletLg} {
    padding-right: ${widthInGrid(1, 1)};
    padding-bottom: 2em;
  }
  ${fromDesktop} {
    max-width: 22.5em;
  }
`;

const QuoteWrap = styled.div`
  grid-area: quote;
`;
const Blockquote = styled.blockquote`
  padding: 0;
  margin: 0;
  ${fontSerifDisplayRules}
  letter-spacing: -0.02em;
  ${fluidFontSize(32, 64)};
  line-height: 1.15;
  margin-top: -0.15em;
  text-wrap: balance; // supported in Chrome but not in safari
  font-weight: 300;
  &.long {
    ${fluidFontSize(32, 50)};
  }
  span {
    &:after {
      content: "”";
    }
  }
  ${fromTabletLg} {
    line-height: 1.075;
  }
  ${uptoTabletLg} {
    margin-top: 1em;
    span {
      &:before {
        content: "“";
      }
    }
  }
  svg {
    ${uptoTabletLg} {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5em;
      width: 1.25em;
      height: 1.25em;
    }
    ${fromTabletLg} {
      display: inline-block;
      width: 0.825em;
      height: 0.825em;
      margin-right: 0.25em;
      transform: translateY(0.125em);
    }
  }
  ${fromTablet} {
    font-weight: 275;
    letter-spacing: -0.02em;
  }
  ${fromDesktop} {
    font-weight: 250;
    letter-spacing: -0.025em;
  }
`;

const BlockquoteSource = styled.div`
  margin-top: 2em;
  strong {
    font-weight: 600;
  }
`;

const ResourceLinkSet = styled.div`
  margin-top: 0.5em;
  a {
    opacity: 0.8;
    text-decoration-color: ${withOpacity(colors.purple800, 0.3)};
    font-weight: 500;
    &:hover {
      opacity: 1;
      text-decoration-color: ${withOpacity(colors.purple800, 0.9)};
    }
  }
  p {
    margin-top: 0.5em;
  }
`;

const DataHighlightCardWithMomentum = styled.div`
  grid-area: dataCard;
  ${uptoTabletLg} {
    margin-top: 2em;
  }
  ${fromTabletLg} {
    padding-right: 2em;
  }
  ${fromDesktopMd} {
    padding-right: ${widthInGrid(1, 1)};
  }
  align-self: end;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
  > span {
    display: block;
    position: relative;
    &:before {
      content: "";
      aspect-ratio: 1/1;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      border-radius: ${rSize("radius")};
    }
    &:nth-child(4) {
      &:before {
        background-color: ${colors.purple400};
      }
      z-index: 4;
    }
    &:nth-child(3) {
      &:before {
        background-color: ${colors.purple300};
      }
      z-index: 3;
    }
    &:nth-child(2) {
      &:before {
        background-color: ${colors.purple200};
      }
      z-index: 2;
    }
    &:nth-child(1) {
      &:before {
        background-color: ${colors.purple100};
      }
      z-index: 1;
    }
  }
`;

const DataHighlightCard = styled.div`
  position: relative;
  background-color: ${colors.purple};
  color: ${colors.white};
  aspect-ratio: 1/1;
  max-width: 22.5rem;
  padding: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  font-size: 2rem;
  text-align: left;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr);
  grid-template-rows: auto minmax(auto, 1fr);
  grid-template-areas: "icon data" ". description";
  grid-gap: 1.6rem;
  z-index: 5;
  [data-browser="safari"] & {
    grid-template-rows: auto minmax(auto, 1fr);
  }
  ${mediaFromTo("tablet", "tabletLg")} {
    max-width: 30rem;
    font-size: 3.2rem;
  }
  ${fromDesktop} {
    font-size: 2.8rem;
  }
  ${fromDesktopMd} {
    max-width: ${widthInGrid(3)};
  }
  ${fromDesktopLg} {
    font-size: 3.2rem;
  }
`;

const DataHighlightData = styled.p`
  grid-area: data;
  line-height: 1;
  font-weight: 500;
  ${fromDesktop} {
    letter-spacing: -0.01em;
  }
`;
const DataHighlightDescription = styled.p`
  grid-area: description;
  align-self: end;
  font-size: 1.4rem;
  line-height: 1.25;
  [data-browser="safari"] & {
    align-self: start;
  }
  ${mediaFromTo("tablet", "tabletLg")} {
    font-size: 1.6rem;
  }
`;
const DataHighlightIcon = styled.div`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  background-color: ${colors.purple800};
  border-radius: 50%;
`;

const BottomSpacer = styled.div`
  height: 4em;
  ${fromTablet} {
    height: 5em;
  }
`;

const SolutionPageQuoteSection = ({ page, shaded }: Props) => {
  const orgName = page.quoteSectionQuoteAuthorOrgnization?.name ?? "";
  const quote = preventWidows(
    (page.quoteSectionQuoteContent ?? "")
      .trim()
      .replace(/^["“]/, "")
      .replace(/["”]$/, "")
  );
  return (
    <PageSection>
      <MobileTopSpacer />
      <ShadableSectionWrap className={cx(shaded && "shaded")}>
        <Content>
          {shaded ? <ShadableOrAdjacentSectionSpacer /> : <TopSpacer />}

          <Header>
            <SolutionPageAllcapsHeading>
              <WithCustomInlineFormatting as="span">
                {page.quoteSectionHeadline}
              </WithCustomInlineFormatting>
            </SolutionPageAllcapsHeading>
          </Header>

          <Grid>
            <DescriptionWrap>
              <WithCustomInlineFormatting as="p">
                {page.quoteSectionDescription}
              </WithCustomInlineFormatting>
            </DescriptionWrap>
            <QuoteWrap>
              <Blockquote className={cx(quote.length > 160 && "long")}>
                <div>
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill={colors.purple}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.80138 8.62441C0 12.0922 0 16.6481 0 25.76V54.24C0 63.3519 0 67.9078 1.80138 71.3756C3.31939 74.2979 5.70212 76.6806 8.62441 78.1986C12.0922 80 16.6481 80 25.76 80H54.24C63.3519 80 67.9078 80 71.3756 78.1986C74.2979 76.6806 76.6806 74.2979 78.1986 71.3756C80 67.9078 80 63.3518 80 54.24V25.76C80 16.6481 80 12.0922 78.1986 8.62441C76.6806 5.70212 74.2979 3.31939 71.3756 1.80138C67.9078 0 63.3518 0 54.24 0H25.76C16.6481 0 12.0922 0 8.62441 1.80138C5.70212 3.31939 3.31939 5.70212 1.80138 8.62441ZM38.84 37.8719H39.68V33.3359H38.28C33.744 33.3359 31 36.1919 31 41.1199V46.6639H36.432V41.5119C36.432 39.1039 37.328 37.8719 38.84 37.8719ZM48.584 37.8719H49.424V33.3359H48.024C43.432 33.3359 40.688 36.1919 40.688 41.1199V46.6639H46.12V41.5119C46.12 39.1039 47.016 37.8719 48.584 37.8719Z"
                    />
                  </svg>

                  <WithCustomInlineFormatting as="span">
                    {quote}
                  </WithCustomInlineFormatting>
                </div>
              </Blockquote>
              <BlockquoteSource>
                {page.quoteSectionQuoteAuthorName && (
                  <p>
                    <strong>{page.quoteSectionQuoteAuthorName}</strong>
                  </p>
                )}
                {(page.quoteSectionQuoteAuthorWorkTitle || orgName) && (
                  <p>
                    {orgName && orgName.length > 10 ? (
                      <>
                        {page.quoteSectionQuoteAuthorWorkTitle} <br />
                        {orgName}
                      </>
                    ) : (
                      preventWidows(
                        [page.quoteSectionQuoteAuthorWorkTitle, orgName]
                          .filter(i => i)
                          .join(", ")
                      )
                    )}
                  </p>
                )}
                {(page.quoteSectionResourceLinks ?? []).length > 0 && (
                  <ResourceLinkSet>
                    {page.quoteSectionResourceLinks?.map(l => {
                      return (
                        <p key={l?.id}>
                          <SmartLink src={l?.url ?? ""}>
                            → {l?.textLabel}
                          </SmartLink>
                        </p>
                      );
                    })}
                  </ResourceLinkSet>
                )}
              </BlockquoteSource>
            </QuoteWrap>
            {page.quoteSectionDataHighlightData && (
              <DataHighlightCardWithMomentum>
                <span />
                <span />
                <span />
                <span />
                <DataHighlightCard>
                  <DataHighlightIcon>
                    {(!page.quoteSectionDataHighlightIcon ||
                      page.quoteSectionDataHighlightIcon === "Arrow down") && (
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="currentColor"
                      >
                        <path d="M15.5078 9.88387L7.83111 17.5871L0.154408 9.91043L1.881 8.18383L6.42327 12.6995L6.42327 0.268066L9.21239 0.268066L9.21238 12.7261L13.7547 8.15727L15.5078 9.88387Z" />
                      </svg>
                    )}
                  </DataHighlightIcon>
                  <DataHighlightData>
                    {page.quoteSectionDataHighlightData}
                  </DataHighlightData>
                  <DataHighlightDescription>
                    {page.quoteSectionDataHighlightDescription}
                  </DataHighlightDescription>
                </DataHighlightCard>
              </DataHighlightCardWithMomentum>
            )}
          </Grid>

          <BottomSpacer />
        </Content>
      </ShadableSectionWrap>
    </PageSection>
  );
};

export default SolutionPageQuoteSection;
