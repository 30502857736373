import { styled } from "@linaria/react";
import {
  ACTION_TYPES,
  ActionTypeName,
} from "../../constants/actionTypes.constants";
import { colors } from "../../styles/colors.styles";

type Props = {
  size?: string | number;
  iconSize?: string | number;
  type: ActionTypeName;
  borderRadius?: string | number;
};

export const ActionIconRect = styled.span<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.size ?? "4.2rem"};
  height: ${p => p.size ?? "4.2rem"};
  border-radius: ${p => p.borderRadius ?? "0.5em"};
  color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  svg {
    width: ${p => p.iconSize ?? "auto"};
    height: ${p => p.iconSize ?? "auto"};
  }
`;

const ActionIcon = (props: Props) => {
  const definition = ACTION_TYPES[props.type];
  const { Icon } = definition;
  return (
    <ActionIconRect
      className="ActionIconRect"
      {...props}
      style={{
        backgroundColor: definition.color,
        borderColor: definition.border,
      }}
    >
      <Icon />
    </ActionIconRect>
  );
};

export default ActionIcon;
