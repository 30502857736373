export const GiftTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M5 9.5H13C13.2761 9.5 13.5 9.72386 13.5 10V13C13.5 13.8284 12.8284 14.5 12 14.5H6C5.17157 14.5 4.5 13.8284 4.5 13V10C4.5 9.72386 4.72386 9.5 5 9.5Z"
      stroke="currentColor"
    />
    <path
      opacity="0.2"
      d="M6 12C6 11.4477 6.44772 11 7 11H15C15.5523 11 16 11.4477 16 12V13C16 14.6569 14.6569 16 13 16H8C6.89543 16 6 15.1046 6 14V12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7H13C13.5523 7 14 7.44772 14 8V9H13H5H4L4 8C4 7.44772 4.44772 7 5 7ZM4 10C3.44772 10 3 9.55228 3 9V8C3 6.89543 3.89543 6 5 6H13C14.1046 6 15 6.89543 15 8V9C15 9.55228 14.5523 10 14 10V13C14 14.1046 13.1046 15 12 15H6C4.89543 15 4 14.1046 4 13L4 10ZM13 10H5V13C5 13.5523 5.44772 14 6 14H12C12.5523 14 13 13.5523 13 13V10Z"
      fill="currentColor"
    />
    <rect opacity="0.7" x="8" y="10" width="2" height="5" fill="currentColor" />
    <rect opacity="0.5" x="8" y="7" width="2" height="2" fill="currentColor" />
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7768 3.00262H10.6669C10.0239 3.00262 9.50261 3.52388 9.50261 4.16687V4.50131V6H9.69721L11.3506 4.89773C11.6893 4.67191 11.8641 4.2684 11.7971 3.86684C11.714 3.36812 11.2824 3.00262 10.7768 3.00262ZM11.9053 5.72978L9.99999 7H9.50261H9.49735L9.49736 7.00003L8.46674 7.00001L7.99999 7L6.08182 5.8491C5.71125 5.62676 5.45167 5.25841 5.36692 4.83465C5.17709 3.88552 5.90305 3 6.87097 3H7.13349C7.70644 3 8.22688 3.27837 8.54861 3.72089C8.75418 2.73957 9.62446 2.00262 10.6669 2.00262H10.7768C11.7712 2.00262 12.6199 2.72146 12.7835 3.7023C12.9153 4.49206 12.5715 5.28565 11.9053 5.72978ZM6.87097 4H7.13349C7.47738 4 7.7771 4.23416 7.8603 4.56783L8.20691 5.95797L6.59631 4.99161C6.46734 4.91422 6.37699 4.78602 6.3475 4.63854C6.28143 4.3082 6.53409 4 6.87097 4Z"
      fill="currentColor"
    />
  </svg>
);
