import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  ResponsiveSizeName,
  isResponsiveSizeName,
  rSize,
} from "../../styles/responsiveSizes.styles";

type Props = {
  size?: ResponsiveSizeName | string;
};

const SpaceChildrenContainer = styled.div<Props>`
  > * {
    + * {
      margin-top: ${p =>
        (isResponsiveSizeName(p.size) ? rSize(p.size ?? "gap") : p.size) ??
        "1em"};
    }
  }
`;

const SpaceChildren = (props: PropsWithChildren<Props>) => {
  return (
    <SpaceChildrenContainer size={props.size}>
      {props.children}
    </SpaceChildrenContainer>
  );
};

export default SpaceChildren;
