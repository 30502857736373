import { css } from "linaria";
import { useState } from "react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { reportErrorSilently } from "../../utils/error.utils";
import { isString } from "../../utils/typeChecks.utils";
import Button from "./Button";
import InfoBox from "../general/InfoBox";
import PrivacyNotice from "../utilities/PrivacyNotice";
import TextInput, { TextInputSet } from "./TextInput";
import Spacing from "../layout/Spacing";
import { brandColorThemeVar } from "../../styles/colors.styles";
import { getFormMetaInfo } from "../../utils/form.utils";

type Props = {
  className?: string;
  white?: boolean;
  color?: string;
};

const NewsletterSignupForm = (props: Props) => {
  const formState = useState({
    email: "",
    firstName: "",
    lastName: "",
    company: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const handleSubmit = async () => {
    const [form] = formState;
    if (!form.email) return;
    if (!form.email.includes("@")) {
      setError("Please enter a valid email address.");
      return;
    }
    setAwaitingResponse(true);
    if (!(await emailIsLegitimate(form.email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }
    const url =
      "https://hq.tines.io/webhook/4a63fc78e8bcb83b4cf83fa936181612/07a1f7348f53cf03e3e7e3bc36f3a126";
    try {
      await fetch(url, {
        method: "post",
        body: JSON.stringify({
          ...form,
          ...getFormMetaInfo(),
        }),
      });
      setError(null);
      setSuccess(true);
    } catch (e) {
      reportErrorSilently(e);
      setError(e);
    } finally {
      setAwaitingResponse(false);
    }
  };

  const SuccessMessage = () => {
    return (
      <InfoBox color={props.white ? colorsV4.white : brandColorThemeVar(500)}>
        You are all set! Thank you for subscribing to the Tines Newsletter.
      </InfoBox>
    );
  };

  return (
    <div className={props.className}>
      <>
        {success ? (
          <SuccessMessage />
        ) : (
          <>
            <TextInputSet>
              <TextInput
                type="email"
                name="email"
                formState={formState}
                placeholder="Your email *"
                required
              />
              <TextInput
                name="company"
                formState={formState}
                placeholder="Your company name"
              />
              <TextInput
                name="firstName"
                formState={formState}
                placeholder="First name"
              />
              <TextInput
                name="lastName"
                formState={formState}
                placeholder="Last name"
              />
            </TextInputSet>
            <Spacing size="1em" />
            <Button
              type="button"
              width="10em"
              color={props.color}
              appearance={props.white ? "filled-white" : "filled"}
              onClick={handleSubmit}
              loading={awaitingResponse}
            >
              Subscribe
            </Button>
            <PrivacyNotice linkColor="currentColor" />
          </>
        )}
        {error && (
          <InfoBox
            color={props.white ? colorsV4.white : brandColorThemeVar(500)}
            className={css`
              max-width: 62rem;
            `}
          >
            {isString(error)
              ? error
              : "An error occurred. Please try again later."}
          </InfoBox>
        )}
      </>
    </div>
  );
};

export default NewsletterSignupForm;
