import dayjs from "dayjs";
import { css } from "linaria";
import { DatoCmsYdwwt } from "../../../graphql-types";
import DateTimeFormatter from "../basic/DateTimeFormatter";
import Button from "../forms/Button";
import InfoBox from "../general/InfoBox";
import Spacing from "../layout/Spacing";
import { useOpenYDWWTSubmissionModalFunction } from "./YDWWTSubmissionModal";
import { styled } from "@linaria/react";

type Props = {
  ydwwt: DatoCmsYdwwt;
};

const SubmissionsOpenTill = styled.p`
  opacity: 0.6;
  font-size: 1.4rem;
`;

const YDWWTSubmitButtonWithNotice = (props: Props) => {
  const openModal = useOpenYDWWTSubmissionModalFunction();
  const submissionHasStarted = props.ydwwt.dateStart
    ? dayjs().isAfter(`${props.ydwwt.dateStart}`)
    : true;
  const submissionHasEnded = props.ydwwt.dateEnd
    ? dayjs().isAfter(`${props.ydwwt.dateEnd}`)
    : true;
  const submissionOpen = submissionHasStarted && !submissionHasEnded;
  return (
    <>
      {submissionOpen ? (
        <>
          <Button onClick={openModal}>Submit your story now</Button>
          <Spacing size="md" />
          <SubmissionsOpenTill>
            Submissions open until{" "}
            <DateTimeFormatter value={props.ydwwt.dateEnd} format="LL" />
          </SubmissionsOpenTill>
        </>
      ) : (
        <InfoBox
          color="white"
          className={css`
            margin: 0 auto;
            max-width: 33em;
          `}
        >
          The competition is now closed for submissions. Winners will be
          announced on{" "}
          <DateTimeFormatter
            value={props.ydwwt.dateWinnerAnnouncement}
            format="LL"
          />
          .
        </InfoBox>
      )}
    </>
  );
};

export default YDWWTSubmitButtonWithNotice;
