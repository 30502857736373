import { styled } from "@linaria/react";
import { cover } from "polished";
import { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  brandColorThemeVar,
  colors,
  getBrandColorThemeVariables,
  withOpacity,
} from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { EventDirectoryItem } from "../../types/helper.types";
import Button from "../forms/Button";
import EventCategoryList from "./EventCategoryList";
import EventDirectoryItemAddressAndMap from "./EventDirectoryItemAddressAndMap";
import EventDirectoryItemDates from "./EventDirectoryItemDates";
import EventDirectoryItemEntry from "./EventDirectoryItemEntry";
import EventDirectoryItemLocation from "./EventDirectoryItemLocation";
import PlaceholderImageFrame from "../general/PlaceholderImageFrame";
import StaticImageFrame from "../basic/StaticImageFrame";
import { AdminToolBarWithEditInDatoCmsButton } from "../site/AdminToolbar";
import { zIndex } from "../../styles/zIndexes.styles";
import { uniqBy } from "../../utils/array.utils";
import { serif } from "../../styles/fonts.styles";
import { Pill } from "../basic/Pill";
import { EventDirectoryItemPrimaryCategory } from "./EventDirectoryItemPrimaryCategory";

type Props = {
  item: EventDirectoryItem;
  allItems: EventDirectoryItem[];
  onShouldClose: () => void;
  onSelectItem: (item: EventDirectoryItem | null) => void;
};

const EventDirectoryItemDetailsScreen = styled.div`
  ${cover()};
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1em;
  .Button {
    width: 100% !important;
  }
  ${fromTablet} {
    padding: 2.5em;
    .Button {
      width: 12rem !important;
    }
  }
  ${fromTabletLg} {
    padding: 4em;
  }
  z-index: ${zIndex("Modal")};
  height: 100vh;
  overflow: auto;
`;

const Backdrop = styled.div`
  ${cover()};
  position: fixed;
  background-color: ${withOpacity(colors.dark500, 0.5)};
`;

const EventDirectoryItemDetailsPanelWrapper = styled.div`
  position: relative;
  overflow: auto;
  border-radius: ${rSize("radius")};
  background-color: ${colors.white};
  text-align: left;
  max-width: 76.8rem;
  font-size: 1.6rem;
  transform: translateZ(0);
  background-color: ${brandColorThemeVar(50)};
  color: ${brandColorThemeVar(800)};
  p {
    line-height: 1.15;
  }
`;

const TextContentWrapper = styled.div`
  position: relative;
  padding-left: ${rSize("lg")};
  padding-right: ${rSize("lg")};
  padding-bottom: ${rSize("lg")};
  border-top: 1px solid ${brandColorThemeVar(100)};
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
`;
const Header = styled.header`
  padding: ${rSize("lg")} 0 0 0;
  ${fromTablet} {
    display: flex;
    align-items: flex-end;
  }
`;
const HeaderInner = styled.div`
  flex: 1 1 auto;
  padding-right: 1.4rem;
  ${onlyPhones} {
    padding-bottom: 1.4rem;
  }
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
`;
const CategoryList = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  opacity: 0.6;
`;

const Title = styled.h3`
  font-size: 2.4rem;
  font-family: ${serif};
  font-weight: 400;
  ${fromTabletMd} {
    font-size: 3.2rem;
  }
`;
const EventDateTime = styled.p`
  font-weight: 600;
`;

const Content = styled.div`
  padding: 0;
  line-height: 1.25;
`;

const CloseButtonPositioner = styled.div`
  position: sticky;
  height: 0;
  z-index: 1;
  top: 0;
`;
const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: ${colors.dark500};
  color: ${colors.white};
  width: 42px;
  height: 42px;
  border: 0;
  padding: 0;
  border-radius: 21px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.dark500};
    color: ${colors.white};
  }
  svg {
    display: block;
  }
`;
const RelatedEventsSection = styled.section`
  h3 {
    font-size: 1.8rem;
  }
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
`;
const MapSection = styled.div`
  position: sticky;
  bottom: 0;
`;
const EventDirectoryItemDetailsPanel = (props: Props) => {
  const { place } = props.item;
  useHotkeys("escape", props.onShouldClose);
  const relatedEvents = uniqBy(
    props.item.relatedEventIds
      .map(id => props.allItems.find(i => id === i.id))
      .filter(i => i) as EventDirectoryItem[],
    "id"
  );
  const ref = useRef<HTMLDivElement>(null);
  const handleSelectRelatedEvent = (item: EventDirectoryItem) => {
    ref.current?.scrollTo({ top: 0, behavior: "smooth" });
    props.onSelectItem(item);
  };
  const isWebinar = props.item.type !== "event";
  return (
    <EventDirectoryItemDetailsScreen
      ref={ref}
      style={
        props.item.color && props.item.color !== "Purple"
          ? getBrandColorThemeVariables(props.item.color)
          : undefined
      }
    >
      <AdminToolBarWithEditInDatoCmsButton
        itemType={isWebinar ? "webinar" : "event"}
        recordId={props.item.id}
      />
      <Backdrop onClick={props.onShouldClose} />
      <EventDirectoryItemDetailsPanelWrapper>
        <CloseButtonPositioner>
          <CloseButton onClick={props.onShouldClose}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path
                d="M11 1L1 11M1 1L11 11"
                stroke="currentColor"
                strokeWidth={1.5}
              />
            </svg>
          </CloseButton>
        </CloseButtonPositioner>
        {props.item.coverImageUrl ? (
          <StaticImageFrame
            src={props.item.coverImageUrl}
            aspectRatio="1200/630"
          />
        ) : (
          <PlaceholderImageFrame />
        )}
        <TextContentWrapper>
          <Header>
            <HeaderInner>
              <Pill allCaps>
                <EventDirectoryItemPrimaryCategory item={props.item} />
              </Pill>
              <Title>{props.item.name}</Title>
              <EventDateTime>
                <EventDirectoryItemDates item={props.item} displayTimezone />
              </EventDateTime>
              {place && <EventDirectoryItemLocation item={props.item} />}
              {props.item.categories.length > 1 && (
                <CategoryList>
                  <EventCategoryList event={props.item} />
                </CategoryList>
              )}
            </HeaderInner>
            {(isWebinar ||
              props.item.url ||
              props.item.externalRegistrationLink ||
              props.item.hasLandingPage) && (
              <Button
                href={
                  props.item.url ||
                  props.item.externalRegistrationLink ||
                  `/${props.item.type}s/${props.item.slug}`
                }
                {...externalLinkAttr}
              >
                Register ↗
              </Button>
            )}
          </Header>
          {props.item.excerpt && (
            <Content>
              <div>{props.item.excerpt}</div>
            </Content>
          )}
          {relatedEvents.length > 0 && (
            <RelatedEventsSection>
              <h3>
                You can also join us at the following event
                {relatedEvents.length === 1 ? "" : "s"}:
              </h3>
              {relatedEvents.map(e => (
                <EventDirectoryItemEntry
                  item={e}
                  key={e.id}
                  landscape
                  onSelect={handleSelectRelatedEvent}
                />
              ))}
            </RelatedEventsSection>
          )}
          {place && (
            <MapSection>
              <EventDirectoryItemAddressAndMap item={props.item} />
            </MapSection>
          )}
        </TextContentWrapper>
      </EventDirectoryItemDetailsPanelWrapper>
    </EventDirectoryItemDetailsScreen>
  );
};

export default EventDirectoryItemDetailsPanel;
