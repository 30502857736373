export const PageTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      d="M3 4C3 2.89543 3.89543 2 5 2H13C14.1046 2 15 2.89543 15 4V6H3V4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H13C13.5523 3 14 3.44772 14 4V11H11.5C10.6716 11 10 11.6716 10 12.5V15H5C4.44772 15 4 14.5523 4 14V4C4 3.44772 4.44772 3 5 3ZM11 14.5858L13.5858 12H11.5C11.2239 12 11 12.2239 11 12.5V14.5858ZM3 4C3 2.89543 3.89543 2 5 2H13C14.1046 2 15 2.89543 15 4V11.1716C15 11.702 14.7893 12.2107 14.4142 12.5858L11.5858 15.4142C11.2107 15.7893 10.702 16 10.1716 16H5C3.89543 16 3 15.1046 3 14V4ZM5 8.5C5 8.22386 5.22386 8 5.5 8H10.5C10.7761 8 11 8.22386 11 8.5C11 8.77614 10.7761 9 10.5 9H5.5C5.22386 9 5 8.77614 5 8.5ZM5.5 10C5.22386 10 5 10.2239 5 10.5C5 10.7761 5.22386 11 5.5 11H7.5C7.77614 11 8 10.7761 8 10.5C8 10.2239 7.77614 10 7.5 10H5.5Z"
    />
  </svg>
);
