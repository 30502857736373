import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import gsap from "gsap";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { colors } from "../../../styles/colors.styles";

const quotes = [
  [
    "🪁",
    "I feel more comfortable, and I can now say that I can find my way in Tines.",
  ],
  [
    "🌱",
    "This certification path indeed helps a lot in making someone comfortable with Tines.",
  ],
  [
    "🎯",
    "Really enjoyed the journey so far and will continue building new stories! Thanks all for this awesome content.",
  ],
  [
    "👌",
    "I've been using Tines for the better part of a year and I still learned some new stuff.",
  ],
] as const;

const duration = quotes.reduce((sum, [, text]) => text.length / 7 + sum, 0);

const QuoteSetWrap = styled.div`
  display: flex;
`;
const Quote = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled.div`
  font-size: 24px;
  padding-left: 0.5em;
  padding-right: 0.5em;
`;
const Body = styled.div`
  white-space: nowrap;
`;

const QuoteSet = () => {
  return (
    <QuoteSetWrap>
      {quotes.map(([icon, body], i) => (
        <Quote key={i}>
          <Icon>{icon}</Icon>
          <Body>“{body}”</Body>
        </Quote>
      ))}
    </QuoteSetWrap>
  );
};

const Marquee = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: crosshair;
  border-bottom: 1px solid ${colors.purple200};
  font-weight: 600;
  overflow: hidden;
`;

const MarqueeContent = styled.div`
  display: inline-flex;
`;

const UniversityTestimonialMarquee = () => {
  const [tl, setTl] = useState<GSAPTimeline | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const timeline = gsap.timeline({
      repeat: -1,
    });
    timeline.fromTo(
      contentRef.current,
      {
        x: 0,
      },
      {
        x: "-50%",
        ease: "linear",
        duration,
      }
    );
    setTl(timeline);
    return () => {
      tl?.kill();
    };
  });
  const pause = () => {
    gsap.to(tl, {
      timeScale: 0.1,
      duration: 2,
    });
  };
  const play = () => {
    gsap.to(tl, {
      timeScale: 1,
      duration: 2,
    });
  };
  const toggle = () => {
    if (!tl) return;
    if (tl.timeScale() < 1) play();
    else pause();
  };
  return (
    <Marquee onPointerEnter={pause} onPointerLeave={play} onPointerUp={toggle}>
      <MarqueeContent ref={contentRef}>
        <QuoteSet />
        <QuoteSet />
      </MarqueeContent>
    </Marquee>
  );
};

export default UniversityTestimonialMarquee;
