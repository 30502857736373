import { Link } from "gatsby";
import { PropsWithChildren } from "react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { isSameOrigin } from "../../utils/urls.utils";
import LinkToAnchor from "./LinkToAnchor";

type Props = {
  className?: string;
  src: string;
  attr?: Record<string, unknown>;
};

const SmartLink = (props: PropsWithChildren<Props>) => {
  let url = props.src.replace(/^https:\/\/hub\.tines\.com\//, "/");
  if (isSameOrigin(url)) {
    url = url.replace(/^https?:\/\/(www\.)?tines\.com\//, "/");
    if (url.includes("#")) {
      return (
        <LinkToAnchor className={props.className} to={url} {...props.attr}>
          {props.children}
        </LinkToAnchor>
      );
    } else if (/^(mailto|sms)\:/.test(url)) {
      return (
        <a className={props.className} href={url} {...props.attr}>
          {props.children}
        </a>
      );
    } else {
      return (
        <Link className={props.className} to={url} {...props.attr}>
          {props.children}
        </Link>
      );
    }
  } else {
    return (
      <a
        className={props.className}
        href={url}
        {...props.attr}
        {...externalLinkAttr}
      >
        {props.children}
      </a>
    );
  }
};

export default SmartLink;
