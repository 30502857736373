import { styled } from "@linaria/react";
import img from "./safeguards.svg";
import { colors } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";

const SafeguardsIlloCardDiv = styled.div`
  overflow: hidden;
  background-color: ${colors.green50};
  border: 1px solid ${colors.green300};
  aspect-ratio: 1 / 1;
  border-radius: ${rSize("radius")};
  img {
    display: block;
    max-width: 100%;
  }
`;

export const SafeguardsIlloCard = () => {
  return (
    <SafeguardsIlloCardDiv>
      <img src={img} />
    </SafeguardsIlloCardDiv>
  );
};
