export const DartTarget = () => (
  <svg
    width="81"
    height="85"
    viewBox="0 0 81 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M79.1424 50.9795L79.1357 46.4345L79.1424 46.4316L79.134 40.8124L79.1253 35.1926L71.212 32.0401C70.6951 30.212 70.0179 28.4503 69.2023 26.7686L71.3543 21.7265V17.1789L67.3742 13.2103L63.3948 9.24401L55.7249 12.5431C53.5531 11.3401 51.2281 10.3791 48.7865 9.69853L45.6338 1.84961L40.0137 1.85827L34.3936 1.86738L31.2633 9.72429C28.8246 10.4135 26.5032 11.3825 24.3345 12.5915L16.6541 9.3144L12.686 13.2943L8.71973 17.273V21.8206L10.8926 26.8721C10.0857 28.5511 9.41627 30.3095 8.90536 32.1351L1.00226 35.31L1.00893 39.855L1.00226 39.8579L1.0107 45.4775L1.01891 51.0974L8.93179 54.2492C9.44936 56.0775 10.1266 57.839 10.9424 59.5205L8.79056 64.5641V69.112L16.2549 76.3074L24.4196 73.7455C26.5922 74.949 28.9168 75.9107 31.3581 76.5919L34.5111 84.4404L40.131 84.4315L45.7519 84.4241L48.8802 76.5648C51.3191 75.8765 53.6419 74.9081 55.8108 73.6978L63.4914 76.9769L67.4579 72.997L71.4252 69.0161V64.4684L69.2527 59.4179C70.0596 57.7384 70.7291 55.9794 71.2391 54.1544L79.1424 50.9795ZM64.5148 43.3859C63.1242 56.8842 51.0562 66.7013 37.5575 65.3115C25.6916 64.0912 16.6708 54.6158 15.6063 43.173C15.6148 43.083 15.6212 42.9933 15.6308 42.9032C17.0201 29.4049 29.0873 19.5867 42.5885 20.9764C54.4544 22.1972 63.4748 31.6712 64.539 43.1161C64.5306 43.2063 64.5239 43.296 64.5148 43.3859Z"
      fill="#80405B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.3583 72.044L34.5113 79.8925L40.1312 79.8836L45.7521 79.8763L48.8805 72.0169C51.3194 71.3286 53.6421 70.3601 55.811 69.1499L63.4916 72.4288L67.4582 68.4492L71.4254 64.4682L68.1789 56.9213C69.4821 54.6431 70.5177 52.1891 71.2393 49.6066L79.1424 46.4316L79.134 40.8124L79.1253 35.1926L71.212 32.0401C70.4826 29.4602 69.4399 27.0099 68.1289 24.7351L71.3543 17.1789L67.3742 13.2103L63.3948 9.24401L55.7249 12.5431C53.5531 11.3401 51.2281 10.3791 48.7865 9.69853L45.6338 1.84961L40.0137 1.85827L34.3936 1.86737L31.2633 9.72429C28.8246 10.4135 26.5032 11.3825 24.3345 12.5915L16.6541 9.3144L12.686 13.2943L8.71973 17.273L11.9666 24.8211C10.663 27.0985 9.62877 29.5514 8.90536 32.1351L1.00226 35.31L1.0107 40.9297L1.01891 46.5493L8.93179 49.7014C9.66208 52.2811 10.7046 54.7304 12.0157 57.0056L8.79056 64.5641L16.2549 71.7596L24.4196 69.1979C26.5925 70.4012 28.917 71.3626 31.3583 72.044ZM15.6308 38.3549C17.0201 24.8568 29.0873 15.0384 42.5885 16.4284C56.0864 17.817 65.9041 29.8849 64.5148 43.3855C63.1242 56.8838 51.0562 66.7008 37.5575 65.3111C24.0587 63.9227 14.2408 51.8528 15.6308 38.3549Z"
      fill="#F67269"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.417 64.6782L35.8251 70.6717L40.1169 70.6648L44.4098 70.6595L46.7992 64.6573C48.6615 64.1317 50.4351 63.3923 52.0918 62.4677L57.9572 64.9717L60.9867 61.9324L64.0165 58.8924L61.5369 53.1289C62.5324 51.389 63.3233 49.5148 63.8744 47.5426L69.9096 45.1181L69.9034 40.8268L69.897 36.5347L63.8535 34.1273C63.2964 32.1572 62.5002 30.2858 61.499 28.5483L63.9625 22.7779L60.9232 19.7473L57.8839 16.7182L52.0267 19.2377C50.3679 18.3189 48.5924 17.5853 46.7277 17.0653L44.3199 11.0713L40.0279 11.078L35.7363 11.0848L33.3455 17.085C31.4831 17.6113 29.7103 18.3513 28.0541 19.2746L22.1887 16.772L19.1587 19.8115L16.1294 22.8499L18.6091 28.6143C17.6135 30.3535 16.8237 32.2269 16.2708 34.1999L10.2353 36.6246L10.242 40.9162L10.2482 45.2076L16.291 47.615C16.8488 49.5854 17.6448 51.4558 18.6462 53.1931L16.1834 58.9655L21.8836 64.4608L28.1185 62.5042C29.7776 63.4232 31.553 64.1575 33.417 64.6782ZM24.8067 39.2998C25.6745 30.8691 33.2114 24.737 41.6436 25.6048C50.0739 26.4723 56.2057 34.0099 55.338 42.4414C54.4698 50.8723 46.9324 57.0038 38.5013 56.1356C30.0707 55.2685 23.9386 47.7298 24.8067 39.2998Z"
      fill="#FFDCE8"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.3912 57.6152L37.0852 61.8302L40.1036 61.8253L43.1229 61.8218L44.8033 57.5999C46.1131 57.2304 47.3608 56.71 48.526 56.06L52.6511 57.8211L54.7818 55.6837L56.913 53.5454L55.169 49.4916C55.8685 48.268 56.4249 46.9499 56.813 45.5626L61.0574 43.8574L61.0529 40.8391L61.0485 37.8205L56.7977 36.1272C56.4058 34.7417 55.8461 33.4254 55.1417 32.2035L56.8746 28.1446L54.7367 26.013L52.5989 23.8828L48.4796 25.6547C47.313 25.0083 46.0641 24.4923 44.7525 24.1268L43.0592 19.9111L40.0403 19.9158L37.0219 19.9207L35.3406 24.1406C34.0306 24.5109 32.7836 25.0314 31.619 25.6809L27.4937 23.9207L25.3625 26.0586L23.2318 28.195L24.9757 32.2495C24.2759 33.4729 23.7203 34.7903 23.3313 36.1783L19.0865 37.8836L19.0912 40.902L19.0959 43.9204L23.346 45.6132C23.7385 46.9992 24.2981 48.3148 25.0024 49.5367L23.2702 53.5967L27.2796 57.4613L31.6647 56.0851C32.8318 56.7322 34.0807 57.2489 35.3912 57.6152ZM32.4214 40.0831C32.8566 35.8574 36.6338 32.7844 40.8603 33.2194C45.0851 33.6541 48.1586 37.4317 47.7236 41.6578C47.2884 45.8833 43.5106 48.9563 39.2851 48.5211C35.0599 48.0866 31.9864 44.3079 32.4214 40.0831Z"
      fill="#F67269"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.1875 51.1908L38.2315 53.7884L40.092 53.7853L41.9525 53.7831L42.9881 51.1812C43.7954 50.9534 44.5641 50.6328 45.2822 50.2322L47.8243 51.3178L49.1373 50.0006L50.4506 48.6826L49.376 46.1842C49.8067 45.4301 50.1498 44.6179 50.3889 43.7633L53.0046 42.7121L53.0019 40.8523L52.999 38.9921L50.3796 37.9487C50.138 37.0945 49.7932 36.2836 49.3586 35.5304L50.4269 33.0287L49.1095 31.7153L47.7921 30.4024L45.2533 31.4946C44.5339 31.0963 43.7645 30.7783 42.9561 30.5532L41.9123 27.9551L40.0525 27.958L38.1922 27.9611L37.1557 30.5621C36.3486 30.7901 35.5801 31.1109 34.862 31.5111L32.3199 30.4262L31.0072 31.7435L29.6938 33.0607L30.7685 35.5591C30.3373 36.3131 29.9947 37.1251 29.7556 37.9802L27.1395 39.0309L27.1421 40.8912L27.145 42.7514L29.7642 43.7944C30.006 44.6488 30.3511 45.4594 30.7852 46.2126L29.7174 48.7148L32.1885 51.0966L34.8909 50.2486C35.6103 50.6468 36.3797 50.9652 37.1875 51.1908ZM37.3356 40.5899C37.491 39.078 38.8426 37.9784 40.3549 38.1341C41.8663 38.2895 42.9661 39.6411 42.8104 41.1532C42.6548 42.6651 41.3034 43.7646 39.7911 43.6087C38.2792 43.4535 37.1797 42.1015 37.3356 40.5899Z"
      fill="#FFDCE8"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.9325 54.25V49.7021"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M16.2545 76.3076V71.7598"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.4197 73.7465V68.8545"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.3584 76.5928V72.0449"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.5113 84.4412V79.8936"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.752 84.4246V79.877"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.8807 76.5654V72.0176"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M55.8108 73.6992V69.1514"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M63.4912 76.9775V72.4297"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.2392 54.155V49.6074"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.0728 44.135C41.8753 44.135 43.3366 42.6737 43.3366 40.8712C43.3366 39.0687 41.8753 37.6074 40.0728 37.6074C38.2703 37.6074 36.809 39.0687 36.809 40.8712C36.809 42.6737 38.2703 44.135 40.0728 44.135Z"
      fill="#F67269"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.6799 41.8601L57.3458 70.4714L63.0598 72.9203L65.7418 70.5877L42.3498 41.0752L38.6799 41.8601Z"
      fill="#4B1D3B"
    />
    <path
      d="M58.5898 70.8975V75.0181L62.5163 77.0007L65.4704 74.7459V70.9365L62.7495 72.8801L58.5898 70.8975Z"
      fill="#4B1D3B"
    />
    <path
      d="M76.5584 6.54492C78.898 8.85126 81.0541 13.114 77.211 17.0128C73.3677 20.9112 64.981 22.9702 62.6418 20.664L76.5584 6.54492Z"
      fill="#B38847"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.4391 8.13672C77.7788 10.4431 79.9354 14.7058 76.0924 18.6048C72.2488 22.5032 63.8619 24.562 61.5229 22.2559L75.4391 8.13672Z"
      fill="#FABE64"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.3485 42.0179C40.9384 42.4209 40.0223 42.4594 39.2844 41.7084C38.5466 40.9579 38.6192 40.0622 39.0289 39.6592L74.9713 1.94135C75.3812 1.53834 77.0656 0.895093 78.7724 2.64233C80.4799 4.38935 79.8224 6.05488 79.4116 6.45788L41.3485 42.0179Z"
      fill="#B36847"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M79.8043 5.98676C80.4647 5.34166 80.0332 3.82916 78.8407 2.60849C77.6482 1.38781 76.1462 0.92122 75.4859 1.56632C74.8255 2.21142 75.2569 3.72392 76.4495 4.94459C77.642 6.16526 79.144 6.63186 79.8043 5.98676Z"
      fill="#FBB598"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M72.7345 3.8256C70.4049 1.50949 66.1202 -0.603671 62.2604 3.27871C59.4831 6.07221 57.6826 11.2091 57.7594 14.7331C57.4222 17.0235 57.648 19.0236 58.611 19.9815L72.5889 5.923L72.7345 3.8256Z"
      fill="#B38847"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M72.5886 5.92229C70.2585 3.60596 65.9738 1.49325 62.1141 5.37519C58.2548 9.25757 56.2815 17.6651 58.6107 19.9808L72.5886 5.92229Z"
      fill="#FABE64"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M76.2183 9.01156C76.2183 9.01156 78.4003 11.7433 78.5171 13.8132L78.4729 15.29C78.4729 15.29 79.7934 11.1989 78.9283 9.28112L77.7058 7.49902L76.2183 9.01156Z"
      fill="#4B1D3B"
    />
  </svg>
);
