import { styled } from "@linaria/react";
import { externalLinkAttr } from "../../../../constants/externalLinks.constants";
import ComboFontHeading from "../../../typography/ComboFontHeading";
import StandardArticle from "../../../articles/StandardArticle";
import ReportSectionHeader from "../../ReportSectionHeader";
import ReportCard, { ReportCardInnerAligner } from "../../ReportCard";
import { ReportBaseSectionStyle } from "../../ReportBaseStyles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import { colorsV4, withOpacity } from "../../../../styles/colorsV4.styles";
import SOCChartDemographics from "../charts/SOCChartDemographics";
import DonutChart from "../../charts/DonutChart";
import {
  fromTablet,
  onlyPhones,
} from "../../../../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";

const SOCMethodologySectionContainer = styled.section`
  ${ReportBaseSectionStyle};
  > * + * {
    margin-top: ${rSize("gap")};
  }
  ${onlyPhones} {
    text-align: center;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const SOCMethodologySection = () => {
  return (
    <ReportSection
      id="methodology-and-participant-demographics"
      fullWidthWhenSmall
    >
      <SOCMethodologySectionContainer>
        <ReportSectionHeader icon="plantWithStickies">
          <ComboFontHeading
            snippets={[
              [
                <>
                  Methodology and
                  <br />
                </>,
              ],
              [<>participant demographics</>, "serif"],
            ]}
          />
        </ReportSectionHeader>
        <ReportCard
          className={css`
            ${onlyPhones} {
              text-align: center;
            }
          `}
        >
          <StandardArticle>
            <p>
              In order to provide greater context around these findings, here
              are more details on who we surveyed and the methodology used.
              Starting on December 9, 2021 we surveyed 468 full-time security
              analysts in the United States who worked at companies with 500 or
              more employees. The survey was conducted online via Pollfish using
              organic sampling. Learn more about the Pollfish methodology{" "}
              <a
                href="https://www.pollfish.com/methodology"
                {...externalLinkAttr}
              >
                here
              </a>
              .
            </p>
          </StandardArticle>
        </ReportCard>

        <SOCChartDemographics />

        <ReportCard centered>
          <h3>What best describes the industry you work in?</h3>
          <DonutChart
            seoTitle="What best describes the industry you work in?"
            height={380}
            header={["Industry", "Respondents"]}
            data={[
              ["Technology", 211],
              ["Finance", 34],
              ["Insurance", 19],
              ["Healthcare", 40],
              ["Utilities/Energy", 23],
              ["Federal", 10],
              ["State/Local Gov", 15],
              ["Education", 24],
              ["Manufacturing", 47],
              ["Services", 18],
              ["Retail", 11],
              ["Other", 16],
            ]}
            sortByValue
            donutPercentage={0.5}
            legendPosition="right"
          />
        </ReportCard>
        <Grid>
          <ReportCard centered>
            <ReportCardInnerAligner>
              <h3>How many people are on your SOC team in total?</h3>
              <DonutChart
                seoTitle="How many people are on your SOC team in total?"
                header={["Number of people", "Respondents"]}
                data={[
                  ["<10", 37],
                  ["10–20", 59],
                  ["20–30", 85],
                  ["30–40", 86],
                  ["40-50", 105],
                  ["50+", 96],
                ]}
                donutPercentage={0.375}
                height={250}
              />
            </ReportCardInnerAligner>
          </ReportCard>
          <ReportCard centered>
            <ReportCardInnerAligner>
              <h3>Is all or part of your SOC team remote?</h3>
              <DonutChart
                seoTitle="Is all or part of your SOC team remote?"
                header={["Answer", "Respondents"]}
                data={[
                  ["Yes", 362],
                  ["No", 106],
                ]}
                colors={[colorsV4.purple, colorsV4.warmBlack]}
                donutPercentage={0.375}
                height={250}
              />
            </ReportCardInnerAligner>
          </ReportCard>
          <ReportCard centered>
            <ReportCardInnerAligner>
              <h3>
                How many different tools do you use for your
                security-related work?
              </h3>
              <DonutChart
                seoTitle="How many different tools do you use for your security-related work?"
                header={["Number of People", "Respondents"]}
                data={[
                  ["1–10", 64],
                  ["11–20", 103],
                  ["21-30", 144],
                  ["31–40", 91],
                  ["41+", 66],
                ]}
                donutPercentage={0.375}
                height={250}
                colors={[
                  colorsV4.green,
                  colorsV4.orange,
                  colorsV4.purple,
                  colorsV4.pink,
                  colorsV4.yellow,
                ]}
              />
            </ReportCardInnerAligner>
          </ReportCard>
          <ReportCard centered>
            <ReportCardInnerAligner>
              <h3>What tier/level of analyst are you?</h3>
              <DonutChart
                seoTitle="What tier/level of analyst are you?"
                header={["Tier", "Respondents"]}
                data={[
                  ["Tier 1", 92],
                  ["Tier 2", 111],
                  ["Tier 3", 224],
                  ["Other", 41],
                ]}
                donutPercentage={0.375}
                height={250}
                colors={[
                  colorsV4.green,
                  colorsV4.orange,
                  colorsV4.purple,
                  colorsV4.pink,
                ]}
              />
            </ReportCardInnerAligner>
          </ReportCard>
        </Grid>
      </SOCMethodologySectionContainer>
      <ReportSummaryCard
        cornerDotsColor={withOpacity(colorsV4.warmBlack, 0.25)}
        standalone
      >
        <blockquote>
          <p>
            Now that we know a bit more about our respondents — security
            analysts, the majority of whom are Level 3 and work in the
            technology industry — let’s explore their day-to-day experiences on
            their SOC team.
          </p>
        </blockquote>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default SOCMethodologySection;
