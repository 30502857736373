export const AIPortalIcon = () => (
  <svg
    width="78"
    height="108"
    viewBox="0 0 78 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.1017 107.097C25.5003 107.097 19.3382 106.205 19.3382 105.106C19.3382 104.006 25.5003 103.115 33.1017 103.115C40.7031 103.115 46.8652 104.006 46.8652 105.106C46.8652 106.205 40.7031 107.097 33.1017 107.097Z"
      fill="#32274C"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.5265 27.6119C47.5262 29.0189 48.6669 30.1601 50.0744 30.1598L63.985 30.2166C65.3925 30.2172 66.5335 29.076 66.5332 27.6687C66.5332 26.2615 65.3922 25.1205 63.9853 25.1205L50.0747 25.0637C48.6669 25.0637 47.5262 26.2047 47.5265 27.6119Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.6523 36.4216C35.6523 37.8286 36.793 38.9698 38.2005 38.9698L56.8913 39.0266C58.2988 39.0271 59.4397 37.8859 59.4395 36.4784C59.4395 35.0712 58.2985 33.9305 56.8913 33.9302L38.2005 33.8734C36.793 33.8734 35.6523 35.0144 35.6523 36.4216Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.8001 48.6533C36.8001 51.5413 39.1412 53.883 42.03 53.883H56.7791C59.6671 53.883 62.0083 51.5413 62.0088 48.6536C62.0083 45.7653 59.6671 43.4239 56.7791 43.4242H42.03C39.1412 43.4239 36.8001 45.7653 36.8001 48.6533Z"
      fill="#5EBC89"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.1762 39.3135C64.1762 41.0685 65.5991 42.4914 67.3543 42.4914H73.7525C75.5075 42.4914 76.9307 41.0688 76.9307 39.3135C76.9307 37.5583 75.5078 36.1351 73.7523 36.1351H67.3543C65.5991 36.1351 64.1762 37.558 64.1762 39.3135Z"
      fill="#FA975F"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.0384 63.3317C48.0384 64.9268 49.3317 66.2199 50.9272 66.2199H61.0663C62.6617 66.2199 63.9551 64.9271 63.9551 63.3317C63.9551 61.7357 62.6617 60.4429 61.0663 60.4426H50.9272C49.3317 60.4426 48.0384 61.7357 48.0384 63.3317Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.233 17.3537C41.233 19.7309 43.1598 21.6577 45.537 21.6577H51.1821C53.5596 21.6577 55.4866 19.7309 55.4863 17.3537C55.4866 14.9759 53.5591 13.0492 51.1818 13.0492H45.5375C43.16 13.0489 41.233 14.9759 41.233 17.3537Z"
      fill="#FBBD74"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.3871 72.8237C37.3871 75.2009 39.3141 77.1276 41.6913 77.1276H47.3364C49.7136 77.1276 51.6409 75.2009 51.6406 72.8237C51.6409 70.4459 49.7133 68.5192 47.3361 68.5192H41.6916C39.3141 68.5189 37.3871 70.4459 37.3871 72.8237Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.2976 0.841797C29.9794 0.841797 31.596 1.67536 33.102 3.20602C34.6077 1.67482 36.2239 0.842064 37.9061 0.842064C46.0556 0.841799 52.6611 20.3534 52.6611 44.4225C52.6611 68.4911 46.0556 88.0029 37.9061 88.0029C36.2239 88.0029 34.6077 87.1694 33.102 85.6387C31.5958 87.1697 29.9796 88.0027 28.2976 88.0029C20.1484 88.0029 13.5421 68.4911 13.5424 44.4222C13.5421 20.3537 20.1482 0.841797 28.2976 0.841797Z"
      fill="#FBBD74"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.0249 79.5841C22.9473 79.5841 18.8311 63.8419 18.8311 44.4231C18.8311 25.0042 22.9473 9.26208 28.0249 9.26208C33.1025 9.26208 37.2188 25.0042 37.2188 44.4231C37.2188 63.8419 33.1025 79.5841 28.0249 79.5841Z"
      fill="#E49307"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.2188 44.4227C37.2188 58.3229 35.1096 70.3383 32.0486 76.0453C28.9875 70.3381 26.8779 58.3226 26.8781 44.4229C26.8779 30.523 28.9872 18.5075 32.0486 12.8C35.1096 18.5075 37.2188 30.5227 37.2188 44.4227Z"
      fill="#32274C"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.5507 35.329C17.4679 35.329 14.1581 32.0192 14.1581 27.9364C14.1581 23.8535 17.4679 20.5437 21.5507 20.5437C25.6336 20.5437 28.9434 23.8535 28.9434 27.9364C28.9434 32.0192 25.6336 35.329 21.5507 35.329Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.838 47.6885C9.36789 47.6885 7.36547 45.6861 7.36547 43.216C7.36547 40.7458 9.36789 38.7434 11.838 38.7434C14.3081 38.7434 16.3105 40.7458 16.3105 43.216C16.3105 45.6861 14.3081 47.6885 11.838 47.6885Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.3339 41.1449C35.9742 41.1446 36.5944 41.2301 37.1842 41.3886C37.2068 42.3886 37.2188 43.4005 37.2188 44.4231C37.2188 48.2033 37.0621 51.8436 36.773 55.2564C36.309 55.3517 35.8269 55.4022 35.3339 55.4024C31.3971 55.4022 28.2052 52.2105 28.2052 48.2737C28.2054 44.336 31.3971 41.1446 35.3339 41.1449Z"
      fill="#5EBC89"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.0467 15.3591C24.0033 15.4782 23.9636 15.5906 23.9276 15.6951H30.504L31.3288 12.9389L28.8148 9.77952C28.154 9.74885 27.5358 10.0171 26.9423 10.5211C26.2684 11.0933 25.6736 11.9334 25.1755 12.8375C24.6811 13.7348 24.3025 14.6569 24.0467 15.3591Z"
      fill="#32274C"
      stroke="#32274B"
      strokeWidth="1.3"
    />
    <path
      d="M27.6091 65.2928C26.0697 65.2928 24.8217 64.0448 24.8217 62.5054C24.8217 60.966 26.0697 59.718 27.6091 59.718C29.1485 59.718 30.3965 60.966 30.3965 62.5054C30.3965 64.0448 29.1485 65.2928 27.6091 65.2928Z"
      fill="#EE86B7"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.3924 70.4847C12.7431 70.4847 11.406 69.1476 11.406 67.4982C11.406 65.8488 12.7431 64.5117 14.3924 64.5117C16.0418 64.5117 17.3789 65.8488 17.3789 67.4982C17.3789 69.1476 16.0418 70.4847 14.3924 70.4847Z"
      fill="#A88FEF"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.8684 35.8347L19.7097 38.6933C20.9177 38.4446 21.7883 37.689 22.3968 36.8967C22.6409 36.5789 22.8375 36.2613 22.9908 35.9799C22.938 35.9804 22.885 35.9806 22.832 35.9806C22.3168 35.9806 21.7401 35.9575 21.2044 35.9273C20.701 35.8989 20.2282 35.8639 19.8684 35.8347Z"
      fill="#32274C"
      stroke="#32274B"
      strokeWidth="1.3"
    />
    <path
      d="M27.3379 65.8265C27.6208 66.1133 28.02 66.3964 28.5825 66.5675L28.5479 66.0127L27.3379 65.8265Z"
      fill="#32274C"
      stroke="#32274B"
      strokeWidth="1.3"
    />
    <path
      d="M18.005 53.0948C17.7613 53.7128 17.0559 54.4179 16.4382 54.661L13.8738 55.6727C13.2553 55.9169 13.2553 56.3162 13.8738 56.5594L16.4382 57.5713C17.0559 57.8145 17.7613 58.5196 18.005 59.1386L19.0167 61.7025C19.2604 62.3199 19.6593 62.3199 19.903 61.7025L20.9145 59.1386C21.1584 58.5196 21.8627 57.8145 22.4807 57.5713L25.0459 56.5594C25.6644 56.3162 25.6644 55.9169 25.0459 55.6727L22.4807 54.661C21.8627 54.4179 21.1584 53.7128 20.9145 53.0948L19.903 50.5299C19.6593 49.9124 19.2604 49.9124 19.0167 50.5299L18.005 53.0948Z"
      fill="#FFD29F"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.98634 28.7913C4.80688 29.2471 4.2871 29.7666 3.83183 29.9455L1.94226 30.6912C1.48673 30.8707 1.48673 31.1651 1.94226 31.3445L3.83183 32.0897C4.2871 32.2692 4.80688 32.7892 4.98634 33.245L5.73177 35.1338C5.91122 35.589 6.20508 35.589 6.3848 35.1338L7.12996 33.245C7.30968 32.7892 7.82866 32.2692 8.28393 32.0897L10.1738 31.3445C10.6296 31.1651 10.6296 30.8707 10.1738 30.6912L8.28393 29.9455C7.82866 29.7666 7.30968 29.2471 7.12996 28.7913L6.3848 26.902C6.20508 26.4467 5.91122 26.4467 5.73177 26.902L4.98634 28.7913Z"
      fill="#FBBD74"
      stroke="#32274B"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
