import { styled } from "@linaria/react";
import { cx } from "linaria";
import { RefObject } from "react";
import { font } from "../../styles/fonts.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { when } from "../../utils/promises.utils";
import { useStoryboardContext } from "./StoryboardContext";
import StoryboardHtmlLayer from "./StoryboardHtmlLayer";
import StoryboardSvgLayer from "./StoryboardSvgLayer";

const StoryboardContentWrapper = styled.div``;

const StoryboardContainer = styled.div`
  position: relative;
  user-select: none;
  // cspell:disable
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.7' x='0' y='0' width='1' height='1' rx='0.5' fill='%23AEADAB'/%3E%3C/svg%3E%0A");
  ${darkModeLinariaCSS(`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='0' y='0' width='1' height='1' rx='0.5' fill='%23AEADAB'/%3E%3C/svg%3E%0A");
  `)}
  // cspell:enable
  transition: opacity 0.2s .1s;
  background-position: -0.5px -0.5px;
  background-repeat: repeat;
  opacity: 0;
  font-family: ${font("system")};
  &.useMarketingStyles {
    font-family: ${font("sans")};
  }
  &.ready {
    opacity: 1;
  }
`;

const Storyboard = (props: {
  innerRef?: RefObject<HTMLDivElement>;
  onMount?: () => void;
}) => {
  const context = useStoryboardContext();
  const { focus, zoom } = context;
  useOnMount(() => {
    when(
      () =>
        context.story.notes.length === context.noteRenderStates.length &&
        context.noteRenderStates.every(s => s.state === "done"),
      () => {
        props.onMount?.();
      }
    );
  });
  return (
    <StoryboardContainer
      className={cx(
        "Storyboard",
        context.ready && "ready",
        context.options.useMarketingStyles && "useMarketingStyles"
      )}
      data-id={context.id}
      style={{
        width: context.storyboard.width,
        height: context.storyboard.height,
        transformOrigin: `${focus.x}px ${focus.y}px`,
        transform: `translate3d(${-focus.x + context.viewport.width / 2}px, ${
          -focus.y + context.viewport.height / 2
        }px, 0) scale(${zoom})`,
      }}
      ref={props.innerRef}
    >
      <StoryboardContent />
    </StoryboardContainer>
  );
};

function StoryboardContent() {
  const context = useStoryboardContext();
  return (
    <StoryboardContentWrapper>
      <StoryboardSvgLayer onClick={() => context.updateActionSelection()} />
      <StoryboardHtmlLayer />
      {/* {context.renderFaders && <Faders />} */}
    </StoryboardContentWrapper>
  );
}

export default Storyboard;
