import { styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren, createElement, useRef } from "react";
import { fontSerifDisplayRules, serif } from "../../styles/fonts.styles";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";
import { widthInGrid } from "../../constants/globalGrid.constants";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import { PageThemeControllerPoint } from "../site/PageThemeController";
import { SiteNavThemeControllerPoint } from "../site/SiteNavThemeController";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  heading: "Build" | "Run" | "Monitor";
};

export const BuildRunMonitorSectionHeader = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "illustration" "heading" "description";
  grid-gap: ${rSize("lg")};
  ${onlyPhones} {
    margin-top: 2em;
  }
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "heading heading" "description illustration";
  }
  ${fromDesktopMd} {
    padding-left: ${widthInGrid(1, 1)};
  }
`;

const PageThemeControllerPointPositioner = styled.div`
  position: relative;
  top: 33vh;
`;
const SiteNavThemeControllerPointPositioner = styled.div`
  position: relative;
  top: -33vh;
`;

export const BuildRunMonitorSection = (
  props: PropsWithChildren<{
    id: string;
    theme: "purple" | "green" | "orange";
  }>
) => {
  const theme = getBrandColorTheme(props.theme);
  return (
    <PageSection style={{ color: theme.c800 }}>
      <PageThemeControllerPointPositioner>
        <PageThemeControllerPoint backgroundColor={theme.c100} />
      </PageThemeControllerPointPositioner>
      <SiteNavThemeControllerPointPositioner>
        <SiteNavThemeControllerPoint
          navThemeColor={props.theme}
          mobileNavBgColor={theme.c100}
        />
      </SiteNavThemeControllerPointPositioner>
      {props.children}
    </PageSection>
  );
};

const BuildRunMonitorHeadingContainer = styled.div`
  grid-area: heading;
`;

const BuildRunMonitorHeadingInnerGrid = styled.div`
  font-family: ${serif};
  font-size: 5.8rem;
  font-weight: 300;
  ${onlyPhones} {
    text-align: center;
    justify-content: center;
    margin-left: 0 !important;
  }
  ${fromTablet} {
    ${fontSerifDisplayRules};
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-areas: "build run monitor";
    opacity: 0;
    grid-gap: 0.2em;
    display: inline-grid;
    font-size: 7.2rem;
    font-weight: 200;
  }
  ${fromTabletMd} {
    font-size: 9.6rem;
    font-weight: 150;
  }
  ${fromTabletLg} {
    font-size: 12.8rem;
  }
  ${fromDesktop} {
    font-weight: 150;
    font-size: 16.2rem;
  }
  ${fromDesktopMd} {
    font-weight: 100;
    font-size: 17.5rem;
  }
  ${fromDesktopMl} {
    font-size: 19.2rem;
  }
  letter-spacing: -0.025em;
  margin-left: -0.05em;
  h2,
  span {
    font: inherit;
  }
  span {
    color: ${colors.white};
    opacity: 0.4;
    ${onlyPhones} {
      display: none;
    }
    &.hidden {
      display: none;
    }
    &.build {
      grid-area: build;
    }
    &.run {
      grid-area: run;
    }
    &.monitor {
      grid-area: monitor;
    }
  }
`;

const offset = {
  Build: 0,
  Run: -2.26,
  Monitor: -4.12,
};

export const BuildRunMonitorHeading = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          observer.disconnect();
          if (window.innerWidth < 640) return;
          gsap.fromTo(
            ref.current,
            {
              x: "4%",
              opacity: 0,
            },
            {
              x: 0,
              delay: 0.2,
              opacity: 1,
              duration: 1.75,
              ease: "power4.out",
            }
          );
        }
      },
      { rootMargin: "-100px" }
    );
    if (ref.current) observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  });
  return (
    <BuildRunMonitorHeadingContainer>
      <BuildRunMonitorHeadingInnerGrid
        style={{
          marginLeft: `${-0.05 + offset[props.heading]}em`,
        }}
        ref={ref}
      >
        {createElement("h2", {
          children: props.heading,
          className: props.heading.toLowerCase(),
        })}
        <span className={cx("build", props.heading === "Build" && "hidden")}>
          Build
        </span>
        <span className={cx("run", props.heading === "Run" && "hidden")}>
          Run
        </span>
        <span
          className={cx("monitor", props.heading === "Monitor" && "hidden")}
        >
          Monitor
        </span>
      </BuildRunMonitorHeadingInnerGrid>
    </BuildRunMonitorHeadingContainer>
  );
};

export const BuildRunMonitorAfterHeading = styled.p<{ maxWidth: string }>`
  grid-area: description;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.35;
  ${onlyPhones} {
    max-width: 28em;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTablet} {
    font-family: ${serif};
    font-size: 2rem;
    line-height: 1.25;
    text-align: left;
    max-width: ${p => p.maxWidth};
    letter-spacing: -0.01em;
  }
  ${fromTabletLg} {
    font-size: 2.6rem;
  }
  ${fromDesktop} {
    font-size: 2.8rem;
  }
`;

export const ProductPageSubSection = styled.div``;

export const ProductPageH3 = styled.h3`
  font-size: 2.4rem;
  line-height: 1.05;
  font-family: ${serif};
  font-weight: 400;
  letter-spacing: -0.01em;
  ${fromTablet} {
    font-size: 2.8rem;
  }
  + p {
    margin-top: 1em;
    ${fromTablet} {
      margin-top: 1.5em;
    }
  }
`;

export const ProductPageSubSectionP = styled.p`
  font-size: 1.4rem;
  line-height: 1.3;
  max-width: 28em;
  ${fromTablet} {
    font-size: 1.6rem;
  }
  + p {
    margin-top: 1em;
  }
  a {
    font-weight: 600;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.single-link {
      &:only-child {
        font-size: 1.4rem;
      }
    }
  }
`;

export const BuildRunMonitorSectionHeaderIllo = styled.div`
  grid-area: illustration;
  ${uptoTablet} {
    text-align: center;
    img {
      max-height: 162px;
    }
  }
  ${fromTablet} {
    img {
      display: block;
    }
  }
`;

export const SplitTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("subsectionMargin")};
  ${fromTablet} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: ${rSize("gap")};
    > * {
      grid-column-end: span 6;
    }
  }
`;
