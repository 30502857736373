export const HatchingDinoEggIcon = () => (
  <svg
    width="61"
    height="65"
    viewBox="0 0 61 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.947 60.5374C60.947 62.4758 49.6054 64.0463 35.6143 64.0463C21.6228 64.0463 10.2808 62.4757 10.2808 60.5374C10.2808 58.5996 21.6227 57.0284 35.6143 57.0284C49.6052 57.0286 60.947 58.5998 60.947 60.5374Z"
      fill="#32274B"
    />
    <path
      d="M11.7583 60.6957L17.2907 58.1951C17.2907 58.1951 17.0403 53.1255 21.2023 53.2504C21.2023 53.2505 11.7583 51.4116 11.7583 60.6957Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.5705 60.6957L44.0384 58.1951C44.0384 58.1951 44.2888 53.1255 40.1265 53.2504C40.1265 53.2505 49.5705 51.4116 49.5705 60.6957Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6334 43.2805C19.27 43.2805 10.0586 45.1993 10.0586 47.5661C10.0586 50.1881 10.0586 56.4112 10.0586 58.9863C10.0586 61.5614 11.5416 61.6745 13.2666 58.9863C14.9141 56.4194 16.3041 53.1923 20.4911 53.1923C26.2065 53.1923 23.4741 63.6154 30.6334 63.6154C37.7932 63.6154 35.0607 53.1923 40.7758 53.1923C44.9629 53.1923 46.3529 56.4194 48.0006 58.9863C49.7254 61.6745 51.2084 61.5614 51.2084 58.9863C51.2084 56.4112 51.2084 50.1881 51.2084 47.5661C51.2086 45.1993 41.9969 43.2805 30.6334 43.2805Z"
      fill="#FDB2D2"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6336 51.8517C41.9968 51.8517 51.2086 49.933 51.2086 47.5661C51.2086 45.1993 41.9968 43.2805 30.6336 43.2805C19.2703 43.2805 10.0586 45.1993 10.0586 47.5661C10.0586 49.933 19.2703 51.8517 30.6336 51.8517Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.9137 49.588C43.442 49.588 48.7341 48.3266 48.7341 46.7706C48.7341 45.2146 43.442 43.9532 36.9137 43.9532C30.3855 43.9532 25.0933 45.2146 25.0933 46.7706C25.0933 48.3266 30.3855 49.588 36.9137 49.588Z"
      fill="#32274B"
    />
    <path
      d="M30.6335 43.5549C38.8669 43.5549 45.5414 42.5539 45.5414 41.319C45.5414 40.0841 38.8669 39.0831 30.6335 39.0831C22.4001 39.0831 15.7256 40.0841 15.7256 41.319C15.7256 42.5539 22.4001 43.5549 30.6335 43.5549Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.7277 31.6842C44.7277 41.3743 38.4178 47.703 30.6338 47.703C22.8502 47.703 16.5405 41.3743 16.5405 31.6842C16.5405 21.9944 22.8502 8.38696 30.6338 8.38696C38.4176 8.38696 44.7277 21.9944 44.7277 31.6842Z"
      fill="#B8E5D2"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.393 19.3924C28.407 19.3924 29.229 18.5704 29.229 17.5564C29.229 16.5425 28.407 15.7205 27.393 15.7205C26.3791 15.7205 25.5571 16.5425 25.5571 17.5564C25.5571 18.5704 26.3791 19.3924 27.393 19.3924Z"
      fill="#B0A6F9"
    />
    <path
      d="M37.4033 36.3784C39.3404 36.3784 40.9106 34.8081 40.9106 32.871C40.9106 30.934 39.3404 29.3637 37.4033 29.3637C35.4663 29.3637 33.896 30.934 33.896 32.871C33.896 34.8081 35.4663 36.3784 37.4033 36.3784Z"
      fill="#B0A6F9"
    />
    <path
      d="M34.7801 24.8498C35.4466 24.8498 35.9869 24.3095 35.9869 23.643C35.9869 22.9765 35.4466 22.4362 34.7801 22.4362C34.1135 22.4362 33.5732 22.9765 33.5732 23.643C33.5732 24.3095 34.1135 24.8498 34.7801 24.8498Z"
      fill="#B0A6F9"
    />
    <path
      d="M27.9735 30.3049C28.8 30.3049 29.47 29.6349 29.47 28.8084C29.47 27.9819 28.8 27.312 27.9735 27.312C27.147 27.312 26.4771 27.9819 26.4771 28.8084C26.4771 29.6349 27.147 30.3049 27.9735 30.3049Z"
      fill="#B0A6F9"
    />
    <path
      d="M26.4003 37.9786C26.4003 39.1468 25.4532 40.0941 24.2849 40.0941C23.1171 40.0941 22.1699 39.1468 22.1699 37.9786C22.1699 36.8108 23.1171 35.8636 24.2849 35.8636C25.4532 35.8635 26.4003 36.8106 26.4003 37.9786Z"
      fill="#B0A6F9"
    />
    <path
      d="M32.3987 13.2149C32.8519 13.2149 33.2193 12.8475 33.2193 12.3944C33.2193 11.9412 32.8519 11.5738 32.3987 11.5738C31.9455 11.5738 31.5781 11.9412 31.5781 12.3944C31.5781 12.8475 31.9455 13.2149 32.3987 13.2149Z"
      fill="#B0A6F9"
    />
    <path
      d="M21.9876 24.8982C22.4407 24.8982 22.8081 24.5308 22.8081 24.0776C22.8081 23.6245 22.4407 23.2571 21.9876 23.2571C21.5344 23.2571 21.167 23.6245 21.167 24.0776C21.167 24.5308 21.5344 24.8982 21.9876 24.8982Z"
      fill="#B0A6F9"
    />
    <path
      d="M30.9734 39.9832C31.4266 39.9832 31.794 39.6158 31.794 39.1626C31.794 38.7094 31.4266 38.342 30.9734 38.342C30.5202 38.342 30.1528 38.7094 30.1528 39.1626C30.1528 39.6158 30.5202 39.9832 30.9734 39.9832Z"
      fill="#B0A6F9"
    />
    <path
      d="M20.2166 31.6362C20.4564 31.6362 20.6509 31.4418 20.6509 31.2019C20.6509 30.962 20.4564 30.7676 20.2166 30.7676C19.9767 30.7676 19.7822 30.962 19.7822 31.2019C19.7822 31.4418 19.9767 31.6362 20.2166 31.6362Z"
      fill="#B0A6F9"
    />
    <path
      d="M40.3733 38.7249C40.6132 38.7249 40.8076 38.5304 40.8076 38.2905C40.8076 38.0507 40.6132 37.8562 40.3733 37.8562C40.1334 37.8562 39.939 38.0507 39.939 38.2905C39.939 38.5304 40.1334 38.7249 40.3733 38.7249Z"
      fill="#B0A6F9"
    />
    <path
      d="M38.9802 25.6706C39.2201 25.6706 39.4145 25.4761 39.4145 25.2363C39.4145 24.9964 39.2201 24.8019 38.9802 24.8019C38.7404 24.8019 38.5459 24.9964 38.5459 25.2363C38.5459 25.4761 38.7404 25.6706 38.9802 25.6706Z"
      fill="#B0A6F9"
    />
    <path
      d="M44.7277 31.6842C44.7277 41.3743 38.4178 47.703 30.6338 47.703C22.8502 47.703 16.5405 41.3743 16.5405 31.6842C16.5405 21.9944 22.8502 8.38696 30.6338 8.38696C38.4176 8.38696 44.7277 21.9944 44.7277 31.6842Z"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9409 12.9205L23.8968 14.8317L23.323 16.3604L24.2788 17.6983L22.5586 19.4182"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5361 15.8165L25.5558 15.7418L26.6026 16.7888L28.7722 16.7142"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.3794 23.1273L36.6015 21.6892L38.6869 21.0423L38.759 19.3163L41.6353 18.6695"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.4375 18.8134L35.4511 17.8067C35.4511 17.8067 36.4578 18.8134 36.5296 19.0289C36.6014 19.2448 38.7589 19.3162 38.7589 19.3162"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7256 41.319C15.7256 42.554 22.4002 43.5549 30.6335 43.5549C38.8673 43.5549 45.5414 42.554 45.5414 41.319V47.1768C45.5414 48.4117 38.8671 49.4127 30.6335 49.4127C22.4 49.4127 15.7256 48.4117 15.7256 47.1768V41.319Z"
      fill="#FDB2D2"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.7866 42.7298L43.2871 40.1205C43.2871 40.1205 45.948 40.7232 45.5414 41.3189C45.1355 41.9146 40.7866 42.7298 40.7866 42.7298Z"
      fill="#32274B"
    />
    <path
      d="M14.6875 56.6144L17.5701 55.731L19.2435 53.5922C19.2435 53.5922 16.0823 53.9176 14.6875 56.6144Z"
      fill="#32274B"
    />
    <path
      d="M46.6151 56.6144L43.7325 55.731L42.0591 53.5922C42.0591 53.5922 45.2203 53.9176 46.6151 56.6144Z"
      fill="#32274B"
    />
    <path
      d="M33.3501 56.9847C33.3501 58.485 32.1338 59.701 30.6335 59.701C29.1338 59.701 27.9175 58.485 27.9175 56.9847C27.9175 55.4847 29.1337 54.2684 30.6335 54.2684C32.1338 54.2684 33.3501 55.4847 33.3501 56.9847Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6338 58.3051C31.363 58.3051 31.9542 57.7139 31.9542 56.9847C31.9542 56.2555 31.363 55.6644 30.6338 55.6644C29.9046 55.6644 29.3135 56.2555 29.3135 56.9847C29.3135 57.7139 29.9046 58.3051 30.6338 58.3051Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.0221 3.1474H30.2514C19.0993 3.1474 10.0586 12.1881 10.0586 23.3402V47.5662C10.0586 49.933 19.27 51.8518 30.6334 51.8518C41.997 51.8518 51.2084 49.933 51.2084 47.5662H51.2149V23.3402C51.2151 12.1881 42.1744 3.1474 31.0221 3.1474Z"
      fill="white"
      fillOpacity="0.1"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.637 4.76776C33.5649 4.76776 35.9385 4.26526 35.9385 3.64539C35.9385 3.02552 33.5649 2.52301 30.637 2.52301C27.709 2.52301 25.3354 3.02552 25.3354 3.64539C25.3354 4.26526 27.709 4.76776 30.637 4.76776Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.9385 3.64548C35.9385 4.26532 33.5649 4.76785 30.637 4.76785C27.7091 4.76785 25.3354 4.26532 25.3354 3.64548C25.3354 3.02564 27.7092 0.567993 30.637 0.567993C33.565 0.567993 35.9385 3.02549 35.9385 3.64548Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.724114"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
