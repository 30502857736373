export const ProductExplorerIcon = () => (
  <svg width="69" height="71" viewBox="0 0 69 71" fill="none">
    <path
      d="M17.4432 23.6372C21.844 26.1846 23.346 31.8176 20.7988 36.2172C18.2512 40.6176 12.6184 42.12 8.21777 39.5722C3.81777 37.0252 2.31577 31.3922 4.86357 26.992C7.41057 22.5922 13.0434 21.0898 17.4432 23.6372Z"
      fill="#EE86B7"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M20.8687 30.7758C23.2519 32.1556 24.0657 35.2058 22.6859 37.589C21.3065 39.972 18.2564 40.7854 15.8728 39.406L14.3439 38.4886C11.9605 37.109 11.147 34.059 12.527 31.676C13.9066 29.2922 16.9565 28.4792 19.3397 29.8588L20.8687 30.7758Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.0167 39.8626C35.7765 40.8814 36.3773 43.1334 35.3585 44.8934C34.3393 46.6536 32.0874 47.254 30.327 46.235L17.3203 38.7054C15.5605 37.686 14.9596 35.434 15.9786 33.6746C16.9974 31.9144 19.2499 31.3136 21.0099 32.3322L34.0167 39.8626Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.2188 23.6372C47.8182 26.1846 46.3162 31.8176 48.8638 36.2172C51.411 40.6176 57.0442 42.12 61.4446 39.5722C65.8444 37.0252 67.3466 31.3922 64.7988 26.992C62.2518 22.5922 56.619 21.0898 52.2188 23.6372Z"
      fill="#4E8FD0"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.7935 30.7758C46.4103 32.1556 45.5967 35.2058 46.9763 37.589C48.3559 39.972 51.4063 40.7854 53.7897 39.406L55.3185 38.4886C57.7017 37.109 58.5153 34.059 57.1357 31.676C55.7557 29.2922 52.7057 28.4792 50.3227 29.8588L48.7935 30.7758Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.6458 39.8626C33.8856 40.8814 33.2852 43.1334 34.304 44.8934C35.3228 46.6536 37.5748 47.254 39.3352 46.235L52.3418 38.7054C54.1016 37.686 54.7026 35.434 53.6838 33.6746C52.6652 31.9144 50.4124 31.3136 48.6526 32.3322L35.6458 39.8626Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.0372 43.9384C45.0372 49.635 40.4184 54.2536 34.7224 54.2536C29.0258 54.2536 24.4072 49.635 24.4072 43.9384C24.4072 38.2422 29.0258 33.6238 34.7224 33.6238C40.4184 33.6238 45.0372 38.2422 45.0372 43.9384Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.4042 33.4146C38.4042 35.448 36.7556 36.634 34.722 36.634C32.6888 36.634 31.0404 35.448 31.0404 33.4146V18.3854C31.0404 16.3518 32.6888 14.7036 34.722 14.7036C36.7556 14.7036 38.4042 16.3518 38.4042 18.3854V33.4146Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.5582 20.9686C39.5582 23.7224 37.3936 23.9708 34.722 23.9708C32.0512 23.9708 29.8866 23.7224 29.8866 20.9686V17.594C29.8866 14.84 32.0512 12.6078 34.722 12.6078C37.3934 12.6078 39.5582 14.84 39.5582 17.594V20.9686Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.5232 46.6178C25.1806 45.4414 27.4796 45.8316 28.6566 47.4902C29.8336 49.1484 29.4432 51.4464 27.7846 52.6234L17.515 59.8874C15.8572 61.0638 13.5592 60.674 12.3822 59.016C11.2052 57.3578 11.595 55.059 13.2538 53.882L23.5232 46.6178Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.0617 52.4194C15.3075 50.826 18.4192 51.354 20.0134 53.6C21.6072 55.8454 21.0786 58.957 18.833 60.5512L16.0809 62.5042C13.8351 64.0986 10.7231 63.57 9.12935 61.3248C7.53555 59.0784 8.06415 55.967 10.3094 54.3728L13.0617 52.4194Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.5232 48.1204C29.5232 48.1204 31.0156 50.9108 30.2006 52.9326L28.3948 52.4182L29.5232 48.1204Z"
      fill="#483C72"
    />
    <path
      d="M34.7223 20.946C40.2302 20.946 44.6953 16.4809 44.6953 10.973C44.6953 5.46507 40.2302 1 34.7223 1C29.2143 1 24.7493 5.46507 24.7493 10.973C24.7493 16.4809 29.2143 20.946 34.7223 20.946Z"
      fill="#F0A848"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.1724 58.8246C20.3328 63.7752 17.2598 68.7294 12.31 69.8894C7.3592 71.0494 2.4054 67.9772 1.245 63.0262C0.0845958 58.0762 3.1576 53.1222 8.1078 51.9624C13.0582 50.802 18.0122 53.8744 19.1724 58.8246Z"
      fill="#25A871"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.652 46.6178C43.9942 45.4414 41.6958 45.8316 40.5186 47.4902C39.3416 49.1484 39.732 51.4464 41.3906 52.6234L51.6602 59.8874C53.3182 61.0638 55.616 60.674 56.793 59.016C57.9698 57.3578 57.5802 55.059 55.9214 53.882L45.652 46.6178Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.1133 52.4194C53.8675 50.826 50.7559 51.354 49.1617 53.6C47.5681 55.8454 48.0965 58.957 50.3417 60.5512L53.0941 62.5042C55.3399 64.0986 58.4519 63.57 60.0461 61.3248C61.6395 59.0784 61.1109 55.967 58.8657 54.3728L56.1133 52.4194Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.6521 48.1204C39.6521 48.1204 38.1597 50.9108 38.9747 52.9326L40.7805 52.4182L39.6521 48.1204Z"
      fill="#483C72"
    />
    <path
      d="M50.0028 58.8246C48.8424 63.7752 51.9154 68.7294 56.8652 69.8894C61.816 71.0494 66.7698 67.9772 67.9302 63.0262C69.0904 58.0762 66.0176 53.1222 61.0672 51.9624C56.117 50.802 51.163 53.8744 50.0028 58.8246Z"
      fill="#F47E3F"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.2372 36.0904C42.2372 36.0904 45.3636 37.9882 46.8654 41.877L45.1632 42.8668L42.2372 36.0904Z"
      fill="#483C72"
    />
    <path
      d="M58.2152 34.9742C58.2152 34.9742 58.215 39.132 56.444 40.7492L53.4026 39.9024C53.4026 39.9024 57.6378 36.7452 58.2152 34.9742Z"
      fill="#483C72"
    />
    <path
      d="M34.3057 37.1106C34.3057 37.1106 37.5401 37.457 40.9475 35.8402L38.5795 34.5116C38.5795 34.5116 35.9233 37.1106 34.3057 37.1106Z"
      fill="#483C72"
    />
    <path
      d="M12.3602 37.1106C12.3602 37.1106 12.9376 39.5364 14.5546 41.0378L16.2872 39.7672L12.3602 37.1106Z"
      fill="#483C72"
    />
    <path
      d="M32.7466 24.1744C32.7466 24.1744 34.3638 25.3868 38.233 25.156L38.1178 23.019C38.1178 23.019 35.2878 24.1166 32.7466 24.1744Z"
      fill="#483C72"
    />
  </svg>
);
