/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { styled } from "@linaria/react";
import dayjs from "dayjs";
import {
  fromDesktopMd,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { DatoCmsArticle } from "../../../graphql-types";
import ArticleEntryLink from "./ArticleEntryLink";
import NewsletterCTASection from "../reusableSections/NewsletterCTASection";
import Spacing from "../layout/Spacing";

const EntryLinkGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("sectionPadding")} ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

type Props = {
  articles: DatoCmsArticle[];
  showFirstPostAsFeatured?: boolean;
  includeNewsletterCta?: boolean;
};

const ArticleIndexGrid = (props: Props) => {
  const allArticles = [...props.articles].sort((a, b) =>
    dayjs(b.datePublished).diff(dayjs(a.datePublished))
  );
  const latestPost = props.showFirstPostAsFeatured ? allArticles.shift() : null;
  const firstRow = allArticles.slice(0, 3);
  const afterFirstRow = allArticles.slice(3);

  return (
    <div>
      {latestPost && (
        <>
          <ArticleEntryLink record={latestPost} featured showFeaturedTag />
          <Spacing size="sectionPadding" />
        </>
      )}

      {firstRow.length > 0 && (
        <>
          <EntryLinkGrid>
            {firstRow.map(article => (
              <ArticleEntryLink record={article} key={article.slug} />
            ))}
          </EntryLinkGrid>

          <Spacing size="sectionPadding" />
        </>
      )}

      {props.includeNewsletterCta && (
        <>
          <NewsletterCTASection />
          <Spacing size="sectionPadding" />
        </>
      )}

      {afterFirstRow.length > 0 && (
        <>
          <EntryLinkGrid>
            {afterFirstRow.map(article => (
              <ArticleEntryLink record={article} key={article.slug} />
            ))}
          </EntryLinkGrid>
        </>
      )}
    </div>
  );
};

export default ArticleIndexGrid;
