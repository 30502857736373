import { styled } from "@linaria/react";
import { ReactNode, useRef, useState } from "react";
import { brandColorThemeVar, color, colors } from "../../styles/colors.styles";
import { css, cx } from "linaria";
import { font } from "../../styles/fonts.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";
import { WorkbenchTwoToneIcon } from "../icons/twoTone/WorkbenchTwoToneIcon";
import { getScrollParent, hideScrollbarsCSS } from "../../utils/scroll.utils";
import { WorkbenchAITypingIndicator } from "./WorkbenchAITypingIndicator";
import MultiplayerCursor from "../misc/MultiplayerCursor";

export type WorkbenchChatMessage = {
  sender: "ai" | "user";
  maxWidth?: string;
} & (
  | {
      type: "text";
      contents: ReactNode;
    }
  | {
      type: "command";
      icon: ReactNode;
      name: string;
      subheading: string;
      requiresConfirmation?: boolean;
    }
);

const WorkbenchChatStackContainer = styled.div`
  position: relative;
  width: 550px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-family: ${font("system")};
  font-size: 1.2rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) auto;
  overflow: hidden;
  &.containsCommands {
    opacity: 0;
    transition: opacity 0.2s;
    &.ready {
      opacity: 1;
    }
  }
  * {
    font-size: inherit;
    line-height: 1.3;
  }
`;

const ChatMessageScrollContainer = styled.div`
  display: grid;
  align-items: end;
  overflow: auto;
  .animating & {
    pointer-events: none;
  }
`;

const ChatMessageList = styled.div`
  flex: 1 1 100%;
  display: inline-grid;
  grid-gap: 0.8em;
  grid-auto-flow: row;
  grid-auto-rows: auto;
`;

const CommandEntryGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-template-areas: "icon text buttons";

  [data-layout="mobile"] .requiresConfirmation.awaitingConfirm & {
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-areas: "icon text" "buttons buttons";
  }
  [data-layout="desktop"] .requiresConfirmation & {
    grid-template-columns: auto minmax(0, 1fr) auto;
    grid-template-areas: "icon text buttons";
  }
`;

const CommandEntryIconBox = styled.div`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.8rem;
  border: 1px solid ${colors.light300};
  svg {
    display: block;
  }
`;
const CommandEntryText = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const CommandEntryName = styled.div``;
const CommandEntrySubheading = styled.div`
  font-size: 1rem;
  opacity: 0.5;
`;
const CommandEntryRunningIndicator = styled.div`
  opacity: 0.66;
  display: none;
  font-size: 1rem;
  .running & {
    grid-area: running;
    display: block;
  }
`;
const CommandEntryButtonSet = styled.div`
  grid-area: buttons;
  display: grid;
  align-items: center;
  grid-gap: 0.5em;
  user-select: none;
  .doesNotRequireConfirmation & {
    grid-template-columns: repeat(1, minmax(0, 1fr)) auto;
    grid-template-areas: "cancel confirm inspect";
  }
  [data-layout="mobile"] .requiresConfirmation.awaitingConfirm & {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "cancel inspect confirm";
  }
  [data-layout="desktop"] .requiresConfirmation.awaitingConfirm & {
    grid-template-columns: repeat(1, minmax(0, 1fr)) auto;
    grid-template-areas: "cancel confirm inspect";
  }

  [data-layout="mobile"] .requiresConfirmation.confirmed & {
    grid-template-columns: auto;
    grid-template-areas: "running inspect";
  }
  [data-layout="desktop"] .requiresConfirmation.confirmed & {
    grid-template-columns: auto;
    grid-template-areas: "running inspect";
  }
  [data-layout="mobile"] .running & {
    grid-template-columns: auto auto;
    grid-template-areas: "running inspect";
  }
  [data-layout="desktop"] .running & {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "running inspect";
  }
`;
const CommandEntryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.light300};
  border-radius: 0.6rem;
  text-align: center;
  position: relative;
  > * {
    display: block;
    + * {
      margin-left: 0.5em;
    }
  }
  [data-layout="desktop"] & {
    padding: 0.75em;
    min-height: 3.6rem;
  }
  &.hover {
    border-color: ${colors.purple200};
  }
  &.active {
    border-color: ${colors.purple300};
    background-color: ${colors.light200};
  }
  [data-layout="mobile"] & {
    padding: 0.6em 0.5em;
    &.primary {
      background-color: ${colors.purple};
      color: ${colors.white};
      border-color: ${colors.purple600};
      &.hover {
        border-color: ${colors.purple600};
        background-color: ${color("purple", 450)};
      }
      &.active {
        border-color: ${colors.purple600};
        background-color: ${color("purple", 550)};
      }
    }
  }
  &.confirmButton,
  &.cancelButton {
    .confirmed & {
      display: none;
    }
  }
`;

const CommandEntryButtonConfirmCursorPositioner = styled.div`
  position: absolute;
  top: 75%;
  left: 75%;
  .confirmCursor {
    opacity: 0;
  }
`;

export const WorkbenchChatStack = (props: {
  id?: string;
  className?: string;
  chatTitle?: string;
  messages: WorkbenchChatMessage[];
  withMessageComposer?: boolean;
  withEmptyStateScreen?: boolean;
  animated?: boolean;
  padding?: string | number;
}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const layout = containerWidth < 375 ? "mobile" : "desktop";
  useOnMount(() => {
    const disposers: (() => void)[] = [];
    const resizeObserver = new ResizeObserver(entries => {
      setContainerWidth(entries[0].contentRect.width);
    });
    if (ref.current) {
      resizeObserver.observe(ref.current);
      if (props.animated) {
        const { dispose, timeline } = createChatStackAnimationTimeline({
          stackEl: ref.current,
          chatId: props.id,
          chatTitle: props.chatTitle,
        });
        disposers.push(dispose);
        const initialVisibilityObserverTarget = props.withMessageComposer
          ? (ref.current?.querySelector(".MessageComposerInputText") as
              | HTMLDivElement
              | undefined)
          : ref.current;
        if (initialVisibilityObserverTarget) {
          const initialIntersectionObserver = new IntersectionObserver(
            entries => {
              if (entries[0].isIntersecting) {
                timeline.play();
                initialIntersectionObserver.disconnect();
              }
            }
          );
          initialIntersectionObserver.observe(initialVisibilityObserverTarget);
          disposers.push(() => {
            initialIntersectionObserver.disconnect();
          });
        }
      }
    }
    return () => {
      disposers.forEach(d => d());
      resizeObserver.disconnect();
    };
  });

  return (
    <WorkbenchChatStackContainer
      id={props.id}
      ref={ref}
      className={cx(
        props.className,
        props.messages.some(m => m.type === "command") && "containsCommands",
        containerWidth !== 0 && "ready"
      )}
      style={{
        paddingLeft: props.padding,
        paddingRight: props.padding,
        paddingBottom: props.padding,
      }}
      data-layout={layout}
    >
      {props.withEmptyStateScreen && <EmptyStateScreen />}
      {props.messages.length > 0 && (
        <ChatMessageScrollContainer
          className={cx(hideScrollbarsCSS)}
          style={{
            paddingTop: props.padding,
          }}
        >
          <ChatMessageList>
            {props.messages.map((m, i) => (
              <ChatMessage key={i} message={m} />
            ))}
          </ChatMessageList>
        </ChatMessageScrollContainer>
      )}
      {props.withMessageComposer && <MessageComposer />}
    </WorkbenchChatStackContainer>
  );
};

const ChatMessage = (props: { message: WorkbenchChatMessage }) => {
  return (
    <ChatMessageSection
      className={cx(
        "message",
        "requiresConfirmation" in props.message &&
          props.message.requiresConfirmation
          ? "requiresConfirmation awaitingConfirm"
          : "doesNotRequireConfirmation"
      )}
      data-sender={props.message.sender}
      data-type={props.message.type}
    >
      <ChatBubble
        className={cx(props.message.sender, props.message.type)}
        style={{
          maxWidth: props.message.maxWidth,
        }}
      >
        {props.message.type === "text" ? (
          props.message.contents
        ) : (
          <CommandEntryGrid>
            <CommandEntryIconBox>{props.message.icon}</CommandEntryIconBox>
            <CommandEntryText>
              <CommandEntryName>{props.message.name}</CommandEntryName>
              <CommandEntrySubheading>
                {props.message.subheading}
              </CommandEntrySubheading>
            </CommandEntryText>
            <CommandEntryButtonSet>
              <CommandEntryRunningIndicator>
                Running…
              </CommandEntryRunningIndicator>
              {props.message.requiresConfirmation && (
                <CommandEntryButton
                  className={cx(
                    "confirmButton",
                    "primary",
                    css`
                      grid-area: confirm;
                    `
                  )}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                  >
                    <path d="M9.72855 3.77145C9.92382 3.96671 9.92382 4.28329 9.72855 4.47855L5.60355 8.60355C5.40829 8.79882 5.09171 8.79882 4.89645 8.60355L3.02145 6.72855C2.82618 6.53329 2.82618 6.21671 3.02145 6.02145C3.21671 5.82618 3.53329 5.82618 3.72855 6.02145L5.25 7.54289L9.02145 3.77145C9.21671 3.57618 9.53329 3.57618 9.72855 3.77145Z" />
                  </svg>
                  <span>Confirm</span>
                  <CommandEntryButtonConfirmCursorPositioner>
                    <MultiplayerCursor
                      className="confirmCursor"
                      lighterFill
                      color="orange"
                      noLabel
                    />
                  </CommandEntryButtonConfirmCursorPositioner>
                </CommandEntryButton>
              )}

              <CommandEntryButton
                className={cx(css`
                  grid-area: inspect;
                  [data-layout="desktop"] & {
                    aspect-ratio: 1;
                    span {
                      display: none;
                    }
                  }
                `)}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                >
                  <path d="M4.37372 3.28123L1.31231 5.99998L4.37184 8.71873C4.40878 8.75141 4.43891 8.79105 4.46053 8.83539C4.48214 8.87972 4.49481 8.92788 4.49781 8.97711C4.50082 9.02633 4.4941 9.07567 4.47803 9.1223C4.46197 9.16894 4.43687 9.21195 4.40419 9.24888C4.3715 9.28582 4.33186 9.31595 4.28753 9.33756C4.24319 9.35918 4.19504 9.37185 4.14581 9.37485C4.09658 9.37786 4.04724 9.37113 4.00061 9.35507C3.95398 9.339 3.91097 9.31391 3.87403 9.28123L0.499031 6.28123C0.459129 6.24603 0.427171 6.20276 0.405281 6.15426C0.383391 6.10577 0.37207 6.05318 0.37207 5.99998C0.37207 5.94677 0.383391 5.89418 0.405281 5.84569C0.427171 5.79719 0.459129 5.75392 0.499031 5.71873L3.87403 2.71873C3.91097 2.68592 3.954 2.6607 4.00068 2.64452C4.04736 2.62834 4.09677 2.62152 4.14608 2.62444C4.1954 2.62735 4.24366 2.63995 4.2881 2.66152C4.33255 2.68309 4.37231 2.7132 4.40512 2.75013C4.43793 2.78707 4.46315 2.8301 4.47933 2.87678C4.49551 2.92346 4.50233 2.97287 4.49941 3.02218C4.4965 3.0715 4.4839 3.11976 4.46233 3.16421C4.44076 3.20865 4.41065 3.24842 4.37372 3.28123ZM11.4987 5.71873L8.12372 2.71873C8.04913 2.65271 7.95137 2.61903 7.85194 2.6251C7.75252 2.63116 7.65958 2.67648 7.59356 2.75107C7.52755 2.82566 7.49387 2.92342 7.49994 3.02285C7.506 3.12227 7.55131 3.21521 7.62591 3.28123L10.6873 5.99998L7.62778 8.71873C7.59085 8.75141 7.56071 8.79105 7.5391 8.83539C7.51748 8.87972 7.50481 8.92788 7.50181 8.97711C7.49881 9.02633 7.50553 9.07567 7.52159 9.1223C7.53766 9.16894 7.56275 9.21195 7.59544 9.24888C7.62812 9.28582 7.66777 9.31595 7.7121 9.33756C7.75643 9.35918 7.80459 9.37185 7.85382 9.37485C7.90305 9.37786 7.95239 9.37113 7.99902 9.35507C8.04565 9.339 8.08866 9.31391 8.12559 9.28123L11.5006 6.28123C11.5405 6.24603 11.5725 6.20276 11.5943 6.15426C11.6162 6.10577 11.6276 6.05318 11.6276 5.99998C11.6276 5.94677 11.6162 5.89418 11.5943 5.84569C11.5725 5.79719 11.5405 5.75392 11.5006 5.71873H11.4987Z" />
                </svg>
                {props.message.requiresConfirmation && (
                  <span
                    className={css`
                      .confirmed & {
                        display: none;
                      }
                    `}
                  >
                    Inspect
                  </span>
                )}
              </CommandEntryButton>
              {props.message.requiresConfirmation && (
                <CommandEntryButton
                  className={cx(
                    "cancelButton",
                    css`
                      grid-area: cancel;
                    `
                  )}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                  >
                    <path
                      d="M2.35983 2.35983C2.50628 2.21339 2.74372 2.21339 2.89017 2.35983L6 5.46967L9.10984 2.35983C9.25628 2.21339 9.49372 2.21339 9.64016 2.35983C9.78661 2.50628 9.78661 2.74372 9.64016 2.89017L6.53033 6L9.64016 9.10984C9.78661 9.25628 9.78661 9.49372 9.64016 9.64016C9.49372 9.78661 9.25628 9.78661 9.10984 9.64016L6 6.53033L2.89017 9.64016C2.74372 9.78661 2.50628 9.78661 2.35983 9.64016C2.21339 9.49372 2.21339 9.25628 2.35983 9.10984L5.46967 6L2.35983 2.89017C2.21339 2.74372 2.21339 2.50628 2.35983 2.35983Z"
                      fill="#4D3E78"
                    />
                  </svg>

                  <span>Cancel</span>
                </CommandEntryButton>
              )}
            </CommandEntryButtonSet>
          </CommandEntryGrid>
        )}
      </ChatBubble>
    </ChatMessageSection>
  );
};

const ChatMessageSection = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
`;

const ChatBubble = styled.div`
  background-color: ${colors.lightest};
  border: 1px solid var(--borderColor, ${colors.light300});
  border-radius: 0.8rem;
  text-align: left;
  font-size: 1.2rem;
  @keyframes ChatMessageSectionEnter {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  animation: ChatMessageSectionEnter 0.2s;
  &.command {
    padding: 1.25em;
  }
  &.text {
    padding: 0.95em 1.25em;
    max-width: 100%;
    [data-layout="desktop"] & {
      max-width: 40rem;
    }
    > * {
      + * {
        margin-top: 0.5em;
      }
    }
    ul,
    ol {
      color: ${brandColorThemeVar(800)};
      background-color: ${brandColorThemeVar(50)};
      border: 1px solid ${brandColorThemeVar(100)};
      padding: 0.75em 1em 0.75em 2.5em;
      border-radius: 0.6rem;
    }
    pre {
      background-color: ${colors.light100};
      border: 1px solid ${colors.light300};
      padding: 0.75em 1em;
      border-radius: 0.6rem;
      font-size: 1.1rem;
      overflow: hidden;
      max-width: 100%;
    }
    &.ai {
      justify-self: start;
    }
    &.user {
      justify-self: end;
      background-color: ${brandColorThemeVar(500)};
      border: 1px solid ${brandColorThemeVar(600)};
      color: ${colors.white};
    }
  }
`;

export const WorkbenchChatCodeBlock = styled.div`
  background-color: ${colors.light100};
  border: 1px solid ${colors.light300};
  padding: 0.75em 1em;
  border-radius: 0.6rem;
  font-size: 1.1rem;
`;

const MessageComposerWithTypingIndicator = styled.div``;
const WorkbenchAITypingIndicatorSpacer = styled.div`
  margin-top: 0.4em;
  margin-bottom: 0.3em;
`;
const MessageComposerContainer = styled.div`
  position: relative;
  border: 1px solid var(--borderColor, ${colors.light300});
  background-color: ${colors.lightest};
  min-height: 4.2rem;
  border-radius: 0.8rem;
  padding-left: 1.25em;
  padding-right: 4em;
  position: sticky;
  bottom: 0;
`;
const MessageComposerInputText = styled.div`
  position: relative;
  &:empty {
    &:before {
      opacity: 0.3;
      content: "Type here…";
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0.1em;
    }
  }
  margin-top: 1em;
  margin-bottom: 1em;
  &.hasFocus {
    &:after {
      display: inline-block;
      content: "";
      width: 1px;
      height: 1.2em;
      background-color: currentColor;
      vertical-align: middle;
      margin-top: -0.1em;
      margin-bottom: -0.1em;
      @keyframes inputCursorBlink {
        0% {
          opacity: 1;
        }
        49% {
          opacity: 1;
        }
        60% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
      animation: inputCursorBlink 1s infinite;
    }
  }
`;

const MessageComposerSendButton = styled.div`
  background-color: ${colors.lightest};
  padding: 0.25em;
  border-radius: 0.6rem;
  border: 1px solid var(--borderColor, ${colors.light200});
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.6rem;
  svg {
    display: block;
    opacity: 0.6;
  }
  &.hover {
    background-color: ${colors.purple400};
  }
`;

const MessageComposer = () => {
  return (
    <MessageComposerWithTypingIndicator>
      <WorkbenchAITypingIndicatorSpacer>
        <WorkbenchAITypingIndicator />
      </WorkbenchAITypingIndicatorSpacer>
      <MessageComposerContainer className="MessageComposerContainer">
        <MessageComposerInputText className="MessageComposerInputText" />
        <MessageComposerSendButton className="MessageComposerSendButton">
          <SendIcon />
        </MessageComposerSendButton>
      </MessageComposerContainer>
    </MessageComposerWithTypingIndicator>
  );
};

const SendIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
    <path d="M14.4919 7.125L3.99186 1.13187C3.81475 1.03217 3.61146 0.988799 3.40908 1.00754C3.2067 1.02629 3.01484 1.10625 2.85905 1.23679C2.70326 1.36732 2.59095 1.54223 2.53707 1.7382C2.4832 1.93418 2.49032 2.14192 2.55748 2.33375L4.47186 8L2.55748 13.6669C2.50421 13.8177 2.48788 13.979 2.50986 14.1375C2.53184 14.2959 2.5915 14.4467 2.68382 14.5773C2.77615 14.7079 2.89845 14.8144 3.04046 14.888C3.18247 14.9615 3.34005 14.9999 3.49998 15C3.67373 14.9996 3.84445 14.9544 3.99561 14.8687L14.4906 8.86562C14.6454 8.77891 14.7743 8.65257 14.8642 8.49956C14.954 8.34656 15.0016 8.17241 15.0019 7.99497C15.0022 7.81754 14.9553 7.64322 14.866 7.48989C14.7767 7.33657 14.6482 7.20977 14.4937 7.1225L14.4919 7.125ZM3.49998 14C3.50025 13.9975 3.50025 13.995 3.49998 13.9925L5.35873 8.5H8.99998C9.13259 8.5 9.25977 8.44732 9.35354 8.35355C9.44731 8.25978 9.49998 8.13261 9.49998 8C9.49998 7.86739 9.44731 7.74021 9.35354 7.64644C9.25977 7.55268 9.13259 7.5 8.99998 7.5H5.35873L3.50373 2.01C3.50312 2.00646 3.50185 2.00307 3.49998 2L14 7.98937L3.49998 14Z" />
  </svg>
);

function animateInText({ tl, el }: { tl: GSAPTimeline; el: HTMLElement }) {
  const disposers: (() => void)[] = [];
  const innerText = el.innerText;
  if (innerText) {
    innerText.split(" ");
    const innerHtml = innerText.split(" ").map(word => `<span>${word}</span>`);
    el.innerHTML = innerHtml.join(" ");
    const words = Array.from(el.children) as HTMLElement[];
    words.forEach((w, i) => {
      if (i === 0) return;
      const d = animateInElement({
        tl,
        el: w,
        duration: 0.01,
        wait: `+=${((i % 4) * 40) / 1000}`,
      });
      disposers.push(d);
    });
    disposers.push(() => {
      el.innerHTML = innerText;
    });
  }
  return () => {
    disposers.forEach(d => d());
  };
}

function animateTypeAndSend({
  tl,
  el,
  messageComposerInputTextEl,
}: {
  tl: GSAPTimeline;
  el: HTMLElement;
  messageComposerInputTextEl?: HTMLElement;
}) {
  const innerText = el.innerText;
  if (!innerText) return () => {};
  tl.add(() => {}, "+=0.75");
  innerText.split(" ");
  const letters = innerText.split("");

  letters.forEach((w, i) => {
    const inputValue = letters.slice(0, i + 1).join("");
    tl.add(() => {
      if (messageComposerInputTextEl) {
        messageComposerInputTextEl.innerHTML = inputValue;
      }
    }, `+=${((i % 4) * 16) / 1000}`);
  });
  tl.add(() => {
    if (messageComposerInputTextEl) {
      messageComposerInputTextEl.innerHTML = "";
    }
  }, "+=.5");
  tl.set(el, { clearProps: "display" });
  tl.to(el, { opacity: 1, duration: 0.1 });
  return () => {};
}

function animateInElement({
  tl,
  el,
  isMessageContainer,
  type,
  sender,
  messageComposerInputTextEl,
  aiTypingIndicatorEl,
  duration,
  wait,
}: {
  tl: GSAPTimeline;
  el: HTMLElement;
  isMessageContainer?: boolean;
  type?: "text" | "command" | null;
  sender?: "ai" | "user" | null;
  messageComposerInputTextEl?: HTMLElement;
  aiTypingIndicatorEl?: HTMLElement;
  duration?: number;
  wait?: string;
}) {
  const scrollParent = getScrollParent(el);
  const disposers: (() => void)[] = [];
  gsap.set(el, { opacity: 0, display: "none" });
  const isUserMessage = isMessageContainer && sender === "user";
  if (!isUserMessage) {
    tl.set(
      el,
      { clearProps: "display" },
      isMessageContainer ? "+=0.75" : undefined
    );
    tl.to(el, { opacity: 1, duration: duration ?? 0.05 }, wait);
  }
  const scrollToBottom = () => {
    tl.add(() => {
      if (scrollParent && scrollParent !== document.body) {
        scrollParent.scrollTop = scrollParent.scrollHeight;
      }
    });
  };
  switch (type) {
    case "command": {
      if (isMessageContainer) {
        scrollToBottom();
        const confirmButton = el.querySelector(".confirmButton");
        const confirmCursor = el.querySelector(".confirmCursor");
        if (confirmCursor) {
          tl.to(confirmCursor, {
            opacity: 0,
            duration: 0.01,
          });
          tl.fromTo(
            confirmCursor,
            {
              opacity: 0,
              x: 42,
              y: 6,
            },
            {
              opacity: 1,
              x: 0,
              y: 0,
              duration: 0.5,
            },
            "+=0.25"
          );
          tl.add(() => {
            confirmButton?.classList.add("hover");
          }, "-=0.25");
          tl.add(() => {
            confirmButton?.classList.add("active");
          }, "+=0.25");
          tl.add(() => {
            confirmButton?.classList.remove("active");
          }, "+=0.1");
          tl.fromTo(
            confirmCursor,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.2,
            }
          );
          tl.add(() => {
            confirmButton?.classList.remove("hover");
          }, "-=0.2");
        }
        tl.add(() => {
          el.classList.remove("awaitingConfirm");
          el.classList.add("confirmed");
          el.classList.add("running");
        });
        tl.add(() => {
          el.classList.remove("running");
        }, "+=0.75");

        disposers.push(() => {
          if (confirmCursor) {
            el.classList.add("awaitingConfirm");
          }
          el.classList.remove("confirmed");
          el.classList.remove("running");
          confirmButton?.classList.remove("hover");
          confirmButton?.classList.remove("active");
        });
      }
      break;
    }
    case "text":
    default: {
      if (sender === "ai") {
        tl.add(() => {
          aiTypingIndicatorEl?.classList.add("visible");
        });
      }
      if (el.children.length > 0) {
        Array.from(el.children).forEach(child => {
          const d = animateInElement({
            tl,
            el: child as HTMLElement,
            type,
            sender,
            duration,
            wait,
            messageComposerInputTextEl:
              sender === "user" ? messageComposerInputTextEl : undefined,
          });
          disposers.push(d);
        });
      } else if (type === "text") {
        if (sender === "ai") {
          const d = animateInText({
            tl,
            el,
          });
          disposers.push(d);
        }
      }
      if (isUserMessage) {
        const d = animateTypeAndSend({
          tl,
          el,
          messageComposerInputTextEl,
        });
        disposers.push(d);
      }
      if (sender === "ai") {
        tl.add(() => {
          aiTypingIndicatorEl?.classList.remove("visible");
        });
      }
      scrollToBottom();
    }
  }

  return () => {
    disposers.forEach(d => d());
  };
}

function createChatStackAnimationTimeline({
  stackEl,
  chatId,
  chatTitle,
}: {
  stackEl: HTMLDivElement;
  chatId?: string;
  chatTitle?: string;
}) {
  const disposers: (() => void)[] = [];
  const tl = gsap.timeline({
    paused: true,
    onStart: () => {
      stackEl.classList.add("animating");
    },
    onComplete: () => {
      stackEl.classList.add("animationCompleted");
      stackEl.classList.remove("animating");
    },
  });
  disposers.push(() => {
    stackEl.classList.remove("animating");
    stackEl.classList.remove("animationCompleted");
  });
  const messageEls = Array.from(stackEl.querySelectorAll(".message"));
  const messageComposerInputTextEl = stackEl.querySelector(
    ".MessageComposerInputText"
  ) as HTMLDivElement | undefined;
  const aiTypingIndicatorEl = stackEl.querySelector(
    ".WorkbenchAITypingIndicator"
  ) as HTMLDivElement | undefined;
  tl.add(() => {
    messageComposerInputTextEl?.classList.add("hasFocus");
  });
  messageEls.forEach((el, i) => {
    const msg = el as HTMLElement;
    const type = msg.getAttribute("data-type") as "text" | "command" | null;
    const sender = msg.getAttribute("data-sender") as "ai" | "user" | null;
    const dispose = animateInElement({
      tl,
      el: msg,
      isMessageContainer: true,
      type,
      sender,
      messageComposerInputTextEl,
      aiTypingIndicatorEl,
    });
    disposers.push(dispose);
    if (i === 0) {
      tl.add(() => {
        stackEl.classList.add("chatStarted");
      }, "-=0.2");
      if (chatId && chatTitle) {
        tl.add(() => {
          const titleContainer = document.getElementById(
            `${chatId}__titleContainer`
          );
          if (titleContainer) {
            titleContainer.innerHTML = chatTitle;
            disposers.push(() => {
              titleContainer.innerHTML = "New chat";
            });
          }
        });
      }
      disposers.push(() => {
        stackEl.classList.remove("chatStarted");
      });
    }
  });
  tl.add(() => {
    messageComposerInputTextEl?.classList.remove("hasFocus");
  });
  return {
    dispose: () => {
      disposers.forEach(d => d());
      tl.kill();
    },
    timeline: tl,
  };
}

const EmptyStateScreenWrap = styled.div`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s;
  .chatStarted & {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
  p {
    max-width: 23em;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.35;
    + p {
      font-size: 1.1rem;
      opacity: 0.7;
    }
  }
`;

const EmptyStateScreen = () => {
  return (
    <EmptyStateScreenWrap>
      <WorkbenchTwoToneIcon />
      <p>
        <strong>Welcome to Workbench</strong>
      </p>
      <p>Chat with a language model, supercharged by the Tines platform.</p>
    </EmptyStateScreenWrap>
  );
};
