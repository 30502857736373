import { Helmet } from "react-helmet";
import { isTinesComBuildOnMain } from "../../environment";
import defaultOgImage from "../../../static/images/og/tines-logo.png";
import { useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";

type Props = {
  title: string;
  seoTitle?: string | null;
  description?: string | null;
  image?: string | null;
  keywords?: string | null;
  ogType?: string;
  ogUrl?: string;
  canonical?: string | null;
  noindex?: boolean | null;
};

const makePageTitle = (pageName: string) => {
  return pageName
    ? pageName.includes("|")
      ? pageName
      : `${pageName} | Tines`
    : "Tines | The smart, secure workflow builder";
};

const SEO = (props: Props) => {
  const originalTitle = makePageTitle(props.title);
  const seoTitle = makePageTitle(props.seoTitle || props.title);
  const [displayTitle, setDisplayTitle] = useState(seoTitle);
  const description =
    props.description ??
    "Build, run, and monitor workflows with the most intuitive platform for security-minded teams.";
  const imageSrc = props.image?.split("?")[0] || defaultOgImage;
  useOnMount(() => {
    if (seoTitle !== originalTitle) {
      setDisplayTitle(originalTitle);
    }
  });
  return (
    <Helmet>
      <title children={displayTitle} />
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={
          props.keywords
            ? props.keywords
            : "Tines, security workflow automation, No-Code, SOAR"
        }
      />
      {(props.noindex || !isTinesComBuildOnMain) && (
        <meta name="robots" content="noindex" />
      )}
      {/* open graph og meta tags */}
      {props.ogUrl && (
        <meta
          property="og:url"
          content={
            /^https?:\/\//.test(props.ogUrl)
              ? props.ogUrl
              : `${process.env.GATSBY_SITE_URL}/${props.ogUrl.replace(
                  /^\//,
                  ""
                )}`
          }
        />
      )}
      {(props.ogType ? true : null) && (
        <meta property="og:type" content="article" />
      )}
      {props.canonical && <link rel="canonical" href={props.canonical} />}
      <meta name="title" content={seoTitle} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={description} />
      <meta name="image" property="og:image" content={imageSrc} />
      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="tines_io" />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageSrc} />
    </Helmet>
  );
};

export default SEO;
