import { styled } from "@linaria/react";
import {
  ProductPageH3,
  ProductPageSubSection,
  ProductPageSubSectionP,
} from "./_ProductPageSharedComponents";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import img from "./illustrations/reportingCardContents.svg";
import { GridCanvas } from "../decorations/GridCanvas";
import {
  fromTablet,
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { words } from "../../utils/string.utils";

const Layout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  grid-template-areas: "figure" "header";
  header {
    grid-area: header;
  }
  figure {
    grid-area: figure;
  }
  ${uptoTabletLg} {
    h3 {
      display: none;
    }
    p {
      margin-top: 0;
    }
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ${`"${words("header", 4)} ${words("figure", 8)}"`};
  }
`;

const Figure = styled.figure`
  position: relative;
  margin: 0;
  background-color: ${colors.orange50};
  border: 1px solid ${colors.orange300};
  border-radius: ${rSize("radius")};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 2em;
  ${fromTablet} {
    padding-top: 5em;
  }
  border-radius: ${rSize("radius")};
  img {
    background-color: ${colors.lightest};
    border-radius: ${rSize("radius")} ${rSize("radius")} 0 0;
    border: 1px solid ${colors.orange300};
    border-bottom: 0;
    position: relative;
    max-width: calc(100% - 4em);
  }
`;

export const ReportingSubsection = () => (
  <ProductPageSubSection>
    <Layout>
      <header>
        <ProductPageH3>Reporting</ProductPageH3>
        <ProductPageSubSectionP>
          Real-time <strong>reporting</strong> tracks general workflow
          performance (as well as user-defined performance like time saved) at
          the action or workflow-level.
        </ProductPageSubSectionP>
      </header>
      <Figure>
        <GridCanvas color={colors.orange200} parallax />
        <img src={img} />
      </Figure>
    </Layout>
  </ProductPageSubSection>
);
