export const MapPinTwoToneIcon = (props: { darker?: boolean }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      opacity={props.darker ? 0.75 : 0.4}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7872 12.0775C13.0308 10.5534 14.1624 9.16661 14.1624 6.96879C14.1624 4.2246 11.8511 2 9 2C6.14889 2 3.83762 4.2246 3.83762 6.96879C3.83762 9.16661 4.96922 10.5534 6.21281 12.0775C7.12223 13.1921 8.09153 14.38 8.72199 16.0122C8.81259 16.2467 9.1874 16.2467 9.278 16.0122C9.90846 14.38 10.8778 13.1921 11.7872 12.0775ZM9 8.5C9.82843 8.5 10.5 7.82843 10.5 7C10.5 6.17157 9.82843 5.5 9 5.5C8.17157 5.5 7.5 6.17157 7.5 7C7.5 7.82843 8.17157 8.5 9 8.5Z"
      fill="currentColor"
    />
    <path
      d="M13.6624 6.96879C13.6624 8.69566 12.9055 9.87711 11.8884 11.1573C11.7343 11.3514 11.5735 11.5482 11.4089 11.7496C10.5813 12.7628 9.65806 13.8929 9 15.3775C8.34193 13.8929 7.41873 12.7628 6.59106 11.7496C6.42651 11.5482 6.26574 11.3514 6.11157 11.1573C5.09453 9.87711 4.33762 8.69566 4.33762 6.96879C4.33762 4.51859 6.40685 2.5 9 2.5C11.5931 2.5 13.6624 4.51859 13.6624 6.96879Z"
      stroke="currentColor"
    />
  </svg>
);
