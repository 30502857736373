import { styled } from "@linaria/react";
import { CSSProperties, PropsWithChildren } from "react";
import { cx } from "linaria";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { inParagraphLinkStyle } from "../../../styles/links.styles";
import { fromReportV2DesktopDesign } from "./reportsV2.helpers";
import {
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";

export const reportV2BodyTextParagraphStyle = `
  font-size: 1.6rem;
  line-height: 1.5;
  ${fromReportV2DesktopDesign} {
    font-size: 1.4rem;
  }
  + p {
    margin-top: 1em;
  }
`;

export const ReportV2TextBlockDiv = styled.div`
  p {
    ${reportV2BodyTextParagraphStyle}
  }
  &.columned {
    ${fromTablet} {
      columns: 2;
      column-gap: ${rSize("gap")};
    }
  }
  &.largerText {
    p {
      ${fromReportV2DesktopDesign} {
        font-size: 1.6rem;
        line-height: 1.45;
      }
    }
  }
  &.limitWidth {
    p {
      max-width: 38em;
    }
  }
  &.centerOnMobile {
    ${onlyPhones} {
      text-align: center;
    }
  }
  a {
    ${inParagraphLinkStyle()}
    font-weight: 600;
  }
`;

export const ReportV2TextBlock = (
  props: PropsWithChildren<{
    columned?: boolean;
    largerText?: boolean;
    limitWidth?: boolean;
    centerOnMobile?: boolean;
    accent?: string;
  }>
) => {
  return (
    <ReportV2TextBlockDiv
      className={cx(
        props.columned && "columned",
        props.largerText && "largerText",
        props.limitWidth && "limitWidth",
        props.centerOnMobile && "centerOnMobile"
      )}
      style={
        props.accent
          ? ({
              "--ac": props.accent,
            } as CSSProperties)
          : undefined
      }
    >
      {props.children}
    </ReportV2TextBlockDiv>
  );
};
