import { styled } from "@linaria/react";
import {
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import { withOpacity } from "../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { TextInputStyled } from "../forms/TextInput";
import { css } from "linaria";
import LinkToAnchor from "../basic/LinkToAnchor";
import { PropsWithChildren, ReactNode } from "react";
import GlobalSearchBar, {
  GlobalSearchBarContainer,
} from "../search/GlobalSearchBar";
import { ClapperboardTwoToneIcon } from "../icons/twoTone/ClapperboardTwoToneIcon";
import { MapPinWithMapTwoToneIcon } from "../icons/twoTone/MapPinWithMapTwoToneIcon";
import { BookShelfTwoToneIcon } from "../icons/twoTone/BookShelfTwoToneIcon";
import { LibraryTwoToneIcon } from "../icons/twoTone/LibraryTwoToneIcon";
import { BookWithLightbulbTwoToneIcon } from "../icons/twoTone/BookWithLightbulbTwoToneIcon";
import { CompassTwoToneIcon } from "../icons/twoTone/CompassTwoToneIcon";
import { RocketTwoToneIcon } from "../icons/twoTone/RocketTwoToneIcon";
import { GiftTwoToneIcon } from "../icons/twoTone/GiftTwoToneIcon";
import { LifebuoyTwoToneIcon } from "../icons/twoTone/LifebuoyTwoToneIcon";

const CustomerCenterSidebarAside = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  align-self: start;
  a {
    text-decoration: none;
  }
  ${uptoTablet} {
    display: none;
  }
  position: sticky;
  top: calc(var(--pageTopFixedElementsHeight, 0px));
  overflow: auto;
  top: calc(var(--pageTopFixedElementsHeight, 0px) + 2em);
  max-height: calc(
    100vh - var(--pageTopFixedElementsHeight, 0px) - ${rSize("gap")}
  );
  transition: top 0.25s, max-height 0.25s;
  margin-left: -0.75em;
  padding-left: 0.75em;
  /* padding-right: 0.75em; */
  padding-bottom: ${rSize("lg")};
  ${GlobalSearchBarContainer} {
    &.withIcon {
      svg {
        left: 1em;
      }
      ${TextInputStyled} {
        border: 1px solid transparent;
        background-color: ${colors.purple50};
        color: ${colors.purple800};
        border-radius: 0.75em;
        padding-left: 2.5em;
        &:hover {
          background-color: ${colors.purple100};
        }
      }
    }
  }
  [opacity="0.1"],
  [opacity="0.2"] {
    ${darkModeLinariaCSS(`opacity: 0;`)}
  }
`;

const NavContent = styled.div`
  overflow: auto;
`;

const TopBar = styled.div`
  margin-bottom: 0.5em;
`;

const NavContentList = styled.div``;

export const CustomerCenterSidebar = () => {
  return (
    <CustomerCenterSidebarAside className="CustomerCenterSidebar">
      <NavContent>
        <TopBar>
          <GlobalSearchBar withIcon />
        </TopBar>
        <NavContentList>
          <SidebarItem
            icon={<ClapperboardTwoToneIcon />}
            label="Webinars"
            id="webinars"
          />
          <SidebarItem
            icon={<MapPinWithMapTwoToneIcon />}
            label="Upcoming events"
            id="events"
          />
          <SidebarItem
            icon={<LibraryTwoToneIcon />}
            label="Resources"
            id="resources"
          />
          <SidebarItem
            icon={<BookShelfTwoToneIcon />}
            label="Learn Tines"
            id="learn-tines"
          />
          <SidebarItem
            icon={<CompassTwoToneIcon />}
            label="Tutorials"
            id="tutorials"
          />
          <SidebarItem
            icon={<RocketTwoToneIcon />}
            label="Inspiration"
            id="inspiration"
          />
          <SidebarItem
            icon={<BookWithLightbulbTwoToneIcon />}
            label="Documentation"
            id="docs"
          />
          <SidebarItem
            icon={<GiftTwoToneIcon />}
            label="Product updates"
            id="product-updates"
          />
          <SidebarItem
            icon={<LifebuoyTwoToneIcon />}
            label="Useful links"
            id="useful-links"
          />
        </NavContentList>
      </NavContent>
    </CustomerCenterSidebarAside>
  );
};

const SidebarItem = (
  props: PropsWithChildren<{ icon: ReactNode; label: string; id?: string }>
) => {
  return (
    <LinkToAnchor to={`#${props.id}`} className={SidebarItemCss}>
      {props.icon}
      <span>{props.label}</span>
    </LinkToAnchor>
  );
};

const SidebarItemCss = css`
  &[aria-current="page"],
  &.active {
    color: ${colors.purple};
  }
  cursor: pointer;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  border-radius: 0.75em;
  font-weight: 500;
  padding: 1em 1em;
  ${fromTablet} {
    padding: 0.66em 1em;
  }
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
  &:hover {
    ${fromTablet} {
      color: ${colors.purple};
      background-color: ${withOpacity(colors.purple, 0.1)};
      .chevron {
        opacity: 0.8;
      }
    }
  }
`;
