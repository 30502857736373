import { Link } from "gatsby";
import ListWithPagination from "../basic/ListWithPagination";
import { Paths } from "../../utils/pathBuilders.utils";
import React from "react";
import {
  LibraryTableCellWithToolIcons,
  LibraryTablePartnerTag,
  LibraryTableRowTr,
  LibraryTableSortableColumnHeading,
  LibraryTableTagCell,
  LibraryTableWrap,
} from "./LibraryTableComponents";
import { LibraryTableToolIcon } from "./LibraryTableToolIcon";
import { DatoCmsLibraryToolWithComputedProperties } from "../../utils/library.utils";
import { LibraryState } from "./WithLibraryState";

type Props = {
  tools: DatoCmsLibraryToolWithComputedProperties[];
  state: LibraryState;
};

const LibraryToolTable = (props: Props) => {
  return (
    <LibraryTableWrap>
      <ListWithPagination
        defaultPerPage={15}
        allowCustomPerPageCount
        entries={props.tools}
        scrollToHashOnNavigation="#find-and-filter"
        children={entries => (
          <table>
            <thead>
              <tr>
                <th></th>
                <LibraryTableSortableColumnHeading
                  currentSort={props.state.sort}
                  asc="nameAsc"
                  desc="nameDesc"
                  onChange={props.state.setSort}
                >
                  Name
                </LibraryTableSortableColumnHeading>
                {/* <th>Vendor</th> */}
                <th>Stories</th>
                {/* <th>Categories</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {entries.map(s => (
                <ToolRow key={s.id} tool={s} />
              ))}
            </tbody>
          </table>
        )}
      />
    </LibraryTableWrap>
  );
};

const ToolRow = ({
  tool,
}: {
  tool: DatoCmsLibraryToolWithComputedProperties;
}) => {
  return (
    <LibraryTableRowTr>
      <LibraryTableCellWithToolIcons
        style={{ width: "2.5em", paddingRight: 0 }}
      >
        <LibraryTableToolIcon tool={tool} />
      </LibraryTableCellWithToolIcons>
      <th>
        <Link to={Paths.library.tool(tool.slug!)}>{tool.name}</Link>
      </th>
      {/* <td>{tool.vendor}</td> */}
      <td>{tool.numberOfStories}</td>
      {/* <td>{tool.categories}</td> */}
      <LibraryTableTagCell>
        {tool.isPartner && (
          <LibraryTablePartnerTag>Partner</LibraryTablePartnerTag>
        )}
      </LibraryTableTagCell>
    </LibraryTableRowTr>
  );
};

export default LibraryToolTable;
