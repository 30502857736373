import { css } from "@linaria/core";
import { Link } from "gatsby";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { GreenhouseJob } from "../../../graphql-types";
import GreenhouseJobEntryLocationDisplay from "./GreenhouseJobEntryLocationDisplay";

type Props = {
  job: Partial<GreenhouseJob>;
};

const greenhouseJobEntryLinkStyle = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: 1em;
  padding-bottom: 1em;
  cursor: pointer;
  &:hover {
    color: ${colorsV4.purple};
  }
  > div {
    flex: 1 1 auto;
    padding-right: 1em;
  }
  h4 {
    font-weight: 500;
    font-size: inherit;
  }
  p {
    opacity: 0.5;
  }
  span {
    font-weight: 700;
    color: ${colorsV4.purple};
  }
  strong {
    font-weight: 400;
  }
`;

const GreenhouseJobEntryLink = (props: Props) => {
  return (
    <Link
      data-playwright="job-listing-link"
      className={greenhouseJobEntryLinkStyle}
      to={`/careers/jobs/${props.job.gh_Id}`}
      data-offices={props.job.offices?.map(o => o?.name).join(", ")}
      data-location={props.job.location?.name}
      data-departments={props.job.departments?.map(d => d?.name).join(", ")}
    >
      <div>
        <h4>{props.job.title}</h4>
        <p>
          <GreenhouseJobEntryLocationDisplay job={props.job} />
        </p>
      </div>
      <span>→</span>
    </Link>
  );
};

export default GreenhouseJobEntryLink;
