type Props = { filled?: boolean };

const ThumbsDownIcon = (props: Props) => {
  return (
    <svg
      data-name="thumbs-down"
      data-filled={props.filled}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      {props.filled ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9795 20.1587C11.7582 19.7694 12.25 18.9735 12.25 18.103V15.25H18C18.6904 15.25 19.25 14.6904 19.25 14C19.25 13.3096 18.6904 12.75 18 12.75L16.6714 12.75C16.3786 12.75 16.3244 12.3332 16.6074 12.2583L17.8846 11.9203L17.2658 7.47329C17.1175 6.40703 16.1518 5.64949 15.0809 5.75933L8.14797 6.4704C8.09117 6.47622 8.03589 6.48673 7.98249 6.5015C7.86183 5.65269 7.13214 5 6.25 5H5.75C4.7835 5 4 5.7835 4 6.75V15.25C4 16.2165 4.7835 17 5.75 17H6.25C7.17184 17 7.92721 16.2872 7.99504 15.3827C9.38553 15.9222 9.71411 17.9892 9.77107 19.4011C9.79526 20.0007 10.4428 20.4271 10.9795 20.1587ZM5.5 15.25C5.5 15.3881 5.61193 15.5 5.75 15.5H6.25C6.38807 15.5 6.5 15.3881 6.5 15.25V6.75C6.5 6.61193 6.38807 6.5 6.25 6.5H5.75C5.61193 6.5 5.5 6.61193 5.5 6.75V15.25Z"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3149 20.8295C10.2352 21.3694 9.06523 20.5108 9.02168 19.4313C8.98986 18.6426 8.87029 17.7184 8.55917 17.0157C8.35595 16.5568 8.10578 16.2535 7.77691 16.1056C7.47718 16.6394 6.9057 17 6.25 17H5.75C4.7835 17 4 16.2165 4 15.25V6.75C4 5.7835 4.7835 5 5.75 5H6.25C6.86526 5 7.40636 5.31751 7.71839 5.79761C7.83129 5.76171 7.94937 5.73684 8.07145 5.72431L15.0044 5.01325C16.4769 4.86222 17.8047 5.90383 18.0087 7.36994L18.6688 12.1146C19.4445 12.3897 20 13.13 20 14C20 15.1046 19.1046 16 18 16H13V18.103C13 19.2576 12.3477 20.3131 11.3149 20.8295ZM6.5 7.46388C6.5 7.46431 6.5 7.46475 6.5 7.46518L6.5 15.25C6.5 15.3881 6.38807 15.5 6.25 15.5H5.75C5.61193 15.5 5.5 15.3881 5.5 15.25V6.75C5.5 6.61193 5.61193 6.5 5.75 6.5H6.25C6.38807 6.5 6.5 6.61193 6.5 6.75V7.46388ZM8 7.46469C8.00025 7.33671 8.09713 7.22955 8.22449 7.21649L15.1574 6.50542C15.8267 6.43677 16.4303 6.91023 16.523 7.57664L17.1384 12H16.75C16.3358 12 16 12.3358 16 12.75C16 13.1642 16.3358 13.5 16.75 13.5H18C18.2761 13.5 18.5 13.7239 18.5 14C18.5 14.2761 18.2761 14.5 18 14.5H12.25C11.8358 14.5 11.5 14.8358 11.5 15.25V18.103C11.5 18.6895 11.1687 19.2256 10.6441 19.4879C10.6384 19.4907 10.6361 19.491 10.6311 19.4905C10.6234 19.4899 10.6071 19.4862 10.5864 19.4731C10.5406 19.4438 10.5217 19.4018 10.5205 19.3708C10.4863 18.5237 10.3575 17.3724 9.93074 16.4084C9.5766 15.6086 8.96918 14.8543 8 14.595L8 7.46518C8 7.46502 8 7.46485 8 7.46469Z"
        />
      )}
    </svg>
  );
};

export default ThumbsDownIcon;
