/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

function toPascalCase(input) {
  if (!input) return "";
  return input
    .replace(/(?:^\w|[A-Z]|\b\w)/g, word => word.toUpperCase())
    .replace(/\s+/g, "");
}
exports.toPascalCase = toPascalCase;

const makeSlug = string => {
  return normalizeString(string) //
    .toLowerCase()
    .trim()
    .replace(/(\s|_)+/g, "-")
    .replace(/&/g, " and ")
    .replace(/[^\w\-]+/g, "-")
    .replace(/\-+/g, "-")
    .replace(/^\-/g, "")
    .replace(/\-$/g, "");
};
exports.makeSlug = makeSlug;

const normalizeString = input => {
  return (`${input}` || "").normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
exports.normalizeString = normalizeString;

const formatAsSearchString = input => {
  return normalizeString(input)
    .toLowerCase()
    .replace(/[^0-9a-z-_@.]/gi, "");
};
exports.formatAsSearchString = formatAsSearchString;

const getPlainTextFromHTMLString = string =>
  `${string ?? ""}`.replace(/<[^>]+>/g, "");

exports.getPlainTextFromHTMLString = getPlainTextFromHTMLString;
