/* eslint-disable unused-imports/no-unused-vars */
import { CSSProperties, RefObject, createContext, useContext } from "react";
import { Action, StoryRecord } from "../../types/tines.types";
import { XY } from "../../utils/geometry.utils";
import { NoteOrAssetRenderStateObject } from "./NoteEntry";
import { gridUnit } from "./utils/storyboard.utils";
import { createStoryFromRecord } from "./utils/story.utils";

const emptyClipboardStoryRecord: StoryRecord = {
  standardLibVersion: "13",
  actionRuntimeVersion: "1",
  agents: [],
  links: [],
  diagramNotes: [],
  jsonString: "",
};

export type StoryboardDecorationComponentProps = { style: CSSProperties };

export type StoryboardDecorationComponent =
  React.FC<StoryboardDecorationComponentProps>;

export type StoryboardDecorationDef = {
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
  component: StoryboardDecorationComponent;
};

const defaultStoryboardContext = {
  id: 0 as number | string,
  ready: false,
  ref: { current: null } as RefObject<HTMLDivElement>,
  setReady: () => {},
  story: createStoryFromRecord(emptyClipboardStoryRecord),
  storyboard: {
    width: 0,
    height: 0,
    center: { x: 0, y: 0 },
    boundingBox: [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
    ],
    decorations: [] as StoryboardDecorationDef[],
  },
  viewport: {
    width: 1280,
    height: 720,
  },
  inset: {
    top: gridUnit(6),
    bottom: gridUnit(6),
    left: gridUnit(6),
    right: gridUnit(6),
  },
  options: {
    delay: 0,
    inspectable: false,
    enableWheelPan: false,
    enableManualZoom: false,
    enableDragPan: false,
    useMarketingStyles: false,
    actionShouldBeHidden: (action: Action) => false,
  },
  noteRenderStates: [] as NoteOrAssetRenderStateObject[],
  get focus() {
    return { x: 0, y: 0 };
  },
  setFocus: (xy: XY) => {},
  zoom: 1,
  setZoom: (v: number) => {},
  panToPosition: (position: XY, animate?: boolean) => {},
  zoomTo: (level: number, animate?: boolean) => {},
  resetView: (animate?: boolean) => {},
  selectedActionId: null as number | null,
  updateActionSelection: (action?: Action | null) => {},
};

export const StoryboardContext = createContext(defaultStoryboardContext);
export const useStoryboardContext = () => useContext(StoryboardContext);
export type StoryboardContextValue = typeof defaultStoryboardContext;
