import { styled } from "@linaria/react";
import VotingBanner from "../components/hub/VotingBanner";
import PageBreadCrumbs from "../components/basic/PageBreadCrumbs";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import StandardArticle from "../components/articles/StandardArticle";
import {
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../styles/colors.styles";
import { PageComponent } from "../types/gatsby.types";
import { FormulasFunctionDef } from "../types/helper.types";
import { darkModeLinariaCSS } from "../utils/colorScheme.utils";
import { PageTree, getBreadcrumbsFromTree } from "../utils/tree.utils";
import {
  HubPageHeader,
  WiderArticlePageBody,
} from "../components/hub/HubPageComponents";
import Page from "./Page.template";
import SpaceChildren from "../components/layout/SpaceChildren";
import HubPrevNextNav from "../components/hub/HubPrevNextNav";
import ogImage from "../../static/images/og/tines-docs.png";
import LinkedHeading from "../components/utilities/LinkedHeading";
import StoryboardViewerStacked, {
  StoryboardViewportWrap,
} from "../components/storyboard/StoryboardViewerStacked";
import { css } from "linaria";
import { hideScrollbarsCSS } from "../utils/scroll.utils";
import { Fragment } from "react";
import FormulasPill, {
  FormulasPillContainer,
  FormulasPillInner,
} from "../components/general/FormulasPill";
import ClickToCopy from "../components/utilities/ClickToCopy";
import FormattedJSONBlock from "../components/storyboard/FormattedJSONBlock";
import { rSize } from "../styles/responsiveSizes.styles";
import { BookWithLightbulbTwoToneIcon } from "../components/icons/twoTone/BookWithLightbulbTwoToneIcon";
import ReactMarkdown from "react-markdown";
import { inParagraphLinkStyle } from "../styles/links.styles";

const ContentWrap = styled.div``;

const Description = styled.div`
  code {
    font-size: 90%;
    background-color: ${withOpacity(colors.green, 0.1)};
    color: ${colors.green700};
    padding: 0.1em 0.4em;
    border-radius: 0.35em;
    ${darkModeLinariaCSS(`color: ${colors.green};`)}
  }
`;

const ExampleCard = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.light200};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.dark500, 0.5)};`
  )}
  padding: 1em;
  border-radius: ${rSize("radius")};
  ${fromTablet} {
    padding: 1.5em;
  }
  header {
    padding-top: 1em;
    h3 {
      position: absolute;
      top: 0;
      left: 1.25em;
      ${fromTablet} {
        left: 1.75em;
      }
      background-color: ${withOpacity(colors.purple, 0.2)};
      color: ${colors.purple700};
      ${darkModeLinariaCSS(`color: ${colors.purple300};`)}
      padding: .5em 1em;
      font-size: 1.4rem;
      font-weight: 700;
      border-radius: 0 0 0.5em 0.5em;
    }
    > * {
      + * {
        margin-top: 0.75em;
      }
    }
  }
  hr {
    margin: 1em 0;
  }
  table {
    table-layout: fixed;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.4rem;
    p {
      font-weight: 500;
    }
    th,
    td {
      padding-left: 0;
    }
    td {
      &:first-child {
        width: 4.5em;
        padding-top: 1.5em;
        padding-right: 0.5em;
        ${fromTablet} {
          width: 5em;
          padding-right: 0.75em;
        }
      }
    }
    tbody {
      tr {
        th,
        td {
          border-bottom: 0;
          padding-bottom: 0;
          padding-right: 0;
        }
      }
    }
    pre[class*="language-"] {
      margin: 0;
      font-size: 1.4rem;
    }
    code {
      color: inherit !important;
    }
  }
  + * {
    margin-top: 1.5em;
  }
`;

const ExampleFormulasPillWrap = styled.div`
  ${FormulasPillContainer} {
    display: flex;
    font-size: 1.6rem;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    min-width: 100%;
    max-width: unset;
    padding-right: 0.5em;
    cursor: pointer;
    svg {
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      transform: translateY(0.1em);
    }
    ${FormulasPillInner} {
      display: grid;
      white-space: normal;
      width: 100%;
      grid-template-columns: auto minmax(0, 1fr);
      align-items: start;
    }
    i {
      padding-top: 0;
      padding-bottom: 0;
    }
    code {
      display: block;
      background-color: transparent;
      color: inherit;
      font-size: inherit;
      padding: 0;
      font-weight: 500;
      white-space: normal;
      overflow-wrap: anywhere;
      ${darkModeLinariaCSS(`color: inherit;`)}
    }
    &:hover {
      background-color: ${withOpacity(colors.purple, 0.1)};
    }
  }
`;

const SampleActionsCardList = styled.div`
  > * {
    + * {
      margin-top: 1.5em;
    }
  }
`;

const descriptionStyle = css`
  font-size: 1.6rem;
  margin-bottom: 0.5em;
  code {
    font-size: 90%;
    background-color: ${withOpacity(colors.green, 0.1)};
    color: ${colors.green700};
    padding: 0.1em 0.4em;
    border-radius: 0.35em;
    ${darkModeLinariaCSS(`color: ${colors.green};`)}
  }
  a {
    ${inParagraphLinkStyle()}
  }
`;

const DocsPageFormulasFunctionTemplate: PageComponent<{
  pageContext: { tree: PageTree; function: FormulasFunctionDef };
}> = props => {
  const { location } = props;
  const { function: fn } = props.pageContext;
  const levels = getBreadcrumbsFromTree(
    location.pathname,
    {
      title: "Docs",
      path: "/docs",
    },
    props.pageContext.tree
  ).map(l => {
    if (l.title === fn.name)
      return {
        ...l,
        titleNode: <code>{l.title}</code>,
      };
    return l;
  });

  return (
    <Page {...props}>
      <SEO
        title={`${fn.name} | Docs | Tines`}
        image={ogImage}
        description="Get to know the features and concepts of Tines, in detail."
      />
      <WiderArticlePageBody generateTableOfContents supportsColorThemes>
        <PageBreadCrumbs
          icon={<BookWithLightbulbTwoToneIcon />}
          levels={levels}
        />
        <HubPageHeader>
          <h1>
            <code>{fn.name}</code>
          </h1>
          <Description dangerouslySetInnerHTML={{ __html: fn.summaryHtml }} />
        </HubPageHeader>
        <SpaceChildren size="lg">
          <ContentWrap>
            <StandardArticle>
              <LinkedHeading>Syntax</LinkedHeading>
              <ClickToCopy
                contentToCopy={fn.syntax}
                successMessage="Copied to clipboard."
              >
                <ExampleFormulasPillWrap className={hideScrollbarsCSS}>
                  <FormulasPill f={fn.syntax} />
                </ExampleFormulasPillWrap>
              </ClickToCopy>
              {fn.examples && (
                <section>
                  <Spacing />
                  <LinkedHeading id="examples">Usage examples</LinkedHeading>
                  <Spacing />
                  {fn.examples.map((e, i) => (
                    <ExampleCard key={i}>
                      <header>
                        <h3>Example {i + 1}</h3>
                        <div className={descriptionStyle}>
                          {e.description && (
                            <ReactMarkdown linkTarget="_blank">
                              {e.description}
                            </ReactMarkdown>
                          )}
                        </div>
                      </header>
                      <table>
                        <tbody>
                          <>
                            {e.input && (
                              <tr>
                                <td>
                                  <p>Input</p>
                                </td>
                                <td>
                                  <FormattedJSONBlock
                                    source={e.input}
                                    canCopy
                                  />
                                </td>
                              </tr>
                            )}
                            {e.formula && (
                              <tr>
                                <td>
                                  <p>Formula</p>
                                </td>
                                <td>
                                  <ClickToCopy
                                    contentToCopy={e.formula}
                                    successMessage="Copied formula to clipboard."
                                  >
                                    <ExampleFormulasPillWrap
                                      className={hideScrollbarsCSS}
                                    >
                                      <FormulasPill f={e.formula} />
                                    </ExampleFormulasPillWrap>
                                  </ClickToCopy>
                                </td>
                              </tr>
                            )}
                            {e.output !== undefined && (
                              <tr>
                                <td>
                                  <p>Output</p>
                                </td>
                                <td>
                                  <FormattedJSONBlock
                                    source={e.output}
                                    canCopy
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        </tbody>
                      </table>
                    </ExampleCard>
                  ))}
                </section>
              )}
              {fn.sampleActions.length > 0 && (
                <section>
                  <Spacing size="1em" />
                  <LinkedHeading>Sample Actions</LinkedHeading>
                  <Spacing size="1em" />
                  <SampleActionsCardList>
                    {fn.sampleActions.map((sampleActions, i) => {
                      return (
                        <StoryboardViewerStacked
                          story={sampleActions}
                          key={i}
                          id={i}
                          className={css`
                            ${StoryboardViewportWrap} {
                              aspect-ratio: 4/3;
                              ${fromPhoneLg} {
                                aspect-ratio: 2/1;
                              }
                              ${fromTabletMd} {
                                aspect-ratio: 4/3;
                              }
                              ${fromTabletLg} {
                                aspect-ratio: 2/1;
                              }
                            }
                          `}
                        />
                      );
                    })}
                  </SampleActionsCardList>
                </section>
              )}
            </StandardArticle>
          </ContentWrap>
          <VotingBanner />
          <HubPrevNextNav tree={props.pageContext.tree} />
        </SpaceChildren>
      </WiderArticlePageBody>
    </Page>
  );
};

export default DocsPageFormulasFunctionTemplate;
