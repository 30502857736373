import {
  DatoCmsArticle,
  DatoCmsCaseStudy,
  DatoCmsContentCard,
  DatoCmsDocument,
  DatoCmsPodcast,
  DatoCmsWebinar,
} from "../../../graphql-types";
import ContentCardArticle from "./ContentCardArticle";
import { ContentCardCaseStudy } from "./ContentCardCaseStudy";
import { ContentCardDocument } from "./ContentCardDocument";
import ContentCardGeneric from "./ContentCardGeneric";
import { ContentCardLibrary } from "./ContentCardLibrary";
import ContentCardPodcast from "./ContentCardPodcast";
import { ContentCardSOC2023 } from "./ContentCardSOC2023";
import ContentCardUniversity from "./ContentCardUniversity";
import { ContentCardWebinar } from "./ContentCardWebinar";

export const ContentCardDispatcher = ({
  card,
  large,
}: {
  card: DatoCmsContentCard;
  large?: boolean;
}) => {
  switch (card.preset) {
    case "preset:library":
      return <ContentCardLibrary large={large} overrides={card} />;
    case "preset:university":
      return <ContentCardUniversity large={large} overrides={card} />;
    case "preset:soc2023":
      return <ContentCardSOC2023 large={large} overrides={card} />;
    default: {
      switch (card.linkToRecord?.model?.apiKey) {
        case "article":
          const article = card.linkToRecord as DatoCmsArticle;
          return (
            <ContentCardArticle
              article={article}
              overrides={card}
              large={large}
            />
          );
        case "webinar":
          const webinar = card.linkToRecord as DatoCmsWebinar;
          return (
            <ContentCardWebinar
              webinar={webinar}
              overrides={card}
              large={large}
            />
          );
        case "case_study":
          const caseStudy = card.linkToRecord as DatoCmsCaseStudy;
          return (
            <ContentCardCaseStudy
              caseStudy={caseStudy}
              overrides={card}
              large={large}
            />
          );
        case "podcast":
          const podcast = card.linkToRecord as DatoCmsPodcast;
          return (
            <ContentCardPodcast
              podcast={podcast}
              overrides={card}
              large={large}
            />
          );
        case "document":
          const doc = card.linkToRecord as DatoCmsDocument;
          return (
            <ContentCardDocument doc={doc} overrides={card} large={large} />
          );
      }
    }
  }

  return <ContentCardGeneric overrides={card} large={large} />;
};
