import { styled } from "@linaria/react";
import { colorsV4, withOpacity } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import ComboFontHeading from "../../../typography/ComboFontHeading";
import CornerDotSet from "../../../utilities/CornerDotSet";
import Spacing from "../../../layout/Spacing";
import ReportCard from "../../ReportCard";
import ReportSectionHeader from "../../ReportSectionHeader";
import { ReportBaseSectionStyle } from "../../ReportBaseStyles";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import LightbulbIcon from "../../../misc/LightbulbIcon";

const MHConclusionSectionInner = styled.div`
  ${ReportBaseSectionStyle};
  position: relative;
  background-color: ${colorsV4.green};
  color: ${colorsV4.white};
  font-weight: 500;
`;
const MHConclusionSection = () => {
  return (
    <ReportSection
      id="prioritizing-mental-health-today"
      themeName="green"
      darkMode
    >
      <MHConclusionSectionInner>
        <CornerDotSet color={colorsV4.green700} offset={rSize("gap")} />
        <ReportSectionHeader
          icon="blossomingMind"
          backgroundColor={withOpacity(colorsV4.green700, 0.5)}
        >
          <ComboFontHeading
            snippets={[
              [
                <>
                  Prioritizing
                  <br />
                </>,
              ],
              [<>mental health</>, "serif"],
              [<>today</>],
            ]}
          />
        </ReportSectionHeader>
        <Spacing size="gap" />
        <ReportCard backgroundColor={withOpacity(colorsV4.green700, 0.5)}>
          <p>
            Cybersecurity professionals are experiencing increasing levels of
            stress, burnout, and frustration at work, and it’s impacting their
            mental health, according to our respondents. Only 47% rank their
            mental health as “excellent” or “very good,” and 27% say their
            mental health has declined over the past year — a massive flag for
            security leaders who want to ensure they’re caring for their team.
            Security leaders also need to know that two-thirds report
            experiencing stress at work, and say that their work impacts their
            mental health. While there are respondents who are taking steps to
            address their mental health through medication and therapy, is there
            more that can be done?
          </p>
        </ReportCard>
        <Spacing size="gap" />
        <ReportCard backgroundColor={withOpacity(colorsV4.green700, 0.5)}>
          <p>
            The good news is that more organizations are normalizing the
            conversation around mental health. They’re also recognizing that
            good mental health and associated practices can help individuals be
            better prepared to handle stress, uncertainty, and burnout —
            especially important considering that Harvard Business Review found
            that 68% of Millennials and 81% of Gen Z have left workplaces
            because of mental health reasons.
          </p>
        </ReportCard>
        <Spacing size="gap" />
      </MHConclusionSectionInner>
      <ReportSummaryCard
        backgroundColor={colorsV4.warmBlack}
        textColor={colorsV4.white}
      >
        <LightbulbIcon color={colorsV4.green} centered />
        <blockquote>
          <p>
            Prioritizing your team’s mental health is more critical now than
            ever. It not only involves reducing workplace stress, frustrations,
            and friction that can impact mental health, but also providing the
            resources and support needed to help your team understand and
            improve their mental health as well.
          </p>
        </blockquote>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default MHConclusionSection;
