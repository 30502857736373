import { Link } from "gatsby";
import { CSSProperties, css } from "linaria";
import { color, withOpacity } from "../../../styles/colors.styles";
import { styled } from "@linaria/react";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  LearningPathWithProgress,
  getKnownLearningPathConfig,
} from "../../../utils/university.utils";
import { CourseIconDefault } from "./CourseIconDefault";
import UniLearningPathProgressMap from "./UniLearningPathProgressMap";
import UniCompletionCheckIcon from "./UniCompletionCheckIcon";
import { font } from "../../../styles/fonts.styles";
import {
  fromDesktop,
  fromTabletLg,
  fromTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  learningPath: LearningPathWithProgress;
  description: string;
  estimatedTime: number;
};

const cardStyle = css`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  text-decoration: none;
  background-color: var(--ac100);
  position: relative;
  border-radius: ${rSize("radius")};
  color: var(--acDarker);
  overflow: hidden;
  border: 1px solid var(--ac600);
  font-size: 1.4rem;
  ${fromTabletMd} {
    font-size: 1.6rem;
  }
  &:hover {
    border-color: var(--ac300);
  }
  > * {
    padding: ${rSize("lg")};
  }
`;

const Body = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(auto, 1fr);
`;

const Header = styled.div`
  h3 {
    font-family: ${font("serif")};
    font-weight: 400;
    font-size: 2.4rem;
    ${fromDesktop} {
      font-size: 2.8rem;
    }
    + * {
      margin-top: 0.25em;
    }
  }
`;

const Description = styled.p`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  max-width: 20em;
`;

const IconRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: ${rSize("lg")};
`;

const IconWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  aspect-ratio: 100/106;
  width: 64px;
  ${fromTabletLg} {
    width: 100px;
  }
  svg {
    width: 100%;
    height: auto;
  }
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  border-top: 1px dashed var(--ac600);
`;

const EstTime = styled.div`
  display: flex;
  align-items: center;
  > * {
    display: block;
    + * {
      margin-left: 0.5em;
    }
  }
`;

const UniLearningPathCard = (props: Props) => {
  const path = props.learningPath.tree.path;
  const knownConfig = getKnownLearningPathConfig(path.id);
  const Icon = knownConfig?.icon ?? CourseIconDefault;
  const colorName = knownConfig?.color ?? "purple";
  const { hasStarted, completed, tree } = props.learningPath;
  const coverPageUrl = `/university/${path.slug}`;
  const firstUncompletedLesson = tree.children.find(c => !c.completionTime);
  const linkUrl = tree.children.every(c => !c.completionTime)
    ? coverPageUrl
    : firstUncompletedLesson?.path ?? coverPageUrl;
  return (
    <Link
      className={cardStyle}
      to={linkUrl}
      style={
        {
          "--ac": color(colorName),
          "--ac50": color(colorName, 50),
          "--ac100": color(colorName, 100),
          "--ac300": color(colorName, 300),
          "--ac25": withOpacity(color(colorName), 0.25),
          "--ac600": color(colorName, 600),
          "--acDarker": color(colorName, 700),
        } as CSSProperties
      }
    >
      <Body>
        <Header>
          <h3>{path.title}</h3>
          <UniLearningPathProgressMap
            learningPathWithProgress={props.learningPath}
          />
        </Header>
        <Description>{props.description}</Description>
        <IconRow>
          <IconWrap>
            <Icon />
          </IconWrap>
        </IconRow>
      </Body>
      <Footer>
        <div>
          {completed ? (
            <>
              <UniCompletionCheckIcon completed={1} /> Completed
            </>
          ) : hasStarted ? (
            "Continue →"
          ) : (
            "Start learning →"
          )}
        </div>
        {props.estimatedTime && (
          <EstTime>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <circle
                cx="7"
                cy="7"
                r="6.25"
                stroke="currentColor"
                strokeWidth="1.5"
              />
              <path
                d="M7 3.5V7L9.5 9.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
            <span>{`${props.estimatedTime} min`}</span>
          </EstTime>
        )}
      </Footer>
    </Link>
  );
};

export default UniLearningPathCard;
