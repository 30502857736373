import { styled } from "@linaria/react";
import PageSection from "./PageSection";
import { colors } from "../../styles/colors.styles";
import { ButtonGroupCenteredUptoDesktop } from "../forms/Button";
import Spacing from "../layout/Spacing";
import BookADemoButton from "../utilities/BookADemoButton";
import SignUpButton from "../utilities/SignUpButton";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { rSize } from "../../styles/responsiveSizes.styles";
import { Paths } from "../../utils/pathBuilders.utils";
import { serif } from "../../styles/fonts.styles";
import TinesLogoIcon from "../misc/TinesLogoIcon";
import backdropSpeed1 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-speed-1.svg";
import backdropSpeed2 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-speed-2.svg";
import backdropSpeed3 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-speed-3.svg";
import backdropSpeed4 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-speed-4.svg";
import backdropLightSpeed1 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-light-speed-1.svg";
import backdropLightSpeed2 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-light-speed-2.svg";
import backdropLightSpeed3 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-light-speed-3.svg";
import backdropLightSpeed4 from "../../../static/images/explosion-cta/explosion-cta-backdrop-layer-light-speed-4.svg";
import topLeftBlock from "../../../static/images/explosion-cta/top-left-block.svg";
import coffeeMug from "../../../static/images/explosion-cta/coffee-mug.svg";
import pentagon from "../../../static/images/explosion-cta/bottom-right-pentagon.svg";
import topLeftBlockLight from "../../../static/images/explosion-cta/top-left-block-light.svg";
import coffeeMugLight from "../../../static/images/explosion-cta/coffee-mug-light.svg";
import pentagonLight from "../../../static/images/explosion-cta/bottom-right-pentagon-light.svg";
import { css, cx } from "linaria";
import {
  breakpoints,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { ReactNode, useRef, useState } from "react";
import gsap from "gsap";
import { useStateWithRef } from "../../utils/stateWithRef.hook";

const width = 1770;
const height = 900;

const ExplosionBackdropWrap = styled.div`
  position: absolute;
  top: calc(50% - ${rSize("sectionPadding", 0.5, true)});
  left: 50%;
  right: 0;
  width: ${width}px;
  height: ${height}px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  &.ready {
    opacity: 1;
  }
  ${onlyPhones} {
    transform: translate(-50%, -50%) scale(0.7);
  }
  ${fromTablet} {
    transform: translate(-50%, -50%);
  }
`;

const ExplosionBackdropLayerGroup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateZ(0);
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: 50% 45%;
  }
`;

const ExplosionBackdropLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${width}px ${height}px;
`;

const ExplosionSectionInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20rem 0;
  ${onlyPhones} {
    padding: 8rem 0 10rem;
  }
`;

const ExplosionCTACardPositioner = styled.div`
  position: relative;
  width: ${getWidthPxInMaxGrid(4)};
`;

const ExplosionCTACard = styled.div`
  background-color: ${colors.lightest};
  color: ${colors.purple800};
  border: 1px solid ${colors.purple400};
  .againstDarkBackground & {
    background-color: ${colors.purple50};
    border-color: ${colors.purple700};
  }
  border-radius: 1.2rem;
  text-align: center;
  user-select: none;
`;

const ExplosionCTACardBody = styled.div`
  padding: 2.5em 2em 3em;
  ${fromTablet} {
    padding: 3.5em 2em 3em;
    .hasBodyText & {
      padding: 3em 1.75em;
    }
  }
  svg {
    ${onlyPhones} {
      height: 30px;
    }
  }
  h3 {
    font-size: 2.8rem;
    letter-spacing: -0.01em;
    font-family: ${serif};
    font-weight: 400;
  }
`;

const ExplosionCTACardBodyText = styled.p`
  margin-top: 0.75em;
  font-size: 1.4rem;
`;

const ExplosionCTACardFooter = styled.footer`
  padding: 2em;
  ${fromTablet} {
    padding: 3em;
    .hasBodyText & {
      padding: 2.5em;
    }
  }
  font-size: 1.4rem;
  background-color: ${colors.purple50};
  .againstDarkBackground & {
    background-color: ${colors.purple100};
  }
  border-radius: 0 0 1.2rem 1.2rem;
  a {
    font-weight: 600;
  }
`;

const ExplosionCardDecoTopLeftBlockPositioner = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(-75%, -47%);
  ${onlyPhones} {
    transform: scale(0.7) translate(-50%, -47%);
  }
  width: 53px;
  height: 60px;
  > div {
    ${onlyPhones} {
      transform: none !important;
    }
  }
`;
const ExplosionCardDecoTopLeftBlock = styled.div`
  background-image: url(${topLeftBlockLight});
  .againstDarkBackground & {
    background-image: url(${topLeftBlock});
  }
  width: 53px;
  height: 60px;
  background-repeat: no-repeat;
`;
const ExplosionCardDecoCoffeeMugPositioner = styled.div`
  position: absolute;
  bottom: 4.5em;
  right: 100%;
  transform: translate(15%, 0);
  ${onlyPhones} {
    transform: scale(0.7) translate(85%, 5%);
  }
  width: 81px;
  height: 75px;
  > div {
    ${onlyPhones} {
      transform: none !important;
    }
  }
`;
const ExplosionCardDecoCoffeeMug = styled.div`
  background-image: url(${coffeeMugLight});
  .againstDarkBackground & {
    background-image: url(${coffeeMug});
  }
  width: 81px;
  height: 75px;
  background-repeat: no-repeat;
`;
const ExplosionCardDecoBottomRightPentagonPositioner = styled.div`
  position: absolute;
  top: 100%;
  right: 0%;
  transform: translate(25%, -55%);
  ${onlyPhones} {
    transform: scale(0.7) translate(25%, -65%);
  }
  width: 51px;
  height: 51px;
  > div {
    ${onlyPhones} {
      transform: none !important;
    }
  }
`;
const ExplosionCardDecoBottomRightPentagon = styled.div`
  background-image: url(${pentagonLight});
  .againstDarkBackground & {
    background-image: url(${pentagon});
  }
  width: 51px;
  height: 51px;
  background-repeat: no-repeat;
`;

const ExplosionCTASectionOverflowClip = styled.div`
  overflow: hidden;
  margin-top: -2em;
  padding-top: 2em;
`;

export const ExplosionCTASection = (props: {
  backgroundColor?: string;
  againstDarkBackground?: boolean;
  heading?: ReactNode;
  bodyText?: ReactNode;
}) => {
  const [visibleRef, setVisible] = useStateWithRef(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const tweens = [] as GSAPTween[];
    const selectors = [
      ".explosiveCTADeco1",
      ".explosiveCTADeco2",
      ".explosiveCTADeco3",
    ];
    selectors.forEach((deco, i) => {
      tweens.push(
        gsap.fromTo(
          deco,
          {
            y: i * -24,
          },
          {
            y: i + 12,
            scrollTrigger: {
              trigger: ref.current,
              scrub: 1,
              start: "top 110%",
              end: "bottom -50%",
              invalidateOnRefresh: true,
            },
          }
        )
      );
    });
    const handlePointerMove = (e: PointerEvent) => {
      if (!ref.current) return;
      if (ref.current.clientWidth < breakpoints.tablet) return;
      if (!visibleRef.current) return;
      const viewOffsetX = (e.clientX - window.innerWidth * 0.5) * 0.2;
      const viewOffsetY = (e.clientY - window.innerHeight * 0.45) * 0.3;
      selectors.forEach((selector, i) => {
        if (!selector) return;
        gsap.to(document.querySelector<HTMLDivElement>(`${selector} div`), {
          x: viewOffsetX * (0.02 * (i + 5)),
          y: viewOffsetY * (0.02 * (i + 5)),
          duration: 2,
          ease: "power2.ease",
        });
      });
    };
    window.addEventListener("pointermove", handlePointerMove);
    const observer = new IntersectionObserver(entries => {
      setVisible(entries[0].isIntersecting);
    });
    if (ref.current) observer.observe(ref.current);
    return () => {
      observer.disconnect();
      window.removeEventListener("pointermove", handlePointerMove);
      tweens.forEach(t => {
        t.kill();
      });
    };
  });
  return (
    <ExplosionCTASectionOverflowClip>
      <PageSection
        backgroundColor={props.backgroundColor}
        relative
        before={
          <ExplosiveBackdrop
            againstDarkBackground={props.againstDarkBackground}
          />
        }
        className={cx(
          css`
            padding-bottom: ${rSize("sectionPadding")};
            .simpleFooter & {
              padding-bottom: 0;
            }
          `,
          props.againstDarkBackground && "againstDarkBackground",
          props.bodyText && "hasBodyText"
        )}
        innerRef={ref}
      >
        <ExplosionSectionInner>
          <ExplosionCTACardPositioner>
            <ExplosionCTACard>
              <ExplosionCTACardBody>
                <TinesLogoIcon size={42} />
                <Spacing size="1.5em" />
                <h3>
                  {props.heading ?? (
                    <>
                      Built by you,
                      <br />
                      powered by Tines
                    </>
                  )}
                </h3>
                {props.bodyText && (
                  <ExplosionCTACardBodyText>
                    {props.bodyText}
                  </ExplosionCTACardBodyText>
                )}
                <Spacing size="2em" />
                <ButtonGroupCenteredUptoDesktop>
                  <BookADemoButton appearance="filled" darker width="8em" />
                  <SignUpButton appearance="outlined" darker width="8em" />
                </ButtonGroupCenteredUptoDesktop>
              </ExplosionCTACardBody>
              <ExplosionCTACardFooter>
                <p>
                  Already have an account? <a href={Paths.login()}>Log in</a>.
                </p>
              </ExplosionCTACardFooter>
              <ExplosionCardDecoTopLeftBlockPositioner>
                <div className="explosiveCTADeco1">
                  <ExplosionCardDecoTopLeftBlock />
                </div>
              </ExplosionCardDecoTopLeftBlockPositioner>
              <ExplosionCardDecoCoffeeMugPositioner>
                <div className="explosiveCTADeco2">
                  <ExplosionCardDecoCoffeeMug />
                </div>
              </ExplosionCardDecoCoffeeMugPositioner>
              <ExplosionCardDecoBottomRightPentagonPositioner>
                <div className="explosiveCTADeco3">
                  <ExplosionCardDecoBottomRightPentagon />
                </div>
              </ExplosionCardDecoBottomRightPentagonPositioner>
            </ExplosionCTACard>
          </ExplosionCTACardPositioner>
        </ExplosionSectionInner>
      </PageSection>
    </ExplosionCTASectionOverflowClip>
  );
};

const ExplosiveBackdrop = (props: { againstDarkBackground?: boolean }) => {
  const [ready, setReady] = useState(false);
  const [visibleRef, setVisible] = useStateWithRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const layerGroupRef = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    const tweens = [] as GSAPTween[];
    const layers =
      layerGroupRef.current?.childNodes ?? ([] as HTMLDivElement[]);
    layers.forEach((layer, i) => {
      tweens.push(
        gsap.fromTo(
          layer,
          {
            scale: 1 - 0.1 * (i + 3),
          },
          {
            scale: 1,
            ease: "expo.out",
            scrollTrigger: {
              trigger: layerGroupRef.current,
              scrub: 1,
              start: "top bottom",
              end: "bottom top",
              invalidateOnRefresh: true,
            },
          }
        )
      );
    });

    const handlePointerMove = (e: PointerEvent) => {
      if (!ref.current) return;
      if (ref.current.clientWidth < breakpoints.tablet) return;
      if (!visibleRef.current) return;
      const viewOffsetX = (e.clientX - window.innerWidth * 0.5) * -0.15;
      const viewOffsetY = (e.clientY - window.innerHeight * 0.45) * -0.25;
      layers.forEach((layer, i) => {
        if (!layer) return;
        gsap.to(
          (layer as HTMLDivElement).querySelector<HTMLDivElement>("div"),
          {
            x: viewOffsetX * (0.1 * (i - 2.5)),
            y: viewOffsetY * (0.1 * (i - 2.5)),
            duration: 2,
            ease: "power3",
          }
        );
      });
    };
    window.addEventListener("pointermove", handlePointerMove);
    const intersectionObserver = new IntersectionObserver(entries => {
      setVisible(entries[0].isIntersecting);
    });
    if (ref.current) intersectionObserver.observe(ref.current);

    const handleDocumentReflow = () => {
      tweens.forEach(t => {
        if (t.scrollTrigger && "refresh" in t.scrollTrigger)
          ((
            t.scrollTrigger as {
              refresh: () => void;
            }
          )?.refresh)();
      });
    };

    const windowResizeObserver = new ResizeObserver(() => {
      handleDocumentReflow();
    });
    windowResizeObserver.observe(document.body);

    setReady(true);
    return () => {
      window.removeEventListener("pointermove", handlePointerMove);
      intersectionObserver.disconnect();
      windowResizeObserver.disconnect();
      tweens.forEach(t => {
        t.kill();
      });
    };
  });
  return (
    <ExplosionBackdropWrap ref={ref} className={cx(ready && "ready")}>
      <ExplosionBackdropLayerGroup ref={layerGroupRef}>
        <div>
          <ExplosionBackdropLayer
            style={{
              backgroundImage: `url(${
                props.againstDarkBackground
                  ? backdropSpeed1
                  : backdropLightSpeed1
              })`,
            }}
          />
        </div>
        <div>
          <ExplosionBackdropLayer
            style={{
              backgroundImage: `url(${
                props.againstDarkBackground
                  ? backdropSpeed2
                  : backdropLightSpeed2
              })`,
            }}
          />
        </div>
        <div>
          <ExplosionBackdropLayer
            style={{
              backgroundImage: `url(${
                props.againstDarkBackground
                  ? backdropSpeed3
                  : backdropLightSpeed3
              })`,
            }}
          />
        </div>
        <div style={{ zIndex: 4 }}>
          <ExplosionBackdropLayer
            style={{
              backgroundImage: `url(${
                props.againstDarkBackground
                  ? backdropSpeed4
                  : backdropLightSpeed4
              })`,
            }}
          />
        </div>
      </ExplosionBackdropLayerGroup>
    </ExplosionBackdropWrap>
  );
};
