import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  onlyPhones,
  uptoDesktop,
  uptoTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { font } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import Spacing from "../../layout/Spacing";
import PageSection from "../../reusableSections/PageSection";
import { SectionHeading2 } from "../../typography/SectionHeading2";
import { Serif } from "../../typography/Serif";
import spreadSheetExampleSvg from "./spreadsheetExample.svg";
import tinesActionExamplesSvg from "./tinesActionExamples.svg";
import tinesFormulasEditorSvg from "./tinesFormulasEditor.svg";

const FormulasPageHowItWorksSectionCard = styled.div`
  position: relative;
  border-radius: ${rSize("radius")};
  background-color: ${colorsV4.warmBlack900};
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  padding: ${rSize("cardSectionPadding")} ${rSize("cardSectionPadding")} 3em
    ${rSize("cardSectionPadding")};
  ${fromDesktopMd} {
    padding-bottom: 4em;
  }
  ${onlyPhones} {
    text-align: center;
  }
  ${uptoDesktop} {
    z-index: 1;
    background-image: linear-gradient(
      to bottom,
      ${colorsV4.warmBlack900} 80%,
      ${withOpacity(colorsV4.warmBlack900, 0)}
    );
  }
  p {
    ${fromTablet} {
      max-width: 75%;
    }
    ${fromDesktop} {
      max-width: 50%;
      padding-right: ${rSize("gap")};
    }
    ${fromDesktopMd} {
      font-size: 110%;
      padding-right: ${rSize("gap", 2)};
      font-family: ${font("serif")};
    }
  }
`;

const ContentGrid = styled.div`
  position: relative;
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: minmax(0, 50%) calc(50% + 27px);
  }
  width: 100%;
  section {
    header {
      ${fromDesktopMd} {
        max-width: 20em;
      }
    }
    h3 {
      font-size: 2.2rem;
      ${fromDesktop} {
        font-size: 2.8rem;
      }
      margin-bottom: 0.5em;
    }
    figure {
      margin: 0;
    }
    img {
      display: block;
      max-width: 100%;
    }
    p {
      font-size: 1.4rem;
      ${fromTabletLg} {
        font-size: 1.6rem;
      }
    }
  }
`;

const SpreadsheetSection = styled.section`
  overflow: hidden;
  align-self: stretch;
  ${onlyPhones} {
    text-align: center;
  }
  header {
    padding: 0 1em 0 ${rSize("cardSectionPadding")};
  }
  figure {
    position: relative;
    ${onlyPhones} {
      height: 175px;
      overflow: hidden;
      &:after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3em;
        background-image: linear-gradient(
          to top,
          ${colorsV4.warmBlack900},
          ${withOpacity(colorsV4.warmBlack900, 0)}
        );
      }
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;
const TinesSection = styled.section`
  display: grid;
  grid-template-columns: minmax(33%, auto) minmax(0, auto);
  grid-template-areas: "actionsExample header" "formulasEditor formulasEditor";
  text-align: right;
  grid-gap: 1em;
  header {
    margin-left: auto;
  }
  ${uptoTablet} {
    padding-left: 1em;
  }
  ${fromTablet} {
    grid-template-columns: minmax(15rem, auto) minmax(0, auto);
  }
  ${fromDesktop} {
    grid-template-columns: minmax(20.8rem, auto) minmax(0, auto);
  }
  header {
    grid-area: header;
    padding-right: 1em;
    ${onlyPhones} {
      align-self: center;
    }
    ${fromTablet} {
      padding-right: ${rSize("cardSectionPadding")};
    }
  }
`;
const TinesActionExamplesImageWrap = styled.figure`
  grid-area: actionsExample;
  display: flex;
  align-items: flex-end;
  position: relative;
  ${onlyPhones} {
    overflow: hidden;
    aspect-ratio: 5/4;
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1em;
      background-image: linear-gradient(
        to bottom,
        ${colorsV4.warmBlack900},
        ${withOpacity(colorsV4.warmBlack900, 0)}
      );
    }
  }
  img {
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 100%;
  }
`;
const TinesFormulasEditorImageWrap = styled.figure`
  grid-area: formulasEditor;
  img {
    width: 100%;
  }
`;

const FormulasPageHowItWorksSection = () => {
  return (
    <PageSection>
      <FormulasPageHowItWorksSectionCard>
        <Header>
          <SectionHeading2>
            How <Serif>it works</Serif>
          </SectionHeading2>
          <Spacing size="1em" />
          <p>
            Just like a cell in a spreadsheet can be computed with a formula, so
            can a value in a Tines action.
          </p>
        </Header>
        <ContentGrid>
          <SpreadsheetSection>
            <header>
              <h3>Spreadsheet</h3>
              <p>Build values by referencing other cells</p>
            </header>
            <Spacing size="2em" />
            <figure>
              <img src={spreadSheetExampleSvg} />
            </figure>
          </SpreadsheetSection>
          <TinesSection>
            <header>
              <h3>Tines</h3>
              <p>
                Build values by referencing other actions and object-like
                resources
              </p>
            </header>
            <TinesActionExamplesImageWrap>
              <img src={tinesActionExamplesSvg} />
            </TinesActionExamplesImageWrap>
            <TinesFormulasEditorImageWrap>
              <img src={tinesFormulasEditorSvg} />
            </TinesFormulasEditorImageWrap>
          </TinesSection>
        </ContentGrid>
      </FormulasPageHowItWorksSectionCard>
    </PageSection>
  );
};

export default FormulasPageHowItWorksSection;
