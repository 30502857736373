import SEO from "../components/utilities/SEO";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponentProps } from "../types/gatsby.types";
import ogImage from "../../static/images/og/tines-enterprise.png";
import { graphql } from "gatsby";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { EnterprisePageQueryQuery } from "../../graphql-types";
import Spacing from "../components/layout/Spacing";
import HeroSection, {
  HeroSectionDescription,
} from "../components/sections/HeroSection";
import AllCaps from "../components/typography/AllCaps";
import BookADemoButton from "../components/utilities/BookADemoButton";
import { styled } from "@linaria/react";
import { NoLetterSpacing } from "../components/typography/NoLetterSpacing";
import { FeaturedClientLogoMarquee } from "../components/general/FeaturedClientLogoMarquee";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import enterpriseHeroIllustrationMobile from "../components/enterprise/enterpriseHeroIllustrationMobile.svg";
import enterpriseHeroIllustrationLeft from "../components/enterprise/enterpriseHeroIllustrationLeft.svg";
import enterpriseHeroIllustrationRight from "../components/enterprise/enterpriseHeroIllustrationRight.svg";
import { EnterprisePageBeforeAfterSection } from "../components/enterprise/EnterprisePageBeforeAfterSection";
import EnterprisePageFeatureHighlightsGridSection from "../components/enterprise/EnterprisePageFeatureHighlightsGridSection";
import EnterpriseRelatedContentSection from "../components/enterprise/EnterpriseRelatedContentSection";
import PageSection from "../components/reusableSections/PageSection";
import G2BadgeList from "../components/general/G2BadgeList";
import PartnersSection from "../components/reusableSections/PartnersSection";
import { EnterprisePageSocialProofSection } from "../components/enterprise/EnterprisePageSocialProofSection";
import EnterpriseUseCasesSection from "../components/enterprise/EnterpriseUseCasesSection";
import LibrarySuggestionsSection from "../components/reusableSections/LibrarySuggestionsSection";
import { EnterpriseSupportOptionsSection } from "../components/enterprise/EnterpriseSupportOptionsSection";
import { rSize } from "../styles/responsiveSizes.styles";
import {
  fromDesktopLg,
  fromDesktopMd,
  fromPhoneLg,
  fromTablet,
  onlyPhones,
  uptoPhoneLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";

const HeaderWrap = styled.div`
  position: relative;
  text-align: center;
  ${fromTablet} {
    text-align: left;
  }
`;

const HeaderTextContents = styled.div`
  position: relative;
  ${fromTablet} {
    display: inline-block;
    h1,
    p {
      margin-left: 0;
    }
    h1 {
      max-width: 9em;
    }
    p {
      max-width: 19em !important;
    }
  }
  ${fromDesktopLg} {
    p {
      max-width: 26em !important;
    }
  }
`;

const IllustrationMobileWrap = styled.div`
  margin-top: ${rSize("lg")};
  overflow: hidden;
  ${fromPhoneLg} {
    margin-left: 0;
    margin-right: 0;
  }
  ${fromTablet} {
    display: none;
  }
`;

const IllustrationMobile = styled.div`
  background-image: url(${enterpriseHeroIllustrationMobile});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 483 / 266;
  width: 120%;
  margin-left: -10%;
  height: auto;
  ${fromPhoneLg} {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const IllustrationLeft = styled.div`
  background-image: url(${enterpriseHeroIllustrationLeft});
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 244 / 358;
  position: absolute;
  right: 100%;
  bottom: 0;
  width: ${(244 / 1512) * 100}vw;
  height: auto;
  max-width: 244px;
  ${onlyPhones} {
    display: none;
  }
  ${fromTablet} {
    transform: translate(-1.5em, -2em);
  }
  ${fromDesktopMd} {
    transform: translate(-3em, -2em);
  }
`;

const IllustrationRight = styled.div`
  background-image: url(${enterpriseHeroIllustrationRight});
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 861 / 518;
  position: absolute;
  left: 100%;
  bottom: 0;
  height: auto;
  max-width: 861px;
  ${onlyPhones} {
    display: none;
  }
  ${fromTablet} {
    width: 75vw;
    transform: translate(-9em, 0);
  }
  ${fromDesktopLg} {
    width: ${(861 / 1512) * 100}vw;
    transform: translate(-4em, 0);
  }
`;

const description =
  "Leading organizations trust Tines to transform security and IT operations. From enhancing SOAR workflows at Mars to optimizing incident response at Jamf, Tines is built to scale with your team.";

const EnterprisePage = (
  props: PageComponentProps<{
    data: EnterprisePageQueryQuery;
  }>
) => {
  return (
    <Page {...props} textColor={colors.purple800} navThemeColor="purple">
      <SEO
        title="Enterprise"
        seoTitle={props.data.enterprise?.seoTitle}
        description={props.data.enterprise?.seoDescription || description}
        image={ogImage}
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="enterprisePage"
        /* cspell:disable */
        recordId="NsImWP5aQy6byw8indYjtg"
        /* cspell:enable */
        label="Edit in CMS"
      />

      <HeroSection contentRelative clipOverflow>
        <HeaderWrap>
          <HeaderTextContents>
            <AllCaps as="p">Enterprise</AllCaps>
            <Spacing size="md" />
            <h1
              className={css`
                ${uptoPhoneLg} {
                  font-size: 3.8rem !important;
                }
              `}
            >
              Orchestrate enterprise work<NoLetterSpacing>fl</NoLetterSpacing>
              ows at scale
            </h1>
            <HeroSectionDescription wider>{description}</HeroSectionDescription>
            <Spacing size="md" />
            <BookADemoButton darker />
            <IllustrationLeft />
            <IllustrationRight />
            <Spacing size="sectionPadding" />
          </HeaderTextContents>
        </HeaderWrap>
      </HeroSection>

      <Spacing size="gap" />

      <FeaturedClientLogoMarquee
        heading="Trusted by global leaders across industries"
        againstBackground="light"
        logoSet="enterprise"
      />

      <IllustrationMobileWrap>
        <IllustrationMobile />
      </IllustrationMobileWrap>

      <Spacing size="sectionPadding" />

      <EnterprisePageBeforeAfterSection />
      <Spacing size="cardSectionPadding" />
      <Spacing size="sectionMargin" />

      <EnterprisePageFeatureHighlightsGridSection />
      <Spacing size="sectionMargin" />

      <EnterprisePageSocialProofSection />
      <Spacing size="sectionMargin" />

      <EnterpriseUseCasesSection />
      <Spacing size="gap" />
      <LibrarySuggestionsSection />
      <Spacing size="sectionMargin" />

      <PartnersSection
        logoSet="enterprise"
        description="Our industry-leading partner network includes 200+ innovative companies — from global consultants to regional experts — to support your business needs today and well into the future."
      />
      <Spacing size="sectionMargin" />

      <EnterpriseSupportOptionsSection />
      <Spacing size="sectionPadding" />

      <PageSection>
        <G2BadgeList set="enterprise" />
      </PageSection>
      <Spacing size="sectionMargin" />

      <EnterpriseRelatedContentSection />
      <Spacing size="sectionMargin" />

      <ExplosionCTASection />
    </Page>
  );
};

export const enterprisePageQuery = graphql`
  query EnterprisePageQuery {
    enterprise: datoCmsEnterprisePage {
      seoTitle
      seoDescription
    }
  }
`;

export default EnterprisePage;
