import { styled } from "@linaria/react";
import { cx } from "linaria";
import { first } from "lodash-es";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  fromDesktopMd,
  fromTabletLg,
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";
import { ArrayElementType } from "../../../types/helper.types";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { resolveAfter } from "../../../utils/promises.utils";
import gsap from "gsap";
import { homeFindYourFlowSectionTeams } from "./teamsAndUseCasesConfig";

const UseCaseKaleidoscopeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;
  user-select: none;
  font-family: ${serif};
  font-weight: 400;
  font-size: 1.8rem;
  ${uptoTabletMd} {
    margin-left: -14px;
    margin-right: -14px;
  }
  ${fromTabletMd} {
    font-size: 2.2rem;
  }
  ${fromTabletLg} {
    font-size: 2.4rem;
  }
  ${fromDesktopMd} {
    font-size: 2.8rem;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const UseCaseKaleidoscope = (props: {
  active: boolean;
  team: ArrayElementType<typeof homeFindYourFlowSectionTeams>;
}) => {
  const [useCaseRows, setUseCaseRows] = useState<string[][]>([]);
  const [ready, setReady] = useState(false);
  useOnMount(() => {
    const rows: string[][] = [[], [], [], [], [], []];
    const fillRows = () => {
      props.team.useCases.forEach(useCase => {
        first([...rows].sort((a, b) => a.length - b.length))?.push(useCase);
      });
    };
    fillRows();
    while (rows.some(r => r.length < 5)) {
      fillRows();
    }
    setUseCaseRows(rows);
    setReady(true);
  });
  return (
    <UseCaseKaleidoscopeContainer
      className={cx("UseCaseKaleidoscope", props.active && ready && "active")}
    >
      {(props.team.name === "All teams" || ready) && (
        <UseCaseKaleidoscopeRows rows={useCaseRows} active={props.active} />
      )}
    </UseCaseKaleidoscopeContainer>
  );
};

const UseCaseKaleidoscopeRowsSet = styled.div`
  position: relative;
  overflow: hidden;
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const UseCaseKaleidoscopeRows = (props: {
  active: boolean;
  rows: string[][];
}) => {
  return (
    <UseCaseKaleidoscopeRowsSet>
      {props.rows.map((row, i) => (
        <UseCaseRow key={i} useCases={row} index={i} active={props.active} />
      ))}
    </UseCaseKaleidoscopeRowsSet>
  );
};

const UseCaseRowWrap = styled.div`
  display: flex;
  justify-content: start;
  letter-spacing: -0.01em;
  ${uptoTabletMd} {
    &:nth-child(1) > * {
      opacity: 0.6;
    }
    &:nth-child(2) > * {
      opacity: 0.6;
    }
    &:nth-child(3) > * {
      opacity: 0.7;
    }
    &:nth-child(4) > * {
      opacity: 0.5;
    }
    &:nth-child(5) > * {
      opacity: 0.3;
    }
    &:nth-child(6) > * {
      opacity: 0.1;
    }
  }
`;

const UseCaseRowInner = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
`;

const UseCaseEntryWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  white-space: nowrap;
  align-items: center;
  margin: 0 0.5em;
  opacity: 0;
`;

const UseCaseRow = (props: {
  active: boolean;
  useCases: string[];
  index: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const activeRef = useRef(props.active);
  activeRef.current = props.active;
  const timelineRef = useRef<GSAPTimeline>(
    null
  ) as unknown as MutableRefObject<GSAPTimeline>;
  useOnMount(() => {
    const tl = gsap.timeline({
      repeat: -1,
      paused: true,
    });
    timelineRef.current = tl;
    tl.fromTo(
      ref.current,
      {
        x: 0,
      },
      {
        x: "-50%",
        duration: 90 + props.index * (props.index % 2 === 1 ? 1 : -1),
        ease: "linear",
      }
    );
    return () => {
      tl.kill();
    };
  });
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (timelineRef.current) {
      if (props.active) {
        timelineRef.current.play();
      } else {
        gsap.to(timelineRef.current, {
          duration: 1,
          onComplete: () => {
            if (!activeRef.current) timelineRef.current.pause();
          },
        });
      }
    }
  });
  const renderEntries = () =>
    props.useCases.map((w, i) => (
      <UseCaseEntry key={i} index={i} active={props.active}>
        {w}
      </UseCaseEntry>
    ));
  return (
    <UseCaseRowWrap>
      <UseCaseRowInner ref={ref}>
        {renderEntries()}
        {renderEntries()}
      </UseCaseRowInner>
    </UseCaseRowWrap>
  );
};

const UseCaseEntry = (props: {
  active: boolean;
  children: string;
  index: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const activeRef = useRef(props.active);
  activeRef.current = props.active;
  const timelineRef = useRef<GSAPTimeline>(
    null
  ) as unknown as MutableRefObject<GSAPTimeline>;

  const playAfterRandomWait = async () => {
    await resolveAfter(Math.random() * 6 * 1000);
    if (!activeRef.current) return;
    if (timelineRef.current.isActive()) return;
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!timelineRef.current) await resolveAfter();
    timelineRef.current.timeScale(1);
    timelineRef.current.seek(0, true);
    timelineRef.current.play();
  };

  useOnMount(() => {
    gsap.to(ref.current, { opacity: 0.2 });
    const breatheTl = gsap.timeline({
      paused: true,
      onComplete: () => {
        playAfterRandomWait();
      },
    });
    timelineRef.current = breatheTl;
    breatheTl.fromTo(
      ref.current,
      {
        opacity: 0.2,
      },
      { opacity: 0.9, duration: 2 }
    );
    breatheTl.to(ref.current, { opacity: 0.2, duration: 3 });
    return () => {
      activeRef.current = false;
      timelineRef.current.kill();
    };
  });

  useEffect(() => {
    if (props.active) {
      setTimeout(() => {
        playAfterRandomWait();
      }, props.index * Math.random() * 1000);
    }
  });
  return (
    <UseCaseEntryWrap ref={ref}>
      <div>{props.children}</div>
    </UseCaseEntryWrap>
  );
};
