import { Link } from "gatsby";
import {
  getPostCodeFieldLabel,
  getRegionFieldLabel,
  isPostCodeRequiredCountry,
  isRegionRequiredCountry,
} from "../../utils/country.utils";
import { openIntercomMessenger } from "../../utils/intercom.utils";
import { Paths } from "../../utils/pathBuilders.utils";
import CountrySelector from "../basic/CountrySelector";
import PseudoLink from "../basic/PseudoLink";
import gsap from "gsap";
import RegionSelector from "../basic/RegionSelector";
import Button from "../forms/Button";
import TextInput from "../forms/TextInput";
import Textarea from "../forms/Textarea";
import InfoBox from "../general/InfoBox";
import Spacing from "../layout/Spacing";
import { UseStateReturnType } from "../../types/helper.types";
import { DemoInfoFormObject } from "./makeDemoInfoForm";
import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";
import { withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { useCallback, useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { isDevelopment } from "../../environment";
import { reportDemoRequestSubmission } from "../../utils/demoRequests.utils";
import { setLocalStorageItem } from "../../utils/localStorage.utils";
import { when } from "../../utils/promises.utils";
import { testDemoNoAvailability } from "./DemoForm";
import Dialog from "../basic/Dialog";
import { isPublicEmailProvider } from "../../utils/email.utils";
import {
  breakpoints,
  fromDesktop,
  fromDesktopMl,
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { cx } from "linaria";
import { clearAnimatedProps } from "../../utils/animations.utils";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { isMobile, isSafari } from "../../utils/browser.utils";
import { CustomEase } from "gsap/CustomEase";
import {
  EmailCheckResult,
  emailIsLegitimate,
} from "../../utils/checkEmailLegitimacy.utils";
import LoadingIndicator from "../utilities/LoadingIndicator";
import { debounce } from "../../utils/debounce.utils";
import { flashNotice } from "../../utils/notice.utils";
import { isInE2ETestMode } from "../../utils/test.utils";

type Props = {
  formState: UseStateReturnType<DemoInfoFormObject>;
  whenRequestToChiliPiperIsSent: () => void;
  onSubmittedToHQ: () => void;
  autoFocus?: boolean;
  mobileButtonText?: string;
  directlyShowFormOnMobile?: boolean;
};

const Form = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  min-height: 5rem;
  overflow: hidden;
  perspective: 100px;
  ${uptoTabletLg} {
    transition: background-color 0.6s;
    &.shouldCollapseFormOnMobile {
      background-color: ${colors.purple};
    }
    &.shouldShowFormOnMobile {
      background-color: ${colors.lightest};
    }
  }
  input {
    &.firstInput {
      border-top-left-radius: ${rSize("radius")};
      border-top-right-radius: ${rSize("radius")};
    }
  }
  input,
  select {
    height: 4.2rem;
    border-width: 0 0 1px 0;
    border-color: ${colors.purple200};
    &:hover,
    &:active,
    &:focus {
      border-width: 0 0 1px 0;
    }
  }
  input,
  textarea,
  select {
    background-color: transparent;
    border-radius: 0;
    font-size: 1.6rem;
    padding-left: 1.5em;
    ${uptoTabletLg} {
      &:focus {
        outline: 0;
        background-color: ${withOpacity(colors.purple100, 0.75)};
        border-color: ${colors.purple400};
      }
    }
  }
  input,
  textarea {
    padding-right: 1.5em;
  }
  textarea {
    background-color: transparent;
    color: inherit;
    border: 0;
  }
`;

const InputSet = styled.div`
  &.hasTwoItems {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    > * {
      + * {
        border-left: 1px solid ${colors.purple200};
        &:hover,
        &:focus,
        &:active {
          border-left: 1px solid ${colors.purple200};
        }
      }
    }
  }
`;

const FormFieldSet = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  position: relative;
`;

const MobileToggleWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 5rem;
  overflow: hidden;
  ${fromTabletLg} {
    display: none;
  }
`;
const MobileToggle = styled.button`
  appearance: none;
  height: 5rem;
  padding: 0 1.5em;
  text-align: center;
  border: 0;
  font-size: inherit;
  width: 100%;
  background-color: ${colors.purple};
  color: ${colors.white};
  font-weight: 600;
`;

const FormContentWrap = styled.div`
  ${uptoTabletLg} {
    transform-origin: top;
    height: 0;
    opacity: 0;
    transition: opacity 0.2s;
    overflow: hidden;
    .shouldShowFormOnMobile & {
      opacity: 1;
      height: auto;
    }
  }
`;

const FormContent = styled.form`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  height: 45rem;
  scroll-margin-top: calc(var(--SiteNavHeight) + ${rSize("gap")});
  ${fromTabletLg} {
    height: 50rem;
  }
`;

const Inputs = styled.div``;

const Footer = styled.footer`
  padding: 1.5em;
  font-size: 1.4rem;
`;

const FooterButtonFlex = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 1em;
  align-items: center;
  p {
    font-size: 1.2rem;
    font-weight: 400;
    color: ${withOpacity(colors.purple800, 0.7)};
    text-wrap: balance; // supported in Chrome but not in safari
    ${fromDesktop} {
      letter-spacing: -0.01em;
      margin-right: -1.5em;
    }
    ${fromDesktopMl} {
      letter-spacing: 0;
      margin-right: 0;
    }
    a {
      font-weight: 500;
      &:hover {
        color: ${colors.purple800};
      }
    }
  }
`;

const EmailFieldWrap = styled.div`
  position: relative;
`;

const LoadingIndicatorPositioner = styled.div`
  position: absolute;
  pointer-events: none;
  top: calc(50% - 10px);
  right: 0.66em;
`;

const DemoInfoForm = (props: Props) => {
  const { formState } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPersonalEmail, setIsPersonalEmail] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [emailValidationComplete, setEmailValidationComplete] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [error, setError] = useState(false);

  const [form] = formState;
  const formRef = useRef(form);
  formRef.current = form;

  const setField = (field: keyof DemoInfoFormObject, value: string) => {
    formState[1]({
      ...formRef.current,
      [field]: value,
      url: window.location.href,
    });
  };

  const { country } = form;

  const formIsComplete = () =>
    Object.entries(formRef.current).every(([fieldName, value]) => {
      switch (fieldName) {
        case "firstName":
        case "lastName":
        case "email":
        case "phone":
        case "company":
        case "country":
          return !!value.trim();
        case "state":
          return isRegionRequiredCountry(formRef.current.country)
            ? !!value.trim()
            : true;
        case "postcode":
          return isPostCodeRequiredCountry(formRef.current.country)
            ? !!value.trim()
            : true;
        default:
          return true;
      }
    });

  const resetEmailCheckState = () => {
    setEmailValidationComplete(false);
    setIsPersonalEmail(false);
    setEmailIsInvalid(false);
  };

  const handleEmailFieldKeyUp = () => {
    resetEmailCheckState();
    checkEmailDebounced();
  };

  const dismissPersonalEmailDialog = () => {
    resetEmailCheckState();
    setField("email", "");
    document
      .querySelector<HTMLInputElement>('#book-a-demo-form [name="email"]')
      ?.focus();
  };

  const checkEmail = async () => {
    let report: EmailCheckResult = {
      valid: false,
      isPublicProvider: false,
    };
    setIsCheckingEmail(true);
    const email = formRef.current.email;
    if (!email) return report;

    if (isPublicEmailProvider(email)) {
      report.isPublicProvider = true;
      setEmailIsInvalid(true);
      setIsPersonalEmail(true);
    } else if (!email.includes("@")) {
      setEmailIsInvalid(true);
    } else {
      report = await emailIsLegitimate(email);
      if (formRef.current.email !== email) return report;
      if (!report.valid || report.isPublicProvider) {
        setEmailIsInvalid(true);
        if (report.isPublicProvider) {
          setIsPersonalEmail(true);
        }
      } else {
        setEmailIsInvalid(false);
      }
    }

    setEmailValidationComplete(true);
    setIsCheckingEmail(false);
    return report;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkEmailDebounced = useCallback(debounce(checkEmail), []);

  const resetState = () => {
    setTimeout(() => {
      setField("state", "");
    });
  };

  const submit = async () => {
    if (!formIsComplete()) {
      flashNotice(
        "Please complete all fields before submitting.",
        5000,
        "error-incomplete-demo-form"
      );
      return;
    }

    if (!emailValidationComplete || isCheckingEmail || emailIsInvalid) {
      setIsSubmitting(true);
      const { valid, isPublicProvider } = await checkEmail();
      if (!valid || isPublicProvider) {
        setIsSubmitting(false);
        return;
      }
    }

    try {
      setIsSubmitting(true);
      scrollToHash({
        useHash: "#form-frame",
        doNotPushState: true,
      });
      setLocalStorageItem("lastDemoRequestFormBody", form);
      if (isInE2ETestMode()) {
        // no need to proceed if in test mode as we don't test the connection with chilipiper.
        return;
      }
      when(() => !!window.ChiliPiper);
      reportDemoRequestSubmission().then(() => {
        props.onSubmittedToHQ();
      });
      if (!testDemoNoAvailability) {
        window.ChiliPiper.submit("tines", "inbound-router", {
          formId: "book-a-demo-form",
          title: "Thanks! What time works best for a quick call?",
          debug: isDevelopment,
          domElement: "#chili-piper-calendar",
          onSuccess: () => {},
          onError: () => {
            setError(true);
          },
        });
      }
      props.whenRequestToChiliPiperIsSent();
    } catch (e) {
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [shouldShowFormOnMobile, setShouldShowFormOnMobile] = useState(false);
  const mobileToggleWrapRef = useRef<HTMLDivElement>(null);
  const formContentWrapRef = useRef<HTMLDivElement>(null);
  const formContentRef = useRef<HTMLFormElement>(null);
  const emailFieldRef = useRef<HTMLInputElement>(null);

  const toggleMobileFormContent = (options: { autoFocus: boolean }) => {
    const shouldFocusOnClick = isSafari() && isMobile();
    setShouldShowFormOnMobile(true);
    if (options.autoFocus) {
      scrollToHash({
        useHash: "#form-frame",
        doNotPushState: true,
        offsetY: -96,
      });
    }
    gsap.fromTo(
      mobileToggleWrapRef.current,
      {
        opacity: 1,
        height: 50,
      },
      { opacity: 0, height: 0, duration: 0.3, ease: "power2.inOut" }
    );
    if (options.autoFocus) {
      if (shouldFocusOnClick) {
        emailFieldRef.current?.focus();
      }
    }
    gsap.fromTo(
      formContentWrapRef.current,
      {
        height: 50,
        rotateX: -15,
        y: 70,
        opacity: 0,
      },
      {
        height: 500,
        rotateX: 0,
        y: 0,
        delay: 0.1,
        opacity: 1,
        duration: 1.25,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        ease: CustomEase.create(
          "custom",
          "M0,0,C0.176,0,0.094,0.38,0.218,0.782,0.272,0.958,0.374,1,1,1"
        ),
        onComplete: () => {
          clearAnimatedProps(formContentWrapRef.current, [
            "opacity",
            "height",
            "transform",
          ]);
          if (!shouldFocusOnClick) {
            emailFieldRef.current?.focus();
          }
        },
      }
    );
  };

  useOnMount(() => {
    if (window.innerWidth <= breakpoints.tabletLg) {
      if (props.directlyShowFormOnMobile)
        toggleMobileFormContent({ autoFocus: false });
    }
    if (props.autoFocus && window.innerWidth >= breakpoints.tabletLg) {
      setTimeout(() => {
        emailFieldRef.current?.focus();
      });
    }
    const emailInput = document.querySelector<HTMLInputElement>(
      'input[name="email"]'
    );

    const timeout = setTimeout(() => {
      if (emailInput?.matches(":autofill")) {
        formState[1]({ ...formRef.current, email: emailInput.value });
        checkEmailDebounced();
      }
    }, 500);

    return () => {
      clearAnimatedProps(mobileToggleWrapRef.current, ["opacity", "height"]);
      clearAnimatedProps(formContentRef.current, ["opacity", "height"]);
      setEmailIsInvalid(false);
      clearTimeout(timeout);
    };
  });

  return (
    <Form
      className={cx(
        shouldShowFormOnMobile
          ? "shouldShowFormOnMobile"
          : "shouldCollapseFormOnMobile"
      )}
    >
      <MobileToggleWrap ref={mobileToggleWrapRef}>
        <MobileToggle
          type="button"
          onClick={() => {
            toggleMobileFormContent({ autoFocus: true });
          }}
        >
          {props.mobileButtonText || "Get started"}
        </MobileToggle>
      </MobileToggleWrap>
      <FormContentWrap ref={formContentWrapRef}>
        <FormContent id="book-a-demo-form" ref={formContentRef}>
          <FormFieldSet>
            <Inputs>
              <EmailFieldWrap>
                <TextInput
                  className="firstInput"
                  formState={formState}
                  name="email"
                  placeholder="Business email *"
                  type="email"
                  required
                  style={{
                    color: emailIsInvalid ? colors.orange600 : undefined,
                  }}
                  onChangeCapture={handleEmailFieldKeyUp}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onBlur={checkEmailDebounced}
                  innerRef={emailFieldRef}
                />
                {isCheckingEmail && (
                  <LoadingIndicatorPositioner>
                    <LoadingIndicator delayInSeconds={0} size={14} />
                  </LoadingIndicatorPositioner>
                )}
              </EmailFieldWrap>

              <InputSet className="hasTwoItems">
                <TextInput
                  formState={formState}
                  name="firstName"
                  placeholder="First name *"
                  required
                />
                <TextInput
                  formState={formState}
                  name="lastName"
                  placeholder="Last name *"
                  required
                />
              </InputSet>

              <TextInput
                formState={formState}
                name="phone"
                placeholder="Phone *"
                type="tel"
                required
              />

              <TextInput
                formState={formState}
                name="company"
                placeholder="Company Name *"
                required
              />
              <CountrySelector
                formState={formState}
                name="country"
                placeholder="Country *"
                onValueHadChanged={resetState}
                required
              />
              <InputSet
                className={cx(
                  isRegionRequiredCountry(country) &&
                    isPostCodeRequiredCountry(country) &&
                    "hasTwoItems"
                )}
              >
                {isRegionRequiredCountry(country) && (
                  <RegionSelector
                    formState={formState}
                    name="state"
                    placeholder={`${getRegionFieldLabel(country)} *`}
                    country={country}
                    required
                  />
                )}
                {isPostCodeRequiredCountry(country) && (
                  <TextInput
                    formState={formState}
                    name="postcode"
                    placeholder={`${getPostCodeFieldLabel(country)}${
                      isPostCodeRequiredCountry(country) ? " *" : ""
                    }`}
                  />
                )}
              </InputSet>
            </Inputs>
            <Textarea
              formState={formState}
              name="message"
              placeholder="Tell us more about how we can help you…"
            />
          </FormFieldSet>

          <Footer>
            {isPersonalEmail && (
              <Dialog color="orange" dataTest="error-personal-email">
                <p>
                  Our demo booking form is only available to users with a
                  business email. If you do not have one, email us at{" "}
                  <a href="mailto:hello@tines.io?subject=Requesting%20a%20demo">
                    hello@tines.io
                  </a>{" "}
                  and we will reply with 48 hours.
                </p>
                <Spacing />
                <Button
                  dataTest="dismiss-personal-email-error"
                  onClick={dismissPersonalEmailDialog}
                >
                  OK
                </Button>
              </Dialog>
            )}
            {!isSubmitting && (
              <>
                {emailIsInvalid && (
                  <>
                    <InfoBox dataTest="error-invalid-email">
                      <p>Please use a valid business email to continue.</p>
                    </InfoBox>
                    <Spacing size="1em" />
                  </>
                )}
              </>
            )}
            {error && (
              <>
                <InfoBox>
                  <p>
                    An error occurred, please try again. If this keeps
                    happening, you can also submit a request{" "}
                    <PseudoLink onClick={openIntercomMessenger}>
                      by sending us a message
                    </PseudoLink>
                    .
                  </p>
                </InfoBox>
                <Spacing size="1em" />
              </>
            )}

            <FooterButtonFlex>
              <Button
                dataTest="submit-button"
                loading={isSubmitting}
                onClick={submit}
                padding="1em 1.25em"
              >
                Book a demo
              </Button>
              <p>
                By filling out this form you agree to our{" "}
                <Link to={Paths.privacy()}>privacy notice</Link>.
              </p>
            </FooterButtonFlex>
          </Footer>
        </FormContent>
      </FormContentWrap>
    </Form>
  );
};

export default DemoInfoForm;
