import { styled } from "@linaria/react";
import { cx } from "linaria";
import { RefObject, useRef, useState } from "react";
import { GRID_MAX_WIDTH } from "../../constants/globalGrid.constants";
import { breakpoints } from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { atLeast, snapToNearestMultiplesOfValue } from "../../utils/math.utils";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import { useViewportSize } from "../../utils/window.utils";

type Props = {
  dotSize?: number;
  desiredCellSize?: number;
  cover?: boolean;
  coverInset?: string | number;
  width?: string | number;
  height?: string | number;
  color?: string | BrandColorNameV4;
  onFittedCellSizeRecalc?: (fittedCellSize: number) => void;
  onNumberOfColumnOfDotsChange?: (count: number) => void;
  heightCalculationRef?: RefObject<HTMLElement | null>;
  snapColumnNumberTo?: number;
};

export const DEFAULT_DOT_SIZE = 4;
export const getDefaultDotSizeFromVw = (vw: number) =>
  vw > breakpoints.tabletMd ? 4 : 3;
export const DEFAULT_CELL_SIZE = (GRID_MAX_WIDTH / 47) * 2;

type FlexibleDotGridContainerProps = {
  coverInset?: number | string;
  dotSize?: number;
  cellSize: number;
  width?: number | string;
  height?: number | string;
};
const FlexibleDotGridContainer = styled.div<FlexibleDotGridContainerProps>`
  &.cover {
    position: absolute;
    top: ${p => valueWithOptionalUnit(p.coverInset, 0)};
    right: ${p => valueWithOptionalUnit(p.coverInset, 0)};
    bottom: ${p => valueWithOptionalUnit(p.coverInset, 0)};
    left: ${p => valueWithOptionalUnit(p.coverInset, 0)};
  }
  background-image: ${p => {
    const dotSize = p.dotSize ?? DEFAULT_DOT_SIZE;
    const cellSize = p.cellSize ?? DEFAULT_CELL_SIZE;
    const colorValue = p.color
      ? colorV4(p.color)
      : withOpacity(colorsV4.warmBlack, 0.5);
    return `url("data:image/svg+xml,${encodeURIComponent(
      `<svg width="${cellSize}" height="${cellSize}" viewBox="0 0 ${cellSize} ${cellSize}" xmlns="http://www.w3.org/2000/svg"><circle cx="${
        dotSize / 2
      }" cy="${dotSize / 2}" r="${dotSize / 2}" fill="${colorValue}"/></svg>`
    )}")`;
  }};
  background-repeat: repeat;
  background-size: ${p => `${p.cellSize ?? DEFAULT_CELL_SIZE}px`};
  transition: opacity 0.1s;
  width: ${p => valueWithOptionalUnit(p.width, "auto")};
  height: ${p => valueWithOptionalUnit(p.height, "auto")};
  opacity: 0;
  pointer-events: none;
  &.initialized {
    opacity: 1;
  }
`;

const FlexibleDotGrid = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [height, setHeight] = useState<string | number>("unset");
  const { vw } = useViewportSize(375);
  const defaultDotSize = getDefaultDotSizeFromVw(vw);
  const [dotSize, setDotSize] = useState(props.dotSize ?? defaultDotSize);
  const [fittedCellSizeRef, setFittedCellSize, fittedCellSize] =
    useStateWithRef(props.desiredCellSize ?? DEFAULT_CELL_SIZE);
  useOnMount(() => {
    const disposers: (() => void)[] = [];

    if (ref.current) {
      const applyWidthChange = (width: number) => {
        const dotSize =
          props.dotSize ?? getDefaultDotSizeFromVw(window.innerWidth);
        setDotSize(dotSize);
        const cellSize = props.desiredCellSize ?? DEFAULT_CELL_SIZE;
        const unadjustedColumnCount = width / cellSize;
        const adjustedColumnCount = atLeast(
          snapToNearestMultiplesOfValue(
            unadjustedColumnCount,
            props.snapColumnNumberTo ?? 2,
            "ceil"
          ),
          1
        );
        const fittedCellSizeBasedOnWidth =
          (width - dotSize) / adjustedColumnCount;
        setFittedCellSize(fittedCellSizeBasedOnWidth);
        props.onNumberOfColumnOfDotsChange?.(adjustedColumnCount + 1);
        props.onFittedCellSizeRecalc?.(fittedCellSizeBasedOnWidth);
        setInitialized(true);
      };
      const widthObserver = new ResizeObserver(entries => {
        const { width } = entries[0]?.contentRect;
        applyWidthChange(width);
      });
      widthObserver.observe(ref.current);
      disposers.push(() => widthObserver.disconnect());
    }

    if (props.heightCalculationRef?.current) {
      const applyHeightChange = (height: number) => {
        setHeight(
          snapToNearestMultiplesOfValue(
            height,
            fittedCellSizeRef.current,
            "floor"
          ) + dotSize
        );
      };
      const heightObserver = new ResizeObserver(entries => {
        const entry = entries[0];
        applyHeightChange(entry.target.clientHeight);
      });
      heightObserver.observe(props.heightCalculationRef.current);
      disposers.push(() => heightObserver.disconnect());
    }

    return () => disposers.forEach(fn => fn());
  });
  return (
    <FlexibleDotGridContainer
      className={cx(
        (props.cover || props.coverInset) && "cover",
        initialized && "initialized"
      )}
      ref={ref}
      dotSize={dotSize}
      coverInset={props.coverInset}
      width={props.width}
      height={props.heightCalculationRef ? height : props.height}
      color={props.color}
      cellSize={fittedCellSize}
    />
  );
};

export default FlexibleDotGrid;
