export const CablesLeft = () => (
  <svg width="333" height="54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.00024C67.5 45 261.519 56.4705 332.5 52" stroke="#A990F5" />
    <path d="M1 1.00024C60.4426 43.4914 148.5 59 332.5 45.5" stroke="#A990F5" />
  </svg>
);

export const CableRight = () => (
  <svg
    width="374"
    height="27"
    viewBox="0 0 374 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 13.3319C87.73 30.3491 256 35 373 1" stroke="#C2AAFA" />
  </svg>
);
