export const CompassIcon = () => (
  <svg width="74" height="78" viewBox="0 0 74 78" fill="none">
    <path
      d="M48.3507 77.0825C62.0201 77.0825 73.1014 75.417 73.1014 73.3624C73.1014 71.3079 62.0201 69.6423 48.3507 69.6423C34.6813 69.6423 23.6001 71.3079 23.6001 73.3624C23.6001 75.417 34.6813 77.0825 48.3507 77.0825Z"
      fill="#32313B"
    />
    <path
      d="M41.5424 4.97404C60.5055 6.68081 74.4945 23.4368 72.7877 42.4001C71.0811 61.3632 54.3251 75.352 35.3628 73.6456C16.3997 71.9384 2.41092 55.1822 4.1175 36.2191C5.82409 17.256 22.5805 3.26688 41.5424 4.97404Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M35.3244 75.7393C54.364 75.7393 69.7987 60.3047 69.7987 41.2651C69.7987 22.2254 54.364 6.79077 35.3244 6.79077C16.2848 6.79077 0.850098 22.2254 0.850098 41.2651C0.850098 60.3047 16.2848 75.7393 35.3244 75.7393Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M35.3241 70.9129C51.6982 70.9129 64.972 57.6391 64.972 41.265C64.972 24.891 51.6982 11.6172 35.3241 11.6172C18.9501 11.6172 5.67627 24.891 5.67627 41.265C5.67627 57.6391 18.9501 70.9129 35.3241 70.9129Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M33.766 70.0246C17.458 68.5568 5.42765 54.1466 6.8954 37.8385C7.7721 28.0978 13.2669 19.8847 21.0244 15.1609C12.5638 19.7276 6.48104 28.3144 5.55485 38.607C4.08691 54.9153 16.1174 69.3255 32.4255 70.7935C38.9936 71.3845 45.2528 69.785 50.4831 66.5995C45.5387 69.2687 39.782 70.5659 33.766 70.0246Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M35.3201 18.6627V18.4829L35.3021 18.5731L35.2838 18.4829V18.6627L30.7427 41.2651L35.2838 63.8674V64.0473L35.3019 63.9573L35.3201 64.0473V63.8674L39.8609 41.2651L35.3201 18.6627Z"
      fill="#7266CC"
    />
    <path
      d="M20.9197 26.8636L20.8053 26.7485L20.8529 26.8153L20.7861 26.7679L20.9007 26.8824L32.892 43.6753L49.6851 55.6666L49.7992 55.7816L49.7514 55.7148L49.8186 55.762L49.7037 55.6478L37.7127 38.8544L20.9197 26.8636Z"
      fill="#7266CC"
    />
    <path
      d="M20.901 55.6476L20.7861 55.7622L20.8529 55.7146L20.8053 55.7814L20.9198 55.6664L37.7131 43.6753L49.7037 26.8826L49.8188 26.7679L49.7524 26.8155L49.7992 26.7485L49.6856 26.8631L32.8922 38.8544L20.901 55.6476Z"
      fill="#7266CC"
    />
    <path
      d="M12.6998 41.2473H12.52L12.6098 41.2654L12.52 41.2834H12.6998L35.3024 45.8246L57.9048 41.2834H58.0846L57.9946 41.2656L58.0846 41.2473H57.9048L35.3024 36.7065L12.6998 41.2473Z"
      fill="#7266CC"
    />
    <path
      d="M35.3246 67.8867C50.0272 67.8867 61.946 55.9678 61.946 41.2652C61.946 26.5626 50.0272 14.6438 35.3246 14.6438C20.6219 14.6438 8.70312 26.5626 8.70312 41.2652C8.70312 55.9678 20.6219 67.8867 35.3246 67.8867Z"
      stroke="#7266CC"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M46.6917 15.942L46.7823 15.7406L46.7151 15.8313L46.738 15.7205L46.6478 15.9221L29.7573 38.777L24.4505 65.4868L24.3604 65.6881L24.4275 65.5972L24.4045 65.7084L24.495 65.5066L40.8476 43.753L46.6917 15.942Z"
      fill="#EB81AF"
    />
    <path
      d="M40.8481 43.7527L29.7578 38.7766L24.2402 65.9712L40.8481 43.7527Z"
      fill="#FFD18C"
    />
    <path
      d="M46.6922 15.9421L46.6483 15.9221L29.7578 38.777L24.3877 65.5782L24.432 65.598L40.8481 43.7531L46.6922 15.9421Z"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.8267 35.684C38.9086 35.9618 41.1822 38.6849 40.9048 41.7674C40.6276 44.8498 37.9037 47.1236 34.822 46.8457C31.7396 46.5687 29.4656 43.8449 29.7432 40.7629C30.0202 37.6808 32.7435 35.4068 35.8267 35.684Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M35.3239 44.1913C36.9402 44.1913 38.2504 42.8811 38.2504 41.2649C38.2504 39.6486 36.9402 38.3384 35.3239 38.3384C33.7077 38.3384 32.3975 39.6486 32.3975 41.2649C32.3975 42.8811 33.7077 44.1913 35.3239 44.1913Z"
      fill="#FFE1B3"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M33.6177 58.7566L40.8944 44.4321L32.4688 55.3862L33.6177 58.7566Z"
      fill="#32313B"
    />
    <path
      d="M39.751 2.39508C39.7481 1.57018 37.791 0.907955 35.3794 0.916587C32.9682 0.924645 31.0155 1.59991 31.0186 2.42519C31.0209 3.11024 31.0262 4.61444 31.0285 5.29968C31.0316 6.12458 32.9887 6.44054 35.3999 6.43248C37.8117 6.42365 39.7642 6.09427 39.761 5.26957C39.7586 4.58471 39.7537 3.08032 39.751 2.39508Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M35.3897 3.21343C37.801 3.20524 39.7541 2.68403 39.752 2.04929C39.7498 1.41455 37.7932 0.906644 35.3818 0.914842C32.9704 0.923041 31.0174 1.44424 31.0195 2.07898C31.0217 2.71372 32.9783 3.22163 35.3897 3.21343Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.959186"
      strokeMiterlimit="10"
    />
    <path
      d="M26.2485 7.10303L30.5488 5.68286L32.324 6.78765L27.1953 7.57649L26.2485 7.10303Z"
      fill="#32313B"
    />
    <path
      d="M40.2789 5.19702C40.2789 5.19702 41.6363 6.13913 40.8642 7.02714L38.4292 6.57115L40.2789 5.19702Z"
      fill="#32313B"
    />
  </svg>
);

export const LightbulbOnBookIcon = () => (
  <svg width="88" height="72" viewBox="0 0 88 72" fill="none">
    <path
      d="M86.9822 39.8159H45.3117L17.5977 70.7248H61.3703L86.9822 39.8159Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
    />
    <path
      d="M81.2193 22.6675H33.6306L4.13086 53.961V70.7251H53.7803L81.2193 39.4315C81.2193 39.4315 79.3815 37.4465 79.3815 31.1064C79.3815 24.7664 81.2193 22.6675 81.2193 22.6675Z"
      fill="#FFF5F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.7805 53.961H4.13086L33.6306 22.6675H81.2192L53.7805 53.961Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.2796 43.8525C54.5808 43.8525 61.3102 42.0378 61.3102 39.7993C61.3102 37.5608 54.5808 35.7461 46.2796 35.7461C37.9785 35.7461 31.249 37.5608 31.249 39.7993C31.249 42.0378 37.9785 43.8525 46.2796 43.8525Z"
      fill="#32313B"
    />
    <path
      d="M4.13067 53.9604H53.7802C53.7802 53.9604 51.2623 56.6006 51.2623 62.3425C51.2623 68.0843 53.7802 70.7245 53.7802 70.7245H4.13067C4.13067 70.7245 0.935059 68.4224 0.935059 62.3425C0.935059 56.2625 4.13067 53.9604 4.13067 53.9604Z"
      fill="#FFF5F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16023 56.8536H53.7802V53.9604H6.26992H4.13067L4.13016 53.9608C4.1229 53.966 2.95051 54.8174 2.0279 56.8384C2.02554 56.8436 2.02301 56.8483 2.02065 56.8536H2.02099C1.42534 58.1643 0.935059 59.9627 0.935059 62.3425C0.935059 64.7222 1.42517 66.5206 2.02099 67.8313H2.02065C2.43965 69.034 3.29419 69.9608 3.77703 70.4154C3.9883 70.6199 4.12341 70.7192 4.12932 70.7235L4.13067 70.7245H6.26992H53.7802V67.8313H4.16023C3.56458 66.5206 3.07431 64.7222 3.07431 62.3425C3.07431 59.9627 3.56458 58.1643 4.16023 56.8536Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.2186 25.5606L53.7798 56.8542V53.961L81.2186 22.6675V25.5606Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.2186 39.431L53.7798 70.7245V67.8314L81.2186 36.5378V39.431Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16063 56.8542C4.16063 56.8542 3.07471 59.2806 3.07471 62.3431C3.07471 65.4057 4.16063 67.832 4.16063 67.832H4.35534V56.8542H4.16063Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.7691 57.952H4.14404L4.65069 56.8542H52.2758L51.7691 57.952Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.7355 34.0464C55.7355 31.7994 49.9287 29.9778 42.7655 29.9778C35.6021 29.9778 29.7954 31.7994 29.7954 34.0464C29.7954 34.7604 29.7954 38.4537 29.7954 39.1676C29.7954 41.4146 35.6023 43.2361 42.7655 43.2361C49.9287 43.2361 55.7355 41.4146 55.7355 39.1676C55.7355 38.4537 55.7355 34.7602 55.7355 34.0464Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7655 38.1149C49.9286 38.1149 55.7355 36.2934 55.7355 34.0464C55.7355 31.7993 49.9286 29.9778 42.7655 29.9778C35.6023 29.9778 29.7954 31.7993 29.7954 34.0464C29.7954 36.2934 35.6023 38.1149 42.7655 38.1149Z"
      fill="#F5D0E0"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.071 36.0042C47.8406 36.0042 50.8964 35.015 50.8964 33.7947C50.8964 32.5744 47.8406 31.5852 44.071 31.5852C40.3014 31.5852 37.2456 32.5744 37.2456 33.7947C37.2456 35.015 40.3014 36.0042 44.071 36.0042Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7658 1.16992C36.0494 1.16992 30.605 6.61438 30.605 13.3309C30.605 17.445 32.6488 21.0816 35.7762 23.2818C36.336 23.6756 37.5547 24.4351 37.5547 26.1802C37.5547 26.8583 37.5547 28.7698 37.5547 28.7698V31.1153C37.5547 33.4471 39.445 35.3374 41.7768 35.3374H42.7658H43.7547C46.0865 35.3374 47.9768 33.4471 47.9768 31.1153V28.7698C47.9768 28.7698 47.9768 26.8583 47.9768 26.1802C47.9768 24.4351 49.1955 23.6756 49.7552 23.2818C52.8827 21.0816 54.9265 17.445 54.9265 13.3309C54.9267 6.61438 49.4821 1.16992 42.7658 1.16992Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7653 35.5067C41.2416 35.5067 36.27 35.3119 36.27 32.8045C36.27 32.1982 36.7 31.7068 37.2305 31.7068C37.664 31.7068 38.0305 32.0349 38.1499 32.486C38.5781 32.8047 40.2003 33.3112 42.7653 33.3112C45.3303 33.3112 46.9524 32.8047 47.3808 32.486C47.5002 32.0349 47.8667 31.7068 48.3003 31.7068C48.8307 31.7068 49.2607 32.1982 49.2607 32.8045C49.2605 35.3119 44.2889 35.5067 42.7653 35.5067Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
    />
    <path
      d="M42.7653 33.3446C41.2416 33.3446 36.27 33.1498 36.27 30.6424C36.27 30.0361 36.7 29.5447 37.2305 29.5447C37.664 29.5447 38.0305 29.8728 38.1499 30.3239C38.5781 30.6426 40.2003 31.1491 42.7653 31.1491C45.3303 31.1491 46.9524 30.6426 47.3808 30.3239C47.5002 29.8728 47.8667 29.5447 48.3003 29.5447C48.8307 29.5447 49.2607 30.0361 49.2607 30.6424C49.2605 33.1497 44.2889 33.3446 42.7653 33.3446Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
    />
    <path
      d="M42.7653 31.1063C41.2416 31.1063 36.27 30.9115 36.27 28.4041C36.27 27.7978 36.7 27.3064 37.2305 27.3064C37.664 27.3064 38.0305 27.6345 38.1499 28.0856C38.5781 28.4043 40.2003 28.9108 42.7653 28.9108C45.3303 28.9108 46.9524 28.4043 47.3808 28.0856C47.5002 27.6345 47.8667 27.3064 48.3003 27.3064C48.8307 27.3064 49.2607 27.7978 49.2607 28.4041C49.2605 30.9114 44.2889 31.1063 42.7653 31.1063Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
    />
    <path
      d="M54.8194 39.6998C55.6242 39.6998 56.2766 38.887 56.2766 37.8843C56.2766 36.8817 55.6242 36.0688 54.8194 36.0688C54.0147 36.0688 53.3623 36.8817 53.3623 37.8843C53.3623 38.887 54.0147 39.6998 54.8194 39.6998Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
    />
    <path
      d="M60.8765 54.3946L67.3835 47.3032"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M64.2054 50.4023C63.7423 50.3907 63.7242 50.388 63.6796 49.9153C63.6312 49.4048 64.0056 48.9515 64.5161 48.903C64.6169 48.893 73.0692 48.0165 73.1948 43.063C73.2839 39.5528 68.436 38.6905 67.4778 38.6661C65.8295 38.6243 55.9029 38.8173 55.8027 38.8192C55.2895 38.8337 54.8659 38.4216 54.8561 37.9087C54.8458 37.3958 55.2537 36.9717 55.7666 36.9619C56.177 36.9538 65.8356 36.7662 67.5251 36.8091C69.9317 36.8701 75.1681 38.5417 75.0519 43.1101C74.981 45.9018 73.115 48.1162 69.6559 49.513C67.1975 50.506 64.4192 50.389 64.3182 50.3985C64.2803 50.402 64.2427 50.4032 64.2054 50.4023Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
    />
    <path
      d="M61.7021 53.4946C62.3302 52.8102 62.5999 49.9539 64.1406 48.9116C64.8761 48.4139 67.0846 47.8461 68.2829 46.4302"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.7071 49.4224L64.439 50.6045L62.5249 52.5185L63.7071 49.4224Z"
      fill="#32313B"
    />
    <path
      d="M60.1143 39.5374H65.9149L66.916 38.7266H60.2146L60.1143 39.5374Z"
      fill="#32313B"
    />
    <path
      d="M52.0542 58.0927L53.7994 57.0794L52.2756 56.8542L52.0542 58.0927Z"
      fill="#32313B"
    />
    <path
      d="M42.71 28.8191C45.4771 28.8191 47.7202 28.2142 47.7202 27.468C47.7202 26.7218 45.4771 26.1169 42.71 26.1169C39.9429 26.1169 37.6997 26.7218 37.6997 27.468C37.6997 28.2142 39.9429 28.8191 42.71 28.8191Z"
      stroke="#32313B"
      strokeWidth="0.844416"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
