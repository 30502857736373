import { G2Review } from "../components/general/CustomerLoveReviewCard";

export const g2Reviews: G2Review[] = [
  {
    title: "The Legos of security automation",
    body: "The pre-built modules make integrating with existing security tooling a breeze + the ability to paste a cURL command and have it automatically imported is extremely useful.",
    source: {
      name: "Glen S.",
      title: "Security Engineer",
      avatar: "glen-s.jpg",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-4935069",
    color: "pink",
  },
  {
    title: "Powerful automation, easy to use",
    body: "Tines saves us countless analyst hours a week, allowing our analysts to focus on higher priority tasks and new detection capabilities.",
    source: {
      name: "Gavin F",
      title: "Cyber Security Analyst",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-5141963",
    color: "green",
  },
  {
    title: "The go-to security automation tool",
    body: `"Do one thing exceptionally well", Tines has succeeded on this front, enabling our team to do their best work in minutes, not days.`,
    source: {
      name: "Heath W.",
      title: "Security Manager",
      avatar: "heath-w.jpg",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-5143335",
    color: "purple",
  },
  {
    title: "Rapid automation—for anyone",
    body: "A much smoother user experience than iterating via code, reviewing logs, etc, and allows our team to go from an automation idea to a production story in literally minutes.",
    source: {
      name: "Paul M.",
      title: "Lead Engineer",
      avatar: "paul-m.jpg",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-5170947",
    color: "green",
  },
  {
    title: "Tines’s innovation is YOUR innovation",
    body: `Tines allows my team to create rapid integrations between systems, processes, and people without tedious protocol and API plumbing.`,
    source: {
      name: "Brent L.",
      title: "CISO",
      avatar: "brent-l.jpg",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-5088936",
    color: "purple",
  },
  {
    title: "Promises made, promises kept",
    body: "A simple and clean interface, with a low learning curve, and easy integrations with third-party services.",
    source: {
      // cspell:disable-next-line
      name: "Prasanth K.",
      title: "Software Engineer",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-4921516",
    color: "orange",
  },
  {
    title: "Laser-focused on Automation",
    body: "It is a sophisticated SOAR product and has a high affinity with SIEM, indispensable part of our SOC.",
    source: {
      // cspell:disable-next-line
      name: "Takeshi M.",
      title: "Security Ops Manager",
      // cspell:disable-next-line
      avatar: "takeshi-m.jpg",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-5125499",
    color: "green",
  },
  {
    title: "Tines automated our SOC",
    body: "What do I like best? Tines’s flexibility. You really can create a playbook for anything imaginable, and the tool is very easy to use.",
    source: {
      // cspell:disable-next-line
      name: "Rylan P.",
      title: "Security Ops Manager",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-4940820",
    color: "purple",
  },
  {
    title: "More like coworkers than a vendor",
    body: "Tines’s customer support and service is the best I've ever experienced. There is nothing I dislike about Tines.",
    source: {
      // cspell:disable-next-line
      name: "Adam B.",
      title: "Security Leader",
    },
    rating: 5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-4912300",
    color: "orange",
  },
  {
    title: "Tines saved me so much time and energy!",
    body: "Right off the bat, Tines is a powerful workflow automation tool. Not only that, it's well-documented too.",
    source: {
      // cspell:disable-next-line
      name: "Alexa S.",
      title: "Business Operations Analyst",
    },
    rating: 4.5,
    url: "https://www.g2.com/products/tines/reviews/tines-review-7914833",
    color: "pink",
  },
];
