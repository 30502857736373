import { styled } from "@linaria/react";
import { uptoDesktopMd } from "../../styles/breakpointsAndMediaQueries.styles";

const FromDesktopMd = styled.div`
  ${uptoDesktopMd} {
    display: none;
  }
`;
export const FromDesktopMdSpan = styled.span`
  ${uptoDesktopMd} {
    display: none;
  }
`;

export default FromDesktopMd;
