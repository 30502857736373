export const SparklesTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0676 5.48754L10.5 4.49778L10.9324 5.48754C11.4367 6.64184 12.3582 7.56326 13.5125 8.06757L14.5022 8.49999L13.5125 8.93242C12.3582 9.43673 11.4367 10.3581 10.9324 11.5124L10.5 12.5022L10.0676 11.5124C9.56327 10.3581 8.64185 9.43673 7.48755 8.93242L6.49778 8.49999L7.48755 8.06757C8.64185 7.56326 9.56327 6.64184 10.0676 5.48754ZM10.0418 3.04871C10.2165 2.64895 10.7835 2.64894 10.9582 3.04871L11.8488 5.08719C12.2522 6.01063 12.9894 6.74776 13.9128 7.15121L15.9513 8.04181C16.3511 8.21647 16.3511 8.78352 15.9513 8.95817L13.9128 9.84878C12.9894 10.2522 12.2522 10.9894 11.8488 11.9128L10.9582 13.9513C10.7835 14.351 10.2165 14.351 10.0418 13.9513L9.15122 11.9128C8.74777 10.9894 8.01064 10.2522 7.0872 9.84878L5.04872 8.95817C4.64895 8.78352 4.64895 8.21647 5.04872 8.04181L7.0872 7.15121C8.01064 6.74776 8.74777 6.01063 9.15122 5.08719L10.0418 3.04871Z"
    />
    <path
      opacity="0.2"
      d="M11.5418 5.04871C11.7165 4.64895 12.2835 4.64895 12.4582 5.04871L13.1968 6.73922C13.6002 7.66266 14.3373 8.39979 15.2608 8.80324L16.9513 9.54182C17.351 9.71648 17.351 10.2835 16.9513 10.4582L15.2608 11.1968C14.3373 11.6002 13.6002 12.3373 13.1968 13.2608L12.4582 14.9513C12.2835 15.351 11.7165 15.351 11.5418 14.9513L10.8032 13.2608C10.3998 12.3373 9.66266 11.6002 8.73922 11.1968L7.04871 10.4582C6.64895 10.2835 6.64895 9.71648 7.04871 9.54182L8.73922 8.80324C9.66266 8.39979 10.3998 7.66266 10.8032 6.73922L11.5418 5.04871Z"
    />
    <path
      opacity="0.3"
      d="M3.61507 11.881C3.76181 11.5452 4.23819 11.5452 4.38493 11.881C4.72387 12.6568 5.34315 13.2761 6.11895 13.6151C6.4548 13.7618 6.4548 14.2382 6.11895 14.3849C5.34315 14.7239 4.72387 15.3432 4.38493 16.119C4.23819 16.4548 3.76181 16.4548 3.61507 16.119C3.27613 15.3432 2.65685 14.7239 1.88105 14.3849C1.5452 14.2382 1.5452 13.7618 1.88105 13.6151C2.65685 13.2761 3.27613 12.6568 3.61507 11.881Z"
    />
    <rect
      opacity="0.8"
      x="5.41422"
      y="2"
      width="2"
      height="2"
      transform="rotate(45 5.41422 2)"
    />
  </svg>
);
