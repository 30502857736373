import { styled } from "@linaria/react";
import { MockInputField } from "./MockInputField";
import TextPlaceholder from "./TextPlaceholder";

const Wrap = styled.div`
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  hr {
    display: block;
    appearance: none;
    height: 0.25em;
    border: 0px;
    opacity: 0;
    margin: 0;
  }
`;
const StatusFieldSet = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 2fr) minmax(2em, 1fr);
  grid-gap: 0.3em;
`;

export const AppRightSidebarContents = () => (
  <Wrap>
    <TextPlaceholder opacity={0.5} block>
      Status
    </TextPlaceholder>
    <StatusFieldSet>
      <MockInputField>
        <svg width="10" height="10" viewBox="0 0 10 10">
          <circle cx="5" cy="5" r="5" fill="#B0E0BD" />
          <circle cx="5" cy="5" r="2" fill="#5ABE89" />
        </svg>
      </MockInputField>
      <MockInputField />
    </StatusFieldSet>
    <hr />
    <TextPlaceholder opacity={0.5} block>
      Name
    </TextPlaceholder>
    <MockInputField />
    <hr />
    <TextPlaceholder opacity={0.5} block>
      Description
    </TextPlaceholder>
    <MockInputField />
    <hr />
    <TextPlaceholder opacity={0.5} block>
      Credentials
    </TextPlaceholder>
    <MockCredentialItemList>
      <MockCredentialItem>Microsoft Jira</MockCredentialItem>
      <MockCredentialItem>Notion</MockCredentialItem>
      <MockCredentialItem>Aqua Security</MockCredentialItem>
    </MockCredentialItemList>
  </Wrap>
);

const MockCredentialItemList = styled.div``;

const MockCredentialItemWrap = styled.div`
  opacity: 0.5;
  > * {
    + * {
      margin-left: 0.375em;
    }
  }
`;

const MockCredentialItem = (props: { children: string }) => (
  <MockCredentialItemWrap>
    <svg width="10" height="10" viewBox="0 0 10 10">
      <circle cx="5" cy="5" r="5" fill="#F3ECF7" />
    </svg>
    <TextPlaceholder>{props.children}</TextPlaceholder>
  </MockCredentialItemWrap>
);
