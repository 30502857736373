import { styled } from "@linaria/react";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import PhotoIconEoinHinchy from "../../images/tines-photo-icon-eoin-hinchy.jpg";
import PhotoIconThomasKinsella from "../../images/tines-photo-icon-thomas-kinsella.jpg";
import {
  fromDesktop,
  fromDesktopMl,
  fromPhoneLg,
  fromTabletMd,
  onlyTablets,
  uptoDesktop,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { font, serif } from "../../styles/fonts.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PersonPhotoIcon from "../general/PersonPhotoIcon";
import PillLegacy, { PillTagStrong } from "../basic/PillLegacy";
import StandardArticle from "../articles/StandardArticle";
import AllCaps from "../typography/AllCaps";
import Spacing from "../layout/Spacing";
import { LightningBoltIcon } from "../home/spotIcons/LightningBoldIcon";

const eoinLinkedIn = "https://www.linkedin.com/in/eoinhinchy";
const thomasLinkedIn = "https://www.linkedin.com/in/thomas-kinsella";

const StoryOfTinesSectionContainer = styled.section`
  padding-bottom: 2em;
`;

const Inner = styled.div`
  ${maxPageContentWidthStyleObject};
  ${uptoDesktop} {
    > * {
      + * {
        margin-top: 4em;
      }
    }
  }
  ${fromDesktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${rSize("gap")};
  }
`;

const StoryOfTinesCard = styled.div`
  position: relative;
  padding: 3em 0;
  font-size: 1.6rem;
  ${fromDesktop} {
    font-size: 1.8rem;
    padding-right: ${getWidthPxInMaxGrid(1)};
  }
`;

const Header = styled.header`
  color: ${colors.purple200};
  h2 {
    font-size: 3.2rem;
    ${fromDesktopMl} {
      font-size: 4.4rem;
      letter-spacing: -0.01em;
    }
    font-family: ${serif};
    font-weight: 400;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    span {
      display: block;
    }
  }
  em {
    font-style: normal;
    font-family: ${font("serif")};
    font-weight: 400;
  }
`;

const ArticleWrapper = styled.div`
  position: relative;
  padding-bottom: 4em;
  ${fromTabletMd} {
    padding-bottom: 5em;
  }
`;

const FloatingFounderIcon = styled.a`
  position: relative;
  ${PillTagStrong} {
    position: absolute;
    bottom: -0.5em;
  }
`;

const TKWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  bottom: 0;
  ${uptoTabletMd} {
    left: 3em;
  }
  ${fromTabletMd} {
    right: calc(100% - 2em);
  }
  ${PillTagStrong} {
    left: 60%;
  }
  ${onlyTablets} {
    transform: scale(0.875);
  }
`;

const HinchyWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  top: -1em;
  ${fromTabletMd} {
    top: 2em;
  }
  right: 3em;
  ${PillTagStrong} {
    left: 60%;
  }
  ${onlyTablets} {
    transform: scale(0.875);
  }
`;

const MissionStatementCard = styled.div`
  padding: 2em;
  background-color: ${colors.purple200};
  border: 1px solid ${colors.purple300};
  color: ${colors.purple800};
  border-radius: ${rSize("radius")};
  text-align: left;
  display: grid;
  grid-gap: 1em;
  grid-template-rows: minmax(auto, 1fr) auto;
  ${fromDesktop} {
    padding: 4em;
  }
  p {
    font-size: 2.8rem;
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: -0.03em;
    max-width: 8em;
    ${fromPhoneLg} {
      font-size: 3.6rem;
    }
    ${fromTabletMd} {
      font-size: 4.2rem;
    }
    ${fromDesktopMl} {
      font-size: 4.8rem;
    }
  }
`;

const MissionSection = () => {
  return (
    <StoryOfTinesSectionContainer>
      <Inner>
        <StoryOfTinesCard>
          <Header>
            <AllCaps>How it started</AllCaps>
            <h2>
              <span>Born in security, </span>
              <span>used by everyone.</span>
            </h2>
          </Header>
          <ArticleWrapper>
            <StandardArticle centered>
              <p>
                Before Tines, our founders,{" "}
                <a href={eoinLinkedIn} rel="noreferrer" target="_blank">
                  Eoin
                </a>{" "}
                and{" "}
                <a href={thomasLinkedIn} rel="noreferrer" target="_blank">
                  Thomas
                </a>{" "}
                spent more than a decade as senior security operators,
                experiencing all of the usual pain points felt within and beyond
                security teams: too much work; not enough staff; inevitable
                incidents. They knew there needed to be a laser-focused
                solution, so in 2018 they started building Tines. 
              </p>
            </StandardArticle>
          </ArticleWrapper>
          <TKWrap>
            <FloatingFounderIcon href={thomasLinkedIn}>
              <PersonPhotoIcon
                size="4em"
                colorHex={withOpacity(colors.pink, 0.75)}
                src={PhotoIconThomasKinsella}
              />
              <PillLegacy appearance="filled" color="pink" fontSize="12px">
                Thomas
              </PillLegacy>
            </FloatingFounderIcon>
          </TKWrap>
          <HinchyWrap>
            <FloatingFounderIcon href={eoinLinkedIn}>
              <PersonPhotoIcon
                size="4em"
                colorHex={withOpacity(colors.purple, 0.75)}
                src={PhotoIconEoinHinchy}
              />
              <PillLegacy appearance="filled" fontSize="12px">
                Eoin
              </PillLegacy>
            </FloatingFounderIcon>
          </HinchyWrap>
        </StoryOfTinesCard>

        <MissionStatementCard>
          <div>
            <AllCaps children="Our Mission" />
            <Spacing size="1.5em" />
            <p>To power the world’s most important workflows.</p>
          </div>
          <footer>
            <LightningBoltIcon />
          </footer>
        </MissionStatementCard>
      </Inner>
    </StoryOfTinesSectionContainer>
  );
};

export default MissionSection;
