const PricingIconLittleHouse = () => {
  return (
    <svg viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M89.1439 114H56.506V108.536H93.8886L89.1439 114Z"
        fill="#32313B"
      />
      <path
        d="M52.4846 86.0997C52.4846 87.9038 46.806 89.366 39.8007 89.366C32.7949 89.366 27.1171 87.904 27.1171 86.0997C27.1171 84.2954 32.7947 82.8328 39.8007 82.8328C46.8058 82.8325 52.4846 84.2954 52.4846 86.0997Z"
        fill="#32313B"
      />
      <path
        d="M42.59 82.8444L39.4911 80.9077V57.6576C39.5635 57.5713 33.6007 61.0498 33.6007 61.0498V80.9077L29.8045 83.2464C27.8855 84.4772 26.7321 85.0627 26.7321 85.559C26.7321 86.7788 31.1255 87.7674 36.5459 87.7674C41.9654 87.7674 46.3584 86.7788 46.3584 85.559C46.3581 85.0625 44.3423 83.8308 42.59 82.8444Z"
        fill="#EB81AF"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M44.7792 43.2182C45.1875 42.1988 45.4174 41.0909 45.4174 39.9254C45.4174 35.0252 41.4448 31.0535 36.545 31.0535C31.6457 31.0535 27.6714 35.0252 27.6714 39.9254C27.6714 41.0907 27.9026 42.1988 28.31 43.2182C24.8004 45.7678 22.5147 49.9039 22.5147 54.5749C22.5147 62.3229 28.7965 68.6056 36.545 68.6056C44.294 68.6056 50.5762 62.3229 50.5762 54.5749C50.5762 49.9039 48.2896 45.7681 44.7792 43.2182Z"
        fill="#49B889"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M33.4751 68.2317V70.2059C33.4751 70.2059 34.3395 72.1814 36.3146 72.1814C38.2893 72.1814 39.7707 71.0703 39.7707 71.0703L39.5238 68.2317C39.524 68.2317 34.9566 69.466 33.4751 68.2317Z"
        fill="#32313B"
      />
      <path
        d="M114.913 105.008H66.875V91.229H123.613L114.913 105.008Z"
        fill="#32313B"
      />
      <path
        d="M110.215 61.1468L87.7527 43.97L65.29 61.1468L59.1118 69.7962V102.389H104.037L110.215 93.7394V61.1468Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M59.1118 69.7962V102.389H104.037V69.7962L81.574 52.6189L59.1118 69.7962Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M81.5741 52.6191L87.7528 43.97"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M104.037 69.7963L110.216 61.147"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M84.3411 93.0315H44.1013L41.4767 96.8223V113.443H81.7169L84.3411 109.652V93.0315Z"
        fill="#EB81AF"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M41.4767 96.8225H81.7169V113.443"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M81.7172 96.8223L84.3415 93.0315"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M97.3692 73.7825H85.2918V85.8599H97.3692V73.7825Z"
        fill="#32313B"
      />
      <path
        d="M77.9566 73.7825H65.8792V85.8599H77.9566V73.7825Z"
        fill="#32313B"
      />
    </svg>
  );
};

export default PricingIconLittleHouse;
