import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { runAfter } from "../../utils/promises.utils";

const HashChangeAutoScrollController = () => {
  useOnMount(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      scrollToHash();
    };
    runAfter(() => {
      window.addEventListener("hashchange", handler);
    });
    return () => {
      window.removeEventListener("hashchange", handler);
    };
  });
  return null;
};

export default HashChangeAutoScrollController;
