import { styled } from "@linaria/react";
import { cx } from "linaria";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import {
  brandColorThemeVar,
  getBrandColorThemeVariables,
} from "../../styles/colors.styles";
import { EventDirectoryItem } from "../../types/helper.types";
import EventDirectoryItemLocation from "./EventDirectoryItemLocation";
import PlaceholderImageFrame from "../general/PlaceholderImageFrame";
import StaticImageFrame from "../basic/StaticImageFrame";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import { EventDirectoryItemPrimaryCategory } from "./EventDirectoryItemPrimaryCategory";
import { Pill } from "../basic/Pill";
import EventDirectoryItemDatesAsPills from "./EventDirectoryItemDatesAsPills";

type Props = {
  item: EventDirectoryItem;
  isSelected?: boolean;
  onSelect?: (item: EventDirectoryItem) => void;
};

const EventDirectoryItemEntryWrapper = styled.div`
  font-size: 1.4rem;
  cursor: pointer;
  overflow: hidden;
  overflow: clip;
  background-color: ${brandColorThemeVar(50)};
  border: 1px solid ${brandColorThemeVar(100)};
  color: ${brandColorThemeVar(800)};
  border-radius: ${rSize("radius")};
  transform: translateZ(0);
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  &:hover {
    border-color: ${brandColorThemeVar(300)};
  }
`;

const Inner = styled.div`
  padding: 1.2em 1.5em 1.5em;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-template-areas: "time type" "title title" "location location";
  grid-gap: 0.75em;
  > * {
    + * {
      margin-top: 0.375em;
    }
  }
`;

const EventTitle = styled.h3`
  grid-area: title;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: ${serif};
  ${fromTablet} {
    font-size: 2.2rem;
  }
`;

const EventType = styled.div`
  grid-area: type;
`;

const EventDateTime = styled.p`
  grid-area: time;
`;

const EventLocation = styled.p`
  grid-area: location;
  margin-bottom: -3px;
  font-weight: 600;
`;

const EventDirectoryItemEntryFigure = styled.figure`
  padding: 0;
  margin: 0;
  aspect-ratio: 1200 / 630;
  border-top: 1px solid ${brandColorThemeVar(100)};
`;

const EventDirectoryItemEntryNeue = (props: Props) => {
  const { type, id } = props.item;
  const handleClick = () => {
    props.onSelect?.(props.item);
  };

  return (
    <EventDirectoryItemEntryWrapper
      className={cx(props.isSelected && "selected")}
      id={`${type}-${id}`}
      data-type={type}
      data-id={id}
      onClick={handleClick}
      style={
        props.item.color && props.item.color !== "Purple"
          ? getBrandColorThemeVariables(props.item.color)
          : undefined
      }
    >
      <Inner>
        <EventTitle>{props.item.name}</EventTitle>
        <EventDateTime>
          <EventDirectoryItemDatesAsPills item={props.item} />
        </EventDateTime>
        <EventType>
          <Pill allCaps>
            <EventDirectoryItemPrimaryCategory item={props.item} />
          </Pill>
        </EventType>
        <EventLocation>
          <EventDirectoryItemLocation item={props.item} />
        </EventLocation>
      </Inner>
      <EventDirectoryItemEntryFigure>
        {props.item.coverImageUrl ? (
          <StaticImageFrame
            src={props.item.coverImageUrl}
            aspectRatio="1200/630"
          />
        ) : (
          <PlaceholderImageFrame />
        )}
      </EventDirectoryItemEntryFigure>
    </EventDirectoryItemEntryWrapper>
  );
};

export default EventDirectoryItemEntryNeue;
