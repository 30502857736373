export const CloudRightA = () => (
  <svg width="73" height="21" viewBox="0 0 73 21">
    <path
      d="M21.2625 18.0717C20.0934 17.0621 18.2016 17.0621 17.0325 18.0717C15.2799 19.5852 12.9974 20.5 10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5C12.9974 0.5 15.2799 1.41483 17.0325 2.92832C18.2016 3.93791 20.0934 3.93791 21.2625 2.92832C23.0151 1.41483 25.2975 0.5 27.7949 0.5C30.292 0.5 32.5743 1.41464 34.3268 2.92784C35.4958 3.93715 37.387 3.93715 38.556 2.92784C40.3085 1.41464 42.5908 0.5 45.0879 0.5C47.5853 0.5 49.8677 1.41483 51.6204 2.92832C52.7895 3.93791 54.6812 3.93791 55.8503 2.92832C57.603 1.41483 59.8854 0.5 62.3828 0.5C67.9057 0.5 72.3828 4.97715 72.3828 10.5C72.3828 16.0228 67.9057 20.5 62.3828 20.5C59.8854 20.5 57.603 19.5852 55.8503 18.0717C54.6812 17.0621 52.7895 17.0621 51.6204 18.0717C49.8677 19.5852 47.5853 20.5 45.0879 20.5C42.5908 20.5 40.3085 19.5854 38.556 18.0722C37.387 17.0628 35.4958 17.0628 34.3268 18.0722C32.5743 19.5854 30.292 20.5 27.7949 20.5C25.2975 20.5 23.0151 19.5852 21.2625 18.0717Z"
      fill="#F3ECF7"
      stroke="#A990F5"
    />
  </svg>
);

export const CloudRightB = () => (
  <svg width="39" height="17" viewBox="0 0 39 17">
    <path
      d="M13.0421 15.0865C11.752 15.9779 10.1877 16.5 8.5 16.5C4.08172 16.5 0.5 12.9183 0.5 8.5C0.5 4.08172 4.08172 0.5 8.5 0.5C10.1877 0.5 11.752 1.02207 13.0421 1.9135C13.6162 2.31019 14.3838 2.31018 14.9579 1.9135C16.248 1.02207 17.8123 0.5 19.5 0.5C21.1877 0.5 22.752 1.02207 24.0421 1.9135C24.6162 2.31019 25.3838 2.31019 25.9579 1.9135C27.248 1.02207 28.8123 0.5 30.5 0.5C34.9183 0.5 38.5 4.08172 38.5 8.5C38.5 12.9183 34.9183 16.5 30.5 16.5C28.8123 16.5 27.248 15.9779 25.9579 15.0865C25.3838 14.6898 24.6162 14.6898 24.0421 15.0865C22.752 15.9779 21.1877 16.5 19.5 16.5C17.8123 16.5 16.248 15.9779 14.9579 15.0865C14.3838 14.6898 13.6162 14.6898 13.0421 15.0865Z"
      fill="#F8F4F0"
      stroke="#A990F5"
    />
  </svg>
);

export const CloudRightC = () => (
  <svg width="96" height="48" viewBox="0 0 96 48">
    <path
      d="M17.044 46.5002H17.0206L16.9972 46.5024C16.5272 46.5466 16.0508 46.5692 15.569 46.5692C7.24661 46.5692 0.5 39.8226 0.5 31.5002C0.5 23.1779 7.24661 16.4313 15.569 16.4313C16.1426 16.4313 16.7087 16.4633 17.2653 16.5256C17.8049 16.5861 18.3381 16.3649 18.677 15.9418C21.3351 12.6236 25.4194 10.5002 30 10.5002C30.7243 10.5002 31.4358 10.5533 32.1311 10.6557C32.8377 10.7597 33.5328 10.3789 33.823 9.72442C36.2325 4.28968 41.6743 0.500244 48 0.500244C53.6884 0.500244 58.6617 3.5642 61.3587 8.1342C61.4933 8.36242 61.7382 8.5 62 8.5C64.1424 8.5 66.0985 9.29194 67.5937 10.5997C68.0405 10.9906 68.68 11.1236 69.2523 10.8935C71.4849 9.99568 73.9323 9.5 76.5 9.5C87.0057 9.5 95.5 17.7949 95.5 28C95.5 38.0458 87.2691 46.2403 76.9908 46.494C76.9678 46.4945 76.9451 46.4967 76.9228 46.5002H17.044ZM76.9968 46.5002H76.991C76.9929 46.5002 76.9948 46.5002 76.9968 46.5002Z"
      fill="#F3ECF7"
      stroke="#A990F5"
    />
  </svg>
);

export const CloudRightD = () => (
  <svg width="28" height="17" viewBox="0 0 28 17">
    <path
      d="M14.9579 15.0867C14.3838 14.6901 13.6162 14.6901 13.0421 15.0867C11.752 15.9782 10.1877 16.5002 8.5 16.5002C4.08172 16.5002 0.5 12.9185 0.5 8.50024C0.5 4.08197 4.08172 0.500244 8.5 0.500244C10.1877 0.500244 11.752 1.02231 13.0421 1.91374C13.6162 2.31043 14.3838 2.31043 14.9579 1.91374C16.248 1.02231 17.8123 0.500244 19.5 0.500244C23.9183 0.500244 27.5 4.08197 27.5 8.50024C27.5 12.9185 23.9183 16.5002 19.5 16.5002C17.8123 16.5002 16.248 15.9782 14.9579 15.0867Z"
      fill="#F8F4F0"
      stroke="#A990F5"
    />
  </svg>
);

export const CloudRightE = () => (
  <svg width="95" height="28" viewBox="0 0 95 28">
    <path
      d="M27.614 23.6713C26.1472 22.4048 23.7664 22.4048 22.2996 23.6713C19.9887 25.6666 16.979 26.8728 13.6863 26.8728C6.40371 26.8728 0.5 20.9691 0.5 13.6865C0.5 6.40395 6.40371 0.500244 13.6863 0.500244C16.979 0.500244 19.9887 1.70647 22.2996 3.7018C23.7664 4.96827 26.1472 4.96827 27.614 3.7018C29.9249 1.70647 32.9346 0.500244 36.2273 0.500244C39.5206 0.500244 42.5307 1.70686 44.8418 3.70279C46.3088 4.96979 48.6907 4.96979 50.1578 3.70279C52.4688 1.70686 55.479 0.500244 58.7722 0.500244C62.0649 0.500244 65.0747 1.70646 67.3856 3.70179C68.8523 4.96826 71.2331 4.96826 72.6999 3.70179C75.0108 1.70646 78.0205 0.500244 81.3132 0.500244C88.5958 0.500244 94.4995 6.40395 94.4995 13.6865C94.4995 20.9691 88.5958 26.8728 81.3132 26.8728C78.0205 26.8728 75.0108 25.6666 72.6999 23.6713C71.2331 22.4048 68.8523 22.4048 67.3855 23.6713C65.0747 25.6666 62.0649 26.8728 58.7722 26.8728C55.479 26.8728 52.4688 25.6662 50.1578 23.6703C48.6907 22.4033 46.3088 22.4033 44.8418 23.6703C42.5307 25.6662 39.5206 26.8728 36.2273 26.8728C32.9346 26.8728 29.9249 25.6666 27.614 23.6713Z"
      fill="#F3ECF7"
      stroke="#7F69CE"
    />
  </svg>
);

export const CloudRightF = () => (
  <svg width="43" height="16" viewBox="0 0 43 16">
    <path
      d="M13.3091 13.2978C11.9508 14.6589 10.0741 15.5002 8 15.5002C3.85786 15.5002 0.5 12.1424 0.5 8.00024C0.5 3.85811 3.85786 0.500244 8 0.500244C10.0741 0.500244 11.9508 1.34154 13.3091 2.70272C13.9988 3.3939 15.179 3.3939 15.8687 2.70272C17.2269 1.34154 19.1036 0.500244 21.1777 0.500244C23.2512 0.500244 25.1273 1.34096 26.4854 2.70132C27.1749 3.39198 28.3544 3.39198 29.0439 2.70132L28.6987 2.35668L29.0439 2.70132C30.402 1.34096 32.2781 0.500244 34.3516 0.500244C38.4937 0.500244 41.8516 3.85811 41.8516 8.00024C41.8516 12.1424 38.4937 15.5002 34.3516 15.5002C32.2781 15.5002 30.402 14.6595 29.0439 13.2992C28.3544 12.6085 27.1749 12.6085 26.4854 13.2992C25.1273 14.6595 23.2512 15.5002 21.1777 15.5002C19.1036 15.5002 17.2269 14.6589 15.8687 13.2978C15.179 12.6066 13.9988 12.6066 13.3091 13.2978Z"
      fill="#C2AAFA"
      stroke="#7F69CE"
    />
  </svg>
);
