import { css, cx } from "linaria";
import React, { PropsWithChildren } from "react";
import {
  brandColorThemeVar,
  getBrandColorThemeVariables,
} from "../../styles/colors.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";

type FootnotesProps = PropsWithChildren<{
  className?: string;
  centered?: boolean;
  smaller?: boolean;
  linkColor?: string;
}>;

export const footnotesContainerStyle = css`
  * + &,
  & + * {
    margin-top: 1em;
  }
  &.centered {
    p {
      text-align: center;
    }
  }
  p {
    font-size: 1.4rem;
    a {
      ${inParagraphLinkStyle(brandColorThemeVar(500))}
      font-weight: 700;
    }
    sup {
      color: inherit;
    }
  }
  &.smaller {
    p {
      font-size: 1.2rem;
    }
  }
`;

const Footnotes: React.FC<FootnotesProps> = props => {
  return (
    <footer
      className={cx(
        footnotesContainerStyle,
        props.className,
        props.centered && "centered"
      )}
      style={getBrandColorThemeVariables(props.linkColor)}
    >
      {props.children}
    </footer>
  );
};

export default Footnotes;
