import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { css, cx } from "linaria";
import React, { useRef } from "react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { colors, withOpacity } from "../../styles/colors.styles";
import { AlgoliaTinesHit } from "../../utils/algolia.utils";
import { isRelativePath } from "../../utils/urls.utils";
import BreadCrumbs from "../basic/BreadCrumbs";
import { OpenBookTwoToneIcon } from "../icons/twoTone/OpenBookTwoToneIcon";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { LibraryTwoToneIcon } from "../icons/twoTone/LibraryTwoToneIcon";
import { BookWithLightbulbTwoToneIcon } from "../icons/twoTone/BookWithLightbulbTwoToneIcon";
import { PageTwoToneIcon } from "../icons/twoTone/PageTwoToneIcon";
import { PlugTwoToneIcon } from "../icons/twoTone/PlugTowToneIcon";
import { NewspaperTwoToneIcon } from "../icons/twoTone/NewspaperTwoToneIcon";
import { GiftTwoToneIcon } from "../icons/twoTone/GiftTwoToneIcon";
import { SlackCommunityTwoToneIcon } from "../icons/twoTone/SlackCommunityTwoToneIcon";
import { UniversityTwoToneIcon } from "../icons/twoTone/UniversityTwoToneIcon";
import { ClapperboardTwoToneIcon } from "../icons/twoTone/ClapperboardTwoToneIcon";
import { CaseStudyTwoToneIcon } from "../icons/twoTone/CaseStudyTwoToneIcon";
import { MailTwoToneIcon } from "../icons/twoTone/MailTwoToneIcon";
import { ShieldTwoToneIcon } from "../icons/twoTone/ShieldTwoToneIcon";
import { ComputerTwoToneIcon } from "../icons/twoTone/ComputerTwoToneIcon";
import { LoadedSkateboardTwoToneIcon } from "../icons/twoTone/LoadedSkateboardTwoToneIcon";
import { MagicCrystalBallTwoToneIcon } from "../icons/twoTone/MagicCrystalBallTwoToneIcon";
import { HatchingDinoEggTwoToneIcon } from "../icons/twoTone/HatchingDinoEggTwoToneIcon";
import { ShapesTwoToneIcon } from "../icons/twoTone/ShapesTwoToneIcon";
import { AppsTwoToneIcon } from "../icons/twoTone/AppsTwoToneIcon";
import { CasesTwoToneIcon } from "../icons/twoTone/CasesTwoToneIcon";
import { FederalTwoToneIcon } from "../icons/twoTone/FederalTwoToneIcon";
import { HeadphonesTwoToneIcon } from "../icons/twoTone/HeadphonesTwoToneIcon";
import { BookShelfTwoToneIcon } from "../icons/twoTone/BookShelfTwoToneIcon";
import { JigsawPieceTwoToneIcon } from "../icons/twoTone/JigsawPieceTwoToneIcon";
import { HeartTwoToneIcon } from "../icons/twoTone/HeartTwoToneIcon";
import { stripCustomInlineFormattingSyntax } from "../typography/WithCustomInlineFormatting";
import { ChainedRingsTwoToneIcon } from "../icons/twoTone/ChainedRingsTwoToneIcon";
import { AWSTwoToneIcon } from "../icons/twoTone/AWSTwoToneIcon";
import {
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { CampingTentTwoToneIcon } from "../icons/twoTone/CampingTentTwoToneIcon";
import { PaperScrollTwoToneIcon } from "../icons/twoTone/PaperScrollTwoToneIcon";
import { LifebuoyTwoToneIcon } from "../icons/twoTone/LifebuoyTwoToneIcon";
import { RSSTwoToneIcon } from "../icons/twoTone/RSSTwoToneIcon";
import { CommunityTwoToneIcon } from "../icons/twoTone/CommunityTwoToneIcon";
import { CertificationBadgeTwoToneIcon } from "../icons/twoTone/CertificationBadgeTwoToneIcon";
import { SparklesTwoToneIcon } from "../icons/twoTone/SparklesTwoToneIcon";
import { ProductExplorerTwoToneIcon } from "../icons/twoTone/ProductExplorerTwoToneIcon";
import { WorkbenchTwoToneIcon } from "../icons/twoTone/WorkbenchTwoToneIcon";
import { CityScapeTwoToneIcon } from "../icons/twoTone/CityscapeTwoToneIcon";

type Props = {
  hit: AlgoliaTinesHit;
  onClick?: (hit: AlgoliaTinesHit) => void;
  openInNewTab?: boolean;
  hasHighlight?: boolean;
  isRecentSearchEntry?: boolean;
  onRemoveEntry?: (hit: AlgoliaTinesHit) => void;
};

export const AlgoliaHitEntryWrap = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: stretch;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 1em;
  &:before {
    content: "";
    display: block;
    background-color: ${withOpacity(colors.purple200, 0.2)};
    ${darkModeLinariaCSS(`background-color: ${colors.dark700};`)}
    transition: opacity 0.1s;
    border-radius: 0.6em;
    opacity: 0;
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    pointer-events: none;
  }
  &:hover {
    &:before {
      transition: 0.075s;
      transform: scale(1);
      opacity: 0.4;
    }
  }
  &.hasHighlight {
    &:before {
      transform: scale(1);
      opacity: 0.6;
    }
  }
`;

const algoliaHitLinkStyle = css`
  display: grid;
  position: relative;
  appearance: none;
  color: inherit;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  grid-template-columns: auto minmax(0, 1fr) auto;
  align-items: center;
  grid-gap: 0.375em;
`;

const EntryIconWrap = styled.div`
  padding: 0.75em;
  ${fromTabletMd} {
    padding: 1em;
  }
  border: 1px solid ${colors.purple100};
  border-radius: 0.5em;
  background-color: ${withOpacity(colors.lightest, 0.8)};
  ${darkModeLinariaCSS(`
    background-color: ${colors.dark700};
    border-color: ${colors.dark500};
  `)}
  svg {
    display: block;
  }
`;

const ContentWrap = styled.div`
  padding: 0.375em;
  ${fromTablet} {
    padding: 0.5em 3em 0.5em 0.5em;
  }
`;

const EnterIconWrap = styled.div`
  padding: 1em;
  opacity: 0.375;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  ${onlyPhones} {
    display: none;
  }
`;

const Heading = styled.h4`
  font-size: inherit;
  font-weight: inherit;
`;

const AfterHeading = styled.div`
  margin: 0.125em 0 0 0;
  font-size: 1.2rem;
  ${fromTablet} {
    margin: 0.25em 0 0 0;
    font-size: 1.3rem;
  }
  p {
    margin: 0;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-gap: 0.5em;
    align-items: center;
    font-size: inherit;
    opacity: 0.7;
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    svg {
      display: block;
    }
  }
`;

const DeleteEntryButton = styled.button`
  appearance: none;
  padding: 1em;
  opacity: 0.375;
  border: none;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.01);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  position: relative;
  svg {
    display: block;
  }
  &:hover {
    opacity: 1;
    color: ${colors.purple};
    background-color: ${withOpacity(colors.purple, 0.1)};
  }
`;

const AlgoliaHitEntry = (props: Props) => {
  const { hit } = props;
  const Icon = algoliaHitEntryIconFactory(hit);
  const removeEntry = () => {
    props.onRemoveEntry?.(hit);
  };
  const removeButtonRef = useRef<HTMLButtonElement>(null);
  const handleClick = () => {
    props.onClick?.(hit);
  };
  const linkAttributes = {
    className: algoliaHitLinkStyle,
    onClick: handleClick,
    children: (
      <>
        <EntryIconWrap>
          <Icon />
        </EntryIconWrap>
        <ContentWrap>
          <Heading>{hit.title}</Heading>
          <AfterHeading>
            {!!hit.breadcrumbs?.length ? (
              <BreadCrumbs
                levels={hit.breadcrumbs}
                openInNewTab={props.openInNewTab}
                renderAs="text"
              />
            ) : hit.summary ? (
              <p>
                <span>{stripCustomInlineFormattingSyntax(hit.summary)}</span>
              </p>
            ) : null}
          </AfterHeading>
        </ContentWrap>
        {!props.isRecentSearchEntry && props.hasHighlight && (
          <EnterIconWrap>
            <EnterIcon />
          </EnterIconWrap>
        )}
      </>
    ),
  };
  return (
    <AlgoliaHitEntryWrap className={cx(props.hasHighlight && "hasHighlight")}>
      {!props.openInNewTab && isRelativePath(hit.path) ? (
        <Link to={hit.path} {...linkAttributes} />
      ) : (
        <a href={hit.path} {...linkAttributes} {...externalLinkAttr} />
      )}
      {props.isRecentSearchEntry && (
        <DeleteEntryButton ref={removeButtonRef} onClick={removeEntry}>
          <CloseIcon />
        </DeleteEntryButton>
      )}
    </AlgoliaHitEntryWrap>
  );
};

const algoliaHitEntryIconFactory = (entry: AlgoliaTinesHit) => {
  switch (entry.type) {
    case "docs":
      return BookWithLightbulbTwoToneIcon;
    case "api":
      return PlugTwoToneIcon;
    case "blog":
      return NewspaperTwoToneIcon;
    case "productUpdate":
      return GiftTwoToneIcon;
    case "productExplorer":
      return ProductExplorerTwoToneIcon;
    case "slackCommunity":
      return SlackCommunityTwoToneIcon;
    case "university":
      return UniversityTwoToneIcon;
    case "onDemandWebinars":
    case "onDemandWebinar":
      return ClapperboardTwoToneIcon;
    case "caseStudies":
      return CaseStudyTwoToneIcon;
    case "bootcamp":
      return CampingTentTwoToneIcon;
    case "legal":
      return PaperScrollTwoToneIcon;
    case "support":
      return LifebuoyTwoToneIcon;
    case "partners":
      switch (entry.slug) {
        case "aws":
          return AWSTwoToneIcon;
        default:
          return ChainedRingsTwoToneIcon;
      }
    case "contact":
      return MailTwoToneIcon;
    case "rss":
      return RSSTwoToneIcon;
    case "certification":
      return CertificationBadgeTwoToneIcon;
    case "solutionPage":
      switch (entry.slug) {
        case "security":
          return ShieldTwoToneIcon;
        case "it":
          return ComputerTwoToneIcon;
        case "infrastructure":
          return LoadedSkateboardTwoToneIcon;
        case "engineering":
          return MagicCrystalBallTwoToneIcon;
        case "product":
          return HatchingDinoEggTwoToneIcon;
        default:
          return ShapesTwoToneIcon;
      }
    case "library":
      return LibraryTwoToneIcon;
    case "story":
      return OpenBookTwoToneIcon;
    case "libraryCollection": {
      switch (entry.slug) {
        case "":
          return LibraryTwoToneIcon;
        case "community":
          return CommunityTwoToneIcon;
        case "it":
          return ComputerTwoToneIcon;
        case "infrastructure":
          return LoadedSkateboardTwoToneIcon;
        case "engineering":
          return MagicCrystalBallTwoToneIcon;
        case "product":
          return HatchingDinoEggTwoToneIcon;
        case "security":
          return ShieldTwoToneIcon;
        default:
          return BookShelfTwoToneIcon;
      }
    }
    default:
      switch (entry.path) {
        case "/product/build-apps":
          return AppsTwoToneIcon;
        case "/product/cases":
          return CasesTwoToneIcon;
        case "/product/ai":
          return SparklesTwoToneIcon;
        case "/product/workbench":
          return WorkbenchTwoToneIcon;
        case "/federal":
          return FederalTwoToneIcon;
        case "/enterprise":
          return CityScapeTwoToneIcon;
        case "/podcast":
          return HeadphonesTwoToneIcon;
        case "/pricing":
          return JigsawPieceTwoToneIcon;
        case "/product":
          return HeartTwoToneIcon;
        default:
          return PageTwoToneIcon;
      }
  }
};

const EnterIcon = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="currentColor">
    <path d="M8.5 1V6.1875C8.5 6.46364 8.27614 6.6875 8 6.6875H2.22068L4.2079 4.73133L3.50638 4.01867L0.649241 6.83117C0.553765 6.92516 0.5 7.05353 0.5 7.1875C0.5 7.32147 0.553765 7.44984 0.649241 7.54383L3.50638 10.3563L4.2079 9.64367L2.22068 7.6875H8C8.82843 7.6875 9.5 7.01593 9.5 6.1875V1H8.5Z" />
  </svg>
);

const CloseIcon = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path d="M10 1L1 10M1 1L10 10" stroke="currentColor" />
  </svg>
);

export default AlgoliaHitEntry;
