import { styled } from "@linaria/react";
import { KeyboardEvent } from "react";
import {
  brandColorThemeVar,
  colors,
  withOpacity,
} from "../../styles/colors.styles";
import { UnknownObject, UseStateReturnType } from "../../types/helper.types";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { formInputControls } from "./TextInput";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";

type Props<T extends object> = {
  className?: string;
  name?: (keyof T & string) | string;
  formState?: UseStateReturnType<T>;
  rows?: number;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onKeyUp?: (e: KeyboardEvent) => void;
};

export const TextareaStyled = styled.textarea`
  background-color: ${colors.lightest};
  border: 1px solid ${brandColorThemeVar(200)};
  width: 100%;
  padding: 1em;
  border-radius: 0.5em;
  resize: none;
  color: inherit;
  font-size: 1.6rem;
  ${fromTablet} {
    font-size: 1.4rem;
  }
  ${darkModeLinariaCSS(`
    border-color: ${withOpacity(colors.gray, 0.3)};
    background-color: ${colors.dark900};
  `)};
  &::placeholder {
    color: inherit;
    opacity: 0.6;
  }
  &:hover {
    border-color: var(--ac30, ${withOpacity(colors.purple, 0.5)});
    ${darkModeLinariaCSS(
      `border-color: var(--ac30, ${withOpacity(colors.purple, 0.5)})`
    )};
  }
  &:focus {
    outline: none;
    border-color: var(--ac, ${colors.purple});
    ${darkModeLinariaCSS(`border-color: var(--ac, ${colors.purple})`)};
  }
`;

const Textarea = function <T extends object>(props: Props<T>) {
  const { className, formState } = props;
  if (
    formState?.[0] &&
    typeof formState[0][props.name as keyof typeof formState[0]] === "undefined"
  ) {
    Reflect.set(formState[0] as UnknownObject, props.name as string, "");
  }
  const controlProps =
    formState && props.name && props.name in formState[0]
      ? formInputControls(formState, props.name as keyof T, props)
      : null;
  return (
    <TextareaStyled
      className={className}
      rows={props.rows}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      required={props.required}
      onKeyUp={props.onKeyUp}
      disabled={props.disabled}
      {...controlProps}
    />
  );
};

export default Textarea;
