import { getVisitorId } from "../../../utils/analyticsEvent";
import { getCookie } from "../../../utils/cookies.utils";
import { loadScript } from "../../../utils/script.utils";
import { shouldLogGDPRDebugInfo } from "../gdpr";

// Reference: https://developers.hubspot.com/docs/api/events/tracking-code

declare global {
  interface Window {
    _hsq: unknown[][]
  }
}

const pushEvent = (value: unknown[]) => {
  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(value);
}

export const notifyHubSpotOfPageView = () => {
  pushEvent(['setPath', window.location.pathname]);
  pushEvent(['trackPageView']);
  pushEvent(['refreshPageHandlers']);
}

const identifyVisitor = () => {
  const id = getVisitorId();
  const email = getCookie("email_address");
  pushEvent(["identify", email ? { id, email } : { id }]);
}

export const initHubSpotTracking = async () => {
  if (shouldLogGDPRDebugInfo()) {
    // eslint-disable-next-line no-console
    console.log('– init HubSpot tracking');
  }
  pushEvent(['setPath', window.location.pathname]);
  identifyVisitor();
  await loadScript({ src: '//js-eu1.hs-scripts.com/144410582.js' });
}

export const getHubSpotToken = () => getCookie("hubspotutk") ?? ""