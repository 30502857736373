import { styled } from "@linaria/react";
import { useRef } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import gsap from "gsap";
import { colors } from "../../../styles/colors.styles";

const TrafficLightsSvg = styled.svg`
  position: absolute;
  top: 12px;
  left: 12px;
  overflow: visible;
  path {
    opacity: 0;
  }
`;

const CursorPath = styled.path`
  pointer-events: none;
  transition: opacity 0.2s;
`;

const CloseButtonHighlight = styled.circle`
  opacity: 0;
  transition: fill 0.05s;
  &:hover {
    fill: ${colors.red300};
    ~ ${CursorPath} {
      opacity: 0 !important;
    }
  }
  &:active {
    fill: ${colors.red600};
  }
`;

export const ImpactSectionsHeadingTrafficLights = (props: {
  onClose: () => void;
}) => {
  const containerRef = useRef<SVGSVGElement>(null);
  const cursorRef = useRef<SVGPathElement>(null);
  const closeButtonHoverRedDotRef = useRef<SVGCircleElement>(null);
  const handleClick = () => {
    props.onClose();
    gsap.to(cursorRef.current, { opacity: 0, duration: 0.2 });
  };
  useOnMount(() => {
    const timeline = gsap.timeline({ paused: true });
    timeline.fromTo(
      cursorRef.current,
      {
        opacity: 0,
        scale: 0.9,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.5,
      },
      0
    );
    timeline.fromTo(
      cursorRef.current,
      {
        x: 124,
      },
      {
        x: 0,
        ease: "power3.out",
        duration: 1,
      },
      0
    );
    timeline.fromTo(
      cursorRef.current,
      {
        y: 24,
      },
      {
        y: 0,
        ease: "power1.out",
        duration: 1,
      },
      0
    );
    timeline.fromTo(
      closeButtonHoverRedDotRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.1,
      },
      0.7
    );
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          timeline.play();
        }
      },
      { rootMargin: "-33%" }
    );
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      timeline.kill();
      observer.disconnect();
    };
  });
  return (
    <TrafficLightsSvg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      ref={containerRef}
    >
      <circle cx="7" cy="7" r="6" fill="#D7C4FA" />
      <CloseButtonHighlight
        ref={closeButtonHoverRedDotRef}
        cx="7"
        cy="7"
        r="6"
        fill="#F67269"
        stroke="#4D3E78"
        onClick={handleClick}
      />
      <circle cx="27" cy="7" r="6" fill="#D7C4FA" />
      <circle cx="47" cy="7" r="6" fill="#D7C4FA" />
      <CursorPath
        ref={cursorRef}
        d="M25.0158 17.0677L8.89453 8.83447L12.6043 26.2351L15.0612 20.9219L20.0092 27.3173L24.062 24.3243L19.1143 17.9287L25.0158 17.0677Z"
        fill="white"
        stroke="#4D3E78"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </TrafficLightsSvg>
  );
};
