import { PageComponentProps } from "../types/gatsby.types";
import Page from "../templates/Page.template";
import DemoPageBackdrop from "../components/demo/DemoPageBackdrop";
import SEO from "../components/utilities/SEO";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import PageSection from "../components/reusableSections/PageSection";
import { DemoPageTopSpacer } from "../components/demo/DemoPageTopSpacer";
import { styled } from "@linaria/react";
import { widthInGrid } from "../constants/globalGrid.constants";
import { colors } from "../styles/colors.styles";
import { serif } from "../styles/fonts.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import BookADemoIcon from "../components/illustrations/spots/BookADemoIcon";
import Spacing from "../components/layout/Spacing";
import {
  fromDesktop,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { DemoAltOptionsButtonGroup } from "../components/demo/DemoAltOptionsButtonGroup";

const MainFrame = styled.div`
  position: relative;
  padding: 4em 2.5em 6em;
  ${fromDesktop} {
    padding: ${widthInGrid(1, 1)};
  }
  background-color: ${colors.purple100};
  border: 1px solid ${colors.purple400};
  border-radius: ${rSize("radius")};
  font-weight: 500;
  text-align: center;
  h1 {
    font-family: ${serif};
    font-size: 2.8rem;
    line-height: 1.1;
    font-weight: 400;
    max-width: 10em;
    margin-left: auto;
    margin-right: auto;
    ${fromTablet} {
      font-size: 3.6rem;
      line-height: 1;
      font-weight: 300;
      letter-spacing: -0.015em;
    }
    ${fromTabletLg} {
      font-size: 4.2rem;
    }
  }
  hr {
    display: block;
    border: 0;
    border-top: 1px dashed ${colors.purple400};
    background-color: transparent;
    height: 0;
    margin: ${rSize("xl")} 0;
  }
  p {
    span {
      ${fromPhoneLg} {
        display: block;
      }
    }
  }
`;

const DemoCancelledPage = (props: PageComponentProps) => {
  return (
    <Page
      {...props}
      backgroundColor={colors.purple50}
      textColor={colors.purple800}
      navThemeColor="purple"
    >
      <SEO title="Demo request cancelled" />
      <SiteNavHeightPlaceholder />
      <DemoPageTopSpacer />
      <DemoPageBackdrop />
      <PageSection>
        <MainFrame>
          <BookADemoIcon />
          <Spacing size="md" />
          <h1>Thanks for submitting your request.</h1>
          <Spacing size="md" />
          <p>
            <span>We got it, but noticed you haven't booked a meeting. </span>
            <span>A member of our team will reach out soon.</span>
          </p>

          <hr />

          <h3>Not quite ready to talk to someone?</h3>
          <Spacing size="md" />
          <DemoAltOptionsButtonGroup />
        </MainFrame>
      </PageSection>

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default DemoCancelledPage;
