import { styled } from "@linaria/react";
import { PropsWithChildren, ReactNode, useRef, useState } from "react";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { font } from "../../../styles/fonts.styles";
import gsap from "gsap";
import { renderInPortal } from "../../../utils/portals.utils";
import { getHomeIllustrationScalar } from "./_HomeHeroIllustration";

export const FormulasCapsulePositioner = styled.div`
  position: relative;
  cursor: help;
  user-select: none;
`;

const FormulasCapsuleContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const FormulasCapsuleFace = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${colors.purple200};
  color: ${colors.purple700};
  border: 1px solid ${colors.purple700};
  border-radius: 4px;
  padding: 2px 5px;
  text-align: left;
  font-family: ${font("monospace")};
  font-size: 9px;
  font-weight: 500;
  line-height: 1;
  z-index: 2;
  svg {
    display: block;
  }
  span {
    margin-left: 0.3em;
    transform: translateY(0.25px);
  }
`;

const FormulasCapsuleDepth = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${colors.purple400};
  border: 1px solid ${colors.purple700};
  border-radius: 4px;
  z-index: 1;
`;

export const FormulasCapsuleShadow = styled.div`
  position: absolute;
  top: 7px;
  left: 1px;
  right: 1px;
  height: 100%;
  background-color: ${colors.purple700};
  border-radius: 5px;
  z-index: 0;
`;

const explainerCardWidth = 256;
const explainerCardHeight = 128;

const FormulasExplainerPositioner = styled.div`
  position: absolute;
  width: ${explainerCardWidth}px;
  height: ${explainerCardHeight}px;
  user-select: none;
`;

const FormulasExplainerCardWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  &:before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 4px;
    right: 4px;
    height: ${explainerCardHeight}px;
    border-radius: 8px;
    background-color: ${withOpacity(colors.purple800, 0.4)};
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: ${explainerCardHeight}px;
    border-radius: 8px;
    background-color: ${colors.purple300};
    border: 1px solid ${colors.purple700};
  }
`;

const FormulasExplainerCard = styled.div`
  position: relative;
  background-color: ${colors.purple100};
  color: ${colors.purple700};
  border: 1px solid ${colors.purple700};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 1em;
  z-index: 100;
  font-size: 1.3rem;
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  h3 {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    font-family: ${font("monospace")};
    margin-bottom: 0.75em;
    font-weight: 500;
    svg {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
    }
  }
  p {
    line-height: 1.2;
  }
  code {
    background-color: ${colors.purple50};
    border: 1px solid ${colors.purple200};
    padding: 0.05em 0.3em;
    border-radius: 3px;
    font-size: 1.1rem;
    font-weight: 500;
  }
`;

const FormulasCapsule = (
  props: PropsWithChildren<{ id?: string; details: ReactNode }>
) => {
  const capsuleOuterRef = useRef<HTMLDivElement>(null);
  const capsuleBodyRef = useRef<HTMLDivElement>(null);
  const capsuleShadowRef = useRef<HTMLDivElement>(null);
  const explainerRef = useRef<HTMLDivElement>(null);
  const [showDetails, setShowDetails] = useState(false);
  const [xCenter, setXCenter] = useState(0);
  const [yCenter, setYCenter] = useState(0);

  const handlePointerDown = () => {
    if (!capsuleBodyRef.current) return;

    const boundingBox = capsuleBodyRef.current.getBoundingClientRect();

    const xCenter = boundingBox.x + boundingBox.width / 2;
    const yCenter = boundingBox.y + (boundingBox.height + 4) / 2;
    setXCenter(xCenter);
    setYCenter(yCenter);

    const cardScaleX = boundingBox.width / explainerCardWidth;
    const cardScaleY = (boundingBox.height + 4) / explainerCardHeight;
    const reverseIllustrationScalar = 1 / getHomeIllustrationScalar();

    const xOffset =
      xCenter + explainerCardWidth / 2 > window.innerWidth
        ? (xCenter + explainerCardWidth / 2 - window.innerWidth + 14) * -1
        : boundingBox.x - explainerCardWidth / 2 < 0
        ? (boundingBox.x - explainerCardWidth / 2) * -1 - 14
        : 0;

    setShowDetails(true);
    setTimeout(() => {
      gsap.fromTo(
        capsuleBodyRef.current,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.1,
          onComplete: () => {},
        }
      );
      gsap.to(capsuleBodyRef.current, {
        x: xOffset * reverseIllustrationScalar,
        scaleX: 1 / cardScaleX,
        scaleY: 1 / cardScaleY,
        duration: 0.2,
        ease: "back.out",
      });
      gsap.fromTo(
        explainerRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.01,
        }
      );
      gsap.fromTo(
        explainerRef.current,
        {
          scaleX: cardScaleX,
          scaleY: cardScaleY,
        },
        {
          scaleX: 1,
          scaleY: 1,
          x: xOffset,
          y: yOffset,
          duration: 0.2,
          ease: "back.out",
        }
      );
    });

    let scrollPosition = window.scrollY;
    let yOffset = 0;

    const handleScroll = () => {
      yOffset -= window.scrollY - scrollPosition;
      scrollPosition = window.scrollY;
      gsap.to(explainerRef.current, {
        y: yOffset,
        duration: 0.2,
        ease: "back.out",
      });
    };

    let hasEnded = false;

    const end = () => {
      if (hasEnded) return;
      hasEnded = true;
      window.removeEventListener("touchend", end);
      window.removeEventListener("pointerup", end);
      window.removeEventListener("pointerleave", end);
      window.removeEventListener("blur", end);
      window.removeEventListener("scroll", handleScroll);
      setTimeout(() => {
        if (!capsuleBodyRef.current) return;
        gsap.to(capsuleBodyRef.current, {
          opacity: 1,
          duration: 0.1,
          delay: 0.1,
        });
        gsap.to(capsuleBodyRef.current, {
          scaleX: 1,
          scaleY: 1,
          x: 0,
          duration: 0.2,
          ease: "back.in",
          onComplete: () => {
            gsap.to(capsuleBodyRef.current, {
              y: 2,
              duration: 0.1,
            });
            gsap.to(capsuleBodyRef.current, {
              y: 0,
              duration: 0.1,
              delay: 0.1,
            });
            gsap.to(capsuleShadowRef.current, {
              scale: 0.97,
              duration: 0.1,
            });
            gsap.to(capsuleShadowRef.current, {
              scale: 1,
              duration: 0.1,
              delay: 0.1,
            });
          },
        });
        gsap.to(explainerRef.current, {
          scaleX: cardScaleX,
          scaleY: cardScaleY,
          x: 0,
          duration: 0.2,
          ease: "back.in",
          onComplete: () => {
            setShowDetails(false);
          },
        });
        gsap.to(explainerRef.current, {
          opacity: 0,
          duration: 0.03,
          delay: 0.15,
        });
      });
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchend", end, { once: true });
    window.addEventListener("pointerup", end, { once: true });
    window.addEventListener("pointerleave", end, { once: true });
    window.addEventListener("blur", end, { once: true });
  };
  return (
    <>
      <FormulasCapsulePositioner
        id={props.id}
        ref={capsuleOuterRef}
        onPointerDown={handlePointerDown}
      >
        <FormulasCapsuleContainer ref={capsuleBodyRef}>
          <FormulasCapsuleFace>
            <FIcon /> <span>{props.children}</span>
          </FormulasCapsuleFace>
          <FormulasCapsuleDepth />
        </FormulasCapsuleContainer>
        <FormulasCapsuleShadow ref={capsuleShadowRef} />
      </FormulasCapsulePositioner>

      {showDetails &&
        renderInPortal(
          <FormulasExplainerPositioner
            style={{
              transform: `translate(${xCenter - explainerCardWidth / 2}px, ${
                yCenter - explainerCardHeight / 2
              }px)`,
            }}
          >
            <FormulasExplainerCardWrap ref={explainerRef}>
              <FormulasExplainerCard>
                <h3>
                  <FIcon /> <span>{props.children}</span>
                </h3>
                {props.details}
              </FormulasExplainerCard>
            </FormulasExplainerCardWrap>
          </FormulasExplainerPositioner>
        )}
    </>
  );
};

const FIcon = () => (
  <svg width="8" height="11" viewBox="0 0 8 11" fill="#6956A8">
    <path d="M6.00356 1.62648C5.51229 1.62648 5.10696 1.96461 5.01631 2.42068L5.00727 2.48214L4.74843 4.2429H6.47069V5.24922H4.6005L4.14222 8.36673L4.14192 8.36892C3.99336 9.35059 3.15315 10.0796 2.15867 10.0796H2.14827C1.63173 10.0687 1.12376 9.87187 0.745117 9.4957L1.44309 8.77809C1.62487 8.95863 1.8819 9.0661 2.16382 9.07325C2.66738 9.07083 3.0837 8.70242 3.15753 8.21767L3.59391 5.24922H2.28886V4.2429H3.74183L4.00228 2.47115L4.02058 2.33861C4.15915 1.33552 5.01683 0.620148 6.00356 0.620148C6.52844 0.620148 7.04225 0.820246 7.42236 1.20934L6.71389 1.9164C6.53247 1.73069 6.27921 1.62648 6.00356 1.62648Z" />
  </svg>
);

export default FormulasCapsule;
