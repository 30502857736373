import {
  DatoCmsCenteredHeadingSection,
  DatoCmsCenteredQuoteSection,
  DatoCmsColumnsWithIconsSection,
  DatoCmsContentCardsSection,
  DatoCmsCustomerLogosSection,
  DatoCmsFeatureHighlightsSection,
  DatoCmsFeaturedVideoSection,
  DatoCmsG2BadgesSection,
  DatoCmsImageAndTextRepeaterSection,
  DatoCmsLargeBookADemoFormSection,
  DatoCmsLargeHeadingSection,
  DatoCmsPartnersLogoSetSection,
  DatoCmsPercentageStatCardsSection,
  DatoCmsQuoteWithDataPointSection,
  DatoCmsSocialProofSection,
  DatoCmsTeamHeadshotsSection,
  DatoCmsTextCtaWithButtonsSection,
  DatoCmsUnionForDatoCmsLandingPageLandingPageSections,
} from "../../../graphql-types";
import PageSection from "../reusableSections/PageSection";
import { LPCenteredHeadingSection } from "./sections/LPCenteredHeadingSection";
import { LPCenteredQuoteSection } from "./sections/LPCenteredQuoteSection";
import { LPColumnsWithIconsSection } from "./sections/LPColumnsWithIconsSection";
import { LPContentCardsSection } from "./sections/LPContentCardsSection";
import { LPCustomerLogosSection } from "./sections/LPCustomerLogosSection";
import { LPFeaturedVideoSection } from "./sections/LPFeaturedVideoSection";
import { LPFeatureHighlightsSection } from "./sections/LPFeatureHighlightsSection";
import { LPG2BadgesSection } from "./sections/LPG2BadgesSection";
import { LPImageAndTextRepeaterSection } from "./sections/LPImageAndTextRepeaterSection";
import { LPLargeBookADemoFormSection } from "./sections/LPLargeBookADemoFormSection";
import { LPLargeHeadingSection } from "./sections/LPLargeHeadingSection";
import { LPPartnersLogoSetSection } from "./sections/LPPartnersLogoSetSection";
import { LPPercentageStatCardsSection } from "./sections/LPPercentageStatCardsSection";
import { LPQuoteWithDataPointSection } from "./sections/LPQuoteWithDataPointSection";
import { LPSocialProofSection } from "./sections/LPSocialProofSection";
import { LPTeamHeadshotsSection } from "./sections/LPTeamHeadshotsSection";
import { LPTextCtaWithButtonsSection } from "./sections/LPTextCtaWithButtonsSection";

export const LPSectionDispatcher = ({
  section,
}: {
  section: DatoCmsUnionForDatoCmsLandingPageLandingPageSections & {
    __typename: string;
  };
}) => {
  const { __typename } = section;
  switch (true) {
    case __typename.includes("FeaturedVideoSection"): {
      return (
        <LPFeaturedVideoSection
          section={section as DatoCmsFeaturedVideoSection}
        />
      );
    }
    case __typename.includes("CenteredQuoteSection"): {
      return (
        <LPCenteredQuoteSection
          section={section as DatoCmsCenteredQuoteSection}
        />
      );
    }
    case __typename.includes("CustomerLogosSection"): {
      return (
        <LPCustomerLogosSection
          section={section as DatoCmsCustomerLogosSection}
        />
      );
    }
    case __typename.includes("CenteredHeadingSection"): {
      return (
        <LPCenteredHeadingSection
          section={section as DatoCmsCenteredHeadingSection}
        />
      );
    }
    case __typename.includes("FeatureHighlightsSection"): {
      return (
        <LPFeatureHighlightsSection
          section={section as DatoCmsFeatureHighlightsSection}
        />
      );
    }
    case __typename.includes("QuoteWithDataPointSection"): {
      return (
        <LPQuoteWithDataPointSection
          section={section as DatoCmsQuoteWithDataPointSection}
        />
      );
    }
    case __typename.includes("ColumnsWithIconsSection"): {
      return (
        <LPColumnsWithIconsSection
          section={section as DatoCmsColumnsWithIconsSection}
        />
      );
    }
    case __typename.includes("PercentageStatCardsSection"): {
      return (
        <LPPercentageStatCardsSection
          section={section as DatoCmsPercentageStatCardsSection}
        />
      );
    }
    case __typename.includes("LargeHeadingSection"): {
      return (
        <LPLargeHeadingSection
          section={section as DatoCmsLargeHeadingSection}
        />
      );
    }
    case __typename.includes("ImageAndTextRepeaterSection"): {
      return (
        <LPImageAndTextRepeaterSection
          section={section as DatoCmsImageAndTextRepeaterSection}
        />
      );
    }
    case __typename.includes("SocialProofSection"): {
      return (
        <LPSocialProofSection section={section as DatoCmsSocialProofSection} />
      );
    }
    case __typename.includes("G2BadgesSection"): {
      return <LPG2BadgesSection section={section as DatoCmsG2BadgesSection} />;
    }
    case __typename.includes("TeamHeadshotsSection"): {
      return (
        <LPTeamHeadshotsSection
          section={section as DatoCmsTeamHeadshotsSection}
        />
      );
    }
    case __typename.includes("PartnersLogoSetSection"): {
      return (
        <LPPartnersLogoSetSection
          section={section as DatoCmsPartnersLogoSetSection}
        />
      );
    }
    case __typename.includes("TextCtaWithButtonsSection"): {
      return (
        <LPTextCtaWithButtonsSection
          section={section as DatoCmsTextCtaWithButtonsSection}
        />
      );
    }
    case __typename.includes("ContentCardsSection"): {
      return (
        <LPContentCardsSection
          section={section as DatoCmsContentCardsSection}
        />
      );
    }
    case __typename.includes("LargeBookADemoFormSection"): {
      return (
        <LPLargeBookADemoFormSection
          section={section as DatoCmsLargeBookADemoFormSection}
        />
      );
    }
    default: {
      return (
        <PageSection>
          <h3>{__typename}</h3>
          <pre>{JSON.stringify(section, undefined, 2)}</pre>
        </PageSection>
      );
    }
  }
};
