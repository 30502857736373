import { DatoCmsPartner } from "../../../graphql-types";
import { ValidVideoBlock } from "../../utils/datocms.utils";
import VideoBlock from "../blocks/VideoBlock.block";
import PageSection from "../reusableSections/PageSection";

type Props = {
  partner: DatoCmsPartner;
};

const PartnerPageVideoEmbedsSection = ({ partner }: Props) => {
  return (
    <PageSection>
      {partner.videoEmbeds?.map(embed => (
        <VideoBlock record={embed as ValidVideoBlock} key={embed!.id} />
      ))}
    </PageSection>
  );
};

export default PartnerPageVideoEmbedsSection;
