export const BriefcaseWithHandcuffsIcon = () => (
  <svg
    width="119"
    height="85"
    viewBox="0 0 119 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3393 83.5196C34.6768 83.5196 39.0038 82.5142 39.0038 81.274C39.0038 80.0337 34.6768 79.0283 29.3393 79.0283C24.0018 79.0283 19.6748 80.0337 19.6748 81.274C19.6748 82.5142 24.0018 83.5196 29.3393 83.5196Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M117.415 69.4882C117.721 69.1985 117.516 68.6836 117.094 68.6836H39.9811C39.7236 68.6836 39.515 68.8923 39.515 69.1497V78.4657C39.515 78.7231 39.7236 78.9318 39.9811 78.9318H107.252C107.372 78.9318 107.486 78.8861 107.573 78.8042L117.415 69.4882Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M95.9531 10.3115H14.5013C12.9292 10.3115 11.6899 11.4878 10.4353 12.3849L3.47153 17.8583C2.25034 18.749 1.5242 19.8149 1.5242 21.3868V76.0414C1.5242 77.6135 2.79888 78.8878 4.37057 78.8878H86.3522C87.9239 78.8878 89.0038 78.4013 90.7395 77.0569L96.4649 72.0347C97.9331 70.7439 98.7994 69.3844 98.7994 67.8123V13.1574C98.7994 11.5855 97.5248 10.3115 95.9531 10.3115Z"
      fill="#C56DA3"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M89.1983 76.0418C89.1983 77.6139 87.9239 78.8881 86.3522 78.8881H4.37057C2.79888 78.8881 1.5242 77.6139 1.5242 76.0418V21.3872C1.5242 19.8155 2.79888 18.541 4.37057 18.541H86.3522C87.9239 18.541 89.1983 19.8155 89.1983 21.3872V76.0418Z"
      fill="#EBB7D6"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M85.4189 22.0879H5.30365V75.3403H85.4189V22.0879Z"
      fill="#F0D8E7"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M86.3523 18.541H84.3863C84.3004 18.8615 84.2499 19.1967 84.2499 19.5444C84.2499 21.6777 85.9792 23.4069 88.1123 23.4069C88.4899 23.4069 88.8535 23.3502 89.1984 23.2496V21.3872C89.1984 19.8153 87.9239 18.541 86.3523 18.541Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.33678 18.541H6.30278C6.38869 18.8615 6.43917 19.1967 6.43917 19.5444C6.43917 21.6777 4.71005 23.4069 2.57673 23.4069C2.19916 23.4069 1.83555 23.3502 1.49063 23.2496V21.3872C1.49063 19.8153 2.76508 18.541 4.33678 18.541Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.33678 78.9313H6.30278C6.38869 78.6111 6.43917 78.2751 6.43917 77.9276C6.43917 75.7948 4.71005 74.0654 2.57673 74.0654C2.19916 74.0654 1.83555 74.1219 1.49063 74.2229V76.0851C1.49063 77.6568 2.76487 78.9313 4.33678 78.9313Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M86.3519 78.9313H84.3859C84.3 78.6111 84.2495 78.2751 84.2495 77.9276C84.2495 75.7948 85.9789 74.0654 88.1122 74.0654C88.4893 74.0654 88.8531 74.1219 89.1983 74.2229V76.0851C89.1983 77.6568 87.9238 78.9313 86.3519 78.9313Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M98.0128 11.1973L88.4568 19.4756"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M79.797 28.0342H59.2881V40.153H79.797V28.0342Z"
      fill="#E269A4"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M94.3403 73.7346V14.5498"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.5005 12.7598H75.5718V16.8116H79.4459L84.5005 12.7598Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M94.3406 14.5498H7.68121"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.7125 10.4287H76.9734L71.7903 14.6709V16.8117H77.5296L82.7125 12.5695V10.4287Z"
      fill="#FFAF7E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.7125 10.4287H76.9734L71.7903 14.6709H77.5296L82.7125 10.4287Z"
      fill="#FFAF7E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5295 14.6709V16.8117"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0674 16.3795C39.0454 16.3795 41.4596 15.4997 41.4596 14.4144C41.4596 13.329 39.0454 12.4492 36.0674 12.4492C33.0893 12.4492 30.6751 13.329 30.6751 14.4144C30.6751 15.4997 33.0893 16.3795 36.0674 16.3795Z"
      fill="#FFAF7E"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M27.0342 12.7598H17.6689V16.8116H21.5429L27.0342 12.7598Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.6608 42.7212C35.7489 42.9631 33.9964 41.6044 33.7543 39.6921L33.4216 37.062C33.1796 35.1499 34.5389 33.3976 36.451 33.1558C38.3627 32.9141 40.1148 34.2728 40.3568 36.1849L40.6895 38.8154C40.9309 40.7273 39.5725 42.4794 37.6608 42.7212ZM36.7828 35.7815C36.3187 35.8402 35.9884 36.2659 36.0472 36.73L36.3797 39.3603C36.4383 39.8246 36.8642 40.1543 37.3285 40.0959C37.7929 40.037 38.1221 39.6118 38.0635 39.1476L37.7308 36.5169C37.6722 36.0528 37.2471 35.7225 36.7828 35.7815Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M57.8196 16.3795C60.7977 16.3795 63.2119 15.4997 63.2119 14.4144C63.2119 13.329 60.7977 12.4492 57.8196 12.4492C54.8416 12.4492 52.4274 13.329 52.4274 14.4144C52.4274 15.4997 54.8416 16.3795 57.8196 16.3795Z"
      fill="#FFAF7E"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M37.9533 35.9017C37.9533 36.5774 37.4054 37.1249 36.7302 37.1249C36.0545 37.1249 35.5066 36.5772 35.5066 35.9017V31.9038C35.5066 31.2283 36.0545 30.6807 36.7302 30.6807C37.4056 30.6807 37.9533 31.2285 37.9533 31.9038V35.9017Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.1654 14.6709C32.1931 15.7563 34.2005 16.5862 36.6496 16.5263C39.0988 16.4655 41.0625 15.5364 41.0347 14.4509C41.0075 13.3657 39.0011 12.5345 36.5518 12.5949C34.1022 12.6557 32.1389 13.5843 32.1654 14.6709Z"
      fill="#80325E"
    />
    <path
      d="M48.4254 16.544C48.4254 11.0639 43.967 6.60547 38.4869 6.60547C33.0068 6.60547 28.5484 11.0641 28.5484 16.544C28.5484 19.7731 30.0971 22.6456 32.4903 24.4615C32.9374 24.8011 33.2903 25.1767 33.6056 25.5072C33.9206 25.838 33.9157 26.7587 33.9157 26.7587V29.2225C33.9157 29.8868 34.4544 30.4255 35.1189 30.4255H41.8554C42.5199 30.4255 43.0585 29.8868 43.0585 29.2225V26.5405C43.0585 26.5405 42.996 26.1155 43.2438 25.6322C43.4917 25.149 43.7913 24.9529 44.1349 24.7147C46.7248 22.9194 48.4254 19.9275 48.4254 16.544ZM38.4869 22.3924C35.2623 22.3924 32.6387 19.7685 32.6387 16.544C32.6387 13.3194 35.2625 10.6956 38.4869 10.6956C41.7119 10.6956 44.3355 13.3194 44.3355 16.544C44.3355 19.7685 41.7117 22.3924 38.4869 22.3924Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M46.1129 17.5069C46.1129 12.0268 41.6544 7.56836 36.1743 7.56836C30.6943 7.56836 26.2358 12.027 26.2358 17.5069C26.2358 20.7359 27.7843 23.6081 30.1775 25.4244C30.6251 25.7637 30.9775 26.1396 31.2928 26.4701C31.6081 26.8006 31.6032 27.7214 31.6032 27.7214V30.1852C31.6032 30.8499 32.1418 31.3884 32.8063 31.3884H39.5426C40.2073 31.3884 40.7457 30.8499 40.7457 30.1852V27.5034C40.7457 27.5034 40.6832 27.0783 40.9311 26.5951C41.1789 26.1119 41.4785 25.916 41.8224 25.6778C44.4123 23.8826 46.1129 20.8904 46.1129 17.5069ZM36.1743 23.3553C32.9496 23.3553 30.3259 20.7317 30.3259 17.5069C30.3259 14.2821 32.9498 11.6587 36.1743 11.6587C39.3989 11.6587 42.0228 14.2823 42.0228 17.5069C42.0228 20.7319 39.3991 23.3553 36.1743 23.3553Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path d="M31.603 28.1921V21.0723" stroke="#80325E" strokeMiterlimit="10" />
    <path
      d="M33.8151 7.85352C33.8151 7.85352 33.5039 11.5143 37.1851 11.748"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M60.0339 12.4207C59.3002 11.8009 58.176 11.9253 57.5229 12.6985C56.8698 13.4716 56.935 14.6008 57.6687 15.2206C58.4024 15.8404 59.5266 15.716 60.1797 14.9428C60.8329 14.1697 60.7676 13.0405 60.0339 12.4207Z"
      fill="#80325E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.1304 10.4287H19.3911L14.2082 14.6709V16.8117H19.9473L25.1304 12.5695V10.4287Z"
      fill="#FFAF7E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.1304 10.4287H19.3911L14.2082 14.6709H19.9473L25.1304 10.4287Z"
      fill="#FFAF7E"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9474 14.6709V16.8117"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.8655 1H41.6073C39.7914 1 38.1823 1.85544 37.1744 3.1679C35.3888 4.05512 34.1618 5.85125 34.1618 7.92357V14.4921C34.1618 15.174 34.7144 15.7258 35.3957 15.7258C35.7172 15.7258 36.0101 15.6027 36.23 15.4008C36.476 15.1753 37.9289 14.0265 38.1488 13.8246C38.3947 13.5993 38.5485 13.275 38.5485 12.9157V6.34672C38.5485 5.9247 38.6479 5.52501 38.8219 5.16355C39.0966 5.08709 39.3874 5.04414 39.6885 5.04414H52.9467C54.6335 5.04414 56.0055 6.33534 56.0055 7.92335V14.4919C56.0055 15.1738 56.5579 15.7255 57.2393 15.7255C57.5797 15.7255 57.888 15.5879 58.1113 15.3647C58.3349 15.1414 59.8069 14.0112 60.0305 13.7879C60.2537 13.5647 60.3922 13.2559 60.3922 12.9155V6.3465C60.3922 3.39878 57.9131 1 54.8655 1Z"
      fill="#C56DA3"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M57.2393 15.7253C56.5579 15.7253 56.0055 15.1735 56.0055 14.4916V7.9231C56.0055 6.33487 54.6333 5.04388 52.9467 5.04388H39.6885C38.0017 5.04388 36.6297 6.33509 36.6297 7.9231V14.4916C36.6297 15.1735 36.0773 15.7253 35.3957 15.7253C34.7144 15.7253 34.1618 15.1735 34.1618 14.4916V7.9231C34.1618 4.97452 36.6411 2.57617 39.6885 2.57617H52.9467C55.9939 2.57617 58.4732 4.97473 58.4732 7.9231V14.4916C58.4732 15.1735 57.9208 15.7253 57.2393 15.7253Z"
      fill="#C56DA3"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M36.807 9.69968L38.84 8.49998L38.5398 5.50049C38.5398 5.50049 37.54 5.36733 37.2402 6.56704C36.9402 7.76675 36.807 9.69968 36.807 9.69968Z"
      fill="#80325E"
    />
    <path
      d="M30.423 17.0358C30.423 17.0358 31.7864 15.7929 32.5082 15.7527L33.5105 12.7051C33.5105 12.7051 32.5482 12.4648 31.8665 13.6276C31.1848 14.7903 30.423 17.0358 30.423 17.0358Z"
      fill="#80325E"
    />
    <path
      d="M39.0036 7.97337L41.129 9.05603C41.129 9.05603 42.3723 7.8531 42.8933 7.81294C42.8933 7.81294 39.7659 6.65017 39.0438 6.77044C38.3219 6.89072 39.0036 7.97337 39.0036 7.97337Z"
      fill="#80325E"
    />
    <path
      d="M38.9224 52.4085C37.0071 52.6229 35.2743 51.2391 35.0599 49.3238L34.7646 46.689C34.5503 44.7734 35.9343 43.0411 37.8498 42.8265C39.7651 42.6124 41.4971 43.996 41.7114 45.9117L42.0063 48.5465C42.2206 50.4619 40.8373 52.1942 38.9224 52.4085ZM38.1443 45.4568C37.6791 45.5088 37.343 45.9293 37.3947 46.3945L37.69 49.0296C37.742 49.4947 38.1627 49.8304 38.6279 49.7785C39.0927 49.7265 39.4288 49.3057 39.3762 48.8406L39.0813 46.2057C39.0293 45.7406 38.609 45.4047 38.1443 45.4568Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M35.5097 61.6161C33.7193 60.9027 32.8431 58.8656 33.5565 57.0752L34.538 54.6125C35.2515 52.8221 37.2886 51.9461 39.0789 52.6596C40.8693 53.3728 41.7451 55.4099 41.0316 57.2002L40.0501 59.663C39.3366 61.4533 37.2998 62.3296 35.5097 61.6161ZM38.0996 55.118C37.6647 54.9447 37.17 55.1576 36.9965 55.5923L36.0152 58.055C35.8419 58.4897 36.0547 58.9846 36.4896 59.1577C36.9237 59.331 37.4183 59.1179 37.5916 58.6832L38.5731 56.2205C38.7465 55.7858 38.5336 55.2914 38.0996 55.118Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M35.5923 63.1082C35.2764 63.7051 34.536 63.9334 33.9388 63.6174C33.3415 63.3007 33.1141 62.5605 33.43 61.9635L35.3004 58.4301C35.6163 57.8334 36.3571 57.6056 36.9539 57.9217C37.5508 58.2374 37.7791 58.9782 37.4627 59.5748L35.5923 63.1082Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.2857 44.6589C39.3591 45.3305 38.8742 45.9342 38.2026 46.0077C37.5305 46.0807 36.927 45.5957 36.8536 44.9244L36.4195 40.95C36.3465 40.2785 36.8315 39.6747 37.5033 39.6011C38.1746 39.5278 38.7786 40.013 38.8518 40.6846L39.2857 44.6589Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.3608 54.5357C39.2961 55.2082 38.6984 55.7011 38.0259 55.6369C37.3531 55.5718 36.8606 54.9745 36.925 54.3021L37.3069 50.3224C37.3716 49.6501 37.9692 49.1572 38.6417 49.2214C39.3141 49.2863 39.807 49.8838 39.7424 50.5562L39.3608 54.5357Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M16.5329 62.4812C11.7789 65.2069 10.1288 71.2928 12.8549 76.0468C15.581 80.8005 21.6665 82.4508 26.4202 79.7245C29.2219 78.1183 30.9433 75.346 31.3282 72.3665C31.4006 71.8098 31.5509 71.3169 31.6806 70.879C31.8108 70.4413 32.6121 69.9873 32.6121 69.9873L34.7491 68.7618C35.3259 68.431 35.525 67.6961 35.1941 67.1196L31.8432 61.2757C31.5127 60.6991 30.7777 60.5 30.2011 60.8307L27.8749 62.1647C27.8749 62.1647 27.5371 62.4303 26.9945 62.4555C26.452 62.4806 26.1333 62.3182 25.7553 62.1387C22.9092 60.785 19.4681 60.7983 16.5329 62.4812ZM26.5498 68.1937C28.1543 70.9911 27.1831 74.5726 24.3862 76.1765C21.5883 77.7809 18.0073 76.8094 16.4031 74.0125C14.799 71.2149 15.7698 67.6338 18.5676 66.0292C21.3648 64.4251 24.9459 65.3963 26.5498 68.1937Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M18.5189 64.0075C13.7647 66.7334 12.1152 72.8191 14.8411 77.5733C17.567 82.3272 23.6525 83.9771 28.4067 81.2512C31.2077 79.645 32.9293 76.8725 33.3144 73.8932C33.3866 73.3363 33.5367 72.8434 33.6668 72.4055C33.7966 71.9671 34.5981 71.5137 34.5981 71.5137L36.7353 70.288C37.3117 69.9577 37.5108 69.2228 37.1803 68.6461L33.829 62.802C33.4987 62.2258 32.7635 62.0262 32.1871 62.357L29.8605 63.6909C29.8605 63.6909 29.5231 63.9566 28.9801 63.9819C28.438 64.0071 28.1189 63.8447 27.7409 63.665C24.8954 62.3115 21.4541 62.3246 18.5189 64.0075ZM28.5364 69.72C30.1405 72.5176 29.1693 76.0989 26.3719 77.703C23.5746 79.3071 19.9935 78.3359 18.3889 75.5383C16.7848 72.7414 17.7556 69.1596 20.5532 67.5555C23.3508 65.9514 26.9321 66.9226 28.5364 69.72Z"
      fill="#F3ECF7"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M35.0056 71.2803L28.829 74.8218"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M16.2615 79.4789C16.2615 79.4789 19.592 77.9282 17.9634 74.6182"
      stroke="#80325E"
      strokeMiterlimit="10"
    />
    <path
      d="M27.3747 71.8112L29.2995 73.6958C29.2995 73.6958 29.5003 69.5658 27.094 68.1221L27.3747 71.8112Z"
      fill="#80325E"
    />
    <path
      d="M38.0414 31.3115H35.4752V32.9958H38.0414V31.3115Z"
      fill="#80325E"
    />
  </svg>
);
