export const CompassIcon = () => (
  <svg
    width="91"
    height="95"
    viewBox="0 0 91 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.5118 94.5332C76.35 94.5332 90 92.4883 90 89.9658C90 87.4433 76.35 85.3984 59.5118 85.3984C42.6737 85.3984 29.0237 87.4433 29.0237 89.9658C29.0237 92.4883 42.6737 94.5332 59.5118 94.5332Z"
      fill="#32274C"
    />
    <path
      d="M51.1253 6.00127C74.4843 8.09677 91.7161 28.669 89.6136 51.9511C87.5115 75.2331 66.8712 92.4078 43.5131 90.3127C20.1541 88.2168 2.92262 67.6444 5.02481 44.3624C7.127 21.0805 27.7677 3.90531 51.1253 6.00127Z"
      fill="#FABE64"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M43.4659 92.882C66.9191 92.882 85.9317 73.9321 85.9317 50.5563C85.9317 27.1804 66.9191 8.23047 43.4659 8.23047C20.0126 8.23047 1 27.1804 1 50.5563C1 73.9321 20.0126 92.882 43.4659 92.882Z"
      fill="#FFD18C"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M43.4658 86.9585C63.6356 86.9585 79.9865 70.6616 79.9865 50.5583C79.9865 30.4551 63.6356 14.1582 43.4658 14.1582C23.2961 14.1582 6.94525 30.4551 6.94525 50.5583C6.94525 70.6616 23.2961 86.9585 43.4658 86.9585Z"
      fill="#32274C"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M41.5462 85.8667C21.4577 84.0646 6.63859 66.3725 8.44658 46.3503C9.5265 34.3911 16.2951 24.3075 25.8508 18.5078C15.429 24.1146 7.93616 34.657 6.79527 47.2938C4.98704 67.3163 19.8063 85.0084 39.8948 86.8107C47.9855 87.5363 55.6958 85.5725 62.1384 81.6616C56.0479 84.9387 48.9568 86.5313 41.5462 85.8667Z"
      fill="#FABE64"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M43.4611 22.8086V22.5879L43.4389 22.6986L43.4165 22.5879V22.8086L37.8226 50.5587L43.4165 78.3086V78.5295L43.4387 78.4191L43.4611 78.5295V78.3086L49.0545 50.5587L43.4611 22.8086Z"
      fill="#7266CC"
    />
    <path
      d="M25.722 32.8777L25.5811 32.7363L25.6397 32.8183L25.5575 32.7601L25.6986 32.9007L40.4697 53.5181L61.1555 68.2405L61.2962 68.3816L61.2373 68.2996L61.32 68.3576L61.1785 68.2174L46.4078 47.5993L25.722 32.8777Z"
      fill="#7266CC"
    />
    <path
      d="M25.699 68.2172L25.5575 68.3578L25.6397 68.2994L25.5811 68.3814L25.7222 68.2403L46.4083 53.5181L61.1785 32.901L61.3203 32.7601L61.2385 32.8185L61.2962 32.7363L61.1563 32.8769L40.4699 47.5993L25.699 68.2172Z"
      fill="#7266CC"
    />
    <path
      d="M15.5967 50.5359H15.3753L15.4859 50.558L15.3753 50.5802H15.5967L43.4389 56.1556L71.2809 50.5802H71.5023L71.3915 50.5583L71.5023 50.5359H71.2809L43.4389 44.9609L15.5967 50.5359Z"
      fill="#7266CC"
    />
    <path
      d="M43.4659 83.2419C61.5767 83.2419 76.2585 68.6086 76.2585 50.5575C76.2585 32.5064 61.5767 17.873 43.4659 17.873C25.355 17.873 10.6732 32.5064 10.6732 50.5575C10.6732 68.6086 25.355 83.2419 43.4659 83.2419Z"
      stroke="#7266CC"
      strokeMiterlimit="10"
    />
    <path
      d="M43.4659 80.277C59.9349 80.277 73.2858 66.9702 73.2858 50.5555C73.2858 34.1408 59.9349 20.834 43.4659 20.834C26.9968 20.834 13.646 34.1408 13.646 50.5555C13.646 66.9702 26.9968 80.277 43.4659 80.277Z"
      stroke="#7266CC"
      strokeMiterlimit="10"
      strokeDasharray="2.88 2.88"
    />
    <path
      d="M57.4685 19.4673L57.58 19.22L57.4973 19.3314L57.5254 19.1953L57.4144 19.4429L36.6085 47.5029L30.0715 80.296L29.9604 80.543L30.0431 80.4314L30.0148 80.568L30.1263 80.3202L50.2696 53.6123L57.4685 19.4673Z"
      fill="#EB81AF"
    />
    <path
      d="M50.2696 53.6113L36.6084 47.502L29.8118 80.8902L50.2696 53.6113Z"
      fill="#FFD18C"
    />
    <path
      d="M57.4685 19.4679L57.4144 19.4434L36.6085 47.5035L29.9935 80.4086L30.0481 80.4328L50.2697 53.6128L57.4685 19.4679Z"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.0853 43.7058C47.8815 44.0469 50.6823 47.3902 50.3406 51.1746C49.9991 54.9591 46.6438 57.7508 42.8477 57.4095C39.0507 57.0694 36.2496 53.7254 36.5915 49.9414C36.9327 46.1574 40.2873 43.3655 44.0853 43.7058Z"
      fill="#FABE64"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M43.4661 54.1489C45.4571 54.1489 47.071 52.5402 47.071 50.5559C47.071 48.5715 45.4571 46.9629 43.4661 46.9629C41.4752 46.9629 39.8613 48.5715 39.8613 50.5559C39.8613 52.5402 41.4752 54.1489 43.4661 54.1489Z"
      fill="#FFE1B3"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M41.3637 72.0341L50.3273 54.4473L39.9484 67.8962L41.3637 72.0341Z"
      fill="#32274C"
    />
    <path
      d="M48.9194 2.83485C48.9158 1.82208 46.505 1.00903 43.5344 1.01963C40.5643 1.02953 38.1589 1.85858 38.1627 2.87182C38.1655 3.71289 38.1721 5.55967 38.175 6.40097C38.1787 7.41374 40.5895 7.80166 43.5597 7.79177C46.5305 7.78093 48.9357 7.37654 48.9317 6.364C48.9288 5.52317 48.9227 3.67615 48.9194 2.83485Z"
      fill="#FABE64"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M43.5466 3.84098C46.517 3.83091 48.9228 3.191 48.9202 2.4117C48.9175 1.6324 46.5074 1.00882 43.537 1.01888C40.5666 1.02895 38.1608 1.66886 38.1634 2.44816C38.1661 3.22746 40.5762 3.85104 43.5466 3.84098Z"
      fill="#FFD18C"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M32.2864 8.61666L37.5834 6.87305L39.7702 8.22945L33.4526 9.19794L32.2864 8.61666Z"
      fill="#32274C"
    />
    <path
      d="M49.5688 6.27344C49.5688 6.27344 51.2409 7.43011 50.2897 8.52037L47.2903 7.96052L49.5688 6.27344Z"
      fill="#32274C"
    />
  </svg>
);
