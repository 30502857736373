import { makeGPTDemoStory } from "./makeGptDemoStory";
import { makeInfrastructureDemoStory } from "./makeInfrastructureDemoStory";
import { makeProductDemoStory } from "./makeProductDemoStory";
import { makeSecurityDemoStory } from "./makeSecurityDemoStory";

const factories = {
  security: makeSecurityDemoStory,
  it: makeInfrastructureDemoStory,
  engineering: makeInfrastructureDemoStory,
  infrastructure: makeInfrastructureDemoStory,
  product: makeProductDemoStory,
  gpt: makeGPTDemoStory,
};

export type DemoStoryType = keyof typeof factories;

export const makeDemoStoryConfig = (type: DemoStoryType) => {
  return (factories[type] ?? factories.security)();
};
