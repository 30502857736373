import ComboFontHeading from "../../../typography/ComboFontHeading";
import PillLegacy from "../../../basic/PillLegacy";
import SpotIllustration from "../../../illustrations/SpotIllustration";
import ReportSecondarySection from "../../ReportSecondarySection";
import ReportChartContainer from "../../ReportChartContainer";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import DonutChart from "../../charts/DonutChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import LeaderboardChart, {
  LeaderboardChartItem,
} from "../../charts/LeaderboardChart";
import { css } from "linaria";
import Spacing from "../../../layout/Spacing";
import BarChart from "../../charts/BarChart";
import { ReportCardColumnedInner } from "../../ReportCard";
import { onlyPhones } from "../../../../styles/breakpointsAndMediaQueries.styles";

type Props = {};

const SOCChapter4 = (props: Props) => {
  return (
    <ReportSection
      id="manual-work-and-automation"
      themeName="pink"
      header={
        <>
          <div>
            <SpotIllustration name="newtonsCradle" />
          </div>
          <PillLegacy color="pink">Part Four</PillLegacy>
          <ComboFontHeading
            snippets={[
              [
                <>
                  Manual work
                  <br />
                </>,
              ],
              [<>and automation</>, "serif"],
            ]}
          />
          <p>
            Lowering barriers to doing the best work, eliminating repetition,
            streamlining processes — it’s what security leaders are looking to
            do for their teams. They can do so by building automation into their
            processes, like automating phishing attack responses, threat
            intelligence enrichment, or suspicious login alerts. But where do
            SOC teams fall on their utilization of automation? Are teams
            embracing it, or still not sure if it’s right for them?
          </p>
        </>
      }
    >
      <ReportSecondarySection
        className={css`
          ${onlyPhones} {
            text-align: center;
          }
        `}
      >
        <ReportCardColumnedInner>
          <header>
            <h3>
              64% are spending over half their time on tedious manual work
            </h3>
            <p>
              How much time are analysts spending on tedious manual work? 12%
              say a quarter of their time is spent on tedious manual work while
              23.9% say a quarter to half their time is spent that way. For 41%
              (the majority), half to three-quarters of their time is spent on
              tedious manual work, while 23.1% — one in four analysts — say
              three-quarters to all of their time is spent that way.
            </p>
          </header>
          <ReportChartContainer>
            <h4>
              What percentage of your time at work would you describe as tedious
              manual work?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Less than 25%", 56],
                ["26–50%", 112],
                ["51–75%", 192],
                ["76–100%", 108],
              ]}
              donutPercentage={0.375}
              height={250}
            />
          </ReportChartContainer>
        </ReportCardColumnedInner>
      </ReportSecondarySection>

      <ReportSecondarySection
        className={css`
          ${onlyPhones} {
            text-align: center;
          }
        `}
      >
        <ReportCardColumnedInner>
          <header>
            <h3>69% fear automation will eliminate their job</h3>
            <p>
              Despite the amount of time spent on tasks that could be automated,
              68.6% worry that automation will eliminate their job — their
              co-workers’ or their own — in the near future. However, 31.4% are
              not worried about automation replacing them.
            </p>
          </header>
          <ReportChartContainer>
            <h4>
              Do you worry that automation will eliminate your job/your
              co-workers jobs in the near future?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Yes", 321],
                ["No", 147],
              ]}
              donutPercentage={0.375}
              colors={[colorsV4.pink, colorsV4.warmBlack]}
              height={250}
            />
          </ReportChartContainer>
        </ReportCardColumnedInner>
      </ReportSecondarySection>

      <ReportSecondarySection
        className={css`
          ${onlyPhones} {
            text-align: center;
          }
        `}
      >
        <ReportCardColumnedInner>
          <header>
            <h3>
              66% believe that half of their tasks to all of their tasks could
              be automated today
            </h3>
            <p>
              13.5% believe that only less than a quarter of their tasks could
              be automated, while 20.1% believe a quarter to half of their tasks
              could. 41.2% (the majority) believe that half to three-quarters of
              their tasks could be automated, while 25.2% — one in four analysts
              — believe three-quarters to all of their tasks could be automated.
            </p>
          </header>
          <ReportChartContainer>
            <h4>
              What percentage of your work do you believe could be
              done/automated by software that’s available today?
            </h4>
            <DonutChart
              header={["Answer", "Respondents"]}
              data={[
                ["Less than 25%", 63],
                ["26–50%", 94],
                ["51–75%", 193],
                ["76–100%", 118],
              ]}
              donutPercentage={0.375}
              height={250}
            />
          </ReportChartContainer>
        </ReportCardColumnedInner>
      </ReportSecondarySection>

      <ReportSecondarySection spacing="1.5em" centered>
        <ComboFontHeading
          snippets={[
            [
              <>
                Why analysts should not
                <br />
              </>,
              "serif",
            ],
            [<>fear automation</>],
          ]}
          as="h3"
        />
        <p>
          Fear of losing your job is a common response to the suggestion of
          automation. But “automation” doesn’t mean “replacement,” and
          automating tasks as a security analyst can not only decrease tedious
          manual work to free up time for more high-impact projects, automation
          can become a learned skill to help SOC teams do their best work and
          increase the value of the analyst. Why shouldn’t analysts fear
          automation?
        </p>
        <LeaderboardChart
          columns={1}
          className={css`
            p {
              font-size: 1.6rem;
            }
          `}
        >
          <LeaderboardChartItem
            centered
            textInCircle="1"
            color={colorsV4.purple}
            padding="2em"
          >
            <h4>Because we need more people than ever</h4>
            <p>
              Security leaders know that the current recruitment market is
              unprecedented, and that there isn’t nearly enough talent in the
              pipeline. As we’ve seen in the answers in this report, teams are
              understaffed, can’t find people with the needed skills, and are
              frustrated with spending their time on manual work. Even with the
              majority of L1 SOC analyst tasks automated, there’s still “more
              work than ever,” as seen from the above responses. Automation can
              help free up skilled workers for higher-level tasks, letting them
              play a more valuable role in the organization.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            textInCircle="2"
            color={colorsV4.pink}
            padding="2em"
          >
            <h4>Because we can learn from history</h4>
            <p>
              Automation brings with it a negative connotation because it often
              gets conflated with the idea that “robots are taking our jobs,”
              and the threat of not being able to put food on the table provokes
              an emotional human response. The truth is that automation has
              never acted to eliminate jobs in the long run. Instead, automation
              transforms and improves them. In security automation specifically,
              once analysts are given the superpower of automation, they start
              by replacing the mundane tasks they once had to do manually. Soon,
              however, they go on to build entirely new processes in a creative
              manner.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            textInCircle="3"
            color={colorsV4.orange}
            padding="2em"
          >
            <h4>Because automation is a tool for you to use</h4>
            <p>
              Automation, and specifically no-code automation, will become a
              core competency of security analysts. It’s nothing to fear, as
              automation can make the job more creative, giving analysts not
              just the ability to build, maintain, and evolve ingenious
              automated workflows, but also the ability to contribute more value
              to their organization, as well. Automated workflows aren’t just a
              one-and-done build, either; analysts will need to modify, improve,
              and update those workflows on an ongoing basis.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
      </ReportSecondarySection>

      <ReportSecondarySection centered>
        <ComboFontHeading
          snippets={[
            [
              <>
                Top five tasks
                <br />
              </>,
            ],
            [<>analysts would automate to save time</>, "serif"],
          ]}
          as="h3"
        />
        <p>
          If analysts could automate one task that would save them the most
          time, which task would it be?
        </p>
        <Spacing size=".5em" />

        <LeaderboardChart columns={2}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.pink}
            textInCircle="24.8%"
          >
            <h4>#1: Risk assessments</h4>
            <p>
              The majority said the number one task they would automate is risk
              assessments. As we’ve seen throughout this report, analysts are
              spending so much of their time and energy manually monitoring for
              and triaging risk, that if they could completely automate it, they
              would.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.purple}
            textInCircle="19.2%"
          >
            <h4>#2: Intelligence analysis</h4>
            <p>
              Next, they would automate intelligence analysis so that alerts
              could arrive with richer, more actionable context, saving time on
              having to track down that information manually.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.orange}
            textInCircle="12%"
          >
            <h4>#3: Threat hunting</h4>
            <p>
              The task they’d most like to automate next is threat hunting,
              allowing for the elimination of manual efforts to search out
              hacker threats or IOCs.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.green}
            textInCircle="8.5%"
          >
            <h4>#4: Email phishing response</h4>
            <p>
              They’d also like to automate email phishing response, which can
              eliminate the manual task of threat evaluation, communication
              follow-up, and remediation.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.blue}
            textInCircle="8.3%"
          >
            <h4>#5: Advanced triage</h4>
            <p>
              Finally, to save them time, they’d automate advanced triage, which
              is especially important considering that the majority of our
              respondents are Level 2 or 3 analysts.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>

        <Spacing size=".5em" />

        <ReportChartContainer centered>
          <h4>
            What one task, if completely automated, would save you the most
            manual time?
          </h4>
          <BarChart
            header={["Answer", "Respondents"]}
            sortByValue
            data={[
              ["Risk assessments", 116],
              ["Threat hunting", 56],
              ["Intelligence analysis", 90],
              ["Attack surface management", 37],
              ["Level-1 triage", 22],
              ["Advanced triage", 39],
              ["Email phishing", 40],
              ["Abuse response", 11],
              ["Endpoint detection & response", 13],
              ["Vulnerability management", 28],
              ["Patching", 16],
            ]}
            total={468}
            height={400}
            colors={[colorsV4.pink]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>

      <ReportSecondarySection centered>
        <ComboFontHeading
          snippets={[
            [
              <>
                What would analysts do
                <br />
              </>,
            ],
            [<>if their work was automated?</>, "serif"],
          ]}
          as="h3"
        />
        <p>
          If their tedious, low-impact tasks were automated today, what would
          security analysts be working on instead?
        </p>
        <Spacing size=".5em" />

        <LeaderboardChart columns={2}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.pink}
            textInCircle="48.1%"
          >
            <h4>#1: Updating operational documentation</h4>
            <p>
              The majority said that in the absence of manual work, they would
              focus on updating operational documentation. Having updated
              documentation is of course critical for the SOC team, but this
              indicates something else: That SOC teams are too busy to keep
              their documentation up-to-date, and have fallen behind. The first
              focus after automation would actually be catching up.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.purple}
            textInCircle="44.7%"
          >
            <h4>#2: Developing advanced detection rules</h4>
            <p>
              The second highest ranked is developing advanced detection rules,
              which is a proactive way to improve their organization’s security
              posture, as well as a way to improve the performance metrics
              listed above.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>
        <LeaderboardChart columns={3}>
          <LeaderboardChartItem
            centered
            index={0}
            color={colorsV4.orange}
            textInCircle="41.0%"
          >
            <h4>#3: Integrating more systems and logs</h4>
            <p>
              If freed up, they would also focus on integrating more systems and
              logs, which is another proactive action that can help improve the
              previously mentioned poor processes that are causing frustration.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={1}
            color={colorsV4.green}
            textInCircle="39.5%"
          >
            <h4>#4: Research TTPs and focus more on intelligence</h4>
            <p>
              Automating manual tasks would also allow them time and energy to
              research TTPs and focus more on intelligence, increasing their
              offense on understanding and protecting against malicious actors.
            </p>
          </LeaderboardChartItem>
          <LeaderboardChartItem
            centered
            index={2}
            color={colorsV4.blue}
            textInCircle="35%"
          >
            <h4>#5: Reduce false positive rates</h4>
            <p>
              Finally, automation would allow them to modify detection and alert
              rules to reduce false positive rates, reducing alert fatigue and
              burnout, and allowing them to focus on alerts that really matter.
            </p>
          </LeaderboardChartItem>
        </LeaderboardChart>

        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            If you no longer had to do tedious manual work, what would you
            prefer to be doing?
          </h4>
          <BarChart
            header={["Challenge", "Respondents"]}
            sortByValue
            data={[
              ["Updating operational documentation", 225],
              ["Develop advanced detection rules", 209],
              ["Integrate more systems and logs", 192],
              ["Threat hunt more", 150],
              ["Research TTPs more/intelligence", 185],
              ["Research and evaluate new tools", 160],
              [
                "Modify detection and alert rules to reduce false positive rates",
                164,
              ],
              ["Update reports and dashboards", 94],
            ]}
            total={468}
            height={400}
            colors={[colorsV4.pink]}
          />
        </ReportChartContainer>
      </ReportSecondarySection>

      <ReportSummaryCard>
        <h3>Summary</h3>
        <p>
          So far, we’ve learned more about areas where analysts are mired in
          time-consuming tasks, spending too much manual effort on reporting and
          detection, frustrated with the amount of alerts that come through or
          lack of context around those alerts, and worried about too much work
          and not enough hands on their team. Yet in this section, we get to see
          a security analyst’s ideal day, if the tasks they’re hindered with are
          moved off their schedule (and 66% believe that half to all of their
          tasks could be automated today).
        </p>
        <p>
          An analyst’s ideal workday would include spending time improving
          processes and procedures, such as updating operational documentation
          and integrating more systems. It would also include proactive
          approaches to keeping their organization safe, like developing
          advanced detection and alert rules and becoming more knowledgeable
          about threat actor tactics, techniques, and procedures (TTPs). They
          would also build and maintain automated workflows in creative and
          intricate ways, as more sophisticated automation frees up the time to
          spend on the previously mentioned high-impact tasks.
        </p>
        <p>
          Automate the present tasks, and security analysts can begin planning
          for the future.
        </p>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default SOCChapter4;
