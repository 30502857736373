import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { color, colors } from "../../styles/colors.styles";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { GridCanvas } from "../decorations/GridCanvas";
import illo from "./illustrations/ProServFiveStagesIllo.svg";
import { words } from "../../utils/string.utils";
import { serif } from "../../styles/fonts.styles";

const SectionWrap = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  max-width: ${maxPageContentWidth}px;
  margin-top: -2.5em;
  ${fromDesktop} {
    margin-top: -6em;
  }
  margin-left: auto;
  margin-right: auto;
  padding: ${rSize("sectionPadding")} ${rSize("pageMargin")};
  h3 {
    font-size: 2.4rem;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

const Backdrop = styled.div`
  ${fromTablet} {
    border-radius: ${rSize("radius")};
  }
  border: 1px solid ${colors.purple200};
  position: absolute;
  left: ${rSize("widerPageMargin")};
  right: ${rSize("widerPageMargin")};
  top: 0;
  bottom: 0;
`;

const Inner = styled.div`
  padding-top: 3em;
  ${fromDesktop} {
    padding-top: 6em;
  }
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 3em ${rSize("gap")};
  grid-template-areas: ${[
    [words("heading", 8)],
    [words("s1", 4), words("s4", 4)],
    [words("s1", 4), words("s4", 4)],
    [words("s2", 4), words("s5", 4)],
    [words("s3", 4), words("illo", 4)],
  ]
    .map(row => `"${row.join(" ")}"`)
    .join(" ")};
  ${fromTablet} {
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 4em ${rSize("gap")};
    grid-template-areas: ${[
      [words("heading", 9)],
      [words("s1", 4), ".", words("s4", 4)],
      [words("s1", 4), ".", words("s4", 4)],
      [words("s2", 4), ".", words("s5", 4)],
      [words("s3", 4), ".", words("illo", 4)],
    ]
      .map(row => `"${row.join(" ")}"`)
      .join(" ")};
  }
  ${fromDesktop} {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${rSize("lg")} ${rSize("gap")};
    grid-template-areas: ${[
      [words("heading", 4), words(".", 8)],
      [words("heading", 4), ".", words("s1", 3), ".", words("s4", 3)],
      [words(".", 4), ".", words("s1", 3), ".", words("s4", 3)],
      [words("illo", 4), ".", words("s2", 3), ".", words("s5", 3)],
      [words("illo", 4), ".", words("s3", 3), ".", words(".", 3)],
    ]
      .map(row => `"${row.join(" ")}"`)
      .join(" ")};
  }
`;
const Heading = styled.h2`
  grid-area: heading;
  font-size: 2.8rem;
  font-weight: 400;
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    font-size: 3.8rem;
  }
  ${fromDesktopMd} {
    font-size: 4.6rem;
    font-weight: 300;
  }
  font-family: ${serif};
  letter-spacing: -0.01em;
`;

const Stage = styled.div`
  h3 {
    font-size: 1.8rem;
    ${fromDesktopMd} {
      font-size: 2.2rem;
      letter-spacing: -0.01em;
    }
  }
  p {
    font-size: 1.4rem;
    ${fromDesktopMd} {
      font-size: 1.6rem;
    }
  }
  &:nth-child(2) {
    grid-area: s1;
  }
  &:nth-child(3) {
    grid-area: s2;
  }
  &:nth-child(4) {
    grid-area: s3;
  }
  &:nth-child(5) {
    grid-area: s4;
  }
  &:nth-child(6) {
    grid-area: s5;
  }
`;

const IlloWrap = styled.div`
  grid-area: illo;
  aspect-ratio: 1/1;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple200};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rSize("radius")};
  align-self: end;
  img {
    display: block;
    max-width: 66%;
    height: auto;
  }
`;

export const ProServFiveStagesSection = () => {
  return (
    <SectionWrap>
      <Backdrop>
        <GridCanvas color={color("purple", 100)} parallax />
      </Backdrop>
      <Inner>
        <Grid>
          <Heading>
            Fine-tuning every stage of workflow automation maturity
          </Heading>
          <Stage>
            <h3>Advisory</h3>
            <p>
              The path to workflow maturity is evolving as technologies change.
              Our advisory services provide coaching and consulting on how to
              optimize your workflows.
            </p>
          </Stage>
          <Stage>
            <h3>Automation workshop</h3>
            <p>
              These offer an assessment of your workflow environment to identify
              new use cases and iterate on your processes. We’ll identify
              whitespace, develop a short-and long-term plan.
            </p>
          </Stage>
          <Stage>
            <h3>Technical training</h3>
            <p>
              At any stage in your automation journey, hands-on training
              provides your team with the guidance and coaching they need from
              our expert trainers.
            </p>
          </Stage>
          <Stage>
            <h3>Story building</h3>
            <p>
              We’ll jump-start your journey by building a selection of
              end-to-end use cases while your team goes through onboarding and
              initial training.
            </p>
          </Stage>
          <Stage>
            <h3>Migration</h3>
            <p>
              Our experts provide the project management and technical expertise
              to support you. We look for opportunities to optimize your
              workflow strategy and embed your team into Tines.
            </p>
          </Stage>
          <IlloWrap>
            <img src={illo} />
          </IlloWrap>
        </Grid>
      </Inner>
    </SectionWrap>
  );
};
