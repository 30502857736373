export const GoldCoinIcon = () => (
  <svg
    width="88"
    height="82"
    viewBox="0 0 88 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.4261 81.7278C74.7593 81.7278 88 80.6817 88 79.3913C88 78.1008 74.7593 77.0547 58.4261 77.0547C42.0929 77.0547 28.8522 78.1008 28.8522 79.3913C28.8522 80.6817 42.0929 81.7278 58.4261 81.7278Z"
      fill="black"
    />
    <path
      d="M46.4304 80.7847C68.566 80.7847 86.5104 62.8403 86.5104 40.7048C86.5104 18.5694 68.566 0.625 46.4304 0.625C24.2949 0.625 6.35049 18.5694 6.35049 40.7048C6.35049 62.8403 24.2949 80.7847 46.4304 80.7847Z"
      fill="#B36847"
      stroke="black"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M59.1271 5.00586H64.1192"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62.936 9.00781H70.7592"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M69.1827 14.0781H76.0864"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M72.8389 19.1465H79.9104"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M74.8188 24.2148H82.9147"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M78.1707 29.2852H84.6909"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M79.8462 34.3535H85.7842"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M78.933 39.4238H86.3063"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M79.6944 44.4941H86.2277"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M78.933 49.5625H85.5108"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M77.5619 54.6309H83.9167"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M74.8197 59.7012H81.5502"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M71.4678 64.7695H78.4529"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.0502 69.8398H73.628"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M60.1946 74.9082H67.3456"
      stroke="#FABE64"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.4304 80.7847C68.566 80.7847 86.5104 62.8403 86.5104 40.7048C86.5104 18.5694 68.566 0.625 46.4304 0.625C24.2949 0.625 6.35049 18.5694 6.35049 40.7048C6.35049 62.8403 24.2949 80.7847 46.4304 80.7847Z"
      stroke="black"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M41.0802 80.7851C63.2158 80.7851 81.1603 62.8407 81.1603 40.7051C81.1603 18.5695 63.2158 0.625 41.0802 0.625C18.9445 0.625 1 18.5695 1 40.7051C1 62.8407 18.9445 80.7851 41.0802 80.7851Z"
      fill="#FABE64"
      stroke="black"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M68.5123 16.8398C70.9585 19.686 72.9224 22.9593 74.2761 26.5323"
      stroke="#FFF0D9"
      strokeWidth="2.51368"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.0797 40.7031V54.1319H36.4086C33.2855 54.1319 30.7535 56.6634 30.7535 59.7869C30.7535 62.9098 33.2855 65.4413 36.4086 65.4413H41.0797V80.7834"
      stroke="#D67C56"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M41.0796 0.892578V14.3215H45.7509C48.8741 14.3215 51.406 16.853 51.406 19.9762C51.406 23.0993 48.8741 25.631 45.7509 25.631H41.0796V40.9729"
      stroke="#D67C56"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M41.2134 40.8396H27.7846V36.1685C27.7846 33.0454 25.2532 30.5137 22.1298 30.5137C19.0064 30.5137 16.4749 33.0454 16.4749 36.1685V40.8396H1.13303"
      stroke="#D67C56"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M81.0593 40.8379H67.5978V45.5092C67.5978 48.6323 65.0663 51.1638 61.943 51.1638C58.8196 51.1638 56.2881 48.6323 56.2881 45.5092V40.8379H40.9467"
      stroke="#D67C56"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M41.0802 80.7851C63.2158 80.7851 81.1603 62.8407 81.1603 40.7051C81.1603 18.5695 63.2158 0.625 41.0802 0.625C18.9445 0.625 1 18.5695 1 40.7051C1 62.8407 18.9445 80.7851 41.0802 80.7851Z"
      stroke="black"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M65.5031 14.9374C66.174 14.9374 66.7179 14.3935 66.7179 13.7226C66.7179 13.0517 66.174 12.5078 65.5031 12.5078C64.8322 12.5078 64.2883 13.0517 64.2883 13.7226C64.2883 14.3935 64.8322 14.9374 65.5031 14.9374Z"
      fill="#FFF0D9"
    />
    <path
      d="M41.0796 73.4188C59.1478 73.4188 73.7949 58.7716 73.7949 40.7035C73.7949 22.6354 59.1478 7.98828 41.0796 7.98828C23.0114 7.98828 8.36429 22.6354 8.36429 40.7035C8.36429 58.7716 23.0114 73.4188 41.0796 73.4188Z"
      stroke="#B36847"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
    />
    <path
      d="M10.6282 40.7033C10.6282 23.0143 24.668 8.60687 42.2117 8.00999C41.8353 7.99719 41.4585 7.98828 41.0796 7.98828C23.0115 7.98828 8.36429 22.6352 8.36429 40.7033C8.36429 58.7716 23.0115 73.4185 41.0796 73.4185C41.4585 73.4185 41.8353 73.4096 42.2117 73.397C24.6682 72.7999 10.6282 58.3927 10.6282 40.7033Z"
      fill="#B36847"
    />
    <path
      d="M81.1602 40.8184H86.5107"
      stroke="black"
      strokeWidth="1.14258"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
