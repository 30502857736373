import { styled } from "@linaria/react";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import ProductPageHeroAppContent from "./ProductPageHeroAppContent";
import { RadarSetAndMailbox } from "./decos/RadarSetAndMailbox";
import { Crane } from "./decos/Crane";
import { CloudLeftFront } from "./decos/CloudLeftFront";
import { CloudLeftMid } from "./decos/CLoudLeftMid";
import { CLoudLeftBack } from "./decos/CloudLeftBack";
import { Machinery } from "./decos/Machinery";
import { MiniPylons } from "./decos/MiniPylons";
import { CableRight, CablesLeft } from "./decos/Cables";
import {
  CloudRightA,
  CloudRightB,
  CloudRightC,
  CloudRightD,
  CloudRightE,
  CloudRightF,
} from "./decos/CloudsRight";
import { SpeechBubbleA, SpeechBubbleB } from "./decos/SpeechBubbles";
import {
  CloudTopLarge,
  CloudTopSmallLeft,
  CloudTopSmallRight,
} from "./decos/CloudsTop";
import { NoodleGreen } from "./decos/NoodleGreen";
import {
  FlowerGroupCloud,
  FlowerLarge,
  FlowerSmall,
  FlowerVines,
} from "./decos/Flowers";
import MultiplayerCursor, {
  MultiplayerCursorOuter,
} from "../../misc/MultiplayerCursor";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
  uptoDesktop,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const ProductPageHeroIllustrationFigure = styled.figure`
  margin: 0;
  padding: 12rem 0 0 0;
  svg {
    display: block;
  }
`;

const AppFramePositioner = styled.div`
  position: relative;
  max-width: ${widthInGrid(10)};
  margin-left: auto;
  margin-right: auto;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(3px);
    background-color: ${colors.purple400};
    border: 1px solid ${colors.purple};
    border-radius: 1.4rem 1.4rem 1.9rem 1.9rem;
  }
`;

const AppFrame = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  background-color: ${colors.purple200};
  border-radius: 1.4rem 1.4rem 1.9rem 1.9rem;
  min-height: 360px;
  ${fromTabletLg} {
    min-height: 480px;
  }
  ${fromDesktop} {
    min-height: 618px;
  }
  border: 1px solid ${colors.purple};
  padding: 4px;
  position: relative;
  color: #333333;
`;

const AppFrameInner = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
`;

const Bullets = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const BulletContent = styled.div`
  ${uptoDesktop} {
    background-color: ${colors.purple100};
    padding: 1em;
    border-radius: 0.25em 0.75em 0.75em 0.75em;
    border: 1px solid ${colors.purple400};
    position: absolute;
    top: 100%;
    width: 26rem;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.3s, transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 2;
  }
`;

const BulletPulse = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
  ${fromDesktop} {
    display: none;
  }
  b {
    position: relative;
    display: block;
    width: 12px;
    height: 12px;
    background-color: ${colors.purple400};
    border: 1px solid ${colors.purple700};
    border-radius: 50%;
  }
  i {
    display: block;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${colors.purple};
    opacity: 0;
    @keyframes BulletPulseAnimation {
      from {
        opacity: 0.2;
        transform: scale(0.5);
      }
      to {
        opacity: 0;
        transform: scale(1);
      }
    }
    animation: BulletPulseAnimation 1.8s cubic-bezier(0.075, 0.82, 0.165, 1)
      infinite;
  }
  &:focus {
    b,
    i {
      background-color: ${colors.orange400};
      border-color: ${colors.orange600};
    }
    + ${BulletContent} {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const Bullet = styled.li`
  position: absolute;
  font-size: 1.4rem;
  ${fromDesktop} {
    padding: ${rSize("gap")};
    width: 20rem;
  }
  ${fromDesktopMd} {
    width: 25rem;
  }
  h4 {
    font-size: 1.4rem;
    ${fromDesktop} {
      font-size: 1.6rem;
    }
    + * {
      margin-top: 0.6rem;
    }
  }
  p {
    line-height: 1.3;
    font-size: 1.3rem;
    ${fromDesktopMd} {
      font-size: 1.4rem;
    }
  }
  ${uptoDesktop} {
    text-align: left;
    &:nth-child(1) {
      top: 8.4rem;
      left: 4.8rem;
      ${fromTabletMd} {
        top: 8.8rem;
        left: 6.4rem;
      }
      i {
        animation-delay: 0.3s;
      }
      ${BulletContent} {
        left: 100%;
        transform-origin: top left;
      }
    }
    &:nth-child(2) {
      top: calc(8.4rem + 59%);
      left: calc(4.8rem + 7%);
      ${fromTabletMd} {
        top: calc(8.8rem + 59%);
      }
      i {
        animation-delay: 0.5s;
      }
      ${BulletContent} {
        left: 100%;
        transform-origin: top left;
      }
    }
    &:nth-child(3) {
      top: calc(8.4rem + 33%);
      left: calc(4.8rem + 70%);
      ${fromTabletMd} {
        left: calc(4.8rem + 45%);
      }
      i {
        animation-delay: 0.7s;
      }
      ${BulletContent} {
        ${uptoTabletMd} {
          right: 100%;
          transform-origin: top right;
          border-radius: 0.75em 0.25em 0.75em 0.75em;
        }
        ${fromTabletMd} {
          left: 100%;
          transform-origin: top left;
        }
      }
    }
  }
  ${fromDesktop} {
    &:nth-child(1) {
      top: 10rem;
      right: 100%;
      text-align: right;
    }
    &:nth-child(2) {
      top: 40rem;
      right: 100%;
      text-align: right;
    }
    &:nth-child(3) {
      top: 18rem;
      left: 100%;
      text-align: left;
    }
  }
`;

const RadarSetAndMailboxPositioner = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  ${onlyPhones} {
    transform-origin: bottom left;
    transform: scale(0.75);
  }
`;

const CranePositioner = styled.div`
  ${onlyPhones} {
    display: none;
  }
  position: absolute;
  top: 4rem;
  right: 100%;
`;

const CloudLeftPositioner = styled.div`
  position: absolute;
  bottom: calc(100% - 92px);
  ${onlyPhones} {
    transform-origin: 0% 66%;
    transform: scale(0.75);
    left: -48px;
  }
  ${fromTablet} {
    left: -18px;
  }
  > svg {
    &:nth-child(1) {
      position: relative;
    }
    &:nth-child(2) {
      position: absolute;
      bottom: 84px;
      left: 240px;
    }
    &:nth-child(3) {
      position: absolute;
      top: 170px;
      left: 52px;
    }
  }
`;

const CablesAndPylonsPositioner = styled.div`
  ${onlyPhones} {
    display: none;
  }
  position: absolute;
  bottom: 100%;
  left: 58px;
  right: 168px;
`;

const CablesLeftPositioner = styled.div`
  position: absolute;
  left: 0;
  width: ${`calc(${(332 / 702) * 100}%)`};
  bottom: 16px;
  svg {
    width: 100%;
    height: 54px;
  }
`;
const CableRightPositioner = styled.div`
  position: absolute;
  right: 0;
  width: ${`calc(${(372 / 702) * 100}%)`};
  bottom: 12px;
  svg {
    width: 100%;
    height: 27px;
  }
`;

const MiniPylonsPositioner = styled.div`
  position: absolute;
  left: ${`calc(${(332 / 702) * 100}% - 10px)`};
  bottom: 0;
`;

const MachineryPositioner = styled.div`
  position: absolute;
  top: -72px;
  right: -26px;
  ${onlyPhones} {
    transform-origin: 82% 33%;
    transform: scale(0.75);
  }
`;

const CloudRightAPositioner = styled.div`
  position: absolute;
  bottom: calc(100% + 13.375rem);
  left: 100%;
  ${onlyPhones} {
    display: none;
  }
`;
const CloudRightBPositioner = styled.div`
  position: absolute;
  bottom: calc(100% + 4rem);
  left: 80%;
`;

const DecoCollaborationGroup = styled.div`
  position: absolute;
  top: 12rem;
  left: calc(100% - 1.8rem);
`;

const CloudRightDPositioner = styled.div`
  position: absolute;
  left: calc(100% + 1.4rem);
  bottom: 0;
`;

const SpeechBubbles = styled.div`
  position: absolute;
  left: 5.5rem;
  bottom: 2.5rem;
`;

const SpeechBubbleBPositioner = styled.div`
  position: absolute;
  bottom: 2.5rem;
  left: 80%;
`;

const CloudTopLargePositioner = styled.div`
  position: absolute;
  top: 0;
  right: 6.75vw;
  > svg {
    transform: translateY(-82%);
  }
  ${onlyPhones} {
    display: none;
  }
`;
const CloudTopSmallLeftPositioner = styled.div`
  position: absolute;
  top: 20rem;
  left: 4.5rem;
  ${onlyPhones} {
    display: none;
  }
`;
const CloudTopSmallRightPositioner = styled.div`
  position: absolute;
  top: 10.5rem;
  right: 0;
  > svg {
    transform: translateX(15%);
  }
  ${onlyPhones} {
    display: none;
  }
`;

const NoodleGreenPositioner = styled.div`
  ${onlyPhones} {
    display: none;
  }
  position: absolute;
  left: calc(100% - 2rem);
  bottom: 6rem;
`;

const BottomRightCloudsPositioner = styled.div`
  ${onlyPhones} {
    display: none;
  }
  position: absolute;
  left: calc(100% - 1.25rem);
  bottom: 14rem;
`;

const CloudRightFPositioner = styled.div`
  position: absolute;
  left: 80%;
  bottom: -20%;
`;

const FlowerGroup = styled.div`
  position: absolute;
  top: 53%;
  right: 100%;
`;

const FlowerGroupCloudPositioner = styled.div`
  position: absolute;
  top: 53%;
  right: 100%;
  svg {
    transform: translate(1.5rem, -100%);
  }
`;

const FlowerVinesPositioner = styled.div`
  position: absolute;
  top: -6rem;
  right: -0.75rem;
`;

const FlowerLargePositioner = styled.div`
  position: relative;
  top: -1rem;
  right: -0.5rem;
`;
const FlowerSmallPositioner = styled.div`
  position: absolute;
  top: 52%;
  right: -0.875rem;
`;

const Bump1Positioner = styled.div`
  position: absolute;
  top: 9.75rem;
  right: calc(100% - 4px);
  ${uptoDesktop} {
    display: none;
  }
`;
const Bump2Positioner = styled.div`
  position: absolute;
  top: 47.5rem;
  right: calc(100% - 4px);
  ${uptoDesktop} {
    display: none;
  }
`;
const Bump3Positioner = styled.div`
  position: absolute;
  top: 17.2rem;
  left: calc(100% - 3px);
  ${uptoDesktop} {
    display: none;
  }
`;

const ProductPageHeroIllustration = () => {
  return (
    <ProductPageHeroIllustrationFigure>
      <PageTopDecoSet />
      <AppFramePositioner>
        <BackLayerDecoSet />
        <AppFrame>
          <Bump1Positioner>
            <BumpLeft />
          </Bump1Positioner>
          <Bump2Positioner>
            <BumpLeft />
          </Bump2Positioner>
          <Bump3Positioner>
            <BumpRight />
          </Bump3Positioner>

          <AppFrameInner>
            <MidLayerDecoSet />
            <ProductPageHeroAppContent />
          </AppFrameInner>
        </AppFrame>
        <TopLayerDecoSet />
        <ProductIlloCursorSet />
        <Bullets>
          <Bullet>
            <BulletPulse tabIndex={1}>
              <i />
              <b />
            </BulletPulse>
            <BulletContent>
              <h4>Actions</h4>
              <p>
                Stack and combine actions on the storyboard to build and run
                your workflows. Each action is purpose-built to handle any level
                of complexity without writing code.
              </p>
            </BulletContent>
          </Bullet>
          <Bullet>
            <BulletPulse tabIndex={2}>
              <i />
              <b />
            </BulletPulse>
            <BulletContent>
              <h4>Integrations</h4>
              <p>
                Integrate across your whole stack, including your internal
                tools. Start from scratch, from thousands of prebuilt templates
                or simply paste a cURL command on the canvas.
              </p>
            </BulletContent>
          </Bullet>
          <Bullet>
            <BulletPulse tabIndex={3}>
              <i />
              <b />
            </BulletPulse>
            <BulletContent>
              <h4>Collaboration</h4>
              <p>
                Determine when to bring people in the loop and collaborate
                comfortably with safeguards, like change control, to experiment
                freely.
              </p>
            </BulletContent>
          </Bullet>
        </Bullets>
      </AppFramePositioner>
    </ProductPageHeroIllustrationFigure>
  );
};

const ProductIlloCursorSetWrap = styled.div`
  ${MultiplayerCursorOuter} {
    filter: drop-shadow(1px 4px 0 ${withOpacity(colors.purple, 0.2)});
  }
`;

const Cursor1Positioner = styled.div`
  position: absolute;
  ${onlyPhones} {
    bottom: 4.5rem;
    right: calc(100% - 2.5rem);
  }
  ${fromTablet} {
    top: 55%;
    right: calc(100% + 16rem);
  }
  transform: scaleX(-1);
`;

const Cursor2Positioner = styled.div`
  position: absolute;
  top: 60%;
  left: 75%;
  ${fromTabletMd} {
    left: 50%;
    top: 60%;
  }
  ${fromDesktopMl} {
    left: 66%;
    top: 66%;
  }
`;

const Cursor3Positioner = styled.div`
  position: absolute;
  ${onlyPhones} {
    left: calc(100% - 5rem);
    bottom: 7rem;
  }
  ${fromTablet} {
    left: calc(100% + 9rem);
    top: 62%;
  }
`;

const Cursor4Positioner = styled.div`
  display: none;
  ${fromTablet} {
    position: absolute;
    left: calc(100% + 18rem);
    top: 22.5%;
  }
`;

const BumpLeft = () => (
  <svg width="20" height="78" viewBox="0 0 20 78" fill="none">
    <g clipPath="url(#clip0_399_12727)">
      <path
        d="M15.5 10.8372V-14C15.5 -15.1046 16.3954 -16 17.5 -16C18.6046 -16 19.5 -15.1147 19.5 -14.0101V92.5102C19.5 93.6147 18.6046 94.5 17.5 94.5C16.3954 94.5 15.5 93.6046 15.5 92.5V69.1628C15.5 63.8746 13.724 58.7396 10.4568 54.5814C3.7326 46.0233 3.7326 33.9767 10.4568 25.4186C13.724 21.2604 15.5 16.1254 15.5 10.8372Z"
        fill="#D7C4FA"
        stroke="#8D75E6"
      />
      <rect x="16" y="-3" width="4" height="85" fill="#D7C4FA" />
    </g>
    <defs>
      <clipPath id="clip0_399_12727">
        <rect width="20" height="78" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const BumpRight = () => (
  <svg width="20" height="78" viewBox="0 0 20 78" fill="none">
    <g clipPath="url(#clip0_399_12728)">
      <path
        d="M3.5 10.8372V-14C3.5 -15.1046 2.60457 -16 1.5 -16C0.39543 -16 -0.5 -15.1147 -0.5 -14.0101V92.5102C-0.5 93.6147 0.39543 94.5 1.5 94.5C2.60457 94.5 3.5 93.6046 3.5 92.5V69.1628C3.5 63.8746 5.27601 58.7396 8.54317 54.5814C15.2674 46.0233 15.2674 33.9767 8.54317 25.4186C5.27601 21.2604 3.5 16.1254 3.5 10.8372Z"
        fill="#D7C4FA"
        stroke="#8D75E6"
      />
      <rect
        width="4"
        height="85"
        transform="matrix(-1 0 0 1 3 -3)"
        fill="#D7C4FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_399_12728">
        <rect width="20" height="78" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ProductIlloCursorSet = () => {
  return (
    <ProductIlloCursorSetWrap>
      <Cursor1Positioner>
        <MultiplayerCursor color="orange" />
      </Cursor1Positioner>
      <Cursor2Positioner>
        <MultiplayerCursor color="purple" />
      </Cursor2Positioner>
      <Cursor3Positioner>
        <MultiplayerCursor color="green" />
      </Cursor3Positioner>
      <Cursor4Positioner>
        <MultiplayerCursor color="pink" />
      </Cursor4Positioner>
    </ProductIlloCursorSetWrap>
  );
};

const PageTopDecoSet = () => (
  <>
    <CloudTopLargePositioner>
      <CloudTopLarge />
    </CloudTopLargePositioner>
    <CloudTopSmallLeftPositioner>
      <CloudTopSmallLeft />
    </CloudTopSmallLeftPositioner>
    <CloudTopSmallRightPositioner>
      <CloudTopSmallRight />
    </CloudTopSmallRightPositioner>
  </>
);

const BackLayerDecoSet = () => (
  <>
    <CloudLeftPositioner>
      <CLoudLeftBack />
      <CloudLeftMid />
      <CloudLeftFront />
    </CloudLeftPositioner>
    <DecoCollaborationGroup>
      <CloudRightC />
      <CloudRightDPositioner>
        <CloudRightD />
      </CloudRightDPositioner>
      <SpeechBubbles>
        <SpeechBubbleA />
        <SpeechBubbleBPositioner>
          <SpeechBubbleB />
        </SpeechBubbleBPositioner>
      </SpeechBubbles>
    </DecoCollaborationGroup>
  </>
);

const MidLayerDecoSet = () => (
  <>
    <CablesAndPylonsPositioner>
      <CablesLeftPositioner>
        <CablesLeft />
      </CablesLeftPositioner>
      <CableRightPositioner>
        <CableRight />
      </CableRightPositioner>
      <MiniPylonsPositioner>
        <MiniPylons />
      </MiniPylonsPositioner>
    </CablesAndPylonsPositioner>
    <RadarSetAndMailboxPositioner>
      <RadarSetAndMailbox />
    </RadarSetAndMailboxPositioner>
    <CranePositioner>
      <Crane />
    </CranePositioner>
    <MachineryPositioner>
      <Machinery />
      <CloudRightAPositioner>
        <CloudRightA />
      </CloudRightAPositioner>
      <CloudRightBPositioner>
        <CloudRightB />
      </CloudRightBPositioner>
    </MachineryPositioner>
    <NoodleGreenPositioner>
      <NoodleGreen />
    </NoodleGreenPositioner>
    <FlowerGroup>
      <FlowerVinesPositioner>
        <FlowerVines />
      </FlowerVinesPositioner>
      <FlowerLargePositioner>
        <FlowerLarge />
      </FlowerLargePositioner>
      <FlowerSmallPositioner>
        <FlowerSmall />
      </FlowerSmallPositioner>
    </FlowerGroup>
  </>
);

const TopLayerDecoSet = () => (
  <>
    <BottomRightCloudsPositioner>
      <CloudRightE />
      <CloudRightFPositioner>
        <CloudRightF />
      </CloudRightFPositioner>
    </BottomRightCloudsPositioner>
    <FlowerGroupCloudPositioner>
      <FlowerGroupCloud />
    </FlowerGroupCloudPositioner>
  </>
);

export default ProductPageHeroIllustration;
