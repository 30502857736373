import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  fromDesktopMl,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = PropsWithChildren<{
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  accentColor?: string;
}>;

const OrderedListCardGridOl = styled.ol<{
  backgroundColor?: string;
  textColor?: string;
  accentColor?: string;
}>`
  display: grid;
  grid-gap: ${rSize("gap")};
  padding: 0;
  margin: 0;
  list-style: none;
  counter-reset: key-finding-list;
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  li {
    background-color: ${p => p.backgroundColor ?? colorsV4.white};
    color: ${p => p.textColor ?? colorsV4.warmBlack};
    counter-increment: key-finding-list;
    padding: 1.5em 1em;
    text-align: centered;
    border-radius: 1em;
    ${fromDesktopMl} {
      padding: 1.5em;
    }
    ${fromTablet} {
      text-align: left;
      &.fullWidth {
        grid-column-end: span 2;
      }
    }
    &:before {
      content: counter(key-finding-list);
      background-color: ${p => p.accentColor ?? colorsV4.purple};
      color: ${colorsV4.white};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.75em;
      width: 2.5em;
      height: 2.5em;
      font-weight: 700;
      text-align: center;
      border-radius: 50%;
      ${onlyPhones} {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  h4 {
    font-size: 1.8rem;
    color: ${p => p.accentColor ?? colorsV4.purple};
    line-height: 1.2;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 1.4rem;
  }
`;

const NumberedListCardGrid = (props: Props) => {
  return (
    <OrderedListCardGridOl
      className={props.className}
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      accentColor={props.accentColor}
    >
      {props.children}
    </OrderedListCardGridOl>
  );
};

export default NumberedListCardGrid;
