import { cx } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { RefObject } from "react";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  PrimaryColorName,
  brandColorThemeVar,
  color,
} from "../../styles/colors.styles";
import { UnknownObject, UseStateReturnType } from "../../types/helper.types";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import { isNotNil } from "../../utils/typeChecks.utils";
import { SelectWrapper } from "./Select";

type InputLikeAppearance = "default";

export type InputLikeProps<T> = {
  name?: (keyof T & string) | string;
  formState?: UseStateReturnType<T>;
  appearance?: InputLikeAppearance;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  hasError?: boolean;
  backgroundColor?: string | PrimaryColorName;
  textColor?: string | PrimaryColorName;
  height?: number | string;
  padding?: number | string;
  border?: string;
  hoverStyle?: {
    border?: string;
    backgroundColor?: string | PrimaryColorName;
    textColor?: string | PrimaryColorName;
  };
  spellCheck?: "true" | "false";
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  borderRadius?: string | number;
  focusRingColor?: string | PrimaryColorName;
  onEnter?: (value: string) => void;
  onValueChange?: (newValue?: string | null, prevValue?: string | null) => void;
};

export type TextInputProps<T> = InputLikeProps<T> &
  React.InputHTMLAttributes<HTMLInputElement> & {
    innerRef?: RefObject<HTMLInputElement>;
  };

export const TextInputStyled = styled.input<
  Omit<TextInputProps<UnknownObject>, "formState">
>`
  background-color: ${p => color(p.backgroundColor ?? "transparent")};
  color: ${p => color(p.textColor ?? "inherit")};
  width: ${p => valueWithOptionalUnit(p.width, "100%")};
  min-width: ${p => valueWithOptionalUnit(p.minWidth, "unset")};
  max-width: ${p => valueWithOptionalUnit(p.maxWidth, "unset")};
  height: ${p => valueWithOptionalUnit(p.height, "3em")};
  padding: ${p => valueWithOptionalUnit(p.padding, "0 1em")};
  border: ${p => p.border ?? `1px solid ${brandColorThemeVar(200)}`};
  border-radius: ${p => valueWithOptionalUnit(p.borderRadius, `.5em`)};
  outline-color: ${p => color(p.focusRingColor ?? brandColorThemeVar(500))};
  font-size: 1.6rem;
  ${fromTablet} {
    font-size: 1.4rem;
  }
  &:hover {
    background-color: ${p =>
      color(
        p.hoverStyle?.backgroundColor ?? p.backgroundColor ?? "transparent"
      )};
    border: ${p =>
      p.hoverStyle?.border ??
      p.border ??
      `1px solid ${brandColorThemeVar(300)}`};
    color: ${p => color(p.hoverStyle?.textColor ?? p.textColor ?? "inherit")};
  }
  &::placeholder {
    color: ${p => color(p.textColor ?? "inherit")};
    opacity: 0.6;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function TextInput<T>(props: TextInputProps<T>) {
  const {
    appearance = "default",
    hasError = props.hasError,
    className,
    formState,
    onEnter,
    innerRef,
    // eslint-disable-next-line unused-imports/no-unused-vars
    onValueChange,
    ...restOfProps
  } = props;
  if (
    formState?.[0] &&
    typeof formState[0][props.name as keyof typeof formState[0]] === "undefined"
  ) {
    Reflect.set(formState[0] as UnknownObject, props.name as string, "");
  }
  const controlProps =
    formState && props.name && props.name in (formState[0] as unknown as object)
      ? formInputControls(formState, props.name as keyof T, props)
      : null;
  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    const value = `${formState?.[0][props.name as keyof T]}` ?? "";
    if (e.key === "Enter") onEnter?.(value);
    props.onKeyUp?.(e);
  };
  return (
    <TextInputStyled
      ref={innerRef}
      className={cx(className, appearance, hasError ? "error" : "")}
      {...controlProps}
      {...restOfProps}
      onKeyUp={handleKeyUp}
    />
  );
}

export const formInputControls = <T, K extends keyof T = keyof T>(
  formState: UseStateReturnType<T>,
  key: K,
  props: InputLikeProps<T>
) => {
  const prevValue = formState[0][key];
  const prevValueFormatted =
    prevValue !== undefined
      ? `${prevValue}`
      : prevValue === null
      ? ""
      : prevValue;
  return {
    value: prevValueFormatted as string | undefined,
    onChange: (
      e: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const newValue = (
        e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      ).value;
      formState[1]({
        ...formState[0],
        [key]: newValue,
      });
      // TODO: null / undefined situations?
      props.onValueChange?.(
        (isNotNil(newValue) ? `${newValue}` : newValue) as
          | string
          | null
          | undefined,
        (isNotNil(prevValueFormatted)
          ? `${prevValueFormatted}`
          : prevValueFormatted) as string | null | undefined
      );
    },
  };
};

export const TextInputSet = styled.fieldset`
  margin: 0;
  padding: 0;
  border-radius: 1em;
  border: 0;
  > input,
  > ${SelectWrapper} {
    display: block;
    width: 100%;
    position: relative;
    border-radius: 0;
    margin-top: -1px;
    &:hover,
    &:focus {
      z-index: 1;
    }
    &:first-child {
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
    &:last-child {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }
  }
  > ${SelectWrapper} {
    select {
      &:hover,
      &:focus {
        z-index: 1;
      }
    }
  }
  ${fromDesktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    > input,
    > ${SelectWrapper} {
      &:nth-child(1) {
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0;
      }
      &:nth-child(2) {
        border-top-left-radius: 0;
        border-top-right-radius: 0.5em;
      }
      &:nth-last-child(1) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0.5em;
      }
      &:nth-last-child(2) {
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0;
      }
      &:nth-child(odd) {
        margin-right: -1px;
      }
      &:nth-child(even) {
        margin-left: -1px;
      }
    }
  }
`;

export default TextInput;
