export const LogoKayak = () => (
  <svg width="125" height="24" viewBox="0 0 125 24" fill="currentColor">
    <title>Kayak</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.936 24H0V0H23.936V24ZM49.2021 24H25.2661V0H49.2021V24ZM50.5322 24H74.4678V0H50.5322V24ZM99.7339 24H75.7979V0H99.7339V24ZM101.064 24H125V0H101.064V24ZM10.2871 6V11.549L13.3477 6H16.5557L13.2261 12L16.5557 18H13.3477L10.2871 12.452V18H7.37988V6H10.2871ZM34.5518 18L35.0718 16.273H39.3066L39.8267 18H43.021L39.0908 6H35.395L31.4478 18H34.5518ZM35.7891 13.916L37.189 9.43597L38.5889 13.916H35.7891ZM61.0737 18H63.98V12.71L67.856 6H64.4468L62.5449 9.68799L60.6069 6H57.144L61.0737 12.71V18ZM85.084 18L85.604 16.273H89.8389L90.3589 18H93.5527L89.623 6H85.9268L81.979 18H85.084ZM86.3208 13.916L87.7207 9.43597L89.1211 13.916H86.3208ZM111.351 6V11.549L114.412 6H117.62L114.29 12L117.62 18H114.412L111.351 12.452V18H108.444V6H111.351Z"
    />
  </svg>
);
