export const RocketTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      opacity="0.2"
      d="M6.5 10.5H5.80278C5.60535 10.5 5.41234 10.5584 5.24808 10.6679L4.25192 11.3321C4.08766 11.4416 3.89465 11.5 3.69722 11.5H2.78078C2.32191 11.5 1.92193 11.8123 1.81063 12.2575L0.904226 15.8831C0.721131 16.6155 1.38452 17.2789 2.1169 17.0958L5.74254 16.1894C6.1877 16.0781 6.5 15.6781 6.5 15.2192V14.3028C6.5 14.1054 6.55844 13.9123 6.66795 13.7481L7.33205 12.7519C7.44156 12.5877 7.5 12.3946 7.5 12.1972V11.5C7.5 10.9477 7.05228 10.5 6.5 10.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M5.5 13.5L7.1326 12.2172C7.36456 12.035 7.5 11.7563 7.5 11.4613C7.5 10.9304 7.0696 10.5 6.53867 10.5C6.24368 10.5 5.96502 10.6354 5.78276 10.8674L4.5 12.5L3.5 14C3.2522 14.3304 3.6696 14.7478 4 14.5L5.5 13.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3535 3.35355C15.5488 3.15828 15.5488 2.8417 15.3535 2.64644C15.1583 2.45118 14.8417 2.45119 14.6464 2.64645L14.1467 3.1462C13.9509 3.05249 13.7316 3.00002 13.5 3.00002H12.7426C11.8144 3.00002 10.9241 3.36876 10.2678 4.02514L8.29289 6.00002L6.18497 6.00002C5.94626 6.00002 5.71542 6.08541 5.53418 6.24076L3.20085 8.24074C2.49595 8.84494 2.92323 10 3.85164 10L5.29292 10C5.55813 10 5.81249 9.89464 6.00002 9.70711L6.06035 9.64678C6.13185 9.79645 6.22958 9.93671 6.35355 10.0607L6.7929 10.5L5.64645 11.6465C5.45119 11.8417 5.45119 12.1583 5.64645 12.3536C5.84171 12.5488 6.15829 12.5488 6.35356 12.3536L7.5 11.2071L7.93933 11.6465C8.0633 11.7704 8.20358 11.8682 8.35325 11.9397L8.29289 12C8.10535 12.1876 7.99999 12.4419 7.99999 12.7071L7.99999 14.1484C7.99999 15.0768 9.15505 15.5041 9.75925 14.7992L11.7593 12.4658C11.9146 12.2846 12 12.0537 12 11.815L12 9.70712L13.9749 7.73224C14.6312 7.07587 15 6.18564 15 5.25739L15 4.50002C15 4.26844 14.9475 4.04912 14.8538 3.8533L15.3535 3.35355ZM7.29289 7.00002L6.35355 7.93936C6.35219 7.94072 6.35083 7.94208 6.34948 7.94344L5.29292 9L3.85164 9L6.18497 7.00002L7.29289 7.00002ZM7.05834 8.64879C6.86539 8.84423 6.86617 9.15908 7.06065 9.35357L7.5 9.79292L9.64643 7.64648C9.84169 7.45122 10.1583 7.45122 10.3535 7.64648C10.5488 7.84174 10.5488 8.15832 10.3535 8.35358L8.20711 10.5L8.64644 10.9394C8.8417 11.1346 9.15829 11.1346 9.35355 10.9394L13.2678 7.02514C13.7366 6.5563 14 5.92042 14 5.25738L14 4.50002C14 4.22387 13.7761 4.00002 13.5 4.00002H12.7426C12.0796 4.00002 11.4437 4.26341 10.9749 4.73225L9.7071 6.00002H9.70711L8.85356 6.85357L7.05834 8.64879ZM11 10.7071L8.99999 12.7071L8.99999 14.1484L11 11.815L11 10.7071Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M7.70711 9.29291L10.7929 6.20711C11.1834 5.81658 11.8166 5.81658 12.2071 6.20711L13.7929 7.79289C14.1834 8.18342 14.1834 8.81658 13.7929 9.20711L10.7071 12.2929C10.3166 12.6834 9.68342 12.6834 9.29289 12.2929L7.70711 10.7071C7.31658 10.3166 7.31658 9.68343 7.70711 9.29291Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="2.5" cy="11.5" r="0.5" fill="currentColor" />
    <rect
      opacity="0.4"
      x="14.4142"
      y="12"
      width="2"
      height="2"
      transform="rotate(45 14.4142 12)"
      fill="currentColor"
    />
  </svg>
);
