import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { isBrowser, isProduction } from "./environment";

if (isBrowser && isProduction)
  Sentry.init({
    dsn: "https://3f5d5c49f94c4baa95bfb00077cb01c1@o357543.ingest.sentry.io/6054936",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

export function initSentry() {}
