import { colors } from "../../../styles/colors.styles";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import {
  ReportV2FullDarkSectionNavControlPoint,
  ReportV2NavPurpleControlPoint,
} from "../components/ReportV2CommonNavControlPoints";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import NoWrap from "../../typography/Nowrap";
import Spacing from "../../layout/Spacing";
import { ReportV2Heading4 } from "../components/ReportV2Heading4";
import ReportV2ContentCard, {
  ReportV2ContentCardGrid,
} from "../components/ReportV2ContentCard";
import AllCaps from "../../typography/AllCaps";
import { SuccessCakeIcon } from "./icons/SuccessCakeIcon";
import { LadderToSky } from "../soc2023/icons/LadderToSky";
import { Sans } from "../../typography/Sans";
import { ReportV2Heading5 } from "../components/ReportV2Heading5";
import { LogoOakRidgeAlt } from "../../thirdPartyLogos/LogoOakRidgeAlt";
import Button from "../../forms/Button";
import { StepsToFlagIcon } from "./icons/StepsToFlagIcon";
import { ChessboardIcon } from "./icons/ChessboardIcon";
import { ReportV2BeforeAfterSplit } from "../components/ReportV2BeforeAfterSplit";
import {
  ReportV2Blockquote,
  ReportV2BlockquoteBody,
} from "../components/ReportV2Blockquote";
import { AlignCenterOnMobile } from "../../typography/Align";
import { LogoMars40h } from "../../thirdPartyLogos/LogoMars40h";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import { NegativeIndentLeftP } from "../../typography/NegativeIndentLeft";

export const GuideSecurityChapter2 = () => {
  return (
    <section>
      <ReportV2FullDarkSectionNavControlPoint />

      <ReportV2ChapterHeaderSection
        id="embracing-workflow-automation"
        color="purple"
        chapterNumber={2}
        icon={<LadderToSky />}
      >
        <h2>
          <span>Embracing</span> <span>workflow automation</span>
        </h2>
        <ReportV2TextBlock largerText>
          <p>
            In thinking about a more sustainable, scalable future for your team,
            automation is going to be the most important strategy you could
            implement. When your team members have the tools they need to
            automate repetitive tasks, they’re not only creating more efficient
            workflows for everyday processes, they’re freeing up their time to
            do what they love, whether that’s security analysis or compliance
            management.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2NavPurpleControlPoint />

      <ReportV2Section
        id="setting-your-teams-up-for-success"
        backgroundColor={colors.lightest}
        color={colors.purple800}
      >
        <ReportV2SectionHeader icon={<SuccessCakeIcon />}>
          <ReportV2SectionHeadingMedium>
            Setting your teams up <NoWrap>for success</NoWrap>
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />
        <ReportV2Heading4 className="centeredOnMobile">
          Getting started
        </ReportV2Heading4>
        <Spacing size="lg" />

        <ReportV2ContentCard backgroundColor={colors.purple50} largerText>
          <ReportV2Heading5>
            Start small and experiment with core use cases
          </ReportV2Heading5>
          <p>
            Leaders often fear they don’t have the bandwidth or tooling in place
            to fully leverage automation. Or, if they adopt automation, they
            want to automate all their processes immediately. Cloud-based
            workflow automation platforms offer you the freedom to start small
            with free community editions or trials and grow as your business
            needs evolve. You can start with a set of core use cases and
            demonstrate their value before expanding.
          </p>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard backgroundColor={colors.purple50} largerText>
          <ReportV2Heading5>
            Think about whether the time is right to build an internal SOC
          </ReportV2Heading5>
          <p>
            The days of having a SOC as a standalone team responsible for
            security are coming to a close. Not only have their costs risen in
            recent years, but their complexity has too.
          </p>
          <p>
            As organizations recognize that security touches everyone, security
            professionals will be embedded into each team and department.
            Security leaders should consider whether the investment required in
            building a SOC would be better spent elsewhere, like implementing
            automation, embracing AI in workflow automation, or developing more
            iterative approaches to security. While SOCs are phased out, an
            outsourced SOC or MSSP could provide an interim solution.
          </p>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard backgroundColor={colors.purple50} largerText>
          <ReportV2Heading5>Invest in best-in-breed solutions</ReportV2Heading5>
          <p>
            According to the latest research,{" "}
            <a
              href="https://blog.checkpoint.com/security/average-weekly-global-cyberattacks-peak-with-the-highest-number-in-2-years-marking-an-8-growth-year-over-year-according-to-check-point-research/"
              {...externalLinkAttr}
            >
              organizations experience an average of 1,258 attacks per week
            </a>
            . Threats are frequent and pervasive, and are becoming too
            sophisticated to be handled by one-size-fits-all solutions.
            Organizations wanting to stay ahead in their security approach need
            to un-bundle their stacks and all-in-one “big box shops” and invest
            in best-of-breed security tools designed for specific purposes.
          </p>
          <p>
            Thanks to workflow automation, the overhead costs associated with
            managing best-in-breed solutions are no longer a hindrance to
            investment. Since the best workflow builders connect to both known
            and custom tools, automation reduces the risk of fragmentation.
          </p>
        </ReportV2ContentCard>

        <Spacing size="xl" />

        <ReportV2Heading4 className="centeredOnMobile">
          Finding Success
        </ReportV2Heading4>
        <Spacing size="lg" />

        <ReportV2ContentCard backgroundColor={colors.purple50} largerText>
          <ReportV2Heading5>Embrace cross-team collaboration</ReportV2Heading5>
          <p>
            The most successful organizations are the ones in which teams
            collaborate and communicate effectively across security, IT and
            beyond.
          </p>
          <p>
            I witnessed this first-hand as the leader of security teams. They’re
            tasked with performing remediations for their organization, yet much
            of that work is often found on software that the security team has
            no direct control over. Security teams then find themselves
            dependent on other teams for their own success – teams whose
            priorities may not align with the priorities of SecOps. This
            misalignment and limited access can allow vulnerabilities to remain
            unaddressed.
          </p>
          <p>
            Security teams need to find ways to work with other departments to
            gain access, which will not only require relationship-building and
            trust, but the ability to adequately communicate why SecOps needs
            access to those systems. Other departments may be reluctant to grant
            open-ended, manual access, but may be more willing to allow access,
            provided it is only used in automated scenarios with known inputs
            and outputs.
          </p>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard backgroundColor={colors.purple50} largerText>
          <ReportV2Heading5>Build a culture of automation</ReportV2Heading5>
          <p>
            To see continued success, security leaders need to promote a culture
            of automation across their teams. This means that, any time team
            members are faced with an inefficient, time-consuming, or monotonous
            process, their first thought is, “Can we automate some or all of
            this?”
          </p>

          <p>
            A culture of automation will get teams used to recognizing where
            they can make their tools work harder for them and allow them to
            shift their focus to more pressing matters.
          </p>
          <p>Leaders can start by doing the following:</p>
          <ol>
            <li>
              Annotate as you build your automated workflows so that colleagues
              can understand how the workflow executes.
            </li>
            <li>
              Extract the shared sequences that begin to pop up repeatedly into
              modules that can be reused across your workflows.
            </li>
            <li>
              Set up monitoring to ensure that when something does break, a
              human gets notified.
            </li>
            <li>
              Continuously improve upon your workflows, and think creatively
              about what can make the workflow run faster.
            </li>
            <li>Demonstrate the value you find in automation to leadership.</li>
          </ol>
        </ReportV2ContentCard>
      </ReportV2Section>

      <ReportV2Section
        id="case-study-oak-ridge-national-laboratory"
        backgroundColor={colors.green100}
        color={colors.green800}
      >
        <ReportV2SectionHeader icon={<LogoOakRidgeAlt />}>
          <ReportV2SectionHeadingMedium>
            <span>Case study</span>
            <Sans>Oak Ridge National Laboratory</Sans>
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />
        <ReportV2TextBlock limitWidth largerText centerOnMobile>
          <p>
            A federally-funded organization and pioneer in technological
            advancement, Oak Ridge National Laboratory used workflow automation
            platform Tines to accelerate their zero trust goals and strengthen
            their security posture.
          </p>
        </ReportV2TextBlock>

        <Spacing size="lg" />

        <ReportV2BeforeAfterSplit>
          <ReportV2ContentCard
            backgroundColor={colors.green800}
            color={colors.white}
            largerText
          >
            <div>
              <AllCaps as="h5">Before Tines</AllCaps>
              <ul>
                <li>Managing automation through manual scripts</li>
                <li>Only engineers could build, run or monitor workflows</li>
                <li>Long waiting periods</li>
                <li>No automated reports</li>
              </ul>
            </div>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.green}
            color={colors.white}
            largerText
          >
            <div>
              <AllCaps as="h5">After Tines</AllCaps>
              <ul>
                <li>
                  Managing automation through a flexible, scalable workflow
                  platform
                </li>
                <li>Anyone on the team can build, run and monitor workflows</li>
                <li>No waiting period</li>
                <li>
                  Automated reporting leading to improved visibility and time
                  savings
                </li>
              </ul>
            </div>
          </ReportV2ContentCard>
        </ReportV2BeforeAfterSplit>

        <Spacing size="xl" />

        <ReportV2TextBlock limitWidth largerText centerOnMobile>
          <AllCaps as="p">Key workflow</AllCaps>
          <ReportV2Heading4>IP analysis</ReportV2Heading4>
          <p>
            Analysts at Oak Ridge National Laboratory gather network information
            from databases via their APIs and update Tenable with over two
            million IP addresses in a matter of seconds. This helps the team
            evaluate and break down those IP addresses for vulnerability,
            evaluate the data, and then push it to Tenable. To comb through this
            volume of IP addresses manually, before Tines, was not even a
            possibility.
          </p>
          <p>
            <strong>In their own words:</strong>
          </p>
          <ReportV2Blockquote className="green">
            <ReportV2BlockquoteBody>
              <NegativeIndentLeftP>
                “It was a game changer during onboarding when we could connect
                all our systems... Our ability to integrate new tools has taken
                out so much of our everyday tasks from before.”
              </NegativeIndentLeftP>
            </ReportV2BlockquoteBody>
            <footer>
              <p>
                {/* cspell:disable */}– <strong>Mike Crider</strong>, Cyber
                Vulnerability Analyst
                {/* cspell:enable */}
              </p>
            </footer>
          </ReportV2Blockquote>
        </ReportV2TextBlock>

        <Spacing size="lg" />

        <AlignCenterOnMobile>
          <Button
            href="/case-studies/oak-ridge-national-laboratory"
            color="green"
            darker
            appearance="outlined"
            {...externalLinkAttr}
          >
            Read the full case study <sup>↗</sup>
          </Button>
        </AlignCenterOnMobile>
      </ReportV2Section>

      <ReportV2Section
        id="step-by-step-guide"
        backgroundColor={colors.lightest}
        color={colors.purple800}
      >
        <ReportV2SectionHeader iconAlign="center" icon={<StepsToFlagIcon />}>
          <ReportV2SectionHeadingMedium>
            Step-by-step guide
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />

        <ReportV2ContentCard
          backgroundColor={colors.purple50}
          color={colors.purple800}
          largerText
        >
          <AllCaps as="p">Step 1</AllCaps>
          <ReportV2Heading4>Outline your goals</ReportV2Heading4>
          <p>
            Define the specific objectives you want to achieve through workflow
            automation. This could include increasing efficiency, mitigating
            risk, or effectively scaling your processes.
          </p>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard
          backgroundColor={colors.purple50}
          color={colors.purple800}
          largerText
        >
          <AllCaps as="p">Step 2</AllCaps>
          <ReportV2Heading4>Find internal champions</ReportV2Heading4>
          <p>
            This is critical to the success of your automation program as you
            pursue buy-in from key stakeholders.
          </p>
          <p>You can achieve this by:</p>
          <ul>
            <li>
              Identifying teammates who can advocate for the use of a workflow
              builder
            </li>
            <li>Hosting knowledge-sharing sessions</li>
            <li>
              Finding joint automation opportunities through cross-functional
              collaboration
            </li>
            <li>Recognizing and rewarding builders for their achievements</li>
          </ul>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard
          backgroundColor={colors.purple50}
          color={colors.purple800}
          largerText
        >
          <AllCaps as="p">Step 3</AllCaps>
          <ReportV2Heading4>Evaluate your options</ReportV2Heading4>
          <p>
            As you begin searching for the right platform, look for vendors who
            are experienced in supporting your specific use cases. For example,
            if you spend most of your time following up on suspicious logins,
            and they don't have examples to share, take a look elsewhere.
          </p>

          <p>
            Additionally, ask how the platform integrates with your in-house
            APIs. Legacy SOAR platforms typically feature pre-baked
            integrations, but only for a limited number of popular tools. Seek
            out a platform that can integrate with all of your organization’s
            tools, no matter how niche or custom they may be.
          </p>

          <p>Five things to look for in a security workflow platform:</p>
          <ul>
            <li>
              The ability to collect information from anyone – not just your
              teammates – at any point in the workflow run
            </li>
            <li>
              A low barrier to entry – the more team members building, the
              better for everyone
            </li>
            <li>
              Intuitive UX – a user-friendly interface that accelerates build
              time
            </li>
            <li>
              Deployable AI-powered capabilities – they should be secure,
              private, intuitive, and deliver return on investment
            </li>
            <li>
              Flexibility – a platform that can connect to all of your tools,
              internal and external{" "}
            </li>
          </ul>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard
          backgroundColor={colors.purple50}
          color={colors.purple800}
          largerText
        >
          <AllCaps as="p">Step 4</AllCaps>
          <ReportV2Heading4>Run a POC process</ReportV2Heading4>
          <p>
            When it comes time to demo, don't pick a simplified workflow, but
            ask the vendor to run a more complex one that closely mimics the
            types of tasks you want to automate – a good vendor will be excited
            by the challenge!
          </p>

          <p>
            Platforms should be robust enough to automate complex, lengthy
            workflows, yet many of the platforms that sell themselves as
            “powerful” have surprisingly low operational limits. Leverage free
            community editions and trials to put platforms to the test.
          </p>

          <p>
            Evaluate AI-powered capabilities with extra scrutiny – in their
            haste to ride the AI wave, some vendors have shipped demoware. Be
            sure the vendor’s capabilities are deployable, and look closely at
            how costs will be incurred. AI in workflow automation should be
            secure, private, and intuitive.
          </p>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard
          backgroundColor={colors.purple50}
          color={colors.purple800}
          largerText
        >
          <AllCaps as="p">Step 5</AllCaps>
          <ReportV2Heading4>
            Purchase the best tool for the best price
          </ReportV2Heading4>
          <p>
            As you explore options, consider the pricing model (e.g. data
            ingestion or storage rates) and not just the price tag to get
            started. And be sure to ask how pricing will change as usage
            increases, as many vendors obscure their pricing structure.
          </p>

          <p>
            Committing to workflow automation means scaling the number, size,
            and throughput of workflows, and you need to know what you can
            expect to pay. Look for a model that will encourage as many team
            members as possible to build automated workflows, without worrying
            about hitting a data cap or a user license limit.
          </p>
        </ReportV2ContentCard>

        <Spacing size="md" />

        <ReportV2ContentCard
          backgroundColor={colors.purple50}
          color={colors.purple800}
          largerText
        >
          <AllCaps as="p">Step 6</AllCaps>
          <ReportV2Heading4>Build workflows iteratively</ReportV2Heading4>
          <p>
            Once you have your workflow automation platform up and running, the
            best approach is to start small with prototypes and MVPs, and then
            keep evolving the complexity.
          </p>

          <p>
            Deploy the simplest usable version to production first, and then
            expand workflows little by little to cover edge and corner cases.
            This allows builders to become more creative with their automation,
            building more sophisticated processes as they go.
          </p>

          <p>
            Because of the accessibility of workflow automation, security teams
            can maintain and evolve their workflows in production, and iterate
            those workflows as their company’s processes and threats
            continuously change. One thing to remember is not to price the
            maintenance of automation at zero. Even if it’s built flawlessly the
            first time around – which is rare – external context will always
            change, necessitating future iteration.
          </p>
        </ReportV2ContentCard>
      </ReportV2Section>

      <ReportV2Section
        id="case-study-mars"
        backgroundColor={colors.purple100}
        color={colors.purple800}
      >
        <ReportV2SectionHeader icon={<LogoMars40h />} iconAlign="center">
          <ReportV2SectionHeadingMedium>
            <span>Case study</span>
            <Sans>Mars</Sans>
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />
        <ReportV2TextBlock limitWidth largerText centerOnMobile>
          <p>
            Mars is a global company and family-owned business with the
            footprint of a small country. Their security team looked for a new
            workflow platform to replace an underperforming SOAR tool, which,
            frustratingly, only one team member could use.
          </p>
        </ReportV2TextBlock>

        <Spacing size="lg" />

        <ReportV2BeforeAfterSplit>
          <ReportV2ContentCard
            backgroundColor={colors.purple800}
            color={colors.white}
            largerText
          >
            <div>
              <AllCaps as="h5">Before Tines</AllCaps>
              <ul>
                <li>Only one team member could use their SOAR</li>
                <li>
                  Relying on that one team member put them at greater risk
                </li>
                <li>A large library of workflows needed streamlining</li>
              </ul>
            </div>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.purple}
            color={colors.white}
            largerText
          >
            <div>
              <AllCaps as="h5">After Tines</AllCaps>
              <ul>
                <li>
                  Everyone on the team can build, run, and monitor workflows
                </li>
                <li>Fully migrated all workflows in a couple of months</li>
                <li>Consolidated, efficient workflows</li>
                <li>Faster workflow build times</li>
                <li>Coverage of 80-90% of sources for true positives</li>
              </ul>
            </div>
          </ReportV2ContentCard>
        </ReportV2BeforeAfterSplit>

        <Spacing size="xl" />

        <ReportV2TextBlock limitWidth largerText centerOnMobile>
          <AllCaps as="p">Key workflow</AllCaps>
          <ReportV2Heading4>Alert triage and escalation</ReportV2Heading4>
          <p>
            One of the security team’s key workflows receives alerts from the
            cloud security platform Wiz, triages them and assigns it to the
            right team in ServiceNow. This workflow includes a structured
            escalation path, ensuring that high-priority issues are promptly
            addressed and managed effectively.
          </p>
          <p>
            <strong>In their own words:</strong>
          </p>
          <ReportV2Blockquote className="purple">
            <ReportV2BlockquoteBody>
              <NegativeIndentLeftP>
                “Because of the flexibility of the Tines platform, we were able
                to consolidate use cases.”
              </NegativeIndentLeftP>
            </ReportV2BlockquoteBody>
            <footer>
              <p>
                {/* cspell:disable */}– <strong>Gregory Poniatowski</strong>,
                Director of Cyber Threat and Vulnerability
                {/* cspell:enable */}
              </p>
            </footer>
          </ReportV2Blockquote>
        </ReportV2TextBlock>

        <Spacing size="lg" />

        <AlignCenterOnMobile>
          <Button
            href="/case-studies/mars"
            color="purple"
            darker
            appearance="outlined"
            {...externalLinkAttr}
          >
            Read the full case study <sup>↗</sup>
          </Button>
        </AlignCenterOnMobile>
      </ReportV2Section>

      <ReportV2Section
        id="workflow-best-practices"
        backgroundColor={colors.purple}
        color={colors.white}
      >
        <ReportV2SectionHeader icon={<ChessboardIcon />}>
          <ReportV2SectionHeadingMedium>
            Workflow best practices
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>

        <Spacing size="xl" />

        <ReportV2ContentCardGrid>
          <ReportV2ContentCard backgroundColor={colors.purple600}>
            <div>
              <h4>Modularize</h4>
              <Spacing />
              <p>
                As you build more workflows, you’ll inevitably repeat certain
                steps, for example, “post a message to the team Slack in this
                format.” It’s a good idea to extract shared sequences so that
                they can be reused across other workflows.
              </p>
            </div>
          </ReportV2ContentCard>

          <ReportV2ContentCard backgroundColor={colors.purple600}>
            <div>
              <h4>Engage</h4>
              <Spacing />
              <p>
                You’re going to need a way for people on your team and outside
                of your team to easily interact with your workflows. Sometimes
                you’ll want them to kick off a workflow, for example by
                providing information on a suspicious email. Other times, you’ll
                need them to answer a question mid-workflow, for example, to
                approve or deny a user’s tool access. Features like Tines Pages
                help you seamlessly collect information from anyone at multiple
                stages of a workflow run.
              </p>
            </div>
          </ReportV2ContentCard>

          <ReportV2ContentCard backgroundColor={colors.purple600}>
            <div>
              <h4>Monitor</h4>
              <Spacing />
              <p>
                Some of your workflows will be mission-critical, and so, it’s
                important to be aware when something unexpected happens. Set up
                monitoring to make sure somebody gets notified when any of your
                tools within a workflow has an issue – for example, the API for
                an upstream system is down, a credential expires, or no alerts
                have been received recently.
              </p>
            </div>
          </ReportV2ContentCard>

          <ReportV2ContentCard backgroundColor={colors.purple600}>
            <div>
              <h4>Evolve</h4>
              <Spacing />
              <p>
                The best workflows are continuously improved. From time to time,
                analyze a random workflow run and creatively think about what
                could make the task faster or the outputs more useful. If your
                platform allows you to run an LLM within the context of your
                workflow, ask whether AI can be used to work faster or more
                efficiently?
              </p>
            </div>
          </ReportV2ContentCard>

          <ReportV2ContentCard backgroundColor={colors.purple600}>
            <div>
              <h4>Communicate value</h4>
              <Spacing />
              <p>
                After investing in workflow automation, it’s important to
                demonstrate value to leadership. For previously manual
                processes, a good system is to estimate the number of minutes
                each step used to cost, and track the accumulated data of actual
                human hours saved. Ideally, the platform would allow you to
                report on this automatically.
              </p>
            </div>
          </ReportV2ContentCard>
        </ReportV2ContentCardGrid>
      </ReportV2Section>
    </section>
  );
};
