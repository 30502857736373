export const PlugTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      d="M2.83984 11.8284C2.64457 11.6331 2.64457 11.3165 2.83984 11.1212L9.2038 4.75729C9.39906 4.56202 9.71564 4.56202 9.9109 4.75729L12.9649 7.81126C13.0476 7.89397 13.0987 8.00306 13.1093 8.11955L13.43 11.6481C13.4406 11.7646 13.4917 11.8736 13.5744 11.9564L14.5071 12.889C14.7024 13.0843 14.7024 13.4009 14.5071 13.5961L11.6787 16.4245C11.4834 16.6198 11.1668 16.6198 10.9716 16.4245L10.0389 15.4919C9.9562 15.4092 9.84711 15.3581 9.73062 15.3475L6.2021 15.0267C6.08561 15.0161 5.97652 14.965 5.89381 14.8823L2.83984 11.8284Z"
    />
    <rect
      opacity="0.3"
      x="6.5271"
      y="12.0302"
      width="1"
      height="8.56694"
      rx="0.5"
      transform="rotate(-135 6.5271 12.0302)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29073 1.19784C5.09547 1.3931 5.09547 1.70968 5.29073 1.90495L7.43599 4.0502L4.60757 6.87861L2.46232 4.73336C2.26706 4.5381 1.95047 4.5381 1.75521 4.73336C1.55995 4.92862 1.55995 5.2452 1.75521 5.44047L3.90046 7.58572L2.83985 8.64633C2.64459 8.84159 2.64459 9.15818 2.83985 9.35344L5.89383 12.4074C5.97654 12.4901 6.08563 12.5412 6.20211 12.5518L9.73064 12.8726C9.84713 12.8832 9.95622 12.9343 10.0389 13.017L10.9716 13.9496C11.1668 14.1449 11.4834 14.1449 11.6787 13.9496L12.734 12.8943C13.6364 13.8447 14.5574 15.0322 14.5574 16.5C14.5574 16.7761 14.7812 17 15.0574 17C15.3335 17 15.5574 16.7761 15.5574 16.5C15.5574 14.6326 14.3926 13.1801 13.4424 12.1859L14.5071 11.1212C14.7024 10.9259 14.7024 10.6094 14.5071 10.4141L13.5745 9.48144C13.4917 9.39873 13.4407 9.28965 13.4301 9.17316L13.1093 5.64463C13.0987 5.52815 13.0476 5.41906 12.9649 5.33635L9.91092 2.28237C9.71566 2.08711 9.39908 2.08711 9.20381 2.28237L8.14309 3.34309L5.99784 1.19784C5.80258 1.00258 5.486 1.00258 5.29073 1.19784ZM8.49086 4.40954C8.49282 4.40764 8.49476 4.40573 8.49669 4.4038C8.49863 4.40186 8.50054 4.39992 8.50243 4.39796L9.55737 3.34303L12.1298 5.91545L12.4342 9.26369C12.4659 9.61316 12.6192 9.94042 12.8674 10.1885L13.4465 10.7677L11.3251 12.889L10.746 12.3099C10.4979 12.0617 10.1706 11.9085 9.82117 11.8767L6.47293 11.5723L3.90051 8.99989L8.49086 4.40954Z"
    />
  </svg>
);
