import { Action } from "../../../types/tines.types";
import { StoryboardContextValue } from "../../storyboard/StoryboardContext";
import { DemoStoryContextValue } from "./DemoStoryContext";

const createMockEvent = (params: {
  demoContext: DemoStoryContextValue;
  storyContext: StoryboardContextValue;
  triggerValue: string;
  initialAction: Action;
  onPropagate: () => void;
  dropZoneActionNameMatcher: string;
  possibleTriggerValues: [string, string];
}) => {
  const event = {
    triggerValue: params.triggerValue,
    alive: true,
    actions: [params.initialAction],
    dispose: () => {
      event.alive = false;
    },
  };
  const propagate = (action: Action) => {
    if (!event.alive) return;
    action.outputs.forEach(output => {
      setTimeout(() => {
        if (output.type === "trigger" && output.name !== event.triggerValue)
          return;
        if (output.name.includes(params.dropZoneActionNameMatcher))
          params.demoContext.setShouldShowNotification(true);
        event.actions.push(output);
        params.onPropagate();
        propagate(output);
      }, 150);
    });
  };
  setTimeout(() => {
    propagate(params.initialAction);
  }, 100);
  return event;
};

type MockEvent = ReturnType<typeof createMockEvent>;

export const createDemoStoryMockEventEmitter = (params: {
  demoContext: DemoStoryContextValue;
  storyContext: StoryboardContextValue;
  initialAction: Action;
  maxEvents: number;
  dropZoneActionNameMatcher: string;
  possibleTriggerValues: [string, string];
}) => {
  const { demoContext, storyContext } = params;
  const events: MockEvent[] = [];
  const emitter = {
    alive: true,
    get events() {
      return events;
    },
    dispose: () => {
      if (!emitter.alive) return;
      emitter.alive = false;
      events.forEach(e => e.dispose());
      events.splice(0, events.length);
      storyContext.story.actions.forEach(action => {
        const eventBubbleEl = document.querySelector(
          `.Storyboard[data-id="${storyContext.id}"] .ActionEntry[data-action-index="${action.index}"] .EventBubble`
        );
        if (eventBubbleEl) eventBubbleEl.innerHTML = "";
      });
    },
  };
  const onPropagate = () => {
    storyContext.story.actions.forEach(action => {
      const eventBubbleEl = document.querySelector(
        `.Storyboard[data-id="${storyContext.id}"] .ActionEntry[data-action-index="${action.index}"] .EventBubble`
      );
      const eventCount = events.filter(e => e.actions.includes(action)).length;
      if (!eventBubbleEl) return;
      if (eventCount > 0) {
        if (`${eventCount}` === eventBubbleEl.innerHTML) return;
        eventBubbleEl.innerHTML = `${eventCount}`;
      }
    });
  };
  const emit = () => {
    if (!demoContext.hasDropped) {
      emitter.dispose();
    }
    if (events.length >= params.maxEvents) return;
    if (emitter.alive === false) return;
    const triggerValue = demoContext.shouldShowNotification
      ? params.possibleTriggerValues[0]
      : params.possibleTriggerValues[events.length % 2];
    const event = createMockEvent({ ...params, triggerValue, onPropagate });
    events.unshift(event);
    setTimeout(emit, 600);
  };
  setTimeout(emit, 1000);
  return emitter;
};

export type DemoStoryMockEventEmitter = ReturnType<
  typeof createDemoStoryMockEventEmitter
>;
