export const PricingPageIllustration = () => (
  <svg
    width="900"
    height="279"
    viewBox="0 0 900 279"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1141_41296)">
      <path
        d="M840.959 223.734H813.053V242.057H858.733L840.959 223.734Z"
        fill="#483C72"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M899.26 183.004H842.707V174.117H887.129L899.26 183.004Z"
        fill="#483C72"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M128.391 123.683L248.369 0.742188V163.676H128.391V123.683Z"
        fill="#88D0A3"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M378.376 44.0156H354.766V152.944H378.376V44.0156Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M549.974 44.0156H526.363V153.255H549.974V44.0156Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M114.395 134.128L238.283 11.1875V174.121H114.395V134.128Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M549.974 153.992H526.363V210.426H549.974V153.992Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M379.087 153.992H355.477V210.426H379.087V153.992Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M525.906 139.867H453.06H378.437L367.283 153.331H453.06H538.171L525.906 139.867Z"
        fill="#88D0A3"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M339.851 119.969H264.738V153.296H339.851V119.969Z"
        fill="#C1AAF7"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M339.836 119.97H264.738L281.98 105.898H339.836V119.97Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M641.825 119.969H566.713V153.296H641.825V119.969Z"
        fill="#C1AAF7"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M566.713 119.97H641.825L624.569 105.898H566.713V119.97Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M257.51 62.1797H238.551V187.342H257.51V62.1797Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M885.381 183.014L832.072 129.602V174.127L840.96 183.014H885.381Z"
        fill="#FEAF7F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M245.645 51.4219L198.986 93.8883V242.069H219.012L265.671 190.819V51.4219H245.645ZM257.494 187.531L228.077 219.658V97.5913L257.494 68.9446V187.531Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M219.012 93.8672H198.986"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M219.012 93.8735V242.054L265.67 190.804V51.4219L219.012 93.8883V93.8735ZM257.494 187.531L228.077 219.658V97.5913L257.494 68.9447V187.531Z"
        fill="#FFDCE8"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M808.344 33.3984H756.102V95.4613H808.344V33.3984Z"
        fill="#FEAF7F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M801.219 33.3984V89.1366H756.102V95.4613H808.344V33.3984H801.219Z"
        fill="#F3EFEA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M228.078 219.658V199.143L238.55 187.367H257.495L228.078 219.658Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M238.623 87.5119V92.8591H257.509V68.9375L238.623 87.5119Z"
        fill="#483C72"
      />
      <path
        d="M668.577 62.1797H649.617V187.342H668.577V62.1797Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M818.697 198.723H801.456V179.852L787.591 167.617H770.35V148.747L756.486 136.512H739.245V117.641L725.366 105.406H708.361V242.063H832.561V210.958L818.697 198.723Z"
        fill="#FFD29F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M801.455 210.965V179.859H770.35V148.754H739.244V117.648H708.139V148.754V179.859V210.965V242.07H832.561V210.965H801.455Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M641.455 51.4211V190.818L688.113 242.068H708.139V93.8727L661.496 51.4062H641.47L641.455 51.4211ZM649.631 68.9438L679.048 97.5905V219.657L649.631 187.53V68.9438Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M688.1 93.8672H708.14"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M641.455 51.4211V190.818L688.113 242.068V93.8727L641.455 51.4062V51.4211ZM649.631 68.9438L679.048 97.5905V219.657L649.631 187.53V68.9438Z"
        fill="#FFD29F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M679.034 219.658V199.143L668.577 187.367H649.617L679.034 219.658Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M668.503 87.5119V92.8591H649.617V68.9375L668.503 87.5119Z"
        fill="#483C72"
      />
      <path
        d="M339.954 16.7266V153.072H367.401V76.6417C367.401 64.9105 376.911 55.4159 388.627 55.4159C400.343 55.4159 409.853 64.9253 409.853 76.6417V153.072H431.819V76.6417C431.819 64.9105 441.328 55.4159 453.045 55.4159C464.761 55.4159 474.271 64.9253 474.271 76.6417V153.072H496.237V76.6417C496.237 64.9105 505.732 55.4159 517.463 55.4159C529.194 55.4159 538.703 64.9253 538.703 76.6417V153.072H566.15V16.7266H339.939H339.954Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M631.574 247.594L566.164 153.078H538.717V187.679C538.717 199.411 529.208 208.92 517.491 208.92C505.775 208.92 496.265 199.411 496.265 187.679V153.078H474.299V187.679C474.299 199.411 464.79 208.92 453.073 208.92C441.357 208.92 431.847 199.411 431.847 187.679V153.078H409.881V187.679C409.881 199.411 400.387 208.92 388.655 208.92C376.924 208.92 367.415 199.411 367.415 187.679V153.078H339.968L267.418 247.58H631.589L631.574 247.594Z"
        fill="#88D0A3"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M339.953 153.078V247.594"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M566.166 153.078V247.594"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M549.708 3.28125H453.059H356.409L339.953 16.7307H453.059H566.164L549.708 3.28125Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M316.388 268.005C324.372 268.005 330.845 266.844 330.845 265.412C330.845 263.981 324.372 262.82 316.388 262.82C308.404 262.82 301.932 263.981 301.932 265.412C301.932 266.844 308.404 268.005 316.388 268.005Z"
        fill="#483C72"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M420.56 260.597C430.786 260.597 439.075 259.111 439.075 257.279C439.075 255.446 430.786 253.961 420.56 253.961C410.334 253.961 402.045 255.446 402.045 257.279C402.045 259.111 410.334 260.597 420.56 260.597Z"
        fill="#483C72"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M300.834 264.547C300.834 266.014 305.322 267.213 310.847 267.213C316.372 267.213 320.86 266.014 320.86 264.547V234.76C320.86 233.294 316.372 232.094 310.847 232.094C305.322 232.094 300.834 233.294 300.834 234.76V264.547Z"
        fill="#EE86B7"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M310.863 237.45C316.393 237.45 320.876 236.256 320.876 234.783C320.876 233.311 316.393 232.117 310.863 232.117C305.333 232.117 300.85 233.311 300.85 234.783C300.85 236.256 305.333 237.45 310.863 237.45Z"
        fill="#FAA3CA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M496.013 76.6484H474.283V247.595H496.013V76.6484Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M496.013 84.9062H474.283V247.588H496.013V84.9062Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M431.893 76.6484H410.164V247.595H431.893V76.6484Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M431.893 84.9062H410.164V247.588H431.893V84.9062Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M411.807 260.134C425.952 260.134 437.418 248.668 437.418 234.524C437.418 220.38 425.952 208.914 411.807 208.914C397.663 208.914 386.197 220.38 386.197 234.524C386.197 248.668 397.663 260.134 411.807 260.134Z"
        fill="#A88FEF"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M0.740234 216.484H101.433V174.047H52.3309L0.740234 216.484Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M52.332 174.047V216.484"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M758.085 259.259C758.085 259.259 747.08 258.948 736.222 253.334C725.35 247.72 718.744 238.922 718.744 238.922C718.744 238.922 729.75 239.233 740.622 244.847C751.494 250.461 758.1 259.259 758.1 259.259H758.085Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M760.04 258.374C760.04 258.374 751.804 251.057 747.109 239.77C742.398 228.483 742.991 217.492 742.991 217.492C742.991 217.492 751.211 224.795 755.922 236.096C760.632 247.383 760.04 258.374 760.04 258.374Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M761.374 258.374C761.374 258.374 769.594 251.057 774.305 239.77C779.015 228.483 778.422 217.492 778.422 217.492C778.422 217.492 770.202 224.795 765.491 236.096C760.781 247.383 761.374 258.374 761.374 258.374Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M765.831 259.259C765.831 259.259 776.837 258.948 787.694 253.334C798.566 247.72 805.172 238.922 805.172 238.922C805.172 238.922 794.167 239.233 783.295 244.847C772.423 250.461 765.816 259.259 765.816 259.259H765.831Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M715.93 259.664C715.93 259.664 725.839 253.828 738.089 253.828C750.338 253.828 760.233 259.664 760.233 259.664H715.93Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M762.099 259.664C762.099 259.664 772.008 253.828 784.243 253.828C796.478 253.828 806.387 259.664 806.387 259.664H762.084H762.099Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M761.181 259.71C761.181 259.71 756.396 249.801 756.396 237.566C756.396 225.331 761.181 215.422 761.181 215.422C761.181 215.422 765.965 225.331 765.965 237.566C765.965 249.801 761.181 259.71 761.181 259.71Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M760.127 259.664C760.127 259.664 749.729 256.035 741.094 247.385C732.443 238.735 728.814 228.352 728.814 228.352C728.814 228.352 739.198 231.981 747.848 240.631C756.498 249.281 760.127 259.664 760.127 259.664Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M762.1 259.664C762.1 259.664 772.498 256.035 781.133 247.385C789.783 238.735 793.412 228.352 793.412 228.352C793.412 228.352 783.014 231.981 774.379 240.631C765.729 249.281 762.1 259.664 762.1 259.664Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M135.5 77.2969L101.447 108.299V216.487H109.727L143.78 179.071V77.2969H135.5ZM127.502 184.596C122.184 184.596 117.889 166.659 117.889 144.529C117.889 122.4 122.199 104.462 127.502 104.462C132.805 104.462 137.115 122.4 137.115 144.529C137.115 166.659 132.805 184.596 127.502 184.596Z"
        fill="#C1AAF7"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M109.714 108.289H101.434"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M109.713 108.291V216.479L143.766 179.063V77.2891L109.713 108.291Z"
        fill="#EADFF8"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M90.7969 273.291C90.7969 275.942 81.3468 278.104 69.6748 278.104C58.0029 278.104 48.5527 275.957 48.5527 273.291C48.5527 270.624 58.0029 268.477 69.6748 268.477C81.3468 268.477 90.7969 270.624 90.7969 273.291Z"
        fill="#483C72"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M116.6 255.218C126.793 255.218 135.056 253.335 135.056 251.011C135.056 248.688 126.793 246.805 116.6 246.805C106.408 246.805 98.1445 248.688 98.1445 251.011C98.1445 253.335 106.408 255.218 116.6 255.218Z"
        fill="#483C72"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M70.0449 217.046C70.0449 237.679 86.7678 254.402 107.401 254.402C116.925 254.402 125.605 250.847 132.197 244.982C134.315 243.101 143.246 232.08 145.024 228.185C147.187 223.46 148.386 218.216 148.386 212.677C148.386 192.043 131.663 175.32 111.03 175.32C98.4546 175.32 87.3455 181.541 80.5763 191.066C74.0738 197.79 70.0597 206.944 70.0597 217.046H70.0449ZM93.1518 201.538C96.9141 198.087 101.891 195.968 107.401 195.968C119.043 195.968 128.479 205.404 128.479 217.046C128.479 221.149 127.294 224.956 125.265 228.2C121.517 231.651 116.525 233.769 111.015 233.769C99.3729 233.769 89.9376 224.334 89.9376 212.691C89.9376 208.588 91.1226 204.782 93.1518 201.538Z"
        fill="#56B88E"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M70.0449 217.052C70.0449 237.685 86.7678 254.408 107.401 254.408C128.034 254.408 144.757 237.685 144.757 217.052C144.757 196.418 128.034 179.695 107.401 179.695C86.7678 179.695 70.0449 196.418 70.0449 217.052ZM86.3235 217.052C86.3235 205.409 95.7588 195.974 107.401 195.974C119.043 195.974 128.479 205.409 128.479 217.052C128.479 228.694 119.043 238.129 107.401 238.129C95.7588 238.129 86.3235 228.694 86.3235 217.052Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M21.9062 239.32C21.9062 259.954 38.6291 276.677 59.2624 276.677C68.7718 276.677 77.4666 273.122 84.058 267.256C86.1761 265.375 95.1078 254.37 96.8853 250.474C99.0478 245.749 100.248 240.505 100.248 234.966C100.248 214.332 83.5247 197.609 62.8914 197.609C50.3159 197.609 39.2068 203.83 32.4377 213.355C25.9203 220.079 21.9062 229.233 21.9062 239.335V239.32ZM45.0132 223.812C48.7606 220.361 53.7523 218.243 59.2624 218.243C70.9048 218.243 80.3401 227.678 80.3401 239.32C80.3401 243.423 79.1551 247.23 77.1259 250.474C73.3784 253.925 68.3867 256.043 62.8766 256.043C51.2343 256.043 41.7989 246.608 41.7989 234.966C41.7989 230.863 42.9839 227.041 45.0132 223.812Z"
        fill="#FEAF7F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M21.9062 239.325C21.9062 259.958 38.6291 276.681 59.2624 276.681C79.8957 276.681 96.6187 259.958 96.6187 239.325C96.6187 218.692 79.8957 201.969 59.2624 201.969C38.6291 201.969 21.9062 218.692 21.9062 239.325ZM38.1848 239.325C38.1848 227.683 47.6201 218.247 59.2624 218.247C70.9048 218.247 80.3401 227.683 80.3401 239.325C80.3401 250.967 70.9048 260.403 59.2624 260.403C47.6201 260.403 38.1848 250.967 38.1848 239.325Z"
        fill="#FFD29F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M841.699 183.016H886.861L841.699 137.75V183.016Z"
        fill="#FFD29F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M801.219 89.1406L808.343 95.4654"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M72.6696 116.578H32.0547V145.64H72.6696V116.578Z"
        fill="#A88FEF"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M38.6313 116.578V139.078H72.6844V145.64H32.0547V116.578H38.6313Z"
        fill="#F3EFEA"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M288.882 142.031H227.826V147.689V159.036H281.905L288.882 153.363V142.031Z"
        fill="#88D0A3"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M281.905 147.688H227.826V159.019H281.905V147.688Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M281.844 147.689L288.82 142.031"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M608.986 160.773H664.68L670.057 166.446V177.793H615.978L608.986 172.12V160.773Z"
        fill="#88D0A3"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M670.058 166.445H615.979V177.777H670.058V166.445Z"
        fill="#B0E0BD"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M616.036 166.446L609.045 160.773"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M349.507 180.188H296.435L290.451 185.861V197.192H344.53L349.507 191.519V180.188Z"
        fill="#F47E3F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M344.53 185.859H290.451V197.191H344.53V185.859Z"
        fill="#FEAF7F"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M344.473 185.861L349.45 180.188"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M508.916 163.953H462.51V175.803H508.916V163.953Z"
        fill="#98BCF1"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M508.916 169.672H462.51V181.522H508.916V169.672Z"
        fill="#BBD2F8"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M32.0547 145.64L38.6313 139.078"
        stroke="#483C72"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1141_41296">
        <rect width="900" height="278.839" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
