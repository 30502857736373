export const Machinery = () => (
  <svg
    width="218"
    height="223"
    viewBox="0 0 218 223"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M94 56.0002C94 52.6865 96.6863 50.0002 100 50.0002H211C213.209 50.0002 215 51.7911 215 54.0002V151.255C215 152.703 214.476 154.102 213.526 155.194L159.026 217.801C156.634 220.549 152.366 220.549 149.974 217.801L95.4745 155.194C94.5237 154.102 94 152.703 94 151.255V56.0002Z"
      fill="#A990F5"
    />
    <path d="M181 153L214 153.5L189 182.5H181V153Z" fill="#8D75E6" />
    <path
      d="M94.5 56.0002C94.5 52.9627 96.9624 50.5002 100 50.5002H211C212.933 50.5002 214.5 52.0672 214.5 54.0002V151.255C214.5 152.582 214.02 153.865 213.148 154.866L158.648 217.473C156.456 219.992 152.544 219.992 150.352 217.473L95.8516 154.866C94.9801 153.865 94.5 152.582 94.5 151.255V56.0002Z"
      stroke="#6956A8"
    />
    <path
      d="M86.5 56.0002C86.5 52.9627 88.9624 50.5002 92 50.5002H203C204.933 50.5002 206.5 52.0672 206.5 54.0002V151.255C206.5 152.582 206.02 153.865 205.148 154.866L150.648 217.473C148.456 219.992 144.544 219.992 142.352 217.473L87.8516 154.866C86.9801 153.865 86.5 152.582 86.5 151.255V56.0002Z"
      fill="#D7C4FA"
      stroke="#6956A8"
    />
    <rect x="180" y="91.0002" width="35" height="6" rx="2" fill="#6956A8" />
    <rect
      x="188.5"
      y="87.5002"
      width="29"
      height="7"
      rx="1.5"
      fill="#C2AAFA"
      stroke="#6956A8"
    />
    <rect
      x="180.5"
      y="87.5002"
      width="29"
      height="7"
      rx="1.5"
      fill="#D7C4FA"
      stroke="#6956A8"
    />
    <path d="M200 131.5C200 178 196 202.5 190.5 220" stroke="#8D75E6" />
    <path d="M204 132C202 170 198.5 192 190.5 195.5" stroke="#8D75E6" />
    <rect x="180" y="109" width="35" height="6" rx="2" fill="#6956A8" />
    <rect
      x="188.5"
      y="105.5"
      width="29"
      height="7"
      rx="1.5"
      fill="#C2AAFA"
      stroke="#6956A8"
    />
    <rect
      x="180.5"
      y="105.5"
      width="29"
      height="7"
      rx="1.5"
      fill="#D7C4FA"
      stroke="#6956A8"
    />
    <rect x="180" y="127" width="35" height="6" rx="2" fill="#6956A8" />
    <rect
      x="188.5"
      y="123.5"
      width="29"
      height="7"
      rx="1.5"
      fill="#C2AAFA"
      stroke="#6956A8"
    />
    <rect
      x="180.5"
      y="123.5"
      width="29"
      height="7"
      rx="1.5"
      fill="#D7C4FA"
      stroke="#6956A8"
    />
    <path
      d="M23 70.2264L22.9979 68.585L22.9971 68.0236L23 68.0224L22.9979 66.3812L22.9957 64.7395L20.7674 63.8187C20.6632 63.436 20.5359 63.0632 20.388 62.702L20.8078 61.6816V59.4777L19.6873 58.3183L18.5665 57.16L16.4072 58.1237C15.7959 57.7721 15.141 57.4914 14.4534 57.2928L13.5658 55.0002L11.9835 55.0028L10.4014 55.0054L9.52002 57.3003C8.83347 57.5018 8.17985 57.7847 7.5692 58.1379L5.40694 57.1808L4.28984 58.3434L3.17299 59.5052V61.709L3.5988 62.736C3.45307 63.0954 3.32803 63.4662 3.22527 63.8467L1 64.774L1.00251 66.4155L1.00331 66.9765L1 66.9779L1.00251 68.6195L1.00484 70.261L3.23261 71.1815C3.33698 71.5644 3.46435 71.9372 3.6125 72.2984L3.19286 73.3189V75.5229L5.29452 77.6247L7.5931 76.8762C8.20474 77.2278 8.85933 77.5087 9.54661 77.7079L10.4344 80.0002L12.0165 79.9976L13.5992 79.9957L14.4801 77.6997C15.1666 77.4988 15.8206 77.2159 16.4313 76.8625L18.5937 77.8201L19.7106 76.6576L20.8277 75.4949V73.291L20.402 72.2642C20.5476 71.9048 20.6728 71.534 20.7754 71.1537L23 70.2264ZM12.4132 64.4412C14.1175 64.623 15.4631 65.8818 15.8895 67.4994C15.3807 69.4408 13.5794 70.7712 11.5876 70.5586C9.88336 70.3768 8.53763 69.1177 8.11119 67.5002C8.61962 65.559 10.4209 64.2285 12.4132 64.4412Z"
      fill="#7F69CE"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.54661 75.6875L10.4344 78.0002L12.0165 77.9976L13.5992 77.9957L14.4801 75.6792C15.1666 75.4765 15.8206 75.191 16.4313 74.8344L18.5937 75.8005L19.7106 74.6277L20.8277 73.4545L19.9136 71.2303C20.2801 70.5591 20.5719 69.8359 20.7754 69.0745L23 68.139L22.9979 66.4831L22.9957 64.8268L20.7674 63.8977C20.5621 63.1374 20.2687 62.4152 19.8996 61.7448L20.8078 59.5177L19.6872 58.348L18.5665 57.1793L16.4072 58.1516C15.7959 57.7969 15.141 57.5136 14.4534 57.3133L13.5658 55.0002L11.9835 55.0029L10.4015 55.0055L9.52002 57.3208C8.83346 57.5241 8.17985 57.8096 7.5692 58.1659L5.40695 57.2003L4.28983 58.3733L3.17299 59.5454L4.08736 61.7701C3.72018 62.4415 3.429 63.1643 3.22527 63.9258L1 64.8614L1.00251 66.5175L1.00484 68.1738L3.23261 69.1025C3.43822 69.8629 3.73173 70.5846 4.1007 71.2552L3.19286 73.4827L5.29452 75.6034L7.5931 74.8481C8.20473 75.203 8.85924 75.4866 9.54661 75.6875ZM7.98982 66.068C8.21753 63.7494 10.1977 62.0634 12.4131 62.3021C14.6278 62.5405 16.2387 64.6132 16.0109 66.9319C15.7825 69.2505 13.8023 70.9363 11.5875 70.6978C9.37269 70.4594 7.76165 68.3861 7.98982 66.068Z"
      fill="#A990F5"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12 71.0002C14.7614 71.0002 17 68.9855 17 66.5002C17 64.015 14.7614 62.0002 12 62.0002C9.23857 62.0002 7 64.015 7 66.5002C7 68.9855 9.23857 71.0002 12 71.0002Z"
      fill="#7F69CE"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12 70.0002C14.7614 70.0002 17 67.7617 17 65.0002C17 62.2388 14.7614 60.0002 12 60.0002C9.23857 60.0002 7 62.2388 7 65.0002C7 67.7617 9.23857 70.0002 12 70.0002Z"
      fill="#C2AAFA"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3 71.0002V69.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5 77.0002V75.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7 77.0002V75.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9 78.0002V75.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10 80.0002V78.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14 80.0002V78.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14 75.0002V78.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M16 77.0002V75.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19 78.0002V75.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21 71.0002V69.0002"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12 68.0002C13.1046 68.0002 14 66.881 14 65.5002C14 64.1195 13.1046 63.0002 12 63.0002C10.8954 63.0002 10 64.1195 10 65.5002C10 66.881 10.8954 68.0002 12 68.0002Z"
      fill="#7F69CE"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12 67.0002C13.1046 67.0002 14 66.1048 14 65.0002C14 63.8957 13.1046 63.0002 12 63.0002C10.8954 63.0002 10 63.8957 10 65.0002C10 66.1048 10.8954 67.0002 12 67.0002Z"
      fill="#C2AAFA"
      stroke="#6956A8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <rect
      x="27.5"
      y="12.5002"
      width="72"
      height="64"
      rx="2.5"
      fill="#6956A8"
      stroke="#6956A8"
    />
    <rect
      x="19.5"
      y="12.5002"
      width="72"
      height="64"
      rx="2.5"
      fill="#C2AAFA"
      stroke="#6956A8"
    />
    <rect
      x="25.5"
      y="54.5002"
      width="5"
      height="5"
      rx="1.5"
      fill="#A990F5"
      stroke="#7F69CE"
    />
    <rect
      x="25.5"
      y="18.5002"
      width="60"
      height="30"
      rx="1.5"
      fill="#6956A8"
      stroke="#4D3E78"
    />
    <rect x="29" y="22.0002" width="9" height="2" fill="#D7C4FA" />
    <rect x="39" y="22.0002" width="6" height="2" fill="#A990F5" />
    <rect x="46" y="22.0002" width="3" height="2" fill="#D7C4FA" />
    <rect x="50" y="22.0002" width="7" height="2" fill="#A990F5" />
    <rect x="29" y="37.0002" width="5" height="2" fill="#D7C4FA" />
    <rect x="35" y="37.0002" width="5" height="2" fill="#A990F5" />
    <rect x="29" y="40.0002" width="9" height="2" fill="#D7C4FA" />
    <rect x="39" y="40.0002" width="8" height="2" fill="#A990F5" />
    <rect x="48" y="40.0002" width="6" height="2" fill="#D7C4FA" />
    <rect x="29" y="25.0002" width="7" height="2" fill="#A990F5" />
    <rect x="37" y="25.0002" width="5" height="2" fill="#D7C4FA" />
    <rect x="43" y="25.0002" width="10" height="2" fill="#D7C4FA" />
    <rect x="31" y="28.0002" width="3" height="2" fill="#D7C4FA" />
    <rect x="35" y="28.0002" width="6" height="2" fill="#A990F5" />
    <rect x="42" y="28.0002" width="6" height="2" fill="#D7C4FA" />
    <rect x="31" y="31.0002" width="6" height="2" fill="#D7C4FA" />
    <rect x="38" y="31.0002" width="6" height="2" fill="#D7C4FA" />
    <rect x="31" y="34.0002" width="4" height="2" fill="#A990F5" />
    <rect x="36" y="34.0002" width="10" height="2" fill="#D7C4FA" />
    <rect x="47" y="34.0002" width="10" height="2" fill="#D7C4FA" />
    <rect
      x="79.5"
      y="26.5002"
      width="55"
      height="14"
      rx="1.5"
      fill="#6956A8"
      stroke="#6956A8"
    />
    <rect
      x="69.5"
      y="26.5002"
      width="55"
      height="14"
      rx="1.5"
      fill="#EADFF8"
      stroke="#6956A8"
    />
    <rect x="74" y="32.0002" width="3" height="3" rx="1.5" fill="#8D75E6" />
    <rect x="100" y="32.0002" width="20" height="3" rx="1" fill="#A990F5" />
    <path
      d="M79.5 45C79.5 44.1716 80.1716 43.5 81 43.5H133C133.828 43.5 134.5 44.1716 134.5 45V72.5H79.5V45Z"
      fill="#6956A8"
      stroke="#6956A8"
    />
    <path
      d="M69.5 45C69.5 44.1716 70.1716 43.5 71 43.5H123C123.828 43.5 124.5 44.1716 124.5 45V72.5H69.5V45Z"
      fill="#EADFF8"
      stroke="#6956A8"
    />
    <mask
      id="path-62-outside-1_242_20887"
      maskUnits="userSpaceOnUse"
      x="100"
      y="65"
      width="46"
      height="9"
      fill="black"
    >
      <rect fill="white" x="100" y="65" width="46" height="9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105 68C103.895 68 103 68.8954 103 70V73H101V70C101 67.7909 102.791 66 105 66H141C143.209 66 145 67.7909 145 70V73H143V70C143 68.8954 142.105 68 141 68H105Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105 68C103.895 68 103 68.8954 103 70V73H101V70C101 67.7909 102.791 66 105 66H141C143.209 66 145 67.7909 145 70V73H143V70C143 68.8954 142.105 68 141 68H105Z"
      fill="#A990F5"
    />
    <path
      d="M103 73V74H104V73H103ZM101 73H100V74H101V73ZM145 73V74H146V73H145ZM143 73H142V74H143V73ZM104 70C104 69.4477 104.448 69 105 69V67C103.343 67 102 68.3431 102 70H104ZM104 73V70H102V73H104ZM101 74H103V72H101V74ZM100 70V73H102V70H100ZM105 65C102.239 65 100 67.2386 100 70H102C102 68.3431 103.343 67 105 67V65ZM141 65H105V67H141V65ZM146 70C146 67.2386 143.761 65 141 65V67C142.657 67 144 68.3431 144 70H146ZM146 73V70H144V73H146ZM143 74H145V72H143V74ZM142 70V73H144V70H142ZM141 69C141.552 69 142 69.4477 142 70H144C144 68.3431 142.657 67 141 67V69ZM105 69H141V67H105V69Z"
      fill="#7F69CE"
      mask="url(#path-62-outside-1_242_20887)"
    />
    <rect x="100" y="48" width="20" height="1" rx="0.5" fill="#A990F5" />
    <rect x="100" y="50" width="20" height="1" rx="0.5" fill="#A990F5" />
    <rect x="100" y="52" width="20" height="1" rx="0.5" fill="#A990F5" />
    <rect x="74" y="66" width="6" height="3" rx="1" fill="#A990F5" />
    <rect x="82" y="66" width="6" height="3" rx="1" fill="#A990F5" />
    <rect x="78" y="41.0002" width="2" height="2" fill="#6956A8" />
    <rect x="114" y="41.0002" width="2" height="2" fill="#6956A8" />
    <rect x="125" y="41.0002" width="2" height="2" fill="#6956A8" />
    <rect
      x="141.5"
      y="56.5002"
      width="1"
      height="4"
      fill="#D9D9D9"
      stroke="#EADFF8"
    />
    <rect
      x="149.5"
      y="56.5002"
      width="1"
      height="4"
      fill="#D9D9D9"
      stroke="#EADFF8"
    />
    <rect
      x="157.5"
      y="56.5002"
      width="1"
      height="4"
      fill="#D9D9D9"
      stroke="#EADFF8"
    />
    <rect
      x="165.5"
      y="56.5002"
      width="1"
      height="4"
      fill="#D9D9D9"
      stroke="#EADFF8"
    />
    <rect
      x="173.5"
      y="56.5002"
      width="1"
      height="4"
      fill="#D9D9D9"
      stroke="#EADFF8"
    />
    <rect
      x="181.5"
      y="56.5002"
      width="1"
      height="4"
      fill="#D9D9D9"
      stroke="#EADFF8"
    />
    <rect
      x="189.5"
      y="56.5002"
      width="1"
      height="4"
      fill="#D9D9D9"
      stroke="#EADFF8"
    />
    <path
      d="M110 26.0002C110 25.448 110.448 25.0002 111 25.0002H118C118.552 25.0002 119 25.448 119 26.0002V27.0002H110V26.0002Z"
      fill="#A54B7A"
    />
    <path
      d="M115 25.5002H115.5V25.0002V1.50024C115.5 0.947959 115.052 0.500244 114.5 0.500244C113.948 0.500244 113.5 0.947958 113.5 1.50024V25.0002V25.5002H114H115Z"
      fill="#FFBFDB"
      stroke="#A54B7A"
    />
    <path
      d="M133 4.93358L124.5 4.00024L124 11.0002L133 4.93358Z"
      fill="#F486B8"
      stroke="#A54B7A"
      strokeLinejoin="round"
    />
    <path
      d="M124.283 2.13643L115.3 2.13633C115.134 2.13633 115 2.27064 115 2.43633L115 12.0676C115 12.277 115.209 12.422 115.405 12.3486L124.389 8.98549C124.506 8.94163 124.583 8.82965 124.583 8.70453L124.583 2.43643C124.583 2.27074 124.449 2.13643 124.283 2.13643Z"
      fill="#F486B8"
      stroke="#A54B7A"
    />
  </svg>
);
