import InvestorListSection from "../components/reusableSections/InvestorListSection";
import LeadershipSlideSection from "../components/reusableSections/LeadershipSlideSection";
import OurValuesSection from "../components/reusableSections/OurValuesSection";
import MissionSection from "../components/reusableSections/MissionSection";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import HeroSection from "../components/sections/HeroSection";
import AllCaps from "../components/typography/AllCaps";
import StandardCTASection from "../components/sections/StandardCTASection";
import { NoLetterSpacing } from "../components/typography/NoLetterSpacing";
import { AboutPageIllustration } from "../components/illustrations/neue/AboutPageIllustration";
import { styled } from "@linaria/react";
import { rSize } from "../styles/responsiveSizes.styles";
import {
  fromTabletLg,
  uptoDesktop,
} from "../styles/breakpointsAndMediaQueries.styles";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";

const IllustrationWrap = styled.div`
  margin-top: ${rSize("xl")};
  svg {
    max-width: 100%;
    height: auto;
    path[stroke] {
      ${uptoDesktop} {
        stroke-width: 2;
      }
    }
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.35;
  ${fromTabletLg} {
    font-size: 1.6rem;
  }
`;

const PageAbout: PageComponent = props => (
  <Page
    {...props}
    browserThemeColor={colors.dark700}
    backgroundColor={colors.dark700}
    colorNavAsDarkTheme
  >
    <SEO
      title="About"
      image="/images/og/tines-about.png"
      description="Tines empowers teams to focus their efforts and break barriers between people and systems so that both can do their best work."
    />
    <HeroSection
      backdrop="grid"
      backdropColor={colors.darkest}
      backdropPositionBottom="14em"
      backdropParallax
      contentRelative
    >
      <AllCaps children="About us" />
      <h1>
        <span>
          Smart, secure work<NoLetterSpacing>fl</NoLetterSpacing>ows{" "}
        </span>
        <span>
          for the <em>whole</em> team
        </span>
      </h1>
      <Description centered wider>
        We believe that by combining automation and human ingenuity,
        organizations will be more efficient, more secure and have more engaged,
        happier teams.
      </Description>
      <IllustrationWrap>
        <AboutPageIllustration />
      </IllustrationWrap>
    </HeroSection>

    <Spacing size="lg" />

    <MissionSection />
    <Spacing size="sectionMargin" />
    <LeadershipSlideSection />
    <Spacing size="sectionMargin" />
    <InvestorListSection />
    <Spacing size="sectionMargin" />

    <OurValuesSection />
    <Spacing size="xl" />
    <StandardCTASection
      heading="Join our team"
      body="We invite you to join our world class team in empowering a generation of accidental programmers."
      primaryAction="careers"
    />

    <Spacing size="sectionMargin" />

    <ExplosionCTASection againstDarkBackground />
  </Page>
);

export default PageAbout;
