export const ReportV2LargeChapterNumber5Dots = () => (
  <svg width="370" height="400" viewBox="0 0 370 400" fill="currentColor">
    <g opacity="0.3">
      <path d="M135.946 187.417C137.344 187.417 138.478 186.284 138.478 184.885C138.478 183.487 137.344 182.354 135.946 182.354C134.548 182.354 133.414 183.487 133.414 184.885C133.414 186.284 134.548 187.417 135.946 187.417Z" />
      <path d="M135.946 197.545C137.344 197.545 138.478 196.412 138.478 195.013C138.478 193.615 137.344 192.481 135.946 192.481C134.548 192.481 133.414 193.615 133.414 195.013C133.414 196.412 134.548 197.545 135.946 197.545Z" />
      <path d="M146.074 126.65C147.472 126.65 148.606 125.516 148.606 124.118C148.606 122.72 147.472 121.586 146.074 121.586C144.676 121.586 143.542 122.72 143.542 124.118C143.542 125.516 144.676 126.65 146.074 126.65Z" />
      <path d="M146.074 136.779C147.472 136.779 148.606 135.645 148.606 134.247C148.606 132.848 147.472 131.715 146.074 131.715C144.676 131.715 143.542 132.848 143.542 134.247C143.542 135.645 144.676 136.779 146.074 136.779Z" />
      <path d="M156.202 157.035C157.6 157.035 158.734 155.901 158.734 154.503C158.734 153.104 157.6 151.971 156.202 151.971C154.804 151.971 153.67 153.104 153.67 154.503C153.67 155.901 154.804 157.035 156.202 157.035Z" />
      <path d="M156.202 167.162C157.6 167.162 158.734 166.028 158.734 164.63C158.734 163.231 157.6 162.098 156.202 162.098C154.804 162.098 153.67 163.231 153.67 164.63C153.67 166.028 154.804 167.162 156.202 167.162Z" />
      <path d="M156.202 197.545C157.6 197.545 158.734 196.412 158.734 195.013C158.734 193.615 157.6 192.481 156.202 192.481C154.804 192.481 153.67 193.615 153.67 195.013C153.67 196.412 154.804 197.545 156.202 197.545Z" />
      <path d="M166.329 106.394C167.727 106.394 168.861 105.26 168.861 103.862C168.861 102.464 167.727 101.33 166.329 101.33C164.93 101.33 163.797 102.464 163.797 103.862C163.797 105.26 164.93 106.394 166.329 106.394Z" />
      <path d="M176.458 167.162C177.856 167.162 178.99 166.028 178.99 164.63C178.99 163.231 177.856 162.098 176.458 162.098C175.059 162.098 173.926 163.231 173.926 164.63C173.926 166.028 175.059 167.162 176.458 167.162Z" />
      <path d="M176.458 187.417C177.856 187.417 178.99 186.284 178.99 184.885C178.99 183.487 177.856 182.354 176.458 182.354C175.059 182.354 173.926 183.487 173.926 184.885C173.926 186.284 175.059 187.417 176.458 187.417Z" />
      <path d="M206.841 157.035C208.239 157.035 209.373 155.901 209.373 154.503C209.373 153.104 208.239 151.971 206.841 151.971C205.442 151.971 204.309 153.104 204.309 154.503C204.309 155.901 205.442 157.035 206.841 157.035Z" />
      <path d="M257.48 157.035C258.879 157.035 260.012 155.901 260.012 154.503C260.012 153.104 258.879 151.971 257.48 151.971C256.082 151.971 254.948 153.104 254.948 154.503C254.948 155.901 256.082 157.035 257.48 157.035Z" />
      <path d="M257.48 187.417C258.879 187.417 260.012 186.284 260.012 184.885C260.012 183.487 258.879 182.354 257.48 182.354C256.082 182.354 254.948 183.487 254.948 184.885C254.948 186.284 256.082 187.417 257.48 187.417Z" />
      <path d="M297.992 177.29C299.39 177.29 300.524 176.157 300.524 174.759C300.524 173.36 299.39 172.227 297.992 172.227C296.594 172.227 295.46 173.36 295.46 174.759C295.46 176.157 296.594 177.29 297.992 177.29Z" />
      <path d="M308.12 187.417C309.518 187.417 310.652 186.284 310.652 184.885C310.652 183.487 309.518 182.354 308.12 182.354C306.721 182.354 305.588 183.487 305.588 184.885C305.588 186.284 306.721 187.417 308.12 187.417Z" />
      <path d="M318.247 197.545C319.645 197.545 320.779 196.412 320.779 195.013C320.779 193.615 319.645 192.481 318.247 192.481C316.848 192.481 315.715 193.615 315.715 195.013C315.715 196.412 316.848 197.545 318.247 197.545Z" />
      <path d="M156.202 65.9839C157.6 65.9839 158.734 64.8503 158.734 63.4519C158.734 62.0535 157.6 60.9199 156.202 60.9199C154.804 60.9199 153.67 62.0535 153.67 63.4519C153.67 64.8503 154.804 65.9839 156.202 65.9839Z" />
      <path d="M176.458 65.9839C177.856 65.9839 178.99 64.8503 178.99 63.4519C178.99 62.0535 177.856 60.9199 176.458 60.9199C175.059 60.9199 173.926 62.0535 173.926 63.4519C173.926 64.8503 175.059 65.9839 176.458 65.9839Z" />
      <path d="M186.585 65.9839C187.983 65.9839 189.117 64.8503 189.117 63.4519C189.117 62.0535 187.983 60.9199 186.585 60.9199C185.186 60.9199 184.053 62.0535 184.053 63.4519C184.053 64.8503 185.186 65.9839 186.585 65.9839Z" />
      <path d="M196.714 65.9839C198.112 65.9839 199.246 64.8503 199.246 63.4519C199.246 62.0535 198.112 60.9199 196.714 60.9199C195.315 60.9199 194.182 62.0535 194.182 63.4519C194.182 64.8503 195.315 65.9839 196.714 65.9839Z" />
      <path d="M206.841 65.9839C208.239 65.9839 209.373 64.8503 209.373 63.4519C209.373 62.0535 208.239 60.9199 206.841 60.9199C205.442 60.9199 204.309 62.0535 204.309 63.4519C204.309 64.8503 205.442 65.9839 206.841 65.9839Z" />
      <path d="M216.969 65.9839C218.368 65.9839 219.501 64.8503 219.501 63.4519C219.501 62.0535 218.368 60.9199 216.969 60.9199C215.571 60.9199 214.438 62.0535 214.438 63.4519C214.438 64.8503 215.571 65.9839 216.969 65.9839Z" />
      <path d="M227.096 65.9839C228.495 65.9839 229.628 64.8503 229.628 63.4519C229.628 62.0535 228.495 60.9199 227.096 60.9199C225.698 60.9199 224.564 62.0535 224.564 63.4519C224.564 64.8503 225.698 65.9839 227.096 65.9839Z" />
      <path d="M237.225 65.9839C238.624 65.9839 239.757 64.8503 239.757 63.4519C239.757 62.0535 238.624 60.9199 237.225 60.9199C235.827 60.9199 234.693 62.0535 234.693 63.4519C234.693 64.8503 235.827 65.9839 237.225 65.9839Z" />
      <path d="M247.352 65.9839C248.751 65.9839 249.884 64.8503 249.884 63.4519C249.884 62.0535 248.751 60.9199 247.352 60.9199C245.954 60.9199 244.82 62.0535 244.82 63.4519C244.82 64.8503 245.954 65.9839 247.352 65.9839Z" />
      <path d="M257.48 65.9839C258.879 65.9839 260.012 64.8503 260.012 63.4519C260.012 62.0535 258.879 60.9199 257.48 60.9199C256.082 60.9199 254.948 62.0535 254.948 63.4519C254.948 64.8503 256.082 65.9839 257.48 65.9839Z" />
      <path d="M267.608 65.9839C269.007 65.9839 270.14 64.8503 270.14 63.4519C270.14 62.0535 269.007 60.9199 267.608 60.9199C266.21 60.9199 265.076 62.0535 265.076 63.4519C265.076 64.8503 266.21 65.9839 267.608 65.9839Z" />
      <path d="M277.736 65.9839C279.134 65.9839 280.268 64.8503 280.268 63.4519C280.268 62.0535 279.134 60.9199 277.736 60.9199C276.338 60.9199 275.204 62.0535 275.204 63.4519C275.204 64.8503 276.338 65.9839 277.736 65.9839Z" />
      <path d="M287.864 65.9839C289.262 65.9839 290.396 64.8503 290.396 63.4519C290.396 62.0535 289.262 60.9199 287.864 60.9199C286.466 60.9199 285.332 62.0535 285.332 63.4519C285.332 64.8503 286.466 65.9839 287.864 65.9839Z" />
      <path d="M297.992 65.9839C299.39 65.9839 300.524 64.8503 300.524 63.4519C300.524 62.0535 299.39 60.9199 297.992 60.9199C296.594 60.9199 295.46 62.0535 295.46 63.4519C295.46 64.8503 296.594 65.9839 297.992 65.9839Z" />
      <path d="M308.12 65.9839C309.518 65.9839 310.652 64.8503 310.652 63.4519C310.652 62.0535 309.518 60.9199 308.12 60.9199C306.721 60.9199 305.588 62.0535 305.588 63.4519C305.588 64.8503 306.721 65.9839 308.12 65.9839Z" />
      <path d="M318.247 65.9839C319.645 65.9839 320.779 64.8503 320.779 63.4519C320.779 62.0535 319.645 60.9199 318.247 60.9199C316.848 60.9199 315.715 62.0535 315.715 63.4519C315.715 64.8503 316.848 65.9839 318.247 65.9839Z" />
      <path d="M115.69 328.65C117.089 328.65 118.222 327.516 118.222 326.118C118.222 324.72 117.089 323.586 115.69 323.586C114.292 323.586 113.158 324.72 113.158 326.118C113.158 327.516 114.292 328.65 115.69 328.65Z" />
      <path d="M125.818 308.394C127.216 308.394 128.35 307.26 128.35 305.862C128.35 304.464 127.216 303.33 125.818 303.33C124.42 303.33 123.286 304.464 123.286 305.862C123.286 307.26 124.42 308.394 125.818 308.394Z" />
      <path d="M125.818 338.779C127.216 338.779 128.35 337.645 128.35 336.247C128.35 334.848 127.216 333.715 125.818 333.715C124.42 333.715 123.286 334.848 123.286 336.247C123.286 337.645 124.42 338.779 125.818 338.779Z" />
      <path d="M135.946 338.779C137.344 338.779 138.478 337.645 138.478 336.247C138.478 334.848 137.344 333.715 135.946 333.715C134.548 333.715 133.414 334.848 133.414 336.247C133.414 337.645 134.548 338.779 135.946 338.779Z" />
      <path d="M146.074 308.394C147.472 308.394 148.606 307.26 148.606 305.862C148.606 304.464 147.472 303.33 146.074 303.33C144.676 303.33 143.542 304.464 143.542 305.862C143.542 307.26 144.676 308.394 146.074 308.394Z" />
      <path d="M146.074 318.523C147.472 318.523 148.606 317.389 148.606 315.991C148.606 314.593 147.472 313.459 146.074 313.459C144.676 313.459 143.542 314.593 143.542 315.991C143.542 317.389 144.676 318.523 146.074 318.523Z" />
      <path d="M156.202 328.65C157.6 328.65 158.734 327.516 158.734 326.118C158.734 324.72 157.6 323.586 156.202 323.586C154.804 323.586 153.67 324.72 153.67 326.118C153.67 327.516 154.804 328.65 156.202 328.65Z" />
      <path d="M166.329 338.779C167.727 338.779 168.861 337.645 168.861 336.247C168.861 334.848 167.727 333.715 166.329 333.715C164.93 333.715 163.797 334.848 163.797 336.247C163.797 337.645 164.93 338.779 166.329 338.779Z" />
      <path d="M166.329 369.162C167.727 369.162 168.861 368.028 168.861 366.63C168.861 365.231 167.727 364.098 166.329 364.098C164.93 364.098 163.797 365.231 163.797 366.63C163.797 368.028 164.93 369.162 166.329 369.162Z" />
      <path d="M176.458 348.906C177.856 348.906 178.99 347.772 178.99 346.374C178.99 344.975 177.856 343.842 176.458 343.842C175.059 343.842 173.926 344.975 173.926 346.374C173.926 347.772 175.059 348.906 176.458 348.906Z" />
      <path d="M196.714 359.035C198.112 359.035 199.246 357.901 199.246 356.503C199.246 355.104 198.112 353.971 196.714 353.971C195.315 353.971 194.182 355.104 194.182 356.503C194.182 357.901 195.315 359.035 196.714 359.035Z" />
      <path d="M196.714 379.29C198.112 379.29 199.246 378.157 199.246 376.759C199.246 375.36 198.112 374.227 196.714 374.227C195.315 374.227 194.182 375.36 194.182 376.759C194.182 378.157 195.315 379.29 196.714 379.29Z" />
      <path d="M257.48 359.035C258.879 359.035 260.012 357.901 260.012 356.503C260.012 355.104 258.879 353.971 257.48 353.971C256.082 353.971 254.948 355.104 254.948 356.503C254.948 357.901 256.082 359.035 257.48 359.035Z" />
      <path d="M277.736 369.162C279.134 369.162 280.268 368.028 280.268 366.63C280.268 365.231 279.134 364.098 277.736 364.098C276.338 364.098 275.204 365.231 275.204 366.63C275.204 368.028 276.338 369.162 277.736 369.162Z" />
      <path d="M297.992 359.035C299.39 359.035 300.524 357.901 300.524 356.503C300.524 355.104 299.39 353.971 297.992 353.971C296.594 353.971 295.46 355.104 295.46 356.503C295.46 357.901 296.594 359.035 297.992 359.035Z" />
      <path d="M308.12 348.906C309.518 348.906 310.652 347.772 310.652 346.374C310.652 344.975 309.518 343.842 308.12 343.842C306.721 343.842 305.588 344.975 305.588 346.374C305.588 347.772 306.721 348.906 308.12 348.906Z" />
      <path d="M318.247 338.779C319.645 338.779 320.779 337.645 320.779 336.247C320.779 334.848 319.645 333.715 318.247 333.715C316.848 333.715 315.715 334.848 315.715 336.247C315.715 337.645 316.848 338.779 318.247 338.779Z" />
      <path d="M328.376 318.523C329.774 318.523 330.908 317.389 330.908 315.991C330.908 314.593 329.774 313.459 328.376 313.459C326.977 313.459 325.844 314.593 325.844 315.991C325.844 317.389 326.977 318.523 328.376 318.523Z" />
      <path d="M135.946 207.216C137.344 207.216 138.478 206.083 138.478 204.684C138.478 203.286 137.344 202.152 135.946 202.152C134.548 202.152 133.414 203.286 133.414 204.684C133.414 206.083 134.548 207.216 135.946 207.216Z" />
      <path d="M146.074 207.216C147.472 207.216 148.606 206.083 148.606 204.684C148.606 203.286 147.472 202.152 146.074 202.152C144.676 202.152 143.542 203.286 143.542 204.684C143.542 206.083 144.676 207.216 146.074 207.216Z" />
      <path d="M146.074 288.24C147.472 288.24 148.606 287.106 148.606 285.708C148.606 284.309 147.472 283.176 146.074 283.176C144.676 283.176 143.542 284.309 143.542 285.708C143.542 287.106 144.676 288.24 146.074 288.24Z" />
      <path d="M146.074 298.368C147.472 298.368 148.606 297.234 148.606 295.836C148.606 294.437 147.472 293.304 146.074 293.304C144.676 293.304 143.542 294.437 143.542 295.836C143.542 297.234 144.676 298.368 146.074 298.368Z" />
      <path d="M287.864 217.345C289.262 217.345 290.396 216.212 290.396 214.813C290.396 213.415 289.262 212.281 287.864 212.281C286.466 212.281 285.332 213.415 285.332 214.813C285.332 216.212 286.466 217.345 287.864 217.345Z" />
      <path d="M297.992 227.472C299.39 227.472 300.524 226.339 300.524 224.94C300.524 223.542 299.39 222.408 297.992 222.408C296.594 222.408 295.46 223.542 295.46 224.94C295.46 226.339 296.594 227.472 297.992 227.472Z" />
      <path d="M297.992 237.601C299.39 237.601 300.524 236.467 300.524 235.069C300.524 233.671 299.39 232.537 297.992 232.537C296.594 232.537 295.46 233.671 295.46 235.069C295.46 236.467 296.594 237.601 297.992 237.601Z" />
      <path d="M328.376 207.216C329.774 207.216 330.908 206.083 330.908 204.684C330.908 203.286 329.774 202.152 328.376 202.152C326.977 202.152 325.844 203.286 325.844 204.684C325.844 206.083 326.977 207.216 328.376 207.216Z" />
      <path d="M338.503 298.368C339.901 298.368 341.035 297.234 341.035 295.836C341.035 294.437 339.901 293.304 338.503 293.304C337.104 293.304 335.971 294.437 335.971 295.836C335.971 297.234 337.104 298.368 338.503 298.368Z" />
    </g>
    <path d="M318.247 227.472C319.645 227.472 320.779 226.339 320.779 224.94C320.779 223.542 319.645 222.408 318.247 222.408C316.848 222.408 315.715 223.542 315.715 224.94C315.715 226.339 316.848 227.472 318.247 227.472Z" />
    <path d="M146.074 146.905C147.472 146.905 148.606 145.771 148.606 144.373C148.606 142.974 147.472 141.841 146.074 141.841C144.676 141.841 143.542 142.974 143.542 144.373C143.542 145.771 144.676 146.905 146.074 146.905Z" />
    <path d="M146.074 157.033C147.472 157.033 148.606 155.899 148.606 154.501C148.606 153.102 147.472 151.969 146.074 151.969C144.676 151.969 143.542 153.102 143.542 154.501C143.542 155.899 144.676 157.033 146.074 157.033Z" />
    <path d="M146.074 167.161C147.472 167.161 148.606 166.027 148.606 164.629C148.606 163.23 147.472 162.097 146.074 162.097C144.676 162.097 143.542 163.23 143.542 164.629C143.542 166.027 144.676 167.161 146.074 167.161Z" />
    <path d="M146.074 177.289C147.472 177.289 148.606 176.155 148.606 174.757C148.606 173.358 147.472 172.225 146.074 172.225C144.676 172.225 143.542 173.358 143.542 174.757C143.542 176.155 144.676 177.289 146.074 177.289Z" />
    <path d="M146.074 187.416C147.472 187.416 148.606 186.283 148.606 184.885C148.606 183.486 147.472 182.353 146.074 182.353C144.676 182.353 143.542 183.486 143.542 184.885C143.542 186.283 144.676 187.416 146.074 187.416Z" />
    <path d="M146.074 197.543C147.472 197.543 148.606 196.41 148.606 195.011C148.606 193.613 147.472 192.479 146.074 192.479C144.676 192.479 143.542 193.613 143.542 195.011C143.542 196.41 144.676 197.543 146.074 197.543Z" />
    <path d="M156.202 106.393C157.6 106.393 158.734 105.259 158.734 103.861C158.734 102.463 157.6 101.329 156.202 101.329C154.804 101.329 153.67 102.463 153.67 103.861C153.67 105.259 154.804 106.393 156.202 106.393Z" />
    <path d="M156.202 116.521C157.6 116.521 158.734 115.387 158.734 113.989C158.734 112.591 157.6 111.457 156.202 111.457C154.804 111.457 153.67 112.591 153.67 113.989C153.67 115.387 154.804 116.521 156.202 116.521Z" />
    <path d="M156.202 126.649C157.6 126.649 158.734 125.515 158.734 124.117C158.734 122.719 157.6 121.585 156.202 121.585C154.804 121.585 153.67 122.719 153.67 124.117C153.67 125.515 154.804 126.649 156.202 126.649Z" />
    <path d="M156.202 136.777C157.6 136.777 158.734 135.643 158.734 134.245C158.734 132.846 157.6 131.713 156.202 131.713C154.804 131.713 153.67 132.846 153.67 134.245C153.67 135.643 154.804 136.777 156.202 136.777Z" />
    <path d="M156.202 146.905C157.6 146.905 158.734 145.771 158.734 144.373C158.734 142.974 157.6 141.841 156.202 141.841C154.804 141.841 153.67 142.974 153.67 144.373C153.67 145.771 154.804 146.905 156.202 146.905Z" />
    <path d="M156.202 187.416C157.6 187.416 158.734 186.283 158.734 184.885C158.734 183.486 157.6 182.353 156.202 182.353C154.804 182.353 153.67 183.486 153.67 184.885C153.67 186.283 154.804 187.416 156.202 187.416Z" />
    <path d="M166.329 177.289C167.727 177.289 168.861 176.155 168.861 174.757C168.861 173.358 167.727 172.225 166.329 172.225C164.93 172.225 163.797 173.358 163.797 174.757C163.797 176.155 164.93 177.289 166.329 177.289Z" />
    <path d="M166.329 187.416C167.727 187.416 168.861 186.283 168.861 184.885C168.861 183.486 167.727 182.353 166.329 182.353C164.93 182.353 163.797 183.486 163.797 184.885C163.797 186.283 164.93 187.416 166.329 187.416Z" />
    <path d="M176.458 177.289C177.856 177.289 178.99 176.155 178.99 174.757C178.99 173.358 177.856 172.225 176.458 172.225C175.059 172.225 173.926 173.358 173.926 174.757C173.926 176.155 175.059 177.289 176.458 177.289Z" />
    <path d="M186.585 167.161C187.983 167.161 189.117 166.027 189.117 164.629C189.117 163.23 187.983 162.097 186.585 162.097C185.186 162.097 184.053 163.23 184.053 164.629C184.053 166.027 185.186 167.161 186.585 167.161Z" />
    <path d="M186.585 177.289C187.983 177.289 189.117 176.155 189.117 174.757C189.117 173.358 187.983 172.225 186.585 172.225C185.186 172.225 184.053 173.358 184.053 174.757C184.053 176.155 185.186 177.289 186.585 177.289Z" />
    <path d="M196.714 167.161C198.112 167.161 199.246 166.027 199.246 164.629C199.246 163.23 198.112 162.097 196.714 162.097C195.315 162.097 194.182 163.23 194.182 164.629C194.182 166.027 195.315 167.161 196.714 167.161Z" />
    <path d="M196.714 177.289C198.112 177.289 199.246 176.155 199.246 174.757C199.246 173.358 198.112 172.225 196.714 172.225C195.315 172.225 194.182 173.358 194.182 174.757C194.182 176.155 195.315 177.289 196.714 177.289Z" />
    <path d="M206.841 167.161C208.239 167.161 209.373 166.027 209.373 164.629C209.373 163.23 208.239 162.097 206.841 162.097C205.442 162.097 204.309 163.23 204.309 164.629C204.309 166.027 205.442 167.161 206.841 167.161Z" />
    <path d="M206.841 177.289C208.239 177.289 209.373 176.155 209.373 174.757C209.373 173.358 208.239 172.225 206.841 172.225C205.442 172.225 204.309 173.358 204.309 174.757C204.309 176.155 205.442 177.289 206.841 177.289Z" />
    <path d="M216.969 157.033C218.368 157.033 219.501 155.899 219.501 154.501C219.501 153.102 218.368 151.969 216.969 151.969C215.571 151.969 214.438 153.102 214.438 154.501C214.438 155.899 215.571 157.033 216.969 157.033Z" />
    <path d="M216.969 167.161C218.368 167.161 219.501 166.027 219.501 164.629C219.501 163.23 218.368 162.097 216.969 162.097C215.571 162.097 214.438 163.23 214.438 164.629C214.438 166.027 215.571 167.161 216.969 167.161Z" />
    <path d="M216.969 177.289C218.368 177.289 219.501 176.155 219.501 174.757C219.501 173.358 218.368 172.225 216.969 172.225C215.571 172.225 214.438 173.358 214.438 174.757C214.438 176.155 215.571 177.289 216.969 177.289Z" />
    <path d="M227.096 157.033C228.495 157.033 229.628 155.899 229.628 154.501C229.628 153.102 228.495 151.969 227.096 151.969C225.698 151.969 224.564 153.102 224.564 154.501C224.564 155.899 225.698 157.033 227.096 157.033Z" />
    <path d="M227.096 167.161C228.495 167.161 229.628 166.027 229.628 164.629C229.628 163.23 228.495 162.097 227.096 162.097C225.698 162.097 224.564 163.23 224.564 164.629C224.564 166.027 225.698 167.161 227.096 167.161Z" />
    <path d="M227.096 177.289C228.495 177.289 229.628 176.155 229.628 174.757C229.628 173.358 228.495 172.225 227.096 172.225C225.698 172.225 224.564 173.358 224.564 174.757C224.564 176.155 225.698 177.289 227.096 177.289Z" />
    <path d="M237.225 157.033C238.624 157.033 239.757 155.899 239.757 154.501C239.757 153.102 238.624 151.969 237.225 151.969C235.827 151.969 234.693 153.102 234.693 154.501C234.693 155.899 235.827 157.033 237.225 157.033Z" />
    <path d="M237.225 167.161C238.624 167.161 239.757 166.027 239.757 164.629C239.757 163.23 238.624 162.097 237.225 162.097C235.827 162.097 234.693 163.23 234.693 164.629C234.693 166.027 235.827 167.161 237.225 167.161Z" />
    <path d="M237.225 177.289C238.624 177.289 239.757 176.155 239.757 174.757C239.757 173.358 238.624 172.225 237.225 172.225C235.827 172.225 234.693 173.358 234.693 174.757C234.693 176.155 235.827 177.289 237.225 177.289Z" />
    <path d="M247.352 157.033C248.751 157.033 249.884 155.899 249.884 154.501C249.884 153.102 248.751 151.969 247.352 151.969C245.954 151.969 244.82 153.102 244.82 154.501C244.82 155.899 245.954 157.033 247.352 157.033Z" />
    <path d="M247.352 167.161C248.751 167.161 249.884 166.027 249.884 164.629C249.884 163.23 248.751 162.097 247.352 162.097C245.954 162.097 244.82 163.23 244.82 164.629C244.82 166.027 245.954 167.161 247.352 167.161Z" />
    <path d="M247.352 177.289C248.751 177.289 249.884 176.155 249.884 174.757C249.884 173.358 248.751 172.225 247.352 172.225C245.954 172.225 244.82 173.358 244.82 174.757C244.82 176.155 245.954 177.289 247.352 177.289Z" />
    <path d="M257.48 167.161C258.879 167.161 260.012 166.027 260.012 164.629C260.012 163.23 258.879 162.097 257.48 162.097C256.082 162.097 254.948 163.23 254.948 164.629C254.948 166.027 256.082 167.161 257.48 167.161Z" />
    <path d="M257.48 177.289C258.879 177.289 260.012 176.155 260.012 174.757C260.012 173.358 258.879 172.225 257.48 172.225C256.082 172.225 254.948 173.358 254.948 174.757C254.948 176.155 256.082 177.289 257.48 177.289Z" />
    <path d="M267.608 167.161C269.007 167.161 270.14 166.027 270.14 164.629C270.14 163.23 269.007 162.097 267.608 162.097C266.21 162.097 265.076 163.23 265.076 164.629C265.076 166.027 266.21 167.161 267.608 167.161Z" />
    <path d="M267.608 177.289C269.007 177.289 270.14 176.155 270.14 174.757C270.14 173.358 269.007 172.225 267.608 172.225C266.21 172.225 265.076 173.358 265.076 174.757C265.076 176.155 266.21 177.289 267.608 177.289Z" />
    <path d="M267.608 187.416C269.007 187.416 270.14 186.283 270.14 184.885C270.14 183.486 269.007 182.353 267.608 182.353C266.21 182.353 265.076 183.486 265.076 184.885C265.076 186.283 266.21 187.416 267.608 187.416Z" />
    <path d="M277.736 167.161C279.134 167.161 280.268 166.027 280.268 164.629C280.268 163.23 279.134 162.097 277.736 162.097C276.338 162.097 275.204 163.23 275.204 164.629C275.204 166.027 276.338 167.161 277.736 167.161Z" />
    <path d="M277.736 177.289C279.134 177.289 280.268 176.155 280.268 174.757C280.268 173.358 279.134 172.225 277.736 172.225C276.338 172.225 275.204 173.358 275.204 174.757C275.204 176.155 276.338 177.289 277.736 177.289Z" />
    <path d="M277.736 187.416C279.134 187.416 280.268 186.283 280.268 184.885C280.268 183.486 279.134 182.353 277.736 182.353C276.338 182.353 275.204 183.486 275.204 184.885C275.204 186.283 276.338 187.416 277.736 187.416Z" />
    <path d="M277.736 197.543C279.134 197.543 280.268 196.41 280.268 195.011C280.268 193.613 279.134 192.479 277.736 192.479C276.338 192.479 275.204 193.613 275.204 195.011C275.204 196.41 276.338 197.543 277.736 197.543Z" />
    <path d="M287.864 177.289C289.262 177.289 290.396 176.155 290.396 174.757C290.396 173.358 289.262 172.225 287.864 172.225C286.466 172.225 285.332 173.358 285.332 174.757C285.332 176.155 286.466 177.289 287.864 177.289Z" />
    <path d="M287.864 187.416C289.262 187.416 290.396 186.283 290.396 184.885C290.396 183.486 289.262 182.353 287.864 182.353C286.466 182.353 285.332 183.486 285.332 184.885C285.332 186.283 286.466 187.416 287.864 187.416Z" />
    <path d="M287.864 197.543C289.262 197.543 290.396 196.41 290.396 195.011C290.396 193.613 289.262 192.479 287.864 192.479C286.466 192.479 285.332 193.613 285.332 195.011C285.332 196.41 286.466 197.543 287.864 197.543Z" />
    <path d="M297.992 187.416C299.39 187.416 300.524 186.283 300.524 184.885C300.524 183.486 299.39 182.353 297.992 182.353C296.594 182.353 295.46 183.486 295.46 184.885C295.46 186.283 296.594 187.416 297.992 187.416Z" />
    <path d="M297.992 197.543C299.39 197.543 300.524 196.41 300.524 195.011C300.524 193.613 299.39 192.479 297.992 192.479C296.594 192.479 295.46 193.613 295.46 195.011C295.46 196.41 296.594 197.543 297.992 197.543Z" />
    <path d="M308.12 197.543C309.518 197.543 310.652 196.41 310.652 195.011C310.652 193.613 309.518 192.479 308.12 192.479C306.721 192.479 305.588 193.613 305.588 195.011C305.588 196.41 306.721 197.543 308.12 197.543Z" />
    <path d="M156.202 76.1098C157.6 76.1098 158.734 74.9762 158.734 73.5779C158.734 72.1795 157.6 71.0459 156.202 71.0459C154.804 71.0459 153.67 72.1795 153.67 73.5779C153.67 74.9762 154.804 76.1098 156.202 76.1098Z" />
    <path d="M156.202 86.2387C157.6 86.2387 158.734 85.1051 158.734 83.7068C158.734 82.3084 157.6 81.1748 156.202 81.1748C154.804 81.1748 153.67 82.3084 153.67 83.7068C153.67 85.1051 154.804 86.2387 156.202 86.2387Z" />
    <path d="M156.202 96.3657C157.6 96.3657 158.734 95.2321 158.734 93.8337C158.734 92.4354 157.6 91.3018 156.202 91.3018C154.804 91.3018 153.67 92.4354 153.67 93.8337C153.67 95.2321 154.804 96.3657 156.202 96.3657Z" />
    <path d="M166.329 35.5991C167.727 35.5991 168.861 34.4655 168.861 33.0671C168.861 31.6688 167.727 30.5352 166.329 30.5352C164.93 30.5352 163.797 31.6688 163.797 33.0671C163.797 34.4655 164.93 35.5991 166.329 35.5991Z" />
    <path d="M166.329 45.727C167.727 45.727 168.861 44.5934 168.861 43.1951C168.861 41.7967 167.727 40.6631 166.329 40.6631C164.93 40.6631 163.797 41.7967 163.797 43.1951C163.797 44.5934 164.93 45.727 166.329 45.727Z" />
    <path d="M166.329 55.854C167.727 55.854 168.861 54.7204 168.861 53.322C168.861 51.9236 167.727 50.79 166.329 50.79C164.93 50.79 163.797 51.9236 163.797 53.322C163.797 54.7204 164.93 55.854 166.329 55.854Z" />
    <path d="M166.329 65.9829C167.727 65.9829 168.861 64.8493 168.861 63.4509C168.861 62.0525 167.727 60.9189 166.329 60.9189C164.93 60.9189 163.797 62.0525 163.797 63.4509C163.797 64.8493 164.93 65.9829 166.329 65.9829Z" />
    <path d="M166.329 76.1098C167.727 76.1098 168.861 74.9762 168.861 73.5779C168.861 72.1795 167.727 71.0459 166.329 71.0459C164.93 71.0459 163.797 72.1795 163.797 73.5779C163.797 74.9762 164.93 76.1098 166.329 76.1098Z" />
    <path d="M166.329 86.2387C167.727 86.2387 168.861 85.1051 168.861 83.7068C168.861 82.3084 167.727 81.1748 166.329 81.1748C164.93 81.1748 163.797 82.3084 163.797 83.7068C163.797 85.1051 164.93 86.2387 166.329 86.2387Z" />
    <path d="M166.329 96.3657C167.727 96.3657 168.861 95.2321 168.861 93.8337C168.861 92.4354 167.727 91.3018 166.329 91.3018C164.93 91.3018 163.797 92.4354 163.797 93.8337C163.797 95.2321 164.93 96.3657 166.329 96.3657Z" />
    <path d="M176.458 35.5991C177.856 35.5991 178.99 34.4655 178.99 33.0671C178.99 31.6688 177.856 30.5352 176.458 30.5352C175.059 30.5352 173.926 31.6688 173.926 33.0671C173.926 34.4655 175.059 35.5991 176.458 35.5991Z" />
    <path d="M176.458 45.727C177.856 45.727 178.99 44.5934 178.99 43.1951C178.99 41.7967 177.856 40.6631 176.458 40.6631C175.059 40.6631 173.926 41.7967 173.926 43.1951C173.926 44.5934 175.059 45.727 176.458 45.727Z" />
    <path d="M176.458 55.854C177.856 55.854 178.99 54.7204 178.99 53.322C178.99 51.9236 177.856 50.79 176.458 50.79C175.059 50.79 173.926 51.9236 173.926 53.322C173.926 54.7204 175.059 55.854 176.458 55.854Z" />
    <path d="M186.585 35.5991C187.983 35.5991 189.117 34.4655 189.117 33.0671C189.117 31.6688 187.983 30.5352 186.585 30.5352C185.186 30.5352 184.053 31.6688 184.053 33.0671C184.053 34.4655 185.186 35.5991 186.585 35.5991Z" />
    <path d="M186.585 45.727C187.983 45.727 189.117 44.5934 189.117 43.1951C189.117 41.7967 187.983 40.6631 186.585 40.6631C185.186 40.6631 184.053 41.7967 184.053 43.1951C184.053 44.5934 185.186 45.727 186.585 45.727Z" />
    <path d="M186.585 55.854C187.983 55.854 189.117 54.7204 189.117 53.322C189.117 51.9236 187.983 50.79 186.585 50.79C185.186 50.79 184.053 51.9236 184.053 53.322C184.053 54.7204 185.186 55.854 186.585 55.854Z" />
    <path d="M196.714 35.5991C198.112 35.5991 199.246 34.4655 199.246 33.0671C199.246 31.6688 198.112 30.5352 196.714 30.5352C195.315 30.5352 194.182 31.6688 194.182 33.0671C194.182 34.4655 195.315 35.5991 196.714 35.5991Z" />
    <path d="M196.714 45.727C198.112 45.727 199.246 44.5934 199.246 43.1951C199.246 41.7967 198.112 40.6631 196.714 40.6631C195.315 40.6631 194.182 41.7967 194.182 43.1951C194.182 44.5934 195.315 45.727 196.714 45.727Z" />
    <path d="M196.714 55.854C198.112 55.854 199.246 54.7204 199.246 53.322C199.246 51.9236 198.112 50.79 196.714 50.79C195.315 50.79 194.182 51.9236 194.182 53.322C194.182 54.7204 195.315 55.854 196.714 55.854Z" />
    <path d="M206.841 35.5991C208.239 35.5991 209.373 34.4655 209.373 33.0671C209.373 31.6688 208.239 30.5352 206.841 30.5352C205.442 30.5352 204.309 31.6688 204.309 33.0671C204.309 34.4655 205.442 35.5991 206.841 35.5991Z" />
    <path d="M206.841 45.727C208.239 45.727 209.373 44.5934 209.373 43.1951C209.373 41.7967 208.239 40.6631 206.841 40.6631C205.442 40.6631 204.309 41.7967 204.309 43.1951C204.309 44.5934 205.442 45.727 206.841 45.727Z" />
    <path d="M206.841 55.854C208.239 55.854 209.373 54.7204 209.373 53.322C209.373 51.9236 208.239 50.79 206.841 50.79C205.442 50.79 204.309 51.9236 204.309 53.322C204.309 54.7204 205.442 55.854 206.841 55.854Z" />
    <path d="M216.969 35.5991C218.368 35.5991 219.501 34.4655 219.501 33.0671C219.501 31.6688 218.368 30.5352 216.969 30.5352C215.571 30.5352 214.438 31.6688 214.438 33.0671C214.438 34.4655 215.571 35.5991 216.969 35.5991Z" />
    <path d="M216.969 45.727C218.368 45.727 219.501 44.5934 219.501 43.1951C219.501 41.7967 218.368 40.6631 216.969 40.6631C215.571 40.6631 214.438 41.7967 214.438 43.1951C214.438 44.5934 215.571 45.727 216.969 45.727Z" />
    <path d="M216.969 55.854C218.368 55.854 219.501 54.7204 219.501 53.322C219.501 51.9236 218.368 50.79 216.969 50.79C215.571 50.79 214.438 51.9236 214.438 53.322C214.438 54.7204 215.571 55.854 216.969 55.854Z" />
    <path d="M227.096 35.5991C228.495 35.5991 229.628 34.4655 229.628 33.0671C229.628 31.6688 228.495 30.5352 227.096 30.5352C225.698 30.5352 224.564 31.6688 224.564 33.0671C224.564 34.4655 225.698 35.5991 227.096 35.5991Z" />
    <path d="M227.096 45.727C228.495 45.727 229.628 44.5934 229.628 43.1951C229.628 41.7967 228.495 40.6631 227.096 40.6631C225.698 40.6631 224.564 41.7967 224.564 43.1951C224.564 44.5934 225.698 45.727 227.096 45.727Z" />
    <path d="M227.096 55.854C228.495 55.854 229.628 54.7204 229.628 53.322C229.628 51.9236 228.495 50.79 227.096 50.79C225.698 50.79 224.564 51.9236 224.564 53.322C224.564 54.7204 225.698 55.854 227.096 55.854Z" />
    <path d="M237.225 35.5991C238.624 35.5991 239.757 34.4655 239.757 33.0671C239.757 31.6688 238.624 30.5352 237.225 30.5352C235.827 30.5352 234.693 31.6688 234.693 33.0671C234.693 34.4655 235.827 35.5991 237.225 35.5991Z" />
    <path d="M237.225 45.727C238.624 45.727 239.757 44.5934 239.757 43.1951C239.757 41.7967 238.624 40.6631 237.225 40.6631C235.827 40.6631 234.693 41.7967 234.693 43.1951C234.693 44.5934 235.827 45.727 237.225 45.727Z" />
    <path d="M237.225 55.854C238.624 55.854 239.757 54.7204 239.757 53.322C239.757 51.9236 238.624 50.79 237.225 50.79C235.827 50.79 234.693 51.9236 234.693 53.322C234.693 54.7204 235.827 55.854 237.225 55.854Z" />
    <path d="M247.352 35.5991C248.751 35.5991 249.884 34.4655 249.884 33.0671C249.884 31.6688 248.751 30.5352 247.352 30.5352C245.954 30.5352 244.82 31.6688 244.82 33.0671C244.82 34.4655 245.954 35.5991 247.352 35.5991Z" />
    <path d="M247.352 45.727C248.751 45.727 249.884 44.5934 249.884 43.1951C249.884 41.7967 248.751 40.6631 247.352 40.6631C245.954 40.6631 244.82 41.7967 244.82 43.1951C244.82 44.5934 245.954 45.727 247.352 45.727Z" />
    <path d="M247.352 55.854C248.751 55.854 249.884 54.7204 249.884 53.322C249.884 51.9236 248.751 50.79 247.352 50.79C245.954 50.79 244.82 51.9236 244.82 53.322C244.82 54.7204 245.954 55.854 247.352 55.854Z" />
    <path d="M257.48 35.5991C258.879 35.5991 260.012 34.4655 260.012 33.0671C260.012 31.6688 258.879 30.5352 257.48 30.5352C256.082 30.5352 254.948 31.6688 254.948 33.0671C254.948 34.4655 256.082 35.5991 257.48 35.5991Z" />
    <path d="M257.48 45.727C258.879 45.727 260.012 44.5934 260.012 43.1951C260.012 41.7967 258.879 40.6631 257.48 40.6631C256.082 40.6631 254.948 41.7967 254.948 43.1951C254.948 44.5934 256.082 45.727 257.48 45.727Z" />
    <path d="M257.48 55.854C258.879 55.854 260.012 54.7204 260.012 53.322C260.012 51.9236 258.879 50.79 257.48 50.79C256.082 50.79 254.948 51.9236 254.948 53.322C254.948 54.7204 256.082 55.854 257.48 55.854Z" />
    <path d="M267.608 35.5991C269.007 35.5991 270.14 34.4655 270.14 33.0671C270.14 31.6688 269.007 30.5352 267.608 30.5352C266.21 30.5352 265.076 31.6688 265.076 33.0671C265.076 34.4655 266.21 35.5991 267.608 35.5991Z" />
    <path d="M267.608 45.727C269.007 45.727 270.14 44.5934 270.14 43.1951C270.14 41.7967 269.007 40.6631 267.608 40.6631C266.21 40.6631 265.076 41.7967 265.076 43.1951C265.076 44.5934 266.21 45.727 267.608 45.727Z" />
    <path d="M267.608 55.854C269.007 55.854 270.14 54.7204 270.14 53.322C270.14 51.9236 269.007 50.79 267.608 50.79C266.21 50.79 265.076 51.9236 265.076 53.322C265.076 54.7204 266.21 55.854 267.608 55.854Z" />
    <path d="M277.736 35.5991C279.134 35.5991 280.268 34.4655 280.268 33.0671C280.268 31.6688 279.134 30.5352 277.736 30.5352C276.338 30.5352 275.204 31.6688 275.204 33.0671C275.204 34.4655 276.338 35.5991 277.736 35.5991Z" />
    <path d="M277.736 45.727C279.134 45.727 280.268 44.5934 280.268 43.1951C280.268 41.7967 279.134 40.6631 277.736 40.6631C276.338 40.6631 275.204 41.7967 275.204 43.1951C275.204 44.5934 276.338 45.727 277.736 45.727Z" />
    <path d="M277.736 55.854C279.134 55.854 280.268 54.7204 280.268 53.322C280.268 51.9236 279.134 50.79 277.736 50.79C276.338 50.79 275.204 51.9236 275.204 53.322C275.204 54.7204 276.338 55.854 277.736 55.854Z" />
    <path d="M287.864 35.5991C289.262 35.5991 290.396 34.4655 290.396 33.0671C290.396 31.6688 289.262 30.5352 287.864 30.5352C286.466 30.5352 285.332 31.6688 285.332 33.0671C285.332 34.4655 286.466 35.5991 287.864 35.5991Z" />
    <path d="M287.864 45.727C289.262 45.727 290.396 44.5934 290.396 43.1951C290.396 41.7967 289.262 40.6631 287.864 40.6631C286.466 40.6631 285.332 41.7967 285.332 43.1951C285.332 44.5934 286.466 45.727 287.864 45.727Z" />
    <path d="M287.864 55.854C289.262 55.854 290.396 54.7204 290.396 53.322C290.396 51.9236 289.262 50.79 287.864 50.79C286.466 50.79 285.332 51.9236 285.332 53.322C285.332 54.7204 286.466 55.854 287.864 55.854Z" />
    <path d="M297.992 35.5991C299.39 35.5991 300.524 34.4655 300.524 33.0671C300.524 31.6688 299.39 30.5352 297.992 30.5352C296.594 30.5352 295.46 31.6688 295.46 33.0671C295.46 34.4655 296.594 35.5991 297.992 35.5991Z" />
    <path d="M297.992 45.727C299.39 45.727 300.524 44.5934 300.524 43.1951C300.524 41.7967 299.39 40.6631 297.992 40.6631C296.594 40.6631 295.46 41.7967 295.46 43.1951C295.46 44.5934 296.594 45.727 297.992 45.727Z" />
    <path d="M297.992 55.854C299.39 55.854 300.524 54.7204 300.524 53.322C300.524 51.9236 299.39 50.79 297.992 50.79C296.594 50.79 295.46 51.9236 295.46 53.322C295.46 54.7204 296.594 55.854 297.992 55.854Z" />
    <path d="M308.12 35.5991C309.518 35.5991 310.652 34.4655 310.652 33.0671C310.652 31.6688 309.518 30.5352 308.12 30.5352C306.721 30.5352 305.588 31.6688 305.588 33.0671C305.588 34.4655 306.721 35.5991 308.12 35.5991Z" />
    <path d="M308.12 45.727C309.518 45.727 310.652 44.5934 310.652 43.1951C310.652 41.7967 309.518 40.6631 308.12 40.6631C306.721 40.6631 305.588 41.7967 305.588 43.1951C305.588 44.5934 306.721 45.727 308.12 45.727Z" />
    <path d="M308.12 55.854C309.518 55.854 310.652 54.7204 310.652 53.322C310.652 51.9236 309.518 50.79 308.12 50.79C306.721 50.79 305.588 51.9236 305.588 53.322C305.588 54.7204 306.721 55.854 308.12 55.854Z" />
    <path d="M318.247 35.5991C319.645 35.5991 320.779 34.4655 320.779 33.0671C320.779 31.6688 319.645 30.5352 318.247 30.5352C316.848 30.5352 315.715 31.6688 315.715 33.0671C315.715 34.4655 316.848 35.5991 318.247 35.5991Z" />
    <path d="M318.247 45.727C319.645 45.727 320.779 44.5934 320.779 43.1951C320.779 41.7967 319.645 40.6631 318.247 40.6631C316.848 40.6631 315.715 41.7967 315.715 43.1951C315.715 44.5934 316.848 45.727 318.247 45.727Z" />
    <path d="M318.247 55.854C319.645 55.854 320.779 54.7204 320.779 53.322C320.779 51.9236 319.645 50.79 318.247 50.79C316.848 50.79 315.715 51.9236 315.715 53.322C315.715 54.7204 316.848 55.854 318.247 55.854Z" />
    <path d="M115.69 308.393C117.089 308.393 118.222 307.259 118.222 305.861C118.222 304.463 117.089 303.329 115.69 303.329C114.292 303.329 113.158 304.463 113.158 305.861C113.158 307.259 114.292 308.393 115.69 308.393Z" />
    <path d="M115.69 318.521C117.089 318.521 118.222 317.387 118.222 315.989C118.222 314.591 117.089 313.457 115.69 313.457C114.292 313.457 113.158 314.591 113.158 315.989C113.158 317.387 114.292 318.521 115.69 318.521Z" />
    <path d="M125.818 318.521C127.216 318.521 128.35 317.387 128.35 315.989C128.35 314.591 127.216 313.457 125.818 313.457C124.42 313.457 123.286 314.591 123.286 315.989C123.286 317.387 124.42 318.521 125.818 318.521Z" />
    <path d="M125.818 328.649C127.216 328.649 128.35 327.515 128.35 326.117C128.35 324.719 127.216 323.585 125.818 323.585C124.42 323.585 123.286 324.719 123.286 326.117C123.286 327.515 124.42 328.649 125.818 328.649Z" />
    <path d="M135.946 308.393C137.344 308.393 138.478 307.259 138.478 305.861C138.478 304.463 137.344 303.329 135.946 303.329C134.548 303.329 133.414 304.463 133.414 305.861C133.414 307.259 134.548 308.393 135.946 308.393Z" />
    <path d="M135.946 318.521C137.344 318.521 138.478 317.387 138.478 315.989C138.478 314.591 137.344 313.457 135.946 313.457C134.548 313.457 133.414 314.591 133.414 315.989C133.414 317.387 134.548 318.521 135.946 318.521Z" />
    <path d="M135.946 328.649C137.344 328.649 138.478 327.515 138.478 326.117C138.478 324.719 137.344 323.585 135.946 323.585C134.548 323.585 133.414 324.719 133.414 326.117C133.414 327.515 134.548 328.649 135.946 328.649Z" />
    <path d="M135.946 348.905C137.344 348.905 138.478 347.771 138.478 346.373C138.478 344.974 137.344 343.841 135.946 343.841C134.548 343.841 133.414 344.974 133.414 346.373C133.414 347.771 134.548 348.905 135.946 348.905Z" />
    <path d="M146.074 328.649C147.472 328.649 148.606 327.515 148.606 326.117C148.606 324.719 147.472 323.585 146.074 323.585C144.676 323.585 143.542 324.719 143.542 326.117C143.542 327.515 144.676 328.649 146.074 328.649Z" />
    <path d="M146.074 338.777C147.472 338.777 148.606 337.643 148.606 336.245C148.606 334.846 147.472 333.713 146.074 333.713C144.676 333.713 143.542 334.846 143.542 336.245C143.542 337.643 144.676 338.777 146.074 338.777Z" />
    <path d="M146.074 348.905C147.472 348.905 148.606 347.771 148.606 346.373C148.606 344.974 147.472 343.841 146.074 343.841C144.676 343.841 143.542 344.974 143.542 346.373C143.542 347.771 144.676 348.905 146.074 348.905Z" />
    <path d="M146.074 359.033C147.472 359.033 148.606 357.899 148.606 356.501C148.606 355.102 147.472 353.969 146.074 353.969C144.676 353.969 143.542 355.102 143.542 356.501C143.542 357.899 144.676 359.033 146.074 359.033Z" />
    <path d="M156.202 338.777C157.6 338.777 158.734 337.643 158.734 336.245C158.734 334.846 157.6 333.713 156.202 333.713C154.804 333.713 153.67 334.846 153.67 336.245C153.67 337.643 154.804 338.777 156.202 338.777Z" />
    <path d="M156.202 348.905C157.6 348.905 158.734 347.771 158.734 346.373C158.734 344.974 157.6 343.841 156.202 343.841C154.804 343.841 153.67 344.974 153.67 346.373C153.67 347.771 154.804 348.905 156.202 348.905Z" />
    <path d="M156.202 359.033C157.6 359.033 158.734 357.899 158.734 356.501C158.734 355.102 157.6 353.969 156.202 353.969C154.804 353.969 153.67 355.102 153.67 356.501C153.67 357.899 154.804 359.033 156.202 359.033Z" />
    <path d="M166.329 348.905C167.727 348.905 168.861 347.771 168.861 346.373C168.861 344.974 167.727 343.841 166.329 343.841C164.93 343.841 163.797 344.974 163.797 346.373C163.797 347.771 164.93 348.905 166.329 348.905Z" />
    <path d="M166.329 359.033C167.727 359.033 168.861 357.899 168.861 356.501C168.861 355.102 167.727 353.969 166.329 353.969C164.93 353.969 163.797 355.102 163.797 356.501C163.797 357.899 164.93 359.033 166.329 359.033Z" />
    <path d="M176.458 359.033C177.856 359.033 178.99 357.899 178.99 356.501C178.99 355.102 177.856 353.969 176.458 353.969C175.059 353.969 173.926 355.102 173.926 356.501C173.926 357.899 175.059 359.033 176.458 359.033Z" />
    <path d="M176.458 369.161C177.856 369.161 178.99 368.027 178.99 366.629C178.99 365.23 177.856 364.097 176.458 364.097C175.059 364.097 173.926 365.23 173.926 366.629C173.926 368.027 175.059 369.161 176.458 369.161Z" />
    <path d="M186.585 359.033C187.983 359.033 189.117 357.899 189.117 356.501C189.117 355.102 187.983 353.969 186.585 353.969C185.186 353.969 184.053 355.102 184.053 356.501C184.053 357.899 185.186 359.033 186.585 359.033Z" />
    <path d="M186.585 369.161C187.983 369.161 189.117 368.027 189.117 366.629C189.117 365.23 187.983 364.097 186.585 364.097C185.186 364.097 184.053 365.23 184.053 366.629C184.053 368.027 185.186 369.161 186.585 369.161Z" />
    <path d="M196.714 369.161C198.112 369.161 199.246 368.027 199.246 366.629C199.246 365.23 198.112 364.097 196.714 364.097C195.315 364.097 194.182 365.23 194.182 366.629C194.182 368.027 195.315 369.161 196.714 369.161Z" />
    <path d="M206.841 369.161C208.239 369.161 209.373 368.027 209.373 366.629C209.373 365.23 208.239 364.097 206.841 364.097C205.442 364.097 204.309 365.23 204.309 366.629C204.309 368.027 205.442 369.161 206.841 369.161Z" />
    <path d="M206.841 379.289C208.239 379.289 209.373 378.155 209.373 376.757C209.373 375.358 208.239 374.225 206.841 374.225C205.442 374.225 204.309 375.358 204.309 376.757C204.309 378.155 205.442 379.289 206.841 379.289Z" />
    <path d="M216.969 369.161C218.368 369.161 219.501 368.027 219.501 366.629C219.501 365.23 218.368 364.097 216.969 364.097C215.571 364.097 214.438 365.23 214.438 366.629C214.438 368.027 215.571 369.161 216.969 369.161Z" />
    <path d="M216.969 379.289C218.368 379.289 219.501 378.155 219.501 376.757C219.501 375.358 218.368 374.225 216.969 374.225C215.571 374.225 214.438 375.358 214.438 376.757C214.438 378.155 215.571 379.289 216.969 379.289Z" />
    <path d="M227.096 369.161C228.495 369.161 229.628 368.027 229.628 366.629C229.628 365.23 228.495 364.097 227.096 364.097C225.698 364.097 224.564 365.23 224.564 366.629C224.564 368.027 225.698 369.161 227.096 369.161Z" />
    <path d="M227.096 379.289C228.495 379.289 229.628 378.155 229.628 376.757C229.628 375.358 228.495 374.225 227.096 374.225C225.698 374.225 224.564 375.358 224.564 376.757C224.564 378.155 225.698 379.289 227.096 379.289Z" />
    <path d="M237.225 369.161C238.624 369.161 239.757 368.027 239.757 366.629C239.757 365.23 238.624 364.097 237.225 364.097C235.827 364.097 234.693 365.23 234.693 366.629C234.693 368.027 235.827 369.161 237.225 369.161Z" />
    <path d="M237.225 379.289C238.624 379.289 239.757 378.155 239.757 376.757C239.757 375.358 238.624 374.225 237.225 374.225C235.827 374.225 234.693 375.358 234.693 376.757C234.693 378.155 235.827 379.289 237.225 379.289Z" />
    <path d="M247.352 369.161C248.751 369.161 249.884 368.027 249.884 366.629C249.884 365.23 248.751 364.097 247.352 364.097C245.954 364.097 244.82 365.23 244.82 366.629C244.82 368.027 245.954 369.161 247.352 369.161Z" />
    <path d="M257.48 369.161C258.879 369.161 260.012 368.027 260.012 366.629C260.012 365.23 258.879 364.097 257.48 364.097C256.082 364.097 254.948 365.23 254.948 366.629C254.948 368.027 256.082 369.161 257.48 369.161Z" />
    <path d="M267.608 359.033C269.007 359.033 270.14 357.899 270.14 356.501C270.14 355.102 269.007 353.969 267.608 353.969C266.21 353.969 265.076 355.102 265.076 356.501C265.076 357.899 266.21 359.033 267.608 359.033Z" />
    <path d="M267.608 369.161C269.007 369.161 270.14 368.027 270.14 366.629C270.14 365.23 269.007 364.097 267.608 364.097C266.21 364.097 265.076 365.23 265.076 366.629C265.076 368.027 266.21 369.161 267.608 369.161Z" />
    <path d="M277.736 348.905C279.134 348.905 280.268 347.771 280.268 346.373C280.268 344.974 279.134 343.841 277.736 343.841C276.338 343.841 275.204 344.974 275.204 346.373C275.204 347.771 276.338 348.905 277.736 348.905Z" />
    <path d="M277.736 359.033C279.134 359.033 280.268 357.899 280.268 356.501C280.268 355.102 279.134 353.969 277.736 353.969C276.338 353.969 275.204 355.102 275.204 356.501C275.204 357.899 276.338 359.033 277.736 359.033Z" />
    <path d="M287.864 338.777C289.262 338.777 290.396 337.643 290.396 336.245C290.396 334.846 289.262 333.713 287.864 333.713C286.466 333.713 285.332 334.846 285.332 336.245C285.332 337.643 286.466 338.777 287.864 338.777Z" />
    <path d="M287.864 348.905C289.262 348.905 290.396 347.771 290.396 346.373C290.396 344.974 289.262 343.841 287.864 343.841C286.466 343.841 285.332 344.974 285.332 346.373C285.332 347.771 286.466 348.905 287.864 348.905Z" />
    <path d="M287.864 359.033C289.262 359.033 290.396 357.899 290.396 356.501C290.396 355.102 289.262 353.969 287.864 353.969C286.466 353.969 285.332 355.102 285.332 356.501C285.332 357.899 286.466 359.033 287.864 359.033Z" />
    <path d="M297.992 318.521C299.39 318.521 300.524 317.387 300.524 315.989C300.524 314.591 299.39 313.457 297.992 313.457C296.594 313.457 295.46 314.591 295.46 315.989C295.46 317.387 296.594 318.521 297.992 318.521Z" />
    <path d="M297.992 328.649C299.39 328.649 300.524 327.515 300.524 326.117C300.524 324.719 299.39 323.585 297.992 323.585C296.594 323.585 295.46 324.719 295.46 326.117C295.46 327.515 296.594 328.649 297.992 328.649Z" />
    <path d="M297.992 338.777C299.39 338.777 300.524 337.643 300.524 336.245C300.524 334.846 299.39 333.713 297.992 333.713C296.594 333.713 295.46 334.846 295.46 336.245C295.46 337.643 296.594 338.777 297.992 338.777Z" />
    <path d="M297.992 348.905C299.39 348.905 300.524 347.771 300.524 346.373C300.524 344.974 299.39 343.841 297.992 343.841C296.594 343.841 295.46 344.974 295.46 346.373C295.46 347.771 296.594 348.905 297.992 348.905Z" />
    <path d="M308.12 308.393C309.518 308.393 310.652 307.259 310.652 305.861C310.652 304.463 309.518 303.329 308.12 303.329C306.721 303.329 305.588 304.463 305.588 305.861C305.588 307.259 306.721 308.393 308.12 308.393Z" />
    <path d="M308.12 318.521C309.518 318.521 310.652 317.387 310.652 315.989C310.652 314.591 309.518 313.457 308.12 313.457C306.721 313.457 305.588 314.591 305.588 315.989C305.588 317.387 306.721 318.521 308.12 318.521Z" />
    <path d="M308.12 328.649C309.518 328.649 310.652 327.515 310.652 326.117C310.652 324.719 309.518 323.585 308.12 323.585C306.721 323.585 305.588 324.719 305.588 326.117C305.588 327.515 306.721 328.649 308.12 328.649Z" />
    <path d="M308.12 338.777C309.518 338.777 310.652 337.643 310.652 336.245C310.652 334.846 309.518 333.713 308.12 333.713C306.721 333.713 305.588 334.846 305.588 336.245C305.588 337.643 306.721 338.777 308.12 338.777Z" />
    <path d="M318.247 308.393C319.645 308.393 320.779 307.259 320.779 305.861C320.779 304.463 319.645 303.329 318.247 303.329C316.848 303.329 315.715 304.463 315.715 305.861C315.715 307.259 316.848 308.393 318.247 308.393Z" />
    <path d="M318.247 318.521C319.645 318.521 320.779 317.387 320.779 315.989C320.779 314.591 319.645 313.457 318.247 313.457C316.848 313.457 315.715 314.591 315.715 315.989C315.715 317.387 316.848 318.521 318.247 318.521Z" />
    <path d="M318.247 328.649C319.645 328.649 320.779 327.515 320.779 326.117C320.779 324.719 319.645 323.585 318.247 323.585C316.848 323.585 315.715 324.719 315.715 326.117C315.715 327.515 316.848 328.649 318.247 328.649Z" />
    <path d="M328.376 308.393C329.774 308.393 330.908 307.259 330.908 305.861C330.908 304.463 329.774 303.329 328.376 303.329C326.977 303.329 325.844 304.463 325.844 305.861C325.844 307.259 326.977 308.393 328.376 308.393Z" />
    <path d="M115.69 298.366C117.089 298.366 118.222 297.232 118.222 295.834C118.222 294.435 117.089 293.302 115.69 293.302C114.292 293.302 113.158 294.435 113.158 295.834C113.158 297.232 114.292 298.366 115.69 298.366Z" />
    <path d="M125.818 298.366C127.216 298.366 128.35 297.232 128.35 295.834C128.35 294.435 127.216 293.302 125.818 293.302C124.42 293.302 123.286 294.435 123.286 295.834C123.286 297.232 124.42 298.366 125.818 298.366Z" />
    <path d="M135.946 288.239C137.344 288.239 138.478 287.105 138.478 285.707C138.478 284.308 137.344 283.175 135.946 283.175C134.548 283.175 133.414 284.308 133.414 285.707C133.414 287.105 134.548 288.239 135.946 288.239Z" />
    <path d="M135.946 298.366C137.344 298.366 138.478 297.232 138.478 295.834C138.478 294.435 137.344 293.302 135.946 293.302C134.548 293.302 133.414 294.435 133.414 295.834C133.414 297.232 134.548 298.366 135.946 298.366Z" />
    <path d="M287.864 207.215C289.262 207.215 290.396 206.082 290.396 204.683C290.396 203.285 289.262 202.151 287.864 202.151C286.466 202.151 285.332 203.285 285.332 204.683C285.332 206.082 286.466 207.215 287.864 207.215Z" />
    <path d="M297.992 207.215C299.39 207.215 300.524 206.082 300.524 204.683C300.524 203.285 299.39 202.151 297.992 202.151C296.594 202.151 295.46 203.285 295.46 204.683C295.46 206.082 296.594 207.215 297.992 207.215Z" />
    <path d="M297.992 217.343C299.39 217.343 300.524 216.21 300.524 214.811C300.524 213.413 299.39 212.279 297.992 212.279C296.594 212.279 295.46 213.413 295.46 214.811C295.46 216.21 296.594 217.343 297.992 217.343Z" />
    <path d="M308.12 207.215C309.518 207.215 310.652 206.082 310.652 204.683C310.652 203.285 309.518 202.151 308.12 202.151C306.721 202.151 305.588 203.285 305.588 204.683C305.588 206.082 306.721 207.215 308.12 207.215Z" />
    <path d="M308.12 217.343C309.518 217.343 310.652 216.21 310.652 214.811C310.652 213.413 309.518 212.279 308.12 212.279C306.721 212.279 305.588 213.413 305.588 214.811C305.588 216.21 306.721 217.343 308.12 217.343Z" />
    <path d="M308.12 227.471C309.518 227.471 310.652 226.338 310.652 224.939C310.652 223.541 309.518 222.407 308.12 222.407C306.721 222.407 305.588 223.541 305.588 224.939C305.588 226.338 306.721 227.471 308.12 227.471Z" />
    <path d="M308.12 237.599C309.518 237.599 310.652 236.465 310.652 235.067C310.652 233.669 309.518 232.535 308.12 232.535C306.721 232.535 305.588 233.669 305.588 235.067C305.588 236.465 306.721 237.599 308.12 237.599Z" />
    <path d="M308.12 247.727C309.518 247.727 310.652 246.593 310.652 245.195C310.652 243.797 309.518 242.663 308.12 242.663C306.721 242.663 305.588 243.797 305.588 245.195C305.588 246.593 306.721 247.727 308.12 247.727Z" />
    <path d="M308.12 257.854C309.518 257.854 310.652 256.72 310.652 255.322C310.652 253.924 309.518 252.79 308.12 252.79C306.721 252.79 305.588 253.924 305.588 255.322C305.588 256.72 306.721 257.854 308.12 257.854Z" />
    <path d="M308.12 267.983C309.518 267.983 310.652 266.849 310.652 265.451C310.652 264.053 309.518 262.919 308.12 262.919C306.721 262.919 305.588 264.053 305.588 265.451C305.588 266.849 306.721 267.983 308.12 267.983Z" />
    <path d="M308.12 278.11C309.518 278.11 310.652 276.976 310.652 275.578C310.652 274.179 309.518 273.046 308.12 273.046C306.721 273.046 305.588 274.179 305.588 275.578C305.588 276.976 306.721 278.11 308.12 278.11Z" />
    <path d="M308.12 288.239C309.518 288.239 310.652 287.105 310.652 285.707C310.652 284.308 309.518 283.175 308.12 283.175C306.721 283.175 305.588 284.308 305.588 285.707C305.588 287.105 306.721 288.239 308.12 288.239Z" />
    <path d="M308.12 298.366C309.518 298.366 310.652 297.232 310.652 295.834C310.652 294.435 309.518 293.302 308.12 293.302C306.721 293.302 305.588 294.435 305.588 295.834C305.588 297.232 306.721 298.366 308.12 298.366Z" />
    <path d="M318.247 207.215C319.645 207.215 320.779 206.082 320.779 204.683C320.779 203.285 319.645 202.151 318.247 202.151C316.848 202.151 315.715 203.285 315.715 204.683C315.715 206.082 316.848 207.215 318.247 207.215Z" />
    <path d="M318.247 217.343C319.645 217.343 320.779 216.21 320.779 214.811C320.779 213.413 319.645 212.279 318.247 212.279C316.848 212.279 315.715 213.413 315.715 214.811C315.715 216.21 316.848 217.343 318.247 217.343Z" />
    <path d="M318.247 237.599C319.645 237.599 320.779 236.465 320.779 235.067C320.779 233.669 319.645 232.535 318.247 232.535C316.848 232.535 315.715 233.669 315.715 235.067C315.715 236.465 316.848 237.599 318.247 237.599Z" />
    <path d="M318.247 247.727C319.645 247.727 320.779 246.593 320.779 245.195C320.779 243.797 319.645 242.663 318.247 242.663C316.848 242.663 315.715 243.797 315.715 245.195C315.715 246.593 316.848 247.727 318.247 247.727Z" />
    <path d="M318.247 257.854C319.645 257.854 320.779 256.72 320.779 255.322C320.779 253.924 319.645 252.79 318.247 252.79C316.848 252.79 315.715 253.924 315.715 255.322C315.715 256.72 316.848 257.854 318.247 257.854Z" />
    <path d="M318.247 267.983C319.645 267.983 320.779 266.849 320.779 265.451C320.779 264.053 319.645 262.919 318.247 262.919C316.848 262.919 315.715 264.053 315.715 265.451C315.715 266.849 316.848 267.983 318.247 267.983Z" />
    <path d="M318.247 278.11C319.645 278.11 320.779 276.976 320.779 275.578C320.779 274.179 319.645 273.046 318.247 273.046C316.848 273.046 315.715 274.179 315.715 275.578C315.715 276.976 316.848 278.11 318.247 278.11Z" />
    <path d="M318.247 288.239C319.645 288.239 320.779 287.105 320.779 285.707C320.779 284.308 319.645 283.175 318.247 283.175C316.848 283.175 315.715 284.308 315.715 285.707C315.715 287.105 316.848 288.239 318.247 288.239Z" />
    <path d="M318.247 298.366C319.645 298.366 320.779 297.232 320.779 295.834C320.779 294.435 319.645 293.302 318.247 293.302C316.848 293.302 315.715 294.435 315.715 295.834C315.715 297.232 316.848 298.366 318.247 298.366Z" />
    <path d="M328.376 217.343C329.774 217.343 330.908 216.21 330.908 214.811C330.908 213.413 329.774 212.279 328.376 212.279C326.977 212.279 325.844 213.413 325.844 214.811C325.844 216.21 326.977 217.343 328.376 217.343Z" />
    <path d="M328.376 227.471C329.774 227.471 330.908 226.338 330.908 224.939C330.908 223.541 329.774 222.407 328.376 222.407C326.977 222.407 325.844 223.541 325.844 224.939C325.844 226.338 326.977 227.471 328.376 227.471Z" />
    <path d="M328.376 237.599C329.774 237.599 330.908 236.465 330.908 235.067C330.908 233.669 329.774 232.535 328.376 232.535C326.977 232.535 325.844 233.669 325.844 235.067C325.844 236.465 326.977 237.599 328.376 237.599Z" />
    <path d="M328.376 247.727C329.774 247.727 330.908 246.593 330.908 245.195C330.908 243.797 329.774 242.663 328.376 242.663C326.977 242.663 325.844 243.797 325.844 245.195C325.844 246.593 326.977 247.727 328.376 247.727Z" />
    <path d="M328.376 257.854C329.774 257.854 330.908 256.72 330.908 255.322C330.908 253.924 329.774 252.79 328.376 252.79C326.977 252.79 325.844 253.924 325.844 255.322C325.844 256.72 326.977 257.854 328.376 257.854Z" />
    <path d="M328.376 267.983C329.774 267.983 330.908 266.849 330.908 265.451C330.908 264.053 329.774 262.919 328.376 262.919C326.977 262.919 325.844 264.053 325.844 265.451C325.844 266.849 326.977 267.983 328.376 267.983Z" />
    <path d="M328.376 278.11C329.774 278.11 330.908 276.976 330.908 275.578C330.908 274.179 329.774 273.046 328.376 273.046C326.977 273.046 325.844 274.179 325.844 275.578C325.844 276.976 326.977 278.11 328.376 278.11Z" />
    <path d="M328.376 288.239C329.774 288.239 330.908 287.105 330.908 285.707C330.908 284.308 329.774 283.175 328.376 283.175C326.977 283.175 325.844 284.308 325.844 285.707C325.844 287.105 326.977 288.239 328.376 288.239Z" />
    <path d="M328.376 298.366C329.774 298.366 330.908 297.232 330.908 295.834C330.908 294.435 329.774 293.302 328.376 293.302C326.977 293.302 325.844 294.435 325.844 295.834C325.844 297.232 326.977 298.366 328.376 298.366Z" />
    <path d="M338.503 237.599C339.901 237.599 341.035 236.465 341.035 235.067C341.035 233.669 339.901 232.535 338.503 232.535C337.104 232.535 335.971 233.669 335.971 235.067C335.971 236.465 337.104 237.599 338.503 237.599Z" />
    <path d="M338.503 247.727C339.901 247.727 341.035 246.593 341.035 245.195C341.035 243.797 339.901 242.663 338.503 242.663C337.104 242.663 335.971 243.797 335.971 245.195C335.971 246.593 337.104 247.727 338.503 247.727Z" />
    <path d="M338.503 257.854C339.901 257.854 341.035 256.72 341.035 255.322C341.035 253.924 339.901 252.79 338.503 252.79C337.104 252.79 335.971 253.924 335.971 255.322C335.971 256.72 337.104 257.854 338.503 257.854Z" />
    <path d="M338.503 267.983C339.901 267.983 341.035 266.849 341.035 265.451C341.035 264.053 339.901 262.919 338.503 262.919C337.104 262.919 335.971 264.053 335.971 265.451C335.971 266.849 337.104 267.983 338.503 267.983Z" />
    <path d="M338.503 278.11C339.901 278.11 341.035 276.976 341.035 275.578C341.035 274.179 339.901 273.046 338.503 273.046C337.104 273.046 335.971 274.179 335.971 275.578C335.971 276.976 337.104 278.11 338.503 278.11Z" />
    <path d="M338.503 288.239C339.901 288.239 341.035 287.105 341.035 285.707C341.035 284.308 339.901 283.175 338.503 283.175C337.104 283.175 335.971 284.308 335.971 285.707C335.971 287.105 337.104 288.239 338.503 288.239Z" />
  </svg>
);
