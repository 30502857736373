export const UniversityTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6H4V7V8H5H6V7V6H5ZM9 6H8V7V8H9H10V7V6H9ZM12 6H13H14V7V8H13H12V7V6Z"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7H4V8V12V13H5H6V12V8V7H5ZM9 7H8V8V12V13H9H10V12V8V7H9ZM12 7H13H14V8V12V13H13H12V12V8V7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15811 2.52567L9 2.47296L8.84189 2.52567L2.84189 4.52567L2.5 4.63963V5.00001V6.50001V7.00001H3H15H15.5V6.50001V5.00001V4.63963L15.1581 4.52567L9.15811 2.52567ZM3.5 6.00001V5.36039L9 3.52705L14.5 5.36039V6.00001H3.5ZM3.38743 14L3.72076 13H14.2792L14.6126 14H3.38743ZM15.2279 12.6838L15.6667 14L16 15H14.9459H3.05409H2L2.33333 14L2.77208 12.6838C2.90819 12.2754 3.29033 12 3.72076 12H14.2792C14.7097 12 15.0918 12.2754 15.2279 12.6838Z"
    />
  </svg>
);
