export const FedDataIcon = () => (
  <svg
    width="89"
    height="79"
    viewBox="0 0 89 79"
    fill="none"
    strokeMiterlimit="10"
  >
    <path
      d="M15.5065 75.2065C23.4664 75.2065 29.9192 73.9833 29.9192 72.4744C29.9192 70.9654 23.4664 69.7422 15.5065 69.7422C7.54654 69.7422 1.09375 70.9654 1.09375 72.4744C1.09375 73.9833 7.54654 75.2065 15.5065 75.2065Z"
      fill="#483C72"
      stroke="#483C72"
    />
    <path
      d="M33.8974 76.7598C41.8574 76.7598 48.3104 75.5364 48.3104 74.0273C48.3104 72.5183 41.8574 71.2949 33.8974 71.2949C25.9373 71.2949 19.4844 72.5183 19.4844 74.0273C19.4844 75.5364 25.9373 76.7598 33.8974 76.7598Z"
      fill="#483C72"
      stroke="#483C72"
    />
    <path
      d="M53.3425 1C34.4584 1 19.1484 16.3089 19.1484 35.1938C19.1484 54.0787 34.4581 69.3876 53.3425 69.3876C72.2272 69.3876 87.5366 54.0787 87.5366 35.1938C87.5366 16.3089 72.2272 1 53.3425 1ZM53.3425 50.0456C45.1401 50.0456 38.491 43.3959 38.491 35.1935C38.491 26.9909 45.1404 20.3417 53.3425 20.3417C61.5454 20.3417 68.1946 26.9909 68.1946 35.1935C68.1949 43.3962 61.5454 50.0456 53.3425 50.0456Z"
      fill="#E269A4"
      stroke="#483C72"
    />
    <path
      d="M58.6883 72.4299C72.7051 72.4299 84.068 71.4951 84.068 70.3419C84.068 69.1887 72.7051 68.2539 58.6883 68.2539C44.6715 68.2539 33.3086 69.1887 33.3086 70.3419C33.3086 71.4951 44.6715 72.4299 58.6883 72.4299Z"
      fill="#483C72"
      stroke="#483C72"
    />
    <path
      d="M48.5066 2.61133C29.6219 2.61133 14.3125 17.9205 14.3125 36.8051C14.3125 55.6898 29.6217 70.9992 48.5066 70.9992C67.3915 70.9992 82.7006 55.69 82.7006 36.8051C82.7006 17.9205 67.3912 2.61133 48.5066 2.61133ZM48.5066 51.657C40.3042 51.657 33.655 45.0078 33.655 36.8051C33.655 28.6028 40.3045 21.9533 48.5066 21.9533C56.7095 21.9533 63.3586 28.603 63.3586 36.8051C63.3586 45.0078 56.7095 51.657 48.5066 51.657Z"
      fill="#EE86B7"
      stroke="#483C72"
    />
    <path
      d="M48.5041 70.9988C52.4023 70.9988 56.1478 70.345 59.6378 69.1433L54.1582 50.5398C52.4146 51.2585 50.5063 51.6568 48.5041 51.6568C44.3501 51.6568 40.596 49.9498 37.9001 47.2002L25.9375 62.4927C31.9594 67.7868 39.8562 70.9988 48.5041 70.9988Z"
      fill="#A88FEF"
      stroke="#483C72"
    />
    <path
      d="M33.6548 36.8055C33.6548 34.913 34.0124 33.1048 34.6579 31.4399L16.69 24.2529C15.1553 28.1391 14.3125 32.3738 14.3125 36.8055C14.3125 47.5996 19.3152 57.2238 27.1276 63.4904L38.9944 48.2095C35.7312 45.4851 33.6548 41.3884 33.6548 36.8055Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path
      d="M48.5047 2.61133C44.8579 2.61133 41.3459 3.18419 38.0508 4.24172L44.0397 22.6374C45.4492 22.1938 46.9488 21.9533 48.5047 21.9533C51.9957 21.9533 55.2032 23.161 57.7389 25.1774L70.0025 10.2159C64.1285 5.46117 56.65 2.61133 48.5047 2.61133Z"
      fill="#FA975F"
      stroke="#483C72"
    />
    <path
      d="M69.851 10.0938L57.8281 25.2471C61.1994 27.9697 63.3576 32.1349 63.3576 36.8055C63.3576 38.7846 62.9675 40.6713 62.2649 42.3979L80.3743 49.2214C81.875 45.3727 82.6996 41.1855 82.6996 36.8055C82.6996 25.9959 77.6821 16.3599 69.851 10.0938Z"
      fill="#FBBD74"
      stroke="#483C72"
    />
    <path
      d="M80.3787 49.2128L62.3379 42.2148C60.7405 46.297 57.3901 49.4972 53.2109 50.8922L59.198 69.292C68.9034 66.0995 76.6895 58.6815 80.3787 49.2128Z"
      fill="#98BCF1"
      stroke="#483C72"
    />
    <path
      d="M38.9609 48.2981C41.4349 50.1408 45.1847 51.657 48.5061 51.657C51.0602 51.657 53.4637 51.0118 55.5627 49.876C54.8381 49.9854 54.0972 50.0451 53.3418 50.0451C50.0721 50.0451 47.051 48.986 44.5976 47.1953L38.9609 48.2981Z"
      fill="#8D75E6"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M38.488 35.1927C38.488 33.4146 38.8011 31.7094 39.3745 30.1289L34.7056 31.3095C34.0279 33.0096 33.6523 34.8627 33.6523 36.8046C33.6523 41.4441 35.7802 45.5852 39.1126 48.3091L44.4448 47.087C40.8283 44.3784 38.488 40.0587 38.488 35.1927Z"
      fill="#5EBC89"
      stroke="#483C72"
    />
    <path
      d="M74.7159 8.50684L69.7539 10.0169C77.6402 16.2808 82.6981 25.952 82.6981 36.8058C82.6981 41.0895 81.9057 45.1878 80.4667 48.9662L85.1073 47.8687C86.6723 43.949 87.5341 39.6723 87.5341 35.1936C87.5341 24.3985 82.5298 14.7735 74.7159 8.50684Z"
      fill="#F0A848"
      stroke="#483C72"
    />
    <path
      d="M74.3809 8.23893C68.5785 3.70374 61.2755 1 53.3404 1C48.6564 1 44.1936 1.94253 40.1289 3.64691C42.8093 2.97191 45.6149 2.6119 48.5047 2.6119C56.5567 2.6119 63.9573 5.39606 69.7996 10.053C71.3439 9.44095 72.9448 8.80724 74.3809 8.23893Z"
      fill="#F47E3F"
      stroke="#483C72"
      strokeLinecap="round"
    />
    <path
      d="M80.4962 48.8935C77.1756 57.6749 70.3487 64.7337 61.7188 68.3524C72.3844 65.666 81.0588 57.9698 85.0914 47.9121L80.4962 48.8935Z"
      fill="#4E8FD0"
      stroke="#483C72"
    />
    <path
      d="M34.9805 30.9535L39.1712 29.9182C39.1712 29.9182 41.6361 23.7558 45.5311 22.4736C45.5311 22.4736 36.706 24.1005 34.9805 30.9535Z"
      fill="#483C72"
    />
    <path
      d="M1 71.974C1 73.4832 5.74884 74.7064 11.6064 74.7064C17.464 74.7064 22.2128 73.4832 22.2128 71.974V48.5C22.2128 46.9908 17.464 45.7676 11.6064 45.7676C5.74884 45.7676 1 46.9908 1 48.5V71.974Z"
      fill="#FA975F"
      stroke="#483C72"
    />
    <path
      d="M11.6064 51.2319C17.4642 51.2319 22.2128 50.0087 22.2128 48.4997C22.2128 46.9908 17.4642 45.7676 11.6064 45.7676C5.74865 45.7676 1 46.9908 1 48.4997C1 50.0087 5.74865 51.2319 11.6064 51.2319Z"
      fill="#FFC8A3"
      stroke="#483C72"
    />
    <path
      d="M19.5898 73.6228C19.5898 75.1317 24.3387 76.3552 30.1963 76.3552C36.0538 76.3552 40.803 75.1317 40.803 73.6228V59.833C40.803 58.3238 36.0538 57.1006 30.1963 57.1006C24.3387 57.1006 19.5898 58.3238 19.5898 59.833V73.6228Z"
      fill="#EE86B7"
      stroke="#483C72"
    />
    <path
      d="M30.1965 62.5655C36.0544 62.5655 40.8032 61.3421 40.8032 59.833C40.8032 58.3239 36.0544 57.1006 30.1965 57.1006C24.3386 57.1006 19.5898 58.3239 19.5898 59.833C19.5898 61.3421 24.3386 62.5655 30.1965 62.5655Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M55.7636 77.4351C64.1094 77.4351 70.875 76.5179 70.875 75.3865C70.875 74.2551 64.1094 73.3379 55.7636 73.3379C47.4179 73.3379 40.6523 74.2551 40.6523 75.3865C40.6523 76.5179 47.4179 77.4351 55.7636 77.4351Z"
      fill="#483C72"
      stroke="#483C72"
    />
    <path
      d="M39.3281 74.9345C39.3281 76.4434 44.0772 77.6669 49.9348 77.6669C55.7924 77.6669 60.5412 76.4434 60.5412 74.9345V69.5149C60.5412 68.0054 55.7924 66.7822 49.9348 66.7822C44.0772 66.7822 39.3281 68.0054 39.3281 69.5149V74.9345Z"
      fill="#A88FEF"
      stroke="#483C72"
    />
    <path
      d="M49.9348 72.247C55.7927 72.247 60.5415 71.0239 60.5415 69.5151C60.5415 68.0063 55.7927 66.7832 49.9348 66.7832C44.0769 66.7832 39.3281 68.0063 39.3281 69.5151C39.3281 71.0239 44.0769 72.247 49.9348 72.247Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
  </svg>
);
