import { css } from "linaria";
import ContentCardBase, { ContentCardSize } from "./_ContentCardBase";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import { PrimaryColorName } from "../../styles/colors.styles";
import { Paths } from "../../utils/pathBuilders.utils";

const contentCardSolutionsByProductStyle = css`
  figure {
    svg {
      transform: translateX(6%);
    }
  }
`;

export const ContentCardSolutionsByProduct = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => (
  <ContentCardBase
    className={contentCardSolutionsByProductStyle}
    large={large}
    color={(overrides?.color ?? "purple") as PrimaryColorName}
    link={Paths.solutionsByProduct()}
    variant={overrides?.colorMode === "dark" ? "500" : "50"}
    size={(overrides?.size ?? "1/3") as ContentCardSize}
  >
    <header>
      <div>
        <p>
          <strong>{overrides?.preHeadingTag || "Solutions by product"}</strong>
        </p>
        <h4>{overrides?.heading || "Connect anything"}</h4>
        <ReactMarkdown>
          {overrides?.description ||
            "If it offers an API, Tines connects with it. From mainstream solutions to niche and internal tools, Tines is an integrator across your entire stack."}
        </ReactMarkdown>
      </div>
      <p>
        <strong>{overrides?.linkLabel || "Learn more"}</strong>
      </p>
    </header>
    <figure>
      {overrides?.icon?.url ? (
        <img src={overrides.icon.url} />
      ) : (
        <SolutionsByProductIcon />
      )}
    </figure>
  </ContentCardBase>
);

const SolutionsByProductIcon = () => (
  <svg
    width="81"
    height="84"
    viewBox="0 0 81 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3264 27.7627C25.5292 30.7744 27.305 37.434 24.2935 42.6355C21.2816 47.8379 14.6222 49.6141 9.41958 46.6019C4.21766 43.5907 2.44191 36.9311 5.45406 31.7289C8.46527 26.5272 15.1247 24.751 20.3264 27.7627Z"
      fill="#EE86B7"
      stroke="#483C72"
    />
    <path
      d="M24.3763 36.2026C27.1938 37.8339 28.156 41.44 26.5247 44.2576C24.8939 47.0749 21.2878 48.0365 18.4698 46.4057L16.6623 45.3211C13.8445 43.6901 12.8827 40.0842 14.5142 37.2669C16.1452 34.4486 19.7511 33.4875 22.5686 35.1185L24.3763 36.2026Z"
      fill="#8D75E6"
      stroke="#483C72"
    />
    <path
      d="M39.9206 46.9454C42.0011 48.1499 42.7114 50.8123 41.5069 52.8931C40.302 54.9741 37.6396 55.6839 35.5583 54.4792L20.1812 45.5773C18.1007 44.3721 17.3901 41.7097 18.5949 39.6296C19.7993 37.5486 22.4625 36.8383 24.5432 38.0426L39.9206 46.9454Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M61.4404 27.7627C56.2377 30.7744 54.462 37.434 57.4739 42.6355C60.4853 47.8379 67.1452 49.6141 72.3476 46.6019C77.5493 43.5907 79.3253 36.9311 76.3131 31.7289C73.3019 26.5272 66.6425 24.751 61.4404 27.7627Z"
      fill="#4E8FD0"
      stroke="#483C72"
    />
    <path
      d="M57.3912 36.2021C54.5737 37.8334 53.6118 41.4395 55.2428 44.2571C56.8739 47.0744 60.4802 48.036 63.298 46.4052L65.1055 45.3206C67.923 43.6896 68.8849 40.0837 67.2538 37.2664C65.6223 34.4481 62.0165 33.487 59.1991 35.118L57.3912 36.2021Z"
      fill="#8D75E6"
      stroke="#483C72"
    />
    <path
      d="M41.8467 46.9454C39.7657 48.1499 39.0559 50.8123 40.2604 52.8931C41.4649 54.9741 44.1273 55.6839 46.2085 54.4792L61.5857 45.5773C63.6662 44.3721 64.3767 41.7097 63.1723 39.6296C61.968 37.5486 59.3046 36.8383 57.2241 38.0426L41.8467 46.9454Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M52.9495 51.7643C52.9495 58.4991 47.4889 63.9595 40.7548 63.9595C34.0199 63.9595 28.5596 58.4991 28.5596 51.7643C28.5596 45.03 34.0199 39.5698 40.7548 39.5698C47.4889 39.5698 52.9495 45.03 52.9495 51.7643Z"
      fill="#8D75E6"
      stroke="#483C72"
    />
    <path
      d="M45.1082 39.3221C45.1082 41.7261 43.1592 43.1283 40.7549 43.1283C38.3512 43.1283 36.4023 41.7261 36.4023 39.3221V21.5538C36.4023 19.1495 38.3512 17.2009 40.7549 17.2009C43.1592 17.2009 45.1082 19.1495 45.1082 21.5538V39.3221Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M46.4724 24.608C46.4724 27.8637 43.9133 28.1574 40.7548 28.1574C37.5972 28.1574 35.0381 27.8637 35.0381 24.608V20.6183C35.0381 17.3624 37.5972 14.7234 40.7548 14.7234C43.913 14.7234 46.4724 17.3624 46.4724 20.6183V24.608Z"
      fill="#8D75E6"
      stroke="#483C72"
    />
    <path
      d="M27.5148 54.9319C29.4742 53.5411 32.1922 54.0024 33.5838 55.9633C34.9753 57.9238 34.5137 60.6406 32.5528 62.0321L20.4116 70.62C18.4516 72.0108 15.7348 71.55 14.3433 69.5898C12.9518 67.6294 13.4126 64.9116 15.3737 63.5201L27.5148 54.9319Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M15.1464 61.7906C17.8015 59.9068 21.4802 60.531 23.365 63.1863C25.2492 65.841 24.6243 69.5197 21.9694 71.4044L18.7158 73.7134C16.0607 75.5984 12.3816 74.9734 10.4973 72.319C8.61301 69.6632 9.23795 65.9847 11.8924 64.1L15.1464 61.7906Z"
      fill="#8D75E6"
      stroke="#483C72"
    />
    <path
      d="M34.608 56.708C34.608 56.708 36.3724 60.007 35.4088 62.3973L33.2739 61.7891L34.608 56.708Z"
      fill="#483C72"
    />
    <path
      d="M40.7555 24.5813C47.2673 24.5813 52.5461 19.3024 52.5461 12.7906C52.5461 6.27885 47.2673 1 40.7555 1C34.2437 1 28.9648 6.27885 28.9648 12.7906C28.9648 19.3024 34.2437 24.5813 40.7555 24.5813Z"
      fill="#F0A848"
      stroke="#483C72"
    />
    <path
      d="M22.3711 69.3632C23.743 75.2161 20.1099 81.0733 14.258 82.4447C8.40491 83.8161 2.54826 80.184 1.17637 74.3306C-0.195523 68.4785 3.43755 62.6216 9.28995 61.2504C15.1426 59.8785 20.9995 63.5108 22.3711 69.3632Z"
      fill="#25A871"
      stroke="#483C72"
    />
    <path
      d="M53.6772 54.9319C51.7172 53.5411 48.9999 54.0024 47.6082 55.9633C46.2167 57.9238 46.6782 60.6406 48.6391 62.0321L60.7804 70.62C62.7406 72.0108 65.4572 71.55 66.8487 69.5898C68.2399 67.6294 67.7793 64.9116 65.8182 63.5201L53.6772 54.9319Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M66.0449 61.7906C63.3898 59.9068 59.711 60.531 57.8263 63.1863C55.9422 65.841 56.567 69.5197 59.2214 71.4044L62.4754 73.7134C65.1305 75.5984 68.8097 74.9734 70.6944 72.319C72.5782 69.6632 71.9533 65.9847 69.2989 64.1L66.0449 61.7906Z"
      fill="#8D75E6"
      stroke="#483C72"
    />
    <path
      d="M46.5833 56.708C46.5833 56.708 44.8189 60.007 45.7825 62.3973L47.9174 61.7891L46.5833 56.708Z"
      fill="#483C72"
    />
    <path
      d="M58.8204 69.3632C57.4485 75.2161 61.0816 81.0733 66.9335 82.4447C72.7866 83.8161 78.6433 80.184 80.0152 74.3306C81.3868 68.4785 77.754 62.6216 71.9013 61.2504C66.0489 59.8785 60.1921 63.5108 58.8204 69.3632Z"
      fill="#F47E3F"
      stroke="#483C72"
    />
    <path
      d="M49.6401 42.4858C49.6401 42.4858 53.3363 44.7295 55.1118 49.3271L53.0994 50.4973L49.6401 42.4858Z"
      fill="#483C72"
    />
    <path
      d="M68.5296 41.166C68.5296 41.166 68.5293 46.0816 66.4355 47.9935L62.8398 46.9924C62.8398 46.9924 67.8469 43.2598 68.5296 41.166Z"
      fill="#483C72"
    />
    <path
      d="M40.2622 43.6921C40.2622 43.6921 44.0861 44.1016 48.1145 42.1901L45.3149 40.6194C45.3149 40.6194 42.1746 43.6921 40.2622 43.6921Z"
      fill="#483C72"
    />
    <path
      d="M14.3174 43.6917C14.3174 43.6917 15 46.5596 16.9117 48.3346L18.9601 46.8324L14.3174 43.6917Z"
      fill="#483C72"
    />
    <path
      d="M38.4194 28.3984C38.4194 28.3984 40.3314 29.8318 44.9058 29.5589L44.7696 27.0325C44.7696 27.0325 41.4238 28.3301 38.4194 28.3984Z"
      fill="#483C72"
    />
  </svg>
);
