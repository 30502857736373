import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import Button from "../forms/Button";
import { PrimaryColorName, color } from "../../styles/colors.styles";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { cx } from "linaria";

type Props = {
  id?: string | null;
  sourceName: ReactNode;
  sourceWorkTitle: ReactNode;
  sourceCompanyLogo?: ReactNode;
  caseStudySlug?: string;
  darker?: boolean;
  color?: PrimaryColorName;
};

const Inner = styled.figure`
  text-align: center;
  padding: 0;
  margin: 0;
  ${fromTabletMd} {
    margin: 2em ${rSize("md")};
  }
  ${fromDesktopMd} {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

const Blockquote = styled.blockquote`
  max-width: ${getWidthPxInMaxGrid(8)};
  margin: ${rSize("lg")} auto;
  padding: 0;
  font-size: 2rem;
  letter-spacing: -0.01em;
  line-height: 1.3;
  font-family: ${serif};
  ${fromTablet} {
    font-size: 2.4rem;
  }
  ${fromTabletLg} {
    font-size: 3.2rem;
    font-weight: 300;
  }
  ${fromDesktopMd} {
    line-height: 1.25;
  }
  p {
    font: inherit;
    margin: 0;
  }
`;

const Source = styled.figcaption`
  &.noLogo {
    text-align: center;
  }
  &.hasLogo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "name logo" "title logo";
    grid-gap: 0 ${rSize("gap", 2)};
    position: relative;
    align-items: center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: calc(50% - 1px);
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: currentColor;
      opacity: 0.5;
    }
  }
`;

const SourceName = styled.p`
  grid-area: name;
  text-align: right;
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0;
  .noLogo & {
    text-align: center;
  }
`;
const WorkTitle = styled.p`
  grid-area: title;
  text-align: right;
  font-size: 1.3rem;
  .noLogo & {
    text-align: center;
  }
  ${fromTablet} {
    font-size: 1.4rem;
  }
  line-height: 1.2;
  margin: 0;
`;
const LogoWrap = styled.div`
  grid-area: logo;
  text-align: left;
`;

const CaseStudyButtonWrap = styled.div`
  margin-top: ${rSize("lg")};
  .Button {
    position: relative;
    .ButtonInner {
      color: inherit;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0.5em;
    }
  }
`;

const CenteredQuoteSection = (props: PropsWithChildren<Props>) => {
  return (
    <PageSection
      style={{ color: color(props.color, props.darker ? 800 : 700) }}
    >
      <Inner>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor">
          <rect width="40" height="40" rx="20" />
          <path
            d="M19.2401 18.042H18.8298C18.0912 18.042 17.6535 18.5966 17.6535 19.6807V22H15V19.5042C15 17.2857 16.3404 16 18.5562 16H19.2401V18.042ZM24 18.042H23.5897C22.8237 18.042 22.386 18.5966 22.386 19.6807V22H19.7325V19.5042C19.7325 17.2857 21.0729 16 23.3161 16H24V18.042Z"
            fill="white"
          />
        </svg>
        <Blockquote>{props.children}</Blockquote>
        <Source className={cx(props.sourceCompanyLogo ? "hasLogo" : "noLogo")}>
          <SourceName>
            <strong>{props.sourceName}</strong>
          </SourceName>
          <WorkTitle>{props.sourceWorkTitle}</WorkTitle>
          {props.sourceCompanyLogo && (
            <LogoWrap>{props.sourceCompanyLogo}</LogoWrap>
          )}
        </Source>

        {props.caseStudySlug && (
          <CaseStudyButtonWrap
            style={
              {
                "--buttonBackdrop": color(props.color, 50),
                "--buttonBackdropHover": color(props.color, 100),
              } as CSSProperties
            }
          >
            <Button
              appearance="outlined"
              to={`/case-studies/${props.caseStudySlug}`}
              color={props.color}
              darker
            >
              Read the case study
            </Button>
          </CaseStudyButtonWrap>
        )}
      </Inner>
    </PageSection>
  );
};

export default CenteredQuoteSection;
