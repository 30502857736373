export const ConnectAnythingIcon = () => (
  <svg
    width="94"
    height="56"
    viewBox="0 0 94 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.325 45.6482C62.8401 45.6482 62.3551 45.4633 61.9851 45.0934L46.2554 29.3636C45.5151 28.6234 45.5151 27.4235 46.2554 26.6835C46.9956 25.9434 48.1953 25.9434 48.9355 26.6835L64.6653 42.413C65.4055 43.1531 65.4055 44.3531 64.6653 45.0934C64.295 45.4633 63.8104 45.6482 63.325 45.6482Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M84.8823 36.1759C89.3655 36.1759 92.9999 32.5415 92.9999 28.0583C92.9999 23.5751 89.3655 19.9407 84.8823 19.9407C80.399 19.9407 76.7646 23.5751 76.7646 28.0583C76.7646 32.5415 80.399 36.1759 84.8823 36.1759Z"
      fill="#FABE64"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M81.1414 25.9239C80.6565 25.9239 80.1715 25.7391 79.8012 25.3691L64.0718 9.63937C63.3315 8.89931 63.3315 7.69928 64.0718 6.95922C64.812 6.21932 66.0117 6.21932 66.7519 6.95922L82.4817 22.6888C83.2216 23.4289 83.2216 24.6289 82.4817 25.3691C82.1114 25.7391 81.6264 25.9239 81.1414 25.9239Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M65.9417 1C70.425 1 74.0591 4.6343 74.0591 9.11761C74.0591 13.6009 70.425 17.2352 65.9417 17.2352C61.458 17.2352 57.8242 13.6009 57.8242 9.11761C57.8242 4.6343 61.458 1 65.9417 1Z"
      fill="#FABE64"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M47.5699 29.536C48.0552 29.536 48.5398 29.3511 48.9101 28.9812L64.6399 13.2514C65.3798 12.5112 65.3798 11.3113 64.6399 10.5713C63.8993 9.83138 62.7 9.83138 61.9594 10.5713L46.23 26.3008C45.4897 27.0409 45.4897 28.2409 46.23 28.9812C46.5999 29.3509 47.0849 29.536 47.5699 29.536Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M32.5376 55.9647C36.6196 55.9647 39.9287 55.2858 39.9287 54.4484C39.9287 53.611 36.6196 52.9321 32.5376 52.9321C28.4556 52.9321 25.1465 53.611 25.1465 54.4484C25.1465 55.2858 28.4556 55.9647 32.5376 55.9647Z"
      fill="#32274B"
    />
    <path
      d="M43.2392 25.9239C42.7542 25.9239 42.2692 25.7391 41.8989 25.3691L26.1692 9.63937C25.4293 8.89931 25.4293 7.69928 26.1692 6.95922C26.9098 6.21932 28.1091 6.21932 28.8497 6.95922L44.5791 22.6888C45.3193 23.4289 45.3193 24.6289 44.5791 25.3691C44.2091 25.7391 43.7242 25.9239 43.2392 25.9239Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0592 1C32.5425 1 36.1766 4.6343 36.1766 9.11761C36.1766 13.6009 32.5425 17.2352 28.0592 17.2352C23.5755 17.2352 19.9414 13.6009 19.9414 9.11761C19.9414 4.6343 23.5755 1 28.0592 1Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M9.194 29.5358C9.67898 29.5358 10.164 29.3509 10.5343 28.9809L26.2637 13.2512C27.0039 12.5109 27.0039 11.3111 26.2637 10.571C25.5234 9.83114 24.3237 9.83114 23.5835 10.571L7.85375 26.3006C7.11386 27.0407 7.11386 28.2407 7.85375 28.9809C8.22404 29.3507 8.70902 29.5358 9.194 29.5358Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M9.1178 36.1762C13.6011 36.1762 17.2356 32.5418 17.2356 28.0585C17.2356 23.5751 13.6011 19.9407 9.1178 19.9407C4.63447 19.9407 1 23.5751 1 28.0585C1 32.5418 4.63447 36.1762 9.1178 36.1762Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M27.7342 48.8259C27.2492 48.8259 26.7643 48.6408 26.394 48.2709L10.6646 32.5413C9.92431 31.8011 9.92431 30.6011 10.6646 29.8608C11.4048 29.1209 12.6045 29.1209 13.3447 29.8608L29.0745 45.5906C29.8144 46.3308 29.8144 47.5308 29.0745 48.2709C28.7042 48.6408 28.2192 48.8259 27.7342 48.8259Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0592 38.8828C32.5425 38.8828 36.1766 42.5173 36.1766 47.0003C36.1766 51.4839 32.5425 55.1182 28.0592 55.1182C23.5755 55.1182 19.9414 51.4839 19.9414 47.0003C19.9414 42.5173 23.5755 38.8828 28.0592 38.8828Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M10.0625 31.9756L11.01 36.0973L12.9998 35.055L10.0625 31.9756Z"
      fill="#32274B"
    />
    <path
      d="M26.8832 12.7385L25.8409 16.8132L23.709 16.0076L26.8832 12.7385Z"
      fill="#32274B"
    />
    <path
      d="M40.1613 32.428L30.0815 42.5076C29.3413 43.2476 29.3413 44.4477 30.0815 45.1879C30.4518 45.5578 30.9365 45.7427 31.4218 45.7427C31.9068 45.7427 32.3918 45.5578 32.7617 45.1879L42.8913 35.0583C41.7869 34.4082 40.8508 33.5051 40.1613 32.428Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M40.1591 32.428C40.1591 32.428 41.871 34.746 42.8891 35.0583L41.3801 36.4444C41.3801 36.4444 39.9273 34.9283 39.6748 32.97L40.1591 32.428Z"
      fill="#32274B"
    />
    <path
      d="M54.397 32.428C54.397 32.428 52.6851 34.746 51.667 35.0583L53.176 36.4444C53.176 36.4444 54.6288 34.9283 54.8816 32.97L54.397 32.428Z"
      fill="#32274B"
    />
    <path
      d="M33.6764 17.1137C33.6764 17.1137 35.382 15.1552 35.6347 13.8919L35.0031 13.5762C35.0031 13.5762 33.6129 15.4082 32.5391 16.04L33.6764 17.1137Z"
      fill="#32274B"
    />
    <path
      d="M71.5785 17.1137C71.5785 17.1137 73.2842 15.1552 73.537 13.8919L72.905 13.5762C72.905 13.5762 71.5153 15.4082 70.4414 16.04L71.5785 17.1137Z"
      fill="#32274B"
    />
    <path
      d="M32.5205 45.8096L35.837 44.8619C35.837 44.8619 35.5054 43.5354 35.0316 42.9668L32.5205 45.8096Z"
      fill="#32274B"
    />
    <path
      d="M65.1971 13.0715L64.1864 16.7986C64.1864 16.7986 63.1126 16.7985 62.165 15.9772L65.1971 13.0715Z"
      fill="#32274B"
    />
    <path
      d="M46.9996 36.1762C51.483 36.1762 55.1174 32.5418 55.1174 28.0585C55.1174 23.5751 51.483 19.9407 46.9996 19.9407C42.5163 19.9407 38.8818 23.5751 38.8818 28.0585C38.8818 32.5418 42.5163 36.1762 46.9996 36.1762Z"
      fill="#F0946C"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M69.429 55.9647C73.5109 55.9647 76.82 55.2858 76.82 54.4484C76.82 53.611 73.5109 52.9321 69.429 52.9321C65.3471 52.9321 62.0381 53.611 62.0381 54.4484C62.0381 55.2858 65.3471 55.9647 69.429 55.9647Z"
      fill="#32274B"
    />
    <path
      d="M65.9417 38.8828C70.425 38.8828 74.0591 42.5173 74.0591 47.0003C74.0591 51.4839 70.425 55.1182 65.9417 55.1182C61.458 55.1182 57.8242 51.4839 57.8242 47.0003C57.8242 42.5173 61.458 38.8828 65.9417 38.8828Z"
      fill="#FABE64"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M77.9226 32.24L68.6177 41.5449C67.8774 42.285 67.8774 43.4849 68.6177 44.2251C68.988 44.595 69.4726 44.7801 69.9579 44.7801C70.4429 44.7801 70.9279 44.595 71.2978 44.2251L80.5804 34.9424C79.4961 34.2635 78.5831 33.337 77.9226 32.24Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeMiterlimit="10"
    />
    <path
      d="M77.9249 32.24L77.2109 33.0935C77.2109 33.0935 77.9929 35.0918 79.2092 35.8738L80.2518 35.0049L77.9249 32.24Z"
      fill="#32274B"
    />
    <path
      d="M70.9131 44.9217L73.788 44.7214L72.9536 42.7373L70.9131 44.9217Z"
      fill="#32274B"
    />
    <path
      d="M77.1514 25.2455L80.6555 26.2447L77.8172 23.7974L77.1514 25.2455Z"
      fill="#32274B"
    />
  </svg>
);
