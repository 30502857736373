import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import { ConnectorPathWaypoint } from "../../decorations/ConnectorPathsPainter";
import { ActionCard } from "../../decorations/storyboard/ActionCard";
import Spacing from "../../layout/Spacing";
import { AutomaticModeDetails } from "./AutomaticModeDetails";
import { getWidthPxInMaxGrid } from "../../../constants/globalGrid.constants";
import { rSizeNoCalc } from "../../../styles/responsiveSizes.styles";
import { fromDesktopMd } from "../../../styles/breakpointsAndMediaQueries.styles";

const HeroSectionAutomaticModeBlock = styled.div`
  position: absolute;
  bottom: 0;
  top: 5em;
  right: calc(50% + ${rSizeNoCalc("gap", 0.5)});
  left: 0;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  padding-right: ${getWidthPxInMaxGrid(3)};
  display: none;
  ${fromDesktopMd} {
    display: block;
  }
`;
const AutomaticModeDetailsWrap = styled.div`
  > * {
    transform: translateX(3.6rem);
  }
`;
const ActionOneWrap = styled.div`
  position: relative;
  text-align: left;
`;
const ActionTwoWrap = styled.div`
  position: relative;
  text-align: center;
`;

export const AutomaticModeIllustratedBlock = () => {
  return (
    <HeroSectionAutomaticModeBlock>
      <ActionOneWrap>
        <ActionCard
          type="webhook"
          title="Security alerts from API"
          hasOutgoingLink
          eventCount="3,230"
          colorVariant={0}
          injectBefore={
            <>
              <ConnectorPathWaypoint
                id="ai-page-hero-automatic-transform-webhook-to-transform"
                index={0}
                color={colors.teal400}
                top="100%"
                left="2.3rem"
              />
              <ConnectorPathWaypoint
                id="ai-page-hero-automatic-transform-webhook-to-transform"
                index={1}
                top="calc(100% + 18px)"
                left="2.3rem"
                radius={12}
              />
            </>
          }
        />
      </ActionOneWrap>
      <Spacing size="4.8rem" />
      <ActionTwoWrap>
        <ActionCard
          id="ai-page-hero-ai-action-example-step-2"
          type="transform"
          title="Parse alert name"
          hasIncomingLink
          hasOutgoingLink
          eventCount="3,230"
          colorVariant={100}
          injectBefore={
            <>
              <ConnectorPathWaypoint
                id="ai-page-hero-automatic-transform-webhook-to-transform"
                index={2}
                bottom="calc(100% + 30px)"
                left="2.3rem"
                radius={18}
              />
              <ConnectorPathWaypoint
                id="ai-page-hero-automatic-transform-webhook-to-transform"
                index={3}
                color={colors.orange400}
                bottom="calc(100% - 1px)"
                left="2.3rem"
              />

              <ConnectorPathWaypoint
                id="ai-page-hero-automatic-transform-out"
                index={0}
                color={colors.orange400}
                bottom="calc(100% - 1px)"
                left="2.3rem"
                radius={0}
              />
              <ConnectorPathWaypoint
                id="ai-page-hero-automatic-transform-out"
                index={1}
                color={colors.orange400}
                top="calc(100% + 133px)"
                left="2.3rem"
                radius={24}
              />
              <ConnectorPathWaypoint
                id="ai-page-hero-automatic-transform-out"
                index={2}
                color={colors.orange400}
                top="calc(100% + 133px)"
                left="4.8rem"
              />
            </>
          }
        />
      </ActionTwoWrap>
      <Spacing size="3.2rem" />
      <AutomaticModeDetailsWrap>
        <AutomaticModeDetails />
      </AutomaticModeDetailsWrap>
    </HeroSectionAutomaticModeBlock>
  );
};
