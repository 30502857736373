import { styled } from "@linaria/react";
import { shade } from "polished";
import ReactMarkdown from "react-markdown";
import {
  fromDesktop,
  fromDesktopMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { ValidFeatureBlockWithIcon } from "../../utils/datocms.utils";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";

type Props = {
  record: ValidFeatureBlockWithIcon;
};

const FeatureBlockWithIconContainer = styled.div`
  background-color: ${colorsV4.white};
  padding: 1.5em;
  margin: 0;
  border-radius: 1.5em;
  border: 1px solid ${shade(0.1, colorsV4.canvas)};
  ${fromDesktop} {
    padding: 2em;
  }
  > * {
    + * {
      margin-top: 1.5rem;
    }
  }
`;
const Heading = styled.h3`
  font-weight: 500;
  max-width: 10em;
  .col-3 &,
  .col-6 & {
    font-size: 2rem;
  }
  .col-1 &,
  .col-2 &,
  .col-4 & {
    ${fromDesktopMd} {
      font-size: 2.4rem;
    }
  }
  + * {
    margin-top: 1em;
  }
`;

const Content = styled.div``;

const IconImg = styled.img`
  width: 10rem;
  height: 10rem;
  margin-left: -3%;
  pointer-events: none;
  .col-1 &,
  .col-2 &,
  .col-4 & {
    width: 12.5rem;
    height: 12.5rem;
  }
`;

const FeatureBlockWithIcon = (props: Props) => {
  return (
    <FeatureBlockWithIconContainer>
      {props.record.icon?.url && <IconImg src={props.record.icon.url} />}
      <div>
        <Heading>
          <WithCustomInlineFormatting as="span">
            {props.record.heading}
          </WithCustomInlineFormatting>
        </Heading>
        <Content>
          <ReactMarkdown>{props.record.content ?? ""}</ReactMarkdown>
        </Content>
      </div>
    </FeatureBlockWithIconContainer>
  );
};

export default FeatureBlockWithIcon;
