import { styled } from "@linaria/react";
import { actionTypeDefs } from "../../../constants/actionTypes.constants";

const LeftSidebarContentsWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
`;

const IconWrap = styled.div`
  flex: 1 1 auto;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 33px;
  max-height: 38px;
  svg {
    max-width: 20px;
    height: auto;
  }
`;

export const LeftSidebarContents = () => (
  <LeftSidebarContentsWrap>
    {actionTypeDefs.map(a => (
      <IconWrap
        key={a.slug}
        style={{
          color: a.color,
        }}
      >
        <a.Icon />
      </IconWrap>
    ))}
  </LeftSidebarContentsWrap>
);
