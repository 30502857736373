import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";

export const ReportV2BeforeAfterSplit = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 5px;
  ${onlyPhones} {
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
    h5 {
      padding-left: 0.375em;
    }
    li {
      font-size: 1.4rem !important;
    }
    > * {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
`;
