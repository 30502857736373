import { styled } from "@linaria/react";
import { DatoCmsCustomerLogosSection } from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import { colors } from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import Spacing from "../../layout/Spacing";
import { LPMultiRowLogoMarquee } from "../LPMultiRowLogoMarquee";
import {
  fromDesktopMd,
  fromTablet,
  uptoTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";

const Container = styled.div`
  border: 1px solid ${colors.purple200};
  border-radius: ${rSize("radius")};
  padding-top: ${rSize("xl")};
  padding-bottom: ${rSize("xl")};
  header {
    ${uptoTablet} {
      padding-left: 1em;
      padding-right: 1em;
      text-align: center;
    }
    ${fromTablet} {
      margin-left: ${widthInGrid(1, 1)};
      max-width: ${widthInGrid(6)};
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    ${fromDesktopMd} {
      grid-template-columns: minmax(auto, 265px) minmax(265px, 1fr);
    }
    h2 {
      font-family: ${serif};
      font-size: 2.8rem;
      font-weight: 400;
      ${uptoTablet} {
        padding-left: 1.25em;
        padding-right: 1.25em;
        + * {
          margin-top: 1em;
        }
      }
      ${fromTablet} {
        border-right: 1px solid ${colors.purple200};
        padding-right: 1em;
        margin-right: 1em;
      }
    }
  }
`;

export const LPCustomerLogosSection = ({
  section,
}: {
  section: DatoCmsCustomerLogosSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Container>
        <header>
          <h2>{section.heading}</h2>
          <p>{section.body}</p>
        </header>
        <Spacing size="xl" />
        <LPMultiRowLogoMarquee
          logos={(section.logos ?? []).map((customer, i) => (
            <img
              key={i}
              src={`${customer?.logo?.url}`}
              style={{
                aspectRatio: `${customer?.logo?.width}/${customer?.logo?.height}`,
              }}
            />
          ))}
        />
      </Container>
    </PageSection>
  );
};
