import { colors, withOpacity } from "../../../styles/colors.styles";
import ReportV2Section from "../components/ReportV2Section";
import { styled } from "@linaria/react";
import { serif } from "../../../styles/fonts.styles";
import {
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { AIPortalIcon } from "./icons/AIPortalIcon";
import { rSize } from "../../../styles/responsiveSizes.styles";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: "icon" "p";
  text-align: center;
  grid-gap: ${rSize("gap")};
  align-items: center;
  ${fromTablet} {
    text-align: left;
    grid-template-areas: "p icon";
    grid-template-columns: minmax(0, 1fr) auto;
  }
  svg {
    grid-area: icon;
    ${onlyPhones} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Paragraph = styled.p`
  grid-area: p;
  font-family: ${serif};
  max-width: 25em;
  font-size: 1.8rem;
  letter-spacing: -0.01em;
  ${fromTablet} {
    font-size: 2.4rem;
    letter-spacing: -0.02em;
  }
  a {
    text-decoration: none;
    display: inline-block;
    background-image: linear-gradient(
      to top,
      ${withOpacity(colors.purple300, 0)} 0,
      ${withOpacity(colors.purple300, 0)} 0.2em,
      ${colors.purple300} 0.2em,
      ${colors.purple300} calc(0.2em + 1px),
      ${withOpacity(colors.purple300, 0)} calc(0.2em + 1px)
    );
    &:hover {
      color: ${colors.purple};
      background-image: linear-gradient(
        to top,
        ${withOpacity(colors.purple, 0)} 0,
        ${withOpacity(colors.purple, 0)} 0.2em,
        ${colors.purple} 0.2em,
        ${colors.purple} calc(0.2em + 1px),
        ${withOpacity(colors.purple, 0)} calc(0.2em + 1px)
      );
    }
  }
`;

export const GuideSecurityAICalloutSection = () => {
  return (
    <ReportV2Section backgroundColor={colors.purple100}>
      <Grid>
        <Paragraph>
          Learn more about how we’re empowering security teams to build
          AI-powered workflows at{" "}
          <a href="/product/ai" target="_blank">
            tines.com/ai
          </a>
          .
        </Paragraph>
        <AIPortalIcon />
      </Grid>
    </ReportV2Section>
  );
};
