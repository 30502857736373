import { styled } from "@linaria/react";
import { cover, darken, mix } from "polished";
import React, { KeyboardEvent, useCallback, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSiteContext } from "../../context/site.context";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { zIndex } from "../../styles/zIndexes.styles";
import {
  AlgoliaTinesHit,
  AlgoliaTinesIndex,
  HUB_RESULTS_HEADING,
  SearchSection,
  contactSupportHit,
  defaultSuggestionsList,
  reportResultClick,
  sectionizeHits,
} from "../../utils/algolia.utils";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import GlobalSearchBar from "./GlobalSearchBar";
import { useQuery } from "react-query";
import AlgoliaHitEntry, { AlgoliaHitEntryWrap } from "./AlgoliaHitEntry";
import { resolveAfter, runAfter } from "../../utils/promises.utils";
import InfoBox from "../general/InfoBox";
import { UseStateReturnType } from "../../types/helper.types";
import { navigate } from "gatsby";
import gsap from "gsap/all";
import {
  elementIsVisibleInScrollParent,
  getScrollParent,
} from "../../utils/scroll.utils";
import { CustomEase } from "gsap/CustomEase";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import { debounce } from "../../utils/debounce.utils";
import { TextInputStyled } from "../forms/TextInput";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage.utils";
import LoadingIndicator from "../utilities/LoadingIndicator";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
gsap.registerPlugin(CustomEase);

type Props<T extends { query: string } = { query: string }> = {
  inline?: boolean;
  placeholder?: string;
  perPage?: number;
  withIcon?: boolean;
  openInNewTab?: boolean;
  doNotKeepQueryInUrl?: boolean;
  autoFocus?: boolean;
  formState?: UseStateReturnType<T>;
  showSuggestionsWhenEmpty?: boolean;
  showOpenInNewTabButton?: boolean;
};

export const GlobalSearchWrap = styled.div`
  --borderColor: ${colors.purple100};
  ${darkModeLinariaCSS(`--borderColor: ${colors.dark600};`)}
  &.overlay {
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: ${zIndex("GlobalSearchScreen")};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1em;
    ${fromTablet} {
      padding: ${rSize("lg")};
    }
  }
  &.inline {
    position: relative;
  }
`;

const Backdrop = styled.div`
  ${cover()};
  position: fixed;
  background-color: ${withOpacity(colors.dark500, 0.1)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(darken(0.1, colors.dark900), 0.5)};`
  )}
`;

const GlobalSearchPanel = styled.div`
  position: relative;
  border-radius: ${rSize("radius")};
  box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.025);
  background-color: var(--BlurredPanelBackgroundColor);
  color: ${colors.purple800};
  backdrop-filter: blur(2em);
  width: 100%;
  .overlay & {
    border: 1px solid var(--borderColor);
    overflow: hidden;
  }
  ${fromDesktop} {
    width: 620px;
  }
  ${darkModeLinariaCSS(`color: ${colors.white};`)}
  ${fromTablet} {
    margin-top: ${rSize("sectionPadding")};
    width: 620px;
  }
`;

const PanelInner = styled.div`
  max-height: 90vh;
  ${fromTablet} {
    max-height: 65vh;
  }
  overflow: auto;
  box-sizing: content-box;
  border-radius: inherit;
`;

const PanelHeader = styled.header`
  background-color: var(--BlurredPanelBackgroundColor);
  backdrop-filter: blur(1em);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--borderColor);
  ${TextInputStyled} {
    font-size: 1.61rem;
    [data-browser="safari"] & {
      font-size: 1.6rem;
      ${fromTablet} {
        font-size: 1.55rem;
      }
    }
    padding: 0 1em 0 1.8em;
    line-height: 1.5;
    background-color: transparent;
    border: 0;
    height: 3.5em;
    ${darkModeLinariaCSS(`
      background-color: transparent;
      color: ${colors.white};
    `)}
    .overlay & {
      border-radius: 0;
    }
    &:focus {
      background-color: ${mix(0.01, colors.purple50, colors.lightest)};
      ${darkModeLinariaCSS(`
        background-color: ${mix(0.01, colors.lightest, colors.dark900)};
      `)}
    }
  }
`;

export const GlobalSearchResultsContainer = styled.div`
  position: relative;
  padding: 1.6rem;
  ${fromTablet} {
    padding: 2.4rem;
  }
  font-size: 1.6rem;
  &:empty {
    padding: 0;
  }
  h3 {
    font-size: 1.3rem;
    opacity: 0.5;
    text-align: left;
    margin-bottom: 0.5em;
  }
  section {
    > ${AlgoliaHitEntryWrap}:first-child {
      margin-top: 0;
    }
    + section {
      margin-top: 1.25em;
    }
    &:last-child {
      > ${AlgoliaHitEntryWrap}:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const LoadingIndicatorWrapper = styled.div`
  position: absolute;
  top: calc(42.5% - 12px);
  left: calc(50% - 12px);
  opacity: 0.25;
`;

const EmptyResultsContainer = styled.div`
  padding-bottom: 1em;
`;

const LoadMoreButton = styled.button`
  appearance: none;
  border: 1px dashed ${colors.purple400};
  font: inherit;
  color: inherit;
  background-color: ${colors.purple50};
  border-radius: 0.5em;
  height: 5em;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1em;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 2em;
  cursor: pointer;
  ${darkModeLinariaCSS(`
    background-color: ${withOpacity(colors.purple, 0.1)};
    border-color: ${withOpacity(colors.purple, 0.4)};
  `)}
  @media (hover: hover) {
    &:hover {
      background-color: ${colors.purple100};
      ${darkModeLinariaCSS(`
        background-color: ${withOpacity(colors.purple, 0.2)};
      `)}
    }
  }
`;

const readPersistedRecentSearches = () => {
  const persistedRecentSearches =
    getLocalStorageItem<AlgoliaTinesHit[]>("RECENT_SEARCHES");
  if (
    persistedRecentSearches instanceof Array &&
    persistedRecentSearches.every(
      entry =>
        typeof entry.path === "string" &&
        typeof entry.title === "string" &&
        typeof entry.objectID === "string"
    )
  )
    return persistedRecentSearches.slice(0, 4);
  else return [];
};
const persistRecentSearchesToStorage = (hits: AlgoliaTinesHit[]) => {
  setLocalStorageItem("RECENT_SEARCHES", hits.slice(0, 4));
};
const persistNewRecentSearchToStorage = (withNewHit: AlgoliaTinesHit) => {
  persistRecentSearchesToStorage([
    withNewHit,
    ...readPersistedRecentSearches().filter(
      hit => hit.objectID !== withNewHit.objectID
    ),
  ]);
};

const GlobalSearch = (props: Props) => {
  const siteContext = useSiteContext();
  const _innerFormState = useState(() => ({ query: "" }));
  const formState = props.formState ?? _innerFormState;
  const wrapRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { query } = formState[0];
  const queryRef = useRef(query);
  queryRef.current = query;
  const { pathname } = siteContext.location;
  const [ready, setReady] = useState(false);
  const [recentSearches, setRecentSearches] = useState<AlgoliaTinesHit[]>([]);
  const [lastQueryId, setLastQueryId] = useState<string | null>(null);
  useOnMount(() => {
    if (pathname.match(/^\/library/)) return;
    const urlParams = getUrlQueryParams();
    const s = urlParams.s;
    const redirectedFrom404 = urlParams["redirected-from-404"]
      ?.replace(/[\/|-]/g, " ")
      .replace(/%20/g, " ");
    const initialQuery = [s, redirectedFrom404].filter(i => i).join(" ");
    if (initialQuery) {
      formState[1]({ ...formState[0], query: initialQuery });
      setDebouncedQuery(initialQuery);
    }
    if (props.inline) {
      setTimeout(() => {
        setReady(true);
      }, 500);
    } else {
      setRecentSearches(readPersistedRecentSearches());
      setReady(true);
    }
    const handleWheel = (e: WheelEvent) => {
      if (!scrollContainerRef.current) return;
      const { scrollHeight, clientHeight, scrollTop } =
        scrollContainerRef.current;
      const maxScrollTop = scrollHeight - clientHeight - 1;
      const deltaY = e.deltaY;
      if (
        (scrollTop <= 0 && deltaY < 0) ||
        (scrollTop >= maxScrollTop && deltaY > 0)
      ) {
        e.preventDefault();
      }
    };
    wrapRef.current?.addEventListener("wheel", handleWheel, {
      passive: false,
      capture: true,
    });
    return () => {
      wrapRef.current?.removeEventListener("wheel", handleWheel, {
        capture: true,
      });
      removeUrlQueryParam("s");
      removeUrlQueryParam("redirected-from-404");
    };
  });
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQueryUpdater = useCallback(
    debounce(
      () => {
        setPage(0);
        setHasReachedEnd(false);
        setDebouncedQuery(queryRef.current);
        if (!props.doNotKeepQueryInUrl) {
          if (!queryRef.current) removeUrlQueryParam("s");
          else setUrlQueryParam("s", queryRef.current);
        }
      },
      { duration: 125, fireImmediately: true }
    ),
    [queryRef]
  );
  const handleQueryUpdate = () => {
    debouncedQueryUpdater();
  };
  const [page, setPage] = useState(0);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const [entries, setEntries] = useState<AlgoliaTinesHit[]>([]);
  const { isLoading, error } = useQuery(
    ["globalSearch", debouncedQuery, props.perPage, page],
    async ({ queryKey }) => {
      const [, _searchQuery, perPage, _page] = queryKey;
      let searchQuery = (_searchQuery as string).trim();
      if (!searchQuery || `${searchQuery}`.length < 2) {
        await resolveAfter();
        return [];
      }
      searchQuery = searchQuery.replace("documentation", "docs");
      const page = _page as number;
      let filters = /(library|workflow|story|stories|automat)/.test(searchQuery)
        ? ""
        : "NOT _tags:story";
      if (
        /^(api|api docs|docs api)\s+|\s+(api|api docs|docs api)$/i.test(
          searchQuery
        )
      ) {
        filters = "_tags:api";
        searchQuery = searchQuery
          .replace(/(api|api docs|docs api)/i, "")
          .trim();
      } else if (/^docs\s+|\s+docs$/i.test(searchQuery)) {
        filters = "_tags:docs";
        searchQuery = searchQuery.replace(/(docs)/i, "").trim();
      } else if (
        /^(library|story library|stories library|story|stories|workflow|workflows)\s+|\s+(library|story library|stories library|story|stories|workflow|workflows)$/.test(
          searchQuery
        )
      ) {
        filters = "_tags:story OR _tags:library OR _tags:libraryCollection";
        searchQuery = searchQuery
          .replace(
            /(library|story library|stories library|story|stories|workflow|workflows)/i,
            ""
          )
          .trim();
      }
      const params = {
        filters,
        hitsPerPage: (perPage as number) ?? 10,
        clickAnalytics: true,
        page: page,
      };
      const response = await AlgoliaTinesIndex.search(searchQuery, params);
      if (response.nbPages === page + 1) setHasReachedEnd(true);
      if (page === 0) {
        setEntries(response.hits as AlgoliaTinesHit[]);
      } else {
        setEntries([...entries, ...(response.hits as AlgoliaTinesHit[])]);
      }
      if (response.queryID) setLastQueryId(response.queryID);
      return sections;
    },
    {
      onSettled: () => {
        if (page === 0) setHighlightedHitIndex(0);
      },
    }
  );
  const sections = sectionizeHits(entries, `${pathname ?? ""}`);
  const [highlightedHitIndex, setHighlightedHitIndex] = useState(0);
  const highlightedHitIndexRef = useRef(highlightedHitIndex);
  highlightedHitIndexRef.current = highlightedHitIndex;
  const hasQuery = debouncedQuery.trim().length > 1;
  const shouldShowRecentSearches = !hasQuery;
  const hasAnyResults = entries.length > 0;
  const shouldShowResults = hasQuery && sections.length > 0 && hasAnyResults;
  const shouldShowSuggestions =
    (!hasQuery || !hasAnyResults) && props.showSuggestionsWhenEmpty;
  const allSections: SearchSection[] = [
    ...(shouldShowRecentSearches
      ? [
          {
            title: "Recent",
            hits: recentSearches,
          },
        ]
      : []),
    ...(shouldShowResults ? sections : []),
    ...(shouldShowSuggestions
      ? [
          ...(shouldShowSuggestions
            ? [
                {
                  title: "Suggested",
                  hits: defaultSuggestionsList,
                },
              ]
            : []),
          {
            title: "Can’t find what you’re looking for?",
            hits: [contactSupportHit],
          },
        ]
      : []),
  ];
  const hits = sections.map(section => section.hits).flat();
  const allHitsIncludingSuggestions = allSections
    .map(section => section.hits)
    .flat();
  const dismiss = () => {
    if (siteContext.shouldShowGlobalSearch) {
      siteContext.toggleGlobalSearch();
    }
  };
  const handleArrowDown = () => {
    if (
      highlightedHitIndexRef.current <
      allHitsIncludingSuggestions.length - 1
    ) {
      setHighlightedHitIndex(highlightedHitIndexRef.current + 1);
    }
    runAfter(scrollToHighlightedHit);
  };
  const handleArrowUp = () => {
    if (highlightedHitIndexRef.current > 0) {
      setHighlightedHitIndex(highlightedHitIndexRef.current - 1);
    }
    runAfter(scrollToHighlightedHit);
  };
  const searchResultsContainerRef = useRef<HTMLDivElement>(null);
  const scrollToHighlightedHit = () => {
    const linkEl =
      searchResultsContainerRef.current?.querySelector<HTMLDivElement>(
        ".hasHighlight"
      );
    if (!linkEl) return;
    if (
      elementIsVisibleInScrollParent({
        el: linkEl,
        visibleHeightRangeOffsetTop: 120,
        visibleHeightRangeOffsetBottom: -120,
      })
    )
      return;
    const scrollParent = getScrollParent(linkEl) ?? document.body;
    const scrollTarget = scrollParent === document.body ? window : scrollParent;
    const parentHeight =
      scrollParent === document.body
        ? window.innerHeight
        : scrollParent.clientHeight;
    gsap.to(scrollTarget, {
      duration: 0.2,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      ease: CustomEase.create(
        "custom",
        "M0,0,C0.176,0,0.094,0.38,0.218,0.782,0.272,0.958,0.374,1,1,1"
      ),
      scrollTo: {
        y: linkEl.offsetTop - parentHeight / 2,
      },
    });
  };
  const navigateToHighlightedHit = () => {
    const highlightedHit =
      allHitsIncludingSuggestions[highlightedHitIndexRef.current];
    if (highlightedHit) {
      navigate(highlightedHit.path);
      persistNewRecentSearchToStorage(highlightedHit);
    }
    dismiss();
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowDown":
        return handleArrowDown();
      case "ArrowUp":
        return handleArrowUp();
      case "Enter":
        if (isLoading) return;
        return navigateToHighlightedHit();
    }
  };
  useHotkeys("escape", dismiss);
  useHotkeys("down", handleArrowDown);
  useHotkeys("up", handleArrowUp);
  const handleResultClick = async (hit: AlgoliaTinesHit) => {
    reportResultClick(
      lastQueryId,
      hit.objectID,
      allHitsIncludingSuggestions.indexOf(hit) + 1
    );
    persistNewRecentSearchToStorage(hit);
    await resolveAfter();
    dismiss();
  };
  const removeHitFromRecentSearches = (hit: AlgoliaTinesHit) => {
    const newList = recentSearches.filter(h => h.objectID !== hit.objectID);
    setRecentSearches(newList.slice(0, 4));
    persistRecentSearchesToStorage(newList);
  };
  const loadMore = () => {
    setPage(page + 1);
  };
  const searchBar = (
    <GlobalSearchBar
      withIcon={!props.inline || props.withIcon}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      formState={formState}
      onEscape={dismiss}
      loading={!!(formState[0].query && isLoading)}
      onQueryUpdate={handleQueryUpdate}
      onKeyDown={handleKeyDown}
      showOpenInNewTabButton={props.showOpenInNewTabButton}
    />
  );
  const searchResults = (
    <>
      {props.inline &&
        hasQuery &&
        hits.length === 0 &&
        !shouldShowRecentSearches &&
        !isLoading && (
          <EmptyResultsContainer>
            <h3>No results matched your query.</h3>
          </EmptyResultsContainer>
        )}
      {allSections
        .filter(section => section.hits.length > 0)
        .map((section, i) => (
          <section key={section.title ?? i}>
            {section.title &&
              section.title !== HUB_RESULTS_HEADING &&
              (allSections.length > 1 || section.title !== "All results") && (
                <h3>{section.title}</h3>
              )}
            {section.hits.map(hit => (
              <AlgoliaHitEntry
                key={hit.objectID}
                hit={hit}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleResultClick}
                openInNewTab={props.openInNewTab}
                hasHighlight={
                  highlightedHitIndexRef.current ===
                  allHitsIncludingSuggestions.indexOf(hit)
                }
                isRecentSearchEntry={section.title === "Recent"}
                onRemoveEntry={
                  section.title === "Recent"
                    ? removeHitFromRecentSearches
                    : undefined
                }
              />
            ))}
          </section>
        ))}
      {!hasReachedEnd && !isLoading && hasQuery && hits.length > 0 && (
        <LoadMoreButton onClick={loadMore}>Load more</LoadMoreButton>
      )}
    </>
  );
  return siteContext.location.pathname.match(/^\/search/) &&
    !props.inline ? null : (
    <GlobalSearchWrap
      className={props.inline ? "inline" : "overlay"}
      ref={wrapRef}
    >
      {props.inline ? (
        <>
          {searchBar}
          <GlobalSearchResultsContainer ref={searchResultsContainerRef}>
            {ready ? (
              searchResults
            ) : (
              <LoadingIndicatorWrapper>
                <LoadingIndicator size={24} />
              </LoadingIndicatorWrapper>
            )}
          </GlobalSearchResultsContainer>
        </>
      ) : (
        <>
          <Backdrop onClick={() => siteContext.toggleGlobalSearch(false)} />
          <GlobalSearchPanel className="GlobalSearchPanel">
            <PanelInner ref={scrollContainerRef}>
              <PanelHeader>{searchBar}</PanelHeader>
              <GlobalSearchResultsContainer ref={searchResultsContainerRef}>
                {error ? (
                  <div>
                    <InfoBox>Failed to fetch search results</InfoBox>
                  </div>
                ) : null}
                {searchResults}
              </GlobalSearchResultsContainer>
            </PanelInner>
          </GlobalSearchPanel>
        </>
      )}
    </GlobalSearchWrap>
  );
};

export default GlobalSearch;
