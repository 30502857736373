import { Link } from "gatsby";
import { DatoCmsProduct } from "../../../graphql-types";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { css } from "linaria";
import { styled } from "@linaria/react";

type Props = {
  product: DatoCmsProduct;
};

const EntryStyle = css`
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple100};
  border-radius: 1em;
  aspect-ratio: 1/1;
  padding: 1em;
  display: grid;
  grid-template-rows: minmax(0, 1fr) minmax(2em, auto);
  grid-template-areas: "logo" "label";
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  grid-gap: 1em;
  line-height: 1;
  img {
    grid-area: logo;
    height: 42px;
    margin-left: auto;
    margin-right: auto;
  }
  span {
    grid-area: label;
  }
  &:hover {
    border-color: ${colors.purple200};
  }
`;

export const ProductExplorerEntryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
  grid-gap: ${rSize("gap")};
`;

const ProductExplorerEntry = (props: Props) => {
  return (
    <Link
      className={EntryStyle}
      to={`/solutions/products/${props.product.slug}`}
    >
      {props.product.icon?.url && (
        <img
          src={props.product.icon.url}
          style={{
            aspectRatio: `${props.product.icon.width}/${props.product.icon.height}`,
          }}
        />
      )}
      <span>{props.product.name}</span>
    </Link>
  );
};

export default ProductExplorerEntry;
