import { css } from "linaria";
import { useState } from "react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { reportErrorSilently } from "../../utils/error.utils";
import { isString } from "../../utils/typeChecks.utils";
import Button from "./Button";
import InfoBox from "../general/InfoBox";
import TextInput, { TextInputStyled } from "./TextInput";
import Spacing from "../layout/Spacing";
import { styled } from "@linaria/react";
import { whenMobileNav } from "../site/SiteNav";
import { getCookie } from "../../utils/cookies.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import FileUploader, { UploadedFileMeta } from "../basic/FileUploader";
import Textarea, { TextareaStyled } from "./Textarea";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { zendeskAttachmentUploadHandler } from "../../utils/zendesk.utils";
import PrivacyNotice from "../utilities/PrivacyNotice";
import { AlignCenter } from "../typography/Align";
import { getFormMetaInfo } from "../../utils/form.utils";

type Props = {
  className?: string;
};

const FormContainer = styled.div`
  ${whenMobileNav} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const InputList = styled.div`
  > * {
    + * {
      margin-top: 2px;
    }
  }
  ${TextInputStyled}, ${TextareaStyled} {
    display: block;
    border-radius: 2px;
    border-color: transparent;
    background-color: ${withOpacity(colors.orange, 0.1)};
    ${darkModeLinariaCSS(
      `
        background-color: ${withOpacity(colors.orange, 0.1)};
        border-color: transparent;
      `
    )}
    &:hover {
      border-color: ${colors.white};
      background-color: ${withOpacity(colors.orange, 0.15)};
    }
    &:focus {
      outline-color: ${colors.orange};
    }
  }
  input {
    &:first-of-type {
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
  }
`;

const FileUploaderSection = styled.div`
  text-align: left;
  border-radius: 2px 2px 0.5em 0.5em;
  padding: 1.25em;
  background-color: ${withOpacity(colors.orange, 0.1)};
  ${darkModeLinariaCSS(`background-color: ${withOpacity(colors.orange, 0.1)};`)}
  > * + * {
    margin-top: 0.75em;
  }
`;

const TenantDownForm = (props: Props) => {
  const formState = useState({
    name: "",
    email: "",
    tenantUrl: "",
    body: "",
    attachmentId: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [form] = formState;
  const formIsComplete = !!(
    form.email.trim() &&
    form.email.includes("@") &&
    form.name.trim() &&
    form.tenantUrl.trim()
  );

  useOnMount(() => {
    const name = [getCookie("first_name"), getCookie("last_name")]
      .filter(i => i)
      .join(" ");
    const newFormState = { ...formState[0] };
    if (name && !newFormState.name) Object.assign(newFormState, { name });
    const email = getCookie("email_address");
    if (email && !newFormState.email) Object.assign(newFormState, { email });
    const tenantUrl = getCookie("last_used_tenant_domain");
    if (tenantUrl && !newFormState.tenantUrl)
      Object.assign(newFormState, { tenantUrl });
    formState[1](newFormState);
  });

  const handleFileUploaded = (details: UploadedFileMeta) => {
    formState[1]({
      ...formState[0],
      attachmentId: details.id,
    });
  };

  const handleSubmit = async () => {
    if (!form.email) return;
    if (!form.email.includes("@")) {
      setError("Please enter a valid email address.");
      return;
    }
    setAwaitingResponse(true);
    if (!(await emailIsLegitimate(form.email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }
    const url =
      "https://hq.tines.io/webhook/c2c98f89d5ead83bb0b4c362151919ee/de5dc514e71fb0509b6425a8d6e2664f";
    try {
      await fetch(url, {
        method: "post",
        body: JSON.stringify({
          ...form,
          ...getFormMetaInfo(),
        }),
      });
      setError(null);
      setSuccess(true);
    } catch (e) {
      reportErrorSilently(e);
      setError(e);
    } finally {
      setAwaitingResponse(false);
    }
  };

  const SuccessMessage = () => {
    return (
      <InfoBox color={colors.orange}>
        Your request has been received. We will be in touch as soon as possible.
      </InfoBox>
    );
  };

  return (
    <FormContainer className={props.className}>
      <>
        {success ? (
          <SuccessMessage />
        ) : (
          <>
            <InputList>
              <TextInput
                name="name"
                formState={formState}
                placeholder="Your name *"
                required
                autoFocus
              />
              <TextInput
                type="email"
                name="email"
                formState={formState}
                placeholder="Your email *"
                required
              />
              <TextInput
                name="tenantUrl"
                formState={formState}
                placeholder="Tenant URL *"
                required
                onEnter={() => {
                  handleSubmit();
                }}
              />
              <Textarea
                formState={formState}
                name="body"
                rows={8}
                placeholder="Add more information"
                required
              />
              <FileUploaderSection>
                <p>
                  <strong>Attach a file</strong> (optional)
                </p>
                <FileUploader
                  fileUploadHandler={zendeskAttachmentUploadHandler}
                  onFileUploaded={handleFileUploaded}
                />
              </FileUploaderSection>
            </InputList>
            <Spacing size=".5em" />
            <Button
              type="button"
              appearance="filled"
              color="orange"
              onClick={handleSubmit}
              loading={awaitingResponse}
              disabled={!formIsComplete}
              fullWidth
              height="4em"
            >
              Submit
            </Button>
            <Spacing size="1em" />
            <AlignCenter>
              <PrivacyNotice />
            </AlignCenter>
          </>
        )}
        {error && (
          <InfoBox
            color={colors.orange}
            className={css`
              margin-top: 0.5em;
              max-width: 62rem;
            `}
          >
            {isString(error)
              ? error
              : "An error occurred. Please try again later."}
          </InfoBox>
        )}
      </>
    </FormContainer>
  );
};

export default TenantDownForm;
