import { styled } from "@linaria/react";
import { DatoCmsHowItWorksSection } from "../../../../graphql-types";
import { withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  fromDesktop,
  fromTablet,
  fromTabletLg,
  uptoTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../../styles/fonts.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { colors } from "../../../styles/colors.styles";

type Props = {
  section: DatoCmsHowItWorksSection;
};

const HowItWorksCard = styled.div`
  background-color: ${withOpacity(colors.pink, 0.15)};
  color: ${colors.pink700};
  border-radius: ${rSize("radius")};
  padding: ${rSize("xl")};
  font-size: 1.4rem;
  ${darkModeLinariaCSS(`color: ${colors.pink300};`)}
  ${uptoTablet} {
    text-align: center;
  }
  ${fromDesktop} {
    font-size: 1.6rem;
  }
  display: grid;
  grid-gap: 1em;
  grid-auto-flow: row;
  &:only-child {
    ${fromTabletLg} {
      grid-auto-flow: column;
      grid-template-columns: minmax(0, 1fr) auto;
      align-items: center;
      min-height: 35rem;
    }
  }
`;

const TextContent = styled.div`
  padding-bottom: 1em;
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  h2 {
    font-size: 3rem;
    font-family: ${font("serif")};
    font-weight: 400;
    ${fromDesktop} {
      font-size: 3.4rem;
    }
  }
  p {
    max-width: 28em;
    ${uptoTablet} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  text-align: center;
  max-width: 28em;
  ${uptoTablet} {
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTablet} {
    grid-gap: 2em;
  }
  svg {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  b {
    display: block;
    margin-top: 0.5em;
  }
`;

const HowItWorksSection = (props: Props) => {
  return (
    <HowItWorksCard id="how-it-works">
      <TextContent>
        <h2>How it works</h2>
        <p>
          Instantly import stories to your tenant where you can adapt them to
          meet your unique business requirements.
        </p>
      </TextContent>
      <Grid>
        <div>
          <ExploreIcon />
          <b>Explore</b>
        </div>
        <div>
          <ImportIcon />
          <b>Import</b>
        </div>
        <div>
          <AdaptIcon />
          <b>Adapt</b>
        </div>
      </Grid>
    </HowItWorksCard>
  );
};

const ExploreIcon = () => (
  <svg width="97" height="97" viewBox="0 0 97 97" fill="none">
    <path
      d="M29.4742 17.0642H13.5226V33.016H29.4742V17.0642Z"
      fill="#F8F4F0"
      stroke="#32313B"
      strokeWidth="0.590735"
      strokeMiterlimit="10"
    />
    <path
      d="M47.506 24.1581C44.7026 22.3299 41.3554 21.2667 37.7588 21.2667C27.8899 21.2667 19.8891 29.2673 19.8891 39.1365C19.8891 42.7961 20.9901 46.1981 22.8778 49.0313H47.506V24.1581Z"
      fill="#F8F4F0"
      stroke="#32313B"
      strokeWidth="0.590735"
      strokeMiterlimit="10"
    />
    <path
      d="M56.1115 28.0292C48.2934 19.7913 35.2771 19.451 27.0393 27.2692C18.8012 35.0875 18.4605 48.1037 26.2789 56.3415C34.0971 64.5798 47.1137 64.9202 55.3515 57.1018C63.5896 49.2837 63.9294 36.2675 56.1115 28.0292ZM29.8304 52.9714C23.8737 46.6945 24.1328 36.7776 30.4096 30.8209C36.6865 24.8639 46.6031 25.1234 52.5601 31.4001C58.5168 37.6767 58.2575 47.5936 51.9808 53.5506C45.7043 59.5073 35.7871 59.2478 29.8304 52.9714Z"
      fill="#CE6492"
      stroke="#32313B"
      strokeWidth="0.590735"
      strokeMiterlimit="10"
    />
    <path
      d="M53.4018 25.7194C45.5836 17.4816 32.5674 17.1408 24.3292 24.9593C16.0913 32.7774 15.7508 45.7939 23.5692 54.0319C31.3875 62.2697 44.4039 62.6101 52.6418 54.7919C60.8798 46.9736 61.22 33.9574 53.4018 25.7194ZM27.1207 50.6613C21.1639 44.3847 21.4234 34.4676 27.6999 28.5109C33.9765 22.554 43.8935 22.8133 49.8502 29.0899C55.8068 35.3666 55.5478 45.2835 49.2712 51.2405C42.9947 57.1972 33.0774 56.938 27.1207 50.6613Z"
      fill="#FFCCE2"
      stroke="#32313B"
      strokeWidth="0.590735"
      strokeMiterlimit="10"
    />
    <path
      d="M50.2196 56.8025L52.6811 59.264C52.6811 59.264 56.7916 58.944 58.6007 56.3226C60.4098 53.7012 59.8929 50.8952 59.8929 50.8952L57.3823 48.2738C57.3823 48.2738 56.9979 49.8958 54.3179 53.0366C51.9549 55.8057 50.2196 56.8025 50.2196 56.8025Z"
      fill="#CE6492"
      stroke="#32313B"
      strokeWidth="0.590735"
      strokeMiterlimit="10"
    />
    <path
      d="M66.3749 59.2675C67.6372 60.2881 67.8327 62.1385 66.8119 63.4009C65.7914 64.6627 63.9408 64.8585 62.6788 63.838L55.7879 58.2652C54.526 57.2445 54.3301 55.394 55.3509 54.1318C56.3717 52.8692 58.2223 52.674 59.4843 53.6946L66.3749 59.2675Z"
      fill="#FFCCE2"
      stroke="#32313B"
      strokeWidth="0.590735"
      strokeMiterlimit="10"
    />
    <path
      d="M81.7293 69.7952C83.6223 71.3265 83.9158 74.1027 82.3849 75.9956C80.8536 77.8887 78.0779 78.1825 76.1846 76.6511L63.6431 66.5082C61.7502 64.9773 61.4565 62.2013 62.9877 60.308C64.5187 58.415 67.2947 58.1211 69.1877 59.6526L81.7293 69.7952Z"
      fill="#CE6492"
      stroke="#32313B"
      strokeWidth="0.590735"
      strokeMiterlimit="10"
    />
    <path
      d="M54.9578 54.4642C54.9578 54.4642 53.2841 56.8763 53.678 58.9439L55.5979 58.3532C55.5979 58.3532 53.924 56.4826 54.9578 54.4642Z"
      fill="#32313B"
    />
    <path
      d="M35.6359 24.5336L40.0664 26.5273C40.0664 26.5273 45.2353 26.4535 48.632 28.3734C48.632 28.3734 46.4168 25.5674 42.2078 24.6074C37.9988 23.6475 35.6359 24.5336 35.6359 24.5336Z"
      fill="#32313B"
    />
  </svg>
);

const ImportIcon = () => (
  <svg width="98" height="97" viewBox="0 0 98 97" fill="none">
    <path
      d="M46.1503 71.3549C47.012 67.4677 46.0898 62.1982 45.0223 56.0964C43.0557 44.858 40.8268 32.12 49.4429 25.7189C50.3322 25.0583 50.5178 23.8019 49.857 22.9126C49.1962 22.0236 47.94 21.838 47.0506 22.4987C36.4487 30.3748 39.0119 45.0202 41.0708 56.7879C42.0234 62.2329 42.9235 67.3759 42.2339 70.4865C40.3786 78.8569 34.6022 80.2427 32.2046 80.4497C29.3699 80.6949 26.5835 79.8795 24.311 78.2909C22.4303 76.9759 20.9015 75.1306 19.9916 72.9203C18.5674 69.4615 18.5375 66.4956 19.9 63.8535C20.6261 62.4459 21.6792 61.2543 22.8671 60.254C22.3701 58.9272 21.8908 57.5015 21.4901 56.252C19.4669 57.7153 17.5771 59.6049 16.3348 62.0147C14.4263 65.7149 14.4087 69.8978 16.2819 74.4477C17.4883 77.3775 19.5153 79.8233 22.0123 81.5686C25.0419 83.6863 28.7633 84.7741 32.5503 84.4467C35.7242 84.1725 38.6331 82.9165 40.9629 80.8149C43.5262 78.502 45.2716 75.3193 46.1503 71.3549Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M31.11 70.8147C40.4321 67.2278 41.4778 64.3027 42.892 62.047C43.9426 60.3714 45.6563 57.3707 48.9787 57.2949C53.917 57.1818 55.1984 54.3844 55.1984 54.3844L52.3154 46.8921L49.4327 39.3997C49.4327 39.3997 46.6065 38.1828 42.8666 41.409C40.35 43.5796 37.0668 42.5017 35.1638 41.9621C32.6028 41.2364 29.8655 39.7663 20.5433 43.3532C18.5181 44.1325 19.2345 50.8949 22.1411 58.4716H22.141C22.1441 58.4805 22.1479 58.4895 22.151 58.4982C22.1545 58.5071 22.1579 58.5161 22.1612 58.5249H22.1613C25.0833 66.0954 29.0847 71.5942 31.11 70.8147Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M54.8763 54.454C53.5222 54.9748 51.1159 51.9959 49.5012 47.7994C47.8871 43.6034 47.6756 39.7796 49.0297 39.2588C50.3831 38.7381 52.7895 41.7172 54.4039 45.9132C56.0187 50.1097 56.2298 53.9332 54.8763 54.454Z"
      fill="#B35980"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M52.0757 49.7397C53.1084 52.4236 54.436 54.4106 55.0409 54.1778C55.6459 53.945 55.2992 51.5806 54.2666 48.8967C53.2339 46.2128 51.9063 44.2258 51.3013 44.4586C50.6964 44.6914 51.0431 47.0558 52.0757 49.7397Z"
      fill="#32313B"
    />
    <path
      d="M70.3708 43.1871C72.6492 42.3103 76.6713 43.3436 76.6713 43.3436L87.3432 36.7463L86.2371 33.8712L85.1306 30.996L72.7887 33.2533C72.7887 33.2533 70.4969 36.7164 68.2183 37.5929C66.5773 38.2243 51.6036 43.9856 51.6036 43.9856C51.6036 43.9856 50.5675 44.678 51.4486 47.2404C52.3779 49.9428 53.7559 49.5796 53.7559 49.5796C53.7559 49.5796 68.7297 43.8182 70.3708 43.1871Z"
      fill="#F8F4F0"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.5114 31.3606L85.0343 31.0612L87.2794 36.7275L85.12 38.0318L82.5114 31.3606Z"
      fill="#FFCCE2"
    />
    <path
      d="M70.3708 43.1871C72.6492 42.3103 76.6713 43.3436 76.6713 43.3436L87.3432 36.7463L86.2371 33.8712L85.1306 30.996L72.7887 33.2533C72.7887 33.2533 70.4969 36.7164 68.2183 37.5929C66.5773 38.2243 51.6036 43.9856 51.6036 43.9856C51.6036 43.9856 50.5675 44.678 51.4486 47.2404C52.3779 49.9428 53.7559 49.5796 53.7559 49.5796C53.7559 49.5796 68.7297 43.8182 70.3708 43.1871Z"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.3708 43.1871C72.6492 42.3103 76.6713 43.3436 76.6713 43.3436L87.3432 36.7463L86.2371 33.8712L85.1306 30.996L72.7887 33.2533C72.7887 33.2533 70.4969 36.7164 68.2183 37.5929C66.5773 38.2243 51.6036 43.9856 51.6036 43.9856C51.6036 43.9856 50.5675 44.678 51.4486 47.2404C52.3779 49.9428 53.7559 49.5796 53.7559 49.5796C53.7559 49.5796 68.7297 43.8182 70.3708 43.1871Z"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.1846 56.501L19.0325 58.2499C19.0325 58.2499 18.9094 60.6422 20.4864 62.5969L22.867 60.2538L21.1846 56.501Z"
      fill="#32313B"
    />
    <path
      d="M46.9246 20.799C45.9169 21.4335 45.9585 23.3092 47.0164 24.9876C48.0742 26.6662 49.7481 27.5123 50.7556 26.8777L61.0426 20.3945C62.0497 19.7597 62.0087 17.8842 60.9508 16.2057C59.8927 14.5271 58.2187 13.6808 57.2116 14.3158L46.9246 20.799Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M57.3093 18.5045C58.3672 20.1831 60.0413 21.0294 61.0484 20.3946C62.0554 19.7599 62.0142 17.8846 60.9563 16.206C59.8983 14.5274 58.2243 13.6812 57.2172 14.3159C56.2101 14.9506 56.2513 16.8259 57.3093 18.5045Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M46.3636 22.3844L45.6249 23.4412C45.6249 23.4412 44.9689 24.9638 45.9396 25.9919C47.7235 27.8814 48.9447 26.6943 48.9447 26.6943C48.9447 26.6943 47.3484 25.4618 47.1462 24.8834C46.9439 24.305 46.3636 22.3844 46.3636 22.3844Z"
      fill="#32313B"
    />
    <path
      d="M58.5062 18.6292C59.0702 19.9002 60.2042 20.6303 61.0391 20.2599C61.874 19.8894 62.0937 18.5587 61.5297 17.2876C60.9657 16.0165 59.8316 15.2864 58.9967 15.6569C58.1618 16.0273 57.9422 17.3581 58.5062 18.6292Z"
      fill="#32313B"
    />
    <path
      d="M58.7671 15.5221C58.3505 15.7603 58.3619 16.5635 58.7923 17.3159C59.223 18.0683 59.9096 18.4849 60.3258 18.2465L68.0859 13.8067C68.7845 13.407 69.4397 12.2225 69.009 11.4702C68.5787 10.7178 67.2256 10.6825 66.5272 11.0822L58.7671 15.5221Z"
      fill="#F8F4F0"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M66.3588 14.7949C66.1039 14.9411 65.5475 14.4494 65.1172 13.6969C64.6867 12.9446 64.545 12.2162 64.8 12.0702"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M64.5642 15.8217C64.309 15.9677 63.7528 15.4761 63.3225 14.7238C62.8919 13.9713 62.75 13.2432 63.0053 13.097"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M62.7696 16.8484C62.5145 16.9946 61.9581 16.5026 61.528 15.7507C61.0973 14.9982 60.9555 14.2699 61.2104 14.1238"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
    />
    <path
      d="M42.5376 65.4521C42.5376 65.4521 45.2358 63.259 45.7663 60.8332L45.4364 58.9709C45.4364 58.9709 42.8249 62.0402 42.3246 63.0887L42.5376 65.4521Z"
      fill="#32313B"
    />
    <path
      d="M82.5427 31.5634L85.0645 37.9587"
      stroke="#32313B"
      strokeWidth="0.716375"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

const AdaptIcon = () => (
  <svg width="97" height="97" viewBox="0 0 97 97" fill="none">
    <g clipPath="url(#clip0_492_30960)">
      <path
        d="M83.2794 10.9486L84.1922 15.8629"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M85.443 22.5972L86.3557 27.5114"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M79.3447 12.8271L82.5923 16.6267"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M87.0427 21.8335L90.2904 25.6329"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M76.8766 16.4214L81.5888 18.088"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M88.0462 20.3719L92.7586 22.0386"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M88.1847 18.6046L93.0989 17.6919"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M87.4209 17.0048L91.2203 13.7572"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M85.9595 16.0014L87.6261 11.2891"
        stroke="#EB81AF"
        strokeWidth="0.987364"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M84.1278 20.5109L83.8292 20.1489L83.531 19.787C81.5662 21.4068 76.0188 22.9763 76.0188 22.9763C74.4978 25.1599 71.5497 27.8052 71.5497 27.8052C71.1158 28.1629 71.3059 29.1109 71.9749 29.9222C72.6438 30.7334 73.5379 31.1012 73.9721 30.7432C73.9721 30.7432 77.1311 28.3536 79.5645 27.2772C79.5644 27.2774 82.1631 22.1308 84.1278 20.5109Z"
        fill="#FAE1EC"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M77.6589 25.2412C77.9348 25.5758 78.5099 25.5573 78.9434 25.1998C79.3769 24.8424 79.5047 24.2814 79.2288 23.9468C78.953 23.6122 78.3779 23.6307 77.9443 23.9882C77.5108 24.3456 77.3831 24.9066 77.6589 25.2412Z"
        fill="#32313B"
      />
      <path
        d="M78.4407 24.5917L83.8292 20.149"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
      />
      <path
        d="M59.1358 46.0978C58.4209 46.6772 56.5355 45.5362 54.9242 43.549C53.3131 41.562 52.5863 39.4813 53.3013 38.9016L71.8999 26.6763C72.5351 26.1613 73.7418 26.5963 74.5947 27.6484C75.4477 28.7005 75.6242 29.971 74.9887 30.486L59.1358 46.0978Z"
        fill="#EB81AF"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
      />
      <path
        d="M18.8442 80.5391C19.5906 79.8599 21.9907 77.5567 23.9515 74.3373C26.3047 70.4725 28.6086 72.0857 32.1906 69.2943C35.7729 66.5028 33.9705 64.8616 35.9244 63.3391C37.8783 61.8164 37.9312 62.8919 41.0247 60.4812C44.1184 58.0703 44.0914 54.5093 46.2896 52.7966C48.4874 51.0837 56.3869 49.133 64.1206 43.1068C64.1212 43.1063 64.1219 43.1061 64.1224 43.1055C65.8313 41.7741 65.7053 38.7555 63.8407 36.3633C61.9768 33.9713 59.0805 33.1115 57.3719 34.4429C49.6378 40.4699 45.8153 47.6542 43.6173 49.3671C41.419 51.0801 37.9727 50.1835 34.879 52.5943C31.7854 55.0051 32.8153 55.3194 30.8613 56.8421C28.9076 58.3646 27.7566 56.2157 24.1742 59.0073C20.5921 61.7987 21.5933 64.4267 17.271 65.7646C13.6673 66.88 10.8461 68.6459 10.0072 69.2028C9.91831 69.2554 9.83217 69.3135 9.74949 69.3778C7.95117 70.7793 8.4765 74.4608 10.923 77.6003C13.3699 80.7401 16.8112 82.1491 18.6097 80.7475C18.6932 80.6828 18.771 80.6132 18.8442 80.5391Z"
        fill="#EB81AF"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
      />
      <path
        d="M16.8158 73.0685C14.398 69.966 11.0137 68.5609 9.25668 69.9301C7.49966 71.2994 8.0353 74.9244 10.4531 78.0269C12.8708 81.1294 16.2552 82.5345 18.0122 81.1653C19.7692 79.7961 19.2336 76.171 16.8158 73.0685Z"
        fill="#B35980"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
      />
      <path
        d="M28.3071 57.4495C29.2977 57.6164 31.6329 58.2033 33.226 60.2478C34.8192 62.2921 34.928 63.6367 34.6272 66.0616"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M31.9722 55.7543C33.2959 55.847 35.036 56.9255 36.0294 58.2001C37.7855 60.4539 37.2504 62.5277 37.2504 62.5277"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M31.0732 64.708C32.4682 64.6287 33.5469 63.6449 33.4824 62.5106C33.4179 61.3764 32.2347 60.5211 30.8397 60.6004C29.4447 60.6797 28.366 61.6635 28.4305 62.7978C28.495 63.9321 29.6782 64.7873 31.0732 64.708Z"
        fill="#32313B"
      />
      <path
        d="M59.2576 42.9245C60.6009 42.5394 61.4361 41.342 61.123 40.2499C60.81 39.1579 59.4673 38.5847 58.124 38.9698C56.7807 39.3548 55.9456 40.5523 56.2586 41.6443C56.5717 42.7364 57.9144 43.3095 59.2576 42.9245Z"
        fill="#32313B"
      />
      <path
        d="M31.5852 63.7293C32.4929 63.0219 32.7176 61.7926 32.0871 60.9836C31.4566 60.1746 30.2097 60.0921 29.302 60.7995C28.3943 61.5069 28.1695 62.7362 28.8 63.5452C29.4305 64.3543 30.6775 64.4367 31.5852 63.7293Z"
        fill="#36996F"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
      />
      <path
        d="M56.1932 42.1929C55.5626 41.3839 56.4904 39.6069 57.3982 38.8995C58.3061 38.1921 59.5527 38.2746 60.1831 39.0838C60.8136 39.8926 60.589 41.1219 59.6813 41.8293C58.7736 42.5365 56.8236 43.0019 56.1932 42.1929Z"
        fill="#36996F"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
      />
      <path
        d="M42.5463 49.9823C42.5463 49.9823 44.0208 50.5006 44.655 51.3143C45.2892 52.128 45.4315 53.6846 45.4315 53.6846"
        stroke="#32313B"
        strokeWidth="0.617102"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_492_30960">
        <rect
          width="96.59"
          height="96.59"
          fill="white"
          transform="translate(0.179901)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HowItWorksSection;
