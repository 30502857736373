import React from "react";
import { useStoryboardContext } from "./StoryboardContext";
import StoryboardActionLink from "./StoryboardActionLink";

const StoryboardActionLinkSet = () => {
  const { links } = useStoryboardContext().story;
  return (
    <g>
      {links.map(link => (
        <StoryboardActionLink
          key={`${link.source.index}-${link.receiver.index}`}
          link={link}
        />
      ))}
    </g>
  );
};

export default StoryboardActionLinkSet;
