import { CSSProperties, cx } from "linaria";
import { footnotesContainerStyle } from "../basic/Footnotes";

const PrivacyNotice = (props: {
  centered?: boolean;
  linkColor?: string;
  smaller?: boolean;
}) => {
  return (
    <div
      className={cx(
        footnotesContainerStyle,
        props.centered && "centered",
        props.smaller && "smaller"
      )}
      style={
        {
          "--ac": props.linkColor,
        } as CSSProperties
      }
    >
      <p>
        By filling out this form you agree to the terms and conditions in our{" "}
        <a href="/privacy" target="_blank">
          privacy notice
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyNotice;
