import Spacing from "../../components/layout/Spacing";
import { CisoAIChapter1 } from "../../components/reportsV2/cisoPerspectives/CisoAIChapter1";
import { CisoAIChapter2 } from "../../components/reportsV2/cisoPerspectives/CisoAIChapter2";
import { CisoAIChapter3 } from "../../components/reportsV2/cisoPerspectives/CisoAIChapter3";
import { CisoAIHero } from "../../components/reportsV2/cisoPerspectives/CisoAIHero";
import { CisoAIIntro } from "../../components/reportsV2/cisoPerspectives/CisoAIIntro";
import { CisoAIKeyFindings } from "../../components/reportsV2/cisoPerspectives/CisoAIKeyFindings";
import { CisoAINav } from "../../components/reportsV2/cisoPerspectives/CisoAINav";
import { CisoAISummarySection } from "../../components/reportsV2/cisoPerspectives/CisoAISummarySection";
import {
  ReportV2HalfDarkSectionNavControlPoint,
  ReportV2NavOrangeControlPoint,
  ReportV2NavPurpleControlPoint,
} from "../../components/reportsV2/components/ReportV2CommonNavControlPoints";
import ReportV2PageContent from "../../components/reportsV2/components/ReportV2PageContent";
import { ReportV2PageLayout } from "../../components/reportsV2/components/ReportV2PageLayout";
import { ExplosionCTASection } from "../../components/reusableSections/ExplosionCTASection";
import { PageThemeControllerPoint } from "../../components/site/PageThemeController";
import SEO from "../../components/utilities/SEO";
import { colors } from "../../styles/colors.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import {
  DocumentIDMap,
  recordDocumentEvent,
} from "../../utils/documents.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import ogImage from "../../../static/images/og/tines-ciso-perspectives-ai-og-image.png";

const PageCisoPerspectivesAI: PageComponent = props => {
  const description =
    "How are CISOs approaching AI in security? Discover key insights on AI adoption in our report for cybersecurity leaders, which is based on a pulse survey of 50+ CISOs.";
  useOnMount(() => {
    recordDocumentEvent({
      type: "view",
      id: DocumentIDMap.cisoPerspectiveAI2023,
    });
  });

  return (
    <Page
      {...props}
      textColor={colors.orange800}
      navThemeColor="orange"
      backgroundColor={colors.yellow50}
      navLogoColor={colors.dark500}
      mobileNavBgColor={colors.orange}
    >
      <SEO
        title="CISO Perspectives: Separating the reality of AI from the hype"
        description={description}
        image={ogImage}
      />
      <ReportV2NavOrangeControlPoint />
      <PageThemeControllerPoint backgroundColor={colors.yellow50} />
      <ReportV2PageContent>
        <CisoAIHero />

        <ReportV2PageLayout nav={<CisoAINav />}>
          <ReportV2HalfDarkSectionNavControlPoint />
          <CisoAIIntro />
          <CisoAIKeyFindings />

          <CisoAIChapter1 />
          <CisoAIChapter2 />
          <CisoAIChapter3 />

          <CisoAISummarySection />
        </ReportV2PageLayout>
      </ReportV2PageContent>

      <ReportV2NavPurpleControlPoint />
      <PageThemeControllerPoint backgroundColor={colors.lightest} />

      <Spacing size="sectionMargin" />

      <ExplosionCTASection />
    </Page>
  );
};

export default PageCisoPerspectivesAI;
