import { styled } from "@linaria/react";
import { useRef } from "react";
import {
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { useViewportSize } from "../../utils/window.utils";
import gsap from "gsap";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import { responsiveSizeMaps } from "../../styles/responsiveSizes.styles";

const useCaseLines = [
  [
    ["Process/convert any data structure", colors.orange600],
    ["Summarize an alert or a case", colors.yellow600],
    ["Statistically analyze event data", colors.orange500],
    ["Convert between file formats", colors.orange700],
  ],
  [
    ["Perform advanced cryptography", colors.orange500],
    ["Translate between different languages", colors.yellow800],
    ["Add a watermark to an image", colors.orange600],
    ["Suggest next steps to an analyst", colors.yellow500],
  ],
  [
    ["Explain in plain English", colors.yellow600],
    ["Make a decision to direct automation", colors.yellow500],
    ["Build secure AI apps with pages", colors.yellow500],
    ["Map an incoming alert to a record schema", colors.orange600],
  ],
  [
    // duplicates
    ["Statistically analyze event data", colors.orange700],
    ["Explain in plain English", colors.yellow500],
    ["Perform advanced cryptography", colors.orange500],
    ["Build secure AI apps with pages", colors.yellow500],
  ],
] as unknown as [string, string][][];

const UseCaseKaleidoscopeWrap = styled.div`
  position: relative;
  overflow: hidden;
  color: ${colors.orange600};
`;

const maskerBreakpoint = maxPageContentWidth;

const Masker = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: -1em;
  @media (min-width: ${maskerBreakpoint}px) {
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: ${responsiveSizeMaps.pageMargin.desktopMd * 10}px;
      display: block;
      z-index: 1;
    }
    &:before {
      left: 0;
      background-image: linear-gradient(
        to right,
        ${colors.lightest},
        ${withOpacity(colors.lightest, 0)}
      );
    }
    &:after {
      right: 0;
      background-image: linear-gradient(
        to left,
        ${colors.lightest},
        ${withOpacity(colors.lightest, 0)}
      );
    }
  }
`;

const LinesWrap = styled.div`
  > * {
    + * {
      margin-top: 0.66em;
    }
  }
`;

export const AIUseCaseKaleidoscope = () => {
  return (
    <UseCaseKaleidoscopeWrap>
      <Masker>
        <LinesWrap>
          {useCaseLines.map((line, i) => (
            <UseCaseLine key={i} line={line} index={i} />
          ))}
        </LinesWrap>
      </Masker>
    </UseCaseKaleidoscopeWrap>
  );
};

const UseCaseLineWrap = styled.div`
  font-size: 2.4rem;
  font-family: ${serif};
  font-weight: 400;
  display: flex;
  justify-content: center;
  letter-spacing: -0.01em;
  ${fromTablet} {
    font-size: 2.8rem;
  }
  ${fromTabletLg} {
    font-weight: 300;
    font-size: 3.2rem;
  }
`;

const UseCaseLineInner = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
`;

const UseCaseEntryWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  white-space: nowrap;
  align-items: center;
`;

const UseCaseEntryIcon = styled.div`
  width: 0.25em;
  height: 0.25em;
  background-color: ${colors.orange100};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 0.75em;
`;

const UseCaseLine = (props: { line: [string, string][]; index: number }) => {
  const ref = useRef<HTMLDivElement>(null);
  const direction = props.index % 2 === 1 ? 1 : -1;
  const { vw } = useViewportSize();
  useOnMount(() => {
    const tween = gsap.fromTo(
      ref.current,
      {
        x: `${direction * 15 + props.index * 5}vw`,
      },
      {
        x: `${props.index * 5}vw`,
        scrollTrigger: {
          trigger: ref.current,
          scrub: 1,
          start: "top bottom",
          end: "bottom top",
          invalidateOnRefresh: true,
        },
      }
    );
    return () => {
      tween.kill();
    };
  });
  const renderWords = () =>
    props.line.map(([word, color], i) => (
      <UseCaseEntryWrap
        key={i}
        style={{
          color,
        }}
      >
        <UseCaseEntryIcon />
        <div>{word}</div>
      </UseCaseEntryWrap>
    ));
  return (
    <UseCaseLineWrap>
      <UseCaseLineInner ref={ref}>
        {renderWords()}
        {renderWords()}
        {vw >= 1920 && (
          <>
            {renderWords()}
            {renderWords()}
          </>
        )}
      </UseCaseLineInner>
    </UseCaseLineWrap>
  );
};
