import { css, cx } from "linaria";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

export const LatestStoriesIcon = () => (
  <svg
    width="97"
    height="95"
    viewBox="0 0 97 95"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    className={cx(
      css`
        --shadow-color: ${colorsV4.warmBlack};
        ${darkModeLinariaCSS(`--shadow-color: ${colorsV4.warmBlack900};`)}
      `
    )}
  >
    <path
      d="M80.1838 94.8061C82.9474 94.8061 85.1879 94.1243 85.1879 93.2832C85.1879 92.4421 82.9474 91.7603 80.1838 91.7603C77.4201 91.7603 75.1797 92.4421 75.1797 93.2832C75.1797 94.1243 77.4201 94.8061 80.1838 94.8061Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M56.0504 93.4485C68.3604 93.4485 78.3396 91.5552 78.3396 89.2197C78.3396 86.8843 68.3604 84.991 56.0504 84.991C43.7404 84.991 33.7612 86.8843 33.7612 89.2197C33.7612 91.5552 43.7404 93.4485 56.0504 93.4485Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M50.982 83.9671C48.0639 86.1583 43.9218 85.5684 41.7309 82.6502L41.7208 82.6372C39.5299 79.719 40.1194 75.5772 43.0377 73.3861L64.0247 57.6295C66.9429 55.4384 71.0845 56.0283 73.2758 58.9465L73.2857 58.9595C75.4769 61.8776 74.8872 66.0195 71.9688 68.2106L50.982 83.9671Z"
      fill="#D66B99"
      stroke="var(--shadow-color)"
    />
    <path
      d="M64.9761 88.537C64.9761 90.9154 57.9797 92.8437 49.3494 92.8437C40.7188 92.8437 33.7227 90.9154 33.7227 88.537C33.7227 86.1585 40.7188 73.8899 49.3494 73.8899C57.9797 73.8899 64.9761 86.1585 64.9761 88.537Z"
      fill="#B35980"
      stroke="var(--shadow-color)"
    />
    <path
      d="M73.2242 58.9827C75.5081 61.8289 75.0522 65.9873 72.2061 68.2715L72.1933 68.2818C69.3469 70.5655 65.1885 70.1098 62.9045 67.2637L46.4796 46.7956C44.1959 43.9495 44.6516 39.7908 47.4979 37.5069L47.5107 37.4964C50.3568 35.2124 54.5157 35.6681 56.7994 38.5145L73.2242 58.9827Z"
      fill="#EB81AF"
      stroke="var(--shadow-color)"
    />
    <path
      d="M52.9473 86.0012C50.0291 88.1928 45.8873 87.6029 43.6963 84.6845L43.6863 84.6715C41.4951 81.7533 42.0848 77.6111 45.0032 75.4204L65.9899 59.6636C68.9081 57.4723 73.0499 58.0622 75.2411 60.9806L75.2509 60.9936C77.4421 63.9119 76.8526 68.054 73.9342 70.2447L52.9473 86.0012Z"
      fill="#EB81AF"
      stroke="var(--shadow-color)"
    />
    <path
      d="M48.8421 47.3366C56.8022 47.3366 63.2551 43.0523 63.2551 37.7673C63.2551 32.4823 56.8022 28.198 48.8421 28.198C40.8821 28.198 34.4292 32.4823 34.4292 37.7673C34.4292 43.0523 40.8821 47.3366 48.8421 47.3366Z"
      fill="#B35980"
      stroke="var(--shadow-color)"
    />
    <path
      d="M90.0772 25.5245C90.0772 20.926 71.6154 17.1982 48.8422 17.1982C26.0687 17.1982 7.60742 20.926 7.60742 25.5245C7.60742 26.4483 7.60742 29.8867 7.60742 30.8104C7.60742 35.4088 26.0687 39.1365 48.8422 39.1365C71.6154 39.1365 90.0772 35.4088 90.0772 30.8104C90.0772 29.8867 90.0772 26.4483 90.0772 25.5245Z"
      fill="#B35980"
      stroke="var(--shadow-color)"
    />
    <path
      d="M48.8419 33.8507C71.6153 33.8507 90.0769 30.1229 90.0769 25.5246C90.0769 20.9262 71.6153 17.1985 48.8419 17.1985C26.0685 17.1985 7.60693 20.9262 7.60693 25.5246C7.60693 30.1229 26.0685 33.8507 48.8419 33.8507Z"
      fill="#FDB2D2"
      stroke="var(--shadow-color)"
    />
    <path
      d="M47.1582 47.5114C47.1582 47.5114 53.4599 48.0778 58.6996 44.5376C58.6996 44.5376 55.0177 48.9277 49.2824 50.2731L47.1582 47.5114Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M34.5545 38.8024L34.5366 38.9143C34.5366 38.9143 46.0778 40.412 62.4643 40.9406L63.3726 38.5898C63.3726 38.5898 50.3442 38.9439 45.2462 39.0147C40.1482 39.0854 34.5545 38.8024 34.5545 38.8024Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M69.4795 68.6597C70.7931 68.6597 71.858 67.5949 71.858 66.2813C71.858 64.9677 70.7931 63.9028 69.4795 63.9028C68.1659 63.9028 67.1011 64.9677 67.1011 66.2813C67.1011 67.5949 68.1659 68.6597 69.4795 68.6597Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M49.0916 83.7092C50.4053 83.7092 51.4702 82.6443 51.4702 81.3306C51.4702 80.0169 50.4053 78.9519 49.0916 78.9519C47.7779 78.9519 46.7129 80.0169 46.7129 81.3306C46.7129 82.6443 47.7779 83.7092 49.0916 83.7092Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M69.5623 67.7742C70.876 67.7742 71.9409 66.7092 71.9409 65.3955C71.9409 64.0818 70.876 63.0168 69.5623 63.0168C68.2486 63.0168 67.1836 64.0818 67.1836 65.3955C67.1836 66.7092 68.2486 67.7742 69.5623 67.7742Z"
      fill="#81D8B4"
      stroke="var(--shadow-color)"
    />
    <path
      d="M49.1741 82.8233C50.4878 82.8233 51.5527 81.7583 51.5527 80.4446C51.5527 79.1309 50.4878 78.0659 49.1741 78.0659C47.8604 78.0659 46.7954 79.1309 46.7954 80.4446C46.7954 81.7583 47.8604 82.8233 49.1741 82.8233Z"
      fill="#81D8B4"
      stroke="var(--shadow-color)"
    />
    <path
      d="M48.842 34.5114C38.212 34.5114 28.2003 33.7319 20.6508 32.3167C11.7174 30.642 7.55664 28.3998 7.55664 25.2601C7.55664 22.1204 11.7174 19.8782 20.6508 18.2033C28.2003 16.7881 38.212 16.0085 48.842 16.0085C59.4718 16.0085 69.4835 16.7879 77.033 18.2033C85.9664 19.878 90.1276 22.1204 90.1276 25.2601C90.1276 28.3998 85.9664 30.642 77.033 32.3167C69.4835 33.7319 59.4718 34.5114 48.842 34.5114ZM11.4925 25.2601C12.0139 25.9012 14.6975 27.4878 22.5317 28.8406C29.7032 30.079 39.0472 30.7613 48.842 30.7613C58.6368 30.7613 67.9804 30.0792 75.1519 28.8406C82.9863 27.4878 85.6697 25.9012 86.1913 25.2601C85.6697 24.619 82.9861 23.0322 75.1521 21.6794C67.9806 20.441 58.6366 19.7587 48.842 19.7587C39.047 19.7587 29.7032 20.441 22.5317 21.6794C14.6975 23.0324 12.0141 24.6192 11.4925 25.2601Z"
      fill="#EB81AF"
      stroke="var(--shadow-color)"
    />
    <path
      d="M58.0584 62.0005L56.7368 62.9696L57.7066 65.6083L59.9971 63.8462L58.0584 62.0005Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M12.9035 26.2015C12.1365 25.7786 7.31369 23.2111 12.9035 20.2209"
      stroke="var(--shadow-color)"
    />
    <path
      d="M84.7183 26.2015C85.4852 25.7786 90.3085 23.2111 84.7183 20.2209"
      stroke="var(--shadow-color)"
    />
    <path
      d="M65.733 27.9003C71.2478 27.9268 75.7235 26.8968 75.7298 25.5998C75.736 24.3028 71.2704 23.2299 65.7555 23.2034C60.2407 23.177 55.765 24.2069 55.7587 25.5039C55.7525 26.8009 60.2181 27.8738 65.733 27.9003Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M16.2383 27.186C16.2383 27.186 17.2957 22.6929 27.6033 21.2393C27.6033 21.2393 18.617 21.6357 12.8025 24.4109C12.8025 24.4109 11.0845 25.0716 13.5955 26.3931C16.1062 27.7146 16.2383 27.186 16.2383 27.186Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M28.8078 26.2028L57.4789 19.0951L57.7895 6.57178L28.0103 12.2092L28.8078 26.2028Z"
      fill="#F0946C"
      stroke="var(--shadow-color)"
    />
    <path
      d="M28.8603 27.1226L63.7964 27.3011L62.9461 12.3879L28.0103 12.2092L28.8603 27.1226Z"
      fill="#F0946C"
      stroke="var(--shadow-color)"
    />
    <path
      d="M63.3968 22.3247L28.5723 21.6197L28.314 17.0894L63.1385 17.7943L63.3968 22.3247Z"
      fill="#D67C56"
    />
    <path
      d="M28.8603 27.1226L63.7964 27.3011L62.9461 12.3879L28.0103 12.2092L28.8603 27.1226Z"
      stroke="var(--shadow-color)"
    />
    <path
      d="M62.9461 12.388L58.3145 6.36182L28.0103 12.2093L62.9461 12.388Z"
      fill="#D67C56"
      stroke="var(--shadow-color)"
    />
    <path
      d="M65.5811 10.062L62.8301 10.2188L63.8033 27.2955L66.5543 27.1387L65.5811 10.062Z"
      fill="#D67C56"
      stroke="var(--shadow-color)"
    />
    <path
      d="M66.7398 9.90414C66.664 8.57378 65.5811 7.53113 64.2329 7.39652C64.2696 7.19849 64.2856 6.99395 64.2736 6.78458C64.2039 5.55965 63.2803 4.5779 62.0818 4.32464C62.0831 4.25891 62.085 4.19339 62.0812 4.1264C61.9968 2.64548 60.6658 1.51715 59.1082 1.60598C57.5507 1.6946 56.3568 2.96699 56.4412 4.44791C56.5109 5.67263 57.4345 6.65417 58.633 6.90764C58.6315 6.97337 58.6298 7.0391 58.6338 7.10609C58.7094 8.43623 59.7924 9.4793 61.1408 9.61349C61.1041 9.81194 61.0879 10.0163 61.0999 10.2259C61.1843 11.707 62.5153 12.8351 64.0726 12.7465C65.63 12.6577 66.8245 11.3853 66.7398 9.90414Z"
      fill="#FFD18C"
      stroke="var(--shadow-color)"
    />
    <path
      d="M48.1695 11.4226C51.5838 11.4226 54.3517 10.5 54.3517 9.36203C54.3517 8.22404 51.5838 7.30151 48.1695 7.30151C44.7552 7.30151 41.9873 8.22404 41.9873 9.36203C41.9873 10.5 44.7552 11.4226 48.1695 11.4226Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M52.0149 8.79548C52.0149 10.0708 49.398 11.1044 46.1699 11.1044C42.942 11.1044 40.3252 10.0708 40.3252 8.79548C40.3252 7.52014 42.9418 4.89893 46.1699 4.89893C49.398 4.89893 52.0149 7.52014 52.0149 8.79548Z"
      fill="#FFD2BF"
      stroke="var(--shadow-color)"
    />
    <path
      d="M41.8794 5.53155C41.8794 7.05552 43.7039 8.29096 45.9542 8.29096C48.2046 8.29096 49.9596 7.05384 50.0289 5.53155C50.1582 2.68563 48.649 1.26267 46.8812 0.960693C46.8812 0.960693 48.6922 3.37569 46.5792 3.89292C45.2549 4.21716 41.8794 2.9013 41.8794 5.53155Z"
      fill="#FFD2BF"
      stroke="var(--shadow-color)"
    />
    <path
      d="M45.6738 8.37725C45.6738 8.37725 47.5279 9.06731 51.2792 7.64435L50.0288 6.26465C50.0288 6.26465 49.339 7.77371 47.6573 8.03243C45.9756 8.29094 45.6738 8.37725 45.6738 8.37725Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M77.8164 93.961C80.01 93.961 81.7882 92.1828 81.7882 89.9893C81.7882 87.7958 80.01 86.0176 77.8164 86.0176C75.6229 86.0176 73.8447 87.7958 73.8447 89.9893C73.8447 92.1828 75.6229 93.961 77.8164 93.961Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
    />
    <path
      d="M11.5966 76.8676C5.77896 75.6063 1.45947 69.0417 1.45947 69.0417C1.45947 69.0417 8.35398 69.215 13.0355 70.2297C17.717 71.2446 18.8319 73.042 18.4346 74.8749C18.0373 76.708 16.2783 77.8823 11.5966 76.8676Z"
      fill="#45BE8B"
      stroke="var(--shadow-color)"
    />
    <path
      d="M16.9392 69.6748C14.5929 66.0418 15.7378 60.448 15.7378 60.448C15.7378 60.448 19.1963 64.0743 21.0844 66.9975C22.9727 69.9213 22.6243 71.418 21.4796 72.1574C20.3347 72.8968 18.8275 72.5986 16.9392 69.6748Z"
      fill="#45BE8B"
      stroke="var(--shadow-color)"
    />
    <path
      d="M6.37451 71.5396C6.37451 71.5396 19.5115 77.6821 28.6106 75.3569"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6555 79.3314C31.4191 79.3314 33.6595 78.6495 33.6595 77.8083C33.6595 76.9671 31.4191 76.2852 28.6555 76.2852C25.8918 76.2852 23.6514 76.9671 23.6514 77.8083C23.6514 78.6495 25.8918 79.3314 28.6555 79.3314Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M18.2402 66.7017C18.2402 66.7017 21.8802 73.5617 26.5002 76.0817"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5176 78.4325C28.7112 78.4325 30.4894 76.6543 30.4894 74.4608C30.4894 72.2672 28.7112 70.489 26.5176 70.489C24.3241 70.489 22.5459 72.2672 22.5459 74.4608C22.5459 76.6543 24.3241 78.4325 26.5176 78.4325Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
    />
    <path
      d="M25.7458 74.374C26.329 74.374 26.8017 73.9012 26.8017 73.3181C26.8017 72.7349 26.329 72.2622 25.7458 72.2622C25.1627 72.2622 24.6899 72.7349 24.6899 73.3181C24.6899 73.9012 25.1627 74.374 25.7458 74.374Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M78.9299 89.6804C79.4168 89.6804 79.8115 89.2857 79.8115 88.7988C79.8115 88.3119 79.4168 87.9172 78.9299 87.9172C78.443 87.9172 78.0483 88.3119 78.0483 88.7988C78.0483 89.2857 78.443 89.6804 78.9299 89.6804Z"
      fill="var(--shadow-color)"
    />
    <path
      d="M76.7165 37.4852C76.7165 37.4852 79.0192 34.8942 80.7527 32.3133C84.7977 34.6901 92.519 36.7628 92.519 36.7628C92.519 36.7628 92.0488 28.7817 90.5379 24.34C93.4155 23.1638 96.4183 21.4319 96.4183 21.4319C96.4183 21.4319 89.9612 16.3671 87.3556 15.5217C82.7175 14.0166 80.1036 21.5254 80.1036 21.5254C80.1036 21.5254 73.2076 22.1493 72.7589 27.4154C72.526 30.145 76.7165 37.4852 76.7165 37.4852Z"
      fill="#45BE8B"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.0795 32.5417C89.0795 32.5417 81.9777 23.8256 79.1985 20.4148C73.2083 13.0631 77.4262 9.50024 77.4262 9.50024"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.6691 21.0995C91.6691 21.0995 85.1343 17.7225 81.7178 23.2804"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.4963 32.6476C77.4963 32.6476 75.5088 25.5653 81.6425 23.342"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.7816 30.4495C82.7816 30.4495 81.7316 27.7195 84.0416 26.4595"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.3915 26.4297C88.3915 26.4297 86.1686 24.5285 84.21 26.2854"
      stroke="var(--shadow-color)"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.4194 34.0814C79.4194 34.0814 81.5194 35.3414 84.5993 35.2014L85.1596 34.3614L80.7527 32.3132L79.4194 34.0814Z"
      fill="var(--shadow-color)"
    />
  </svg>
);
