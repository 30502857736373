import { cx } from "linaria";
import { CSSProperties, ReactNode } from "react";
import { round } from "lodash-es";
import { styled } from "@linaria/react";
import { PrimaryColorName, color } from "../../../styles/colors.styles";
import { serif } from "../../../styles/fonts.styles";
import { reportV2BodyTextParagraphStyle } from "./ReportV2TextBlock";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { ReportV2DotGridGraphSimple } from "./ReportV2DotGridGraph";
import {
  fromDesktopMd,
  fromTablet,
  uptoPhoneLg,
  uptoTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";

type ItemDescriptor = {
  percentage: number;
  heading: ReactNode;
  description?: ReactNode;
};

type Props = {
  total: number;
  color: PrimaryColorName;
  items: (ItemDescriptor | { text: ReactNode })[];
  borderColor?: string;
  rowHighlightColor?: string;
  rowHighlightBorderColor?: string;
  numberOfRowsToHighlight?: number;
};

export const ReportV2StatTableWrap = styled.table`
  border-collapse: collapse;
  ${uptoTablet} {
    width: calc(100% + 3em);
    margin-left: -1.5em;
    margin-right: -1.5em;
    tr {
      > * {
        &:first-child {
          padding-left: 1.5em;
          padding-right: 1.5em;
        }
      }
    }
  }
  ${fromTablet} {
    width: 100%;
  }
  tr {
    position: relative;
    --padding: 1.4rem;
    vertical-align: top;
    > * {
      padding-bottom: var(--padding);
    }
    + tr {
      > * {
        border-top: 1px solid var(--borderColor);
        padding-top: var(--padding);
      }
    }
    &:last-child {
      > * {
        padding-bottom: 0;
      }
    }
    &.isTextRow {
      --padding: ${rSize("gap")};
      > * {
        padding-bottom: ${rSize("gap", 1.5)};
      }
    }
    ${fromDesktopMd} {
      display: flex;
      &.highlighted {
        --padding: ${rSize("gap", 1.5)};
        &.noDescription {
          vertical-align: middle;
        }
        > * {
          position: relative;
          border: 0;
          z-index: 1;
        }
        + tr {
          > * {
            padding-top: ${rSize("gap", 1.5)};
          }
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: ${rSize("gap", -2)};
          left: ${rSize("gap", -2)};
          background-color: var(--rowHighlightColor);
          border-top: 1px solid var(--rowHighlightBorderColor);
        }
        &:first-of-type {
          &:after {
            top: ${rSize("gap", -2)};
            border-top: none;
            border-top-left-radius: ${rSize("radius")};
            border-top-right-radius: ${rSize("radius")};
          }
        }
        &.lastHighlightedRow {
          + tr {
            > * {
              border-top: 0;
              padding-top: ${rSize("gap", 2.5)};
            }
          }
          &:after {
            bottom: ${rSize("gap", -1)};
            border-bottom-left-radius: ${rSize("radius")};
            border-bottom-right-radius: ${rSize("radius")};
          }
        }
      }
    }
  }
`;

const PercentageCell = styled.td`
  text-align: right;
  padding-right: ${rSize("gap")};
  font-feature-settings: "tnum" 1;
  ${fromDesktopMd} {
    display: block;
    width: ${widthInGrid(1, 1)};
    flex: 0 0 ${widthInGrid(1, 1)};
  }
  tr.highlighted & {
    ${fromDesktopMd} {
      font-feature-settings: "tnum" 0;
      font-family: ${serif};
      font-size: 3.2rem;
      font-weight: 300;
      line-height: 0.95;
      flex: 0 0 auto;
    }
  }
`;

const ContentCell = styled.td`
  padding-right: 1.8rem;
  h4 {
    font-size: inherit;
  }
  tr.highlighted & {
    ${fromDesktopMd} {
      display: block;
      flex: 1 1 100%;
    }
    h4 {
      ${fromDesktopMd} {
        font-family: ${serif};
        font-size: 2.4rem;
        font-weight: 400;
      }
      + * {
        margin-top: 0.5em;
      }
    }
  }
  p {
    ${reportV2BodyTextParagraphStyle};
    line-height: 1.25;
    max-width: 32em;
  }
`;

const GraphCell = styled.td`
  text-align: right;
  svg,
  canvas {
    display: block;
    margin-left: auto;
    ${uptoPhoneLg} {
      display: none;
    }
  }
  tr:not(.highlighted) & {
    vertical-align: middle;
  }
  ${fromDesktopMd} {
    display: block;
    flex: 1 0 10em;
    tr.highlighted & {
      text-align: right;
      padding-top: 0.2em;
    }
  }
  table.hasLargerPercentages & {
    > * {
      margin-top: -0.2em;
      margin-bottom: -0.2em;
    }
  }
`;

const ReportV2StatTable = (props: Props) => {
  const itemDescriptors = props.items.filter(
    i => !("text" in i)
  ) as ItemDescriptor[];
  const hasLargerPercentages = itemDescriptors.some(i => i.percentage > 20);
  return (
    <ReportV2StatTableWrap
      style={
        {
          "--borderColor": props.borderColor ?? color(props.color, 150),
          "--rowHighlightBorderColor":
            props.rowHighlightBorderColor ??
            props.borderColor ??
            color(props.color, 150),
          "--rowHighlightColor":
            props.rowHighlightColor ?? color(props.color, 50),
        } as CSSProperties
      }
      className={cx(
        hasLargerPercentages && "hasLargerPercentages",
        (props.numberOfRowsToHighlight ||
          itemDescriptors.some(i => !!i.description)) &&
          "hasHighlightedRows"
      )}
    >
      <tbody>
        {props.items.map((item, i, arr) => {
          if ("text" in item)
            return (
              <tr key={i} className="isTextRow">
                <PercentageCell></PercentageCell>
                <ContentCell>
                  <p>{item.text}</p>
                </ContentCell>
                <GraphCell />
              </tr>
            );
          const next = arr[i + 1];
          const shouldHighlight =
            item.description ||
            (props.numberOfRowsToHighlight &&
              i < props.numberOfRowsToHighlight);
          return (
            <tr
              key={i}
              className={cx(
                shouldHighlight && "highlighted",
                shouldHighlight && !item.description && "noDescription",
                shouldHighlight &&
                  ((next && "text" in next) || !next?.description) &&
                  "lastHighlightedRow"
              )}
            >
              <PercentageCell>{item.percentage}%</PercentageCell>
              <ContentCell>
                <h4>{item.heading}</h4>
                {item.description && <p>{item.description}</p>}
              </ContentCell>
              <GraphCell>
                <ReportV2DotGridGraphSimple
                  total={900}
                  value={round((900 * item.percentage) / 100)}
                  dotSize={hasLargerPercentages ? 1.75 : 2.5}
                  gap={hasLargerPercentages ? 1 : 1.5}
                  rows={hasLargerPercentages ? 9 : 5}
                  color={color(props.color)}
                  hideOutOfRange
                  fixedSize
                  flipHorizontal
                />
              </GraphCell>
            </tr>
          );
        })}
      </tbody>
    </ReportV2StatTableWrap>
  );
};

export default ReportV2StatTable;
