export const LogoTuro = () => (
  <svg width="88" height="32" viewBox="0 0 88 32">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.887 2.187L77.416 2.853L87.745 16.013L76.928 29.787C76.914 29.8 76.914 29.813 76.901 29.827C76.725 30.04 76.548 30.24 76.345 30.427C75.2525 31.4452 73.8124 32.0078 72.319 32H0V0H72.305C73.824 0 75.261 0.56 76.331 1.573C76.5306 1.76423 76.7165 1.96942 76.887 2.187ZM58.084 15.594C58.084 19.714 61.351 22.927 65.513 22.927C69.62 22.927 72.969 19.727 72.969 15.794C72.9732 14.8348 72.7818 13.8848 72.4065 13.002C72.0311 12.1193 71.4798 11.3224 70.786 10.66C69.39 9.32696 67.533 8.57996 65.54 8.57996C61.433 8.57996 58.084 11.727 58.084 15.594ZM61.975 12.394C62.883 11.54 64.143 11.06 65.513 11.06C66.855 11.06 68.088 11.514 69.037 12.354C69.986 13.207 70.515 14.394 70.515 15.687C70.5149 16.3215 70.385 16.9493 70.1332 17.5318C69.8814 18.1143 69.5131 18.6391 69.051 19.074C68.116 19.967 66.882 20.447 65.554 20.447C64.266 20.447 62.992 19.967 62.056 19.127C61.08 18.26 60.552 17.087 60.552 15.82C60.552 14.487 61.053 13.26 61.975 12.394ZM26.514 20.594C26.988 21.287 28.438 22.874 31.76 22.874C33.157 22.874 35.691 22.607 37.019 20.874C37.955 19.687 38.022 18.674 38.022 17.367V8.96696H35.569V17.367C35.569 18.527 35.284 19.314 34.701 19.78C34.145 20.207 33.318 20.407 32.058 20.407C30.512 20.407 29.469 20.127 28.859 19.527C28.249 18.914 28.194 18.247 28.194 17.367V8.96696H25.714V17.367C25.714 18.5 25.782 19.527 26.514 20.594ZM49.45 17.674L52.242 22.607H55.062L52.12 17.5C53.937 16.94 55.062 15.354 55.062 13.34C55.062 10.767 53.218 8.96696 50.588 8.96696H42.428V22.607H44.922V17.674H49.45ZM52.594 13.247C52.594 15.034 51.198 15.22 50.602 15.22H44.936V11.407H50.602C51.835 11.407 52.594 12.114 52.594 13.247ZM14.639 11.42V22.607H14.64H17.08V11.42H21.823V8.96696H9.89502V11.42H14.639Z"
      fill="currentColor"
    />
  </svg>
);
