import ContentCardBase from "./_ContentCardBase";
import { css } from "linaria";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import {
  PrimaryColorName,
  brandColorThemeVar,
} from "../../styles/colors.styles";

const contentCardBlogStyle = css`
  [data-path="/"] & {
    border-color: ${brandColorThemeVar(700)};
    &:hover {
      border-color: ${brandColorThemeVar(800)};
    }
  }
`;

const ContentCardBlog = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  return (
    <ContentCardBase
      className={contentCardBlogStyle}
      link="/blog"
      color={(overrides?.color ?? "purple") as PrimaryColorName}
      variant="50"
      large={large}
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Tines blog"}</strong>
          </p>
          <h4>{overrides?.heading || "Stay in the know"}</h4>
          <ReactMarkdown>
            {overrides?.description ||
              "Update from Tines; the smart, secure workflow builder for your whole team."}
          </ReactMarkdown>
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Go to blog"}</strong>
        </p>
      </header>
      <figure>
        <DefaultContentCardBlogIcon />
      </figure>
    </ContentCardBase>
  );
};

export default ContentCardBlog;

export const DefaultContentCardBlogIcon = () => (
  <svg
    width="102"
    height="122"
    viewBox="0 0 102 122"
    fill="none"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M46.8095 121.158C67.3019 121.158 83.9144 119.515 83.9144 117.488C83.9144 115.461 67.3019 113.818 46.8095 113.818C26.317 113.818 9.70459 115.461 9.70459 117.488C9.70459 119.515 26.317 121.158 46.8095 121.158Z"
      fill="#32313B"
    />
    <path
      d="M42.0434 100.108C44.6385 100.108 46.7422 98.5796 46.7422 96.6937C46.7422 94.8079 44.6385 93.2791 42.0434 93.2791C39.4484 93.2791 37.3447 94.8079 37.3447 96.6937C37.3447 98.5796 39.4484 100.108 42.0434 100.108Z"
      fill="#8578E6"
      stroke="#32313B"
    />
    <path
      d="M92.4262 66.9541L18.2318 68.1231L1.10645 101.178C1.10645 101.178 1.44572 102.323 3.52092 103.252C4.812 103.829 7.31087 103.445 7.31087 103.445L38.5191 98.3671H45.4846L86.0213 104.475C86.0213 104.475 87.8266 104.804 89.1781 104.293C90.5297 103.782 90.7708 102.592 90.7708 102.592L96.5549 72.3995L92.4262 66.9541Z"
      fill="#8578E6"
      stroke="#32313B"
    />
    <path
      d="M45.4846 95.4161H38.2826L1.10645 101.178L18.2318 68.123L93.448 66.1397L94.9744 66.0996C94.9744 66.0996 96.5777 65.8948 97.4466 67.005C97.6588 67.2764 97.8543 67.6932 97.7523 68.4321C97.661 69.0941 97.5251 69.8241 97.5251 69.8241L90.6781 102.61L45.4846 95.4161Z"
      fill="#8578E6"
      stroke="#32313B"
    />
    <path
      d="M45.179 94.961L86.9051 97.9511C86.9051 97.9511 64.4788 90.4756 54.0137 90.4756C43.5479 90.4756 45.179 94.961 45.179 94.961Z"
      fill="#32313B"
    />
    <path
      d="M2.97852 97.713L40.0832 94.6551C40.0832 94.6551 34.5787 89.0483 25.9141 90.4755C17.2496 91.9026 2.97852 97.713 2.97852 97.713Z"
      fill="#32313B"
    />
    <path
      d="M92.8997 61.219L95.3433 66.8578L88.7645 98.4832L81.2458 96.5562L77.4868 73.4368L92.8997 61.219Z"
      fill="#FAE8E1"
      stroke="#32313B"
    />
    <path
      d="M57.4616 56.3683C57.4616 56.3683 65.166 52.3431 71.5567 55.7264C77.9475 59.1098 84.0651 61.4061 92.8996 61.2183L85.945 92.7963L59.2912 86.6467L41.3975 92.4204L57.4616 56.3683Z"
      fill="#FAE8E1"
      stroke="#32313B"
    />
    <path
      d="M57.46 56.3687C57.46 56.3687 51.3385 51.9138 43.8061 53.3787C33.5405 55.3748 33.0799 59.6074 21.0977 58.9596L3.78516 90.8447C3.78516 90.8447 9.97123 94.0184 18.2737 89.7254C30.8294 83.2329 35.7617 84.2199 42.2388 91.0208L57.46 56.3687Z"
      fill="#FAE8E1"
      stroke="#32313B"
    />
    <path
      d="M86.3346 91.9918C78.1257 91.5633 67.2055 88.0507 61.2847 85.9364C55.3639 83.8213 48.456 83.9624 43.6629 89.1785C43.0146 89.8842 42.4807 90.5676 42.036 91.2244C41.5916 90.5676 41.0575 89.884 40.4092 89.1785C35.6162 83.9626 28.7086 83.8213 22.7876 85.9364C16.8668 88.0505 11.9944 90.4153 3.7855 90.8441L1.35693 97.335H2.9615C11.6049 96.6477 16.0458 94.5246 21.9386 92.5149C27.8927 90.4839 34.7989 90.5366 39.7822 95.1644H44.4032C49.3867 90.5366 56.2927 90.4839 62.2469 92.5149C68.1398 94.5246 78.7603 97.7958 87.1781 98.4828H88.7632L86.3346 91.9918Z"
      fill="#FAE8E1"
      stroke="#32313B"
    />
    <path
      d="M2.91016 93.5963C10.9319 93.0839 14.9339 92.6489 21.9399 89.7459C27.8783 87.2852 33.8525 86.1186 40.3905 92.3496C41.0307 92.9597 41.6285 93.4651 42.0941 93.9927C42.5594 93.465 43.1186 92.9159 43.7974 92.3496C48.8159 88.161 54.2663 86.5388 62.2482 89.7459C67.5611 91.8806 75.916 94.4735 87.1522 95.0499"
      stroke="#32313B"
    />
    <path d="M38.3838 95.5037V98.0181" stroke="#32313B" />
    <path d="M45.5186 95.5037V98.0181" stroke="#32313B" />
    <path d="M87.4097 94.8143L94.1871 64.5417" stroke="#32313B" />
    <path d="M42.1637 94.9466L41.9292 90.7256" stroke="#32313B" />
    <g opacity="0.42">
      <path
        opacity="0.16"
        d="M40.9592 58.3182C46.4899 57.2425 50.9847 60.5137 50.9847 60.5137L46.8108 70.0158C42.8214 65.6792 37.0719 65.5616 32.1436 67.3202C27.9925 68.8008 24.6633 70.4117 19.5693 71.1015L24.2853 62.416C33.0833 62.8917 33.4215 59.7839 40.9592 58.3182Z"
        fill="#32313B"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M41.1706 83.3328C37.0393 78.8418 31.0851 78.7201 25.9813 80.5413C21.6825 82.0746 18.0249 83.761 12.7495 84.4754"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M43.1702 78.8868C39.0388 74.3958 33.0846 74.2741 27.9808 76.0952C23.682 77.6285 20.0244 79.3149 14.749 80.0293"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M45.3489 74.3243C41.2175 69.8333 35.2633 69.7116 30.1596 71.5327C25.8607 73.066 22.2031 74.7524 16.9277 75.4668"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M87.4097 65.9183C81.5883 65.2088 76.9072 63.2706 72.0695 60.7096C68.4282 58.7818 64.3603 59.2592 61.5342 60.0269"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M86.2378 69.4935C80.4164 68.784 75.7353 66.8458 70.8976 64.2848C67.2563 62.357 63.1884 62.8344 60.3623 63.6021"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M84.5606 73.2928C78.7391 72.5834 74.058 70.6452 69.2204 68.0841C65.579 66.1563 61.5112 66.6338 58.6851 67.4014"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M83.1807 76.9937C77.3593 76.2843 72.6782 74.3461 67.8405 71.785C64.1992 69.8572 60.1313 70.3347 57.3052 71.1023"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M82.1192 81.1712C76.2977 80.4618 71.6166 78.5236 66.779 75.9625C63.1376 74.0347 59.0698 74.5122 56.2437 75.2798"
        stroke="#32313B"
      />
      <path
        opacity="0.16"
        d="M80.5996 85.5296C74.7782 84.8202 70.0971 82.882 65.2595 80.3209C61.6181 78.3931 57.5502 78.8706 54.7241 79.6382"
        stroke="#32313B"
      />
    </g>
    <path
      d="M52.4823 74.7699L51.5923 73.7L50.5224 74.59L51.4124 75.6599L52.4823 74.7699Z"
      fill="white"
    />
    <path
      opacity="0.49"
      d="M40.4604 36.2637L39.896 35.5852L39.2175 36.1496L39.7819 36.8281L40.4604 36.2637Z"
      fill="white"
    />
    <path
      d="M20.5248 32.0061L19.9604 31.3276L19.282 31.892L19.8464 32.5705L20.5248 32.0061Z"
      fill="white"
    />
    <path
      d="M60.4287 26.814L59.8643 26.1355L59.1858 26.6999L59.7502 27.3784L60.4287 26.814Z"
      fill="white"
    />
    <path
      opacity="0.49"
      d="M40.0205 6.13403L39.4561 5.45557L38.7776 6.01997L39.342 6.69843L40.0205 6.13403Z"
      fill="white"
    />
    <path
      opacity="0.49"
      d="M99.6713 24.6008L99.1069 23.9224L98.4285 24.4868L98.9929 25.1652L99.6713 24.6008Z"
      fill="white"
    />
    <path
      d="M70.2452 66.6641L69.5 65.7683L68.6042 66.5135L69.3494 67.4093L70.2452 66.6641Z"
      fill="white"
    />
    <path
      opacity="0.49"
      d="M74.101 63.197L73.5366 62.5186L72.8582 63.083L73.4226 63.7614L74.101 63.197Z"
      fill="white"
    />
    <path
      d="M78.5873 5.80052L78.0229 5.12207L77.3445 5.68647L77.9089 6.36492L78.5873 5.80052Z"
      fill="white"
    />
    <path
      d="M33.7058 22.6772L36.7548 20.8942L40.8021 18.53C40.9839 18.4244 41.189 18.3652 41.3991 18.3579C41.6093 18.3505 41.818 18.395 42.0068 18.4876L45.6043 20.279C45.7579 20.3541 45.8945 20.4598 46.0058 20.5895L51.6005 27.4616L40.1252 34.993L36.6794 37.255L36.3959 36.9048L31.1371 30.4131C30.9927 30.2393 30.8971 30.0302 30.86 29.8072C30.823 29.5843 30.8459 29.3555 30.9263 29.1443L33.0568 23.6023L33.1648 23.3285C33.2655 23.056 33.4564 22.8262 33.7058 22.6772V22.6772Z"
      fill="#AFA6F9"
      stroke="#32313A"
    />
    <path
      d="M30.924 29.1465L33.0545 23.6044C32.9983 23.8571 32.1109 27.976 31.8406 29.0694C31.8155 29.1703 31.8139 29.2756 31.836 29.3772C31.858 29.4787 31.9031 29.5739 31.9678 29.6553L36.7039 35.4804L36.4301 36.7009L36.3877 36.9006L31.1288 30.409C30.9865 30.2352 30.8927 30.0269 30.8567 29.8053C30.8207 29.5836 30.844 29.3563 30.924 29.1465Z"
      fill="#D4CFFC"
    />
    <path
      d="M33.7058 22.6772L36.7548 20.8942L40.8021 18.53C40.9839 18.4244 41.189 18.3652 41.3991 18.3579C41.6093 18.3505 41.818 18.395 42.0068 18.4876L45.6043 20.279C45.7579 20.3541 45.8945 20.4598 46.0058 20.5895L51.6005 27.4616L40.1252 34.993L36.6794 37.255L36.3959 36.9048L31.1371 30.4131C30.9927 30.2393 30.8971 30.0302 30.86 29.8072C30.823 29.5843 30.8459 29.3555 30.9263 29.1443L33.0568 23.6023L33.1648 23.3285C33.2655 23.056 33.4564 22.8262 33.7058 22.6772V22.6772Z"
      stroke="#32313A"
    />
    <path
      d="M39.2819 29.5593L46.3782 25.412C46.5595 25.3049 46.7648 25.2449 46.9752 25.2375C47.1856 25.2301 47.3945 25.2755 47.5829 25.3696L51.1849 27.1489C51.3372 27.2241 51.4724 27.3298 51.5823 27.4593C51.7323 27.6321 51.8325 27.8424 51.8721 28.0678C51.9117 28.2932 51.8893 28.5251 51.8071 28.7387L49.5702 34.5689C49.4697 34.8395 49.2768 35.0658 49.0255 35.2078L41.9312 39.3572C41.7062 39.4888 41.4462 39.5481 41.1863 39.5271C41.026 39.516 40.8696 39.4726 40.7265 39.3995L37.1245 37.6203C36.9466 37.5344 36.792 37.407 36.6735 37.249C36.5415 37.0771 36.4556 36.8744 36.4238 36.66C36.3921 36.4457 36.4157 36.2267 36.4923 36.024L36.7042 35.4805L38.7312 30.1959C38.7715 30.0891 38.8267 29.9884 38.8953 29.897C38.9989 29.7584 39.1306 29.6433 39.2819 29.5593V29.5593Z"
      fill="#8578E6"
      stroke="#32313A"
    />
    <path d="M39.2783 29.561L33.7044 22.6772" stroke="#32313A" />
    <path d="M42.0055 18.4878L47.5815 25.3696" stroke="#32313A" />
    <path
      d="M25.3086 48.6468C30.0603 46.509 36.827 46.5705 41.2498 48.9501C43.7198 50.2818 44.9871 52.0795 45.0394 53.9089L45.3731 65.1256L24.9519 60.7199L24.6065 49.4758C24.6149 49.166 24.8461 48.8548 25.3086 48.6468Z"
      fill="#FFD8C8"
    />
    <path
      opacity="0.85"
      d="M40.6023 69.5417C41.3622 69.9382 42.6069 69.8578 43.2096 69.345C46.5824 66.4658 46.0121 62.5767 41.588 60.1941C37.164 57.8115 30.3981 57.7604 25.6469 59.8908C24.7981 60.2744 24.7241 61.0048 25.484 61.4013L40.6023 69.5417Z"
      fill="#FFC947"
    />
    <path
      d="M45.3731 65.1256L45.0394 53.9089C44.9871 52.0795 43.7198 50.2818 41.2498 48.9501C36.827 46.5705 30.0603 46.509 25.3086 48.6468C24.8461 48.8548 24.6149 49.166 24.6065 49.4758L24.9519 60.7199"
      stroke="#32313A"
    />
    <path
      opacity="0.85"
      d="M40.6023 69.5417C41.3622 69.9382 42.6069 69.8578 43.2096 69.345C46.5824 66.4658 46.0121 62.5767 41.588 60.1941C37.164 57.8115 30.3981 57.7604 25.6469 59.8908C24.7981 60.2744 24.7241 61.0048 25.484 61.4013L40.6023 69.5417Z"
      fill="#F69267"
      stroke="#32313A"
    />
    <path
      opacity="0.85"
      d="M40.6023 69.5417C41.3622 69.9382 42.6069 69.8578 43.2096 69.345C46.5824 66.4658 46.0121 62.5767 41.588 60.1941C37.164 57.8115 30.3981 57.7604 25.6469 59.8908C24.7981 60.2744 24.7241 61.0048 25.484 61.4013L40.6023 69.5417Z"
      stroke="#32313A"
    />
    <path
      d="M60.8624 52.1777L66.7304 58.1142C66.7944 58.1933 66.8799 58.2523 66.9767 58.284C67.0735 58.3156 67.1774 58.3186 67.2758 58.2925L76.1409 55.9747L71.9796 39.8155L63.0704 42.1441L62.9998 42.1642C62.8951 42.221 62.8045 42.3007 62.7349 42.3973C62.6652 42.494 62.6183 42.6051 62.5976 42.7224L60.4884 50.4053C60.4064 50.7093 60.3976 51.0283 60.4626 51.3364C60.5276 51.6445 60.6646 51.9328 60.8624 52.1777Z"
      fill="#F48EBA"
      stroke="#32313A"
    />
    <path
      d="M60.5902 51.4675C60.5657 51.1735 60.4223 50.6255 60.4761 50.4145L62.5852 42.7317C62.606 42.6143 62.6529 42.5032 62.7226 42.4066C62.7922 42.3099 62.8828 42.2303 62.9875 42.1735L63.0581 42.1534L71.9673 39.8248L73.9568 47.5211L60.5902 51.4675Z"
      fill="#FFCCE2"
      stroke="#32313A"
    />
    <path
      d="M69.4093 48.0786L71.5161 40.3947C71.6797 39.8008 72.108 39.6253 72.4902 40.0056L78.3582 45.942C78.5558 46.1858 78.6935 46.4725 78.7604 46.7791C78.8273 47.0857 78.8215 47.4036 78.7435 47.7076L76.6343 55.3905C76.4697 55.9868 76.0414 56.1623 75.6601 55.7796L69.7921 49.8431C69.5946 49.5995 69.4571 49.3129 69.3906 49.0064C69.3241 48.6999 69.3305 48.3821 69.4093 48.0786V48.0786Z"
      fill="#FFCCE2"
    />
    <path
      d="M72.2311 40.951L78.1819 46.9717C78.547 47.3451 78.6377 48.0067 78.4907 48.6016L78.7435 47.6852C78.8215 47.3812 78.8273 47.0633 78.7604 46.7567C78.6935 46.4501 78.5558 46.1634 78.3583 45.9196L72.4903 39.9832C72.1154 39.6059 71.6797 39.7784 71.5161 40.3723L71.2643 41.2862C71.4426 40.7408 71.865 40.5801 72.2311 40.951Z"
      fill="#FFE9F3"
    />
    <path
      d="M69.4093 48.0786L71.5161 40.3947C71.6797 39.8008 72.108 39.6253 72.4902 40.0056L78.3582 45.942C78.5558 46.1858 78.6935 46.4725 78.7604 46.7791C78.8273 47.0857 78.8215 47.4036 78.7435 47.7076L76.6343 55.3905C76.4697 55.9868 76.0414 56.1623 75.6601 55.7796L69.7921 49.8431C69.5946 49.5995 69.4571 49.3129 69.3906 49.0064C69.3241 48.6999 69.3305 48.3821 69.4093 48.0786V48.0786Z"
      stroke="#32313A"
    />
    <path
      d="M62.8145 24.052L67.0785 23.9959C67.1304 24.0004 67.1824 23.9898 67.2283 23.9654C67.2742 23.941 67.3121 23.9038 67.3374 23.8584L69.6257 19.7745L62.2042 15.5801L59.9043 19.6841L59.8867 19.7172C59.8705 19.7758 59.8677 19.8374 59.8786 19.8973C59.8895 19.9571 59.9138 20.0138 59.9496 20.063L62.0297 23.5616C62.1125 23.6994 62.2268 23.8157 62.3632 23.901C62.4996 23.9862 62.6543 24.0379 62.8145 24.052Z"
      fill="#45BE8B"
      stroke="#32313A"
    />
    <path
      d="M62.4564 23.901C62.3395 23.8058 62.087 23.6644 62.0284 23.5699L59.9483 20.0713C59.9124 20.0221 59.8882 19.9655 59.8773 19.9056C59.8664 19.8457 59.8692 19.7841 59.8854 19.7255L59.903 19.6924L62.2029 15.5885L65.7403 17.5834L62.4564 23.901Z"
      fill="#C0E9D8"
      stroke="#32313A"
    />
    <path
      d="M64.3339 19.4542L62.2525 15.9562C62.0919 15.6855 62.1791 15.4657 62.4545 15.4598L66.7185 15.4037C66.8782 15.4174 67.0325 15.4683 67.169 15.5523C67.3056 15.6364 67.4206 15.7512 67.5048 15.8876L69.5849 19.3861C69.746 19.658 69.6589 19.8778 69.3829 19.8825L65.1189 19.9386C64.9593 19.9249 64.8051 19.874 64.6687 19.7899C64.5323 19.7057 64.4177 19.5908 64.3339 19.4542V19.4542Z"
      fill="#C0E9D8"
    />
    <path
      d="M62.71 15.8899L67.0344 15.8333C67.3012 15.8312 67.5768 16.0322 67.7437 16.2971L67.496 15.8794C67.4118 15.743 67.2968 15.6282 67.1603 15.5442C67.0237 15.4601 66.8694 15.4092 66.7097 15.3955L62.4457 15.4516C62.174 15.4559 62.0831 15.6773 62.2437 15.948L62.4908 16.3645C62.3533 16.1056 62.4438 15.8932 62.71 15.8899Z"
      fill="#EDF9F4"
    />
    <path
      d="M64.3339 19.4542L62.2525 15.9562C62.0919 15.6855 62.1791 15.4657 62.4545 15.4598L66.7185 15.4037C66.8782 15.4174 67.0325 15.4683 67.169 15.5523C67.3056 15.6364 67.4206 15.7512 67.5048 15.8876L69.5849 19.3861C69.746 19.658 69.6589 19.8778 69.3829 19.8825L65.1189 19.9386C64.9593 19.9249 64.8051 19.874 64.6687 19.7899C64.5323 19.7057 64.4177 19.5908 64.3339 19.4542V19.4542Z"
      stroke="#32313A"
    />
  </svg>
);
