import { styled } from "@linaria/react";
import Button from "../components/forms/Button";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { useOnMount } from "../utils/lifeCycle.utils";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import SpotIllustration from "../components/illustrations/SpotIllustration";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
} from "../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../styles/colors.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { TextInputStyled } from "../components/forms/TextInput";
import { useAllSitePages } from "../utils/useAllSitePages";
import { useState } from "react";
import { navigate } from "gatsby";
import RedirectHandler from "../components/utilities/RedirectHandler";
import LoadingIndicator from "../components/utilities/LoadingIndicator";
import { makeSlug } from "../utils/string.utils";
import { isTinesComBuild } from "../environment";
import GlobalSearch, {
  GlobalSearchResultsContainer,
} from "../components/search/GlobalSearch";
import { Serif } from "../components/typography/Serif";
import { Helmet } from "react-helmet";
import WiderPageSection from "../components/layout/WiderPageSection";

const Page404Content = styled.div``;

const Page404ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 72vh;
  position: relative;
  padding: 5em ${rSize("cardSectionPadding")};
  background-color: ${colors.purple50};
  border-radius: ${rSize("radius")};
  position: relative;
  overflow: hidden;
  ${fromDesktopMd} {
    padding-top: 7em;
    padding-bottom: 7em;
  }
  h1 {
    font-size: 3.2rem;
    ${fromTablet} {
      font-size: 4.2rem;
    }
  }
  h3 {
    font-size: inherit;
  }
  p {
    font-weight: 500;
  }
  img {
    margin-top: -4%;
  }
  ${TextInputStyled} {
    background-color: ${colors.purple100};
    border-color: ${colors.purple200};
  }
`;

const ContentWrap = styled.div`
  width: 100%;
  position: relative;
  ${fromDesktop} {
    width: 620px;
    margin-left: auto;
    margin-right: auto;
  }
  ${GlobalSearchResultsContainer} {
    padding-left: 0;
    padding-right: 0;
    h3 {
      font-size: 1.3rem;
    }
  }
`;

const HeightStretcher = styled.div`
  padding: ${rSize("sectionPadding")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-weight: 500;
  min-height: 90vh;
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const Page404: PageComponent = props => {
  const title = "Not found";
  const { pathname } = props.location;
  const allPages = useAllSitePages();
  const [ready, setReady] = useState(false);
  const [roughMatchFound, setRoughMatchFound] = useState("");
  const searchFormState = useState({ query: "" });
  useOnMount(() => {
    if (window.location.pathname !== "/404") {
      searchFormState[1]({
        query: window.location.pathname
          .replace(/[\/|-]/g, " ")
          .replace(/%20/g, " ")
          .trim(),
      });
    }
    if (isTinesComBuild) {
      setReady(true);
      return;
    }
    const slug = makeSlug(
      /([^/]+)$/.exec(decodeURIComponent(pathname).replace(/\/$/, ""))?.[1] ??
        ""
    );
    if (slug && slug !== "404") {
      const currentPageRegex = new RegExp(`${slug}$`);
      const firstMatch = allPages.find(path => currentPageRegex.test(path));
      if (firstMatch) {
        setRoughMatchFound(firstMatch);
        navigate(firstMatch);
        return;
      } else {
        setReady(true);
      }
    } else {
      setReady(true);
    }
  });
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO title={title} />
      <Helmet>
        <html data-404 />
      </Helmet>
      {ready ? (
        roughMatchFound ? (
          <HeightStretcher>
            <RedirectHandler to={roughMatchFound} delay={0} />
          </HeightStretcher>
        ) : (
          <Page404Content>
            <SiteNavHeightPlaceholder withSpacingTop />
            <WiderPageSection>
              <Page404ContentInner>
                <ContentWrap>
                  <SpotIllustration name="ufo" />
                  <h1>
                    <Serif>Page not found</Serif>
                  </h1>
                  <Spacing size="1em" />
                  <p>We couldn’t find what you were looking for.</p>
                  <Spacing size="2em" />
                  <GlobalSearch
                    formState={searchFormState}
                    inline
                    perPage={5}
                    autoFocus
                    doNotKeepQueryInUrl
                    showSuggestionsWhenEmpty
                  />
                  <Spacing size="2em" />
                  <Button to="/" darker>
                    Go home
                  </Button>
                </ContentWrap>
              </Page404ContentInner>
            </WiderPageSection>
            <Spacing size="xl" />
          </Page404Content>
        )
      ) : (
        <HeightStretcher>
          <LoadingIndicator />
        </HeightStretcher>
      )}
    </Page>
  );
};

export default Page404;
