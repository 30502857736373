import { styled } from "@linaria/react";
import { KeyboardEventHandler, useState } from "react";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { DatoCmsWebinar } from "../../../graphql-types";
import { reportErrorSilently } from "../../utils/error.utils";
import Button from "../forms/Button";
import InfoBox from "../general/InfoBox";
import SpotIllustration from "../illustrations/SpotIllustration";
import TextInput, { TextInputSet } from "../forms/TextInput";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { webinarRegistrationWebhook } from "../../constants/webhooks.constants";
import SpaceChildren from "../layout/SpaceChildren";
import PoweredByTines from "../utilities/PoweredByTines";
import PrivacyNotice from "../utilities/PrivacyNotice";
import {
  brandColorThemeVar,
  colors,
  getStandardColorThemeName,
  withOpacity,
} from "../../styles/colors.styles";
import { getFormMetaInfo } from "../../utils/form.utils";

type Props = {
  webinar: DatoCmsWebinar;
  showPoweredByTinesTag?: boolean;
};

const WebinarRegistrationFormContainer = styled.form`
  h3 {
    text-align: center;
  }
  ${fromTabletMd} {
    background-color: ${brandColorThemeVar(100)};
    [data-dark-mode="true"] & {
      background-color: ${withOpacity(colors.white, 0.05)};
    }
    padding: 2em;
    border-radius: 1em;
    grid-gap: 1em;
    grid-template-columns: [start] 1fr 1fr [end];
  }
  input {
    background-color: ${withOpacity(colors.white, 0.3)};
    border-color: ${brandColorThemeVar(200)};
    [data-dark-mode="true"] & {
      background-color: transparent;
      border-color: ${brandColorThemeVar(700)};
    }
    &:hover,
    &:focus {
      z-index: 1;
      background-color: transparent;
      border-color: ${brandColorThemeVar(300)};
      [data-dark-mode="true"] & {
        border-color: ${brandColorThemeVar(500)};
      }
    }
  }
`;

const WebinarRegistrationFormSuccess = styled.div`
  border-radius: 1em;
  padding: 1.5em 2em 2em;
  background-color: ${brandColorThemeVar(500)};
  [data-dark-mode="true"] & {
    background-color: ${brandColorThemeVar(600)};
  }
  color: ${colors.white};
  text-align: center;
  font-weight: 500;
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
`;

const makeDefaultForm = () => ({
  firstName: "",
  lastName: "",
  email: "",
  title: "",
  company: "",
  phone: "",
  country: "",
});

const WebinarRegistrationForm = (props: Props) => {
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState<string | null>(null);
  const shouldDisableForm = awaitingResponse || success;
  const formState = useState(makeDefaultForm());
  const [form] = formState;

  const reset = () => {
    formState[1](makeDefaultForm());
    setSuccess(false);
    setHasError(null);
  };

  const validateForm = async () => {
    if (!form.email.includes("@")) return "Please enter a valid email.";
    if (!(await emailIsLegitimate(form.email)).valid)
      return "Please use a valid business or personal email to continue.";
    if (!form.firstName || !form.lastName)
      return "Please enter your preferred name before continuing.";
    if (!form.company)
      return "Please enter your company name before continuing.";
    return true;
  };

  const handleFormKeyup: KeyboardEventHandler<HTMLFormElement> = e => {
    if (e.key === "Enter") {
      submit();
    }
  };

  const submit = async () => {
    setValidationMessage("");
    setHasError(null);
    setAwaitingResponse(true);
    const validationResult = await validateForm();
    if (typeof validationResult === "string") {
      setValidationMessage(validationResult);
      setAwaitingResponse(false);
      return;
    }
    const payload = {
      ...formState[0],
      webinar: {
        id: props.webinar.id,
        slug: props.webinar.slug,
        title: props.webinar.title,
        url: window.location.href,
      },
      ...getFormMetaInfo(),
    };
    fetch(webinarRegistrationWebhook, {
      method: "post",
      body: JSON.stringify(payload),
    })
      .then(() => {
        setSuccess(true);
      })
      .catch(e => {
        reportErrorSilently(e);
        setHasError("Registration failed.");
      })
      .finally(() => {
        setAwaitingResponse(false);
      });
  };

  return success ? (
    <WebinarRegistrationFormSuccess>
      <SpotIllustration name="springHand" />
      <h2>Thanks for registering!</h2>
      <p>
        See you at the {props.webinar.category?.singularLowercase ?? "webinar"}!
      </p>
      <Button
        onClick={reset}
        appearance="filled-white"
        name="resetWebinarRegFormState"
        color={getStandardColorThemeName(props.webinar.colorTheme)}
      >
        Register another attendee
      </Button>
    </WebinarRegistrationFormSuccess>
  ) : (
    <>
      <WebinarRegistrationFormContainer
        className="WebinarRegistrationForm"
        onKeyUp={handleFormKeyup}
      >
        <SpaceChildren>
          <h3>Register now</h3>
          <TextInputSet>
            <TextInput
              formState={formState}
              name="firstName"
              placeholder="First name"
              disabled={shouldDisableForm}
              backgroundColor={colors.white}
            />
            <TextInput
              formState={formState}
              name="lastName"
              placeholder="Last name"
              disabled={shouldDisableForm}
              backgroundColor={colors.white}
            />
            <TextInput
              formState={formState}
              name="email"
              type="email"
              placeholder="Work email"
              disabled={shouldDisableForm}
              backgroundColor={colors.white}
            />
            <TextInput
              formState={formState}
              name="company"
              placeholder="Company"
              disabled={shouldDisableForm}
              backgroundColor={colors.white}
            />
          </TextInputSet>
          <Button
            name="submit"
            type="button"
            onClick={submit}
            padding="1em"
            fullWidth
            disabled={shouldDisableForm}
            loading={awaitingResponse}
          >
            {hasError ? "Retry" : "Register"}
          </Button>
          {props.showPoweredByTinesTag && <PoweredByTines centered />}
          {hasError && <InfoBox>An error occurred. Please try again.</InfoBox>}
          {validationMessage && (
            <InfoBox dataName="webinar-registration-validation-message">
              {validationMessage}
            </InfoBox>
          )}
        </SpaceChildren>
        <PrivacyNotice centered smaller />
      </WebinarRegistrationFormContainer>
    </>
  );
};

export default WebinarRegistrationForm;
