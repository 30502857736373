import { styled } from "@linaria/react";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

export const AIPageHeading2 = styled.h2`
  font-family: ${serif};
  font-weight: 400;
  ${fromTablet} {
    font-size: 3.2rem;
  }
  ${fromTabletLg} {
    font-size: 3.8rem;
  }
  line-height: 1.1;
  letter-spacing: -0.02rem;
`;

export const AIPageCardTag = styled.div`
  position: absolute;
  top: 0;
  left: ${rSize("lg")};
  padding: 0.5em 1em 0.6em;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 0 0 0.66em 0.66em;
  z-index: 1;
`;
