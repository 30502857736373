const LogoGitLab = () => {
  return (
    <svg width="89" height="20" viewBox="0 0 89 20" fill="currentColor">
      <title>GitLab</title>
      <path d="M19.9202 7.79326L19.8937 7.72263L17.2199 0.747846C17.1657 0.610895 17.0694 0.494718 16.9449 0.416134C16.852 0.356648 16.7464 0.31976 16.6367 0.308425C16.5269 0.29709 16.416 0.311623 16.3129 0.35086C16.2098 0.390097 16.1173 0.452946 16.0428 0.53437C15.9684 0.615794 15.9141 0.713528 15.8842 0.819738L14.0793 6.34533H6.77029L4.96542 0.819738C4.93532 0.713687 4.88085 0.616137 4.80635 0.534869C4.73186 0.453601 4.63941 0.390863 4.53638 0.351658C4.43334 0.312453 4.32257 0.297866 4.2129 0.30906C4.10323 0.320255 3.99769 0.35692 3.9047 0.416134C3.78016 0.494718 3.68387 0.610895 3.62974 0.747846L0.957129 7.72389L0.929381 7.79326C0.544602 8.79887 0.497199 9.90232 0.794317 10.9372C1.09143 11.9721 1.71697 12.8824 2.57659 13.5307L2.58668 13.5383L2.60938 13.556L6.67696 16.6044L8.69498 18.1293L9.92092 19.0563C10.0648 19.165 10.2401 19.2238 10.4204 19.2238C10.6007 19.2238 10.776 19.165 10.9198 19.0563L12.1458 18.1293L14.1638 16.6044L18.2604 13.5383L18.2717 13.5295C19.1313 12.8814 19.7569 11.9714 20.0543 10.9368C20.3516 9.90211 20.3046 8.79884 19.9202 7.79326Z" />
      <path d="M35.931 7.43253H38.8042C38.3249 4.37775 35.68 2.25 32.238 2.25C28.1654 2.25 25.1094 5.24424 25.1094 10.259C25.1094 15.1842 28.0279 18.239 32.3213 18.239C36.1744 18.239 38.9328 15.7606 38.9328 11.7486V9.87938H32.6177V12.0828H36.2047C36.1593 14.3026 34.6773 15.7077 32.3364 15.7077C29.7306 15.7077 27.9447 13.7552 27.9447 10.2287C27.9447 6.72496 29.7609 4.78009 32.2759 4.78009C34.1526 4.78009 35.4366 5.7828 35.931 7.43253Z" />
      <path d="M40.8331 18.023H43.5839V6.35635H40.8331V18.023ZM42.2205 4.5023C43.0946 4.5023 43.8084 3.83257 43.8084 3.01274C43.8084 2.19292 43.0946 1.51562 42.2205 1.51562C41.3464 1.51562 40.625 2.18409 40.625 3.01274C40.625 3.84139 41.3338 4.5023 42.2155 4.5023H42.2205Z" />
      <path d="M51.8193 6.35721H49.5162V3.55469H46.7654V6.35721H45.1094V8.48496H46.7654V14.9754C46.7503 17.1713 48.347 18.2547 50.413 18.1904C50.9633 18.1794 51.5091 18.0872 52.0324 17.9167L51.5683 15.765C51.3054 15.8332 51.0352 15.8692 50.7636 15.8722C50.0712 15.8722 49.5162 15.6288 49.5162 14.5188V8.47866H51.8193V6.35721Z" />
      <path d="M53.9531 18.0327H63.627V15.6691H56.7733V2.46875H53.9531V18.0327Z" />
      <path d="M68.8908 18.2633C70.7221 18.2633 71.8156 17.4044 72.3176 16.4244H72.4084V18.0275H75.0571V10.2152C75.0571 7.13015 72.5421 6.20312 70.3147 6.20312C67.8603 6.20312 65.976 7.29664 65.3681 9.42439L67.9373 9.79016C68.2097 8.99178 68.9854 8.30817 70.3337 8.30817C71.6113 8.30817 72.3101 8.96151 72.3101 10.1093V10.1547C72.3101 10.9442 71.4814 10.9833 69.4218 11.2028C67.1578 11.4462 64.9922 12.1222 64.9922 14.752C64.9846 17.0475 66.6634 18.2633 68.8908 18.2633ZM69.7951 16.2453C68.6474 16.2453 67.8263 15.7206 67.8263 14.7103C67.8263 13.6534 68.7457 13.2132 69.9704 13.0379C70.6931 12.9395 72.1373 12.7566 72.4929 12.4691V13.8439C72.5005 15.1392 71.4511 16.2415 69.7951 16.2415V16.2453Z" />
      <path d="M77.0781 18.0327H79.7835V16.1938H79.9424C80.3763 17.0452 81.2807 18.2383 83.2873 18.2383C86.0381 18.2383 88.0978 16.0563 88.0978 12.212C88.0978 8.32101 85.9776 6.20839 83.2798 6.20839C81.2201 6.20839 80.3612 7.44695 79.9424 8.29074H79.8264V2.46875H77.0781V18.0327ZM79.776 12.1969C79.776 9.9266 80.7484 8.46479 82.5192 8.46479C84.3506 8.46479 85.294 10.0225 85.294 12.1969C85.294 14.3713 84.3367 15.9807 82.5192 15.9807C80.7635 15.9807 79.776 14.4608 79.776 12.1969Z" />
    </svg>
  );
};

export default LogoGitLab;
