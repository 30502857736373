import phone from "../images/tines-federal-layered-product-visual-phone.png";
import tablet from "../images/tines-federal-layered-product-visual-tablet.png";
import desktop from "../images/tines-federal-layered-product-visual-desktop.png";
import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromPhoneLg,
  uptoDesktop,
  uptoPhoneLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";

const PhoneImgWrap = styled.div`
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  width: 375px;
  max-width: 100%;
  img {
    display: block;
    max-width: calc(100% + ${rSize("pageMargin")} + ${rSize("pageMargin")});
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
    aspect-ratio: 375 / 380;
    user-select: none;
    ${fromPhoneLg} {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromPhoneLg} {
    display: none;
  }
`;

const TabletImgWrap = styled.div`
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  width: 768px;
  max-width: 100%;
  img {
    display: block;
    max-width: 100%;
    aspect-ratio: 768 / 497;
    user-select: none;
  }
  ${uptoPhoneLg} {
    display: none;
  }
  ${fromDesktop} {
    display: none;
  }
`;

const DesktopImg = styled.img`
  display: block;
  width: 100%;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 1310 / 623;
  user-select: none;
  ${uptoDesktop} {
    display: none;
  }
`;

export const FedLayeredProductVisual = () => (
  <>
    <PhoneImgWrap>
      <img src={phone} />
    </PhoneImgWrap>
    <TabletImgWrap>
      <img src={tablet} />
    </TabletImgWrap>
    <DesktopImg src={desktop} />
  </>
);
