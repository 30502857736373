import { css } from "@linaria/core";
import PullQuote from "../components/blocks/PullQuote.block";
import GartnerBadge from "../components/utilities/GartnerBadge";
import PageSection from "../components/reusableSections/PageSection";
import SimpleCTASection from "../components/reusableSections/SimpleCTASection";
import StandardPageHeader from "../components/reusableSections/StandardPageHeader";
import SEO from "../components/utilities/SEO";
import { SmallPrintBlack12 } from "../components/typography/SmallPrint";
import Spacing from "../components/layout/Spacing";
import StandardArticle from "../components/articles/StandardArticle";
import {
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { ArticleLikePageContent } from "../components/articles/ArticleLikePageContent";
import { widthInGrid } from "../constants/globalGrid.constants";

const PageGartnerCoolVendor: PageComponent = props => {
  return (
    <Page {...props}>
      <SEO
        title="Tines is a 2021 Gartner Cool Vendor in Security Operations"
        image="/images/og/tines-gartner-cool-vendor-2021.png"
      />
      <StandardPageHeader
        pageTitle="Tines is a 2021 Gartner Cool Vendor in Security Operations"
        largerPadding
        centered
      />
      <Spacing size="sectionPadding" />
      <PageSection>
        <ArticleLikePageContent>
          <StandardArticle>
            <GartnerBadge
              width={185}
              className={css`
                ${onlyPhones} {
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: 1em;
                }
                ${fromTablet} {
                  float: right;
                  margin-left: 2em;
                }
              `}
            />

            <p>
              We’re proud to announce that we have been recognized as a Cool
              Vendor in the June 2021 Gartner "Cool Vendors in Security
              Operations" report. This is a powerful step forward in our work
              toward building secure and reliable automation for the enterprise.
            </p>

            <h3>What makes Tines a Gartner Cool Vendor?</h3>
            <p>As Gartner analyst Pete Shoard writes in the report:</p>
            <PullQuote>
              <p>
                Tines is cool, because it focuses on simplicity in automation —
                democratizing security orchestration and automation through the
                implementation of automation building blocks.
              </p>
            </PullQuote>

            <Spacing size="md" />

            <p>
              Despite this simplicity and accessibility, Tines is immensely
              powerful. That’s why leading companies like{" "}
              <a href="https://www.tines.com/blog/how-sophos-is-using-tines-to-keep-its-employees-and-data-around-the-world-safe">
                Sophos trust Tines to help safeguard their employees and data
              </a>
              .
            </p>

            <SmallPrintBlack12
              className={css`
                ${fromTabletMd} {
                  margin-right: ${widthInGrid(1)};
                }
              `}
            >
              <br />
              <p>
                <em>Gartner, Cool Vendors in Security Operations</em>, Toby
                Bussa, Mitchell Schneider, Kelly Kavanagh, John Collins, Craig
                Lawson, Pete Shoard, 18 June 2021.
              </p>

              <p>
                The <strong>GARTNER COOL VENDOR badge</strong> is a trademark
                and service mark of Gartner, Inc. and/or its affiliates and is
                used herein with permission. All rights reserved. Gartner does
                not endorse any vendor, product or service depicted in its
                research publications and does not advise technology users to
                select only those vendors with the highest ratings or other
                designation. Gartner research publications consist of the
                opinions of the Gartner Research & Advisory organization and
                should not be construed as statements of fact. Gartner disclaims
                all warranties, expressed or implied, with respect to this
                research, including any warranties of merchantability or fitness
                for a particular purpose.
              </p>
            </SmallPrintBlack12>
          </StandardArticle>
        </ArticleLikePageContent>
      </PageSection>

      <Spacing size="sectionPadding" />

      <SimpleCTASection brandColorName="pink" />

      <Spacing size="sectionPadding" />
    </Page>
  );
};
export default PageGartnerCoolVendor;
