import { useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";

const DisablePageScroll = () => {
  const [ready, setReady] = useState(false);
  useOnMount(() => {
    setReady(true);
  });
  return ready ? (
    <style
      children={`:root { 
        overflow: hidden;
        margin-right: var(--ScrollbarWidth);
      }`}
    />
  ) : null;
};

export default DisablePageScroll;
