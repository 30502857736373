import { styled } from "@linaria/react";
import { first, last } from "lodash-es";
import FormulasPill from "../general/FormulasPill";

type Props = {
  string: string;
  appearance?: "json" | "nocode";
};

const FormattedStringWrap = styled.span``;
const Whitespace = styled.span`
  display: inline-block;
`;
const StringSnippet = styled.span``;

/**
 * accepts a string input,
 * maintains all spaces as written (white-space: pre),
 * and optionally render formulas as pills
 */
const FormattedString = (props: Props) => {
  const isNoCode = props.appearance === "nocode";
  const tokens = [];
  let buffer = "";
  for (const char of JSON.stringify(props.string)) {
    if (/^ +$/.test(buffer) && char !== " ") {
      tokens.push(buffer);
      buffer = char;
      continue;
    } else if (
      buffer.length &&
      char === " " &&
      !(isNoCode && /(^<<)|(^<%)/.test(buffer))
    ) {
      tokens.push(buffer);
      tokens.push(" ");
      buffer = "";
      continue;
    }

    buffer += char;

    if (isNoCode) {
      if (/<<$/.test(buffer)) {
        tokens.push(buffer.replace(/<<$/, ""));
        buffer = "<<";
      } else if (/<%$/.test(buffer)) {
        tokens.push(buffer.replace(/<%$/, ""));
        buffer = "<%";
      } else if (/^<<.+>>$/.test(buffer)) {
        tokens.push(buffer);
        buffer = "";
      }
    }
  }
  tokens.push(buffer);

  if (isNoCode) {
    if (typeof first(tokens) === "string")
      tokens[0] = tokens[0].replace(/^"/, "");
    if (typeof last(tokens) === "string")
      tokens[tokens.length - 1] = last(tokens)!.replace(/"$/, "");
  }

  return (
    <FormattedStringWrap>
      {tokens.map((token, i) => {
        if (/^ +$/.test(token)) {
          return (
            <Whitespace key={i} style={{ width: `${token.length * 0.6}em` }}>
              {token}
            </Whitespace>
          );
        }
        if (isNoCode) {
          if (/^<<.+>>$/.test(token)) {
            return (
              <FormulasPill
                type="value"
                f={token.replace(/(^<<|>>$)/g, "")}
                key={i}
              />
            );
          }
          if (/^<%.+%>$/.test(token)) {
            return (
              <FormulasPill
                type="tag"
                f={token.replace(/(^<%|%>$)/g, "")}
                key={i}
              />
            );
          }
        }
        return <StringSnippet key={i}>{token}</StringSnippet>;
      })}
    </FormattedStringWrap>
  );
};

export default FormattedString;
