import { colors, withOpacity } from "../../../styles/colors.styles";
import ReportV2Section from "../components/ReportV2Section";
import Spacing from "../../layout/Spacing";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import { AIPortalIcon } from "./icons/AIPortalIcon";
import { styled } from "@linaria/react";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../../styles/fonts.styles";
import { ReportV2HalfDarkSectionNavControlPoint } from "../components/ReportV2CommonNavControlPoints";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { css } from "linaria";
import { Link } from "@reach/router";
import { Paths } from "../../../utils/pathBuilders.utils";
import { OrigamiIcon } from "./icons/OrigamiIcon";

const ChapterSubheading = styled.p`
  ${fromTablet} {
    font-size: 2rem !important;
    font-family: ${serif};
  }
`;

const AIBannerWrap = css`
  background-color: ${withOpacity(colors.purple800, 0.8)};
  padding: 2em ${rSize("lg")};
  border: 1px solid ${colors.purple700};
  &:hover {
    background-color: ${colors.purple800};
    border-color: ${colors.purple600};
  }
  border-radius: ${rSize("radius")};
  font-family: ${serif};
  text-decoration: none;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: ${rSize("lg")};
  h3 {
    font-weight: inherit;
    font-size: 1.8rem;
    ${fromTablet} {
      font-size: 2.4rem;
    }
    span {
      display: block;
    }
  }
  svg {
    display: block;
  }
`;

export const CisoAISummarySection = () => {
  return (
    <section>
      <ReportV2ChapterHeaderSection
        id="summary"
        color="purple"
        icon={<OrigamiIcon />}
      >
        <h2>
          <span>Summary</span>
        </h2>
        <ReportV2TextBlock largerText>
          <Spacing size=".125em" />
          <ChapterSubheading>
            The survey results paint a nuanced picture of AI adoption in
            cybersecurity. While there is significant satisfaction with the
            progress made so far, many concerns and challenges still need to be
            addressed.
          </ChapterSubheading>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2HalfDarkSectionNavControlPoint />

      <ReportV2Section
        color={colors.white}
        backgroundColor={colors.purple800}
        accentColor={colors.purple400}
      >
        <ReportV2TextBlock largerText limitWidth columned>
          <p>
            Feedback from respondents highlights a spectrum of sentiments,
            ranging from anticipation to skepticism. For instance, some
            respondents are “looking forward to adoption” and remain “excited
            about AI usage to augment security operations, if clear value can be
            shown.” These comments suggest a strong belief in AI’s potential to
            enhance security operations. On the other hand, insights such as the
            “lack of enterprise assigned owner/decision-makers” indicate the
            importance of cross-functional coordination, visibility between
            stakeholders, and shared responsibility for implementing AI
            effectively.
          </p>

          <p>
            <a
              href="https://www.forbes.com/sites/sherzododilov/2024/02/14/leaders-what-are-you-doing-about-ai-fatigue/"
              {...externalLinkAttr}
            >
              AI fatigue
            </a>{" "}
            is evident in comments like “I’m tired of it, already,” reflecting a
            growing weariness among some leaders. These insights reveal the
            complexity of AI adoption in cybersecurity, emphasizing the optimism
            for its potential and the necessity for incremental implementation.
          </p>

          <p>
            Despite these revelations and challenges, the excitement around the
            potential benefits of AI—such as faster decision-making—is clear.
            Ensuring that these benefits are realized requires a strategic
            approach that includes flexible, intuitive AI-enhanced workflow
            solutions, clear AI policies, continuous skill development, and
            alignment of organizational priorities.
          </p>
        </ReportV2TextBlock>
      </ReportV2Section>

      <ReportV2Section
        color={colors.white}
        backgroundColor={colors.purple900}
        accentColor={colors.purple400}
        isLastSection
      >
        <ReportV2SectionHeader>
          <ReportV2SectionHeadingMedium>
            <span>How Tines can help</span>
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <Spacing size="lg" />

        <ReportV2TextBlock largerText limitWidth columned>
          <p>
            Despite the advancements in AI, its practical relevance to
            businesses is limited. While LLMs can describe complex topics well,
            they can’t yet provide real-time information or integrate with
            real-world business workflows.
          </p>
          <p>
            This is where Tines comes into play.{" "}
            <a href="/blog/introducing-ai-in-tines" target="_blank">
              AI in Tines
            </a>{" "}
            is secure and private by design, enabling your security team to
            securely integrate and control LLMs and AI in your workflows. Tines
            automates and orchestrates powerful workflows, letting you choose
            when to call an AI—whether for real-time data analysis,
            vulnerability assessments, or incident response.
          </p>
          <p>
            There’s no public networking, training, storage, or query/output
            logging. With Tines, your security team can build, run, and monitor
            repetitive workflows so they can focus on more impactful work,
            detecting and responding to incidents faster and more accurately.
          </p>
        </ReportV2TextBlock>

        <Spacing size="xl" />

        <Link to={Paths.ai()} className={AIBannerWrap}>
          <h3>
            <span>To learn more,</span> <span>explore AI in Tines →</span>
          </h3>
          <AIPortalIcon />
        </Link>
      </ReportV2Section>
    </section>
  );
};
