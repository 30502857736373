import { styled } from "@linaria/react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { colors } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { PropsWithChildren } from "react";

export const LibraryTableWrap = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.4rem;
    font-weight: 500;
  }
  th,
  td {
    vertical-align: top;
  }
  thead {
    th {
      border: 0;
      border-bottom: 1px solid ${withOpacity(colors.gray, 0.2)};
      text-align: left;
      color: var(--ac);
      white-space: nowrap;
      padding-top: 0.75em;
      padding-bottom: 0.75em;
      padding-right: 0.5em;
    }
  }
`;

export const LibraryTablePlainTextCell = styled.td``;

export const LibraryTableRowTr = styled.tr`
  th {
    text-align: left;
    a {
      display: block;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-top: -0.75em;
      margin-bottom: -0.75em;
    }
  }
  th,
  td {
    font-weight: inherit;
    border: 0;
    border-bottom: 1px solid ${withOpacity(colors.gray, 0.1)};
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-right: 0.5em;
    &${LibraryTablePlainTextCell} {
      padding-top: 1em;
      padding-bottom: 1em;
    }
    a {
      text-decoration: none;
      &:hover {
        color: var(--ac, ${colors.purple});
      }
    }
  }
  &:hover {
    th,
    td {
      border-bottom-color: ${withOpacity(colors.gray, 0.25)};
    }
  }
`;

export const LibraryTableStoryIconCell = styled.td`
  color: var(--ac);
  width: 2em;
  svg {
    display: block;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
`;

export const LibraryTableCellWithToolIcons = styled.td`
  img {
    display: block;
    width: auto !important;
    height: 1.8rem !important;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  > * {
    display: inline-block;
    margin-right: 0.5em;
  }
`;

export const LibraryTableAuthorCell = styled.td`
  strong {
    font-weight: inherit;
  }
`;
export const LibraryTableTagCell = styled.td`
  text-align: right;
`;

export const LibraryTableCommunityTag = styled.span`
  vertical-align: middle;
  background-color: ${withOpacity(colors.green, 0.125)};
  border: 1px solid ${withOpacity(colors.green, 0.25)};
  color: ${colors.green600};
  ${darkModeLinariaCSS(`color: ${colors.green300};`)}
  font-weight: 600;
  padding: 0.25em 0.5em;
  border-radius: 0.5em;
  font-size: 1.2rem;
`;

export const LibraryTablePartnerTag = styled.span`
  vertical-align: middle;
  background-color: ${withOpacity(colors.pink, 0.125)};
  border: 1px solid ${withOpacity(colors.pink, 0.25)};
  color: ${colors.pink600};
  ${darkModeLinariaCSS(`color: ${colors.pink300};`)}
  font-weight: 600;
  padding: 0.25em 0.5em;
  border-radius: 0.5em;
  font-size: 1.2rem;
`;

export const LibraryTableZeroWidthCell = styled.td`
  width: 0;
`;

const SortableColumnHeadingTh = styled.th`
  cursor: pointer;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0.125em;
    left: -0.5em;
    bottom: 0;
    display: block;
    background-color: var(--ac10, ${withOpacity(colors.purple, 0.1)});
    opacity: 0;
  }
  &:hover {
    filter: brightness(1.1);
    &:before {
      opacity: 0.7;
    }
  }
  &:active {
    &:before {
      opacity: 1;
    }
  }
  svg {
    margin-right: 0.5em;
  }
`;

export const LibraryTableSortableColumnHeading = (
  props: PropsWithChildren<{
    currentSort: string;
    asc: string;
    desc: string;
    onChange: (newSort: string) => void;
  }>
) => {
  return (
    <SortableColumnHeadingTh
      onClick={() =>
        props.onChange(props.currentSort === props.asc ? props.desc : props.asc)
      }
    >
      <svg width="4" height="10" viewBox="0 0 4 10" fill="currentColor">
        <path
          d="M0 4L2 0L4 4H0Z"
          fillOpacity={props.currentSort === props.asc ? 1 : 0.25}
        />
        <path
          d="M4 6L2 10L3.49691e-07 6L4 6Z"
          fillOpacity={props.currentSort === props.desc ? 1 : 0.25}
        />
      </svg>
      <span>{props.children}</span>
    </SortableColumnHeadingTh>
  );
};
