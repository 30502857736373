import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { CommonColorNameV4, colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ValidCtaBlock } from "../../utils/datocms.utils";
import Button from "../forms/Button";
import LinkToAnchor from "../basic/LinkToAnchor";
import StaticImageFrame from "../basic/StaticImageFrame";
import {
  color,
  colors,
  getStandardColorThemeName,
} from "../../styles/colors.styles";

type Props = {
  record: ValidCtaBlock;
};

const CTABlockContainer = styled.div<{ color: string }>`
  background-color: ${p => p.color};
  color: ${p => (p.color === colors.light200 ? "inherit" : colorsV4.white)};
  padding: ${rSize("lg")} ${rSize("lg")} ${rSize("xl")};
  border-radius: ${rSize("radius")};
  text-align: center;
  > * + * {
    margin-top: 1.6rem;
  }
  h3 {
    font-size: 2.4rem;
    + a,
    + button {
      margin-top: 2.4rem;
    }
  }
`;

const CTABlock = (props: Props) => {
  const colorName: CommonColorNameV4 =
    getStandardColorThemeName(props.record.themeColor) ?? "purple";
  const colorHex =
    props.record.themeColor === "Warm Black"
      ? colors.dark500
      : props.record.themeColor === "Canvas"
      ? colors.light200
      : color(colorName);
  const isRelativeLink = props.record.buttonLink?.match(/^\//);
  const isLinkToAnchor = isRelativeLink && props.record.buttonLink?.match(/\#/);
  const button = (
    <Button
      appearance={
        colorHex !== colors.dark500 && colorHex !== colors.light200
          ? "filled-white"
          : "filled"
      }
      to={
        isRelativeLink && !isLinkToAnchor
          ? props.record.buttonLink ?? ""
          : undefined
      }
      color={
        props.record.themeColor === "Warm Black" ||
        props.record.themeColor === "Canvas"
          ? "purple"
          : colorName
      }
      href={isRelativeLink ? undefined : props.record.buttonLink ?? ""}
      {...(isRelativeLink ? {} : externalLinkAttr)}
    >
      {props.record.buttonLabel ?? props.record.heading}
    </Button>
  );
  return (
    <CTABlockContainer className="CTABlock" color={colorHex}>
      <StaticImageFrame
        src={props.record.spotIllustration?.url}
        className={cx(
          css`
            max-width: 150px;
            margin-left: auto;
            margin-right: auto;
          `
        )}
      />
      <h3>{props.record.heading}</h3>
      {isLinkToAnchor ? (
        <LinkToAnchor
          to={props.record.buttonLink ?? ""}
          className={css`
            display: inline-block;
            text-decoration: none;
          `}
        >
          {button}
        </LinkToAnchor>
      ) : (
        button
      )}
    </CTABlockContainer>
  );
};

export default CTABlock;
