import ReportKeyFindingsSection from "../../ReportKeyFindingsSection";

const MHKeyFindingsSection = () => {
  return (
    <ReportKeyFindingsSection
      color="orange"
      openingParagraph="Our respondents provided the following insights about the state of their mental health:"
    >
      <li>
        <h4>47% rank their mental health highly.</h4>
        <p>
          26% say the state of their mental health is “excellent,” while 21% say
          it’s “very good.” The remainder rank their mental health as good
          (20%), fair (15%), or poor (17%).
        </p>
      </li>
      <li>
        <h4>27% say their mental health has declined over the past year.</h4>
        <p>
          39% report that their mental health has improved over the past twelve
          months, and 34% say it has stayed the same. However, 27% report that
          their mental health has gotten worse over the past twelve months.
        </p>
      </li>
      <li>
        <h4>57% have workplaces that provide mental health support</h4>
        <p>
          Nearly two-thirds (57%) of respondents say their workplace provides
          them with resources and support for their mental health and wellbeing.
          Yet only 54% say their workplace prioritizes mental health.
        </p>
      </li>
      <li>
        <h4>Stress levels are already high — and rising.</h4>
        <p>
          66% experience stress at work: 22% are very stressed, 23% are fairly
          stressed, and 21% are somewhat stressed. Additionally, 63% say their
          stress levels have risen over the past year.
        </p>
      </li>
      <li>
        <h4>
          Mental health is affecting productivity, and work affects mental
          health.
        </h4>
        <p>
          64% of respondents say that their mental health affects their ability
          to get their work done, and 64% say their work impacts their mental
          health.
        </p>
      </li>
      <li>
        <h4>Therapy and medication for mental health is common.</h4>
        <p>
          51% of respondents have been prescribed medication for their mental
          health, and of those, 58% are currently taking medication for their
          mental health. Additionally, 49% are currently seeing a therapist.
        </p>
      </li>
      <li className="fullWidth">
        <h4>Half are in good physical health. </h4>
        <p>
          50% reported that they’re in excellent physical health (27%) or very
          good physical health (25%). 42% are getting the recommended eight
          hours of sleep or more per night.
        </p>
      </li>
    </ReportKeyFindingsSection>
  );
};

export default MHKeyFindingsSection;
