import { styled } from "@linaria/react";
import { isStructuredText } from "datocms-structured-text-utils";
import { ValidStructuredTextBlock } from "../../utils/datocms.utils";
import { css, cx } from "linaria";
import { fromDesktop } from "../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import StandardArticleStructuredTextWithCustomBlocks, {
  StructuredTextWithCustomBlocksOptions,
} from "../articles/StructuredTextWithCustomBlocks";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";

type Props = {
  record: ValidStructuredTextBlock;
} & StructuredTextWithCustomBlocksOptions & {
    centered?: boolean;
  };

export const StructuredTextBlockContainer = styled.div`
  &.withBackdrop {
    position: relative;
    padding: ${rSize("lg")};
    &:not(:first-child) {
      margin-top: ${rSize("lg")};
    }
    &:not(:last-child) {
      margin-bottom: ${rSize("lg")};
    }
  }
  &[data-appearance="Large and bold sans-serif"] {
    p {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 1.5;
      ${fromDesktop} {
        font-size: 2.2rem;
        letter-spacing: -0.01em;
      }
      a {
        font-weight: 700;
        font-weight: 600;
      }
    }
  }
  &[data-appearance="Large serif"] {
    p {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 1.5;
      font-family: ${font("serif")};
      ${fromDesktop} {
        font-size: 2.2rem;
        letter-spacing: -0.01em;
      }
      a {
        font-weight: inherit;
      }
    }
  }
`;

export const StructuredTextBlockBackdrop = styled.div`
  border-radius: ${rSize("radius")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const contentStyle = css`
  position: relative;
`;

const StructuredTextBlock = ({
  record,
  centered,
  lazyLoadImages,
  linkedHeadingMaxLevel,
}: Props) => {
  const colorTheme = getBrandColorTheme(record.backdropColor);
  const startsWithHeading =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    record.structuredText.value?.document?.children[0]?.type === "heading";
  return isStructuredText(record.structuredText) ? (
    <StructuredTextBlockContainer
      className={cx(
        "StructuredTextBlock",
        record.backdropColor && "withBackdrop",
        startsWithHeading && "startsWithHeading"
      )}
      data-appearance={record.appearance}
      style={{
        color: record.backdropColor ? colorTheme.c800 : undefined,
      }}
    >
      {record.backdropColor && (
        <StructuredTextBlockBackdrop
          style={{
            backgroundColor: record.backdropColor
              ? record.backdropColor === "Canvas"
                ? colors.light100
                : colorTheme.c100
              : undefined,
          }}
        />
      )}
      <StandardArticleStructuredTextWithCustomBlocks
        className={contentStyle}
        value={record.structuredText}
        centered={centered}
        lazyLoadImages={lazyLoadImages}
        linkedHeadingMaxLevel={linkedHeadingMaxLevel}
      />
    </StructuredTextBlockContainer>
  ) : null;
};

export default StructuredTextBlock;
