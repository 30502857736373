export const CisoAIHeroIllustrationLeft = () => (
  <svg
    width="600"
    height="708"
    viewBox="0 0 600 708"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M210.105 431.654C215.563 432.42 219.483 437.31 218.929 442.785C217.48 457.059 225.94 468.341 226.026 468.448C229.525 472.912 228.678 479.305 224.153 482.775C219.635 486.237 213.112 485.4 209.614 480.941C209.054 480.236 196.034 463.347 198.326 440.773C198.898 435.156 203.97 431.045 209.657 431.601C209.806 431.617 209.955 431.635 210.105 431.654Z"
      fill="#EE86B7"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M159.279 457.546C160.587 459.191 161.454 461.225 161.686 463.483C162.322 469.757 157.801 475.399 151.586 476.084L150.818 476.168C143.355 476.979 129.461 478.494 122.424 489.099C118.561 494.919 110.529 506.118 100.116 515.756C95.4949 520.034 88.339 519.741 84.1378 515.099C79.9644 510.491 80.2718 503.229 84.895 498.953C93.8743 490.64 101.009 480.455 103.661 476.459C116.627 456.921 138.994 454.485 148.544 453.448L149.28 453.368C153.256 452.927 156.961 454.624 159.279 457.546Z"
      fill="#8D75E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M502.309 97.9071C504.31 96.3372 504.66 93.4421 503.09 91.4407C501.52 89.4392 498.625 89.0894 496.624 90.6594C494.622 92.2293 494.273 95.1244 495.843 97.1258C497.413 99.1273 500.308 99.477 502.309 97.9071Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M512.601 424.313L504.261 417.321L506.147 406.605L516.37 402.878L524.71 409.87L522.824 420.585L512.601 424.313Z"
      fill="#FEEDE0"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M61.876 221.184C62.5278 214.917 58.1878 209.187 51.9703 208.251C35.7573 205.814 25.5845 193.242 25.4893 193.117C21.505 187.974 14.19 187.102 9.10272 191.134C4.02505 195.161 3.15983 202.628 7.13769 207.768C7.76496 208.589 22.8975 227.803 48.5356 231.656C54.9165 232.613 60.8648 228.155 61.812 221.694C61.8355 221.525 61.8566 221.354 61.876 221.184Z"
      fill="#EE86B7"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M176.056 499.977C177.576 499.896 179.139 500.22 180.583 500.996C184.743 503.231 186.329 508.374 184.128 512.483C181.281 517.801 185.947 526.828 190.061 534.794C191.448 537.482 192.759 540.02 193.844 542.51C199.879 556.378 198.974 570.245 191.356 580.556C188.584 584.31 183.257 585.077 179.457 582.281C175.655 579.48 174.821 574.175 177.59 570.425C181.558 565.057 181.788 557.284 178.225 549.098C177.367 547.132 176.182 544.834 174.926 542.404C169.498 531.893 162.059 517.496 169.073 504.397C170.507 501.713 173.204 500.132 176.056 499.977Z"
      fill="#F0A848"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M238.681 241.962C241.657 250.309 245.058 259.599 248.793 269.56C249.104 269.274 249.401 268.973 249.687 268.651C277.931 236.544 265.922 201.926 259.474 183.33C249.429 154.36 275.834 143.531 276.962 143.085C283.181 140.625 286.231 133.52 283.772 127.219C281.318 120.918 274.281 117.802 268.058 120.259C248.233 128.105 223.5 153.514 236.622 191.359C242.594 208.587 247.076 225.481 238.681 241.962Z"
      fill="#A990F5"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M228.429 397.942L231.615 402.638L228.758 405.884C223.5 411.528 209.53 426.527 190.024 426.235C168.472 425.912 163.823 436.65 162.296 440.181C155.326 456.273 141.377 465.125 124.033 464.464C118.512 464.255 114.152 459.612 114.295 454.095C114.433 448.58 119.025 444.278 124.548 444.488C133.803 444.841 140.139 440.772 143.92 432.052C149.117 420.04 161.347 405.815 190.077 406.244C200.779 406.408 210.067 396.426 214.037 392.172C215.97 390.122 219.371 386.017 219.371 386.017C219.371 386.017 222.152 389.853 223.9 391.98C225.142 393.49 228.429 397.942 228.429 397.942Z"
      fill="#F67269"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M219.769 111.076C221.441 116.537 223.335 122.729 222.062 129.573C221.01 135.238 215.505 142.872 210.064 149.055C206.243 153.397 199.308 152.194 196.2 147.243V147.243C193.976 143.699 194.568 139.479 197.259 136.333C200.337 132.736 203.716 128.417 204.158 126.04C204.601 123.656 203.518 120.108 202.367 116.356C199.723 107.716 195.299 93.2539 210.222 80.5458C214.865 76.5944 214.953 73.1941 214.616 70.98C213.913 66.3726 210.197 62.5319 208.841 61.9346C204.06 60.3131 201.454 55.0998 203.021 50.2961C204.586 45.4857 209.751 42.9018 214.511 44.5274C222.743 47.3179 230.439 57.049 232.412 67.1542C234.426 77.4628 230.743 87.2228 222.042 94.6266C216.51 99.3389 217.092 102.331 219.769 111.076Z"
      fill="#FD975D"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M191.867 245.872C186.13 247.452 180.086 244.403 178.104 238.763C176.103 233.065 170.852 225.054 157.253 219.237C139.106 211.472 131.469 192.24 128.53 181.468C126.886 175.439 130.496 169.192 136.589 167.512C142.685 165.831 148.961 169.353 150.6 175.385C152.11 180.914 156.676 194.234 166.463 198.415C187.592 207.465 196.199 221.204 199.694 231.143C201.771 237.035 198.618 243.511 192.655 245.624C192.394 245.715 192.131 245.797 191.867 245.872Z"
      fill="#E269A4"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M92.6599 275.728C86.0124 274.838 79.7681 278.818 79.665 285.6V285.6C79.5913 290.449 81.4854 295.247 86.0813 296.616C88.3239 297.284 90.4788 297.787 91.7049 297.952C100.978 299.213 111 298.592 119.21 296.253C129.995 293.173 144.05 298.633 147.675 301.637C152.167 305.36 158.801 304.689 162.49 300.134C166.178 295.583 165.524 288.865 161.029 285.139C152.394 277.985 131.082 270.732 113.523 275.738C108.863 277.067 100.359 276.759 92.6599 275.728Z"
      fill="#FA975F"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M296.131 342.935C304.26 336.815 305.953 325.186 299.913 316.961C293.873 308.736 282.386 307.03 274.258 313.15C266.129 319.269 264.436 330.898 270.476 339.123C276.516 347.348 288.003 349.054 296.131 342.935Z"
      fill="#FA975F"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M308.166 393.78C318.988 385.633 321.242 370.151 313.2 359.201C305.159 348.251 289.866 345.979 279.044 354.127C268.222 362.274 265.968 377.756 274.01 388.706C282.052 399.656 297.344 401.928 308.166 393.78Z"
      fill="#8D75E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M232.453 448.739C227.831 447.794 234.714 393.839 247.839 328.227C260.969 262.618 275.37 210.199 279.995 211.15L301.589 216.035C306.22 216.981 300.141 282.192 287.012 347.806C273.884 413.41 258.936 455.394 254.305 454.447L232.453 448.739Z"
      fill="#FBBD74"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M279.994 211.148C284.625 212.095 277.735 266.053 264.611 331.665C251.482 397.276 237.088 449.689 232.453 448.737C227.831 447.791 234.714 393.837 247.839 328.225C260.968 262.616 275.369 210.197 279.994 211.148Z"
      fill="#E49307"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M259.042 330.503C269.351 278.963 276.002 236.832 273.897 236.401C271.792 235.969 261.728 277.401 251.418 328.941C241.109 380.48 234.459 422.612 236.564 423.043C238.669 423.475 248.733 382.043 259.042 330.503Z"
      fill="#32313B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M99.5161 505.364C100.508 506.825 101.124 508.571 101.22 510.47C101.497 515.941 97.3422 520.6 91.9424 520.879C84.9549 521.239 78.7917 531.285 73.3516 540.147C71.5146 543.136 69.7809 545.96 67.9599 548.531C57.8122 562.838 43.5014 570.254 28.6948 568.871C23.3041 568.369 19.3477 563.539 19.8438 558.09C20.3431 552.634 25.1102 548.626 30.494 549.127C38.204 549.848 46.0594 545.417 52.0487 536.97C53.4862 534.939 55.0568 532.385 56.7153 529.68C63.8948 517.988 73.726 501.968 90.9386 501.08C94.4634 500.895 97.6513 502.625 99.5161 505.364Z"
      fill="#F0A848"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M91.0632 495.691C91.6088 501.764 87.3314 507.248 81.3026 508.067C65.5816 510.199 55.5674 522.222 55.4736 522.342C51.5484 527.263 44.4585 528.005 39.5907 524.033C34.7322 520.065 33.9965 512.828 37.9154 507.91C38.5335 507.124 53.4371 488.744 78.2971 485.372C84.4844 484.535 90.1795 488.931 91.0082 495.196C91.0287 495.36 91.0468 495.525 91.0632 495.691Z"
      fill="#EE86B7"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M134.359 421.7C134.896 423.387 134.992 425.239 134.547 427.086C133.264 432.408 127.964 435.666 122.712 434.367C115.915 432.685 107.167 440.522 99.4464 447.434C96.8402 449.766 94.38 451.968 91.9076 453.903C78.1357 464.667 62.326 467.62 48.5317 462C43.509 459.956 41.0857 454.181 43.1035 449.104C45.126 444.022 50.8279 441.564 55.8441 443.606C63.0266 446.533 71.807 444.566 79.9359 438.211C81.8881 436.682 84.1159 434.691 86.4702 432.581C96.6584 423.461 110.612 410.965 127.354 415.107C130.783 415.952 133.348 418.534 134.359 421.7Z"
      fill="#F0A848"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M166.666 508.51C161.741 506.54 155.947 508.234 152.848 512.87L152.18 513.865C147.115 521.356 133.655 541.261 138.745 563.388C144.37 587.837 133.427 595.994 129.828 598.674C113.43 610.902 106.878 629.142 111.856 648.711C113.438 654.94 119.726 658.666 125.896 657.031C132.066 655.403 135.784 649.028 134.199 642.797C131.543 632.354 134.573 624.056 143.458 617.426C155.7 608.305 168.714 590.588 161.218 557.996C158.421 545.856 167.381 532.62 171.196 526.966L171.94 525.866C175.515 520.513 174.139 513.266 168.864 509.674C168.161 509.196 167.423 508.808 166.666 508.51Z"
      fill="#FD975D"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M120.538 364.856C119.809 368.49 117.021 371.531 113.203 372.348C98.6834 375.456 86.9246 368.658 77.4783 363.192C75.4157 362.001 73.4662 360.873 71.5872 359.893C66.0713 357.026 53.1162 359.066 47.6849 363.659C43.6652 367.059 37.685 366.517 34.3252 362.453C30.9661 358.383 31.503 352.329 35.5218 348.929C45.6714 340.337 66.9599 335.898 80.2604 342.819C82.5137 343.99 84.7391 345.278 86.8838 346.519C94.8996 351.155 101.819 355.156 109.272 353.557C114.394 352.458 119.429 355.773 120.513 360.959C120.79 362.287 120.785 363.604 120.538 364.856Z"
      fill="#5EBC89"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M189.074 173.473C190.8 170.193 190.554 166.075 188.106 163.033C178.797 151.465 165.383 149.327 154.605 147.613C152.253 147.237 150.029 146.882 147.953 146.458C141.864 145.205 133.096 135.452 131.776 128.463C130.8 123.289 125.817 119.94 120.646 120.975C115.473 122.015 112.072 127.053 113.048 132.226C115.507 145.294 129.231 162.164 143.918 165.179C146.405 165.691 148.945 166.094 151.391 166.484C160.536 167.94 168.43 169.198 173.206 175.139C176.488 179.221 182.486 179.824 186.598 176.482C187.65 175.626 188.476 174.6 189.074 173.473Z"
      fill="#5EBC89"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M189.822 445.763C185.356 442.86 179.335 443.385 175.401 447.331L174.553 448.177C168.139 454.54 151.095 471.449 151.863 494.187C152.711 519.311 140.39 525.173 136.339 527.099C117.875 535.889 107.941 552.52 109.088 572.723C109.451 579.153 114.92 584.049 121.299 583.655C127.677 583.268 132.553 577.736 132.188 571.303C131.576 560.523 136.144 552.966 146.149 548.199C159.932 541.645 176.12 526.797 174.99 493.306C174.565 480.831 185.908 469.588 190.742 464.784L191.684 463.849C196.223 459.294 196.258 451.903 191.759 447.338C191.16 446.73 190.509 446.205 189.822 445.763Z"
      fill="#4E8FD0"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M200.93 275.132C202.24 268.357 198.174 261.606 191.481 259.771C184.719 257.92 174.992 252.493 167.051 237.216C156.45 216.83 133.547 209.559 120.838 207.036C113.725 205.625 106.833 210.318 105.439 217.514C104.044 224.714 108.677 231.695 115.793 233.1C122.316 234.396 138.114 238.595 143.823 249.59C156.175 273.325 172.821 282.171 184.617 285.404C191.61 287.328 198.809 283.145 200.713 276.065C200.795 275.756 200.866 275.444 200.93 275.132Z"
      fill="#F67269"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M180.331 148.576C175.541 150.868 169.658 149.557 166.281 145.131L165.552 144.182C160.038 137.034 145.383 118.043 149.09 95.6107C153.188 70.8254 141.769 63.4 138.015 60.9602C120.904 49.8283 113.241 32.0441 116.995 12.1745C118.188 5.84981 124.228 1.71608 130.483 2.94308C136.737 4.16267 140.839 10.2841 139.644 16.6111C137.641 27.2138 141.176 35.3018 150.448 41.3377C163.221 49.6409 177.296 66.4771 171.836 99.5172C169.798 111.825 179.552 124.453 183.708 129.848L184.518 130.898C188.413 136.008 187.489 143.336 182.451 147.269C181.779 147.794 181.068 148.229 180.331 148.576Z"
      fill="#FAA3CA"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M229.967 385.094C228.318 390.811 222.56 394.372 216.716 393.116C210.812 391.845 201.237 392.137 189.173 400.695C173.071 412.112 152.683 408.577 141.963 405.455C135.962 403.707 132.522 397.366 134.272 391.293C136.023 385.216 142.305 381.705 148.305 383.458C153.808 385.06 167.551 388.125 176.23 381.963C194.984 368.675 211.197 368.513 221.497 370.726C227.606 372.032 231.481 378.105 230.167 384.292C230.108 384.562 230.041 384.829 229.967 385.094Z"
      fill="#E269A4"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M178.053 110.53C180.132 110.219 182.154 109.323 183.864 107.83C188.608 103.677 189.151 96.4676 185.074 91.7265L184.569 91.1417C179.666 85.458 170.54 74.8716 172.629 62.3169C173.774 55.4263 175.541 41.7587 174.502 27.6081C174.041 21.3274 168.609 16.6598 162.37 17.1883C156.175 17.7107 151.446 23.2305 151.91 29.5106C152.804 41.7149 151.074 54.0293 150.287 58.76C146.44 81.891 161.132 98.9301 167.408 106.203L167.892 106.764C170.498 109.798 174.365 111.085 178.053 110.53Z"
      fill="#8D75E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M242.469 402.925C244.291 395.035 246.249 386.209 248.281 376.7C247.902 376.764 247.525 376.845 247.147 376.949C209.649 387.425 201.841 419.983 197.644 437.471C191.106 464.715 165.491 459.797 164.405 459.573C158.417 458.346 152.577 462.266 151.366 468.329C150.151 474.389 154.024 480.302 160.013 481.534C179.105 485.446 210.61 478.347 219.152 442.757C223.041 426.557 227.911 411.359 242.469 402.925Z"
      fill="#25A871"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M190.05 331.672C197.392 335.088 205.556 338.972 214.304 343.217C214.02 343.478 213.724 343.724 213.41 343.959C182.088 367.083 151.613 353.217 135.242 345.771C109.739 334.171 97.8639 357.394 97.3718 358.388C94.6573 363.864 87.9747 366.058 82.4487 363.284C76.9228 360.515 74.6424 353.825 77.3544 348.344C86.0117 330.884 110.985 310.408 144.301 325.561C159.468 332.458 174.447 337.964 190.05 331.672Z"
      fill="#8D75E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M217.095 268.221C217.968 266.974 218.521 265.476 218.626 263.841C218.931 259.128 215.406 255.061 210.755 254.754C204.736 254.356 199.545 245.62 194.963 237.914C193.416 235.315 191.956 232.859 190.417 230.621C181.843 218.162 169.597 211.593 156.818 212.601C152.165 212.966 148.696 217.081 149.058 221.784C149.422 226.493 153.483 230.008 158.13 229.642C164.784 229.116 171.502 233.033 176.562 240.389C177.777 242.157 179.1 244.378 180.496 246.73C186.544 256.898 194.824 270.828 209.65 271.808C212.686 272.011 215.455 270.559 217.095 268.221Z"
      fill="#F0A848"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M162.738 97.472C161.42 99.1961 160.505 101.28 160.156 103.594C159.068 110.781 163.886 117.496 170.937 118.715C174.525 119.579 198.61 129.168 208.428 149.022C218.346 169.083 213.122 188.283 213.07 188.471C210.974 195.502 214.919 202.88 221.871 204.994C228.814 207.115 236.157 203.093 238.248 196.061C238.601 194.881 246.661 166.914 231.921 137.117C217.444 107.826 184.755 93.9483 175.12 92.4561C170.233 91.6995 165.566 93.775 162.738 97.472Z"
      fill="#71A7E5"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M246.601 332.69C246.147 337.302 245.672 341.998 245.173 346.771C244.805 350.318 244.433 353.819 244.06 357.276C239.992 356.955 236.233 356.293 233.038 355.541C222.462 353.063 211.839 348.234 203.879 342.292C193.427 334.477 175.956 334.571 170.802 336.326C164.415 338.502 157.488 335.023 155.334 328.552C153.181 322.086 156.62 315.071 163.012 312.893C175.288 308.714 201.362 309.685 218.384 322.397C225.625 327.805 236.809 332.071 246.601 332.69Z"
      fill="#71A7E5"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M265.379 294.651C263.768 303.908 261.882 314.226 259.767 325.306C259.391 325.063 259.026 324.802 258.669 324.517C223.391 296.036 229.725 257.704 233.124 237.114C238.42 205.037 208.883 198.461 207.623 198.198C200.683 196.743 196.22 189.867 197.658 182.84C199.092 175.815 205.886 171.296 212.83 172.746C234.956 177.385 265.364 199.498 258.446 241.402C255.298 260.477 253.648 278.936 265.379 294.651Z"
      fill="#F67269"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M260.288 322.595C258.644 331.265 256.853 340.421 254.945 349.907C221.916 342.757 191.43 311.746 190.092 310.378C184.764 304.897 184.83 296.085 190.246 290.687C195.657 285.293 204.36 285.363 209.691 290.843C217.017 298.371 239.747 318.016 260.288 322.595Z"
      fill="#25A871"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M249.38 372.001C250.978 364.565 252.624 356.693 254.286 348.519C225.712 342.858 191.166 356.984 189.647 357.61C183.59 360.126 180.693 367.135 183.179 373.271C185.663 379.403 192.585 382.332 198.645 379.817C206.972 376.362 231.565 368.586 249.38 372.001Z"
      fill="#F5A847"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M241.498 254.35C242.063 260.034 242.703 266.477 246.518 272.301C251.287 279.588 259.047 285.169 267.111 288.028C268.306 281.327 269.376 275.087 270.303 269.422C267.077 267.831 263.821 265.413 261.674 262.135C260.345 260.107 259.976 256.416 259.591 252.51C258.697 243.519 257.201 228.469 238.531 222.5C232.723 220.645 231.33 217.542 230.787 215.37C229.659 210.847 231.606 205.871 232.626 204.797C236.412 201.456 236.805 195.641 233.507 191.814C230.207 187.979 224.445 187.588 220.681 190.923C214.162 196.673 210.815 208.62 212.893 218.704C215.011 228.992 222.174 236.576 233.056 240.051C239.978 242.265 240.595 245.249 241.498 254.35Z"
      fill="#FD975D"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M277.019 392.082L278.462 392.877C278.462 392.877 289.279 367.227 291.789 349.566C291.789 349.566 287.499 349.622 286.823 350.313L277.019 392.082Z"
      fill="#32313B"
    />
    <path
      d="M287.779 346.43L291.509 345.022C291.509 345.022 296.899 331.556 297.75 314.849L294.073 312.188L287.779 346.43Z"
      fill="#32313B"
    />
    <path
      d="M366.277 222.105C373.424 216.43 374.674 205.963 369.067 198.728C363.461 191.492 353.122 190.228 345.975 195.903C338.827 201.579 337.578 212.045 343.184 219.28C348.791 226.516 359.13 227.78 366.277 222.105Z"
      fill="#FAA3CA"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M361.51 253.412C363.298 257.123 361.772 261.596 358.105 263.405C354.44 265.209 350.023 263.667 348.237 259.956C346.451 256.246 347.975 251.772 351.638 249.964C355.306 248.154 359.725 249.698 361.51 253.412Z"
      fill="#FA9589"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M368.427 515.492C369.091 515.914 370.26 516.091 371.02 515.886L374.175 515.036C374.935 514.831 375.217 515.218 374.798 515.891L373.066 518.692C372.649 519.366 372.472 520.55 372.677 521.321L373.514 524.511C373.716 525.28 373.336 525.563 372.668 525.142L369.904 523.39C369.237 522.967 368.069 522.787 367.311 522.994L364.154 523.842C363.393 524.049 363.112 523.663 363.532 522.99L365.264 520.188C365.68 519.512 365.855 518.329 365.653 517.561L364.814 514.366C364.614 513.599 364.993 513.315 365.658 513.737L368.427 515.492Z"
      fill="#FFD29F"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M337.927 455.7C345.547 449.649 346.879 438.491 340.902 430.777C334.925 423.063 323.902 421.715 316.282 427.766C308.662 433.817 307.33 444.975 313.307 452.689C319.284 460.403 330.307 461.751 337.927 455.7Z"
      fill="#E269A4"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M387.7 366.642C393.39 362.124 394.384 353.793 389.921 348.033C385.458 342.273 377.228 341.267 371.538 345.784C365.849 350.302 364.854 358.634 369.317 364.394C373.78 370.153 382.01 371.16 387.7 366.642Z"
      fill="#5EBC89"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M488.14 339.014C496.489 332.384 497.949 320.157 491.399 311.705C484.85 303.253 472.772 301.776 464.423 308.406C456.073 315.035 454.614 327.262 461.163 335.714C467.713 344.167 479.79 345.644 488.14 339.014Z"
      fill="#FFC8A3"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M583.033 217.275C584.139 217.445 585.761 217.007 586.639 216.307L590.279 213.392C591.155 212.688 591.739 213.03 591.568 214.151L590.874 218.795C590.705 219.913 591.137 221.557 591.833 222.446L594.708 226.13C595.4 227.016 595.064 227.605 593.958 227.437L589.375 226.733C588.267 226.561 586.644 226.997 585.767 227.699L582.126 230.611C581.25 231.318 580.668 230.974 580.839 229.855L581.532 225.208C581.699 224.089 581.266 222.447 580.576 221.561L577.695 217.874C577.002 216.987 577.339 216.4 578.445 216.569L583.033 217.275Z"
      fill="#FFD29F"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M526.358 560.499C528.275 558.977 528.609 556.17 527.106 554.23C525.603 552.29 522.83 551.951 520.914 553.473C518.997 554.995 518.662 557.801 520.165 559.742C521.669 561.682 524.441 562.021 526.358 560.499Z"
      fill="#88D0A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M85.1134 222.229C83.8427 225.442 84.0229 228.886 85.9297 231.731C88.8579 236.099 93.9931 242.82 101.06 248.532C112.635 257.888 125.737 266.696 146.564 262.483C169.576 257.827 176.681 268.347 179.015 271.806C189.666 287.571 206.354 294.219 224.794 290.044C230.664 288.718 234.413 282.894 233.166 277.041C231.926 271.188 226.158 267.519 220.286 268.847C210.446 271.076 202.856 268.004 197.081 259.462C189.136 247.694 173.224 234.973 142.547 241.178C131.121 243.493 121.252 236.961 114.106 230.967C111.324 228.633 107.402 224.102 103.649 219.438C98.3476 212.849 88.2539 214.286 85.1134 222.229V222.229Z"
      fill="#4E8FD0"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M187.902 226.203C191.646 230.037 196.727 237.379 198.473 240.797L197.428 242.058C191.877 231.068 185.924 224.3 181.328 220.458C178.819 218.536 176.41 216.969 174.971 216.447C176.377 216.943 178.616 218.192 181.328 220.458C183.668 222.251 186.095 224.353 187.902 226.203Z"
      fill="#32313B"
    />
    <path
      d="M198.473 240.797C196.727 237.379 191.646 230.037 187.902 226.203C184.158 222.369 177.752 217.456 174.971 216.447C178.759 217.784 188.602 224.584 197.428 242.058L198.473 240.797Z"
      stroke="#32313B"
      strokeLinejoin="bevel"
    />
    <path
      d="M235.318 204.727C231.355 208.288 231.047 216.04 232.995 219.376C228.857 215.023 230.661 208.848 231.691 206.474L235.318 204.727Z"
      fill="#32313B"
      stroke="#32313B"
      strokeLinejoin="bevel"
    />
    <path
      d="M147.658 642.175C153.415 644.787 156.142 651.438 153.758 657.282C147.534 672.517 153.184 687.672 153.244 687.818C155.646 693.864 152.641 700.623 146.538 702.973C140.445 705.317 133.54 702.281 131.136 696.241C130.749 695.284 121.901 672.48 131.742 648.388C134.193 642.393 141.107 639.515 147.183 641.971C147.342 642.037 147.5 642.105 147.658 642.175Z"
      fill="#F67269"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M129.545 646.525C128.216 650.689 128.652 653.783 129.013 656.094C131.266 647.083 133.301 644.422 134.24 644.133L133.354 640.146C132.871 640.043 130.652 643.054 129.545 646.525Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M237.471 199.346C236.836 204.34 233.549 207.012 231.694 207.277C232.186 206.71 233.699 205.011 235.879 199.925C238.002 194.972 232.578 190.06 229.476 189.119C229.395 189.097 229.313 189.074 229.23 189.052C229.31 189.072 229.392 189.094 229.476 189.119C237.057 191.248 237.808 196.275 237.471 199.346Z"
      fill="#32313B"
    />
    <path
      d="M231.694 207.277C233.549 207.012 236.836 204.34 237.471 199.346C237.812 196.243 237.041 191.141 229.23 189.052C232.281 189.795 238.059 194.839 235.879 199.925C233.699 205.011 232.186 206.71 231.694 207.277Z"
      stroke="#32313B"
      strokeLinejoin="bevel"
    />
    <path
      d="M238.31 148.398C244.361 161.835 243.481 180.664 242.285 188.398L239.977 186.604C242.746 170.706 239.25 155.621 237.156 150.065L238.31 148.398Z"
      fill="#32313B"
      stroke="#32313B"
      strokeLinejoin="bevel"
    />
    <path
      d="M182.773 89.0475C187.645 91.2966 190.831 96.6334 190.618 97.7578L187.45 96.372C188.476 94.6728 184.269 90.5885 182.773 89.0475Z"
      fill="#32313B"
      stroke="#32313B"
      strokeLinejoin="bevel"
    />
    <path
      d="M254.871 346.055C240.363 344.226 225.131 347.297 219.521 349.094C238.081 345.304 250.514 347.362 254.217 348.832L254.871 346.055Z"
      fill="#32313B"
      stroke="#32313B"
      strokeLinejoin="bevel"
    />
    <path
      d="M131.466 92.4452C133.954 86.879 131.689 80.3029 126.253 77.5701C112.076 70.4486 106.509 55.825 106.459 55.6813C104.345 49.7523 97.8802 46.7483 91.9862 48.9249C86.1027 51.0994 83.0567 57.7056 85.166 63.63C85.4956 64.5735 93.6227 86.7978 116.041 98.0595C121.621 100.86 128.435 98.5518 131.253 92.8955C131.326 92.7469 131.397 92.5964 131.466 92.4452Z"
      fill="#F67269"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
  </svg>
);
