/* eslint-disable unused-imports/no-unused-vars */
import { createContext, useContext } from "react";
import { ColorScheme, SystemColorScheme } from "../utils/colorScheme.utils";
import {
  CookieConsentState,
  makeDefaultCookieConsentState,
} from "../components/gdpr/gdpr";
import { StoryDescriptor } from "../types/helper.types";

export const createSiteContext = () => {
  const context = {
    isClient: false,
    isFirstLoad: true,
    supportsColorSchemes: true,
    location: {
      hash: "",
      host: "",
      hostname: "",
      href: "",
      origin: "",
      pathname: "",
      port: "",
      protocol: "",
      search: "",
    },
    timezone: "",
    setTimezone: (value: string) => {},
    cookieConsentState: makeDefaultCookieConsentState(),
    setCookieConsentState: (cookieConsentState: CookieConsentState) => {},
    shouldDisplayCookieConsentManager: false,
    setShouldDisplayCookieConsentManager: (value: boolean) => {},
    gdprApplicable: false,
    setGdprApplicable: (value: boolean) => {},
    hasSidebar: false,
    sidebarOpen: false,
    toggleSidebar: (value?: boolean) => {},
    modal: null as string | null,
    openModal: (value: string | null) => {},
    colorScheme: "auto",
    setColorScheme: (colorScheme: ColorScheme) => {},
    alwaysUseColorScheme: null as null | SystemColorScheme,
    library: {
      query: "",
      setQuery: (query: string) => {},
      clearQuery: () => {},
      storyToRenderInLightbox: null as null | StoryDescriptor,
      viewStoryInLightbox: (story: StoryDescriptor) => {},
      closeStoryLightbox: () => {},
    },
    showGlobalGrid: false,
    toggleGlobalGrid: () => {},
    shouldShowGlobalSearch: false,
    setShouldShowGlobalSearch: (newValue: boolean) => {},
    toggleGlobalSearch: (newValue?: boolean) => {},
  };
  return context;
};

export type ISiteContext = ReturnType<typeof createSiteContext>;
export type SiteContextLocation = ISiteContext["location"];

export const SiteContext = createContext(createSiteContext());
export const useSiteContext = () => useContext(SiteContext);
