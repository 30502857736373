import { styled } from "@linaria/react";

const Wrap = styled.div`
  svg {
    &:first-child {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translate(33%, -12.5%);
    }
    &:last-child {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-33%, -100%);
    }
  }
`;

export const PageHeroHeadlinePortals = () => (
  <Wrap>
    <PortalLeft />
    <PortalRight />
  </Wrap>
);

const PortalLeft = () => (
  <svg width="205" height="200" viewBox="0 0 205 200" fill="none">
    <g clipPath="url(#clip0_215_1792)">
      <path
        d="M173.439 166.989C157.731 183.653 113.029 167.066 73.6041 129.9C34.1788 92.7337 14.9867 49.0877 30.6957 32.4239C46.4046 15.7602 91.1059 32.3468 130.531 69.5131C169.956 106.679 189.148 150.325 173.439 166.989Z"
        fill="#FFE0CC"
        stroke="#8D75E6"
        strokeLinejoin="round"
      />
      <mask
        id="mask0_215_1792"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="24"
        y="24"
        width="156"
        height="151"
      >
        <path
          d="M173.439 166.989C157.731 183.653 113.029 167.066 73.6041 129.9C34.1788 92.7337 14.9867 49.0877 30.6957 32.4239C46.4046 15.7602 91.1059 32.3468 130.531 69.5131C169.956 106.679 189.148 150.325 173.439 166.989Z"
          fill="#EDE9E3"
          stroke="#483C72"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_215_1792)">
        <path
          d="M101.167 122.922C104.038 123.427 105.751 127.332 105.003 131.716C104.277 136.072 101.368 139.23 98.4739 138.755L67.0779 133.391C64.2072 132.886 62.4941 128.981 63.2423 124.597C63.9674 120.241 66.8772 117.083 69.771 117.559L101.167 122.922Z"
          fill="#D7C4FA"
          stroke="#483C72"
          strokeMiterlimit="10"
        />
        <path
          d="M101.165 122.923C98.2942 122.418 95.3844 125.576 94.6362 129.961C93.911 134.316 95.6303 138.273 98.4718 138.755C101.343 139.26 104.252 136.101 104.977 131.746C105.703 127.391 103.983 123.434 101.142 122.952L101.165 122.923Z"
          fill="#C1AAF7"
          stroke="#483C72"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M173.439 166.989C157.731 183.653 113.029 167.066 73.6041 129.9C34.1788 92.7337 14.9867 49.0877 30.6957 32.4239C46.4046 15.7602 91.1059 32.3468 130.531 69.5131C169.956 106.679 189.148 150.325 173.439 166.989Z"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M117.019 83.8462C150.615 115.517 169.506 151.877 164.669 172.221C168.173 171.193 171.133 169.436 173.475 166.952C189.148 150.326 169.956 106.68 130.531 69.5138C91.1054 32.3475 46.4042 15.7609 30.6952 32.4247C28.3532 34.9089 26.8097 37.9293 25.9542 41.5261C46.0141 35.4606 83.426 52.2496 116.983 83.8844L117.019 83.8462Z"
        fill="#FFE0CC"
        stroke="#483C72"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M77.4892 43.2695L120.958 57.3698L118.498 65.83L101.519 81.1003L79.7992 74.0501L75.0293 51.7297L77.4892 43.2995L77.4892 43.2695Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M92.0089 36.4609L113.728 43.5111L118.498 65.8315L101.519 81.1018L79.7992 74.0517L75.0293 51.7312L92.0089 36.4609Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M75.0293 51.7297L77.4892 43.2695L82.2591 65.59L104.009 72.6401L120.988 57.3698L118.528 65.83L101.549 81.1003L79.8292 74.0501L75.0593 51.7297L75.0293 51.7297Z"
      fill="#88D0A3"
    />
    <path
      d="M94.4698 28L116.219 35.0501L120.989 57.3706L104.01 72.6409L82.2601 65.5907L77.4902 43.2703L94.4698 28Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M104.218 72.6387L100.318 80.6188"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M82.6209 64.9609L80.4609 74.2911"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M79.7699 74.0494L75 51.729L77.4599 43.2988"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M101.518 81.1009L79.7988 74.0508"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M120.957 57.3672L118.497 65.7973L101.518 81.0676"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M183.255 66.4681C185.055 61.1131 179.385 54.3631 170.61 51.3931C161.835 48.4231 153.194 50.3581 151.394 55.7131C150.854 57.3331 148.919 63.0931 148.334 64.7131C146.534 70.0681 152.204 76.7731 161.025 79.7881C169.845 82.7582 178.44 80.8232 180.24 75.4681C180.78 73.8481 182.715 68.0881 183.3 66.4681L183.255 66.4681Z"
      fill="#FA975F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M163.994 70.7881C172.769 73.7581 181.409 71.8231 183.209 66.4681C185.009 61.1131 179.339 54.3631 170.564 51.3931C161.789 48.4231 153.149 50.3581 151.349 55.7131C149.548 61.0681 155.219 67.8181 163.994 70.7881Z"
      fill="#F47E3F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <defs>
      <clipPath id="clip0_215_1792">
        <rect
          width="199.335"
          height="86.1411"
          fill="white"
          transform="translate(59.0889) rotate(43.3107)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PortalRight = () => (
  <svg width="172" height="152" viewBox="0 0 172 152" fill="none">
    <path
      d="M5.74663 131.283L25.7312 112.51L29.4671 116.25L32.5836 129.593L22.5957 138.971L9.48255 135.023L5.7624 131.292L5.74663 131.283Z"
      fill="#FFC8A3"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M6.36695 121.682L16.3549 112.303L29.468 116.251L32.5845 129.594L22.5966 138.972L9.48345 135.024L6.36695 121.682Z"
      fill="#FFC8A3"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.48255 135.023L5.74663 131.283L18.8597 135.231L28.8564 125.837L25.7399 112.495L29.4758 116.235L32.5923 129.577L22.6043 138.956L9.49123 135.008L9.48255 135.023Z"
      fill="#FFE0CC"
    />
    <path
      d="M2.63111 117.941L12.6277 108.547L25.7409 112.495L28.8574 125.837L18.8607 135.232L5.74761 131.284L2.63111 117.941Z"
      fill="#FFC8A3"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.916 125.727L31.9831 130.086"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.6335 134.859L22.9135 138.693"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M22.5867 138.987L9.47362 135.039L5.75346 131.308"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.5836 129.594L22.5957 138.972"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.7288 112.51L29.449 116.241L32.5655 129.584"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_215_1793)">
      <path
        d="M145.751 19.1736C158.999 28.8819 151.144 62.1639 128.175 93.5092C105.205 124.854 75.8362 142.371 62.5876 132.663C49.3389 122.955 57.1943 89.6728 80.1636 58.3275C103.133 26.9822 132.502 9.46522 145.751 19.1736Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M91.5586 66.6776C111.132 39.967 135.341 23.3088 150.249 24.9535C149.208 22.5503 147.695 20.5987 145.72 19.1513C132.502 9.46522 103.133 26.9822 80.1636 58.3275C57.1943 89.6728 49.3389 122.955 62.5876 132.663C64.5627 134.11 66.8489 134.942 69.4845 135.233C63.3947 121.492 72.0383 93.3799 91.589 66.6998L91.5586 66.6776Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M78.7928 60.965L72.8585 88.5763L45.2743 82.7021L40.5375 73.5945L46.4485 45.9642L74.056 51.8574L78.7928 60.965Z"
      fill="#A88FEF"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M67.8429 78.8591L73.7843 52.1427L46.9568 46.5742L40.8685 72.9005L67.824 78.8823L67.8429 78.8591Z"
      fill="#8D75E6"
    />
    <path
      d="M78.7928 60.965L72.8585 88.5763L45.2743 82.7021L40.5375 73.5945L46.4485 45.9642L74.056 51.8574L78.7928 60.965Z"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.5129 73.5745L68.0971 79.4488L74.0546 51.8564"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M68.1206 79.4709L72.8574 88.5785"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_215_1793">
        <rect
          width="142.966"
          height="61.7818"
          fill="white"
          transform="translate(37 115.319) rotate(-53.7667)"
        />
      </clipPath>
    </defs>
  </svg>
);
