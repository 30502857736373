import { styled } from "@linaria/react";
import { useSiteContext } from "../../context/site.context";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { zIndex } from "../../styles/zIndexes.styles";

const PreviewModeTagDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex("PreviewModeTag")};
  pointer-events: none;
  user-select: none;
  text-align: center;
  white-space: nowrap;
`;

const Tag = styled.span`
  background-color: ${colorsV4.orange};
  color: ${colorsV4.white};
  font-weight: 700;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  padding: 0.3em 0.5em;
  border-radius: 0 0 5px 5px;
  text-transform: uppercase;
  font-family: ${font("monospace")};
  letter-spacing: 0.05em;
  &:before,
  &:after {
    content: "";
    width: 5px;
    height: 5px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
  }
  &:before {
    right: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 5V0H0C2.76142 0 5 2.23858 5 5Z' fill='%23F69267'/%3E%3C/svg%3E%0A");
  }
  &:after {
    left: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 5V0H5C2.23858 0 0 2.23858 0 5Z' fill='%23F69267'/%3E%3C/svg%3E%0A");
  }
`;

const PreviewModeTag = () => {
  const siteContext = useSiteContext();
  return siteContext.isClient ? (
    <PreviewModeTagDiv>
      <Tag>{siteContext.location.hostname}</Tag>
    </PreviewModeTagDiv>
  ) : null;
};

export default PreviewModeTag;
