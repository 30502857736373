import { styled } from "@linaria/react";
import { useState } from "react";
import { useSiteContext } from "../../context/site.context";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Button from "../forms/Button";
import SpotIllustration from "../illustrations/SpotIllustration";
import Spacing from "../layout/Spacing";
import Textarea from "../forms/Textarea";
import TextInput, { TextInputSet } from "../forms/TextInput";
import Modal, { ModalBackdrop, useOpenModalFunction } from "./Modal";
import { STORY_REQUEST_MODAL_NAME } from "./modalNames";
import { css } from "linaria";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { darken } from "polished";
import PseudoLink from "../basic/PseudoLink";
import FileUploader, {
  UploadedFileMeta,
  fileUploaderBase64UploadHandler,
} from "../basic/FileUploader";
import PrivacyNotice from "../utilities/PrivacyNotice";
import InfoBox from "../general/InfoBox";
import { openIntercomMessenger } from "../../utils/intercom.utils";
import OnlyPhonesDiv from "../mediaQueries/OnlyPhones";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import InlineSelect from "../forms/InlineSelect";
import SpaceChildren from "../layout/SpaceChildren";
import { Serif } from "../typography/Serif";
import { Separator } from "../utilities/Hr";
import { AlignCenter } from "../typography/Align";
import { colors } from "../../styles/colors.styles";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import { getFormMetaInfo } from "../../utils/form.utils";

const StoryTemplateRequestModalInner = styled.div`
  background-color: ${colors.lightest};
  ${darkModeLinariaCSS(`
    background-color: ${colors.dark700};
  `)}
  padding: ${rSize("cardSectionPadding")};
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  font-size: 1.6rem;
  h2 {
    font-size: 2.4rem;
    line-height: 1.25;
    ${fromTablet} {
      font-size: 3.2rem;
    }
  }
  img {
    margin-top: -1.5em;
    align-self: center;
  }
  p {
    max-width: 33em;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const useOpenStoryRequestModalFunction = () =>
  useOpenModalFunction(STORY_REQUEST_MODAL_NAME);

const submissionUrl =
  "https://hq.tines.io/webhook/3aa29ee9ffe0f8f55d192f73be4c5bbd/ad5c573a33a66c6251192e2f4c782298";

const SuccessScreen = () => {
  const siteContext = useSiteContext();
  const closeModal = () => {
    siteContext.openModal(null);
  };
  return (
    <AlignCenter>
      <Spacing size="1em" />
      <h3>Thanks for submitting! We will be in touch.</h3>
      <Spacing size="2em" />
      <Button onClick={closeModal} width="8em">
        Close
      </Button>
    </AlignCenter>
  );
};

type RequestType = "story" | "idea";

const StoryRequestModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const formState = useState({
    name: "",
    submissionType: "story" as RequestType,
    email: "",
    query: "",
    credit: "",
  });
  const [form] = formState;
  const [attachmentFileType, setAttachmentFileType] = useState("");
  const [attachmentFileName, setAttachmentFileName] = useState("");
  const [attachmentBase64, setAttachmentBase64] = useState("");
  const { submissionType } = form;
  const handleFileUpload = (meta: UploadedFileMeta) => {
    setAttachmentFileType(meta.type);
    setAttachmentFileName(meta.fileName);
    setAttachmentBase64(meta.base64 ?? meta.url);
  };
  const formIsComplete = !!(
    (form.query || attachmentBase64) &&
    form.name &&
    form.email.includes("@")
  );
  const submit = async () => {
    if (attachmentFileType && attachmentFileType !== "application/json") {
      window.alert("Please attach a JSON file.");
      return;
    }
    try {
      setIsSubmitting(true);
      if (!(await emailIsLegitimate(form.email)).valid) {
        setIsSubmitting(false);
        window.alert(
          "Please use a valid business or personal email to continue."
        );
        return;
      }
      await fetch(submissionUrl, {
        method: "post",
        body: JSON.stringify({
          form: form,
          attachment: {
            fileName: attachmentFileName || null,
            base64: attachmentBase64 || null,
          },
          ...getFormMetaInfo(),
        }),
      });
      setSuccess(true);
    } catch (e) {
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  useOnMount(() => {
    setUrlQueryParam("modal", "request-story");
    return () => {
      removeUrlQueryParam("modal");
    };
  });

  const uploader = (
    <FileUploader
      className={css`
        width: 100%;
      `}
      fileUploadHandler={fileUploaderBase64UploadHandler}
      onFileUploaded={handleFileUpload}
      sizeLimitInMB={1}
      accepts="application/json"
      placeholder="Upload a story JSON file (Optional)"
    />
  );
  const detailsField = (
    <Textarea
      formState={formState}
      name="query"
      rows={4}
      placeholder={
        submissionType === "story"
          ? "Tell us about your story..."
          : "Tell us your story idea..."
      }
    />
  );
  const nameAndEmailFieldSet = (
    <TextInputSet
      className={css`
        width: 100%;
      `}
    >
      <TextInput
        formState={formState}
        name="name"
        placeholder="Your name"
        required
      />
      <TextInput
        formState={formState}
        name="email"
        placeholder="Email"
        type="email"
        required
      />
    </TextInputSet>
  );
  return (
    <Modal
      name={STORY_REQUEST_MODAL_NAME}
      width="670px"
      height="auto"
      maxHeight="unset"
      borderRadius={rSize("radius")}
      canEscape
      className={css`
        background-color: transparent;
        ${ModalBackdrop} {
          background-color: ${withOpacity(colorsV4.white, 0.4)};
          ${darkModeLinariaCSS(`
            background-color: ${withOpacity(
              darken(0.1, colorsV4.warmBlack900),
              0.4
            )};
          `)}
        }
        textarea,
        input {
          background-color: ${colorsV4.white};
          border-color: ${colorsV4.canvas700};
          ${darkModeLinariaCSS(`
            background-color: ${colorsV4.warmBlack900};
            border-color: ${colorsV4.warmBlack};
        `)}
        }
      `}
    >
      <StoryTemplateRequestModalInner>
        <OnlyPhonesDiv>
          <Spacing size="2em" />
        </OnlyPhonesDiv>
        <SpotIllustration name="storyboard" />
        {success ? (
          <SuccessScreen />
        ) : (
          <>
            <h2>
              <Serif>Submit a story or an idea</Serif>
            </h2>
            <Spacing size="1em" />
            <p>
              Tell us your ideas for a story, or if you would like your own
              story featured in our library, please submit a copy here. We can't
              wait to hear from you!
            </p>
            <Separator margin="1.5em 0" />
            <p
              className={css`
                font-size: 120%;
              `}
            >
              <Serif>
                <em>I would like to...</em>
              </Serif>
            </p>
            <Spacing size=".75em" />
            <SpaceChildren size=".75em">
              <InlineSelect
                formState={formState}
                name="submissionType"
                fullWidth
                options={[
                  { label: "Submit a story", value: "story" },
                  {
                    label: "Share my idea",
                    value: "idea",
                  },
                ]}
              />
              {submissionType === "idea" ? (
                <>
                  {detailsField}
                  {nameAndEmailFieldSet}
                  {uploader}
                </>
              ) : (
                <>
                  {uploader}
                  {detailsField}
                  {nameAndEmailFieldSet}
                  <div>
                    <Spacing size=".5em" />
                    <p>
                      <strong>Would you like to be credited?</strong>
                    </p>
                    <Spacing size=".5em" />
                    <p>
                      If so, please tell us the name of the author(s) and/or
                      company we should attribute the story to:
                    </p>
                    <Spacing size=".5em" />
                    <TextInput
                      formState={formState}
                      name="credit"
                      placeholder="Story author"
                    />
                  </div>
                </>
              )}
            </SpaceChildren>

            <Spacing size="1em" />

            {error && (
              <>
                <InfoBox>
                  <p>
                    An error occurred, please try again. If this keeps
                    happening, you can also submit a request{" "}
                    <PseudoLink onClick={openIntercomMessenger}>
                      by sending us a message
                    </PseudoLink>
                    .
                  </p>
                </InfoBox>
                <Spacing size="1em" />
              </>
            )}

            <Button
              loading={isSubmitting}
              fullWidth
              onClick={submit}
              disabled={!formIsComplete}
            >
              Submit
            </Button>
            <PrivacyNotice />
          </>
        )}
      </StoryTemplateRequestModalInner>
    </Modal>
  );
};

export default StoryRequestModal;
