import { css } from "@linaria/core";
import { onlyPhones } from "../breakpointsAndMediaQueries.styles";

/**
 * We almost never need to use the oblique style of the sans font.
 * They are defined here any ways, can be imported on pages where they are used.
 * */
export const sansObliqueFontFaces = css`
  :global() {
    @font-face {
      font-family: "Roobert";
      font-style: oblique;
      font-display: block;
      font-weight: 400;
      src: url("/fonts/Roobert-RegularItalic.woff2") format("woff2"),
        url("/fonts/Roobert-RegularItalic.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: oblique;
        font-display: swap;
        font-weight: 400;
        src: url("/fonts/Roobert-RegularItalic.woff2") format("woff2"),
          url("/fonts/Roobert-RegularItalic.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Roobert";
      font-style: oblique;
      font-display: block;
      font-weight: 500;
      src: url("/fonts/Roobert-MediumItalic.woff2") format("woff2"),
        url("/fonts/Roobert-MediumItalic.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: oblique;
        font-display: swap;
        font-weight: 500;
        src: url("/fonts/Roobert-MediumItalic.woff2") format("woff2"),
          url("/fonts/Roobert-MediumItalic.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Roobert";
      font-style: oblique;
      font-display: block;
      font-weight: 600;
      src: url("/fonts/Roobert-SemiBoldItalic.woff2") format("woff2"),
        url("/fonts/Roobert-SemiBoldItalic.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: oblique;
        font-display: swap;
        font-weight: 600;
        src: url("/fonts/Roobert-SemiBoldItalic.woff2") format("woff2"),
          url("/fonts/Roobert-SemiBoldItalic.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Roobert";
      font-style: oblique;
      font-display: block;
      font-weight: 700;
      src: url("/fonts/Roobert-BoldItalic.woff2") format("woff2"),
        url("/fonts/Roobert-BoldItalic.woff") format("woff");
    }
    ${onlyPhones} {
      font-family: "Roobert";
      font-style: oblique;
      font-display: swap;
      font-weight: 700;
      src: url("/fonts/Roobert-BoldItalic.woff2") format("woff2"),
        url("/fonts/Roobert-BoldItalic.woff") format("woff");
    }
  }
`;
