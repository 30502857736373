import { styled } from "@linaria/react";
import { ValidCustomHtmlBlock } from "../../utils/datocms.utils";
import DangerouslySetHtmlContent from "../utilities/DangerouslySetHtmlContent";

type Props = {
  record?: ValidCustomHtmlBlock;
};

const CustomHtmlBlockDiv = styled.div`
  position: relative;
`;

const CustomHtmlBlock = (props: Props) => {
  return (
    <CustomHtmlBlockDiv>
      <DangerouslySetHtmlContent html={props.record?.htmlContent ?? ""} />
    </CustomHtmlBlockDiv>
  );
};

export default CustomHtmlBlock;
