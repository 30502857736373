import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";

const Footnotes = styled.footer`
  font-size: 85%;
  font-weight: 600;
  opacity: 0.8;
`;

export const ReportV2Footnotes = (props: PropsWithChildren) => {
  return <Footnotes>{props.children}</Footnotes>;
};
