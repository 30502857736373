export const TVTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.63636 13H6.63636L12.3636 4H10.3636L4.63636 13ZM9.36364 4H3C2.44772 4 2 4.44772 2 5V12C2 12.5523 2.44772 13 3 13H3.63636L9.36364 4ZM15 13H9.13636L14.8636 4H15C15.5523 4 16 4.44772 16 5V12C16 12.5523 15.5523 13 15 13Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5H15V12H3V5ZM2 5C2 4.44772 2.44772 4 3 4H15C15.5523 4 16 4.44772 16 5V12C16 12.5523 15.5523 13 15 13H3C2.44772 13 2 12.5523 2 12V5ZM13 14H5V15H13V14Z"
    />
    <rect opacity="0.6" x="8" y="13" width="2" height="1" />
  </svg>
);
