import { styled } from "@linaria/react";
import { uptoTabletLg } from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { GridCanvas } from "../decorations/GridCanvas";

const Backdrop = styled.div`
  position: absolute;
  height: 680px;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${colors.purple200};
  ${uptoTabletLg} {
    display: none;
  }
`;

const DemoPageBackdrop = () => {
  return (
    <Backdrop>
      <GridCanvas color={colors.purple200} />
    </Backdrop>
  );
};

export default DemoPageBackdrop;
