import { styled } from "@linaria/react";
import { DatoCmsLibraryCollection } from "../../../../graphql-types";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../../styles/fonts.styles";
import CollectionsGrid from "../CollectionsGrid";
import Spacing from "../../layout/Spacing";
import { useState } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { LibraryCollectionType } from "../../../utils/libraryCollectionType.enum";

type Props = {
  currentCollection: DatoCmsLibraryCollection;
  collections: DatoCmsLibraryCollection[];
};

const SectionWrap = styled.section`
  h2 {
    text-align: center;
    font-family: ${font("serif")};
    font-weight: 400;
    font-size: 2.8rem;
    ${fromTabletLg} {
      font-size: 3.4rem;
    }
  }
`;

const RelatedCollectionsSection = (props: Props) => {
  const [recordsToUse, setRecordsToUse] = useState(
    [] as DatoCmsLibraryCollection[]
  );
  const isOfTypeTeams =
    props.currentCollection.collectionType === LibraryCollectionType.team;
  const isOfTypeProductFeatures =
    props.currentCollection.collectionType ===
    LibraryCollectionType.productFeature;
  useOnMount(() => {
    setRecordsToUse(
      props.collections
        .filter(c => c.id !== props.currentCollection.id)
        .sort(() => Math.random() - 0.5)
        .slice(0, isOfTypeTeams ? 5 : 4)
    );
  });
  return (
    <SectionWrap id="explore-more">
      <h2>
        Explore more{" "}
        {isOfTypeTeams
          ? "teams"
          : isOfTypeProductFeatures
          ? "product features"
          : "use cases"}
      </h2>
      <Spacing size="xl" />
      <CollectionsGrid collections={recordsToUse} />
    </SectionWrap>
  );
};

export default RelatedCollectionsSection;
