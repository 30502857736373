import { styled } from "@linaria/react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import Spacing from "../layout/Spacing";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  from12ColGrid,
  fromTablet,
  onlyTablets,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { FromTabletLg } from "../mediaQueries/UptoTabletLg";
import { color, colors } from "../../styles/colors.styles";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import { GridCanvas } from "../decorations/GridCanvas";
import { serif } from "../../styles/fonts.styles";
import { AutoCelloIcon } from "./icons/AutoCelloIcon";
import { GoldWreathIcon } from "./icons/GoldWreathIcon";

const SectionWrap = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  max-width: ${maxPageContentWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding: ${rSize("sectionPadding")} ${rSize("pageMargin")};
  h3 {
    font-size: 2.4rem;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

const Backdrop = styled.div`
  background-color: ${colors.purple};
  ${uptoTablet} {
    display: none;
  }
  ${fromTablet} {
    border-radius: ${rSize("radius")};
  }
  border: 1px solid ${colors.purple300};
  position: absolute;
  left: ${rSize("widerPageMargin")};
  right: ${rSize("widerPageMargin")};
  top: 0;
  bottom: 0;
`;

const Inner = styled.div`
  display: grid;
  position: relative;
  ${uptoTablet} {
    text-align: center;
    grid-gap: 3em;
  }
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${rSize("gap")};
  }
  ${from12ColGrid} {
    max-width: ${widthInGrid(10)};
  }
  margin-left: auto;
  margin-right: auto;
  h2 {
    font-size: 2.8rem;
    font-family: ${serif};
    font-weight: 400;
    ${FromTabletLg} {
      font-size: 3.2rem;
    }
  }
  > * {
    max-width: 35rem;
    ${uptoTablet} {
      margin-left: auto;
      margin-right: auto;
    }
    ${onlyTablets} {
      padding-right: 2em;
    }
    + * {
      max-width: ${widthInGrid(4)};
      ${from12ColGrid} {
        margin-left: ${widthInGrid(1, 1)};
      }
    }
  }
`;

const CasesTwoSplitExplainerSection = () => {
  return (
    <SectionWrap>
      <Backdrop>
        <GridCanvas color={color("purple", 450)} parallax />
      </Backdrop>
      <Inner>
        <div>
          <AutoCelloIcon />
          <Spacing size="lg" />
          <h2>Better automations, fewer cases, faster incident response</h2>
          <Spacing size="lg" />
          <p>
            Bring humans into the loop when it matters for your business while
            workflows run to capture the rest. Cases make it easy to work on
            incident response and create an audit trail of the resolution.
          </p>
        </div>
        <div>
          <GoldWreathIcon />
          <Spacing size="lg" />
          <h2>Reduce friction without compromising on your technology</h2>
          <Spacing size="lg" />
          <p>
            Automation often involves other teams – DevOps, IT, HROps – who work
            in other systems. Effectively collaborate across those systems and
            teams using cases.
          </p>
        </div>
      </Inner>
    </SectionWrap>
  );
};

export default CasesTwoSplitExplainerSection;
