import { styled } from "@linaria/react";
import { cx } from "linaria";
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";

type Props = {
  totalSlides: number;
  registerPrevFn?: (fn: () => void) => void;
  registerNextFn?: (fn: () => void) => void;
  registerScrollToFn?: (fn: (toIndex: number) => void) => void;
  registerIndex?: (index: number) => void;
  children: (scrollTo: (index: number) => void) => ReactNode;
};

const SlideshowWrapper = styled.div`
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  &.ready {
    opacity: 1;
  }
`;

const SlideshowWidthLimiter = styled.div`
  ${maxPageContentWidthStyleObject};
`;

const SlideshowInner = styled.div``;

const SlideshowTrack = styled.div`
  display: inline-flex;
  align-items: stretch;
  > * {
    width: var(--SlideshowWidth);
    + * {
      margin-left: 24px;
    }
  }
`;

export const SlideshowSlide = styled.div``;

const Slideshow = (props: Props) => {
  const [ready, setReady] = useState(false);
  const [focusedIndex, focusOnIndex] = useState(0);
  const focusedIndexRef = useRef(focusedIndex);
  focusedIndexRef.current = focusedIndex;
  const slideshowWrapperRef = useRef<HTMLDivElement>(null);
  const slideshowInnerRef = useRef<HTMLDivElement>(null);
  const slideshowTrackRef = useRef<HTMLDivElement>(null);
  const [slideshowWidth, setSlideshowWidth] = useState(768);
  const slideshowWidthRef = useRef(slideshowWidth);
  slideshowWidthRef.current = slideshowWidth;
  const slideToFocusedIndex = useCallback(() => {
    if (!slideshowWrapperRef.current) return;
    const index = focusedIndexRef.current;
    gsap.to(slideshowTrackRef.current, {
      x: (index * slideshowWidthRef.current + index * 24) * -1,
      ease: "expo.out",
    });
  }, []);
  useEffect(() => {
    slideToFocusedIndex();
    props.registerIndex?.(focusedIndexRef.current);
  });
  const prev = useCallback(() => {
    if (focusedIndexRef.current === 0) return;
    focusOnIndex(focusedIndexRef.current - 1);
  }, []);
  const next = useCallback(() => {
    if (focusedIndexRef.current === props.totalSlides - 1) return;
    focusOnIndex(focusedIndexRef.current + 1);
  }, [props.totalSlides]);
  const scrollToFn = useCallback(
    (toIndex: number) => {
      const _toIndex =
        toIndex <= 0
          ? 0
          : toIndex > props.totalSlides - 1
          ? props.totalSlides - 1
          : toIndex;
      focusOnIndex(_toIndex);
    },
    [props.totalSlides]
  );
  useEffect(() => {
    props.registerPrevFn?.(() => prev);
    props.registerNextFn?.(() => next);
    props.registerScrollToFn?.(() => scrollToFn);
  }, [props, prev, next, scrollToFn]);
  useOnMount(() => {
    const handleResize = () => {
      setSlideshowWidth(slideshowInnerRef.current?.clientWidth ?? 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    setReady(true);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <SlideshowWrapper
      ref={slideshowWrapperRef}
      className={cx(ready && "ready")}
      style={
        {
          "--SlideshowWidth": `${slideshowWidth}px`,
        } as CSSProperties
      }
    >
      <SlideshowWidthLimiter>
        <SlideshowInner ref={slideshowInnerRef}>
          <SlideshowTrack ref={slideshowTrackRef}>
            {props.children(scrollToFn)}
          </SlideshowTrack>
        </SlideshowInner>
      </SlideshowWidthLimiter>
    </SlideshowWrapper>
  );
};

export default Slideshow;
