import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { colors } from "../styles/colors.styles";
import ogImage from "../../static/images/og/tines-home.png";
import HomeHero from "../components/home/HomeHero";
import { styled } from "@linaria/react";
import {
  GridCanvas,
  GridCanvasContainer,
} from "../components/decorations/GridCanvas";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import { HomeFindYourFlowSection } from "../components/home/HomeFindYourFlowSection";
import HomeMeetTheProductSection from "../components/home/HomeMeetTheProductSection";
import HomeImpactSections from "../components/home/HomeImpactSections";
import { SocialProofSection } from "../components/reusableSections/SocialProofSection";
import { HomeEndingParagraphSection } from "../components/home/HomeEndingParagraphSection";
import HomeContentGridSection from "../components/home/HomeContentGridSection";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import {
  fromDesktopMd,
  fromTabletMd,
  uptoTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import { graphql } from "gatsby";
import { HomepageConfigurationQuery } from "../../graphql-types";

const TopGridCanvasWrapper = styled.div`
  position: relative;
  ${GridCanvasContainer} {
    bottom: 30rem;
  }
`;

const TopGridInnerGap = styled.div`
  ${uptoTabletMd} {
    height: 2em;
  }
  ${fromTabletMd} {
    max-height: 64px;
    min-height: 32px;
    height: calc(var(--vh, 100vh) * 0.15 + 5vw);
  }
`;

const BottomGridCanvasWrapper = styled.div`
  position: relative;
  ${GridCanvasContainer} {
    top: 2rem;
    bottom: -15rem;
    ${fromDesktopMd} {
      top: 30rem;
    }
  }
`;

const PageIndex: PageComponent<{
  data: HomepageConfigurationQuery;
}> = props => {
  return (
    <Page
      {...props}
      browserThemeColor={colors.purple}
      backgroundColor={colors.purple}
      textColor={colors.white}
      navThemeColor="purple"
      colorNavAsDarkTheme
      alwaysUseColorScheme="light"
      mobileNavBgColor={colors.purple}
      footerBackgroundColor={colors.purple600}
    >
      <SEO
        title=""
        seoTitle={props.data.home?.seoTitle ?? ""}
        description={props.data.home?.seoDescription ?? ""}
        image={ogImage}
      />

      <AdminToolBarWithEditInDatoCmsButton
        itemType="home"
        recordId="65171040"
        label="Edit homepage in CMS"
      />

      <TopGridCanvasWrapper>
        <GridCanvas
          color={colors.purple600}
          addBorderBottom
          parallax
          animateIn
        />

        <SiteNavHeightPlaceholder />

        <HomeHero />
        <TopGridInnerGap />
        <HomeFindYourFlowSection />
      </TopGridCanvasWrapper>

      <Spacing size="sectionMargin" multiplier={0.825} />

      <HomeMeetTheProductSection />

      <Spacing size="sectionMargin" />

      <HomeImpactSections />

      <Spacing size="sectionMargin" />

      <BottomGridCanvasWrapper>
        <GridCanvas color={colors.purple600} addBorderTop parallax animateIn />
        <SocialProofSection againstBackdrop="dark" />
        <Spacing size="sectionMargin" />
        <HomeEndingParagraphSection />
        <Spacing size="sectionMargin" />
      </BottomGridCanvasWrapper>

      <HomeContentGridSection />

      <ExplosionCTASection
        backgroundColor={colors.purple600}
        againstDarkBackground
      />
    </Page>
  );
};

export default PageIndex;

export const homepageConfigurationQuery = graphql`
  query HomepageConfiguration {
    home: datoCmsHomepage {
      seoTitle
      seoDescription
    }
  }
`;
