import { styled } from "@linaria/react";
import { cx } from "linaria";
import { colors, withOpacity } from "../../styles/colors.styles";
import { CSSProperties } from "react";

type Props = React.PropsWithChildren<{
  className?: string;
  color?: string;
  dataName?: string;
  dataTest?: string;
  accentColor?: string;
}>;

export const InfoBoxContainer = styled.div<Props>`
  display: flex;
  background-color: ${p => withOpacity(p.color ?? colors.purple, 0.1)};
  border-radius: 0.5em;
  padding: 1em;
  line-height: 1.4;
  text-align: left;
  svg {
    display: block;
    flex: 0 0 auto;
    margin-right: 0.75em;
    color: ${p => p.color ?? colors.purple};
    width: 1.25em;
    height: auto;
  }
  a {
    font-weight: 700;
    color: var(--ac, ${colors.purple});
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    line-height: 1.25;
    + p {
      margin-top: 0.5em;
    }
  }
`;

const InfoBox = (props: Props) => {
  return (
    <InfoBoxContainer
      className={cx("InfoBox", props.className)}
      color={props.color}
      data-name={props.dataName}
      data-test={props.dataTest}
      style={
        {
          "--ac": props.accentColor ?? colors.purple,
        } as CSSProperties
      }
    >
      <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM10.5 5C10.2348 5 9.98043 5.10536 9.79289 5.29289C9.60536 5.48043 9.5 5.73478 9.5 6C9.5 6.26522 9.60536 6.51957 9.79289 6.70711C9.98043 6.89464 10.2348 7 10.5 7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5H10.5ZM9 9C8.73478 9 8.48043 9.10536 8.29289 9.29289C8.10536 9.48043 8 9.73478 8 10C8 10.2652 8.10536 10.5196 8.29289 10.7071C8.48043 10.8946 8.73478 11 9 11H10V14H9C8.73478 14 8.48043 14.1054 8.29289 14.2929C8.10536 14.4804 8 14.7348 8 15C8 15.2652 8.10536 15.5196 8.29289 15.7071C8.48043 15.8946 8.73478 16 9 16H13C13.2652 16 13.5196 15.8946 13.7071 15.7071C13.8946 15.5196 14 15.2652 14 15C14 14.7348 13.8946 14.4804 13.7071 14.2929C13.5196 14.1054 13.2652 14 13 14H12V10C12 9.73478 11.8946 9.48043 11.7071 9.29289C11.5196 9.10536 11.2652 9 11 9H9Z"
        />
      </svg>
      <div>{props.children}</div>
    </InfoBoxContainer>
  );
};

export default InfoBox;
