import { styled } from "@linaria/react";
import {
  ProductPageH3,
  ProductPageSubSection,
  ProductPageSubSectionP,
} from "./_ProductPageSharedComponents";
import img from "./illustrations/casesIllo.svg";
import { colors } from "../../styles/colors.styles";
import RigidDotGrid from "../decorations/RigidDotGrid";
import { rSize } from "../../styles/responsiveSizes.styles";
import Spacing from "../layout/Spacing";
import { GRID_COL_WIDTH_MAX } from "../../constants/globalGrid.constants";
import {
  fromTabletLg,
  uptoPhoneLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { words } from "../../utils/string.utils";
import { Link } from "gatsby";

const Layout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  grid-template-areas: "figure" "header";
  header {
    grid-area: header;
  }
  figure {
    grid-area: figure;
  }
  ${uptoTabletLg} {
    h3 {
      display: none;
    }
    p {
      margin-top: 0;
    }
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ${`"${words("figure", 9)} ${words("header", 3)}"`};
    figure {
      margin-right: ${GRID_COL_WIDTH_MAX * 0.5}px;
    }
  }
`;

const Figure = styled.figure`
  position: relative;
  overflow: hidden;
  margin: 0;
  background-color: ${colors.orange200};
  border: 1px solid ${colors.orange400};
  border-radius: ${rSize("radius")};
  display: flex;
  justify-content: flex-end;
  ${uptoPhoneLg} {
    aspect-ratio: 5/4;
    justify-content: flex-start;
  }
  img {
    display: block;
    position: relative;
    max-width: 100%;
    ${uptoPhoneLg} {
      height: 100%;
      max-width: unset;
    }
  }
`;

export const CasesSubsection = () => (
  <ProductPageSubSection>
    <Layout>
      <header>
        <ProductPageH3>Cases</ProductPageH3>
        <ProductPageSubSectionP>
          <strong>Cases</strong> provide a space for the entire team to
          collaborate on incidents, investigations, and workflow exceptions.
          With our automation-first approach to case management, you can create
          a case straight from the storyboard.
        </ProductPageSubSectionP>
        <Spacing size="md" />
        <ProductPageSubSectionP>
          <Link to="/product/cases" className="single-link">
            Explore cases →
          </Link>
        </ProductPageSubSectionP>
      </header>
      <Figure>
        <RigidDotGrid color={colors.orange400} cellSize={24} />
        <img src={img} />
      </Figure>
    </Layout>
  </ProductPageSubSection>
);
