export const LegalIcon = () => (
  <svg
    width="70"
    height="85"
    viewBox="0 0 70 85"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    strokeLinecap="round"
  >
    <path
      d="M42.8309 83.9726C56.8245 83.9726 68.1685 82.1239 68.1685 79.8435C68.1685 77.563 56.8245 75.7144 42.8309 75.7144C28.8372 75.7144 17.4932 77.563 17.4932 79.8435C17.4932 82.1239 28.8372 83.9726 42.8309 83.9726Z"
      fill="#32313B"
    />
    <path
      d="M55.7971 76.4368C55.7971 74.259 45.6465 72.4937 33.125 72.4937C20.6033 72.4937 10.4526 74.259 10.4526 76.4368C10.4526 77.0783 10.4526 79.3603 10.4526 80.0019C10.4526 82.1796 20.6033 83.9448 33.125 83.9448C45.6465 83.9448 55.7971 82.1796 55.7971 80.0019C55.7971 79.3603 55.7971 77.0783 55.7971 76.4368Z"
      fill="#D67C56"
      stroke="#32313B"
    />
    <path
      d="M33.1263 80.3799C45.6478 80.3799 55.7984 78.6145 55.7984 76.4368C55.7984 74.259 45.6478 72.4937 33.1263 72.4937C20.6048 72.4937 10.4541 74.259 10.4541 76.4368C10.4541 78.6145 20.6048 80.3799 33.1263 80.3799Z"
      fill="#FBB598"
      stroke="#32313B"
    />
    <path
      d="M35.5985 77.3894C38.7627 77.3894 41.3278 76.7547 41.3278 75.9718C41.3278 75.1889 38.7627 74.5542 35.5985 74.5542C32.4342 74.5542 29.8691 75.1889 29.8691 75.9718C29.8691 76.7547 32.4342 77.3894 35.5985 77.3894Z"
      fill="#32313B"
    />
    <path
      d="M30.0278 58.1189V74.5128C30.0278 76.4726 31.6166 76.8496 33.5766 76.8496C35.5366 76.8496 37.1254 76.4726 37.1254 74.5128V58.1189H30.0278Z"
      fill="#FBB598"
      stroke="#32313B"
    />
    <path
      d="M37.2053 59.9031H29.8306V63.1536H37.2053V59.9031Z"
      fill="#32313B"
    />
    <path
      d="M18.5204 55.0717C2.02856 34.8152 5.07584 3.62378 5.07584 3.62378H46.4851C46.4851 3.62378 43.4377 34.8152 59.9297 55.0717H18.5204Z"
      fill="#F8F4F0"
      stroke="#32313B"
    />
    <path
      d="M60.1374 55.8368C58.5443 55.8368 57.253 54.1867 57.253 52.1514C57.253 50.116 58.5441 48.4658 60.1374 48.4658H66.1137C67.7066 48.4658 68.998 50.116 68.998 52.1514C68.998 54.1867 67.7068 55.8368 66.1137 55.8368H60.1374Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M58.5247 59.8565C61.855 59.8565 64.5547 56.407 64.5547 52.1514C64.5547 47.896 61.8548 44.4463 58.5247 44.4463H21.0039C17.6738 44.4463 14.9739 47.896 14.9739 52.1514C14.9739 56.407 17.6738 59.8565 21.0039 59.8565H58.5247Z"
      fill="#F8F4F0"
      stroke="#32313B"
    />
    <path
      d="M21.0045 59.8565C17.6741 59.8565 14.9743 56.4068 14.9743 52.1514C14.9743 47.896 17.6741 44.4463 21.0045 44.4463C24.3349 44.4463 27.0347 47.896 27.0347 52.1514C27.0347 56.4068 24.3349 59.8565 21.0045 59.8565Z"
      fill="#D2CCFF"
      stroke="#32313B"
    />
    <path
      d="M46.6763 17.9329C46.6763 17.9329 13.3191 17.9329 10.5198 17.9329C7.7206 17.9329 5.50459 16.8832 5.038 15.367V12.9177H46.352L46.6763 17.9329Z"
      fill="#32313B"
    />
    <path
      d="M48.5978 11.936C47.0047 11.936 45.7134 10.2858 45.7134 8.25054C45.7134 6.21527 47.0047 4.56494 48.5978 4.56494H54.5741C56.167 4.56494 57.4585 6.21508 57.4585 8.25054C57.4585 10.286 56.167 11.936 54.5741 11.936H48.5978Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M46.9851 15.956C50.3153 15.956 53.0151 12.5064 53.0151 8.25094C53.0151 3.99552 50.3153 0.545654 46.9851 0.545654H9.46434C6.13422 0.545654 3.43434 3.99552 3.43434 8.25094C3.43434 12.5064 6.13422 15.956 9.46434 15.956H46.9851Z"
      fill="#F8F4F0"
      stroke="#32313B"
    />
    <path
      d="M9.46444 15.9559C6.13406 15.9559 3.43425 12.5062 3.43425 8.25075C3.43425 3.99535 6.13406 0.545654 9.46444 0.545654C12.7948 0.545654 15.4946 3.99535 15.4946 8.25075C15.4946 12.5062 12.7948 15.9559 9.46444 15.9559Z"
      fill="#D2CCFF"
      stroke="#32313B"
    />
    <path
      d="M24.2422 52.2908C24.2422 52.2908 24.3013 56.6744 19.6215 59.6958C19.6215 59.6958 16.8963 59.1034 16.1262 56.2007L24.2422 52.2908Z"
      fill="#32313B"
    />
    <path
      d="M12.7656 8.4248C12.7656 8.4248 12.8247 12.8086 8.14473 15.8298C8.14473 15.8298 5.41972 15.2375 4.64964 12.3347L12.7656 8.4248Z"
      fill="#32313B"
    />
    <path
      d="M15.028 55.8368C13.4349 55.8368 12.1438 54.1867 12.1438 52.1514C12.1438 50.116 13.4349 48.4658 15.028 48.4658H21.0043C22.5974 48.4658 23.8887 50.116 23.8887 52.1514C23.8887 54.1867 22.5974 55.8368 21.0043 55.8368H15.028Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M15.0282 55.8369C13.4352 55.8369 12.1439 54.1869 12.1439 52.1515C12.1439 50.1161 13.4352 48.4661 15.0282 48.4661C16.6212 48.4661 17.9126 50.1161 17.9126 52.1515C17.9126 54.1869 16.6212 55.8369 15.0282 55.8369Z"
      fill="#B0A6F9"
      stroke="#32313B"
    />
    <path
      d="M3.48794 11.936C1.89486 11.936 0.603775 10.2858 0.603775 8.25054C0.603775 6.21527 1.89486 4.56494 3.48794 4.56494H9.46427C11.0574 4.56494 12.3486 6.21508 12.3486 8.25054C12.3486 10.286 11.0574 11.936 9.46427 11.936H3.48794Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M3.48869 11.9363C1.8957 11.9363 0.604319 10.2862 0.604319 8.25085C0.604319 6.21545 1.8957 4.56543 3.48869 4.56543C5.08169 4.56543 6.37305 6.21545 6.37305 8.25085C6.37305 10.2862 5.08169 11.9363 3.48869 11.9363Z"
      fill="#B0A6F9"
      stroke="#32313B"
    />
    <path d="M42.3325 20.9097H10.0504" stroke="#BFBAB2" />
    <path d="M42.9741 24.9133H10.692" stroke="#BFBAB2" />
    <path d="M43.5396 28.9175H11.2575" stroke="#BFBAB2" />
    <path d="M44.5557 32.9211H12.2736" stroke="#BFBAB2" />
    <path d="M45.7622 36.9253H13.4801" stroke="#BFBAB2" />
    <path d="M47.2222 40.929H14.9401" stroke="#BFBAB2" />
  </svg>
);
