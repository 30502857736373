import { styled } from "@linaria/react";
import { color, colors } from "../../../styles/colors.styles";
import WebhookIcon20 from "../../storyboard/icons/Webhook.icon.20";
import TransformIcon20 from "../../storyboard/icons/Transform.icon.20";
import { CSSProperties, ReactNode } from "react";
import SendEmailIcon20 from "../../storyboard/icons/SendEmail.icon.20";
import TriggerIcon20 from "../../storyboard/icons/Trigger.icon.20";
import SendToStoryIcon20 from "../../storyboard/icons/SendToStory.icon.20";
import ReceiveEmailIcon20 from "../../storyboard/icons/ReceiveEmail.icon.20";
import { cx } from "linaria";
import { EventBubble } from "./EventBubble";
import {
  ActionLinkIncomingGooeyBit,
  ActionLinkOutgoingGooeyBit,
  ActionLinkOutgoingTerminal,
} from "./ActionLinkComponents";
import { onlyPhones } from "../../../styles/breakpointsAndMediaQueries.styles";
import { AIIcon20 } from "../../storyboard/icons/AI.icon.20";
import HTTPRequestIcon20 from "../../storyboard/icons/HTTPRequest.icon.20";

const ActionCardWrap = styled.div`
  display: inline-grid;
  justify-content: start;
  grid-template-rows: auto minmax(auto, 1fr);
  grid-template-columns: auto minmax(auto, 1fr);
  grid-template-areas: "icon type" "icon title";
  grid-gap: 3px 6px;
  background-color: var(--bg);
  color: var(--text);
  border: 1px solid var(--border);
  padding: 3px 10px 3px 3px;
  border-radius: 12px;
  min-height: 48px;
  transform: translateZ(0);
  max-width: ${24 * 8}px;
  user-select: none;
  text-align: left;
  &.positioned {
    position: absolute;
    top: 0;
    left: 0;
  }
  ${EventBubble} {
    ${onlyPhones} {
      display: none;
    }
  }
`;

const ActionCardIcon = styled.div`
  grid-area: icon;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ac);
  color: ${colors.white};
  width: 40px;
  min-height: 40px;
  border-radius: 9px;
`;

const ActionCardType = styled.div`
  grid-area: type;
  align-self: end;
  font-weight: 600;
  color: var(--ac);
  font-size: 10px;
  line-height: 1;
  margin-top: 8px;
`;

const ActionCardTitle = styled.div`
  grid-area: title;
  align-self: start;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.5em;
`;

const actionDefs = {
  "send-email": {
    icon: SendEmailIcon20,
    name: "Send email",
    color: "pink",
    colorVariant: 400,
  },
  "receive-email": {
    icon: ReceiveEmailIcon20,
    name: "Receive email",
    color: "red",
    colorVariant: 400,
  },
  trigger: {
    icon: TriggerIcon20,
    name: "Trigger",
    color: "green",
    colorVariant: 400,
  },
  "http-request": {
    icon: HTTPRequestIcon20,
    name: "HTTP Request",
    color: "blue",
    colorVariant: 400,
  },
  webhook: {
    icon: WebhookIcon20,
    name: "Webhook",
    color: "teal",
    colorVariant: 400,
  },
  ai: {
    icon: AIIcon20,
    name: "AI",
    color: "yellow",
    colorVariant: 400,
  },
  transform: {
    icon: TransformIcon20,
    name: "Transform",
    color: "orange",
    colorVariant: 400,
  },
  "send-to-story": {
    icon: SendToStoryIcon20,
    name: "Send to story",
    color: "purple",
    colorVariant: 500,
  },
} as const;

export const ActionCard = (props: {
  id?: string;
  className?: string;
  type: keyof typeof actionDefs;
  title: string;
  hasOutgoingLink?: boolean;
  showOutgoingLinkTerminal?: boolean;
  hasIncomingLink?: boolean;
  appendToIconContainer?: ReactNode;
  topByGridCell?: number;
  leftByGridCell?: number;
  eventCount?: number | string;
  colorVariant?: 0 | 50 | 100;
  injectBefore?: ReactNode;
}) => {
  const def = actionDefs[props.type];
  const accent = color(def.color, def.colorVariant);
  const positioned =
    props.topByGridCell !== undefined || props.leftByGridCell !== undefined;
  return (
    <ActionCardWrap
      id={props.id}
      className={cx(props.className, positioned && "positioned")}
      style={
        {
          "--bg": color(def.color, props.colorVariant ?? 50),
          "--ac": accent,
          "--text": color(def.color, props.colorVariant === 0 ? 600 : 800),
          "--border": color(def.color, props.colorVariant === 0 ? 100 : 200),
          transform:
            positioned &&
            `translate(${(props.leftByGridCell ?? 0) * 24}px, ${
              (props.topByGridCell ?? 0) * 24
            }px)`,
        } as CSSProperties
      }
    >
      {props.injectBefore}
      <ActionCardIcon id={props.id ? `${props.id}-icon-container` : undefined}>
        <def.icon />
        {props.hasIncomingLink && (
          <ActionLinkIncomingGooeyBit
            color={def.color}
            id={props.id ? `${props.id}-incoming-link-gooey-bit` : ""}
          />
        )}
        {props.hasOutgoingLink && (
          <ActionLinkOutgoingGooeyBit
            color={def.color}
            id={props.id ? `${props.id}-outgoing-gooey-bit` : ""}
          />
        )}
        {props.showOutgoingLinkTerminal && (
          <ActionLinkOutgoingTerminal
            color={def.color}
            id={`${props.id}-outgoing-link-terminal`}
          />
        )}
        {props.appendToIconContainer}
      </ActionCardIcon>
      <ActionCardType>{def.name}</ActionCardType>
      <ActionCardTitle>{props.title}</ActionCardTitle>
      {props.eventCount && <EventBubble>{props.eventCount}</EventBubble>}
    </ActionCardWrap>
  );
};
