import Point, { XY } from "../../../utils/geometry.utils";
import { StoryboardContextValue } from "../StoryboardContext";

export const getPositionInViewport = (
  context: StoryboardContextValue,
  point: XY
) => {
  const { boundingBox: B } = context.storyboard;
  return Point.round({ x: point.x - B[0].x, y: point.y - B[0].y });
};
