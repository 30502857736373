import { LinariaClassName, css } from "@linaria/core";
import { font } from "../styles/fonts.styles";
import { resetStyles } from "./@reset.styles";
import { withOpacity } from "./colorsV4.styles";
import { defaultFontFaces } from "./fontFaces/default.fontFaces";
import { emojiFontFaces } from "./fontFaces/emoji.fontFaces";
import {
  globalMonospaceFontStyles,
  monospaceFontFaces,
} from "./fontFaces/monospace.fontFaces";
import { responsiveSizesCSSVariables } from "./responsiveSizes.styles";
import { colors, getBrandColorThemeVariablesAsString } from "./colors.styles";
import { zIndex } from "./zIndexes.styles";
import { fromTablet } from "./breakpointsAndMediaQueries.styles";

export const globals = css`
  :global() {
    // uncomment below to debug page content overflowing or other layout issues
    /* * {
      border: 1px solid red;
    } */
    * {
      scroll-margin-top: var(--pageTopFixedElementsHeight);
    }
    html {
      font-family: ${font("sans")};
      font-size: 10px;
      -webkit-text-size-adjust: 100%;
      font-weight: 400;
      // the following uses grayscale based font smoothing, which generally looks thinner than the default.
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-tap-highlight-color: transparent;
      text-rendering: optimizeLegibility;
      color: var(--PageForegroundColor, ${colors.dark500});
      background-color: var(--PageBackgroundColor, ${colors.lightest});
      accent-color: ${colors.purple};
      ${getBrandColorThemeVariablesAsString("purple")}
      --PageBackgroundColor: ${colors.lightest};
      --BlurredPanelBackgroundColor: ${colors.lightest};
      --intercomBannerHeight: 0px;
      --SiteNavHeight: 58px;
      ${fromTablet} {
        --SiteNavHeight: 88px;
      }
      &[data-color-scheme="light"] {
        --BlurredPanelBackgroundColor: ${colors.lightest};
      }
      &[data-color-scheme="dark"] {
        --BlurredPanelBackgroundColor: ${colors.dark900};
      }
      @supports (backdrop-filter: blur(2em)) {
        --BlurredPanelBackgroundColor: ${withOpacity(colors.lightest, 0.9)};
        &[data-color-scheme="light"] {
          --BlurredPanelBackgroundColor: ${withOpacity(colors.lightest, 0.9)};
        }
        &[data-color-scheme="dark"] {
          --BlurredPanelBackgroundColor: ${withOpacity(colors.dark900, 0.8)};
        }
        @media (prefers-color-scheme: dark) {
          --BlurredPanelBackgroundColor: ${withOpacity(colors.dark900, 0.8)};
        }
      }
      &.supports-color-schemes {
        color-scheme: light dark;
        @media (prefers-color-scheme: dark) {
          color-scheme: dark;
          background-color: ${colors.dark900};
          color: ${colors.white};
          --PageBackgroundColor: ${colors.dark900};
          --BlurredPanelBackgroundColor: ${colors.dark900};
        }
        &[data-color-scheme="light"] {
          color-scheme: light;
          color: ${colors.dark500};
          background-color: var(--PageBackgroundColor, ${colors.lightest});
          --PageBackgroundColor: ${colors.lightest};
        }
        &[data-color-scheme="dark"] {
          color-scheme: dark;
          background-color: ${colors.dark900};
          color: ${colors.white};
          --PageBackgroundColor: ${colors.dark900};
          --BlurredPanelBackgroundColor: ${colors.dark900};
        }
        @supports (backdrop-filter: blur(2em)) {
          --BlurredPanelBackgroundColor: ${withOpacity(colors.lightest, 0.9)};
          &[data-color-scheme="light"] {
            --BlurredPanelBackgroundColor: ${withOpacity(colors.lightest, 0.9)};
          }
          &[data-color-scheme="dark"] {
            --BlurredPanelBackgroundColor: ${withOpacity(colors.dark900, 0.8)};
          }
          @media (prefers-color-scheme: dark) {
            --BlurredPanelBackgroundColor: ${withOpacity(colors.dark900, 0.8)};
          }
        }
      }
      &:before {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: calc(var(--intercomBannerHeight) + 1px);
        background-color: ${colors.purple900};
        border-bottom: 1px solid ${colors.purple800};
        box-sizing: border-box;
        transform: translateY(-100%);
        opacity: 0;
        z-index: ${zIndex("IntercomBannerBackdrop")};
      }
      &:after {
        content: "";
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='26' height='9' viewBox='0 0 26 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-3.13672e-07 1L26 1.00004L26 2.00007L20.8284 2.00003C20.298 2.00002 19.7893 2.21074 19.4142 2.58581L14.4142 7.58581C13.6332 8.36686 12.3668 8.36686 11.5858 7.58581L6.58577 2.5858C6.2107 2.21073 5.702 2.00002 5.17157 2.00002L3.39525e-06 1.99999L-3.13672e-07 1Z' fill='%234D3E78'/%3E%3Cpath d='M-3.13672e-07 3.13672e-07L26 3.82927e-05L26 1.00007L20.3284 1.00003C19.798 1.00002 19.2893 1.21073 18.9142 1.58581L13.7071 6.7929C13.3166 7.18342 12.6834 7.18342 12.2929 6.7929L7.08578 1.58584C6.71071 1.21077 6.20201 1.00006 5.67159 1.00006L3.39525e-06 0.999994L-3.13672e-07 3.13672e-07Z' fill='%2332274B'/%3E%3C/svg%3E%0A");
        transform: translateY(-100%);
        position: fixed;
        top: calc(var(--intercomBannerHeight) - 1px);
        left: calc(50% - 13px);
        width: 26px;
        height: 9px;
        opacity: 0;
        z-index: ${zIndex("IntercomBannerBackdrop")};
      }
      &[data-has-intercom-banner] {
        &:before,
        &:after {
          transition: transform 250ms ease 0s, opacity 250ms ease 0s;
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
    body {
      font-size: 1.6rem;
      --pageTopFixedElementsHeight: calc(
        var(--SiteNavHeight, 0px) + var(--intercomBannerHeight, 0px)
      );
    }
    * {
      box-sizing: border-box;
      outline-color: ${colors.purple};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-top: 0;
      margin-bottom: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 600;
    }
    a {
      color: inherit;
    }
    a,
    button {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    p {
      font-weight: 500;
      line-height: 1.4;
    }
    img {
      user-select: none;
    }
    hr {
      color: inherit;
    }
    ::cue {
      color: ${colors.white};
      background: ${withOpacity(colors.purple900, 0.8)};
      font-family: ${font("sans")};
      /* font-size: 1.8rem; */
      /* line-height: 2rem; */
      font-weight: 600;
    }
  }
`;

export const loadGlobalStyles = () => [
  resetStyles,
  defaultFontFaces,
  monospaceFontFaces,
  globalMonospaceFontStyles,
  emojiFontFaces,
  globals,
  responsiveSizesCSSVariables,
];

export const includeGlobalStyles = (...args: LinariaClassName[]) => args;
