import { styled } from "@linaria/react";
import { cover } from "polished";
import { PropsWithChildren, RefObject, useRef } from "react";
import { useSizeAndVisibilityObserver } from "../../utils/useSizeAndVisibilityObserver.utils";
import { RigidDotGridDefaults } from "./RigidDotGrid";

type Props = {
  cellSize?: number;
  containerRef: RefObject<HTMLDivElement>;
  insetTop?: number;
  gridXOrigin: "start" | "center";
};

type LinePointDef = [
  x: number,
  y: number,
  xOrigin?: "start" | "center" | "end",
  yOrigin?: "start" | "end"
];

const SvgLayerSvg = styled.svg`
  display: block;
  ${cover()};
`;

const useGridLayout = ({
  containerRef,
  cellSize = RigidDotGridDefaults.cellSize,
  insetTop = 0,
  gridXOrigin,
}: Props) => {
  const ownRef = useRef<HTMLDivElement>(null);
  const ref = containerRef ?? ownRef;
  const { ready, width, height } = useSizeAndVisibilityObserver(ref);
  const insetLeft = gridXOrigin === "center" ? (width / 2) % cellSize : 0;
  const insetRight =
    gridXOrigin === "center" ? (width / 2) % cellSize : width % cellSize;
  const insetBottom = (height - insetTop) % cellSize;
  const totalCols = Math.ceil(width / cellSize);
  const totalRows = Math.ceil(height / cellSize);
  const x = {
    start: (v: number) => insetLeft + v * cellSize,
    center: (v: number) =>
      insetLeft +
      (totalCols / 2 + v + (insetLeft > cellSize / 2 ? -1 : -0.5)) * cellSize,
    end: (v: number) => width - insetRight - v * cellSize,
    span: (v: number) => v * cellSize,
  };
  const y = {
    start: (v: number) => insetTop + (v - 0.5) * cellSize,
    end: (v: number) =>
      height -
      insetBottom -
      (v + (insetBottom > cellSize / 2 ? -1 : -0.5)) * cellSize,
    span: (v: number) => v * cellSize,
  };
  const Line = ({
    p1,
    p2,
    id,
    stroke,
  }: {
    p1: LinePointDef;
    p2: LinePointDef;
    id?: string;
    stroke?: string;
  }) => (
    <line
      id={id}
      x1={x[p1[2] ?? "start"](p1[0])}
      y1={y[p1[3] ?? "start"](p1[1])}
      x2={x[p2[2] ?? "start"](p2[0])}
      y2={y[p2[3] ?? "start"](p2[1])}
      stroke={stroke}
    />
  );
  const HrztLine = ({
    y,
    yOrigin = "start",
    id,
  }: {
    y: number;
    yOrigin?: LinePointDef[3];
    id?: string;
  }) => (
    <Line id={id} p1={[-1, y, "start", yOrigin]} p2={[-1, y, "end", yOrigin]} />
  );
  const VertLine = ({
    x,
    xOrigin,
    id,
  }: {
    x: number;
    xOrigin: LinePointDef[2];
    id?: string;
  }) => <Line id={id} p1={[x, -1, xOrigin]} p2={[x, -1, xOrigin, "end"]} />;
  const SvgLayer = (
    props: PropsWithChildren<{
      fill?: string;
      stroke?: string;
      strokeDasharray?: string;
    }>
  ) => (
    <SvgLayerSvg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={props.fill}
      stroke={props.stroke}
      strokeDasharray={props.strokeDasharray}
    >
      {props.children}
    </SvgLayerSvg>
  );
  return {
    ref,
    ready,
    width,
    height,
    x,
    y,
    Line,
    HrztLine,
    VertLine,
    totalCols,
    totalRows,
    SvgLayer,
  };
};

export default useGridLayout;
