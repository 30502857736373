import ContentCardBase from "./_ContentCardBase";
import { DatoCmsContentCard, DatoCmsWebinar } from "../../../graphql-types";
import { PrimaryColorName } from "../../styles/colors.styles";
import ReactMarkdown from "react-markdown";

export const ContentCardWebinar = ({
  webinar,
  overrides,
  large,
}: {
  webinar: DatoCmsWebinar;
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  const color = (webinar.colorTheme?.toLowerCase() ??
    "purple") as PrimaryColorName;
  return (
    <ContentCardBase
      link={`/${webinar.category?.slug ?? "webinars"}/${webinar.slug}`}
      color={color}
      variant={overrides?.colorMode === "dark" ? "800" : "50"}
      large={large}
      size="1/3"
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Webinar"}</strong>
          </p>
          <h4>{overrides?.heading || webinar.title}</h4>
          {overrides?.description && (
            <ReactMarkdown>{overrides.description}</ReactMarkdown>
          )}
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Watch webinar"}</strong>
        </p>
      </header>
      <figure>
        <WebinarIcon />
      </figure>
    </ContentCardBase>
  );
};

const WebinarIcon = () => (
  <svg width="85" height="78" viewBox="0 0 85 78" fill="none">
    <path
      d="M84.789 75.4995C84.789 76.8803 70.1098 77.999 51.7483 77.999C33.3868 77.999 18.5019 76.8803 18.5019 75.4995C18.5019 74.1187 33.3868 72.9995 51.7483 72.9995C70.1098 72.9995 84.789 74.1187 84.789 75.4995Z"
      fill="#26242C"
    />
    <path
      d="M70.7523 26.0083L8.51213 25.9641C7.75259 25.9635 7.06324 26.2591 6.55123 26.7415C6.03986 27.2241 4.24104 29.0205 3.75758 29.5317C3.27455 30.0424 2.97786 30.7318 2.97743 31.4913L2.94694 74.4028C2.94565 75.9826 4.22559 77.2649 5.80565 77.2658L68.0461 77.3102C68.8052 77.3106 69.4947 77.015 70.0065 76.5324C70.5181 76.0498 72.3172 74.2531 72.8006 73.7424C73.2839 73.2312 73.5803 72.5423 73.581 71.7826L73.6117 28.8711C73.6125 27.2913 72.3324 26.0096 70.7523 26.0083Z"
      fill="#464550"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.9088 74.4505C70.9073 76.0299 69.6259 77.3102 68.0461 77.3092L5.80563 77.2647C4.22558 77.2639 2.94565 75.9816 2.94694 74.4017L2.97742 31.4903C2.97828 29.91 4.26037 28.6303 5.83999 28.6313L68.0806 28.6753C69.6602 28.6764 70.9404 29.9583 70.9393 31.5386L70.9088 74.4505Z"
      fill="#464550"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.8508 56.2276C43.8511 55.4783 43.2774 55.2588 43.2774 55.2588L33.9499 49.2825C33.9499 49.2825 33.2895 48.8259 32.5474 49.1761C32.0137 49.4286 32.0513 50.2498 32.0513 50.2498L32.0441 60.8089C32.0441 60.8089 31.9946 61.7591 32.7303 62.1793C33.4661 62.5998 34.2779 62.13 34.2779 62.13L43.2791 57.1941C43.2794 57.1939 43.8503 56.9634 43.8508 56.2276Z"
      fill="#FCF9F5"
    />
    <path
      d="M73.8745 22.6481L5.33665 22.5994L2.56919 25.7247L2.56319 34.3319L71.1009 34.3807L73.869 30.1793L73.8745 22.6481Z"
      fill="#464550"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.1039 25.7495H2.56619V34.3567H71.1039V25.7495Z"
      fill="#464550"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.7325 9.47585L70.2338 1L3.89317 12.9108L0.745289 15.5956L2.24355 24.071L69.7344 12.139L71.7325 9.47585Z"
      fill="#464550"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M68.2296 3.65891L0.740788 15.5913L2.23923 24.0664L69.7281 12.134L68.2296 3.65891Z"
      fill="#464550"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.5126 34.3221L18.354 34.3175L23.0358 25.7681L29.1943 25.7724L24.5126 34.3221Z"
      fill="#FCF9F5"
    />
    <path
      d="M38.3124 34.332L32.1541 34.3273L36.8358 25.7776L42.9942 25.7817L38.3124 34.332Z"
      fill="#FCF9F5"
    />
    <path
      d="M52.1108 34.3415L45.9524 34.3368L50.6346 25.7869L56.7928 25.7916L52.1108 34.3415Z"
      fill="#FCF9F5"
    />
    <path
      d="M65.9108 34.3508L59.7524 34.3463L64.434 25.7964L70.5926 25.8009L65.9108 34.3508Z"
      fill="#FCF9F5"
    />
    <path
      d="M63.431 13.3665L69.5097 12.3792L63.5233 4.68604L57.4446 5.67336L63.431 13.3665Z"
      fill="#FCF9F5"
    />
    <path
      d="M49.8104 15.5804L55.8893 14.5935L49.9027 6.90015L43.824 7.88768L49.8104 15.5804Z"
      fill="#FCF9F5"
    />
    <path
      d="M36.1888 17.7926L42.2673 16.8055L36.2804 9.11255L30.202 10.0997L36.1888 17.7926Z"
      fill="#FCF9F5"
    />
    <path
      d="M22.5675 20.0039L28.6462 19.0166L22.6596 11.3235L16.5809 12.311L22.5675 20.0039Z"
      fill="#FCF9F5"
    />
    <path
      d="M71.1039 25.7495H2.56619V34.3567H71.1039V25.7495Z"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.7532 26.0074L73.8751 22.6487"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.9447 34.5647H2.84092V36.1164H70.9447V34.5647Z"
      fill="#26242C"
    />
    <path
      d="M68.2296 3.65891L0.740788 15.5913L2.23923 24.0664L69.7281 12.134L68.2296 3.65891Z"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.943 36.141L73.5928 32.9476L73.5938 31.2134L71.1003 34.3812L70.943 36.141Z"
      fill="#26242C"
    />
    <path
      d="M0.565657 23.7114L0.560075 31.5475C0.558787 33.3257 1.99974 34.7688 3.77816 34.7699L20.4518 34.7817L20.4582 26.0517L9.83839 15.9539L0.571461 15.9473L0.565657 23.7114Z"
      fill="#F69267"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.43498 21.4242C6.02781 21.4242 6.50839 20.9436 6.50839 20.3508C6.50839 19.7579 6.02781 19.2773 5.43498 19.2773C4.84216 19.2773 4.36158 19.7579 4.36158 20.3508C4.36158 20.9436 4.84216 21.4242 5.43498 21.4242Z"
      fill="#FBB598"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.42732 31.0863C6.02014 31.0863 6.50072 30.6057 6.50072 30.0129C6.50072 29.42 6.02014 28.9395 5.42732 28.9395C4.83449 28.9395 4.35391 29.42 4.35391 30.0129C4.35391 30.6057 4.83449 31.0863 5.42732 31.0863Z"
      fill="#FBB598"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.3035 31.0931C15.8963 31.0931 16.3769 30.6125 16.3769 30.0197C16.3769 29.4269 15.8963 28.9463 15.3035 28.9463C14.7107 28.9463 14.2301 29.4269 14.2301 30.0197C14.2301 30.6125 14.7107 31.0931 15.3035 31.0931Z"
      fill="#FBB598"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M68.2364 3.66334L70.2338 1"
      stroke="#26242C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.022 25.7412L43.2162 22.3112L17.1324 22.2927L20.4576 25.6227L36.022 25.7412Z"
      fill="#26242C"
    />
    <rect
      x="7.21097"
      y="39.239"
      width="59.5401"
      height="33.5638"
      rx="1.61894"
      stroke="#FCF9F5"
    />
  </svg>
);
