import { PropsWithChildren } from "react";
import Button, { ButtonProps } from "../forms/Button";
import { Paths } from "../../utils/pathBuilders.utils";
import { isString } from "lodash-es";
import { captureAnalyticsEvent } from "../../utils/analyticsEvent";

type Props = ButtonProps & {
  paramString?: string;
};

const SignUpButton = (props: PropsWithChildren<Props>) => {
  return (
    <Button
      href={Paths.signup_loginTinesCom(props.paramString)}
      title={isString(props.children) ? props.children : "Sign up free"}
      darker={props.darker}
      rel="nofollow"
      children={props.children ?? "Sign up free"}
      onClick={() => captureAnalyticsEvent("clickedSignup")}
      {...props}
    />
  );
};

export default SignUpButton;
