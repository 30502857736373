import { css, cx } from "linaria";
import {
  CSSProperties,
  PropsWithChildren,
  ReactChild,
  ReactNode,
  createElement,
} from "react";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { FontType, font } from "../../styles/fonts.styles";
import { fluidFontSize } from "../../styles/helpers.styles";

type Props = PropsWithChildren<{
  className?: string;
  as?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  snippets?: ([ReactNode, FontType?] | ReactChild)[];
  noWrap?: boolean;
  lineHeight?: number;
  style?: CSSProperties;
}>;

const paragraphStyle = css`
  line-height: 1.1;
  font-weight: 600;
  ${fluidFontSize(48, 72)};
  ${fromTablet} {
    line-height: 1;
    letter-spacing: -0.01em;
  }
  span {
    &.sans {
      font-family: ${font("sans")};
    }
    &.serif {
      font-family: ${font("serif")};
      font-weight: 400;
    }
  }
  &.noWrap {
    white-space: nowrap;
  }
`;

/** @deprecated use \<Serif /> instead for serif fonts. */
const ComboFontHeading = (props: Props) => {
  const snippetsRendered = (props.snippets ?? []).map((snippet, i) =>
    snippet instanceof Array ? (
      <span key={i} className={snippet[1]}>
        {snippet[0]}{" "}
      </span>
    ) : (
      <span key={i}>{snippet} </span>
    )
  );
  return createElement(props.as ?? "h2", {
    className: cx(
      "ComboFontHeading",
      paragraphStyle,
      props.noWrap && "noWrap",
      props.className
    ),
    style: {
      lineHeight: props.lineHeight,
      ...props.style,
    },
    children: (
      <>
        {props.children ? <span>{props.children}</span> : null}
        {snippetsRendered}
      </>
    ),
  });
};

export default ComboFontHeading;
