import { styled } from "@linaria/react";
import ActionIcon from "../basic/ActionIcon";
import {
  ProductPageH3,
  ProductPageSubSection,
} from "./_ProductPageSharedComponents";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import {
  fromDesktopMd,
  fromDesktopSm,
  fromPhoneLg,
  fromPhoneMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  actionTypeDefs,
  actionTypeNames,
} from "../../constants/actionTypes.constants";

const Header = styled.header`
  position: relative;
  z-index: 1;
  h3 {
    display: inline-block;
    background-color: ${colors.purple100};
    margin-left: 0.25em;
    ${fromTabletMd} {
      margin-left: -0.5em;
    }
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;

const ContentsWrap = styled.div`
  position: relative;
  --unit: 3em;
  padding-top: var(--unit);
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: var(--unit);
  ${fromPhoneMd} {
    padding-left: 2em;
    padding-right: 2em;
  }
  ${fromPhoneLg} {
    padding-left: 3em;
    padding-right: 3em;
  }
  ${fromTablet} {
    --unit: 4em;
    padding-bottom: var(--unit);
  }
  ${fromTabletMd} {
    --unit: 6em;
    padding-left: 0;
    padding-right: 0;
  }
  &:before {
    content: "";
    border: 1px solid ${colors.purple200};
    border-radius: ${rSize("radius")};
    position: absolute;
    pointer-events: none;
    top: -1.4rem;
    bottom: 0;
    left: 0;
    right: 0;
    ${fromTabletMd} {
      left: calc(
        ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
      );
      right: calc(
        ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
      );
    }
  }
`;

const ActionsRow = styled.div`
  display: none;
  ${fromTabletLg} {
    display: flex;
  }
  justify-content: space-between;
  margin-bottom: 4em;
  > * {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 16px;
    svg {
      width: 28px;
      height: 28px;
    }
    ${fromDesktopSm} {
      width: 72px;
      height: 72px;
      border-radius: 18px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: calc(100% + 1px);
      left: calc(50% - 1px);
      border-right: 1px solid ${colors.purple300};
    }
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7) {
      &:after {
        height: 4em;
      }
    }
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(8) {
      &:after {
        height: 16em;
      }
    }
  }
`;

const DescriptionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 2em ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: auto auto;
    grid-gap: 3.5em 1em;
    grid-template-areas: "a1 a1 . a3 a3 . a5 a5 . a7 a7 ." ". a2 a2 . a4 a4 . a6 a6 . a8 a8";
  }
  ${fromDesktopMd} {
    grid-gap: 3.5em ${rSize("gap")};
  }
`;

const DescriptionGridItem = styled.div`
  ${fromPhoneLg} {
    padding-right: 24px;
  }
  ${fromTablet} {
    padding-right: 0;
  }
  ${fromTabletLg} {
    border-top: 1px solid ${colors.purple300};
    background-image: linear-gradient(
      to bottom,
      ${colors.purple100},
      ${withOpacity(colors.purple100)} 2em
    );
    position: relative;
    padding-top: 0.875em;
  }
  h4 {
    font-size: 1.4rem;
    ${fromTabletLg} {
      font-size: 1.6rem;
    }
    span {
      display: inline-block;
      position: relative;
    }
  }
  p {
    margin-top: 0.8rem;
    font-size: 1.3rem;
    line-height: 1.25;
    ${fromDesktopSm} {
      font-size: 1.4rem;
      line-height: 1.35;
    }
  }
  &[data-name="ai"] {
    ${fromDesktopSm} {
      margin-right: -0.5em;
    }
  }
  ${fromTabletLg} {
    &:nth-child(1) {
      grid-area: a1;
      margin-right: 1em;
    }
    &:nth-child(2) {
      grid-area: a2;
    }
    &:nth-child(3) {
      grid-area: a3;
    }
    &:nth-child(4) {
      grid-area: a4;
    }
    &:nth-child(5) {
      grid-area: a5;
    }
    &:nth-child(6) {
      grid-area: a6;
    }
    &:nth-child(7) {
      grid-area: a7;
    }
    &:nth-child(8) {
      grid-area: a8;
    }
  }
`;

const DescriptionGridItemActionIconWrap = styled.div`
  margin-bottom: 0.75em;
  ${fromTabletLg} {
    display: none;
  }
`;

export const EightActionsSubsection = () => (
  <ProductPageSubSection>
    <Header>
      <ProductPageH3>The actions</ProductPageH3>
    </Header>
    <ContentsWrap>
      <ActionsRow>
        {actionTypeNames.map(action => (
          <ActionIcon type={action} key={action} />
        ))}
      </ActionsRow>
      <DescriptionGrid>
        {actionTypeDefs.map(def => (
          <DescriptionGridItem key={def.slug} data-name={def.slug}>
            <DescriptionGridItemActionIconWrap>
              <ActionIcon type={def.slug} />
            </DescriptionGridItemActionIconWrap>
            <h4>
              <span>{def.name}</span>
            </h4>
            <p>{def.description}</p>
          </DescriptionGridItem>
        ))}
      </DescriptionGrid>
    </ContentsWrap>
  </ProductPageSubSection>
);
