import { styled } from "@linaria/react";
import { PropsWithChildren, ReactNode, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { getCookie } from "../../utils/cookies.utils";
import { useSiteContext } from "../../context/site.context";
import GlobalGridOverlayAndControl from "../utilities/GlobalGridOverlayAndControl";
import { colors, withOpacity } from "../../styles/colors.styles";
import { zIndex } from "../../styles/zIndexes.styles";
import { useHotkeys } from "react-hotkeys-hook";
import { createPortal } from "react-dom";
import {
  DatoCMSItemTypeName,
  datoCmsItemTypes,
} from "../../constants/datoCmsItemTypes";
import { isMac } from "../../utils/platform.utils";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage.utils";
import { cx } from "linaria";
import { isTinesComBuildOnMain } from "../../environment";

const Toolbar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${zIndex("AdminToolbar")};
  transform: translateY(100%);
  transition: 0.05s;
  user-select: none;
  &.visible {
    transform: translateY(0);
  }
`;

const ToolbarInner = styled.div`
  overflow: hidden;
  background-color: ${withOpacity(colors.black, 0.7)};
  backdrop-filter: saturate(2) blur(1em);
  border-top: 1px solid ${withOpacity(colors.white, 0.1)};
  border-right: 1px solid ${withOpacity(colors.white, 0.1)};
  background-clip: border-box;
  color: ${colors.white};
  font-size: 1.2rem;
  display: flex;
  align-items: stretch;
  font-weight: 600;
  border-top-right-radius: 0.5em;
`;

const ToolbarSection = styled.div`
  display: flex;
  align-items: stretch;
  line-height: 1;
  &.padded {
    padding: 0.5em 0.75em;
  }
  + * {
    border-left: 1px solid ${withOpacity(colors.white, 0.25)};
  }
  &.dynamic {
    &:empty {
      display: none;
    }
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;

export const AdminToolbarSeparator = styled.b`
  display: block;
  width: 1px;
  background-color: currentColor;
  opacity: 0.1;
`;

export const AdminToolbarButton = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0.5em 0.75em;
  color: inherit;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  > * {
    + * {
      margin-left: 0.375em;
    }
  }
  &:hover {
    background-color: ${withOpacity(colors.white, 0.1)};
  }
  &.active {
    background-color: ${colors.white};
    color: ${colors.black};
  }
  svg {
    display: block;
  }
`;

const AdminToolbar = () => {
  const siteContext = useSiteContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const displayName = name || email;
  const [shouldRender, setShouldRender] = useState(false);
  const [visible, setVisible] = useState(false);
  const checkEntitlement = () => {
    const _name = getCookie("first_name");
    const _email = getCookie("email_address");
    if (_email && /[^@]+@tines\.(io|com)/.test(_email)) {
      if (_name) setName(_name);
      setEmail(_email);
      setShouldRender(true);
    } else {
      setShouldRender(false);
    }
    if (!isTinesComBuildOnMain) setShouldRender(true);
    setVisible(getLocalStorageItem("enableAdminBar") === true);
  };
  const toggleVisibility = () => {
    setLocalStorageItem(
      "enableAdminBar",
      !getLocalStorageItem("enableAdminBar")
    );
    checkEntitlement();
  };
  useHotkeys(isMac ? "ctrl+a" : "ctrl+alt+a", toggleVisibility);
  useOnMount(checkEntitlement);
  return shouldRender && siteContext.isClient ? (
    <Toolbar className={cx(visible && "visible")}>
      <ToolbarToggle visible={visible} onPointerDown={toggleVisibility} />
      <ToolbarInner>
        <ToolbarSection className="padded" onClick={toggleVisibility}>
          <Heading>
            <TinesLogo11 />
            {displayName ? <p>Welcome, {displayName}</p> : <p>Tines</p>}
          </Heading>
        </ToolbarSection>
        <ToolbarSection>
          <GlobalGridOverlayAndControl />
        </ToolbarSection>
        <ToolbarSection id="adminBarContent" className="dynamic" />
      </ToolbarInner>
    </Toolbar>
  ) : null;
};

const Toggle = styled.button`
  display: block;
  appearance: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 13px;
  z-index: 1;
`;

const Chevron = styled.svg`
  position: absolute;
  top: 5px;
  left: 6px;
  transform: rotate(180deg);
  .visible & {
    transform: rotate(0);
  }
`;

const ToolbarToggle = (props: {
  visible: boolean;
  onPointerDown: () => void;
}) => (
  <Toggle onPointerDown={props.onPointerDown}>
    <svg width="28" height="13" viewBox="0 0 28 13" fill="none">
      <g clipPath="url(#clip0_8768_9665)">
        <mask id="path-1-inside-1_8768_9665" fill="white">
          <path d="M-1 0L14.3241 0C15.9958 0 17.557 0.835506 18.4843 2.2265L23.3359 9.50385C24.3755 11.0633 26.1258 12 28 12V12V13H-1L-1 0Z" />
        </mask>
        <path
          d="M-1 0L14.3241 0C15.9958 0 17.557 0.835506 18.4843 2.2265L23.3359 9.50385C24.3755 11.0633 26.1258 12 28 12V12V13H-1L-1 0Z"
          fill={withOpacity(colors.black, props.visible ? 0.8 : 1)}
        />
        <path
          d="M-1 0L-1 -1L-2 -1L-2 0L-1 0ZM28 13V14H29V13H28ZM-1 13L-2 13L-2 14H-1V13ZM28 12H29V11H28V12ZM-1 1L14.3241 1L14.3241 -1L-1 -1L-1 1ZM28 12H-1V14H28V12ZM0 13L0 0L-2 0L-2 13L0 13ZM17.6523 2.7812L22.5038 10.0585L24.168 8.94915L19.3164 1.6718L17.6523 2.7812ZM27 12V13H29V12H27ZM22.5038 10.0585C23.729 11.8962 25.7914 13 28 13V11C26.4601 11 25.0221 10.2304 24.168 8.94915L22.5038 10.0585ZM14.3241 1C15.6615 1 16.9104 1.6684 17.6523 2.7812L19.3164 1.6718C18.2036 0.00260705 16.3302 -1 14.3241 -1L14.3241 1Z"
          fill={withOpacity(colors.white, 0.1)}
          mask="url(#path-1-inside-1_8768_9665)"
        />
      </g>
      <defs>
        <clipPath id="clip0_8768_9665">
          <rect width="28" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <Chevron width="7" height="5" viewBox="0 0 7 5" fill="none">
      <path d="M1 1L3.5 3.5L6 1" stroke="white" />
    </Chevron>
  </Toggle>
);

export const AdminToolBarEditButton = (props: {
  label: ReactNode;
  action: () => void;
  shortcut?: string;
}) => {
  useHotkeys(props.shortcut ?? "command+shift+e", props.action);
  return (
    <AdminToolbarButton onClick={props.action}>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        stroke="currentColor"
      >
        <path d="M4.53571 3.57143L1.29289 6.81425C1.10536 7.00179 1 7.25614 1 7.52136V9C1 9.55228 1.44771 10 2 10H3.47864C3.74386 10 3.99821 9.89464 4.18575 9.70711L7.42857 6.46429M4.53571 3.57143L6.40004 1.70711C6.79056 1.31658 7.42373 1.31658 7.81425 1.70711L9.29289 3.18575C9.68342 3.57627 9.68342 4.20944 9.29289 4.59996L7.42857 6.46429M4.53571 3.57143L7.42857 6.46429" />
      </svg>
      <div>{props.label}</div>
    </AdminToolbarButton>
  );
};

export const AdminToolBarContent = (props: PropsWithChildren) => {
  const siteContext = useSiteContext();
  const portal = siteContext.isClient
    ? document.getElementById("adminBarContent")
    : null;
  return portal ? createPortal(props.children, portal) : null;
};

type EditInDatoCMSButtonProps = {
  itemType: DatoCMSItemTypeName;
  recordId?: string | null;
  shortcut?: string;
  label?: string;
};

export const EditInDatoCMSButton = (props: EditInDatoCMSButtonProps) => {
  const itemType = datoCmsItemTypes[props.itemType];
  const editAsAdmin = () => {
    window.open(
      `https://tines.admin.datocms.com/editor/item_types/${itemType.id}/items${
        props.recordId ? `/${props.recordId}` : ""
      }`
    );
  };
  return (
    <AdminToolBarEditButton
      label={
        props.label ??
        `${
          props.recordId
            ? `Edit this ${itemType.name}`
            : `View all ${itemType.plural}`
        } in CMS`
      }
      action={editAsAdmin}
      shortcut={props.shortcut}
    />
  );
};

export const AdminToolBarWithEditInDatoCmsButton = (
  props: EditInDatoCMSButtonProps
) => {
  return (
    <AdminToolBarContent>
      <EditInDatoCMSButton {...props} />
    </AdminToolBarContent>
  );
};

export const AdminToolbarStoryPageOptions = (props: {
  storyId: string;
  datoCmsRecordId: string;
}) => {
  const editInLibraryTenant = () => {
    window.open(
      `${process.env.GATSBY_LIBRARY_TENANT_URL}/stories/${props.storyId}/diagram`
    );
  };
  useHotkeys("command+shift+e", editInLibraryTenant);

  return (
    <AdminToolBarContent>
      <AdminToolbarButton onClick={editInLibraryTenant}>
        <TinesLogo11 />
        <span>Edit in library tenant</span>
      </AdminToolbarButton>
      <AdminToolbarSeparator />
      <EditInDatoCMSButton
        shortcut="command+control+shift+e"
        itemType="story"
        recordId={props.datoCmsRecordId}
      />
    </AdminToolBarContent>
  );
};

const TinesLogo11 = (props: { onClick?: () => void }) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
    onClick={props.onClick}
  >
    <path d="M4.05685 0C2.755 0 1.60392 0.844762 1.21431 2.08611L0.142822 5.5L1.21431 8.91389C1.60392 10.1552 2.755 11 4.05685 11H6.94308C8.24493 11 9.39601 10.1552 9.78562 8.91389L10.8571 5.5L9.78562 2.08611C9.39601 0.844762 8.24493 0 6.94308 0H4.05685ZM6.94312 0.858118C7.86973 0.858118 8.68902 1.45938 8.96632 2.34292L9.22902 3.1799C8.70055 2.74365 8.03134 2.49811 7.33189 2.49806L3.66023 2.49796C2.96549 2.49801 2.30058 2.74027 1.77379 3.17103L2.0337 2.34292C2.311 1.45938 3.13029 0.858118 4.0569 0.858118H6.94312ZM1.97199 4.19327L1.30481 5.07096L9.68723 5.07096L9.02013 4.19337C8.61922 3.66596 7.9946 3.35622 7.33183 3.35617H3.64023C2.98494 3.3624 2.36885 3.67119 1.97199 4.19327ZM9.68587 5.92908L9.02007 6.80587C8.61913 7.33387 7.99406 7.64396 7.3308 7.64391L3.66122 7.64382C2.99796 7.64387 2.37288 7.33377 1.97194 6.80577L1.30622 5.92908L9.68587 5.92908ZM2.03364 8.65708C2.31095 9.54062 3.13024 10.1419 4.05685 10.1419H6.94307C7.86968 10.1419 8.68896 9.54062 8.96627 8.65708L9.22916 7.81949C8.70045 8.25626 8.03073 8.50209 7.33074 8.50203L3.66128 8.50194C2.96596 8.50199 2.30051 8.25943 1.77346 7.82811L2.03364 8.65708Z" />
  </svg>
);

export default AdminToolbar;
