export const CasesPageAIPortalIcon = () => (
  <svg
    width="75"
    height="94"
    viewBox="0 0 75 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.6784 91.9997C30.2399 91.9997 25.0204 91.2447 25.0204 90.3133C25.0204 89.382 30.2399 88.627 36.6784 88.627C43.117 88.627 48.3364 89.382 48.3364 90.3133C48.3364 91.2447 43.117 91.9997 36.6784 91.9997Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.9014 24.6745C48.9012 25.8662 49.8674 26.8328 51.0596 26.8326L62.8421 26.8807C64.0343 26.8812 65.0007 25.9145 65.0005 24.7226C65.0005 23.5307 64.0341 22.5642 62.8424 22.5642L51.0598 22.5161C49.8674 22.5161 48.9012 23.4825 48.9014 24.6745Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.8405 32.1369C38.8405 33.3286 39.8067 34.2952 40.9989 34.2952L56.8304 34.3434C58.0226 34.3438 58.989 33.3772 58.9888 32.185C58.9888 30.9931 58.0223 30.0269 56.8304 30.0266L40.9989 29.9785C39.8067 29.9785 38.8405 30.9449 38.8405 32.1369Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.8122 42.4965C39.8122 44.9427 41.7952 46.9261 44.2421 46.9261H56.7349C59.1811 46.9261 61.1641 44.9427 61.1646 42.4968C61.1641 40.0504 59.1811 38.0672 56.7349 38.0674H44.2421C41.7952 38.0672 39.8122 40.0504 39.8122 42.4965Z"
      fill="#5EBC89"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M63.0018 34.5862C63.0018 36.0727 64.2071 37.2779 65.6938 37.2779H71.1132C72.5997 37.2779 73.8052 36.0729 73.8052 34.5862C73.8052 33.0995 72.6 31.894 71.113 31.894H65.6938C64.2071 31.894 63.0018 33.0993 63.0018 34.5862Z"
      fill="#FA975F"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.3312 54.93C49.3312 56.2811 50.4267 57.3764 51.7781 57.3764H60.3661C61.7175 57.3764 62.813 56.2813 62.813 54.93C62.813 53.5782 61.7175 52.4831 60.3661 52.4829H51.7781C50.4267 52.4829 49.3312 53.5782 49.3312 54.93Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.5683 15.9858C43.5683 17.9994 45.2003 19.6314 47.2138 19.6314H51.9953C54.0091 19.6314 55.6413 17.9994 55.6411 15.9858C55.6413 13.9718 54.0087 12.3398 51.9951 12.3398H47.2143C45.2005 12.3396 43.5683 13.9718 43.5683 15.9858Z"
      fill="#FBBD74"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.3102 62.9692C40.3102 64.9828 41.9424 66.6148 43.956 66.6148H48.7375C50.7511 66.6148 52.3835 64.9828 52.3833 62.9692C52.3835 60.9552 50.7509 59.3232 48.7373 59.3232H43.9562C41.9424 59.323 40.3102 60.9552 40.3102 62.9692Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.6102 2C34.0346 2 35.404 2.70604 36.6796 4.00254C37.9549 2.70559 39.3239 2.00023 40.7488 2.00023C47.6516 2 53.2466 18.5267 53.2466 38.9136C53.2466 59.3001 47.6516 75.827 40.7488 75.827C39.3239 75.827 37.9549 75.121 36.6796 73.8245C35.4038 75.1212 34.0349 75.8268 32.6102 75.827C25.7076 75.827 20.1119 59.3001 20.1121 38.9134C20.1119 18.5269 25.7074 2 32.6102 2Z"
      fill="#FBBD74"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.3772 68.6958C28.0764 68.6958 24.5898 55.3619 24.5898 38.9138C24.5898 22.4657 28.0764 9.13184 32.3772 9.13184C36.678 9.13184 40.1646 22.4657 40.1646 38.9138C40.1646 55.3619 36.678 68.6958 32.3772 68.6958Z"
      fill="#E49307"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.1646 38.9138C40.1646 50.6876 38.3781 60.8648 35.7853 65.6987C33.1925 60.8646 31.4056 50.6873 31.4058 38.914C31.4056 27.1405 33.1923 16.9632 35.7853 12.1289C38.3781 16.9632 40.1646 27.1403 40.1646 38.9138Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M26.8989 31.2109C23.4407 31.2109 20.6372 28.4074 20.6372 24.9492C20.6372 21.491 23.4407 18.6875 26.8989 18.6875C30.3572 18.6875 33.1606 21.491 33.1606 24.9492C33.1606 28.4074 30.3572 31.2109 26.8989 31.2109Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.6692 41.6802C16.5769 41.6802 14.8809 39.9841 14.8809 37.8918C14.8809 35.7996 16.5769 34.1035 18.6692 34.1035C20.7614 34.1035 22.4575 35.7996 22.4575 37.8918C22.4575 39.9841 20.7614 41.6802 18.6692 41.6802Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.5681 36.1367C39.1104 36.1365 39.6357 36.2089 40.1353 36.3431C40.1544 37.1902 40.1646 38.0473 40.1646 38.9134C40.1646 42.1153 40.0319 45.1987 39.787 48.0894C39.394 48.1702 38.9856 48.2129 38.5681 48.2131C35.2335 48.2129 32.5299 45.5095 32.5299 42.1749C32.5301 38.8397 35.2335 36.1365 38.5681 36.1367Z"
      fill="#5EBC89"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.3502 14.8823C28.3338 14.9347 28.3199 14.9805 28.3083 15.0191H34.8073L35.665 12.1526L33.2769 9.15142C32.4946 9.04537 31.7962 9.34177 31.1806 9.86446C30.5521 10.3981 30.0178 11.1616 29.584 11.949C29.1507 12.7353 28.8212 13.5387 28.5999 14.1464C28.4893 14.45 28.4059 14.7042 28.3502 14.8823Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="0.224853"
    />
    <path
      d="M32.0301 56.591C30.7262 56.591 29.6692 55.534 29.6692 54.2301C29.6692 52.9262 30.7262 51.8691 32.0301 51.8691C33.3341 51.8691 34.3911 52.9262 34.3911 54.2301C34.3911 55.534 33.3341 56.591 32.0301 56.591Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M20.8342 60.9889C19.4371 60.9889 18.3046 59.8563 18.3046 58.4593C18.3046 57.0622 19.4371 55.9297 20.8342 55.9297C22.2312 55.9297 23.3638 57.0622 23.3638 58.4593C23.3638 59.8563 22.2312 60.9889 20.8342 60.9889Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.062 31.1647L24.8733 34.5638C26.3279 34.474 27.3235 33.6396 27.9636 32.8062C28.2924 32.3781 28.5254 31.9525 28.6761 31.6338C28.7423 31.494 28.7925 31.3751 28.8279 31.2859C28.5992 31.3145 28.3046 31.3253 27.9847 31.3253C27.5599 31.3253 27.0805 31.3062 26.6308 31.2808C26.1809 31.2554 25.7597 31.2237 25.451 31.1983C25.2966 31.1857 25.1703 31.1746 25.0826 31.1666L25.062 31.1647Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="0.224853"
    />
    <path
      d="M30.8868 56.4594C30.8994 56.4894 30.9142 56.5227 30.9312 56.5588C31.0101 56.7257 31.1379 56.9509 31.3326 57.1822C31.7047 57.624 32.3236 58.0915 33.3253 58.2157L33.2383 56.8212L30.8868 56.4594Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="0.224853"
    />
    <path
      d="M23.8938 46.2591C23.6873 46.7826 23.0899 47.3798 22.5667 47.5858L20.3946 48.4427C19.8707 48.6495 19.8707 48.9877 20.3946 49.1937L22.5667 50.0508C23.0899 50.2568 23.6873 50.854 23.8938 51.3783L24.7507 53.55C24.9571 54.073 25.2951 54.073 25.5015 53.55L26.3582 51.3783C26.5648 50.854 27.1613 50.2568 27.6848 50.0508L29.8576 49.1937C30.3815 48.9877 30.3815 48.6495 29.8576 48.4427L27.6848 47.5858C27.1613 47.3798 26.5648 46.7826 26.3582 46.2591L25.5015 44.0866C25.2951 43.5636 24.9571 43.5636 24.7507 44.0866L23.8938 46.2591Z"
      fill="#FFD29F"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12.8678 25.6732C12.7158 26.0593 12.2756 26.4993 11.89 26.6508L10.2894 27.2825C9.9036 27.4345 9.9036 27.6838 10.2894 27.8358L11.89 28.467C12.2756 28.619 12.7158 29.0595 12.8678 29.4455L13.4992 31.0454C13.6512 31.431 13.9001 31.431 14.0524 31.0454L14.6835 29.4455C14.8358 29.0595 15.2754 28.619 15.661 28.467L17.2617 27.8358C17.6478 27.6838 17.6478 27.4345 17.2617 27.2825L15.661 26.6508C15.2754 26.4993 14.8358 26.0593 14.6835 25.6732L14.0524 24.0729C13.9001 23.6873 13.6512 23.6873 13.4992 24.0729L12.8678 25.6732Z"
      fill="#FBBD74"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
