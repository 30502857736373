import { styled } from "@linaria/react";
import { useSiteContext } from "../../context/site.context";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { getGreenhouseJobIdFromCurrentLocation } from "../../utils/greenhouse.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import GreenhouseEmbed from "../general/GreenhouseEmbed";
import TinesLogo from "../misc/TinesLogo";
import Modal, { ModalCloseButton } from "./Modal";
import { APPLY_FOR_JOB_MODAL_NAME } from "./modalNames";

const JobListingModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em calc(${rSize("md")} + 8px);
  background-color: ${colorsV4.canvas550};
  position: sticky;
  top: 0;
  z-index: 1;
  ${fromTabletMd} {
    padding: 2em calc(${rSize("md")} + 8px);
    .TinesLogo {
      width: 13.4rem;
      height: 4.2rem;
    }
  }
`;

const JobListingModalContent = styled.div`
  padding: 0 ${rSize("md")};
  #grnhse_app {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
`;

/* TODO: provide a proper document title when the dialog is open */
const ApplyForJobModal = () => {
  const siteContext = useSiteContext();
  const closeSelf = () => {
    siteContext.openModal(null);
  };
  useOnMount(() => {
    if (!getGreenhouseJobIdFromCurrentLocation()) {
      closeSelf();
    }
  });

  return (
    <Modal
      name={APPLY_FOR_JOB_MODAL_NAME}
      width="600px"
      height="1024px"
      borderRadius="1em"
      clickOnBackdropShouldCloseModal={false}
    >
      <JobListingModalHeader>
        <TinesLogo />
        <ModalCloseButton
          backgroundColor={colorsV4.canvas700}
          iconColor={colorsV4.warmBlack}
        />
      </JobListingModalHeader>
      <JobListingModalContent>
        <GreenhouseEmbed />
      </JobListingModalContent>
    </Modal>
  );
};

export default ApplyForJobModal;
