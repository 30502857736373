import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import photoDee from "../../images/tines-dee-morgan.jpg";
import {
  fromDesktop,
  fromDesktopMd,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { DatoCmsPerson } from "../../../graphql-types";
import AvoidWidows from "../typography/AvoidWidows";
import Button from "../forms/Button";
import ComboFontHeading from "../typography/ComboFontHeading";
import PersonInfoItem from "../general/PersonInfoItem";
import PageSection from "./PageSection";
import { PartialAndNullable } from "../../types/helper.types";

const InsideTinesSectionInner = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  width: 100%;
  ${uptoTabletMd} {
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
  }
  ${fromTabletMd} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-auto-flow: column;
  }
  ${fromDesktopMd} {
    font-size: 1.8rem;
  }
`;

const HeaderCard = styled.header`
  background-color: ${colors.purple};
  color: ${colors.white};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  > * {
    + * {
      margin-top: 1.5em;
    }
  }
  p {
    ${fromDesktop} {
      font-size: 2rem;
    }
  }
`;

const InterviewCardOuter = styled.div`
  position: relative;
  border-radius: ${rSize("radius")};
  border: 1px solid ${colors.light400};
  background-color: ${colors.white};
  color: ${colors.dark500};
`;

const InsideTinesSection = () => {
  return (
    <PageSection id="inside-tines">
      <InsideTinesSectionInner>
        <HeaderCard>
          <ComboFontHeading
            snippets={[
              [
                <>
                  Inside Tines
                  <br />
                </>,
                "sans",
              ],
              ["Meet our team", "serif"],
            ]}
            className={css`
              ${fluidFontSize(38, 52)}
            `}
          />
          <p>
            Get to know some of the people building Tines and learn more about
            our company culture.
          </p>
          <Button
            to="/blog/inside-tines"
            title="Explore all interviews"
            appearance="filled-white"
          >
            Explore all interviews
          </Button>
        </HeaderCard>
        <InterviewCardOuter>
          <InterviewCard
            person={{
              name: "Dee",
              surname: "Morgan",
              title: "Product Designer",
              photoIcon: {
                url: photoDee,
              } as unknown as DatoCmsPerson,
            }}
            content="As a product designer at Tines, Dee Morgan is, above all else, a problem solver. She ensures our automation platform meets the needs and exceeds the expectations of existing and potential customers."
            link="/blog/five-questions-with-dee-morgan-product-designer-at-tines"
          />
        </InterviewCardOuter>
      </InsideTinesSectionInner>
    </PageSection>
  );
};

const InterviewCardContainer = styled.div`
  padding: ${rSize("cardSectionPadding", 0.75)};
  padding-bottom: 6.5em;
  > * {
    + * {
      margin-top: 1em;
    }
  }
`;

const textLinkStyle = css`
  display: inline-block;
  font-weight: 600;
  color: ${colors.purple};
  margin-top: 1em;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const InterviewCard = (props: {
  person: PartialAndNullable<DatoCmsPerson>;
  content: string;
  link?: string;
}) => (
  <InterviewCardContainer>
    <PersonInfoItem useAccentColorOnName person={props.person} />
    <AvoidWidows>{props.content}</AvoidWidows>
    {props.link && (
      <Link to={props.link} className={textLinkStyle}>
        Read more →
      </Link>
    )}
  </InterviewCardContainer>
);

export default InsideTinesSection;
