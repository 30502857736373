import { styled } from "@linaria/react";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { makeSrcSet2x } from "../../../utils/srcset.utils";
import demoAvatars from "../../../../static/images/demo-avatars.png";
import demoAvatars2x from "../../../../static/images/demo-avatars@2x.png";
import { DemoTextPlaceholder } from "./DemoTextPlaceholder";
import {
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  uptoPhoneLg,
  uptoTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import FromPhoneLg from "../../mediaQueries/FromPhoneLg";
import UptoTablet, { FromTablet } from "../../mediaQueries/UptoTablet";

const DemoHeaderWrap = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr);
  ${fromTabletLg} {
    grid-template-columns: minmax(auto, 1fr) minmax(0, 1fr) minmax(auto, 1fr);
  }
  width: 100%;
  padding: 0.75em;
  border-radius: 0.5em 0.5em 0 0;
  background-color: ${colorsV4.white};
  border-bottom: 1px solid ${colorsV4.canvas550};
  > * {
    display: flex;
    align-items: center;
    > * {
      + * {
        margin-left: 0.3em;
      }
    }
  }
  svg,
  img {
    display: block;
  }
`;

const DemoHeaderStart = styled.div`
  text-align: left;
  padding-left: 0.25em;
  ${FromTablet} {
    ${fromTablet} {
      display: flex;
      align-items: center;
    }
  }
  ${UptoTablet} {
    ${uptoTablet} {
      display: flex;
      align-items: center;
    }
  }
`;

const DemoHeaderMiddle = styled.div`
  display: none;
  text-align: center;
  justify-content: center;
  ${fromTabletLg} {
    display: flex;
    align-items: center;
  }
`;

const DemoHeaderEnd = styled.div`
  text-align: right;
  justify-content: flex-end;
  ${FromPhoneLg} {
    ${fromPhoneLg} {
      display: flex;
      align-items: center;
      > * {
        + * {
          margin-left: 0.3em;
        }
      }
    }
  }
`;

const DemoHeader = () => {
  return (
    <DemoHeaderWrap>
      <DemoHeaderStart>
        <DemoTinesIcon />
        <DemoTextPlaceholder width="4em" />
        <UptoTablet>
          <DemoTextPlaceholder width="3em" />
        </UptoTablet>
        <FromTablet>
          <DemoTextPlaceholder width="6em" />
        </FromTablet>
      </DemoHeaderStart>
      <DemoHeaderMiddle>
        <DemoTextPlaceholder width="4em" />
        <DemoTextPlaceholder width="3em" />
        <DemoTextPlaceholder width="5em" />
      </DemoHeaderMiddle>
      <DemoHeaderEnd>
        <DemoAvatarSetImg
          src={demoAvatars}
          srcSet={makeSrcSet2x(demoAvatars, demoAvatars2x)}
        />
        <FromPhoneLg>
          <DemoHeaderEndSeparator />
          <DemoHeaderRightFakeButtons />
        </FromPhoneLg>
      </DemoHeaderEnd>
    </DemoHeaderWrap>
  );
};

const DemoTinesIconSvg = styled.svg`
  ${uptoPhoneLg} {
    margin-left: 0.05em;
    margin-right: 1em;
  }
`;

const DemoTinesIcon = () => (
  <DemoTinesIconSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.97964 0.5C4.15705 0.5 2.54554 1.65195 2.00008 3.34469L0.5 8L2.00008 12.6553C2.54554 14.3481 4.15705 15.5 5.97964 15.5H10.0204C11.843 15.5 13.4545 14.3481 13.9999 12.6553L15.5 8L13.9999 3.34469C13.4545 1.65195 11.843 0.5 10.0204 0.5H5.97964ZM10.0204 1.67016C11.3177 1.67016 12.4647 2.49007 12.8529 3.69489L13.2207 4.83623C12.4808 4.24135 11.5439 3.90651 10.5647 3.90644L5.42436 3.90631C4.45173 3.90638 3.52086 4.23673 2.78335 4.82413L3.14722 3.69489C3.53545 2.49007 4.68246 1.67016 5.9797 1.67016H10.0204ZM3.06083 6.2181L2.12679 7.41495L13.8622 7.41495L12.9282 6.21823C12.367 5.49904 11.4925 5.07667 10.5646 5.0766H5.39638C4.47896 5.08509 3.61644 5.50617 3.06083 6.2181ZM13.8603 8.58511L12.9281 9.78073C12.3668 10.5007 11.4917 10.9236 10.5632 10.9235L5.42575 10.9234C4.49719 10.9235 3.62209 10.5006 3.06077 9.7806L2.12876 8.58511L13.8603 8.58511ZM3.14715 12.3051C3.53538 13.5099 4.68238 14.3298 5.97963 14.3298H10.0203C11.3176 14.3298 12.4646 13.5099 12.8528 12.3051L13.2209 11.1629C12.4807 11.7585 11.5431 12.0938 10.5631 12.0937L5.42584 12.0935C4.4524 12.0936 3.52077 11.7629 2.7829 11.1747L3.14715 12.3051Z"
    />
  </DemoTinesIconSvg>
);

const DemoAvatarSetImg = styled.img`
  width: 86px;
  height: 26px;
`;

const DemoHeaderEndSeparator = () => (
  <svg width="21" height="28" viewBox="0 0 21 28" fill="none">
    <rect width="1" height="14" transform="translate(10 7)" fill="#EEEEEE" />
  </svg>
);

const DemoHeaderRightFakeButtons = () => (
  <svg width="121" height="30" viewBox="0 0 121 30" fill="none">
    <rect width="83" height="30" rx="6" fill="#F9F9F9" />
    <rect x="11" y="8" width="45" height="14" rx="2" fill="#EEEEEE" />
    <path
      opacity="0.3"
      d="M66.5 14L69.5 17L72.5 14"
      stroke="#555555"
      strokeWidth="0.999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="91" width="30" height="30" rx="6" fill="#F9F9F9" />
    <g opacity="0.3">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.5 10.75C106.5 11.1625 106.163 11.5 105.75 11.5C105.33 11.5 105 11.1625 105 10.75C105 10.33 105.33 10 105.75 10C106.163 10 106.5 10.33 106.5 10.75ZM106.5 14.75C106.5 15.1625 106.163 15.5 105.75 15.5C105.33 15.5 105 15.1625 105 14.75C105 14.33 105.33 14 105.75 14C106.163 14 106.5 14.33 106.5 14.75ZM105.75 19.5C106.163 19.5 106.5 19.1625 106.5 18.75C106.5 18.33 106.163 18 105.75 18C105.33 18 105 18.33 105 18.75C105 19.1625 105.33 19.5 105.75 19.5Z"
        fill="#555555"
      />
    </g>
  </svg>
);

export default DemoHeader;
