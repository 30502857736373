import Point, { XY } from "../../../utils/geometry.utils";
import { GRID_SIZE } from "./storyboard.utils";

const maxCurveFraction = 0.5;
const skew = 0.01; // avoid drawing perfectly straight line, which breaks the SVG gradient

const pointFrom = (p1: XY, p2: XY) => {
  const offset = Point.subtract(p2, p1);
  const distance = Math.sqrt(offset.x ** 2 + offset.y ** 2);
  const fraction = Math.min(
    distance === 0 ? 1 : GRID_SIZE / distance,
    maxCurveFraction
  );
  return Point.round(Point.add(p1, Point.multiply(offset, fraction)), 2);
};

const drawLinkSvgPath = (from: XY, to: XY) => {
  const x1 = from.x;
  const y1 = from.y;

  const x2 = to.x + skew;
  const y2 = to.y + skew;

  const isUpward = y1 < y2;

  // extra horizontal sections, in the case where links flow upwards
  const parallelDistance = (xa: number, xb: number) =>
    (isUpward ? 0 : xb - xa >= GRID_SIZE * 5 ? 1 : -1) * GRID_SIZE * 2;

  const points = Point.uniq([
    { x: x1, y: y1 },
    { x: x1, y: y1 + GRID_SIZE },
    { x: x1 + parallelDistance(x1, x2), y: y1 + GRID_SIZE },
    { x: x2 + parallelDistance(x2, x1), y: y2 - GRID_SIZE },
    { x: x2, y: y2 - GRID_SIZE },
    { x: x2, y: y2 },
  ]);

  return points
    .flatMap((p1, index) => {
      const p2 = points[index + 1];
      const isLast = !p2;
      if (isLast) return [`L ${p1.x} ${p1.y}`];
      // draw a straight line to the last point

      const nearP2 = pointFrom(p2, p1);
      const nearP1 = pointFrom(p1, p2);
      const isFirst = index === 0;
      if (isFirst) return `M ${p1.x} ${p1.y} L ${nearP2.x} ${nearP2.y}`;
      // draw a straight line from the first point

      return [
        `C ${p1.x} ${p1.y} ${p1.x} ${p1.y} ${nearP1.x} ${nearP1.y}`,
        `L ${nearP2.x} ${nearP2.y}`,
      ];
      // draw a bezier curve around the intermediate point
    })
    .join("");
};

export default drawLinkSvgPath;
