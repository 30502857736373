import ReactMarkdown from "react-markdown";
import { DatoCmsQuoteWithDataPointSection } from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import { styled } from "@linaria/react";
import { getWidthPxInMaxGrid } from "../../../constants/globalGrid.constants";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  uptoTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import {
  brandColorThemeVar,
  colors,
  getBrandColorThemeVariables,
} from "../../../styles/colors.styles";
import { serif } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { BlendedDarkGreyLogoImage } from "../../misc/BlendedDarkGreyLogoImage";
import WithCustomInlineFormatting from "../../typography/WithCustomInlineFormatting";

const Ribbon = styled.div`
  display: grid;
  color: ${brandColorThemeVar(700)};
  ${uptoTabletLg} {
    text-align: center;
  }
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletLg} {
    grid-template-columns: ${getWidthPxInMaxGrid(4)} minmax(0, 1fr);
  }
`;

const RibbonHeader = styled.div`
  position: relative;
  background-color: ${brandColorThemeVar(50)};
  border: 1px solid ${brandColorThemeVar(300)};
  font-size: 1.4rem;
  overflow: hidden;
  border-top-left-radius: ${rSize("radius")};
  ${uptoTabletLg} {
    border-top-right-radius: ${rSize("radius")};
  }
  ${fromTabletLg} {
    padding-right: 7.1rem;
    transform: translateY(-24px);
    border-bottom-left-radius: ${rSize("radius")};
    display: grid;
    grid-template-rows: minmax(auto, 1fr) auto;
  }

  ${fromTabletLg} {
    &:after {
      content: "";
      display: block;
      background-image: ${`url("data:image/svg+xml,%3Csvg width='71' height='23' viewBox='0 0 71 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71 23H0L71 0V23Z' fill='${encodeURIComponent(
        colors.purple300
      )}'/%3E%3C/svg%3E%0A")`};
      [data-theme="Green"] & {
        background-image: ${`url("data:image/svg+xml,%3Csvg width='71' height='23' viewBox='0 0 71 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71 23H0L71 0V23Z' fill='${encodeURIComponent(
          colors.green300
        )}'/%3E%3C/svg%3E%0A")`};
      }
      [data-theme="Orange"] & {
        background-image: ${`url("data:image/svg+xml,%3Csvg width='71' height='23' viewBox='0 0 71 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71 23H0L71 0V23Z' fill='${encodeURIComponent(
          colors.orange300
        )}'/%3E%3C/svg%3E%0A")`};
      }
      [data-theme="Pink"] & {
        background-image: ${`url("data:image/svg+xml,%3Csvg width='71' height='23' viewBox='0 0 71 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71 23H0L71 0V23Z' fill='${encodeURIComponent(
          colors.pink300
        )}'/%3E%3C/svg%3E%0A")`};
      }
      width: 7.1rem;
      height: 2.3rem;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const RibbonHeaderStart = styled.div`
  padding: 2.8rem;
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
  h2 {
    font-size: 2.8rem;
    font-family: ${serif};
    font-weight: 400;
    line-height: 1.1;
  }
`;

const RibbonHeaderBody = styled.div`
  p,
  li {
    font-weight: 500;
    line-height: 1.3;
  }
  ul {
    padding-left: 1.5em;
    text-align: left;
    ${uptoTabletLg} {
      background-color: ${brandColorThemeVar(100)};
      padding: 1em 1em 1em 2em;
      border-radius: 0.5em;
      border: 1px solid ${brandColorThemeVar(200)};
      max-width: 29em;
      margin-left: auto;
      margin-right: auto;
    }
    li + li {
      margin-top: 0.5em;
    }
  }
`;

const RibbonHeaderEnd = styled.div`
  background-color: ${brandColorThemeVar(100)};
  font-size: 1.8rem;
  padding: 2.8rem;
  line-height: 1.2;
  font-weight: 600;
  ${fromTabletLg} {
    font-family: ${serif};
    font-weight: 400;
    font-size: 2.2rem;
  }
`;

const DataPointIcon = styled.div`
  background-color: currentColor;
  width: 1.2em;
  height: 1.2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 0.25em;
  svg {
    display: block;
    color: ${colors.white};
    width: 0.66em;
    height: 0.66em;
  }
`;

const RibbonContent = styled.div`
  position: relative;
  background-color: ${brandColorThemeVar(50)};
  border: 1px solid ${brandColorThemeVar(300)};
  padding: ${rSize("xl")} ${rSize("lg")};
  min-height: 40rem;
  border-bottom-right-radius: ${rSize("radius")};
  ${uptoTabletLg} {
    border-bottom-left-radius: ${rSize("radius")};
    border-top: 0;
  }
  ${fromTabletLg} {
    margin-left: -7.2rem;
    border-top-right-radius: ${rSize("radius")};
  }
`;

const Blockquote = styled.blockquote`
  max-width: ${getWidthPxInMaxGrid(8)};
  margin: ${rSize("md")} 0;
  padding: 0;
  font-size: 2rem;
  letter-spacing: -0.01em;
  line-height: 1.3;
  font-family: ${serif};
  ${fromTablet} {
    font-size: 2rem;
  }
  ${fromTabletLg} {
    font-size: 2.4rem;
  }
  ${fromDesktopMd} {
    font-size: 3rem;
    line-height: 1.25;
  }
  p {
    font: inherit;
    margin: 0;
  }
`;

const Source = styled.figcaption`
  position: relative;
  display: grid;
  font-size: 1.4rem;
  line-height: 1.2;
`;

const SourceName = styled.p`
  margin: 0;
`;
const SourceSubheading = styled.p`
  margin: 0;
`;
const LogoWrap = styled.div`
  margin-top: 1em;
  ${fromTabletLg} {
    text-align: left;
  }
  img {
    max-height: 32px;
  }
`;

export const LPQuoteWithDataPointSection = ({
  section,
}: {
  section: DatoCmsQuoteWithDataPointSection;
}) => {
  const logo = section.quote?.sourceOrganization?.logoDarkGray;
  return (
    <PageSection
      id={section.anchorOnPage}
      style={getBrandColorThemeVariables(section.color)}
      data-theme={section.color}
    >
      <Ribbon>
        <RibbonHeader>
          <RibbonHeaderStart>
            <WithCustomInlineFormatting as="h2">
              {section.heading}
            </WithCustomInlineFormatting>
            {section.body && (
              <RibbonHeaderBody>
                <ReactMarkdown>{section.body}</ReactMarkdown>
              </RibbonHeaderBody>
            )}
          </RibbonHeaderStart>
          {section.dataPointText && (
            <RibbonHeaderEnd>
              <DataPointIcon>
                {section.dataPointIcon === "arrow-down" ? (
                  <ArrowDown />
                ) : (
                  <ArrowUp />
                )}
              </DataPointIcon>
              {section.dataPointText}
            </RibbonHeaderEnd>
          )}
        </RibbonHeader>
        <RibbonContent>
          {section.quote && (
            <>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="currentColor"
              >
                <rect width="40" height="40" rx="20" />
                <path
                  d="M19.2401 18.042H18.8298C18.0912 18.042 17.6535 18.5966 17.6535 19.6807V22H15V19.5042C15 17.2857 16.3404 16 18.5562 16H19.2401V18.042ZM24 18.042H23.5897C22.8237 18.042 22.386 18.5966 22.386 19.6807V22H19.7325V19.5042C19.7325 17.2857 21.0729 16 23.3161 16H24V18.042Z"
                  fill="white"
                />
              </svg>
              <Blockquote>{section.quote?.body}</Blockquote>
              <Source>
                <SourceName>
                  <strong>{section.quote?.sourceName}</strong>
                </SourceName>
                <SourceSubheading>
                  {section.quote?.sourceSubheading}
                </SourceSubheading>
                {logo?.url && (
                  <LogoWrap>
                    <BlendedDarkGreyLogoImage
                      className="logo"
                      alt={`${
                        section.quote?.sourceOrganization?.name ?? ""
                      } Logo`}
                      src={logo.url}
                      width={logo.width}
                      height={logo.height}
                    />
                  </LogoWrap>
                )}
              </Source>
            </>
          )}
        </RibbonContent>
      </Ribbon>
    </PageSection>
  );
};

const ArrowDown = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="currentColor">
    <path d="M6.7291 0V10.9967L10.9565 6.75585L12 7.78595L5.99331 13.7793L0 7.78595L1.01672 6.75585L5.25753 10.9967V0H6.7291Z" />
  </svg>
);

const ArrowUp = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="currentColor">
    <path d="M6.7291 14V3.00334L10.9565 7.24415L12 6.21405L5.99331 0.220736L0 6.21405L1.01672 7.24415L5.25753 3.00334V14H6.7291Z" />
  </svg>
);
