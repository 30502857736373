import { UseStateReturnType } from "../../types/helper.types";
import TextInput, { TextInputSet } from "./TextInput";

type Props<T extends {}> = {
  inputBackgroundColor?: string;
  formState: UseStateReturnType<T>;
  streetFieldPlaceholder?: string | null;
};

function USAddressFieldSet<T extends {}>(props: Props<T>) {
  return (
    <TextInputSet>
      <TextInput
        backgroundColor={props.inputBackgroundColor}
        name="Street"
        formState={props.formState}
        required
        placeholder={`${props.streetFieldPlaceholder ?? "Company address"} *`}
      />
      <TextInput
        backgroundColor={props.inputBackgroundColor}
        name="City"
        formState={props.formState}
        required
        placeholder="City *"
      />
      <TextInput
        backgroundColor={props.inputBackgroundColor}
        name="State"
        formState={props.formState}
        required
        placeholder="State *"
      />
      <TextInput
        backgroundColor={props.inputBackgroundColor}
        name="Zip Code"
        formState={props.formState}
        required
        placeholder="ZIP Code *"
      />
    </TextInputSet>
  );
}

export default USAddressFieldSet;
