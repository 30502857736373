import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  fromTabletLg,
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import {
  CommonColorNameV4,
  colorV4,
  withOpacity,
} from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import PageSection from "../../reusableSections/PageSection";
import Spacing from "../../layout/Spacing";

type Props = {};

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.8rem;
  ${fromTabletLg} {
    font-size: 3.8rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const FeatureCardDiv = styled.div<{ color: CommonColorNameV4 }>`
  background-color: ${p => withOpacity(p.color, 0.1)};
  padding: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  text-align: left;
  font-weight: 500;
  ${uptoTabletMd} {
    text-align: center;
  }
  svg {
    margin-bottom: 0.5em;
    ${uptoTabletMd} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  h3 {
    color: ${p => colorV4(p.color, 700)};
    margin-bottom: 0.25em;
  }
`;

const FeatureCard = (
  props: PropsWithChildren<{ color: CommonColorNameV4 }>
) => {
  return <FeatureCardDiv color={props.color}>{props.children}</FeatureCardDiv>;
};

const AWSPageFeaturesSection = (props: Props) => {
  return (
    <PageSection>
      <SectionTitle>Tines allows AWS customers to...</SectionTitle>
      <Spacing size="xl" />
      <Grid>
        <FeatureCard color="pink">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <path
              d="M0 6.42823C0 2.87802 2.87802 0 6.42823 0H25.5718C29.122 0 32 2.87802 32 6.42823V25.5718C32 29.122 29.122 32 25.5718 32H6.42823C2.87802 32 0 29.122 0 25.5718V6.42823Z"
              fill="#FEE2EE"
            />
            <rect
              x="6.6665"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#CE6492"
            />
            <rect
              x="10"
              y="13.3333"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#F48EBA"
            />
            <rect
              x="13.3335"
              y="6.66675"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#FDB2D2"
            />
            <rect
              x="20"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#CE6492"
            />
            <rect
              x="13.3335"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#CE6492"
            />
            <rect
              x="16.6665"
              y="13.3333"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#F48EBA"
            />
          </svg>

          <h3>Automate</h3>
          <p>security alerts management</p>
        </FeatureCard>
        <FeatureCard color="orange">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
              fill="#FFE4D9"
            />
            <rect
              x="6.66675"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#FBB598"
            />
            <rect
              x="6.66675"
              y="13.3333"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#F69267"
            />
            <rect
              x="13.3333"
              y="13.3333"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#F69267"
            />
            <rect
              x="20"
              y="13.3333"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#F69267"
            />
            <rect
              x="13.3333"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#FBB598"
            />
            <rect
              x="6.66675"
              y="6.66675"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#D4744B"
            />
            <rect
              x="13.3333"
              y="6.66675"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#D4744B"
            />
            <rect
              x="20"
              y="6.66675"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#D4744B"
            />
            <rect
              x="20"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#FBB598"
            />
          </svg>

          <h3>Streamline</h3>
          <p>incident response</p>
        </FeatureCard>
        <FeatureCard color="purple">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
              fill="#E5E2F9"
            />
            <rect
              x="6.66669"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#B0A6F9"
            />
            <rect
              x="13.3333"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#8578E6"
            />
            <rect
              x="13.3333"
              y="13.3333"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#8578E6"
            />
            <rect
              x="20"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#665DAC"
            />
            <rect
              x="20"
              y="13.3333"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#665DAC"
            />
            <rect
              x="20"
              y="6.66666"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#665DAC"
            />
            <rect
              x="6.66669"
              y="20"
              width="5.33333"
              height="5.33333"
              rx="1.33333"
              fill="#B0A6F9"
            />
            <rect
              x="13.3333"
              y="14.6667"
              width="5.33333"
              height="10.6667"
              rx="1.33333"
              fill="#8578E6"
            />
            <rect
              x="20"
              y="6.66666"
              width="5.33333"
              height="18.6667"
              rx="1.33333"
              fill="#665DAC"
            />
          </svg>
          <h3>Improve</h3>
          <p>accuracy and remediation timelines</p>
        </FeatureCard>
      </Grid>
    </PageSection>
  );
};

export default AWSPageFeaturesSection;
