import { styled } from "@linaria/react";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";

export const ReportV2Heading5 = styled.h4`
  font-size: 1.8rem !important;
  ${fromTabletLg} {
    font-size: 2rem !important;
  }
  line-height: 1.1;
  letter-spacing: -0.01em;
  * + & {
    margin-top: 1.2rem;
  }
  + * {
    margin-top: 1.2rem;
  }
  span {
    display: block;
  }
`;
