import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTablet,
  mediaFromTo,
  onlyPhones,
  uptoDesktopMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import { SectionHeading2 } from "../typography/SectionHeading2";
import {
  getWidthPxInMaxGrid,
  gridGapPx,
  widthInGrid,
} from "../../constants/globalGrid.constants";
import PageSection from "../reusableSections/PageSection";
import trophySpotIcon from "./trophySpotIcon.svg";
import { Link } from "@reach/router";
import { Paths } from "../../utils/pathBuilders.utils";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import { serif } from "../../styles/fonts.styles";

const Positioner = styled.div`
  ${uptoDesktopMd} {
    margin-bottom: 1.5em;
  }
  ${fromDesktopMd} {
    margin-left: ${gridGapPx(-2)};
    margin-right: ${gridGapPx(-2)};
  }
`;

const Wrap = styled.div`
  position: relative;
  ${fromDesktopMd} {
    display: grid;
    grid-template-columns: minmax(auto, 5fr) minmax(
        auto,
        calc(${getWidthPxInMaxGrid(7)} + ${gridGapPx(3)})
      );
  }
`;

const Header = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${gridGapPx(2)};
  z-index: 1;
  ${uptoDesktopMd} {
    text-align: center;
  }
  ${fromDesktopMd} {
    color: ${colors.orange800};
    background-color: ${colors.orange50};
    border: 1px solid ${colors.orange200};
    margin-right: -1px;
    margin-bottom: 47px;
    border-radius: 8px 0 0 8px;
    padding: ${gridGapPx(2)};
    &:after {
      content: "";
      display: block;
      /** cspell:disable */
      background-image: ${`url("data:image/svg+xml,%3Csvg width='72' height='47' viewBox='0 0 72 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1603_1373)'%3E%3Cpath d='M0.5 0.5V46.5H0.85753L71.5 0.726562V0.5H0.5Z' fill='${encodeURIComponent(
        colors.orange200
      )}' stroke='${encodeURIComponent(
        colors.orange200
      )}'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1603_1373'%3E%3Crect width='72' height='47' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")`};
      /** cspell:enable */
      width: 72px;
      height: 47px;
      position: absolute;
      top: 100%;
      right: 0;
    }
  }
`;

const IconWrap = styled.div``;

const HeaderContent = styled.div`
  ${mediaFromTo("tablet", "desktopMd")} {
    margin-bottom: 3em;
  }
  h2 {
    ${fromDesktopMd} {
      max-width: 8em;
    }
  }
  > * + * {
    margin-top: ${rSize("gap")};
  }
  > p + p {
    margin-top: 0.75em;
  }
  p {
    ${onlyPhones} {
      max-width: 20em;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Content = styled.div`
  background-color: ${colors.orange50};
  color: ${colors.orange800};
  border: 1px solid ${colors.orange200};
  overflow: hidden;
  padding: ${rSize("lg")};
  ${onlyPhones} {
    margin-top: 2em;
    border-radius: ${rSize("radius")};
  }
  ${fromTablet} {
    border-radius: 8px;
  }
  ${fromDesktopMd} {
    margin-top: 47px;
    margin-left: -72px;
    border-radius: 0 8px 8px 0;
    padding-left: ${widthInGrid(1, 2)};
    padding-right: ${gridGapPx(3)};
    padding-top: ${rSize("sectionPadding")};
    padding-bottom: ${rSize("sectionPadding")};
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("lg")} ${rSize("gap")};
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
`;

const SupportOption = styled.div`
  display: grid;
  grid-gap: 0.75em;
  grid-template-columns: auto minmax(0, 1fr);
`;

const SupportOptionIcon = styled.div``;
const SupportOptionTextContent = styled.div`
  > * {
    + * {
      margin-top: 1rem;
    }
  }
  h3 {
    font-size: 2.2rem;
    font-family: ${serif};
    font-weight: 400;
    margin-top: 0.4em;
  }
  p {
    font-size: 1.4rem;
    max-width: 16em;
    line-height: 1.4;
    a {
      ${inParagraphLinkStyle("currentColor")}
    }
  }
`;

export const EnterpriseSupportOptionsSection = () => {
  return (
    <PageSection>
      <Positioner>
        <Wrap>
          <Header>
            <IconWrap>
              <img src={trophySpotIcon} width={82} height={90} />
            </IconWrap>
            <HeaderContent>
              <SectionHeading2 lighter>
                With you every step of the way
              </SectionHeading2>
              <p>
                Our industry-leading team works with you and your business to
                reach your goals. Tines is consistently recognized for our level
                of service by enterprise organizations.
              </p>
            </HeaderContent>
          </Header>
          <Content>
            <ContentGrid>
              <SupportOption>
                <SupportOptionIcon>
                  <BellIcon />
                </SupportOptionIcon>
                <SupportOptionTextContent>
                  <h3>Professional services</h3>
                  <p>
                    For when you need additional support, our team and partners
                    are here to accelerate and increase the value you get from
                    Tines.
                  </p>
                  <p>
                    <Link to={Paths.professionalServices()}>Learn more →</Link>
                  </p>
                </SupportOptionTextContent>
              </SupportOption>
              <SupportOption>
                <SupportOptionIcon>
                  <BlueHandIcon />
                </SupportOptionIcon>
                <SupportOptionTextContent>
                  <h3>Enterprise-grade support</h3>
                  <p>
                    Our best-in-class team is with you every step of the way,
                    across regions and time zones.
                  </p>
                  <p>
                    <Link to={Paths.supportReferenceGuide()}>Learn more →</Link>
                  </p>
                </SupportOptionTextContent>
              </SupportOption>
              <SupportOption>
                <SupportOptionIcon>
                  <UniIcon />
                </SupportOptionIcon>
                <SupportOptionTextContent>
                  <h3>University</h3>
                  <p>
                    Onboard new teams to Tines with our University program to
                    realize value quickly.
                  </p>
                  <p>
                    <Link to={Paths.university()}>Start learning →</Link>
                  </p>
                </SupportOptionTextContent>
              </SupportOption>
              <SupportOption>
                <SupportOptionIcon>
                  <MachineArmIcon />
                </SupportOptionIcon>
                <SupportOptionTextContent>
                  <h3>Technical resources</h3>
                  <p>
                    Content to support every stage with technical docs, how-to
                    articles that guide your team, and even best practices.
                  </p>
                  <p>
                    <Link to={Paths.docs()}>Read the docs →</Link>
                  </p>
                </SupportOptionTextContent>
              </SupportOption>
            </ContentGrid>
          </Content>
        </Wrap>
      </Positioner>
    </PageSection>
  );
};

const BellIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    strokeWidth="1.2"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M7.375 15.2651C7.375 15.2651 9.05489 12.9391 11.592 11.4939"
      stroke="#D67C56"
    />
    <path
      d="M3.64062 13.6754C3.64062 13.6754 6.16368 9.83577 11.4405 7.3335"
      stroke="#D67C56"
    />
    <path
      d="M34.2639 15.2651C34.2639 15.2651 32.5838 12.9391 30.0469 11.4939"
      stroke="#D67C56"
    />
    <path
      d="M37.9952 13.6754C37.9952 13.6754 35.4722 9.83577 30.1953 7.3335"
      stroke="#D67C56"
    />
    <path
      d="M36.0363 28.7755C35.3145 27.7461 33.9074 26.4986 33.9074 26.1684C33.9074 23.4016 28.671 21.1583 20.8539 21.1518V21.1516C20.8481 21.1516 20.8427 21.1518 20.8372 21.1518C20.8314 21.1518 20.826 21.1516 20.8202 21.1516V21.1518C13.0031 21.1583 7.76652 23.4016 7.76652 26.1684C7.76652 26.4986 6.35943 27.7461 5.63763 28.7755C5.23393 29.3512 5.32024 29.8014 5.32024 30.124C5.32024 32.925 12.2568 35.1959 20.8201 35.2018V35.202C20.8256 35.202 20.8312 35.2018 20.837 35.2018C20.8425 35.2018 20.8481 35.202 20.8537 35.202V35.2018C29.4171 35.1959 36.3535 32.925 36.3535 30.124C36.3537 29.8014 36.44 29.3512 36.0363 28.7755Z"
      fill="#FDBD74"
      stroke="#D67C56"
    />
    <path
      d="M34.9736 24.8572C34.9736 24.3444 34.8513 23.8649 34.7015 23.1746C34.6521 22.9467 33.9317 22.6089 33.8301 22.3769C32.395 19.0928 30.3785 12.855 20.7852 12.855C11.1919 12.855 9.24268 19.0928 7.80753 22.3769C7.70611 22.6091 6.98576 22.9469 6.93613 23.1746C6.78634 23.8648 6.66406 24.3444 6.66406 24.8572C6.66406 27.6198 12.9803 29.8605 20.7852 29.8732V29.8738C20.7965 29.8738 20.8077 29.8736 20.8188 29.8736C20.83 29.8736 20.8411 29.8738 20.8524 29.8738V29.8732C28.6574 29.8605 34.9736 27.6198 34.9736 24.8572Z"
      fill="#FDBD74"
      stroke="#D67C56"
    />
    <path
      d="M20.7866 14.0166C21.8293 14.0166 22.6747 13.1702 22.6747 12.1261C22.6747 11.082 21.8293 10.2356 20.7866 10.2356C19.7438 10.2356 18.8984 11.082 18.8984 12.1261C18.8984 13.1702 19.7438 14.0166 20.7866 14.0166Z"
      fill="#FFD18C"
      stroke="#D67C56"
    />
    <path
      d="M24.9358 8.71348C24.9358 8.09827 23.0789 7.59937 20.7882 7.59937C18.4975 7.59937 16.6406 8.09827 16.6406 8.71348C16.6406 8.97257 16.6406 10.5561 16.6406 10.8152C16.6406 11.4306 18.4975 11.9293 20.7882 11.9293C23.0789 11.9293 24.9358 11.4304 24.9358 10.8152C24.9358 10.5561 24.9358 8.97257 24.9358 8.71348Z"
      fill="#D7C4FA"
      stroke="#A990F5"
    />
    <path
      d="M20.7882 10.1274C23.0788 10.1274 24.9358 9.6286 24.9358 9.01329C24.9358 8.39798 23.0788 7.89917 20.7882 7.89917C18.4976 7.89917 16.6406 8.39798 16.6406 9.01329C16.6406 9.6286 18.4976 10.1274 20.7882 10.1274Z"
      fill="#D7C4FA"
      stroke="#A990F5"
    />
    <path
      d="M7.13281 22.9399C7.13281 22.9399 10.4359 26.4292 20.8513 26.4292C31.2667 26.4292 34.5019 22.9399 34.5019 22.9399"
      stroke="#D67C56"
    />
  </svg>
);

const UniIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    strokeWidth="1.2"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M8 31.4187C8 31.7398 8.89554 32 10.0001 32C11.1046 32 12 31.7398 12 31.4187V16.5814C12 16.2604 11.1045 16 10.0001 16C8.89548 16 8 16.2603 8 16.5814V31.4187Z"
      fill="#C2AAFA"
      stroke="#7F69CE"
    />
    <path
      d="M13 15V17.167C13 18.1793 12.4689 19 11.8135 19H8.18675C7.53123 19 7 18.1793 7 17.167V15H13Z"
      fill="#5ABE89"
      stroke="#7F69CE"
    />
    <path
      d="M37 15H6V12.5L21.426 6L37 12.5V15Z"
      fill="#C2AAFA"
      stroke="#7F69CE"
    />
    <path
      d="M14 35V32.833C14 31.8207 13.2919 31 12.418 31H7.58233C6.7083 31 6 31.8207 6 32.833V35H14Z"
      fill="#A990F5"
      stroke="#7F69CE"
    />
    <path
      d="M19.5 31.4187C19.5 31.7398 20.3955 32 21.4999 32C22.6046 32 23.5 31.7398 23.5 31.4187V16.5814C23.5 16.2604 22.6045 16 21.4999 16C20.3954 16 19.5 16.2603 19.5 16.5814V31.4187Z"
      fill="#C2AAFA"
      stroke="#7F69CE"
    />
    <path
      d="M24.5 15V17.167C24.5 18.1793 23.9689 19 23.3135 19H19.6867C19.0312 19 18.5 18.1793 18.5 17.167V15H24.5Z"
      fill="#FD975D"
      stroke="#7F69CE"
    />
    <path
      d="M25.5 35V32.833C25.5 31.8207 24.7919 31 23.918 31H19.0823C18.2083 31 17.5 31.8207 17.5 32.833V35H25.5Z"
      fill="#A990F5"
      stroke="#7F69CE"
    />
    <path
      d="M31 31.4187C31 31.7398 31.8954 32 32.9999 32C34.1045 32 35 31.7398 35 31.4187V16.5814C35 16.2604 34.1045 16 32.9999 16C31.8954 16 31 16.2603 31 16.5814V31.4187Z"
      fill="#C2AAFA"
      stroke="#7F69CE"
    />
    <path
      d="M36 15V17.167C36 18.1793 35.4689 19 34.8135 19H31.1867C30.5312 19 30 18.1793 30 17.167V15H36Z"
      fill="#F486B8"
      stroke="#7F69CE"
    />
    <path
      d="M37 35V32.833C37 31.8207 36.2919 31 35.418 31H30.5823C29.7083 31 29 31.8207 29 32.833V35H37Z"
      fill="#A990F5"
      stroke="#7F69CE"
    />
  </svg>
);

const MachineArmIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    strokeWidth="1.2"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M30.7065 7.63228C31.6685 9.63195 30.8735 12.0517 28.9215 13.0417L13.806 20.4951C11.8602 21.4829 9.49777 20.6646 8.5341 18.6678C7.56986 16.6682 8.36815 14.2513 10.3191 13.2607L25.4335 5.80615C27.3788 4.81896 29.7411 5.6372 30.7065 7.63228Z"
      fill="#87D1A3"
      stroke="#239667"
    />
    <path
      d="M22.1503 31.0585C23.1314 33.7701 20.8626 34.4476 18.2188 35.4532C15.5722 36.4588 13.5518 37.4115 12.5696 34.6999L7.06167 19.4817C6.08174 16.7689 7.43167 13.7579 10.0772 12.75C12.7215 11.7456 15.6619 13.1281 16.6418 15.8363L22.1503 31.0585Z"
      fill="#87D1A3"
      stroke="#239667"
    />
    <path
      d="M17.1543 17.2281C17.1543 20.3483 14.6882 22.8766 11.643 22.8766C8.60059 22.8766 6.13281 20.3483 6.13281 17.2281C6.13281 14.1091 8.60003 11.5796 11.643 11.5796C14.6876 11.5796 17.1543 14.1096 17.1543 17.2281Z"
      fill="#FAE8E1"
      stroke="#239667"
    />
    <path
      d="M11.6437 20.0341C13.1555 20.0341 14.3811 18.7779 14.3811 17.2282C14.3811 15.6786 13.1555 14.4224 11.6437 14.4224C10.1318 14.4224 8.90625 15.6786 8.90625 17.2282C8.90625 18.7779 10.1318 20.0341 11.6437 20.0341Z"
      fill="#5ABE89"
    />
    <path
      d="M31.4299 16.5837L31.0234 20.1182L33.5209 21.4904"
      stroke="#1F7A57"
    />
    <path d="M33.7812 14.9409L37.1387 15.8442L37.4717 18.73" stroke="#1F7A57" />
    <path
      d="M33.4606 12.7235C34.1978 13.84 33.5929 14.8737 32.503 15.6322C31.4127 16.3884 30.2505 16.5838 29.5122 15.4662L25.9888 10.1395C25.2505 9.023 25.5348 7.50197 26.6235 6.74462C27.7127 5.98613 29.1977 6.27861 29.9338 7.39566L33.4606 12.7235Z"
      fill="#87D1A3"
      stroke="#239667"
    />
    <path
      d="M35.0997 13.4692C35.4327 13.972 35.3049 14.6576 34.8116 15.0012L31.0718 17.6013C30.5807 17.9426 29.9102 17.8111 29.5761 17.306C29.2419 16.8032 29.372 16.1177 29.8642 15.7752L33.6046 13.175C34.0957 12.8343 34.7656 12.9647 35.0997 13.4692Z"
      fill="#87D1A3"
      stroke="#239667"
    />
    <path
      d="M32.3226 8.96831C32.3226 11.415 30.3851 13.4015 27.9969 13.4015C25.6082 13.4015 23.6719 11.415 23.6719 8.96831C23.6719 6.52044 25.6082 4.53516 27.9969 4.53516C30.3851 4.53516 32.3226 6.52044 32.3226 8.96831Z"
      fill="#FAE8E1"
      stroke="#239667"
    />
    <path
      d="M28.0002 10.7544C28.9624 10.7544 29.7425 9.95485 29.7425 8.96851C29.7425 7.98218 28.9624 7.18262 28.0002 7.18262C27.0379 7.18262 26.2578 7.98218 26.2578 8.96851C26.2578 9.95485 27.0379 10.7544 28.0002 10.7544Z"
      fill="#5ABE89"
    />
    <path
      d="M26.1283 35.3174C26.1283 33.3959 24.7066 31.8369 22.9547 31.8369H12.6116C10.8597 31.8369 9.4375 33.3959 9.4375 35.3174V36.9998H26.1278L26.1283 35.3174Z"
      fill="#87D1A3"
      stroke="#239667"
    />
  </svg>
);

const BlueHandIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    strokeWidth="1.2"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M26.5757 29.374C26.5757 31.3861 24.3629 33.0147 21.6301 33.0147C18.8991 33.0147 16.6875 31.3867 16.6875 29.374C16.6875 27.363 18.8991 25.7344 21.6301 25.7344C24.3629 25.7344 26.5757 27.363 26.5757 29.374Z"
      stroke="#96BDF3"
    />
    <path
      d="M26.5757 32.7026C26.5757 34.7135 24.3629 36.3421 21.6301 36.3421C18.8991 36.3421 16.6875 34.7141 16.6875 32.7026C16.6875 30.691 18.8991 29.063 21.6301 29.063C24.3629 29.063 26.5757 30.6916 26.5757 32.7026Z"
      stroke="#96BDF3"
    />
    <path
      d="M26.5757 36.0302C26.5757 38.0424 24.3629 39.671 21.6301 39.671C18.8991 39.671 16.6875 38.043 16.6875 36.0302C16.6875 34.0204 18.8991 32.3918 21.6301 32.3918C24.3629 32.3918 26.5757 34.0204 26.5757 36.0302Z"
      stroke="#96BDF3"
    />
    <path
      d="M28.1112 23.5084C28.2227 26.9622 25.4159 29.842 21.843 29.9472C18.2675 30.0549 15.284 27.344 15.175 23.8938C15.0648 20.4424 17.8715 17.5614 21.4445 17.4549C25.02 17.3485 28.0009 20.0582 28.1112 23.5084Z"
      fill="#BBD2F8"
      stroke="#4E8FD0"
    />
    <path
      d="M9.19433 16.1484C9.81834 17.1974 13.251 21.618 13.8737 22.8056C14.499 23.9932 16.433 27.9008 21.0924 27.98C25.748 28.0617 28.2578 24.1578 29.0252 21.4033C29.7921 18.6439 30.4111 12.15 30.0289 9.58128C29.6461 7.01615 28.6644 5.82133 27.5899 5.80378C26.5154 5.78503 25.9867 7.16134 25.9867 7.16134C25.9867 7.16134 25.2355 4.17395 23.7005 4.02088C22.4237 3.89081 21.8611 4.34817 21.7252 5.96227C21.7252 5.96227 20.8054 3.89322 19.2341 4.17575C17.6604 4.45707 17.5664 6.71126 17.5664 6.71126C17.5664 6.71126 16.8985 5.49522 15.4575 5.88181C14.0153 6.26597 13.7954 8.16139 13.9664 9.03559C14.135 9.91101 15.3962 13.6262 14.0341 15.4248C14.0341 15.4248 12.0706 13.9015 11.4917 13.5591C10.9128 13.2166 9.51509 12.6776 8.78458 13.435C7.86612 14.3945 9.19433 16.1484 9.19433 16.1484Z"
      fill="#BBD2F8"
      stroke="#4E8FD0"
    />
    <path
      d="M21.2211 22.3363C21.2211 22.3363 20.681 18.77 17.0166 17.3912C15.6552 16.8782 14.9027 16.1885 14.0312 15.4238"
      stroke="#4E8FD0"
    />
    <path
      d="M17.0312 14.76C17.0312 14.76 19.6263 14.0086 22.2395 14.2615C24.8514 14.5132 27.0861 15.7963 27.0861 15.7963"
      stroke="#4E8FD0"
    />
    <path
      d="M18.2949 14.4352C18.2949 14.4352 18.4904 12.4986 18.231 10.1434C17.9855 7.92434 17.5469 6.42944 17.5469 6.42944"
      stroke="#4E8FD0"
    />
    <path
      d="M21.6719 5.70386C21.6719 5.70386 22.3786 8.37789 22.4588 9.93874C22.5352 11.4948 22.4851 14.1936 22.4851 14.1936"
      stroke="#4E8FD0"
    />
    <path
      d="M26.0582 15.2953C26.0582 15.2953 26.3576 12.6001 26.2511 10.6267C26.1434 8.6508 26.1872 7.8159 25.7812 6.49341"
      stroke="#4E8FD0"
    />
  </svg>
);
