export const SuccessCakeIcon = () => (
  <svg
    width="76"
    height="81"
    viewBox="0 0 76 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.9097 80.1671C60.9363 80.1671 75.5498 74.4104 75.5498 67.3092C75.5498 60.2079 60.9363 54.4512 42.9097 54.4512C24.883 54.4512 10.2695 60.2079 10.2695 67.3092C10.2695 74.4104 24.883 80.1671 42.9097 80.1671Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M65.9328 54.465C65.9328 47.0702 51.3968 41.0757 33.466 41.0757C15.5359 41.0757 1 47.0704 1 54.465C1 55.872 1 64.6819 1 66.0881C1 73.4831 15.5359 79.4783 33.466 79.4783C51.3968 79.4783 65.9328 73.4831 65.9328 66.0881C65.9328 64.6819 65.9328 55.872 65.9328 54.465Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M65.9328 54.465C65.9328 61.8605 51.3968 67.8553 33.466 67.8553C15.5359 67.8553 1 61.8605 1 54.465C1 47.0702 15.5359 41.0757 33.466 41.0757C51.3968 41.0757 65.9328 47.0704 65.9328 54.465Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M35.8501 61.6646C50.36 61.6646 62.1225 57.7885 62.1225 53.007C62.1225 48.2256 50.36 44.3494 35.8501 44.3494C21.3402 44.3494 9.57764 48.2256 9.57764 53.007C9.57764 57.7885 21.3402 61.6646 35.8501 61.6646Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M57.2579 43.5561C57.2579 39.2947 46.6064 35.8401 33.4659 35.8401C20.3265 35.8401 9.67529 39.2946 9.67529 43.5561C9.67529 44.8379 9.67529 52.431 9.67529 53.7128C9.67529 57.9738 20.3263 61.4288 33.4659 61.4288C46.6063 61.4288 57.2579 57.9738 57.2579 53.7128C57.2579 52.431 57.2579 44.8379 57.2579 43.5561Z"
      fill="#6DA2F0"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M57.2579 43.5561C57.2579 47.8174 46.6064 51.2719 33.4659 51.2719C20.3265 51.2719 9.67529 47.8174 9.67529 43.5561C9.67529 39.2947 20.3263 35.8401 33.4659 35.8401C46.6063 35.8401 57.2579 39.2946 57.2579 43.5561Z"
      fill="#96BEFA"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M36.0479 45.742C44.9516 45.742 52.1695 43.9152 52.1695 41.6619C52.1695 39.4085 44.9516 37.5818 36.0479 37.5818C27.1442 37.5818 19.9263 39.4085 19.9263 41.6619C19.9263 43.9152 27.1442 45.742 36.0479 45.742Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M18.0005 41.564C18.0005 43.7657 24.925 45.5504 33.4667 45.5504C42.0093 45.5504 48.9338 43.7657 48.9338 41.564V31.0529C48.9338 28.8511 42.0093 27.0663 33.4667 27.0663C24.925 27.0663 18.0005 28.8511 18.0005 31.0529V41.564Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M48.9338 31.0529C48.9338 33.2546 42.0093 35.0395 33.4667 35.0395C24.925 35.0395 18.0005 33.2546 18.0005 31.0529C18.0005 28.8511 24.925 27.0663 33.4667 27.0663C42.0093 27.0663 48.9338 28.8511 48.9338 31.0529Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M35.7504 32.4071C40.9717 32.4071 45.2044 31.2933 45.2044 29.9192C45.2044 28.5452 40.9717 27.4313 35.7504 27.4313C30.5291 27.4313 26.2964 28.5452 26.2964 29.9192C26.2964 31.2933 30.5291 32.4071 35.7504 32.4071Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M24.8794 30.2198C24.8794 31.2619 28.7235 32.1068 33.4657 32.1068C38.208 32.1068 42.0523 31.2619 42.0523 30.2198V22.7403C42.0523 21.6982 38.208 20.8533 33.4657 20.8533C28.7235 20.8533 24.8794 21.6982 24.8794 22.7403V30.2198Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M33.4658 24.6272C38.208 24.6272 42.0523 23.7824 42.0523 22.7403C42.0523 21.6981 38.208 20.8533 33.4658 20.8533C28.7237 20.8533 24.8794 21.6981 24.8794 22.7403C24.8794 23.7824 28.7237 24.6272 33.4658 24.6272Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M34.7087 23.2509C37.1896 23.2509 39.2007 22.7291 39.2007 22.0854C39.2007 21.4416 37.1896 20.9198 34.7087 20.9198C32.2279 20.9198 30.2168 21.4416 30.2168 22.0854C30.2168 22.7291 32.2279 23.2509 34.7087 23.2509Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M29.7134 22.1706C29.7134 22.7357 31.3936 23.1941 33.4662 23.1941C35.5388 23.1941 37.219 22.7357 37.219 22.1706V14.8359C37.219 14.2707 35.5388 13.8124 33.4662 13.8124C31.3936 13.8124 29.7134 14.2707 29.7134 14.8359V22.1706Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M33.4662 15.8593C35.5388 15.8593 37.219 15.4011 37.219 14.8359C37.219 14.2706 35.5388 13.8124 33.4662 13.8124C31.3936 13.8124 29.7134 14.2706 29.7134 14.8359C29.7134 15.4011 31.3936 15.8593 33.4662 15.8593Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
    />
    <path
      d="M29.9866 1C30.2681 1.00102 30.5453 1.07164 30.7933 1.20504L33.3952 2.59645V2.59679C33.508 2.65564 33.6425 2.65564 33.7555 2.59679L36.3768 1.24137C36.757 1.04401 37.1975 0.996414 37.6108 1.10831C38.0241 1.21987 38.3806 1.48291 38.6094 1.84471C38.8383 2.20702 38.9233 2.64148 38.8463 3.06299L38.3272 5.96863H38.3276C38.3097 6.08974 38.3499 6.21239 38.4366 6.29938L40.5359 8.3755C40.8401 8.67589 41.0207 9.07966 41.0417 9.50662C41.0631 9.93358 40.9237 10.3534 40.6506 10.6822C40.3776 11.0116 39.9909 11.2264 39.5674 11.2846L36.6448 11.688C36.5181 11.7059 36.4078 11.7847 36.3505 11.8993L35.0285 14.5443C34.8881 14.8294 34.6703 15.0694 34.4004 15.2372C34.1302 15.4049 33.8186 15.4936 33.5008 15.4933C33.1787 15.4946 32.8633 15.4046 32.5907 15.2336C32.3178 15.0629 32.0993 14.8179 31.9602 14.5278L30.6742 11.883L30.6744 11.8831C30.619 11.7675 30.5098 11.6873 30.3836 11.6682L27.4678 11.2218C27.0439 11.1578 26.6601 10.9374 26.3911 10.6036C26.1229 10.2701 25.9893 9.84795 26.0173 9.42065C26.0451 8.99317 26.2324 8.5918 26.542 8.29602L28.6677 6.25299C28.755 6.16684 28.7966 6.0442 28.7799 5.92258L28.3002 3.01011C28.2125 2.51133 28.3497 1.99976 28.6748 1.61204C29.0005 1.22414 29.4808 1.00034 29.9866 1Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.852905"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
