import { graphql, navigate } from "gatsby";
import { PageComponentProps } from "../types/gatsby.types";
import Page from "./Page.template";
import { styled } from "@linaria/react";
import { cover } from "polished";
import { CSSProperties, useState } from "react";
import RigidDotGrid from "../components/decorations/RigidDotGrid";
import Button from "../components/forms/Button";
import TextInput from "../components/forms/TextInput";
import { readCookieConsentState } from "../components/gdpr/gdpr";
import Spacing from "../components/layout/Spacing";
import PageSection from "../components/reusableSections/PageSection";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import LoadingIndicator from "../components/utilities/LoadingIndicator";
import PrivacyNotice from "../components/utilities/PrivacyNotice";
import SEO from "../components/utilities/SEO";
import { widthInGrid } from "../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  uptoTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import {
  brandColorThemeVar,
  colors,
  getBrandColorTheme,
  getBrandColorThemeVariables,
} from "../styles/colors.styles";
import { serif } from "../styles/fonts.styles";
import { maxPageContentWidthStyleObject } from "../styles/maxPageContentWidth.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { emailIsLegitimate } from "../utils/checkEmailLegitimacy.utils";
import { setCookie } from "../utils/cookies.utils";
import { reportErrorSilently } from "../utils/error.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import { recordDocumentEvent } from "../utils/documents.utils";
import {
  DatoCmsDocument,
  DocumentAccessPageBySlugQuery,
} from "../../graphql-types";
import StandardArticleStructuredTextWithCustomBlocks from "../components/articles/StructuredTextWithCustomBlocks";
import { toLower } from "lodash-es";
import { isSameOrigin } from "../utils/urls.utils";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { getFormMetaInfo } from "../utils/form.utils";
import { reportIntent } from "../utils/intentReporter.utils";

enum PageLayout {
  "twoColumns" = "twoColumns",
  "wideBanner" = "wideBanner",
}

const AccessPageLayout = styled.div``;

const AccessPageHeader = styled.header`
  position: relative;
`;

const AccessPageHeaderInner = styled.div`
  position: relative;
  ${maxPageContentWidthStyleObject};
  [data-layout=${PageLayout.twoColumns}] & {
    padding-top: ${rSize("sectionPadding")};
  }
  [data-layout=${PageLayout.wideBanner}] & {
    padding-top: ${rSize("lg")};
  }
  h1 {
    font-family: ${serif};
    font-size: 3.8rem;
    letter-spacing: -0.01em;
    font-weight: 400;
    line-height: 1;
    ${fromTablet} {
      font-size: 4.8rem;
      font-weight: 300;
    }
    ${fromDesktop} {
      font-size: 5.6rem;
      letter-spacing: -0.02em;
    }
    ${fromDesktopMd} {
      font-size: 6.4rem;
    }
  }
`;

const Subheading = styled.p`
  margin-top: 0.5em;
  font-weight: 600;
  font-size: 1.8rem;
  ${fromTablet} {
    font-size: 2.2rem;
  }
`;

const AccessPageContentGrid = styled.div`
  ${uptoTabletMd} {
    text-align: center;
  }
  ${fromTabletMd} {
    align-items: center;
    [data-layout=${PageLayout.twoColumns}] & {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${rSize("gap")};
    }
    [data-layout=${PageLayout.wideBanner}] & {
    }
  }
  ${fromDesktop} {
    align-items: start;
  }
`;

const ReportThumbContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  [data-layout=${PageLayout.twoColumns}] & {
    max-width: 200px;
    ${fromTablet} {
      max-width: 275px;
    }
    ${fromTabletMd} {
      max-width: unset;
      grid-column-end: span 5;
      padding-right: ${rSize("gap")};
    }
    ${fromDesktop} {
      height: 0;
      padding-bottom: 0;
      padding-right: ${widthInGrid(1)};
    }
  }
`;

const ReportThumb = styled.figure`
  margin: 0;
  position: relative;
  width: 100%;
  [data-layout=${PageLayout.wideBanner}] & {
    margin-bottom: ${rSize("xl")};
  }
  img {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 4px;
    z-index: 2;
    background-color: var(--thumbBg);
    pointer-events: none;
    [data-layout=${PageLayout.wideBanner}] & {
      border-radius: var(--radius);
    }
  }
  [data-layout=${PageLayout.twoColumns}] & {
    &:before,
    &:after {
      content: "";
      display: block;
      border-radius: 4px;
      position: absolute;
      ${cover()}
    }
    &:before {
      z-index: 1;
      transform: translate(3px, 3px);
      border-bottom-right-radius: 5px;
      background-color: var(--thumbShadow);
    }
    &:after {
      z-index: 0;
      transform: translate(6px, 6px);
      border-bottom-right-radius: 6px;
      background-color: var(--thumbShadow);
      opacity: 0.5;
    }
  }
`;

const AccessPageContentGridTextContent = styled.div`
  font-weight: 400;
  [data-layout=${PageLayout.twoColumns}] & {
    ${fromTabletMd} {
      grid-column-start: 6;
      grid-column-end: span 7;
    }
  }
  [data-layout=${PageLayout.wideBanner}] & {
    ${fromTabletMd} {
      max-width: 53.2em;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromTablet} {
    font-size: 1.4rem;
    font-weight: 500;
  }
  p,
  li {
    font-weight: 500;
  }
  p {
    strong {
      font-weight: 700;
    }
  }
  h3 {
    font-size: inherit;
    font-weight: 700;
    margin-top: 0;
    ${fromTabletMd} {
      margin-top: 1em;
    }
  }
  ul {
    margin-top: 1em;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    li {
      margin-top: 0.125em !important;
      position: relative;
      padding-left: 1em;
      &:before {
        content: "–";
        position: absolute;
        left: 0;
      }
    }
  }
`;

const ContentWrap = styled.div`
  text-align: left;
  ${uptoTabletMd} {
    margin-top: 2em;
    padding: 2em;
    background-color: ${brandColorThemeVar(100)};
    border-radius: 0.5em;
    font-size: 1.4rem;
    font-weight: 500;
  }
  ${fromTabletMd} {
    font-size: 1.6rem;
  }
  * {
    font-size: inherit;
  }
`;

const LoadingIndicatorBlock = styled.div`
  padding: 5em 3em;
  text-align: center;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // make sure we push footer out of sight
  min-height: 600px;
  > * {
    margin-left: auto;
    margin-right: auto;
  }
`;

const TextInputSet = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  border-radius: 0.5em;
  border: 0;
  background-color: ${colors.lightest};
  > input {
    position: relative;
    border-radius: 0;
    margin-top: -1px;
    grid-column-end: span 2;
    border-color: ${brandColorThemeVar(200)};
    &:hover,
    &:focus {
      z-index: 1;
      border-color: ${brandColorThemeVar(400)};
    }
    &:first-child {
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
    &:last-child {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }
    ${fromTabletMd} {
      &:nth-child(1) {
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0;
        grid-column-end: span 1;
        margin-right: -1px;
      }
      &:nth-child(2) {
        border-top-left-radius: 0;
        border-top-right-radius: 0.5em;
        grid-column-end: span 1;
        margin-left: -1px;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        max-width: calc(100% - 1px);
      }
    }
  }
`;

const DocumentAccessPageTemplate = (
  props: PageComponentProps & {
    pageContext: {
      slug: string;
    };
    data: DocumentAccessPageBySlugQuery;
  }
) => {
  const document = props.data.document as DatoCmsDocument;

  const layout =
    !document.pageLayout || document.pageLayout === "2 columns"
      ? PageLayout["twoColumns"]
      : PageLayout.wideBanner;

  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [showAccessForm, setShowAccessForm] = useState(false);

  const formState = useState(() => ({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    company: "",
    ...getFormMetaInfo(),
  }));

  const goToReport = () => {
    if (document.fileUrl) {
      if (isSameOrigin(document.fileUrl)) {
        const relativeUrl = document.fileUrl.replace(
          /^https?:\/\/(www\.)?tines\.com\//,
          "/"
        );
        navigate(relativeUrl);
      } else {
        window.location.href = document.fileUrl;
      }
    } else {
      window.location.href = document.file!.url!;
    }
  };

  const submit = async () => {
    const [form] = formState;

    setAwaitingResponse(true);

    if (!(await emailIsLegitimate(form.email)).valid) {
      setAwaitingResponse(false);
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      return;
    }

    const cookieConsentState = readCookieConsentState();
    if (cookieConsentState?.personalization) {
      setCookie("email_address", form.email, { days: 30 });
      setCookie("company", form.company, { days: 30 });
      setCookie("first_name", form.firstName, { days: 30 });
      setCookie("last_name", form.lastName, { days: 30 });
    }

    try {
      await recordDocumentEvent({
        type: "access",
        id: document.id,
        form,
      });
    } catch (e) {
      reportErrorSilently(e);
    } finally {
      goToReport();
    }
  };

  useOnMount(() => {
    setShowAccessForm(true);
    reportIntent(
      `Viewed access form for ${document.category?.name?.toLowerCase()} "${
        document.title
      }"`
    );
  });

  const theme = getBrandColorTheme(document.primaryColor);

  const textBeforeHeading = document.textBeforeTitle || document.category?.name;

  return (
    <Page
      {...props}
      backgroundColor={theme.c25}
      textColor={theme.c800}
      navThemeColor={theme.navTheme}
      style={getBrandColorThemeVariables(document.primaryColor)}
    >
      <SEO
        title={document.seoTitle || document.title || "Access document"}
        description={document.seoDescription}
        image={document.seoImage?.url}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="document"
        recordId={document.id}
      />
      {showAccessForm ? (
        <AccessPageLayout data-layout={layout}>
          <AccessPageHeader>
            {layout === PageLayout.twoColumns && (
              <RigidDotGrid color={theme.c200} />
            )}
            <SiteNavHeightPlaceholder withSpacingTop />
            <AccessPageHeaderInner>
              <AccessPageContentGrid>
                {document.previewImage?.url && (
                  <ReportThumbContainer
                    style={
                      {
                        "--thumbBg":
                          document.previewImageBackgroundColor?.hex ??
                          theme.c100,
                        "--thumbShadow":
                          document.previewImageShadowColor?.hex ?? theme.c300,
                      } as CSSProperties
                    }
                  >
                    <ReportThumb>
                      <img
                        src={document.previewImage.url}
                        style={{
                          aspectRatio: `${document.previewImage.width}/${document.previewImage.height}`,
                        }}
                      />
                    </ReportThumb>
                  </ReportThumbContainer>
                )}
                <AccessPageContentGridTextContent>
                  {textBeforeHeading && (
                    <>
                      <p>
                        <strong>{textBeforeHeading}</strong>
                      </p>
                      <Spacing size="gap" />
                    </>
                  )}
                  <h1>{document.title}</h1>
                  {document.subheading && (
                    <Subheading>{document.subheading}</Subheading>
                  )}
                  <Spacing size="gap" />
                  <ContentWrap>
                    <StandardArticleStructuredTextWithCustomBlocks
                      value={document.description}
                    />
                  </ContentWrap>
                  <Spacing size="4em" />
                </AccessPageContentGridTextContent>
              </AccessPageContentGrid>
            </AccessPageHeaderInner>
          </AccessPageHeader>

          <PageSection>
            <AccessPageContentGrid>
              <AccessPageContentGridTextContent>
                <Spacing size="4em" />
                <h2>
                  Read the {toLower(document.category?.name ?? "document")} now
                </h2>
                <Spacing size="2em" />
                <TextInputSet>
                  <TextInput
                    formState={formState}
                    name="firstName"
                    placeholder="First name *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="lastName"
                    placeholder="Last name *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="email"
                    type="email"
                    placeholder="Email *"
                    required
                    className="fullWidth"
                  />
                  <TextInput
                    formState={formState}
                    name="jobTitle"
                    placeholder="Job Title *"
                    required
                  />
                  <TextInput
                    formState={formState}
                    name="company"
                    placeholder="Company *"
                    required
                    onEnter={() => {
                      submit();
                    }}
                  />
                </TextInputSet>
                <Spacing size="2em" />
                <Button
                  loading={awaitingResponse}
                  onClick={submit}
                  padding="1em"
                  fullWidth
                >
                  Access {toLower(document.category?.name ?? "document")}
                </Button>
                <Spacing size=".5em" />
                <PrivacyNotice />
              </AccessPageContentGridTextContent>
            </AccessPageContentGrid>
          </PageSection>
        </AccessPageLayout>
      ) : (
        <LoadingIndicatorBlock>
          <LoadingIndicator delayInSeconds={1} />
        </LoadingIndicatorBlock>
      )}

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const documentAccessPageBySlugQuery = graphql`
  query DocumentAccessPageBySlug($slug: String) {
    document: datoCmsDocument(slug: { eq: $slug }) {
      id: originalId
      textBeforeTitle
      title
      subheading
      slug
      pageLayout
      description {
        value
      }
      file {
        url
      }
      fileUrl
      category {
        name
        slug
      }
      previewImage {
        url
        width
        height
      }
      primaryColor
      previewImageBackgroundColor {
        hex
      }
      previewImageShadowColor {
        hex
      }
      seoTitle
      seoImage {
        url
      }
      seoDescription
    }
  }
`;

export default DocumentAccessPageTemplate;
