import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
} from "../../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../../styles/colorsV4.styles";
import { font } from "../../../../styles/fonts.styles";
import { fluidFontSize } from "../../../../styles/helpers.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import CornerDotSet from "../../../utilities/CornerDotSet";
import LightbulbIcon from "../../../misc/LightbulbIcon";
import { ReportBaseSectionStyle } from "../../ReportBaseStyles";
import ReportSection from "../../ReportSection";
import ReportTinesCTACard from "../../ReportTinesCTACard";

const SOCConclusionCard = styled.div`
  ${ReportBaseSectionStyle}
  background-color: ${colorsV4.warmBlack};
  color: ${colorsV4.canvas};
  position: relative;
  font-weight: 500;
  ${onlyPhones} {
    padding: 2em 1em;
    margin-top: 2em;
    border-radius: 1em;
  }
`;

const Inner = styled.div`
  h2 {
    ${fluidFontSize(32, 42)};
  }
  blockquote {
    padding: 0;
    margin: 1em;
    font-size: 1.8rem;
    ${fromTablet} {
      font-size: 2rem;
      margin: 1em 3em;
    }
    text-align: center;
    font-family: ${font("serif")};
    p {
      line-height: 1.5;
    }
  }
`;

const SOCConclusion = () => {
  return (
    <ReportSection id="conclusion" darkMode>
      <SOCConclusionCard>
        <CornerDotSet
          color={withOpacity(colorsV4.canvas, 0.7)}
          offset={rSize("gap")}
        />
        <Inner>
          <LightbulbIcon centered />
          <blockquote>
            <p>
              Fortunately, there’s a better, faster, and easier solution:
              no-code automation, which removes the barrier of coding so that
              analysts can start building automated workflows today, without
              waiting on a development team. SOC teams that adopt this flexible,
              powerful form of workflow automation can reap the benefits of
              automation immediately, giving security analysts the ability and
              freedom to do their best work.
            </p>
          </blockquote>
        </Inner>
      </SOCConclusionCard>
      <ReportTinesCTACard />
    </ReportSection>
  );
};

export default SOCConclusion;
