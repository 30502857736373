import { CSSProperties, styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren } from "react";
import {
  PrimaryColorName,
  SecondaryColorName,
  color,
  colors,
} from "../../styles/colors.styles";

type Props = {
  className?: string;
  id?: string;
  color: PrimaryColorName | SecondaryColorName;
  lighterFill?: boolean;
  lighterStroke?: boolean;
  style?: CSSProperties;
  noLabel?: boolean;
};

export const MultiplayerCursorOuter = styled.div`
  position: absolute;
  display: inline-block;
  transform-origin: 0 0;
  z-index: 2;
  user-select: none;
  pointer-events: none;
`;

export const MultiplayerCursorInner = styled.div`
  position: relative;
`;

const CursorSvg = styled.svg`
  display: block;
  transform: translate(-2px, -2px);
`;

const Label = styled.span`
  display: inline-block;
  background-color: var(--fill);
  border: 1.5px solid var(--stroke);
  color: ${colors.white};
  padding: 0.3em 0.8em;
  border-radius: 1em;
  font-weight: 600;
  font-size: 0.8em;
  position: absolute;
  top: calc(100% - 0.4em);
  left: calc(100% - 0.4em);
  min-width: 2.6rem;
  min-height: 1.2rem;
`;

const MultiplayerCursor = (props: PropsWithChildren<Props>) => {
  const fill = color(props.color, props.lighterFill ? 300 : 500);
  const stroke = color(props.color, props.lighterStroke ? 300 : 700);
  return (
    <MultiplayerCursorOuter
      id={props.id}
      className={cx("MultiplayerCursor", props.className)}
      style={
        {
          ...props.style,
          "--fill": fill,
          "--stroke": stroke,
        } as CSSProperties
      }
    >
      <MultiplayerCursorInner>
        <CursorSvg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill={fill}
          stroke={stroke}
        >
          <path d="M1.20448 2.04479L2.09998 10.9998C2.23814 12.3814 4.01747 12.8499 4.81806 11.7155L7.27523 8.23391C7.38734 8.07505 7.54333 7.95233 7.72412 7.88075L11.2949 6.46698C12.5818 5.95743 12.5507 4.12525 11.2472 3.6597L2.93526 0.691166C2.03635 0.370123 1.1095 1.095 1.20448 2.04479Z" />
        </CursorSvg>
        {!props.noLabel && <Label>{props.children}</Label>}
      </MultiplayerCursorInner>
    </MultiplayerCursorOuter>
  );
};

export default MultiplayerCursor;
