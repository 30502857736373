import { graphql } from "gatsby";
import { PageComponentProps } from "../types/gatsby.types";
import {
  DatoCmsButton,
  DatoCmsLandingPage,
  DatoCmsUnionForDatoCmsLandingPageLandingPageSections,
  LandingPageByIdQuery,
} from "../../graphql-types";
import Page from "./Page.template";
import SEO from "../components/utilities/SEO";
import { colors } from "../styles/colors.styles";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import HeroSection from "../components/sections/HeroSection";
import StandardArticleStructuredTextWithCustomBlocks from "../components/articles/StructuredTextWithCustomBlocks";
import Spacing from "../components/layout/Spacing";
import { ButtonGroupCenteredOnMobile } from "../components/forms/Button";
import { styled } from "@linaria/react";
import { rSize } from "../styles/responsiveSizes.styles";
import AllCaps from "../components/typography/AllCaps";
import { StandardArticleContainer } from "../components/articles/StandardArticle";
import { getWidthPxInMaxGrid } from "../constants/globalGrid.constants";
import { LPSectionDispatcher } from "../components/landingPages/LPSectionDispatcher";
import { LPHeroDemoForm } from "../components/landingPages/LPHeroDemoForm";
import { G2AndGartnerSetCompact } from "../components/landingPages/G2AndGartnerSetCompact";
import QuoteCardNeue from "../components/general/QuoteCardNeue";
import { css } from "linaria";
import { BlendedDarkGreyLogoImage } from "../components/misc/BlendedDarkGreyLogoImage";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import { LPButtonGroup } from "../components/landingPages/LPButtonGroup";
import VideoPlayer from "../components/basic/VideoPlayer";
import {
  fromDesktopMd,
  fromTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { useState } from "react";
import { useOnMount } from "../utils/lifeCycle.utils";
import axios from "axios";
import { reportErrorSilently } from "../utils/error.utils";

const HeaderContent = styled.div`
  position: relative;
  display: grid;
  gap: ${rSize("gap")};
  grid-template-areas: "text" "figure";
  text-align: center;
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: "text figure";
    text-align: left;
  }
`;

const HeaderTextContent = styled.div`
  grid-area: text;
  ${fromDesktopMd} {
    padding-right: ${getWidthPxInMaxGrid(1)};
  }
  > * {
    + * {
      margin-top: ${rSize("md")};
    }
  }
  ${StandardArticleContainer} {
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 500;
    p,
    ul,
    ol,
    li {
      font-weight: inherit;
    }
  }
`;

const HeaderImageFrame = styled.div`
  text-align: right;
  img {
    max-width: 100%;
  }
`;

const HeaderVideoFrame = styled.div`
  border-radius: ${rSize("radius")};
  overflow: hidden;
  align-self: start;
  > * {
    display: block;
  }
`;

const HeaderFigure = styled.div`
  grid-area: figure;
`;

const PageSectionList = styled.div`
  > * {
    &:last-child {
      margin-bottom: ${rSize("lg")};
    }
    + * {
      margin-top: ${rSize("sectionMargin")};
      &.TextCtaWithButtonsSection {
        margin-top: ${rSize("sectionPadding")};
      }
    }
  }
`;

const LandingPageTemplate = (
  props: PageComponentProps & {
    pageContext: { slug: string };
    data: LandingPageByIdQuery;
  }
) => {
  const [page, setPage] = useState(
    props.data.landingPage as DatoCmsLandingPage
  );
  const shouldShowDemoFormInHeader =
    !page.heroSectionRightColumnImageOrVideo?.url &&
    !page.heroSectionRightColumnImageOrVideo?.video?.streamingUrl;
  const shouldShowVideoInHeader =
    !!page.heroSectionRightColumnImageOrVideo?.video?.streamingUrl;
  const quoteLogo = page.heroSectionQuote?.sourceOrganization?.logoDarkGray;
  const shouldShowImageInHeader =
    !shouldShowVideoInHeader && !!page.heroSectionRightColumnImageOrVideo?.url;

  const fetchLatestPageConfig = async () => {
    try {
      const { data } = await axios.get(`/api/landing-page?id=${page.id}`);
      setPage(Object.assign(page, data as DatoCmsLandingPage));
    } catch (e) {
      reportErrorSilently(e);
    }
  };

  useOnMount(() => {
    fetchLatestPageConfig();
  });
  return (
    <Page {...props} textColor={colors.purple800} navThemeColor="purple">
      <SEO
        title={`${page.pageHeading}`}
        seoTitle={page.seoHeading}
        description={page.pageDescription}
        image={page.seoImage?.url}
        noindex={page.noindex}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="landingPage"
        recordId={page.id}
        label="Edit in CMS"
      />
      <HeroSection
        backdrop="grid"
        backdropParallax
        backgroundColor={
          shouldShowDemoFormInHeader ? colors.purple50 : undefined
        }
        backdropColor={
          shouldShowDemoFormInHeader ? colors.purple200 : undefined
        }
      >
        <HeaderContent>
          <HeaderTextContent>
            {page.preHeadingTag && <AllCaps>{page.preHeadingTag}</AllCaps>}
            <h1>{page.pageHeading}</h1>
            <StandardArticleStructuredTextWithCustomBlocks
              value={page.heroSectionCopy}
            />
            {!!page.heroSectionButtonSet?.length && (
              <ButtonGroupCenteredOnMobile>
                <LPButtonGroup
                  buttons={page.heroSectionButtonSet as DatoCmsButton[]}
                />
              </ButtonGroupCenteredOnMobile>
            )}
            {page.heroSectionIncludeRatings && <G2AndGartnerSetCompact />}
            {page.heroSectionQuote && (
              <QuoteCardNeue
                color="green"
                sourceName={page.heroSectionQuote.sourceName}
                sourceDetails={page.heroSectionQuote.sourceSubheading}
                sourceLogo={
                  quoteLogo?.url ? (
                    <BlendedDarkGreyLogoImage
                      className="logo"
                      alt={`${
                        page.heroSectionQuote.sourceOrganization?.name ?? ""
                      } Logo`}
                      src={quoteLogo.url}
                      width={quoteLogo.width}
                      height={quoteLogo.height}
                    />
                  ) : null
                }
                footerLayout="space-between"
                fontSize="medium"
                className={css`
                  position: relative;
                  .logo {
                    height: 1.5em;
                    display: block;
                  }
                `}
              >
                {page.heroSectionQuote.body}
              </QuoteCardNeue>
            )}
          </HeaderTextContent>
          <HeaderFigure>
            {shouldShowDemoFormInHeader && <LPHeroDemoForm />}
            {shouldShowImageInHeader &&
              page.heroSectionRightColumnImageOrVideo?.url && (
                <HeaderImageFrame>
                  <img src={page.heroSectionRightColumnImageOrVideo?.url} />
                </HeaderImageFrame>
              )}
            {shouldShowVideoInHeader &&
              page.heroSectionRightColumnImageOrVideo?.video && (
                <HeaderVideoFrame>
                  <VideoPlayer
                    datoAssetVideo={
                      page.heroSectionRightColumnImageOrVideo.video
                    }
                    autoPlay
                    loop
                  />
                </HeaderVideoFrame>
              )}
          </HeaderFigure>
        </HeaderContent>
      </HeroSection>
      <Spacing size="sectionMargin" />
      <PageSectionList>
        {page.landingPageSections?.map(section => (
          <LPSectionDispatcher
            section={
              section as DatoCmsUnionForDatoCmsLandingPageLandingPageSections & {
                __typename: string;
              }
            }
            key={section?.id}
          />
        ))}
      </PageSectionList>
      {page.includeStandardExplosionCtaSectionBeforeFooter && (
        <>
          <Spacing size="sectionMargin" />
          <ExplosionCTASection />
        </>
      )}
      {!page.useSimplifiedFooter &&
        !page.includeStandardExplosionCtaSectionBeforeFooter && (
          <Spacing size="sectionMargin" />
        )}
    </Page>
  );
};

export default LandingPageTemplate;

export const landingPageByIdQuery = graphql`
  query LandingPageById($id: String) {
    landingPage: datoCmsLandingPage(originalId: { eq: $id }) {
      id: originalId
      useSimplifiedHeaderNavigation
      preHeadingTag
      pageHeading
      noindex
      seoHeading
      pageDescription
      seoImage {
        url
      }
      heroSectionButtonSet {
        link
        label
        appearance
      }
      heroSectionCopy {
        blocks
        value
      }
      heroSectionIncludeRatings
      heroSectionQuote {
        body
        sourceName
        sourceSubheading
        sourceOrganization {
          logoDarkGray {
            width
            url
            height
          }
          name
        }
      }
      heroSectionRightColumnImageOrVideo {
        alt
        height
        url
        width
        video {
          streamingUrl
          thumbnailUrl
        }
      }
      includeStandardExplosionCtaSectionBeforeFooter
      landingPageSections {
        __typename
        ... on DatoCmsTextCtaWithButtonsSection {
          id: originalId
          anchorOnPage
          body
          ctaButtons {
            link
            label
            appearance
          }
        }
        ... on DatoCmsG2BadgesSection {
          id: originalId
          anchorOnPage
        }
        ... on DatoCmsContentCardsSection {
          id: originalId
          anchorOnPage
          cards {
            id: originalId
            preset
            linkToRecord {
              ... on DatoCmsArticle {
                id: originalId
                slug
                title
                model {
                  apiKey
                }
              }
              ... on DatoCmsWebinar {
                id: originalId
                slug
                title
                colorTheme
                category {
                  slug
                }
                model {
                  apiKey
                }
              }
              ... on DatoCmsCaseStudy {
                id: originalId
                color
                slug
                title
                customer {
                  logoDarkGray {
                    url
                    width
                    height
                  }
                  logoWhite {
                    url
                    width
                    height
                  }
                }
                model {
                  apiKey
                }
              }
              ... on DatoCmsPodcast {
                id: originalId
                applePodcastsLink
                color
                episodeNumber
                title
                spotifyLink
                season {
                  seasonNumber
                  title
                }
                guestSpeaker {
                  name
                  surname
                  title
                  organization {
                    name
                  }
                }
                guestSpeakerImageTransparent {
                  url
                  width
                  height
                }
                model {
                  apiKey
                }
              }
              ... on DatoCmsDocument {
                id: originalId
                title
                slug
                description {
                  value
                }
                category {
                  name
                  slug
                }
                previewImage {
                  url
                  width
                  height
                }
                previewImageBackgroundColor {
                  hex
                }
                previewImageShadowColor {
                  hex
                }
                model {
                  apiKey
                }
              }
            }
            linkLabel
            linkUrl
            preHeadingTag
            heading
            description
            size
            color
            colorMode
            backgroundImage {
              url
            }
            icon {
              url
              width
              height
            }
          }
        }
        ... on DatoCmsTeamHeadshotsSection {
          id: originalId
          anchorOnPage
          body
          heading
          team {
            fullName
            linkedin
            photoIcon {
              url
              width
              height
            }
            title
          }
        }
        ... on DatoCmsSocialProofSection {
          id: originalId
          anchorOnPage
          heading
          body
        }
        ... on DatoCmsPartnersLogoSetSection {
          id: originalId
          anchorOnPage
          heading
          partnerLogos {
            name
            logo {
              url
              width
              height
            }
          }
        }
        ... on DatoCmsCustomerLogosSection {
          id: originalId
          anchorOnPage
          heading
          body
          logos {
            name
            logo {
              url
              width
              height
            }
          }
        }
        ... on DatoCmsFeaturedVideoSection {
          id: originalId
          anchorOnPage
          ctaButtons {
            label
            link
            appearance
          }
          descriptionAfterHeading
          heading
          textAfterVideo
          video {
            video {
              streamingUrl
              thumbnailUrl
            }
            width
            height
          }
        }
        ... on DatoCmsFeatureHighlightsSection {
          id: originalId
          anchorOnPage
          featureHighlights {
            title
            linkLabel
            linkPath
            image {
              url
              width
              height
            }
          }
        }
        ... on DatoCmsQuoteWithDataPointSection {
          id: originalId
          anchorOnPage
          heading
          body
          color
          dataPointIcon
          dataPointText
          quote {
            body
            sourceName
            sourceSubheading
            sourceOrganization {
              logoDarkGray {
                url
                width
                height
              }
              name
            }
          }
        }
        ... on DatoCmsColumnsWithIconsSection {
          id: originalId
          anchorOnPage
          columns {
            spotIcon {
              image {
                url
                width
                height
              }
            }
            heading
            body
          }
        }
        ... on DatoCmsCenteredQuoteSection {
          id: originalId
          anchorOnPage
          quote {
            body
            sourceName
            sourceSubheading
            sourceOrganization {
              logoDarkGray {
                url
                width
                height
              }
              name
            }
          }
        }
        ... on DatoCmsPercentageStatCardsSection {
          id: originalId
          anchorOnPage
          cards {
            percentage
            heading
            color
          }
        }
        ... on DatoCmsLargeHeadingSection {
          id: originalId
          heading
          body
          illustration {
            imageWithNoPadding {
              url
              width
              height
            }
          }
          ctaButtons {
            appearance
            label
            link
          }
        }
        ... on DatoCmsImageAndTextRepeaterSection {
          id: originalId
          anchorOnPage
          textPosition
          preHeadingTag
          title
          body
          visual {
            color
            video21 {
              width
              url
              video {
                streamingUrl
                thumbnailUrl
              }
              height
              isImage
              alt
            }
          }
        }
        ... on DatoCmsLargeBookADemoFormSection {
          id: originalId
          anchorOnPage
          body
          heading
          illustration {
            imageWithNoPadding {
              width
              height
              url
            }
          }
        }
        ... on DatoCmsCenteredHeadingSection {
          id: originalId
          anchorOnPage
          body
          heading
          ctaButtons {
            appearance
            label
            link
          }
          icon {
            image {
              width
              url
              height
            }
          }
        }
      }
      useSimplifiedFooter
    }
  }
`;
