import { styled } from "@linaria/react";
import { DatoCmsStoryDigest, DatoCmsYdwwt } from "../../../graphql-types";
import PageSection from "../reusableSections/PageSection";
import { font } from "../../styles/fonts.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import StructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";
import { StoryGrid } from "../library/StoryGrid";
import { convertDatoRecordToStoryDescriptor } from "../../utils/story.utils";
import Spacing from "../layout/Spacing";
import { BrandColorNameV4 } from "../../styles/colorsV4.styles";

const SectionInner = styled.div`
  header {
    text-align: center;
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
    > * {
      + * {
        margin-top: 1em;
      }
    }
    h2 {
      ${fluidFontSize(32, 46)};
      line-height: 1.1;
      letter-spacing: -0.01em;
      font-family: ${font("serif")};
      font-weight: 400;
      text-align: center;
      + * {
        margin-top: ${rSize("md")};
      }
    }
  }
`;

export const YDWWTInspirationsSection = (props: { ydwwt: DatoCmsYdwwt }) => {
  return (
    <PageSection>
      <SectionInner>
        <header>
          <StructuredTextWithCustomBlocks
            value={props.ydwwt.inspirationSectionCopy}
            linkedHeadingMaxLevel="off"
          />
        </header>
      </SectionInner>
      <Spacing size="xl" />
      <StoryGrid
        stories={(props.ydwwt.inspirationSectionExampleStories ?? []).map(
          (s, i) => ({
            ...convertDatoRecordToStoryDescriptor(s as DatoCmsStoryDigest),
            color: (i % 4 === 0
              ? "purple"
              : i % 4 === 1
              ? "green"
              : i % 4 === 2
              ? "orange"
              : "pink") as BrandColorNameV4,
          })
        )}
        doNotApplyCommunityStyling
      />
    </PageSection>
  );
};
