import { styled } from "@linaria/react";

const NoWrap = styled.span`
  display: inline !important;
  white-space: nowrap;
`;

export default NoWrap;

export const NoBreak = NoWrap;
