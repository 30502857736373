import { styled } from "@linaria/react";
import { DatoCmsLibraryCollection } from "../../../graphql-types";
import TinesLogoIcon, { TinesLogoIconWrap } from "../misc/TinesLogoIcon";
import { withOpacity } from "../../styles/colorsV4.styles";
import { colors } from "../../styles/colors.styles";

type Props = {
  collection: DatoCmsLibraryCollection;
};

export const TeamCollectionImageWrap = styled.div`
  background-color: var(--ac20, ${withOpacity(colors.purple, 0.2)});
  padding: 1em;
  border-radius: 1.2rem;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${TinesLogoIconWrap} {
    opacity: 0.2;
  }
  img {
    max-width: 100%;
  }
`;

const TeamCollectionImage = ({ collection }: Props) => {
  const { image } = collection;
  return (
    <TeamCollectionImageWrap>
      {image?.url ? (
        <img
          width={`${image.width}`}
          height={`${image.height}`}
          src={image.url}
          style={{ aspectRatio: `${image.width} / ${image.height}` }}
        />
      ) : (
        <TinesLogoIcon />
      )}
    </TeamCollectionImageWrap>
  );
};

export default TeamCollectionImage;
