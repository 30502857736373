export const SearchIcon = {
  search: () => (
    <svg
      className="Icon SearchIcon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8889 12.9446C11.1287 12.9446 12.9445 11.1289 12.9445 8.88905C12.9445 6.64923 11.1287 4.8335 8.8889 4.8335C6.64908 4.8335 4.83334 6.64923 4.83334 8.88905C4.83334 11.1289 6.64908 12.9446 8.8889 12.9446ZM8.8889 14.4446C10.1526 14.4446 11.3178 14.0227 12.2512 13.312L13.4464 14.5072C13.461 14.7419 13.558 14.9724 13.7373 15.1517L15.9596 17.3739C16.3501 17.7644 16.9833 17.7644 17.3738 17.3739C17.7643 16.9834 17.7643 16.3502 17.3738 15.9597L15.1516 13.7375C14.9722 13.5581 14.7417 13.4612 14.5071 13.4465L13.3119 12.2513C14.0225 11.3179 14.4445 10.1528 14.4445 8.88905C14.4445 5.8208 11.9571 3.3335 8.8889 3.3335C5.82065 3.3335 3.33334 5.8208 3.33334 8.88905C3.33334 11.9573 5.82065 14.4446 8.8889 14.4446Z"
      />
    </svg>
  ),
};
