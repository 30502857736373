const TriggerIcon20 = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7426 3.8635L5.51854 10.2071L9.10691 11.4032C9.41807 11.5069 9.61793 11.81 9.59069 12.1368L9.25738 16.1365L14.4815 9.79295L10.8931 8.59682C10.582 8.4931 10.3821 8.19001 10.4093 7.86315L10.7426 3.8635ZM10.8108 1.89303C11.254 1.35495 12.1263 1.70949 12.0684 2.40415L11.6371 7.57991L15.5363 8.87963C16.0097 9.03744 16.1776 9.62109 15.8603 10.0063L9.18919 18.107C8.74607 18.6451 7.87372 18.2905 7.93161 17.5959L8.36293 12.4201L4.46375 11.1204C3.99032 10.9626 3.82246 10.3789 4.1397 9.99369L10.8108 1.89303Z"
      />
    </svg>
  );
};

export default TriggerIcon20;
