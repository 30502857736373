import { graphql, navigate } from "gatsby";
import { useState } from "react";
import BenefitsSection from "../components/reusableSections/BenefitsSection";
import InsideTinesSection from "../components/reusableSections/InsideTinesSection";
import OpenPositionsSection from "../components/reusableSections/OpenPositionsSection";
import OfficeLocationsSection from "../components/reusableSections/OfficeLocationsSection";
import CareersPageHero from "../components/sections/CareersPageHero";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import {
  AllJobsQuery,
  GreenhouseDepartment,
  GreenhouseJob,
  GreenhouseOffice,
} from "../../graphql-types";
import { getGreenhouseJobIdFromCurrentLocation } from "../utils/greenhouse.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import { colors } from "../styles/colors.styles";

const PageCareers: PageComponent<{ data: AllJobsQuery }> = props => {
  const filterState = useState({
    officeId: "",
    departmentId: "",
  });
  const [filters, setFilters] = filterState;
  const setOfficeFilter = (officeId: string) => {
    setFilters({ ...filters, officeId });
  };
  const { nodes: jobs = [] } = props.data.jobs;
  const { nodes: departments = [] } = props.data.departments;
  const { nodes: offices = [] } = props.data.offices;
  // const locations = uniq(jobs.map(j => j.location?.name).filter(i => i));

  useOnMount(() => {
    const jobId = getGreenhouseJobIdFromCurrentLocation();
    if (jobId) {
      navigate(`/careers/jobs/${jobId}`);
    }
  });

  const dublinOffice = offices.find(office => office.name?.includes("Dublin"));
  const bostonOffice = offices.find(office => office.name?.includes("Boston"));

  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title="Careers"
        description="We pride ourselves in hiring diverse, ambitious, and smart people, allowing them to do their best work every day."
        image="/images/og/tines-careers.png"
      />
      <CareersPageHero />

      <Spacing size="xl" />

      <OfficeLocationsSection
        onSelectOfficeFilter={setOfficeFilter}
        dublinOfficeId={`${dublinOffice?.gh_Id ?? ""}`}
        bostonOfficeId={`${bostonOffice?.gh_Id ?? ""}`}
      />

      <Spacing size="sectionPadding" />

      <BenefitsSection />

      <Spacing size="sectionMargin" />

      <InsideTinesSection />

      <Spacing size="sectionMargin" />

      <OpenPositionsSection
        filterState={filterState}
        jobs={jobs as Partial<GreenhouseJob>[]}
        departments={departments as Partial<GreenhouseDepartment>[]}
        offices={offices as Partial<GreenhouseOffice>[]}
      />

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export const allJobsQuery = graphql`
  query AllJobs {
    jobs: allGreenhouseJob {
      nodes {
        id
        gh_Id
        title
        absolute_url
        location {
          name
        }
        offices {
          name
          gh_Id
          parent_id
        }
        departments {
          name
          gh_Id
        }
      }
    }
    departments: allGreenhouseDepartment {
      nodes {
        gh_Id
        name
        jobs {
          gh_Id
        }
      }
    }
    offices: allGreenhouseOffice {
      nodes {
        gh_Id
        name
        location
        parent_id
        jobs {
          gh_Id
        }
      }
    }
  }
`;

export default PageCareers;
