import { styled } from "@linaria/react";

type Props = {
  className?: string;
  inputColor: string;
  outputColor: string;
  inputOffset?: string | number;
  outputOffset?: string | number;
};

export const HorizontalActionLinkWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  z-index: 1;
  svg {
    flex: 0 0 auto;
  }
`;
const Line = styled.div`
  height: 3px;
  flex: 1 1 auto;
`;

const HorizontalActionLink = (props: Props) => {
  return (
    <HorizontalActionLinkWrap
      className={props.className}
      style={{
        marginLeft: props.inputOffset,
        marginRight: props.outputOffset,
      }}
    >
      <svg width="5" height="13" viewBox="0 0 5 13" fill={props.inputColor}>
        <path d="M0 0C0 2.76142 2.23858 5 5 5L5 8C2.23858 8 0 10.2386 0 13V8V5V0Z" />
      </svg>
      <Line
        style={{
          backgroundImage: `linear-gradient(to right, ${props.inputColor}, ${props.outputColor})`,
        }}
      />
      <svg width="5" height="13" viewBox="0 0 5 13" fill={props.outputColor}>
        <path d="M0 5C2.76142 5 5 2.76142 5 0V5V8V13C5 10.2386 2.76142 8 0 8V5Z" />
      </svg>
    </HorizontalActionLinkWrap>
  );
};

export default HorizontalActionLink;
