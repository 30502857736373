import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import { rSize } from "../../styles/responsiveSizes.styles";
import { font } from "../../styles/fonts.styles";
import {
  fromDesktopMd,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { withOpacity } from "../../styles/colorsV4.styles";

type Props = {};

const SidebarWrap = styled.aside`
  align-self: start;
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  ${onlyPhones} {
    padding: 1em 0;
    text-align: center;
  }
  ${fromTablet} {
    position: sticky;
    overflow: auto;
    padding-bottom: ${rSize("lg")};
    top: calc(var(--pageTopFixedElementsHeight, 0px) + 2em);
    max-height: calc(100vh - var(--pageTopFixedElementsHeight, 0px) - 2em);
    transition: top 0.25s, max-height 0.25s;
    .largerGapOnTop & {
      top: calc(var(--pageTopFixedElementsHeight, 0px) + 4em);
      max-height: calc(100vh - var(--pageTopFixedElementsHeight, 0px) - 4em);
    }
  }
  ${fromDesktopMd} {
    padding-right: 3em;
  }
  h1 {
    font-size: 3.6rem;
    font-family: ${font("serif")};
    font-weight: 400;
    text-wrap: balance; // supported in Chrome but not in safari
    letter-spacing: -0.01em;
  }
`;

export const StandardSidebarLinkList = styled.div`
  font-size: 1.4rem;
  ${onlyPhones} {
    text-align: center;
  }
  a,
  span,
  button {
    display: inline-block;
    ${fromTablet} {
      display: block;
      width: 100%;
    }
    appearance: none;
    padding: 0.55em 1em;
    background-color: transparent;
    border: none;
    border-radius: 0.5em;
    color: inherit;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    text-align: left;
    &:hover {
      background-color: ${withOpacity(colors.purple, 0.05)};
    }
    &[aria-current="page"],
    &.active {
      color: ${colors.purple};
      background-color: ${withOpacity(colors.purple, 0.1)};
    }
    + * {
      margin-top: 0.25em;
    }
  }
`;

const StandardSidebar = (props: PropsWithChildren<Props>) => {
  return <SidebarWrap>{props.children}</SidebarWrap>;
};

export default StandardSidebar;
