import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { colors } from "../../styles/colors.styles";
import { PageThemeControllerPoint } from "../site/PageThemeController";
import { SiteNavThemeControllerPoint } from "../site/SiteNavThemeController";
import { styled } from "@linaria/react";
import Spacing from "../layout/Spacing";
import { rSize } from "../../styles/responsiveSizes.styles";
import { HatchingDinoEggIcon } from "../home/spotIcons/HatchingDinoEggIcon";
import { LoadedSkateboardIcon } from "../home/spotIcons/LoadedSkateboardIcon";
import { MagicCrystalBallIcon } from "../home/spotIcons/MagicCrystalBallIcon";
import { ShieldOnFireIcon } from "../home/spotIcons/ShieldOnFireIcon";
import { VintagePCIcon } from "../home/spotIcons/VintagePCIcon";
import { Link } from "gatsby";
import { css } from "linaria";
import { serif } from "../../styles/fonts.styles";
import {
  fromDesktopSm,
  fromTablet,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";

const PageThemeControllerPointPositioner = styled.div`
  position: relative;
  top: 33vh;
`;
const SiteNavThemeControllerPointPositioner = styled.div`
  position: relative;
  top: -50vh;
`;

const Header = styled.header`
  text-align: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktopSm} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const solutionCardStyle = css`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 9.2rem;
  grid-template-rows: auto auto;
  grid-template-areas: "header icon" "footer icon";
  grid-gap: 2.8rem 1.2rem;
  padding: 1.8rem;
  ${fromTabletMd} {
    grid-template-areas: "header header" "footer icon";
    grid-gap: 4.8rem;
    padding: 2.4rem;
  }
  text-decoration: none;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple200};
  border-radius: ${rSize("radius")};
  header {
    grid-area: header;
    h3 {
      font-family: ${serif};
      font-weight: 400;
      font-size: 2rem;
      letter-spacing: -0.01rem;
      ${fromTablet} {
        font-size: 2.4rem;
        letter-spacing: -0.02rem;
      }
    }
    p {
      margin-top: 0.5em;
      font-size: 1.3rem;
      line-height: 1.25;
      max-width: 18em;
      ${fromTablet} {
        font-size: 1.4rem;
        line-height: 1.35;
      }
    }
  }
  footer {
    grid-area: footer;
    align-self: end;
    p {
      font-weight: 600;
      font-size: 1.3rem;
      font-size: 1.3rem;
      line-height: 1.25;
      ${fromTablet} {
        font-size: 1.4rem;
        line-height: 1.35;
      }
    }
    span {
      display: inline-block;
      transition: 0.15s;
    }
  }
  figure {
    margin: 0;
    grid-area: icon;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &:hover {
    border-color: ${colors.purple400};
    footer {
      span {
        transform: translateX(0.25em);
      }
    }
  }
`;

export const ProductPageWhatWillYouBuildSection = () => (
  <PageSection>
    <PageThemeControllerPointPositioner>
      <PageThemeControllerPoint backgroundColor={colors.purple100} />
    </PageThemeControllerPointPositioner>
    <SiteNavThemeControllerPointPositioner>
      <SiteNavThemeControllerPoint
        navThemeColor="purple"
        mobileNavBgColor={colors.purple100}
      />
    </SiteNavThemeControllerPointPositioner>
    <Header>
      <SectionHeading2 lighter>What will you build?</SectionHeading2>
      <Spacing size="sm" />
      <p>Teams like yours build in Tines every day.</p>
    </Header>
    <Spacing size="xxl" multiplier={1.5} />
    <Grid>
      {solutions.map(team => {
        return (
          <Link
            className={solutionCardStyle}
            key={team.name}
            to={`/solutions/${team.name.toLowerCase()}`}
          >
            <header>
              <h3>{team.name}</h3>
              <p>{team.description}</p>
            </header>
            <footer>
              <p>
                Learn more <span>→</span>
              </p>
            </footer>
            <figure>{team.spotIcon}</figure>
          </Link>
        );
      })}
    </Grid>
  </PageSection>
);

const solutions = [
  {
    name: "Security",
    spotIcon: <ShieldOnFireIcon />,
    spotIconOffsetLeft: 0,
    spotIconOffsetLeftMobile: 3.5,
    description:
      "Break away from traditional SOAR. Tines is scalable for the enterprise and accessible for the whole team.",
  },
  {
    name: "IT",
    spotIcon: <VintagePCIcon />,
    spotIconOffsetLeft: -1,
    spotIconOffsetLeftMobile: 1,
    description:
      "Move faster to reduce your backlog and create transparency across your organization.",
  },
  {
    name: "Infrastructure",
    spotIcon: <LoadedSkateboardIcon />,
    spotIconOffsetLeft: -2,
    spotIconOffsetLeftMobile: 0,
    description:
      "Build workflows to connect your systems, and create consistency and transparency as you respond to requests.      ",
  },
  {
    name: "Engineering",
    spotIcon: <MagicCrystalBallIcon />,
    spotIconOffsetLeft: -3,
    spotIconOffsetLeftMobile: 0,
    description:
      "Manage and monitor your software development with automated workflows.",
  },
  {
    name: "Product",
    spotIcon: <HatchingDinoEggIcon />,
    spotIconOffsetLeft: -4,
    spotIconOffsetLeftMobile: 0,
    description:
      "Build in-product workflows and avoid the constraints of embedded iPaaS.",
  },
];
