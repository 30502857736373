export const ConnectedBlocksIcon = () => (
  <svg
    width="89"
    height="73"
    viewBox="0 0 89 73"
    fill="none"
    strokeLinejoin="round"
    strokeMiterlimit="10"
  >
    <path
      d="M87.1315 54.3491H67.3269V59.6883H81.6987L87.1315 54.3491Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M87.9999 40.7172L80.2248 27.25H64.6747L59.5521 32.7544L51.7773 46.2214L59.5521 59.6883H75.1025L80.2248 54.1839L87.9999 40.7172Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M75.1025 32.7544L82.8775 46.2214L75.1025 59.6883H59.5521L51.7773 46.2214L59.5521 32.7544H75.1025Z"
      fill="#FDB2D2"
      stroke="#32313B"
    />
    <path d="M75.1023 32.7544L80.2246 27.25" stroke="#32313B" />
    <path d="M82.877 46.2213L87.9994 40.717" stroke="#32313B" />
    <path
      d="M75.2163 29.0994H68.7786V30.6104H73.8856L75.2163 29.0994Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M73.9298 21.7564C73.9298 20.5042 72.9146 19.4893 71.6623 19.4893H69.5463C68.9193 19.4893 68.3522 19.7442 67.9425 20.1559C67.6204 20.4797 66.7569 21.7819 66.5629 22.0671C66.3167 22.429 66.1731 22.8656 66.1731 23.3364V30.575H72.824L73.9298 28.9951V21.7564Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M72.6594 17.4143H68.5499L67.4441 18.9942V21.0688H71.5534L72.6594 19.489V17.4143Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M71.5534 18.9944H67.4441V21.069H71.5534V18.9944Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path d="M71.5527 18.9942L72.6587 17.4143" stroke="#32313B" />
    <path
      d="M66.1731 23.3362V30.5748H72.824V23.3362C72.824 22.084 71.8087 21.0691 70.5568 21.0691H68.4404C67.1883 21.0691 66.1731 22.0842 66.1731 23.3362Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M32.2385 54.0066H13.1499V59.3457H27.5211L32.2385 54.0066Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M25.1797 27.1025H5.86112L1 32.7256V59.3457H20.3179L25.1797 53.7227V27.1025Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M17.7031 29.0994H11.2649V30.6104H16.372L17.7031 29.0994Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M16.7208 21.7564C16.7208 20.5042 15.7054 19.4893 14.4534 19.4893H12.3373C11.7096 19.4893 11.1431 19.7442 10.7334 20.1559C10.4111 20.4797 9.54743 21.7819 9.35331 22.0671C9.10777 22.429 8.96362 22.8656 8.96362 23.3364V30.575H15.6145L16.7207 28.9951L16.7208 21.7564Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M15.45 17.4143H11.3408L10.2346 18.9942V21.0688H14.3438L15.45 19.489V17.4143Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M14.3436 18.9944H10.2346V21.069H14.3436V18.9944Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path d="M14.3435 18.9942L15.4497 17.4143" stroke="#32313B" />
    <path
      d="M8.96362 23.3362V30.5748H15.6145V23.3362C15.6145 22.084 14.5994 21.0691 13.3473 21.0691H11.2309C9.97902 21.0691 8.96362 22.0842 8.96362 23.3362Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M20.3179 32.7256H1V59.3457H20.3179V32.7256Z"
      fill="#FFD18C"
      stroke="#32313B"
    />
    <path d="M20.3181 32.7256L25.1799 27.1025" stroke="#32313B" />
    <path
      d="M60.5187 64.4982C60.0247 63.9957 59.3138 64.0133 59.3138 64.0133H44.4956L34.4746 71.6824H52.2086C52.2086 71.6824 53.9371 71.7706 54.8153 71.4092C55.6766 71.0546 56.2971 70.3464 56.2971 70.3464L60.5396 65.3853C60.5396 65.3853 60.9495 64.9368 60.5187 64.4982Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M51.9614 39.002H34.95C33.6526 39.002 32.4828 39.5474 31.6568 40.4215C30.8904 41.2327 27.4445 45.0132 26.6248 45.8326C25.8053 46.6523 25.2981 47.7846 25.2981 49.0353V67.1528C25.2981 69.654 27.3257 71.6822 29.8276 71.6822H46.8388C48.1273 71.6822 49.2899 71.1444 50.1146 70.281C50.8914 69.4676 54.4651 65.6153 55.2927 64.7171C56.0364 63.91 56.4909 62.8326 56.4909 61.6485V43.5314C56.4911 41.0295 54.4632 39.002 51.9614 39.002Z"
      fill="#49B889"
      stroke="#32313B"
    />
    <path
      d="M51.3686 67.1529C51.3686 69.6542 49.3407 71.6824 46.8388 71.6824H29.8276C27.3257 71.6824 25.2981 69.6542 25.2981 67.1529V49.0355C25.2981 46.5339 27.3257 44.5063 29.8276 44.5063H46.8388C49.3407 44.5063 51.3686 46.5339 51.3686 49.0355V67.1529Z"
      fill="#81D8B4"
      stroke="#32313B"
    />
    <path d="M47.9238 44.5066L53.2943 39.2014" stroke="#32313B" />
    <path d="M51.3687 48.3006L56.3937 42.8755" stroke="#32313B" />
    <path
      d="M50.4593 40.4756H37.8623V42.7995H48.1292L50.4593 40.4756Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M49.2687 40.5867C49.2687 36.8422 44.3097 36.1277 44.3097 32.2726C44.3097 30.786 44.5321 29.7246 44.5321 29.7246H40.5546H36.9516L35.3773 31.936C35.3773 31.936 35.3773 32.9972 35.3773 34.484C35.3773 34.9756 35.303 35.4156 35.1745 35.8176C35.1289 36.7953 34.9091 37.1882 34.3457 37.9268C34.0077 38.3692 31.6198 40.2939 31.3286 40.8443C31.0313 41.4054 30.845 42.04 30.845 42.7995H39.1326H47.4204C48.4076 41.7262 49.2687 40.5867 49.2687 40.5867Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M42.8876 34.4838C42.8876 32.997 42.8876 31.9358 42.8876 31.9358H39.1326H35.3772C35.3772 31.9358 35.3772 32.997 35.3772 34.4838C35.3772 38.3389 30.845 39.0545 30.845 42.7993H39.1326H47.4204C47.4204 39.0545 42.8876 38.3389 42.8876 34.4838Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path d="M42.8879 31.9358L44.5323 29.7246" stroke="#32313B" />
    <path
      d="M42.6448 27.8977H38.5355L37.4294 29.4774V31.552H41.5388L42.6448 29.9722V27.8977Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M41.5388 29.4773H37.4294V31.5519H41.5388V29.4773Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path d="M41.5049 29.5471L42.6104 27.9673" stroke="#32313B" />
    <path
      d="M41.2813 28.2647H38.7017V17.3194C38.7017 8.32143 46.0221 1.00098 55.0201 1.00098C64.018 1.00098 71.3382 8.32143 71.3382 17.3194V17.9048H68.7586V17.3194C68.7586 9.74392 62.5955 3.58059 55.0201 3.58059C47.4444 3.58059 41.2813 9.74392 41.2813 17.3194V28.2647Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M41.2812 28.7784H38.7016V18.0585C38.7016 11.2656 33.1753 5.73928 26.3823 5.73928C19.5896 5.73928 14.0633 11.2656 14.0633 18.0585H11.4836C11.4836 9.84328 18.1673 3.15967 26.3823 3.15967C34.5976 3.15967 41.2812 9.84328 41.2812 18.0585V28.7784Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M39.9443 11.0156C39.9443 11.0156 41.6003 12.6438 41.9867 13.1957L41.2969 15.1275L39.9443 11.0156Z"
      fill="#32313B"
    />
    <path
      d="M20.3181 4.45074C22.6467 3.63414 25.5183 3.66235 30.101 6.31338"
      stroke="#32313B"
    />
    <path
      d="M56.7967 1.09853C55.0198 1.00131 52.0578 2.13086 49.4048 4.78189"
      stroke="#32313B"
    />
  </svg>
);
