export const ReportV2LargeChapterNumber2Dots = () => (
  <svg width="370" height="400" viewBox="0 0 370 400" fill="currentColor">
    <path
      d="M127.691 106.394C129.09 106.394 130.223 105.26 130.223 103.862C130.223 102.463 129.09 101.33 127.691 101.33C126.293 101.33 125.159 102.463 125.159 103.862C125.159 105.26 126.293 106.394 127.691 106.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M127.691 116.521C129.09 116.521 130.223 115.388 130.223 113.989C130.223 112.591 129.09 111.458 127.691 111.458C126.293 111.458 125.159 112.591 125.159 113.989C125.159 115.388 126.293 116.521 127.691 116.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M137.819 126.649C139.217 126.649 140.351 125.516 140.351 124.117C140.351 122.719 139.217 121.585 137.819 121.585C136.421 121.585 135.287 122.719 135.287 124.117C135.287 125.516 136.421 126.649 137.819 126.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M269.481 187.416C270.88 187.416 272.013 186.283 272.013 184.885C272.013 183.486 270.88 182.353 269.481 182.353C268.083 182.353 266.949 183.486 266.949 184.885C266.949 186.283 268.083 187.416 269.481 187.416Z"
      fillOpacity={0.3}
    />
    <path
      d="M279.609 167.161C281.007 167.161 282.141 166.028 282.141 164.629C282.141 163.231 281.007 162.097 279.609 162.097C278.211 162.097 277.077 163.231 277.077 164.629C277.077 166.028 278.211 167.161 279.609 167.161Z"
      fillOpacity={0.3}
    />
    <path
      d="M289.737 106.394C291.135 106.394 292.269 105.26 292.269 103.862C292.269 102.463 291.135 101.33 289.737 101.33C288.339 101.33 287.205 102.463 287.205 103.862C287.205 105.26 288.339 106.394 289.737 106.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M289.737 116.521C291.135 116.521 292.269 115.388 292.269 113.989C292.269 112.591 291.135 111.458 289.737 111.458C288.339 111.458 287.205 112.591 287.205 113.989C287.205 115.388 288.339 116.521 289.737 116.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M289.737 126.649C291.135 126.649 292.269 125.516 292.269 124.117C292.269 122.719 291.135 121.585 289.737 121.585C288.339 121.585 287.205 122.719 287.205 124.117C287.205 125.516 288.339 126.649 289.737 126.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M289.737 136.777C291.135 136.777 292.269 135.644 292.269 134.245C292.269 132.847 291.135 131.713 289.737 131.713C288.339 131.713 287.205 132.847 287.205 134.245C287.205 135.644 288.339 136.777 289.737 136.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M330.249 136.777C331.647 136.777 332.781 135.644 332.781 134.245C332.781 132.847 331.647 131.713 330.249 131.713C328.85 131.713 327.717 132.847 327.717 134.245C327.717 135.644 328.85 136.777 330.249 136.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M137.819 86.2392C139.217 86.2392 140.351 85.1056 140.351 83.7073C140.351 82.3089 139.217 81.1753 137.819 81.1753C136.421 81.1753 135.287 82.3089 135.287 83.7073C135.287 85.1056 136.421 86.2392 137.819 86.2392Z"
      fillOpacity={0.3}
    />
    <path
      d="M137.819 96.3662C139.217 96.3662 140.351 95.2326 140.351 93.8342C140.351 92.4358 139.217 91.3022 137.819 91.3022C136.421 91.3022 135.287 92.4358 135.287 93.8342C135.287 95.2326 136.421 96.3662 137.819 96.3662Z"
      fillOpacity={0.3}
    />
    <path
      d="M147.947 65.9834C149.345 65.9834 150.479 64.8498 150.479 63.4514C150.479 62.053 149.345 60.9194 147.947 60.9194C146.549 60.9194 145.415 62.053 145.415 63.4514C145.415 64.8498 146.549 65.9834 147.947 65.9834Z"
      fillOpacity={0.3}
    />
    <path
      d="M147.947 76.1103C149.345 76.1103 150.479 74.9767 150.479 73.5784C150.479 72.18 149.345 71.0464 147.947 71.0464C146.549 71.0464 145.415 72.18 145.415 73.5784C145.415 74.9767 146.549 76.1103 147.947 76.1103Z"
      fillOpacity={0.3}
    />
    <path
      d="M158.075 55.8545C159.473 55.8545 160.607 54.7209 160.607 53.3225C160.607 51.9241 159.473 50.7905 158.075 50.7905C156.677 50.7905 155.543 51.9241 155.543 53.3225C155.543 54.7209 156.677 55.8545 158.075 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M168.202 45.7275C169.6 45.7275 170.734 44.5939 170.734 43.1955C170.734 41.7972 169.6 40.6636 168.202 40.6636C166.804 40.6636 165.67 41.7972 165.67 43.1955C165.67 44.5939 166.804 45.7275 168.202 45.7275Z"
      fillOpacity={0.3}
    />
    <path
      d="M178.331 35.5996C179.729 35.5996 180.863 34.466 180.863 33.0676C180.863 31.6692 179.729 30.5356 178.331 30.5356C176.932 30.5356 175.799 31.6692 175.799 33.0676C175.799 34.466 176.932 35.5996 178.331 35.5996Z"
      fillOpacity={0.3}
    />
    <path
      d="M188.458 35.5996C189.856 35.5996 190.99 34.466 190.99 33.0676C190.99 31.6692 189.856 30.5356 188.458 30.5356C187.059 30.5356 185.926 31.6692 185.926 33.0676C185.926 34.466 187.059 35.5996 188.458 35.5996Z"
      fillOpacity={0.3}
    />
    <path
      d="M208.714 25.4716C210.112 25.4716 211.246 24.338 211.246 22.9397C211.246 21.5413 210.112 20.4077 208.714 20.4077C207.315 20.4077 206.182 21.5413 206.182 22.9397C206.182 24.338 207.315 25.4716 208.714 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M218.843 25.4716C220.241 25.4716 221.374 24.338 221.374 22.9397C221.374 21.5413 220.241 20.4077 218.843 20.4077C217.444 20.4077 216.311 21.5413 216.311 22.9397C216.311 24.338 217.444 25.4716 218.843 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M218.843 55.8545C220.241 55.8545 221.374 54.7209 221.374 53.3225C221.374 51.9241 220.241 50.7905 218.843 50.7905C217.444 50.7905 216.311 51.9241 216.311 53.3225C216.311 54.7209 217.444 55.8545 218.843 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M228.969 25.4716C230.368 25.4716 231.501 24.338 231.501 22.9397C231.501 21.5413 230.368 20.4077 228.969 20.4077C227.571 20.4077 226.438 21.5413 226.438 22.9397C226.438 24.338 227.571 25.4716 228.969 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M228.969 55.8545C230.368 55.8545 231.501 54.7209 231.501 53.3225C231.501 51.9241 230.368 50.7905 228.969 50.7905C227.571 50.7905 226.438 51.9241 226.438 53.3225C226.438 54.7209 227.571 55.8545 228.969 55.8545Z"
      fillOpacity={0.3}
    />
    <path
      d="M239.098 25.4716C240.497 25.4716 241.63 24.338 241.63 22.9397C241.63 21.5413 240.497 20.4077 239.098 20.4077C237.7 20.4077 236.566 21.5413 236.566 22.9397C236.566 24.338 237.7 25.4716 239.098 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M249.225 25.4716C250.624 25.4716 251.757 24.338 251.757 22.9397C251.757 21.5413 250.624 20.4077 249.225 20.4077C247.827 20.4077 246.693 21.5413 246.693 22.9397C246.693 24.338 247.827 25.4716 249.225 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M259.354 25.4716C260.753 25.4716 261.886 24.338 261.886 22.9397C261.886 21.5413 260.753 20.4077 259.354 20.4077C257.956 20.4077 256.822 21.5413 256.822 22.9397C256.822 24.338 257.956 25.4716 259.354 25.4716Z"
      fillOpacity={0.3}
    />
    <path
      d="M259.354 65.9834C260.753 65.9834 261.886 64.8498 261.886 63.4514C261.886 62.053 260.753 60.9194 259.354 60.9194C257.956 60.9194 256.822 62.053 256.822 63.4514C256.822 64.8498 257.956 65.9834 259.354 65.9834Z"
      fillOpacity={0.3}
    />
    <path
      d="M269.481 76.1103C270.88 76.1103 272.013 74.9767 272.013 73.5784C272.013 72.18 270.88 71.0464 269.481 71.0464C268.083 71.0464 266.949 72.18 266.949 73.5784C266.949 74.9767 268.083 76.1103 269.481 76.1103Z"
      fillOpacity={0.3}
    />
    <path
      d="M279.609 86.2392C281.007 86.2392 282.141 85.1056 282.141 83.7073C282.141 82.3089 281.007 81.1753 279.609 81.1753C278.211 81.1753 277.077 82.3089 277.077 83.7073C277.077 85.1056 278.211 86.2392 279.609 86.2392Z"
      fillOpacity={0.3}
    />
    <path
      d="M330.249 96.3662C331.647 96.3662 332.781 95.2326 332.781 93.8342C332.781 92.4358 331.647 91.3022 330.249 91.3022C328.85 91.3022 327.717 92.4358 327.717 93.8342C327.717 95.2326 328.85 96.3662 330.249 96.3662Z"
      fillOpacity={0.3}
    />
    <path
      d="M117.563 359.033C118.962 359.033 120.095 357.9 120.095 356.501C120.095 355.103 118.962 353.969 117.563 353.969C116.165 353.969 115.031 355.103 115.031 356.501C115.031 357.9 116.165 359.033 117.563 359.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M117.563 369.161C118.962 369.161 120.095 368.028 120.095 366.629C120.095 365.231 118.962 364.097 117.563 364.097C116.165 364.097 115.031 365.231 115.031 366.629C115.031 368.028 116.165 369.161 117.563 369.161Z"
      fillOpacity={0.3}
    />
    <path
      d="M127.691 348.905C129.09 348.905 130.223 347.772 130.223 346.373C130.223 344.975 129.09 343.841 127.691 343.841C126.293 343.841 125.159 344.975 125.159 346.373C125.159 347.772 126.293 348.905 127.691 348.905Z"
      fillOpacity={0.3}
    />
    <path
      d="M137.819 338.777C139.217 338.777 140.351 337.644 140.351 336.245C140.351 334.847 139.217 333.713 137.819 333.713C136.421 333.713 135.287 334.847 135.287 336.245C135.287 337.644 136.421 338.777 137.819 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M147.947 328.649C149.345 328.649 150.479 327.516 150.479 326.117C150.479 324.719 149.345 323.585 147.947 323.585C146.549 323.585 145.415 324.719 145.415 326.117C145.415 327.516 146.549 328.649 147.947 328.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M158.075 318.521C159.473 318.521 160.607 317.388 160.607 315.989C160.607 314.591 159.473 313.458 158.075 313.458C156.677 313.458 155.543 314.591 155.543 315.989C155.543 317.388 156.677 318.521 158.075 318.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M168.202 308.394C169.6 308.394 170.734 307.26 170.734 305.862C170.734 304.463 169.6 303.33 168.202 303.33C166.804 303.33 165.67 304.463 165.67 305.862C165.67 307.26 166.804 308.394 168.202 308.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M168.202 338.777C169.6 338.777 170.734 337.644 170.734 336.245C170.734 334.847 169.6 333.713 168.202 333.713C166.804 333.713 165.67 334.847 165.67 336.245C165.67 337.644 166.804 338.777 168.202 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M178.331 338.777C179.729 338.777 180.863 337.644 180.863 336.245C180.863 334.847 179.729 333.713 178.331 333.713C176.932 333.713 175.799 334.847 175.799 336.245C175.799 337.644 176.932 338.777 178.331 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M188.458 338.777C189.856 338.777 190.99 337.644 190.99 336.245C190.99 334.847 189.856 333.713 188.458 333.713C187.059 333.713 185.926 334.847 185.926 336.245C185.926 337.644 187.059 338.777 188.458 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M198.587 338.777C199.985 338.777 201.119 337.644 201.119 336.245C201.119 334.847 199.985 333.713 198.587 333.713C197.188 333.713 196.055 334.847 196.055 336.245C196.055 337.644 197.188 338.777 198.587 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M208.714 338.777C210.112 338.777 211.246 337.644 211.246 336.245C211.246 334.847 210.112 333.713 208.714 333.713C207.315 333.713 206.182 334.847 206.182 336.245C206.182 337.644 207.315 338.777 208.714 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M218.843 338.777C220.241 338.777 221.374 337.644 221.374 336.245C221.374 334.847 220.241 333.713 218.843 333.713C217.444 333.713 216.311 334.847 216.311 336.245C216.311 337.644 217.444 338.777 218.843 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M228.969 338.777C230.368 338.777 231.501 337.644 231.501 336.245C231.501 334.847 230.368 333.713 228.969 333.713C227.571 333.713 226.438 334.847 226.438 336.245C226.438 337.644 227.571 338.777 228.969 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M239.098 338.777C240.497 338.777 241.63 337.644 241.63 336.245C241.63 334.847 240.497 333.713 239.098 333.713C237.7 333.713 236.566 334.847 236.566 336.245C236.566 337.644 237.7 338.777 239.098 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M249.225 338.777C250.624 338.777 251.757 337.644 251.757 336.245C251.757 334.847 250.624 333.713 249.225 333.713C247.827 333.713 246.693 334.847 246.693 336.245C246.693 337.644 247.827 338.777 249.225 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M259.354 338.777C260.753 338.777 261.886 337.644 261.886 336.245C261.886 334.847 260.753 333.713 259.354 333.713C257.956 333.713 256.822 334.847 256.822 336.245C256.822 337.644 257.956 338.777 259.354 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M269.481 338.777C270.88 338.777 272.013 337.644 272.013 336.245C272.013 334.847 270.88 333.713 269.481 333.713C268.083 333.713 266.949 334.847 266.949 336.245C266.949 337.644 268.083 338.777 269.481 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M279.609 338.777C281.007 338.777 282.141 337.644 282.141 336.245C282.141 334.847 281.007 333.713 279.609 333.713C278.211 333.713 277.077 334.847 277.077 336.245C277.077 337.644 278.211 338.777 279.609 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M320.121 328.649C321.519 328.649 322.653 327.516 322.653 326.117C322.653 324.719 321.519 323.585 320.121 323.585C318.722 323.585 317.589 324.719 317.589 326.117C317.589 327.516 318.722 328.649 320.121 328.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M178.331 298.366C179.729 298.366 180.863 297.233 180.863 295.834C180.863 294.436 179.729 293.302 178.331 293.302C176.932 293.302 175.799 294.436 175.799 295.834C175.799 297.233 176.932 298.366 178.331 298.366Z"
      fillOpacity={0.3}
    />
    <path
      d="M188.458 288.239C189.856 288.239 190.99 287.106 190.99 285.707C190.99 284.309 189.856 283.175 188.458 283.175C187.059 283.175 185.926 284.309 185.926 285.707C185.926 287.106 187.059 288.239 188.458 288.239Z"
      fillOpacity={0.3}
    />
    <path
      d="M198.587 278.11C199.985 278.11 201.119 276.977 201.119 275.578C201.119 274.18 199.985 273.046 198.587 273.046C197.188 273.046 196.055 274.18 196.055 275.578C196.055 276.977 197.188 278.11 198.587 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M208.714 267.983C210.112 267.983 211.246 266.85 211.246 265.451C211.246 264.053 210.112 262.919 208.714 262.919C207.315 262.919 206.182 264.053 206.182 265.451C206.182 266.85 207.315 267.983 208.714 267.983Z"
      fillOpacity={0.3}
    />
    <path
      d="M218.843 257.854C220.241 257.854 221.374 256.721 221.374 255.322C221.374 253.924 220.241 252.791 218.843 252.791C217.444 252.791 216.311 253.924 216.311 255.322C216.311 256.721 217.444 257.854 218.843 257.854Z"
      fillOpacity={0.3}
    />
    <path
      d="M239.098 227.472C240.497 227.472 241.63 226.338 241.63 224.94C241.63 223.541 240.497 222.408 239.098 222.408C237.7 222.408 236.566 223.541 236.566 224.94C236.566 226.338 237.7 227.472 239.098 227.472Z"
      fillOpacity={0.3}
    />
    <path
      d="M249.225 217.344C250.624 217.344 251.757 216.21 251.757 214.812C251.757 213.413 250.624 212.28 249.225 212.28C247.827 212.28 246.693 213.413 246.693 214.812C246.693 216.21 247.827 217.344 249.225 217.344Z"
      fillOpacity={0.3}
    />
    <path
      d="M259.354 207.216C260.753 207.216 261.886 206.082 261.886 204.684C261.886 203.285 260.753 202.152 259.354 202.152C257.956 202.152 256.822 203.285 256.822 204.684C256.822 206.082 257.956 207.216 259.354 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M299.865 207.216C301.263 207.216 302.397 206.082 302.397 204.684C302.397 203.285 301.263 202.152 299.865 202.152C298.467 202.152 297.333 203.285 297.333 204.684C297.333 206.082 298.467 207.216 299.865 207.216Z"
      fillOpacity={0.3}
    />
    <path d="M309.992 136.777C311.39 136.777 312.524 135.644 312.524 134.245C312.524 132.847 311.39 131.713 309.992 131.713C308.594 131.713 307.46 132.847 307.46 134.245C307.46 135.644 308.594 136.777 309.992 136.777Z" />
    <path d="M330.247 126.649C331.645 126.649 332.779 125.516 332.779 124.117C332.779 122.719 331.645 121.585 330.247 121.585C328.848 121.585 327.715 122.719 327.715 124.117C327.715 125.516 328.848 126.649 330.247 126.649Z" />
    <path d="M158.074 86.2387C159.472 86.2387 160.606 85.1051 160.606 83.7068C160.606 82.3084 159.472 81.1748 158.074 81.1748C156.676 81.1748 155.542 82.3084 155.542 83.7068C155.542 85.1051 156.676 86.2387 158.074 86.2387Z" />
    <path d="M137.818 106.394C139.216 106.394 140.35 105.26 140.35 103.862C140.35 102.463 139.216 101.33 137.818 101.33C136.42 101.33 135.286 102.463 135.286 103.862C135.286 105.26 136.42 106.394 137.818 106.394Z" />
    <path d="M137.818 116.521C139.216 116.521 140.35 115.388 140.35 113.989C140.35 112.591 139.216 111.458 137.818 111.458C136.42 111.458 135.286 112.591 135.286 113.989C135.286 115.388 136.42 116.521 137.818 116.521Z" />
    <path d="M269.48 197.544C270.879 197.544 272.012 196.411 272.012 195.012C272.012 193.614 270.879 192.48 269.48 192.48C268.082 192.48 266.948 193.614 266.948 195.012C266.948 196.411 268.082 197.544 269.48 197.544Z" />
    <path d="M279.608 177.289C281.007 177.289 282.14 176.155 282.14 174.757C282.14 173.359 281.007 172.225 279.608 172.225C278.21 172.225 277.076 173.359 277.076 174.757C277.076 176.155 278.21 177.289 279.608 177.289Z" />
    <path d="M279.608 187.417C281.007 187.417 282.14 186.283 282.14 184.885C282.14 183.487 281.007 182.353 279.608 182.353C278.21 182.353 277.076 183.487 277.076 184.885C277.076 186.283 278.21 187.417 279.608 187.417Z" />
    <path d="M279.608 197.544C281.007 197.544 282.14 196.411 282.14 195.012C282.14 193.614 281.007 192.48 279.608 192.48C278.21 192.48 277.076 193.614 277.076 195.012C277.076 196.411 278.21 197.544 279.608 197.544Z" />
    <path d="M289.736 146.905C291.134 146.905 292.268 145.772 292.268 144.373C292.268 142.975 291.134 141.841 289.736 141.841C288.338 141.841 287.204 142.975 287.204 144.373C287.204 145.772 288.338 146.905 289.736 146.905Z" />
    <path d="M289.736 157.033C291.134 157.033 292.268 155.9 292.268 154.501C292.268 153.103 291.134 151.969 289.736 151.969C288.338 151.969 287.204 153.103 287.204 154.501C287.204 155.9 288.338 157.033 289.736 157.033Z" />
    <path d="M289.736 167.161C291.134 167.161 292.268 166.028 292.268 164.629C292.268 163.231 291.134 162.097 289.736 162.097C288.338 162.097 287.204 163.231 287.204 164.629C287.204 166.028 288.338 167.161 289.736 167.161Z" />
    <path d="M289.736 177.289C291.134 177.289 292.268 176.155 292.268 174.757C292.268 173.359 291.134 172.225 289.736 172.225C288.338 172.225 287.204 173.359 287.204 174.757C287.204 176.155 288.338 177.289 289.736 177.289Z" />
    <path d="M289.736 187.417C291.134 187.417 292.268 186.283 292.268 184.885C292.268 183.487 291.134 182.353 289.736 182.353C288.338 182.353 287.204 183.487 287.204 184.885C287.204 186.283 288.338 187.417 289.736 187.417Z" />
    <path d="M289.736 197.544C291.134 197.544 292.268 196.411 292.268 195.012C292.268 193.614 291.134 192.48 289.736 192.48C288.338 192.48 287.204 193.614 287.204 195.012C287.204 196.411 288.338 197.544 289.736 197.544Z" />
    <path d="M299.864 106.394C301.262 106.394 302.396 105.26 302.396 103.862C302.396 102.463 301.262 101.33 299.864 101.33C298.466 101.33 297.332 102.463 297.332 103.862C297.332 105.26 298.466 106.394 299.864 106.394Z" />
    <path d="M299.864 116.521C301.262 116.521 302.396 115.388 302.396 113.989C302.396 112.591 301.262 111.458 299.864 111.458C298.466 111.458 297.332 112.591 297.332 113.989C297.332 115.388 298.466 116.521 299.864 116.521Z" />
    <path d="M299.864 126.649C301.262 126.649 302.396 125.516 302.396 124.117C302.396 122.719 301.262 121.585 299.864 121.585C298.466 121.585 297.332 122.719 297.332 124.117C297.332 125.516 298.466 126.649 299.864 126.649Z" />
    <path d="M299.864 136.777C301.262 136.777 302.396 135.644 302.396 134.245C302.396 132.847 301.262 131.713 299.864 131.713C298.466 131.713 297.332 132.847 297.332 134.245C297.332 135.644 298.466 136.777 299.864 136.777Z" />
    <path d="M299.864 146.905C301.262 146.905 302.396 145.772 302.396 144.373C302.396 142.975 301.262 141.841 299.864 141.841C298.466 141.841 297.332 142.975 297.332 144.373C297.332 145.772 298.466 146.905 299.864 146.905Z" />
    <path d="M299.864 157.033C301.262 157.033 302.396 155.9 302.396 154.501C302.396 153.103 301.262 151.969 299.864 151.969C298.466 151.969 297.332 153.103 297.332 154.501C297.332 155.9 298.466 157.033 299.864 157.033Z" />
    <path d="M299.864 167.161C301.262 167.161 302.396 166.028 302.396 164.629C302.396 163.231 301.262 162.097 299.864 162.097C298.466 162.097 297.332 163.231 297.332 164.629C297.332 166.028 298.466 167.161 299.864 167.161Z" />
    <path d="M299.864 177.289C301.262 177.289 302.396 176.155 302.396 174.757C302.396 173.359 301.262 172.225 299.864 172.225C298.466 172.225 297.332 173.359 297.332 174.757C297.332 176.155 298.466 177.289 299.864 177.289Z" />
    <path d="M299.864 187.417C301.262 187.417 302.396 186.283 302.396 184.885C302.396 183.487 301.262 182.353 299.864 182.353C298.466 182.353 297.332 183.487 297.332 184.885C297.332 186.283 298.466 187.417 299.864 187.417Z" />
    <path d="M299.864 197.544C301.262 197.544 302.396 196.411 302.396 195.012C302.396 193.614 301.262 192.48 299.864 192.48C298.466 192.48 297.332 193.614 297.332 195.012C297.332 196.411 298.466 197.544 299.864 197.544Z" />
    <path d="M309.992 106.394C311.39 106.394 312.524 105.26 312.524 103.862C312.524 102.463 311.39 101.33 309.992 101.33C308.594 101.33 307.46 102.463 307.46 103.862C307.46 105.26 308.594 106.394 309.992 106.394Z" />
    <path d="M309.992 116.521C311.39 116.521 312.524 115.388 312.524 113.989C312.524 112.591 311.39 111.458 309.992 111.458C308.594 111.458 307.46 112.591 307.46 113.989C307.46 115.388 308.594 116.521 309.992 116.521Z" />
    <path d="M309.992 126.649C311.39 126.649 312.524 125.516 312.524 124.117C312.524 122.719 311.39 121.585 309.992 121.585C308.594 121.585 307.46 122.719 307.46 124.117C307.46 125.516 308.594 126.649 309.992 126.649Z" />
    <path d="M309.992 146.905C311.39 146.905 312.524 145.772 312.524 144.373C312.524 142.975 311.39 141.841 309.992 141.841C308.594 141.841 307.46 142.975 307.46 144.373C307.46 145.772 308.594 146.905 309.992 146.905Z" />
    <path d="M309.992 157.033C311.39 157.033 312.524 155.9 312.524 154.501C312.524 153.103 311.39 151.969 309.992 151.969C308.594 151.969 307.46 153.103 307.46 154.501C307.46 155.9 308.594 157.033 309.992 157.033Z" />
    <path d="M309.992 167.161C311.39 167.161 312.524 166.028 312.524 164.629C312.524 163.231 311.39 162.097 309.992 162.097C308.594 162.097 307.46 163.231 307.46 164.629C307.46 166.028 308.594 167.161 309.992 167.161Z" />
    <path d="M309.992 177.289C311.39 177.289 312.524 176.155 312.524 174.757C312.524 173.359 311.39 172.225 309.992 172.225C308.594 172.225 307.46 173.359 307.46 174.757C307.46 176.155 308.594 177.289 309.992 177.289Z" />
    <path d="M309.992 187.417C311.39 187.417 312.524 186.283 312.524 184.885C312.524 183.487 311.39 182.353 309.992 182.353C308.594 182.353 307.46 183.487 307.46 184.885C307.46 186.283 308.594 187.417 309.992 187.417Z" />
    <path d="M320.12 106.394C321.518 106.394 322.652 105.26 322.652 103.862C322.652 102.463 321.518 101.33 320.12 101.33C318.721 101.33 317.588 102.463 317.588 103.862C317.588 105.26 318.721 106.394 320.12 106.394Z" />
    <path d="M320.12 116.521C321.518 116.521 322.652 115.388 322.652 113.989C322.652 112.591 321.518 111.458 320.12 111.458C318.721 111.458 317.588 112.591 317.588 113.989C317.588 115.388 318.721 116.521 320.12 116.521Z" />
    <path d="M320.12 126.649C321.518 126.649 322.652 125.516 322.652 124.117C322.652 122.719 321.518 121.585 320.12 121.585C318.721 121.585 317.588 122.719 317.588 124.117C317.588 125.516 318.721 126.649 320.12 126.649Z" />
    <path d="M320.12 136.777C321.518 136.777 322.652 135.644 322.652 134.245C322.652 132.847 321.518 131.713 320.12 131.713C318.721 131.713 317.588 132.847 317.588 134.245C317.588 135.644 318.721 136.777 320.12 136.777Z" />
    <path d="M320.12 146.905C321.518 146.905 322.652 145.772 322.652 144.373C322.652 142.975 321.518 141.841 320.12 141.841C318.721 141.841 317.588 142.975 317.588 144.373C317.588 145.772 318.721 146.905 320.12 146.905Z" />
    <path d="M320.12 157.033C321.518 157.033 322.652 155.9 322.652 154.501C322.652 153.103 321.518 151.969 320.12 151.969C318.721 151.969 317.588 153.103 317.588 154.501C317.588 155.9 318.721 157.033 320.12 157.033Z" />
    <path d="M320.12 167.161C321.518 167.161 322.652 166.028 322.652 164.629C322.652 163.231 321.518 162.097 320.12 162.097C318.721 162.097 317.588 163.231 317.588 164.629C317.588 166.028 318.721 167.161 320.12 167.161Z" />
    <path d="M330.247 106.394C331.645 106.394 332.779 105.26 332.779 103.862C332.779 102.463 331.645 101.33 330.247 101.33C328.848 101.33 327.715 102.463 327.715 103.862C327.715 105.26 328.848 106.394 330.247 106.394Z" />
    <path d="M330.247 116.521C331.645 116.521 332.779 115.388 332.779 113.989C332.779 112.591 331.645 111.458 330.247 111.458C328.848 111.458 327.715 112.591 327.715 113.989C327.715 115.388 328.848 116.521 330.247 116.521Z" />
    <path d="M147.946 86.2387C149.344 86.2387 150.478 85.1051 150.478 83.7068C150.478 82.3084 149.344 81.1748 147.946 81.1748C146.548 81.1748 145.414 82.3084 145.414 83.7068C145.414 85.1051 146.548 86.2387 147.946 86.2387Z" />
    <path d="M147.946 96.3667C149.344 96.3667 150.478 95.2331 150.478 93.8347C150.478 92.4363 149.344 91.3027 147.946 91.3027C146.548 91.3027 145.414 92.4363 145.414 93.8347C145.414 95.2331 146.548 96.3667 147.946 96.3667Z" />
    <path d="M158.074 65.9829C159.472 65.9829 160.606 64.8493 160.606 63.4509C160.606 62.0525 159.472 60.9189 158.074 60.9189C156.676 60.9189 155.542 62.0525 155.542 63.4509C155.542 64.8493 156.676 65.9829 158.074 65.9829Z" />
    <path d="M158.074 76.1108C159.472 76.1108 160.606 74.9772 160.606 73.5788C160.606 72.1805 159.472 71.0469 158.074 71.0469C156.676 71.0469 155.542 72.1805 155.542 73.5788C155.542 74.9772 156.676 76.1108 158.074 76.1108Z" />
    <path d="M168.202 55.8554C169.6 55.8554 170.734 54.7218 170.734 53.3235C170.734 51.9251 169.6 50.7915 168.202 50.7915C166.804 50.7915 165.67 51.9251 165.67 53.3235C165.67 54.7218 166.804 55.8554 168.202 55.8554Z" />
    <path d="M168.202 65.9834C169.6 65.9834 170.734 64.8498 170.734 63.4514C170.734 62.053 169.6 60.9194 168.202 60.9194C166.804 60.9194 165.67 62.053 165.67 63.4514C165.67 64.8498 166.804 65.9834 168.202 65.9834Z" />
    <path d="M168.202 76.1113C169.6 76.1113 170.734 74.9777 170.734 73.5793C170.734 72.181 169.6 71.0474 168.202 71.0474C166.804 71.0474 165.67 72.181 165.67 73.5793C165.67 74.9777 166.804 76.1113 168.202 76.1113Z" />
    <path d="M178.329 45.7275C179.727 45.7275 180.861 44.5939 180.861 43.1955C180.861 41.7972 179.727 40.6636 178.329 40.6636C176.93 40.6636 175.797 41.7972 175.797 43.1955C175.797 44.5939 176.93 45.7275 178.329 45.7275Z" />
    <path d="M178.329 55.8554C179.727 55.8554 180.861 54.7218 180.861 53.3235C180.861 51.9251 179.727 50.7915 178.329 50.7915C176.93 50.7915 175.797 51.9251 175.797 53.3235C175.797 54.7218 176.93 55.8554 178.329 55.8554Z" />
    <path d="M178.329 65.9834C179.727 65.9834 180.861 64.8498 180.861 63.4514C180.861 62.053 179.727 60.9194 178.329 60.9194C176.93 60.9194 175.797 62.053 175.797 63.4514C175.797 64.8498 176.93 65.9834 178.329 65.9834Z" />
    <path d="M188.458 45.7275C189.856 45.7275 190.99 44.5939 190.99 43.1955C190.99 41.7972 189.856 40.6636 188.458 40.6636C187.059 40.6636 185.926 41.7972 185.926 43.1955C185.926 44.5939 187.059 45.7275 188.458 45.7275Z" />
    <path d="M188.458 55.8554C189.856 55.8554 190.99 54.7218 190.99 53.3235C190.99 51.9251 189.856 50.7915 188.458 50.7915C187.059 50.7915 185.926 51.9251 185.926 53.3235C185.926 54.7218 187.059 55.8554 188.458 55.8554Z" />
    <path d="M198.585 35.5996C199.983 35.5996 201.117 34.466 201.117 33.0676C201.117 31.6692 199.983 30.5356 198.585 30.5356C197.186 30.5356 196.053 31.6692 196.053 33.0676C196.053 34.466 197.186 35.5996 198.585 35.5996Z" />
    <path d="M198.585 45.7275C199.983 45.7275 201.117 44.5939 201.117 43.1955C201.117 41.7972 199.983 40.6636 198.585 40.6636C197.186 40.6636 196.053 41.7972 196.053 43.1955C196.053 44.5939 197.186 45.7275 198.585 45.7275Z" />
    <path d="M198.585 55.8554C199.983 55.8554 201.117 54.7218 201.117 53.3235C201.117 51.9251 199.983 50.7915 198.585 50.7915C197.186 50.7915 196.053 51.9251 196.053 53.3235C196.053 54.7218 197.186 55.8554 198.585 55.8554Z" />
    <path d="M208.714 35.5996C210.112 35.5996 211.246 34.466 211.246 33.0676C211.246 31.6692 210.112 30.5356 208.714 30.5356C207.315 30.5356 206.182 31.6692 206.182 33.0676C206.182 34.466 207.315 35.5996 208.714 35.5996Z" />
    <path d="M208.714 45.7275C210.112 45.7275 211.246 44.5939 211.246 43.1955C211.246 41.7972 210.112 40.6636 208.714 40.6636C207.315 40.6636 206.182 41.7972 206.182 43.1955C206.182 44.5939 207.315 45.7275 208.714 45.7275Z" />
    <path d="M208.714 55.8554C210.112 55.8554 211.246 54.7218 211.246 53.3235C211.246 51.9251 210.112 50.7915 208.714 50.7915C207.315 50.7915 206.182 51.9251 206.182 53.3235C206.182 54.7218 207.315 55.8554 208.714 55.8554Z" />
    <path d="M218.841 35.5996C220.239 35.5996 221.373 34.466 221.373 33.0676C221.373 31.6692 220.239 30.5356 218.841 30.5356C217.442 30.5356 216.309 31.6692 216.309 33.0676C216.309 34.466 217.442 35.5996 218.841 35.5996Z" />
    <path d="M218.841 45.7275C220.239 45.7275 221.373 44.5939 221.373 43.1955C221.373 41.7972 220.239 40.6636 218.841 40.6636C217.442 40.6636 216.309 41.7972 216.309 43.1955C216.309 44.5939 217.442 45.7275 218.841 45.7275Z" />
    <path d="M228.969 35.5996C230.368 35.5996 231.501 34.466 231.501 33.0676C231.501 31.6692 230.368 30.5356 228.969 30.5356C227.571 30.5356 226.438 31.6692 226.438 33.0676C226.438 34.466 227.571 35.5996 228.969 35.5996Z" />
    <path d="M228.969 45.7275C230.368 45.7275 231.501 44.5939 231.501 43.1955C231.501 41.7972 230.368 40.6636 228.969 40.6636C227.571 40.6636 226.438 41.7972 226.438 43.1955C226.438 44.5939 227.571 45.7275 228.969 45.7275Z" />
    <path d="M239.096 35.5996C240.495 35.5996 241.628 34.466 241.628 33.0676C241.628 31.6692 240.495 30.5356 239.096 30.5356C237.698 30.5356 236.564 31.6692 236.564 33.0676C236.564 34.466 237.698 35.5996 239.096 35.5996Z" />
    <path d="M239.096 45.7275C240.495 45.7275 241.628 44.5939 241.628 43.1955C241.628 41.7972 240.495 40.6636 239.096 40.6636C237.698 40.6636 236.564 41.7972 236.564 43.1955C236.564 44.5939 237.698 45.7275 239.096 45.7275Z" />
    <path d="M239.096 55.8554C240.495 55.8554 241.628 54.7218 241.628 53.3235C241.628 51.9251 240.495 50.7915 239.096 50.7915C237.698 50.7915 236.564 51.9251 236.564 53.3235C236.564 54.7218 237.698 55.8554 239.096 55.8554Z" />
    <path d="M249.225 35.5996C250.624 35.5996 251.757 34.466 251.757 33.0676C251.757 31.6692 250.624 30.5356 249.225 30.5356C247.827 30.5356 246.693 31.6692 246.693 33.0676C246.693 34.466 247.827 35.5996 249.225 35.5996Z" />
    <path d="M249.225 45.7275C250.624 45.7275 251.757 44.5939 251.757 43.1955C251.757 41.7972 250.624 40.6636 249.225 40.6636C247.827 40.6636 246.693 41.7972 246.693 43.1955C246.693 44.5939 247.827 45.7275 249.225 45.7275Z" />
    <path d="M249.225 55.8554C250.624 55.8554 251.757 54.7218 251.757 53.3235C251.757 51.9251 250.624 50.7915 249.225 50.7915C247.827 50.7915 246.693 51.9251 246.693 53.3235C246.693 54.7218 247.827 55.8554 249.225 55.8554Z" />
    <path d="M259.352 35.5996C260.751 35.5996 261.884 34.466 261.884 33.0676C261.884 31.6692 260.751 30.5356 259.352 30.5356C257.954 30.5356 256.82 31.6692 256.82 33.0676C256.82 34.466 257.954 35.5996 259.352 35.5996Z" />
    <path d="M259.352 45.7275C260.751 45.7275 261.884 44.5939 261.884 43.1955C261.884 41.7972 260.751 40.6636 259.352 40.6636C257.954 40.6636 256.82 41.7972 256.82 43.1955C256.82 44.5939 257.954 45.7275 259.352 45.7275Z" />
    <path d="M259.352 55.8554C260.751 55.8554 261.884 54.7218 261.884 53.3235C261.884 51.9251 260.751 50.7915 259.352 50.7915C257.954 50.7915 256.82 51.9251 256.82 53.3235C256.82 54.7218 257.954 55.8554 259.352 55.8554Z" />
    <path d="M269.48 35.5996C270.879 35.5996 272.012 34.466 272.012 33.0676C272.012 31.6692 270.879 30.5356 269.48 30.5356C268.082 30.5356 266.948 31.6692 266.948 33.0676C266.948 34.466 268.082 35.5996 269.48 35.5996Z" />
    <path d="M269.48 45.727C270.879 45.727 272.012 44.5934 272.012 43.1951C272.012 41.7967 270.879 40.6631 269.48 40.6631C268.082 40.6631 266.948 41.7967 266.948 43.1951C266.948 44.5934 268.082 45.727 269.48 45.727Z" />
    <path d="M269.48 55.8549C270.879 55.8549 272.012 54.7213 272.012 53.323C272.012 51.9246 270.879 50.791 269.48 50.791C268.082 50.791 266.948 51.9246 266.948 53.323C266.948 54.7213 268.082 55.8549 269.48 55.8549Z" />
    <path d="M269.48 65.9829C270.879 65.9829 272.012 64.8493 272.012 63.4509C272.012 62.0525 270.879 60.9189 269.48 60.9189C268.082 60.9189 266.948 62.0525 266.948 63.4509C266.948 64.8493 268.082 65.9829 269.48 65.9829Z" />
    <path d="M279.608 35.5996C281.007 35.5996 282.14 34.466 282.14 33.0676C282.14 31.6692 281.007 30.5356 279.608 30.5356C278.21 30.5356 277.076 31.6692 277.076 33.0676C277.076 34.466 278.21 35.5996 279.608 35.5996Z" />
    <path d="M279.608 45.727C281.007 45.727 282.14 44.5934 282.14 43.1951C282.14 41.7967 281.007 40.6631 279.608 40.6631C278.21 40.6631 277.076 41.7967 277.076 43.1951C277.076 44.5934 278.21 45.727 279.608 45.727Z" />
    <path d="M279.608 55.8549C281.007 55.8549 282.14 54.7213 282.14 53.323C282.14 51.9246 281.007 50.791 279.608 50.791C278.21 50.791 277.076 51.9246 277.076 53.323C277.076 54.7213 278.21 55.8549 279.608 55.8549Z" />
    <path d="M279.608 65.9829C281.007 65.9829 282.14 64.8493 282.14 63.4509C282.14 62.0525 281.007 60.9189 279.608 60.9189C278.21 60.9189 277.076 62.0525 277.076 63.4509C277.076 64.8493 278.21 65.9829 279.608 65.9829Z" />
    <path d="M279.608 76.1108C281.007 76.1108 282.14 74.9772 282.14 73.5788C282.14 72.1805 281.007 71.0469 279.608 71.0469C278.21 71.0469 277.076 72.1805 277.076 73.5788C277.076 74.9772 278.21 76.1108 279.608 76.1108Z" />
    <path d="M289.736 45.727C291.134 45.727 292.268 44.5934 292.268 43.1951C292.268 41.7967 291.134 40.6631 289.736 40.6631C288.338 40.6631 287.204 41.7967 287.204 43.1951C287.204 44.5934 288.338 45.727 289.736 45.727Z" />
    <path d="M289.736 55.8549C291.134 55.8549 292.268 54.7213 292.268 53.323C292.268 51.9246 291.134 50.791 289.736 50.791C288.338 50.791 287.204 51.9246 287.204 53.323C287.204 54.7213 288.338 55.8549 289.736 55.8549Z" />
    <path d="M289.736 65.9829C291.134 65.9829 292.268 64.8493 292.268 63.4509C292.268 62.0525 291.134 60.9189 289.736 60.9189C288.338 60.9189 287.204 62.0525 287.204 63.4509C287.204 64.8493 288.338 65.9829 289.736 65.9829Z" />
    <path d="M289.736 76.1108C291.134 76.1108 292.268 74.9772 292.268 73.5788C292.268 72.1805 291.134 71.0469 289.736 71.0469C288.338 71.0469 287.204 72.1805 287.204 73.5788C287.204 74.9772 288.338 76.1108 289.736 76.1108Z" />
    <path d="M289.736 86.2387C291.134 86.2387 292.268 85.1051 292.268 83.7068C292.268 82.3084 291.134 81.1748 289.736 81.1748C288.338 81.1748 287.204 82.3084 287.204 83.7068C287.204 85.1051 288.338 86.2387 289.736 86.2387Z" />
    <path d="M289.736 96.3667C291.134 96.3667 292.268 95.2331 292.268 93.8347C292.268 92.4363 291.134 91.3027 289.736 91.3027C288.338 91.3027 287.204 92.4363 287.204 93.8347C287.204 95.2331 288.338 96.3667 289.736 96.3667Z" />
    <path d="M299.864 45.727C301.262 45.727 302.396 44.5934 302.396 43.1951C302.396 41.7967 301.262 40.6631 299.864 40.6631C298.466 40.6631 297.332 41.7967 297.332 43.1951C297.332 44.5934 298.466 45.727 299.864 45.727Z" />
    <path d="M299.864 55.8549C301.262 55.8549 302.396 54.7213 302.396 53.323C302.396 51.9246 301.262 50.791 299.864 50.791C298.466 50.791 297.332 51.9246 297.332 53.323C297.332 54.7213 298.466 55.8549 299.864 55.8549Z" />
    <path d="M299.864 65.9829C301.262 65.9829 302.396 64.8493 302.396 63.4509C302.396 62.0525 301.262 60.9189 299.864 60.9189C298.466 60.9189 297.332 62.0525 297.332 63.4509C297.332 64.8493 298.466 65.9829 299.864 65.9829Z" />
    <path d="M299.864 76.1108C301.262 76.1108 302.396 74.9772 302.396 73.5788C302.396 72.1805 301.262 71.0469 299.864 71.0469C298.466 71.0469 297.332 72.1805 297.332 73.5788C297.332 74.9772 298.466 76.1108 299.864 76.1108Z" />
    <path d="M299.864 86.2387C301.262 86.2387 302.396 85.1051 302.396 83.7068C302.396 82.3084 301.262 81.1748 299.864 81.1748C298.466 81.1748 297.332 82.3084 297.332 83.7068C297.332 85.1051 298.466 86.2387 299.864 86.2387Z" />
    <path d="M299.864 96.3667C301.262 96.3667 302.396 95.2331 302.396 93.8347C302.396 92.4363 301.262 91.3027 299.864 91.3027C298.466 91.3027 297.332 92.4363 297.332 93.8347C297.332 95.2331 298.466 96.3667 299.864 96.3667Z" />
    <path d="M309.992 55.8549C311.39 55.8549 312.524 54.7213 312.524 53.323C312.524 51.9246 311.39 50.791 309.992 50.791C308.594 50.791 307.46 51.9246 307.46 53.323C307.46 54.7213 308.594 55.8549 309.992 55.8549Z" />
    <path d="M309.992 65.9829C311.39 65.9829 312.524 64.8493 312.524 63.4509C312.524 62.0525 311.39 60.9189 309.992 60.9189C308.594 60.9189 307.46 62.0525 307.46 63.4509C307.46 64.8493 308.594 65.9829 309.992 65.9829Z" />
    <path d="M309.992 76.1108C311.39 76.1108 312.524 74.9772 312.524 73.5788C312.524 72.1805 311.39 71.0469 309.992 71.0469C308.594 71.0469 307.46 72.1805 307.46 73.5788C307.46 74.9772 308.594 76.1108 309.992 76.1108Z" />
    <path d="M309.992 86.2387C311.39 86.2387 312.524 85.1051 312.524 83.7068C312.524 82.3084 311.39 81.1748 309.992 81.1748C308.594 81.1748 307.46 82.3084 307.46 83.7068C307.46 85.1051 308.594 86.2387 309.992 86.2387Z" />
    <path d="M309.992 96.3667C311.39 96.3667 312.524 95.2331 312.524 93.8347C312.524 92.4363 311.39 91.3027 309.992 91.3027C308.594 91.3027 307.46 92.4363 307.46 93.8347C307.46 95.2331 308.594 96.3667 309.992 96.3667Z" />
    <path d="M320.12 76.1108C321.518 76.1108 322.652 74.9772 322.652 73.5788C322.652 72.1805 321.518 71.0469 320.12 71.0469C318.721 71.0469 317.588 72.1805 317.588 73.5788C317.588 74.9772 318.721 76.1108 320.12 76.1108Z" />
    <path d="M320.12 86.2387C321.518 86.2387 322.652 85.1051 322.652 83.7068C322.652 82.3084 321.518 81.1748 320.12 81.1748C318.721 81.1748 317.588 82.3084 317.588 83.7068C317.588 85.1051 318.721 86.2387 320.12 86.2387Z" />
    <path d="M320.12 96.3667C321.518 96.3667 322.652 95.2331 322.652 93.8347C322.652 92.4363 321.518 91.3027 320.12 91.3027C318.721 91.3027 317.588 92.4363 317.588 93.8347C317.588 95.2331 318.721 96.3667 320.12 96.3667Z" />
    <path d="M127.69 359.033C129.089 359.033 130.222 357.9 130.222 356.501C130.222 355.103 129.089 353.969 127.69 353.969C126.292 353.969 125.158 355.103 125.158 356.501C125.158 357.9 126.292 359.033 127.69 359.033Z" />
    <path d="M127.69 369.161C129.089 369.161 130.222 368.028 130.222 366.629C130.222 365.231 129.089 364.097 127.69 364.097C126.292 364.097 125.158 365.231 125.158 366.629C125.158 368.028 126.292 369.161 127.69 369.161Z" />
    <path d="M137.818 348.905C139.216 348.905 140.35 347.772 140.35 346.373C140.35 344.975 139.216 343.841 137.818 343.841C136.42 343.841 135.286 344.975 135.286 346.373C135.286 347.772 136.42 348.905 137.818 348.905Z" />
    <path d="M137.818 359.033C139.216 359.033 140.35 357.9 140.35 356.501C140.35 355.103 139.216 353.969 137.818 353.969C136.42 353.969 135.286 355.103 135.286 356.501C135.286 357.9 136.42 359.033 137.818 359.033Z" />
    <path d="M137.818 369.161C139.216 369.161 140.35 368.028 140.35 366.629C140.35 365.231 139.216 364.097 137.818 364.097C136.42 364.097 135.286 365.231 135.286 366.629C135.286 368.028 136.42 369.161 137.818 369.161Z" />
    <path d="M147.946 338.777C149.344 338.777 150.478 337.644 150.478 336.245C150.478 334.847 149.344 333.713 147.946 333.713C146.548 333.713 145.414 334.847 145.414 336.245C145.414 337.644 146.548 338.777 147.946 338.777Z" />
    <path d="M147.946 348.905C149.344 348.905 150.478 347.772 150.478 346.373C150.478 344.975 149.344 343.841 147.946 343.841C146.548 343.841 145.414 344.975 145.414 346.373C145.414 347.772 146.548 348.905 147.946 348.905Z" />
    <path d="M147.946 359.033C149.344 359.033 150.478 357.9 150.478 356.501C150.478 355.103 149.344 353.969 147.946 353.969C146.548 353.969 145.414 355.103 145.414 356.501C145.414 357.9 146.548 359.033 147.946 359.033Z" />
    <path d="M147.946 369.161C149.344 369.161 150.478 368.028 150.478 366.629C150.478 365.231 149.344 364.097 147.946 364.097C146.548 364.097 145.414 365.231 145.414 366.629C145.414 368.028 146.548 369.161 147.946 369.161Z" />
    <path d="M158.074 328.649C159.472 328.649 160.606 327.516 160.606 326.117C160.606 324.719 159.472 323.585 158.074 323.585C156.676 323.585 155.542 324.719 155.542 326.117C155.542 327.516 156.676 328.649 158.074 328.649Z" />
    <path d="M158.074 338.777C159.472 338.777 160.606 337.644 160.606 336.245C160.606 334.847 159.472 333.713 158.074 333.713C156.676 333.713 155.542 334.847 155.542 336.245C155.542 337.644 156.676 338.777 158.074 338.777Z" />
    <path d="M158.074 348.905C159.472 348.905 160.606 347.772 160.606 346.373C160.606 344.975 159.472 343.841 158.074 343.841C156.676 343.841 155.542 344.975 155.542 346.373C155.542 347.772 156.676 348.905 158.074 348.905Z" />
    <path d="M158.074 359.033C159.472 359.033 160.606 357.9 160.606 356.501C160.606 355.103 159.472 353.969 158.074 353.969C156.676 353.969 155.542 355.103 155.542 356.501C155.542 357.9 156.676 359.033 158.074 359.033Z" />
    <path d="M158.074 369.161C159.472 369.161 160.606 368.028 160.606 366.629C160.606 365.231 159.472 364.097 158.074 364.097C156.676 364.097 155.542 365.231 155.542 366.629C155.542 368.028 156.676 369.161 158.074 369.161Z" />
    <path d="M168.202 318.522C169.6 318.522 170.734 317.389 170.734 315.99C170.734 314.592 169.6 313.458 168.202 313.458C166.804 313.458 165.67 314.592 165.67 315.99C165.67 317.389 166.804 318.522 168.202 318.522Z" />
    <path d="M168.202 328.649C169.6 328.649 170.734 327.516 170.734 326.117C170.734 324.719 169.6 323.585 168.202 323.585C166.804 323.585 165.67 324.719 165.67 326.117C165.67 327.516 166.804 328.649 168.202 328.649Z" />
    <path d="M168.202 348.905C169.6 348.905 170.734 347.772 170.734 346.373C170.734 344.975 169.6 343.841 168.202 343.841C166.804 343.841 165.67 344.975 165.67 346.373C165.67 347.772 166.804 348.905 168.202 348.905Z" />
    <path d="M168.202 359.033C169.6 359.033 170.734 357.9 170.734 356.501C170.734 355.103 169.6 353.969 168.202 353.969C166.804 353.969 165.67 355.103 165.67 356.501C165.67 357.9 166.804 359.033 168.202 359.033Z" />
    <path d="M168.202 369.161C169.6 369.161 170.734 368.028 170.734 366.629C170.734 365.231 169.6 364.097 168.202 364.097C166.804 364.097 165.67 365.231 165.67 366.629C165.67 368.028 166.804 369.161 168.202 369.161Z" />
    <path d="M178.329 308.394C179.727 308.394 180.861 307.26 180.861 305.862C180.861 304.463 179.727 303.33 178.329 303.33C176.93 303.33 175.797 304.463 175.797 305.862C175.797 307.26 176.93 308.394 178.329 308.394Z" />
    <path d="M178.329 318.522C179.727 318.522 180.861 317.389 180.861 315.99C180.861 314.592 179.727 313.458 178.329 313.458C176.93 313.458 175.797 314.592 175.797 315.99C175.797 317.389 176.93 318.522 178.329 318.522Z" />
    <path d="M178.329 328.649C179.727 328.649 180.861 327.516 180.861 326.117C180.861 324.719 179.727 323.585 178.329 323.585C176.93 323.585 175.797 324.719 175.797 326.117C175.797 327.516 176.93 328.649 178.329 328.649Z" />
    <path d="M178.329 348.905C179.727 348.905 180.861 347.772 180.861 346.373C180.861 344.975 179.727 343.841 178.329 343.841C176.93 343.841 175.797 344.975 175.797 346.373C175.797 347.772 176.93 348.905 178.329 348.905Z" />
    <path d="M178.329 359.033C179.727 359.033 180.861 357.9 180.861 356.501C180.861 355.103 179.727 353.969 178.329 353.969C176.93 353.969 175.797 355.103 175.797 356.501C175.797 357.9 176.93 359.033 178.329 359.033Z" />
    <path d="M178.329 369.161C179.727 369.161 180.861 368.028 180.861 366.629C180.861 365.231 179.727 364.097 178.329 364.097C176.93 364.097 175.797 365.231 175.797 366.629C175.797 368.028 176.93 369.161 178.329 369.161Z" />
    <path d="M188.458 308.394C189.856 308.394 190.99 307.26 190.99 305.862C190.99 304.463 189.856 303.33 188.458 303.33C187.059 303.33 185.926 304.463 185.926 305.862C185.926 307.26 187.059 308.394 188.458 308.394Z" />
    <path d="M188.458 318.522C189.856 318.522 190.99 317.389 190.99 315.99C190.99 314.592 189.856 313.458 188.458 313.458C187.059 313.458 185.926 314.592 185.926 315.99C185.926 317.389 187.059 318.522 188.458 318.522Z" />
    <path d="M188.458 348.905C189.856 348.905 190.99 347.772 190.99 346.373C190.99 344.975 189.856 343.841 188.458 343.841C187.059 343.841 185.926 344.975 185.926 346.373C185.926 347.772 187.059 348.905 188.458 348.905Z" />
    <path d="M188.458 359.033C189.856 359.033 190.99 357.9 190.99 356.501C190.99 355.103 189.856 353.969 188.458 353.969C187.059 353.969 185.926 355.103 185.926 356.501C185.926 357.9 187.059 359.033 188.458 359.033Z" />
    <path d="M188.458 369.161C189.856 369.161 190.99 368.028 190.99 366.629C190.99 365.231 189.856 364.097 188.458 364.097C187.059 364.097 185.926 365.231 185.926 366.629C185.926 368.028 187.059 369.161 188.458 369.161Z" />
    <path d="M198.585 308.394C199.983 308.394 201.117 307.26 201.117 305.862C201.117 304.463 199.983 303.33 198.585 303.33C197.186 303.33 196.053 304.463 196.053 305.862C196.053 307.26 197.186 308.394 198.585 308.394Z" />
    <path d="M198.585 348.905C199.983 348.905 201.117 347.772 201.117 346.373C201.117 344.975 199.983 343.841 198.585 343.841C197.186 343.841 196.053 344.975 196.053 346.373C196.053 347.772 197.186 348.905 198.585 348.905Z" />
    <path d="M198.585 359.033C199.983 359.033 201.117 357.9 201.117 356.501C201.117 355.103 199.983 353.969 198.585 353.969C197.186 353.969 196.053 355.103 196.053 356.501C196.053 357.9 197.186 359.033 198.585 359.033Z" />
    <path d="M198.585 369.161C199.983 369.161 201.117 368.028 201.117 366.629C201.117 365.231 199.983 364.097 198.585 364.097C197.186 364.097 196.053 365.231 196.053 366.629C196.053 368.028 197.186 369.161 198.585 369.161Z" />
    <path d="M208.714 348.905C210.112 348.905 211.246 347.772 211.246 346.373C211.246 344.975 210.112 343.841 208.714 343.841C207.315 343.841 206.182 344.975 206.182 346.373C206.182 347.772 207.315 348.905 208.714 348.905Z" />
    <path d="M208.714 359.033C210.112 359.033 211.246 357.9 211.246 356.501C211.246 355.103 210.112 353.969 208.714 353.969C207.315 353.969 206.182 355.103 206.182 356.501C206.182 357.9 207.315 359.033 208.714 359.033Z" />
    <path d="M208.714 369.161C210.112 369.161 211.246 368.028 211.246 366.629C211.246 365.231 210.112 364.097 208.714 364.097C207.315 364.097 206.182 365.231 206.182 366.629C206.182 368.028 207.315 369.161 208.714 369.161Z" />
    <path d="M218.841 348.905C220.239 348.905 221.373 347.772 221.373 346.373C221.373 344.975 220.239 343.841 218.841 343.841C217.442 343.841 216.309 344.975 216.309 346.373C216.309 347.772 217.442 348.905 218.841 348.905Z" />
    <path d="M218.841 359.033C220.239 359.033 221.373 357.9 221.373 356.501C221.373 355.103 220.239 353.969 218.841 353.969C217.442 353.969 216.309 355.103 216.309 356.501C216.309 357.9 217.442 359.033 218.841 359.033Z" />
    <path d="M218.841 369.161C220.239 369.161 221.373 368.028 221.373 366.629C221.373 365.231 220.239 364.097 218.841 364.097C217.442 364.097 216.309 365.231 216.309 366.629C216.309 368.028 217.442 369.161 218.841 369.161Z" />
    <path d="M228.969 348.905C230.368 348.905 231.501 347.772 231.501 346.373C231.501 344.975 230.368 343.841 228.969 343.841C227.571 343.841 226.438 344.975 226.438 346.373C226.438 347.772 227.571 348.905 228.969 348.905Z" />
    <path d="M228.969 359.033C230.368 359.033 231.501 357.9 231.501 356.501C231.501 355.103 230.368 353.969 228.969 353.969C227.571 353.969 226.438 355.103 226.438 356.501C226.438 357.9 227.571 359.033 228.969 359.033Z" />
    <path d="M228.969 369.161C230.368 369.161 231.501 368.028 231.501 366.629C231.501 365.231 230.368 364.097 228.969 364.097C227.571 364.097 226.438 365.231 226.438 366.629C226.438 368.028 227.571 369.161 228.969 369.161Z" />
    <path d="M239.096 348.905C240.495 348.905 241.628 347.772 241.628 346.373C241.628 344.975 240.495 343.841 239.096 343.841C237.698 343.841 236.564 344.975 236.564 346.373C236.564 347.772 237.698 348.905 239.096 348.905Z" />
    <path d="M239.096 359.033C240.495 359.033 241.628 357.9 241.628 356.501C241.628 355.103 240.495 353.969 239.096 353.969C237.698 353.969 236.564 355.103 236.564 356.501C236.564 357.9 237.698 359.033 239.096 359.033Z" />
    <path d="M239.096 369.161C240.495 369.161 241.628 368.028 241.628 366.629C241.628 365.231 240.495 364.097 239.096 364.097C237.698 364.097 236.564 365.231 236.564 366.629C236.564 368.028 237.698 369.161 239.096 369.161Z" />
    <path d="M249.225 348.905C250.624 348.905 251.757 347.772 251.757 346.373C251.757 344.975 250.624 343.841 249.225 343.841C247.827 343.841 246.693 344.975 246.693 346.373C246.693 347.772 247.827 348.905 249.225 348.905Z" />
    <path d="M249.225 359.033C250.624 359.033 251.757 357.9 251.757 356.501C251.757 355.103 250.624 353.969 249.225 353.969C247.827 353.969 246.693 355.103 246.693 356.501C246.693 357.9 247.827 359.033 249.225 359.033Z" />
    <path d="M249.225 369.161C250.624 369.161 251.757 368.028 251.757 366.629C251.757 365.231 250.624 364.097 249.225 364.097C247.827 364.097 246.693 365.231 246.693 366.629C246.693 368.028 247.827 369.161 249.225 369.161Z" />
    <path d="M259.352 348.905C260.751 348.905 261.884 347.772 261.884 346.373C261.884 344.975 260.751 343.841 259.352 343.841C257.954 343.841 256.82 344.975 256.82 346.373C256.82 347.772 257.954 348.905 259.352 348.905Z" />
    <path d="M259.352 359.033C260.751 359.033 261.884 357.9 261.884 356.501C261.884 355.103 260.751 353.969 259.352 353.969C257.954 353.969 256.82 355.103 256.82 356.501C256.82 357.9 257.954 359.033 259.352 359.033Z" />
    <path d="M259.352 369.161C260.751 369.161 261.884 368.028 261.884 366.629C261.884 365.231 260.751 364.097 259.352 364.097C257.954 364.097 256.82 365.231 256.82 366.629C256.82 368.028 257.954 369.161 259.352 369.161Z" />
    <path d="M269.48 348.905C270.879 348.905 272.012 347.772 272.012 346.373C272.012 344.975 270.879 343.841 269.48 343.841C268.082 343.841 266.948 344.975 266.948 346.373C266.948 347.772 268.082 348.905 269.48 348.905Z" />
    <path d="M269.48 359.033C270.879 359.033 272.012 357.9 272.012 356.501C272.012 355.103 270.879 353.969 269.48 353.969C268.082 353.969 266.948 355.103 266.948 356.501C266.948 357.9 268.082 359.033 269.48 359.033Z" />
    <path d="M269.48 369.161C270.879 369.161 272.012 368.028 272.012 366.629C272.012 365.231 270.879 364.097 269.48 364.097C268.082 364.097 266.948 365.231 266.948 366.629C266.948 368.028 268.082 369.161 269.48 369.161Z" />
    <path d="M279.608 348.905C281.007 348.905 282.14 347.772 282.14 346.373C282.14 344.975 281.007 343.841 279.608 343.841C278.21 343.841 277.076 344.975 277.076 346.373C277.076 347.772 278.21 348.905 279.608 348.905Z" />
    <path d="M279.608 359.033C281.007 359.033 282.14 357.9 282.14 356.501C282.14 355.103 281.007 353.969 279.608 353.969C278.21 353.969 277.076 355.103 277.076 356.501C277.076 357.9 278.21 359.033 279.608 359.033Z" />
    <path d="M279.608 369.161C281.007 369.161 282.14 368.028 282.14 366.629C282.14 365.231 281.007 364.097 279.608 364.097C278.21 364.097 277.076 365.231 277.076 366.629C277.076 368.028 278.21 369.161 279.608 369.161Z" />
    <path d="M289.736 338.777C291.134 338.777 292.268 337.644 292.268 336.245C292.268 334.847 291.134 333.713 289.736 333.713C288.338 333.713 287.204 334.847 287.204 336.245C287.204 337.644 288.338 338.777 289.736 338.777Z" />
    <path d="M289.736 348.905C291.134 348.905 292.268 347.772 292.268 346.373C292.268 344.975 291.134 343.841 289.736 343.841C288.338 343.841 287.204 344.975 287.204 346.373C287.204 347.772 288.338 348.905 289.736 348.905Z" />
    <path d="M289.736 359.033C291.134 359.033 292.268 357.9 292.268 356.501C292.268 355.103 291.134 353.969 289.736 353.969C288.338 353.969 287.204 355.103 287.204 356.501C287.204 357.9 288.338 359.033 289.736 359.033Z" />
    <path d="M289.736 369.161C291.134 369.161 292.268 368.028 292.268 366.629C292.268 365.231 291.134 364.097 289.736 364.097C288.338 364.097 287.204 365.231 287.204 366.629C287.204 368.028 288.338 369.161 289.736 369.161Z" />
    <path d="M299.864 338.777C301.262 338.777 302.396 337.644 302.396 336.245C302.396 334.847 301.262 333.713 299.864 333.713C298.466 333.713 297.332 334.847 297.332 336.245C297.332 337.644 298.466 338.777 299.864 338.777Z" />
    <path d="M299.864 348.905C301.262 348.905 302.396 347.772 302.396 346.373C302.396 344.975 301.262 343.841 299.864 343.841C298.466 343.841 297.332 344.975 297.332 346.373C297.332 347.772 298.466 348.905 299.864 348.905Z" />
    <path d="M299.864 359.033C301.262 359.033 302.396 357.9 302.396 356.501C302.396 355.103 301.262 353.969 299.864 353.969C298.466 353.969 297.332 355.103 297.332 356.501C297.332 357.9 298.466 359.033 299.864 359.033Z" />
    <path d="M299.864 369.161C301.262 369.161 302.396 368.028 302.396 366.629C302.396 365.231 301.262 364.097 299.864 364.097C298.466 364.097 297.332 365.231 297.332 366.629C297.332 368.028 298.466 369.161 299.864 369.161Z" />
    <path d="M309.992 338.777C311.39 338.777 312.524 337.644 312.524 336.245C312.524 334.847 311.39 333.713 309.992 333.713C308.594 333.713 307.46 334.847 307.46 336.245C307.46 337.644 308.594 338.777 309.992 338.777Z" />
    <path d="M309.992 348.905C311.39 348.905 312.524 347.772 312.524 346.373C312.524 344.975 311.39 343.841 309.992 343.841C308.594 343.841 307.46 344.975 307.46 346.373C307.46 347.772 308.594 348.905 309.992 348.905Z" />
    <path d="M309.992 359.033C311.39 359.033 312.524 357.9 312.524 356.501C312.524 355.103 311.39 353.969 309.992 353.969C308.594 353.969 307.46 355.103 307.46 356.501C307.46 357.9 308.594 359.033 309.992 359.033Z" />
    <path d="M309.992 369.161C311.39 369.161 312.524 368.028 312.524 366.629C312.524 365.231 311.39 364.097 309.992 364.097C308.594 364.097 307.46 365.231 307.46 366.629C307.46 368.028 308.594 369.161 309.992 369.161Z" />
    <path d="M320.12 338.777C321.518 338.777 322.652 337.644 322.652 336.245C322.652 334.847 321.518 333.713 320.12 333.713C318.721 333.713 317.588 334.847 317.588 336.245C317.588 337.644 318.721 338.777 320.12 338.777Z" />
    <path d="M320.12 348.905C321.518 348.905 322.652 347.772 322.652 346.373C322.652 344.975 321.518 343.841 320.12 343.841C318.721 343.841 317.588 344.975 317.588 346.373C317.588 347.772 318.721 348.905 320.12 348.905Z" />
    <path d="M320.12 359.033C321.518 359.033 322.652 357.9 322.652 356.501C322.652 355.103 321.518 353.969 320.12 353.969C318.721 353.969 317.588 355.103 317.588 356.501C317.588 357.9 318.721 359.033 320.12 359.033Z" />
    <path d="M320.12 369.161C321.518 369.161 322.652 368.028 322.652 366.629C322.652 365.231 321.518 364.097 320.12 364.097C318.721 364.097 317.588 365.231 317.588 366.629C317.588 368.028 318.721 369.161 320.12 369.161Z" />
    <path d="M330.247 318.522C331.645 318.522 332.779 317.389 332.779 315.99C332.779 314.592 331.645 313.458 330.247 313.458C328.848 313.458 327.715 314.592 327.715 315.99C327.715 317.389 328.848 318.522 330.247 318.522Z" />
    <path d="M330.247 328.649C331.645 328.649 332.779 327.516 332.779 326.117C332.779 324.719 331.645 323.585 330.247 323.585C328.848 323.585 327.715 324.719 327.715 326.117C327.715 327.516 328.848 328.649 330.247 328.649Z" />
    <path d="M330.247 338.777C331.645 338.777 332.779 337.644 332.779 336.245C332.779 334.847 331.645 333.713 330.247 333.713C328.848 333.713 327.715 334.847 327.715 336.245C327.715 337.644 328.848 338.777 330.247 338.777Z" />
    <path d="M330.247 348.905C331.645 348.905 332.779 347.772 332.779 346.373C332.779 344.975 331.645 343.841 330.247 343.841C328.848 343.841 327.715 344.975 327.715 346.373C327.715 347.772 328.848 348.905 330.247 348.905Z" />
    <path d="M330.247 359.033C331.645 359.033 332.779 357.9 332.779 356.501C332.779 355.103 331.645 353.969 330.247 353.969C328.848 353.969 327.715 355.103 327.715 356.501C327.715 357.9 328.848 359.033 330.247 359.033Z" />
    <path d="M330.247 369.161C331.645 369.161 332.779 368.028 332.779 366.629C332.779 365.231 331.645 364.097 330.247 364.097C328.848 364.097 327.715 365.231 327.715 366.629C327.715 368.028 328.848 369.161 330.247 369.161Z" />
    <path d="M340.376 308.394C341.774 308.394 342.908 307.26 342.908 305.862C342.908 304.463 341.774 303.33 340.376 303.33C338.977 303.33 337.844 304.463 337.844 305.862C337.844 307.26 338.977 308.394 340.376 308.394Z" />
    <path d="M340.376 318.522C341.774 318.522 342.908 317.389 342.908 315.99C342.908 314.592 341.774 313.458 340.376 313.458C338.977 313.458 337.844 314.592 337.844 315.99C337.844 317.389 338.977 318.522 340.376 318.522Z" />
    <path d="M340.376 328.649C341.774 328.649 342.908 327.516 342.908 326.117C342.908 324.719 341.774 323.585 340.376 323.585C338.977 323.585 337.844 324.719 337.844 326.117C337.844 327.516 338.977 328.649 340.376 328.649Z" />
    <path d="M188.458 298.367C189.856 298.367 190.99 297.234 190.99 295.835C190.99 294.437 189.856 293.303 188.458 293.303C187.059 293.303 185.926 294.437 185.926 295.835C185.926 297.234 187.059 298.367 188.458 298.367Z" />
    <path d="M198.585 288.239C199.983 288.239 201.117 287.106 201.117 285.707C201.117 284.309 199.983 283.175 198.585 283.175C197.186 283.175 196.053 284.309 196.053 285.707C196.053 287.106 197.186 288.239 198.585 288.239Z" />
    <path d="M198.585 298.367C199.983 298.367 201.117 297.234 201.117 295.835C201.117 294.437 199.983 293.303 198.585 293.303C197.186 293.303 196.053 294.437 196.053 295.835C196.053 297.234 197.186 298.367 198.585 298.367Z" />
    <path d="M208.714 278.111C210.112 278.111 211.246 276.978 211.246 275.579C211.246 274.181 210.112 273.047 208.714 273.047C207.315 273.047 206.182 274.181 206.182 275.579C206.182 276.978 207.315 278.111 208.714 278.111Z" />
    <path d="M208.714 288.239C210.112 288.239 211.246 287.106 211.246 285.707C211.246 284.309 210.112 283.175 208.714 283.175C207.315 283.175 206.182 284.309 206.182 285.707C206.182 287.106 207.315 288.239 208.714 288.239Z" />
    <path d="M208.714 298.367C210.112 298.367 211.246 297.234 211.246 295.835C211.246 294.437 210.112 293.303 208.714 293.303C207.315 293.303 206.182 294.437 206.182 295.835C206.182 297.234 207.315 298.367 208.714 298.367Z" />
    <path d="M218.841 267.983C220.239 267.983 221.373 266.85 221.373 265.451C221.373 264.053 220.239 262.919 218.841 262.919C217.442 262.919 216.309 264.053 216.309 265.451C216.309 266.85 217.442 267.983 218.841 267.983Z" />
    <path d="M218.841 278.111C220.239 278.111 221.373 276.978 221.373 275.579C221.373 274.181 220.239 273.047 218.841 273.047C217.442 273.047 216.309 274.181 216.309 275.579C216.309 276.978 217.442 278.111 218.841 278.111Z" />
    <path d="M228.969 247.728C230.368 247.728 231.501 246.594 231.501 245.196C231.501 243.797 230.368 242.664 228.969 242.664C227.571 242.664 226.438 243.797 226.438 245.196C226.438 246.594 227.571 247.728 228.969 247.728Z" />
    <path d="M228.969 257.855C230.368 257.855 231.501 256.722 231.501 255.323C231.501 253.925 230.368 252.792 228.969 252.792C227.571 252.792 226.438 253.925 226.438 255.323C226.438 256.722 227.571 257.855 228.969 257.855Z" />
    <path d="M228.969 267.983C230.368 267.983 231.501 266.85 231.501 265.451C231.501 264.053 230.368 262.919 228.969 262.919C227.571 262.919 226.438 264.053 226.438 265.451C226.438 266.85 227.571 267.983 228.969 267.983Z" />
    <path d="M228.969 278.111C230.368 278.111 231.501 276.978 231.501 275.579C231.501 274.181 230.368 273.047 228.969 273.047C227.571 273.047 226.438 274.181 226.438 275.579C226.438 276.978 227.571 278.111 228.969 278.111Z" />
    <path d="M239.096 237.6C240.495 237.6 241.628 236.466 241.628 235.068C241.628 233.669 240.495 232.536 239.096 232.536C237.698 232.536 236.564 233.669 236.564 235.068C236.564 236.466 237.698 237.6 239.096 237.6Z" />
    <path d="M239.096 247.728C240.495 247.728 241.628 246.594 241.628 245.196C241.628 243.797 240.495 242.664 239.096 242.664C237.698 242.664 236.564 243.797 236.564 245.196C236.564 246.594 237.698 247.728 239.096 247.728Z" />
    <path d="M239.096 257.855C240.495 257.855 241.628 256.722 241.628 255.323C241.628 253.925 240.495 252.792 239.096 252.792C237.698 252.792 236.564 253.925 236.564 255.323C236.564 256.722 237.698 257.855 239.096 257.855Z" />
    <path d="M239.096 267.983C240.495 267.983 241.628 266.85 241.628 265.451C241.628 264.053 240.495 262.919 239.096 262.919C237.698 262.919 236.564 264.053 236.564 265.451C236.564 266.85 237.698 267.983 239.096 267.983Z" />
    <path d="M249.225 227.472C250.624 227.472 251.757 226.338 251.757 224.94C251.757 223.541 250.624 222.408 249.225 222.408C247.827 222.408 246.693 223.541 246.693 224.94C246.693 226.338 247.827 227.472 249.225 227.472Z" />
    <path d="M249.225 237.6C250.624 237.6 251.757 236.466 251.757 235.068C251.757 233.669 250.624 232.536 249.225 232.536C247.827 232.536 246.693 233.669 246.693 235.068C246.693 236.466 247.827 237.6 249.225 237.6Z" />
    <path d="M249.225 247.728C250.624 247.728 251.757 246.594 251.757 245.196C251.757 243.797 250.624 242.664 249.225 242.664C247.827 242.664 246.693 243.797 246.693 245.196C246.693 246.594 247.827 247.728 249.225 247.728Z" />
    <path d="M249.225 257.855C250.624 257.855 251.757 256.722 251.757 255.323C251.757 253.925 250.624 252.792 249.225 252.792C247.827 252.792 246.693 253.925 246.693 255.323C246.693 256.722 247.827 257.855 249.225 257.855Z" />
    <path d="M259.352 217.344C260.751 217.344 261.884 216.21 261.884 214.812C261.884 213.413 260.751 212.28 259.352 212.28C257.954 212.28 256.82 213.413 256.82 214.812C256.82 216.21 257.954 217.344 259.352 217.344Z" />
    <path d="M259.352 227.472C260.751 227.472 261.884 226.338 261.884 224.94C261.884 223.541 260.751 222.408 259.352 222.408C257.954 222.408 256.82 223.541 256.82 224.94C256.82 226.338 257.954 227.472 259.352 227.472Z" />
    <path d="M259.352 237.6C260.751 237.6 261.884 236.466 261.884 235.068C261.884 233.669 260.751 232.536 259.352 232.536C257.954 232.536 256.82 233.669 256.82 235.068C256.82 236.466 257.954 237.6 259.352 237.6Z" />
    <path d="M259.352 247.728C260.751 247.728 261.884 246.594 261.884 245.196C261.884 243.797 260.751 242.664 259.352 242.664C257.954 242.664 256.82 243.797 256.82 245.196C256.82 246.594 257.954 247.728 259.352 247.728Z" />
    <path d="M269.48 207.216C270.879 207.216 272.012 206.082 272.012 204.684C272.012 203.285 270.879 202.152 269.48 202.152C268.082 202.152 266.948 203.285 266.948 204.684C266.948 206.082 268.082 207.216 269.48 207.216Z" />
    <path d="M269.48 217.344C270.879 217.344 272.012 216.21 272.012 214.812C272.012 213.413 270.879 212.28 269.48 212.28C268.082 212.28 266.948 213.413 266.948 214.812C266.948 216.21 268.082 217.344 269.48 217.344Z" />
    <path d="M269.48 227.472C270.879 227.472 272.012 226.338 272.012 224.94C272.012 223.541 270.879 222.408 269.48 222.408C268.082 222.408 266.948 223.541 266.948 224.94C266.948 226.338 268.082 227.472 269.48 227.472Z" />
    <path d="M269.48 237.6C270.879 237.6 272.012 236.466 272.012 235.068C272.012 233.669 270.879 232.536 269.48 232.536C268.082 232.536 266.948 233.669 266.948 235.068C266.948 236.466 268.082 237.6 269.48 237.6Z" />
    <path d="M279.608 207.216C281.007 207.216 282.14 206.082 282.14 204.684C282.14 203.285 281.007 202.152 279.608 202.152C278.21 202.152 277.076 203.285 277.076 204.684C277.076 206.082 278.21 207.216 279.608 207.216Z" />
    <path d="M279.608 217.344C281.007 217.344 282.14 216.21 282.14 214.812C282.14 213.413 281.007 212.28 279.608 212.28C278.21 212.28 277.076 213.413 277.076 214.812C277.076 216.21 278.21 217.344 279.608 217.344Z" />
    <path d="M279.608 227.472C281.007 227.472 282.14 226.338 282.14 224.94C282.14 223.541 281.007 222.408 279.608 222.408C278.21 222.408 277.076 223.541 277.076 224.94C277.076 226.338 278.21 227.472 279.608 227.472Z" />
    <path d="M289.736 207.216C291.134 207.216 292.268 206.082 292.268 204.684C292.268 203.285 291.134 202.152 289.736 202.152C288.338 202.152 287.204 203.285 287.204 204.684C287.204 206.082 288.338 207.216 289.736 207.216Z" />
    <path d="M289.736 217.344C291.134 217.344 292.268 216.21 292.268 214.812C292.268 213.413 291.134 212.28 289.736 212.28C288.338 212.28 287.204 213.413 287.204 214.812C287.204 216.21 288.338 217.344 289.736 217.344Z" />
    <path d="M340.376 298.367C341.774 298.367 342.908 297.234 342.908 295.835C342.908 294.437 341.774 293.303 340.376 293.303C338.977 293.303 337.844 294.437 337.844 295.835C337.844 297.234 338.977 298.367 340.376 298.367Z" />
    <path d="M218.841 288.239C220.239 288.239 221.373 287.106 221.373 285.707C221.373 284.309 220.239 283.175 218.841 283.175C217.442 283.175 216.309 284.309 216.309 285.707C216.309 287.106 217.442 288.239 218.841 288.239Z" />
  </svg>
);
