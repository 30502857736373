type Props = {};

const FourLinkChainIcon = (props: Props) => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.3468 117.394C100.272 117.394 114.803 115.272 114.803 112.654C114.803 110.036 100.272 107.914 82.3468 107.914C64.4215 107.914 49.8901 110.036 49.8901 112.654C49.8901 115.272 64.4215 117.394 82.3468 117.394Z"
        fill="#32313B"
      />
      <path
        d="M41.0076 38.9482C38.7532 41.2026 36.1862 41.5812 32.5799 41.72C28.5316 41.8755 21.8852 41.9448 16.6329 47.1971C9.30954 54.5202 9.30954 66.394 16.6329 73.717C23.956 81.0404 35.8298 81.0401 43.1525 73.7173C48.4051 68.4651 47.596 60.5228 48.2622 56.5263C48.5991 54.5044 49.1476 51.5971 51.4017 49.343"
        fill="#6359B3"
      />
      <path
        d="M41.0076 38.9482C38.7532 41.2026 36.1862 41.5812 32.5799 41.72C28.5316 41.8755 21.8852 41.9448 16.6329 47.1971C9.30954 54.5202 9.30954 66.394 16.6329 73.717C23.956 81.0404 35.8298 81.0401 43.1525 73.7173C48.4051 68.4651 47.596 60.5228 48.2622 56.5263C48.5991 54.5044 49.1476 51.5971 51.4017 49.343"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M51.4019 49.3429C53.656 47.0888 55.6416 45.8624 59.3836 45.4057C62.8313 44.984 70.408 46.4624 77.8578 39.0119C85.1812 31.6892 85.1812 19.8154 77.8578 12.4926C70.5344 5.16924 58.6613 5.16923 51.3382 12.492C43.888 19.9425 45.3661 27.5191 44.9447 30.9665C44.488 34.7085 43.2616 36.6938 41.0075 38.9482C38.7531 41.2026 35.8458 41.7514 33.8235 42.0883C29.8277 42.7535 21.8854 41.9448 16.6332 47.197C9.30978 54.5201 9.30978 66.3939 16.6332 73.7169C23.9562 81.0403 35.83 81.04 43.1528 73.7173C48.4053 68.465 47.5963 60.5228 48.2624 56.5262C48.5993 54.5043 49.1475 51.5973 51.4019 49.3429Z"
        fill="#6359B3"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M88.1886 38.9482C85.9345 36.6941 84.7081 34.7091 84.251 30.9668C83.8303 27.5191 85.3084 19.9425 77.8579 12.4926C70.5345 5.16924 58.6613 5.16923 51.3383 12.492C44.0149 19.815 44.0149 31.6892 51.338 39.0125C58.7881 46.4621 66.3651 44.9843 69.8125 45.4057C73.5544 45.8621 75.5401 47.0888 77.7942 49.3426C80.0486 51.597 80.5971 54.5043 80.934 56.5262C81.5995 60.5231 80.7911 68.465 86.043 73.7173C93.3664 81.0406 105.24 81.0403 112.563 73.7173C119.887 66.3939 119.887 54.5204 112.563 47.1974C107.311 41.9451 99.3691 42.7532 95.3722 42.0883C93.3503 41.7514 90.443 41.2026 88.1886 38.9482Z"
        fill="#6359B3"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M86.107 84.0478C88.3611 81.7938 90.3467 80.5674 94.0887 80.1106C97.5367 79.6895 105.113 81.1679 112.563 73.7174C119.887 66.3941 119.887 54.5206 112.563 47.1975C105.24 39.8741 93.3664 39.8742 86.043 47.1972C78.5931 54.6474 80.0709 62.2244 79.6498 65.672C79.1931 69.4137 77.9667 71.399 75.7126 73.6534C73.4585 75.9078 70.5509 76.4563 68.529 76.7932C64.5324 77.4588 56.5905 76.6503 51.3382 81.9026C44.0152 89.2256 44.0149 101.099 51.3382 108.422C58.6616 115.746 70.5351 115.746 77.8582 108.422C83.1104 103.17 82.3016 95.228 82.9675 91.2318C83.3041 89.2096 83.8529 86.3022 86.107 84.0478Z"
        fill="#6359B3"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M53.3339 73.8027C51.0799 71.5486 49.8538 69.5629 49.3967 65.821C48.9756 62.3736 50.4537 54.7963 43.0035 47.3468C35.6805 40.0234 23.807 40.0234 16.4836 47.3468C9.16025 54.6698 9.16056 66.5433 16.4836 73.8667C23.9338 81.3166 31.5108 79.8387 34.9581 80.2598C38.6998 80.7163 40.6854 81.9433 42.9395 84.1971C45.1939 86.4515 45.28 89.6288 45.6172 91.6507C46.2831 95.6472 45.9361 103.319 51.1887 108.572C58.5121 115.894 70.3852 115.895 77.7086 108.572C85.032 101.248 85.032 89.3755 77.7086 82.0521C72.4567 76.7996 64.5144 77.6083 60.5179 76.9425C58.496 76.6056 55.5883 76.0577 53.3339 73.8027Z"
        fill="#6359B3"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M46.4107 50.8679C48.6647 48.6139 50.6504 47.3875 54.3924 46.9307C57.84 46.5093 65.4164 47.9874 72.8666 40.5369C80.1899 33.2142 80.1899 21.3404 72.8666 14.0176C65.5432 6.69426 53.6697 6.69426 46.3466 14.017C38.8965 21.4675 40.3746 29.0442 39.9535 32.4915C39.4964 36.2335 38.2703 38.2188 36.0159 40.4732C33.7615 42.7276 30.8542 43.2764 28.832 43.6133C24.8358 44.2786 16.8935 43.4701 11.6416 48.7221C4.3182 56.0454 4.3182 67.9189 11.6416 75.242C18.965 82.5654 30.8384 82.565 38.1612 75.2423C43.4138 69.99 42.605 62.0478 43.2709 58.0513C43.6077 56.0293 44.1563 53.1223 46.4107 50.8679Z"
        fill="#B0A6F9"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M83.197 40.4732C80.9429 38.2191 79.7168 36.2341 79.2598 32.4918C78.839 29.0442 80.3171 21.4675 72.8666 14.0176C65.5435 6.69426 53.6697 6.69426 46.3467 14.017C39.0236 21.3401 39.0236 33.2142 46.3467 40.5375C53.7965 47.9871 61.3738 46.5093 64.8212 46.9307C68.5632 47.3871 70.5488 48.6139 72.8029 50.8676C75.0573 53.122 75.6058 56.0293 75.9427 58.0513C76.6079 62.0481 75.7995 69.99 81.0517 75.2423C88.3748 82.5657 100.249 82.5653 107.572 75.2423C114.895 67.9189 114.895 56.0454 107.572 48.7224C102.319 43.4701 94.3775 44.2783 90.3809 43.6133C88.359 43.2764 85.4517 42.7276 83.197 40.4732Z"
        fill="#B0A6F9"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M81.1157 85.573C83.3698 83.3189 85.3551 82.0928 89.0974 81.6358C92.5454 81.215 100.122 82.6931 107.572 75.2426C114.895 67.9192 114.895 56.0457 107.572 48.7227C100.248 41.3993 88.3751 41.3993 81.0517 48.7224C73.6015 56.1725 75.0796 63.7495 74.6585 67.1972C74.2015 70.9388 72.9754 72.9242 70.7213 75.1786C68.4669 77.433 65.5596 77.9818 63.5377 78.3184C59.5411 78.9839 51.5992 78.1758 46.3466 83.4277C39.0239 90.7505 39.0236 102.624 46.347 109.947C53.6703 117.271 65.5438 117.271 72.8669 109.947C78.1191 104.695 77.31 96.7534 77.9759 92.7569C78.3128 90.7344 78.8613 87.8274 81.1157 85.573Z"
        fill="#B0A6F9"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M48.3427 75.3278C46.0886 73.0737 44.8626 71.0881 44.4055 67.3461C43.9844 63.8985 45.4625 56.3215 38.0123 48.8719C30.689 41.5486 18.8155 41.5486 11.4924 48.8719C4.16904 56.195 4.16935 68.0682 11.4924 75.3915C18.9423 82.8417 26.5193 81.3639 29.9669 81.7853C33.7086 82.2414 35.6942 83.4685 37.9483 85.7226C40.2027 87.9766 40.2891 91.154 40.626 93.1759C41.2916 97.1724 40.9452 104.844 46.1975 110.097C53.5205 117.42 65.394 117.421 72.7174 110.097C80.0405 102.774 80.0405 90.9007 72.7174 83.5773C67.4651 78.3247 59.5232 79.1335 55.5264 78.4679C53.5045 78.1307 50.5971 77.5825 48.3427 75.3278Z"
        fill="#B0A6F9"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M46.4107 50.868C44.1566 53.1221 43.6077 56.0294 43.2709 58.0513C42.605 62.0478 43.4138 69.9901 38.1612 75.2423C30.8384 82.5651 18.965 82.5654 11.6416 75.242C4.3182 67.919 4.3182 56.0455 11.6416 48.7221C16.8935 43.4702 23.5403 43.4005 27.5886 43.245C31.1946 43.1062 33.7615 42.7277 36.0159 40.4733"
        fill="#B0A6F9"
      />
      <path
        d="M46.4107 50.868C44.1566 53.1221 43.6077 56.0294 43.2709 58.0513C42.605 62.0478 43.4138 69.9901 38.1612 75.2423C30.8384 82.5651 18.965 82.5654 11.6416 75.242C4.3182 67.919 4.3182 56.0455 11.6416 48.7221C16.8935 43.4702 23.5403 43.4005 27.5886 43.245C31.1946 43.1062 33.7615 42.7277 36.0159 40.4733"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
      />
      <path
        d="M66.792 96.9151L63.6106 91.4042H57.2474L55.4283 92.0111L52.2468 97.5217L55.4283 103.032H61.7915L63.6106 102.426L66.792 96.9151Z"
        fill="#D6A356"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M66.792 26.0871L63.6106 20.5762H57.2474L55.4283 21.1827L52.2468 26.6933L55.4283 32.2039H61.7915L63.6106 31.5977L66.792 26.0871Z"
        fill="#D6A356"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.4577 54.7979C48.4577 54.7979 50.6512 48.9848 53.0643 47.2301L46.3737 50.7396L48.4577 54.7979Z"
        fill="#32313B"
      />
      <path
        d="M80.9231 85.9476L82.8977 91.6512C82.8977 91.6512 83.5557 85.07 87.5043 82.328C87.504 82.328 84.4329 82.1088 80.9231 85.9476Z"
        fill="#32313B"
      />
      <path
        d="M25.3852 55.0453L23.5661 55.6519L18.0555 58.8336V65.1968L23.5661 68.3783L25.3852 67.772L30.8961 64.5902V58.227L25.3852 55.0453Z"
        fill="#D6A356"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M18.0555 58.8337L23.5661 55.652L29.0771 58.8337V65.1969L23.5661 68.3784L18.0555 65.1969V58.8337Z"
        fill="#FFD18C"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M55.4283 103.032L52.2468 97.5216L55.4283 92.011H61.7915L64.9729 97.5216L61.7915 103.032H55.4283Z"
        fill="#FFD18C"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M55.4283 32.2039L52.2468 26.6933L55.4283 21.1827H61.7915L64.9729 26.6933L61.7915 32.2039H55.4283Z"
        fill="#FFD18C"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M29.077 58.8336L30.8961 58.2271"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M95.0104 55.0453L93.191 55.6519L87.6804 58.8336V65.1968L93.191 68.3783L95.0104 67.772L100.521 64.5902V58.227L95.0104 55.0453Z"
        fill="#D6A356"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M87.6804 58.8336L93.191 55.6519L98.7019 58.8336V65.1968L93.191 68.3783L87.6804 65.1968V58.8336Z"
        fill="#FFD18C"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M98.7019 58.8336L100.521 58.2271"
        stroke="#32313B"
        strokeWidth="1.57716"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FourLinkChainIcon;
