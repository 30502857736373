import { useSiteContext } from "../../context/site.context";
import { isDevelopment } from "../../environment";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  getReadableTimezoneName,
  guessBrowserTimezone,
} from "../../utils/time.utils";
import Select from "./Select";

const GlobalTimezoneSelect = () => {
  const siteContext = useSiteContext();
  const options = [
    { label: "Please select", value: "", disabled: true },
    ...(isDevelopment ? [{ label: "UTC", value: "UTC" }] : []),
    { label: "Dublin, Ireland", value: "Europe/Dublin" },
    { label: "Eastern Time", value: "America/New_York" },
    { label: "Central Time", value: "America/Chicago" },
    { label: "Mountain Time", value: "America/Denver" },
    { label: "Pacific Time", value: "America/Los_Angeles" },
  ];
  useOnMount(() => {
    const browserTimezone = guessBrowserTimezone();
    if (!options.find(o => o.value === browserTimezone)) {
      options.unshift({
        label: `${getReadableTimezoneName(browserTimezone)} (Detected)`,
        value: browserTimezone,
      });
    }
  });
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    siteContext.setTimezone((e.target as HTMLSelectElement).value);
  };
  return (
    <Select
      options={options}
      height="2.25em"
      padding="0 2em 0 .5em"
      borderRadius=".5em"
      name="timezone"
      value={siteContext.timezone}
      onChange={handleChange}
    />
  );
};

export default GlobalTimezoneSelect;
