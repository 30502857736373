export const CloudsIllustration = () => (
  <svg width="843" height="415" viewBox="0 0 843 415" fill="none">
    <path
      d="M449.619 115.988H382.318V146.081H449.619V115.988Z"
      fill="#EE86B7"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M449.619 105.306H382.318V135.4H449.619V105.306Z"
      fill="#EE86B7"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M333.314 81.1727C331.661 81.1727 330.044 81.319 328.465 81.5018C322.11 54.0042 297.386 33.4907 267.85 33.4907C266.454 33.4907 265.095 33.6004 263.736 33.7101C249.997 18.0233 229.755 8.07739 207.199 8.07739C174.982 8.07739 147.54 28.2618 136.886 56.637C128.915 52.0662 119.694 49.3969 109.812 49.3969C79.8352 49.3969 55.5159 73.6036 55.5159 103.441C55.5159 133.279 79.8352 157.486 109.812 157.486H333.314C354.474 157.486 371.63 140.41 371.63 119.348C371.63 98.2856 354.474 81.2093 333.314 81.2093V81.1727Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M176.377 124.137C158.634 129.037 144.013 141.323 135.968 157.448H150.221C154.924 143.626 164.218 131.925 176.341 124.137H176.377Z"
      fill="#483C72"
      stroke="#483C72"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M613.241 24.8977C610.816 24.8977 608.428 25.0806 606.114 25.4096C595.571 14.6958 580.876 8.07739 564.639 8.07739C544.177 8.07739 526.176 18.6084 515.817 34.5146C512.474 33.7467 509.02 33.3079 505.42 33.3079C480.33 33.3079 459.978 53.5654 459.978 78.5399C459.978 103.514 480.33 123.772 505.42 123.772H613.241C640.683 123.772 662.908 101.65 662.908 74.3349C662.908 47.0201 640.683 24.8977 613.241 24.8977Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M738.143 61.9698C718.379 33.558 685.427 14.9094 648.03 14.9094C591.162 14.9094 544.434 58.0572 539.071 113.235C531.172 109.652 522.392 107.641 513.135 107.641C478.383 107.641 450.206 135.687 450.206 170.278C450.206 204.869 478.383 232.916 513.135 232.916H734.58C782.043 232.916 820.506 194.631 820.506 147.388C820.506 100.145 783.953 63.8346 738.18 61.9698H738.143Z"
      fill="#FFD8CF"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M720.363 194.564C720.363 194.564 733.552 217.199 730.76 232.959L713.677 233.361C713.677 233.361 724.147 206.668 720.4 194.564H720.363Z"
      fill="#483C72"
      stroke="#483C72"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M783.762 147.163C783.762 147.163 788.06 167.31 817.706 167.31L820.094 154.33L783.762 147.199V147.163Z"
      fill="#483C72"
      stroke="#483C72"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M672.165 157.522C648.985 157.522 629.441 173.172 623.637 194.417C621.359 193.905 619.008 193.612 616.583 193.612C598.84 193.612 584.439 207.946 584.439 225.608C584.439 243.269 598.84 257.603 616.583 257.603H672.165C699.937 257.603 722.457 235.188 722.457 207.544C722.457 179.9 699.937 157.485 672.165 157.485V157.522Z"
      fill="#FFE5CA"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M800.229 104.358C810.221 104.358 818.854 110.172 822.932 118.582C832.299 119.277 839.683 127.029 839.683 136.536C839.683 146.043 831.565 154.563 821.573 154.563H800.229C786.306 154.563 774.991 143.337 774.991 129.442C774.991 115.547 786.269 104.321 800.229 104.321V104.358Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M269.025 150.242C258.629 128.815 236.587 114.006 211.056 114.006C178.104 114.006 150.956 138.688 147.208 170.5C143.976 169.586 140.559 169.074 137.033 169.074C116.791 169.074 100.407 185.382 100.407 205.53C100.407 225.678 116.791 241.986 137.033 241.986H261.862C287.467 241.986 308.223 221.327 308.223 195.84C308.223 172.767 291.214 153.643 268.989 150.242H269.025Z"
      fill="#FFC8A3"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_215_1791"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="55"
      y="8"
      width="785"
      height="250"
    >
      <path
        d="M371.631 119.303C371.631 140.365 354.476 157.442 333.316 157.442H287.616C300.069 165.706 308.262 179.82 308.262 195.799C308.262 221.286 287.506 241.946 261.901 241.946H137.072C116.83 241.946 100.446 225.637 100.446 205.489C100.446 185.342 116.83 169.033 137.072 169.033C140.598 169.033 144.015 169.545 147.247 170.459C147.762 165.962 148.827 161.61 150.26 157.442H109.85C79.8735 157.442 55.5542 133.235 55.5542 103.397C55.5542 73.5595 79.8735 49.3528 109.85 49.3528C119.732 49.3528 128.953 52.0221 136.925 56.5929C147.541 28.2177 175.02 8.0333 207.237 8.0333C229.793 8.0333 250.035 17.9792 263.774 33.666C265.133 33.5929 266.493 33.4466 267.889 33.4466C297.424 33.4466 322.148 53.9601 328.503 81.4577C330.083 81.2749 331.699 81.1286 333.352 81.1286C354.512 81.1286 371.668 98.2049 371.668 119.267L371.631 119.303ZM822.934 118.572C820.326 113.197 815.844 108.882 810.333 106.469C796.374 80.5801 769.446 63.2479 738.184 61.9681C718.42 33.5198 685.468 14.9077 648.07 14.9077C632.568 14.9077 617.873 18.1255 604.501 23.9029C594.105 14.1033 580.109 8.06988 564.643 8.06988C544.181 8.06988 526.18 18.6009 515.82 34.507C512.477 33.7392 509.024 33.3004 505.424 33.3004C480.333 33.3004 459.982 53.5579 459.982 78.5324C459.982 94.5117 468.321 108.517 480.884 116.561C462.516 127.494 450.21 147.423 450.21 170.276C450.21 204.868 478.386 232.914 513.139 232.914H585.325C588.668 247.065 601.379 257.596 616.624 257.596H672.206C690.5 257.596 706.481 247.833 715.261 233.28L732.306 232.877H734.584C779.622 232.877 816.505 198.396 820.179 154.516H821.538C831.53 154.516 839.649 145.997 839.649 136.489C839.649 126.982 832.265 119.23 822.897 118.536L822.934 118.572Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_215_1791)">
      <path
        d="M414.941 -21.9512C525.884 -29.9226 602.846 50.815 622.426 126.214H622.463C636.092 178.65 636.423 222.565 601.891 284.691H222.481C220.534 280.23 215.391 275.622 205.362 272.295C189.675 267.103 183.32 254.414 188.61 246.37C195.627 235.692 206.464 223.406 213.187 211.01C225.199 188.815 236.808 178.613 234.934 169.069C230.489 146.545 229.093 120.693 234.53 98.3507C240.394 74.2039 261.754 38.1896 304.252 11.7042"
        stroke="#483C72"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M458.839 252.45C458.839 252.45 456.929 238.665 453.402 229.669Z"
      fill="#F47E3F"
    />
    <path
      d="M458.839 252.45C458.839 252.45 456.929 238.665 453.402 229.669"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M479.777 184.612C470.997 192.144 469.858 210.171 469.858 210.171C462.842 215.839 453.4 229.661 453.4 229.661C453.4 229.661 446.567 216.424 447.045 203.809C447.486 191.194 443.922 172.728 443.261 163.513C442.343 150.496 438.963 137.625 436.979 127.203C435.289 118.354 434.555 111.772 424.783 112.65C418.354 113.199 416.187 120.256 416.187 127.13C416.187 132.578 416.995 114.588 405.937 114.588C393.998 114.588 395.908 125.338 395.21 130.787C394.512 136.199 393.337 163.001 392.602 167.499C391.867 172.033 390.178 184.502 390.178 184.502C390.178 184.502 379.193 183.844 374.785 186.074C369.238 188.89 365.711 192.035 363.911 200.043C363.177 203.224 363.066 205.125 363.066 205.125C363.066 205.125 357.299 205.418 353.074 209.769C349.107 213.828 344.551 225.127 344.772 231.416C344.992 237.706 340.657 259.974 345.727 274.198C350.76 288.423 360.678 306.486 363.25 318.077C364.021 321.624 364.462 330.4 364.756 340.492C369.936 338.81 379.01 339.761 394.586 350.365C407.884 359.397 421.33 370.952 438.669 365.613C439.404 347.001 441.351 318.992 446.494 310.143C455.017 295.443 467.838 276.063 470.96 262.461C474.083 248.858 489.365 222.202 490.981 216.753C492.598 211.305 496.051 182.381 496.051 182.381C496.051 182.381 488.63 177.262 479.887 184.758L479.777 184.612Z"
      fill="#D7C4FA"
    />
    <path
      d="M279.677 335.05C282.8 323.531 326.296 317.535 362.15 314.39C360.35 308.466 357.264 301.372 353.995 294.096C337.647 289.269 339.3 284.735 339.3 284.735H222.479C223.582 287.258 223.692 289.708 223.067 291.902C221.377 297.972 213.185 305.724 223.361 312.708C229.386 316.84 238.974 320.021 238.974 320.021C238.974 320.021 225.712 323.641 224.206 329.053C222.7 334.465 228.835 338.011 239.011 347.811C245.55 354.1 247.901 352.528 243.309 370.665C238.937 387.961 244.227 400.21 254.477 407.56C267.481 402.112 295.217 394.908 354.362 388.253C355.538 375.418 356.346 366.533 356.933 360.28C313.548 354.32 275.453 350.7 279.714 335.05H279.677Z"
      fill="#E269A4"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M461.189 284.699C460.161 286.564 459.132 288.429 458.03 290.257C486.611 295.267 518.093 302.799 516.257 309.564C514.31 316.767 486.317 323.203 441.719 327.262C441.205 330.663 440.764 334.283 440.36 338.049C440.36 338.049 454.944 343.022 458.801 353.918C497.742 359.294 540.539 363.791 555.932 379.039C558.723 360.318 564.528 341.705 575.328 326.457C585.871 311.539 594.615 297.717 601.852 284.663H461.189V284.699Z"
      fill="#E269A4"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M415.971 125.857C415.971 125.857 418.579 159.535 418.579 168.896C418.579 178.256 419.571 211.312 419.571 216.468"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M413.768 121.215C413.768 126.882 410.057 130.064 405.465 130.064C400.873 130.064 397.163 126.882 397.163 121.215C397.163 115.547 400.873 114.194 405.465 114.194C410.057 114.194 413.768 115.547 413.768 121.215Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M434.228 120.482C434.228 126.369 430.517 129.733 425.925 129.733C421.333 129.733 417.623 126.406 417.623 120.482C417.623 114.558 421.884 112.511 426.44 112.511C430.995 112.511 434.191 114.558 434.191 120.482H434.228Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M396.755 175.37C396.755 175.37 404.249 171.823 413.323 175.041"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M423.094 175.37C423.094 175.37 430.589 171.823 439.662 175.041"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M398.413 182.499C398.413 182.499 405.246 183.486 409.471 182.17Z"
      fill="#F47E3F"
    />
    <path
      d="M398.413 182.499C398.413 182.499 405.246 183.486 409.471 182.17"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M426.039 182.499C426.039 182.499 432.541 183.486 436.104 182.17Z"
      fill="#F47E3F"
    />
    <path
      d="M426.039 182.499C426.039 182.499 432.541 183.486 436.104 182.17"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M468.761 380.724C466.777 364.379 463.434 354.25 458.916 353.921C457.887 353.848 456.785 354.286 455.61 355.31C429.453 378.127 411.82 362.002 394.444 350.228C363.255 329.019 358.149 346.425 358.149 346.425C358.149 346.425 357.708 351.325 354.292 388.293"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M479.78 184.619C471 192.151 469.861 210.178 469.861 210.178C462.844 215.846 453.403 229.668 453.403 229.668C453.403 229.668 446.57 216.431 447.048 203.816C447.489 191.201 443.925 172.735 443.264 163.52C442.346 150.503 438.966 137.631 436.982 127.21C435.292 118.361 434.558 111.779 424.786 112.657C418.357 113.205 416.19 120.263 416.19 127.137C416.19 132.585 416.998 114.595 405.94 114.595C394.001 114.595 395.911 125.345 395.213 130.794C394.515 136.205 393.34 163.008 392.605 167.506C391.87 172.04 390.18 184.509 390.18 184.509C390.18 184.509 379.196 183.851 374.788 186.081C369.241 188.897 365.714 192.042 363.914 200.049C363.179 203.231 363.069 205.132 363.069 205.132C363.069 205.132 357.302 205.425 353.077 209.776C349.11 213.835 344.554 225.134 344.775 231.423C344.995 237.712 340.66 259.981 345.73 274.205C350.763 288.429 360.681 306.493 363.253 318.084C364.024 321.631 364.465 330.407 364.759 340.499C369.939 338.817 379.013 339.768 394.589 350.372C407.887 359.404 421.333 370.959 438.672 365.62C439.407 347.008 441.354 318.998 446.497 310.15C455.02 295.45 467.841 276.07 470.963 262.468C474.086 248.865 489.368 222.208 490.984 216.76C492.601 211.312 496.054 182.388 496.054 182.388C496.054 182.388 488.633 177.269 479.89 184.765L479.78 184.619Z"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M495.539 187.438L487.2 188.279C487.2 188.279 482.865 203.198 492.454 208.207L495.539 187.475V187.438Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M361.602 234.058C361.602 234.058 363.585 226.233 363.842 223.746C364.1 221.26 364.1 200.198 364.1 200.198"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M389.52 184.03C389.52 184.03 390.18 201.984 388.97 206.481Z"
      fill="#F47E3F"
    />
    <path
      d="M389.52 184.03C389.52 184.03 390.18 201.984 388.97 206.481"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M449.545 387.048C449.545 391.326 446.055 394.8 441.757 394.8C437.458 394.8 433.969 391.326 433.969 387.048C433.969 382.77 437.458 379.296 441.757 379.296C446.055 379.296 449.545 382.77 449.545 387.048Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
