import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
  onlyPhones,
  uptoDesktopMd,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PageSection from "./PageSection";
import { colors } from "../../styles/colors.styles";
import { EscherFrameIcon } from "../home/spotIcons/EscherFrameIcon";
import { SectionHeading2 } from "../typography/SectionHeading2";
import {
  getWidthPxInMaxGrid,
  gridGapPx,
} from "../../constants/globalGrid.constants";
import { serif } from "../../styles/fonts.styles";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import LogoIntercom from "../thirdPartyLogos/LogoIntercom";
import LogoMars20h from "../thirdPartyLogos/LogoMars20h";
import { w } from "../../utils/string.utils";
import { LogoKnowBe4 } from "../thirdPartyLogos/LogoKnowBe4";
import { LogoElastic36h } from "../thirdPartyLogos/LogoElastic36h";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import { Link } from "gatsby";
import { Paths } from "../../utils/pathBuilders.utils";
import { css } from "linaria";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { ExternalLinkIcon } from "../basic/ExternalLinkIcon";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";
import { G2Logo24 } from "../misc/G2Logo24";
import { RatingStarsFourPointEight } from "../misc/RatingStarsFourPointEight";
import { GartnerLogo20 } from "../misc/GartnerLogo20";

const Positioner = styled.div`
  ${uptoDesktopMd} {
    margin-bottom: 1.5em;
  }
  ${fromDesktopMd} {
    margin-left: ${gridGapPx(-2)};
    margin-right: ${gridGapPx(-2)};
  }
`;

const Wrap = styled.div`
  position: relative;
  ${fromDesktopMd} {
    display: grid;
    grid-template-columns: minmax(auto, 5fr) minmax(
        auto,
        calc(${getWidthPxInMaxGrid(7)} + ${gridGapPx(3)})
      );
  }
`;

const Header = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  ${uptoDesktopMd} {
    text-align: center;
  }
  ${fromDesktopMd} {
    color: ${colors.purple800};
    background-color: ${colors.lightest};
    border: 1px solid ${colors.purple200};
    margin-right: -1px;
    margin-bottom: 47px;
    border-radius: 8px 0 0 8px;
    padding: ${gridGapPx(2)};
    [data-against-backdrop="light"] & {
      background-color: ${colors.purple50};
    }
  }
  grid-gap: ${gridGapPx(2)};
  z-index: 1;
  ${fromDesktopMd} {
    &:after {
      content: "";
      display: block;
      /** cspell:disable */
      background-image: url("data:image/svg+xml,%3Csvg width='72' height='47' viewBox='0 0 72 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1603_1373)'%3E%3Cpath d='M0.5 0.5V46.5H0.85753L71.5 0.726562V0.5H0.5Z' fill='%23C2AAFA' stroke='%23D7C4FA'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1603_1373'%3E%3Crect width='72' height='47' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      /** cspell:enable */
      width: 72px;
      height: 47px;
      position: absolute;
      top: 100%;
      right: 0;
    }
  }
`;

const IconWrap = styled.div``;

const HeaderContent = styled.div`
  ${mediaFromTo("tablet", "desktopMd")} {
    margin-bottom: 3em;
  }
  h2 {
    ${uptoTablet} {
      span {
        display: block;
      }
    }
    ${fromDesktopMd} {
      span {
        display: block;
      }
    }
  }
  > * + * {
    margin-top: ${rSize("gap")};
  }
  > p + p {
    margin-top: 0.75em;
  }
  p {
    ${onlyPhones} {
      max-width: 20em;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const RatingsBannerSet = styled.div`
  a {
    padding: 1em 1.25em;
    display: flex;
    text-align: left;
    background-color: ${colors.purple50};
    [data-against-backdrop="light"] & {
      background-color: ${colors.lightest};
    }
    border: 1px solid ${colors.purple200};
    color: ${colors.purple800};
    ${uptoDesktopMd} {
      margin-top: 2em;
    }
    ${fromDesktopMd} {
      display: flex;
      &:hover {
        background-color: ${colors.purple100};
        border-color: ${colors.purple200};
      }
    }
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    transition: background-color 0.1s;
    user-select: none;
    > div {
      display: flex;
      align-items: center;
      + * {
        margin-left: 2em;
      }
      > * + * {
        margin-left: 0.75em;
      }
      &:last-child {
        text-align: right;
      }
    }
    svg {
      display: block;
      flex: 0 0 auto;
    }
    p {
      font-weight: 600;
      line-height: 1.2;
      font-size: 1.4rem;
      svg {
        display: inline-block;
      }
      span {
        display: inline-block;
      }
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    + a {
      margin-top: -1px;
    }
  }
`;

const Content = styled.div`
  background-color: ${colors.lightest};
  color: ${colors.purple800};
  border: 1px solid ${colors.purple200};
  overflow: hidden;
  ${onlyPhones} {
    margin-top: -2em;
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
  }
  ${fromTablet} {
    border-radius: 8px;
  }
  ${fromDesktopMd} {
    margin-top: 47px;
    margin-left: -72px;
    border-radius: 0 8px 8px 0;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 5em repeat(5, auto) 5em;
  grid-template-areas:
    "deco1 deco1"
    "quote1 quote1"
    "stat1 stat2"
    "quote2 quote2"
    "quote3 quote3"
    "stat3 stat3"
    "deco2 deco2";
  grid-gap: 1.2rem;
  ${uptoTablet} {
    margin-left: -32px;
    margin-right: -32px;
  }
  ${fromTablet} {
    grid-template-columns: 0 repeat(7, minmax(0, 1fr)) 0;
    grid-template-rows: auto;
    grid-template-areas:
      "${w("deco1", 4)} ${w(".", 4)} deco2"
      "${w("deco1", 4)} ${w("stat1", 2)} ${w("stat2", 2)} deco2"
      ". ${w("quote1", 3)} ${w("stat1", 2)} ${w("stat2", 2)} deco2"
      ". ${w("quote1", 3)} ${w("quote2", 3)} deco3 deco3"
      ". ${w("quote1", 3)} ${w("quote2", 3)} deco3 deco3"
      "${w("footer", 3)} ${w("quote3", 3)} ${w("stat3", 2)} deco4"
      "${w("footer", 3)} ${w("deco6", 3)} ${w("deco5", 2)} deco4";
    [id*="home-customer-section"] {
      transform: none !important;
    }
  }
  ${fromTabletLg} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(9, 1fr);
    margin-right: ${gridGapPx(-2)};
  }
  > * {
    border-radius: 8px;
  }
`;

const QuoteCardStyle = css`
  padding: 1em;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  text-decoration: none;
  border-radius: inherit;
  p {
    font-size: 1.8rem;
    font-family: ${serif};
    letter-spacing: -0.01em;
    line-height: 1.2;
    ${fromTabletMd} {
      font-size: 2rem;
    }
    svg {
      vertical-align: baseline;
      transform: translateY(0.2em);
      margin-right: 0.2em;
    }
  }
  > svg {
    margin-top: 1em;
  }
`;

const Quote1Wrap = styled.div`
  grid-area: quote1;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.green50};
    border: 1px solid ${colors.green200};
    color: ${colors.green700};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.green100};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 3fr minmax(260px, 10fr) 2fr;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.purple50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.pink50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const Quote2Wrap = styled.div`
  grid-area: quote2;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.orange50};
    border: 1px solid ${colors.orange200};
    color: ${colors.orange700};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.orange100};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 2fr minmax(260px, 10fr) 3fr;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.purple50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.orange50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const Quote3Wrap = styled.div`
  grid-area: quote3;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.pink50};
    border: 1px solid ${colors.pink200};
    color: ${colors.pink700};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.pink100};
      }
    }
  }
  ${onlyPhones} {
    grid-gap: inherit;
    grid-template-columns: 3fr minmax(260px, 10fr) 2fr;
    &:before {
      content: "";
      background-color: ${colors.orange50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.green50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const StatCardStyle = css`
  color: ${colors.white};
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  padding: 1em;
  min-height: 150px;
  ${fromTablet} {
    min-height: 16.8rem;
  }
  text-decoration: none;
  transition: background-color 0.1s;
  border-radius: inherit;
  svg {
    max-width: 100%;
    height: auto;
  }
  p {
    font-size: 1.3rem;
    strong {
      display: block;
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: -0.01em;
      line-height: 1;
    }
  }
  &[data-area="stat2"] {
    background-color: ${colors.pink};
    grid-area: stat2;
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.pink600};
      }
    }
  }
  &[data-area="stat3"] {
    background-color: ${colors.purple};
    grid-area: stat3;
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.purple600};
      }
    }
  }
`;

const Stat1Wrap = styled.div`
  grid-area: stat1;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.orange};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.orange600};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: minmax(0, 2fr) 150px;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.green50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
`;

const Stat2Wrap = styled.div`
  grid-area: stat2;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.pink};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.pink600};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 150px minmax(0, 2fr);
    grid-gap: inherit;
    &:after {
      content: "";
      background-color: ${colors.purple50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;
const Stat3Wrap = styled.div`
  grid-area: stat3;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  > a {
    background-color: ${colors.purple};
    @media (hover: hover) {
      &:hover {
        background-color: ${colors.purple600};
      }
    }
  }
  ${onlyPhones} {
    grid-template-columns: 2fr 150px 3fr;
    grid-gap: inherit;
    &:before {
      content: "";
      background-color: ${colors.pink50};
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
    &:after {
      content: "";
      background-color: ${colors.purple50};
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
`;

const Deco1 = styled.div`
  display: grid;
  grid-area: deco1;
  grid-template-columns: 2fr 3fr 4fr;
  grid-gap: inherit;
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr) ${gridGapPx(5)};
  }
  span {
    display: block;
    &:nth-child(1) {
      background-color: ${colors.orange50};
      border-bottom-right-radius: 8px;
    }
    &:nth-child(2) {
      background-color: ${colors.purple50};
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:nth-child(3) {
      ${fromTablet} {
        display: none;
      }
      background-color: ${colors.green50};
      border-bottom-left-radius: 8px;
    }
  }
`;

const Deco2 = styled.div`
  grid-area: deco2;
  display: block;
  ${fromTablet} {
    background-color: ${colors.green50};
    border-top-left-radius: 0;
  }
  ${onlyPhones} {
    display: grid;
    grid-template-columns: 4fr 3fr 2fr;
    grid-gap: inherit;
    span {
      display: block;
      &:nth-child(1) {
        background-color: ${colors.orange50};
        border-top-right-radius: 8px;
      }
      &:nth-child(2) {
        background-color: ${colors.pink50};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:nth-child(3) {
        ${fromTablet} {
          display: none;
        }
        background-color: ${colors.green50};
        border-top-left-radius: 8px;
      }
    }
  }
`;
const Deco3 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco3;
  }
  background-color: ${colors.purple50};
`;
const Deco4 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco4;
  }
  background-color: ${colors.orange50};
`;
const Deco5 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco5;
  }
  background-color: ${colors.pink50};
  height: ${rSize("gap")};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
const Deco6 = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
    grid-area: deco6;
  }
  background-color: ${colors.green50};
  height: ${rSize("gap")};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const Footer = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  ${onlyPhones} {
    position: absolute;
    bottom: 0;
    left: 1em;
    right: 1em;
    transform: translateY(50%);
  }
  ${fromTablet} {
    grid-area: footer;
    grid-template-columns: minmax(0, 1fr) ${gridGapPx(5)};
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: "span1 link" "span1 span2";
    grid-gap: inherit;
  }
  a {
    display: grid;
    border-radius: 8px;
    grid-template-areas: "text icon";
    padding: 1.5em;
    ${fromTablet} {
      grid-template-areas: "icon" "text";
      grid-template-rows: minmax(auto, 1fr) auto;
      padding: 0.75em;
    }
    grid-gap: ${rSize("gap")};
    background-color: ${colors.purple50};
    border: 1px solid ${colors.purple200};
    color: ${colors.purple700};
    text-decoration: none;
    ${fromTablet} {
      height: ${gridGapPx(5)};
    }
    transition: background-color 0.1s;
    user-select: none;
    &:hover {
      background-color: ${colors.purple100};
      svg {
        transform: translateX(2px);
      }
    }
    strong {
      font-size: 1.4rem;
      ${fromTabletMd} {
        font-size: 1.3rem;
        b {
          font-weight: inherit;
          display: block;
        }
      }
      line-height: 1;
      font-weight: 600;
    }
    svg {
      grid-area: icon;
      margin-left: auto;
      transition: transform 0.25s;
    }
  }
  span {
    display: none;
    ${fromTablet} {
      display: block;
      &:first-of-type {
        grid-area: span1;
        background-color: ${colors.pink50};
        border-top-right-radius: 8px;
      }
      &:last-of-type {
        grid-area: span2;
        background-color: ${colors.orange50};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
`;

export const SocialProofSection = (props: {
  id?: string | null;
  againstBackdrop: "dark" | "light";
}) => {
  useOnMount(() => {
    const tl = gsap.timeline({ paused: true });
    [
      "#home-customer-section-deco-1",
      "#home-customer-section-quote-1",
      ["#home-customer-section-stat-1", "#home-customer-section-stat-2"],
      "#home-customer-section-quote-2",
      "#home-customer-section-quote-3",
      "#home-customer-section-stat-3",
      "#home-customer-section-deco-2",
    ].forEach((id, i) => {
      tl.fromTo(
        id,
        { x: (i % 2 === 0 ? 1 : -1) * 12 },
        {
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: id instanceof Array ? id[0] : id,
            scrub: 1,
            start: "top bottom",
            end: "bottom top",
            invalidateOnRefresh: true,
          },
        }
      );
    });
    return () => {
      tl.kill();
    };
  });
  return (
    <PageSection id={props.id}>
      <Positioner data-against-backdrop={props.againstBackdrop}>
        <Wrap>
          <Header>
            <IconWrap>
              <EscherFrameIcon />
            </IconWrap>
            <HeaderContent>
              <SectionHeading2 lighter>
                <span>Sounds… </span>
                <span>hard to believe?</span>
              </SectionHeading2>
              <p>
                We get it. A lot of products make bold claims. There’s no way
                one product could do all this. Right?
              </p>
              <p>Don’t take our word for it.</p>
              <RatingsBannerSet>
                <a
                  href="https://www.g2.com/products/tines/reviews"
                  {...externalLinkAttr}
                >
                  <div>
                    <G2Logo24 />
                    <p>
                      200+ reviews{" "}
                      <span>
                        on G2.com{}
                        <ExternalLinkIcon />
                      </span>
                    </p>
                  </div>
                  <div>
                    <RatingStarsFourPointEight />
                    <p>4.8</p>
                  </div>
                </a>
                <a
                  href="https://www.gartner.com/reviews/market/security-orchestration-automation-and-response-solutions/vendor/tines/product/tines"
                  {...externalLinkAttr}
                >
                  <div>
                    <div style={{ paddingBottom: ".2em" }}>
                      <GartnerLogo20 />
                    </div>
                    <p>
                      50+ reviews <ExternalLinkIcon />
                    </p>
                  </div>
                  <div>
                    <RatingStarsFourPointEight />
                    <p>4.8</p>
                  </div>
                </a>
              </RatingsBannerSet>
            </HeaderContent>
          </Header>
          <Content>
            <ContentGrid>
              <Quote1Wrap id="home-customer-section-quote-1">
                <Link
                  className={QuoteCardStyle}
                  to="/case-studies/oak-ridge-national-laboratory"
                >
                  <p>
                    <QuoteMarkIcon /> We can automate anything, the only thing
                    we’re limited by is our own mindset and time to do it.
                  </p>
                  <LogoOakRidge />
                </Link>
              </Quote1Wrap>
              <Quote2Wrap id="home-customer-section-quote-2">
                <Link className={QuoteCardStyle} to="/case-studies/mars">
                  <p>
                    <QuoteMarkIcon /> An opportunity to bring automation into
                    areas of the organization that just don’t have developers.
                  </p>
                  <LogoMars20h />
                </Link>
              </Quote2Wrap>
              <Quote3Wrap id="home-customer-section-quote-3">
                <Link className={QuoteCardStyle} to="/case-studies/intercom">
                  <p>
                    <QuoteMarkIcon /> No matter how many tools you change, you
                    can keep Tines in place.
                  </p>
                  <LogoIntercom />
                </Link>
              </Quote3Wrap>
              <Stat1Wrap id="home-customer-section-stat-1">
                <Link className={StatCardStyle} to="/case-studies/knowbe4">
                  <LogoKnowBe4 />
                  <p>
                    <strong>50% reduction</strong>{" "}
                    <span>in time spent per ticket</span>
                  </p>
                </Link>
              </Stat1Wrap>
              <Stat2Wrap id="home-customer-section-stat-2">
                <Link className={StatCardStyle} to="/case-studies/elastic">
                  <LogoElastic36h />
                  <p>
                    <strong>750 days</strong> <span>of work time saved</span>
                  </p>
                </Link>
              </Stat2Wrap>
              <Stat3Wrap id="home-customer-section-stat-3">
                <Link className={StatCardStyle} to="/case-studies/snowflake">
                  <LogoSnowflake />
                  <p>
                    <strong>10 hours of work time</strong>{" "}
                    <span>saved every day</span>
                  </p>
                </Link>
              </Stat3Wrap>
              <Deco1 id="home-customer-section-deco-1">
                <span />
                <span />
                <span />
              </Deco1>
              <Deco2 id="home-customer-section-deco-2">
                <span />
                <span />
                <span />
              </Deco2>
              <Deco3 />
              <Deco4 />
              <Deco5 />
              <Deco6 />
              <Footer>
                <Link to={Paths.caseStudies()}>
                  <strong>
                    <b>More </b>
                    <b>case studies</b>
                  </strong>
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none">
                    <path
                      d="M1 6H13M13 6L8 1M13 6L8 11"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <span />
                <span />
              </Footer>
            </ContentGrid>
          </Content>
        </Wrap>
      </Positioner>
    </PageSection>
  );
};

const QuoteMarkIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C2.23858 0 0 2.23858 0 5V14C0 16.7614 2.23858 19 5 19H14C16.7614 19 19 16.7614 19 14V5C19 2.23858 16.7614 0 14 0H5ZM9.08997 8.36134H9.34131V7H8.92241C7.56518 7 6.74414 7.85714 6.74414 9.33613V11H8.36946V9.45378C8.36946 8.73109 8.63756 8.36134 9.08997 8.36134ZM12.0055 8.36134H12.2568V7H11.8379C10.464 7 9.64291 7.85714 9.64291 9.33613V11H11.2682V9.45378C11.2682 8.73109 11.5363 8.36134 12.0055 8.36134Z"
    />
  </svg>
);
