import { styled } from "@linaria/react";
import { cx } from "linaria";
import { RefObject } from "react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { UseStateReturnType } from "../../types/helper.types";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { SelectOptionConfig } from "./Select";
import { brandColorThemeVar, colors } from "../../styles/colors.styles";

type Props<T extends {}> = {
  className?: string;
  options?: SelectOptionConfig[];
  formState: UseStateReturnType<T>;
  name: keyof T | string;
  innerRef?: RefObject<HTMLSelectElement>;
  backgroundColor?: string;
  borderRadius?: string | number;
  fullWidth?: boolean;
};

const InlineSelectWrapper = styled.span`
  position: relative;
  display: block;
  border-radius: 0.5em;
  overflow: auto;
  border: 1px solid ${brandColorThemeVar(200)};
  ${darkModeLinariaCSS(`border-color: ${withOpacity(colors.gray, 0.2)}`)}
  &.fullWidth {
    width: 100%;
  }
`;

const OptionsRow = styled.div`
  display: flex;
`;

const OptionButton = styled.button`
  appearance: none;
  flex: 1 1 100%;
  border: 0;
  background-color: transparent;
  color: inherit;
  font-weight: 600;
  height: 3.5em;
  text-align: center;
  padding: 0 0.25em;
  font-size: 1.4rem;
  &:hover {
    background-color: ${brandColorThemeVar(300)};
  }
  + * {
    border-left: 1px solid ${brandColorThemeVar(200)};
    ${darkModeLinariaCSS(`border-color: ${withOpacity(colors.gray, 0.3)}`)}
  }
  &.selected {
    background-color: ${brandColorThemeVar(500)};
    color: ${colors.white};
  }
`;

function InlineSelect<T extends {}>(props: Props<T>) {
  const { className, formState, name, innerRef } = props;
  const [form, setForm] = formState;

  const value = Reflect.get(form, name);

  const selectOption = (option: SelectOptionConfig) => {
    setForm({
      ...form,
      [name]: option.value,
    });
  };

  const optionsMapped = props.options?.map((option, i) => (
    <OptionButton
      type="button"
      className={cx(value === option.value && "selected")}
      value={option.value}
      key={`${option.value}-${i}`}
      disabled={option.disabled}
      onClick={() => {
        selectOption(option);
      }}
    >
      {option.label}
    </OptionButton>
  ));

  return (
    <InlineSelectWrapper
      ref={innerRef}
      className={cx("InlineSelect", className, props.fullWidth && "fullWidth")}
      style={{
        backgroundColor: props.backgroundColor,
        borderRadius: props.borderRadius,
      }}
    >
      <OptionsRow>{optionsMapped}</OptionsRow>
    </InlineSelectWrapper>
  );
}

export default InlineSelect;
