import { styled } from "@linaria/react";
import {
  ValidProductFeatureCard,
  ValidProductFeatureCardGrid,
} from "../../utils/datocms.utils";
import { rSize } from "../../styles/responsiveSizes.styles";
import ProductFeatureCard from "./ProductFeatureCard.block";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  record: ValidProductFeatureCardGrid;
};

export const ProductFeatureCardGridContainer = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: [start] minmax(0, 1fr) [end];
  ${fromTabletMd} {
    &[data-columns="2"] {
      grid-template-columns: [start] repeat(2, 1fr) [end];
    }
  }
`;

const ProductFeatureCardGrid = ({ record }: Props) => {
  const features = record.features as ValidProductFeatureCard[];
  const columns = record.features.length > 2 ? 2 : 1;
  return (
    <ProductFeatureCardGridContainer
      className="ProductFeatureCardGrid"
      data-columns={columns}
    >
      {features.map((f, i) => (
        <ProductFeatureCard record={f} key={i} textOnly={i !== 0} />
      ))}
    </ProductFeatureCardGridContainer>
  );
};

export default ProductFeatureCardGrid;
