export const ChampaignBottleIcon = () => (
  <svg
    width="49"
    height="133"
    viewBox="0 0 49 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7475 132C38.1527 132 48.209 130.013 48.209 127.563C48.209 125.112 38.1527 123.125 25.7475 123.125C13.3424 123.125 3.28607 125.112 3.28607 127.563C3.28607 130.013 13.3424 132 25.7475 132Z"
      fill="#10352E"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.03852 40.3002C6.32846 40.3002 7.37417 39.2545 7.37417 37.9645C7.37417 36.6746 6.32846 35.6289 5.03852 35.6289C3.74858 35.6289 2.70288 36.6746 2.70288 37.9645C2.70288 39.2545 3.74858 40.3002 5.03852 40.3002Z"
      fill="#FFF0D9"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M32.5165 81.8824C28.7626 70.3706 25.259 64.2696 24.1329 51.7568C23.1684 41.0388 23.5383 38.0428 23.6324 36.5447C23.8448 33.164 23.6597 34.6333 23.7512 32.4766H18.2519H12.7525C12.8438 34.6333 12.6586 33.164 12.8714 36.5447C12.9655 38.0428 13.3353 41.0388 12.3709 51.7568C11.2447 64.2696 7.74112 70.3706 3.98727 81.8824C0.233431 93.3942 0.9842 119.921 0.9842 123.925C0.9842 131.934 18.2519 131.683 18.2519 131.683C18.2519 131.683 35.5195 131.934 35.5195 123.925C35.5195 119.921 36.2703 93.3942 32.5165 81.8824Z"
      fill="#49B889"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.1329 51.7572C23.518 44.9272 23.4454 41.2354 23.498 39.0664C21.9942 39.5944 20.1914 39.9025 18.2519 39.9025C16.3124 39.9025 14.5095 39.5944 13.0058 39.0664C13.0581 41.2354 12.9855 44.9272 12.3709 51.7572C11.5245 61.1618 9.33501 66.9447 6.70557 74.1248C6.70557 74.1248 9.99968 75.9659 17.9954 75.9659C25.991 75.9659 29.798 74.1248 29.798 74.1248C27.1688 66.9449 24.9795 61.1618 24.1329 51.7572Z"
      fill="#FDB2D2"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2381 33.7251C21.2677 33.7251 23.7237 33.1658 23.7237 32.4758C23.7237 31.7859 21.2677 31.2266 18.2381 31.2266C15.2084 31.2266 12.7524 31.7859 12.7524 32.4758C12.7524 33.1658 15.2084 33.7251 18.2381 33.7251Z"
      fill="#1F5941"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.5988 35.3535C23.5988 36.0435 21.1989 36.6028 18.2383 36.6028C15.2778 36.6028 12.8779 36.0437 12.8779 35.3535"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3942 38.6504C23.3942 39.3403 21.0858 39.8999 18.2381 39.8999C15.3907 39.8999 13.0821 39.3403 13.0821 38.6504"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.7221 120.389C31.7221 120.389 26.452 122.164 18.2518 122.164C10.0517 122.164 4.78131 120.389 4.78131 120.389V100.965C4.78131 100.965 10.2742 102.74 18.2518 102.74C26.2295 102.74 31.7221 100.965 31.7221 100.965V120.389Z"
      fill="#B0A6F9"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9479 117.64C28.9479 117.64 24.7631 118.786 18.2519 118.786C11.7407 118.786 7.55597 117.64 7.55597 117.64V105.104C7.55597 105.104 11.9174 106.249 18.2519 106.249C24.5864 106.249 28.9479 105.104 28.9479 105.104V117.64Z"
      fill="#B0A6F9"
      stroke="#7266CC"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9547 39.0879C12.9547 39.0879 14.0683 41.3402 18.156 41.3402C22.2437 41.3402 23.5072 39.5051 23.5072 39.5051C23.5072 39.5051 19.142 39.9 18.2381 39.9C17.3341 39.9 12.9547 39.0879 12.9547 39.0879Z"
      fill="#10352E"
    />
    <path
      d="M18.2519 69.6657C14.7581 69.6657 11.502 69.2087 8.75292 68.4219C8.11602 70.2863 7.42756 72.1524 6.70557 74.1237C6.70557 74.1237 9.99968 75.9648 17.9954 75.9648C25.991 75.9648 29.798 74.1237 29.798 74.1237C29.076 72.1524 28.3875 70.2863 27.7506 68.4219C25.0015 69.2087 21.7457 69.6657 18.2519 69.6657Z"
      fill="#B0A6F9"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7167 69.8445L18.2519 66.6484L23.7871 69.8445V76.236L18.2519 79.4318L12.7167 76.236V69.8445Z"
      fill="#FDB2D2"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7317 31.3362C19.7357 32.4766 18.9284 33.4038 17.9281 33.4071C16.9284 33.4108 16.1143 32.4899 16.1103 31.3492L16.074 21.0887C16.0697 19.9483 16.8773 19.0208 17.8773 19.0176C18.8771 19.0138 19.691 19.9355 19.6952 21.0757L19.7317 31.3362Z"
      fill="#FFE1B3"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M18.3113 33.4988C19.0155 33.4936 19.6859 33.4578 20.3038 33.3985C20.7035 30.6995 22.6698 20.9768 30.3346 19.7518C31.2896 19.5986 31.9405 18.7007 31.7878 17.7452C31.6354 16.7897 30.737 16.1391 29.7813 16.2917C24.9211 17.0685 21.1127 20.3962 18.768 25.9161C17.3431 29.2698 16.8786 32.446 16.7595 33.4588C17.2522 33.4881 17.772 33.5028 18.3113 33.4988Z"
      fill="#FFE1B3"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M16.4841 33.4412C17.0567 33.4829 17.6699 33.505 18.3113 33.5002C18.9316 33.4957 19.5257 33.4669 20.0816 33.4199C16.834 18.9879 5.08447 16.8179 4.58246 16.7323C3.63174 16.5699 2.73232 17.2073 2.5659 18.157C2.39923 19.1075 3.03514 20.0137 3.98511 20.1846C4.38527 20.2567 13.5474 22.0342 16.4841 33.4412Z"
      fill="#FFE1B3"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M15.8289 33.3788C16.5754 33.4599 17.4175 33.5034 18.3114 33.4969C19.0216 33.4919 19.6981 33.4551 20.3205 33.3948C20.2997 33.3242 20.2754 33.2542 20.2469 33.1851C17.0952 25.6404 8.49439 25.026 4.38668 25.7555C3.29807 25.9487 2.57232 26.9882 2.76552 28.0764C2.95871 29.1652 3.99778 29.891 5.08665 29.6978C5.16723 29.6835 12.559 28.4447 15.8289 33.3788Z"
      fill="#FFF0D9"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M32.7775 26.2485C32.803 25.1431 31.9276 24.2264 30.8222 24.2007C26.7453 24.1066 18.6227 25.9382 16.422 33.4344C17.0121 33.4794 17.647 33.5029 18.3117 33.4982C19.1816 33.4922 20.0004 33.4389 20.7307 33.3515C23.269 28.1022 30.6488 28.2015 30.7301 28.204C31.8353 28.2295 32.752 27.3541 32.7775 26.2485Z"
      fill="#FFF0D9"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M22.3667 10.8988C22.6317 11.3452 20.9795 12.814 18.6767 14.1794C16.3736 15.5448 14.2922 16.2893 14.0274 15.8428L11.7155 9.83642C11.4675 9.41799 12.7191 8.21676 14.5114 7.15392C16.304 6.09184 17.9582 5.56955 18.2062 5.98823L22.3667 10.8988Z"
      fill="#FBB598"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.5497 2.72719C19.5332 4.38588 18.9856 6.52758 17.327 7.51108L13.4735 9.79617C11.8151 10.7792 9.67312 10.2319 8.68986 8.57317C7.70636 6.91447 8.25391 4.77253 9.91261 3.78927L13.7658 1.50443C15.4242 0.520926 17.5662 1.06824 18.5497 2.72719Z"
      fill="#FBB598"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12.8846 11.9691C12.8846 11.9691 17.4763 10.2151 18.3094 7.24805L12.1808 10.6836L12.8846 11.9691Z"
      fill="#10352E"
    />
    <path
      d="M27.4594 5.97401C28.7494 5.97401 29.7951 4.92831 29.7951 3.63838C29.7951 2.34844 28.7494 1.30273 27.4594 1.30273C26.1695 1.30273 25.1238 2.34844 25.1238 3.63838C25.1238 4.92831 26.1695 5.97401 27.4594 5.97401Z"
      fill="#FFF0D9"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
  </svg>
);
