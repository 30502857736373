import { styled } from "@linaria/react";
import dayjs from "dayjs";
import { colors } from "../../styles/colors.styles";
import { uptoTabletLg } from "../../styles/breakpointsAndMediaQueries.styles";

const Wrap = styled.div`
  position: relative;
  width: 81px;
  height: 97px;
  padding-top: 28px;
  text-align: center;
  font-weight: 700;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  [fill="#F3ECF7"] {
    ${uptoTabletLg} {
      fill: ${colors.lightest};
    }
  }
`;

const Month = styled.div`
  position: relative;
  color: ${colors.pink700};
  [data-browser="firefox"] & {
    color: ${colors.pink400};
    -webkit-text-stroke: 3px ${colors.purple800};
    paint-order: stroke fill;
  }
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 9px;
`;

const Day = styled.div`
  position: relative;
  color: ${colors.purple700};
  -webkit-text-fill-color: ${colors.purple700};
  -webkit-text-stroke: 1px ${colors.purple800};
  font-size: 28px;
  line-height: 28px;
`;

export const DemoPageDecoCalendar = () => (
  <Wrap>
    <svg
      width="82"
      height="98"
      viewBox="0 0 82 98"
      fill="none"
      strokeLinejoin="round"
    >
      <path
        d="M76.3334 42H5.66665C3.08933 42 1 43.9831 1 46.4293V92.5707C1 95.0169 3.08933 97 5.66665 97H76.3334C78.9107 97 81 95.0169 81 92.5707V46.4293C81 43.9831 78.9107 42 76.3334 42Z"
        fill="#4D3E78"
        stroke="#4D3E78"
      />
      <path
        d="M76.3334 35H5.66665C3.08933 35 1 37.0552 1 39.5903V87.4097C1 89.9448 3.08933 92 5.66665 92H76.3334C78.9107 92 81 89.9448 81 87.4097V39.5903C81 37.0552 78.9107 35 76.3334 35Z"
        fill="#F3ECF7"
        stroke="#4D3E78"
      />
      <path
        d="M76.3334 29H5.66665C3.08933 29 1 31.0912 1 33.6708V82.3292C1 84.9088 3.08933 87 5.66665 87H76.3334C78.9107 87 81 84.9088 81 82.3292V33.6708C81 31.0912 78.9107 29 76.3334 29Z"
        fill="#F3ECF7"
        stroke="#4D3E78"
      />
      <path
        d="M33.1835 68.678H22.9851C21.577 68.678 20.4355 69.8195 20.4355 71.2276V86.0152C20.4355 87.4233 21.577 88.5648 22.9851 88.5648H33.1835C34.5916 88.5648 35.7331 87.4233 35.7331 86.0152V71.2276C35.7331 69.8195 34.5916 68.678 33.1835 68.678Z"
        fill="#25A871"
        stroke="#4D3E78"
      />
      <path
        d="M76.3334 23H5.66665C3.08933 23 1 25.0912 1 27.6708V76.3292C1 78.9088 3.08933 81 5.66665 81H76.3334C78.9107 81 81 78.9088 81 76.3292V27.6708C81 25.0912 78.9107 23 76.3334 23Z"
        fill="#F3ECF7"
        stroke="#4D3E78"
      />
      <path
        d="M61.6806 62.8193H51.4822C50.0741 62.8193 48.9326 63.9608 48.9326 65.3689V80.1565C48.9326 81.5646 50.0741 82.7061 51.4822 82.7061H61.6806C63.0887 82.7061 64.2301 81.5646 64.2301 80.1565V65.3689C64.2301 63.9608 63.0887 62.8193 61.6806 62.8193Z"
        fill="#E269A4"
        stroke="#4D3E78"
      />
      <path
        d="M76.3334 18H5.66665C3.08933 18 1 20.0552 1 22.5903V70.4097C1 72.9448 3.08933 75 5.66665 75H76.3334C78.9107 75 81 72.9448 81 70.4097V22.5903C81 20.0552 78.9107 18 76.3334 18Z"
        fill="#F3ECF7"
        stroke="#4D3E78"
      />
      <path
        d="M76.3334 12H5.66665C3.08933 12 1 14.0912 1 16.6708V65.3292C1 67.9088 3.08933 70 5.66665 70H76.3334C78.9107 70 81 67.9088 81 65.3292V16.6708C81 14.0912 78.9107 12 76.3334 12Z"
        fill="#F3ECF7"
        stroke="#4D3E78"
      />
      <path
        d="M28.1119 20.2087C28.1119 23.0693 25.7918 25.3946 22.9261 25.3946C20.0604 25.3946 17.7402 23.0744 17.7402 20.2087C17.7402 17.3429 20.0604 15.0229 22.9261 15.0229C25.7918 15.0229 28.1119 17.3429 28.1119 20.2087Z"
        fill="#4D3E78"
        stroke="#4D3E78"
      />
      <path
        d="M64.316 20.2087C64.316 23.0693 61.9959 25.3946 59.1302 25.3946C56.2645 25.3946 53.9443 23.0744 53.9443 20.2087C53.9443 17.3429 56.2645 15.0229 59.1302 15.0229C61.9959 15.0229 64.316 17.3429 64.316 20.2087Z"
        fill="#4D3E78"
        stroke="#4D3E78"
      />
      <path
        d="M23.0542 1H22.7992C21.1799 1 19.8672 2.31271 19.8672 3.93203V20.5043C19.8672 22.1237 21.1799 23.4364 22.7992 23.4364H23.0542C24.6735 23.4364 25.9862 22.1237 25.9862 20.5043V3.93203C25.9862 2.31271 24.6735 1 23.0542 1Z"
        fill="#7F69CE"
        stroke="#4D3E78"
      />
      <path
        d="M59.2583 1H59.0033C57.384 1 56.0713 2.31271 56.0713 3.93203V20.5043C56.0713 22.1237 57.384 23.4364 59.0033 23.4364H59.2583C60.8776 23.4364 62.1903 22.1237 62.1903 20.5043V3.93203C62.1903 2.31271 60.8776 1 59.2583 1Z"
        fill="#7F69CE"
        stroke="#4D3E78"
      />
    </svg>

    <Month>{dayjs().format("MMM")}</Month>
    <Day>{dayjs().format("D")}</Day>
  </Wrap>
);
