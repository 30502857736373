import ClientRedirectPageTemplate from "../templates/ClientRedirectPage.template";
import { PageComponent } from "../types/gatsby.types";
import { useOnMount } from "../utils/lifeCycle.utils";
import { writeMarketingAttribution } from "../utils/marketingAttribution.utils";
import { Paths } from "../utils/pathBuilders.utils";
import ogImage from "../../static/images/og/tines-signup.png";

const PageCommunityEditionSignup: PageComponent = props => {
  useOnMount(() => {
    writeMarketingAttribution();
  });
  return (
    <ClientRedirectPageTemplate
      {...props}
      pageContext={{
        title: "Sign up for Tines and start building",
        message: "Redirecting you to Community Edition signup...",
        description:
          "Community Edition is a free version of Tines that includes access for three builders and unlimited viewers, and three active workflows with 5000 daily events.",
        ogImage,
        to: Paths.signup_webhook(),
      }}
    />
  );
};

export default PageCommunityEditionSignup;
