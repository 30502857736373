import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

export const DemoPageTopSpacer = styled.div`
  ${fromTablet} {
    height: 2em;
  }
  ${fromDesktop} {
    height: 3em;
  }
`;
