import { styled } from "@linaria/react";
import { cover } from "polished";
import { useRef, useState } from "react";
import { fromDesktop } from "../../styles/breakpointsAndMediaQueries.styles";
import { EventDirectoryItem } from "../../types/helper.types";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  MapController,
  initEventDirectoryMapInElement,
} from "../../utils/maps.utils";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  items: EventDirectoryItem[];
  onControllerConstructed?: (mapController: MapController) => void;
  onSelectItem?: (item: EventDirectoryItem) => void;
};

const EventDirectoryMapContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.light400};
  ${fromDesktop} {
    ${cover()}
    border-radius: ${rSize("radius")};
    a[title="Open this area in Google Maps (opens a new window)"] {
      display: block !important;
      transform: translate(0.6em, -2px);
    }
  }
  > div {
    ${cover()}
    > div {
      background-color: ${colors.light400} !important;
    }
  }
  img {
    pointer-events: auto;
  }
`;

const EventDirectoryMap = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mapController, setMapController] = useState<MapController>();
  const init = async () => {
    if (!ref.current) return;
    const onSelectItem = (item: EventDirectoryItem) => {
      props.onSelectItem?.(item);
    };
    const mapController = await initEventDirectoryMapInElement(
      "event-directory",
      ref.current,
      props.items,
      onSelectItem
    );
    setMapController(mapController);
    props.onControllerConstructed?.(mapController);
  };
  useOnMount(() => {
    init();
  });
  return <EventDirectoryMapContainer ref={ref} />;
};

export default EventDirectoryMap;
