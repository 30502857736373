import { styled } from "@linaria/react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { LibraryState } from "./WithLibraryState";
import { colors } from "../../styles/colors.styles";
import {
  fromTablet,
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { useRef } from "react";

const FindAndFilterBarWrap = styled.div`
  ${fromTabletLg} {
    display: flex;
  }
  overflow: hidden;
  background-color: var(--ac50, ${withOpacity(colors.lightest, 0.7)});
  color: var(--ac700, ${colors.purple700});
  ${darkModeLinariaCSS(`
    background-color: var(--ac800, ${withOpacity(colors.dark800, 0.6)}); 
    color: var(--ac100, ${colors.purple100});
  `)}
  .neutralColor & {
    background-color: ${withOpacity(colors.light400, 0.7)};
    color: inherit;
    ${darkModeLinariaCSS(`
      background-color: ${withOpacity(colors.dark800, 0.6)}; 
      color: inherit;
    `)}
    ${darkModeLinariaCSS(`color: inherit;`)}
  }
  font-size: 1.6rem;
  ${fromTablet} {
    font-size: 1.4rem;
  }
  input {
    display: block;
    flex: 1 1 auto;
    background-color: transparent;
    border: 0;
    color: inherit;
    border-radius: 0;
    height: 3.5em;
    font-size: inherit;
    padding-left: 1em;
    padding-right: 1em;
    ${uptoTabletLg} {
      width: 100%;
    }
    ${fromTabletLg} {
      padding-left: 2.125em;
    }
    &::placeholder {
      color: var(--ac, ${colors.purple});
      opacity: 0.6;
      .neutralColor & {
        color: inherit;
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      border: 0;
      color: inherit;
    }
  }
`;

const SelectWrap = styled.div`
  position: relative;
  background-color: var(--ac20, ${withOpacity(colors.purple, 0.2)});
  .neutralColor & {
    background-color: ${colors.light300};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark900, 0.5)};`
    )}
  }
  label {
    font-weight: 600;
    white-space: nowrap;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 1em;
    transform: translateY(-50%);
  }
  select {
    background-color: transparent;
    appearance: none;
    border: 0;
    color: inherit;
    height: 3.5em;
    font-size: inherit;
    padding: 0.5em 3em 0.5em 5em;
    border-radius: 0 0 ${rSize("radius")} ${rSize("radius")};
    ${fromTabletLg} {
      border-radius: 0 0 ${rSize("radius")} 0;
      padding: 0.5em 4em 0.5em 5em;
    }
    font-weight: 500;
    width: 100%;
    &:focus {
      outline-color: var(--ac, ${colors.purple});
    }
  }
  svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0.75em;
    transform: translateY(-50%);
    ${fromTabletLg} {
      right: 1.25em;
    }
  }
  &:hover {
    background-color: var(--ac20, ${withOpacity(colors.purple, 0.2)});
    .neutralColor & {
      background-color: ${colors.light300};
      ${darkModeLinariaCSS(
        `background-color: ${withOpacity(colors.dark900, 0.25)};`
      )}
    }
  }
`;

export const LibraryFindAndFilterBar = (props: {
  state: LibraryState;
  searchBarPlaceholder?: string | null;
}) => {
  const handleSortChange = (e: React.FormEvent<HTMLSelectElement>) => {
    props.state.setSort((e.target as HTMLSelectElement).value);
  };
  const inputRef = useRef<HTMLInputElement>(null);
  useOnMount(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    });
  });
  return (
    <FindAndFilterBarWrap id="find-and-filter">
      <input
        value={props.state.query}
        onChange={e => props.state.searchFor(e.target.value)}
        placeholder={
          props.searchBarPlaceholder || `Find a ${props.state.entryType}…`
        }
        type="search"
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck="false"
        onKeyUp={e =>
          e.key === "Enter" && (e.target as HTMLInputElement).blur()
        }
        ref={inputRef}
      />
      <SelectWrap>
        <label>Sort by</label>
        <select value={props.state.sort} onChange={handleSortChange}>
          <option value="popularity">Most popular</option>
          {props.state.entryType === "story" && (
            <>
              <option value="simplerStoriesFirst">Simpler stories first</option>
              <option value="complexStoriesFirst">Complex stories first</option>
              <option value="latest">Latest first</option>
            </>
          )}
          {props.state.entryType === "tool" && (
            <>
              <option value="partnersFirst">Tines partners first</option>
            </>
          )}
          <option value="nameAsc">Name A–Z</option>
          <option value="nameDesc">Name Z–A</option>
        </select>
        <svg
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path d="M1.04785 1L5.04785 5L9.04785 1" />
        </svg>
      </SelectWrap>
    </FindAndFilterBarWrap>
  );
};
