import { styled } from "@linaria/react";

const IntercomButton = styled.div`
  position: fixed;
  z-index: 2147483003;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  bottom: 20px;
  right: 20px;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f69267;
  color: white;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  box-sizing: content-box;
  svg {
    width: 21px;
    height: 24px;
  }
`;

const MockIntercomButton = () => {
  return (
    <IntercomButton>
      <svg width="28" height="32" viewBox="0 0 28 32">
        <path
          d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"
          fill="currentColor"
        />
      </svg>
    </IntercomButton>
  );
};

export default MockIntercomButton;
