import { styled } from "@linaria/react";
import { useCallback, useRef, useState } from "react";
import { clamp } from "lodash-es";
import { flashEmailBranch } from "./AppStoryboard";
import gsap from "gsap";
import { colors } from "../../../styles/colors.styles";

const RadarSvg = styled.svg`
  overflow: visible;
  touch-action: none;
`;
const RadioDishGroup = styled.g`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

export const Radar = () => {
  const radioDishRef = useRef<SVGGElement>(null);
  const tipRef = useRef<SVGPathElement>(null);
  const tweens = useRef<ReturnType<typeof gsap["to"]>[]>([]);
  const getCurrentRotate = useCallback(
    () => parseInt(`${gsap.getProperty(radioDishRef.current, "rotate")}`),
    []
  );
  const rotateTo = useCallback(
    (deg: number, duration = 0.01) =>
      new Promise(resolve => {
        gsap.to(radioDishRef.current, {
          rotate: deg,
          duration,
          transformOrigin: "50.5px 47.25px",
          ease: "back",
          onComplete: resolve,
        });
      }),
    []
  );
  const ping = useCallback(
    (direction: 1 | -1) => {
      const deg = 3 * direction;
      const currentDeg = getCurrentRotate();
      rotateTo(currentDeg + deg, 0.15).then(() => {});
      setTimeout(() => {
        flashEmailBranch();
        const tipPosition = tipRef.current?.getBoundingClientRect();
        if (tipPosition) {
          const glow = document.createElement("div");
          glow.style.setProperty("background-color", colors.pink100);
          glow.style.setProperty("position", "fixed");
          glow.style.setProperty("top", `${tipPosition.top}px`);
          glow.style.setProperty("left", `${tipPosition.left}px`);
          glow.style.setProperty("width", `${tipPosition.width}px`);
          glow.style.setProperty("height", `${tipPosition.height}px`);
          glow.style.setProperty("border-radius", `50%`);
          glow.style.setProperty("pointer-events", `none`);
          glow.style.setProperty("opacity", `0.5`);
          document.body.append(glow);
          gsap.to(glow, {
            opacity: 0,
            scale: 5,
            onComplete: () => {
              glow.remove();
            },
          });
        }
      });
    },
    [getCurrentRotate, rotateTo]
  );

  const [grabbing, setGrabbing] = useState(false);

  const handlePointerDown = useCallback(
    (e: React.PointerEvent) => {
      setGrabbing(true);
      e.preventDefault();
      tweens.current.forEach(t => {
        t.kill();
      });
      tweens.current.length = 0;
      let prevClientX = e.clientX;
      let prevClientY = e.clientY;
      let deltaDeg = 0;
      const handlePointerMove = (e: PointerEvent) => {
        e.preventDefault();
        const deltaX = e.clientX - prevClientX;
        const deltaY = e.clientY - prevClientY;
        prevClientX = e.clientX;
        prevClientY = e.clientY;
        deltaDeg = (deltaY - deltaX) * -0.5;
        const newDeg = clamp(getCurrentRotate() + deltaDeg, -36, 18);
        rotateTo(newDeg);
      };
      const end = () => {
        setGrabbing(false);
        window.removeEventListener("pointermove", handlePointerMove);
        window.removeEventListener("pointerup", end);
        window.removeEventListener("blur", end);
        ping(
          deltaDeg === 0
            ? Math.random() > 0.5
              ? 1
              : -1
            : deltaDeg > 0
            ? -1
            : 1
        );
      };
      window.addEventListener("pointermove", handlePointerMove);
      window.addEventListener("pointerup", end, { once: true });
      window.addEventListener("blur", end, { once: true });
    },
    [rotateTo, getCurrentRotate, ping]
  );
  return (
    <>
      {grabbing && <style children={`:root { cursor: grabbing }`} />}

      <RadarSvg
        width="58"
        height="76"
        viewBox="0 0 58 76"
        fill="none"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      >
        <path
          d="M50.2403 66.6904C49.9549 69.4022 47.7516 71.4176 45.3198 71.1916C42.8877 70.9659 41.1479 68.5843 41.4336 65.8721L43.19 47.9273C43.4757 45.2152 45.6785 43.2002 48.1105 43.4258C50.5426 43.6515 52.2824 46.0331 51.997 48.7456L50.2403 66.6904Z"
          fill="#FFDCE8"
          stroke="#4D3E78"
        />
        <path
          d="M42.3457 55.2412L44.7305 56.8541L45.1362 51.7108L47.501 43.5L44.0007 45.5L43.0006 49.5002L42.3457 55.2412Z"
          fill="#4D3E78"
        />
        <RadioDishGroup ref={radioDishRef} onPointerDown={handlePointerDown}>
          <path
            d="M44.8393 50.935C47.6054 52.5112 51.1092 51.5188 52.6661 48.7182C54.2229 45.918 53.2433 42.3706 50.4775 40.7942L43.3352 36.7238L37.6973 46.8642L44.8393 50.935Z"
            fill="#FFDCE8"
            stroke="#4D3E78"
          />
          <path
            d="M36.7003 49.5173C37.6683 50.0125 40.1633 46.9857 42.2721 42.7571C44.3814 38.5285 45.3069 34.6995 44.3386 34.2043C43.8281 33.9429 42.2677 33.1453 41.7571 32.8839C40.7887 32.3889 38.2945 35.4159 36.1853 39.6445C34.0759 43.8733 33.1506 47.7023 34.118 48.1971C34.6294 48.4583 36.1892 49.2561 36.7003 49.5173Z"
            fill="#F486B8"
            stroke="#4D3E78"
          />
          <path
            d="M36.19 39.6446C34.0806 43.8731 33.1551 47.7021 34.1228 48.1969C35.0905 48.6917 37.585 45.6649 39.6943 41.4364C41.8037 37.2079 42.7292 33.379 41.7615 32.8842C40.7938 32.3894 38.2993 35.4161 36.19 39.6446Z"
            fill="#FFDCE8"
            stroke="#4D3E78"
          />
          <path
            d="M37.9863 40.4103C26.2476 63.3391 7.52087 60.6843 3.77857 58.7205C0.0364649 56.7571 3.55495 42.3683 11.6367 26.5827C19.7189 10.7971 29.304 -0.407487 33.0459 1.55611C36.7886 3.51971 49.7257 17.4813 37.9863 40.4103Z"
            fill="#FFDCE8"
            stroke="#4D3E78"
          />
          <path
            d="M3.77555 58.7191C7.51755 60.6829 17.1028 49.4784 25.1848 33.6932C33.2668 17.908 36.7851 3.51958 33.0431 1.55578C29.3011 -0.408023 19.7158 10.7964 11.6338 26.5817C3.55182 42.3669 0.0335506 56.7553 3.77555 58.7191Z"
            fill="#F486B8"
            stroke="#4D3E78"
          />
          <path
            d="M2.72559 21.9055L23.1512 36.9651L24.9028 33.5439L26.6543 30.1227L2.72559 21.9055Z"
            fill="#FFDCE8"
            stroke="#4D3E78"
          />
          <path
            ref={tipRef}
            d="M8.11014 24.7319C7.17338 26.5627 4.94744 27.2773 3.1397 26.3279C1.33157 25.3793 0.625535 23.1263 1.56309 21.2957C2.50004 19.4651 4.7256 18.7503 6.53334 19.6995C8.34206 20.6481 9.0473 22.9011 8.11014 24.7319Z"
            fill="#FFDCE8"
            stroke="#4D3E78"
          />
          <path
            d="M26.893 4.0595C26.893 4.0595 31.4528 19.3089 26.7676 29.2403C26.7676 29.2403 39.0897 5.9155 33.0466 1.5559C30.8898 0 26.893 4.0595 26.893 4.0595Z"
            fill="#4D3E78"
          />
          <path
            d="M23.3966 37.4523L9.06348 31.9565C9.06348 31.9565 10.7487 27.7555 10.9384 28.3273C11.1282 28.8989 23.3966 37.4523 23.3966 37.4523Z"
            fill="#4D3E78"
          />
          <path
            d="M32.9521 15.8715C32.9521 15.8715 42.0837 24.9383 40.1315 35.5307"
            stroke="#4D3E78"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M25.3662 34.2039L37.6771 40.7539" stroke="#4D3E78" />
          <path
            d="M16.0762 48.8206C16.0762 48.8206 26.5443 53.2292 33.455 47.6316"
            stroke="#4D3E78"
          />
          <path
            d="M43.6074 40.4246L44.7433 37.4746L46.9657 38.9246C46.9657 38.9246 43.8544 39.7246 43.6074 40.4246Z"
            fill="#4D3E78"
          />
        </RadioDishGroup>
        <path
          d="M46.0515 63.9068C40.7968 65.986 41.6495 70.5486 44.0162 73.1601C46.3829 75.7716 50.2353 74.3111 53.0356 73.5244L72.4382 67.5862C72.4382 67.5862 72.5575 67.4762 72.6198 67.4188C73.1133 66.9638 76.7664 62.8991 74.3996 60.2874C72.0329 57.6759 69.8408 56.1332 69.2205 56.3829C69.1354 56.4172 69 56.5 69 56.5L46.0515 63.9068Z"
          fill="#FFDCE8"
          stroke="#4D3E78"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <circle
          cx="49.008"
          cy="67.4826"
          r="5"
          transform="rotate(23.8527 49.008 67.4826)"
          fill="#4D3E78"
        />
        <path
          d="M52.9786 65.9705C52.693 68.6825 50.49 70.6979 48.0581 70.472C45.6261 70.2461 43.8862 67.8647 44.1719 65.1525L45.9283 47.2075C46.214 44.4953 48.4168 42.4803 50.8489 42.7061C53.2811 42.9318 55.0207 45.3135 54.7353 48.0257L52.9786 65.9705Z"
          fill="#FFDCE8"
          stroke="#4D3E78"
        />
        <path
          d="M48.4836 47.0105L49.6187 45.463L51.5174 45.6543L52.281 47.3924L51.1459 48.9396L49.247 48.7487L48.4836 47.0105Z"
          fill="#F486B8"
          stroke="#4D3E78"
        />
        <path
          d="M46.4288 66.1167L47.5639 64.5692L49.4627 64.7604L50.2261 66.4986L49.091 68.0458L47.1924 67.8549L46.4288 66.1167Z"
          fill="#F486B8"
          stroke="#4D3E78"
        />
      </RadarSvg>
    </>
  );
};
