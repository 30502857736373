export const FlowerVine = () => (
  <svg width="81" height="82" viewBox="0 0 81 82">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9577 58.7846C65.3866 45.661 69.385 25.8258 69.385 0.623108H73.385C73.385 26.1664 69.3446 46.6737 62.5498 60.5443C55.7759 74.3723 45.9327 82.1277 34.3145 80.6445C26.852 79.6919 19.1886 74.6828 13.1083 66.9226C6.99997 59.1265 2.36203 48.408 1 35.6965L4.97723 35.2703C6.26297 47.2698 10.63 57.2739 16.257 64.4556C21.9121 71.6732 28.7143 75.8972 34.821 76.6767C43.9425 77.8412 52.5079 71.9508 58.9577 58.7846Z"
      fill="#5ABE89"
    />
    <path
      d="M69.385 0.623108V0.123108H68.885V0.623108H69.385ZM58.9577 58.7846L59.4067 59.0046L58.9577 58.7846ZM73.385 0.623108H73.885V0.123108H73.385V0.623108ZM62.5498 60.5443L62.1008 60.3243L62.5498 60.5443ZM34.3145 80.6445L34.2511 81.1405L34.3145 80.6445ZM13.1083 66.9226L12.7148 67.231L13.1083 66.9226ZM1 35.6965L0.946731 35.1993L0.449576 35.2526L0.502846 35.7497L1 35.6965ZM4.97723 35.2703L5.47439 35.217L5.42112 34.7199L4.92396 34.7732L4.97723 35.2703ZM16.257 64.4556L15.8634 64.764L16.257 64.4556ZM34.821 76.6767L34.8843 76.1807L34.821 76.6767ZM68.885 0.623108C68.885 25.7832 64.8918 45.5344 58.5087 58.5647L59.4067 59.0046C65.8813 45.7876 69.885 25.8684 69.885 0.623108H68.885ZM73.385 0.123108H69.385V1.12311H73.385V0.123108ZM62.9988 60.7643C69.8394 46.8003 73.885 26.209 73.885 0.623108H72.885C72.885 26.1238 68.8499 46.5471 62.1008 60.3243L62.9988 60.7643ZM34.2511 81.1405C46.1823 82.6636 56.1849 74.6738 62.9988 60.7643L62.1008 60.3243C55.3669 74.0707 45.6831 81.5918 34.3778 80.1486L34.2511 81.1405ZM12.7148 67.231C18.848 75.0588 26.6191 80.1662 34.2511 81.1405L34.3778 80.1486C27.0849 79.2175 19.5291 74.3067 13.5019 66.6142L12.7148 67.231ZM0.502846 35.7497C1.87442 48.5503 6.54621 59.358 12.7148 67.231L13.5019 66.6142C7.45373 58.8949 2.84965 48.2658 1.49715 35.6432L0.502846 35.7497ZM4.92396 34.7732L0.946731 35.1993L1.05327 36.1936L5.0305 35.7675L4.92396 34.7732ZM16.6506 64.1472C11.0838 57.0423 6.75059 47.1275 5.47439 35.217L4.48008 35.3236C5.77536 47.4121 10.1763 57.5055 15.8634 64.764L16.6506 64.1472ZM34.8843 76.1807C28.947 75.4228 22.2524 71.2969 16.6506 64.1472L15.8634 64.764C21.5718 72.0495 28.4817 76.3715 34.7577 77.1727L34.8843 76.1807ZM58.5087 58.5647C52.1001 71.6468 43.6947 77.3055 34.8843 76.1807L34.7577 77.1727C44.1903 78.3769 52.9158 72.2548 59.4067 59.0046L58.5087 58.5647Z"
      fill="#195642"
    />
    <path
      d="M57 61.9999L59 58.9999L63 59.4999L61.5 61.9999H57Z"
      fill="#195642"
    />
    <path
      d="M71.761 28.6223C69.0819 30.6229 68.7049 32.6617 69.7453 34.053C70.7857 35.4443 72.8468 35.6583 75.5259 33.6577C78.205 31.6572 78.4921 27.5164 78.4921 27.5164C78.4921 27.5164 74.4372 26.623 71.761 28.6223Z"
      fill="#5ABE89"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M67.626 28.0228C69.6266 30.7019 69.4126 32.763 68.02 33.8006C66.6287 34.841 64.5899 34.464 62.5894 31.7849C60.5888 29.1058 61.4834 25.0537 61.4834 25.0537C61.4834 25.0537 65.6226 25.3449 67.626 28.0228Z"
      fill="#5ABE89"
      stroke="#195642"
      strokeMiterlimit="10"
    />
    <path
      d="M68.842 35.3257C68.2821 35.5618 66.9123 35.9381 65.9115 35.5549L66.7053 34.4172L69.096 33.2946L70.5271 35.3995L70.0444 36.4714C69.804 36.4394 69.1426 35.6942 68.842 35.3257Z"
      fill="#195642"
    />
    <path
      d="M72.5 20C71.0001 22.5 69.4999 22 68.5 21L69 16.5L72.5 15.5V20Z"
      fill="#195642"
    />
    <path
      d="M57.4712 69.644C54.1438 69.3146 52.4906 70.5658 52.3206 72.2948C52.1506 74.0237 53.5282 75.5716 56.8555 75.9011C60.1829 76.2306 63.1872 73.3667 63.1872 73.3667C63.1872 73.3667 60.7956 69.9725 57.4712 69.644Z"
      fill="#5ABE89"
      stroke="#195642"
      strokeMiterlimit="10"
    />
  </svg>
);
