export const ReportV2LargeChapterNumber4Dots = () => (
  <svg width="370" height="400" viewBox="0 0 370 400" fill="currentColor">
    <path
      d="M154.714 177.289C156.112 177.289 157.246 176.155 157.246 174.757C157.246 173.359 156.112 172.225 154.714 172.225C153.315 172.225 152.182 173.359 152.182 174.757C152.182 176.155 153.315 177.289 154.714 177.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M174.969 116.521C176.368 116.521 177.501 115.388 177.501 113.989C177.501 112.591 176.368 111.458 174.969 111.458C173.571 111.458 172.438 112.591 172.438 113.989C172.438 115.388 173.571 116.521 174.969 116.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M205.352 106.394C206.751 106.394 207.884 105.26 207.884 103.862C207.884 102.463 206.751 101.33 205.352 101.33C203.954 101.33 202.82 102.463 202.82 103.862C202.82 105.26 203.954 106.394 205.352 106.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 106.394C227.007 106.394 228.14 105.26 228.14 103.862C228.14 102.463 227.007 101.33 225.608 101.33C224.21 101.33 223.076 102.463 223.076 103.862C223.076 105.26 224.21 106.394 225.608 106.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 116.521C227.007 116.521 228.14 115.388 228.14 113.989C228.14 112.591 227.007 111.458 225.608 111.458C224.21 111.458 223.076 112.591 223.076 113.989C223.076 115.388 224.21 116.521 225.608 116.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 126.649C227.007 126.649 228.14 125.516 228.14 124.117C228.14 122.719 227.007 121.585 225.608 121.585C224.21 121.585 223.076 122.719 223.076 124.117C223.076 125.516 224.21 126.649 225.608 126.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 136.777C227.007 136.777 228.14 135.644 228.14 134.245C228.14 132.847 227.007 131.713 225.608 131.713C224.21 131.713 223.076 132.847 223.076 134.245C223.076 135.644 224.21 136.777 225.608 136.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 146.905C227.007 146.905 228.14 145.772 228.14 144.373C228.14 142.975 227.007 141.841 225.608 141.841C224.21 141.841 223.076 142.975 223.076 144.373C223.076 145.772 224.21 146.905 225.608 146.905Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 157.033C227.007 157.033 228.14 155.9 228.14 154.501C228.14 153.103 227.007 151.969 225.608 151.969C224.21 151.969 223.076 153.103 223.076 154.501C223.076 155.9 224.21 157.033 225.608 157.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 167.161C227.007 167.161 228.14 166.028 228.14 164.629C228.14 163.231 227.007 162.097 225.608 162.097C224.21 162.097 223.076 163.231 223.076 164.629C223.076 166.028 224.21 167.161 225.608 167.161Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 177.289C227.007 177.289 228.14 176.155 228.14 174.757C228.14 173.359 227.007 172.225 225.608 172.225C224.21 172.225 223.076 173.359 223.076 174.757C223.076 176.155 224.21 177.289 225.608 177.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 187.417C227.007 187.417 228.14 186.283 228.14 184.885C228.14 183.487 227.007 182.353 225.608 182.353C224.21 182.353 223.076 183.487 223.076 184.885C223.076 186.283 224.21 187.417 225.608 187.417Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 197.544C227.007 197.544 228.14 196.41 228.14 195.012C228.14 193.614 227.007 192.48 225.608 192.48C224.21 192.48 223.076 193.614 223.076 195.012C223.076 196.41 224.21 197.544 225.608 197.544Z"
      fillOpacity={0.3}
    />
    <path
      d="M195.225 86.2392C196.624 86.2392 197.757 85.1056 197.757 83.7073C197.757 82.3089 196.624 81.1753 195.225 81.1753C193.827 81.1753 192.693 82.3089 192.693 83.7073C192.693 85.1056 193.827 86.2392 195.225 86.2392Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 76.1103C227.007 76.1103 228.14 74.9767 228.14 73.5784C228.14 72.18 227.007 71.0464 225.608 71.0464C224.21 71.0464 223.076 72.18 223.076 73.5784C223.076 74.9767 224.21 76.1103 225.608 76.1103Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 86.2392C227.007 86.2392 228.14 85.1056 228.14 83.7073C228.14 82.3089 227.007 81.1753 225.608 81.1753C224.21 81.1753 223.076 82.3089 223.076 83.7073C223.076 85.1056 224.21 86.2392 225.608 86.2392Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 96.3662C227.007 96.3662 228.14 95.2326 228.14 93.8342C228.14 92.4358 227.007 91.3022 225.608 91.3022C224.21 91.3022 223.076 92.4358 223.076 93.8342C223.076 95.2326 224.21 96.3662 225.608 96.3662Z"
      fillOpacity={0.3}
    />
    <path
      d="M235.736 35.5996C237.134 35.5996 238.268 34.466 238.268 33.0676C238.268 31.6692 237.134 30.5356 235.736 30.5356C234.338 30.5356 233.204 31.6692 233.204 33.0676C233.204 34.466 234.338 35.5996 235.736 35.5996Z"
      fillOpacity={0.3}
    />
    <path
      d="M205.352 359.033C206.751 359.033 207.884 357.9 207.884 356.501C207.884 355.103 206.751 353.969 205.352 353.969C203.954 353.969 202.82 355.103 202.82 356.501C202.82 357.9 203.954 359.033 205.352 359.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 308.394C227.007 308.394 228.14 307.26 228.14 305.862C228.14 304.463 227.007 303.33 225.608 303.33C224.21 303.33 223.076 304.463 223.076 305.862C223.076 307.26 224.21 308.394 225.608 308.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 318.521C227.007 318.521 228.14 317.388 228.14 315.989C228.14 314.591 227.007 313.458 225.608 313.458C224.21 313.458 223.076 314.591 223.076 315.989C223.076 317.388 224.21 318.521 225.608 318.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 328.649C227.007 328.649 228.14 327.516 228.14 326.117C228.14 324.719 227.007 323.585 225.608 323.585C224.21 323.585 223.076 324.719 223.076 326.117C223.076 327.516 224.21 328.649 225.608 328.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 338.777C227.007 338.777 228.14 337.644 228.14 336.245C228.14 334.847 227.007 333.713 225.608 333.713C224.21 333.713 223.076 334.847 223.076 336.245C223.076 337.644 224.21 338.777 225.608 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M276.247 359.033C277.645 359.033 278.779 357.9 278.779 356.501C278.779 355.103 277.645 353.969 276.247 353.969C274.848 353.969 273.715 355.103 273.715 356.501C273.715 357.9 274.848 359.033 276.247 359.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M306.632 369.161C308.03 369.161 309.164 368.028 309.164 366.629C309.164 365.231 308.03 364.097 306.632 364.097C305.233 364.097 304.1 365.231 304.1 366.629C304.1 368.028 305.233 369.161 306.632 369.161Z"
      fillOpacity={0.3}
    />
    <path
      d="M73.6902 278.11C75.0885 278.11 76.2221 276.977 76.2221 275.578C76.2221 274.18 75.0885 273.046 73.6902 273.046C72.2918 273.046 71.1582 274.18 71.1582 275.578C71.1582 276.977 72.2918 278.11 73.6902 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M83.8181 278.11C85.2165 278.11 86.3501 276.977 86.3501 275.578C86.3501 274.18 85.2165 273.046 83.8181 273.046C82.4197 273.046 81.2861 274.18 81.2861 275.578C81.2861 276.977 82.4197 278.11 83.8181 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M93.946 227.472C95.3444 227.472 96.478 226.338 96.478 224.94C96.478 223.541 95.3444 222.408 93.946 222.408C92.5477 222.408 91.4141 223.541 91.4141 224.94C91.4141 226.338 92.5477 227.472 93.946 227.472Z"
      fillOpacity={0.3}
    />
    <path
      d="M93.946 257.854C95.3444 257.854 96.478 256.721 96.478 255.322C96.478 253.924 95.3444 252.791 93.946 252.791C92.5477 252.791 91.4141 253.924 91.4141 255.322C91.4141 256.721 92.5477 257.854 93.946 257.854Z"
      fillOpacity={0.3}
    />
    <path
      d="M93.946 278.11C95.3444 278.11 96.478 276.977 96.478 275.578C96.478 274.18 95.3444 273.046 93.946 273.046C92.5477 273.046 91.4141 274.18 91.4141 275.578C91.4141 276.977 92.5477 278.11 93.946 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M104.074 278.11C105.472 278.11 106.606 276.977 106.606 275.578C106.606 274.18 105.472 273.046 104.074 273.046C102.676 273.046 101.542 274.18 101.542 275.578C101.542 276.977 102.676 278.11 104.074 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M114.202 278.11C115.6 278.11 116.734 276.977 116.734 275.578C116.734 274.18 115.6 273.046 114.202 273.046C112.804 273.046 111.67 274.18 111.67 275.578C111.67 276.977 112.804 278.11 114.202 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M124.329 217.344C125.727 217.344 126.861 216.21 126.861 214.812C126.861 213.413 125.727 212.28 124.329 212.28C122.93 212.28 121.797 213.413 121.797 214.812C121.797 216.21 122.93 217.344 124.329 217.344Z"
      fillOpacity={0.3}
    />
    <path
      d="M124.329 278.11C125.727 278.11 126.861 276.977 126.861 275.578C126.861 274.18 125.727 273.046 124.329 273.046C122.93 273.046 121.797 274.18 121.797 275.578C121.797 276.977 122.93 278.11 124.329 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M134.458 278.11C135.856 278.11 136.99 276.977 136.99 275.578C136.99 274.18 135.856 273.046 134.458 273.046C133.059 273.046 131.926 274.18 131.926 275.578C131.926 276.977 133.059 278.11 134.458 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M144.585 278.11C145.983 278.11 147.117 276.977 147.117 275.578C147.117 274.18 145.983 273.046 144.585 273.046C143.186 273.046 142.053 274.18 142.053 275.578C142.053 276.977 143.186 278.11 144.585 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M154.714 278.11C156.112 278.11 157.246 276.977 157.246 275.578C157.246 274.18 156.112 273.046 154.714 273.046C153.315 273.046 152.182 274.18 152.182 275.578C152.182 276.977 153.315 278.11 154.714 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M164.841 278.11C166.239 278.11 167.373 276.977 167.373 275.578C167.373 274.18 166.239 273.046 164.841 273.046C163.442 273.046 162.309 274.18 162.309 275.578C162.309 276.977 163.442 278.11 164.841 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M174.969 278.11C176.368 278.11 177.501 276.977 177.501 275.578C177.501 274.18 176.368 273.046 174.969 273.046C173.571 273.046 172.438 274.18 172.438 275.578C172.438 276.977 173.571 278.11 174.969 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M185.096 278.11C186.495 278.11 187.628 276.977 187.628 275.578C187.628 274.18 186.495 273.046 185.096 273.046C183.698 273.046 182.564 274.18 182.564 275.578C182.564 276.977 183.698 278.11 185.096 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M195.225 278.11C196.624 278.11 197.757 276.977 197.757 275.578C197.757 274.18 196.624 273.046 195.225 273.046C193.827 273.046 192.693 274.18 192.693 275.578C192.693 276.977 193.827 278.11 195.225 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M205.352 278.11C206.751 278.11 207.884 276.977 207.884 275.578C207.884 274.18 206.751 273.046 205.352 273.046C203.954 273.046 202.82 274.18 202.82 275.578C202.82 276.977 203.954 278.11 205.352 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M215.48 278.11C216.879 278.11 218.012 276.977 218.012 275.578C218.012 274.18 216.879 273.046 215.48 273.046C214.082 273.046 212.948 274.18 212.948 275.578C212.948 276.977 214.082 278.11 215.48 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 207.216C227.007 207.216 228.14 206.082 228.14 204.684C228.14 203.285 227.007 202.152 225.608 202.152C224.21 202.152 223.076 203.285 223.076 204.684C223.076 206.082 224.21 207.216 225.608 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 217.344C227.007 217.344 228.14 216.21 228.14 214.812C228.14 213.413 227.007 212.28 225.608 212.28C224.21 212.28 223.076 213.413 223.076 214.812C223.076 216.21 224.21 217.344 225.608 217.344Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 227.472C227.007 227.472 228.14 226.338 228.14 224.94C228.14 223.541 227.007 222.408 225.608 222.408C224.21 222.408 223.076 223.541 223.076 224.94C223.076 226.338 224.21 227.472 225.608 227.472Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 237.6C227.007 237.6 228.14 236.466 228.14 235.068C228.14 233.669 227.007 232.536 225.608 232.536C224.21 232.536 223.076 233.669 223.076 235.068C223.076 236.466 224.21 237.6 225.608 237.6Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 247.728C227.007 247.728 228.14 246.594 228.14 245.196C228.14 243.797 227.007 242.664 225.608 242.664C224.21 242.664 223.076 243.797 223.076 245.196C223.076 246.594 224.21 247.728 225.608 247.728Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 257.854C227.007 257.854 228.14 256.721 228.14 255.322C228.14 253.924 227.007 252.791 225.608 252.791C224.21 252.791 223.076 253.924 223.076 255.322C223.076 256.721 224.21 257.854 225.608 257.854Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 278.11C227.007 278.11 228.14 276.977 228.14 275.578C228.14 274.18 227.007 273.046 225.608 273.046C224.21 273.046 223.076 274.18 223.076 275.578C223.076 276.977 224.21 278.11 225.608 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 288.239C227.007 288.239 228.14 287.106 228.14 285.707C228.14 284.309 227.007 283.175 225.608 283.175C224.21 283.175 223.076 284.309 223.076 285.707C223.076 287.106 224.21 288.239 225.608 288.239Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.608 298.366C227.007 298.366 228.14 297.233 228.14 295.834C228.14 294.436 227.007 293.302 225.608 293.302C224.21 293.302 223.076 294.436 223.076 295.834C223.076 297.233 224.21 298.366 225.608 298.366Z"
      fillOpacity={0.3}
    />
    <path
      d="M266.12 278.11C267.518 278.11 268.652 276.977 268.652 275.578C268.652 274.18 267.518 273.046 266.12 273.046C264.721 273.046 263.588 274.18 263.588 275.578C263.588 276.977 264.721 278.11 266.12 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M276.247 278.11C277.645 278.11 278.779 276.977 278.779 275.578C278.779 274.18 277.645 273.046 276.247 273.046C274.848 273.046 273.715 274.18 273.715 275.578C273.715 276.977 274.848 278.11 276.247 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M286.376 278.11C287.774 278.11 288.908 276.977 288.908 275.578C288.908 274.18 287.774 273.046 286.376 273.046C284.977 273.046 283.844 274.18 283.844 275.578C283.844 276.977 284.977 278.11 286.376 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M296.503 278.11C297.901 278.11 299.035 276.977 299.035 275.578C299.035 274.18 297.901 273.046 296.503 273.046C295.104 273.046 293.971 274.18 293.971 275.578C293.971 276.977 295.104 278.11 296.503 278.11Z"
      fillOpacity={0.3}
    />
    <path
      d="M306.632 278.11C308.03 278.11 309.164 276.977 309.164 275.578C309.164 274.18 308.03 273.046 306.632 273.046C305.233 273.046 304.1 274.18 304.1 275.578C304.1 276.977 305.233 278.11 306.632 278.11Z"
      fillOpacity={0.3}
    />
    <path d="M124.329 187.417C125.727 187.417 126.861 186.283 126.861 184.885C126.861 183.487 125.727 182.353 124.329 182.353C122.93 182.353 121.797 183.487 121.797 184.885C121.797 186.283 122.93 187.417 124.329 187.417Z" />
    <path d="M124.329 197.544C125.727 197.544 126.861 196.41 126.861 195.012C126.861 193.614 125.727 192.48 124.329 192.48C122.93 192.48 121.797 193.614 121.797 195.012C121.797 196.41 122.93 197.544 124.329 197.544Z" />
    <path d="M134.458 177.289C135.856 177.289 136.99 176.155 136.99 174.757C136.99 173.359 135.856 172.225 134.458 172.225C133.059 172.225 131.926 173.359 131.926 174.757C131.926 176.155 133.059 177.289 134.458 177.289Z" />
    <path d="M134.458 187.417C135.856 187.417 136.99 186.283 136.99 184.885C136.99 183.487 135.856 182.353 134.458 182.353C133.059 182.353 131.926 183.487 131.926 184.885C131.926 186.283 133.059 187.417 134.458 187.417Z" />
    <path d="M134.458 197.544C135.856 197.544 136.99 196.41 136.99 195.012C136.99 193.614 135.856 192.48 134.458 192.48C133.059 192.48 131.926 193.614 131.926 195.012C131.926 196.41 133.059 197.544 134.458 197.544Z" />
    <path d="M144.585 167.161C145.983 167.161 147.117 166.028 147.117 164.629C147.117 163.231 145.983 162.097 144.585 162.097C143.186 162.097 142.053 163.231 142.053 164.629C142.053 166.028 143.186 167.161 144.585 167.161Z" />
    <path d="M144.585 177.289C145.983 177.289 147.117 176.155 147.117 174.757C147.117 173.359 145.983 172.225 144.585 172.225C143.186 172.225 142.053 173.359 142.053 174.757C142.053 176.155 143.186 177.289 144.585 177.289Z" />
    <path d="M144.585 187.417C145.983 187.417 147.117 186.283 147.117 184.885C147.117 183.487 145.983 182.353 144.585 182.353C143.186 182.353 142.053 183.487 142.053 184.885C142.053 186.283 143.186 187.417 144.585 187.417Z" />
    <path d="M154.714 146.905C156.112 146.905 157.246 145.772 157.246 144.373C157.246 142.975 156.112 141.841 154.714 141.841C153.315 141.841 152.182 142.975 152.182 144.373C152.182 145.772 153.315 146.905 154.714 146.905Z" />
    <path d="M154.714 157.033C156.112 157.033 157.246 155.9 157.246 154.501C157.246 153.103 156.112 151.969 154.714 151.969C153.315 151.969 152.182 153.103 152.182 154.501C152.182 155.9 153.315 157.033 154.714 157.033Z" />
    <path d="M154.714 167.161C156.112 167.161 157.246 166.028 157.246 164.629C157.246 163.231 156.112 162.097 154.714 162.097C153.315 162.097 152.182 163.231 152.182 164.629C152.182 166.028 153.315 167.161 154.714 167.161Z" />
    <path d="M164.841 136.777C166.239 136.777 167.373 135.644 167.373 134.245C167.373 132.847 166.239 131.713 164.841 131.713C163.442 131.713 162.309 132.847 162.309 134.245C162.309 135.644 163.442 136.777 164.841 136.777Z" />
    <path d="M164.841 146.905C166.239 146.905 167.373 145.772 167.373 144.373C167.373 142.975 166.239 141.841 164.841 141.841C163.442 141.841 162.309 142.975 162.309 144.373C162.309 145.772 163.442 146.905 164.841 146.905Z" />
    <path d="M164.841 157.033C166.239 157.033 167.373 155.9 167.373 154.501C167.373 153.103 166.239 151.969 164.841 151.969C163.442 151.969 162.309 153.103 162.309 154.501C162.309 155.9 163.442 157.033 164.841 157.033Z" />
    <path d="M174.969 126.649C176.368 126.649 177.501 125.516 177.501 124.117C177.501 122.719 176.368 121.585 174.969 121.585C173.571 121.585 172.438 122.719 172.438 124.117C172.438 125.516 173.571 126.649 174.969 126.649Z" />
    <path d="M174.969 136.777C176.368 136.777 177.501 135.644 177.501 134.245C177.501 132.847 176.368 131.713 174.969 131.713C173.571 131.713 172.438 132.847 172.438 134.245C172.438 135.644 173.571 136.777 174.969 136.777Z" />
    <path d="M174.969 146.905C176.368 146.905 177.501 145.772 177.501 144.373C177.501 142.975 176.368 141.841 174.969 141.841C173.571 141.841 172.438 142.975 172.438 144.373C172.438 145.772 173.571 146.905 174.969 146.905Z" />
    <path d="M185.096 106.394C186.495 106.394 187.628 105.26 187.628 103.862C187.628 102.463 186.495 101.33 185.096 101.33C183.698 101.33 182.564 102.463 182.564 103.862C182.564 105.26 183.698 106.394 185.096 106.394Z" />
    <path d="M185.096 116.521C186.495 116.521 187.628 115.388 187.628 113.989C187.628 112.591 186.495 111.458 185.096 111.458C183.698 111.458 182.564 112.591 182.564 113.989C182.564 115.388 183.698 116.521 185.096 116.521Z" />
    <path d="M185.096 126.649C186.495 126.649 187.628 125.516 187.628 124.117C187.628 122.719 186.495 121.585 185.096 121.585C183.698 121.585 182.564 122.719 182.564 124.117C182.564 125.516 183.698 126.649 185.096 126.649Z" />
    <path d="M195.225 106.394C196.624 106.394 197.757 105.26 197.757 103.862C197.757 102.463 196.624 101.33 195.225 101.33C193.827 101.33 192.693 102.463 192.693 103.862C192.693 105.26 193.827 106.394 195.225 106.394Z" />
    <path d="M195.225 116.521C196.624 116.521 197.757 115.388 197.757 113.989C197.757 112.591 196.624 111.458 195.225 111.458C193.827 111.458 192.693 112.591 192.693 113.989C192.693 115.388 193.827 116.521 195.225 116.521Z" />
    <path d="M235.736 106.394C237.134 106.394 238.268 105.26 238.268 103.862C238.268 102.463 237.134 101.33 235.736 101.33C234.338 101.33 233.204 102.463 233.204 103.862C233.204 105.26 234.338 106.394 235.736 106.394Z" />
    <path d="M235.736 116.521C237.134 116.521 238.268 115.388 238.268 113.989C238.268 112.591 237.134 111.458 235.736 111.458C234.338 111.458 233.204 112.591 233.204 113.989C233.204 115.388 234.338 116.521 235.736 116.521Z" />
    <path d="M235.736 126.649C237.134 126.649 238.268 125.516 238.268 124.117C238.268 122.719 237.134 121.585 235.736 121.585C234.338 121.585 233.204 122.719 233.204 124.117C233.204 125.516 234.338 126.649 235.736 126.649Z" />
    <path d="M235.736 136.777C237.134 136.777 238.268 135.644 238.268 134.245C238.268 132.847 237.134 131.713 235.736 131.713C234.338 131.713 233.204 132.847 233.204 134.245C233.204 135.644 234.338 136.777 235.736 136.777Z" />
    <path d="M235.736 146.905C237.134 146.905 238.268 145.772 238.268 144.373C238.268 142.975 237.134 141.841 235.736 141.841C234.338 141.841 233.204 142.975 233.204 144.373C233.204 145.772 234.338 146.905 235.736 146.905Z" />
    <path d="M235.736 157.033C237.134 157.033 238.268 155.9 238.268 154.501C238.268 153.103 237.134 151.969 235.736 151.969C234.338 151.969 233.204 153.103 233.204 154.501C233.204 155.9 234.338 157.033 235.736 157.033Z" />
    <path d="M235.736 167.161C237.134 167.161 238.268 166.028 238.268 164.629C238.268 163.231 237.134 162.097 235.736 162.097C234.338 162.097 233.204 163.231 233.204 164.629C233.204 166.028 234.338 167.161 235.736 167.161Z" />
    <path d="M235.736 177.289C237.134 177.289 238.268 176.155 238.268 174.757C238.268 173.359 237.134 172.225 235.736 172.225C234.338 172.225 233.204 173.359 233.204 174.757C233.204 176.155 234.338 177.289 235.736 177.289Z" />
    <path d="M235.736 187.417C237.134 187.417 238.268 186.283 238.268 184.885C238.268 183.487 237.134 182.353 235.736 182.353C234.338 182.353 233.204 183.487 233.204 184.885C233.204 186.283 234.338 187.417 235.736 187.417Z" />
    <path d="M235.736 197.544C237.134 197.544 238.268 196.41 238.268 195.012C238.268 193.614 237.134 192.48 235.736 192.48C234.338 192.48 233.204 193.614 233.204 195.012C233.204 196.41 234.338 197.544 235.736 197.544Z" />
    <path d="M245.864 106.394C247.262 106.394 248.396 105.26 248.396 103.862C248.396 102.463 247.262 101.33 245.864 101.33C244.466 101.33 243.332 102.463 243.332 103.862C243.332 105.26 244.466 106.394 245.864 106.394Z" />
    <path d="M245.864 116.521C247.262 116.521 248.396 115.388 248.396 113.989C248.396 112.591 247.262 111.458 245.864 111.458C244.466 111.458 243.332 112.591 243.332 113.989C243.332 115.388 244.466 116.521 245.864 116.521Z" />
    <path d="M245.864 126.649C247.262 126.649 248.396 125.516 248.396 124.117C248.396 122.719 247.262 121.585 245.864 121.585C244.466 121.585 243.332 122.719 243.332 124.117C243.332 125.516 244.466 126.649 245.864 126.649Z" />
    <path d="M245.864 136.777C247.262 136.777 248.396 135.644 248.396 134.245C248.396 132.847 247.262 131.713 245.864 131.713C244.466 131.713 243.332 132.847 243.332 134.245C243.332 135.644 244.466 136.777 245.864 136.777Z" />
    <path d="M245.864 146.905C247.262 146.905 248.396 145.772 248.396 144.373C248.396 142.975 247.262 141.841 245.864 141.841C244.466 141.841 243.332 142.975 243.332 144.373C243.332 145.772 244.466 146.905 245.864 146.905Z" />
    <path d="M245.864 157.033C247.262 157.033 248.396 155.9 248.396 154.501C248.396 153.103 247.262 151.969 245.864 151.969C244.466 151.969 243.332 153.103 243.332 154.501C243.332 155.9 244.466 157.033 245.864 157.033Z" />
    <path d="M245.864 167.161C247.262 167.161 248.396 166.028 248.396 164.629C248.396 163.231 247.262 162.097 245.864 162.097C244.466 162.097 243.332 163.231 243.332 164.629C243.332 166.028 244.466 167.161 245.864 167.161Z" />
    <path d="M245.864 177.289C247.262 177.289 248.396 176.155 248.396 174.757C248.396 173.359 247.262 172.225 245.864 172.225C244.466 172.225 243.332 173.359 243.332 174.757C243.332 176.155 244.466 177.289 245.864 177.289Z" />
    <path d="M245.864 187.417C247.262 187.417 248.396 186.283 248.396 184.885C248.396 183.487 247.262 182.353 245.864 182.353C244.466 182.353 243.332 183.487 243.332 184.885C243.332 186.283 244.466 187.417 245.864 187.417Z" />
    <path d="M245.864 197.544C247.262 197.544 248.396 196.41 248.396 195.012C248.396 193.614 247.262 192.48 245.864 192.48C244.466 192.48 243.332 193.614 243.332 195.012C243.332 196.41 244.466 197.544 245.864 197.544Z" />
    <path d="M255.992 106.394C257.39 106.394 258.524 105.26 258.524 103.862C258.524 102.463 257.39 101.33 255.992 101.33C254.594 101.33 253.46 102.463 253.46 103.862C253.46 105.26 254.594 106.394 255.992 106.394Z" />
    <path d="M255.992 116.521C257.39 116.521 258.524 115.388 258.524 113.989C258.524 112.591 257.39 111.458 255.992 111.458C254.594 111.458 253.46 112.591 253.46 113.989C253.46 115.388 254.594 116.521 255.992 116.521Z" />
    <path d="M255.992 126.649C257.39 126.649 258.524 125.516 258.524 124.117C258.524 122.719 257.39 121.585 255.992 121.585C254.594 121.585 253.46 122.719 253.46 124.117C253.46 125.516 254.594 126.649 255.992 126.649Z" />
    <path d="M255.992 136.777C257.39 136.777 258.524 135.644 258.524 134.245C258.524 132.847 257.39 131.713 255.992 131.713C254.594 131.713 253.46 132.847 253.46 134.245C253.46 135.644 254.594 136.777 255.992 136.777Z" />
    <path d="M255.992 146.905C257.39 146.905 258.524 145.772 258.524 144.373C258.524 142.975 257.39 141.841 255.992 141.841C254.594 141.841 253.46 142.975 253.46 144.373C253.46 145.772 254.594 146.905 255.992 146.905Z" />
    <path d="M255.992 157.033C257.39 157.033 258.524 155.9 258.524 154.501C258.524 153.103 257.39 151.969 255.992 151.969C254.594 151.969 253.46 153.103 253.46 154.501C253.46 155.9 254.594 157.033 255.992 157.033Z" />
    <path d="M255.992 167.161C257.39 167.161 258.524 166.028 258.524 164.629C258.524 163.231 257.39 162.097 255.992 162.097C254.594 162.097 253.46 163.231 253.46 164.629C253.46 166.028 254.594 167.161 255.992 167.161Z" />
    <path d="M255.992 177.289C257.39 177.289 258.524 176.155 258.524 174.757C258.524 173.359 257.39 172.225 255.992 172.225C254.594 172.225 253.46 173.359 253.46 174.757C253.46 176.155 254.594 177.289 255.992 177.289Z" />
    <path d="M255.992 187.417C257.39 187.417 258.524 186.283 258.524 184.885C258.524 183.487 257.39 182.353 255.992 182.353C254.594 182.353 253.46 183.487 253.46 184.885C253.46 186.283 254.594 187.417 255.992 187.417Z" />
    <path d="M255.992 197.544C257.39 197.544 258.524 196.41 258.524 195.012C258.524 193.614 257.39 192.48 255.992 192.48C254.594 192.48 253.46 193.614 253.46 195.012C253.46 196.41 254.594 197.544 255.992 197.544Z" />
    <path d="M195.225 96.3662C196.624 96.3662 197.757 95.2326 197.757 93.8342C197.757 92.4358 196.624 91.3022 195.225 91.3022C193.827 91.3022 192.693 92.4358 192.693 93.8342C192.693 95.2326 193.827 96.3662 195.225 96.3662Z" />
    <path d="M205.352 76.1103C206.751 76.1103 207.884 74.9767 207.884 73.5784C207.884 72.18 206.751 71.0464 205.352 71.0464C203.954 71.0464 202.82 72.18 202.82 73.5784C202.82 74.9767 203.954 76.1103 205.352 76.1103Z" />
    <path d="M205.352 86.2392C206.751 86.2392 207.884 85.1056 207.884 83.7073C207.884 82.3089 206.751 81.1753 205.352 81.1753C203.954 81.1753 202.82 82.3089 202.82 83.7073C202.82 85.1056 203.954 86.2392 205.352 86.2392Z" />
    <path d="M205.352 96.3662C206.751 96.3662 207.884 95.2326 207.884 93.8342C207.884 92.4358 206.751 91.3022 205.352 91.3022C203.954 91.3022 202.82 92.4358 202.82 93.8342C202.82 95.2326 203.954 96.3662 205.352 96.3662Z" />
    <path d="M215.48 65.9834C216.879 65.9834 218.012 64.8498 218.012 63.4514C218.012 62.053 216.879 60.9194 215.48 60.9194C214.082 60.9194 212.948 62.053 212.948 63.4514C212.948 64.8498 214.082 65.9834 215.48 65.9834Z" />
    <path d="M215.48 76.1103C216.879 76.1103 218.012 74.9767 218.012 73.5784C218.012 72.18 216.879 71.0464 215.48 71.0464C214.082 71.0464 212.948 72.18 212.948 73.5784C212.948 74.9767 214.082 76.1103 215.48 76.1103Z" />
    <path d="M215.48 86.2392C216.879 86.2392 218.012 85.1056 218.012 83.7073C218.012 82.3089 216.879 81.1753 215.48 81.1753C214.082 81.1753 212.948 82.3089 212.948 83.7073C212.948 85.1056 214.082 86.2392 215.48 86.2392Z" />
    <path d="M225.608 55.8545C227.007 55.8545 228.14 54.7209 228.14 53.3225C228.14 51.9241 227.007 50.7905 225.608 50.7905C224.21 50.7905 223.076 51.9241 223.076 53.3225C223.076 54.7209 224.21 55.8545 225.608 55.8545Z" />
    <path d="M225.608 65.9834C227.007 65.9834 228.14 64.8498 228.14 63.4514C228.14 62.053 227.007 60.9194 225.608 60.9194C224.21 60.9194 223.076 62.053 223.076 63.4514C223.076 64.8498 224.21 65.9834 225.608 65.9834Z" />
    <path d="M235.736 45.7275C237.134 45.7275 238.268 44.5939 238.268 43.1955C238.268 41.7972 237.134 40.6636 235.736 40.6636C234.338 40.6636 233.204 41.7972 233.204 43.1955C233.204 44.5939 234.338 45.7275 235.736 45.7275Z" />
    <path d="M235.736 55.8545C237.134 55.8545 238.268 54.7209 238.268 53.3225C238.268 51.9241 237.134 50.7905 235.736 50.7905C234.338 50.7905 233.204 51.9241 233.204 53.3225C233.204 54.7209 234.338 55.8545 235.736 55.8545Z" />
    <path d="M235.736 65.9834C237.134 65.9834 238.268 64.8498 238.268 63.4514C238.268 62.053 237.134 60.9194 235.736 60.9194C234.338 60.9194 233.204 62.053 233.204 63.4514C233.204 64.8498 234.338 65.9834 235.736 65.9834Z" />
    <path d="M235.736 76.1103C237.134 76.1103 238.268 74.9767 238.268 73.5784C238.268 72.18 237.134 71.0464 235.736 71.0464C234.338 71.0464 233.204 72.18 233.204 73.5784C233.204 74.9767 234.338 76.1103 235.736 76.1103Z" />
    <path d="M235.736 86.2392C237.134 86.2392 238.268 85.1056 238.268 83.7073C238.268 82.3089 237.134 81.1753 235.736 81.1753C234.338 81.1753 233.204 82.3089 233.204 83.7073C233.204 85.1056 234.338 86.2392 235.736 86.2392Z" />
    <path d="M235.736 96.3662C237.134 96.3662 238.268 95.2326 238.268 93.8342C238.268 92.4358 237.134 91.3022 235.736 91.3022C234.338 91.3022 233.204 92.4358 233.204 93.8342C233.204 95.2326 234.338 96.3662 235.736 96.3662Z" />
    <path d="M245.864 35.5996C247.262 35.5996 248.396 34.466 248.396 33.0676C248.396 31.6692 247.262 30.5356 245.864 30.5356C244.466 30.5356 243.332 31.6692 243.332 33.0676C243.332 34.466 244.466 35.5996 245.864 35.5996Z" />
    <path d="M245.864 45.7275C247.262 45.7275 248.396 44.5939 248.396 43.1955C248.396 41.7972 247.262 40.6636 245.864 40.6636C244.466 40.6636 243.332 41.7972 243.332 43.1955C243.332 44.5939 244.466 45.7275 245.864 45.7275Z" />
    <path d="M245.864 55.8545C247.262 55.8545 248.396 54.7209 248.396 53.3225C248.396 51.9241 247.262 50.7905 245.864 50.7905C244.466 50.7905 243.332 51.9241 243.332 53.3225C243.332 54.7209 244.466 55.8545 245.864 55.8545Z" />
    <path d="M245.864 65.9834C247.262 65.9834 248.396 64.8498 248.396 63.4514C248.396 62.053 247.262 60.9194 245.864 60.9194C244.466 60.9194 243.332 62.053 243.332 63.4514C243.332 64.8498 244.466 65.9834 245.864 65.9834Z" />
    <path d="M245.864 76.1103C247.262 76.1103 248.396 74.9767 248.396 73.5784C248.396 72.18 247.262 71.0464 245.864 71.0464C244.466 71.0464 243.332 72.18 243.332 73.5784C243.332 74.9767 244.466 76.1103 245.864 76.1103Z" />
    <path d="M245.864 86.2392C247.262 86.2392 248.396 85.1056 248.396 83.7073C248.396 82.3089 247.262 81.1753 245.864 81.1753C244.466 81.1753 243.332 82.3089 243.332 83.7073C243.332 85.1056 244.466 86.2392 245.864 86.2392Z" />
    <path d="M245.864 96.3662C247.262 96.3662 248.396 95.2326 248.396 93.8342C248.396 92.4358 247.262 91.3022 245.864 91.3022C244.466 91.3022 243.332 92.4358 243.332 93.8342C243.332 95.2326 244.466 96.3662 245.864 96.3662Z" />
    <path d="M255.992 35.5996C257.39 35.5996 258.524 34.466 258.524 33.0676C258.524 31.6692 257.39 30.5356 255.992 30.5356C254.594 30.5356 253.46 31.6692 253.46 33.0676C253.46 34.466 254.594 35.5996 255.992 35.5996Z" />
    <path d="M255.992 45.7275C257.39 45.7275 258.524 44.5939 258.524 43.1955C258.524 41.7972 257.39 40.6636 255.992 40.6636C254.594 40.6636 253.46 41.7972 253.46 43.1955C253.46 44.5939 254.594 45.7275 255.992 45.7275Z" />
    <path d="M255.992 55.8545C257.39 55.8545 258.524 54.7209 258.524 53.3225C258.524 51.9241 257.39 50.7905 255.992 50.7905C254.594 50.7905 253.46 51.9241 253.46 53.3225C253.46 54.7209 254.594 55.8545 255.992 55.8545Z" />
    <path d="M255.992 65.9834C257.39 65.9834 258.524 64.8498 258.524 63.4514C258.524 62.053 257.39 60.9194 255.992 60.9194C254.594 60.9194 253.46 62.053 253.46 63.4514C253.46 64.8498 254.594 65.9834 255.992 65.9834Z" />
    <path d="M255.992 76.1103C257.39 76.1103 258.524 74.9767 258.524 73.5784C258.524 72.18 257.39 71.0464 255.992 71.0464C254.594 71.0464 253.46 72.18 253.46 73.5784C253.46 74.9767 254.594 76.1103 255.992 76.1103Z" />
    <path d="M255.992 86.2392C257.39 86.2392 258.524 85.1056 258.524 83.7073C258.524 82.3089 257.39 81.1753 255.992 81.1753C254.594 81.1753 253.46 82.3089 253.46 83.7073C253.46 85.1056 254.594 86.2392 255.992 86.2392Z" />
    <path d="M255.992 96.3662C257.39 96.3662 258.524 95.2326 258.524 93.8342C258.524 92.4358 257.39 91.3022 255.992 91.3022C254.594 91.3022 253.46 92.4358 253.46 93.8342C253.46 95.2326 254.594 96.3662 255.992 96.3662Z" />
    <path d="M174.969 369.161C176.368 369.161 177.501 368.028 177.501 366.629C177.501 365.231 176.368 364.097 174.969 364.097C173.571 364.097 172.438 365.231 172.438 366.629C172.438 368.028 173.571 369.161 174.969 369.161Z" />
    <path d="M185.096 369.161C186.495 369.161 187.628 368.028 187.628 366.629C187.628 365.231 186.495 364.097 185.096 364.097C183.698 364.097 182.564 365.231 182.564 366.629C182.564 368.028 183.698 369.161 185.096 369.161Z" />
    <path d="M195.225 369.161C196.624 369.161 197.757 368.028 197.757 366.629C197.757 365.231 196.624 364.097 195.225 364.097C193.827 364.097 192.693 365.231 192.693 366.629C192.693 368.028 193.827 369.161 195.225 369.161Z" />
    <path d="M205.352 369.161C206.751 369.161 207.884 368.028 207.884 366.629C207.884 365.231 206.751 364.097 205.352 364.097C203.954 364.097 202.82 365.231 202.82 366.629C202.82 368.028 203.954 369.161 205.352 369.161Z" />
    <path d="M215.48 359.033C216.879 359.033 218.012 357.9 218.012 356.501C218.012 355.103 216.879 353.969 215.48 353.969C214.082 353.969 212.948 355.103 212.948 356.501C212.948 357.9 214.082 359.033 215.48 359.033Z" />
    <path d="M215.48 369.161C216.879 369.161 218.012 368.028 218.012 366.629C218.012 365.231 216.879 364.097 215.48 364.097C214.082 364.097 212.948 365.231 212.948 366.629C212.948 368.028 214.082 369.161 215.48 369.161Z" />
    <path d="M225.608 348.905C227.007 348.905 228.14 347.772 228.14 346.373C228.14 344.975 227.007 343.841 225.608 343.841C224.21 343.841 223.076 344.975 223.076 346.373C223.076 347.772 224.21 348.905 225.608 348.905Z" />
    <path d="M225.608 359.033C227.007 359.033 228.14 357.9 228.14 356.501C228.14 355.103 227.007 353.969 225.608 353.969C224.21 353.969 223.076 355.103 223.076 356.501C223.076 357.9 224.21 359.033 225.608 359.033Z" />
    <path d="M225.608 369.161C227.007 369.161 228.14 368.028 228.14 366.629C228.14 365.231 227.007 364.097 225.608 364.097C224.21 364.097 223.076 365.231 223.076 366.629C223.076 368.028 224.21 369.161 225.608 369.161Z" />
    <path d="M235.736 308.394C237.134 308.394 238.268 307.26 238.268 305.862C238.268 304.463 237.134 303.33 235.736 303.33C234.338 303.33 233.204 304.463 233.204 305.862C233.204 307.26 234.338 308.394 235.736 308.394Z" />
    <path d="M235.736 318.521C237.134 318.521 238.268 317.388 238.268 315.989C238.268 314.591 237.134 313.458 235.736 313.458C234.338 313.458 233.204 314.591 233.204 315.989C233.204 317.388 234.338 318.521 235.736 318.521Z" />
    <path d="M235.736 328.649C237.134 328.649 238.268 327.516 238.268 326.117C238.268 324.719 237.134 323.585 235.736 323.585C234.338 323.585 233.204 324.719 233.204 326.117C233.204 327.516 234.338 328.649 235.736 328.649Z" />
    <path d="M235.736 338.777C237.134 338.777 238.268 337.644 238.268 336.245C238.268 334.847 237.134 333.713 235.736 333.713C234.338 333.713 233.204 334.847 233.204 336.245C233.204 337.644 234.338 338.777 235.736 338.777Z" />
    <path d="M235.736 348.905C237.134 348.905 238.268 347.772 238.268 346.373C238.268 344.975 237.134 343.841 235.736 343.841C234.338 343.841 233.204 344.975 233.204 346.373C233.204 347.772 234.338 348.905 235.736 348.905Z" />
    <path d="M235.736 359.033C237.134 359.033 238.268 357.9 238.268 356.501C238.268 355.103 237.134 353.969 235.736 353.969C234.338 353.969 233.204 355.103 233.204 356.501C233.204 357.9 234.338 359.033 235.736 359.033Z" />
    <path d="M235.736 369.161C237.134 369.161 238.268 368.028 238.268 366.629C238.268 365.231 237.134 364.097 235.736 364.097C234.338 364.097 233.204 365.231 233.204 366.629C233.204 368.028 234.338 369.161 235.736 369.161Z" />
    <path d="M245.864 308.394C247.262 308.394 248.396 307.26 248.396 305.862C248.396 304.463 247.262 303.33 245.864 303.33C244.466 303.33 243.332 304.463 243.332 305.862C243.332 307.26 244.466 308.394 245.864 308.394Z" />
    <path d="M245.864 318.521C247.262 318.521 248.396 317.388 248.396 315.989C248.396 314.591 247.262 313.458 245.864 313.458C244.466 313.458 243.332 314.591 243.332 315.989C243.332 317.388 244.466 318.521 245.864 318.521Z" />
    <path d="M245.864 328.649C247.262 328.649 248.396 327.516 248.396 326.117C248.396 324.719 247.262 323.585 245.864 323.585C244.466 323.585 243.332 324.719 243.332 326.117C243.332 327.516 244.466 328.649 245.864 328.649Z" />
    <path d="M245.864 338.777C247.262 338.777 248.396 337.644 248.396 336.245C248.396 334.847 247.262 333.713 245.864 333.713C244.466 333.713 243.332 334.847 243.332 336.245C243.332 337.644 244.466 338.777 245.864 338.777Z" />
    <path d="M245.864 348.905C247.262 348.905 248.396 347.772 248.396 346.373C248.396 344.975 247.262 343.841 245.864 343.841C244.466 343.841 243.332 344.975 243.332 346.373C243.332 347.772 244.466 348.905 245.864 348.905Z" />
    <path d="M245.864 359.033C247.262 359.033 248.396 357.9 248.396 356.501C248.396 355.103 247.262 353.969 245.864 353.969C244.466 353.969 243.332 355.103 243.332 356.501C243.332 357.9 244.466 359.033 245.864 359.033Z" />
    <path d="M245.864 369.161C247.262 369.161 248.396 368.028 248.396 366.629C248.396 365.231 247.262 364.097 245.864 364.097C244.466 364.097 243.332 365.231 243.332 366.629C243.332 368.028 244.466 369.161 245.864 369.161Z" />
    <path d="M255.992 308.394C257.39 308.394 258.524 307.26 258.524 305.862C258.524 304.463 257.39 303.33 255.992 303.33C254.594 303.33 253.46 304.463 253.46 305.862C253.46 307.26 254.594 308.394 255.992 308.394Z" />
    <path d="M255.992 318.521C257.39 318.521 258.524 317.388 258.524 315.989C258.524 314.591 257.39 313.458 255.992 313.458C254.594 313.458 253.46 314.591 253.46 315.989C253.46 317.388 254.594 318.521 255.992 318.521Z" />
    <path d="M255.992 328.649C257.39 328.649 258.524 327.516 258.524 326.117C258.524 324.719 257.39 323.585 255.992 323.585C254.594 323.585 253.46 324.719 253.46 326.117C253.46 327.516 254.594 328.649 255.992 328.649Z" />
    <path d="M255.992 338.777C257.39 338.777 258.524 337.644 258.524 336.245C258.524 334.847 257.39 333.713 255.992 333.713C254.594 333.713 253.46 334.847 253.46 336.245C253.46 337.644 254.594 338.777 255.992 338.777Z" />
    <path d="M255.992 348.905C257.39 348.905 258.524 347.772 258.524 346.373C258.524 344.975 257.39 343.841 255.992 343.841C254.594 343.841 253.46 344.975 253.46 346.373C253.46 347.772 254.594 348.905 255.992 348.905Z" />
    <path d="M255.992 359.033C257.39 359.033 258.524 357.9 258.524 356.501C258.524 355.103 257.39 353.969 255.992 353.969C254.594 353.969 253.46 355.103 253.46 356.501C253.46 357.9 254.594 359.033 255.992 359.033Z" />
    <path d="M255.992 369.161C257.39 369.161 258.524 368.028 258.524 366.629C258.524 365.231 257.39 364.097 255.992 364.097C254.594 364.097 253.46 365.231 253.46 366.629C253.46 368.028 254.594 369.161 255.992 369.161Z" />
    <path d="M266.12 359.033C267.518 359.033 268.652 357.9 268.652 356.501C268.652 355.103 267.518 353.969 266.12 353.969C264.721 353.969 263.588 355.103 263.588 356.501C263.588 357.9 264.721 359.033 266.12 359.033Z" />
    <path d="M266.12 369.161C267.518 369.161 268.652 368.028 268.652 366.629C268.652 365.231 267.518 364.097 266.12 364.097C264.721 364.097 263.588 365.231 263.588 366.629C263.588 368.028 264.721 369.161 266.12 369.161Z" />
    <path d="M276.247 369.161C277.645 369.161 278.779 368.028 278.779 366.629C278.779 365.231 277.645 364.097 276.247 364.097C274.848 364.097 273.715 365.231 273.715 366.629C273.715 368.028 274.848 369.161 276.247 369.161Z" />
    <path d="M286.376 369.161C287.774 369.161 288.908 368.028 288.908 366.629C288.908 365.231 287.774 364.097 286.376 364.097C284.977 364.097 283.844 365.231 283.844 366.629C283.844 368.028 284.977 369.161 286.376 369.161Z" />
    <path d="M296.503 369.161C297.901 369.161 299.035 368.028 299.035 366.629C299.035 365.231 297.901 364.097 296.503 364.097C295.104 364.097 293.971 365.231 293.971 366.629C293.971 368.028 295.104 369.161 296.503 369.161Z" />
    <path d="M73.6902 257.854C75.0885 257.854 76.2221 256.721 76.2221 255.322C76.2221 253.924 75.0885 252.791 73.6902 252.791C72.2918 252.791 71.1582 253.924 71.1582 255.322C71.1582 256.721 72.2918 257.854 73.6902 257.854Z" />
    <path d="M73.6902 267.983C75.0885 267.983 76.2221 266.85 76.2221 265.451C76.2221 264.053 75.0885 262.919 73.6902 262.919C72.2918 262.919 71.1582 264.053 71.1582 265.451C71.1582 266.85 72.2918 267.983 73.6902 267.983Z" />
    <path d="M83.8181 247.728C85.2165 247.728 86.3501 246.594 86.3501 245.196C86.3501 243.797 85.2165 242.664 83.8181 242.664C82.4197 242.664 81.2861 243.797 81.2861 245.196C81.2861 246.594 82.4197 247.728 83.8181 247.728Z" />
    <path d="M83.8181 257.854C85.2165 257.854 86.3501 256.721 86.3501 255.322C86.3501 253.924 85.2165 252.791 83.8181 252.791C82.4197 252.791 81.2861 253.924 81.2861 255.322C81.2861 256.721 82.4197 257.854 83.8181 257.854Z" />
    <path d="M83.8181 267.983C85.2165 267.983 86.3501 266.85 86.3501 265.451C86.3501 264.053 85.2165 262.919 83.8181 262.919C82.4197 262.919 81.2861 264.053 81.2861 265.451C81.2861 266.85 82.4197 267.983 83.8181 267.983Z" />
    <path d="M93.946 237.6C95.3444 237.6 96.478 236.466 96.478 235.068C96.478 233.669 95.3444 232.536 93.946 232.536C92.5477 232.536 91.4141 233.669 91.4141 235.068C91.4141 236.466 92.5477 237.6 93.946 237.6Z" />
    <path d="M93.946 247.728C95.3444 247.728 96.478 246.594 96.478 245.196C96.478 243.797 95.3444 242.664 93.946 242.664C92.5477 242.664 91.4141 243.797 91.4141 245.196C91.4141 246.594 92.5477 247.728 93.946 247.728Z" />
    <path d="M93.946 267.983C95.3444 267.983 96.478 266.85 96.478 265.451C96.478 264.053 95.3444 262.919 93.946 262.919C92.5477 262.919 91.4141 264.053 91.4141 265.451C91.4141 266.85 92.5477 267.983 93.946 267.983Z" />
    <path d="M104.074 217.344C105.472 217.344 106.606 216.21 106.606 214.812C106.606 213.413 105.472 212.28 104.074 212.28C102.676 212.28 101.542 213.413 101.542 214.812C101.542 216.21 102.676 217.344 104.074 217.344Z" />
    <path d="M104.074 227.472C105.472 227.472 106.606 226.338 106.606 224.94C106.606 223.541 105.472 222.408 104.074 222.408C102.676 222.408 101.542 223.541 101.542 224.94C101.542 226.338 102.676 227.472 104.074 227.472Z" />
    <path d="M104.074 237.6C105.472 237.6 106.606 236.466 106.606 235.068C106.606 233.669 105.472 232.536 104.074 232.536C102.676 232.536 101.542 233.669 101.542 235.068C101.542 236.466 102.676 237.6 104.074 237.6Z" />
    <path d="M104.074 267.983C105.472 267.983 106.606 266.85 106.606 265.451C106.606 264.053 105.472 262.919 104.074 262.919C102.676 262.919 101.542 264.053 101.542 265.451C101.542 266.85 102.676 267.983 104.074 267.983Z" />
    <path d="M114.202 207.216C115.6 207.216 116.734 206.082 116.734 204.684C116.734 203.285 115.6 202.152 114.202 202.152C112.804 202.152 111.67 203.285 111.67 204.684C111.67 206.082 112.804 207.216 114.202 207.216Z" />
    <path d="M114.202 217.344C115.6 217.344 116.734 216.21 116.734 214.812C116.734 213.413 115.6 212.28 114.202 212.28C112.804 212.28 111.67 213.413 111.67 214.812C111.67 216.21 112.804 217.344 114.202 217.344Z" />
    <path d="M114.202 227.472C115.6 227.472 116.734 226.338 116.734 224.94C116.734 223.541 115.6 222.408 114.202 222.408C112.804 222.408 111.67 223.541 111.67 224.94C111.67 226.338 112.804 227.472 114.202 227.472Z" />
    <path d="M114.202 267.983C115.6 267.983 116.734 266.85 116.734 265.451C116.734 264.053 115.6 262.919 114.202 262.919C112.804 262.919 111.67 264.053 111.67 265.451C111.67 266.85 112.804 267.983 114.202 267.983Z" />
    <path d="M124.329 207.216C125.727 207.216 126.861 206.082 126.861 204.684C126.861 203.285 125.727 202.152 124.329 202.152C122.93 202.152 121.797 203.285 121.797 204.684C121.797 206.082 122.93 207.216 124.329 207.216Z" />
    <path d="M124.329 267.983C125.727 267.983 126.861 266.85 126.861 265.451C126.861 264.053 125.727 262.919 124.329 262.919C122.93 262.919 121.797 264.053 121.797 265.451C121.797 266.85 122.93 267.983 124.329 267.983Z" />
    <path d="M134.458 267.983C135.856 267.983 136.99 266.85 136.99 265.451C136.99 264.053 135.856 262.919 134.458 262.919C133.059 262.919 131.926 264.053 131.926 265.451C131.926 266.85 133.059 267.983 134.458 267.983Z" />
    <path d="M144.585 267.983C145.983 267.983 147.117 266.85 147.117 265.451C147.117 264.053 145.983 262.919 144.585 262.919C143.186 262.919 142.053 264.053 142.053 265.451C142.053 266.85 143.186 267.983 144.585 267.983Z" />
    <path d="M154.714 267.983C156.112 267.983 157.246 266.85 157.246 265.451C157.246 264.053 156.112 262.919 154.714 262.919C153.315 262.919 152.182 264.053 152.182 265.451C152.182 266.85 153.315 267.983 154.714 267.983Z" />
    <path d="M164.841 267.983C166.239 267.983 167.373 266.85 167.373 265.451C167.373 264.053 166.239 262.919 164.841 262.919C163.442 262.919 162.309 264.053 162.309 265.451C162.309 266.85 163.442 267.983 164.841 267.983Z" />
    <path d="M174.969 267.983C176.368 267.983 177.501 266.85 177.501 265.451C177.501 264.053 176.368 262.919 174.969 262.919C173.571 262.919 172.438 264.053 172.438 265.451C172.438 266.85 173.571 267.983 174.969 267.983Z" />
    <path d="M185.096 267.983C186.495 267.983 187.628 266.85 187.628 265.451C187.628 264.053 186.495 262.919 185.096 262.919C183.698 262.919 182.564 264.053 182.564 265.451C182.564 266.85 183.698 267.983 185.096 267.983Z" />
    <path d="M195.225 267.983C196.624 267.983 197.757 266.85 197.757 265.451C197.757 264.053 196.624 262.919 195.225 262.919C193.827 262.919 192.693 264.053 192.693 265.451C192.693 266.85 193.827 267.983 195.225 267.983Z" />
    <path d="M205.352 267.983C206.751 267.983 207.884 266.85 207.884 265.451C207.884 264.053 206.751 262.919 205.352 262.919C203.954 262.919 202.82 264.053 202.82 265.451C202.82 266.85 203.954 267.983 205.352 267.983Z" />
    <path d="M215.48 267.983C216.879 267.983 218.012 266.85 218.012 265.451C218.012 264.053 216.879 262.919 215.48 262.919C214.082 262.919 212.948 264.053 212.948 265.451C212.948 266.85 214.082 267.983 215.48 267.983Z" />
    <path d="M225.608 267.983C227.007 267.983 228.14 266.85 228.14 265.451C228.14 264.053 227.007 262.919 225.608 262.919C224.21 262.919 223.076 264.053 223.076 265.451C223.076 266.85 224.21 267.983 225.608 267.983Z" />
    <path d="M235.736 207.216C237.134 207.216 238.268 206.082 238.268 204.684C238.268 203.285 237.134 202.152 235.736 202.152C234.338 202.152 233.204 203.285 233.204 204.684C233.204 206.082 234.338 207.216 235.736 207.216Z" />
    <path d="M235.736 217.344C237.134 217.344 238.268 216.21 238.268 214.812C238.268 213.413 237.134 212.28 235.736 212.28C234.338 212.28 233.204 213.413 233.204 214.812C233.204 216.21 234.338 217.344 235.736 217.344Z" />
    <path d="M235.736 227.472C237.134 227.472 238.268 226.338 238.268 224.94C238.268 223.541 237.134 222.408 235.736 222.408C234.338 222.408 233.204 223.541 233.204 224.94C233.204 226.338 234.338 227.472 235.736 227.472Z" />
    <path d="M235.736 237.6C237.134 237.6 238.268 236.466 238.268 235.068C238.268 233.669 237.134 232.536 235.736 232.536C234.338 232.536 233.204 233.669 233.204 235.068C233.204 236.466 234.338 237.6 235.736 237.6Z" />
    <path d="M235.736 247.728C237.134 247.728 238.268 246.594 238.268 245.196C238.268 243.797 237.134 242.664 235.736 242.664C234.338 242.664 233.204 243.797 233.204 245.196C233.204 246.594 234.338 247.728 235.736 247.728Z" />
    <path d="M235.736 257.854C237.134 257.854 238.268 256.721 238.268 255.322C238.268 253.924 237.134 252.791 235.736 252.791C234.338 252.791 233.204 253.924 233.204 255.322C233.204 256.721 234.338 257.854 235.736 257.854Z" />
    <path d="M235.736 267.983C237.134 267.983 238.268 266.85 238.268 265.451C238.268 264.053 237.134 262.919 235.736 262.919C234.338 262.919 233.204 264.053 233.204 265.451C233.204 266.85 234.338 267.983 235.736 267.983Z" />
    <path d="M235.736 278.11C237.134 278.11 238.268 276.977 238.268 275.578C238.268 274.18 237.134 273.046 235.736 273.046C234.338 273.046 233.204 274.18 233.204 275.578C233.204 276.977 234.338 278.11 235.736 278.11Z" />
    <path d="M235.736 288.239C237.134 288.239 238.268 287.106 238.268 285.707C238.268 284.309 237.134 283.175 235.736 283.175C234.338 283.175 233.204 284.309 233.204 285.707C233.204 287.106 234.338 288.239 235.736 288.239Z" />
    <path d="M235.736 298.366C237.134 298.366 238.268 297.233 238.268 295.834C238.268 294.436 237.134 293.302 235.736 293.302C234.338 293.302 233.204 294.436 233.204 295.834C233.204 297.233 234.338 298.366 235.736 298.366Z" />
    <path d="M245.864 207.216C247.262 207.216 248.396 206.082 248.396 204.684C248.396 203.285 247.262 202.152 245.864 202.152C244.466 202.152 243.332 203.285 243.332 204.684C243.332 206.082 244.466 207.216 245.864 207.216Z" />
    <path d="M245.864 217.344C247.262 217.344 248.396 216.21 248.396 214.812C248.396 213.413 247.262 212.28 245.864 212.28C244.466 212.28 243.332 213.413 243.332 214.812C243.332 216.21 244.466 217.344 245.864 217.344Z" />
    <path d="M245.864 227.472C247.262 227.472 248.396 226.338 248.396 224.94C248.396 223.541 247.262 222.408 245.864 222.408C244.466 222.408 243.332 223.541 243.332 224.94C243.332 226.338 244.466 227.472 245.864 227.472Z" />
    <path d="M245.864 237.6C247.262 237.6 248.396 236.466 248.396 235.068C248.396 233.669 247.262 232.536 245.864 232.536C244.466 232.536 243.332 233.669 243.332 235.068C243.332 236.466 244.466 237.6 245.864 237.6Z" />
    <path d="M245.864 247.728C247.262 247.728 248.396 246.594 248.396 245.196C248.396 243.797 247.262 242.664 245.864 242.664C244.466 242.664 243.332 243.797 243.332 245.196C243.332 246.594 244.466 247.728 245.864 247.728Z" />
    <path d="M245.864 257.854C247.262 257.854 248.396 256.721 248.396 255.322C248.396 253.924 247.262 252.791 245.864 252.791C244.466 252.791 243.332 253.924 243.332 255.322C243.332 256.721 244.466 257.854 245.864 257.854Z" />
    <path d="M245.864 267.983C247.262 267.983 248.396 266.85 248.396 265.451C248.396 264.053 247.262 262.919 245.864 262.919C244.466 262.919 243.332 264.053 243.332 265.451C243.332 266.85 244.466 267.983 245.864 267.983Z" />
    <path d="M245.864 278.11C247.262 278.11 248.396 276.977 248.396 275.578C248.396 274.18 247.262 273.046 245.864 273.046C244.466 273.046 243.332 274.18 243.332 275.578C243.332 276.977 244.466 278.11 245.864 278.11Z" />
    <path d="M245.864 288.239C247.262 288.239 248.396 287.106 248.396 285.707C248.396 284.309 247.262 283.175 245.864 283.175C244.466 283.175 243.332 284.309 243.332 285.707C243.332 287.106 244.466 288.239 245.864 288.239Z" />
    <path d="M245.864 298.366C247.262 298.366 248.396 297.233 248.396 295.834C248.396 294.436 247.262 293.302 245.864 293.302C244.466 293.302 243.332 294.436 243.332 295.834C243.332 297.233 244.466 298.366 245.864 298.366Z" />
    <path d="M255.992 207.216C257.39 207.216 258.524 206.082 258.524 204.684C258.524 203.285 257.39 202.152 255.992 202.152C254.594 202.152 253.46 203.285 253.46 204.684C253.46 206.082 254.594 207.216 255.992 207.216Z" />
    <path d="M255.992 217.344C257.39 217.344 258.524 216.21 258.524 214.812C258.524 213.413 257.39 212.28 255.992 212.28C254.594 212.28 253.46 213.413 253.46 214.812C253.46 216.21 254.594 217.344 255.992 217.344Z" />
    <path d="M255.992 227.472C257.39 227.472 258.524 226.338 258.524 224.94C258.524 223.541 257.39 222.408 255.992 222.408C254.594 222.408 253.46 223.541 253.46 224.94C253.46 226.338 254.594 227.472 255.992 227.472Z" />
    <path d="M255.992 237.6C257.39 237.6 258.524 236.466 258.524 235.068C258.524 233.669 257.39 232.536 255.992 232.536C254.594 232.536 253.46 233.669 253.46 235.068C253.46 236.466 254.594 237.6 255.992 237.6Z" />
    <path d="M255.992 247.728C257.39 247.728 258.524 246.594 258.524 245.196C258.524 243.797 257.39 242.664 255.992 242.664C254.594 242.664 253.46 243.797 253.46 245.196C253.46 246.594 254.594 247.728 255.992 247.728Z" />
    <path d="M255.992 257.854C257.39 257.854 258.524 256.721 258.524 255.322C258.524 253.924 257.39 252.791 255.992 252.791C254.594 252.791 253.46 253.924 253.46 255.322C253.46 256.721 254.594 257.854 255.992 257.854Z" />
    <path d="M255.992 267.983C257.39 267.983 258.524 266.85 258.524 265.451C258.524 264.053 257.39 262.919 255.992 262.919C254.594 262.919 253.46 264.053 253.46 265.451C253.46 266.85 254.594 267.983 255.992 267.983Z" />
    <path d="M255.992 278.11C257.39 278.11 258.524 276.977 258.524 275.578C258.524 274.18 257.39 273.046 255.992 273.046C254.594 273.046 253.46 274.18 253.46 275.578C253.46 276.977 254.594 278.11 255.992 278.11Z" />
    <path d="M255.992 288.239C257.39 288.239 258.524 287.106 258.524 285.707C258.524 284.309 257.39 283.175 255.992 283.175C254.594 283.175 253.46 284.309 253.46 285.707C253.46 287.106 254.594 288.239 255.992 288.239Z" />
    <path d="M255.992 298.366C257.39 298.366 258.524 297.233 258.524 295.834C258.524 294.436 257.39 293.302 255.992 293.302C254.594 293.302 253.46 294.436 253.46 295.834C253.46 297.233 254.594 298.366 255.992 298.366Z" />
    <path d="M266.12 267.983C267.518 267.983 268.652 266.85 268.652 265.451C268.652 264.053 267.518 262.919 266.12 262.919C264.721 262.919 263.588 264.053 263.588 265.451C263.588 266.85 264.721 267.983 266.12 267.983Z" />
    <path d="M276.247 267.983C277.645 267.983 278.779 266.85 278.779 265.451C278.779 264.053 277.645 262.919 276.247 262.919C274.848 262.919 273.715 264.053 273.715 265.451C273.715 266.85 274.848 267.983 276.247 267.983Z" />
    <path d="M286.376 267.983C287.774 267.983 288.908 266.85 288.908 265.451C288.908 264.053 287.774 262.919 286.376 262.919C284.977 262.919 283.844 264.053 283.844 265.451C283.844 266.85 284.977 267.983 286.376 267.983Z" />
    <path d="M296.503 267.983C297.901 267.983 299.035 266.85 299.035 265.451C299.035 264.053 297.901 262.919 296.503 262.919C295.104 262.919 293.971 264.053 293.971 265.451C293.971 266.85 295.104 267.983 296.503 267.983Z" />
    <path d="M306.632 267.983C308.03 267.983 309.164 266.85 309.164 265.451C309.164 264.053 308.03 262.919 306.632 262.919C305.233 262.919 304.1 264.053 304.1 265.451C304.1 266.85 305.233 267.983 306.632 267.983Z" />
    <path d="M316.759 267.983C318.157 267.983 319.29 266.85 319.29 265.451C319.29 264.053 318.157 262.919 316.759 262.919C315.36 262.919 314.227 264.053 314.227 265.451C314.227 266.85 315.36 267.983 316.759 267.983Z" />
    <path
      d="M316.759 278.11C318.157 278.11 319.29 276.977 319.29 275.578C319.29 274.18 318.157 273.046 316.759 273.046C315.36 273.046 314.227 274.18 314.227 275.578C314.227 276.977 315.36 278.11 316.759 278.11Z"
      fillOpacity={0.3}
    />
  </svg>
);
