export const PurpleMapIcon = () => (
  <svg
    width="84"
    height="64"
    viewBox="0 0 84 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2325 56.7822L41.6319 62.6784L62.1701 56.7822L82.6323 62.6784V6.89598L62.1701 1L41.6319 6.89598L21.2325 1L1 6.89598V62.6784L21.2325 56.7822Z"
      fill="#EADFF8"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4077 51.1745L41.6314 57.0704L62.2237 51.1745L77.8096 55.6772V11.2106L62.2237 6.70789L41.6314 12.6039L21.4077 6.70789L6.09229 11.1326V55.5992L21.4077 51.1745Z"
      fill="#8D75E6"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M1 62.6784L26.5158 58.3094L21.2325 56.7822L1 62.6784Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.3074 39.1883L45.8189 29.2964"
      stroke="#EADFF8"
      strokeMiterlimit="10"
      strokeDasharray="2.46 2.46"
    />
    <path
      d="M41.8164 62.6784L67.3322 58.3094L62.0488 56.7822L41.8164 62.6784Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1453 20.5459C15.7212 20.5459 16.9988 19.2683 16.9988 17.6924C16.9988 16.1164 15.7212 14.8389 14.1453 14.8389C12.5693 14.8389 11.2917 16.1164 11.2917 17.6924C11.2917 19.2683 12.5693 20.5459 14.1453 20.5459Z"
      fill="#EADFF8"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M31.8018 33.8623C33.3777 33.8623 34.6553 32.5847 34.6553 31.0088C34.6553 29.4328 33.3777 28.1553 31.8018 28.1553C30.2258 28.1553 28.9482 29.4328 28.9482 31.0088C28.9482 32.5847 30.2258 33.8623 31.8018 33.8623Z"
      fill="#EADFF8"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M14.1453 47.1785C15.7212 47.1785 16.9988 45.9009 16.9988 44.3249C16.9988 42.749 15.7212 41.4714 14.1453 41.4714C12.5693 41.4714 11.2917 42.749 11.2917 44.3249C11.2917 45.9009 12.5693 47.1785 14.1453 47.1785Z"
      fill="#EADFF8"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M14.1453 33.8623C15.7212 33.8623 16.9988 32.5847 16.9988 31.0088C16.9988 29.4328 15.7212 28.1553 14.1453 28.1553C12.5693 28.1553 11.2917 29.4328 11.2917 31.0088C11.2917 32.5847 12.5693 33.8623 14.1453 33.8623Z"
      fill="#8D75E6"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M69.5027 27.3935C71.0786 27.3935 72.3562 26.116 72.3562 24.54C72.3562 22.9641 71.0786 21.6865 69.5027 21.6865C67.9267 21.6865 66.6492 22.9641 66.6492 24.54C66.6492 26.116 67.9267 27.3935 69.5027 27.3935Z"
      fill="#8D75E6"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M69.5027 40.3301C71.0786 40.3301 72.3562 39.0525 72.3562 37.4766C72.3562 35.9006 71.0786 34.623 69.5027 34.623C67.9267 34.623 66.6492 35.9006 66.6492 37.4766C66.6492 39.0525 67.9267 40.3301 69.5027 40.3301Z"
      fill="#8D75E6"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M50.4477 40.3301C52.0237 40.3301 53.3013 39.0525 53.3013 37.4766C53.3013 35.9006 52.0237 34.623 50.4477 34.623C48.8718 34.623 47.5942 35.9006 47.5942 37.4766C47.5942 39.0525 48.8718 40.3301 50.4477 40.3301Z"
      fill="#8D75E6"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M21.2329 56.7822V1"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.073 16.6084C28.4379 16.6084 31.9762 20.1467 31.9762 24.5114"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path d="M63.9802 37.4814H57.1167" stroke="#EADFF8" strokeMiterlimit="10" />
    <path
      d="M62.1702 56.7822V1"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.6257 27.3625L53.2692 21.719"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M53.2692 27.3625L47.6257 21.719"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M28.2227 47.1459L33.8661 41.5024"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path
      d="M33.8661 47.1459L28.2227 41.5024"
      stroke="#EADFF8"
      strokeMiterlimit="10"
    />
    <path d="M29.4238 24.4771H34.1797" stroke="#EADFF8" strokeMiterlimit="10" />
    <path
      d="M41.6326 62.6784V6.896"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M57.1167 35.4819V39.4816" stroke="#EADFF8" strokeMiterlimit="10" />
  </svg>
);
