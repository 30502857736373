import { CSSProperties, styled } from "@linaria/react";
import { useSiteContext } from "../../context/site.context";
import { zIndex } from "../../styles/zIndexes.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import DisablePageScroll from "../utilities/DisablePageScroll";
import { cover } from "polished";
import {
  getBrandColorThemeC4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import StoryEmbed, { StoryEmbedWrapper } from "../blocks/StoryEmbed.block";
import {
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useHotkeys } from "react-hotkeys-hook";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { colors } from "../../styles/colors.styles";
import { getUrlQueryParams } from "../../utils/urlQueryParams.utils";
import { useState } from "react";
import { isCommunityStory } from "../storyboard/utils/story.utils";
import { cx } from "linaria";
import { reportStoryView } from "../../utils/library.utils";
import { AdminToolbarStoryPageOptions } from "../site/AdminToolbar";

const StoryLightboxContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: grid;
  padding: 1em;
  z-index: ${zIndex("Modal")};
  overflow: auto;
  ${fromTablet} {
    padding: 1.75em;
  }
  ${fromDesktopMd} {
    padding: 2em;
  }
`;

const ModalBackdrop = styled.div`
  ${cover()};
  position: fixed;
  background-color: var(--ac70010, ${withOpacity(colors.black, 0.1)});
  backdrop-filter: blur(0.125em);
  .community & {
    background-color: ${withOpacity(colors.black, 0.1)};
  }
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  ${StoryEmbedWrapper} {
    background-color: ${colors.white};
    border-radius: ${rSize("radius")};
    box-shadow: 0 1em 8em rgba(0, 0, 0, 0.1);
    [data-color-scheme="dark"] & {
      background-color: ${colors.darkest};
    }
    @media (prefers-color-scheme: dark) {
      [data-color-scheme="auto"] & {
        background-color: ${colors.darkest};
      }
    }
  }
`;

const StoryLightbox = () => {
  const siteContext = useSiteContext();
  const story = siteContext.library.storyToRenderInLightbox;
  useHotkeys("escape", siteContext.library.closeStoryLightbox);

  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmSource, setUtmSource] = useState("");

  useOnMount(() => {
    if (!story) return () => {};
    const { href } = window.location;
    const redirectedFrom = href.replace(window.location.origin, "");
    window.history.pushState(
      "",
      "",
      `/library/stories/${story.id}/?name=${
        story.slug
      }&redirected-from=${encodeURI(redirectedFrom)}`
    );
    const params = getUrlQueryParams();
    if (params.utm_campaign) setUtmCampaign(params.utm_campaign);
    if (params.utm_medium) setUtmMedium(params.utm_medium);
    if (params.utm_source) setUtmSource(params.utm_source);

    reportStoryView(story);

    const handleBrowserBackButtonClick = () => {
      siteContext.library.closeStoryLightbox();
    };
    window.addEventListener("popstate", handleBrowserBackButtonClick);
    return () => {
      window.history.pushState("", "", href);
      window.removeEventListener("popstate", handleBrowserBackButtonClick);
    };
  });

  const theme = getBrandColorThemeC4(story?.color);
  const isCommunity = isCommunityStory(story);
  return story ? (
    <StoryLightboxContainer
      className={cx(isCommunity && "community")}
      style={
        {
          // "--ac30": withOpacity(theme.primary, 0.3),
          "--ac70010": withOpacity(theme.dark, 0.1),
        } as CSSProperties
      }
    >
      <DisablePageScroll />
      <AdminToolbarStoryPageOptions
        storyId={story.id}
        datoCmsRecordId={story.datoCmsRecordId}
      />
      <ModalBackdrop onClick={siteContext.library.closeStoryLightbox} />
      <Inner>
        <StoryEmbed
          descriptor={story}
          fullWidth
          asLightbox
          utmCampaign={utmCampaign}
          utmMedium={utmMedium}
          utmSource={utmSource}
        />
      </Inner>
    </StoryLightboxContainer>
  ) : null;
};

export default StoryLightbox;
