import { CSSProperties, styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import Spacing from "../layout/Spacing";
import { font } from "../../styles/fonts.styles";
import { cx } from "linaria";
import {
  fromTabletLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  DatoCmsCompetitionCategory,
  DatoCmsYdwwt,
} from "../../../graphql-types";
import { StructuredTextWithCustomBlocks } from "../articles/StructuredTextWithCustomBlocks";
import { fluidFontSize } from "../../styles/helpers.styles";

const SectionInner = styled.div`
  header {
    text-align: center;
    max-width: 45em;
    margin-left: auto;
    margin-right: auto;
    > * {
      + * {
        margin-top: 1em;
      }
    }
    h2 {
      ${fluidFontSize(32, 46)};
      line-height: 1.1;
      letter-spacing: -0.01em;
      font-family: ${font("serif")};
      font-weight: 400;
      text-align: center;
      + * {
        margin-top: ${rSize("md")};
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  ${fromTabletLg} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto;
  }
  grid-gap: ${rSize("gap")};
`;

const Card = styled.div`
  position: relative;
  background-color: ${colors.dark700};
  border-radius: ${rSize("radius")};
  line-height: 1.375;
  font-size: 1.6rem;
  counter-increment: category;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  &.wide {
    ${fromTabletLg} {
      grid-column-end: span 2;
    }
    p {
      max-width: 40em;
    }
  }
  p,
  li {
    color: ${withOpacity(colors.white, 0.7)};
  }
  a {
    text-decoration: underline;
    text-decoration-color: ${withOpacity(colors.white, 0.15)};
    color: ${colors.white};
    font-weight: 500;
    &:hover {
      text-decoration-color: var(--ac);
      color: var(--ac);
    }
  }
`;

const CardTextContent = styled.div`
  padding: ${rSize("lg")};
  .wide & {
    ${fromTabletLg} {
      padding-right: ${rSize("lg")};
    }
  }
  h3 {
    font-size: 2.8rem;
    font-family: ${font("serif")};
    font-weight: 400;
    line-height: 1;
    &:before {
      content: counter(category);
      font-family: ${font("sans")};
      font-weight: 600;
      background-color: var(--ac);
      font-size: 75%;
      width: 1.5em;
      height: 1.5em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 0.375em;
      transform: translateY(-4%) rotate(5deg);
      border: 3px solid white;
      ${Card}:nth-of-type(2n + 1) & {
        transform: translateY(-4%) rotate(-2deg);
      }
      ${Card}:nth-of-type(3n + 1) & {
        transform: translateY(-4%) rotate(-5deg);
      }
      ${onlyPhones} {
        display: flex;
        margin-bottom: 0.375em;
      }
    }
    + * {
      margin-top: 1em;
    }
  }
  p {
    ${onlyPhones} {
      font-size: 1.4rem;
    }
  }
`;

const CardIcon = styled.img`
  position: relative;
  max-width: 30vw;
`;

const YDWWTCategoriesSection = (props: { ydwwt: DatoCmsYdwwt }) => {
  const categories = props.ydwwt.categories ?? [];
  const hasOddNumber = categories.length % 2 !== 0;
  return (
    <PageSection>
      <SectionInner>
        <header>
          <StructuredTextWithCustomBlocks
            value={props.ydwwt.examplesSectionCopy}
            linkedHeadingMaxLevel="off"
          />
        </header>

        <Spacing size="sectionPadding" />

        <Grid>
          {props.ydwwt.categories?.map((c, i) => {
            const cat = c as DatoCmsCompetitionCategory;
            return (
              <Card
                key={cat.id}
                className={cx(
                  hasOddNumber && i === categories.length - 1 && "wide"
                )}
                style={
                  {
                    "--ac":
                      colors[
                        (
                          cat.color ?? "purple"
                        ).toLowerCase() as keyof typeof colors
                      ],
                  } as CSSProperties
                }
              >
                <CardTextContent>
                  <h3>{cat.name}</h3>
                  <StructuredTextWithCustomBlocks
                    value={cat.description}
                    linkedHeadingMaxLevel="off"
                  />
                </CardTextContent>
                {cat.icon?.url && <CardIcon src={cat.icon.url} />}
              </Card>
            );
          })}
        </Grid>
      </SectionInner>
    </PageSection>
  );
};

export default YDWWTCategoriesSection;
