import { styled } from "@linaria/react";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { ValidIframeEmbed } from "../../utils/datocms.utils";
import GenericIframe from "../utilities/GenericIframe";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { valueWithOptionalUnit } from "../../utils/css.utils";

type Props = {
  record?: ValidIframeEmbed;
  src?: string | null;
  title?: string | null;
} & IframeProps;

type IframeProps = {
  backgroundColor?: string;
  aspectRatio?: string | null;
  aspectRatioMobile?: string | null;
  height?: string | number | null;
  heightMobile?: string | number | null;
};

export const IframeEmbedWrapper = styled.div<IframeProps>`
  position: relative;
  background-color: ${p => p.backgroundColor ?? colorsV4.warmBlack};
  border-radius: 1em;
  overflow: hidden;
  transform: translateZ(0);
  iframe {
    display: block;
    width: 100%;
    height: ${p => (p.height ? valueWithOptionalUnit(p.height) : "100%")};
    aspect-ratio: ${p => p.aspectRatioMobile || p.aspectRatio || "16 / 9"};
    ${fromTabletMd} {
      aspect-ratio: ${p => p.aspectRatio || "16 / 9"};
    }
  }
`;

const IframeEmbed = (p: Props) => {
  return (
    <IframeEmbedWrapper
      backgroundColor={p.backgroundColor}
      aspectRatio={p.aspectRatio ?? p.record?.aspectRatio}
      aspectRatioMobile={
        p.aspectRatioMobile ??
        p.aspectRatio ??
        p.record?.aspectRatioMobile ??
        p.record?.aspectRatio
      }
      height={p.height ?? p.record?.height}
      heightMobile={
        p.heightMobile ?? p.height ?? p.record?.heightMobile ?? p.record?.height
      }
    >
      <GenericIframe
        src={p.record?.src ?? p.src}
        title={p.record?.title ?? p.title}
        width="100%"
        height="100%"
      />
    </IframeEmbedWrapper>
  );
};

export default IframeEmbed;
