import { styled } from "@linaria/react";
import { colorsV4 } from "../../styles/colorsV4.styles";

export const RatingStarsContainer = styled.div`
  > * {
    margin-right: 0.2em;
  }
`;
const RatingStars = (props: { rating: number; color: string }) => {
  return (
    <RatingStarsContainer>
      {Array(Math.floor(props.rating))
        .fill(null)
        .map((n, i) => i)
        .map(i => (
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            key={i}
            fill={props.color ?? colorsV4.purple}
          >
            <path d="M7.21592 0.702928C7.67847 -0.234308 9.01494 -0.23431 9.4775 0.702927L11.0799 3.9497C11.2636 4.32188 11.6186 4.57984 12.0293 4.63952L15.6124 5.16017C16.6467 5.31046 17.0597 6.58152 16.3112 7.31106L13.7185 9.83832C13.4213 10.128 13.2857 10.5454 13.3559 10.9545L13.9679 14.523C14.1446 15.5531 13.0634 16.3387 12.1383 15.8523L8.93351 14.1675C8.56614 13.9744 8.12727 13.9744 7.75991 14.1675L4.55515 15.8523C3.63005 16.3387 2.54882 15.5532 2.7255 14.523L3.33755 10.9545C3.40771 10.5454 3.27209 10.128 2.97489 9.83832L0.382187 7.31106C-0.366241 6.58152 0.0467497 5.31046 1.08105 5.16017L4.66408 4.63952C5.0748 4.57984 5.42986 4.32188 5.61354 3.9497L7.21592 0.702928Z" />
          </svg>
        ))}
      {Math.floor(props.rating) !== props.rating && (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill={props.color ?? colorsV4.purple}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.9896 0.702928C8.45215 -0.234308 9.78862 -0.23431 10.2512 0.702927L11.8536 3.9497C12.0372 4.32188 12.3923 4.57984 12.803 4.63952L16.386 5.16017C17.4203 5.31046 17.8333 6.58152 17.0849 7.31106L14.4922 9.83832C14.195 10.128 14.0594 10.5454 14.1295 10.9545L14.7416 14.523C14.9183 15.5531 13.8371 16.3387 12.9119 15.8523L9.70719 14.1675C9.33983 13.9744 8.90095 13.9744 8.53359 14.1675L5.32883 15.8523C4.40373 16.3387 3.3225 15.5532 3.49918 14.523L4.11123 10.9545C4.18139 10.5454 4.04577 10.128 3.74857 9.83832L1.15587 7.31106C0.407441 6.58152 0.820431 5.31046 1.85473 5.16017L5.43776 4.63952C5.84849 4.57984 6.20354 4.32188 6.38722 3.9497L7.9896 0.702928ZM9.12039 2.9308L10.0601 4.83483C10.5351 5.7973 11.4533 6.4644 12.5154 6.61874L14.6166 6.92407L13.0962 8.40615C12.3276 9.15532 11.9769 10.2347 12.1583 11.2926L12.5173 13.3853L10.6379 12.3972C10.1629 12.1475 9.64161 12.0227 9.12036 12.0227L9.12039 2.9308Z"
          />
        </svg>
      )}
    </RatingStarsContainer>
  );
};

export default RatingStars;
