import { styled } from "@linaria/react";
import { cx } from "linaria";
import { CSSProperties, ReactNode } from "react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromTablet,
  fromTabletLg,
  onlyPhones,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { BrandColorNameV4, colorV4 } from "../../styles/colorsV4.styles";
import { fluidFontSize, fluidLineHeight } from "../../styles/helpers.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { isNotNil } from "../../utils/typeChecks.utils";
import SiteNavHeightPlaceholder from "../site/SiteNavHeightPlaceholder";
import { colors } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";

type Props = React.PropsWithChildren<{
  className?: string;
  pageTitle?: ReactNode;
  backgroundColor?: string | BrandColorNameV4;
  textColor?: string | BrandColorNameV4;
  endSlot?: ReactNode;
  centered?: boolean;
  centeredOnMobile?: boolean;
  beforeTitle?: ReactNode;
  afterTitle?: ReactNode;
  alignItems?: CSSProperties["alignItems"];
  largerPadding?: boolean;
}>;

const StandardPageHeaderContainer = styled.header<Props>`
  background-color: ${p =>
    p.backgroundColor ? colorV4(p.backgroundColor) : colors.light100};
  color: ${p => (p.textColor ? colorV4(p.textColor) : "")};
  border-radius: 0 0 1em 1em;
  ${fromTablet} {
    border-radius: 0 0 2em 2em;
  }
`;

export const StandardPageHeaderTitleGroup = styled.div`
  h1 {
    ${fluidFontSize(38, 52)};
    ${fluidLineHeight(42, 58)};
    font-family: ${font("serif")};
    max-width: 16em;
    font-weight: 400;
    text-wrap: balance; // supported in Chrome but not in safari
    ${fromTablet} {
      letter-spacing: -0.02rem;
    }
    ${fromDesktop} {
      letter-spacing: -0.03rem;
    }
  }
  &.centered {
    text-align: center;
    max-width: ${widthInGrid(8)};
    margin-left: auto;
    margin-right: auto;
  }
  &.centeredOnMobile {
    ${uptoTablet} {
      text-align: center;
      max-width: ${widthInGrid(8)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const StandardPageHeaderInner = styled.div<Props>`
  ${maxPageContentWidthStyleObject};
  padding-top: ${rSize("sectionPadding")};
  padding-bottom: ${rSize("sectionPadding")};
  ${fromTabletLg} {
    padding-top: calc(${rSize("sectionPadding")} - 2em);
    padding-bottom: calc(${rSize("sectionPadding")} - 2em);
    &.largerPadding {
      padding-top: ${rSize("sectionPadding")};
      padding-bottom: ${rSize("sectionPadding")};
    }
  }
  .hasSlotEnd & {
    display: flex;
    flex-direction: column;
    ${onlyPhones} {
      > * {
        + * {
          margin-top: ${rSize("xl")};
        }
      }
    }
    ${fromTablet} {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: ${p => p.alignItems ?? "center"};
      ${StandardPageHeaderTitleGroup} {
        flex: 1 0 auto;
        padding-right: ${rSize("sectionPadding")};
      }
    }
  }
`;

export const StandardPageHeaderSlotEnd = styled.div`
  ${fromTablet} {
    margin-top: 2em;
    margin-bottom: 2em;
  }
`;

const ChildrenContainer = styled.div`
  * + & {
    margin-top: 1em;
  }
`;

const StandardPageHeader = (props: Props) => {
  return (
    <StandardPageHeaderContainer
      {...props}
      className={cx(
        "StandardPageHeader",
        props.className,
        isNotNil(props.endSlot) && "hasSlotEnd"
      )}
    >
      <SiteNavHeightPlaceholder withSpacingTop />
      <StandardPageHeaderInner
        {...props}
        className={cx(props.largerPadding && "largerPadding")}
      >
        <StandardPageHeaderTitleGroup
          className={cx(
            props.centered && "centered",
            props.centeredOnMobile && "centeredOnMobile"
          )}
        >
          {props.beforeTitle}
          {props.pageTitle && <h1>{props.pageTitle}</h1>}
          {isNotNil(props.children) && (
            <ChildrenContainer>{props.children}</ChildrenContainer>
          )}
          {props.afterTitle}
        </StandardPageHeaderTitleGroup>
        {props.endSlot && (
          <StandardPageHeaderSlotEnd>{props.endSlot}</StandardPageHeaderSlotEnd>
        )}
      </StandardPageHeaderInner>
    </StandardPageHeaderContainer>
  );
};

export default StandardPageHeader;
