export const PageIconPlatform = () => (
  <svg width="117" height="84" viewBox="0 0 117 84" fill="none">
    <path
      d="M14.0218 80.7876C12.5641 80.7876 11.3824 79.6059 11.3824 78.1482C11.3824 76.6905 12.5641 75.5088 14.0218 75.5088C15.4795 75.5088 16.6611 76.6905 16.6611 78.1482C16.6611 79.6059 15.4795 80.7876 14.0218 80.7876Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M32.9393 72.4253C38.2544 72.4253 42.5632 68.1165 42.5632 62.8014C42.5632 57.4862 38.2544 53.1775 32.9393 53.1775C27.6242 53.1775 23.3154 57.4862 23.3154 62.8014C23.3154 68.1165 27.6242 72.4253 32.9393 72.4253Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M113.748 9.96875H39.5284L1.3291 61.8138L2.66675 65.327H76.8868L115.987 12.4675L113.748 9.96875Z"
      fill="#A88FEF"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M75.5461 61.8138H1.3291L39.5284 9.96875H113.748L75.5461 61.8138Z"
      fill="#C1AAF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M76.8865 65.327L75.5459 61.8138"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M35.1568 47.9022C37.7957 45.1068 38.8301 41.7977 37.4672 40.5112C36.1044 39.2246 32.8603 40.4478 30.2215 43.2432C27.5826 46.0386 26.5482 49.3477 27.911 50.6342C29.2739 51.9208 32.5179 50.6976 35.1568 47.9022Z"
      fill="#483C72"
    />
    <path
      d="M91.2685 21.4054C93.3073 19.2458 94.1065 16.6894 93.0537 15.6956C92.0009 14.7017 89.4948 15.6468 87.4561 17.8065C85.4174 19.9661 84.6181 22.5225 85.6709 23.5163C86.7237 24.5102 89.2298 23.5651 91.2685 21.4054Z"
      fill="#483C72"
    />
    <path
      d="M39.1905 38.6908L37.5596 40.9801L37.5327 43.793L34.8663 44.6848L33.1935 46.9471L30.5122 46.1002L27.828 46.9471L26.1552 44.6848L23.4888 43.793L23.4649 40.9801L21.834 38.6908L23.4649 36.4016L23.4888 33.5886L26.1581 32.6968L27.828 30.4345L30.5122 31.2814L33.1935 30.4345L34.8663 32.6968L37.5327 33.5886L37.5596 36.4016L39.1905 38.6908Z"
      fill="#88D0A3"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M86.7264 3.81916L89.9164 1.67054L90.5688 5.46204L94.4142 5.5997L92.7114 9.04706L95.7428 11.4201L92.3374 13.2126L93.3967 16.9113L89.5873 16.3607L88.2706 19.9727L85.5145 17.2884L82.3215 19.437L81.6691 15.6455L77.8267 15.5079L79.5265 12.0575L76.498 9.68745L79.9005 7.89494L78.8442 4.19621L82.6506 4.74683L83.9703 1.13489L86.7264 3.81916Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M63.1543 61.739H67.1044L76.6355 48.6558H72.8979L63.1543 61.739Z"
      fill="#483C72"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M89.8383 33.1158H87.0074L73.9751 33.5198L65.6679 20.1792H62.837L63.3277 35.9886L49.9004 44.3496H52.7313L65.7636 43.9486L71.0394 52.4174H73.8703L76.6354 48.6558L76.411 41.4768L89.8383 33.1158Z"
      fill="#FA9589"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M68.5408 43.8618L73.8705 52.4174L76.6356 48.6558L76.4111 41.4768L89.8385 33.1158L74.0291 33.6066L65.668 20.1792L66.1558 35.9886L52.7314 44.3496L68.5408 43.8618Z"
      fill="#FFB6AB"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M109.472 63.8547C108.317 63.8547 107.243 64.1899 106.336 64.7674C105.429 64.1899 104.355 63.8547 103.2 63.8547C102.045 63.8547 100.97 64.1899 100.064 64.7674C99.1568 64.1899 98.0825 63.8547 96.9274 63.8547C93.6985 63.8547 91.0801 66.4732 91.0801 69.7021C91.0801 72.931 93.6985 75.5494 96.9274 75.5494C98.0825 75.5494 99.1568 75.2143 100.064 74.6367C100.97 75.2143 102.048 75.5494 103.2 75.5494C104.352 75.5494 105.429 75.2143 106.336 74.6367C107.243 75.2143 108.32 75.5494 109.472 75.5494C112.701 75.5494 115.319 72.931 115.319 69.7021C115.319 66.4732 112.701 63.8547 109.472 63.8547Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M65.0153 75.8576C63.6597 75.8576 62.4747 76.5758 61.8103 77.6501C61.146 76.5758 59.961 75.8576 58.6054 75.8576C57.2498 75.8576 56.0647 76.5758 55.4004 77.6501C54.7361 76.5758 53.551 75.8576 52.1924 75.8576C50.1096 75.8576 48.4219 77.5454 48.4219 79.6312C48.4219 81.7169 50.1096 83.4047 52.1924 83.4047C53.548 83.4047 54.7331 82.6865 55.4004 81.6122C56.0647 82.6865 57.2498 83.4047 58.6054 83.4047C59.961 83.4047 61.146 82.6865 61.8103 81.6122C62.4747 82.6865 63.6597 83.4047 65.0153 83.4047C67.0981 83.4047 68.7888 81.7169 68.7888 79.6312C68.7888 77.5484 67.1011 75.8576 65.0153 75.8576Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
  </svg>
);
