export const CasesPageThunderboltIcon = () => (
  <svg
    width="47"
    height="79"
    viewBox="0 0 47 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.4844 44.0626L8.03955 38.0524L27.6888 31.2339L46.1339 37.2443L26.4844 44.0626Z"
      fill="#D67C56"
      stroke="#302C54"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.4447 46.6508L1 40.6406L20.6491 33.8223L39.094 39.8325L19.4447 46.6508Z"
      fill="#FFE1B3"
      stroke="#302C54"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.1343 37.2446L26.4849 44.0629L26.4066 44.2352L19.4451 46.6509L5.34912 77.7287L12.3887 75.1405L46.1343 37.2446Z"
      fill="#D67C56"
      stroke="#302C54"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.5672 0.84375L33.5276 3.43196L1 40.6404L20.6491 33.8221L20.7211 33.6516L27.6889 31.2339L40.5672 0.84375Z"
      fill="#D67C56"
      stroke="#302C54"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1 40.6406L20.6491 33.8223L33.5276 3.43213L1 40.6406Z"
      fill="#FFD18C"
      stroke="#302C54"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.4451 46.6503L39.0943 39.832L5.34912 77.728L19.4451 46.6503Z"
      fill="#FFD18C"
      stroke="#302C54"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.1924 33.8472L26.3916 35.7629L33.2329 33.0264L27.6888 31.2339L21.1924 33.8472Z"
      fill="#302C54"
    />
    <path
      d="M13.0171 74.833L5.17871 78.2346C7.14668 78.3514 9.3584 78.4174 11.6977 78.4174C19.8871 78.4174 26.5259 77.6133 26.5259 76.6215C26.5261 75.6835 20.588 74.914 13.0171 74.833Z"
      fill="#302C54"
    />
  </svg>
);
