import col1Img from "./images/tines-reporting-dashboard-records-illustration-col-1.png";
import col2Img from "./images/tines-reporting-dashboard-records-illustration-col-2.png";
import col3Img from "./images/tines-reporting-dashboard-records-illustration-col-3.png";
import col1Img2x from "./images/tines-reporting-dashboard-records-illustration-col-1@2x.png";
import col2Img2x from "./images/tines-reporting-dashboard-records-illustration-col-2@2x.png";
import col3Img2x from "./images/tines-reporting-dashboard-records-illustration-col-3@2x.png";
import { makeSrcSet2x } from "../../../utils/srcset.utils";
import { styled } from "@linaria/react";
import { useRef } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import gsap from "gsap";

const Wrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Inner = styled.div`
  display: inline-flex;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  > * {
    width: 236px;
    + * {
      margin-left: 24px;
    }
  }
`;

const HomeEnterpriseCardIllustration = () => {
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const tweens = [] as ReturnType<typeof gsap["fromTo"]>[];
    ref.current?.childNodes.forEach((node, i) => {
      const direction = i === 1 ? 1 : -1;
      tweens.push(
        gsap.fromTo(
          node,
          {
            y: direction * 25,
          },
          {
            y: direction * -25,
            scrollTrigger: {
              trigger: ref.current,
              scrub: 1,
              start: "top bottom",
              end: "bottom top",
              invalidateOnRefresh: true,
            },
          }
        )
      );
    });
    return () => {
      tweens.forEach(t => {
        t.kill();
      });
    };
  });
  return (
    <Wrap>
      <Inner ref={ref}>
        <img src={col1Img} srcSet={makeSrcSet2x(col1Img, col1Img2x)} />
        <img src={col2Img} srcSet={makeSrcSet2x(col2Img, col2Img2x)} />
        <img src={col3Img} srcSet={makeSrcSet2x(col3Img, col3Img2x)} />
      </Inner>
    </Wrap>
  );
};

export default HomeEnterpriseCardIllustration;
