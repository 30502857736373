import { styled } from "@linaria/react";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { StoryDescriptor } from "../../types/helper.types";
import StoryEntry, {
  StoryEntryDescription,
  StoryEntryWrapper,
} from "../library/StoryEntry";
import { colors, withOpacity } from "../../styles/colors.styles";
import {
  fromDesktop,
  fromPhoneLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import RigidDotGrid from "../decorations/RigidDotGrid";
import Button from "../forms/Button";
import { StoryIconArrayWrap } from "../library/StoryIconArray";
import { rSize } from "../../styles/responsiveSizes.styles";

const BuildAppsStoryGridSectionContainer = styled.section`
  overflow: hidden;
  ${StoryEntryWrapper} {
    background-color: ${colors.white};
    &:hover {
      border-color: var(--ac50);
    }
  }
`;

const Inner = styled.div`
  ${maxPageContentWidthStyleObject};
`;

const CardGrid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: ${rSize("gap")};
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromDesktop} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${StoryEntryWrapper} {
    ${onlyPhones} {
      text-align: left;
      h3 {
        font-size: 2rem;
      }
    }
  }
  ${StoryEntryDescription} {
    ${onlyPhones} {
      -webkit-line-clamp: 2;
    }
  }
  ${StoryIconArrayWrap} {
    ${onlyPhones} {
      transform: scale(0.85);
    }
  }
`;

const BuildAppsStoryGridSection = (props: { stories: StoryDescriptor[] }) => {
  return (
    <BuildAppsStoryGridSectionContainer>
      <Inner>
        <CardGrid>
          <HeaderSlide />
          {props.stories.map(story => (
            <StoryEntry key={story.id} story={story} withDescription />
          ))}
        </CardGrid>
      </Inner>
    </BuildAppsStoryGridSectionContainer>
  );
};

const HeaderSlideWrapper = styled.div`
  background-color: ${colors.dark500};
  color: ${colors.white};
  padding: 1.5em;
  width: 100%;
  border-radius: ${rSize("radius")};
  display: flex;
  align-items: stretch;
  position: relative;
  ${fromDesktop} {
    font-size: 1.6rem;
    padding: 2em;
    min-height: 400px;
  }
  h2 {
    font-size: 2.8rem;
    ${fromDesktop} {
      font-size: 3.8rem;
    }
    max-width: 6em;
    letter-spacing: -0.02em;
    line-height: 1.1;
  }
`;

const HeaderSlideInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  header {
    + * {
      margin-top: 1em;
    }
  }
  footer {
    font-weight: 500;
    * + * {
      margin-top: 1em;
    }
  }
`;

const HeaderSlide = () => (
  <HeaderSlideWrapper>
    <RigidDotGrid cellSize={32} color={withOpacity(colors.light100, 0.2)} />
    <HeaderSlideInner>
      <header>
        <h2>What will you build with Tines?</h2>
      </header>
      <footer>
        <p>
          Extend your team’s visibility, impact, and influence, without
          friction.
        </p>
        <Button to="/library?tag=Pages">Explore our Library</Button>
      </footer>
    </HeaderSlideInner>
  </HeaderSlideWrapper>
);
export default BuildAppsStoryGridSection;
