import { css, cx } from "linaria";
import { ReactElement, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSiteContext } from "../../context/site.context";
import {
  darkModeLinariaCSS,
  getSystemColorScheme,
  lightModeLinariaCSS,
} from "../../utils/colorScheme.utils";
import MoonIcon from "../icons/misc/MoonIcon";
import { ProductIconButton } from "../icons/misc/ProductIconButtonOrLink";
import SunIcon from "../icons/misc/SunIcon";

type Props = {
  children?: (toggleFn: Function) => ReactElement;
  invisible?: boolean;
};

const ColorSchemeToggle = (props: Props) => {
  const siteContext = useSiteContext();
  const toggle = () => {
    const { colorScheme, setColorScheme } = siteContext;
    if (colorScheme === "auto") {
      const system = getSystemColorScheme();
      setColorScheme(system === "light" ? "dark" : "light");
    } else {
      setColorScheme(colorScheme === "light" ? "dark" : "light");
    }
  };
  const toggleFnRef = useRef(toggle);
  toggleFnRef.current = toggle;
  useHotkeys("cmd+shift+K, ctrl+shift+k", e => {
    e.preventDefault();
    toggleFnRef.current();
  });
  return props.children ? (
    props.children(toggle)
  ) : (
    <ProductIconButton
      onClick={toggle}
      className={cx(
        css`
          transition: transform 0.2s;
          &:active {
            transform: scale(0.9);
            transition: transform 0.05s;
          }
          .SunIcon {
            display: block;
          }
          .MoonIcon {
            display: none;
          }
          ${darkModeLinariaCSS(`
          .SunIcon { display: none; }
          .MoonIcon { display: block; }
        `)}
          ${lightModeLinariaCSS(`
          .SunIcon { display: block; }
          .MoonIcon { display: none; }
        `)}
        `,
        props.invisible && "invisible"
      )}
    >
      <MoonIcon />
      <SunIcon />
    </ProductIconButton>
  );
};

export default ColorSchemeToggle;
