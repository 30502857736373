import { styled } from "@linaria/react";
import { BrandColorNameV4, withOpacity } from "../../styles/colorsV4.styles";
import { StoryDescriptor } from "../../types/helper.types";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { colors } from "../../styles/colors.styles";
import { fromTabletLg } from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  story: StoryDescriptor;
  white?: boolean;
};

export const StoryIconArrayWrap = styled.div<{ color?: BrandColorNameV4 }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${withOpacity(colors.lightest, 0.5)};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.darkest, 0.5)}`
    )}
    .community & {
      background-color: var(--ac50, ${colors.lightest});
      ${darkModeLinariaCSS(`background-color: var(--ac900, ${colors.darkest})`)}
    }
    border-radius: 50%;
    width: 50px;
    height: 50px;
    img[src*=".svg"] {
      transform: scale(0.8);
      max-height: 60%;
    }
    img[src*=".png"] {
      transform: scale(0.4);
      max-height: 58px;
    }
    ${fromTabletLg} {
      width: 58px;
      height: 58px;
      font-size: 24px;
      img[src*=".svg"] {
        transform: scale(1);
      }
      img[src*=".png"] {
        transform: scale(0.45);
        max-height: 64px;
      }
    }
    + span {
      margin-left: 0.75em;
    }
  }
  img {
    &.light {
      ${darkModeLinariaCSS("display: none;")}
    }
    &.dark {
      display: none;
      ${darkModeLinariaCSS("display: block;")}
    }
  }
`;

const StoryIconArray = (props: Props) => {
  return (
    <StoryIconArrayWrap color={props.white ? undefined : props.story.color}>
      {props.story.icons.length === 0 && (
        <span>
          <StoryIconFallbackTinesLogo />
        </span>
      )}
      {props.story.icons.map((icon, i) => {
        const imageUrl = icon.url;
        const imageDarkModeUrl = icon.urlDarkMode;
        return (
          <span data-icon={icon.name} key={i}>
            <img
              className={imageDarkModeUrl ? "light" : undefined}
              src={imageUrl}
            />
            {imageDarkModeUrl && (
              <img className="dark" src={imageDarkModeUrl} />
            )}
          </span>
        );
      })}
    </StoryIconArrayWrap>
  );
};

const StoryIconFallbackTinesLogo = () => (
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill={`var(--ac, ${colors.purple})`}
    fillOpacity={0.5}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3246 0C7.55789 0 4.22744 2.45749 3.10017 6.06868L0 16L3.10017 25.9313C4.22744 29.5425 7.55789 32 11.3246 32H19.6754C23.4421 32 26.7726 29.5425 27.8998 25.9313L31 16L27.8998 6.06868C26.7726 2.45749 23.4421 0 19.6754 0H11.3246ZM19.6755 2.49634C22.3565 2.49634 24.727 4.24548 25.5293 6.81577L26.2894 9.25062C24.7603 7.98154 22.8241 7.26723 20.8004 7.26707L10.177 7.26679C8.16691 7.26694 6.24311 7.97168 4.71892 9.22481L5.47093 6.81577C6.27327 4.24548 8.64375 2.49634 11.3247 2.49634H19.6755ZM5.29239 12.1986L3.36203 14.7519L27.6152 14.7519L25.685 12.1989C24.525 10.6646 22.7178 9.76356 20.8002 9.76341H10.1192C8.22318 9.78153 6.44065 10.6798 5.29239 12.1986ZM27.6112 17.2482L25.6848 19.7989C24.5248 21.3349 22.7162 22.237 20.7972 22.2368L10.1799 22.2366C8.26086 22.2367 6.45231 21.3346 5.29226 19.7986L3.3661 17.2482L27.6112 17.2482ZM5.47078 25.1842C6.27312 27.7545 8.6436 29.5037 11.3246 29.5037H19.6754C22.3564 29.5037 24.7268 27.7545 25.5292 25.1842L26.2898 22.7476C24.7601 24.0182 22.8223 24.7333 20.797 24.7332L10.1801 24.7329C8.16828 24.7331 6.24292 24.0274 4.71799 22.7727L5.47078 25.1842Z"
    />
  </svg>
);

export default StoryIconArray;
