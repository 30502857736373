export const CityScapeTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path opacity="0.5" d="M3 8L6 7V15H3V8Z" />
    <rect opacity="0.5" x="6" y="12" width="4" height="3" />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4H6V5H10V4ZM10 6H6V7H10V6ZM6 8H10V9H6V8ZM10 10H6V11H10V10Z"
    />
    <path opacity="0.2" d="M10 8H14L15 15H10V8Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 1C6.77614 1 7 1.22386 7 1.5V2.66667L10 2.16667L11 2V3.01379V8H14L14.8571 14H16.5C16.7761 14 17 14.2239 17 14.5C17 14.7761 16.7761 15 16.5 15H15H13.9898H11H10V14V12V9V8V3.18046L6 3.84713V12V14V15H5H1.5C1.22386 15 1 14.7761 1 14.5C1 14.2239 1.22386 14 1.5 14H5V3L6 2.83333V1.5C6 1.22386 6.22386 1 6.5 1ZM11 12V14H13.847L13.1327 9H11V12Z"
    />
  </svg>
);
