import { css } from "@linaria/core";
import { onlyPhones } from "../breakpointsAndMediaQueries.styles";

/** For most pages, those fonts are enough */
export const defaultFontFaces = css`
  :global() {
    /** Roobert */
    /* 
    @font-face {
      font-family: "Roobert";
      font-style: normal;
      font-display: block;
      font-weight: 300;
      src: url("/fonts/Roobert-Light.woff2") format("woff2"),
        url("/fonts/Roobert-Light.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: normal;
        font-display: swap;
        font-weight: 300;
        src: url("/fonts/Roobert-Light.woff2") format("woff2"),
          url("/fonts/Roobert-Light.woff") format("woff");
      }
    } */
    @font-face {
      font-family: "Roobert";
      font-style: normal;
      font-display: block;
      font-weight: 400;
      src: url("/fonts/Roobert-Regular.woff2") format("woff2"),
        url("/fonts/Roobert-Regular.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url("/fonts/Roobert-Regular.woff2") format("woff2"),
          url("/fonts/Roobert-Regular.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Roobert";
      font-style: normal;
      font-display: block;
      font-weight: 500;
      src: url("/fonts/Roobert-Medium.woff2") format("woff2"),
        url("/fonts/Roobert-Medium.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: swap;
        font-display: block;
        font-weight: 500;
        src: url("/fonts/Roobert-Medium.woff2") format("woff2"),
          url("/fonts/Roobert-Medium.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Roobert";
      font-style: normal;
      font-display: block;
      font-weight: 600;
      src: url("/fonts/Roobert-SemiBold.woff2") format("woff2"),
        url("/fonts/Roobert-SemiBold.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: swap;
        font-display: block;
        font-weight: 600;
        src: url("/fonts/Roobert-SemiBold.woff2") format("woff2"),
          url("/fonts/Roobert-SemiBold.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Roobert";
      font-style: normal;
      font-display: block;
      font-weight: 700;
      src: url("/fonts/Roobert-Bold.woff2") format("woff2"),
        url("/fonts/Roobert-Bold.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Roobert";
        font-style: swap;
        font-display: block;
        font-weight: 700;
        src: url("/fonts/Roobert-Bold.woff2") format("woff2"),
          url("/fonts/Roobert-Bold.woff") format("woff");
      }
    }

    /** Reckless Neue */

    @font-face {
      font-family: "Reckless Neue VF";
      font-style: normal;
      font-display: block;
      src: url("/fonts/RecklessNeueVF.woff2") format("woff2-variations"),
        url("/fonts/RecklessNeueVF.woff2") format("woff2"),
        url("/fonts/RecklessNeueVF.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless Neue VF";
        font-style: normal;
        font-display: swap;
        src: url("/fonts/RecklessNeueVF.woff2") format("woff2-variations"),
          url("/fonts/RecklessNeueVF.woff2") format("woff2"),
          url("/fonts/RecklessNeueVF.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Reckless Neue VF";
      font-style: italic;
      font-display: block;
      src: url("/fonts/RecklessNeueItalicVF.woff2") format("woff2-variations"),
        url("/fonts/RecklessNeueItalicVF.woff2") format("woff2"),
        url("/fonts/RecklessNeueItalicVF.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless Neue VF";
        font-style: italic;
        font-display: swap;
        src: url("/fonts/RecklessNeueItalicVF.woff2") format("woff2-variations"),
          url("/fonts/RecklessNeueItalicVF.woff2") format("woff2"),
          url("/fonts/RecklessNeueItalicVF.woff") format("woff");
      }
    }
    /* @font-face {
      font-family: "Reckless Neue";
      font-style: normal;
      font-display: block;
      font-weight: 300;
      src: url("/fonts/RecklessNeue-Light.woff2") format("woff2"),
        url("/fonts/RecklessNeue-Light.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless Neue";
        font-style: normal;
        font-display: swap;
        font-weight: 300;
        src: url("/fonts/RecklessNeue-Light.woff2") format("woff2"),
          url("/fonts/RecklessNeue-Light.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Reckless Neue";
      font-style: normal;
      font-display: block;
      font-weight: 350;
      src: url("/fonts/RecklessNeue-Book.woff2") format("woff2"),
        url("/fonts/RecklessNeue-Book.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless Neue";
        font-style: normal;
        font-display: swap;
        font-weight: 350;
        src: url("/fonts/RecklessNeue-Book.woff2") format("woff2"),
          url("/fonts/RecklessNeue-Book.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Reckless Neue";
      font-style: normal;
      font-display: block;
      font-weight: 400;
      src: url("/fonts/RecklessNeue-Regular.woff2") format("woff2"),
        url("/fonts/RecklessNeue-Regular.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless Neue";
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url("/fonts/RecklessNeue-Regular.woff2") format("woff2"),
          url("/fonts/RecklessNeue-Regular.woff") format("woff");
      }
    } */

    /** Reckless */

    @font-face {
      font-family: "Reckless";
      font-style: normal;
      font-display: block;
      font-weight: 300;
      src: url("/fonts/Reckless-Light.woff2") format("woff2"),
        url("/fonts/Reckless-Light.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless";
        font-style: normal;
        font-display: swap;
        font-weight: 300;
        src: url("/fonts/Reckless-Light.woff2") format("woff2"),
          url("/fonts/Reckless-Light.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Reckless";
      font-style: italic;
      font-display: block;
      font-weight: 300;
      src: url("/fonts/Reckless-LightItalic.woff2") format("woff2"),
        url("/fonts/Reckless-LightItalic.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless";
        font-style: italic;
        font-display: swap;
        font-weight: 300;
        src: url("/fonts/Reckless-LightItalic.woff2") format("woff2"),
          url("/fonts/Reckless-LightItalic.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Reckless";
      font-style: normal;
      font-display: block;
      font-weight: 400;
      src: url("/fonts/Reckless-Regular.woff2") format("woff2"),
        url("/fonts/Reckless-Regular.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless";
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url("/fonts/Reckless-Regular.woff2") format("woff2"),
          url("/fonts/Reckless-Regular.woff") format("woff");
      }
    }
    font-face {
      font-family: "Reckless";
      font-style: italic;
      font-display: block;
      font-weight: 400;
      src: url("/fonts/Reckless-RegularItalic.woff2") format("woff2"),
        url("/fonts/Reckless-RegularItalic.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless";
        font-style: italic;
        font-display: swap;
        font-weight: 400;
        src: url("/fonts/Reckless-RegularItalic.woff2") format("woff2"),
          url("/fonts/Reckless-RegularItalic.woff") format("woff");
      }
    }
    /* @font-face {
      font-family: "Reckless";
      font-style: normal;
      font-display: block;
      font-weight: 700;
      src: url("/fonts/Reckless-Bold.woff2") format("woff2"),
        url("/fonts/Reckless-Bold.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless";
        font-style: normal;
        font-display: swap;
        font-weight: 700;
        src: url("/fonts/Reckless-Bold.woff2") format("woff2"),
          url("/fonts/Reckless-Bold.woff") format("woff");
      }
    }
    @font-face {
      font-family: "Reckless";
      font-style: italic;
      font-display: block;
      font-weight: 700;
      src: url("/fonts/Reckless-BoldItalic.woff2") format("woff2"),
        url("/fonts/Reckless-BoldItalic.woff") format("woff");
    }
    ${onlyPhones} {
      @font-face {
        font-family: "Reckless";
        font-style: italic;
        font-display: swap;
        font-weight: 700;
        src: url("/fonts/Reckless-BoldItalic.woff2") format("woff2"),
          url("/fonts/Reckless-BoldItalic.woff") format("woff");
      }
    } */
  }
`;
