import { styled } from "@linaria/react";

type Props = {
  children: string;
  opacity?: number;
  color?: string;
  block?: boolean;
};

const Wrap = styled.span`
  > * {
    + * {
      margin-left: 0.2em;
    }
  }
`;

const Word = styled.span`
  display: block;
  background-color: currentColor;
  border-radius: 0.2em;
  height: 0.6em;
`;

const TextPlaceholder = (props: Props) => {
  return (
    <Wrap
      style={{
        display: props.block ? "flex" : "inline-flex",
        color: props.color,
      }}
    >
      {props.children.split(" ").map((word, i) => (
        <Word
          key={i}
          style={{
            width: `${word.length * 0.375}em`,
            opacity: props.opacity,
          }}
        />
      ))}
    </Wrap>
  );
};

export default TextPlaceholder;
