export const LightningBoltIcon = () => (
  <svg
    width="48"
    height="80"
    viewBox="0 0 48 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7899 45.0135L8.00586 38.8928L28.0164 31.949L46.8006 38.0699L26.7899 45.0135Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.6198 47.6488L0.835938 41.5281L20.8463 34.5845L39.6303 40.7052L19.6198 47.6488Z"
      fill="#FFE1B3"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.8007 38.0698L26.79 45.0135L26.7102 45.1889L19.6208 47.6491L5.26562 79.2982L12.4347 76.6624L46.8007 38.0698Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.1306 1L33.9615 3.6358L0.835938 41.5284L20.8463 34.5847L20.9197 34.411L28.0155 31.9489L41.1306 1Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M0.835938 41.5283L20.8463 34.5847L33.9615 3.63574L0.835938 41.5283Z"
      fill="#FFD18C"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.6208 47.649L39.6313 40.7053L5.26562 79.2981L19.6208 47.649Z"
      fill="#FFD18C"
      stroke="#32274B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.3984 34.6103L26.6933 36.5612L33.6603 33.7744L28.0143 31.949L21.3984 34.6103Z"
      fill="#32274B"
    />
    <path
      d="M13.0753 76.3499L5.09277 79.814C7.09692 79.9329 9.34931 80.0001 11.7316 80.0001C20.0716 80.0001 26.8325 79.1812 26.8325 78.1712C26.8327 77.216 20.7854 76.4323 13.0753 76.3499Z"
      fill="#32274B"
    />
  </svg>
);
