import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { DatoCmsMediaMention } from "../../../graphql-types";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  mediaMention: DatoCmsMediaMention;
};

const mediaMentionLinkAnchorStyle = css`
  display: grid;
  grid-template-rows: minmax(0, auto) minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  text-align: left;
  text-decoration: none;
  position: relative;
`;

const LogoFrame = styled.div`
  background-color: ${colors.white};
  aspect-ratio: 5/3;
  border-radius: ${rSize("gap", 0.5)};
  border: 1px solid ${colors.light400};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    max-width: 80%;
    pointer-events: none;
  }
`;

const Header = styled.header`
  display: grid;
  grid-template-rows: minmax(0, auto) minmax(0, 1fr);
  grid-gap: 1.5em;
`;
const TitleGroup = styled.div`
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
`;
const Meta = styled.p`
  text-transform: uppercase;
  color: ${withOpacity(colors.dark500, 0.5)};
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 1.15rem;
`;
const MediaName = styled.span`
  color: ${colors.purple700};
`;
const Title = styled.h3`
  font-size: 2.2rem;
  ${fromTablet} {
    font-size: 2.4rem;
  }
`;
const ReadMore = styled.span`
  display: block;
  color: ${colors.purple};
  font-weight: 600;
  align-self: end;
`;

const MediaMentionLink = (props: Props) => {
  const { heading, url, mediaLogo, mediaName } = props.mediaMention;
  return (
    <a
      className={mediaMentionLinkAnchorStyle}
      href={url ?? ""}
      title={heading ?? ""}
      {...externalLinkAttr}
    >
      <LogoFrame>{mediaLogo?.url && <img src={mediaLogo?.url} />}</LogoFrame>
      <Header>
        <TitleGroup>
          <Meta>
            <MediaName>{mediaName}</MediaName>
          </Meta>
          <Title>{heading}</Title>
        </TitleGroup>
        <ReadMore>Read more ↗</ReadMore>
      </Header>
    </a>
  );
};

export default MediaMentionLink;
