import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { colors } from "../../styles/colors.styles";
import { css } from "linaria";
import { Link } from "gatsby";
import { captureAnalyticsEvent } from "../../utils/analyticsEvent";
import { onlyPhones } from "../../styles/breakpointsAndMediaQueries.styles";

export const AnnouncementBarOuter = styled.div`
  position: relative;
  font-size: 1.4rem;
  line-height: 1;
  display: flex;
  font-weight: 500;
  text-align: left;
  ${onlyPhones} {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
`;

const Tag = styled.div`
  position: relative;
  padding: 0.2em 0.66em;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid currentColor;
    border-radius: 1em;
    opacity: 0.25;
  }
`;

const AnnouncementBarContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.33em 0.75em 0.33em 0.33em;
  border-radius: 2em;
  text-decoration: none;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
  &:hover {
    background-color: ${withOpacity(colors.purple300, 0.1)};
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid currentColor;
    border-radius: 2em;
    opacity: 0.25;
  }
`;

const Children = styled.div``;

export const AnnouncementBar = (props: {
  tag?: ReactNode;
  children: ReactNode;
  link: string;
}) => {
  return (
    <AnnouncementBarOuter>
      <Link
        to={props.link}
        className={AnnouncementBarContainer}
        onClick={() => {
          captureAnalyticsEvent("clickedHomepageHeroAnnouncementBar", {
            linkLabel: `[${props.tag}] ${props.children}`,
            linkUrl: props.link,
          });
        }}
      >
        {props.tag && <Tag>{props.tag}</Tag>}
        <Children>{props.children}</Children>
        <svg width="9" height="8" viewBox="0 0 9 8" fill="currentColor">
          <path d="M5.3358 0.465698L8.86381 4.0063L5.34841 7.5343L4.668 6.8539L6.9864 4.5355H0.799805V3.4771H6.9864L4.65541 1.1461L5.3358 0.465698Z" />
        </svg>
      </Link>
    </AnnouncementBarOuter>
  );
};
