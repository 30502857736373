import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import { PropsWithChildren, ReactNode } from "react";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import StaticImageFrame, {
  StaticImageFrameFigure,
} from "../basic/StaticImageFrame";
import { colors } from "../../styles/colors.styles";

export const BrowserChromeOuter = styled.figure<{ backgroundColor?: string }>`
  position: relative;
  &[data-border-style="solid"] {
    background-color: ${p => p.backgroundColor ?? colors.light200};
  }
  &[data-border-style="gradient"] {
    background-color: ${p =>
      withOpacity(p.backgroundColor ?? colors.lightest, 0.1)};
    background-image: ${p => `linear-gradient(
    85deg,
    ${withOpacity(p.backgroundColor ?? colors.lightest, 0.25)},
    ${withOpacity(p.backgroundColor ?? colors.lightest, 0)}
  )`};
  }
  margin: 0;
  color: ${withOpacity(colors.darkest, 0.3)};
  user-select: none;
  padding: 0 0.8rem 0.8rem;
  border-radius: 0.75em;
  ${fromTabletMd} {
    padding: 0 1.4rem 1.4rem;
    border-radius: 1.25em;
  }
  &.noTitleBar {
    padding-top: 0.8rem;
    ${fromTabletMd} {
      padding-top: 1.4rem;
    }
  }
  &.smallerHeaderForMobile {
    padding: 0 0.35em 0.35em;
    border-radius: 0.75em;
    ${fromPhoneLg} {
      padding: 0 0.5em 0.5em;
      border-radius: 1em;
    }
    ${fromDesktopMd} {
      padding: 0 1.4rem 1.4rem;
      border-radius: 1.25em;
    }
  }
  &.fillParent {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
  }
  &.withFade {
    &:after {
      content: "";
      display: block;
      ${cover()};
      background-image: linear-gradient(
        to top,
        ${colors.lightest},
        ${withOpacity(colors.lightest, 0)} 8rem
      );
      pointer-events: none;
      border-radius: 1em;
      ${fromTabletMd} {
        border-radius: 2em;
      }
    }
  }
`;

export const BrowserChromeHeader = styled.div`
  display: grid;
  position: relative;
  padding: 1em 0.25em;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  grid-template-columns: 1fr minmax(auto, 1fr) 1fr;
  grid-gap: 1em;
  svg {
    width: 54px;
    height: 14px;
  }
  p {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${withOpacity(colorsV4.warmBlack, 0.8)};
    white-space: nowrap;
  }
  .smallerHeaderForMobile & {
    padding: 0.5em 0.25em;
    svg {
      display: block;
      width: 28px;
      height: 8px;
    }
    ${fromPhoneLg} {
      padding: 0.875em 0.375em;
      svg {
        width: 41px;
        height: 10px;
      }
    }
    ${fromDesktopMd} {
      padding: 1em 0.25em;
      svg {
        width: 54px;
        height: 14px;
      }
    }
  }
`;

export const BrowserChromeInner = styled.div<{ backgroundColor?: string }>`
  position: relative;
  background-color: ${p => p.backgroundColor ?? colors.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.5em;
  padding: 0;
  transform: translate3d(0, 0, 0);
  ${StaticImageFrameFigure} {
    border-radius: 0.5em;
    overflow: hidden;
  }
`;

const Backdrop = styled.div`
  ${cover()};
`;

const ContentWrap = styled.div`
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
`;

export const BrowserChromeTitleBarTextHolder = styled.p``;

const BrowserChrome = (
  props: PropsWithChildren<{
    className?: string;
    src?: string;
    src2x?: string;
    alt?: string;
    width: number;
    height?: number;
    fillParent?: boolean;
    withFade?: boolean;
    smallerHeaderForMobile?: boolean;
    backgroundColor?: string;
    titleBarContent?: ReactNode;
    titleBarTextColor?: string;
    backdrop?: ReactNode;
    noTitleBar?: boolean;
    borderStyle?: "solid" | "gradient";
  }>
) => {
  const {
    className,
    src,
    src2x,
    alt,
    width,
    height,
    withFade,
    children,
    backgroundColor,
    fillParent,
    smallerHeaderForMobile,
    noTitleBar,
    borderStyle = "gradient",
  } = props;
  return (
    <BrowserChromeOuter
      className={cx(
        className,
        withFade && "withFade",
        noTitleBar && "noTitleBar",
        fillParent && "fillParent",
        smallerHeaderForMobile && "smallerHeaderForMobile"
      )}
      backgroundColor={backgroundColor}
      data-border-style={borderStyle}
    >
      {!noTitleBar && (
        <BrowserChromeHeader>
          <svg
            width="54"
            height="14"
            viewBox="0 0 54 14"
            fill={colorsV4.white}
            fillOpacity={0.5}
          >
            <circle cx="7" cy="7" r="6" />
            <circle cx="27" cy="7" r="6" />
            <circle cx="47" cy="7" r="6" />
          </svg>
          {props.titleBarContent && (
            <>
              <BrowserChromeTitleBarTextHolder
                style={{
                  color: props.titleBarTextColor,
                }}
              >
                {props.titleBarContent}
              </BrowserChromeTitleBarTextHolder>
              <div />
            </>
          )}
        </BrowserChromeHeader>
      )}
      <BrowserChromeInner
        style={{
          aspectRatio: height ? `${width} / ${height}` : undefined,
          backgroundColor,
        }}
      >
        {props.backdrop && <Backdrop>{props.backdrop}</Backdrop>}
        <ContentWrap>
          {src && (
            <StaticImageFrame
              src={src}
              src2x={src2x}
              alt={alt}
              intrinsicWidth={width}
              intrinsicHeight={height}
              aspectRatio={`${width} / ${height}`}
            />
          )}
          {children}
        </ContentWrap>
      </BrowserChromeInner>
    </BrowserChromeOuter>
  );
};

export default BrowserChrome;
