import { Script } from "gatsby";
import { isTinesComBuildOnMain } from "../../../environment";

export const AlwaysOnThirdPartyScripts = () => <>
  {isTinesComBuildOnMain && (
    <Script
      id="6senseWebTag"
      src="https://j.6sc.co/j/97cbe00c-b247-4f9c-9fc6-ad994fd663d7.js"
    />
  )}
</>