import { styled } from "@linaria/react";
import { css } from "linaria";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import SpotIllustration from "../illustrations/SpotIllustration";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  align-items: stretch;
  color: ${colorsV4.warmBlack};
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const ContainerStyle = css`
  background-color: ${colorsV4.canvas550};
  text-decoration: none;
  border-radius: 1.5em;
  display: flex;
  padding: 1em;
  > div {
    display: flex;
    align-items: center;
  }
  ${fromTabletMd} {
    padding: 1.5em;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    text-align: center;
    > div {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  ${fromDesktopMd} {
    aspect-ratio: 1/1;
    justify-content: center;
  }
  img {
    width: 72px;
    height: 72px;
    ${uptoTablet} {
      margin-right: 0.5em;
    }
    ${fromTablet} {
      width: 100px;
      height: 100px;
    }
    ${fromDesktopMd} {
      width: 120px;
      height: 120px;
    }
  }
  h3 {
    font-size: 1.8rem;
    margin-bottom: 0.25em;
    ${fromTabletMd} {
      font-size: 2rem;
      margin-top: 0.75em;
      margin-bottom: 0.5em;
    }
  }
  p {
    font-size: 1.4rem;
    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const MSSPUseCases = () => {
  return (
    <Grid>
      <div
        className={ContainerStyle}
        style={{
          backgroundColor: colorsV4.purple50,
        }}
      >
        <div>
          <SpotIllustration name="magnet" />
          <div>
            <h3>Ticketing integration</h3>
            <p>
              Automatically add and enrich security alerts or test results to
              your preferred ticketing system.
            </p>
          </div>
        </div>
      </div>
      <div
        className={ContainerStyle}
        style={{
          backgroundColor: colorsV4.yellow50,
        }}
      >
        <div>
          <SpotIllustration
            name="onboarding"
            className={css`
              ${fromTabletMd} {
                transform: translateY(2%);
              }
            `}
          />
          <div>
            <h3>Client onboarding</h3>
            <p>
              Create workflows to automate client onboarding, including software
              deployment and more.
            </p>
          </div>
        </div>
      </div>
      <div
        className={ContainerStyle}
        style={{
          backgroundColor: colorsV4.orange50,
        }}
        {...externalLinkAttr}
      >
        <div>
          <SpotIllustration
            name="automation"
            className={css`
              ${fromTabletMd} {
                transform: translateY(7%);
              }
            `}
          />
          <div>
            <h3>IT maintenance</h3>
            <p>
              Reduce time analysts spend on manual tasks and automate software
              updates and changes.
            </p>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default MSSPUseCases;
