import { styled } from "@linaria/react";
import { DatoCmsSolutionPage } from "../../../../graphql-types";
import { CTADecorationChartAndWires } from "./CTADecorationChartAndWires";
import { CTADecorationClouds } from "./CTADecorationClouds";
import { CTADecorationPortals } from "./CTADecorationPortals";
import { SolutionPageIllustrationType } from "./SolutionPageIllustrationType.enum";
import {
  fromDesktop,
  fromTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { CTADecorationPlatforms } from "./CTADecorationPlatforms";
import { CTADecorationPalace } from "./CTADecorationPalace";

type Props = {
  page: DatoCmsSolutionPage;
};

const Wrap = styled.div`
  max-width: 100%;
  [stroke] {
    stroke-width: 2;
    ${fromTablet} {
      stroke-width: 1.5;
    }
    ${fromDesktop} {
      stroke-width: 1;
    }
  }
`;

const defs = {
  [SolutionPageIllustrationType.Clouds]: CTADecorationClouds,
  [SolutionPageIllustrationType.Portals]: CTADecorationPortals,
  [SolutionPageIllustrationType.ChartAndWires]: CTADecorationChartAndWires,
  [SolutionPageIllustrationType.Platforms]: CTADecorationPlatforms,
  [SolutionPageIllustrationType.Palace]: CTADecorationPalace,
};

const CTADecorationDispatcher = (props: Props) => {
  const Decoration = defs[props.page.pageIllustrationType as keyof typeof defs];
  return <Wrap>{Decoration ? <Decoration /> : null}</Wrap>;
};

export default CTADecorationDispatcher;
