import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { font } from "../../../styles/fonts.styles";
import { actionTypeDefs } from "../../../constants/actionTypes.constants";
import { ProductTemplatesIcon } from "../../icons/misc/TemplatesIcon";
import { ProductLibraryIcon } from "../../icons/misc/ProductLibraryIcon";
import { ProductToolsMenuButtonIcon } from "../../icons/misc/ProductToolsMenuButtonIcon";
import RigidDotGrid from "../../decorations/RigidDotGrid";
import storyboardContent from "./product-page-hero-illustration-story-board-content.svg";
import {
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
  onlyTablets,
  uptoDesktopMl,
  uptoTabletLg,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const productBorderColor = "#F2F2F2";

const ProductPageHeroAppContentOuter = styled.div`
  position: relative;
  background-color: ${colors.white};
  border: 1px solid ${colors.purple};
  border-radius: 0.9rem 0.9rem 1.5rem 1.5rem;
  font-family: ${font("system")};
  font-size: 11px;
  font-weight: 500;
  display: grid;
  overflow: hidden;
  grid-template-columns: 48px minmax(0, 1fr);
  grid-template-rows: 48px minmax(auto, 1fr);
  grid-template-areas: "top top top" "left viewport right";
  ${fromTabletMd} {
    grid-template-columns: 64px minmax(0, 1fr) 252px;
    grid-template-rows: 54px minmax(auto, 1fr);
  }
  ${fromDesktopMl} {
    grid-template-columns: 252px minmax(0, 1fr) 252px;
  }
`;

const TitleBar = styled.div`
  grid-area: top;
  border-bottom: 1px solid ${productBorderColor};
  display: grid;
  align-items: center;
  grid-template-columns: 4rem minmax(auto, 1fr) 4rem;
  padding: 0 1.6rem;
  ${fromTablet} {
    grid-template-columns: 8em minmax(auto, 2fr) 8em;
    padding: 0 2rem;
  }
  ${fromTabletLg} {
    grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr) minmax(auto, 1fr);
    padding: 0 2rem;
  }
`;

const TitleBarStart = styled.div`
  display: flex;
  align-items: center;
`;
const TitleBarCenter = styled.div``;
const TitleBarEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StoryAndRunsToggle = styled.div`
  display: inline-flex;
  background-color: #f9f9f9;
  border: 1px solid ${productBorderColor};
  border-radius: 8px;
  margin-left: 1.5em;
  padding: 1px;
  ${uptoTabletLg} {
    display: none;
  }
  span,
  strong {
    padding: 0.4em;
    border-radius: 6px;
    width: 4.5em;
    border: 1px solid transparent;
    color: ${withOpacity(colors.dark500, 0.5)};
  }
  strong {
    color: ${colors.dark500};
    background-color: ${colors.white};
    border-color: ${productBorderColor};
    font-weight: inherit;
  }
`;

const StoryTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    opacity: 0.6;
  }
  svg {
    margin-left: 0.5em;
  }
`;

const Viewport = styled.div`
  grid-area: viewport;
  position: relative;
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryboardContentImage = styled.img`
  display: block;
  max-width: 100%;
  aspect-ratio: 420 / 552;
  position: relative;
  ${uptoDesktopMl} {
    max-height: 420px;
  }
`;

const ProductPageHeroAppContent = () => {
  return (
    <ProductPageHeroAppContentOuter>
      <TitleBar>
        <TitleBarStart>
          <TitleBarTinesLogo />
          <StoryAndRunsToggle>
            <strong>Story</strong>
            <span>Runs</span>
          </StoryAndRunsToggle>
        </TitleBarStart>
        <TitleBarCenter>
          <StoryTitle>
            <span>Team Tines  /  </span>Report triage <ChevronDown />
          </StoryTitle>
        </TitleBarCenter>
        <TitleBarEnd>
          <TitleBarEndContents />
        </TitleBarEnd>
      </TitleBar>

      <Viewport>
        <RigidDotGrid
          cellSize={12}
          dotSize={0.5}
          color={withOpacity(colors.dark500, 0.3)}
        />
        <StoryboardContentImage src={storyboardContent} />
      </Viewport>

      <LeftSidebar />

      <RightSidebar />
    </ProductPageHeroAppContentOuter>
  );
};

export default ProductPageHeroAppContent;

const TitleBarTinesLogo = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64711 0.893555C4.04166 0.893555 2.62214 1.90826 2.14168 3.39933L0.820312 7.50001L2.14168 11.6007C2.62215 13.0918 4.04166 14.1065 5.64711 14.1065H9.20642C10.8119 14.1065 12.2314 13.0918 12.7119 11.6007L14.0332 7.50001L12.7119 3.39933C12.2314 1.90826 10.8119 0.893555 9.20642 0.893555H5.64711ZM9.20647 1.9243C10.3492 1.9243 11.3595 2.64653 11.7015 3.70781L12.0255 4.71317C11.3737 4.18916 10.5485 3.89422 9.68591 3.89415L5.15799 3.89404C4.30123 3.8941 3.48127 4.18509 2.83162 4.70251L3.15215 3.70781C3.49412 2.64653 4.50447 1.9243 5.64717 1.9243H9.20647ZM3.07605 5.9304L2.25329 6.98466L12.5905 6.98466L11.7678 5.93052C11.2734 5.29701 10.5032 4.92496 9.68583 4.9249H5.13334C4.32522 4.93238 3.56546 5.30329 3.07605 5.9304ZM12.5888 8.0154L11.7678 9.06858C11.2733 9.7028 10.5025 10.0753 9.68456 10.0752L5.15921 10.0751C4.34128 10.0752 3.57044 9.70268 3.07599 9.06847L2.25502 8.0154L12.5888 8.0154ZM3.15208 11.2922C3.49406 12.3535 4.50441 13.0757 5.6471 13.0757H9.20641C10.3491 13.0757 11.3595 12.3535 11.7014 11.2922L12.0256 10.2861C11.3736 10.8108 10.5477 11.106 9.68448 11.106L5.15929 11.1059C4.30182 11.1059 3.48118 10.8146 2.83123 10.2965L3.15208 11.2922Z"
      fill="#333333"
    />
  </svg>
);

const ChevronDown = () => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
    <path
      d="M7.11542 1.26123L4.01865 4.358L0.921875 1.26123"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CaretRight = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path
      d="M4.56641 2.24536L7.66318 5.34214L4.56641 8.43891"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TitleBarEndContentsWrap = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.75em;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      ${onlyPhones} {
        display: none;
      }
    }
    &:nth-child(1),
    &:nth-child(2) {
      ${onlyTablets} {
        display: none;
      }
    }
    &:nth-child(2) {
      ${fromTabletLg} {
        display: block;
      }
    }
  }
`;

const TitleBarEndContents = () => (
  <TitleBarEndContentsWrap>
    <svg width="70" height="22" viewBox="0 0 70 22" fill="none">
      <rect
        x="48.8672"
        y="1.25"
        width="19.5"
        height="19.5"
        rx="9.75"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="58.6172" cy="11" r="8.5" fill="#F486B8" stroke="#E269A4" />
      <rect
        x="32.8672"
        y="1.25"
        width="19.5"
        height="19.5"
        rx="9.75"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="42.6172" cy="11" r="8.5" fill="#5ABE89" stroke="#25A871" />
      <rect
        x="16.8672"
        y="1.25"
        width="19.5"
        height="19.5"
        rx="9.75"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="26.6172" cy="11" r="8.5" fill="#8D75E6" stroke="#7F69CE" />
      <rect
        x="0.867188"
        y="1.25"
        width="19.5"
        height="19.5"
        rx="9.75"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="10.6172" cy="11" r="8.5" fill="#FD975D" stroke="#F47E3F" />
    </svg>

    <svg width="56" height="27" viewBox="0 0 56 27" fill="none">
      <path
        d="M18.4582 17.8756L15.8739 15.2919C16.6229 14.3926 16.9964 13.2392 16.9167 12.0716C16.837 10.904 16.3102 9.81204 15.4459 9.02294C14.5816 8.23384 13.4464 7.80832 12.2763 7.83492C11.1063 7.86151 9.99155 8.33816 9.164 9.16571C8.33645 9.99326 7.8598 11.108 7.83321 12.278C7.80662 13.4481 8.23213 14.5833 9.02123 15.4476C9.81033 16.3119 10.9023 16.8387 12.0699 16.9184C13.2375 16.9981 14.3909 16.6246 15.2901 15.8756L17.8739 18.4599C17.9123 18.4982 17.9578 18.5287 18.0079 18.5494C18.058 18.5702 18.1118 18.5809 18.166 18.5809C18.2203 18.5809 18.274 18.5702 18.3241 18.5494C18.3742 18.5287 18.4198 18.4982 18.4582 18.4599C18.4965 18.4215 18.5269 18.376 18.5477 18.3258C18.5685 18.2757 18.5792 18.222 18.5792 18.1677C18.5792 18.1135 18.5685 18.0598 18.5477 18.0096C18.5269 17.9595 18.4965 17.914 18.4582 17.8756ZM8.66925 12.3871C8.66925 11.6521 8.88719 10.9336 9.29553 10.3225C9.70386 9.7114 10.2842 9.23509 10.9633 8.95383C11.6423 8.67256 12.3895 8.59897 13.1104 8.74236C13.8312 8.88575 14.4934 9.23967 15.0131 9.75938C15.5328 10.2791 15.8867 10.9412 16.0301 11.6621C16.1735 12.383 16.0999 13.1302 15.8186 13.8092C15.5374 14.4882 15.0611 15.0686 14.4499 15.4769C13.8388 15.8853 13.1204 16.1032 12.3854 16.1032C11.4001 16.1021 10.4556 15.7103 9.75888 15.0136C9.06221 14.3169 8.67034 13.3723 8.66925 12.3871Z"
        fill="#333333"
      />
      <path
        d="M40.9883 16.5161C40.9883 16.9541 41.1623 17.3742 41.472 17.684C41.7818 17.9937 42.2019 18.1677 42.6399 18.1677C43.0779 18.1677 43.498 17.9937 43.8078 17.684C44.1175 17.3742 44.2915 16.9541 44.2915 16.5161"
        stroke="#333333"
        strokeWidth="0.825806"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9249 11.9742C38.9249 10.9886 39.3164 10.0434 40.0133 9.34649C40.7102 8.64958 41.6554 8.25806 42.641 8.25806C43.6266 8.25806 44.5718 8.64958 45.2687 9.34649C45.9656 10.0434 46.3571 10.9886 46.3571 11.9742C46.3571 13.823 46.7855 15.3084 47.1262 15.8968C47.1624 15.9594 47.1814 16.0305 47.1815 16.1029C47.1816 16.1752 47.1626 16.2463 47.1265 16.309C47.0905 16.3718 47.0386 16.4239 46.976 16.4603C46.9134 16.4966 46.8424 16.5159 46.77 16.5161H38.512C38.4397 16.5157 38.3688 16.4963 38.3064 16.4599C38.244 16.4235 38.1922 16.3713 38.1563 16.3086C38.1203 16.2459 38.1015 16.1749 38.1016 16.1026C38.1017 16.0303 38.1207 15.9594 38.1569 15.8968C38.497 15.3084 38.9249 13.8224 38.9249 11.9742Z"
        stroke="#333333"
        strokeWidth="0.825806"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <svg width="34" height="25" viewBox="0 0 34 25" fill="none">
      <rect x="0.5" y="1.5" width="33" height="23" rx="11.5" fill="#F9F9F9" />
      <rect
        x="0.5"
        y="1.5"
        width="33"
        height="23"
        rx="11.5"
        stroke={productBorderColor}
      />
      <rect x="3.5" y="4.5" width="17" height="17" rx="8.5" fill="white" />
      <rect
        x="3.5"
        y="4.5"
        width="17"
        height="17"
        rx="8.5"
        stroke={productBorderColor}
      />
      <path
        d="M9.5 11.4375V12.0625C9.5 12.3111 9.59877 12.5496 9.77459 12.7254C9.9504 12.9012 10.1889 13 10.4375 13H13.5625C13.8111 13 14.0496 12.9012 14.2254 12.7254C14.4012 12.5496 14.5 12.3111 14.5 12.0625V11.4375"
        stroke="#333333"
        strokeWidth="0.635236"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13V14.5625"
        stroke="#333333"
        strokeWidth="0.635236"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 11.4375C10.0178 11.4375 10.4375 11.0178 10.4375 10.5C10.4375 9.98223 10.0178 9.5625 9.5 9.5625C8.98223 9.5625 8.5625 9.98223 8.5625 10.5C8.5625 11.0178 8.98223 11.4375 9.5 11.4375Z"
        stroke="#333333"
        strokeWidth="0.635236"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.4375C12.5178 16.4375 12.9375 16.0178 12.9375 15.5C12.9375 14.9822 12.5178 14.5625 12 14.5625C11.4822 14.5625 11.0625 14.9822 11.0625 15.5C11.0625 16.0178 11.4822 16.4375 12 16.4375Z"
        stroke="#333333"
        strokeWidth="0.635236"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 11.4375C15.0178 11.4375 15.4375 11.0178 15.4375 10.5C15.4375 9.98223 15.0178 9.5625 14.5 9.5625C13.9822 9.5625 13.5625 9.98223 13.5625 10.5C13.5625 11.0178 13.9822 11.4375 14.5 11.4375Z"
        stroke="#333333"
        strokeWidth="0.635236"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    <svg
      width="18"
      height="26"
      viewBox="0 0 18 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00217 13.6193C9.34423 13.6193 9.62152 13.342 9.62152 13C9.62152 12.6579 9.34423 12.3806 9.00217 12.3806C8.66011 12.3806 8.38281 12.6579 8.38281 13C8.38281 13.342 8.66011 13.6193 9.00217 13.6193Z"
        fill="#333333"
      />
      <path
        d="M9.00217 10.1098C9.34423 10.1098 9.62152 9.83251 9.62152 9.49045C9.62152 9.14839 9.34423 8.87109 9.00217 8.87109C8.66011 8.87109 8.38281 9.14839 8.38281 9.49045C8.38281 9.83251 8.66011 10.1098 9.00217 10.1098Z"
        fill="#333333"
      />
      <path
        d="M9.00217 17.1291C9.34423 17.1291 9.62152 16.8518 9.62152 16.5097C9.62152 16.1677 9.34423 15.8904 9.00217 15.8904C8.66011 15.8904 8.38281 16.1677 8.38281 16.5097C8.38281 16.8518 8.66011 17.1291 9.00217 17.1291Z"
        fill="#333333"
      />
    </svg>
  </TitleBarEndContentsWrap>
);

const LeftSidebarWrap = styled.div`
  grid-area: left;
  border-right: 1px solid ${productBorderColor};
`;

const LeftSidebarContentGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  ${fromDesktopMl} {
    height: 100%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    background-color: ${productBorderColor};
    grid-gap: 1px;
    > * {
      background-color: ${colors.white};
    }
  }
`;

const ActionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  ${uptoDesktopMl} {
    padding-top: 1em;
    padding-bottom: 1em;
    span {
      display: none;
    }
  }
  ${fromDesktopMl} {
    min-height: 5.5em;
  }
`;

const ActionButtonIconWrap = styled.div`
  + * {
    margin-top: 0.5em;
  }
`;

const LeftSidebarFullWidthButton = styled.div`
  ${uptoDesktopMl} {
    display: none;
  }
  ${fromDesktopMl} {
    display: grid;
    align-items: center;
    grid-column-end: span 2;
    grid-template-columns: auto minmax(0, 1fr) auto;
    grid-gap: 1em;
    padding: 0.5em 2rem;
    text-align: left;
  }
`;

const LeftSidebar = () => (
  <LeftSidebarWrap>
    <LeftSidebarContentGrid>
      {actionTypeDefs.map(def => (
        <ActionButton key={def.slug}>
          <ActionButtonIconWrap style={{ color: def.color }}>
            <def.Icon />
          </ActionButtonIconWrap>
          <span>{def.name}</span>
        </ActionButton>
      ))}
      <LeftSidebarFullWidthButton>
        <ProductToolsMenuButtonIcon />
        <span>Tools</span>
        <CaretRight />
      </LeftSidebarFullWidthButton>
      <LeftSidebarFullWidthButton>
        <ProductTemplatesIcon />
        <span>Templates</span>
        <CaretRight />
      </LeftSidebarFullWidthButton>
      <LeftSidebarFullWidthButton>
        <ProductLibraryIcon />
        <span>Library</span>
        <CaretRight />
      </LeftSidebarFullWidthButton>
    </LeftSidebarContentGrid>
  </LeftSidebarWrap>
);

const RightSidebarWrap = styled.div`
  grid-area: right;
  border-left: 1px solid ${productBorderColor};
  padding: 1em;
  text-align: left;
  ${fromDesktopMd} {
    padding: 1.5em;
  }
  ${uptoTabletMd} {
    display: none;
  }
`;

const RightSidebarSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  strong {
    font-weight: 600;
  }
  + * {
    margin-top: 1em;
  }
`;

const DummyButton = styled.div`
  background-color: #f9f9f9;
  border: 1px solid ${productBorderColor};
  min-width: 3.2rem;
  min-height: 3.2rem;
  &.compact {
    min-width: 2.8rem;
    min-height: 2.8rem;
  }
  padding: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75em;
`;
const DummyInput = styled.div`
  background-color: #f9f9f9;
  border: 1px solid ${productBorderColor};
  min-height: 3.2rem;
  padding: 0.5em 0.75em;
  display: flex;
  align-items: center;
  border-radius: 0.75em;
`;
const DummyTextarea = styled.div`
  background-color: #f9f9f9;
  border: 1px solid ${productBorderColor};
  min-height: 10rem;
  padding: 0.5em 0.75em;
  display: flex;
  align-items: flex-start;
  border-radius: 0.75em;
  line-height: 1.35;
`;

const StatusFlex = styled.div`
  display: flex;
  align-items: center;
  svg {
    display: block;
  }
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;

const StatusInputSetFlex = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  grid-gap: 0.5em;
`;

const Separator = styled.hr`
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: ${productBorderColor};
  margin: 1.5em 0;
`;

const DummyTag = styled.strong`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.purple600};
  padding: 0.2em 0.5em;
  border-radius: 0.375em;
  white-space: nowrap;
  border: 1px solid ${colors.purple200};
  background-color: ${colors.purple100};
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-right: 0.25em;
`;

const SlackIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M5.93826 11.8475C5.93733 12.2372 5.78213 12.6108 5.50657 12.8864C5.23102 13.1621 4.85753 13.3175 4.46776 13.3185C4.07789 13.3178 3.70419 13.1626 3.4284 12.8871C3.15261 12.6115 2.99717 12.2379 2.99609 11.848C2.99686 11.4581 3.15208 11.0843 3.42778 10.8085C3.70348 10.5327 4.0772 10.3773 4.46717 10.3764H5.93826V11.8475ZM6.67992 11.8475C6.68085 11.4577 6.83605 11.0841 7.11161 10.8085C7.38716 10.5328 7.76065 10.3775 8.15042 10.3764C8.54039 10.3772 8.91418 10.5324 9.18999 10.8081C9.4658 11.0838 9.62116 11.4575 9.62209 11.8475V15.5313C9.62116 15.9212 9.46588 16.2948 9.1902 16.5705C8.91451 16.8462 8.54088 17.0014 8.15101 17.0024C7.76113 17.0014 7.3875 16.8462 7.11181 16.5705C6.83613 16.2948 6.68085 15.9212 6.67992 15.5313V11.8475Z"
      fill="#E01E5A"
    />
    <path
      d="M8.15101 5.93979C7.76124 5.93887 7.38768 5.78366 7.11202 5.50811C6.83636 5.23255 6.681 4.85906 6.67992 4.46929C6.68069 4.07942 6.83583 3.70572 7.1114 3.42993C7.38697 3.15414 7.76055 2.99871 8.15042 2.99763C8.54039 2.9984 8.91418 3.15362 9.18999 3.42931C9.4658 3.70501 9.62116 4.07874 9.62209 4.46871V5.93979H8.15101ZM8.15101 6.68146C8.54078 6.68238 8.91433 6.83759 9.18999 7.11314C9.46565 7.3887 9.62101 7.76219 9.62209 8.15196C9.62132 8.54193 9.4661 8.91572 9.1904 9.19152C8.9147 9.46733 8.54098 9.6227 8.15101 9.62362H4.46659C4.07682 9.62254 3.70333 9.46719 3.42778 9.19153C3.15222 8.91586 2.99702 8.54231 2.99609 8.15254C2.99702 7.76267 3.1523 7.38903 3.42798 7.11335C3.70367 6.83767 4.0773 6.68238 4.46717 6.68146H8.15101Z"
      fill="#36C5F0"
    />
    <path
      d="M14.0549 8.15254C14.0558 7.76277 14.2111 7.38922 14.4866 7.11356C14.7622 6.83789 15.1357 6.68254 15.5254 6.68146C15.9153 6.68223 16.289 6.83737 16.5648 7.11294C16.8406 7.38851 16.996 7.76209 16.9971 8.15196C16.9963 8.54193 16.8411 8.91572 16.5654 9.19152C16.2897 9.46733 15.916 9.6227 15.526 9.62362H14.0549V8.15254ZM13.3133 8.15254C13.3123 8.54231 13.1571 8.91586 12.8816 9.19153C12.606 9.46719 12.2325 9.62254 11.8428 9.62362C11.4528 9.62285 11.079 9.46763 10.8032 9.19194C10.5274 8.91624 10.372 8.54251 10.3711 8.15254V4.46813C10.3722 4.07836 10.5275 3.70487 10.8032 3.42931C11.0789 3.15376 11.4524 2.99855 11.8422 2.99763C12.232 2.99855 12.6057 3.15384 12.8814 3.42952C13.157 3.7052 13.3123 4.07884 13.3133 4.46871V8.15254Z"
      fill="#2EB67D"
    />
    <path
      d="M11.8422 14.0602C12.2319 14.0611 12.6055 14.2163 12.8812 14.4919C13.1568 14.7674 13.3122 15.1409 13.3133 15.5307C13.3125 15.9206 13.1573 16.2943 12.8818 16.5701C12.6062 16.8459 12.2326 17.0013 11.8428 17.0024C11.4528 17.0016 11.079 16.8464 10.8032 16.5707C10.5274 16.295 10.372 15.9213 10.3711 15.5313V14.0602H11.8422ZM11.8422 13.3185C11.4524 13.3176 11.0789 13.1624 10.8032 12.8869C10.5275 12.6113 10.3722 12.2378 10.3711 11.848C10.3719 11.4581 10.5271 11.0843 10.8028 10.8085C11.0785 10.5327 11.4522 10.3773 11.8422 10.3764H15.526C15.9159 10.3773 16.2895 10.5326 16.5652 10.8083C16.8409 11.084 16.9962 11.4576 16.9971 11.8475C16.9962 12.2373 16.8409 12.611 16.5652 12.8867C16.2895 13.1623 15.9159 13.3176 15.526 13.3185H11.8422Z"
      fill="#ECB22E"
    />
  </svg>
);

const DummyCredentialItem = styled.div`
  display: flex;
  align-items: center;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
  header {
    > * {
      display: block;
    }
    strong {
      font-weight: 600;
    }
    span {
      font-size: 1rem;
    }
  }
`;

const RightSidebar = () => (
  <RightSidebarWrap>
    <RightSidebarSectionHeader>
      <strong>Status</strong>
    </RightSidebarSectionHeader>
    <StatusInputSetFlex>
      <DummyInput>
        <StatusFlex>
          <svg width="16" height="16" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="7.5" fill="#D6EDD9" stroke="#B0E0BD" />
            <circle cx="8" cy="8" r="3" fill="#5ABE89" />
          </svg>
          <span>Enabled</span>
        </StatusFlex>
      </DummyInput>
      <DummyButton>Disable</DummyButton>
    </StatusInputSetFlex>

    <Separator />

    <RightSidebarSectionHeader>
      <strong>Name</strong>
    </RightSidebarSectionHeader>
    <DummyInput>Report triage</DummyInput>

    <Separator />

    <RightSidebarSectionHeader>
      <strong>Description</strong>
    </RightSidebarSectionHeader>
    <DummyTextarea>
      Triage reports through different sources including a Tines page for manual
      inputs, automated webhook notifications and hand-written reports received
      through email.
    </DummyTextarea>

    <Separator />

    <RightSidebarSectionHeader>
      <strong>Tags</strong>
    </RightSidebarSectionHeader>
    <DummyInput>
      <DummyTag>Report</DummyTag>
      <DummyTag>Triage</DummyTag>
      <DummyTag>App</DummyTag>
      <DummyTag>AI</DummyTag>
    </DummyInput>

    <Separator />

    <RightSidebarSectionHeader>
      <strong>Credentials</strong>
      <DummyButton className="compact">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none">
          <path d="M0 3.5H7M3.5 0V7" stroke="currentColor" />
        </svg>
      </DummyButton>
    </RightSidebarSectionHeader>

    <DummyCredentialItem>
      <SlackIcon />
      <header>
        <strong>Slack</strong>
        <span>1 action</span>
      </header>
    </DummyCredentialItem>
  </RightSidebarWrap>
);
