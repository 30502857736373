export const BrokenGuitarIcon = () => (
  <svg
    width="76"
    height="115"
    viewBox="0 0 76 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.9138 114.309C63.6204 114.309 74.7319 113.2 74.7319 111.831C74.7319 110.463 63.6204 109.353 49.9138 109.353C36.2071 109.353 25.0957 110.463 25.0957 111.831C25.0957 113.2 36.2071 114.309 49.9138 114.309Z"
      fill="#4C1812"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M60.0089 73.9259C51.97 67.8485 54.6627 63.3782 55.681 59.3114C58.4188 48.3781 45.9926 41.5204 34.0415 41.5204C22.0903 41.5204 9.66411 48.3781 12.4019 59.3114C13.4203 63.3782 16.1127 67.8483 8.07408 73.9259C3.55268 77.3443 1.2002 81.727 1.2002 86.7609C1.2002 98.8325 7.2682 113.286 34.0413 113.286C60.8145 113.286 66.8823 98.8325 66.8823 86.7609C66.8827 81.7268 64.5303 77.3441 60.0089 73.9259Z"
      fill="#E49307"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M34.0406 77.0539C38.6803 77.0539 42.4415 73.2989 42.4415 68.6669C42.4415 64.0349 38.6803 60.2799 34.0406 60.2799C29.4009 60.2799 25.6396 64.0349 25.6396 68.6669C25.6396 73.2989 29.4009 77.0539 34.0406 77.0539Z"
      fill="#BB781B"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M34.0404 79.8495C40.2266 79.8495 45.2415 74.8429 45.2415 68.667C45.2415 62.4911 40.2266 57.4845 34.0404 57.4845C27.8543 57.4845 22.8394 62.4911 22.8394 68.667C22.8394 74.8429 27.8543 79.8495 34.0404 79.8495Z"
      stroke="#FFC8A3"
      strokeMiterlimit="10"
    />
    <path
      d="M34.0408 82.8771C41.9018 82.8771 48.2745 76.515 48.2745 68.667C48.2745 60.819 41.9018 54.4569 34.0408 54.4569C26.1798 54.4569 19.8071 60.819 19.8071 68.667C19.8071 76.515 26.1798 82.8771 34.0408 82.8771Z"
      stroke="#FFC8A3"
      strokeMiterlimit="10"
    />
    <path
      d="M26.6297 72.686C26.6297 72.686 29.6847 61.3337 41.3956 63.8753C41.3956 63.8753 35.7627 57.3693 29.1755 62.0114C23.4049 66.0779 26.6297 72.686 26.6297 72.686Z"
      fill="#4C1812"
    />
    <path
      d="M47.8799 86.072H20.2017V92.0024H25.282C26.3207 93.3183 29.8479 94.2875 34.0409 94.2875C38.2338 94.2875 41.7608 93.3183 42.7996 92.0024H47.8799V86.072Z"
      fill="#C86735"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.0409 60.2807C36.0037 60.2807 37.8079 60.9545 39.2381 62.0806L37.6633 14.652H34.0409H30.4184L28.8438 62.0806C30.2739 60.9545 32.0779 60.2807 34.0409 60.2807Z"
      fill="#C86735"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.7083 3.55447C37.7083 2.64324 36.0664 1.11829 34.0414 1.11829C32.0165 1.11829 30.3746 2.64324 30.3746 3.55447H29.0415V17.3208C29.0415 17.3208 30.1828 17.8224 30.2386 18.5574C30.2949 19.301 30.3003 19.7354 30.3003 19.7354H34.0414H37.7826C37.7826 19.7354 37.788 19.301 37.8443 18.5574C37.8999 17.8224 39.0414 17.3208 39.0414 17.3208V3.55447H37.7083Z"
      fill="#C86735"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.0122 9.14636C27.973 9.14636 28.7519 8.36877 28.7519 7.40955C28.7519 6.45033 27.973 5.67273 27.0122 5.67273C26.0514 5.67273 25.2725 6.45033 25.2725 7.40955C25.2725 8.36877 26.0514 9.14636 27.0122 9.14636Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.0122 15.1611C27.973 15.1611 28.7519 14.3835 28.7519 13.4243C28.7519 12.4651 27.973 11.6875 27.0122 11.6875C26.0514 11.6875 25.2725 12.4651 25.2725 13.4243C25.2725 14.3835 26.0514 15.1611 27.0122 15.1611Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.8442 9.14636C41.805 9.14636 42.5839 8.36877 42.5839 7.40955C42.5839 6.45033 41.805 5.67273 40.8442 5.67273C39.8834 5.67273 39.1045 6.45033 39.1045 7.40955C39.1045 8.36877 39.8834 9.14636 40.8442 9.14636Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.8442 15.1611C41.805 15.1611 42.5839 14.3835 42.5839 13.4243C42.5839 12.4651 41.805 11.6875 40.8442 11.6875C39.8834 11.6875 39.1045 12.4651 39.1045 13.4243C39.1045 14.3835 39.8834 15.1611 40.8442 15.1611Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.4925 88.8567L36.4131 12.8605L38.2342 12.8041L40.3137 88.8003L38.4925 88.8567Z"
      fill="#FFE0CC"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.7331 88.6565C27.6735 88.4513 26.2964 83.5109 28.1464 72.9663C30.4776 59.6801 26.4108 49.8159 26.3691 49.7178L28.0476 49.01C28.2253 49.4294 32.3696 59.4383 29.9409 73.2801C28.1629 83.4135 29.4695 88.1037 29.483 88.1499L27.7331 88.6565Z"
      fill="#FFE0CC"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2452 39.6586L16.7573 37.9059C19.9904 37.0094 23.3816 35.5374 26.2791 30.8769C29.7229 25.3375 29.8597 13.0301 29.8606 12.9065L31.6826 12.9206C31.6783 13.4467 31.538 25.867 27.827 31.836C24.5915 37.0404 20.662 38.7111 17.2452 39.6586Z"
      fill="#FFE0CC"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.9873 89.1909L31.165 89.1794L31.2232 6.87646L33.0453 6.88796L32.9873 89.1909Z"
      fill="#FFE0CC"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9108 89.1909L34.7876 6.88796L36.6092 6.87646L36.7328 89.1794L34.9108 89.1909Z"
      fill="#FFE0CC"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.3859 9.05897C37.2598 9.05897 37.9681 8.35176 37.9681 7.47938C37.9681 6.60699 37.2598 5.89978 36.3859 5.89978C35.5121 5.89978 34.8037 6.60699 34.8037 7.47938C34.8037 8.35176 35.5121 9.05897 36.3859 9.05897Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.697 9.05897C32.5708 9.05897 33.2792 8.35176 33.2792 7.47938C33.2792 6.60699 32.5708 5.89978 31.697 5.89978C30.8231 5.89978 30.1147 6.60699 30.1147 7.47938C30.1147 8.35176 30.8231 9.05897 31.697 9.05897Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.5327 14.4418C38.4064 14.4418 39.1147 13.7347 39.1147 12.8625C39.1147 11.9902 38.4064 11.2831 37.5327 11.2831C36.659 11.2831 35.9507 11.9902 35.9507 12.8625C35.9507 13.7347 36.659 14.4418 37.5327 14.4418Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.6943 90.7031C29.6082 90.7031 30.3491 89.9635 30.3491 89.0511C30.3491 88.1387 29.6082 87.399 28.6943 87.399C27.7804 87.399 27.0396 88.1387 27.0396 89.0511C27.0396 89.9635 27.7804 90.7031 28.6943 90.7031Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.2583 90.7031C33.1722 90.7031 33.9131 89.9635 33.9131 89.0511C33.9131 88.1387 33.1722 87.399 32.2583 87.399C31.3444 87.399 30.6035 88.1387 30.6035 89.0511C30.6035 89.9635 31.3444 90.7031 32.2583 90.7031Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.8227 90.7031C36.7366 90.7031 37.4775 89.9635 37.4775 89.0511C37.4775 88.1387 36.7366 87.399 35.8227 87.399C34.9088 87.399 34.168 88.1387 34.168 89.0511C34.168 89.9635 34.9088 90.7031 35.8227 90.7031Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.3867 90.7031C40.3006 90.7031 41.0415 89.9635 41.0415 89.0511C41.0415 88.1387 40.3006 87.399 39.3867 87.399C38.4728 87.399 37.7319 88.1387 37.7319 89.0511C37.7319 89.9635 38.4728 90.7031 39.3867 90.7031Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.6699 14.4418C31.5436 14.4418 32.2519 13.7347 32.2519 12.8625C32.2519 11.9902 31.5436 11.2831 30.6699 11.2831C29.7962 11.2831 29.0879 11.9902 29.0879 12.8625C29.0879 13.7347 29.7962 14.4418 30.6699 14.4418Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
