export const IconTinesUniversityPurple = () => (
  <svg
    width="144"
    height="112"
    viewBox="0 0 144 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_633_224052)">
      <path
        d="M142.76 102.453H33.4551V110.696H133.842L142.76 102.453Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M47.9032 96.1825C47.9032 93.3463 45.6031 91.0469 42.7673 91.0469H27.0729C25.5623 91.0469 24.2037 91.6995 23.264 92.7379C22.4396 93.6492 15.9026 100.09 14.9692 101.05C14.0701 101.975 13.5176 103.238 13.5176 104.629V110.7H39.4821L47.9034 102.253L47.9032 96.1825Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M39.4821 110.699V104.628C39.4821 101.792 37.184 99.4922 34.3478 99.4922H18.6532C15.8164 99.4922 13.5176 101.792 13.5176 104.628V110.699H39.4821Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M38.166 101.193L46.4916 92.8359"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M87.6004 96.1825C87.6004 93.3463 85.301 91.0469 82.4646 91.0469H66.7706C65.2596 91.0469 63.9008 91.6995 62.9615 92.7379C62.1371 93.6492 55.6003 100.09 54.6665 101.05C53.7676 101.975 53.2148 103.238 53.2148 104.629V110.7H79.1798L87.6004 102.253V96.1825Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M79.1798 110.699V104.628C79.1798 101.792 76.8815 99.4922 74.0453 99.4922H58.3504C55.5139 99.4922 53.2148 101.792 53.2148 104.628V110.699H79.1798Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M77.8633 101.193L86.1893 92.8359"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M127.299 96.1825C127.299 93.3463 125 91.0469 122.164 91.0469H106.469C104.959 91.0469 103.6 91.6995 102.66 92.7379C101.836 93.6492 95.2995 100.09 94.3659 101.05C93.467 101.975 92.9141 103.238 92.9141 104.629V110.7H118.878L127.3 102.253L127.299 96.1825Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M118.878 110.699V104.628C118.878 101.792 116.581 99.4922 113.745 99.4922H98.0495C95.2131 99.4922 92.9141 101.792 92.9141 104.628V110.699H118.878Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M117.562 101.193L125.888 92.8359"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M43.3144 95.1601C43.3144 95.1698 43.3008 95.3251 42.9112 95.5807C42.5471 95.8195 41.9792 96.0585 41.222 96.2687C39.7161 96.6866 37.6004 96.953 35.2383 96.953C32.8763 96.953 30.7606 96.6866 29.2547 96.2687C28.4973 96.0585 27.9295 95.8195 27.5654 95.5807C27.1759 95.3251 27.1621 95.1698 27.1621 95.1601C27.1621 95.1504 27.1759 94.9951 27.5654 94.7397C27.9295 94.5007 28.4973 94.2617 29.2547 94.0516C30.7606 93.6336 32.8763 93.3672 35.2383 93.3672C37.6004 93.3672 39.7161 93.6336 41.222 94.0516C41.9792 94.2617 42.5471 94.5007 42.9112 94.7397C43.3008 94.9951 43.3144 95.1504 43.3144 95.1601Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M82.8561 95.1601C82.8561 95.1698 82.8423 95.3251 82.4528 95.5807C82.0887 95.8195 81.5207 96.0585 80.7634 96.2687C79.2574 96.6866 77.1416 96.953 74.7795 96.953C72.4174 96.953 70.3016 96.6866 68.7957 96.2687C68.0384 96.0585 67.4704 95.8195 67.1063 95.5807C66.7169 95.3251 66.7031 95.1698 66.7031 95.1601C66.7031 95.1504 66.7169 94.9951 67.1063 94.7397C67.4704 94.5007 68.0384 94.2617 68.7957 94.0516C70.3016 93.6336 72.4174 93.3672 74.7795 93.3672C77.1416 93.3672 79.2574 93.6336 80.7634 94.0516C81.5207 94.2617 82.0887 94.5007 82.4528 94.7397C82.8423 94.9951 82.8561 95.1504 82.8561 95.1601Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M122.397 95.1601C122.397 95.1698 122.383 95.3251 121.994 95.5807C121.63 95.8195 121.062 96.0585 120.304 96.2687C118.798 96.6866 116.683 96.953 114.321 96.953C111.958 96.953 109.843 96.6866 108.337 96.2687C107.579 96.0585 107.012 95.8195 106.647 95.5807C106.258 95.3251 106.244 95.1698 106.244 95.1601C106.244 95.1504 106.258 94.9951 106.647 94.7397C107.012 94.5007 107.579 94.2617 108.337 94.0516C109.843 93.6336 111.958 93.3672 114.321 93.3672C116.683 93.3672 118.798 93.6336 120.304 94.0516C121.062 94.2617 121.63 94.5007 121.994 94.7397C122.383 94.9951 122.397 95.1504 122.397 95.1601Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M22.3164 94.5813C22.3164 95.699 26.3724 96.6048 31.3751 96.6048C36.3775 96.6048 40.433 95.699 40.433 94.5813V42.9302C40.433 41.8127 36.3771 40.9062 31.3751 40.9062C26.3721 40.9062 22.3164 41.8123 22.3164 42.9302V94.5813Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M62.0156 94.5813C62.0156 95.699 66.0715 96.6048 71.0735 96.6048C76.0765 96.6048 80.1319 95.699 80.1319 94.5813V42.9302C80.1319 41.8127 76.0762 40.9062 71.0735 40.9062C66.0711 40.9062 62.0156 41.8123 62.0156 42.9302V94.5813Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M101.715 94.5813C101.715 95.699 105.77 96.6048 110.773 96.6048C115.775 96.6048 119.831 95.699 119.831 94.5813V42.9302C119.831 41.8127 115.775 40.9062 110.773 40.9062C105.77 40.9062 101.715 41.8123 101.715 42.9302V94.5813Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M31.3755 51.9799C28.0977 51.9799 25.1273 51.7383 22.9082 51.3483V47.3594H39.8838V51.3411C37.6603 51.7352 34.6735 51.9799 31.3755 51.9799Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M71.237 51.9799C67.959 51.9799 64.9887 51.7383 62.7695 51.3483V47.3594H79.7451V51.3411C77.5218 51.7352 74.5348 51.9799 71.237 51.9799Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M111.101 51.9799C107.823 51.9799 104.852 51.7383 102.633 51.3483V47.3594H119.608V51.3411C117.385 51.7352 114.398 51.9799 111.101 51.9799Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M44.1259 42.0739C44.1259 40.7128 38.4171 39.6094 31.3745 39.6094C24.3322 39.6094 18.623 40.7128 18.623 42.0739C18.623 42.8867 18.623 45.2259 18.623 46.0391C18.623 47.4001 24.3322 48.5039 31.3745 48.5039C38.4171 48.5039 44.1259 47.4005 44.1259 46.0391C44.1259 45.2262 44.1259 42.8867 44.1259 42.0739Z"
        fill="#5EBC89"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M44.1259 37.6446C44.1259 36.2834 38.4171 35.1797 31.3745 35.1797C24.3322 35.1797 18.623 36.2831 18.623 37.6446C18.623 38.4574 18.623 40.7966 18.623 41.6097C18.623 42.9708 24.3322 44.0742 31.3745 44.0742C38.4171 44.0742 44.1259 42.9708 44.1259 41.6097C44.1259 40.7966 44.1259 38.4572 44.1259 37.6446Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M31.3962 40.1087C38.4388 40.1087 44.1481 39.0054 44.1481 37.6442C44.1481 36.2831 38.4388 35.1797 31.3962 35.1797C24.3536 35.1797 18.6445 36.2831 18.6445 37.6442C18.6445 39.0054 24.3536 40.1087 31.3962 40.1087Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M83.8255 42.0739C83.8255 40.7128 78.1164 39.6094 71.0738 39.6094C64.0311 39.6094 58.3223 40.7128 58.3223 42.0739C58.3223 42.8867 58.3223 45.2259 58.3223 46.0391C58.3223 47.4001 64.0311 48.5039 71.0738 48.5039C78.1164 48.5039 83.8255 47.4005 83.8255 46.0391C83.8255 45.2262 83.8255 42.8867 83.8255 42.0739Z"
        fill="#FA975F"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M83.8255 37.6446C83.8255 36.2834 78.1164 35.1797 71.0738 35.1797C64.0311 35.1797 58.3223 36.2831 58.3223 37.6446C58.3223 38.4574 58.3223 40.7966 58.3223 41.6097C58.3223 42.9708 64.0311 44.0742 71.0738 44.0742C78.1164 44.0742 83.8255 42.9708 83.8255 41.6097C83.8255 40.7966 83.8255 38.4572 83.8255 37.6446Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M71.074 40.1087C78.1167 40.1087 83.8258 39.0054 83.8258 37.6442C83.8258 36.2831 78.1167 35.1797 71.074 35.1797C64.0314 35.1797 58.3223 36.2831 58.3223 37.6442C58.3223 39.0054 64.0314 40.1087 71.074 40.1087Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M123.523 42.0739C123.523 40.7128 117.814 39.6094 110.771 39.6094C103.729 39.6094 98.0195 40.7128 98.0195 42.0739C98.0195 42.8867 98.0195 45.2259 98.0195 46.0391C98.0195 47.4001 103.729 48.5039 110.771 48.5039C117.814 48.5039 123.523 47.4005 123.523 46.0391C123.523 45.2262 123.523 42.8867 123.523 42.0739Z"
        fill="#E269A4"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M123.523 37.6446C123.523 36.2834 117.814 35.1797 110.771 35.1797C103.729 35.1797 98.0195 36.2831 98.0195 37.6446C98.0195 38.4574 98.0195 40.7966 98.0195 41.6097C98.0195 42.9708 103.729 44.0742 110.771 44.0742C117.814 44.0742 123.523 42.9708 123.523 41.6097C123.523 40.7966 123.523 38.4572 123.523 37.6446Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M110.771 40.1087C117.814 40.1087 123.523 39.0054 123.523 37.6442C123.523 36.2831 117.814 35.1797 110.771 35.1797C103.729 35.1797 98.0195 36.2831 98.0195 37.6442C98.0195 39.0054 103.729 40.1087 110.771 40.1087Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M118.094 38.5095L121.006 36.6328L122.566 37.7221C122.396 37.8088 122.179 37.8933 121.914 37.9725C121.229 38.1778 120.339 38.3162 119.434 38.4067C118.975 38.4528 118.518 38.4858 118.094 38.5095Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.1553 28.7188L15.8691 31.1833V34.0383C15.8691 36.8745 18.1677 39.1739 21.0039 39.1739H116.097C117.088 39.1739 118.012 38.892 118.795 38.4051C119.481 37.9784 123.094 36.1154 124.001 35.216C124.938 34.2866 125.518 32.9985 125.518 31.5736V28.7188H20.1553Z"
        fill="#483C72"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.8691 31.1875V34.0427C15.8691 36.8789 18.1677 39.1783 21.0039 39.1783H116.097C118.933 39.1783 121.232 36.8786 121.232 34.0427V31.1875H15.8691Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M125.409 28.7191L72.5467 1.35938L19.8506 28.7191L16.002 31.1836H121.225L125.409 28.7191Z"
        fill="#8D75E6"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M121.225 31.1876H16.002L68.3623 3.82812L121.225 31.1876Z"
        fill="#D7C4FA"
        stroke="#483C72"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_633_224052">
        <rect
          width="144"
          height="111.724"
          fill="white"
          transform="translate(0 0.140625)"
        />
      </clipPath>
    </defs>
  </svg>
);
