import { styled } from "@linaria/react";
import React, { ReactNode, useRef } from "react";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";

interface Props {
  inline?: boolean | undefined;
  children: ReactNode[];
}

const InlineStyled = styled.code`
  background-color: ${withOpacity(colorsV4.grey, 0.05)};
  border-radius: 5px;
  padding: 2px 4px;
  overflow: hidden;
`;

const NonInlineCode = styled.code`
  overflow: hidden;
`;

export default function Code({ inline = false, children }: Props) {
  const ref = useRef<HTMLElement>(null);

  const props = { ref, children };

  return inline ? <InlineStyled {...props} /> : <NonInlineCode {...props} />;
}
