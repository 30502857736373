import { styled } from "@linaria/react";
import { DatoCmsPerson } from "../../../graphql-types";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PersonFullName from "../general/PersonFullName";
import PersonPhotoIcon, {
  PersonPhotoIconContainer,
} from "../general/PersonPhotoIcon";
import Spacing from "../layout/Spacing";

type Props = {
  authors: DatoCmsPerson[];
  showAuthorWorkTitle?: boolean | null;
};

const SingleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${PersonPhotoIconContainer} {
    flex-shrink: 0;
    + ${PersonPhotoIconContainer} {
      margin-left: -0.35em;
    }
  }
  strong {
    font-weight: 500;
  }
`;

const Label = styled.span`
  display: block;
  line-height: 1.25;
`;

const AuthorTitleDisplay = styled.span`
  display: block;
  opacity: 0.6;
`;

const Multiline = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  font-size: 1.4rem;
  margin-top: 1em;
  ${fromTabletMd} {
    grid-template-columns: 1fr 1fr;
  }
  > * {
    background-color: ${colors.purple50};
    color: ${colors.purple700};
    border-radius: 1em;
    padding: 1em;
    strong {
      font-weight: 700;
    }
  }
`;

const AuthorDisplay = (props: Props) => {
  const firstAuthor = props.authors[0];
  const renderInCompactStyle =
    props.authors.length === 1 || !props.showAuthorWorkTitle;
  const Compact = () => (
    <SingleLine {...props}>
      {props.authors.map(author => (
        <PersonPhotoIcon
          person={author}
          key={author.id}
          size={
            props.authors.length > 1
              ? "2.5em"
              : props.showAuthorWorkTitle
              ? "3.75em"
              : "3em"
          }
        />
      ))}
      <Spacing size=".75em" />
      <Label>
        Written by{" "}
        {props.authors.map((author, i, array) => (
          <span key={author.id}>
            <strong>
              <PersonFullName person={author} />
            </strong>
            {array.length > 0 && i !== array.length - 1
              ? i === array.length - 2
                ? " and "
                : ", "
              : ""}
          </span>
        ))}
        {props.showAuthorWorkTitle && (
          <AuthorTitleDisplay>
            {[firstAuthor.title, firstAuthor.organization?.name]
              .filter(i => i)
              .join(", ")}
          </AuthorTitleDisplay>
        )}
      </Label>
    </SingleLine>
  );
  const Detailed = () => (
    <Multiline>
      <Grid>
        {props.authors.map(a => (
          <SingleLine key={a.id}>
            <PersonPhotoIcon person={a} key={a.id} size="3.5em" />
            <Spacing size=".75em" />
            <Label>
              <strong>
                <PersonFullName person={a} />
              </strong>
              {props.showAuthorWorkTitle && (
                <AuthorTitleDisplay>
                  {[a.title, a.organization?.name].filter(i => i).join(", ")}
                </AuthorTitleDisplay>
              )}
            </Label>
          </SingleLine>
        ))}
      </Grid>
    </Multiline>
  );
  return renderInCompactStyle ? <Compact /> : <Detailed />;
};

export default AuthorDisplay;
