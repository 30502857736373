import { styled } from "@linaria/react";
import { uptoTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";

const FromTabletMd = styled.div`
  ${uptoTabletMd} {
    display: none;
  }
`;
export const FromTabletMdSpan = styled.span`
  ${uptoTabletMd} {
    display: none;
  }
`;

export default FromTabletMd;
