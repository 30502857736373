import { styled } from "@linaria/react";
import {
  fromDesktopLg,
  fromDesktopMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import { zIndex } from "../../styles/zIndexes.styles";

type Props = React.PropsWithChildren<{
  color?: string | BrandColorNameV4;
}>;

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
  padding: 0 0.3em;
  vertical-align: middle;
  svg {
    display: block;
    cursor: help;
    margin: 0;
  }
  &:hover {
    .TooltipPopover {
      display: block;
    }
  }
`;

const Popover = styled.div`
  position: absolute;
  white-space: normal;
  display: none;
  width: 16em;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background-color: ${colorsV4.warmBlack};
  color: ${colorsV4.white};
  font-size: 1.1rem;
  padding: 0.75em 1em 1em;
  ${fromDesktopMd} {
    padding: 1em;
    font-size: 1.2rem;
  }
  ${fromDesktopLg} {
    font-size: 1.4rem;
  }
  border-radius: 0.5em;
  line-height: 1.375;
  border: 1px solid ${withOpacity(colorsV4.white, 0.1)};
  z-index: ${zIndex("Tooltip")};
  p {
    line-height: inherit;
  }
  ul {
    margin: 0;
    padding: 0 0 0 0.75em;
    list-style: "•  ";
  }
  a {
    color: ${colorsV4.purple300};
    text-decoration: none;
    &:hover {
      color: ${colorsV4.purple100};
      text-decoration: underline;
      text-decoration-color: ${withOpacity(colorsV4.purple300, 0.6)};
    }
  }
`;

const InfoTooltip = (props: Props) => {
  return (
    <TooltipContainer>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 6.5C13 2.90964 10.0904 0 6.5 0C2.90964 0 0 2.90964 0 6.5C0 10.0904 2.90964 13 6.5 13C10.0904 13 13 10.0904 13 6.5ZM7.5 4C7.5 4.55228 7.05228 5 6.5 5C5.94772 5 5.5 4.55228 5.5 4C5.5 3.44772 5.94772 3 6.5 3C7.05228 3 7.5 3.44772 7.5 4ZM6.5 6.25C6.91421 6.25 7.25 6.58579 7.25 7V9C7.25 9.41421 6.91421 9.75 6.5 9.75C6.08579 9.75 5.75 9.41421 5.75 9V7C5.75 6.58579 6.08579 6.25 6.5 6.25Z"
          fill={colorV4(props.color ?? colorsV4.purple)}
        />
      </svg>

      <Popover className="TooltipPopover">{props.children}</Popover>
    </TooltipContainer>
  );
};

export default InfoTooltip;
