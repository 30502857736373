import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import { colors, withOpacity } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";

type Props = {
  className?: string;
  f: string;
  inline?: boolean;
  animate?: boolean;
  animationOffset?: number;
  type?: "formulas" | "tag" | "value";
};

export const FormulasPillInner = styled.span`
  display: flex;
  position: relative;
  align-items: center;
  font-size: 85%;
  white-space: nowrap;
  > * {
    flex: 0 0 auto;
  }
  i {
    padding: 0.5em 0.25em 0.5em 0.5em;
  }
  svg {
    display: block;
    width: 1em;
    height: 1em;
  }
  code {
    padding: 0.5em 0.5em 0.5em 0.25em;
  }
`;

export const FormulasPillContainer = styled.span<Props>`
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  border-radius: 0.5em;
  overflow: hidden;
  opacity: ${p => (p.animate ? 0 : 1)};
  max-width: 100%;
  @keyframes FormulaPillOuterEnter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation-name: FormulaPillOuterEnter;
  animation-duration: ${p => (p.animate ? "1s" : "0")};
  animation-delay: ${p => `${(p.animationOffset ?? 0) / 10}s`};
  animation-fill-mode: forwards;
  color: ${colors.purple600};
  ${darkModeLinariaCSS(`color: ${colors.purple400};`)}
  border: 1px solid ${withOpacity(colors.purple, 0.3)};
  &.inline {
    vertical-align: middle;
    font-size: inherit;
    padding-top: 0em;
    padding-bottom: 0em;
    display: inline;
    background-color: transparent;
    border: 0;
    ${FormulasPillInner} {
      display: inline;
      white-space: normal;
      font-size: inherit;
    }
    i {
      padding: 0.1em 0.1em 0.1em 0.2em;
      background-color: ${withOpacity(colors.purple, 0.2)};
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: ${withOpacity(colors.purple, 0.2)};
      border-radius: 0.4em 0 0 0.4em;
    }
    svg {
      width: 1.25em;
      height: 1.25em;
      display: inline-block;
      transform: translateY(0.2em);
    }
    code {
      font-size: inherit;
      padding: 0.1em 0.2em;
      font-weight: 600;
      border-width: 1px 1px 1px 0;
      border-style: solid;
      border-color: ${withOpacity(colors.purple, 0.2)};
      background-color: ${withOpacity(colors.purple, 0.2)};
      border-radius: 0 0.4em 0.4em 0;
    }
  }
  &:not(.inline) {
    &:before {
      content: "";
      position: absolute;
      ${cover()}
      background-color: ${colors.purple};
      opacity: 0.1;
      @keyframes FormulaPillInnerColorAnimation {
        0% {
          opacity: 0.1;
        }
        30% {
          opacity: 0.1;
        }
        40% {
          opacity: 0.3;
        }
        70% {
          opacity: 0.3;
        }
        80% {
          opacity: 0.1;
        }
        100% {
          opacity: 0.1;
        }
      }
      animation-name: FormulaPillInnerColorAnimation;
      animation-duration: ${p => (p.animate ? "5s" : "0")};
      animation-delay: ${p => `${p.animationOffset ?? 0}s`};
      animation-iteration-count: infinite;
    }
  }
`;

const icons = {
  formulas: (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor">
      <path d="M8.93084 3.30005C8.43743 3.30005 8.03034 3.63606 7.93929 4.08926L7.93021 4.15033L7.67025 5.90002H9.39999V6.90002H7.52168L7.06141 9.99793L7.0611 10.0001L7.06109 10C6.91189 10.9755 6.06804 11.7 5.06924 11.7H5.05879L5.0588 11.6999C4.54002 11.6891 4.02983 11.4936 3.64954 11.1198L4.35055 10.4067C4.53312 10.5861 4.79126 10.6929 5.07441 10.7C5.58016 10.6976 5.99829 10.3315 6.07244 9.8498L6.07259 9.84885L6.51071 6.90002H5.19999V5.90002H6.65928L6.92086 4.13941L6.93924 4.00771C7.07841 3.01092 7.93982 2.30005 8.93084 2.30005C9.458 2.30005 9.97404 2.49889 10.3558 2.88554L9.64425 3.58815C9.46204 3.40361 9.20768 3.30005 8.93084 3.30005Z" />
    </svg>
  ),
  value: (
    <svg
      viewBox="0 0 12 12"
      width="12"
      height="12"
      stroke="currentColor"
      strokeLinejoin="round"
      fill="transparent"
    >
      <path d="M5 2H4.5C3.39543 2 2.5 2.89543 2.5 4V6M2.5 6H0M2.5 6V8C2.5 9.10457 3.39543 10 4.5 10H5" />
      <path d="M7 10L7.5 10C8.60457 10 9.5 9.10457 9.5 8L9.5 6M9.5 6L12 6M9.5 6L9.5 4C9.5 2.89543 8.60457 2 7.5 2L7 2" />
    </svg>
  ),
  tag: (
    <svg
      viewBox="0 0 12 12"
      width="12"
      height="12"
      stroke="currentColor"
      strokeLinecap="round"
      fill="transparent"
    >
      <path d="M2 10L10 2" />
      <circle cx="3" cy="3" r="1.5" />
      <circle cx="9" cy="9" r="1.5" />
    </svg>
  ),
};

const FormulasPill = (props: Props) => {
  const content = () => {
    try {
      return `${JSON.parse(`"${props.f}"`)}`;
    } catch (e) {
      return props.f;
    }
  };
  return (
    <FormulasPillContainer
      {...props}
      className={cx(props.className, props.inline && "inline")}
    >
      <FormulasPillInner>
        <i>{icons[props.type ?? "formulas"]}</i>
        <code>{content()}</code>
      </FormulasPillInner>
    </FormulasPillContainer>
  );
};

export default FormulasPill;
