import React, { useState } from "react";
import FullscreenVideoEmbedLightbox from "../basic/FullscreenVideoEmbedLightbox";
import Button, { ButtonAppearance } from "../forms/Button";

type Props = {
  src: string;
  buttonColor?: string;
  buttonLabel?: string;
  buttonWidth?: string | number;
  buttonAppearance?: ButtonAppearance;
};

const WatchVideoCTAAndFrame = (props: Props) => {
  const [showVideoFrame, setShowVideoFrame] = useState(false);
  return (
    <>
      <Button
        onClick={() => setShowVideoFrame(!showVideoFrame)}
        appearance={props.buttonAppearance}
        color={props.buttonColor}
        width={props.buttonWidth}
      >
        {props.buttonLabel ?? "Watch the film"}
      </Button>
      {showVideoFrame && (
        <FullscreenVideoEmbedLightbox
          onShouldClose={() => setShowVideoFrame(false)}
        >
          <iframe
            src={props.src}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </FullscreenVideoEmbedLightbox>
      )}
    </>
  );
};

export default WatchVideoCTAAndFrame;
