import { styled } from "@linaria/react";
import { css } from "linaria";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import SpotIllustration from "../illustrations/SpotIllustration";
import { Paths } from "../../utils/pathBuilders.utils";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  align-items: stretch;
  color: ${colorsV4.warmBlack};
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const LinkStyle = css`
  background-color: ${colorsV4.canvas550};
  text-decoration: none;
  border-radius: 1.5em;
  display: flex;
  padding: 1em;
  > div {
    display: flex;
    align-items: center;
  }
  ${fromTabletMd} {
    padding: 1.5em;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    text-align: center;
    > div {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  ${fromDesktopMd} {
    aspect-ratio: 1/1;
    justify-content: center;
  }
  img {
    width: 72px;
    height: 72px;
    ${uptoTablet} {
      margin-right: 0.5em;
    }
    ${fromTablet} {
      width: 100px;
      height: 100px;
    }
    ${fromDesktopMd} {
      width: 120px;
      height: 120px;
    }
  }
  h3 {
    font-size: 1.8rem;
    margin-bottom: 0.25em;
    ${fromTabletMd} {
      font-size: 2rem;
      margin-top: 0.75em;
      margin-bottom: 0.5em;
    }
  }
  p {
    font-size: 1.4rem;
    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SupportAndServicesGrid = () => {
  return (
    <Grid>
      <a
        className={LinkStyle}
        target="_blank"
        href="/hub"
        style={{
          backgroundColor: colorsV4.purple50,
        }}
      >
        <div>
          <SpotIllustration name="university" />
          <div>
            <h3>University</h3>
            <p>
              Build your Tines knowledge by going through our dedicated learning
              paths.
            </p>
          </div>
        </div>
      </a>
      <a
        className={LinkStyle}
        target="_blank"
        href="/library"
        style={{
          backgroundColor: colorsV4.yellow50,
        }}
      >
        <div>
          <SpotIllustration
            name="storyLibrary"
            className={css`
              ${fromTabletMd} {
                transform: translateY(2%);
              }
            `}
          />
          <div>
            <h3>Tines Library</h3>
            <p>
              Hundreds of ready-made automation workflows that you can use in
              one click.
            </p>
          </div>
        </div>
      </a>
      <a
        className={LinkStyle}
        href={Paths.slackCommunity()}
        style={{
          backgroundColor: colorsV4.orange50,
        }}
        {...externalLinkAttr}
      >
        <div>
          <SpotIllustration
            name="slackCommunity"
            className={css`
              ${fromTabletMd} {
                transform: translateY(7%);
              }
            `}
          />
          <div>
            <h3>Slack Community</h3>
            <p>
              Post questions, discuss techniques, and get all the latest news
              about Tines.
            </p>
          </div>
        </div>
      </a>
    </Grid>
  );
};

export default SupportAndServicesGrid;
