import { styled } from "@linaria/react";
import { PropsWithChildren, useRef, useState } from "react";
import {
  fromDesktop,
  fromTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import FormulasPill from "../../general/FormulasPill";
import gsap from "gsap";
import { debounce } from "../../../utils/debounce.utils";
import { cx } from "linaria";

const FormulaPillRowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  > * {
    + * {
      margin-left: var(--FormulaPillKaleidoscopeGap, 14px);
    }
  }
`;

const FormulaPillRowInnerSet = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  > * {
    + * {
      margin-left: var(--FormulaPillKaleidoscopeGap, 14px);
    }
  }
`;

const FormulaPillRow = (
  props: PropsWithChildren<{
    backward?: boolean;
  }>
) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const [timeline, setTimeline] = useState<ReturnType<typeof gsap.timeline>>();
  useOnMount(() => {
    const handleResize = debounce(
      () => {
        if (!innerRef.current) return;
        const direction = props.backward ? -1 : 1;
        const width = (innerRef.current?.clientWidth ?? 0) + 14;
        const duration = width / 5;
        if (timeline) timeline.kill();
        const tl = gsap.timeline({ repeat: -1 });
        setTimeline(tl);
        tl.fromTo(
          parentRef.current,
          { x: 0 },
          {
            duration,
            ease: "linear",
            x: width * direction,
          }
        );
      },
      { fireImmediately: true }
    );
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      timeline?.kill();
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <FormulaPillRowContainer ref={parentRef}>
      <FormulaPillRowInnerSet>{props.children}</FormulaPillRowInnerSet>
      <FormulaPillRowInnerSet ref={innerRef}>
        {props.children}
      </FormulaPillRowInnerSet>
      <FormulaPillRowInnerSet>{props.children}</FormulaPillRowInnerSet>
    </FormulaPillRowContainer>
  );
};

const FormulaPillKaleidoscopeContainer = styled.div`
  position: relative;
  color: ${colors.purple};
  font-size: 1.4rem;
  ${fromTablet} {
    font-size: 1.6rem;
  }
  ${fromDesktop} {
    font-size: 1.8rem;
  }
`;

const FormulaPillRows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  > * {
    + * {
      margin-top: var(--FormulaPillKaleidoscopeGap, 14px);
    }
  }
`;

export const FormulaPillKaleidoscope = (props: {
  className?: string;
  shadeColor?: string;
  alwaysShowShades?: boolean;
  rows?: 3 | 4;
}) => {
  return (
    <FormulaPillKaleidoscopeContainer className={props.className}>
      <FormulaPillRows>
        <FormulaPillRow>
          <FormulasPill
            animate
            animationOffset={3}
            f="REVERSE(all_incident_reports)"
          />
          <FormulasPill
            animate
            animationOffset={1.25}
            f='JSONPATH(request.form, "$.phoneNumbers[*].type")'
          />
          <FormulasPill animate animationOffset={3.5} f="MD5(fetched_data)" />
          <FormulasPill
            animate
            animationOffset={0.75}
            f='SORT(incident.tags, "name")'
          />
          <FormulasPill
            animate
            animationOffset={2.5}
            f='MAP(ip_reports, "deliverable")'
          />
        </FormulaPillRow>

        <FormulaPillRow backward>
          <FormulasPill
            animate
            animationOffset={0.5}
            f="IF(AND(creation.status = 200, …)"
          />
          <FormulasPill
            animate
            animationOffset={3.25}
            f="TRUNCATE(error.message, 8)"
          />
          <FormulasPill
            animate
            animationOffset={1.5}
            f="FIND(ARRAY(r,g,b), LAMBDA(value, VALUE > 128))"
          />
          <FormulasPill
            animate
            animationOffset={0.25}
            f="TRUNCATE(alert_payload.message, 8)"
          />
        </FormulaPillRow>

        <FormulaPillRow>
          <FormulasPill
            animate
            animationOffset={2}
            f="MODULO(numerator, denominator)"
          />
          <FormulasPill
            animate
            animationOffset={1}
            f='OBJECT("parsed", PARSE_CSV(upload))'
          />
          <FormulasPill
            animate
            animationOffset={4}
            f='ZIP(download_from_s3.result, "attachments.zip")'
          />
          <FormulasPill
            animate
            animationOffset={1.75}
            f='DEFAULT(form.body.slack_channel_name, "general")'
          />
        </FormulaPillRow>

        {props.rows && props.rows >= 4 && (
          <FormulaPillRow backward>
            <FormulasPill animate animationOffset={1.5} f="EML_PARSE(text)" />
            <FormulasPill
              animate
              animationOffset={0.5}
              f="PARSE_URL(my_action.url)"
            />
            <FormulasPill
              animate
              animationOffset={2.25}
              f="URL_DECODE(my_action.message)"
            />
            <FormulasPill
              animate
              animationOffset={0.25}
              f='WHERE(get_all_alarms.body.alarms, "classification", "malware")'
            />
          </FormulaPillRow>
        )}
      </FormulaPillRows>

      {(props.shadeColor || props.alwaysShowShades) && (
        <Shades className={cx(props.alwaysShowShades && "alwaysShow")}>
          <Shade left shadeColor={props.shadeColor ?? colors.darkest} />
          <Shade right shadeColor={props.shadeColor ?? colors.darkest} />
        </Shades>
      )}
    </FormulaPillKaleidoscopeContainer>
  );
};

const Shades = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
  }
  &.alwaysShow {
    display: block;
  }
`;

const Shade = styled.span<{
  left?: boolean;
  right?: boolean;
  shadeColor: string;
}>`
  display: block;
  position: absolute;
  top: 0;
  left: ${p => (p.left ? "-1px" : "auto")};
  bottom: 0;
  right: ${p => (p.right ? "-1px" : "auto")};
  width: 4em;
  background-image: ${p =>
    `linear-gradient(to ${p.left ? "right" : "left"}, ${
      p.shadeColor
    }, ${withOpacity(p.shadeColor, 0)})`};
`;
