import PillLegacy from "../../basic/PillLegacy";
import ReportCard from "../ReportCard";
import ReportSection from "../ReportSection";
import { Serif } from "../../typography/Serif";
import SpotIllustration from "../../illustrations/SpotIllustration";
import ReportSummaryCard from "../ReportSummaryCard";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import ColumnedAndAvoidBreaksInParagraphs, {
  Columned,
} from "../../layout/Columned";

const NoCodePlaybookChapter2 = () => {
  return (
    <ReportSection
      id="how-to-build-a-no-code-security-automation-program"
      themeName="orange"
      smallerSectionHeading
      header={
        <>
          <div>
            <SpotIllustration name="sevenBuildingBlocksSquares" />
          </div>
          <PillLegacy color="orange">Part Two</PillLegacy>
          <h2>
            How to build a no-code automation program{" "}
            <Serif>for your security team</Serif>
          </h2>
          <p>
            Now that you've seen that no-code automation is the next step to
            maximizing your efficiency and improving your security team, here
            are the 5 steps to follow for getting up and running, and making the
            most out of your new platform.
          </p>
        </>
      }
    >
      <ReportCard largerHeading id="evaluate-your-options">
        <PillLegacy color="orange">Step 1</PillLegacy>
        <h3>Evaluate your options</h3>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            As you begin searching for the right platform, look for vendors who
            have experience in solving your specific use cases. For example, if
            you spend most of your time following up on suspicious logins, and
            they don't make any mention of how to automate that use case, take a
            look elsewhere.
          </p>
          <p>
            Additionally, ask how the platform integrates with your in-house
            APIs. Legacy automation platforms typically feature pre-baked
            integration, but only for a limited number of popular tools. Seek
            out a platform that has the ability to integrate with all of your
            organization’s tools, no matter how niche or custom they may be.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
      </ReportCard>

      <ReportCard largerHeading>
        <PillLegacy color="orange">Step 2</PillLegacy>
        <h3>Run a POC process</h3>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            When it comes time to demo, don't pick a simplified workflow, but
            ask the vendor to run a more complex one that more closely mimics
            what types of tasks you want to automate in the future — a good
            vendor will be excited by the challenge!
          </p>
          <p>
            Platforms should be robust enough to automate complex, lengthy
            workflows, yet many of the automation platforms that sell themselves
            as “powerful” have surprisingly low operational limits. Leverage
            free community editions and trials to put platforms to the test.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
      </ReportCard>

      <ReportCard largerHeading>
        <PillLegacy color="orange">Step 3</PillLegacy>
        <h3>Purchase the best tool for the best price</h3>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            As you explore options, consider the pricing model (e.g., data
            ingestion or storage rates) and not just the price tag to get
            started. And be sure to ask how pricing will change as usage
            increases, as many security vendors often make their pricing opaque.
          </p>
          <p>
            Committing to no-code automation means scaling the number, size, and
            throughput of workflows, and with increased usage, you need to know
            what to expect to pay. You want a model that will encourage as many
            team members as possible to be involved, without worrying about
            hitting a data cap or a user license limit.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
      </ReportCard>

      <ReportCard largerHeading>
        <PillLegacy color="orange">Step 4</PillLegacy>
        <h3>Build workflows iteratively</h3>
        <Columned>
          <p>
            Once you have your no-code automation platform up and running, the
            best approach is to start small with prototypes and MVPs, and then
            keep evolving the complexity.
          </p>
          <p>
            Deploy the simplest usable version to production first, and then
            expand workflows little by little to cover edge and corner cases.
            This also allows analysts to become more creative with their
            automation, building more sophisticated processes as they go.
          </p>
        </Columned>
      </ReportCard>

      <ReportCard largerHeading>
        <PillLegacy color="orange">Step 5</PillLegacy>
        <h3>Deployment is only the beginning</h3>
        <Columned>
          <p>
            Because of the accessibility of no-code automation, security
            analysts can keep maintaining and evolving their workflows in
            production, and iterating those workflows as their company’s
            processes and threats continuously change. One thing to remember is
            not to price the maintenance of automation at zero. Even if it’s
            built flawlessly the first time around — which is rare — external
            context will always change, necessitating future iteration.
          </p>
        </Columned>
      </ReportCard>

      <ReportSummaryCard
        backgroundColor={colorsV4.orange700}
        textColor={colorsV4.white}
      >
        <blockquote>
          <p>
            Following these five steps can give you a clear path to success with
            your no-code automation program. Next, we’ll take a look at the
            common misconceptions we’ve seen security organizations face as they
            bring the power of no-code automation to their team.
          </p>
        </blockquote>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default NoCodePlaybookChapter2;
