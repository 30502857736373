import { styled } from "@linaria/react";
import { cx } from "linaria";
import { clamp } from "lodash-es";
import { cover } from "polished";
import { useRef, useState } from "react";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import Point, { RectDef, XY } from "../../utils/geometry.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { runAfter, when } from "../../utils/promises.utils";
import {
  getAverageXYFromTouchEvent,
  getMultiFingerTouchEventRect,
  getZoomDeltaFromTouchRects,
} from "../../utils/touch.utils";
import { useVisibilityObserver } from "../../utils/useSizeAndVisibilityObserver.utils";
import LoadingIndicator from "../utilities/LoadingIndicator";
import Storyboard from "./Storyboard";
import { STORYBOARD_BG_COLOR } from "./utils/storyboard.utils";
import {
  StoryboardContextValue,
  useStoryboardContext,
} from "./StoryboardContext";

const StoryboardViewportContainer = styled.div`
  position: absolute;
  ${cover()}
  overflow: hidden;
  background-color: ${STORYBOARD_BG_COLOR.lightMode};
  &.dragPanEnabled {
    touch-action: none;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  ${darkModeLinariaCSS(`
    background-color: ${STORYBOARD_BG_COLOR.darkMode};
  `)}
`;

const LoadingStateWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
`;

const StoryboardViewport = (props: {
  onReady?: (context: StoryboardContextValue) => void;
}) => {
  const context = useStoryboardContext();

  const [storyboardMounted, setStoryboardMounted] = useState(false);
  const storyboardMountedRef = useRef(storyboardMounted);
  storyboardMountedRef.current = storyboardMounted;

  const [isInteracting, setIsInteracting] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<XY>({ x: 0, y: 0 });
  const cursorPositionRef = useRef(cursorPosition);
  cursorPositionRef.current = cursorPosition;

  const ref = useRef<HTMLDivElement>(null);

  const handleWheel = (e: WheelEvent) => {
    if (e.metaKey || e.ctrlKey) {
      if (!context.options.enableManualZoom) return;
      e.preventDefault();
      context.setZoom(clamp(context.zoom - e.deltaY / 100, 0.1, 10));
    } else {
      if (!context.options.enableWheelPan) return;
      e.preventDefault();
      const newFocus = Point.add(context.focus, {
        x: e.deltaX / context.zoom,
        y: e.deltaY / context.zoom,
      });
      if (newFocus.x < -context.viewport.width * 0.75)
        newFocus.x = -context.viewport.width * 0.75;
      if (newFocus.y < -context.viewport.height * 0.75)
        newFocus.y = -context.viewport.height * 0.75;
      if (newFocus.x > context.storyboard.width - context.viewport.width * 0.25)
        newFocus.x = context.storyboard.width - context.viewport.width * 0.25;
      if (
        newFocus.y >
        context.storyboard.height - context.viewport.height * 0.25
      )
        newFocus.y = context.storyboard.height - context.viewport.height * 0.25;
      context.setFocus(newFocus);
    }
  };
  const handleMouseDown = (e: React.MouseEvent) => {
    if (!context.options.enableDragPan) return;
    setIsInteracting(true);
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };
  const handleMouseMove = (e: React.MouseEvent) => {
    if (isInteracting) {
      const newPosition: XY = { x: e.clientX, y: e.clientY };
      const delta = Point.multiply(
        Point.subtract(newPosition, cursorPositionRef.current),
        1 / context.zoom
      );
      context.setFocus(Point.subtract(context.focus, delta));
      setCursorPosition(newPosition);
    }
  };
  const handleMouseUp = () => {
    setIsInteracting(false);
  };
  const handleDoubleClick = () => {
    context.resetView(true);
  };
  useOnMount(() => {
    let prevTouchXY: XY | null = null;
    let prevMultiFingerTouchRect: RectDef | null = null;

    const handleTouchStart = (e: TouchEvent) => {
      const currXY = getAverageXYFromTouchEvent(e);
      prevTouchXY = currXY;
      if (e.touches.length > 1) {
        prevMultiFingerTouchRect = getMultiFingerTouchEventRect(e);
      } else {
        prevMultiFingerTouchRect = null;
      }
      window.addEventListener("touchmove", handleTouchMove);
      window.addEventListener("touchend", handleTouchEnd);
      window.addEventListener("touchcancel", handleTouchEnd);
      window.addEventListener("blur", handleTouchEnd);
    };
    const handleTouchMove = (e: TouchEvent) => {
      const currXY = getAverageXYFromTouchEvent(e);
      if (currXY.x === 0) return;
      e.preventDefault();

      if (e.touches.length > 1) {
        if (!context.options.enableManualZoom) return;
        const currMultiFingerTouchRect = getMultiFingerTouchEventRect(e);
        if (prevMultiFingerTouchRect) {
          const zoomDelta = getZoomDeltaFromTouchRects(
            currMultiFingerTouchRect,
            prevMultiFingerTouchRect
          );
          context.zoomTo(context.zoom + zoomDelta);
        }
        prevMultiFingerTouchRect = currMultiFingerTouchRect;
      }

      if (prevTouchXY) {
        if (!context.options.enableDragPan) return;
        const wasMultiFingerTouchButNowOnlyOneFingerLeft =
          prevMultiFingerTouchRect && e.touches.length === 1;
        if (!wasMultiFingerTouchButNowOnlyOneFingerLeft) {
          const positionDelta = Point.round(
            Point.multiply(
              Point.subtract(currXY, prevTouchXY),
              1 / context.zoom
            ),
            3
          );
          context.panToPosition(Point.subtract(context.focus, positionDelta));
        }
      }

      prevTouchXY = currXY;
    };
    const handleTouchEnd = () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
    window.addEventListener("blur", handleMouseUp);
    ref.current?.addEventListener("wheel", handleWheel, { passive: false });
    ref.current?.addEventListener("touchstart", handleTouchStart);
    return () => {
      window.removeEventListener("blur", handleMouseUp);
      ref.current?.removeEventListener("wheel", handleWheel);
      ref.current?.removeEventListener("touchstart", handleTouchStart);
    };
  });
  useVisibilityObserver(ref, {
    onBecomeVisible: () => {
      when(
        () => storyboardMountedRef.current,
        () => {
          runAfter(() => {
            context.setReady();
            props.onReady?.(context);
          }, context.options.delay);
        }
      );
    },
  });
  return (
    <StoryboardViewportContainer
      className={cx(
        "StoryboardViewport",
        context.options.enableDragPan && "dragPanEnabled"
      )}
      ref={ref}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onDoubleClick={handleDoubleClick}
    >
      <Storyboard onMount={() => setStoryboardMounted(true)} />
      {!context.ready && (
        <LoadingStateWrap>
          <LoadingIndicator />
        </LoadingStateWrap>
      )}
    </StoryboardViewportContainer>
  );
};

export default StoryboardViewport;
