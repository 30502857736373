import { RuntimeStoryRecord } from "../../../../types/tines.types";
import MailIcon from "../notificationIcons/MailIcon";

export const makeInfrastructureDemoStory = () => ({
  dropZoneActionNameMatcher: "Stop AWS EC2 instances",
  notification: {
    icon: MailIcon,
    heading: "AWS",
    body: "An AWS EC2 instance was powered off",
  },
  maxEvents: 7,
  possibleTriggerValues: ["Power on?", "Power off?"] as [string, string],
  inspectable: true,
  story: {
    standardLibVersion: "35",
    actionRuntimeVersion: "4",
    agents: [
      {
        name: "Find EC2 instances with 'test' tag",
        options: {
          url: "https://ec2.<<RESOURCES.aws_region>>.amazonaws.com",
          method: "get",
          content_type: "form",
          headers: { Authorization: "<<CREDENTIAL.aws_ec2>>" },
          payload: {
            Action: "DescribeInstances",
            "Filter.1.Name": "tag:Environment",
            "Filter.1.Value.1": "<<RESOURCES.aws_environment>>",
            Version: "2016-11-15",
            MaxResults: "5",
          },
        },
        position: { x: 690, y: 795 },
        type: "httpRequest",
      },
      {
        name: "Stop AWS EC2 instances",
        options: {
          url: "https://ec2.us-east-1.amazonaws.com",
          method: "get",
          content_type: "form",
          headers: { Authorization: "<<CREDENTIAL.aws_ec2>>" },
          payload: {
            Action: "StopInstances",
            Version: "2016-11-15",
            "InstanceId.1":
              "<<iterate_through_instances.ec2_instance.instancesSet.item.instanceId>>",
          },
        },
        position: { x: 825, y: 1035 },
        type: "httpRequest",
      },
      {
        name: "Iterate through instances",
        options: {
          mode: "explode",
          path: "=find_ec2_instances_with_test_tag.instances",
          to: "ec2_instance",
          limit: "500",
        },
        position: { x: 690, y: 870 },
        type: "eventTransformation",
      },
      {
        name: "Start AWS EC2 instances",
        options: {
          url: "https://ec2.us-east-1.amazonaws.com",
          method: "get",
          content_type: "form",
          headers: { Authorization: "<<CREDENTIAL.aws_ec2>>" },
          payload: {
            Action: "StartInstances",
            Version: "2016-11-15",
            "InstanceId.1":
              "<<iterate_through_instances.ec2_instance.instancesSet.item.instanceId>>",
          },
        },
        position: { x: 585, y: 1035 },
        type: "httpRequest",
      },
      {
        name: "Power on?",
        options: {
          rules: [
            {
              type: "field==value",
              value: "false",
              path: "<<IS_BLANK(power_on_ec2)>>",
            },
          ],
        },
        position: { x: 585, y: 945 },
        type: "trigger",
      },
      {
        name: "Power off?",
        options: {
          rules: [
            {
              type: "field==value",
              value: "false",
              path: "<<IS_BLANK(power_on_ec2)>>",
            },
          ],
        },
        position: { x: 825, y: 945 },
        type: "trigger",
      },
      {
        name: "Receive slash command",
        options: {
          path: "path",
          secret: "secret",
          verbs: "get,post",
        },
        position: { x: 690, y: 735 },
        type: "webhook",
      },
    ],
    links: [
      { sourceIdentifier: 4, receiverIdentifier: 3 },
      { sourceIdentifier: 2, receiverIdentifier: 4 },
      { sourceIdentifier: 2, receiverIdentifier: 5 },
      { sourceIdentifier: 5, receiverIdentifier: 1 },
      { sourceIdentifier: 6, receiverIdentifier: 0 },
      { sourceIdentifier: 0, receiverIdentifier: 2 },
    ],
    diagramNotes: [],
  } as unknown as RuntimeStoryRecord,
});
