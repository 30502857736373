import imgSoc2023BottomLeft from "../../../static/images/tines-report-soc-2023-content-card-deco-bottom-left.png";
import imgSoc2023BottomRight from "../../../static/images/tines-report-soc-2023-content-card-deco-bottom-right.png";
import imgSoc2023BottomLeft2x from "../../../static/images/tines-report-soc-2023-content-card-deco-bottom-left@2x.png";
import imgSoc2023BottomRight2x from "../../../static/images/tines-report-soc-2023-content-card-deco-bottom-right@2x.png";
import ContentCardBase from "./_ContentCardBase";
import { css } from "linaria";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import {
  fromDesktopSm,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

const socCardStyle = css`
  ${fromTablet} {
    &:before {
      content: "";
      background-image: url(${imgSoc2023BottomLeft});
      @media (min-device-pixel-ratio: 1.5) {
        background-image: url(${imgSoc2023BottomLeft2x});
      }
      @media (-webkit-min-device-pixel-ratio: 1.5) {
        background-image: url(${imgSoc2023BottomLeft2x});
      }
      @media (-moz-min-device-pixel-ratio: 1.5) {
        background-image: url(${imgSoc2023BottomLeft2x});
      }
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 75%;
      max-width: 383px;
      height: 100%;
    }
  }
  &:after {
    content: "";
    background-image: url(${imgSoc2023BottomRight});
    @media (min-device-pixel-ratio: 1.5) {
      background-image: url(${imgSoc2023BottomRight2x});
    }
    @media (-webkit-min-device-pixel-ratio: 1.5) {
      background-image: url(${imgSoc2023BottomRight2x});
    }
    @media (-moz-min-device-pixel-ratio: 1.5) {
      background-image: url(${imgSoc2023BottomRight2x});
    }
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0;
    right: 0;
    width: ${(359 / 742) * 100}%;
    max-width: 359px;
    height: 100%;
  }
  h4 + p {
    max-width: 55%;
    ${fromDesktopSm} {
      max-width: 100%;
    }
  }
`;

export const ContentCardSOC2023 = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => (
  <ContentCardBase
    className={socCardStyle}
    link="/reports/voice-of-the-soc-2023"
    color="purple"
    variant="800"
    large={large}
    size="2/3"
  >
    <header>
      <div>
        <p>
          <strong>{overrides?.preHeadingTag || "Report"}</strong>
        </p>
        <h4>
          {overrides?.heading || (
            <>
              Voice of the
              <br /> SOC 2023
            </>
          )}
        </h4>
        <ReactMarkdown>
          {overrides?.description ||
            "Retain your analysts and improve your security posture with our report based on insights from 900 security practitioners."}
        </ReactMarkdown>
      </div>
      <p>
        <strong>{overrides?.linkLabel || "Access the report"}</strong>
      </p>
    </header>
  </ContentCardBase>
);
