export const CaseStudiesPageIllustration = () => (
  <svg
    width="913"
    height="362"
    viewBox="0 0 913 362"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    strokeLinecap="round"
  >
    <path
      d="M800.722 315.93C812.402 315.93 821.871 306.722 821.871 295.363C821.871 284.005 812.402 274.797 800.722 274.797C789.041 274.797 779.572 284.005 779.572 295.363C779.572 306.722 789.041 315.93 800.722 315.93Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M784.533 299.107C784.533 287.744 794.001 278.541 805.682 278.541C809.417 278.541 812.926 279.496 815.971 281.143C812.117 277.24 806.717 274.805 800.71 274.805C789.029 274.805 779.561 284.008 779.561 295.371C779.561 303.094 783.95 309.814 790.433 313.336C786.781 309.647 784.533 304.634 784.533 299.107Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M824.737 302.085C823.167 300.748 820.811 300.939 819.479 302.515C819.194 302.837 816.327 305.893 812.306 304.448C796.772 298.886 790.61 310.023 789.385 312.697C791.478 313.986 793.798 314.941 796.308 315.466C797.164 313.58 800.507 308.173 809.797 311.491C816.981 314.069 822.833 310.106 825.153 307.349C826.497 305.773 826.295 303.41 824.725 302.085H824.737Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path
      d="M796.379 315.507C796.093 306.029 801.386 302.006 806.18 300.311C811.866 298.306 815.791 294.952 817.837 290.368C821.049 283.171 818.444 275.173 817.171 272.058C816.386 270.148 814.209 269.229 812.306 270.017C810.403 270.804 809.499 272.989 810.272 274.899C811.176 277.107 813.067 282.753 811.033 287.301C809.832 289.986 807.441 291.932 803.694 293.257C799.221 294.833 789.92 299.655 788.956 312.475C791.169 313.907 793.666 314.957 796.367 315.495L796.379 315.507Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path
      d="M824.239 288.606C825.844 287.066 826.166 284.619 825.273 282.423C827.498 281.599 829.08 279.713 829.128 277.481C829.175 275.249 827.688 273.303 825.5 272.372C826.499 270.212 826.285 267.753 824.738 266.141C823.204 264.53 820.777 264.208 818.577 265.103C817.756 262.871 815.877 261.283 813.652 261.235C811.428 261.188 809.477 262.68 808.561 264.876C806.408 263.885 803.958 264.1 802.352 265.64C800.746 267.18 800.425 269.615 801.317 271.823C799.093 272.647 797.511 274.533 797.463 276.765C797.415 278.997 798.902 280.943 801.091 281.874C800.092 284.034 800.318 286.493 801.852 288.105C803.387 289.716 805.825 290.038 808.014 289.143C808.835 291.375 810.714 292.963 812.938 293.01C815.163 293.058 817.102 291.566 818.029 289.37C820.182 290.361 822.633 290.146 824.239 288.606ZM810.107 278.376C810.238 277.959 810.31 277.517 810.321 277.063C810.333 276.61 810.274 276.168 810.167 275.75C810.559 275.547 810.916 275.285 811.237 274.974C811.559 274.664 811.832 274.318 812.058 273.936C812.475 274.067 812.915 274.139 813.367 274.151C813.819 274.151 814.259 274.103 814.675 273.996C814.877 274.378 815.139 274.748 815.448 275.07C815.758 275.392 816.114 275.667 816.483 275.894C816.352 276.311 816.281 276.753 816.269 277.207C816.269 277.66 816.317 278.102 816.424 278.52C816.043 278.722 815.674 278.985 815.353 279.295C815.032 279.606 814.758 279.964 814.532 280.334C814.116 280.203 813.676 280.131 813.224 280.119C812.772 280.119 812.332 280.167 811.915 280.274C811.713 279.892 811.452 279.522 811.142 279.2C810.833 278.878 810.488 278.603 810.107 278.376Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M813.306 283.282C816.499 283.282 819.087 280.685 819.087 277.481C819.087 274.277 816.499 271.68 813.306 271.68C810.114 271.68 807.525 274.277 807.525 277.481C807.525 280.685 810.114 283.282 813.306 283.282Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M832.813 319.571C834.217 318.222 834.502 316.086 833.717 314.152C835.668 313.424 837.048 311.777 837.095 309.831C837.131 307.874 835.835 306.167 833.92 305.355C834.788 303.457 834.609 301.308 833.265 299.9C831.921 298.491 829.792 298.205 827.865 298.993C827.151 297.035 825.498 295.651 823.559 295.603C821.608 295.555 819.907 296.868 819.098 298.79C817.207 297.919 815.066 298.109 813.662 299.458C812.259 300.807 811.973 302.944 812.758 304.877C810.808 305.594 809.428 307.253 809.38 309.198C809.333 311.144 810.641 312.863 812.556 313.663C811.688 315.561 811.878 317.697 813.222 319.118C814.566 320.526 816.696 320.813 818.623 320.025C819.348 321.982 820.99 323.367 822.94 323.415C824.879 323.463 826.592 322.15 827.389 320.228C829.28 321.099 831.422 320.908 832.825 319.559L832.813 319.571ZM820.442 310.607C820.561 310.237 820.621 309.855 820.633 309.461C820.633 309.067 820.597 308.685 820.502 308.315C820.835 308.136 821.156 307.909 821.442 307.635C821.727 307.36 821.965 307.05 822.155 306.716C822.524 306.835 822.905 306.895 823.297 306.907C823.69 306.907 824.082 306.871 824.451 306.775C824.63 307.122 824.856 307.432 825.129 307.718C825.403 308.005 825.712 308.244 826.045 308.435C825.926 308.805 825.867 309.187 825.855 309.58C825.855 309.974 825.89 310.356 825.986 310.738C825.653 310.917 825.331 311.144 825.046 311.419C824.76 311.693 824.522 312.004 824.332 312.326C823.963 312.206 823.583 312.147 823.19 312.135C822.798 312.135 822.405 312.171 822.036 312.266C821.858 311.92 821.632 311.61 821.358 311.323C821.085 311.037 820.776 310.798 820.454 310.607H820.442Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M823.238 314.92C826.037 314.92 828.305 312.643 828.305 309.835C828.305 307.027 826.037 304.75 823.238 304.75C820.44 304.75 818.171 307.027 818.171 309.835C818.171 312.643 820.44 314.92 823.238 314.92Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M809 291.281C809 291.281 810.725 295.029 813.984 295.936L816.399 292.535C816.399 292.535 814.388 293.442 812.319 292.749C810.249 292.057 809 291.293 809 291.293V291.281Z"
      fill="#483C72"
    />
    <path
      d="M796.559 314.027C796.559 314.027 803.47 303.821 806.574 303.117L801.067 303.189C801.067 303.189 796.725 306.698 796.559 314.015V314.027Z"
      fill="#483C72"
    />
    <path
      d="M800.401 298.877C801.032 304.344 796.416 309.357 796.416 309.357C796.416 309.357 790.79 305.513 790.171 300.058C789.553 294.591 794.156 289.578 794.156 289.578C794.156 289.578 799.783 293.422 800.401 298.889V298.877Z"
      fill="#B0E0BD"
      stroke="#483C72"
    />
    <path
      d="M592.848 252.578C604.529 252.578 613.998 243.37 613.998 232.012C613.998 220.653 604.529 211.445 592.848 211.445C581.168 211.445 571.699 220.653 571.699 232.012C571.699 243.37 581.168 252.578 592.848 252.578Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M576.66 235.764C576.66 224.4 586.128 215.197 597.809 215.197C601.544 215.197 605.053 216.152 608.098 217.799C604.244 213.896 598.844 211.461 592.837 211.461C581.156 211.461 571.688 220.664 571.688 232.028C571.688 239.75 576.077 246.471 582.56 249.992C578.908 246.304 576.66 241.29 576.66 235.764Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M609.716 244.402C611.322 242.337 612.523 239.985 613.237 237.419C607.182 236.058 600.402 237.025 593.836 240.236C586.628 243.757 580.633 249.57 578.896 254.691C578.242 256.649 579.289 258.773 581.227 259.43C581.62 259.561 582.024 259.633 582.417 259.633C583.975 259.633 585.426 258.654 585.95 257.09C586.866 254.369 591.077 249.905 597.095 246.956C599.784 245.643 604.565 243.829 609.716 244.39V244.402Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path
      d="M609.75 244.345C611.297 242.34 612.486 240.06 613.2 237.577C606.765 234.271 594.013 224.865 596.476 203.105C596.713 201.052 595.238 199.201 593.192 198.963C591.158 198.724 589.303 200.204 589.065 202.257C586.222 227.443 600.603 239.762 609.75 244.345Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path
      d="M605.921 221.429C607.859 219.567 608.252 216.619 607.17 213.945C609.87 212.942 611.773 210.662 611.832 207.977C611.892 205.279 610.084 202.916 607.443 201.805C608.645 199.191 608.383 196.219 606.527 194.273C604.672 192.328 601.734 191.934 599.069 193.032C598.07 190.334 595.81 188.413 593.122 188.353C590.433 188.293 588.078 190.108 586.96 192.758C584.355 191.552 581.405 191.815 579.454 193.677C577.515 195.539 577.123 198.487 578.205 201.161C575.505 202.164 573.602 204.443 573.542 207.129C573.483 209.827 575.291 212.19 577.932 213.3C576.73 215.914 576.992 218.875 578.848 220.832C580.703 222.778 583.641 223.172 586.306 222.086C587.305 224.783 589.577 226.705 592.265 226.765C594.953 226.824 597.297 225.01 598.415 222.36C601.02 223.566 603.982 223.303 605.921 221.441V221.429ZM588.839 209.051C588.994 208.55 589.089 208.012 589.101 207.463C589.113 206.914 589.053 206.389 588.911 205.876C589.375 205.625 589.815 205.315 590.207 204.945C590.6 204.563 590.933 204.145 591.195 203.679C591.694 203.835 592.229 203.93 592.777 203.942C593.324 203.954 593.859 203.894 594.359 203.751C594.608 204.229 594.918 204.658 595.286 205.052C595.667 205.446 596.083 205.78 596.547 206.043C596.393 206.556 596.298 207.081 596.286 207.63C596.274 208.18 596.345 208.705 596.476 209.218C596.012 209.469 595.572 209.779 595.179 210.161C594.787 210.543 594.454 210.961 594.192 211.426C593.693 211.271 593.157 211.176 592.61 211.164C592.063 211.152 591.528 211.211 591.016 211.355C590.766 210.889 590.457 210.447 590.088 210.054C589.708 209.66 589.291 209.325 588.827 209.063L588.839 209.051Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M597.524 213.05C594.728 215.724 590.303 215.628 587.627 212.823C584.963 210.018 585.058 205.578 587.841 202.904C590.636 200.23 595.061 200.326 597.726 203.131C600.39 205.924 600.295 210.364 597.512 213.05H597.524Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M590.815 260.933C592.183 259.62 592.456 257.543 591.683 255.669C593.575 254.965 594.919 253.365 594.954 251.468C594.99 249.57 593.729 247.91 591.862 247.123C592.706 245.284 592.528 243.196 591.219 241.835C589.911 240.462 587.841 240.188 585.974 240.951C585.272 239.054 583.678 237.705 581.787 237.657C579.895 237.621 578.242 238.886 577.457 240.76C575.625 239.913 573.543 240.092 572.176 241.405C570.808 242.718 570.534 244.783 571.307 246.669C569.416 247.373 568.072 248.973 568.024 250.871C567.988 252.769 569.249 254.428 571.117 255.204C570.272 257.042 570.451 259.131 571.759 260.503C573.068 261.876 575.137 262.151 577.005 261.387C577.707 263.285 579.301 264.633 581.192 264.669C583.083 264.717 584.737 263.44 585.522 261.566C587.354 262.413 589.435 262.234 590.803 260.921L590.815 260.933ZM578.801 252.231C578.908 251.873 578.979 251.503 578.979 251.121C578.991 250.739 578.944 250.357 578.849 249.999C579.182 249.82 579.479 249.605 579.765 249.343C580.05 249.08 580.276 248.782 580.466 248.46C580.823 248.567 581.192 248.639 581.573 248.639C581.953 248.639 582.334 248.603 582.691 248.507C582.869 248.841 583.083 249.152 583.345 249.426C583.607 249.701 583.904 249.94 584.225 250.131C584.118 250.489 584.047 250.859 584.047 251.241C584.047 251.623 584.082 252.005 584.178 252.363C583.844 252.542 583.547 252.757 583.262 253.019C582.988 253.282 582.75 253.58 582.56 253.903C582.203 253.795 581.834 253.724 581.454 253.724C581.073 253.724 580.692 253.759 580.335 253.855C580.157 253.521 579.943 253.21 579.681 252.936C579.42 252.661 579.122 252.422 578.801 252.231Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M581.512 256.415C584.232 256.415 586.437 254.202 586.437 251.473C586.437 248.744 584.232 246.531 581.512 246.531C578.793 246.531 576.588 248.744 576.588 251.473C576.588 254.202 578.793 256.415 581.512 256.415Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M601.793 239.117C601.793 239.117 604.755 243.653 605.659 244.56L608.288 243.653L601.793 239.117Z"
      fill="#483C72"
    />
    <path
      d="M614.795 231.174C615.425 236.64 610.81 241.654 610.81 241.654C610.81 241.654 605.184 237.81 604.565 232.355C603.946 226.888 608.55 221.875 608.55 221.875C608.55 221.875 614.176 225.719 614.795 231.185V231.174Z"
      fill="#B0E0BD"
      stroke="#483C72"
    />
    <path
      d="M594.645 230.171C594.645 230.171 597.464 227.342 597.773 224.203C597.773 224.203 594.906 226.769 592.266 226.746L594.645 230.171Z"
      fill="#483C72"
    />
    <path
      d="M38.8758 225.953C50.5563 225.953 60.0251 216.745 60.0251 205.387C60.0251 194.028 50.5563 184.82 38.8758 184.82C27.1954 184.82 17.7266 194.028 17.7266 205.387C17.7266 216.745 27.1954 225.953 38.8758 225.953Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M22.688 209.131C22.688 197.767 32.1564 188.564 43.8372 188.564C47.5723 188.564 51.0813 189.519 54.1264 191.166C50.2724 187.263 44.8721 184.828 38.8651 184.828C27.1843 184.828 17.7158 194.031 17.7158 205.395C17.7158 213.118 22.1051 219.838 28.5879 223.359C24.9361 219.671 22.688 214.657 22.688 209.131Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M14.4904 212.116C16.0606 210.779 18.4158 210.97 19.748 212.546C20.0335 212.868 22.9002 215.924 26.9207 214.48C42.4556 208.917 48.6171 220.054 49.8423 222.728C47.7488 224.017 45.4293 224.972 42.9194 225.497C42.063 223.611 38.7205 218.204 29.4305 221.522C22.246 224.101 16.3936 220.138 14.0741 217.38C12.7419 215.805 12.9322 213.441 14.5023 212.116H14.4904Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path
      d="M42.8386 225.538C43.1241 216.06 37.8308 212.038 33.0371 210.343C27.3513 208.337 23.426 204.983 21.38 200.4C18.1684 193.202 20.7734 185.204 22.0462 182.089C22.8312 180.179 25.008 179.26 26.9112 180.048C28.8144 180.836 29.7184 183.02 28.9452 184.93C28.0412 187.138 26.1499 192.784 28.184 197.332C29.3854 200.018 31.7762 201.963 35.5232 203.288C39.9957 204.864 49.2976 209.686 50.2611 222.506C48.0486 223.938 45.5506 224.989 42.8505 225.526L42.8386 225.538Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path
      d="M24.2322 224.993C25.6239 223.656 25.9094 221.531 25.1243 219.622C27.0632 218.905 28.4311 217.27 28.4668 215.336C28.5025 213.403 27.2178 211.708 25.3146 210.908C26.1829 209.034 25.9926 206.897 24.6604 205.501C23.3282 204.104 21.2228 203.818 19.3077 204.605C18.594 202.66 16.9643 201.287 15.0373 201.251C13.1103 201.204 11.4213 202.505 10.6243 204.414C8.75679 203.555 6.62759 203.734 5.23588 205.071C3.84416 206.408 3.55869 208.533 4.34375 210.442C2.40487 211.159 1.03695 212.794 1.00127 214.728C0.953687 216.661 2.25023 218.356 4.15343 219.156C3.29699 221.03 3.47544 223.167 4.80768 224.563C6.13991 225.96 8.25722 226.246 10.1604 225.458C10.8741 227.392 12.5037 228.765 14.4307 228.813C16.3577 228.86 18.0468 227.559 18.8437 225.649C20.7231 226.509 22.8405 226.33 24.2322 224.993ZM11.9684 216.112C12.0755 215.754 12.1469 215.372 12.1588 214.978C12.1588 214.584 12.1231 214.202 12.0279 213.832C12.361 213.653 12.6821 213.426 12.9557 213.164C13.2412 212.889 13.4791 212.591 13.6694 212.257C14.0263 212.364 14.4069 212.436 14.7995 212.436C15.192 212.436 15.5726 212.4 15.9414 212.304C16.1198 212.639 16.3458 212.961 16.6075 213.236C16.8811 213.522 17.1784 213.761 17.5115 213.952C17.4045 214.31 17.3331 214.692 17.3212 215.086C17.3212 215.48 17.3569 215.862 17.452 216.232C17.119 216.411 16.8097 216.637 16.5242 216.9C16.2387 217.175 16.0009 217.473 15.8105 217.807C15.4537 217.7 15.073 217.628 14.6805 217.628C14.288 217.628 13.9073 217.664 13.5386 217.759C13.3602 217.425 13.1342 217.115 12.8725 216.828C12.6108 216.542 12.3015 216.303 11.9684 216.112Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M14.7287 220.379C17.501 220.379 19.7484 218.124 19.7484 215.342C19.7484 212.56 17.501 210.305 14.7287 210.305C11.9564 210.305 9.70898 212.56 9.70898 215.342C9.70898 218.124 11.9564 220.379 14.7287 220.379Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M49.4161 209.6C47.7745 214.852 41.5297 217.55 41.5297 217.55C41.5297 217.55 37.9493 211.749 39.5908 206.497C41.2323 201.245 47.4772 198.547 47.4772 198.547C47.4772 198.547 51.0576 204.348 49.4161 209.6Z"
      fill="#B0E0BD"
      stroke="#483C72"
    />
    <path
      d="M40.5291 196.075C42.3015 194.38 42.6583 191.683 41.6591 189.248C44.1214 188.341 45.8581 186.264 45.9057 183.805C45.9532 181.346 44.3117 179.185 41.8971 178.171C42.9914 175.783 42.7535 173.086 41.0644 171.307C39.3753 169.529 36.687 169.171 34.2605 170.161C33.3565 167.69 31.2748 165.948 28.8364 165.9C26.386 165.84 24.233 167.499 23.2219 169.923C20.8429 168.824 18.1428 169.063 16.3704 170.758C14.5981 172.453 14.2412 175.151 15.2285 177.586C12.7662 178.493 11.0296 180.582 10.982 183.041C10.9225 185.5 12.5759 187.648 14.9906 188.663C13.8963 191.05 14.1342 193.748 15.8233 195.526C17.5123 197.305 20.2006 197.663 22.6272 196.672C23.5312 199.143 25.6128 200.886 28.0513 200.934C30.5017 200.993 32.6428 199.334 33.6657 196.911C36.0447 198.009 38.7449 197.77 40.5172 196.075H40.5291ZM24.9467 184.784C25.0895 184.318 25.1727 183.841 25.1846 183.339C25.1965 182.838 25.137 182.36 25.0181 181.883C25.4463 181.656 25.8388 181.37 26.1957 181.024C26.5525 180.677 26.8499 180.295 27.0997 179.878C27.5636 180.021 28.0394 180.104 28.539 180.116C29.0386 180.116 29.5263 180.069 29.9902 179.949C30.2162 180.379 30.5017 180.773 30.8466 181.131C31.1916 181.489 31.5841 181.787 31.9885 182.038C31.8458 182.504 31.7625 182.981 31.7506 183.482C31.7506 183.984 31.7982 184.461 31.9291 184.939C31.5008 185.166 31.1083 185.452 30.7515 185.798C30.3946 186.144 30.0972 186.526 29.8474 186.944C29.3835 186.801 28.9077 186.717 28.4082 186.705C27.9086 186.693 27.4209 186.753 26.957 186.884C26.731 186.455 26.4455 186.061 26.1005 185.691C25.7556 185.333 25.3749 185.034 24.9586 184.784H24.9467Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M32.8672 188.417C30.3217 190.864 26.2774 190.769 23.8389 188.214C21.4004 185.66 21.4956 181.601 24.0411 179.166C26.5866 176.719 30.6309 176.815 33.0575 179.369C35.496 181.924 35.4008 185.982 32.8672 188.417Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M42.8606 220.931L46.5123 214.438L41.5283 217.553L42.8606 220.931Z"
      fill="#483C72"
    />
    <path
      d="M24.2812 199.781C24.2812 199.781 27.3026 203.446 32.9408 202.61L30.4428 200.617C30.4428 200.617 26.3748 200.927 24.2812 199.781Z"
      fill="#483C72"
    />
    <path
      d="M28.8841 216.633C28.8841 216.633 29.4075 220.19 27.8373 222.076L25.8509 222.183L25.4346 219.88C25.4346 219.88 28.5629 218.304 28.8841 216.633Z"
      fill="#483C72"
    />
    <path
      d="M219.643 33.2888C210.8 32.1285 202.15 35.3407 194.934 41.6038C190.256 37.0918 184.604 34.116 178.277 33.2888C169.434 32.1285 160.784 35.3407 153.568 41.6038C148.89 37.0918 143.238 34.116 136.911 33.2888C128.068 32.1285 119.418 35.3407 112.202 41.6038C107.524 37.0918 101.872 34.116 95.5447 33.2888C75.5362 30.6675 56.4804 50.3807 52.969 77.3347C49.4576 104.278 62.8288 128.256 82.8373 130.877C91.68 132.038 100.33 128.825 107.546 122.562C112.224 127.074 117.876 130.05 124.203 130.877C133.046 132.038 141.696 128.825 148.912 122.562C153.59 127.074 159.242 130.05 165.569 130.877C174.412 132.038 183.062 128.825 190.277 122.562C194.956 127.074 200.608 130.05 206.935 130.877C226.944 133.499 246 113.775 249.511 86.8314C253.022 59.8775 239.651 35.91 219.643 33.2888Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M58.5688 81.8174C62.0802 54.8634 81.1359 35.1502 101.144 37.7715C107.471 38.5987 113.124 41.5745 117.802 46.0865C125.018 39.8234 133.668 36.6113 142.51 37.7715C148.837 38.5987 154.49 41.5745 159.168 46.0865C166.384 39.8234 175.034 36.6113 183.876 37.7715C190.203 38.5987 195.856 41.5745 200.534 46.0865C207.75 39.8234 216.4 36.6113 225.242 37.7715C229.375 38.3086 233.207 39.7804 236.687 41.972C231.944 37.2451 226.152 34.1404 219.654 33.281C210.811 32.1207 202.161 35.3329 194.946 41.596C190.268 37.084 184.615 34.1082 178.288 33.281C169.445 32.1207 160.795 35.3329 153.58 41.596C148.902 37.084 143.249 34.1082 136.922 33.281C128.079 32.1207 119.429 35.3329 112.214 41.596C107.536 37.084 101.883 34.1082 95.5562 33.281C75.5477 30.6597 56.4919 50.3729 52.9805 77.3269C50.1971 98.716 58.0442 118.214 71.4047 126.669C61.5342 116.839 56.2029 100.016 58.5688 81.8174Z"
      fill="#EDE9E3"
      stroke="#483C72"
    />
    <path
      d="M153.161 84.5318C153.161 87.4754 144.821 89.0653 143.965 91.7296C143.076 94.4905 148.857 100.689 147.187 102.977C145.506 105.298 137.841 101.677 135.529 103.364C133.238 105.029 134.308 113.484 131.557 114.376C128.902 115.235 124.834 107.801 121.89 107.801C118.946 107.801 114.878 115.235 112.223 114.376C109.471 113.484 110.542 105.04 108.251 103.364C105.939 101.677 98.2736 105.287 96.5928 102.977C94.9335 100.678 100.714 94.4905 99.8151 91.7296C98.9587 89.0653 90.6191 87.4754 90.6191 84.5318C90.6191 81.5882 98.9587 79.9983 99.8151 77.3341C100.704 74.5839 94.9227 68.3852 96.5928 66.0862C98.2736 63.7658 105.949 67.3861 108.251 65.6995C110.542 64.0236 109.471 55.5797 112.223 54.688C114.878 53.8179 118.946 61.2519 121.89 61.2519C124.834 61.2519 128.902 53.8179 131.557 54.688C134.308 55.5797 133.238 64.0236 135.529 65.6995C137.841 67.3861 145.506 63.7765 147.187 66.0862C148.846 68.3852 143.065 74.5731 143.965 77.3341C144.821 79.9983 153.161 81.5775 153.161 84.5318Z"
      fill="#FEAF7F"
      stroke="#483C72"
    />
    <path
      d="M111.699 58.1146C110.704 58.1146 109.783 58.3725 108.948 58.7807C107.749 55.2033 104.388 52.625 100.416 52.625C95.7589 52.625 91.9263 56.1702 91.4553 60.7251C90.4597 59.8765 89.1964 59.3393 87.7833 59.3393C84.6573 59.3393 82.1201 61.8854 82.1201 65.0223C82.1201 68.1592 84.6573 70.7053 87.7833 70.7053H111.689C115.147 70.7053 117.951 67.8907 117.951 64.4207C117.951 60.9508 115.147 58.1254 111.689 58.1254L111.699 58.1146Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M216.795 91.1783C215.232 91.1783 213.765 91.5758 212.448 92.2204C210.553 86.5589 205.233 82.4766 198.949 82.4766C191.583 82.4766 185.524 88.0951 184.775 95.2821C183.212 93.9392 181.188 93.0905 178.961 93.0905C174.016 93.0905 170.001 97.1191 170.001 102.082C170.001 107.046 174.016 111.074 178.961 111.074H216.795C222.265 111.074 226.708 106.616 226.708 101.126C226.708 95.6366 222.265 91.1783 216.795 91.1783Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M85.9937 65.1201C84.1416 65.1201 82.4716 65.8721 81.2725 67.0861C80.6944 66.8927 80.0842 66.7853 79.4419 66.7853C76.2196 66.7853 73.6074 69.4065 73.6074 72.6402C73.6074 75.8738 76.2196 78.495 79.4419 78.495H85.9937C89.6763 78.495 92.6632 75.4978 92.6632 71.8022C92.6632 68.1066 89.6763 65.1094 85.9937 65.1094V65.1201Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M90.5547 66.5938C90.5547 66.5938 94.5585 68.4952 94.5585 70.719H92.5566C92.5566 70.719 91.4432 67.2598 90.5547 66.5938Z"
      fill="#483C72"
    />
    <path
      d="M168.727 54.5107C171.21 54.5107 173.416 55.7032 174.807 57.551C175.332 57.4006 175.889 57.3254 176.467 57.3254C179.186 57.3254 181.488 59.0657 182.355 61.5044C183.018 61.1176 183.768 60.8813 184.581 60.8813C187.054 60.8813 189.056 62.8902 189.056 65.3718C189.056 67.8534 187.054 69.8623 184.581 69.8623H168.716C164.487 69.8623 161.062 66.4246 161.062 62.1812C161.062 57.9377 164.487 54.5 168.716 54.5L168.727 54.5107Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M76.3491 100.706C74.1116 100.706 72.0669 101.49 70.4611 102.801C68.8445 101.501 66.7998 100.706 64.5623 100.706C62.6461 100.706 60.8797 101.286 59.3916 102.275C60.6334 108.71 62.8816 114.586 65.9541 119.56H76.3491C81.5412 119.56 85.7485 115.338 85.7485 110.128C85.7485 104.917 81.5412 100.695 76.3491 100.695V100.706Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M213.24 152.578C205.896 181.669 179.121 199.964 153.418 193.444C127.714 186.923 112.833 158.046 120.166 128.943C127.5 99.8407 154.285 81.5562 179.989 88.0772C205.692 94.5981 220.573 123.475 213.24 152.578Z"
      fill="#EDE9E3"
      stroke="#483C72"
    />
    <path
      d="M208.959 151.517C215.626 125.094 202.112 98.8663 178.773 92.9356C155.434 87.005 131.109 103.617 124.441 130.04C117.773 156.463 131.288 182.691 154.627 188.621C177.966 194.552 202.291 177.94 208.959 151.517Z"
      fill="#B0E0BD"
      stroke="#483C72"
    />
    <path
      d="M178.78 92.9113C155.442 86.9812 131.108 103.6 124.449 130.028C122.918 136.087 122.469 142.135 122.94 147.926C127.201 150.869 131.975 152.814 137.157 153.501C147.252 154.823 157.133 151.159 165.366 144.005C170.708 149.161 177.163 152.556 184.389 153.501C193.478 154.694 202.385 151.836 210.072 146.035C213.733 121.541 200.522 98.4331 178.79 92.9113H178.78Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M159.745 115.82C161.586 115.82 163.267 116.572 164.466 117.786C165.044 117.593 165.654 117.496 166.286 117.496C169.508 117.496 172.12 120.117 172.12 123.351C172.12 126.585 169.508 129.206 166.286 129.206H159.734C156.051 129.206 153.064 126.209 153.064 122.513C153.064 118.818 156.051 115.82 159.734 115.82H159.745Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M716.539 92.5415C726.666 53.9934 703.786 14.5183 665.434 4.37138C627.082 -5.77553 587.781 17.2481 577.653 55.7962C567.525 94.3443 590.405 133.819 628.757 143.966C667.11 154.113 706.411 131.09 716.539 92.5415Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M684.298 65.8516H676.616H672.651H664.969L650.331 96.1736H672.651H676.616H698.935L684.298 65.8516Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M664.969 126.643H672.651H676.616H684.298L698.935 96.3047H676.616H672.651H650.331L664.969 126.643Z"
      fill="#5EBC89"
      stroke="#483C72"
    />
    <path
      d="M664.969 64.0251H672.651H676.616H684.298L698.935 33.7031H676.616H672.651H650.331L664.969 64.0251Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M689.732 64.6346C689.732 67.8011 687.172 70.3707 684.016 70.3707H665.248C662.093 70.3707 659.532 67.8011 659.532 64.6346C659.532 61.4681 662.093 58.8984 665.248 58.8984H684.016C687.172 58.8984 689.732 61.4681 689.732 64.6346Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M684.048 135.938H664.967L660.572 145.023C668.915 143.398 676.845 140.298 684.048 135.938Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M665.25 136.933H682.333C684.861 135.491 687.306 133.916 689.652 132.175C689.701 131.86 689.751 131.529 689.751 131.197C689.751 128.031 687.19 125.461 684.035 125.461H665.267C662.111 125.461 659.551 128.031 659.551 131.197C659.551 134.364 662.111 136.933 665.267 136.933H665.25Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M664.934 4.32812L650.313 33.7052H698.934L691.103 17.4748C683.603 11.5066 674.797 6.94752 664.951 4.3447L664.934 4.32812Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M642.666 112.269C642.666 115.452 640.088 118.039 636.916 118.039H612.185C609.013 118.039 606.436 115.452 606.436 112.269C606.436 109.086 609.013 106.5 612.185 106.5H636.916C640.088 106.5 642.666 109.086 642.666 112.269Z"
      fill="#5EBC89"
      stroke="#483C72"
    />
    <path
      d="M642.666 36.6677C642.666 39.8508 640.088 42.4371 636.916 42.4371H612.185C609.013 42.4371 606.436 39.8508 606.436 36.6677C606.436 33.4847 609.013 30.8984 612.185 30.8984H636.916C640.088 30.8984 642.666 33.4847 642.666 36.6677Z"
      fill="#B0E0BD"
      stroke="#483C72"
    />
    <path
      d="M642.666 77.6443C642.666 80.8274 640.088 83.4136 636.916 83.4136H612.185C609.013 83.4136 606.436 80.8274 606.436 77.6443C606.436 74.4612 609.013 71.875 612.185 71.875H636.916C640.088 71.875 642.666 74.4612 642.666 77.6443Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M640.089 97.8451C640.089 102.62 636.223 106.499 631.465 106.499H617.67C612.912 106.499 609.046 102.62 609.046 97.8451V92.0758C609.046 87.2847 612.912 83.4219 617.67 83.4219H631.465C636.223 83.4219 640.089 87.3012 640.089 92.0758V97.8451Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M624.558 71.8809C632.66 71.8809 639.229 65.2897 639.229 57.1592C639.229 49.0286 632.66 42.4375 624.558 42.4375C616.456 42.4375 609.888 49.0286 609.888 57.1592C609.888 65.2897 616.456 71.8809 624.558 71.8809Z"
      fill="#FEAF7F"
      stroke="#483C72"
    />
    <path
      d="M628.706 143.933C632.505 144.927 636.305 145.607 640.089 145.972V127.271C640.089 122.497 636.223 118.617 631.465 118.617H617.67C612.912 118.617 609.046 122.497 609.046 127.271V135.411C614.993 139.141 621.585 142.043 628.722 143.933H628.706Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M631.465 4.35938H628.772C621.783 6.21616 615.126 9.13397 609.046 12.947C609.046 12.9636 609.046 12.9968 609.046 13.0133V21.6673C609.046 26.4419 612.912 30.3213 617.67 30.3213H631.465C636.223 30.3213 640.089 26.4419 640.089 21.6673V13.0133C640.089 8.23874 636.223 4.35938 631.465 4.35938Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M587.089 63.3915C597.216 24.8466 636.502 1.81912 674.864 11.9651C682.794 14.054 690.046 17.436 696.506 21.7796C688.031 13.6893 677.474 7.55527 665.381 4.35563C627.036 -5.79039 587.733 17.2371 577.606 55.782C569.577 86.3527 582.314 117.487 607.062 134.132C588.691 116.575 580.166 89.7679 587.089 63.3915Z"
      fill="#EDE9E3"
      stroke="#483C72"
    />
    <path
      d="M660.938 12.8925C660.938 12.8925 678.185 13.7546 689.254 17.7997C689.254 17.7997 672.436 9.57681 662.226 9.85865L660.938 12.8925Z"
      fill="#483C72"
    />
    <path
      d="M617.801 21.1098C617.801 21.1098 633.182 15.6223 639.79 15.7715L640.219 11.5938C640.219 11.5938 624.112 17.3631 617.784 21.1098H617.801Z"
      fill="#483C72"
    />
    <path
      d="M704.306 263.699C704.273 264.711 704.191 265.722 704.042 266.733C699.35 299.045 682.053 325.189 654.017 342.381C640.09 350.919 623.966 356.986 606.107 360.402L605.496 360.518C592.031 363.187 578.963 354.4 576.32 340.889C573.66 327.377 582.416 314.264 595.88 311.611L596.822 311.429C639.76 303.222 652.084 278.686 654.876 259.555C656.859 245.927 669.464 236.494 683.044 238.484C695.6 240.324 704.587 251.299 704.29 263.716L704.306 263.699Z"
      fill="#B0E0BD"
      stroke="#483C72"
    />
    <path
      d="M690.264 267.68C670.968 267.68 655.322 283.379 655.322 302.743C655.322 314.331 660.939 324.56 669.563 330.943C687.587 315.194 699.135 294.553 703.431 270.282C699.367 268.608 694.923 267.68 690.247 267.68H690.264Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M702.011 277.221C698.442 275.563 694.461 274.602 690.264 274.602C674.784 274.602 662.229 287.201 662.229 302.735C662.229 312.5 667.185 321.088 674.702 326.127C688.166 312.682 697.418 296.17 701.994 277.204L702.011 277.221Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M690.266 283.102C679.461 283.102 670.705 291.888 670.705 302.73C670.705 310.042 674.687 316.391 680.601 319.773C689.109 309.727 695.503 298.238 699.666 285.505C696.874 283.98 693.686 283.102 690.282 283.102H690.266Z"
      fill="#EE86B7"
      stroke="#483C72"
    />
    <path
      d="M690.265 290.595C683.591 290.595 678.155 296.032 678.155 302.73C678.155 307.654 681.079 311.881 685.276 313.788C690.001 307.256 693.933 300.194 697.006 292.65C695.073 291.341 692.76 290.578 690.249 290.578L690.265 290.595Z"
      fill="#E269A4"
      stroke="#483C72"
    />
    <path
      d="M581.509 347.796C578.849 334.285 587.605 321.171 601.07 318.502L602.011 318.32C644.949 310.113 657.273 285.577 660.065 266.446C662.048 252.818 674.653 243.385 688.234 245.374C693.454 246.137 698.03 248.491 701.615 251.857C698.08 244.877 691.372 239.671 683.063 238.445C669.482 236.455 656.877 245.888 654.894 259.516C652.119 278.647 639.778 303.2 596.84 311.39L595.899 311.572C582.434 314.241 573.662 327.355 576.338 340.85C577.395 346.171 580.072 350.747 583.739 354.195C582.732 352.206 581.972 350.067 581.509 347.78V347.796Z"
      fill="#EDE9E3"
      stroke="#483C72"
    />
    <path
      d="M630.31 330.859C621.026 330.859 613.492 338.419 613.492 347.736C613.492 351.566 614.781 355.081 616.912 357.899C627.733 355.064 637.811 351.185 647.062 346.294C646.336 337.657 639.116 330.859 630.31 330.859Z"
      fill="#FFC8A3"
      stroke="#483C72"
    />
    <path
      d="M144.299 299.965C160.531 299.965 173.689 287.176 173.689 271.401C173.689 255.625 160.531 242.836 144.299 242.836C128.067 242.836 114.908 255.625 114.908 271.401C114.908 287.176 128.067 299.965 144.299 299.965Z"
      fill="#FFBFDB"
      stroke="#483C72"
    />
    <path
      d="M121.813 276.598C121.813 260.815 134.964 248.033 151.204 248.033C156.391 248.033 161.265 249.359 165.494 251.647C160.141 246.226 152.641 242.844 144.298 242.844C128.074 242.844 114.924 255.626 114.924 271.408C114.924 282.135 121.02 291.468 130.024 296.359C124.968 291.236 121.83 284.273 121.83 276.598H121.813Z"
      fill="#EDE9E3"
      stroke="#483C72"
    />
    <path
      d="M173.606 272.58L172.747 272.779L173.656 271.32C173.656 267.192 172.714 263.279 171.062 259.731C169.41 259.814 167.824 260.676 166.882 262.202L163.793 267.158L162.488 261.455C161.827 258.604 159.002 256.83 156.16 257.477C153.319 258.14 151.551 260.975 152.195 263.826L153.5 269.529L148.561 266.412C146.082 264.854 142.828 265.617 141.291 268.087C139.738 270.574 140.498 273.84 142.96 275.398L147.9 278.498L142.217 279.808C139.375 280.471 137.607 283.306 138.252 286.157C138.912 289.009 141.737 290.783 144.579 290.136L150.262 288.827L147.156 293.784C145.967 295.707 146.149 298.077 147.437 299.785C151.138 299.404 154.624 298.343 157.779 296.751L159.217 294.447L159.514 295.79C167.675 290.965 173.193 282.427 173.606 272.563V272.58Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M778.463 215.636C786.332 215.636 793.731 213.607 800.189 210.021C806.647 213.623 814.046 215.683 821.9 215.683C829.769 215.683 837.168 213.654 843.626 210.052C850.084 213.654 857.483 215.715 865.337 215.715C890.528 215.73 910.969 194.779 911 168.905C911.031 143.015 890.622 122.032 865.431 122.001C857.562 122.001 850.163 124.03 843.704 127.616C837.246 124.014 829.847 121.953 821.994 121.953C814.125 121.953 806.726 123.982 800.267 127.584C793.809 123.982 786.41 121.922 778.557 121.922C753.366 121.89 732.925 142.857 732.894 168.747C732.878 194.622 753.272 215.62 778.463 215.652V215.636Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M878.406 182.619C878.406 193.063 885.445 201.824 895.007 204.467C904.773 195.879 910.98 183.138 910.996 168.887C910.996 166.56 910.839 164.279 910.51 162.045C907.626 160.693 904.412 159.922 901.011 159.922C888.517 159.922 878.406 170.083 878.406 182.603V182.619Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M794.901 212.489C796.72 211.765 798.475 210.947 800.184 210.004C806.642 213.606 814.041 215.666 821.895 215.666C829.764 215.666 837.163 213.637 843.621 210.035C850.08 213.637 857.478 215.698 865.332 215.698C869.737 215.698 873.985 215.053 878.013 213.857L892.984 181.094L823.792 149.258L794.886 212.489H794.901Z"
      fill="#A88FEF"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M872.423 132.567C872.423 162.673 848 187.179 817.997 187.179C787.994 187.179 763.571 162.673 763.571 132.567C763.571 129.752 763.791 126.999 764.198 124.294C761.157 125.316 758.273 126.669 755.545 128.304C755.451 129.72 755.389 131.136 755.389 132.567C755.389 167.203 783.479 195.389 817.997 195.389C852.515 195.389 880.605 167.203 880.605 132.567C880.605 129.83 880.417 127.141 880.073 124.498C877.314 123.539 874.445 122.831 871.467 122.438C872.094 125.725 872.423 129.107 872.423 132.583V132.567Z"
      fill="#FEAF7F"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M842.944 190.739C842.944 190.739 860.125 188.286 873.23 172.399L868.323 169.93C868.323 169.93 861.222 181.695 842.96 190.739H842.944Z"
      fill="#483C72"
    />
    <path
      d="M909.744 157.949C909.101 155.212 908.239 152.57 907.157 150.053C886.183 160.733 859.833 156.879 842.903 138.854C838.482 134.151 835.065 128.866 832.635 123.266C829.657 122.527 826.553 122.102 823.371 121.992C826.177 130.14 830.692 137.816 836.946 144.485C856.133 164.885 885.807 169.526 909.744 157.933V157.949Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M863.543 161.672L862.211 164.346L870.033 168.184L872.024 163.512L863.543 161.672Z"
      fill="#483C72"
    />
    <path
      d="M775.042 151.54L780.513 121.985C779.855 121.953 779.196 121.922 778.538 121.922C777.159 121.922 775.795 121.985 774.447 122.111L756.702 142.669L748.66 133.341C744.13 137.368 740.384 142.291 737.687 147.843L738.361 151.524L736.042 151.618C734.019 156.919 732.875 162.692 732.875 168.732C732.875 169.487 732.891 170.226 732.922 170.965L733.815 171.437L733.016 172.522C733.533 179.144 735.367 185.405 738.299 190.973L746.497 187.418L751.717 206.718C754.366 208.699 757.235 210.382 760.276 211.751L766.86 187.418L802.522 202.895L779.541 171.453L813.84 153.128L775.011 151.54H775.042Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M739.445 171.996C739.476 146.121 759.902 125.155 785.108 125.17C792.977 125.17 800.36 127.231 806.819 130.833C813.277 127.231 820.676 125.202 828.545 125.202C836.414 125.202 843.797 127.262 850.256 130.864C856.714 127.278 864.113 125.233 871.982 125.249C877.845 125.249 883.457 126.413 888.614 128.489C881.826 124.352 873.895 121.977 865.446 121.961C857.576 121.961 850.177 123.991 843.719 127.577C837.261 123.975 829.862 121.914 822.008 121.914C814.139 121.914 806.74 123.943 800.282 127.545C793.824 123.943 786.425 121.883 778.571 121.883C753.381 121.851 732.94 142.818 732.908 168.708C732.893 188.559 744.9 205.515 761.845 212.357C748.427 204.193 739.429 189.172 739.445 171.98V171.996Z"
      fill="#EDE9E3"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M792.115 188.041L789.497 179.201L782.004 173.633L792.115 188.041Z"
      fill="#483C72"
    />
    <path
      d="M852.399 191.087C854.233 193.085 854.107 196.199 852.117 198.055C850.126 199.895 847.022 199.769 845.172 197.772L831.848 183.285C830.014 181.288 830.139 178.173 832.13 176.317C834.121 174.477 837.225 174.603 839.075 176.6L852.399 191.087Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M779.325 158.23C778.447 160.81 775.641 162.162 773.086 161.282C770.516 160.401 769.167 157.585 770.045 155.021L773.525 143.272C774.403 140.708 777.209 139.34 779.764 140.22C782.335 141.117 783.683 143.917 782.805 146.481L779.325 158.23Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M833.619 184.852L832.35 185.481L835.14 192.606L839.451 191.725L833.619 184.852Z"
      fill="#483C72"
    />
    <path
      d="M777.579 161.28C777.328 161.595 780.494 160.258 781.638 158.103C782.783 155.932 784.053 152.126 784.053 152.126L781.137 152C781.137 152 778.974 159.629 777.579 161.28Z"
      fill="#483C72"
    />
    <path
      d="M810.888 171.442C819.502 171.442 826.485 164.435 826.485 155.791C826.485 147.148 819.502 140.141 810.888 140.141C802.274 140.141 795.291 147.148 795.291 155.791C795.291 164.435 802.274 171.442 810.888 171.442Z"
      fill="#FEAF7F"
      stroke="#483C72"
      strokeWidth="1.08002"
    />
    <path
      d="M765.482 132.412L778.807 130.241L779.59 125.711C779.59 125.711 769.997 127.677 769.401 128.07C768.806 128.464 765.482 132.396 765.482 132.396V132.412Z"
      fill="#483C72"
    />
    <path
      d="M493.053 75.7842C470.023 65.7708 443.061 70.761 417.966 86.9415C412.894 82.3327 407.195 78.5528 400.851 75.7842C354.13 55.4756 291.285 96.8389 260.473 168.176C229.678 239.513 242.581 313.818 289.319 334.126C312.365 344.14 339.31 339.133 364.406 322.969C369.477 327.578 375.177 331.358 381.521 334.126C428.242 354.435 491.087 313.072 521.898 241.735C552.693 170.398 539.774 96.0929 493.053 75.7842Z"
      fill="#FA9589"
      stroke="#483C72"
    />
    <path
      d="M341.855 78.25C310.085 92.2256 279.307 124.587 260.473 168.172C229.678 239.509 242.581 313.813 289.319 334.122C305.558 341.184 323.731 340.753 341.855 334.238V78.25Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path
      d="M505.74 272.135L369.642 237.586L249.85 286.658C257.02 308.807 270.401 325.883 289.318 334.106C312.364 344.119 339.31 339.112 364.405 322.948C369.477 327.557 375.176 331.337 381.52 334.106C421.121 351.314 472.302 324.225 505.74 272.135Z"
      fill="#FAA3CA"
      stroke="#483C72"
    />
    <path d="M292.293 332.449L426.392 264.195" stroke="#FEEBEE" />
    <path d="M333.313 332.716L443.739 270.828" stroke="#FEEBEE" />
    <path d="M381.091 327.887L466.173 274.836" stroke="#FEEBEE" />
    <path d="M405.047 334.114L487.238 280.648" stroke="#FEEBEE" />
    <path d="M355.897 253.289L491.649 288.253" stroke="#FEEBEE" />
    <path d="M339.376 262L478.432 299.865" stroke="#FEEBEE" />
    <path d="M320.923 270.289L462.457 312.017" stroke="#FEEBEE" />
    <path d="M307.161 279.148L445.936 323.081" stroke="#FEEBEE" />
    <path d="M286.51 288.266L425.285 332.199" stroke="#FEEBEE" />
    <path d="M270.401 295.977L352.592 324.989" stroke="#FEEBEE" />
    <path d="M262.555 308.828L323.682 332.452" stroke="#FEEBEE" />
    <path d="M264.62 304.27L385.767 251.219" stroke="#FEEBEE" />
    <path d="M274.119 320.303L406.005 257.305" stroke="#FEEBEE" />
    <path
      d="M537.459 145.732C522.607 128.143 500.453 116.969 475.672 116.969C430.966 116.969 394.72 153.342 394.72 198.203C394.72 243.065 430.966 279.438 475.672 279.438C485.815 279.438 495.513 277.548 504.451 274.133C510.977 264.219 516.875 253.393 521.897 241.755C536.27 208.465 541.111 174.529 537.459 145.749V145.732Z"
      fill="#A88FEF"
      stroke="#483C72"
    />
    <path
      d="M536.255 138.181C521.419 119.696 498.687 107.859 473.179 107.859C428.473 107.859 392.227 144.233 392.227 189.094C392.227 233.955 428.473 270.328 473.179 270.328C487.7 270.328 501.297 266.482 513.093 259.768C516.248 253.999 519.205 248.014 521.898 241.764C537.56 205.507 541.889 168.503 536.255 138.181Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M528.902 223.182C533.627 208.692 536.584 194.302 537.873 180.459C533.676 148.363 506.318 123.578 473.194 123.578C437.145 123.578 407.937 152.889 407.937 189.063C407.937 225.237 437.145 254.548 473.194 254.548C496.769 254.548 517.436 241.998 528.902 223.182Z"
      fill="#FEEBEE"
      stroke="#483C72"
    />
    <path
      d="M467.099 182.268C466.983 182.467 466.851 182.682 466.735 182.881C464.753 186.18 463.051 189.032 461.597 191.319C458.045 196.89 454.559 199.94 450.908 200.62C444.762 201.78 437.212 196.31 429.216 190.491C426.953 188.849 424.591 187.142 422.212 185.534C417.867 182.599 413.224 181.273 408.4 181.522C408.119 183.992 407.954 186.495 407.954 189.032C407.954 189.877 407.987 190.706 408.02 191.535C411.077 191.154 413.869 191.883 416.694 193.773C418.94 195.282 421.204 196.923 423.401 198.531C432.752 205.328 442.4 212.341 452.759 210.368C459.218 209.141 464.852 204.649 469.94 196.641C471.427 194.32 473.112 191.502 475.045 188.269L467.099 182.218V182.268Z"
      fill="#FAA3CA"
    />
    <path
      d="M322.558 263.425C328.27 263.425 332.9 262.015 332.9 260.275C332.9 258.535 328.27 257.125 322.558 257.125C316.846 257.125 312.216 258.535 312.216 260.275C312.216 262.015 316.846 263.425 322.558 263.425Z"
      fill="#483C72"
    />
    <path
      d="M410.828 191.55C410.828 155.376 440.036 126.065 476.085 126.065C491.548 126.065 505.756 131.469 516.924 140.488C505.36 129.977 490.028 123.578 473.194 123.578C437.145 123.578 407.937 152.889 407.937 189.063C407.937 209.604 417.37 227.923 432.106 239.926C419.022 227.956 410.828 210.715 410.828 191.55Z"
      fill="#483C72"
      stroke="#483C72"
    />
    <path
      d="M527.35 110.383C525.599 106.786 523.682 103.371 521.551 100.188C519.007 100.27 516.463 100.585 513.902 101.132C482.645 107.83 459.383 146.657 445.489 169.867C443.507 173.166 441.805 176.017 440.351 178.305C436.799 183.876 433.313 186.926 429.662 187.606C423.517 188.766 415.967 183.295 407.971 177.476C405.707 175.835 403.345 174.127 400.966 172.519C395.134 168.59 388.806 167.529 382.149 169.32C365.975 173.696 348.644 195.331 329.15 235.451C324.309 245.398 320.179 249.642 318.064 251.134C317.965 249.211 318.362 245.465 320.575 239.496C326.193 224.377 326.589 215.159 321.914 209.639C318.61 205.743 313.356 204.483 306.285 205.875C290.26 209.058 261.844 244.37 245.323 266.917C245.984 271.443 246.86 275.836 247.967 280.064C264.52 256.423 295.86 218.077 308.201 215.623C311.291 215.01 313.604 215.176 314.364 216.071C314.859 216.668 317.09 220.415 311.291 236.015C304.352 254.732 310.63 259.788 313.653 261.082C320.922 264.198 330.042 256.257 338.055 239.795C360.374 193.872 375.755 181.339 384.726 178.902C388.592 177.858 392.011 178.438 395.448 180.759C397.695 182.267 399.958 183.909 402.155 185.517C411.506 192.314 421.154 199.327 431.513 197.354C437.972 196.127 443.606 191.634 448.694 183.627C450.231 181.223 451.965 178.322 453.981 174.94C466.19 154.548 488.906 116.6 515.967 110.798C519.8 109.985 523.583 109.82 527.35 110.334V110.383Z"
      fill="#5EBC89"
      stroke="#483C72"
    />
    <path
      d="M433.131 267.812C433.131 267.812 453.369 288.536 495.91 286.878L504.451 274.112C504.451 274.112 469.477 289.365 433.131 267.812Z"
      fill="#483C72"
    />
    <path
      d="M470.567 195.82C475.576 195.82 479.637 191.745 479.637 186.719C479.637 181.692 475.576 177.617 470.567 177.617C465.558 177.617 461.497 181.692 461.497 186.719C461.497 191.745 465.558 195.82 470.567 195.82Z"
      fill="#FAA3CA"
    />
    <path
      d="M500.174 140.981C491.368 144.015 484.347 148.574 484.347 148.574C484.347 148.574 486.346 149.436 489.056 151.094C480.035 161.953 472.403 173.973 466.257 184.135L474.567 189.573C480.415 179.924 488.015 167.971 496.903 157.328C497.977 158.455 499.001 159.665 499.91 161.008C499.91 161.008 505.411 149.436 506.386 147.198C509.277 140.567 508.996 137.948 500.191 140.981H500.174Z"
      fill="#FAA3CA"
    />
    <path
      d="M453.782 244.733C455.908 244.733 457.631 243.004 457.631 240.871C457.631 238.737 455.908 237.008 453.782 237.008C451.656 237.008 449.933 238.737 449.933 240.871C449.933 243.004 451.656 244.733 453.782 244.733Z"
      fill="#C1AAF7"
    />
    <path
      d="M489.301 139.46C491.427 139.46 493.151 137.731 493.151 135.597C493.151 133.464 491.427 131.734 489.301 131.734C487.176 131.734 485.452 133.464 485.452 135.597C485.452 137.731 487.176 139.46 489.301 139.46Z"
      fill="#C1AAF7"
    />
    <path
      d="M422.26 177.179C424.385 177.179 426.109 175.449 426.109 173.316C426.109 171.183 424.385 169.453 422.26 169.453C420.134 169.453 418.41 171.183 418.41 173.316C418.41 175.449 420.134 177.179 422.26 177.179Z"
      fill="#C1AAF7"
    />
    <path
      d="M523.831 215.772C525.957 215.772 527.68 214.043 527.68 211.91C527.68 209.776 525.957 208.047 523.831 208.047C521.705 208.047 519.981 209.776 519.981 211.91C519.981 214.043 521.705 215.772 523.831 215.772Z"
      fill="#C1AAF7"
    />
    <path
      d="M389.583 302.936C386.873 303.235 386.774 304.163 389.385 304.992L423.451 315.917C426.044 316.746 429.976 316.116 432.174 314.508L439.443 309.186C441.64 307.578 441.276 305.705 438.65 305.025L423.054 300.997C420.411 300.317 416.033 300.002 413.323 300.3L389.583 302.936Z"
      fill="#483C72"
    />
    <path
      d="M389.583 302.936C386.873 303.235 386.774 304.163 389.385 304.992L423.451 315.917C426.044 316.746 429.976 316.116 432.174 314.508L439.443 309.186C441.64 307.578 441.276 305.705 438.65 305.025L423.054 300.997C420.411 300.317 416.033 300.002 413.323 300.3L389.583 302.936Z"
      stroke="#483C72"
    />
    <path
      d="M270.83 176.83C301.625 105.493 364.47 64.1296 411.207 84.4382C417.551 87.2068 423.251 90.9867 428.323 95.5955C453.418 79.4315 480.38 74.4248 503.41 84.4382C506.863 85.9468 510.117 87.7705 513.207 89.8428C507.425 83.8911 500.701 79.0999 493.068 75.7842C470.038 65.7708 443.076 70.761 417.981 86.9415C412.909 82.3327 407.209 78.5528 400.865 75.7842C354.144 55.4756 291.299 96.8389 260.488 168.176C231.973 234.241 240.96 302.793 279.537 328.722C249.915 298.234 245.074 236.512 270.83 176.83Z"
      fill="#EDE9E3"
      stroke="#483C72"
    />
    <path
      d="M424.21 271.986L374.78 257.778C373.475 257.38 372.517 257.712 371.443 258.209C370.253 258.756 356.458 264.923 355.599 265.52C354.707 266.15 354.146 267.244 354.146 268.654V289.194C354.146 291.93 356.277 294.814 358.887 295.61L410.366 311.327C411.621 311.708 412.761 311.542 413.604 310.962C414.529 310.332 426.589 302.689 427.448 302.109C428.373 301.479 428.952 300.368 428.952 298.942V278.402C428.952 275.666 426.821 272.782 424.21 271.986Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M415.124 307.8C415.124 310.535 412.993 312.127 410.382 311.331L358.904 295.615C356.293 294.819 354.162 291.934 354.162 289.199V268.658C354.162 265.923 356.293 264.331 358.904 265.127L410.382 280.843C412.993 281.639 415.124 284.524 415.124 287.259V307.8Z"
      fill="#D7C4FA"
      stroke="#483C72"
    />
    <path
      d="M424.211 241.554L404.667 236.216C403.362 235.818 402.403 236.149 401.33 236.647C400.14 237.194 386.345 243.361 385.486 243.958C384.594 244.588 384.032 245.682 384.032 247.091V267.632C384.032 270.367 386.163 273.252 388.774 274.048L410.366 280.895C411.622 281.276 412.762 281.11 413.604 280.53C414.53 279.9 426.59 273.915 427.449 273.335C428.374 272.705 428.952 271.594 428.952 270.168V247.953C428.952 245.218 426.821 242.333 424.211 241.537V241.554Z"
      fill="#25A871"
      stroke="#483C72"
    />
    <path
      d="M415.124 277.368C415.124 280.103 412.993 281.695 410.383 280.899L388.79 274.052C386.18 273.257 384.049 270.372 384.049 267.636V247.096C384.049 244.36 386.18 242.769 388.79 243.564L410.383 250.411C412.993 251.207 415.124 254.092 415.124 256.827V277.368Z"
      fill="#88D0A3"
      stroke="#483C72"
    />
    <path d="M414.05 253.376L428.208 245.07" stroke="#483C72" />
    <path
      d="M411.093 281.368C411.093 281.368 413.571 282.893 414.397 284.402L428.159 276.66C428.159 276.66 428.291 274.024 426.788 273.609L414.265 280.108L411.093 281.352V281.368Z"
      fill="#483C72"
    />
    <path
      d="M337.971 305.001C344.577 305.001 349.932 303.234 349.932 301.055C349.932 298.876 344.577 297.109 337.971 297.109C331.365 297.109 326.01 298.876 326.01 301.055C326.01 303.234 331.365 305.001 337.971 305.001Z"
      fill="#483C72"
      stroke="#483C72"
    />
    <path
      d="M332.619 303.627C339.617 303.627 345.29 297.934 345.29 290.911C345.29 283.888 339.617 278.195 332.619 278.195C325.62 278.195 319.947 283.888 319.947 290.911C319.947 297.934 325.62 303.627 332.619 303.627Z"
      fill="#C1AAF7"
      stroke="#483C72"
    />
    <path
      d="M499.1 129.953C499.1 129.953 511.342 119.641 514.233 119.227L522.229 124.15C522.229 124.15 511.49 132.44 509.838 135.341L499.1 129.953Z"
      fill="#483C72"
    />
  </svg>
);
