import { styled } from "@linaria/react";
import { DatoCmsProductFamily } from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import {
  fromDesktopMd,
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";
import { Link } from "gatsby";
import { serif } from "../../styles/fonts.styles";

type Props = {
  families: DatoCmsProductFamily[];
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ProductExplorerFamiliesCardGrid = (props: Props) => {
  return (
    <Grid>
      {props.families.map(f => (
        <FamilyCard family={f} key={f.id} />
      ))}
    </Grid>
  );
};

const FamilyCardWrap = css`
  appearance: none;
  text-decoration: none;
  text-align: left;
  background-color: ${colors.purple50};
  color: ${colors.purple700};
  border: 1px solid ${colors.purple200};
  padding: 1em;
  cursor: pointer;
  border-radius: ${rSize("radius")};
  overflow: hidden;
  width: auto;
  display: grid;
  grid-template-rows: auto minmax(auto, 1fr) auto;
  grid-template-areas: "icon" "heading" "footer";
  grid-gap: 0.5em;
  text-align: center;
  ${fromTablet} {
    padding: 1.25em;
    text-align: left;
    grid-template-columns: minmax(0, 1fr) 6.4rem;
    grid-template-rows: auto minmax(auto, 1fr);
    grid-template-areas: "heading icon" "footer icon";
  }
  &:hover {
    border-color: ${colors.purple500};
  }
  h3 {
    grid-area: heading;
    font-size: 2rem;
    line-height: 1.2;
    font-family: ${serif};
    font-weight: 400;
    ${fromDesktopMd} {
      font-size: 2.4rem;
    }
  }
  p {
    grid-area: footer;
    font-size: 1.4rem;
  }
`;

const ProductFamilyIcon = styled.div`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff5;
  border-radius: 50%;
  aspect-ratio: 1;
  ${uptoTablet} {
    margin: 0.5em auto;
    width: 72px;
  }
  img {
    width: 4.8rem;
    max-width: 45%;
  }
`;

const FamilyCard = (props: { family: DatoCmsProductFamily }) => {
  return (
    <Link
      to={`/solutions/products/${props.family.slug}`}
      className={FamilyCardWrap}
    >
      <h3>{props.family.name?.replace("&", "and")}</h3>
      <p>
        <b>View products →</b>
      </p>
      {props.family.icon?.url && (
        <ProductFamilyIcon>
          <img src={props.family.icon?.url} />
        </ProductFamilyIcon>
      )}
    </Link>
  );
};

export default ProductExplorerFamiliesCardGrid;
