import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import AllCaps from "../typography/AllCaps";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import LogoCanva from "../thirdPartyLogos/LogoCanva";
import { LogoCode42 } from "../thirdPartyLogos/LogoCode42";
import LogoCoinbase from "../thirdPartyLogos/LogoCoinbase";
import { LogoElastic36h } from "../thirdPartyLogos/LogoElastic36h";
import LogoGitLab from "../thirdPartyLogos/LogoGitLab";
import LogoIntercom from "../thirdPartyLogos/LogoIntercom";
import LogoMars from "../thirdPartyLogos/LogoMars20h";
import LogoMcKesson from "../thirdPartyLogos/LogoMcKesson";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import LogoOpenTable from "../thirdPartyLogos/LogoOpenTable";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import { LogoRedditForLightBackgrounds } from "../thirdPartyLogos/LogoReddit";
import Spacing from "../layout/Spacing";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Inner = styled.div`
  border: 1px solid ${colors.purple200};
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  text-align: center;
`;

const Logos = styled.div`
  ${onlyPhones} {
    margin-left: -1em;
    margin-right: -1em;
  }
`;

const LogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${onlyPhones} {
    display: inline;
  }
  + * {
    margin-top: 3em;
  }
  > * {
    vertical-align: middle;
    ${onlyPhones} {
      margin: 1em 0;
      transform: scale(0.8);
    }
    ${fromTablet} {
      margin: 0.5em;
    }
  }
`;

export const SolutionByProductPageCustomerLogoSection = () => {
  return (
    <PageSection>
      <Inner>
        <AllCaps>Trusted by industry innovators</AllCaps>
        <Spacing size="xl" />
        <Logos>
          <LogoRow>
            <LogoCanva />
            <LogoCode42 />
            <LogoCoinbase />
            <LogoElastic36h />
            <LogoGitLab />
            <LogoIntercom />
          </LogoRow>
          <LogoRow>
            <LogoMars />
            <LogoMcKesson />
            <LogoOakRidge />
            <LogoOpenTable />
            <LogoSnowflake />
            <LogoRedditForLightBackgrounds />
          </LogoRow>
        </Logos>
      </Inner>
    </PageSection>
  );
};
