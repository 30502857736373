import { styled } from "@linaria/react";
import { Link } from "gatsby";
import gsap from "gsap";
import { cover, lighten } from "polished";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { first, last } from "../../../utils/array.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { rangesIntersect } from "../../../utils/math.utils";
import { when } from "../../../utils/promises.utils";
import { hideScrollbarsCSS } from "../../../utils/scroll.utils";
import MaxWidth from "../../layout/MaxWidth";
import Spacing from "../../layout/Spacing";
import { SectionHeading2 } from "../../typography/SectionHeading2";
import { Serif } from "../../typography/Serif";
import imageExcel from "../../../../static/images/formulas-page/timeline-excel.png";
import imageGoogle from "../../../../static/images/formulas-page/timeline-google-sheets.png";
import imageTines from "../../../../static/images/formulas-page/timeline-tines.png";
import imageVisiCalc from "../../../../static/images/formulas-page/timeline-visicalc.png";
import {
  fromDesktop,
  fromPhoneLg,
  fromTabletMd,
  onlyPhones,
  uptoDesktop,
  uptoPhoneLg,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import PageSection from "../../reusableSections/PageSection";
import { css } from "@linaria/core";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import { useSiteContext } from "../../../context/site.context";

const borderColor = lighten(0.1, colorsV4.warmBlack);

const FormulasPageTimelineSectionCard = styled.div`
  position: relative;
  ${uptoPhoneLg} {
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
  }
  ${fromPhoneLg} {
    border-radius: ${rSize("radius")};
    background-color: ${colorsV4.warmBlack900};
  }
  overflow: hidden;
  p {
    a {
      text-decoration-thickness: 0.75px;
      text-decoration-color: ${withOpacity(colorsV4.white, 0.5)};
      font-weight: 600;
      &:hover {
        text-decoration-color: ${colorsV4.white};
      }
    }
  }
`;

const SectionHeader = styled.div`
  position: relative;
  padding: ${rSize("cardSectionPadding")} ${rSize("cardSectionPadding")} 0
    ${rSize("cardSectionPadding")};
  ${onlyPhones} {
    text-align: center;
  }
  h2 {
    ${onlyPhones} {
      max-width: 8em;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const FormulasPageTimelineSection = () => {
  return (
    <PageSection>
      <FormulasPageTimelineSectionCard>
        <SectionHeader>
          <SectionHeading2>
            Proudly{" "}
            <Serif>
              <i>not</i> invented here
            </Serif>
          </SectionHeading2>
          <Spacing size="1em" />
          <MaxWidth maxWidth={widthInGrid(8)}>
            <p>
              In Tines, one <Link to="/docs/actions">action</Link> can reference
              another, just like the cells in a spreadsheet. So when it came to
              finding the perfect way of manipulating data for actions, the
              answer was right in front of us: formulas. We’re proud to tap into
              an almost 50 year history, and include one of the very best ideas
              in software directly in Tines.
            </p>
          </MaxWidth>
        </SectionHeader>
        <Spacing size="lg" />
        <Timeline />
      </FormulasPageTimelineSectionCard>
    </PageSection>
  );
};

const TimelineContainer = styled.div`
  position: relative;
`;

const TimelineLinesWrap = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;
const TimelineNav = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 16px;
  padding-right: 16px;
  ${fromTabletMd} {
    margin: 0 0 0 calc(${rSize("cardSectionPadding")} - 7px);
    padding-right: calc(${rSize("cardSectionPadding")} - 7px);
  }
  &:before {
    display: block;
    content: "";
    height: 2px;
    background-image: linear-gradient(
      to right,
      ${borderColor},
      ${borderColor} 95%,
      ${withOpacity(borderColor, 0)} 100%
    );
    position: absolute;
    left: 15px;
    right: 0;
    top: 14px;
  }
`;
const TimelineContentViewport = styled.div`
  display: flex;
  overflow: scroll;
  cursor: grab;
  user-select: none;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 16px;
  padding-left: 16px;
  ${fromTabletMd} {
    scroll-padding-left: calc(${rSize("cardSectionPadding")} - 7px);
    padding-left: calc(${rSize("cardSectionPadding")} - 7px);
  }
  &:active {
    cursor: grabbing;
  }
  &.interacting,
  &:active {
    scroll-snap-type: none;
  }
`;
const TimelineItem = styled.div`
  scroll-snap-align: start;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-template-areas: "header" "figure" "article";
  flex: 0 0 90%;
  padding-left: 38px;
  ${fromTabletMd} {
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: 14.7em minmax(0, 1fr);
    grid-template-areas: "header header" "article figure";
    padding-left: 48px;
  }
  ${fromDesktop} {
    flex: 0 0 ${widthInGrid(8, 1)};
  }
  grid-gap: ${rSize("gap")};
  margin-right: ${widthInGrid(1)};
  padding-top: 1em;
  padding-bottom: 2em;
  &:last-child {
    margin-right: 0;
    padding-right: ${rSize("cardSectionPadding")};
    flex: 0 0 100%;
    ${fromDesktop} {
      flex: 0 0 ${widthInGrid(9, 1)};
    }
  }
  ${fromTabletMd} {
    padding-bottom: 4em;
  }
  header {
    grid-area: header;
  }
  h2 {
    line-height: 26px;
    font-size: 24px;
    ${fromDesktop} {
      font-size: 28px;
      line-height: 32px;
    }
    span,
    em,
    svg {
      display: block;
    }
    svg {
      ${uptoDesktop} {
        width: 184px;
        height: 24px;
      }
    }
  }
  figure {
    grid-area: figure;
    aspect-ratio: 16/9;
    background-color: ${colorsV4.warmBlack};
    margin: 0;
    border-radius: 0.5em;
    overflow: hidden;
    ${uptoTabletMd} {
      width: 100%;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  article {
    grid-area: article;
    p {
      font-size: 1.4rem;
      ${fromDesktop} {
        font-size: 1.6rem;
      }
      color: ${withOpacity(colorsV4.white, 0.75)};
      + p {
        margin-top: 0.5em;
      }
    }
  }
`;

const Timeline = () => {
  const siteContext = useSiteContext();

  const navRef = useRef<HTMLDivElement>(null);
  const viewportRef = useRef<HTMLDivElement>(null);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [scrollLeftOnDragStart, setScrollLeftOnDragStart] = useState(0);
  const scrollLeftOnDragStartRef = useRef(scrollLeftOnDragStart);
  scrollLeftOnDragStartRef.current = scrollLeftOnDragStart;

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!viewportRef.current) return;
    const delta = e.movementX;
    if (delta === 0) return;
    viewportRef.current.scrollTo({
      left: viewportRef.current.scrollLeft - delta,
    });
  }, []);

  const findFirstVisibleSectionIndex = useCallback(() => {
    const sections = Array.from(viewportRef.current?.children ?? []);
    return sections.findIndex(el => {
      const left = (el as HTMLDivElement).offsetLeft;
      const width = (el as HTMLDivElement).clientWidth;
      const { scrollLeft, clientWidth } = viewportRef.current!;
      return rangesIntersect(
        [left - paddingLeft, left - paddingLeft + width * 0.5],
        [scrollLeft, scrollLeft + clientWidth]
      );
    });
  }, [paddingLeft]);

  const findCurrentSectionIndex = useCallback(() => {
    if (!viewportRef.current) return 0;
    const firstVisibleSectionIndex = findFirstVisibleSectionIndex();
    const sections = Array.from(
      viewportRef.current?.children ?? []
    ) as HTMLElement[];
    const section = sections[firstVisibleSectionIndex];
    const secondLastSection = sections[sections.length - 2];
    const hasScrolledPastSecondLastSection =
      viewportRef.current.scrollLeft >
      secondLastSection.offsetLeft + paddingLeft;
    return hasScrolledPastSecondLastSection
      ? sections.length - 1
      : sections.indexOf(section);
  }, [findFirstVisibleSectionIndex, paddingLeft]);

  const highlightCurrentButton = useCallback(() => {
    if (!viewportRef.current) return;
    const index = findCurrentSectionIndex();
    const buttons = Array.from(navRef.current?.children ?? []);
    const button = buttons[index];
    buttons.forEach(b => b.classList.toggle("current", button === b));
  }, [findCurrentSectionIndex]);

  const scrollToSection = useCallback(
    (section: HTMLElement) => {
      if (section)
        gsap.to(viewportRef.current, {
          scrollTo: {
            x: section,
            offsetX: paddingLeft,
          },
          ease: "power3.out",
          onComplete: () => {
            viewportRef.current?.classList.remove("interacting");
            highlightCurrentButton();
          },
        });
    },
    [highlightCurrentButton, paddingLeft]
  );

  const handleMouseDown = useCallback(() => {
    if (!viewportRef.current) return;
    setScrollLeftOnDragStart(viewportRef.current.scrollLeft);
    viewportRef.current.classList.add("interacting");
    window.addEventListener("mousemove", handleMouseMove);
    const currentSectionIndex = findCurrentSectionIndex();
    const handleDragEnd = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("blur", handleDragEnd);
      const hasNotMoved =
        viewportRef.current!.scrollLeft === scrollLeftOnDragStartRef.current;
      if (hasNotMoved) return;
      const forwards =
        viewportRef.current!.scrollLeft > scrollLeftOnDragStartRef.current;
      const sections = Array.from(viewportRef.current?.children ?? []);
      const section = (
        forwards
          ? sections[currentSectionIndex + 1] ?? last(sections)
          : sections[currentSectionIndex - 1] ?? first(sections)
      ) as HTMLElement;
      scrollToSection(section);
    };
    window.addEventListener("mouseup", handleDragEnd, { once: true });
    window.addEventListener("blur", handleDragEnd, { once: true });
  }, [handleMouseMove, findCurrentSectionIndex, scrollToSection]);

  const handleScroll = useCallback(() => {
    highlightCurrentButton();
  }, [highlightCurrentButton]);
  const handleButtonClick = useCallback(
    (index: number) => () => {
      const section = Array.from(viewportRef.current?.children ?? [])[
        index
      ] as HTMLElement;
      scrollToSection(section);
    },
    [scrollToSection]
  );

  useOnMount(() => {
    highlightCurrentButton();
    const detectPadding = () => {
      if (!viewportRef.current) return;
      setPaddingLeft(
        parseInt(getComputedStyle(viewportRef.current).paddingLeft ?? 0)
      );
    };
    window.addEventListener("resize", detectPadding);
    detectPadding();
    return () => {
      window.removeEventListener("resize", detectPadding);
    };
  });
  return (
    <TimelineContainer>
      {siteContext.isClient && (
        <TimelineLinesWrap>
          <svg
            width="24"
            height="24"
            className={css`
              position: fixed;
              top: -24px;
              left: -24px;
            `}
          >
            <defs>
              <linearGradient
                id="timeline-svg-line-linear-gradient"
                gradientTransform="rotate(90)"
              >
                <stop offset="10%" stopColor={borderColor} stopOpacity={1} />
                <stop offset="90%" stopColor={borderColor} stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
          {Array(4)
            .fill(null)
            .map((n, i) => (
              <TimelineItemLine
                key={i}
                index={i}
                paddingLeft={paddingLeft}
                navRef={navRef}
                viewportRef={viewportRef}
              />
            ))}
        </TimelineLinesWrap>
      )}
      <TimelineNav ref={navRef}>
        <Bullet color={colorsV4.green} onClick={handleButtonClick(0)} />
        <Bullet color={colorsV4.orange} onClick={handleButtonClick(1)} />
        <Bullet color={colorsV4.pink} onClick={handleButtonClick(2)} />
        <Bullet color={colorsV4.purple} onClick={handleButtonClick(3)} />
      </TimelineNav>
      <Spacing size="1em" />
      <TimelineContentViewport
        className={hideScrollbarsCSS}
        ref={viewportRef}
        onScroll={handleScroll}
        onMouseDown={handleMouseDown}
      >
        <TimelineItem>
          <header>
            <h2>
              <span>1979 - Visicalc</span>
              <TheSpreadsheet />
            </h2>
          </header>
          <figure>
            <img src={imageVisiCalc} />
          </figure>
          <article>
            <p>
              PCs owe everything to spreadsheets. In 1979 VisiCalc hit the stage
              and opened the door to personal computers.
            </p>
            <p>
              VisiCalc succeeded by allowing a much broader audience to be
              productive with computers. For the first time, the concept of
              formulas was introduced to a sheet of cells, allowing you to
              combine data and compute useful results.
            </p>
          </article>
        </TimelineItem>
        <TimelineItem>
          <header>
            <h2>
              <span>1987 – Excel</span>
              <Serif>250 million new programmers</Serif>
            </h2>
          </header>
          <figure>
            <img src={imageExcel} />
          </figure>
          <article>
            <p>
              We all meet equations and formulas from a young age in math and
              science classes. Those formulas and equations grew up with us into
              business with spreadsheets from Lotus 1-2-3 which displaced
              VisiCalc in 1983 and then Excel displacing Lotus again in 1987.
            </p>
            <p>
              Formulas made code feel like no code. Spreadsheets became a bigger
              and bigger hit. While other features came and went, formulas stood
              strong.
            </p>
          </article>
        </TimelineItem>
        <TimelineItem>
          <header>
            <h2>
              <span>2006 – Google sheets</span>
              <Serif>Internet spreadsheets</Serif>
            </h2>
          </header>
          <figure>
            <img src={imageGoogle} />
          </figure>
          <article>
            <p>
              Spreadsheets survived the arrival of the internet. Google Sheets
              revolutionized spreadsheets with live collaboration. Later
              entrants, like{" "}
              <a
                href="https://support.airtable.com/docs/formula-field-reference"
                {...externalLinkAttr}
              >
                Airtable
              </a>{" "}
              and{" "}
              <a
                href="https://help.equals.app/en/articles/5647752-supported-functions"
                {...externalLinkAttr}
              >
                Equals
              </a>
              , continue to take the metaphor to increasingly ambitious places.
            </p>
            <p>
              Through all of this innovation, formulas persist. <code>SUM</code>{" "}
              works exactly the same in Airtable and Equals as it did all the
              way back in VisiCalc.
            </p>
          </article>
        </TimelineItem>
        <TimelineItem>
          <header>
            <h2>
              <span>You are here – Tines</span>
              <Serif>Workflow automation</Serif>
            </h2>
            <p></p>
          </header>
          <figure>
            <img src={imageTines} />
          </figure>
          <article>
            <p>
              You can’t automate your security team with spreadsheets. We now
              live in a networked world with complex data flowing between
              different apps and APIs constantly.
            </p>
            <p>
              That’s where Tines comes in. Instead of cells, actions perform
              different jobs, like fetching data from other systems. But just
              like in cells, an action might need data from another to calculate
              a new value. Formulas live on.
            </p>
          </article>
        </TimelineItem>
      </TimelineContentViewport>
    </TimelineContainer>
  );
};

const TimelineItemLine = (props: {
  index: number;
  paddingLeft: number;
  navRef: RefObject<HTMLDivElement | null>;
  viewportRef: RefObject<HTMLDivElement | null>;
}) => {
  const { index, paddingLeft, navRef, viewportRef } = props;
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const width = Math.max(4, right - left);
  const [height, setHeight] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const remeasure = useCallback(() => {
    if (!viewportRef.current) return;
    const button = (
      Array.from(navRef.current?.children ?? []) as HTMLDivElement[]
    )[index];
    const section = (
      Array.from(viewportRef.current?.children ?? []) as HTMLDivElement[]
    )[index];
    if (!button) return;
    if (!section) return;
    const buttonEndX = Math.round(button.offsetLeft + 14);
    const sectionStartX = Math.round(
      section.offsetLeft -
        paddingLeft -
        (viewportRef.current?.scrollLeft ?? 0) +
        14
    );
    setFlipped(buttonEndX > sectionStartX);
    const left = Math.min(buttonEndX, sectionStartX);
    const right = Math.max(buttonEndX, sectionStartX);
    setLeft(left);
    setRight(right <= left + 2 ? left + 2 : right);
    setHeight(section.clientHeight + 100);
  }, [index, navRef, paddingLeft, viewportRef]);
  useEffect(() => {
    remeasure();
    window.addEventListener("resize", remeasure);
    let viewport = viewportRef.current;
    when(
      () => !!viewportRef.current,
      () => {
        viewport = viewportRef.current;
        viewport?.addEventListener("scroll", remeasure);
        remeasure();
      }
    );
    return () => {
      viewport?.removeEventListener("scroll", remeasure);
      window.removeEventListener("resize", remeasure);
    };
  }, [remeasure, viewportRef]);
  const topMaskId = `timeline-item-mask-${index}-top`;
  const bottomMaskId = `timeline-item-mask-${index}-bottom`;
  return (
    <TimelineItemLineSvg
      width={width}
      height={height}
      style={{
        transform: `translateX(${paddingLeft + left}px)`,
      }}
    >
      {width === 4 ? (
        <line
          x1={2}
          y1={0}
          x2={2.001}
          y2={height}
          stroke="url(#timeline-svg-line-linear-gradient)"
          strokeWidth={2}
        />
      ) : (
        <>
          <defs>
            <mask id={topMaskId}>
              <rect
                x={flipped ? width / 2 : -10}
                y={-24}
                width={(flipped ? width : width / 2) + 10}
                height={48 + 2}
                fill="white"
              />
            </mask>
            <mask id={bottomMaskId}>
              <rect
                x={flipped ? -10 : width / 2}
                y={24 - 1}
                width={(flipped ? width / 2 : width) + 10}
                height={height}
                fill="white"
              />
            </mask>
          </defs>
          <rect
            x={2}
            y={-24}
            width={width - 4}
            height={48}
            rx={Math.min((width - 4) / 2, 12)}
            fill="transparent"
            stroke={borderColor}
            strokeWidth={2}
            mask={`url(#${topMaskId})`}
          />
          <rect
            x={2}
            y={24}
            width={width - 4}
            height={height + 2}
            rx={Math.min((width - 4) / 2, 24)}
            fill="transparent"
            stroke="url(#timeline-svg-line-linear-gradient)"
            strokeWidth={2}
            mask={`url(#${bottomMaskId})`}
          />
        </>
      )}
    </TimelineItemLineSvg>
  );
};

const TimelineItemLineSvg = styled.svg`
  position: absolute;
  top: 15px;
  left: 0;
`;

const TheSpreadsheet = () => (
  <svg width="207" height="27" viewBox="0 0 207 27" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0H0V3H3H6V6V9V12V15V18V21H9V18V15V12V9V6V3H12H15V21H18V9H21H24V21H27V9H24V6H21H18V0H15H12H9H6H3ZM198 6H201V3H204V6H207V9H204V12V15V18H201V15V12V9H198V6ZM204 18H207V21H204V18ZM111 6H114H117V9H114H111V6ZM111 18V21H114H117H120V18V15V12V9H117V12H114H111V15H108V18H111ZM111 18V15H114H117V18H114H111ZM57 6H54V9H51V12H54V15H57H60V18H57H54H51V21H54H57H60V18H63V15H60V12H57H54V9H57H60H63V6H60H57ZM144 6H141V9H138V12H141V15H144H147V18H144H141H138V21H141H144H147V18H150V15H147V12H144H141V9H144H147H150V6H147H144ZM36 6H33V9H30V12V18H33V21H36H42V18H36H33V15H36H39H42V12V9H39V6H36ZM39 9V12H36H33V9H36H39ZM174 6H171V9H168V12V18H171V21H174H180V18H174H171V15H174H177H180V12V9H177V6H174ZM177 9V12H174H171V9H174H177ZM189 6H186V9H183V12V18H186V21H189H195V18H189H186V15H189H192H195V12V9H192V6H189ZM192 9V12H189H186V9H189H192ZM99 6H96V9H93V12V18H96V21H99H105V18H99H96V15H99H102H105V12V9H102V6H99ZM102 9V12H99H96V9H99H102ZM129 6H126V9H123V12V18H126V21H129H135V18V12V0H132V6H129ZM126 18H129H132V12V9H129H126V12V18ZM72 21H75V18H78V15V9H75V6H72L66 6V9V15V27H69V21H72ZM75 9V15V18H72H69V15V9L72 9L75 9ZM159 6H156V0H153V21H156V9H159H162V21H165V9H162V6H159ZM84 6H81V21H84V12H87V9H90V6H87V9H84V6Z"
    />
  </svg>
);

const Bullet = (props: { color: string; onClick: () => void }) => {
  return (
    <BulletButton style={{ color: props.color }} onClick={props.onClick}>
      <span />
      <span />
    </BulletButton>
  );
};

const BulletButton = styled.button`
  appearance: none;
  padding: 0;
  border: 0;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(1em);
  cursor: pointer;
  span {
    position: absolute;
    display: block;
    ${cover()};
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid currentColor;
    background-color: rgba(0, 0, 0, 0);
    &:first-child {
      opacity: 0.2;
    }
    &:last-child {
      transform: scale(0.38);
    }
  }
  &:hover {
    span {
      &:last-child {
        background-color: currentColor;
      }
    }
  }
  &.current {
    span {
      background-color: currentColor;
      &:first-child {
        @keyframes BulletHaloPulse {
          0% {
            opacity: 0.2;
          }
          50% {
            opacity: 0.4;
          }
          100% {
            opacity: 0.2;
          }
        }
        opacity: 0.2;
        animation-name: BulletHaloPulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }
    }
  }
`;

export default FormulasPageTimelineSection;
