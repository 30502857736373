import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import { fromDesktop } from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";

type Props = PropsWithChildren<{
  className?: string;
  columns: number;
}>;

export const LeaderboardChartOl = styled.ol<{ columns: number }>`
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromDesktop} {
    grid-template-columns: ${p => `repeat(${p.columns}, 1fr)`};
  }
`;

export const LeaderboardChartLi = styled.li<{
  color: string;
  index: number;
  centered?: boolean;
  padding?: string | number;
}>`
  position: relative;
  background-color: ${p => p.color ?? colorsV4.purple};
  color: ${colorsV4.white};
  padding: 1.25em;
  border-radius: 1em;
  margin-top: 2em;
  ${fromDesktop} {
    /* margin-top: ${p => `${p.index + 2}em`}; */
    padding: ${p => p.padding ?? "1.25em"};
  }
  h4 {
    color: inherit !important;
    margin-bottom: 0.5em;
    line-height: 1.25;
    font-size: 2rem;
  }
  p {
    font-size: 1.5rem;
    line-height: 1.35;
    opacity: 0.9;
    font-weight: 500;
  }
`;

const LeaderboardChartLiCircle = styled.div<{
  color: string;
  centered?: boolean;
}>`
  width: 4em;
  height: 4em;
  margin-top: -3em;
  margin-right: ${p => (p.centered ? "auto" : "unset")};
  margin-bottom: 0.5em;
  margin-left: ${p => (p.centered ? "auto" : "-1px")};
  font-weight: 700;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${colorsV4.white};
  color: ${p => p.color};
  border: ${p => `2px solid ${withOpacity(p.color, 0.5)}`};
`;

const LeaderboardChart = (props: Props) => {
  return (
    <LeaderboardChartOl className={props.className} columns={props.columns}>
      {props.children}
    </LeaderboardChartOl>
  );
};

export const LeaderboardChartItem = (
  props: PropsWithChildren<{
    index?: number;
    color: string;
    textInCircle?: string | number;
    centered?: boolean;
    padding?: string | number;
  }>
) => {
  return (
    <LeaderboardChartLi
      color={props.color}
      index={props.index ?? 0}
      centered={props.centered}
      padding={props.padding}
    >
      <LeaderboardChartLiCircle color={props.color} centered={props.centered}>
        {props.textInCircle ?? (props.index ?? 0) + 1}
      </LeaderboardChartLiCircle>
      {props.children}
    </LeaderboardChartLi>
  );
};

export default LeaderboardChart;
