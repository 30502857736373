import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import { PropsWithChildren, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSiteContext } from "../../context/site.context";
import { isBrowser } from "../../environment";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import {
  getResponsiveSizeAtCurrentBreakpoint,
  rSize,
} from "../../styles/responsiveSizes.styles";
import { zIndex } from "../../styles/zIndexes.styles";
import { useResizeObserver } from "../../utils/resizeObserver.utils";
import { hideScrollbarsCSS } from "../../utils/scroll.utils";
import { isNumber } from "../../utils/typeChecks.utils";
import { useOnWindowResize } from "../../utils/window.utils";
import DisablePageScroll from "../utilities/DisablePageScroll";

type Props = PropsWithChildren<{
  className?: string;
  name: string;
  backgroundColor?: string | BrandColorNameV4;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  borderRadius?: string | number;
  showCloseButton?: boolean;
  closeButtonBackgroundColor?: string;
  insetY?: string | number;
  canEscape?: boolean;
  clickOnBackdropShouldCloseModal?: boolean;
  onShouldClose?: () => void;
  colorScheme?: "light" | "dark";
}>;

const ModalFullscreenSection = styled.section<Props & { align: string }>`
  display: flex;
  align-items: ${p => (p.align === "center" ? "center" : "flex-start")};
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --modal-inset-y: ${p =>
    p.insetY !== undefined
      ? isNumber(p.insetY)
        ? `${p.insetY}px`
        : p.insetY
      : rSize("modalScreenDefaultMinInset")};
  padding-top: var(--modal-inset-y);
  padding-bottom: var(--modal-inset-y);
  padding-left: 0.5em;
  padding-right: 0.5em;
  z-index: ${zIndex("Modal")};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const ModalBackdrop = styled.div`
  ${cover()};
  position: fixed;
  background-color: ${withOpacity(colorsV4.black, 0.6)};
`;

export const ModalContainer = styled.div<Props>`
  position: relative;
  background-color: ${p => colorV4(p.backgroundColor ?? "white")};
  width: ${p => p.width ?? "62.5em"};
  height: ${p => p.height ?? "35em"};
  max-height: ${p => p.maxHeight ?? "calc(100vh - var(--modal-inset-y) * 2)"};
  transform: translateZ(0);
  border-radius: ${p =>
    isNumber(p.borderRadius) ? `${p.borderRadius}px` : p.borderRadius ?? ""};
  overflow: auto;
  box-shadow: ${p =>
    p.backgroundColor === "transparent"
      ? "none"
      : "0 1em 8em rgba(0, 0, 0, 0.15)"};
`;

const Modal = (props: Props) => {
  const siteContext = useSiteContext();
  const closeModal = () => {
    props.onShouldClose?.();
    siteContext.openModal(null);
  };
  const ref = useRef<HTMLDivElement>(null);
  const [align, setAlign] = useState("center");
  const resizeHandler = () => {
    const vh = window.innerHeight;
    const modalScrollHeight = ref.current?.scrollHeight ?? 0;
    const modalIsTooTallForWindowSize =
      vh <
      modalScrollHeight +
        getResponsiveSizeAtCurrentBreakpoint("modalScreenDefaultMinInset") * 2;
    setAlign(modalIsTooTallForWindowSize ? "start" : "center");
  };
  useResizeObserver(resizeHandler, ref);
  useOnWindowResize(resizeHandler);
  useHotkeys("escape", () => {
    if (props.canEscape) {
      closeModal();
    }
  });
  return isBrowser ? (
    <ModalFullscreenSection
      {...props}
      align={align}
      data-color-scheme={props.colorScheme}
      className={cx(
        props.className,
        props.colorScheme === "dark" && "supports-color-schemes",
        props.colorScheme === "dark" && "DARK_MODE"
      )}
    >
      <DisablePageScroll />
      <ModalBackdrop
        onClick={
          props.showCloseButton ||
          props.clickOnBackdropShouldCloseModal === false
            ? undefined
            : closeModal
        }
      />
      <ModalContainer
        className={cx("Modal", hideScrollbarsCSS, props.className)}
        {...props}
        ref={ref}
        data-name={props.name}
      >
        {props.children}
      </ModalContainer>
      {props.showCloseButton && (
        <ModalCloseButton
          position="top-right"
          onShouldClose={props.onShouldClose}
          backgroundColor={props.closeButtonBackgroundColor}
        />
      )}
    </ModalFullscreenSection>
  ) : null;
};

export const useOpenModalFunction = (modalName: string) => {
  const siteContext = useSiteContext();
  return () => siteContext.openModal(modalName);
};

type CloseButtonProps = {
  position?: "static" | "top-right";
  className?: string;
  iconColor?: string;
  backgroundColor?: string;
  onShouldClose?: () => void;
};

const CloseButton = styled.button<CloseButtonProps>`
  appearance: none;
  background-color: ${p =>
    p.backgroundColor ?? withOpacity(colorsV4.warmBlack, 0.8)};
  backdrop-filter: blur(1em);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  ${fromTablet} {
    top: 1.5em;
    right: 1.5em;
  }
  svg {
    display: block;
    width: 14px;
    height: 14px;
  }
`;

export const ModalCloseButton = (props: CloseButtonProps) => {
  const siteContext = useSiteContext();
  const closeModal = () => {
    props.onShouldClose?.();
    siteContext.openModal(null);
  };
  return (
    <CloseButton
      className={props.className}
      onClick={closeModal}
      name="close-modal"
      backgroundColor={props.backgroundColor}
      style={
        props.position === "top-right"
          ? {
              position: "fixed",
              top: "1em",
              right: "1em",
            }
          : undefined
      }
    >
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        stroke={props.iconColor ?? colorsV4.white}
        strokeWidth="2"
      >
        <path d="M18 1L1 18M1 1L18 18" />
      </svg>
    </CloseButton>
  );
};

export default Modal;
