import { styled } from "@linaria/react";
import AWSPartnerBadge from "./AWSPartnerBadge";
import BookADemoButton from "../../utilities/BookADemoButton";
import Button, { ButtonGroupCenterFlex } from "../../forms/Button";
import { whenDesktopNav, whenMobileNav } from "../../site/SiteNav";
import { uptoPhoneLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";
import HeroSection from "../../sections/HeroSection";
import AllCaps from "../../typography/AllCaps";
import SpaceChildren from "../../layout/SpaceChildren";

const BadgeWithText = styled.div`
  display: grid;
  grid-gap: 0.75em;
  text-align: left;
  ${whenMobileNav} {
    justify-content: center;
  }
  ${whenDesktopNav} {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: end;
  }
  p {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0.25em;
    ${whenMobileNav} {
      display: none;
    }
  }
`;
const Tagline = styled.p`
  margin-top: 0.75em;
  font-weight: 600;
  ${uptoPhoneLg} {
    font-size: 1.6rem !important;
  }
  ${whenDesktopNav} {
    display: none;
  }
`;

const AWSPageHero = () => {
  return (
    <HeroSection
      backdrop="grid"
      backgroundColor={colors.orange50}
      backdropColor={colors.orange200}
      largeHeading
      contentRelative
      textColor={colors.orange800}
    >
      <SpaceChildren size="gap">
        <AllCaps>Partnership</AllCaps>
        <h1>AWS × Tines</h1>
        <Tagline>Automate cloud security with AWS and Tines</Tagline>
        <ButtonGroupCenterFlex>
          <Button
            title="View Examples"
            color="orange"
            darker
            href="#example-stories"
          />
          <BookADemoButton appearance="outlined" color="orange" darker />
        </ButtonGroupCenterFlex>
        <BadgeWithText>
          <AWSPartnerBadge />
          <p>
            Automate cloud security
            <br />
            with AWS and Tines
          </p>
        </BadgeWithText>
      </SpaceChildren>
    </HeroSection>
  );
};

export default AWSPageHero;
