import { styled } from "@linaria/react";
import { cx } from "linaria";
import React, { RefObject, useState } from "react";
import { BrandColorNameV4, colorV4 } from "../../styles/colorsV4.styles";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import LoadingIndicator from "./LoadingIndicator";

type PropsPartialStyling = {
  width?: string | number;
  height?: string | number;
  backgroundColor?: string | BrandColorNameV4;
  innerRef?: RefObject<HTMLIFrameElement>;
};
type Props = {
  src?: string | null;
  title?: string | null;
  onClick?: (e: React.MouseEvent) => void;
  onLoad?: () => void;
  onError?: () => void;
} & PropsPartialStyling;

export const GenericIframeWrapper = styled.div<PropsPartialStyling>`
  width: ${p => valueWithOptionalUnit(p.width, 640)};
  height: ${p => valueWithOptionalUnit(p.height, 480)};
`;

const Iframe = styled.iframe<PropsPartialStyling>`
  position: relative;
  background-color: ${p => colorV4(p.backgroundColor ?? "white")};
  display: block;
  width: ${p => valueWithOptionalUnit(p.width, 640)};
  height: ${p => valueWithOptionalUnit(p.height, 480)};
`;

const GenericIframe = (props: Props) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const handleLoad = () => {
    setReady(true);
    props.onLoad?.();
  };
  const handleError = (e: unknown) => {
    setError(e);
    props.onError?.();
  };
  return (
    <GenericIframeWrapper
      className={cx(ready && "ready")}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
    >
      {!ready && (
        <LoadingIndicator
          centered
          color={props.backgroundColor === "transparent" ? "white" : undefined}
        />
      )}
      <Iframe
        frameBorder="0"
        allowFullScreen
        {...props}
        src={props.src ?? ""}
        title={props.title ?? undefined}
        onLoad={handleLoad}
        onError={handleError}
        ref={props.innerRef}
      />
    </GenericIframeWrapper>
  );
};

export default GenericIframe;
