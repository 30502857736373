import { styled } from "@linaria/react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { css } from "linaria";
import { FormulasPageFunctionsSectionQuery } from "../../../../graphql-types";
import {
  fromDesktop,
  fromTablet,
  fromTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { font, fontSerifDisplayRules } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import FormulasPill, {
  FormulasPillContainer,
} from "../../general/FormulasPill";
import Spacing from "../../layout/Spacing";
import PageSection from "../../reusableSections/PageSection";

const FormulasPageFunctionsGridSection = () => {
  const data = useStaticQuery<FormulasPageFunctionsSectionQuery>(
    formulasPageFunctionsSectionQuery
  );
  return (
    <PageSection>
      <FunctionExampleSectionInner>
        <header>
          <h2>
            <em>
              {Object.entries(
                JSON.parse(`${data.functionsPage?.jsonData ?? "{}"}`) as Record<
                  string,
                  unknown
                >
              ).length || "100+"}
            </em>
            <span>
              <strong>functions to date</strong>
            </span>
          </h2>
        </header>
        <Spacing size="xl" />
        <FunctionExampleGrid>
          <Link
            className={functionExampleCardStyle}
            to="/docs/formulas/functions/sum"
          >
            <h3>SUM</h3>
            <p>Summing up numbers since 1979.</p>
            <FormulasPill f="SUM(array)" />
          </Link>
          <Link
            className={functionExampleCardStyle}
            to="/docs/formulas/functions/is_ip_address"
          >
            <h3>IS_IP_ADDRESS</h3>
            <p>Checks if a text value is a valid IP address.</p>
            <FormulasPill f="IS_IP_ADDRESS(text)" />
          </Link>
          <Link
            className={functionExampleCardStyle}
            to="/docs/formulas/functions/lambda"
          >
            <h3>LAMBDA</h3>
            <p>Creates a custom, reusable function.</p>
            <FormulasPill f="LAMBDA([param1, param2, …,] calculation)" />
          </Link>
          <Link
            className={functionExampleCardStyle}
            to="/docs/formulas/functions/sha256"
          >
            <h3>SHA256</h3>
            <p>Calculates the sha256 hash of text.</p>
            <FormulasPill f="SHA256(text)" />
          </Link>
          <Link
            className={functionExampleCardStyle}
            to="/docs/formulas/functions/zip"
          >
            <h3>ZIP</h3>
            <p>Creates a ZIP archive containing a given file.</p>
            <FormulasPill f="ZIP(file, [filename], [password])" />
          </Link>
          <Link className={functionExampleCardStyle} to="/docs/formulas">
            <h3>Learn more</h3>
            <p>Read the formulas documentation.</p>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none">
              <circle cx="22.5" cy="22.5" r="22.5" fill="#8578E6" />
              <path
                d="M23.7071 15.2928L30.9142 22.5L23.7071 29.7071L22.2929 28.2928L27.0858 23.5H15V21.5H27.0858L22.2929 16.7071L23.7071 15.2928Z"
                fill="#FFFDFB"
              />
            </svg>
          </Link>
        </FunctionExampleGrid>
      </FunctionExampleSectionInner>
    </PageSection>
  );
};

const FunctionExampleSectionInner = styled.div`
  h2 {
    text-align: center;
    font-size: 2.4rem;
    ${fromTablet} {
      font-size: 2.8rem;
    }
    ${fromDesktop} {
      font-size: 3.2rem;
    }
    em {
      font-style: normal;
      display: block;
      ${fontSerifDisplayRules}
      font-size: 250%;
      font-weight: 200;
      margin-bottom: 1.6rem;
    }
    span {
      display: flex;
      align-items: center;
      strong {
        display: block;
        font-weight: inherit;
        flex: 0 0 auto;
        padding-left: 1em;
        padding-right: 1em;
      }
      &:before,
      &:after {
        content: "";
        height: 2px;
        background-color: currentColor;
        opacity: 0.2;
        flex: 1 1 50%;
      }
    }
  }
  ${FormulasPillContainer} {
    color: ${colorsV4.purple};
  }
`;

const FunctionExampleGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  width: 100%;
  grid-template-columns: minmax(0, 1fr);
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`;

const functionExampleCardStyle = css`
  display: block;
  text-decoration: none;
  padding: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  background-color: ${colorsV4.warmBlack900};
  &:hover {
    filter: brightness(1.1);
  }
  h3 {
    font-family: ${font("serif")};
    font-weight: 400;
    font-size: 2rem;
    ${fromTabletMd} {
      font-size: 2.4rem;
    }
  }
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
  > svg {
    float: right;
  }
`;

const formulasPageFunctionsSectionQuery = graphql`
  query FormulasPageFunctionsSection {
    functionsPage: datoCmsDocsPage(slug: { eq: "functions" }) {
      id
      jsonData
    }
  }
`;

export default FormulasPageFunctionsGridSection;
