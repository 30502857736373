import PodcastEntry from "./PodcastEntry";
import { styled } from "@linaria/react";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import { graphql, useStaticQuery } from "gatsby";
import { PodcastListQuery } from "../../../graphql-types";
import {
  PodcastRecord,
  podcastEpisodesSeason1to4,
} from "../../data/podcastEpisodesSeason1to4";
import dayjs from "dayjs";
import StandardArticleStructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";

const SeasonList = styled.div`
  > * {
    + * {
      margin-top: 3em;
      ${fromTablet} {
        margin-top: 4em;
      }
      ${fromDesktop} {
        margin-top: 5em;
      }
    }
  }
`;

const EpisodeList = styled.div`
  > * {
    + * {
      margin-top: 3em;
      ${fromTablet} {
        margin-top: 4em;
      }
      ${fromDesktop} {
        margin-top: 5em;
      }
    }
  }
`;

const SeasonSection = styled.section``;

const SectionHeader = styled.header`
  h2 {
    font-family: ${serif};
    font-weight: 400;
    display: flex;
    align-items: center;
    span {
      display: block;
      white-space: nowrap;
    }
    &:after {
      content: "";
      height: 1px;
      background-color: ${colors.dark600};
      display: block;
      flex: 1 1 auto;
      margin-left: 1em;
    }
  }
  padding-bottom: ${rSize("xl")};
`;

const PodcastEpisodeList = () => {
  const data = useStaticQuery<PodcastListQuery>(podcastListQuery);
  const season5Episodes = (data.episodes.nodes ?? []).map(e => {
    return {
      episodeNumber: e.episodeNumber,
      title: e.title,
      summary: (
        <StandardArticleStructuredTextWithCustomBlocks value={e.description} />
      ),
      datePublished: dayjs(`${e.datePublished ?? ""}`).format("LL"),
      duration: e.durationInMinutes ? `${e.durationInMinutes} min` : "",
      coverImageSrc: e.imageSquare?.url ?? "",
      links: {
        apple: e.applePodcastsLink,
        spotify: e.spotifyLink,
        youtube: e.youtubeLink,
      },
    } as PodcastRecord;
  });

  const seasons = [
    ...podcastEpisodesSeason1to4,
    {
      seasonName: "Season 5",
      episodes: season5Episodes,
    },
  ];

  return (
    <SeasonList>
      {[...seasons].reverse().map(season => (
        <SeasonSection key={season.seasonName}>
          <SectionHeader>
            <h2>
              <span>{season.seasonName}</span>
            </h2>
          </SectionHeader>
          <EpisodeList>
            {[...season.episodes].reverse().map(ep => (
              <PodcastEntry record={ep} key={ep.episodeNumber} />
            ))}
          </EpisodeList>
        </SeasonSection>
      ))}
    </SeasonList>
  );
};

export const podcastListQuery = graphql`
  query podcastList {
    episodes: allDatoCmsPodcast(
      filter: { episodeNumber: { gte: 29 } }
      sort: { episodeNumber: ASC }
    ) {
      nodes {
        applePodcastsLink
        color
        datePublished
        durationInMinutes
        episodeNumber
        imageSquare {
          url
        }
        # season {
        #   seasonNumber
        #   title
        # }
        spotifyLink
        youtubeLink
        title
        description {
          value
        }
      }
    }
  }
`;

export default PodcastEpisodeList;
