import { styled } from "@linaria/react";
import { UseStateReturnType } from "../../types/helper.types";
import { setUrlQueryParam } from "../../utils/urlQueryParams.utils";
import TextInput, { TextInputStyled } from "../forms/TextInput";
import { cx } from "linaria";
import { withOpacity } from "../../styles/colorsV4.styles";
import { colors } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";

type Props<T extends {}> = {
  id?: string;
  formState: UseStateReturnType<T>;
  field: keyof T & string;
  onChange?: (query?: string | null) => void;
  onEnter?: (query: string) => void;
  placeholder?: string;
  borderRadius?: string | number;
  keepInUrl?: boolean;
  icon?: "left" | "right";
  autoFocus?: boolean;
};

export const GenericSearchBarContainer = styled.div`
  position: relative;
  ${TextInputStyled} {
    border: 2px solid transparent;
    background-color: ${withOpacity(colors.light400, 0.4)};
    border-radius: 0.5em;
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark500, 0.8)};`
    )}
    &:hover {
      border-color: ${colors.purple};
    }
  }
  &.withIcon {
    svg {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &.left {
      input {
        padding-left: 2.5em;
      }
      svg {
        left: 1em;
      }
    }
    &.right {
      input {
        padding-right: 2.5em;
      }
      svg {
        right: 1em;
      }
    }
  }
`;

function GenericSearchBar<T extends {}>(props: Props<T>) {
  const handleChange = (query?: string | null) => {
    props.onChange?.(query);
    if (props.keepInUrl) {
      if (query) setTimeout(() => setUrlQueryParam("s", query));
      // else removeUrlQueryParam("s");
    }
  };
  // useOnMount(() => () => {
  //   if (props.keepInUrl) {
  //     const query = getUrlQueryParam("s");
  //     if (query) props.onChange?.(query);
  //   }
  // });
  return (
    <GenericSearchBarContainer
      className={cx(props.icon && "withIcon", props.icon)}
    >
      <TextInput
        id={props.id}
        formState={props.formState}
        name={props.field}
        onValueChange={handleChange}
        placeholder={props.placeholder ?? "Search..."}
        onEnter={props.onEnter}
        borderRadius={props.borderRadius}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck="false"
        autoFocus={props.autoFocus}
      />
      {props.icon && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6ZM9.16437 9.87147C8.30243 10.5768 7.20063 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 7.20063 10.5768 8.30243 9.87147 9.16437L14.3536 13.6464C14.5488 13.8417 14.5488 14.1583 14.3536 14.3536C14.1583 14.5488 13.8417 14.5488 13.6464 14.3536L9.16437 9.87147Z"
            fill="currentColor"
          />
        </svg>
      )}
    </GenericSearchBarContainer>
  );
}

export default GenericSearchBar;
