import { styled } from "@linaria/react";
import { DatoCmsPartnersLogoSetSection } from "../../../../graphql-types";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromPhoneLg,
  fromTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import PageSection from "../../reusableSections/PageSection";
import { SectionHeading2 } from "../../typography/SectionHeading2";
import { DoubleRingPartnerIcon } from "../../illustrations/spots/DoubleRingPartnerIcon";
import Spacing from "../../layout/Spacing";

const Contents = styled.div`
  text-align: center;
  h2 {
    ${fromDesktop} {
      max-width: ${widthInGrid(7)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const PartnerLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: ${widthInGrid(10)};
  margin-left: auto;
  margin-right: auto;
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    flex: 0 0 50%;
    height: 7rem;
    ${fromPhoneLg} {
      flex: 0 0 33.33%;
    }
    ${fromTabletLg} {
      flex: 0 0 auto;
      padding: 1.5em;
    }
    ${fromDesktopMd} {
      height: 9rem;
      padding: 1.75em;
    }
  }
  img {
    display: block;
    max-height: 32px;
    height: auto;
  }
`;

export const LPPartnersLogoSetSection = ({
  section,
}: {
  section: DatoCmsPartnersLogoSetSection;
}) => {
  return (
    <PageSection id={section.id}>
      <Contents>
        <DoubleRingPartnerIcon />
        <Spacing size="xl" />
        <SectionHeading2 lighter>{section.heading}</SectionHeading2>
        <Spacing size="lg" />
        <PartnerLogos>
          {section.partnerLogos?.map((partner, i) => (
            <div key={i}>
              <img
                src={`${partner?.logo?.url}`}
                style={{
                  aspectRatio: `${partner?.logo?.width}/${partner?.logo?.height}`,
                }}
              />
            </div>
          ))}
        </PartnerLogos>
      </Contents>
    </PageSection>
  );
};
