import { css, cx } from "linaria";
import { CSSProperties, PropsWithChildren } from "react";
import { serif } from "../../styles/fonts.styles";
import {
  PrimaryColorName,
  SecondaryColorName,
  brandColorThemeVar,
  color,
  colors,
  getBrandColorThemeVariables,
} from "../../styles/colors.styles";
import { Link } from "gatsby";
import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromDesktopSm,
  fromPhoneLg,
  fromTablet,
  fromTabletMd,
  onlyPhones,
  uptoPhoneLg,
  uptoTablet,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import RigidDotGrid from "../decorations/RigidDotGrid";
import { isSameOrigin } from "../../utils/urls.utils";
import { externalLinkAttr } from "../../constants/externalLinks.constants";

export const ContentCardBaseStyle = css`
  position: relative;
  background-color: ${brandColorThemeVar(50)};
  border: 1px solid ${brandColorThemeVar(200)};
  color: ${brandColorThemeVar(700)};
  border-radius: 8px;
  text-decoration: none;
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  @media (hover: hover) {
    &:hover {
      border-color: ${brandColorThemeVar(400)};
      header p {
        &:last-child {
          strong:only-child {
            &:after {
              transform: translateX(3px);
            }
          }
        }
      }
    }
  }
  &[target="_blank"] {
    @media (hover: hover) {
      &:hover {
        header p {
          &:last-child {
            strong:only-child {
              &:after {
                transform: translate(1px, -1px);
              }
            }
          }
        }
      }
    }
  }
  &.large {
    color: ${brandColorThemeVar(800)};
  }
  &.v50.pink {
    color: ${brandColorThemeVar(700)};
  }
  &.v100 {
    background-color: ${brandColorThemeVar(100)};
    color: ${brandColorThemeVar(800)};
    border: 1px solid ${brandColorThemeVar(200)};
    @media (hover: hover) {
      &:hover {
        border-color: ${brandColorThemeVar(300)};
      }
    }
  }
  &.v200 {
    background-color: ${brandColorThemeVar(200)};
    color: ${brandColorThemeVar(800)};
    border: 1px solid ${brandColorThemeVar(300)};
    @media (hover: hover) {
      &:hover {
        border-color: ${brandColorThemeVar(300)};
      }
    }
  }
  &.v500 {
    background-color: ${brandColorThemeVar(500)};
    border-color: ${brandColorThemeVar(400)};
    color: ${colors.white};
    @media (hover: hover) {
      &:hover {
        border-color: ${brandColorThemeVar(300)};
      }
    }
  }
  &.v600 {
    background-color: ${brandColorThemeVar(600)};
    border-color: ${brandColorThemeVar(400)};
    color: ${colors.white};
    @media (hover: hover) {
      &:hover {
        border-color: ${brandColorThemeVar(300)};
      }
    }
  }
  &.v700 {
    background-color: ${brandColorThemeVar(700)};
    border-color: ${brandColorThemeVar(400)};
    color: ${colors.white};
    @media (hover: hover) {
      &:hover {
        border-color: ${brandColorThemeVar(300)};
      }
    }
  }
  &.v800 {
    background-color: ${brandColorThemeVar(800)};
    border-color: ${brandColorThemeVar(400)};
    color: ${colors.white};
    @media (hover: hover) {
      &:hover {
        border-color: ${brandColorThemeVar(300)};
      }
    }
  }
`;

const Content = styled.div`
  padding: 0.875em;
  ${fromTablet} {
    padding: 1.25em;
  }
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  .large & {
    ${fromPhoneLg} {
      padding: 2.4rem;
    }
    ${fromTabletMd} {
      padding: 2.4rem 2.8rem 2.4rem;
    }
    ${fromDesktopMd} {
      padding: 3rem 3.6rem 3.2rem;
    }
  }
  header {
    position: relative;
    z-index: 1;
    > * {
      + * {
        margin-top: 0.5rem;
        ${fromPhoneLg} {
          margin-top: 0.75rem;
        }
      }
    }
    > div {
      > * {
        + * {
          margin-top: 0.5rem;
          ${fromPhoneLg} {
            margin-top: 0.75rem;
          }
        }
      }
    }
    p {
      &:first-child {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      }
      &:last-child {
        strong:only-child {
          margin-right: -0.5em;
          user-select: none;
          ${onlyPhones} {
            font-weight: 600;
            font-size: 1.2rem;
          }
          &:after {
            display: inline-block;
            vertical-align: baseline;
            content: "→";
            transition: 0.2s;
            margin-left: 0.25em;
            [target="_blank"] & {
              vertical-align: 35%;
              content: "↗";
              font-size: 80%;
              margin-left: 0.15em;
            }
          }
        }
      }
    }
    .large & {
      max-width: 26rem;
      ${fromTabletMd} {
        display: grid;
        grid-template-rows: minmax(auto, 1fr) auto;
      }
    }
    ${uptoTabletMd} {
      .large[data-size="2/3"] & {
        display: grid;
        grid-template-rows: minmax(auto, 1fr) auto;
      }
    }
  }
  h4 {
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 1.2;
    [data-size="2/3"] & {
      ${uptoPhoneLg} {
        font-size: 1.6rem;
      }
    }
    ${fromPhoneLg} {
      font-weight: 400;
      font-family: ${serif};
      line-height: 1.1;
      font-size: 2.2rem;
      margin-right: 0;
    }
    ${fromTabletMd} {
      .compact & {
        &:first-child {
          margin-top: -0.1em;
        }
      }
      .large & {
        font-size: 2.8rem;
      }
    }
    ${fromDesktopSm} {
      .large & {
        font-size: 2.6rem;
      }
    }
    ${fromDesktopMd} {
      .large & {
        font-size: 2.8rem;
      }
    }
    + p {
      ${onlyPhones} {
        &:not(:only-of-type) {
          display: none;
        }
        [data-size="2/3"] & {
          display: block;
          max-width: 14em;
        }
      }
    }
  }
  p {
    line-height: 1.25;
    font-size: 1.3rem;
    opacity: 0.8;
    ${fromTablet} {
      font-size: 1.4rem;
      opacity: 1;
    }
  }
  figure {
    margin: 1em 0 0 0;
    display: flex;
    justify-content: flex-end;
    .large & {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: inherit;
      ${fromTablet} {
        margin-right: -0.25em;
        margin-bottom: -0.375em;
      }
      > img,
      > svg {
        ${uptoTablet} {
          max-height: 72px;
          width: auto;
        }
        ${fromTablet} {
          max-width: 100%;
        }
        height: auto;
      }
    }
  }
`;
const DottedBackdropWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  mix-blend-mode: multiply;
  ${onlyPhones} {
    display: none;
  }
`;

export type ContentCardSize = "1/3" | "2/3" | "1/2";
export type ContentCardVariant =
  | "50"
  | "100"
  | "200"
  | "500"
  | "600"
  | "700"
  | "800";

const ContentCardBase = (
  props: PropsWithChildren<{
    className?: string;
    link: string;
    large?: boolean;
    size?: ContentCardSize | null;
    variant?: ContentCardVariant;
    color?: PrimaryColorName | SecondaryColorName;
    style?: CSSProperties;
    design?: string | null;
    backgroundImage?: string | null;
  }>
) => {
  const isInternalLink = isSameOrigin(props.link);
  const attr = {
    className: cx(
      "ContentCard",
      ContentCardBaseStyle,
      props.className,
      props.large ? "large" : "compact",
      props.color,
      `v${props.variant ?? (props.color === "yellow" ? "100" : "50")}`
    ),
    style: {
      ...getBrandColorThemeVariables(props.color),
      ...{
        ...props.style,
        backgroundImage: props.backgroundImage
          ? `url(${props.backgroundImage})`
          : props.style?.backgroundImage,
      },
    },
    "data-size": props.large ? props.size ?? "1/3" : undefined,
  };
  const children = (
    <>
      {props.design === "design:dotted" && (
        <DottedBackdropWrap>
          <RigidDotGrid
            color={color(props.color, 300)}
            cellSize={24}
            dotSize={1}
          />
        </DottedBackdropWrap>
      )}
      <Content>{props.children}</Content>
    </>
  );
  return isInternalLink ? (
    <Link {...attr} to={props.link}>
      {children}
    </Link>
  ) : (
    <a {...attr} {...externalLinkAttr} href={props.link}>
      {children}
    </a>
  );
};

export default ContentCardBase;
