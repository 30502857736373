import { styled } from "@linaria/react";
import {
  DatoCmsActionTemplate,
  DatoCmsProduct,
  DatoCmsProductFamily,
} from "../../../graphql-types";
import {
  fromDesktopMl,
  fromPhoneLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import ListWithPagination from "../basic/ListWithPagination";
import ActionTemplateEntry from "./ActionTemplateEntry";
import { colors } from "../../styles/colors.styles";
import { withOpacity } from "../../styles/colorsV4.styles";
import { SearchTwoToneIcon } from "../icons/twoTone/SearchTwoToneIcon";
import { useEffect, useState } from "react";
import { SearchableEntry } from "../../types/helper.types";
import { formatAsSearchString } from "../../utils/search.utils";
import Spacing from "../layout/Spacing";

type Props = (
  | {
      product: DatoCmsProduct;
    }
  | {
      family: DatoCmsProductFamily;
    }
) & {
  actionTemplates: DatoCmsActionTemplate[];
  showProductName?: boolean;
};

const ActionTemplateBrowserWrap = styled.div`
  position: relative;
`;

const SearchbarWrap = styled.div`
  display: flex;
  position: relative;
  height: 3em;
  align-items: stretch;
  font-weight: 500;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0.75em;
  transform: translateY(-50%);
  z-index: 2;
  pointer-events: none;
`;

const SearchbarInput = styled.input`
  width: 100%;
  flex: 1 1 100%;
  position: relative;
  border-radius: 0.5em;
  padding: 0.5em 1em 0.5em 3em;
  border: 1px solid ${colors.purple200};
  background-color: ${colors.lightest};
  color: inherit;
  font: inherit;
  &:focus {
    z-index: 1;
  }
  &::placeholder {
    color: ${withOpacity(colors.purple800, 0.5)};
  }
`;

const ResultListWrap = styled.div``;

const ResultList = styled.div`
  display: grid;
  grid-gap: 0.5em;
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromTabletMd} {
    grid-gap: ${rSize("gap")};
    grid-template-columns: repeat(3, 1fr);
  }
  ${fromDesktopMl} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ActionTemplateBrowser = (props: Props) => {
  const [templates, setTemplates] = useState(
    props.actionTemplates as SearchableEntry<DatoCmsActionTemplate>[]
  );
  const formState = useState(() => ({
    query: "",
  }));
  useEffect(() => {
    setTemplates(
      props.actionTemplates
        .sort((a, b) => {
          const an = `${a.name}`;
          const bn = `${b.name}`;
          return an > bn ? 1 : an < bn ? -1 : 0;
        })
        .map(t => {
          (t as SearchableEntry<DatoCmsActionTemplate>).__searchString =
            formatAsSearchString(t.name ?? "");
          return t as SearchableEntry<DatoCmsActionTemplate>;
        })
    );
  }, [props.actionTemplates]);
  const formattedQuery = formatAsSearchString(formState[0].query);
  const results = formattedQuery
    ? templates.filter(t => t.__searchString.includes(formattedQuery))
    : templates;
  return (
    <ActionTemplateBrowserWrap>
      <SearchbarWrap id="action-templates-search-bar">
        <SearchIconContainer>
          <SearchTwoToneIcon />
        </SearchIconContainer>
        <SearchbarInput
          value={formState[0].query}
          type="search"
          placeholder="Find a template..."
          onChange={e =>
            formState[1]({
              ...formState[0],
              query: e.target.value,
            })
          }
        />
      </SearchbarWrap>
      <Spacing size="gap" />
      <ListWithPagination
        defaultPerPage={20}
        allowCustomPerPageCount
        entries={results}
        scrollToHashOnNavigation="#action-templates-search-bar"
        appearance="outlined"
        children={entries => {
          return (
            <ResultListWrap>
              <ResultList>
                {entries.map(actionTemplate => (
                  <ActionTemplateEntry
                    actionTemplate={actionTemplate}
                    key={actionTemplate.key}
                    product={
                      ("product" in props
                        ? props.product
                        : props.family.products?.find(
                            p => p!.id === actionTemplate.product?.id
                          )) as DatoCmsProduct
                    }
                    showProductName={"family" in props}
                  />
                ))}
              </ResultList>
            </ResultListWrap>
          );
        }}
      />
    </ActionTemplateBrowserWrap>
  );
};

export default ActionTemplateBrowser;
