export const CasesMediumIllustration = () => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M246.763 252.599H205.096V266.353H261.966L246.763 252.599Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M80.1994 164.613C76.3882 164.613 72.9673 166.284 70.6277 168.932C68.2875 166.284 64.8671 164.613 61.056 164.613C57.2448 164.613 53.8238 166.284 51.4845 168.932C49.1444 166.284 45.7243 164.613 41.9125 164.613C38.1014 164.613 34.6807 166.284 32.3411 168.932C30.001 166.284 26.5803 164.613 22.7691 164.613C15.7169 164.613 10 170.331 10 177.383C10 184.435 10.0507 190.153 10.0507 190.153H92.986C92.986 190.153 92.9688 184.435 92.9688 177.383C92.9688 170.331 87.2516 164.613 80.1994 164.613Z"
      fill="#6956A8"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M277.009 164.613C273.197 164.613 269.777 166.284 267.437 168.932C265.097 166.284 261.677 164.613 257.865 164.613C254.054 164.613 250.633 166.284 248.294 168.932C245.954 166.284 242.534 164.613 238.722 164.613C234.911 164.613 231.49 166.284 229.15 168.932C226.811 166.284 223.39 164.613 219.579 164.613C212.526 164.613 206.81 170.331 206.81 177.383C206.81 184.435 206.86 190.153 206.86 190.153H289.796C289.796 190.153 289.778 184.435 289.778 177.383C289.778 170.331 284.061 164.613 277.009 164.613Z"
      fill="#6956A8"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M55.9048 284C64.3088 284 71.1216 283.092 71.1216 281.971C71.1216 280.85 64.3088 279.942 55.9048 279.942C47.5008 279.942 40.688 280.85 40.688 281.971C40.688 283.092 47.5008 284 55.9048 284Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M240.419 228.209C240.419 231.683 237.577 234.525 234.103 234.525H65.1198C61.6456 234.525 58.8032 231.683 58.8032 228.209V30.0099C58.8032 26.5355 61.6456 23.6934 65.1198 23.6934H234.103C237.577 23.6934 240.419 26.5355 240.419 30.0099L240.419 228.209Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M234.736 29.749H64.4863V242.235H234.736V29.749Z"
      fill="#C2AAFA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M234.517 29.4287H65.2891V31.5343H234.517V29.4287Z"
      fill="#483C72"
    />
    <path
      d="M66.2854 29.2705H64.3115V230.202H66.2854V29.2705Z"
      fill="#483C72"
    />
    <path
      d="M214.328 198.655C205.736 198.655 198.024 202.42 192.75 208.389C187.475 202.42 179.763 198.655 171.171 198.655C162.579 198.655 154.866 202.42 149.592 208.389C144.317 202.42 136.606 198.655 128.013 198.655C119.421 198.655 111.71 202.42 106.434 208.389C101.16 202.42 93.448 198.655 84.8552 198.655C68.9559 198.655 56.0674 211.544 56.0674 227.443C56.0674 243.342 56.1811 256.233 56.1811 256.233H243.155C243.155 256.233 243.117 243.342 243.117 227.443C243.117 211.544 230.228 198.655 214.328 198.655Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.3324 127.294C81.5922 126.544 80.5635 126.08 79.4258 126.08C77.1704 126.08 75.3418 127.909 75.3418 130.165C75.3418 130.428 75.3671 130.686 75.415 130.935C75.6487 132.162 77.9044 136.525 78.644 137.264C79.383 138.003 80.4041 138.46 81.532 138.46C83.7882 138.46 85.6165 136.632 85.6165 134.375C85.6165 133.944 85.5501 133.528 85.4262 133.138C85.1021 132.119 83.0616 128.032 82.3324 127.294Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M129.149 127.294C128.409 126.544 127.38 126.08 126.242 126.08C123.987 126.08 122.158 127.909 122.158 130.165C122.158 130.428 122.184 130.686 122.231 130.935C122.465 132.162 124.721 136.525 125.46 137.264C126.199 138.003 127.221 138.46 128.348 138.46C130.604 138.46 132.433 136.632 132.433 134.375C132.433 133.944 132.366 133.528 132.243 133.138C131.919 132.119 129.878 128.032 129.149 127.294Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M222.58 127.294C221.839 126.544 220.81 126.08 219.673 126.08C217.418 126.08 215.589 127.909 215.589 130.165C215.589 130.428 215.614 130.686 215.661 130.935C215.896 132.162 218.152 136.525 218.892 137.264C219.631 138.003 220.652 138.46 221.779 138.46C224.035 138.46 225.864 136.632 225.864 134.375C225.864 133.944 225.797 133.528 225.673 133.138C225.349 132.119 223.308 128.032 222.58 127.294Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M176.037 127.294C175.297 126.544 174.268 126.08 173.131 126.08C170.876 126.08 169.046 127.909 169.046 130.165C169.046 130.428 169.071 130.686 169.119 130.935C169.354 132.162 171.61 136.525 172.348 137.264C173.088 138.003 174.109 138.46 175.236 138.46C177.493 138.46 179.322 136.632 179.322 134.375C179.322 133.944 179.255 133.528 179.131 133.138C178.806 132.119 176.766 128.032 176.037 127.294Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M82.3324 48.0756C81.5922 47.3256 80.5635 46.8613 79.4258 46.8613C77.1704 46.8613 75.3418 48.6899 75.3418 50.9456C75.3418 51.2097 75.3671 51.4667 75.415 51.7159C75.6487 52.9425 77.9044 57.3058 78.644 58.0446C79.383 58.7839 80.4041 59.2411 81.532 59.2411C83.7882 59.2411 85.6165 57.4128 85.6165 55.1563C85.6165 54.7247 85.5501 54.3094 85.4262 53.9189C85.1021 52.9003 83.0616 48.8132 82.3324 48.0756Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M129.149 48.0756C128.409 47.3256 127.38 46.8613 126.242 46.8613C123.987 46.8613 122.158 48.6899 122.158 50.9456C122.158 51.2097 122.184 51.4667 122.231 51.7159C122.465 52.9425 124.721 57.3058 125.46 58.0446C126.199 58.7839 127.221 59.2411 128.348 59.2411C130.604 59.2411 132.433 57.4128 132.433 55.1563C132.433 54.7247 132.366 54.3094 132.243 53.9189C131.919 52.9003 129.878 48.8132 129.149 48.0756Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M222.58 48.0756C221.839 47.3256 220.81 46.8613 219.673 46.8613C217.418 46.8613 215.589 48.6899 215.589 50.9456C215.589 51.2097 215.614 51.4667 215.661 51.7159C215.896 52.9425 218.152 57.3058 218.892 58.0446C219.631 58.7839 220.652 59.2411 221.779 59.2411C224.035 59.2411 225.864 57.4128 225.864 55.1563C225.864 54.7247 225.797 54.3094 225.673 53.9189C225.349 52.9003 223.308 48.8132 222.58 48.0756Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M176.037 48.0756C175.297 47.3256 174.268 46.8613 173.131 46.8613C170.876 46.8613 169.046 48.6899 169.046 50.9456C169.046 51.2097 169.071 51.4667 169.119 51.7159C169.354 52.9425 171.61 57.3058 172.348 58.0446C173.088 58.7839 174.109 59.2411 175.236 59.2411C177.493 59.2411 179.322 57.4128 179.322 55.1563C179.322 54.7247 179.255 54.3094 179.131 53.9189C178.806 52.9003 176.766 48.8132 176.037 48.0756Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M82.3324 167.068C81.5922 166.319 80.5635 165.854 79.4258 165.854C77.1704 165.854 75.3418 167.683 75.3418 169.939C75.3418 170.203 75.3671 170.46 75.415 170.71C75.6487 171.936 77.9044 176.299 78.644 177.038C79.383 177.777 80.4041 178.235 81.532 178.235C83.7882 178.235 85.6165 176.406 85.6165 174.15C85.6165 173.719 85.5501 173.303 85.4262 172.913C85.1021 171.893 83.0616 167.807 82.3324 167.068Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M129.149 167.068C128.409 166.319 127.38 165.854 126.242 165.854C123.987 165.854 122.158 167.683 122.158 169.939C122.158 170.203 122.184 170.46 122.231 170.71C122.465 171.936 124.721 176.299 125.46 177.038C126.199 177.777 127.221 178.235 128.348 178.235C130.604 178.235 132.433 176.406 132.433 174.15C132.433 173.719 132.366 173.303 132.243 172.913C131.919 171.893 129.878 167.807 129.149 167.068Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M222.58 167.068C221.839 166.319 220.81 165.854 219.673 165.854C217.418 165.854 215.589 167.683 215.589 169.939C215.589 170.203 215.614 170.46 215.661 170.71C215.896 171.936 218.152 176.299 218.892 177.038C219.631 177.777 220.652 178.235 221.779 178.235C224.035 178.235 225.864 176.406 225.864 174.15C225.864 173.719 225.797 173.303 225.673 172.913C225.349 171.893 223.308 167.807 222.58 167.068Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M176.037 167.068C175.297 166.319 174.268 165.854 173.131 165.854C170.876 165.854 169.046 167.683 169.046 169.939C169.046 170.203 169.071 170.46 169.119 170.71C169.354 171.936 171.61 176.299 172.348 177.038C173.088 177.777 174.109 178.235 175.236 178.235C177.493 178.235 179.322 176.406 179.322 174.15C179.322 173.719 179.255 173.303 179.131 172.913C178.806 171.893 176.766 167.807 176.037 167.068Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M66.1553 241.549C66.1553 241.549 69.6041 249.784 77.4007 256.081H115.636L106.34 231.791C106.34 231.791 96.1432 247.056 85.7973 247.056C75.4522 247.056 66.1553 241.549 66.1553 241.549Z"
      fill="#483C72"
    />
    <path
      d="M109.639 241.549C109.639 241.549 116.086 252.41 123.583 256.082H155.371L149.762 233.183C149.762 233.183 139.627 247.056 129.281 247.056C118.935 247.056 109.639 241.549 109.639 241.549Z"
      fill="#483C72"
    />
    <path
      d="M153.635 241.437C153.635 241.437 156.121 246.785 164.967 256.081H197.504L192.891 233.44C192.891 233.44 183.145 247.056 172.799 247.056C162.453 247.056 153.635 241.437 153.635 241.437Z"
      fill="#483C72"
    />
    <path
      d="M195.663 241.549C195.663 241.549 200.203 251.434 205.601 256.252H240.987L235.739 231.792C235.739 231.792 225.651 247.056 215.305 247.056C204.959 247.056 195.663 241.549 195.663 241.549Z"
      fill="#483C72"
    />
    <path
      d="M86.4989 248.176C95.878 248.176 103.481 240.572 103.481 231.193C103.481 221.814 95.878 214.211 86.4989 214.211C77.1199 214.211 69.5166 221.814 69.5166 231.193C69.5166 240.572 77.1199 248.176 86.4989 248.176Z"
      fill="#FA975F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M84.9589 246.176C94.338 246.176 101.941 238.572 101.941 229.193C101.941 219.814 94.338 212.211 84.9589 212.211C75.5798 212.211 67.9766 219.814 67.9766 229.193C67.9766 238.572 75.5798 246.176 84.9589 246.176Z"
      fill="#FA975F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M139.017 52C148.129 52 155.517 44.6127 155.517 35.5C155.517 26.3873 148.129 19 139.017 19C129.904 19 122.517 26.3873 122.517 35.5C122.517 44.6127 129.904 52 139.017 52Z"
      fill="#E269A4"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M139.017 50C148.129 50 155.517 42.6127 155.517 33.5C155.517 24.3873 148.129 17 139.017 17C129.904 17 122.517 24.3873 122.517 33.5C122.517 42.6127 129.904 50 139.017 50Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M170.017 227C179.129 227 186.517 219.613 186.517 210.5C186.517 201.387 179.129 194 170.017 194C160.904 194 153.517 201.387 153.517 210.5C153.517 219.613 160.904 227 170.017 227Z"
      fill="#239667"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M170.017 225C179.129 225 186.517 217.613 186.517 208.5C186.517 199.387 179.129 192 170.017 192C160.904 192 153.517 199.387 153.517 208.5C153.517 217.613 160.904 225 170.017 225Z"
      fill="#87D1A3"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M99.5285 229.693C99.5285 229.705 99.5296 229.717 99.5296 229.73C99.5296 237.821 92.947 244.403 84.8561 244.403C76.7653 244.403 70.1821 237.821 70.1821 229.73C70.1821 229.717 70.1827 229.705 70.1827 229.693H63.3785C63.3785 229.705 63.3779 229.717 63.3779 229.73C63.3779 241.573 73.0135 251.208 84.8561 251.208C96.6987 251.208 106.334 241.573 106.334 229.73C106.334 229.717 106.333 229.705 106.333 229.693H99.5285Z"
      fill="#FA975F"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M142.686 229.693C142.686 229.705 142.687 229.717 142.687 229.73C142.687 237.821 136.104 244.403 128.013 244.403C119.922 244.403 113.339 237.821 113.339 229.73C113.339 229.717 113.34 229.705 113.34 229.693H106.535C106.535 229.705 106.535 229.717 106.535 229.73C106.535 241.573 116.17 251.208 128.013 251.208C139.856 251.208 149.491 241.573 149.491 229.73C149.491 229.717 149.491 229.705 149.491 229.693H142.686Z"
      fill="#EE86B7"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M186.055 229.693C186.055 229.706 186.056 229.717 186.056 229.73C186.056 237.821 179.473 244.404 171.383 244.404C163.291 244.404 156.708 237.821 156.708 229.73C156.708 229.717 156.709 229.706 156.709 229.693H149.905C149.905 229.705 149.904 229.717 149.904 229.73C149.904 241.573 159.539 251.208 171.383 251.208C183.226 251.208 192.861 241.573 192.861 229.73C192.861 229.717 192.86 229.705 192.86 229.693H186.055Z"
      fill="#5ABE89"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M229.002 229.693C229.002 229.705 229.003 229.717 229.003 229.73C229.003 237.821 222.42 244.403 214.329 244.403C206.238 244.403 199.655 237.821 199.655 229.73C199.655 229.717 199.656 229.705 199.656 229.693H192.851C192.851 229.705 192.851 229.717 192.851 229.73C192.851 241.573 202.486 251.208 214.329 251.208C226.172 251.208 235.806 241.573 235.806 229.73C235.806 229.717 235.806 229.705 235.806 229.693H229.002Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M66.6058 234.04C68.8416 234.04 70.6541 232.228 70.6541 229.992C70.6541 227.756 68.8416 225.944 66.6058 225.944C64.3701 225.944 62.5576 227.756 62.5576 229.992C62.5576 232.228 64.3701 234.04 66.6058 234.04Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M232.244 234.041C234.48 234.041 236.293 232.228 236.293 229.992C236.293 227.756 234.48 225.944 232.244 225.944C230.008 225.944 228.196 227.756 228.196 229.992C228.196 232.228 230.008 234.041 232.244 234.041Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M110.089 225.944C108.393 225.944 106.943 226.987 106.34 228.466C105.737 226.987 104.287 225.944 102.592 225.944C100.356 225.944 98.543 227.756 98.543 229.992C98.543 232.228 100.356 234.04 102.592 234.04C104.287 234.04 105.738 232.997 106.34 231.518C106.943 232.997 108.393 234.04 110.089 234.04C112.325 234.04 114.137 232.228 114.137 229.992C114.137 227.756 112.325 225.944 110.089 225.944Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M153.421 225.861C151.726 225.861 150.276 226.905 149.673 228.383C149.07 226.905 147.62 225.861 145.924 225.861C143.689 225.861 141.876 227.674 141.876 229.909C141.876 232.145 143.689 233.958 145.924 233.958C147.62 233.958 149.071 232.914 149.673 231.436C150.276 232.914 151.726 233.958 153.421 233.958C155.657 233.958 157.47 232.145 157.47 229.909C157.47 227.674 155.658 225.861 153.421 225.861Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M196.584 225.861C194.889 225.861 193.438 226.905 192.836 228.383C192.233 226.905 190.783 225.861 189.087 225.861C186.851 225.861 185.039 227.674 185.039 229.909C185.039 232.145 186.851 233.958 189.087 233.958C190.783 233.958 192.233 232.914 192.836 231.436C193.439 232.914 194.889 233.958 196.584 233.958C198.82 233.958 200.632 232.145 200.632 229.909C200.632 227.674 198.82 225.861 196.584 225.861Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M248.122 259.605H51.1006V266.353H248.122V259.605Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M248.122 259.606H51.1006L56.1809 256.232H243.155L248.122 259.606Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.4316 233.815C64.4316 233.815 66.5311 236.064 71.1787 235.09L70.1288 232.391C70.1288 232.391 68.1054 234.64 64.4316 233.815Z"
      fill="#483C72"
    />
    <path
      d="M107.54 233.815C107.54 233.815 109.639 236.064 114.287 235.09L113.237 232.391C113.237 232.391 111.213 234.64 107.54 233.815Z"
      fill="#483C72"
    />
    <path
      d="M99.7427 233.366C99.7427 233.366 101.092 235.765 105.44 235.54L105.815 232.991C105.815 232.991 104.09 234.041 102.592 234.041C101.092 234.041 99.7427 233.366 99.7427 233.366Z"
      fill="#483C72"
    />
    <path
      d="M83.5915 130.165C83.5915 132.421 81.7629 134.25 79.5066 134.25C77.2515 134.25 75.4224 132.421 75.4224 130.165C75.4224 127.909 77.2515 126.08 79.5066 126.08C81.7629 126.08 83.5915 127.909 83.5915 130.165Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M130.328 130.165C130.328 132.421 128.499 134.25 126.242 134.25C123.987 134.25 122.158 132.421 122.158 130.165C122.158 127.909 123.987 126.08 126.242 126.08C128.499 126.08 130.328 127.909 130.328 130.165Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M177.064 130.165C177.064 132.421 175.235 134.25 172.979 134.25C170.723 134.25 168.894 132.421 168.894 130.165C168.894 127.909 170.723 126.08 172.979 126.08C175.235 126.08 177.064 127.909 177.064 130.165Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M223.8 130.165C223.8 132.421 221.972 134.25 219.715 134.25C217.46 134.25 215.631 132.421 215.631 130.165C215.631 127.909 217.46 126.08 219.715 126.08C221.972 126.08 223.8 127.909 223.8 130.165Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M82.3324 87.8166C81.5922 87.0666 80.5635 86.6021 79.4258 86.6021C77.1704 86.6021 75.3418 88.4315 75.3418 90.6872C75.3418 90.9504 75.3671 91.208 75.415 91.4574C75.6487 92.6841 77.9044 97.0471 78.644 97.7858C79.383 98.5252 80.4041 98.9827 81.532 98.9827C83.7882 98.9827 85.6165 97.1538 85.6165 94.8978C85.6165 94.4662 85.5501 94.0507 85.4262 93.6608C85.1021 92.641 83.0616 88.5539 82.3324 87.8166Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M79.5069 94.7711C81.7627 94.7711 83.5915 92.9424 83.5915 90.6866C83.5915 88.4308 81.7627 86.6021 79.5069 86.6021C77.2511 86.6021 75.4224 88.4308 75.4224 90.6866C75.4224 92.9424 77.2511 94.7711 79.5069 94.7711Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M129.15 87.8166C128.409 87.0666 127.38 86.6021 126.243 86.6021C123.987 86.6021 122.159 88.4315 122.159 90.6872C122.159 90.9504 122.184 91.208 122.232 91.4574C122.466 92.6841 124.722 97.0471 125.46 97.7858C126.2 98.5252 127.221 98.9827 128.349 98.9827C130.605 98.9827 132.434 97.1538 132.434 94.8978C132.434 94.4662 132.366 94.0507 132.243 93.6608C131.919 92.641 129.878 88.5539 129.15 87.8166Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M222.58 87.8166C221.839 87.0666 220.81 86.6021 219.673 86.6021C217.418 86.6021 215.589 88.4315 215.589 90.6872C215.589 90.9504 215.614 91.208 215.661 91.4574C215.896 92.6841 218.152 97.0471 218.892 97.7858C219.631 98.5252 220.652 98.9827 221.779 98.9827C224.035 98.9827 225.864 97.1538 225.864 94.8978C225.864 94.4662 225.797 94.0507 225.673 93.6608C225.349 92.641 223.308 88.5539 222.58 87.8166Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M176.037 87.8166C175.297 87.0666 174.268 86.6021 173.131 86.6021C170.876 86.6021 169.046 88.4315 169.046 90.6872C169.046 90.9504 169.071 91.208 169.119 91.4574C169.354 92.6841 171.61 97.0471 172.348 97.7858C173.088 98.5252 174.109 98.9827 175.236 98.9827C177.493 98.9827 179.322 97.1538 179.322 94.8978C179.322 94.4662 179.255 94.0507 179.131 93.6608C178.806 92.641 176.766 88.5539 176.037 87.8166Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M172.979 94.7717C175.235 94.7717 177.064 92.9429 177.064 90.6869C177.064 88.4309 175.235 86.6021 172.979 86.6021C170.723 86.6021 168.895 88.4309 168.895 90.6869C168.895 92.9429 170.723 94.7717 172.979 94.7717Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M219.716 94.7717C221.972 94.7717 223.8 92.9429 223.8 90.6869C223.8 88.4309 221.972 86.6021 219.716 86.6021C217.46 86.6021 215.631 88.4309 215.631 90.6869C215.631 92.9429 217.46 94.7717 219.716 94.7717Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M126.244 94.7711C128.5 94.7711 130.328 92.9424 130.328 90.6866C130.328 88.4308 128.5 86.6021 126.244 86.6021C123.988 86.6021 122.159 88.4308 122.159 90.6866C122.159 92.9424 123.988 94.7711 126.244 94.7711Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M79.5069 173.838C81.7627 173.838 83.5915 172.009 83.5915 169.753C83.5915 167.498 81.7627 165.669 79.5069 165.669C77.2511 165.669 75.4224 167.498 75.4224 169.753C75.4224 172.009 77.2511 173.838 79.5069 173.838Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M126.243 173.838C128.499 173.838 130.328 172.009 130.328 169.753C130.328 167.498 128.499 165.669 126.243 165.669C123.987 165.669 122.159 167.498 122.159 169.753C122.159 172.009 123.987 173.838 126.243 173.838Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M172.979 173.839C175.235 173.839 177.064 172.01 177.064 169.754C177.064 167.498 175.235 165.669 172.979 165.669C170.723 165.669 168.894 167.498 168.894 169.754C168.894 172.01 170.723 173.839 172.979 173.839Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M219.716 173.839C221.972 173.839 223.8 172.01 223.8 169.754C223.8 167.498 221.972 165.669 219.716 165.669C217.46 165.669 215.631 167.498 215.631 169.754C215.631 172.01 217.46 173.839 219.716 173.839Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M79.5069 55.2936C81.7627 55.2936 83.5915 53.4649 83.5915 51.2091C83.5915 48.9532 81.7627 47.1245 79.5069 47.1245C77.2511 47.1245 75.4224 48.9532 75.4224 51.2091C75.4224 53.4649 77.2511 55.2936 79.5069 55.2936Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M126.243 55.2936C128.499 55.2936 130.328 53.4649 130.328 51.2091C130.328 48.9532 128.499 47.1245 126.243 47.1245C123.987 47.1245 122.159 48.9532 122.159 51.2091C122.159 53.4649 123.987 55.2936 126.243 55.2936Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M172.979 55.2932C175.235 55.2932 177.064 53.4644 177.064 51.2084C177.064 48.9524 175.235 47.1235 172.979 47.1235C170.723 47.1235 168.894 48.9524 168.894 51.2084C168.894 53.4644 170.723 55.2932 172.979 55.2932Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M219.716 55.2932C221.972 55.2932 223.8 53.4644 223.8 51.2084C223.8 48.9524 221.972 47.1235 219.716 47.1235C217.46 47.1235 215.631 48.9524 215.631 51.2084C215.631 53.4644 217.46 55.2932 219.716 55.2932Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M189.659 78.8855L178.143 84.4875C176.633 83.2319 174.707 82.566 172.744 82.6223L167.142 71.1066C166.703 70.2023 165.78 69.7355 165.093 70.0694L161.628 71.7544C160.942 72.0889 160.738 73.1027 161.179 74.007L166.78 85.5233C165.524 87.0326 164.859 88.9589 164.915 90.9226L153.399 96.5238C152.495 96.9638 152.028 97.8862 152.362 98.5737L154.047 102.037C154.381 102.725 155.396 102.928 156.3 102.487L167.816 96.8861C169.325 98.1418 171.252 98.8068 173.214 98.7513L178.816 110.267C179.256 111.172 180.178 111.638 180.865 111.304L184.33 109.619C185.017 109.284 185.22 108.271 184.78 107.367L179.178 95.8504C180.434 94.341 181.099 92.4142 181.044 90.4516L192.559 84.8496C193.464 84.4098 193.931 83.4875 193.596 82.8003L191.911 79.3365C191.577 78.649 190.563 78.4457 189.659 78.8855ZM174.749 94.325C172.74 95.3022 170.319 94.4652 169.341 92.4561C168.364 90.4471 169.201 88.0261 171.21 87.0489C173.219 86.0723 175.64 86.9087 176.617 88.9169C177.594 90.9263 176.758 93.3478 174.749 94.325Z"
      fill="#7F69CE"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M142.812 118.1L131.295 123.702C129.786 122.447 127.86 121.78 125.897 121.837L120.295 110.321C119.856 109.417 118.932 108.95 118.246 109.284L114.781 110.969C114.095 111.303 113.892 112.317 114.332 113.221L119.934 124.738C118.678 126.247 118.012 128.173 118.068 130.137L106.553 135.738C105.647 136.178 105.181 137.101 105.515 137.788L107.201 141.252C107.535 141.939 108.548 142.141 109.454 141.702L120.969 136.1C122.478 137.356 124.405 138.022 126.368 137.966L131.97 149.482C132.409 150.386 133.332 150.852 134.019 150.518L137.483 148.833C138.17 148.499 138.373 147.485 137.933 146.581L132.331 135.065C133.587 133.555 134.253 131.629 134.197 129.666L145.713 124.064C146.618 123.624 147.084 122.702 146.75 122.014L145.065 118.55C144.73 117.863 143.716 117.66 142.812 118.1ZM127.902 133.539C125.893 134.516 123.472 133.679 122.495 131.671C121.517 129.662 122.354 127.241 124.363 126.264C126.372 125.286 128.793 126.123 129.77 128.132C130.747 130.141 129.911 132.562 127.902 133.539Z"
      fill="#7F69CE"
      stroke="#483C72"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M129.678 126.338C128.782 125.405 127.522 124.823 126.125 124.823C123.403 124.823 121.196 127.029 121.196 129.752C121.196 130.341 121.3 130.905 121.49 131.427C121.754 132.154 123.387 135.048 124.013 135.692C124.908 136.613 126.159 137.186 127.545 137.186C130.267 137.186 132.474 134.98 132.474 132.257C132.474 131.505 132.304 130.794 132.003 130.157C131.739 129.602 130.293 126.979 129.678 126.338Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M201.017 119C210.129 119 217.517 111.613 217.517 102.5C217.517 93.3873 210.129 86 201.017 86C191.904 86 184.517 93.3873 184.517 102.5C184.517 111.613 191.904 119 201.017 119Z"
      fill="#E49307"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M201.017 117C210.129 117 217.517 109.613 217.517 100.5C217.517 91.3873 210.129 84 201.017 84C191.904 84 184.517 91.3873 184.517 100.5C184.517 109.613 191.904 117 201.017 117Z"
      fill="#FBBD74"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M126.125 134.68C128.847 134.68 131.053 132.473 131.053 129.751C131.053 127.029 128.847 124.823 126.125 124.823C123.403 124.823 121.196 127.029 121.196 129.751C121.196 132.473 123.403 134.68 126.125 134.68Z"
      fill="#C2AAFA"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M176.574 87.2352C175.679 86.3017 174.419 85.7197 173.022 85.7197C170.299 85.7197 168.093 87.9264 168.093 90.6483C168.093 91.2376 168.197 91.8018 168.386 92.3243C168.651 93.0507 170.284 95.9452 170.91 96.5893C171.804 97.5102 173.056 98.0826 174.441 98.0826C177.164 98.0826 179.37 95.8765 179.37 93.154C179.37 92.402 179.201 91.6914 178.899 91.0543C178.636 90.4994 177.189 87.8766 176.574 87.2352Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M173.021 95.5768C175.743 95.5768 177.949 93.3704 177.949 90.6485C177.949 87.9267 175.743 85.7202 173.021 85.7202C170.299 85.7202 168.093 87.9267 168.093 90.6485C168.093 93.3704 170.299 95.5768 173.021 95.5768Z"
      fill="#C2AAFA"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M47.1336 283.184C57.9677 283.184 66.7505 274.401 66.7505 263.567C66.7505 252.733 57.9677 243.95 47.1336 243.95C36.2994 243.95 27.5166 252.733 27.5166 263.567C27.5166 274.401 36.2994 283.184 47.1336 283.184Z"
      fill="#4E8FD0"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M44.8391 283.184C55.6733 283.184 64.4561 274.401 64.4561 263.567C64.4561 252.733 55.6733 243.95 44.8391 243.95C34.005 243.95 25.2222 252.733 25.2222 263.567C25.2222 274.401 34.005 283.184 44.8391 283.184Z"
      fill="#71A7E5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M269.696 202.883C274.588 202.883 278.553 202.354 278.553 201.702C278.553 201.049 274.588 200.521 269.696 200.521C264.805 200.521 260.839 201.049 260.839 201.702C260.839 202.354 264.805 202.883 269.696 202.883Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M260.935 202.407C267.241 202.407 272.353 197.295 272.353 190.989C272.353 184.683 267.241 179.571 260.935 179.571C254.629 179.571 249.517 184.683 249.517 190.989C249.517 197.295 254.629 202.407 260.935 202.407Z"
      fill="#A990F5"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
  </svg>
);
