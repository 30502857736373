import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import { debounce } from "../../utils/debounce.utils";
import { getCookie } from "../../utils/cookies.utils";
import { isDevelopment } from "../../environment";
import { useSiteContext } from "../../context/site.context";
import { STORY_REQUEST_MODAL_NAME } from "../modals/modalNames";
import StoryRequestModal from "../modals/StoryRequestModal";
import { formatAsSearchString } from "../../utils/search.utils";
import { SearchableEntry, StoryDescriptor } from "../../types/helper.types";
import { DatoCmsLibraryTool } from "../../../graphql-types";
import dayjs from "dayjs";
import { DatoCmsLibraryToolWithComputedProperties } from "../../utils/library.utils";
import { navigate } from "gatsby";
import { Paths } from "../../utils/pathBuilders.utils";
import { intersection, uniq } from "lodash-es";
import ColorSchemeToggle from "../utilities/ColorSchemeToggle";

export type LibrarySearchableEntryBase = {
  name: string;
  ranking: number;
} & Partial<SearchableEntry>;

const prepareEntryForSearch = (e: LibrarySearchableEntryBase) => {
  if ("__searchString" in e) return e as SearchableEntry;
  (
    e as SearchableEntry & (StoryDescriptor | DatoCmsLibraryTool)
  ).__searchString = formatAsSearchString(
    [
      e.name,
      (e as unknown as StoryDescriptor).description,
      (e as unknown as StoryDescriptor).internalAuthors?.map(a =>
        [a.name, a.surname].filter(i => i).join(" ")
      ),
      (e as unknown as StoryDescriptor).communityAuthors?.map(a =>
        [a.name, a.surname, a.organization?.name].filter(i => i).join(" ")
      ),
      (e as unknown as StoryDescriptor).tags?.join?.(", "),
      e.slug,
    ]
      .filter(i => i)
      .join("__")
  );
  return e as unknown as SearchableEntry;
};

type LibraryEntryType = "story" | "tool";

type Props<T extends LibrarySearchableEntryBase> = {
  entries: T[];
  entryType: LibraryEntryType;
  tools: DatoCmsLibraryToolWithComputedProperties[];
  children: (props: { state: LibraryState; entries: T[] }) => ReactNode;
  awaitingData: boolean;
  error?: string;
};

export type LibraryState = {
  entryType: LibraryEntryType;
  query: string;
  sort: string;
  setSort: (sort: string) => void;
  clearSearch: () => void;
  searchFor: (query: string) => void;
  awaitingData: boolean;
  error?: string;
};

const findAndFilterTrackingWebhook =
  "https://hq.tines.io/webhook/c7dfc8f6f432e31454cc05e974e6d6be/7c973767336ed04ca964d29dc7046198";

function WithLibraryState<T extends LibrarySearchableEntryBase>(
  props: Props<T>
) {
  const siteContext = useSiteContext();
  const [pathname] = useState(siteContext.location.pathname);
  const [sort, setSort] = useState("");

  const query = siteContext.library.query;
  const searchQueryRef = useRef<string>(query);
  searchQueryRef.current = query;

  const reportSearchAndTag = useMemo(
    () =>
      debounce(
        () => {
          // TODO
          if (isDevelopment) return;
          if (searchQueryRef.current) {
            fetch(findAndFilterTrackingWebhook, {
              method: "post",
              body: JSON.stringify({
                query: searchQueryRef.current ?? "",
                email: getCookie("email_address") ?? "",
              }),
            });
          }
        },
        { duration: 3000 }
      ),
    []
  );

  useEffect(reportSearchAndTag);

  const location = useLocation();

  useEffect(() => {
    if (siteContext.shouldShowGlobalSearch) return;
    const urlParams = getUrlQueryParams<{
      s?: string;
      sort?: string;
    }>();
    const { sort, s } = urlParams;
    if (sort) setSort(sort);
    if (s && s !== siteContext.library.query) siteContext.library.setQuery(s);
  }, [
    location.search,
    siteContext.library,
    siteContext.shouldShowGlobalSearch,
  ]);

  useOnMount(() => {
    let keepQueryOnUnmount = false;
    const urlParams = getUrlQueryParams<{
      s?: string;
      tag?: string;
      sort?: string;
      page?: string;
      "redirected-from-404"?: string;
    }>();
    const { s, sort, tag } = urlParams;
    setSort(sort || "popularity");
    const redirectedFrom404 = urlParams["redirected-from-404"]?.replace(
      /-/g,
      " "
    );
    const initialQuery = [s, redirectedFrom404].filter(i => i).join(" ");
    if (initialQuery) {
      siteContext.library.setQuery(initialQuery);
    }
    if (tag) {
      removeUrlQueryParam("tag");
      const tool = props.tools.find(t =>
        [
          t.name,
          t.slug,
          ...(t.altSlugs ?? "").split(/\n/),
          ...(t.tags ?? "").split(/\n/),
        ]
          .map(t => t?.toLowerCase())
          .includes(tag.toLowerCase())
      );
      if (tool) navigate(Paths.library.tool(`${tool.slug}`));
      else {
        const queryConvertedFromTag = uniq(
          [initialQuery, tag].filter(i => i)
        ).join(" ");
        keepQueryOnUnmount = true;
        const dest = Paths.library.stories({
          query: queryConvertedFromTag,
        });
        navigate(dest);
      }
    }
    return () => {
      if (!window.location.pathname.match(/\/library\//)) {
        removeUrlQueryParam("s");
        removeUrlQueryParam("sort");
      }
      removeUrlQueryParam("redirected-from-404");
      if (
        !keepQueryOnUnmount &&
        window.location.pathname !== pathname &&
        !!searchQueryRef.current
      )
        siteContext.library.clearQuery();
    };
  });

  const queryPhrases = query
    .split(/\s+/)
    .map(q => formatAsSearchString(q))
    .filter(i => !!i);

  const entriesMatchingSearchQuery =
    queryPhrases.length > 0
      ? queryPhrases
          .map(q =>
            props.entries.filter(e =>
              prepareEntryForSearch(e).__searchString.includes(q)
            )
          )
          .reduce((results, next) => {
            return results.length > 0 ? intersection(results, next) : next;
          }, [])
      : props.entries;

  const entries =
    sort === "popularity"
      ? [...entriesMatchingSearchQuery].sort((a, b) => a.ranking - b.ranking)
      : sort === "nameAsc"
      ? [...entriesMatchingSearchQuery].sort((a, b) => {
          const an = a.name.toLowerCase();
          const bn = b.name.toLowerCase();
          return an > bn ? 1 : an < bn ? -1 : 0;
        })
      : sort === "nameDesc"
      ? [...entriesMatchingSearchQuery].sort((a, b) => {
          const an = a.name.toLowerCase();
          const bn = b.name.toLowerCase();
          return an > bn ? -1 : an < bn ? 1 : 0;
        })
      : sort === "latest"
      ? [...entriesMatchingSearchQuery].sort((a, b) =>
          dayjs((b as unknown as StoryDescriptor).timeFirstPublished).diff(
            (a as unknown as StoryDescriptor).timeFirstPublished
          )
        )
      : sort === "simplerStoriesFirst"
      ? [...entriesMatchingSearchQuery].sort(
          (a, b) =>
            (a as unknown as StoryDescriptor).numberOfActions -
            (b as unknown as StoryDescriptor).numberOfActions
        )
      : sort === "complexStoriesFirst"
      ? [...entriesMatchingSearchQuery].sort(
          (a, b) =>
            (b as unknown as StoryDescriptor).numberOfActions -
            (a as unknown as StoryDescriptor).numberOfActions
        )
      : sort === "partnersFirst"
      ? [...entriesMatchingSearchQuery].sort(
          (a, b) =>
            ((b as unknown as DatoCmsLibraryToolWithComputedProperties)
              .isPartner
              ? 1
              : 0) -
            ((a as unknown as DatoCmsLibraryToolWithComputedProperties)
              .isPartner
              ? 1
              : 0)
        )
      : entriesMatchingSearchQuery;

  const state = {
    entryType: props.entryType,
    query,
    sort,
    setSort: (sort: string) => {
      setSort(sort);
      setUrlQueryParam("sort", sort);
    },
    searchFor: siteContext.library.setQuery,
    clearSearch: () => {
      siteContext.library.setQuery("");
    },
    awaitingData: props.awaitingData,
    error: props.error,
  };

  return (
    <>
      {props.children({
        state,
        entries,
      })}
      {siteContext.modal === STORY_REQUEST_MODAL_NAME && <StoryRequestModal />}
      <ColorSchemeToggle invisible />
    </>
  );
}

export default WithLibraryState;
