const SearchIcon13 = () => {
  return (
    <svg
      className="Icon SearchIcon"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94366 5.1385C8.94366 7.24003 7.24003 8.94366 5.1385 8.94366C3.03696 8.94366 1.33333 7.24003 1.33333 5.1385C1.33333 3.03696 3.03696 1.33333 5.1385 1.33333C7.24003 1.33333 8.94366 3.03696 8.94366 5.1385ZM8.26993 9.21292C7.40299 9.8802 6.31708 10.277 5.1385 10.277C2.30058 10.277 0 7.97641 0 5.1385C0 2.30058 2.30058 0 5.1385 0C7.97641 0 10.277 2.30058 10.277 5.1385C10.277 6.31718 9.88014 7.40317 9.21276 8.27014L12.8038 11.8612C13.0642 12.1216 13.0642 12.5437 12.8038 12.804C12.5435 13.0644 12.1214 13.0644 11.861 12.804L8.26993 9.21292Z"
      />
    </svg>
  );
};

export default SearchIcon13;
