import { styled } from "@linaria/react";
import { CSSProperties, PropsWithChildren } from "react";
import { PrimaryColorName, color, colors } from "../../../styles/colors.styles";
import PageIcon from "../../icons/standard/page.icon";
import TextPlaceholder from "../../home/heroIllustration/TextPlaceholder";
import {
  ActionLinkIncomingGooeyBit,
  ActionLinkIncomingGooeyBitSvg,
  ActionLinkOutgoingGooeyBit,
  ActionLinkOutgoingGooeyBitSvg,
  ActionLinkOutgoingTerminal,
} from "./ActionLinkComponents";
import { cx } from "linaria";

type Props = {
  id?: string;
  className?: string;
  title?: string;
  color?: PrimaryColorName;
  hasOutgoingLink?: boolean;
  showOutgoingLinkTerminal?: boolean;
  hasIncomingLink?: boolean;
  topByGridCell?: number;
  leftByGridCell?: number;
  widthByGridCell?: number;
  heightByGridCell?: number;
  eventCount?: number | string;
};

export const PagePreviewCardWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  color: var(--text);
  border: 1px solid var(--border);
  padding: 0.9rem;
  border-radius: 1.2rem;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-gap: 0.9rem;
  width: ${24 * 8}px;
  height: ${24 * 7}px;
  ${ActionLinkIncomingGooeyBitSvg},
  ${ActionLinkOutgoingGooeyBitSvg} {
    left: 19px;
    fill: var(--border);
  }
  ${ActionLinkIncomingGooeyBitSvg} {
    transform: translateY(-1px);
  }
  ${ActionLinkOutgoingGooeyBitSvg} {
    transform: translateY(1px);
  }
`;

const CardHeader = styled.header`
  display: flex;
  align-items: center;
  font-weight: 500;
  > * {
    + * {
      margin-left: 0.8rem;
    }
  }
`;

const PageBodyFrame = styled.div`
  background-color: var(--backdrop);
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  position: relative;
  overflow: hidden;
`;

const PagePreviewCard = (props: PropsWithChildren<Props>) => {
  const accent = color(props.color);
  const lighter = color(props.color, 100);
  const positioned =
    props.topByGridCell !== undefined || props.leftByGridCell !== undefined;
  return (
    <PagePreviewCardWrapper
      id={props.id}
      className={cx(props.className, positioned && "positioned")}
      style={
        {
          "--ac": accent,
          "--backdrop": color(props.color, 100),
          "--text": color(props.color, 700),
          "--border": color(props.color, 200),
          transform:
            positioned &&
            `translate(${(props.leftByGridCell ?? 0) * 24}px, ${
              (props.topByGridCell ?? 0) * 24
            }px)`,
          width: props.widthByGridCell ? props.widthByGridCell * 24 : undefined,
          height: props.heightByGridCell
            ? props.heightByGridCell * 24
            : undefined,
        } as CSSProperties
      }
    >
      <CardHeader>
        <PageIcon />
        {props.title || (
          <TextPlaceholder color={color(props.color, 50)}>
            Example
          </TextPlaceholder>
        )}
      </CardHeader>
      <PageBodyFrame>
        <svg width="70" height="80" fill={lighter}>
          <rect width="70" height="80" rx="9" fill="white" />
          <rect x="24" y="45" width="33" height="6" rx="2" />
          <rect x="7" y="44" width="14" height="8" rx="4" />
          <circle cx="17" cy="48" r="3" fill="white" />
          <rect x="7" y="7" width="23" height="8" rx="4" />
          <rect x="7" y="30" width="44" height="9" rx="2" />
          <rect x="54" y="30" width="9" height="9" rx="2" />
          <rect x="7" y="57" width="56" height="16" rx="3" fill={accent} />
          <rect x="7" y="20" width="18" height="6" rx="2" />
          <rect x="47" y="20" width="16" height="6" rx="2" />
          <path
            d="M35.3536 60.1464C35.1583 59.9512 34.8417 59.9512 34.6464 60.1464L32.1464 62.6464C31.9512 62.8417 31.9512 63.1583 32.1464 63.3536C32.3417 63.5488 32.6583 63.5488 32.8536 63.3536L34.5 61.7071V65C34.5 65.2761 34.7239 65.5 35 65.5C35.2761 65.5 35.5 65.2761 35.5 65V61.7071L37.1464 63.3536C37.3417 63.5488 37.6583 63.5488 37.8536 63.3536C38.0488 63.1583 38.0488 62.8417 37.8536 62.6464L35.3536 60.1464ZM32 66C32 65.7239 31.7761 65.5 31.5 65.5C31.2239 65.5 31 65.7239 31 66V67C31 68.1046 31.8954 69 33 69H37C38.1046 69 39 68.1046 39 67V66C39 65.7239 38.7761 65.5 38.5 65.5C38.2239 65.5 38 65.7239 38 66V67C38 67.5523 37.5523 68 37 68H33C32.4477 68 32 67.5523 32 67V66Z"
            fill="white"
          />
        </svg>
      </PageBodyFrame>
      {props.hasIncomingLink && (
        <ActionLinkIncomingGooeyBit
          color={props.color}
          id={props.id ? `${props.id}-incoming-link-gooey-bit` : ""}
        />
      )}
      {props.hasOutgoingLink && (
        <ActionLinkOutgoingGooeyBit
          color={props.color}
          id={props.id ? `${props.id}-outgoing-gooey-bit` : ""}
        />
      )}
      {props.showOutgoingLinkTerminal && (
        <ActionLinkOutgoingTerminal
          color={props.color}
          id={`${props.id}-outgoing-link-terminal`}
        />
      )}
    </PagePreviewCardWrapper>
  );
};

export default PagePreviewCard;
