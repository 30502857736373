import { styled } from "@linaria/react";
import SEO from "../components/utilities/SEO";

import { rSize } from "../styles/responsiveSizes.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";

import {
  LayoutWithSidebar,
  LayoutWithSidebarContent,
} from "../components/layout/LayoutWithSidebar";
import StandardSidebar from "../components/layout/StandardSidebar";
import Spacing from "../components/layout/Spacing";
import { LegalIcon } from "../components/illustrations/spots/LegalIcon";
import { preventWidows } from "../utils/typography.utils";
import OnlyPhonesDiv from "../components/mediaQueries/OnlyPhones";
import { Separator } from "../components/utilities/Hr";
import { Link } from "@reach/router";

const LinkContainer = styled.p`
  font-size: 1.4rem;
  + * {
    margin-top: ${rSize("md")};
  }
`;

const PageLegal: PageComponent<{}> = props => {
  const description = "Prior versions of our terms and policies.";

  const links = [
    "/cloud-service-addendum-feb-2024",
    "/general-terms-public-sector-customers-feb-2024",
    "/general-terms-tines-automation-inc-feb-2024",
    "/general-terms-tines-security-services-ltd-feb-2024",
    "/professional-services-addendum-feb-2024",
    "/service-level-agreement-feb-2024",
  ];

  return (
    <Page {...props}>
      <SEO title="Legal" description={description} />
      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <LegalIcon />
          <h1>Legal</h1>
          <p>{preventWidows(description)}</p>
          <p>
            <Link to="/legal">See latest versions.</Link>
          </p>
        </StandardSidebar>

        <LayoutWithSidebarContent>
          <OnlyPhonesDiv>
            <Separator margin="1em 0 3em" />
          </OnlyPhonesDiv>
          {links.map(link => (
            <LinkContainer key={link}>
              <Link to={link}>{link}</Link>
            </LinkContainer>
          ))}
        </LayoutWithSidebarContent>
      </LayoutWithSidebar>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageLegal;
