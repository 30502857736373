import { css, cx } from "linaria";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

type Props = {};

const ToolsIcon = (props: Props) => {
  return (
    <svg
      width="101"
      height="77"
      viewBox="0 0 101 77"
      fill="none"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      className={cx(
        css`
          --shadow-color: ${colorsV4.warmBlack};
          ${darkModeLinariaCSS(`--shadow-color: ${colorsV4.warmBlack900};`)}
        `
      )}
    >
      <path
        d="M83.5802 10.1921C83.5916 8.06694 82.2229 6.09451 80.087 5.43448L72.5133 3.0935C69.8989 2.28513 67.1246 3.74959 66.3164 6.36377L63.9754 13.9377C63.8225 14.4321 63.751 14.9322 63.7535 15.4235C63.7573 16.1837 63.7519 19.5125 63.7531 19.9997C63.7569 22.112 65.1218 24.0669 67.2459 24.7236L74.8196 27.0646C77.434 27.8726 80.2083 26.4087 81.0169 23.7939L83.3579 16.2198C83.5035 15.7489 83.5754 15.2729 83.5797 14.8042C83.587 14.0195 83.5777 10.6686 83.5802 10.1921Z"
        fill="#B38847"
        stroke="var(--shadow-color)"
      />
      <path
        d="M81.0173 19.2053C80.2085 21.8199 77.4344 23.2839 74.82 22.4759L67.2463 20.135C64.6319 19.3266 63.1675 16.552 63.9758 13.9377L66.3168 6.36377C67.1252 3.74959 69.8993 2.28513 72.5137 3.0935L80.0874 5.43448C82.7022 6.24264 84.1666 9.01761 83.3583 11.6312L81.0173 19.2053Z"
        fill="#FABE64"
        stroke="var(--shadow-color)"
      />
      <path d="M75.7329 22.6133V27.3094" stroke="var(--shadow-color)" />
      <path d="M80.3301 20.6362V25.3321" stroke="var(--shadow-color)" />
      <path
        d="M41.5171 11.0426C41.5171 5.53425 37.051 1.06836 31.5426 1.06836C26.0338 1.06836 21.5679 5.53425 21.5679 11.0426C21.5679 11.8537 21.5679 14.9395 21.5679 15.7508C21.5679 21.2594 26.0338 25.7253 31.5426 25.7253C37.051 25.7253 41.5171 21.2594 41.5171 15.7508C41.5171 14.9395 41.5171 11.8537 41.5171 11.0426Z"
        fill="#B35980"
        stroke="var(--shadow-color)"
      />
      <path
        d="M31.5424 21.0169C37.0511 21.0169 41.5169 16.5511 41.5169 11.0424C41.5169 5.53361 37.0511 1.06787 31.5424 1.06787C26.0336 1.06787 21.5679 5.53361 21.5679 11.0424C21.5679 16.5511 26.0336 21.0169 31.5424 21.0169Z"
        fill="#EB81AF"
        stroke="var(--shadow-color)"
      />
      <path
        d="M75.4624 35.9322C74.5076 21.873 62.3369 11.2514 48.2788 12.2056C34.2219 13.1608 23.5988 25.3313 24.5532 39.3892C25.1736 48.5277 30.5347 56.2129 38.0718 60.2116C38.0706 60.2332 38.0654 60.2529 38.0654 60.2745V74.7905C38.0654 75.4206 38.5763 75.9321 39.2066 75.9321H60.8093C61.4394 75.9321 61.9503 75.4206 61.9503 74.7905V60.2745C61.9503 60.2542 61.9455 60.2353 61.9442 60.2153C70.5708 55.6433 76.1667 46.3016 75.4624 35.9322Z"
        fill="#8173E6"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M75.4624 31.9269C74.5076 17.8681 62.3369 7.24629 48.2788 8.20071C34.2219 9.15555 23.5988 21.3258 24.5532 35.3837C25.1736 44.523 30.5347 52.2081 38.0718 56.2063C38.0706 56.2279 38.0654 56.2476 38.0654 56.2699V70.7852C38.0654 71.4154 38.5763 71.9271 39.2066 71.9271H60.8093C61.4394 71.9271 61.9503 71.4154 61.9503 70.7852V56.2699C61.9503 56.2493 61.9455 56.2302 61.9442 56.21C70.5708 51.6384 76.1667 42.2967 75.4624 31.9269Z"
        fill="#B0A6F9"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M70.6106 32.2558C71.3832 43.6349 62.7857 53.4846 51.4066 54.257C40.0279 55.0305 30.1778 46.4326 29.4052 35.0543C28.6325 23.676 37.2303 13.8251 48.6086 13.0527C59.9873 12.2798 69.8377 20.8777 70.6106 32.2558Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M58.7604 62.7879C58.7604 60.0152 56.5129 57.7671 53.74 57.7671H46.2762C43.5035 57.7671 41.2559 60.0152 41.2559 62.7879C41.2559 63.0054 41.2559 63.8539 41.2559 64.0714C41.2559 66.8445 43.5035 69.0924 46.2762 69.0924H53.74C56.5129 69.0924 58.7604 66.8445 58.7604 64.0714C58.7604 63.8539 58.7604 63.0054 58.7604 62.7879Z"
        fill="#8173E6"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M58.7604 64.0714C58.7604 66.8445 56.5129 69.0924 53.74 69.0924H46.2762C43.5035 69.0924 41.2559 66.8445 41.2559 64.0714C41.2559 61.2985 43.5035 59.0508 46.2762 59.0508H53.74C56.5129 59.0508 58.7604 61.2985 58.7604 64.0714Z"
        fill="#B0A6F9"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M46.698 66.2428C47.8327 66.2428 48.7525 65.3229 48.7525 64.1883C48.7525 63.0536 47.8327 62.1338 46.698 62.1338C45.5634 62.1338 44.6436 63.0536 44.6436 64.1883C44.6436 65.3229 45.5634 66.2428 46.698 66.2428Z"
        fill="#49B889"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M53.3175 66.2428C54.452 66.2428 55.3717 65.3231 55.3717 64.1886C55.3717 63.054 54.452 62.1343 53.3175 62.1343C52.1829 62.1343 51.2632 63.054 51.2632 64.1886C51.2632 65.3231 52.1829 66.2428 53.3175 66.2428Z"
        fill="#FFD18C"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M46.698 65.482C47.8327 65.482 48.7525 64.5622 48.7525 63.4275C48.7525 62.2929 47.8327 61.373 46.698 61.373C45.5634 61.373 44.6436 62.2929 44.6436 63.4275C44.6436 64.5622 45.5634 65.482 46.698 65.482Z"
        fill="#49B889"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M53.3175 65.4821C54.452 65.4821 55.3717 64.5624 55.3717 63.4278C55.3717 62.2933 54.452 61.3735 53.3175 61.3735C52.1829 61.3735 51.2632 62.2933 51.2632 63.4278C51.2632 64.5624 52.1829 65.4821 53.3175 65.4821Z"
        fill="#FF6565"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M70.6106 32.2558C71.3832 43.6349 62.7857 53.4846 51.4066 54.257C40.0279 55.0305 30.1778 46.4326 29.4052 35.0543C28.6325 23.676 37.2303 13.8251 48.6086 13.0527C59.9873 12.2798 69.8377 20.8777 70.6106 32.2558Z"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M48.6081 15.8119C59.9869 15.0388 69.8373 23.6363 70.6104 35.015C70.6108 35.0248 70.6116 35.0352 70.612 35.0451C70.6725 34.1285 70.6742 33.198 70.6104 32.2558C69.8373 20.8775 59.9869 12.2798 48.6081 13.0527C37.2405 13.8253 28.6479 23.6587 29.4031 35.0248C30.0758 24.8666 38.1718 16.5203 48.6081 15.8119Z"
        fill="#8173E6"
        stroke="var(--shadow-color)"
        strokeMiterlimit="10"
      />
      <path
        d="M61.9443 60.2149V56.2096L66.9373 52.96L67.0497 56.5161C67.0497 56.5161 63.8447 59.2882 61.9443 60.2149Z"
        fill="var(--shadow-color)"
      />
      <path
        d="M41.7666 61.6009C41.7666 61.6009 43.4812 59.2227 46.744 59.0569H49.0671V57.6743L46.2761 57.767C46.2761 57.767 42.5407 58.2274 41.7666 61.6009Z"
        fill="var(--shadow-color)"
      />
      <path
        d="M20.3855 29.8827L8.89086 25.686L1.34668 35.3217V39.6469L8.17971 49.7984L19.9467 46.437L20.3855 33.1669V29.8827Z"
        fill="#2A7857"
        stroke="var(--shadow-color)"
      />
      <path
        d="M8.17971 45.474L1.34668 35.3217L8.89086 25.686L20.3855 29.8827L19.9467 42.1125L8.17971 45.474Z"
        fill="#49B889"
        stroke="var(--shadow-color)"
      />
      <path d="M8.17969 49.4637V45.0938" stroke="var(--shadow-color)" />
      <path
        d="M65.6694 27.7572C66.5069 29.5919 67.0335 31.6059 67.1785 33.737C67.8226 43.22 60.6569 51.4286 51.1738 52.073C41.6908 52.7173 33.482 45.5518 32.8376 36.0691C32.1936 26.5866 39.3594 18.3767 48.8417 17.7331C52.7506 17.4676 56.4437 18.5292 59.4794 20.5334"
        fill="var(--shadow-color)"
      />
      <path
        d="M65.6694 27.7572C66.5069 29.5919 67.0335 31.6059 67.1785 33.737C67.8226 43.22 60.6569 51.4286 51.1738 52.073C41.6908 52.7173 33.482 45.5518 32.8376 36.0691C32.1936 26.5866 39.3594 18.3767 48.8417 17.7331C52.7506 17.4676 56.4437 18.5292 59.4794 20.5334"
        stroke="#49B889"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.6079 37.6477L44.3913 28.503L54.2023 27.207L60.2305 35.0564L56.4471 44.2007L46.6359 45.4967L40.6079 37.6477Z"
        fill="#1F5941"
        stroke="#49B889"
      />
      <path
        d="M40.6079 33.7095L44.3913 24.5646L54.2023 23.269L60.2305 31.1182L56.4471 40.2623L46.6359 41.5583L40.6079 33.7095Z"
        stroke="#49B889"
      />{" "}
      <path d="M40.6079 33.71V37.6482" stroke="#49B889" />
      <path d="M46.6362 41.5586V45.497" stroke="#49B889" />
      <path d="M56.4473 40.2627V44.2011" stroke="#49B889" />
      <path d="M60.231 31.1187V35.0568" />
      <path d="M54.2026 23.269V27.207" stroke="#49B889" />
      <path d="M44.3916 24.5649V28.5033" stroke="#49B889" />
      <path
        d="M100.493 34.1765L87.3264 25.8936L79.0435 39.06V43.6516L92.2103 51.9345L100.493 38.7679V34.1765Z"
        fill="#5178B3"
        stroke="var(--shadow-color)"
      />
      <path
        d="M100.482 34.1768L87.3154 25.8936L79.0323 39.0595L92.1984 47.3427L100.482 34.1768Z"
        fill="#96BEFA"
        stroke="var(--shadow-color)"
      />
      <path d="M92.2104 51.9351V47.3438" stroke="var(--shadow-color)" />
      <path
        d="M63.0084 24.8293C63.6586 24.8293 64.1857 24.3022 64.1857 23.652C64.1857 23.0017 63.6586 22.4746 63.0084 22.4746C62.3582 22.4746 61.8311 23.0017 61.8311 23.652C61.8311 24.3022 62.3582 24.8293 63.0084 24.8293Z"
        fill="#FF6565"
      />
    </svg>
  );
};

export default ToolsIcon;
