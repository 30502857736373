import { styled } from "@linaria/react";
import { LayoutWithSidebar } from "../components/layout/LayoutWithSidebar";
import LibrarySidebar from "../components/library/LibrarySidebar";
import WithLibraryState from "../components/library/WithLibraryState";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import Page from "./Page.template";
import LibraryPageHero from "../components/library/LibraryPageHero";
import Spacing from "../components/layout/Spacing";
import LibraryEmptyState from "../components/library/LibraryEmptyState";
import LibraryStoryTable from "../components/library/LibraryStoryTable";
import LibraryToolImageWithDarkMode from "../components/library/LibraryToolImageWithDarkMode";
import { useSiteContext } from "../context/site.context";
import { useRef } from "react";
import { onlyPhones } from "../styles/breakpointsAndMediaQueries.styles";
import { useRankedStoriesAndTools } from "../utils/library.utils";
import seoImage from "../../static/images/og/tines-library.png";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { graphql } from "gatsby";
import { DatoCmsLibraryTool, ToolPageQuery } from "../../graphql-types";
import { LoadingStateBanner } from "../components/utilities/LoadingIndicatorBanner";

const BodyContent = styled.div`
  ${onlyPhones} {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const LibraryToolPage: PageComponent<{
  data: ToolPageQuery;
  pageContext: {
    slug: string;
  };
}> = props => {
  const tool = props.data.tool as DatoCmsLibraryTool;
  const siteContext = useSiteContext();
  const hasStoryOpen = useRef(!!siteContext.library.storyToRenderInLightbox);
  hasStoryOpen.current = !!siteContext.library.storyToRenderInLightbox;
  const { awaitingData, error, stories, tools } = useRankedStoriesAndTools({
    toolSlug: props.pageContext.slug,
  });

  return (
    <Page {...props}>
      <SEO
        title={`${tool.name} | Tools | Tines library`}
        description="Ready-made automation workflows that you can use in one click. Use these Stories as a starting point for fully custom playbooks or inspiration for your use cases."
        image={seoImage}
      />
      {!hasStoryOpen.current && (
        <AdminToolBarWithEditInDatoCmsButton
          itemType="libraryTool"
          recordId={tool.id}
        />
      )}
      <WithLibraryState
        awaitingData={awaitingData}
        error={error}
        entries={stories}
        entryType="story"
        tools={tools}
        children={p => {
          return (
            <LayoutWithSidebar noPageMarginOnPhones>
              <LibrarySidebar state={p.state} />
              <BodyContent>
                <LibraryPageHero
                  collection={{
                    tagline: tool.name,
                  }}
                  neutralColor
                  libraryState={p.state}
                  icon={<LibraryToolImageWithDarkMode tool={tool} />}
                  totalCount={stories.length}
                  compact
                  topTagLabel="← Tools"
                  topTagLink="/library/tools"
                />
                <Spacing />
                <LoadingStateBanner
                  loading={p.state.awaitingData}
                  error={p.state.error}
                />
                {p.state.error && p.entries.length === 0 && <Spacing />}
                {!p.state.awaitingData &&
                  (p.entries.length > 0 ? (
                    <LibraryStoryTable
                      stories={p.entries}
                      tools={tools}
                      state={p.state}
                    />
                  ) : (
                    <LibraryEmptyState state={p.state} />
                  ))}
                <Spacing size="lg" />
              </BodyContent>
            </LayoutWithSidebar>
          );
        }}
      />
      <Spacing size="xl" />
    </Page>
  );
};

export const toolPageQuery = graphql`
  query ToolPage($slug: String) {
    tool: datoCmsLibraryTool(slug: { eq: $slug }) {
      id: originalId
      name
      slug
      altSlugs
      isPartner
      tags
      image {
        url
      }
      imageDarkMode {
        url
      }
    }
  }
`;

export default LibraryToolPage;
