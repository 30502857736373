import ContentCardBase from "./_ContentCardBase";
import { css } from "linaria";
import { DatoCmsContentCard } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import {
  PrimaryColorName,
  brandColorThemeVar,
} from "../../styles/colors.styles";

const contentCardCaseStudiesStyle = css`
  [data-path="/"] & {
    border-color: ${brandColorThemeVar(700)};
    &:hover {
      border-color: ${brandColorThemeVar(800)};
    }
  }
`;

const ContentCardCaseStudies = ({
  overrides,
  large,
}: {
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => {
  return (
    <ContentCardBase
      className={contentCardCaseStudiesStyle}
      link="/case-studies"
      color={(overrides?.color ?? "green") as PrimaryColorName}
      variant="50"
      large={large}
    >
      <header>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Case studies"}</strong>
          </p>
          <h4>{overrides?.heading || "Powerful users, powerful studies"}</h4>
          <ReactMarkdown>
            {overrides?.description ||
              "Discover how companies – from Fortune 10 to startups – apply Tines to transform the way their teams operate."}
          </ReactMarkdown>
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Read case studies"}</strong>
        </p>
      </header>
      <figure>
        <Icon />
      </figure>
    </ContentCardBase>
  );
};

export default ContentCardCaseStudies;

const Icon = () => (
  <svg
    width="84"
    height="86"
    viewBox="0 0 84 86"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M48.4402 28.461C47.9443 28.459 47.4556 28.3351 47.019 28.0997L42.4343 25.6475V25.647C42.2351 25.5436 41.9984 25.5436 41.7992 25.647L37.1797 28.0357C36.5098 28.3835 35.7337 28.4674 35.0051 28.2705C34.2769 28.0735 33.649 27.6107 33.2455 26.9723C32.842 26.3343 32.6932 25.5684 32.828 24.8259L33.7425 19.7051H33.7422C33.7742 19.4913 33.7027 19.275 33.5499 19.1222L29.8508 15.4636C29.3148 14.9344 28.9966 14.2227 28.9593 13.4699C28.922 12.7173 29.1678 11.9781 29.6492 11.3983C30.1298 10.8183 30.8108 10.4395 31.5576 10.3371L36.7072 9.62626C36.9312 9.59451 37.1251 9.45573 37.2259 9.25365L39.5558 4.59305H39.5562C39.8034 4.0903 40.1869 3.6675 40.6627 3.37194C41.1384 3.0766 41.6875 2.92004 42.2476 2.92093C42.8149 2.91827 43.3712 3.07704 43.8517 3.37793C44.3323 3.67926 44.7173 4.11073 44.9627 4.62213L47.2288 9.28296L47.2284 9.28252C47.3263 9.48615 47.5184 9.6276 47.7414 9.66135L52.8796 10.4479C53.6262 10.5607 54.3028 10.9491 54.7762 11.5369C55.2492 12.1244 55.4848 12.8686 55.4355 13.6216C55.3865 14.3752 55.0565 15.0823 54.5109 15.6037L50.7649 19.2042C50.611 19.3561 50.5375 19.5721 50.5671 19.7864L51.412 24.9189C51.5666 25.7974 51.325 26.6991 50.7518 27.3829C50.1782 28.0659 49.332 28.4605 48.4402 28.461Z"
      fill="#B35980"
      stroke="#32313B"
    />
    <path
      d="M51.5848 41.0564C51.0814 40.6771 50.7718 40.0951 50.7383 39.4658C50.7052 38.8365 50.9519 38.2253 51.4122 37.7948L54.0688 35.2571C54.1765 35.1487 54.2269 34.9955 54.2042 34.8445L53.6191 31.2218C53.5325 30.6957 53.6466 30.1563 53.9391 29.7107C54.2306 29.2643 54.6797 28.9448 55.1962 28.8138C55.7131 28.683 56.2601 28.7511 56.7293 29.0045L59.9629 30.7428C60.1033 30.8183 60.2713 30.8201 60.4132 30.7468H60.4128L63.6711 29.0787C64.0268 28.8941 64.4276 28.8142 64.8267 28.8482C65.2257 28.8824 65.607 29.0289 65.9261 29.2714C66.2426 29.5086 66.4859 29.8306 66.6289 30.1992C66.7719 30.568 66.809 30.9695 66.7351 31.3583H66.7355L66.0709 34.9784C66.0418 35.1354 66.0927 35.2959 66.2052 35.409L68.8098 37.9971C69.1875 38.3719 69.4113 38.8751 69.4358 39.4065C69.4611 39.9381 69.2866 40.4599 68.9457 40.8685C68.6053 41.2776 68.1234 41.544 67.5956 41.6153L63.9544 42.1092C63.803 42.1307 63.6711 42.2222 63.5983 42.3563H63.598L61.9397 45.6346C61.7001 46.1111 61.2911 46.4802 60.7923 46.669C60.2936 46.8577 59.7424 46.8524 59.2479 46.6541C58.7529 46.456 58.3507 46.0792 58.1202 45.5982L56.5276 42.2882C56.459 42.1452 56.3256 42.0448 56.169 42.0186V42.0188L52.5397 41.4557C52.1939 41.4033 51.8655 41.2658 51.5848 41.0564Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M59.7183 64.2302C59.1037 63.8378 58.694 63.1941 58.5989 62.4713C58.5046 61.7478 58.7342 61.0205 59.2269 60.4825L62.0649 57.3161C62.1797 57.1818 62.2238 56.9999 62.1843 56.8278L61.1846 52.7002C61.0376 52.1008 61.1206 51.4684 61.4182 50.9277C61.7149 50.3867 62.2043 49.9775 62.7885 49.7805C63.3732 49.5831 64.0103 49.6129 64.5746 49.8631L68.4617 51.5794C68.6305 51.6536 68.8246 51.6405 68.982 51.5435H68.9816L72.5916 49.3269C72.9856 49.0821 73.4406 48.9533 73.9042 48.9571C74.3677 48.9611 74.8211 49.0959 75.2108 49.3473C75.5972 49.5924 75.9068 49.9419 76.1049 50.3543C76.3032 50.7673 76.3815 51.2275 76.3313 51.6827H76.3318L75.8892 55.9191C75.8699 56.1028 75.9423 56.2836 76.0829 56.4039L79.3205 59.1568C79.7897 59.5554 80.0931 60.1159 80.1692 60.727C80.2465 61.3379 80.0908 61.9559 79.7346 62.4582C79.3785 62.9607 78.8462 63.3113 78.2437 63.4406L74.0861 64.337C73.9138 64.3752 73.7692 64.4925 73.6973 64.6541L73.6975 64.6539L72.0773 68.5852C71.8439 69.1564 71.4047 69.6187 70.8465 69.8816C70.2875 70.1441 69.6513 70.1874 69.0631 70.0027C68.4746 69.8188 67.9761 69.4198 67.6672 68.8855L65.5332 65.2088C65.4413 65.0501 65.2776 64.9459 65.0949 64.9297V64.9304L60.8569 64.6057C60.452 64.576 60.061 64.4467 59.7183 64.2302Z"
      fill="#B38847"
      stroke="#32313B"
    />
    <path
      d="M23.1859 65.0289C22.82 65.2032 22.416 65.285 22.011 65.2663L17.7643 65.0851V65.0844C17.5811 65.0791 17.4063 65.1631 17.2962 65.3096L14.7403 68.7065C14.3699 69.2001 13.8278 69.537 13.2214 69.6502C12.6154 69.7635 11.9885 69.6447 11.4651 69.3176C10.9422 68.9905 10.5609 68.4793 10.3968 67.8842L9.25538 63.7883L9.25561 63.7885C9.20343 63.6195 9.07396 63.4861 8.90719 63.4274L4.88568 62.0435C4.303 61.8435 3.81624 61.432 3.52201 60.8908C3.228 60.3499 3.14717 59.7181 3.29617 59.1203C3.44451 58.5228 3.81224 58.0023 4.32564 57.6623L7.86751 55.3136C8.02117 55.2105 8.11466 55.0402 8.11732 54.8555L8.1815 50.5963H8.18195C8.18617 50.1382 8.31852 49.6908 8.56456 49.3044C8.81038 48.9185 9.15924 48.6082 9.57205 48.4106C9.98885 48.2076 10.4552 48.1275 10.9157 48.1785C11.3765 48.2301 11.8131 48.4119 12.1753 48.7015L15.4962 51.3316H15.4957C15.6403 51.4468 15.8317 51.483 16.008 51.4291L20.0717 50.1869C20.6615 50.0054 21.2977 50.0516 21.8547 50.317C22.4114 50.5819 22.8488 51.0465 23.0791 51.6189C23.3103 52.1914 23.3176 52.8292 23.1004 53.4067L21.617 57.3865C21.5573 57.5526 21.58 57.7382 21.6777 57.8855L24.1192 61.3665C24.5445 61.9592 24.6861 62.7091 24.5063 63.4159C24.3262 64.1218 23.8428 64.7123 23.1859 65.0289Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M41.9215 76.6009C40.7952 76.6009 39.8299 76.3362 39.7146 75.1913C39.0706 68.7906 36.8078 64.1884 32.9891 61.5128C26.6395 57.0634 17.9816 59.1867 17.8945 59.2087C16.7056 59.5098 15.4978 58.7901 15.1969 57.6014C14.8958 56.4125 15.6153 55.2047 16.8042 54.9036C17.2388 54.7935 27.5399 52.2715 35.5377 57.8759C40.4623 61.3265 43.3542 67.0026 44.1334 74.7469C44.2564 75.9672 43.3664 76.467 42.1464 76.5898C42.0711 76.5974 41.9959 76.6009 41.9215 76.6009Z"
      fill="#2A7857"
      stroke="#32313B"
    />
    <path
      d="M33.3004 41.58C33.0197 41.7894 32.6915 41.9269 32.3449 41.9795L28.7157 42.5427L28.7155 42.5425C28.5592 42.5687 28.4253 42.669 28.3569 42.812L26.7643 46.1221C26.534 46.6028 26.1314 46.9799 25.6367 47.1782C25.1421 47.3765 24.591 47.3816 24.0922 47.1928C23.5937 47.0039 23.1844 46.6348 22.9451 46.1585L21.2865 42.8802C21.2136 42.7461 21.0815 42.6544 20.9303 42.6331L17.289 42.1392C16.7613 42.0679 16.2793 41.8017 15.9388 41.3926C15.5982 40.9838 15.4232 40.462 15.4487 39.9304C15.4734 39.3988 15.6972 38.8958 16.0747 38.5209L18.6793 35.9328C18.7923 35.8196 18.8427 35.659 18.8136 35.5023L18.1492 31.8822H18.1497C18.0755 31.4934 18.1124 31.0919 18.2556 30.7231C18.3988 30.3542 18.6422 30.0327 18.9586 29.7953C19.2777 29.5528 19.659 29.4063 20.0578 29.3721C20.4571 29.3381 20.8579 29.418 21.2134 29.6028L24.4719 31.2709H24.4715C24.6134 31.3442 24.7815 31.3424 24.9216 31.2669L28.1555 29.5284C28.6245 29.2752 29.1714 29.2068 29.6884 29.3376C30.2049 29.4687 30.6539 29.788 30.9457 30.2343C31.2379 30.6802 31.3521 31.2196 31.2654 31.7457L30.6801 35.3686C30.6579 35.5196 30.7081 35.6728 30.8158 35.7809L33.4723 38.3187C33.9326 38.749 34.1795 39.3603 34.1465 39.9897C34.1138 40.6187 33.804 41.2008 33.3004 41.58Z"
      fill="#B38847"
      stroke="#32313B"
    />
    <path
      d="M48.6976 85.2792C61.7573 85.2792 72.3443 84.0032 72.3443 82.4293C72.3443 80.8553 61.7573 79.5793 48.6976 79.5793C35.6378 79.5793 25.0508 80.8553 25.0508 82.4293C25.0508 84.0032 35.6378 85.2792 48.6976 85.2792Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M61.4452 77.2988C61.4452 75.4899 52.7188 74.0232 41.9542 74.0232C31.1896 74.0232 22.4629 75.4897 22.4629 77.2988C22.4629 78.1917 22.4629 80.9923 22.4629 81.8852C22.4629 83.6941 31.1896 85.1611 41.9542 85.1611C52.7188 85.1611 61.4452 83.6944 61.4452 81.8852C61.4452 80.9923 61.4452 78.1919 61.4452 77.2988Z"
      fill="#36996F"
      stroke="#32313B"
    />
    <path
      d="M41.954 80.5758C52.7187 80.5758 61.4451 79.1089 61.4451 77.2995C61.4451 75.49 52.7187 74.0232 41.954 74.0232C31.1894 74.0232 22.4629 75.49 22.4629 77.2995C22.4629 79.1089 31.1894 80.5758 41.954 80.5758Z"
      fill="#81D8B4"
      stroke="#32313B"
    />
    <path
      d="M42.1934 52.7791C41.9855 52.7791 41.7746 52.7497 41.565 52.6889C40.38 52.342 39.7005 51.1007 40.047 49.9156C42.3317 42.1026 47.1496 38.811 50.7889 37.4254C54.7278 35.9258 58.1549 36.3135 58.2985 36.3304C59.5245 36.4772 60.3995 37.5899 60.2527 38.8157C60.1063 40.0381 58.9987 40.9115 57.7778 40.7711C57.3448 40.7236 47.6567 39.8218 44.3381 51.1705C44.0529 52.1458 43.1609 52.7788 42.1934 52.7791Z"
      fill="#2A7857"
      stroke="#32313B"
    />
    <path
      d="M41.9404 77.3589C44.8684 77.3589 47.2421 76.7934 47.2421 76.0958C47.2421 75.3983 44.8684 74.8328 41.9404 74.8328C39.0123 74.8328 36.6387 75.3983 36.6387 76.0958C36.6387 76.7934 39.0123 77.3589 41.9404 77.3589Z"
      fill="#32313B"
    />
    <path
      d="M44.4348 50.5828V24.9046C44.4348 23.67 43.434 22.6689 42.1993 22.6689C40.9647 22.6689 39.9639 23.67 39.9639 24.9046V41.7777C37.8909 39.4929 35.5742 38.181 33.6029 37.4306C29.6643 35.9313 26.2372 36.3186 26.0933 36.3356C24.8722 36.482 24.0048 37.5861 24.1441 38.8083C24.2835 40.0301 25.3903 40.9092 26.6125 40.7764C27.0211 40.7324 36.5603 39.8313 39.9639 50.8817V74.763C39.9639 75.9976 40.9647 75.6201 42.1993 75.6201C43.434 75.6201 44.4348 75.9974 44.4348 74.763V50.5888C44.4348 50.5868 44.4348 50.585 44.4348 50.5828Z"
      fill="#36996F"
      stroke="#32313B"
    />
    <path
      d="M48.4402 26.5405C47.9443 26.5385 47.4556 26.4144 47.019 26.179L42.4343 23.7268V23.7264C42.2351 23.6226 41.9984 23.6226 41.7992 23.7264L37.1797 26.1151C36.5098 26.4628 35.7337 26.5465 35.0051 26.3496C34.2769 26.1528 33.649 25.6896 33.2455 25.0518C32.842 24.4134 32.6932 23.6475 32.828 22.9052L33.7425 17.7845H33.7422C33.7742 17.5708 33.7027 17.3546 33.5499 17.2016L29.8508 13.5431C29.3148 13.0135 28.9966 12.302 28.9593 11.5494C28.922 10.7969 29.1678 10.0574 29.6492 9.47785C30.1298 8.89739 30.8108 8.51899 31.5576 8.41618L36.7072 7.70558C36.9312 7.67383 37.1251 7.53482 37.2259 7.33296L39.5558 2.67214H39.5562C39.8034 2.16962 40.1869 1.7466 40.6627 1.45103C41.1384 1.15569 41.6875 0.999362 42.2476 1.00003C42.8149 0.997585 43.3712 1.15613 43.8517 1.45725C44.3323 1.75858 44.7173 2.18982 44.9627 2.70145L47.2288 7.36206L47.2284 7.36161C47.3263 7.56546 47.5184 7.7067 47.7414 7.74045L52.8796 8.52721C53.6262 8.63979 54.3028 9.02818 54.7762 9.61642C55.2492 10.204 55.4848 10.9481 55.4355 11.7009C55.3865 12.4543 55.0565 13.1616 54.5109 13.683L50.7649 17.2833C50.611 17.4352 50.5375 17.651 50.5671 17.8655L51.412 22.9978C51.5666 23.8765 51.325 24.7783 50.7518 25.4615C50.1782 26.1455 49.332 26.5399 48.4402 26.5405Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M31.3797 40.3897C31.0988 40.5993 30.7706 40.7365 30.4239 40.7891L26.795 41.3521H26.7946C26.6385 41.3785 26.5046 41.4786 26.4362 41.6216L24.8433 44.9315C24.613 45.4124 24.2107 45.7895 23.7157 45.9878C23.2212 46.1861 22.67 46.1914 22.1715 46.0024C21.673 45.8135 21.2635 45.4444 21.0241 44.9683L19.3658 41.6898H19.3655C19.2929 41.5555 19.1608 41.464 19.0094 41.4427L15.368 40.9488C14.8406 40.8775 14.3585 40.611 14.0181 40.2022C13.6775 39.7934 13.5025 39.2716 13.5278 38.74C13.5524 38.2083 13.7763 37.7054 14.154 37.3305L16.7586 34.7422C16.8718 34.6292 16.922 34.4686 16.8931 34.3116L16.2285 30.6918H16.2289C16.155 30.3028 16.1919 29.9015 16.3351 29.5325C16.4783 29.1636 16.7217 28.8418 17.0379 28.6047C17.3572 28.3622 17.7383 28.2156 18.1373 28.1814C18.5364 28.1472 18.9372 28.2274 19.2929 28.4119L22.5512 30.0801H22.5508C22.6927 30.1536 22.8608 30.1516 23.0011 30.0761L26.235 28.3378C26.704 28.0844 27.2509 28.0162 27.7676 28.147C28.2842 28.2778 28.7334 28.5973 29.0249 29.0437C29.3174 29.4894 29.4315 30.0288 29.3449 30.5548L28.7596 34.1775C28.7371 34.3287 28.7876 34.4817 28.8953 34.5901L31.5518 37.1278C32.0121 37.5582 32.259 38.1695 32.2257 38.7988C32.1929 39.4286 31.8831 40.0104 31.3797 40.3897Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M20.9628 64.1398C20.5968 64.3141 20.1929 64.3958 19.7878 64.3772L15.5411 64.1959V64.1953C15.3579 64.1899 15.1832 64.2739 15.0731 64.4205L12.5171 67.8173C12.1467 68.311 11.6047 68.6478 10.9982 68.7611C10.3922 68.8743 9.76536 68.7555 9.24196 68.4284C8.71901 68.1013 8.33773 67.5901 8.17363 66.995L7.03224 62.8991L7.03247 62.8993C6.98028 62.7303 6.85082 62.5969 6.68405 62.5383L2.66254 61.1544C2.07985 60.9543 1.5931 60.5428 1.29887 60.0017C1.00486 59.4607 0.924023 58.829 1.07303 58.2312C1.22136 57.6336 1.58909 57.1131 2.10249 56.7731L5.64436 54.4244C5.79803 54.3214 5.89151 54.151 5.89418 53.9663L5.95835 49.7072H5.9588C5.96302 49.2491 6.09537 48.8016 6.34142 48.4152C6.58724 48.0293 6.93609 47.7191 7.3489 47.5214C7.76571 47.3185 8.23203 47.2383 8.69258 47.2894C9.15336 47.3409 9.58993 47.5228 9.95211 47.8123L13.273 50.4424H13.2726C13.4171 50.5577 13.6086 50.5939 13.7849 50.5399L17.8486 49.2977C18.4384 49.1163 19.0746 49.1625 19.6315 49.4278C20.1882 49.6927 20.6257 50.1573 20.8559 50.7298C21.0871 51.3022 21.0944 51.94 20.8773 52.5176L19.3939 56.4973C19.3342 56.6634 19.3568 56.8491 19.4545 56.9963L21.8961 60.4773C22.3213 61.07 22.463 61.8199 22.2831 62.5267C22.103 63.2326 21.6196 63.8231 20.9628 64.1398Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M53.5853 40.3897C53.0819 40.0104 52.7723 39.4283 52.7388 38.799C52.7057 38.1697 52.9524 37.5586 53.4127 37.128L56.0692 34.5903C56.1769 34.482 56.2274 34.3287 56.2047 34.1777L55.6196 30.555C55.533 30.029 55.6471 29.4896 55.9396 29.0439C56.2311 28.5976 56.6801 28.278 57.1967 28.147C57.7136 28.0162 58.2605 28.0844 58.7298 28.3378L61.9634 30.0761C62.1037 30.1516 62.2718 30.1533 62.4137 30.0801H62.4133L65.6716 28.4119C66.0273 28.2274 66.4281 28.1474 66.8272 28.1814C67.2262 28.2156 67.6075 28.3622 67.9266 28.6047C68.243 28.8418 68.4864 29.1638 68.6294 29.5325C68.7724 29.9013 68.8095 30.3028 68.7356 30.6916H68.736L68.0714 34.3116C68.0423 34.4686 68.0931 34.6292 68.2057 34.7422L70.8103 37.3303C71.188 37.7052 71.4118 38.2083 71.4363 38.7397C71.4616 39.2713 71.2871 39.7932 70.9462 40.2018C70.6058 40.6108 70.1239 40.8773 69.596 40.9486L65.9549 41.4424C65.8035 41.464 65.6716 41.5555 65.5987 41.6896H65.5985L63.9402 44.9679C63.7006 45.4444 63.2915 45.8135 62.7928 46.0022C62.294 46.191 61.7429 46.1856 61.2484 45.9873C60.7534 45.7893 60.3512 45.4124 60.1207 44.9314L58.5281 41.6214C58.4595 41.4784 58.326 41.378 58.1695 41.3518V41.3521L54.5401 40.7889C54.1944 40.7365 53.866 40.5991 53.5853 40.3897Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M42.3068 76.6009C43.4331 76.6009 44.3984 76.3362 44.5136 75.1913C45.1576 68.7906 47.4204 64.1884 51.2392 61.5128C57.5888 57.0634 66.2467 59.1867 66.3337 59.2087C67.5227 59.5098 68.7304 58.7901 69.0313 57.6014C69.3324 56.4125 68.613 55.2047 67.4241 54.9036C66.9895 54.7935 56.6883 52.2715 48.6906 57.8759C43.766 61.3265 40.8741 67.0026 40.0949 74.7469C39.9718 75.9672 40.8619 76.467 42.0819 76.5898C42.1571 76.5974 42.2324 76.6009 42.3068 76.6009Z"
      fill="#36996F"
      stroke="#32313B"
    />
    <path
      d="M62.1622 63.5637C61.5475 63.1713 61.1378 62.5276 61.0428 61.8048C60.9484 61.0813 61.178 60.354 61.6708 59.816L64.5087 56.6496C64.6235 56.5153 64.6677 56.3334 64.6282 56.1613L63.6285 52.0337C63.4814 51.4343 63.5645 50.8019 63.8621 50.2612C64.1587 49.7202 64.6482 49.311 65.2324 49.114C65.8171 48.9166 66.4542 48.9464 67.0184 49.1966L70.9056 50.9129C71.0744 50.9871 71.2684 50.974 71.4259 50.877H71.4254L75.0355 48.6603C75.4294 48.4156 75.8844 48.2868 76.3481 48.2906C76.8115 48.2946 77.265 48.4294 77.6547 48.6808C78.0411 48.9259 78.3506 49.2754 78.5487 49.6878C78.747 50.1008 78.8254 50.561 78.7752 51.0162H78.7756L78.3331 55.2526C78.3137 55.4363 78.3862 55.6171 78.5267 55.7374L81.7644 58.4903C82.2336 58.8889 82.5369 59.4494 82.6131 60.0605C82.6903 60.6714 82.5347 61.2894 82.1785 61.7917C81.8223 62.2942 81.29 62.6448 80.6876 62.7741L76.5299 63.6705C76.3576 63.7087 76.2131 63.826 76.1411 63.9876L76.1413 63.9874L74.5212 67.9187C74.2878 68.4899 73.8486 68.9522 73.2903 69.2151C72.7314 69.4776 72.0952 69.5209 71.5069 69.3362C70.9185 69.1523 70.4199 68.7533 70.1111 68.219L67.9771 64.5423C67.8851 64.3836 67.7215 64.2794 67.5387 64.2632V64.2639L63.3007 63.9392C62.8959 63.9095 62.5048 63.7802 62.1622 63.5637Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M40.1231 27.2842C40.1231 27.2842 40.9088 26.695 42.2181 26.695C43.5273 26.695 44.3456 27.3823 44.3456 27.3823L44.4435 24.8948C44.4435 24.8948 43.3306 23.7166 42.0542 23.7166C40.7778 23.7166 39.8613 25.0913 39.8613 25.0913L40.1231 27.2842Z"
      fill="#32313B"
    />
    <path
      d="M55.3545 41.0833L57.131 43.3594L59.2405 43.1928L58.1676 41.3419L55.3545 41.0833Z"
      fill="#32313B"
    />
    <path
      d="M26.1772 42.8993L28.3603 42.8087L31.9508 41.789L29.5082 40.9563L26.7324 41.1784L26.1772 42.8993Z"
      fill="#32313B"
    />
    <path
      d="M14.3193 65.6752L16.6863 66.1053L17.7622 64.7065L15.0795 64.4189L14.3193 65.6752Z"
      fill="#32313B"
    />
    <path
      d="M65.0225 64.2722L66.0984 66.2088L68.5728 65.886L67.5326 64.2603L65.0225 64.2722Z"
      fill="#32313B"
    />
    <path
      d="M59.9746 59.9793L61.6738 59.8236L62.3418 59.1186L60.7278 58.6885L59.9746 59.9793Z"
      fill="#32313B"
    />
    <path
      d="M20.6689 59.0022L21.9043 60.4732L23.5737 60.4008L22.3904 58.8945L20.6689 59.0022Z"
      fill="#32313B"
    />
  </svg>
);
