import { styled } from "@linaria/react";
import {
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

const UptoTabletLg = styled.div`
  ${fromTabletLg} {
    display: none;
  }
`;
export const FromTabletLg = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
`;
export const FromTabletLgG = styled.g`
  ${uptoTabletLg} {
    display: none;
  }
`;

export default UptoTabletLg;
