import { styled } from "@linaria/react";
import { cx } from "linaria";
import {
  fromTablet,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import {
  Action,
  ActionType,
  EventTransformAction,
  HTTPRequestAction,
  SendEmailAction,
  TriggerAction,
} from "../../types/tines.types";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { useStoryboardContext } from "./StoryboardContext";
import FormattedJSONBlock from "./FormattedJSONBlock";
import Spacing from "../layout/Spacing";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import React, { ReactNode } from "react";
import InfoBox from "../general/InfoBox";
import { colors } from "../../styles/colors.styles";

type Props = {};

const StoryboardActionInspectorWrap = styled.div`
  font-size: 1.4rem;
  > * + * {
    margin-top: 1em;
  }
`;

const EmptyState = styled.div`
  border-radius: 1em;
  background-color: ${withOpacity(colorsV4.white, 0.5)};
  ${darkModeLinariaCSS(`
    background-color: ${withOpacity(colorsV4.warmBlack900, 0.5)};
  `)}
  padding: 2.25em 1.5em 2.5em;
  height: 100%;
  text-align: center;
  h3 {
    font-size: inherit;
  }
  > * + * {
    margin-top: 0.5em;
  }
  ${uptoTabletMd} {
    text-align: center;
  }
  p {
    opacity: 0.4;
    margin-left: auto;
    margin-right: auto;
    max-width: 33em;
  }
`;

export const StoryboardActionInspectorInner = styled.div`
  text-align: left;
`;

const JSONBlockScrollable = styled.div`
  overflow: auto;
  max-height: 35rem;
`;

const StoryboardActionInspector = (p: {
  emptyState?: ReactNode;
  withIcon?: boolean;
  copiable?: boolean;
}) => {
  const context = useStoryboardContext();
  const selectedAction = context.story.actions.find(
    a => a.index === context.selectedActionId
  );
  return (
    <StoryboardActionInspectorWrap
      className={cx(selectedAction ? "hasSelection" : "empty")}
    >
      {selectedAction ? (
        <StoryboardActionInspectorInner>
          {(() => {
            switch (selectedAction.type) {
              case ActionType.transform:
                return <EventTransformInspector action={selectedAction} />;
              case ActionType.email:
                return <SendEmailActionInspector action={selectedAction} />;
              case ActionType.httpRequest:
                return <HTTPRequestInspector action={selectedAction} />;
              case ActionType.webhook:
                return <WebhookInspector />;
              case ActionType.trigger:
                return <TriggerActionInspector action={selectedAction} />;
              case ActionType.form:
                return <FormActionInspector />;
              default:
                return <DefaultInspector action={selectedAction} />;
            }
          })()}
          {selectedAction.description && (
            <div>
              <Spacing size=".75em" />
              <WithCustomInlineFormatting
                as="div"
                children={selectedAction.description}
              />
            </div>
          )}
        </StoryboardActionInspectorInner>
      ) : (
        p.emptyState ?? (
          <EmptyState>
            {p.withIcon && (
              <svg width="47" height="28" viewBox="0 0 47 28" fill="none">
                <rect
                  x="0.5"
                  y="0.5"
                  width="46"
                  height="19"
                  rx="5.5"
                  fill="#F3ECF7"
                  stroke="#EADFF8"
                />
                <rect
                  x="3"
                  y="3"
                  width="14"
                  height="14"
                  rx="3"
                  fill="#D7C4FA"
                />
                <rect
                  x="19"
                  y="4"
                  width="13"
                  height="4"
                  rx="1"
                  fill="#D7C4FA"
                />
                <rect
                  x="19"
                  y="10"
                  width="21"
                  height="6"
                  rx="1"
                  fill="#D7C4FA"
                />
                <path
                  d="M36 14.5675V23.757C36 24.2331 36.6024 24.4398 36.8947 24.064L38.0129 22.6262C38.2388 22.3359 38.6903 22.3806 38.8548 22.7096L40.7808 26.5617C40.9026 26.8052 41.1968 26.9065 41.4426 26.7897L43.2178 25.9462C43.481 25.8211 43.5819 25.4984 43.4368 25.2457L41.4298 21.7489C41.2385 21.4156 41.4791 21 41.8635 21H43.619C44.0866 21 44.2983 20.4153 43.9391 20.1159L36.8201 14.1834C36.4944 13.912 36 14.1436 36 14.5675Z"
                  fill="#EADFF8"
                  stroke="#A88FEF"
                />
              </svg>
            )}
            <h3>Select an action to inspect.</h3>
            {p.copiable && (
              <p>
                You can also click "Copy action
                {context.story.actions.length > 1 ? "s" : ""}" and paste them in
                your Tines story to see how they work.
              </p>
            )}
          </EmptyState>
        )
      )}
    </StoryboardActionInspectorWrap>
  );
};

// const DefaultInspectorContent = styled.div`
//   word-wrap: break-word;
//   font-family: ${font("monospace")};
// `;

const SimpleOptionFieldDisplay = styled.div`
  h4 {
    font-size: 1.2rem;
    opacity: 0.8;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
    ${fromTablet} {
      font-weight: 600;
    }
  }
  + * {
    margin-top: 0.5em;
  }
`;

export const SimpleOptionFieldValue = styled.div`
  background-color: ${withOpacity(colors.light100, 0.75)};
  ${darkModeLinariaCSS(`
    background-color: ${withOpacity(colorsV4.warmBlack900, 0.75)};
  `)}
  font-size: 1.2rem;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  ${fromTablet} {
    padding: 0.75em 1em;
  }
  overflow-wrap: anywhere;
  &.mono {
    font-family: ${font("monospace")};
  }
`;

const DefaultInspector = (props: { action: Action }) => {
  return (
    <>
      <EmptyState>
        Inspect this action by copying and pasting the sample actions to your
        Tines story.
      </EmptyState>
      {/* {isDevelopment && (
        <DefaultInspectorContent>
          {JSON.stringify(props.action.options)}
        </DefaultInspectorContent>
      )} */}
    </>
  );
};

const FormActionInspector = () => {
  return (
    <EmptyState>
      Inspect this Page by copying and pasting the sample Actions to your Tines
      story.
    </EmptyState>
  );
};

const EventTransformInspector = (props: { action: Action }) => {
  const { options } = props.action as EventTransformAction;
  return (
    <>
      {options.mode !== "message_only" && (
        <SimpleOptionFieldDisplay>
          <h4>Mode</h4>
          <SimpleOptionFieldValue>{options.mode}</SimpleOptionFieldValue>
        </SimpleOptionFieldDisplay>
      )}
      {options.loop === true && (
        <SimpleOptionFieldDisplay>
          <h4>Loop</h4>
          <SimpleOptionFieldValue className="mono">{`${options.loop}`}</SimpleOptionFieldValue>
        </SimpleOptionFieldDisplay>
      )}
      {options.path && (
        <SimpleOptionFieldDisplay>
          <h4>Path</h4>
          <FormattedJSONBlock source={options.path} appearance="nocode" />
        </SimpleOptionFieldDisplay>
      )}
      {options.to && (
        <SimpleOptionFieldDisplay>
          <h4>To</h4>
          <FormattedJSONBlock source={options.to} appearance="nocode" />
        </SimpleOptionFieldDisplay>
      )}
      {options.payload && (
        <SimpleOptionFieldDisplay>
          <JSONBlockScrollable>
            <FormattedJSONBlock source={options.payload} appearance="nocode" />
          </JSONBlockScrollable>
        </SimpleOptionFieldDisplay>
      )}
    </>
  );
};

const WebhookInspector = () => {
  return (
    <>
      <InfoBox>
        The Webhook action will emit events it receives through Webhooks (HTTP
        callbacks).
      </InfoBox>
      <Spacing />
      <SimpleOptionFieldDisplay>
        <h4>Webhook URL</h4>
        <SimpleOptionFieldValue>***</SimpleOptionFieldValue>
      </SimpleOptionFieldDisplay>
    </>
  );
};

const HTTPRequestInspector = (props: { action: Action }) => {
  const { options } = props.action as HTTPRequestAction;
  return (
    <>
      <SimpleOptionFieldDisplay>
        <h4>URL</h4>
        <FormattedJSONBlock source={options.url} appearance="nocode" />
      </SimpleOptionFieldDisplay>
      <SimpleOptionFieldDisplay>
        <h4>Method</h4>
        <SimpleOptionFieldValue>{options.method}</SimpleOptionFieldValue>
      </SimpleOptionFieldDisplay>
      {options.payload && (
        <SimpleOptionFieldDisplay>
          <h4>Payload</h4>
          <JSONBlockScrollable>
            <FormattedJSONBlock source={options.payload} appearance="nocode" />
          </JSONBlockScrollable>
        </SimpleOptionFieldDisplay>
      )}
    </>
  );
};

const TriggerActionInspector = (props: { action: Action }) => {
  const { options } = props.action as TriggerAction;
  return (
    <>
      <h4>Rules</h4>
      <Spacing />
      {options.rules.map((r, i) => (
        <React.Fragment key={i}>
          <SimpleOptionFieldDisplay>
            <h4>Type</h4>
            <SimpleOptionFieldValue className="mono">
              {r.type}
            </SimpleOptionFieldValue>
          </SimpleOptionFieldDisplay>
          <SimpleOptionFieldDisplay>
            <h4>Path</h4>
            <FormattedJSONBlock source={r.path} appearance="nocode" />
          </SimpleOptionFieldDisplay>
          <SimpleOptionFieldDisplay>
            <h4>Value</h4>
            <FormattedJSONBlock source={r.value} appearance="nocode" />
          </SimpleOptionFieldDisplay>
        </React.Fragment>
      ))}
    </>
  );
};

const SendEmailActionInspector = (props: { action: Action }) => {
  const { options } = props.action as SendEmailAction;
  return (
    <>
      {options.recipients.join(",") !== "recipients@email.com" && (
        <SimpleOptionFieldDisplay>
          <h4>Recipients</h4>
          <SimpleOptionFieldValue>
            {options.recipients.join(", ")}
          </SimpleOptionFieldValue>
        </SimpleOptionFieldDisplay>
      )}
      {options.reply_to !== "replyto@email.com" && (
        <SimpleOptionFieldDisplay>
          <h4>Reply to</h4>
          <SimpleOptionFieldValue>{options.reply_to}</SimpleOptionFieldValue>
        </SimpleOptionFieldDisplay>
      )}
      {options.sender_name !== "Sender Name" && (
        <SimpleOptionFieldDisplay>
          <h4>Sender name</h4>
          <SimpleOptionFieldValue>{options.sender_name}</SimpleOptionFieldValue>
        </SimpleOptionFieldDisplay>
      )}
      <SimpleOptionFieldDisplay>
        <h4>Subject</h4>
        <SimpleOptionFieldValue>{options.subject}</SimpleOptionFieldValue>
      </SimpleOptionFieldDisplay>

      <SimpleOptionFieldDisplay>
        <h4>Body</h4>
        <FormattedJSONBlock source={options.body} appearance="nocode" />
      </SimpleOptionFieldDisplay>
    </>
  );
};

export default StoryboardActionInspector;
