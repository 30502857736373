import { css } from "linaria";
import { PropsWithChildren, createElement } from "react";

type Props = {
  as?: "h2" | "p";
};

const SolutionPageAllcapsHeadingParagraph = css`
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.075em;
`;

const SolutionPageAllcapsHeading = (props: PropsWithChildren<Props>) => {
  return createElement(props.as ?? "h2", {
    className: SolutionPageAllcapsHeadingParagraph,
    children: <strong>{props.children}</strong>,
  });
};

export default SolutionPageAllcapsHeading;
