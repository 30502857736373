export const LoadedSkateboardIcon = () => (
  <svg
    width="78"
    height="53"
    viewBox="0 0 78 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.3674 40.3567H21.6281L4.92188 51.7561H60.6613L77.3674 40.3567Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.3921 34.9607C37.3921 31.5853 34.6556 28.8488 31.2799 28.8488C30.3048 28.8488 29.3832 29.0777 28.5655 29.4845C28.1191 29.7064 25.2522 31.0912 24.4685 31.8391C23.3036 32.9515 22.5771 34.52 22.5771 36.2581C22.5771 39.6337 25.3138 42.3701 28.6889 42.3701C29.587 42.3701 30.4392 42.1758 31.2073 41.8278C31.7285 41.5916 34.5305 40.2275 35.2731 39.5866C36.5708 38.4661 37.3921 36.8096 37.3921 34.9607Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.8005 36.2581C34.8005 39.6337 32.064 42.3701 28.6884 42.3701C25.3133 42.3701 22.5767 39.6337 22.5767 36.2581C22.5767 32.8827 25.3133 30.1464 28.6884 30.1464C32.0642 30.1465 34.8005 32.8827 34.8005 36.2581Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.689 38.3304C29.8335 38.3304 30.7613 37.4026 30.7613 36.2581C30.7613 35.1136 29.8335 34.1858 28.689 34.1858C27.5445 34.1858 26.6167 35.1136 26.6167 36.2581C26.6167 37.4026 27.5445 38.3304 28.689 38.3304Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M74.0576 34.9607C74.0576 31.5853 71.3209 28.8488 67.9456 28.8488C66.9703 28.8488 66.0486 29.0777 65.2307 29.4845C64.7848 29.7064 61.9177 31.0912 61.1338 31.8391C59.9686 32.9515 59.2427 34.52 59.2427 36.2581C59.2427 39.6337 61.9792 42.3701 65.3545 42.3701C66.2527 42.3701 67.1046 42.1758 67.8726 41.8278C68.3938 41.5916 71.1958 40.2275 71.9386 39.5866C73.2363 38.4661 74.0576 36.8096 74.0576 34.9607Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M65.3545 42.37C68.73 42.37 71.4664 39.6336 71.4664 36.2581C71.4664 32.8826 68.73 30.1462 65.3545 30.1462C61.979 30.1462 59.2427 32.8826 59.2427 36.2581C59.2427 39.6336 61.979 42.37 65.3545 42.37Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M65.3545 38.3304C66.499 38.3304 67.4268 37.4026 67.4268 36.2581C67.4268 35.1136 66.499 34.1858 65.3545 34.1858C64.21 34.1858 63.2822 35.1136 63.2822 36.2581C63.2822 37.4026 64.21 38.3304 65.3545 38.3304Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.4656 44.3436C21.4656 40.9683 18.7291 38.2318 15.3536 38.2318C14.3784 38.2318 13.4566 38.4608 12.6391 38.8675C12.1928 39.0895 9.32577 40.4743 8.54221 41.2222C7.377 42.3346 6.65088 43.9029 6.65088 45.6413C6.65088 49.0167 9.38724 51.7533 12.7626 51.7533C13.6609 51.7533 14.5128 51.5589 15.281 51.2107C15.802 50.9745 18.6041 49.6106 19.3467 48.9697C20.6443 47.8492 21.4656 46.1926 21.4656 44.3436Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12.7627 51.7533C16.1382 51.7533 18.8746 49.0169 18.8746 45.6414C18.8746 42.2659 16.1382 39.5295 12.7627 39.5295C9.38725 39.5295 6.65088 42.2659 6.65088 45.6414C6.65088 49.0169 9.38725 51.7533 12.7627 51.7533Z"
      fill="#FDB2D2"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12.7627 47.7136C13.9072 47.7136 14.835 46.7858 14.835 45.6413C14.835 44.4968 13.9072 43.569 12.7627 43.569C11.6182 43.569 10.6904 44.4968 10.6904 45.6413C10.6904 46.7858 11.6182 47.7136 12.7627 47.7136Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.1312 44.3436C58.1312 40.9683 55.3948 38.2318 52.0193 38.2318C51.0439 38.2318 50.1223 38.4608 49.3046 38.8675C48.8584 39.0895 45.9914 40.4743 45.2074 41.2222C44.0425 42.3346 43.3164 43.9029 43.3164 45.6413C43.3164 49.0167 46.0528 51.7533 49.4281 51.7533C50.3263 51.7533 51.1785 51.5589 51.9464 51.2107C52.4675 50.9745 55.2696 49.6106 56.0122 48.9697C57.3102 47.8492 58.1312 46.1926 58.1312 44.3436Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.4283 51.7533C52.8038 51.7533 55.5401 49.0169 55.5401 45.6414C55.5401 42.2659 52.8038 39.5295 49.4283 39.5295C46.0528 39.5295 43.3164 42.2659 43.3164 45.6414C43.3164 49.0169 46.0528 51.7533 49.4283 51.7533Z"
      fill="#FDB2D2"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.4283 47.7136C50.5728 47.7136 51.5006 46.7858 51.5006 45.6413C51.5006 44.4968 50.5728 43.569 49.4283 43.569C48.2838 43.569 47.356 44.4968 47.356 45.6413C47.356 46.7858 48.2838 47.7136 49.4283 47.7136Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.9082 27.9302H17.854L1.92773 37.3131V40.5482H59.9821L75.9082 31.1653V27.9302Z"
      fill="#8173E6"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.9082 27.9302H17.854L1.92773 37.3131H59.9821L75.9082 27.9302Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.9824 40.5483V37.3132"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.9476 44.014L21.3754 43.2908L20.1872 40.6046H16.4165L18.9476 44.014Z"
      fill="#32274B"
    />
    <path
      d="M55.7387 44.014L58.1669 43.2908L56.9784 40.6046H53.2075L55.7387 44.014Z"
      fill="#32274B"
    />
    <path
      d="M71.6116 35.8573L73.9574 34.5609L73.5871 32.524L71.3032 34.0055L71.6116 35.8573Z"
      fill="#32274B"
    />
    <path
      d="M65.7372 30.5861H21.6772V33.8177H60.4098L65.7372 30.5861Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.1518 16.2666H19.1314C18.6255 16.2666 18.2055 16.5493 17.8042 16.7909C17.2452 17.1271 14.1153 18.7817 13.7796 19.1325C13.4625 19.464 13.2671 19.9133 13.2671 20.4085V31.9722C13.2671 32.9915 14.0933 33.8177 15.1123 33.8177H26.1329C26.5152 33.8177 26.8701 33.7017 27.1646 33.5028C27.6556 33.1711 30.4243 31.6634 31.0431 31.2922C31.6004 30.958 31.9973 30.372 31.9973 29.676V18.1121C31.9973 17.0928 31.1711 16.2666 30.1518 16.2666Z"
      fill="#B35980"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.9785 31.9722C27.9785 32.9915 27.1521 33.8178 26.1329 33.8178H15.1123C14.0933 33.8178 13.2671 32.9915 13.2671 31.9722V20.4085C13.2671 19.3894 14.0933 18.5632 15.1123 18.5632H26.1329C27.1521 18.5632 27.9785 19.3896 27.9785 20.4085V31.9722Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M26.3535 18.5766L30.4933 16.2985"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.979 20.4085L31.9977 18.112"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.8505 16.2666H33.8302C33.3241 16.2666 32.904 16.5493 32.5029 16.7909C31.9438 17.1271 28.8141 18.7817 28.4782 19.1325C28.1607 19.464 27.9658 19.9133 27.9658 20.4085V31.9722C27.9658 32.9915 28.7919 33.8177 29.8112 33.8177H40.8317C41.2139 33.8177 41.5687 33.7017 41.8635 33.5028C42.3542 33.1711 45.1229 31.6634 45.7418 31.2922C46.2993 30.958 46.6957 30.372 46.6957 29.676V18.1121C46.6957 17.0928 45.8696 16.2666 44.8505 16.2666Z"
      fill="#B36847"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.6771 31.9722C42.6771 32.9915 41.8508 33.8178 40.8317 33.8178H29.8112C28.7919 33.8178 27.9658 32.9915 27.9658 31.9722V20.4085C27.9658 19.3894 28.7919 18.5632 29.8112 18.5632H40.8317C41.8508 18.5632 42.6771 19.3896 42.6771 20.4085V31.9722Z"
      fill="#F0946C"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.0513 18.5766L45.1911 16.2985"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.6772 20.4085L46.6959 18.112"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.5481 16.2666H48.5276C48.0218 16.2666 47.6018 16.5493 47.2005 16.7909C46.6412 17.1271 43.5114 18.7817 43.1754 19.1325C42.8584 19.464 42.6631 19.9133 42.6631 20.4085V31.9722C42.6631 32.9915 43.4893 33.8177 44.5083 33.8177H55.5291C55.9113 33.8177 56.2661 33.7017 56.5609 33.5028C57.0519 33.1711 59.8203 31.6634 60.4392 31.2922C60.9967 30.958 61.3933 30.372 61.3933 29.676V18.1121C61.3934 17.0928 60.5674 16.2666 59.5481 16.2666Z"
      fill="#2A7857"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.3741 31.9722C57.3741 32.9915 56.5479 33.8178 55.5286 33.8178H44.5078C43.4888 33.8178 42.6626 32.9915 42.6626 31.9722V20.4085C42.6626 19.3894 43.4888 18.5632 44.5078 18.5632H55.5286C56.5479 18.5632 57.3741 19.3896 57.3741 20.4085V31.9722Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M55.749 18.5766L59.8891 16.2985"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.3745 20.4085L61.3932 18.112"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.1929 1.02502H27.1725C26.6665 1.02502 26.2466 1.30775 25.8452 1.54929C25.2862 1.88572 22.1563 3.54013 21.8204 3.89103C21.5031 4.22254 21.3081 4.67191 21.3081 5.16709V16.7315C21.3081 17.7508 22.1343 18.5769 23.1536 18.5769H34.174C34.5562 18.5769 34.9111 18.4607 35.2059 18.262C35.6966 17.9303 38.4654 16.4224 39.0842 16.0514C39.6415 15.7172 40.0383 15.1311 40.0383 14.4351V2.8707C40.0383 1.85126 39.2121 1.02502 38.1929 1.02502Z"
      fill="#D67C56"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.0195 16.7314C36.0195 17.7507 35.1933 18.5768 34.174 18.5768H23.1536C22.1343 18.5768 21.3081 17.7507 21.3081 16.7314V5.16697C21.3081 4.14783 22.1343 3.32159 23.1536 3.32159H34.174C35.1933 3.32159 36.0195 4.14783 36.0195 5.16697V16.7314Z"
      fill="#FABE64"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.3945 3.33502L38.5345 1.05713"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.0195 5.16696L40.0384 2.87042"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.9594 1.02502H41.9386C41.4327 1.02502 41.0127 1.30775 40.6112 1.54929C40.0523 1.88572 36.9225 3.54013 36.5866 3.89103C36.2692 4.22254 36.0742 4.67191 36.0742 5.16709V16.7315C36.0742 17.7508 36.9006 18.5769 37.9197 18.5769H48.9404C49.3226 18.5769 49.6772 18.4607 49.9722 18.262C50.4629 17.9303 53.2315 16.4224 53.8506 16.0514C54.4077 15.7172 54.8043 15.1311 54.8043 14.4351V2.8707C54.8044 1.85126 53.9783 1.02502 52.9594 1.02502Z"
      fill="#8173E6"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.7856 16.7314C50.7856 17.7507 49.9597 18.5768 48.9404 18.5768H37.9198C36.9006 18.5768 36.0742 17.7507 36.0742 16.7314V5.16697C36.0742 4.14783 36.9006 3.32159 37.9198 3.32159H48.9404C49.9597 3.32159 50.7856 4.14783 50.7856 5.16697V16.7314Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.1606 3.33502L53.3005 1.05713"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.7861 5.16696L54.8048 2.87042"
      stroke="#32274B"
      strokeWidth="0.657778"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
