import { styled } from "@linaria/react";
import { cx } from "linaria";
import { getLuminance } from "polished";
import React from "react";
import { brandColorThemeVar, colors } from "../../styles/colors.styles";
import { UseStateReturnType } from "../../types/helper.types";
import {
  addOneToArrayIfNew,
  removeOneFromArray,
} from "../../utils/array.utils";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import {
  SelectOptionConfig,
  SelectProps,
  prepareSelectOptions,
} from "./Select";

type MultiselectWrapperProps = {
  borderRadius?: string | number;
  padding?: string | number;
  border?: string;
};

export const MultiselectWrapper = styled.span<MultiselectWrapperProps>`
  position: relative;
  display: block;
  text-align: left;
  border-radius: ${p => valueWithOptionalUnit(p.borderRadius, `.5em`)};
  padding: ${p => valueWithOptionalUnit(p.padding, ".75em")};
  border: ${p => p.border ?? `1px solid ${brandColorThemeVar(200)}`};
`;

const CheckboxEntry = styled.div<{ color?: string | null }>`
  padding: 0.25em;
  font-weight: 500;
  position: relative;
  --color: ${p => p.color ?? brandColorThemeVar(500)};
  --contrastColor: ${p =>
    getLuminance(p.color ?? colors.purple) > 0.5
      ? colors.dark500
      : colors.white};
  svg {
    vertical-align: middle;
    width: 1em;
    height: 1em;
  }
  input {
    position: absolute;
    opacity: 0;
  }
  label {
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: auto minmax(0, 1fr);
  }
  rect {
    fill: ${brandColorThemeVar(100)};
    ${darkModeLinariaCSS(`
      stroke: ${colors.dark500};
      fill: ${colors.darkest};
    `)}
  }
  &:hover rect {
    fill: var(--color);
    opacity: 0.5;
  }
  &.checked rect {
    fill: var(--color);
  }
  &.checked:hover rect {
    opacity: 0.8;
  }
  + * {
    margin-top: 0.375em;
  }
`;

function Multiselect<T extends object>(
  props: SelectProps<T> & {
    formState: UseStateReturnType<T>;
    name: keyof T & string;
    onValueChange?: (newValue?: string[], prevValue?: string[]) => void;
  }
) {
  const {
    color,
    border,
    borderRadius,
    className,
    formState,
    name,
    hasError,
    options,
    padding,
    onValueChange,
    innerRef,
    ...restOfProps
  } = props;

  const value = formState[0][
    props.name as keyof typeof formState[0]
  ] as unknown as string[];

  const handleValueChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const newValue = target.checked
      ? addOneToArrayIfNew(value, target.value)
      : removeOneFromArray(value, target.value);
    formState[1]({
      ...formState[0],
      [props.name]: newValue,
    });
    onValueChange?.(newValue);
  };

  const mapOptions = (options: string[] | SelectOptionConfig[]) =>
    prepareSelectOptions(options).map((option, i) => {
      const id = `${name}-${option.value}`;
      const checked = value.includes(option.value);
      return (
        <CheckboxEntry
          key={id}
          color={color}
          className={cx(checked && "checked")}
        >
          <input
            type="checkbox"
            id={id}
            onChange={handleValueChange}
            value={option.value}
            checked={checked}
            key={`${option.value}-${i}`}
            disabled={option.disabled}
          />
          <label htmlFor={id}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
              <rect width="18" height="18" rx="4" />
              {checked && (
                <path
                  d="M3.5 8L7.5 12L14.5 5"
                  stroke="#F7F4F0"
                  strokeWidth="2"
                />
              )}
            </svg>

            <span>{option.label}</span>
          </label>
        </CheckboxEntry>
      );
    });

  const optionGroupsMapped = props.optgroups?.map((group, i) => (
    <div key={`${group.label}-${i}`}>
      <h4>{group.label}</h4>
      {mapOptions(group.options)}
    </div>
  ));

  return (
    <MultiselectWrapper
      ref={innerRef}
      padding={padding}
      border={border}
      borderRadius={borderRadius}
      className={cx("Multiselect", className, hasError && "hasError")}
      {...restOfProps}
    >
      {options && mapOptions(options)}
      {optionGroupsMapped}
    </MultiselectWrapper>
  );
}

export default Multiselect;
