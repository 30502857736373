import { Link, graphql } from "gatsby";
import SEO from "../components/utilities/SEO";
import { colors, withOpacity } from "../styles/colors.styles";
import { PageComponent } from "../types/gatsby.types";
import Page from "./Page.template";
import {
  DatoCmsActionTemplate,
  DatoCmsArticle,
  DatoCmsCaseStudy,
  DatoCmsDocsPage,
  DatoCmsIntercomArticle,
  DatoCmsLibraryTool,
  DatoCmsProduct,
  DatoCmsProductFamily,
  DatoCmsStoryDigest,
  SolutionByProductPageQuery,
} from "../../graphql-types";
import { externalLinkAttr } from "../constants/externalLinks.constants";
import Button, {
  ButtonGroupCenteredUptoTabletMd,
} from "../components/forms/Button";
import BookADemoButton from "../components/utilities/BookADemoButton";
import SignUpButton from "../components/utilities/SignUpButton";
import PageSection from "../components/reusableSections/PageSection";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import Spacing from "../components/layout/Spacing";
import { ExternalLinkIcon } from "../components/basic/ExternalLinkIcon";
import { styled } from "@linaria/react";
import { serif } from "../styles/fonts.styles";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopSm,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  uptoTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import { ProductExplorerPageSectionHeader } from "../components/productExplorer/ProductExplorerPageSectionHeader";
import { StoryGrid } from "../components/library/StoryGrid";
import { BlocksOnShelvesIcon } from "../components/illustrations/spots/BlocksOnShelvesIcon";
import ActionTemplateBrowser from "../components/productExplorer/ActionTemplateBrowser";
import { css, cx } from "linaria";
import { BuildYourOwnConnectionsSection } from "../components/productExplorer/BuildYourOwnConnectionsSection";
import { SolutionByProductPageCustomerLogoSection } from "../components/productExplorer/SolutionByProductPageCustomerLogoSection";
import StoryEmbed from "../components/blocks/StoryEmbed.block";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { ProductExplorerPageHeaderGraphics } from "../components/productExplorer/ProductExplorerPageHeaderGraphics";
import {
  CompassIcon,
  LightbulbOnBookIcon,
} from "../components/productExplorer/ProductExplorerPageIcons";
import ContentCardArticle from "../components/contentCards/ContentCardArticle";
import { ContentCardCaseStudy } from "../components/contentCards/ContentCardCaseStudy";
import ContentCardDocs from "../components/contentCards/ContentCardDocs";
import ContentCardCaseStudies from "../components/contentCards/ContentCardCaseStudies";
import ContentCardBlog from "../components/contentCards/ContentCardBlog";
import ProductExplorerPseudoSearchButton from "../components/productExplorer/ProductExplorerPseudoSearchButton";
import ContentCardUniversitySimple from "../components/contentCards/ContentCardUniversitySimple";
import { ContentCardLibrary } from "../components/contentCards/ContentCardLibrary";
import ProductExplorerEntry, {
  ProductExplorerEntryGrid,
} from "../components/productExplorer/ProductExplorerEntry";
import { PurpleMapIcon } from "../components/productExplorer/icons/PurpleMapIcon";
import { PurpleTinesKeyIcon } from "../components/productExplorer/icons/PurpleTinesKeyIcon";
import fallbackOgImage from "../../static/images/og/tines-solutions-by-product.png";
import { convertDatoRecordToStoryDescriptor } from "../utils/story.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import { reportIntent } from "../utils/intentReporter.utils";

const HeaderSectionInner = styled.div`
  display: grid;
  ${uptoTabletMd} {
    text-align: center;
    grid-template-rows: auto auto;
    grid-template-areas: "icons" "text";
    grid-gap: 2em;
  }
  ${fromTabletMd} {
    grid-template-columns: minmax(0, 2fr) minmax(auto, 1fr);
    grid-template-areas: "text icons";
    grid-gap: ${rSize("gap")};
  }
`;
const HeaderSectionTextContent = styled.div`
  grid-area: text;
  > div:first-child {
    a {
      text-decoration: none;
    }
  }
  > * + * {
    margin-top: ${rSize("gap")};
  }
`;
const PageHeading = styled.h1`
  font-family: ${serif};
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: -0.015em;
  ${fromTabletMd} {
    font-size: 6.4rem;
    font-weight: 300;
    line-height: 1;
  }
  ${fromDesktop} {
    font-size: 7.2rem;
    line-height: 0.98;
    letter-spacing: -0.0175em;
  }
`;
const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.3;
  max-width: 30em;
  margin-left: auto;
  margin-right: auto;
  ${fromTablet} {
    font-family: ${serif};
    font-size: 2rem;
  }
  ${fromTabletMd} {
    margin-left: 0;
  }
  a {
    text-decoration: none;
    background-image: linear-gradient(
      to top,
      transparent 0,
      transparent 0.1em,
      ${withOpacity(colors.purple800, 0.5)} 0.1em,
      ${withOpacity(colors.purple800, 0.5)} calc(0.1em + 1px),
      transparent calc(0.1em + 1px),
      transparent 100%
    );
    &:hover {
      color: ${colors.purple600};
      background-image: linear-gradient(
        to top,
        transparent 0,
        transparent 0.1em,
        ${withOpacity(colors.purple600, 0.7)} 0.1em,
        ${withOpacity(colors.purple600, 0.7)} calc(0.1em + 1px),
        transparent calc(0.1em + 1px),
        transparent 100%
      );
    }
  }
`;

const HeaderSectionLogoSetWrap = styled.div`
  grid-area: icons;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fromTabletMd} {
    min-height: 28rem;
  }
`;

const HeaderSectionProductLogoBubble = styled.div`
  width: 9rem;
  ${uptoTabletMd} {
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTabletMd} {
    width: 16rem;
  }
  ${fromDesktop} {
    width: 18rem;
  }
  ${fromDesktopMd} {
    width: 22rem;
  }
  aspect-ratio: 1;
  max-width: 66%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple200};
  border-radius: 50%;
  z-index: 2;
  img {
    width: 5rem;
    ${fromTabletMd} {
      width: 7rem;
    }
    ${fromDesktop} {
      width: 8rem;
    }
    height: auto;
  }
`;
const ConnectInfoPageLinkGrid = styled.div`
  display: grid;
  ${fromTablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-gap: ${rSize("gap")};
`;
const ConnectInfoPageLink = css`
  display: grid;
  grid-gap: 2em;
  grid-template-rows: minmax(0, 1fr) auto;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple200};
  border-radius: ${rSize("radius")};
  padding: 2.4rem;
  text-decoration: none;
  position: relative;
  &:hover {
    border-color: ${colors.purple400};
  }
  ${fromTabletLg} {
    min-height: 26rem;
  }
  h3 {
    font-size: 1.8rem;
    ${fromTablet} {
      font-size: 2.2rem;
    }
    span {
      display: block;
    }
    + * {
      margin-top: 0.5em;
    }
  }
  svg {
    max-height: 9.2rem;
    width: auto;
  }
  p {
    font-size: 1.3rem;
    ${fromTablet} {
      font-size: 1.4rem;
    }
  }
`;

const ConnectInfoPageLinkExternalLinkIconWrap = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  color: ${colors.purple400};
`;

const RelatedContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopSm} {
    grid-template-columns: repeat(4, 1fr);
  }
  min-height: 22.5rem;
  ${fromPhoneLg} {
    min-height: 30rem;
  }
  ${fromDesktopSm} {
    min-height: 36rem;
  }
`;

const ProductFamilyIconBubble = styled.div`
  width: 9rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple200};
  border-radius: 50%;
  z-index: 2;
  img {
    width: 4.2rem;
    height: auto;
  }
`;

const getDescriptionWithWebsiteLink = (p: DatoCmsProduct) => {
  const regex = new RegExp(`\\b${p.name}\\b`);
  return p.website
    ? (p.description ?? "").replace(
        regex,
        `<a href="${p.website}" target="_blank" rel="noreferrer noopener">${p.name}</a>`
      )
    : p.description ?? "";
};

const SolutionByProductPageTemplate: PageComponent<{
  data: SolutionByProductPageQuery;
  pageContext: {
    slug: string;
    toolRegex: string;
    authGuideRegex: string;
  };
}> = props => {
  const product = props.data.product as DatoCmsProduct;
  product.name = product.name?.trim();
  const tool = props.data.tool as DatoCmsLibraryTool;
  const family = props.data.family as DatoCmsProductFamily;
  const stories = ((product.firstSixStories ?? []) as DatoCmsStoryDigest[])
    .map(convertDatoRecordToStoryDescriptor)
    .filter(s => !s.isPrivate && !s.isUnlisted);
  const shouldRenderLibrarySection = stories.length > 0;
  const actionTemplates = product.actionTemplates as DatoCmsActionTemplate[];
  const connectFlowPage = (product.connectFlow ??
    props.data.connectFlowPage) as DatoCmsDocsPage;
  const authGuide = props.data.authGuide as DatoCmsIntercomArticle;
  const latestRelatedArticle = props.data.latestRelatedArticles.nodes[0] as
    | DatoCmsArticle
    | undefined;
  const latestRelatedCaseStudy = props.data
    .latestRelatedCaseStudy as DatoCmsCaseStudy | null;
  const caseStudy = props.data.caseStudy as DatoCmsCaseStudy | null;
  const caseStudyToShow = caseStudy ?? latestRelatedCaseStudy;
  const descriptionWithWebsiteLink = getDescriptionWithWebsiteLink(product);
  const productsInTheSameFamily = family
    ? (family.products as DatoCmsProduct[]).filter(p => p.slug !== product.slug)
    : [];
  useOnMount(() => {
    reportIntent(`Viewed product "${product.name}" in product explorer`);
  });
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO
        title={`Tines × ${product.name}`}
        image={product.pagePreviewImage?.url ?? fallbackOgImage}
        description={product.description}
        noindex={product.noindex}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="product"
        recordId={product.id}
      />
      <ProductExplorerPageHeaderGraphics light />

      <PageSection relative>
        <SiteNavHeightPlaceholder />
        <Spacing size="sectionPadding" />
        <HeaderSectionInner>
          <HeaderSectionTextContent>
            <ProductExplorerPseudoSearchButton />
            <PageHeading>{product.name} × Tines</PageHeading>
            <Description
              dangerouslySetInnerHTML={{
                __html: descriptionWithWebsiteLink,
              }}
            />
            <ButtonGroupCenteredUptoTabletMd>
              <BookADemoButton appearance="filled" darker width="8em" />
              <SignUpButton appearance="outlined" darker width="8em" />
            </ButtonGroupCenteredUptoTabletMd>
          </HeaderSectionTextContent>
          <HeaderSectionLogoSetWrap>
            {product.icon?.url && (
              <HeaderSectionProductLogoBubble>
                <img src={product.icon.url} />
              </HeaderSectionProductLogoBubble>
            )}
          </HeaderSectionLogoSetWrap>
        </HeaderSectionInner>
      </PageSection>

      {actionTemplates.length > 0 && (
        <>
          <Spacing size="subsectionMargin" />
          <PageSection>
            <ProductExplorerPageSectionHeader>
              <div>
                <h2>Pre-built templates</h2>
                <p>
                  With Tines, you can easily take any action that has a defined
                  API. We've already pre-built some of the most popular ones for
                  you, so you can build quickly.
                </p>
              </div>
              <div>
                <BlocksOnShelvesIcon />
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            <ActionTemplateBrowser
              actionTemplates={actionTemplates}
              product={product}
            />
          </PageSection>
          <Spacing size="xxl" />
          <BuildYourOwnConnectionsSection />
        </>
      )}

      {shouldRenderLibrarySection && (
        <>
          <Spacing size="subsectionMargin" />
          <PageSection>
            <ProductExplorerPageSectionHeader>
              <div>
                <h2>Full workflow examples</h2>
                <p>
                  Explore pre-built workflows for {product.name}. Use them for
                  inspiration or as a starting point to build your custom
                  automation solution.
                </p>
              </div>
              <div>
                {tool ? (
                  <Button
                    to={`/library/tools/${tool.slug}`}
                    appearance="outlined"
                    darker
                  >
                    Explore all →
                  </Button>
                ) : (
                  <PurpleMapIcon />
                )}
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            {stories.length === 1 ? (
              <StoryEmbed descriptor={stories[0]} fullWidth />
            ) : (
              <StoryGrid stories={stories} />
            )}
          </PageSection>

          {actionTemplates.length === 0 && (
            <>
              <Spacing size="xxl" />
              <BuildYourOwnConnectionsSection />
            </>
          )}
        </>
      )}

      <Spacing size="subsectionMargin" />
      <PageSection>
        <ProductExplorerPageSectionHeader>
          <div>
            <h2>Connect quickly</h2>
            <p>It takes minutes, not months, to connect to tools in Tines.</p>
          </div>
          <div>
            <PurpleTinesKeyIcon />
          </div>
        </ProductExplorerPageSectionHeader>
        <Spacing size="xl" />
        <ConnectInfoPageLinkGrid>
          {connectFlowPage ? (
            <a
              className={ConnectInfoPageLink}
              href={`/docs/credentials/connect-flows/${connectFlowPage.slug}`}
              target="_blank"
              rel="noreferrer"
            >
              <LightbulbOnBookIcon />
              <div>
                <h3>
                  <span>{product.name}</span> <span>connect flow</span>
                </h3>
                <p>
                  Learn how to authenticate {product.name} for use with Tines.
                </p>
              </div>
              <ConnectInfoPageLinkExternalLinkIconWrap>
                <ExternalLinkIcon />
              </ConnectInfoPageLinkExternalLinkIconWrap>
            </a>
          ) : (
            <a
              className={ConnectInfoPageLink}
              href="/docs/credentials"
              target="_blank"
            >
              <LightbulbOnBookIcon />
              <div>
                <h3>
                  <span>Credentials</span> <span>documentation</span>
                </h3>
                <p>Learn how to use credentials in Tines.</p>
              </div>
              <ConnectInfoPageLinkExternalLinkIconWrap>
                <ExternalLinkIcon />
              </ConnectInfoPageLinkExternalLinkIconWrap>
            </a>
          )}

          {authGuide ? (
            <a
              className={ConnectInfoPageLink}
              href={authGuide.url ?? ""}
              {...externalLinkAttr}
            >
              <CompassIcon />
              <div>
                <h3>
                  <span>{product.name}</span> <span>authentication guide</span>
                </h3>
                <p>
                  Learn how to authenticate {product.name} for use with Tines.
                </p>
              </div>
              <ConnectInfoPageLinkExternalLinkIconWrap>
                <ExternalLinkIcon />
              </ConnectInfoPageLinkExternalLinkIconWrap>
            </a>
          ) : (
            <a
              className={ConnectInfoPageLink}
              href="https://explained.tines.com/en/collections/3801629-authentication-guides"
              {...externalLinkAttr}
            >
              <CompassIcon />
              <div>
                <h3>Authentication Guides</h3>
                <p>
                  Learn how to authenticate third-party products for use with
                  Tines.
                </p>
              </div>
              <ConnectInfoPageLinkExternalLinkIconWrap>
                <ExternalLinkIcon />
              </ConnectInfoPageLinkExternalLinkIconWrap>
            </a>
          )}
        </ConnectInfoPageLinkGrid>
      </PageSection>

      {productsInTheSameFamily.length > 0 && (
        <>
          <Spacing size="subsectionMargin" />
          <PageSection relative>
            <ProductExplorerPageSectionHeader
              className={cx(
                "alignCenter",
                css`
                  z-index: 2;
                `
              )}
            >
              <div>
                <h2>More {family.name} products</h2>
                {!family.hidden && (
                  <p>
                    <Link to={`/solutions/products/${family.slug}`}>
                      View all {family.name} products →
                    </Link>
                  </p>
                )}
              </div>
              <div>
                {family.icon?.url && (
                  <ProductFamilyIconBubble>
                    <img src={family.icon.url} />
                  </ProductFamilyIconBubble>
                )}
              </div>
            </ProductExplorerPageSectionHeader>
            <Spacing size="xl" />
            <ProductExplorerEntryGrid>
              {productsInTheSameFamily.map(p => (
                <ProductExplorerEntry product={p} key={p.id} />
              ))}
            </ProductExplorerEntryGrid>
          </PageSection>
        </>
      )}

      <Spacing size="subsectionMargin" />
      <PageSection>
        <ProductExplorerPageSectionHeader>
          <div>
            <h2>Explore more resources</h2>
            <p>
              Check out our blogs
              {latestRelatedArticle ? ` that mention ${product.name}` : ""},
              browse our learning paths, and more.
            </p>
          </div>
        </ProductExplorerPageSectionHeader>
        <Spacing size="xl" />
        <RelatedContentGrid>
          {latestRelatedArticle ? (
            <ContentCardArticle
              article={latestRelatedArticle}
              displayDefaultBlogIcon
            />
          ) : (
            <ContentCardBlog overrides={{ color: "orange" }} />
          )}
          {caseStudyToShow ? (
            <ContentCardCaseStudy caseStudy={caseStudyToShow} />
          ) : (
            <ContentCardCaseStudies />
          )}
          <ContentCardUniversitySimple overrides={{ color: "pink" }} />
          {shouldRenderLibrarySection ? (
            <ContentCardDocs />
          ) : (
            <ContentCardLibrary overrides={{ colorMode: "light" }} />
          )}
        </RelatedContentGrid>
      </PageSection>

      <Spacing size="subsectionMargin" />
      <SolutionByProductPageCustomerLogoSection />

      <Spacing size="subsectionMargin" />
      <ExplosionCTASection />
    </Page>
  );
};

export const solutionByProductPageQuery = graphql`
  query SolutionByProductPage(
    $slug: String
    $toolRegex: String
    $authGuideRegex: String
  ) {
    product: datoCmsProduct(slug: { eq: $slug }) {
      id: originalId
      name
      slug
      description
      website
      noindex
      icon {
        url
      }
      pagePreviewImage {
        url
      }
      connectFlow {
        slug
      }
      actionTemplates {
        name
        key
      }
      firstSixStories {
        id: originalId
        storyId
        descriptor
        internalAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        communityAuthors {
          name
          surname
          linkedin
          organization {
            name
          }
        }
        videoIntroEmbedUrl
        gallery {
          url
          width
          height
        }
      }
    }
    tool: datoCmsLibraryTool(name: { regex: $toolRegex }) {
      id: originalId
      slug
    }
    family: datoCmsProductFamily(
      products: { elemMatch: { slug: { eq: $slug } } }
    ) {
      id: originalId
      name
      slug
      description
      website
      hidden
      icon {
        url
      }
      products {
        id: originalId
        name
        slug
        icon {
          url
        }
      }
    }
    connectFlowPage: datoCmsDocsPage(
      slug: { eq: $slug }
      # cspell:disable
      treeParent: { originalId: { eq: "Gm0wbeOeSASU3cIHmY2buA" } } # cspell:enable
    ) {
      id: originalId
      slug
    }
    authGuide: datoCmsIntercomArticle(url: { regex: $authGuideRegex }) {
      id: originalId
      title
      url
    }
    latestRelatedArticles: allDatoCmsArticle(
      filter: {
        tags: { elemMatch: { slug: { eq: $slug } } }
        categories: { elemMatch: { slug: { in: ["news", "tutorials"] } } }
      }
      sort: { datePublished: DESC }
      limit: 1
    ) {
      nodes {
        id: originalId
        slug
        title
      }
    }
    latestRelatedCaseStudy: datoCmsCaseStudy(
      tags: { elemMatch: { slug: { eq: $slug } } }
    ) {
      id: originalId
      color
      slug
      title
      customer {
        logoWhite {
          url
          width
          height
        }
      }
    }
    caseStudy: datoCmsCaseStudy(customer: { slug: { eq: $slug } }) {
      id: originalId
      color
      slug
      title
      customer {
        logoWhite {
          url
          width
          height
        }
      }
    }
  }
`;

export default SolutionByProductPageTemplate;
