import dayjs from "dayjs";
import { getHubSpotToken } from "../components/gdpr/thirdPartyServices/hubspot";
import { readGCLID } from "./gclid.utils";
import { readMarketingAttribution } from "./marketingAttribution.utils";
import { isBuildTime } from "../environment";

export const getFormMetaInfo = () => ({
  time: dayjs().format(),
  pageUrl: isBuildTime ? "" : window.location.href,
  pageName: isBuildTime ? "" : document.title,
  gclid: readGCLID(),
  hubSpotToken: getHubSpotToken(),
  marketingAttribution: readMarketingAttribution(),
});
