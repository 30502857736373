export const RadarSetAndMailbox = () => (
  <svg
    width="109"
    height="166"
    viewBox="0 0 109 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_340_12558)">
      <circle cx="46" cy="166" r="8.5" fill="#25A871" stroke="#195642" />
      <path
        d="M54 125.5H69.8563C71.6611 125.5 73.351 126.385 74.3783 127.869L79.4552 135.203C80.7867 137.126 81.5 139.409 81.5 141.749V166.5H48.5V131C48.5 127.962 50.9624 125.5 54 125.5Z"
        fill="#A54B7A"
        stroke="#763359"
      />
      <path
        d="M47 125.5H65.2791C67.0137 125.5 68.6466 126.318 69.6849 127.708L75.2121 135.104C76.6974 137.092 77.5 139.507 77.5 141.988V166.5H44.5V128C44.5 126.619 45.6193 125.5 47 125.5Z"
        fill="#FDA2CB"
        stroke="#763359"
      />
      <rect x="47" y="129" width="3" height="3" rx="1.5" fill="#E269A4" />
      <rect
        x="62.5"
        y="142.5"
        width="11"
        height="24"
        rx="1.5"
        fill="#FDA2CB"
        stroke="#A54B7A"
      />
      <rect x="53" y="126" width="4" height="43" fill="#763359" />
      <rect
        x="52.5"
        y="61.5"
        width="3"
        height="107"
        fill="#FDA2CB"
        stroke="#763359"
      />
      <rect x="52" y="126" width="3" height="2" fill="#763359" />
      <rect x="55" y="125" width="4" height="4" rx="2" fill="#763359" />
      <rect
        x="50.5"
        y="124.5"
        width="8"
        height="2"
        rx="0.5"
        fill="#FDA2CB"
        stroke="#763359"
      />
      <rect x="52" y="144" width="3" height="2" fill="#763359" />
      <rect x="55" y="143" width="4" height="4" rx="2" fill="#763359" />
      <rect
        x="50.5"
        y="142.5"
        width="8"
        height="2"
        rx="0.5"
        fill="#FDA2CB"
        stroke="#763359"
      />
      <path d="M53 65H56V68L53 69V65Z" fill="#763359" />
      <path d="M53 100H56V106L53 103V100Z" fill="#763359" />
      <path
        d="M59.2183 41.0831L56.89 39.8844C55.9319 39.3912 54.7553 39.7457 54.2292 40.6861L47.7339 52.2949C47.1039 53.4209 47.6892 54.84 48.9299 55.1945L51.9261 56.0506C52.8416 56.3121 53.8146 55.8921 54.252 55.0464L60.0794 43.7801C60.5875 42.7977 60.2018 41.5893 59.2183 41.0831Z"
        fill="#F486B8"
        stroke="#763359"
      />
      <path
        d="M48 64.4299V53.6969C48 52.5374 49.027 51.6467 50.1748 51.8107C50.97 51.9243 51.6085 52.5243 51.7712 53.3109L54.1238 64.6819C54.336 65.7072 53.7215 66.7225 52.7149 67.0102L51.8894 67.2461C51.3252 67.4073 50.7186 67.3127 50.2304 66.9872L48.8906 66.094C48.3342 65.723 48 65.0986 48 64.4299Z"
        fill="#763359"
      />
      <path
        d="M54.0632 54.8784L52.5 48.0002L50.9383 49.9522C50.6546 50.3068 50.5 50.7474 50.5 51.2016V65.7697C50.5 66.8539 51.7646 67.4462 52.5975 66.7521C56.9712 63.1075 59.5 57.7085 59.5 52.0154V45.0002L58.1452 47.7097C58.049 47.902 57.9845 48.1085 57.9541 48.3213L57.0347 54.7571C57.0117 54.918 56.9629 55.0741 56.8903 55.2195C56.2552 56.4896 54.3779 56.2631 54.0632 54.8784Z"
        fill="#FDA2CB"
        stroke="#763359"
      />
      <mask id="path-19-inside-1_340_12558" fill="white">
        <path d="M49.7897 8.58255C55.0724 11.6325 58.5058 17.3861 59.3344 24.5775C60.163 31.7689 58.3191 39.8091 54.2083 46.9293C50.0974 54.0495 44.0564 59.6665 37.4141 62.5446C30.7718 65.4227 24.0724 65.3261 18.7897 62.2761L34.2897 35.4293L49.7897 8.58255Z" />
      </mask>
      <path
        d="M49.7897 8.58255C55.0724 11.6325 58.5058 17.3861 59.3344 24.5775C60.163 31.7689 58.3191 39.8091 54.2083 46.9293C50.0974 54.0495 44.0564 59.6665 37.4141 62.5446C30.7718 65.4227 24.0724 65.3261 18.7897 62.2761L34.2897 35.4293L49.7897 8.58255Z"
        fill="#FDA2CB"
        stroke="#763359"
        strokeWidth="2"
        mask="url(#path-19-inside-1_340_12558)"
      />
      <mask id="path-20-inside-2_340_12558" fill="white">
        <path d="M46.3555 13.5286C51.7531 16.6449 55.6126 21.9146 57.0851 28.1784C58.5575 34.4422 57.5223 41.1871 54.2071 46.9292C50.8919 52.6713 45.5683 56.9403 39.4074 58.797C33.2466 60.6537 26.7531 59.9461 21.3555 56.8298L33.8555 35.1792L46.3555 13.5286Z" />
      </mask>
      <path
        d="M46.3555 13.5286C51.7531 16.6449 55.6126 21.9146 57.0851 28.1784C58.5575 34.4422 57.5223 41.1871 54.2071 46.9292C50.8919 52.6713 45.5683 56.9403 39.4074 58.797C33.2466 60.6537 26.7531 59.9461 21.3555 56.8298L33.8555 35.1792L46.3555 13.5286Z"
        stroke="#763359"
        strokeWidth="2"
        mask="url(#path-20-inside-2_340_12558)"
      />
      <mask id="path-21-inside-3_340_12558" fill="white">
        <path d="M39.8555 24.7869C45.2531 27.9032 49.7974 31.9868 52.4889 36.1393C55.1803 40.2917 55.7984 44.173 54.2071 46.9292C52.6158 49.6854 48.9455 51.0908 44.0036 50.8362C39.0617 50.5816 33.2531 48.6878 27.8555 45.5715L33.8555 35.1792L39.8555 24.7869Z" />
      </mask>
      <path
        d="M39.8555 24.7869C45.2531 27.9032 49.7974 31.9868 52.4889 36.1393C55.1803 40.2917 55.7984 44.173 54.2071 46.9292C52.6158 49.6854 48.9455 51.0908 44.0036 50.8362C39.0617 50.5816 33.2531 48.6878 27.8555 45.5715L33.8555 35.1792L39.8555 24.7869Z"
        stroke="#763359"
        strokeWidth="2"
        mask="url(#path-21-inside-3_340_12558)"
      />
      <ellipse
        cx="34.1791"
        cy="35.4596"
        rx="31.2675"
        ry="11.0356"
        transform="rotate(-60 34.1791 35.4596)"
        fill="#FFBFDB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8837 9.27544C46.5482 20.1977 43.2625 37.9054 33.4022 54.9839C33.3298 55.1094 33.2572 55.2345 33.1843 55.3594C36.7655 51.6092 40.4413 46.6785 43.7329 40.9774C52.3672 26.0224 55.0878 11.4285 49.8095 8.38115C48.057 7.36929 45.6428 7.75767 42.8837 9.27544Z"
        fill="#CB5D94"
      />
      <path
        d="M49.5629 8.8142C50.7034 9.47269 51.4727 10.7886 51.8117 12.7424C52.1501 14.6926 52.044 17.2051 51.5002 20.1223C50.4134 25.952 47.5997 33.2856 43.3032 40.7274C39.0067 48.1692 34.0624 54.2728 29.5572 58.1288C27.3027 60.0583 25.1798 61.4064 23.3217 62.0885C21.4602 62.7718 19.9359 62.7635 18.7954 62.105C17.6548 61.4466 16.8855 60.1306 16.5465 58.1768C16.2082 56.2267 16.3142 53.7141 16.858 50.7969C17.9448 44.9673 20.7585 37.6336 25.055 30.1918C29.3515 22.7501 34.2958 16.6465 38.8011 12.7905C41.0555 10.8609 43.1784 9.5128 45.0365 8.83075C46.898 8.14743 48.4223 8.15571 49.5629 8.8142Z"
        stroke="#763359"
      />
      <path
        d="M39.45 46.4912L15.2254 26.7016C13.4693 25.267 15.0789 22.479 17.1994 23.2825L46.45 34.3669"
        stroke="#763359"
      />
      <path d="M41.45 43.0273L5.71094 18.9293L44.45 37.8312" stroke="#763359" />
      <rect
        x="9.72598"
        y="22.9781"
        width="3"
        height="11"
        rx="1.5"
        transform="rotate(-60 9.72598 22.9781)"
        fill="#FDA2CB"
        stroke="#763359"
      />
      <path
        d="M21 62.5C27.5 71 37 68 39 59.5C41.5 63 45 64.5 49 63.5C49 69.8333 49.7 84.6 52.5 93"
        stroke="#763359"
      />
      <path
        d="M71.9428 86.1897C74.7245 93.1662 71.9042 100.739 65.7962 103.174C59.6882 105.609 52.4313 102.055 49.6496 95.0785C46.8679 88.102 49.6882 80.5295 55.7962 78.0941C61.9043 75.6587 69.1611 79.2131 71.9428 86.1897Z"
        fill="#FDA2CB"
        stroke="#763359"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6736 100.538C49.678 90.9645 53.7577 80.8837 62.2738 77.4881C62.4901 77.4019 62.7073 77.3207 62.9252 77.2446C60.5319 76.6002 57.9957 76.6794 55.6124 77.6297C49.1998 80.1865 46.3228 88.0815 49.1865 95.2637C50.0097 97.3283 51.2177 99.1093 52.6736 100.538Z"
        fill="#E269A4"
      />
      <path
        d="M71.9428 86.1897C74.7245 93.1662 71.9042 100.739 65.7962 103.174C59.6882 105.609 52.4313 102.055 49.6496 95.0785C46.8679 88.102 49.6882 80.5295 55.7962 78.0941C61.9043 75.6587 69.1611 79.2131 71.9428 86.1897Z"
        stroke="#763359"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.364 97.3276L61.396 91.478C60.8973 91.212 60.7013 90.5971 60.954 90.0916C61.2406 89.5184 61.9714 89.3369 62.4928 89.7094L72.5459 96.8901C72.4878 97.0373 72.4271 97.1832 72.364 97.3276Z"
        fill="#763359"
      />
      <path
        d="M75.5 81.5L61.3962 89.022C60.8975 89.288 60.7015 89.9029 60.9542 90.4085C61.2408 90.9816 61.9716 91.1631 62.493 90.7907L75.5 81.5Z"
        fill="#FFBFDB"
        stroke="#763359"
      />
      <circle cx="75.5" cy="81.5" r="1" fill="#FDA2CB" stroke="#763359" />
      <rect
        x="22.5"
        y="139.5"
        width="13"
        height="4"
        rx="1.5"
        fill="#25A871"
        stroke="#195642"
      />
      <mask id="path-36-inside-4_340_12558" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 142H23C22.4477 142 22 141.552 22 141V126C22 125.448 22.4477 125 23 125H34C37.3137 125 40 127.686 40 131V141C40 141.552 39.5523 142 39 142H34H28Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 142H23C22.4477 142 22 141.552 22 141V126C22 125.448 22.4477 125 23 125H34C37.3137 125 40 127.686 40 131V141C40 141.552 39.5523 142 39 142H34H28Z"
        fill="#25A871"
      />
      <path
        d="M28 141H23V143H28V141ZM23 141V126H21V141H23ZM23 126H34V124H23V126ZM41 131C41 127.134 37.866 124 34 124V126C36.7614 126 39 128.239 39 131H41ZM41 141V131H39V141H41ZM34 143H39V141H34V143ZM28 143H34V141H28V143ZM39 141V143C40.1046 143 41 142.105 41 141H39ZM23 126V126V124C21.8954 124 21 124.895 21 126H23ZM23 141H23H21C21 142.105 21.8954 143 23 143V141Z"
        fill="#195642"
        mask="url(#path-36-inside-4_340_12558)"
      />
      <mask id="path-38-inside-5_340_12558" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 131C28 127.686 25.3137 125 22 125C18.6863 125 16 127.686 16 131V141C16 141.552 16.4477 142 17 142H27C27.5523 142 28 141.552 28 141V131Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 131C28 127.686 25.3137 125 22 125C18.6863 125 16 127.686 16 131V141C16 141.552 16.4477 142 17 142H27C27.5523 142 28 141.552 28 141V131Z"
        fill="#5ABE89"
      />
      <path
        d="M22 126C24.7614 126 27 128.239 27 131H29C29 127.134 25.866 124 22 124V126ZM17 131C17 128.239 19.2386 126 22 126V124C18.134 124 15 127.134 15 131H17ZM17 141V131H15V141H17ZM27 141H17V143H27V141ZM27 131V141H29V131H27ZM27 143C28.1046 143 29 142.105 29 141H27V143ZM15 141C15 142.105 15.8954 143 17 143V141H17H15Z"
        fill="#195642"
        mask="url(#path-38-inside-5_340_12558)"
      />
      <rect x="19" y="136" width="6" height="2" fill="#195642" />
      <path
        d="M28.5 121V132H30.5V125C30.5 124.448 30.9477 124 31.5 124H34C34.5523 124 35 123.552 35 123V121C35 120.448 34.5523 120 34 120H29.5C28.9477 120 28.5 120.448 28.5 121Z"
        fill="#E269A4"
        stroke="#A54B7A"
      />
      <rect x="21" y="124" width="2" height="5" rx="1" fill="#195642" />
      <rect
        x="27.5"
        y="131.5"
        width="3"
        height="2"
        fill="#1F7A57"
        stroke="#195642"
      />
      <rect x="27" y="133" width="4" height="2" fill="#195642" />
      <rect
        x="27.5"
        y="143.5"
        width="3"
        height="23"
        fill="#1F7A57"
        stroke="#195642"
      />
      <rect
        x="27.5"
        y="144.5"
        width="3"
        height="1"
        fill="#1F7A57"
        stroke="#195642"
      />
      <path
        d="M23 164.5H75C75.8284 164.5 76.5 165.172 76.5 166V166.5H21.5V166C21.5 165.172 22.1716 164.5 23 164.5Z"
        fill="#C2AAFA"
        stroke="#8D75E6"
      />
      <circle cx="100" cy="167" r="8.5" fill="#25A871" stroke="#195642" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.5528 175.316C99.9219 171.888 102 167.187 102 162C102 160.679 101.865 159.389 101.608 158.143C101.086 158.049 100.549 158 100 158C95.0294 158 91 162.029 91 167C91 170.749 93.2928 173.963 96.5528 175.316Z"
        fill="#195642"
      />
      <mask id="path-50-inside-6_340_12558" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.534 144.986C66.2896 148.099 62 154.548 62 162C62 172.493 70.5066 181 81 181C91.4934 181 100 172.493 100 162C100 160.248 99.763 158.552 99.3192 156.942L96 155L97.2762 152.192C94.857 148.186 91.0005 145.145 86.4211 143.785L84.5 146.353L83.6529 143.184C82.786 143.063 81.9003 143 81 143C79.1682 143 77.397 143.259 75.721 143.743L76 146.353L72.534 144.986Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.534 144.986C66.2896 148.099 62 154.548 62 162C62 172.493 70.5066 181 81 181C91.4934 181 100 172.493 100 162C100 160.248 99.763 158.552 99.3192 156.942L96 155L97.2762 152.192C94.857 148.186 91.0005 145.145 86.4211 143.785L84.5 146.353L83.6529 143.184C82.786 143.063 81.9003 143 81 143C79.1682 143 77.397 143.259 75.721 143.743L76 146.353L72.534 144.986Z"
        fill="#5ABE89"
      />
      <path
        d="M72.534 144.986L72.901 144.055L72.4866 143.892L72.0879 144.091L72.534 144.986ZM99.3192 156.942L100.283 156.676L100.175 156.284L99.8242 156.078L99.3192 156.942ZM96 155L95.0896 154.586L94.7163 155.408L95.4951 155.863L96 155ZM97.2762 152.192L98.1866 152.606L98.4046 152.126L98.1323 151.675L97.2762 152.192ZM86.4211 143.785L86.7059 142.826L86.0378 142.627L85.6203 143.186L86.4211 143.785ZM84.5 146.353L83.5339 146.611L84.0662 148.602L85.3008 146.952L84.5 146.353ZM83.6529 143.184L84.619 142.926L84.4478 142.285L83.7913 142.193L83.6529 143.184ZM75.721 143.743L75.4436 142.782L74.6374 143.015L74.7266 143.849L75.721 143.743ZM76 146.353L75.6331 147.283L77.17 147.889L76.9943 146.247L76 146.353ZM63 162C63 154.942 67.062 148.831 72.9802 145.881L72.0879 144.091C65.5172 147.367 61 154.155 61 162H63ZM81 180C71.0589 180 63 171.941 63 162H61C61 173.046 69.9543 182 81 182V180ZM99 162C99 171.941 90.9411 180 81 180V182C92.0457 182 101 173.046 101 162H99ZM98.3552 157.207C98.7753 158.732 99 160.339 99 162H101C101 160.158 100.751 158.372 100.283 156.676L98.3552 157.207ZM95.4951 155.863L98.8143 157.805L99.8242 156.078L96.5049 154.137L95.4951 155.863ZM96.3659 151.778L95.0896 154.586L96.9104 155.414L98.1866 152.606L96.3659 151.778ZM86.1362 144.743C90.4727 146.032 94.1274 148.912 96.4202 152.709L98.1323 151.675C95.5866 147.46 91.5283 144.259 86.7059 142.826L86.1362 144.743ZM85.3008 146.952L87.2219 144.384L85.6203 143.186L83.6992 145.754L85.3008 146.952ZM82.6868 143.442L83.5339 146.611L85.4661 146.095L84.619 142.926L82.6868 143.442ZM81 144C81.8539 144 82.6933 144.059 83.5145 144.174L83.7913 142.193C82.8786 142.066 81.9467 142 81 142V144ZM75.9983 144.704C77.5851 144.246 79.263 144 81 144V142C79.0734 142 77.2089 142.273 75.4436 142.782L75.9983 144.704ZM74.7266 143.849L75.0057 146.459L76.9943 146.247L76.7153 143.637L74.7266 143.849ZM76.3669 145.423L72.901 144.055L72.1671 145.916L75.6331 147.283L76.3669 145.423Z"
        fill="#195642"
        mask="url(#path-50-inside-6_340_12558)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.2927 153.96V158.294L72.5913 155.702C75.2283 157.548 77.2442 160.22 78.2631 163.343L75.9104 164.912L78.7331 165.168C78.9083 166.085 79 167.032 79 168C79 176.284 72.2843 183 64 183C55.7157 183 49 176.284 49 168C49 164.857 49.9669 161.939 51.6194 159.529L54.7339 161.824L53.0616 157.736C55.798 154.821 59.6864 153 64 153C65.8631 153 67.6469 153.34 69.2927 153.96Z"
        fill="#195642"
      />
      <mask id="path-53-inside-7_340_12558" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.2927 151.96V156.294L70.5913 153.702C73.2283 155.548 75.2442 158.22 76.2631 161.343L73.9104 162.912L76.7331 163.168C76.9083 164.085 77 165.032 77 166C77 174.284 70.2843 181 62 181C53.7157 181 47 174.284 47 166C47 162.857 47.9669 159.939 49.6194 157.529L52.7339 159.824L51.0616 155.736C53.798 152.821 57.6864 151 62 151C63.8631 151 65.6469 151.34 67.2927 151.96Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.2927 151.96V156.294L70.5913 153.702C73.2283 155.548 75.2442 158.22 76.2631 161.343L73.9104 162.912L76.7331 163.168C76.9083 164.085 77 165.032 77 166C77 174.284 70.2843 181 62 181C53.7157 181 47 174.284 47 166C47 162.857 47.9669 159.939 49.6194 157.529L52.7339 159.824L51.0616 155.736C53.798 152.821 57.6864 151 62 151C63.8631 151 65.6469 151.34 67.2927 151.96Z"
        fill="#87D1A3"
      />
      <path
        d="M67.2927 151.96H68.2927V151.269L67.6456 151.025L67.2927 151.96ZM67.2927 156.294H66.2927V158.352L67.9106 157.081L67.2927 156.294ZM70.5913 153.702L71.1647 152.883L70.5569 152.458L69.9735 152.916L70.5913 153.702ZM76.2631 161.343L76.8178 162.175L77.4492 161.755L77.2138 161.033L76.2631 161.343ZM73.9104 162.912L73.3557 162.08L70.9985 163.651L73.8199 163.908L73.9104 162.912ZM76.7331 163.168L77.7154 162.981L77.574 162.241L76.8237 162.173L76.7331 163.168ZM49.6194 157.529L50.2126 156.724L49.3796 156.11L48.7946 156.963L49.6194 157.529ZM52.7339 159.824L52.1407 160.629L55.008 162.741L53.6595 159.445L52.7339 159.824ZM51.0616 155.736L50.3325 155.051L49.8928 155.52L50.1361 156.114L51.0616 155.736ZM66.2927 151.96V156.294H68.2927V151.96H66.2927ZM67.9106 157.081L71.2091 154.489L69.9735 152.916L66.6749 155.508L67.9106 157.081ZM70.0179 154.522C72.4805 156.245 74.362 158.741 75.3125 161.654L77.2138 161.033C76.1265 157.7 73.9761 154.851 71.1647 152.883L70.0179 154.522ZM75.7084 160.511L73.3557 162.08L74.4651 163.744L76.8178 162.175L75.7084 160.511ZM73.8199 163.908L76.6426 164.164L76.8237 162.173L74.0009 161.916L73.8199 163.908ZM75.7509 163.356C75.9143 164.212 76 165.095 76 166H78C78 164.969 77.9023 163.959 77.7154 162.981L75.7509 163.356ZM76 166C76 173.732 69.732 180 62 180V182C70.8366 182 78 174.837 78 166H76ZM62 180C54.268 180 48 173.732 48 166H46C46 174.837 53.1634 182 62 182V180ZM48 166C48 163.065 48.9021 160.343 50.4442 158.094L48.7946 156.963C47.0316 159.535 46 162.649 46 166H48ZM53.3271 159.019L50.2126 156.724L49.0262 158.334L52.1407 160.629L53.3271 159.019ZM50.1361 156.114L51.8084 160.202L53.6595 159.445L51.9872 155.357L50.1361 156.114ZM51.7907 156.42C54.346 153.698 57.9739 152 62 152V150C57.3989 150 53.2501 151.943 50.3325 155.051L51.7907 156.42ZM62 152C63.7407 152 65.405 152.317 66.9398 152.896L67.6456 151.025C65.8887 150.362 63.9855 150 62 150V152Z"
        fill="#195642"
        mask="url(#path-53-inside-7_340_12558)"
      />
    </g>
    <defs>
      <clipPath id="clip0_340_12558">
        <rect width="109" height="166" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
