import { getPositionInViewport } from "./utils/storyboardViewport.utils";
import {
  StoryboardDecorationDef,
  useStoryboardContext,
} from "./StoryboardContext";

type Props = {
  def: StoryboardDecorationDef;
  isSelected?: boolean;
};

const StoryboardDecoration = ({ def, isSelected }: Props) => {
  const context = useStoryboardContext();
  const positionInViewport = getPositionInViewport(context, def);
  return (
    <div>
      <def.component
        style={{
          position: "absolute",
          left: positionInViewport.x,
          top: positionInViewport.y,
          width: def.width,
          height: def.height,
        }}
      />
    </div>
  );
};

export default StoryboardDecoration;
