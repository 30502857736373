import { styled } from "@linaria/react";
import LogoCanva from "../thirdPartyLogos/LogoCanva";
import LogoCoinbase from "../thirdPartyLogos/LogoCoinbase";
import LogoGitLab from "../thirdPartyLogos/LogoGitLab";
import LogoMcKesson from "../thirdPartyLogos/LogoMcKesson";
import LogoOpenTable from "../thirdPartyLogos/LogoOpenTable";
import LogoMars20h from "../thirdPartyLogos/LogoMars20h";
import LogoRedditForDarkBackgrounds, {
  LogoRedditForLightBackgrounds,
} from "../thirdPartyLogos/LogoReddit";
import { rSize } from "../../styles/responsiveSizes.styles";
import LogoIntercom from "../thirdPartyLogos/LogoIntercom";
import { LogoElastic36h } from "../thirdPartyLogos/LogoElastic36h";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import LogoDatabricks from "../thirdPartyLogos/LogoDatabricks";
import {
  fromPhoneLg,
  fromTabletLg,
  uptoDesktopMd,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

export const FeaturedClientLogoSetContainer = styled.div`
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  ${fromPhoneLg} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(6, 1fr);
  }
  grid-gap: ${rSize("gap")};
  > div {
    display: grid;
    align-items: center;
    justify-items: center;
    min-height: 4em;
    svg {
      height: auto;
      ${uptoDesktopMd} {
        max-width: 90%;
      }
      ${uptoTablet} {
        max-width: 85%;
      }
    }
  }
`;

const FeaturedClientLogoSet = (props: {
  againstBackground?: "light" | "dark";
}) => {
  return (
    <FeaturedClientLogoSetContainer>
      <div>
        <LogoCanva />
      </div>
      <div>
        <LogoCoinbase />
      </div>
      <div>
        <LogoDatabricks />
      </div>
      <div>
        <LogoElastic36h />
      </div>
      <div>
        <LogoGitLab />
      </div>
      <div>
        <LogoIntercom />
      </div>
      <div>
        <LogoMars20h />
      </div>
      <div>
        <LogoMcKesson />
      </div>
      <div>
        <LogoOakRidge />
      </div>
      <div>
        <LogoOpenTable />
      </div>
      <div>
        <LogoSnowflake />
      </div>
      <div>
        {props.againstBackground === "light" ? (
          <LogoRedditForLightBackgrounds />
        ) : (
          <LogoRedditForDarkBackgrounds />
        )}
      </div>
    </FeaturedClientLogoSetContainer>
  );
};

export default FeaturedClientLogoSet;
