export const PagerDutyIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36">
    <rect width="36" height="36" rx="8" fill="#06AC38" />
    <path d="M14.3572 23.2477H11.3103V28.7875H14.3572V23.2477Z" fill="white" />
    <path
      d="M22.7856 9.01563C21.15 8.14509 20.0157 8 17.3381 8H11.3103V20.6096H14.3572H14.7529H17.3117C19.6991 20.6096 21.4798 20.4645 23.0494 19.4225C24.7641 18.2882 25.6478 16.402 25.6478 14.2257C25.6478 11.8647 24.553 9.9785 22.7856 9.01563ZM18.0108 17.9716H14.3572V10.7039L17.7998 10.6776C20.939 10.638 22.5086 11.746 22.5086 14.2521C22.5086 16.9428 20.5697 17.9716 18.0108 17.9716Z"
      fill="white"
    />
  </svg>
);
