import {
  DatoCmsFaqQuestion,
  DatoCmsProfessionalServicesPage,
} from "../../../graphql-types";
import { BrandColorNameV4 } from "../../styles/colorsV4.styles";
import FaqListSection from "../reusableSections/FaqListSection";
import faqSectionIcon from "./illustrations/faqSectionIcon.svg";

export const ProServFAQSection = (props: {
  page: DatoCmsProfessionalServicesPage;
  questionColor?: BrandColorNameV4 | string;
  lighterHeading?: boolean;
}) => {
  return (
    <FaqListSection
      sectionHeading={
        <>
          <div>FAQs about</div> <div>Tines professional services</div>
        </>
      }
      headerIcon={<img src={faqSectionIcon} style={{ aspectRatio: 93 / 72 }} />}
      lighterHeading
      datoRecords={(props.page.faqs ?? []) as DatoCmsFaqQuestion[]}
      questionColor={props.questionColor}
    />
  );
};
