import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import NewsletterToasterIcon from "../illustrations/spots/NewsletterToasterIcon";
import {
  fromDesktopMd,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import NewsletterSignupForm from "../forms/NewsletterSignupForm";
import { PropsWithChildren } from "react";
import { font } from "../../styles/fonts.styles";
import { brandColorThemeVar, colors } from "../../styles/colors.styles";

type Props = {
  color?: string;
};

const NewsletterCTASectionContainer = styled.section``;

const Card = styled.div<Props>`
  background-color: ${brandColorThemeVar(500)};
  color: ${colors.white};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  display: grid;
  font-weight: 500;
  ${uptoTabletMd} {
    padding-top: 2em;
    grid-gap: 2em;
    grid-template-columns: minmax(0, 1fr);
    text-align: center;
    grid-template-areas: "icon" "header";
  }
  ${fromTabletMd} {
    grid-gap: 4em;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: minmax(auto, 1fr) auto;
    grid-template-areas: "header icon";
  }
`;

const Header = styled.header`
  grid-area: header;
  > * {
    + * {
      margin-top: 2em;
    }
  }
  h2 {
    ${fluidFontSize(28, 42)};
    font-family: ${font("serif")};
    font-weight: 400;
    letter-spacing: -0.01em;
  }
`;

const IconWrapper = styled.div`
  grid-area: icon;
  svg {
    height: auto;
    width: 12rem;
    ${uptoTabletMd} {
      margin-right: -8%;
    }
    ${fromDesktopMd} {
      width: 18rem;
    }
  }
`;

const formStyle = css`
  ${fromTabletMd} {
    max-width: 62rem;
  }
  input {
    border-color: ${brandColorThemeVar(300)};
  }
`;

const NewsletterCTASection = (props: PropsWithChildren<Props>) => {
  return (
    <NewsletterCTASectionContainer>
      <Card {...props}>
        <Header>
          <div>
            {props.children ?? (
              <h2>
                World class ideas
                <br />
                right in your inbox
              </h2>
            )}
          </div>
          <NewsletterSignupForm
            white
            className={formStyle}
            color={props.color}
          />
        </Header>
        <IconWrapper>
          <NewsletterToasterIcon />
        </IconWrapper>
      </Card>
    </NewsletterCTASectionContainer>
  );
};

export default NewsletterCTASection;
