import { useState } from "react";
import { renderInPortal } from "../../utils/portals.utils";
import Button from "../forms/Button";
import { styled } from "@linaria/react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { useHotkeys } from "react-hotkeys-hook";

const LightboxScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`;

const LightboxScreenInner = styled.div`
  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  padding: ${rSize("lg")} ${rSize("pageMargin")};
  overflow: hidden;
`;

const Backdrop = styled.div`
  position: fixed;
  background-color: ${withOpacity(colors.dark500, 0.4)};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @keyframes BackdropEnter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: BackdropEnter 1s;
`;

const Iframe = styled.iframe`
  position: relative;
  border-radius: ${rSize("radius")};
  background-color: ${colors.purple800};
  overflow: hidden;
  display: block;
  border: 0;
  width: 100%;
  max-width: 1280px;
  height: auto;
  aspect-ratio: 16/9;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2em 5em rgba(0, 0, 0, 0.3);
  @keyframes IframeEnter {
    from {
      opacity: 0;
      transform: scale(1.03);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  animation: IframeEnter 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const AIPageWebinarButtonAndLightbox = () => {
  const [shouldShowLightbox, setShouldShowLightbox] = useState(false);
  useHotkeys("escape", () => {
    setShouldShowLightbox(false);
  });
  return (
    <>
      <Button
        title="Watch the webinar"
        darker
        onClick={() => {
          setShouldShowLightbox(!shouldShowLightbox);
        }}
      />
      {shouldShowLightbox &&
        renderInPortal(
          <LightboxScreen>
            <Backdrop
              onClick={() => {
                setShouldShowLightbox(false);
              }}
            />
            <LightboxScreenInner>
              <Iframe
                src="https://www.youtube.com/embed/u5b6EwQbCQk?autoplay=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </LightboxScreenInner>
          </LightboxScreen>
        )}
    </>
  );
};

export default AIPageWebinarButtonAndLightbox;
