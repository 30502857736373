import { styled } from "@linaria/react";
import BootcampIcon from "../illustrations/spots/BootcampIcon";
import PageSection from "../reusableSections/PageSection";
import SiteNavHeightPlaceholder from "../site/SiteNavHeightPlaceholder";
import Spacing from "../layout/Spacing";
import { serif } from "../../styles/fonts.styles";
import { css } from "linaria";
import { color, colors } from "../../styles/colors.styles";
import {
  fromDesktop,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import AllCaps from "../typography/AllCaps";
import { GridCanvas } from "../decorations/GridCanvas";
import { rSize } from "../../styles/responsiveSizes.styles";

const bootcampsPageHeroSectionStyle = css`
  position: relative;
  background-color: ${colors.green100};
  border-bottom: 1px solid ${colors.green200};
`;

const Inner = styled.div`
  position: relative;
  display: grid;
  grid-gap: ${rSize("lg")};
  ${uptoTabletMd} {
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: "icon" "text";
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  ${fromTabletMd} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "text icon";
  }
  h1 {
    font-family: ${serif};
    font-size: 3.8rem;
    font-weight: 400;
    ${fromTabletMd} {
      font-size: 4.8rem;
      letter-spacing: -0.01em;
    }
    ${fromDesktop} {
      font-weight: 300;
      letter-spacing: -0.02em;
      font-size: 6.4rem;
    }
  }
  p {
    max-width: 30em;
  }
`;

const Text = styled.div`
  grid-area: text;
`;

const IconWrap = styled.div`
  grid-area: icon;
  background-color: ${color("green", 150)};
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
  svg {
    display: block;
    width: 66%;
    height: auto;
  }
  ${uptoTabletMd} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const BootcampsPageHero = () => {
  return (
    <PageSection relative className={bootcampsPageHeroSectionStyle}>
      <SiteNavHeightPlaceholder />
      <Spacing size="xl" />
      <GridCanvas color={colors.green200} parallax />
      <Inner>
        <IconWrap>
          <BootcampIcon />
        </IconWrap>
        <Text>
          <AllCaps as="div">New to Tines?</AllCaps>
          <Spacing size="1em" />
          <h1>Tines Bootcamp</h1>
          <Spacing size="1em" />
          <p>
            Become a powerful automator and builder in Tines by taking our
            series of educational bootcamps.
          </p>
          {/* <Spacing size="md" />
          <Button to="/bootcamp" darker>
            Register now
          </Button> */}
        </Text>
      </Inner>
      <Spacing size="sectionPadding" />
    </PageSection>
  );
};

export default BootcampsPageHero;
