export const CasesPageStatBuildingIcon = () => (
  <svg
    width="78"
    height="73"
    viewBox="0 0 78 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.8585 71.9793C60.4937 71.9793 77.2219 65.4335 77.2219 57.3588C77.2219 49.2841 60.4937 42.7383 39.8585 42.7383C19.2233 42.7383 2.49512 49.2841 2.49512 57.3588C2.49512 65.4335 19.2233 71.9793 39.8585 71.9793Z"
      fill="#32313B"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.6149 9.08316H60.9846C59.5004 4.54587 46.6716 1 31.0644 1C22.87 1 15.443 1.97771 10.0195 3.56291L30.6149 9.08316Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.4859 59.6124H60.9848V9.08324H30.6149L10.0195 3.56299V50.7681L30.4859 59.6124Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path d="M30.6152 9.08301V59.6122" stroke="#32313B" strokeMiterlimit="10" />
    <path
      d="M8.00464 66.9578C3.632 64.9699 1 62.4085 1 59.6128V25.7519L30.486 23.2339V56.4085L8.00464 66.9578Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M1 25.0698C1 27.4613 4.61346 29.6784 8.00464 31.5024L30.486 23.056L10.0199 16.7334C3.97479 18.7941 1 21.7662 1 25.0698Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.00439 31.5024V66.632" stroke="#32313B" strokeMiterlimit="10" />
    <path
      d="M54.9184 66.5806L30.4858 55.7744V29.9595L61.1292 33.5809V59.6124C61.1295 62.2345 58.8135 64.6507 54.9184 66.5806Z"
      fill="#5178B3"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.9189 38.3404C59.3432 36.997 60.2593 35.3561 61.012 33.8707C61.3922 33.1203 61.2101 31.1971 60.9855 29.644L30.5991 29.9596L54.9189 38.3404Z"
      fill="#96BEFA"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.9185 66.5807V38.3403"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.3349 17.4368C39.3369 18.0443 38.8462 18.5383 38.2384 18.5405L36.8152 18.545C36.2069 18.5472 35.713 18.0565 35.711 17.4488L35.6987 13.6844C35.6965 13.0769 36.1875 12.5829 36.7949 12.5812L38.219 12.5762C38.8267 12.5739 39.3202 13.0647 39.3224 13.6721L39.3349 17.4368Z"
      fill="#FDB2D2"
    />
    <path
      d="M44.9973 17.4185C44.9993 18.0262 44.5085 18.5202 43.9011 18.5217L42.477 18.5267C41.8693 18.5287 41.3758 18.0379 41.3738 17.4307L41.3608 13.6663C41.3591 13.0586 41.8498 12.5649 42.4576 12.5631L43.8816 12.5581C44.4891 12.5561 44.9828 13.0468 44.985 13.6541L44.9973 17.4185Z"
      fill="#FDB2D2"
    />
    <path
      d="M50.6596 17.3994C50.6616 18.0072 50.1709 18.5011 49.5632 18.5029L48.1389 18.5076C47.5314 18.5099 47.0377 18.0191 47.0352 17.4117L47.023 13.6473C47.0207 13.0398 47.5117 12.5458 48.1189 12.5436L49.5437 12.5391C50.1509 12.5371 50.6449 13.0278 50.6469 13.6353L50.6596 17.3994Z"
      fill="#FDB2D2"
    />
    <path
      d="M56.3213 17.3806C56.3232 17.9881 55.8325 18.4823 55.2248 18.4841L53.8005 18.4888C53.1933 18.4908 52.6991 18.0001 52.6973 17.3926L52.6846 13.6285C52.6826 13.0207 53.1733 12.5268 53.781 12.5248L55.2053 12.52C55.8126 12.518 56.3065 13.009 56.3088 13.6162L56.3213 17.3806Z"
      fill="#FDB2D2"
    />
    <path
      d="M39.3605 25.5833C39.3628 26.1908 38.8718 26.6847 38.2641 26.6867L36.8403 26.6915C36.2326 26.6932 35.7391 26.2027 35.7368 25.5953L35.7246 21.8306C35.7224 21.2234 36.2131 20.7291 36.8208 20.7274L38.2446 20.7227C38.8523 20.7204 39.3463 21.2114 39.3483 21.8189L39.3605 25.5833Z"
      fill="#FDB2D2"
    />
    <path
      d="M45.0229 25.5647C45.0249 26.1722 44.5337 26.6662 43.9264 26.6682L42.5027 26.6729C41.8949 26.6749 41.401 26.1839 41.399 25.5767L41.3862 21.812C41.3842 21.2048 41.875 20.7106 42.4827 20.7088L43.9065 20.7041C44.5142 20.7019 45.0082 21.1926 45.0102 21.8003L45.0229 25.5647Z"
      fill="#FDB2D2"
    />
    <path
      d="M50.6848 25.5459C50.6868 26.1534 50.196 26.6476 49.5883 26.6491L48.1643 26.6539C47.5568 26.6564 47.0628 26.1651 47.0608 25.5579L47.0483 21.7937C47.0461 21.186 47.5368 20.6921 48.1446 20.6901L49.5689 20.6851C50.1761 20.6831 50.6703 21.1738 50.6721 21.7815L50.6848 25.5459Z"
      fill="#FDB2D2"
    />
    <path
      d="M56.3469 25.5271C56.3491 26.1346 55.8584 26.6286 55.2507 26.6306L53.8269 26.6353C53.2192 26.6371 52.7252 26.1466 52.7232 25.5391L52.7105 21.7749C52.7087 21.1672 53.1997 20.6732 53.8069 20.6715L55.2312 20.6665C55.8387 20.6645 56.3324 21.1555 56.3347 21.763L56.3469 25.5271Z"
      fill="#FDB2D2"
    />
    <path
      d="M54.9181 57.4336L30.6147 47.8398L31.1955 57.2996L30.6147 57.0723L32.2528 71.0492C41.2176 71.0492 49.7227 69.5907 54.9181 66.5805V57.4336Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.9181 57.4336L30.6147 47.8398L31.5124 62.4604C41.5963 62.3299 49.7229 60.4441 54.9181 57.4336Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.00439 66.9577C13.5054 69.6496 21.4125 71.0594 31.0648 71.0594C31.463 71.0594 31.8587 71.0554 32.2534 71.0497V52.4597C31.8587 52.4539 13.5057 45.7888 8.00465 48.4807V66.9577H8.00439Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.0651 52.4683C31.4632 52.4683 31.8589 52.4646 32.2536 52.4596L30.4863 37.4658L8.00488 48.4804C13.5057 50.907 21.4127 52.4683 31.0651 52.4683Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.3069 39.3657C38.3069 39.6636 38.0769 39.8272 37.7957 39.7294L35.0878 38.7894C34.8067 38.6916 34.5767 38.368 34.5767 38.0701V35.2261C34.5767 34.9282 34.8067 34.7645 35.0878 34.8623L37.7957 35.8026C38.0769 35.9004 38.3069 36.224 38.3069 36.5219V39.3657Z"
      fill="#96BEFA"
    />
    <path
      d="M43.9951 41.3592C43.9951 41.6568 43.7646 41.8208 43.4834 41.7232L40.7763 40.7829C40.4949 40.6851 40.2646 40.3615 40.2646 40.0637V37.2198C40.2646 36.922 40.4949 36.7583 40.7763 36.8558L43.4834 37.7961C43.7646 37.8939 43.9951 38.2175 43.9951 38.5154V41.3592Z"
      fill="#96BEFA"
    />
    <path
      d="M49.9338 43.4003C49.9338 43.6982 49.7036 43.8619 49.4222 43.7641L46.7148 42.824C46.4334 42.726 46.2031 42.4027 46.2031 42.1048V39.261C46.2031 38.9631 46.4334 38.7992 46.7148 38.897L49.4222 39.8373C49.7036 39.9351 49.9338 40.2586 49.9338 40.5565V43.4003Z"
      fill="#96BEFA"
    />
    <path
      d="M38.3069 46.7464C38.3069 47.0443 38.0769 47.2079 37.7957 47.1101L35.0878 46.1701C34.8067 46.0723 34.5767 45.7487 34.5767 45.4508V42.607C34.5767 42.3091 34.8067 42.1452 35.0878 42.2433L37.7957 43.1833C38.0769 43.2811 38.3069 43.6047 38.3069 43.9026V46.7464Z"
      fill="#96BEFA"
    />
    <path
      d="M43.9951 48.7396C43.9951 49.0372 43.7646 49.2011 43.4834 49.1036L40.7763 48.1633C40.4949 48.0655 40.2646 47.7422 40.2646 47.444V44.6002C40.2646 44.3023 40.4949 44.1387 40.7763 44.2362L43.4834 45.1765C43.7646 45.2743 43.9951 45.5979 43.9951 45.8958V48.7396Z"
      fill="#96BEFA"
    />
    <path
      d="M49.9338 50.781C49.9338 51.0788 49.7036 51.2427 49.4222 51.1449L46.7148 50.2047C46.4334 50.1066 46.2031 49.7833 46.2031 49.4854V46.6416C46.2031 46.3437 46.4334 46.18 46.7148 46.2778L49.4222 47.2179C49.7036 47.3157 49.9338 47.6393 49.9338 47.9371V50.781Z"
      fill="#96BEFA"
    />
    <path
      d="M18.8754 37.6166C18.8754 38.0634 18.5476 38.5903 18.1469 38.7879L12.9702 41.3411C12.5698 41.5387 12.2417 41.3346 12.2417 40.888V34.9335C12.2417 34.4866 12.584 33.9929 13.0024 33.8362L18.1147 31.921C18.5331 31.7641 18.8754 32.0013 18.8754 32.4481V37.6166Z"
      fill="#B38847"
    />
    <path
      d="M27.4491 33.3979C27.4491 33.8447 27.1218 34.3718 26.7209 34.5697L21.5444 37.1223C21.1435 37.3199 20.8159 37.1161 20.8159 36.6693V31.6849C20.8159 31.2381 21.1585 30.7441 21.5766 30.5875L26.6884 28.6724C27.1071 28.5155 27.4491 28.7528 27.4491 29.1996V33.3979Z"
      fill="#B38847"
    />
    <path
      d="M16.0661 13.7803C16.0661 14.5248 15.4778 14.9766 14.7583 14.7845L13.956 14.5699C13.2365 14.3776 12.6479 13.6112 12.6479 12.8665V9.3805C12.6479 8.63605 13.2365 8.18399 13.956 8.37634L14.7583 8.59065C15.4778 8.78299 16.0661 9.54965 16.0661 10.2941V13.7803Z"
      fill="#D66B99"
    />
    <path
      d="M21.3908 15.2244C21.3908 15.9689 20.8023 16.4207 20.083 16.2283L19.2804 16.0138C18.5612 15.8214 17.9727 15.055 17.9727 14.3103V10.8243C17.9727 10.0799 18.5612 9.62784 19.2804 9.82019L20.083 10.0347C20.8023 10.2271 21.3908 10.9935 21.3908 11.7382V15.2244Z"
      fill="#D66B99"
    />
    <path
      d="M27.1212 16.7579C27.1212 17.5023 26.5327 17.9544 25.8135 17.762L25.0109 17.5477C24.2916 17.3554 23.7031 16.589 23.7031 15.8443V12.3583C23.7031 11.6138 24.2916 11.162 25.0109 11.3544L25.8135 11.5687C26.5327 11.761 27.1212 12.5277 27.1212 13.2721V16.7579Z"
      fill="#D66B99"
    />
  </svg>
);
