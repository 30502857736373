import { styled } from "@linaria/react";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { widerPageSectionMaxWidthStyle } from "./WiderPageSection";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { Helmet } from "react-helmet";
import SiteNavHeightPlaceholder from "../site/SiteNavHeightPlaceholder";
import { cx } from "linaria";

export const LayoutWithSidebarWrap = styled.div`
  display: grid;
  ${widerPageSectionMaxWidthStyle()};
  grid-template-columns: minmax(0, 1fr);
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
  grid-gap: ${rSize("layoutWithSidebarGap")};
  &.relative {
    position: relative;
  }
  &.noPageMarginOnPhones {
    ${onlyPhones} {
      padding-left: 0;
      padding-right: 0;
    }
  }
  ${fromTablet} {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
    grid-template-columns: ${rSize("sidebarWidth")} minmax(0, 1fr);
    padding-top: 2em;
    &.largerGapOnTop {
      padding-top: 4em;
    }
  }
`;

export const LayoutWithSidebar = (
  props: PropsWithChildren<{
    style?: CSSProperties;
    largerGapOnTop?: boolean;
    noPageMarginOnPhones?: boolean;
    header?: ReactNode;
    relative?: boolean;
  }>
) => {
  return (
    <>
      <Helmet>
        <html data-has-sidebar="true" />
      </Helmet>
      <SiteNavHeightPlaceholder />
      {props.header}
      <LayoutWithSidebarWrap
        className={cx(
          props.largerGapOnTop && "largerGapOnTop",
          props.noPageMarginOnPhones && "noPageMarginOnPhones",
          props.relative && "relative"
        )}
        style={props.style}
      >
        {props.children}
      </LayoutWithSidebarWrap>
    </>
  );
};

export const LayoutWithSidebarContent = styled.div``;
