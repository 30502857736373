type Props = {
  total: number;
  value: number;
  color?: string;
};

const CisoAILineStylePercentageChart = (props: Props) => {
  return (
    <svg width="284" height="30">
      {Array(props.total)
        .fill(0)
        .map((l, i) => {
          return (
            <line
              key={i}
              x1={(283 / props.total) * i + 0.5}
              x2={(283 / props.total) * i + 0.5}
              y1={0}
              y2={30}
              stroke={props.color ?? "currentColor"}
              strokeOpacity={i >= props.value ? 0.3 : 1}
            />
          );
        })}
    </svg>
  );
};

export default CisoAILineStylePercentageChart;
