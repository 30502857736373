import { styled } from "@linaria/react";
import PricingPlanCard from "../components/general/PricingPlanCard";
import PageSection from "../components/reusableSections/PageSection";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import PricingIconSkyscraper from "../components/illustrations/spots/PricingIconSkyscraper";
import {
  fromTablet,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import PricingIconLittleHouse from "../components/illustrations/spots/PricingIconLittleHouse";
import StartupProgramSection from "../components/reusableSections/StartupProgramSection";
import SupportOptionsGridSection from "../components/reusableSections/SupportOptionsGridSection";
import ClientLogoSetWithQuotesSection from "../components/reusableSections/ClientLogoSetWithQuotesSection";
import PricingModelIllustratedSection from "../components/reusableSections/PricingModelIllustratedSection";
import BookADemoButton from "../components/utilities/BookADemoButton";
import AllCaps from "../components/typography/AllCaps";
import { colors } from "../styles/colors.styles";
import HeroSection, {
  HeroSectionCenteredIllustrationWrap,
  HeroSectionDescription,
  HeroSectionSplit8Col,
  HeroSectionSplitHeaderWrap,
} from "../components/sections/HeroSection";
import { PricingPageIllustration } from "../components/illustrations/neue/PricingPageIllustration";
import SignUpButton from "../components/utilities/SignUpButton";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";

const PricingGrid = styled.div`
  display: grid;
  ${onlyPhones} {
    padding-top: ${rSize("xl")};
    grid-gap: ${rSize("xl")};
  }
  ${fromTablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${rSize("gap")};
  }
`;

const PagePricing: PageComponent = props => {
  useOnMount(() => {
    reportIntent("Viewed pricing page");
  });

  const description = "Simple, scalable pricing to unlock powerful automation.";

  return (
    <Page
      {...props}
      browserThemeColor={colors.purple}
      backgroundColor={colors.purple}
      theme={colors.white}
      textColor={colors.white}
      alwaysUseColorScheme="light"
      navThemeColor="purple"
      mobileNavBgColor={colors.purple}
    >
      <SEO
        title="Pricing model"
        description={description}
        image="/images/og/tines-pricing.png"
      />

      <HeroSection
        backdrop="dots"
        backdropColor={colors.purple700}
        backdropPositionBottom="-5em"
        contentRelative
      >
        <HeroSectionCenteredIllustrationWrap>
          <PricingPageIllustration />
          <Spacing size="md" />
        </HeroSectionCenteredIllustrationWrap>

        <HeroSectionSplit8Col>
          <HeroSectionSplitHeaderWrap>
            <AllCaps>Freedom to build</AllCaps>
            <h1>Pricing</h1>
          </HeroSectionSplitHeaderWrap>
          <HeroSectionDescription>
            We believe in simplicity, so our pricing model reflects that with
            our always free community edition alongside our paid plans.
          </HeroSectionDescription>
        </HeroSectionSplit8Col>
      </HeroSection>

      <PageSection>
        <PricingGrid>
          <PricingPlanCard
            tag="Always free"
            icon={<PricingIconLittleHouse />}
            heading={
              <>
                <span>Community</span> <span>Edition</span>
              </>
            }
            description="Sign up to explore our full workflow functionality"
            color="green"
            details={
              <>
                <ul>
                  <li>3 builders</li>
                  <li>1 team</li>
                  <li>Unlimited viewers</li>
                </ul>
                <ul>
                  <li>3 stories</li>
                  <li>5000 daily events</li>
                </ul>
              </>
            }
            ctaButton={
              <SignUpButton
                paramString="?utm_source=pricing&utm_medium=website&utm_campaign=pricing"
                appearance="filled"
                children="Sign up now →"
                color="green"
                darker
                width="9em"
              />
            }
          />

          <PricingPlanCard
            tag="For businesses"
            heading={
              <>
                <span>Paid</span> <span>plans</span>
              </>
            }
            description="Get advanced features and expanded limits with our paid plans"
            color="purple"
            icon={<PricingIconSkyscraper />}
            details={
              <>
                <ul>
                  <li>More teams</li>
                  <li>Unlimited viewers</li>
                </ul>
                <ul>
                  <li>More stories</li>
                  <li>Unlimited story runs</li>
                </ul>
              </>
            }
            ctaButton={
              <BookADemoButton
                label="Get pricing →"
                paramsString="?utm_source=pricing&utm_medium=website&utm_campaign=pricing"
                appearance="filled"
                width="9em"
                darker
              />
            }
          />
        </PricingGrid>
      </PageSection>

      <Spacing size="xl" />

      <PricingModelIllustratedSection />

      <Spacing size="sectionMargin" />

      <SupportOptionsGridSection />

      <Spacing size="sectionMargin" />

      <ClientLogoSetWithQuotesSection />

      <Spacing size="sectionMargin" />

      <StartupProgramSection />

      <Spacing size="sectionMargin" />

      <ExplosionCTASection againstDarkBackground />
    </Page>
  );
};

export default PagePricing;
