export const NewspaperTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4H15V13C15 13.5523 14.5523 14 14 14H4V13.5V6V5V4ZM3 5V4V3H4H15H16V4V13C16 14.1046 15.1046 15 14 15H4H3H2.5L3 14.9146L2.49629 15C1.66957 14.998 1 14.3272 1 13.5V6V5H2H3ZM3 13.5V6H2V13.5C2 13.7761 2.22386 14 2.5 14C2.77614 14 3 13.7761 3 13.5Z"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5.5C5 5.22386 5.22386 5 5.5 5H12.5C12.7761 5 13 5.22386 13 5.5V6.5C13 6.77614 12.7761 7 12.5 7H5.5C5.22386 7 5 6.77614 5 6.5V5.5ZM5 8.5C5 8.22386 5.22386 8 5.5 8H8.5C8.77614 8 9 8.22386 9 8.5V10.5C9 10.7761 8.77614 11 8.5 11H5.5C5.22386 11 5 10.7761 5 10.5V8.5ZM10.5 8C10.2239 8 10 8.22386 10 8.5C10 8.77614 10.2239 9 10.5 9H13.5C13.7761 9 14 8.77614 14 8.5C14 8.22386 13.7761 8 13.5 8H10.5ZM10 10.5C10 10.2239 10.2239 10 10.5 10H13.5C13.7761 10 14 10.2239 14 10.5C14 10.7761 13.7761 11 13.5 11H10.5C10.2239 11 10 10.7761 10 10.5ZM5.5 12C5.22386 12 5 12.2239 5 12.5C5 12.7761 5.22386 13 5.5 13H11.5C11.7761 13 12 12.7761 12 12.5C12 12.2239 11.7761 12 11.5 12H5.5Z"
    />
  </svg>
);
