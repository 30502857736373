import { css, cx } from "linaria";
import { font } from "../../styles/fonts.styles";
import { ValidTwoColumnLayoutBlock } from "../../utils/datocms.utils";
import StandardArticleStructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";
import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";

const TwoColumnLayoutBlockLayout = styled.section`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  &.hasBackdrop {
    border-radius: ${rSize("radius")};
    padding: ${rSize("gap")};
    ${fromDesktop} {
      padding: ${rSize("md")} ${rSize("lg")};
    }
  }
  &[data-theme="Purple"] {
    background-color: ${colors.purple100};
    color: ${colors.purple800};
    h4 {
      color: ${colors.purple600};
    }
  }
  &[data-theme="Green"] {
    background-color: ${colors.green100};
    color: ${colors.green800};
    h4 {
      color: ${colors.green600};
    }
  }
  &[data-theme="Orange"] {
    background-color: ${colors.orange100};
    color: ${colors.orange800};
    h4 {
      color: ${colors.orange600};
    }
  }
  &[data-theme="Pink"] {
    background-color: ${colors.pink100};
    color: ${colors.pink800};
    h4 {
      color: ${colors.pink600};
    }
  }
`;

const contentStyle = css`
  font-size: 1.6rem;
  h4 {
    font-family: ${font("sans")};
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1.2;
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    padding-left: 1em;
  }
`;

export const TwoColumnLayoutBlock = ({
  record,
}: {
  record: ValidTwoColumnLayoutBlock;
}) => {
  return (
    <TwoColumnLayoutBlockLayout
      className={cx("TwoColumnLayoutBlock", record.colorTheme && "hasBackdrop")}
      data-theme={record.colorTheme}
    >
      <StandardArticleStructuredTextWithCustomBlocks
        className={contentStyle}
        value={record.column1}
        lazyLoadImages
      />
      <StandardArticleStructuredTextWithCustomBlocks
        className={contentStyle}
        value={record.column2}
        lazyLoadImages
      />
    </TwoColumnLayoutBlockLayout>
  );
};
