import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import Button, { ButtonGroupCenteredUptoDesktop } from "../forms/Button";
import Spacing from "../layout/Spacing";
import { serif } from "../../styles/fonts.styles";
import { colors } from "../../styles/colors.styles";
import {
  fromDesktop,
  fromDesktopMl,
  fromPhoneLg,
  fromTabletMd,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { GridCanvas } from "../decorations/GridCanvas";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ProductDemoVideoFrame } from "../general/ProductDemoVideoFrame";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { Paths } from "../../utils/pathBuilders.utils";

const Inner = styled.div`
  position: relative;
  ${uptoTabletMd} {
    margin-top: -3em;
  }
`;

const Contents = styled.div`
  position: relative;
  ${uptoTabletMd} {
    padding-top: 6em;
  }
`;

const Header = styled.header`
  p {
    max-width: 16em;
    ${fromPhoneLg} {
      max-width: 22em;
    }
    margin-left: auto;
    margin-right: auto;
  }
`;

const ProductDemoVideoFramePositioner = styled.div`
  ${fromTabletMd} {
    margin-left: ${getWidthPxInMaxGrid(1, true)};
    margin-right: ${getWidthPxInMaxGrid(1, true)};
  }
  > * {
    border-color: ${colors.purple600};
  }
`;

const Footer = styled.footer`
  padding: 4.5em 2em 6em;
  ${fromDesktop} {
    padding: 5em 2em;
  }
  p {
    font-size: 1.6rem;
    line-height: 1.3;
    ${fromTabletMd} {
      font-size: 2.4rem;
      line-height: 1.2;
      font-family: ${serif};
      font-weight: 400;
    }
    max-width: 24em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const GridCanvasWrap = styled.div`
  position: absolute;
  top: 0;
  left: -0.5em;
  right: -0.5em;
  bottom: 0;
  ${fromPhoneLg} {
    left: -1em;
    right: -1em;
  }
  ${fromTabletMd} {
    top: 33%;
    left: 0;
    right: 0;
  }
  border: 1px solid ${colors.purple400};
  border-radius: ${rSize("radius")};
  ${fromDesktopMl} {
    margin-left: ${rSize("gap", -1)};
    margin-right: ${rSize("gap", -1)};
  }
`;

const HomeMeetTheProductSection = () => {
  return (
    <PageSection id="meet-the-product" centered>
      <Inner>
        <GridCanvasWrap>
          <GridCanvas color={colors.purple400} parallax animateIn />
        </GridCanvasWrap>
        <Contents>
          <Header>
            <SectionHeading2 lighter>Meet the product</SectionHeading2>
            <Spacing size="gap" />
            <p>Take two minutes for an overview of the product.</p>
          </Header>
          <Spacing size="lg" />
          <ProductDemoVideoFramePositioner>
            <ProductDemoVideoFrame version="homePage" />
          </ProductDemoVideoFramePositioner>
          <Footer>
            <p>
              Tines is easy to understand and quick to learn: you’ll feel the
              impact from day one. Try it for yourself with our always-free
              community edition.
            </p>
            <Spacing size="lg" />
            <ButtonGroupCenteredUptoDesktop>
              <Button appearance="outlined" to={Paths.product()} color="white">
                Meet the product
              </Button>
            </ButtonGroupCenteredUptoDesktop>
          </Footer>
        </Contents>
      </Inner>
    </PageSection>
  );
};

export default HomeMeetTheProductSection;
