import { styled } from "@linaria/react";
import {
  brandColorThemeVar,
  colors,
  withOpacity,
} from "../../styles/colors.styles";

export const SmallPrintBlack12 = styled.div`
  font-size: 1.2rem;
  color: ${withOpacity(colors.dark500, 0.6)};
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
`;

export const SmallPrintBlack14 = styled.div`
  font-size: 1.4rem;
  line-height: 1.5;
  color: ${withOpacity(colors.dark500, 0.6)};
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
  a {
    font-weight: 600;
    &:hover {
      color: ${brandColorThemeVar(500)};
    }
  }
`;

export const SmallPrint14 = styled.div`
  font-size: 1.4rem;
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
`;

export const SmallPrintWhite14 = styled.div`
  font-size: 1.4rem;
  color: ${withOpacity(colors.white, 0.8)};
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
`;
