import { makeSrcSet2x } from "../../utils/srcset.utils";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import YDWWTWhatSticker from "./YDWWTWhatSticker";

import ImageStickerHearts from "../../images/tines-sticker-hearts.png";
import ImageStickerHearts2x from "../../images/tines-sticker-hearts@2x.png";
import { styled } from "@linaria/react";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import {
  fromPhoneLg,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { font } from "../../styles/fonts.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { GridCanvas } from "../decorations/GridCanvas";
import YDWWTSubmitButtonWithNotice from "./YDWWTSubmitButtonWithNotice";
import { DatoCmsYdwwt } from "../../../graphql-types";

const Outer = styled.div`
  padding-top: 2em;
`;

const Inner = styled.div`
  text-align: center;
  color: ${colors.white};
  padding: 0 ${rSize("cardSectionPadding")} 0 ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  border: 1px solid ${colors.dark500};
  font-weight: 500;
  position: relative;
  article {
    margin-left: auto;
    margin-right: auto;
    max-width: ${getWidthPxInMaxGrid(8)};
    position: relative;
  }
  h2 {
    ${fluidFontSize(32, 46)};
    line-height: 1.1;
    letter-spacing: -0.01em;
    font-family: ${font("serif")};
    font-weight: 400;
    text-align: center;
    + * {
      margin-top: ${rSize("md")};
    }
    ${fromPhoneLg} {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }
`;

const WhatWrapper = styled.div`
  font-size: 4.8rem;
  ${fromTablet} {
    font-size: 6.4rem;
  }
  > * {
    transform: translateY(-38%);
  }
`;

const StickerHeartsImgWrapper = styled.div`
  display: inline-block;
`;

const StickerHeartsImg = styled.img`
  display: block;
  margin-bottom: -40%;
  transform: scale(0.8);
`;

const YDWWTEnterCompetitionSection = (props: { ydwwt: DatoCmsYdwwt }) => {
  return (
    <PageSection>
      <Outer>
        <Inner>
          <GridCanvas color={colors.dark500} parallax />
          <article>
            <WhatWrapper>
              <YDWWTWhatSticker />
            </WhatWrapper>
            <Spacing size="2.5em" />
            <h2>Entering couldn’t be easier</h2>
            <Spacing size="1em" />
            <p>
              It’s as easy as clicking export a story from your current tenant,
              then submitting the story by clicking the button below. New and
              existing Tines users are all welcome!
            </p>
            <Spacing size="3em" />
            <YDWWTSubmitButtonWithNotice ydwwt={props.ydwwt} />
          </article>
          <Spacing size="3em" />
          <StickerHeartsImgWrapper>
            <StickerHeartsImg
              src={ImageStickerHearts}
              srcSet={makeSrcSet2x(ImageStickerHearts, ImageStickerHearts2x)}
              width="215"
              height="159"
            />
          </StickerHeartsImgWrapper>
        </Inner>
      </Outer>
    </PageSection>
  );
};

export default YDWWTEnterCompetitionSection;
