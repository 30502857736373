import { styled } from "@linaria/react";
import {
  PrimaryColorName,
  color,
  withOpacity,
} from "../../../styles/colors.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../../styles/fonts.styles";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";

const Container = styled.div`
  position: relative;
  background-color: var(--acVeryLight);
  color: var(--acDarker);
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
`;

const Inner = styled.div`
  display: grid;
  ${uptoTabletMd} {
    grid-template-rows: auto auto auto;
    grid-template-areas: "icon" "content" "footer";
    text-align: center;
  }
  ${fromTabletMd} {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-areas: "content icon" "footer icon";
  }
  grid-gap: ${rSize("lg")};
  align-items: start;
  svg {
    width: 128px;
    height: 128px;
  }
`;

const Content = styled.div`
  grid-area: content;
  h2 {
    font-family: ${font("serif")};
    font-weight: 400;
    font-size: 2.4rem;
    ${fromTabletMd} {
      font-size: 2.8rem;
    }
  }
  p {
    max-width: 30em;
    font-size: 1.4rem;
    ${fromTabletMd} {
      font-size: 1.6rem;
    }
  }
`;

const Icon = styled.div`
  grid-area: icon;
  align-self: end;
  ${fromTabletMd} {
    transform: translateY(10%);
  }
`;

const Footer = styled.div`
  grid-area: footer;
`;

const UniMoreContentCard = (
  props: PropsWithChildren<{
    color: PrimaryColorName;
    icon: ReactNode;
    button: ReactNode;
  }>
) => {
  return (
    <Container
      style={
        {
          "--acVeryLight": color(props.color, 100),
          "--acDarker": color(props.color, 800),
          "--ac15": withOpacity(color(props.color), 0.15),
        } as CSSProperties
      }
    >
      <Inner>
        <Content>{props.children}</Content>
        <Icon>{props.icon}</Icon>
        <Footer>{props.button}</Footer>
      </Inner>
    </Container>
  );
};

export default UniMoreContentCard;
