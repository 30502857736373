const LogoAccel = () => {
  return (
    <svg width="97" height="31" viewBox="0 0 97 31" fill="currentColor">
      <title>Accel</title>
      <path d="M75.0333 25.5236C74.4044 24.799 73.9255 23.9579 73.624 23.0486L73.5423 22.8199L73.4606 22.5404L73.4096 22.3676L73.3279 22.0474L73.287 21.8594C73.2462 21.6815 73.2104 21.4934 73.1798 21.3054C73.1736 21.2371 73.1634 21.1692 73.1492 21.1021L73.1083 20.7718C73.1164 20.6991 73.1164 20.6259 73.1083 20.5532L73.0828 20.2737L87.3799 16.8433C87.2959 16.1065 87.0761 15.3915 86.7314 14.7342C86.2013 13.5848 85.3391 12.6187 84.2549 11.9593C83.0541 11.2034 81.705 10.7113 80.2977 10.516L80.0577 10.4804C79.2093 10.3871 78.3531 10.3871 77.5047 10.4804C75.2535 10.7227 73.1398 11.6775 71.4743 13.2044C69.8298 14.6937 68.7303 16.6857 68.3494 18.866V18.9524C68.3239 19.0947 68.3035 19.2319 68.2881 19.3894C68.2728 19.547 68.2881 19.5673 68.2575 19.6689C68.2641 19.7467 68.2641 19.8249 68.2575 19.9027C68.2676 20.0381 68.2676 20.174 68.2575 20.3093V20.9649C68.2907 22.1417 68.5399 23.3026 68.9928 24.3903C69.8002 26.2544 71.1344 27.8448 72.8332 28.9681C74.532 30.0914 76.5222 30.6992 78.5616 30.7176C80.2865 30.7523 81.9943 30.3722 83.5401 29.6096C85.069 28.8582 86.388 27.7427 87.3799 26.3621L87.1348 25.7472C86.1238 27.6683 79.1488 30.0569 75.0333 25.5236ZM81.5385 17.0821L73.0368 19.115C73.0368 18.8609 73.0368 18.6068 73.0726 18.3679V18.2917C73.0683 18.2104 73.0683 18.129 73.0726 18.0477L73.1134 17.7174C73.1905 17.1887 73.31 16.6669 73.4708 16.1572C73.7593 15.2114 74.2008 14.3187 74.778 13.5144C75.3045 12.7732 75.9811 12.15 76.7643 11.6849C77.5139 11.2388 78.3725 11.0067 79.2458 11.014C80.4645 11.014 81.3564 11.3528 81.9215 12.0305C82.1272 12.2666 82.299 12.53 82.4321 12.8131C82.9988 14.2514 82.958 16.6451 81.5385 17.0821Z" />
      <path d="M49.5215 25.7307C48.5003 27.6721 41.5355 30.0557 37.42 25.5275C36.7451 24.7447 36.2409 23.8309 35.9392 22.8441C35.5842 21.725 35.4118 20.5565 35.4286 19.3831C35.421 18.2876 35.5829 17.1974 35.9086 16.1509C36.197 15.2051 36.6385 14.3124 37.2158 13.5081C37.733 12.7729 38.397 12.1518 39.1663 11.6836C39.9161 11.2379 40.7745 11.0058 41.6479 11.0128C42.8665 11.0128 43.7584 11.3516 44.3235 12.0292C44.9058 12.7619 45.2064 13.6774 45.1711 14.611C45.1835 15.0958 45.111 15.5791 44.9566 16.0391L49.2968 15.0226C47.719 10.9569 42.4904 10.3928 41.5253 10.3928C33.2432 10.2606 28.6988 18.016 31.3744 24.3332C32.1827 26.1984 33.5176 27.7901 35.2171 28.915C36.9165 30.04 38.9075 30.6498 40.9483 30.6706C42.6732 30.7053 44.381 30.3252 45.9268 29.5627C47.4559 28.8114 48.7748 27.6959 49.7666 26.3152L49.5215 25.7307Z" />
      <path d="M68.3526 25.7313C67.3314 27.6727 60.3667 30.0562 56.2512 25.528C55.5833 24.7504 55.0844 23.8437 54.7857 22.8649C54.4307 21.7459 54.2583 20.5774 54.2751 19.404C54.2675 18.3084 54.4293 17.2182 54.7551 16.1717C55.0418 15.2262 55.4816 14.3336 56.0571 13.529C56.5732 12.7873 57.2371 12.1593 58.0077 11.6842C58.7573 11.2382 59.6159 11.0061 60.4892 11.0133C61.7045 11.0133 62.5964 11.3521 63.1648 12.0298C63.7527 12.7603 64.0587 13.676 64.0278 14.6115C64.0408 15.0968 63.9665 15.5804 63.8082 16.0396L68.1229 15.0232C66.5553 10.9574 61.3266 10.3984 60.3718 10.3984C52.0897 10.2663 47.5452 18.0216 50.2208 24.3388C51.024 26.2088 52.3562 27.8059 54.0551 28.9357C55.7541 30.0654 57.7466 30.679 59.7897 30.7016C61.518 30.738 63.2295 30.3579 64.7784 29.5937C66.3073 28.8422 67.6262 27.7267 68.6182 26.3462L68.3526 25.7313Z" />
      <path d="M93.6426 26.6368V0.717773L87.5153 2.20176H87.4745V2.77605C87.6889 2.77605 89.5424 3.28427 89.5424 4.55481V26.5453C89.5424 29.3202 87.5715 29.7217 86.2388 29.8081V30.2705H96.9463V29.8081C95.6187 29.7217 93.6886 29.3151 93.6426 26.6368Z" />
      <path d="M19.579 0.717773L13.5129 2.19668H13.467V2.64391C13.6814 2.64391 15.4839 3.27918 14.9988 4.42267C12.6959 9.49467 5.22569 26.4233 4.83251 27.1857C3.54066 29.7268 1.70757 29.7268 0.951859 29.8081V30.2705L10.4492 30.2705V29.7979C10.4492 29.7979 6.84432 29.8386 6.84432 27.9632C6.84432 26.8248 8.70805 22.3475 9.21356 21.1227L21.5857 18.1191C21.6163 18.1953 24.9455 26.2201 24.9455 27.9124C24.9455 29.742 21.9891 29.7979 21.8819 29.7979V30.2604H34.2744V29.7979C33.3947 29.8128 32.53 29.5703 31.7875 29.1005C31.045 28.6307 30.4574 27.9543 30.0976 27.1552C29.7249 26.4233 19.579 0.717773 19.579 0.717773ZM21.1568 17.0823L9.73439 19.8572C9.79566 19.7098 16.0098 5.21549 16.1834 4.84957C16.4438 5.45435 21.1517 17.0823 21.1517 17.0823H21.1568Z" />
    </svg>
  );
};

export default LogoAccel;
