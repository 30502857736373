import { DatoCmsSocialProofSection } from "../../../../graphql-types";
import { SocialProofSection } from "../../reusableSections/SocialProofSection";

export const LPSocialProofSection = ({
  section,
}: {
  section: DatoCmsSocialProofSection;
}) => {
  return (
    <SocialProofSection id={section.anchorOnPage} againstBackdrop="light" />
  );
};
