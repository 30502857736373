import { styled } from "@linaria/react";
import { DatoCmsLargeBookADemoFormSection } from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { SectionHeading2 } from "../../typography/SectionHeading2";
import ReactMarkdown from "react-markdown";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { GridCanvas } from "../../decorations/GridCanvas";
import DemoForm from "../../demo/DemoForm";
import Spacing from "../../layout/Spacing";
import {
  fromTablet,
  fromTabletMd,
  uptoTablet,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const Container = styled.div`
  border: 1px solid ${colors.purple200};
  position: relative;
  background-color: ${colors.purple50};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  overflow: hidden;
`;
const Header = styled.header`
  display: grid;
  position: relative;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "illo" "text";
  grid-gap: ${rSize("gap")};
  text-align: center;
  ${fromTabletMd} {
    text-align: left;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas: "text text illo";
  }
`;
const HeaderBody = styled.div`
  margin-top: ${rSize("md")};
  p,
  ul,
  ol,
  li {
    line-height: 1.25;
  }
  li {
    + li {
      margin-top: 0.5em;
    }
  }
  ul,
  ol {
    padding-left: 1.5em;
    ${uptoTabletMd} {
      background-color: ${colors.purple100};
      border: 1px solid ${colors.purple200};
      padding: 0.75em 1em 1em 2em;
      border-radius: ${rSize("radius")};
      text-align: left;
    }
  }
`;
const HeaderTextContents = styled.div`
  grid-area: text;
`;
const HeaderIllo = styled.div`
  grid-area: illo;
  ${fromTabletMd} {
    text-align: right;
  }
`;
const FormWrap = styled.div`
  position: relative;
  background-color: ${withOpacity(colors.purple100, 0.8)};
  ${fromTablet} {
    padding: ${rSize("xl")};
    border-radius: ${rSize("radius")};
    border: 1px solid ${colors.purple200};
    overflow: hidden;
  }
  ${uptoTablet} {
    border-top: 1px solid ${colors.purple300};
    margin: 0 ${rSize("cardSectionPadding", -1)}
      ${rSize("cardSectionPadding", -1)} ${rSize("cardSectionPadding", -1)};
  }
`;
const FormWrapInner = styled.div`
  background-color: ${colors.lightest};
  max-width: 60rem;
  margin: 0 auto;
  border-radius: ${rSize("radius")};
  ${fromTablet} {
    border: 1px solid ${colors.purple300};
  }
`;

export const LPLargeBookADemoFormSection = ({
  section,
}: {
  section: DatoCmsLargeBookADemoFormSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Container>
        <GridCanvas color={colors.purple200} parallax />
        <Header>
          <HeaderTextContents>
            <SectionHeading2 lighter>{section.heading}</SectionHeading2>
            {section.body && (
              <HeaderBody>
                <ReactMarkdown>{section.body}</ReactMarkdown>
              </HeaderBody>
            )}
          </HeaderTextContents>
          {section.illustration?.imageWithNoPadding?.url && (
            <HeaderIllo>
              <img src={section.illustration.imageWithNoPadding.url} />
            </HeaderIllo>
          )}
        </Header>
        <Spacing size="lg" />
        <FormWrap id="book-a-demo">
          <FormWrapInner>
            <DemoForm directlyShowFormOnMobile />
          </FormWrapInner>
        </FormWrap>
      </Container>
    </PageSection>
  );
};
