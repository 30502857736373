import { styled } from "@linaria/react";

const Img = styled.img`
  mix-blend-mode: luminosity;
  opacity: 0.85;
`;

export const BlendedDarkGreyLogoImage = (props: {
  className?: string;
  src: string;
  width?: number | null;
  height?: number | null;
  alt?: string;
}) => {
  return (
    <Img
      className={props.className}
      src={props.src}
      alt={props.alt}
      style={{
        aspectRatio:
          props.width && props.height
            ? `${props.width} / ${props.height}`
            : undefined,
      }}
    />
  );
};
