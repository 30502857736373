export function setSessionStorageItem(key: string, value: unknown): void {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // sessionStorage sometimes being unavailable is expected
    console.error("Can not setItem. SessionStorage is not available"); // eslint-disable-line no-console
  }
}

export function getSessionStorageItem<T>(key: string): T | null {
  try {
    const item = sessionStorage.getItem(key);
    return item !== null ? (JSON.parse(item) as T) : null;
  } catch (e) {
    return null;
  }
}

export function removeSessionStorageItem(key: string): void {
  try {
    sessionStorage.removeItem(key);
  } catch (e) {}
}
