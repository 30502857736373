import { styled } from "@linaria/react";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { ChartAndWiresIllustration } from "../../illustrations/neue/ChartAndWiresIllustration";

const Outer = styled.div`
  margin-top: -4em;
  ${fromTabletLg} {
    margin-top: -8em;
    margin-bottom: -8em;
  }
`;

const Wrap = styled.div`
  position: relative;
  height: 0;
  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    aspect-ratio: 1009 / 363;
    max-width: 90%;
  }
`;

const Sizer = styled.div`
  aspect-ratio: 1009 / 363;
  margin-bottom: 2em;
`;

export const CTADecorationChartAndWires = () => {
  return (
    <Outer>
      <Wrap>
        <ChartAndWiresIllustration />
      </Wrap>
      <Sizer />
    </Outer>
  );
};
