import { styled } from "@linaria/react";
import { ArrayElementType, StoryDescriptor } from "../../types/helper.types";
import StoryEmbedFrame, {
  StoryEmbedFrameInner,
  StoryEmbedFrameWrapper,
} from "./StoryEmbedFrame";
import {
  fromDesktopMd,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { cx } from "linaria";
import { isVideoSrc } from "../../utils/fileType.utils";
import VideoBlock from "../blocks/VideoBlock.block";
import { colors, withOpacity } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { hideScrollbarsCSS } from "../../utils/scroll.utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { cover } from "polished";
import { StoryCardWithEmbedDiv } from "./StoryCardWithEmbed";
import {
  getUrlQueryParam,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";

type Props = {
  story: StoryDescriptor;
  flexibleRatio?: boolean;
  asLightbox?: boolean;
  disableFullScreen?: boolean;
};

export const StoryEmbedAsGalleryWrap = styled.div`
  box-sizing: border-box;
  border-radius: 1em;
  aspect-ratio: 3 / 4;
  font-size: 1.2rem;
  border: 1px solid var(--BorderColor);
  position: relative;
  overflow: hidden;
  ${fromTablet} {
    aspect-ratio: 16 / 9;
  }
  &.flexibleRatio {
    aspect-ratio: unset;
    min-height: 245px;
    ${fromTablet} {
      min-height: 480px;
    }
    ${fromDesktopMd} {
      min-height: 600px;
    }
  }
  @supports not (aspect-ratio: 16 / 9) {
    min-height: 245px;
    ${fromTablet} {
      min-height: 480px;
    }
    ${fromDesktopMd} {
      min-height: 600px;
    }
  }
  ${StoryEmbedFrameWrapper} {
    width: 100%;
    height: 100%;
  }
`;

const SlidesRow = styled.div`
  display: inline-flex;
  min-width: 100%;
  align-items: stretch;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const Slide = styled.div`
  flex: 0 0 100%;
  background-color: ${withOpacity(colors.lightest, 0.8)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.darkest, 0.8)};`
  )};
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  > * {
    border-radius: 0.8em;
  }
  padding-bottom: 9em;
  &.storyboard {
    padding-bottom: 0;
    ${StoryCardWithEmbedDiv} {
      border-radius: 0.8em;
    }
    ${StoryEmbedAsGalleryWrap},
    ${StoryEmbedFrameWrapper} {
      border-radius: 0.8em;
    }
    ${StoryEmbedFrameInner} {
      border-radius: 0.8em;
    }
  }
  &.video {
    background-color: ${colors.darkest};
  }
  &.video,
  &.image {
    padding-top: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    ${fromTablet} {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
`;

const GalleryNav = styled.div`
  position: absolute;
  bottom: 1em;
  left: 1em;
  max-width: calc(100% - 1em);
  display: grid;
  overflow: auto;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 0.5em;
  padding: 6px 3px 3px 3px;
  button {
    appearance: none;
    width: 6.25em;
    height: 6.25em;
    padding: 0.5em;
    font-weight: 700;
    border: 1px solid var(--ac30);
    border-radius: 8px;
    color: var(--ac600);
    ${darkModeLinariaCSS(`color: var(--ac100);`)}
    text-align: left;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    position: relative;
    cursor: pointer;
    font-size: inherit;
    transition: 0.1s;
    overflow: hidden;
    &:before {
      content: "";
      display: block;
      ${cover()};
      background-color: var(--ac10030, ${withOpacity(colors.purple100, 0.3)});
      border-radius: 3px;
    }
    &.video {
      color: ${colors.white};
      &:before {
        background-color: var(--ac70, ${withOpacity(colors.purple, 0.7)});
      }
    }
    svg {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
    }
    span {
      position: relative;
      text-wrap: balance; // supported in Chrome but not in safari
    }
    &:hover {
      border-color: var(--ac);
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
    }
    &.active {
      border-color: var(--ac);
      box-shadow: 0 0 3px var(--ac);
    }
  }
`;

const ImageWrap = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  max-height: 100%;
  img {
    display: block;
    max-width: 100%;
    max-height: calc(100vh - 25rem);
    opacity: 0;
    object-fit: contain;
    object-position: center;
  }
`;

const isVideoSlide = (slide: ArrayElementType<StoryDescriptor["gallery"]>) => {
  return slide.isEmbedLink || isVideoSrc(slide.url);
};

const StoryEmbedAsGallery = (p: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const hasGalleryItems = p.story.gallery.length > 0;
  const [width, setWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const translateX = activeIndex * width * -1;
  const storySlide = useMemo(
    () => (
      <Slide className="storyboard" data-slide-index={0}>
        <StoryEmbedFrame
          story={p.story}
          frameless
          flexibleRatio={p.flexibleRatio}
          disableFullScreen={p.disableFullScreen || p.asLightbox}
        />
      </Slide>
    ),
    [p.asLightbox, p.disableFullScreen, p.flexibleRatio, p.story]
  );
  useOnMount(() => {
    if (getUrlQueryParam("video-intro") === "true") {
      const videoSlideIndex = p.story.gallery.findIndex(isVideoSlide);
      if (videoSlideIndex >= 0) setActiveIndex(videoSlideIndex + 1);
    }
    const handleResize = () => {
      setWidth(containerRef.current?.clientWidth ?? 0);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      removeUrlQueryParam("video-intro");
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    const currentSlide = p.story.gallery[activeIndex - 1];
    if (!currentSlide) {
      removeUrlQueryParam("video-intro");
      return;
    }
    if (isVideoSlide(currentSlide)) {
      setUrlQueryParam("video-intro", "true");
    } else {
      removeUrlQueryParam("video-intro");
    }
  });
  const gallerySlides = useMemo(
    () => (
      <>
        {p.story.gallery.map((item, i) => {
          const isVideo = isVideoSlide(item);
          return (
            <Slide
              key={i}
              className={isVideo ? "video" : "image"}
              data-slide-index={i + 1}
            >
              {isVideo ? (
                <VideoBlock url={item.url} />
              ) : (
                <ImageWrap
                  style={{
                    backgroundImage: `url(${item.url})`,
                  }}
                >
                  <img
                    src={item.url}
                    style={{
                      aspectRatio: `${item.width} / ${item.height}`,
                      width: item.width,
                      height: item.height,
                    }}
                  />
                </ImageWrap>
              )}
            </Slide>
          );
        })}
      </>
    ),
    [p.story.gallery]
  );
  return (
    <StoryEmbedAsGalleryWrap
      className={cx(
        hasGalleryItems ? "hasGalleryItems" : "storyboardOnly",
        p.flexibleRatio && "flexibleRatio"
      )}
      ref={containerRef}
    >
      <SlidesRow
        className={hideScrollbarsCSS}
        ref={rowRef}
        style={{
          transform: translateX ? `translateX(${translateX}px)` : undefined,
        }}
      >
        {hasGalleryItems ? (
          <>
            {storySlide}
            {gallerySlides}
          </>
        ) : (
          storySlide
        )}
      </SlidesRow>

      {hasGalleryItems && (
        <GalleryNav>
          <button
            onClick={() => setActiveIndex(0)}
            className={cx(activeIndex === 0 && "active")}
          >
            <span>View storyboard</span>
          </button>
          {p.story.gallery.map((item, i) => {
            const isVideo = isVideoSlide(item);
            return (
              <button
                className={cx(
                  isVideo ? "video" : "image",
                  activeIndex === i + 1 && "active"
                )}
                onClick={() => setActiveIndex(i + 1)}
                key={i}
                style={{
                  backgroundImage: !isVideo ? `url(${item.url})` : undefined,
                }}
              >
                {isVideo && (
                  <svg
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="currentColor"
                  >
                    <path d="M0 9.56923V1.6447C0 0.45532 1.27517 -0.298654 2.31733 0.274532L9.30957 4.12026C10.3743 4.70584 10.3935 6.2289 9.3439 6.84116L2.35166 10.92C1.30918 11.5281 0 10.7761 0 9.56923Z" />
                  </svg>
                )}
                <span>{isVideo && "Video intro"}</span>
              </button>
            );
          })}
        </GalleryNav>
      )}
    </StoryEmbedAsGalleryWrap>
  );
};

export default StoryEmbedAsGallery;
