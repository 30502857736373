import { styled } from "@linaria/react";
import { cx } from "linaria";
import React, { RefObject, useRef } from "react";
import {
  brandColorThemeVar,
  color,
  withOpacity,
} from "../../styles/colors.styles";
import { UnknownObject } from "../../types/helper.types";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import { InputLikeProps, formInputControls } from "./TextInput";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";

export type SelectOptionConfig = {
  label: string;
  value: string;
  disabled?: boolean;
};
export type SelectOptgroupConfig = {
  label: string;
  options: SelectOptionConfig[] | string[];
};

export const prepareSelectOptions = (
  options: string[] | SelectOptionConfig[]
): SelectOptionConfig[] => {
  if (typeof options[0] === "string") {
    return (options as string[]).map(option => ({
      label: option,
      value: option,
    }));
  } else {
    return options as SelectOptionConfig[];
  }
};

export type SelectProps<T extends object> = {
  options?: SelectOptionConfig[] | string[];
  optgroups?: SelectOptgroupConfig[];
  onValueHadChanged?: () => void;
} & React.SelectHTMLAttributes<HTMLSelectElement> &
  InputLikeProps<T> & {
    innerRef?: RefObject<HTMLSelectElement>;
  };

export const SelectWrapper = styled.span<InputLikeProps<UnknownObject>>`
  position: relative;
  display: block;
  border-radius: ${p => valueWithOptionalUnit(p.borderRadius, `.5em`)};
  text-align: left;
  select {
    display: block;
    appearance: none;
    background-color: ${p => color(p.backgroundColor ?? "transparent")};
    color: ${p => color(p.textColor ?? "inherit")};
    width: ${p => valueWithOptionalUnit(p.width, "100%")};
    min-width: ${p => valueWithOptionalUnit(p.minWidth, "unset")};
    max-width: ${p => valueWithOptionalUnit(p.maxWidth, "unset")};
    height: ${p => valueWithOptionalUnit(p.height, "3em")};
    padding: ${p => valueWithOptionalUnit(p.padding, "0 2em 0 1em")};
    border: ${p => p.border ?? `1px solid ${brandColorThemeVar(200)}`};
    [data-dark-mode="true"] & {
      border-color: ${brandColorThemeVar(700)};
    }
    border-radius: inherit;
    outline-color: ${p => color(p.focusRingColor ?? brandColorThemeVar(500))};
    font-size: 1.6rem;
    ${fromTablet} {
      font-size: 1.4rem;
    }
    &:hover {
      background-color: ${p =>
        color(
          p.hoverStyle?.backgroundColor ?? p.backgroundColor ?? "transparent"
        )};
      border: ${p =>
        p.hoverStyle?.border ??
        p.border ??
        `1px solid ${brandColorThemeVar(300)}`};
      [data-dark-mode="true"] & {
        border-color: ${brandColorThemeVar(600)};
      }
      color: ${p => color(p.hoverStyle?.textColor ?? p.textColor ?? "inherit")};
    }
    &::placeholder {
      color: ${p => withOpacity(color(p.textColor ?? "inherit"), 0.4)};
    }
  }
  &.empty {
    color: ${p => withOpacity(color(p.textColor ?? "inherit"), 0.4)};
  }
  svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0.75em;
    transform: translateY(-50%);
  }
`;

function Select<T extends object>(props: SelectProps<T>) {
  const {
    appearance,
    backgroundColor,
    border,
    borderRadius,
    className,
    focusRingColor,
    formState,
    hasError,
    height,
    hoverStyle,
    maxWidth,
    minWidth,
    options,
    padding,
    textColor,
    width,
    onValueChange,
    onChange,
    innerRef,
    onValueHadChanged: _onValueHadChanged,
    ...restOfProps
  } = props;

  const controlProps =
    formState && props.name && props.name in formState[0]
      ? formInputControls(formState, props.name as keyof T, props)
      : null;

  const valueRef = useRef(controlProps?.value);
  valueRef.current = controlProps?.value;

  const optionsMapped = prepareSelectOptions(options ?? []).map((option, i) => (
    <option
      value={option.value}
      key={`${option.value}-${i}`}
      disabled={option.disabled}
    >
      {option.label}
    </option>
  ));

  const optionGroupsMapped = props.optgroups?.map((group, i) => (
    <optgroup label={group.label} key={`${group.label}-${i}`}>
      {prepareSelectOptions(group.options).map((option, j) => (
        <option
          value={option.value}
          key={`${option.value}-${j}`}
          disabled={option.disabled}
        >
          {option.label}
        </option>
      ))}
    </optgroup>
  ));

  const handleValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange?.(e);
    else controlProps?.onChange?.(e);
    onValueChange?.(
      controlProps?.value ?? (e.target as HTMLSelectElement).value
    );
    props.onValueHadChanged?.();
  };

  return (
    <SelectWrapper
      ref={innerRef}
      name={props.name}
      appearance={appearance}
      hasError={hasError}
      backgroundColor={backgroundColor}
      textColor={textColor}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      height={height}
      padding={padding}
      border={border}
      borderRadius={borderRadius}
      focusRingColor={focusRingColor}
      hoverStyle={hoverStyle}
      className={cx("Select", className, !controlProps?.value && "empty")}
    >
      <select {...controlProps} {...restOfProps} onChange={handleValueChange}>
        {optionsMapped}
        {optionGroupsMapped}
      </select>
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <path d="M1.04785 1L5.04785 5L9.04785 1" />
      </svg>
    </SelectWrapper>
  );
}

export default Select;
