import { Fragment, ReactNode } from "react";
import { StoryDescriptor } from "../../types/helper.types";
import { externalLinkAttr } from "../../constants/externalLinks.constants";

type Props = {
  story: StoryDescriptor;
  linkToLinkedInIfAvailable?: boolean;
};

const StoryInternalAuthorsDisplay = (props: Props) => {
  const components = props.story.internalAuthors.map((a, i) => {
    const fullName = [a.name, a.surname].filter(i => i).join(" ");
    return props.linkToLinkedInIfAvailable && a.linkedin ? (
      <a key={i} href={a.linkedin} {...externalLinkAttr}>
        {fullName}
      </a>
    ) : (
      <strong key={i}>{fullName}</strong>
    );
  });
  return (
    <>
      {components.reduce(
        (arr, next, i) => [
          ...arr,
          i > 0 ? (
            <Fragment key={`and-${i}`}>
              {i === components.length - 1 ? " and" : ","}{" "}
            </Fragment>
          ) : null,
          next,
        ],
        [] as ReactNode[]
      )}
    </>
  );
};

export default StoryInternalAuthorsDisplay;
