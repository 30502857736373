import ReportKeyFindingsSection from "../../ReportKeyFindingsSection";

const SOCKeyFindingsSection = () => {
  return (
    <ReportKeyFindingsSection openingParagraph="Here are a few of the insights we learned from the security analysts we surveyed:">
      <li>
        <h4>71% of analysts experience some level of burnout.</h4>
        <p>
          This could be due to the fact that 69% are understaffed, and 60% have
          seen increased workloads over the past year
        </p>
      </li>
      <li>
        <h4>
          Reporting, monitoring, and detection are top tasks consuming an
          analyst’s time.
        </h4>
        <p>
          Additionally, reporting and monitoring are also two of the top tasks
          analysts enjoy the least.
        </p>
      </li>
      <li>
        <h4>Spending time on manual work is an analyst’s top frustration.</h4>
        <p>64% are spending over half their time on tedious manual work.</p>
      </li>
      <li>
        <h4>
          However, 66% believe that half of their tasks to all of their tasks
          could be automated today.
        </h4>
        <p>
          If they could automate their tasks, analysts would use the time to
          update operational documentation, develop advanced detection rules,
          integrate more systems and logs, focus more on intelligence, and
          modify alert rules to reduce false positives.
        </p>
      </li>
      <li>
        <h4>64% say they’re likely to switch jobs in the next year.</h4>
        <p>
          However, organizations could retain them by providing tools that
          automate tedious manual tasks, providing best-in-breed tools with
          advanced capabilities, and hiring more people to the team.
        </p>
      </li>
      <li>
        <h4>Coding is the top skill needed to succeed as an analyst.</h4>
        <p>
          This is likely due to the need to know code to automate processes.
          Other top skills our respondents say analysts need for the future are
          knowing computer forensics techniques and knowing how to
          operationalize MITRE ATT&CK.
        </p>
      </li>
    </ReportKeyFindingsSection>
  );
};

export default SOCKeyFindingsSection;
