import { colors } from "../../../../styles/colors.styles";

export const StatBuildingsIcon = () => (
  <svg
    width="102"
    height="94"
    viewBox="0 0 102 94"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    strokeLinecap="round"
  >
    <path
      d="M51.8418 93.0599C78.6652 93.0599 100.41 84.5603 100.41 74.0755C100.41 63.5907 78.6652 55.0911 51.8418 55.0911C25.0183 55.0911 3.27356 63.5907 3.27356 74.0755C3.27356 84.5603 25.0183 93.0599 51.8418 93.0599Z"
      fill={colors.purple800}
      stroke={colors.purple800}
    />
    <path
      d="M39.8266 11.3906H79.3038C77.3745 5.49899 60.6986 0.894775 40.4109 0.894775C29.7591 0.894775 20.1048 2.16431 13.0549 4.22265L39.8266 11.3906Z"
      fill="#FDB2D2"
      stroke={colors.purple800}
    />
    <path
      d="M39.6589 77.0017H79.3041V11.3908H39.8266L13.0549 4.2229V65.5176L39.6589 77.0017Z"
      fill="#EB81AF"
      stroke={colors.purple800}
    />
    <path d="M39.8266 11.3906V77.0015" stroke={colors.purple800} />
    <path
      d="M10.4353 86.5393C4.75139 83.9581 1.33008 80.6322 1.33008 77.002V33.0345L39.6586 29.7649V72.8413L10.4353 86.5393Z"
      fill="#FABE64"
      stroke={colors.purple800}
    />
    <path
      d="M1.33008 32.1488C1.33008 35.2541 6.02717 38.133 10.4353 40.5014L39.6586 29.5339L13.055 21.3242C5.19697 24 1.33008 27.8591 1.33008 32.1488Z"
      fill="#FFD18C"
      stroke={colors.purple800}
    />
    <path d="M10.4353 40.5017V86.1165" stroke={colors.purple800} />
    <path
      d="M71.4182 86.05L39.6586 72.0184V38.4983L79.4916 43.2007V77.0019C79.4919 80.4066 76.4815 83.5439 71.4182 86.05Z"
      fill="#5178B3"
      stroke={colors.purple800}
    />
    <path
      d="M71.4182 49.3804C77.1693 47.636 78.3601 45.5054 79.3385 43.5766C79.8327 42.6022 79.596 40.1049 79.3041 38.0884L39.8052 38.4982L71.4182 49.3804Z"
      fill="#96BEFA"
      stroke={colors.purple800}
    />
    <path d="M71.4183 86.0498V49.3804" stroke={colors.purple800} />
    <path
      d="M51.1611 22.2379C51.1637 23.0267 50.5258 23.6681 49.7358 23.671L47.8857 23.6769C47.095 23.6798 46.4529 23.0426 46.4504 22.2535L46.4345 17.3655C46.4315 16.5767 47.0697 15.9353 47.8594 15.933L49.7105 15.9265C50.5005 15.9236 51.1419 16.5608 51.1449 17.3496L51.1611 22.2379Z"
      fill="#FDB2D2"
    />
    <path
      d="M58.5213 22.2137C58.5239 23.0028 57.886 23.6442 57.0964 23.6461L55.2453 23.6526C54.4553 23.6552 53.8138 23.018 53.8112 22.2295L53.7944 17.3416C53.7921 16.5524 54.43 15.9113 55.22 15.9091L57.0711 15.9026C57.8607 15.9 58.5025 16.5372 58.5054 17.3257L58.5213 22.2137Z"
      fill="#FDB2D2"
    />
    <path
      d="M65.8819 22.1895C65.8845 22.9786 65.2466 23.62 64.4566 23.6223L62.6052 23.6284C61.8155 23.6314 61.1737 22.9942 61.1705 22.2054L61.1546 17.3174C61.1517 16.5286 61.7899 15.8872 62.5792 15.8843L64.4313 15.8784C65.2206 15.8758 65.8627 16.513 65.8653 17.3018L65.8819 22.1895Z"
      fill="#FDB2D2"
    />
    <path
      d="M73.2418 22.1649C73.2444 22.9537 72.6065 23.5955 71.8165 23.5977L69.9651 23.6039C69.1758 23.6065 68.5334 22.9693 68.5311 22.1805L68.5145 17.2928C68.5119 16.5037 69.1498 15.8623 69.9398 15.8597L71.7912 15.8535C72.5806 15.8509 73.2227 16.4884 73.2256 17.2769L73.2418 22.1649Z"
      fill="#FDB2D2"
    />
    <path
      d="M51.1941 32.8153C51.197 33.6041 50.5588 34.2455 49.7688 34.2481L47.9181 34.2543C47.1281 34.2565 46.4866 33.6197 46.4837 32.8309L46.4678 27.9425C46.4649 27.1541 47.1028 26.5123 47.8928 26.5101L49.7435 26.5039C50.5335 26.501 51.1756 27.1385 51.1782 27.9273L51.1941 32.8153Z"
      fill="#FDB2D2"
    />
    <path
      d="M58.5547 32.7909C58.5573 33.5797 57.9188 34.2211 57.1294 34.2237L55.2787 34.2298C54.4887 34.2324 53.8466 33.5949 53.844 32.8064L53.8275 27.9181C53.8249 27.1297 54.4628 26.4879 55.2527 26.4857L57.1035 26.4795C57.8935 26.4766 58.5356 27.1138 58.5382 27.9029L58.5547 32.7909Z"
      fill="#FDB2D2"
    />
    <path
      d="M65.9145 32.7668C65.9171 33.5556 65.2792 34.1973 64.4892 34.1993L62.6382 34.2054C61.8485 34.2087 61.2064 33.5708 61.2038 32.7823L61.1876 27.8947C61.1847 27.1056 61.8226 26.4642 62.6125 26.4616L64.4639 26.4551C65.2533 26.4525 65.8957 27.0897 65.898 27.8788L65.9145 32.7668Z"
      fill="#FDB2D2"
    />
    <path
      d="M73.2744 32.7423C73.2773 33.5311 72.6395 34.1725 71.8495 34.1751L69.9987 34.1813C69.2087 34.1835 68.5666 33.5466 68.564 32.7578L68.5475 27.8702C68.5452 27.0811 69.1834 26.4396 69.9728 26.4374L71.8242 26.4309C72.6138 26.4283 73.2556 27.0658 73.2586 27.8546L73.2744 32.7423Z"
      fill="#FDB2D2"
    />
    <path
      d="M71.4182 74.1728L39.8266 61.7156L40.5816 73.9989L39.8266 73.7037L41.9559 91.8524C53.6092 91.8524 64.6647 89.9586 71.4182 86.0499V74.1728Z"
      fill="#8173E6"
      stroke={colors.purple800}
    />
    <path
      d="M71.4182 74.1728L39.8266 61.7156L40.9934 80.7C54.1014 80.5306 64.6651 78.0818 71.4182 74.1728Z"
      fill="#B0A6F9"
      stroke={colors.purple800}
    />
    <path
      d="M10.4353 86.539C17.586 90.0344 27.8643 91.865 40.4113 91.865C40.9289 91.865 41.4432 91.8598 41.9562 91.8523V67.7137C41.4432 67.7062 17.5864 59.0517 10.4356 62.5471V86.539H10.4353Z"
      fill="#49B889"
      stroke={colors.purple800}
    />
    <path
      d="M40.4113 67.7252C40.9288 67.7252 41.4432 67.7204 41.9562 67.7139L39.6589 48.2449L10.4356 62.547C17.586 65.698 27.8642 67.7252 40.4113 67.7252Z"
      fill="#81D8B4"
      stroke={colors.purple800}
    />
    <path
      d="M49.8246 50.7119C49.8246 51.0987 49.5256 51.3112 49.1601 51.1843L45.6402 49.9636C45.2747 49.8366 44.9757 49.4165 44.9757 49.0297V45.3367C44.9757 44.9499 45.2747 44.7374 45.6402 44.8644L49.1601 46.0854C49.5256 46.2124 49.8246 46.6325 49.8246 47.0193V50.7119Z"
      fill="#96BEFA"
    />
    <path
      d="M57.2189 53.2997C57.2189 53.6861 56.9193 53.899 56.5538 53.7723L53.0349 52.5514C52.6691 52.4244 52.3698 52.0042 52.3698 51.6174V47.9248C52.3698 47.538 52.6691 47.3255 53.0349 47.4522L56.5538 48.6731C56.9193 48.8001 57.2189 49.2202 57.2189 49.607V53.2997Z"
      fill="#96BEFA"
    />
    <path
      d="M64.9388 55.9507C64.9388 56.3375 64.6395 56.55 64.2737 56.423L60.7544 55.2024C60.3886 55.0751 60.0893 54.6552 60.0893 54.2684V50.5758C60.0893 50.189 60.3886 49.9762 60.7544 50.1032L64.2737 51.3241C64.6395 51.4511 64.9388 51.8713 64.9388 52.258V55.9507Z"
      fill="#96BEFA"
    />
    <path
      d="M49.8246 60.2952C49.8246 60.682 49.5256 60.8945 49.1601 60.7675L45.6402 59.5469C45.2747 59.4199 44.9757 58.9998 44.9757 58.613V54.9204C44.9757 54.5336 45.2747 54.3207 45.6402 54.448L49.1601 55.6687C49.5256 55.7956 49.8246 56.2158 49.8246 56.6026V60.2952Z"
      fill="#96BEFA"
    />
    <path
      d="M57.2189 62.8834C57.2189 63.2699 56.9193 63.4827 56.5538 63.356L53.0349 62.1351C52.6691 62.0081 52.3698 61.5883 52.3698 61.2012V57.5085C52.3698 57.1218 52.6691 56.9092 53.0349 57.0359L56.5538 58.2569C56.9193 58.3838 57.2189 58.804 57.2189 59.1908V62.8834Z"
      fill="#96BEFA"
    />
    <path
      d="M64.9388 65.5344C64.9388 65.9211 64.6395 66.134 64.2737 66.007L60.7544 64.786C60.3886 64.6587 60.0893 64.2389 60.0893 63.8521V60.1595C60.0893 59.7727 60.3886 59.5602 60.7544 59.6872L64.2737 60.9078C64.6395 61.0348 64.9388 61.4549 64.9388 61.8417V65.5344Z"
      fill="#96BEFA"
    />
    <path
      d="M24.5662 48.4407C24.5662 49.0209 24.1401 49.705 23.6193 49.9616L16.8901 53.2768C16.3696 53.5334 15.9432 53.2684 15.9432 52.6886V44.9567C15.9432 44.3765 16.3881 43.7354 16.932 43.532L23.5774 41.0451C24.1213 40.8413 24.5662 41.1494 24.5662 41.7295V48.4407Z"
      fill="#B38847"
    />
    <path
      d="M35.7109 42.9626C35.7109 43.5428 35.2855 44.2273 34.7643 44.4842L28.0355 47.7988C27.5144 48.0553 27.0886 47.7907 27.0886 47.2105V40.7384C27.0886 40.1582 27.5338 39.5168 28.0773 39.3134L34.7222 36.8268C35.2663 36.623 35.7109 36.9311 35.7109 37.5113V42.9626Z"
      fill="#B38847"
    />
    <path
      d="M20.9143 17.4895C20.9143 18.4562 20.1496 19.0428 19.2144 18.7934L18.1714 18.5148C17.2362 18.265 16.4711 17.2699 16.4711 16.3029V11.7764C16.4711 10.8098 17.2362 10.2228 18.1714 10.4726L19.2144 10.7508C20.1496 11.0006 20.9143 11.9961 20.9143 12.9627V17.4895Z"
      fill="#D66B99"
    />
    <path
      d="M27.8358 19.3646C27.8358 20.3312 27.0707 20.9179 26.1358 20.6681L25.0925 20.3895C24.1576 20.1398 23.3926 19.1446 23.3926 18.1777V13.6512C23.3926 12.6846 24.1576 12.0976 25.0925 12.3473L26.1358 12.6259C27.0707 12.8757 27.8358 13.8708 27.8358 14.8378V19.3646Z"
      fill="#D66B99"
    />
    <path
      d="M35.2852 21.3563C35.2852 22.323 34.5201 22.91 33.5852 22.6602L32.5419 22.3819C31.607 22.1322 30.842 21.137 30.842 20.17V15.6436C30.842 14.6769 31.607 14.0903 32.5419 14.34L33.5852 14.6183C34.5201 14.8681 35.2852 15.8635 35.2852 16.8302V21.3563Z"
      fill="#D66B99"
    />
  </svg>
);
