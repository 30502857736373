import { styled } from "@linaria/react";
import { DatoCmsSpotlightStorySection } from "../../../../graphql-types";
import StoryEmbed from "../../blocks/StoryEmbed.block";
import { convertDatoRecordToStoryDescriptor } from "../../../utils/story.utils";
import { StoryCardWithEmbedDiv } from "../StoryCardWithEmbed";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";

const Wrap = styled.div`
  ${StoryCardWithEmbedDiv} {
    ${fromTablet} {
      max-height: 720px;
    }
  }
`;

const SpotlightStorySection = ({
  section,
}: {
  section: DatoCmsSpotlightStorySection;
}) => {
  return (
    <Wrap id="spotlight-story">
      {section.story && (
        <StoryEmbed
          descriptor={convertDatoRecordToStoryDescriptor(section.story)}
          fullWidth
        />
      )}
    </Wrap>
  );
};

export default SpotlightStorySection;
