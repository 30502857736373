import React, { useRef } from "react";
import { Note } from "../../types/tines.types";
import { resolveAfter } from "../../utils/promises.utils";
import NoteEntry from "./NoteEntry";
import { stretchToGridUnit } from "./utils/storyboard.utils";
import { useStoryboardContext } from "./StoryboardContext";
import { getNotePositionInViewport } from "./utils/notes.utils";

type Props = {
  note: Note;
  isSelected?: boolean;
};

const StoryboardNote = ({ note, isSelected }: Props) => {
  const context = useStoryboardContext();
  const positionInViewport = getNotePositionInViewport(note, context);
  const ref = useRef<HTMLDivElement>(null);
  const recordDimensions = async () => {
    await resolveAfter();
    if (!note.width)
      note.width = note.record.width = stretchToGridUnit(
        ref.current?.clientWidth ?? 0
      );
    if (!note.height)
      note.height = note.record.height = stretchToGridUnit(
        ref.current?.clientHeight ?? 0
      );
    await resolveAfter();
  };
  return (
    <NoteEntry
      note={note}
      isSelected={isSelected}
      innerRef={ref}
      onAllAssetProcessed={recordDimensions}
      style={{
        position: "absolute",
        left: positionInViewport.x,
        top: positionInViewport.y,
      }}
    />
  );
};

export default StoryboardNote;
