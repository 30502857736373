import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import dayjs from "dayjs";
import { darken } from "polished";
import { useRef, useState } from "react";
import { useSiteContext } from "../../context/site.context";
import { colors, withOpacity } from "../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { scrollIntoViewIfNotVisible } from "../../utils/scroll.utils";
import LinkToAnchor from "../basic/LinkToAnchor";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import { AdminToolBarWithEditInDatoCmsButton } from "../site/AdminToolbar";
import { fromDesktopMd } from "../../styles/breakpointsAndMediaQueries.styles";

export type ProductUpdateTimelineEntry = {
  id: string;
  title: string;
  slug: string;
  date: string;
  category: { slug: string };
};

type Props = {
  entries: ProductUpdateTimelineEntry[];
  focusedEntry?: ProductUpdateTimelineEntry | null;
  onTimelineEntryClick: (entry: ProductUpdateTimelineEntry) => void;
};

export const ProductUpdateTimelineWrap = styled.div`
  @keyframes timelineEnter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: timelineEnter 0.2s;
  ${fromDesktopMd} {
    margin-right: -2em;
  }
`;

const ProductUpdateTimeline = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [currentId, setCurrentId] = useState("");
  useOnMount(() => {
    const handleScroll = () => {
      const activeLink =
        ref.current?.querySelector<HTMLAnchorElement>("a.active");
      if (activeLink) {
        scrollIntoViewIfNotVisible(activeLink);
        const id = activeLink.getAttribute("data-id");
        if (id) setCurrentId(id);
      }
    };
    setTimeout(handleScroll, 500);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <ProductUpdateTimelineWrap ref={ref}>
      <AdminToolBarWithEditInDatoCmsButton
        itemType="productUpdate"
        recordId={currentId}
      />
      {props.entries.map(entry => (
        <ProductUpdateTimelineEntry
          key={entry.id}
          entry={entry}
          onClick={props.onTimelineEntryClick}
        />
      ))}
    </ProductUpdateTimelineWrap>
  );
};

const entryStyle = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 1em 1em;
  position: relative;
  border-radius: 1em;
  transition: background-color 0.1s;
  h4 {
    font-size: inherit;
    font-weight: 500;
    transition: color 0.1s;
  }
  time {
    display: block;
    font-size: 85%;
    opacity: 0.8;
  }
  &:before {
    content: "";
    flex: 0 0 auto;
    width: 8px;
    height: 8px;
    box-sizing: content-box;
    border-radius: 9px;
    background-color: ${colors.purple300};
    border: 2px solid ${colors.purple100};
    ${darkModeLinariaCSS(`
      background-color: ${colors.purple};
      border-color: ${darken(0.1, colors.purple700)};
    `)}
    display: block;
    margin-right: 0.5em;
    filter: grayscale(1);
    transition: filter 0.1s;
    z-index: 1;
  }
  &:after {
    content: "";
    width: 2px;
    height: calc(50% + 2.75em);
    background-color: ${colors.light400};
    ${darkModeLinariaCSS(`
      background-color: ${colors.dark500};
    `)}
    position: absolute;
    top: 2em;
    left: 1em;
    margin: 5px;
  }
  &.active {
    background-color: ${withOpacity(colors.light400, 0.4)};
    ${darkModeLinariaCSS(`background-color: ${withOpacity("#5E5B76", 0.2)};`)}
    h4 {
      color: ${colors.purple};
    }
    &:before {
      background-color: ${colors.purple};
      filter: grayscale(0);
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`;

const EntryInner = styled.div`
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
`;

const ProductUpdateTimelineEntry = (props: {
  entry: ProductUpdateTimelineEntry;
  onClick: (entry: ProductUpdateTimelineEntry) => void;
}) => {
  const siteContext = useSiteContext();
  return (
    <LinkToAnchor
      className={entryStyle}
      to={`#${props.entry.slug}`}
      onClick={() => {
        siteContext.toggleSidebar(false);
        props.onClick(props.entry);
      }}
      doNotPushState
      data-id={props.entry.id}
    >
      <EntryInner>
        <WithCustomInlineFormatting as="h4">
          {props.entry.title}
        </WithCustomInlineFormatting>
        <time dateTime={props.entry.date}>
          {dayjs(`${props.entry.date}`).format("MMMM Do, YYYY")}
        </time>
      </EntryInner>
    </LinkToAnchor>
  );
};
export default ProductUpdateTimeline;
