export const SlackIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      d="M28 0H8C3.58172 0 0 3.58172 0 8V28C0 32.4183 3.58172 36 8 36H28C32.4183 36 36 32.4183 36 28V8C36 3.58172 32.4183 0 28 0Z"
      fill="#4A154B"
    />
    <path
      d="M11.4139 21.0663C11.4139 22.3674 10.3596 23.4217 9.05852 23.4217C7.75744 23.4217 6.70312 22.3674 6.70312 21.0663C6.70312 19.7653 7.75744 18.7109 9.05852 18.7109H11.4139V21.0663Z"
      fill="#E01E5A"
    />
    <path
      d="M12.5859 21.0663C12.5859 19.7653 13.6403 18.7109 14.9413 18.7109C16.2424 18.7109 17.2967 19.7653 17.2967 21.0663V26.9212C17.2967 28.2222 16.2424 29.2766 14.9413 29.2766C13.6403 29.2766 12.5859 28.2222 12.5859 26.9212V21.0663Z"
      fill="#E01E5A"
    />
    <path
      d="M14.9101 11.6884C13.609 11.6884 12.5547 10.634 12.5547 9.33296C12.5547 8.03189 13.6314 7 14.9101 7C16.1887 7 17.2655 8.05432 17.2655 9.3554V11.7108H14.9101V11.6884Z"
      fill="#36C5F0"
    />
    <path
      d="M14.9134 12.8828C16.2144 12.8828 17.2688 13.9371 17.2688 15.2382C17.2688 16.5393 16.2144 17.5936 14.9134 17.5936H9.05852C7.75744 17.5936 6.70312 16.5393 6.70312 15.2382C6.70312 13.9371 7.75744 12.8828 9.05852 12.8828H14.9134Z"
      fill="#36C5F0"
    />
    <path
      d="M24.2891 15.207C24.2891 13.9059 25.3434 12.8516 26.6445 12.8516C27.9455 12.8516 28.9999 13.9059 28.9999 15.207C28.9999 16.508 27.9455 17.5624 26.6445 17.5624H24.2891V15.207Z"
      fill="#2EB67D"
    />
    <path
      d="M23.1259 15.2102C23.1259 16.5113 22.0715 17.5656 20.7705 17.5656C19.4694 17.5656 18.4375 16.5113 18.4375 15.2102V9.3554C18.4375 8.05432 19.4918 7 20.7929 7C22.094 7 23.1483 8.05432 23.1483 9.3554V15.2102H23.1259Z"
      fill="#2EB67D"
    />
    <path
      d="M20.7929 24.5938C22.094 24.5938 23.1483 25.6481 23.1483 26.9491C23.1483 28.2502 22.094 29.3045 20.7929 29.3045C19.4918 29.3045 18.4375 28.2502 18.4375 26.9491V24.5938H20.7929Z"
      fill="#ECB22E"
    />
    <path
      d="M20.7929 23.4217C19.4918 23.4217 18.4375 22.3674 18.4375 21.0663C18.4375 19.7653 19.4918 18.7109 20.7929 18.7109H26.6477C27.9488 18.7109 29.0031 19.7653 29.0031 21.0663C29.0031 22.3674 27.9488 23.4217 26.6477 23.4217H20.7929Z"
      fill="#ECB22E"
    />
  </svg>
);
