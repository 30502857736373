import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { css } from "linaria";
import { cover } from "polished";
import { ReactNode } from "react";
import { GRID_GAP_PX } from "../../constants/globalGrid.constants";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  id?: string;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  hoverBorderColor?: string;
  figureBackgroundColor?: string;
  figureSlot: ReactNode;
  heading: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  to?: string;
  href?: string;
  download?: boolean;
};

const wrapperStyle = css`
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  text-decoration: none;
  color: inherit;
`;

const Inner = styled.div<{
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  hoverBorderColor?: string;
}>`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr) auto;
  grid-gap: 1em;
  align-items: flex-start;
  background-color: ${p => p.backgroundColor ?? colorsV4.purple};
  color: ${p => p.textColor ?? colorsV4.white};
  padding: 24px;
  border-width: 2px;
  border-style: solid;
  border-color: ${p => p.borderColor ?? "transparent"};
  border-radius: ${rSize("gap")};
  ${fromTabletMd} {
    padding: 38px;
  }
  &:hover {
    border-color: ${p => p.hoverBorderColor ?? p.borderColor ?? "transparent"};
  }
  h3 {
    font-size: 32px;
    margin-top: 0.5em;
  }
  a {
    ${inParagraphLinkStyle()}
  }
  th {
    text-align: left;
  }
  th,
  td {
    padding-right: 0.5em;
  }
  footer {
    margin-top: 0.5em;
  }
`;

const Figure = styled.figure<{
  backgroundColor?: string;
}>`
  margin: 0;
  display: block;
  padding: 0;
  position: relative;
  background-color: ${p =>
    p.backgroundColor ?? withOpacity(colorsV4.grey, 0.2)};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
`;

const FigureInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${cover()};
  object-position: center;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

const LargeGenericCard = (props: Props) => {
  const inner = (
    <Inner
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      borderColor={props.borderColor}
      hoverBorderColor={props.hoverBorderColor}
    >
      <Figure backgroundColor={props.figureBackgroundColor}>
        <FigureInner>{props.figureSlot}</FigureInner>
      </Figure>
      <h3>{props.heading}</h3>
      {props.content && (
        <div
          className={css`
            line-height: 1.4;
            > * + * {
              margin-top: 0.5em;
            }
          `}
        >
          {props.content}
        </div>
      )}
      {props.footer && <footer>{props.footer}</footer>}
    </Inner>
  );
  return props.to ? (
    <Link id={props.id} className={wrapperStyle} to={props.to}>
      {inner}
    </Link>
  ) : props.href ? (
    <a
      id={props.id}
      className={wrapperStyle}
      href={props.href}
      download={props.download}
    >
      {inner}
    </a>
  ) : (
    <div id={props.id} className={wrapperStyle}>
      {inner}
    </div>
  );
};

export const LargeGenericCardGrid = styled.div`
  display: grid;
  grid-gap: ${GRID_GAP_PX};
  grid-template-columns: minmax(0, 1fr);
  align-items: stretch;
  ${fromTabletMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export default LargeGenericCard;
