import { css, cx } from "linaria";
import { colors } from "../../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

type Props = {
  className?: string;
};

const BookADemoIcon = (props: Props) => {
  return (
    <svg
      width="89"
      height="88"
      viewBox="0 0 89 88"
      fill="none"
      strokeMiterlimit="10"
      className={cx(
        props.className,
        css`
          --shadow-color: ${colors.dark500};
          ${darkModeLinariaCSS(`--shadow-color: ${colors.dark800};`)}
        `
      )}
    >
      <path
        d="M69.7231 5.17761H18.9844V55.6602H69.7231V5.17761Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M14.188 54.6698V10.9585C14.188 6.65947 17.6854 3.16211 21.9846 3.16211H66.4803C70.7791 3.16211 74.2767 6.65947 74.2767 10.9585V54.6698C74.2767 58.9688 70.7791 62.4663 66.4803 62.4663H21.9846C17.6854 62.4663 14.188 58.9688 14.188 54.6698ZM68.226 12.7132C68.226 10.7657 66.6413 9.18143 64.694 9.18143H23.7709C21.8234 9.18143 20.2391 10.7657 20.2391 12.7132V52.9152C20.2391 54.8625 21.8234 56.447 23.7709 56.447H64.694C66.6413 56.447 68.226 54.8625 68.226 52.9152V12.7132Z"
        fill="#6359B3"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M87.5674 79.8354C87.5631 79.2315 87.3079 78.51 86.6324 77.7354L75.1342 64.5476C73.3094 62.4548 69.408 60.7427 66.4638 60.7427H22.0021C19.058 60.7427 15.1564 62.4548 13.3318 64.5476L1.83335 77.7354C1.17993 78.4849 0.891959 79.1853 0.895464 79.776C0.900823 80.6877 0.863721 84.8942 0.896495 85.5561C0.95318 86.6988 2.00443 87.457 3.86865 87.457H84.5971C86.5238 87.457 87.5816 86.6473 87.5715 85.44C87.5664 84.8429 87.5736 80.6933 87.5674 79.8354Z"
        fill="#6359B3"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M86.6321 77.7354L75.1339 64.5476C73.309 62.4548 69.4076 60.7427 66.4635 60.7427H45.3198H43.1453H22.0016C19.0574 60.7427 15.1559 62.4548 13.3312 64.5476L1.8328 77.7354C0.00815414 79.828 0.924186 81.5403 3.86831 81.5403H43.1451H45.3196H84.5966C87.5407 81.5403 88.4565 79.828 86.6321 77.7354Z"
        fill="#8173E6"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M14.188 52.5079V8.79655C14.188 4.49756 17.6854 1 21.9846 1H66.4803C70.7791 1 74.2767 4.49735 74.2767 8.79655V52.5079C74.2767 56.8069 70.7791 60.3044 66.4803 60.3044H21.9846C17.6854 60.3042 14.188 56.8069 14.188 52.5079ZM68.226 10.5511C68.226 8.60362 66.6413 7.01932 64.694 7.01932H23.7709C21.8234 7.01932 20.2391 8.60362 20.2391 10.5511V50.7531C20.2391 52.7004 21.8234 54.2849 23.7709 54.2849H64.694C66.6413 54.2849 68.226 52.7004 68.226 50.7531V10.5511Z"
        fill="#8173E6"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M62.148 76.0334C69.466 76.0334 75.3985 74.0927 75.3985 71.6988C75.3985 69.3048 69.466 67.3641 62.148 67.3641C54.8299 67.3641 48.8975 69.3048 48.8975 71.6988C48.8975 74.0927 54.8299 76.0334 62.148 76.0334Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M30.7295 76.0334C38.0476 76.0334 43.98 74.0927 43.98 71.6988C43.98 69.3048 38.0476 67.3641 30.7295 67.3641C23.4115 67.3641 17.479 69.3048 17.479 71.6988C17.479 74.0927 23.4115 76.0334 30.7295 76.0334Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M61.9172 75.1862C67.8695 75.1862 72.6948 73.6405 72.6948 71.7338C72.6948 69.8271 67.8695 68.2814 61.9172 68.2814C55.9649 68.2814 51.1396 69.8271 51.1396 71.7338C51.1396 73.6405 55.9649 75.1862 61.9172 75.1862Z"
        fill="var(--shadow-color)"
      />
      <path
        d="M71.8867 69.2007C71.0341 66.9634 66.2748 64.8044 59.3994 64.8044C52.524 64.8044 47.7178 66.8713 46.9501 69.1391C46.8164 69.534 46.1489 71.0979 46.1489 71.6988C46.1489 74.0927 52.0813 76.0334 59.3996 76.0334C66.7176 76.0334 72.6501 74.0927 72.6501 71.6988C72.6501 71.0979 72.0079 69.519 71.8867 69.2007Z"
        fill="#8173E6"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M59.3995 73.4733C66.275 73.4733 71.8488 71.5328 71.8488 69.1391C71.8488 66.7453 66.275 64.8048 59.3995 64.8048C52.5239 64.8048 46.9502 66.7453 46.9502 69.1391C46.9502 71.5328 52.5239 73.4733 59.3995 73.4733Z"
        fill="#B0A6F9"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M68.5649 64.5694C68.5649 62.5856 64.3929 60.9772 59.2461 60.9772C54.0991 60.9772 49.9272 62.5856 49.9272 64.5694C49.9272 65.2238 49.9272 66.8802 49.9272 67.5347C49.9272 69.5185 54.0991 71.1267 59.2461 71.1267C64.3929 71.1267 68.5649 69.5185 68.5649 67.5347C68.5649 66.8802 68.5649 65.2238 68.5649 64.5694Z"
        fill="#D66B99"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M59.2461 68.1614C64.3927 68.1614 68.5649 66.5532 68.5649 64.5694C68.5649 62.5856 64.3927 60.9774 59.2461 60.9774C54.0994 60.9774 49.9272 62.5856 49.9272 64.5694C49.9272 66.5532 54.0994 68.1614 59.2461 68.1614Z"
        fill="#F38EBA"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M41.0329 69.2006C40.1806 66.9633 35.4215 64.8043 28.5461 64.8043C21.6703 64.8043 16.8642 66.8712 16.0968 69.139C15.9631 69.5339 15.2954 71.0978 15.2954 71.6986C15.2954 74.0926 21.2278 76.0333 28.5461 76.0333C35.8641 76.0333 41.7966 74.0926 41.7966 71.6986C41.7966 71.0978 41.1543 69.5189 41.0329 69.2006Z"
        fill="#8173E6"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M28.546 73.4733C35.4215 73.4733 40.9953 71.5328 40.9953 69.1391C40.9953 66.7453 35.4215 64.8048 28.546 64.8048C21.6704 64.8048 16.0967 66.7453 16.0967 69.1391C16.0967 71.5328 21.6704 73.4733 28.546 73.4733Z"
        fill="#B0A6F9"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M23.741 14.143C24.1394 14.143 24.4624 13.82 24.4624 13.4215C24.4624 13.0231 24.1394 12.7001 23.741 12.7001C23.3425 12.7001 23.0195 13.0231 23.0195 13.4215C23.0195 13.82 23.3425 14.143 23.741 14.143Z"
        fill="#595652"
      />
      <path
        d="M31.9861 14.143C32.3845 14.143 32.7075 13.82 32.7075 13.4215C32.7075 13.0231 32.3845 12.7001 31.9861 12.7001C31.5877 12.7001 31.2646 13.0231 31.2646 13.4215C31.2646 13.82 31.5877 14.143 31.9861 14.143Z"
        fill="#595652"
      />
      <path
        d="M40.2312 14.143C40.6297 14.143 40.9527 13.82 40.9527 13.4215C40.9527 13.0231 40.6297 12.7001 40.2312 12.7001C39.8328 12.7001 39.5098 13.0231 39.5098 13.4215C39.5098 13.82 39.8328 14.143 40.2312 14.143Z"
        fill="#595652"
      />
      <path
        d="M48.4763 14.143C48.8748 14.143 49.1978 13.82 49.1978 13.4215C49.1978 13.0231 48.8748 12.7001 48.4763 12.7001C48.0779 12.7001 47.7549 13.0231 47.7549 13.4215C47.7549 13.82 48.0779 14.143 48.4763 14.143Z"
        fill="#595652"
      />
      <path
        d="M56.7214 14.143C57.1199 14.143 57.4429 13.82 57.4429 13.4215C57.4429 13.0231 57.1199 12.7001 56.7214 12.7001C56.323 12.7001 56 13.0231 56 13.4215C56 13.82 56.323 14.143 56.7214 14.143Z"
        fill="#595652"
      />
      <path
        d="M64.9666 14.143C65.365 14.143 65.688 13.82 65.688 13.4215C65.688 13.0231 65.365 12.7001 64.9666 12.7001C64.5681 12.7001 64.2451 13.0231 64.2451 13.4215C64.2451 13.82 64.5681 14.143 64.9666 14.143Z"
        fill="#595652"
      />
      <path
        d="M23.741 20.3268C24.1394 20.3268 24.4624 20.0038 24.4624 19.6054C24.4624 19.2069 24.1394 18.8839 23.741 18.8839C23.3425 18.8839 23.0195 19.2069 23.0195 19.6054C23.0195 20.0038 23.3425 20.3268 23.741 20.3268Z"
        fill="#595652"
      />
      <path
        d="M31.9861 20.3268C32.3845 20.3268 32.7075 20.0038 32.7075 19.6054C32.7075 19.2069 32.3845 18.8839 31.9861 18.8839C31.5877 18.8839 31.2646 19.2069 31.2646 19.6054C31.2646 20.0038 31.5877 20.3268 31.9861 20.3268Z"
        fill="#595652"
      />
      <path
        d="M40.2312 20.3268C40.6297 20.3268 40.9527 20.0038 40.9527 19.6054C40.9527 19.2069 40.6297 18.8839 40.2312 18.8839C39.8328 18.8839 39.5098 19.2069 39.5098 19.6054C39.5098 20.0038 39.8328 20.3268 40.2312 20.3268Z"
        fill="#595652"
      />
      <path
        d="M48.4763 20.3268C48.8748 20.3268 49.1978 20.0038 49.1978 19.6054C49.1978 19.2069 48.8748 18.8839 48.4763 18.8839C48.0779 18.8839 47.7549 19.2069 47.7549 19.6054C47.7549 20.0038 48.0779 20.3268 48.4763 20.3268Z"
        fill="#595652"
      />
      <path
        d="M56.7214 20.3268C57.1199 20.3268 57.4429 20.0038 57.4429 19.6054C57.4429 19.2069 57.1199 18.8839 56.7214 18.8839C56.323 18.8839 56 19.2069 56 19.6054C56 20.0038 56.323 20.3268 56.7214 20.3268Z"
        fill="#595652"
      />
      <path
        d="M64.9666 20.3268C65.365 20.3268 65.688 20.0038 65.688 19.6054C65.688 19.2069 65.365 18.8839 64.9666 18.8839C64.5681 18.8839 64.2451 19.2069 64.2451 19.6054C64.2451 20.0038 64.5681 20.3268 64.9666 20.3268Z"
        fill="#595652"
      />
      <path
        d="M23.741 26.5105C24.1394 26.5105 24.4624 26.1875 24.4624 25.7891C24.4624 25.3906 24.1394 25.0676 23.741 25.0676C23.3425 25.0676 23.0195 25.3906 23.0195 25.7891C23.0195 26.1875 23.3425 26.5105 23.741 26.5105Z"
        fill="#595652"
      />
      <path
        d="M31.9861 26.5105C32.3845 26.5105 32.7075 26.1875 32.7075 25.7891C32.7075 25.3906 32.3845 25.0676 31.9861 25.0676C31.5877 25.0676 31.2646 25.3906 31.2646 25.7891C31.2646 26.1875 31.5877 26.5105 31.9861 26.5105Z"
        fill="#595652"
      />
      <path
        d="M40.2312 26.5105C40.6297 26.5105 40.9527 26.1875 40.9527 25.7891C40.9527 25.3906 40.6297 25.0676 40.2312 25.0676C39.8328 25.0676 39.5098 25.3906 39.5098 25.7891C39.5098 26.1875 39.8328 26.5105 40.2312 26.5105Z"
        fill="#595652"
      />
      <path
        d="M48.4763 26.5105C48.8748 26.5105 49.1978 26.1875 49.1978 25.7891C49.1978 25.3906 48.8748 25.0676 48.4763 25.0676C48.0779 25.0676 47.7549 25.3906 47.7549 25.7891C47.7549 26.1875 48.0779 26.5105 48.4763 26.5105Z"
        fill="#595652"
      />
      <path
        d="M56.7214 26.5105C57.1199 26.5105 57.4429 26.1875 57.4429 25.7891C57.4429 25.3906 57.1199 25.0676 56.7214 25.0676C56.323 25.0676 56 25.3906 56 25.7891C56 26.1875 56.323 26.5105 56.7214 26.5105Z"
        fill="#595652"
      />
      <path
        d="M64.9666 26.5105C65.365 26.5105 65.688 26.1875 65.688 25.7891C65.688 25.3906 65.365 25.0676 64.9666 25.0676C64.5681 25.0676 64.2451 25.3906 64.2451 25.7891C64.2451 26.1875 64.5681 26.5105 64.9666 26.5105Z"
        fill="#595652"
      />
      <path
        d="M23.741 32.6944C24.1394 32.6944 24.4624 32.3714 24.4624 31.9729C24.4624 31.5745 24.1394 31.2515 23.741 31.2515C23.3425 31.2515 23.0195 31.5745 23.0195 31.9729C23.0195 32.3714 23.3425 32.6944 23.741 32.6944Z"
        fill="#595652"
      />
      <path
        d="M31.9861 32.6944C32.3845 32.6944 32.7075 32.3714 32.7075 31.9729C32.7075 31.5745 32.3845 31.2515 31.9861 31.2515C31.5877 31.2515 31.2646 31.5745 31.2646 31.9729C31.2646 32.3714 31.5877 32.6944 31.9861 32.6944Z"
        fill="#595652"
      />
      <path
        d="M40.2312 32.6944C40.6297 32.6944 40.9527 32.3714 40.9527 31.9729C40.9527 31.5745 40.6297 31.2515 40.2312 31.2515C39.8328 31.2515 39.5098 31.5745 39.5098 31.9729C39.5098 32.3714 39.8328 32.6944 40.2312 32.6944Z"
        fill="#595652"
      />
      <path
        d="M48.4763 32.6944C48.8748 32.6944 49.1978 32.3714 49.1978 31.9729C49.1978 31.5745 48.8748 31.2515 48.4763 31.2515C48.0779 31.2515 47.7549 31.5745 47.7549 31.9729C47.7549 32.3714 48.0779 32.6944 48.4763 32.6944Z"
        fill="#595652"
      />
      <path
        d="M56.7214 32.6944C57.1199 32.6944 57.4429 32.3714 57.4429 31.9729C57.4429 31.5745 57.1199 31.2515 56.7214 31.2515C56.323 31.2515 56 31.5745 56 31.9729C56 32.3714 56.323 32.6944 56.7214 32.6944Z"
        fill="#595652"
      />
      <path
        d="M64.9666 32.6944C65.365 32.6944 65.688 32.3714 65.688 31.9729C65.688 31.5745 65.365 31.2515 64.9666 31.2515C64.5681 31.2515 64.2451 31.5745 64.2451 31.9729C64.2451 32.3714 64.5681 32.6944 64.9666 32.6944Z"
        fill="#595652"
      />
      <path
        d="M23.741 38.8782C24.1394 38.8782 24.4624 38.5552 24.4624 38.1567C24.4624 37.7583 24.1394 37.4353 23.741 37.4353C23.3425 37.4353 23.0195 37.7583 23.0195 38.1567C23.0195 38.5552 23.3425 38.8782 23.741 38.8782Z"
        fill="#595652"
      />
      <path
        d="M31.9861 38.8782C32.3845 38.8782 32.7075 38.5552 32.7075 38.1567C32.7075 37.7583 32.3845 37.4353 31.9861 37.4353C31.5877 37.4353 31.2646 37.7583 31.2646 38.1567C31.2646 38.5552 31.5877 38.8782 31.9861 38.8782Z"
        fill="#595652"
      />
      <path
        d="M40.2312 38.8782C40.6297 38.8782 40.9527 38.5552 40.9527 38.1567C40.9527 37.7583 40.6297 37.4353 40.2312 37.4353C39.8328 37.4353 39.5098 37.7583 39.5098 38.1567C39.5098 38.5552 39.8328 38.8782 40.2312 38.8782Z"
        fill="#595652"
      />
      <path
        d="M48.4763 38.8782C48.8748 38.8782 49.1978 38.5552 49.1978 38.1567C49.1978 37.7583 48.8748 37.4353 48.4763 37.4353C48.0779 37.4353 47.7549 37.7583 47.7549 38.1567C47.7549 38.5552 48.0779 38.8782 48.4763 38.8782Z"
        fill="#595652"
      />
      <path
        d="M56.7214 38.8782C57.1199 38.8782 57.4429 38.5552 57.4429 38.1567C57.4429 37.7583 57.1199 37.4353 56.7214 37.4353C56.323 37.4353 56 37.7583 56 38.1567C56 38.5552 56.323 38.8782 56.7214 38.8782Z"
        fill="#595652"
      />
      <path
        d="M64.9666 38.8782C65.365 38.8782 65.688 38.5552 65.688 38.1567C65.688 37.7583 65.365 37.4353 64.9666 37.4353C64.5681 37.4353 64.2451 37.7583 64.2451 38.1567C64.2451 38.5552 64.5681 38.8782 64.9666 38.8782Z"
        fill="#595652"
      />
      <path
        d="M23.741 45.062C24.1394 45.062 24.4624 44.739 24.4624 44.3406C24.4624 43.9421 24.1394 43.6191 23.741 43.6191C23.3425 43.6191 23.0195 43.9421 23.0195 44.3406C23.0195 44.739 23.3425 45.062 23.741 45.062Z"
        fill="#595652"
      />
      <path
        d="M31.9861 45.062C32.3845 45.062 32.7075 44.739 32.7075 44.3406C32.7075 43.9421 32.3845 43.6191 31.9861 43.6191C31.5877 43.6191 31.2646 43.9421 31.2646 44.3406C31.2646 44.739 31.5877 45.062 31.9861 45.062Z"
        fill="#595652"
      />
      <path
        d="M40.2312 45.062C40.6297 45.062 40.9527 44.739 40.9527 44.3406C40.9527 43.9421 40.6297 43.6191 40.2312 43.6191C39.8328 43.6191 39.5098 43.9421 39.5098 44.3406C39.5098 44.739 39.8328 45.062 40.2312 45.062Z"
        fill="#595652"
      />
      <path
        d="M48.4763 45.062C48.8748 45.062 49.1978 44.739 49.1978 44.3406C49.1978 43.9421 48.8748 43.6191 48.4763 43.6191C48.0779 43.6191 47.7549 43.9421 47.7549 44.3406C47.7549 44.739 48.0779 45.062 48.4763 45.062Z"
        fill="#595652"
      />
      <path
        d="M56.7214 45.062C57.1199 45.062 57.4429 44.739 57.4429 44.3406C57.4429 43.9421 57.1199 43.6191 56.7214 43.6191C56.323 43.6191 56 43.9421 56 44.3406C56 44.739 56.323 45.062 56.7214 45.062Z"
        fill="#595652"
      />
      <path
        d="M64.9666 45.062C65.365 45.062 65.688 44.739 65.688 44.3406C65.688 43.9421 65.365 43.6191 64.9666 43.6191C64.5681 43.6191 64.2451 43.9421 64.2451 44.3406C64.2451 44.739 64.5681 45.062 64.9666 45.062Z"
        fill="#595652"
      />
      <path
        d="M23.741 51.2459C24.1394 51.2459 24.4624 50.9229 24.4624 50.5244C24.4624 50.126 24.1394 49.803 23.741 49.803C23.3425 49.803 23.0195 50.126 23.0195 50.5244C23.0195 50.9229 23.3425 51.2459 23.741 51.2459Z"
        fill="#595652"
      />
      <path
        d="M31.9861 51.2459C32.3845 51.2459 32.7075 50.9229 32.7075 50.5244C32.7075 50.126 32.3845 49.803 31.9861 49.803C31.5877 49.803 31.2646 50.126 31.2646 50.5244C31.2646 50.9229 31.5877 51.2459 31.9861 51.2459Z"
        fill="#595652"
      />
      <path
        d="M40.2312 51.2459C40.6297 51.2459 40.9527 50.9229 40.9527 50.5244C40.9527 50.126 40.6297 49.803 40.2312 49.803C39.8328 49.803 39.5098 50.126 39.5098 50.5244C39.5098 50.9229 39.8328 51.2459 40.2312 51.2459Z"
        fill="#595652"
      />
      <path
        d="M48.4763 51.2459C48.8748 51.2459 49.1978 50.9229 49.1978 50.5244C49.1978 50.126 48.8748 49.803 48.4763 49.803C48.0779 49.803 47.7549 50.126 47.7549 50.5244C47.7549 50.9229 48.0779 51.2459 48.4763 51.2459Z"
        fill="#595652"
      />
      <path
        d="M56.7214 51.2459C57.1199 51.2459 57.4429 50.9229 57.4429 50.5244C57.4429 50.126 57.1199 49.803 56.7214 49.803C56.323 49.803 56 50.126 56 50.5244C56 50.9229 56.323 51.2459 56.7214 51.2459Z"
        fill="#595652"
      />
      <path
        d="M64.9666 51.2459C65.365 51.2459 65.688 50.9229 65.688 50.5244C65.688 50.126 65.365 49.803 64.9666 49.803C64.5681 49.803 64.2451 50.126 64.2451 50.5244C64.2451 50.9229 64.5681 51.2459 64.9666 51.2459Z"
        fill="#595652"
      />
      <path
        d="M28.7545 70.1072C31.4895 70.1072 33.7067 69.4335 33.7067 68.6025C33.7067 67.7715 31.4895 67.0978 28.7545 67.0978C26.0194 67.0978 23.8022 67.7715 23.8022 68.6025C23.8022 69.4335 26.0194 70.1072 28.7545 70.1072Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M30.772 68.1129C30.772 69.227 29.8685 69.5407 28.7546 69.5407C27.6401 69.5407 26.7368 69.2272 26.7368 68.1129V45.9198C26.7368 44.8057 27.6401 43.9022 28.7546 43.9022C29.8685 43.9022 30.772 44.8055 30.772 45.9198V68.1129Z"
        fill="#F5D0E0"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M33.7728 34.2874C33.7728 34.7945 33.3615 35.2059 32.8545 35.2059H26.6094C26.1019 35.2059 25.6909 34.7947 25.6909 34.2874V28.0424C25.6909 27.5349 26.1019 27.1239 26.6094 27.1239H32.8545C33.3613 27.1239 33.7728 27.5349 33.7728 28.0424V34.2874Z"
        fill="#FABE64"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M44.0418 44.3532C44.0418 44.8603 43.6304 45.2715 43.1235 45.2715H36.8785C36.3712 45.2715 35.96 44.8603 35.96 44.3532V38.1082C35.96 37.6007 36.3712 37.1897 36.8785 37.1897H43.1235C43.6304 37.1897 44.0418 37.6007 44.0418 38.1082V44.3532Z"
        fill="#6DA2F0"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M33.7728 24.3237C33.7728 24.8308 33.3615 25.2422 32.8545 25.2422H26.6094C26.1019 25.2422 25.6909 24.8308 25.6909 24.3237V18.0787C25.6909 17.5712 26.1019 17.1602 26.6094 17.1602H32.8545C33.3613 17.1602 33.7728 17.5712 33.7728 18.0787V24.3237Z"
        fill="#F38EBA"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M63.8414 27.6088C63.8414 28.1158 63.4302 28.5273 62.9231 28.5273H52.7637C52.2564 28.5273 51.8452 28.116 51.8452 27.6088V21.3639C51.8452 20.8565 52.2564 20.4454 52.7637 20.4454H62.9231C63.4302 20.4454 63.8414 20.8565 63.8414 21.3639V27.6088Z"
        fill="#49B889"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M63.8414 39.6245C63.8414 40.1318 63.4302 40.543 62.9231 40.543H52.7637C52.2564 40.543 51.8452 40.132 51.8452 39.6245V33.3797C51.8452 32.8722 52.2564 32.4612 52.7637 32.4612H62.9231C63.4302 32.4612 63.8414 32.8722 63.8414 33.3797V39.6245Z"
        fill="#F0946C"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M44.0418 24.3237C44.0418 24.8308 43.6304 25.2422 43.1235 25.2422H36.8785C36.3712 25.2422 35.96 24.8308 35.96 24.3237V18.0787C35.96 17.5712 36.3712 17.1602 36.8785 17.1602H43.1235C43.6304 17.1602 44.0418 17.5712 44.0418 18.0787V24.3237Z"
        fill="#F0946C"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M44.0418 34.2874C44.0418 34.7945 43.6304 35.2059 43.1235 35.2059H36.8785C36.3712 35.2059 35.96 34.7947 35.96 34.2874V28.0424C35.96 27.5349 36.3712 27.1239 36.8785 27.1239H43.1235C43.6304 27.1239 44.0418 27.5349 44.0418 28.0424V34.2874Z"
        fill="#49B889"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M49.0912 44.6107C49.0912 44.8548 48.6758 45.0525 48.163 45.0525C47.6501 45.0525 47.2344 44.8548 47.2344 44.6107V17.9181C47.2344 17.674 47.6501 17.4766 48.163 17.4766C48.6758 17.4766 49.0912 17.674 49.0912 17.9181V44.6107Z"
        fill="#BFBAB2"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path d="M57.7734 30.5686V27.1844" stroke="#49B889" strokeWidth="1" />
      <path d="M57.7734 33.5765V30.5687" stroke="#F0946C" strokeWidth="1" />
      <path
        d="M28.7547 57.2952C35.6142 57.2952 41.1749 51.7345 41.1749 44.875C41.1749 38.0155 35.6142 32.4548 28.7547 32.4548C21.8952 32.4548 16.3345 38.0155 16.3345 44.875C16.3345 51.7345 21.8952 57.2952 28.7547 57.2952Z"
        fill="#EB81AF"
        stroke="var(--shadow-color)"
        strokeWidth="1"
      />
      <path
        d="M26.8896 59.3153C27.4169 59.7226 28.1002 59.9698 28.8479 59.9698C29.544 59.9698 30.1834 59.7548 30.6942 59.3967V57.0753H26.8896V59.3153Z"
        fill="var(--shadow-color)"
      />
      <path
        d="M37.7496 42.6762C37.409 42.6762 37.0914 42.461 36.9772 42.1201C36.109 39.5266 34.038 37.4736 31.4375 36.6283C31.0096 36.4891 30.7756 36.0297 30.9146 35.6018C31.0537 35.1738 31.5132 34.9401 31.9411 35.0788C35.0303 36.0833 37.4905 38.5219 38.5221 41.6025C38.665 42.0292 38.4349 42.4907 38.0083 42.6336C37.9225 42.6626 37.8353 42.6762 37.7496 42.6762Z"
        fill="#F5D0E0"
      />
    </svg>
  );
};

export default BookADemoIcon;
