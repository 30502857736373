import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";

export const ProductIconButton = styled.button`
  display: block;
  appearance: none;
  background-color: transparent;
  padding: 0.5em;
  border: 0;
  border-radius: 0;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: content-box;
  color: currentColor;
  cursor: pointer;
  transition: 0.1s;
  &:active {
    transform: scale(0.92);
  }
  &.invisible {
    display: none;
  }
  svg {
    display: block;
    &[data-name="thumbs-up"] {
      &[data-filled="true"] {
        color: var(--ac, ${colors.purple});
      }
    }
    &[data-name="thumbs-down"] {
      &[data-filled="true"] {
        color: var(--ac, ${colors.orange});
      }
    }
  }
`;
export const productIconLinkStyle = css`
  display: block;
  padding: 0.5em;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: content-box;
  color: currentColor;
  text-decoration: none;
  svg {
    display: block;
  }
`;
