const NewsletterToasterIcon = () => (
  <svg width="173" height="167" viewBox="0 0 173 167" fill="none">
    <path
      d="M152.799 144.664C149.525 145.979 145.503 145.878 143.349 145.878C141.195 145.878 14.1895 145.878 14.1895 145.878L53.2937 114.604C53.2937 114.604 160.625 114.604 164.235 114.604C167.844 114.604 170.394 114.186 171.706 116.665C172.66 118.468 171.338 120.362 170.758 121.309C170.178 122.256 159.555 138.45 159.555 138.45C159.555 138.45 156.159 143.314 152.799 144.664Z"
      fill="#32313B"
    />
    <path
      d="M15.1245 145.06C19.4741 145.06 23.0001 140.893 23.0001 135.753C23.0001 130.612 19.4741 126.445 15.1245 126.445C10.775 126.445 7.24902 130.612 7.24902 135.753C7.24902 140.893 10.775 145.06 15.1245 145.06Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M121.559 145.06C125.908 145.06 129.434 140.893 129.434 135.753C129.434 130.612 125.908 126.445 121.559 126.445C117.209 126.445 113.684 130.612 113.684 135.753C113.684 140.893 117.209 145.06 121.559 145.06Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M127.196 31.8071H41.2995C34.4795 31.8071 26.6789 33.3296 21.0658 40.6861H21.0591L4.33479 60.4298C4.07531 60.8115 3.82875 61.2032 3.59612 61.6045L3.55073 61.6771L3.55469 61.6764C1.93154 64.5035 1 67.779 1 71.2732V71.5188V79.8221V137.263H133.807L146.502 117.104V59.6629V51.3596V51.114C146.502 40.4511 137.858 31.8071 127.196 31.8071Z"
      fill="#7266CC"
    />
    <path
      d="M1.00098 79.822V71.5187V71.2731C1.00098 60.6096 9.64567 51.9656 20.3085 51.9656H114.501C125.165 51.9656 133.808 60.6096 133.808 71.2731V71.5187V79.822V137.263H1.00098V79.822Z"
      fill="#B0A6F9"
    />
    <path
      d="M127.196 31.8071H41.2995C34.4795 31.8071 26.6789 33.3296 21.0658 40.6861H21.0591L4.33479 60.4298C4.07531 60.8115 3.82875 61.2032 3.59612 61.6045L3.55073 61.6771L3.55469 61.6764C1.93154 64.5035 1 67.779 1 71.2732V71.5188V79.8221V137.263H133.807L146.502 117.104V59.6629V51.3596V51.114C146.502 40.4511 137.858 31.8071 127.196 31.8071Z"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M133.808 137.263V79.822V71.5187V71.2731C133.808 60.6096 125.165 51.9656 114.501 51.9656H20.3085C9.64567 51.9656 1.00098 60.6096 1.00098 71.2731V71.5187V79.822"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M133.808 137.263V79.822V71.5187V71.2731C133.808 60.6096 125.165 51.9656 114.501 51.9656H20.3085C9.64567 51.9656 1.00098 60.6096 1.00098 71.2731V71.5187V79.822"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M34.2241 34.2119C33.1206 34.5274 31.8318 35.4324 31.8318 35.4324L23.3996 44.881C23.3996 44.881 22.4713 46.0906 24.0223 47.0559C25.5732 48.021 27.4979 47.7316 27.4979 47.7316H111.492C111.492 47.7316 114.885 47.8735 116.125 47.4417C118.346 46.6695 119.001 45.4729 119.001 45.4729L125.511 37.0635C125.511 37.0635 126.815 35.3794 124.958 34.3043C123.582 33.508 123.051 33.5935 120.363 33.5935C117.676 33.5935 38.6896 33.5935 38.6896 33.5935C38.6896 33.5935 36.2188 33.6422 34.2241 34.2119Z"
      fill="#32313B"
    />
    <path
      d="M137.667 105.326C137.667 105.326 139.397 116.006 144.468 119.108L146.258 116.423L145.721 111.471L137.667 105.326Z"
      fill="#32313B"
    />
    <path
      d="M150.771 91.4253C149.833 91.4253 142.953 91.4253 142.015 91.4253C139.973 91.4253 138.316 95.913 138.316 101.448C138.316 106.984 139.973 111.472 142.015 111.472C142.953 111.472 149.833 111.472 150.771 111.472C152.813 111.472 154.469 106.984 154.469 101.448C154.469 95.913 152.813 91.4253 150.771 91.4253Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M150.77 111.471C152.813 111.471 154.469 106.984 154.469 101.448C154.469 95.9121 152.813 91.4246 150.77 91.4246C148.727 91.4246 147.071 95.9121 147.071 101.448C147.071 106.984 148.727 111.471 150.77 111.471Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M21.6856 140.287H8.32192L7.36719 137.186H22.4014L21.6856 140.287Z"
      fill="#32313B"
    />
    <path
      d="M128.359 140.287H114.996L114.041 137.186H129.076L128.359 140.287Z"
      fill="#32313B"
    />
    <path
      d="M24.7617 40.7729H125.704"
      stroke="#7266CC"
      strokeWidth="2.31974"
      strokeMiterlimit="10"
    />
    <path
      d="M120.447 1H38.3536L36.0332 3.31974V38.8784H118.126L120.447 36.5586V1Z"
      fill="#E1EBFA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M118.126 38.8785V3.31982H36.0332"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M118.126 3.31974L120.446 1"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M115.272 7.74512H33.1781L30.292 10.956V46.5146H112.385L115.272 43.3034V7.74512Z"
      fill="#E1EBFA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M112.385 46.5142V10.9556H30.292"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M112.385 10.9555L115.272 7.74463"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.2603 16.5273H37.749V31.2998H71.2603V16.5273Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.2363 16.5273H107.644"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.2363 21.4521H107.644"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.2363 26.3755H107.644"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.2363 31.2998H107.644"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.749 36.2241H107.644"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.749 41.1484H107.644"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M140.726 85.4769C140.726 85.4769 140.726 69.1287 140.726 63.6051C140.726 60.3594 139.811 55.1301 137.038 50.9658"
      stroke="#32313B"
      strokeWidth="3.31392"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M151.221 57.3975C151.221 55.8058 147.196 54.4666 141.724 54.0667L135.831 60.7873V66.6211C136.635 66.6632 137.465 66.6857 138.314 66.6857C145.443 66.6857 151.221 65.1394 151.221 63.2319C151.221 62.5841 151.221 58.046 151.221 57.3975Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M141.724 54.0676L135.831 60.7882C136.635 60.8297 137.465 60.8522 138.314 60.8522C145.443 60.8522 151.221 59.3059 151.221 57.3981C151.221 55.8068 147.196 54.4676 141.724 54.0676Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M146.429 71.904C146.429 71.904 134.996 72.187 134.996 66.1464L146.724 65.7805L146.429 71.904Z"
      fill="#32313B"
    />
    <path
      d="M49.9735 167C61.5089 167 70.8601 164.32 70.8601 161.015C70.8601 157.71 61.5089 155.03 49.9735 155.03C38.4382 155.03 29.0869 157.71 29.0869 161.015C29.0869 164.32 38.4382 167 49.9735 167Z"
      fill="#32313B"
    />
    <path
      d="M61.9829 124.463H43.8045H25.626C25.626 136.658 33.9114 140.059 33.9114 145.803C33.9114 151.546 28.4822 153.722 28.4822 160.691C28.4822 162.985 35.3424 164.843 43.8045 164.843C52.2672 164.843 59.1267 162.985 59.1267 160.691C59.1267 153.722 53.6975 151.546 53.6975 145.803C53.6975 140.06 61.9829 136.658 61.9829 124.463Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.8045 130.499C53.8442 130.499 61.9829 127.797 61.9829 124.464C61.9829 121.13 53.8442 118.428 43.8045 118.428C33.7648 118.428 25.626 121.13 25.626 124.464C25.626 127.797 33.7648 130.499 43.8045 130.499Z"
      fill="#BBD5FA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
    />
    <path
      d="M46.1657 128.163C54.9039 128.163 61.9877 126.099 61.9877 123.552C61.9877 121.005 54.9039 118.94 46.1657 118.94C37.4275 118.94 30.3438 121.005 30.3438 123.552C30.3438 126.099 37.4275 128.163 46.1657 128.163Z"
      fill="#32313B"
    />
    <path
      d="M43.8049 127.085C49.7504 127.085 54.9125 126.063 57.5116 124.564C58.135 122.484 58.4806 120.228 58.4806 117.868C58.4806 107.518 51.9098 96.2471 43.8043 96.2471C35.6988 96.2471 29.1279 107.518 29.1279 117.868C29.1279 120.227 29.4729 122.483 30.0963 124.563C32.6957 126.063 37.8574 127.085 43.8049 127.085Z"
      fill="#E1EBFA"
      stroke="#32313B"
      strokeWidth="1.65696"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export default NewsletterToasterIcon;
