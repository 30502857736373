import { css } from "linaria";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

export const CourseIconIntermediate = () => (
  <svg
    width="94"
    height="73"
    viewBox="0 0 94 73"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    className={css`
      --shadow-color: ${colorsV4.orange700};
      ${darkModeLinariaCSS(`--shadow-color: ${colorsV4.warmBlack900};`)}
    `}
  >
    <path
      d="M93.2976 50.8326H35.0054L10.7231 71.8094H72.021L93.2976 50.8326Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M88.2234 44.1356H29.9307L5.64844 65.1127V71.7181H66.9469L88.2234 50.7411V44.1356Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
    />
    <path
      d="M88.2234 44.1356H29.9307L5.64844 65.1127H66.9469L88.2234 44.1356Z"
      fill="#FBB598"
      stroke="var(--shadow-color)"
    />
    <path d="M66.947 71.7181V65.1127" stroke="var(--shadow-color)" />
    <path
      d="M65.1066 49.1056H34.7971V57.8097H56.2745L65.1066 49.1056Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M81.9674 49.1056H51.6582V57.8097H73.1353L81.9674 49.1056Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M46.9948 27.5986H30.2261C29.8889 27.5986 29.5814 27.7258 29.3486 27.9347C29.0817 28.1749 21.1406 35.8781 20.9035 36.1154C20.6655 36.3534 20.5193 36.6808 20.5193 37.043V56.4985C20.5193 57.223 21.1065 57.8099 21.831 57.8099H38.6006C38.9519 57.8099 39.2702 57.6721 39.5062 57.4471C39.7571 57.2081 47.7387 49.5055 47.9791 49.2332C48.1822 49.0026 48.3057 48.6988 48.3065 48.3658V28.9107C48.3065 28.1856 47.7195 27.5986 46.9948 27.5986Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
    />
    <path
      d="M39.9126 56.4981C39.9126 57.2226 39.3248 57.8096 38.6006 57.8096H21.831C21.1065 57.8096 20.5193 57.2226 20.5193 56.4981V37.0427C20.5193 36.3184 21.1065 35.7312 21.831 35.7312H38.6006C39.3248 35.7312 39.9126 36.3184 39.9126 37.0427V56.4981Z"
      fill="#FBB598"
      stroke="var(--shadow-color)"
    />
    <path d="M39.519 36.1073L47.988 28.0546" stroke="var(--shadow-color)" />
    <path
      d="M74.9833 27.5986H58.2149C57.8777 27.5986 57.57 27.7258 57.3371 27.9347C57.0702 28.1749 49.1291 35.8781 48.8923 36.1154C48.6543 36.3534 48.5078 36.6808 48.5078 37.043V56.4985C48.5078 57.223 49.0951 57.8099 49.8196 57.8099H66.5891C66.9407 57.8099 67.2588 57.6721 67.495 57.4471C67.7456 57.2081 75.7272 49.5055 75.9677 49.2332C76.171 49.0026 76.2945 48.6988 76.295 48.3658V28.9107C76.295 28.1856 75.7083 27.5986 74.9833 27.5986Z"
      fill="#EB994D"
      stroke="var(--shadow-color)"
    />
    <path
      d="M67.9008 56.4981C67.9008 57.2226 67.313 57.8096 66.5891 57.8096H49.8196C49.0951 57.8096 48.5078 57.2226 48.5078 56.4981V37.0427C48.5078 36.3184 49.0951 35.7312 49.8196 35.7312H66.5891C67.313 35.7312 67.9008 36.3184 67.9008 37.0427V56.4981Z"
      fill="#FFBD59"
      stroke="var(--shadow-color)"
    />
    <path d="M67.5076 36.1073L75.9763 28.0546" stroke="var(--shadow-color)" />
    <path
      d="M46.9948 11.359H30.2261C29.8889 11.359 29.5814 11.4859 29.3486 11.6951C29.0817 11.9353 21.1406 19.6385 20.9035 19.8758C20.6655 20.1135 20.5193 20.4411 20.5193 20.8031V35.1213C20.5193 35.8455 21.1065 36.4324 21.831 36.4324H38.6006C38.9519 36.4324 39.2702 36.2949 39.5062 36.0696C39.7571 35.8306 47.7387 28.1283 47.9791 27.856C48.1822 27.6251 48.3057 27.3216 48.3065 26.9886V12.6711C48.3065 11.946 47.7195 11.359 46.9948 11.359Z"
      fill="#8173E6"
      stroke="var(--shadow-color)"
    />
    <path
      d="M39.9126 35.1213C39.9126 35.8455 39.3248 36.4325 38.6006 36.4325H21.831C21.1065 36.4325 20.5193 35.8455 20.5193 35.1213V20.8034C20.5193 20.0789 21.1065 19.4919 21.831 19.4919H38.6006C39.3248 19.4919 39.9126 20.0789 39.9126 20.8034V35.1213Z"
      fill="#FFD8C8"
      stroke="var(--shadow-color)"
    />
    <path d="M39.519 19.8679L47.988 11.8152" stroke="var(--shadow-color)" />
    <path
      d="M74.9833 11.359H58.2149C57.8777 11.359 57.57 11.4859 57.3371 11.6951C57.0702 11.9353 49.1291 19.6385 48.8923 19.8758C48.6543 20.1135 48.5078 20.4411 48.5078 20.8031V35.1213C48.5078 35.8455 49.0951 36.4324 49.8196 36.4324H66.5891C66.9407 36.4324 67.2588 36.2949 67.495 36.0696C67.7456 35.8306 75.7272 28.1283 75.9677 27.856C76.171 27.6251 76.2945 27.3216 76.295 26.9886V12.6711C76.295 11.946 75.7083 11.359 74.9833 11.359Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
    />
    <path
      d="M67.9008 35.1213C67.9008 35.8455 67.313 36.4325 66.5891 36.4325H49.8196C49.0951 36.4325 48.5078 35.8455 48.5078 35.1213V20.8034C48.5078 20.0789 49.0951 19.4919 49.8196 19.4919H66.5891C67.313 19.4919 67.9008 20.0789 67.9008 20.8034V35.1213Z"
      fill="#FBB598"
      stroke="var(--shadow-color)"
    />
    <path d="M67.5076 19.8679L75.9763 11.8152" stroke="var(--shadow-color)" />
    <path
      d="M75.5711 11.5333L53.1613 1.25L29.7692 11.3317L21.2971 19.4919H66.8692L75.5711 11.5333Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
    />
    <path
      d="M66.8692 19.4918H21.9241L44.8696 8.17773L66.8692 19.4918Z"
      fill="#FBB598"
      stroke="var(--shadow-color)"
    />
    <path d="M44.8696 8.17775L53.1614 1.25" stroke="var(--shadow-color)" />
    <path
      d="M48.5079 27.5294L39.9126 35.7029V19.8679H48.5079V27.5294Z"
      fill="#F69267"
      stroke="var(--shadow-color)"
    />
  </svg>
);
