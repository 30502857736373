import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { ReportBaseSectionStyle } from "./ReportBaseStyles";

type Props = PropsWithChildren<{
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  centered?: boolean;
  spacing?: string;
}>;

const ReportSecondarySectionContainer = styled.div<{
  spacing?: string;
  centered?: boolean;
}>`
  ${ReportBaseSectionStyle};
  background-color: ${colorsV4.white};
  border-radius: 1em;
  font-size: 1.6rem;
  > * + * {
    margin-top: ${p => p.spacing ?? "1em"};
  }
  h3,
  h4 {
    color: var(--ThemeColorTextHighlight);
    &.ComboFontHeading {
      color: inherit;
      font-size: 2.6rem;
      ${fromTablet} {
        font-size: 3.4rem;
      }
      ${fromDesktop} {
        font-size: 3.8rem;
      }
    }
  }
  h3,
  h4,
  p {
    margin-left: ${p => (p.centered ? "auto" : "unset")};
    margin-right: ${p => (p.centered ? "auto" : "unset")};
    max-width: ${p => (p.centered ? "58rem" : "unset")};
  }
`;

const ReportSecondarySection = (props: Props) => {
  return (
    <ReportSecondarySectionContainer
      className={props.className}
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        textAlign: props.centered ? "center" : undefined,
      }}
      spacing={props.spacing}
      centered={props.centered}
    >
      {props.children}
    </ReportSecondarySectionContainer>
  );
};

export default ReportSecondarySection;
