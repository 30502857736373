/* eslint-disable no-console */
import { checkIfIsClientAndLive } from "../../../environment";
import { shouldLogGDPRDebugInfo } from "../gdpr";

export const initDemandBase = () => {
  if (shouldLogGDPRDebugInfo()) {
    console.log("– init DemandBase");
  }
  if (!checkIfIsClientAndLive()) return;
  (function (d, b, a, s, e) {
    let t = b.createElement(a),
    fs = b.getElementsByTagName(a)[0]; t.async = 1; t.id = e; t.src = s;
    fs.parentNode.insertBefore(t, fs);
  })
    (window, document, 'script', 'https://tag.demandbase.com/c149befc90e2abdb.min.js', 'demandbase_js_lib');
};
