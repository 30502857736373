export const CasesPageYarnBallIcon = () => (
  <svg
    width="82"
    height="64"
    viewBox="0 0 82 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.9286 62.3375C79.2818 62.3375 82.0002 61.7789 82.0002 61.0899C82.0002 60.4009 79.2818 59.8423 75.9286 59.8423C72.5753 59.8423 69.8569 60.4009 69.8569 61.0899C69.8569 61.7789 72.5753 62.3375 75.9286 62.3375Z"
      fill="#32313B"
    />
    <path
      d="M47.6074 63.4457C61.7219 63.4457 73.164 62.3247 73.164 60.9418C73.164 59.559 61.7219 58.438 47.6074 58.438C33.4929 58.438 22.0508 59.559 22.0508 60.9418C22.0508 62.3247 33.4929 63.4457 47.6074 63.4457Z"
      fill="#32313B"
    />
    <path
      d="M31.7699 62.5398C48.7637 62.5398 62.5398 48.7637 62.5398 31.7699C62.5398 14.7762 48.7637 1 31.7699 1C14.7762 1 1 14.7762 1 31.7699C1 48.7637 14.7762 62.5398 31.7699 62.5398Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M17.3301 18.8552C22.5838 14.6765 29.2352 12.1797 36.4697 12.1797C40.7182 12.1797 44.7656 13.0407 48.4467 14.5977"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M15.4653 14.3326C20.651 10.3531 27.1403 7.9873 34.1821 7.9873C38.663 7.9873 42.9198 8.94512 46.7597 10.667"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M45.2426 35.944C45.2426 35.944 45.2426 20.9729 49.0134 17.8677C49.0134 17.8677 51.5082 23.3571 50.0667 30.5654L48.4588 36.3321L46.629 36.6093L45.1084 36.6241L45.2426 35.944Z"
      fill="#D66B99"
    />
    <path
      d="M14.5791 10.2328C19.6996 6.42775 26.0432 4.17676 32.9126 4.17676C36.8085 4.17676 40.5353 4.90069 43.9655 6.22132"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M53.9257 35.3425C51.1329 36.1763 48.1734 36.6239 45.1096 36.6239C28.3027 36.6239 14.6428 23.1489 14.3447 6.41309"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M53.0178 38.1883C49.7192 39.396 46.1564 40.055 42.4395 40.055C25.4462 40.055 11.6699 26.2787 11.6699 9.28494C11.6699 9.03592 11.6729 8.78773 11.6787 8.54004"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M51.7263 41.2905C47.9829 42.9104 43.8548 43.8082 39.5166 43.8082C22.5229 43.8082 8.74658 30.032 8.74658 13.0381C8.74658 12.5038 8.76022 11.973 8.78716 11.4453"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M36.7357 47.8588C19.7419 47.8588 5.96577 34.0825 5.96577 17.0887C5.96577 16.3476 5.99355 15.613 6.04511 14.8853C2.85675 19.7329 1 25.5342 1 31.7701C1 48.7638 14.7761 62.5402 31.7699 62.5402C48.0224 62.5402 61.3305 49.9389 62.4605 33.9737C56.9601 42.3367 47.4936 47.8588 36.7357 47.8588Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M10.5859 33.1543C10.5859 33.1543 15.9624 42.742 25.9257 45.9217C39.36 50.2091 49.2686 45.4453 49.2686 45.4453C49.2686 45.4453 48.5831 46.6556 46.8679 47.9896C46.8679 47.9896 34.596 52.2101 22.9721 45.8264C12.9002 40.2949 10.5859 33.1543 10.5859 33.1543Z"
      fill="#32313B"
    />
    <path
      d="M39.6808 2.02881C46.3205 7.67261 50.5344 16.0844 50.5344 25.4816C50.5344 42.4754 36.7584 56.2517 19.7646 56.2517C17.0292 56.2517 14.378 55.8929 11.8535 55.2229C17.221 59.7856 24.1731 62.54 31.7697 62.54C48.7633 62.54 62.5396 48.7638 62.5396 31.7699C62.5396 17.5117 52.8404 5.52009 39.6808 2.02881Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.6715 2.98926C50.1376 8.60345 54.9668 17.5361 54.9668 27.5969C54.9668 44.5907 41.1903 58.367 24.1969 58.367C20.3583 58.367 16.6849 57.6622 13.2964 56.378C18.4413 60.2467 24.838 62.5403 31.771 62.5403C48.7646 62.5403 62.5409 48.7641 62.5409 31.7702C62.5407 18.615 54.2841 7.38995 42.6715 2.98926Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.9529 3.96094C53.4675 9.43357 59.1083 18.9898 59.1083 29.8637C59.1083 46.8573 45.3319 60.6338 28.3384 60.6338C23.6198 60.6338 19.1503 59.5702 15.1538 57.6722C19.9461 60.7523 25.6484 62.5393 31.7685 62.5393C48.7621 62.5393 62.5384 48.7632 62.5384 31.7692C62.5382 19.494 55.3492 8.89926 44.9529 3.96094Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.8704 52.787C33.2755 51.0851 32.8261 49.315 32.5366 47.4917"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M30.4161 54.3274C29.5255 52.0311 28.9025 49.6016 28.5854 47.0767"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M26.7073 55.4884C25.6179 52.4479 24.9936 49.1864 24.9199 45.7891"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M22.5884 56.1097C21.2177 52.6236 20.4648 48.8271 20.4648 44.8544C20.4648 44.5102 20.4705 44.1669 20.4818 43.8252"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M18.6626 56.1552C16.9621 52.3351 15.9575 47.6538 15.9575 43.2027C15.9575 42.2628 15.9994 41.3329 16.0821 40.4146"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M17.0587 51.2729C15.1705 52.6563 13.3135 53.7588 11.1323 54.6872"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M16.1912 46.4189C13.6902 48.5533 10.843 50.2943 7.74561 51.5454"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M16.3283 40.3218C13.2396 43.4088 9.49776 45.8423 5.32812 47.3973"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M13.0655 36.9077C10.2003 39.6597 6.80353 41.8625 3.04443 43.3463"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M10.585 33.4834C7.97966 35.7449 4.99041 37.5755 1.72803 38.8645"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M8.22512 29.3989C6.0879 31.1988 3.70233 32.7129 1.12695 33.8826"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M7.04564 24.9639C5.21899 26.4365 3.22052 27.7043 1.08496 28.7326"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M6.06938 21.0151C4.84175 21.8903 3.5469 22.6776 2.19434 23.3678"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M19.6494 23.3761C20.4903 20.3861 21.7717 17.5803 23.4192 15.0337"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M23.4863 27.7595C24.0255 22.3258 25.978 17.3076 28.9716 13.0781"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M27.2715 30.9295C27.364 23.9052 29.8096 17.4491 33.8565 12.3135"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M31.5163 33.5421C31.4812 32.9404 31.4634 32.3341 31.4634 31.7238C31.4634 24.3395 34.0642 17.5632 38.3997 12.2603"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M36.3415 35.4379C36.2024 34.2511 36.1309 33.0436 36.1309 31.8193C36.1309 24.6817 38.5612 18.1116 42.6392 12.8916"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M40.3989 36.1281C40.2858 35.0565 40.228 33.9686 40.228 32.867C40.228 25.7426 42.6492 19.1836 46.7134 13.9683"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M44.959 36.5869C44.8537 35.5516 44.7998 34.5011 44.7998 33.4381C44.7998 27.5125 46.4749 21.9779 49.3773 17.2822"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M49.6216 32.9378C49.2534 32.9507 48.8837 32.9572 48.5124 32.9572C47.2368 32.9572 45.9794 32.8797 44.7446 32.729"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M50.304 29.0581C48.9395 29.2431 47.546 29.3386 46.1304 29.3386C45.7942 29.3386 45.4592 29.3332 45.1255 29.3224"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M50.5082 25.3052C48.9681 25.6144 47.3862 25.8087 45.7725 25.8787"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M50.2767 21.6499C49.1269 22.0094 47.9478 22.3035 46.7441 22.5272"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M25.3555 14.0985C25.3555 14.0985 37.1461 9.47458 48.4483 14.5972L49.1748 16.4804C49.1748 16.4804 40.9809 11.1449 25.3555 14.0985Z"
      fill="#32313B"
    />
    <path
      d="M46.1237 36.5844C46.1237 36.5844 45.6475 25.5322 47.553 21.2446C47.553 21.2446 44.2182 28.3905 44.9804 36.4893L46.1237 36.5844Z"
      fill="#32313B"
    />
    <path
      d="M75.4859 27.8125C74.0546 23.1056 70.9904 22.1177 68.6715 22.1177C66.4716 22.1177 64.6406 22.9596 63.376 24.552C60.2031 28.5486 61.4937 35.9926 62.1956 39.0075C64.1901 47.5728 64.186 54.5612 62.1843 57.2454C61.6464 57.967 61.0043 58.3266 60.1639 58.3777C58.825 58.46 57.7945 58.0773 57.0218 57.2109C55.9314 55.9885 55.3989 53.8358 55.5127 51.3394C54.6367 52.4012 53.6907 53.4028 52.6821 54.338C52.9944 56.3062 53.7082 57.9941 54.7871 59.2038C56.0636 60.635 57.7979 61.3822 59.8275 61.3822C59.998 61.3822 60.1705 61.3769 60.345 61.3663C62.0589 61.2625 63.5247 60.4564 64.5845 59.0351C68.131 54.2789 66.4371 44.0202 65.1119 38.3285C63.8738 33.012 64.1073 28.4467 65.721 26.4137C66.4167 25.5376 67.3815 25.1119 68.6713 25.1119C69.9008 25.1119 71.6478 25.4822 72.6207 28.6835C73.3247 30.9997 73.3247 33.7736 73.3247 35.4309V47.3622C73.3247 48.1892 73.9949 48.8594 74.8218 48.8594C75.6487 48.8594 76.3189 48.1892 76.3189 47.3622V35.4309C76.3193 33.6069 76.3193 30.5546 75.4859 27.8125Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M53.3878 57.0143C53.3878 57.0143 54.996 56.4597 55.9941 55.2399L55.4395 51.5249L52.6821 54.3375L53.3878 57.0143Z"
      fill="#32313B"
    />
    <path
      d="M79.2561 58.2901C79.2561 57.7388 77.2326 57.292 74.7364 57.292C72.2402 57.292 70.2168 57.7388 70.2168 58.2901C70.2168 58.6658 70.2168 60.5574 70.2168 60.9333C70.2168 61.4843 72.2402 61.9312 74.7364 61.9312C77.2326 61.9312 79.2561 61.4843 79.2561 60.9333C79.2561 60.5574 79.2561 58.6658 79.2561 58.2901Z"
      fill="#D67C56"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M74.7364 59.2419C77.2325 59.2419 79.2561 58.7951 79.2561 58.244C79.2561 57.6929 77.2325 57.2461 74.7364 57.2461C72.2403 57.2461 70.2168 57.6929 70.2168 58.244C70.2168 58.7951 72.2403 59.2419 74.7364 59.2419Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M74.8998 58.3493C75.8012 58.3493 76.532 58.1586 76.532 57.9234C76.532 57.6882 75.8012 57.4976 74.8998 57.4976C73.9983 57.4976 73.2676 57.6882 73.2676 57.9234C73.2676 58.1586 73.9983 58.3493 74.8998 58.3493Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M76.0314 57.1893C76.0314 57.8128 75.5259 58.0435 74.9024 58.0435C74.2786 58.0435 73.7734 57.8128 73.7734 57.1893V54.9312C73.7734 54.3076 74.2786 53.9854 74.9024 53.9854C75.5259 53.9854 76.0314 54.3076 76.0314 54.9312V57.1893Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M77.0649 55.0011C77.0649 56.1342 76.0223 56.5531 74.7361 56.5531C73.4499 56.5531 72.4072 56.1341 72.4072 55.0011V47.2625C72.4072 46.1293 73.4499 45.5439 74.7361 45.5439C76.0223 45.5439 77.0649 46.1295 77.0649 47.2625V55.0011Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
    <path
      d="M72.4072 49.3416C72.4072 48.2084 73.4499 47.623 74.7361 47.623C76.0223 47.623 77.0649 48.2086 77.0649 49.3416"
      stroke="#32313B"
      strokeWidth="0.944684"
      strokeMiterlimit="10"
    />
  </svg>
);
