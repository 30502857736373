import { styled } from "@linaria/react";
import PageSection from "./PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { LogoAWSMarketplace } from "../thirdPartyLogos/LogoAWSMarketplace";
import { DoubleRingPartnerIcon } from "../illustrations/spots/DoubleRingPartnerIcon";
import { LogoCrowdstrike } from "../thirdPartyLogos/LogoCrowdstrike";
import { LogoRecordedFuture } from "../thirdPartyLogos/LogoRecordedFuture";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromPhoneLg,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import Spacing from "../layout/Spacing";
import { LogoHashiCorp } from "../thirdPartyLogos/LogoHashiCorp";
import { LogoAxonius } from "../thirdPartyLogos/LogoAxonius";
import { LogoNoName } from "../thirdPartyLogos/LogoNoName";
import { LogoTenable } from "../thirdPartyLogos/LogoTenable";
import { LogoElastic45h } from "../thirdPartyLogos/LogoElastic45h";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import { LogoGuidePoint } from "../thirdPartyLogos/LogoGuidePoint";
import { LogoWiz } from "../thirdPartyLogos/LogoWiz";
import { LogoOptiv } from "../thirdPartyLogos/LogoOptiv";
import { LogoDrata } from "../thirdPartyLogos/LogoDrata";
import { LogoTrace3H28 } from "../thirdPartyLogos/LogoTrace3H28";
import { LogoJamf32 } from "../thirdPartyLogos/LogoJamf32";

const Inner = styled.div`
  text-align: center;
  h2 {
    ${fromDesktop} {
      max-width: ${widthInGrid(7)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const PartnerLogoSet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: ${widthInGrid(10)};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -1em;
  ${fromTabletLg} {
    margin-bottom: -1.4em;
  }
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    flex: 0 0 50%;
    height: 9rem;
    ${fromPhoneLg} {
      flex: 0 0 33.33%;
    }
    ${fromTabletLg} {
      flex: 0 0 auto;
      padding: 1.4em;
    }
  }
  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

const Description = styled.p`
  text-align: center;
  max-width: 33em;
  margin-left: auto;
  margin-right: auto;
`;

const PartnersSection = (props: {
  logoSet?: "default" | "enterprise";
  description?: string;
}) => {
  const logosDefault = (
    <>
      <div>
        <LogoAWSMarketplace />
      </div>
      <div>
        <LogoCrowdstrike />
      </div>
      <div>
        <LogoRecordedFuture />
      </div>
      <div>
        <LogoElastic45h />
      </div>
      <div>
        <LogoHashiCorp />
      </div>
      <div>
        <LogoAxonius />
      </div>
      <div>
        <LogoNoName />
      </div>
      <div>
        <LogoTenable />
      </div>
    </>
  );

  const logosEnterprise = (
    <>
      <div>
        <LogoAWSMarketplace />
      </div>
      <div>
        <LogoElastic45h />
      </div>
      <div>
        <LogoSnowflake />
      </div>
      <div>
        <LogoJamf32 />
      </div>
      <div>
        <LogoHashiCorp />
      </div>
      <div>
        <LogoGuidePoint />
      </div>
      <div>
        <LogoWiz />
      </div>
      <div>
        <LogoOptiv />
      </div>
      <div>
        <LogoDrata />
      </div>
      <div>
        <LogoTrace3H28 />
      </div>
    </>
  );

  return (
    <PageSection>
      <Inner>
        <DoubleRingPartnerIcon />
        <Spacing size="xl" />
        <SectionHeading2 lighter>
          Partnered with the companies you trust today
        </SectionHeading2>
        {props.description && (
          <>
            <Spacing size="md" />
            <Description>{props.description}</Description>
          </>
        )}
        <Spacing size="lg" />
        <PartnerLogoSet>
          {props.logoSet === "enterprise" ? logosEnterprise : logosDefault}
        </PartnerLogoSet>
      </Inner>
    </PageSection>
  );
};

export default PartnersSection;
