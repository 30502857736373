import { styled } from "@linaria/react";
import { DatoCmsSolutionPage } from "../../../../graphql-types";
import PageHeroHeadlineChart from "./PageHeroHeadlineChart";
import PageHeroHeadlineClouds from "./PageHeroHeadlineClouds";
import { PageHeroHeadlinePortals } from "./PageHeroHeadlinePortals";
import { SolutionPageIllustrationType } from "./SolutionPageIllustrationType.enum";
import { uptoDesktop } from "../../../styles/breakpointsAndMediaQueries.styles";
import PageHeroHeadlinePlatforms from "./PageHeroHeadlinePlatforms";
import { PageHeroHeadlinePalace } from "./PageHeroHeadlinePalace";

type Props = {
  page: DatoCmsSolutionPage;
};

const Wrap = styled.div`
  ${uptoDesktop} {
    display: none;
  }
`;

const defs = {
  [SolutionPageIllustrationType.Clouds]: PageHeroHeadlineClouds,
  [SolutionPageIllustrationType.Portals]: PageHeroHeadlinePortals,
  [SolutionPageIllustrationType.ChartAndWires]: PageHeroHeadlineChart,
  [SolutionPageIllustrationType.Palace]: PageHeroHeadlinePalace,
  [SolutionPageIllustrationType.Platforms]: PageHeroHeadlinePlatforms,
};

const PageHeroHeadlineDecorationDispatcher = (props: Props) => {
  const Decoration = defs[props.page.pageIllustrationType as keyof typeof defs];
  return <Wrap>{Decoration ? <Decoration /> : null}</Wrap>;
};

export default PageHeroHeadlineDecorationDispatcher;
