import Button, { ButtonGroupCenterFlex } from "../forms/Button";
import SignUpButton from "../utilities/SignUpButton";

export const DemoAltOptionsButtonGroup = () => (
  <ButtonGroupCenterFlex>
    <Button darker to="/recorded-demo">
      Watch recorded demo
    </Button>
    <SignUpButton appearance="outlined" darker />
  </ButtonGroupCenterFlex>
);
