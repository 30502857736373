import CenteredQuoteSection from "../general/CenteredQuoteSection";
import Spacing from "../layout/Spacing";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import { CasesSubsection } from "./CasesSubsection";
import {
  BuildRunMonitorAfterHeading,
  BuildRunMonitorHeading,
  BuildRunMonitorSection,
  BuildRunMonitorSectionHeader,
  BuildRunMonitorSectionHeaderIllo,
  ProductPageSubSection,
  SplitTwoCol,
} from "./_ProductPageSharedComponents";
import { ReportingSubsection } from "./ReportingSubsection";
import { styled } from "@linaria/react";
import recordsIlloContentsSvg from "./illustrations/recordsIlloContents.svg";
import dashboardsIlloContentsSvg from "./illustrations/dashboardsIlloContents.svg";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import RigidDotGrid from "../decorations/RigidDotGrid";
import sectionIllo from "./illustrations/monitorSectionIllo.svg";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromTablet,
  fromTabletLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Figure = styled.figure`
  margin: 0;
  figcaption {
    margin-top: ${rSize("gap")};
    padding-right: 1em;
    ${fromTabletLg} {
      padding-right: ${widthInGrid(1, 1)};
    }
    p {
      font-size: 1.4rem;
      line-height: 1.35;
    }
  }
`;

const RecordsCard = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange900};
  border: 1px solid ${colors.orange700};
  border-radius: ${rSize("radius")};
  ${onlyPhones} {
    aspect-ratio: 5 / 4;
    img {
      height: 100%;
    }
  }
  ${fromTablet} {
    height: 315px;
  }
  img {
    position: relative;
  }
`;

const DashboardsCard = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange50};
  border: 1px solid ${colors.orange300};
  border-radius: ${rSize("radius")};
  ${onlyPhones} {
    aspect-ratio: 5 / 4;
    img {
      height: 100%;
    }
  }
  ${fromTablet} {
    height: 315px;
  }
`;

const SectionHeaderIlloImg = styled.img`
  margin-top: -1.5em;
  ${fromTablet} {
    margin-top: -6rem;
  }
`;

export const ProductPageMonitorSection = () => (
  <BuildRunMonitorSection id="monitor" theme="orange">
    <BuildRunMonitorSectionHeader>
      <BuildRunMonitorHeading heading="Monitor" />
      <BuildRunMonitorAfterHeading maxWidth="20em">
        Our robust monitoring features give visibility into workflow
        performance, and create transparency on their output and impact.
      </BuildRunMonitorAfterHeading>
      <BuildRunMonitorSectionHeaderIllo>
        <SectionHeaderIlloImg src={sectionIllo} />
      </BuildRunMonitorSectionHeaderIllo>
    </BuildRunMonitorSectionHeader>

    <Spacing size="subsectionMargin" />

    <ReportingSubsection />

    <Spacing size="subsectionMargin" />

    <ProductPageSubSection>
      <SplitTwoCol>
        <Figure>
          <RecordsCard>
            <RigidDotGrid color={colors.orange800} cellSize={24} />
            <img src={recordsIlloContentsSvg} />
          </RecordsCard>
          <figcaption>
            <p>
              Capture and monitor data across workflows with{" "}
              <strong>records</strong>. They are user-defined data captures that
              you add to the storyboard at any point in your workflows. This
              creates a centralized view of tabular data for reference, trend
              analysis, and more.
            </p>
          </figcaption>
        </Figure>
        <Figure>
          <DashboardsCard>
            <img src={dashboardsIlloContentsSvg} />
          </DashboardsCard>
          <figcaption>
            <p>
              Create live <strong>dashboards</strong>, which offer curated views
              of your case and record data. Define and control views to share
              at-a-glance or deep insights across your workflows.
            </p>
          </figcaption>
        </Figure>
      </SplitTwoCol>
    </ProductPageSubSection>

    <Spacing size="subsectionMargin" />

    <CasesSubsection />

    <Spacing size="subsectionMargin" />

    <CenteredQuoteSection
      // cspell:disable-next-line
      sourceName="Mike Crider"
      sourceWorkTitle="Cyber Vulnerability Analyst"
      sourceCompanyLogo={<LogoOakRidge />}
      caseStudySlug="oak-ridge-national-laboratory"
      color="orange"
      darker
    >
      <p>
        Anything that has a backend API, we’re now using Tines to tie into that
        tool. Our ability to integrate new tools has simplified so many of our
        everyday tasks.
      </p>
    </CenteredQuoteSection>
  </BuildRunMonitorSection>
);
