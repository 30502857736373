import ep1img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-1-lena-smart-mongodb.png";
import ep2img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-2-james-spiteri-elastic.png";
import ep3img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-3-maxime-lamothe-brassard-limacharlie.png";
import ep4img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-4-johannes-gilger-urlscan.png";
import ep5img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-5-dylan-white-knowbe4.png";
import ep6img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-6-niall-heffernan-head-of-security-cygnvs.png";
import ep7img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-7-jack-naglieri-founder-ceo-panther.png";
import ep8img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-8-json-barnes-netskope.png";
import ep9img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-9-rebecca-blair.png";
import ep10img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-10-jay-thoden-van-velzen.png";
import ep11img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-11-aaron-cooper.png";
import ep12img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-12-corey-hill.png";
import ep13img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-13-andrew-dimichele.png";
import ep14img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-14-kristian-kivimagi.png";
import ep15img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-15-madhav-gopal.png";
import ep16img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-16-jon-hencinski.png";
import ep17img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-17-jacob-depriest-github.png";
import ep18img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-18-andreas-schneider.png";
import ep19img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-19-diana-kelly.png";
import ep20img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-20-ryan-noon.png";
import ep21img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-21-arthur-barnes.png";
import ep22img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-22-morey-haber.png";
import ep23img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-23-yinon-costica.png";
import ep24img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-24-joshua-kamdjou.png";
import ep25img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-25-rebecca-harness.png";
import ep26img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-26-jeff-moss.png";
import ep27img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-27-david-seidman.png";
import ep28img from "../../static/images/podcast/tines-podcast-future-of-security-operations-ep-28-dmitriy-sokolovskiy.png";
import { ReactNode } from "react";
import { externalLinkAttr } from "../constants/externalLinks.constants";

export type PodcastRecord = {
  episodeNumber: number;
  title: string;
  summary: ReactNode;
  datePublished: string;
  duration: string;
  coverImageSrc: string;
  links: {
    apple: string;
    spotify: string;
    youtube?: string;
  };
};

export const podcastEpisodesSeason1to4: {
  seasonName: string;
  episodes: PodcastRecord[];
}[] = [
  {
    seasonName: "Season 1",
    episodes: [
      {
        episodeNumber: 1,
        title: "MongoDB’s Lena Smart: How to address burnout in SOC teams",
        summary: (
          <p>
            In our first episode, we are joined by{" "}
            <a
              href="https://www.linkedin.com/in/lena-smart-04709b143/"
              {...externalLinkAttr}
            >
              Lena Smart
            </a>
            , CISO of{" "}
            <a href="https://www.mongodb.com/" {...externalLinkAttr}>
              MongoDB
            </a>
            , an inspiring leader who enjoys building teams and models from
            scratch, not losing sight of one of the most pressing topics of our
            time: burnout.
          </p>
        ),
        datePublished: "March 28, 2022",
        duration: "38 min",
        coverImageSrc: ep1img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/ep-1-mongodbs-lena-smart-how-to-address-burnout-in-soc-teams/id1616529036?i=1000555479992",
          spotify: "https://open.spotify.com/episode/6tTeqCl88OdrhaIkpyfXSh",
        },
      },
      {
        episodeNumber: 2,
        title:
          "Elastic’s James Spiteri: Why SecOps Teams Need to Focus on Small Incremental Wins and Not Try to Boil the Ocean",
        summary: (
          <p>
            In our second episode, we speak with{" "}
            <a href="https://www.elastic.co/" {...externalLinkAttr}>
              Elastic
            </a>
            ’s Product Marketing Director{" "}
            <a
              href="https://www.linkedin.com/in/jamesspiteri/"
              {...externalLinkAttr}
            >
              James Spiteri
            </a>
            , an experienced security practitioner turned product marketer with
            a passion for making security accessible and easy for anyone and
            everyone.
          </p>
        ),
        datePublished: "April 11, 2022",
        duration: "39 min",
        coverImageSrc: ep2img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/elastics-james-spiteri-why-secops-teams-need-to-focus/id1616529036?i=1000557078220",
          spotify: "https://open.spotify.com/episode/7lALFxdALSDrsfbCsOLhf0",
        },
      },
      {
        episodeNumber: 3,
        title:
          "LimaCharlie’s Maxime Lamothe-Brassard: Rethinking How Cybersecurity Tools Are Sold — Less Snakeoil, More Focus on Capabilities",
        summary: (
          <p>
            In our third episode, we speak with{" "}
            <a
              href="https://www.linkedin.com/in/maximelb/"
              {...externalLinkAttr}
            >
              Maxime Lamothe-Brassard
            </a>{" "}
            — CEO and founder of{" "}
            <a href="https://limacharlie.io/" {...externalLinkAttr}>
              LimaCharlie
            </a>
            , a security infrastructure as a service tool that gives security
            teams full control over how they manage their security
            infrastructure. Maxime’s unique perspectives come from a career in
            security, including Canada’s NSA, Google, Arc4dia, and the early
            days of Crowdstrike.
          </p>
        ),
        datePublished: "April 25, 2022",
        duration: "30 min",
        coverImageSrc: ep3img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/limacharlies-maxime-lamothe-brassard-rethinking-how/id1616529036?i=1000558635466",
          spotify: "https://open.spotify.com/episode/36WVmLkTpN7XS3F9YCcSAe",
        },
      },
      {
        episodeNumber: 4,
        title:
          "urlscan’s Johannes Gilger: How Automation Can Free Up Resources and Streamline Security Investigations",
        summary: (
          <p>
            In our fourth episode of the Future of Security Operations Podcast, 
            Thomas speaks with{" "}
            <a
              href="https://www.linkedin.com/in/johannesgilger/"
              {...externalLinkAttr}
            >
              Johannes Gilger
            </a>{" "}
            — CEO and founder of{" "}
            <a href="https://urlscan.io/" {...externalLinkAttr}>
              urlscan
            </a>
            , a URL and website scanner that enables users to take a look at the
            individual resources that are requested when a site is loaded. Prior
            to founding urlscan, Johannes was the managed the Threat
            Intelligence Automation team at CrowdStrike
          </p>
        ),
        datePublished: "May 10, 2022",
        duration: "37 min",
        coverImageSrc: ep4img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/urlscans-johannes-gilger-how-automation-can-free-up/id1616529036?i=1000560169721",
          spotify: "https://open.spotify.com/episode/4hDKIvJ3cWHVla65v3hGFT",
        },
      },
      {
        episodeNumber: 5,
        title:
          "KnowBe4’s Dylan White: How to Use Automation to Remove Mundane Manual Security Tasks and Make Your Team 5x More Effective",
        summary: (
          <p>
            In our fifth episode of the Future of Security Operations Podcast,
            Thomas speaks with{" "}
            <a
              href="https://www.linkedin.com/in/dylan-white-820368106/"
              {...externalLinkAttr}
            >
              Dylan White
            </a>
            , an Information Security Engineer at{" "}
            <a href="https://www.knowbe4.com/" {...externalLinkAttr}>
              KnowBe4
            </a>{" "}
            — a leading security awareness training platform.
          </p>
        ),
        datePublished: "May 22, 2022",
        duration: "33 min",
        coverImageSrc: ep5img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/knowbe4s-dylan-white-how-to-use-automation-to-remove/id1616529036?i=1000563159240",
          spotify: "https://open.spotify.com/episode/53UwbB1rTl05W7lGrzztZ8",
        },
      },
      {
        episodeNumber: 6,
        title:
          "Niall Heffernan: How to Empower Your Security Operations Team to Focus on Higher Value Strategic Work",
        summary: (
          <p>
            In our sixth episode of the Future of Security Operations Podcast,
            Thomas speaks with{" "}
            <a
              href="https://www.linkedin.com/in/niallheffernan1/"
              {...externalLinkAttr}
            >
              Niall Heffernan
            </a>
            , Head of Security at{" "}
            <a href="https://www.cygnvs.com/" {...externalLinkAttr}>
              Cygnvs
            </a>
            , a former Senior Manager of Information Security at Informatica,
            and a Lecturer for BSc, HDIP, PGDip and MSc students studying in the
            Cyber Security streams at the National College of Ireland.
          </p>
        ),
        datePublished: "June 6, 2022",
        duration: "35 min",
        coverImageSrc: ep6img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/niall-heffernan-how-to-empower-your-security-operations/id1616529036?i=1000565365496",
          spotify: "https://open.spotify.com/episode/2K9PRwLhX64MpP77fKMxqY",
        },
      },
      {
        episodeNumber: 7,
        title:
          "Panther Labs Jack Naglieri: How to Make Security Operations Painless",
        summary: (
          <p>
            Today on the Future of Security Operations Podcast, Thomas is joined
            by
            <a
              href="https://www.linkedin.com/in/jacknaglieri/"
              {...externalLinkAttr}
            >
              Jack Naglieri
            </a>
            , CEO of{" "}
            <a href="http://www.panther.com/" {...externalLinkAttr}>
              Panther Labs
            </a>
            , a cloud-native SIEM platform that alleviates the pain of
            traditional SIEM with detection-as-code, a robust security data
            lake, and huge scalability with zero-ops.
          </p>
        ),
        datePublished: "June 21, 2022",
        duration: "38 min",
        coverImageSrc: ep7img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/panther-labs-jack-naglieri-how-to-make-security-operations/id1616529036?i=1000567304989",
          spotify: "https://open.spotify.com/episode/6wkzyvY5iVeQP5QWF3pjVw",
        },
      },
      {
        episodeNumber: 8,
        title:
          "Jason Barnes: How to Use Automation to Remove Tedious Tasks, Mitigate Risk, and Prioritize the Adoption of New Capabilities",
        summary: (
          <p>
            On Today’s episode of the Future of Security Operations Podcast,
            Thomas speaks with{" "}
            <a
              href="https://www.linkedin.com/in/thejasonbarnes/"
              {...externalLinkAttr}
            >
              Jason Barnes
            </a>{" "}
            — the former Head of Global Security Operations at Netskope and
            current Senior Director at Charter Communications.
          </p>
        ),
        datePublished: "July 4, 2022",
        duration: "27 min",
        coverImageSrc: ep8img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/jason-barnes-how-to-use-automation-to-remove-tedious/id1616529036?i=1000568774103",
          spotify: "https://open.spotify.com/episode/0Xtf57vBlVnxfYwpq19Xcq",
        },
      },
      {
        episodeNumber: 9,
        title:
          "Toast’s Rebecca Blair: Lessons From Building a Security Operations Team From Scratch",
        summary: (
          <p>
            Today on the Future of Security Operations Podcast, Thomas speaks
            with{" "}
            <a
              href="https://www.linkedin.com/in/rebecca--weaver/"
              {...externalLinkAttr}
            >
              Rebecca Blair
            </a>
            , Manager of the Security Operations Center at{" "}
            <a href="https://pos.toasttab.com/" {...externalLinkAttr}>
              Toast
            </a>
            , an all-in-one point-of-sale and restaurant management platform for
            businesses in the food service and hospitality space.
          </p>
        ),
        datePublished: "July 18, 2022",
        duration: "32 min",
        coverImageSrc: ep9img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/toasts-rebecca-blair-lessons-from-building-a/id1616529036?i=1000570265007",
          spotify: "https://open.spotify.com/episode/20SMFJf2PjsGeiTQVnnOFp",
        },
      },
      {
        episodeNumber: 10,
        title:
          "Jay Thoden Van Velzen: How to Prepare Your Security Operations For a Multi-Cloud Future",
        summary: (
          <p>
            In our 10th episode of the Future of Security Operations, Thomas
            speaks to{" "}
            <a
              href="https://www.linkedin.com/in/jay-thoden-van-velzen/"
              {...externalLinkAttr}
            >
              Jay Thoden Van Velzen
            </a>
            , Multicloud Security Operations Advisor at SAP. Prior to Jay’s
            current role, Jay scaled the SecDevOps team from 5 to 25 team
            members across three continents and five countries and was the
            Initiative Lead for multiple security improvement programs for
            Multicloud across SAP.
          </p>
        ),
        datePublished: "August 1, 2022",
        duration: "37 min",
        coverImageSrc: ep10img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/jay-thoden-van-velzen-how-to-prepare-your-security/id1616529036?i=1000574672729",
          spotify: "https://open.spotify.com/episode/4b2lVY5FqEJvOkuw3c5Rze",
        },
      },
    ],
  },
  {
    seasonName: "Season 2",
    episodes: [
      {
        episodeNumber: 11,
        title:
          "TripAction’s Aaron Cooper: Why Understanding Broader Company Culture Is Critical to a Security Operation Team’s Success",
        summary: (
          <p>
            <a
              href="https://www.linkedin.com/in/aaroncoopercissp/"
              {...externalLinkAttr}
            >
              Aaron Cooper
            </a>{" "}
            is the Security Operations Manager at TripActions with 20+ years of
            experience working in a variety of enterprise infrastructures. He
            specializes in managing and designing secure network environments to
            meet the needs of financial and corporate customers, managing
            security operations centers, designing and implementing highly
            secure and available data networks while maintaining HIPAA, SOX, and
            PCI compliance.
          </p>
        ),
        datePublished: "October 4, 2022",
        duration: "33 min",
        coverImageSrc: ep11img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/tripactions-aaron-cooper-why-understanding-broader/id1616529036?i=1000581556123",
          spotify: "https://open.spotify.com/episode/6IIqJ3HxoeNanniwRsghrE",
        },
      },
      {
        episodeNumber: 12,
        title:
          "Citrix’s Corey Hill: How to Make Mental Health a Priority For Your Security Operations Team",
        summary: (
          <p>
            In our latest episode of the Future of Security Operations, Thomas
            speaks to{" "}
            <a
              href="https://www.linkedin.com/in/corey-hill-7a814137/"
              {...externalLinkAttr}
            >
              Corey Hill
            </a>
            , Director of Security Operations and Incident Response at Citrix.
            After working at organizations such as Mandiant and Cisco, in 2020
            Corey joined the Citrix team when there were just a small handful of
            people on the SOC team. Today, the teams Corey leads spread across
            four countries and are responsible for Detection Engineering, Threat
            Intelligence, Threat Monitoring, SOAR Automation, and Incident
            Response (both CSIRT) services at Citrix.
          </p>
        ),
        datePublished: "October 14, 2022",
        duration: "31 min",
        coverImageSrc: ep12img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/citrixs-corey-hill-how-to-make-mental-health-a/id1616529036?i=1000582599036",
          spotify: "https://open.spotify.com/episode/6FcXlbJYl3sThviuxQRuhd",
        },
      },
      {
        episodeNumber: 13,
        title:
          "Redis’ Andrew DiMichele: How to Build Engaged SecOps Teams That Stay Ahead of Attackers",
        summary: (
          <p>
            In this episode of the Future of Security Operations, Thomas speaks
            with{" "}
            <a
              href="https://www.linkedin.com/in/andrew-dimichele-88083753/"
              {...externalLinkAttr}
            >
              Andrew DiMichele
            </a>
            , Director of Security Operations at Redis, whose background is in
            building security operations programs. DiMichele's security journey
            began in the US Air Force Reserves, and brought him to CISCO,
            banking, IBM, and Citrix.
          </p>
        ),
        datePublished: "November 1, 2022",
        duration: "23 min",
        coverImageSrc: ep13img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/redis-andrew-dimichele-how-to-build-engaged-secops/id1616529036?i=1000584589236",
          spotify: "https://open.spotify.com/episode/6lCMQIidHLIO2XCz5IZduh",
        },
      },
      {
        episodeNumber: 14,
        title:
          "Pipedrive’s Kristian Kivimägi: How to Scale Security Teams While Taking Care of Your People",
        summary: (
          <p>
            In this episode of Future of Security Operations, Thomas speaks with{" "}
            <a
              href="https://www.linkedin.com/in/kristian-kivim%C3%A4gi-59907981/"
              {...externalLinkAttr}
            >
              Kristian Kivimägi
            </a>
            , Head of Security Operations at Pipedrive, a CRM and intelligent
            revenue management platform for small businesses, who helped scale
            Pipedrive's Info Sec team from start-up to 20 people. Kivimägi is
            also a guest lecturer in vulnerability assessment and cybersecurity
            at Tallinn University of Technology.
          </p>
        ),
        datePublished: "November 15, 2022",
        duration: "32 min",
        coverImageSrc: ep14img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/pipedrives-kristian-kivim%C3%A4gi-how-to-scale-security/id1616529036?i=1000586286131",
          spotify: "https://open.spotify.com/episode/0xAb3zg9Eai38mkSr6LS4p",
        },
      },
      {
        episodeNumber: 15,
        title: "Madhav Gopal: Security Operations at Fortune 30 Scale",
        summary: (
          <p>
            In this episode of Future of Security Operations, Thomas speaks with{" "}
            <a
              href="https://www.linkedin.com/in/madhavgopal/"
              {...externalLinkAttr}
            >
              Madhav Gopal
            </a>
            , CISO at a Fintech start-up and formerly VP of Cybersecurity
            Operations at Comcast Cable. Madhav has over 25 years of experience
            with a track record of using innovation to protect and support
            customers and partnering effectively with business teams. Over the
            course of his career, Madhav has also led engineering operations,
            internal audit and security consulting teams. Madhav serves as an
            advisor to Save the Children US and Citadel Banking.
          </p>
        ),
        datePublished: "December 1, 2022",
        duration: "30 min",
        coverImageSrc: ep15img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/madhav-gopal-security-operations-at-fortune-30-scale/id1616529036?i=1000588357606",
          spotify: "https://open.spotify.com/episode/3ogTHmkJfFqMFN1AAtkxHU",
        },
      },
      {
        episodeNumber: 16,
        title:
          "Expel’s Jon Hencinski: How to Reduce Risk Through Better Security Strategy",
        summary: (
          <p>
            In this episode of Future of Security Operations, Thomas speaks with
            Jon Hencinski, VP of SecOps at Expel, a company with "a mission to
            make security easy to understand, easy to use, and easy to
            continuously improve." Hencinski is passionate about getting to the
            root cause of security issues and using strategy to help
            organizations make problems go away entirely.
          </p>
        ),
        datePublished: "December 13, 2022",
        duration: "42 min",
        coverImageSrc: ep16img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/expels-jon-hencinski-how-to-reduce-risk-through-better/id1616529036?i=1000590047404",
          spotify: "https://open.spotify.com/episode/4Nn5IfGNIb2WeQI5Ki3YwZ",
        },
      },
    ],
  },
  {
    seasonName: "Season 3",
    episodes: [
      {
        episodeNumber: 17,
        title:
          "GitHub’s Jacob DePriest: How to attract and retain more diverse security talent",
        summary: (
          <p>
            In this episode of Future of Security Operations, Thomas speaks with
            Jacob DePriest, VP & Deputy Chief Security Officer at GitHub, a
            company with a mission "to help every developer - regardless of
            experience level - learn, code, and ship software effectively."
          </p>
        ),
        datePublished: "February 14, 2023",
        duration: "37 min",
        coverImageSrc: ep17img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/githubs-jacob-depriest-how-to-attract-and-retain-more/id1616529036?i=1000599505436",
          spotify: "https://open.spotify.com/episode/00gEliKPZk21Cl08BUX8tb",
        },
      },
      {
        episodeNumber: 18,
        title:
          "Lacework’s Andreas Schneider: How to adapt as a CISO and the value of security failures",
        summary: (
          <p>
            In this episode of the Future of Security Operations podcast, Thomas
            interviews Andreas Schneider - the Field CISO EMEA at Lacework.
            Leveraging its data-driven platform and cloud-native application
            protection solution, Lacework helps organizations make sense of
            immense amounts of security data with minimal effort.
          </p>
        ),
        datePublished: "February 28, 2023",
        duration: "41 min",
        coverImageSrc: ep18img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/laceworks-andreas-schneider-how-to-adapt-as-a-ciso/id1616529036?i=1000602030682",
          spotify: "https://open.spotify.com/episode/0nZJHKuQ1MRRr8ubg3QiCA",
        },
      },
      {
        episodeNumber: 19,
        title:
          "Cybrize’s Diana Kelley: Why compliance is more than a checkbox exercise, and how to integrate it into your security toolkit",
        summary: (
          <p>
            In this episode of the Future of Security Operations podcast, Thomas
            speaks with Diana Kelley, Chief Security Officer / Chief Strategy
            Officer at Cybrize, which connects organizations, security leaders,
            and job seekers to train and support the next generation of
            cybersecurity professionals.
          </p>
        ),
        datePublished: "March 14, 2023",
        duration: "42 min",
        coverImageSrc: ep19img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/cybrizes-diana-kelley-why-compliance-is-more-than-a/id1616529036?i=1000604090342",
          spotify: "https://open.spotify.com/episode/76hBvT4AoJzU3MTOjU6wBg",
        },
      },
      {
        episodeNumber: 20,
        title:
          "Material Security’s Ryan Noon: Building & marketing a differentiated cybersecurity solution without spreading FUD",
        summary: (
          <p>
            In this episode of Future of Security Operations, Thomas speaks with
            Ryan Noon, Founder and CEO of Material Security, a company that
            protects the email of high-risk VIPs and top global organizations.
          </p>
        ),
        datePublished: "March 28, 2023",
        duration: "36 min",
        coverImageSrc: ep20img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/material-securitys-ryan-noon-building-marketing-a/id1616529036?i=1000606245625",
          spotify: "https://open.spotify.com/episode/4gVvUSu9wzE75gtQlGq6u3",
        },
      },
      {
        episodeNumber: 21,
        title:
          "Oracle’s Arthur Barnes: The evolution of cybersecurity & solving the challenge of hiring the right team",
        summary: (
          <p>
            In this episode of the Future of Security Operations podcast, Thomas
            interviews Arthur Barnes, Senior Director of Security Operations at
            Oracle – the world’s largest database management company.
          </p>
        ),
        datePublished: "April 11, 2023",
        duration: "42 min",
        coverImageSrc: ep21img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/oracles-arthur-barnes-the-evolution-of/id1616529036?i=1000608332384",
          spotify: "https://open.spotify.com/episode/4RBSq1btn9EGhBId5c4txn",
        },
      },
      {
        episodeNumber: 22,
        title:
          "BeyondTrust’s Morey Haber: The challenges for security operations teams due to identity-based risks in a remote working world",
        summary: (
          <p>
            In this episode of the Future of Security Operations podcast, Thomas
            chats with Morey Haber, Chief Security Officer at BeyondTrust.
            BeyondTrust is a worldwide leader in Privileged Access Management
            (PAM), focused on addressing the most urgent cybersecurity
            challenges, including zero trust, ransomware, cloud security, and
            more.
          </p>
        ),
        datePublished: "April 25, 2023",
        duration: "42 min",
        coverImageSrc: ep22img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/beyondtrusts-morey-haber-the-challenges-for-security/id1616529036?i=1000610524375",
          spotify: "https://open.spotify.com/episode/0rdDSWafRu5AWV6FEUCHZh",
        },
      },
      {
        episodeNumber: 23,
        title:
          "Wiz’s Yinon Costica: Using a self-serve model to better equip organizations and improve security posture",
        summary: (
          <p>
            In this season’s finale of the Future of Security Operations
            podcast, Thomas chats with Yinon Costica, Vice President of Product
            and co-founder at Wiz, the leading cloud infrastructure security
            platform that enables organizations to identify and remove the most
            pressing risks in the cloud.
          </p>
        ),
        datePublished: "May 9, 2023",
        duration: "42 min",
        coverImageSrc: ep23img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/wizs-yinon-costica-using-a-self-serve-model-to/id1616529036?i=1000612295708",
          spotify: "https://open.spotify.com/episode/21adoReJrTE44PI3ilJUMc",
        },
      },
    ],
  },
  {
    seasonName: "Season 4",
    episodes: [
      {
        episodeNumber: 24,
        title:
          "Sublime Security’s Josh Kamdjou: The state of today’s email threat landscape and how to defend without reinventing the wheel",
        summary: (
          <p>
            In the first episode of this season of The Future of Security
            Operations podcast, Thomas speaks to Josh Kamdjou, founder and CEO
            of Sublime Security, the world's first open and adaptable email
            security platform, preventing email attacks using Detection-as-Code
            and behavioral AI.
          </p>
        ),
        datePublished: "August 1, 2023",
        duration: "41 min",
        coverImageSrc: ep24img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/sublime-securitys-josh-kamdjou-the-state-of-todays/id1616529036?i=1000623030983",
          spotify: "https://open.spotify.com/episode/7JC6wROMj8YMAfyO9ZeuF2",
        },
      },
      {
        episodeNumber: 25,
        title:
          "Quickbase’s Rebecca Harness: Securely engaging with technology partners and third-party vendors and overcoming the inevitability of human error",
        summary: (
          <p>
            In this episode of The Future of Security Operations podcast, Thomas
            chats with Rebecca Harness, VP and Chief Information Security
            Officer at Quickbase. Quickbase is a no-code database and
            application development platform that enables anyone to safely
            build, iterate, and integrate their applications.
          </p>
        ),
        datePublished: "August 15, 2023",
        duration: "38 min",
        coverImageSrc: ep25img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/quickbases-rebecca-harness-securely-engaging-with/id1616529036?i=1000624492490",
          spotify: "https://open.spotify.com/episode/6YbsYFkZrV69CnQ0wzpjHI",
        },
      },
      {
        episodeNumber: 26,
        title:
          "Incode Technologies’ Jeff Moss: Scaling security for startups and defending against the ever-growing attack surface",
        summary: (
          <p>
            In this episode of The Future of Security Operations podcast, Thomas
            speaks to Jeff Moss, Senior Director, Information Security, at
            Incode Technologies. Incode is the leading provider of world-class
            identity solutions for the world’s largest financial institutions,
            governments, retailers, hospitality organizations, and gaming
            establishments.
          </p>
        ),
        datePublished: "August 29, 2023",
        duration: "35 min",
        coverImageSrc: ep26img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/incode-technologies-jeff-moss-scaling-security-for/id1616529036?i=1000626054029",
          spotify: "https://open.spotify.com/episode/6hnKK6UsqnKY31n603amU2",
        },
      },
      {
        episodeNumber: 27,
        title:
          "Robinhood’s David Seidman: The tradeoff between technical mastery and strong management",
        summary: (
          <p>
            In this episode of The Future of Security Operations podcast, David
            Seidman joins Thomas to discuss their career to date and what they
            have learned along the way. David is currently Head of Detection and
            Response at Robinhood, an online brokerage firm with a mission to
            democratize finance for everyone.
          </p>
        ),
        datePublished: "September 12, 2023",
        duration: "43 min",
        coverImageSrc: ep27img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/robinhoods-david-seidman-the-tradeoff-between/id1616529036?i=1000627574802",
          spotify: "https://open.spotify.com/episode/6HH2cqsUL1yZO2uRk7SGMJ",
        },
      },
      {
        episodeNumber: 28,
        title:
          "Dmitriy Sokolovskiy: How SecOps teams can measure and communicate their ROI to senior leadership",
        summary: (
          <p>
            In this episode of The Future of Security Operations podcast, Thomas
            interviews industry veteran Dmitriy Sokolovskiy, a founding member
            of (ISC)2 Eastern Massachusetts Chapter, and has over 25 years of
            experience in the security industry.
          </p>
        ),
        datePublished: "October 31, 2023",
        duration: "43 min",
        coverImageSrc: ep28img,
        links: {
          apple:
            "https://podcasts.apple.com/ie/podcast/dmitriy-sokolovskiy-how-secops-teams-can-measure-and/id1616529036?i=1000633197946",
          spotify: "https://open.spotify.com/episode/7AHwZnWWCPrne2wSl6tBcU",
        },
      },
    ],
  },
];
