import { styled } from "@linaria/react";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Button, { ButtonGroupStackedOnMobile } from "../forms/Button";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { NoBreak } from "../typography/Nowrap";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import BookADemoButton from "../utilities/BookADemoButton";
import PagesSpotIconAnimated from "./decorations/PagesSpotIconAnimated";
import SignUpButton from "../utilities/SignUpButton";

const BuildAppsPageCTASectionWrap = styled.div`
  background-color: ${colorsV4.orange};
  color: ${colorsV4.white};
  padding: 4em ${rSize("cardSectionPadding")} 4.5em;
  ${fromTablet} {
    padding-top: ${rSize("cardSectionPadding", 1.5)};
    padding-bottom: ${rSize("cardSectionPadding", 1.75)};
  }
  border-radius: ${rSize("radius")};
  text-align: center;
  position: relative;
  overflow: hidden;
`;
const Content = styled.div`
  position: relative;
  p {
    max-width: 36em;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    line-height: 1.5;
  }
`;
const BuildAppsPageCTASection = () => {
  return (
    <PageSection>
      <BuildAppsPageCTASectionWrap>
        <Content>
          <PagesSpotIconAnimated />
          <Spacing size="1em" />
          <SectionHeading2>
            Start building <Serif>today</Serif>
          </SectionHeading2>
          <Spacing size="2em" />
          <p>
            Attacks don’t happen in isolation, so your response shouldn’t
            either. <NoBreak>Discover how</NoBreak> building apps with Tines
            enables collaboration at scale.
          </p>
          <Spacing size="2em" />
          <ButtonGroupStackedOnMobile color={colorsV4.white}>
            <Button
              href="/docs/pages"
              title="Read the docs"
              appearance="filled-white"
              color="orange"
            />
            <BookADemoButton appearance="outlined" color="white" />
            <SignUpButton appearance="outlined" color="white">
              Get started for free
            </SignUpButton>
          </ButtonGroupStackedOnMobile>
        </Content>
      </BuildAppsPageCTASectionWrap>
    </PageSection>
  );
};

export default BuildAppsPageCTASection;
