import { styled } from "@linaria/react";
import { withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import Spacing from "../../layout/Spacing";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { colors } from "../../../styles/colors.styles";
import { CommunityEditionIcon } from "../../illustrations/spots/CommunityEditionIcon";
import SignUpButton from "../../utilities/SignUpButton";
import { serif } from "../../../styles/fonts.styles";

const CommunityEditionCTASectionCard = styled.section`
  position: relative;
  background-color: ${withOpacity(colors.green, 0.1)};
  color: ${colors.green700};
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  text-align: center;
  margin-top: ${rSize("xl")};
  ${darkModeLinariaCSS(`
    color: ${colors.green300};
  `)}
  h2 {
    font-family: ${serif};
    font-weight: 400;
    font-size: 2.8rem;
    ${fromTabletLg} {
      font-size: 3.2rem;
    }
    span,
    em {
      display: block;
    }
  }
`;

const Inner = styled.div`
  position: relative;
`;

const CommunityEditionCTASection = () => {
  return (
    <CommunityEditionCTASectionCard id="community-edition">
      <Inner>
        <CommunityEditionIcon />
        <Spacing size="1em" />
        <h2>
          <span>Sign up for our</span> <span>Community Edition</span>
        </h2>
        <Spacing size="1.5em" />
        <SignUpButton color={colors.green700} />
        <Spacing size="1em" />
      </Inner>
    </CommunityEditionCTASectionCard>
  );
};

export default CommunityEditionCTASection;
