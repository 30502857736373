import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover } from "polished";
import { BrandColorNameV4, colorV4 } from "../../styles/colorsV4.styles";
import { px, valueWithOptionalUnit } from "../../utils/css.utils";

type Props = {
  className?: string;
  offset?: string | number;
  color?: string | BrandColorNameV4;
  size?: string | number;
  opacity?: number;
};

export const CornerDotSetContainer = styled.div<Props>`
  ${cover()};
  margin: 0 !important;
  pointer-events: none;
  opacity: ${p => p.opacity ?? 1};
  span {
    display: inline-block;
    position: absolute;
    background-color: ${p => (p.color ? colorV4(p.color) : "currentColor")};
    width: ${p => valueWithOptionalUnit(p.size, px(12))};
    height: ${p => valueWithOptionalUnit(p.size, px(12))};
    border-radius: 50%;
    user-select: none;
  }
  span {
    &:nth-child(1) {
      top: ${p => p.offset ?? ".5em"};
      left: ${p => p.offset ?? ".5em"};
    }
    &:nth-child(2) {
      top: ${p => p.offset ?? ".5em"};
      right: ${p => p.offset ?? ".5em"};
    }
    &:nth-child(3) {
      bottom: ${p => p.offset ?? ".5em"};
      right: ${p => p.offset ?? ".5em"};
    }
    &:nth-child(4) {
      bottom: ${p => p.offset ?? ".5em"};
      left: ${p => p.offset ?? ".5em"};
    }
  }
`;

/** @deprecated */
const CornerDotSet = (props: Props) => {
  return (
    <CornerDotSetContainer
      {...props}
      className={cx("CornerDotSet", props.className)}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </CornerDotSetContainer>
  );
};

export default CornerDotSet;
