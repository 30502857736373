import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { DeepPartial } from "utility-types";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { DatoCmsCaseStudy } from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import { font } from "../../styles/fonts.styles";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import {
  fromDesktop,
  fromDesktopLg,
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  mediaFromTo,
  onlyTablets,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { Paths } from "../../utils/pathBuilders.utils";
import { colors, getBrandColorTheme } from "../../styles/colors.styles";

type Props = {
  caseStudy: DeepPartial<DatoCmsCaseStudy>;
  withHeadingAndExcerpt?: boolean;
  withFeaturedTags?: boolean;
};

export const caseStudyBookCardLinkStyle = css`
  display: block;
  text-decoration: none;
`;

const Outer = styled.div`
  background-color: ${colors.light200};
  border-radius: ${rSize("radius")};
  aspect-ratio: 4 / 3;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  transition: filter 0.1s;
  ${uptoPhoneLg} {
    max-width: ${getWidthPxInMaxGrid(4)};
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    filter: brightness(1.02);
  }
  &:active {
    filter: brightness(0.99);
  }
`;

const Inner = styled.div<Props>`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 4fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: ${rSize("gap", 0.5)};
  padding: ${rSize("gap", 0.5)};
  ${mediaFromTo("tabletMd", "desktopMd")} {
    padding: 0.5em;
  }
`;

const Left = styled.div`
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  > div {
    padding: ${rSize("gap", 0.5)};
  }
`;

const FeaturedTagsList = styled.ul<{ color: string }>`
  margin: 0;
  padding: ${rSize("gap", 0.5)};
  padding-left: ${rSize("gap", 0.25)};
  color: ${p => p.color};
  list-style: none;
  font-size: 1.4rem;
  font-weight: 600;
`;

const LogoImg = styled.img`
  max-height: 5rem;
  opacity: 0.8;
  max-width: 87.5%;
  mix-blend-mode: luminosity;
  ${mediaFromTo("tabletMd", "desktopMd")} {
    max-height: 3.8rem;
    max-width: 100%;
  }
`;

const Book = styled.div<{ backgroundColor: string }>`
  background-color: ${p => p.backgroundColor};
  color: ${colorsV4.white};
  border-radius: 0.4rem 1.2rem 1.2rem 0.4rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  overflow: hidden;
  padding: ${rSize("gap", 0.75)};
  padding-left: ${rSize("gap")};
  ${mediaFromTo("tabletMd", "desktop")} {
    padding: 0.5em 0.5em 0.5em 0.75em;
  }
  ${mediaFromTo("desktop", "desktopMd")} {
    padding: 0.5em 0.5em 0.5em 1.5em;
  }
  &:before {
    content: "";
    width: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    ${mediaFromTo("tabletMd", "desktopMd")} {
      left: 8px;
    }
    background-color: rgba(0, 0, 0, 0.05);
  }
  svg {
    position: absolute;
    top: 0;
    right: 12px;
    ${onlyTablets} {
      width: 10px;
      height: auto;
    }
  }
`;

const FeaturedData = styled.div`
  strong {
    display: block;
    font-weight: 400;
    font-family: ${font("serif")};
    font-size: 2.6rem;
    margin-top: 0.5em;
    line-height: 1;
    ${fromTablet} {
      font-size: 2.2rem;
    }
    ${fromDesktop} {
      font-size: 2.6rem;
    }
    ${fromDesktopMd} {
      font-size: 3.2rem;
    }
    ${fromDesktopLg} {
      font-size: 3.8rem;
    }
  }
  span {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    ${fromTablet} {
      font-size: 1.2rem;
    }
    ${fromDesktop} {
      font-size: 1.3rem;
    }
    ${fromDesktopMd} {
      font-size: 1.5rem;
    }
    line-height: 1.2;
    max-width: 20em;
    margin-top: 0.4em;
    padding-left: 0.5em;
  }
`;

const FeaturedQuote = styled.div`
  p {
    font-family: ${font("serif")};
    line-height: 1.1;
    font-size: 1.6rem;
    font-weight: 400;
  }
  &.long {
    p {
      font-size: 1.4rem;
      ${mediaFromTo("tabletMd", "desktop")} {
        font-size: 1.2rem;
      }
      ${mediaFromTo("desktop", "desktopMl")} {
        font-size: 1.4rem;
      }
      ${fromDesktopMl} {
        font-size: 1.6rem;
      }
    }
  }
`;

const HeadingAndExcerpt = styled.div`
  margin-top: 1em;
  margin-right: 1em;
  h3 {
    > * {
      font-weight: inherit;
      font-family: inherit;
    }
  }
`;

const Excerpt = styled.p`
  margin-top: 1em;
  font-size: 1.6rem;
`;

const getClampedFeaturedQuote = (quote: string) => {
  const featuredQuoteWordList = quote?.split(" ");
  if (featuredQuoteWordList.length <= 15) return quote;
  return `${featuredQuoteWordList
    .slice(0, 15)
    .join(" ")
    .replace(/[,.?!”…]$/, "")}…`;
};

const CaseStudyBookCardLink = (p: Props) => {
  const colorTheme = getBrandColorTheme(p.caseStudy.color);
  const logoUrl = p.caseStudy.customer?.logoDarkGray?.url;
  const featuredQuote = getClampedFeaturedQuote(
    p.caseStudy.featuredQuoteCover || p.caseStudy.featuredQuote || ""
  );
  return (
    <Link
      to={Paths.caseStudy(p.caseStudy.slug)}
      title={p.caseStudy.title ?? "Case study"}
      className={cx("CaseStudyBookCardLink", caseStudyBookCardLinkStyle)}
    >
      <Outer
        style={{
          backgroundColor: colorTheme.c100,
        }}
      >
        <Inner {...p}>
          <Left>
            <div>{logoUrl && <LogoImg src={logoUrl} />}</div>
            {p.withFeaturedTags && p.caseStudy.featuredTags && (
              <FeaturedTagsList color={colorTheme.c500}>
                {p.caseStudy.featuredTags.split(/\n/).map(tag => (
                  <li key={tag}>#{tag}</li>
                ))}
              </FeaturedTagsList>
            )}
          </Left>
          <Book backgroundColor={colorTheme.c500}>
            <svg width="19" height="33" viewBox="0 0 19 33" fill="none">
              <path
                opacity="0.2"
                d="M19 31.0671C19 31.8785 18.0846 32.3521 17.4223 31.8833L9.87043 26.5382C9.51762 26.2885 9.04431 26.2938 8.6971 26.5512L1.59561 31.8169C0.935703 32.3062 -3.71286e-06 31.8351 -3.64104e-06 31.0136L-9.29746e-07 -1.66103e-06L19 0L19 31.0671Z"
                fill="white"
              />
            </svg>
            {p.caseStudy.featuredData ? (
              <FeaturedData>
                <strong>{p.caseStudy.featuredData}</strong>
                <span>{p.caseStudy.featuredDataLabel}</span>
              </FeaturedData>
            ) : (
              <FeaturedQuote
                className={cx(
                  (featuredQuote.length > 60 ||
                    featuredQuote.split(" ").some(word => word.length > 10)) &&
                    "long"
                )}
              >
                <WithCustomInlineFormatting as="p">
                  {featuredQuote}
                </WithCustomInlineFormatting>
              </FeaturedQuote>
            )}
          </Book>
        </Inner>
      </Outer>
      {p.withHeadingAndExcerpt && (
        <HeadingAndExcerpt>
          <WithCustomInlineFormatting as="h3">
            {p.caseStudy.title}
          </WithCustomInlineFormatting>
          <Excerpt>{p.caseStudy.excerpt}</Excerpt>
        </HeadingAndExcerpt>
      )}
    </Link>
  );
};

export default CaseStudyBookCardLink;
