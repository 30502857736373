import ReactMarkdown from "react-markdown";
import { DatoCmsArticle, DatoCmsContentCard } from "../../../graphql-types";
import ContentCardBase from "./_ContentCardBase";
import { PrimaryColorName } from "../../styles/colors.styles";
import { styled } from "@linaria/react";
import {
  fromDesktop,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { cx } from "linaria";
import { DefaultContentCardBlogIcon } from "./ContentCardBlog";

type Props = {
  article: DatoCmsArticle;
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
  displayDefaultBlogIcon?: boolean;
};

const Header = styled.header`
  h4 {
    ${fromDesktop} {
      margin-right: -0.4em;
    }
  }
  &.longTitle {
    h4 {
      ${onlyPhones} {
        letter-spacing: -0.02em;
        margin-right: -0.5em;
        line-height: 1.1;
      }
    }
  }
`;

const ContentCardArticle = ({
  article,
  overrides,
  large,
  displayDefaultBlogIcon,
}: Props) => {
  const heading = overrides?.heading || article.title || "";
  return (
    <ContentCardBase
      link={`/blog/${article.slug}`}
      large={large}
      color={(overrides?.color ?? "purple") as PrimaryColorName}
      variant={overrides?.colorMode === "dark" ? "800" : "50"}
      design={overrides?.preset}
      backgroundImage={overrides?.backgroundImage?.url}
    >
      <Header className={cx(heading.length > 50 && "longTitle")}>
        <div>
          <p>
            <strong>{overrides?.preHeadingTag || "Blog"}</strong>
          </p>
          <h4>{heading}</h4>
          {overrides?.description && (
            <ReactMarkdown>{overrides.description}</ReactMarkdown>
          )}
        </div>
        <p>
          <strong>{overrides?.linkLabel || "Read now"}</strong>
        </p>
      </Header>
      {overrides?.icon?.url ? (
        <figure>
          <img
            src={overrides.icon.url}
            style={{
              aspectRatio: `${overrides.icon.width}/${overrides.icon.height}`,
            }}
          />
        </figure>
      ) : displayDefaultBlogIcon ? (
        <figure>
          <DefaultContentCardBlogIcon />
        </figure>
      ) : null}
    </ContentCardBase>
  );
};

export default ContentCardArticle;
