export const isBrowser = typeof window !== "undefined";
export const isBuildTime = !isBrowser;

export const isDevelopment = process.env.NODE_ENV === "development";
export const isProduction = !isDevelopment;

export const isTinesComBuild =
  process.env.GATSBY_SITE_URL === "https://www.tines.com";

export const shouldEnablePreviewMode =
  process.env.GATSBY_PREVIEW_MODE === "true";

export const isTinesComBuildOnMain =
  isTinesComBuild && !process.env.GATSBY_PREVIEW_MODE;
export const isPreview = process.env.GATSBY_PREVIEW_MODE;

export const checkIfIsClientAndLive = () => isBrowser && isTinesComBuildOnMain;

export const isAppleDevice = isBuildTime
  ? false
  : [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].some(s => navigator.userAgent.includes(s)) ||
    // iPad on iOS 13 detection
    navigator.userAgent.includes("Mac");

export const isAndroidDevice = isBuildTime
  ? false
  : navigator.userAgent.includes("Android");
