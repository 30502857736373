import { cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { ReactNode } from "react-markdown/lib/react-markdown";
import { useSiteContext } from "../../context/site.context";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { zIndex } from "../../styles/zIndexes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { TreeBreadcrumbLevel } from "../../utils/tree.utils";
import BreadCrumbs, { BreadCrumbsWrap } from "./BreadCrumbs";

type Props = {
  icon: ReactNode;
  levels: TreeBreadcrumbLevel[];
  mobileOnly?: boolean;
  color?: string;
};

const PageBreadCrumbsWrap = styled.div`
  ${onlyPhones} {
    padding-bottom: 0.5em;
    background-color: var(--BlurredPanelBackgroundColor);
    backdrop-filter: blur(2em);
    ${darkModeLinariaCSS(`color: ${colors.white};`)}
    position: sticky;
    top: var(--pageTopFixedElementsHeight);
    transition: top 0.25s;
    z-index: ${zIndex("PageBreadCrumbs")};
    margin-left: -1em;
    margin-right: -1em;
    padding: 0.5em 1em;
  }
  ${fromTablet} {
    position: relative;
    &.mobileOnly {
      display: none;
    }
  }
  ${BreadCrumbsWrap} {
    ${onlyPhones} {
      display: inline;
      vertical-align: baseline;
      > * {
        display: inline;
        height: 2.4rem;
        vertical-align: baseline;
        &:first-of-type {
          display: none;
        }
      }
    }
  }
  &.nowrap {
    white-space: nowrap;
    overflow: hidden;
    &:after {
      content: "";
      background-image: linear-gradient(
        to left,
        ${withOpacity(colors.lightest)} 0,
        ${withOpacity(colors.lightest)} 50%,
        ${withOpacity(colors.lightest, 0)} 100%
      );
      ${darkModeLinariaCSS(`
        background-image: linear-gradient(to left, ${withOpacity(
          colors.dark900
        )} 0, ${withOpacity(colors.dark900)} 50%, ${withOpacity(
        colors.dark900,
        0
      )} 100%);
      `)}
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 2em;
    }
  }
`;

const SidebarToggle = styled.button`
  ${fromTablet} {
    display: none;
  }
  appearance: none;
  background-color: transparent;
  color: var(--ac, ${colors.purple});
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  vertical-align: baseline;
  font-weight: 500;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  padding-left: 2.4rem;
  &:after {
    content: "|";
    margin-left: 1em;
    margin-right: 1em;
    opacity: 0.5;
    color: inherit;
  }
  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    + * {
      margin-left: 0.5em;
    }
  }
`;

const PageBreadCrumbs = (props: Props) => {
  const siteContext = useSiteContext();
  const nowrap = props.levels.length <= 2;
  return (
    <PageBreadCrumbsWrap
      className={cx(props.mobileOnly && "mobileOnly", nowrap && "nowrap")}
    >
      <SidebarToggle onClick={() => siteContext.toggleSidebar(true)}>
        {props.icon}
        {props.levels[0] && (
          <span>{props.levels[0].titleNode ?? props.levels[0].title}</span>
        )}
      </SidebarToggle>
      <BreadCrumbs padItems levels={props.levels} />
    </PageBreadCrumbsWrap>
  );
};

export default PageBreadCrumbs;
