import ContactSection from "../components/reusableSections/ContactSection";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import HeroSection from "../components/sections/HeroSection";
import { colors } from "../styles/colors.styles";
import { styled } from "@linaria/react";
import RigidDotGrid from "../components/decorations/RigidDotGrid";
import { css } from "linaria";
import ContactSupportEmergencyOptionsList from "../components/hub/contactSupport/ContactSupportEmergencyOptionsList";
import { ContactSupportGeneralQueryForm } from "../components/hub/contactSupport/ContactSupportGeneralQueryForm";
import {
  fromDesktop,
  fromTabletLg,
  uptoTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import PageSection from "../components/reusableSections/PageSection";
import { SectionHeading2 } from "../components/typography/SectionHeading2";
import { Separator } from "../components/utilities/Hr";
import { AlignCenterOnMobile } from "../components/typography/Align";

const BackdropWrap = styled.div`
  position: relative;
`;
const Inner = styled.div`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-areas: "emergency" "general" "links";
  grid-gap: 1.5em;
  ${fromTabletLg} {
    grid-gap: ${rSize("lg")};
    grid-template-columns: minmax(0, 3fr) minmax(auto, 360px);
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: "general emergency" "general links";
  }
  h2 {
    ${uptoTabletLg} {
      text-align: center;
    }
  }
`;

const LearnMoreLinks = styled.ul`
  margin: -0.5em 0 0 0;
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
  font-weight: 500;
  a {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    text-decoration: none;
    ${uptoTabletLg} {
      justify-content: center;
    }
    &:hover {
      color: ${colors.purple};
    }
    &:before {
      content: "→";
      display: inline-block;
      margin-right: 0.5em;
      color: ${colors.purple};
      flex: 0 0 auto;
    }
  }
`;

const ContactSupportCard = styled.div`
  ${fromDesktop} {
    background-color: ${colors.purple50};
    padding: 3.5em;
    border-radius: ${rSize("radius")};
  }
`;

const PageContact: PageComponent = props => {
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO title="Contact" image="/images/og/tines-contact.png" />

      <BackdropWrap>
        <RigidDotGrid color={colors.purple100} />
        <Inner>
          <HeroSection contentRelative>
            <h1>Contact</h1>
          </HeroSection>
          <Spacing size="sectionPadding" />
          <ContactSection />
          <Spacing size="xl" />
        </Inner>
      </BackdropWrap>

      <Spacing size="xl" />

      <PageSection id="support">
        <ContactSupportCard>
          <SectionHeading2>
            <AlignCenterOnMobile>Contact support</AlignCenterOnMobile>
          </SectionHeading2>
          <Separator margin="2.5em 0" />
          <Grid>
            <div
              className={css`
                grid-area: emergency;
              `}
            >
              <h2>Emergencies</h2>
              <Spacing size="1.5em" />
              <ContactSupportEmergencyOptionsList />
            </div>
            <div
              className={css`
                grid-area: general;
              `}
            >
              <h2>General queries</h2>
              <Spacing size="1.5em" />
              <ContactSupportGeneralQueryForm />
            </div>
            <div
              className={css`
                grid-area: links;
              `}
            >
              <h2>Learn more</h2>
              <Spacing size="1.5em" />
              <LearnMoreLinks>
                <li>
                  <a href="/support-reference-guide" target="_blank">
                    Tines Support Reference Guide
                  </a>
                </li>
                <li>
                  <a
                    href="/getting-answers-to-your-tines-questions"
                    target="_blank"
                  >
                    Getting answers to your Tines questions
                  </a>
                </li>
              </LearnMoreLinks>
            </div>
          </Grid>
        </ContactSupportCard>
      </PageSection>

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageContact;
