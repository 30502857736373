import { graphql } from "gatsby";
import { DatoCmsDocsPage, DocsPageByIdQuery } from "../../graphql-types";
import StandardArticleStructuredTextWithCustomBlocks from "../components/articles/StructuredTextWithCustomBlocks";
import PageBreadCrumbs from "../components/basic/PageBreadCrumbs";
import VotingBanner from "../components/hub/VotingBanner";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import { PageTree, getBreadcrumbsFromTree } from "../utils/tree.utils";
import {
  HubPageHeader,
  WiderArticlePageBody,
} from "../components/hub/HubPageComponents";
import Page from "./Page.template";
import SpaceChildren from "../components/layout/SpaceChildren";
import HubPrevNextNav from "../components/hub/HubPrevNextNav";
import ogImage from "../../static/images/og/tines-docs.png";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { BookWithLightbulbTwoToneIcon } from "../components/icons/twoTone/BookWithLightbulbTwoToneIcon";

const DocsPageTemplate: PageComponent<{
  pageContext: { tree: PageTree };
  data: DocsPageByIdQuery;
}> = props => {
  const page = props.data.page as DatoCmsDocsPage;
  const { location } = props;
  const levels = getBreadcrumbsFromTree(
    location.pathname,
    {
      title: "Docs",
      path: "/docs",
    },
    props.pageContext.tree
  );
  return (
    <Page {...props}>
      <SEO
        title={`${page.title} | Docs | Tines`}
        seoTitle={page.seoTitle}
        image={ogImage}
        description={
          page.seoDescription ||
          "Get to know the features and concepts of Tines, in detail."
        }
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="docsPage"
        recordId={page.id}
      />
      <WiderArticlePageBody generateTableOfContents supportsColorThemes>
        <PageBreadCrumbs
          icon={<BookWithLightbulbTwoToneIcon />}
          levels={levels}
        />
        <HubPageHeader>
          <h1>{page.title}</h1>
        </HubPageHeader>
        <SpaceChildren size="lg">
          <StandardArticleStructuredTextWithCustomBlocks value={page.content} />
          <VotingBanner />
          <HubPrevNextNav tree={props.pageContext.tree} />
        </SpaceChildren>
      </WiderArticlePageBody>
    </Page>
  );
};

export const docsPageByIdQuery = graphql`
  query DocsPageById($id: String) {
    page: datoCmsDocsPage(originalId: { eq: $id }) {
      id: originalId
      title
      slug
      meta {
        updatedAt
      }
      seoTitle
      seoDescription
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              width
              height
              url
            }
            caption
            link
            altTag
            showInFullPageWidth
            applyBorder
          }
          ... on DatoCmsPullQuote {
            id: originalId
            content
            source
            color
          }
          ... on DatoCmsVideoBlock {
            id: originalId
            title
            url
            videoFile {
              video {
                mp4Url
                streamingUrl
                thumbnailUrl
              }
              width
              height
              basename
              alt
              url
            }
            autoplay
            loop
          }
          ... on DatoCmsIframeEmbed {
            id: originalId
            src
            title
            aspectRatio
            aspectRatioMobile
            height
            heightMobile
          }
          ... on DatoCmsCustomHtmlBlock {
            id: originalId
            htmlContent
          }
          ... on DatoCmsChecklistBlock {
            id: originalId
            customIcon
            color
            listContent {
              value
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            heading
            spotIllustration {
              url
            }
            themeColor
            buttonLabel
            buttonLink
          }
          ... on DatoCmsStoryEmbed {
            id: originalId
            story {
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            pillText
            storyUrl
            overrideColor
            utmCampaign
            utmSource
            utmMedium
          }
          ... on DatoCmsHtmlWysiwygBlock {
            id: originalId
            content
          }
          ... on DatoCmsCalloutBanner {
            id: originalId
            content
            calloutType
          }
          ... on DatoCmsDataCardGrid {
            id: originalId
            cards {
              data
              label
              color
            }
          }
          ... on DatoCmsStructuredTextBlock {
            id: originalId
            structuredText {
              value
              blocks
            }
            backdropColor
            appearance
          }
        }
        links {
          __typename
          ... on DatoCmsDocsPage {
            id: originalId
            slug
            title
            ...DocsPageTree
          }
        }
      }
    }
  }
`;

export default DocsPageTemplate;
