import { styled } from "@linaria/react";
import DemoForm from "../demo/DemoForm";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import demoFormDecoPictureFrames from "./decorations/demoFormDecoPictureFrames.svg";
import demoFormDecoPlants from "./decorations/demoFormDecoPlants.svg";
import { DemoPageDecoCalendar } from "../demo/DemoPageDecoCalendar";
import {
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Container = styled.div``;
const Positioner = styled.div`
  position: relative;
  position: relative;
  ${fromTabletLg} {
    max-width: ${getWidthPxInMaxGrid(4)};
    margin-left: auto;
  }
`;
const FormWrap = styled.div`
  position: relative;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple400};
  border-radius: ${rSize("radius")};
  z-index: 1;
  overflow: hidden;
`;
const DecoTopRightPositioner = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  ${uptoTabletLg} {
    display: none;
  }
  ${fromTabletLg} {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    transform: translate(75%, -33%);
  }
`;

const DecoBottomLeft = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  width: 69px;
  height: 114px;
  background-image: url(${demoFormDecoPlants});
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-86%, -15%);
  z-index: 2;
`;

const DecoBottomRight = styled.div`
  ${uptoTabletLg} {
    display: none;
  }
  width: 129px;
  height: 259px;
  background-image: url(${demoFormDecoPictureFrames});
  background-size: contain;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(73%, 20%);
  z-index: 0;
`;

export const LPHeroDemoForm = () => {
  return (
    <Container id="book-a-demo">
      <Positioner>
        <DecoTopRightPositioner>
          <DemoPageDecoCalendar />
        </DecoTopRightPositioner>
        <FormWrap>
          <DemoForm mobileButtonText="Book a demo" />
        </FormWrap>
        <DecoBottomLeft />
        <DecoBottomRight />
      </Positioner>
    </Container>
  );
};
