/* eslint-disable @typescript-eslint/no-misused-promises */
import { cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link, graphql, useStaticQuery } from "gatsby";
import gsap from "gsap";
import { PropsWithChildren, useRef, useState } from "react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { ChevronDownIcon } from "../icons/misc/chevron.icons";
import {
  fromDesktop,
  fromDesktopLg,
  fromDesktopSm,
  fromDesktopXl,
  fromPhoneLg,
  fromTablet,
  fromTabletMd,
  uptoDesktop,
  uptoDesktopMd,
  uptoPhoneLg,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { SiteFooterQuery } from "../../../graphql-types";
import { clearAnimatedProps } from "../../utils/animations.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";
import { Paths } from "../../utils/pathBuilders.utils";
import TinesLogoIcon from "../misc/TinesLogoIcon";
import { GRID_MAX_WIDTH_PX } from "../../constants/globalGrid.constants";
import { createPortal } from "react-dom";
import { useSiteContext } from "../../context/site.context";
import { resolveAfter } from "../../utils/promises.utils";

const SiteFooterOuter = styled.footer`
  position: relative;
  background-color: var(--FooterBackgroundColor, "transparent");
  user-select: none;
`;

const SiteFooterWrap = styled.div`
  position: relative;
  padding-bottom: 1em;
  font-size: 1.4rem;
  color: var(--FooterForegroundColor, "currentColor");
  font-weight: 600;
  ${widerPageSectionMaxWidthStyle()}
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
  ${fromTablet} {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
    padding-bottom: ${rSize("widerPageMargin")};
    font-size: 1.3rem;
  }
  ${fromDesktopSm} {
    font-size: 1.4rem;
  }
`;

const SiteFooterInner = styled.div`
  position: relative;
  /* max-width: ${GRID_MAX_WIDTH_PX};
  margin-left: auto;
  margin-right: auto; */
`;

const SiteFooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${fromDesktop} {
    flex-wrap: nowrap;
  }
`;

const SiteFooterLinkListSet = styled.div`
  ${uptoDesktop} {
    flex: 1 1 100%;
  }
  ${fromTabletMd} {
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktop} {
    flex: 1 1 auto;
  }
  ${uptoDesktopMd} {
    flex-wrap: wrap;
  }
  ${fromDesktopLg} {
    display: flex;
    grid-gap: 0;
  }
  .simpleFooter & {
    display: none !important;
  }
`;

const SiteFooterEndRow = styled.footer`
  display: grid;
  align-items: center;
  ${uptoTabletMd} {
    font-size: 1.2rem;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas: "middle middle" "start end";
    padding-bottom: 2em;
  }
  ${fromTabletMd} {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "start middle end";
  }
  .SiteFooterEndRowCopyrightLink {
    grid-area: start;
    ${uptoTabletMd} {
      text-align: right;
      &:after {
        content: "|";
        opacity: 0.5;
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }
  .SiteFooterEndRowLogoLink {
    grid-area: middle;
    justify-self: center;
  }
  ${uptoTabletMd} {
    padding-top: 2em;
    text-align: center;
    > * {
      + * {
        margin-top: 1.5em;
      }
    }
  }
  ${fromTabletMd} {
    padding-top: 3em;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em;
  }
  ${fromDesktop} {
    margin-top: 3em;
  }
  a,
  button {
    appearance: none;
    padding: 0;
    display: inline;
    border: 0;
    color: inherit;
    font-weight: 500;
    ${fromTablet} {
      font-weight: 600;
    }
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
  }
  small {
    ${uptoTabletMd} {
      display: block;
      margin-top: 1em;
    }
    ${fromPhoneLg} {
      font-size: inherit;
    }
  }
  b {
    opacity: 0.5;
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-weight: inherit;
    ${uptoTabletMd} {
      display: none;
    }
  }
  span {
    opacity: 0.5;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
`;

const SiteFooterEndRowEnd = styled.div`
  text-align: left;
  ${fromTabletMd} {
    display: none;
    text-align: right;
  }
  ${fromDesktopXl} {
    display: block;
  }
  button {
    appearance: none;
    padding: 0;
    background-color: transparent;
    color: inherit;
    font: inherit;
    border: 0;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SiteFooter = () => {
  const data: SiteFooterQuery = useStaticQuery(siteFooterQuery);

  const {
    cookieConsentState,
    setCookieConsentState,
    setShouldDisplayCookieConsentManager,
  } = useSiteContext();
  const openCookieConsentManager = async () => {
    setShouldDisplayCookieConsentManager(false);
    setCookieConsentState({
      ...cookieConsentState,
      hasReviewed: true,
    });
    await resolveAfter();
    setShouldDisplayCookieConsentManager(true);
  };

  return (
    <SiteFooterOuter>
      <SiteFooterBackdropLayer id="site-footer-backdrop-layer" />
      <SiteFooterWrap id="site-footer">
        <SiteFooterInner>
          <SiteFooterContent>
            <SiteFooterLinkListSet>
              <SiteFooterLinkList heading="Product">
                <li>
                  <Link to={Paths.product()} title="Product">
                    Meet the product
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" title="Pricing model">
                    Pricing model
                  </Link>
                </li>
                <li>
                  <Link to={Paths.cases()} title="Cases">
                    Cases
                  </Link>
                </li>
                <li>
                  <Link to={Paths.workbench()} title="Workbench">
                    Workbench
                  </Link>
                </li>
                <li>
                  <Link to={Paths.ai()} title="AI in Tines">
                    AI in Tines
                  </Link>
                </li>
                <li>
                  <Link to={Paths.buildApps()} title="Build apps">
                    Build apps
                  </Link>
                </li>
                <li>
                  <Link to={Paths.formulas()} title="Formulas">
                    Formulas
                  </Link>
                </li>
                <li>
                  <Link to={Paths.whatsNew()} title="What’s new">
                    What’s new
                  </Link>
                </li>
              </SiteFooterLinkList>
              <SiteFooterLinkList heading="Solutions">
                <li>
                  <Link to={Paths.solutionsByProduct()} title="By product">
                    By product
                  </Link>
                </li>
                {data.solutions.nodes.map(solution => (
                  <li key={solution.slug}>
                    <Link to={Paths.solutionPage(solution.slug)}>
                      {solution.name}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to={Paths.partners()} title="Partners">
                    Partners
                  </Link>
                </li>
                <li>
                  <Link
                    to={Paths.professionalServices()}
                    title="Professional services"
                  >
                    Professional services
                  </Link>
                </li>
                <li>
                  <Link to={Paths.federal()} title="Federal">
                    Federal
                  </Link>
                </li>
                <li>
                  <Link to={Paths.enterprise()} title="Enterprise">
                    Enterprise
                  </Link>
                </li>
                <li>
                  <Link to={Paths.mssps()} title="MSSPs">
                    MSSPs
                  </Link>
                </li>
              </SiteFooterLinkList>
              <SiteFooterLinkList heading="Resources">
                <li>
                  <Link to={Paths.blog()} title="Blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to={Paths.caseStudies()} title="Case studies">
                    Case studies
                  </Link>
                </li>

                <li>
                  <Link to={Paths.library.home()} title="Product">
                    Library
                  </Link>
                </li>

                <li>
                  <Link to={Paths.university()} title="University">
                    University
                  </Link>
                </li>

                <li>
                  <a
                    href={Paths.tinesExplained()}
                    title="Tines explained"
                    {...externalLinkAttr}
                  >
                    Tines Explained
                  </a>
                </li>

                <li>
                  <Link to={Paths.customerCenter()} title="Customer center">
                    Customer center
                  </Link>
                </li>

                <li>
                  <Link to={Paths.events()} title="Events">
                    Events
                  </Link>
                </li>
                <li>
                  <Link to={Paths.podcast()} title="Podcast">
                    Podcast
                  </Link>
                </li>
                <li>
                  <Link to={Paths.webinars()} title="Webinars">
                    Webinars
                  </Link>
                </li>
                <li>
                  <Link to={Paths.bootcamps()} title="Bootcamps">
                    Bootcamps
                  </Link>
                </li>

                <li>
                  <Link to={Paths.docs()} title="Docs">
                    Docs
                  </Link>
                </li>
                <li>
                  <Link to={Paths.apiDocs()} title="API">
                    API
                  </Link>
                </li>

                <li>
                  <Link to={Paths.getCertified()} title="Get certified">
                    Get certified
                  </Link>
                </li>

                <li>
                  <Link to={Paths.ydwwt()} title="YDWWT">
                    YDWWT
                  </Link>
                </li>
              </SiteFooterLinkList>
              <SiteFooterLinkList heading="Company">
                <li>
                  <Link to={Paths.about()} title="About us">
                    About us
                  </Link>
                </li>
                <li>
                  <Link to={Paths.careers()} title="Careers">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to={Paths.contact()} title="Contact">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to={Paths.newsroom()} title="Newsroom">
                    Newsroom
                  </Link>
                </li>
                <li>
                  <Link to={Paths.legal()} title="Legal and privacy">
                    Legal and privacy
                  </Link>
                </li>
                <li>
                  <Link to={Paths.security()} title="Security">
                    Security
                  </Link>
                </li>
                <li>
                  <a
                    href={Paths.trustCenter()}
                    title="Trust center"
                    {...externalLinkAttr}
                  >
                    Trust center
                  </a>
                </li>
              </SiteFooterLinkList>
              <SiteFooterLinkList heading="Connect">
                <li>
                  <a
                    href={Paths.slackCommunity()}
                    title="Connect with Tines on Slack"
                    {...externalLinkAttr}
                  >
                    Slack Community
                  </a>
                </li>
                <li>
                  <a
                    href={Paths.linkedIn()}
                    title="Connect with Tines on LinkedIn"
                    {...externalLinkAttr}
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href={Paths.twitter()}
                    title="Follow Tines on Twitter"
                    {...externalLinkAttr}
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href={Paths.youTube()}
                    title="Tines on YouTube"
                    {...externalLinkAttr}
                  >
                    YouTube
                  </a>
                </li>
              </SiteFooterLinkList>
              <SiteFooterLinkList heading="RSS">
                <li>
                  <a
                    href="/rss/tines-blog.xml"
                    title="Tines Blog RSS feed"
                    {...externalLinkAttr}
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="/rss/tines-product-updates.xml"
                    title="Tines product updates RSS feed"
                    {...externalLinkAttr}
                  >
                    Product updates
                  </a>
                </li>
                <li>
                  <a
                    href="/rss/tines-story-library.xml"
                    title="Tines Library RSS feed"
                    {...externalLinkAttr}
                  >
                    Library
                  </a>
                </li>
                <li>
                  <a
                    href="/rss/tines-self-hosting-release-notes.xml"
                    title="Tines self-hosting release notes"
                    {...externalLinkAttr}
                  >
                    Self-hosting releases
                  </a>
                </li>
              </SiteFooterLinkList>
            </SiteFooterLinkListSet>
          </SiteFooterContent>

          <SiteFooterEndRow>
            <Link
              className="SiteFooterEndRowLogoLink"
              to="/"
              title="Tines Homepage"
            >
              <TinesLogoIcon size={28} />
            </Link>
            <p className="SiteFooterEndRowCopyrightLink">
              ©{" "}
              <Link to="/" title="Tines Homepage">
                Tines
              </Link>
            </p>

            <SiteFooterEndRowEnd>
              <button onClick={openCookieConsentManager}>
                Cookie settings
              </button>
            </SiteFooterEndRowEnd>
          </SiteFooterEndRow>
        </SiteFooterInner>
      </SiteFooterWrap>
    </SiteFooterOuter>
  );
};

export const SiteFooterLinkList = (
  props: PropsWithChildren<{ className?: string; heading?: string }>
) => {
  const [shouldShowContent, setShouldShowContent] = useState(false);
  const contentRef = useRef<HTMLUListElement>(null);
  const handleHeaderClick = () => {
    if (shouldShowContent) {
      animateCloseContent();
      setShouldShowContent(false);
    } else {
      animateOpenContent();
      setShouldShowContent(true);
    }
  };
  const animateOpenContent = () => {
    clearAnimatedProps(contentRef.current, "height");
    gsap.from(contentRef.current, {
      height: 0,
      duration: 0.15,
      ease: "power3.out",
    });
  };
  const animateCloseContent = () => {
    gsap.to(contentRef.current, {
      height: 0,
      duration: 0.15,
      ease: "power3.out",
    });
  };
  useOnMount(() => {
    gsap.set(contentRef.current, {
      height: 0,
    });
  });
  return (
    <SiteFooterLinkListWrap
      className={cx(props.className, shouldShowContent && "open")}
    >
      <header onClick={handleHeaderClick}>
        <h3>{props.heading}</h3>
        <ChevronDownIcon />
      </header>
      <ul ref={contentRef}>{props.children}</ul>
    </SiteFooterLinkListWrap>
  );
};

const SiteFooterLinkListWrap = styled.div`
  + * {
    ${uptoTabletMd} {
      &:before {
        display: block;
        content: "";
        height: 1px;
        background-color: currentColor;
        opacity: 0.1;
      }
    }
  }
  ${uptoPhoneLg} {
    flex: 0 0 40%;
    &:nth-child(even) {
      flex: 0 0 60%;
    }
  }
  ${fromPhoneLg} {
    flex: 0 0 50%;
  }
  ${fromDesktop} {
    flex: 1 1 auto;
    &:last-child {
      flex: 0 0 auto;
    }
  }
  header {
    display: flex;
    align-items: center;
    padding: 1.5em 0;
    ${fromDesktop} {
      padding-top: 0;
    }
    svg {
      display: block;
      flex: 0 0 auto;
      transition: 0.1s;
      ${fromTabletMd} {
        display: none;
      }
    }
  }
  &.open {
    header {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  h3 {
    flex: 1 1 auto;
    font-size: inherit;
    ${fromTabletMd} {
      opacity: 0.4;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 85%;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    ${fromTabletMd} {
      // overrides any values set by gsap
      height: auto !important;
    }
  }
  li {
    &:first-child {
      margin-top: -0.5em;
    }
    &:last-child {
      margin-bottom: 1em;
    }
    ${uptoTabletMd} {
      &:before {
        display: block;
        content: "";
        height: 1px;
        background-color: currentColor;
        opacity: 0.05;
      }
    }
  }
  button {
    appearance: none;
    border: 0;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    color: inherit;
    cursor: pointer;
  }
  sup {
    opacity: 0.5;
  }
  a,
  button {
    text-decoration: none;
    padding-top: 1em;
    padding-bottom: 1em;
    font-weight: 500;
    display: block;
    ${fromTabletMd} {
      display: inline-block;
      font-weight: 600;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      &:hover {
        color: var(--footerLinkHoverColor);
        sup {
          opacity: 0.8;
        }
      }
    }
  }
`;

const SiteFooterBackdropLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

export const SiteFooterBackdrop = (props: PropsWithChildren<{}>) => {
  const [portal, setPortal] = useState<HTMLDivElement | null>(null);
  useOnMount(() => {
    setPortal(
      document.getElementById(
        "site-footer-backdrop-layer"
      ) as HTMLDivElement | null
    );
  });
  return portal ? createPortal(props.children, portal) : null;
};

export const siteFooterQuery = graphql`
  query SiteFooter {
    solutions: allDatoCmsSolutionPage(sort: { position: ASC }) {
      nodes {
        slug
        name
      }
    }
  }
`;

export default SiteFooter;
