import { isBrowser } from "../../../../environment";
import { StoryRecord } from "../../../../types/tines.types";
import { PagerDutyIcon } from "../notificationIcons/PagerDutyIcon";

// cspell:disable

export const makeSecurityDemoStory = () => ({
  dropZoneActionNameMatcher: "PagerDuty",
  notification: {
    icon: PagerDutyIcon,
    heading: "PagerDuty",
    body: "New incident #33062 created",
  },
  maxEvents: 7,
  possibleTriggerValues: ["Low severity", "High severity"] as [string, string],
  inspectable: true,
  story: {
    standardLibVersion: "17",
    actionRuntimeVersion: "4",
    agents: [
      {
        name: "Receive alerts",
        options: JSON.parse(
          '{"path":"webhook-path","secret":"***","verbs":"get,post"}'
        ),
        position: { x: -855, y: 675 },
        type: "webhook",
      },
      {
        name: "High severity",
        options: JSON.parse(
          '{"rules":[{"type":"field==value","value":"CRITICAL","path":"<<extract_threats.individual_ioc.severity>>"}]}'
        ),
        position: {
          get x() {
            return isBrowser && window.innerWidth > 768 ? -705 : -720;
          },
          y: 930,
        },
        type: "trigger",
      },
      {
        name: "Low severity",
        options: JSON.parse(
          '{"rules":[{"type":"field==value","value":"HIGH","path":"<<extract_threats.individual_ioc.severity>>"}]}'
        ),
        position: { x: -930, y: 930 },
        type: "trigger",
      },
      {
        name: "Extract threats",
        options: JSON.parse(
          '{"mode":"explode","path":"=receive_alerts.body.iocs","to":"individual_ioc","limit":"500"}'
        ),
        position: { x: -855, y: 735 },
        type: "eventTransformation",
      },
      {
        name: "Enrich in VirusTotal",
        options: JSON.parse(
          '{"url":"https://www.virustotal.com/api/v3/ip_addresses/<<extract_threats.individual_ioc.ioc>>","method":"get","headers":{"x-apikey":"<<CREDENTIAL.virustotal>>"},"content_type":"json"}'
        ),
        position: { x: -855, y: 795 },
        type: "httpRequest",
      },
      {
        name: "Open a ticket in Jira",
        options: JSON.parse(
          '{"url":"https://<<RESOURCE.jira_domain>>/rest/api/2/issue","content_type":"json","method":"post","payload":{"fields":{"project":{"key":"DEMO"},"issuetype":{"name":"Task"},"priority":{"name":"Highest"},"description":"*Virus Total Analysis Stats*:\\n<<enrich_in_virustotal.body.data.attributes.last_analysis_stats>>\\n\\n*Last Analysis Date*:\\n<<DATE(enrich_in_virustotal.body.data.attributes.last_analysis_date, \\"%Y-%m-%dT%l:%M:%S%z\\")>>\\n","summary":"IOC Observed: <<extract_threats.individual_ioc.ioc>>"}},"basic_auth":["<<RESOURCE.jira_user>>","<<CREDENTIAL.jira>>"]}'
        ),
        position: { x: -855, y: 855 },
        type: "httpRequest",
      },
      {
        name: "Notify Slack channel",
        options: JSON.parse(
          '{"url":"https://<<slack_webhook_url>>","content_type":"json","method":"post","payload":{"text":"A ticket has been opened for a High Severity IOC. Click this link to view the ticket.\\nhttps://<<RESOURCE.jiradomain>>/browse/<<open_a_ticket_in_jira.body.key>> "},"headers":{}}'
        ),
        position: { x: -930, y: 1020 },
        type: "httpRequest",
      },
      {
        get name() {
          return isBrowser && window.innerWidth > 768
            ? "Create incident in PagerDuty"
            : "Send to PagerDuty";
        },
        options: JSON.parse(
          '{"url":"https://api.pagerduty.com/incidents","content_type":"json","method":"post","payload":{"incident":{"type":"incident","title":"A new Critical Severity IOC has been spotted","service":{"id":"<<service_reference>>","type":"service_reference"},"urgency":"high","body":{"type":"IOC observed","details":"Check this ticket for details https://<<RESOURCE.jiradomain>>/browse/<<open_a_ticket_in_jira.body.key>> "}}},"headers":{"Authorization":"Token token=<<CREDENTIAL.pagerduty>>","From":"<<user_email>>"}}'
        ),
        position: {
          get x() {
            return isBrowser && window.innerWidth > 768 ? -705 : -720;
          },
          y: 1020,
        },
        type: "httpRequest",
      },
    ],
    links: [
      { sourceIdentifier: 5, receiverIdentifier: 1 },
      { sourceIdentifier: 3, receiverIdentifier: 4 },
      { sourceIdentifier: 0, receiverIdentifier: 3 },
      { sourceIdentifier: 4, receiverIdentifier: 5 },
      { sourceIdentifier: 1, receiverIdentifier: 7 },
      { sourceIdentifier: 5, receiverIdentifier: 2 },
      { sourceIdentifier: 2, receiverIdentifier: 6 },
    ],
    diagramNotes: [],
  } as unknown as StoryRecord,
});

// cspell:enable
