import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import SkyTunnelIcon from "../../illustrations/spots/SkyTunnelIcon";
import {
  fromDesktopMl,
  fromTablet,
  onlyPhones,
  uptoPhoneLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";
import { fluidFontSize, fluidLineHeight } from "../../../styles/helpers.styles";
import { maxPageContentWidthStyleObject } from "../../../styles/maxPageContentWidth.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import BookADemoButton from "../../utilities/BookADemoButton";
import Button from "../../forms/Button";
import ComboFontHeading from "../../typography/ComboFontHeading";
import { FromTabletMdSpan } from "../../mediaQueries/FromTabletMd";
import Spacing from "../../layout/Spacing";

const AWSPageCTASectionContainer = styled.section``;

const Inner = styled.div`
  ${maxPageContentWidthStyleObject};
`;

const Banner = styled.div`
  display: grid;
  grid-gap: 2em;
  background-color: ${colors.orange};
  border-radius: 1em;
  color: ${colors.white};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  ${onlyPhones} {
    grid-template-columns: minmax(10em, 1fr) auto;
  }
  ${fromTablet} {
    grid-template-columns: minmax(21em, 1fr) auto;
    align-items: center;
  }
  ${fromDesktopMl} {
    display: block;
    position: relative;
  }
`;

const Header = styled.header``;
const IconWrapper = styled.div`
  svg {
    max-height: 20rem;
  }
  ${uptoPhoneLg} {
    padding-top: 4em;
    margin-right: -12.5%;
    svg {
      max-height: 18rem;
    }
  }
  ${fromDesktopMl} {
    position: absolute;
    right: ${rSize("cardSectionPadding")};
    top: 50%;
    transform: translateY(-50%);
  }
`;

const AWSPageCTASection = () => {
  return (
    <AWSPageCTASectionContainer>
      <Inner>
        <Banner>
          <Header>
            <ComboFontHeading
              noWrap
              snippets={[
                ["Automate", "sans"],
                [
                  <>
                    cloud security
                    <br />
                  </>,
                  "serif",
                ],
                "with AWS and Tines",
              ]}
              className={css`
                ${fluidFontSize(24, 48)}
                ${fluidLineHeight(30, 48)};
              `}
            />
            <Spacing size="md" />
            <div>
              <Button
                color="orange"
                href="https://www.tines.com/community-edition?utm_campaign=partner-landing-page&utm_source=aws&utm_medium=website"
                title="Get started"
                appearance="filled-white"
              />
              <FromTabletMdSpan>
                <Spacing inline size="1em" />
                <BookADemoButton appearance="outlined" color="white" />
              </FromTabletMdSpan>
            </div>
          </Header>
          <IconWrapper>
            <SkyTunnelIcon />
          </IconWrapper>
        </Banner>
      </Inner>
    </AWSPageCTASectionContainer>
  );
};

export default AWSPageCTASection;
