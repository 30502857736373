import { onFontReady } from "./fonts.utils";
import { useOnMount } from "./lifeCycle.utils";

export const useOnResize = (fn: () => void) => {
  useOnMount(() => {
    window.addEventListener("resize", fn);
    setTimeout(fn);
    onFontReady(fn);
    return () => {
      window.removeEventListener("resize", fn);
    };
  });
};
