import { Link } from "gatsby";
import PageSection from "../reusableSections/PageSection";
import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import {
  fromDesktopMl,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import CurlToTinesIllustration from "./CurlToTinesIllustration";
import { ConnectedBlocksIcon } from "../illustrations/spots/ConnectedBlocksIcon";

const Inner = styled.figure`
  margin: 0;
  background-color: ${colors.lightest};
  border: 1px dashed ${colors.purple200};
  border-radius: ${rSize("radius")};
  padding: ${rSize("cardSectionPadding")};
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
`;

const Text = styled.figcaption`
  display: grid;
  grid-template-columns: minmax(auto, 1fr) auto;
  grid-template-rows: minmax(auto, 1fr) auto auto;
  grid-template-areas: "heading icon" "p p";
  grid-gap: 1em;
  ${fromTabletLg} {
    padding-right: 2em;
    grid-template-rows: auto minmax(auto, 1fr) auto;
    grid-template-areas: "heading heading" "p p" "icon icon";
  }
  ${fromDesktopMl} {
    padding-right: 4em;
  }
  h2 {
    grid-area: heading;
    font-size: 2.6rem;
    align-self: center;
    ${fromTabletLg} {
      font-size: 3.2rem;
    }
    max-width: 10em;
    font-family: ${serif};
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: -0.01em;
  }
  p {
    grid-area: p;
    a {
      font-weight: 700;
    }
  }
`;

const IconWrap = styled.div`
  grid-area: icon;
  align-self: center;
  ${fromTabletLg} {
    margin-top: 2em;
    align-self: end;
  }
`;

export const BuildYourOwnConnectionsSection = () => {
  return (
    <PageSection relative>
      <Inner>
        <Text>
          <h2>Build your own connections</h2>
          <p>
            With Tines, you can easily take any action that has a defined API
            using an{" "}
            <Link to="/docs/actions/types/http-request">HTTP request</Link>. To
            build even more quickly,{" "}
            <Link to="/docs/actions/shortcuts#import-from-curl">
              copy a cURL command
            </Link>{" "}
            and paste it into the storyboard.
          </p>
          <IconWrap>
            <ConnectedBlocksIcon />
          </IconWrap>
        </Text>
        <CurlToTinesIllustration />
      </Inner>
    </PageSection>
  );
};
