import { styled } from "@linaria/react";
import RigidDotGrid from "../decorations/RigidDotGrid";
import { BaseColorName, color, colors } from "../../styles/colors.styles";
import { PropsWithChildren, useRef } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { mix } from "chroma-js";
import { uptoTabletLg } from "../../styles/breakpointsAndMediaQueries.styles";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 84rem;
  pointer-events: none;
  ${uptoTabletLg} {
    display: none;
  }
`;

type BaubleDef = { id: string; x: number; y: number; color: BaseColorName };

const baubleDefs: BaubleDef[] = [
  { id: "1", x: 6, y: 1, color: "green" },
  { id: "2", x: 11, y: 3, color: "orange" },
  { id: "3", x: 15, y: 4, color: "pink" },
  { id: "4", x: 14, y: 7, color: "yellow" },
  { id: "5", x: 9, y: 6, color: "purple" },
  { id: "6", x: -8, y: 5, color: "pink" },
  { id: "7", x: -10, y: 3, color: "purple" },
  { id: "8", x: -13, y: 4, color: "green" },
  { id: "9", x: -15, y: 7, color: "orange" },
  // off canvas baubles on the sides (off canvas relative to a 14" MacBook)
  { id: "-4", x: -18, y: 5, color: "pink" },
  { id: "-5", x: 18, y: 6, color: "green" },
  // off canvas baubles on the top
  { id: "-1", x: -14, y: -2, color: "green" },
  { id: "-2", x: -2, y: -2, color: "orange" },
  { id: "-3", x: 12, y: -2, color: "purple" },
];

type CableDef = { id: string; start: string; end: string; dangle: number };

const cableDefs: CableDef[] = [
  { id: "1a", start: "1", end: "-3", dangle: 1 },
  { id: "1b", start: "1", end: "5", dangle: 0.825 },
  { id: "2a", start: "2", end: "4", dangle: 0.85 },
  { id: "-3a", start: "-3", end: "3", dangle: 0.8 },
  { id: "4a", start: "4", end: "3", dangle: 0.9 },
  { id: "5a", start: "5", end: "4", dangle: 1.4 },
  { id: "-1a", start: "-1", end: "7", dangle: 0.7 },
  { id: "7a", start: "7", end: "6", dangle: 1 },
  { id: "7b", start: "7", end: "5", dangle: 1.5 },
  { id: "7c", start: "7", end: "-2", dangle: 1.1 },
  { id: "8a", start: "8", end: "5", dangle: 1.4 },
  { id: "9a", start: "9", end: "8", dangle: 0.9 },
  { id: "-4a", start: "-4", end: "8", dangle: 1.1 },
  { id: "3b", start: "3", end: "-5", dangle: 0.9 },
];

export const ProductExplorerPageHeaderGraphics = ({
  light,
  className,
}: {
  light?: boolean;
  className?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    const scrollHandler = () => {
      const scrollY = window.scrollY;
      if (ref.current)
        ref.current.style.transform = `translateY(${scrollY / 8}px)`;
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  return (
    <Container className={className} ref={ref}>
      <RigidDotGrid
        cellSize={50}
        dotSize={1}
        // color={colors.purple300}
        color="transparent"
        children={({ cellSize }) => {
          return (
            <>
              {cableDefs.map(({ start, end, id, dangle }) => {
                const startBauble = baubleDefs.find(b => b.id === start)!;
                const endBauble = baubleDefs.find(b => b.id === end)!;
                return (
                  <Cable
                    key={id}
                    from={[startBauble.x, startBauble.y]}
                    to={[endBauble.x, endBauble.y]}
                    cellSize={cellSize}
                    dangle={dangle}
                  />
                );
              })}
              {baubleDefs.map(({ x, y, id, color }, i) => {
                const Icon = shapes[i];
                return (
                  <Bauble
                    key={id}
                    x={x}
                    y={y}
                    cellSize={cellSize}
                    light={light}
                    colorName={color}
                  >
                    {Icon && <Icon light={light} colorName={color} />}
                  </Bauble>
                );
              })}
            </>
          );
        }}
      />
    </Container>
  );
};

const CableSvg = styled.svg`
  overflow: visible;
  position: absolute;
  top: 0;
  left: 50%;
`;

const Cable = ({
  from,
  to,
  cellSize,
  dangle,
}: {
  from: [number, number];
  to: [number, number];
  cellSize: number;
  dangle: number;
}) => {
  const boxWidth = Math.abs((from[0] - to[0]) * cellSize);
  const boxHeight = Math.abs((from[1] - to[1]) * cellSize);
  const displayWidth = boxWidth;
  const displayHeight = boxHeight * 1.5;

  const fromLeftToRight = from[0] < to[0];
  const fromHighToLow = from[1] < to[1];

  const x1 = fromLeftToRight ? 0 : boxWidth;
  const x2 = fromLeftToRight ? boxWidth : 0;
  const y1 = fromHighToLow ? 0 : boxHeight;
  const y2 = fromHighToLow ? boxHeight : 0;

  // Determine control points for the Bezier curve
  const cp1: [number, number] = [boxWidth * 0.15, boxHeight * 2.25 * dangle];
  const cp2: [number, number] = [boxWidth * 0.85, boxHeight * 1.5 * dangle];

  return (
    <CableSvg
      width={displayWidth}
      height={displayHeight}
      viewBox={`0 0 ${displayWidth} ${displayHeight}`}
      fill="none"
      style={{
        transform: `translate(${Math.min(
          from[0] * cellSize,
          to[0] * cellSize
        )}px,${
          Math.min(from[1] * cellSize, to[1] * cellSize) + 0.5 * cellSize
        }px)`,
      }}
    >
      <path
        d={`M ${x1},${y1} C ${cp1[0]},${cp1[1]} ${cp2[0]},${cp2[1]} ${x2},${y2}`}
        stroke={colors.purple100}
        strokeWidth="1"
      />
    </CableSvg>
  );
};

const BaubleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  border-radius: 50%;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple200};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    max-height: 50%;
    height: auto;
  }
`;

const Bauble = ({
  x,
  y,
  cellSize,
  colorName,
  light,
  children,
}: PropsWithChildren<{
  x: number;
  y: number;
  cellSize: number;
  colorName: BaseColorName;
  light?: boolean;
}>) => {
  return (
    <BaubleContainer
      style={{
        transform: `translate(${(x - (0.5 + 0.15 / 2)) * cellSize}px,${
          (y - 0.15 / 2) * cellSize
        }px)`,
        width: cellSize * 1.15,
        height: cellSize * 1.15,
        backgroundColor: light
          ? mix(colors.lightest, color(colorName, 50)).hex()
          : color(colorName, 50),
        borderColor: color(colorName, light ? 100 : 200),
      }}
    >
      {children}
    </BaubleContainer>
  );
};

const Hexagon = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="24" height="26" viewBox="0 0 24 26">
    <path
      d="M11.75 0.721688C11.9047 0.632372 12.0953 0.632371 12.25 0.721688L22.5083 6.64434C22.663 6.73365 22.7583 6.89872 22.7583 7.07735V18.9226C22.7583 19.1013 22.663 19.2663 22.5083 19.3557L12.25 25.2783C12.0953 25.3676 11.9047 25.3676 11.75 25.2783L1.49167 19.3557C1.33697 19.2663 1.24167 19.1013 1.24167 18.9226V7.07735C1.24167 6.89872 1.33697 6.73365 1.49167 6.64434L11.75 0.721688Z"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const Star = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="28" height="28" viewBox="0 0 28 28">
    <path
      d="M13.561 0.849184C13.7505 0.501712 14.2495 0.501714 14.439 0.849182L18.0067 7.39097C18.2224 7.78656 18.6045 8.06421 19.0474 8.14715L26.3715 9.51869C26.7605 9.59155 26.9147 10.0661 26.6428 10.3537L21.5237 15.7682C21.2141 16.0957 21.0682 16.5449 21.1261 16.9918L22.085 24.3812C22.1359 24.7737 21.7323 25.067 21.3747 24.8973L14.6432 21.7019C14.2362 21.5086 13.7638 21.5086 13.3568 21.7019L6.62527 24.8972C6.26773 25.067 5.86409 24.7737 5.91501 24.3812L6.87386 16.9918C6.93184 16.5449 6.78588 16.0957 6.47631 15.7682L1.35718 10.3537C1.08528 10.0661 1.23945 9.59155 1.62848 9.51869L8.95256 8.14715C9.39546 8.06421 9.77761 7.78656 9.99335 7.39097L13.561 0.849184Z"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const Square = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="1.5"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const FourPointStar = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path
      d="M18.8147 0.960407C19.2243 0.794094 19.6324 1.20217 19.4661 1.61178L16.2027 9.6489C16.0558 10.0108 16.0558 10.4156 16.2027 10.7775L19.4661 18.8146C19.6324 19.2242 19.2243 19.6323 18.8147 19.466L10.7776 16.2027C10.4157 16.0558 10.0108 16.0558 9.64895 16.2027L1.61183 19.466C1.20222 19.6323 0.794147 19.2242 0.960461 18.8146L4.22378 10.7775C4.3707 10.4156 4.3707 10.0108 4.22378 9.6489L0.960461 1.61178C0.794148 1.20217 1.20222 0.794094 1.61183 0.960407L9.64895 4.22372C10.0108 4.37065 10.4157 4.37065 10.7776 4.22372L18.8147 0.960407Z"
      fill={light ? "none" : color(colorName, 300)}
      stroke={color(colorName, light ? 200 : 500)}
    />
  </svg>
);

const Pentagon = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="28" height="28" viewBox="0 0 28 28">
    <path
      d="M13.7061 0.83156C13.8814 0.704237 14.1186 0.704238 14.2939 0.831559L26.4331 9.65122C26.6084 9.77854 26.6817 10.0042 26.6147 10.2102L21.978 24.4807C21.911 24.6868 21.7191 24.8262 21.5025 24.8262H6.49755C6.28094 24.8262 6.08896 24.6868 6.02202 24.4807L1.38525 10.2102C1.31831 10.0042 1.39164 9.77854 1.56689 9.65122L13.7061 0.83156Z"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const Triangle = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="26" height="25" viewBox="0 0 26 25">
    <path
      d="M12.567 0.749999C12.7594 0.416666 13.2406 0.416665 13.433 0.75L24.6913 20.25C24.8838 20.5833 24.6432 21 24.2583 21H1.74167C1.35677 21 1.11621 20.5833 1.30866 20.25L12.567 0.749999Z"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const EightPointStar = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path
      d="M15.6016 1.35298C15.8017 1.08912 16.1983 1.08912 16.3984 1.35298L19.5342 5.48848C19.8617 5.92041 20.3967 6.14204 20.9337 6.0682L26.0753 5.36127C26.4033 5.31617 26.6838 5.59666 26.6387 5.92472L25.9318 11.0663C25.858 11.6033 26.0796 12.1383 26.5115 12.4658L30.647 15.6016C30.9109 15.8017 30.9109 16.1983 30.647 16.3984L26.5115 19.5342C26.0796 19.8617 25.858 20.3967 25.9318 20.9337L26.6387 26.0753C26.6838 26.4033 26.4033 26.6838 26.0753 26.6387L20.9337 25.9318C20.3967 25.858 19.8617 26.0796 19.5342 26.5115L16.3984 30.647C16.1983 30.9109 15.8017 30.9109 15.6016 30.647L12.4658 26.5115C12.1383 26.0796 11.6033 25.858 11.0663 25.9318L5.92472 26.6387C5.59666 26.6838 5.31617 26.4033 5.36127 26.0753L6.0682 20.9337C6.14204 20.3967 5.92041 19.8617 5.48848 19.5342L1.35298 16.3984C1.08912 16.1983 1.08912 15.8017 1.35298 15.6016L5.48848 12.4658C5.92041 12.1383 6.14204 11.6033 6.0682 11.0663L5.36127 5.92472C5.31617 5.59666 5.59666 5.31617 5.92472 5.36127L11.0663 6.0682C11.6033 6.14204 12.1383 5.92041 12.4658 5.48848L15.6016 1.35298Z"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const Circle = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <circle
      cx="12"
      cy="12"
      r="11.5"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const Diamond = ({
  colorName,
  light,
}: {
  colorName: BaseColorName;
  light?: boolean;
}) => (
  <svg width="30" height="30" viewBox="0 0 30 30">
    <path
      d="M15.3536 1.06066L28.9393 14.6464C29.1346 14.8417 29.1346 15.1583 28.9393 15.3536L15.3536 28.9393C15.1583 29.1346 14.8417 29.1346 14.6464 28.9393L1.06066 15.3536C0.865398 15.1583 0.865398 14.8417 1.06066 14.6464L14.6464 1.06066C14.8417 0.865398 15.1583 0.865398 15.3536 1.06066Z"
      fill={light ? "none" : color(colorName, 200)}
      stroke={color(colorName, light ? 200 : 400)}
    />
  </svg>
);

const shapes = [
  Hexagon,
  Star,
  Square,
  FourPointStar,
  Pentagon,
  Triangle,
  EightPointStar,
  Circle,
  Diamond,
  Hexagon,
  Diamond,
];
