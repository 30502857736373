import { styled } from "@linaria/react";
import {
  DatoCmsButton,
  DatoCmsTextCtaWithButtonsSection,
} from "../../../../graphql-types";
import { ButtonGroupCenterFlex } from "../../forms/Button";
import PageSection from "../../reusableSections/PageSection";
import { LPButtonGroup } from "../LPButtonGroup";
import { serif } from "../../../styles/fonts.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";

const Contents = styled.div`
  text-align: center;
  p {
    font-family: ${serif};
    font-size: 2rem;
    font-weight: 400;
    max-width: 25em;
    margin-left: auto;
    margin-right: auto;
  }
  > * {
    + * {
      margin-top: ${rSize("lg")};
    }
  }
`;

export const LPTextCtaWithButtonsSection = ({
  section,
}: {
  section: DatoCmsTextCtaWithButtonsSection;
}) => {
  return (
    <PageSection
      id={section.anchorOnPage}
      className="TextCtaWithButtonsSection"
    >
      <Contents>
        <p>{section.body}</p>
        {!!section.ctaButtons?.length && (
          <ButtonGroupCenterFlex>
            <LPButtonGroup buttons={section.ctaButtons as DatoCmsButton[]} />
          </ButtonGroupCenterFlex>
        )}
      </Contents>
    </PageSection>
  );
};
