const PricingIconSkyscraper = () => {
  return (
    <svg viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M99.8553 121.783H56.8376V95.9065H117.538L99.8553 121.783Z"
        fill="#32313B"
      />
      <path
        d="M85.7832 45.4316H58.1243L45.657 60.2184V110.31H74.83L85.7832 95.5238V45.4316Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M74.8298 60.218H45.657V110.31H74.8298V60.218Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M74.8301 60.2179L85.7834 45.4312"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M65.8198 56.6648H77.4151L82.9723 49.5667H72.8436L65.8198 56.6648Z"
        fill="#32313B"
      />
      <path
        d="M78.5744 37.5903H60.244L54.8394 43.9743V56.1245H73.7843L78.5744 49.7405V37.5903Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.7844 43.9736H54.8395V56.1238H73.7844V43.9736Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.7844 43.9739L78.5745 37.5898"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M66.5621 42.6279H74.6851L77.5413 38.6631H72.1354L66.5621 42.6279Z"
        fill="#32313B"
      />
      <path
        d="M72.5302 24.3286H64.5142L61.7727 27.7668V42.0736H70.2012L72.5302 38.6354V24.3286Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M70.2012 27.7666H61.7728V42.0734H70.2012V27.7666Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M70.2014 27.7666L72.5303 24.3284"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M66.8026 26.5834H70.7028L71.5161 25.1323H68.0966L66.8026 26.5834Z"
        fill="#32313B"
      />
      <path
        d="M68.4753 25.5303C68.4753 25.9996 67.9593 26.0472 67.3229 26.0472C66.6861 26.0472 66.17 25.9996 66.17 25.5303V16.2776C66.17 15.8085 66.6859 15.428 67.3229 15.428C67.9593 15.428 68.4753 15.8085 68.4753 16.2776V25.5303Z"
        fill="#8173E6"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M61.997 48.0991H58.2999V51.7221H61.997V48.0991Z"
        fill="#32313B"
      />
      <path
        d="M69.9079 48.0991H66.2112V51.7221H69.9079V48.0991Z"
        fill="#32313B"
      />
      <path
        d="M113.539 68.3173L95.6466 55.4529H86.4993L79.3799 63.8814V104.18H107.329L113.539 95.7516V68.3173Z"
        fill="#FFBD59"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M107.328 104.18H79.3796V63.8816H89.4356L107.328 76.7463V104.18Z"
        fill="#FFBD59"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M89.4355 63.8814L95.6461 55.4529"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M107.328 76.7461L113.539 68.3174"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M56.7474 65.1807H49.6497V69.2841H56.7474V65.1807Z"
        fill="#32313B"
      />
      <path
        d="M70.3888 65.1807H63.2913V69.2841H70.3888V65.1807Z"
        fill="#32313B"
      />
      <path
        d="M56.7474 74.7686H49.6497V78.8718H56.7474V74.7686Z"
        fill="#32313B"
      />
      <path
        d="M70.3888 74.7686H63.2913V78.8718H70.3888V74.7686Z"
        fill="#32313B"
      />
      <path
        d="M70.3888 84.2993H63.2913V88.4028H70.3888V84.2993Z"
        fill="#32313B"
      />
      <path
        d="M56.7474 92.9998H49.6497V97.1032H56.7474V92.9998Z"
        fill="#32313B"
      />
      <path
        d="M70.3888 93.8303H63.2913V97.9338H70.3888V93.8303Z"
        fill="#32313B"
      />
      <path
        d="M60.0386 84.6147H45.4373L34.1091 94.0296V121.345H50.3023L60.0386 111.929V84.6147Z"
        fill="#FFBD59"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M50.3016 121.344V94.0293H34.1085"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M50.3016 94.0293L60.038 84.6145"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M107.274 97.2722H79.4342L74.8302 103.402H63.8315L50.2666 114.416V121.325H94.3793L107.274 104.18V97.2722Z"
        fill="#49B889"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M60.3154 103.402H74.8302L79.4342 97.2722H107.274L94.3793 114.416H50.2666L60.3154 103.402Z"
        fill="#49B889"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M50.8067 114.265L60.315 103.402H74.8299L79.6427 97.2231H91.2294L50.8067 114.265Z"
        fill="#32313B"
      />
      <path
        d="M94.379 121.324V114.416"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M87.3464 108.345C91.7113 108.345 95.2497 107.434 95.2497 106.311C95.2497 105.187 91.7113 104.276 87.3464 104.276C82.9816 104.276 79.4431 105.187 79.4431 106.311C79.4431 107.434 82.9816 108.345 87.3464 108.345Z"
        fill="#32313B"
      />
      <path
        d="M89.084 104.283L87.1532 103.076V88.5886C87.1983 88.5346 83.4827 90.7023 83.4827 90.7023V103.076L81.1173 104.533C79.9216 105.3 79.2034 105.665 79.2034 105.974C79.2034 106.734 81.9405 107.35 85.318 107.35C88.6949 107.35 91.4321 106.734 91.4321 105.974C91.4321 105.665 90.1761 104.897 89.084 104.283Z"
        fill="#EB81AF"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M90.4479 79.5916C90.7025 78.9563 90.846 78.2658 90.846 77.5397C90.846 74.4866 88.3705 72.0117 85.3172 72.0117C82.2645 72.0117 79.788 74.4866 79.788 77.5397C79.788 78.2658 79.932 78.9563 80.1862 79.5916C77.9994 81.1801 76.5751 83.7572 76.5751 86.6677C76.5751 91.4955 80.4892 95.4104 85.317 95.4104C90.1453 95.4104 94.0597 91.4953 94.0597 86.6677C94.0601 83.7572 92.6354 81.1799 90.4479 79.5916Z"
        fill="#49B889"
        stroke="#32313B"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M83.4043 95.177V96.4074C83.4043 96.4074 83.943 97.6382 85.1736 97.6382C86.404 97.6382 87.327 96.9461 87.327 96.9461L87.1734 95.177C87.1734 95.177 84.3275 95.9462 83.4043 95.177Z"
        fill="#32313B"
      />
    </svg>
  );
};

export default PricingIconSkyscraper;
