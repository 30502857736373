import { styled } from "@linaria/react";

type Props = React.PropsWithChildren<{}>;

const PillListWrapper = styled.div``;
const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  > * {
    display: block;
    margin-top: 0.75em;
    margin-right: 0.75em;
    box-sizing: border-box;
  }
  a {
    &:hover {
      filter: brightness(1.02);
    }
  }
`;

/** deprecated */
const PillListLegacy = (props: Props) => {
  return (
    <PillListWrapper>
      <List>{props.children}</List>
    </PillListWrapper>
  );
};

export default PillListLegacy;
