import { styled } from "@linaria/react";
import { externalLinkAttr } from "../../../../constants/externalLinks.constants";
import ComboFontHeading from "../../../typography/ComboFontHeading";
import StandardArticle from "../../../articles/StandardArticle";
import ReportSectionHeader from "../../ReportSectionHeader";
import ReportCard, { ReportCardInnerAligner } from "../../ReportCard";
import { ReportBaseSectionStyle } from "../../ReportBaseStyles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import { colorsV4, withOpacity } from "../../../../styles/colorsV4.styles";
import DonutChart from "../../charts/DonutChart";
import {
  fromTablet,
  onlyPhones,
} from "../../../../styles/breakpointsAndMediaQueries.styles";
import { css } from "linaria";
import MHChartDemographics from "../charts/MHChartDemographics";

const SOCMethodologySectionContainer = styled.section`
  ${ReportBaseSectionStyle};
  > * + * {
    margin-top: ${rSize("gap")};
  }
  ${onlyPhones} {
    text-align: center;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const SOCMethodologySection = () => {
  return (
    <ReportSection
      id="methodology-and-participant-demographics"
      fullWidthWhenSmall
    >
      <SOCMethodologySectionContainer>
        <ReportSectionHeader icon="retroMobilePhone">
          <ComboFontHeading
            snippets={[
              [
                <>
                  Methodology and
                  <br />
                </>,
              ],
              [<>participant demographics</>, "serif"],
            ]}
          />
        </ReportSectionHeader>
        <ReportCard
          className={css`
            ${onlyPhones} {
              text-align: center;
            }
          `}
        >
          <StandardArticle>
            <p>
              In order to provide greater context around these findings, here
              are more details on who we surveyed and the methodology used.
              Starting on April 21, 2022, we surveyed 1,027 members of security
              teams from both the United States and Europe. The survey was
              conducted online via Pollfish using organic sampling. Learn more
              about the Pollfish methodology{" "}
              <a
                href="https://www.pollfish.com/methodology"
                {...externalLinkAttr}
              >
                here
              </a>
              .
            </p>
          </StandardArticle>
        </ReportCard>

        <MHChartDemographics />

        <Grid>
          <ReportCard centered>
            <ReportCardInnerAligner>
              <h3>What best describes your role?</h3>
              <DonutChart
                seoTitle="What best describes your role?"
                header={["Level", "Respondents"]}
                data={[
                  ["Entry-level", 279],
                  ["Mid-level", 357],
                  ["Senior-level", 391],
                ]}
                donutPercentage={0.375}
                height={250}
                colors={[colorsV4.green, colorsV4.orange, colorsV4.purple]}
              />
            </ReportCardInnerAligner>
          </ReportCard>

          <ReportCard centered>
            <ReportCardInnerAligner>
              <h3>How many years have you worked in cybersecurity?</h3>
              <DonutChart
                seoTitle="How many years have you worked in cybersecurity?"
                header={["Duration", "Respondents"]}
                data={[
                  ["< 1", 170],
                  ["1–5", 224],
                  ["6–10", 257],
                  ["11–15", 188],
                  ["15+", 188],
                ]}
                donutPercentage={0.375}
                height={250}
              />
            </ReportCardInnerAligner>
          </ReportCard>
        </Grid>
      </SOCMethodologySectionContainer>
      <ReportSummaryCard
        cornerDotsColor={withOpacity(colorsV4.warmBlack, 0.25)}
        standalone
      >
        <blockquote>
          <p>
            Now, with context around who our respondents were — security team
            members, typically at the senior level who have been in their
            workplaces for six to ten years — let's take a closer look at what
            we uncovered.
          </p>
        </blockquote>
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default SOCMethodologySection;
