import { styled } from "@linaria/react";
import { graphql } from "gatsby";
import SEO from "../../components/utilities/SEO";
import Spacing from "../../components/layout/Spacing";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Page from "../../templates/Page.template";
import { PageComponentProps } from "../../types/gatsby.types";
import { CaseStudiesIndexPageQuery } from "../../../graphql-types";
import ogImage from "../../../static/images/og/tines-case-studies.png";
import CaseStudyBookCardLink from "../../components/general/CaseStudyBookCardLink";
import {
  LayoutWithSidebar,
  LayoutWithSidebarContent,
} from "../../components/layout/LayoutWithSidebar";
import StandardSidebar from "../../components/layout/StandardSidebar";
import GlobalSearchBar from "../../components/search/GlobalSearchBar";
import { AdminToolBarWithEditInDatoCmsButton } from "../../components/site/AdminToolbar";

const EntryLinkGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${rSize("sectionPadding")} ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromDesktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CaseStudiesIndexPage = (
  props: PageComponentProps & {
    data: CaseStudiesIndexPageQuery;
  }
) => {
  const caseStudies = props.data.caseStudies?.nodes ?? [];
  return (
    <Page {...props}>
      <SEO title="All case studies" image={ogImage} />
      <AdminToolBarWithEditInDatoCmsButton itemType="caseStudy" />
      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <Icon />
          <h1>All case studies</h1>
          <GlobalSearchBar showShortcutHint />
          <p>
            Discover how companies – from Fortune 10 to startups – apply Tines
            to transform the way their teams operate.
          </p>
        </StandardSidebar>
        <LayoutWithSidebarContent>
          <EntryLinkGrid>
            {caseStudies.map(entry => (
              <CaseStudyBookCardLink
                caseStudy={entry}
                key={entry.slug}
                // withHeadingAndExcerpt
              />
            ))}
          </EntryLinkGrid>
        </LayoutWithSidebarContent>
      </LayoutWithSidebar>
      <Spacing size="sectionMargin" />
    </Page>
  );
};

const Icon = () => (
  <svg
    width="95"
    height="116"
    viewBox="0 0 95 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.1494 51.4475C46.8348 39.3878 26.034 48.098 23.808 64.7001C23.6503 65.8751 23.1294 70.4093 22.998 71.4955C22.73 73.7068 22.7906 76.0553 23.2391 78.5112C27.0888 99.6076 53.6162 115 53.6162 115C53.6162 115 83.0065 106.236 91.6817 86.6241C92.6738 84.3816 93.2777 82.1529 93.5423 79.9779C93.6795 78.8502 94.2283 74.2824 94.3512 73.1223C96.1184 56.4322 77.9065 43.0585 61.1494 51.4475Z"
      fill="#32313B"
    />
    <path
      d="M52.3326 50.7986C36.6981 40.5076 17.067 51.6057 16.8107 68.3542C16.7925 69.5399 16.809 74.1037 16.8063 75.1981C16.8006 77.4254 17.137 79.7506 17.8715 82.1369C24.178 102.633 52.3326 114.796 52.3326 114.796C52.3326 114.796 80.4871 102.633 86.7934 82.1369C87.5144 79.7931 87.8522 77.5089 87.8588 75.3181C87.8623 74.182 87.8698 69.5809 87.855 68.4149C87.6452 51.6323 67.986 40.4955 52.3326 50.7986Z"
      fill="#B35980"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.3326 50.7985C34.539 39.0862 11.5647 55.078 17.8715 75.5747C24.178 96.0712 52.3326 108.234 52.3326 108.234C52.3326 108.234 80.4871 96.0712 86.7934 75.5747C93.1004 55.0782 70.1262 39.0862 52.3326 50.7985Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.9912 47.9412L73.5129 59.3481C73.5129 59.3481 83.5989 62.2298 87.2012 73.3966C87.2012 73.3966 89.1225 63.1904 83.7191 55.8661C78.3157 48.5415 70.9912 47.9412 70.9912 47.9412Z"
      fill="#B35980"
    />
    <path
      d="M52.3326 50.7985C34.539 39.0862 11.5647 55.078 17.8715 75.5747C24.178 96.0712 52.3326 108.234 52.3326 108.234C52.3326 108.234 80.4871 96.0712 86.7934 75.5747C93.1004 55.0782 70.1262 39.0862 52.3326 50.7985Z"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M87.2016 74.2431C83.6455 60.3794 66.3755 52.3669 52.3325 61.6105C38.9928 52.8299 22.7414 59.6201 18.0986 72.2058"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.3325 114.796V108.234"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M77.1324 60.628C69.9966 56.1136 60.2851 55.4157 51.7924 61.0681C36.7706 51.2901 18.1836 61.1447 16.4841 76.7064L17.6508 75.905C21.4989 88.4121 33.4818 97.8156 42.2394 103.246C47.7683 104.95 51.6865 105.611 51.6865 105.611C51.6865 105.611 76.1283 87.0852 77.3649 65.6759C77.4671 63.913 77.3799 62.2288 77.1324 60.628Z"
      fill="#32313B"
    />
    <path
      d="M52.3325 109.555C52.1542 109.555 51.9759 109.519 51.8088 109.446C51.516 109.32 44.5501 106.285 36.6983 100.664C29.3916 95.4342 19.9753 86.903 16.609 75.963C14.3412 68.5922 15.51 61.2596 19.9002 55.3154C24.4353 49.1752 31.729 45.5093 39.4111 45.5093C43.9039 45.5093 48.3533 46.7944 52.3328 49.2337C56.3119 46.7944 60.7616 45.5093 65.2542 45.5093C72.9358 45.5093 80.2293 49.1752 84.7646 55.3159C89.1546 61.2602 90.3239 68.5927 88.0562 75.963C86.1983 82.0016 81.2046 91.1884 67.967 100.664C60.1152 106.285 53.1489 109.32 52.8563 109.446C52.6892 109.519 52.5109 109.555 52.3325 109.555ZM39.4109 48.151C32.5634 48.151 26.0638 51.416 22.0248 56.8848C18.1453 62.1373 17.1185 68.6369 19.1336 75.186C24.7244 93.3561 48.4874 105.003 52.3321 106.784C56.1752 105.002 79.9457 93.3396 85.5314 75.186C87.5463 68.6376 86.5191 62.1382 82.6396 56.8852C78.5999 51.4162 72.101 48.151 65.254 48.151C61.0033 48.151 56.7864 49.448 53.0586 51.9016C52.6181 52.1916 52.047 52.1916 51.6062 51.9016C47.8782 49.448 43.6613 48.151 39.4109 48.151Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
    />
    <path
      d="M93.1519 65.1583C93.0727 64.5201 91.5634 61.5847 91.3371 61.2511C91.2077 61.0609 91.0525 60.8874 90.8744 60.7362L88.804 58.9756L89.1448 55.7139L89.1443 55.7141C89.1969 55.2386 89.1148 54.7582 88.9081 54.3266C88.7011 53.896 87.7236 51.9756 87.5167 51.544C87.3099 51.1134 86.9867 50.7486 86.5832 50.4923C86.1761 50.2301 85.7028 50.0892 85.2189 50.085C84.7347 50.0813 84.2598 50.2158 83.8481 50.471L80.0786 52.786H80.079C79.9145 52.8872 79.712 52.9011 79.5356 52.8232L75.4765 51.0311C74.8872 50.7695 74.2221 50.7387 73.6116 50.9445C73.0015 51.1506 72.4903 51.5777 72.1805 52.1427C71.8699 52.7072 71.7831 53.3676 71.9368 53.9933L72.9806 58.3039C73.022 58.4837 72.9757 58.6729 72.856 58.8138L69.8924 62.1201C69.3779 62.6815 69.1381 63.4413 69.2365 64.1967C69.3356 64.9514 70.3448 66.7217 70.4925 66.9857C70.8985 67.7104 71.155 68.4061 71.7968 68.8159C72.1545 69.0422 72.5629 69.1769 72.9854 69.208L77.4111 69.5468V69.5461C77.6017 69.5633 77.7728 69.6718 77.8686 69.8376L80.0971 73.6768C80.4198 74.2347 80.94 74.6514 81.5547 74.8436C82.169 75.0365 82.8334 74.9912 83.4171 74.7171C83.9998 74.4427 84.4586 73.9599 84.7024 73.3637L86.3943 69.2586H86.3939C86.4691 69.0902 86.62 68.9676 86.8001 68.9275L91.1416 67.9916C91.7706 67.8566 92.3263 67.4907 92.6982 66.9658C93.0702 66.4414 93.2325 65.7961 93.1519 65.1583Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.4056 66.0338C69.7638 65.6241 69.3358 64.9517 69.2365 64.197C69.1381 63.4418 69.3779 62.682 69.8924 62.1206L72.856 58.8143C72.9757 58.6736 73.022 58.484 72.9806 58.3044L71.9368 53.9939C71.7833 53.3682 71.8699 52.7077 72.1805 52.1432C72.4903 51.5783 73.0015 51.1507 73.6116 50.9453C74.2218 50.739 74.8872 50.77 75.4765 51.0316L79.5356 52.8237C79.712 52.9016 79.9145 52.8878 80.079 52.7865H80.0786L83.8481 50.4715C84.2596 50.2161 84.7345 50.0818 85.2189 50.0858C85.7028 50.0897 86.1763 50.2306 86.5832 50.4929C86.9867 50.7491 87.3099 51.1142 87.5167 51.5446C87.7236 51.9761 87.8055 52.4563 87.7531 52.932L87.7536 52.9318L87.2912 57.3557C87.2712 57.5475 87.3467 57.7361 87.4936 57.8619L90.8744 60.7369C91.3644 61.153 91.681 61.7385 91.7605 62.3765C91.8411 63.0145 91.6788 63.6598 91.3068 64.1842C90.9347 64.7089 90.3788 65.0748 89.7498 65.2099L85.4084 66.1458C85.2283 66.1859 85.0775 66.3083 85.0025 66.477L83.3107 70.5821C83.067 71.1783 82.6084 71.6611 82.0254 71.9354C81.4418 72.2095 80.7773 72.2549 80.1631 72.0622C79.5484 71.8698 79.0282 71.453 78.7054 70.8952L76.477 67.0562C76.381 66.8902 76.2101 66.7817 76.0195 66.7647V66.7652L71.5938 66.4259C71.1717 66.3948 70.7633 66.2599 70.4056 66.0338Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M74.1106 77.0784C73.9435 76.6502 73.6309 76.3113 73.2881 76.0053C72.992 75.7416 71.5009 74.3138 71.1308 74.0413C70.7605 73.7687 70.3204 73.6073 69.8618 73.5748L66.4403 73.3141L65.2694 71.308C65.1631 71.1246 65.0343 70.9577 64.887 70.81C64.609 70.5312 62.6991 68.7823 62.419 68.5824C62.2094 68.4329 61.9758 68.3164 61.7262 68.2387C61.1425 68.0573 60.5115 68.1011 59.9587 68.3631C59.4052 68.6244 58.9711 69.0839 58.7406 69.6506L57.1431 73.5466C57.0746 73.7077 56.9337 73.8277 56.7631 73.8691L52.643 74.7614C51.9345 74.9069 51.3271 75.3576 50.9823 75.9934C50.6384 76.629 50.5931 77.3844 50.8584 78.0568C51.0076 78.4299 51.2467 78.7613 51.5543 79.0202L53.2471 80.4556C53.3764 80.6522 53.5303 80.8321 53.7114 80.9845L54.9388 82.0252C54.9558 82.0895 54.9653 82.1562 54.9584 82.224L54.5344 86.4172C54.4719 87.0261 54.6399 87.6362 55.0056 88.1265C55.1029 88.257 55.2123 88.3768 55.332 88.4843C55.661 88.7797 57.6347 90.5993 57.9346 90.7658C58.1604 90.891 58.407 90.9811 58.6653 91.0302C59.2661 91.1438 59.8881 91.0278 60.4074 90.7048L63.9945 88.49L63.9943 88.4895C64.1457 88.4021 64.3302 88.3918 64.4907 88.4611L68.3506 90.1592C68.9096 90.4054 69.5408 90.4333 70.1189 90.2372C70.6971 90.041 71.1814 89.6355 71.4747 89.0998C71.7688 88.5648 71.8509 87.9385 71.706 87.3454L70.7072 83.2517C70.6642 83.0734 70.7112 82.8864 70.8336 82.7499L73.6523 79.6054H73.6518C73.9572 79.269 74.1615 78.8538 74.2427 78.4071C74.3235 77.9606 74.2782 77.5 74.1106 77.0784Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.8582 78.0569C50.5929 77.3845 50.638 76.6291 50.9822 75.9935C51.3269 75.3577 51.9346 74.907 52.6428 74.7615L56.7629 73.8692C56.9333 73.8278 57.0744 73.708 57.1429 73.5467L58.7404 69.6507C58.9709 69.0842 59.4053 68.6245 59.9585 68.3632C60.5116 68.1012 61.1423 68.0572 61.726 68.2388C62.3101 68.4202 62.8046 68.8145 63.1121 69.3438L65.2358 72.9822C65.328 73.14 65.4914 73.2424 65.6744 73.2561L65.6741 73.2556L69.8618 73.5746C70.3204 73.6072 70.7605 73.7686 71.1308 74.0411C71.5009 74.3137 71.7865 74.6858 71.9533 75.114C72.1209 75.5358 72.1662 75.9964 72.0856 76.4427C72.0046 76.8896 71.8001 77.3046 71.4947 77.641L71.4952 77.6412L68.6762 80.7856C68.5538 80.9221 68.5069 81.1092 68.5499 81.2875L69.549 85.381C69.694 85.9739 69.6117 86.6004 69.3174 87.1354C69.0241 87.6711 68.54 88.0766 67.9618 88.273C67.3837 88.4692 66.7525 88.4412 66.1935 88.195L62.3339 86.4972C62.1731 86.4278 61.9891 86.4377 61.8372 86.5256H61.8374L58.2503 88.7406C57.731 89.0636 57.109 89.1794 56.5082 89.066C55.9069 88.9515 55.3706 88.6167 55.0056 88.1264C54.6401 87.6361 54.4719 87.026 54.5344 86.417L54.9585 82.2239C54.977 82.0431 54.9061 81.8641 54.7689 81.7448L54.7687 81.7454L51.5541 79.0201C51.2463 78.7616 51.0073 78.4303 50.8582 78.0569Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M68.5425 81.0671L70.7073 83.252"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M61.8374 86.5259L64.2592 88.4168"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M85.1753 66.2559L86.5856 69.0248"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.0867 37.7356C14.4492 31.4699 -1.96888 46.9291 1.77627 63.2558C2.04134 64.4112 3.14567 68.8397 3.40392 69.9031C3.92966 72.0675 4.81074 74.2453 6.09296 76.3875C17.1054 94.7887 47.3482 99.8865 47.3482 99.8865C47.3482 99.8865 71.7902 81.3607 73.0266 59.9514C73.1682 57.5036 72.9511 55.2045 72.4348 53.0753C72.1673 51.9712 71.0775 47.5013 70.7854 46.3723C66.5794 30.1242 44.8313 23.9967 32.0867 37.7356Z"
      fill="#B35980"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.0863 37.7356C12.013 30.6044 -6.48449 51.6134 4.52795 70.0149C15.5404 88.4163 45.7832 93.5137 45.7832 93.5137C45.7832 93.5137 70.2252 74.9879 71.4618 53.5786C72.6985 32.1697 46.5736 22.118 32.0863 37.7356Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.3481 99.8867L45.7832 93.5137"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M86.2698 72.2276L88.7435 71.9183L89.2072 68.2856L86.7337 68.9039L86.2698 72.2276Z"
      fill="#32313B"
    />
    <path
      d="M71.4155 68.9812L73.9663 69.2131L74.5072 66.7398L72.1883 66.4307L71.4155 68.9812Z"
      fill="#32313B"
    />
    <path
      d="M85.9077 30.4022C85.9049 30.0719 85.8481 29.7417 85.7365 29.4251C85.4857 28.7091 84.9747 28.1134 84.3059 27.7554L79.6915 25.2835C79.5312 25.1967 79.4163 25.0516 79.363 24.8819L79.138 22.8221L79.1376 22.8223C79.1268 22.7197 79.1094 22.6186 79.0885 22.5182L78.7627 19.5344L78.7622 19.5346C78.7039 18.9732 78.4899 18.4398 78.1438 17.9939C77.7977 17.5492 77.3343 17.2091 76.8052 17.0138C76.2704 16.813 75.6894 16.7687 75.1304 16.8845C74.5715 17.0008 74.0572 17.2742 73.6466 17.671L69.877 21.2798L69.8776 21.2796C69.7134 21.4372 69.4831 21.5037 69.2605 21.4582L64.1339 20.4036C63.3893 20.249 62.6148 20.3794 61.9622 20.7691C61.3101 21.1583 60.8275 21.7781 60.6109 22.507C60.5305 22.7767 60.4887 23.0537 60.4849 23.3304C60.4783 23.8016 60.8191 26.9179 60.9673 27.4608C61.0168 27.6418 61.0831 27.8186 61.1663 27.9894L62.5659 30.8828L60.4594 34.5808C60.1611 35.0914 60.021 35.6713 60.0464 36.2494C60.0598 36.5495 60.3172 39.6443 60.5963 40.4263C60.8986 41.2717 61.5595 41.9404 62.4016 42.2528C62.8706 42.4245 63.375 42.478 63.8701 42.4084L69.0571 41.6966L69.0569 41.6958C69.2812 41.668 69.5051 41.7506 69.6573 41.9179L73.1836 45.7886C73.6946 46.3517 74.3989 46.7022 75.1551 46.7709C75.9114 46.8398 76.6661 46.6221 77.2706 46.1606C77.8743 45.6989 78.2827 45.0277 78.4152 44.2798L79.3423 39.1247V39.1249C79.3868 38.9118 79.5304 38.7328 79.7276 38.6419L84.5003 36.4808C85.1918 36.1684 85.7413 35.6079 86.0394 34.9102C86.1885 34.5617 86.2697 34.1909 86.2822 33.8184C86.2944 33.4455 85.9115 30.8181 85.9077 30.4022Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.4012 11.8851C57.7796 11.4433 57.9791 10.8755 57.9588 10.294C57.9516 10.0765 57.9137 9.86275 57.8479 9.65866C57.7378 9.31807 56.4796 7.28731 56.2933 6.96147C56.1953 6.7904 56.0751 6.63123 55.9347 6.48835C55.5278 6.07268 54.9792 5.82522 54.3986 5.79462L50.3915 5.57908C50.2169 5.56895 50.0592 5.47495 49.9685 5.32568L47.877 1.89029L47.8768 1.89073C47.6538 1.51954 47.3317 1.21902 46.9464 1.02241C46.5613 0.82603 46.1294 0.740612 45.6985 0.777379C45.2622 0.811945 44.8443 0.97002 44.4938 1.23201C44.1435 1.49466 43.8754 1.85154 43.7193 2.2606L42.2761 5.99166L42.2766 5.99144C42.2138 6.15414 42.0753 6.27545 41.9062 6.31596L38.0043 7.2512C37.4372 7.38594 36.9418 7.72829 36.6156 8.21088C36.2895 8.69325 36.1559 9.28064 36.2426 9.85702C36.2853 10.1435 36.3809 10.4167 36.522 10.6641C36.6644 10.9145 37.913 13.0742 38.2705 13.4263C38.3249 13.4798 38.3819 13.5308 38.4416 13.5791L40.3185 15.1034L40.0642 18.2879C40.0254 18.7128 40.1042 19.135 40.2852 19.5111C40.3964 19.7416 41.6227 21.926 42.0901 22.3978C42.1775 22.4861 42.2715 22.5665 42.3704 22.6387C42.8195 22.9663 43.3752 23.125 43.9372 23.0785C44.3185 23.0453 44.6862 22.9185 45.0072 22.7091L48.3763 20.528L48.3761 20.5275C48.5227 20.4355 48.705 20.4199 48.8649 20.4864L52.5715 22.0266C53.1096 22.2514 53.7121 22.2657 54.2597 22.0676C54.807 21.8696 55.2601 21.4729 55.5298 20.9558C55.7988 20.439 55.8642 19.8404 55.7127 19.2779L54.68 15.3978C54.6417 15.2355 54.6831 15.0643 54.7905 14.9372L57.4012 11.8851Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.3097 19.7803C32.1291 19.3477 31.7923 18.9988 31.3672 18.8024L28.4318 17.4451C28.3041 17.3854 28.214 17.2681 28.1907 17.1292L27.6504 13.9335L27.6502 13.9338C27.5941 13.5894 27.4428 13.2682 27.2132 13.0062C26.9836 12.7444 26.6857 12.5514 26.3521 12.4503C26.165 12.3924 25.971 12.3647 25.7771 12.3671C25.6216 12.3691 25.4664 12.3902 25.3147 12.4312C24.9935 12.5181 23.6739 12.7799 23.3333 12.8715C22.9929 12.9635 22.6854 13.1511 22.4467 13.4102L20.6958 15.2952L18.6608 14.9998C18.5217 14.9793 18.3817 14.9747 18.2434 14.9855C17.9202 15.0103 15.9365 15.4429 15.6039 15.6006C15.5171 15.6415 15.4335 15.6893 15.3533 15.7439C14.9654 16.0079 14.69 16.4077 14.5828 16.8647C14.4751 17.322 14.5434 17.8026 14.7737 18.2119L16.3489 21.0335C16.4132 21.1517 16.4176 21.2937 16.361 21.4157L14.9271 24.3161C14.6755 24.811 14.6559 25.3914 14.8725 25.9021C15.0898 26.4127 15.5215 26.801 16.0521 26.9631C16.3476 27.052 16.6607 27.0668 16.9632 27.006L20.1363 26.379L20.1361 26.3786C20.2735 26.3532 20.4146 26.3962 20.5144 26.4937L22.8293 28.7528C23.1651 29.0817 23.6118 29.2723 24.081 29.2871C24.1897 29.2908 24.2978 29.2849 24.4044 29.2697C24.7566 29.2197 26.5923 28.8069 26.8354 28.707C27.0192 28.6312 27.1916 28.526 27.3448 28.3939C27.7004 28.0874 27.928 27.6588 27.9824 27.1925L28.3675 23.9799L28.3673 23.9803C28.3873 23.8474 28.4692 23.7316 28.5874 23.6684L31.4524 22.1627C31.8674 21.9447 32.1862 21.5795 32.3447 21.1383C32.5032 20.6966 32.4909 20.2121 32.3097 19.7803Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M62.0257 38.9651C61.1838 38.6527 60.5229 37.9841 60.2204 37.1387C59.9183 36.2924 60.0055 35.3565 60.459 34.5808L63.0514 30.0301C63.1547 29.8381 63.1606 29.6083 63.0684 29.411L60.7904 24.7018C60.4575 24.0186 60.3928 23.2353 60.6103 22.507C60.8267 21.7783 61.3095 21.1583 61.9616 20.7691C62.6142 20.3794 63.3887 20.2493 64.1333 20.4036L69.26 21.4582C69.4825 21.5037 69.7128 21.4372 69.8771 21.2796L69.8764 21.2798L73.646 17.671C74.0566 17.2742 74.5709 17.0008 75.1299 16.8845C75.6889 16.7687 76.2701 16.813 76.8046 17.0138C77.3337 17.2091 77.7971 17.5492 78.1432 17.9939C78.4891 18.4395 78.7033 18.973 78.7617 19.5346L78.7621 19.5344L79.3316 24.75C79.3561 24.9763 79.4906 25.1749 79.6912 25.2835L84.3055 27.7554C84.9744 28.1132 85.4854 28.7091 85.7361 29.4251C85.988 30.1404 85.9616 30.9251 85.6635 31.6223C85.3654 32.32 84.8156 32.8805 84.1241 33.1929L79.3517 35.354C79.1544 35.445 79.0107 35.624 78.9664 35.8371V35.8369L78.0391 40.9919C77.9066 41.7398 77.4981 42.4111 76.8945 42.8725C76.2899 43.334 75.5352 43.5519 74.7792 43.4828C74.0225 43.4141 73.3186 43.0634 72.8074 42.5005L69.2811 38.6298C69.1294 38.4625 68.9053 38.3799 68.6809 38.4077L68.6812 38.4085L63.4939 39.1203C62.9992 39.1903 62.4949 39.1368 62.0257 38.9651Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M68.8748 38.4121L69.33 41.7172"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M79.0764 35.5859L79.395 38.9694"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.1858 42.7174C64.1858 42.7174 64.1123 44.4789 65.4332 44.5522C66.7542 44.6255 70.0566 44.6988 70.0566 44.6988L69.0293 42.0569L64.1858 42.7174Z"
      fill="#32313B"
    />
    <path
      d="M42.578 20.8219C41.892 20.8789 41.2156 20.6297 40.7306 20.141C40.2458 19.6518 40.0019 18.9737 40.0646 18.2879L40.3843 14.2856C40.3935 14.1189 40.3231 13.9573 40.1948 13.8505L37.0819 11.322C36.6286 10.9561 36.3292 10.4334 36.2429 9.85704C36.1561 9.28088 36.2898 8.69349 36.6158 8.21111C36.9419 7.72852 37.4375 7.38618 38.0046 7.25122L41.9065 6.31619C42.0758 6.27546 42.2141 6.15459 42.2768 5.99145L42.2764 5.9919L43.7195 2.26083C43.8756 1.85177 44.1438 1.49467 44.4941 1.23224C44.8445 0.97003 45.2622 0.811954 45.6988 0.777609C46.1296 0.740843 46.5618 0.826488 46.9466 1.02287C47.3319 1.21947 47.6538 1.51999 47.877 1.89096L47.8773 1.89074L49.9688 5.32613C50.0595 5.4754 50.2171 5.56941 50.3917 5.57953L54.3988 5.79485C54.9794 5.82589 55.5281 6.07336 55.9349 6.4888C56.3427 6.90336 56.5791 7.45663 56.5989 8.03763C56.6192 8.61907 56.4197 9.18687 56.0413 9.62873L53.4313 12.6808C53.3238 12.8081 53.2824 12.9791 53.3207 13.1416V13.1414L54.3535 17.0215C54.505 17.584 54.4396 18.1826 54.1708 18.6993C53.9011 19.2165 53.448 19.613 52.9007 19.8109C52.3531 20.0095 51.7503 19.9948 51.2125 19.7702L47.5058 18.23C47.346 18.163 47.1635 18.1789 47.0171 18.2711L47.0173 18.2716L43.6482 20.4527C43.327 20.6616 42.9593 20.7887 42.578 20.8219Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.2524 18.1909L48.6254 20.4471"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.3149 12.9324L54.67 15.2212"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M16.052 26.9629C15.5212 26.8007 15.0897 26.4125 14.8724 25.902C14.6555 25.3912 14.6753 24.8109 14.927 24.316L16.3609 21.4156C16.4177 21.2936 16.413 21.1516 16.3488 21.0334L14.7735 18.2118C14.5435 17.8027 14.4752 17.3219 14.5826 16.8646C14.6899 16.4076 14.9651 16.0078 15.3532 15.7438C15.7416 15.48 16.2145 15.3717 16.6792 15.4402L19.879 15.9047C20.018 15.9245 20.1575 15.8754 20.2531 15.7722H20.2526L22.4468 13.4101C22.6854 13.1507 22.9928 12.9634 23.3334 12.8713C23.6739 12.7798 24.0339 12.7861 24.3708 12.8905C24.7043 12.9916 25.0022 13.1846 25.2318 13.4464C25.4614 13.7084 25.6127 14.0296 25.6688 14.3739L25.669 14.3737L26.2093 17.5694C26.2327 17.7083 26.3229 17.8258 26.4504 17.8853L29.3858 19.2426C29.8109 19.439 30.1478 19.7879 30.3283 20.2205C30.5095 20.6523 30.5216 21.1371 30.3631 21.578C30.2046 22.0192 29.8858 22.3847 29.4708 22.6024L26.6058 24.1081C26.4876 24.1713 26.4055 24.2871 26.3857 24.4201L26.3859 24.4196L26.0008 27.6322C25.9462 28.0985 25.7186 28.5272 25.3632 28.8337C25.007 29.1403 24.5495 29.3019 24.0808 29.2865C23.6119 29.2718 23.1649 29.0811 22.8292 28.7522L20.5142 26.4931C20.4145 26.3956 20.2734 26.3527 20.136 26.378L20.1362 26.3784L16.963 27.0054C16.6605 27.0666 16.3474 27.0519 16.052 26.9629Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M26.4502 17.8853L28.302 17.3477"
      stroke="#32313B"
      strokeWidth="1.1008"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const caseStudiesIndexPageQuery = graphql`
  query CaseStudiesIndexPage {
    caseStudies: allDatoCmsCaseStudy(
      sort: { position: ASC }
      filter: { hiddenInIndex: { ne: true } }
    ) {
      nodes {
        title
        slug
        datePublished
        position
        excerpt
        color
        featuredQuote
        featuredQuoteCover
        featuredData
        featuredDataLabel
        featuredTags
        coverImage {
          url
          width
          height
        }
        customer {
          name
          logoDarkGray {
            url
          }
        }
      }
    }
  }
`;

export default CaseStudiesIndexPage;
