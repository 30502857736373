import { styled } from "@linaria/react";
import RigidDotGrid from "../../decorations/RigidDotGrid";
import { colors } from "../../../styles/colors.styles";
import avatarsImg from "./images/tines-for-the-whole-team-top-right-avatars.png";
import avatarsImg2x from "./images/tines-for-the-whole-team-top-right-avatars@2x.png";
import { makeSrcSet2x } from "../../../utils/srcset.utils";
import { useRef } from "react";
import MultiplayerCursor, {
  MultiplayerCursorOuter,
} from "../../misc/MultiplayerCursor";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import gsap from "gsap";
import { ActionCard } from "../../decorations/storyboard/ActionCard";
import {
  ActionLinkIncomingGooeyBitSvg,
  ActionLinkOutgoingGooeyBitSvg,
  ActionLinkOutgoingTerminalSvg,
} from "../../decorations/storyboard/ActionLinkComponents";
import { onlyPhones } from "../../../styles/breakpointsAndMediaQueries.styles";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.lightest};
  ${MultiplayerCursorOuter},
  ${ActionLinkOutgoingTerminalSvg},
  ${ActionLinkOutgoingGooeyBitSvg},
  ${ActionLinkIncomingGooeyBitSvg} {
    opacity: 0;
  }
`;

const AvatarsImg = styled.img`
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
`;

const ActionCardSet = styled.div`
  position: absolute;
  top: 16.75%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 19.2rem;
  > * {
    + * {
      margin-top: 48px;
    }
  }
`;

const ActionLinkSvg = styled.svg`
  position: absolute;
  top: 44px;
  left: 23px;
  margin: 0;
  transform-origin: center top;
  opacity: 0;
  line {
    &:nth-child(2) {
      opacity: 0;
    }
  }
`;

export const HomeForTheWholeTeamIllustration = () => {
  const ref = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    const containerEl = ref.current;
    if (!containerEl) return;
    const tl = gsap.timeline({
      paused: true,
    });
    const setupTimeline = () => {
      const containerRect = containerEl.getBoundingClientRect();
      const currentProgress = tl.progress();
      tl.clear();
      [1, 2].forEach(i => {
        const cursorEl = document.getElementById(`cursor${i}`);
        if (tl.progress() === 0) gsap.set(cursorEl, { opacity: 0 });
        const startPositionEl = document.getElementById(
          `cursorStartPosition${i}`
        );
        const endPositionEl = document.getElementById(`cursorEndPosition${i}`);
        if (!startPositionEl || !endPositionEl) return;
        const startPositionElRect = startPositionEl.getBoundingClientRect();
        const endPositionElRect = endPositionEl.getBoundingClientRect();
        const startPosition = {
          x: startPositionElRect.left - containerRect.left,
          y: startPositionElRect.top - containerRect.top,
        };
        if (tl.progress() === 0) {
          gsap.set(cursorEl, { ...startPosition, opacity: 1 });
        }
        tl.to(cursorEl, { opacity: 1 }, 0.5);
        tl.fromTo(
          cursorEl,
          startPosition,
          {
            x: endPositionElRect.left - containerRect.left,
            y: endPositionElRect.top - containerRect.top,
            duration: 2.5 - i * 0.3,
            ease: "power3.inOut",
          },
          i * 0.3
        );
      });
      const protagonistCursorEl = document.getElementById("protagonistCursor");
      const trailSteps = [1, 2, 3, 4].map(i => {
        const stepPositionEl = document.querySelector(
          `[data-protagonist-cursor-step="${i}"]`
        );
        if (!stepPositionEl) return;
        const stepPositionElRect = stepPositionEl.getBoundingClientRect();
        return {
          x: stepPositionElRect.left - containerRect.left,
          y: stepPositionElRect.top - containerRect.top,
        };
      });
      if (tl.progress() === 0)
        gsap.set(protagonistCursorEl, { ...trailSteps[0], opacity: 0 });
      tl.to(protagonistCursorEl, { opacity: 1 }, 0.5);
      trailSteps.forEach((step, i) => {
        const next = trailSteps[i];
        if (!next) return;
        tl.to(
          protagonistCursorEl,
          {
            x: next.x,
            y: next.y,
            duration: 1.5,
            ease: "power3.inOut",
          },
          i * 1.5
        );
      });
      const webhookLinkTerminal = document.getElementById(
        "action-1-outgoing-link-terminal"
      );
      const webhookGooeyBit = document.getElementById(
        "action-1-outgoing-gooey-bit"
      );
      const actionLink = document.getElementById("action-link");
      const actionLinkGradientLine = document.getElementById(
        "action-link-gradient-line"
      );
      const etLinkGooeyBit = document.getElementById(
        "action-2-incoming-link-gooey-bit"
      );
      if (tl.progress() === 0) {
        gsap.set(
          [webhookLinkTerminal, webhookGooeyBit, actionLink, etLinkGooeyBit],
          { opacity: 0 }
        );
        gsap.set(actionLink, { scaleY: 0 });
      }
      tl.to(webhookLinkTerminal, { opacity: 1, duration: 0.1 }, 2.6); // link terminal shows
      tl.to(protagonistCursorEl, { scale: 0.9, duration: 0.1 }, 3); // cursor drag start
      tl.to(actionLink, { opacity: 1, duration: 0.1 }, 3); // link shows
      tl.to(actionLink, { scaleY: 1, duration: 1.5, ease: "power3.inOut" }, 3); // animate link draw
      tl.to(actionLinkGradientLine, { opacity: 1, duration: 1 }, 3.75); // animate in link gradient
      tl.to(webhookGooeyBit, { opacity: 1, duration: 0.1 }, 3); // outgoing gooey bit shows
      tl.to(webhookLinkTerminal, { opacity: 0, duration: 0.1 }, 3.5); // link terminal hides
      tl.to(protagonistCursorEl, { scale: 1, duration: 0.1 }, 4.5); // cursor drag end
      tl.to(etLinkGooeyBit, { opacity: 1, duration: 0.1 }, 4.3); // outgoing gooey bit shows
      const action1 = document.getElementById("action-1");
      const action2 = document.getElementById("action-2");
      [action1, actionLink, action2].forEach((el, i) => {
        tl.fromTo(
          el,
          { filter: "brightness(1)" },
          { filter: "brightness(1.5)", duration: 0.1 },
          4.4 + i * 0.1
        );
        tl.to(
          el,
          { filter: "brightness(1)", duration: 2 },
          4.4 + i * 0.1 + 0.1
        );
      });
      if (currentProgress >= 0) tl.seek(tl.duration() * currentProgress);
      else tl.seek(0.001);
    };
    setupTimeline();
    setTimeout(() => {
      setupTimeline();
    }, 1000);
    const handleResize = () => {
      setupTimeline();
    };
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(ref.current);
    const intersectionObserver = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          tl.play();
        } else tl.pause();
      },
      { rootMargin: "-33%" }
    );
    intersectionObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
      intersectionObserver.disconnect();
      tl.kill();
    };
  });
  return (
    <Wrapper ref={ref}>
      <AvatarsImg
        src={avatarsImg}
        srcSet={makeSrcSet2x(avatarsImg, avatarsImg2x)}
      />
      <RigidDotGrid cellSize={24} dotSize={1} color={colors.purple200} />
      <ActionCardSet>
        <ActionCard
          id="action-1"
          title="Incoming alerts"
          type="webhook"
          hasOutgoingLink
          showOutgoingLinkTerminal
          appendToIconContainer={
            <ProtagonistCursorTrailStep data-protagonist-cursor-step="2" />
          }
        />
        <ActionCard
          id="action-2"
          type="transform"
          title="Extract email addresses"
          hasIncomingLink
          appendToIconContainer={
            <ProtagonistCursorTrailStep data-protagonist-cursor-step="3" />
          }
        />
        <ActionLinkSvg
          width={2}
          height={56}
          viewBox="0 0 2 56"
          id="action-link"
        >
          <line
            x1={1}
            y1={0}
            x2={1}
            y2={56}
            stroke={colors.teal400}
            strokeWidth={2}
          />
          <line
            id="action-link-gradient-line"
            x1={1}
            y1={0}
            x2={1}
            y2={56}
            stroke="url(#action-link-gradient)"
            strokeWidth={2}
          />
          <linearGradient
            id="action-link-gradient"
            x1="0"
            y1="3.5"
            x2="0"
            y2="53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colors.teal400} />
            <stop offset="1" stopColor="#F47E3F" />
          </linearGradient>
        </ActionLinkSvg>
      </ActionCardSet>

      <MultiplayerCursor color="green" id="cursor1" />
      <MultiplayerCursor color="pink" id="cursor2" />
      <CursorStartPosition1 id="cursorStartPosition1" />
      <CursorStartPosition2 id="cursorStartPosition2" />
      <CursorEndPosition1 id="cursorEndPosition1" />
      <CursorEndPosition2 id="cursorEndPosition2" />

      <MultiplayerCursor color="orange" id="protagonistCursor" />

      <ProtagonistCursorTrailStep data-protagonist-cursor-step="1" />
      <ProtagonistCursorTrailStep data-protagonist-cursor-step="4" />
    </Wrapper>
  );
};

const ProtagonistCursorTrailStep = styled.div`
  position: absolute;
  width: 3px;
  height: 3px;
  /* background-color: ${colors.orange}; */
  &[data-protagonist-cursor-step="1"] {
    top: -24px;
    left: 45%;
  }
  &[data-protagonist-cursor-step="2"] {
    top: calc(100% + 1px);
    left: calc(50% + 1px);
  }
  &[data-protagonist-cursor-step="3"] {
    top: 3px;
    left: calc(50%);
  }
  &[data-protagonist-cursor-step="4"] {
    bottom: 22%;
    right: 28%;
    ${onlyPhones} {
      bottom: 25%;
    }
  }
`;

const CursorStartPosition1 = styled.div`
  position: absolute;
  right: -15%;
  top: 25%;
  width: 3px;
  height: 3px;
  /* background-color: ${colors.green}; */
`;

const CursorStartPosition2 = styled.div`
  position: absolute;
  top: 55%;
  left: -15%;
  width: 3px;
  height: 3px;
  /* background-color: ${colors.pink}; */
`;

const CursorEndPosition1 = styled.div`
  position: absolute;
  right: 18%;
  top: 30%;
  width: 3px;
  height: 3px;
  /* background-color: ${colors.green}; */
`;

const CursorEndPosition2 = styled.div`
  position: absolute;
  top: 45%;
  left: 15%;
  ${onlyPhones} {
    left: 6.5%;
  }
  width: 3px;
  height: 3px;
  /* background-color: ${colors.pink}; */
`;
