import { styled } from "@linaria/react";
import React, { FormEvent, useState } from "react";
import { useSiteContext } from "../../context/site.context";
import { colors } from "../../styles/colors.styles";
import { emailIsLegitimate } from "../../utils/checkEmailLegitimacy.utils";
import Button from "../forms/Button";
import PrivacyNotice from "../utilities/PrivacyNotice";
import TextInput, { TextInputSet } from "../forms/TextInput";
import CountrySelector from "../basic/CountrySelector";
import RegionSelector from "../basic/RegionSelector";
import { getRegionFieldLabel } from "../../utils/country.utils";
import Multiselect from "../forms/Multiselect";
import Select from "../forms/Select";
import Spacing from "../layout/Spacing";
import Textarea from "../forms/Textarea";
import { BecomeAPartnerIcon } from "../illustrations/spots/BecomeAPartnerIcon";
import { rSize } from "../../styles/responsiveSizes.styles";
import { GridCanvas } from "../decorations/GridCanvas";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { getFormMetaInfo } from "../../utils/form.utils";

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: [start] 1fr [end];
  margin: 0 auto;
  grid-gap: 1em;
  max-width: ${getWidthPxInMaxGrid(6, true, true)};
  padding-left: ${rSize("gap")};
  padding-right: ${rSize("gap")};
`;

const SuccessMessageContainer = styled.div`
  background-color: ${colors.purple50};
  text-align: center;
  padding: 3em 2em;
  border-radius: 1em;
  h2 {
    font-size: 2rem;
  }
`;

const Form = styled.form`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-radius: ${rSize("radius")};
  border: 1px solid ${colors.purple200};
`;

const Header = styled.header`
  text-align: center;
  position: relative;
  border-bottom: 1px solid ${colors.purple200};
`;

const HeaderInner = styled.div`
  position: relative;
  padding: ${rSize("sectionPadding")} ${rSize("lg")};
`;

const Content = styled.div`
  flex: 1;
  padding-top: ${rSize("sectionPadding")};
  padding-bottom: ${rSize("sectionPadding")};
  font-size: 1.4rem;
  h4 {
    font-size: inherit;
    margin-top: 1em;
  }
`;

const companySizeOptions = [
  "0–10",
  "10–50",
  "50–100",
  "100–200",
  "200–500",
  "500–1000",
  "1000+",
];

const annualSalesOptions = [
  "0–5m",
  "5–10m",
  "10–20m",
  "20–50m",
  "50–100m",
  "100m+",
];

const yearsInBusinessOptions = [
  "< 1 yr",
  "1–2 yrs",
  "2–5 yrs",
  "5–10 yrs",
  "10+ yrs",
];

const regionsCoveredOptions = [
  "Africa",
  "Asia-Pacific",
  "Europe",
  "Latin America",
  "Middle East",
  "North America",
  "Global",
];

const customerSegmentsOptions = [
  "Enterprise ($1bn+ ARR)",
  "Corporate ($100m+ ARR)",
  "Small or Medium (<$100m ARR)",
];

const cloudServiceProvidersOptions = [
  "AWS",
  "Google Cloud Platform",
  "Microsoft Azure",
  "IBM",
];

const sectorsOptions = [
  "Banking & Financial Services",
  "Education",
  "Energy & Utilities",
  "Government - State/Local",
  "Government - Federal/Central",
  "Healthcare",
  "Insurance",
  "Manufacturing",
  "Retail",
  "Telecommunications & Media",
  "Other",
];

const SuccessMessage = () => (
  <SuccessMessageContainer>
    <h2>Thanks for submitting, we’ll be in touch.</h2>
  </SuccessMessageContainer>
);

const BecomeAPartnerForm = () => {
  const siteContext = useSiteContext();

  const [awaitingResponse, setAwaitingResponse] = useState(false);

  const formState = useState({
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    website: "",

    company: "",
    country: "",
    street: "",
    state: "",
    postcode: "",

    companySize: "",
    annualSales: "",
    yearsInBusiness: "",

    regionsCovered: [],
    customerSegments: [],
    cloudServiceProviders: [],
    sectors: [],

    reason: [],

    description: "",
    message: "",
  });

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [form] = formState;
  const { country } = form;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setAwaitingResponse(true);

    if (!(await emailIsLegitimate(form.email)).valid) {
      window.alert(
        "Please use a valid business or personal email to continue."
      );
      setAwaitingResponse(false);
      return;
    }

    await fetch(
      "https://hq.tines.io/webhook/61b4a21cecf55d736e5f285fc43ad9f4/4810e5d13cd3ff3b2ec9475cd5be7c2c",
      {
        method: "post",
        body: JSON.stringify({
          ...form,
          ...getFormMetaInfo(),
        }),
      }
    );

    setHasSubmitted(true);
    setAwaitingResponse(false);
    scrollToHash({
      useHash: "#form",
      doNotPushState: true,
    });
  };

  return (
    <Form
      id="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit}
    >
      <Header>
        <GridCanvas color={colors.purple100} parallax />
        <HeaderInner>
          <BecomeAPartnerIcon />
          <Spacing size="2em" />
          <SectionHeading2 lighter centered>
            Become a partner
          </SectionHeading2>
          {hasSubmitted && (
            <>
              <Spacing size="2em" />
              <SuccessMessage />
            </>
          )}
        </HeaderInner>
      </Header>
      {!hasSubmitted && (
        <Content>
          <FormGrid>
            <h4>Your information</h4>

            <TextInputSet>
              <TextInput
                name="firstName"
                formState={formState}
                required
                placeholder="First Name *"
              />
              <TextInput
                name="lastName"
                formState={formState}
                required
                placeholder="Last name *"
              />
            </TextInputSet>

            <TextInput
              name="title"
              formState={formState}
              required
              placeholder="Work title *"
            />
            <TextInput
              name="email"
              formState={formState}
              required
              type="email"
              placeholder="Email address *"
            />

            <TextInputSet>
              <TextInput
                name="company"
                formState={formState}
                required
                placeholder="Company name *"
              />
              <TextInput
                name="website"
                formState={formState}
                required
                placeholder="Company website *"
              />
            </TextInputSet>

            <h4>Company address</h4>

            <TextInputSet>
              <TextInput
                name="street"
                formState={formState}
                required
                placeholder="Street *"
              />
              <RegionSelector
                name="state"
                formState={formState}
                required
                placeholder={`${getRegionFieldLabel(country)} *`}
              />
              <CountrySelector
                name="country"
                formState={formState}
                required
                placeholder="Country *"
              />
              <TextInput
                name="postcode"
                formState={formState}
                required
                placeholder="Postcode"
              />
            </TextInputSet>

            <h4>Company size</h4>
            <Select
              options={companySizeOptions}
              formState={formState}
              name="companySize"
            />

            <h4>Annual sales</h4>
            <Select
              options={annualSalesOptions}
              formState={formState}
              name="annualSales"
            />

            <h4>Years in business</h4>
            <Select
              options={yearsInBusinessOptions}
              formState={formState}
              name="yearsInBusiness"
            />

            <h4>Regions covered</h4>
            <Multiselect
              options={regionsCoveredOptions}
              formState={formState}
              name="regionsCovered"
            />

            <h4>What customer segments do you sell to?</h4>
            <Multiselect
              options={customerSegmentsOptions}
              formState={formState}
              name="customerSegments"
            />

            <h4>Cloud service providers</h4>
            <Multiselect
              options={cloudServiceProvidersOptions}
              formState={formState}
              name="cloudServiceProviders"
            />

            <h4>Which sectors do you focus on?</h4>
            <Multiselect
              options={sectorsOptions}
              formState={formState}
              name="sectors"
            />

            <h4>What are you interested in?</h4>
            <Multiselect
              formState={formState}
              name="reason"
              required
              options={[
                {
                  label: "I want to build a Tines integration",
                  value: "technology partner",
                },
                {
                  label: "I want to resell Tines",
                  value: "reseller",
                },
                {
                  label: "I want to offer Tines as part of a managed service",
                  value: "managed service provider",
                },
              ]}
            />

            <h4>Partner description *</h4>

            <Textarea
              name="description"
              formState={formState}
              required
              placeholder="Tell us a bit about what you do"
              rows={5}
            />

            <h4>Any other details we should know?</h4>

            <Textarea
              name="message"
              formState={formState}
              placeholder="Additional details..."
              rows={5}
            />

            <Spacing />

            <div>
              {hasSubmitted ? (
                <Button
                  children="Close"
                  fullWidth
                  height="4em"
                  onClick={() => {
                    siteContext.openModal(null);
                  }}
                />
              ) : (
                <div>
                  <Button
                    children="Submit"
                    type="submit"
                    fullWidth
                    height="4em"
                    loading={awaitingResponse}
                  />
                  <Spacing />
                  <PrivacyNotice />
                </div>
              )}
            </div>
          </FormGrid>
        </Content>
      )}
    </Form>
  );
};

export default BecomeAPartnerForm;
