import { styled } from "@linaria/react";
import { cx } from "linaria";
import { ReactNode, useRef } from "react";
import { useSizeAndVisibilityObserver } from "../../utils/useSizeAndVisibilityObserver.utils";
import { useViewportSize } from "../../utils/window.utils";

type Props = {
  A: ReactNode;
  B: ReactNode;
  sideBSizeGetter: (measurements: {
    vw: number;
    vh: number;
    /** side A's width */
    aw: number;
    /** side A's height */
    ah: number;
  }) => {
    width: number;
    height: number;
  };
  flipped: boolean;
  fillParent?: boolean;
};

const SideAOuter = styled.div`
  perspective: 1000px;
`;
const SideAInner = styled.div`
  transition: opacity 0.1s 0.2s, transform 1s cubic-bezier(0.3, 0, 0, 1);
  opacity: 1;
  z-index: 10;
  .flipped & {
    pointer-events: none;
    opacity: 0;
    z-index: 0;
  }
`;

const FlippableContainer = styled.div`
  position: relative;
  &.fillParent {
    width: 100%;
    height: 100%;
    ${SideAOuter},
    ${SideAInner} {
      width: 100%;
      height: 100%;
    }
  }
  &.flipped {
    @keyframes flippableContainerOpen {
      0% {
        transform: scale(1) translateY(0);
        filter: brightness(1);
      }
      20% {
        transform: scale(0.7) translateY(-5%);
        filter: brightness(0.9);
      }
      100% {
        transform: scale(1) translateY(0);
        filter: brightness(1);
      }
    }
    animation: flippableContainerOpen 1s;
  }
`;

const SideBOuter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  perspective: 1000px;
  z-index: 0;
  .flipped & {
    pointer-events: auto;
    z-index: 10;
  }
`;
const SideBInner = styled.div`
  opacity: 0;
  transition: opacity 0.1s 0.2s, transform 1s cubic-bezier(0.3, 0, 0, 1);
  .flipped & {
    opacity: 1;
  }
`;

const Flippable = ({ A, B, sideBSizeGetter, flipped, fillParent }: Props) => {
  const refA = useRef<HTMLDivElement>(null);
  const refB = useRef<HTMLDivElement>(null);
  const { vw, vh } = useViewportSize();
  const { ready, width: aw, height: ah } = useSizeAndVisibilityObserver(refA);
  const { width: bw, height: bh } = sideBSizeGetter({ vw, vh, aw, ah });
  const sideAScalarsWhenFLipped = {
    x: bw / aw,
    y: bh / ah,
  };
  const sideBScalarsWhenFLipped = {
    x: aw / bw,
    y: ah / bh,
  };
  return (
    <FlippableContainer
      className={cx(fillParent && "fillParent", flipped && "flipped")}
    >
      {ready && (
        <SideBOuter ref={refB}>
          <SideBInner
            style={{
              width: bw,
              height: bh,
              transform: flipped
                ? ""
                : `scale(${sideBScalarsWhenFLipped.x}, ${sideBScalarsWhenFLipped.y}) rotate3d(0,-1,0,-180deg)`,
            }}
          >
            {B}
          </SideBInner>
        </SideBOuter>
      )}
      <SideAOuter ref={refA}>
        <SideAInner
          style={{
            transform: flipped
              ? `scale(${sideAScalarsWhenFLipped.x}, ${sideAScalarsWhenFLipped.y}) rotate3d(0,1,0,-180deg)`
              : "",
          }}
        >
          {A}
        </SideAInner>
      </SideAOuter>
    </FlippableContainer>
  );
};

export default Flippable;
