const GroupIcon20 = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.967 2.22762C10.3883 1.92737 9.60412 1.92273 9.02081 2.22221C6.78495 3.37012 4.75697 4.68628 2.97983 6.14609C2.70501 6.37184 2.50998 6.69987 2.51331 7.08599C2.51663 7.47053 2.71562 7.79418 2.98877 8.0162C4.75115 9.44873 6.78079 10.7661 8.97572 11.9049C9.55442 12.2051 10.3386 12.2098 10.9219 11.9103C13.1577 10.7624 15.1857 9.44623 16.9629 7.98642C17.2377 7.76068 17.4327 7.43264 17.4294 7.04652C17.4261 6.66199 17.2271 6.33833 16.9539 6.11631C15.1915 4.68379 13.1619 3.36643 10.967 2.22762ZM9.61432 3.36464C9.82098 3.25854 10.1605 3.25955 10.3685 3.3675C12.4633 4.45435 14.3934 5.70474 16.0656 7.05531C14.3834 8.42761 12.459 9.67397 10.3284 10.7679C10.1217 10.874 9.78224 10.873 9.57418 10.765C7.47941 9.67816 5.54927 8.42777 3.87711 7.0772C5.55925 5.7049 7.48365 4.45854 9.61432 3.36464Z"
        fill="currentColor"
      />
      <path
        d="M17.9018 10.2319C18.0909 10.5331 17.9987 10.9297 17.6957 11.1177L12.4295 14.3861C11.7149 14.8296 10.8283 15.0347 9.97127 15.0347C9.11422 15.0348 8.22758 14.8298 7.51286 14.3864L2.3044 11.155C2.0014 10.967 1.90906 10.5704 2.09817 10.2692C2.28727 9.96801 2.6862 9.87622 2.9892 10.0642L8.19766 13.2956C8.66687 13.5867 9.30427 13.749 9.97119 13.7489C10.6381 13.7489 11.2755 13.5866 11.7446 13.2954L17.0107 10.027C17.3137 9.839 17.7126 9.93072 17.9018 10.2319Z"
        fill="currentColor"
      />
      <path
        d="M17.9017 13.4504C18.0909 13.7515 17.9987 14.1481 17.6958 14.3362L13.1352 17.1674C12.2233 17.7336 11.0825 17.9999 9.97123 18C8.86 18.0001 7.71916 17.7338 6.80705 17.1678L2.30433 14.3735C2.00135 14.1855 1.90907 13.7889 2.09821 13.4877C2.28735 13.1865 2.68629 13.0948 2.98926 13.2828L7.49198 16.0771C8.15854 16.4907 9.05007 16.7143 9.97116 16.7142C10.8922 16.7142 11.7837 16.4905 12.4501 16.0768L17.0106 13.2456C17.3136 13.0575 17.7126 13.1492 17.9017 13.4504Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GroupIcon20;
