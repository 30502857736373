import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../../styles/colors.styles";

const CardStack = styled.div`
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.purple200};
    border-bottom: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:before {
    left: 12px;
    right: 12px;
    height: 4px;
    z-index: 4;
  }
  &:after {
    left: 18px;
    right: 18px;
    height: 8px;
    z-index: 3;
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  color: ${colors.purple800};
  border: 1px solid ${colors.purple200};
  border-radius: 12px;
  position: relative;
  z-index: 5;
  padding: 0.5em;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.purple200};
  border-radius: 7px;
  margin-right: 0.75em;
  width: 5.6rem;
  height: 5.6rem;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Text = styled.div`
  padding-right: 1.2em;
  line-height: 1;
`;
const Heading = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0;
`;
const Meta = styled.p`
  font-size: 1rem;
  margin: 0;
  color: ${withOpacity(colors.purple800, 0.5)};
`;
const TagList = styled.div`
  display: flex;
  margin-top: 0.4rem;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;
const Tag = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 2px;
  &.orange {
    background-color: ${colors.orange100};
    color: ${colors.orange700};
  }
  &.pink {
    background-color: ${colors.pink100};
    color: ${colors.pink700};
  }
  &.green {
    background-color: ${colors.green100};
    color: ${colors.green700};
  }
`;

const CasesCardStack = () => {
  return (
    <CardStack>
      <Card>
        <IconWrap>
          <CaseIcon />
        </IconWrap>
        <Right>
          <Text>
            <Heading>Provision access for new hires</Heading>
            <Meta>
              #332 • Created 1h ago •{" "}
              <span id="hero-case-card-comment-span">
                <span id="hero-case-card-comment-counter">2</span> comments
              </span>
            </Meta>
            <TagList>
              <Tag className="pink">
                <PriorityTagIcon />
                 Medium
              </Tag>
              <Tag className="green">Onboarding</Tag>
            </TagList>
          </Text>
          <Avatars />
        </Right>
      </Card>
    </CardStack>
  );
};

const CaseIcon = () => (
  <svg width="22" height="22" fill="none">
    <path
      d="M15.9515 4.53275H6.684C5.22162 4.53275 4.03613 5.71823 4.03613 7.18061V16.4481C4.03613 17.9105 5.22162 19.096 6.684 19.096H15.9515C17.4139 19.096 18.5994 17.9105 18.5994 16.4481V7.18061C18.5994 5.71823 17.4139 4.53275 15.9515 4.53275Z"
      fill="#F3ECF7"
    />
    <path
      d="M15.2896 8.50454L7.34596 8.50454M7.34596 11.8144L12.6375 11.8144M6.684 4.53275H15.9515C17.4139 4.53275 18.5994 5.71823 18.5994 7.18061V16.4481C18.5994 17.9105 17.4139 19.096 15.9515 19.096H6.684C5.22162 19.096 4.03613 17.9105 4.03613 16.4481V7.18061C4.03613 5.71823 5.22162 4.53275 6.684 4.53275Z"
      stroke="#8D75E6"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);

const Avatars = () => (
  <svg width="42" height="24" fill="none">
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="11"
      stroke="white"
      strokeWidth="2"
    />
    <circle cx="12" cy="12" r="9.5" fill="#EADFF8" stroke="#D7C4FA" />
    <rect
      x="19"
      y="1"
      width="22"
      height="22"
      rx="11"
      stroke="white"
      strokeWidth="2"
    />
    <circle cx="30" cy="12" r="9.5" fill="#D7C4FA" stroke="#C2AAFA" />
  </svg>
);

const PriorityTagIcon = () => (
  <svg width="7" height="4" fill="none">
    <path
      d="M1 3.5L3.5 1L6 3.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CasesCardStack;
