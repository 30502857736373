import { styled } from "@linaria/react";
import { DatoCmsSolutionPage } from "../../../../graphql-types";
import { fromDesktop } from "../../../styles/breakpointsAndMediaQueries.styles";
import { PageIconChart } from "./PageIconChart";
import { PageIconClouds } from "./PageIconClouds";
import { PageIconPortal } from "./PageIconPortal";
import { SolutionPageIllustrationType } from "./SolutionPageIllustrationType.enum";
import { PageIconPalace } from "./PageIconPalace";
import { PageIconPlatform } from "./PageIconPlatform";

type Props = {
  page: DatoCmsSolutionPage;
};

const Wrap = styled.div`
  margin-bottom: 2em;
  ${fromDesktop} {
    display: none;
  }
`;

const defs = {
  [SolutionPageIllustrationType.Clouds]: PageIconClouds,
  [SolutionPageIllustrationType.Portals]: PageIconPortal,
  [SolutionPageIllustrationType.ChartAndWires]: PageIconChart,
  [SolutionPageIllustrationType.Palace]: PageIconPalace,
  [SolutionPageIllustrationType.Platforms]: PageIconPlatform,
};

const PageIconDispatcher = (props: Props) => {
  const Decoration = defs[props.page.pageIllustrationType as keyof typeof defs];
  return <Wrap>{Decoration ? <Decoration /> : null}</Wrap>;
};

export default PageIconDispatcher;
