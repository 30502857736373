import { styled } from "@linaria/react";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import { brandColorThemeVar } from "../../../styles/colors.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { Paths } from "../../../utils/pathBuilders.utils";
import { useUserEmail } from "../../../utils/hooks.utils";

const UniProgressHintParagraph = styled.p`
  font-size: 1.2rem;
  a {
    font-weight: 700;
    text-decoration: none;
    &:hover {
      color: ${brandColorThemeVar(600)};
      ${darkModeLinariaCSS(`color: ${brandColorThemeVar(300)};`)}
      text-decoration: underline;
    }
  }
  strong {
    white-space: nowrap;
  }
`;

const UniProgressHint = () => {
  const userEmail = useUserEmail();
  return (
    <UniProgressHintParagraph>
      {userEmail ? (
        <>
          Your learning progress is linked with your account{" "}
          <strong>{userEmail}</strong>.
        </>
      ) : (
        <>
          <a href={Paths.login()} {...externalLinkAttr}>
            Sign in to your tenant <sup>↗</sup>
          </a>{" "}
          to remember your learning progress.
        </>
      )}
    </UniProgressHintParagraph>
  );
};

export default UniProgressHint;
