import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import {
  GRID_MAX_WIDTH_PX,
  getWidthPxInMaxGrid,
} from "../../constants/globalGrid.constants";
import { NoBreak } from "../typography/Nowrap";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPathsPainter";
import {
  fromDesktop,
  fromPhoneLg,
  fromTablet,
  onlyPhones,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  ${uptoPhoneLg} {
    grid-template-rows: repeat(4, 1fr);
  }
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktop} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  grid-gap: ${rSize("gap")};
  min-height: ${getWidthPxInMaxGrid(3)};
`;

const Card = styled.div`
  padding: 1.375em 1.5em;
  border-radius: ${rSize("radius")};
  border-width: 1px;
  border-style: solid;
  display: grid;
  grid-gap: 1.5em;
  ${onlyPhones} {
    grid-template-columns: minmax(auto, 1fr) auto;
  }
  ${fromTablet} {
    grid-template-rows: minmax(auto, 1fr) auto;
  }
  &:nth-child(1) {
    background-color: ${colors.pink50};
    color: ${colors.pink800};
    border-color: ${colors.pink300};
  }
  &:nth-child(2) {
    background-color: ${colors.purple50};
    color: ${colors.purple800};
    border-color: ${colors.purple300};
  }
  &:nth-child(3) {
    background-color: ${colors.orange50};
    color: ${colors.orange800};
    border-color: ${colors.orange300};
  }
  &:nth-child(4) {
    background-color: ${colors.green50};
    color: ${colors.green800};
    border-color: ${colors.green300};
  }
  svg {
    ${onlyPhones} {
      align-self: center;
    }
    ${fromTablet} {
      align-self: end;
      justify-self: end;
    }
  }
`;

const CardText = styled.div`
  h3 {
    font-family: ${serif};
    font-size: 2rem;
    ${fromTablet} {
      font-size: 2.2rem;
    }
    letter-spacing: -0.02rem;
    font-weight: 400;
    + p {
      margin-top: 0.5em;
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

const ConnectorWaypointsPositioner = styled.div`
  position: absolute;
  top: ${rSize("sectionMargin", -1)};
  bottom: ${rSize("sectionMargin", -1)};
  left: 0;
  right: 0;
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
`;

const ConnectorWaypointsPositionerMaxWidth = styled.div`
  position: relative;
  max-width: ${GRID_MAX_WIDTH_PX};
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;

export const AIPageBenefitsSection = () => {
  return (
    <PageSection relative>
      <ConnectorWaypoints />
      <Grid>
        <Card>
          <CardText>
            <h3>
              Put AI to work, <br />
              where AI works best
            </h3>
            <p>
              Build and define when and how your workflows interact with AI.
            </p>
          </CardText>
          <TreeOfStarsIcon />
        </Card>
        <Card>
          <CardText>
            <h3>
              Build workflows <br /> faster
            </h3>
            <p>Transform data faster than ever, regardless of skill set.</p>
          </CardText>
          <MachineArmIcon />
        </Card>
        <Card>
          <CardText>
            <h3>
              Run better <br />
              workflows
            </h3>
            <p>
              Access LLMs to progress <NoBreak>your workflow</NoBreak>.
            </p>
          </CardText>
          <SkySlideIcon />
        </Card>
        <Card>
          <CardText>
            <h3>
              Secure <br />
              by design
            </h3>
            <p>
              All LLMs are run and <NoBreak>managed by</NoBreak> Tines.
            </p>
          </CardText>
          <LocksIcon />
        </Card>
      </Grid>
    </PageSection>
  );
};

const ConnectorWaypoints = () => (
  <ConnectorWaypointsPositioner>
    <ConnectorWaypointsPositionerMaxWidth>
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-start"
        index={0}
        color={colors.yellow400}
        top={0}
        left="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-start"
        index={1}
        top={rSize("lg")}
        left="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-start"
        index={2}
        top={rSize("lg")}
        left={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-start"
        index={3}
        color={colors.pink300}
        top={rSize("sectionMargin")}
        left={rSize("xxl")}
      />

      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-start"
        index={0}
        color={colors.yellow400}
        top={0}
        right="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-start"
        index={1}
        top={rSize("lg")}
        right="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-start"
        index={2}
        top={rSize("lg")}
        right={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-start"
        index={3}
        color={colors.green300}
        top={rSize("sectionMargin")}
        right={rSize("xxl")}
      />

      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-end"
        index={0}
        color={colors.pink300}
        bottom={rSize("sectionMargin")}
        left={rSize("xxl")}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-end"
        index={1}
        bottom={rSize("lg")}
        left={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-end"
        index={2}
        bottom={rSize("lg")}
        left="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-left-end"
        index={3}
        color={colors.yellow400}
        bottom={0}
        left="50%"
      />

      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-end"
        index={0}
        color={colors.green300}
        bottom={rSize("sectionMargin")}
        right={rSize("xxl")}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-end"
        index={1}
        bottom={rSize("lg")}
        right={rSize("xxl")}
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-end"
        index={2}
        bottom={rSize("lg")}
        right="50%"
        radius={24}
      />
      <ConnectorPathWaypoint
        id="ai-page-benefits-section-right-end"
        index={3}
        color={colors.yellow400}
        bottom={0}
        right="50%"
      />
    </ConnectorWaypointsPositionerMaxWidth>
  </ConnectorWaypointsPositioner>
);

const TreeOfStarsIcon = () => (
  <svg
    width="77"
    height="78"
    viewBox="0 0 77 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.5086 25.5893C44.0569 25.5875 43.6118 25.4746 43.2141 25.2602L39.0384 23.0267V23.0263C38.8569 22.932 38.6413 22.932 38.4599 23.0263L34.2524 25.202C33.6422 25.5187 32.9353 25.5951 32.2717 25.4157C31.6085 25.2363 31.0365 24.8148 30.669 24.2334C30.3015 23.6523 30.166 22.9547 30.2888 22.2784L31.1217 17.6143H31.1215C31.1506 17.4196 31.0855 17.2226 30.9463 17.0834L27.5771 13.7511C27.0889 13.2691 26.7991 12.6209 26.7651 11.9352C26.7311 11.2498 26.955 10.5765 27.3935 10.0484C27.8312 9.52007 28.4515 9.17503 29.1317 9.08179L33.822 8.43436C34.0261 8.40544 34.2026 8.27904 34.2945 8.09498L36.4165 3.85004H36.4169C36.642 3.39213 36.9913 3.00704 37.4248 2.73784C37.858 2.46884 38.3582 2.32625 38.8683 2.32706C39.385 2.32463 39.8917 2.46924 40.3294 2.7433C40.767 3.01776 41.1178 3.41074 41.3413 3.87653L43.4053 8.12168L43.4049 8.12128C43.4941 8.30675 43.669 8.43558 43.8721 8.46632L48.5521 9.18271C49.2321 9.28546 49.8483 9.6392 50.2795 10.1746C50.7103 10.7097 50.9249 11.3875 50.88 12.0733C50.8353 12.7598 50.5348 13.4038 50.0378 13.8787L46.626 17.1581C46.4858 17.2964 46.4189 17.4932 46.4458 17.6884L47.2154 22.3631C47.3561 23.1632 47.1361 23.9846 46.614 24.6073C46.0916 25.2295 45.3208 25.5889 44.5086 25.5893Z"
      fill="#B35980"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.3752 37.0614C46.9167 36.7159 46.6348 36.1858 46.6042 35.6126C46.5741 35.0394 46.7988 34.4828 47.2181 34.0906L49.6377 31.7793C49.7358 31.6806 49.7817 31.541 49.761 31.4035L49.2281 28.1039C49.1492 27.6247 49.2532 27.1335 49.5196 26.7275C49.7851 26.321 50.1941 26.03 50.6645 25.9106C51.1354 25.7915 51.6335 25.8536 52.0609 26.0844L55.0062 27.6676C55.134 27.7364 55.2871 27.738 55.4163 27.6713H55.4159L58.3836 26.1519C58.7076 25.9838 59.0727 25.911 59.4361 25.942C59.7996 25.9731 60.1469 26.1066 60.4375 26.3275C60.7257 26.5435 60.9474 26.8368 61.0777 27.1725C61.2079 27.5084 61.2417 27.8741 61.1743 28.2283H61.1747L60.5694 31.5254C60.5429 31.6684 60.5892 31.8147 60.6918 31.9176L63.064 34.2749C63.408 34.6163 63.6119 35.0746 63.6342 35.5586C63.6572 36.0428 63.4983 36.5181 63.1878 36.8903C62.8777 37.2628 62.4388 37.5055 61.9581 37.5705L58.6417 38.0203C58.5038 38.0399 58.3836 38.1232 58.3173 38.2454H58.3171L56.8066 41.2313C56.5884 41.6653 56.2158 42.0015 55.7616 42.1734C55.3073 42.3453 54.8053 42.3405 54.3549 42.1598C53.9041 41.9794 53.5378 41.6362 53.3278 41.1981L51.8773 38.1833C51.8148 38.053 51.6932 37.9616 51.5506 37.9378V37.938L48.2449 37.425C47.93 37.3773 47.6309 37.2521 47.3752 37.0614Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.7807 58.1686C54.2209 57.8112 53.8477 57.2249 53.7611 56.5665C53.6752 55.9076 53.8843 55.2452 54.3331 54.7551L56.918 51.8711C57.0225 51.7488 57.0628 51.5831 57.0268 51.4264L56.1162 47.6669C55.9823 47.121 56.058 46.545 56.329 46.0525C56.5992 45.5598 57.045 45.187 57.5771 45.0076C58.1096 44.8278 58.6899 44.8549 59.2038 45.0828L62.7443 46.6461C62.898 46.7136 63.0748 46.7017 63.2182 46.6133H63.2178L66.5059 44.5944C66.8647 44.3715 67.2791 44.2542 67.7014 44.2576C68.1235 44.2613 68.5365 44.3841 68.8915 44.613C69.2434 44.8363 69.5254 45.1546 69.7058 45.5302C69.8864 45.9064 69.9578 46.3255 69.9121 46.7401H69.9125L69.5094 50.5988C69.4918 50.766 69.5577 50.9307 69.6857 51.0403L72.6346 53.5476C73.062 53.9107 73.3383 54.4212 73.4077 54.9778C73.478 55.5342 73.3362 56.0971 73.0118 56.5546C72.6874 57.0123 72.2026 57.3317 71.6539 57.4494L67.8671 58.2659C67.7101 58.3007 67.5784 58.4075 67.5129 58.5547L67.5131 58.5545L66.0374 62.1352C65.8249 62.6554 65.4248 63.0765 64.9163 63.316C64.4073 63.5551 63.8278 63.5945 63.292 63.4262C62.7561 63.2588 62.302 62.8953 62.0206 62.4087L60.077 59.0599C59.9932 58.9153 59.8442 58.8205 59.6777 58.8057V58.8063L55.8177 58.5106C55.449 58.4835 55.0928 58.3658 54.7807 58.1686Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.5066 58.8959C21.1733 59.0546 20.8053 59.1291 20.4364 59.1121L16.5685 58.947V58.9464C16.4016 58.9416 16.2425 59.018 16.1421 59.1515L13.8142 62.2454C13.4768 62.695 12.9831 63.0019 12.4307 63.105C11.8788 63.2082 11.3078 63.0999 10.8311 62.802C10.3548 62.5041 10.0075 62.0385 9.85806 61.4965L8.81846 57.7659L8.81867 57.7661C8.77114 57.6121 8.65322 57.4906 8.50133 57.4372L4.83848 56.1767C4.30776 55.9945 3.86442 55.6197 3.59643 55.1268C3.32864 54.6341 3.25502 54.0587 3.39073 53.5142C3.52584 52.97 3.86077 52.4959 4.32839 52.1862L7.55437 50.047C7.69433 49.9531 7.77948 49.798 7.7819 49.6297L7.84036 45.7505H7.84076C7.84461 45.3332 7.96515 44.9257 8.18925 44.5737C8.41315 44.2222 8.73089 43.9397 9.10688 43.7597C9.48652 43.5748 9.91125 43.5018 10.3307 43.5483C10.7504 43.5952 11.148 43.7609 11.4779 44.0246L14.5027 46.4201H14.5022C14.6339 46.5251 14.8083 46.5581 14.9689 46.5089L18.6701 45.3775C19.2073 45.2123 19.7868 45.2543 20.2941 45.496C20.8011 45.7373 21.1995 46.1604 21.4093 46.6818C21.6198 47.2033 21.6265 47.7841 21.4287 48.3102L20.0776 51.935C20.0232 52.0863 20.0439 52.2554 20.1329 52.3895L22.3567 55.5601C22.744 56.0999 22.873 56.7829 22.7092 57.4267C22.5452 58.0696 22.1048 58.6074 21.5066 58.8959Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.5714 69.4359C37.5456 69.4359 36.6664 69.1948 36.5614 68.152C35.9749 62.3222 33.9139 58.1304 30.4357 55.6935C24.6524 51.6409 16.7667 53.5748 16.6874 53.5948C15.6045 53.8691 14.5044 53.2136 14.2304 52.1309C13.9561 51.048 14.6114 49.948 15.6943 49.6737C16.0901 49.5734 25.4725 47.2764 32.757 52.3809C37.2424 55.5238 39.8764 60.6936 40.5861 67.7473C40.6982 68.8587 39.8875 69.314 38.7763 69.4258C38.7078 69.4327 38.6392 69.4359 38.5714 69.4359Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M30.7188 37.5383C30.4632 37.729 30.1642 37.8542 29.8485 37.9022L26.543 38.4151L26.5428 38.4149C26.4004 38.4388 26.2785 38.5302 26.2162 38.6604L24.7656 41.6752C24.5559 42.1131 24.1892 42.4566 23.7386 42.6372C23.2881 42.8178 22.7861 42.8224 22.3319 42.6505C21.8778 42.4784 21.505 42.1423 21.287 41.7084L19.7764 38.7225C19.71 38.6004 19.5897 38.5168 19.452 38.4974L16.1354 38.0476C15.6548 37.9827 15.2157 37.7402 14.9056 37.3676C14.5954 36.9952 14.436 36.52 14.4593 36.0358C14.4817 35.5516 14.6856 35.0934 15.0294 34.752L17.4017 32.3947C17.5046 32.2916 17.5505 32.1454 17.524 32.0026L16.9189 28.7054H16.9193C16.8517 28.3512 16.8853 27.9856 17.0158 27.6496C17.1462 27.3137 17.3679 27.0208 17.6561 26.8046C17.9468 26.5837 18.294 26.4503 18.6573 26.4191C19.0209 26.3882 19.386 26.461 19.7098 26.6293L22.6777 28.1486H22.6773C22.8066 28.2153 22.9597 28.2137 23.0873 28.1449L26.0327 26.5615C26.4599 26.3309 26.9581 26.2686 27.4289 26.3878C27.8993 26.5071 28.3083 26.7979 28.5741 27.2045C28.8403 27.6106 28.9442 28.1019 28.8653 28.581L28.3322 31.8808C28.312 32.0183 28.3577 32.1579 28.4558 32.2564L30.8753 34.5678C31.2946 34.9598 31.5195 35.5166 31.4894 36.0898C31.4597 36.6627 31.1775 37.1929 30.7188 37.5383Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.7448 77.3402C56.6398 77.3402 66.2826 76.178 66.2826 74.7444C66.2826 73.3108 56.6398 72.1487 44.7448 72.1487C32.8498 72.1487 23.207 73.3108 23.207 74.7444C23.207 76.178 32.8498 77.3402 44.7448 77.3402Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.3532 70.0716C56.3532 68.424 48.4052 67.0881 38.6007 67.0881C28.7961 67.0881 20.8477 68.4238 20.8477 70.0716C20.8477 70.8849 20.8477 73.4357 20.8477 74.249C20.8477 75.8966 28.7961 77.2327 38.6007 77.2327C48.4052 77.2327 56.3532 75.8968 56.3532 74.249C56.3532 73.4357 56.3532 70.8851 56.3532 70.0716Z"
      fill="#36996F"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.6004 73.0563C48.405 73.0563 56.3532 71.7203 56.3532 70.0722C56.3532 68.4241 48.405 67.0881 38.6004 67.0881C28.7958 67.0881 20.8477 68.4241 20.8477 70.0722C20.8477 71.7203 28.7958 73.0563 38.6004 73.0563Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.8225 47.7387C38.6332 47.7387 38.4411 47.712 38.2501 47.6566C37.1709 47.3407 36.552 46.2101 36.8675 45.1306C38.9485 38.0145 43.3367 35.0164 46.6514 33.7544C50.239 32.3885 53.3604 32.7417 53.4913 32.757C54.608 32.8907 55.4048 33.9042 55.2711 35.0207C55.1379 36.1341 54.129 36.9296 53.017 36.8017C52.6226 36.7585 43.7986 35.9371 40.7759 46.2736C40.5162 47.1619 39.7037 47.7385 38.8225 47.7387Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M38.5906 70.1264C41.2575 70.1264 43.4194 69.6114 43.4194 68.976C43.4194 68.3406 41.2575 67.8256 38.5906 67.8256C35.9237 67.8256 33.7617 68.3406 33.7617 68.976C33.7617 69.6114 35.9237 70.1264 38.5906 70.1264Z"
      fill="#32313B"
    />
    <path
      d="M40.8617 45.7382V22.3502C40.8617 21.2256 39.9501 20.3138 38.8255 20.3138C37.701 20.3138 36.7894 21.2256 36.7894 22.3502V37.7183C34.9014 35.6373 32.7913 34.4424 30.9958 33.759C27.4084 32.3934 24.287 32.7461 24.1559 32.7617C23.0437 32.8949 22.2537 33.9006 22.3805 35.0138C22.5076 36.1266 23.5156 36.9273 24.6288 36.8064C25.001 36.7663 33.6895 35.9456 36.7894 46.0104V67.7618C36.7894 68.8863 37.701 68.5425 38.8255 68.5425C39.9501 68.5425 40.8617 68.8861 40.8617 67.7618V45.7437C40.8617 45.7418 40.8617 45.7402 40.8617 45.7382Z"
      fill="#36996F"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.5086 23.8402C44.0569 23.8383 43.6118 23.7253 43.2141 23.5109L39.0384 21.2774V21.277C38.8569 21.1825 38.6413 21.1825 38.4599 21.277L34.2524 23.4526C33.6422 23.7694 32.9353 23.8456 32.2717 23.6662C31.6085 23.487 31.0365 23.0651 30.669 22.4843C30.3015 21.9028 30.166 21.2052 30.2888 20.529L31.1217 15.865H31.1215C31.1506 15.6705 31.0855 15.4735 30.9463 15.3341L27.5771 12.0019C27.0889 11.5196 26.7991 10.8715 26.7651 10.1861C26.7311 9.50065 26.955 8.82714 27.3935 8.29926C27.8312 7.77056 28.4515 7.42592 29.1317 7.33227L33.822 6.68505C34.0261 6.65613 34.2026 6.52951 34.2945 6.34566L36.4165 2.10052H36.4169C36.642 1.64282 36.9913 1.25752 37.4248 0.988322C37.858 0.719322 38.3582 0.576933 38.8683 0.57754C39.385 0.575315 39.8917 0.719724 40.3294 0.993982C40.767 1.26844 41.1178 1.66122 41.3413 2.12722L43.4053 6.37216L43.4049 6.37175C43.4941 6.55743 43.669 6.68606 43.8721 6.71681L48.5521 7.4334C49.2321 7.53594 49.8483 7.88969 50.2795 8.42546C50.7103 8.96063 50.9249 9.63839 50.88 10.324C50.8353 11.0103 50.5348 11.6545 50.0378 12.1294L46.626 15.4085C46.4858 15.5469 46.4189 15.7435 46.4458 15.9388L47.2154 20.6134C47.3561 21.4137 47.1361 22.2351 46.614 22.8574C46.0916 23.4804 45.3208 23.8396 44.5086 23.8402Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.9729 36.4542C28.717 36.6451 28.4181 36.7701 28.1024 36.8181L24.7971 37.3308H24.7967C24.6545 37.3549 24.5325 37.4461 24.4703 37.5763L23.0195 40.5909C22.8097 41.029 22.4432 41.3725 21.9924 41.5531C21.542 41.7337 21.04 41.7385 20.5859 41.5664C20.1319 41.3943 19.7589 41.0581 19.5409 40.6245L18.0304 37.6384H18.0302C17.9641 37.516 17.8437 37.4327 17.7058 37.4133L14.3892 36.9635C13.9089 36.8986 13.4698 36.6558 13.1597 36.2835C12.8495 35.9111 12.6901 35.4358 12.7131 34.9516C12.7356 34.4674 12.9395 34.0093 13.2835 33.6679L15.6558 31.3104C15.7589 31.2075 15.8046 31.0613 15.7783 30.9183L15.173 27.6213H15.1734C15.106 27.267 15.1396 26.9015 15.27 26.5653C15.4005 26.2294 15.6222 25.9363 15.9102 25.7203C16.201 25.4994 16.5481 25.3659 16.9116 25.3348C17.275 25.3036 17.6401 25.3767 17.9641 25.5447L20.9318 27.0641H20.9314C21.0606 27.131 21.2137 27.1292 21.3416 27.0604L24.287 25.4772C24.7142 25.2464 25.2123 25.1843 25.683 25.3034C26.1534 25.4226 26.5626 25.7136 26.8281 26.1201C27.0945 26.5261 27.1985 27.0174 27.1196 27.4965L26.5864 30.7961C26.566 30.9338 26.6119 31.0732 26.71 31.1719L29.1296 33.4833C29.5489 33.8752 29.7738 34.4321 29.7435 35.0052C29.7135 35.5788 29.4314 36.1088 28.9729 36.4542Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.4831 58.0862C19.1498 58.2449 18.7819 58.3194 18.413 58.3024L14.5451 58.1373V58.1367C14.3782 58.1319 14.219 58.2083 14.1187 58.3418L11.7907 61.4357C11.4534 61.8853 10.9597 62.1922 10.4073 62.2953C9.85535 62.3985 9.28439 62.2903 8.80767 61.9923C8.33136 61.6944 7.98409 61.2288 7.83462 60.6868L6.79503 56.9562L6.79524 56.9564C6.74771 56.8024 6.62978 56.6809 6.47789 56.6275L2.81504 55.367C2.28433 55.1848 1.84098 54.81 1.57299 54.3171C1.30521 53.8244 1.23158 53.249 1.36729 52.7046C1.5024 52.1603 1.83733 51.6862 2.30495 51.3765L5.53093 49.2373C5.67089 49.1434 5.75604 48.9883 5.75847 48.82L5.81692 44.9408H5.81733C5.82117 44.5235 5.94172 44.116 6.16582 43.764C6.38971 43.4125 6.70745 43.13 7.08345 42.95C7.46308 42.7651 7.88781 42.6921 8.30729 42.7386C8.72697 42.7855 9.1246 42.9512 9.45448 43.2149L12.4792 45.6104H12.4788C12.6105 45.7154 12.7848 45.7484 12.9454 45.6992L16.6467 44.5678C17.1839 44.4026 17.7634 44.4446 18.2706 44.6863C18.7777 44.9276 19.1761 45.3507 19.3858 45.8722C19.5964 46.3936 19.6031 46.9745 19.4053 47.5005L18.0542 51.1253C17.9998 51.2766 18.0204 51.4457 18.1094 51.5798L20.3332 54.7504C20.7205 55.2902 20.8496 55.9732 20.6857 56.617C20.5217 57.26 20.0814 57.7977 19.4831 58.0862Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.1956 36.4542C48.737 36.1088 48.4551 35.5786 48.4246 35.0054C48.3944 34.4323 48.6191 33.8756 49.0384 33.4835L51.458 31.1721C51.5561 31.0734 51.602 30.9338 51.5814 30.7963L51.0484 27.4967C50.9695 27.0176 51.0735 26.5263 51.3399 26.1204C51.6054 25.7138 52.0144 25.4228 52.4848 25.3034C52.9557 25.1843 53.4538 25.2464 53.8812 25.4772L56.8265 27.0604C56.9543 27.1292 57.1074 27.1308 57.2366 27.0641H57.2362L60.2039 25.5447C60.5279 25.3767 60.893 25.3038 61.2565 25.3348C61.6199 25.3659 61.9672 25.4994 62.2578 25.7203C62.5461 25.9363 62.7677 26.2296 62.898 26.5653C63.0282 26.9013 63.062 27.2669 62.9946 27.6211H62.9951L62.3897 30.9183C62.3632 31.0613 62.4095 31.2075 62.5121 31.3104L64.8843 33.6677C65.2284 34.0091 65.4322 34.4674 65.4545 34.9514C65.4775 35.4356 65.3186 35.9109 65.0081 36.2831C64.6981 36.6557 64.2591 36.8984 63.7784 36.9633L60.462 37.4131C60.3241 37.4327 60.2039 37.516 60.1376 37.6382H60.1374L58.6269 40.6241C58.4087 41.0581 58.0362 41.3943 57.5819 41.5662C57.1276 41.7381 56.6256 41.7333 56.1752 41.5527C55.7244 41.3722 55.3581 41.029 55.1481 40.5909L53.6976 37.5761C53.6351 37.4459 53.5135 37.3544 53.3709 37.3306V37.3308L50.0653 36.8179C49.7503 36.7701 49.4512 36.6449 49.1956 36.4542Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.9235 69.4359C39.9493 69.4359 40.8285 69.1948 40.9335 68.152C41.52 62.3222 43.581 58.1304 47.0592 55.6935C52.8425 51.6409 60.7282 53.5748 60.8075 53.5948C61.8904 53.8691 62.9905 53.2136 63.2645 52.1309C63.5388 51.048 62.8835 49.948 61.8006 49.6737C61.4048 49.5734 52.0223 47.2764 44.7379 52.3809C40.2525 55.5238 37.6185 60.6936 36.9088 67.7473C36.7967 68.8587 37.6074 69.314 38.7186 69.4258C38.7871 69.4327 38.8557 69.4359 38.9235 69.4359Z"
      fill="#36996F"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M57.0073 57.5614C56.4474 57.204 56.0743 56.6177 55.9877 55.9593C55.9017 55.3004 56.1109 54.638 56.5597 54.1479L59.1445 51.264C59.2491 51.1416 59.2893 50.976 59.2533 50.8192L58.3428 47.0597C58.2089 46.5138 58.2845 45.9378 58.5555 45.4453C58.8258 44.9526 59.2715 44.5798 59.8037 44.4004C60.3362 44.2206 60.9165 44.2477 61.4304 44.4757L64.9709 46.0389C65.1246 46.1065 65.3014 46.0945 65.4448 46.0061H65.4444L68.7324 43.9872C69.0912 43.7643 69.5057 43.647 69.928 43.6505C70.3501 43.6541 70.7631 43.7769 71.1181 44.0058C71.47 44.2291 71.7519 44.5475 71.9323 44.9231C72.1129 45.2993 72.1843 45.7183 72.1386 46.133H72.139L71.7359 49.9916C71.7183 50.1588 71.7843 50.3235 71.9123 50.4331L74.8612 52.9405C75.2886 53.3035 75.5648 53.814 75.6342 54.3706C75.7046 54.927 75.5628 55.4899 75.2384 55.9474C74.914 56.4051 74.4292 56.7245 73.8805 56.8422L70.0936 57.6587C69.9367 57.6935 69.805 57.8003 69.7395 57.9475L69.7397 57.9473L68.264 61.528C68.0514 62.0482 67.6514 62.4693 67.1429 62.7088C66.6338 62.9479 66.0544 62.9873 65.5186 62.819C64.9826 62.6516 64.5285 62.2881 64.2472 61.8015L62.3035 58.4527C62.2198 58.3081 62.0707 58.2133 61.9043 58.1985V58.1991L58.0442 57.9034C57.6755 57.8763 57.3194 57.7586 57.0073 57.5614Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.9338 24.5175C36.9338 24.5175 37.6494 23.9809 38.8419 23.9809C40.0344 23.9809 40.7797 24.6069 40.7797 24.6069L40.8689 22.3412C40.8689 22.3412 39.8552 21.2681 38.6926 21.2681C37.53 21.2681 36.6953 22.5202 36.6953 22.5202L36.9338 24.5175Z"
      fill="#32313B"
    />
    <path
      d="M50.8086 37.0859L52.4266 39.1591L54.3481 39.0074L53.3708 37.3216L50.8086 37.0859Z"
      fill="#32313B"
    />
    <path
      d="M24.2305 38.7401L26.2188 38.6575L29.4891 37.7288L27.2643 36.9703L24.7361 37.1726L24.2305 38.7401Z"
      fill="#32313B"
    />
    <path
      d="M13.4336 59.4846L15.5894 59.8764L16.5694 58.6024L14.1259 58.3405L13.4336 59.4846Z"
      fill="#32313B"
    />
    <path
      d="M59.6133 58.2068L60.5932 59.9707L62.8469 59.6766L61.8996 58.1959L59.6133 58.2068Z"
      fill="#32313B"
    />
    <path
      d="M55.0156 54.2967L56.5633 54.1549L57.1717 53.5127L55.7017 53.121L55.0156 54.2967Z"
      fill="#32313B"
    />
    <path
      d="M19.2148 53.4069L20.34 54.7467L21.8606 54.6807L20.7827 53.3088L19.2148 53.4069Z"
      fill="#32313B"
    />
  </svg>
);

const MachineArmIcon = () => (
  <svg
    width="90"
    height="76"
    viewBox="0 0 90 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.35549 34.8995C5.34706 31.7282 7.91454 30.5613 11.0858 30.5529C14.2568 30.5449 16.8305 31.6984 16.8391 34.8693L16.9235 42.8371C16.9311 46.008 14.3676 48.5851 11.1959 48.5937C8.02573 48.6024 5.44766 46.0384 5.43982 42.8671L5.35549 34.8995Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M56.0437 56.6993C61.3598 56.6993 65.6694 56.2429 65.6694 55.6799C65.6694 55.1169 61.3598 54.6605 56.0437 54.6605C50.7275 54.6605 46.418 55.1169 46.418 55.6799C46.418 56.2429 50.7275 56.6993 56.0437 56.6993Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.2965 39.6253C15.4385 39.6253 18.7962 36.2676 18.7962 32.1257C18.7962 27.9837 15.4385 24.626 11.2965 24.626C7.15459 24.626 3.79688 27.9837 3.79688 32.1257C3.79688 36.2676 7.15459 39.6253 11.2965 39.6253Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M61.474 37.1389L59.5557 35.8436C59.5739 35.8162 61.4862 32.9386 62.4758 28.8313C63.7764 23.4341 62.9519 18.5474 60.0254 14.3069C55.5027 7.75424 44.6297 9.67417 44.5207 9.69437L44.0977 7.4185C44.2232 7.39516 47.2182 6.84958 50.8819 7.2267C55.8615 7.74012 59.682 9.73359 61.9308 12.9918C65.216 17.752 66.176 23.4447 64.7069 29.4543C63.6093 33.9447 61.5605 37.0109 61.474 37.1389Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M62.9816 75.6843C77.4258 75.6843 89.1351 73.5727 89.1351 70.968C89.1351 68.3633 77.4258 66.2517 62.9816 66.2517C48.5375 66.2517 36.8281 68.3633 36.8281 70.968C36.8281 73.5727 48.5375 75.6843 62.9816 75.6843Z"
      fill="#32313B"
    />
    <path
      d="M77.8405 63.3692C77.8405 61.207 68.0254 59.4546 55.9183 59.4546C43.8108 59.4546 33.9961 61.207 33.9961 63.3692C33.9961 64.3017 33.9961 70.0301 33.9961 70.9628C33.9961 73.1245 43.8108 74.8773 55.9183 74.8773C68.0254 74.8773 77.8405 73.1245 77.8405 70.9628C77.8405 70.0299 77.8405 64.3015 77.8405 63.3692Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M60.2691 33.6112C62.4532 37.3011 61.2326 42.0636 57.5426 44.2475C53.853 46.4316 49.0904 45.2108 46.9067 41.5208L31.3771 15.0011C29.1926 11.3116 30.4134 6.54908 34.1031 4.36539C37.7929 2.18091 42.555 3.4017 44.7393 7.09173L60.2691 33.6112Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M55.9183 67.2845C68.0256 67.2845 77.8405 65.5317 77.8405 63.3696C77.8405 61.2074 68.0256 59.4546 55.9183 59.4546C43.811 59.4546 33.9961 61.2074 33.9961 63.3696C33.9961 65.5317 43.811 67.2845 55.9183 67.2845Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M64.8474 57.0206C64.8474 61.9499 60.8513 63.7533 55.9221 63.7533C50.9924 63.7533 46.9961 61.9499 46.9961 57.0206V39.7962C46.9961 34.8664 50.9924 30.8706 55.9221 30.8706C60.8513 30.8706 64.8474 34.8664 64.8474 39.7962V57.0206Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M59.2664 64.5747C66.2416 64.5747 71.8961 63.4037 71.8961 61.9593C71.8961 60.5149 66.2416 59.344 59.2664 59.344C52.2912 59.344 46.6367 60.5149 46.6367 61.9593C46.6367 63.4037 52.2912 64.5747 59.2664 64.5747Z"
      fill="#32313B"
    />
    <path
      d="M30.1031 26.4891C29.2777 27.1341 26.7555 25.284 24.4697 22.3576C22.1836 19.4304 20.9999 16.5355 21.8253 15.8906L40.8484 1.03327C41.6742 0.388458 44.1964 2.23837 46.4823 5.16494C48.7686 8.09228 49.9521 10.9871 49.1263 11.6321L30.1031 26.4891Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M27.4586 20.0172C29.745 22.9436 30.9288 25.8388 30.1031 26.4832C29.2777 27.1282 26.7555 25.2781 24.4697 22.3517C22.1836 19.4246 20.9999 16.5296 21.8253 15.8848C22.6507 15.2402 25.1729 17.0899 27.4586 20.0172Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M64.3868 33.1276C66.5705 36.8177 65.3497 41.5802 61.6599 43.7639C57.9699 45.9478 53.2077 44.7272 51.0238 41.037L35.4944 14.5175C33.3098 10.8281 34.5306 6.0653 38.2204 3.882C41.9104 1.69752 46.6725 2.9183 48.8566 6.60813L64.3868 33.1276Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M21.0478 49.3829C20.8831 48.7232 20.6368 48.0788 20.3087 47.4663L21.2175 45.4556L20.2083 44.4062L19.1991 43.3566L17.6612 43.0299L16.0951 43.6651C15.8231 43.5537 15.5477 43.4541 15.2677 43.3725L14.5162 41.381L12.9783 41.0543L11.5223 41.0256L10.067 40.9972L9.22412 42.9893C8.73934 43.1175 8.26416 43.2913 7.80408 43.5082L6.95767 43.1256L5.41978 42.7989L4.3702 43.808L3.32081 44.8172L4.14408 46.8478C3.81147 47.4139 3.55104 48.0113 3.36218 48.6261L1.33851 49.3902L1.30988 50.8459L1.28125 52.3012L3.24177 53.1306C3.40631 53.7901 3.65262 54.4343 3.98072 55.0472L3.07193 57.0579L4.08113 58.1073L5.09031 59.1569L6.62821 59.4836L8.19454 58.8486C8.46635 58.9598 8.74169 59.0592 9.02154 59.1408L9.77342 61.1325L11.3113 61.4592L13.9973 61.4667L15.0649 59.5238C15.5499 59.3957 16.025 59.222 16.4853 59.0053L17.3317 59.3877L18.8696 59.7144L19.919 58.7052L20.969 57.696L20.1449 55.6655C20.4777 55.0993 20.7384 54.5018 20.927 53.8868L22.9511 53.1229L22.9795 51.6674L23.008 50.2115L21.0478 49.3829ZM8.99408 54.3443C7.36401 52.2516 7.73859 49.2335 9.83168 47.603C10.8087 46.8421 11.9871 46.5183 13.132 46.6018C13.9594 46.8988 14.7154 47.4249 15.2957 48.1696C16.9258 50.2627 16.5506 53.2806 14.4577 54.9109C13.4809 55.672 12.3023 55.9956 11.1574 55.912C10.33 55.6151 9.57418 55.0889 8.99408 54.3443Z"
      fill="#D66B99"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.418 55.683C46.418 56.4253 50.7277 57.0275 56.0437 57.0275C61.3596 57.0275 65.6694 56.4253 65.6694 55.683V62.7606C65.6694 63.5029 61.3596 64.1054 56.0437 64.1054C50.7277 64.1054 46.418 63.5029 46.418 62.7606V55.683Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.7812 43.9136C53.7812 43.9136 58.2459 47.9956 64.7519 46.082L64.8797 40.5966C64.8797 40.5966 63.2212 44.4241 60.0319 44.5515C56.8427 44.6788 53.7812 43.9136 53.7812 43.9136Z"
      fill="#32313B"
    />
    <path
      d="M56.2693 39.724L54.7344 37.0657L56.2693 34.4072H59.3387L60.8736 37.0657L59.3387 39.724H56.2693Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M40.7379 13.3506L39.2031 10.6923L40.7379 8.03381H43.8074L45.3424 10.6923L43.8074 13.3506H40.7379Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M16.5835 31.2847C15.6316 31.9999 14.7467 31.4581 14.0315 30.5064C13.3169 29.555 13.0429 28.5531 13.9945 27.8385L23.576 20.6428C24.528 19.9277 25.4834 20.4174 26.1978 21.3688C26.9126 22.3205 27.1166 23.3746 26.1641 24.089L16.5835 31.2847Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M26.8151 21.3794C26.8151 21.3794 28.749 24.3585 27.6123 25.9576L26.2188 24.3336C26.2187 24.3334 26.5845 22.5916 26.8151 21.3794Z"
      fill="#32313B"
    />
    <path
      d="M19.9023 23.4811L22.3751 26.887L23.5278 26.0415C23.5278 26.0415 23.6855 24.346 21.1216 22.7471L19.9023 23.4811Z"
      fill="#32313B"
    />
    <path
      d="M16.8703 58.8035L18.8673 59.706L19.9167 58.6968L20.9666 57.6877L20.1426 55.6571C20.4754 55.091 20.736 54.4934 20.9247 53.8784L22.9487 53.1145L22.9772 51.659L23.0056 50.2031L21.0453 49.3737C20.8806 48.714 20.6342 48.0696 20.3061 47.4571L21.2149 45.4464L20.2057 44.397L19.1965 43.3474L17.1515 44.1768C16.5524 43.8252 15.9182 43.5537 15.2651 43.3635L14.5136 41.372L13.0577 41.3434L11.6024 41.3149L10.7595 43.307C10.1376 43.4716 9.53045 43.7087 8.95192 44.0191L6.95493 43.1164L5.90535 44.1256L4.85596 45.1348L5.67923 47.1653C5.34663 47.7315 5.08619 48.3288 4.89734 48.9437L2.87368 49.7077L2.84505 51.1634L2.81641 52.6188L4.77693 53.4481C4.94146 54.1076 5.18778 54.7519 5.51587 55.3647L4.6071 57.3754L5.61628 58.4248L6.62547 59.4744L8.67051 58.6453C9.26962 58.9967 9.90366 59.2679 10.5567 59.4583L11.3086 61.45L13.9945 61.4575L15.0622 59.5146C15.6846 59.3507 16.2916 59.1138 16.8703 58.8035ZM8.9786 54.4742C7.28715 52.3025 7.67583 49.1706 9.84815 47.4787C12.0197 45.7871 15.1516 46.1759 16.8434 48.3481C18.5349 50.52 18.146 53.6519 15.9739 55.3433C13.8023 57.0352 10.6701 56.6459 8.9786 54.4742Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.726 23.1471C17.2268 21.1974 19.7225 22.5114 21.6722 25.012C23.6216 27.513 24.2876 30.2538 21.7868 32.2038L15.542 37.1526C13.0406 39.1018 9.43317 38.6552 7.48324 36.154C5.5337 33.6536 5.98025 30.0455 8.48145 28.096L14.726 23.1471Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M6.94533 34.8164C6.9369 31.6451 9.50458 30.4782 12.6759 30.47C15.8466 30.462 18.4205 31.6153 18.4292 34.7862L18.5133 42.7542C18.5212 45.9249 15.9574 48.502 12.7857 48.5107C9.61558 48.5193 7.0377 45.9553 7.02966 42.784L6.94533 34.8164Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M33.2562 6.77633C33.2011 6.74828 33.145 6.72142 33.084 6.7022C32.7396 6.59336 24.5757 4.10883 17.3455 10.0945C10.6682 15.6217 10.6203 24.8307 10.6211 25.22C10.6235 25.8501 11.1284 26.361 11.7552 26.3737C11.7644 26.3739 11.7734 26.3739 11.7825 26.3739C12.4216 26.3715 12.9387 25.852 12.9364 25.2127C12.9362 25.1284 12.9778 16.7154 18.8219 11.878C23.4797 8.02183 28.5827 8.21931 30.9909 8.60447L33.2562 6.77633Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M12.6754 40.7996C16.8174 40.7996 20.1751 37.4419 20.1751 33.3C20.1751 29.158 16.8174 25.8003 12.6754 25.8003C8.53349 25.8003 5.17578 29.158 5.17578 33.3C5.17578 37.4419 8.53349 40.7996 12.6754 40.7996Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M14.9708 31.5889C15.9169 32.8542 15.6588 34.6463 14.3941 35.5927C13.1286 36.539 11.3365 36.2805 10.3901 35.0158C9.44464 33.751 9.70214 31.9588 10.9671 31.0127C12.2318 30.0663 14.0242 30.324 14.9708 31.5889Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M49.4141 6.99768L50.7047 9.57929L53.3426 9.95602L51.6589 7.26204L49.4141 6.99768Z"
      fill="#32313B"
    />
    <path
      d="M35.7227 22.3672L36.8452 24.1632L40.8859 24.2754L38.5849 20.1223L35.7227 22.3672Z"
      fill="#32313B"
    />
    <path
      d="M11.8906 40.7568C11.8906 40.7568 13.2071 42.0733 18.4733 41.1955L18.5464 38.1237C18.5464 38.1237 15.6208 41.6344 11.8906 40.7568Z"
      fill="#32313B"
    />
    <path
      d="M17.6523 46.3496C17.6523 46.3496 18.9322 46.0752 20.2124 44.6125L19.2067 43.1801L18.6276 43.9419L17.6523 46.3496Z"
      fill="#32313B"
    />
  </svg>
);

const SkySlideIcon = () => (
  <svg
    width="59"
    height="90"
    viewBox="0 0 59 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.0913 75.5495C58.0913 70.333 47.2172 66.1044 33.8034 66.1044C20.3897 66.1044 9.51562 70.3332 9.51562 75.5495C9.51562 76.2442 9.51562 78.9025 9.51562 79.5974C9.51562 84.8139 20.3897 89.0425 33.8034 89.0425C47.2172 89.0425 58.0913 84.8137 58.0913 79.5974C58.0913 78.9025 58.0913 76.244 58.0913 75.5495Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M33.8034 84.9945C47.2172 84.9945 58.0912 80.7658 58.0912 75.5495C58.0912 70.3331 47.2172 66.1044 33.8034 66.1044C20.3896 66.1044 9.51562 70.3331 9.51562 75.5495C9.51562 80.7658 20.3896 84.9945 33.8034 84.9945Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M32.0764 80.2684C39.8898 82.1353 46.6236 81.9758 47.1167 79.912C47.6099 77.8482 41.6756 74.6617 33.8622 72.7947C26.0488 70.9277 19.3151 71.0872 18.8219 73.151C18.3288 75.2148 24.263 78.4014 32.0764 80.2684Z"
      fill="#32313B"
    />
    <path
      d="M33.8031 74.2846C34.5977 74.2846 35.3415 74.1685 35.9809 73.9665V12.8604C35.9809 11.6578 35.006 10.6826 33.8031 10.6826C32.6002 10.6826 31.625 11.6578 31.625 12.8604V73.9667C32.2649 74.1685 33.0084 74.2846 33.8031 74.2846Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M28.7268 63.7783C28.0861 63.2884 28.0858 62.3186 28.2801 61.6367C28.4431 61.065 29.1031 59.5131 31.55 58.5427V44.1245C27.5944 44.7745 24.1484 46.2121 21.2869 48.4134C18.1089 50.8584 15.8724 54.1043 14.8191 57.7999C12.976 64.2673 15.0986 70.9794 20.2273 74.8992C23.4168 77.3368 27.8807 78.9113 33.4949 79.5785C35.9007 79.8646 37.9891 79.9205 39.2572 79.9205C39.9564 79.9205 40.4065 79.9034 40.5226 79.8985L40.9369 65.8805C37.6008 66.0237 30.8415 65.3943 28.7268 63.7783Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.7253 79.9325C43.3372 79.9325 45.4545 76.7992 45.4545 72.934C45.4545 69.0688 43.3372 65.9354 40.7253 65.9354C38.1134 65.9354 35.9961 69.0688 35.9961 72.934C35.9961 76.7992 38.1134 79.9325 40.7253 79.9325Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M41.0081 77.6821C42.6241 77.6821 43.9341 75.5564 43.9341 72.9341C43.9341 70.3119 42.6241 68.1862 41.0081 68.1862C39.3921 68.1862 38.082 70.3119 38.082 72.9341C38.082 75.5564 39.3921 77.6821 41.0081 77.6821Z"
      fill="#B38847"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M38.3012 73.5649C38.3012 73.5649 40.7166 69.7226 43.2963 70.052C43.2963 70.052 41.6496 67.033 39.6185 68.8444C37.5878 70.6558 38.3012 73.5649 38.3012 73.5649Z"
      fill="#32313B"
    />
    <path
      d="M49.6155 30.5982C47.14 29.1325 44.1129 28.0434 40.9079 26.8905C32.6945 23.9358 26.8345 21.4581 26.2397 16.4618C26.2137 16.2412 26.1786 15.9474 26.1486 15.6006H12.1367C12.1721 16.4109 12.2373 17.2478 12.3406 18.1161C13.0968 24.4685 16.4803 29.7181 22.3968 33.7188C26.7815 36.684 31.7689 38.4781 36.1697 40.0613C37.3872 40.4993 38.9303 41.0544 40.248 41.5899C39.8971 41.7883 39.4829 41.9958 38.9975 42.2051C37.977 42.6455 36.9368 42.9697 36.0507 43.2016V57.4942C37.3748 57.2851 40.6985 56.6589 44.1766 55.2122C53.6408 51.2757 55.5836 44.861 55.8994 41.3874C56.1303 38.8455 55.4354 36.3967 53.8886 34.3058C52.8472 32.8969 51.4495 31.6842 49.6155 30.5982Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.4961 38.5996V41.6269L35.9424 44.1711L36.1711 40.0612L31.4961 38.5996Z"
      fill="#32313B"
    />
    <path
      d="M48.8982 5.93077C47.4178 2.85767 44.2742 0.736694 40.6346 0.736694C35.9357 0.736694 32.0646 4.27368 31.5329 8.82999C31.0719 8.69677 30.5857 8.62431 30.0823 8.62431C27.1979 8.62431 24.8594 10.9625 24.8594 13.8472C24.8594 16.7316 27.1979 19.0701 30.0823 19.0701C31.9203 19.0701 38.2594 19.0701 40.6346 19.0701C42.0452 19.0701 46.6769 19.0701 47.8828 19.0701C51.5329 19.0701 54.4916 16.1114 54.4916 12.4615C54.4914 9.15702 52.0661 6.41919 48.8982 5.93077Z"
      fill="#E9E6FF"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.1075 18.7843H31.3867V22.1876H36.1075V18.7843Z"
      fill="#32313B"
    />
    <path
      d="M24.9946 11.7298C24.6173 11.7298 24.2465 11.7583 23.8837 11.8105C22.2403 10.1353 19.9519 9.09509 17.4197 9.09509C14.2312 9.09509 11.4294 10.7446 9.81549 13.2353C9.29578 13.1137 8.75483 13.0472 8.19808 13.0472C4.28728 13.0472 1.11719 16.2175 1.11719 20.1281C1.11719 24.0389 4.28728 27.209 8.19808 27.209C9.81548 27.209 15.604 27.209 17.4197 27.209C18.8346 27.209 23.683 27.209 24.9946 27.209C29.269 27.209 32.7342 23.7438 32.7342 19.4694C32.7342 15.1949 29.269 11.7298 24.9946 11.7298Z"
      fill="#E9E6FF"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.9062 27.2375H24.9938C24.9938 27.2375 27.8726 27.3474 29.4093 25.9202C29.4093 25.9202 27.9823 29.8723 23.9205 29.8723C19.8584 29.8723 17.663 29.8723 17.663 29.8723L15.9062 27.2375Z"
      fill="#32313B"
    />
    <path
      d="M44.9453 35.1415C44.9453 35.1415 45.3843 36.9529 46.8114 36.9529C48.2387 36.9529 55.539 36.9529 55.539 36.9529C55.539 36.9529 54.8253 34.7573 53.9473 34.4828C53.9473 34.4828 53.6726 34.7571 52.1359 34.7571C50.5988 34.7571 44.9453 35.1415 44.9453 35.1415Z"
      fill="#32313B"
    />
    <path
      d="M52.438 23.1976C49.645 23.1976 47.2955 25.0884 46.5955 27.6596C46.3228 27.5985 46.0397 27.5637 45.7483 27.5637C43.6092 27.5637 41.875 29.2976 41.875 31.4368C41.875 33.5761 43.6092 35.3099 45.7483 35.3099C46.9103 35.3099 51.0087 35.3099 52.438 35.3099C55.7827 35.3099 58.4942 32.5986 58.4942 29.2539C58.4942 25.9093 55.7825 23.1976 52.438 23.1976Z"
      fill="#E9E6FF"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

const LocksIcon = () => (
  <svg
    width="93"
    height="61"
    viewBox="0 0 93 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.0268 35.4647C47.0268 35.4647 43.343 30.1494 36.9338 22.1114C30.525 14.073 26.1639 9.29738 26.1639 9.29738L21.8009 8.37488L0.734375 25.1709C0.734375 25.1709 4.41831 30.4862 10.8273 38.5246C17.2362 46.563 21.5971 51.3381 21.5971 51.3381L25.9602 52.2606L47.0268 35.4647Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.597 51.3381C21.597 51.3381 17.236 46.563 10.8273 38.5246C4.41831 30.4862 0.734375 25.1709 0.734375 25.1709L21.8009 8.37488C21.8009 8.37488 25.2697 13.42 31.8937 21.728C38.5174 30.0361 42.6633 34.5417 42.6633 34.5417L21.597 51.3381Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.6641 34.542L47.0275 35.4652"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.5038 15.9129C29.113 16.2246 27.8398 15.2775 26.6599 13.7981C25.4807 12.3183 24.841 10.8666 25.2321 10.5547C25.622 10.2435 26.8962 11.1897 28.0756 12.6697C29.255 14.1493 29.8945 15.6015 29.5038 15.9129Z"
      fill="#32313B"
    />
    <path
      d="M43.3708 33.3011C42.9801 33.6124 41.7247 32.6881 40.5675 31.2369C39.41 29.7847 38.7885 28.356 39.1794 28.0443C39.5702 27.7324 40.825 28.6564 41.9827 30.1085C43.14 31.5598 43.7613 32.9897 43.3708 33.3011Z"
      fill="#32313B"
    />
    <path
      d="M40.5255 30.1973C39.6131 29.0541 39.1202 27.9301 40.2639 27.018L47.0687 21.5934C50.7705 18.6412 51.3813 13.2282 48.4293 9.5264C45.4776 5.82396 40.0647 5.21384 36.363 8.16553L29.5583 13.5905C28.4144 14.5027 27.4287 13.7717 26.5166 12.6275C25.6047 11.4838 25.1113 10.3596 26.255 9.44784L33.0597 4.02253C39.046 -0.750102 47.7996 0.236822 52.5721 6.22313C57.3454 12.2096 56.3582 20.9629 50.372 25.7357L43.5672 31.1612C42.4233 32.0732 41.4377 31.3422 40.5255 30.1973Z"
      fill="#FCF9F5"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M53.8611 32.8574C53.8611 32.8574 48.6937 28.9698 40.083 23.3532C31.4727 17.7358 25.8319 14.5735 25.8319 14.5735L21.3998 15.0685L6.67969 37.6341C6.67969 37.6341 11.847 41.5221 20.4576 47.1391C29.0679 52.756 34.7087 55.9188 34.7087 55.9188L39.1405 55.4233L53.8611 32.8574Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.7087 55.9195C34.7087 55.9195 29.0679 52.7565 20.4576 47.1398C11.847 41.5227 6.67969 37.6347 6.67969 37.6347L21.3999 15.0692C21.3999 15.0692 26.2787 18.7685 35.178 24.5743C44.0772 30.3789 49.4291 33.3535 49.4291 33.3535L34.7087 55.9195Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.4297 33.3526L53.862 32.8572"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.0826 19.8021C30.8101 20.2213 29.3039 19.7222 27.719 18.6883C26.134 17.6545 25.0704 16.4773 25.3436 16.0583C25.6166 15.6397 27.1228 16.1385 28.7077 17.1724C30.2927 18.2062 31.3559 19.3841 31.0826 19.8021Z"
      fill="#32313B"
    />
    <path
      d="M49.71 31.9525C49.4368 32.3714 47.9547 31.8881 46.3997 30.8744C44.8447 29.8597 43.8054 28.698 44.0788 28.2793C44.3515 27.861 45.8334 28.3436 47.3888 29.3579C48.9436 30.3721 49.9825 31.534 49.71 31.9525Z"
      fill="#32313B"
    />
    <path
      d="M46.03 29.9016C44.8047 29.1024 43.9835 28.1903 44.7827 26.9649L49.5376 19.676C52.1241 15.7106 51.0028 10.3797 47.0371 7.79302C43.0717 5.20634 37.7411 6.32801 35.1543 10.2934L30.3993 17.5824C29.6001 18.8077 28.4347 18.423 27.2092 17.624C25.9839 16.8244 25.1622 15.9125 25.9615 14.6874L30.7165 7.39855C34.8997 0.98595 43.5197 -0.828272 49.9319 3.35528C56.3445 7.538 58.1579 16.1583 53.9752 22.5709L49.2204 29.8597C48.4208 31.0853 47.2554 30.7006 46.03 29.9016Z"
      fill="#FCF9F5"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M61.2739 32.3452C61.2739 32.3452 55.251 29.9915 45.4468 26.8982C35.6425 23.805 29.3586 22.2761 29.3586 22.2761L25.2232 23.9455L17.1172 49.6402C17.1172 49.6402 23.1403 51.9942 32.9443 55.0875C42.749 58.181 49.0323 59.7091 49.0323 59.7091L53.1676 58.0396L61.2739 32.3452Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.0323 59.709C49.0323 59.709 42.749 58.181 32.9443 55.0875C23.1403 51.9941 17.1172 49.6401 17.1172 49.6401L25.2232 23.9454C25.2232 23.9454 30.9177 26.1962 41.0508 29.3931C51.1835 32.5897 57.1382 34.0149 57.1382 34.0149L49.0323 59.709Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.1367 34.0147L61.2726 32.345"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.277 25.7341C34.0815 26.3037 35.6663 26.3789 35.8168 25.9022C35.9673 25.4255 34.6264 24.5773 32.822 24.0077C31.0175 23.4382 29.4327 23.3629 29.2822 23.8396C29.1318 24.3163 30.4726 25.1645 32.277 25.7341Z"
      fill="#32313B"
    />
    <path
      d="M53.5602 32.4513C55.3309 33.0099 56.8882 33.0765 57.0386 32.6C57.1889 32.1235 55.8753 31.2844 54.1046 30.7257C52.3339 30.167 50.7766 30.1004 50.6262 30.5769C50.4759 31.0534 51.7895 31.8926 53.5602 32.4513Z"
      fill="#32313B"
    />
    <path
      d="M52.9422 31.6045C51.5468 31.1644 50.5104 30.5075 50.9505 29.1123L53.5688 20.813C54.9932 16.2974 52.4787 11.465 47.9633 10.0404C43.4483 8.61611 38.616 11.1304 37.1918 15.6456L34.573 23.9452C34.133 25.3401 32.907 25.284 31.512 24.8435C30.1166 24.4034 29.0799 23.7464 29.5206 22.3508L32.1384 14.0517C34.442 6.75075 42.2563 2.6841 49.5578 4.98767C56.8592 7.2914 60.9256 15.1054 58.622 22.407L56.0035 30.7064C55.5628 32.1017 54.3372 32.045 52.9422 31.6045Z"
      fill="#FCF9F5"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M50.0296 17.6809C51.1933 15.1598 49.2539 10.8928 49.2539 10.8928C49.2539 10.8928 52.0339 12.1215 53.7793 15.8709L50.0296 17.6809Z"
      fill="#32313B"
    />
    <path
      d="M50.1223 17.4097L49.5039 19.4073C49.5039 19.4073 50.6453 20.834 53.0234 22.3084L53.8322 19.8827L50.1223 17.4097Z"
      fill="#32313B"
    />
    <path
      d="M53.7383 21.2612C53.7383 21.2612 55.7361 22.688 58.2567 23.2112L58.7322 21.8794L54.0712 20.0725L53.7383 21.2612Z"
      fill="#32313B"
    />
    <path
      d="M91.1408 42.6921C91.1408 42.6921 78.5072 27.8951 77.9178 27.2046C77.3284 26.5141 77.7198 25.7823 77.8937 25.4168C79.6025 21.8286 79.1629 17.4176 76.4158 14.2001C72.6908 9.83745 66.1346 9.31985 61.7717 13.045C57.4089 16.77 56.8918 23.3262 60.6164 27.6891C63.3188 30.8542 67.5113 31.9956 71.2756 30.961C71.7283 30.8369 72.6858 30.5802 73.4131 31.4322C73.5479 31.5902 74.0798 32.2132 74.8568 33.1234C75.6516 32.5382 76.6471 32.5187 77.1464 33.1036C77.6455 33.6885 77.47 34.6687 76.7679 35.3621C77.2416 35.9163 77.7439 36.5044 78.2613 37.1108C79.2442 36.437 80.3694 36.3601 80.8976 36.9786C81.426 37.5968 81.1736 38.6966 80.3541 39.5617C80.7385 40.0119 81.1206 40.4597 81.4953 40.8989C82.4785 40.2247 83.6039 40.1478 84.132 40.7665C84.6601 41.3849 84.4078 42.4847 83.5882 43.3498C84.0153 43.8499 84.4108 44.3133 84.7637 44.7265C85.794 44.1085 87.1744 44.3303 88.0103 45.3094C89.8707 47.4883 89.9693 47.7492 90.7974 47.0422C92.0937 45.9355 92.5476 44.3394 91.1408 42.6921ZM65.0457 20.7345C64.148 19.6835 64.2724 18.1033 65.3238 17.2053C66.3755 16.3077 67.9557 16.4325 68.853 17.4839C69.7507 18.5352 69.6264 20.1153 68.5747 21.0129C67.5237 21.9108 65.9433 21.7858 65.0457 20.7345Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M89.7813 43.7917C89.7813 43.7917 77.1481 28.9947 76.5586 28.3042C75.969 27.6133 76.3605 26.8817 76.5344 26.5162C78.243 22.928 77.8034 18.5172 75.0565 15.2997C71.3313 10.9369 64.7751 10.4194 60.4122 14.1444C56.0494 17.8694 55.5325 24.4258 59.2573 28.7885C61.9595 31.9536 66.1521 33.0951 69.9163 32.0604C70.3688 31.9363 71.3264 31.6801 72.0538 32.5316C72.1886 32.6897 72.7205 33.3126 73.4974 34.2227C74.2923 33.6378 75.2876 33.6181 75.787 34.203C76.2863 34.7879 76.1107 35.7681 75.4085 36.4615C75.8822 37.0157 76.3847 37.6042 76.902 38.2102C77.8848 37.5365 79.0102 37.4598 79.5384 38.0784C80.0668 38.6967 79.8143 39.7962 78.9947 40.6613C79.3791 41.1115 79.7615 41.5593 80.1361 41.9985C81.119 41.3244 82.2445 41.2476 82.7725 41.8661C83.3007 42.4848 83.0484 43.5843 82.2289 44.4494C82.656 44.9495 83.0514 45.4129 83.4044 45.8264C84.4349 45.208 85.8149 45.4299 86.6511 46.4089C88.5114 48.5878 88.6101 48.8487 89.4383 48.1417C90.734 47.0349 91.1881 45.439 89.7813 43.7917ZM63.6861 21.8341C62.7885 20.7831 62.9129 19.2027 63.9646 18.3049C65.016 17.4073 66.596 17.5321 67.4936 18.5835C68.3916 19.6348 68.2671 21.2149 67.2157 22.1125C66.1642 23.0102 64.5838 22.8854 63.6861 21.8341Z"
      fill="#FBB598"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M67.9588 19.4137C67.8601 19.1176 67.7052 18.835 67.49 18.583C66.9971 18.0055 66.2987 17.7096 65.5949 17.7071C64.8458 18.5199 63.4044 18.9883 61.5563 18.9883C59.8282 18.9883 57.9382 18.5771 56.0915 17.7986C53.7857 16.8268 51.8527 15.4138 50.648 13.8196C49.8072 12.707 49.4235 11.6119 49.5443 10.7249C49.0486 10.4499 48.5202 10.2168 47.9587 10.04C47.5649 9.91582 47.1686 9.8233 46.7723 9.75769C46.2994 11.5645 46.8364 13.6014 48.3273 15.5736C49.8357 17.5697 52.1917 19.3119 54.9614 20.4793C57.1627 21.407 59.4434 21.8976 61.5563 21.8976C64.3886 21.8972 66.6764 20.9895 67.9588 19.4137Z"
      fill="#D2CCFF"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M66.445 10.6341C65.0161 9.13189 63.0637 7.78532 60.7283 6.80109C58.5267 5.87287 56.2462 5.38269 54.1331 5.38269C53.1389 5.38269 52.2151 5.49809 51.375 5.71139C52.7855 6.38627 54.0406 7.27984 55.1126 8.33859C56.5631 8.46155 58.0944 8.84744 59.5984 9.48161C61.0912 10.1106 62.3503 10.8928 63.3577 11.7297C64.0341 11.3555 65.1455 10.8418 66.445 10.6341Z"
      fill="#D2CCFF"
      stroke="#32313B"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M46.7733 9.75781L46.7031 11.8632C46.7031 11.8632 48.9658 12.1541 49.6446 12.8007L49.3858 10.6351L46.7733 9.75781Z"
      fill="#32313B"
    />
    <path
      d="M64.5781 18.2629C64.5781 18.2629 66.7113 19.5235 66.663 20.5903L67.9605 19.414C67.9605 19.414 66.9054 17.7781 65.9356 17.7781C64.9659 17.7781 64.5781 18.2629 64.5781 18.2629Z"
      fill="#32313B"
    />
  </svg>
);
