import { useState } from "react";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import PartnerDirectory, { PartnerDirectoryEntry } from "./PartnerDirectory";
import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import PartnerEntry from "./PartnerEntry";
import { Link } from "gatsby";
import {
  fromDesktopMd,
  fromTabletLg,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import LoadingIndicator from "../utilities/LoadingIndicator";

const featuredChannelPartnerSlugs = [
  "guidepoint-security",
  "trace3",
  "optiv",
  "wwt",
  "evotek",
  "kudelski-security",
];

const featuredTechnologyPartnerSlugs = [
  "aws",
  "elastic-security",
  "wiz",
  "crowdstrike",
  "cribl",
  "sysdig",
];

const CardGrid = styled.div`
  display: grid;
  color: ${colors.purple800};
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Card = styled.div`
  border: 1px solid ${colors.purple100};
  border-radius: ${rSize("radius")};
  padding: ${rSize("md")};
  ${uptoTabletMd} {
    text-align: center;
  }
  h3 {
    font-family: ${serif};
    font-weight: 400;
    font-size: 2.4rem;
    margin-bottom: 0.5em;
    ${uptoTabletMd} {
      margin-top: 0.5em;
    }
    ${fromTabletLg} {
      span {
        display: block;
      }
    }
    ${fromDesktopMd} {
      font-size: 3.2rem;
      letter-spacing: -0.02em;
      line-height: 0.95;
    }
  }
  p {
    font-size: 1.4rem;
    max-width: 26em;
    ${uptoTabletMd} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const CardHeader = styled.header`
  ${fromTabletLg} {
    max-width: 20em;
  }
`;

const PartnerEntryGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
`;

const EmptyPartnerEntry = styled.div`
  border: 1px solid ${colors.purple100};
  border-radius: 0.5em;
  min-height: 11em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${withOpacity(colors.purple800, 0.4)};
`;

const CardFooter = styled.div`
  padding-top: 2em;
  font-size: 1.4rem;
  a {
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PartnerDirectorySection = () => {
  const [partners, setPartners] = useState<PartnerDirectoryEntry[]>([]);
  const [hasQuery, setHasQuery] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const featuredChannelPartners = featuredChannelPartnerSlugs.map(s =>
    partners.find(p => p.slug === s)
  );
  const featuredTechnologyPartners = featuredTechnologyPartnerSlugs.map(s =>
    partners.find(p => p.slug === s)
  );
  return (
    <PageSection>
      <PartnerDirectory
        onDataFetched={setPartners}
        onQueryUpdate={query => {
          setHasQuery(!!query);
        }}
        onSearchResultUpdate={results => {
          setHasResults(results.length > 0);
        }}
        inline
      />
      {(!hasQuery || !hasResults) && (
        <CardGrid>
          <Card>
            <CardHeader>
              <h3>
                <span>Channel</span> <span>partners</span>
              </h3>
              <p>
                Trusted advisors to build better workflow solutions, faster for
                your organization with Tines.
              </p>
            </CardHeader>
            <Spacing size="gap" />
            <PartnerEntryGrid>
              {featuredChannelPartners.map((p, i) => {
                return p ? (
                  <PartnerEntry partner={p} key={p.id} tool={p.tool} />
                ) : (
                  <EmptyPartnerEntry key={i}>
                    <LoadingIndicator delayInSeconds={2} size={24} />
                  </EmptyPartnerEntry>
                );
              })}
            </PartnerEntryGrid>
            <CardFooter>
              <Link to="/partners/directory?category=channel">View all →</Link>
            </CardFooter>
          </Card>
          <Card>
            <CardHeader>
              <h3>
                <span>Technology</span> <span>partners</span>
              </h3>
              <p>
                The tools and systems you use, working together like never
                before.
              </p>
            </CardHeader>
            <Spacing size="gap" />
            <PartnerEntryGrid>
              {featuredTechnologyPartners.map((p, i) => {
                return p ? (
                  <PartnerEntry partner={p} key={p.id} tool={p.tool} />
                ) : (
                  <EmptyPartnerEntry key={i}>
                    <LoadingIndicator delayInSeconds={2} size={24} />
                  </EmptyPartnerEntry>
                );
              })}
            </PartnerEntryGrid>
            <CardFooter>
              <Link to="/partners/directory?category=technology">
                View all →
              </Link>
            </CardFooter>
          </Card>
        </CardGrid>
      )}
    </PageSection>
  );
};

export default PartnerDirectorySection;
