import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { cx } from "linaria";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktopMd,
  fromDesktopXl,
  fromPhoneMd,
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import DisablePageScroll from "../utilities/DisablePageScroll";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(27, 15, 54, 0.9);
  color: ${withOpacity(colors.white, 0.75)};
  backdrop-filter: blur(1em);
  transform: scale(1.05);
  opacity: 0;
  transition: opacity 0.3s, transform 0.5s;
  pointer-events: none;
  padding: 2em 1em 0 1em;
  ${fromTablet} {
    padding-top: 3em;
  }
  ${fromDesktopXl} {
    padding-top: 4em;
  }
  overflow: auto;
  &.frameLoaded {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(440px, 2fr) auto;
  min-height: 100%;
`;

const BookADemoCalendarContainer = styled.div`
  margin: auto;
  border-radius: ${rSize("radius")};
  border: 2px solid ${colors.purple100};
  position: relative;
  background-color: ${colors.purple50};
  width: 560px;
  max-width: 100%;
  height: 720px;
  min-height: 540px;
  max-height: calc(100vh - 16em);
  ${fromTabletLg} {
    min-height: 620px;
  }
  transform: translate3d(0, 0, 0);
`;

const IFrameWrap = styled.div`
  overflow: hidden;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  .chilipiper-popup.in-custom-element {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: relative;
    ${fromPhoneMd} {
      height: calc(100% + 72px);
      margin-top: -72px;
    }
  }
  .chilipiper-popup-window {
    max-width: unset !important;
    max-height: unset !important;
    width: 100% !important;
    height: 100% !important;
  }
  iframe.chilipiper-frame {
    width: 100%;
    height: 100%;
  }
`;

const Footer = styled.div`
  padding: 1.5em;
  font-size: 1.2rem;
  ${fromTablet} {
    padding: 2.5em;
    font-size: 1.4rem;
  }
  ${fromDesktopMd} {
    padding: 3em;
  }
`;

const CloseButton = styled.button`
  appearance: none;
  border: 0;
  background-color: ${colors.dark900};
  color: ${colors.white};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  svg {
    display: block;
  }
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  z-index: 10000000;
  cursor: pointer;
  &:hover {
    background-color: ${colors.dark800};
  }
  &:active {
    background-color: ${colors.darkest};
  }
`;

export const DemoCalendarOverlay = (props: {
  shouldRender: boolean;
  canCancel: boolean;
  onCancel: () => void;
}) => {
  return (
    <>
      {props.shouldRender && <DisablePageScroll />}
      <Overlay className={cx(props.shouldRender && "frameLoaded")}>
        <Grid>
          <BookADemoCalendarContainer>
            <IFrameWrap id="chili-piper-calendar" />
            {props.canCancel && (
              <CloseButton onClick={props.onCancel}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
                  <path
                    d="M10 1L1 10M1 1L10 10"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </CloseButton>
            )}
          </BookADemoCalendarContainer>
          <Footer>
            <p>Can't find a time that works?</p>
            <p>Don’t worry, our team will be in touch within 24 hours.</p>
          </Footer>
        </Grid>
      </Overlay>
    </>
  );
};
