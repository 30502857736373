import axios from "axios";
import { reportErrorSilently } from "./error.utils";
import { isPublicEmailProvider } from "./email.utils";

export type EmailCheckResult = { valid: boolean; isPublicProvider: boolean };

export const emailIsLegitimate = (email: string) =>
  new Promise<EmailCheckResult>(resolve => {
    if (!email || !email.includes("@")) {
      resolve({ valid: false, isPublicProvider: false });
      return;
    }
    try {
      axios
        .get<EmailCheckResult>(
          `/api/utils/check-email-legitimacy?email=${encodeURIComponent(
            email
          )}`,
          {
            timeout: 12000,
          }
        )
        .then(r => resolve(r.data))
        .catch(e => {
          reportErrorSilently(e);
          resolve({
            valid: true,
            isPublicProvider: isPublicEmailProvider(email),
          });
        });
    } catch (e) {
      reportErrorSilently(e);
      resolve({ valid: true, isPublicProvider: isPublicEmailProvider(email) });
    }
  });
