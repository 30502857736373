import { styled } from "@linaria/react";
import { onlyPhones } from "../../../styles/breakpointsAndMediaQueries.styles";

const Svg = styled.svg`
  ${onlyPhones} {
    display: none !important;
  }
`;
export const AppTopBarStoryRunToggle = () => (
  <Svg width="53" height="16" viewBox="0 0 53 16" fill="none">
    <rect opacity="0.5" width="53" height="16" rx="5" fill="#F3ECF7" />
    <rect x="2" y="2" width="25" height="12" rx="3" fill="white" />
  </Svg>
);
