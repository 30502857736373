import { styled } from "@linaria/react";
import { PropsWithChildren, ReactNode } from "react";
import { SpotIllustrationName } from "../illustrations/spots/PNGSpotIllustrations";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import SpotIllustration from "../illustrations/SpotIllustration";

type Props = PropsWithChildren<{
  icon?: SpotIllustrationName | ReactNode;
  backgroundColor?: string;
}>;

const ReportSectionHeaderContainer = styled.header<{
  backgroundColor?: string;
}>`
  display: grid;
  align-items: stretch;
  ${onlyPhones} {
    grid-gap: 0.5em;
    grid-template-rows: auto minmax(0, 1fr);
    background-color: ${p => p.backgroundColor ?? colorsV4.white};
    padding: 1em 1em 2em 1em;
    align-items: center;
    border-radius: 1em;
    text-align: center;
    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromTablet} {
    grid-gap: ${rSize("gap")};
    grid-template-columns: auto minmax(0, 1fr);
    > * {
      display: flex;
      align-items: center;
      background-color: ${p => p.backgroundColor ?? colorsV4.white};
      margin: 0;
      border-radius: 1em;
    }
    > div {
      padding: 2em;
    }
  }
  h2 {
    ${fluidFontSize(24, 36)};
    ${onlyPhones} {
      line-height: 1.1;
    }
  }
`;

const IconContainer = styled.figure`
  margin: 0;
  box-sizing: content-box;
  ${fromTablet} {
    padding: 1em;
  }
  > * {
    ${onlyPhones} {
      width: 90px;
      height: 90px;
    }
    ${fromTablet} {
      width: 120px;
      height: 120px;
    }
  }
`;

const ReportSectionHeader = (props: Props) => {
  return (
    <ReportSectionHeaderContainer backgroundColor={props.backgroundColor}>
      <IconContainer>
        {typeof props.icon === "string" ? (
          <SpotIllustration name={props.icon as SpotIllustrationName} />
        ) : (
          props.icon
        )}
      </IconContainer>
      <div>{props.children}</div>
    </ReportSectionHeaderContainer>
  );
};

export default ReportSectionHeader;
