import { styled } from "@linaria/react";
import SEO from "../components/utilities/SEO";
import Spacing from "../components/layout/Spacing";
import { colors } from "../styles/colors.styles";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import { reportIntent } from "../utils/intentReporter.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import badgeApple from "../images/badge-listen-on-apple-podcasts.svg";
import badgeYouTube from "../images/badge-listen-on-youtube.svg";
import badgeSpotify from "../images/badge-listen-on-spotify.svg";
import { externalLinkAttr } from "../constants/externalLinks.constants";
import PodcastEpisodeList from "../components/general/PodcastEpisodeList";
import { whenDesktopNav } from "../components/site/SiteNav";
import ogImage from "../../static/images/og/tines-podcast.png";
import { podcastLinks } from "../components/general/PodcastCover";
import StaticImageFrame from "../components/basic/StaticImageFrame";
import {
  LayoutWithSidebar,
  LayoutWithSidebarContent,
} from "../components/layout/LayoutWithSidebar";
import StandardSidebar from "../components/layout/StandardSidebar";
import { rSize } from "../styles/responsiveSizes.styles";
import {
  onlyPhones,
  uptoDesktop,
} from "../styles/breakpointsAndMediaQueries.styles";
import cover from "../images/tines-podcast-the-future-of-security-operations-with-thomas-kinsella.png";
import { useState } from "react";
import { isAppleDevice } from "../environment";

const SidebarContent = styled.div`
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  ${onlyPhones} {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  h1 {
    display: none;
    /* ${uptoDesktop} {
      span {
        display: block;
      }
    } */
  }
`;

const BadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: -0.5em;
  margin-right: -0.5em;
  ${whenDesktopNav} {
    justify-content: flex-start;
  }
  > * {
    margin: 0.5em;
  }
`;

const BadgeLink = styled.a`
  display: block;
  background-color: ${colors.black};
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  img {
    display: block;
  }
  &:hover {
    background-color: ${colors.dark500};
  }
`;

const PodcastCoverImage = styled.img`
  display: block;
  aspect-ratio: 1/1;
  width: 100%;
  ${onlyPhones} {
    max-width: 15em;
    margin-left: auto;
    margin-right: auto;
  }
  max-width: 18em;
  border-radius: 1rem;
`;

const PodcastCoverImageLink = styled.a`
  display: block;
`;

const PagePodcast: PageComponent = props => {
  const [podcastLink, setPodcastLink] = useState(podcastLinks.spotify);

  useOnMount(() => {
    if (isAppleDevice) setPodcastLink(podcastLinks.apple);
  });

  useOnMount(() => {
    reportIntent("Viewed podcast page");
  });

  return (
    <Page
      {...props}
      browserThemeColor={colors.dark900}
      browserThemeColorDesktop={colors.dark900}
      backgroundColor={colors.dark900}
    >
      <SEO
        title="Podcast: The Future of Security Operations"
        description="The Future of Security Operations podcast is dedicated to empowering SecOps leaders to reimagine how their teams work."
        image={ogImage}
      />

      <LayoutWithSidebar largerGapOnTop>
        <StandardSidebar>
          <SidebarContent>
            <PodcastCoverImageLink href={podcastLink} {...externalLinkAttr}>
              <PodcastCoverImage src={cover} />
            </PodcastCoverImageLink>
            <h1>
              <span>The Future of </span>
              <span>Security Operations</span>
            </h1>
            <p>Listen to our podcast on your favorite platform:</p>
            <BadgeList>
              <BadgeLink
                href={podcastLinks.spotify}
                {...externalLinkAttr}
                title="Listen on Spotify"
              >
                <StaticImageFrame
                  src={badgeSpotify}
                  renderedWidth={150}
                  renderedHeight={38}
                />
              </BadgeLink>
              <BadgeLink
                href={podcastLinks.youtube}
                {...externalLinkAttr}
                title="Listen on YouTube"
              >
                <StaticImageFrame
                  src={badgeYouTube}
                  renderedWidth={150}
                  renderedHeight={38}
                />
              </BadgeLink>
              <BadgeLink
                href={podcastLinks.apple}
                {...externalLinkAttr}
                title="Listen on Apple Podcasts"
              >
                <StaticImageFrame
                  src={badgeApple}
                  renderedWidth={150}
                  renderedHeight={38}
                />
              </BadgeLink>
            </BadgeList>
          </SidebarContent>
        </StandardSidebar>
        <LayoutWithSidebarContent>
          <PodcastEpisodeList />
        </LayoutWithSidebarContent>
      </LayoutWithSidebar>

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PagePodcast;
