export const FormulasIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d="M4 11.98C4.329 12.31 4.778 12.49 5.248 12.5C6.126 12.5 6.864 11.85 6.994 10.981L8.008 4.02C8.128 3.14 8.875 2.5 9.753 2.5C10.223 2.5 10.671 2.68 11 3.02M9.83333 7.5H7.75H5.16667"
      stroke="currentColor"
      strokeWidth="1.26654"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChangeControlIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.72977C3.44062 3.72977 2.999 4.1714 2.999 4.73077C2.999 5.28416 3.44351 5.73177 4 5.73177C4.55049 5.73177 5.001 5.28126 5.001 4.73077C5.001 4.17429 4.55339 3.72977 4 3.72977ZM2 4.73077C2 3.61966 2.88889 2.73077 4 2.73077C5.10222 2.73077 6 3.61966 6 4.73077C6 5.64496 5.38241 6.41851 4.54281 6.65583C4.59597 6.9157 4.69535 7.15188 4.82232 7.33734C5.01947 7.6253 5.23437 7.73071 5.39993 7.73079H5.39999H5.40012L9.59996 7.72923L9.60011 7.72923C9.76594 7.729 9.98073 7.6235 10.1776 7.33612C10.3043 7.15127 10.4035 6.91586 10.4568 6.6566C9.6128 6.42018 9 5.64595 9 4.73077C9 3.61966 9.88889 2.73077 11 2.73077C12.1022 2.73077 13 3.61966 13 4.73077C13 5.67185 12.3455 6.46389 11.4682 6.67537C11.4029 7.12795 11.2399 7.55501 11.0026 7.90133C10.693 8.35316 10.2081 8.72862 9.60097 8.72923L9.60065 8.72923L8 8.72982C8 8.73014 8 8.73046 8 8.73077V9.73077C8 9.75191 7.99869 9.77274 7.99614 9.79319C8.85867 10.013 9.4995 10.7928 9.4995 11.7308C9.4995 12.8333 8.60253 13.7303 7.5 13.7303C6.38935 13.7303 5.5005 12.8319 5.5005 11.7308C5.5005 10.793 6.13683 10.0118 7.00379 9.79262C7.00129 9.77235 7 9.75171 7 9.73077V8.73077C7 8.73058 7 8.73039 7 8.73019L5.4003 8.73079H5.40012C4.79214 8.73079 4.30677 8.35445 3.99718 7.90226C3.75997 7.55578 3.59694 7.12858 3.53175 6.67603C2.64933 6.46554 2 5.67283 2 4.73077ZM11.026 5.73144C11.5646 5.71752 12.001 5.27257 12.001 4.73077C12.001 4.17429 11.5534 3.72977 11 3.72977C10.4406 3.72977 9.999 4.1714 9.999 4.73077C9.999 5.27547 10.4297 5.7177 10.9739 5.73144C10.9825 5.731 10.9912 5.73077 11 5.73077C11.0087 5.73077 11.0174 5.731 11.026 5.73144ZM7.5 10.7303C6.94253 10.7303 6.4995 11.1733 6.4995 11.7308C6.4995 12.283 6.94398 12.7313 7.5 12.7313C8.0508 12.7313 8.5005 12.2816 8.5005 11.7308C8.5005 11.1748 8.05225 10.7303 7.5 10.7303Z"
      fill="currentColor"
    />
  </svg>
);

export const VersioningIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 12.9983H4.1C3.71691 12.9983 3.5 12.717 3.5 12.481V4.51553C3.5 4.27513 3.71358 3.99829 4.1 3.99829H7.5V6.99829C7.5 7.49829 8 7.99829 8.5 7.99829H11.5C12.0523 7.99829 12.5 7.55058 12.5 6.99829V6.65228C12.5 6.37785 12.3872 6.11548 12.1881 5.92665L9.38934 3.27267C9.20356 3.09649 8.95728 2.99829 8.70125 2.99829H4.1C3.212 2.99829 2.5 3.67346 2.5 4.51553V12.481C2.5 13.3155 3.212 13.9983 4.1 13.9983H6.5V12.9983ZM11.5 6.99829V6.93726C11.5 6.6575 11.3828 6.39053 11.1769 6.20116L9.06824 4.26219C8.91051 4.11715 8.71114 4.02728 8.5 4.00421V6.99829H11.5ZM10.6464 13.1447C10.4512 13.34 10.4512 13.6566 10.6464 13.8518C10.8417 14.0471 11.1583 14.0471 11.3536 13.8518L13.3536 11.8518L13.3599 11.8453C13.4467 11.7554 13.5 11.6331 13.5 11.4983C13.5 11.3635 13.4467 11.2412 13.3599 11.1512L13.3536 11.1447L11.3536 9.14474C11.1583 8.94948 10.8417 8.94948 10.6464 9.14474C10.4512 9.34 10.4512 9.65658 10.6464 9.85184L11.7929 10.9983H9C8.72386 10.9983 8.5 11.2221 8.5 11.4983C8.5 11.7744 8.72386 11.9983 9 11.9983H11.7929L10.6464 13.1447Z"
      fill="currentColor"
    />
  </svg>
);

export const SchedulingIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M6.00195 7.99854C5.72609 7.99854 5.50245 8.22217 5.50245 8.49804C5.50245 8.7739 5.72609 8.99754 6.00195 8.99754H10.002C10.2778 8.99754 10.5015 8.7739 10.5015 8.49804C10.5015 8.22217 10.2778 7.99854 10.002 7.99854H6.00195Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50145 3.49804C6.50145 3.22217 6.27782 2.99854 6.00195 2.99854C5.72609 2.99854 5.50245 3.22217 5.50245 3.49804V3.99804H4.01919C3.17713 3.99804 2.50195 4.71004 2.50195 5.59804V12.398C2.50195 13.278 3.17713 13.998 4.01919 13.998H11.9847C12.8192 13.998 13.502 13.278 13.502 12.398V5.59804C13.502 4.71004 12.8192 3.99804 11.9847 3.99804H10.5015V3.49804C10.5015 3.22217 10.2778 2.99854 10.002 2.99854C9.72609 2.99854 9.50245 3.22217 9.50245 3.49804V3.99804H6.50145V3.49804ZM9.50245 5.49804V4.99704H6.50145V5.49804C6.50145 5.7739 6.27782 5.99754 6.00195 5.99754C5.72609 5.99754 5.50245 5.7739 5.50245 5.49804V4.99704H4.01919C3.77819 4.99704 3.50095 5.21111 3.50095 5.59804V12.398C3.50095 12.7803 3.78135 12.999 4.01919 12.999H11.9847C12.2181 12.999 12.503 12.777 12.503 12.398V5.59804C12.503 5.21444 12.2213 4.99704 11.9847 4.99704H10.5015V5.49804C10.5015 5.7739 10.2778 5.99754 10.002 5.99754C9.72609 5.99754 9.50245 5.7739 9.50245 5.49804Z"
      fill="currentColor"
    />
  </svg>
);

export const SmartRetriesIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0039 7.99854C11.7278 7.99854 11.5039 7.77468 11.5039 7.49854V6.49854C11.5039 6.22239 11.28 5.99854 11.0039 5.99854H5.21101L6.35746 7.14498C6.55272 7.34024 6.55272 7.65683 6.35746 7.85209C6.1622 8.04735 5.84561 8.04735 5.65035 7.85209L3.65035 5.85209C3.55658 5.75832 3.50391 5.63114 3.50391 5.49854C3.50391 5.36593 3.55658 5.23875 3.65035 5.14498L5.65035 3.14498C5.84561 2.94972 6.1622 2.94972 6.35746 3.14498C6.55272 3.34024 6.55272 3.65683 6.35746 3.85209L5.21101 4.99854H11.0039C11.8323 4.99854 12.5039 5.67011 12.5039 6.49854V7.49854C12.5039 7.77468 12.28 7.99854 12.0039 7.99854ZM4.00391 8.99854C4.28005 8.99854 4.50391 9.22239 4.50391 9.49854V10.4985C4.50391 10.7747 4.72776 10.9985 5.00391 10.9985H10.7968L9.65035 9.85209C9.45509 9.65683 9.45509 9.34024 9.65035 9.14498C9.84561 8.94972 10.1622 8.94972 10.3575 9.14498L12.3575 11.145C12.4512 11.2388 12.5039 11.3659 12.5039 11.4985C12.5039 11.6311 12.4512 11.7583 12.3575 11.8521L10.3575 13.8521C10.1622 14.0474 9.84561 14.0474 9.65035 13.8521C9.45509 13.6568 9.45509 13.3402 9.65035 13.145L10.7968 11.9985H5.00391C4.17548 11.9985 3.50391 11.327 3.50391 10.4985V9.49854C3.50391 9.22239 3.72776 8.99854 4.00391 8.99854Z"
      fill="currentColor"
    />
  </svg>
);

export const LineChartIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d="M2.5 11.5H12.5M12.5 2.5L8.5 8.5L5.5 6.5L2.5 9.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RecordsIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.999 3.60318C2.999 3.31284 3.2269 3.08494 3.51724 3.08494H11.4828C11.7688 3.08494 12.001 3.31609 12.001 3.60318V11.5687C12.001 11.8514 11.7655 12.0869 11.4828 12.0869H3.51724C3.23016 12.0869 2.999 11.8547 2.999 11.5687V3.60318ZM2 3.60318C2 2.76111 2.67517 2.08594 3.51724 2.08594H11.4828C12.3172 2.08594 13 2.76111 13 3.60318V11.5687C13 12.4032 12.3172 13.0859 11.4828 13.0859H3.51724C2.67517 13.0859 2 12.4032 2 11.5687V3.60318ZM5.9995 5.58594C5.9995 5.31007 5.77587 5.08644 5.5 5.08644C5.22413 5.08644 5.0005 5.31007 5.0005 5.58594V9.58594C5.0005 9.8618 5.22413 10.0854 5.5 10.0854C5.77587 10.0854 5.9995 9.8618 5.9995 9.58594V5.58594ZM9.5 5.08644C9.77587 5.08644 9.9995 5.31007 9.9995 5.58594V9.58594C9.9995 9.8618 9.77587 10.0854 9.5 10.0854C9.22413 10.0854 9.0005 9.8618 9.0005 9.58594V5.58594C9.0005 5.31007 9.22413 5.08644 9.5 5.08644ZM7.9995 7.58594C7.9995 7.31007 7.77587 7.08644 7.5 7.08644C7.22413 7.08644 7.0005 7.31007 7.0005 7.58594V9.58594C7.0005 9.8618 7.22413 10.0854 7.5 10.0854C7.77587 10.0854 7.9995 9.8618 7.9995 9.58594V7.58594Z"
      fill="currentColor"
    />
  </svg>
);

export const MonitoringIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      d="M11.0566 12.386L12.5007 13.83"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.94391 12.386L2.5 13.8299"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 13.8644C10.2614 13.8644 12.5 11.6259 12.5 8.86444C12.5 6.10302 10.2614 3.86444 7.5 3.86444C4.73858 3.86444 2.5 6.10302 2.5 8.86444C2.5 11.6259 4.73858 13.8644 7.5 13.8644Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M7.33008 6.50006V9.24544L9.48813 10.5612"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.06055 2.927C4.45913 2.92778 4.85 3.03696 5.19129 3.24283C5.53259 3.44871 5.81146 3.74354 5.99805 4.09575C5.22849 4.33353 4.52892 4.75627 3.96055 5.327C3.499 5.79171 3.13182 6.34142 2.8793 6.94575C2.57044 6.75047 2.31614 6.48006 2.14017 6.15981C1.96419 5.83956 1.87229 5.47992 1.87305 5.1145C1.87369 4.53454 2.10436 3.97851 2.51446 3.56841C2.92456 3.15831 3.48059 2.92764 4.06055 2.927V2.927Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M10.9395 2.927C11.5194 2.92764 12.0754 3.15831 12.4855 3.56841C12.8956 3.97851 13.1263 4.53454 13.127 5.1145C13.1277 5.47992 13.0358 5.83956 12.8598 6.15981C12.6839 6.48006 12.4296 6.75047 12.1207 6.94575C11.8682 6.34142 11.501 5.79171 11.0395 5.327C10.4711 4.75627 9.77152 4.33353 9.00195 4.09575C9.18854 3.74354 9.46742 3.44871 9.80871 3.24283C10.15 3.03696 10.5409 2.92778 10.9395 2.927V2.927Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </svg>
);

export const TeamsIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 4.08494C4.94628 4.08494 4.499 4.53222 4.499 5.08594C4.499 5.63475 4.94864 6.08694 5.5 6.08694C6.04645 6.08694 6.501 5.63239 6.501 5.08594C6.501 4.53458 6.04881 4.08494 5.5 4.08494ZM3.5 5.08594C3.5 3.98048 4.39455 3.08594 5.5 3.08594C6.59818 3.08594 7.5 3.98048 7.5 5.08594C7.5 6.18412 6.59818 7.08594 5.5 7.08594C4.39455 7.08594 3.5 6.18412 3.5 5.08594ZM2.01473 11.2951C1.92418 11.7133 2.26375 12.0707 2.68632 12.0707L2.70896 12.0859H8.31561C8.73819 12.0859 9.07021 11.7285 8.9872 11.3027C8.74573 10.1392 7.98359 8.08594 5.50097 8.08594C3.01834 8.08594 2.24866 10.1392 2.01473 11.2951ZM7.90402 11.0869H3.09419C3.21368 10.6798 3.40222 10.2428 3.69135 9.88525C4.04749 9.44488 4.58259 9.08494 5.50097 9.08494C6.4188 9.08494 6.95158 9.44437 7.30677 9.88497C7.59405 10.2413 7.78242 10.6771 7.90402 11.0869ZM11.7459 12.0859H10V11.0869H11.7459C11.8502 11.0869 11.9268 11.062 11.9749 11.0378C11.8371 10.5549 11.6229 10.0619 11.3132 9.69491C11.0454 9.37759 10.4809 9.13983 10 9.08594V8.08594C11.8823 8.08594 12.6644 9.71953 12.9735 10.9027C13.1372 11.5363 12.5189 12.0859 11.7459 12.0859ZM11.001 5.08594C11.001 5.66906 10.6203 6.08694 10 6.08694V7.08594C11.208 7.08594 12 6.18412 12 5.08594C12 3.98048 11.208 3.08594 10 3.08594V4.08494C10.6226 4.08494 11.001 4.49795 11.001 5.08594Z"
      fill="currentColor"
    />
  </svg>
);

export const MultiplayerIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d="M4.45435 8.65607V6.85571"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.55267 7.75073H5.35303"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.38586 4.37109C3.51952 4.37109 2.65386 4.70138 1.9926 5.36264C0.670761 6.68448 0.670761 8.82664 1.9926 10.1485C3.31444 11.4703 5.45659 11.4703 6.77843 10.1485H8.22278C9.54462 11.4703 11.6868 11.4703 13.0086 10.1485C14.3305 8.82664 14.3305 6.68448 13.0086 5.36264C12.348 4.70138 11.4817 4.37109 10.6154 4.37109H4.38586Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CredentialsIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.57098 2.08594C7.67462 2.08594 6.14197 3.62169 6.14197 5.51899C6.14197 5.67063 6.151 5.82026 6.16927 5.96769L2.14649 9.98941C2.0527 10.0832 2 10.2104 2 10.343V12.5859C2 12.8621 2.22386 13.0859 2.49999 13.0859H4.73983C4.87256 13.0859 4.99984 13.0332 5.09362 12.9392L5.61051 12.4216C5.70413 12.3279 5.75671 12.2008 5.75671 12.0683V11.3606H6.46278C6.59551 11.3606 6.72279 11.3078 6.81657 11.2139L7.67805 10.3512C7.77167 10.2575 7.82425 10.1304 7.82425 9.99794V9.29021H8.53032C8.6645 9.29021 8.79305 9.23627 8.88707 9.14053L9.11217 8.9113C9.22287 8.92495 9.3348 8.93337 9.4479 8.93666C9.4878 8.94679 9.52912 8.95204 9.57098 8.95204C11.4605 8.95204 13 7.40939 13 5.51899C13 3.62093 11.4597 2.08594 9.57098 2.08594ZM7.14196 5.51899C7.14196 4.17267 8.2282 3.08593 9.57098 3.08593C10.9076 3.08593 12 4.17344 12 5.51899C12 6.82654 10.9583 7.89988 9.6659 7.95021C9.63048 7.94232 9.59405 7.93825 9.5572 7.93825C9.37604 7.93825 9.20334 7.92072 9.03837 7.88654C8.8719 7.85205 8.6993 7.90451 8.58018 8.02581L8.32055 8.29022H7.32425C7.04811 8.29022 6.82426 8.51407 6.82426 8.79021V9.79104L6.25547 10.3606H5.25671C4.98057 10.3606 4.75672 10.5845 4.75672 10.8606V11.8614L4.53252 12.0859H2.99999V10.5501L7.0575 6.4937C7.17653 6.3747 7.2277 6.20378 7.19365 6.03894C7.15948 5.8736 7.14196 5.70053 7.14196 5.51899ZM9.5 6.08594C9.77 6.08594 10 5.85594 10 5.58594C10 5.30594 9.77 5.08594 9.5 5.08594C9.22 5.08594 9 5.30594 9 5.58594C9 5.85594 9.22 6.08594 9.5 6.08594Z"
      fill="currentColor"
    />
  </svg>
);

export const NotesIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87132 3.4053C9.44866 2.98287 8.76714 2.97551 8.34325 3.40709L2.97436 8.76731C2.90854 8.83302 2.86238 8.9158 2.84106 9.00633L2.26355 11.4594C2.2239 11.6279 2.27425 11.8048 2.39665 11.9272L2.4082 11.9387C2.5305 12.061 2.70733 12.1113 2.87566 12.0717L5.33009 11.4945C5.42057 11.4732 5.50332 11.4271 5.56903 11.3613L10.9341 5.99338L10.9342 5.9934L10.9384 5.98907C11.3505 5.56667 11.3568 4.88998 10.9339 4.46735L9.87132 3.4053ZM9.05569 4.1074C9.07191 4.09077 9.08919 4.08494 9.10601 4.08494C9.12308 4.08494 9.14433 4.09113 9.16511 4.11189L10.2277 5.17394C10.255 5.20122 10.2615 5.251 10.2246 5.29016L4.96297 10.5546L3.41732 10.918L3.78088 9.37375L9.05106 4.11208L9.05109 4.11211L9.05569 4.1074ZM9.25025 11.0864C8.97438 11.0864 8.75075 11.3101 8.75075 11.5859C8.75075 11.8618 8.97438 12.0854 9.25025 12.0854H12.2502C12.5261 12.0854 12.7498 11.8618 12.7498 11.5859C12.7498 11.3101 12.5261 11.0864 12.2502 11.0864H9.25025Z"
      fill="currentColor"
    />
  </svg>
);

export const ResourcesIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.02626 7.08644C3.23017 5.23009 4.55774 3.71758 6.31643 3.24144C6.11846 3.46579 5.9332 3.74142 5.76847 4.0579C5.37499 4.81384 5.07699 5.84382 5.0132 7.08644H3.02626ZM3.0264 8.08544C3.2308 9.93711 4.55825 11.4521 6.3158 11.9297C6.11807 11.7055 5.93303 11.4301 5.76847 11.114C5.37499 10.358 5.07699 9.32805 5.0132 8.08544H3.0264ZM6.01366 8.08544C6.07623 9.17567 6.33882 10.046 6.65461 10.6527C6.83661 11.0024 7.02946 11.2516 7.19937 11.4063C7.37934 11.5703 7.48494 11.5864 7.5 11.5864C7.51271 11.5864 7.61738 11.5711 7.7978 11.4066C7.96771 11.2516 8.16086 11.0021 8.34326 10.6524C8.65972 10.0456 8.92341 9.17529 8.98627 8.08544H7.5H6.01366ZM9.98674 8.08544C9.92265 9.32843 9.62321 10.3585 9.22905 11.1143C9.06418 11.4305 8.87898 11.7057 8.6814 11.9298C10.437 11.4522 11.7684 9.93669 11.9735 8.08544H9.98674ZM11.9736 7.08644H9.98674C9.92265 5.84344 9.62321 4.81338 9.22905 4.05755C9.06401 3.74109 8.87859 3.46557 8.68078 3.24137C10.4375 3.71752 11.769 5.2305 11.9736 7.08644ZM8.98627 7.08644H7.5H6.01366C6.07623 5.99621 6.33882 5.12585 6.65461 4.51915C6.83661 4.16949 7.02946 3.92032 7.19937 3.76553C7.37934 3.60157 7.48494 3.58544 7.5 3.58544C7.51271 3.58544 7.61738 3.60074 7.7978 3.76531C7.96771 3.92031 8.16086 4.16974 8.34326 4.51949C8.65972 5.12631 8.92341 5.99658 8.98627 7.08644ZM2 7.58594C2 4.54387 4.45793 2.08594 7.5 2.08594C10.5345 2.08594 13 4.54387 13 7.58594C13 10.6204 10.5345 13.0859 7.5 13.0859C4.45793 13.0859 2 10.6204 2 7.58594Z"
      fill="currentColor"
    />
  </svg>
);

export const PagesIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      d="M10.7814 12.1177H3.59387C3.24869 12.1177 2.96887 11.8379 2.96887 11.4927V3.68018C2.96887 3.335 3.24869 3.05518 3.59387 3.05518H8.90637C9.25155 3.05518 9.53137 3.335 9.53137 3.68018V6.33643"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9518 6.17969H9.53137V10.8672C9.53137 11.5575 10.091 12.1172 10.7814 12.1172C11.4717 12.1172 12.0314 11.5575 12.0314 10.8672V7.25923C12.0314 6.66302 11.548 6.17969 10.9518 6.17969Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.84387 5.55518C4.84387 5.21 5.12369 4.93018 5.46887 4.93018H7.03137C7.37655 4.93018 7.65637 5.21 7.65637 5.55518V6.49268C7.65637 6.83785 7.37655 7.11768 7.03137 7.11768H5.46887C5.12369 7.11768 4.84387 6.83785 4.84387 6.49268V5.55518Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99988 8.68018H7.49988"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99988 10.2417H7.49988"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
