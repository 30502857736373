export const AppTopBarUserIconList = () => (
  <svg width="54" height="22" viewBox="0 0 54 22">
    <rect
      x="1.1"
      y="1.1"
      width="19.8"
      height="19.8"
      rx="9.9"
      stroke="white"
      strokeWidth="1.8"
    />
    <circle cx="11" cy="11" r="8.55" fill="#5ABE89" stroke="#25A871" />
    <rect
      x="17.1"
      y="1.1"
      width="19.8"
      height="19.8"
      rx="9.9"
      stroke="white"
      strokeWidth="1.8"
    />
    <circle cx="27" cy="11" r="8.55" fill="#FD975D" stroke="#F47E3F" />
    <rect
      x="33.1"
      y="1.1"
      width="19.8"
      height="19.8"
      rx="9.9"
      stroke="white"
      strokeWidth="1.8"
    />
    <circle cx="43" cy="11" r="8.55" fill="#A990F5" stroke="#7F69CE" />
  </svg>
);
