import { styled } from "@linaria/react";
import video from "../../../../static/images/university/tines-university-intro.mp4";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { useRef, useState } from "react";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { cx } from "linaria";
import {
  fromDesktop,
  fromTabletLg,
  uptoTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const Overlay = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  pointer-events: none;
  transition: 0.3s;
  background-color: ${withOpacity(colors.purple600, 0.7)};
  backdrop-filter: blur(3px);
  border-radius: 50%;
  transform: translate3d(0, 0, 0);
  .playing & {
    opacity: 0;
    transform: scale(1.1);
    background-color: ${withOpacity(colors.purple600, 0.2)};
    backdrop-filter: blur(1px);
  }
  .playOrPauseButton {
    transition: 0.1s;
    ${uptoTabletLg} {
      width: 24px;
      height: auto;
    }
  }
  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Bubble = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${colors.purple800};
  color: ${colors.white};
  border: 2px solid ${colors.purple300};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
`;

const BubblePositioner = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  width: 100px;
  height: 100px;
  ${fromTabletLg} {
    bottom: 32px;
    left: 32px;
    width: 150px;
    height: 150px;
  }
  ${fromDesktop} {
    width: 175px;
    height: 175px;
  }
  z-index: 100;
  transition: 0.1s;
  &:hover {
    &.neverStarted {
      transform: scale(1.05);
    }
    &.hasStarted {
      ${Overlay} {
        opacity: 0;
      }
      &.shouldShowPauseButton.playing {
        ${Overlay} {
          opacity: 1;
        }
      }
      &.paused {
        transform: scale(1.05);
        ${Overlay} {
          opacity: 1;
        }
      }
    }
  }
  &:active {
    transform: scale(0.98);
  }
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s, transform 0.38s cubic-bezier(0.13, 0.66, 0.4, 1.17);
  &.ready {
    opacity: 1;
    transform: scale(1);
  }
`;

const Video = styled.video`
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const UniIntroVideoBubble = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [ready, setReady] = useState(false);
  const [playState, setPlayState] = useState("paused");
  const [hasStarted, setHasStarted] = useState(false);
  const [shouldShowPauseButton, setShouldShowPauseButton] = useState(false);
  const lastPointerMoveTimeRef = useRef(0);
  const lastPointerMoveTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  useOnMount(() => {
    videoRef.current?.play();
    setTimeout(() => setReady(true), 500);
  });
  const handleClick = () => {
    handlePointerMove();
    if (!videoRef.current) return;
    const video = videoRef.current;
    const hasStarted = !video.muted;
    if (hasStarted) {
      if (video.paused) {
        video.play();
        setPlayState("playing");
      } else {
        video.pause();
        setPlayState("paused");
      }
    } else {
      video.currentTime = 0;
      video.muted = false;
      video.loop = false;
      video.play();
      setPlayState("playing");
      setHasStarted(true);
      setShouldShowPauseButton(false);
    }
  };
  const handleVideoEnded = () => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    video.currentTime = 0;
    video.muted = true;
    video.loop = true;
    setPlayState("paused");
  };
  const handlePointerMove = () => {
    setShouldShowPauseButton(true);
    lastPointerMoveTimeRef.current = performance.now();
    if (lastPointerMoveTimeoutRef.current)
      clearTimeout(lastPointerMoveTimeoutRef.current);
    lastPointerMoveTimeoutRef.current = setTimeout(() => {
      if (lastPointerMoveTimeRef.current - performance.now() < 1500) {
        setShouldShowPauseButton(false);
      }
    }, 1500);
  };
  return (
    <BubblePositioner
      className={cx(
        playState,
        hasStarted ? "hasStarted" : "neverStarted",
        shouldShowPauseButton && "shouldShowPauseButton",
        ready ? "ready" : "loading"
      )}
    >
      <Bubble onClick={handleClick} onPointerMove={handlePointerMove}>
        <Video
          src={video}
          muted
          loop
          preload="auto"
          controls={false}
          playsInline
          ref={videoRef}
          onEnded={handleVideoEnded}
        />
        <Overlay>
          <CircularTextWrap>
            <CircularText />
          </CircularTextWrap>
          {playState === "playing" ? <PauseIcon /> : <PlayIcon />}
        </Overlay>
      </Bubble>
    </BubblePositioner>
  );
};

const PlayIcon = () => (
  <svg
    className="playOrPauseButton"
    width="39"
    height="36"
    viewBox="0 0 39 36"
    fill="currentColor"
  >
    <path d="M36.0213 16.2704C37.3489 17.0413 37.3489 18.9587 36.0213 19.7296L11.0043 34.2556C9.67094 35.0298 8 34.0678 8 32.526L8 3.47399C8 1.9322 9.67095 0.970226 11.0043 1.74442L36.0213 16.2704Z" />
  </svg>
);

const PauseIcon = () => (
  <svg
    className="playOrPauseButton"
    width="39"
    height="36"
    viewBox="0 0 39 36"
    fill="currentColor"
    fillOpacity={0.8}
  >
    <rect x="5" y="4" width="12" height="28" rx="2" />
    <rect x="23" y="4" width="12" height="28" rx="2" />
  </svg>
);

const CircularTextWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircularTextSvg = styled.svg`
  display: block;
  max-width: 90%;
  height: auto;
  @keyframes CircularTextSpin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: CircularTextSpin 18s linear infinite;
  .hasStarted & {
    transition: opacity 0.5s;
    opacity: 0;
  }
`;

const CircularText = () => (
  <CircularTextSvg
    width="149"
    height="149"
    viewBox="0 0 149 149"
    fill="currentColor"
  >
    <path d="M101.23 11.88L102.65 12.64L101.33 18.81L105.8 14.33L106.99 14.96L105.76 21.17L110.16 16.65L111.57 17.4L105.41 23.64L104.02 22.9L105.24 16.82L100.87 21.22L99.5 20.49L101.23 11.89V11.88Z" />
    <path d="M115.02 20.11L116.2 21.01L113.68 29.65L112.37 28.65L112.98 26.66L110.4 24.69L108.64 25.81L107.33 24.81L115 20.1L115.02 20.11ZM111.62 23.93L113.41 25.3L114.38 22.17L111.62 23.93Z" />
    <path d="M119.96 26.03L118.13 24.27L119.08 23.28L123.88 27.9L122.93 28.89L121.1 27.14L116.23 32.21L115.09 31.11L119.96 26.04V26.03Z" />
    <path d="M121.67 31.64C123.9 29.85 126.25 30.49 127.63 32.21C128.89 33.78 128.93 35.73 127.72 37L126.68 35.7C127.33 35.03 127.26 33.94 126.56 33.06C125.64 31.92 124.17 31.71 122.68 32.9C121.19 34.09 121.09 35.58 122.01 36.72C122.73 37.62 123.78 37.91 124.58 37.45L125.57 38.68C124.11 39.64 122.22 39.18 120.94 37.58C119.54 35.83 119.45 33.42 121.68 31.64H121.67Z" />
    <path d="M134.89 43.42L127.63 47.65L126.83 46.28L129.95 44.46L128.06 41.22L124.94 43.04L124.15 41.68L131.41 37.45L132.2 38.81L129.25 40.53L131.14 43.77L134.09 42.05L134.89 43.42Z" />
    <path d="M137.44 49.19L138.01 50.67L130.18 53.71L129.61 52.23L137.44 49.19Z" />
    <path d="M139.05 53.38L139.5 55.02L134.73 60.09L140.45 58.54L140.86 60.06L132.75 62.26L132.3 60.62L137.05 55.55L131.34 57.1L130.93 55.58L139.04 53.38H139.05Z" />
    <path d="M140.29 65.06L139.96 62.54L141.32 62.36L142.18 68.97L140.82 69.15L140.49 66.64L133.52 67.55L133.31 65.98L140.28 65.07L140.29 65.06Z" />
    <path d="M142.26 71.11L142.32 74.54C142.35 76.29 141.3 77.35 139.78 77.38C138.92 77.39 138.32 76.96 137.95 76.28C137.6 76.85 136.98 77.23 136.22 77.3L133.98 77.54L133.95 75.97L136.01 75.73C136.64 75.67 137.09 75.22 137.08 74.58L137.05 72.77L133.9 72.82L133.87 71.24L142.27 71.1L142.26 71.11ZM138.44 74.53C138.46 75.47 139.07 75.84 139.74 75.83C140.41 75.82 140.99 75.43 140.98 74.49L140.95 72.72L138.41 72.76L138.44 74.53Z" />
    <path d="M138.07 79.39C140.52 79.74 142.07 81.78 141.72 84.2C141.38 86.6 139.32 88.13 136.87 87.78C134.42 87.43 132.88 85.38 133.22 82.98C133.57 80.56 135.62 79.04 138.07 79.38V79.39ZM134.59 83.17C134.37 84.69 135.44 85.97 137.1 86.21C138.76 86.45 140.15 85.52 140.37 84C140.59 82.48 139.51 81.21 137.85 80.98C136.19 80.75 134.81 81.66 134.59 83.18V83.17Z" />
    <path d="M129.03 107.64C129.69 108.06 129.87 108.93 129.46 109.58C129.05 110.23 128.18 110.43 127.52 110.01C126.86 109.59 126.67 108.71 127.08 108.06C127.49 107.41 128.37 107.22 129.03 107.64Z" />
    <path d="M115.58 128.77L114.22 129.63L109.52 125.43L111.2 131.53L110.06 132.25L105.28 128.1L107.03 134.16L105.68 135.01L103.32 126.58L104.65 125.74L109.33 129.81L107.68 123.83L108.99 123.01L115.6 128.77H115.58Z" />
    <path d="M101.61 136.68L100.24 137.26L93.98 130.79L95.49 130.15L96.92 131.67L99.9 130.41L99.8 128.33L101.31 127.69L101.61 136.69V136.68ZM99.97 131.83L97.89 132.71L100.13 135.1L99.97 131.83Z" />
    <path d="M94.01 138.04L96.45 137.32L96.84 138.63L90.45 140.51L90.06 139.2L92.49 138.49L90.5 131.74L92.02 131.29L94.01 138.04Z" />
    <path d="M88.31 136.74C88.75 139.56 87.03 141.29 84.85 141.63C82.86 141.94 81.14 141.01 80.65 139.33L82.3 139.07C82.56 139.96 83.54 140.46 84.65 140.28C86.1 140.05 87.02 138.88 86.72 136.99C86.42 135.1 85.19 134.28 83.74 134.51C82.6 134.69 81.82 135.45 81.82 136.38L80.25 136.63C80.15 134.88 81.48 133.47 83.51 133.15C85.73 132.8 87.86 133.92 88.3 136.75L88.31 136.74Z" />
    <path d="M71.59 142.37L71.45 133.97L73.03 133.94L73.09 137.55L76.83 137.49L76.77 133.88L78.34 133.85L78.48 142.25L76.91 142.28L76.85 138.87L73.11 138.93L73.17 142.34L71.59 142.37Z" />
    <path d="M65.22 141.69L63.65 141.46L64.89 133.15L66.46 133.38L65.22 141.69Z" />
    <path d="M60.81 141.02L59.16 140.59L57.11 133.94L55.63 139.68L54.11 139.29L56.21 131.16L57.86 131.59L59.91 138.23L61.39 132.51L62.91 132.9L60.81 141.03V141.02Z" />
    <path d="M50.01 136.29L52.37 137.26L51.85 138.53L45.68 136L46.2 134.73L48.54 135.69L51.21 129.18L52.68 129.78L50.01 136.29Z" />
    <path d="M43.64 134.98L40.66 133.28C39.14 132.41 38.76 130.97 39.51 129.64C39.94 128.89 40.61 128.6 41.39 128.62C41.08 128.03 41.06 127.3 41.39 126.6L42.32 124.55L43.69 125.33L42.85 127.23C42.58 127.81 42.74 128.42 43.29 128.74L44.87 129.64L46.43 126.89L47.81 127.67L43.65 134.98H43.64ZM42.64 129.95C41.83 129.49 41.19 129.83 40.86 130.41C40.53 130.99 40.57 131.7 41.39 132.16L42.93 133.04L44.19 130.83L42.65 129.95H42.64Z" />
    <path d="M38.69 127.26C37.17 129.21 34.63 129.54 32.7 128.03C30.79 126.54 30.48 123.99 32 122.04C33.52 120.09 36.07 119.77 37.98 121.26C39.91 122.77 40.21 125.31 38.69 127.26ZM37.15 122.35C35.94 121.4 34.3 121.69 33.26 123.02C32.23 124.35 32.34 126.02 33.55 126.96C34.76 127.9 36.4 127.6 37.43 126.28C38.46 124.96 38.35 123.3 37.14 122.36L37.15 122.35Z" />
    <path d="M18.66 105.38C17.97 105.74 17.12 105.47 16.76 104.79C16.4 104.11 16.66 103.26 17.35 102.89C18.04 102.52 18.9 102.79 19.26 103.47C19.62 104.15 19.35 105.01 18.66 105.37V105.38Z" />
    <path d="M6.98999 83.22L6.91999 81.61L12.9 79.62L6.76999 78.04L6.70999 76.7L12.69 74.61L6.55999 73.11L6.48999 71.52L14.99 73.66L15.06 75.23L9.19999 77.27L15.21 78.81L15.28 80.36L6.98999 83.23V83.22Z" />
    <path d="M7.07001 67.17L7.25001 65.69L15.98 63.47L15.79 65.1L13.76 65.58L13.38 68.8L15.24 69.75L15.05 71.38L7.09001 67.17H7.07001ZM12.09 68.16L12.36 65.92L9.18001 66.68L12.1 68.16H12.09Z" />
    <path d="M9.65999 59.89L9.06999 62.36L7.73999 62.05L9.26999 55.57L10.6 55.88L10.02 58.34L16.86 59.96L16.5 61.5L9.65999 59.88V59.89Z" />
    <path d="M13.62 55.59C10.95 54.58 10.3 52.23 11.09 50.16C11.8 48.28 13.47 47.25 15.17 47.65L14.58 49.21C13.67 48.99 12.76 49.6 12.36 50.65C11.84 52.02 12.41 53.4 14.19 54.08C15.97 54.76 17.3 54.09 17.82 52.73C18.23 51.65 17.95 50.6 17.15 50.14L17.71 48.66C19.28 49.43 19.84 51.29 19.11 53.21C18.31 55.31 16.28 56.6 13.61 55.59H13.62Z" />
    <path d="M17.08 38.24L24.4 42.36L23.62 43.74L20.47 41.97L18.63 45.23L21.78 47L21.01 48.37L13.69 44.25L14.46 42.88L17.43 44.55L19.27 41.29L16.3 39.62L17.08 38.24Z" />
    <path d="M20.79 33.08L21.77 31.84L28.36 37.05L27.38 38.29L20.79 33.08Z" />
    <path d="M23.58 29.58L24.77 28.36L31.56 29.9L27.32 25.75L28.42 24.63L34.42 30.5L33.23 31.72L26.45 30.19L30.68 34.33L29.58 35.45L23.58 29.58Z" />
    <path d="M33.01 22.59L31 24.16L30.16 23.08L35.41 18.98L36.25 20.06L34.25 21.62L38.58 27.16L37.33 28.13L33 22.59H33.01Z" />
    <path d="M37.31 17.7L40.26 15.95C41.77 15.05 43.21 15.44 43.99 16.75C44.43 17.49 44.35 18.22 43.95 18.88C44.62 18.9 45.26 19.25 45.7 19.88L47.02 21.7L45.67 22.5L44.44 20.83C44.07 20.31 43.46 20.14 42.91 20.47L41.35 21.4L42.96 24.11L41.6 24.92L37.31 17.7ZM42.18 19.31C42.98 18.83 43 18.11 42.66 17.53C42.32 16.95 41.69 16.64 40.88 17.12L39.35 18.03L40.65 20.22L42.18 19.31Z" />
    <path d="M46.49 17.21C45.55 14.92 46.53 12.55 48.79 11.62C51.03 10.7 53.4 11.7 54.34 13.99C55.28 16.28 54.29 18.65 52.05 19.57C49.78 20.5 47.43 19.49 46.49 17.2V17.21ZM51.52 18.3C52.94 17.72 53.51 16.15 52.87 14.6C52.23 13.05 50.73 12.31 49.31 12.89C47.89 13.47 47.34 15.05 47.98 16.6C48.62 18.15 50.11 18.88 51.53 18.3H51.52Z" />
    <path d="M75.41 10.71C75.44 9.93002 76.1 9.33003 76.86 9.36003C77.62 9.39003 78.24 10.03 78.21 10.81C78.18 11.59 77.52 12.2 76.76 12.18C76 12.16 75.38 11.49 75.41 10.71Z" />
  </CircularTextSvg>
);

export default UniIntroVideoBubble;
