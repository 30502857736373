/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { getCookie } from "../../../utils/cookies.utils";

export function initCommonRoom() {
  if (typeof window === 'undefined') return;
  if (typeof window.signals !== 'undefined') return;
  var script = document.createElement('script');
  script.src = 'https://cdn.cr-relay.com/v1/site/72a04513-c590-4a24-b26d-ed1aa41a2769/signals.js';
  script.async = true;
  window.signals = Object.assign(
    [],
    ['page', 'identify', 'form'].reduce(function (acc, method) {
      acc[method] = function () {
        signals.push([method, arguments]);
        return signals;
      };
      return acc;
    }, {})
  );
  document.head.appendChild(script);
  identifyKnownUserForCommonRoom();
}

export function identifyKnownUserForCommonRoom() {
  if (typeof window === 'undefined') return;
  if (typeof window.signals === 'undefined') return;
  const email = getCookie("email_address");
  if (email) window.signals.identify({ email });
}