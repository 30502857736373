import { styled } from "@linaria/react";
import { fluidFontSize } from "../../styles/helpers.styles";
import { serif } from "../../styles/fonts.styles";
import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  createElement,
} from "react";
import { colors } from "../../styles/colors.styles";
import { GridCanvas } from "../decorations/GridCanvas";
import Spacing from "../layout/Spacing";
import { css, cx } from "linaria";
import RigidDotGrid from "../decorations/RigidDotGrid";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  getWidthPxInMaxGrid,
  widthInGrid,
} from "../../constants/globalGrid.constants";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import {
  fromDesktopMd,
  fromTablet,
  onlyPhones,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { isString } from "../../utils/typeChecks.utils";
import SiteNavHeightPlaceholder from "../site/SiteNavHeightPlaceholder";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";

const HeroSectionWrap = styled.header<{
  textColor?: string;
}>`
  position: relative;
  text-align: center;
  color: ${p => p.textColor ?? "inherit"};
  &.clipOverflow {
    overflow: hidden;
  }
`;

export const HeroSectionBackdropLayer = styled.div<{
  backgroundColor?: string;
  offsetBottom?: string;
}>`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${p => p.backgroundColor ?? "transparent"};
  bottom: ${p => p.offsetBottom || "0"};
  left: 0;
`;

const Content = styled.div`
  &.contentRelative {
    position: relative;
  }
  ${maxPageContentWidthStyleObject}
  &.wider {
    ${widerPageSectionMaxWidthStyle()};
    padding-left: ${rSize("pageMargin")};
    padding-right: ${rSize("pageMargin")};
    ${fromTablet} {
      padding-left: ${rSize("widerPageMargin")};
      padding-right: ${rSize("widerPageMargin")};
    }
  }
  h1 {
    ${fluidFontSize(44, 64)};
    font-weight: 300;
    font-family: ${serif};
    letter-spacing: -0.015em;
    ${fromTablet} {
      letter-spacing: -0.02em;
    }
    line-height: 0.97;
    text-wrap: balance; // supported in Chrome but not in safari
    > span {
      display: block;
    }
    + * {
      margin-top: 1.5em;
    }
  }
  > * {
    + h1 {
      margin-top: 3.2rem;
    }
  }
  &.largeHeading {
    h1 {
      ${fluidFontSize(48, 84)};
    }
    > * {
      + h1 {
        margin-top: 4.8rem;
      }
    }
  }
  p {
    max-width: ${getWidthPxInMaxGrid(6)};
    margin-left: auto;
    margin-right: auto;
  }
`;

const HeroSection = (
  props: PropsWithChildren<{
    className?: string;
    largeHeading?: boolean;
    backgroundColor?: string;
    textColor?: string;
    backdrop?: "grid" | "dots";
    backdropColor?: string;
    backdropPositionBottom?: string;
    /** only available to the grid design */
    backdropParallax?: boolean;
    /** only available to the grid design */
    backdropAnimateIn?: boolean;
    backdropLayerChildren?: ReactNode;
    wider?: boolean;
    contentRelative?: boolean;
    clipOverflow?: boolean;
  }>
) => {
  const backdropColor = props.backdropColor ?? colors.purple100;
  const hasBackground = props.backdrop || props.backdropColor;
  return (
    <HeroSectionWrap
      className={cx(props.className, props.clipOverflow && "clipOverflow")}
      textColor={props.textColor}
    >
      <SiteNavHeightPlaceholder />
      <Spacing size="xxl" />
      {hasBackground && (
        <HeroSectionBackdropLayer
          id="HeroSectionBackdropLayer"
          backgroundColor={props.backgroundColor}
          offsetBottom={props.backdropPositionBottom}
        >
          {props.backdrop === "grid" && (
            <GridCanvas
              color={backdropColor}
              addBorderBottom
              parallax={props.backdropParallax}
              animateIn={props.backdropAnimateIn}
            />
          )}
          {props.backdrop === "dots" && (
            <RigidDotGrid color={backdropColor} cellSize={48} />
          )}
          {props.backdropLayerChildren}
        </HeroSectionBackdropLayer>
      )}
      <Content
        className={cx(
          props.largeHeading && "largeHeading",
          props.wider && "wider",
          props.contentRelative && "contentRelative"
        )}
      >
        {props.children}
      </Content>
      {hasBackground && <Spacing size="sectionPadding" />}
    </HeroSectionWrap>
  );
};

const HeroSectionDescriptionStyle = css`
  max-width: ${getWidthPxInMaxGrid(5)};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3;
  &.wider {
    max-width: ${getWidthPxInMaxGrid(6)};
  }
  &.centered {
    margin-left: auto;
    margin-right: auto;
  }
  ${onlyPhones} {
    margin-left: 1em;
    margin-right: 1em;
  }
`;

export const HeroSectionSplit8Col = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${fromDesktopMd} {
    width: ${widthInGrid(8)};
  }
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  p,
  ${HeroSectionDescriptionStyle} {
    ${fromTablet} {
      margin-left: 0;
    }
  }
`;

export const HeroSectionSplit12Col = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    grid-template-columns: 1fr 1fr;
  }
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  p,
  ${HeroSectionDescriptionStyle} {
    ${fromTablet} {
      margin-left: 0;
    }
  }
`;

export const HeroSectionSplit2to1 = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    grid-template-columns: 2fr 1fr;
  }
  ${fromDesktopMd} {
    width: ${widthInGrid(10)};
  }
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  h1 {
    max-width: 9em;
  }
  p,
  ${HeroSectionDescriptionStyle} {
    max-width: 28em;
    ${fromTablet} {
      margin-left: 0;
    }
  }
`;

export const HeroSectionSplitHeaderWrap = styled.div`
  position: relative;
  > * {
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  h1 {
    ${fluidFontSize(38, 56)};
    text-wrap: balance; // supported in Chrome but not in safari
    letter-spacing: -0.01em;
    font-weight: 300;
    ${uptoTablet} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  p,
  ${HeroSectionDescriptionStyle} {
    ${fromTablet} {
      margin-left: 0;
    }
  }
`;

export const HeroSectionDescription = (props: {
  children: ReactNode;
  className?: string;
  centered?: boolean;
  wider?: boolean;
  style?: CSSProperties;
}) => {
  return createElement(isString(props.children) ? "p" : "div", {
    className: cx(
      props.className,
      HeroSectionDescriptionStyle,
      props.centered && "centered",
      props.wider && "wider"
    ),
    children: props.children,
    style: props.style,
  });
};

export const HeroSectionCenteredIllustrationWrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  svg {
    max-width: 100%;
    height: auto;
  }
  + * {
    margin-top: ${rSize("xl")};
  }
`;

export default HeroSection;
