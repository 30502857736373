import { DatoCmsListItem } from "../../../graphql-types";
import StandardArticleStructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";

type Props = {
  record: DatoCmsListItem;
};

const ListItemBlock = (props: Props) => {
  return (
    <li>
      <StandardArticleStructuredTextWithCustomBlocks
        value={props.record.content}
      />
    </li>
  );
};

export default ListItemBlock;
