import { styled } from "@linaria/react";
import { cx } from "linaria";
import { cover, darken } from "polished";
import { useSiteContext } from "../../context/site.context";
import { isBuildTime } from "../../environment";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { zIndex } from "../../styles/zIndexes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { hideScrollbarsCSS } from "../../utils/scroll.utils";
import { PageTree } from "../../utils/tree.utils";
import HubPageSidebar from "../hub/HubPageSidebar";
import UniLessonPageSidebar from "../hub/university/UniLessonPageSidebar";

const SiteSidebarAside = styled.aside`
  ${onlyPhones} {
    position: fixed;
    top: var(--pageTopFixedElementsHeight, 0px);
    left: 0;
    right: 4em;
    bottom: 0;
    background-color: var(--BlurredPanelBackgroundColor);
    backdrop-filter: blur(2em);
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    padding: 1.25em 1.25em 1.25em 1em;
    overflow: auto;
    z-index: ${zIndex("PageSidebar")};
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.SiteSidebarAside--open {
      transform: translate3d(0, 0, 0);
      box-shadow: 0.5em 0 1em rgba(0, 0, 0, 0.2);
    }
  }
  ${fromTablet} {
    overflow: auto;
    position: sticky;
    padding-bottom: ${rSize("widerPageMargin")};
    top: calc(var(--pageTopFixedElementsHeight, 0px) + 2em);
    max-height: calc(100vh - var(--pageTopFixedElementsHeight, 0px) - 2em);
    transition: top 0.25s, max-height 0.25s;
    .largerGapOnTop & {
      top: calc(var(--pageTopFixedElementsHeight, 0px) + 4em);
      max-height: calc(100vh - var(--pageTopFixedElementsHeight, 0px) - 4em);
    }
  }
`;

const SidebarBackdrop = styled.div`
  ${cover()};
  position: fixed;
  background-color: ${withOpacity(colorsV4.canvas300, 0.3)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(darken(0.1, colorsV4.warmBlack900), 0.3)};`
  )}
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s, transform 0s 0.2s;
  z-index: ${zIndex("PageSidebarBackdrop")};
  transform: translate3d(-100%, 0, 0);
  &.SiteSidebarAside--open {
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s 0.5s, transform 0s 0.5s;
    pointer-events: auto;
    opacity: 1;
  }
  ${fromTablet} {
    display: none;
  }
`;

export const SITE_SIDEBAR_PORTAL_ID = "site-sidebar-portal";
export const getSiteSidebarPortalDestination = () =>
  isBuildTime ? null : document.getElementById(SITE_SIDEBAR_PORTAL_ID);

const SiteSidebar = (props: {
  tree?: PageTree;
  learningPath?: {
    slug: string;
    title: string;
  };
}) => {
  const { hasSidebar, sidebarOpen, toggleSidebar, location } = useSiteContext();
  const isInUniArea = location.pathname.match(/^\/university\//);
  return hasSidebar ? (
    <>
      <SidebarBackdrop
        className={cx(sidebarOpen && "SiteSidebarAside--open")}
        onClick={() => toggleSidebar(false)}
      />
      <SiteSidebarAside
        className={cx(
          "SiteSidebarAside",
          hideScrollbarsCSS,
          sidebarOpen && "SiteSidebarAside--open"
        )}
      >
        <div id={SITE_SIDEBAR_PORTAL_ID} />
        {props.tree &&
          (isInUniArea ? (
            <UniLessonPageSidebar
              tree={props.tree}
              learningPathSlug={props.learningPath?.slug}
            />
          ) : (
            <HubPageSidebar tree={props.tree} />
          ))}
      </SiteSidebarAside>
    </>
  ) : null;
};

export default SiteSidebar;
