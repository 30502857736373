import { styled } from "@linaria/react";
import { LayoutWithSidebar } from "../components/layout/LayoutWithSidebar";
import LibrarySidebar from "../components/library/LibrarySidebar";
import WithLibraryState from "../components/library/WithLibraryState";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import Page from "./Page.template";
import LibraryPageHero from "../components/library/LibraryPageHero";
import Spacing from "../components/layout/Spacing";
import { useOpenStoryRequestModalFunction } from "../components/modals/StoryRequestModal";
import PseudoLink from "../components/basic/PseudoLink";
import LibraryToolTable from "../components/library/LibraryToolTable";
import {
  DatoCmsLibraryToolWithComputedProperties,
  useRankedStoriesAndTools,
} from "../utils/library.utils";
import ToolsIcon from "../components/illustrations/spots/ToolsIcon";
import { onlyPhones } from "../styles/breakpointsAndMediaQueries.styles";
import seoImage from "../../static/images/og/tines-library.png";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { LoadingStateBanner } from "../components/utilities/LoadingIndicatorBanner";

const BodyContent = styled.div`
  ${onlyPhones} {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const LibraryDirectoryToolsPage: PageComponent<{
  pageContext: {
    tools: DatoCmsLibraryToolWithComputedProperties[];
  };
}> = props => {
  const openModal = useOpenStoryRequestModalFunction();
  const { awaitingData, error, tools } = useRankedStoriesAndTools();
  const toolsWithStories = tools.filter(t => t.numberOfStories > 0);
  return (
    <Page {...props}>
      <SEO
        title="Tools | Tines library"
        description="Ready-made automation workflows that you can use in one click. Use these Stories as a starting point for fully custom playbooks or inspiration for your use cases."
        image={seoImage}
      />
      <AdminToolBarWithEditInDatoCmsButton itemType="libraryTool" />
      <WithLibraryState
        awaitingData={awaitingData}
        error={error}
        entries={toolsWithStories}
        entryType="tool"
        tools={toolsWithStories}
        children={p => {
          return (
            <LayoutWithSidebar noPageMarginOnPhones>
              <LibrarySidebar state={p.state} />
              <BodyContent>
                <LibraryPageHero
                  collection={{
                    tagline: "Tools",
                  }}
                  neutralColor
                  icon={<ToolsIcon />}
                  totalCount={toolsWithStories.length}
                  compact
                  description={
                    <>
                      When it comes to integrations, the answer is YES! Tines
                      works with any API. Below are some pre-built examples
                      today to provide inspiration as to what you can
                      accomplish. If you’re looking for something in particular,{" "}
                      <PseudoLink onClick={openModal}>
                        let us know we’re happy to help
                      </PseudoLink>
                      .
                    </>
                  }
                  libraryState={p.state}
                />
                <Spacing />
                <LoadingStateBanner
                  loading={p.state.awaitingData}
                  error={p.state.error}
                />
                {!p.state.awaitingData && (
                  <LibraryToolTable tools={p.entries} state={p.state} />
                )}
                <Spacing size="lg" />
              </BodyContent>
            </LayoutWithSidebar>
          );
        }}
      />
      <Spacing size="xl" />
    </Page>
  );
};

export default LibraryDirectoryToolsPage;
