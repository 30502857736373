/* eslint-disable no-console */
/* cspell:disable */

import { checkIfIsClientAndLive } from "../../../environment";
import { shouldLogGDPRDebugInfo } from "../gdpr";

export const initSalesLoft = () => {
  if (shouldLogGDPRDebugInfo()) {
    console.log("– init SalesLoft");
  }
  if (!checkIfIsClientAndLive()) return;
  eval(
    `(function(i,s,o,g,r,a,m){i['SLScoutObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://scout-cdn.salesloft.com/sl.js','slscout');slscout(["init", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0IjoxMDM0NDV9.M6msw2-K5eJcAWy0UXghet2JZCZszkaBc-ytxB_bet4"]);`
  );
};
