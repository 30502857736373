import PillLegacy from "../../basic/PillLegacy";
import ReportCard from "../ReportCard";
import ReportSection from "../ReportSection";
import ReportSummaryCard from "../ReportSummaryCard";
import ColumnedAndAvoidBreaksInParagraphs, {
  Columned,
} from "../../layout/Columned";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import { Serif } from "../../typography/Serif";
import SpotIllustration from "../../illustrations/SpotIllustration";
import ReportCaseStudyCard from "../ReportCaseStudyCard";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import CornerDotSet from "../../utilities/CornerDotSet";
import { rSize } from "../../../styles/responsiveSizes.styles";
import Spacing from "../../layout/Spacing";
import caseStudyImage from "../../../../static/images/reports/tines-opentable.png";
import { Separator } from "../../utilities/Hr";
import ReportCardWithSpotIllustration from "../ReportCardWithSpotIllustration";

const NoCodePlaybookChapter1 = () => {
  return (
    <ReportSection
      id="introduction"
      themeName="purple"
      smallerSectionHeading
      header={
        <>
          <div>
            <SpotIllustration name="newtonsCradle" />
          </div>
          <PillLegacy color="purple">Part One</PillLegacy>
          <h2>
            Introduction to
            <br />
            no-code automation <Serif>for security teams</Serif>
          </h2>
        </>
      }
    >
      <ReportCard centered largerHeading>
        <svg width="134" height="134" viewBox="0 0 134 134">
          <circle cx="67" cy="67" r="67" fill="#ECEAFF" />
          <circle cx="67" cy="67" r="45" fill="#D4CFFC" />
          <path
            d="M65.2705 74.9337H67.5487C66.5636 65.9441 78.7548 65.5131 78.7548 54.9228C78.7548 49.135 73.9522 45.256 67.364 45.256C61.4531 45.256 56.281 48.704 54.6802 53.9992L60.6527 55.8463C60.9605 50.7359 63.6081 47.7188 67.364 47.7188C70.812 47.7188 73.398 50.3049 73.398 54.307C73.398 62.6193 63.3618 64.1586 65.2705 74.9337ZM62.4382 84.1079C62.4382 86.386 64.2854 88.1101 66.502 88.1101C68.7802 88.1101 70.5042 86.386 70.5042 84.1079C70.5042 81.8913 68.9649 80.1057 66.502 80.1057C64.2854 80.1057 62.4382 81.8913 62.4382 84.1079Z"
            fill="#8578E6"
          />
        </svg>
        <Spacing size="0.5em" />
        <h3>
          What is <Serif>no-code automation?</Serif>
        </h3>
        <blockquote>
          <p>
            Today's frontline security teams need automation to handle complex
            workflows and organizational-specific requirements, often resulting
            in the need to introduce coding and scripting to solve those needs.
          </p>
        </blockquote>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            Security analysts, however, don't necessarily have coding skills,
            requiring them to call in developers, which can take weeks or months
            to create integrations and deploy automations. If an analyst needs
            an update or addition, they need to get developers involved
            all over again.
          </p>
          <p>
            And don’t even get me started on the change management process this
            can involve.
          </p>
          <p>
            Why can't security analysts have the tools to build their own
            automations quickly and simply, without needing to know code and get
            another party involved? They can.
          </p>
          <p>
            With no-code automation, which provides both the power of automation
            and the accessibility of being able to build it themselves. Analysts
            are able to simply drag-and-drop Actions into a workflow, wire them
            together, enter the parameters, test it, and set it loose, taking
            monotonous tasks from time-consuming efforts to truly hands-off
            processes that will only loop them in when their judgment is
            required.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
      </ReportCard>

      <ReportCard centered largerHeading backgroundColor={colorsV4.purple50}>
        <CornerDotSet color={colorsV4.purple100} offset={rSize("gap")} />
        <SpotIllustration name="storyRun" />
        <h3>
          The evolution of
          <br /> <Serif>no-code security automation</Serif>
        </h3>
        <p>
          Automation evolves to fit people’s needs better. As I see it, three
          phases of security automation have gotten us to the easily adoptable
          and accessible no-code automation we have today.
        </p>
        <Spacing size="1em" />
      </ReportCard>

      <ReportCard largerHeading>
        <PillLegacy>Phase 1</PillLegacy>
        <h3>Automation as a feature</h3>
        <Columned>
          <p>
            Security automation started as a feature of larger software
            solutions, like RSA Archer or other legacy security tools that would
            automate the collection of artifacts and make them available in a
            single dashboard. However, it allowed for little customization for
            organization-specific needs, meaning that automation wasn't
            available across all workflows, but only for what the tool’s
            features allowed.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <PillLegacy>Phase 2</PillLegacy>
        <h3>Emergence of SOAR tools</h3>
        <Columned>
          <p>
            As the requirements of security teams grew in sophistication, so did
            the number of technologies and solutions needed. Yet with increased
            tools came an increase in alerts, which quickly overwhelmed the
            SecOps team. Our recent report on the “
            <a
              href="https://www.tines.com/reports/voice-of-the-soc-analyst"
              {...externalLinkAttr}
            >
              Voice of the SOC Analyst
            </a>
            ” found that 60% of analysts say they have more work than ever these
            days, and the number one most frustrating aspect of the job is
            “spending time on manual work” like tracking down alerts.
            First-generation SOAR tools addressed these needs; however, building
            workflows capable of handling the variety of tools and use cases
            modern security teams need to automate proved impossible and costly
            for the average frontline security team.
          </p>
        </Columned>
      </ReportCard>
      <ReportCard largerHeading>
        <PillLegacy>Phase 3</PillLegacy>
        <h3>Development of no-code automation</h3>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            What is needed as we look to the future is no-code automation,
            offered through lightweight and flexible platforms designed to focus
            on workflow exclusively. No-code interfaces remove the barrier of
            having to know how to write scripts or relying on developers to do
            so by offering simple yet robust actions that an analyst can drag
            and drop into sequences and wire together.
          </p>
          <p>
            With just a few building blocks, analysts are able to construct very
            powerful automation streams from the simple to the complex. Since
            they’re typically cloud-first, no-code platforms can be deployed in
            seconds, and analyst ramp-up takes just a few hours. Suddenly,
            automation is efficient, accessible, affordable, and just makes
            sense.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
      </ReportCard>

      <ReportCard centered largerHeading backgroundColor={colorsV4.purple50}>
        <CornerDotSet color={colorsV4.purple100} offset={rSize("gap")} />
        <SpotIllustration name="stackedRings" />
        <h3>Five benefits of no-code automation for security teams</h3>
        <Spacing size="1em" />
      </ReportCard>

      <ReportCardWithSpotIllustration illustration="stopwatch" largerHeading>
        <PillLegacy>Benefit 1</PillLegacy>
        <h3>Faster time to value</h3>
        <p>
          With no-code automation, processes that typically took days or weeks
          to complete can be reduced to minutes or even seconds, thus
          significantly increasing time to value. No-code automation also
          reduces project management needs, communication burdens, unnecessary
          feedback loops, and other extra steps that can be condensed with
          automated workflows. And with businesses getting hit by a cyber attack{" "}
          <a
            href="https://cybersecurityventures.com/global-ransomware-damage-costs-predicted-to-reach-20-billion-usd-by-2021/"
            {...externalLinkAttr}
          >
            every 11 seconds
          </a>
          , increasing speed within the SOC is crucial.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration
        illustration="steppingOnGum"
        largerHeading
      >
        <PillLegacy>Benefit 2</PillLegacy>
        <h3>Significantly improved retention</h3>
        <p>
          Our recent report on the “
          <a
            href="https://www.tines.com/reports/voice-of-the-soc-analyst"
            {...externalLinkAttr}
          >
            Voice of the SOC Analyst
          </a>
          ” found that 71% of analysts feel very or somewhat burned out at work,
          and the number one most frustrating aspect of the job is “spending
          time on manual work.”
        </p>
        <p>
          No one wants to do boring and menial work, and analysts who burn out
          through mind-numbing toil simply leave their jobs. Another benefit of
          no-code automation is higher retention rates because when low-level
          tasks are automated, security practitioners can really focus on what
          they're practitioners of: increasing the security posture of their
          organization, deploying new technology, improving awareness training,
          and other high impact, high value, and more interesting work.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration
        illustration="blossomingMind"
        largerHeading
      >
        <PillLegacy>Benefit 3</PillLegacy>
        <h3>Fewer mistakes</h3>
        <p>
          Mundane work isn’t just bad for humans — humans are bad at it, too.
          Hours of menial, repetitive work increases the likelihood of error,
          which increases the chances of an incident.{" "}
        </p>
        <p>
          <a
            href="https://www.researchgate.net/publication/266025022_Effects_of_Stress_Repetition_Fatigue_and_Work_Environment_on_Human_Error_in_Manufacturing_Industries"
            {...externalLinkAttr}
          >
            One study
          </a>{" "}
          found that upwards of 49% of human error at work is due to stress,
          repetition, or fatigue. However, automated workflows function
          deterministically and consistently, reducing false positives and false
          negatives. No-code automation also reduces error because the analysts
          who know the workflows the best are the ones actually building and
          maintaining the automation.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration illustration="blocksOnCar" largerHeading>
        <PillLegacy>Benefit 4</PillLegacy>
        <h3>Automation flywheel</h3>
        <p>
          By reducing the need to rely on other teams when trying to automate a
          workflow, teams using no-code can automate much more than they
          initially set out to. For example, a team member may build a
          Slack-based chatbot that automates aspects of team process and
          collaboration, not just the threat intelligence workflow.
        </p>
        <p>
          In other words, an automation process an analyst builds for a specific
          purpose might have other applications beyond what they were thinking
          of when they created it.
        </p>
        <p>
          I’ve witnessed many security teams with access to no-code automation
          repeatedly have that light-bulb moment and realize, ‘We could automate
          this!’ and immediately start building the new workflow, allowing for
          more innovation and quicker application.
        </p>
      </ReportCardWithSpotIllustration>
      <ReportCardWithSpotIllustration illustration="zenBrain" largerHeading>
        <PillLegacy>Benefit 5</PillLegacy>
        <h3>Incident readiness</h3>
        <p>
          Another benefit is creating a calm and well-functioning team which,
          when an incident eventually occurs, beats a stressed, overworked team
          every time.
        </p>
        <p>
          In damage-control moments, minutes and even seconds count enormously,
          and already having robust automation in place frees your team to turn
          their full attention to incident response. They can also collect
          information and context about an incident in seconds, and know when to
          alert a human for more critical decision-making.
        </p>
      </ReportCardWithSpotIllustration>

      <ReportSummaryCard
        backgroundColor={colorsV4.purple700}
        textColor={colorsV4.white}
        cornerDotsColor={withOpacity(colorsV4.white, 0.3)}
      >
        <blockquote>
          <p>
            Next, we’ll explore how you can unlock these benefits and many more
            by adopting a no-code automation program.
          </p>
        </blockquote>
      </ReportSummaryCard>

      <ReportCaseStudyCard color="purple" imageSrc={caseStudyImage}>
        <h2>
          How No-Code Automation Saves OpenTable’s Security Team 40 Hours of
          Work per Week
        </h2>
        <Separator />
        <Spacing size="0.25em" />
        <h3>The challenge</h3>
        <Columned>
          <p>
            OpenTable’s security team keeps the company’s staff safe online. The
            team’s workload is increased by handling the security of the
            company’s web apps, too. Internal company security is something that
            became more important than ever in the spring of 2020, when
            employees started working from home due to the coronavirus pandemic.
            The security team needed a tool to cut down the amount of time they
            spent on every incident. Before using Tines, they used to spend a
            lot of time handling incident response, looking at alerts, making
            sure that OpenTable wasn’t under attack or didn’t have malware in
            its systems. Each alert would take between 30 and 90 minutes to
            handle, and if they got too many, it would create a backlog,
            delaying the rest of their workload.
          </p>
        </Columned>
        <h3>The impact of Tines no-code security automation platform</h3>
        <Columned>
          <p>
            According to Joel Perez-Sanchez, Security Engineer, OpenTable:
            “Tines has helped us do more with a small team during a difficult
            period for the whole restaurant trade. The pandemic changed the
            dynamic of the threat field, because suddenly everyone was working
            remotely. Working from home opens businesses up to additional
            threats, as security teams are less able to monitor activity of
            employees’ home networks. This put more demands on the team, as we
            had to rapidly rethink our approach to keeping the company safe.
          </p>
          <p>
            “Whether it’s EDR, traffic behavior analysis, firewall management,
            IDS, phishing simulations or anything else we use, Tines is very
            easy to plug into everything, get the alerts we want, and have it
            process them. That takes hours off our work. Even preparing the
            incident tickets used to take 30 minutes sometimes, and now Tines
            does that for us really well, without us having to lift a finger.
          </p>
          <p>
            “For example, I’ve set it up so when a user reports a phishing
            email, Tines will go in and scrape all the data we need, check with
            VirusTotal, URLhaus, and Urlscan, and others, and then present all
            that information to us in our ticketing system.
          </p>
          <p>
            “After just a short period using Tines, we found it saved us 40
            hours of work per week. It essentially saves us the workload of one
            person. And during uncertain times, that’s more important than
            ever.”
          </p>
        </Columned>
      </ReportCaseStudyCard>
    </ReportSection>
  );
};

export default NoCodePlaybookChapter1;
