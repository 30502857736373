export const ScannerIcon = () => (
  <svg
    width="101"
    height="80"
    viewBox="0 0 101 80"
    fill="none"
    strokeMiterlimit="10"
  >
    <path
      d="M100.043 49.3582C100.043 51.4658 98.3342 53.1747 96.2263 53.1747H69.6653C67.5577 53.1747 65.849 51.4661 65.849 49.3582V27.0032C65.849 24.8956 67.5574 23.1869 69.6653 23.1869H96.2263C98.334 23.1869 100.043 24.8953 100.043 27.0032V49.3582Z"
      fill="#49B889"
      stroke="#32274C"
    />
    <path
      d="M94.2718 27.1598C93.1138 27.1598 92.1748 27.1049 92.1748 25.9466V17.4337C92.1748 12.1801 87.901 7.9056 82.6472 7.9056C77.3934 7.9056 73.3401 12.1799 73.3401 17.4337V25.9466C73.3401 27.1049 72.4016 27.1598 71.2431 27.1598C70.0846 27.1598 69.1457 27.1049 69.1457 25.9466V17.4337C69.1457 9.86712 75.0809 3.71118 82.6472 3.71118C90.2136 3.71118 96.3692 9.86712 96.3692 17.4337V25.9466C96.3695 27.1046 95.4303 27.1598 94.2718 27.1598Z"
      fill="#DAF2E8"
      stroke="#32274C"
    />
    <path
      d="M100.043 45.5168C100.043 47.6245 98.3342 49.3334 96.2263 49.3334H69.6653C67.5577 49.3334 65.849 47.6248 65.849 45.5168V23.1619C65.849 21.0542 67.5574 19.3456 69.6653 19.3456H96.2263C98.334 19.3456 100.043 21.054 100.043 23.1619V45.5168Z"
      fill="#81D8B4"
      stroke="#32274C"
    />
    <path
      d="M92.5652 33.161C92.5652 27.2375 88.0421 22.4359 82.4615 22.4359C76.8819 22.4359 72.3578 27.2375 72.3578 33.161C72.3578 33.6095 72.3578 35.3592 72.3578 35.8077C72.3578 41.7302 76.8819 46.5323 82.4615 46.5323C88.0419 46.5323 92.5652 41.7302 92.5652 35.8077C92.5652 35.3592 92.5652 33.6095 92.5652 33.161Z"
      fill="#32274C"
    />
    <path
      d="M92.5652 31.4306C92.5652 25.8505 88.0421 21.3269 82.4615 21.3269C76.8819 21.3269 72.3578 25.8502 72.3578 31.4306C72.3578 31.8529 72.3578 33.5014 72.3578 33.9237C72.3578 39.5033 76.8819 44.0271 82.4615 44.0271C88.0419 44.0271 92.5652 39.5033 92.5652 33.9237C92.5652 33.5014 92.5652 31.8531 92.5652 31.4306Z"
      fill="#B0A6F9"
      stroke="#32274C"
    />
    <path
      d="M82.4615 41.5343C88.0416 41.5343 92.5652 37.0107 92.5652 31.4306C92.5652 25.8505 88.0416 21.3269 82.4615 21.3269C76.8814 21.3269 72.3578 25.8505 72.3578 31.4306C72.3578 37.0107 76.8814 41.5343 82.4615 41.5343Z"
      fill="#E9E6FF"
      stroke="#32274C"
    />
    <path
      d="M88.1538 29.8551C88.1538 26.7113 85.6051 24.1628 82.4615 24.1628C79.318 24.1628 76.7692 26.7113 76.7692 29.8551C76.7692 30.2066 76.7692 31.5621 76.7692 31.9136C76.7692 35.0574 79.318 37.6059 82.4615 37.6059C85.6051 37.6059 88.1538 35.0572 88.1538 31.9136C88.1538 31.5621 88.1538 30.2068 88.1538 29.8551Z"
      fill="#B0A6F9"
      stroke="#32274C"
    />
    <path
      d="M82.4615 35.5474C85.6053 35.5474 88.1538 32.9989 88.1538 29.8551C88.1538 26.7114 85.6053 24.1628 82.4615 24.1628C79.3178 24.1628 76.7692 26.7114 76.7692 29.8551C76.7692 32.9989 79.3178 35.5474 82.4615 35.5474Z"
      fill="#E9E6FF"
      stroke="#32274C"
    />
    <path
      d="M85.1267 41.3636L85.0599 43.8078L83.821 44.0089L84.1221 41.4304L85.1267 41.3636Z"
      fill="#32274C"
    />
    <path
      d="M55.301 69.2698H48.2047C47.2201 69.2698 46.4224 68.472 46.4224 67.4874C46.4224 66.5029 47.2201 65.7051 48.2047 65.7051H55.301C69.3873 65.7051 80.8477 52.6459 80.8477 36.5937C80.8477 35.6092 81.6455 35.525 82.6301 35.525C83.6146 35.525 84.4124 35.6092 84.4124 36.5937C84.4124 45.2879 81.4126 53.4671 75.9653 59.625C70.4634 65.8446 63.1247 69.2698 55.301 69.2698Z"
      fill="#FFD18C"
      stroke="#32274C"
    />
    <path
      d="M50.766 67.6208C50.766 69.6729 49.1025 71.3366 47.0502 71.3366H34.4579C32.4056 71.3366 30.7421 69.6729 30.7421 67.6208C30.7421 65.5688 32.4056 63.905 34.4579 63.905H47.0502C49.1025 63.9053 50.766 65.569 50.766 67.6208Z"
      fill="#FABE64"
      stroke="#32274C"
    />
    <path
      d="M59.3014 33.0886C58.2087 17.0067 44.2875 4.85669 28.2066 5.94842C12.1279 7.04111 -0.0235698 20.9623 1.06817 37.0427C1.77777 47.4962 7.91018 56.2871 16.5317 60.8611C16.5302 60.8858 16.5243 60.9084 16.5243 60.9331V77.5375C16.5243 78.2583 17.1087 78.8431 17.8294 78.8431H42.5397C43.2604 78.8431 43.8448 78.2583 43.8448 77.5375V60.9331C43.8448 60.9098 43.8393 60.8882 43.8382 60.8654C53.7061 55.6355 60.1073 44.9499 59.3014 33.0886Z"
      fill="#8173E6"
      stroke="#32274C"
    />
    <path
      d="M59.3015 28.5068C58.2088 12.4254 44.2876 0.275387 28.2067 1.36712C12.1279 2.45934 -0.0235393 16.3805 1.0682 32.461C1.7778 42.9149 7.91021 51.7059 16.5317 56.2793C16.5303 56.304 16.5243 56.3266 16.5243 56.3518V72.9557C16.5243 73.6765 17.1087 74.2618 17.8295 74.2618H42.5397C43.2605 74.2618 43.8448 73.6765 43.8448 72.9557V56.3516C43.8448 56.3283 43.8394 56.3062 43.8382 56.2833C53.7061 51.0542 60.1073 40.3688 59.3015 28.5068Z"
      fill="#B0A6F9"
      stroke="#32274C"
    />
    <path
      d="M53.7513 28.8832C54.6351 41.8992 44.8007 53.1661 31.7847 54.0497C18.7694 54.9345 7.50219 45.0996 6.61815 32.0843C5.73435 19.069 15.5693 7.80084 28.5841 6.91728C41.5994 6.03325 52.8675 15.8682 53.7513 28.8832Z"
      fill="#32274C"
      stroke="#32274C"
    />
    <path
      d="M40.196 63.8081C40.196 60.6365 37.6252 58.0647 34.4534 58.0647H25.916C22.7447 58.0647 20.1733 60.6365 20.1733 63.8081C20.1733 64.0569 20.1733 65.0274 20.1733 65.2762C20.1733 68.4483 22.7447 71.0196 25.916 71.0196H34.4534C37.6252 71.0196 40.196 68.4483 40.196 65.2762C40.196 65.0274 40.196 64.0569 40.196 63.8081Z"
      fill="#8173E6"
      stroke="#32274C"
    />
    <path
      d="M40.196 65.2762C40.196 68.4483 37.6252 71.0196 34.4534 71.0196H25.916C22.7447 71.0196 20.1733 68.4483 20.1733 65.2762C20.1733 62.1042 22.7447 59.5333 25.916 59.5333H34.4534C37.6252 59.5333 40.196 62.1042 40.196 65.2762Z"
      fill="#B0A6F9"
      stroke="#32274C"
    />
    <path
      d="M26.3987 67.7598C27.6965 67.7598 28.7485 66.7078 28.7485 65.41C28.7485 64.1122 27.6965 63.0602 26.3987 63.0602C25.1009 63.0602 24.0489 64.1122 24.0489 65.41C24.0489 66.7078 25.1009 67.7598 26.3987 67.7598Z"
      fill="#49B889"
      stroke="#32274C"
    />
    <path
      d="M33.9705 67.7598C35.2683 67.7598 36.3203 66.7078 36.3203 65.41C36.3203 64.1122 35.2683 63.0602 33.9705 63.0602C32.6727 63.0602 31.6207 64.1122 31.6207 65.41C31.6207 66.7078 32.6727 67.7598 33.9705 67.7598Z"
      fill="#FFD18C"
      stroke="#32274C"
    />
    <path
      d="M26.3987 66.8895C27.6965 66.8895 28.7485 65.8374 28.7485 64.5396C28.7485 63.2419 27.6965 62.1898 26.3987 62.1898C25.1009 62.1898 24.0489 63.2419 24.0489 64.5396C24.0489 65.8374 25.1009 66.8895 26.3987 66.8895Z"
      fill="#2A7857"
      stroke="#32274C"
    />
    <path
      d="M33.9705 66.8895C35.2683 66.8895 36.3203 65.8374 36.3203 64.5396C36.3203 63.2419 35.2683 62.1898 33.9705 62.1898C32.6727 62.1898 31.6207 63.2419 31.6207 64.5396C31.6207 65.8374 32.6727 66.8895 33.9705 66.8895Z"
      fill="#FF6565"
      stroke="#32274C"
    />
    <path
      d="M53.7513 28.8832C54.6351 41.8992 44.8007 53.1662 31.7847 54.0497C18.7694 54.9345 7.50219 45.0996 6.61815 32.0843C5.73435 19.069 15.5693 7.80084 28.5841 6.91728C41.5994 6.03325 52.8675 15.8682 53.7513 28.8832Z"
      stroke="#32274C"
    />
    <path
      d="M28.5838 10.0737C41.5996 9.18941 52.8675 19.0238 53.7513 32.0396C53.7523 32.0508 53.7527 32.0624 53.7532 32.0741C53.8224 31.0256 53.8247 29.9612 53.7513 28.8835C52.8675 15.8682 41.5996 6.03349 28.5838 6.91753C15.5811 7.80133 5.75243 19.0495 6.61626 32.0508C7.38575 20.4312 16.6463 10.884 28.5838 10.0737Z"
      fill="#8173E6"
      stroke="#32274C"
    />
    <path
      d="M43.8382 60.8651V56.2836L52.5101 49.2103L51.949 54.4454C51.949 54.4454 46.0117 59.805 43.8382 60.8651Z"
      fill="#32274C"
    />
    <path
      d="M20.7577 62.4505C20.7577 62.4505 22.7192 59.7301 26.4512 59.5403H29.1083V57.959L25.916 58.0647C25.916 58.0647 21.6434 58.5916 20.7577 62.4505Z"
      fill="#32274C"
    />
    <path
      d="M10.9304 30.0562C13.3653 30.0562 13.3653 33.9336 15.8002 33.9336C18.2351 33.9336 18.2351 30.0562 20.67 30.0562C23.1054 30.0562 23.1054 38.7057 25.5403 38.7057C27.9757 38.7057 27.9757 25.7311 30.4108 25.7311C32.846 25.7311 32.846 37.5125 35.2813 37.5125C37.7167 37.5125 37.7167 30.0562 40.1526 30.0562C42.5884 30.0562 42.5882 33.6356 45.024 33.6356C47.4599 33.6356 47.4599 30.0562 49.8955 30.0562"
      stroke="#49B889"
      strokeLinecap="round"
    />
    <path d="M45.779 63.7321H43.8648V71.302H45.779V63.7321Z" fill="#32274C" />
    <path
      d="M83.9979 49.4242L83.4038 52.9889L80.7897 52.87L82.3344 49.543L83.9979 49.4242Z"
      fill="#32274C"
    />
  </svg>
);
