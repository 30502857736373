import { styled } from "@linaria/react";
import { useState } from "react";
import { isAppleDevice } from "../../environment";
import { useOnMount } from "../../utils/lifeCycle.utils";
import StaticImageFrame from "../basic/StaticImageFrame";
import coverImg from "../../../static/images/podcast/tines-podcast-cover-season-1.png";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { colorsV4 } from "../../styles/colorsV4.styles";

const CoverImageLink = styled.a`
  display: block;
  text-decoration: none;
  transition: 0.1s;
  &:hover {
    filter: brightness(1.05);
  }
`;

export const podcastLinks = {
  spotify: "https://open.spotify.com/show/2ViUFqBCqTAe0EPdnY3KPw",
  youtube:
    "https://www.youtube.com/playlist?list=PLrLy59ZkmoJ7bi8HPltSN8uNfQf4VLxkH",
  apple:
    "https://podcasts.apple.com/ie/podcast/the-future-of-security-operations/id1616529036",
};

const PodcastCover = () => {
  const [link, setLink] = useState(podcastLinks.spotify);
  useOnMount(() => {
    if (isAppleDevice) setLink(podcastLinks.apple);
  });
  return (
    <CoverImageLink href={link} {...externalLinkAttr}>
      <StaticImageFrame
        src={coverImg}
        backgroundColor={colorsV4.warmBlack900}
        alt="Tines podcast: The Future of Security Operations"
        aspectRatio="1/1"
        renderedWidth={325}
        renderedHeight={325}
        borderRadius={18}
        fadeInOnLoad
      />
    </CoverImageLink>
  );
};

export default PodcastCover;
