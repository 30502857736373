const MSSPPageIconStreetBlock = () => {
  return (
    <svg width="218" height="218" viewBox="0 0 218 218" fill="none">
      <path
        d="M93.8982 165.871C105.388 165.871 114.703 167.608 114.703 169.751C114.703 171.894 105.388 173.631 93.8982 173.631H77.4209L80.898 167.235L93.8982 165.871Z"
        fill="#32313B"
      />
      <path
        d="M173.841 60.3335L130.669 47.5494L109.861 60.3335L100.83 73.4181V147.447H164.81L173.841 134.362V60.3335Z"
        fill="#EB81AF"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
      />
      <path
        d="M164.81 147.447H100.83V73.4184L121.638 60.6341L164.81 73.4184V147.447Z"
        fill="#EB81AF"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M121.639 60.6341L130.669 47.5494"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M164.81 73.4181L173.841 60.3335"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M122.704 76.8554H108.539V91.0205H122.704V76.8554Z"
        fill="#32313B"
      />
      <path
        d="M143.052 76.8554H128.885V91.0205H143.052V76.8554Z"
        fill="#32313B"
      />
      <path
        d="M122.704 97.2024H108.539V111.368H122.704V97.2024Z"
        fill="#32313B"
      />
      <path
        d="M143.052 97.2024H128.885V111.368H143.052V97.2024Z"
        fill="#32313B"
      />
      <path
        d="M122.704 116.643H108.539V130.809H122.704V116.643Z"
        fill="#32313B"
      />
      <path
        d="M143.052 116.643H128.885V130.809H143.052V116.643Z"
        fill="#32313B"
      />
      <path
        d="M125.603 146.718H65.4912V158.434H125.603V146.718Z"
        fill="#32313B"
      />
      <path
        d="M212.523 158.537H152.412V172.502H204.329L212.523 158.537Z"
        fill="#32313B"
      />
      <path
        d="M75.2361 71.6884L67.8764 81.001V88.7113H51.9076L44.5479 98.024V157.285H106.336L113.695 147.973V125.275V88.7113V83.037L75.2361 71.6884Z"
        fill="#49B889"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
      />
      <path
        d="M67.8764 81.001V98.024H44.5479V157.285H106.336V134.588V98.024V92.3497L67.8764 81.001Z"
        fill="#49B889"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M106.335 92.35L113.695 83.0374"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M98.0071 98.1852H88.7197V116.702H98.0071V98.1852Z"
        fill="#32313B"
      />
      <path
        d="M98.0071 124.418H88.7197V142.935H98.0071V124.418Z"
        fill="#32313B"
      />
      <path
        d="M197.493 117.583L164.659 92.4751L131.825 117.583L122.794 130.226V171.622H188.462L197.493 158.98V117.583Z"
        fill="#F69267"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
      />
      <path
        d="M122.794 130.226V171.623H188.462V130.226L155.629 105.118L122.794 130.226Z"
        fill="#F69267"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
      />
      <path
        d="M155.628 105.118L164.659 92.4751"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
      />
      <path
        d="M188.461 130.226L197.493 117.583"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
      />
      <path d="M177 135.219H160.606V151.612H177V135.219Z" fill="#32313B" />
      <path
        d="M150.65 135.219H134.256V151.612H150.65V135.219Z"
        fill="#32313B"
      />
      <path
        d="M97.8787 167.637H40.8789V157.958H106.801L97.8787 167.637Z"
        fill="#32313B"
      />
      <path
        d="M78.906 141.251C78.906 134.514 73.446 129.053 66.7096 129.053H27.7256C24.4862 129.053 21.5421 130.316 19.3594 132.378C17.919 133.738 13.3769 137.669 12.367 139.052C10.8958 141.066 10.0278 143.548 10.0278 146.233V167.677H73.4059L78.906 160.98V141.251Z"
        fill="#FABE64"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
      />
      <path
        d="M61.2082 134.035H22.2249C15.4891 134.035 10.0278 139.497 10.0278 146.233V167.677H73.4059V146.233C73.4059 139.497 67.9453 134.035 61.2082 134.035Z"
        fill="#FABE64"
        stroke="#32313B"
        strokeWidth="1.5805"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M71.6347 163.155C71.5465 162.577 71.4865 161.989 71.4865 161.384C71.4865 154.955 76.6964 149.744 83.1265 149.744C88.2015 149.744 92.5065 152.998 94.098 157.53C94.2634 157.52 94.4258 157.504 94.5937 157.504C98.8772 157.504 102.352 160.977 102.352 165.264C102.352 169.55 98.8772 173.023 94.5937 173.023C92.6933 173.023 85.8478 173.023 83.3228 173.023C83.2587 173.023 83.1844 173.023 83.1265 173.023H63.2881C63.2884 168.062 66.9002 163.953 71.6347 163.155Z"
        fill="#49B889"
        stroke="#32313B"
        strokeWidth="1.2644"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MSSPPageIconStreetBlock;
