export const ShieldOnFireIcon = () => (
  <svg
    width="54"
    height="74"
    viewBox="0 0 54 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1575 73.1854C31.4235 73.1854 38.1245 72.4161 38.1245 71.4671C38.1245 70.518 31.4235 69.7487 23.1575 69.7487C14.8914 69.7487 8.19043 70.518 8.19043 71.4671C8.19043 72.4161 14.8914 73.1854 23.1575 73.1854Z"
      fill="#32274B"
    />
    <path
      d="M35.1494 4.54118C28.9237 3.06362 25.2417 1 25.2417 1C25.2417 1 21.5602 3.06362 15.3344 4.54118C9.65556 5.88844 4.2746 6.01132 4.2746 6.01132L1 8.78308V36.6458C1 52.5055 21.9674 63.5066 21.9674 63.5066C21.9674 63.5066 27.4232 60.6427 32.769 55.7328C38.7825 51.0308 46.2092 43.3131 46.2092 33.8742V6.01149C46.2092 6.01133 40.8284 5.88844 35.1494 4.54118Z"
      fill="#2A7857"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.8751 7.31263C25.6494 5.83523 21.9674 3.77161 21.9674 3.77161C21.9674 3.77161 18.2856 5.83523 12.0598 7.31263C6.38096 8.66004 1 8.78293 1 8.78293V36.6457C1 52.5053 21.9674 63.5064 21.9674 63.5064C21.9674 63.5064 42.9349 52.5053 42.9349 36.6457V8.78293C42.9349 8.78309 37.5541 8.6602 31.8751 7.31263Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.5411 12.5836C24.782 11.4021 21.9677 9.75153 21.9677 9.75153C21.9677 9.75153 19.1533 11.402 14.3941 12.5836C10.0532 13.6615 5.93994 13.7596 5.93994 13.7596V36.0438C5.93994 48.7281 21.9677 57.5267 21.9677 57.5267C21.9677 57.5267 37.9954 48.7281 37.9954 36.0438V13.7596C37.9954 13.7596 33.882 13.6615 29.5411 12.5836Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.9673 3.7716L25.2414 1"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.9346 8.78305L46.2089 6.01129"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M22.0164 8.78813C22.6621 8.78813 23.1856 8.2375 23.1856 7.55828C23.1856 6.87905 22.6621 6.32843 22.0164 6.32843C21.3706 6.32843 20.8472 6.87905 20.8472 7.55828C20.8472 8.2375 21.3706 8.78813 22.0164 8.78813Z"
      fill="#32274B"
    />
    <path
      d="M3.56081 13.6075C4.20655 13.6075 4.73003 13.057 4.73003 12.3778C4.73003 11.6987 4.20655 11.1481 3.56081 11.1481C2.91508 11.1481 2.3916 11.6987 2.3916 12.3778C2.3916 13.057 2.91508 13.6075 3.56081 13.6075Z"
      fill="#32274B"
    />
    <path
      d="M3.55788 31.7249C4.20362 31.7249 4.7271 31.1744 4.7271 30.4953C4.7271 29.8161 4.20362 29.2656 3.55788 29.2656C2.91215 29.2656 2.38867 29.8161 2.38867 30.4953C2.38867 31.1744 2.91215 31.7249 3.55788 31.7249Z"
      fill="#32274B"
    />
    <path
      d="M22.0139 61.8735C22.6597 61.8735 23.1832 61.323 23.1832 60.6438C23.1832 59.9647 22.6597 59.4141 22.0139 59.4141C21.3682 59.4141 20.8447 59.9647 20.8447 60.6438C20.8447 61.323 21.3682 61.8735 22.0139 61.8735Z"
      fill="#32274B"
    />
    <path
      d="M36.7 49.5893C37.3457 49.5893 37.8692 49.0387 37.8692 48.3596C37.8692 47.6804 37.3457 47.1299 36.7 47.1299C36.0542 47.1299 35.5308 47.6804 35.5308 48.3596C35.5308 49.0387 36.0542 49.5893 36.7 49.5893Z"
      fill="#32274B"
    />
    <path
      d="M40.3357 31.9434C40.9815 31.9434 41.5049 31.3928 41.5049 30.7137C41.5049 30.0346 40.9815 29.484 40.3357 29.484C39.69 29.484 39.1665 30.0346 39.1665 30.7137C39.1665 31.3928 39.69 31.9434 40.3357 31.9434Z"
      fill="#32274B"
    />
    <path
      d="M40.3015 13.8543C40.9473 13.8543 41.4707 13.3037 41.4707 12.6246C41.4707 11.9454 40.9473 11.3949 40.3015 11.3949C39.6558 11.3949 39.1323 11.9454 39.1323 12.6246C39.1323 13.3037 39.6558 13.8543 40.3015 13.8543Z"
      fill="#32274B"
    />
    <path
      d="M22.0222 8.02027C22.5268 8.02027 22.9359 7.61116 22.9359 7.10651C22.9359 6.60185 22.5268 6.19275 22.0222 6.19275C21.5175 6.19275 21.1084 6.60185 21.1084 7.10651C21.1084 7.61116 21.5175 8.02027 22.0222 8.02027Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M22.0222 61.1365C22.5268 61.1365 22.9359 60.7274 22.9359 60.2228C22.9359 59.7181 22.5268 59.309 22.0222 59.309C21.5175 59.309 21.1084 59.7181 21.1084 60.2228C21.1084 60.7274 21.5175 61.1365 22.0222 61.1365Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.5604 12.8584C4.06515 12.8584 4.47432 12.4492 4.47432 11.9444C4.47432 11.4397 4.06515 11.0305 3.5604 11.0305C3.05566 11.0305 2.64648 11.4397 2.64648 11.9444C2.64648 12.4492 3.05566 12.8584 3.5604 12.8584Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.3298 12.8582C40.8344 12.8582 41.2435 12.4491 41.2435 11.9445C41.2435 11.4398 40.8344 11.0307 40.3298 11.0307C39.8251 11.0307 39.416 11.4398 39.416 11.9445C39.416 12.4491 39.8251 12.8582 40.3298 12.8582Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.5604 30.9201C4.06515 30.9201 4.47432 30.511 4.47432 30.0062C4.47432 29.5015 4.06515 29.0923 3.5604 29.0923C3.05566 29.0923 2.64648 29.5015 2.64648 30.0062C2.64648 30.511 3.05566 30.9201 3.5604 30.9201Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.3298 30.92C40.8344 30.92 41.2435 30.5109 41.2435 30.0062C41.2435 29.5016 40.8344 29.0925 40.3298 29.0925C39.8251 29.0925 39.416 29.5016 39.416 30.0062C39.416 30.5109 39.8251 30.92 40.3298 30.92Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.4232 48.7997C7.92794 48.7997 8.33712 48.3905 8.33712 47.8858C8.33712 47.381 7.92794 46.9719 7.4232 46.9719C6.91845 46.9719 6.50928 47.381 6.50928 47.8858C6.50928 48.3905 6.91845 48.7997 7.4232 48.7997Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.6735 48.7995C37.1782 48.7995 37.5873 48.3904 37.5873 47.8857C37.5873 47.3811 37.1782 46.972 36.6735 46.972C36.1689 46.972 35.7598 47.3811 35.7598 47.8857C35.7598 48.3904 36.1689 48.7995 36.6735 48.7995Z"
      fill="#F5D0E0"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1.19385 39.5769C1.19385 39.5769 2.53772 37.2924 5.14498 37.803C6.88767 38.1442 9.09611 36.8353 9.33801 34.8195C9.57992 32.8036 11.4345 31.0296 13.773 31.0296C13.773 31.0296 11.3539 32.7768 11.9183 34.5776C12.1782 35.4063 13.2775 37.8409 11.4345 38.3674C10.8701 38.5287 10.5475 39.5769 11.3539 40.1414C12.1603 40.7058 13.6923 39.5769 13.9342 38.2061C13.9342 38.2061 14.8212 42.0766 12.5634 42.7217C10.3056 43.3668 8.666 42.6682 7.91351 45.1411C7.56065 46.3006 7.4834 47.8288 5.14498 48.4468C5.14498 48.4468 1.63734 43.649 1.19385 39.5769Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.9102 26.1724C19.9102 26.1724 17.0217 26.1118 16.8089 28.4547C16.5963 30.7975 13.5771 31.0154 13.5771 31.0154C13.5771 31.0154 18.0347 31.6607 19.3148 29.6254C19.8424 28.7866 20.3442 26.9857 19.9102 26.1724Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.7901 49.8176C5.7901 49.8176 8.85424 48.3662 8.93488 46.6728C9.01551 44.9795 9.57996 43.5281 10.7895 43.1249C10.7895 43.1249 8.77361 43.2861 8.12853 44.8989C7.48344 46.5116 5.87074 48.2855 5.14502 48.4468L5.7901 49.8176Z"
      fill="#32274B"
    />
    <path
      d="M12.5631 40.1414C12.5631 40.1414 10.359 39.1467 12.4015 37.803C12.4015 37.803 10.1437 38.69 10.7888 39.6576C11.4338 40.6252 12.5631 40.1414 12.5631 40.1414Z"
      fill="#32274B"
    />
    <path
      d="M34.4808 24.8318C34.4808 24.8318 35.7597 23.2083 39.8129 23.5329C42.9377 23.7833 44.7714 21.916 44.8457 19.7564C44.9201 17.5967 44.6236 14.7067 46.7272 13.9229C46.7272 13.9229 43.5317 14.0986 42.4973 16.6397C41.5703 18.9173 39.0538 16.8648 40.8035 15.6029C42.3036 14.5211 43.9307 12.2952 42.3718 9.06982C40.4743 5.14353 44.2374 3.85014 46.0193 3.08701C46.0193 3.08701 42.8203 2.71124 40.519 3.71273C38.5745 4.55892 36.996 6.22081 37.9399 8.4349C38.4205 9.56234 38.6169 10.7485 36.5332 10.6464C32.8186 10.4643 31.4549 14.513 32.36 16.8244C33.2652 19.1352 36.32 19.7703 35.145 20.7189C33.2739 22.2289 33.6147 23.5763 34.4808 24.8318Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.1732 16.8032C42.1732 16.8032 41.0993 16.7263 41.6642 16.0914C42.229 15.4563 43.5605 14.752 42.6632 11.4476C42.6632 11.4476 42.8054 14.2808 41.5873 14.9447C40.3691 15.6085 39.7443 16.2909 40.1465 16.9898C40.5489 17.6884 41.7699 17.8913 42.1732 16.8032Z"
      fill="#32274B"
    />
    <path
      d="M45.707 14.0906C45.707 14.0906 48.9324 15.0582 49.4969 13.123C50.0062 11.3766 51.029 10.0588 52.7223 10.0588C52.7223 10.0588 51.3218 8.83236 49.5775 9.41374C46.4327 10.462 47.481 14.2519 45.707 14.0906Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.8762 30.22C28.8762 30.22 28.9081 28.253 30.9851 27.8181C33.5043 27.2907 35.0274 26.1191 34.5002 24.8889C34.5002 24.8889 36.4922 29.5756 33.27 30.5714C31.1461 31.228 29.2278 32.2704 29.1691 33.4421C29.1105 34.6139 28.6419 31.1575 28.8762 30.22Z"
      fill="#EB81AF"
      stroke="#32274B"
      strokeWidth="0.806353"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.5361 25.1431C34.5361 25.1431 36.3391 23.5377 37.6735 24.4985C39.0078 25.4594 42.9045 26.9539 44.1854 22.0966C44.1854 22.0966 42.3707 23.6978 40.289 23.6444C38.2073 23.5912 35.1885 23.2256 34.5361 25.1431Z"
      fill="#32274B"
    />
    <path
      d="M29.7139 32.6472C29.7139 32.6472 30.5126 32.0298 31.6748 32.1024C32.8369 32.175 36.2866 31.812 35.3787 27.7449C35.3787 27.7449 34.7547 29.9848 33.2692 30.5715C31.7838 31.1582 30.1861 31.5215 29.7139 32.6472Z"
      fill="#32274B"
    />
    <path
      d="M19.6863 29.2827C19.6863 29.2827 19.6134 31.6885 17.4287 32.1849C14.4719 32.857 13.585 31.2715 13.585 31.2715C13.585 31.2715 16.5415 31.5673 17.3479 31.1102C18.1542 30.6535 19.6863 29.2827 19.6863 29.2827Z"
      fill="#32274B"
    />
  </svg>
);
