import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Image } from "react-datocms";
import { aspectRatioChild } from "../../styles/aspectRatio.styles";
import { colors } from "../../styles/colors.styles";
import { DatoCmsFileField, DatoCmsFluid } from "../../../graphql-types";
import { DeepNullablePartial } from "../../types/helper.types";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import {
  isValidFluid,
  isValidFluidImageField,
} from "../../utils/datocms.utils";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";

type Props = {
  className?: string;
  aspectRatio?: string | number;
  fallbackAspectRatio?: number;
  borderRadius?: string | number;
  image?: DeepNullablePartial<DatoCmsFileField> | null;
  intersectionMargin?: string;
  fluid?: DeepNullablePartial<DatoCmsFluid> | null;
  intrinsicWidth?: string | number;
  intrinsicHeight?: string | number;
  renderedWidth?: string | number;
  renderedHeight?: string | number;
  alt?: string;
  lazy?: boolean;
  cover?: boolean;
};

export const ImageFrameFigure = styled.figure<Props>`
  display: flex;
  align-items: stretch;
  justify-content: ${p => (p.aspectRatio ? "center" : "stretch")};
  margin: 0;
  padding: 0;
  border-radius: ${p => valueWithOptionalUnit(p.borderRadius)};
  overflow: hidden;
  width: ${p =>
    valueWithOptionalUnit(
      p.renderedWidth,
      p.image?.fluid?.width ?? p.image?.width ?? "100%"
    )};
  height: ${p => valueWithOptionalUnit(p.renderedHeight, "auto")};
  transform: translateZ(0);
  max-width: 100%;
  background-size: cover;
  &.lazy {
    opacity: 0;
    transition: 0.1s;
    &.loaded {
      opacity: 1;
    }
  }
  aspect-ratio: ${p =>
    p.aspectRatio ??
    p.fluid?.aspectRatio ??
    p.image?.fluid?.aspectRatio ??
    (p.image?.fluid?.width && p.image?.fluid.height
      ? p.image.fluid.width / p.image.fluid.height
      : p.image?.width && p.image?.height
      ? p.image.width / p.image.height
      : "") ??
    ""};
  @supports not (aspect-ratio: 16 / 9) {
    position: relative;
    padding-bottom: ${p =>
      `${
        (1 /
          (p.fallbackAspectRatio ??
            (p.image?.fluid?.width && p.image?.fluid.height
              ? p.image.fluid.width / p.image.fluid.height
              : null) ??
            16 / 9)) *
        100
      }%`};
    height: 0;
  }
  > * {
    @supports not (aspect-ratio: 16 / 9) {
      ${aspectRatioChild()};
    }
    width: 100%;
    height: 100%;
    max-width: unset !important;
  }
  img {
    display: block;
    object-fit: ${p => (p.aspectRatio && !p.cover ? "contain" : "cover")};
    background-color: ${colors.light200};
    ${darkModeLinariaCSS(`background-color: ${colors.dark600};`)}
  }
  div[style*="background-image"] {
    background-image: none !important;
  }
`;

const imageStyle = css`
  // this is necessary, otherwise older Safari on iOS won't be able to show the image coz it'll take the position: relative from the inline styles
  position: absolute !important;
  transform: translateZ(0);
`;

const ImageFrame = (props: Props) => {
  const fluid =
    props.fluid ??
    (isValidFluidImageField(props.image) ? props.image.fluid : null);
  const ref = useRef<HTMLDivElement>(null);
  const [ready, setReady] = useState(!props.lazy);
  const [loaded, setLoaded] = useState(!props.lazy);
  const handleImageLoad = () => {
    setLoaded(true);
  };
  useOnMount(() => {
    if (ready) return;
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setReady(true);
        observer.disconnect();
      }
    });
    ref.current && observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  });
  return (
    <ImageFrameFigure
      {...props}
      ref={ref}
      className={cx(props.className, props.lazy && "lazy", loaded && "loaded")}
    >
      {isValidFluid(fluid) ? (
        <Image
          data={{
            ...fluid,
            ...(props.alt
              ? {
                  alt: props.alt,
                }
              : {}),
          }}
          className={imageStyle}
          fadeInDuration={100}
          lazyLoad={props.lazy}
          intersectionMargin={props.intersectionMargin ?? "200px"}
        />
      ) : (
        ready &&
        props.image?.url && (
          <img
            src={props.image.url}
            width={props.image.width ?? undefined}
            height={props.image.height ?? undefined}
            onLoad={handleImageLoad}
            alt={props.alt}
            style={{
              aspectRatio: `${props.image?.width ?? 200}/${
                props.image?.height ?? 200
              }`,
            }}
          />
        )
      )}
    </ImageFrameFigure>
  );
};

export default ImageFrame;
