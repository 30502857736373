export const AppsTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4089 4.40136L14.8132 4.77766C15.3467 4.9206 15.6633 5.46894 15.5204 6.0024L13.7086 12.7639C13.5959 13.1846 13.2311 13.4704 12.8203 13.5023C13.237 13.1358 13.5 12.5986 13.5 12L13.5 5C13.5 4.79146 13.4681 4.59037 13.4089 4.40136ZM8.34635 3C8.83444 2.53671 9.54479 2.3307 10.2424 2.51764L15.0721 3.81173C16.139 4.09761 16.7722 5.19429 16.4863 6.26122L14.6745 13.0227C14.3887 14.0896 13.292 14.7228 12.2251 14.4369L10.5945 14L10.3251 14L5.77888 15.2182C4.71195 15.504 3.61527 14.8709 3.32939 13.8039L1.51766 7.04246C1.23177 5.97553 1.86494 4.87886 2.93187 4.59297L7.7615 3.29888L7.76149 3.29888L4.70449 4.118C5.03043 3.45573 5.712 3 6.50001 3L8.34635 3ZM9.85628 4C10.0167 4.20533 10.1393 4.44557 10.211 4.71309L12.0227 11.4746C12.1596 11.9854 12.0858 12.5031 11.8545 12.9353C12.2318 12.7922 12.5 12.4274 12.5 12L12.5 5C12.5 4.44771 12.0523 4 11.5 4L9.85628 4ZM3.19069 5.5589L8.02032 4.26481C8.55379 4.12186 9.10212 4.43845 9.24506 4.97191L11.0568 11.7334C11.1997 12.2669 10.8832 12.8152 10.3497 12.9581L5.52006 14.2522C4.9866 14.3952 4.43826 14.0786 4.29532 13.5451L2.48358 6.78365C2.34064 6.25018 2.65722 5.70184 3.19069 5.5589ZM5.09249 6.86101C5.19969 7.26111 4.96225 7.67237 4.56216 7.77957C4.16206 7.88678 3.7508 7.64934 3.6436 7.24924C3.53639 6.84914 3.77383 6.43789 4.17393 6.33068C4.57403 6.22348 4.98528 6.46091 5.09249 6.86101ZM8.11967 6.56756C8.3864 6.49609 8.5447 6.22192 8.47323 5.95519C8.40175 5.68845 8.12759 5.53016 7.86085 5.60163L6.17048 6.05457C5.90375 6.12604 5.74546 6.40021 5.81693 6.66694C5.8884 6.93367 6.16257 7.09196 6.4293 7.02049L8.11967 6.56756Z"
    />
    <rect
      opacity="0.5"
      x="4.09653"
      y="8.9397"
      width="5"
      height="4.5"
      rx="0.5"
      transform="rotate(-15 4.09653 8.9397)"
    />
  </svg>
);
