import React, { ReactNode } from "react";

type Props = {
  href: string;
  target?: string;
  children?: ReactNode;
};

const truncate = (url: string, maxLength = 30) => {
  const excess = url.length - maxLength + 2;
  if (excess < 0) return url;
  const { origin } = new URL(url);
  const path = url.replace(origin, "");
  return `${origin}/…${path.substring(excess)}`;
};

export default function MarkdownLink({ href, target, ...props }: Props) {
  // In cases where the title is omitted from a Markdown link
  // `children` will match `href`. When that's the case we
  // ignore `children` so that we can truncate the url.
  let children;
  if (String(props.children) !== href) {
    children = props.children;
  }

  return (
    <a href={href} target={target}>
      {children || truncate(href)}
    </a>
  );
}
