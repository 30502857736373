export const GolfFlagSetIcon = () => (
  <svg
    width="92"
    height="55"
    viewBox="0 0 92 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.2856 51.9648C79.9052 51.9648 91.7567 47.4713 91.7567 41.9282C91.7567 36.3852 79.9052 31.8917 65.2856 31.8917C50.666 31.8917 38.8145 36.3852 38.8145 41.9282C38.8145 47.4713 50.666 51.9648 65.2856 51.9648Z"
      fill="#32274B"
    />
    <path
      d="M87.2443 38.9445C87.2443 33.8414 79.5218 29.7045 66.8361 29.7045C59.4534 29.7045 53.0069 33.2832 46.1222 33.2832C39.2374 33.2832 32.7329 29.7045 25.3502 29.7045C12.6646 29.7045 5 33.8414 5 38.9445C5 39.5516 5 41.8485 5 42.4347C5 47.5377 12.1069 51.6744 24.7925 51.6744C32.1752 51.6744 40.2854 48.0957 46.1222 48.0957C51.9588 48.0957 60.2342 51.6744 67.6168 51.6744C80.3023 51.6744 87.2443 47.5377 87.2443 42.4347C87.2443 41.8528 87.2443 39.5561 87.2443 38.9445Z"
      fill="#2A7857"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M54.8211 47.5741H46.1225H37.4238L34.2036 48.4905C34.3523 53.6185 46.1225 53.1002 46.1225 53.1002C46.1225 53.1002 57.8928 53.6184 58.0415 48.4905L54.8211 47.5741Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.8212 46.0878C54.8212 44.8133 50.9265 43.7803 46.1226 43.7803C41.3184 43.7803 37.4238 44.8133 37.4238 46.0878C37.4238 46.3478 37.4238 47.314 37.4238 47.5742C37.4238 48.8487 41.3184 49.8816 46.1226 49.8816C50.9265 49.8816 54.8212 48.8487 54.8212 47.5742C54.8212 47.314 54.8212 46.3478 54.8212 46.0878Z"
      fill="#2A7857"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M66.8361 29.7045C59.4534 29.7045 53.0069 33.2832 46.1222 33.2832C39.2374 33.2832 32.7329 29.7045 25.3502 29.7045C12.6646 29.7045 5 33.8414 5 38.9445C5 44.0475 12.1069 48.1843 24.7925 48.1843C28.9519 48.1843 33.3419 47.0484 37.4264 46.0564C37.4259 46.0669 37.4236 46.0772 37.4236 46.0876C37.4236 47.3621 41.3182 48.3949 46.1223 48.3949C50.9263 48.3949 54.8209 47.3621 54.8209 46.0876C54.8209 46.0716 54.8176 46.056 54.8164 46.04C58.9662 47.0358 63.4344 48.1841 67.6169 48.1841C80.3025 48.1841 87.2445 44.0474 87.2445 38.9443C87.2445 33.8413 79.522 29.7045 66.8361 29.7045Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.1225 52.1267C39.5401 52.1267 34.2036 50.4984 34.2036 48.4905C34.2036 48.77 34.2036 49.8373 34.2036 50.117C34.2036 52.1252 39.5401 53.7532 46.1225 53.7532C52.7052 53.7532 58.0415 52.1252 58.0415 50.117C58.0415 49.8373 58.0415 48.7699 58.0415 48.4905C58.0415 50.4984 52.7053 52.1267 46.1225 52.1267Z"
      fill="#2A7857"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M26.1036 45.2727C34.5723 45.2727 41.4376 42.3768 41.4376 38.8046C41.4376 35.2324 34.5723 32.3365 26.1036 32.3365C17.6348 32.3365 10.7695 35.2324 10.7695 38.8046C10.7695 42.3768 17.6348 45.2727 26.1036 45.2727Z"
      fill="#32274B"
    />
    <path
      d="M69.9459 45.2727C78.4146 45.2727 85.2799 42.3768 85.2799 38.8046C85.2799 35.2324 78.4146 32.3365 69.9459 32.3365C61.4771 32.3365 54.6118 35.2324 54.6118 38.8046C54.6118 42.3768 61.4771 45.2727 69.9459 45.2727Z"
      fill="#32274B"
    />
    <path
      d="M38.5656 34.9009C38.5656 31.5289 31.9374 28.7953 23.761 28.7953C15.5849 28.7953 8.95654 31.5288 8.95654 34.9009C8.95654 35.5424 8.95654 37.9399 8.95654 38.5812C8.95654 41.9533 15.5848 44.6872 23.761 44.6872C31.9373 44.6872 38.5656 41.9535 38.5656 38.5812C38.5656 37.9399 38.5656 35.5424 38.5656 34.9009Z"
      fill="#2A7857"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M23.761 41.007C31.9373 41.007 38.5655 38.2733 38.5655 34.901C38.5655 31.5287 31.9373 28.795 23.761 28.795C15.5847 28.795 8.95654 31.5287 8.95654 34.901C8.95654 38.2733 15.5847 41.007 23.761 41.007Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M83.3 34.9009C83.3 31.5289 76.6719 28.7953 68.4955 28.7953C60.3192 28.7953 53.6909 31.5288 53.6909 34.9009C53.6909 35.5424 53.6909 37.9399 53.6909 38.5812C53.6909 41.9533 60.3192 44.6872 68.4955 44.6872C76.6719 44.6872 83.3 41.9535 83.3 38.5812C83.3 37.9399 83.3 35.5424 83.3 34.9009Z"
      fill="#2A7857"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M68.4954 41.007C76.6717 41.007 83.2999 38.2733 83.2999 34.901C83.2999 31.5287 76.6717 28.795 68.4954 28.795C60.3191 28.795 53.6909 31.5287 53.6909 34.901C53.6909 38.2733 60.3191 41.007 68.4954 41.007Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M26.3825 38.1352C31.741 38.1352 36.0849 36.4875 36.0849 34.4549C36.0849 32.4223 31.741 30.7746 26.3825 30.7746C21.0241 30.7746 16.6802 32.4223 16.6802 34.4549C16.6802 36.4875 21.0241 38.1352 26.3825 38.1352Z"
      fill="#32274B"
    />
    <path
      d="M71.2141 38.1352C76.5725 38.1352 80.9164 36.4875 80.9164 34.4549C80.9164 32.4223 76.5725 30.7746 71.2141 30.7746C65.8556 30.7746 61.5117 32.4223 61.5117 34.4549C61.5117 36.4875 65.8556 38.1352 71.2141 38.1352Z"
      fill="#32274B"
    />
    <path
      d="M34.6095 31.158C34.6095 29.2148 29.7525 27.6395 23.7608 27.6395C17.7691 27.6395 12.9121 29.2148 12.9121 31.158C12.9121 31.7425 12.9121 33.8143 12.9121 34.3988C12.9121 36.3419 17.7691 37.9173 23.7608 37.9173C29.7526 37.9173 34.6095 36.3419 34.6095 34.3988C34.6095 33.8143 34.6095 31.7425 34.6095 31.158Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M23.7608 34.6762C29.7524 34.6762 34.6095 33.101 34.6095 31.1579C34.6095 29.2147 29.7524 27.6395 23.7608 27.6395C17.7692 27.6395 12.9121 29.2147 12.9121 31.1579C12.9121 33.101 17.7692 34.6762 23.7608 34.6762Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M79.3443 31.158C79.3443 29.2148 74.4873 27.6395 68.4957 27.6395C62.504 27.6395 57.647 29.2148 57.647 31.158C57.647 31.7425 57.647 33.8143 57.647 34.3988C57.647 36.3419 62.5041 37.9173 68.4957 37.9173C74.4872 37.9173 79.3443 36.3419 79.3443 34.3988C79.3443 33.8143 79.3443 31.7425 79.3443 31.158Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M24.549 31.9274C26.5131 31.9274 28.1053 31.4185 28.1053 30.7908C28.1053 30.1631 26.5131 29.6542 24.549 29.6542C22.5849 29.6542 20.9927 30.1631 20.9927 30.7908C20.9927 31.4185 22.5849 31.9274 24.549 31.9274Z"
      fill="#32274B"
    />
    <path
      d="M68.4957 34.6762C74.4872 34.6762 79.3443 33.101 79.3443 31.1579C79.3443 29.2147 74.4872 27.6395 68.4957 27.6395C62.5041 27.6395 57.647 29.2147 57.647 31.1579C57.647 33.101 62.5041 34.6762 68.4957 34.6762Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M21.1836 30.5032C21.1836 31.0973 22.3378 31.5789 23.7615 31.5789C25.1852 31.5789 26.3394 31.0973 26.3394 30.5032V25.5172C26.3394 24.9234 25.1852 24.4418 23.7615 24.4418C22.3378 24.4418 21.1836 24.9234 21.1836 25.5172V30.5032Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M23.7615 26.5929C25.1853 26.5929 26.3394 26.1113 26.3394 25.5174C26.3394 24.9234 25.1853 24.4418 23.7615 24.4418C22.3378 24.4418 21.1836 24.9234 21.1836 25.5174C21.1836 26.1113 22.3378 26.5929 23.7615 26.5929Z"
      fill="#DAF2E8"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M23.8087 25.9513C24.8292 25.9513 25.6564 25.6558 25.6564 25.2914C25.6564 24.9269 24.8292 24.6315 23.8087 24.6315C22.7882 24.6315 21.9609 24.9269 21.9609 25.2914C21.9609 25.6558 22.7882 25.9513 23.8087 25.9513Z"
      fill="#32274B"
    />
    <path
      d="M24.9709 24.6739C24.9709 25.3423 24.4293 25.5904 23.7611 25.5904C23.0929 25.5904 22.5513 25.3423 22.5513 24.6739V6.34299C22.5513 5.6748 23.0929 5.13318 23.7611 5.13318C24.4293 5.13318 24.9709 5.6748 24.9709 6.34299V24.6739Z"
      fill="#DAF2E8"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M69.276 31.9274C71.2402 31.9274 72.8324 31.4185 72.8324 30.7908C72.8324 30.1631 71.2402 29.6542 69.276 29.6542C67.3119 29.6542 65.7197 30.1631 65.7197 30.7908C65.7197 31.4185 67.3119 31.9274 69.276 31.9274Z"
      fill="#32274B"
    />
    <path
      d="M65.9111 30.5032C65.9111 31.0973 67.0653 31.5789 68.4891 31.5789C69.9128 31.5789 71.067 31.0973 71.067 30.5032V25.5172C71.067 24.9234 69.9128 24.4418 68.4891 24.4418C67.0653 24.4418 65.9111 24.9234 65.9111 25.5172V30.5032Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M68.4891 26.5929C69.9128 26.5929 71.067 26.1113 71.067 25.5174C71.067 24.9234 69.9128 24.4418 68.4891 24.4418C67.0653 24.4418 65.9111 24.9234 65.9111 25.5174C65.9111 26.1113 67.0653 26.5929 68.4891 26.5929Z"
      fill="#DAF2E8"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M68.4889 25.9513C69.5093 25.9513 70.3366 25.6558 70.3366 25.2914C70.3366 24.9269 69.5093 24.6315 68.4889 24.6315C67.4684 24.6315 66.6411 24.9269 66.6411 25.2914C66.6411 25.6558 67.4684 25.9513 68.4889 25.9513Z"
      fill="#32274B"
    />
    <path
      d="M69.6989 24.6739C69.6989 25.3423 69.1574 25.5904 68.4891 25.5904C67.8209 25.5904 67.2793 25.3423 67.2793 24.6739V6.34299C67.2793 5.6748 67.8209 5.13318 68.4891 5.13318C69.1574 5.13318 69.6989 5.6748 69.6989 6.34299V24.6739Z"
      fill="#DAF2E8"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M30.9994 14.0747H22.874C22.4994 14.0747 22.1958 13.7711 22.1958 13.3964V6.07165C22.1958 5.697 22.4994 5.39343 22.874 5.39343H30.9994V14.0747Z"
      fill="#FF6565"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.7608 5.31159C24.9514 5.31159 25.9166 4.34641 25.9166 3.15579C25.9166 1.96518 24.9514 1 23.7608 1C22.5702 1 21.605 1.96518 21.605 3.15579C21.605 4.34641 22.5702 5.31159 23.7608 5.31159Z"
      fill="#DAF2E8"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M68.4893 5.31159C69.6799 5.31159 70.6451 4.34641 70.6451 3.15579C70.6451 1.96518 69.6799 1 68.4893 1C67.2987 1 66.3335 1.96518 66.3335 3.15579C66.3335 4.34641 67.2987 5.31159 68.4893 5.31159Z"
      fill="#DAF2E8"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
    />
    <path
      d="M30.9986 9.12315C30.032 9.12315 29.2485 8.33967 29.2485 7.37307C29.2485 6.40647 30.032 5.62299 30.9986 5.62299V9.12315Z"
      fill="#B35980"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.5677 6.69891H29.9268C29.5521 6.69891 29.2485 7.00249 29.2485 7.37713V14.7019C29.2485 15.0766 29.5521 15.3801 29.9268 15.3801H41.5677L37.9498 10.9945L41.5677 6.69891Z"
      fill="#FF6565"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M61.2466 14.0747H69.3719C69.7466 14.0747 70.0502 13.7711 70.0502 13.3964V6.07165C70.0502 5.697 69.7466 5.39343 69.3719 5.39343H61.2466V14.0747Z"
      fill="#FABE64"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M61.2466 9.12315C62.2132 9.12315 62.9967 8.33967 62.9967 7.37307C62.9967 6.40647 62.2132 5.62299 61.2466 5.62299V9.12315Z"
      fill="#B35980"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.6768 6.69891H62.3177C62.6924 6.69891 62.996 7.00249 62.996 7.37713V14.7019C62.996 15.0766 62.6924 15.3801 62.3177 15.3801H50.6768L54.2947 10.9945L50.6768 6.69891Z"
      fill="#FABE64"
      stroke="#32274B"
      strokeWidth="0.678221"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.9367 14.1556H22.5127V15.5621H24.9367V14.1556Z"
      fill="#32274B"
    />
    <path
      d="M69.7174 14.1556H67.2935V15.5621H69.7174V14.1556Z"
      fill="#32274B"
    />
  </svg>
);
