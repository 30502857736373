import { styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren } from "react";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { UnknownObject, UseStateReturnType } from "../../types/helper.types";

type Props<T extends {}> = PropsWithChildren<{
  formState: UseStateReturnType<T>;
  name: keyof T & string;
  disabled?: boolean;
}>;

const ToggleBox = styled.div`
  flex: 0 0 auto;
  position: relative;
  padding: 0;
  background-color: ${withOpacity(colorsV4.black, 0.2)};
  height: 1.6em;
  width: 2.8em;
  color: var(--ac, ${colorsV4.purple});
  border-radius: 0.8em;
  border: 2px solid ${withOpacity(colorsV4.grey, 0.2)};
  transition: background-color 0.1s;
  vertical-align: middle;
  &:hover {
    border: 2px solid ${withOpacity(colorsV4.grey, 0.4)};
  }
  &:before {
    content: "";
    display: block;
    border-radius: 50%;
    width: calc(1.6em - 4px);
    height: calc(1.6em - 4px);
    background-color: ${colorsV4.canvas300};
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.1s;
  }
`;

const ToggleContainer = styled.button`
  appearance: none;
  display: flex;
  background-color: transparent;
  padding: 0;
  border: 0;
  text-align: left;
  line-height: 1.25;
  font-size: 1.4rem;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
  &.on {
    ${ToggleBox} {
      background-color: currentColor;
      &:before {
        transform: translateX(1.2em);
      }
    }
  }
  &.disabled,
  &[disabled] {
    ${ToggleBox} {
      opacity: 0.6;
      pointer-events: none;
    }
  }
`;

const Toggle = function <T extends {}>(props: Props<T>) {
  const { disabled, name, formState, children } = props;
  if (
    formState?.[0] &&
    typeof formState[0][props.name as keyof typeof formState[0]] === "undefined"
  ) {
    Reflect.set(formState[0] as UnknownObject, props.name as string, "");
  }
  const toggle = () => {
    formState[1]({
      ...formState[0],
      [name]: !formState[0][name],
    });
  };
  return (
    <ToggleContainer
      className={cx(disabled && "disabled", formState[0][name] && "on")}
      onClick={disabled ? undefined : toggle}
      disabled={disabled}
      type="button"
    >
      <ToggleBox />
      {children && <div>{children}</div>}
    </ToggleContainer>
  );
};

export default Toggle;
