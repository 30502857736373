import { styled } from "@linaria/react";
import {
  ColorThemeNameV4,
  colorV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import RigidDotGrid from "../decorations/RigidDotGrid";
import { StoryDescriptor } from "../../types/helper.types";
import GradientBorder from "../decorations/GradientBorder";
import StoryEntry, { StoryEntryWrapper } from "../library/StoryEntry";
import {
  fromDesktop,
  fromTablet,
  fromTabletLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { ReactNode } from "react";
import { cx } from "linaria";
import AllCaps from "../typography/AllCaps";
import { serif } from "../../styles/fonts.styles";
import { color } from "../../styles/colors.styles";

type Props = {
  name: string;
  description: ReactNode;
  color: ColorThemeNameV4;
  icon?: string | null;
  winner?: StoryDescriptor;
  winnerDescription?: ReactNode;
  runnerUp?: StoryDescriptor;
  runnerUpDescription?: ReactNode;
};

const Container = styled.div<{ color?: ColorThemeNameV4 }>`
  position: relative;
  border-radius: 12px;
  background-color: ${p => withOpacity(color(p.color, 700), 0.15)};
  color: ${p => color(p.color, 250)};
  text-align: left;
  padding: 18px;
  ${fromTabletLg} {
    padding: 24px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-areas: "header" "." ".";
  ${fromTabletLg} {
    grid-template-columns: auto 18.75em;
    grid-template-rows: auto auto;
    grid-template-areas: "header header" ". .";
  }
  .StoryEntry,
  ${StoryEntryWrapper} {
    border-radius: 18px;
  }
  &.singleItem {
    ${fromTabletLg} {
      padding: 18px;
    }
    .StoryEntry {
      ${fromTabletLg} {
        grid-column-end: span 2;
        h3 {
          font-size: 2.6rem;
        }
      }
    }
  }
`;

const Header = styled.header`
  grid-area: header;
  .twoItems & {
    padding: 24px;
  }
  ${onlyPhones} {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "icon" "text";
    text-align: center;
  }
  ${fromTablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h3 {
    font-family: ${serif};
    font-size: 2.8rem;
    font-weight: 400;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    ${fromDesktop} {
      margin-top: 0.25em;
      margin-bottom: 0.15em;
      font-size: 3.2rem;
    }
  }
  p {
    font-size: 1.4rem;
    ${fromTabletLg} {
      font-size: 1.6rem;
    }
    max-width: 33em;
    margin-top: 0.5em;
  }
  img {
    ${onlyPhones} {
      grid-area: icon;
      margin-left: auto;
      margin-right: auto;
      transform: scale(0.8) translateY(-1em);
    }
  }
`;

const HeaderText = styled.div`
  ${onlyPhones} {
    grid-area: text;
  }
`;

const YDWWTCategoryWinnerSet = (props: Props) => {
  return (
    <Container color={props.color}>
      <GradientBorder
        colors={[
          withOpacity(colorV4(props.color, 300), 0.4),
          withOpacity(colorV4(props.color, 300), 0.1),
        ]}
        angle={33}
        offset={-2}
        borderWidth={2}
        borderRadius={12}
      />
      <RigidDotGrid
        color={withOpacity(colorV4(props.color), 0.15)}
        cellSize={24}
        dotSize={1}
      />
      <Grid
        className={cx(
          !props.runnerUp || !props.winner
            ? "singleItem"
            : props.runnerUp && props.winner
            ? "twoItems"
            : ""
        )}
      >
        <Header>
          <HeaderText>
            <AllCaps as="div">Category</AllCaps>
            <h3>{props.name}</h3>
            <p>{props.description}</p>
          </HeaderText>
          {props.icon && <img src={props.icon} />}
        </Header>
        {props.winner && (
          <StoryEntry
            story={props.winner}
            preHeading="Winner"
            noDotGrid
            size={props.runnerUp ? "medium" : "large"}
            withArrow
            description={props.winnerDescription}
            hideTools
            doNotClampDescription
          />
        )}
        {props.runnerUp && (
          <StoryEntry
            story={props.runnerUp}
            preHeading="Runner-up"
            noDotGrid
            withArrow
            description={props.runnerUpDescription}
            hideTools
            doNotClampDescription
          />
        )}
      </Grid>
    </Container>
  );
};

export default YDWWTCategoryWinnerSet;
