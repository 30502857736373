import axios from "axios";
import {
  readCookieConsentState,
  whenUserActOnCookieBanner,
} from "../components/gdpr/gdpr";
import { when } from "./promises.utils";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "./localStorage.utils";
import { readMarketingAttribution } from "./marketingAttribution.utils";
import { DemoInfoFormObject } from "../components/demo/makeDemoInfoForm";
import { getCookie } from "./cookies.utils";

const webhooks = {
  submitted:
    "https://hq.tines.io/webhook/c19925ea2fb5dffdd8f1a78f45f838b2/b6096bc265b80d922ef9039d02a1f798",
  success:
    "https://hq.tines.io/webhook/5ca96967e767b43c146cf118e3a9ae99/d41234c0e1ce4842e65e7b3eddfc466c",
};

const getPayload = () => {
  return {
    ...(getLocalStorageItem("lastDemoRequestFormBody") as DemoInfoFormObject),
    fbc: getCookie("_fbc"),
    marketingAttribution: readMarketingAttribution(),
  };
};

export const reportDemoRequestSubmission = async () => {
  await axios.post(webhooks.submitted, {
    body: getPayload(),
  });
};

const reportDemoRequestSuccess = () => {
  try {
    axios.post(webhooks.success, {
      body: getPayload(),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  removeLocalStorageItem("lastDemoRequestFormBody");
  whenUserActOnCookieBanner(() => {
    const consent = readCookieConsentState();
    if (!consent?.analytics) return;
    when(
      () => !!window.dataLayer,
      () => {
        window.dataLayer.push({
          event: "demo-request-success",
        });
      }
    );
  });
};

export const listenForDemoRequestSuccessEvents = () => {
  const handler = (
    e: MessageEvent<{
      action?: string;
    }>
  ) => {
    if (e.data["action"] === "booked") {
      reportDemoRequestSuccess();
    }
  };
  window.addEventListener("message", handler);
  return () => {
    window.removeEventListener("message", handler);
  };
};
