import { styled } from "@linaria/react";
import chroma from "chroma-js";
import gsap from "gsap";
import { CSSProperties, cx } from "linaria";
import { useRef, useState } from "react";
import {
  ACTION_TYPES,
  ActionTypeName,
} from "../../../constants/actionTypes.constants";
import {
  fromDesktop,
  fromPhoneLg,
  uptoPhoneLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { makeId } from "../../../utils/id.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { runAfter, when } from "../../../utils/promises.utils";
import DemoActionButton, { DemoActionButtonWrap } from "./DemoActionButton";
import { useDemoStoryContext } from "./DemoStoryContext";

type Props = {
  action: ActionTypeName;
  showDragHintAnimation?: boolean;
};

const GradientBorderWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const GradientBorder = styled.svg`
  position: absolute;
  top: -7px;
  left: -7px;
  pointer-events: none;
  rect {
    transition: 0.1s;
  }
`;

const HintMoveArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const DraggableButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  touch-action: none;
  height: 5rem;
  ${fromPhoneLg} {
    height: 8rem;
  }
  ${fromDesktop} {
    font-size: 1.2rem;
    height: 10rem;
  }
  background-color: ${colorsV4.white};
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.1s;
  cursor: grab;
  ${DemoActionButtonWrap} {
    cursor: grab;
  }
  svg {
    color: var(--color);
  }
  &:before {
    background-color: var(--colorBrighter);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    display: block;
    opacity: 0.5;
    border-radius: inherit;
    transition: 0.1s;
  }
  &.grabbed {
    border-radius: 1em;
    transform: rotate(-5deg);
    background-color: transparent;
    cursor: grabbing;
    &:before {
      background-color: var(--colorLight);
      width: 8em;
      height: 8em;
      opacity: 1;
      box-shadow: 0 1em 2em rgba(0, 0, 0, 0.15);
    }
  }
  &.bouncingBack {
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    pointer-events: none;
  }
`;

const Label = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 0.5em;
    ${uptoPhoneLg} {
      display: none;
    }
  }
  svg {
    display: block;
  }
`;

const DemoActionButtonDraggableWrap = styled.div`
  position: relative;
  z-index: 1;
  ${DraggableButtonWrap} {
    &:before {
      opacity: 0;
    }
  }
  ${GradientBorderWrap} {
    opacity: 0;
  }
  ${HintMoveArea} {
    opacity: 0;
    transition: opacity 0.1s 0.1s;
  }
  &.ready {
    ${HintMoveArea} {
      @keyframes HintMoveAreaEnter {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      animation: HintMoveAreaEnter 0.5s 1s forwards;
    }
    ${DraggableButtonWrap} {
      &:before {
        @keyframes backdropGlow {
          from {
            opacity: 0.35;
          }
          to {
            opacity: 0.75;
          }
        }
        animation: backdropGlow 1s 1s infinite alternate-reverse forwards;
      }
      &:hover {
        &:before {
          opacity: 0.6;
        }
      }
      &.grabbed {
        &:before {
          opacity: 1;
          animation: none;
        }
      }
    }
    ${GradientBorderWrap} {
      @keyframes GradientBorderWrapEnter {
        from {
          opacity: 0;
          transform: scale(1.5);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }
      animation: GradientBorderWrapEnter 0.5s 1s forwards;
    }
  }
  &.grabbed {
    cursor: grabbing;
  }
`;

const DemoActionButtonDraggable = (props: Props) => {
  const [id] = useState(makeId());

  const context = useDemoStoryContext();

  const def = ACTION_TYPES[props.action];

  const sourceRef = useRef<HTMLDivElement>(null);
  const draggableRef = useRef<HTMLDivElement>(null);
  const [grabbed, setGrabbed] = useState(false);

  const [tl] = useState(
    gsap.timeline({
      paused: true,
      onComplete: () => {
        setTimeout(() => {
          if (hasEverGrabbedRef.current) return;
          tl.seek(0);
          tl.play();
        }, 3000);
      },
    })
  );

  const [x, setX] = useState(0);
  const xRef = useRef(x);
  xRef.current = x;
  const [y, setY] = useState(0);
  const yRef = useRef(y);
  yRef.current = y;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [hasEverGrabbed, setHasEverGrabbed] = useState(false);
  const hasEverGrabbedRef = useRef(hasEverGrabbed);
  hasEverGrabbedRef.current = hasEverGrabbed;

  useOnMount(() => {
    const handler = (entries: ResizeObserverEntry[]) => {
      const rect = entries[0].contentRect;
      setWidth(rect.width);
      setHeight(rect.height);
    };
    const observer = new ResizeObserver(handler);
    observer.observe(sourceRef.current!);
    const removeListeners = () => {
      window.removeEventListener("pointermove", handlePointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
    };
    const prevEventXY = { x: 0, y: 0 };
    const handlePointerMove = (e: PointerEvent) => {
      setX(xRef.current + (e.clientX - prevEventXY.x));
      setY(yRef.current + (e.clientY - prevEventXY.y));
      prevEventXY.x = e.clientX;
      prevEventXY.y = e.clientY;
    };
    const handlePointerUp = () => {
      draggableRef.current?.classList.remove("grabbed");
      draggableRef.current?.classList.add("bouncingBack");
      context.setIsBouncingBack(true);
      setGrabbed(false);
      setX(0);
      setY(0);
      removeListeners();
      prevEventXY.x = 0;
      prevEventXY.y = 0;
      runAfter(() => {
        draggableRef.current?.classList.remove("bouncingBack");
        context.setIsBouncingBack(false);
        context.setIsDragging(false);
      }, 300);
    };
    const handlePointerDown = (e: PointerEvent) => {
      draggableRef.current?.classList.add("grabbed");
      setGrabbed(true);
      setHasEverGrabbed(true);
      context.setIsDragging(true);
      prevEventXY.x = e.clientX;
      prevEventXY.y = e.clientY;
      tl.pause();
      tl.seek(0);
      tl.kill();
      window.addEventListener("pointermove", handlePointerMove);
      window.addEventListener("pointerup", handlePointerUp);
    };
    draggableRef.current?.addEventListener("pointerdown", handlePointerDown);
    return () => {
      observer.disconnect();
      draggableRef.current?.removeEventListener(
        "pointerdown",
        handlePointerDown
      );
      removeListeners();
    };
  });

  const [hintGrabbing, setHintGrabbing] = useState(false);
  const hintMoveAreaRef = useRef<HTMLDivElement>(null);
  const fakeHandRef = useRef<HTMLDivElement>(null);

  useOnMount(function hintAnimator() {
    if (props.showDragHintAnimation) {
      tl.fromTo(
        fakeHandRef.current,
        {
          opacity: 0,
        },
        { opacity: 1, duration: 0.2 }
      );
      tl.add(() => {
        setHintGrabbing(true);
      }, 0.5);
      tl.fromTo(
        hintMoveAreaRef.current,
        {
          x: 0,
          y: 0,
        },
        {
          x: window.innerWidth > 768 ? 20 : 15,
          y: window.innerWidth > 768 ? 20 : 15,
          duration: 0.4,
        },
        0.75
      );
      tl.to(
        hintMoveAreaRef.current,
        {
          x: 0,
          y: 0,
          duration: 0.5,
        },
        1.5
      );
      tl.add(() => {
        setHintGrabbing(false);
      }, 1.75);
      tl.fromTo(
        fakeHandRef.current,
        {
          opacity: 1,
        },
        { opacity: 0, duration: 0.2 }
      );
      gsap.set(fakeHandRef.current, {
        opacity: 0,
      });
      when(
        () => context.ready,
        () => {
          runAfter(() => {
            if (hasEverGrabbedRef.current) return;
            tl.play();
          }, 1000);
        }
      );
    }
    return () => {
      tl.kill();
    };
  });

  return (
    <DemoActionButtonDraggableWrap
      className={cx(context.ready && "ready", grabbed && "grabbed")}
    >
      <DemoActionButton action="httpRequest" innerRef={sourceRef} />
      <HintMoveArea ref={hintMoveAreaRef}>
        <DraggableButtonWrap
          ref={draggableRef}
          style={
            {
              "--color": def.color,
              "--colorBrighter": chroma(def.color).brighten(1.5).hex(),
              "--colorLight": chroma(def.color).brighten(2.5).hex(),
              top: y,
              left: x,
              width,
              height,
            } as CSSProperties
          }
        >
          <Label>
            <def.Icon />
            <span>{def.name}</span>
          </Label>
          {width > 0 && (
            <GradientBorderWrap>
              <GradientBorder
                width={width + 14}
                height={height + 14}
                viewBox={`0 0 ${width + 14} ${height + 14}`}
              >
                <defs>
                  <linearGradient
                    id={`buttonGradient-${id}`}
                    gradientTransform="rotate(120)"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop
                      offset={0}
                      stopColor={grabbed ? `var(--color)` : colorsV4.orange}
                    />
                    <stop
                      offset={1}
                      stopColor={
                        grabbed
                          ? `var(--colorBrighter)`
                          : chroma(colorsV4.yellow).brighten(1).hex()
                      }
                    />
                    <animateTransform
                      attributeName="gradientTransform"
                      type="rotate"
                      from="0 .5 .5"
                      to="360 .5 .5"
                      className="ignore"
                      dur="3s"
                      repeatCount="indefinite"
                    />
                  </linearGradient>
                </defs>
                <rect
                  x={grabbed ? (width + 14 - 100) / 2 : 2}
                  y={grabbed ? (height + 14 - 100) / 2 : 2}
                  width={grabbed ? 100 : width + 10}
                  height={grabbed ? 100 : height + 10}
                  rx={grabbed ? 12 : 8}
                  stroke={`url(#buttonGradient-${id})`}
                  strokeWidth={grabbed ? 0 : 4}
                  fill="none"
                />
              </GradientBorder>
            </GradientBorderWrap>
          )}
        </DraggableButtonWrap>
        {props.showDragHintAnimation && (
          <FakeHandWrap ref={fakeHandRef}>
            {hintGrabbing ? <FakeHandGrabbing /> : <FakeHand />}
          </FakeHandWrap>
        )}
      </HintMoveArea>
    </DemoActionButtonDraggableWrap>
  );
};

const FakeHandWrap = styled.div`
  position: absolute;
  opacity: 0;
  top: calc(75% - 19px);
  left: calc(75% - 19px);
  pointer-events: none;
`;

const FakeHand = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none">
    <path
      d="M18.0585 29.5241C20.5761 29.5241 22.617 27.3986 22.617 24.7766C22.617 22.1547 20.5761 20.0292 18.0585 20.0292C15.5409 20.0292 13.5 22.1547 13.5 24.7766C13.5 27.3986 15.5409 29.5241 18.0585 29.5241Z"
      fill="#FDD2AA"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M18.0321 27.8883C20.2504 27.8883 22.0486 26.0105 22.0486 23.6942C22.0486 21.3778 20.2504 19.5 18.0321 19.5C15.8139 19.5 14.0156 21.3778 14.0156 23.6942C14.0156 26.0105 15.8139 27.8883 18.0321 27.8883Z"
      fill="#DE8A5D"
    />
    <path
      d="M7.67515 16.9219C8.23176 17.8012 11.235 21.478 11.7942 22.4761C12.3536 23.4743 14.0981 26.7646 17.9634 26.6724C21.8287 26.5803 23.7698 23.1425 24.3077 20.7497C24.8457 18.3567 25.1287 12.757 24.7193 10.5653C24.3101 8.3736 23.4534 7.38257 22.5614 7.40379C21.6694 7.42501 21.2814 8.6243 21.2814 8.6243C21.2814 8.6243 20.5527 6.08635 19.272 6.0067C18.21 5.94069 17.7588 6.35144 17.7038 7.74181C17.7038 7.74181 16.8685 5.99774 15.5747 6.29332C14.2809 6.5888 14.283 8.52897 14.283 8.52897C14.283 8.52897 13.6854 7.5069 12.5039 7.8879C11.3224 8.26889 11.2071 9.90324 11.3791 10.6488C11.5512 11.3943 12.7287 14.5411 11.6643 16.132C11.6643 16.132 9.98096 14.8934 9.48939 14.6185C8.99781 14.3436 7.81867 13.9274 7.2402 14.606C6.51083 15.4613 7.67515 16.9219 7.67515 16.9219Z"
      fill="#FDD2AA"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.1604 15.1364C15.1604 15.1364 15.2544 13.4656 14.9537 11.4523C14.6705 9.55513 14.2534 8.28589 14.2534 8.28589"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6533 7.52039C17.6533 7.52039 18.3321 9.79495 18.4534 11.1307C18.5744 12.4666 18.6297 14.7858 18.6297 14.7858"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6271 15.6067C21.6271 15.6067 21.78 13.2826 21.6206 11.5906C21.4612 9.89873 21.4657 9.17864 21.082 8.05719"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FakeHandGrabbing = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none">
    <path
      d="M18.0585 29.5241C20.5761 29.5241 22.617 27.3986 22.617 24.7766C22.617 22.1547 20.5761 20.0292 18.0585 20.0292C15.5409 20.0292 13.5 22.1547 13.5 24.7766C13.5 27.3986 15.5409 29.5241 18.0585 29.5241Z"
      fill="#FDD2AA"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M18.0321 27.8883C20.2504 27.8883 22.0486 26.0105 22.0486 23.6942C22.0486 21.3778 20.2504 19.5 18.0321 19.5C15.8139 19.5 14.0156 21.3778 14.0156 23.6942C14.0156 26.0105 15.8139 27.8883 18.0321 27.8883Z"
      fill="#DE8A5D"
    />
    <path
      d="M9.63873 19.1063C10.168 20.6314 11.191 22.4673 11.7502 23.2933C12.3096 24.1193 14.1841 26.6538 18.0494 26.5775C21.9147 26.5012 23.8557 23.6562 24.3937 21.6761C24.9317 19.6958 25.2147 15.0618 24.8053 13.248C24.3961 11.4343 23.5394 10.6141 22.6474 10.6317C21.7554 10.6492 21.3674 11.6417 21.3674 11.6417C21.3674 11.6417 20.6387 9.54143 19.358 9.47551C18.296 9.42089 17.8448 9.7608 17.7898 10.9114C17.7898 10.9114 16.9545 9.4681 15.6607 9.71271C14.3669 9.95723 14.369 11.5628 14.369 11.5628C14.369 11.5628 13.7713 10.717 12.5899 11.0323C11.4084 11.3476 11.2931 12.7001 11.4651 13.3171C11.6372 13.934 12.8146 16.5382 11.7502 17.8548C11.7502 17.8548 12.1601 17.2482 11.9487 16.0908C11.7372 14.9334 10.58 14.5995 9.91204 15.4565C9.24407 16.3134 9.10948 17.5812 9.63873 19.1063Z"
      fill="#FDD2AA"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.2454 17.0314C15.2454 17.0314 15.3393 15.6488 15.0386 13.9826C14.7554 12.4126 14.3384 11.3622 14.3384 11.3622"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7407 10.7283C17.7407 10.7283 18.4195 12.6106 18.5408 13.716C18.6618 14.8215 18.7172 16.7407 18.7172 16.7407"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7116 17.4204C21.7116 17.4204 21.8645 15.4971 21.7051 14.0968C21.5457 12.6968 21.5502 12.1008 21.1665 11.1728"
      stroke="#DE8A5D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DemoActionButtonDraggable;
