import { XY } from "../../../utils/geometry.utils";
import { GRID_SIZE } from "./storyboard.utils";

type GradientDirection = "ns" | "nwse" | "nesw" | "sn" | "ew" | "we";

export const getGradientDirection = (
  pointA: XY,
  pointB: XY
): GradientDirection => {
  const { x: aX, y: aY } = pointA;
  const { x: bX, y: bY } = pointB;
  if (aX < bX) return aY < bY ? "nwse" : bX - aX > GRID_SIZE * 4 ? "we" : "sn";
  if (aX === bX) return aY < bY ? "ns" : "sn";
  /** aX > bX */ return aY < bY
    ? "nesw"
    : aX - bX > GRID_SIZE * 4
    ? "ew"
    : "sn";
};
