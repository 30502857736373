import axios from "axios";

const locateResult = {
  country: "",
  countryCode: "",
  city: "",
  regionName: "",
};

export const locateUserIP = () =>
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  new Promise<typeof locateResult>(async resolve => {
    if (locateResult.countryCode) {
      resolve(locateResult);
      return;
    }
    if (process.env.CI) {
      resolve({
        country: "United States",
        countryCode: "US",
        city: "Boston",
        regionName: "Massachusetts",
      });
      return;
    }
    try {
      const { data } = await axios.get<{
        countryCode: string;
        city: string;
        regionName: string;
      }>(`/api/utils/locate-ip`);
      Object.assign(locateResult, data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    resolve(locateResult);
  });

export const EUCountryCodes = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
];

export const EEACountryCodes = [
  ...EUCountryCodes,
  "IS", // Iceland
  "NO", // Norway
  "LI", // Liechtenstein
];

export const isEUCountryCode = (code: string) => {
  return EUCountryCodes.includes(code.toUpperCase());
};
export const isEEACountryCode = (code: string) => {
  return EEACountryCodes.includes(code.toUpperCase());
};

export const isRegionRequiredCountry = (country: string) =>
  ["United States", "Canada"].includes(country);

export const isPostCodeRequiredCountry = (country: string) =>
  ["United States"].includes(country);

export const getRegionFieldLabel = (country?: string | null) => {
  switch (country) {
    case "Ireland":
    case "United Kingdom":
      return "County";
    case "Canada":
      return "Province";
    case "United States":
      return "State";
    default:
      return "Region";
  }
};

export const getPostCodeFieldLabel = (country: string) => {
  switch (country) {
    case "Ireland":
      return "Eircode";
    case "United States":
      return "ZIP code";
    default:
      return "Postcode";
  }
};
