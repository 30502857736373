/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */

const { render } = require("datocms-structured-text-to-plain-text");
const { makeSlug } = require("./string");

const renderChildren = c => {
  if (c instanceof Array) return `${c.map(c => renderChildren(c)).join(" ")}`;
  return `${c}`;
};

const getPlainTextContentFromStructuredText = structuredText => {
  return render(structuredText, {
    renderBlock: () => ``,
    renderInlineRecord: ({ record, adapter, children }) =>
      renderChildren(children ?? ""),
    renderLinkToRecord: ({ record, adapter, children }) =>
      renderChildren(children ?? ""),
    renderNode: (tagName, attrs, children) => renderChildren(children ?? ""),
  });
};
exports.getPlainTextContentFromStructuredText =
  getPlainTextContentFromStructuredText;

const flattenStructuredText = blocks => {
  return blocks.reduce(
    (array, block) => [
      ...array,
      block,
      ...flattenStructuredText(block.children ?? []),
    ],
    []
  );
};
exports.flattenStructuredText = flattenStructuredText;

const getHeadingsFromStructuredText = structuredText => {
  return flattenStructuredText(structuredText.value.document.children)
    .filter(block => block.type === "heading")
    .map(block => {
      try {
        const text = render(block);
        return {
          level: block.level,
          text,
          slug: makeSlug(`${text || ""}`),
        };
      } catch (e) {
        console.log(JSON.stringify(block));
        console.error(e);
        return null;
      }
    })
    .filter(i => i);
};
exports.getHeadingsFromStructuredText = getHeadingsFromStructuredText;
