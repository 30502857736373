import { styled } from "@linaria/react";
import { cx } from "linaria";
import { PropsWithChildren } from "react";
import { StructuredText } from "react-datocms";
import {
  fromDesktop,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  ColorThemeDisplayNameV4,
  ColorThemeNameV4,
  colorsV4,
  getStandardColorThemeNameV4,
} from "../../styles/colorsV4.styles";
import { ValidChecklistBlock } from "../../utils/datocms.utils";

type Props = {
  className?: string;
  record?: ValidChecklistBlock;
  centerOnMobile?: boolean;
  spacious?: boolean;
};

const ChecklistBlockDiv = styled.div<{
  customIcon?: string | null;
  color: ColorThemeNameV4;
}>`
  position: relative;
  ul {
    padding: 0;
    list-style: none;
    li {
      break-inside: avoid;
      margin-bottom: 0.75em;
      text-align: left;
      ${fromDesktop} {
        margin-bottom: 0.5em;
      }
      padding-left: 28px;
      position: relative;
      &:before {
        content: ${p => {
          if (p.customIcon) return `"${p.customIcon}"`;
          const hexValue = (colorsV4[p.color] ?? colorsV4.purple).replace(
            "#",
            ""
          );
          return `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9.61626 13.7071L15.7071 7.61616L14.2929 6.20196L8.90914 11.5858L6.11629 8.7929L4.70207 10.2071L8.20204 13.7071L8.90915 14.4142L9.61626 13.7071Z' fill='%23${hexValue}'/%3E%3C/svg%3E%0A")`;
        }};
        position: absolute;
        left: 0;
        top: ${p => (p.customIcon ? "0" : "0.2em")};
      }
    }
  }
  &.centerOnMobile {
    ${uptoTablet} {
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 325px;
      }
      li {
        display: inline-flex;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &.spacious {
    ul {
      li {
        margin-bottom: 1em;
        ${fromDesktop} {
          padding-left: 36px;
          margin-bottom: 1.25em;
        }
      }
    }
  }
`;

const ChecklistBlock = (props: PropsWithChildren<Props>) => {
  return (
    <ChecklistBlockDiv
      className={cx(
        props.className,
        props.centerOnMobile && "centerOnMobile",
        props.spacious && "spacious"
      )}
      customIcon={props.record?.customIcon}
      color={getStandardColorThemeNameV4(
        (props.record?.color ?? "Purple") as ColorThemeDisplayNameV4
      )}
    >
      {props.record && <StructuredText data={props.record.listContent} />}
      {props.children}
    </ChecklistBlockDiv>
  );
};

export default ChecklistBlock;
