export const PizzaIcon = () => (
  <svg
    width="142"
    height="92"
    viewBox="0 0 142 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.4106 90.6656C116.26 90.6656 141.268 84.8944 141.268 77.7752C141.268 70.656 116.26 64.8848 85.4106 64.8848C54.5613 64.8848 29.553 70.656 29.553 77.7752C29.553 84.8944 54.5613 90.6656 85.4106 90.6656Z"
      fill="#10352E"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M122.711 77.6931C122.713 76.0163 122.726 70.7819 122.729 69.1049C122.738 61.6529 98.6995 55.5823 69.0381 55.5451C39.3774 55.5076 15.3236 61.5192 15.3148 68.9706C15.3123 70.6479 15.2986 75.8828 15.2961 77.5588C15.2873 85.0108 39.3248 91.0819 68.9867 91.1191C98.6488 91.1561 122.701 85.145 122.711 77.6931Z"
      fill="#D67C56"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M122.74 69.1175C122.749 61.6655 98.7118 55.5933 69.0504 55.5548C39.389 55.5162 15.3358 61.5259 15.3261 68.9778C15.3164 76.4297 39.3539 82.502 69.0153 82.5405C98.6767 82.5791 122.73 76.5694 122.74 69.1175Z"
      fill="#FFE1B3"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M117.55 68.553C117.557 62.993 95.8406 58.4575 69.0448 58.4226C42.249 58.3878 20.5208 62.8668 20.5136 68.4268C20.5063 73.9869 42.2228 78.5224 69.0186 78.5572C95.8144 78.592 117.543 74.113 117.55 68.553Z"
      fill="#FABE64"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path d="M69.0124 68.4883V90.9626" stroke="#4C1812" strokeMiterlimit="10" />
    <path d="M33.4969 79.125V87.7176" stroke="#4C1812" strokeMiterlimit="10" />
    <path d="M105.866 79.125V87.7176" stroke="#4C1812" strokeMiterlimit="10" />
    <path
      d="M89.6754 64.0771C92.9904 64.0771 95.6777 63.3974 95.6777 62.559C95.6777 61.7207 92.9904 61.041 89.6754 61.041C86.3605 61.041 83.6732 61.7207 83.6732 62.559C83.6732 63.3974 86.3605 64.0771 89.6754 64.0771Z"
      fill="#FF6565"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M71.1389 67.524L105.366 58.9316"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M48.0254 64.0771C51.3404 64.0771 54.0277 63.3974 54.0277 62.559C54.0277 61.7207 51.3404 61.041 48.0254 61.041C44.7105 61.041 42.0232 61.7207 42.0232 62.559C42.0232 63.3974 44.7105 64.0771 48.0254 64.0771Z"
      fill="#FF6565"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M67.4151 67.524L33.1878 58.9316"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M55.2283 75.9381C59.9996 75.9381 63.8675 74.8099 63.8675 73.4183C63.8675 72.0266 59.9996 70.8984 55.2283 70.8984C50.457 70.8984 46.5891 72.0266 46.5891 73.4183C46.5891 74.8099 50.457 75.9381 55.2283 75.9381Z"
      fill="#FF6565"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M84.5058 75.9381C89.2771 75.9381 93.145 74.8099 93.145 73.4183C93.145 72.0266 89.2771 70.8984 84.5058 70.8984C79.7345 70.8984 75.8666 72.0266 75.8666 73.4183C75.8666 74.8099 79.7345 75.9381 84.5058 75.9381Z"
      fill="#FF6565"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M34.4786 70.2975C38.7128 70.2975 42.1452 69.2262 42.1452 67.9046C42.1452 66.5831 38.7128 65.5117 34.4786 65.5117C30.2445 65.5117 26.8121 66.5831 26.8121 67.9046C26.8121 69.2262 30.2445 70.2975 34.4786 70.2975Z"
      fill="#FF6565"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M111.725 67.9044C111.725 69.2264 108.293 70.2973 104.059 70.2973C99.8243 70.2973 96.3921 69.2264 96.3921 67.9044C96.3921 66.5831 99.8243 65.5117 104.059 65.5117C108.293 65.512 111.725 66.5833 111.725 67.9044Z"
      fill="#FF6565"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M105.7 79.0283L74.433 68.3809"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M33.5228 79.0293L64.9813 68.4355"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path d="M122.393 68.4844H15.6531" stroke="#4C1812" strokeMiterlimit="10" />
    <path
      d="M54.4064 68.3359H84.9854C82.7375 61.163 82.809 53.2086 85.729 45.6518C86.6979 43.144 92.1173 29.118 92.1173 29.118L74.3972 22.2715C74.3972 22.2715 69.1939 35.7382 68.0089 38.8056C64.1643 48.7555 56.2028 55.8999 46.822 59.0467C48.7685 62.5284 51.3234 65.6828 54.4064 68.3359Z"
      fill="#E1EBFA"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M81.8455 45.1696C85.688 46.6543 89.9981 44.7654 91.9676 39.6679C93.9372 34.5704 92.0169 30.2741 88.1743 28.7894L79.7718 24.3481C77.264 23.3791 70.5527 25.8448 65.7664 23.9955C62.9609 22.9119 7.00139 1.29037 7.00139 1.29037C4.33579 0.260142 2.67004 2.10107 1.64006 4.76716C0.610076 7.43325 0.604678 9.91559 3.27052 10.9458C3.27052 10.9458 59.23 32.5676 62.0357 33.6514C66.8218 35.5014 70.1313 41.8388 72.6396 42.8073L81.8455 45.1696Z"
      fill="#F0946C"
      stroke="#10352E"
      strokeMiterlimit="10"
    />
    <path
      d="M67.8481 36.8155L66.3563 35.6405C67.6282 34.4489 68.9014 32.6445 69.993 30.8679L67.8481 36.8155Z"
      fill="#32313B"
      stroke="#10352E"
    />
    <path
      d="M62.5649 68.3359H84.9846C82.737 61.1633 82.8098 53.2086 85.7292 45.6518C86.6982 43.144 92.1176 29.118 92.1176 29.118L74.3975 22.2715C74.3975 22.2715 69.1941 35.7382 68.0091 38.8056C64.6445 47.5134 58.1268 54.0725 50.2645 57.6835C53.4002 62.0465 57.5596 65.7335 62.5649 68.3359Z"
      fill="#B0A6F9"
      stroke="#10352E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
