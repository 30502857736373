import { countryList } from "../../constants/countryList.constant";
import Select, { SelectProps } from "../forms/Select";

type Props<T extends object> = SelectProps<T>;

const optgroups = [
  {
    label: "",
    options: [
      "Australia",
      "Canada",
      "Ireland",
      "United Kingdom",
      "United States",
    ].map(c => ({
      label: c,
      value: c,
    })),
  },
  {
    label: "",
    options: countryList.map(c => ({
      label: c,
      value: c,
    })),
  },
];

function CountrySelector<T extends object>(props: Props<T>) {
  return (
    <Select
      {...props}
      options={[
        {
          label: `Select country${props.required ? " *" : ""}`,
          value: "",
          disabled: true,
        },
      ]}
      optgroups={optgroups}
    />
  );
}

export default CountrySelector;
