export const CasesTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <rect
      x="3.5"
      y="3.5"
      width="11"
      height="11"
      rx="2.5"
      stroke="currentColor"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7C5 5.89543 5.89543 5 7 5H14C15.1046 5 16 5.89543 16 7V12C16 14.2091 14.2091 16 12 16H7C5.89543 16 5 15.1046 5 14V7ZM12.5 7C12.5 7.82843 11.8284 8.5 11 8.5C10.1716 8.5 9.5 7.82843 9.5 7C9.5 6.17157 10.1716 5.5 11 5.5C11.8284 5.5 12.5 6.17157 12.5 7ZM7 8.5C7.82843 8.5 8.5 7.82843 8.5 7C8.5 6.17157 7.82843 5.5 7 5.5C6.17157 5.5 5.5 6.17157 5.5 7C5.5 7.82843 6.17157 8.5 7 8.5ZM8.5 11C8.5 11.8284 7.82843 12.5 7 12.5C6.17157 12.5 5.5 11.8284 5.5 11C5.5 10.1716 6.17157 9.5 7 9.5C7.82843 9.5 8.5 10.1716 8.5 11ZM11 12.5C11.8284 12.5 12.5 11.8284 12.5 11C12.5 10.1716 11.8284 9.5 11 9.5C10.1716 9.5 9.5 10.1716 9.5 11C9.5 11.8284 10.1716 12.5 11 12.5Z"
      fill="currentColor"
    />
    <circle
      opacity="0.4"
      cx="11"
      cy="7"
      r="1.25"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <circle
      opacity="0.4"
      cx="7"
      cy="7"
      r="1.25"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <circle
      opacity="0.4"
      cx="7"
      cy="11"
      r="1.25"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <circle opacity="0.4" cx="7" cy="11" r="1.5" fill="currentColor" />
    <circle cx="11" cy="11" r="1.5" fill="currentColor" />
    <circle opacity="0.6" cx="7" cy="7" r="1.5" fill="currentColor" />
  </svg>
);
