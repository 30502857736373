import { navigate } from "gatsby";
import { printErrorInDevelopment } from "./error.utils";
import { isDevelopment } from "../environment";
import { Paths } from "./pathBuilders.utils";

declare global {
  function Intercom(action: string, options?: unknown): unknown;
}

export const openIntercomMessenger = () => {
  try {
    if (!Intercom) {
      navigate(Paths.contactSupport());
      return;
    }
    Intercom("show");
  } catch (e) {
    printErrorInDevelopment(e);
    navigate(Paths.contactSupport());
  }
};

export const showIntercomButton = () => {
  try {
    if (!Intercom) return;
    Intercom("update", { hide_default_launcher: false });
  } catch (e) {
    printErrorInDevelopment(e);
  }
};

export const hideIntercomButton = () => {
  try {
    if (!Intercom) return;
    Intercom("update", { hide_default_launcher: true });
  } catch (e) {
    printErrorInDevelopment(e);
  }
};

export const mimicIntercomBanner = () => {
  if (isDevelopment) {
    setTimeout(() => {
      document.body.style.setProperty("transition", "margin 250ms ease 0s");
      document.body.style.setProperty("margin-top", "50px");
      document.body.style.setProperty("max-height", "calc(100% - 50px)");
    }, 1000);
  }
};

export const getIntercomBannerHeight = () => document.body.style.marginTop;

export const recordIntercomBannerHeight = () => {
  const newValue = getIntercomBannerHeight();
  if (
    document.documentElement.style.getPropertyValue(
      "--intercomBannerHeight"
    ) !== newValue
  )
    document.documentElement.style.setProperty(
      "--intercomBannerHeight",
      newValue
    );
  document.documentElement.toggleAttribute(
    "data-has-intercom-banner",
    parseInt(newValue) > 0
  );
};
