import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  breakpoints,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize, responsiveSizeMaps } from "../../styles/responsiveSizes.styles";

type Props = {};

export const widerPageSectionMaxWidth =
  breakpoints.desktopLg + responsiveSizeMaps.widerPageMargin.desktopMd * 10 * 2;

export const widerPageSectionMaxWidthStyle = () => `
  max-width: ${widerPageSectionMaxWidth}px;
  margin-left: auto;
  margin-right: auto;
`;

const WiderPageSectionWrap = styled.section`
  ${widerPageSectionMaxWidthStyle()};
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
  ${fromTablet} {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
  }
`;

const WiderPageSection = (props: PropsWithChildren<Props>) => {
  return <WiderPageSectionWrap>{props.children}</WiderPageSectionWrap>;
};

export default WiderPageSection;
