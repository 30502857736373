import { styled } from "@linaria/react";
import { DatoCmsSolutionPage } from "../../../graphql-types";
import { colors } from "../../styles/colors.styles";
import PageSection from "../reusableSections/PageSection";
import { RocketIcon } from "../illustrations/spots/RocketIcon";
import { ButtonGroup, ButtonGroupCenterFlex } from "../forms/Button";
import BookADemoButton from "../utilities/BookADemoButton";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import CTADecorationDispatcher from "./decorations/CTADecorationDispatcher";
import { serif } from "../../styles/fonts.styles";
import SignUpButton from "../utilities/SignUpButton";

type Props = {
  page: DatoCmsSolutionPage;
};

const Container = styled.div`
  position: relative;
  border: 1px solid ${colors.purple300};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  text-align: center;
  > * {
    + * {
      margin-top: 1.8rem;
      ${fromTablet} {
        margin-top: 2.4rem;
      }
    }
  }
  h2 {
    font-size: 2.8rem;
    max-width: 16em;
    font-family: ${serif};
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    text-wrap: balance; // supported in Chrome but not in safari
    ${fromTablet} {
      font-size: 3.8rem;
      letter-spacing: -0.01em;
    }
    ${fromDesktopMd} {
      font-size: 4.2rem;
    }
    + ${ButtonGroup} {
      margin-top: 2em;
      ${fromTablet} {
        margin-top: 2.5em;
      }
    }
  }
  p {
    max-width: 28em;
    margin-left: auto;
    margin-right: auto;
  }
  .Button {
    &:first-child {
      .ButtonInner {
        background-color: ${colors.purple800};
        &:hover {
          background-color: ${colors.purple800};
        }
      }
    }
    &:last-child {
      .ButtonInner {
        color: ${colors.purple800};
      }
    }
  }
`;

const BottomSpacer = styled.div`
  height: 5em;
  ${fromTabletLg} {
    height: 8em;
  }
`;

const SolutionPageCTASection = ({ page }: Props) => {
  return (
    <PageSection>
      <Container>
        <RocketIcon />
        <h2>{page.ctaSectionHeadline || "Experience Tines for yourself"}</h2>
        {page.ctaSectionBodyText && <p>{page.ctaSectionBodyText}</p>}
        <ButtonGroupCenterFlex>
          {page.ctaSectionPrimaryButton === "Sign up" ? (
            <>
              <SignUpButton width="8em" />
              <BookADemoButton appearance="outlined" width="8em" />
            </>
          ) : (
            <>
              <BookADemoButton width="8em" />
              <SignUpButton appearance="outlined" width="8em" />
            </>
          )}
        </ButtonGroupCenterFlex>
        <BottomSpacer />
      </Container>
      <CTADecorationDispatcher page={page} />
    </PageSection>
  );
};

export default SolutionPageCTASection;
