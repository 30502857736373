import {
  BrandColorNameV4,
  CommonColorNameV4,
} from "../../styles/colorsV4.styles";
import { StoryDescriptor } from "../../types/helper.types";
import { getCookie } from "../../utils/cookies.utils";
import { useIsLoggedIn } from "../../utils/hooks.utils";
import Button, { ButtonAppearance } from "../forms/Button";

type Props = {
  story: StoryDescriptor;
  color?: BrandColorNameV4 | CommonColorNameV4 | string;
  appearance?: ButtonAppearance;
  utmCampaign?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
  fullWidth?: boolean;
  onClick?: () => void;
};

const reportImportWebhook =
  "https://hq.tines.io/webhook/4772f3950ffb581829c43a00746f1baa/c6d359e2f9b092c6b4b41945636f454c";

const StoryImportButton = (props: Props) => {
  const isLoggedIn = useIsLoggedIn();
  const storyGuid = props.story.guid;
  const urlParams = {
    guid: storyGuid,
    utm_campaign: props.utmCampaign,
    utm_medium: props.utmMedium,
    utm_source: props.utmSource,
  };
  const urlSearchString = Object.entries(urlParams).reduce((str, pair) => {
    if (pair[1])
      return `${str ? `${str}${encodeURIComponent("&")}` : str}${
        pair[0]
      }=${encodeURIComponent(pair[1])}`;
    return str;
  }, "");
  const importToTenantLink = `https://login.tines.com/saml_idp/choose_tenant?intended_path=/import_seed_story?${urlSearchString}`;
  const guestImportLink = `https://login.tines.com/story_import_signup?intended_path=/import_seed_story?${urlSearchString}`;
  const handleButtonClick = () => {
    props.onClick?.();
    fetch(reportImportWebhook, {
      method: "post",
      body: JSON.stringify({
        email: getCookie("email_address") ?? "",
        story: {
          slug: props.story.slug,
          name: props.story.name,
        },
      }),
    });
  };
  return isLoggedIn ? (
    <Button
      color={props.color}
      appearance={props.appearance}
      href={importToTenantLink}
      allowWrap
      onClick={handleButtonClick}
      fullWidth={props.fullWidth}
      target="_blank"
      width={props.fullWidth ? undefined : "9em"}
    >
      Import
    </Button>
  ) : (
    <Button
      color={props.color}
      appearance={props.appearance}
      href={guestImportLink}
      allowWrap
      onClick={handleButtonClick}
      fullWidth={props.fullWidth}
      target="_blank"
      width={props.fullWidth ? undefined : "9em"}
    >
      Import
    </Button>
  );
};

export default StoryImportButton;
