export const CustomerCenterTwoToneIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      opacity="0.1"
      d="M9 5C7.34315 5 6 6.34315 6 8V14H7V8C7 6.89543 7.89543 6 9 6C10.1046 6 11 6.89543 11 8V14H12V8C12 6.34315 10.6569 5 9 5Z"
      fill="currentColor"
    />
    <path
      opacity="0.8"
      d="M12.5 8V13.5H5.5V8C5.5 6.067 7.067 4.5 9 4.5C10.933 4.5 12.5 6.067 12.5 8Z"
      stroke="currentColor"
    />
    <path
      opacity="0.6"
      d="M10.5 8V13.5H7.5V8C7.5 7.17157 8.17157 6.5 9 6.5C9.82843 6.5 10.5 7.17157 10.5 8Z"
      stroke="currentColor"
    />
    <path
      d="M14 8V13H4V8C4 5.23858 6.23858 3 9 3C11.7614 3 14 5.23858 14 8ZM3 13V8C3 4.68629 5.68629 2 9 2C12.3137 2 15 4.68629 15 8V13L16 15V16H15H3H2V15L3 13ZM14.382 14H14H4H3.61803L3.11803 15H14.882L14.382 14Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M4 8C4 5.23858 6.23858 3 9 3C11.7614 3 14 5.23858 14 8V13H15L16 15V16H11.5V15L11 13H12V8C12 6.34315 10.6569 5 9 5C7.34315 5 6 6.34315 6 8V13H7L6.5 15V16H2V15L3 13H4V8Z"
      fill="currentColor"
    />
  </svg>
);
