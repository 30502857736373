import { styled } from "@linaria/react";

type Props = {};

const Wrap = styled.div`
  svg {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translate(75%, -25%);
  }
`;

const PageHeroHeadlineChart = (props: Props) => {
  return (
    <Wrap>
      <Illustration />
    </Wrap>
  );
};

const Illustration = () => (
  <svg width="109" height="143" viewBox="0 0 109 143" fill="none">
    <path
      d="M107.276 131.787H1.87012V142.315H107.276V131.787Z"
      fill="#483C72"
    />
    <path
      d="M108.067 17.7427H1.06689V138.143H108.067V17.7427Z"
      fill="#FA9589"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M98.9515 26.3831H10.1763V129.46H98.9515V26.3831Z" fill="#FFE0CC" />
    <path
      d="M99.0393 105.884H10.1567V129.353H99.0393V105.884Z"
      fill="#C86735"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0054 17.7431L54.5618 3.64992L85.1183 17.7431"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.4971 4.94788C50.4971 2.7668 52.2797 0.984177 54.4608 0.984177C56.6419 0.984177 58.4245 2.7668 58.4245 4.94788C58.4245 7.12897 56.6419 8.91159 54.4608 8.91159C52.2797 8.91159 50.4971 7.12897 50.4971 4.94788Z"
      fill="#F47E3F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M98.6535 26.1718H10.1929V28.5836H98.6535V26.1718Z"
      fill="#483C72"
    />
    <mask
      id="mask0_209_11979"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="10"
      y="26"
      width="89"
      height="104"
    >
      <path
        d="M98.9505 26.3808H10.1753V129.458H98.9505V26.3808Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_209_11979)">
      <path
        d="M129.382 119.409L100.818 92.6275L81.3346 83.6934L63.8019 65.3639L50.8201 69.9148L32.6792 82.519L29.4075 82.8965L16.4258 87.4474L7.74328 101.331L5.96065 103.197L-5.00779 105.63L-22.5405 123.96H116.379L129.361 119.409H129.382Z"
        fill="#25A871"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M-22.5244 123.96H116.395L87.8312 97.1787L68.348 88.2446L50.8153 69.9151L27.4523 86.1474L16.4209 87.4477L6.68981 103.03L-4.99169 105.63L-22.5244 123.96Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M68.3618 88.2662L81.3436 83.7153"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M87.8408 97.1807L100.823 92.6297"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M144.63 126.875L128.629 114.711L118.143 112.971L111.369 110.119L105.035 109.909L100.882 105.756L94.5489 105.987L79.3021 112.153L74.101 116.955L73.6396 117.396L62.7341 119.137L49.207 133.062H129.384L144.63 126.896V126.875Z"
        fill="#FFC8A3"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M49.2153 133.038H129.392L113.39 120.874L102.904 119.134L96.1301 116.281L89.7965 116.072L85.644 111.919L79.3104 112.15L73.6269 117.393L62.7214 119.134L49.1943 133.059L49.2153 133.038Z"
        fill="#FA975F"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M85.6304 111.922L100.877 105.756"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M89.7915 116.074L105.038 109.908"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M96.1143 116.285L111.361 110.12"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M37.2101 128.174L28.4437 122.826L26.4933 118.275L17.0768 117.961L4.40961 109.53L-6.62176 113.431L-14.4024 121.861L-22.1831 132.075H26.1787L37.2101 128.174Z"
        fill="#FFBFDB"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M-22.1831 132.075H26.1787L17.4124 126.727L15.4619 122.176L6.04544 121.862L-6.62176 113.431L-14.4024 121.862L-22.1831 132.075Z"
        fill="#EE86B7"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M6.05127 121.841L17.0826 117.94"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M15.4561 122.176L26.4874 118.275"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M17.4062 126.708L28.4376 122.807"
        stroke="#483C72"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M98.9539 26.3831H10.1787V129.461H98.9539V26.3831Z"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PageHeroHeadlineChart;
