import { styled } from "@linaria/react";
import React, { useState } from "react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { isAndroidDevice, isAppleDevice } from "../../environment";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTabletLg,
  fromTabletMd,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import StaticImageFrame from "../basic/StaticImageFrame";
import { PodcastRecord } from "../../data/podcastEpisodesSeason1to4";

type PodcastEntryProps = {
  record: PodcastRecord;
};
const PodcastEntryWrapper = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  text-align: left;
  font-size: 1.4rem;
  ${fromDesktopMd} {
    font-size: 1.6rem;
  }
  ${uptoPhoneLg} {
    grid-template-rows: auto auto;
    justify-content: center;
  }
  ${fromPhoneLg} {
    grid-template-columns: 14rem minmax(auto, 1fr);
    align-content: start;
  }
  ${fromTabletLg} {
    grid-template-columns: 28rem minmax(auto, 1fr);
  }
  ${fromDesktopMd} {
    grid-template-columns: 34rem minmax(auto, 1fr);
  }
`;
const InformationSection = styled.div`
  display: grid;
  align-self: stretch;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: 1em;
  strong {
    font-weight: 600;
  }
`;
const Details = styled.div`
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  h3 {
    font-size: 1.8rem;
    ${fromTabletMd} {
      font-size: 2.4rem;
    }
    a {
      text-decoration: none;
      &:hover {
        color: ${colors.purple100};
      }
    }
  }
`;
const MetaLine = styled.p`
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
  span {
    opacity: 0.6;
  }
`;
const Summary = styled.div`
  font: inherit;
  color: ${withOpacity(colors.white, 0.75)};
  * {
    font-size: inherit;
    line-height: 1.35;
  }
  a {
    text-decoration: none;
    color: ${colors.white};
    font-weight: 500;
    &:hover {
      color: ${colors.purple300};
    }
  }
`;
const LinkList = styled.p`
  font-weight: 500;
  a {
    color: ${colors.purple300};
    text-decoration: none;
  }
  span {
    display: inline-block;
    opacity: 0.5;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`;
const ImageLink = styled.a`
  display: block;
  text-decoration: none;
  transition: 0.1s;
  &:hover {
    filter: brightness(1.05);
  }
`;

const PodcastEntry: React.FC<PodcastEntryProps> = props => {
  const [defaultLink, setDefaultLink] = useState(props.record.links.spotify);

  useOnMount(() => {
    if (isAppleDevice) setDefaultLink(props.record.links.apple);
    if (isAndroidDevice && props.record.links.youtube)
      setDefaultLink(props.record.links.youtube);
  });

  return (
    <PodcastEntryWrapper>
      <ImageLink
        href={defaultLink}
        {...externalLinkAttr}
        title={props.record.title}
      >
        <StaticImageFrame
          src={props.record.coverImageSrc}
          alt={props.record.title}
          backgroundColor={colors.dark500}
          borderRadius={12}
          aspectRatio="1/1"
          fadeInOnLoad
        />
      </ImageLink>
      <InformationSection>
        <Details>
          <MetaLine>
            <strong>Episode {props.record.episodeNumber}</strong>
            <span>•</span>
            <span>{props.record.datePublished}</span>
            <span>•</span>
            <span>{props.record.duration}</span>
          </MetaLine>
          <h3>
            <a href={defaultLink} {...externalLinkAttr}>
              {props.record.title}
            </a>
          </h3>
          <Summary>{props.record.summary}</Summary>
        </Details>
        <div>
          <p>
            <strong>Listen on:</strong>
          </p>
          <LinkList>
            <a href={props.record.links.spotify} {...externalLinkAttr}>
              Spotify
            </a>
            {props.record.links.youtube && (
              <>
                <span> • </span>
                <a href={props.record.links.youtube} {...externalLinkAttr}>
                  YouTube
                </a>
              </>
            )}
            {props.record.links.apple && (
              <>
                <span> • </span>
                <a href={props.record.links.apple} {...externalLinkAttr}>
                  Apple Podcasts
                </a>
              </>
            )}
          </LinkList>
        </div>
      </InformationSection>
    </PodcastEntryWrapper>
  );
};

export default PodcastEntry;
