export const TimeCakeIcon = () => (
  <svg
    width="107"
    height="94"
    viewBox="0 0 107 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.0321 50.2911L90.6164 65.1829C95.6514 60.2822 98.5892 54.3924 98.5892 48.0553C98.5892 31.0097 77.3462 17.1914 51.1416 17.1914C24.937 17.1914 3.69403 31.0097 3.69403 48.0553C3.69403 65.1012 24.937 78.9192 51.1416 78.9192C57.0578 78.9192 62.7194 78.2128 67.9425 76.9262L51.0321 50.2911Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.3015 0.603516C21.5027 0.603516 1.3999 13.8566 1.3999 30.2053C1.3999 33.7068 1.3999 46.6599 1.3999 50.1614C1.3999 66.5103 21.5027 79.7631 46.3015 79.7631C50.4375 79.7631 54.4422 79.3929 58.2457 78.7025V58.4923L45.8965 28.4674L82.7011 47.1119V67.4935C88.0486 62.6217 91.203 56.635 91.203 50.1611C91.203 46.6596 91.203 33.7065 91.203 30.205C91.203 13.8569 71.0996 0.603516 46.3015 0.603516Z"
      fill="#EB81AF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.3015 0.603516C21.5027 0.603516 1.3999 13.8566 1.3999 30.2053C1.3999 46.5539 21.5027 59.8073 46.3015 59.8073C50.4375 59.8073 54.4422 59.4365 58.2457 58.7464V58.4923L45.8965 28.4674L82.7011 47.1119V47.538C88.0486 42.6659 91.203 36.6789 91.203 30.2055C91.203 13.8569 71.0996 0.603516 46.3015 0.603516Z"
      fill="#FFD18C"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.4222 44.7913C47.6795 44.829 46.928 44.848 46.1693 44.848C31.3702 44.848 19.3729 37.5482 19.3729 28.5431C19.3729 19.5383 31.3702 12.2383 46.1693 12.2383C60.9684 12.2383 72.9654 19.5383 72.9654 28.5431C72.9654 31.3113 71.8316 33.9184 69.8309 36.2018"
      stroke="#FFF0D9"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.4249 47.5317C24.873 47.5317 26.0469 46.9287 26.0469 46.1848C26.0469 45.4409 24.873 44.8379 23.4249 44.8379C21.9768 44.8379 20.8029 45.4409 20.8029 46.1848C20.8029 46.9287 21.9768 47.5317 23.4249 47.5317Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.3377 14.3046C25.7858 14.3046 26.9597 13.7017 26.9597 12.958C26.9597 12.2142 25.7858 11.6113 24.3377 11.6113C22.8896 11.6113 21.7157 12.2142 21.7157 12.958C21.7157 13.7017 22.8896 14.3046 24.3377 14.3046Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M69.9195 14.8827C71.3676 14.8827 72.5415 14.2798 72.5415 13.5361C72.5415 12.7924 71.3676 12.1895 69.9195 12.1895C68.4714 12.1895 67.2975 12.7924 67.2975 13.5361C67.2975 14.2798 68.4714 14.8827 69.9195 14.8827Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.8143 54.6053C49.68 54.6053 51.1925 53.9407 51.1925 53.121C51.1925 52.3012 49.68 51.6367 47.8143 51.6367C45.9486 51.6367 44.4362 52.3012 44.4362 53.121C44.4362 53.9407 45.9486 54.6053 47.8143 54.6053Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.6307 10.5838C49.4965 10.5838 51.0089 9.91927 51.0089 9.09952C51.0089 8.27977 49.4965 7.61523 47.6307 7.61523C45.765 7.61523 44.2526 8.27977 44.2526 9.09952C44.2526 9.91927 45.765 10.5838 47.6307 10.5838Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.5972 30.6483C13.4629 30.6483 14.9753 29.9837 14.9753 29.164C14.9753 28.3442 13.4629 27.6797 11.5972 27.6797C9.73144 27.6797 8.21896 28.3442 8.21896 29.164C8.21896 29.9837 9.73144 30.6483 11.5972 30.6483Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M84.9949 30.6483C86.8606 30.6483 88.3731 29.9837 88.3731 29.164C88.3731 28.3442 86.8606 27.6797 84.9949 27.6797C83.1292 27.6797 81.6167 28.3442 81.6167 29.164C81.6167 29.9837 83.1292 30.6483 84.9949 30.6483Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.6712 54.6184L45.8964 28.4678L82.701 47.1122V67.694L56.6712 54.6184Z"
      fill="#EB81AF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.7941 61.1019L53.1585 46.5145L49.7803 38.2227L82.9475 53.8853L82.7941 61.1019Z"
      fill="#FBB598"
    />
    <path
      d="M56.6712 54.6184L45.8964 28.4678L82.701 47.1122V67.694L56.6712 54.6184Z"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.0253 10.2745C48.0271 10.2745 49.6499 8.65173 49.6499 6.64994C49.6499 4.64816 48.0271 3.02539 46.0253 3.02539C44.0235 3.02539 42.4008 4.64816 42.4008 6.64994C42.4008 8.65173 44.0235 10.2745 46.0253 10.2745Z"
      fill="#EB81AF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.2089 54.0304C48.2107 54.0304 49.8335 52.4076 49.8335 50.4058C49.8335 48.404 48.2107 46.7812 46.2089 46.7812C44.2071 46.7812 42.5844 48.404 42.5844 50.4058C42.5844 52.4076 44.2071 54.0304 46.2089 54.0304Z"
      fill="#EB81AF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10.1465 30.4103C12.1482 30.4103 13.771 28.7875 13.771 26.7857C13.771 24.7839 12.1482 23.1611 10.1465 23.1611C8.14468 23.1611 6.52191 24.7839 6.52191 26.7857C6.52191 28.7875 8.14468 30.4103 10.1465 30.4103Z"
      fill="#EB81AF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M76.7902 18.8826L67.3388 18.332L67.8762 19.5966L48.5645 24.7102L49.6309 27.8043L52.4354 29.0215L68.6153 24.6493L69.1281 25.8564L76.8356 21.0592L76.7902 18.8826Z"
      fill="#6359B3"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.2292 13.849C24.6917 13.849 25.8773 12.6634 25.8773 11.2009C25.8773 9.73835 24.6917 8.55273 23.2292 8.55273C21.7666 8.55273 20.581 9.73835 20.581 11.2009C20.581 12.6634 21.7666 13.849 23.2292 13.849Z"
      fill="#FDB2D2"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M68.2827 14.4916C69.7452 14.4916 70.9308 13.306 70.9308 11.8435C70.9308 10.3809 69.7452 9.19531 68.2827 9.19531C66.8201 9.19531 65.6345 10.3809 65.6345 11.8435C65.6345 13.306 66.8201 14.4916 68.2827 14.4916Z"
      fill="#FDB2D2"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M22.1407 47.0125C23.6031 47.0125 24.7885 45.8271 24.7885 44.3647C24.7885 42.9023 23.6031 41.7168 22.1407 41.7168C20.6783 41.7168 19.4928 42.9023 19.4928 44.3647C19.4928 45.8271 20.6783 47.0125 22.1407 47.0125Z"
      fill="#FDB2D2"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.1009 27.9189L54.0773 27.9283C54.0773 27.9283 55.3846 30.4933 54.4594 32.3523L51.1891 30.9274L52.1009 27.9189Z"
      fill="#4B1D3B"
    />
    <path
      d="M83.1244 30.4103C85.1262 30.4103 86.749 28.7875 86.749 26.7857C86.749 24.7839 85.1262 23.1611 83.1244 23.1611C81.1226 23.1611 79.4999 24.7839 79.4999 26.7857C79.4999 28.7875 81.1226 30.4103 83.1244 30.4103Z"
      fill="#EB81AF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M76.7902 18.8826L67.3388 18.332L67.8765 19.5966L48.5645 24.7102L49.6309 27.8043L69.1659 22.6307L69.546 23.5947L76.7902 18.8826Z"
      fill="#B0A6F9"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.2384 24.3203L25.3469 19.7994L25.8179 18.415L16.2556 19.5511L16.1981 21.4409L23.7115 26.3289L24.2733 24.6776L42.5029 29.4486L43.1811 25.9342L43.2384 24.3203Z"
      fill="#6359B3"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.2383 24.3203L25.3468 19.7994L25.8178 18.415L16.2556 19.5511L23.7684 24.4394L24.3302 22.7881L42.5601 27.5591L43.2383 24.3203Z"
      fill="#B0A6F9"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.0468 25.7139C52.0468 23.537 49.4509 21.7725 46.2487 21.7725C43.0467 21.7725 40.4508 23.537 40.4508 25.7139C40.4508 26.214 40.4508 28.0482 40.4508 28.5484C40.4508 30.7253 43.0467 32.4898 46.2487 32.4898C46.6841 32.4898 47.1064 32.4545 47.5144 32.3926L47.3729 29.2148L50.8243 30.9631C51.5876 30.2955 52.0468 29.4593 52.0468 28.5481C52.0468 28.0476 52.0468 26.214 52.0468 25.7139Z"
      fill="#6359B3"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.8965 25.6327L50.8239 28.1288C51.5873 27.4613 52.0464 26.625 52.0464 25.7139C52.0464 23.537 49.4506 21.7725 46.2483 21.7725C43.0463 21.7725 40.4504 23.537 40.4504 25.7139C40.4504 27.8907 43.0463 29.6553 46.2483 29.6553C46.6838 29.6553 47.106 29.62 47.514 29.5581L45.8965 25.6327Z"
      fill="#B0A6F9"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.824 28.1289V30.9637"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M106.5 61.7149L63.6947 45.6113V64.329L81.7252 92.7283C96.0396 90.3428 106.5 80.4326 106.5 80.4326V61.7149Z"
      fill="#EB81AF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M63.8323 52.3545L82.0005 79.882V86.7639L63.97 59.5117L63.8323 52.3545Z"
      fill="#FBB598"
    />
    <path
      d="M81.7252 74.0107L63.6947 45.6113L106.5 61.7149C106.5 61.7149 96.0396 71.6251 81.7252 74.0107Z"
      fill="#FFD18C"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M74.1552 56.6212C74.1552 56.6212 78.9262 56.6215 80.7618 52.4004"
      stroke="#FFF0D9"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.9203 92.4175V74.1602"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M90.3043 65.8452C91.7523 65.8452 92.9262 65.2422 92.9262 64.4983C92.9262 63.7544 91.7523 63.1514 90.3043 63.1514C88.8562 63.1514 87.6823 63.7544 87.6823 64.4983C87.6823 65.2422 88.8562 65.8452 90.3043 65.8452Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M89.02 65.325C90.4824 65.325 91.6679 64.1395 91.6679 62.6772C91.6679 61.2148 90.4824 60.0293 89.02 60.0293C87.5576 60.0293 86.3721 61.2148 86.3721 62.6772C86.3721 64.1395 87.5576 65.325 89.02 65.325Z"
      fill="#FDB2D2"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M106.5 61.7149L63.6947 45.6113V64.329L81.7252 92.7283C96.0396 90.3428 106.5 80.4326 106.5 80.4326V61.7149Z"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M69.4948 25.8488V23.3906"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
