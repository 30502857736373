import { styled } from "@linaria/react";
import { css } from "linaria";
import { darken } from "polished";
import { useState } from "react";
import {
  fromTablet,
  fromTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { zIndex } from "../../../styles/zIndexes.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../../utils/urlQueryParams.utils";
import TenantDownForm from "../../forms/TenantDownForm";
import SpotIllustration from "../../illustrations/SpotIllustration";
import Spacing from "../../layout/Spacing";
import FromPhoneLg from "../../mediaQueries/FromPhoneLg";
import UptoPhoneLg from "../../mediaQueries/UptoPhoneLg";
import { AlignCenter } from "../../typography/Align";

const ContactSupportEmergencyOptionsList = () => {
  return (
    <div>
      <TenantDownPrompt />
    </div>
  );
};

const TenantDownPrompt = () => {
  const [showForm, setShowForm] = useState(false);
  const toggleForm = () => {
    if (showForm) removeUrlQueryParam("with-query");
    else setUrlQueryParam("with-query", "tenant-down");
    setShowForm(!showForm);
  };
  useOnMount(() => {
    const withQuery = getUrlQueryParams()["with-query"];
    if (withQuery === "tenant-down") {
      setShowForm(true);
    }
  });
  return (
    <div>
      <EmergencyOptionContainer>
        <EmergencyOptionHeader onClick={toggleForm}>
          <h3>🔥 Is your tenant down?</h3>
          <UptoPhoneLg>
            <span>→</span>
          </UptoPhoneLg>
          <FromPhoneLg>
            <span>Get help now</span>
          </FromPhoneLg>
        </EmergencyOptionHeader>
      </EmergencyOptionContainer>
      {showForm && (
        <OverlayFormScreen>
          <Backdrop onClick={toggleForm} />
          <OverlayFormScreenContent>
            <OverlayForm>
              <AlignCenter>
                <SpotIllustration
                  name="radarDown"
                  className={css`
                    margin-top: -1em;
                  `}
                />
                <h2>Tenant down</h2>
                <Spacing size="1em" />
                <p>
                  Please <strong>only</strong> submit a request if your tenant
                  is unavailable or otherwise unusable.
                </p>
              </AlignCenter>
              <Spacing size="1.5em" />
              <TenantDownForm />
            </OverlayForm>
          </OverlayFormScreenContent>
        </OverlayFormScreen>
      )}
    </div>
  );
};

const EmergencyOptionContainer = styled.div`
  background-color: ${withOpacity(colorsV4.orange, 0.1)};
  border-radius: 0.5em;
  overflow: hidden;
`;

const EmergencyOptionHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${withOpacity(colorsV4.orange, 0.1)};
  appearance: none;
  padding: 0.75em 1em;
  border: 0;
  border-radius: 0.5em 0.5em 0 0;
  font-weight: 500;
  width: 100%;
  min-height: 3em;
  color: inherit;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: ${withOpacity(colorsV4.orange, 0.3)};
  }
  h3 {
    font: inherit;
    padding-right: 1em;
  }
  span {
    color: ${colorsV4.orange700};
    ${darkModeLinariaCSS(`color: ${colorsV4.orange};`)};
  }
`;

const OverlayFormScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: ${zIndex("Modal")};
`;

const Backdrop = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${withOpacity(colorsV4.canvas300, 0.5)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(darken(0.1, colorsV4.warmBlack900), 0.5)};`
  )}
`;

const OverlayFormScreenContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 5em 1em;
  ${fromTabletLg} {
    padding: ${rSize("sectionPadding")};
  }
`;

const OverlayForm = styled.div`
  position: relative;
  background-color: var(--BlurredPanelBackgroundColor);
  backdrop-filter: blur(2em);
  ${darkModeLinariaCSS(`color: ${colorsV4.white};`)};
  padding: 1.5em 1.25em;
  ${fromTablet} {
    padding: ${rSize("cardSectionPadding")};
  }
  border-radius: ${rSize("radius")};
  box-shadow: 0 0.5em 2em rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 3.8rem;
  }
`;

export default ContactSupportEmergencyOptionsList;
