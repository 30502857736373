import { styled } from "@linaria/react";
import { css } from "linaria";
import { useRef } from "react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktopMd,
  fromPhoneLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import RigidDotGrid from "../decorations/RigidDotGrid";
import PageSection from "../reusableSections/PageSection";
import { NoBreak } from "../typography/Nowrap";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import { blueprintColors } from "./buildAppsPageConstants";
import HumanTouchingMachineIllustration from "./decorations/HumanTouchingMachineIllustration";

const HumanAndAutomationSectionInner = styled.div`
  background-color: ${blueprintColors.orange.bg};
  color: ${colorsV4.white};
  border: 1px solid ${blueprintColors.orange.fg};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  position: relative;
  text-align: center;
  ${uptoTabletLg} {
    padding-top: 3em;
    padding-bottom: 3em;
  }
  h2 {
    ${fromPhoneLg} {
      span {
        display: block;
      }
    }
  }
  p {
    max-width: 33em;
    margin-left: auto;
    margin-right: auto;
    ${fromDesktopMd} {
      max-width: ${widthInGrid(6)};
    }
  }
`;

const TextContent = styled.div`
  margin-top: ${rSize("lg")};
  padding-bottom: 1em;
  position: relative;
  > * {
    + * {
      margin-top: ${rSize("lg")};
    }
  }
`;

const MichelangeloSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <PageSection
      className={css`
        position: relative;
        z-index: 1;
      `}
    >
      <HumanAndAutomationSectionInner ref={ref}>
        <RigidDotGrid color={withOpacity(blueprintColors.orange.fg, 0.5)} />
        <HumanTouchingMachineIllustration withSolarSystem containerRef={ref} />
        <TextContent>
          <SectionHeading2>
            <span>Human intervention when you need it, </span>
            <Serif>automation when you don’t</Serif>
          </SectionHeading2>
          <p>
            Share insights and expertise across your organization.{" "}
            <NoBreak>Collect real-time</NoBreak> input and communicate updates
            throughout <NoBreak>the workflow</NoBreak> automatically.
          </p>
        </TextContent>
      </HumanAndAutomationSectionInner>
    </PageSection>
  );
};

export default MichelangeloSection;
