import { styled } from "@linaria/react";

const Figure = styled.figure`
  margin: 0;
  padding: 0;
  img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default Figure;
