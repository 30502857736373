import { getFormMetaInfo } from "../../utils/form.utils";

const defaultDemoSalesforceCampaignId = "70109000000t7K6AAI";

export const makeDemoInfoForm = (defaultValues?: {
  leadSource?: string;
  campaignId?: string;
  url?: string;
  gclid?: string;
}) => {
  const { marketingAttribution: _ma, ...rest } = getFormMetaInfo();
  return {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    country: "",
    state: "",
    postcode: "",
    message: "",
    leadSource: defaultValues?.leadSource || "Demo Request",
    campaignId: defaultValues?.campaignId || defaultDemoSalesforceCampaignId,
    url: defaultValues?.url || "",
    ...rest,
  };
};

export type DemoInfoFormObject = ReturnType<typeof makeDemoInfoForm>;
