import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromDesktop,
  fromDesktopMl,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { color, colors } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { PersonPhotoIconContainer } from "./PersonPhotoIcon";
import RatingStars, { RatingStarsContainer } from "../utilities/RatingStars";
import { rSize } from "../../styles/responsiveSizes.styles";
import { FromDesktopMdSpan } from "../mediaQueries/FromDesktopMd";

export type G2Review = {
  color: string;
  title: string;
  body: ReactNode;
  rating: number;
  url: string;
  source: {
    name: string;
    title: string;
    avatar?: string;
  };
};

type Props = {
  review: G2Review;
  stretchHeight?: boolean;
  withAvatar?: boolean;
};

type ContainerProps = {
  backgroundColor: string;
  foregroundColor: string;
};

export const CustomerG2ReviewContainer = styled.blockquote<ContainerProps>`
  display: grid;
  grid-auto-flow: rows;
  grid-gap: 1.5rem;
  grid-template-rows: minmax(auto, 1fr) auto;
  background-color: ${p => p.backgroundColor ?? colors.purple50};
  color: ${p => p.foregroundColor ?? "inherit"};
  margin: 0;
  border-radius: ${rSize("radius")};
  text-align: "left";
  font-size: 1.6rem;
  ${fromTabletMd} {
    font-size: 1.4rem;
  }
  ${fromDesktop} {
    font-size: 1.6rem;
  }
  > * {
    padding: 1.5em;
    ${fromDesktopMl} {
      padding: 2em;
    }
  }
  b,
  strong {
    font-weight: 700;
  }
  &.stretchHeight {
    height: 100%;
  }
`;

const Content = styled.div``;

const Decorations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  + * {
    margin-top: 2em;
  }
`;

const Footer = styled.footer`
  background-color: inherit;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;

const ReviewTitle = styled.h3`
  font-family: ${font("serif")};
  font-size: 2.4rem;
  ${fromTabletMd} {
    font-size: 2rem;
  }
  ${fromDesktop} {
    font-size: 2.4rem;
  }
  line-height: 1.275;
  font-weight: 400;
  margin-bottom: 0.5em;
`;

const ReviewBody = styled.div`
  padding-bottom: 1em;
  font-family: ${font("sans")};
  line-height: 1.4;
`;

const BlockquoteCite = styled.cite`
  display: flex;
  align-items: center;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  ${PersonPhotoIconContainer} {
    flex: 0 0 auto;
  }
  > * {
    + * {
      margin-left: 0.75em;
    }
  }
  strong {
    font-weight: 600;
  }
`;

const BlockquoteCiteSourceNameGroup = styled.div`
  line-height: 1.2;
  > * {
    display: block;
  }
  ${RatingStarsContainer} {
    margin-top: 0.25em;
  }
`;

const fullReviewLink = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  text-decoration: none;
  margin-top: 1em;
  &:hover {
    filter: brightness(0.9);
  }
  svg {
    margin-left: 0.5em;
  }
`;

const CustomerG2ReviewCard = (props: Props) => {
  const { title, body, source, rating, url } = props.review;
  const bg = color(props.review.color ?? "purple", 50);
  const fg = color(props.review.color ?? "purple", 700);
  return (
    <CustomerG2ReviewContainer
      className={cx(props.stretchHeight && "stretchHeight")}
      backgroundColor={bg}
      foregroundColor={fg}
    >
      <Content>
        <Decorations>
          <svg width="19" height="16" viewBox="0 0 19 16" fill="currentColor">
            <path d="M0.135209 6.16935L3.4284 0.497841C3.60727 0.189694 3.93677 0 4.29318 0H6.56713C7.3283 0 7.81044 0.816617 7.44273 1.48309L5.07915 5.76698C4.99872 5.91272 4.95659 6.07656 4.95659 6.24299C4.95659 6.78718 5.39774 7.22833 5.94193 7.22833H7.26098C7.81322 7.22833 8.26098 7.67609 8.26098 8.22832V14.6959C8.26098 15.2481 7.81322 15.6959 7.26098 15.6959H0.999993C0.447761 15.6959 0 15.2481 0 14.6959V6.6715C0 6.49512 0.0466338 6.32189 0.135209 6.16935Z" />
            <path d="M10.8742 6.16935L14.1674 0.497841C14.3464 0.189694 14.6758 0 15.0322 0H17.3062C18.0674 0 18.5495 0.816617 18.1818 1.48309L15.8183 5.76698C15.7378 5.91272 15.6956 6.07656 15.6956 6.24299C15.6956 6.78718 16.1369 7.22833 16.6811 7.22833H18C18.5523 7.22833 19 7.67609 19 8.22832V14.6959C19 15.2481 18.5523 15.6959 18 15.6959H11.7391C11.1868 15.6959 10.739 15.2481 10.739 14.6959V6.6715C10.739 6.49512 10.7858 6.32189 10.8742 6.16935Z" />
          </svg>
          <RatingStars rating={rating} color={fg} />
        </Decorations>

        <div>
          <ReviewTitle>{title}</ReviewTitle>
          <ReviewBody>{body}</ReviewBody>
        </div>
      </Content>

      <Footer>
        <BlockquoteCite>
          <BlockquoteCiteSourceNameGroup>
            <div>
              <strong>{source.name}</strong>
            </div>
            <div>{source.title}</div>
          </BlockquoteCiteSourceNameGroup>
        </BlockquoteCite>
        <a className={fullReviewLink} href={url} {...externalLinkAttr}>
          <span>
            Read <FromDesktopMdSpan>full</FromDesktopMdSpan> review on G2.com
          </span>
          <svg width="11" height="10" viewBox="0 0 11 10" fill="currentColor">
            <path d="M2.43735 0V1.85111H7.30657L0.646606 8.51107L2.13554 10L8.7955 3.36016L8.81562 8.20926H10.6667V0H2.43735Z" />
          </svg>
        </a>
      </Footer>
    </CustomerG2ReviewContainer>
  );
};

export default CustomerG2ReviewCard;
