export const PNG_SPOT_ILLUSTRATIONS = {
  templateBlocks: {
    displayName: "Template blocks",
    alt: "An illustration of template blocks in Tines",
    slug: "tines-icon-template-blocks",
    preferredBgColor: "green",
  },
  storyRun: {
    displayName: "Story run",
    alt: "An illustration of action blocks passing through a gate, signifying story runs in Tines",
    slug: "tines-icon-story-run",
    preferredBgColor: "green",
  },
  skyscraper: {
    displayName: "Skyscraper",
    alt: "An illustration of a purple skyscraper",
    slug: "tines-icon-skyscraper",
    preferredBgColor: "purple",
  },
  notebook: {
    displayName: "Notebook",
    alt: "An illustration of a paper notebook",
    slug: "tines-icon-notebook",
    preferredBgColor: "orange",
  },
  accessForbidden: {
    displayName: "Access forbidden",
    alt: "An illustration of a road block that signifies blocked access",
    slug: "tines-icon-access-forbidden",
    preferredBgColor: "pink",
  },
  gameBoard: {
    displayName: "Game board",
    alt: "An illustration of a game board",
    slug: "tines-icon-game-board",
    preferredBgColor: "purple",
  },
  emptyTreasureChest: {
    displayName: "Empty treasure chest",
    alt: "An illustration of an empty treasure chest",
    slug: "tines-icon-empty-treasure-chest",
    preferredBgColor: "purple",
  },
  emptyJar: {
    displayName: "Empty Jar",
    alt: "An illustration of an empty glass jar",
    slug: "tines-icon-empty-jar",
    preferredBgColor: "pink",
  },
  adaptorCable: {
    displayName: "Adapter cable",
    alt: "An illustration of an adaptor cable",
    slug: "tines-icon-adaptor-cable",
    preferredBgColor: "pink",
  },
  blocksOnCar: {
    displayName: "Blocks on car",
    alt: "An illustration of some colorful blocks stacked on top of a car",
    slug: "tines-icon-blocks-on-car",
    preferredBgColor: "green",
  },
  blockStack: {
    displayName: "Block stack",
    alt: "An illustration of a stack of blocks",
    slug: "tines-icon-block-stack",
    preferredBgColor: "orange",
  },
  brokenEgg: {
    displayName: "Broken egg",
    alt: "An illustration of a smashed egg",
    slug: "tines-icon-broken-egg",
    preferredBgColor: "purple",
  },
  chainedRings: {
    displayName: "Chained rings",
    alt: "An illustration of five rings chained into a loop",
    slug: "tines-icon-chained-rings",
    preferredBgColor: "pink",
  },
  colorfulBuildings: {
    displayName: "Colorful buildings",
    alt: "An illustration of colorful buildings",
    slug: "tines-icon-colorful-buildings",
    preferredBgColor: "orange",
  },
  horn: {
    displayName: "horn",
    alt: "An illustration of a small horn",
    slug: "tines-icon-horn",
    preferredBgColor: "pink",
  },
  houseStack: {
    displayName: "House stack",
    alt: "An illustration of a stack of houses",
    slug: "tines-icon-house-stack",
    preferredBgColor: "purple",
  },
  lamp: {
    displayName: "Lamp",
    alt: "An illustration of a table lamp",
    slug: "tines-icon-lamp",
    preferredBgColor: "green",
  },
  magnet: {
    displayName: "Magnet",
    alt: "An illustration of a magnet attracting small bolts and nails",
    slug: "tines-icon-magnet",
    preferredBgColor: "pink",
  },
  newsletter: {
    displayName: "Newsletter",
    alt: "An illustration of newspapers popping out of a toaster",
    slug: "tines-icon-newsletter",
    preferredBgColor: "purple",
  },
  newtonsCradle: {
    displayName: "Newtons cradle",
    alt: "An illustration of a Newtons Cradle",
    slug: "tines-icon-newtons-cradle",
    preferredBgColor: "pink",
  },
  paperPlane: {
    displayName: "Paper plane",
    alt: "An illustration of a hand holding a paper plane",
    slug: "tines-icon-paper-plane",
    preferredBgColor: "pink",
  },
  pen: {
    displayName: "Pen",
    alt: "An illustration of a fancy pen",
    slug: "tines-icon-pen",
    preferredBgColor: "pink",
  },
  plantWithStickies: {
    displayName: "Plant with stickies",
    alt: "An illustration of a pot plant and a mug with sticky notes on them",
    slug: "tines-icon-plant-with-stickies",
    preferredBgColor: "green",
  },
  sevenBuildingBlocks: {
    displayName: "Seven building blocks",
    alt: "An illustration of seven color blocks",
    slug: "tines-icon-seven-building-blocks",
    preferredBgColor: "orange",
  },
  sevenBuildingBlocksSquares: {
    displayName: "Seven building blocks (squares)",
    alt: "An illustration of seven color blocks",
    slug: "tines-icon-seven-building-blocks-squares",
    preferredBgColor: "orange",
  },
  skyTunnel: {
    displayName: "Sky tunnel",
    alt: "An illustration of a tunnel connecting the sky to the ground",
    slug: "tines-icon-sky-tunnel",
    preferredBgColor: "purple",
  },
  springHand: {
    displayName: "Spring hand",
    alt: "An illustration of a hand attached to a metal spring",
    slug: "tines-icon-spring-hand",
    preferredBgColor: "purple",
  },
  stackedRings: {
    displayName: "Stacked rings",
    alt: "An illustration of a stack of wooden rings",
    slug: "tines-icon-stacked-rings",
    preferredBgColor: "orange",
  },
  tinesKey: {
    displayName: "Tines key",
    alt: "A Tines logo-shaped key",
    slug: "tines-icon-tines-key",
    preferredBgColor: "purple",
  },
  galaxyGate: {
    displayName: "Galaxy gate",
    alt: "An illustration of a gate to the galaxy",
    slug: "tines-icon-galaxy-gate",
    preferredBgColor: "purple",
  },
  magicEmail: {
    displayName: "Magic email",
    alt: "An illustration of an email delivered over a magic carpet",
    slug: "tines-icon-magic-email",
    preferredBgColor: "pink",
  },
  stopwatch: {
    displayName: "Stopwatch",
    alt: "An illustration of a purple stopwatch",
    slug: "tines-icon-stopwatch",
    preferredBgColor: "purple",
  },
  steppingOnGum: {
    displayName: "Stepping on gum",
    alt: "Sn illustration of a sneaker shoe stepping on chewing gum",
    slug: "tines-icon-stepping-on-gum",
    preferredBgColor: "purple",
  },
  emptyIdCard: {
    displayName: "Empty ID card",
    alt: "An ID card with no details",
    slug: "tines-icon-empty-id-card",
    preferredBgColor: "purple",
  },
  retroMobilePhone: {
    displayName: "Retro phone",
    alt: "A retro mobile phone",
    slug: "tines-icon-retro-mobile-phone",
    preferredBgColor: "purple",
  },
  tutorials: {
    displayName: "Tutorials",
    alt: "An illustration of a classroom blackboard with a play icon",
    slug: "tines-icon-tutorials",
    preferredBgColor: "purple",
  },
  forkingRoutes: {
    displayName: "Forking routes",
    alt: "An illustration of three forking routes",
    slug: "tines-icon-forking-routes",
    preferredBgColor: "orange",
  },
  openBookWithBlocks: {
    displayName: "Opened book with floating blocks",
    alt: "An illustration of colorful blocks floating on an opened book",
    slug: "tines-icon-open-book-with-floating-blocks",
    preferredBgColor: "yellow",
  },
  emptyBook: {
    displayName: "Empty Book",
    alt: "An illustration of a floating opened book",
    slug: "tines-icon-empty-book",
    preferredBgColor: "purple",
  },
  chainWithTwoRings: {
    displayName: "Chain with two rings",
    alt: "An illustration of a chain with two rings",
    slug: "tines-icon-chain-with-two-rings",
    preferredBgColor: "pink",
  },
  chainWithThreeRings: {
    displayName: "Chain with three rings",
    alt: "An illustration of a chain with three rings",
    slug: "tines-icon-chain-with-three-rings",
    preferredBgColor: "pink",
  },
  vintageComputer: {
    displayName: "Vintage Computer",
    alt: "An illustration of a vintage computer",
    slug: "tines-icon-vintage-computer",
    preferredBgColor: "green",
  },
  balancingBlocks: {
    displayName: "Balancing blocks",
    alt: "An illustration of a tower of blocks",
    slug: "tines-icon-balancing-blocks",
    preferredBgColor: "yellow",
  },
  snorkel: {
    displayName: "Snorkeling in a pool",
    alt: "An illustration of a snorkeler peeking out of a pool",
    slug: "tines-icon-snorkel",
    preferredBgColor: "pink",
  },
  tableTennis: {
    displayName: "Table tennis",
    alt: "An illustration of a table tennis set",
    slug: "tines-icon-table-tennis",
    preferredBgColor: "orange",
  },
  worldMap: {
    displayName: "World map",
    alt: "An illustration of a world map",
    slug: "tines-icon-world-map",
    preferredBgColor: "orange",
  },
  cookingCode: {
    displayName: "Cooking code",
    alt: "An illustration of code cooking in soup",
    slug: "tines-icon-cooking-code",
    preferredBgColor: "purple",
  },
  zenBrain: {
    displayName: "Zen brain",
    alt: "An illustration of a brain chilling in the sky",
    slug: "tines-icon-zen-brain",
    preferredBgColor: "pink",
  },
  blossomingMind: {
    displayName: "Blossoming mind",
    alt: "An illustration of flowers growing out of a human-head shaped pot",
    slug: "tines-icon-blossoming-mind",
    preferredBgColor: "green",
  },
  robotArm: {
    displayName: "Robot arm assembling Actions",
    alt: "An illustration of a robot arm assembling Tines Actions",
    slug: "tines-icon-robot-arm",
  },
  partnersGlobe: {
    displayName: "Tines Partners Ecosystem",
    alt: "An illustration of a network of nodes shaped as a desktop globe",
    slug: "tines-icon-partners-globe",
  },
  storyboard: {
    displayName: "Tines Storyboard",
    alt: "An illustration of a tines Storyboard",
    slug: "tines-icon-storyboard",
  },
  wonkyIntegrationStack: {
    displayName: "Wonky integration stack",
    alt: "An illustration of a cube glued together with chewing gum, duct tape and nails",
    slug: "tines-icon-wonky-integration-stack",
  },
  chessboard: {
    displayName: "Chessboard",
    alt: "An illustration of a chessboard",
    slug: "tines-icon-chessboard",
  },
  suitcase: {
    displayName: "Suitcase",
    alt: "An illustration of a purple suitcase",
    slug: "tines-icon-suitcase",
  },
  suitcaseWithHandcuffs: {
    displayName: "Suitcase with handcuffs",
    alt: "An illustration of a purple suitcase with a pair of handcuffs",
    slug: "tines-icon-suitcase-with-handcuffs",
  },
  crystalBall: {
    displayName: "Crystal ball",
    alt: "An illustration of a crystal ball surrounded by two candles",
    slug: "tines-icon-crystal-ball",
  },
  goldenBadge: {
    displayName: "Golden badge",
    alt: "An illustration of a hexagonal golden badge with a cog etched at its center",
    slug: "tines-icon-golden-badge",
  },
  strategyMap: {
    displayName: "Strategy map",
    alt: "An illustration of a map showing a strategic plan",
    slug: "tines-icon-strategy-map",
  },
  cogStatue: {
    displayName: "Cog statue",
    alt: "An illustration of a cog-shaped statue",
    slug: "tines-icon-cog-statue",
  },
  locksChainedWithKey: {
    displayName: "Cog statue",
    alt: "An illustration of three locks chained to a key",
    slug: "tines-icon-locks-chained-with-key",
  },
  blocks3: {
    displayName: "3 blocks",
    alt: "An illustration of four blocks of various shapes and colors",
    slug: "tines-icon-blocks-3",
  },
  blocks4: {
    displayName: "4 blocks",
    alt: "An illustration of four blocks of various shapes and colors",
    slug: "tines-icon-blocks-4",
  },
  meltingCloud: {
    displayName: "Melting cloud",
    alt: "An illustration of a cloud melting",
    slug: "tines-icon-melting-cloud",
  },
  eyePoppingGlasses: {
    displayName: "Eye-popping glasses",
    alt: "An illustration of a pair of eye-popping glasses",
    slug: "tines-icon-eye-popping-glasses",
  },
  documentBox: {
    displayName: "Document box",
    alt: "An illustration of a box or documents and folders",
    slug: "tines-icon-document-box",
  },
  gift: {
    displayName: "Gift box",
    alt: "An illustration of a gift box",
    slug: "tines-icon-gift",
  },
  guidesCompass: {
    displayName: "Compass",
    alt: "An illustration of a compass",
    slug: "tines-icon-guides-compass",
  },
  radar: {
    displayName: "Radar",
    alt: "An illustration of a radar",
    slug: "tines-icon-radar",
  },
  radarDown: {
    displayName: "Radar down",
    alt: "An illustration of a non-functional radar with its disk pointing downwards",
    slug: "tines-icon-radar-down",
  },
  storyLibrary: {
    displayName: "Tines Library",
    alt: "An illustration of a book-shaped building with an entrance",
    slug: "tines-icon-story-library",
  },
  submissionSuccessPiano: {
    displayName: "Piano",
    alt: "An illustration of a piano playing a happy tune",
    slug: "tines-icon-submission-success-piano",
  },
  whatsNewDinosaurEgg: {
    displayName: "Dinosaur egg",
    alt: "An illustration of a dinosaur egg hatching",
    slug: "tines-icon-whats-new-dinosaur-egg",
  },
  lightbulbOnBook: {
    displayName: "Documentation",
    alt: "An illustration of a lightbulb on top of a book",
    slug: "tines-icon-lightbulb-on-book",
  },
  hubLighthouse: {
    displayName: "Tines Hub",
    alt: "An illustration of a lighthouse sitting on top of a book",
    slug: "tines-icon-hub-lighthouse",
  },
  bootcamp: {
    displayName: "Tines Bootcamp",
    alt: "An illustration of a camping tent with a pair of boots sitting in front",
    slug: "tines-icon-bootcamp",
  },
  ufo: {
    displayName: "UFO",
    alt: "An illustration of a flying UFO",
    slug: "tines-icon-ufo",
  },
  certBadgeCustomer: {
    displayName: "Tines Certification badge",
    alt: "Tines Certification badge",
    slug: "tines-icon-certificate-customer",
  },
  slackCommunity: {
    displayName: "Tines Slack Community icon",
    alt: "Tines Slack Community icon",
    slug: "tines-icon-slack-community",
  },
  apps: {
    displayName: "Tines apps icon",
    alt: "Tines apps icon",
    slug: "tines-icon-apps",
  },
  cases: {
    displayName: "Tines cases icon",
    alt: "Tines cases icon",
    slug: "tines-icon-cases",
  },
  university: {
    displayName: "Tines university icon",
    alt: "Tines university icon",
    slug: "tines-icon-university",
  },
  lighthouse: {
    displayName: "Lighthouse icon",
    alt: "Lighthouse icon",
    slug: "tines-icon-lighthouse",
  },
  soarCello: {
    displayName: "Automated cello icon",
    alt: "Automated cello icon",
    slug: "tines-icon-soar-cello",
  },
  complianceStamp: {
    displayName: "Compliance stamp icon",
    alt: "Compliance stamp icon",
    slug: "tines-icon-compliance-stamp",
  },
  onboardingOffboarding: {
    displayName: "Onboarding/offboarding icon",
    alt: "Onboarding/offboarding icon",
    slug: "tines-icon-onboarding-offboarding",
  },
  webinar: {
    displayName: "Webinar icon",
    alt: "Webinar icon",
    slug: "tines-icon-webinar",
  },
  onboarding: {
    displayName: "Onboarding icon",
    alt: "Onboarding icon",
    slug: "tines-icon-onboarding",
  },
  automation: {
    displayName: "Automation icon",
    alt: "Automation icon",
    slug: "tines-icon-automation",
  },
} as const;

export type SpotIllustrationName = keyof typeof PNG_SPOT_ILLUSTRATIONS;
