export const WorkbenchTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <circle opacity="0.1" cx="8.5" cy="6" r="3.5" fill="currentColor" />
    <path opacity="0.2" d="M6 9.5L2 16H10L6 9.5Z" fill="currentColor" />
    <rect opacity="0.3" x="11" y="9" width="5" height="5" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7.44999C8.83844 7.48278 8.67123 7.5 8.5 7.5C7.11929 7.5 6 6.38071 6 5C6 3.61929 7.11929 2.5 8.5 2.5C9.88071 2.5 11 3.61929 11 5C11 5.8178 10.6073 6.54389 10.0002 7H10H9V7.44999ZM9 8.46456C8.8367 8.48791 8.66976 8.5 8.5 8.5C6.567 8.5 5 6.933 5 5C5 3.067 6.567 1.5 8.5 1.5C10.433 1.5 12 3.067 12 5C12 5.74362 11.7681 6.43308 11.3727 7H14H15V8V12V13H14H10H9V12V8.46456ZM2 16H3.17418H8.82582H10L9.38462 15L6.58709 10.454L6 9.5L5.41291 10.454L2.61538 15L2 16ZM8.21044 15L6 11.408L3.78956 15H8.21044ZM10 12V8H14V12H10Z"
      fill="currentColor"
    />
  </svg>
);
