import { flashNotice } from "./notice.utils";

export const copyToClipboard = (
  text: string,
  successMessage?: string,
  errorMessage?: string
) => {
  if (!text) return;
  try {
    navigator.clipboard.writeText(text);
    flashNotice(successMessage ?? `Copied to clipboard.`);
  } catch (e) {
    flashNotice(errorMessage ?? "Failed to copy, please try copying manually.");
  }
};
