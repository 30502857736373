import { styled } from "@linaria/react";
import { isDevelopment } from "../../environment";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { UnknownObject } from "../../types/helper.types";

type Props = {
  record: Partial<UnknownObject>;
};

const DevUnknownBlockContainer = styled.div`
  background-color: ${withOpacity(colorsV4.orange, 0.2)};
  padding: 1em;
  border-radius: 1em;
`;

const DevUnknownBlock = (props: Props) => {
  return isDevelopment ? (
    <DevUnknownBlockContainer>
      <p>
        Encountered a block that does not have a rendering component defined:
      </p>
      <pre>{JSON.stringify(props.record, null, 2)}</pre>
    </DevUnknownBlockContainer>
  ) : null;
};

export default DevUnknownBlock;
