export const ChessboardIcon = () => (
  <svg
    width="104"
    height="53"
    viewBox="0 0 104 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.2255 20.7573H68.6841V51.666H102.95L81.2255 20.7573Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M51.9807 45.3274H11.0513L28.6553 17.1043H51.9807V45.3274Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.98 45.3274H92.9096L75.3056 17.1043H51.98V45.3274Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.3071 17.1043H52.333V28.7222H82.275L75.3071 17.1043Z"
      fill="#DAF2E8"
    />
    <path
      d="M52.0021 28.8019V45.3277H11.0513L21.5711 28.6921L52.0021 28.8019Z"
      fill="#DAF2E8"
    />
    <path
      d="M51.9807 45.3274H11.0513L28.6553 17.1043H51.9807V45.3274Z"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.98 45.3274H92.9096L75.3056 17.1043H51.98V45.3274Z"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.3564 28.8049H83.0032"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M92.91 45.3277H11.0513V51.6664H92.91V45.3277Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.3745 28.7643C51.7219 28.7643 54.4356 28.2234 54.4356 27.5562C54.4356 26.889 51.7219 26.3481 48.3745 26.3481C45.0271 26.3481 42.3135 26.889 42.3135 27.5562C42.3135 28.2234 45.0271 28.7643 48.3745 28.7643Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M71.6619 33.8987C75.7142 33.8987 78.9992 32.8068 78.9992 31.4598C78.9992 30.1128 75.7142 29.0208 71.6619 29.0208C67.6097 29.0208 64.3247 30.1128 64.3247 31.4598C64.3247 32.8068 67.6097 33.8987 71.6619 33.8987Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M44.3735 28.3889C47.8509 28.3889 50.6699 25.57 50.6699 22.0926C50.6699 18.6152 47.8509 15.7962 44.3735 15.7962C40.8961 15.7962 38.0771 18.6152 38.0771 22.0926C38.0771 25.57 40.8961 28.3889 44.3735 28.3889Z"
      fill="#DAF2E8"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.4023 25.5716L65.1048 29.759C66.5123 29.6897 67.4786 26.0653 67.2615 21.6634C67.0449 17.2611 65.7279 13.7494 64.3204 13.8188L48.4023 19.5965C48.4023 19.5965 46.792 20.3642 46.792 22.5445C46.792 24.7249 48.4023 25.5716 48.4023 25.5716Z"
      fill="#DAF2E8"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M67.263 33.1148C71.6022 33.1148 75.1197 28.0439 75.1197 21.7886C75.1197 15.5333 71.6022 10.4624 67.263 10.4624C62.9238 10.4624 59.4062 15.5333 59.4062 21.7886C59.4062 28.0439 62.9238 33.1148 67.263 33.1148Z"
      fill="#DAF2E8"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M68.7935 33.1148C72.2873 33.1148 75.1197 28.0439 75.1197 21.7886C75.1197 15.5333 72.2873 10.4624 68.7935 10.4624C65.2996 10.4624 62.4673 15.5333 62.4673 21.7886C62.4673 28.0439 65.2996 33.1148 68.7935 33.1148Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M37.7003 40.8826C45.5183 40.8826 51.8561 39.3936 51.8561 37.5568C51.8561 35.72 45.5183 34.231 37.7003 34.231C29.8822 34.231 23.5444 35.72 23.5444 37.5568C23.5444 39.3936 29.8822 40.8826 37.7003 40.8826Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M45.5858 34.2576C45.5858 32.3888 40.0675 30.8744 33.2608 30.8744C26.4544 30.8744 20.936 32.3888 20.936 34.2576C20.936 35.2686 20.936 36.2661 20.936 37.2771C20.936 39.1461 26.4544 40.6606 33.2608 40.6606C40.0675 40.6606 45.5858 39.1461 45.5858 37.2771C45.5858 36.2662 45.5858 35.2686 45.5858 34.2576Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M45.5858 34.2581C45.5858 36.1266 40.0675 37.6414 33.2608 37.6414C26.4544 37.6414 20.936 36.1266 20.936 34.2581C20.936 32.3894 26.4544 27.9661 33.2608 27.9661C40.0675 27.9663 45.5858 32.3896 45.5858 34.2581Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M36.4924 12.6805C38.0893 11.4987 39.7485 9.60172 39.7485 7.46287C39.7485 3.87979 36.8439 0.974609 33.26 0.974609C29.6771 0.974609 26.7719 3.87979 26.7719 7.46287C26.7719 9.60172 28.4317 11.4985 30.0284 12.6805L24.6377 30.1672C24.6377 31.6906 28.498 32.9249 33.2602 32.9249C38.023 32.9249 41.8829 31.6906 41.8829 30.1672L36.4924 12.6805Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.2593 14.1517C37.0169 14.1517 40.063 11.1056 40.063 7.34798C40.063 3.59038 37.0169 0.54425 33.2593 0.54425C29.5017 0.54425 26.4556 3.59038 26.4556 7.34798C26.4556 11.1056 29.5017 14.1517 33.2593 14.1517Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="0.92"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.8872 13.1334C29.8872 13.1334 31.533 14.1514 33.2598 14.1514C34.9869 14.1514 36.7578 13.4166 36.7578 13.4166L37.5768 16.212C37.5768 16.212 33.2164 17.0285 29.6748 13.5581L29.8872 13.1334Z"
      fill="#32313B"
    />
  </svg>
);
