export const PageIconPortal = () => (
  <svg width="103" height="101" viewBox="0 0 103 101" fill="none">
    <g clipPath="url(#clip0_209_12664)">
      <path
        d="M86.753 84.3482C78.8985 92.6801 56.5478 84.3868 36.8352 65.8037C17.1226 47.2205 7.52653 25.3975 15.381 17.0656C23.2355 8.73374 45.5861 17.027 65.2988 35.6102C85.0114 54.1934 94.6075 76.0164 86.753 84.3482Z"
        fill="#FFE0CC"
        stroke="#8D75E6"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <mask
        id="mask0_209_12664"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="13"
        width="78"
        height="76"
      >
        <path
          d="M86.753 84.3482C78.8985 92.6801 56.5478 84.3868 36.8352 65.8037C17.1226 47.2205 7.52653 25.3975 15.381 17.0656C23.2355 8.73374 45.5861 17.027 65.2988 35.6102C85.0114 54.1934 94.6075 76.0164 86.753 84.3482Z"
          fill="#EDE9E3"
          stroke="#483C72"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_209_12664)">
        <path
          d="M50.617 62.3149C52.0523 62.5673 52.9089 64.5196 52.5348 66.7118C52.1722 68.8895 50.7173 70.4687 49.2704 70.2309L33.5724 67.549C32.137 67.2965 31.2805 65.3443 31.6546 63.152C32.0172 60.9743 33.4721 59.3951 34.9189 59.6329L50.617 62.3149Z"
          fill="#D7C4FA"
          stroke="#483C72"
          strokeMiterlimit="10"
        />
        <path
          d="M50.6159 62.315C49.1805 62.0626 47.7256 63.6418 47.3515 65.8341C46.989 68.0117 47.8486 69.9901 49.2693 70.2311C50.7047 70.4836 52.1596 68.9043 52.5222 66.7267C52.8848 64.549 52.0251 62.5706 50.6044 62.3297L50.6159 62.315Z"
          fill="#C1AAF7"
          stroke="#483C72"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M86.753 84.3482C78.8985 92.6801 56.5478 84.3868 36.8352 65.8037C17.1226 47.2205 7.52653 25.3975 15.381 17.0656C23.2355 8.73374 45.5861 17.027 65.2988 35.6102C85.0114 54.1934 94.6075 76.0164 86.753 84.3482Z"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M58.5427 42.7767C75.3406 58.6122 84.7857 76.7919 82.3674 86.9641C84.1194 86.4502 85.5995 85.5716 86.7705 84.3295C94.607 76.0167 85.0109 54.1937 65.2983 35.6106C45.5857 17.0274 23.235 8.7341 15.3805 17.066C14.2096 18.3081 13.4378 19.8183 13.0101 21.6167C23.04 18.584 41.7459 26.9785 58.5247 42.7958L58.5427 42.7767Z"
        fill="#FFE0CC"
        stroke="#483C72"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M38.7778 22.4884L60.5123 29.5385L59.2824 33.7686L50.7925 41.4038L39.9328 37.8787L37.5479 26.7185L38.7778 22.5034L38.7778 22.4884Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M46.0377 19.0841L56.8974 22.6092L59.2824 33.7694L50.7925 41.4045L39.9328 37.8795L37.5479 26.7193L46.0377 19.0841Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M37.5479 26.7185L38.7778 22.4884L41.1628 33.6486L52.0375 37.1737L60.5273 29.5385L59.2974 33.7686L50.8075 41.4038L39.9478 37.8787L37.5629 26.7185L37.5479 26.7185Z"
      fill="#88D0A3"
    />
    <path
      d="M47.2681 14.8536L58.1429 18.3787L60.5278 29.5389L52.038 37.1741L41.1633 33.649L38.7783 22.4888L47.2681 14.8536Z"
      fill="#5EBC89"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M52.1423 37.173L50.1924 41.163"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M41.3436 33.3341L40.2637 37.9992"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M39.9181 37.8783L37.5332 26.7181L38.7632 22.5031"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M50.7924 41.4041L39.9326 37.879"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M60.5118 29.5372L59.2818 33.7523L50.792 41.3875"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M91.6603 34.0877C92.5603 31.4102 89.7252 28.0352 85.3376 26.5502C80.95 25.0652 76.6299 26.0327 75.7299 28.7102C75.4599 29.5202 74.4924 32.4002 74.1998 33.2102C73.2998 35.8877 76.1349 39.2402 80.545 40.7477C84.9551 42.2327 89.2527 41.2652 90.1527 38.5877C90.4227 37.7777 91.3903 34.8977 91.6828 34.0877L91.6603 34.0877Z"
      fill="#FA975F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M82.0299 36.2477C86.4175 37.7327 90.7376 36.7652 91.6376 34.0877C92.5376 31.4102 89.7026 28.0352 85.3149 26.5502C80.9273 25.0652 76.6072 26.0327 75.7072 28.7102C74.8072 31.3877 77.6423 34.7627 82.0299 36.2477Z"
      fill="#F47E3F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <defs>
      <clipPath id="clip0_209_12664">
        <rect
          width="99.6674"
          height="43.0705"
          fill="white"
          transform="translate(29.5776 0.853638) rotate(43.3107)"
        />
      </clipPath>
    </defs>
  </svg>
);
