import { PropsWithChildren, useRef } from "react";
import { getElementBoundingBoxFromPage } from "../../../utils/boundingRect.utils";
import { onFontReady } from "../../../utils/fonts.utils";
import { getFirstVisibleElement } from "../../../utils/html.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { styled } from "@linaria/react";

type Props = {
  className?: string;
};

const ReportV2PageContentWrap = styled.div`
  position: relative;
  z-index: 1;
`;

const ReportV2PageContent = (props: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);
  const sectionsRef = useRef<{ element: HTMLElement; offsetTop: number }[]>([]);
  const prevActiveSlugRef = useRef("");
  const highlightActiveSectionInSidebar = () => {
    const firstVisibleSection = getFirstVisibleElement(
      sectionsRef.current,
      +100
    );
    const headingSlug = firstVisibleSection?.getAttribute("id") ?? "";
    if (headingSlug !== prevActiveSlugRef.current) {
      prevActiveSlugRef.current = headingSlug;
      const activeLinkInTimeline = document.documentElement.querySelector(
        `aside [href$="#${headingSlug}"]`
      );
      if (activeLinkInTimeline) {
        Array.from(
          document.documentElement.querySelectorAll("aside a.current") ?? []
        ).forEach(a => a.classList.remove("current"));
        activeLinkInTimeline.classList.add("current");
      }
    }
  };

  useOnMount(() => {
    const handleScroll = () => {
      if (window.scrollY > 32) {
        highlightActiveSectionInSidebar();
      }
    };
    const handleResize = () => {
      const sections = Array.from(
        ref.current?.querySelectorAll<HTMLHeadingElement>(
          "section[id], header[id]"
        ) ?? []
      ).map(element => ({
        element,
        offsetTop: getElementBoundingBoxFromPage(element).top,
      }));
      sectionsRef.current = sections;
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    onFontReady(handleResize);
    setTimeout(handleResize, 1000);
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <ReportV2PageContentWrap ref={ref} className={props.className}>
      {props.children}
    </ReportV2PageContentWrap>
  );
};

export default ReportV2PageContent;
