import { colors } from "../../../styles/colors.styles";
import Spacing from "../../layout/Spacing";
import { NoBreak } from "../../typography/Nowrap";
import DonutChartV2 from "../components/DonutChartV2";
import ReportChapterSummary from "../components/ReportChapterSummary";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import ReportV2ContentCard, {
  ReportV2ContentCardGrid,
} from "../components/ReportV2ContentCard";
import ReportV2Section from "../components/ReportV2Section";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import ReportV2StatTable from "../components/ReportV2StatTable";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { ChampaignBottleIcon } from "./icons/ChampaignBottleIcon";
import { ControlPanelIcon } from "./icons/ControlPanelIcon";
import { PizzaIcon } from "./icons/PizzaIcon";
import { UnevenPlatformsIcon } from "./icons/UnevenPlatformsIcon";

const greenColorArray = [
  colors.green,
  colors.green400,
  colors.green300,
  colors.green200,
  colors.green100,
];

const greenColorArrayInverted = [...greenColorArray].reverse();

export const SOC2023Chapter4 = () => {
  return (
    <section id="chapter-4-automation-friend-or-foe">
      <ReportV2ChapterHeaderSection
        chapterNumber={4}
        largeChapterNumberStyle="dots"
        color="green"
        icon={<ControlPanelIcon />}
      >
        <h2>
          Automation — <span>friend or foe</span>
        </h2>
        <ReportV2TextBlock largerText>
          <p>
            Business leaders are focused on streamlining processes and achieving
            operational efficiencies. They’ve found an effective way to do so in
            automation. But how do they feel about automation? Are they
            embracing the technology?
          </p>
          <p>
            Nearly all security teams have already adopted automation to some
            extent. This is especially true in Ireland, where 44% of respondents
            said much of their work is automated, the highest percentage among
            the countries we surveyed.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2Section backgroundColor={colors.green100}>
        <ReportV2ContentCardGrid>
          <ReportV2ContentCard color={colors.green800}>
            <header>
              <h3>
                Nearly all security teams have already adopted automation to
                some extent
              </h3>
              <p>
                This is especially true in Ireland, where 44% of respondents
                said much of their work is automated, the highest percentage
                among the countries we surveyed.
              </p>
            </header>
            <figure>
              <h4>
                What best describes your team’s adoption of security automation
                tools?
              </h4>
              <DonutChartV2
                seoTitle="What best describes your team’s adoption of security automation tools?"
                header={["Statement", "Respondents"]}
                data={[
                  ["Much of our work is automated", 900 * 0.35],
                  ["Some of our work is automated", 900 * 0.57],
                  ["Very little of our work is automated today", 900 * 0.07],
                  ["None of our work is automated", 900 * 0.01],
                ]}
                colors={greenColorArray}
                textColor={colors.green800}
                accentColor={colors.green}
                donutPercentage={0.375}
                height={250}
              />
            </figure>
          </ReportV2ContentCard>

          <ReportV2ContentCard color={colors.green800}>
            <header>
              <h3>
                SOC teams are excited about automation’s effect on their lives
              </h3>
              <p>
                A stunning 93% of respondents agreed that automation at their
                workplace would improve their work/life balance.
              </p>
            </header>
            <figure>
              <h4>
                Please indicate whether you agree or disagree with the following
                statement: “Automation at my workplace will improve my work/life
                balance.”
              </h4>
              <DonutChartV2
                seoTitle="Please indicate whether you agree or disagree with the following statement: Automation at my workplace will improve my work/life balance."
                header={["Statement", "Respondents"]}
                data={[
                  ["Strongly agree", 900 * 0.56],
                  ["Somewhat agree", 900 * 0.37],
                  ["Neither agree nor disagree", 900 * 0.06],
                  ["Somewhat disagree", 900 * 0.01],
                  ["Strongly disagree", 900 * 0.0],
                ]}
                colors={greenColorArray}
                textColor={colors.green800}
                accentColor={colors.green}
                donutPercentage={0.375}
                height={250}
              />
            </figure>
          </ReportV2ContentCard>

          <ReportV2ContentCard color={colors.green800}>
            <header>
              <h3>
                25% spend more than half their time on tedious manual work
              </h3>
              <p>
                While tedious manual work is still an obstacle for security
                teams, they are making strides in this area. In 2022, 64% of
                surveyed analysts reported spending over half their time on such
                work. This year, that number fell to 25%.
              </p>
            </header>
            <figure>
              <h4>
                What percentage of your or your teams’ time would you describe
                as tedious manual work?
              </h4>
              <DonutChartV2
                seoTitle="What percentage of your or your teams’ time would you describe as tedious manual work?"
                header={["Percentage", "Respondents"]}
                data={[
                  ["< 10%", 900 * 0.08],
                  ["10–24%", 900 * 0.32],
                  ["25–49%", 900 * 0.35],
                  ["50–74%", 900 * 0.2],
                  ["75–100%", 900 * 0.05],
                ]}
                colors={greenColorArrayInverted}
                textColor={colors.green800}
                accentColor={colors.green}
                donutPercentage={0.375}
                height={250}
              />
            </figure>
          </ReportV2ContentCard>

          <ReportV2ContentCard color={colors.green800}>
            <header>
              <h3>56% fear automation will eliminate their job</h3>
              <p>
                More than half of respondents worry that automation will
                eliminate jobs in the near future. This figure is down from 69%
                last year, which may reflect a growing understanding of how jobs
                could evolve after automating manual tasks.
              </p>
            </header>
            <figure>
              <h4>
                How worried are you that automation will eliminate your job/your
                co-workers jobs in the near future?
              </h4>
              <DonutChartV2
                seoTitle="How worried are you that automation will eliminate your job/your co-workers jobs in the near future?"
                header={["Statement", "Respondents"]}
                data={[
                  ["Very worried", 900 * 0.12],
                  ["Worried", 900 * 0.17],
                  ["Somewhat worried", 900 * 0.27],
                  ["Not very worried", 900 * 0.31],
                  ["Not worried at all", 900 * 0.13],
                ]}
                colors={greenColorArrayInverted}
                textColor={colors.green800}
                accentColor={colors.green}
                donutPercentage={0.375}
                height={250}
              />
            </figure>
          </ReportV2ContentCard>

          <ReportV2ContentCard wide color={colors.green800} centeredOnDesktop>
            <header>
              <h3>
                SOC teams recognize automation could have an immediate impact
              </h3>
              <p>
                Only 3% of respondents believe less than 10% of security work
                could be automated by software currently available. More than a
                third said that half to all of the work could be done by today’s
                automation solutions.
              </p>
            </header>
            <figure>
              <h4>
                What percentage of your work do you believe could be
                done/automated by software that’s available today?
              </h4>
              <DonutChartV2
                seoTitle="What percentage of your work do you believe could be done/automated by software that’s available today?                "
                header={["Statement", "Respondents"]}
                data={[
                  ["< 10%", 900 * 0.03],
                  ["10–24%", 900 * 0.22],
                  ["25–49%", 900 * 0.36],
                  ["50–74%", 900 * 0.32],
                  ["75–100%", 900 * 0.07],
                ]}
                colors={greenColorArrayInverted}
                textColor={colors.green800}
                accentColor={colors.green}
                donutPercentage={0.375}
                height={250}
              />
            </figure>
          </ReportV2ContentCard>
        </ReportV2ContentCardGrid>
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.lightest}
        color={colors.green800}
      >
        <ReportV2SectionHeader icon={<UnevenPlatformsIcon />}>
          <ReportV2SectionHeadingMedium>
            Top five tasks SOC teams would automate to save time
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="green"
          borderColor={colors.green50}
          rowHighlightBorderColor={colors.green100}
          items={[
            {
              percentage: 17,
              heading: "Intelligence analysis",
              description:
                "The task SOC teams most want to automate would ensure alerts arrive with richer, more actionable context, saving valuable time spent tracking that information down manually.",
            },
            {
              percentage: 12,
              heading: "Threat hunting",
              description:
                "This would automate manual efforts to smoke out hackers or data that indicates a system may have been breached.",
            },
            {
              percentage: 11,
              heading: "Endpoint detection and response",
              description:
                "Survey respondents want to use automation to detect and investigate threats on endpoints.",
            },
            {
              percentage: 11,
              heading: "Risk assessments",
              description:
                "This was the top answer in last year's report. SOC teams would love to automate these assessments rather than manually monitoring for and triaging risk.",
            },
            {
              percentage: 10,
              heading: "Vulnerability management",
              description:
                "This process — identifying, assessing, reporting on, and remediating vulnerabilities — rounds out the top five.",
            },
            { percentage: 9, heading: "Advanced triage" },
            { percentage: 8, heading: "Email phishing" },
            { percentage: 8, heading: "Attack surface management" },
            { percentage: 6, heading: "Patching" },
            { percentage: 5, heading: "Initial triage" },
            { percentage: 4, heading: "Abuse response" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.green100}
        color={colors.green800}
      >
        <ReportV2SectionHeader icon={<ChampaignBottleIcon />}>
          <ReportV2SectionHeadingMedium>
            What would SOC teams do if parts of their work was automated?
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="green"
          items={[
            {
              percentage: 45,
              heading: "Research and evaluate new tools",
              description:
                "Respondents welcome the opportunity to spend more time finding the best tools for their teams.",
            },
            {
              percentage: 44,
              heading: "Develop advanced detection rules",
              description:
                "These rules help enhance an organization’s security posture and improve their key performance metrics.",
            },
            {
              percentage: 38,
              heading: "Integrate more systems and logs",
              description:
                "Bringing these sources of data together is a key proactive action with long-term performance benefits.",
            },
            {
              percentage: 37,
              heading: "Research TTPs more/intelligence",
              description:
                "Respondents want more time to dive deep on threat actor tactics, techniques, and procedures.",
            },
            {
              percentage: 37,
              heading: "Update reports and dashboards",
              description:
                "In the absence of manual work, SOC teams would focus on keeping reports and dashboards up-to-date. This indicates they are currently behind, and the first order of business after automation would be catching up.",
            },
            { percentage: 36, heading: "Update operational documentation" },
            {
              percentage: 36,
              heading:
                "Modify detection and alert rules to reduce false positive rates",
            },
            { percentage: 30, heading: "Threat hunt more" },
            { percentage: 1, heading: "Other" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.lightest}
        color={colors.green800}
      >
        <ReportV2SectionHeader icon={<PizzaIcon />}>
          <ReportV2SectionHeadingMedium>
            SOC teams and their tools
          </ReportV2SectionHeadingMedium>
          <Spacing />
          <p>
            Our respondents would love to spend more time researching and
            evaluating tools, rather than doing tedious tasks. On the subject of
            tools, they feel like they have access to the best available —
            probably because they are involved in the evaluation process. Three
            out of four respondents have found a security solution and added it
            to the organization’s toolkit.
          </p>
        </ReportV2SectionHeader>
        <Spacing size="xl" />
        <ReportV2ContentCardGrid>
          <ReportV2ContentCard
            backgroundColor={colors.green50}
            color={colors.green800}
            wide
            alwaysCentered
          >
            <h3>Do you have access to the best tools possible?</h3>
            <DonutChartV2
              seoTitle="Do you have access to the best tools possible?"
              header={["Choice", "Respondents"]}
              data={[
                ["Yes", 0.84],
                ["No", 0.16],
              ]}
              colors={[colors.green, colors.green300]}
              donutPercentage={0.375}
              height={250}
            />
          </ReportV2ContentCard>

          <ReportV2ContentCard
            backgroundColor={colors.green50}
            color={colors.green800}
            wide
            alwaysCentered
          >
            <h3>
              How involved are you in evaluating the software tools your team
              uses?
            </h3>
            <DonutChartV2
              seoTitle="How involved are you in evaluating the software tools your team uses?"
              header={["Statement", "Respondents"]}
              data={[
                [
                  "Very involved - we have a lot of influence over the process",
                  58,
                ],
                ["Involved", 26],
                ["Somewhat involved", 13],
                ["Not very involved", 3],
                ["Not involved at all - management just gives us the tools", 0],
              ]}
              colors={greenColorArray}
              textColor={colors.green800}
              accentColor={colors.green}
              donutPercentage={0.375}
              height={250}
            />
          </ReportV2ContentCard>
        </ReportV2ContentCardGrid>
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.green100}
        color={colors.green800}
      >
        <ReportChapterSummary>
          <p>
            In this section, we learned that nearly all security teams have
            already adopted automation — and they’re excited about its impact on
            work-life balance. We previously established that the SOC feels
            understaffed and overworked under a deluge of alerts and manual
            tasks. Now, we’ve identified the tasks that security professionals
            wish they could automate to save time, and the{" "}
            <NoBreak>high-impact</NoBreak> tasks — like researching new tools
            and developing advanced detection rules — that they would work on
            instead if automation was deployed to full effect.
          </p>
          <p>
            Automate the present tedium, and security teams can anticipate the
            threats of tomorrow. Given time, they could focus on protecting the
            business with insights from integrated systems and enhanced research
            and reporting capabilities.
          </p>
        </ReportChapterSummary>
      </ReportV2Section>
    </section>
  );
};
