import { styled } from "@linaria/react";
import { uptoPhoneLg } from "../../styles/breakpointsAndMediaQueries.styles";

const FromPhoneLg = styled.div`
  ${uptoPhoneLg} {
    display: none !important;
  }
`;

export const FromPhoneLgSpan = styled.span`
  ${uptoPhoneLg} {
    display: none !important;
  }
`;

export default FromPhoneLg;
