import { styled } from "@linaria/react";
import {
  ProductPageH3,
  ProductPageSubSection,
  ProductPageSubSectionP,
} from "./_ProductPageSharedComponents";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import connectAnythingIllo from "./illustrations/connectAnythingIllo.svg";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  DatoCmsLibraryTool,
  ProductPageConnectAnythingSubsectionQuery,
} from "../../../graphql-types";
import awsIcon from "../icons/misc/icon-aws.svg";
import { uniqBy } from "lodash-es";
import { moveItemToNewIndex } from "../../utils/array.utils";
import { useRef, useState } from "react";
import {
  fromDesktop,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { words } from "../../utils/string.utils";
import { GRID_COL_WIDTH_MAX } from "../../constants/globalGrid.constants";

const offsetX = (24 / 807) * 100;
const offsetY = (24 / 807) * 100;
const cellSizeX = (48 / 807) * 100;
const cellSizeY = (48.5 / 419) * 100;

const SplitThreeOne = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ${`"${words("figure", 9)} ${words("header", 3)}"`};
    header {
      grid-area: header;
    }
    figure {
      grid-area: figure;
      margin-right: ${GRID_COL_WIDTH_MAX * 0.5}px;
    }
  }
`;

const Figure = styled.figure`
  position: relative;
  margin: 0;
  border: 1px solid ${colors.purple300};
  border-radius: ${rSize("radius")};
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 807 / 419;
  overflow: hidden;
  > * {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0;
    left: 0;
    &:nth-child(1) {
      top: ${offsetY + cellSizeY * 2}%;
      left: ${offsetX + cellSizeX * 0}%;
    }
    &:nth-child(2) {
      top: ${offsetY + cellSizeY * 2}%;
      left: ${offsetX + cellSizeX * 5}%;
    }
    &:nth-child(3) {
      top: ${offsetY + cellSizeY * 1}%;
      left: ${offsetX + cellSizeX * 8}%;
    }
    &:nth-child(4) {
      top: ${offsetY + cellSizeY * 1}%;
      left: ${offsetX + cellSizeX * 13}%;
    }
    &:nth-child(5) {
      top: ${offsetY + cellSizeY * 3}%;
      left: ${offsetX + cellSizeX * 7}%;
    }
    &:nth-child(6) {
      top: ${offsetY + cellSizeY * 3}%;
      left: ${offsetX + cellSizeX * 11}%;
    }
    &:nth-child(7) {
      top: ${offsetY + cellSizeY * 3}%;
      left: ${offsetX + cellSizeX * 16}%;
    }
    &:nth-child(8) {
      top: ${offsetY + cellSizeY * 4}%;
      left: ${offsetX + cellSizeX * 3}%;
    }
    &:nth-child(9) {
      top: ${offsetY + cellSizeY * 5}%;
      left: ${offsetX + cellSizeX * 1}%;
    }
    &:nth-child(10) {
      top: ${offsetY + cellSizeY * 5}%;
      left: ${offsetX + cellSizeX * 14}%;
    }
    &:nth-child(11) {
      top: ${offsetY + cellSizeY * 6}%;
      left: ${offsetX + cellSizeX * 7}%;
    }
    &:nth-child(12) {
      top: ${offsetY + cellSizeY * 6}%;
      left: ${offsetX + cellSizeX * 12}%;
    }
    &:nth-child(13) {
      top: ${offsetY + cellSizeY * 7}%;
      left: ${offsetX + cellSizeX * 2}%;
    }
    &:nth-child(14) {
      top: ${offsetY + cellSizeY * 7}%;
      left: ${offsetX + cellSizeX * 14}%;
    }
  }
`;

const staticAwsToolDef = {
  id: "aws",
  slug: "aws",
  name: "AWS",
  image: {
    url: awsIcon,
  },
} as DatoCmsLibraryTool;

export const ConnectAnythingSubsection = () => {
  const data: ProductPageConnectAnythingSubsectionQuery = useStaticQuery(
    productPageConnectAnythingSubsectionQuery
  );
  const tools = uniqBy(
    [...((data.all?.nodes ?? []) as DatoCmsLibraryTool[])],
    t => t.image?.url
  );
  const aws = tools.find(t => t.slug === "aws");
  if (!aws) {
    tools.unshift();
    tools.push(staticAwsToolDef);
  }
  moveItemToNewIndex(tools, aws ?? staticAwsToolDef, 2, true);
  const toolBubbles = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ] as DatoCmsLibraryTool[][];
  tools.forEach((t, i) => toolBubbles[i % 14].push(t));
  const itemCount = Math.min(...toolBubbles.map(r => r.length));
  toolBubbles.forEach(r => (r.length = itemCount));
  return (
    <ProductPageSubSection>
      <SplitThreeOne>
        <header>
          <ProductPageH3>Connect anything</ProductPageH3>
          <ProductPageSubSectionP>
            If it offers an API, Tines connects with it. From mainstream
            solutions to niche and internal tools, Tines is an integrator across
            your entire stack.
          </ProductPageSubSectionP>
          <ProductPageSubSectionP>
            <Link to="/solutions/products">Learn more →</Link>
          </ProductPageSubSectionP>
        </header>
        <Figure
          style={{
            backgroundImage: `url(${connectAnythingIllo})`,
          }}
        >
          {toolBubbles.map((bubble, i) => (
            <ToolBubble key={i} tools={bubble} />
          ))}
        </Figure>
      </SplitThreeOne>
    </ProductPageSubSection>
  );
};

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple200};
  ${fromPhoneLg} {
    width: 42px;
    height: 42px;
  }
  ${fromTablet} {
    width: 52px;
    height: 52px;
  }
  ${fromTabletLg} {
    width: 46px;
    height: 46px;
  }
  ${fromDesktop} {
    width: 52px;
    height: 52px;
  }
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    width: auto;
    max-width: unset;
    max-height: 16px;
    opacity: 0;
    transition: 0.25s;
    ${fromTablet} {
      max-height: 20px;
    }
    ${fromDesktop} {
      max-height: 22px;
    }
    &.active {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  + span {
    margin-left: 0.75em;
  }
`;

const ToolBubble = (props: { tools: DatoCmsLibraryTool[] }) => {
  const [index, setIndex] = useState(0);
  const indexRef = useRef(index);
  indexRef.current = index;
  useOnMount(() => {
    const delay = Math.random() * 2 + 2;
    const interval = setInterval(() => {
      const newIndex = indexRef.current + 1;
      setIndex(newIndex >= props.tools.length ? 0 : newIndex);
    }, delay * 1000);
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <IconWrap>
      {props.tools.map((t, i) => (
        <img
          key={t.id}
          src={t.image?.url ?? ""}
          className={i === index ? "active" : "inactive"}
          style={{ aspectRatio: `${t.image?.width}/${t.image?.height}` }}
        />
      ))}
    </IconWrap>
  );
};

export const productPageConnectAnythingSubsectionQuery = graphql`
  query ProductPageConnectAnythingSubsection {
    all: allDatoCmsLibraryTool(limit: 56) {
      nodes {
        id: originalId
        name
        slug
        image {
          url
          width
          height
        }
      }
    }
  }
`;
