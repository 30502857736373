import { css } from "linaria";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

export const CourseIconBeginner = () => (
  <svg
    width="94"
    height="70"
    viewBox="0 0 94 70"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    className={css`
      --shadow-color: ${colorsV4.green700};
      ${darkModeLinariaCSS(`--shadow-color: ${colorsV4.warmBlack900};`)}
    `}
  >
    <path
      d="M92.6485 47.8855H34.3566L10.0743 68.8622H71.3722L92.6485 47.8855Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M88.5744 41.1885H30.282L6 62.1655V68.771H67.2982L88.5744 47.7939V41.1885Z"
      fill="#49B889"
      stroke="var(--shadow-color)"
    />
    <path
      d="M88.5744 41.1885H30.282L6 62.1655H67.2982L88.5744 41.1885Z"
      fill="#81D8B4"
      stroke="var(--shadow-color)"
    />
    <path d="M67.2981 68.771V62.1655" stroke="var(--shadow-color)" />
    <path
      d="M71.7096 46.1584H41.4004V54.8626H62.8778L71.7096 46.1584Z"
      fill="var(--shadow-color)"
      stroke="var(--shadow-color)"
    />
    <path
      d="M62.4839 24.6515H45.7152C45.378 24.6515 45.0706 24.7786 44.8377 24.9876C44.5708 25.2278 36.6295 32.9309 36.3926 33.1683C36.1547 33.4062 36.0082 33.7336 36.0082 34.0959V53.5513C36.0082 54.2758 36.5954 54.8628 37.3199 54.8628H54.0895C54.441 54.8628 54.7591 54.725 54.9951 54.5C55.246 54.2609 63.2275 46.5583 63.468 46.2861C63.6714 46.0554 63.7948 45.7517 63.7954 45.4187V25.9635C63.7954 25.2385 63.2087 24.6515 62.4839 24.6515Z"
      fill="#45BE8B"
      stroke="var(--shadow-color)"
    />
    <path
      d="M55.4015 53.5511C55.4015 54.2756 54.8137 54.8626 54.0895 54.8626H37.3199C36.5954 54.8626 36.0082 54.2756 36.0082 53.5511V34.0956C36.0082 33.3714 36.5954 32.7842 37.3199 32.7842H54.0895C54.8137 32.7842 55.4015 33.3714 55.4015 34.0956V53.5511Z"
      fill="#C0E9D8"
      stroke="var(--shadow-color)"
    />
    <path d="M55.0083 33.1602L63.477 25.1074" stroke="var(--shadow-color)" />
    <path
      d="M62.4839 14.8263H45.7152C45.378 14.8263 45.0706 14.9534 44.8377 15.1627C44.5708 15.4029 36.6295 23.106 36.3926 23.3431C36.1547 23.5811 36.0082 23.9084 36.0082 24.2707V31.7874C36.0082 32.5119 36.5954 33.0988 37.3199 33.0988H54.0895C54.441 33.0988 54.7591 32.9613 54.9951 32.736C55.246 32.497 63.2275 24.7946 63.468 24.5224C63.6714 24.2915 63.7948 23.988 63.7954 23.655V16.1383C63.7954 15.4136 63.2087 14.8263 62.4839 14.8263Z"
      fill="#EB994D"
      stroke="var(--shadow-color)"
    />
    <path
      d="M55.4015 31.787C55.4015 32.5115 54.8137 33.0985 54.0895 33.0985H37.3199C36.5954 33.0985 36.0082 32.5115 36.0082 31.787V24.2703C36.0082 23.5461 36.5954 22.9589 37.3199 22.9589H54.0895C54.8137 22.9589 55.4015 23.5461 55.4015 24.2703V31.787Z"
      fill="#FFBD59"
      stroke="var(--shadow-color)"
    />
    <path d="M55.0083 23.3352L63.477 15.2825" stroke="var(--shadow-color)" />
    <path
      d="M54.6017 0.5L46.7182 7.58412L36.4056 21.5527C35.8535 22.2052 36.5958 22.9977 37.3203 22.9977H54.0898C54.4518 22.9977 54.7797 22.8512 55.0171 22.6138C55.2547 22.3765 62.6634 15.7674 62.9008 15.53C63.1382 15.2926 63.2853 14.9647 63.2853 14.6024L54.6017 0.5Z"
      fill="#45BE8B"
      stroke="var(--shadow-color)"
    />
    <path
      d="M55.4015 21.6863C55.4015 22.4108 54.8137 22.9975 54.0895 22.9975H37.32C36.5955 22.9975 35.6024 22.5061 36.3049 21.4019L46.7179 7.58386L55.4015 21.6863Z"
      fill="#C0E9D8"
      stroke="var(--shadow-color)"
    />
  </svg>
);
