import { DatoCmsLibraryTool, DatoCmsPartner } from "../../graphql-types";

export const partnerMatchesTool = (
  partner: DatoCmsPartner,
  tool: DatoCmsLibraryTool
) => {
  if (!partner.name) return false;
  return (
    tool.name?.toLowerCase() === partner.name.toLowerCase() ||
    tool.slug === partner.slug
  );
};
