import { styled } from "@linaria/react";
import React, { useState } from "react";
import { DatoCmsEvent, EventByIdQuery } from "../../graphql-types";
import EventRegistrationForm from "../components/events/EventRegistrationForm";
import PageSection from "../components/reusableSections/PageSection";
import SEO from "../components/utilities/SEO";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import Spacing from "../components/layout/Spacing";
import StandardArticle from "../components/articles/StandardArticle";
import {
  fromTablet,
  fromTabletLg,
  uptoTablet,
} from "../styles/breakpointsAndMediaQueries.styles";
import Page from "./Page.template";
import { PageComponent } from "../types/gatsby.types";
import { graphql, navigate } from "gatsby";
import StandardArticleStructuredTextWithCustomBlocks from "../components/articles/StructuredTextWithCustomBlocks";
import ImageFrame from "../components/basic/ImageFrame";
import { ArticleLikePageTitleSerif } from "../components/articles/ArticleLikePageTitle";
import { eventIsOpenForRegistration } from "../utils/events.utils";
import InfoBox from "../components/general/InfoBox";
import { uniq, uniqBy } from "../utils/array.utils";
import { ArticleLikePageContent } from "../components/articles/ArticleLikePageContent";
import { asEventDirectoryItem } from "../../scripts/events";
import EventDirectoryItemEntry from "../components/events/EventDirectoryItemEntry";
import {
  EventDirectoryItem,
  EventDirectoryItemCategory,
} from "../types/helper.types";
import EventDirectoryItemAddressAndMap from "../components/events/EventDirectoryItemAddressAndMap";
import EventDirectoryItemDates from "../components/events/EventDirectoryItemDates";
import EventDirectoryItemLocation from "../components/events/EventDirectoryItemLocation";
import { rSize } from "../styles/responsiveSizes.styles";
import GlobalTimezoneSelect from "../components/forms/TimezoneSelect";
import { useOnMount } from "../utils/lifeCycle.utils";
import axios from "axios";
import LoadingIndicator from "../components/utilities/LoadingIndicator";
import { colorsV4 } from "../styles/colorsV4.styles";
import { reportErrorSilently } from "../utils/error.utils";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { Pill } from "../components/basic/Pill";
import { getBrandColorTheme } from "../styles/colors.styles";

export const EventPageHeader = styled.header`
  margin-bottom: 2em;
  ${fromTabletLg} {
    margin-bottom: 3.5em;
  }
  h1 {
    margin-top: 1em;
    margin-bottom: 1em;
    ${fromTabletLg} {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
    }
  }
`;

const CategoryList = styled.div`
  margin-top: -0.75em;
  > * {
    margin-top: 0.75em;
    margin-right: 0.75em;
  }
`;

const DateTimeGrid = styled.div`
  display: grid;
  ${fromTablet} {
    grid-template-columns: minmax(auto, 1fr) auto;
    grid-gap: 0.5em;
  }
`;

const EventDateTime = styled.p`
  font-weight: 600;
  ${fromTablet} {
    font-size: 2rem;
  }
  + span {
    margin-top: 0.5em;
  }
`;
const TimezoneSelectorWrap = styled.div`
  display: flex;
  align-items: baseline;
  ${uptoTablet} {
    padding: 0.5em 0;
  }
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
  label {
    font-weight: 600;
    text-align: right;
  }
`;

const MapAndRelatedEventsSection = styled.section`
  > * {
    + * {
      margin-top: ${rSize("xl")};
    }
  }
`;
const RelatedEventsSection = styled.section`
  h3 {
    font-size: 1.8rem;
  }
  > * {
    + * {
      margin-top: 0.75em;
    }
  }
`;

const Separator = styled.hr`
  border-top: 2px solid currentColor;
  opacity: 0.1;
  background-color: transparent;
  margin: 1.5em 0;
  ${fromTablet} {
    margin: 2.5em 0;
  }
`;

const LoadingIndicatorContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1.5em;
  background-color: ${colorsV4.canvas550};
  padding: 1em;
  ${fromTablet} {
    padding: 2em;
    border-radius: 2em;
  }
`;

const EventPageTemplate: PageComponent<{
  data: EventByIdQuery;
  pageContext: {
    id: string;
    eventAsDirItem: EventDirectoryItem;
  };
}> = props => {
  const [event, setEvent] = useState(props.data.event as DatoCmsEvent);

  const [fetchingEventInfo, setFetchingEventInfo] = useState(true);

  const { eventAsDirItem } = props.pageContext;
  const { place } = eventAsDirItem;
  const yesNoQuestions = uniq(event.yesOrNoQuestions?.split("\n") ?? []).filter(
    i => i.trim()
  );
  const openEndedQuestions = uniq(
    event.openEndedQuestions?.split("\n") ?? []
  ).filter(i => i.trim());
  const relatedEvents = uniqBy(
    [
      ...((props.data.event?.relatedEvents ?? []) as DatoCmsEvent[]),
      ...(props.data.relatedEvents.nodes as DatoCmsEvent[]),
    ],
    "id"
  );
  const relatedEventsAsDirItem = relatedEvents.map(e =>
    asEventDirectoryItem(e, "event")
  ) as EventDirectoryItem[];
  const viewRelatedEvent = (item: EventDirectoryItem) => {
    if (item.externalRegistrationLink)
      window.open(item.externalRegistrationLink);
    else navigate(`/events/${item.slug}`);
  };
  const isVirtual = !!event.categories?.find(c => c?.slug === "virtual");

  useOnMount(() => {
    const getLatestData = async () => {
      try {
        const upToDateEventObject = await axios.get<{
          event: DatoCmsEvent;
          relatedEvents: DatoCmsEvent[];
        }>(`/api/event?id=${props.pageContext.id}`);
        setEvent(upToDateEventObject.data.event);
      } catch (e) {
        reportErrorSilently(e);
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setFetchingEventInfo(false);
      }
    };
    getLatestData();
  });

  const theme = getBrandColorTheme(event.color);

  const bgColor = theme.c50;
  const textColor = theme.c800;
  const navTheme = theme.name === "dark" ? "purple" : theme.name;

  return (
    <Page
      {...props}
      browserThemeColor={bgColor}
      browserThemeColorDesktop={bgColor}
      backgroundColor={bgColor}
      theme={theme.name}
      navThemeColor={navTheme}
      textColor={textColor}
    >
      <SEO
        title={event.name || event.title || "Tines Event"}
        description={event.excerpt}
        image={event.coverImage?.url}
        noindex={!!event.discourageSearchEngineIndexing}
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="event"
        recordId={event.id}
      />
      <SiteNavHeightPlaceholder withSpacingTop />

      <Spacing size="sectionPadding" />

      <PageSection>
        <ArticleLikePageContent>
          <EventPageHeader>
            <CategoryList>
              {(event.categories as EventDirectoryItemCategory[])
                ?.filter(i => i)
                .map(category => (
                  <Pill allCaps key={category?.name}>
                    {category?.name}
                  </Pill>
                ))}
            </CategoryList>

            {event.hidePageHeading ? (
              <Spacing size="xl" />
            ) : (
              <ArticleLikePageTitleSerif>
                {event.title || event.name}
              </ArticleLikePageTitleSerif>
            )}

            {!eventIsOpenForRegistration(event) && (
              <>
                <InfoBox>
                  <p>
                    Registration for this event has closed. Thanks for your
                    interest.
                  </p>
                </InfoBox>
                <Spacing size="2em" />
              </>
            )}

            {event.coverImage && (
              <ImageFrame
                image={event.coverImage}
                borderRadius={rSize("radius")}
              />
            )}
          </EventPageHeader>

          {isVirtual && (
            <>
              <DateTimeGrid>
                <div>
                  <EventDateTime>
                    <EventDirectoryItemDates
                      item={eventAsDirItem}
                      showTimeOnSeparateLine={!place}
                    />
                  </EventDateTime>
                  {place && (
                    <EventDirectoryItemLocation item={eventAsDirItem} />
                  )}
                </div>
                <TimezoneSelectorWrap>
                  <label>Timezone</label>
                  <GlobalTimezoneSelect />
                </TimezoneSelectorWrap>
              </DateTimeGrid>
              <Separator />
            </>
          )}

          <StandardArticleStructuredTextWithCustomBlocks
            value={event.content}
          />

          <Spacing size="2em" />

          <StandardArticle>
            {fetchingEventInfo ? (
              <LoadingIndicatorContainer>
                <LoadingIndicator />
              </LoadingIndicatorContainer>
            ) : (
              <EventRegistrationForm
                event={event}
                heading={event.formHeading}
                collectAddress={event.collectAddress ?? false}
                streetFieldPlaceholder={event.streetFieldPlaceholder}
                yesNoQuestions={yesNoQuestions}
                openEndedQuestions={openEndedQuestions}
              />
            )}
          </StandardArticle>

          {(place || relatedEventsAsDirItem.length > 0) && (
            <>
              <Separator />
              <MapAndRelatedEventsSection>
                {place && (
                  <EventDirectoryItemAddressAndMap item={eventAsDirItem} />
                )}

                {relatedEventsAsDirItem.length > 0 && (
                  <RelatedEventsSection>
                    <h3>
                      You can also join us at the following event
                      {relatedEventsAsDirItem.length === 1 ? "" : "s"}:
                    </h3>
                    <Spacing size=".5em" />
                    {relatedEventsAsDirItem.map(e => (
                      <EventDirectoryItemEntry
                        item={e}
                        key={e.id}
                        landscape
                        onSelect={viewRelatedEvent}
                      />
                    ))}
                  </RelatedEventsSection>
                )}
              </MapAndRelatedEventsSection>
            </>
          )}
        </ArticleLikePageContent>
      </PageSection>

      <Spacing size="sectionPadding" />
    </Page>
  );
};

export const eventByIdQuery = graphql`
  query EventById($id: String) {
    event: datoCmsEvent(originalId: { eq: $id }) {
      id: originalId
      name
      hidePageHeading
      title
      slug
      timeStart
      timeEnd
      timeRegistrationEnd
      registrationOpen
      excerpt
      color
      formHeading
      requirePhone
      yesOrNoQuestions
      openEndedQuestions
      collectAddress
      streetFieldPlaceholder
      includeTinesCustomerSatisfactionQuestion
      includeTinesTShirtSizeSelector
      street
      city
      state
      country
      externalRegistrationLink
      discourageSearchEngineIndexing
      categories {
        name
        color
      }
      relatedEvents {
        id: originalId
        name
        title
        slug
        excerpt
        timeStart
        timeEnd
        street
        city
        state
        country
        featured
        externalRegistrationLink
        shouldCreateLandingPage
        coverImage {
          url
        }
        categories {
          id
          slug
          name
          color
        }
      }
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              width
              height
              url
            }
            caption
            link
            altTag
            showInFullPageWidth
            applyBorder
          }
          ... on DatoCmsPullQuote {
            id: originalId
            content
            source
            color
          }
          ... on DatoCmsVideoBlock {
            id: originalId
            title
            url
            videoFile {
              video {
                mp4Url
                streamingUrl
                thumbnailUrl
              }
              width
              height
              basename
              alt
              url
            }
            autoplay
            loop
          }
          ... on DatoCmsIframeEmbed {
            id: originalId
            src
            title
            aspectRatio
            aspectRatioMobile
            height
            heightMobile
          }
          ... on DatoCmsCustomHtmlBlock {
            id: originalId
            htmlContent
          }
          ... on DatoCmsChecklistBlock {
            id: originalId
            customIcon
            color
            listContent {
              value
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            heading
            spotIllustration {
              url
            }
            themeColor
            buttonLabel
            buttonLink
          }
          ... on DatoCmsStoryEmbed {
            id: originalId
            story {
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            storyUrl
            pillText
            fullWidth
            overrideColor
            utmCampaign
            utmSource
            utmMedium
          }
          ... on DatoCmsHtmlWysiwygBlock {
            id: originalId
            content
          }
          ... on DatoCmsCalloutBanner {
            id: originalId
            content
            calloutType
          }
        }
      }
      coverImage {
        url
        width
        height
      }
    }
    relatedEvents: allDatoCmsEvent(
      filter: { relatedEvents: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        id: originalId
        name
        title
        slug
        excerpt
        timeStart
        timeEnd
        street
        city
        state
        country
        featured
        color
        externalRegistrationLink
        shouldCreateLandingPage
        coverImage {
          url
          width
          height
        }
        categories {
          id
          slug
          name
          color
        }
      }
    }
  }
`;

export default EventPageTemplate;
