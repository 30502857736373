import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { cx } from "linaria";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { colors } from "../../styles/colors.styles";
import { TreeBreadcrumbLevel } from "../../utils/tree.utils";
import { ChevronIconRight } from "../icons/misc/ChevronIconRight";
import LinkToAnchor from "./LinkToAnchor";

type Props = {
  levels: TreeBreadcrumbLevel[];
  highlightActiveLinks?: boolean;
  padItems?: boolean;
  openInNewTab?: boolean;
  renderAs?: "links" | "buttons" | "text";
};

export const BreadCrumbsWrap = styled.nav`
  font-weight: 500;
  > * {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    margin-right: 0.325em;
    min-height: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
  button {
    appearance: none;
    background-color: transparent;
    font: inherit;
    color: inherit;
    text-align: inherit;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
  &.paddedLinks {
    a,
    span,
    button {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
  code {
    font-size: 90%;
  }
`;

const LinkWithChevron = styled.div`
  > * {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.375em;
    &:last-child {
      margin-right: 0;
    }
  }
  a,
  span,
  button {
    text-decoration: none;
    opacity: 0.7;
  }
  a,
  button {
    &:hover {
      color: var(--ac, ${colors.purple});
      opacity: 1;
    }
    &.active {
      color: var(--ac, ${colors.purple});
      opacity: 1;
    }
  }
  svg {
    opacity: 0.5;
  }
`;

const BreadCrumbs = (props: Props) => {
  const { levels = [] } = props;
  return (
    <BreadCrumbsWrap className={cx(props.padItems && "paddedLinks")}>
      {levels.map((level, i) => {
        const RenderLink = () => {
          const sharedAttributes = {
            children: level.titleNode ?? level.title,
          };
          return props.openInNewTab ? (
            <a href={level.path} {...externalLinkAttr} {...sharedAttributes} />
          ) : level.path.includes("#") ? (
            <LinkToAnchor to={level.path} {...sharedAttributes} />
          ) : (
            <Link
              to={level.path}
              {...sharedAttributes}
              activeClassName={
                props.highlightActiveLinks ? "active" : undefined
              }
            />
          );
        };
        const RenderItem = () => {
          switch (props.renderAs) {
            case "text":
              return <span>{level.title}</span>;
            case "buttons":
              return (
                <button
                  onClick={e => {
                    window.open(
                      level.path,
                      props.openInNewTab || e.shiftKey || e.metaKey || e.ctrlKey
                        ? "_blank"
                        : undefined
                    );
                  }}
                >
                  {level.title}
                </button>
              );
            case "links":
            default:
              return <RenderLink />;
          }
        };
        return (
          <LinkWithChevron key={i}>
            <RenderItem />
            {i !== levels.length - 1 && <ChevronIconRight />}
          </LinkWithChevron>
        );
      })}
    </BreadCrumbsWrap>
  );
};

export default BreadCrumbs;
