import { Link } from "gatsby";
import React, { CSSProperties, PropsWithChildren } from "react";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { runAfter } from "../../utils/promises.utils";

type Props = PropsWithChildren<{
  to: string;
  title?: string;
  className?: string;
  onClick?: (e?: React.MouseEvent) => void;
  onScrollComplete?: (element?: HTMLElement | null) => void;
  style?: CSSProperties;
  offsetY?: number;
  doNotPushState?: boolean;
}>;

const LinkToAnchor = (props: Props) => {
  const path = /^([^#]*)/.exec(props.to)?.[1];
  const hash = /(#[^#]*)/.exec(props.to)?.[1];
  const handleClick = (e: React.MouseEvent) => {
    if (!path) e.preventDefault();
    props.onClick?.(e);
    window.dispatchEvent(
      new CustomEvent("beforehashchange", { detail: { hash } })
    );
    // run after next tick in case some UI needs to respond to hash changes
    runAfter(() =>
      scrollToHash({
        useHash: hash,
        onScrollComplete: props.onScrollComplete,
        easeIn: !!path,
        offsetY: props.offsetY,
        doNotPushState: props.doNotPushState ?? false,
      })
    );
  };
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { doNotPushState, offsetY, onScrollComplete, ...propsToPass } = props;
  return (
    <Link {...propsToPass} onClick={handleClick}>
      {props.children}
    </Link>
  );
};

export default LinkToAnchor;
