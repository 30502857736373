import { styled } from "@linaria/react";
import {
  ProductPageH3,
  ProductPageSubSection,
  ProductPageSubSectionP,
} from "./_ProductPageSharedComponents";
import { colors } from "../../styles/colors.styles";
import aiActionIllo from "./illustrations/aiActionIllo.svg";
import automaticTransformIllo from "./illustrations/automaticTransformIllo.svg";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktop,
  fromDesktopMd,
  fromPhoneLg,
  mediaFromTo,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { Link } from "gatsby";

const Layout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 3em;
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    > * {
      &:first-child {
        grid-column-end: span 2;
      }
    }
  }
  ${fromDesktop} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: ${rSize("gap")};
    > * {
      grid-column-end: span 4;
      &:first-child {
        grid-column-end: span 4;
      }
    }
  }
  ${fromDesktopMd} {
    header {
      padding-right: 2em;
    }
  }
`;

const Lists = styled.div`
  display: inline-grid;
  grid-gap: 1em;
  ${mediaFromTo("tabletMd", "desktop")} {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 0.5em;
  }
  grid-template-columns: auto auto;
  margin-top: 2em;
  ${fromDesktopMd} {
    grid-gap: 1em 2em;
  }
  ul {
    list-style: none;
    font-weight: 500;
    font-size: 1.4rem;
    ${fromDesktop} {
      font-size: 1.3rem;
    }
    ${fromDesktopMd} {
      font-size: 1.4rem;
    }
    margin: 0;
    padding: 0;
    ${mediaFromTo("tabletMd", "desktop")} {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    li {
      + li {
        margin-top: 0.75em;
        ${mediaFromTo("tabletMd", "desktop")} {
          margin-top: 0;
        }
      }
      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.5em;
        transform: translateY(-0.1em);
      }
    }
  }
`;

const GreenList = styled.ul`
  li {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23B0E0BD' stroke='%2387D1A3'/%3E%3Cpath d='M5.5 9L8 11.5L12.5 6' stroke='%23239667' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
`;

const RedList = styled.ul`
  li {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23FFC8A3' stroke='%23FFAF7E'/%3E%3Cpath d='M12 6L6 12M6 6L12 12' stroke='%23E16521' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
`;

const Figure = styled.figure`
  margin: 0;
  figcaption {
    margin-top: 1em;
    font-size: 1.4rem;
    p {
      font-size: inherit;
      line-height: 1.35;
    }
  }
`;

const IllustrationWrap = styled.div`
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple300};
  border-radius: 1.2rem;
  overflow: hidden;
  img {
    display: block;
    aspect-ratio: 358 / 254;
    width: 100%;
  }
  &.dark {
    background-color: ${colors.purple700};
    border-color: ${colors.purple900};
  }
`;

export const AISubsection = () => (
  <ProductPageSubSection>
    <Layout>
      <header>
        <ProductPageH3>AI in Tines</ProductPageH3>
        <ProductPageSubSectionP>
          <Link to="/product/ai" style={{ color: colors.purple600 }}>
            AI in Tines
          </Link>{" "}
          is private and secure by design. Your data stays within your tenant.
          When and how your data interacts with AI is defined by you within the
          workflow.
        </ProductPageSubSectionP>
        <Lists>
          <GreenList>
            <li>Stateless</li>
            <li>Private</li>
            <li>In-region</li>
            <li>Tenant-scoped</li>
          </GreenList>
          <RedList>
            <li>No networking</li>
            <li>No training</li>
            <li>No storage</li>
            <li>No logging</li>
          </RedList>
        </Lists>
      </header>
      <Figure>
        <IllustrationWrap>
          <img src={aiActionIllo} />
        </IllustrationWrap>
        <figcaption>
          <p>
            The <strong>AI action</strong> brings the power of a secure and
            private LLM to your workflows as they run. Data never leaves your
            tenant.
          </p>
        </figcaption>
      </Figure>
      <Figure>
        <IllustrationWrap className="dark">
          <img src={automaticTransformIllo} />
        </IllustrationWrap>
        <figcaption>
          <p>
            <strong>Automatic transform</strong> leverages AI to generate code
            from your prompts, accelerating your ability to build robust data
            manipulation into your workflows.
          </p>
        </figcaption>
      </Figure>
    </Layout>
  </ProductPageSubSection>
);
