export const TorchIcon = () => (
  <svg
    width="83"
    height="140"
    viewBox="0 0 83 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.5743 8.93639C46.0497 8.93639 49.6778 7.06861 49.6778 4.76458C49.6778 2.46055 46.0497 0.592773 41.5743 0.592773C37.0988 0.592773 33.4708 2.46055 33.4708 4.76458C33.4708 7.06861 37.0988 8.93639 41.5743 8.93639Z"
      fill="#8173E6"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M30.936 49.2926C30.936 52.3175 35.6985 54.7698 41.574 54.7698C47.4503 54.7698 52.2132 52.3175 52.2132 49.2926L50.5365 8.10546C50.5365 5.55703 46.5233 3.49121 41.574 3.49121C36.6243 3.49121 32.612 5.55703 32.612 8.10546L30.936 49.2926Z"
      fill="#8173E6"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M41.5746 54.7708C47.4501 54.7708 52.2132 52.3186 52.2132 49.2936C52.2132 46.2686 47.4501 43.8164 41.5746 43.8164C35.699 43.8164 30.936 46.2686 30.936 49.2936C30.936 52.3186 35.699 54.7708 41.5746 54.7708Z"
      fill="#8173E6"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M39.6727 3.83789L38.9901 44.0002"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M35.675 4.8418L34.407 44.9037"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M43.4757 3.83789L44.158 44.0002"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M47.4742 4.8418L48.7419 44.9037"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M44.4969 26.6607C44.4969 28.3233 43.1881 29.6707 41.5738 29.6707C39.9589 29.6707 38.6507 28.3233 38.6507 26.6607V18.9895C38.6507 17.3272 39.9589 15.9795 41.5738 15.9795C43.1881 15.9795 44.4969 17.3272 44.4969 18.9895V26.6607Z"
      fill="#8173E6"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M41.5742 28.4497C42.9659 28.4497 44.0942 27.2879 44.0942 25.8547C44.0942 24.4216 42.9659 23.2598 41.5742 23.2598C40.1824 23.2598 39.0541 24.4216 39.0541 25.8547C39.0541 27.2879 40.1824 28.4497 41.5742 28.4497Z"
      fill="#49B889"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M41.5742 23.3296C42.9659 23.3296 44.0942 22.1678 44.0942 20.7346C44.0942 19.3015 42.9659 18.1396 41.5742 18.1396C40.1824 18.1396 39.0541 19.3015 39.0541 20.7346C39.0541 22.1678 40.1824 23.3296 41.5742 23.3296Z"
      fill="#B8E5D2"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M0.868774 123.995C0.868774 132.224 18.9794 138.896 41.3201 138.896C63.6616 138.896 81.7723 132.224 81.7723 123.995L59.2803 67.2038C58.1266 64.1381 51.2394 61.3398 41.3204 61.3398C31.4019 61.3398 24.5729 63.7979 23.3619 67.2038L0.868774 123.995Z"
      fill="#FFE1B3"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M59.4355 67.2038C59.4355 70.4424 51.3953 73.0678 41.4759 73.0678C31.5575 73.0678 23.5172 70.4424 23.5172 67.2038C23.5172 63.9653 31.5575 61.3398 41.4759 61.3398C51.3953 61.3398 59.4355 63.9653 59.4355 67.2038Z"
      fill="#FFD18C"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M59.5339 67.2043C59.5339 70.4428 51.4937 73.0682 41.5743 73.0682C31.6559 73.0682 23.6159 70.4428 23.6159 67.2043C23.6159 67.2043 24.9365 46.1279 41.5743 46.1279C58.2133 46.1282 59.5339 67.2043 59.5339 67.2043Z"
      fill="#8173E6"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M81.9281 123.995C81.9281 132.224 63.8178 138.895 41.4759 138.895C19.1349 138.895 1.0246 132.224 1.0246 123.995C1.0246 115.765 19.1349 109.094 41.4759 109.094C63.8178 109.094 81.9281 115.765 81.9281 123.995Z"
      fill="#FFD18C"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M59.4355 67.2038C59.4355 70.4424 51.3953 73.0678 41.4759 73.0678C31.5575 73.0678 23.5172 70.4424 23.5172 67.2038C23.5172 63.9653 31.5575 61.3398 41.4759 61.3398C51.3953 61.3398 59.4355 63.9653 59.4355 67.2038Z"
      fill="#FABE64"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M41.4768 129.243C50.8315 129.243 58.4149 126.665 58.4149 123.486C58.4149 120.306 50.8315 117.729 41.4768 117.729C32.1222 117.729 24.5388 120.306 24.5388 123.486C24.5388 126.665 32.1222 129.243 41.4768 129.243Z"
      fill="#4C1812"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M26.5305 126.194C29.3814 124.381 35.0039 123.147 41.4759 123.147C47.9489 123.147 53.5717 124.381 56.4229 126.194C57.6927 125.387 58.4146 124.465 58.4146 123.486C58.4146 120.306 50.8312 117.729 41.4759 117.729C32.1216 117.729 24.5388 120.306 24.5388 123.486C24.5388 124.465 25.2601 125.387 26.5305 126.194Z"
      fill="#EB81AF"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M35.8843 61.7837C35.777 62.2368 35.7135 62.7081 35.7135 63.1956C35.7135 66.4728 38.2929 69.1292 41.4758 69.1292C44.6583 69.1292 47.2386 66.4728 47.2386 63.1956C47.2386 62.7081 47.1748 62.2371 47.0675 61.7837C45.3069 61.5956 43.429 61.4922 41.4758 61.4922C39.5225 61.4922 37.6446 61.5953 35.8843 61.7837Z"
      fill="#FFE1B3"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M41.5746 73.3714C37.0845 73.3714 32.8482 72.8389 29.6459 71.8721C25.8954 70.7396 23.9936 69.1688 23.9936 67.2034C23.9936 65.2377 25.8954 63.667 29.6459 62.5344C32.8482 61.5677 37.0845 61.0352 41.5746 61.0352C46.0641 61.0352 50.3016 61.5677 53.504 62.5344C57.2547 63.667 59.1569 65.2377 59.1569 67.2034C59.1569 69.1691 57.2547 70.7399 53.504 71.8721C50.3016 72.8389 46.0644 73.3714 41.5746 73.3714ZM41.5746 63.4692C31.5347 63.4692 26.3576 66.0864 26.3576 67.2031C26.3576 68.3196 31.535 70.9371 41.5746 70.9371C51.6154 70.9371 56.7929 68.3199 56.7929 67.2031C56.7929 66.0864 51.6157 63.4692 41.5746 63.4692Z"
      fill="#B0A6F9"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8271 69.2299C29.8271 69.2299 29.6637 65.5292 33.9109 64.1836C33.9109 64.1836 27.2161 65.0949 26.3574 67.2046L29.8271 69.2299Z"
      fill="#4C1812"
    />
    <path
      d="M25.2336 59.5124C28.1106 57.5269 34.3019 56.1533 41.4755 56.1533C49.0666 56.1533 55.557 57.6909 58.1854 59.8641"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
  </svg>
);
