import { getDynamicTenantUrl } from "../../../utils/apiPage.utils";

export default function AccessingTheAPI(props: {
  shouldInterpolateDynamicTenantUrl: boolean;
}) {
  const dynamicTenantUrl = getDynamicTenantUrl();

  if (props.shouldInterpolateDynamicTenantUrl && dynamicTenantUrl) {
    return (
      <p>
        The REST API is served directly by your own tenant:
        <code>https://{dynamicTenantUrl}/api/v1/&lt;api-endpoint&gt;</code>.
      </p>
    );
  }

  return (
    <>
      <p>
        The REST API takes the form of the following URL:
        <code>https://&lt;tenant-domain&gt;/api/v1/&lt;api-endpoint&gt;</code>
      </p>
      <p>
        For <code>&lt;tenant-domain&gt;</code>, you can copy the domain that's
        visible in your web browser when you're navigating our UI. For our cloud
        users this will be similar to either{" "}
        <code>adjective-noun-1234.tines.io</code> or
        <code>adjective-noun-1234.tines.com</code>.
      </p>
    </>
  );
}
