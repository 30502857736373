import { styled } from "@linaria/react";
import { fromPhoneLg } from "../../styles/breakpointsAndMediaQueries.styles";

const UptoPhoneLg = styled.div`
  ${fromPhoneLg} {
    display: none !important;
  }
`;

export const UptoPhoneLgSpan = styled.span`
  ${fromPhoneLg} {
    display: none !important;
  }
`;

export default UptoPhoneLg;
