import { Link } from "gatsby";
import { DatoCmsArticle } from "../../../graphql-types";
import { Paths } from "../../utils/pathBuilders.utils";
import ArticleEntryLink from "../articles/ArticleEntryLink";
import ContentCardGeneric from "../contentCards/ContentCardGeneric";
import AllCaps from "../typography/AllCaps";
import CustomerCenterPageSection from "./CustomerCenterPageSection";
import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromDesktopSm,
  fromPhoneLg,
  fromTabletLg,
  fromTabletMd,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import whatsNewIcon from "./illos/CustomerCenterWhatsNewIcon.svg";
import Spacing from "../layout/Spacing";
import { YouTubeEmbed } from "../blocks/VideoBlock.block";
import { css } from "linaria";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  ${fromTabletLg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const ProductUpdatesCard = styled.div`
  grid-column-end: span 2;
  background-color: ${colors.light100};
  border: 1px solid ${colors.light400};
  border-radius: 8px;
  padding: 0.875em;
  ${fromPhoneLg} {
    padding: 2.4rem;
  }
  ${fromTabletMd} {
    padding: 2.4rem 2.8rem 2.4rem;
  }
  ${fromDesktopMd} {
    padding: 3rem 3.6rem 3.2rem;
  }
  > header {
    align-items: end;
  }
`;

const whatsNewCard = css`
  ${uptoTabletLg} {
    &.large {
      grid-column-end: span 2;
      min-height: 30rem;
    }
  }
`;

const CardHeader = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  padding-bottom: 1.5em;
  > div {
    > * {
      + * {
        margin-top: 0.5rem;
        ${fromPhoneLg} {
          margin-top: 0.75rem;
        }
      }
    }
  }
  > a {
    font-weight: 600;
    font-size: 1.4rem;
    text-decoration: none;
    &:after {
      display: inline-block;
      vertical-align: baseline;
      content: "→";
      transition: 0.2s;
      margin-left: 0.25em;
    }
    &:hover {
      &:after {
        transform: translateX(3px);
      }
    }
  }
  h3 {
    font-weight: 400;
    font-family: ${serif};
    line-height: 1.1;
    font-size: 2.2rem;
    margin-right: 0;
    ${fromTabletMd} {
      font-size: 2.8rem;
    }
    ${fromDesktopSm} {
      font-size: 2.6rem;
    }
    ${fromDesktopMd} {
      font-size: 2.8rem;
    }
  }
`;

const ProductUpdatesCardArticleGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  h4 {
    font-size: 1.6rem;
  }
`;

const FeaturedUpdateCard = styled.div`
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple200};
  border-radius: 8px;
  padding: 0.875em;
  ${fromPhoneLg} {
    padding: 2.4rem;
  }
  ${fromTabletMd} {
    padding: 2.4rem 2.8rem 2.4rem;
  }
  ${fromDesktopMd} {
    padding: 3rem 3.6rem 3.2rem;
  }
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    align-items: stretch;
    grid-template-columns: 214px minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-areas: "header video" "footer video";
    > header {
      grid-area: header;
    }
  }
`;

const FeaturedUpdateCardVideoFrame = styled.div`
  ${fromTabletLg} {
    grid-area: video;
  }
  > * {
    width: 100%;
    aspect-ratio: 1280 / 720;
    border-radius: 8px;
    border: 1px solid ${colors.purple300};
  }
`;

const FeaturedUpdateCardFooter = styled.div`
  ${fromTabletLg} {
    grid-area: footer;
  }
  a {
    font-weight: 600;
    font-size: 1.4rem;
    text-decoration: none;
    &:after {
      display: inline-block;
      vertical-align: baseline;
      content: "→";
      transition: 0.2s;
      margin-left: 0.25em;
    }
    &:hover {
      &:after {
        transform: translateX(3px);
      }
    }
  }
`;

export const CustomerCenterProductUpdatesSection = (props: {
  productUpdates: DatoCmsArticle[];
}) => (
  <CustomerCenterPageSection title="Product updates" id="product-updates">
    <Grid>
      <ProductUpdatesCard>
        <CardHeader>
          <div>
            <AllCaps as="div">Updates in detail</AllCaps>
            <h3>From the blog</h3>
          </div>
          <Link to={Paths.blogCategory("product-updates")}>See all</Link>
        </CardHeader>
        <ProductUpdatesCardArticleGrid>
          {props.productUpdates.map(u => (
            <ArticleEntryLink
              key={u.id}
              record={u}
              headingLevel="h4"
              noDescription
            />
          ))}
        </ProductUpdatesCardArticleGrid>
      </ProductUpdatesCard>
      <ContentCardGeneric
        large
        className={whatsNewCard}
        overrides={{
          preHeadingTag: "Product",
          heading: "What’s new",
          description: "Discover our latest updates and releases.",
          linkLabel: "Read updates",
          linkUrl: Paths.whatsNew(),
          color: "pink",
          icon: {
            url: whatsNewIcon,
          },
          size: "1/3",
        }}
      />
    </Grid>
    <Spacing size="gap" />
    <FeaturedUpdateCard>
      <CardHeader>
        <div>
          <AllCaps as="div">AI CHAT MEETs tines</AllCaps>
          <h3>Workbench</h3>
          <p>Watch this demo to learn about our latest product, Workbench.</p>
        </div>
      </CardHeader>
      <FeaturedUpdateCardVideoFrame>
        <YouTubeEmbed id="z8-sm7Hf0rU" />
      </FeaturedUpdateCardVideoFrame>
      <FeaturedUpdateCardFooter>
        <Link to={Paths.workbench()}>Learn more</Link>
      </FeaturedUpdateCardFooter>
    </FeaturedUpdateCard>
  </CustomerCenterPageSection>
);
