import { css, cx } from "@linaria/core";
import GartnerBadgeImage from "../../images/gartner-cool-vendor-2021.png";

const GartnerBadge = (props: {
  className?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) => (
  <img
    src={GartnerBadgeImage}
    title="Gartner Cool Vendor 2021 Badge"
    className={cx(
      props.className,
      css`
        mix-blend-mode: multiply;
        aspect-ratio: 225 / 202;
        max-width: 100%;
        height: auto;
      `
    )}
    style={props.style}
    width={props.width ?? 225}
  />
);

export default GartnerBadge;
