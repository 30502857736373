import { uniq } from "lodash-es";
import { ComputerTwoToneIcon } from "../../icons/twoTone/ComputerTwoToneIcon";
import { FlagTwoToneIcon } from "../../icons/twoTone/FlagTwoToneIcon";
import { HatchingDinoEggTwoToneIcon } from "../../icons/twoTone/HatchingDinoEggTwoToneIcon";
import { LoadedSkateboardTwoToneIcon } from "../../icons/twoTone/LoadedSkateboardTwoToneIcon";
import { MagicCrystalBallTwoToneIcon } from "../../icons/twoTone/MagicCrystalBallTwoToneIcon";
import { ShieldTwoToneIcon } from "../../icons/twoTone/ShieldTwoToneIcon";
import { GolfFlagSetIcon } from "../spotIcons/GolfFlagSetIcon";
import { HatchingDinoEggIcon } from "../spotIcons/HatchingDinoEggIcon";
import { LoadedSkateboardIcon } from "../spotIcons/LoadedSkateboardIcon";
import { MagicCrystalBallIcon } from "../spotIcons/MagicCrystalBallIcon";
import { ShieldOnFireIcon } from "../spotIcons/ShieldOnFireIcon";
import { VintagePCIcon } from "../spotIcons/VintagePCIcon";

const sharedUseCases = [
  "Automate processes",
  "ChatOps",
  "Dynamic integrations",
  "Lifecycle management",
  "Alert management",
  "Build apps & APIs",
  "AI-powered workflows",
  "Self-service portals",
];
const securityUseCases = [
  "Vulnerability management",
  "Endpoint detection and response",
  "Threat intelligence enrichment",
  "Compliance",
  "Incident response",
  "System Integration",
  "Phishing attack response",
  "Risk management",
  "Identity and access management",
  "SOAR",
];
const ITUseCases = [
  "Employee onboarding",
  "Employee offboarding",
  "Shift management",
  "Asset management",
  "Application management",
  "Software maintenance",
  "Hardware maintenance",
  "Network security",
  "Incident management",
  "Enrich, triage, and consolidate alerts",
  "Cost management",
  "Risk management",
  "Automated responses",
  "Data and system integration",
];
const infrastructureUseCases = [
  "Asset management",
  "Application management",
  "System integration",
  "Network management",
];
const engineeringUseCases = [
  "Application security",
  "Software development lifecycle",
];
const productUseCases = ["Software development lifecycle"];

export const homeFindYourFlowSectionTeams = [
  {
    name: "All teams",
    icon: <FlagTwoToneIcon />,
    spotIcon: <GolfFlagSetIcon />,
    spotIconOffsetLeft: -5,
    spotIconOffsetLeftMobile: 0,
    description:
      "Operate more effectively, mitigate risk, reduce tech debt, and do the work that matters most.",
    useCases: uniq([
      ...sharedUseCases,
      ...securityUseCases,
      ...ITUseCases,
      ...infrastructureUseCases,
      ...engineeringUseCases,
      ...productUseCases,
    ]),
  },
  {
    name: "Security",
    icon: <ShieldTwoToneIcon />,
    spotIcon: <ShieldOnFireIcon />,
    spotIconOffsetLeft: 0,
    spotIconOffsetLeftMobile: 3.5,
    description:
      "Break away from traditional SOAR. Tines is scalable for the enterprise and accessible for the whole team.",
    useCases: [...sharedUseCases, ...securityUseCases],
  },
  {
    name: "IT",
    icon: <ComputerTwoToneIcon />,
    spotIcon: <VintagePCIcon />,
    spotIconOffsetLeft: -1,
    spotIconOffsetLeftMobile: 1,
    description:
      "Move faster to reduce your backlog and create transparency across your organization.",
    useCases: [...sharedUseCases, ...ITUseCases],
  },
  {
    name: "Infrastructure",
    icon: <LoadedSkateboardTwoToneIcon />,
    spotIcon: <LoadedSkateboardIcon />,
    spotIconOffsetLeft: -2,
    spotIconOffsetLeftMobile: 0,
    description:
      "Build workflows to connect your systems, and create consistency and transparency as you respond to requests.      ",
    useCases: [...sharedUseCases, ...infrastructureUseCases],
  },
  {
    name: "Engineering",
    icon: <MagicCrystalBallTwoToneIcon />,
    spotIcon: <MagicCrystalBallIcon />,
    spotIconOffsetLeft: -3,
    spotIconOffsetLeftMobile: 0,
    description:
      "Manage and monitor your software development with automated workflows.",
    useCases: [...sharedUseCases, ...engineeringUseCases],
  },
  {
    name: "Product",
    icon: <HatchingDinoEggTwoToneIcon />,
    spotIcon: <HatchingDinoEggIcon />,
    spotIconOffsetLeft: -4,
    spotIconOffsetLeftMobile: 0,
    description:
      "Build in-product workflows and avoid the constraints of embedded iPaaS.",
    useCases: [...sharedUseCases, ...productUseCases],
  },
];
