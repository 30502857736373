import { makeSrcSet2x } from "../../utils/srcset.utils";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import YDWWTWhatSticker from "./YDWWTWhatSticker";

import ImageStickerHearts from "../../images/tines-sticker-hearts.png";
import ImageStickerHearts2x from "../../images/tines-sticker-hearts@2x.png";
import { styled } from "@linaria/react";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import {
  fromPhoneLg,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import SignUpButton from "../utilities/SignUpButton";
import { GridCanvas } from "../decorations/GridCanvas";

const Outer = styled.div`
  padding-top: 2em;
`;

const Inner = styled.div`
  text-align: center;
  color: ${colors.white};
  padding: 0 ${rSize("cardSectionPadding")} 0 ${rSize("cardSectionPadding")};
  border-radius: 1.5em;
  border: 1px solid ${colors.dark500};
  font-weight: 500;
  position: relative;
  article {
    margin-left: auto;
    margin-right: auto;
    max-width: ${getWidthPxInMaxGrid(7)};
    position: relative;
  }
  h2 {
    font-size: 36px;
    letter-spacing: -0.01em;
    ${fromPhoneLg} {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
    ${fromTablet} {
      font-size: 42px;
    }
  }
`;

const WhatWrapper = styled.div`
  font-size: 4.8rem;
  ${fromTablet} {
    font-size: 6.4rem;
  }
  > * {
    transform: translateY(-38%);
  }
`;

const StickerHeartsImgWrapper = styled.div`
  display: inline-block;
`;

const StickerHeartsImg = styled.img`
  display: block;
  margin-bottom: -40%;
  transform: scale(0.8);
`;

const YDWWTCommunityEditionCTASection = () => {
  return (
    <PageSection>
      <Outer>
        <Inner>
          <GridCanvas parallax color={colors.dark500} />
          <article>
            <WhatWrapper>
              <YDWWTWhatSticker />
            </WhatWrapper>
            <Spacing size="2.5em" />
            <h2>What will you build?</h2>
            <Spacing size="1em" />
            <p>
              If you want to try your hand at creating workflows as practical,
              inventive, or outrageous as you please, try our free Community
              Edition. 
            </p>
            <Spacing size="3em" />
            <SignUpButton />
          </article>
          <Spacing size="3em" />
          <StickerHeartsImgWrapper>
            <StickerHeartsImg
              src={ImageStickerHearts}
              srcSet={makeSrcSet2x(ImageStickerHearts, ImageStickerHearts2x)}
              width="215"
              height="159"
            />
          </StickerHeartsImgWrapper>
        </Inner>
      </Outer>
    </PageSection>
  );
};

export default YDWWTCommunityEditionCTASection;
