export const LoadedSkateboardTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <circle opacity="0.5" cx="5.5" cy="15.5" r="1" stroke="currentColor" />
    <circle opacity="0.5" cx="15.5" cy="15.5" r="1" stroke="currentColor" />
    <rect x="0.5" y="11.5" width="17" height="2" rx="1" stroke="currentColor" />
    <circle cx="2.5" cy="15.5" r="1.5" fill="currentColor" />
    <circle cx="12.5" cy="15.5" r="1.5" fill="currentColor" />
    <rect x="3" y="7.5" width="4" height="4" rx="0.5" stroke="currentColor" />
    <rect x="7" y="7.5" width="4" height="4" rx="0.5" stroke="currentColor" />
    <rect
      opacity="0.3"
      x="4.5"
      y="3"
      width="5"
      height="5"
      rx="1"
      fill="currentColor"
    />
    <rect
      opacity="0.3"
      x="6.5"
      y="7"
      width="5"
      height="5"
      rx="1"
      fill="currentColor"
    />
    <rect x="11" y="7.5" width="4" height="4" rx="0.5" stroke="currentColor" />
    <rect x="5" y="3.5" width="4" height="4" rx="0.5" stroke="currentColor" />
    <rect x="9" y="3.5" width="4" height="4" rx="0.5" stroke="currentColor" />
  </svg>
);
