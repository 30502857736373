import { styled } from "@linaria/react";
import { SectionHeading2 } from "../typography/SectionHeading2";
import {
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Spacing from "../layout/Spacing";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import trace3Logo from "./partnerLogos/trace3-logo.png";
import guidepointLogo from "./partnerLogos/guidepoint-security-logo.svg";
import kudelskiLogo from "./partnerLogos/kudelski-security-dark-logo.png";
import optivLogo from "./partnerLogos/optiv-logo.svg";
import { css } from "linaria";

const SectionWrap = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  max-width: ${maxPageContentWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding: ${rSize("sectionPadding")} ${rSize("pageMargin")};
  h3 {
    font-size: 2.4rem;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

const Backdrop = styled.div`
  ${uptoTablet} {
    display: none;
  }
  ${fromTablet} {
    border-radius: ${rSize("radius")};
  }
  border: 1px solid ${colors.purple200};
  position: absolute;
  left: ${rSize("widerPageMargin")};
  right: ${rSize("widerPageMargin")};
  top: 0;
  bottom: 0;
`;

const Header = styled.header`
  text-align: center;
  max-width: 30em;
  margin-left: auto;
  margin-right: auto;
`;

const Inner = styled.div`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  ${fromPhoneLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  grid-gap: ${rSize("gap")};
`;
const Card = css`
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  background-color: ${colors.purple50};
  border: 1px solid ${colors.purple300};
  text-decoration: none;
  padding: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  font-size: 1.4rem;
  p {
    font-size: inherit;
  }
  &:nth-child(2) {
    background-color: ${colors.green50};
    color: ${colors.green800};
    border: 1px solid ${colors.green300};
    figure {
      border-color: ${colors.green200};
    }
  }
  &:nth-child(3) {
    background-color: ${colors.orange50};
    color: ${colors.orange800};
    border: 1px solid ${colors.orange300};
    figure {
      border-color: ${colors.orange200};
    }
  }
  &:nth-child(4) {
    background-color: ${colors.pink50};
    color: ${colors.pink800};
    border: 1px solid ${colors.pink300};
    figure {
      border-color: ${colors.pink200};
    }
  }
`;
const CardBody = styled.div``;
const CardFigure = styled.figure`
  margin: 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.purple200};
  padding: ${rSize("gap")};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 6/4;
  img {
    display: block;
    max-width: 80%;
  }
`;
const CardFooter = styled.div`
  font-weight: 600;
`;

export const ProServPartnersSection = () => {
  return (
    <SectionWrap>
      <Backdrop />
      <Inner>
        <Header>
          <SectionHeading2 lighter>Services your way</SectionHeading2>
          <Spacing />
          <p>
            Our certified services partners are also here to help with your
            professional service needs.
          </p>
        </Header>
        <Spacing size="xl" />
        <Grid>
          <a
            className={Card}
            href="https://www.guidepointsecurity.com/"
            {...externalLinkAttr}
          >
            <CardBody>
              <CardFigure>
                <img src={guidepointLogo} alt="Guidepoint Logo" />
              </CardFigure>
              <Spacing />
              <p>
                <strong>Guidepoint Security</strong> is a trusted cybersecurity
                services provider delivering tailored solutions to help minimize
                risk.
              </p>
            </CardBody>
            <CardFooter>
              Visit website <sup>↗</sup>
            </CardFooter>
          </a>
          <a
            className={Card}
            href="https://kudelskisecurity.com/"
            {...externalLinkAttr}
          >
            <CardBody>
              <CardFigure>
                <img src={kudelskiLogo} alt="Kudelski Logo" />
              </CardFigure>
              <Spacing />
              <p>
                <strong>Kudelski</strong> provides international advisory
                services for technology optimization, managed security, managed
                detection and response, and emerging technology.
              </p>
            </CardBody>
            <CardFooter>
              Visit website <sup>↗</sup>
            </CardFooter>
          </a>
          <a
            className={Card}
            href="https://www.optiv.com/"
            {...externalLinkAttr}
          >
            <CardBody>
              <CardFigure>
                <img src={optivLogo} alt="Optiv Logo" />
              </CardFigure>
              <Spacing />
              <p>
                <strong>Optiv</strong> delivers strategic and technical
                cybersecurity expertise to customers across every major
                industry, partnering to advise, deploy, and manage large-scale
                security programs.
              </p>
            </CardBody>
            <CardFooter>
              Visit website <sup>↗</sup>
            </CardFooter>
          </a>
          <a
            className={Card}
            href="https://www.trace3.com/"
            {...externalLinkAttr}
          >
            <CardBody>
              <CardFigure>
                <img src={trace3Logo} alt="Trace3 Logo" />
              </CardFigure>
              <Spacing />
              <p>
                <strong>Trace3</strong> is a trusted technology consultancy that
                provides IT strategy, solutions, and services.
              </p>
            </CardBody>
            <CardFooter>
              Visit website <sup>↗</sup>
            </CardFooter>
          </a>
        </Grid>
      </Inner>
    </SectionWrap>
  );
};
