import ComboFontHeading from "../../../typography/ComboFontHeading";
import PillLegacy from "../../../basic/PillLegacy";
import ReportCard from "../../ReportCard";
import ReportSection from "../../ReportSection";
import ReportSummaryCard from "../../ReportSummaryCard";
import DonutChart from "../../charts/DonutChart";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { rSize } from "../../../../styles/responsiveSizes.styles";
import Spacing from "../../../layout/Spacing";
import ColumnedAndAvoidBreaksInParagraphs from "../../../layout/Columned";
import ReportChartContainer from "../../ReportChartContainer";

const MHChapter1 = () => {
  return (
    <ReportSection
      id="general-mental-health"
      themeName="orange"
      header={
        <>
          <PillLegacy color="orange">Part Two</PillLegacy>
          <ComboFontHeading
            snippets={[
              [
                <>
                  General
                  <br />
                </>,
              ],
              [<>mental health</>, "serif"],
            ]}
          />
          <p>
            Understanding and managing mental health is just as important as
            managing physical health — especially in a high-stress industry like
            cybersecurity. Yet while organizations are placing more importance
            on addressing mental health, workers are still suffering from the
            impacts of high-stress environments and poor or non-existent ways of
            coping with those environments. In light of increasing concerns
            around mental health, how are respondents assessing their mental
            health, and how has it changed over the past year?
          </p>
        </>
      }
    >
      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>47% say their mental health is either excellent or very good</h3>
        <ColumnedAndAvoidBreaksInParagraphs>
          <p>
            When it comes to rating their mental health, 47% ranked themselves
            in the higher tiers, saying their mental health is excellent (26.4%)
            or very good (20.6%). The remainder said their mental health was
            good (20.4%), fair (15.4%), or poor (17.3%).
          </p>
          <p>
            In looking at sub-groups of respondents, European respondents rank
            their mental health lower than US respondents. 43.4% say their
            mental health is either excellent or very good, compared to 50.6% of
            US respondents saying it’s either excellent or very good.
          </p>
          <p>
            Additionally, older respondents rank their mental health as better
            than younger respondents. Of respondents 35 and older, 50.9% say
            their mental health is either excellent or very good, compared to
            only 43.6% of respondents 34 or younger. 48.9% of senior-level
            employees rank their mental health as either excellent or very good,
            compared to 42.3% of entry-level employees.
          </p>
          <p>
            Finally, of those who rate their mental health as “excellent,” the
            largest segment are senior-level employees, who generally rate their
            physical health as excellent and mostly get seven hours of sleep
            per night.
          </p>
        </ColumnedAndAvoidBreaksInParagraphs>
        <Spacing size="1em" />
        <ReportChartContainer centered>
          <h4>How would you rate your mental health?</h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Excellent", 271],
              ["Very good", 211],
              ["Good", 209],
              ["Fair", 158],
              ["Poor", 178],
            ]}
            donutPercentage={0.375}
            height={325}
            colors={[
              colorsV4.purple,
              colorsV4.green,
              colorsV4.pink,
              colorsV4.orange,
              colorsV4.warmBlack,
            ]}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>27% say their mental health has gotten worse over the past year</h3>
        <p>
          Fortunately, for 39.1% of respondents, their mental health has
          improved over the past twelve months. For 34.2%, their mental health
          has stayed the same. However, 26.8% report that their mental health
          has gotten worse over the past year.
        </p>
        <p>
          US respondents have also fared better, as 42.3% of US respondents say
          it’s improved, while only 35.9% of European respondents say
          it’s improved.{" "}
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>How has your mental health changed over the past 12 months?</h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["It's improved", 401],
              ["It's stayed the same", 351],
              ["It's gotten worse", 275],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.purple, colorsV4.pink, colorsV4.warmBlack]}
            height={325}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>
          Half (50.8%) of respondents say they have been prescribed medication
          by a doctor for mental health.
        </h3>
        <p>
          Half (50.8%) of respondents say they have been prescribed medication
          by a doctor for mental health.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            Have you ever been prescribed medication by a doctor for
            mental health?
          </h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Yes", 522],
              ["No", 505],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.orange, colorsV4.warmBlack]}
            height={275}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>58% are currently taking medication for their mental health</h3>
        <p>
          Of those who have been prescribed medication, well over half (58.2%)
          of respondents are currently taking medication prescribed by a doctor
          for their mental health. Of those who ranked their mental health as
          “excellent” above, 59.3% are currently taking medication for their
          mental health, and of those who ranked their mental health as “poor”
          above, 51.6% are currently taking medication for their mental health.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>
            Are you currently taking medication prescribed by a doctor for
            mental health?
          </h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Yes", 304],
              ["No", 218],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.orange, colorsV4.warmBlack]}
            height={275}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportCard paddingOnDesktop={rSize("gap", 2)} centered largerHeading>
        <h3>49% are currently seeing a therapist</h3>
        <p>
          Nearly half (49.3%) of respondents are also currently seeing a
          therapist for their mental health. Of those who ranked their mental
          health as “excellent” above, 52% are currently seeing a therapist for
          their mental health, and of those who ranked their mental health as
          “poor” above, 53.9% are currently seeing a therapist for their
          mental health.
        </p>
        <Spacing size=".5em" />
        <ReportChartContainer centered>
          <h4>Are you currently seeing a therapist?</h4>
          <DonutChart
            header={["Answer", "Respondents"]}
            data={[
              ["Yes", 506],
              ["No", 521],
            ]}
            donutPercentage={0.375}
            colors={[colorsV4.orange, colorsV4.warmBlack]}
            height={275}
          />
        </ReportChartContainer>
      </ReportCard>

      <ReportSummaryCard>
        <h3>Summary</h3>
        <p>
          While about half of respondents ranked their physical health as either
          excellent or very good above, we found here that only 47% consider
          their mental health to be excellent or very good — meaning that 53%
          rank it as good, fair, or poor. We also found that younger and
          entry-level employees are not ranking their mental health as high as
          older or senior-level employees. Additionally, European respondents
          rank their mental health lower than US respondents.
        </p>
        <p>
          However, many respondents are taking steps to address their mental
          health. 51% have been prescribed medication for their mental health,
          and 49% are currently seeing a therapist. What’s interesting to note
          is that medication and therapy aren’t just for those who reported poor
          mental health; many respondents who reported that they have excellent
          mental health are also taking medication and attending therapy —
          perhaps reasons why they rank their mental health state so high.
        </p>
        <p>
          As we continue to look at mental health in workplace settings in our
          following section, security leaders need to consider that while 39% of
          respondents said their mental health has improved over the past year,
          27% said that it had gotten worse.
        </p>
        <Spacing size="1em" />
      </ReportSummaryCard>
    </ReportSection>
  );
};

export default MHChapter1;
