import { styled } from "@linaria/react";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PersonPhotoIcon, {
  PersonPhotoIconContainer,
} from "../general/PersonPhotoIcon";
import Spacing from "../layout/Spacing";
import PhotoIconEoinHinchy from "../../images/tines-photo-icon-eoin-hinchy.jpg";
import { fluidFontSize } from "../../styles/helpers.styles";
import CornerDotSet from "../utilities/CornerDotSet";
import { ReportBaseSectionStyle } from "./ReportBaseStyles";
import ReportSection from "./ReportSection";
import { font } from "../../styles/fonts.styles";
import ColumnedAndAvoidBreaksInParagraphs from "../layout/Columned";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { PropsWithChildren, ReactNode } from "react";
import { Hr } from "../utilities/Hr";
import { Serif } from "../typography/Serif";

const ReportIntroSectionContainer = styled.section`
  ${ReportBaseSectionStyle}
  position: relative;
  background-color: ${colorsV4.warmBlack};
  color: ${withOpacity(colorsV4.white, 0.875)};
  strong {
    color: ${colorsV4.white};
  }
  hr {
    margin-top: 2em;
    margin-bottom: 2em;
  }
`;

const Header = styled.header`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${rSize("gap")};
  color: ${colorsV4.white};
  ${onlyPhones} {
    grid-template-columns: minmax(0, 1fr);
    justify-content: center;
    text-align: center;
    ${PersonPhotoIconContainer} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${fromTablet} {
    grid-template-columns: auto minmax(0, 1fr);
    grid-auto-flow: column;
    align-items: center;
  }
  h2 {
    ${fluidFontSize(28, 48)};
  }
  p {
    font-weight: 500;
    opacity: 0.875;
  }
`;

const Blockquote = styled.blockquote`
  column-span: all;
  text-align: center;
  font-family: ${font("serif")};
  background-color: ${withOpacity(colorsV4.white, 0.075)};
  margin: 2em 0;
  padding: 2em 1em;
  border-radius: ${rSize("radius")};
  font-size: 1.8rem;
  ${fromTablet} {
    font-size: 2rem;
    padding: 3em 1em;
  }
`;

const Article = styled.div`
  font-size: 1.6rem;
  ${onlyPhones} {
    text-align: center;
  }
`;

const ReportIntroSection = (
  props: PropsWithChildren<{ id?: string; blockquote?: ReactNode }>
) => {
  return (
    <ReportSection id={props.id ?? "introduction"} fullWidthWhenSmall>
      <ReportIntroSectionContainer>
        <CornerDotSet
          color={withOpacity(colorsV4.grey, 0.3)}
          offset={rSize("gap")}
        />
        <Header>
          <PersonPhotoIcon size="8em" src={PhotoIconEoinHinchy} />
          <div>
            <h2>
              A word from
              <br />
              <Serif>Eoin Hinchy</Serif>
            </h2>
            <Spacing size=".5em" />
            <p>CEO & Co-Founder, Tines</p>
          </div>
        </Header>
        {props.blockquote ? (
          <Blockquote>{props.blockquote}</Blockquote>
        ) : (
          <Hr />
        )}
        <Article>
          <ColumnedAndAvoidBreaksInParagraphs>
            {props.children}
          </ColumnedAndAvoidBreaksInParagraphs>
        </Article>
      </ReportIntroSectionContainer>
    </ReportSection>
  );
};

export default ReportIntroSection;
