import { css } from "linaria";
import Select from "../../forms/Select";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { colors, withOpacity } from "../../../styles/colors.styles";
import { styled } from "@linaria/react";
import { navigate } from "gatsby";

const VersionSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  padding-bottom: 1.5em;
`;

const Label = styled.label`
  border-bottom: 1px solid ${withOpacity(colors.gray, 0.3)};
  padding-bottom: 0.6em;
`;

export default function VersionSelect(props: {
  versionOptions: { value: string; label: string }[];
  value: string;
}) {
  return (
    <VersionSelectContainer>
      <Label>Version</Label>
      <Select
        name="version"
        options={props.versionOptions}
        disabled={props.versionOptions.length === 1}
        className={css`
          ${darkModeLinariaCSS(`select { border-color: ${colors.dark500}}`)}
        `}
        onChange={event => {
          navigate(event.target.value);
        }}
        value={props.value}
      />
    </VersionSelectContainer>
  );
}
