import { styled } from "@linaria/react";
import {
  DocumentIDMap,
  recordDocumentEvent,
} from "../../../utils/documents.utils";
import { Paths } from "../../../utils/pathBuilders.utils";
import Button from "../../forms/Button";
import Spacing from "../../layout/Spacing";
import { fontSerifDisplayRules, serif } from "../../../styles/fonts.styles";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  onlyPhones,
  uptoDesktop,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import PageSection from "../../reusableSections/PageSection";
import SiteNavHeightPlaceholder from "../../site/SiteNavHeightPlaceholder";
import AllCaps from "../../typography/AllCaps";
import { CisoAIHeroIllustrationLeft } from "./illustrations/CisoAIHeroIllustrationLeft";
import { CisoAIHeroIllustrationRight } from "./illustrations/CisoAIHeroIllustrationRight";

const Clip = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 15em;
  margin-bottom: -15em;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  ${fromTablet} {
    text-align: left;
  }
  ${uptoDesktop} {
    display: flex;
    justify-content: center;
  }
  ${fromDesktop} {
    padding-left: ${widthInGrid(4, 1)};
  }
  p {
    max-width: unset;
  }
`;

const ContentInner = styled.div``;

const Title = styled.h1`
  font-size: 4.8rem;
  letter-spacing: -0.03em;
  font-weight: 200;
  line-height: 1;
  margin-left: -0.07em;
  font-family: ${serif};
  ${fromTablet} {
    font-size: 7.2rem;
    ${fontSerifDisplayRules}
  }
  ${fromDesktopMd} {
    font-size: 9.6rem;
    font-weight: 160;
    line-height: 0.9;
  }
  span {
    display: block;
  }
`;

const SectionContentWrap = styled.div`
  position: relative;
`;

const IllustrationLayer = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const CisoAIHeroIlloLeftPositioner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-42%, -15%);
  ${onlyPhones} {
    transform: translate(-38%, -15%) rotate(5deg);
  }
  svg {
    ${onlyPhones} {
      max-width: 60vw;
    }
    max-width: 45vw;
    height: auto;
  }
`;

const CisoAIHeroIlloRightPositioner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(56%, 34%);
  ${onlyPhones} {
    transform: translate(48%, 34%) rotate(3deg);
  }
  svg {
    ${onlyPhones} {
      max-width: 60vw;
    }
    max-width: 45vw;
    height: auto;
  }
`;

export const CisoAIHero = () => {
  const handleDownloadButtonClick = () => {
    recordDocumentEvent({
      type: "download",
      id: DocumentIDMap.cisoPerspectiveAI2023,
    });
  };
  return (
    <Clip>
      <PageSection>
        <SiteNavHeightPlaceholder />
        <Spacing size="sectionPadding" />
        <SectionContentWrap>
          <IllustrationLayer>
            <CisoAIHeroIlloLeftPositioner>
              <CisoAIHeroIllustrationLeft />
            </CisoAIHeroIlloLeftPositioner>
            <CisoAIHeroIlloRightPositioner>
              <CisoAIHeroIllustrationRight />
            </CisoAIHeroIlloRightPositioner>
          </IllustrationLayer>
          <Content>
            <ContentInner>
              <AllCaps as="p">CISO perspectives</AllCaps>
              <Spacing size="1.5em" />
              <Title>
                <span>Separating</span> <span>the reality</span>{" "}
                <span>of AI from</span> <span>the hype</span>
              </Title>
              <Spacing size="lg" />
              <Button
                appearance="outlined"
                download
                onClick={handleDownloadButtonClick}
                href={Paths.cisoPerspectivesAI.pdf()}
                color="orange"
                darker
              >
                Download PDF
              </Button>
            </ContentInner>
          </Content>
        </SectionContentWrap>
        <Spacing size="sectionPadding" />
      </PageSection>
    </Clip>
  );
};
