import { colors } from "../../../styles/colors.styles";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import ReportV2StatTable from "../components/ReportV2StatTable";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { TimeCakeIcon } from "./icons/TimeCakeIcon";
import { GooglyEyesIcon } from "./icons/GooglyEyesIcon";
import { styled } from "@linaria/react";
import Spacing from "../../layout/Spacing";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { cx } from "linaria";
import imgAWSReInforce from "../../../../static/images/reports/soc2023/event-img-aws-reinforce.png";
import imgAWSReInforce2x from "../../../../static/images/reports/soc2023/event-img-aws-reinforce@2x.png";
import imgAWSReInvent from "../../../../static/images/reports/soc2023/event-img-aws-reinvent.png";
import imgAWSReInvent2x from "../../../../static/images/reports/soc2023/event-img-aws-reinvent@2x.png";
import imgBlackHat from "../../../../static/images/reports/soc2023/event-img-blackhat.png";
import imgBlackHat2x from "../../../../static/images/reports/soc2023/event-img-blackhat@2x.png";
import imgBSides from "../../../../static/images/reports/soc2023/event-img-bsides.png";
import imgBSides2x from "../../../../static/images/reports/soc2023/event-img-bsides@2x.png";
import imgDefCon from "../../../../static/images/reports/soc2023/event-img-defcon.png";
import imgDefCon2x from "../../../../static/images/reports/soc2023/event-img-defcon@2x.png";
import imgInfoSec from "../../../../static/images/reports/soc2023/event-img-infosec.png";
import imgInfoSec2x from "../../../../static/images/reports/soc2023/event-img-infosec@2x.png";
import imgNone from "../../../../static/images/reports/soc2023/event-img-none.png";
import imgNone2x from "../../../../static/images/reports/soc2023/event-img-none@2x.png";
import imgRSA from "../../../../static/images/reports/soc2023/event-img-rsa.png";
import imgRSA2x from "../../../../static/images/reports/soc2023/event-img-rsa@2x.png";
import { makeSrcSet2x } from "../../../utils/srcset.utils";
import ReportChapterSummary from "../components/ReportChapterSummary";
import { BriefcaseWithHandcuffsIcon } from "./icons/BriefcaseWithHandcuffsIcon";
import { DartTarget } from "./icons/DartTarget";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";

export const SOC2023Chapter2 = () => {
  return (
    <section id="chapter-2-where-time-goes">
      <ReportV2ChapterHeaderSection
        chapterNumber={2}
        largeChapterNumberStyle="dots"
        color="pink"
        icon={<TimeCakeIcon />}
      >
        <h2>Where time goes</h2>
        <ReportV2TextBlock largerText>
          <p>
            We know security teams are frustrated by time spent on tedious
            tasks, and this repetitive work prevents them from engaging in the
            improvements that will enhance their organization’s security
            posture. In this section, we find out exactly what these necessary
            but mundane tasks are, and learn more about the internal metrics
            guiding this time management.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2Section backgroundColor={colors.pink100} color={colors.pink800}>
        <ReportV2SectionHeader>
          <ReportV2SectionHeadingMedium>
            Top five time-consuming tasks
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="pink"
          items={[
            {
              percentage: 18,
              heading: "Security orchestration, automation and response (SOAR)",
              description:
                "The number one most time-consuming task is SOAR, likely because nearly every current SOAR tool uses an app-based integration model which relies on limited pre-built integrations and often requires teams to build their own custom apps. Direct integrations can address these challenges.",
            },
            {
              percentage: 17,
              heading: "Troubleshooting system errors/system maintenance",
              description:
                "Troubleshooting and maintenance take up a significant amount of time, preventing teams from doing the proactive work that could improve security postures.",
            },
            {
              percentage: 16,
              heading:
                "Intelligence (i.e. researching threat actors, TTPs, ATT&CK)",
              description:
                "Teams are also spending time researching threat actors — including their tactics, techniques, and procedures — and operationalizing the MITRE ATT&CK framework.",
            },
            {
              percentage: 15,
              heading: "Monitoring",
              description:
                "Respondents are spending valuable time monitoring for threats and alerts, despite the fact that respondents over the practitioner level should not be doing front-line monitoring.",
            },
            {
              percentage: 15,
              heading: "Managing a knowledge base/operational documentation",
              description:
                "Documentation rounds out the top five tasks — ensuring all essential documents are stored, backed up, and eventually discarded when they’re no longer needed.",
            },
            {
              text: "Lower on the list, you’ll find more proactive, higher-impact tasks like managing IOCs and modifying alert rules — tasks that SOC would likely prefer to prioritize. One task that saw a steep decline this year? Reporting. ",
            },
            { percentage: 13, heading: "Data Loss Prevention (DLP)" },
            {
              percentage: 12,
              heading: "Communicating (email, phone, messenger, etc)",
            },
            {
              percentage: 11,
              heading: "Detecting (including intrusion detection)",
            },
            { percentage: 11, heading: "Responding to security incidents" },
            {
              percentage: 11,
              heading:
                "Vulnerability/compliance scanning (e.g. Nessus) and patching",
            },
            { percentage: 9, heading: "Malware analysis/forensics" },
            { percentage: 8, heading: "Threat hunting" },
            {
              percentage: 8,
              heading: "Evaluating new vendors/products/services",
            },
            { percentage: 8, heading: "Log analysis" },
            { percentage: 8, heading: "Operations/ShiftOps" },
            { percentage: 7, heading: "Compliance and audits" },
            { percentage: 7, heading: "Managing IOCs" },
            { percentage: 7, heading: "Tracking" },
            {
              percentage: 6,
              heading: "Penetration testing, Red teaming, Purple teaming, etc.",
            },
            { percentage: 6, heading: "Reporting" },
            { percentage: 5, heading: "Phishing triage/response" },
            { percentage: 5, heading: "Recovery" },
            { percentage: 4, heading: "Modifying alert rules" },
            { percentage: 3, heading: "Containment" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section backgroundColor={colors.lightest} color={colors.pink800}>
        <ReportV2SectionHeader icon={<BriefcaseWithHandcuffsIcon />}>
          <ReportV2SectionHeadingMedium>
            <span>Top four tasks</span>
            <span>security teams enjoy the least</span>
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="pink"
          borderColor={colors.pink50}
          rowHighlightBorderColor={colors.pink100}
          items={[
            {
              percentage: 18,
              heading: "Communicating (email, phone, Slack, etc.)",
              description:
                "One of the two tasks which respondents enjoyed the least was communicating. Slack notifications come for us all, but there are ways to automate communications internally and externally and increase transparency on shared projects.",
            },
            {
              percentage: 18,
              heading: "Reporting",
              description:
                "The other top choice was reporting. Reporting matters, but it’s reactive — collecting what happened after an incident — rather than proactive. Streamlining the reporting process through automation frees up security practitioners to focus on analysis and increases job satisfaction.",
            },
            {
              percentage: 10,
              heading: "Monitoring",
              description:
                "Monitoring, one of the most time-consuming tasks, is also one of the least enjoyable. Much of this type of manual front-line monitoring can be automated.",
            },
            {
              percentage: 10,
              heading: "Responding to security incidents",
              description:
                "As you’ll see below, teams are judged on their ability to respond to incidents. It should be noted that 14% of respondents named this type of response their most enjoyable task — including 22% of VPs and above, suggesting a split between analysts and leaders on the task.",
            },
            { percentage: 9, heading: "Triaging" },
            { percentage: 9, heading: "Threat hunting" },
            { percentage: 8, heading: "Tracking" },
            { percentage: 6, heading: "Intrusion detection" },
            { percentage: 6, heading: "Detecting" },
            { percentage: 6, heading: "Operations/ShiftOps" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section backgroundColor={colors.pink100} color={colors.pink800}>
        <ReportV2SectionHeader icon={<DartTarget />} iconAlign="start">
          <ReportV2SectionHeadingMedium>
            Top four key metrics used to measure job performance
          </ReportV2SectionHeadingMedium>
          <Spacing size="lg" />
          <p>
            What key metrics are used to measure a security team’s job
            performance? In other words, what metrics should SOC teams
            prioritize to improve team performance?
          </p>
          <p>
            When we asked this question last year, the top five responses were
            mean time to investigate (54.1%), number of alerts (43.8%), mean
            time to respond (40%), time to detect (37.6%), and number of
            incidents handled (34.2%). Four of those answers cracked the top
            five again this year, with only the number of alerts falling off —
            possibly because security teams are learning that an avalanche of
            alerts is an impediment to success, not a marker of it.
          </p>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="pink"
          items={[
            {
              percentage: 36,
              heading: "Mean time to investigate (MTTI)",
              description:
                "The average amount of time between when a problem is detected and when the security team begins to investigate it. Successful SOC teams reduce the intervening window.",
            },
            {
              percentage: 36,
              heading: "Time to detect",
              description:
                "The time it takes an organization to discover an incident. SOC teams need solutions in place to identify issues quickly and catch zero-day vulnerabilities.",
            },
            {
              percentage: 36,
              heading: "Number of incidents handled",
              description:
                "SOC teams are measured by the amount of incidents they resolve successfully. They can slash this figure by implementing faster and more thorough alert and resolution tools.",
            },
            {
              percentage: 36,
              heading: "Mean time to respond",
              description:
                "The average time it takes to resolve an incident completely. Automation can help security teams investigate and remediate threats and return a system to operation after a failure.",
            },
            {
              percentage: 33,
              heading:
                "Adherence to SOW/SOP/KBs (Statements of Work, Standard Operating Procedure, Knowledge Base articles)",
            },
            { percentage: 33, heading: "Percentage of recurring incidents" },
            { percentage: 31, heading: "Time to containment" },
            {
              percentage: 31,
              heading: "Adherence to Service Level Agreements (SLAs)",
            },
            { percentage: 30, heading: "Number of alerts" },
            { percentage: 28, heading: "Percentage of escalated events" },
            {
              percentage: 28,
              heading: "Knowledge base/wiki articles created or enhanced",
            },
            {
              percentage: 28,
              heading: "Rules or detections created or enhanced",
            },
            { percentage: 26, heading: "Time to eradication" },
            {
              percentage: 25,
              heading: "False positives identified and reduced",
            },
            { percentage: 22, heading: "False positive rate" },
          ]}
        />
      </ReportV2Section>

      <ReportV2Section backgroundColor={colors.lightest} color={colors.pink800}>
        <ReportV2SectionHeader icon={<GooglyEyesIcon />}>
          <ReportV2SectionHeadingMedium>
            <span>Where you can </span>
            <span>find them</span>
          </ReportV2SectionHeadingMedium>
          <Spacing />
          <p>
            As a fun aside, we asked our participants which conferences they’re
            aware of or have attended in the past two years. If you’re looking
            for your peers, your best bet is AWS re:Inforce, followed by Black
            Hat and AWS re:Invent.
          </p>
        </ReportV2SectionHeader>
        <Spacing size="xl" />
        <EventCardGrid>
          <EventCard
            image={imgAWSReInforce}
            image2x={imgAWSReInforce2x}
            name="AWS re:Inforce"
            percentage={39}
            wide
          />
          <EventCard
            image={imgBlackHat}
            image2x={imgBlackHat2x}
            name="Black Hat"
            percentage={37}
            wide
          />
          <EventCard
            image={imgAWSReInvent}
            image2x={imgAWSReInvent2x}
            name="AWS re:Invent"
            percentage={35}
            wide
          />
          <EventCard
            image={imgInfoSec}
            image2x={imgInfoSec2x}
            name="InfoSec"
            percentage={27}
            wide
          />
          <EventCard
            image={imgDefCon}
            image2x={imgDefCon2x}
            name="DefCon"
            percentage={27}
          />
          <EventCard
            image={imgBSides}
            image2x={imgBSides2x}
            name="BSides"
            percentage={24}
          />
          <EventCard
            image={imgRSA}
            image2x={imgRSA2x}
            name="RSA"
            percentage={13}
          />
          <EventCard
            image={imgNone}
            image2x={imgNone2x}
            name="None of the above"
            percentage={19}
          />
        </EventCardGrid>
      </ReportV2Section>

      <ReportV2Section backgroundColor={colors.pink100} color={colors.pink800}>
        <ReportChapterSummary>
          <p>
            Our respondents reveal that their time is mostly spent on necessary
            but tedious tasks like operating imperfect SOAR tools,
            troubleshooting system errors, and front-line monitoring (which is
            also one of the tasks they enjoy least). Effective automation can
            help maximize a SOC team’s time and improve the metrics — including
            time to detect, investigate, and respond — that they are measured
            against.
          </p>
        </ReportChapterSummary>
      </ReportV2Section>
    </section>
  );
};

const EventCardGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(2, 1fr);
  ${fromTabletLg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const EventCardWrap = styled.figure`
  margin: 0;
  padding: 0;
  &.wide {
    ${fromTabletLg} {
      grid-column-end: span 2;
    }
  }
  img {
    display: block;
    aspect-ratio: 360 / 200;
    background-color: ${colors.light300};
    border-radius: 1.2rem;
    width: 100%;
  }
  figcaption {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-gap: ${rSize("gap")};
    margin-top: 1.2rem;
  }
  &.small {
    figcaption {
      font-size: 1.4rem;
    }
  }
`;

const EventCard = (props: {
  image: string;
  image2x: string;
  name: string;
  percentage: number;
  wide?: boolean;
}) => (
  <EventCardWrap className={cx(props.wide ? "wide" : "small")}>
    <img src={props.image} srcSet={makeSrcSet2x(props.image, props.image2x)} />
    <figcaption>
      <h4>{props.name}</h4>
      <p>{props.percentage}%</p>
    </figcaption>
  </EventCardWrap>
);
