/* eslint-disable no-console */
import {
  deleteCookie,
  getAllCookies,
  getCookie,
  setCookie,
} from "../../utils/cookies.utils";
import { reportErrorSilently } from "../../utils/error.utils";
import { ISiteContext } from "../../context/site.context";
import { writeMarketingAttribution } from "../../utils/marketingAttribution.utils";
import { resolveAfter, when } from "../../utils/promises.utils";
import { initGoogleTagManager } from "./thirdPartyServices/googleTagManager";
import { initGtagForGoogleAnalytics } from "./thirdPartyServices/gtagAndGoogleAnalytics";
import { initLinkedInInsight } from "./thirdPartyServices/linkedInInsight";
import { initSalesLoft } from "./thirdPartyServices/salesloft";
import { EEACountryCodes, locateUserIP } from "../../utils/country.utils";
import { getLocalStorageItem } from "../../utils/localStorage.utils";
import { initDemandBase } from "./thirdPartyServices/initDemandBase";
import {
  checkIfIsClientAndLive,
  isBuildTime,
  isDevelopment,
} from "../../environment";
import { renderNotice } from "../../utils/notice.utils";
import { readGCLID } from "../../utils/gclid.utils";
import { initZoomInfo } from "./thirdPartyServices/initZoomInfo";
import { initAnalyticsEvents } from "../../utils/analyticsEvent";
import { initHubSpotTracking } from "./thirdPartyServices/hubspot";
import { initCommonRoom } from "./thirdPartyServices/initCommonRoom";

const COOKIE_CONSENT_STORAGE_KEY = "cookie_consent_preferences";

/**
 * In browser console, run
 * ```
 *   localStorage.setItem('DEBUG_GDPR', true)
 * ```
 * to view logs from GDPR / cookies related functionalities
 */

export const makeDefaultCookieConsentState = () => ({
  hasReviewed: false,
  essential: true,
  personalization: false,
  analytics: false,
  marketing: false,
});

export type CookieConsentState = ReturnType<
  typeof makeDefaultCookieConsentState
>;
declare global {
  interface Window {
    playwright?: Record<string, unknown>;
  }
}

let COOKIE_CONTROLS_INITIATED = false;
export const checkCookieControlsInitState = () => COOKIE_CONTROLS_INITIATED;

let GDPR_APPLICABLE = false;
export const checkIfGDPRApplicable = async () => {
  await when(checkCookieControlsInitState);
  return GDPR_APPLICABLE;
};

export async function initCookieControls(siteContext: ISiteContext) {
  if (isBuildTime) return;
  if (COOKIE_CONTROLS_INITIATED) return;
  if (!checkIfIsClientAndLive() && !isDevelopment) {
    declineAnalyticsAndMarketingCookies(siteContext);
    COOKIE_CONTROLS_INITIATED = true;
    return;
  }
  let merged = makeDefaultCookieConsentState();
  try {
    const read = readCookieConsentState();
    if (read) merged = read;
    const { countryCode } = await locateUserIP();
    GDPR_APPLICABLE = shouldApplyGDPRToCountry(countryCode);
    siteContext.setGdprApplicable(GDPR_APPLICABLE);
    if (GDPR_APPLICABLE) {
      if (shouldLogGDPRDebugInfo()) {
        console.log(
          `GDPR applies in country "${countryCode}" detected from IP.`
        );
      }
      const wasAutomaticallyAccepted =
        !merged.hasReviewed &&
        merged.personalization &&
        merged.analytics &&
        merged.marketing;
      if (wasAutomaticallyAccepted) {
        if (shouldLogGDPRDebugInfo()) {
          console.log(
            "Cookie consent was previously accepted as GDPR was not applicable. Resetting consents."
          );
        }
        merged = makeDefaultCookieConsentState();
        resetCookieConsentState(siteContext);
      }
    } else {
      if (!merged.hasReviewed) {
        const newState = acceptAllCookies(siteContext, false);
        merged = newState;
      }
    }
  } catch (e) {
    reportErrorSilently(e);
  } finally {
    siteContext.setCookieConsentState(merged);
    enableFeaturesBasedOnCookieConsent(siteContext, merged);
    if (!merged.hasReviewed && GDPR_APPLICABLE) {
      siteContext.setShouldDisplayCookieConsentManager(true);
    }
    COOKIE_CONTROLS_INITIATED = true;
  }
}

export const resetCookieConsentState = (siteContext: ISiteContext) => {
  siteContext.setCookieConsentState(makeDefaultCookieConsentState());
};

export const readCookieConsentState = () => {
  try {
    const consents = getCookie(COOKIE_CONSENT_STORAGE_KEY);
    if (!consents) return null;
    const read = JSON.parse(consents) as CookieConsentState;
    const merged = {
      ...makeDefaultCookieConsentState(),
      ...read,
    };
    if (shouldLogGDPRDebugInfo()) {
      console.log(
        `%cCookies reviewed: ${merged.hasReviewed} | personalization: ${merged.personalization} | analytics: ${merged.analytics} | marketing: ${merged.marketing}`,
        "color: rgb(122,122,122)"
      );
    }
    return merged;
  } catch (e) {
    reportErrorSilently(e);
    return null;
  }
};

export const saveCookieConsentState = (state: CookieConsentState) => {
  setCookie(COOKIE_CONSENT_STORAGE_KEY, JSON.stringify(state));
};

export const acceptAllCookies = (
  siteContext: ISiteContext,
  hasReviewed: boolean
) => {
  const { setCookieConsentState } = siteContext;
  const allTrue = {
    hasReviewed,
    essential: true,
    personalization: true,
    analytics: true,
    marketing: true,
  };
  setCookieConsentState(allTrue);
  saveCookieConsentState(allTrue);
  return allTrue;
};

export const declineAnalyticsAndMarketingCookies = (
  siteContext: ISiteContext
) => {
  const { setCookieConsentState } = siteContext;
  const allFalse = {
    hasReviewed: true,
    essential: true,
    personalization: true,
    analytics: false,
    marketing: false,
  };
  setCookieConsentState(allFalse);
  saveCookieConsentState(allFalse);
  return allFalse;
};

const reloadAfterDelay = async function () {
  renderNotice("Applying your cookie settings...");
  await resolveAfter(1000);
  window.location.reload();
};

const clearAllCookiesApartFromConsent = () => {
  Object.keys(getAllCookies()).forEach(name => {
    if (name === COOKIE_CONSENT_STORAGE_KEY) return;
    deleteCookie(name);
  });
};

const processors = {
  analytics: {
    init: () => {
      if (shouldLogGDPRDebugInfo())
        console.log(`initializing analytics services...`);
      writeMarketingAttribution();
      initGtagForGoogleAnalytics();
      initAnalyticsEvents();
    },
  },
  marketing: {
    init: () => {
      if (shouldLogGDPRDebugInfo())
        console.log(`initializing marketing services...`);
      initGoogleTagManager();
      readGCLID();
      initSalesLoft();
      initLinkedInInsight();
      initDemandBase();
      initZoomInfo();
      initHubSpotTracking();
      initCommonRoom();
    },
  },
};

export function enableFeaturesBasedOnCookieConsent(
  siteContext: ISiteContext,
  curr: CookieConsentState,
  prev?: CookieConsentState
) {
  if (prev) {
    if (
      (prev.personalization && !curr.personalization) ||
      (prev.analytics && !curr.analytics) ||
      (prev.marketing && !curr.marketing)
    ) {
      clearAllCookiesApartFromConsent();
      reloadAfterDelay();
    }
  }
  if (!prev?.analytics && curr.analytics) processors.analytics.init();
  if (!prev?.marketing && curr.marketing) processors.marketing.init();
}

const GDPRApplicableCountryCodes = [
  ...EEACountryCodes,
  "GB", // UK has its own GDPR counterpart
  "CH", // Switzerland
];

export const shouldApplyGDPRToCountry = (countryCode: string) => {
  return (
    !countryCode ||
    GDPRApplicableCountryCodes.includes(countryCode.toUpperCase())
  );
};

export const whenUserActOnCookieBanner = async (fn: () => void) => {
  const GDPRApplicable = await checkIfGDPRApplicable();
  if (!GDPRApplicable) {
    if (shouldLogGDPRDebugInfo()) {
      console.log("GDPR not applicable.");
    }
    fn();
    return;
  }
  if (shouldLogGDPRDebugInfo()) {
    if (!COOKIE_CONTROLS_INITIATED) {
      console.log("Waiting for cookie controls to init...");
      await when(checkCookieControlsInitState);
    }
    const state = readCookieConsentState();
    if (!state?.hasReviewed) {
      console.log("Waiting for action on cookie banner...");
    } else {
      console.log("Cookie controls initiated & cookie reviewed.");
    }
  }
  when(checkCookieControlsInitState, () => {
    if (!readCookieConsentState()?.hasReviewed) {
      when(() => !!readCookieConsentState()?.hasReviewed, fn, {
        recheckInterval: 1000,
      });
    } else {
      fn();
    }
  });
};

export const shouldLogGDPRDebugInfo = () =>
  getLocalStorageItem("DEBUG_GDPR") === true;
