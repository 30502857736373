import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import {
  aspectRatioChild,
  aspectRatioParent,
} from "../../styles/aspectRatio.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { EventDirectoryItem } from "../../types/helper.types";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  MapController,
  initEventDirectoryMapInElement,
} from "../../utils/maps.utils";
import { colors } from "../../styles/colors.styles";

type Props = {
  item: EventDirectoryItem;
  onReady?: (mapController: MapController) => void;
  onSelectItem?: (item: EventDirectoryItem) => void;
};

const EventDirectoryItemMapContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.light400};
  ${aspectRatioParent(16 / 9)};
  border-radius: ${rSize("radius")};
  border: 1px solid ${withOpacity(colors.gray, 0.5)};
  > div {
    ${aspectRatioChild()};
    > div {
      background-color: ${colorsV4.canvas700} !important;
    }
  }
  img {
    pointer-events: auto;
  }
`;

const EventDirectoryItemMap = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mapController, setMapController] = useState<MapController>();
  const init = async () => {
    if (!ref.current) return;
    const mapController = await initEventDirectoryMapInElement(
      props.item.id,
      ref.current,
      [props.item]
    );
    setMapController(mapController);
    props.onReady?.(mapController);
  };
  useOnMount(() => {
    init();
  });
  return <EventDirectoryItemMapContainer ref={ref} />;
};

export default EventDirectoryItemMap;
