import { StoryboardElementGroupMeasurements } from "../components/storyboard/utils/storyboard.utils";
import { XY } from "../utils/geometry.utils";

export enum ActionType {
  "transform" = "eventTransformation",
  "trigger" = "trigger",
  "webhook" = "webhook",
  "httpRequest" = "httpRequest",
  "sendToStory" = "sendToStory",
  "email" = "email",
  "imap" = "imap",
  "group" = "group",
  "groupInput" = "groupInput",
  "groupOutput" = "groupOutput",
  "form" = "form",
}
export const ActionTypeToTypeNameMap = {
  eventTransformation: "Transform",
  trigger: "Trigger",
  webhook: "Webhook",
  httpRequest: "HTTP request",
  sendToStory: "Send to story",
  email: "Send email",
  imap: "Receive email",
  group: "Group",
  groupInput: "Group input",
  groupOutput: "Group output",
  form: "Page",
} as const;

export type ActionTypeName = typeof ActionTypeToTypeNameMap[ActionType];

export type RuntimeActionRecord<T extends {} = {}> = {
  readonly type: ActionType;
  readonly name: string;
  readonly description: string | null;
  readonly disabled: boolean;
  readonly guid?: string;
  readonly options: T;
  readonly jsonString?: string;
  position: XY;
  width?: number | null;
  height?: number | null;
};

export type RuntimeActionLinkRecord = {
  readonly sourceIdentifier: number;
  readonly receiverIdentifier: number;
};

export type RuntimeNoteRecord = {
  readonly content: string;
  position: XY;
  width: number | null;
  height: number | null;
};

export type RuntimeStoryRecord = {
  standardLibVersion: string;
  actionRuntimeVersion: string;
  jsonString?: string;
  agents: RuntimeActionRecord[];
  links: RuntimeActionLinkRecord[];
  diagramNotes: RuntimeNoteRecord[];
};

export type ActionRecord<T extends {} = {}> = RuntimeActionRecord<T>;
export type LinkRecord = RuntimeActionLinkRecord;
export type StoryRecord = RuntimeStoryRecord;

export type ActionBase<T extends {} = {}> = {
  readonly index: number;
  readonly record: ActionRecord<T>;
  readonly type: ActionType;
  readonly typeName: ActionTypeName;
  readonly name: string;
  readonly description: string;
  readonly color: string;
  readonly disabled: boolean;
  readonly guid?: string;
  readonly position: XY;
  readonly inputs: Action[];
  readonly outputs: Action[];
  readonly inputLinks: ActionLink[];
  readonly outputLinks: ActionLink[];
  readonly options: T;
  width: number | null;
  height: number | null;
};

export type FormAction<T extends {} = {}> = ActionBase<T> & {
  readonly type: ActionType.form;
  readonly typeName: typeof ActionTypeToTypeNameMap[ActionType.form];
};

export type Action<T extends {} = {}> = ActionBase<T> | FormAction<T>;
export type EventTransformAction = Action<{
  mode: string;
  loop: boolean;
  payload?: string;
  path?: string;
  to?: string;
}>;
export type HTTPRequestAction = Action<{
  url: string;
  method: string;
  headers: Record<string, string>;
  content_type: string;
  payload: Record<string, string>;
}>;
export type WebhookAction = Action<{
  path: string;
  secret: string;
  verbs: string;
}>;
export type TriggerAction = Action<{
  rules: { type: string; value: string; path: string }[];
}>;
export type SendEmailAction = Action<{
  recipients: string[];
  reply_to: string;
  sender_name: string;
  subject: string;
  body: string;
}>;

export type ActionLink = {
  readonly index: number;
  readonly record: LinkRecord;
  readonly source: Action;
  readonly receiver: Action;
};

export type Note = {
  readonly index: number;
  readonly record: RuntimeNoteRecord;
  readonly content: string;
  readonly position: XY;
  width: number | null;
  height: number | null;
};

export type Story = {
  record: StoryRecord;
  actions: Action[];
  heads: Action[];
  links: ActionLink[];
  notes: Note[];
  measurements: StoryboardElementGroupMeasurements;
};
