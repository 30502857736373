import { styled } from "@linaria/react";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import {
  fromTablet,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import QuoteCardNeue from "../general/QuoteCardNeue";
import Spacing from "../layout/Spacing";
import { NoBreak } from "../typography/Nowrap";
import { SectionHeading2 } from "../typography/SectionHeading2";
import PageSection from "./PageSection";
import FeaturedClientLogoSet from "../general/FeaturedClientLogoSet";

const SectionInner = styled.div`
  h2 {
    text-align: center;
    ${fromTablet} {
      max-width: ${getWidthPxInMaxGrid(6)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const QuoteCardGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ClientLogoSetWithQuotesSection = () => (
  <PageSection>
    <SectionInner>
      <SectionHeading2 className="serif">
        Trusted by <NoBreak>the world’s</NoBreak>{" "}
        <NoBreak>leading teams</NoBreak>
      </SectionHeading2>
      <Spacing size="sectionPadding" />
      <QuoteCardGrid>
        <QuoteCardNeue
          sourceName="Jake Roberts"
          color="green"
          fontSize="large"
          sourceDetails={
            <>
              Security Operations Engineer,
              <br />
              R3
            </>
          }
        >
          Tines fell into my lap, and <NoBreak>it was</NoBreak> the perfect fit.
          It was one of those rare cases where a vendor has a product that
          really fits your needs, and Tines did and still does{" "}
          <NoBreak>for me</NoBreak>.
        </QuoteCardNeue>
        <QuoteCardNeue
          sourceName="Shane Lonneman"
          color="purple"
          fontSize="large"
          sourceDetails={
            <>
              Director of Security,
              <br />
              Applied Systems
            </>
          }
        >
          With a single story that automates the access requests and integration
          between GitLab and Snyk, we turned a 10-20 minute process into a
          30-second <NoBreak>end-to-end</NoBreak> automation.
        </QuoteCardNeue>
        <QuoteCardNeue
          /** cspell:disable */
          sourceName="Mike Crider"
          /** cspell:enable */
          color="orange"
          fontSize="large"
          sourceDetails={
            <>
              Cyber Vulnerability Analyst,
              <br />
              Oak Ridge National Laboratory
            </>
          }
        >
          The sheer time savings are immeasurable because it wasn’t even
          possible before. And the confidence we have in what we’re doing is
          incredibly valuable.
        </QuoteCardNeue>
      </QuoteCardGrid>
      <Spacing size="sectionPadding" />
      <FeaturedClientLogoSet />
    </SectionInner>
  </PageSection>
);

export default ClientLogoSetWithQuotesSection;
