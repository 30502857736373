import { styled } from "@linaria/react";
import { cx } from "linaria";
import { useRef, useState } from "react";
import { makeId } from "../../utils/id.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";

type Props = {
  borderRadius?: number;
  borderWidth?: number;
  offset?: number;
  colors: string[];
  animateIn?: boolean;
  spin?: boolean;
  angle?: number;
};

export const GradientBorderWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  &.ready {
    &.animateIn {
      @keyframes GradientBorderWrapEnter {
        from {
          opacity: 0;
          transform: scale(1.5);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }
      animation: GradientBorderWrapEnter 0.5s forwards;
    }
    &:not(.animateIn) {
      opacity: 1;
    }
  }
`;

const GradientBorderSvg = styled.svg`
  position: absolute;
  pointer-events: none;
  overflow: visible;
`;

const GradientBorder = (props: Props) => {
  const [id] = useState(makeId());
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const offset = props.offset ?? 0;
  const borderWidth = props.borderWidth ?? 4;
  const extraPixelsNeeded = offset * 2 + borderWidth * 2;
  useOnMount(() => {
    const handler = (entries: ResizeObserverEntry[]) => {
      const rect = entries[0].target as HTMLElement | SVGElement;
      setWidth(rect.clientWidth);
      setHeight(rect.clientHeight);
    };
    const observer = new ResizeObserver(handler);
    observer.observe(ref.current!.parentElement as HTMLElement);
    return () => {
      observer.disconnect();
    };
  });
  return (
    <GradientBorderWrap
      className={cx(
        width > 0 && height > 0 && "ready",
        props.animateIn && "animateIn"
      )}
      ref={ref}
    >
      <GradientBorderSvg
        width={width + extraPixelsNeeded}
        height={height + extraPixelsNeeded}
        viewBox={`0 0 ${width + extraPixelsNeeded} ${
          height + extraPixelsNeeded
        }`}
        style={{
          top: -offset - borderWidth,
          left: -offset - borderWidth,
        }}
      >
        <defs>
          <linearGradient
            id={`gradientBorderGradient-${id}`}
            gradientTransform={`rotate(${props.angle ?? 120})`}
            gradientUnits="objectBoundingBox"
          >
            {props.colors.map((c, i, arr) => (
              <stop key={i} offset={i / (arr.length - 1)} stopColor={c} />
            ))}
            {props.spin && (
              <animateTransform
                attributeName="gradientTransform"
                type="rotate"
                from="0 .5 .5"
                to="360 .5 .5"
                className="ignore"
                dur="3s"
                repeatCount="indefinite"
              />
            )}
          </linearGradient>
        </defs>
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          width={width + borderWidth + offset * 2}
          height={height + borderWidth + offset * 2}
          rx={props.borderRadius ?? 16}
          stroke={`url(#gradientBorderGradient-${id})`}
          strokeWidth={borderWidth}
          fill="none"
        />
      </GradientBorderSvg>
    </GradientBorderWrap>
  );
};

export default GradientBorder;
