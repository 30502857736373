import { styled } from "@linaria/react";
import { cx } from "linaria";
import React from "react";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PageSection from "./PageSection";
import InfoTooltip, { TooltipContainer } from "../general/InfoTooltip";
import { font } from "../../styles/fonts.styles";

type Props = {
  singleColumn?: boolean;
};

const BenefitsSectionInner = styled.div`
  background-color: ${withOpacity(colorsV4.purple50, 0.5)};
  margin: 0;
  padding: ${rSize("lg")};
  border-radius: ${rSize("radius")};
  &.twoColumns {
    padding: ${rSize("cardSectionPadding")};
  }
  h2 {
    font-family: ${font("serif")};
    font-weight: 400;
    font-size: 3.2rem;
  }
`;

const Separator = styled.hr`
  border-top: 3px solid ${colorsV4.purple};
  opacity: 0.15;
  margin: 2em 0 1.5em;
  background-color: transparent;
`;

const BenefitsChecklistUl = styled.ul`
  list-style: none;
  font-weight: 400;
  padding: 0;
  margin: 0;
  .twoColumns & {
    ${fromTablet} {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-gap: 0 ${rSize("gap")};
    }
  }
`;

const BenefitsLi = styled.li`
  break-inside: avoid;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  padding-left: 32px;
  position: relative;
  display: inline-block;
  width: 100%;
  &:before {
    background-color: ${colorsV4.white};
    border-radius: 50%;
    content: ${`url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.6098 14.7134L16.7007 9.71345L15.2993 8.28655L10.9091 12.5984L8.70071 10.4294L7.29929 11.8563L10.2084 14.7134L10.9091 15.4016L11.6098 14.7134Z' fill='%23${colorsV4.purple.replace(
      "#",
      ""
    )}'/%3E%3C/svg%3E%0A")`};
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    transform: translateY(-2px);
  }
  span {
    display: inline-flex;
  }
  ${TooltipContainer} {
    margin-top: 0.2em;
  }
`;

const BenefitsSection = (props: Props) => {
  return (
    <PageSection>
      <BenefitsSectionInner
        className={cx(props.singleColumn ? "singleColumn" : "twoColumns")}
      >
        <h2>Our benefits</h2>
        <Separator />
        <BenefitsChecklistUl>
          <BenefitsChecklistContent />
        </BenefitsChecklistUl>
      </BenefitsSectionInner>
    </PageSection>
  );
};

export const BenefitsChecklistContent = () => (
  <>
    <BenefitsLi>Competitive salary</BenefitsLi>
    <BenefitsLi>
      Startup equity & extended exercise{" "}
      {/* those spans are here to keep the tooltip icon together with the last word to avoid ugly line breaks */}
      <span>
        window{" "}
        <InfoTooltip>
          Exercise window extends to 5 years after 2 years of working here
        </InfoTooltip>
      </span>
    </BenefitsLi>
    <BenefitsLi>Matching retirement plans</BenefitsLi>
    <BenefitsLi>
      Home office{" "}
      <span>
        setup{" "}
        <InfoTooltip>
          Equipment provided plus a generous allowance for extra expenses
        </InfoTooltip>
      </span>
    </BenefitsLi>
    <BenefitsLi>Private healthcare plans</BenefitsLi>
    <BenefitsLi>
      25 days annual{" "}
      <span>
        leave{" "}
        <InfoTooltip>
          25 days applies regardless of where in the world you work
        </InfoTooltip>
      </span>
    </BenefitsLi>
    <BenefitsLi>Extra company holidays</BenefitsLi>
    <BenefitsLi>Generous parental leave programs</BenefitsLi>
    <BenefitsLi>Flexibility in how and where you work</BenefitsLi>
    <BenefitsLi>Phone and home Internet allowance</BenefitsLi>
  </>
);

export default BenefitsSection;
