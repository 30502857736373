export const ThreeDGlasses = () => (
  <svg
    width="128"
    height="53"
    viewBox="0 0 128 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5704 31.1755C20.5704 32.2947 19.663 33.2019 18.544 33.2019C17.4248 33.2019 16.5176 32.2947 16.5176 31.1755V26.7539C16.5176 25.6347 17.425 24.7275 18.544 24.7275C19.6632 24.7275 20.5704 25.6349 20.5704 26.7539V31.1755Z"
      fill="#D2CCFF"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.7903 26.9283C21.4983 26.9283 26.9361 21.4905 26.9361 14.7825C26.9361 8.07459 21.4983 2.63672 14.7903 2.63672C8.08239 2.63672 2.64453 8.07459 2.64453 14.7825C2.64453 21.4905 8.08239 26.9283 14.7903 26.9283Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M46.179 52.4895C53.9004 52.4895 60.1598 51.5322 60.1598 50.3513C60.1598 49.1704 53.9004 48.2131 46.179 48.2131C38.4577 48.2131 32.1982 49.1704 32.1982 50.3513C32.1982 51.5322 38.4577 52.4895 46.179 52.4895Z"
      fill="#32274C"
    />
    <path
      d="M69.5522 27.3957C68.0316 25.7845 65.891 24.8601 63.6788 24.8601C62.973 24.8601 62.2746 24.9553 61.6012 25.1357C59.8596 15.8355 51.6996 8.79688 41.8942 8.79688C30.82 8.79688 21.8428 17.7747 21.8428 28.8489C21.8428 39.9229 30.8202 48.9001 41.8942 48.9001C52.9686 48.9001 61.9458 39.9229 61.9458 28.8489C61.9458 28.7633 61.9436 28.6781 61.9426 28.5929C62.4864 28.3751 63.0722 28.2603 63.6788 28.2603C64.9778 28.2603 66.1854 28.7817 67.079 29.7291C67.4134 30.0835 67.8642 30.2623 68.3158 30.2623C68.7344 30.2623 69.1536 30.1087 69.4822 29.7991C70.165 29.1543 70.1962 28.0787 69.5522 27.3957Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M93.1361 52.4086C100.857 52.4086 107.117 51.4513 107.117 50.2704C107.117 49.0895 100.857 48.1322 93.1361 48.1322C85.4147 48.1322 79.1553 49.0895 79.1553 50.2704C79.1553 51.4513 85.4147 52.4086 93.1361 52.4086Z"
      fill="#32274C"
    />
    <path
      d="M88.794 48.9007C99.8683 48.9007 108.846 39.9232 108.846 28.8489C108.846 17.7746 99.8683 8.79712 88.794 8.79712C77.7197 8.79712 68.7422 17.7746 68.7422 28.8489C68.7422 39.9232 77.7197 48.9007 88.794 48.9007Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M69.2715 31.4677C69.2715 40.8033 76.8395 48.3713 86.1751 48.3713C95.5103 48.3713 103.079 40.8033 103.079 31.4677C103.079 22.1327 95.5103 14.5641 86.1751 14.5641C76.8395 14.5641 69.2715 22.1327 69.2715 31.4677Z"
      fill="#32274C"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M127.368 13.595C127.368 6.88701 121.93 1.44922 115.222 1.44922C112.786 1.44922 110.517 2.16762 108.616 3.40342C107.873 3.88622 104.767 6.29942 104.01 7.07282C101.866 9.26202 100.544 12.2584 100.544 15.565C100.544 22.273 105.982 27.7106 112.69 27.7106C115.1 27.7106 117.346 27.0078 119.234 25.7968C120.001 25.3052 123.072 22.9172 123.806 22.1846C126.006 19.9872 127.368 16.9508 127.368 13.595Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M105.393 25.1357C105.393 25.1357 107.291 26.1299 108.798 27.1349C108.891 27.1975 108.86 31.1025 108.86 31.1025C108.86 31.1025 106.955 30.4465 106.049 29.2281L105.393 25.1357Z"
      fill="#32274C"
    />
    <path
      d="M69.2715 31.4677C69.2715 40.8033 76.8395 48.3713 86.1751 48.3713C95.5103 48.3713 103.079 40.8033 103.079 31.4677C103.079 22.1327 95.5103 14.5641 86.1751 14.5641C76.8395 14.5641 69.2715 22.1327 69.2715 31.4677Z"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M112.756 24.8024C117.858 24.8024 121.994 20.6665 121.994 15.5647C121.994 10.463 117.858 6.32715 112.756 6.32715C107.654 6.32715 103.519 10.463 103.519 15.5647C103.519 20.6665 107.654 24.8024 112.756 24.8024Z"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M56.1793 31.4677C56.1793 40.8033 48.6113 48.3713 39.2757 48.3713C29.9401 48.3713 22.3721 40.8033 22.3721 31.4677C22.3721 22.1327 29.9401 14.5641 39.2757 14.5641C48.6113 14.5641 56.1793 22.1327 56.1793 31.4677Z"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M39.2757 48.371C48.6113 48.371 56.1793 40.803 56.1793 31.4674C56.1793 22.1318 48.6113 14.5638 39.2757 14.5638C29.9401 14.5638 22.3721 22.1318 22.3721 31.4674C22.3721 40.803 29.9401 48.371 39.2757 48.371Z"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M22.2715 31.4677C22.2715 40.8033 29.8395 48.3713 39.1751 48.3713C48.5107 48.3713 56.0789 40.8033 56.0789 31.4677C56.0789 22.1327 48.5107 14.5641 39.1751 14.5641C29.8395 14.5641 22.2715 22.1327 22.2715 31.4677Z"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M12.7122 24.8024C17.814 24.8024 21.9498 20.6665 21.9498 15.5647C21.9498 10.463 17.814 6.32715 12.7122 6.32715C7.61042 6.32715 3.47461 10.463 3.47461 15.5647C3.47461 20.6665 7.61042 24.8024 12.7122 24.8024Z"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M110.428 31.1755C110.428 32.2947 109.521 33.2019 108.401 33.2019C107.282 33.2019 106.375 32.2947 106.375 31.1755V26.7539C106.375 25.6347 107.282 24.7275 108.401 24.7275C109.521 24.7275 110.428 25.6349 110.428 26.7539V31.1755Z"
      fill="#D2CCFF"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.9717 27.6983L61.6015 25.1355"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.3241 31.6835C59.3241 31.6835 59.4325 37.2087 56.9629 41.5817C56.9629 41.5817 61.2843 37.2603 61.9533 30.4693C61.9533 30.4693 60.7075 29.9653 59.3241 31.6835Z"
      fill="#32274C"
    />
    <path
      d="M112.69 3.41938C105.982 3.41938 100.544 8.85739 100.544 15.5652C100.544 16.2862 100.611 16.9912 100.731 17.6778C97.0767 13.8216 91.9067 11.4162 86.1745 11.4162C76.4267 11.4162 68.3043 18.3732 66.4989 27.5926C65.3727 27.0192 64.1167 26.7076 62.8361 26.7076C61.4785 26.7076 60.1485 27.057 58.9717 27.6984C57.2079 18.4264 49.0609 11.4162 39.2755 11.4162C33.4711 11.4162 28.2439 13.8832 24.5821 17.825C24.7199 17.0924 24.7951 16.3374 24.7951 15.565C24.7951 8.85719 19.3575 3.41919 12.6495 3.41919C5.94171 3.41919 0.503906 8.85719 0.503906 15.565C0.503906 22.273 5.94171 27.7106 12.6495 27.7106C15.5445 27.7106 18.2015 26.6964 20.2881 25.0054C19.5981 27.0336 19.2237 29.2066 19.2237 31.4676C19.2237 42.5422 28.2011 51.5194 39.2753 51.5194C50.2773 51.5194 59.2083 42.6582 59.3241 31.6834C59.3623 31.6496 59.3941 31.6078 59.4359 31.5764C60.1081 31.0676 61.5369 30.6676 62.8359 30.6676C63.9747 30.6676 65.3747 30.9344 66.1233 31.4628C66.1233 31.4646 66.1233 31.4662 66.1233 31.4676C66.1233 42.5422 75.1007 51.5194 86.1745 51.5194C97.2491 51.5194 106.227 42.5422 106.227 31.4676C106.227 29.2504 105.866 27.1176 105.201 25.1238C107.265 26.7428 109.864 27.7106 112.69 27.7106C119.397 27.7106 124.835 22.273 124.835 15.565C124.835 8.85739 119.397 3.41938 112.69 3.41938Z"
      fill="#E9E6FF"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M112.689 24.8024C117.791 24.8024 121.926 20.6665 121.926 15.5647C121.926 10.463 117.791 6.32715 112.689 6.32715C107.587 6.32715 103.451 10.463 103.451 15.5647C103.451 20.6665 107.587 24.8024 112.689 24.8024Z"
      fill="#6191D6"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M69.2715 31.4677C69.2715 40.8033 76.8395 48.3713 86.1751 48.3713C95.5103 48.3713 103.079 40.8033 103.079 31.4677C103.079 22.1327 95.5103 14.5641 86.1751 14.5641C76.8395 14.5641 69.2715 22.1327 69.2715 31.4677Z"
      fill="#6191D6"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M104.982 16.9396C104.982 11.8374 109.118 7.70159 114.22 7.70159C115.972 7.70159 117.61 8.18979 119.006 9.03759C117.334 7.36319 115.023 6.32739 112.47 6.32739C107.368 6.32739 103.232 10.4632 103.232 15.565C103.232 18.9144 105.015 21.8474 107.683 23.467C106.014 21.7958 104.982 19.488 104.982 16.9396Z"
      fill="#32274C"
    />
    <path
      d="M70.2906 33.4357C70.2906 24.1003 77.8584 16.5319 87.1942 16.5319C93.0786 16.5319 98.2598 19.5397 101.287 24.1003C98.5492 18.4561 92.7642 14.5641 86.0696 14.5641C76.734 14.5641 69.166 22.1325 69.166 31.4675C69.166 34.9191 70.2012 38.1281 71.977 40.8029C70.8968 38.5763 70.2906 36.0771 70.2906 33.4357Z"
      fill="#32274C"
    />
    <path
      d="M99.0152 31.3379C98.2692 31.3379 97.6226 30.7817 97.5288 30.0219C97.1374 26.8529 95.426 23.9485 92.8342 22.0527C92.1658 21.5637 92.0202 20.6257 92.5088 19.9569C92.9978 19.2887 93.9362 19.1429 94.6046 19.6319C97.8616 22.0139 100.013 25.6671 100.505 29.6543C100.607 30.4761 100.023 31.2247 99.2008 31.3263C99.1386 31.3341 99.0766 31.3379 99.0152 31.3379Z"
      fill="#E1EBFA"
    />
    <path
      d="M118.721 13.0673C118.448 13.0673 118.184 12.9173 118.052 12.6569C117.616 11.8003 116.981 11.0379 116.214 10.4529C115.885 10.2015 115.821 9.7311 116.073 9.4019C116.324 9.0731 116.794 9.0095 117.124 9.2607C118.068 9.9815 118.852 10.9209 119.389 11.9773C119.576 12.3463 119.429 12.7979 119.06 12.9857C118.951 13.0413 118.835 13.0673 118.721 13.0673Z"
      fill="#E1EBFA"
    />
    <path
      d="M12.6497 24.8024C17.7515 24.8024 21.8873 20.6665 21.8873 15.5647C21.8873 10.463 17.7515 6.32715 12.6497 6.32715C7.54793 6.32715 3.41211 10.463 3.41211 15.5647C3.41211 20.6665 7.54793 24.8024 12.6497 24.8024Z"
      fill="#FF6565"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M56.1793 31.4677C56.1793 40.8033 48.6113 48.3713 39.2757 48.3713C29.9401 48.3713 22.3721 40.8033 22.3721 31.4677C22.3721 22.1327 29.9401 14.5641 39.2757 14.5641C48.6113 14.5641 56.1793 22.1327 56.1793 31.4677Z"
      fill="#FF6565"
      stroke="#32274C"
      strokeMiterlimit="10"
    />
    <path
      d="M23.4965 33.4357C23.4965 24.1003 31.0643 16.5319 40.3999 16.5319C46.2843 16.5319 51.4655 19.5397 54.4927 24.1003C51.7551 18.4561 45.9699 14.5641 39.2755 14.5641C29.9403 14.5641 22.3721 22.1325 22.3721 31.4675C22.3721 34.9191 23.4073 38.1281 25.1827 40.8029C24.1027 38.5763 23.4965 36.0771 23.4965 33.4357Z"
      fill="#32274C"
    />
    <path
      d="M52.3275 31.3379C51.5815 31.3379 50.9353 30.7817 50.8411 30.0219C50.4497 26.8529 48.7387 23.9485 46.1465 22.0527C45.4783 21.5637 45.3325 20.6257 45.8215 19.9569C46.3103 19.2887 47.2485 19.1429 47.9169 19.6319C51.1741 22.0139 53.3251 25.6671 53.8175 29.6543C53.9189 30.4761 53.3351 31.2247 52.5133 31.3263C52.4509 31.3341 52.3889 31.3379 52.3275 31.3379Z"
      fill="#F5D0E0"
    />
    <path
      d="M5.03651 16.9396C5.03651 11.8374 9.17231 7.70159 14.2741 7.70159C16.0267 7.70159 17.6649 8.18979 19.0611 9.03759C17.3889 7.36319 15.0777 6.32739 12.5247 6.32739C7.42291 6.32739 3.28711 10.4632 3.28711 15.565C3.28711 18.9144 5.06991 21.8474 7.73811 23.467C6.06891 21.7958 5.03651 19.488 5.03651 16.9396Z"
      fill="#32274C"
    />
    <path
      d="M19.0505 13.0673C18.7773 13.0673 18.5139 12.9173 18.3817 12.6569C17.9461 11.8003 17.3107 11.0379 16.5439 10.4529C16.2147 10.2015 16.1517 9.7311 16.4027 9.4019C16.6541 9.0731 17.1245 9.0095 17.4537 9.2607C18.3983 9.9815 19.1815 10.9209 19.7183 11.9773C19.9061 12.3463 19.7589 12.7979 19.3899 12.9857C19.2809 13.0413 19.1649 13.0673 19.0505 13.0673Z"
      fill="#F5D0E0"
    />
    <path
      d="M16.501 29.1917C16.501 29.1917 19.025 28.2739 19.7134 27.4135V25.6351C19.7134 25.6351 17.4762 27.1265 16.501 27.1265V29.1917Z"
      fill="#32274C"
    />
    <path
      d="M106.333 26.094L106.448 27.2414C106.448 27.2414 108.463 28.8754 110.242 29.3344L110.062 27.5282L106.333 26.094Z"
      fill="#32274C"
    />
    <path
      d="M106.374 32.4675C106.374 32.4675 105.641 38.9343 104.308 40.8007C104.308 40.8007 107.441 38.1339 108.041 33.2675L106.374 32.4675Z"
      fill="#32274C"
    />
  </svg>
);
