import { styled } from "@linaria/react";
import { DatoCmsLibraryCollection } from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  onlyPhones,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import Spacing from "../layout/Spacing";
import LibraryPageViewSwitcher, {
  LibraryPageViewType,
} from "./LibraryPageViewSwitcher";
import { LibraryState } from "./WithLibraryState";
import {
  darkModeLinariaCSS,
  lightModeLinariaCSS,
} from "../../utils/colorScheme.utils";
import { ReactNode } from "react";
import { PseudoLinkSpan } from "../basic/PseudoLink";
import TeamCollectionImage, {
  TeamCollectionImageWrap,
} from "./TeamCollectionImage";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import { css, cx } from "linaria";
import { LibraryToolImageWithDarkModeWrap } from "./LibraryToolImageWithDarkMode";
import { LibraryFindAndFilterBar } from "./LibraryFindAndFilterBar";
import { withOpacity } from "../../styles/colorsV4.styles";
import AvoidWidows from "../typography/AvoidWidows";
import { CommunityEditionIcon } from "../illustrations/spots/CommunityEditionIcon";
import { Link } from "gatsby";
import { LibraryCollectionType } from "../../utils/libraryCollectionType.enum";

type Props = {
  collection: Partial<DatoCmsLibraryCollection>;
  view?: LibraryPageViewType;
  handleViewSwitch?: (view: LibraryPageViewType) => void;
  libraryState: LibraryState;
  description?: ReactNode;
  neutralColor?: boolean;
  compact?: boolean;
  icon?: ReactNode;
  topTagLabel?: string;
  topTagLink?: string;
  totalCount: number;
};

export const LibraryPageHeroWrap = styled.header`
  position: relative;
  background-color: var(--ac, ${colors.purple});
  color: ${colors.white};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  ${darkModeLinariaCSS(`background-color: var(--ac600, ${colors.purple600})`)}
  ${onlyPhones} {
    text-align: center;
  }
  &[data-appearance="lighter"] {
    background-color: var(--ac100, ${colors.purple100});
    color: var(--ac700, ${colors.purple700});
    ${darkModeLinariaCSS(`
      background-color: var(--ac80050, ${withOpacity(colors.purple800, 0.5)});
      color: var(--ac300, ${colors.purple300});
    `)}
  }
  &.neutralColor {
    background-color: ${colors.light200};
    color: ${colors.dark500};
    ${darkModeLinariaCSS(`
      background-color: ${colors.dark500};
      color: ${colors.light300};
    `)}
  }
  ${TeamCollectionImageWrap} {
    border-radius: 2.4rem;
    ${uptoTabletMd} {
      margin: 2em auto;
      width: 15rem;
      height: 15rem;
    }
  }
  --SectionPaddingLeft: 2rem;
  ${fromTablet} {
    --SectionPaddingLeft: 4rem;
  }
  ${fromDesktop} {
    --SectionPaddingLeft: 6rem;
  }
`;

const PaddingContainer = styled.div`
  position: relative;
  padding-left: var(--SectionPaddingLeft);
  padding-right: 1.5em;
  ${onlyPhones} {
    padding-top: 1.5em;
  }
  ${fromTablet} {
    padding-right: ${rSize("xl")};
  }
`;

const HeaderContent = styled.div`
  position: relative;
  ${fromTabletMd} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 33rem;
    .compact & {
      min-height: 25rem;
    }
  }
  .hasTopTag & {
    padding-top: 1em;
  }
`;

const HeaderTextContent = styled.div`
  padding-top: ${rSize("cardSectionPadding")};
  ${fromTabletMd} {
    padding-right: ${rSize("cardSectionPadding")};
    padding-bottom: ${rSize("xl")};
  }
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
`;

const PreTagline = styled.p`
  font-size: 1.8rem;
  ${fromTablet} {
    font-size: 2rem;
  }
`;

const Tagline = styled.h1`
  font-family: ${font("serif")};
  font-weight: 400;
  font-size: 3rem;
  max-width: 18em;
  text-wrap: balance; // supported in Chrome but not in safari
  line-height: 1;
  ${fromTablet} {
    font-size: 3.2rem;
    letter-spacing: -0.005em;
  }
  ${fromDesktopMd} {
    font-size: 4rem;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  margin-bottom: 1em;
  max-width: 33.3em;
  line-height: 1.3;
  ${uptoTabletMd} {
    margin-left: auto;
    margin-right: auto;
  }
  ${fromDesktopMd} {
    font-size: 1.6rem;
  }
  ${PseudoLinkSpan} {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: ${withOpacity(colors.white, 0.2)};
    &:hover {
      text-decoration-color: ${withOpacity(colors.white, 0.6)};
    }
    .neutralColor & {
      color: ${colors.purple};
      text-decoration-color: ${withOpacity(colors.purple, 0.2)};
      ${darkModeLinariaCSS(`
        color: ${colors.purple300};
        text-decoration-color: ${withOpacity(colors.purple300, 0.2)};
      `)}
      &:hover {
        text-decoration-color: ${withOpacity(colors.purple, 0.6)};
        ${darkModeLinariaCSS(`
          text-decoration-color: ${withOpacity(colors.purple300, 0.6)};
        `)}
      }
    }
  }
`;

const IllustrationWrap = styled.div`
  padding-top: 14px;
  padding-bottom: 38px;
  pointer-events: none;
  ${uptoTabletMd} {
    width: 275px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTabletMd} {
    padding-bottom: 14px;
    height: 200px;
    .noTopTag & {
      margin-top: 1em;
    }
  }
  ${fromDesktop} {
    height: 300px;
  }
  ${fromDesktopMd} {
    height: 350px;
  }
  img {
    ${uptoTabletMd} {
      max-width: 100%;
      height: auto;
    }
    ${fromTabletMd} {
      width: auto;
      max-height: 100%;
    }
  }
`;

const IconWrap = styled.div`
  flex: 0 0 auto;
  background-color: ${withOpacity(colors.white, 0.1)};
  .neutralColor & {
    ${lightModeLinariaCSS(`
      background-color: ${colors.light300};
    `)}
  }
  [data-appearance="lighter"] & {
    background-color: var(--ac10, ${withOpacity(colors.purple, 0.1)});
  }
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 15rem;
  ${onlyPhones} {
    margin: 2em auto;
  }
  ${LibraryToolImageWithDarkModeWrap} {
    width: 4.8rem;
    height: 4.8rem;
  }
  > * {
    max-width: 66%;
    height: auto;
  }
`;

const TopTag = css`
  display: block;
  position: absolute;
  top: 0;
  ${uptoTabletMd} {
    left: 50%;
    transform: translateX(-50%);
  }
  ${fromTabletMd} {
    left: var(--SectionPaddingLeft);
  }
  background-color: ${withOpacity(colors.white, 0.1)};
  text-decoration: none;
  font-size: 1.4rem;
  .neutralColor & {
    ${lightModeLinariaCSS(`
      background-color: ${colors.light300};
    `)}
    > span {
      opacity: 0.8;
    }
  }
  [data-appearance="lighter"] & {
    background-color: var(--ac10, ${withOpacity(colors.purple, 0.1)});
  }
  padding: 0.75em 1em;
  font-weight: 600;
  border-radius: 0 0 0.5em 0.5em;
  &[href] {
    background-color: ${withOpacity(colors.white, 0.2)};
    &:hover {
      .neutralColor & {
        ${lightModeLinariaCSS(`background-color: ${colors.light400};`)}
      }
      [data-appearance="lighter"] & {
        background-color: var(--ac20, ${withOpacity(colors.purple, 0.2)});
      }
    }
  }
`;

const LibraryPageHero = (props: Props) => {
  const collection = props.collection;
  const { image } = collection;
  const canSwitchView = !!(
    props.view &&
    props.handleViewSwitch &&
    props.collection.pageSections &&
    props.collection.pageSections.length > 0
  );
  const shouldShowSearchBar = !props.view || props.view === "all";
  const isCommunityPage = collection.slug === "community";
  const appearance =
    collection.collectionType === LibraryCollectionType.team || isCommunityPage
      ? "lighter"
      : "full-color";
  return (
    <LibraryPageHeroWrap
      data-appearance={appearance}
      className={cx(
        props.neutralColor && "neutralColor",
        props.topTagLabel ? "hasTopTag" : "noTopTag",
        props.compact && "compact"
      )}
    >
      <PaddingContainer>
        <HeaderContent>
          <HeaderTextContent>
            {collection.preTagline ? (
              <PreTagline>{collection.preTagline}</PreTagline>
            ) : (
              <Spacing size=".25em" />
            )}
            <Tagline>
              <WithCustomInlineFormatting as="span">
                {collection.tagline || collection.name}
              </WithCustomInlineFormatting>
            </Tagline>
            <Description>
              {props.description ?? (
                <AvoidWidows as="fragment">
                  {collection.description}
                </AvoidWidows>
              )}
            </Description>
          </HeaderTextContent>
          {props.icon ? (
            <IconWrap>{props.icon}</IconWrap>
          ) : (
            (image?.url || props.icon || isCommunityPage) &&
            (isCommunityPage ? (
              <IconWrap>
                <CommunityEditionIcon />
              </IconWrap>
            ) : collection.collectionType === LibraryCollectionType.team ? (
              <TeamCollectionImage
                collection={collection as DatoCmsLibraryCollection}
              />
            ) : (
              <IllustrationWrap>
                {image?.url && (
                  <img
                    width={`${image.width}`}
                    height={`${image.height}`}
                    src={image.url}
                    style={{
                      aspectRatio: `${image.width} / ${image.height}`,
                    }}
                  />
                )}
              </IllustrationWrap>
            ))
          )}
        </HeaderContent>
        <LibraryPageViewSwitcher
          view={props.view ?? "all"}
          handleViewSwitch={props.handleViewSwitch ?? (() => {})}
          totalCount={props.totalCount}
          canSwitchView={canSwitchView}
          entryType={
            props.libraryState.entryType === "story" ? "stories" : "tags"
          }
        />
      </PaddingContainer>
      {shouldShowSearchBar && (
        <LibraryFindAndFilterBar
          state={props.libraryState}
          searchBarPlaceholder={props.collection.searchbarPlaceholderText}
        />
      )}
      {props.topTagLabel &&
        (props.topTagLink ? (
          <Link className={TopTag} to={props.topTagLink}>
            <span>{props.topTagLabel}</span>
          </Link>
        ) : (
          <span className={TopTag}>
            <span>{props.topTagLabel}</span>
          </span>
        ))}
    </LibraryPageHeroWrap>
  );
};

export default LibraryPageHero;
