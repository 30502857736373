import { styled } from "@linaria/react";
import { cx } from "linaria";
import { useRef, useState } from "react";
import {
  fromDesktop,
  fromDesktopMl,
  fromPhoneLg,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { runAfter } from "../../../utils/promises.utils";
import { useStateWithRef } from "../../../utils/stateWithRef.hook";
import { useVisibilityObserver } from "../../../utils/useSizeAndVisibilityObserver.utils";
import DemoHeader from "./DemoHeader";
import DemoSidebarLeft from "./DemoSidebarLeft";
import DemoStoryboardWithSidebar from "./DemoStoryboardWithSidebar";
import { DemoStoryContext, DemoStoryContextValue } from "./DemoStoryContext";
import { DemoStoryMockEventEmitter } from "./demoStoryMockEventEmitter";
import DemoStoryNotificationCard, {
  DemoStoryNotificationConfig,
} from "./DemoStoryNotificationCard";
import { colors } from "../../../styles/colors.styles";
import { RuntimeStoryRecord } from "../../../types/tines.types";

const DemoFrameOuter = styled.div`
  ${uptoTabletMd} {
    border-radius: 0.5em;
  }
  img {
    max-width: 100%;
  }
`;

const DemoFrame = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.lightest};
  color: ${colorsV4.warmBlack};
  border-radius: 0.5em;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  text-align: left;
`;

const DemoFrameContent = styled.div`
  position: relative;
  display: grid;
  align-items: stretch;
  grid-template-columns: auto minmax(0, 1fr);
  border-radius: 0 0 0.5em 0.5em;
  ${fromPhoneLg} {
    grid-template-columns: 18rem minmax(0, 1fr);
  }
  ${fromDesktop} {
    grid-template-columns: 26rem minmax(0, 1fr);
  }
  ${fromDesktopMl} {
    grid-template-columns: 28rem minmax(0, 1fr);
  }
`;

const InteractiveStoryDemo = (props: {
  shaded?: boolean;
  inspectable?: boolean;
  story: RuntimeStoryRecord;
  dropZoneActionNameMatcher: string;
  notification: DemoStoryNotificationConfig;
  maxEvents: number;
  possibleTriggerValues: [string, string];
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [ready, setReady] = useState(false);
  const readyRef = useRef(ready);
  readyRef.current = ready;

  const [isDraggingRef, setIsDragging] = useStateWithRef(false);
  const [isBouncingBackRef, setIsBouncingBack] = useStateWithRef(false);
  const [hasDroppedRef, setHasDropped] = useStateWithRef(false);
  const [shouldShowNotificationRef, setShouldShowNotification] =
    useStateWithRef(false);

  const [eventEmittersRef, setEventEmitters] = useStateWithRef<
    DemoStoryMockEventEmitter[]
  >([]);

  const context: DemoStoryContextValue = {
    get ready() {
      return readyRef.current;
    },
    get isDragging() {
      return isDraggingRef.current;
    },
    setIsDragging,
    get isBouncingBack() {
      return isBouncingBackRef.current;
    },
    setIsBouncingBack,
    get hasDropped() {
      return hasDroppedRef.current;
    },
    setHasDropped,
    get eventEmitters() {
      return eventEmittersRef.current;
    },
    setEventEmitters,
    get shouldShowNotification() {
      return shouldShowNotificationRef.current;
    },
    setShouldShowNotification,
    reset: () => {
      setReady(false);
      setIsDragging(false);
      setHasDropped(false);
      setShouldShowNotification(false);
      eventEmittersRef.current.forEach(emitter => emitter.dispose());
      runAfter(() => {
        setReady(true);
      });
    },
  };
  useVisibilityObserver(ref, {
    onBecomeVisible: () => {
      setTimeout(() => {
        setReady(true);
      }, 500);
    },
  });
  useOnMount(() => {
    return () => {
      context.reset();
    };
  });
  return (
    <DemoStoryContext.Provider value={context}>
      <DemoFrameOuter>
        <DemoFrame
          className={cx(ready && "ready", props.shaded && "shaded")}
          ref={ref}
        >
          <DemoHeader />
          <DemoFrameContent>
            <DemoSidebarLeft shaded={props.shaded} />
            <DemoStoryboardWithSidebar
              shaded={props.shaded}
              story={props.story}
              dropZoneActionNameMatcher={props.dropZoneActionNameMatcher}
              maxEvents={props.maxEvents}
              possibleTriggerValues={props.possibleTriggerValues}
              inspectable={props.inspectable}
            />
          </DemoFrameContent>
          {context.shouldShowNotification && (
            <DemoStoryNotificationCard notification={props.notification} />
          )}
        </DemoFrame>
      </DemoFrameOuter>
    </DemoStoryContext.Provider>
  );
};

export default InteractiveStoryDemo;
