import { styled } from "@linaria/react";
import { DatoCmsCollapsableSection } from "../../../graphql-types";
import { colors, withOpacity } from "../../styles/colors.styles";
import StandardArticleStructuredTextWithCustomBlocks from "../articles/StructuredTextWithCustomBlocks";

type Props = {
  record: DatoCmsCollapsableSection;
};

const CollapsableSectionWrap = styled.details`
  summary {
    font-weight: 600;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 0.25em;
    &:hover {
      background-color: ${withOpacity(colors.purple, 0.1)};
    }
  }
`;

const Content = styled.div`
  margin-left: 0.75em;
  padding-left: 1em;
  padding-top: 1em;
  border-left: 2px solid ${withOpacity(colors.purple, 0.25)};
`;

const CollapsableSection = ({ record }: Props) => {
  return (
    <CollapsableSectionWrap>
      <summary>{record.heading}</summary>
      <Content>
        <StandardArticleStructuredTextWithCustomBlocks value={record.details} />
      </Content>
    </CollapsableSectionWrap>
  );
};

export default CollapsableSection;
