import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import { StoryDescriptor } from "../../types/helper.types";
import StoryEntry from "./StoryEntry";
import {
  fromDesktopMd,
  fromTabletLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  &[data-columns="2"] {
    ${fromTabletLg} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &[data-columns="3"] {
    ${fromTabletMd} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${fromDesktopMd} {
      grid-template-columns: repeat(3, 1fr);
      > * {
        aspect-ratio: 1/1;
      }
    }
  }
`;

export const StoryGrid = (props: {
  stories: StoryDescriptor[];
  noDescription?: boolean;
  doNotApplyCommunityStyling?: boolean;
}) => {
  const columns =
    props.stories.length === 1 ||
    props.stories.length === 2 ||
    props.stories.length === 4
      ? 2
      : 3;
  return (
    <Grid data-columns={columns}>
      {props.stories.map(descriptor => {
        return (
          <StoryEntry
            story={descriptor}
            key={descriptor.id}
            withDescription={!props.noDescription && columns === 2}
            size={columns === 2 ? "medium" : "small"}
            doNotApplyCommunityStyling={props.doNotApplyCommunityStyling}
          />
        );
      })}
    </Grid>
  );
};
