import { styled } from "@linaria/react";
import React from "react";
import {
  fromDesktopMd,
  uptoDesktopMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";

const OpenEndedQuestionFieldContainer = styled.div`
  background-color: ${colorsV4.canvas700};
  padding: 1em;
  border-radius: 1.5em;
  overflow: hidden;
  text-align: left;
  ${darkModeLinariaCSS(`
    background-color: ${colorsV4.warmBlack900};
  `)}
  ${fromDesktopMd} {
    padding: 0;
    border-radius: 1em;
  }
  header {
    border: 2px solid ${colorsV4.canvas700};
    ${darkModeLinariaCSS(`
      border: 2px solid ${colorsV4.warmBlack900};
    `)}
    ${fromDesktopMd} {
      padding: 1em;
    }
  }
  p {
    font-weight: 500;
    line-height: 1.2;
    ${uptoDesktopMd} {
      margin-bottom: 1em;
    }
  }
  textarea {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    background-color: ${colorsV4.white};
    border: 2px solid ${colorsV4.canvas700};
    resize: none;
    font: inherit;
    color: inherit;
    border-radius: 0 0 1em 1em;
    ${darkModeLinariaCSS(`
      background-color: ${colorsV4.warmBlack};
      border: 2px solid ${colorsV4.warmBlack900};
    `)}
  }
`;

export const OpenEndedQuestionField = (props: {
  question: string;
  value: string;
  setValue: (v: string) => void;
}) => {
  const { question, value, setValue } = props;

  const handleChange = (e: React.FormEvent) => {
    setValue((e.target as HTMLTextAreaElement).value);
  };
  return (
    <OpenEndedQuestionFieldContainer className="YesOrNoField">
      <header>
        <p>{question}</p>
      </header>
      <textarea value={value} onChange={handleChange} rows={3} />
    </OpenEndedQuestionFieldContainer>
  );
};

export default OpenEndedQuestionField;
