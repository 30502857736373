import { styled } from "@linaria/react";
import { PNG_ILLUSTRATIONS, PngIllustrationName } from "./PNGIllustrations";
import StaticImageFrame from "../basic/StaticImageFrame";

export const IllustrationContainer = styled.picture`
  display: block;
`;

export const PNGIllustrationImg = styled.img`
  display: block;
  pointer-events: none;
`;

/** @deprecated */
export const PNGIllustration = (props: {
  name: PngIllustrationName;
  className?: string;
  lazy?: boolean;
}) => {
  const { src, src2x, width, height, alt } = PNG_ILLUSTRATIONS[props.name];
  return (
    <IllustrationContainer className={props.className}>
      <StaticImageFrame
        src={src}
        src2x={src2x}
        alt={alt}
        intrinsicWidth={width}
        intrinsicHeight={height}
        lazy={props.lazy}
        overflow="visible"
      />
    </IllustrationContainer>
  );
};
