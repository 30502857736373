export const StacksOfBlocksIcon = () => (
  <svg
    width="71"
    height="81"
    viewBox="0 0 71 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.2468 75.2317H24.8306V80.22H47.8819L54.2468 75.2317Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M69.9229 69.9802H40.5068V74.9685H63.558L69.9229 69.9802Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.2053 41.4635C30.2053 39.7589 28.8237 38.377 27.1188 38.377H9.01084C8.10255 38.377 7.28606 38.7692 6.72128 39.3933C6.226 39.9408 2.29742 43.8121 1.73627 44.3892C1.19638 44.945 0.86377 45.7037 0.86377 46.5399V65.4847H25.1445L30.2051 60.4083L30.2053 41.4635Z"
      fill="#6DA2F0"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.1445 65.4847V46.5399C25.1445 44.8353 23.7629 43.4534 22.058 43.4534H3.95027C2.24573 43.4534 0.86377 44.8353 0.86377 46.5399V65.4847H25.1445Z"
      fill="#96BEFA"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.3525 44.4758L29.2499 39.2322"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.76823 16.8818L0.86377 21.8783V40.2427C0.86377 41.9473 2.24538 43.3292 3.95027 43.3292H22.0579C22.9082 43.3292 23.678 42.9856 24.2364 42.4293C24.7972 41.8704 28.5863 37.9872 29.1449 37.4287C29.7034 36.8701 30.0488 36.0984 30.0488 35.2461V16.8818H5.76823Z"
      fill="#EB81AF"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M0.86377 21.8782V40.2426C0.86377 41.9472 2.24538 43.3292 3.95028 43.3292H22.0579C23.7626 43.3292 25.1444 41.9472 25.1444 40.2426V21.8782H0.86377Z"
      fill="#FDB2D2"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M25.1445 21.8783L30.0492 16.8818"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.1589 43.5093C23.1589 43.5093 24.4351 44.2273 24.754 45.1841L29.7785 40.0001C29.7785 40.0001 28.9013 38.6443 28.1037 38.8038L23.1589 43.5093Z"
      fill="#4D3E78"
    />
    <path
      d="M62.2607 46.0911C62.2607 44.3864 60.8787 43.0046 59.174 43.0046H41.0659C40.158 43.0046 39.3415 43.3969 38.7767 44.021C38.2814 44.5685 34.3528 48.4398 33.7917 49.0169C33.2515 49.5727 32.9192 50.3314 32.9192 51.1674V74.9685H57.1996L62.2605 69.892L62.2607 46.0911Z"
      fill="#8173E6"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.1996 74.9684V51.1676C57.1996 49.4632 55.8183 48.0811 54.1134 48.0811H36.0057C34.301 48.0811 32.9192 49.463 32.9192 51.1676V74.9686L57.1996 74.9684Z"
      fill="#B0A6F9"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.408 49.1034L61.3048 43.8596"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.8238 25.8528L32.9192 30.8492V44.8702C32.9192 46.5748 34.3008 47.9567 36.0057 47.9567H54.1136C54.9638 47.9567 55.7336 47.613 56.2922 47.0569C56.853 46.4978 60.6421 42.6149 61.2006 42.0563C61.7592 41.4978 62.1046 40.7262 62.1046 39.8738V25.8528H37.8238Z"
      fill="#36996F"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.9192 30.8494V44.8704C32.9192 46.5749 34.3008 47.9569 36.0057 47.9569H54.1136C55.8183 47.9569 57.2001 46.5749 57.2001 44.8704V30.8494H32.9192Z"
      fill="#81D8B4"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.2 30.8492L62.1042 25.8528"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.7603 61.9408C43.7603 60.236 42.3787 58.8542 40.6738 58.8542H22.5657C21.6577 58.8542 20.8411 59.2465 20.2765 59.8704C19.781 60.4181 15.8524 64.2893 15.2914 64.8665C14.7512 65.4223 14.4189 66.181 14.4189 67.0172V80.2198H38.7001L43.7603 75.1434V61.9408Z"
      fill="#F0946C"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.7002 80.22V67.0174C38.7002 65.3127 37.3186 63.9309 35.6137 63.9309H17.5054C15.8007 63.9309 14.4189 65.3127 14.4189 67.0174V80.22H38.7002Z"
      fill="#FBB598"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.9082 64.9535L42.8054 59.7097"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.3234 41.8827L14.4189 46.8792V60.9C14.4189 62.6047 15.8002 63.9865 17.5053 63.9865H35.6134C36.4639 63.9865 37.2334 63.6428 37.792 63.0867C38.3528 62.5278 42.1422 58.6447 42.7004 58.0861C43.2591 57.5277 43.6045 56.756 43.6045 55.9036V41.8826L19.3234 41.8827Z"
      fill="#FABE64"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.4189 46.8792V60.9C14.4189 62.6047 15.8002 63.9865 17.5053 63.9865H35.6134C37.3181 63.9865 38.6999 62.6047 38.6999 60.9V46.8792H14.4189Z"
      fill="#FFD18C"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.6997 46.8792L43.6043 41.8828"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.7137 29.3797C55.9947 29.3797 59.4652 28.633 59.4652 27.7118C59.4652 26.7907 55.9947 26.0439 51.7137 26.0439C47.4326 26.0439 43.9622 26.7907 43.9622 27.7118C43.9622 28.633 47.4326 29.3797 51.7137 29.3797Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M48.3777 28.9072C54.3433 28.9072 59.1794 24.0711 59.1794 18.1054C59.1794 12.1398 54.3433 7.30371 48.3777 7.30371C42.412 7.30371 37.5759 12.1398 37.5759 18.1054C37.5759 24.0711 42.412 28.9072 48.3777 28.9072Z"
      fill="#EB81AF"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M55.2146 48.1371C55.2146 48.1371 56.4906 48.855 56.8097 49.8119L61.8341 44.6281C61.8341 44.6281 60.9566 43.272 60.1593 43.4318L55.2146 48.1371Z"
      fill="#4D3E78"
    />
    <path
      d="M36.9424 63.7881C36.9424 63.7881 38.2184 64.5059 38.5375 65.4629L43.5619 60.2789C43.5619 60.2789 42.6848 58.9231 41.8871 59.0826L36.9424 63.7881Z"
      fill="#4D3E78"
    />
    <path
      d="M41.9441 43.574H25.8035V45.0585H40.47L41.9441 43.574Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
    />
    <path
      d="M41.4573 33.8293L36.0638 24.4868H25.2756L23.3013 26.3734L17.9075 35.7159L23.3013 45.0584H34.0892L36.0638 43.1716L41.4573 33.8293Z"
      fill="#8173E6"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.3013 45.0585L17.9075 35.716L23.3013 26.3735H34.0892L39.4831 35.716L34.0892 45.0585H23.3013Z"
      fill="#B0A6F9"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.0896 26.3734L36.0642 24.4868"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.4829 35.7159L41.4571 33.8293"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.7225 17.0234H16.0962V19.676H27.0281L29.7225 17.0234Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.6918 1H13.5238C12.4184 1 11.4173 1.4391 10.6821 2.15186C10.2371 2.58353 8.63627 4.34807 8.25094 4.81258C7.66524 5.51912 7.31257 6.42585 7.31274 7.4159V15.5839C7.31274 17.844 9.14488 19.6762 11.405 19.6762H19.573C20.6627 19.6762 21.6511 19.2493 22.3834 18.5544C22.8435 18.1175 24.3867 16.3848 24.7568 15.9675C25.3958 15.2468 25.7841 14.2996 25.7841 13.2603V5.09229C25.7841 2.8323 23.9518 1 21.6918 1Z"
      fill="#2A7857"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.6653 15.584C23.6653 17.8442 21.833 19.6763 19.573 19.6763H11.405C9.14488 19.6763 7.31274 17.8442 7.31274 15.584V7.41602C7.31274 5.15586 9.14488 3.32373 11.405 3.32373H19.573C21.833 3.32373 23.6653 5.15603 23.6653 7.41602V15.584Z"
      fill="#49B889"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M22.5723 4.63304L24.668 2.2854"
      stroke="#4D3E78"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
