import { styled } from "@linaria/react";
import { DatoCmsColumnsWithIconsSection } from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import { rSize } from "../../../styles/responsiveSizes.styles";
import ReactMarkdown from "react-markdown";
import WithCustomInlineFormatting from "../../typography/WithCustomInlineFormatting";
import {
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../../styles/colors.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Column = styled.div`
  text-align: center;
  ${fromTabletMd} {
    text-align: left;
  }
  > * {
    ${fromTabletMd} {
      padding-right: 1em;
    }
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  h3 {
    font-size: 2.4rem;
    font-weight: 600;
    ${fromTabletMd} {
      padding-right: 1em;
    }
  }
  p,
  li {
    font-weight: 500;
    line-height: 1.3;
  }
  ul,
  ol {
    margin-top: 0.75em;
    padding-left: 1.5em;
    text-align: left;
    ${uptoTabletMd} {
      background-color: ${colors.purple50};
      border: 1px solid ${colors.purple100};
      padding: 0.75em 1em 1em 2em;
      border-radius: ${rSize("radius")};
    }
  }
  li {
    margin-top: 0.33em;
  }
`;

export const LPColumnsWithIconsSection = ({
  section,
}: {
  section: DatoCmsColumnsWithIconsSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Grid>
        {section.columns?.map((c, i) => (
          <Column key={i}>
            {c?.spotIcon?.image?.url && <img src={c.spotIcon.image.url} />}
            <WithCustomInlineFormatting as="h3">
              {c?.heading}
            </WithCustomInlineFormatting>
            {c?.body && <ReactMarkdown>{c?.body}</ReactMarkdown>}
          </Column>
        ))}
      </Grid>
    </PageSection>
  );
};
