import { styled } from "@linaria/react";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { DatoCmsPerson } from "../../../graphql-types";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import ImageFrame from "../basic/ImageFrame";
import StaticImageFrame from "../basic/StaticImageFrame";
import { PartialAndNullable } from "../../types/helper.types";

type Props = {
  person?: PartialAndNullable<DatoCmsPerson>;
  photoIcon?: DatoCmsPerson["photoIcon"];
  src?: string;
  size?: string | number;
  colorHex?: string;
  name?: string;
  inline?: boolean;
};

type P = {
  inline?: boolean;
  size?: string | number;
};

export const PersonPhotoIconContainer = styled.div<P>`
  display: ${p => (p.inline ? "inline-block" : "block")};
  width: ${p => valueWithOptionalUnit(p.size, "3em")};
  aspect-ratio: 1/1;
  > * {
    width: 100%;
    height: 100%;
  }
`;

export const PersonPhotoIconPlaceholder = styled.div<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 120%;
  font-weight: 700;
  background-color: ${p => withOpacity(p.colorHex ?? colorsV4.purple, 0.3)};
  color: ${p => p.colorHex ?? colorsV4.purple};
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
`;

const PersonPhotoIcon = (props: Props) => {
  const photo = props.src
    ? {
        url: props.src,
      }
    : props.photoIcon ?? props.person?.photoIcon;
  const color = props.colorHex ?? colorsV4.purple;
  const size = props.size ?? 64;
  return (
    <PersonPhotoIconContainer inline={props.inline} size={size}>
      {photo ? (
        photo.url ? (
          <StaticImageFrame
            src={photo.url}
            backgroundColor={color}
            aspectRatio={"1 / 1"}
            borderRadius="50%"
            renderedWidth={size}
            renderedHeight={size}
          />
        ) : (
          <ImageFrame
            image={photo}
            aspectRatio={1}
            fallbackAspectRatio={1}
            borderRadius="50%"
            renderedWidth={size}
            renderedHeight={size}
          />
        )
      ) : (
        <PersonPhotoIconPlaceholder
          colorHex={color}
          size={props.size}
          children={
            (props.name ??
              props.person?.fullName ??
              props.person?.name ??
              props.person?.surname)?.[0]
          }
        />
      )}
    </PersonPhotoIconContainer>
  );
};

export default PersonPhotoIcon;
