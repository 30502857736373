import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import { brandColorThemeVar } from "../../styles/colors.styles";

type Props = {
  title: string;
  description: string;
  link?: string;
  onClick?: () => void;
};

const LegalLinkStyle = css`
  appearance: none;
  min-height: 7em;
  grid-gap: 1.8em;
  text-decoration: none;
  width: 100%;
  background-color: ${brandColorThemeVar(50)};
  color: ${brandColorThemeVar(700)};
  border: 0;
  padding: 1.5em;
  border-radius: 0.5em;
  background-position: 0 0;
  cursor: pointer;
  ${fromTablet} {
    padding: 1.375em 1.5em;
  }
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:not(.disabled) {
    @media (hover: hover) {
      &:hover {
        background-color: ${brandColorThemeVar(100)};
      }
    }
  }
`;

const Title = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.15;
  letter-spacing: -0.01em;
`;

const Description = styled.p`
  font-size: 1.4rem;
`;

const LegalLink = ({ title, description, link, onClick }: Props) => {
  const inner = (
    <>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </>
  );
  return link ? (
    <Link to={link} className={LegalLinkStyle}>
      {inner}
    </Link>
  ) : (
    <span
      className={cx(LegalLinkStyle, !onClick && "disabled")}
      onClick={onClick}
    >
      {inner}
    </span>
  );
};

export default LegalLink;
