export const RightHandPointingLeft = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path
      d="M28.9102 16.3201C28.8142 18.8358 27.0533 20.811 24.9772 20.7317C22.9011 20.6525 21.2959 18.5488 21.3919 16.033C21.488 13.5172 23.2489 11.5421 25.325 11.6213C27.4011 11.7006 29.0063 13.8043 28.9102 16.3201Z"
      fill="#FD975D"
      stroke="#E16521"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M14.1484 7.55973C14.0669 7.70074 13.9842 8.34504 13.9344 9.21593C12.1328 9.10631 9.35631 8.90948 4.48359 8.94146C1.50588 8.96101 1.01404 12.2638 2.92442 13.0385C4.23607 13.5704 7.28695 13.3606 9.79169 13.1842C8.32155 13.4355 7.3556 15.4738 9.46805 16.6295C8.04933 16.9203 8.77631 20.1539 10.3875 20.2446C9.40648 20.791 11.0811 24.5828 13.6961 23.3895C13.9871 23.2567 14.2292 23.1163 14.4275 22.9714C16.041 23.5554 18.4119 23.1715 19.109 22.9565C24.4377 21.3132 25.0558 18.3051 25.2964 15.6566C25.769 10.4545 22.0454 6.66947 16.7066 6.34606C14.8302 6.63336 14.4316 7.06998 14.1484 7.55973Z"
      fill="#FD975D"
    />
    <path
      d="M13.9344 9.21593C13.8111 11.3698 13.8887 14.9095 14.6815 15.6493C15.795 16.6882 19.301 14.1455 17.7888 10.8802M13.9344 9.21593C13.9842 8.34504 14.0669 7.70074 14.1484 7.55973C14.4316 7.06998 14.8302 6.63336 16.7066 6.34606C22.0454 6.66947 25.769 10.4545 25.2964 15.6566C25.0558 18.3051 24.4377 21.3132 19.109 22.9565C18.4119 23.1715 16.041 23.5554 14.4275 22.9714M13.9344 9.21593C13.9491 9.21683 13.9638 9.21772 13.9784 9.2186M13.9344 9.21593C12.1328 9.10631 9.35631 8.90948 4.48359 8.94146C1.50588 8.96101 1.01404 12.2638 2.92442 13.0385C4.23607 13.5704 7.28695 13.3606 9.79169 13.1842M9.79169 13.1842C11.0988 13.0921 12.2572 13.0092 12.942 13.0455C14.0161 13.1025 14.7871 15.9838 12.109 16.6564M9.79169 13.1842C8.32155 13.4355 7.3556 15.4738 9.46805 16.6295M9.46805 16.6295C9.96714 16.6597 11.4805 16.7631 12.109 16.6564M9.46805 16.6295C8.04933 16.9203 8.77631 20.1539 10.3875 20.2446M12.109 16.6564C14.3926 16.269 15.5165 19.5232 12.9849 20.0866M10.3875 20.2446C10.5256 20.2524 10.6702 20.2371 10.8204 20.1948M10.3875 20.2446C10.4976 20.1833 10.6411 20.1628 10.8204 20.1948M10.3875 20.2446C9.40648 20.791 11.0811 24.5828 13.6961 23.3895C13.9871 23.2567 14.2292 23.1163 14.4275 22.9714M10.8204 20.1948C11.2152 20.2158 12.4641 20.2475 12.9849 20.0866M12.9849 20.0866C14.4551 19.9939 16.2105 21.6681 14.4275 22.9714M14.4275 22.9714C14.411 22.9655 14.3947 22.9594 14.3784 22.9533M18.395 13.4892C18.7805 14.1052 19.3435 14.669 20.1364 14.8365"
      stroke="#E16521"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M24.4581 14.3428C24.457 15.9383 26.6001 15.408 26.1965 13.9347C26.979 15.0957 26.4244 17.9941 25.6406 18.6898C25.9305 16.988 24.0764 17.2146 23.7565 18.4008C23.5541 17.4143 24.4593 12.5579 24.4581 14.3428Z"
      fill="#FD975D"
    />
  </svg>
);
