import { styled } from "@linaria/react";
import {
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

const UptoTablet = styled.div`
  ${fromTablet} {
    display: none;
  }
`;
export const FromTablet = styled.div`
  ${uptoTablet} {
    display: none;
  }
`;
export const FromTabletG = styled.g`
  ${uptoTablet} {
    display: none;
  }
`;
export const UptoTabletSpan = styled.span`
  ${fromTablet} {
    display: none;
  }
`;
export const FromTabletSpan = styled.span`
  ${uptoTablet} {
    display: none;
  }
`;

export default UptoTablet;
