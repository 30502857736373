import { styled } from "@linaria/react";
import { ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import {
  fromTablet,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { BrandColorNameV4, colorsV4 } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { fluidFontSize, fluidLineHeight } from "../../styles/helpers.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ValidPullQuote } from "../../utils/datocms.utils";

type Props = React.PropsWithChildren<{
  record?: ValidPullQuote;
  backgroundColor?: string | BrandColorNameV4;
  textColor?: string | BrandColorNameV4;
  content?: ReactNode;
  source?: ReactNode;
}>;

export const PullQuoteBlockquote = styled.blockquote<Props>`
  position: relative;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  background-color: ${p => p.backgroundColor ?? colorsV4.canvas550};
  color: ${p => p.textColor ?? "inherit"};
  border-radius: ${rSize("radius")};
  a {
    color: inherit;
  }
`;

export const PullQuoteBlockquoteInner = styled.div`
  padding: 1.5em;
  border-radius: ${rSize("radius")};
  ${fromTablet} {
    padding: 2.5em;
  }
  + * {
    margin-top: ${rSize("lg")};
  }
`;

export const PullQuoteBlockContent = styled.div`
  position: relative;
  font-family: ${font("serif")};
  ${fluidFontSize(18, 24)};
  ${fluidLineHeight(26, 32)};
`;

export const PullQuoteBlockSource = styled.div`
  margin-top: 1.5em;
  p {
    line-height: 1.4;
    &:nth-child(1) {
      font-weight: 600;
    }
    &:nth-child(2) {
      font-weight: 500;
      opacity: 0.8;
    }
    &:nth-child(3) {
      font-weight: 500;
      opacity: 0.5;
      margin-top: 1em;
      font-size: 90%;
      ${fromTabletMd} {
        margin-right: 3em;
      }
    }
    &:only-child {
      font-weight: 500;
    }
    a {
      color: inherit !important;
    }
    + p {
      margin-top: 0 !important;
    }
    strong {
      font-weight: 600;
    }
  }
`;

const PullQuote = (props: Props) => {
  return (
    <PullQuoteBlockquote
      className="PullQuote"
      {...props}
      data-color={props.record?.color || "Canvas"}
    >
      <PullQuoteBlockquoteInner>
        {props.record ? (
          <>
            {props.record.content && (
              <PullQuoteBlockContent>
                <PullQuoteDoubleQuoteMark />
                <ReactMarkdown>{props.record.content}</ReactMarkdown>
              </PullQuoteBlockContent>
            )}
            {props.record.source && (
              <PullQuoteBlockSource>
                <ReactMarkdown>{props.record.source}</ReactMarkdown>
              </PullQuoteBlockSource>
            )}
          </>
        ) : (
          <>
            <PullQuoteBlockContent>
              <PullQuoteDoubleQuoteMark />
              {props.content} {props.children}
            </PullQuoteBlockContent>
            <PullQuoteBlockSource>{props.source}</PullQuoteBlockSource>
          </>
        )}
      </PullQuoteBlockquoteInner>
    </PullQuoteBlockquote>
  );
};

export const PullQuoteDoubleQuoteMarkWrapper = styled.div`
  display: block;
  margin-bottom: 1em;
  user-select: none;
  svg {
    display: block;
  }
`;

const PullQuoteDoubleQuoteMark = () => (
  <PullQuoteDoubleQuoteMarkWrapper className="PullQuoteDoubleQuoteMark">
    <svg width="30" height="25" viewBox="0 0 30 25" fill="currentColor">
      <path d="M0.213158 9.82686L5.41342 0.793221C5.55226 0.551915 5.75151 0.351634 5.9912 0.212425C6.23089 0.0732155 6.50263 -3.69198e-05 6.77921 1.39596e-08H10.3689C11.5705 1.39596e-08 12.3324 1.30053 11.7513 2.36214L8.01947 9.18575C7.88897 9.42474 7.82248 9.6939 7.82654 9.96672C7.8306 10.2395 7.90509 10.5066 8.04264 10.7415C8.1802 10.9764 8.37608 11.1711 8.61097 11.3064C8.84586 11.4416 9.11164 11.5128 9.38211 11.5129H11.4647C11.8835 11.5129 12.2851 11.6807 12.5812 11.9794C12.8773 12.2781 13.0437 12.6832 13.0437 13.1057V23.4072C13.0437 23.8296 12.8773 24.2348 12.5812 24.5335C12.2851 24.8322 11.8835 25 11.4647 25H1.57895C1.16018 25 0.758573 24.8322 0.462463 24.5335C0.166353 24.2348 4.10862e-08 23.8296 4.10862e-08 23.4072V10.6265C-6.34563e-05 10.3456 0.0734739 10.0698 0.213158 9.82686ZM17.1703 9.82686L22.3697 0.793221C22.5086 0.551915 22.7078 0.351634 22.9475 0.212425C23.1872 0.0732155 23.4589 -3.69198e-05 23.7355 1.39596e-08H27.3253C28.5276 1.39596e-08 29.2887 1.30053 28.7084 2.36214L24.9766 9.18575C24.8464 9.42471 24.7801 9.69375 24.7842 9.96641C24.7883 10.2391 24.8628 10.5059 25.0002 10.7407C25.1376 10.9756 25.3333 11.1702 25.5679 11.3056C25.8026 11.441 26.0681 11.5124 26.3384 11.5129H28.4211C28.8398 11.5129 29.2414 11.6807 29.5375 11.9794C29.8336 12.2781 30 12.6832 30 13.1057V23.4072C30 23.8296 29.8336 24.2348 29.5375 24.5335C29.2414 24.8322 28.8398 25 28.4211 25H18.5353C18.1165 25 17.7149 24.8322 17.4188 24.5335C17.1227 24.2348 16.9563 23.8296 16.9563 23.4072V10.6265C16.9563 10.3456 17.0298 10.0698 17.1695 9.82686H17.1703Z" />
    </svg>
  </PullQuoteDoubleQuoteMarkWrapper>
);

export default PullQuote;
