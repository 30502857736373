import { styled } from "@linaria/react";
import { withOpacity } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import PseudoLink, { PseudoLinkSpan } from "../basic/PseudoLink";
import Spacing from "../layout/Spacing";
import { useOpenStoryRequestModalFunction } from "../modals/StoryRequestModal";
import { LibraryState } from "./WithLibraryState";
import Button from "../forms/Button";
import SpotIllustration from "../illustrations/SpotIllustration";
import {
  fromDesktop,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";

const StoryLibraryEmptyStateContainer = styled.div`
  position: relative;
  text-align: center;
  background-color: var(--ac10, ${colors.light200});
  color: var(--ac700, ${colors.dark500});
  ${darkModeLinariaCSS(
    `background-color: var(--ac10, ${withOpacity(colors.dark500, 0.5)});
    color: var(--ac300, ${colors.purple700});
    `
  )}
  padding: ${rSize("xl")};
  border-radius: ${rSize("radius")};
  font-size: 1.6rem;
  h2 {
    font-family: ${serif};
    font-weight: 400;
    line-height: 1.15;
    font-size: 2.2rem;
    ${fromTabletMd} {
      font-size: 2.8rem;
    }
    ${fromDesktop} {
      font-size: 3.4rem;
    }
    > * {
      display: block;
    }
  }
  p {
    max-width: 45em;
    margin-left: auto;
    margin-right: auto;
    + p {
      margin-top: 0.5em;
    }
  }
  a,
  ${PseudoLinkSpan} {
    color: inherit;
    font-weight: 600;
  }
  hr {
    margin: 2em 0;
    border: 0;
    background-color: ${withOpacity(colors.dark500, 0.1)};
    height: 2px;
  }
  .ButtonInner {
    background-color: var(--ac700, ${colors.purple700});
    ${darkModeLinariaCSS(`background-color: var(--ac, ${colors.purple});`)}
    &:hover {
      background-color: var(--ac700, ${colors.purple700});
      filter: brightness(1.1);
      ${darkModeLinariaCSS(`background-color: var(--ac, ${colors.purple});`)}
    }
  }
`;

const LibraryEmptyState = (props: { state: LibraryState }) => {
  const openModal = useOpenStoryRequestModalFunction();
  return (
    <StoryLibraryEmptyStateContainer>
      <SpotIllustration name="ufo" />
      <Spacing size="1em" />
      <h2>
        <span>Are we missing a story? </span>
        <span>Let us know and we’ll add it!</span>
      </h2>
      <Spacing size="1em" />
      <p>No stories found matching your search query.</p>
      <Spacing size="2em" />
      <Button onClick={openModal}>Submit your idea</Button>
      <Spacing size="2em" />
      <p>
        <PseudoLink onClick={props.state.clearSearch}>
          Clear search query
        </PseudoLink>
      </p>
    </StoryLibraryEmptyStateContainer>
  );
};

export default LibraryEmptyState;
