import { styled } from "@linaria/react";
import { EventDirectoryItem } from "../../types/helper.types";
import { brandColorThemeVar } from "../../styles/colors.styles";
import { MapPinTwoToneIcon } from "../icons/twoTone/MapPinTwoToneIcon";
import { CommunityTwoToneIcon } from "../icons/twoTone/CommunityTwoToneIcon";

type Props = {
  item: EventDirectoryItem;
};

const EventDirectoryItemLocationWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: -0.2em;
  svg {
    display: block;
    color: ${brandColorThemeVar(600)};
  }
  > * + * {
    margin-left: 0.25em;
  }
`;

const EventDirectoryItemLocation = (props: Props) => {
  const { type, categories } = props.item;
  const isVirtual =
    type === "webinar" || categories.find(c => c.slug === "virtual");
  const { address } = props.item.place ?? {};
  const text = isVirtual
    ? "Virtual"
    : address
    ? [
        address.city.trim(),
        address.state.trim(),
        "usa united states of america".includes(
          address.country.trim().toLowerCase()
        )
          ? ""
          : address.country.trim(),
        props.item.distanceFromUser
          ? `${
              Math.round((props.item.distanceFromUser / 1000) * 10) / 10
            } km away`
          : "",
      ]
        .filter(i => i)
        .join(", ")
    : "";
  return text ? (
    <EventDirectoryItemLocationWrapper>
      {isVirtual ? <CommunityTwoToneIcon /> : <MapPinTwoToneIcon darker />}
      <span>{text}</span>
    </EventDirectoryItemLocationWrapper>
  ) : null;
};

export default EventDirectoryItemLocation;
