import { styled } from "@linaria/react";
import { UseStateReturnType } from "../../types/helper.types";
import { colors, withOpacity } from "../../styles/colors.styles";
import {
  fromTabletLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { SearchPlainIcon } from "../icons/plain/SearchPlainIcon";
import { DatoCmsProductCategory } from "../../../graphql-types";

type Props = {
  formState: UseStateReturnType<{
    query: string;
    category: string;
  }>;
  categories: DatoCmsProductCategory[];
  onEnter?: () => void;
  onCategoryChange: (slug: string) => void;
};

const Container = styled.div`
  position: relative;
  display: grid;
  font-weight: 500;
  ${uptoTabletLg} {
    grid-template-rows: auto auto;
  }
  ${fromTabletLg} {
    height: 4em;
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: stretch;
  }
`;

const SearchInputWrap = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
  z-index: 2;
  pointer-events: none;
`;

const Input = styled.input`
  position: relative;
  border-radius: 0.6em 0.6em 0 0;
  width: 100%;
  height: 3em;
  ${fromTabletLg} {
    border-radius: 0.6em 0 0 0.6em;
    height: 4em;
  }
  padding: 0.5em 1em 0.5em 3em;
  border: 1px solid ${colors.purple200};
  background-color: ${colors.lightest};
  color: inherit;
  font: inherit;
  &:focus {
    z-index: 1;
  }
  &::placeholder {
    color: ${withOpacity(colors.purple800, 0.5)};
  }
`;

const DropdownWrap = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
`;

const Dropdown = styled.select`
  display: block;
  appearance: none;
  position: relative;
  border-radius: 0 0 0.6em 0.6em;
  height: 3em;
  width: 100%;
  ${uptoTabletLg} {
    margin-top: -1px;
  }
  ${fromTabletLg} {
    border-radius: 0 0.6em 0.6em 0;
    height: 4em;
    margin-left: -1px;
  }
  padding: 0.5em 2em 0.5em 1em;
  border: 1px solid ${colors.purple200};
  background-color: ${colors.lightest};
  color: inherit;
  font: inherit;
  min-width: 10em;
  &:focus {
    z-index: 1;
  }
`;

const DropdownChevron = styled.svg`
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 0.75em;
  transform: translateY(-50%);
  z-index: 2;
`;

const ProductExplorerSearchBar = (props: Props) => {
  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      props.onEnter?.();
    }
  };
  return (
    <Container>
      <SearchInputWrap>
        <IconContainer>
          <SearchPlainIcon />
        </IconContainer>
        <Input
          id="product-explorer-search-bar"
          value={props.formState[0].query}
          type="search"
          autoFocus
          autoComplete="off"
          placeholder="Find a product..."
          onKeyUp={handleKeyUp}
          onChange={e =>
            props.formState[1]({
              ...props.formState[0],
              query: e.target.value,
            })
          }
        />
      </SearchInputWrap>
      <DropdownWrap>
        <Dropdown
          value={props.formState[0].category}
          onChange={e => {
            props.onCategoryChange(e.target.value);
          }}
        >
          <option value="">All categories</option>
          {props.categories.map(c => (
            <option key={c.id} value={`${c.slug}`}>
              {c.name}
            </option>
          ))}
        </Dropdown>
        <DropdownChevron
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path d="M1.04785 1L5.04785 5L9.04785 1" />
        </DropdownChevron>
      </DropdownWrap>
    </Container>
  );
};

export default ProductExplorerSearchBar;
