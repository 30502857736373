import { styled } from "@linaria/react";

const Wrap = styled.div`
  svg {
    &:first-child {
      position: absolute;
      right: 100%;
      top: 75%;
      transform: translate(45%, -33%);
    }
    &:last-child {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

const PageHeroHeadlinePlatforms = () => {
  return (
    <Wrap>
      <PlatformLeft />
      <PlatformRight />
    </Wrap>
  );
};

const PlatformLeft = () => (
  <svg
    width="190"
    height="137"
    viewBox="0 0 190 137"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8079 131.58C19.4182 131.58 17.481 129.643 17.481 127.253C17.481 124.863 19.4182 122.926 21.8079 122.926C24.1976 122.926 26.1348 124.863 26.1348 127.253C26.1348 129.643 24.1976 131.58 21.8079 131.58Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M52.821 117.871C61.5344 117.871 68.5981 110.807 68.5981 102.094C68.5981 93.3804 61.5344 86.3168 52.821 86.3168C44.1076 86.3168 37.0439 93.3804 37.0439 102.094C37.0439 110.807 44.1076 117.871 52.821 117.871Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M185.296 15.4819H63.6226L1 100.475L3.19289 106.234H124.867L188.966 19.5782L185.296 15.4819Z"
      fill="#A88FEF"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M122.669 100.475H1L63.6226 15.4819H185.296L122.669 100.475Z"
      fill="#C1AAF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M124.867 106.234L122.669 100.475"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M56.4561 77.6687C60.7822 73.086 62.4779 67.6612 60.2437 65.5521C58.0095 63.443 52.6913 65.4482 48.3653 70.0309C44.0392 74.6136 42.3434 80.0384 44.5776 82.1475C46.8118 84.2566 52.13 82.2514 56.4561 77.6687Z"
      fill="#483C72"
    />
    <path
      d="M148.444 34.2308C151.786 30.6904 153.097 26.4995 151.371 24.8703C149.645 23.241 145.536 24.7904 142.194 28.3308C138.852 31.8713 137.542 36.0621 139.268 37.6914C140.993 39.3206 145.102 37.7713 148.444 34.2308Z"
      fill="#483C72"
    />
    <path
      d="M63.0689 62.5679L60.3952 66.3208L60.3511 70.9323L55.98 72.3942L53.2377 76.103L48.8421 74.7146L44.4416 76.103L41.6992 72.3942L37.3281 70.9323L37.2889 66.3208L34.6152 62.5679L37.2889 58.8149L37.3281 54.2035L41.7041 52.7415L44.4416 49.0328L48.8421 50.4211L53.2377 49.0328L55.98 52.7415L60.3511 54.2035L60.3952 58.8149L63.0689 62.5679Z"
      fill="#88D0A3"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M140.998 5.40052L146.227 1.87815L147.297 8.0938L153.601 8.31947L150.809 13.971L155.779 17.8613L150.196 20.7998L151.933 26.8634L145.687 25.9607L143.529 31.882L139.011 27.4815L133.776 31.0039L132.707 24.7882L126.408 24.5626L129.194 18.9062L124.229 15.0208L129.807 12.0822L128.076 6.01865L134.316 6.92131L136.479 1.00002L140.998 5.40052Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M102.354 100.352H108.83L124.455 78.9041H118.328L102.354 100.352Z"
      fill="#483C72"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M146.1 53.4284H141.459L120.094 54.0906L106.476 32.2206H101.835L102.639 58.1379L80.627 71.8447H85.2678L106.633 71.1874L115.282 85.0708H119.922L124.455 78.9042L124.087 67.1352L146.1 53.4284Z"
      fill="#FA9589"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M111.185 71.0451L119.922 85.0708L124.455 78.9042L124.087 67.1352L146.1 53.4284L120.182 54.2329L106.475 32.2206L107.275 58.1379L85.2676 71.8447L111.185 71.0451Z"
      fill="#FFB6AB"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M178.287 103.821C176.393 103.821 174.632 104.37 173.146 105.317C171.659 104.37 169.898 103.821 168.004 103.821C166.111 103.821 164.349 104.37 162.863 105.317C161.377 104.37 159.615 103.821 157.722 103.821C152.428 103.821 148.136 108.113 148.136 113.407C148.136 118.7 152.428 122.993 157.722 122.993C159.615 122.993 161.377 122.443 162.863 121.496C164.349 122.443 166.116 122.993 168.004 122.993C169.893 122.993 171.659 122.443 173.146 121.496C174.632 122.443 176.398 122.993 178.287 122.993C183.58 122.993 187.873 118.7 187.873 113.407C187.873 108.113 183.58 103.821 178.287 103.821Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M105.406 123.498C103.183 123.498 101.241 124.675 100.152 126.436C99.0626 124.675 97.1199 123.498 94.8976 123.498C92.6752 123.498 90.7325 124.675 89.6435 126.436C88.5544 124.675 86.6117 123.498 84.3844 123.498C80.97 123.498 78.2031 126.265 78.2031 129.684C78.2031 133.103 80.97 135.87 84.3844 135.87C86.6068 135.87 88.5495 134.693 89.6435 132.932C90.7325 134.693 92.6752 135.87 94.8976 135.87C97.1199 135.87 99.0626 134.693 100.152 132.932C101.241 134.693 103.183 135.87 105.406 135.87C108.82 135.87 111.592 133.103 111.592 129.684C111.592 126.27 108.825 123.498 105.406 123.498Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
  </svg>
);

const PlatformRight = () => (
  <svg
    width="189"
    height="166"
    viewBox="0 0 189 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.2201 113.108C68.8439 113.108 72.0105 115.029 73.7907 117.899C75.5662 115.029 78.7376 113.108 82.3614 113.108C85.9851 113.108 89.1517 115.029 90.932 117.899C92.7074 115.029 95.8788 113.108 99.5026 113.108C105.072 113.108 109.586 117.622 109.586 123.191C109.586 128.761 105.072 133.275 99.5026 133.275C95.8788 133.275 92.7074 131.353 90.932 128.483C89.1566 131.353 85.9851 133.275 82.3614 133.275C78.7376 133.275 75.571 131.353 73.7907 128.483C72.0153 131.353 68.8439 133.275 65.2201 133.275C59.6507 133.275 55.1368 128.761 55.1368 123.191C55.1368 117.622 59.6507 113.108 65.2201 113.108Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M4.63807 35.2428H125.278L187.374 119.514L185.195 125.225H64.5545L0.999707 39.3044L4.63807 35.2428Z"
      fill="#EE86B7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M66.7337 119.514H187.374L125.278 35.2428H4.63807L66.7337 119.514Z"
      fill="#FAA3CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M64.5553 125.225L66.7344 119.514"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M19.5707 44.2493C20.1637 42.4961 22.902 41.8386 25.6869 42.7805C28.4718 43.7225 30.2487 45.9073 29.6557 47.6605C29.0627 49.4137 26.3244 50.0712 23.5395 49.1293C20.7546 48.1873 18.9777 46.0024 19.5707 44.2493Z"
      fill="#483C72"
    />
    <path
      d="M126.369 69.181C127.575 65.6154 133.713 64.4701 140.077 66.6228C146.441 68.7756 150.623 73.4112 149.417 76.9767C148.211 80.5423 142.074 81.6877 135.71 79.535C129.345 77.3822 125.163 72.7466 126.369 69.181Z"
      fill="#483C72"
    />
    <path
      d="M142.59 48.3128L137.405 44.8203L136.345 50.9832L130.094 51.2069L132.862 56.8104L127.935 60.6677L133.47 63.5813L131.748 69.5934L137.94 68.6984L140.08 74.5694L144.56 70.2063L149.75 73.6987L150.811 67.5358L157.056 67.3121L154.293 61.7037L159.216 57.8514L153.685 54.9377L155.402 48.9257L149.215 49.8207L147.07 43.9496L142.59 48.3128Z"
      fill="#FFC8A3"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_209_12645"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="33"
      y="0"
      width="85"
      height="83"
    >
      <rect
        x="49.6426"
        y="-3.05176e-05"
        width="69.7193"
        height="67.5575"
        transform="rotate(13.7099 49.6426 -3.05176e-05)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_209_12645)">
      <path
        d="M91.4429 30.1214C90.5965 33.0107 60.5605 77.5711 60.5605 77.5711C60.5605 77.5711 61.0809 24.3526 61.9322 21.4584C62.7785 18.5691 70.0747 18.1605 78.2222 20.5537C86.3696 22.9468 92.2892 27.2273 91.4429 30.1166V30.1214Z"
        fill="#FBBD74"
        stroke="#483C72"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M75.1383 31.0178C66.9905 28.6259 61.0736 24.3424 61.9226 21.4504C62.7716 18.5583 70.065 18.1528 78.2128 20.5446C86.3607 22.9365 92.2776 27.22 91.4286 30.1121C90.5796 33.0042 83.2862 33.4097 75.1383 31.0178Z"
      fill="#FFD29F"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M21.2914 37.4263C20.3332 40.7826 22.2789 44.2847 25.6351 45.243C28.9914 46.2012 32.4936 44.2556 33.4518 40.8993C34.41 37.543 31.1462 25.9421 31.1462 25.9421C31.1462 25.9421 22.2497 34.0652 21.2914 37.4263Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M105.619 145.897C107.496 145.897 109.242 146.442 110.716 147.381C112.19 146.442 113.936 145.897 115.814 145.897C117.691 145.897 119.438 146.442 120.912 147.381C122.385 146.442 124.132 145.897 126.009 145.897C131.258 145.897 135.514 150.153 135.514 155.402C135.514 160.65 131.258 164.906 126.009 164.906C124.132 164.906 122.385 164.361 120.912 163.423C119.438 164.361 117.687 164.906 115.814 164.906C113.941 164.906 112.19 164.361 110.716 163.423C109.242 164.361 107.491 164.906 105.619 164.906C100.37 164.906 96.1142 160.65 96.1142 155.402C96.1142 150.153 100.37 145.897 105.619 145.897Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M14.249 115.404C16.9486 115.404 19.3661 116.61 20.9956 118.512C22.6251 116.615 25.0425 115.404 27.7421 115.404C32.65 115.404 36.6338 119.388 36.6338 124.296C36.6338 129.203 32.6549 133.187 27.7421 133.187C25.0425 133.187 22.6251 131.981 20.9956 130.079C19.3661 131.976 16.9486 133.187 14.249 133.187C9.33624 133.187 5.35739 129.208 5.35739 124.296C5.35739 119.383 9.33624 115.404 14.249 115.404Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M130.562 10.2687C132.765 10.2687 134.691 11.4361 135.771 13.1824C136.851 11.4361 138.777 10.2687 140.981 10.2687C143.184 10.2687 145.11 11.4361 146.19 13.1824C147.27 11.4361 149.196 10.2687 151.404 10.2687C154.79 10.2687 157.533 13.0121 157.533 16.4024C157.533 19.7927 154.79 22.5361 151.404 22.5361C149.201 22.5361 147.275 21.3687 146.19 19.6225C145.11 21.3687 143.184 22.5361 140.981 22.5361C138.777 22.5361 136.851 21.3687 135.771 19.6225C134.691 21.3687 132.765 22.5361 130.562 22.5361C127.176 22.5361 124.428 19.7927 124.428 16.4024C124.428 13.017 127.171 10.2687 130.562 10.2687Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M47.2967 158.213C44.9273 158.213 43.0066 156.292 43.0066 153.923C43.0066 151.553 44.9273 149.633 47.2967 149.633C49.6661 149.633 51.5869 151.553 51.5869 153.923C51.5869 156.292 49.6661 158.213 47.2967 158.213Z"
      fill="#F3ECF7"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M63.6247 72.962L60.6521 72.1514L45.7344 91.3377L50.0581 97.2827L63.6247 72.962Z"
      fill="#483C72"
      stroke="#483C72"
    />
  </svg>
);

export default PageHeroHeadlinePlatforms;
