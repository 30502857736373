import { styled } from "@linaria/react";
import React from "react";
import { useStoryboardContext } from "./StoryboardContext";
import StoryboardActionLinkSet from "./StoryboardActionLinkSet";

const Svg = styled.svg`
  position: relative;
  transition: opacity 0.2s;
  .hasSelection & {
    opacity: 0.6;
  }
`;

function StoryboardSvgLayer(props: { onClick: () => void }) {
  const context = useStoryboardContext();
  const { width, height } = context.storyboard;
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      onClick={props.onClick}
    >
      <StoryboardActionLinkSet />
    </Svg>
  );
}

export default StoryboardSvgLayer;
