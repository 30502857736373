export const SendEmailIcon = {
  sendEmail: () => (
    <svg
      className="Icon SendEmailIcon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.51595 8.31629C1.9864 8.54357 1.90035 9.25833 2.36083 9.6048L5.81425 12.2032L5.81426 17.3271C5.81426 17.8696 6.43238 18.1804 6.86784 17.8569L11.8388 14.164L15.7116 15.3285C16.3533 15.5215 16.9995 15.041 16.9995 14.3709L16.9995 2.09998L2.51595 8.31629ZM7.01425 16.3137V12.7133L10.6439 13.8048L7.01425 16.3137ZM6.22184 11.1669L3.73714 9.1572L15.4038 3.86837L6.22184 11.1669ZM15.7995 4.59185L15.7995 14.1048L7.44505 11.6511L15.7995 4.59185Z"
      />
    </svg>
  ),
};
