export const CasesPageFunnelIcon = () => (
  <svg
    width="69"
    height="89"
    viewBox="0 0 69 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5768 88.22C28.3461 88.22 31.4017 87.07 31.4017 85.6513C31.4017 84.2326 28.3461 83.0825 24.5768 83.0825C20.8076 83.0825 17.752 84.2326 17.752 85.6513C17.752 87.07 20.8076 88.22 24.5768 88.22Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.52 85.5878C22.3873 85.5878 22.7403 84.6563 22.7403 84.187V74.1735C22.7403 73.2935 23.4539 72.5801 24.3338 72.5801H24.7763C25.6565 72.5801 26.3698 73.2937 26.3698 74.1735V84.187C26.3698 84.6562 26.6588 85.5878 27.7188 85.5878"
      fill="#FABE64"
    />
    <path
      d="M21.52 85.5878C22.3873 85.5878 22.7403 84.6563 22.7403 84.187V74.1735C22.7403 73.2935 23.4539 72.5801 24.3338 72.5801H24.7763C25.6565 72.5801 26.3698 73.2937 26.3698 74.1735V84.187C26.3698 84.6562 26.6588 85.5878 27.7188 85.5878"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.923 88.22C48.6923 88.22 51.7479 87.07 51.7479 85.6513C51.7479 84.2326 48.6923 83.0825 44.923 83.0825C41.1538 83.0825 38.0981 84.2326 38.0981 85.6513C38.0981 87.07 41.1538 88.22 44.923 88.22Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M41.8643 85.5878C42.7316 85.5878 43.0846 84.6563 43.0846 84.187V74.1735C43.0846 73.2935 43.7982 72.5801 44.678 72.5801H45.1206C46.0008 72.5801 46.714 73.2937 46.714 74.1735V84.187C46.714 84.6562 47.003 85.5878 48.063 85.5878"
      fill="#49B889"
    />
    <path
      d="M41.8643 85.5878C42.7316 85.5878 43.0846 84.6563 43.0846 84.187V74.1735C43.0846 73.2935 43.7982 72.5801 44.678 72.5801H45.1206C46.0008 72.5801 46.714 73.2937 46.714 74.1735V84.187C46.714 84.6562 47.003 85.5878 48.063 85.5878"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.8106 63.3863C41.8106 63.3863 41.2278 63.3863 40.2603 63.3863C38.4369 63.3863 38.0083 62.7001 37.9118 61.5767V57.0718C37.9118 55.315 36.4879 53.8911 34.731 53.8911C34.7266 53.8911 34.7221 53.8918 34.7174 53.8918C34.7131 53.8918 34.7086 53.8911 34.7042 53.8911C32.9476 53.8911 31.5237 55.315 31.5237 57.0718V61.5767C31.4272 62.7001 30.9986 63.3863 29.1753 63.3863C28.2074 63.3863 27.6246 63.3863 27.6246 63.3863C24.1737 63.3863 21.3662 66.1939 21.3662 69.6448V75.007C21.3662 76.7637 22.7901 77.1607 24.5467 77.1607C26.3032 77.1607 27.7272 76.7637 27.7272 75.007C27.7272 75.007 27.7272 73.6886 27.7272 72.3193C27.7272 70.95 28.04 69.7474 29.8529 69.7474C31.3933 69.7474 31.6533 69.7474 31.6533 69.7474C32.7661 69.7474 33.8112 69.4539 34.7176 68.9427C35.6244 69.4539 36.6694 69.7474 37.7823 69.7474C37.7823 69.7474 38.0421 69.7474 39.5828 69.7474C41.3954 69.7474 41.7082 70.95 41.7082 72.3193C41.7082 73.6886 41.7082 75.007 41.7082 75.007C41.7082 76.7637 43.1321 77.1607 44.8887 77.1607C46.6456 77.1607 48.0695 76.7637 48.0695 75.007V69.6448C48.0693 66.1939 45.2615 63.3863 41.8106 63.3863Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.0513 60.312C39.0513 62.7057 37.1112 63.0693 34.7172 63.0693C32.3235 63.0693 30.3833 62.7055 30.3833 60.312V38.8701C30.3833 36.4765 32.3235 34.5361 34.7172 34.5361C37.1112 34.5361 39.0513 36.4765 39.0513 38.8701V60.312Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M34.6631 58.5129C37.256 58.5129 39.3579 57.4812 39.3579 56.2086C39.3579 54.936 37.256 53.9043 34.6631 53.9043C32.0702 53.9043 29.9683 54.936 29.9683 56.2086C29.9683 57.4812 32.0702 58.5129 34.6631 58.5129Z"
      fill="#32313B"
    />
    <path
      d="M34.7707 17.5903C18.013 17.5903 4.42823 19.2518 4.42823 21.3013C4.42823 21.3013 4.41014 22.323 5.91248 24.7744C6.72765 26.1039 8.99247 28.2056 8.99247 28.2056C8.99247 28.2056 9.7026 30.6574 10.736 32.2072C12.0012 34.1049 13.5864 35.155 13.5864 35.155C13.5864 35.155 14.5037 37.6292 15.4805 39.1656C16.4574 40.702 18.3286 42.3281 18.3286 42.3281C18.3286 42.3281 19.1688 44.4831 19.8297 45.4912C20.4906 46.4994 22.0653 47.981 22.0653 47.981C22.0653 47.981 22.9105 49.9487 23.6252 51.0264C24.3399 52.1041 27.0161 54.1533 27.0161 54.1533C28.9251 55.667 30.0072 57.1749 34.7709 57.1749C39.5346 57.1749 40.4654 55.6977 42.5257 54.1533C42.5257 54.1533 45.2021 52.1039 45.9166 51.0264C46.6311 49.9489 47.4761 47.981 47.4761 47.981C47.4761 47.981 49.0513 46.4994 49.7121 45.4912C50.3729 44.4831 51.2132 42.3281 51.2132 42.3281C51.2132 42.3281 53.0845 40.702 54.0612 39.1656C55.0379 37.6292 55.9553 35.155 55.9553 35.155C55.9553 35.155 57.5405 34.1049 58.8058 32.2072C59.8388 30.6574 60.5493 28.2056 60.5493 28.2056C60.5493 28.2056 62.8141 26.1039 63.629 24.7744C65.1315 22.323 65.1136 21.3013 65.1136 21.3013C65.1132 19.2516 51.5285 17.5903 34.7707 17.5903Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M47.2649 48.1353C43.753 48.7849 39.4364 49.1678 34.7696 49.1678C30.1693 49.1678 25.9094 48.7958 22.4253 48.1633"
      stroke="#6359B3"
      strokeMiterlimit="10"
    />
    <path
      d="M43.6576 53.0443C41.1356 53.6146 38.0726 53.949 34.7701 53.949C31.439 53.949 28.3519 53.6088 25.8174 53.0293"
      stroke="#6359B3"
      strokeMiterlimit="10"
    />
    <path
      d="M51.436 42.146C47.2776 43.0425 41.3496 43.6035 34.77 43.6035C28.2112 43.6035 22.2997 43.0464 18.1436 42.1545"
      stroke="#6359B3"
      strokeMiterlimit="10"
    />
    <path
      d="M68.0001 16.3963C68.0001 13.6106 53.0393 11.3525 34.5843 11.3525C16.1294 11.3525 1.16895 13.6106 1.16895 16.3963C1.16895 16.9194 1.16895 19.867 1.16895 20.3902C1.16895 23.1758 16.1294 25.4342 34.5843 25.4342C53.0393 25.4342 68.0001 23.1758 68.0001 20.3902C68.0001 19.867 68.0001 16.9194 68.0001 16.3963Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M34.5845 21.4398C53.0393 21.4398 68 19.1817 68 16.3962C68 13.6106 53.0393 11.3525 34.5845 11.3525C16.1296 11.3525 1.16895 13.6106 1.16895 16.3962C1.16895 19.1817 16.1296 21.4398 34.5845 21.4398Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M34.5845 21.4398C53.0393 21.4398 68 19.1817 68 16.3962C68 13.6106 53.0393 11.3525 34.5845 11.3525C16.1296 11.3525 1.16895 13.6106 1.16895 16.3962C1.16895 19.1817 16.1296 21.4398 34.5845 21.4398Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M34.5844 18.4306C49.6783 18.4306 61.9144 17.4277 61.9144 16.1906C61.9144 14.9535 49.6783 13.9507 34.5844 13.9507C19.4904 13.9507 7.25439 14.9535 7.25439 16.1906C7.25439 17.4277 19.4904 18.4306 34.5844 18.4306Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M16.3359 17.7915C16.3359 17.7915 24.2832 14.5187 29.5818 14.0513C29.5818 14.0513 8.70021 14.3634 7.29785 16.0772C7.29785 16.0772 8.70003 17.4797 16.3359 17.7915Z"
      fill="#32313B"
    />
    <path
      d="M21.0623 13.1792C21.3331 13.8984 20.9693 14.7005 20.2503 14.9712L16.6701 16.3183C15.9511 16.5888 15.1488 16.2252 14.8782 15.5062L13.531 11.9258C13.2602 11.2068 13.624 10.4047 14.343 10.134L17.9234 8.78676C18.6423 8.5162 19.4447 8.87978 19.7153 9.59878L21.0623 13.1792Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.1509 25.6233C35.1509 25.6233 59.7474 24.7352 64.8032 22.6172C64.8032 22.6172 64.0517 24.3936 63.505 25.0084C63.505 25.0084 50.2503 26.3064 35.1509 25.6233Z"
      fill="#32313B"
    />
    <path
      d="M17.7734 12.0241L18.2714 6.88606L22.9704 4.74854L27.1709 7.74887L26.6728 12.8869L21.9739 15.0244L17.7734 12.0241Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.2054 9.00308L38.8086 6.88329L38.946 2.88192L42.4798 1L45.8766 3.11962L45.739 7.12116L42.2054 9.00308Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.5829 14.4071C50.202 14.87 49.5176 14.9359 49.0549 14.5548L46.7504 12.6565C46.2875 12.2755 46.2216 11.5909 46.6026 11.1282L48.501 8.82379C48.8822 8.36074 49.5666 8.29484 50.0295 8.67581L52.3339 10.5743C52.7965 10.9556 52.8627 11.64 52.4817 12.1027L50.5829 14.4071Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.7662 13.9102C40.422 14.5967 39.5859 14.8744 38.8991 14.5296L34.681 12.4129C33.9944 12.0683 33.717 11.2322 34.0617 10.5458L36.1785 6.32739C36.523 5.64073 37.359 5.36356 38.0457 5.70819L42.2637 7.82486C42.9504 8.16932 43.2277 9.00551 42.8831 9.69199L40.7662 13.9102Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M55.6072 17.6385L55.7533 16.9118L51.0165 12.7402L45.0355 14.7562L44.3252 18.2891C48.7399 18.1539 52.6131 17.9278 55.6072 17.6385Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.7841 18.4313C35.0614 18.4313 35.3374 18.4307 35.6123 18.43C35.8315 17.128 35.0541 15.8356 33.7564 15.4469L25.1183 12.8578C23.7114 12.4362 22.2303 13.2343 21.8086 14.6403L20.7676 18.1144C24.866 18.3156 29.6599 18.4313 34.7841 18.4313Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.8436 17.8506L17.5993 16.5505L13.9844 15.2803L11.5737 17.3495C13.3216 17.5428 15.4404 17.7122 17.8436 17.8506Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.3583 16.6115L37.2432 14.6802L38.3583 12.749H40.5881L41.7031 14.6802L40.5881 16.6115H38.3583Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.7261 4.52817L29.3668 2.3922L31.5367 1.87891L33.0664 3.50157L32.4259 5.63736L30.2557 6.15083L28.7261 4.52817Z"
      fill="#49B889"
      stroke="#32313B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M60.4929 28.0747C54.9218 29.155 45.4799 29.8655 34.7702 29.8655C24.0665 29.8655 14.6297 29.1561 9.05713 28.0768"
      stroke="#6359B3"
      strokeMiterlimit="10"
    />
    <path
      d="M56.0582 35.1211C51.2319 36.0465 43.4934 36.6464 34.7701 36.6464C26.1358 36.6464 18.4664 36.0587 13.6309 35.1494"
      stroke="#6359B3"
      strokeMiterlimit="10"
    />
    <path
      d="M4.87158 22.8112C5.09137 23.3217 5.42036 23.971 5.9121 24.7735C6.72726 26.103 8.99208 28.2047 8.99208 28.2047C8.99208 28.2047 9.70221 30.6564 10.7356 32.2062C12.0008 34.104 13.5861 35.154 13.5861 35.154C13.5861 35.154 14.5033 37.6282 15.4802 39.1646C16.457 40.701 18.3282 42.3272 18.3282 42.3272C18.3282 42.3272 19.1684 44.4821 19.8293 45.4903C20.4902 46.4984 22.0649 47.9801 22.0649 47.9801C22.0649 47.9801 22.9102 49.9477 23.6248 51.0254C24.3395 52.1032 27.0157 54.1523 27.0157 54.1523C28.9248 55.666 30.0068 57.1739 34.7705 57.1739C39.5342 57.1739 40.465 55.6968 42.5253 54.1523C42.5253 54.1523 45.2017 52.103 45.9162 51.0254C46.6307 49.9479 47.4758 47.9801 47.4758 47.9801C47.4758 47.9801 49.051 46.4984 49.7117 45.4903C50.3725 44.4821 51.2128 42.3272 51.2128 42.3272C51.2128 42.3272 53.0842 40.701 54.0609 39.1646C55.0376 37.6282 55.955 35.154 55.955 35.154C55.955 35.154 57.5401 34.104 58.8054 32.2062C59.8385 30.6564 60.5489 28.2047 60.5489 28.2047C60.5489 28.2047 62.8137 26.103 63.6286 24.7735C64.1488 23.9247 64.4869 23.2475 64.7061 22.7246"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M22.8105 78.1094C23.2866 78.2245 23.8714 78.2933 24.5059 78.2933C25.1793 78.2933 25.7973 78.2163 26.2882 78.0878V76.9312H22.8105V78.1094Z"
      fill="#32313B"
    />
    <path
      d="M43.1543 78.1094C43.6304 78.2245 44.2152 78.2933 44.8497 78.2933C45.5231 78.2933 46.1411 78.2163 46.6319 78.0878V76.9312H43.1543V78.1094Z"
      fill="#32313B"
    />
  </svg>
);
