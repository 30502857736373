import { styled } from "@linaria/react";
import { cx } from "linaria";
import TinesLogo from "../misc/TinesLogo";

const PoweredByTinesDiv = styled.div`
  display: flex;
  align-items: center;
  &.centered {
    justify-content: center;
  }
  font-size: 1.4rem;
  font-weight: 500;
  opacity: 0.6;
  white-space: nowrap;
  > * + * {
    margin-left: 0.5em;
  }
`;

const PoweredByTines = (props: { centered?: boolean }) => {
  return (
    <PoweredByTinesDiv className={cx(props.centered && "centered")}>
      <span>Powered by</span>
      <TinesLogo height={18} />
    </PoweredByTinesDiv>
  );
};

export default PoweredByTines;
