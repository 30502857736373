import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import CustomerCenterPageSection from "./CustomerCenterPageSection";
import { css } from "linaria";
import { Paths } from "../../utils/pathBuilders.utils";
import { openIntercomMessenger } from "../../utils/intercom.utils";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import statusIcon from "./illos/CustomerCenterTinesStatusIcon.svg";
import paperPlaneIcon from "./illos/CustomerCenterPaperPlaneIcon.svg";
import { colors, withOpacity } from "../../styles/colors.styles";
import { fromTabletLg } from "../../styles/breakpointsAndMediaQueries.styles";

const TwoSplit = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const entryStyle = css`
  appearance: none;
  color: inherit;
  text-decoration: none;
  display: grid;
  background-color: ${colors.purple50};
  border-radius: 1.6rem;
  border: 1px solid ${colors.purple200};
  grid-template-columns: auto minmax(0, 1fr);
  padding: 1rem;
  text-align: left;
  align-items: center;
  grid-gap: 0.75em;
  cursor: pointer;
  &:hover {
    border-color: ${colors.purple300};
  }
  strong {
    display: block;
    margin-bottom: 0.1em;
    font-weight: 600;
    font-size: 1.8rem;
    + span {
      font-weight: 500;
      font-size: 1.4rem;
      &:after {
        display: inline-block;
        vertical-align: baseline;
        content: "→";
        transition: 0.2s;
        margin-left: 0.25em;
      }
    }
  }
  &[target="_blank"] {
    strong + span {
      &:after {
        vertical-align: 35%;
        content: "↗";
        font-size: 80%;
        margin-left: 0.15em;
      }
    }
  }
  @media (hover: hover) {
    &:hover {
      strong + span {
        &:after {
          transform: translateX(3px);
        }
      }
    }
    &[target="_blank"] {
      &:hover {
        strong + span {
          &:after {
            transform: translate(1px, -1px);
          }
        }
      }
    }
  }
  figure {
    margin: 0;
    width: 9.6rem;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${withOpacity(colors.purple100, 0.5)};
    border: 1px solid ${colors.purple200};
    border-radius: 0.8rem;
    aspect-ratio: 1/1;
  }
`;

export const CustomerCenterUsefulLinksSection = () => {
  const handleContactButtonClick = () => {
    openIntercomMessenger();
  };
  return (
    <CustomerCenterPageSection title="Useful links" id="useful-links">
      <TwoSplit>
        <a className={entryStyle} href={Paths.status()} {...externalLinkAttr}>
          <figure>
            <img src={statusIcon} />
          </figure>
          <div>
            <strong>Tines status</strong>
            <span>Visit status.tines.com</span>
          </div>
        </a>
        <button className={entryStyle} onClick={handleContactButtonClick}>
          <figure>
            <img src={paperPlaneIcon} />
          </figure>
          <div>
            <strong>Contact support</strong>
            <span>Submit a query</span>
          </div>
        </button>
      </TwoSplit>
    </CustomerCenterPageSection>
  );
};
