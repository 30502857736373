import { graphql } from "gatsby";
import { DatoCmsLesson, UniLessonPageByIdQuery } from "../../graphql-types";
import StandardArticleStructuredTextWithCustomBlocks from "../components/articles/StructuredTextWithCustomBlocks";
import PageBreadCrumbs from "../components/basic/PageBreadCrumbs";
import VotingBanner from "../components/hub/VotingBanner";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import {
  PageTree,
  TreePage,
  getBreadcrumbsFromTree,
} from "../utils/tree.utils";
import {
  HubPageHeader,
  WiderArticlePageBody,
} from "../components/hub/HubPageComponents";
import Page from "./Page.template";
import SpaceChildren from "../components/layout/SpaceChildren";
import HubPrevNextNav from "../components/hub/HubPrevNextNav";
import ogImage from "../../static/images/og/tines-docs.png";
import { useOnMount } from "../utils/lifeCycle.utils";
import {
  getCachedUniTreeWithProgress,
  getKnownLearningPathConfig,
  markCourseAsComplete,
  markLessonAsComplete,
} from "../utils/university.utils";
import { CSSProperties } from "react";
import { colorV4, withOpacity } from "../styles/colorsV4.styles";
import { last } from "lodash-es";
import UniLearningPathCompletionCard from "../components/hub/university/UniLearningPathCompletionCard";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { Paths } from "../utils/pathBuilders.utils";
import { UniversityTwoToneIcon } from "../components/icons/twoTone/UniversityTwoToneIcon";

const UniLessonPageTemplate: PageComponent<{
  pageContext: {
    tree: PageTree;
    learningPath: Pick<TreePage, "slug" | "title" | "id">;
  };
  data: UniLessonPageByIdQuery;
}> = props => {
  const page = props.data.page as DatoCmsLesson;
  const { location } = props;
  const levels = getBreadcrumbsFromTree(
    location.pathname,
    undefined,
    props.pageContext.tree
  );
  const knownConfig = getKnownLearningPathConfig(
    props.pageContext.learningPath.id
  );
  const colorName = knownConfig?.color ?? "purple";
  const learningPath = props.pageContext.tree.find(
    p => p.slug === props.pageContext.learningPath.slug
  );
  const isLastLesson =
    last(learningPath?.treeChildren ?? [])?.id === props.data.page?.id;
  useOnMount(() => {
    markLessonAsComplete({
      course: {
        id: props.pageContext.learningPath.id,
        title: props.pageContext.learningPath.title,
      },
      lesson: {
        id: page.id,
        slug: page.slug ?? "",
      },
    });
    if (isLastLesson) {
      const cachedProgress = getCachedUniTreeWithProgress();
      if (cachedProgress) {
        const path = cachedProgress.find(
          p => p.tree.path.id === props.pageContext.learningPath.id
        );
        const courseHasCompleted = path?.tree.children.every(
          lesson => lesson.id === page.id || !!lesson.completionTime
        );
        if (courseHasCompleted) {
          markCourseAsComplete({
            course: {
              id: props.pageContext.learningPath.id,
              title: props.pageContext.learningPath.title,
            },
          });
        }
      }
    }
  });
  return (
    <Page
      {...props}
      style={
        {
          "--ac": colorV4(colorName),
          "--ac10": withOpacity(colorV4(colorName), 0.1),
          "--ac15": withOpacity(colorV4(colorName), 0.15),
          "--ac25": withOpacity(colorV4(colorName), 0.25),
          "--ac50": withOpacity(colorV4(colorName), 0.5),
          "--acDarker": colorV4(colorName, 700),
        } as CSSProperties
      }
    >
      <SEO
        title={`${page.title} | Tines University`}
        image={ogImage}
        description="Get to know the features and concepts of Tines, in detail."
      />
      <AdminToolBarWithEditInDatoCmsButton
        itemType="universityLesson"
        recordId={page.id}
      />
      <WiderArticlePageBody generateTableOfContents supportsColorThemes>
        <PageBreadCrumbs
          icon={<UniversityTwoToneIcon />}
          levels={[
            {
              title: "University",
              path: Paths.university(),
            },
            ...levels,
          ]}
        />
        <HubPageHeader>
          <h1>{page.title}</h1>
        </HubPageHeader>
        <SpaceChildren size="lg">
          <StandardArticleStructuredTextWithCustomBlocks value={page.content} />
          {isLastLesson && learningPath && (
            <UniLearningPathCompletionCard learningPath={learningPath} />
          )}
          <VotingBanner />
          <HubPrevNextNav tree={props.pageContext.tree} />
        </SpaceChildren>
      </WiderArticlePageBody>
    </Page>
  );
};

export const uniLessonPageByIdQuery = graphql`
  query UniLessonPageById($id: String) {
    page: datoCmsLesson(originalId: { eq: $id }) {
      id: originalId
      title
      slug
      meta {
        updatedAt
      }
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              width
              height
              url
            }
            caption
            link
            altTag
            showInFullPageWidth
            applyBorder
          }
          ... on DatoCmsPullQuote {
            id: originalId
            content
            source
            color
          }
          ... on DatoCmsVideoBlock {
            id: originalId
            title
            url
            videoFile {
              video {
                mp4Url
                streamingUrl
                thumbnailUrl
              }
              width
              height
              basename
              alt
              url
            }
            autoplay
            loop
          }
          ... on DatoCmsIframeEmbed {
            id: originalId
            src
            title
            aspectRatio
            aspectRatioMobile
            height
            heightMobile
          }
          ... on DatoCmsCustomHtmlBlock {
            id: originalId
            htmlContent
          }
          ... on DatoCmsChecklistBlock {
            id: originalId
            customIcon
            color
            listContent {
              value
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            heading
            spotIllustration {
              url
            }
            themeColor
            buttonLabel
            buttonLink
          }
          ... on DatoCmsStoryEmbed {
            id: originalId
            story {
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            pillText
            storyUrl
            overrideColor
            utmCampaign
            utmSource
            utmMedium
          }
          ... on DatoCmsHtmlWysiwygBlock {
            id: originalId
            content
          }
          ... on DatoCmsCalloutBanner {
            id: originalId
            content
            calloutType
          }
        }
        links {
          __typename
          ... on DatoCmsDocsPage {
            id: originalId
            slug
            title
            ...DocsPageTree
          }
        }
      }
    }
  }
`;

export default UniLessonPageTemplate;
