export const LadderToSky = () => (
  <svg
    width="67"
    height="101"
    viewBox="0 0 67 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.6461 23.2461C54.181 23.2461 53.73 23.3072 53.3003 23.4212C53.1208 18.0682 48.7283 13.7831 43.3321 13.7831C39.1848 13.7831 35.6297 16.3135 34.1238 19.9139C33.2564 19.5431 32.301 19.3374 31.2974 19.3374C27.3209 19.3374 24.0977 22.561 24.0977 26.5375C24.0977 30.5138 27.3209 33.7374 31.2974 33.7374C33.4721 33.7374 52.6968 33.7374 54.6461 33.7374C57.5428 33.7374 59.8915 31.3891 59.8915 28.4917C59.8915 25.5946 57.5428 23.2461 54.6461 23.2461Z"
      fill="#E9E6FF"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.2469 37.919C21.5716 36.9585 27.2882 36.3245 33.7839 36.3245C40.2796 36.3245 45.9962 36.9587 49.3209 37.919C51.2522 37.3612 52.3769 36.6933 52.3769 35.9752C52.3769 34.0213 44.0525 32.4373 33.7839 32.4373C23.5153 32.4373 15.1909 34.0213 15.1909 35.9752C15.1909 36.6931 16.3156 37.3612 18.2469 37.919Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M33.7841 100.156C51.5764 100.156 65.9999 96.8462 65.9999 92.7628C65.9999 88.6795 51.5764 85.3693 33.7841 85.3693C15.9918 85.3693 1.56836 88.6795 1.56836 92.7628C1.56836 96.8462 15.9918 100.156 33.7841 100.156Z"
      fill="#32274B"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M4.00227 95.5859C8.83577 92.9033 20.3484 91.0153 33.7838 91.0153C47.2192 91.0153 58.7318 92.9031 63.5653 95.5859C65.1327 94.7159 65.9994 93.7629 65.9994 92.7628C65.9994 88.6795 51.5759 85.3693 33.7836 85.3693C15.9914 85.3693 1.56787 88.6795 1.56787 92.7628C1.56824 93.7631 2.43513 94.7159 4.00227 95.5859Z"
      fill="#8173E6"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M47.6625 98.1927C47.6875 98.0069 47.7032 97.8167 47.7032 97.6217V46.8157C47.7032 44.9589 46.532 43.4536 45.0882 43.4536C43.6436 43.4536 42.4731 44.9589 42.4731 46.8157V97.6217C42.4731 97.974 42.5155 98.3134 42.5936 98.6324C44.3489 98.5183 46.0428 98.3706 47.6625 98.1927Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M23.5691 98.5327C23.6326 98.2424 23.669 97.9378 23.669 97.6217V46.8157C23.669 44.9589 22.4982 43.4536 21.054 43.4536C19.6097 43.4536 18.439 44.9589 18.439 46.8157V97.6217C18.439 97.7579 18.447 97.8916 18.4593 98.0237C20.0836 98.2256 21.792 98.3961 23.5691 98.5327Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M44.5886 87.9098C44.5886 89.2509 43.5015 90.338 42.1604 90.338H23.6685C22.3273 90.338 21.2402 89.2509 21.2402 87.9098C21.2402 86.5687 22.3273 85.4816 23.6685 85.4816H42.1604C43.5015 85.4816 44.5886 86.5687 44.5886 87.9098Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M44.5886 72.967C44.5886 74.3081 43.5015 75.3952 42.1604 75.3952H23.6685C22.3273 75.3952 21.2402 74.3081 21.2402 72.967C21.2402 71.6259 22.3273 70.5388 23.6685 70.5388H42.1604C43.5015 70.5388 44.5886 71.6259 44.5886 72.967Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M44.5886 58.0231C44.5886 59.3642 43.5015 60.4513 42.1604 60.4513H23.6685C22.3273 60.4513 21.2402 59.3642 21.2402 58.0231C21.2402 56.6819 22.3273 55.5948 23.6685 55.5948H42.1604C43.5015 55.5948 44.5886 56.6819 44.5886 58.0231Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M18.4385 48.2148V50.2448C18.4385 50.2448 21.1144 50.9832 23.6058 51.0752V48.5837L18.4385 48.2148Z"
      fill="#32274B"
    />
    <path
      d="M47.6888 48.2148V50.2448C47.6888 50.2448 45.013 50.9832 42.5215 51.0752V48.5837L47.6888 48.2148Z"
      fill="#32274B"
    />
    <path
      d="M33.7841 28.972C15.9919 28.972 1.56836 32.2822 1.56836 36.3656C1.56836 37.2638 1.56836 40.5774 1.56836 41.4755C1.56836 45.5588 15.9919 48.8691 33.7841 48.8691C51.5763 48.8691 65.9999 45.5588 65.9999 41.4755C65.9999 40.5772 65.9999 37.2636 65.9999 36.3656C65.9997 32.282 51.5763 28.972 33.7841 28.972ZM33.7841 39.5131C23.5155 39.5131 15.1911 37.9292 15.1911 35.975C15.1911 34.021 23.5155 32.4371 33.7841 32.4371C44.0527 32.4371 52.3771 34.021 52.3771 35.975C52.3771 37.9292 44.0526 39.5131 33.7841 39.5131Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M33.7841 28.972C15.9919 28.972 1.56836 32.2822 1.56836 36.3656C1.56836 40.4489 15.9919 43.7591 33.7841 43.7591C51.5763 43.7591 65.9999 40.4489 65.9999 36.3656C65.9999 32.2822 51.5763 28.972 33.7841 28.972ZM33.7841 39.5131C23.5155 39.5131 15.1911 37.9292 15.1911 35.975C15.1911 34.021 23.5155 32.4371 33.7841 32.4371C44.0527 32.4371 52.3771 34.021 52.3771 35.975C52.3771 37.9292 44.0526 39.5131 33.7841 39.5131Z"
      fill="#81D8B4"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M47.6625 38.1388C47.6875 37.953 47.7032 37.7629 47.7032 37.5679V33.3362C47.7032 31.4793 46.532 30.7219 45.0882 30.7219C43.6436 30.7219 42.4731 31.4793 42.4731 33.3362V37.5679C42.4731 37.9201 42.5155 38.2595 42.5936 38.5785C44.3489 38.4642 46.0428 38.3165 47.6625 38.1388Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M23.5691 38.4788C23.6326 38.1885 23.669 37.8839 23.669 37.5679V33.3362C23.669 31.4793 22.4982 30.7219 21.054 30.7219C19.6097 30.7219 18.439 31.4793 18.439 33.3362V37.5679C18.439 37.704 18.447 37.8378 18.4593 37.9698C20.0836 38.1717 21.792 38.3424 23.5691 38.4788Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M36.2991 32.3063C39.1859 32.3063 41.526 31.5901 41.526 30.7065C41.526 29.823 39.1859 29.1067 36.2991 29.1067C33.4124 29.1067 31.0723 29.823 31.0723 30.7065C31.0723 31.5901 33.4124 32.3063 36.2991 32.3063Z"
      fill="#32274B"
    />
    <path
      d="M38.1396 30.4994C38.7081 31.1483 35.9196 32.0615 33.1817 32.0615C30.4435 32.0615 27.6412 31.1356 28.2241 30.4994C29.5012 29.1052 33.1817 25.2703 33.1817 25.2703C33.1817 25.2703 37.2273 29.459 38.1396 30.4994Z"
      fill="#49B889"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M34.5361 26.6135C34.5361 27.3614 33.9297 27.6369 33.1818 27.6369C32.4339 27.6369 31.8276 27.3612 31.8276 26.6135V3.09297C31.8276 2.34508 32.4339 1.73877 33.1818 1.73877C33.9297 1.73877 34.5361 2.34508 34.5361 3.09297V26.6135Z"
      fill="#B0A6F9"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
    />
    <path
      d="M31.8306 0.463867V8.86927L44.2368 4.61539L31.8306 0.463867Z"
      fill="#FABE64"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M20.6927 54.3843C19.1735 54.3955 17.7844 54.9411 16.7003 55.8412C15.4359 53.0566 12.6236 51.1284 9.3685 51.1521C4.95808 51.1844 1.40913 54.7859 1.44163 59.1959C1.47394 63.6059 5.07501 67.1551 9.48524 67.1228C11.9084 67.105 18.6033 67.0561 20.7853 67.0402C24.2803 67.0146 27.0926 64.1609 27.067 60.6659C27.0414 57.1711 24.1884 54.3589 20.6927 54.3843Z"
      fill="#E9E6FF"
      stroke="#32274B"
      strokeWidth="0.933935"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.3311 70.2005C18.3311 70.2005 22.347 70.574 23.6545 69.2665V66.3713C23.6545 66.3713 22.2134 67.0402 20.786 67.0402C19.3586 67.0402 18.4244 66.8383 18.4244 66.8383L18.3311 70.2005Z"
      fill="#32274B"
    />
    <path
      d="M18.2468 37.9191V34.2993C18.2468 34.2993 15.2264 34.756 15.1907 35.9754C15.1551 37.1947 18.2468 37.9191 18.2468 37.9191Z"
      fill="#32274B"
    />
  </svg>
);
