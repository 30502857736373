type Props = {};

const PageIcon = (props: Props) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H11C11.5523 2 12 2.44772 12 3V12C12 12.5523 11.5523 13 11 13H4C3.44772 13 3 12.5523 3 12V3C3 2.44772 3.44772 2 4 2ZM2 3C2 1.89543 2.89543 1 4 1H11C12.1046 1 13 1.89543 13 3V12C13 13.1046 12.1046 14 11 14H4C2.89543 14 2 13.1046 2 12V3ZM5 11V7H10V11H5ZM4 7C4 6.44772 4.44772 6 5 6H10C10.5523 6 11 6.44772 11 7V11C11 11.5523 10.5523 12 10 12H5C4.44772 12 4 11.5523 4 11V7ZM5 3C4.44772 3 4 3.44772 4 4C4 4.55228 4.44772 5 5 5C5.55228 5 6 4.55228 6 4C6 3.44772 5.55228 3 5 3ZM7.5 3.5C7.22386 3.5 7 3.72386 7 4C7 4.27614 7.22386 4.5 7.5 4.5H10.5C10.7761 4.5 11 4.27614 11 4C11 3.72386 10.7761 3.5 10.5 3.5H7.5Z"
      />
    </svg>
  );
};

export default PageIcon;
