import { navigate } from "gatsby";
import { ReactNode } from "react";
import { isDevelopment } from "../../environment";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { runAfter } from "../../utils/promises.utils";
import {
  addOriginIfIsRelativeUrl,
  isRelativePath,
} from "../../utils/urls.utils";
import LoadingIndicator from "./LoadingIndicator";
import Spacing from "../layout/Spacing";

type Props = {
  /** non-relative URLs must include protocol e.g. https:// */
  to: string;
  message?: ReactNode;
  debug?: boolean;
  delay?: number;
};

const RedirectHandler = (props: Props) => {
  useOnMount(() => {
    runAfter(() => {
      const source = new URL(window.location.href);
      if (isRelativePath(props.to)) {
        navigate(props.to);
        return;
      }
      const target = new URL(addOriginIfIsRelativeUrl(props.to));
      source.searchParams.forEach((value, key) => {
        target.searchParams.set(key, value);
      });
      if (props.debug && isDevelopment) {
        // eslint-disable-next-line no-console
        console.info(target.toString());
        return;
      }
      window.location.href = target.toString();
    }, props.delay ?? 500);
  });
  if (props.delay === 0) return null;
  return (
    <>
      <LoadingIndicator size={24} />
      {props.message && (
        <>
          <Spacing size="1.2em" />
          <div>{props.message}</div>
        </>
      )}
    </>
  );
};

export default RedirectHandler;
