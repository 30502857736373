import { styled } from "@linaria/react";
import gsap from "gsap";
import { useRef, useState } from "react";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
  withOpacity,
} from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { clearAnimatedProps } from "../../utils/animations.utils";
import { ValidFaqQuestion } from "../../utils/datocms.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { StaticFaqRecord } from "../reusableSections/FaqListSection";
import { colors } from "../../styles/colors.styles";
import StandardArticleStructuredTextWithCustomBlocks, {
  StructuredTextWithCustomBlocks,
} from "../articles/StructuredTextWithCustomBlocks";

type Props = {
  id?: string;
  datoRecord?: ValidFaqQuestion;
  questionColor?: BrandColorNameV4 | string;
  initialOpenState?: boolean;
  staticRecord?: StaticFaqRecord;
};

const FAQQuestionContainer = styled.div`
  border-top: 1px solid ${withOpacity(colors.purple200, 0.4)};
`;

const Header = styled.header<{ color?: BrandColorNameV4 | string }>`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: minmax(auto, 1fr) auto;
  padding: ${rSize("md")} 0;
  color: ${p => (p.color ? colorV4(p.color) : colorsV4.purple)};
  font-size: inherit;
  cursor: pointer;
  &:hover {
    ${fromTablet} {
      filter: brightness(1.2);
    }
  }
  ${fromTabletMd} {
    font-size: 2rem;
  }
  ${fromDesktopMd} {
    font-size: 2.4rem;
  }
`;
const Question = styled.div`
  font-weight: 600;
  line-height: 1.375;
  padding-right: 1em;
  * {
    font-weight: inherit;
  }
`;
const Arrow = styled.span`
  display: inline-block;
  transition: 0.2s;
  align-self: center;
  ${onlyPhones} {
    font-size: 2.4rem;
    font-weight: 600;
  }
  .answerVisible & {
    transform: rotate(180deg);
  }
`;
const AnswerContainer = styled.div`
  overflow: hidden;
`;
const Answer = styled.div`
  padding-bottom: ${rSize("md")};
  line-height: 1.4;
  ${fromDesktop} {
    padding-right: ${getWidthPxInMaxGrid(3, true)};
  }
  a {
    text-decoration: none;
    color: ${colorsV4.purple};
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    + p {
      margin-top: 0.75em;
    }
  }
`;

const FaqEntryBlock = (props: Props) => {
  const [shouldShowAnswer, setShouldShowAnswer] = useState(
    props.initialOpenState ?? false
  );
  const answerRef = useRef<HTMLDivElement>(null);
  const handleHeaderClick = () => {
    if (shouldShowAnswer) {
      animateCloseAnswer();
      setShouldShowAnswer(false);
    } else {
      animateOpenAnswer();
      setShouldShowAnswer(true);
    }
  };
  const animateOpenAnswer = () => {
    clearAnimatedProps(answerRef.current, "height");
    gsap.from(answerRef.current, {
      height: 0,
      duration: 0.15,
      ease: "power3.out",
      onComplete: () => {
        clearAnimatedProps(answerRef.current, "height");
      },
    });
  };
  const animateCloseAnswer = () => {
    gsap.to(answerRef.current, {
      height: 0,
      duration: 0.15,
      ease: "power3.out",
    });
  };
  useOnMount(() => {
    if (!props.initialOpenState) {
      gsap.set(answerRef.current, {
        height: 0,
      });
    }
  });
  return (
    <FAQQuestionContainer
      id={props.id}
      className={shouldShowAnswer ? "answerVisible" : undefined}
    >
      <Header color={props.questionColor} onClick={handleHeaderClick}>
        {props.datoRecord ? (
          <Question>
            <StructuredTextWithCustomBlocks value={props.datoRecord.question} />
          </Question>
        ) : (
          <Question>{props.staticRecord?.question}</Question>
        )}
        <Arrow>↓</Arrow>
      </Header>
      <AnswerContainer ref={answerRef}>
        {props.datoRecord ? (
          <Answer>
            <StandardArticleStructuredTextWithCustomBlocks
              value={props.datoRecord.answer}
            />
          </Answer>
        ) : (
          <Answer>{props.staticRecord?.answer}</Answer>
        )}
      </AnswerContainer>
    </FAQQuestionContainer>
  );
};

export default FaqEntryBlock;
