import { styled } from "@linaria/react";
import { LogoBEYONCyber } from "../thirdPartyLogos/LogoBEYONCyber";
import LogoCanva from "../thirdPartyLogos/LogoCanva";
import { LogoChegg } from "../thirdPartyLogos/LogoChegg";
import { LogoCode42 } from "../thirdPartyLogos/LogoCode42";
import LogoCoinbase from "../thirdPartyLogos/LogoCoinbase";
import LogoDatabricks from "../thirdPartyLogos/LogoDatabricks";
import { LogoDropbox } from "../thirdPartyLogos/LogoDropbox";
import { LogoElastic36h } from "../thirdPartyLogos/LogoElastic36h";
import LogoGitLab from "../thirdPartyLogos/LogoGitLab";
import LogoIntercom from "../thirdPartyLogos/LogoIntercom";
import { LogoKayak } from "../thirdPartyLogos/LogoKayak";
import LogoMars20h from "../thirdPartyLogos/LogoMars20h";
import LogoMcKesson from "../thirdPartyLogos/LogoMcKesson";
import { LogoMyFitnessPal } from "../thirdPartyLogos/LogoMyFitnessPal";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import LogoOpenTable from "../thirdPartyLogos/LogoOpenTable";
import { LogoRedditForLightBackgrounds } from "../thirdPartyLogos/LogoReddit";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import LogoSophos from "../thirdPartyLogos/LogoSophos";
import { LogoSprinklr } from "../thirdPartyLogos/LogoSprinklr";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { LogoJamf32 } from "../thirdPartyLogos/LogoJamf32";
import { useRef, useState } from "react";
import gsap from "gsap";
import { runAfter } from "../../utils/promises.utils";
import { fromDesktop } from "../../styles/breakpointsAndMediaQueries.styles";

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > * {
    + * {
      margin-top: 2em;
      ${fromDesktop} {
        margin-top: 2.5em;
      }
    }
  }
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
  > * {
    margin-right: 2em;
  }
`;

export const ThreeRowCustomerLogoMarquee = () => {
  const [ready, setReady] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    setReady(true);
    const tweens = [] as GSAPTween[];
    runAfter(() => {
      tweens.push(
        ...Array.from(ref.current?.childNodes ?? []).map((c, i) => {
          const row = c as HTMLDivElement;
          return gsap.to(row, {
            x: "-50%",
            duration: row.clientWidth / 25 - i * 7,
            repeat: -1,
            ease: "linear",
          });
        })
      );
    });
    return () => {
      tweens.forEach(tween => {
        tween.kill();
      });
    };
  });
  return (
    <Container ref={ref}>
      <Row>
        <RowOneContents />
        {ready && <RowOneContents />}
      </Row>
      <Row>
        <RowTwoContents />
        {ready && <RowTwoContents />}
      </Row>
      <Row>
        <RowThreeContents />
        {ready && <RowThreeContents />}
      </Row>
    </Container>
  );
};

const RowOneContents = () => (
  <>
    <LogoBEYONCyber />
    <LogoCanva />
    <LogoChegg />
    <LogoCode42 />
    <LogoCoinbase />
    <LogoDatabricks />
    <LogoDropbox />
  </>
);

const RowTwoContents = () => (
  <>
    <LogoElastic36h />
    <LogoGitLab />
    <LogoIntercom />
    <LogoJamf32 />
    <LogoKayak />
    <LogoMars20h />
  </>
);

const RowThreeContents = () => (
  <>
    <LogoMcKesson />
    <LogoMyFitnessPal />
    <LogoOakRidge />
    <LogoOpenTable />
    <LogoRedditForLightBackgrounds />
    <LogoSnowflake />
    <LogoSophos />
    <LogoSprinklr />
  </>
);
