import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
gsap.registerPlugin(CustomEase);

// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
export const scrollEase = CustomEase.create(
  "custom",
  "M0,0,C0.176,0,0.094,0.38,0.218,0.782,0.272,0.958,0.374,1,1,1"
) as gsap.EaseFunction;
