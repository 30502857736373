import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  DatoCmsArticle,
  DatoCmsCaseStudy,
  DatoCmsEvent,
} from "../../../graphql-types";
import StaticImageFrame from "../basic/StaticImageFrame";
import { colors } from "../../styles/colors.styles";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  entry?: DatoCmsArticle | DatoCmsCaseStudy | DatoCmsEvent;
  staticImageSrc?: string;
  maxHeight?: string | number;
};

const ImageFrameWrapper = styled.div<{ maxHeight?: string | number }>`
  background-color: ${colors.light300};
  border-radius: 1rem;
  ${fromTablet} {
    border-radius: 1.6rem;
  }
  ${fromDesktop} {
    border-radius: 2.2rem;
  }
  overflow: hidden;
  transform: translateZ(0);
  > * {
    margin-left: auto;
    margin-right: auto;
  }
`;

const ArticleCoverImage = (props: Props) => {
  const Wrapper = (wrapperProps: PropsWithChildren<{}>) => (
    <ImageFrameWrapper maxHeight={props.maxHeight}>
      {wrapperProps.children}
    </ImageFrameWrapper>
  );
  return props.entry?.coverImage?.url ? (
    <Wrapper>
      <StaticImageFrame
        src={props.entry.coverImage.url.split("?")[0]}
        backgroundColor="transparent"
        intrinsicWidth={props.entry.coverImage.width}
        intrinsicHeight={props.entry.coverImage.height}
        aspectRatio={`${props.entry.coverImage.width}/${props.entry.coverImage.height}`}
        fadeInOnLoad
      />
    </Wrapper>
  ) : props.staticImageSrc ? (
    <Wrapper>
      <StaticImageFrame
        src={props.staticImageSrc}
        backgroundColor="transparent"
      />
    </Wrapper>
  ) : null;
};

export default ArticleCoverImage;
