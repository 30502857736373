import axios from "axios";
import { UploadedFileMeta } from "../components/basic/FileUploader";
import { readFileAsBase64String } from "./file.utils";

export type ZendeskAttachment = {
  content_type: string;
  content_url: string;
  deleted: boolean;
  file_name: string;
  height: string;
  id: number;
  inline: boolean;
  mapped_content_url: string;
  size: number;
  thumbnails: unknown[];
  url: string;
  width: string;
};

export const zendeskAttachmentUploadHandler = async (file: File) => {
  const readResult = await readFileAsBase64String(file);
  const url = `/api/utils/upload-zendesk-attachment?fileName=${file.name}`;
  const response = await axios.post<UploadedFileMeta>(url, readResult, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
