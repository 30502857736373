import { styled } from "@linaria/react";
import { ReactNode } from "react";
import {
  fromDesktop,
  fromTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import {
  darkModeLinariaCSS,
  lightModeLinariaCSS,
} from "../../utils/colorScheme.utils";
import FormulasPill from "../general/FormulasPill";
import ClickToCopy from "../utilities/ClickToCopy";
import FormattedString from "./FormattedString";
import { ErrorBoundary } from "@sentry/react";

type Props = {
  source: unknown;
  appearance?: "json" | "nocode";
  canCopy?: boolean;
};

const CopyButton = styled.button`
  position: absolute;
  top: 1em;
  right: 1em;
  appearance: none;
  font-size: 1.2rem;
  color: ${colors.white};
  background-color: ${colors.purple};
  padding: 0.1em 0.7em;
  border-radius: 2em;
  border: 0;
  font-weight: 600;
  font-family: ${font("sans")};
  cursor: pointer;
  ${fromDesktop} {
    opacity: 0;
  }
  .touch & {
    opacity: 1;
  }
`;

export const JsonWithFormulasContainer = styled.div`
  position: relative;
  background-color: ${withOpacity(colors.light100, 0.75)};
  ${darkModeLinariaCSS(`
    background-color: ${withOpacity(colors.dark900, 0.75)};
  `)}
  padding: 0.5em 1em;
  border-radius: 0.5em;
  ${fromTablet} {
    padding: 0.75em 1em;
  }
  font-family: ${font("monospace")};
  font-size: 1.2rem;
  font-weight: 400;
  overflow: auto;
  &:hover {
    ${CopyButton} {
      opacity: 1;
    }
  }
`;

const Inner = styled.div``;

// const DebugPre = styled.div`
//   margin-top: 1em;
//   opacity: 0.5;
//   white-space: pre;
// `;

const FormattedJSONBlock = (props: Props) => {
  return (
    <JsonWithFormulasContainer>
      <ErrorBoundary
        fallback={<>{JSON.stringify(props.source, undefined, 2)}</>}
      >
        <Inner>
          {JSON.stringify(props.source, undefined, 2)
            .split("\n")
            .map((line, i, arr) => {
              return (
                <Line
                  line={line}
                  key={i}
                  index={i}
                  shouldShowLineNumber={arr.length > 1}
                  appearance={props.appearance}
                />
              );
            })}
        </Inner>
      </ErrorBoundary>
      {/* {isDevelopment && (
        <DebugPre>{JSON.stringify(props.source, undefined, 2)}</DebugPre>
      )} */}
      {props.canCopy && props.source !== null && props.source !== undefined && (
        <ClickToCopy
          contentToCopy={JSON.stringify(props.source ?? "")}
          successMessage="Copied to clipboard"
        >
          <CopyButton>Copy</CopyButton>
        </ClickToCopy>
      )}
    </JsonWithFormulasContainer>
  );
};

const LineWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto minmax(0, 1fr);
  align-items: start;
  padding-right: 1em;
  min-height: 2em;
`;

const LineNumber = styled.span`
  opacity: 0.2;
  text-align: right;
  width: 3em;
  padding-right: 1em;
  user-select: none;
  pointer-events: none;
`;

const LineContent = styled.div``;

const WhiteSpace = styled.span`
  white-space: pre;
`;

const FieldName = styled.span``;

const NumericValue = styled.span`
  color: ${colors.green};
  ${lightModeLinariaCSS(`color: ${colors.green600}`)};
`;
const BooleanValue = styled.span`
  color: ${colors.pink};
  ${lightModeLinariaCSS(`color: ${colors.pink600}`)};
`;

const StringValue = styled.span`
  color: ${colors.orange};
  ${lightModeLinariaCSS(`color: ${colors.orange600}`)};
`;
const BracketWrap = styled.span`
  opacity: 0.5;
`;
const PunctuationWrap = styled.span`
  opacity: 0.5;
`;
const NullWrap = styled.span`
  opacity: 0.4;
`;

const Line = (props: {
  line: string;
  index: number;
  shouldShowLineNumber: boolean;
  appearance?: "json" | "nocode";
}) => {
  const isJsonAppearance = !props.appearance || props.appearance === "json";
  const isNoCode = !isJsonAppearance;

  const [, fieldName] =
    props.line.match(/^\s*"([^(?<!\):]+)":\s*".*",?$/) ??
    props.line.match(/^\s*"([^(?<!\):]+)":\s*"?[^,]*"?,?$/) ??
    [];
  const [, whitespace] = props.line.match(/^(\s*)/) ?? [];
  const [, comma] = props.line.match(/(,?)$/) ?? [];

  const [, stringValueWithKey] =
    props.line.match(/^\s*"[^(?<!\):]+":\s*(".*"),?$/) ?? [];
  const [, nonStringValueValueWithKey] =
    props.line.match(/^\s*"[^(?<!\):]+":\s*([^,]*),?$/) ??
    props.line.match(/^\s*"[^(?<!\):]+":\s*(.*),?$/) ??
    [];
  const [, stringValueWithoutKey] = props.line.match(/^\s*(".*"),?$/) ?? [];
  const [, nonStringValueValueWithoutKey] =
    props.line.match(/^\s*([^,]*),?$/) ?? props.line.match(/^\s*(.*),?$/) ?? [];

  const stringValue = JSON.parse(
    (fieldName ? stringValueWithKey : stringValueWithoutKey) ?? '""'
  ) as string;
  const nonStringValue =
    (fieldName ? nonStringValueValueWithKey : nonStringValueValueWithoutKey) ??
    "";

  let valueFormatted = stringValue as ReactNode | null;

  const numberFieldMatch = nonStringValue.match(/^-?[0-9.]+$/);
  const booleanFieldMatch = nonStringValue.match(/^(true|false)$/);
  const emptyArrayOrObjectFieldMatch = nonStringValue.match(/^(\[\]|{})$/);
  const nullFieldMatch = nonStringValue.match(/^null$/);

  const formulasFieldMatch = isNoCode ? stringValue.match(/^=(.+)/) : null;

  switch (true) {
    case /^[{}\[\]],?$/.test(nonStringValue): {
      valueFormatted = <BracketWrap>{nonStringValue}</BracketWrap>;
      break;
    }
    case !!numberFieldMatch: {
      valueFormatted = <NumericValue>{nonStringValue}</NumericValue>;
      break;
    }
    case !!booleanFieldMatch: {
      valueFormatted = <BooleanValue>{nonStringValue}</BooleanValue>;
      break;
    }
    case !!emptyArrayOrObjectFieldMatch: {
      valueFormatted = <BracketWrap>{nonStringValue}</BracketWrap>;
      break;
    }
    case !!nullFieldMatch: {
      valueFormatted = <NullWrap>{nonStringValue}</NullWrap>;
      break;
    }
    case !!formulasFieldMatch: {
      const pillContent = formulasFieldMatch![1];
      valueFormatted = <FormulasPill f={pillContent} />;
      break;
    }
    default: {
      // treat as string field
      valueFormatted = (
        <StringValue quoteValue={isJsonAppearance}>
          <FormattedString string={stringValue} appearance={props.appearance} />
        </StringValue>
      );
    }
  }

  return (
    <LineWrap>
      {props.shouldShowLineNumber && <LineNumber>{props.index + 1}</LineNumber>}
      <WhiteSpace>{whitespace}</WhiteSpace>
      <LineContent>
        {fieldName && (
          <>
            <FieldName>
              {isJsonAppearance ? `"${fieldName}"` : fieldName}
            </FieldName>
            <PunctuationWrap>:</PunctuationWrap>{" "}
          </>
        )}
        {valueFormatted ?? props.line}
        {isJsonAppearance && comma && (
          <PunctuationWrap>{comma}</PunctuationWrap>
        )}
      </LineContent>
    </LineWrap>
  );
};

export default FormattedJSONBlock;
