import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import SpotIllustration from "../illustrations/SpotIllustration";
import { SpotIllustrationName } from "../illustrations/spots/PNGSpotIllustrations";
import ReportCard from "./ReportCard";

type Props = {
  illustration: SpotIllustrationName;
  largerHeading?: boolean;
  illustrationBackdropColor?: string;
};

const CardWithIllustrationInner = styled.div`
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTabletMd} {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: auto minmax(0, 1fr);
    grid-gap: 2.25em;
  }
`;

const IllustrationWrap = styled.div`
  border-radius: 1em;
  padding: 1em;
  align-self: start;
  img {
    width: 115px;
    height: 115px;
  }
  ${onlyPhones} {
    margin-top: -0.5em;
    + * {
      margin-top: 1.5em;
    }
  }
`;

const Inner = styled.div`
  h3 {
    .largerHeading & {
      margin-top: 0.5em;
      margin-bottom: 0;
    }
  }
  * {
    + p {
      margin-top: 1em;
    }
  }
`;
const ReportCardWithSpotIllustration = (props: PropsWithChildren<Props>) => {
  return (
    <ReportCard largerHeading={props.largerHeading}>
      <CardWithIllustrationInner>
        <IllustrationWrap
          style={{
            backgroundColor: props.illustrationBackdropColor ?? colorsV4.canvas,
          }}
        >
          <SpotIllustration name={props.illustration} />
        </IllustrationWrap>
        <Inner>{props.children}</Inner>
      </CardWithIllustrationInner>
    </ReportCard>
  );
};

export default ReportCardWithSpotIllustration;
