import { styled } from "@linaria/react";
import { inRange, last, round, sumBy } from "lodash-es";
import { ReactNode, useRef } from "react";
import { withOpacity } from "../../../styles/colorsV4.styles";
import { cx } from "linaria";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { isDevelopment } from "../../../environment";
import { onlyPhones } from "../../../styles/breakpointsAndMediaQueries.styles";

type Props = {
  heading?: ReactNode;
  total: number;
  rows?: number;
  dotSize?: number;
  gap?: number;
  showOriginalValue?: boolean;
  noLabels?: boolean;
  hideOutOfRange?: boolean;
  fixedSize?: boolean;
  flipHorizontal?: boolean;
  series: {
    value: number;
    label: ReactNode;
    color: string;
    labelFlexBasis?: string;
    labelColor?: string;
  }[];
};

export const ReportV2DotGridGraphContainer = styled.div`
  h4 {
    + * {
      margin-top: 0.5em;
    }
  }
  svg,
  canvas {
    display: block;
    max-width: 100%;
    height: auto;
    aspect-ratio: inherit;
  }
  &.fixedSize {
    svg,
    canvas {
      max-width: unset;
    }
  }
  svg {
    > * {
      pointer-events: none;
    }
  }
`;

const GraphContainer = styled.div`
  .fixedSize & {
    display: inline-block;
  }
`;

const Labels = styled.div`
  margin-top: 0.5em;
  display: flex;
  font-size: 1.2rem;
  white-space: nowrap;
  b {
    font-weight: 700;
    ${onlyPhones} {
      display: block;
    }
  }
  em {
    font-style: normal;
    /* font-feature-settings: "tnum" 1; */
  }
  > * {
    &:last-child:not(:only-child) {
      text-align: right;
    }
    padding-right: 0.5em;
    &:last-child {
      padding-right: 0;
    }
  }
`;

const renderAsCanvas = isDevelopment ? true : true;

const ReportV2DotGridGraph = (props: Props) => {
  const rows = props.rows ?? 10;
  const gap = props.gap ?? 2;
  const dotSize = props.dotSize ?? 2;
  const cols = props.total / rows;

  const series = props.series.map((s, i, arr) => {
    const value = round(s.value);
    const start = sumBy(props.series.slice(0, i), "value");
    const end =
      !props.hideOutOfRange && i === arr.length - 1
        ? props.total
        : start + value;
    const calculatedValue = end - start;
    const percentage = round(round(calculatedValue / props.total, 3) * 100, 1);
    return {
      value: value,
      color: s.color,
      label: s.label,
      labelColor: s.labelColor ?? s.color,
      labelFlexBasis: s.labelFlexBasis ?? `${percentage}%`,
      range: [start, end],
      percentage,
    };
  });

  const totalDots = last(series)?.range[1] ?? 0;
  const inRangeCols = Math.ceil(totalDots / rows);
  const visibleCols = props.hideOutOfRange ? inRangeCols : cols;

  const w = visibleCols * dotSize + (visibleCols - 1) * gap;
  const h = rows * dotSize + (rows - 1) * gap;

  const dots = Array(cols)
    .fill(null)
    .map((col, colIndex) =>
      Array(rows)
        .fill(null)
        .map((row, rowIndex) => {
          const index = colIndex * rows + rowIndex;
          if (index > totalDots - 1) return null;
          const currentSeries = series.find(s =>
            inRange(index, s.range[0], s.range[1])
          );
          const r = dotSize / 2;
          const x = (dotSize + gap) * colIndex + r;
          const y = (dotSize + gap) * rowIndex + r;
          const fill = currentSeries?.color ?? "currentColor";
          return {
            id: `${col}-${row}`,
            x: props.flipHorizontal ? w - x : x,
            y,
            r,
            fill,
          };
        })
    )
    .flat()
    .filter(i => i) as {
    id: string;
    x: number;
    y: number;
    r: number;
    fill: string;
  }[];

  const graphContainerRef = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    if (renderAsCanvas) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d")!;
      const paint = () => {
        ctx.clearRect(0, 0, w, h);
        dots.forEach(d => {
          ctx.fillStyle = d.fill;
          ctx.beginPath();
          ctx.arc(d.x, d.y, d.r, 0, 2 * Math.PI);
          ctx.fill();
        });
      };
      const handleContainerResize = (
        clientWidth = graphContainerRef.current?.clientWidth ?? w
      ) => {
        const containerWidth = Math.ceil(props.fixedSize ? w : clientWidth);
        const containerHeight = Math.ceil(
          props.fixedSize ? h : (containerWidth / w) * h
        );
        const scalar = (clientWidth / w) * window.devicePixelRatio;
        canvas.width = containerWidth * window.devicePixelRatio;
        canvas.height = containerHeight * window.devicePixelRatio;
        ctx.canvas.style.width = `${containerWidth}px`;
        ctx.canvas.style.height = `${containerHeight}px`;
        ctx.scale(scalar, scalar);
        paint();
      };
      const resizeObserver = new ResizeObserver(entries => {
        const { clientWidth } = entries[0].target;
        handleContainerResize(clientWidth);
      });
      setTimeout(() => {
        resizeObserver.observe(graphContainerRef.current!);
      });
      graphContainerRef.current?.append(canvas);
      const checkDevicePixelRatio = () => {
        if (devicePixelRatio !== window.devicePixelRatio) {
          handleContainerResize();
        }
      };
      window.addEventListener("resize", checkDevicePixelRatio);
      return () => {
        graphContainerRef.current?.removeChild(canvas);
        resizeObserver.disconnect();
        window.removeEventListener("resize", checkDevicePixelRatio);
      };
    } else {
      return () => {};
    }
  });

  return (
    <ReportV2DotGridGraphContainer
      className={cx(props.fixedSize && "fixedSize")}
    >
      {props.heading && <h4>{props.heading}</h4>}
      <GraphContainer
        ref={graphContainerRef}
        style={{ aspectRatio: `${w}/${h}` }}
      >
        {renderAsCanvas ? null : (
          <svg
            width={w}
            height={h}
            viewBox={`0 0 ${w} ${h}`}
            fill="currentColor"
          >
            {dots.map(d => (
              <circle key={d.id} cx={d.x} cy={d.y} r={d.r} fill={d.fill} />
            ))}
          </svg>
        )}
      </GraphContainer>
      {!props.noLabels && (
        <Labels>
          {series.map((s, i) => (
            <div
              key={i}
              style={{
                flex: `0 1 ${s.labelFlexBasis}`,
                color: s.labelColor,
              }}
            >
              <b>{s.label}</b>{" "}
              <em>{props.showOriginalValue ? s.value : `${s.percentage}%`}</em>
            </div>
          ))}
        </Labels>
      )}
    </ReportV2DotGridGraphContainer>
  );
};

export const ReportV2DotGridGraphSimple = (
  props: Omit<Props, "series" | "noLabels"> & { value: number; color: string }
) => {
  return (
    <ReportV2DotGridGraph
      {...props}
      noLabels
      series={[
        { value: props.value, label: "", color: props.color },
        ...(props.hideOutOfRange
          ? []
          : [
              {
                value: props.total - props.value,
                label: "",
                color: withOpacity(props.color, 0.3),
              },
            ]),
      ]}
    />
  );
};

export default ReportV2DotGridGraph;
