import { styled } from "@linaria/react";
import { getWidthPxInMaxGrid } from "../../../constants/globalGrid.constants";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import {
  fromDesktopMd,
  fromTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import RigidDotGrid from "../../decorations/RigidDotGrid";
import { cx } from "linaria";
import {
  fromReportV2DesktopDesign,
  inReportV2MobileDesign,
} from "./reportsV2.helpers";

type Props = {
  id?: string;
  className?: string;
  backgroundColor?: string;
  accentColor?: string;
  color?: string;
  backdropDotGridColor?: string;
  isLastSection?: boolean;
  injectNodesAfter?: ReactNode;
};

const ReportV2SectionContainer = styled.section`
  position: relative;
  border-top-left-radius: var(--sectionRadius);
  border-top-right-radius: var(--sectionRadius);
  padding-top: 4em;
  padding-bottom: calc(4em + var(--sectionRadius));
  &.isLastSection {
    border-radius: var(--sectionRadius);
  }
  ${inReportV2MobileDesign} {
    --sectionRadius: 0px;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  ${fromTablet} {
    padding-left: 3em;
    padding-right: 3em;
  }
  ${fromReportV2DesktopDesign} {
    padding-left: 7.2em;
    padding-right: calc(${rSize("pageMargin")});
    --sectionRadius: 1.2rem;
  }
  ${fromTablet} {
    padding-top: 5em;
    padding-bottom: calc(5em + var(--sectionRadius));
  }
  ${fromReportV2DesktopDesign} {
    margin-right: -4.8rem;
  }
  ${fromDesktopMd} {
    padding-top: 6.5em;
    padding-left: ${getWidthPxInMaxGrid(2, true)};
    padding-bottom: calc(6.5em + var(--sectionRadius));
    margin-right: 0;
  }
  &:not(:first-child) {
    ${fromReportV2DesktopDesign} {
      margin-top: calc(var(--sectionRadius) * -1);
    }
  }
`;

const Content = styled.div`
  position: relative;
  ${fromReportV2DesktopDesign} {
    margin-right: ${rSize("widerPageMargin", -1)};
  }
  hr {
    background-color: currentColor;
    margin: ${rSize("lg")} 0;
    height: 1px;
    border: 0;
    opacity: 0.2;
  }
`;

const ReportV2Section = (props: PropsWithChildren<Props>) => {
  return (
    <ReportV2SectionContainer
      id={props.id}
      className={cx(
        "ReportV2Section",
        props.isLastSection && "isLastSection",
        props.className
      )}
      style={
        {
          backgroundColor: props.backgroundColor,
          color: props.color,
          "--ac": props.accentColor,
        } as CSSProperties
      }
    >
      {props.backdropDotGridColor && (
        <RigidDotGrid color={props.backdropDotGridColor} />
      )}
      <Content>{props.children}</Content>
      {props.injectNodesAfter}
    </ReportV2SectionContainer>
  );
};

export default ReportV2Section;
