import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
import SEO from "../components/utilities/SEO";
import { PageComponent } from "../types/gatsby.types";
import Page from "./Page.template";
import {
  DatoCmsLibraryCollection,
  LibraryCollectionByIdQuery,
} from "../../graphql-types";
import { withOpacity } from "../styles/colorsV4.styles";
import LibraryPageHero from "../components/library/LibraryPageHero";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { LayoutWithSidebar } from "../components/layout/LayoutWithSidebar";
import LibrarySidebar from "../components/library/LibrarySidebar";
import { styled } from "@linaria/react";
import { rSize } from "../styles/responsiveSizes.styles";
import LibraryCollectionPageSection, {
  LibraryCollectionPageSectionRecord,
} from "../components/library/pageSections/LibraryCollectionPageSection";
import CommunityEditionCTASection from "../components/library/pageSections/CommunityEditionCTASection";
import { useIsLoggedIn } from "../utils/hooks.utils";
import { useOnMount } from "../utils/lifeCycle.utils";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../utils/urlQueryParams.utils";
import { LibraryPageViewType } from "../components/library/LibraryPageViewSwitcher";
import LibraryStoryTable from "../components/library/LibraryStoryTable";
import WithLibraryState from "../components/library/WithLibraryState";
import Spacing from "../components/layout/Spacing";
import LibraryEmptyState from "../components/library/LibraryEmptyState";
import { useRankedStoriesAndTools } from "../utils/library.utils";
import RelatedCollectionsSection from "../components/library/pageSections/RelatedCollectionsSection";
import { useSiteContext } from "../context/site.context";
import { colors, getBrandColorTheme } from "../styles/colors.styles";
import {
  fromTablet,
  onlyPhones,
} from "../styles/breakpointsAndMediaQueries.styles";
import defaultSeoImage from "../../static/images/og/tines-library.png";
import { AdminToolBarWithEditInDatoCmsButton } from "../components/site/AdminToolbar";
import { LoadingStateBanner } from "../components/utilities/LoadingIndicatorBanner";
import { getSystemColorScheme } from "../utils/colorScheme.utils";
import { LibraryCollectionType } from "../utils/libraryCollectionType.enum";

const BodyContent = styled.div`
  padding-bottom: ${rSize("lg")};
  ${onlyPhones} {
    padding-left: ${rSize("pageMargin")};
    padding-right: ${rSize("pageMargin")};
  }
`;

const FeedContentWrap = styled.div`
  > * {
    margin-top: 4em;
    ${fromTablet} {
      margin-top: ${rSize("sectionPadding")};
    }
    &:first-child {
      margin-top: ${rSize("xl")};
    }
  }
`;

const LibraryCollectionPage: PageComponent<{
  data: LibraryCollectionByIdQuery;
  pageContext: {
    id: string;
    relatedCollections: DatoCmsLibraryCollection[];
  };
}> = props => {
  const siteContext = useSiteContext();
  const collection = props.data.collection as DatoCmsLibraryCollection;
  const theme = getBrandColorTheme(collection.color);
  const hasStoryOpen = useRef(!!siteContext.library.storyToRenderInLightbox);
  hasStoryOpen.current = !!siteContext.library.storyToRenderInLightbox;
  const loggedIn = useIsLoggedIn();
  const [view, setView] = useState<LibraryPageViewType>("featured");
  const handleViewSwitch = (view: LibraryPageViewType) => {
    setView(view);
    setUrlQueryParam("view", view);
  };

  useOnMount(() => {
    return () => {
      removeUrlQueryParam("view");
    };
  });

  const location = useLocation();

  useEffect(() => {
    const urlParams = getUrlQueryParams<{
      view?: LibraryPageViewType;
    }>();
    if (urlParams.view !== view) setView(urlParams.view || "featured");
  }, [location.search, view]);

  const canEdit = !hasStoryOpen.current;

  const { awaitingData, error, stories, tools } = useRankedStoriesAndTools({
    collectionId: collection.slug !== "" ? props.pageContext.id : undefined,
  });

  const isDarkNavTheme =
    siteContext.colorScheme === "dark" ||
    (siteContext.supportsColorSchemes &&
      siteContext.colorScheme === "auto" &&
      getSystemColorScheme() === "dark");

  return (
    <Page
      {...props}
      navThemeColor={theme.name === "dark" ? "purple" : theme.name}
      navLogoColor={isDarkNavTheme ? undefined : colors.dark500}
    >
      <SEO
        title={[
          collection.slug === "/" ? "Home" : collection.name,
          "Tines library",
        ]
          .filter(i => i)
          .join(" | ")}
        seoTitle={collection.seoTitle}
        description={collection.seoDescription || collection.description}
        image={collection.seoImage?.url ?? defaultSeoImage}
      />
      {canEdit && (
        <AdminToolBarWithEditInDatoCmsButton
          itemType="libraryCollection"
          recordId={collection.id}
        />
      )}
      <WithLibraryState
        awaitingData={awaitingData}
        error={error}
        entries={stories}
        entryType="story"
        tools={tools}
        children={p => (
          <LayoutWithSidebar
            noPageMarginOnPhones
            style={
              {
                "--ac10": withOpacity(theme.c500, 0.1),
                "--ac20": withOpacity(theme.c500, 0.2),
                "--ac30": withOpacity(theme.c500, 0.3),
                "--ac": theme.c500,
                "--ac50": theme.c50,
                "--ac100": theme.c100,
                "--ac10020": withOpacity(theme.c100, 0.2),
                "--ac10060": withOpacity(theme.c100, 0.6),
                "--ac300": theme.c300,
                "--ac600": theme.c600,
                "--ac700": theme.c700,
                "--ac800": theme.c800,
                "--ac80050": withOpacity(theme.c800, 0.5),
                "--ac900": theme.c900,
              } as CSSProperties
            }
          >
            <LibrarySidebar state={p.state} />
            <BodyContent>
              <LibraryPageHero
                collection={collection}
                view={view}
                handleViewSwitch={handleViewSwitch}
                libraryState={p.state}
                totalCount={stories.length}
                topTagLabel={
                  collection.collectionType === LibraryCollectionType.topLevel
                    ? undefined
                    : `${collection.collectionType}`
                }
              />
              {view === "featured" ? (
                <FeedContentWrap>
                  {collection.pageSections?.map(section => (
                    <LibraryCollectionPageSection
                      key={section!.id}
                      collection={collection}
                      section={section as LibraryCollectionPageSectionRecord}
                      stories={stories}
                    />
                  ))}
                  {collection.collectionType &&
                    collection.collectionType !==
                      LibraryCollectionType.topLevel &&
                    props.pageContext.relatedCollections.length > 0 && (
                      <RelatedCollectionsSection
                        currentCollection={collection}
                        collections={props.pageContext.relatedCollections}
                      />
                    )}
                  {!loggedIn && <CommunityEditionCTASection />}
                </FeedContentWrap>
              ) : (
                <>
                  <Spacing />
                  <LoadingStateBanner
                    loading={p.state.awaitingData}
                    error={p.state.error}
                    color={theme.name}
                  />
                  {p.state.error && p.entries.length === 0 && <Spacing />}
                  {!p.state.awaitingData &&
                    (p.entries.length > 0 ? (
                      <LibraryStoryTable
                        stories={p.entries}
                        tools={tools}
                        state={p.state}
                      />
                    ) : (
                      <LibraryEmptyState state={p.state} />
                    ))}
                </>
              )}
            </BodyContent>
          </LayoutWithSidebar>
        )}
      />
      <Spacing size="xl" />
    </Page>
  );
};

export const libraryCollectionByIdQuery = graphql`
  query LibraryCollectionById($id: String) {
    collection: datoCmsLibraryCollection(originalId: { eq: $id }) {
      id: originalId
      slug
      name
      preTagline
      tagline
      description
      color
      collectionType
      searchbarPlaceholderText
      tags
      seoTitle
      seoDescription
      image {
        url
        width
        height
      }
      seoImage {
        url
      }
      pageSections {
        __typename
        ... on DatoCmsBeginnerStoriesSection {
          id: originalId
          maxNumberOfStories
        }
        ... on DatoCmsCollectionsGridSection {
          id: originalId
          pill
          heading
          collections {
            id: originalId
            slug
            name
            collectionType
            description
            color
            image {
              url
              width
              height
            }
          }
          image {
            url
            width
            height
          }
          description
          linkTextLabel
          linkUrl
        }
        ... on DatoCmsCustomerFocusSection {
          id: originalId
          heading
          description
          customer {
            name
            logo {
              url
            }
            logoWhite {
              url
            }
          }
          caseStudy {
            slug
            color
          }
          stories {
            id: originalId
            descriptor
            internalAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            communityAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            videoIntroEmbedUrl
            gallery {
              url
              width
              height
            }
          }
        }
        ... on DatoCmsHowItWorksSection {
          id: originalId
        }
        ... on DatoCmsLatestStoriesSection {
          id: originalId
          maxNumberOfStories
        }
        ... on DatoCmsSpotlightStorySection {
          id: originalId
          story {
            id: originalId
            descriptor
            internalAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            communityAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            videoIntroEmbedUrl
            gallery {
              url
              width
              height
            }
          }
        }
        ... on DatoCmsStoryGridSection {
          id: originalId
          pill
          heading
          stories {
            id: originalId
            descriptor
            internalAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            communityAuthors {
              name
              surname
              linkedin
              organization {
                name
              }
            }
            videoIntroEmbedUrl
            gallery {
              url
              width
              height
            }
          }
          image {
            url
            width
            height
          }
          color
          description
          linkTextLabel
          linkUrl
        }
        ... on DatoCmsSubmitStoryCtaSection {
          id: originalId
        }
        ... on DatoCmsToolsSection {
          id: originalId
          pill
          heading
          tools {
            name
            slug
            image {
              url
              width
              height
            }
            imageDarkMode {
              url
              width
              height
            }
          }
        }
        ... on DatoCmsYdwwtWinnersSection {
          id: originalId
          ydwwt {
            name
            winners {
              id: originalId
              name
              description
              color
              story {
                storyId
                descriptor
                internalAuthors {
                  name
                  surname
                  linkedin
                  organization {
                    name
                  }
                }
                communityAuthors {
                  name
                  surname
                  linkedin
                  organization {
                    name
                  }
                }
                videoIntroEmbedUrl
                gallery {
                  url
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LibraryCollectionPage;
