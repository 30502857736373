import { styled } from "@linaria/react";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

export const Split = styled.div<{
  flip?: boolean;
  align?: "start" | "end" | "center" | "stretch";
}>`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: "start end";
    align-items: ${p => p.align ?? "start"};
    > *:first-child {
      grid-area: ${p => (p.flip ? "end" : "start")};
    }
    > *:last-child:not(:first-child) {
      grid-area: ${p => (p.flip ? "start" : "end")};
    }
  }
`;
