import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { colors } from "../../styles/colors.styles";
import { DatoCmsPerson } from "../../../graphql-types";
import PersonFullName from "./PersonFullName";
import PersonPhotoIcon from "./PersonPhotoIcon";
import PersonTitleWithOrgName from "./PersonTitleWithOrgName";
import { PartialAndNullable } from "../../types/helper.types";

type Props = {
  person: PartialAndNullable<DatoCmsPerson>;
  beforeName?: ReactNode;
  useAccentColorOnName?: boolean;
  iconSize?: number;
};

const PersonInfoItemContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto minmax(auto, 1fr);
  grid-gap: 0.75em;
`;
const Name = styled.strong<{ color?: string }>`
  font-weight: 600;
  font-size: 1.4rem;
`;
export const PersonInfoItemBeforeName = styled.div``;

const PersonInfoItem = (props: Props) => {
  return (
    <PersonInfoItemContainer>
      <PersonPhotoIcon person={props.person} size={props.iconSize ?? 48} />
      <div>
        <PersonInfoItemBeforeName>{props.beforeName}</PersonInfoItemBeforeName>
        <p>
          <Name color={props.useAccentColorOnName ? colors.purple : undefined}>
            <PersonFullName person={props.person} />
          </Name>
        </p>
        <PersonTitleWithOrgName
          className={css`
            line-height: 1.15;
            font-size: 1.2rem;
            opacity: 0.75;
            font-weight: 500;
          `}
          person={props.person}
        />
      </div>
    </PersonInfoItemContainer>
  );
};

export default PersonInfoItem;
