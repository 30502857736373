import React, { Suspense, useEffect, useRef, useState } from "react";
import { debounce } from "../../utils/debounce.utils";

// eslint-disable-next-line import/no-named-as-default-member
const WorkbenchRiverOfShapes = React.lazy(
  () => import("./WorkbenchRiverOfShapes")
);

export const WorkbenchRiverOfShapesResizeManager = () => {
  const [vw, setVw] = useState(0);
  const [vh, setVh] = useState(0);
  const vhRef = useRef(vh);
  vhRef.current = vh;
  useEffect(() => {
    setVw(window.innerWidth);
    setVh(window.innerHeight);
    const handleResize = debounce(
      () => {
        setVw(window.innerWidth);
        if (vhRef.current < window.innerHeight && vhRef.current < 600) {
          setVh(window.innerHeight);
        }
      },
      {
        fireImmediately: true,
      }
    );
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [vw]);
  return vw > 0 && vh > 0 ? (
    <Suspense>
      <WorkbenchRiverOfShapes key={`${vw}_${vh}`} />
    </Suspense>
  ) : null;
};
