import { styled } from "@linaria/react";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

const CheckIconWrap = styled.span`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 0.3em;
  background-color: ${withOpacity(colorsV4.canvas700, 0.4)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colorsV4.warmBlack, 0.8)};`
  )}
  svg {
    color: var(--ac);
  }
  .active & {
    background-color: var(--ac10);
  }
  &.completed {
    background-color: var(--ac25);
    ${darkModeLinariaCSS(`background-color: var(--ac50);`)}
    .active & {
      background-color: var(--ac25);
      ${darkModeLinariaCSS(`background-color: var(--ac50);`)}
    }
  }
  &.partial {
    background-color: var(--ac15);
    .active & {
      background-color: var(--ac15);
    }
  }
`;

const UniCompletionCheckIcon = (props: { completed: number }) => (
  <CheckIconWrap
    className={
      props.completed === 1
        ? "completed"
        : props.completed === 0
        ? ""
        : "partial"
    }
  >
    {props.completed === 1 ? (
      <svg width="10" height="8" viewBox="0 0 10 8" fill="none">
        <path
          d="M1 3.05882L3.96296 6L9 1"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    ) : null}
  </CheckIconWrap>
);

export default UniCompletionCheckIcon;
