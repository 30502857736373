import { styled } from "@linaria/react";
import {
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../../styles/fonts.styles";
import { ReportV2SectionSubheading } from "./ReportV2SectionSubheading";

export const ReportV2SectionHeadingMedium = styled.h2`
  font-size: 3rem;
  letter-spacing: -0.01em;
  ${fromTablet} {
    font-size: 3.4rem;
  }
  ${fromTabletLg} {
    font-size: 3.6rem;
  }
  ${fromDesktopMl} {
    font-size: 4.2rem;
    letter-spacing: -0.02em;
  }
  line-height: 1.1;
  font-family: ${font("serif")};
  font-weight: 400;
  + ${ReportV2SectionSubheading} {
    margin-top: 0.5em;
  }
  span {
    display: block;
  }
`;
