import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { cover } from "polished";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { uptoDesktop } from "../../styles/breakpointsAndMediaQueries.styles";
import { DatoCmsPerson } from "../../../graphql-types";
import PersonFullName from "./PersonFullName";
import StaticImageFrame from "../basic/StaticImageFrame";
import { color, colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  person: DatoCmsPerson;
};

export const PersonCardContainer = styled.div`
  position: relative;
`;
const PersonCardInner = styled.div<Props>`
  ${cover()};
  background-color: ${colors.purple};
  border-radius: ${rSize("radius")};
  padding: 0.5em;
  display: grid;
  overflow: hidden;
  transform: translateZ(0);
`;
const imageStyle = css`
  ${cover("-1px")};
`;
const NameTab = styled.div`
  background-color: ${withOpacity(colors.dark500, 0.7)};
  backdrop-filter: blur(5em);
  color: ${colors.white};
  padding: 1em;
  border-radius: ${rSize("radius", 0.75)};
  align-self: end;
  display: grid;
  grid-template-columns: minmax(auto, 1fr) auto;
  grid-gap: 0.5em;
  position: relative;
  transform: translateZ(0);
`;
const NameAndTitleGroup = styled.div`
  font-size: 1.4rem;
`;
const Name = styled.p`
  font-weight: 600;
`;
const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  opacity: 0.75;
`;

const linkedInLinkStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  ${uptoDesktop} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &:hover {
    color: ${color("purple")};
  }
`;
const PersonCard = (props: Props) => {
  return (
    <PersonCardContainer>
      <PersonCardInner person={props.person}>
        <StaticImageFrame
          className={imageStyle}
          src={props.person.photoPortrait?.url}
          intrinsicWidth={357}
          intrinsicHeight={400}
          backgroundColor={colors.purple}
          cover
        />
        <NameTab>
          <NameAndTitleGroup>
            <Name>
              <PersonFullName person={props.person} />
            </Name>
            <Title>{props.person.title || "Tines Leadership"}</Title>
          </NameAndTitleGroup>
          {props.person.linkedin && (
            <a
              className={linkedInLinkStyle}
              href={props.person.linkedin}
              title={`Connect with ${
                props.person.name ?? "this Tines member"
              } on LinkedIn`}
              {...externalLinkAttr}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 0C3.58172 0 0 3.58172 0 8V24C0 28.4183 3.58172 32 8 32H24C28.4183 32 32 28.4183 32 24V8C32 3.58172 28.4183 0 24 0H8ZM9.5162 7.99963C10.7566 7.99963 11.5205 8.81324 11.544 9.88143C11.544 10.9275 10.7566 11.7645 9.4923 11.7645H9.46913C8.25182 11.7645 7.46484 10.9275 7.46484 9.88143C7.46484 8.81324 8.27578 7.99963 9.5162 7.99963ZM7.67969 13.2518H11.3056V24.1463H7.67969V13.2518ZM24.382 17.8996C24.382 14.5533 22.5932 12.9958 20.2072 12.9958C18.2821 12.9958 17.4201 14.0533 16.9389 14.7951V13.252H13.3125C13.3603 14.2742 13.3125 24.1464 13.3125 24.1464H16.9389V18.0621C16.9389 17.7365 16.9624 17.4117 17.0583 17.1785C17.3204 16.5281 17.917 15.8546 18.9188 15.8546C20.2314 15.8546 20.7561 16.8536 20.7561 18.3176V24.1461H24.3819L24.382 17.8996Z"
                />
              </svg>
            </a>
          )}
        </NameTab>
      </PersonCardInner>
    </PersonCardContainer>
  );
};

export default PersonCard;
