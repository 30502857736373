import { css, cx } from "@linaria/core";
import { PropsWithChildren } from "react";
import {
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import ReportCard from "./ReportCard";

type Props = PropsWithChildren<{
  backgroundColor?: string;
  centered?: boolean;
}>;

const ReportChartContainer = (props: Props) => {
  return (
    <ReportCard
      backgroundColor={props.backgroundColor ?? colorsV4.canvas}
      centered={props.centered}
      className={cx(
        "ReportChartContainer",
        css`
          padding-top: 2em;
          h4 {
            ${fromTablet} {
              font-size: 1.8rem;
            }
          }
          ${onlyPhones} {
            border-radius: none;
            background-color: transparent;
            padding: 0;
            text-align: center;
          }
        `
      )}
    >
      {props.children ?? "Chart Placeholder"}
    </ReportCard>
  );
};

export default ReportChartContainer;
