import { styled } from "@linaria/react";
import Hls from "hls.js";
import { useEffect, useRef } from "react";
import { DatoCmsAssetVideo } from "../../../graphql-types";

type Props = {
  datoAssetVideo: DatoCmsAssetVideo;
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
};

const VideoPlayerVideo = styled.video`
  width: 100%;
  display: block;
`;

const VideoPlayer = (props: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const src = props.datoAssetVideo.streamingUrl;
  useEffect(() => {
    let hls: Hls;
    if (!src) return;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browsers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        // console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src, videoRef]);
  return (
    <VideoPlayerVideo
      ref={videoRef}
      poster={props.datoAssetVideo?.thumbnailUrl ?? undefined}
      autoPlay={props.autoPlay}
      loop={props.loop}
      muted={props.autoPlay}
      playsInline
      controls={props.controls ?? true}
    />
  );
};

export default VideoPlayer;
