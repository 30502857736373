export const ProServIlloTopRightFloatingElements = () => (
  <svg
    width="131"
    height="154"
    viewBox="0 0 131 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5652 5.85876C31.101 5.85876 29.7531 6.33671 28.6487 7.12685C27.3312 3.83922 24.126 1.51025 20.3618 1.51025C16.6003 1.51025 13.3925 3.84198 12.0778 7.12961C10.9707 6.33671 9.62276 5.85876 8.15582 5.85876C4.4248 5.85876 1.40234 8.87842 1.40234 12.5998C1.40234 16.3267 4.42757 19.3436 8.15582 19.3436C10.4863 19.3436 17.7296 19.3436 20.3618 19.3436C22.994 19.3436 30.2374 19.3436 32.5652 19.3436C36.299 19.3436 39.3214 16.3239 39.3214 12.5998C39.3241 8.87842 36.299 5.85876 32.5652 5.85876Z"
      fill="#FEEBEE"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M111.603 90.201C112.104 90.0207 112.687 89.4718 112.898 88.9829L113.772 86.9525C113.981 86.4623 114.302 86.4715 114.482 86.9733L115.234 89.0503C115.414 89.5503 115.966 90.1323 116.458 90.3414L118.491 91.2114C118.98 91.4212 118.971 91.7403 118.47 91.9213L116.388 92.6717C115.884 92.852 115.302 93.4003 115.092 93.8886L114.218 95.9196C114.008 96.4097 113.687 96.3999 113.509 95.8994L112.754 93.8205C112.575 93.3205 112.024 92.7392 111.534 92.53L109.498 91.6594C109.008 91.4496 109.018 91.1305 109.52 90.9502L111.603 90.201Z"
      fill="#FBBD74"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M91.2083 104.585C91.8011 104.309 92.4529 103.574 92.6567 102.953L93.4993 100.374C93.7007 99.7519 94.0944 99.7276 94.3701 100.321L95.5193 102.776C95.7948 103.368 96.5333 104.018 97.1578 104.219L99.7393 105.057C100.36 105.26 100.384 105.651 99.7922 105.927L97.3312 107.075C96.7346 107.351 96.0834 108.086 95.8811 108.706L95.0378 111.285C94.8356 111.908 94.4427 111.931 94.1686 111.339L93.0162 108.882C92.7422 108.29 92.0038 107.641 91.3822 107.439L88.7969 106.601C88.1745 106.399 88.1513 106.008 88.7448 105.732L91.2083 104.585Z"
      fill="#FBBD74"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M130.273 143.247C130.273 142.385 120.81 141.686 109.137 141.686C97.4637 141.686 88 142.385 88 143.247C88 143.855 88 150.023 88 150.632C88 151.493 97.4631 152.88 109.137 152.88C120.81 152.88 130.273 151.493 130.273 150.632C130.273 150.023 130.273 143.856 130.273 143.247Z"
      fill="#B0E0BD"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M109.137 145.13C120.81 145.13 130.273 144.359 130.273 143.408C130.273 142.457 120.81 141.686 109.137 141.686C97.4632 141.686 88 142.457 88 143.408C88 144.359 97.4632 145.13 109.137 145.13Z"
      fill="#D6EDD9"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M112.19 144.067C115.87 144.067 118.853 143.546 118.853 142.904C118.853 142.261 115.87 141.74 112.19 141.74C108.51 141.74 105.527 142.261 105.527 142.904C105.527 143.546 108.51 144.067 112.19 144.067Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M107.652 143.49C112.764 143.49 116.909 139.353 116.909 134.25C116.909 129.147 112.764 125.01 107.652 125.01C102.539 125.01 98.3945 129.147 98.3945 134.25C98.3945 139.353 102.539 143.49 107.652 143.49Z"
      fill="#D7C4FA"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
