import { CanadianStateList } from "../../constants/CanadianStateList.constant";
import { USStateList } from "../../constants/USStateList.constant";
import { getRegionFieldLabel } from "../../utils/country.utils";
import Select, { SelectProps } from "../forms/Select";
import TextInput, { TextInputProps } from "../forms/TextInput";

type Props<T extends object> = SelectProps<T> &
  TextInputProps<T> & {
    country?: string | null;
  };

const usStateOptions = USStateList.map(c => ({
  label: c,
  value: c,
}));
const canadianStateOptions = CanadianStateList.map(c => ({
  label: c,
  value: c,
}));

function RegionSelector<T extends object>(props: Props<T>) {
  const country = props.country;
  const options = (() => {
    switch (country) {
      case "United States":
        return usStateOptions;
      case "Canada":
        return canadianStateOptions;
      default:
        return [];
    }
  })();
  if (options.length) {
    return (
      <Select
        {...props}
        options={[
          {
            label: `${getRegionFieldLabel(country)}${
              props.required ? " *" : ""
            }`,
            value: "",
            disabled: true,
          },
          ...options,
        ]}
      />
    );
  }
  return <TextInput {...props} />;
}

export default RegionSelector;
