import { styled } from "@linaria/react";
import { cx } from "linaria";
import { colors, withOpacity } from "../../styles/colors.styles";
import { ValidCalloutBanner } from "../../utils/datocms.utils";
import DangerouslySetHtmlContent from "../utilities/DangerouslySetHtmlContent";
import { lightModeLinariaCSS } from "../../utils/colorScheme.utils";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  record?: ValidCalloutBanner;
};

const CalloutBannerDiv = styled.div`
  position: relative;
  background-color: ${withOpacity(colors.purple, 0.125)};
  padding: 0.66em 1.25em 1em 1.25em;
  border-radius: ${rSize("radius")};
  h4 {
    display: flex;
    color: ${colors.purple};
    margin-bottom: 0.375em;
    strong {
      font-family: color-emoji;
      flex: 0 0 auto;
    }
    span {
      ${lightModeLinariaCSS(`filter: brightness(0.9);`)}
    }
    > * {
      + * {
        margin-left: 0.375em;
      }
    }
  }
  a {
    font-weight: 700;
    color: ${colors.purple};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.Important {
    background-color: ${withOpacity(colors.orange, 0.125)};
    border: 1px solid ${withOpacity(colors.orange, 0.25)};
    h4,
    a {
      color: ${colors.orange};
    }
  }
  &.Warning {
    background-color: ${withOpacity(colors.yellow, 0.2)};
    border: 1px solid ${withOpacity(colors.yellow, 0.375)};
    h4,
    a {
      color: ${colors.yellow700};
    }
  }
  &.Tip {
    background-color: ${withOpacity(colors.pink, 0.125)};
    border: 1px solid ${withOpacity(colors.pink, 0.25)};
    h4,
    a {
      color: ${colors.pink};
    }
  }
  &.Info {
    background-color: ${withOpacity(colors.green, 0.125)};
    border: 1px solid ${withOpacity(colors.green, 0.25)};
    h4,
    a {
      color: ${colors.green};
    }
  }
`;

const iconMap = {
  Note: "💡",
  Important: "❗️",
  Warning: "⚠️",
  Info: "ℹ️",
  Tip: "🪄",
};

const CalloutBanner = (props: Props) => {
  const type = props.record?.calloutType || "Note";
  const icon = iconMap[type as keyof typeof iconMap] ?? "💡";
  return (
    <CalloutBannerDiv className={cx("CalloutBanner", type)}>
      <h4>
        <strong>{icon}</strong>
        <span>{type}</span>
      </h4>
      <DangerouslySetHtmlContent html={props.record?.content ?? ""} />
    </CalloutBannerDiv>
  );
};

export default CalloutBanner;
