import { styled } from "@linaria/react";
import { DatoCmsCaseStudy } from "../../../graphql-types";
import { Link } from "gatsby";
import Spacing from "../layout/Spacing";
import SiteNavHeightPlaceholder from "../site/SiteNavHeightPlaceholder";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import {
  fromDesktopLg,
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import RigidDotGrid from "../decorations/RigidDotGrid";
import MaxPageContentWidth from "../layout/MaxPageContentWidth";
import { rSize } from "../../styles/responsiveSizes.styles";
import { font, serif } from "../../styles/fonts.styles";
import TinesLogoIcon from "../misc/TinesLogoIcon";
import { Paths } from "../../utils/pathBuilders.utils";
import AllCaps from "../typography/AllCaps";
import { css } from "linaria";
import { getBrandColorTheme } from "../../styles/colors.styles";

type Props = {
  caseStudy: DatoCmsCaseStudy;
};

const CaseStudyHeroSectionWrap = styled.section`
  position: relative;
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 2em;
  background-color: ${withOpacity(colorsV4.canvas600, 0.5)};
  overflow: hidden;
  display: none;
  ${fromTablet} {
    display: block;
    border-radius: 0 0 2em 2em;
    bottom: 4em;
  }
  ${fromDesktopMd} {
    bottom: 6em;
  }
`;

const HeaderContentCard = styled.div`
  position: relative;
  display: grid;
  padding: 1em;
  background-color: ${colorsV4.canvas650};
  border-radius: 1.5em;
  ${fromTablet} {
    padding: 1.5em;
    border-radius: 2em;
  }
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, minmax(9em, auto));
  grid-template-areas: "header" "logoSet" "quote";
  ${fromTabletLg} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(12em, auto) minmax(14em, auto);
    grid-template-areas: "header logoSet" "header quote";
  }
  ${fromDesktopLg} {
    grid-template-rows: 1fr 1fr;
    grid-template-rows: repeat(2, minmax(14em, 1fr));
  }
  grid-gap: ${rSize("gap")};
  .HeaderContentCell {
    padding: 2em;
    border-radius: 1em;
    ${fromDesktopMd} {
      padding: 2.5em;
    }
  }
`;

type HeaderCellProps = { backgroundColor: string; foregroundColor: string };

const HeaderCell = styled.div<HeaderCellProps>`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap", 2)};
  grid-area: header;
  background-color: ${p => p.backgroundColor};
  color: ${p => p.foregroundColor};
  h1 {
    font-size: 2.8rem;
    line-height: 1.05;
    font-family: ${serif};
    font-weight: 400;
    ${fromTablet} {
      font-size: 3.2rem;
    }
    ${fromTabletLg} {
      font-size: 3.8rem;
      letter-spacing: -0.01em;
    }
    ${fromDesktopMd} {
      font-size: 4.6rem;
    }
  }
`;

const HighlightsHeading = styled.p`
  text-transform: uppercase;
  font-size: 1.4rem;
  opacity: 0.8;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.05em;
  span {
    display: block;
  }
`;

const HighlightsList = styled.ul`
  list-style: none;
  margin: 1em 0 0 0;
  padding: 0;
  li {
    position: relative;
    line-height: 1.2;
    + li {
      margin-top: 0.5em;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: calc(100% + 0.5em);
      top: calc(50% - 0.5px);
      width: 1.5em;
      height: 1px;
      background-color: currentColor;
      opacity: 0.5;
      ${fromDesktopMd} {
        right: calc(100% + 1em);
        top: calc(50% - 1px);
        height: 2px;
        opacity: 0.3;
      }
    }
  }
`;

const LogoSet = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${rSize("gap")};
`;

const CustomerLogoCell = styled.div`
  background-color: ${colorsV4.canvas};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    position: relative;
    z-index: 1;
  }
`;

const TinesLogoCell = styled.div`
  background-color: ${colorsV4.canvas};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    position: relative;
    z-index: 1;
    ${onlyPhones} {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
`;

const PlusSign = styled.svg`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FeaturedQuote = styled.blockquote`
  grid-area: quote;
  background-color: ${colorsV4.canvas};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: ${font("serif")};
  text-align: center;
  text-wrap: balance;
  ${fromTabletMd} {
    font-size: 1.8rem;
  }
  ${fromDesktopMd} {
    font-size: 2rem;
    padding-left: 4em;
    padding-right: 4em;
  }
  ${fromDesktopLg} {
    font-size: 2.2rem;
  }
`;

const CaseStudyHeroSection = ({ caseStudy }: Props) => {
  const colorTheme = getBrandColorTheme(caseStudy.color);
  return (
    <CaseStudyHeroSectionWrap>
      <Backdrop>
        <RigidDotGrid color={withOpacity(colorsV4.warmBlack, 0.15)} />
      </Backdrop>
      <SiteNavHeightPlaceholder withSpacingTop />
      <Spacing size="lg" multiplier={1.5} />
      <MaxPageContentWidth>
        <HeaderContentCard>
          <HeaderCell
            className="HeaderContentCell HeaderContentHeaderCell"
            backgroundColor={colorTheme.c500}
            foregroundColor="white"
          >
            <div>
              <Link
                to={Paths.caseStudies()}
                className={css`
                  text-decoration: none;
                `}
              >
                <AllCaps>Case study</AllCaps>
              </Link>
              <Spacing size="gap" />
              <WithCustomInlineFormatting as="h1">
                {caseStudy.title}
              </WithCustomInlineFormatting>
            </div>
            <div>
              {caseStudy.highlights && (
                <>
                  <HighlightsHeading>
                    <span>Highlights</span>
                  </HighlightsHeading>
                  <HighlightsList>
                    {caseStudy.highlights.split(/[\n]+/).map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </HighlightsList>
                </>
              )}
            </div>
          </HeaderCell>

          <LogoSet>
            <PlusSign width="78" height="95" viewBox="0 0 78 95" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 13.5C0 6.04416 6.04416 0 13.5 0C19.1456 0 23.9819 3.46551 25.9974 8.38531C26.7273 10.1669 28.6545 11.4133 30.5291 10.9742C33.2495 10.337 36.0854 10 39 10C41.9146 10 44.7505 10.337 47.4709 10.9742C49.3455 11.4133 51.2727 10.1669 52.0026 8.38531C54.0181 3.46551 58.8544 0 64.5 0C71.9558 0 78 6.04416 78 13.5V81.5C78 88.9558 71.9558 95 64.5 95C58.6085 95 53.5984 91.226 51.7554 85.9637C51.0903 84.0647 49.0865 82.6657 47.123 83.1055C44.5091 83.6911 41.7906 84 39 84C36.2094 84 33.4909 83.6911 30.877 83.1055C28.9135 82.6657 26.9097 84.0647 26.2446 85.9637C24.4016 91.226 19.3915 95 13.5 95C6.04416 95 0 88.9558 0 81.5V13.5Z"
                fill={colorsV4.canvas}
              />
              <circle cx="39" cy="47" r="27" fill={colorTheme.c100} />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38 48V55H40V48H47V46H40V39H38V46H31V48H38Z"
                fill={colorTheme.c500}
              />
            </PlusSign>
            <CustomerLogoCell className="HeaderContentCell">
              {caseStudy.customer?.logoDarkGray?.url && (
                <img src={caseStudy.customer.logoDarkGray.url} />
              )}
            </CustomerLogoCell>
            <TinesLogoCell className="HeaderContentCell">
              <TinesLogoIcon size={48} />
            </TinesLogoCell>
          </LogoSet>

          <FeaturedQuote className="HeaderContentCell">
            <WithCustomInlineFormatting as="p">
              {caseStudy.featuredQuote}
            </WithCustomInlineFormatting>
          </FeaturedQuote>
        </HeaderContentCard>
      </MaxPageContentWidth>
    </CaseStudyHeroSectionWrap>
  );
};

export default CaseStudyHeroSection;
