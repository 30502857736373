import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import {
  fromDesktop,
  fromPhoneLg,
  uptoPhoneLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import Spacing from "../../layout/Spacing";
import DemoActionButton from "./DemoActionButton";
import DemoActionButtonDraggable from "./DemoActionButtonDraggable";
import { useDemoStoryContext } from "./DemoStoryContext";
import { DemoTextPlaceholder } from "./DemoTextPlaceholder";
import { colors } from "../../../styles/colors.styles";
import { cx } from "linaria";

const DemoSidebarLeftWrap = styled.div`
  display: grid;
  background-color: ${colorsV4.canvas300};
  border-bottom-left-radius: inherit;
  ${uptoPhoneLg} {
    border-right: 1px solid ${colorsV4.canvas550};
  }
  ${fromPhoneLg} {
    grid-template-rows: repeat(6, auto) minmax(auto, 1fr);
    grid-template-columns: 1fr 1fr;
    background-color: ${colorsV4.canvas550};
    grid-gap: 1px;
    padding-right: 1px;
  }
  align-items: stretch;
  height: 100%;
  position: relative;
  z-index: 2;
  border-radius: 0 0 0 0.5em;
  .spanAll {
    ${fromPhoneLg} {
      grid-column-end: span 2;
    }
  }
  &.shaded {
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: -2px;
      ${fromPhoneLg} {
        right: 0;
      }
      bottom: 0;
      height: 8em;
      background-image: linear-gradient(
        to top,
        ${colors.lightest},
        ${withOpacity(colors.lightest, 0)}
      );
      pointer-events: none;
    }
  }
`;

const Filler = styled.div`
  background-color: ${colors.lightest};
`;

const DemoSidebarLeft = (props: { shaded?: boolean }) => {
  const context = useDemoStoryContext();
  return (
    <DemoSidebarLeftWrap className={cx(props.shaded && "shaded")}>
      <div>
        <DemoActionButton action="webhook" />
      </div>
      <div>
        {context.hasDropped ? (
          <DemoActionButton action="httpRequest" />
        ) : (
          <DemoActionButtonDraggable
            action="httpRequest"
            showDragHintAnimation
          />
        )}
      </div>
      <div>
        <DemoActionButton action="sendEmail" />
      </div>
      <div>
        <DemoActionButton action="receiveEmail" />
      </div>
      <div>
        <DemoActionButton action="ai" />
      </div>
      <div>
        <DemoActionButton action="transform" />
      </div>
      <div>
        <DemoActionButton action="trigger" />
      </div>
      <div>
        <DemoActionButton action="sendToStory" />
      </div>
      <div className="spanAll">
        <FakeFullWidthButton />
      </div>
      <div className="spanAll second">
        <FakeFullWidthButton>
          <DemoTextPlaceholder width="3.2em" />
          <DemoTextPlaceholder width="4.8em" />
        </FakeFullWidthButton>
      </div>
      <Filler />
    </DemoSidebarLeftWrap>
  );
};

const FakeFullWidthButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${uptoPhoneLg} {
    display: none;
  }
  height: 8rem;
  ${fromDesktop} {
    height: 10rem;
  }
  background-color: ${colorsV4.white};
  padding: 1.6rem;
  .second & {
    border-radius: 0 0 0 0.5em;
  }
  > div {
    display: flex;
    align-items: center;
    > * + * {
      margin-left: 0.25em;
    }
  }
`;

const FakeFullWidthButton = (props: PropsWithChildren<{}>) => (
  <FakeFullWidthButtonWrap>
    <div>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
        <rect width="18" height="18" rx="9" fill="#EEEEEE" />
      </svg>
      <Spacing size=".25em" />
      {props.children ?? <DemoTextPlaceholder width="6em" />}
    </div>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M13.5 18L16.5 15L13.5 12"
        stroke="#555555"
        strokeWidth="0.999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </FakeFullWidthButtonWrap>
);

export default DemoSidebarLeft;
