import { styled } from "@linaria/react";
import { cx } from "linaria";
import { CSSProperties, RefObject } from "react";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { Action, FormAction } from "../../types/tines.types";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { FormIcon16 } from "./icons/Form.icon.16";
import { FORM_HEIGHT, FORM_WIDTH } from "./utils/storyboard.utils";

type Props = {
  action: Action;
  style?: CSSProperties;
  isSelected?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
  onClick?: () => void;
};

const FormContainer = styled.div`
  position: relative;
  font-size: 1.2rem;
  border-radius: 14px;
  font-family: ${font("system")};
  width: ${FORM_WIDTH}px;
  height: ${FORM_HEIGHT}px;
  box-shadow: rgba(0, 0, 0, 6%) 0px 1px 3px;
  overflow: hidden;
  background-color: ${colorsV4.white};
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    border-color: ${withOpacity(colorsV4.purple, 0.2)};
  }
  ${darkModeLinariaCSS(`
    background-color: ${colorsV4.warmBlack800};
  `)}
  &.selected {
    border-color: ${colorsV4.purple};
    &:hover {
      border-color: ${withOpacity(colorsV4.purple300, 0.9)};
    }
  }
`;

const FormHeader = styled.header`
  display: grid;
  align-items: center;
  padding: 1em;
  grid-gap: 0.5em;
  grid-template-columns: auto minmax(0, 1fr);
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    width: 3em;
    background-image: linear-gradient(
      to left,
      ${colorsV4.white} 3px,
      ${withOpacity(colorsV4.white, 0)}
    );
    ${darkModeLinariaCSS(`
      background-image: linear-gradient(
        to left,
        ${colorsV4.warmBlack800} 3px,
        ${withOpacity(colorsV4.warmBlack800, 0)}
      );
    `)}
  }
  span {
    display: block;
  }
`;

const FormPreviewWindow = styled.div`
  padding: 1.5em;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  justify-content: center;
  border-radius: 0.75em;
  background-color: ${colorsV4.canvas550};
  ${darkModeLinariaCSS(`
    background-color: ${colorsV4.warmBlack900};
  `)}
  svg {
    display: block;
  }
`;

const FormBody = styled.div`
  padding: 1em;
  border-radius: 0.75em;
  background-color: ${colorsV4.white};
  ${darkModeLinariaCSS(`
    background-color: ${withOpacity(colorsV4.warmBlack1000, 0.5)};
  `)}
`;

const FormActionEntry = (props: Props) => {
  const { style, isSelected, innerRef, onClick } = props;
  const action = props.action as FormAction;
  const color = action.disabled ? colorsV4.grey : action.color;
  return (
    <FormContainer
      className={cx(isSelected && "selected")}
      data-action-index={action.index}
      data-action-guid={action.guid}
      data-disabled={action.disabled}
      ref={innerRef}
      onClick={onClick}
      style={
        {
          "--ac": color,
          "--ac10": withOpacity(color, 0.1),
          "--ac40": withOpacity(color, 0.4),
          ...style,
        } as CSSProperties
      }
    >
      <FormHeader>
        <FormIcon16 />
        <span>{action.name}</span>
      </FormHeader>
      <FormPreviewWindow>
        <FormBody>
          <PlaceholderFieldGraphics />
        </FormBody>
      </FormPreviewWindow>
    </FormContainer>
  );
};

const PlaceholderFieldGraphics = () => (
  <svg width="107" height="160" viewBox="0 0 107 160" fill="none">
    <rect
      y="19"
      width="107"
      height="13"
      rx="4"
      fill="url(#paint0_linear_999_16089)"
    />
    <rect
      y="45"
      width="49"
      height="8"
      rx="4"
      fill="url(#paint1_linear_999_16089)"
    />
    <rect opacity="0.1" y="57" width="107" height="19" rx="4" fill="#AEADAB" />
    <rect
      y="87"
      width="49"
      height="8"
      rx="4"
      fill="url(#paint2_linear_999_16089)"
    />
    <rect opacity="0.1" y="99" width="69" height="19" rx="4" fill="#AEADAB" />
    <rect
      opacity="0.1"
      x="73"
      y="99"
      width="34"
      height="19"
      rx="4"
      fill="#AEADAB"
    />
    <rect
      y="129"
      width="49"
      height="8"
      rx="4"
      fill="url(#paint3_linear_999_16089)"
    />
    <rect opacity="0.1" y="141" width="107" height="19" rx="4" fill="#AEADAB" />
    <rect opacity="0.1" width="34" height="13" rx="6.5" fill="#AEADAB" />
    <defs>
      <linearGradient
        id="paint0_linear_999_16089"
        x1="0"
        y1="32"
        x2="85.6527"
        y2="32"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A7982" stopOpacity="0.27" />
        <stop offset="1" stopColor="#7D7C87" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_999_16089"
        x1="0"
        y1="53"
        x2="39"
        y2="53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A7982" stopOpacity="0.27" />
        <stop offset="1" stopColor="#7D7C87" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_999_16089"
        x1="0"
        y1="95"
        x2="39"
        y2="95"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A7982" stopOpacity="0.27" />
        <stop offset="1" stopColor="#7D7C87" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_999_16089"
        x1="0"
        y1="137"
        x2="39"
        y2="137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A7982" stopOpacity="0.27" />
        <stop offset="1" stopColor="#7D7C87" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default FormActionEntry;
