export const FolderTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M7.5 5V5.5H8H15C15.2761 5.5 15.5 5.72386 15.5 6V14C15.5 14.2761 15.2761 14.5 15 14.5H2C1.72386 14.5 1.5 14.2761 1.5 14V4C1.5 3.72386 1.72386 3.5 2 3.5H7C7.27614 3.5 7.5 3.72386 7.5 4V5Z"
      stroke="currentColor"
    />
    <rect
      opacity="0.1"
      x="3"
      y="7"
      width="14"
      height="9"
      rx="1"
      fill="currentColor"
    />
  </svg>
);
