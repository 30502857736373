import { styled } from "@linaria/react";
import { fromTablet } from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandThemeName,
  color,
  colors,
  withOpacity,
} from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { cx } from "linaria";
import { CSSProperties, PropsWithChildren } from "react";
import LoadingIndicator from "./LoadingIndicator";

const LoadingStateBannerOuter = styled.div`
  &.sticky {
    position: sticky;
    bottom: ${rSize("md")};
  }
  border-radius: ${rSize("radius")};
  background-color: ${withOpacity(colors.lightest, 0.9)};
  ${darkModeLinariaCSS(
    `background-color: ${withOpacity(colors.dark500, 0.9)};`
  )}
  @supports (backdrop-filter: blur(1em)) {
    background-color: ${withOpacity(colors.lightest, 0.5)};
    backdrop-filter: blur(1em) brightness(1.2);
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark500, 0.5)};
      backdrop-filter: blur(1em) brightness(0.8);`
    )}
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  > * {
    + * {
      margin-left: 1em;
    }
  }
  background-color: var(--ac20);
  border: 1px solid var(--ac20);
  color: var(--ac700);
  ${darkModeLinariaCSS(`color: var(--ac300)`)};
  border-radius: ${rSize("radius")};
  padding: 1em;
  ${fromTablet} {
    padding: 2em;
  }
  text-align: center;
  font-weight: 600;
  &.loading {
    @keyframes FetchStatusBoxPulse {
      from {
        opacity: 1;
      }
      to {
        opacity: 0.8;
      }
    }
    animation: FetchStatusBoxPulse 1s alternate-reverse infinite;
  }
  &.error {
    background-color: ${withOpacity(colors.red, 0.2)};
    border: 1px solid ${withOpacity(colors.red, 0.2)};
    color: ${colors.red700};
    ${darkModeLinariaCSS(`color: ${colors.red300}`)};
  }
`;

export const LoadingStateBanner = (
  props: PropsWithChildren<{
    id?: string;
    loading?: boolean;
    color?: BrandThemeName;
    error?: string;
    stickToBottomWhenLoading?: boolean;
  }>
) => {
  const hasContent = !!(props.loading || props.error || props.children);
  return hasContent ? (
    <LoadingStateBannerOuter
      id={props.id}
      className={cx(
        props.stickToBottomWhenLoading && props.loading && "sticky"
      )}
      style={
        {
          "--ac20": withOpacity(color(props.color), 0.2),
          "--ac300": color(props.color, 300),
          "--ac700": color(props.color, 700),
        } as CSSProperties
      }
    >
      <Inner className={cx(props.loading && "loading", props.error && "error")}>
        {props.loading ? (
          <LoadingIndicator size="24px" />
        ) : props.error ? (
          props.error
        ) : (
          props.children
        )}
      </Inner>
    </LoadingStateBannerOuter>
  ) : null;
};
