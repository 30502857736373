export const BlocksOnShelvesIcon = () => (
  <svg
    width="84"
    height="86"
    viewBox="0 0 84 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M82.6805 26.6239H78.6414L60.5557 35.5523L75.0996 49.7954H82.6805V26.6239Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.6799 26.6239V49.7954L68.1357 35.5523L82.6799 26.6239Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.8658 26.6239L22.646 33.6438V39.3384L33.3243 49.7954H40.905V26.6239H36.8658Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.908 26.6239V49.7954L26.3638 35.5523L40.908 26.6239Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.6668 26.1321H33.6626L22.6367 33.4825V39.0715H71.6408L82.6668 31.7208V26.1321Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.725 31.6045L71.6426 38.9358V33.7356L82.725 26.4045V31.6045Z"
      fill="#FABE64"
    />
    <path
      d="M71.6408 33.4827H22.6367V39.0716H71.6408V33.4827Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.6421 33.4825L82.6682 26.1321"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.1525 41.8129H40.896V39.243L30.0562 39.0719L33.1525 41.8129Z"
      fill="#32313B"
    />
    <path
      d="M82.6668 26.1321H33.6626L22.6367 33.4825V39.0715H71.6408L82.6668 31.7208V26.1321Z"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.6805 61.9124H78.6414L60.5557 70.8408L75.0996 85.0839H82.6805V61.9124Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.6799 61.9124V85.0839L68.1357 70.8408L82.6799 61.9124Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.4198 61.9124L1.2002 68.9322V74.627L11.8785 85.0839H19.4589V61.9124H15.4198Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.4619 61.9124V85.0839L4.91797 70.8408L19.4619 61.9124Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.6694 61.421H12.2263L1.2002 68.7716V74.3604H71.6433L82.6694 67.0098V61.421Z"
      fill="#FDB2D2"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.725 66.8933L71.6426 74.2243V69.0244L82.725 61.6931V66.8933Z"
      fill="#FABE64"
    />
    <path
      d="M71.6431 68.7712H1.2002V74.36H71.6431V68.7712Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.6421 68.7716L82.6682 61.421"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.7155 77.101H19.4588V74.5311L8.61963 74.36L11.7155 77.101Z"
      fill="#32313B"
    />
    <path
      d="M82.6694 61.421H12.2263L1.2002 68.7716V74.3604H71.6433L82.6694 67.0098V61.421Z"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M43.0757 67.1096C47.8815 67.1096 51.7773 66.4238 51.7773 65.5779C51.7773 64.732 47.8815 64.0463 43.0757 64.0463C38.2699 64.0463 34.374 64.732 34.374 65.5779C34.374 66.4238 38.2699 67.1096 43.0757 67.1096Z"
      fill="#32313B"
    />
    <path
      d="M28.8114 65.5781C28.8114 66.424 25.2331 67.1098 20.819 67.1098C16.4056 67.1098 12.8271 66.424 12.8271 65.5781C12.8271 64.732 16.4056 64.0463 20.819 64.0463C25.2331 64.0463 28.8114 64.732 28.8114 65.5781Z"
      fill="#32313B"
    />
    <path
      d="M17.056 66.2432C22.809 66.2432 27.4728 61.5796 27.4728 55.8267C27.4728 50.0739 22.809 45.4103 17.056 45.4103C11.3029 45.4103 6.63916 50.0739 6.63916 55.8267C6.63916 61.5796 11.3029 66.2432 17.056 66.2432Z"
      fill="#EB81AF"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M70.8732 28.3679H53.6157V30.9546H66.7439L70.8732 28.3679Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M63.8878 28.5459L53.1173 0.9104L50.6368 3.31958L39.6528 30.9551H60.0613L63.8878 28.5459Z"
      fill="#6DA2F0"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.6528 30.9535H60.0613L50.6368 3.31799L39.6528 30.9535Z"
      fill="#96BEFA"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M74.3197 35.6568C74.7452 35.6568 75.0902 35.1969 75.0902 34.6298C75.0902 34.0626 74.7452 33.6028 74.3197 33.6028C73.8942 33.6028 73.5493 34.0626 73.5493 34.6298C73.5493 35.1969 73.8942 35.6568 74.3197 35.6568Z"
      fill="#32313B"
    />
    <path
      d="M80.3415 31.4072C80.7669 31.4072 81.1117 30.9474 81.1117 30.3802C81.1117 29.8131 80.7669 29.3533 80.3415 29.3533C79.9161 29.3533 79.5713 29.8131 79.5713 30.3802C79.5713 30.9474 79.9161 31.4072 80.3415 31.4072Z"
      fill="#32313B"
    />
    <path
      d="M74.3197 70.8827C74.7452 70.8827 75.0902 70.4229 75.0902 69.8557C75.0902 69.2885 74.7452 68.8287 74.3197 68.8287C73.8942 68.8287 73.5493 69.2885 73.5493 69.8557C73.5493 70.4229 73.8942 70.8827 74.3197 70.8827Z"
      fill="#32313B"
    />
    <path
      d="M80.3415 66.6328C80.7669 66.6328 81.1117 66.1731 81.1117 65.606C81.1117 65.0389 80.7669 64.5792 80.3415 64.5792C79.9161 64.5792 79.5713 65.0389 79.5713 65.606C79.5713 66.1731 79.9161 66.6328 80.3415 66.6328Z"
      fill="#32313B"
    />
    <path
      d="M31.5088 65.1802C31.5088 65.9632 35.1572 66.5975 39.6581 66.5975C44.1588 66.5975 47.8075 65.9632 47.8075 65.1802V48.4571C47.8075 47.6744 44.1588 47.04 39.6581 47.04C35.1572 47.04 31.5088 47.6744 31.5088 48.4571V65.1802Z"
      fill="#49B889"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.6581 49.8746C44.1589 49.8746 47.8075 49.2401 47.8075 48.4573C47.8075 47.6746 44.1589 47.04 39.6581 47.04C35.1574 47.04 31.5088 47.6746 31.5088 48.4573C31.5088 49.2401 35.1574 49.8746 39.6581 49.8746Z"
      fill="#81D8B4"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M73.9803 64.7922H59.9561V66.3979H71.5155L73.9803 64.7922Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M74.7873 56.3575L69.6665 47.488H59.425L56.5549 48.6574L51.4341 57.527L56.5549 66.3963H66.7963L69.6665 65.2269L74.7873 56.3575Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.5549 66.3943L51.4341 57.525L56.5549 48.6555H66.7963L71.9171 57.525L66.7963 66.3943H56.5549Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M66.792 48.6574L69.6621 47.488"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.915 57.5267L74.7852 56.3573"
      stroke="#32313B"
      strokeWidth="1.06292"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
