export const PurpleTinesKeyIcon = () => (
  <svg
    width="91"
    height="53"
    viewBox="0 0 91 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1836_18878)">
      <path
        d="M5.22979 34.2155C2.7954 35.295 1.68883 38.1619 2.75851 40.619C3.82819 43.076 6.669 44.1923 9.10345 43.1131C11.5381 42.0334 12.6444 39.1665 11.5747 36.7094C10.505 34.2525 7.66454 33.1358 5.22979 34.2155ZM8.08891 40.7827C6.92977 41.2968 5.57687 40.7652 5.06745 39.5949C4.55808 38.4252 5.08524 37.0598 6.24432 36.5457C7.40377 36.0316 8.75631 36.5633 9.26568 37.7333C9.77499 38.9034 9.24831 40.2685 8.08891 40.7827Z"
        fill="#6359B3"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M3.79496 34.852C1.36068 35.9314 0.254322 38.7984 1.32395 41.2554C2.39368 43.7124 5.23433 44.8286 7.66857 43.7495C10.1033 42.6698 11.2097 39.8029 10.14 37.3458C9.07032 34.8889 6.22966 33.7723 3.79496 34.852ZM6.65403 41.419C5.49494 41.9332 4.14236 41.4015 3.63294 40.2313C3.12357 39.0616 3.65036 37.6963 4.80949 37.1823C5.96905 36.668 7.32148 37.1998 7.83085 38.3698C8.34043 39.5397 7.81364 40.9048 6.65403 41.419Z"
        fill="#8173E6"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M87.3941 12.3952L84.1782 5.00903L69.9424 11.3218L73.1577 18.7081L75.385 17.7205L76.4102 20.0747L74.1829 21.0623L75.9307 25.077L81.6419 22.5445L79.0352 16.5577L81.8492 15.31L84.4558 21.2967L90.167 18.7642L88.4188 14.7495L86.1915 15.7372L85.1668 13.383L87.3941 12.3952Z"
        fill="#6359B3"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M85.6558 13.1658L82.44 5.77954L68.2041 12.0923L71.4199 19.4786L73.6472 18.4909L74.6719 20.8452L72.4446 21.8328L74.1929 25.8474L79.9036 23.3151L77.297 17.3283L80.1114 16.0804L82.7175 22.0672L88.4288 19.5347L86.681 15.5201L84.4537 16.5078L83.4285 14.1535L85.6558 13.1658Z"
        fill="#8173E6"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M42.7796 23.073L42.1917 22.5253C42.1523 22.4881 41.6023 21.9777 40.7334 21.2997C39.6513 19.1419 37.9376 16.2018 35.6725 13.8249C31.0119 8.93487 26.7931 9.30613 24.0749 10.479L13.4743 15.0515C10.9595 16.1364 7.98868 18.8591 8.11576 25.6695C8.17468 28.8087 8.88621 32.0473 9.71936 34.6113C9.59742 35.7515 9.58184 36.5365 9.58105 36.5918L9.56715 37.3991L10.155 37.9471C10.1942 37.984 10.7443 38.4944 11.6136 39.1722C12.695 41.3305 14.4089 44.2699 16.6741 46.6475C21.3348 51.5372 25.5536 51.166 28.2716 49.9931L38.8726 45.4206C41.3872 44.3356 44.3583 41.6133 44.2307 34.8029C44.1722 31.6634 43.4604 28.425 42.6274 25.8611C42.7493 24.7207 42.7648 23.936 42.7656 23.8804L42.7796 23.073ZM38.915 26.7014C38.5174 29.1605 37.664 31.9339 36.144 32.5894L20.9676 39.1356C19.4477 39.7912 16.8718 38.497 14.8361 37.0877L38.915 26.7014ZM13.4317 33.771C13.8291 31.3116 14.6823 28.5385 16.2022 27.8829L31.3786 21.3366C32.8987 20.681 35.4744 21.9751 37.5101 23.3845L13.4317 33.771ZM14.8784 18.368L25.4791 13.7954C28.9325 12.3059 32.0692 14.8255 34.402 17.8566C32.7425 17.4138 31.2607 17.465 29.9741 18.0201L14.798 24.5664C13.6038 25.0815 12.6045 26.0202 11.8034 27.364C11.3972 23.458 11.9531 19.63 14.8784 18.368ZM37.468 42.1042L26.8674 46.6766C23.4142 48.1662 20.2774 45.6467 17.9445 42.6157C19.6037 43.0584 21.086 43.007 22.3724 42.4521L37.5488 35.9057C38.7426 35.3906 39.742 34.452 40.5433 33.108C40.9493 37.0141 40.3935 40.8418 37.468 42.1042Z"
        fill="#6359B3"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M87.2161 3.16773C87.8856 4.70585 87.8226 6.22173 86.9133 6.62481L39.442 27.6754C37.918 28.3512 36.1394 27.6521 35.4698 26.114C34.8001 24.5758 35.4928 22.7808 37.0169 22.1049L84.5485 1.02754C85.3366 0.678156 86.5466 1.62951 87.2161 3.16773Z"
        fill="#8173E6"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M40.156 24.2363L39.5682 23.6886C39.5288 23.6515 38.9787 23.1411 38.1098 22.4632C37.028 20.3052 35.3141 17.3652 33.049 14.9883C28.3886 10.0981 24.1696 10.4695 21.4515 11.6423L10.8507 16.2149C8.33587 17.2998 5.36514 20.0224 5.49222 26.8329C5.55099 29.9721 6.26299 33.2105 7.09582 35.7746C6.97388 36.9148 6.9583 37.6998 6.95741 37.7552L6.94361 38.5627L7.53131 39.1108C7.57082 39.1473 8.12075 39.6578 8.98994 40.3356C10.0715 42.4934 11.7852 45.4333 14.0506 47.8109C18.7111 52.7006 22.9298 52.3294 25.6481 51.1565L36.2489 46.584C38.7636 45.499 41.7346 42.7767 41.6068 35.9663C41.5484 32.827 40.8367 29.5884 40.0038 27.0245C40.1257 25.8842 40.1413 25.0993 40.1419 25.0439L40.156 24.2363ZM36.2914 27.8648C35.8939 30.3238 35.0405 33.0973 33.5205 33.7527L18.344 40.299C16.824 40.9546 14.2483 39.6604 12.2127 38.251L36.2914 27.8648ZM10.8082 34.9344C11.2056 32.475 12.0588 29.7019 13.5789 29.0461L28.755 22.5C30.2752 21.8443 32.851 23.1384 34.8867 24.5478L10.8082 34.9344ZM12.255 19.5313L22.8557 14.9587C26.3091 13.4692 29.4456 15.9888 31.7787 19.0199C30.1191 18.5772 28.6372 18.6284 27.3506 19.1835L12.1744 25.7298C10.9804 26.2448 9.98081 27.1836 9.17983 28.5274C8.77367 24.6214 9.32962 20.7934 12.255 19.5313ZM34.8446 43.2676L24.2439 47.84C20.7907 49.3296 17.6538 46.8101 15.321 43.7791C16.98 44.2218 18.4625 44.1704 19.7487 43.6155L34.9253 37.0691C36.1192 36.554 37.1185 35.6154 37.9198 34.2714C38.3258 38.1774 37.7701 42.0052 34.8446 43.2676Z"
        fill="#8173E6"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M86.9943 6.58844C87.8988 6.18711 88.0898 4.61473 87.4198 3.0764C86.7503 1.53801 85.4737 0.616212 84.5687 1.01749C83.6636 1.41876 83.4731 2.9912 84.1426 4.52953C84.8126 6.06792 86.0892 6.98972 86.9943 6.58844Z"
        fill="#6359B3"
        stroke="#4D3E78"
        strokeMiterlimit="10"
      />
      <path
        d="M9.45435 23.3485L12.0073 22.2164C12.0073 22.2164 12.3788 19.6869 14.1284 18.7085C14.1284 18.7085 11.94 19.679 11.4351 19.9029C10.9302 20.1268 9.66596 21.8357 9.45435 23.3485Z"
        fill="#4D3E78"
      />
      <path
        d="M11.4685 32.5891L14.0213 31.4571C14.0213 31.4571 14.3928 28.9276 16.1425 27.9492C16.1425 27.9492 13.954 28.9197 13.4491 29.1435C12.9442 29.3674 11.6802 31.0765 11.4685 32.5891Z"
        fill="#4D3E78"
      />
      <path
        d="M12.3306 38.1857L14.9118 37.041L16.9687 38.4599L14.3874 39.6042L12.3306 38.1857Z"
        fill="#4D3E78"
      />
      <path
        d="M21.0334 45.643L19.1831 43.9299C19.1831 43.9299 15.9708 44.2397 15.2844 43.9696C15.2845 43.9696 17.9628 47.8156 21.0334 45.643Z"
        fill="#4D3E78"
      />
      <path
        d="M40.0981 27.3533L42.8351 26.1396L43.4564 28.3874L40.8051 29.5631L40.0981 27.3533Z"
        fill="#4D3E78"
      />
      <path
        d="M36.5842 36.2759L37.92 34.2715L37.9478 35.7742L36.5842 36.2759Z"
        fill="#4D3E78"
      />
      <path
        d="M28.9651 18.5413L30.7186 17.7637L31.5442 18.8395L28.9651 18.5413Z"
        fill="#4D3E78"
      />
      <path
        d="M45.9881 24.772C46.0138 25.0538 46.0166 25.292 46.0011 25.4767C45.4629 25.0549 44.5587 23.9705 43.7922 22.2097C43.0255 20.4489 42.8457 19.0431 42.9018 18.3579C43.1118 18.5223 43.3774 18.7885 43.6684 19.155L46.2311 18.0186C44.9058 16.11 43.2204 14.8349 41.6676 15.5234C39.4971 16.4859 39.9511 20.2388 41.2919 23.3185C42.6327 26.3983 45.0644 29.2742 47.2354 28.3116C48.6982 27.6628 48.9679 25.7466 48.5791 23.6235L45.9882 24.7723L45.9881 24.772Z"
        fill="#8173E6"
        stroke="#4D3E78"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.3804 23.7115C48.4061 23.9933 48.4092 24.2315 48.3935 24.4162C47.8551 23.9943 46.9511 22.9099 46.1845 21.1491C45.4179 19.3884 45.2381 17.9825 45.2941 17.2974C45.5042 17.4617 45.7698 17.728 46.0608 18.0945L48.6233 16.9581C47.2979 15.0495 45.6127 13.7743 44.06 14.4629C41.8894 15.4254 42.3434 19.1782 43.6843 22.258C45.0251 25.3378 47.4569 28.2137 49.6275 27.2511C51.0904 26.6023 51.3606 24.6861 50.9713 22.563L48.3804 23.7118V23.7115Z"
        fill="#8173E6"
        stroke="#4D3E78"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.2609 13.3313L83.3547 14.1764L84.3453 16.4522L86.2515 15.6071L85.2609 13.3313Z"
        fill="#4D3E78"
      />
      <path
        d="M79.0974 16.4131L77.1912 17.2584L78.0543 19.2419L79.9606 18.3965L79.0974 16.4131Z"
        fill="#4D3E78"
      />
      <path
        d="M85.3689 7.41382L83.4626 8.25915L84.1075 9.74143L86.0143 8.89605L85.3689 7.41382Z"
        fill="#4D3E78"
      />
      <path
        d="M50.8325 22.6287L48.1514 23.8175L48.5412 24.7129L51.2224 23.524L50.8325 22.6287Z"
        fill="#4D3E78"
      />
    </g>
    <defs>
      <clipPath id="clip0_1836_18878">
        <rect width="91" height="53" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
