import { Link, graphql } from "gatsby";
import Spacing from "../components/layout/Spacing";
import { PageComponent } from "../types/gatsby.types";
import { StoryDescriptor } from "../types/helper.types";
import SEO from "../components/utilities/SEO";
import { DatoCmsStoryDigest, StoryPageQuery } from "../../graphql-types";
import { useOnMount } from "../utils/lifeCycle.utils";
import Page from "./Page.template";
import { useState } from "react";
import {
  getUrlQueryParams,
  setUrlQueryParam,
} from "../utils/urlQueryParams.utils";
import WiderPageSection from "../components/layout/WiderPageSection";
import StoryEmbed from "../components/blocks/StoryEmbed.block";
import { reportStoryView } from "../utils/library.utils";
import { css } from "linaria";
import { colors } from "../styles/colors.styles";
import { AdminToolbarStoryPageOptions } from "../components/site/AdminToolbar";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import { convertDatoRecordToStoryDescriptor } from "../utils/story.utils";
import ColorSchemeToggle from "../components/utilities/ColorSchemeToggle";

const backLinkStyle = css`
  padding-top: 1em;
  padding-bottom: 1em;
  text-decoration: none;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  &:hover {
    color: ${colors.purple};
  }
`;

const StoryPageTemplate: PageComponent<{
  pageContext: { story: StoryDescriptor };
  data: StoryPageQuery;
}> = props => {
  const storyRecord = props.data.story as DatoCmsStoryDigest;
  const story = convertDatoRecordToStoryDescriptor(storyRecord);

  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmSource, setUtmSource] = useState("");

  const [backLink, setBackLink] = useState("/library");

  useOnMount(() => {
    const params = getUrlQueryParams();
    if (params.utm_campaign) setUtmCampaign(params.utm_campaign);
    if (params.utm_medium) setUtmMedium(params.utm_medium);
    if (params.utm_source) setUtmSource(params.utm_source);

    if (params["redirected-from"]) setBackLink(params["redirected-from"]);

    setUrlQueryParam("name", story.slug);

    reportStoryView(story);
  });

  return (
    <Page {...props}>
      <SEO
        title={`${story.name} | Library | Tines`}
        seoTitle={props.data.story?.seoTitle}
        description={props.data.story?.seoDescription || story.description}
        image={storyRecord.image?.url}
        noindex={story.isUnlisted}
      />
      <AdminToolbarStoryPageOptions
        storyId={story.id}
        datoCmsRecordId={story.datoCmsRecordId}
      />
      <SiteNavHeightPlaceholder />
      <WiderPageSection>
        <Link className={backLinkStyle} to={backLink}>
          ← Go back to library
        </Link>
        <Spacing />
        <StoryEmbed
          descriptor={story}
          fullWidth
          asLightbox
          noCloseButton
          utmCampaign={utmCampaign}
          utmMedium={utmMedium}
          utmSource={utmSource}
        />
        <Spacing size="sectionPadding" />
      </WiderPageSection>
      <ColorSchemeToggle invisible />
    </Page>
  );
};

export const storyPageQuery = graphql`
  query StoryPage($id: String) {
    story: datoCmsStoryDigest(storyId: { eq: $id }) {
      id: originalId
      descriptor
      seoTitle
      seoDescription
      image {
        url
      }
      internalAuthors {
        name
        surname
        organization {
          name
        }
      }
      communityAuthors {
        name
        surname
        organization {
          name
        }
      }
      videoIntroEmbedUrl
      gallery {
        url
        width
        height
      }
    }
  }
`;

export default StoryPageTemplate;
