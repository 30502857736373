import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingLarge } from "../components/ReportV2SectionHeadingLarge";
import Button, { ButtonGroupKeepFlexOnMobile } from "../../forms/Button";
import BookADemoButton from "../../utilities/BookADemoButton";
import Spacing from "../../layout/Spacing";
import { serif } from "../../../styles/fonts.styles";
import { SOC2023ConclusionIllustrationLeft } from "./SOC2023ConclusionIllustrationLeft";
import { SOC2023ConclusionIllustrationRight } from "./SOC2023ConclusionIllustrationRight";
import { uptoDesktopMd } from "../../../styles/breakpointsAndMediaQueries.styles";
import { shareSOC2023AccessPage } from "./soc2023.utils";

const Body = styled.div`
  p {
    font-family: ${serif};
    font-size: 1.8rem;
    max-width: 30em;
    + p {
      margin-top: 1em;
    }
  }
`;

const IllustrationLeftWrap = styled.div`
  position: absolute;
  left: -23em;
  bottom: 9em;
  ${uptoDesktopMd} {
    display: none;
  }
`;
const IllustrationRightWrap = styled.div`
  position: absolute;
  right: 4em;
  bottom: 0;
  transform: translateY(66.7%);
  max-width: 60%;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const SOC2023ConclusionSection = () => {
  return (
    <ReportV2Section
      id="conclusion"
      backgroundColor={colors.purple800}
      color={colors.white}
      isLastSection
      injectNodesAfter={
        <>
          <IllustrationLeftWrap>
            <SOC2023ConclusionIllustrationLeft />
          </IllustrationLeftWrap>
          <IllustrationRightWrap>
            <SOC2023ConclusionIllustrationRight />
          </IllustrationRightWrap>
        </>
      }
    >
      <ReportV2SectionHeadingLarge>Conclusion</ReportV2SectionHeadingLarge>
      <Spacing size="lg" />
      <Body>
        <p>
          The second edition of the Voice of the SOC paints a clear picture of
          the pressures facing today’s security teams. Competitive organizations
          need to move quickly to address the lack of resources in their SOC;
          otherwise, they risk significant consequences to their reputation and
          bottom line.
        </p>
        <p>
          Smart workflow automation offers a logical solution to many of the
          challenges outlined in this report. SOC teams at leading organizations
          are deploying the technology to run mission-critical workflows and
          achieve greater productivity at scale, all without having to write a
          single line of code. As a result, security professionals can focus on
          high-impact work and improve their most important performance metrics.
        </p>
        <p>
          Learn how Tines’s smart, secure workflows can make a difference in
          your SOC, or book a demo with one of our experts.
        </p>
      </Body>
      <Spacing size="lg" />
      <ButtonGroupKeepFlexOnMobile>
        <Button
          onClick={shareSOC2023AccessPage}
          color="purple"
          darker
          appearance="filled-white"
        >
          Share this report
        </Button>
        <BookADemoButton appearance="outlined" color="white" />
      </ButtonGroupKeepFlexOnMobile>
    </ReportV2Section>
  );
};
