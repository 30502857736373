import ReactMarkdown from "react-markdown";
import { DatoCmsContentCard } from "../../../graphql-types";
import ContentCardBase, {
  ContentCardSize,
  ContentCardVariant,
} from "./_ContentCardBase";
import { PrimaryColorName } from "../../styles/colors.styles";
import WithCustomInlineFormatting from "../typography/WithCustomInlineFormatting";
import { DeepNullablePartial } from "../../types/helper.types";

type Props = {
  className?: string;
  overrides: DeepNullablePartial<DatoCmsContentCard>;
  large?: boolean;
};

const ContentCardGeneric = ({ className, overrides, large }: Props) => {
  return (
    <ContentCardBase
      className={className}
      link={overrides.linkUrl ?? ""}
      large={large}
      color={(overrides.color ?? "purple") as PrimaryColorName}
      variant={
        overrides.colorMode === "dark"
          ? "800"
          : [50, 100, 500, 600, 700, 800].includes(
              parseInt(`${overrides.colorMode}`)
            )
          ? (`${overrides.colorMode}` as ContentCardVariant)
          : overrides.color === "yellow"
          ? "100"
          : "50"
      }
      backgroundImage={overrides.backgroundImage?.url}
      size={overrides.size as ContentCardSize | null}
    >
      <header>
        <div>
          {overrides.preHeadingTag && (
            <p>
              <strong>{overrides.preHeadingTag}</strong>
            </p>
          )}
          <WithCustomInlineFormatting as="h4">
            {overrides.heading}
          </WithCustomInlineFormatting>
          {overrides.description && (
            <ReactMarkdown>{overrides.description}</ReactMarkdown>
          )}
        </div>
        <p>
          <strong>{overrides.linkLabel || "Learn more"}</strong>
        </p>
      </header>
      {overrides.icon?.url && (
        <figure>
          <img
            src={overrides.icon.url}
            style={{
              aspectRatio: `${overrides.icon.width}/${overrides.icon.height}`,
            }}
          />
        </figure>
      )}
    </ContentCardBase>
  );
};

export default ContentCardGeneric;
