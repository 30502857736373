import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import Spacing from "../../layout/Spacing";
import { ReportV2SectionHeadingMedium } from "./ReportV2SectionHeadingMedium";
import { ReportV2TextBlock } from "./ReportV2TextBlock";
import { rSize } from "../../../styles/responsiveSizes.styles";

type Props = {};

const ReportChapterSummaryWrap = styled.div`
  margin-top: ${rSize("xl")};
  &:first-child {
    margin-top: 0;
  }
  p {
    max-width: 35em;
  }
`;

const ReportChapterSummary = (props: PropsWithChildren<Props>) => {
  return (
    <ReportChapterSummaryWrap>
      <ReportV2SectionHeadingMedium>Summary</ReportV2SectionHeadingMedium>
      <Spacing size="md" />
      <ReportV2TextBlock largerText>{props.children}</ReportV2TextBlock>
    </ReportChapterSummaryWrap>
  );
};

export default ReportChapterSummary;
