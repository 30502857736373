import { styled } from "@linaria/react";
import SEO from "../components/utilities/SEO";
import Page from "../templates/Page.template";
import { PageComponent } from "../types/gatsby.types";
import SiteNavHeightPlaceholder from "../components/site/SiteNavHeightPlaceholder";
import { widerPageSectionMaxWidthStyle } from "../components/layout/WiderPageSection";
import {
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  uptoTabletLg,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import GlobalSearch, {
  GlobalSearchResultsContainer,
} from "../components/search/GlobalSearch";
import { getWidthPxInMaxGrid } from "../constants/globalGrid.constants";
import { colors } from "../styles/colors.styles";
import { GlobalSearchBarContainer } from "../components/search/GlobalSearchBar";
import { GridCanvas } from "../components/decorations/GridCanvas";
import illoLeft from "../components/search/SearchPageIllustrationLeft.svg";
import illoRight from "../components/search/SearchPageIllustrationRight.svg";
import { TextInputStyled } from "../components/forms/TextInput";

const Wrap = styled.div``;

const BackdropWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40em;
  overflow: hidden;
  border-bottom: 1px solid ${colors.purple100};
  background-color: ${colors.purple50};
  ${fromTabletLg} {
    border-bottom-color: ${colors.purple300};
  }
`;

const IlloLeftWrap = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  right: calc(50% + ${getWidthPxInMaxGrid(4)});
  &:before {
    content: url(${illoLeft});
    display: block;
    transform: translate(22.5%, 5px);
  }
  ${uptoTabletLg} {
    display: none;
  }
`;

const IlloRightWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% + ${getWidthPxInMaxGrid(4)});
  z-index: 1;
  &:before {
    content: url(${illoRight});
    display: block;
    transform: translate(-56%, 5px);
  }
  ${uptoTabletLg} {
    display: none;
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 2;
  ${widerPageSectionMaxWidthStyle()};
  padding-left: ${rSize("pageMargin")};
  padding-right: ${rSize("pageMargin")};
  ${fromTablet} {
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
  }
  padding-top: 3em;
  padding-bottom: 4em;
  ${fromTabletLg} {
    padding-top: 5em;
    padding-bottom: 8em;
  }
  > * {
    max-width: ${getWidthPxInMaxGrid(8)};
    margin-left: auto;
    margin-right: auto;
  }
`;

const SearchWrap = styled.div`
  position: relative;
  background-color: ${colors.lightest};
  border: 1px solid ${colors.purple400};
  border-radius: ${rSize("radius")};
  color: ${colors.purple800};
  overflow: hidden;
  ${GlobalSearchBarContainer} {
    border-bottom: 1px solid ${colors.purple200};
    ${TextInputStyled} {
      ${fromTabletMd} {
        font-size: 1.8rem;
        font-weight: 600;
      }
      line-height: 1.5;
      height: 3.75em;
      min-height: 3.75em;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      color: ${colors.purple800};
      scroll-margin-top: var(--pageTopFixedElementsHeight);
    }
    button {
      font-size: 1.4rem;
    }
  }
  ${GlobalSearchResultsContainer} {
    padding: ${rSize("gap")};
    min-height: 25em;
    ${fromTabletLg} {
      min-height: 40em;
    }
  }
`;

const SearchPage: PageComponent = props => {
  return (
    <Page
      {...props}
      navThemeColor="purple"
      textColor={colors.purple800}
      browserThemeColor={colors.purple200}
    >
      <SEO title={`Search`} />
      <Wrap>
        <BackdropWrap>
          <GridCanvas color={colors.purple200} parallax />
          <IlloLeftWrap />
          <IlloRightWrap />
        </BackdropWrap>
        <SiteNavHeightPlaceholder />
        <Inner>
          <SearchWrap>
            <GlobalSearch
              inline
              autoFocus
              withIcon
              perPage={12}
              showSuggestionsWhenEmpty
            />
          </SearchWrap>
        </Inner>
      </Wrap>
    </Page>
  );
};

export default SearchPage;
