import { styled } from "@linaria/react";
import { font } from "../../styles/fonts.styles";
import {
  fromDesktop,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { inParagraphLinkStyle } from "../../styles/links.styles";

export const ProductExplorerPageSectionHeader = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 1em;
  align-items: end;
  &.alignCenter {
    align-items: center;
  }
  &.alignCenterOnMobile {
    ${onlyPhones} {
      align-items: center;
    }
  }
  > div {
    &:first-child {
      max-width: 38em;
    }
  }
  h2 {
    font-size: 2.4rem;
    ${fromTablet} {
      font-size: 2.8rem;
    }
    ${fromDesktop} {
      font-size: 3.2rem;
    }
    font-weight: 400;
    font-family: ${font("serif")};
    + * {
      margin-top: 1em;
    }
  }
  p {
    font-size: 1.3rem;
    ${fromTablet} {
      font-size: 1.4rem;
    }
    ${fromDesktop} {
      font-size: 1.6rem;
    }
    a {
      ${inParagraphLinkStyle("inherit")}
      font-weight: 600;
    }
  }
`;
