import { graphql, useStaticQuery } from "gatsby";
import {
  DatoCmsLibraryTool,
  ParallaxProductGridQuery,
} from "../../../graphql-types";
import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";
import { useRef } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";
import { moveItemToNewIndex } from "../../utils/array.utils";
import awsIcon from "../icons/misc/icon-aws.svg";
import thinkstIcon from "../icons/misc/icon-thinkst.svg";
import {
  fromDesktop,
  fromPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { uniqBy } from "lodash-es";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const RowSet = styled.div`
  > * {
    + * {
      margin-top: 16px;
      ${fromDesktop} {
        margin-top: 18px;
      }
    }
  }
`;

const staticAwsToolDef = {
  id: "aws",
  slug: "aws",
  name: "AWS",
  image: {
    url: awsIcon,
  },
} as DatoCmsLibraryTool;

const staticThinkstToolDef = {
  id: "thinkst-canary",
  slug: "thinkst-canary",
  name: "Thinkst",
  image: {
    url: thinkstIcon,
  },
} as DatoCmsLibraryTool;

const ParallaxProductGrid = (props: { className?: string; count?: number }) => {
  const count = props.count ?? 75;
  const data: ParallaxProductGridQuery = useStaticQuery(
    parallaxProductGridQuery
  );
  const tools = uniqBy(
    [...((data.all?.nodes ?? []) as DatoCmsLibraryTool[])],
    t => t.image?.url
  );
  const aws = tools.find(t => t.slug === "aws");
  if (!aws) {
    tools.unshift();
    tools.push(staticAwsToolDef);
  }
  const thinkst = tools.find(t => t.slug === "thinkst-canary");
  if (!thinkst) {
    tools.unshift();
    tools.push(staticThinkstToolDef);
  }
  moveItemToNewIndex(tools, aws ?? staticAwsToolDef, 27, true);
  moveItemToNewIndex(tools, thinkst ?? staticThinkstToolDef, 28, true);
  const toolRows = [[], [], [], [], []] as DatoCmsLibraryTool[][];
  tools.slice(0, count).forEach((t, i) => toolRows[i % 5].push(t));
  const itemCount = Math.min(...toolRows.map(r => r.length));
  toolRows.forEach(r => (r.length = itemCount));
  return (
    <Wrapper className={props.className}>
      <RowSet>
        {toolRows.map((row, i) => (
          <ToolRow tools={row} key={i} index={i} />
        ))}
      </RowSet>
    </Wrapper>
  );
};

const ToolRowWrap = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;
const ToolRowContents = styled.div`
  display: inline-flex;
  > * {
    + * {
      margin-left: 18px;
      ${fromPhoneLg} {
        margin-left: 20px;
      }
    }
  }
`;

const ToolRow = (props: { tools: DatoCmsLibraryTool[]; index: number }) => {
  const ref = useRef<HTMLDivElement>(null);
  const direction = props.index % 2 === 1 ? 1 : -1;
  useOnMount(() => {
    const tween = gsap.fromTo(
      ref.current,
      {
        x: direction * 15,
      },
      {
        x: direction * -15,
        scrollTrigger: {
          trigger: ref.current,
          scrub: 1,
          start: "top bottom",
          end: "bottom top",
          invalidateOnRefresh: true,
        },
      }
    );
    return () => {
      tween.kill();
    };
  });
  return (
    <ToolRowWrap>
      <ToolRowContents ref={ref}>
        {props.tools.map(t => (
          <IconWrap key={t.slug} data-icon={t.slug}>
            <img
              src={t.image?.url ?? ""}
              style={{ aspectRatio: `${t.image?.width}/${t.image?.height}` }}
            />
          </IconWrap>
        ))}
      </ToolRowContents>
    </ToolRowWrap>
  );
};

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${colors.lightest};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  ${fromDesktop} {
    width: 38px;
    height: 38px;
  }
  img {
    width: auto;
    max-width: unset;
    max-height: 56%;
  }
  + span {
    margin-left: 0.75em;
  }
`;

export const parallaxProductGridQuery = graphql`
  query ParallaxProductGrid {
    all: allDatoCmsLibraryTool(limit: 75) {
      nodes {
        id: originalId
        name
        slug
        image {
          url
          width
          height
        }
      }
    }
  }
`;

export default ParallaxProductGrid;
