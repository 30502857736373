export const BookShelfTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3V5.5V14H10V3H7ZM6 2.5V5H2.5C2.22386 5 2 5.22386 2 5.5V14.5C2 14.7761 2.22386 15 2.5 15H6.5H10.5C10.7761 15 11 14.7761 11 14.5V8.25025L12.7498 14.7804C12.8212 15.0472 13.0954 15.2054 13.3621 15.134L16.1812 14.3786C16.448 14.3071 16.6062 14.033 16.5348 13.7662L14.0092 4.34072C13.9377 4.07398 13.6636 3.91569 13.3968 3.98716L11 4.6294V2.5C11 2.22386 10.7761 2 10.5 2H6.5C6.22386 2 6 2.22386 6 2.5ZM3 6H6V14H3V6ZM13.5863 14.0386L11.3195 5.57906L13.1727 5.0825L15.4394 13.5421L13.5863 14.0386Z"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2961 10.3184C12.0294 10.3899 11.8711 10.664 11.9426 10.9308L12.2513 12.0828C12.3227 12.3495 12.5969 12.5078 12.8636 12.4363L15.2088 11.808C15.4755 11.7365 15.6338 11.4623 15.5623 11.1956L15.2536 10.0436C15.1822 9.77683 14.908 9.61854 14.6413 9.69001L12.2961 10.3184ZM6 10.5C6 10.2239 6.22386 10 6.5 10H10.5C10.7761 10 11 10.2239 11 10.5V11.5C11 11.7762 10.7761 12 10.5 12H7V12.5C7 12.7762 6.77614 13 6.5 13H2.5C2.22386 13 2 12.7762 2 12.5V11.5C2 11.2239 2.22386 11 2.5 11H6V10.5Z"
    />
  </svg>
);
