import dayjs from "dayjs";
import { DatoCmsWebinar } from "../../graphql-types";
import { last } from "lodash-es";

export const findNextUpcomingBootcamp = (webinars: DatoCmsWebinar[]) => {
  const webinarsSortedEarliestToLatest = [...webinars].sort((a, b) =>
    dayjs(`${a.timeStart}`).diff(`${b.timeStart}`)
  );
  const nextUpcomingBootcamp =
    webinarsSortedEarliestToLatest.find(b =>
      dayjs(`${b.timeStart}`).isAfter(dayjs())
    ) ?? last(webinarsSortedEarliestToLatest);
  if (nextUpcomingBootcamp) return nextUpcomingBootcamp;
};
