import { styled } from "@linaria/react";

type Props = {
  size?: number;
};

export const TinesLogoIconWrap = styled.svg``;

const TinesLogoIcon = ({ size }: Props) => {
  return (
    <TinesLogoIconWrap
      className="TinesLogoIcon"
      width={size ?? 48}
      height={size ?? 48}
      viewBox="0 0 48 48"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6641 0C11.9485 0 6.89478 3.68623 5.18424 9.10302L0.47998 24L5.18424 38.897C6.89479 44.3138 11.9485 48 17.6641 48H30.3358C36.0515 48 41.1052 44.3138 42.8157 38.897L47.52 24L42.8157 9.10302C41.1052 3.68623 36.0515 0 30.3358 0H17.6641ZM30.336 3.74451C34.4042 3.74451 38.0012 6.36822 39.2187 10.2237L40.372 13.8759C38.0518 11.9723 35.1137 10.9008 32.0429 10.9006L15.9228 10.9002C12.8726 10.9004 9.9534 11.9575 7.64056 13.8372L8.78167 10.2237C9.99917 6.36822 13.5962 3.74451 17.6643 3.74451H30.336ZM8.51076 18.2979L5.58159 22.1278L42.3838 22.1278L39.4549 18.2983C37.6948 15.9969 34.9524 14.6453 32.0426 14.6451H15.835C12.958 14.6723 10.2531 16.0197 8.51076 18.2979ZM42.3778 25.8723L39.4547 29.6983C37.6944 32.0023 34.9501 33.3555 32.0381 33.3553L15.9271 33.3548C13.0152 33.3551 10.2708 32.0019 8.51056 29.6979L5.58776 25.8723L42.3778 25.8723ZM8.78145 37.7763C9.99894 41.6318 13.5959 44.2555 17.6641 44.2555H30.3358C34.404 44.2555 38.001 41.6318 39.2185 37.7763L40.3726 34.1214C38.0514 36.0273 35.111 37.1 32.0378 37.0998L15.9274 37.0994C12.8747 37.0996 9.9531 36.0412 7.63915 34.159L8.78145 37.7763Z"
      />
    </TinesLogoIconWrap>
  );
};

export default TinesLogoIcon;
