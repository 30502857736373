const popularPublicProviders = [
  "126.com",
  "163.com",
  "protonmail.",
  "hotmail.",
  "outlook.",
  "icloud.com",
  "gmail.com",
  "yahoo.",
  "aol.com",
  "mail.com",
  "yandex.",
  "yandex.",
  "posteo.",
];

export const isPublicEmailProvider = (email: string) =>
  !!popularPublicProviders.find(e => email.includes(`@${e}`));
