import {
  PrimaryColorName,
  SecondaryColorName,
  colors,
} from "../../../styles/colors.styles";
import { SiteNavThemeControllerPoint } from "../../site/SiteNavThemeController";

export const ReportV2NavPurpleControlPoint = () => (
  <SiteNavThemeControllerPoint
    navThemeColor="purple"
    navLogoColor={colors.dark500}
  />
);
export const ReportV2NavGreenControlPoint = () => (
  <SiteNavThemeControllerPoint
    navThemeColor="green"
    navLogoColor={colors.dark500}
  />
);
export const ReportV2NavPinkControlPoint = () => (
  <SiteNavThemeControllerPoint
    navThemeColor="pink"
    navLogoColor={colors.dark500}
  />
);
export const ReportV2NavOrangeControlPoint = () => (
  <SiteNavThemeControllerPoint
    navThemeColor="orange"
    navLogoColor={colors.dark500}
  />
);

export const ReportV2HalfDarkSectionNavControlPoint = () => (
  <SiteNavThemeControllerPoint
    navThemeColor="purple"
    colorNavAsDarkTheme
    navLogoColor={colors.dark500}
  />
);
export const ReportV2FullDarkSectionNavControlPoint = (props: {
  color?: PrimaryColorName | SecondaryColorName | "neutral";
}) => (
  <SiteNavThemeControllerPoint
    navThemeColor={props.color}
    colorNavAsDarkTheme
  />
);
