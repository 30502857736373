import { styled } from "@linaria/react";
import { cx } from "linaria";

import { useSiteContext } from "../../context/site.context";
import {
  fromTablet,
  uptoPhoneLg,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { zIndex } from "../../styles/zIndexes.styles";
import { widerPageSectionMaxWidthStyle } from "../layout/WiderPageSection";
import { useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { font } from "../../styles/fonts.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { withOpacity } from "../../styles/colorsV4.styles";
import { AdminToolbarButton } from "../site/AdminToolbar";
import { renderInPortal } from "../../utils/portals.utils";
import { useHotkeys } from "react-hotkeys-hook";

const GridOuterWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex("GlobalGrid")};
  pointer-events: none;
`;

const WiderBoundary = styled.div`
  ${widerPageSectionMaxWidthStyle()}
  height: 100%;
`;

const InnerWrap = styled.div`
  display: grid;
  height: 100%;
  ${maxPageContentWidthStyleObject};
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-left: 1px dashed ${colors.pink};
    border-right: 1px dashed ${colors.pink};
  }
  [data-has-sidebar="true"] & {
    width: 100%;
    max-width: 100%;
    padding-left: ${rSize("widerPageMargin")};
    padding-right: ${rSize("widerPageMargin")};
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    grid-gap: ${rSize("layoutWithSidebarGap")};
    ${fromTablet} {
      grid-template-columns: ${rSize("sidebarWidth")} minmax(0, 1fr);
    }
  }
`;

const SidebarSpace = styled.div`
  position: relative;
  border-left: 1px dashed ${colors.green};
  border-right: 1px dashed ${colors.green};
  display: none;
  [data-has-sidebar="true"] & {
    display: block;
  }
`;

const ContentSpace = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
`;

const SizeMarker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colors.orange600};
  color: ${colors.white};
  font-size: 1rem;
  font-family: ${font("monospace")};
  font-weight: 700;
  padding: 0.25em 0.5em;
  &.left {
    left: 0;
    right: auto;
    background-color: ${colors.green600};
  }
`;

const ContentSpaceGrid = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${rSize("gap")};
  grid-auto-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  span {
    box-sizing: border-box;
    border-left: 1px solid #2ce7e733;
    border-right: 1px solid #2ce7e733;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%232ce7e7' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    &:nth-child(even) {
      border-left-color: #f2c33533;
      border-right-color: #f2c33533;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f2c335' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      ${uptoPhoneLg} {
        display: none;
      }
    }
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12) {
      ${uptoTabletLg} {
        display: none;
      }
    }
  }
  code {
    display: block;
    color: ${colors.white};
    font-size: 12px;
    font-weight: 700;
    padding: 0.3em 0.5em;
    background-color: ${withOpacity(colors.purple700, 0.8)};
  }
`;

const GlobalGridOverlayAndControl = () => {
  const siteContext = useSiteContext();
  const sidebarSpaceRef = useRef<HTMLDivElement>(null);
  const contentSpaceRef = useRef<HTMLDivElement>(null);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [gridWidth, setGridWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const showGlobalGridRef = useRef(siteContext.showGlobalGrid);
  showGlobalGridRef.current = siteContext.showGlobalGrid;

  const toggleGrid = () => {
    setScrollY(window.scrollY);
    siteContext.toggleGlobalGrid();
  };

  useHotkeys("shift+g", toggleGrid);
  useHotkeys("control+g", toggleGrid);

  useOnMount(() => {
    const handleResize = () => {
      setSidebarWidth(sidebarSpaceRef.current?.offsetWidth ?? 0);
      setGridWidth(contentSpaceRef.current?.offsetWidth ?? 0);
      setPageHeight(contentSpaceRef.current?.clientHeight ?? 0);
    };
    setTimeout(handleResize);
    const handleScroll = () => {
      if (!showGlobalGridRef.current) return;
      setScrollY(window.scrollY);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <>
      {siteContext.showGlobalGrid &&
        renderInPortal(
          <>
            <GridOuterWrap>
              <WiderBoundary>
                <InnerWrap>
                  <SidebarSpace ref={sidebarSpaceRef}>
                    <SizeMarker>
                      {sidebarWidth}px * {pageHeight}px
                    </SizeMarker>
                  </SidebarSpace>
                  <ContentSpace ref={contentSpaceRef}>
                    <ContentSpaceGrid>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
                        <span key={i}>
                          <code>{i}</code>
                        </span>
                      ))}
                    </ContentSpaceGrid>
                    <SizeMarker>
                      {gridWidth}px * {pageHeight}px
                    </SizeMarker>
                  </ContentSpace>
                </InnerWrap>
              </WiderBoundary>
            </GridOuterWrap>
            <SizeMarker className="left">Scroll: {scrollY}px</SizeMarker>
          </>
        )}
      <AdminToolbarButton
        className={cx(siteContext.showGlobalGrid && "active")}
        onClick={toggleGrid}
        title="Toggle Global Grid Overlay"
      >
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          stroke="currentColor"
        >
          <path d="M0 3.5H11M0 7.5H11M3.5 0V11M7.5 0V11" />
        </svg>
      </AdminToolbarButton>
    </>
  );
};

export default GlobalGridOverlayAndControl;
