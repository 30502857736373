import { styled } from "@linaria/react";
import {
  fromTablet,
  fromTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { CloudsIllustration } from "../../illustrations/neue/CloudsIllustration";

const Outer = styled.div`
  margin-top: -3.5em;
  ${fromTabletLg} {
    margin-top: -8.5em;
    margin-bottom: -8.5em;
  }
`;

const Wrap = styled.div`
  position: relative;
  height: 0;
  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    aspect-ratio: 843 / 415;
    max-width: 92%;
    ${fromTablet} {
      max-width: 80%;
    }
  }
`;

const Sizer = styled.div`
  aspect-ratio: 843 / 415;
`;

export const CTADecorationClouds = () => {
  return (
    <Outer>
      <Wrap>
        <CloudsIllustration />
      </Wrap>
      <Sizer />
    </Outer>
  );
};
