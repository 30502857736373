export const HoneycombIcon = () => (
  <svg
    width="68"
    height="96"
    viewBox="0 0 68 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.5716 16.0327L39.3316 1.46005C37.8074 0.580049 35.3132 0.580049 33.789 1.46005L8.5488 16.0327C8.1684 16.2525 7.8054 16.5517 7.4748 16.9045C6.7128 17.7179 2.3576 22.1645 1.8906 22.8475C1.2284 23.8161 0.799605 24.9857 0.799805 26.0179V55.1627C0.799805 56.9227 2.0468 59.0829 3.571 59.9627L28.8112 74.5364C30.3352 75.4162 32.8296 75.4164 34.3536 74.5364L59.5938 59.9627C59.8736 59.8009 60.1442 59.5962 60.3992 59.3586C61.2896 58.5294 65.933 53.6782 66.4248 52.8812C66.9876 51.9694 67.3428 50.9165 67.3428 49.9773V20.8327C67.343 19.0727 66.0958 16.9129 64.5716 16.0327Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
    />
    <path
      d="M8.44141 27.2298L31.5828 13.8688L54.7244 27.2298V53.9508L31.5828 67.3128L8.44141 53.9508V27.2298Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3833 20.7404L14.8271 18.1748L19.2711 20.7404V25.872L14.8271 28.4378L10.3833 25.872V20.7404Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M19.271 20.7404L23.7148 18.1748L28.159 20.7404V25.872L23.7148 28.4378L19.271 25.872V20.7404Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M28.1592 20.7404L32.6032 18.1748L37.047 20.7404V25.872L32.6032 28.4378L28.1592 25.872V20.7404Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M37.0469 20.7404L41.4909 18.1748L45.9349 20.7404V25.872L41.4909 28.4378L37.0469 25.872V20.7404Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M45.9346 20.7404L50.3786 18.1748L54.8224 20.7404V25.872L50.3786 28.4378L45.9346 25.872V20.7404Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M23.7153 13.1559L28.1591 10.5901L32.6031 13.1559V18.2873L28.1591 20.8529L23.7153 18.2873V13.1559Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M32.603 13.1559L37.047 10.5901L41.4908 13.1559V18.2873L37.047 20.8529L32.603 18.2873V13.1559Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M14.8271 59.2787L19.2709 56.7129L23.7152 59.2787V64.4101L19.2709 66.9757L14.8271 64.4101V59.2787Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M23.7153 59.2787L28.1591 56.7129L32.6031 59.2787V64.4101L28.1591 66.9757L23.7153 64.4101V59.2787Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M32.603 59.2787L37.047 56.7129L41.4908 59.2787V64.4101L37.047 66.9757L32.603 64.4101V59.2787Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M41.4912 59.2787L45.9354 56.7129L50.3792 59.2787V64.4101L45.9354 66.9757L41.4912 64.4101V59.2787Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M10.3833 36.1677L14.8271 33.6021L19.2711 36.1677V41.2992L14.8271 43.8651L10.3833 41.2992V36.1677Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M19.271 36.1677L23.7148 33.6021L28.159 36.1677V41.2992L23.7148 43.8651L19.271 41.2992V36.1677Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M28.1592 36.1677L32.6032 33.6021L37.047 36.1677V41.2992L32.6032 43.8651L28.1592 41.2992V36.1677Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M37.0469 36.1677L41.4909 33.6021L45.9349 36.1677V41.2992L41.4909 43.8651L37.0469 41.2992V36.1677Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M45.9346 36.1677L50.3786 33.6021L54.8224 36.1677V41.2992L50.3786 43.8651L45.9346 41.2992V36.1677Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M10.3833 51.5556L14.8273 48.99L19.2711 51.5556V56.6872L14.8273 59.253L10.3833 56.6872V51.5556Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M19.271 51.5556L23.7152 48.99L28.159 51.5556V56.6872L23.7152 59.253L19.271 56.6872V51.5556Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M28.1592 51.5556L32.6032 48.99L37.0472 51.5556V56.6872L32.6032 59.253L28.1592 56.6872V51.5556Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M37.0469 51.5556L41.4909 48.99L45.9349 51.5556V56.6872L41.4909 59.253L37.0469 56.6872V51.5556Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M45.9351 51.5556L50.3789 48.99L54.8229 51.5556V56.6872L50.3789 59.253L45.9351 56.6872V51.5556Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M5.93896 28.474L10.3832 25.9082L14.8271 28.474V33.6052L10.3832 36.1714L5.93896 33.6052V28.474Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M14.8271 28.474L19.2709 25.9082L23.7152 28.474V33.6052L19.2709 36.1714L14.8271 33.6052V28.474Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M23.7153 28.474L28.1591 25.9082L32.6031 28.474V33.6052L28.1591 36.1714L23.7153 33.6052V28.474Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M32.603 28.474L37.047 25.9082L41.4908 28.474V33.6052L37.047 36.1714L32.603 33.6052V28.474Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M41.4907 28.474L45.9349 25.9082L50.3787 28.474V33.6052L45.9349 36.1714L41.4907 33.6052V28.474Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M50.3789 28.474L54.8227 25.9082L59.2669 28.474V33.6052L54.8227 36.1714L50.3789 33.6052V28.474Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M5.93896 43.7381L10.3832 41.1725L14.8271 43.7381V48.8695L10.3832 51.4351L5.93896 48.8695V43.7381Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M14.8271 43.7381L19.2709 41.1725L23.7152 43.7381V48.8695L19.2709 51.4351L14.8271 48.8695V43.7381Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M23.7153 43.7381L28.1591 41.1725L32.6031 43.7381V48.8695L28.1591 51.4351L23.7153 48.8695V43.7381Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M32.603 43.7381L37.047 41.1725L41.4908 43.7381V48.8695L37.047 51.4351L32.603 48.8695V43.7381Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M41.4907 43.7381L45.9349 41.1725L50.3787 43.7381V48.8695L45.9349 51.4351L41.4907 48.8695V43.7381Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M50.3789 43.7381L54.8227 41.1725L59.2669 43.7381V48.8695L54.8227 51.4351L50.3789 48.8695V43.7381Z"
      fill="#733D26"
      stroke="#E49307"
      strokeMiterlimit="10"
    />
    <path
      d="M37.1212 61.7155C35.5972 62.5953 33.103 62.5953 31.5788 61.7155L13.9798 51.5537C12.4556 50.6739 11.2086 48.5137 11.2086 46.7537V28.8319C11.2086 27.0719 10.586 25.9913 9.82502 26.4307C9.06402 26.8701 8.44141 28.6695 8.44141 30.4295V50.7505C8.44141 52.5105 9.68842 54.6705 11.2126 55.5505L28.8116 65.7123C30.3356 66.5923 32.83 66.5923 34.354 65.7123L51.953 55.5505C53.4772 54.6705 54.7242 53.4107 54.7242 52.7509C54.7242 52.0911 53.4772 52.2715 51.953 53.1513L37.1212 61.7155Z"
      fill="#C86735"
    />
    <path
      d="M37.1212 61.7155C35.5972 62.5953 33.103 62.5953 31.5788 61.7155L13.9798 51.5537C12.4556 50.6739 11.2086 48.5137 11.2086 46.7537V28.8319C11.2086 27.0719 10.586 25.9913 9.82502 26.4307C9.06402 26.8701 8.44141 28.6695 8.44141 30.4295V50.7505C8.44141 52.5105 9.68842 54.6705 11.2126 55.5505L28.8116 65.7123C30.3356 66.5923 32.83 66.5923 34.354 65.7123L51.953 55.5505C53.4772 54.6705 54.7242 53.4107 54.7242 52.7509C54.7242 52.0911 53.4772 52.2715 51.953 53.1513L37.1212 61.7155Z"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.38135 35.619L11.2699 33.6934V25.6318L8.44114 27.2296L8.38135 35.619Z"
      fill="#733D26"
    />
    <path
      d="M34.3538 6.64511C32.8296 5.76511 30.3352 5.76511 28.8112 6.64511L3.571 21.2177C2.0468 22.0977 0.799805 24.2577 0.799805 26.0177V55.1625C0.799805 56.9225 2.0468 59.0827 3.571 59.9625L28.8112 74.5363C30.3352 75.4161 32.8296 75.4163 34.3536 74.5363L59.5938 59.9625C61.118 59.0825 62.365 56.9225 62.365 55.1625V26.0177C62.365 24.2577 61.118 22.0977 59.5938 21.2177L34.3538 6.64511ZM54.724 50.7507C54.724 52.5107 53.477 54.6707 51.9528 55.5507L34.3538 65.7125C32.8298 66.5925 30.3354 66.5925 28.8114 65.7125L11.2124 55.5507C9.6882 54.6707 8.44121 52.5107 8.44121 50.7507V30.4297C8.44121 28.6697 9.6882 26.5097 11.2124 25.6297L28.8114 15.4687C30.3354 14.5887 32.8298 14.5887 34.354 15.4687L51.9528 25.6297C53.477 26.5097 54.724 28.6697 54.724 30.4297V50.7507Z"
      fill="#F0A848"
      stroke="#733D26"
      strokeMiterlimit="10"
    />
    <path
      d="M31.5845 5.98525L36.5579 0.800049"
      stroke="#733D26"
      strokeMiterlimit="10"
    />
    <path
      d="M31.0952 66.2681L34.8044 62.4011"
      stroke="#733D26"
      strokeMiterlimit="10"
    />
    <path
      d="M61.5273 23.2482L66.703 18.4236"
      stroke="#733D26"
      strokeMiterlimit="10"
    />
    <path
      d="M9.61865 54.0552L13.0175 50.7976"
      stroke="#733D26"
      strokeMiterlimit="10"
    />
    <path
      d="M34.2611 95.1468C47.6628 95.1468 58.5271 93.4754 58.5271 91.4136C58.5271 89.3518 47.6628 87.6804 34.2611 87.6804C20.8594 87.6804 9.99512 89.3518 9.99512 91.4136C9.99512 93.4754 20.8594 95.1468 34.2611 95.1468Z"
      fill="#10352E"
    />
    <path
      d="M56.9095 40.9011C56.9095 40.9011 59.6879 43.2627 58.8545 44.9989C58.0209 46.7355 54.8956 44.3739 54.8956 44.3739L54.5483 40.9705L56.9095 40.9011Z"
      fill="#733D26"
    />
    <path
      d="M54.75 38.3302C55.4085 38.7403 56.5785 38.0514 57.3633 36.7914C58.1481 35.5314 58.2506 34.1775 57.5921 33.7674C56.9337 33.3573 55.7637 34.0462 54.9789 35.3062C54.194 36.5662 54.0916 37.9201 54.75 38.3302Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.7131 37.8524C55.6665 37.5068 54.6431 37.45 53.8525 37.6346C53.5593 36.9396 52.8719 36.4524 52.0703 36.4524C51.0025 36.4524 50.1367 37.3182 50.1367 38.3862C50.1367 39.4542 51.0025 40.32 52.0703 40.32C52.3245 40.32 52.5667 40.2698 52.7889 40.1804C53.2895 40.877 54.2153 41.5262 55.3743 41.909C57.3625 42.5652 59.2743 42.1892 59.6441 41.0692C60.0139 39.9492 58.7017 38.5086 56.7131 37.8524Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.9689 41.6728C54.7017 41.5914 54.7475 40.6618 55.0707 39.5958C55.3945 38.53 55.8731 37.7318 56.1403 37.8128"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.6025 42.0348C56.6025 42.0348 56.6407 39.1918 58.1099 38.4668"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.7131 37.8524C55.6665 37.5068 54.6431 37.45 53.8525 37.6346C53.5593 36.9396 52.8719 36.4524 52.0703 36.4524C51.0025 36.4524 50.1367 37.3182 50.1367 38.3862C50.1367 39.4542 51.0025 40.32 52.0703 40.32C52.3245 40.32 52.5667 40.2698 52.7889 40.1804C53.2895 40.877 54.2153 41.5262 55.3743 41.909C57.3625 42.5652 59.2743 42.1892 59.6441 41.0692C60.0139 39.9492 58.7017 38.5086 56.7131 37.8524Z"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.2195 35.8303C55.7503 37.2387 53.9743 38.2013 53.9743 38.2013C53.9743 38.2013 53.0851 36.3507 53.5541 34.9425C54.0233 33.5339 54.9371 32.8561 55.6733 33.1015C56.4091 33.3469 56.6885 34.4221 56.2195 35.8303Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.9289 23.1449C38.3721 24.0907 37.7051 26.4773 37.7051 26.4773C37.7051 26.4773 40.1613 27.0361 41.7177 26.0909C43.2745 25.1453 43.8359 23.8675 43.3417 23.0535C42.8477 22.2405 41.4855 22.1995 39.9289 23.1449Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.2906 24.6042C34.5656 23.4858 35.6952 22.8016 36.8138 23.0766C37.8238 23.3248 38.4764 24.2706 38.3922 25.2746C39.0164 25.5212 39.6034 26.0334 39.992 26.747C40.737 28.1154 41.1598 30.992 40.0888 31.575C39.0176 32.158 36.8578 30.2272 36.1128 28.859C35.7962 28.2768 35.6634 27.6568 35.6962 27.0906C34.651 26.7656 34.0262 25.6808 34.2906 24.6042Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.4527 28.7645C40.4527 28.7645 38.3633 28.2921 37.7661 30.5553"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.6056 26.2281C39.6056 26.2281 37.2092 26.0069 36.0654 28.6665"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.2906 24.6042C34.5656 23.4858 35.6952 22.8016 36.8138 23.0766C37.8238 23.3248 38.4764 24.2706 38.3922 25.2746C39.0164 25.5212 39.6034 26.0334 39.992 26.747C40.737 28.1154 41.1598 30.992 40.0888 31.575C39.0176 32.158 36.8578 30.2272 36.1128 28.859C35.7962 28.2768 35.6634 27.6568 35.6962 27.0906C34.651 26.7656 34.0262 25.6808 34.2906 24.6042Z"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M33.3969 27.5969C35.2031 27.8335 37.2169 26.3891 37.2169 26.3891C37.2169 26.3891 35.6505 24.4163 33.8453 24.1797C32.0395 23.9425 30.8017 24.5883 30.6779 25.5325C30.5543 26.4757 31.5915 27.3601 33.3969 27.5969Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.99406 58.1398C9.50626 59.2754 9.95265 61.8088 9.95265 61.8088C9.95265 61.8088 7.35824 62.1364 5.84664 61.0018C4.33444 59.8668 3.88445 58.4894 4.47765 57.6992C5.07065 56.9094 6.48206 57.0054 7.99406 58.1398Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.6695 60.2209C13.4993 59.0375 12.4017 58.2161 11.2183 58.3865C10.1497 58.5403 9.37953 59.4505 9.36433 60.4965C8.69433 60.6879 8.03591 61.1573 7.56191 61.8551C6.65331 63.1929 5.92412 66.1213 6.97112 66.8329C8.01852 67.5441 10.4457 65.7689 11.3545 64.4311C11.7407 63.8619 11.9407 63.2351 11.9647 62.6465C13.0777 62.4171 13.8333 61.3599 13.6695 60.2209Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M6.88135 63.8922C6.88135 63.8922 9.08793 63.6166 9.47473 66.0152"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.01416 61.3582C8.01416 61.3582 10.5122 61.373 11.4236 64.237"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.6695 60.2209C13.4993 59.0375 12.4017 58.2161 11.2183 58.3865C10.1497 58.5403 9.37953 59.4505 9.36433 60.4965C8.69433 60.6879 8.03591 61.1573 7.56191 61.8551C6.65331 63.1929 5.92412 66.1213 6.97112 66.8329C8.01852 67.5441 10.4457 65.7689 11.3545 64.4311C11.7407 63.8619 11.9407 63.2351 11.9647 62.6465C13.0777 62.4171 13.8333 61.3599 13.6695 60.2209Z"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.2888 63.4029C12.3992 63.4639 10.4658 61.7669 10.4658 61.7669C10.4658 61.7669 12.2846 59.8881 14.1736 59.8273C16.063 59.7661 17.276 60.5589 17.3078 61.5469C17.3396 62.5339 16.1782 63.3421 14.2888 63.4029Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.9923 73.4424C54.016 73.4424 54.8459 72.6125 54.8459 71.5888C54.8459 70.5651 54.016 69.7352 52.9923 69.7352C51.9686 69.7352 51.1387 70.5651 51.1387 71.5888C51.1387 72.6125 51.9686 73.4424 52.9923 73.4424Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.7146 72.9951C57.424 74.0809 57.659 76.3633 56.5734 77.0729C55.4878 77.7815 53.4926 76.6487 52.7832 75.5631C52.0742 74.4771 52.3796 73.0221 53.4654 72.3131C54.5508 71.6041 56.0058 71.9091 56.7146 72.9951Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.6508 69.9387C56.5078 71.6601 53.7718 72.3067 53.7718 72.3067C53.7718 72.3067 53.251 69.4966 54.3934 67.7762C55.5362 66.0552 57.0108 65.4773 57.9104 66.0745C58.8096 66.6721 58.7936 68.2179 57.6508 69.9387Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.4264 75.3104C51.4622 74.9558 53.1542 72.7102 53.1542 72.7102C53.1542 72.7102 50.7906 71.104 48.7558 71.4586C46.7206 71.8128 45.5988 72.9308 45.784 73.9946C45.9696 75.0578 47.3914 75.6648 49.4264 75.3104Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.2007 76.8532C54.1437 76.093 54.5383 75.449 55.2217 75.0032C56.0033 74.4928 56.7397 74.3922 57.4461 74.9208"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.4233 74.8036C52.6035 73.9414 53.0617 73.4336 53.8577 72.9146C54.6221 72.4154 55.3187 72.1984 56.1515 72.348"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.7146 72.9951C57.424 74.0809 57.659 76.3633 56.5734 77.0729C55.4878 77.7815 53.4926 76.6487 52.7832 75.5631C52.0742 74.4771 52.3796 73.0221 53.4654 72.3131C54.5508 71.6041 56.0058 71.9091 56.7146 72.9951Z"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.8499 45.9294C20.8535 45.9294 21.6671 45.1159 21.6671 44.1122C21.6671 43.1086 20.8535 42.295 19.8499 42.295C18.8463 42.295 18.0327 43.1086 18.0327 44.1122C18.0327 45.1159 18.8463 45.9294 19.8499 45.9294Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.1904 47.775C20.839 48.9968 19.2958 50.6336 18.0744 50.282C16.853 49.9304 16.4155 47.7238 16.7669 46.5022C17.1185 45.2806 18.3938 44.5752 19.6152 44.9268C20.8366 45.2784 21.542 46.5534 21.1904 47.775Z"
      fill="#C86735"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M24.3331 44.7008C22.6533 45.833 19.9321 45.3958 19.9321 45.3958C19.9321 45.3958 20.5115 42.6546 22.1907 41.523C23.8697 40.391 25.4217 40.4188 26.0135 41.2964C26.6047 42.1744 26.0121 43.569 24.3331 44.7008Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.6099 41.8751C16.5075 43.6911 19.0878 44.6601 19.0878 44.6601C19.0878 44.6601 19.9431 41.9923 19.0459 40.1773C18.1487 38.3617 16.7869 37.6167 15.8379 38.0855C14.8891 38.5549 14.7127 40.0601 15.6099 41.8751Z"
      fill="#FFC8A3"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M16.7998 48.3903C17.3272 47.8615 17.976 47.7323 18.7448 47.9533C19.624 48.2067 20.191 48.6979 20.2514 49.5603"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.1797 45.7871C17.9345 45.3679 18.5253 45.3457 19.4205 45.6033C20.2807 45.8507 20.8523 46.1555 21.2747 46.8693"
      stroke="#F0A848"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.1904 47.775C20.839 48.9968 19.2958 50.6336 18.0744 50.282C16.853 49.9304 16.4155 47.7238 16.7669 46.5022C17.1185 45.2806 18.3938 44.5752 19.6152 44.9268C20.8366 45.2784 21.542 46.5534 21.1904 47.775Z"
      stroke="#733D26"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.8939 63.5232C13.8939 63.5232 14.1529 65.1416 13.8939 65.7244L11.6279 64.3648L12.0811 63.1348L13.8939 63.5232Z"
      fill="#733D26"
    />
  </svg>
);
