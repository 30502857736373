import { styled } from "@linaria/react";
import { css } from "linaria";
import { ACTION_TYPES } from "../../../constants/actionTypes.constants";
import { widthInGrid } from "../../../constants/globalGrid.constants";
import {
  fromDesktopMd,
  fromTablet,
  uptoDesktopMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import ActionIconCube from "../../decorations/ActionIconCube";
import { RigidDotGridLayer } from "../../decorations/RigidDotGrid";
import HorizontalActionLink, {
  HorizontalActionLinkWrap,
} from "./HorizontalActionLink";
import {
  CardAGraphics,
  CardBGraphics,
  CardCGraphics,
} from "./MockCardGraphics";
import PagePreviewCardStylized from "./PagePreviewCardStylized";
import VerticalActionLink from "./VerticalActionLink";
import mobileImg from "../../../../static/images/build-apps/attack-defense-illustration-mobile.png";
import mobileImg2x from "../../../../static/images/build-apps/attack-defense-illustration-mobile@2x.png";
import { makeSrcSet2x } from "../../../utils/srcset.utils";

const cardWidth = `minmax(20rem, ${widthInGrid(3)})`;

const PagesAndActionsIllustrationWrap = styled.div``;

const MobileVersion = styled.div`
  display: none;
  ${fromTablet} {
    display: block;
  }
  ${fromDesktopMd} {
    display: none;
  }
  img {
    margin-left: -${(35 / 945) * 100}%;
    margin-right: -${(35 / 945) * 100}%;
    width: ${((35 * 2) / 945) * 100 + 100}%;
  }
`;

const AttackThroughStoryGrid = styled.div`
  display: grid;
  grid-template-columns: ${cardWidth} 1fr ${cardWidth} 1fr ${cardWidth};
  grid-template-areas: "cardA actionA cardB actionB cardC" ". . actionC . .";
  grid-template-rows: auto 16rem;
  ${uptoDesktopMd} {
    display: none;
  }
  ${RigidDotGridLayer} {
    @keyframes RigidDotGridLayerAnimation {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(18px);
      }
    }
    animation: RigidDotGridLayerAnimation 3s infinite linear;
  }
`;

const ActionGridCellHorizontal = styled.div`
  display: flex;
  align-items: center;
`;

const ActionGridCellVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    &:first-child {
      flex: 1 1 33%;
    }
    &:last-child {
      flex: 2 2 66%;
    }
  }
`;

const FirstCardGridCell = styled.div`
  position: relative;
  ${HorizontalActionLinkWrap} {
    position: absolute;
    top: calc(50% - 6.5px);
    right: 100%;
    width: ${rSize("pageMargin", 0.75)};
  }
`;

const MiddleCardGridCell = styled.div`
  position: relative;
`;

const PagesAndActionsIllustration = () => {
  return (
    <PagesAndActionsIllustrationWrap>
      <MobileVersion>
        <img src={mobileImg} srcSet={makeSrcSet2x(mobileImg, mobileImg2x)} />
      </MobileVersion>
      <AttackThroughStoryGrid>
        <FirstCardGridCell>
          <HorizontalActionLink
            inputColor={withOpacity(colorsV4.purple, 0)}
            outputColor={colorsV4.purple}
            outputOffset="-1.8rem"
          />
          <PagePreviewCardStylized
            id="pagePreviewCardA"
            color={colorsV4.purple}
          >
            <CardAGraphics />
          </PagePreviewCardStylized>
        </FirstCardGridCell>

        <ActionGridCellHorizontal>
          <HorizontalActionLink
            inputColor={colorsV4.purple}
            outputColor={ACTION_TYPES.httpRequest.color}
            inputOffset="-1.8rem"
          />
          <ActionIconCube type="httpRequest" />
          <HorizontalActionLink
            inputColor={ACTION_TYPES.httpRequest.color}
            outputColor={colorsV4.pink}
            outputOffset="-1.8rem"
          />
        </ActionGridCellHorizontal>

        <MiddleCardGridCell>
          <PagePreviewCardStylized id="pagePreviewCardB" color={colorsV4.pink}>
            <CardBGraphics />
          </PagePreviewCardStylized>
        </MiddleCardGridCell>

        <ActionGridCellHorizontal>
          <HorizontalActionLink
            inputColor={colorsV4.pink}
            outputColor={ACTION_TYPES.trigger.color}
            inputOffset="-1.8rem"
          />
          <ActionIconCube type="trigger" />
          <HorizontalActionLink
            inputColor={ACTION_TYPES.trigger.color}
            outputColor={colorsV4.green}
            outputOffset="-1.8rem"
          />
        </ActionGridCellHorizontal>

        <PagePreviewCardStylized id="pagePreviewCardC" color={colorsV4.green}>
          <CardCGraphics />
        </PagePreviewCardStylized>

        <ActionGridCellVertical
          className={css`
            grid-area: actionC;
          `}
        >
          <VerticalActionLink
            inputColor="#EEA7C6"
            outputColor={colorsV4.purple}
            inputOffset="-1.8rem"
          />
          <ActionIconCube type="sendToStory" />
          <VerticalActionLink
            inputColor={colorsV4.purple}
            outputColor={withOpacity(colorsV4.orange300, 0)}
            largerOutputTerminal
            outputOffset={rSize("cardSectionPadding", -1)}
          />
        </ActionGridCellVertical>
      </AttackThroughStoryGrid>
    </PagesAndActionsIllustrationWrap>
  );
};

export default PagesAndActionsIllustration;
