import { CSSProperties, Fragment, useRef, useState } from "react";
import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { getOffsetRelativeToPagePrecise } from "../../utils/measurements.utils";
import { createRoundedPathD } from "../../utils/svgRoundCorners";
import { useOnResize } from "../../utils/useOnResize";
import { isBuildTime } from "../../environment";
import {
  breakpoints,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { createPortal } from "react-dom";
import { observeDocumentSize } from "../../utils/document.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";

const debug = false;

const ConnectorSvgElementGroup = styled.div`
  position: relative;
  z-index: 0;
  top: calc(var(--intercomBannerHeight) * -1);
  pointer-events: none;
  ${uptoDesktop} {
    display: none;
  }
`;

const portalId = "connector-paths-portal";

const getOrCreateConnectorPathsPortal = () => {
  if (isBuildTime) return null;
  const existing = document.getElementById(portalId) as HTMLDivElement | null;
  if (existing) return existing;
  const portal = document.createElement("div");
  portal.setAttribute("id", portalId);
  document.body.prepend(portal);
  return portal as HTMLDivElement | null;
};

export const ConnectorPathsPainter = () => {
  const [counter, setCounter] = useState(0);
  const counterRef = useRef(counter);
  counterRef.current = counter;
  useOnResize(() => {
    const observer = observeDocumentSize(() => {
      setCounter(counterRef.current + 1);
    });
    return () => {
      observer.disconnect();
    };
  });
  const connectorConfigs =
    isBuildTime || window.innerWidth < breakpoints.tabletLg
      ? []
      : Array.from(
          document.querySelectorAll<HTMLDivElement>(
            ".ConnectorPathWaypoint[data-index='0']"
          )
        ).map(startEl => {
          const id = startEl.getAttribute("data-connector-path-id") ?? "";
          const strokeWidth =
            parseInt(startEl.getAttribute("data-stroke-width") ?? "2") || 2;
          const waypointsRaw = Array.from(
            document.querySelectorAll<HTMLDivElement>(
              `[data-connector-path-id="${id}"]`
            )
          )
            .map(p => {
              const offsets = getOffsetRelativeToPagePrecise(p);
              return {
                index: parseInt(p.getAttribute("data-index") ?? "0") || 0,
                color: p.getAttribute("data-color"),
                radius: parseInt(p.getAttribute("data-radius") ?? "0") || 0,
                pageX: offsets.left + strokeWidth / 2,
                pageY: offsets.top + strokeWidth / 2,
              };
            })
            .sort((a, b) => a.index - b.index);
          const pageXMin =
            (Math.min(...waypointsRaw.map(w => w.pageX)) ?? 0) -
            strokeWidth / 2;
          const pageYMin =
            (Math.min(...waypointsRaw.map(w => w.pageY)) ?? 0) -
            strokeWidth / 2;
          const pageXMax =
            (Math.max(...waypointsRaw.map(w => w.pageX)) ?? 0) -
            strokeWidth / 2;
          const pageYMax =
            (Math.max(...waypointsRaw.map(w => w.pageY)) ?? 0) -
            strokeWidth / 2;
          const svgWidth = pageXMax - pageXMin + strokeWidth;
          const svgHeight = pageYMax - pageYMin + strokeWidth;
          const waypoints = waypointsRaw.map(w => ({
            ...w,
            x: w.pageX - pageXMin,
            y: w.pageY - pageYMin,
          }));
          const pathDRounded = createRoundedPathD(waypoints);
          const gradientId = `${id}-gradient`;
          const firstGradientStop = waypoints.find(p => !!p.color);
          const lastGradientStop = [...waypoints]
            .reverse()
            .find(p => !!p.color);
          const config = {
            id,
            waypoints,
            svg: {
              width: svgWidth,
              height: svgHeight,
              top: pageYMin,
              left: pageXMin,
              element: (
                <svg
                  id={id}
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  fill="none"
                  style={{
                    position: "absolute",
                    top: pageYMin,
                    left: pageXMin,
                  }}
                >
                  <defs>
                    <linearGradient
                      id={gradientId}
                      gradientUnits="userSpaceOnUse"
                      x1={firstGradientStop?.x ?? 0}
                      x2={lastGradientStop?.x ?? 0}
                      y1={firstGradientStop?.y ?? 0}
                      y2={lastGradientStop?.y ?? 0}
                    >
                      <stop
                        offset="0%"
                        stopColor={firstGradientStop?.color ?? colors.purple}
                      />
                      <stop
                        offset="100%"
                        stopColor={lastGradientStop?.color ?? colors.purple}
                      />
                    </linearGradient>
                  </defs>
                  <path
                    d={pathDRounded}
                    strokeWidth={2}
                    stroke={`url(#${gradientId})`}
                  />
                </svg>
              ),
            },
          };
          return config;
        });
  const [portal, setPortal] = useState<HTMLDivElement | null>(null);
  useOnMount(() => {
    setPortal(getOrCreateConnectorPathsPortal());
  });
  return portal
    ? createPortal(
        <ConnectorSvgElementGroup>
          {connectorConfigs.map(e => (
            <Fragment key={e.id}>{e.svg.element}</Fragment>
          ))}
        </ConnectorSvgElementGroup>,
        portal
      )
    : null;
};

const ConnectorPathWaypointDiv = styled.div`
  position: relative;
  width: var(--strokeWidthPx);
  height: var(--strokeWidthPx);
  z-index: 10000;
  pointer-events: none;
  /* background-color: var(--ac);
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;
    background-color: var(--ac);
    opacity: 0.2;
  } */
  code {
    font-size: 9px;
    font-family: ${font("monospace")};
    white-space: nowrap;
  }
`;

export const ConnectorPathWaypoint = (p: {
  id: string;
  index: number;
  color?: string;
  position?: string;
  strokeWidth?: number;
  radius?: number;
  top?: number | string;
  left?: number | string;
  bottom?: number | string;
  right?: number | string;
}) => {
  return (
    <ConnectorPathWaypointDiv
      className="ConnectorPathWaypoint"
      data-connector-path-id={p.id}
      data-index={p.index}
      data-stroke-width={p.strokeWidth}
      data-color={p.color}
      data-radius={p.radius}
      style={
        {
          position: p.position ?? "absolute",
          top: p.top,
          bottom: p.bottom,
          left: p.left,
          right: p.right,
          transform: `translate(${p.left !== undefined ? "-" : ""}50%, ${
            p.top !== undefined ? "-" : ""
          }50%)`,
          "--strokeWidth": p.strokeWidth ?? 2,
          "--strokeWidthPx": `${p.strokeWidth ?? 2}px`,
          "--ac": p.color ?? withOpacity(colors.dark500, 0.5),
        } as CSSProperties
      }
    >
      {debug && (
        <code>
          {p.id} {p.index}r{p.radius}
        </code>
      )}
    </ConnectorPathWaypointDiv>
  );
};
