import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import QuoteCardNeue from "../general/QuoteCardNeue";
import Spacing from "../layout/Spacing";
import {
  DatoCmsCaseStudy,
  DatoCmsProfessionalServicesPage,
} from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import { words } from "../../utils/string.utils";
import {
  brandColorThemeVar,
  colors,
  getBrandColorThemeVariables,
} from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { Link } from "@reach/router";
import AllCaps from "../typography/AllCaps";
import { css } from "linaria";
import {
  fromDesktop,
  fromDesktopMd,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useOnMount } from "../../utils/lifeCycle.utils";
import gsap from "gsap";
import { useRef } from "react";

const GridOuter = styled.div`
  ${onlyPhones} {
    overflow: hidden;
    margin-left: ${rSize("pageMargin", -1)};
    margin-right: ${rSize("pageMargin", -1)};
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: stretch;
  grid-template-areas: "c1" "c2" "c3" "c4" "c5" "c6" "c7";
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    margin-left: -5vw;
    margin-right: -5vw;
  }
  ${fromTablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.75em;
    grid-template-rows: 2.4rem auto auto auto auto 2.4rem;
    grid-template-areas:
      ". c2 ."
      "c1 c2 c3"
      "c1 c6 c4"
      "c7 c6 c4"
      "c5 c6 c4"
      ". c6 .";
  }
  ${fromDesktop} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 4.2rem 4.2rem auto 4.2rem 2.4rem auto 4.8rem;
    grid-template-areas: ${[
      [words(".", 3), words(".", 3), words("c3", 3), words(".", 3)],
      [words(".", 3), words("c2", 3), words("c3", 3), words("c4", 3)],
      [words("c1", 3), words("c2", 3), words("c3", 3), words("c4", 3)],
      [words("c1", 3), words("c2", 3), words("c6", 3), words("c4", 3)],
      [words("c1", 3), words("c7", 3), words("c6", 3), words("c5", 3)],
      [words(".", 3), words("c7", 3), words("c6", 3), words("c5", 3)],
      [words(".", 3), words(".", 3), words("c6", 3), words(".", 3)],
    ]
      .map(row => `"${row.join(" ")}"`)
      .join(" ")};
  }
  ${fromDesktopMd} {
    grid-gap: ${rSize("gap")};
  }
`;

const GridItem = styled.div`
  display: grid;
  grid-template-rows: minmax(auto, 1fr);
  ${fromTablet} {
    grid-template-columns: minmax(0, 1fr);
    transform: none !important;
    footer {
      font-size: 1.2rem;
    }
  }
  ${fromDesktop} {
    footer {
      font-size: 1.4rem;
    }
  }
  > * {
    min-height: 100%;
  }
  &.flexHeight {
    ${fromTablet} {
      grid-template-rows: auto;
      align-items: start;
      > * {
        min-height: unset;
      }
    }
  }
  ${onlyPhones} {
    grid-gap: ${rSize("gap")};
    grid-template-columns: 15% minmax(0, 1fr) 15%;
    &:before {
      content: "";
      display: block;
      background-color: ${colors.light200};
      border-radius: 0 ${rSize("radius")} ${rSize("radius")} 0;
    }
    &:after {
      content: "";
      display: block;
      background-color: ${colors.light200};
      border-radius: ${rSize("radius")} 0 0 ${rSize("radius")};
    }
  }
  &:nth-child(1) {
    grid-area: c1;
    ${onlyPhones} {
      grid-template-columns: 12.5% minmax(0, 1fr) 18%;
      min-height: 17em;
      &:after {
        background-color: ${colors.pink50};
      }
    }
  }
  &:nth-child(2) {
    grid-area: c2;
    ${onlyPhones} {
      grid-template-columns: 16% minmax(0, 1fr) 10%;
      min-height: 17em;
      &:before {
        background-color: ${colors.yellow50};
      }
    }
  }
  &:nth-child(3) {
    grid-area: c3;
    ${onlyPhones} {
      grid-template-columns: 10% minmax(0, 1fr) 15%;
      min-height: 17em;
      &:after {
        background-color: ${colors.purple50};
      }
    }
    ${fromTablet} {
      margin-right: -2em;
    }
    ${fromDesktop} {
      margin-right: 0;
    }
  }
  &:nth-child(4) {
    grid-area: c4;
    ${onlyPhones} {
      grid-template-columns: 18% minmax(0, 1fr) 12.5%;
      min-height: 17em;
      &:before {
        background-color: ${colors.green50};
      }
      &:after {
        background-color: ${colors.yellow50};
      }
    }
  }
  &:nth-child(5) {
    grid-area: c5;
    ${onlyPhones} {
      grid-template-columns: 12.5% minmax(0, 1fr) 15%;
      &:before {
        background-color: ${colors.blue50};
      }
      &:after {
        background-color: ${colors.pink50};
      }
    }
  }
  &:nth-child(6) {
    grid-area: c6;
    ${onlyPhones} {
      grid-template-columns: 12.5% minmax(0, 1fr) 18%;
      min-height: 17em;
      &:before {
        background-color: ${colors.purple50};
      }
    }
  }
  &:nth-child(7) {
    grid-area: c7;
    ${onlyPhones} {
      grid-template-columns: 18% minmax(0, 1fr) 10%;
      &:after {
        background-color: ${colors.orange50};
      }
    }
    ${fromTablet} {
      margin-left: -2em;
    }
    ${fromDesktop} {
      margin-left: 0;
    }
  }
`;

const StatCard = styled.div`
  background-color: ${colors.orange100};
  color: ${colors.orange700};
  border: 1px solid ${colors.orange300};
  padding: ${rSize("gap")};
  ${fromTablet} {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  ${fromDesktop} {
    padding: ${rSize("gap")};
  }
  border-radius: ${rSize("radius")};
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  font-size: 1.4rem;
  grid-gap: 0.75em;
  align-items: center;
  &.darkPurple {
    background-color: ${colors.purple500};
    border: 1px solid ${colors.purple700};
    color: ${colors.white};
  }
  strong {
    font-size: 6rem;
    letter-spacing: -0.01em;
    font-weight: 300;
    font-family: ${serif};
    ${fromTablet} {
      font-size: 4.2rem;
    }
    ${fromDesktopMd} {
      font-size: 6rem;
    }
  }
  span {
    font-weight: 600;
  }
`;

export const ProServCustomerLoveSection = (props: {
  page: DatoCmsProfessionalServicesPage;
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const tl = gsap.timeline({ paused: true });
    gridRef.current?.childNodes.forEach((id, i) => {
      tl.fromTo(
        id,
        { x: (i % 2 === 0 ? 1 : -1) * 12 },
        {
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: id instanceof Array ? id[0] : id,
            scrub: 1,
            start: "top bottom",
            end: "bottom top",
            invalidateOnRefresh: true,
          },
        }
      );
    });
    return () => {
      tl.kill();
    };
  });
  return (
    <PageSection
      className={css`
        h2 {
          ${onlyPhones} {
            padding-left: 1em;
            padding-right: 1em;
          }
        }
      `}
    >
      <SectionHeading2 lighter centered>
        Don’t take our word for it
      </SectionHeading2>
      <Spacing size="sectionPadding" />
      <GridOuter>
        <Grid ref={gridRef}>
          <GridItem>
            <QuoteCardNeue
              color="orange"
              // cspell:disable
              sourceName="Abubakar Mohd"
              // cspell:enable
              sourceDetails={
                <>
                  <div>Chief Technology Officer,</div>
                  <div>Beyon Cyber</div>
                </>
              }
            >
              This proactive support helped us overcome initial deployment
              challenges and optimize our use of the platform.
            </QuoteCardNeue>
          </GridItem>
          <GridItem>
            {props.page.featuredCaseStudies?.[0] && (
              <CaseStudyCard
                caseStudy={props.page.featuredCaseStudies[0]}
                colorMode="light"
              />
            )}
          </GridItem>
          <GridItem>
            <QuoteCardNeue
              color="pink"
              sourceName="Security Leader"
              sourceDetails="National Research Firm"
            >
              Tines’ service has made a huge impact on our decision-making with
              the product and internally.
            </QuoteCardNeue>
          </GridItem>
          <GridItem>
            {props.page.featuredCaseStudies?.[1] && (
              <CaseStudyCard
                caseStudy={props.page.featuredCaseStudies[1]}
                colorMode="light"
              />
            )}
          </GridItem>
          <GridItem className="flexHeight">
            <StatCard>
              <strong>2×</strong>
              <span>faster product adoption with professional services</span>
            </StatCard>
          </GridItem>
          <GridItem>
            {props.page.featuredCaseStudies?.[2] && (
              <CaseStudyCard
                caseStudy={props.page.featuredCaseStudies[2]}
                colorMode="dark"
              />
            )}
          </GridItem>
          <GridItem className="flexHeight">
            <StatCard className="darkPurple">
              <strong>30%</strong>
              <span>more workflows compared to the average</span>
            </StatCard>
          </GridItem>
        </Grid>
      </GridOuter>
    </PageSection>
  );
};

const CaseStudyCardStyle = css`
  position: relative;
  display: grid;
  grid-template-rows: auto minmax(2.4rem, 1fr);
  border-radius: 6px ${rSize("radius")} ${rSize("radius")} 6px;
  padding: ${rSize("gap")};
  text-decoration: none;
  &[data-color-mode="light"] {
    background-color: ${brandColorThemeVar(200)};
    color: ${brandColorThemeVar(800)};
    border: 1px solid ${brandColorThemeVar(400)};
  }
  &[data-color-mode="dark"] {
    background-color: ${brandColorThemeVar(600)};
    color: ${colors.white};
    border: 1px solid ${brandColorThemeVar(700)};
  }
  ${fromDesktopMd} {
    min-height: 30rem;
  }
  ${fromTablet} {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 6px;
      bottom: 0;
      width: 4px;
      height: 100%;
      background-color: black;
      mix-blend-mode: color-burn;
      opacity: 0.1;
      .large & {
        left: 8px;
        width: 5px;
        opacity: 0.175;
      }
    }
  }
  &:after {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='36' height='56' viewBox='0 0 36 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 53.263V0H36V53.263C36 54.8604 34.2197 55.8131 32.8906 54.9271L19.1094 45.7396C18.4376 45.2917 17.5624 45.2917 16.8906 45.7396L3.1094 54.9271C1.78029 55.8131 0 54.8604 0 53.263Z' fill='%23000000'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    position: absolute;
    top: 0;
    mix-blend-mode: color-burn;
    opacity: 0.175;
    width: 20px;
    height: 32px;
    right: 16px;
    .large & {
      ${fromTabletMd} {
        width: 36px;
        height: 57px;
        right: 24px;
      }
    }
  }
  header {
    > div > strong {
      font-size: 1.4rem;
    }
  }
  h3 {
    font-family: ${serif};
    font-size: 2.2rem;
    letter-spacing: -0.01em;
    font-weight: 400;
    ${fromTablet} {
      font-size: 1.8rem;
    }
    ${fromDesktopMd} {
      font-size: 2.2rem;
    }
  }
  figure {
    margin: 0;
    max-width: 50%;
    mix-blend-mode: luminosity;
    align-self: end;
    justify-self: end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      max-height: 2.8rem;
      width: auto;
    }
  }
`;

const CaseStudyCard = (props: {
  caseStudy: DatoCmsCaseStudy;
  colorMode: "light" | "dark";
}) => {
  const logo =
    props.colorMode === "light"
      ? props.caseStudy.customer?.logoDarkGray
      : props.caseStudy.customer?.logoWhite;
  return (
    <Link
      className={CaseStudyCardStyle}
      to={`/case-studies/${props.caseStudy.slug}`}
      data-color-mode={props.colorMode}
      style={{
        ...getBrandColorThemeVariables(props.caseStudy.color),
      }}
    >
      <header>
        <AllCaps as="div">Case studies</AllCaps>
        <Spacing size=".5em" />
        <h3>{props.caseStudy.title}</h3>
        <Spacing size=".5em" />
        <div>
          <strong>Read now →</strong>
        </div>
      </header>
      <figure>
        {logo?.url && (
          <img
            src={logo.url}
            style={{ aspectRatio: `${logo.width}/${logo.height}` }}
          />
        )}
      </figure>
    </Link>
  );
};
