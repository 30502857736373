import { styled } from "@linaria/react";
import { TreePage } from "../../../utils/tree.utils";
import {
  KnownLearningPathConfigs,
  getKnownLearningPathConfig,
} from "../../../utils/university.utils";
import { CourseCompletionIcon } from "./CourseCompletionIcon";
import { colorV4, withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import Spacing from "../../layout/Spacing";
import { font } from "../../../styles/fonts.styles";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";
import { Link } from "gatsby";
import { css } from "linaria";
import { CSSProperties } from "react";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

type Props = {
  learningPath: TreePage;
};

const Card = styled.div`
  border-radius: ${rSize("radius")};
  text-align: center;
  overflow: hidden;
  position: relative;
  color: var(--acDarker);
  ${darkModeLinariaCSS(`color: var(--acLighter);`)}
  h3 {
    font-weight: 400;
    font-family: ${font("serif")};
    font-size: 1.8rem;
    ${fromTablet} {
      font-size: 2.8rem;
    }
  }
`;

const Inner = styled.div`
  padding: ${rSize("xl")} ${rSize("lg")};
  background-color: inherit;
`;

const Footer = styled.div`
  padding: ${rSize("xl")} ${rSize("lg")};
`;

const linkStyle = css`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const UniLearningPathCompletionCard = (props: Props) => {
  const config = getKnownLearningPathConfig(props.learningPath.id);
  const indexOfConfig = config ? KnownLearningPathConfigs.indexOf(config) : -2;
  const nextLearningPath = KnownLearningPathConfigs[indexOfConfig + 1];
  return (
    <Card
      style={
        {
          background: withOpacity(colorV4(config?.color ?? "purple"), 0.1),
          "--acDarker": colorV4(config?.color ?? "purple", 700),
          "--acLighter": colorV4(config?.color ?? "purple", 300),
        } as CSSProperties
      }
    >
      <Inner>
        <CourseCompletionIcon color={config?.color} />
        <Spacing />
        <p>You have completed the series</p>
        <Spacing size=".5em" />
        <h3>{props.learningPath.title}</h3>
      </Inner>
      {nextLearningPath && (
        <Footer>
          <Link
            to={`/university/${nextLearningPath.slug}`}
            className={linkStyle}
          >
            Continue to the <strong>{nextLearningPath.title}</strong> series →
          </Link>
        </Footer>
      )}
    </Card>
  );
};

export default UniLearningPathCompletionCard;
