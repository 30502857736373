import { DatoCmsG2BadgesSection } from "../../../../graphql-types";
import G2BadgeList from "../../general/G2BadgeList";
import PageSection from "../../reusableSections/PageSection";

export const LPG2BadgesSection = ({
  section,
}: {
  section: DatoCmsG2BadgesSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage} className="LPG2BadgesSection">
      <G2BadgeList />
    </PageSection>
  );
};
