import { styled } from "@linaria/react";
import { cx } from "linaria";
import {
  fromDesktop,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import badge1 from "../../../static/images/g2-badges/general/G2-badge-leader-spring-2024.svg";
import badge2 from "../../../static/images/g2-badges/general/G2-badge-best-relationship-spring-2024.svg";
import badge3 from "../../../static/images/g2-badges/general/G2-badge-leader-enterprise-spring-2024.svg";
import badge4 from "../../../static/images/g2-badges/general/G2-badge-best-support-enterprise-spring-2024.svg";
import badge5 from "../../../static/images/g2-badges/general/G2-badge-best-support-mid-market-spring-2024.svg";
import badge6 from "../../../static/images/g2-badges/general/G2-badge-easiest-to-do-business-with-mid-market-spring-2024.svg";
import enterpriseBadge6 from "../../../static/images/g2-badges/enterprise/G2-badge-best-est-roi-enterprise-fall-2024.svg";
import enterpriseBadge2 from "../../../static/images/g2-badges/enterprise/G2-badge-best-relationship-enterprise-fall-2024.svg";
import enterpriseBadge1 from "../../../static/images/g2-badges/enterprise/G2-badge-best-support-enterprise-fall-2024.svg";
import enterpriseBadge3 from "../../../static/images/g2-badges/enterprise/G2-badge-easiest-to-use-enterprise-fall-2024.svg";
import enterpriseBadge4 from "../../../static/images/g2-badges/enterprise/G2-badge-leader-enterprise-fall-2024.svg";
import enterpriseBadge5 from "../../../static/images/g2-badges/enterprise/G2-badge-momentum-leader-fall-2024.svg";
import { externalLinkAttr } from "../../constants/externalLinks.constants";

const G2BadgeCardListUl = styled.ul`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  list-style: none;
  ${fromTabletMd} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${fromDesktop} {
    > * {
      + * {
        margin-left: ${rSize("gap")};
      }
    }
  }
`;

const G2BadgeWrap = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rSize("radius")};
  h3 {
    line-height: 1.25;
    margin-bottom: 1em;
  }
  a {
    display: block;
    &:hover {
      filter: brightness(1.02);
    }
  }
  img {
    width: 120px;
    height: auto;
    max-width: 100%;
  }
`;

const badgeSets = {
  general: [badge6, badge5, badge4, badge2, badge1, badge3],
  enterprise: [
    enterpriseBadge1,
    enterpriseBadge2,
    enterpriseBadge3,
    enterpriseBadge4,
    enterpriseBadge5,
    enterpriseBadge6,
  ],
} as const;

const G2BadgeList = (props: {
  className?: string;
  set?: "general" | "enterprise";
}) => {
  const images = badgeSets[props.set ?? "general"];
  return (
    <G2BadgeCardListUl className={cx(props.className)}>
      {images.map((src, i) => (
        <G2BadgeWrap key={i}>
          <a
            href="https://www.g2.com/products/tines/reviews"
            {...externalLinkAttr}
          >
            <img src={src} width={159} height={176} />
          </a>
        </G2BadgeWrap>
      ))}
    </G2BadgeCardListUl>
  );
};

export default G2BadgeList;
