import { styled } from "@linaria/react";
import { css } from "linaria";
import FeaturedClientLogoSet from "../../components/general/FeaturedClientLogoSet";
import Spacing from "../../components/layout/Spacing";
import HeroSectionWithInset, {
  ColumnedHeroSectionContent,
} from "../../components/reusableSections/HeroSectionWithInset";
import { Serif } from "../../components/typography/Serif";
import SEO from "../../components/utilities/SEO";
import {
  fromDesktop,
  fromDesktopMd,
  fromDesktopMl,
  fromPhoneLg,
  fromTablet,
  fromTabletMd,
  onlyPhones,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import Page from "../../templates/Page.template";
import { PageComponent } from "../../types/gatsby.types";
import headerImage from "../../images/tines-illustration-automation-dominos-orange.png";
import headerImage2x from "../../images/tines-illustration-automation-dominos-orange@2x.png";

import StaticImageFrame from "../../components/basic/StaticImageFrame";
import PageSection from "../../components/reusableSections/PageSection";
import { SectionHeading2 } from "../../components/typography/SectionHeading2";
import { Split } from "../../components/layout/Split";
import ogImage from "../../../static/images/og/tines-switch-from-legacy-soar.png";
import {
  ColoredCardLg,
  ColoredCardMd,
} from "../../components/general/ColoredCard";
import QuotemarkIcon from "../../components/icons/misc/QuotemarkIcon";
import SpaceChildren from "../../components/layout/SpaceChildren";
import {
  AlignCenter,
  AlignCenterOnMobile,
} from "../../components/typography/Align";
import { colorsV4, withOpacity } from "../../styles/colorsV4.styles";
import LogoMcKesson from "../../components/thirdPartyLogos/LogoMcKesson";
import UptoTabletMd from "../../components/mediaQueries/UptoTabletMd";
import SupportAndServicesGrid from "../../components/reusableSections/SupportAndServicesGrid";
import LogoSophos from "../../components/thirdPartyLogos/LogoSophos";
import G2BadgeList from "../../components/general/G2BadgeList";
import MaxWidth from "../../components/layout/MaxWidth";
import { rSize } from "../../styles/responsiveSizes.styles";
import CornerDotSet from "../../components/utilities/CornerDotSet";
import Button from "../../components/forms/Button";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import SpotIllustration from "../../components/illustrations/SpotIllustration";
import { makeSrcSet2x } from "../../utils/srcset.utils";
import LogoOpenTableColor from "../../components/thirdPartyLogos/LogoOpenTableColor";
import illoGetStartedInMinutes from "../../../static/images/tines-get-started-in-minutes-intuitive-and-practical-onboarding-process.png";
import illoGetStartedInMinutes2x from "../../../static/images/tines-get-started-in-minutes-intuitive-and-practical-onboarding-process@2x.png";
import illoSayGoodbyeToLimitedIntegrations from "../../../static/images/tines-illustration-say-goodbye-to-limited-integrations.png";
import illoSayGoodbyeToLimitedIntegrations2x from "../../../static/images/tines-illustration-say-goodbye-to-limited-integrations@2x.png";
import illoRealizeResultsImmediately from "../../../static/images/tines-realize-results-immediately.jpg";
import illoRealizeResultsImmediately2x from "../../../static/images/tines-realize-results-immediately@2x.jpg";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { Link } from "gatsby";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import NoCodeNoPythonCTASection from "../../components/reusableSections/NoCodeNoPythonCTASection";
import BookADemoButton from "../../components/utilities/BookADemoButton";
import { colors } from "../../styles/colors.styles";
import { whenDesktopNav, whenMobileNav } from "../../components/site/SiteNav";

const HeroImageFrameWrap = styled.div`
  pointer-events: none;
  img {
    display: block;
    ${whenMobileNav} {
      max-width: 325px;
    }
    ${whenDesktopNav} {
      max-width: 38vw;
    }
  }
  margin-top: -1.25em;
  ${fromDesktopMd} {
    margin-right: -1em;
    margin-top: -2.5em;
  }
  ${fromDesktopMl} {
    margin-top: -3.5em;
    margin-right: -1.5em;
  }
`;

const SectionContentWrap = styled.div`
  p {
    a {
      ${inParagraphLinkStyle()}
    }
  }
`;
const SectionContentWrapWithPaddingRight = styled.div`
  ${fromDesktop} {
    padding-right: ${widthInGrid(1)};
  }
  p {
    a {
      ${inParagraphLinkStyle()}
    }
  }
`;

const SectionImageWrap = styled.div`
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  img {
    display: block;
    max-width: 100%;
    ${onlyPhones} {
      width: 100%;
    }
  }
`;

const SectionImageWrapWithPaddingRight = styled.div`
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  img {
    display: block;
    max-width: 100%;
    ${onlyPhones} {
      width: 100%;
    }
  }
  ${fromDesktop} {
    padding-right: ${widthInGrid(1)};
  }
`;

const CTAGrid = styled.div`
  ${onlyPhones} {
    text-align: center;
    padding-top: 3em;
    padding-bottom: 2em;
  }
  ${fromTablet} {
    display: grid;
    align-items: center;
    grid-gap: ${rSize("md")};
    grid-template-columns: minmax(auto, 1fr) auto;
  }
`;

const CTAGridHeader = styled.header`
  ${onlyPhones} {
    margin-bottom: 2em;
  }
`;

const CTAImageHolder = styled.figure`
  margin: 0;
  padding: 0;
  img {
    max-width: 100%;
    aspect-ratio: 337 / 416;
    ${onlyPhones} {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${onlyPhones} {
    width: 250px;
    min-width: 250px;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
  }
  ${fromTablet} {
    min-width: 175px;
    max-width: 245px;
    text-align: right;
  }
  ${fromTabletMd} {
    max-width: 300px;
  }
  ${fromDesktop} {
    min-width: 200px;
  }
  picture {
    ${fromTablet} {
      transform: translateX(10%);
    }
  }
`;

const CtaButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${fromPhoneLg} {
    flex-direction: row;
  }
  ${fromTablet} {
    justify-content: flex-start;
  }
`;

const PageWhyTines: PageComponent = props => {
  return (
    <Page
      {...props}
      browserThemeColor={colors.dark900}
      backgroundColor={colors.dark900}
      textColor={colors.white}
    >
      <SEO
        title="SOAR doesn’t need to be complicated to handle massive complexity"
        description="Tines no-code automation empowers security teams to move faster and smarter than their current systems."
        image={ogImage}
      />
      <HeroSectionWithInset backgroundColor={colorsV4.warmBlack}>
        <ColumnedHeroSectionContent>
          <div
            className={css`
              ${whenDesktopNav} {
                max-width: 38em;
                padding-right: 2em;
              }
              h1 {
                ${fluidFontSize(32, 56)};
                letter-spacing: 0;
              }
            `}
          >
            <h1>
              <span>SOAR doesn’t need to be complicated to </span>
              <Serif>handle massive complexity</Serif>
            </h1>
            <Spacing size="1.25em" />
            <p>
              Tines no-code automation empowers security teams to move faster
              and smarter than their current systems.
            </p>
            <Spacing size="1.5em" />
            <BookADemoButton color="purple" children="Talk to our team" />
            <Spacing size="1.5em" />
          </div>
          <HeroImageFrameWrap>
            <StaticImageFrame
              src={headerImage}
              src2x={headerImage2x}
              aspectRatio="448/439"
            />
          </HeroImageFrameWrap>
        </ColumnedHeroSectionContent>
        <Spacing size="sectionPadding" />
      </HeroSectionWithInset>

      <Spacing size="xl" />

      <PageSection>
        <FeaturedClientLogoSet />
      </PageSection>

      <Spacing size="sectionPadding" />

      <PageSection>
        <AlignCenterOnMobile>
          <Split>
            <SectionHeading2>
              Get started in minutes, <Serif>not months</Serif>
            </SectionHeading2>
          </Split>
          <Spacing size="lg" />
          <Split>
            <SectionContentWrapWithPaddingRight>
              <p>
                Bad actors wait for no one, discover automation that keeps you
                on your front foot. With Tines speed and flexibility, you can
                spin up an automation story to contain a threat in minutes
                unlike with legacy solutions.
              </p>
              <Spacing size="1em" />
              <p>
                Our intuitive platform and practical onboarding process ensure
                that every interaction takes you closer to your automation
                goals.
              </p>
              <Spacing size="lg" />
              <ColoredCardMd
                backgroundColor={colorsV4.warmBlack}
                borderColor={withOpacity(colorsV4.grey, 0.2)}
              >
                <AlignCenterOnMobile>
                  <SpaceChildren>
                    <QuotemarkIcon color="purple" size={36} />
                    <p
                      className={css`
                        ${whenDesktopNav} {
                          font-size: 165%;
                          line-height: 1.38;
                        }
                      `}
                    >
                      <Serif>
                        We’re saving ~440 hours before even completing
                        onboarding.
                      </Serif>
                    </p>
                    <div>
                      <p>
                        <strong>– World’s leading freelance marketplace</strong>
                      </p>
                    </div>
                  </SpaceChildren>
                </AlignCenterOnMobile>
              </ColoredCardMd>
            </SectionContentWrapWithPaddingRight>
            <SectionImageWrap>
              <img
                src={illoGetStartedInMinutes}
                srcSet={makeSrcSet2x(
                  illoGetStartedInMinutes,
                  illoGetStartedInMinutes2x
                )}
              />
            </SectionImageWrap>
          </Split>
        </AlignCenterOnMobile>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <AlignCenterOnMobile>
          <Split flip>
            <SectionHeading2>
              Say goodbye to <Serif>limited integrations</Serif>
            </SectionHeading2>
          </Split>
          <Spacing size="lg" />
          <Split flip>
            <SectionContentWrap>
              <p>
                And hello to infinite possibilities. With Tines no-code
                automation, security teams are no longer limited by prefixed
                integration sets. Connect to your tech stack and rest easy
                knowing that if it changes, you can adapt your automation
                without custom code or professional services.
              </p>
              <Spacing size="1em" />
              <p>
                <Link to="/library">Tines library</Link> offers inspiration to
                get started quickly with templates for common use cases and
                integrations.
              </p>
              <Spacing size="lg" />
              <ColoredCardMd
                backgroundColor={colorsV4.warmBlack}
                borderColor={withOpacity(colorsV4.grey, 0.2)}
              >
                <AlignCenterOnMobile>
                  <SpaceChildren>
                    <QuotemarkIcon color="purple" size={34} />
                    <p
                      className={css`
                        ${whenDesktopNav} {
                          font-size: 112%;
                        }
                      `}
                    >
                      <Serif>
                        With Tines, rather than buying a specific tool that
                        works with Tines, it works with whatever we already
                        have. If it has an API, Tines can integrate with it.
                      </Serif>
                    </p>
                    <div>
                      <LogoOpenTableColor />
                    </div>
                  </SpaceChildren>
                </AlignCenterOnMobile>
              </ColoredCardMd>
            </SectionContentWrap>
            <SectionImageWrapWithPaddingRight>
              <img
                src={illoSayGoodbyeToLimitedIntegrations}
                srcSet={makeSrcSet2x(
                  illoSayGoodbyeToLimitedIntegrations,
                  illoSayGoodbyeToLimitedIntegrations2x
                )}
              />
            </SectionImageWrapWithPaddingRight>
          </Split>
        </AlignCenterOnMobile>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <ColoredCardLg backgroundColor={colorsV4.purple}>
          <CornerDotSet
            className={css`
              ${uptoDesktop} {
                display: none;
              }
            `}
            color={withOpacity(colorsV4.purple100, 0.5)}
            offset={rSize("cardSectionPadding", 0.5)}
          />
          <CTAGrid>
            <CTAGridHeader>
              <SectionHeading2
                className={css`
                  ${fluidFontSize(24, 34)};
                `}
              >
                Stop waiting for a professional services team to deliver your
                workflows.
                <br />
                <Serif>Start building Stories and owning your results.</Serif>
              </SectionHeading2>
              <Spacing size="1.25em" />
              <CtaButtonGroup>
                <Button
                  href={`/community-edition?utm_campaign=project-clarity-2022&utm_source=email-why-switch&utm_medium=email`}
                  title="Try it for free"
                  appearance="filled-white"
                  color="purple"
                />
                <Spacing inline size=".75em" />
                <BookADemoButton
                  appearance="outlined"
                  color="white"
                  children="Book a demo"
                />
              </CtaButtonGroup>
            </CTAGridHeader>
            <CTAImageHolder>
              <SpotIllustration name="robotArm" size={162} />
            </CTAImageHolder>
          </CTAGrid>
        </ColoredCardLg>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <AlignCenterOnMobile>
          <Split>
            <SectionHeading2>
              Realize results <Serif>immediately</Serif>
            </SectionHeading2>
          </Split>
          <Spacing size="lg" />
          <Split>
            <SectionContentWrapWithPaddingRight>
              <p>
                Software shouldn’t be gathering dust, it should be delivering
                valuable results. In times like these, you need your resources
                focused on solving the newest threat or testing use cases, not
                debugging thousands of lines of code.
              </p>
              <Spacing size="1em" />
              <p>
                With Tines, customers are discovering greater productivity
                through immediate impact on their response-time to incident
                decrease ratios.
              </p>
              <Spacing size="lg" />
              <ColoredCardMd
                backgroundColor={colorsV4.warmBlack}
                borderColor={withOpacity(colorsV4.grey, 0.2)}
              >
                <AlignCenterOnMobile>
                  <SpaceChildren>
                    <QuotemarkIcon color="orange" size={38} />
                    <p
                      className={css`
                        ${whenDesktopNav} {
                          font-size: 140%;
                        }
                      `}
                    >
                      <Serif>
                        Earliest implementations are freeing up 1.5 analysts per
                        week.
                      </Serif>
                    </p>
                    <div>
                      <LogoMcKesson />
                    </div>
                  </SpaceChildren>
                </AlignCenterOnMobile>
              </ColoredCardMd>
            </SectionContentWrapWithPaddingRight>
            <SectionImageWrap
              className={css`
                border-radius: 18px;
              `}
            >
              <img
                src={illoRealizeResultsImmediately}
                srcSet={makeSrcSet2x(
                  illoRealizeResultsImmediately,
                  illoRealizeResultsImmediately2x
                )}
              />
            </SectionImageWrap>
          </Split>
        </AlignCenterOnMobile>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <AlignCenter>
          <MaxWidth maxWidth="38em" centered>
            <SectionHeading2>
              Support and services are available, <Serif>not required</Serif>
            </SectionHeading2>
          </MaxWidth>
          <Spacing size="lg" />
          <MaxWidth maxWidth="38em" centered>
            <p>
              Our team and product are laser-focused on delivering the best
              automation in the market. We understand the myriad use cases and
              technology you need to move the needle and we’re ready to get to
              work.
            </p>
          </MaxWidth>
        </AlignCenter>
        <Spacing size="xl" />
        <SupportAndServicesGrid />
        <Spacing size="gap" />
        <UptoTabletMd>
          <Spacing size="1em" />
        </UptoTabletMd>
        <ColoredCardMd
          backgroundColor={colorsV4.warmBlack}
          borderColor={withOpacity(colorsV4.grey, 0.2)}
        >
          <AlignCenter>
            <SpaceChildren>
              <QuotemarkIcon color="purple" size={38} darker />
              <MaxWidth maxWidth="33.5em" centered>
                <p
                  className={css`
                    ${whenDesktopNav} {
                      font-size: 125%;
                    }
                  `}
                >
                  <Serif>
                    "If there’s something we can’t do in Tines, they’re
                    responsive, helpful support team is there to quickly set us
                    on the right path. That’s not something we could say about
                    the previous automation product we used!"
                  </Serif>
                </p>
              </MaxWidth>
              <div>
                <LogoSophos />
              </div>
            </SpaceChildren>
          </AlignCenter>
        </ColoredCardMd>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <AlignCenter>
          <MaxWidth maxWidth="38em" centered>
            <SectionHeading2>
              We’re laser-focused on{" "}
              <Serif>automation for security teams</Serif>
            </SectionHeading2>
            <Spacing size="md" />
            <p>Discover why our customers can’t get enough of us.</p>
            <Spacing size="md" />
            <Button
              href="https://www.g2.com/compare/tines-vs-splunk-soar-security-orchestration-automation-and-response-vs-palo-alto-networks-cortex-xsoar-vs-siemplify-google-cloud"
              {...externalLinkAttr}
            >
              Compare us with your current solution
            </Button>
          </MaxWidth>
        </AlignCenter>
        <Spacing size="xl" />
        <G2BadgeList />
      </PageSection>

      <Spacing size="sectionMargin" />

      <NoCodeNoPythonCTASection />

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageWhyTines;
