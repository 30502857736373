import { styled } from "@linaria/react";
import { cx } from "linaria";
import { RefObject, useEffect, useRef, useState } from "react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";
import { isEqualByJson } from "../../utils/equality.utils";
import { getFirstVisibleElement } from "../../utils/html.utils";
import { runAfter } from "../../utils/promises.utils";
import { scrollIntoViewIfNotVisible } from "../../utils/scroll.utils";
import {
  TableOfContents,
  generateTableOfContents,
} from "../../utils/tableOfContents.utils";
import LinkToAnchor from "./LinkToAnchor";
import ColorSchemeToggle from "../utilities/ColorSchemeToggle";
import { ProductIconButton } from "../icons/misc/ProductIconButtonOrLink";

const PageOutlineWrapper = styled.div`
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 1.4rem;
    line-height: 1.25;
  }
  li {
    &.level-1 {
      font-weight: 600;
      border-bottom: 1px solid ${withOpacity(colors.gray, 0.3)};
      padding-bottom: 0.6em;
      margin-bottom: 0.6em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        flex: 1 1 auto;
        padding: 0.5em 0;
        transform: translateY(-0.125em);
      }
      [data-path^="/docs/formulas/functions/"] & {
        font-family: ${font("monospace")};
        font-weight: 600;
      }
      ${ProductIconButton} {
        margin-top: -0.6em;
      }
    }
    &.level-3 {
      padding-left: 0.75em;
    }
    &.level-4 {
      padding-left: 2em;
      font-size: 90%;
    }
    svg {
      flex: 0 0 auto;
      margin-left: 0.5em;
    }
  }
  a {
    display: inline-block;
    text-decoration: none;
    padding: 0.375em 0;
    border-radius: 0.375em;
    &:hover,
    &.active {
      color: var(--ac, ${colors.purple});
    }
  }
  &.fullWidthLinks {
    a {
      display: block;
    }
  }
`;

const PageOutline = (props: {
  bodyRef: RefObject<HTMLDivElement | null>;
  includeLevels?: ("h2" | "h3" | "h4" | "h5" | "h6")[];
  highlightCurrentHeadings?: boolean;
  supportsColorThemes?: boolean;
  fullWidthLinks?: boolean;
  mainHeading?: string;
}) => {
  const { bodyRef } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [toc, setToc] = useState<TableOfContents | null>(null);
  useEffect(() => {
    const newToc = generateTableOfContents(
      bodyRef,
      props.includeLevels,
      props.mainHeading
    );
    if (!isEqualByJson(toc, newToc)) setToc(newToc);
    const headings = Array.from(
      bodyRef.current?.querySelectorAll<HTMLHeadingElement>(
        props.includeLevels?.join(",") ?? "h2, h3"
      ) ?? []
    );
    if (props.highlightCurrentHeadings) {
      const handleScroll = () => {
        if (!bodyRef.current) return;
        const firstVisibleHeading = getFirstVisibleElement(headings, 90);
        const id = firstVisibleHeading?.getAttribute("id");
        const linkInToc = ref.current?.querySelector<HTMLAnchorElement>(
          `[href$="#${id}"]`
        );
        if (linkInToc) {
          if (linkInToc.classList.contains("active")) return;
          Array.from(ref.current?.querySelectorAll("a.active") ?? []).forEach(
            a => a.classList.remove("active")
          );
          linkInToc.classList.add("active");
          runAfter(() => {
            scrollIntoViewIfNotVisible(linkInToc);
          });
        }
      };
      window.addEventListener("scroll", handleScroll);
      handleScroll();
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      return () => {};
    }
  }, [bodyRef, props.includeLevels, toc, props.highlightCurrentHeadings]);
  return (
    <PageOutlineWrapper
      ref={ref}
      className={cx(props.fullWidthLinks && "fullWidthLinks")}
    >
      {toc && toc?.length > 1 && (
        <ul>
          {toc.map((heading, i) => (
            <li key={i} className={`level-${heading.level}`}>
              <LinkToAnchor to={heading.anchor}>
                <span>{heading.text}</span>{" "}
              </LinkToAnchor>
              {props.supportsColorThemes && heading.level === 1 && (
                <ColorSchemeToggle />
              )}
            </li>
          ))}
        </ul>
      )}
    </PageOutlineWrapper>
  );
};

export default PageOutline;
