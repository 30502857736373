import { v4 as uuid } from "uuid";

import { getCookie, setCookie } from "./cookies.utils";

type Event = {
  eventId: string;
  email: string | null;
  url: string;
  visitorId: string;
  eventName: string;
  data: unknown;
};

const storyUrl =
  "https://hq.tines.io/webhook/f0338e0689152b1171da5748eb46b2bc/3f21a00a5c31d5520cea837f6058581c";

let initialized = false;
const queue: Event[] = [];

export function getVisitorId() {
  const existingId = getCookie("tines-visitor-id");
  if (existingId) return existingId;

  const newId = uuid();
  setCookie("tines-visitor-id", newId);
  return newId;
}

setInterval(() => {
  if (!initialized) return;

  const item = queue.shift();
  if (!item) return;

  if (window.location.hostname === "www.tines.com")
    fetch(storyUrl, { method: "post", body: JSON.stringify(item) });
}, 100);

export function captureAnalyticsEvent(eventName: string, data = {}) {
  queue.push({
    eventId: uuid(),
    email: getCookie("email_address"),
    url: window.location.href,
    visitorId: getVisitorId(),
    eventName,
    data,
  });
}

export function initAnalyticsEvents() {
  initialized = true;
}
