import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { BrandColorNameV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ValidStoryEmbed } from "../../utils/datocms.utils";
import StoryEmbed from "../blocks/StoryEmbed.block";
import Button from "../forms/Button";
import PageSection from "./PageSection";

type Props = {
  records: ValidStoryEmbed[];
  overrideColor?: BrandColorNameV4;
  afterList?: ReactNode;
  linkToTag?: string | null;
};

const StoryEmbedListSectionInner = styled.div`
  > * {
    + * {
      margin-top: ${rSize("lg")};
    }
  }
`;

const StoryLibraryCTAButtonWrap = styled.div`
  margin-top: ${rSize("xl")};
  text-align: center;
`;

const StoryEmbedListSection = (props: Props) => {
  return (
    <PageSection>
      <StoryEmbedListSectionInner>
        {props.records.map(record => {
          return <StoryEmbed key={record.id} record={record} fullWidth />;
        })}
        <StoryLibraryCTAButtonWrap>
          <Button
            to={`/library/stories/${
              props.linkToTag ? `?tag=${props.linkToTag}` : ""
            }`}
          >
            View more example Stories
          </Button>
        </StoryLibraryCTAButtonWrap>
      </StoryEmbedListSectionInner>
    </PageSection>
  );
};

export default StoryEmbedListSection;
