export const GooglyEyesIcon = () => (
  <svg
    width="119"
    height="57"
    viewBox="0 0 119 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.9337 18.8334L52.6015 12.7618C52.5678 12.1466 52.1938 11.6389 51.6855 11.4315C50.7443 10.8509 49.543 11.2628 49.543 11.2628L9.70755 18.7921V24.063L49.8668 15.1443L50.0784 19.0102C50.124 19.8487 50.8004 20.4885 51.5892 20.4398C52.3775 20.3908 52.9793 19.6721 52.9337 18.8334Z"
      fill="#E269A4"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.0648 18.4961H7.76525L6.11261 19.3658V26.3951H12.4122L14.0648 24.503V18.4961Z"
      fill="#FFBFDB"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.8622 43.406C50.8624 43.406 50.8614 43.4076 50.8586 43.4106C50.8607 43.4075 50.8621 43.406 50.8622 43.406ZM50.2602 43.314C50.4644 43.3861 50.6135 43.454 50.7161 43.5132C50.6135 43.5725 50.4644 43.6404 50.2602 43.7125C49.7208 43.903 48.9126 44.0831 47.8805 44.2372C45.8241 44.5444 42.9659 44.7365 39.797 44.7365C36.6282 44.7365 33.7699 44.5444 31.7135 44.2372C30.6814 44.0831 29.8732 43.903 29.3338 43.7125C29.1296 43.6404 28.9806 43.5725 28.8779 43.5132C28.9806 43.454 29.1296 43.3861 29.3338 43.314C29.8732 43.1235 30.6814 42.9434 31.7135 42.7893C33.7699 42.4821 36.6282 42.29 39.797 42.29C42.9659 42.29 45.8241 42.4821 47.8805 42.7893C48.9126 42.9434 49.7208 43.1235 50.2602 43.314ZM28.7318 43.406C28.732 43.406 28.7333 43.4075 28.7354 43.4106C28.7327 43.4076 28.7316 43.406 28.7318 43.406ZM28.7318 43.6205C28.7316 43.6205 28.7327 43.6189 28.7354 43.6159C28.7333 43.619 28.732 43.6205 28.7318 43.6205ZM50.8586 43.6159C50.8614 43.6189 50.8624 43.6205 50.8622 43.6205C50.8621 43.6205 50.8607 43.619 50.8586 43.6159Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
    />
    <path
      d="M98.817 43.411C98.8172 43.411 98.8163 43.4124 98.8138 43.4153C98.8156 43.4124 98.8169 43.411 98.817 43.411ZM98.2357 43.3126C98.4372 43.3856 98.5829 43.4541 98.6819 43.5133C98.5829 43.5724 98.4372 43.6409 98.2357 43.7139C97.7116 43.9037 96.9251 44.0834 95.9192 44.2375C93.9153 44.5444 91.1292 44.7365 88.0398 44.7365C84.9504 44.7365 82.1643 44.5444 80.1604 44.2375C79.1545 44.0834 78.368 43.9037 77.8439 43.7139C77.6424 43.6409 77.4967 43.5724 77.3977 43.5132C77.4967 43.4541 77.6424 43.3856 77.8439 43.3126C78.368 43.1228 79.1545 42.9431 80.1604 42.789C82.1643 42.482 84.9504 42.29 88.0398 42.29C91.1292 42.29 93.9153 42.482 95.9192 42.789C96.9251 42.9431 97.7116 43.1228 98.2357 43.3126ZM77.2626 43.411C77.2627 43.411 77.264 43.4124 77.2658 43.4153C77.2633 43.4124 77.2624 43.411 77.2626 43.411ZM77.2626 43.6155C77.2624 43.6155 77.2633 43.614 77.2658 43.6112C77.264 43.6141 77.2627 43.6155 77.2626 43.6155ZM98.8138 43.6112C98.8163 43.614 98.8172 43.6155 98.817 43.6155C98.8169 43.6155 98.8157 43.6141 98.8138 43.6112Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
    />
    <path
      d="M31.0165 0.674805C20.0454 0.674805 11.1514 10.1308 11.1514 21.7951C11.1514 33.4594 20.0454 42.9151 31.0165 42.9151C41.9874 42.9151 50.8814 33.4594 50.8814 21.7951C50.8814 10.1308 41.9874 0.674805 31.0165 0.674805ZM31.0165 39.5991C21.7678 39.5991 14.2704 31.6279 14.2704 21.7951C14.2704 11.9618 21.7678 3.99087 31.0165 3.99087C40.2651 3.99087 47.7626 11.9618 47.7626 21.7951C47.7626 31.6279 40.265 39.5991 31.0165 39.5991Z"
      fill="#E269A4"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M28.5313 2.78809C17.56 2.78809 8.66602 12.244 8.66602 23.9083C8.66602 35.5726 17.56 45.0284 28.5313 45.0284C39.5018 45.0284 48.396 35.5726 48.396 23.9083C48.396 12.244 39.502 2.78809 28.5313 2.78809ZM28.5313 41.7123C19.2826 41.7123 11.785 33.7412 11.785 23.9083C11.785 14.0751 19.2826 6.10415 28.5313 6.10415C37.7798 6.10415 45.2772 14.0751 45.2772 23.9083C45.2774 33.741 37.7798 41.7123 28.5313 41.7123Z"
      fill="#FFBFDB"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M28.5312 6.03125C19.2448 6.03125 11.7169 14.0348 11.7169 23.9078C11.7169 33.7808 19.2448 41.7842 28.5312 41.7842C37.8169 41.7842 45.345 33.7806 45.345 23.9078C45.345 14.0351 37.8171 6.03125 28.5312 6.03125ZM28.5312 38.9772C20.703 38.9772 14.3569 32.2303 14.3569 23.9076C14.3569 15.5845 20.703 8.83785 28.5312 8.83785C36.3595 8.83785 42.7054 15.5845 42.7054 23.9076C42.7054 32.2303 36.3595 38.9772 28.5312 38.9772Z"
      fill="#D2CCFF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M26.6243 10.6953C17.97 10.6953 10.9542 18.1544 10.9542 27.3555C10.9542 36.5566 17.97 44.0155 26.6243 44.0155C35.2782 44.0155 42.2942 36.5566 42.2942 27.3555C42.2942 18.1544 35.2782 10.6953 26.6243 10.6953ZM26.6243 41.3996C19.3288 41.3996 13.4146 35.1118 13.4146 27.3555C13.4146 19.5988 19.329 13.3111 26.6243 13.3111C33.92 13.3111 39.834 19.599 39.834 27.3555C39.8342 35.1118 33.92 41.3996 26.6243 41.3996Z"
      fill="#D2CCFF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M24.5268 14.1416C16.3991 14.1416 9.81012 21.147 9.81012 29.7881C9.81012 38.4295 16.3991 45.4345 24.5268 45.4345C32.6542 45.4345 39.2433 38.4295 39.2433 29.7881C39.2433 21.1468 32.6542 14.1416 24.5268 14.1416ZM24.5268 42.9778C17.675 42.9778 12.1206 37.0724 12.1206 29.7879C12.1206 22.5032 17.675 16.5979 24.5268 16.5979C31.3786 16.5979 36.933 22.5032 36.933 29.7879C36.933 37.0724 31.3786 42.9778 24.5268 42.9778Z"
      fill="#D2CCFF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M21.2852 18.3994C12.9469 18.3994 6.18716 25.5862 6.18716 34.4514C6.18716 43.3166 12.9469 50.5032 21.2852 50.5032C29.6233 50.5032 36.383 43.3166 36.383 34.4514C36.383 25.5862 29.6233 18.3994 21.2852 18.3994ZM21.2852 47.9826C14.2561 47.9826 8.55773 41.9245 8.55773 34.4512C8.55773 26.978 14.2561 20.9196 21.2852 20.9196C28.3145 20.9196 34.0127 26.978 34.0127 34.4512C34.0127 41.9245 28.3147 47.9826 21.2852 47.9826Z"
      fill="#D2CCFF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M59.8812 25.1352C59.0302 25.1352 58.3405 24.4021 58.3405 23.4975C58.3405 22.4784 56.9339 21.3423 54.9153 21.3423C52.8965 21.3423 50.8063 22.4784 50.8063 23.4975C50.8063 24.4021 50.1168 25.1352 49.2659 25.1352C48.4149 25.1352 48.4086 24.4021 48.4086 23.4975C48.4086 20.4519 51.2666 18.0664 54.9153 18.0664C58.564 18.0664 61.4217 20.4519 61.4217 23.4975C61.4217 24.4021 60.732 25.1352 59.8812 25.1352Z"
      fill="#E269A4"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M80.881 0.938477C69.9097 0.938477 61.0159 10.3944 61.0159 22.0587C61.0159 33.7228 69.9097 43.1788 80.881 43.1788C91.8521 43.1788 100.746 33.7228 100.746 22.0587C100.746 10.3942 91.8521 0.938477 80.881 0.938477ZM80.8811 39.8627C71.6325 39.8627 64.1349 31.8916 64.1349 22.0587C64.1349 12.2255 71.6325 4.25454 80.8811 4.25454C90.1296 4.25454 97.6271 12.2255 97.6271 22.0587C97.6272 31.8914 90.1296 39.8627 80.8811 39.8627Z"
      fill="#E269A4"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M78.3958 2.78809C67.4247 2.78809 58.5309 12.244 58.5309 23.9083C58.5309 35.5726 67.4247 45.0284 78.3958 45.0284C89.3671 45.0284 98.2607 35.5726 98.2607 23.9083C98.2607 12.244 89.3671 2.78809 78.3958 2.78809ZM78.3962 41.7123C69.1473 41.7123 61.6497 33.7412 61.6497 23.9083C61.6497 14.0751 69.1473 6.10415 78.3962 6.10415C87.6447 6.10415 95.1419 14.0751 95.1419 23.9083C95.1419 33.741 87.6447 41.7123 78.3962 41.7123Z"
      fill="#FFBFDB"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M49.6412 26.6878C49.2828 26.4313 49.0678 26.0476 48.9367 25.6473C48.9238 25.6078 48.9118 25.5685 48.9007 25.5296C49.0865 25.5896 49.2945 25.6259 49.5077 25.6032C49.64 25.5892 49.7715 25.5623 49.8964 25.5288L49.6412 26.6878Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
    />
    <path
      d="M78.633 6.03125C69.347 6.03125 61.8188 14.0348 61.8188 23.9078C61.8188 33.7808 69.3468 41.7842 78.633 41.7842C87.9189 41.7842 95.447 33.7806 95.447 23.9078C95.447 14.0351 87.9191 6.03125 78.633 6.03125ZM78.633 38.9772C70.805 38.9772 64.4588 32.2303 64.4588 23.9076C64.4588 15.5845 70.805 8.83785 78.633 8.83785C86.4615 8.83785 92.8072 15.5845 92.8072 23.9076C92.8072 32.2303 86.4615 38.9772 78.633 38.9772Z"
      fill="#D2CCFF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M74.6549 12.375C66.0004 12.375 58.985 19.8341 58.985 29.0352C58.985 38.2363 66.0004 45.6952 74.6549 45.6952C83.309 45.6952 90.3248 38.2363 90.3248 29.0352C90.3248 19.8341 83.309 12.375 74.6549 12.375ZM74.6549 43.0793C67.3594 43.0793 61.4452 36.7914 61.4452 29.0352C61.4452 21.2785 67.3596 14.9908 74.6549 14.9908C81.9506 14.9908 87.8646 21.2787 87.8646 29.0352C87.8646 36.7914 81.9506 43.0793 74.6549 43.0793Z"
      fill="#D2CCFF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M70.7253 18.6416C62.071 18.6416 55.0553 26.1007 55.0553 35.3018C55.0553 44.5029 62.071 51.9618 70.7253 51.9618C79.3794 51.9618 86.3952 44.5029 86.3952 35.3018C86.3952 26.1007 79.3793 18.6416 70.7253 18.6416ZM70.7253 49.3459C63.4296 49.3459 57.5156 43.0581 57.5156 35.3018C57.5156 27.5451 63.4298 21.2574 70.7253 21.2574C78.0208 21.2574 83.935 27.5453 83.935 35.3018C83.935 43.0581 78.0208 49.3459 70.7253 49.3459Z"
      fill="#D2CCFF"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M17.3033 55.1171C26.3074 55.1171 33.6067 47.3566 33.6067 37.7836C33.6067 28.2106 26.3074 20.4502 17.3033 20.4502C8.29925 20.4502 1 28.2106 1 37.7836C1 47.3566 8.29925 55.1171 17.3033 55.1171Z"
      fill="white"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M12.3456 49.238C15.7682 49.238 18.5428 46.2881 18.5428 42.6493C18.5428 39.0104 15.7682 36.0605 12.3456 36.0605C8.92301 36.0605 6.14844 39.0104 6.14844 42.6493C6.14844 46.2881 8.92301 49.238 12.3456 49.238Z"
      fill="#B0A6F9"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M12.3458 45.7238C13.9431 45.7238 15.2379 44.3472 15.2379 42.649C15.2379 40.9509 13.9431 39.5742 12.3458 39.5742C10.7486 39.5742 9.45374 40.9509 9.45374 42.649C9.45374 44.3472 10.7486 45.7238 12.3458 45.7238Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M70.5834 55.998C79.5875 55.998 86.8867 48.2375 86.8867 38.6645C86.8867 29.0915 79.5875 21.3311 70.5834 21.3311C61.5793 21.3311 54.2801 29.0915 54.2801 38.6645C54.2801 48.2375 61.5793 55.998 70.5834 55.998Z"
      fill="white"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M74.7785 48.9021C78.2011 48.9021 80.9756 45.9522 80.9756 42.3133C80.9756 38.6745 78.2011 35.7246 74.7785 35.7246C71.3559 35.7246 68.5813 38.6745 68.5813 42.3133C68.5813 45.9522 71.3559 48.9021 74.7785 48.9021Z"
      fill="#B0A6F9"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M74.7782 45.3889C76.3755 45.3889 77.6703 44.0122 77.6703 42.3141C77.6703 40.6159 76.3755 39.2393 74.7782 39.2393C73.181 39.2393 71.8862 40.6159 71.8862 42.3141C71.8862 44.0122 73.181 45.3889 74.7782 45.3889Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
    />
    <path
      d="M106.355 18.4961H100.055L98.4028 19.3658V26.3951H104.702L106.355 24.503V18.4961Z"
      fill="#FFBFDB"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M98.4391 28.1823L98.786 26.8955H99.6019L99.1637 28.6354L98.4391 28.1823Z"
      fill="#4B1D3B"
      stroke="#4B1D3B"
    />
    <path
      d="M117.984 20.6813C117.984 20.6813 117.143 15.1812 116.998 14.2361C116.952 13.9648 117.001 13.6972 116.47 13.3779C115.833 12.9952 115.084 13.5261 115.084 13.5261L104.702 19.7676V26.3951L114.63 17.463L115.156 21.1385C115.275 21.9688 116.004 22.5393 116.785 22.413C117.566 22.2869 118.102 21.5119 117.984 20.6813Z"
      fill="#E269A4"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M98.5289 19.6826H104.779"
      stroke="#4B1D3B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
