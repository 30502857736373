import { cx } from "@linaria/core";
import { CSSProperties, styled } from "@linaria/react";
import { RefObject, useState } from "react";
import shortid from "shortid";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { font } from "../../styles/fonts.styles";
import { Note } from "../../types/tines.types";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import Markdown from "./Markdown";
import { NOTES_MAX_HEIGHT, gridUnit } from "./utils/storyboard.utils";
import { useStoryboardContext } from "./StoryboardContext";

type Props = {
  note: Note;
  style?: CSSProperties;
  isSelected?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
  onAllAssetProcessed?: () => Promise<void>;
};

const NoteContainer = styled.div`
  background-color: ${colorsV4.white};
  padding: 1.6rem;
  border-radius: 1.4rem;
  font-family: ${font("system")};
  box-shadow: rgba(0, 0, 0, 6%) 0px 1px 3px;
  max-height: ${NOTES_MAX_HEIGHT}px;
  overflow: auto;
  a {
    color: ${colorsV4.purple};
    text-decoration: none;
  }
  ${darkModeLinariaCSS(`
    background-color: ${colorsV4.warmBlack800};
    a {
      color: ${colorsV4.purple300};
    }
  `)}
  &.selected {
    border-color: ${colorsV4.purple};
  }
`;

export type NoteOrAssetRenderStateObject = {
  id: string;
  target?: string;
  state: "pending" | "done";
};

const NoteEntry = ({
  note,
  style,
  isSelected,
  innerRef,
  onAllAssetProcessed,
}: Props) => {
  const context = useStoryboardContext();
  const [stateObject] = useState<NoteOrAssetRenderStateObject>({
    id: shortid(),
    state: "pending",
  });
  if (!context.noteRenderStates.includes(stateObject)) {
    context.noteRenderStates.push(stateObject);
  }

  return (
    <NoteContainer
      className={cx(isSelected && "selected")}
      ref={innerRef}
      style={{
        ...style,
        width: note.width ?? gridUnit(18),
      }}
    >
      <Markdown
        markdown={note.content}
        onLoad={() => {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          return new Promise(async resolve => {
            await onAllAssetProcessed?.();
            stateObject.state = "done";
            resolve();
          });
        }}
      />
    </NoteContainer>
  );
};

export default NoteEntry;
