import { css } from "@linaria/core";

export const emojiFontFaces = css`
  :global() {
    @font-face {
      font-family: "color-emoji";
      src: local("Apple Color Emoji"), local("Segoe UI Emoji"),
        local("Segoe UI Symbol"), local("Noto Color Emoji");
    }
  }
`;
