export const FederalTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C8 2.55228 8.44772 3 9 3C9.55228 3 10 2.55228 10 2H8ZM8 1C7.44772 1 7 1.44772 7 2C7 3.10457 7.89543 4 9 4H6C5.44772 4 5 4.44772 5 5C5 5.74028 5.4022 6.38663 6 6.73244V7V12V12.2676C5.4022 12.6134 5 13.2597 5 14C5 14.0862 5.01092 14.1699 5.03144 14.2498C4.41634 14.5909 4 15.2468 4 16C4 16.5523 4.44772 17 5 17H13C13.5523 17 14 16.5523 14 16C14 15.2468 13.5837 14.5909 12.9686 14.2498C12.9891 14.1699 13 14.0862 13 14C13 13.2597 12.5978 12.6134 12 12.2676V12V7V6.73244C12.5978 6.38663 13 5.74028 13 5C13 4.44772 12.5523 4 12 4H9C10.1046 4 11 3.10457 11 2C11 1.44772 10.5523 1 10 1H8ZM11 6C11.5523 6 12 5.55228 12 5H6C6 5.55228 6.44772 6 7 6H11ZM11 12V7H10V12H11ZM9.5 12H8.5V7H9.5V12ZM8 12V7H7V12H8ZM7 13C6.44772 13 6 13.4477 6 14H12C12 13.4477 11.5523 13 11 13H7ZM12 15C12.5523 15 13 15.4477 13 16H5C5 15.4477 5.44772 15 6 15H12Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M4 16C4 14.8954 4.89543 14 6 14H12C13.1046 14 14 14.8954 14 16V16C14 16.5523 13.5523 17 13 17H5C4.44772 17 4 16.5523 4 16V16Z"
      fill="currentColor"
    />
  </svg>
);
