import { styled } from "@linaria/react";
import { FormEvent, ReactNode, useEffect, useState } from "react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { css, cx } from "linaria";
import { last, uniq } from "lodash-es";
import { rSize } from "../../styles/responsiveSizes.styles";
import { useViewportSize } from "../../utils/windowEvents.utils";
import {
  fromDesktopMl,
  fromTablet,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import {
  getUrlQueryParam,
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import { Link } from "gatsby";

type Props<T extends { id: string }> = {
  page?: number;
  defaultPerPage?: number;
  entries: T[];
  children: (entries: T[]) => ReactNode;
  onNavigation?: (page: number) => void;
  scrollToHashOnNavigation?: string;
  scrollToHashOnNavigationOffset?: number;
  appearance?: "filled" | "outlined";
  allowCustomPerPageCount?: boolean;
};

function ListWithPagination<T extends { id: string }>(props: Props<T>) {
  const [page, setPage] = useState(props.page ?? 1);
  const [perPage, setPerPage] = useState(props.defaultPerPage ?? 12);
  const entriesOnCurrentPage = props.entries.slice(
    (page - 1) * perPage,
    page * perPage
  );
  const shouldPaginate = props.entries.length > perPage || perPage === Infinity;
  const scrollToTop = () => {
    if (props.scrollToHashOnNavigation)
      scrollToHash({
        useHash: props.scrollToHashOnNavigation,
        doNotPushState: true,
        offsetY:
          (document.querySelector(".SiteNav")?.clientHeight ?? 0) * -1 +
          (props.scrollToHashOnNavigationOffset ?? 0),
      });
  };
  const handleNavigation = (page: number) => {
    setPage(page);
    props.onNavigation?.(page);
    setUrlQueryParam("page", `${page}`);
    scrollToTop();
  };
  useEffect(() => {
    if (props.page && props.page !== page) {
      handleNavigation(props.page);
    }
  });
  useEffect(() => {
    if (page !== 1) handleNavigation(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entries.map(e => e.id).join("_")]);
  useOnMount(() => {
    const { page } = getUrlQueryParams();
    const parsedPage = parseInt(`${page}`);
    if (parsedPage && parsedPage !== props.page) {
      handleNavigation(parsedPage);
    }
    return () => {
      removeUrlQueryParam("page");
      removeUrlQueryParam("perPage");
    };
  });
  useEffect(() => {
    const fromUrl = getUrlQueryParam("perPage");
    const newValue = fromUrl === "all" ? Infinity : parseInt(`${fromUrl}`);
    if (newValue) {
      if (newValue !== perPage) {
        setPerPage(newValue);
      }
    }
  }, [props.defaultPerPage, perPage]);
  const handleChangePerPage = (value: string) => {
    const newValue = value === "all" ? Infinity : parseInt(value);
    if (newValue) {
      setPerPage(newValue);
      if (page !== 1) handleNavigation(1);
      if (newValue !== props.defaultPerPage && newValue !== perPage) {
        setUrlQueryParam(
          "perPage",
          `${newValue === Infinity ? "all" : newValue}`
        );
      }
      scrollToTop();
    }
  };
  return (
    <div>
      <div>{props.children(entriesOnCurrentPage)}</div>
      {shouldPaginate && (
        <PaginatedListNav
          page={page}
          totalEntries={props.entries.length}
          perPage={perPage}
          onNavigation={handleNavigation}
          onPerPageChange={handleChangePerPage}
          appearance={props.appearance}
          allowCustomPerPageCount={props.allowCustomPerPageCount}
        />
      )}
    </div>
  );
}

const PaginatedListNavWrap = styled.div`
  display: flex;
  justify-content: space-between;
  &.filled {
    border-radius: 1em;
    background-color: ${withOpacity(colors.light300, 0.5)};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark700, 0.8)}`
    )};
  }
  &.outlined {
    border-radius: 0.5em;
    border: 1px solid ${colors.purple100};
  }
  padding: 0.5em 0.375em;
  ${fromTablet} {
    font-size: 1.4rem;
  }
  margin-top: ${rSize("gap")};
  overflow: hidden;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${fromDesktopMl} {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  &.hasEnoughPages {
    flex: 1 1 auto;
  }
`;

const PaginationNavButtonStyle = css`
  appearance: none;
  text-decoration: none;
  display: block;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  border-radius: 0.2em;
  font-weight: 500;
  padding: 0.75em 0.5em;
  ${fromDesktopMl} {
    padding: 0.75em 1.25em;
  }
  border: 0;
  &.interactable {
    cursor: pointer;
    &:hover {
      color: ${colors.purple};
    }
  }
  &.current {
    color: ${colors.purple};
  }
  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

const PaginatedListNavEnd = styled.div`
  display: flex;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  .filled & {
    background-color: ${withOpacity(colors.light400, 0.5)};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.lightest, 0.025)}`
    )};
  }
  .outlined & {
    background-color: transparent;
    border-left: 1px solid ${colors.purple100};
  }
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  margin-right: -0.5em;
  ${onlyPhones} {
    display: none;
  }
  select {
    display: block;
    appearance: none;
    color: inherit;
    background-color: transparent;
    border: 0;
    padding: 0.5em 3em 0.5em 1em;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    .outlined & {
      &:hover {
        background-color: ${colors.purple50};
      }
    }
  }
  svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
  }
`;

const PaginationNavButton = (p: {
  linkFactory?: (page: number | string) => string;
  forPage: number | string;
  currentPage: number;
  onClick: (page: number) => void;
}) => {
  const className = cx(
    PaginationNavButtonStyle,
    typeof p.forPage === "number" && "interactable",
    p.forPage === p.currentPage && "current"
  );
  const onClick =
    typeof p.forPage === "string" ? undefined : () => p.onClick(+p.forPage);
  return p.linkFactory ? (
    <Link
      data-for-page={p.forPage}
      to={p.linkFactory(p.forPage)}
      className={className}
      onClick={onClick}
    >
      {p.forPage}
    </Link>
  ) : (
    <button className={className} onClick={onClick}>
      {p.forPage}
    </button>
  );
};

export const PaginatedListNav = ({
  totalEntries,
  page,
  perPage,
  onNavigation,
  onPerPageChange,
  appearance,
  allowCustomPerPageCount,
  linkFactory,
}: {
  totalEntries: number;
  page: number;
  perPage: number;
  linkFactory?: (page: number | string) => string;
  onNavigation?: (page: number) => void;
  onPerPageChange?: (perPage: string) => void;
  appearance?: "filled" | "outlined";
  allowCustomPerPageCount?: boolean;
}) => {
  const [vw] = useViewportSize();
  const totalPages =
    perPage === Infinity ? 1 : Math.ceil(totalEntries / perPage);
  const firstPages = [1, 2, 3, 4];
  const aroundCurrentPages = [page - 1, page, page + 1];
  const center =
    page > 6 && page < totalPages - 5 ? page : Math.floor(totalPages / 2);
  const centerPages = [center - 1, center, center + 1];
  const lastPages = [
    totalPages - 3,
    totalPages - 2,
    totalPages - 1,
    totalPages,
  ];
  const allPages = uniq(
    [...firstPages, ...aroundCurrentPages, ...centerPages, ...lastPages].sort(
      (a, b) => a - b
    )
  ).filter(n => n <= totalPages && n >= 1);
  const buttons = allPages.reduce<(number | string)[]>((arr, curr) => {
    if (arr.length === 0) return [curr];
    const prev = +last(arr)!;
    return typeof prev === "string"
      ? [...arr, curr]
      : [...arr, ...(curr - prev > 1 ? ["…", curr] : [curr])];
  }, []);
  const goToPage = (page: number) => {
    onNavigation?.(page);
  };
  const canGoPrev = page > 1;
  const previous = () => {
    if (!canGoPrev) return;
    goToPage(page - 1);
  };
  const canGoNext = page < totalPages;
  const next = () => {
    if (!canGoNext) return;
    goToPage(page + 1);
  };
  const handleChangePerPage = (e: FormEvent<HTMLSelectElement>) => {
    const value = (e.target as HTMLSelectElement).value;
    onPerPageChange?.(value);
  };
  const prevButtonAttrs = {
    onClick: previous,
    className: cx(
      PaginationNavButtonStyle,
      canGoPrev ? "interactable" : "disabled"
    ),
    children: vw >= 1280 ? "←  Previous" : "←  Prev",
  };
  const nextButtonAttrs = {
    onClick: next,
    className: cx(
      PaginationNavButtonStyle,
      canGoNext ? "interactable" : "disabled"
    ),
    children: "Next  →",
  };
  return (
    <PaginatedListNavWrap className={appearance ?? "filled"}>
      {linkFactory && canGoPrev ? (
        <Link to={linkFactory(page - 1)} {...prevButtonAttrs} />
      ) : (
        <button {...prevButtonAttrs} />
      )}
      {vw >= 768 ? (
        <Inner className={cx(buttons.length >= 12 ? "hasEnoughPages" : "")}>
          {buttons.map((p, i) => (
            <PaginationNavButton
              forPage={p}
              currentPage={page}
              key={`${p}_${i}`}
              onClick={goToPage}
              linkFactory={linkFactory}
            />
          ))}
        </Inner>
      ) : (
        <button className={PaginationNavButtonStyle}>
          Page {page} / {totalPages}
        </button>
      )}
      <PaginatedListNavEnd>
        {linkFactory && canGoNext ? (
          <Link to={linkFactory(page + 1)} {...nextButtonAttrs} />
        ) : (
          <button {...nextButtonAttrs} />
        )}
        {allowCustomPerPageCount && (
          <SelectWrapper>
            <select
              id="pagination-per-page"
              value={perPage === Infinity ? "all" : perPage}
              onChange={handleChangePerPage}
            >
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
              <option value="all">All entries</option>
            </select>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
            >
              <path d="M1.04785 1L5.04785 5L9.04785 1" />
            </svg>
          </SelectWrapper>
        )}
      </PaginatedListNavEnd>
    </PaginatedListNavWrap>
  );
};

export default ListWithPagination;
