export const SpeechBubbleA = () => (
  <svg width="29" height="21" viewBox="0 0 29 21" fill="none">
    <path
      d="M4.86578 15V14.5H4.36578H3.12294C1.67433 14.5 0.5 13.3257 0.5 11.8771V3.12293C0.5 1.67433 1.67433 0.5 3.12293 0.5H25.8771C27.3257 0.5 28.5 1.67433 28.5 3.12293V11.8771C28.5 13.3257 27.3257 14.5 25.8771 14.5H9.4586H9.2515L9.10506 14.6464L4.86578 18.8855V15Z"
      fill="#FFBFDB"
      stroke="#CB5D94"
    />
  </svg>
);

export const SpeechBubbleB = () => (
  <svg width="27" height="21" viewBox="0 0 27 21" fill="none">
    <path
      d="M21.9995 14.5002H21.4995V15.0002V18.8187L17.3273 14.6467L17.1809 14.5002H16.9738H3.12293C1.67433 14.5002 0.5 13.3259 0.5 11.8773V3.12318C0.5 1.67457 1.67433 0.500244 3.12293 0.500244H23.8771C25.3257 0.500244 26.5 1.67457 26.5 3.12318V11.8773C26.5 13.3259 25.3257 14.5002 23.8771 14.5002H21.9995Z"
      fill="#D7C4FA"
      stroke="#7F69CE"
    />
  </svg>
);
