import { useLayoutEffect, useRef } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { styled } from "@linaria/react";

type Props = {
  className?: string;
  src: string;
  poster?: string;
  playbackRate?: number;
};

const Video = styled.video`
  max-width: 100%;
`;

const VideoAutoplayWhenVisible = (props: Props) => {
  const ref = useRef<HTMLVideoElement>(null);
  useOnMount(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) ref.current?.play();
      else ref.current?.pause();
    });
    intersectionObserver.observe(ref.current!);
    return () => {
      intersectionObserver.disconnect();
    };
  });
  useLayoutEffect(() => {
    if (props.playbackRate) ref.current!.playbackRate = props.playbackRate;
  });
  return (
    <Video
      className={props.className}
      src={props.src}
      poster={props.poster}
      muted
      loop
      preload="auto"
      controls={false}
      playsInline
      ref={ref}
    />
  );
};

export default VideoAutoplayWhenVisible;
