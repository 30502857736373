import { colors, withOpacity } from "../../../styles/colors.styles";
import Spacing from "../../layout/Spacing";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { UFOIcon } from "./icons/UFOIcon";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import ReportV2StatTable from "../components/ReportV2StatTable";
import { ReportV2HalfDarkSectionNavControlPoint } from "../components/ReportV2CommonNavControlPoints";
import { GoldCoinIcon } from "./icons/GoldCoinIcon";
import { styled } from "@linaria/react";
import { PropsWithChildren } from "react";
import { cx } from "linaria";
import {
  fromDesktopMd,
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const SpecialChart = styled.div`
  text-align: center;
  p {
    text-align: center;
    max-width: 38em;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SOC2023Chapter5 = () => {
  return (
    <section id="chapter-5-improving-retention">
      <ReportV2ChapterHeaderSection
        chapterNumber={5}
        largeChapterNumberStyle="dots"
        color="dark"
        icon={<UFOIcon />}
      >
        <h2>
          <span>Improving</span> <span>retention</span>
        </h2>
        <ReportV2TextBlock largerText>
          <p>
            Burnout is a real issue for SOC teams, and the current economic
            pressures are only making the job harder. We wanted to know if SOC
            teams were considering career moves — and what organizations could
            do to retain them.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2HalfDarkSectionNavControlPoint />

      <ReportV2Section backgroundColor={colors.dark900} color={colors.white}>
        <SpecialChart>
          <ReportV2SectionHeadingMedium>
            <span>How likely are you to</span>
            <span>switch jobs in the next 12 months?</span>
          </ReportV2SectionHeadingMedium>
          <Spacing size="lg" />
          <JobSwitchDonutChart />
          <Spacing size="lg" />
          <p>
            We know SOC teams are frustrated by manual work and poorly
            integrated tools, so it’s no surprise that more than half of
            respondents are at least somewhat likely to leave for a new job in
            the next year.
          </p>
        </SpecialChart>
      </ReportV2Section>

      <ReportV2Section
        backgroundColor={colors.dark700}
        color={colors.white}
        isLastSection
      >
        <ReportV2SectionHeader icon={<GoldCoinIcon />}>
          <ReportV2SectionHeadingMedium>
            <span>Top three ways to</span> <span>improve retention</span>
          </ReportV2SectionHeadingMedium>
          <Spacing />
          <p>
            As SOC teams eye the door, we asked our respondents about the
            actions their organizations could take to keep them on board. The
            top answer was to simply pay more — no surprises there. Despite over
            96% of respondents reporting they feel fairly compensated, they
            still feel a pay increase would help keep them around. But security
            teams also pointed to other factors: supplying more modern tools
            with advanced capabilities; hiring more people; and providing tools
            that automate the tedious manual tasks that have them looking
            elsewhere.
          </p>
          <p>
            What could your current organization do to retain you or your team?
          </p>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="purple"
          borderColor={withOpacity(colors.white, 0.1)}
          rowHighlightColor={colors.dark500}
          numberOfRowsToHighlight={3}
          items={[
            { percentage: 49, heading: "Pay more" },
            {
              percentage: 42,
              heading: "Provide more modern tools with advanced capabilities",
            },
            { percentage: 40, heading: "Hire more people on our team" },
            {
              percentage: 39,
              heading: "Provide tools that automate tedious manual tasks",
            },
            { percentage: 34, heading: "Provide regular training" },
            { percentage: 33, heading: "Pay for industry certifications" },
            {
              percentage: 33,
              heading:
                "Consult security team for security software purchases and upgrades",
            },
            { percentage: 31, heading: "More favorable shifts" },
            { percentage: 25, heading: "Reduce on-call hours" },
            { percentage: 1, heading: "Other" },
            { percentage: 2, heading: "None of the above" },
          ]}
        />
      </ReportV2Section>
    </section>
  );
};

const JobSwitchDonutChart = () => (
  <JobSwitchDonutChartFrame>
    <JobSwitchDonutChartSvg />
    <div>
      <Legend color={colors.orange600}>Very likely</Legend>
      <Legend color={colors.orange500}>Likely</Legend>
      <Legend color={colors.orange400}>Somewhat likely</Legend>
    </div>
    <div>
      <Legend color={colors.green400} flipped>
        Not very likely
      </Legend>
      <Legend color={colors.green500} flipped>
        Not likely at all
      </Legend>
    </div>
  </JobSwitchDonutChartFrame>
);

const JobSwitchDonutChartFrame = styled.div`
  display: grid;
  grid-gap: 2.4rem;
  grid-template-columns: auto auto;
  grid-template-areas: "donut donut" "legendsA legendsB";
  ${fromTablet} {
    align-items: center;
    grid-template-columns: 1fr minmax(135px, auto) 1fr;
    grid-template-areas: "legendsA donut legendsB";
  }
  ${fromDesktopMd} {
    grid-gap: 4.8rem;
  }
  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
    ${onlyPhones} {
      margin-top: 1em;
      max-width: 250px;
    }
  }
  > * {
    &:first-child {
      grid-area: donut;
    }
    &:nth-child(2) {
      grid-area: legendsB;
      padding: 1em 0 2em;
    }
    &:nth-child(3) {
      grid-area: legendsA;
      padding: 1em 0 2em;
    }
  }
`;

const LegendWrap = styled.div`
  display: flex;
  text-align: left;
  font-size: 1.2rem;
  padding: 0.5em 0;
  &.flipped {
    text-align: right;
    flex-direction: row-reverse;
  }
`;

const ColorDot = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  flex: 0 0 auto;
`;

const LegendLabel = styled.div`
  padding: 0 0.375em;
`;

const Legend = (
  props: PropsWithChildren<{ color: string; flipped?: boolean }>
) => {
  return (
    <LegendWrap className={cx(props.flipped && "flipped")}>
      <ColorDot style={{ backgroundColor: props.color }} />
      <LegendLabel>{props.children}</LegendLabel>
    </LegendWrap>
  );
};

const JobSwitchDonutChartSvg = () => (
  <svg
    width="303"
    height="303"
    viewBox="0 0 303 303"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M151.627 95.4977V1.63281C185.3 1.63281 217.996 12.949 244.465 33.7642C270.934 54.5794 289.639 83.6858 297.578 116.409L206.359 138.539C203.382 126.268 196.367 115.353 186.442 107.547C176.516 99.7413 164.255 95.4977 151.627 95.4977Z"
      fill="#BE5019"
    />
    <path
      d="M209.523 49.2677C209.462 47.482 210.596 46.3599 212.382 46.3599C214.035 46.3599 215.205 47.3734 215.205 48.9178C215.205 50.0279 214.53 50.9448 213.227 51.6085L211.984 52.2359C211.369 52.5375 210.681 53.0925 210.681 53.7923H215.23V54.9023H209.45V53.9974C209.438 52.5496 210.608 51.6326 211.453 51.2103L212.768 50.5467C213.589 50.1244 213.927 49.5935 213.927 48.9058C213.927 48.0129 213.287 47.4338 212.382 47.4338C211.369 47.4338 210.753 48.1698 210.802 49.2677H209.523ZM216.451 49.2677C216.391 47.482 217.525 46.3599 219.31 46.3599C220.963 46.3599 222.134 47.3734 222.134 48.9178C222.134 50.0279 221.458 50.9448 220.155 51.6085L218.912 52.2359C218.297 52.5375 217.609 53.0925 217.609 53.7923H222.158V54.9023H216.378V53.9974C216.366 52.5496 217.537 51.6326 218.381 51.2103L219.696 50.5467C220.517 50.1244 220.855 49.5935 220.855 48.9058C220.855 48.0129 220.215 47.4338 219.31 47.4338C218.297 47.4338 217.682 48.1698 217.73 49.2677H216.451ZM223.391 54.9023L229.52 46.4565H230.594L224.465 54.9023H223.391ZM223.09 48.2904C223.09 47.2166 223.85 46.3599 224.924 46.3599C225.997 46.3599 226.745 47.2166 226.745 48.2904C226.745 49.3643 225.997 50.2209 224.924 50.2209C223.85 50.2209 223.09 49.3643 223.09 48.2904ZM223.97 48.2904C223.97 48.9299 224.344 49.4125 224.924 49.4125C225.503 49.4125 225.877 48.9299 225.877 48.2904C225.877 47.651 225.503 47.1683 224.924 47.1683C224.344 47.1683 223.97 47.651 223.97 48.2904ZM227.264 53.0684C227.264 51.9945 228.024 51.1379 229.098 51.1379C230.172 51.1379 230.92 51.9945 230.92 53.0684C230.92 54.1422 230.172 54.9989 229.098 54.9989C228.024 54.9989 227.264 54.1422 227.264 53.0684ZM228.145 53.0684C228.145 53.7079 228.519 54.1905 229.098 54.1905C229.677 54.1905 230.051 53.7079 230.051 53.0684C230.051 52.4289 229.677 51.9463 229.098 51.9463C228.519 51.9463 228.145 52.4289 228.145 53.0684Z"
      fill="white"
    />
    <path
      d="M206.359 138.536L297.578 116.406C303.984 142.812 303.106 170.46 295.038 196.406C286.971 222.352 272.015 245.623 251.765 263.74L189.179 193.786C196.773 186.992 202.381 178.265 205.407 168.536C208.432 158.806 208.761 148.438 206.359 138.536Z"
      fill="#F47E3F"
    />
    <path
      d="M260.857 173.275V181.721H259.602V175.229C259.457 175.422 259.083 175.531 258.745 175.531H257.997V174.421H258.649C259.228 174.421 259.566 174.18 259.638 173.275H260.857ZM263.546 181.721C263.558 180.357 263.751 179.139 264.825 177.413L266.707 174.385H261.977V173.275H268.119V174.276L265.85 177.98C264.958 179.44 264.813 180.502 264.801 181.721H263.546ZM268.976 181.721L275.105 173.275H276.179L270.05 181.721H268.976ZM268.674 175.109C268.674 174.035 269.435 173.178 270.508 173.178C271.582 173.178 272.33 174.035 272.33 175.109C272.33 176.183 271.582 177.039 270.508 177.039C269.435 177.039 268.674 176.183 268.674 175.109ZM269.555 175.109C269.555 175.748 269.929 176.231 270.508 176.231C271.088 176.231 271.462 175.748 271.462 175.109C271.462 174.469 271.088 173.987 270.508 173.987C269.929 173.987 269.555 174.469 269.555 175.109ZM272.849 179.887C272.849 178.813 273.609 177.956 274.683 177.956C275.757 177.956 276.505 178.813 276.505 179.887C276.505 180.961 275.757 181.817 274.683 181.817C273.609 181.817 272.849 180.961 272.849 179.887ZM273.73 179.887C273.73 180.526 274.104 181.009 274.683 181.009C275.262 181.009 275.636 180.526 275.636 179.887C275.636 179.247 275.262 178.765 274.683 178.765C274.104 178.765 273.73 179.247 273.73 179.887Z"
      fill="white"
    />
    <path
      d="M105.751 119.148L29.2913 64.7015C43.1792 45.1987 61.5285 29.2989 82.8092 18.3279C104.09 7.35696 127.685 1.63281 151.627 1.63281V95.4977C142.649 95.4977 133.801 97.6443 125.821 101.758C117.84 105.872 110.959 111.835 105.751 119.148Z"
      fill="#25A871"
    />
    <path
      d="M84.9882 46.4565V54.9023H83.7334V48.4111C83.5886 48.6041 83.2146 48.7127 82.8767 48.7127H82.1287V47.6027H82.7802C83.3594 47.6027 83.6972 47.3614 83.7696 46.4565H84.9882ZM89.3305 54.9989C87.7499 54.9989 86.5554 53.925 86.4348 52.4772H87.6292C87.7499 53.3821 88.3894 53.8888 89.3305 53.8888C90.4646 53.8888 91.1886 53.2132 91.1886 52.1152C91.1886 51.0172 90.4646 50.2933 89.3425 50.2933C88.498 50.2933 87.9067 50.6191 87.6534 51.1862H86.5916L87.2673 46.4565H91.8763V47.5665H88.2084L87.8706 49.8469C88.2446 49.509 88.872 49.2315 89.5356 49.2315C91.261 49.2315 92.4675 50.4381 92.4675 52.1152C92.4675 53.8285 91.1644 54.9989 89.3305 54.9989ZM93.5791 54.9023L99.7084 46.4565H100.782L94.6529 54.9023H93.5791ZM93.2774 48.2904C93.2774 47.2166 94.0375 46.3599 95.1114 46.3599C96.1852 46.3599 96.9333 47.2166 96.9333 48.2904C96.9333 49.3643 96.1852 50.2209 95.1114 50.2209C94.0375 50.2209 93.2774 49.3643 93.2774 48.2904ZM94.1582 48.2904C94.1582 48.9299 94.5322 49.4125 95.1114 49.4125C95.6905 49.4125 96.0646 48.9299 96.0646 48.2904C96.0646 47.651 95.6905 47.1683 95.1114 47.1683C94.5322 47.1683 94.1582 47.651 94.1582 48.2904ZM97.4521 53.0684C97.4521 51.9945 98.2122 51.1379 99.2861 51.1379C100.36 51.1379 101.108 51.9945 101.108 53.0684C101.108 54.1422 100.36 54.9989 99.2861 54.9989C98.2122 54.9989 97.4521 54.1422 97.4521 53.0684ZM98.3329 53.0684C98.3329 53.7079 98.7069 54.1905 99.2861 54.1905C99.8652 54.1905 100.239 53.7079 100.239 53.0684C100.239 52.4289 99.8652 51.9463 99.2861 51.9463C98.7069 51.9463 98.3329 52.4289 98.3329 53.0684Z"
      fill="white"
    />
    <path
      d="M132.365 204.737L100.262 292.941C78.52 285.028 58.9007 272.198 42.9344 255.453C26.968 238.708 15.0865 218.501 8.21664 196.407C1.34681 174.313 -0.325508 150.931 3.33016 128.085C6.98583 105.239 15.8706 83.546 29.2914 64.6992L105.751 119.146C100.719 126.214 97.3869 134.349 96.016 142.916C94.6451 151.483 95.2722 160.252 97.8484 168.537C100.425 176.822 104.88 184.4 110.868 190.679C116.855 196.958 124.212 201.769 132.365 204.737Z"
      fill="#5ABE89"
    />
    <path
      d="M28.1761 176.088C28.1158 174.302 29.25 173.18 31.0357 173.18C32.6887 173.18 33.859 174.194 33.859 175.738C33.859 176.848 33.1833 177.765 31.8803 178.429L30.6375 179.056C30.0222 179.358 29.3344 179.913 29.3344 180.613H33.8831V181.723H28.1038V180.818C28.0917 179.37 29.262 178.453 30.1066 178.031L31.4218 177.367C32.2422 176.945 32.5801 176.414 32.5801 175.726C32.5801 174.833 31.9406 174.254 31.0357 174.254C30.0222 174.254 29.4068 174.99 29.4551 176.088H28.1761ZM38.0484 178.923C36.2868 178.923 35.0803 177.693 35.0803 176.052C35.0803 174.399 36.3954 173.18 38.1449 173.18C40.1478 173.18 41.3302 174.797 41.3302 177.114C41.3302 180.202 39.9186 181.831 38.0001 181.831C36.3713 181.831 35.213 180.854 35.0079 179.515H36.2265C36.3834 180.263 37.0349 180.745 37.9639 180.745C39.2308 180.745 40.0875 179.43 40.1599 177.717C39.81 178.429 38.9171 178.923 38.0484 178.923ZM36.3592 176.064C36.3592 177.089 37.0832 177.813 38.1329 177.813C39.1705 177.813 39.9065 177.089 39.9065 176.064C39.9065 175.026 39.1705 174.29 38.1329 174.29C37.0832 174.29 36.3592 175.026 36.3592 176.064ZM42.5632 181.723L48.6925 173.277H49.7663L43.637 181.723H42.5632ZM42.2615 175.111C42.2615 174.037 43.0217 173.18 44.0955 173.18C45.1693 173.18 45.9174 174.037 45.9174 175.111C45.9174 176.185 45.1693 177.041 44.0955 177.041C43.0217 177.041 42.2615 176.185 42.2615 175.111ZM43.1423 175.111C43.1423 175.75 43.5163 176.233 44.0955 176.233C44.6746 176.233 45.0487 175.75 45.0487 175.111C45.0487 174.471 44.6746 173.989 44.0955 173.989C43.5163 173.989 43.1423 174.471 43.1423 175.111ZM46.4362 179.889C46.4362 178.815 47.1963 177.958 48.2702 177.958C49.344 177.958 50.0921 178.815 50.0921 179.889C50.0921 180.963 49.344 181.819 48.2702 181.819C47.1963 181.819 46.4362 180.963 46.4362 179.889ZM47.317 179.889C47.317 180.528 47.691 181.011 48.2702 181.011C48.8493 181.011 49.2233 180.528 49.2233 179.889C49.2233 179.249 48.8493 178.767 48.2702 178.767C47.691 178.767 47.317 179.249 47.317 179.889Z"
      fill="white"
    />
    <path
      d="M189.179 193.789L251.765 263.743C231.516 281.86 206.73 294.144 180.05 299.286C153.37 304.429 125.794 302.236 100.262 292.943L132.365 204.739C141.94 208.224 152.281 209.046 162.286 207.118C172.291 205.189 181.585 200.583 189.179 193.789Z"
      fill="#FD975D"
    />
    <path
      d="M100.262 292.94L132.365 204.736"
      stroke="#2E2D36"
      strokeWidth="2.01092"
      strokeLinecap="round"
    />
    <path
      d="M151.499 1.5V95.5107"
      stroke="#2E2D36"
      strokeWidth="2.01092"
      strokeLinecap="round"
    />
    <path
      d="M166.614 263.695V272.141H165.359V265.649C165.214 265.842 164.84 265.951 164.502 265.951H163.754V264.841H164.406C164.985 264.841 165.323 264.6 165.395 263.695H166.614ZM169.303 272.141C169.315 270.777 169.508 269.559 170.582 267.833L172.464 264.805H167.734V263.695H173.876V264.696L171.607 268.4C170.715 269.86 170.57 270.922 170.558 272.141H169.303ZM174.733 272.141L180.862 263.695H181.936L175.807 272.141H174.733ZM174.431 265.529C174.431 264.455 175.192 263.598 176.265 263.598C177.339 263.598 178.087 264.455 178.087 265.529C178.087 266.603 177.339 267.459 176.265 267.459C175.192 267.459 174.431 266.603 174.431 265.529ZM175.312 265.529C175.312 266.168 175.686 266.651 176.265 266.651C176.845 266.651 177.219 266.168 177.219 265.529C177.219 264.889 176.845 264.407 176.265 264.407C175.686 264.407 175.312 264.889 175.312 265.529ZM178.606 270.307C178.606 269.233 179.366 268.376 180.44 268.376C181.514 268.376 182.262 269.233 182.262 270.307C182.262 271.38 181.514 272.237 180.44 272.237C179.366 272.237 178.606 271.38 178.606 270.307ZM179.487 270.307C179.487 270.946 179.861 271.429 180.44 271.429C181.019 271.429 181.393 270.946 181.393 270.307C181.393 269.667 181.019 269.185 180.44 269.185C179.861 269.185 179.487 269.667 179.487 270.307Z"
      fill="white"
    />
  </svg>
);
