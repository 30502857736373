export const AIActionPortalIconFacingLeft = () => (
  <svg
    width="66"
    height="92"
    viewBox="0 0 66 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4201 90.6868C20.9816 90.6868 15.7621 89.9317 15.7621 89.0004C15.7621 88.069 20.9816 87.314 27.4201 87.314C33.8587 87.314 39.0781 88.069 39.0781 89.0004C39.0781 89.9317 33.8587 90.6868 27.4201 90.6868Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.6431 23.3612C39.6429 24.553 40.6091 25.5196 41.8013 25.5194L53.5838 25.5675C54.776 25.5679 55.7424 24.6013 55.7422 23.4094C55.7422 22.2174 54.7758 21.251 53.584 21.251L41.8015 21.2029C40.6091 21.2029 39.6429 22.1693 39.6431 23.3612Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.5822 30.8234C29.5822 32.0151 30.5484 32.9818 31.7406 32.9818L47.5721 33.0299C48.7643 33.0303 49.7307 32.0637 49.7305 30.8715C49.7305 29.6796 48.764 28.7134 47.5721 28.7132L31.7406 28.665C30.5484 28.665 29.5822 29.6314 29.5822 30.8234Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.5539 41.1833C30.5539 43.6295 32.5369 45.6129 34.9838 45.6129H47.4766C49.9228 45.6129 51.9058 43.6295 51.9062 41.1835C51.9058 38.7371 49.9228 36.7539 47.4766 36.7542H34.9838C32.5369 36.7539 30.5539 38.7371 30.5539 41.1833Z"
      fill="#5EBC89"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.7435 33.273C53.7435 34.7595 54.9488 35.9647 56.4355 35.9647H61.8549C63.3414 35.9647 64.5469 34.7597 64.5469 33.273C64.5469 31.7862 63.3417 30.5808 61.8547 30.5808H56.4355C54.9488 30.5808 53.7435 31.786 53.7435 33.273Z"
      fill="#FA975F"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.0729 53.6167C40.0729 54.9679 41.1684 56.0631 42.5198 56.0631H51.1078C52.4592 56.0631 53.5547 54.9681 53.5547 53.6167C53.5547 52.2649 52.4592 51.1699 51.1078 51.1697H42.5198C41.1684 51.1697 40.0729 52.2649 40.0729 53.6167Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.31 14.6726C34.31 16.6862 35.942 18.3181 37.9555 18.3181H42.737C44.7508 18.3181 46.383 16.6862 46.3828 14.6726C46.383 12.6586 44.7504 11.0266 42.7368 11.0266H37.956C35.9422 11.0264 34.31 12.6586 34.31 14.6726Z"
      fill="#FBBD74"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M31.0519 61.6562C31.0519 63.6698 32.6841 65.3018 34.6977 65.3018H39.4792C41.4928 65.3018 43.1252 63.6698 43.125 61.6562C43.1252 59.6422 41.4926 58.0103 39.479 58.0103H34.6979C32.6841 58.01 31.0519 59.6422 31.0519 61.6562Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.3519 0.686768C24.7763 0.686768 26.1457 1.39281 27.4213 2.68931C28.6966 1.39236 30.0656 0.686994 31.4905 0.686994C38.3933 0.686769 43.9883 17.2134 43.9883 37.6004C43.9883 57.9869 38.3933 74.5138 31.4905 74.5138C30.0656 74.5138 28.6966 73.8078 27.4213 72.5113C26.1455 73.808 24.7766 74.5136 23.3519 74.5138C16.4493 74.5138 10.8536 57.9869 10.8538 37.6002C10.8536 17.2137 16.4491 0.686768 23.3519 0.686768Z"
      fill="#FBBD74"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.1189 67.3823C18.8181 67.3823 15.3315 54.0485 15.3315 37.6003C15.3315 21.1522 18.8181 7.81836 23.1189 7.81836C27.4197 7.81836 30.9062 21.1522 30.9062 37.6003C30.9062 54.0485 27.4197 67.3823 23.1189 67.3823Z"
      fill="#E49307"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.9062 37.6003C30.9062 49.3741 29.1198 59.5514 26.527 64.3853C23.9342 59.5511 22.1473 49.3739 22.1475 37.6006C22.1473 25.8271 23.934 15.6498 26.527 10.8154C29.1198 15.6498 30.9062 25.8268 30.9062 37.6003Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.6406 29.8979C14.1824 29.8979 11.3789 27.0945 11.3789 23.6362C11.3789 20.178 14.1824 17.3745 17.6406 17.3745C21.0989 17.3745 23.9023 20.178 23.9023 23.6362C23.9023 27.0945 21.0989 29.8979 17.6406 29.8979Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.41088 40.3667C7.31864 40.3667 5.62255 38.6706 5.62255 36.5784C5.62255 34.4861 7.31864 32.79 9.41088 32.79C11.5031 32.79 13.1992 34.4861 13.1992 36.5784C13.1992 38.6706 11.5031 40.3667 9.41088 40.3667Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.3098 34.8237C29.8521 34.8235 30.3774 34.8959 30.877 35.0302C30.8961 35.8772 30.9062 36.7343 30.9062 37.6004C30.9062 40.8023 30.7736 43.8858 30.5287 46.7765C30.1357 46.8572 29.7273 46.8999 29.3098 46.9001C25.9752 46.8999 23.2716 44.1965 23.2716 40.8619C23.2718 37.5267 25.9752 34.8235 29.3098 34.8237Z"
      fill="#5EBC89"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.0919 13.5694C19.0755 13.6218 19.0616 13.6676 19.05 13.7061H25.549L26.4067 10.8397L24.0186 7.83843C23.2363 7.73239 22.5379 8.02879 21.9223 8.55147C21.2938 9.08509 20.7595 9.84859 20.3257 10.636C19.8924 11.4223 19.5629 12.2257 19.3416 12.8334C19.231 13.137 19.1476 13.3912 19.0919 13.5694Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="0.224853"
    />
    <path
      d="M22.7718 55.2778C21.4679 55.2778 20.4109 54.2208 20.4109 52.9169C20.4109 51.6129 21.4679 50.5559 22.7718 50.5559C24.0758 50.5559 25.1328 51.6129 25.1328 52.9169C25.1328 54.2208 24.0758 55.2778 22.7718 55.2778Z"
      fill="#EE86B7"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.5759 59.6756C10.1788 59.6756 9.04626 58.5431 9.04626 57.146C9.04626 55.749 10.1788 54.6165 11.5759 54.6165C12.9729 54.6165 14.1055 55.749 14.1055 57.146C14.1055 58.5431 12.9729 59.6756 11.5759 59.6756Z"
      fill="#A88FEF"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.8037 29.8515L15.615 33.2505C17.0696 33.1608 18.0652 32.3263 18.7053 31.493C19.0341 31.0648 19.2671 30.6393 19.4178 30.3206C19.484 30.1808 19.5342 30.0619 19.5696 29.9726C19.3409 30.0013 19.0463 30.012 18.7264 30.012C18.3016 30.012 17.8222 29.9929 17.3725 29.9676C16.9226 29.9422 16.5014 29.9105 16.1927 29.8851C16.0383 29.8724 15.912 29.8613 15.8243 29.8534L15.8037 29.8515Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="0.224853"
    />
    <path
      d="M21.6285 55.1459C21.6411 55.1759 21.6559 55.2093 21.6729 55.2453C21.7518 55.4122 21.8796 55.6374 22.0743 55.8687C22.4464 56.3105 23.0653 56.778 24.067 56.9022L23.98 55.5077L21.6285 55.1459Z"
      fill="#4F3014"
      stroke="#4F3014"
      strokeWidth="0.224853"
    />
    <path
      d="M14.6355 44.9456C14.429 45.4691 13.8316 46.0663 13.3084 46.2723L11.1363 47.1292C10.6124 47.336 10.6124 47.6742 11.1363 47.8802L13.3084 48.7373C13.8316 48.9433 14.429 49.5405 14.6355 50.0649L15.4924 52.2365C15.6988 52.7595 16.0368 52.7595 16.2432 52.2365L17.0999 50.0649C17.3065 49.5405 17.903 48.9433 18.4265 48.7373L20.5993 47.8802C21.1232 47.6742 21.1232 47.336 20.5993 47.1292L18.4265 46.2723C17.903 46.0663 17.3065 45.4691 17.0999 44.9456L16.2432 42.7731C16.0368 42.2501 15.6988 42.2501 15.4924 42.7731L14.6355 44.9456Z"
      fill="#FFD29F"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M3.60954 24.36C3.45754 24.746 3.01728 25.1861 2.63165 25.3376L1.03115 25.9692C0.645299 26.1212 0.645299 26.3706 1.03115 26.5226L2.63165 27.1537C3.01728 27.3057 3.45754 27.7462 3.60954 28.1323L4.24094 29.7321C4.39294 30.1178 4.64185 30.1178 4.79407 29.7321L5.42524 28.1323C5.57747 27.7462 6.01705 27.3057 6.40268 27.1537L8.00341 26.5226C8.38949 26.3706 8.38949 26.1212 8.00341 25.9692L6.40268 25.3376C6.01705 25.1861 5.57747 24.746 5.42524 24.36L4.79407 22.7597C4.64185 22.3741 4.39294 22.3741 4.24094 22.7597L3.60954 24.36Z"
      fill="#FBBD74"
      stroke="#4F3014"
      strokeWidth="1.12427"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
