import dayjs from "dayjs";
import { EventDirectoryItem } from "../../types/helper.types";
import { eventIsAllDayOrMultiDayEvent } from "../../utils/events.utils";
import {
  getReadableTimezoneName,
  getStandardTimezoneFromReadable,
} from "../../utils/time.utils";
import { Pill, PillGroup } from "../basic/Pill";

type Props = {
  item: EventDirectoryItem;
  displayTimezone?: boolean;
};

const EventDirectoryItemDatesAsPills = (props: Props) => {
  const timezoneId =
    props.item.place?.timezone?.id ||
    (props.item.timezone
      ? getStandardTimezoneFromReadable(props.item.timezone)
      : null);
  const timezoneName = props.item.place?.timezone?.name
    ? props.item.place.timezone.name.replace(" Daylight ", " ")
    : timezoneId
    ? getReadableTimezoneName(timezoneId)
    : "";
  const isVirtualEvent =
    props.item.type === "webinar" ||
    props.item.categories.find(c => c.slug === "virtual");
  const shouldDisplayTimezone =
    (props.displayTimezone || isVirtualEvent) && timezoneName;
  const currentYear = `${new Date().getFullYear()}`;
  let start = dayjs(props.item.timeStart).tz();
  let end = dayjs(props.item.timeEnd || props.item.timeStart).tz();
  if (timezoneId) {
    start = start.tz(timezoneId);
    end = end.tz(timezoneId);
  }
  const startYear = start.format("YYYY");
  const endYear = end.isValid() ? end.format("YYYY") : startYear;
  const startsInThisYear = startYear === currentYear;
  const endsInThisYear = endYear === currentYear;
  const startDate = start.format(startsInThisYear ? "MMM D" : "MMM D, YYYY");
  const endDate = end.isValid()
    ? end.format(endsInThisYear ? "MMM D" : "MMM D, YYYY")
    : "";
  const startTime = start.format(start.minute() !== 0 ? "h:mmA" : "hA");
  const endTime = end.isValid()
    ? end.format(end.minute() !== 0 ? "h:mmA" : "hA")
    : "";
  const startAndEndInSameDay = end.isValid() ? start.isSame(end, "date") : true;
  return (
    <PillGroup allCaps>
      {eventIsAllDayOrMultiDayEvent(props.item) ? (
        <>
          <Pill dark>{startDate}</Pill>
          {!startAndEndInSameDay && (
            <>
              <Pill circle>
                <Arrow />
              </Pill>
              <Pill dark>{endDate}</Pill>
            </>
          )}
        </>
      ) : (
        <>
          <Pill dark>{startDate}</Pill>
          <Pill noLetterSpacing>
            {startTime}
            {startAndEndInSameDay && startTime !== endTime && `–${endTime}`}
          </Pill>
          {!startAndEndInSameDay && (
            <>
              <Pill circle>
                <Arrow />
              </Pill>
              <Pill noLetterSpacing>
                {`${!startAndEndInSameDay ? endDate : ""} ${endTime}`}
              </Pill>
            </>
          )}
          {shouldDisplayTimezone && <Pill>{` ${timezoneName}`}</Pill>}
        </>
      )}
    </PillGroup>
  );
};

export default EventDirectoryItemDatesAsPills;

const Arrow = () => (
  <svg width="8" height="7" viewBox="0 0 8 7" fill="currentColor">
    <path d="M4.44172 0L8 3.5L4.45399 7L3.65644 6.2128L5.74233 4.14187H0V2.87024H5.7546L3.64417 0.799308L4.44172 0Z" />
  </svg>
);
