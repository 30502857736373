import { styled } from "@linaria/react";
import { rSize } from "../../../styles/responsiveSizes.styles";
import HeroSection from "../../sections/HeroSection";
import { scrollToHash } from "../../../utils/anchorLinkScroll.utils";
import Button, { ButtonGroupCenteredOnMobile } from "../../forms/Button";
import {
  KnownDocumentName,
  recordDocumentEvent,
} from "../../../utils/documents.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import Spacing from "../../layout/Spacing";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { words } from "../../../utils/string.utils";
import { SOC2023HeroIllustrationHeaven } from "./SOC2023HeroIllustrationHeaven";
import { widerPageSectionMaxWidth } from "../../layout/WiderPageSection";
import { colors } from "../../../styles/colors.styles";
import { SOC2023HeroIllustrationHell } from "./SOC2023HeroIllustrationHell";
import { Paths } from "../../../utils/pathBuilders.utils";
import { resetFontDisplayRulesForSerif } from "../../../styles/fonts.styles";
import {
  fromReportV2DesktopDesign,
  inReportV2MobileDesign,
} from "../components/reportsV2.helpers";

const Grid = styled.div`
  padding-top: 1em;
  padding-bottom: 3em;
  ${fromReportV2DesktopDesign} {
    padding-top: 2.5em;
    display: grid;
    grid-gap: ${rSize("gap")};
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "${words(".", 4)} ${words("body", 5)} ${words(
      ".",
      2
    )}";
  }
`;

const SOC2023HeroSectionContent = styled.div`
  grid-area: body;
  text-align: center;
  ${fromReportV2DesktopDesign} {
    text-align: left;
  }
  h1 {
    font-size: 6rem;
    line-height: 1;
    font-weight: 300;
    white-space: nowrap;
    ${onlyPhones} {
      ${resetFontDisplayRulesForSerif}
    }
    ${fromTablet} {
      font-size: 8.2rem;
      font-weight: 200;
      line-height: 0.9;
      margin-left: -00.0375em;
    }
    ${fromDesktopMd} {
      font-size: 11rem;
      font-weight: 165;
      line-height: 0.85;
    }
  }
  p {
    max-width: none;
    margin-left: auto;
    ${inReportV2MobileDesign} {
      max-width: 22em;
      margin-right: auto;
    }
  }
  --ac: ${colors.purple800};
`;

const IllustrationOuter = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  padding-bottom: 1000px;
  box-sizing: content-box;
  svg {
    display: block;
    overflow: visible;
    height: auto;
  }
`;

const IllustrationInner = styled.div`
  max-width: ${widerPageSectionMaxWidth}px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100%;
`;

const IllustrationHeavenWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  max-width: 43%;
  ${fromPhoneLg} {
    max-width: 38%;
  }
  ${fromTablet} {
    max-width: 35%;
  }
  svg {
    width: ${(917 / 500) * 100}%;
  }
`;

const IllustrationHellWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: ${rSize("pageMargin", 2)};
  width: 533px;
  max-width: 43%;
  ${fromTablet} {
    max-width: 40%;
  }
  display: flex;
  justify-content: flex-end;
  svg {
    width: ${(980 / 533) * 100}%;
    transform: translate(1%, ${(505 / 1000) * 100}%);
  }
`;

const SOC2023HeroSection = () => {
  const scrollToIntro = () => {
    scrollToHash({
      useHash: "#a-word-from-eoin-hinchy",
      doNotPushState: true,
    });
  };
  useOnMount(() => {
    recordDocumentEvent({
      type: "view",
      identifier: KnownDocumentName.soc2023,
    });
  });
  const handleDownloadButtonClick = () => {
    recordDocumentEvent({
      type: "download",
      identifier: KnownDocumentName.soc2023,
    });
  };
  return (
    <HeroSection
      contentRelative
      backdrop="dots"
      backdropPositionBottom={rSize("pageMargin", -2)}
      backdropLayerChildren={
        <IllustrationOuter>
          <IllustrationInner>
            <IllustrationHeavenWrap>
              <SOC2023HeroIllustrationHeaven />
            </IllustrationHeavenWrap>
            <IllustrationHellWrap>
              <SOC2023HeroIllustrationHell />
            </IllustrationHellWrap>
          </IllustrationInner>
        </IllustrationOuter>
      }
    >
      <Grid>
        <SOC2023HeroSectionContent>
          <p>
            <strong>2023</strong>
          </p>
          <Spacing />
          <h1>
            <span>Voice of </span>
            <span>the SOC</span>
          </h1>
          <p>
            Discover insights and recommendations from our survey of 900
            security professionals – and the takeaways for leadership.
          </p>
          <Spacing size="1.5em" />
          <ButtonGroupCenteredOnMobile>
            <Button appearance="filled" onClick={scrollToIntro}>
              Start reading
            </Button>
            <Button
              appearance="outlined"
              download
              onClick={handleDownloadButtonClick}
              href={Paths.reportSoc2023Pdf()}
            >
              Download PDF
            </Button>
          </ButtonGroupCenteredOnMobile>
        </SOC2023HeroSectionContent>
      </Grid>
    </HeroSection>
  );
};

export default SOC2023HeroSection;
