import { styled } from "@linaria/react";
import { cx } from "linaria";
import { ReactNode } from "react";
import {
  getWidthPxInMaxGrid,
  widthInGrid,
} from "../../constants/globalGrid.constants";
import {
  fromDesktop,
  fromDesktopLg,
  fromDesktopMl,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = React.PropsWithChildren<{
  content?: ReactNode;
  aside?: ReactNode;
  asidePositionOnMobile?: "start" | "end";
  asidePositionOnTablet?: "start" | "end";
  asidePositionOnDesktop?: "start" | "end";
  centered?: boolean;
  shouldPadRightOnDesktopLg?: boolean;
}>;

const WebinarPageContentGridSection = styled.section<Props>`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: ${p =>
    p.aside
      ? p.asidePositionOnMobile === "start"
        ? `"aside" "content"`
        : `"content" "aside"`
      : `"content"`};
  grid-gap: 1em;
  ${fromTabletMd} {
    grid-gap: 4em;
    grid-template-columns: ${p =>
      p.asidePositionOnTablet === "start"
        ? "16em minmax(0, 1fr)"
        : "minmax(0, 1fr) 14.5em"};
    grid-template-areas: ${p =>
      p.asidePositionOnTablet === "start"
        ? `"aside content"`
        : `"content aside"`};
    &.centered {
      grid-template-columns: 4em minmax(0, 1fr) 4em;
      grid-template-areas: ". content aside";
    }
  }
  ${fromDesktop} {
    grid-gap: 5em;
  }
  ${fromDesktopMl} {
    grid-template-columns: 4em minmax(0, 1fr) 14.5em;
    grid-template-areas: "aside-start content aside-end";
    grid-gap: 7em;
    &.centered {
      grid-template-columns: 9em minmax(0, 1fr) 9em;
      grid-template-areas: "aside-start content aside-end";
    }
  }
  ${fromDesktopLg} {
    grid-template-columns: ${widthInGrid(2)} minmax(0, 1fr) ${widthInGrid(3)};
    grid-template-areas: "aside-start content aside-end";
    grid-gap: ${rSize("gap")};
    &.centered {
      grid-template-columns: ${widthInGrid(3)} minmax(0, 1fr) ${widthInGrid(3)};
      grid-template-areas: "aside-start content aside-end";
    }
  }
  .SimpleCTACard {
    ${onlyPhones} {
      display: none;
    }
  }
`;

const ContentContainer = styled.div<{ shouldPadRightOnDesktopLg: boolean }>`
  grid-area: content;
  ${fromDesktopLg} {
    padding-right: ${p =>
      p.shouldPadRightOnDesktopLg ? getWidthPxInMaxGrid(1, true) : ""};
  }
`;

const AsideContainer = styled.aside<{ position: "start" | "end" }>`
  grid-area: aside;
  ${fromDesktopMl} {
    grid-area: ${p => `aside-${p.position}`};
  }
`;

const WebinarPageContentGrid = (props: Props) => {
  return (
    <WebinarPageContentGridSection
      {...props}
      className={cx(props.centered && "centered")}
    >
      <ContentContainer
        shouldPadRightOnDesktopLg={
          !!(
            props.shouldPadRightOnDesktopLg ||
            (!props.centered && props.aside)
          )
        }
      >
        {props.content}
        {props.children}
      </ContentContainer>
      {props.aside && (
        <AsideContainer position={props.asidePositionOnDesktop ?? "end"}>
          {props.aside}
        </AsideContainer>
      )}
    </WebinarPageContentGridSection>
  );
};

export default WebinarPageContentGrid;
