import { CSSProperties } from "react";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
} from "../../../styles/colorsV4.styles";
import { css } from "linaria";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";

export const CourseCompletionIcon = ({
  color: colorName = "purple",
}: {
  color?: BrandColorNameV4;
}) => {
  const c700 = colorV4(colorName, 700);
  const c500 = colorV4(colorName, 500);
  const c300 = colorV4(colorName, 300);
  const c100 = colorV4(colorName, 100);
  return (
    <svg
      width="90"
      height="75"
      viewBox="0 0 90 75"
      fill="none"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      style={
        {
          "--shadow-color": c700,
        } as CSSProperties
      }
      className={css`
        ${darkModeLinariaCSS(
          `--shadow-color: ${colorsV4.warmBlack800} !important;`
        )}
      `}
    >
      <path
        d="M89.0001 53.2421H30.4743L6.09473 74.3029H67.638L89.0001 53.2421Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
      />
      <path
        d="M83.9056 46.5183H25.379L1 67.5794V74.211H62.5435L83.9056 53.1502V46.5183Z"
        fill={c500}
        stroke="var(--shadow-color)"
      />
      <path
        d="M83.9056 46.5183H25.379L1 67.5794H62.5435L83.9056 46.5183Z"
        fill={c300}
        stroke="var(--shadow-color)"
      />
      <path
        d="M72.9317 49.7589H26.239V60.9569H60.4577L72.9317 49.7589Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
      />
      <path d="M62.5432 74.211V67.5793" stroke="var(--shadow-color)" />
      <path
        d="M64.8823 41.194H37.8063C37.4677 41.194 37.1588 41.3213 36.925 41.5314C36.657 41.7726 24.4504 51.9356 24.2127 52.1736C23.9738 52.4122 23.8267 52.7412 23.8267 53.1046V59.64C23.8267 60.3671 24.4163 60.9564 25.1437 60.9564H52.2211C52.5741 60.9564 52.8934 60.8183 53.1306 60.5921C53.3822 60.3521 65.6292 50.1899 65.8706 49.9166C66.0748 49.6848 66.1987 49.3801 66.1993 49.0457V42.511C66.1993 41.7833 65.6103 41.194 64.8823 41.194Z"
        fill={c500}
        stroke="var(--shadow-color)"
      />
      <path
        d="M53.5381 59.6404C53.5381 60.3675 52.9479 60.9568 52.2211 60.9568H25.1437C24.4163 60.9568 23.8267 60.3675 23.8267 59.6404V53.105C23.8267 52.3779 24.4163 51.7886 25.1437 51.7886H52.2211C52.9479 51.7886 53.5381 52.3779 53.5381 53.105V59.6404Z"
        fill={c100}
        stroke="var(--shadow-color)"
      />
      <path d="M53.1428 52.166L65.8792 41.652" stroke="var(--shadow-color)" />
      <path
        d="M64.8823 32.4403H37.8063C37.4677 32.4403 37.1588 32.5677 36.925 32.7778C36.657 33.0189 24.4504 43.1819 24.2127 43.42C23.9738 43.6586 23.8267 43.9875 23.8267 44.351V50.8863C23.8267 51.6134 24.4163 52.2027 25.1437 52.2027H52.2211C52.5741 52.2027 52.8934 52.0647 53.1306 51.8385C53.3822 51.5985 65.6292 41.4363 65.8706 41.1629C66.0748 40.9311 66.1987 40.6264 66.1993 40.2921V33.7573C66.1993 33.0296 65.6103 32.4403 64.8823 32.4403Z"
        fill={c500}
        stroke="var(--shadow-color)"
      />
      <path
        d="M53.5381 50.8867C53.5381 51.6138 52.9479 52.2031 52.2211 52.2031H25.1437C24.4163 52.2031 23.8267 51.6138 23.8267 50.8867V44.3514C23.8267 43.6242 24.4163 43.0349 25.1437 43.0349H52.2211C52.9479 43.0349 53.5381 43.6242 53.5381 44.3514V50.8867Z"
        fill={c300}
        stroke="var(--shadow-color)"
      />
      <path d="M53.1428 43.4123L65.8792 32.8983" stroke="var(--shadow-color)" />
      <path
        d="M64.8823 23.6866H37.8063C37.4677 23.6866 37.1588 23.814 36.925 24.0241C36.657 24.2652 24.4504 34.4283 24.2127 34.6663C23.9738 34.9049 23.8267 35.2339 23.8267 35.5973V42.1326C23.8267 42.8598 24.4163 43.4491 25.1437 43.4491H52.2211C52.5741 43.4491 52.8934 43.311 53.1306 43.0848C53.3822 42.8448 65.6292 32.6826 65.8706 32.4093C66.0748 32.1774 66.1987 31.8727 66.1993 31.5384V25.0037C66.1993 24.276 65.6103 23.6866 64.8823 23.6866Z"
        fill="#EBA96D"
        stroke="var(--shadow-color)"
      />
      <path
        d="M53.5381 42.133C53.5381 42.8602 52.9479 43.4495 52.2211 43.4495H25.1437C24.4163 43.4495 23.8267 42.8602 23.8267 42.133V35.5977C23.8267 34.8706 24.4163 34.2812 25.1437 34.2812H52.2211C52.9479 34.2812 53.5381 34.8706 53.5381 35.5977V42.133Z"
        fill="#FFD18C"
        stroke="var(--shadow-color)"
      />
      <path d="M53.1428 34.6587L65.8792 24.1447" stroke="var(--shadow-color)" />
      <path
        d="M45.0577 28.8963C47.0096 28.8963 48.5919 28.4565 48.5919 27.9139C48.5919 27.3714 47.0096 26.9315 45.0577 26.9315C43.1058 26.9315 41.5234 27.3714 41.5234 27.9139C41.5234 28.4565 43.1058 28.8963 45.0577 28.8963Z"
        fill="var(--shadow-color)"
        stroke="var(--shadow-color)"
      />
      <path
        d="M46.8314 27.3006C46.8314 28.2813 46.0357 28.767 45.055 28.767C44.0737 28.767 43.2786 28.281 43.2786 27.3006V2.77615C43.2786 1.79518 44.0737 1 45.055 1C46.0357 1 46.8314 1.79489 46.8314 2.77615V27.3006Z"
        fill={c100}
        stroke="var(--shadow-color)"
      />
      <path
        d="M61.9121 13.7029H43.3035V3.2146H61.9121L56.922 8.45891L61.9121 13.7029Z"
        fill={c300}
        stroke="var(--shadow-color)"
      />
      <path
        d="M46.898 13.592H42.9761V15.8991H46.898V13.592Z"
        fill="var(--shadow-color)"
      />
    </svg>
  );
};
