import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import SkyTunnelIcon from "../illustrations/spots/SkyTunnelIcon";
import {
  fromDesktopMl,
  fromTablet,
  onlyPhones,
  uptoPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  BrandColorNameV4,
  colorV4,
  colorsV4,
} from "../../styles/colorsV4.styles";
import { fluidFontSize, fluidLineHeight } from "../../styles/helpers.styles";
import { maxPageContentWidthStyleObject } from "../../styles/maxPageContentWidth.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import ComboFontHeading from "../typography/ComboFontHeading";
import { FromTabletSpan } from "../mediaQueries/FromTablet";
import Spacing from "../layout/Spacing";
import { UptoTabletSpan } from "../mediaQueries/UptoTablet";
import SignUpButton from "../utilities/SignUpButton";

type Props = {
  brandColorName?: BrandColorNameV4;
};

const SimpleCTASectionContainer = styled.section``;

const Inner = styled.div`
  ${maxPageContentWidthStyleObject};
`;

const Banner = styled.div<Props>`
  display: grid;
  grid-gap: 2em;
  background-color: ${p => colorV4(p.brandColorName ?? colorsV4.purple)};
  border-radius: 1em;
  color: ${colorsV4.white};
  padding: ${rSize("cardSectionPadding")};
  border-radius: ${rSize("radius")};
  overflow: hidden;
  ${onlyPhones} {
    grid-template-columns: minmax(10em, 1fr) auto;
  }
  ${fromTablet} {
    grid-template-columns: minmax(21em, 1fr) auto;
    align-items: center;
  }
  ${fromDesktopMl} {
    display: block;
    position: relative;
  }
`;

const Header = styled.header`
  ${fromDesktopMl} {
    text-align: center;
  }
`;
const IconWrapper = styled.div`
  svg {
    max-height: 20rem;
  }
  ${uptoPhoneLg} {
    padding-top: 4em;
    margin-right: -12.5%;
    svg {
      max-height: 18rem;
    }
  }
  ${fromDesktopMl} {
    position: absolute;
    right: ${rSize("cardSectionPadding")};
    top: 50%;
    transform: translateY(-50%);
  }
`;

const SimpleCTASection = (props: Props) => {
  return (
    <SimpleCTASectionContainer>
      <Inner>
        <Banner {...props}>
          <Header>
            <ComboFontHeading
              noWrap
              snippets={[
                [
                  <>
                    No-code{" "}
                    <UptoTabletSpan>
                      <br />
                    </UptoTabletSpan>{" "}
                    automation
                    <FromTabletSpan>
                      <br />
                    </FromTabletSpan>
                  </>,
                  "sans",
                ],
                [
                  <>
                    for{" "}
                    <UptoTabletSpan>
                      <br />
                    </UptoTabletSpan>{" "}
                    security&nbsp;teams
                  </>,
                  "serif",
                ],
              ]}
              className={css`
                ${fluidFontSize(28, 48)}
                ${fluidLineHeight(30, 48)};
              `}
            />
            <Spacing size="md" />
            <SignUpButton
              color={props.brandColorName}
              appearance="filled-white"
            >
              Get started
            </SignUpButton>
          </Header>
          <IconWrapper>
            <SkyTunnelIcon />
          </IconWrapper>
        </Banner>
      </Inner>
    </SimpleCTASectionContainer>
  );
};

export default SimpleCTASection;
