import { styled } from "@linaria/react";
import { DatoCmsSubmitStoryCtaSection } from "../../../../graphql-types";
import Button from "../../forms/Button";
import { Serif } from "../../typography/Serif";
import SpotIllustration from "../../illustrations/SpotIllustration";
import RigidDotGrid from "../../decorations/RigidDotGrid";
import { withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  fromDesktopMd,
  fromTabletLg,
  fromTabletMd,
  uptoTabletMd,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import Spacing from "../../layout/Spacing";
import { useOpenStoryRequestModalFunction } from "../../modals/StoryRequestModal";
import { darkModeLinariaCSS } from "../../../utils/colorScheme.utils";
import { colors } from "../../../styles/colors.styles";

type Props = {
  section: DatoCmsSubmitStoryCtaSection;
};

const SectionWrap = styled.div`
  ${uptoTabletMd} {
    display: grid;
    grid-template-areas: "image" "textContent";
  }
  ${fromTabletMd} {
    display: flex;
    align-items: center;
  }
  position: relative;
  border: 1px dashed ${withOpacity(colors.purple, 0.3)};
  border-radius: ${rSize("radius")};
  padding: 2em ${rSize("xl")};
  ${fromTabletLg} {
    padding: 3em ${rSize("xl")};
  }
  ${fromDesktopMd} {
    padding: 4.5em ${rSize("xl")};
  }
  color: ${colors.purple800};
  ${darkModeLinariaCSS(`
    background-color: ${withOpacity(colors.purple, 0.025)};
    color: ${colors.purple300};
  `)}
  h2 {
    max-width: 15em;
    font-size: 2.8rem;
    ${fromTabletLg} {
      font-size: 3.4rem;
    }
  }
  .Button {
    font-size: inherit;
  }
`;

const TextContent = styled.div`
  position: relative;
  ${uptoTabletMd} {
    text-align: center;
    grid-area: textContent;
    padding-bottom: 1em;
  }
  ${fromTabletMd} {
    flex: 1 1 auto;
    padding-right: 2em;
  }
`;

const IllustrationWrap = styled.div`
  ${uptoTabletMd} {
    grid-area: image;
    margin-left: auto;
    margin-right: auto;
    margin-top: -1em;
    padding-right: 1em;
  }
  ${fromTabletMd} {
    flex: 0 0 auto;
  }
`;

const SubmitStoryCtaSection = () => {
  const openModal = useOpenStoryRequestModalFunction();
  return (
    <SectionWrap id="submit-story">
      <RigidDotGrid color={withOpacity(colors.purple, 0.2)} />
      <TextContent>
        <h2>
          <Serif>Submit your story</Serif>
        </h2>
        <Spacing size="1em" />
        <p>We’d love to hear your ideas or see what you’ve created.</p>
        <Spacing size="1.5em" />
        <Button color={colors.purple800} onClick={openModal}>
          Get in touch
        </Button>
      </TextContent>
      <IllustrationWrap>
        <SpotIllustration name="storyboard" />
      </IllustrationWrap>
    </SectionWrap>
  );
};

export default SubmitStoryCtaSection;
