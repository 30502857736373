import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { styled } from "@linaria/react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  CSSProperties,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { cx } from "linaria";
import {
  fromDesktop,
  fromDesktopLg,
  fromDesktopMd,
  fromDesktopMl,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import img1 from "./images/tines-federal-example-story-1.png";
import img2 from "./images/tines-federal-example-story-2.png";
import img3 from "./images/tines-federal-example-story-3.png";
import img4 from "./images/tines-federal-example-story-4.png";
import img5 from "./images/tines-federal-example-story-5.png";
import { FedIdentityIcon } from "./iconsAndIllustrations/FedIdentityIcon";
import { FedDevicesIcon } from "./iconsAndIllustrations/FedDevicesIcon";
import gsap from "gsap";
import { FederalIcon } from "../home/spotIcons/FederalIcon";
import { FedNetworksIcon } from "./iconsAndIllustrations/FedNetworksIcon";
import { FedAppsAndWorkloadsIcon } from "./iconsAndIllustrations/FedAppsAndWorkloadsIcon";
import { FedDataIcon } from "./iconsAndIllustrations/FedDataIcon";

const defs = [
  {
    title: "Identity",
    bg: colors.green50,
    bgDarker: colors.green100,
    fg: colors.green800,
    primary: colors.green,
    border: colors.green100,
    borderDarker: colors.green200,
    description:
      "Enforce dynamic access control or multifactor authentication policies, and automate response to identity-based vulnerabilities and attacks.",
    img: img1,
    icon: <FedIdentityIcon />,
  },
  {
    title: "Devices",
    bg: colors.orange50,
    bgDarker: colors.orange100,
    fg: colors.orange800,
    primary: colors.orange,
    border: colors.orange100,
    borderDarker: colors.orange200,
    description:
      "Automate device profiling, endpoint security, and threat intelligence in a bring-your-own-device economy.",
    img: img2,
    icon: <FedDevicesIcon />,
  },
  {
    title: "Networks",
    bg: colors.purple50,
    bgDarker: colors.purple100,
    fg: colors.purple800,
    primary: colors.purple,
    border: colors.purple100,
    borderDarker: colors.purple200,
    description:
      "Prevent unauthorized access and lateral movement across your networks. Dynamically segment networks, automate forensic data collection, and more.",
    img: img3,
    icon: <FedNetworksIcon />,
  },
  {
    title: "Apps and workloads",
    bg: colors.pink50,
    bgDarker: colors.pink100,
    fg: colors.pink800,
    primary: colors.pink,
    border: colors.pink100,
    borderDarker: colors.pink200,
    description:
      "Monitor application and workload security with dynamic white and blacklisting, just-in-time access, and more.",
    img: img4,
    icon: <FedAppsAndWorkloadsIcon />,
  },
  {
    title: "Data",
    bg: colors.light100,
    bgDarker: colors.light200,
    fg: colors.dark500,
    primary: colors.dark500,
    border: colors.light300,
    borderDarker: colors.light400,
    description:
      "Bring data management and security programs up to date with automated tagging, just-in-time access, and real-time DLP policy enforcement.",
    img: img5,
    icon: <FedDataIcon />,
  },
];

const Inner = styled.div`
  h2 {
    ${fromDesktop} {
      max-width: ${widthInGrid(8)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Desc = styled.p`
  max-width: ${widthInGrid(7)};
  font-size: 1.6rem;
  margin: auto;
`;

const Grid = styled.div`
  ${uptoTabletMd} {
    display: none;
  }
  ${fromTabletMd} {
    display: grid;
    position: relative;
    grid-gap: 0.5em;
    grid-template-columns: repeat(5, 1fr);
    z-index: 10;
  }
  ${fromDesktopMd} {
    grid-gap: ${rSize("gap")};
  }
`;

const Chevron = styled.div`
  position: absolute;
  left: 50%;
  top: calc(100% + ${rSize("gap")} + 2px);
  transform: translate(-50%, -100%);
  transition: 0.2s;
  svg {
    display: block;
    transition: opacity 0.5s, transform 0.2s;
    path {
      &:nth-child(1) {
        fill: var(--border);
      }
      &:nth-child(2) {
        fill: var(--bg);
      }
    }
  }
`;

const GridItem = styled.button`
  position: relative;
  appearance: none;
  margin: 0;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  grid-gap: ${rSize("gap")};
  padding: 0.75em;
  border-radius: 0.5em;
  ${fromDesktop} {
    padding: 1em;
  }
  ${fromDesktopMd} {
    padding: ${rSize("gap")};
    border-radius: ${rSize("radius")};
  }
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  background-color: var(--bg);
  cursor: pointer;
  &:hover {
    border-color: var(--borderDarker);
  }
  ${fromTabletMd} {
    aspect-ratio: 1/1;
  }
  span {
    display: block;
    font-family: ${serif};
    margin-top: -0.1em;
    line-height: 1;
    font-weight: 300;
    font-size: 2.2rem;
    ${fromDesktop} {
      font-size: 2.8rem;
    }
    ${fromDesktopMd} {
      font-size: 4.2rem;
    }
  }
  div {
    font-family: ${serif};
    font-weight: 400;
    font-size: 1.6rem;
    ${fromDesktop} {
      font-size: 1.8rem;
    }
    ${fromDesktopMd} {
      font-size: 2.2rem;
    }
  }
  &.inactive {
    ${Chevron} {
      opacity: 0;
      svg {
        transform: translateY(11px);
      }
    }
  }
  &.active {
    background-color: var(--bgDarker);
  }
`;

const Contents = styled.div`
  position: relative;
  ${fromTabletMd} {
    height: 32rem;
  }
  ${fromTabletLg} {
    height: 35rem;
  }
  ${fromDesktop} {
    height: 38rem;
  }
  ${fromDesktopMd} {
    height: 48rem;
  }
  ${fromDesktopLg} {
    height: 56.5rem;
  }
`;

const ContentCard = styled.figure`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "text" "img";
  grid-gap: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  padding-top: 1.5em;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  ${uptoTabletMd} {
    overflow: hidden;
    + * {
      margin-top: 3em;
    }
  }
  ${fromTabletMd} {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: minmax(auto, 1fr) minmax(0, auto);
    grid-template-areas: "img text" "img icon";
    padding-top: 3em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    transition: opacity 0.5s;
    &.inactive {
      opacity: 0;
      z-index: 1;
      pointer-events: none;
    }
    &.active {
      opacity: 1;
      z-index: 1;
    }
  }
  ${fromDesktopMd} {
    padding-top: 4em;
  }
`;

const ContentCardVisual = styled.div`
  grid-area: img;
  overflow: hidden;
  ${fromTabletMd} {
    border-top: inherit;
    border-right: inherit;
    border-radius: 0 ${rSize("radius")} 0 ${rSize("radius")};
  }
  img {
    display: block;
    width: 138%;
    ${fromPhoneLg} {
      width: 120%;
    }
    ${fromTablet} {
      width: 100%;
    }
  }
`;

const ContentCardText = styled.figcaption`
  grid-area: text;
  padding: 0.5em 1em 1em;
  ${fromPhoneLg} {
    padding: 0.5em 2em 1em;
  }
  ${mediaFromTo("tabletMd", "desktop")} {
    padding-left: 0.5em;
  }
  ${fromTabletMd} {
    padding: 0 1.5em;
  }
  h3 {
    font-family: ${serif};
    line-height: 1.15;
    font-size: 2.4rem;
    font-weight: 400;
    ${fromDesktopMd} {
      font-weight: 300;
      line-height: 1;
      letter-spacing: -0.02em;
      font-size: 3.6rem;
    }
  }
  p {
    font-size: 1.4rem;
    margin-top: 1em;
    ${fromTabletMd} {
      margin-right: 2em;
    }
    ${fromDesktopMl} {
      margin-right: 3em;
    }
  }
`;

const ContentCardIconWrap = styled.div`
  grid-area: icon;
  padding: 1em ${rSize("md")} ${rSize("lg")};
  display: none;
  ${fromDesktop} {
    display: block;
  }
`;

const ContentCardNextButton = styled.button`
  ${uptoTabletMd} {
    display: none;
  }
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  display: block;
  position: absolute;
  left: calc(100% + 0.5px);
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  svg {
    display: block;
  }
  circle:first-of-type {
    transition: 0.1s;
  }
  &:hover {
    circle:first-of-type {
      fill: var(--buttonHover);
    }
  }
`;

const ProgressCircle = styled.circle`
  stroke-dasharray: 191.54; /* 2 * π * radius = 2 * 3.14 * 31.5 = 191.54 */
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  transform-origin: 38.5px 43.5px;
  stroke: var(--primary);
  opacity: 0;
  .autoplaying .active & {
    opacity: 0.5;
    stroke-width: 2;
  }
`;

const FedFiveColSection = () => {
  const [currentIndexRef, setCurrentIndex, currentIndex] = useStateWithRef(0);

  const visibilityDetectionRef = useRef<HTMLDivElement>(null);

  const [hasManuallyPaused, setHasManuallyPaused] = useState(false);
  const [isAutoplaying, setIsAutoplaying] = useState(false);
  const isAutoplayingRef = useRef(isAutoplaying);
  isAutoplayingRef.current = isAutoplaying;

  const tweenRef = useRef<GSAPTween>(null) as MutableRefObject<GSAPTween>;

  const next = () => {
    setCurrentIndex(
      currentIndexRef.current >= defs.length - 1
        ? 0
        : currentIndexRef.current + 1
    );
    if (isAutoplayingRef.current) startAnimation();
  };

  const pause = () => {
    tweenRef.current?.pause();
    setIsAutoplaying(false);
  };

  const startAnimation = () => {
    if (window.innerWidth < 768) {
      pause();
      return;
    }
    setTimeout(() => {
      tweenRef.current?.kill();
      tweenRef.current = gsap.fromTo(
        visibilityDetectionRef.current?.querySelector(
          ".active .progressCircle"
        ) ?? null,
        {
          strokeDashoffset: 191.54,
        },
        {
          strokeDashoffset: 0,
          duration: 10,
          ease: "linear",
          onComplete: () => {
            if (isAutoplayingRef.current) next();
          },
        }
      );
    });
  };

  const goToIndex = (i: number) => {
    setIsAutoplaying(false);
    setCurrentIndex(i);
  };

  const resume = () => {
    setIsAutoplaying(true);
    startAnimation();
  };

  const handleNextButtonClick = () => {
    setIsAutoplaying(false);
    setHasManuallyPaused(true);
    next();
  };
  const handleNextButtonPointerEnter = () => {
    pause();
  };
  const handleNextButtonPointerLeave = () => {
    if (hasManuallyPaused) return;
    else resume();
  };

  useOnMount(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsAutoplaying(true);
        startAnimation();
      } else {
        pause();
      }
    });
    observer.observe(visibilityDetectionRef.current!);
    return () => {
      observer.disconnect();
      tweenRef.current?.kill();
    };
  });

  useEffect(() => {});

  return (
    <PageSection centered className={cx(isAutoplaying && "autoplaying")}>
      <Inner>
        <FederalIcon againstLightBackground />
        <Spacing size="1em" />
        <SectionHeading2 lighter>
          Automation and orchestration for federal agencies
        </SectionHeading2>
        <Spacing size="md" />
        <Desc>
          Meet zero trust (M-22-09), investigation and remediation (M-21-31),
          and evolving federal requirements faster. Tines is flexible enough to
          enforce your custom security policies while still supporting
          consistent rapid response to threats.
        </Desc>

        <Spacing size="xl" />

        <Grid>
          {defs.map((d, i) => (
            <GridItem
              key={i}
              className={cx(i === currentIndex ? "active" : "inactive")}
              onClick={() => goToIndex(i)}
              style={
                {
                  "--bg": d.bg,
                  "--bgDarker": d.bgDarker,
                  color: d.fg,
                  "--border": d.border,
                  "--borderDarker": d.borderDarker,
                } as CSSProperties
              }
            >
              <span>{i + 1}</span>
              <div>{d.title}</div>
              <Chevron>
                <svg width="34" height="11" viewBox="0 0 34 11" fill="none">
                  <path d="M20.5463 1.50105C18.9572 -0.0335529 16.3807 -0.0335522 14.7916 1.50105L6.86261 9.15801C6.30331 9.69812 5.55616 10 4.77864 10H3C1.34315 10 0 11.3431 0 13V20C0 21.6568 1.34315 23 3 23H31C32.6569 23 34 21.6568 34 20V13C34 11.3431 32.6569 10 31 10H30.5593C29.7817 10 29.0346 9.69812 28.4753 9.15801L20.5463 1.50105Z" />
                  <path d="M19.827 2.33392C18.6351 1.18297 16.7028 1.18297 15.5109 2.33392L7.40888 10.158C6.84958 10.6981 6.10243 11 5.3249 11H3C1.34315 11 0 12.3431 0 14V21C0 22.6569 1.34315 24 3 24H31C32.6569 24 34 22.6569 34 21V14C34 12.3431 32.6569 11 31 11H30.013C29.2355 11 28.4883 10.6981 27.929 10.158L19.827 2.33392Z" />
                </svg>
              </Chevron>
            </GridItem>
          ))}
        </Grid>

        <Spacing size="gap" />

        <Contents ref={visibilityDetectionRef}>
          {defs.map((d, i) => (
            <ContentCard
              key={i}
              className={cx(i === currentIndex ? "active" : "inactive")}
              style={
                {
                  backgroundColor: d.bg,
                  color: d.fg,
                  "--border": d.border,
                  "--primary": d.primary,
                  "--buttonHover": d.borderDarker,
                } as CSSProperties
              }
            >
              <ContentCardVisual
                style={{
                  backgroundColor: d.bgDarker,
                }}
              >
                <img src={d.img} />
              </ContentCardVisual>
              <ContentCardText>
                <h3>{d.title}</h3>
                <p>{d.description}</p>
              </ContentCardText>
              <ContentCardIconWrap>{d.icon}</ContentCardIconWrap>
              <ContentCardNextButton
                onClick={handleNextButtonClick}
                onPointerEnter={handleNextButtonPointerEnter}
                onPointerLeave={handleNextButtonPointerLeave}
              >
                <svg width="75" height="88" viewBox="0 0 75 88" fill="none">
                  <path
                    d="M37.9 0H31.5C29.2909 0 27.5 1.79086 27.5 4V5.52178C27.5 7.29446 26.3242 8.83489 24.6706 9.4737C10.8226 14.8235 1 28.2647 1 44C1 59.7353 10.8226 73.1765 24.6706 78.5263C26.3242 79.1651 27.5 80.7055 27.5 82.4782V84C27.5 86.2091 29.2909 88 31.5 88H37.9C37.9552 88 38 87.9552 38 87.9V85C38 82.7909 39.7972 81.0227 41.9937 80.787C60.55 78.7955 75 63.0852 75 44C75 24.9148 60.55 9.20455 41.9937 7.21303C39.7972 6.97729 38 5.20914 38 3V0.1C38 0.0447715 37.9552 0 37.9 0Z"
                    fill={d.border}
                  />
                  <path
                    d="M36.9 0H4.5C2.01472 0 0 2.01472 0 4.5V44V83.5C0 85.9853 2.01472 88 4.5 88H36.9C36.9552 88 37 87.9539 37 87.8987V84.5C37 82.0147 39.024 80.028 41.4923 79.7373C59.8082 77.5804 74 62.4028 74 44C74 25.5972 59.8082 10.4196 41.4923 8.26265C39.024 7.97199 37 5.98528 37 3.5V0.101319C37 0.0460904 36.9552 0 36.9 0Z"
                    fill={d.bg}
                  />
                  <circle cx="38.5" cy="43.5" r="31.5" fill={d.bgDarker} />
                  <ProgressCircle
                    className="progressCircle"
                    cx="38.5"
                    cy="43.5"
                    r="30.5"
                    fill="transparent"
                  />
                  <path
                    d="M41.4082 36L49 43.5L41.4082 51L39.8716 49.6646L45.1236 44.476L30 44.476V42.524L45.1236 42.524L39.8716 37.3354L41.4082 36Z"
                    fill={d.fg}
                  />
                </svg>
              </ContentCardNextButton>
            </ContentCard>
          ))}
        </Contents>
      </Inner>
    </PageSection>
  );
};

export default FedFiveColSection;
