import { DatoCmsEvent } from "../../../graphql-types";
import {
  EventDirectoryItem,
  EventDirectoryItemCategory,
} from "../../types/helper.types";

type Props = {
  event: DatoCmsEvent | EventDirectoryItem;
};

const EventCategoryList = (props: Props) => {
  return (
    <>
      {(props.event.categories as EventDirectoryItemCategory[])
        ?.filter(i => i)
        .map(category => (
          <span key={category?.name}>#{category?.name} </span>
        ))}
    </>
  );
};

export default EventCategoryList;
