import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import SolutionPageAllcapsHeading from "./SolutionPageAllcapsHeading";
import FeaturedClientLogoSet from "../general/FeaturedClientLogoSet";
import {
  fromTabletLg,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";

type Props = {};

const Positioner = styled.div`
  position: relative;
  z-index: 1;
`;

const InnerWrap = styled.div`
  background-color: ${colorsV4.white};
  border-radius: ${rSize("radius")};
  padding: ${rSize("xl")} 1em;
  ${fromTabletLg} {
    position: absolute;
    left: ${rSize("xl", -1)};
    right: ${rSize("xl", -1)};
    top: 50%;
    transform: translateY(-50%);
    padding: ${rSize("xl")};
  }
  > * {
    + * {
      margin-top: ${rSize("lg")};
    }
  }
  h2 {
    ${onlyPhones} {
      text-align: center;
    }
  }
`;

const SolutionPageCustomerLogosSection = (props: Props) => {
  return (
    <PageSection>
      <Positioner>
        <InnerWrap>
          <SolutionPageAllcapsHeading>
            Trusted by industry leaders
          </SolutionPageAllcapsHeading>
          <FeaturedClientLogoSet againstBackground="light" />
        </InnerWrap>
      </Positioner>
    </PageSection>
  );
};

export default SolutionPageCustomerLogosSection;
