import { styled } from "@linaria/react";
import {
  DatoCmsButton,
  DatoCmsCenteredHeadingSection,
} from "../../../../graphql-types";
import { ButtonGroupCenterFlex } from "../../forms/Button";
import PageSection from "../../reusableSections/PageSection";
import { SectionHeading2 } from "../../typography/SectionHeading2";
import { LPButtonGroup } from "../LPButtonGroup";
import { rSize } from "../../../styles/responsiveSizes.styles";

const Contents = styled.div`
  text-align: center;
  margin-bottom: ${rSize("sectionMargin", -0.5)};
  > * {
    margin-left: auto;
    margin-right: auto;
    + * {
      margin-top: ${rSize("gap")};
    }
  }
  h2 {
    max-width: 16em;
  }
  p {
    max-width: 38em;
  }
`;

export const LPCenteredHeadingSection = ({
  section,
}: {
  section: DatoCmsCenteredHeadingSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Contents>
        {section.icon?.image?.url && <img src={section.icon.image.url} />}
        <SectionHeading2 lighter>{section.heading}</SectionHeading2>
        {section.body && <p>{section.body}</p>}
        {!!section.ctaButtons?.length && (
          <ButtonGroupCenterFlex>
            <LPButtonGroup buttons={section.ctaButtons as DatoCmsButton[]} />
          </ButtonGroupCenterFlex>
        )}
      </Contents>
    </PageSection>
  );
};
