import { graphql, useStaticQuery } from "gatsby";
import { AllSitePagesQuery } from "../../graphql-types";

export const useAllSitePages = () => {
  const {
    pages: { nodes },
  } = useStaticQuery<AllSitePagesQuery>(allSitePagesQuery);

  return nodes.map(node => node.path);
};

export const allSitePagesQuery = graphql`
  query AllSitePages {
    pages: allSitePage {
      nodes {
        path
      }
    }
  }
`;
