import { styled } from "@linaria/react";
import { DatoCmsLibraryTool } from "../../../graphql-types";
import {
  darkModeLinariaCSS,
  lightModeLinariaCSS,
} from "../../utils/colorScheme.utils";

type Props = {
  tool: DatoCmsLibraryTool;
};

export const LibraryToolImageWithDarkModeWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    width: 100%;
    height: auto;
    &.light {
      ${darkModeLinariaCSS("display: none;")}
    }
    &.dark {
      ${lightModeLinariaCSS("display: none;")}
    }
  }
`;

const LibraryToolImageWithDarkMode = ({ tool }: Props) => {
  const imageUrl = tool.image?.url;
  const imageDarkModeUrl = tool.imageDarkMode?.url ?? tool?.image?.url;
  return imageUrl ? (
    <LibraryToolImageWithDarkModeWrap>
      <img className={imageDarkModeUrl ? "light" : undefined} src={imageUrl} />
      {imageDarkModeUrl && <img className="dark" src={imageDarkModeUrl} />}
    </LibraryToolImageWithDarkModeWrap>
  ) : null;
};

export default LibraryToolImageWithDarkMode;
