import AWSPageCTASection from "../../components/partners/aws/AWSPageCTASection";
import AWSPageFeaturesSection from "../../components/partners/aws/AWSPageFeaturesSection";
import AWSPageHero from "../../components/partners/aws/AWSPageHero";
import AWSPageIntroSection from "../../components/partners/aws/AWSPageIntroSection";
import AWSPageUsefulResourcesSection from "../../components/partners/aws/AWSPageUsefulResourcesSection";
import SEO from "../../components/utilities/SEO";
import Spacing from "../../components/layout/Spacing";
import Page from "../Page.template";
import { PageComponent } from "../../types/gatsby.types";
import { StoryDescriptor } from "../../types/helper.types";
import AWSPageQuotesSection from "../../components/partners/aws/AWSPageQuotesSection";
import ogImage from "../../../static/images/og/tines-partner-aws.png";
import PartnerPageFeaturedStories from "../../components/partners/PartnerPageFeaturedStories";

const PageAWS: PageComponent<{
  pageContext: {
    stories: StoryDescriptor[];
  };
}> = props => {
  return (
    <Page {...props} navThemeColor="orange">
      <SEO title="AWS × Tines" image={ogImage} />
      <AWSPageHero />
      <Spacing size="sectionMargin" />
      <AWSPageIntroSection />
      <Spacing size="sectionMargin" multiplier={0.75} />
      <AWSPageFeaturesSection />
      <Spacing size="sectionMargin" multiplier={0.75} />
      <PartnerPageFeaturedStories
        id="example-stories"
        partner={{ name: "AWS", slug: "aws", storyLibraryTag: "AWS" }}
        stories={props.pageContext.stories}
      />
      <Spacing size="sectionMargin" />
      <AWSPageUsefulResourcesSection />
      <Spacing size="sectionMargin" />
      <AWSPageQuotesSection />
      <Spacing size="sectionMargin" />
      <AWSPageCTASection />
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageAWS;
