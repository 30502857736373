export const SquareWheelIcon = () => (
  <svg
    width="97"
    height="94"
    viewBox="0 0 97 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.9168 25.5495C58.4873 26.0377 57.7429 26.0853 57.2547 25.6557C56.767 25.2259 56.7193 24.4817 57.1492 23.9935L60.4549 20.2364C60.8849 19.7481 61.6288 19.701 62.117 20.1306C62.6051 20.56 62.6533 21.3041 62.2233 21.7926L58.9168 25.5495Z"
      fill="#49B889"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M49.8015 43.4624L52.7295 18.2861"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.5099 50.6201L57.2472 72.2021"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.7836 50.1953L71.5422 56.6229"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M87.8487 39.112L54.9597 8.78387C51.3823 5.48505 45.808 5.71083 42.5098 9.28814L12.1805 42.1778C8.88213 45.7552 9.1077 51.3295 12.685 54.6281L45.574 84.9566C49.1513 88.2551 54.7257 88.0291 58.0247 84.4521L88.3527 51.5623C91.6517 47.9849 91.4265 42.411 87.8487 39.112ZM56.5552 77.3346C53.8809 80.2337 49.3628 80.4175 46.4632 77.7436L19.8022 53.1586C16.9028 50.4851 16.7196 45.9666 19.3936 43.0666L43.9789 16.4058C46.6528 13.506 51.1713 13.3227 54.0705 15.997L80.7316 40.5815C83.6307 43.255 83.8141 47.7735 81.1393 50.6735L56.5552 77.3346Z"
      fill="#49B889"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M58.301 93.6368C79.0213 93.6368 95.8185 92.5073 95.8185 91.1136C95.8185 89.7203 79.0213 88.5908 58.301 88.5908C37.5806 88.5908 20.7836 89.7203 20.7836 91.1136C20.7836 92.5073 37.5806 93.6368 58.301 93.6368Z"
      fill="#4C1812"
    />
    <path
      d="M92.9868 37.8285L55.8996 3.62918C53.9479 1.8299 51.4694 0.961613 49.0099 1.0013C47.5994 1.02403 45.3722 1.29624 44.5964 1.41225C42.1268 1.78108 39.6576 2.61546 37.8306 4.59652L3.63166 41.6838C-0.0884051 45.7176 0.166346 52.0032 4.20002 55.7225L41.2874 89.9227C43.3405 91.8158 45.9767 92.6783 48.5615 92.5372C49.8421 92.4673 52.2829 91.959 53.2324 91.7819C55.6313 91.3348 57.8497 90.5888 59.6353 88.6529L93.5562 51.868C97.2755 47.8339 97.0209 41.5482 92.9868 37.8285ZM48.3404 85.742L14.1417 54.2066C10.4224 50.7766 10.1877 44.9812 13.6177 41.2614L45.1532 7.06318C45.7904 6.37165 46.5105 5.80136 47.2821 5.3519C48.6251 5.73057 49.9012 6.42093 50.9972 7.4313L85.1953 38.9664C88.9154 42.3964 89.1503 48.1922 85.7198 51.9119L54.1852 86.11C53.5472 86.8012 52.8275 87.3718 52.0547 87.8211C50.7125 87.4422 49.436 86.7526 48.3404 85.742Z"
      fill="#594F4A"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M81.4367 39.7633L50.7955 11.5081C47.463 8.43484 42.2692 8.64513 39.1963 11.9779L10.9408 42.619C7.86752 45.9514 8.07802 51.1448 11.4108 54.2182L42.0519 82.4735C45.3848 85.5468 50.5782 85.3366 53.6515 82.0033L81.9068 51.3625C84.9801 48.0296 84.77 42.8367 81.4367 39.7633ZM52.7121 77.4551C50.0381 80.3547 45.52 80.538 42.62 77.8641L15.9593 53.2795C13.0594 50.6052 12.8767 46.0867 15.5505 43.1871L40.1349 16.5261C42.8088 13.6263 47.3273 13.4433 50.2278 16.1173L76.888 40.7024C79.788 43.3763 79.971 47.8948 77.2966 50.7944L52.7121 77.4551Z"
      fill="#B8E5D2"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M88.6094 38.282L51.6909 4.23862C47.6756 0.535762 41.4185 0.789297 37.7159 4.80455L3.67239 41.7229C-0.0307226 45.7382 0.222855 51.995 4.23832 55.6984L41.1564 89.7416C45.1721 93.4446 51.4293 93.1913 55.1318 89.176L89.1759 52.2579C92.8784 48.2418 92.6247 41.985 88.6094 38.282ZM53.5112 81.3229C50.4974 84.5909 45.4045 84.7969 42.1364 81.7838L12.0909 54.077C8.82311 51.0635 8.61668 45.9714 11.6297 42.7034L39.3365 12.6568C42.35 9.38914 47.4425 9.18291 50.7105 12.1966L80.7563 39.9031C84.0243 42.9169 84.2311 48.009 81.2177 51.277L53.5112 81.3229Z"
      fill="#856E65"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M38.8927 17.7443C38.8927 17.7443 41.3849 16.4095 42.9846 17.2065L46.7454 14.272C46.7454 14.272 45.7586 13.452 43.057 14.7174C40.3551 15.983 38.8927 17.7443 38.8927 17.7443Z"
      fill="#4C1812"
    />
    <path
      d="M47.1075 50.3216C49.1689 50.3216 50.8398 48.6507 50.8398 46.5897C50.8398 44.5287 49.1689 42.8574 47.1075 42.8574C45.0465 42.8574 43.3756 44.5287 43.3756 46.5897C43.3756 48.6507 45.0465 50.3216 47.1075 50.3216Z"
      fill="#49B889"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M40.9445 47.3691L16.49 48.3685"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M78.8371 45.834L48.6967 47.0552"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.7223 44.6772L28.2003 31.9238"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.0561 43.0637L43.9563 15.915"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.4914 79.4698L46.9262 50.0088"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.4367 48.1608C19.4625 48.803 18.9633 49.3439 18.3221 49.3698L16.8832 49.4284C16.2417 49.454 15.7004 48.9549 15.6741 48.3136C15.648 47.6718 16.1474 47.1305 16.7887 47.1041L18.2278 47.0459C18.8693 47.0199 19.4106 47.5194 19.4367 48.1608Z"
      fill="#4C1812"
    />
    <path
      d="M24.0971 40.5882C23.9648 41.217 23.3481 41.6193 22.7195 41.4866L21.3106 41.191C20.6822 41.0587 20.2796 40.4421 20.4117 39.8137C20.5442 39.1848 21.1606 38.7825 21.7893 38.9148L23.1981 39.2108C23.8268 39.3431 24.2289 39.9598 24.0971 40.5882Z"
      fill="#4C1812"
    />
    <path
      d="M32.4228 62.1282C32.8955 62.5636 32.9252 63.2996 32.4894 63.7714L31.5135 64.8298C31.0781 65.302 30.3421 65.3317 29.8703 64.8963C29.398 64.4609 29.3679 63.7254 29.8041 63.2535L30.78 62.1948C31.2154 61.7229 31.951 61.6928 32.4228 62.1282Z"
      fill="#4C1812"
    />
    <path
      d="M39.5354 68.765C40.1554 68.9325 40.5221 69.5713 40.3547 70.1909L39.9791 71.5813C39.8117 72.2013 39.1732 72.568 38.5532 72.4006C37.9332 72.2331 37.5664 71.5951 37.7339 70.9746L38.1094 69.5843C38.2769 68.9643 38.9157 68.5979 39.5354 68.765Z"
      fill="#4C1812"
    />
    <path
      d="M48.651 79.4092L48.607 78.7485C48.5648 78.1076 48.0109 77.6228 47.3699 77.6646C46.7285 77.7078 46.2442 78.2616 46.2864 78.9026L46.3266 79.5038L48.651 79.4092Z"
      fill="#4C1812"
    />
    <path
      d="M25.3613 55.3692C25.6938 55.9189 25.5168 56.634 24.9675 56.966L23.7351 57.7104C23.1857 58.0428 22.4707 57.8665 22.1387 57.3168C21.8065 56.7675 21.9828 56.0525 22.5324 55.7205L23.7643 54.9753C24.3143 54.6433 25.029 54.8196 25.3613 55.3692Z"
      fill="#4C1812"
    />
    <path
      d="M30.206 33.9506C29.7706 34.4229 29.0346 34.4526 28.5628 34.0172L27.504 33.0413C27.0318 32.6059 27.0024 31.8699 27.4377 31.3977C27.8728 30.9258 28.6088 30.8961 29.0807 31.3315L30.1394 32.3078C30.6112 32.7428 30.641 33.4788 30.206 33.9506Z"
      fill="#4C1812"
    />
    <path
      d="M47.7493 44.2501L62.6759 28.3145"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.5099 48.0596L64.2513 64.2238"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.4202 49.7773L30.5736 64.2606"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.847 42.585L35.4015 24.5381"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.3591 43.9864L21.0291 40.0469"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.9089 46.4834L22.7169 57.0849"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.8272 50.0645L39.0798 70.6479"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M50.7142 47.7491L70.0333 34.8848"
      stroke="#4C1812"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.848 50.7386C46.9095 50.7386 48.5803 49.0677 48.5803 47.0063C48.5803 44.9453 46.9095 43.2744 44.848 43.2744C42.787 43.2744 41.1161 44.9453 41.1161 47.0063C41.1161 49.0677 42.787 50.7386 44.848 50.7386Z"
      fill="#B8E5D2"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M44.4775 48.5379C45.3152 48.5379 45.9943 47.8588 45.9943 47.0207C45.9943 46.183 45.3152 45.5039 44.4775 45.5039C43.6394 45.5039 42.9603 46.183 42.9603 47.0207C42.9603 47.8588 43.6394 48.5379 44.4775 48.5379Z"
      fill="#B0A6F9"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M43.8877 48.5609C44.7254 48.5609 45.4045 47.8818 45.4045 47.0441C45.4045 46.2064 44.7254 45.5273 43.8877 45.5273C43.05 45.5273 42.3709 46.2064 42.3709 47.0441C42.3709 47.8818 43.05 48.5609 43.8877 48.5609Z"
      fill="#B8E5D2"
      stroke="#4C1812"
      strokeMiterlimit="10"
    />
    <path
      d="M36.982 26.9638C36.4365 27.3028 35.7193 27.1351 35.3811 26.5893L34.6216 25.366C34.2829 24.8203 34.4504 24.1035 34.9963 23.765C35.5418 23.4261 36.2586 23.594 36.5977 24.1396L37.3567 25.3626C37.6954 25.9083 37.5275 26.6252 36.982 26.9638Z"
      fill="#4C1812"
    />
  </svg>
);
