import { DatoCmsStoryDigest } from "../../graphql-types";
import {
  StoryAuthor,
  StoryDescriptor,
  StoryDescriptorBase,
  StoryGalleryItem,
} from "../types/helper.types";

export const convertDatoRecordToStoryDescriptor = (s: DatoCmsStoryDigest) => {
  // remember also update the same function in storyDescriptors.js
  const d: StoryDescriptor =
    typeof s.descriptor === "string"
      ? JSON.parse(s.descriptor)
      : ({ ...s.descriptor } as StoryDescriptorBase);
  d.internalAuthors = (s.internalAuthors ?? []) as StoryAuthor[];
  d.communityAuthors = (s.communityAuthors ?? []) as StoryAuthor[];
  d.gallery = [...(s.gallery ?? [])] as StoryGalleryItem[];
  if (s.videoIntroEmbedUrl) {
    d.gallery.unshift({
      url: s.videoIntroEmbedUrl,
      width: 1280,
      height: 720,
      isEmbedLink: true,
    });
  }
  return { ...d, datoCmsRecordId: s.id };
};
