import { styled } from "@linaria/react";
import { css } from "linaria";
import {
  fromDesktopMd,
  fromDesktopMl,
  fromTabletLg,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { RigidDotGridDefaults } from "../decorations/RigidDotGrid";
import Spacing from "../layout/Spacing";
import HeroSectionWithInset, {
  HeroSectionBackgroundLayer,
} from "../reusableSections/HeroSectionWithInset";
import { Serif } from "../typography/Serif";
import { Hidden } from "../utilities/Hidden";
import { blueprintColors } from "./buildAppsPageConstants";
import WhatWillYouBuild from "./WhatWillYouBuild";
import {
  fromMaxPageWidthBreakpoint,
  maxPageContentWidth,
} from "../../styles/maxPageContentWidth.styles";
import { BuildAppsPageHeroDotGridWithGraphics } from "./decorations/BuildAppsPageHeroDotGridWithGraphics";
import PagesFlippableDemo from "./PagesFlippableDemo";
import { StoryDescriptor } from "../../types/helper.types";
import WatchVideoCTAAndFrame from "./WatchVideoCTAAndFrame";

const { cellSize } = RigidDotGridDefaults;

const BuildAppsPageHeroStyle = css`
  ${HeroSectionBackgroundLayer} {
    border: 1px solid ${blueprintColors.canvas.fg};
  }
  ${HeroSectionBackgroundLayer} {
    height: calc(100% - 30vw);
    ${fromTabletMd} {
      height: calc(100% - 40vw);
    }
    ${fromMaxPageWidthBreakpoint} {
      height: calc(100% - ${maxPageContentWidth * 0.35}px);
    }
  }
`;

const Content = styled.div`
  text-align: center;
`;

const Subheading = styled.p`
  font-weight: 600;
  font-size: 2.4rem;
  text-align: center;
  ${onlyPhones} {
    line-height: 1.15;
  }
  ${fromTabletLg} {
    font-size: 2.6rem;
    height: ${cellSize}px;
    line-height: ${cellSize}px;
    transform: translateY(0.25em);
  }
  ${fromDesktopMd} {
    font-size: 3.8rem;
  }
  ${fromDesktopMl} {
    font-size: 4.2rem;
    transform: translateY(0.125em);
  }
`;

const Paragraph = styled.p`
  max-width: 25em;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  ${fromTabletLg} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${cellSize * 2}px;
    span {
      display: block;
    }
  }
`;

const DemoContainer = styled.div``;

const BuildAppsPageHero = (props: { demoStory: StoryDescriptor }) => {
  return (
    <HeroSectionWithInset
      backgroundColor={colorsV4.canvas}
      textColor={colorsV4.warmBlack}
      className={BuildAppsPageHeroStyle}
      backgroundPattern={<BuildAppsPageHeroDotGridWithGraphics />}
    >
      <Content>
        <Hidden reason="Hidden text title for SEO">
          <h1>What will you build?</h1>
        </Hidden>
        <WhatWillYouBuild />
        <Subheading>
          <span>Build apps </span>
          <span>
            <Serif>with Tines</Serif>
          </span>
        </Subheading>
        <Spacing size={cellSize} />
        <Paragraph>
          <span>Introduce interactions from anyone, </span>
          <span>at any point in a workflow with slick, intuitive apps.</span>
        </Paragraph>
        <Spacing size={cellSize * 1.25} />
        <WatchVideoCTAAndFrame
          src="https://www.youtube.com/embed/g3q-1Rgny5c?autoplay=1"
          buttonColor="orange"
        />
        <Spacing size={cellSize * 2.5} />
        <DemoContainer id="demo">
          <PagesFlippableDemo story={props.demoStory} />
        </DemoContainer>
      </Content>
    </HeroSectionWithInset>
  );
};

export default BuildAppsPageHero;
