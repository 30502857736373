/* eslint-disable import/no-named-as-default-member */
import { styled } from "@linaria/react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import Matter from "matter-js";
import { colors } from "../../styles/colors.styles";
import { useRef, useState } from "react";
import { resolveAfter } from "../../utils/promises.utils";
import { cx } from "linaria";
import gsap from "gsap";

const WorkbenchRiverOfShapesLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const CanvasContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -300px;
  transition: opacity 1s;
  opacity: 0;
  .ready & {
    opacity: 1;
  }
`;

const shapeColors = [
  colors.purple300,
  colors.green300,
  colors.pink300,
  colors.orange300,
];

export const WorkbenchRiverOfShapes = () => {
  const [ready, setReady] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    if (!ref.current) return;
    const containerWidth = ref.current?.clientWidth ?? window.innerWidth;
    const pixelRatio = containerWidth > 1680 ? 1 : window.devicePixelRatio || 1;
    const containerHeight =
      (ref.current.clientHeight ?? window.innerHeight) + 300;
    const renderWidth = containerWidth * pixelRatio;
    const renderHeight = containerHeight * pixelRatio;

    const engine = Matter.Engine.create();
    const render = Matter.Render.create({
      element: ref.current,
      engine: engine,
      options: {
        width: renderWidth,
        height: renderHeight,
        wireframes: false,
        background: colors.lightest,
      },
    });

    engine.gravity.x = 0.1; // gentle rightward pull
    engine.gravity.y = 0.01; // even gentler downward pull

    const shapeGenerationBoundsWidth = renderWidth + 800 * pixelRatio;
    const shapeGenerationBoundsHeight = renderHeight + 800 * pixelRatio;

    const sizeFactor =
      (containerWidth > 2560
        ? 1
        : containerWidth > 1280
        ? 0.95
        : containerWidth > 768
        ? 0.9
        : 0.6) * (pixelRatio > 1 ? 1 : 0.6);

    function createRandomShape(_x: number, _y: number) {
      const x = _x - 400 * pixelRatio;
      const y = _y - 400 * pixelRatio;
      const size =
        Math.random() * (400 * sizeFactor) + 60 * sizeFactor * pixelRatio;
      const color = shapeColors[Math.floor(Math.random() * shapeColors.length)];
      const shapeType = Math.floor(Math.random() * 3);

      let body;
      // const maxSize = 260 * pixelRatio;
      const options: Matter.IBodyDefinition = {
        isStatic: false,
        restitution: 0,
        friction: 2 / pixelRatio,
        frictionStatic: 1.8 / pixelRatio,
        frictionAir: 0.1 / pixelRatio,
        density: 1.6 / pixelRatio,
        inertia: Matter.Body.nextGroup(true),
        mass: 200 / pixelRatio,
        render: {
          fillStyle: colors.lightest,
          strokeStyle: color,
          lineWidth: 1.5 * pixelRatio,
        },
      };

      switch (shapeType) {
        case 0: // Circle
          body = Matter.Bodies.circle(x, y, size / 2, options);
          break;
        case 1: // Rounded rectangle
          body = Matter.Bodies.rectangle(x, y, size, size, {
            ...options,
            chamfer: { radius: 24 * pixelRatio * sizeFactor },
          });
          break;
        case 2: // Rounded hexagon
          body = Matter.Bodies.polygon(x, y, 6, size / 2, {
            ...options,
            chamfer: { radius: 24 * pixelRatio * sizeFactor },
          });
          break;
      }

      if (body) {
        Matter.Body.set(body, {
          angularDamping: 2 / pixelRatio,
        });
        Matter.Body.setAngularVelocity(
          body,
          ((Math.random() * 0.1 - 0.05) * 2) / pixelRatio
        );
      }
      return body;
    }

    const shapes = [];
    const totalShapes = (pixelRatio > 1 ? 140 : 100) / sizeFactor;

    (async () => {
      for (let i = 0; i < totalShapes; i++) {
        const x = Math.random() * shapeGenerationBoundsWidth;
        const y = Math.random() * shapeGenerationBoundsHeight;
        const shape = createRandomShape(x, y);
        if (shape) {
          shapes.push(shape);
          Matter.World.add(engine.world, shape);
        }
        await resolveAfter(1);
      }
      await resolveAfter(600);
      setReady(true);
    })();

    Matter.Render.run(render);

    const runner = Matter.Runner.create();
    Matter.Runner.run(runner, engine);

    function createShapeOffScreen() {
      const x = -800 * pixelRatio;
      const y = -200 * pixelRatio + Math.random() * renderHeight;
      return createRandomShape(x, y);
    }

    let pointerX = 0;
    let pointerY = 0;

    const draggedBodies: Matter.Body[] = [];

    const limits = {
      maxAngularVelocity: 0.02 / pixelRatio,
      baseMaxVelocity: 6 / pixelRatio,
      maxVelocityFactor: 10 / pixelRatio,
      maxDistance: 200 / pixelRatio,
    };

    const handleMatterEngineBeforeUpdate = () => {
      const allBodies = Matter.Composite.allBodies(engine.world);

      allBodies.forEach(body => {
        if (Math.abs(body.angularVelocity) > limits.maxAngularVelocity) {
          Matter.Body.setAngularVelocity(
            body,
            Math.sign(body.angularVelocity) * limits.maxAngularVelocity
          );
        }
        const distanceToPointer = Matter.Vector.magnitude(
          Matter.Vector.sub(body.position, { x: pointerX, y: pointerY })
        );
        const distanceFactor = Math.min(
          distanceToPointer / (limits.maxDistance * pixelRatio),
          1
        );
        const maxVelocity = draggedBodies.includes(body)
          ? 10
          : limits.baseMaxVelocity +
            (1 - distanceFactor) * limits.maxVelocityFactor;

        if (
          Math.abs(body.velocity.x) > maxVelocity ||
          Math.abs(body.velocity.y) > maxVelocity
        ) {
          Matter.Body.setVelocity(body, {
            x:
              Math.abs(body.velocity.x) > maxVelocity
                ? Math.sign(body.velocity.x) * maxVelocity
                : body.velocity.x,
            y:
              Math.abs(body.velocity.y) > maxVelocity
                ? Math.sign(body.velocity.y) * maxVelocity
                : body.velocity.y,
          });
        }
      });
    };

    Matter.Events.on(engine, "beforeUpdate", handleMatterEngineBeforeUpdate);

    const handleMatterEngineAfterUpdate = () => {
      const allBodies = Matter.Composite.allBodies(engine.world);

      allBodies.forEach(body => {
        const xOff = body.position.x > renderWidth + 360 * pixelRatio;
        const yOff =
          body.position.y > renderHeight + 400 * sizeFactor * pixelRatio ||
          body.position.y < -400 * sizeFactor * pixelRatio;

        if (xOff || yOff) {
          Matter.World.remove(engine.world, body);
          const newShape = createShapeOffScreen();
          if (newShape) Matter.World.add(engine.world, newShape);
        }
      });
    };

    Matter.Events.on(engine, "afterUpdate", handleMatterEngineAfterUpdate);

    const handlePointerMove = (event: PointerEvent) => {
      const rect = render.canvas.getBoundingClientRect();
      pointerX = event.clientX - rect.left;
      pointerY = event.clientY - rect.top;
      applyPointerEffectOnCanvas(pointerX, pointerY);
    };

    function applyPointerEffectOnCanvas(
      pointerX: number,
      pointerY: number,
      magnitude = 0.001
    ) {
      const interactionPosition = {
        x: pointerX * pixelRatio,
        y: pointerY * pixelRatio,
      };

      // Check all bodies and apply a gentle force to those near the pointer
      const bodies = Matter.Composite.allBodies(engine.world);
      bodies.forEach(body => {
        const bounds = body.bounds;
        const isInsideBounds =
          interactionPosition.x >= bounds.min.x &&
          interactionPosition.x <= bounds.max.x &&
          interactionPosition.y >= bounds.min.y &&
          interactionPosition.y <= bounds.max.y;
        if (isInsideBounds) {
          const forceMagnitude = magnitude * body.mass;
          const forceVector = Matter.Vector.mult(
            Matter.Vector.normalise(
              Matter.Vector.sub(body.position, interactionPosition)
            ),
            forceMagnitude
          );
          Matter.Body.applyForce(body, body.position, forceVector);
        }
      });
    }

    window.addEventListener("pointermove", handlePointerMove);

    gsap.to(limits, {
      baseMaxVelocity: 5,
      duration: 3,
      delay: 2,
    });

    const mouse = Matter.Mouse.create(render.canvas);
    const mouseConstraint = Matter.MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

    const handleMouseConstraintStartDrag = (
      event: Matter.IEvent<Matter.MouseConstraint>
    ) => {
      const body = Reflect.get(event, "body") as Matter.Body;
      draggedBodies.push(body);
      document.body.classList.add("matter-dragging");
    };

    Matter.Events.on(
      mouseConstraint,
      "startdrag",
      handleMouseConstraintStartDrag
    );

    const handleMouseConstraintEndDrag = (
      event: Matter.IEvent<Matter.MouseConstraint>
    ) => {
      const body = Reflect.get(event, "body") as Matter.Body;
      setTimeout(() => {
        const index = draggedBodies.indexOf(body);
        if (index > -1) {
          draggedBodies.splice(index, 1); // Remove the body from the array
        }
      }, 5000);
    };

    Matter.Events.on(mouseConstraint, "enddrag", handleMouseConstraintEndDrag);

    if (containerWidth >= 768) {
      Matter.World.add(engine.world, mouseConstraint);

      render.mouse = mouse;
    }

    const handleWheelOrTouchMove = (event: WheelEvent | TouchEvent) => {
      // Prevent Matter.js or the canvas from intercepting the event
      event.stopPropagation(); // Let the event bubble up for scrolling
    };

    const handlePointerUp = () => {
      document.body.classList.remove("matter-dragging");
    };

    window.addEventListener("wheel", handleWheelOrTouchMove, {
      capture: true,
      passive: true,
    });
    window.addEventListener("touchstart", handleWheelOrTouchMove, {
      capture: true,
      passive: true,
    });
    window.addEventListener("touchmove", handleWheelOrTouchMove, {
      capture: true,
      passive: true,
    });

    window.addEventListener("pointerup", handlePointerUp);
    window.addEventListener("blur", handlePointerUp);

    let isPaused = false;

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const totalY = 1000;
      const y = (scrollY / totalY) * 300 * -1;
      const opacity = Math.min(1, (totalY - scrollY) / totalY);
      gsap.to(ref.current, {
        y,
        opacity,
        ease: "expo.out",
        onUpdate: () => {
          if (ref.current) {
            if (parseFloat(ref.current.style.opacity) < 0.01) {
              if (!isPaused) {
                Matter.Render.stop(render);
                isPaused = true;
              }
            } else {
              if (isPaused) {
                Matter.Render.run(render);
                isPaused = false;
              }
            }
          }
        },
      });
    };

    window.addEventListener("scroll", handleScroll);
    setTimeout(handleScroll, 1000);

    render.options.width = renderWidth;
    render.options.height = renderHeight;
    render.canvas.style.width = `${containerWidth}px`;
    render.canvas.style.height = `${containerHeight}px`;
    render.context.scale(pixelRatio, pixelRatio);

    return () => {
      Matter.Runner.stop(runner);
      Matter.Render.stop(render);
      Matter.Engine.clear(engine);
      Matter.World.clear(engine.world, true);
      render.canvas.remove();
      Matter.Events.off(engine, "beforeUpdate", handleMatterEngineBeforeUpdate);
      Matter.Events.off(engine, "afterUpdate", handleMatterEngineAfterUpdate);
      Matter.Events.off(
        mouseConstraint,
        "startdrag",
        handleMouseConstraintStartDrag
      );
      Matter.Events.off(
        mouseConstraint,
        "enddrag",
        handleMouseConstraintEndDrag
      );
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("pointermove", handlePointerMove);
      window.removeEventListener("wheel", handleWheelOrTouchMove, {
        capture: true,
      });
      window.removeEventListener("touchmove", handleWheelOrTouchMove, {
        capture: true,
      });
      window.removeEventListener("touchstart", handleWheelOrTouchMove, {
        capture: true,
      });
    };
  });
  return (
    <WorkbenchRiverOfShapesLayer className={cx(ready && "ready")}>
      <CanvasContainer ref={ref} />
    </WorkbenchRiverOfShapesLayer>
  );
};

export default WorkbenchRiverOfShapes;
