import { styled } from "@linaria/react";
import DemoFormMessageView from "./DemoFormMessageView";
import { DemoAltOptionsButtonGroup } from "./DemoAltOptionsButtonGroup";
import Spacing from "../layout/Spacing";
import { colors } from "../../styles/colors.styles";

const Content = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  height: 100%;
  h3 {
    max-width: 22rem;
  }
  p {
    max-width: 23rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Footer = styled.div`
  background-color: ${colors.purple50};
  border-radius: 6px;
  padding: 2em 1em;
  margin-top: 3em;
  margin-left: -1em;
  margin-bottom: -1em;
  margin-right: -1em;
  > * + * {
    margin-top: 1.5em;
  }
  .ButtonInner {
    font-size: 1.2rem;
  }
`;

const DemoNoAvailabilityView = () => {
  return (
    <DemoFormMessageView>
      <Content>
        <div>
          <Icon />
          <h3>Thanks for submitting your request.</h3>
          <Spacing />
          <p>
            It looks like we don't have any slots available. A member of our
            team will reach out within 24 hours to schedule a meeting.
          </p>
        </div>
        <Footer>
          <h4>Want to get started right away?</h4>
          <DemoAltOptionsButtonGroup />
        </Footer>
      </Content>
    </DemoFormMessageView>
  );
};

const Icon = () => (
  <svg width="104" height="80" viewBox="0 0 104 80" fill="none">
    <path
      d="M80.2678 73.2603C80.2678 73.2603 76.9905 77.5152 70.0127 78.6223C63.1486 79.7112 57.4565 78.7107 54.737 75.5276C54.2379 74.944 53.7931 70.9814 53.4858 68.5672C53.2142 66.433 51.681 58.9487 50.7449 56.3154C49.8082 53.6819 49.2421 48.4092 48.5571 46.7217C47.8731 45.034 48.1065 42.0573 49.9918 40.2444C51.8771 38.4315 57.9814 32.6474 57.9814 32.6474L67.5793 24.15C67.5793 24.15 72.1688 19.73 73.6037 18.6978C76.1148 16.894 80.5278 20.3733 78.6632 24.1472C78.0156 25.4578 70.0035 33.7006 70.0035 33.7006C70.0035 33.7006 72.3839 31.8179 75.1789 31.6016C77.9732 31.3854 78.5452 33.1187 79.2935 33.9946C79.2935 33.9946 82.6256 32.4469 84.9109 36.2683C86.6382 39.1566 86.3786 41.1324 86.3786 41.1324C86.3786 41.1324 90.4031 42.3283 90.0812 45.7337C89.7591 49.1397 88.2846 51.9651 85.1072 52.2057C85.1072 52.2057 86.7583 56.2802 79.8545 66.3893C79.8541 66.3898 79.9049 69.4165 80.2678 73.2603Z"
      fill="#F486B8"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M71.1078 32.7146L68.9727 34.7136"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.9688 47.6719C76.3413 47.8167 76.7877 47.8929 77.297 47.8074C78.9524 47.529 80.6697 45.9481 80.5821 41.8566C80.5156 38.7454 80.0297 35.8905 78.868 33.5117"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M78.9502 47.0387C78.9502 47.0387 79.8194 48.455 81.4752 48.1764C83.1306 47.898 86.0668 45.8481 86.3815 40.8071"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M82.7977 47.6401C82.7977 47.6401 81.8021 51.6467 85.4118 52.1479"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M77.1574 71.9173C77.1574 71.9173 76.2696 69.9403 77.5 68.6905C78.3402 67.8374 80.0377 66.2246 80.0377 66.2246"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M76.0697 50.8698C76.0697 50.8698 77.5657 52.2122 79.2767 51.2915C79.2767 51.2915 80.2769 53.4236 81.9055 53.0801C81.9055 53.0801 82.6833 55.8922 85.5277 55.4188C85.5277 55.4188 85.4753 54.1709 85.275 53.119C85.0752 52.0672 84.4519 52.0936 84.0358 51.6278C83.6196 51.1622 82.2287 50.4116 82.5429 49.2874C82.8572 48.1629 83.0497 47.6316 81.842 47.8948C80.6349 48.1576 80.0559 47.8486 79.7034 47.5385C79.3507 47.228 79.0945 47.0149 78.3845 47.303C77.5674 47.6343 75.9586 47.2443 76.0203 48.6243C76.0811 50.0042 76.0697 50.8698 76.0697 50.8698Z"
      fill="#4D3E78"
    />
    <path
      d="M34.8008 33.823L45.1191 47.134L90.1627 6.35547L34.8008 33.823Z"
      fill="#EADFF8"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
    />
    <path
      d="M36.6396 36.2007L41.2211 41.9637L83.0371 12.2488L36.6396 36.2007Z"
      fill="#4D3E78"
    />
    <path
      d="M57.7702 58.2065C57.7702 58.2065 57.2028 53.5789 57.348 51.0302C57.4934 48.4822 57.0477 40.0559 56.9259 37.381C56.7855 34.2854 56.1297 28.1737 56.4657 25.1573C56.7418 22.6872 57.5388 21.0748 60.3517 20.9284C62.8535 20.7981 65.6401 24.8426 66.787 28.9631C67.9336 33.0834 69.8228 36.7721 71.8183 39.1069C73.8135 41.4422 81.5505 52.3669 73.1077 62.7093"
      fill="#F486B8"
    />
    <path
      d="M57.7702 58.2065C57.7702 58.2065 57.2028 53.5789 57.348 51.0302C57.4934 48.4822 57.0477 40.0559 56.9259 37.381C56.7855 34.2854 56.1297 28.1737 56.4657 25.1573C56.7418 22.6872 57.5388 21.0748 60.3517 20.9284C62.8535 20.7981 65.6401 24.8426 66.787 28.9631C67.9336 33.0834 69.8228 36.7721 71.8183 39.1069C73.8135 41.4422 81.5505 52.3669 73.1077 62.7093"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.11523 34.0249L31.7297 38.9956L100.968 2.40677L27.4247 1L33.5626 13.3188L26.1427 15.0456L2.11523 34.0249Z"
      fill="#EADFF8"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
    />
    <path
      d="M33.5625 13.3188L100.967 2.40674"
      stroke="#4D3E78"
      strokeWidth="1.15"
      strokeMiterlimit="10"
    />
    <path
      d="M48.0404 44.7804L49.0341 48.7967C49.0341 48.7967 56.9715 40.8541 57.0319 40.5259C57.0921 40.1975 56.4255 36.9875 56.4255 36.9875C56.4255 36.9875 47.8462 44.9147 48.0404 44.7804Z"
      fill="#4D3E78"
    />
    <path
      d="M66.7227 27.6458L67.4791 30.7367L77.6759 19.171L76.7364 18.5574L66.7227 27.6458Z"
      fill="#4D3E78"
    />
  </svg>
);

export default DemoNoAvailabilityView;
