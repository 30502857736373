import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { AIPageHeading2 } from "./_AIPageComponents";
import Spacing from "../layout/Spacing";
import { rSize } from "../../styles/responsiveSizes.styles";
import { w } from "../../utils/string.utils";
import NoWrap, { NoBreak } from "../typography/Nowrap";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPathsPainter";
import {
  fromDesktop,
  fromTablet,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import aiPageSceneIllustration from "./graphics/aiPageSceneIllustration.svg";

const Inner = styled.div`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-gap: ${rSize("gap")};
  grid-template-areas: ${`
    '${w("i", 12)}'
    '${w("h", 12)}'
    '${w("u1", 6)} ${w("u3", 6)}'
    '${w("u2", 6)} ${w("u4", 6)}'
  `};
  ${fromTablet} {
    grid-template-areas: ${`
      '${w("i", 12)}'
      '${w("h", 12)}'
      '${w("u1", 6)} ${w("u3", 6)}'
      '${w("u2", 6)} ${w("u4", 6)}'
    `};
  }
  ${fromDesktop} {
    grid-template-areas: ${`
      '${w("h", 12)}'
      '. ${w("u1", 3)} ${w("i", 5)} ${w("u3", 3)}'
      '. ${w("u2", 3)} ${w("i", 5)} ${w("u4", 3)}'
    `};
  }
`;

const Header = styled.header`
  grid-area: h;
  text-align: center;
  p {
    font-size: 1.6rem;
  }
`;

const UseCase = styled.div`
  h3 {
    font-family: ${serif};
    font-size: 2rem;
    ${fromTablet} {
      font-size: 2.2rem;
    }
    letter-spacing: -0.02em;
    font-weight: 400;
    margin-top: 0.5em;
    em {
      font-style: inherit;
    }
  }
  ul {
    font-weight: 500;
    font-size: 1.4rem;
    padding-left: 1.5em;
    line-height: 1.25;
    li {
      margin-top: 0.5em;
    }
  }
  &:nth-of-type(1) {
    grid-area: u1;
    h3 em {
      color: ${colors.green};
    }
  }
  &:nth-of-type(2) {
    grid-area: u2;
    h3 em {
      color: ${colors.purple};
    }
  }
  &:nth-of-type(3) {
    grid-area: u3;
    h3 em {
      color: ${colors.pink};
    }
  }
  &:nth-of-type(4) {
    grid-area: u4;
    h3 em {
      color: ${colors.orange};
    }
  }
`;

const IllustrationWrap = styled.div`
  grid-area: i;
  align-self: center;
  ${uptoDesktop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${fromDesktop} {
    margin-left: -4%;
  }
  > * {
    max-width: 100%;
    height: auto;
  }
`;

export const AIPageUseCasesSection = () => {
  return (
    <PageSection relative>
      <ConnectorPathWaypoint
        id="ai-page-use-cases-section"
        index={0}
        color={colors.yellow100}
        top={rSize("xxl", -1)}
        left="50%"
      />
      <ConnectorPathWaypoint
        id="ai-page-use-cases-section"
        index={1}
        color={colors.yellow100}
        bottom={rSize("pageMargin", -0.5)}
        left="50%"
      />
      <Inner>
        <Grid>
          <Header>
            <AIPageHeading2>Make AI work for you</AIPageHeading2>
            <Spacing />
            <p>
              Access to leading open-source LLMs and over 100K Python libraries.
            </p>
            <Spacing size="sectionPadding" />
          </Header>

          <UseCase>
            <InterpretIcon />
            <h3>
              <em>Interpret</em> incoming data from multiple sources
            </h3>
            <ul>
              <li>Perform advanced cryptography</li>
              <li>
                Internationalization across <NoWrap>any languages</NoWrap>
              </li>
            </ul>
          </UseCase>
          <UseCase>
            <SummarizeIcon />
            <h3>
              <em>Summarize</em> information
            </h3>
            <ul>
              <li>Make a decision based on information</li>
              <li>
                Assign a role (analyst, AWS expert, engineer, etc.) and
                recommend <NoBreak>next steps</NoBreak>
              </li>
            </ul>
          </UseCase>
          <UseCase>
            <FormatIcon />
            <h3>
              <em>Format</em> data
            </h3>
            <ul>
              <li>Define case layout</li>
              <li>Create data visualizations</li>
              <li>
                Use markdown, CSS, and, yes, <NoWrap>even LaTeX</NoWrap>
              </li>
            </ul>
          </UseCase>
          <UseCase>
            <TransformIcon />
            <h3>
              <em>Transform</em> data into <NoBreak>any structure</NoBreak>
            </h3>
            <ul>
              <li>Map an alert to a record schema</li>
              <li>Convert file formats</li>
            </ul>
          </UseCase>

          <IllustrationWrap>
            <img src={aiPageSceneIllustration} />
          </IllustrationWrap>
        </Grid>
      </Inner>
    </PageSection>
  );
};

const InterpretIcon = () => {
  return (
    <svg width="40" height="45" viewBox="0 0 40 45" fill="none">
      <path
        d="M13 20C13 18.3431 14.3431 17 16 17H36C37.6569 17 39 18.3431 39 20V41.2673L30.2317 36.2012C30.0036 36.0694 29.7448 36 29.4813 36H16C14.3431 36 13 34.6569 13 33V20ZM39.7501 41.7007L39.7493 41.7002L39.7501 41.7007Z"
        stroke="#5ABE89"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M27 16C27 9.37258 21.6274 4 15 4C8.37258 4 3 9.37258 3 16C3 18.3876 3.69728 20.6123 4.8993 22.4816L2.5 28H12"
        stroke="#5ABE89"
        strokeWidth="2"
      />
    </svg>
  );
};

const SummarizeIcon = () => {
  return (
    <svg width="36" height="45" viewBox="0 0 36 45" fill="none">
      <circle cx="32" cy="23" r="3" stroke="#A990F5" strokeWidth="2" />
      <circle cx="32" cy="37" r="3" stroke="#A990F5" strokeWidth="2" />
      <circle cx="32" cy="9" r="3" stroke="#A990F5" strokeWidth="2" />
      <circle cx="4" cy="23" r="3" stroke="#A990F5" strokeWidth="2" />
      <circle cx="4" cy="37" r="3" stroke="#A990F5" strokeWidth="2" />
      <circle cx="4" cy="9" r="3" stroke="#A990F5" strokeWidth="2" />
      <circle cx="18" cy="9" r="3" stroke="#A990F5" strokeWidth="2" />
      <circle cx="18" cy="37" r="3" stroke="#A990F5" strokeWidth="2" />
      <path
        d="M17.5496 17.2371C17.7314 16.86 18.2686 16.86 18.4504 17.2371L19.799 20.0346C20.0446 20.5442 20.4558 20.9554 20.9654 21.201L23.7629 22.5496C24.14 22.7314 24.14 23.2686 23.7629 23.4504L20.9654 24.799C20.4558 25.0446 20.0446 25.4558 19.799 25.9654L18.4504 28.7629C18.2686 29.14 17.7314 29.14 17.5496 28.7629L16.201 25.9654C15.9554 25.4558 15.5442 25.0446 15.0346 24.799L12.2371 23.4504C11.86 23.2686 11.86 22.7314 12.2371 22.5496L15.0346 21.201C15.5442 20.9554 15.9554 20.5442 16.201 20.0346L17.5496 17.2371Z"
        stroke="#A990F5"
        strokeWidth="2"
      />
    </svg>
  );
};

const FormatIcon = () => {
  return (
    <svg width="35" height="45" viewBox="0 0 35 45" fill="none">
      <path
        d="M1 12C1 10.3431 2.34315 9 4 9H31C32.6569 9 34 10.3431 34 12V30C34 31.6569 32.6569 33 31 33H28.9172C26.8919 33 25.25 34.6419 25.25 36.6672C25.25 38.1858 23.3853 38.9137 22.3565 37.7967L19.4238 34.6126C18.4771 33.5848 17.1435 33 15.7461 33H4C2.34315 33 1 31.6569 1 30V12Z"
        stroke="#F486B8"
        strokeWidth="2"
      />
      <path
        d="M11 16L7 21L11 26"
        stroke="#F486B8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24 16L28 21L24 26"
        stroke="#F486B8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19 14L16 27"
        stroke="#F486B8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const TransformIcon = () => {
  return (
    <svg width="37" height="45" viewBox="0 0 37 45" fill="none">
      <circle cx="12" cy="27" r="11" stroke="#FD975D" strokeWidth="2" />
      <path
        d="M22.1291 5.54159C22.5119 4.86399 23.4879 4.86405 23.8706 5.54169L35.1462 25.5083C35.5226 26.1749 35.041 27 34.2754 27H11.7212C10.9556 27 10.474 26.1748 10.8505 25.5082L22.1291 5.54159Z"
        stroke="#FD975D"
        strokeWidth="2"
      />
    </svg>
  );
};
