import ContentCardBase, { ContentCardSize } from "./_ContentCardBase";
import { css } from "linaria";
import { DatoCmsContentCard, DatoCmsDocument } from "../../../graphql-types";
import ReactMarkdown from "react-markdown";
import {
  fromDesktopMd,
  fromDesktopSm,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { CSSProperties, styled } from "@linaria/react";
import {
  PrimaryColorName,
  SecondaryColorName,
  brandColorThemeVar,
} from "../../styles/colors.styles";
import { cover } from "polished";

const contentCardDocumentStyle = css`
  h4 {
    ${fromDesktopMd} {
      margin-right: -0.5em;
    }
    + p {
      max-width: 55%;
      ${fromDesktopSm} {
        max-width: 100%;
      }
    }
  }
`;

const ReportThumbContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 50%;
  max-width: 10em;
`;

const ReportThumb = styled.div`
  margin: 0;
  position: relative;
  flex: 0 1 auto;
  max-width: 100%;
  img {
    border-radius: 2px;
    position: relative;
    display: block;
    width: 100%;
    border-radius: 4px;
    z-index: 2;
    background-color: ${brandColorThemeVar(800)};
    pointer-events: none;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    border-radius: 4px;
    position: absolute;
    ${cover()}
  }
  &:before {
    z-index: 1;
    transform: translate(2px, 2px);
    border-bottom-right-radius: 3px;
    background-color: var(
      --previewImageShadowColor,
      ${brandColorThemeVar(200)}
    );
  }
  &:after {
    z-index: 0;
    transform: translate(4px, 4px);
    border-bottom-right-radius: 4px;
    background-color: var(
      --previewImageShadowColor,
      ${brandColorThemeVar(200)}
    );
    opacity: 0.5;
  }
`;

export const ContentCardDocument = ({
  doc,
  overrides,
  large,
}: {
  doc: DatoCmsDocument;
  overrides?: Partial<DatoCmsContentCard>;
  large?: boolean;
}) => (
  <ContentCardBase
    className={contentCardDocumentStyle}
    link={["/access", doc.category?.slug, doc.slug].filter(i => i).join("/")}
    color={
      (overrides?.color ?? "purple") as PrimaryColorName | SecondaryColorName
    }
    variant={overrides?.colorMode === "dark" ? "800" : "50"}
    large={large}
    size={overrides?.size as ContentCardSize | null}
    design={overrides?.preset}
  >
    <header>
      <div>
        <p>
          <strong>
            {overrides?.preHeadingTag || doc.category?.name || "Download"}
          </strong>
        </p>
        <h4>{overrides?.heading || doc.title}</h4>
        {overrides?.description && (
          <ReactMarkdown>{overrides.description}</ReactMarkdown>
        )}
      </div>
      <p>
        <strong>
          {overrides?.linkLabel ||
            (doc.category?.name
              ? `Access the ${doc.category.name.toLowerCase()}`
              : "Learn more")}
        </strong>
      </p>
    </header>
    {doc.previewImage?.url && (
      <figure>
        <ReportThumbContainer>
          <ReportThumb
            style={
              {
                "--previewImageShadowColor":
                  doc.previewImageShadowColor?.hex ?? undefined,
              } as CSSProperties
            }
          >
            <img
              src={doc.previewImage.url}
              style={{
                backgroundColor:
                  doc.previewImageBackgroundColor?.hex ?? undefined,
                aspectRatio: `${doc.previewImage.width}/${doc.previewImage.height}`,
              }}
            />
          </ReportThumb>
        </ReportThumbContainer>
      </figure>
    )}
  </ContentCardBase>
);
