import { css } from "linaria";
import { useRef } from "react";
import { fromTablet } from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import { rSize } from "../../../styles/responsiveSizes.styles";
import useGridLayout from "../../decorations/GridLayout";
import RigidDotGrid, {
  RigidDotGridDefaults,
} from "../../decorations/RigidDotGrid";
import { FromTabletG } from "../../mediaQueries/UptoTablet";
import { FromTabletLgG } from "../../mediaQueries/UptoTabletLg";
import { blueprintColors } from "../buildAppsPageConstants";

const { cellSize } = RigidDotGridDefaults;
const insetTop = cellSize * 0.25;

const pacman = { x: 14, y: 15 } as const;

export const BuildAppsPageHeroDotGridWithGraphics = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { ready, x, y, Line, HrztLine, VertLine, totalRows, SvgLayer } =
    useGridLayout({
      containerRef,
      insetTop,
      gridXOrigin: "center",
    });
  const pacmanMaskPathDef = [
    `M${x.center(pacman.x) - x.span(4)} ${y.start(pacman.y) - y.span(4)}`,
    `H${x.center(pacman.x) + x.span(4)}`,
    `L${x.center(pacman.x)} ${y.start(pacman.y)}`,
    `L${x.center(pacman.x) + x.span(4)} ${y.start(pacman.y) + y.span(4)}`,
    `H${x.center(pacman.x) - x.span(4)}`,
    `Z`,
  ].join("");
  return (
    <RigidDotGrid
      color={blueprintColors.canvas.fg}
      insetTop={insetTop}
      className={css`
        ${fromTablet} {
          border-radius: ${rSize("widerPageMargin", 1.5)};
        }
      `}
      innerRef={containerRef}
    >
      {() =>
        ready ? (
          <SvgLayer
            fill="none"
            stroke={blueprintColors.canvas.fg}
            strokeDasharray={`6 6`}
          >
            <defs>
              <symbol
                id="RoundedAppIconSymbol"
                width={180}
                height={180}
                viewBox={`0 0 ${180} ${180}`}
              >
                <path d="M0.5 68.425C0.5 56.3149 0.500402 47.263 1.0964 40.0862C1.6919 32.9154 2.87942 27.6615 5.22864 23.1391C9.21341 15.4681 15.4681 9.21341 23.1391 5.22864C27.6615 2.87942 32.9154 1.6919 40.0862 1.0964C47.263 0.500402 56.3149 0.5 68.425 0.5H111.575C123.685 0.5 132.737 0.500402 139.914 1.0964C147.085 1.6919 152.339 2.87942 156.861 5.22864C164.532 9.21341 170.787 15.4681 174.771 23.1391C177.121 27.6615 178.308 32.9154 178.904 40.0862C179.5 47.263 179.5 56.3149 179.5 68.425V111.575C179.5 123.685 179.5 132.737 178.904 139.914C178.308 147.085 177.121 152.339 174.771 156.861C170.787 164.532 164.532 170.787 156.861 174.771C152.339 177.121 147.085 178.308 139.914 178.904C132.737 179.5 123.685 179.5 111.575 179.5H68.425C56.3149 179.5 47.263 179.5 40.0862 178.904C32.9154 178.308 27.6615 177.121 23.1391 174.771C15.4681 170.787 9.21341 164.532 5.22864 156.861C2.87942 152.339 1.6919 147.085 1.0964 139.914C0.500402 132.737 0.5 123.685 0.5 111.575V68.425Z" />
              </symbol>
              <symbol
                id="ShieldSymbol"
                width="146"
                height="214"
                viewBox="0 0 146 214"
              >
                <path d="M38.2521 33.7224L69.5145 2.52884C71.4681 0.579461 74.6318 0.581697 76.5827 2.53383L107.747 33.7174C108.685 34.6557 109.957 35.1829 111.284 35.1829H140C142.761 35.1829 145 37.4215 145 40.1829V141.036C145 142.364 144.472 143.637 143.533 144.575L76.5316 211.474C74.5801 213.422 71.4194 213.423 69.4672 211.475L2.46836 144.624C1.5283 143.686 1 142.412 1 141.084V40.1829C1 37.4215 3.23858 35.1829 6 35.1829H34.7205C36.0447 35.1829 37.3148 34.6577 38.2521 33.7224Z" />
              </symbol>
              <symbol
                id="GhostSymbol"
                width="44"
                height="44"
                viewBox="0 0 44 44"
              >
                <path d="M6.95992 35.6675L0.5 42.9219V21.5H0.5005L0.5222 21.0227C0.765277 15.6761 2.99612 10.5983 6.79721 6.7972C10.8292 2.76517 16.2978 0.5 22 0.5C27.7022 0.5 33.1708 2.76517 37.2028 6.7972C41.0039 10.5983 43.2347 15.6761 43.4778 21.0227L43.4995 21.5H43.5V42.9219L37.0401 35.6675L36.6667 35.2481L36.2933 35.6675L29.3333 43.4834L22.3734 35.6675L22 35.2481L21.6266 35.6675L14.6667 43.4834L7.70674 35.6675L7.33333 35.2481L6.95992 35.6675Z" />
              </symbol>
              <linearGradient id="gradientLeftToRight">
                <stop stopColor={blueprintColors.canvas.fg} offset="0%" />
                <stop
                  stopColor={withOpacity(blueprintColors.canvas.fg, 0)}
                  offset="100%"
                />
              </linearGradient>
              <linearGradient id="gradientRightToLeft">
                <stop
                  stopColor={withOpacity(blueprintColors.canvas.fg, 0)}
                  offset="0%"
                />
                <stop stopColor={blueprintColors.canvas.fg} offset="100%" />
              </linearGradient>
            </defs>
            <FromTabletLgG>
              <VertLine id="centerLine" x={0} xOrigin="center" />
            </FromTabletLgG>
            <circle
              id="horizonArc"
              cx={x.center(-11)}
              cy={y.end(0)}
              r={y.span(totalRows - 6)}
              stroke="url(#gradientRightToLeft)"
            />
            <g id="appIconBoxAndPageTitle">
              <HrztLine id="appIconBoxBottom_&_baselinePageTitle" y={11} />
              <HrztLine id="appIconBoxTop" y={6} />
              <VertLine id="appIconBoxLeft" x={-16} xOrigin="center" />
              <VertLine id="appIconBoxRight" x={-11} xOrigin="center" />
              <use
                xlinkHref="#RoundedAppIconSymbol"
                x={x.center(-16)}
                y={y.start(6)}
                width={x.span(5)}
                height={y.span(5)}
                strokeDasharray="none"
              />
              <circle
                id="appIconBoxNotificationDot"
                cx={x.center(-11.5)}
                cy={y.start(6.5)}
                r={x.span(1)}
              />
            </g>
            <FromTabletG>
              <HrztLine id="baselineSubheading" y={15} />
            </FromTabletG>
            <use
              xlinkHref="#ShieldSymbol"
              x={x.center(-18)}
              y={y.start(12)}
              width={x.span(4)}
              height={x.span(6)}
            />
            <g id="pacManLines">
              <VertLine id="pacManCenterVertLine" x={14} xOrigin="center" />
              <mask
                id="pacManMask"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x={x.center(pacman.x) - x.span(4)}
                y={y.start(pacman.y) - y.span(4)}
                width={x.center(8)}
                height={x.center(8)}
              >
                <path
                  d={pacmanMaskPathDef}
                  fill={colorsV4.white}
                  stroke="none"
                />
              </mask>
              <circle
                id="appIconBoxNotificationDot"
                cx={x.center(pacman.x)}
                cy={y.start(pacman.y)}
                r={x.span(3)}
                mask="url(#pacManMask)"
              />
              <Line
                id="pacManFromCenterDiagonalNW"
                p1={[pacman.x, pacman.y, "center"]}
                p2={[pacman.x + pacman.y, pacman.y - pacman.y, "center"]}
                stroke="url(#gradientLeftToRight)"
              />
              <Line
                id="pacManFromCenterDiagonalSW"
                p1={[pacman.x, pacman.y, "center"]}
                p2={[pacman.x + pacman.y, pacman.y + pacman.y, "center"]}
                stroke="url(#gradientLeftToRight)"
              />
              <use
                xlinkHref="#GhostSymbol"
                x={x.center(pacman.x) + x.span(3) - 22}
                y={y.start(pacman.y) - 22}
                width="44"
                height="44"
                strokeDasharray="2 2"
              />
            </g>
          </SvgLayer>
        ) : null
      }
    </RigidDotGrid>
  );
};
