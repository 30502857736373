import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import ImageA from "../../images/office-image-1.png";
import ImageB from "../../images/office-image-2-wide.png";
import ImageC from "../../images/office-image-5.png";
import ImageD from "../../images/office-image-3.png";
import ImageDWide from "../../images/office-image-3-wide.png";
import {
  fromTablet,
  fromTabletLg,
  mediaFromTo,
  onlyPhones,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import Button from "../forms/Button";
import Spacing from "../layout/Spacing";
import StaticImageFrame from "../basic/StaticImageFrame";
import HeroSection, { HeroSectionDescription } from "./HeroSection";
import AllCaps from "../typography/AllCaps";
import { colors } from "../../styles/colors.styles";

const CareersHeroInnerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
  grid-gap: ${rSize("gap")};
  ${onlyPhones} {
    padding-left: ${rSize("pageMargin")};
    padding-right: ${rSize("pageMargin")};
    grid-template-areas:
      "header header header header"
      "imgA imgA .    .  "
      "imgA imgA imgD imgD"
      "imgB imgB imgD imgD"
      "imgB imgB imgC imgC"
      ".    .    imgC imgC";
  }
  ${fromTablet} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "header header header header header header"
      ".    imgA imgA .    .    ."
      ".    imgA imgA imgD imgD imgD"
      ".    imgA imgA imgC imgC ."
      "imgB imgB imgB imgC imgC ."
      ".    .    .    imgC imgC .";
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(12, 1fr);
    margin-top: -1em;
    grid-template-rows: 2em auto 3em 1em 2em;
    grid-template-areas:
      "imgA imgA  .      .      .      .      .      .      .      .    .    .     "
      "imgA imgA  header header header header header header header header imgD imgD"
      ".    .     header header header header header header header header imgD imgD"
      ".    .     header header header header header header header header .    .   "
      "imgB imgB  imgB   imgB   .      .      .      .      .      .      .    .   "
      "imgB imgB  imgB   imgB   .      .      .      .      imgC   imgC   imgC .   "
      ".    .     .      .      .      .      .      .      imgC   imgC   imgC .   ";
  }
`;

const Header = styled.header`
  grid-area: header;
  text-align: center;
  ${uptoTabletLg} {
    padding-bottom: 2em;
  }
  ${fromTabletLg} {
    padding-left: 1em;
    padding-right: 1em;
  }
  p {
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CareersPageHero = () => {
  return (
    <HeroSection
      backdrop="dots"
      backdropColor={colors.purple200}
      contentRelative
    >
      <CareersHeroInnerGrid>
        <Header>
          <AllCaps color="pink" children="Careers" />
          <Spacing size="1em" />
          <h1>Join our team</h1>
          <Spacing size="1em" />
          <HeroSectionDescription centered>
            We pride ourselves in hiring diverse, ambitious, and smart people,
            allowing them to do their best work every day.
          </HeroSectionDescription>
          <Spacing size="1.5em" />
          <div>
            <Button href="#open-positions" title="See open positions" darker />
          </div>
        </Header>
        <StaticImageFrame
          src={ImageA}
          cover
          gridArea="imgA"
          borderRadius={rSize("radius")}
          backgroundColor={colors.light400}
          fadeInOnLoad
          className={css`
            ${onlyPhones} {
              max-height: 150px;
            }
            ${mediaFromTo("tablet", "tabletLg")} {
              min-height: 200px;
            }
          `}
        />
        <StaticImageFrame
          src={ImageB}
          cover
          gridArea="imgB"
          borderRadius={rSize("radius")}
          backgroundColor={colors.light400}
          fadeInOnLoad
          className={css`
            ${onlyPhones} {
              margin-left: 0;
              max-height: 150px;
            }
            ${fromTabletLg} {
              aspect-ratio: 5 / 3;
              margin-top: ${rSize("gap", -1)};
            }
          `}
        />
        <StaticImageFrame
          src={ImageC}
          cover
          gridArea="imgC"
          borderRadius={`${rSize("radius")}`}
          backgroundColor={colors.light400}
          fadeInOnLoad
          className={css`
            ${onlyPhones} {
              max-height: 200px;
            }
            ${fromTabletLg} {
              aspect-ratio: 1 / 1;
            }
          `}
        />
        <StaticImageFrame
          src={ImageD}
          cover
          gridArea="imgD"
          borderRadius={rSize("radius")}
          backgroundColor={colors.light400}
          fadeInOnLoad
          className={css`
            ${onlyPhones} {
              max-height: 200px;
            }
            ${mediaFromTo("tablet", "tabletLg")} {
              display: none;
            }
          `}
        />
        <StaticImageFrame
          src={ImageDWide}
          cover
          gridArea="imgD"
          borderRadius={rSize("radius")}
          backgroundColor={colors.light400}
          className={css`
            display: none;
            ${onlyPhones} {
              max-height: 200px;
            }
            ${mediaFromTo("tablet", "tabletLg")} {
              display: block;
              max-height: 180px;
            }
            ${fromTabletLg} {
              img {
                max-height: 120px;
              }
            }
          `}
        />
      </CareersHeroInnerGrid>
    </HeroSection>
  );
};

export default CareersPageHero;
