const configs = [
  {
    name: "wistia",
    extract: (url: string) =>
      /https?:\/\/[^.]+\.(wistia\.(com|net)|wi\.st)\/(medias|embed)\/.*/.test(
        url
      )
        ? url
        : undefined,
  },
  {
    name: "loom",
    extract: (url: string) => {
      const regex = /^.*loom\.com\/(?:embed|share)\/([^#&?]*).*/;
      const match = url.match(regex);
      return match ? `https://www.loom.com/embed/${match[1]}` : undefined;
    },
  },
  {
    name: "youtube",
    extract: (url: string) => {
      const regex =
        // cspell:disable-next-line; from https://stackoverflow.com/a/27728417/16401929
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
      const match = url.match(regex);
      return match && !url.match(/notion/) // notion uses v= query param for tables
        ? `https://www.youtube.com/embed/${match[1]}`
        : undefined;
    },
  },
];

export const extractNoteVideoUrl = (url: string) =>
  configs
    .map(config => config.extract(url))
    .find(result => result !== undefined);
