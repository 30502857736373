import { css } from "linaria";
import { PropsWithChildren } from "react";
import {
  fromDesktop,
  fromTablet,
  fromTabletMd,
  onlyPhones,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { BrandColorNameV4, colorV4 } from "../../styles/colorsV4.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PillLegacy from "../basic/PillLegacy";
import StaticImageFrame, {
  StaticImageFrameFigure,
} from "../basic/StaticImageFrame";
import Spacing from "../layout/Spacing";
import CornerDotSet from "../utilities/CornerDotSet";
import ReportCard from "./ReportCard";

type Props = {
  color?: BrandColorNameV4;
  imageSrc?: string;
};

const ReportCaseStudyCard = (props: PropsWithChildren<Props>) => {
  return (
    <ReportCard
      largerHeading
      className={css`
        h2 {
          font-size: 2.4rem;
          margin-bottom: 1.25em;
          ${fromTabletMd} {
            font-size: 3.2rem;
            font-weight: 500;
          }
        }
        h3 {
          margin-top: 1.25em;
          margin-bottom: 1.5em;
          font-size: 1.8rem !important;
          max-width: 30em !important;
          ${onlyPhones} {
            margin-left: 0;
            margin-right: 0;
          }
          ${fromDesktop} {
            margin-top: 1.75em;
            font-size: 2rem !important;
          }
        }
        ${StaticImageFrameFigure} {
          ${onlyPhones} {
            border-radius: 1em;
          }
          ${fromTablet} {
            border-radius: 1.5em;
          }
        }
      `}
    >
      <CornerDotSet
        color={colorV4(props.color ?? "purple", 50)}
        offset={rSize("gap")}
      />
      {props.imageSrc && (
        <>
          <StaticImageFrame src={props.imageSrc} />
          <Spacing size="1em" />
        </>
      )}
      <PillLegacy color={props.color}>Case Study</PillLegacy>
      {props.children}
    </ReportCard>
  );
};

export default ReportCaseStudyCard;
