export const ShieldTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      opacity="0.2"
      d="M8.67157 4.5H5.5V10.8431C5.5 11.904 5.92143 12.9214 6.67157 13.6716L10.2929 17.2929C10.6834 17.6834 11.3166 17.6834 11.7071 17.2929L15.3284 13.6716C16.0786 12.9214 16.5 11.904 16.5 10.8431V4.5H13.3284C12.798 4.5 12.2893 4.28929 11.9142 3.91421L11 3L10.0858 3.91421C9.71071 4.28929 9.20201 4.5 8.67157 4.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.35355 1.64646L9 1.29291L8.64645 1.64646L7.73223 2.56067C7.45093 2.84198 7.0694 3.00001 6.67157 3.00001H3.5H3V3.50001V9.84316C3 11.0366 3.47411 12.1812 4.31802 13.0251L7.93934 16.6465C8.52513 17.2322 9.47487 17.2322 10.0607 16.6465L13.682 13.0251C14.5259 12.1812 15 11.0366 15 9.84316V8.83335L14 9.50001V9.84316C14 10.7714 13.6313 11.6617 12.9749 12.318L9.35355 15.9394C9.15829 16.1346 8.84171 16.1346 8.64645 15.9394L5.02513 12.318C4.36875 11.6617 4 10.7714 4 9.84316V4.00001H6.67157C7.33461 4.00001 7.9705 3.73662 8.43934 3.26778L9 2.70712L9.56066 3.26778C10.0295 3.73662 10.6654 4.00001 11.3284 4.00001H11.7L12.9 3.00001H11.3284C10.9306 3.00001 10.5491 2.84198 10.2678 2.56067L9.35355 1.64646Z"
      fill="currentColor"
    />
    <path
      d="M3.5 9.5C5 9.5 5.8041 9.28571 7 8C7 8.71429 7 9.5 7 10.5C7.81093 10.5 9 10.5 9.9795 9.42857C10.1124 10.8571 9.6606 13.9714 6.79044 15"
      stroke="currentColor"
    />
    <path
      d="M13.4393 4.16896C12.1094 4.89062 11.2342 6.6875 12.3123 7.79688C13.2905 8.80341 15.5 7.79688 15.8281 5.96875C16.0802 4.56426 15.5047 2.86759 15.0916 2C14.875 2.65625 14.503 3.59176 13.4393 4.16896Z"
      stroke="currentColor"
    />
  </svg>
);
