import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import Spacing from "../../layout/Spacing";
import ReportV2ContentCard, {
  ReportV2ContentCardGrid,
} from "../components/ReportV2ContentCard";
import ReportV2DotGridGraph from "../components/ReportV2DotGridGraph";
import { NoBreak } from "../../typography/Nowrap";
import DonutChartV2 from "../components/DonutChartV2";
import { serif } from "../../../styles/fonts.styles";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import { externalLinkAttr } from "../../../constants/externalLinks.constants";
import { StatBuildingsIcon } from "./icons/StatBuildings";
import {
  fromDesktop,
  fromTablet,
  onlyPhones,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const purpleColorSet = [
  colors.purple600,
  colors.purple500,
  colors.purple400,
  colors.purple300,
  colors.purple200,
  colors.purple100,
];

const Header = styled.header`
  ${onlyPhones} {
    display: grid;
    grid-template-areas: "icon" "text";
    grid-gap: 2em;
  }
  ${fromTablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const HeaderInner = styled.div`
  ${onlyPhones} {
    grid-area: text;
  }
  ${fromTablet} {
    max-width: 32em;
    padding-right: 2em;
  }
`;

const IconWrap = styled.div`
  ${onlyPhones} {
    grid-area: icon;
  }
  svg {
    flex: 0 0 auto;
    ${fromDesktop} {
      margin-right: -22px;
    }
  }
`;

const Summary = styled.div`
  font-family: ${serif};
  font-size: 2rem;
  letter-spacing: -0.01em;
  max-width: 25em;
`;

export const SOC2023MethodologySection = () => {
  return (
    <ReportV2Section
      id="methodology-and-participant-demographics"
      backgroundColor={colors.purple50}
      color={colors.purple800}
    >
      <Header>
        <HeaderInner>
          <ReportV2SectionHeadingMedium>
            Methodology and participant demographics
          </ReportV2SectionHeadingMedium>
          <Spacing />
          <ReportV2TextBlock>
            <p>
              Tines surveyed 900 full-time security professionals from companies
              with 200 or more employees. Nearly half (46%) work at companies
              with more than 1,000 employees. There were 500 U.S. respondents,
              along with 100 each from the United Kingdom, Ireland, Benelux, and
              the Nordic region. The survey was conducted online by{" "}
              <a href="https://sago.com/en/" {...externalLinkAttr}>
                Sago
              </a>
              , a research panel company, in May and June 2023.
            </p>
          </ReportV2TextBlock>
        </HeaderInner>
        <IconWrap>
          <StatBuildingsIcon />
        </IconWrap>
      </Header>
      <Spacing size="xl" />
      <ReportV2ContentCardGrid>
        <ReportV2ContentCard wide>
          <h3>Demographic breakdown</h3>
          <ReportV2DotGridGraph
            heading="Gender"
            total={900}
            rows={9}
            gap={0.62}
            dotSize={1}
            series={[
              {
                value: 706,
                label: "Male",
                color: colors.green400,
                labelColor: colors.green,
              },
              {
                value: 4,
                label: <NoBreak>Non-binary</NoBreak>,
                color: colors.orange300,
                labelColor: colors.orange,
              },
              {
                value: 1,
                label: "Agender",
                color: colors.blue200,
                labelColor: colors.blue,
              },
              {
                value: 189,
                label: "Female",
                color: colors.purple,
                labelFlexBasis: "1px",
              },
            ]}
          />
          <Spacing size=".5em" />
          <ReportV2DotGridGraph
            heading="Age"
            total={900}
            rows={9}
            gap={0.62}
            dotSize={1}
            series={[
              { value: 900 * 0.33, label: "25–34", color: colors.blue },
              { value: 900 * 0.45, label: "35–44", color: colors.orange },
              { value: 900 * 0.15, label: "45–54", color: colors.purple },
              {
                value: 900 * 0.07,
                label: "55+",
                color: colors.pink300,
                labelColor: colors.pink,
              },
            ]}
          />
          <Spacing size=".5em" />
          <ReportV2DotGridGraph
            heading="Country"
            total={900}
            rows={9}
            gap={0.62}
            dotSize={1}
            showOriginalValue
            series={[
              {
                value: 500,
                label: "United States",
                color: colors.purple,
              },
              {
                value: 100,
                label: "UK",
                color: colors.pink300,
                labelColor: colors.pink,
              },
              {
                value: 100,
                label: "Ireland",
                color: colors.green600,
              },
              {
                value: 100,
                label: "Benelux",
                color: colors.yellow300,
                labelColor: colors.yellow,
              },
              {
                value: 100,
                label: "Nordics",
                color: colors.blue,
              },
            ]}
          />
          <Spacing size=".5em" />
          <ReportV2DotGridGraph
            heading="Employment status"
            total={900}
            rows={9}
            gap={0.62}
            dotSize={1}
            series={[
              {
                value: 900 * 0.33,
                label: "Full-time employee",
                color: colors.pink300,
                labelColor: colors.pink,
              },
            ]}
          />
          <Spacing size=".5em" />
          <ReportV2DotGridGraph
            heading="Company size"
            total={900}
            rows={9}
            gap={0.62}
            dotSize={1}
            series={[
              {
                value: 900 * 0.2,
                label: "200–499",
                color: colors.green300,
                labelColor: colors.green,
              },
              {
                value: 900 * 0.34,
                label: "500–999",
                color: colors.blue600,
                labelColor: colors.blue,
              },
              {
                value: 900 * 0.31,
                label: "1,000–4,999",
                color: colors.pink300,
                labelColor: colors.pink,
              },
              {
                value: 900 * 0.15,
                label: "5000+",
                color: colors.purple500,
                labelColor: colors.purple,
              },
            ]}
          />
        </ReportV2ContentCard>

        <ReportV2ContentCard wide centeredOnDesktop>
          <h3>What best describes the industry you work in?</h3>
          <DonutChartV2
            seoTitle="What best describes the industry you work in?"
            header={["Industry", "Respondents"]}
            data={[
              ["Technology", 900 * 0.7],
              ["Finance", 900 * 0.06],
              ["Retail", 900 * 0.06],
              ["Manufacturing", 900 * 0.05],
              ["Other", 900 * 0.13],
            ]}
            donutPercentage={0.375}
            height={250}
            textColor={colors.purple800}
            colors={purpleColorSet}
          />
        </ReportV2ContentCard>

        <ReportV2ContentCard>
          <h3>How many people are on your security team in total?</h3>
          <DonutChartV2
            seoTitle="How many people are on your security team in total?"
            header={["Number of people", "Respondents"]}
            data={[
              ["< 10", 900 * 0.08],
              ["10–19", 900 * 0.15],
              ["20–29", 900 * 0.23],
              ["30–39", 900 * 0.21],
              ["40–49", 900 * 0.15],
              ["50+", 900 * 0.18],
            ]}
            textColor={colors.purple800}
            colors={purpleColorSet}
            donutPercentage={0.375}
            height={250}
          />
        </ReportV2ContentCard>

        <ReportV2ContentCard>
          <h3>
            Which of the following best describes your security team in terms of
            work location?
          </h3>
          <DonutChartV2
            seoTitle="Which of the following best describes your security team in terms of work location?"
            header={["Work location", "Respondents"]}
            data={[
              ["All office", 900 * 0.3],
              ["Mostly office", 900 * 0.37],
              ["Half-half", 900 * 0.24],
              ["Mostly remote", 900 * 0.07],
              ["All remote", 900 * 0.02],
            ]}
            textColor={colors.purple800}
            colors={purpleColorSet}
            donutPercentage={0.375}
            height={250}
          />
        </ReportV2ContentCard>

        <ReportV2ContentCard>
          <h3>
            How many different tools do you use for your security-related work?
          </h3>
          <DonutChartV2
            seoTitle="How many different tools do you use for your security-related work?"
            header={["Number of tools", "Respondents"]}
            data={[
              ["1–3", 900 * 0.21],
              ["4–5", 900 * 0.3],
              ["6–10", 900 * 0.28],
              ["11–24", 900 * 0.12],
              ["25–49", 900 * 0.05],
              ["50+", 900 * 0.04],
            ]}
            textColor={colors.purple800}
            colors={purpleColorSet}
            donutPercentage={0.375}
            height={250}
          />
        </ReportV2ContentCard>

        <ReportV2ContentCard>
          <h3>Which of the following best describes your title?</h3>
          <DonutChartV2
            seoTitle="Which of the following best describes your title?"
            header={["Work title", "Respondents"]}
            data={[
              ["Practitioner", 900 * 0.53],
              ["Management", 900 * 0.27],
              ["Executive", 900 * 0.2],
            ]}
            textColor={colors.purple800}
            colors={[colors.purple500, colors.purple300, colors.purple100]}
            donutPercentage={0.375}
            height={250}
          />
        </ReportV2ContentCard>
      </ReportV2ContentCardGrid>

      <Spacing size="xl" />

      <Summary>
        <p>
          To summarize, our respondents are typically security professionals,
          the majority of whom work for companies in the technology industry
          with more than 500 employees. Let’s explore their day-to-day
          experiences in the SOC.
        </p>
      </Summary>
    </ReportV2Section>
  );
};
