import { css } from "@linaria/core";
import { onlyPhones } from "../breakpointsAndMediaQueries.styles";

export const monospaceFontFaces = css`
  :global() {
    @font-face {
      font-family: "JetBrains Mono";
      font-style: normal;
      font-weight: 400;
      src: url("/fonts/JetBrainsMono-Regular.woff2") format("woff2");
      font-display: block;
    }
    ${onlyPhones} {
      @font-face {
        font-family: "JetBrains Mono";
        font-style: normal;
        font-weight: 400;
        src: url("/fonts/JetBrainsMono-Regular.woff2") format("woff2");
        font-display: swap;
      }
    }
    @font-face {
      font-family: "JetBrains Mono";
      font-style: normal;
      font-weight: 500;
      src: url("/fonts/JetBrainsMono-Medium.woff2") format("woff2");
      font-display: block;
    }
    ${onlyPhones} {
      @font-face {
        font-family: "JetBrains Mono";
        font-style: normal;
        font-weight: 500;
        src: url("/fonts/JetBrainsMono-Medium.woff2") format("woff2");
        font-display: swap;
      }
    }
    /* @font-face {
      font-family: "JetBrains Mono";
      font-style: normal;
      font-weight: 600;
      src: url("/fonts/JetBrainsMono-SemiBold.woff2") format("woff2");
      font-display: block;
    }
    ${onlyPhones} {
      @font-face {
        font-family: "JetBrains Mono";
        font-style: normal;
        font-weight: 600;
        src: url("/fonts/JetBrainsMono-SemiBold.woff2") format("woff2");
        font-display: swap;
      }
    } */
    @font-face {
      font-family: "JetBrains Mono";
      font-style: normal;
      font-weight: 700;
      src: url("/fonts/JetBrainsMono-Bold.woff2") format("woff2");
      font-display: block;
    }
    ${onlyPhones} {
      font-family: "JetBrains Mono";
      font-style: normal;
      font-weight: 700;
      src: url("/fonts/JetBrainsMono-Bold.woff2") format("woff2");
      font-display: swap;
    }
  }
`;

export const globalMonospaceFontStyles = css`
  :global() {
    code,
    pre,
    kbd,
    samp {
      font-family: "JetBrains Mono", menlo, monospace;
      /** subjective opinion: the slashed zero is prettier */
      font-feature-settings: "zero";
      /** we are not loading a bold weight for the monospace font, so we will just force everything to be 400 to prevent faux bold */
      font-weight: 400;
    }
  }
`;
