import { styled } from "@linaria/react";
import {
  fromPhoneLg,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { colors, withOpacity } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import PageSection from "../reusableSections/PageSection";
import Spacing from "../layout/Spacing";
import { font } from "../../styles/fonts.styles";
import { StructuredTextWithCustomBlocks } from "../articles/StructuredTextWithCustomBlocks";
import { DatoCmsYdwwt } from "../../../graphql-types";
import { fluidFontSize } from "../../styles/helpers.styles";
import InfoBox from "../general/InfoBox";
import { Link } from "gatsby";
import { Paths } from "../../utils/pathBuilders.utils";

const SectionInner = styled.div`
  text-align: center;
  header {
    text-align: center;
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
    > * {
      + * {
        margin-top: 1em;
      }
    }
    h2 {
      ${fluidFontSize(32, 46)};
      line-height: 1.1;
      letter-spacing: -0.01em;
      font-family: ${font("serif")};
      font-weight: 400;
      text-align: center;
      + * {
        margin-top: ${rSize("md")};
      }
    }
  }
`;

const PrizesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 5em;
  font-weight: 500;
  font-size: 1.6rem;
  padding-top: 4em;
  ${fromTabletLg} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: ${rSize("gap")};
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    color: ${colors.white};
    border-radius: ${rSize("radius")};
    position: relative;
    &:nth-of-type(1) {
      background-color: ${colors.green600};
      border: 1px solid ${colors.green400};
      img {
        transform: rotate(-5deg);
      }
    }
    &:nth-of-type(2) {
      background-color: ${colors.purple600};
      border: 1px solid ${colors.purple400};
      img {
        transform: rotate(-1deg);
      }
    }
    &:nth-of-type(3) {
      background-color: ${colors.pink600};
      border: 1px solid ${colors.pink400};
      img {
        transform: rotate(5deg);
      }
    }
  }
  article {
    padding: 2em;
    padding-top: 5em;
    ${fromPhoneLg} {
      padding-bottom: 4em;
    }
  }
  h3 {
    font-size: 2.8rem;
    letter-spacing: -0.02rem;
    margin-bottom: 0.75em;
    max-width: 100%;
    font-family: ${font("serif")};
    font-weight: 400;
  }
  figure {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    filter: drop-shadow(0 0.1em 0.25em ${withOpacity(colors.darkest, 0.1)});
  }
`;

const Footer = styled.div`
  max-width: 40em;
  margin-top: ${rSize("xl")};
  margin-left: auto;
  margin-right: auto;
`;

const YDWWTPrizesSection = (props: { ydwwt: DatoCmsYdwwt }) => {
  return (
    <PageSection>
      <SectionInner>
        <header>
          <StructuredTextWithCustomBlocks
            value={props.ydwwt.prizesSectionCopy}
            linkedHeadingMaxLevel="off"
          />
        </header>

        <Spacing size="xl" />

        <PrizesGrid>
          {(props.ydwwt.prizes ?? []).map(p => (
            <div key={p!.id}>
              <article>
                <h3>{p!.prizeType}</h3>
                <StructuredTextWithCustomBlocks
                  value={p!.summary}
                  linkedHeadingMaxLevel="off"
                />
                {p?.image?.url && (
                  <figure>
                    <img src={p?.image?.url} />
                  </figure>
                )}
              </article>
            </div>
          ))}
        </PrizesGrid>

        <Footer>
          <InfoBox>
            <p>
              Read the promotional terms for the YDWWT competition{" "}
              <Link to={Paths.ydwwtTerms()}>here</Link>.
            </p>
          </InfoBox>
        </Footer>
      </SectionInner>
    </PageSection>
  );
};

export default YDWWTPrizesSection;
