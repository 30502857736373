import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../../styles/colors.styles";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingLarge } from "../components/ReportV2SectionHeadingLarge";
import Button, { ButtonGroupKeepFlexOnMobile } from "../../forms/Button";
import BookADemoButton from "../../utilities/BookADemoButton";
import Spacing from "../../layout/Spacing";
import { serif } from "../../../styles/fonts.styles";
import AllCaps from "../../typography/AllCaps";
import { shareGuideSecurityAccessPage } from "./guideSecurity.utils";
import MaxWidth from "../../layout/MaxWidth";

const Body = styled.div`
  p {
    font-family: ${serif};
    font-size: 1.8rem;
    max-width: 30em;
    + p {
      margin-top: 1em;
    }
  }
`;

export const GuideSecurityConclusionSection = () => {
  return (
    <ReportV2Section
      id="conclusion"
      backgroundColor={colors.purple900}
      color={colors.white}
      isLastSection
      backdropDotGridColor={withOpacity(colors.purple800, 0.8)}
    >
      <AllCaps as="p">Conclusion</AllCaps>
      <Spacing />
      <ReportV2SectionHeadingLarge>
        <MaxWidth maxWidth="10em">
          The future is workflow automation + AI
        </MaxWidth>
      </ReportV2SectionHeadingLarge>
      <Spacing size="lg" />
      <Body>
        <p>
          Workflow automation isn't simply taking the monotonous tasks off your
          team's plate, but giving your security practitioners the tools to get
          creative with what they automate and evolve the efficiency of their
          workflows. And AI can make these workflows easier to build and run.
          Not only will your teams become more efficient and processes more
          streamlined, but your people will be empowered to focus more time and
          energy on the work that matters most.
        </p>
      </Body>
      <Spacing size="lg" />
      <ButtonGroupKeepFlexOnMobile>
        <Button
          onClick={shareGuideSecurityAccessPage}
          color="purple"
          darker
          appearance="filled-white"
        >
          Share this guide
        </Button>
        <BookADemoButton appearance="outlined" color="white" />
      </ButtonGroupKeepFlexOnMobile>
    </ReportV2Section>
  );
};
