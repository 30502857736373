export const ProServIlloTopLeftFloatingElements = () => (
  <svg
    width="174"
    height="222"
    viewBox="0 0 174 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M126.783 211.494C130.599 211.494 133.692 210.973 133.692 210.33C133.692 209.688 130.599 209.167 126.783 209.167C122.968 209.167 119.875 209.688 119.875 210.33C119.875 210.973 122.968 211.494 126.783 211.494Z"
      fill="#4D3E78"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M146.716 210.576C146.716 209.242 136.053 208.16 122.899 208.16C109.746 208.16 99.082 209.242 99.082 210.576C99.082 211.518 99.082 217.29 99.082 218.233C99.082 219.567 109.745 221.301 122.899 221.301C136.052 221.301 146.716 219.567 146.716 218.233C146.716 217.29 146.716 211.519 146.716 210.576Z"
      fill="#C1AAF7"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M122.899 213.181C136.053 213.181 146.716 212.057 146.716 210.67C146.716 209.284 136.053 208.16 122.899 208.16C109.745 208.16 99.082 209.284 99.082 210.67C99.082 212.057 109.745 213.181 122.899 213.181Z"
      fill="#D7C4FA"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M167.819 1.17725C166.586 1.17725 165.449 1.5878 164.537 2.27896C163.626 1.5878 162.489 1.17725 161.255 1.17725C158.254 1.17725 155.82 3.60666 155.82 6.60318C155.82 9.59885 158.254 12.0283 161.255 12.0283C162.489 12.0283 163.626 11.6186 164.537 10.9266C165.449 11.6186 166.586 12.0283 167.819 12.0283C170.821 12.0283 173.255 9.59885 173.255 6.60318C173.255 3.60666 170.821 1.17725 167.819 1.17725Z"
      fill="#FEEBEE"
      stroke="#483C72"
      strokeWidth="1.23594"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.864 117.796C48.4943 117.796 47.1908 118.075 46.0059 118.579C44.6229 111.181 38.125 105.581 30.3133 105.581C22.5395 105.581 16.067 111.125 14.6397 118.468C13.5296 118.037 12.324 117.796 11.0603 117.796C5.62117 117.796 1.21094 122.198 1.21094 127.628C1.21094 133.058 5.62117 137.459 11.0603 137.459C14.4969 137.459 26.0332 137.459 30.3127 137.459C34.6844 137.459 46.3513 137.459 49.8634 137.459C55.3031 137.459 59.7127 133.058 59.7127 127.628C59.7133 122.198 55.3036 117.796 49.864 117.796Z"
      fill="#FEEBEE"
      stroke="#483C72"
      strokeWidth="1.23594"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.7127 103.125C56.2231 103.125 53.1233 104.785 51.16 107.356C49.7373 106.48 48.0676 105.965 46.2736 105.965C41.1218 105.965 36.9453 110.134 36.9453 115.276C36.9453 120.418 41.1218 124.586 46.2736 124.586C48.7752 124.586 57.0401 124.586 59.7133 124.586C65.6504 124.586 70.4642 119.782 70.4642 113.855C70.4631 107.929 65.6498 103.125 59.7127 103.125Z"
      fill="#FEEBEE"
      stroke="#483C72"
      strokeWidth="1.23594"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <ellipse
      cx="122.244"
      cy="210.234"
      rx="8.60742"
      ry="1.30174"
      fill="#4D3E78"
    />
    <path
      d="M130.813 194.224C129.552 193.524 128.238 191.807 127.89 190.411L126.444 184.616C126.094 183.215 125.234 183.098 124.537 184.359L121.631 189.573C120.932 190.828 119.214 192.14 117.809 192.482L112.012 193.917C110.612 194.264 110.499 195.118 111.756 195.819L116.983 198.728C118.247 199.429 119.561 201.144 119.908 202.542L121.355 208.339C121.703 209.735 122.563 209.851 123.259 208.593L126.169 203.377C126.867 202.123 128.587 200.811 129.984 200.467L135.79 199.034C137.188 198.689 137.3 197.833 136.042 197.133L130.813 194.224Z"
      fill="#FBBD74"
      stroke="#4D3E78"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
