import { styled } from "@linaria/react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  from12ColGrid,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { ImageBlockContainer } from "../blocks/ImageBlock.block";
import { StoryEmbedWrapper } from "../blocks/StoryEmbed.block";

export const ArticleLikePageContent = styled.div`
  ${StoryEmbedWrapper} {
    margin-top: ${rSize("xl")} !important;
    margin-bottom: ${rSize("xl")} !important;
  }
  ${fromTabletMd} {
    padding-left: ${widthInGrid(1, 1)};
    padding-right: ${widthInGrid(1, 1)};
    ${ImageBlockContainer}, ${StoryEmbedWrapper} {
      &.showInFullPageWidth {
        margin: ${rSize("xl")} ${widthInGrid(1, 1, -1)};
        max-width: calc(
          100% + ${widthInGrid(2, 1, undefined, undefined, true)}
        );
      }
    }
  }
  ${from12ColGrid} {
    padding-left: ${widthInGrid(2, 1)};
    padding-right: ${widthInGrid(2, 1)};
    ${ImageBlockContainer}, ${StoryEmbedWrapper} {
      &.showInFullPageWidth {
        margin-left: ${widthInGrid(2, 1, -1)};
        margin-right: ${widthInGrid(2, 1, -1)};
        max-width: calc(
          100% + ${widthInGrid(4, 1, undefined, undefined, true)}
        );
      }
    }
  }
`;
