import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import YDWWTCategoryWinnerSet from "./YDWWTCategoryWinnerSet";
import sheriffBadgeIcon from "./stickerIcons/sheriffBadge.stickerIcon.svg";
import {
  DatoCmsCompetitionCategoryWinner,
  DatoCmsYdwwt,
} from "../../../graphql-types";
import { convertDatoRecordToStoryDescriptor } from "../../utils/story.utils";
import SpaceChildren from "../layout/SpaceChildren";
import ReactMarkdown from "react-markdown";
import { ColorThemeNameV4 } from "../../styles/colorsV4.styles";
import { StructuredText } from "react-datocms";

const YDWWTCategoryWinnersSection = (props: { ydwwt: DatoCmsYdwwt }) => {
  return (
    <PageSection centered>
      <img src={sheriffBadgeIcon} />
      <Spacing size="lg" />
      <SpaceChildren size="sectionPadding">
        <SectionHeading2 className="smaller">
          <Serif>Category winners</Serif>
        </SectionHeading2>
        {props.ydwwt.categoryWinners?.map(c => {
          const category = c as DatoCmsCompetitionCategoryWinner;
          const winnerRecord = category.winners?.[0];
          const runnerUpRecord = category.winners?.[1];
          const winner = winnerRecord?.story
            ? convertDatoRecordToStoryDescriptor(winnerRecord.story)
            : null;
          const runnerUp = runnerUpRecord?.story
            ? convertDatoRecordToStoryDescriptor(runnerUpRecord.story)
            : null;
          return (
            <YDWWTCategoryWinnerSet
              key={category.id}
              name={`${category.name}`}
              description={
                <StructuredText data={category.description?.value} />
              }
              color={category.color?.toLowerCase() as ColorThemeNameV4}
              icon={category.icon?.url}
              winner={
                winner
                  ? Object.assign(winner, {
                      color: category.color || winner.color,
                      name: winnerRecord?.name || winner.name,
                    })
                  : undefined
              }
              winnerDescription={
                winnerRecord?.description ? (
                  <ReactMarkdown>{winnerRecord?.description}</ReactMarkdown>
                ) : (
                  <p>{winner?.description}</p>
                )
              }
              runnerUp={
                runnerUp
                  ? Object.assign(runnerUp, {
                      color: category.color || runnerUp.color,
                      name: runnerUpRecord?.name || runnerUp.name,
                    })
                  : undefined
              }
              runnerUpDescription={
                runnerUpRecord?.description ? (
                  <ReactMarkdown>{runnerUpRecord?.description}</ReactMarkdown>
                ) : (
                  <p>{runnerUp?.description}</p>
                )
              }
            />
          );
        })}
      </SpaceChildren>
    </PageSection>
  );
};

export default YDWWTCategoryWinnersSection;
