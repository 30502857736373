export const CommunityTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
    <path
      opacity="0.1"
      d="M7 9.91514V15.5C7 15.7761 7.22386 16 7.5 16H17.5C17.7761 16 18 15.7761 18 15.5V9.91514C18 9.76862 17.9357 9.62948 17.8242 9.53448L12.8242 5.27611C12.6374 5.11698 12.3626 5.11698 12.1758 5.27611L7.17581 9.53448C7.06426 9.62948 7 9.76862 7 9.91514Z"
    />
    <rect opacity="0.5" x="8" y="10" width="2" height="2" />
    <rect opacity="0.5" x="12" y="10" width="2" height="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62611 2.18642C5.4319 1.86275 4.96281 1.86275 4.76861 2.18642L3.5348 4.24276C3.38409 4.49396 3.48191 4.80395 3.71786 4.93731C3.84196 5.00744 3.93022 5.12721 3.96046 5.26651C3.99071 5.40581 3.96006 5.5514 3.87621 5.66668L2.75676 7.20593C2.54715 7.49415 2.70053 7.89279 3.02169 7.98166C3.17627 8.02444 3.30095 8.13878 3.35691 8.28909C3.41288 8.43941 3.39333 8.60745 3.30436 8.7409L1.64985 11.2227C1.42833 11.5549 1.66653 12 2.06588 12H4.6974H5V8.91514C5 8.62211 5.12853 8.34382 5.35161 8.15383L7.18916 6.58883L6.51851 5.66668C6.43467 5.5514 6.40401 5.40581 6.43426 5.26651C6.4645 5.12721 6.55276 5.00744 6.67686 4.93731C6.91281 4.80395 7.01063 4.49396 6.85992 4.24277L5.62611 2.18642ZM7.95271 5.93853L7.59469 5.44625C7.96686 4.98596 8.06808 4.31271 7.71741 3.72827L6.4836 1.67192C5.901 0.700914 4.49373 0.700911 3.91112 1.67192L2.67731 3.72827C2.32665 4.31271 2.42786 4.98596 2.80003 5.44625L1.94803 6.61776C1.45585 7.2945 1.62918 8.15186 2.17527 8.63176L0.817801 10.668C0.153246 11.6648 0.867836 13 2.06588 13H4.4474V14.4798C4.4474 14.5558 4.41287 14.6276 4.35357 14.675L3.25387 15.5548C3.06934 15.7024 3.17373 16 3.41005 16H6.78049L6.78327 16H16C16.5523 16 17 15.5523 17 15V8.91514C17 8.62211 16.8715 8.34382 16.6484 8.15383L11.6484 3.89545C11.2747 3.5772 10.7253 3.5772 10.3516 3.89545L7.95271 5.93853ZM16 15H6.00398L6 14.9966V8.91514L11 4.65676L16 8.91514V15Z"
    />
  </svg>
);
