import { DatoCmsFaqQuestion, DatoCmsYdwwt } from "../../../graphql-types";
import FaqListSection from "../reusableSections/FaqListSection";

const YDWWTFaqSection = (props: { ydwwt: DatoCmsYdwwt }) => {
  return (
    <FaqListSection
      datoRecords={(props.ydwwt.faq ?? []) as DatoCmsFaqQuestion[]}
    />
  );
};

export default YDWWTFaqSection;
