import Page from "./Page.template";
import ogImage from "../../static/images/og/tines-build-apps-with-tines.png";
import SEO from "../components/utilities/SEO";
import { PageComponentProps } from "../types/gatsby.types";
import { StoryDescriptor } from "../types/helper.types";
import BuildAppsPageHero from "../components/buildApps/BuildAppsPageHero";
import WorkflowsFuelCollabSection from "../components/buildApps/WorkflowsFuelCollabSection";
import MichelangeloSection from "../components/buildApps/MichelangeloSection";
import Spacing from "../components/layout/Spacing";
import CultureOfCyberSecuritySection from "../components/buildApps/CultureOfCyberSecuritySection";
import BuildAppsStoryGridSection from "../components/buildApps/BuildAppsStoryGridSection";
import BuildAppsPageCTASection from "../components/buildApps/BuildAppsPageCTASection";

type Props = {
  pageContext: {
    stories: StoryDescriptor[];
  };
};

const BuildAppsPage = (props: PageComponentProps<Props>) => {
  const description =
    "Build apps with Tines to collaborate faster and more effectively while securing your business.";
  const demoStory = props.pageContext.stories.find(s => s.id === "1142877")!;
  return (
    <Page {...props}>
      <SEO title="Build apps" description={description} image={ogImage} />
      <BuildAppsPageHero demoStory={demoStory} />
      <Spacing size="sectionMargin" />
      <WorkflowsFuelCollabSection />
      <MichelangeloSection />
      <Spacing size="sectionMargin" />
      <CultureOfCyberSecuritySection />
      <Spacing size="sectionMargin" />
      <BuildAppsStoryGridSection
        stories={props.pageContext.stories.filter(s => s.id !== "1142877")}
      />
      <Spacing size="sectionMargin" />
      <BuildAppsPageCTASection />
      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default BuildAppsPage;
