export const ArrowRightIcon = () => (
  <svg
    className="ArrowRightIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M13.4697 17.5303C13.1768 17.2374 13.1768 16.7626 13.4697 16.4697L17.1893 12.75L6 12.75C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25L17.1893 11.25L13.4697 7.53033C13.1768 7.23744 13.1768 6.76256 13.4697 6.46967C13.7626 6.17678 14.2374 6.17678 14.5303 6.46967L19.5303 11.4697C19.6022 11.5416 19.6565 11.6245 19.6931 11.7129C19.7298 11.8013 19.75 11.8983 19.75 12C19.75 12.1017 19.7298 12.1987 19.6931 12.2871C19.6565 12.3755 19.6022 12.4584 19.5303 12.5303L14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303Z" />
  </svg>
);
