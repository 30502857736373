import { styled } from "@linaria/react";
import {
  DatoCmsFeatureHighlight,
  DatoCmsFeatureHighlightsSection,
} from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import { FeatureHighlightCard } from "../../general/FeatureHighlightCard";
import { rSize } from "../../../styles/responsiveSizes.styles";
import {
  fromTablet,
  fromTabletLg,
} from "../../../styles/breakpointsAndMediaQueries.styles";

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-auto-rows: 1fr;
  grid-template-columns: minmax(0, 1fr);
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const LPFeatureHighlightsSection = ({
  section,
}: {
  section: DatoCmsFeatureHighlightsSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Grid>
        {section.featureHighlights?.map((f, i) => (
          <FeatureHighlightCard key={i} card={f as DatoCmsFeatureHighlight} />
        ))}
      </Grid>
    </PageSection>
  );
};
