import { styled } from "@linaria/react";
import { onlyPhones } from "../../styles/breakpointsAndMediaQueries.styles";

const MobileHidden = styled.div`
  ${onlyPhones} {
    display: none;
  }
`;
export const MobileHiddenSpan = styled.span`
  ${onlyPhones} {
    display: none;
  }
`;

export default MobileHidden;
