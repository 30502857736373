export const PianoIcon = () => (
  <svg
    width="135"
    height="60"
    viewBox="0 0 135 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M134 43.3896H59.761V58.6345H109.774L134 43.3896Z"
      fill="#32274C"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5839 29.8325H119.324V43.0126L93.45 58.6345H0.727905V45.4549L26.5839 29.8325Z"
      fill="#FABE64"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.1241 44.7008H80.4651L103.851 30.7153H114.51L91.1241 44.7008Z"
      fill="#32274C"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.4428 26.4668H27.7833L6.46515 39.2579V43.7323H17.1244L38.4428 30.941V26.4668Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1244 39.2579H6.46515L27.7833 26.4668H38.4428L17.1244 39.2579Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1244 39.2578V43.7323"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.102 29.6641H38.4428L17.1244 42.4552V46.9294H27.7833L49.102 34.1383V29.6641Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.7833 42.4552H17.1244L38.4428 29.6641H49.102L27.7833 42.4552Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.7834 46.9293V42.4551"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.761 26.4668H49.102L27.7833 39.2579V43.7323H38.4428L59.761 30.941V26.4668Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.4428 39.2579H27.7833L49.102 26.4668H59.761L38.4428 39.2579Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.4428 43.7323V39.2578"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.4205 26.4668H59.761L38.4428 39.2579V43.7323H49.1021L70.4205 30.941V26.4668Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.1021 39.2579H38.4428L59.761 26.4668H70.4205L49.1021 39.2579Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.1021 43.7323V39.2578"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.0797 28.5986H70.4205L49.1021 41.3895V45.864H59.761L81.0797 33.0729V28.5986Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.761 41.3895H49.1021L70.4205 28.5986H81.0797L59.761 41.3895Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.761 45.8641V41.3896"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.7389 26.4668H81.0796L59.761 39.2579V43.7323H70.4204L91.7389 30.941V26.4668Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.4204 39.2579H59.761L81.0796 26.4668H91.7389L70.4204 39.2579Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.4205 43.7323V39.2578"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.398 28.3623H91.7389L70.4205 41.153V45.6274H81.0797L102.398 32.8363V28.3623Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.0797 41.153H70.4205L91.7389 28.3623H102.398L81.0797 41.153Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.0797 45.6278V41.1533"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M113.057 26.4668H102.398L81.0797 39.2579V43.7323H91.7389L113.057 30.941V26.4668Z"
      fill="#FDB2D2"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.7389 39.2579H81.0797L102.398 26.4668H113.057L91.7389 39.2579Z"
      fill="#FAE1EC"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.7389 43.7323V39.2578"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.1805 43.7812H27.8722L28.0057 46.6682L32.1805 43.7812Z"
      fill="#32274C"
    />
    <path
      d="M59.8055 43.959L59.761 45.5579L62.4261 44.0035L59.8055 43.959Z"
      fill="#32274C"
    />
    <path
      d="M38.528 29.7913L17.3549 42.3161V39.1102L38.528 26.5854V29.7913Z"
      fill="#32274C"
    />
    <path
      d="M81.0797 43.7324L81.1682 45.3805L83.5665 43.8261L81.0797 43.7324Z"
      fill="#32274C"
    />
    <path
      d="M56.8324 21.0781H49.2433L35.9553 29.482V32.1898H43.5448L56.8324 23.7861V21.0781Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5448 29.482H35.9553L49.2433 21.0781H56.8324L43.5448 29.482Z"
      fill="#B0A6F9"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5448 32.1897V29.4819"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.38 21.0781H27.7907L14.5029 29.482V32.1898H22.0922L35.38 23.7861V21.0781Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0922 29.482H14.5029L27.7907 21.0781H35.38L22.0922 29.482Z"
      fill="#B0A6F9"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0922 32.1897V29.4819"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.3304 20.96H60.7413L47.4535 29.3636V32.0716H55.0428L68.3304 23.668V20.96Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.0428 29.3636H47.4535L60.7413 20.96H68.3304L55.0428 29.3636Z"
      fill="#B0A6F9"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.0428 32.0713V29.3633"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.66 28.5979L49.0399 41.1973V38.9608L70.66 26.3613V28.5979Z"
      fill="#32274C"
    />
    <path
      d="M91.9073 28.5979L70.2874 41.1973V38.9608L91.9073 26.3613V28.5979Z"
      fill="#32274C"
    />
    <path
      d="M89.284 20.96H81.6947L68.4069 29.3636V32.0716H75.9962L89.284 23.668V20.96Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.9962 29.3636H68.4069L81.6947 20.96H89.284L75.9962 29.3636Z"
      fill="#B0A6F9"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.9962 32.0713V29.3633"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.774 20.96H102.184L88.8962 29.3636V32.0716H96.4858L109.774 23.668V20.96Z"
      fill="#8173E6"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.4858 29.3636H88.8962L102.184 20.96H109.774L96.4858 29.3636Z"
      fill="#B0A6F9"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.4858 32.0713V29.3633"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.4501 45.4551H0.655029V58.6349H93.4501V45.4551Z"
      fill="#FFD18C"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.4501 45.4549L119.324 29.8325"
      stroke="#32274C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.1266 43.5293H30.9445V45.3295H49.1266V43.5293Z"
      fill="#32274C"
    />
    <path d="M70.369 43.5293H60.4678V45.1495H70.369V43.5293Z" fill="#32274C" />
    <path
      d="M16.9153 43.5293H7.90863V45.1495H16.9153V43.5293Z"
      fill="#32274C"
    />
    <path d="M91.791 43.3491H81.17V44.9693H91.791V43.3491Z" fill="#32274C" />
    <path
      d="M55.4984 2.6511L55.801 3.82686L56.1038 5.00262L57.4258 10.1382C56.9293 10.0031 56.357 9.99305 55.7766 10.1425C54.3374 10.5128 53.4016 11.7098 53.6863 12.8154C53.9708 13.9213 55.3684 14.5172 56.8074 14.1468C58.2435 13.7773 59.178 12.5843 58.8988 11.4804H58.8993L57.1615 4.73043L63.6598 3.0577L64.9818 8.19285C64.4851 8.05776 63.913 8.04769 63.3326 8.19709C61.8936 8.56747 60.9578 9.76447 61.2423 10.87C61.5268 11.9758 62.9244 12.5718 64.3634 12.2014C65.7993 11.8317 66.734 10.639 66.4548 9.535H66.4553L64.7175 2.78507L64.4146 1.60909L64.112 0.433105L55.4984 2.6511Z"
      fill="#F3ECF7"
    />
    <path
      d="M45.1921 4.07006L42.9759 13.772H42.9754C42.7006 15.0453 41.1363 15.7932 39.4761 15.4421C37.8124 15.0905 36.6829 13.7691 36.9528 12.4905C37.2232 11.2121 38.7911 10.4613 40.4548 10.8126C41.1258 10.9546 41.7073 11.2559 42.1477 11.6466L43.9687 3.81152L45.1921 4.07006Z"
      fill="#F3ECF7"
    />
    <path
      d="M82.6273 3.44336L84.154 12.8076H84.1536C84.3678 14.0312 83.2547 15.254 81.6625 15.5398C80.0663 15.8265 78.5944 15.0647 78.3743 13.8389C78.1538 12.613 79.2691 11.386 80.8647 11.0998C81.5084 10.9837 82.1302 11.0416 82.6593 11.2281L81.4545 3.65449L82.6273 3.44336Z"
      fill="#F3ECF7"
    />
    <path
      d="M99.6605 1.04893L97.9665 10.3843L97.9663 10.3841C97.7585 11.6091 96.3007 12.3887 94.7047 12.1255C93.1047 11.8618 91.9723 10.6514 92.175 9.42239C92.3771 8.1934 93.8387 7.41038 95.4381 7.67429C96.0836 7.78053 96.6503 8.04287 97.0864 8.3958L98.4847 0.855469L99.6605 1.04893Z"
      fill="#F3ECF7"
    />
  </svg>
);
