export const PageIconPalace = () => (
  <svg width="122" height="108" viewBox="0 0 122 108" fill="none">
    <path
      d="M90.8677 69.6427C107.623 69.6427 121.205 64.6348 121.205 58.4572C121.205 52.2796 107.623 47.2716 90.8677 47.2716C74.1128 47.2716 60.5303 52.2796 60.5303 58.4572C60.5303 64.6348 74.1128 69.6427 90.8677 69.6427Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M113.38 51.7757C113.38 46.2698 100.811 41.8068 85.3115 41.8068C69.8125 41.8068 57.2432 46.2698 57.2432 51.7757V59.1373C57.2432 64.6432 69.8125 69.1062 85.3115 69.1062C100.811 69.1062 113.38 64.6432 113.38 59.1373V51.7757Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M85.3115 61.7445C100.813 61.7445 113.38 57.2813 113.38 51.7757C113.38 46.27 100.813 41.8068 85.3115 41.8068C69.8098 41.8068 57.2432 46.27 57.2432 51.7757C57.2432 57.2813 69.8098 61.7445 85.3115 61.7445Z"
      fill="#A88FEF"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M85.3113 57.9256C97.4722 57.9256 107.331 54.9959 107.331 51.3819C107.331 47.7679 97.4722 44.8383 85.3113 44.8383C73.1504 44.8383 63.292 47.7679 63.292 51.3819C63.292 54.9959 73.1504 57.9256 85.3113 57.9256Z"
      fill="#D7C4FA"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M64.96 53.8818C68.2634 56.259 76.1321 57.9256 85.3113 57.9256C94.4905 57.9256 102.359 56.2539 105.663 53.8818C102.359 51.5097 94.4905 49.838 85.3113 49.838C76.1321 49.838 68.2634 51.5097 64.96 53.8818Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M39.2051 55.9676C39.2051 56.8213 41.4092 57.5115 44.1332 57.5115C46.8571 57.5115 49.0612 56.8213 49.0612 55.9676C49.0612 55.1139 46.8571 54.4237 44.1332 54.4237C41.4092 54.4237 39.2051 55.1139 39.2051 55.9676Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M48.4114 56.7293C47.5666 56.2641 45.969 55.9472 44.1332 55.9472C42.2973 55.9472 40.6997 56.2641 39.8495 56.7293C39.4379 56.5044 39.2051 56.2437 39.2051 55.9676C39.2051 55.1139 41.4092 54.4237 44.1332 54.4237C46.8571 54.4237 49.0612 55.1139 49.0612 55.9676C49.0612 56.2437 48.8229 56.5044 48.4168 56.7293H48.4114Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M44.0305 57.5063C45.6606 57.5063 46.982 57.1893 46.982 55.6505C46.982 47.839 53.708 41.4896 61.9828 41.4896C70.2577 41.4896 76.9837 47.839 76.9837 55.6505C76.9837 57.1893 78.305 57.5063 79.9351 57.5063C81.5652 57.5063 82.8865 57.1893 82.8865 55.6505C82.8865 44.7666 73.5069 35.9173 61.9828 35.9173C50.4587 35.9173 41.0791 44.7666 41.0791 55.6505C41.0791 57.1893 42.4005 57.5063 44.0305 57.5063Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M17.0439 55.7583C17.0439 56.6172 19.2535 57.3073 21.9829 57.3073C24.7122 57.3073 26.9218 56.6172 26.9218 55.7583C26.9218 54.8995 24.7122 54.2144 21.9829 54.2144C19.2535 54.2144 17.0439 54.9046 17.0439 55.7583Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M26.2773 56.52C25.4271 56.0548 23.8295 55.7379 21.9829 55.7379C20.1362 55.7379 18.5386 56.0548 17.6884 56.52C17.2768 56.2951 17.0439 56.0344 17.0439 55.7583C17.0439 54.9046 19.2535 54.2144 21.9829 54.2144C24.7122 54.2144 26.9218 54.9046 26.9218 55.7583C26.9218 56.0344 26.6889 56.2951 26.2773 56.52Z"
      fill="#E4E0D9"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M22.0262 57.1793C23.6617 57.1793 24.9885 57.1793 24.9885 55.6405C24.9885 44.731 15.5818 35.8561 4.03062 35.8561C2.39515 35.8561 1.06836 37.1086 1.06836 38.6474C1.06836 40.1862 2.39515 41.4438 4.03062 41.4438C12.3217 41.4438 19.0694 47.8086 19.0694 55.6405C19.0694 57.1793 20.3908 57.1793 22.0262 57.1793Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M70.9276 106.805C76.5626 106.805 81.1307 105.758 81.1307 104.467C81.1307 103.175 76.5626 102.129 70.9276 102.129C65.2927 102.129 60.7246 103.175 60.7246 104.467C60.7246 105.758 65.2927 106.805 70.9276 106.805Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M66.3664 106.533C73.7112 106.533 79.6653 100.579 79.6653 93.2339C79.6653 85.889 73.7112 79.9349 66.3664 79.9349C59.0215 79.9349 53.0674 85.889 53.0674 93.2339C53.0674 100.579 59.0215 106.533 66.3664 106.533Z"
      fill="#EBEDCB"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M48.4553 15.0631C51.4169 15.0631 53.8177 14.5125 53.8177 13.8333C53.8177 13.1541 51.4169 12.6035 48.4553 12.6035C45.4936 12.6035 43.0928 13.1541 43.0928 13.8333C43.0928 14.5125 45.4936 15.0631 48.4553 15.0631Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M46.0601 14.9203C49.922 14.9203 53.0528 11.7895 53.0528 7.92758C53.0528 4.06561 49.922 0.934875 46.0601 0.934875C42.1981 0.934875 39.0674 4.06561 39.0674 7.92758C39.0674 11.7895 42.1981 14.9203 46.0601 14.9203Z"
      fill="#FFE0CC"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
  </svg>
);
