const GalaxyGateIconForDarkBackground = () => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.9851 53.4816C42.9851 35.3577 57.6777 20.665 75.8017 20.665C93.9262 20.665 108.619 35.3577 108.619 53.4816V106.244H42.9851V53.4816Z"
        fill="#222126"
      />
      <path
        d="M108.074 106.361L102.018 113.423L98.8295 106.361V51.3047C98.8295 38.1651 88.1394 27.4754 75 27.4754C61.8606 27.4754 51.1708 38.1651 51.1708 51.3047V106.361L47.9821 113.423L41.9258 106.361V51.3047C41.9258 33.0677 56.7628 18.2305 75 18.2305C93.2372 18.2305 108.074 33.0677 108.074 51.3047V106.361Z"
        fill="#8173E6"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M112.5 113.423H102.018V51C102.018 36.1022 89.8978 23.9821 75 23.9821C60.1022 23.9821 47.9821 36.1022 47.9821 51V113.423H37.5V51C37.5 30.3223 54.3226 13.5 75 13.5C95.6774 13.5 112.5 30.3226 112.5 51V113.423Z"
        fill="#B0A6F9"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M76.6946 73.6611C75.9462 74.068 74.8358 73.4722 74.2145 72.3298C73.5931 71.1878 73.6958 69.9318 74.444 69.5244L82.6459 65.0625C83.3943 64.6556 84.5047 65.2516 85.1262 66.3937C85.7474 67.5358 85.6444 68.792 84.8962 69.1992L76.6946 73.6611Z"
        fill="#EB81AF"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M76.6918 73.6551C77.4401 73.248 77.5429 71.992 76.9215 70.8497C76.3 69.7075 75.1897 69.1116 74.4413 69.5187C73.693 69.9258 73.5902 71.1818 74.2116 72.3241C74.8331 73.4663 75.9435 74.0622 76.6918 73.6551Z"
        fill="#EB81AF"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M71.9181 36.1374L73.2141 36.8462L71.9181 37.555L71.2093 38.8508L70.5007 37.555L69.2048 36.8462L70.5007 36.1374L71.2093 34.8418L71.9181 36.1374Z"
        fill="#F0F4FA"
      />
      <path
        d="M89.3348 54.8192L90.2035 55.2944L89.3348 55.7693L88.8594 56.6382L88.3845 55.7693L87.5156 55.2944L88.3845 54.8192L88.8594 53.9508L89.3348 54.8192Z"
        fill="#F0F4FA"
      />
      <path
        d="M65.4273 75.2441L66.5659 75.8667L65.4273 76.4898L64.804 77.6281L64.1818 76.4898L63.043 75.8667L64.1818 75.2441L64.804 74.1055L65.4273 75.2441Z"
        fill="#F0F4FA"
      />
      <path
        d="M87.3263 94.097L88.1467 94.5456L87.3263 94.9947L86.8774 95.8154L86.4285 94.9947L85.6082 94.5456L86.4285 94.097L86.8774 93.2764L87.3263 94.097Z"
        fill="#F0F4FA"
      />
      <path
        d="M69.3091 61.6028L61.4024 59.0361L59.6726 50.905L65.8489 45.3418L73.7556 47.9087L75.4854 56.04L69.3091 61.6028Z"
        fill="#D6A356"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M68.4132 64.6794L60.5066 62.1127L58.7769 53.9817L64.9531 48.4185L72.8597 50.9852L74.5897 59.1167L68.4132 64.6794Z"
        fill="#FABE64"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M64.8672 48.4227L66.2853 45.5176"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M72.7197 51.2173L73.5073 47.8225"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M91.8662 72.8405L82.7121 68.1704L78.042 77.3243L79.5279 80.7557L88.6825 85.4258L93.3523 76.2712L91.8662 72.8405Z"
        fill="#2A7857"
      />
      <path
        d="M84.3054 71.8213L93.2147 76.2759L88.6352 85.1435L79.8091 80.8142L84.3054 71.8213Z"
        fill="#49B889"
      />
      <path
        d="M91.8662 72.8405L82.7121 68.1704L78.042 77.3243L79.5279 80.7557L88.6825 85.4258L93.3523 76.2712L91.8662 72.8405Z"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M79.5278 80.7557L84.1975 71.6011L93.3523 76.2712"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M84.1978 71.6011L82.7124 68.1704"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M72.8445 92.0458C73.6945 90.5704 72.4183 88.2424 69.9944 86.8462C67.57 85.45 64.9157 85.514 64.0659 86.9899C63.8081 87.4376 62.895 89.0227 62.6369 89.4707C61.7867 90.9465 63.0631 93.2745 65.4875 94.6703C67.9117 96.0669 70.5658 96.0029 71.4155 94.527C71.6738 94.0788 72.5869 92.4939 72.8445 92.0458Z"
        fill="#5178B3"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M66.9198 92.2015C69.3441 93.5976 71.9983 93.5332 72.8482 92.0574C73.6981 90.5817 72.4219 88.2536 69.9976 86.8574C67.5734 85.4612 64.9192 85.5257 64.0692 87.0014C63.2193 88.4771 64.4956 90.8053 66.9198 92.2015Z"
        fill="#6DA2F0"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M85.7482 41.3996C85.0399 40.2258 82.9027 40.2174 80.9744 41.3812C79.0464 42.5445 78.0574 44.4389 78.7658 45.6129C78.9805 45.9688 79.7415 47.2295 79.956 47.5863C80.6646 48.7601 82.8018 48.7683 84.7296 47.6045C86.6581 46.4412 87.6473 44.5468 86.9387 43.373C86.7242 43.0166 85.963 41.7555 85.7482 41.3996Z"
        fill="#B36847"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M83.5345 45.6385C85.4626 44.4751 86.4516 42.5806 85.7435 41.407C85.0354 40.2334 82.8983 40.2251 80.9702 41.3884C79.0421 42.5518 78.053 44.4463 78.7612 45.6199C79.4693 46.7935 81.6064 46.8018 83.5345 45.6385Z"
        fill="#F0946C"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M95.1515 115.832H77.3005H72.6996H54.8483L48.468 133.868V136.197H101.532L101.479 133.686L95.1515 115.832Z"
        fill="#2A7857"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M95.1515 115.832H77.3005H72.6996H54.8483L48.468 133.868H72.6996H77.3005H101.532L95.1515 115.832Z"
        fill="#81D8B4"
        stroke="#222126"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GalaxyGateIconForDarkBackground;
