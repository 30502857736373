import { styled } from "@linaria/react";
import { ConnectorPathWaypoint } from "../decorations/ConnectorPathsPainter";
import { colors } from "../../styles/colors.styles";
import CenteredQuoteSection from "../general/CenteredQuoteSection";

// const Header = styled.header`
//   text-align: center;
//   position: relative;
// `;
// const TwoSplit = styled.div`
//   display: grid;
//   grid-gap: ${rSize("gap")};
//   ${fromTablet} {
//     grid-template-columns: repeat(2, minmax(0, 1fr));
//   }
// `;

const WaypointsWrap = styled.div`
  position: relative;
`;

export const WorkbenchCustomerQuotesSection = () => (
  <>
    <WaypointsWrap>
      <ConnectorPathWaypoint
        id="workbench-page-see-it-in-section-close-left"
        index={3}
        color={colors.purple100}
        top={24}
        left="50%"
      />
      <ConnectorPathWaypoint
        id="workbench-page-see-it-in-section-close-right"
        index={3}
        color={colors.purple100}
        top={24}
        right="50%"
      />
    </WaypointsWrap>

    <CenteredQuoteSection
      /** cspell:disable */
      sourceName="Horia Todoran"
      /** cspell:enable */
      sourceWorkTitle="Python Developer"
      // sourceCompanyLogo={<LogoHollandAndBarrett />}
      color="purple"
    >
      <p>
        “Workbench completely changes how Tines is used. Most of the heavy
        lifting is done by the AI. All you have to do is build a bunch of action
        templates and let the AI do the rest. Previously I was skeptical about
        anything that had AI in it, but this is the right way to use AI. You
        guys really nailed it!”
      </p>
    </CenteredQuoteSection>
  </>
);

// const TwoQuoteVersion = () => (
//   <>
//     <PageSection>
//       <Header>
//         <ConnectorPathWaypoint
//           id="workbench-page-see-it-in-section-close-left"
//           index={3}
//           color={colors.purple100}
//           top={0}
//           left="50%"
//         />
//         <ConnectorPathWaypoint
//           id="workbench-page-see-it-in-section-close-right"
//           index={3}
//           color={colors.purple100}
//           top={0}
//           right="50%"
//         />

//         <img src={WorkbenchTrophyIcon} />
//         <Spacing size="md" />
//         <SectionHeading2 lighter>Don’t take our word for it</SectionHeading2>
//       </Header>
//     </PageSection>
//     <TwoSplit>
//       <QuoteCardNeue
//         /** cspell:disable */
//         sourceName="Horia Todoran"
//         /** cspell:enable */
//         sourceDetails="Python Developer"
//         sourceLogo={<LogoHollandAndBarrett />}
//         color="green"
//       >
//         Workbench completely changes how Tines is used. Most of the heavy
//         lifting is done by the AI. All you have to do is build a bunch of action
//         templates and let the AI do the rest. Previously I was skeptical about
//         anything that had AI in it, but this is the right way to use AI. You
//         guys really nailed it!
//       </QuoteCardNeue>
//       <QuoteCardNeue
//         /** cspell:disable */
//         sourceName="Nathan Collings"
//         /** cspell:enable */
//         sourceDetails="Senior Security Ops Detection & Automation Manager"
//         sourceLogo={<LogoHollandAndBarrett />}
//         color="purple"
//       >
//         This is so cool, we can correlate authentication, account, and order
//         addresses. I love the extra context it adds after the facts.
//       </QuoteCardNeue>
//     </TwoSplit>
//   </>
// );
