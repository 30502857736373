import { styled } from "@linaria/react";
import { cx } from "linaria";
import { colorsV4 } from "../../styles/colorsV4.styles";
import { DatoCmsImageBlock } from "../../../graphql-types";
import ImageFrame, { ImageFrameFigure } from "../basic/ImageFrame";
import SmartLink from "../basic/SmartLink";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { inParagraphLinkStyle } from "../../styles/links.styles";
import ReactMarkdown from "react-markdown";

type Props = {
  record: DatoCmsImageBlock;
  lazy?: boolean;
};

export const ImageBlockContainer = styled.div`
  padding: 0;
  margin: 0;
  width: min-content;
  max-width: 100%;
  &.applyBorder {
    ${ImageFrameFigure} {
      border: 2px solid ${colorsV4.canvas550};
      ${darkModeLinariaCSS(`
        border-color: ${colorsV4.warmBlack};
      `)}
    }
  }
  > a {
    display: block;
  }
`;

const ImageBlockFigcaption = styled.figcaption`
  margin-top: 1em;
  text-align: center;
  font-size: 1.4rem;
  color: ${colorsV4.grey};
  min-width: 30rem;
  a {
    ${inParagraphLinkStyle()}
  }
`;

const ImageBlock = (props: Props) => {
  const caption = props.record.caption;
  const image = (
    <ImageFrame
      image={props.record.image}
      alt={props.record.altTag ?? undefined}
      borderRadius=".5em"
      lazy={props.lazy}
    />
  );
  return (
    <ImageBlockContainer
      className={cx(
        "ImageBlock",
        props.record.showInFullPageWidth && "showInFullPageWidth",
        props.record.applyBorder && "applyBorder"
      )}
    >
      {props.record.link ? (
        <SmartLink src={props.record.link}>{image}</SmartLink>
      ) : (
        image
      )}
      {caption && (
        <ImageBlockFigcaption>
          <ReactMarkdown>{caption}</ReactMarkdown>
        </ImageBlockFigcaption>
      )}
    </ImageBlockContainer>
  );
};

export default ImageBlock;
