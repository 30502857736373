import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import { CSSProperties, PropsWithChildren } from "react";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { cx } from "linaria";
import {
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
} from "../../../styles/breakpointsAndMediaQueries.styles";
import { reportV2BodyTextParagraphStyle } from "./ReportV2TextBlock";
import { DonutChartV2Container } from "./DonutChartV2";
import { ReportV2DotGridGraphContainer } from "./ReportV2DotGridGraph";
import { inParagraphLinkStyle } from "../../../styles/links.styles";
import { fromReportV2DesktopDesign } from "./reportsV2.helpers";

type Props = {
  backgroundColor?: string;
  accentColor?: string;
  color?: string;
  wide?: boolean;
  centeredOnDesktop?: boolean;
  alwaysCentered?: boolean;
  largerText?: boolean;
  alignTop?: boolean;
};

const ReportV2ContentCardDiv = styled.div<Props>`
  background-color: ${p => p.backgroundColor ?? colors.lightest};
  color: ${p => p.color ?? "inherit"};
  border-radius: 0.8rem;
  padding: 2.4rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(auto, 1fr) auto;
  width: 100%;
  max-width: 100%;
  &.alignTop {
    grid-template-rows: auto minmax(auto, 1fr);
  }
  ${fromDesktopMd} {
    border-radius: 1.2rem;
    padding: 3.8rem;
  }
  > * + * {
    margin-top: 0.75em;
  }
  > svg {
    + * {
      margin-top: 1em;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.2;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    max-width: 20em;
    &:first-child {
      margin-top: -0.2em;
    }
    ${fromTablet} {
      font-size: 1.6rem;
    }
    ${fromDesktopMd} {
      font-size: 2rem;
    }
  }
  h4,
  h5 {
    font-size: inherit;
    font-weight: 600;
    max-width: 35em;
  }
  p,
  ul,
  ol {
    ${reportV2BodyTextParagraphStyle}
    max-width: 35em;
  }
  p,
  li {
    a {
      ${inParagraphLinkStyle()}
      font-weight: 600;
    }
  }
  &.largerText {
    p,
    ul,
    ol {
      ${fromReportV2DesktopDesign} {
        font-size: 1.6rem;
        line-height: 1.45;
      }
    }
  }
  ul,
  ol {
    font-weight: 500;
    line-height: 1.33;
    li {
      + li {
        margin-top: 0.5em;
      }
    }
  }
  ul {
    padding-left: 1em;
  }
  ol {
    padding-left: 1.5em;
  }
  svg,
  canvas {
    width: 100%;
  }
  ${DonutChartV2Container} {
    margin-top: 1em;
  }
  ${ReportV2DotGridGraphContainer} {
    + h3 {
      margin-top: 0.75em;
    }
  }
  figure {
    margin: 0;
    padding: 0;
  }
  &.centeredOnDesktop {
    ${fromDesktopMl} {
      h3,
      h4,
      p {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      h3 {
        max-width: 22em;
      }
    }
  }
  &.alwaysCentered {
    text-align: center;
    h3,
    h4,
    p {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    h3 {
      max-width: 22em;
    }
  }
  header {
    + figure {
      &:before {
        content: "";
        display: block;
        margin: 1.5em 0;
        height: 1px;
        background-color: currentColor;
        opacity: 0.2;
      }
    }
  }
`;

const ReportV2ContentCard = (props: PropsWithChildren<Props>) => {
  return (
    <ReportV2ContentCardDiv
      className={cx(
        props.wide ? "wide" : "regular",
        props.centeredOnDesktop && "centeredOnDesktop",
        props.alwaysCentered && "alwaysCentered",
        props.largerText && "largerText",
        props.alignTop && "alignTop"
      )}
      backgroundColor={props.backgroundColor}
      color={props.color}
      style={
        props.accentColor
          ? ({
              "--ac": props.accentColor,
            } as CSSProperties)
          : undefined
      }
    >
      {props.children}
    </ReportV2ContentCardDiv>
  );
};

const ReportV2ContentCardGridDiv = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  width: 100%;
  max-width: 100%;
  grid-template-columns: minmax(0, 1fr);
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    &.equalHeight {
      grid-auto-rows: 1fr;
    }
    .wide {
      grid-column-end: span 2;
    }
  }
`;
export const ReportV2ContentCardGrid = (
  props: PropsWithChildren<{ equalHeight?: boolean }>
) => (
  <ReportV2ContentCardGridDiv
    className={cx(props.equalHeight && "equalHeight")}
  >
    {props.children}
  </ReportV2ContentCardGridDiv>
);

export default ReportV2ContentCard;
