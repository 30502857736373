import {
  DatoCmsCollectionsGridSection,
  DatoCmsLibraryCollection,
} from "../../../../graphql-types";
import LibraryCollectionPageSectionHeader from "./LibraryCollectionPageSectionHeader";
import CollectionsGrid from "../CollectionsGrid";
import { makeSlug } from "../../../utils/string.utils";

type Props = {
  section: DatoCmsCollectionsGridSection;
};

const CollectionsGridSection = ({ section }: Props) => {
  return (
    <div id={makeSlug(section.heading)}>
      <LibraryCollectionPageSectionHeader section={section} />
      <CollectionsGrid
        collections={section.collections as DatoCmsLibraryCollection[]}
      />
    </div>
  );
};

export default CollectionsGridSection;
