export const ReportV2LargeChapterNumber1Dots = () => (
  <svg width="370" height="400" viewBox="0 0 370 400" fill="currentColor">
    <path
      d="M225.388 106.394C226.782 106.394 227.912 105.26 227.912 103.862C227.912 102.463 226.782 101.33 225.388 101.33C223.994 101.33 222.865 102.463 222.865 103.862C222.865 105.26 223.994 106.394 225.388 106.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 116.521C226.782 116.521 227.912 115.388 227.912 113.989C227.912 112.591 226.782 111.458 225.388 111.458C223.994 111.458 222.865 112.591 222.865 113.989C222.865 115.388 223.994 116.521 225.388 116.521Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 126.649C226.782 126.649 227.912 125.516 227.912 124.117C227.912 122.719 226.782 121.585 225.388 121.585C223.994 121.585 222.865 122.719 222.865 124.117C222.865 125.516 223.994 126.649 225.388 126.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 136.777C226.782 136.777 227.912 135.644 227.912 134.245C227.912 132.847 226.782 131.713 225.388 131.713C223.994 131.713 222.865 132.847 222.865 134.245C222.865 135.644 223.994 136.777 225.388 136.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 146.905C226.782 146.905 227.912 145.772 227.912 144.373C227.912 142.975 226.782 141.841 225.388 141.841C223.994 141.841 222.865 142.975 222.865 144.373C222.865 145.772 223.994 146.905 225.388 146.905Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 157.033C226.782 157.033 227.912 155.9 227.912 154.501C227.912 153.103 226.782 151.969 225.388 151.969C223.994 151.969 222.865 153.103 222.865 154.501C222.865 155.9 223.994 157.033 225.388 157.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 167.161C226.782 167.161 227.912 166.028 227.912 164.629C227.912 163.231 226.782 162.097 225.388 162.097C223.994 162.097 222.865 163.231 222.865 164.629C222.865 166.028 223.994 167.161 225.388 167.161Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 177.289C226.782 177.289 227.912 176.155 227.912 174.757C227.912 173.359 226.782 172.225 225.388 172.225C223.994 172.225 222.865 173.359 222.865 174.757C222.865 176.155 223.994 177.289 225.388 177.289Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 187.417C226.782 187.417 227.912 186.283 227.912 184.885C227.912 183.487 226.782 182.353 225.388 182.353C223.994 182.353 222.865 183.487 222.865 184.885C222.865 186.283 223.994 187.417 225.388 187.417Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 197.545C226.782 197.545 227.912 196.411 227.912 195.013C227.912 193.615 226.782 192.481 225.388 192.481C223.994 192.481 222.865 193.615 222.865 195.013C222.865 196.411 223.994 197.545 225.388 197.545Z"
      fillOpacity={0.3}
    />
    <path
      d="M154.729 65.9829C156.123 65.9829 157.253 64.8493 157.253 63.4509C157.253 62.0525 156.123 60.9189 154.729 60.9189C153.335 60.9189 152.206 62.0525 152.206 63.4509C152.206 64.8493 153.335 65.9829 154.729 65.9829Z"
      fillOpacity={0.3}
    />
    <path
      d="M185.011 55.8554C186.405 55.8554 187.534 54.7218 187.534 53.3235C187.534 51.9251 186.405 50.7915 185.011 50.7915C183.617 50.7915 182.487 51.9251 182.487 53.3235C182.487 54.7218 183.617 55.8554 185.011 55.8554Z"
      fillOpacity={0.3}
    />
    <path
      d="M205.2 35.5996C206.593 35.5996 207.723 34.466 207.723 33.0676C207.723 31.6692 206.593 30.5356 205.2 30.5356C203.806 30.5356 202.676 31.6692 202.676 33.0676C202.676 34.466 203.806 35.5996 205.2 35.5996Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 35.5996C226.782 35.5996 227.912 34.466 227.912 33.0676C227.912 31.6692 226.782 30.5356 225.388 30.5356C223.994 30.5356 222.865 31.6692 222.865 33.0676C222.865 34.466 223.994 35.5996 225.388 35.5996Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 45.7275C226.782 45.7275 227.912 44.5939 227.912 43.1955C227.912 41.7972 226.782 40.6636 225.388 40.6636C223.994 40.6636 222.865 41.7972 222.865 43.1955C222.865 44.5939 223.994 45.7275 225.388 45.7275Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 55.8554C226.782 55.8554 227.912 54.7218 227.912 53.3235C227.912 51.9251 226.782 50.7915 225.388 50.7915C223.994 50.7915 222.865 51.9251 222.865 53.3235C222.865 54.7218 223.994 55.8554 225.388 55.8554Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 65.9834C226.782 65.9834 227.912 64.8498 227.912 63.4514C227.912 62.053 226.782 60.9194 225.388 60.9194C223.994 60.9194 222.865 62.053 222.865 63.4514C222.865 64.8498 223.994 65.9834 225.388 65.9834Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 76.1113C226.782 76.1113 227.912 74.9777 227.912 73.5793C227.912 72.181 226.782 71.0474 225.388 71.0474C223.994 71.0474 222.865 72.181 222.865 73.5793C222.865 74.9777 223.994 76.1113 225.388 76.1113Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 86.2392C226.782 86.2392 227.912 85.1056 227.912 83.7073C227.912 82.3089 226.782 81.1753 225.388 81.1753C223.994 81.1753 222.865 82.3089 222.865 83.7073C222.865 85.1056 223.994 86.2392 225.388 86.2392Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 96.3672C226.782 96.3672 227.912 95.2336 227.912 93.8352C227.912 92.4368 226.782 91.3032 225.388 91.3032C223.994 91.3032 222.865 92.4368 222.865 93.8352C222.865 95.2336 223.994 96.3672 225.388 96.3672Z"
      fillOpacity={0.3}
    />
    <path
      d="M174.918 359.033C176.311 359.033 177.441 357.9 177.441 356.501C177.441 355.103 176.311 353.969 174.918 353.969C173.524 353.969 172.394 355.103 172.394 356.501C172.394 357.9 173.524 359.033 174.918 359.033Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 308.394C226.782 308.394 227.912 307.26 227.912 305.862C227.912 304.463 226.782 303.33 225.388 303.33C223.994 303.33 222.865 304.463 222.865 305.862C222.865 307.26 223.994 308.394 225.388 308.394Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 318.522C226.782 318.522 227.912 317.389 227.912 315.99C227.912 314.592 226.782 313.458 225.388 313.458C223.994 313.458 222.865 314.592 222.865 315.99C222.865 317.389 223.994 318.522 225.388 318.522Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 328.649C226.782 328.649 227.912 327.516 227.912 326.117C227.912 324.719 226.782 323.585 225.388 323.585C223.994 323.585 222.865 324.719 222.865 326.117C222.865 327.516 223.994 328.649 225.388 328.649Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 338.777C226.782 338.777 227.912 337.644 227.912 336.245C227.912 334.847 226.782 333.713 225.388 333.713C223.994 333.713 222.865 334.847 222.865 336.245C222.865 337.644 223.994 338.777 225.388 338.777Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 207.216C226.782 207.216 227.912 206.082 227.912 204.684C227.912 203.285 226.782 202.152 225.388 202.152C223.994 202.152 222.865 203.285 222.865 204.684C222.865 206.082 223.994 207.216 225.388 207.216Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 217.344C226.782 217.344 227.912 216.21 227.912 214.812C227.912 213.413 226.782 212.28 225.388 212.28C223.994 212.28 222.865 213.413 222.865 214.812C222.865 216.21 223.994 217.344 225.388 217.344Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 227.472C226.782 227.472 227.912 226.338 227.912 224.94C227.912 223.541 226.782 222.408 225.388 222.408C223.994 222.408 222.865 223.541 222.865 224.94C222.865 226.338 223.994 227.472 225.388 227.472Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 237.6C226.782 237.6 227.912 236.466 227.912 235.068C227.912 233.669 226.782 232.536 225.388 232.536C223.994 232.536 222.865 233.669 222.865 235.068C222.865 236.466 223.994 237.6 225.388 237.6Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 247.728C226.782 247.728 227.912 246.594 227.912 245.196C227.912 243.797 226.782 242.664 225.388 242.664C223.994 242.664 222.865 243.797 222.865 245.196C222.865 246.594 223.994 247.728 225.388 247.728Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 257.855C226.782 257.855 227.912 256.722 227.912 255.323C227.912 253.925 226.782 252.792 225.388 252.792C223.994 252.792 222.865 253.925 222.865 255.323C222.865 256.722 223.994 257.855 225.388 257.855Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 267.983C226.782 267.983 227.912 266.85 227.912 265.451C227.912 264.053 226.782 262.919 225.388 262.919C223.994 262.919 222.865 264.053 222.865 265.451C222.865 266.85 223.994 267.983 225.388 267.983Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 278.111C226.782 278.111 227.912 276.978 227.912 275.579C227.912 274.181 226.782 273.047 225.388 273.047C223.994 273.047 222.865 274.181 222.865 275.579C222.865 276.978 223.994 278.111 225.388 278.111Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 288.239C226.782 288.239 227.912 287.106 227.912 285.707C227.912 284.309 226.782 283.175 225.388 283.175C223.994 283.175 222.865 284.309 222.865 285.707C222.865 287.106 223.994 288.239 225.388 288.239Z"
      fillOpacity={0.3}
    />
    <path
      d="M225.388 298.367C226.782 298.367 227.912 297.234 227.912 295.835C227.912 294.437 226.782 293.303 225.388 293.303C223.994 293.303 222.865 294.437 222.865 295.835C222.865 297.234 223.994 298.367 225.388 298.367Z"
      fillOpacity={0.3}
    />
    <path d="M195.104 106.394C196.498 106.394 197.628 105.26 197.628 103.862C197.628 102.463 196.498 101.33 195.104 101.33C193.711 101.33 192.581 102.463 192.581 103.862C192.581 105.26 193.711 106.394 195.104 106.394Z" />
    <path d="M195.104 116.521C196.498 116.521 197.628 115.388 197.628 113.989C197.628 112.591 196.498 111.458 195.104 111.458C193.711 111.458 192.581 112.591 192.581 113.989C192.581 115.388 193.711 116.521 195.104 116.521Z" />
    <path d="M195.104 126.649C196.498 126.649 197.628 125.516 197.628 124.117C197.628 122.719 196.498 121.585 195.104 121.585C193.711 121.585 192.581 122.719 192.581 124.117C192.581 125.516 193.711 126.649 195.104 126.649Z" />
    <path d="M195.104 136.777C196.498 136.777 197.628 135.644 197.628 134.245C197.628 132.847 196.498 131.713 195.104 131.713C193.711 131.713 192.581 132.847 192.581 134.245C192.581 135.644 193.711 136.777 195.104 136.777Z" />
    <path d="M195.104 146.905C196.498 146.905 197.628 145.772 197.628 144.373C197.628 142.975 196.498 141.841 195.104 141.841C193.711 141.841 192.581 142.975 192.581 144.373C192.581 145.772 193.711 146.905 195.104 146.905Z" />
    <path d="M195.104 157.033C196.498 157.033 197.628 155.9 197.628 154.501C197.628 153.103 196.498 151.969 195.104 151.969C193.711 151.969 192.581 153.103 192.581 154.501C192.581 155.9 193.711 157.033 195.104 157.033Z" />
    <path d="M195.104 167.161C196.498 167.161 197.628 166.028 197.628 164.629C197.628 163.231 196.498 162.097 195.104 162.097C193.711 162.097 192.581 163.231 192.581 164.629C192.581 166.028 193.711 167.161 195.104 167.161Z" />
    <path d="M195.104 177.289C196.498 177.289 197.628 176.155 197.628 174.757C197.628 173.359 196.498 172.225 195.104 172.225C193.711 172.225 192.581 173.359 192.581 174.757C192.581 176.155 193.711 177.289 195.104 177.289Z" />
    <path d="M195.104 187.417C196.498 187.417 197.628 186.283 197.628 184.885C197.628 183.487 196.498 182.353 195.104 182.353C193.711 182.353 192.581 183.487 192.581 184.885C192.581 186.283 193.711 187.417 195.104 187.417Z" />
    <path d="M195.104 197.545C196.498 197.545 197.628 196.411 197.628 195.013C197.628 193.615 196.498 192.481 195.104 192.481C193.711 192.481 192.581 193.615 192.581 195.013C192.581 196.411 193.711 197.545 195.104 197.545Z" />
    <path d="M205.2 106.394C206.593 106.394 207.723 105.26 207.723 103.862C207.723 102.463 206.593 101.33 205.2 101.33C203.806 101.33 202.676 102.463 202.676 103.862C202.676 105.26 203.806 106.394 205.2 106.394Z" />
    <path d="M205.2 116.521C206.593 116.521 207.723 115.388 207.723 113.989C207.723 112.591 206.593 111.458 205.2 111.458C203.806 111.458 202.676 112.591 202.676 113.989C202.676 115.388 203.806 116.521 205.2 116.521Z" />
    <path d="M205.2 126.649C206.593 126.649 207.723 125.516 207.723 124.117C207.723 122.719 206.593 121.585 205.2 121.585C203.806 121.585 202.676 122.719 202.676 124.117C202.676 125.516 203.806 126.649 205.2 126.649Z" />
    <path d="M205.2 136.777C206.593 136.777 207.723 135.644 207.723 134.245C207.723 132.847 206.593 131.713 205.2 131.713C203.806 131.713 202.676 132.847 202.676 134.245C202.676 135.644 203.806 136.777 205.2 136.777Z" />
    <path d="M205.2 146.905C206.593 146.905 207.723 145.772 207.723 144.373C207.723 142.975 206.593 141.841 205.2 141.841C203.806 141.841 202.676 142.975 202.676 144.373C202.676 145.772 203.806 146.905 205.2 146.905Z" />
    <path d="M205.2 157.033C206.593 157.033 207.723 155.9 207.723 154.501C207.723 153.103 206.593 151.969 205.2 151.969C203.806 151.969 202.676 153.103 202.676 154.501C202.676 155.9 203.806 157.033 205.2 157.033Z" />
    <path d="M205.2 167.161C206.593 167.161 207.723 166.028 207.723 164.629C207.723 163.231 206.593 162.097 205.2 162.097C203.806 162.097 202.676 163.231 202.676 164.629C202.676 166.028 203.806 167.161 205.2 167.161Z" />
    <path d="M205.2 177.289C206.593 177.289 207.723 176.155 207.723 174.757C207.723 173.359 206.593 172.225 205.2 172.225C203.806 172.225 202.676 173.359 202.676 174.757C202.676 176.155 203.806 177.289 205.2 177.289Z" />
    <path d="M205.2 187.417C206.593 187.417 207.723 186.283 207.723 184.885C207.723 183.487 206.593 182.353 205.2 182.353C203.806 182.353 202.676 183.487 202.676 184.885C202.676 186.283 203.806 187.417 205.2 187.417Z" />
    <path d="M205.2 197.545C206.593 197.545 207.723 196.411 207.723 195.013C207.723 193.615 206.593 192.481 205.2 192.481C203.806 192.481 202.676 193.615 202.676 195.013C202.676 196.411 203.806 197.545 205.2 197.545Z" />
    <path d="M215.293 106.394C216.687 106.394 217.816 105.26 217.816 103.862C217.816 102.463 216.687 101.33 215.293 101.33C213.899 101.33 212.769 102.463 212.769 103.862C212.769 105.26 213.899 106.394 215.293 106.394Z" />
    <path d="M215.293 116.521C216.687 116.521 217.816 115.388 217.816 113.989C217.816 112.591 216.687 111.458 215.293 111.458C213.899 111.458 212.769 112.591 212.769 113.989C212.769 115.388 213.899 116.521 215.293 116.521Z" />
    <path d="M215.293 126.649C216.687 126.649 217.816 125.516 217.816 124.117C217.816 122.719 216.687 121.585 215.293 121.585C213.899 121.585 212.769 122.719 212.769 124.117C212.769 125.516 213.899 126.649 215.293 126.649Z" />
    <path d="M215.293 136.777C216.687 136.777 217.816 135.644 217.816 134.245C217.816 132.847 216.687 131.713 215.293 131.713C213.899 131.713 212.769 132.847 212.769 134.245C212.769 135.644 213.899 136.777 215.293 136.777Z" />
    <path d="M215.293 146.905C216.687 146.905 217.816 145.772 217.816 144.373C217.816 142.975 216.687 141.841 215.293 141.841C213.899 141.841 212.769 142.975 212.769 144.373C212.769 145.772 213.899 146.905 215.293 146.905Z" />
    <path d="M215.293 157.033C216.687 157.033 217.816 155.9 217.816 154.501C217.816 153.103 216.687 151.969 215.293 151.969C213.899 151.969 212.769 153.103 212.769 154.501C212.769 155.9 213.899 157.033 215.293 157.033Z" />
    <path d="M215.293 167.161C216.687 167.161 217.816 166.028 217.816 164.629C217.816 163.231 216.687 162.097 215.293 162.097C213.899 162.097 212.769 163.231 212.769 164.629C212.769 166.028 213.899 167.161 215.293 167.161Z" />
    <path d="M215.293 177.289C216.687 177.289 217.816 176.155 217.816 174.757C217.816 173.359 216.687 172.225 215.293 172.225C213.899 172.225 212.769 173.359 212.769 174.757C212.769 176.155 213.899 177.289 215.293 177.289Z" />
    <path d="M215.293 187.417C216.687 187.417 217.816 186.283 217.816 184.885C217.816 183.487 216.687 182.353 215.293 182.353C213.899 182.353 212.769 183.487 212.769 184.885C212.769 186.283 213.899 187.417 215.293 187.417Z" />
    <path d="M215.293 197.545C216.687 197.545 217.816 196.411 217.816 195.013C217.816 193.615 216.687 192.481 215.293 192.481C213.899 192.481 212.769 193.615 212.769 195.013C212.769 196.411 213.899 197.545 215.293 197.545Z" />
    <path d="M144.634 76.1108C146.028 76.1108 147.157 74.9772 147.157 73.5788C147.157 72.1805 146.028 71.0469 144.634 71.0469C143.24 71.0469 142.11 72.1805 142.11 73.5788C142.11 74.9772 143.24 76.1108 144.634 76.1108Z" />
    <path d="M154.728 76.1108C156.122 76.1108 157.252 74.9772 157.252 73.5788C157.252 72.1805 156.122 71.0469 154.728 71.0469C153.334 71.0469 152.205 72.1805 152.205 73.5788C152.205 74.9772 153.334 76.1108 154.728 76.1108Z" />
    <path d="M164.822 65.9834C166.216 65.9834 167.346 64.8498 167.346 63.4514C167.346 62.053 166.216 60.9194 164.822 60.9194C163.429 60.9194 162.299 62.053 162.299 63.4514C162.299 64.8498 163.429 65.9834 164.822 65.9834Z" />
    <path d="M164.822 76.1113C166.216 76.1113 167.346 74.9777 167.346 73.5793C167.346 72.181 166.216 71.0474 164.822 71.0474C163.429 71.0474 162.299 72.181 162.299 73.5793C162.299 74.9777 163.429 76.1113 164.822 76.1113Z" />
    <path d="M174.916 65.9834C176.309 65.9834 177.439 64.8498 177.439 63.4514C177.439 62.053 176.309 60.9194 174.916 60.9194C173.522 60.9194 172.392 62.053 172.392 63.4514C172.392 64.8498 173.522 65.9834 174.916 65.9834Z" />
    <path d="M174.916 76.1113C176.309 76.1113 177.439 74.9777 177.439 73.5793C177.439 72.181 176.309 71.0474 174.916 71.0474C173.522 71.0474 172.392 72.181 172.392 73.5793C172.392 74.9777 173.522 76.1113 174.916 76.1113Z" />
    <path d="M185.011 65.9834C186.405 65.9834 187.535 64.8498 187.535 63.4514C187.535 62.053 186.405 60.9194 185.011 60.9194C183.617 60.9194 182.488 62.053 182.488 63.4514C182.488 64.8498 183.617 65.9834 185.011 65.9834Z" />
    <path d="M185.011 76.1113C186.405 76.1113 187.535 74.9777 187.535 73.5793C187.535 72.181 186.405 71.0474 185.011 71.0474C183.617 71.0474 182.488 72.181 182.488 73.5793C182.488 74.9777 183.617 76.1113 185.011 76.1113Z" />
    <path d="M195.104 55.8554C196.498 55.8554 197.628 54.7218 197.628 53.3235C197.628 51.9251 196.498 50.7915 195.104 50.7915C193.711 50.7915 192.581 51.9251 192.581 53.3235C192.581 54.7218 193.711 55.8554 195.104 55.8554Z" />
    <path d="M195.104 65.9834C196.498 65.9834 197.628 64.8498 197.628 63.4514C197.628 62.053 196.498 60.9194 195.104 60.9194C193.711 60.9194 192.581 62.053 192.581 63.4514C192.581 64.8498 193.711 65.9834 195.104 65.9834Z" />
    <path d="M195.104 76.1113C196.498 76.1113 197.628 74.9777 197.628 73.5793C197.628 72.181 196.498 71.0474 195.104 71.0474C193.711 71.0474 192.581 72.181 192.581 73.5793C192.581 74.9777 193.711 76.1113 195.104 76.1113Z" />
    <path d="M195.104 86.2392C196.498 86.2392 197.628 85.1056 197.628 83.7073C197.628 82.3089 196.498 81.1753 195.104 81.1753C193.711 81.1753 192.581 82.3089 192.581 83.7073C192.581 85.1056 193.711 86.2392 195.104 86.2392Z" />
    <path d="M195.104 96.3672C196.498 96.3672 197.628 95.2336 197.628 93.8352C197.628 92.4368 196.498 91.3032 195.104 91.3032C193.711 91.3032 192.581 92.4368 192.581 93.8352C192.581 95.2336 193.711 96.3672 195.104 96.3672Z" />
    <path d="M205.2 45.7275C206.593 45.7275 207.723 44.5939 207.723 43.1955C207.723 41.7972 206.593 40.6636 205.2 40.6636C203.806 40.6636 202.676 41.7972 202.676 43.1955C202.676 44.5939 203.806 45.7275 205.2 45.7275Z" />
    <path d="M205.2 55.8554C206.593 55.8554 207.723 54.7218 207.723 53.3235C207.723 51.9251 206.593 50.7915 205.2 50.7915C203.806 50.7915 202.676 51.9251 202.676 53.3235C202.676 54.7218 203.806 55.8554 205.2 55.8554Z" />
    <path d="M205.2 65.9834C206.593 65.9834 207.723 64.8498 207.723 63.4514C207.723 62.053 206.593 60.9194 205.2 60.9194C203.806 60.9194 202.676 62.053 202.676 63.4514C202.676 64.8498 203.806 65.9834 205.2 65.9834Z" />
    <path d="M205.2 76.1113C206.593 76.1113 207.723 74.9777 207.723 73.5793C207.723 72.181 206.593 71.0474 205.2 71.0474C203.806 71.0474 202.676 72.181 202.676 73.5793C202.676 74.9777 203.806 76.1113 205.2 76.1113Z" />
    <path d="M205.2 86.2392C206.593 86.2392 207.723 85.1056 207.723 83.7073C207.723 82.3089 206.593 81.1753 205.2 81.1753C203.806 81.1753 202.676 82.3089 202.676 83.7073C202.676 85.1056 203.806 86.2392 205.2 86.2392Z" />
    <path d="M205.2 96.3672C206.593 96.3672 207.723 95.2336 207.723 93.8352C207.723 92.4368 206.593 91.3032 205.2 91.3032C203.806 91.3032 202.676 92.4368 202.676 93.8352C202.676 95.2336 203.806 96.3672 205.2 96.3672Z" />
    <path d="M215.293 35.5996C216.687 35.5996 217.816 34.466 217.816 33.0676C217.816 31.6692 216.687 30.5356 215.293 30.5356C213.899 30.5356 212.769 31.6692 212.769 33.0676C212.769 34.466 213.899 35.5996 215.293 35.5996Z" />
    <path d="M215.293 45.7275C216.687 45.7275 217.816 44.5939 217.816 43.1955C217.816 41.7972 216.687 40.6636 215.293 40.6636C213.899 40.6636 212.769 41.7972 212.769 43.1955C212.769 44.5939 213.899 45.7275 215.293 45.7275Z" />
    <path d="M215.293 55.8554C216.687 55.8554 217.816 54.7218 217.816 53.3235C217.816 51.9251 216.687 50.7915 215.293 50.7915C213.899 50.7915 212.769 51.9251 212.769 53.3235C212.769 54.7218 213.899 55.8554 215.293 55.8554Z" />
    <path d="M215.293 65.9834C216.687 65.9834 217.816 64.8498 217.816 63.4514C217.816 62.053 216.687 60.9194 215.293 60.9194C213.899 60.9194 212.769 62.053 212.769 63.4514C212.769 64.8498 213.899 65.9834 215.293 65.9834Z" />
    <path d="M215.293 76.1113C216.687 76.1113 217.816 74.9777 217.816 73.5793C217.816 72.181 216.687 71.0474 215.293 71.0474C213.899 71.0474 212.769 72.181 212.769 73.5793C212.769 74.9777 213.899 76.1113 215.293 76.1113Z" />
    <path d="M215.293 86.2392C216.687 86.2392 217.816 85.1056 217.816 83.7073C217.816 82.3089 216.687 81.1753 215.293 81.1753C213.899 81.1753 212.769 82.3089 212.769 83.7073C212.769 85.1056 213.899 86.2392 215.293 86.2392Z" />
    <path d="M215.293 96.3672C216.687 96.3672 217.816 95.2336 217.816 93.8352C217.816 92.4368 216.687 91.3032 215.293 91.3032C213.899 91.3032 212.769 92.4368 212.769 93.8352C212.769 95.2336 213.899 96.3672 215.293 96.3672Z" />
    <path d="M144.634 369.162C146.028 369.162 147.157 368.028 147.157 366.63C147.157 365.231 146.028 364.098 144.634 364.098C143.24 364.098 142.11 365.231 142.11 366.63C142.11 368.028 143.24 369.162 144.634 369.162Z" />
    <path d="M154.728 369.162C156.122 369.162 157.252 368.028 157.252 366.63C157.252 365.231 156.122 364.098 154.728 364.098C153.334 364.098 152.205 365.231 152.205 366.63C152.205 368.028 153.334 369.162 154.728 369.162Z" />
    <path d="M164.822 369.161C166.216 369.161 167.346 368.028 167.346 366.629C167.346 365.231 166.216 364.097 164.822 364.097C163.429 364.097 162.299 365.231 162.299 366.629C162.299 368.028 163.429 369.161 164.822 369.161Z" />
    <path d="M174.916 369.161C176.309 369.161 177.439 368.028 177.439 366.629C177.439 365.231 176.309 364.097 174.916 364.097C173.522 364.097 172.392 365.231 172.392 366.629C172.392 368.028 173.522 369.161 174.916 369.161Z" />
    <path d="M185.011 359.033C186.405 359.033 187.535 357.9 187.535 356.501C187.535 355.103 186.405 353.969 185.011 353.969C183.617 353.969 182.488 355.103 182.488 356.501C182.488 357.9 183.617 359.033 185.011 359.033Z" />
    <path d="M185.011 369.161C186.405 369.161 187.535 368.028 187.535 366.629C187.535 365.231 186.405 364.097 185.011 364.097C183.617 364.097 182.488 365.231 182.488 366.629C182.488 368.028 183.617 369.161 185.011 369.161Z" />
    <path d="M195.104 308.394C196.498 308.394 197.628 307.26 197.628 305.862C197.628 304.463 196.498 303.33 195.104 303.33C193.711 303.33 192.581 304.463 192.581 305.862C192.581 307.26 193.711 308.394 195.104 308.394Z" />
    <path d="M195.104 318.522C196.498 318.522 197.628 317.389 197.628 315.99C197.628 314.592 196.498 313.458 195.104 313.458C193.711 313.458 192.581 314.592 192.581 315.99C192.581 317.389 193.711 318.522 195.104 318.522Z" />
    <path d="M195.104 328.649C196.498 328.649 197.628 327.516 197.628 326.117C197.628 324.719 196.498 323.585 195.104 323.585C193.711 323.585 192.581 324.719 192.581 326.117C192.581 327.516 193.711 328.649 195.104 328.649Z" />
    <path d="M195.104 338.777C196.498 338.777 197.628 337.644 197.628 336.245C197.628 334.847 196.498 333.713 195.104 333.713C193.711 333.713 192.581 334.847 192.581 336.245C192.581 337.644 193.711 338.777 195.104 338.777Z" />
    <path d="M195.104 348.905C196.498 348.905 197.628 347.772 197.628 346.373C197.628 344.975 196.498 343.841 195.104 343.841C193.711 343.841 192.581 344.975 192.581 346.373C192.581 347.772 193.711 348.905 195.104 348.905Z" />
    <path d="M195.104 359.033C196.498 359.033 197.628 357.9 197.628 356.501C197.628 355.103 196.498 353.969 195.104 353.969C193.711 353.969 192.581 355.103 192.581 356.501C192.581 357.9 193.711 359.033 195.104 359.033Z" />
    <path d="M195.104 369.161C196.498 369.161 197.628 368.028 197.628 366.629C197.628 365.231 196.498 364.097 195.104 364.097C193.711 364.097 192.581 365.231 192.581 366.629C192.581 368.028 193.711 369.161 195.104 369.161Z" />
    <path d="M205.2 308.394C206.593 308.394 207.723 307.26 207.723 305.862C207.723 304.463 206.593 303.33 205.2 303.33C203.806 303.33 202.676 304.463 202.676 305.862C202.676 307.26 203.806 308.394 205.2 308.394Z" />
    <path d="M205.2 318.522C206.593 318.522 207.723 317.389 207.723 315.99C207.723 314.592 206.593 313.458 205.2 313.458C203.806 313.458 202.676 314.592 202.676 315.99C202.676 317.389 203.806 318.522 205.2 318.522Z" />
    <path d="M205.2 328.649C206.593 328.649 207.723 327.516 207.723 326.117C207.723 324.719 206.593 323.585 205.2 323.585C203.806 323.585 202.676 324.719 202.676 326.117C202.676 327.516 203.806 328.649 205.2 328.649Z" />
    <path d="M205.2 338.777C206.593 338.777 207.723 337.644 207.723 336.245C207.723 334.847 206.593 333.713 205.2 333.713C203.806 333.713 202.676 334.847 202.676 336.245C202.676 337.644 203.806 338.777 205.2 338.777Z" />
    <path d="M205.2 348.905C206.593 348.905 207.723 347.772 207.723 346.373C207.723 344.975 206.593 343.841 205.2 343.841C203.806 343.841 202.676 344.975 202.676 346.373C202.676 347.772 203.806 348.905 205.2 348.905Z" />
    <path d="M205.2 359.033C206.593 359.033 207.723 357.9 207.723 356.501C207.723 355.103 206.593 353.969 205.2 353.969C203.806 353.969 202.676 355.103 202.676 356.501C202.676 357.9 203.806 359.033 205.2 359.033Z" />
    <path d="M205.2 369.161C206.593 369.161 207.723 368.028 207.723 366.629C207.723 365.231 206.593 364.097 205.2 364.097C203.806 364.097 202.676 365.231 202.676 366.629C202.676 368.028 203.806 369.161 205.2 369.161Z" />
    <path d="M215.293 308.394C216.687 308.394 217.816 307.26 217.816 305.862C217.816 304.463 216.687 303.33 215.293 303.33C213.899 303.33 212.769 304.463 212.769 305.862C212.769 307.26 213.899 308.394 215.293 308.394Z" />
    <path d="M215.293 318.522C216.687 318.522 217.816 317.389 217.816 315.99C217.816 314.592 216.687 313.458 215.293 313.458C213.899 313.458 212.769 314.592 212.769 315.99C212.769 317.389 213.899 318.522 215.293 318.522Z" />
    <path d="M215.293 328.649C216.687 328.649 217.816 327.516 217.816 326.117C217.816 324.719 216.687 323.585 215.293 323.585C213.899 323.585 212.769 324.719 212.769 326.117C212.769 327.516 213.899 328.649 215.293 328.649Z" />
    <path d="M215.293 338.777C216.687 338.777 217.816 337.644 217.816 336.245C217.816 334.847 216.687 333.713 215.293 333.713C213.899 333.713 212.769 334.847 212.769 336.245C212.769 337.644 213.899 338.777 215.293 338.777Z" />
    <path d="M215.293 348.905C216.687 348.905 217.816 347.772 217.816 346.373C217.816 344.975 216.687 343.841 215.293 343.841C213.899 343.841 212.769 344.975 212.769 346.373C212.769 347.772 213.899 348.905 215.293 348.905Z" />
    <path d="M215.293 359.033C216.687 359.033 217.816 357.9 217.816 356.501C217.816 355.103 216.687 353.969 215.293 353.969C213.899 353.969 212.769 355.103 212.769 356.501C212.769 357.9 213.899 359.033 215.293 359.033Z" />
    <path d="M215.293 369.161C216.687 369.161 217.816 368.028 217.816 366.629C217.816 365.231 216.687 364.097 215.293 364.097C213.899 364.097 212.769 365.231 212.769 366.629C212.769 368.028 213.899 369.161 215.293 369.161Z" />
    <path d="M225.388 348.905C226.782 348.905 227.912 347.772 227.912 346.373C227.912 344.975 226.782 343.841 225.388 343.841C223.994 343.841 222.865 344.975 222.865 346.373C222.865 347.772 223.994 348.905 225.388 348.905Z" />
    <path d="M225.388 359.033C226.782 359.033 227.912 357.9 227.912 356.501C227.912 355.103 226.782 353.969 225.388 353.969C223.994 353.969 222.865 355.103 222.865 356.501C222.865 357.9 223.994 359.033 225.388 359.033Z" />
    <path d="M225.388 369.161C226.782 369.161 227.912 368.028 227.912 366.629C227.912 365.231 226.782 364.097 225.388 364.097C223.994 364.097 222.865 365.231 222.865 366.629C222.865 368.028 223.994 369.161 225.388 369.161Z" />
    <path d="M235.481 359.033C236.875 359.033 238.005 357.9 238.005 356.501C238.005 355.103 236.875 353.969 235.481 353.969C234.088 353.969 232.958 355.103 232.958 356.501C232.958 357.9 234.088 359.033 235.481 359.033Z" />
    <path d="M235.481 369.161C236.875 369.161 238.005 368.028 238.005 366.629C238.005 365.231 236.875 364.097 235.481 364.097C234.088 364.097 232.958 365.231 232.958 366.629C232.958 368.028 234.088 369.161 235.481 369.161Z" />
    <path d="M245.577 369.161C246.97 369.161 248.1 368.028 248.1 366.629C248.1 365.231 246.97 364.097 245.577 364.097C244.183 364.097 243.053 365.231 243.053 366.629C243.053 368.028 244.183 369.161 245.577 369.161Z" />
    <path d="M255.67 369.161C257.064 369.161 258.194 368.028 258.194 366.629C258.194 365.231 257.064 364.097 255.67 364.097C254.276 364.097 253.146 365.231 253.146 366.629C253.146 368.028 254.276 369.161 255.67 369.161Z" />
    <path d="M265.764 369.162C267.158 369.162 268.288 368.028 268.288 366.63C268.288 365.231 267.158 364.098 265.764 364.098C264.371 364.098 263.241 365.231 263.241 366.63C263.241 368.028 264.371 369.162 265.764 369.162Z" />
    <path d="M195.104 207.216C196.498 207.216 197.628 206.082 197.628 204.684C197.628 203.285 196.498 202.152 195.104 202.152C193.711 202.152 192.581 203.285 192.581 204.684C192.581 206.082 193.711 207.216 195.104 207.216Z" />
    <path d="M195.104 217.344C196.498 217.344 197.628 216.21 197.628 214.812C197.628 213.413 196.498 212.28 195.104 212.28C193.711 212.28 192.581 213.413 192.581 214.812C192.581 216.21 193.711 217.344 195.104 217.344Z" />
    <path d="M195.104 227.472C196.498 227.472 197.628 226.338 197.628 224.94C197.628 223.541 196.498 222.408 195.104 222.408C193.711 222.408 192.581 223.541 192.581 224.94C192.581 226.338 193.711 227.472 195.104 227.472Z" />
    <path d="M195.104 237.6C196.498 237.6 197.628 236.466 197.628 235.068C197.628 233.669 196.498 232.536 195.104 232.536C193.711 232.536 192.581 233.669 192.581 235.068C192.581 236.466 193.711 237.6 195.104 237.6Z" />
    <path d="M195.104 247.728C196.498 247.728 197.628 246.594 197.628 245.196C197.628 243.797 196.498 242.664 195.104 242.664C193.711 242.664 192.581 243.797 192.581 245.196C192.581 246.594 193.711 247.728 195.104 247.728Z" />
    <path d="M195.104 257.855C196.498 257.855 197.628 256.722 197.628 255.323C197.628 253.925 196.498 252.792 195.104 252.792C193.711 252.792 192.581 253.925 192.581 255.323C192.581 256.722 193.711 257.855 195.104 257.855Z" />
    <path d="M195.104 267.983C196.498 267.983 197.628 266.85 197.628 265.451C197.628 264.053 196.498 262.919 195.104 262.919C193.711 262.919 192.581 264.053 192.581 265.451C192.581 266.85 193.711 267.983 195.104 267.983Z" />
    <path d="M195.104 278.111C196.498 278.111 197.628 276.978 197.628 275.579C197.628 274.181 196.498 273.047 195.104 273.047C193.711 273.047 192.581 274.181 192.581 275.579C192.581 276.978 193.711 278.111 195.104 278.111Z" />
    <path d="M195.104 288.239C196.498 288.239 197.628 287.106 197.628 285.707C197.628 284.309 196.498 283.175 195.104 283.175C193.711 283.175 192.581 284.309 192.581 285.707C192.581 287.106 193.711 288.239 195.104 288.239Z" />
    <path d="M195.104 298.367C196.498 298.367 197.628 297.234 197.628 295.835C197.628 294.437 196.498 293.303 195.104 293.303C193.711 293.303 192.581 294.437 192.581 295.835C192.581 297.234 193.711 298.367 195.104 298.367Z" />
    <path d="M205.2 207.216C206.593 207.216 207.723 206.082 207.723 204.684C207.723 203.285 206.593 202.152 205.2 202.152C203.806 202.152 202.676 203.285 202.676 204.684C202.676 206.082 203.806 207.216 205.2 207.216Z" />
    <path d="M205.2 217.344C206.593 217.344 207.723 216.21 207.723 214.812C207.723 213.413 206.593 212.28 205.2 212.28C203.806 212.28 202.676 213.413 202.676 214.812C202.676 216.21 203.806 217.344 205.2 217.344Z" />
    <path d="M205.2 227.472C206.593 227.472 207.723 226.338 207.723 224.94C207.723 223.541 206.593 222.408 205.2 222.408C203.806 222.408 202.676 223.541 202.676 224.94C202.676 226.338 203.806 227.472 205.2 227.472Z" />
    <path d="M205.2 237.6C206.593 237.6 207.723 236.466 207.723 235.068C207.723 233.669 206.593 232.536 205.2 232.536C203.806 232.536 202.676 233.669 202.676 235.068C202.676 236.466 203.806 237.6 205.2 237.6Z" />
    <path d="M205.2 247.728C206.593 247.728 207.723 246.594 207.723 245.196C207.723 243.797 206.593 242.664 205.2 242.664C203.806 242.664 202.676 243.797 202.676 245.196C202.676 246.594 203.806 247.728 205.2 247.728Z" />
    <path d="M205.2 257.855C206.593 257.855 207.723 256.722 207.723 255.323C207.723 253.925 206.593 252.792 205.2 252.792C203.806 252.792 202.676 253.925 202.676 255.323C202.676 256.722 203.806 257.855 205.2 257.855Z" />
    <path d="M205.2 267.983C206.593 267.983 207.723 266.85 207.723 265.451C207.723 264.053 206.593 262.919 205.2 262.919C203.806 262.919 202.676 264.053 202.676 265.451C202.676 266.85 203.806 267.983 205.2 267.983Z" />
    <path d="M205.2 278.111C206.593 278.111 207.723 276.978 207.723 275.579C207.723 274.181 206.593 273.047 205.2 273.047C203.806 273.047 202.676 274.181 202.676 275.579C202.676 276.978 203.806 278.111 205.2 278.111Z" />
    <path d="M205.2 288.239C206.593 288.239 207.723 287.106 207.723 285.707C207.723 284.309 206.593 283.175 205.2 283.175C203.806 283.175 202.676 284.309 202.676 285.707C202.676 287.106 203.806 288.239 205.2 288.239Z" />
    <path d="M205.2 298.367C206.593 298.367 207.723 297.234 207.723 295.835C207.723 294.437 206.593 293.303 205.2 293.303C203.806 293.303 202.676 294.437 202.676 295.835C202.676 297.234 203.806 298.367 205.2 298.367Z" />
    <path d="M215.293 207.216C216.687 207.216 217.816 206.082 217.816 204.684C217.816 203.285 216.687 202.152 215.293 202.152C213.899 202.152 212.769 203.285 212.769 204.684C212.769 206.082 213.899 207.216 215.293 207.216Z" />
    <path d="M215.293 217.344C216.687 217.344 217.816 216.21 217.816 214.812C217.816 213.413 216.687 212.28 215.293 212.28C213.899 212.28 212.769 213.413 212.769 214.812C212.769 216.21 213.899 217.344 215.293 217.344Z" />
    <path d="M215.293 227.472C216.687 227.472 217.816 226.338 217.816 224.94C217.816 223.541 216.687 222.408 215.293 222.408C213.899 222.408 212.769 223.541 212.769 224.94C212.769 226.338 213.899 227.472 215.293 227.472Z" />
    <path d="M215.293 237.6C216.687 237.6 217.816 236.466 217.816 235.068C217.816 233.669 216.687 232.536 215.293 232.536C213.899 232.536 212.769 233.669 212.769 235.068C212.769 236.466 213.899 237.6 215.293 237.6Z" />
    <path d="M215.293 247.728C216.687 247.728 217.816 246.594 217.816 245.196C217.816 243.797 216.687 242.664 215.293 242.664C213.899 242.664 212.769 243.797 212.769 245.196C212.769 246.594 213.899 247.728 215.293 247.728Z" />
    <path d="M215.293 257.855C216.687 257.855 217.816 256.722 217.816 255.323C217.816 253.925 216.687 252.792 215.293 252.792C213.899 252.792 212.769 253.925 212.769 255.323C212.769 256.722 213.899 257.855 215.293 257.855Z" />
    <path d="M215.293 267.983C216.687 267.983 217.816 266.85 217.816 265.451C217.816 264.053 216.687 262.919 215.293 262.919C213.899 262.919 212.769 264.053 212.769 265.451C212.769 266.85 213.899 267.983 215.293 267.983Z" />
    <path d="M215.293 278.111C216.687 278.111 217.816 276.978 217.816 275.579C217.816 274.181 216.687 273.047 215.293 273.047C213.899 273.047 212.769 274.181 212.769 275.579C212.769 276.978 213.899 278.111 215.293 278.111Z" />
    <path d="M215.293 288.239C216.687 288.239 217.816 287.106 217.816 285.707C217.816 284.309 216.687 283.175 215.293 283.175C213.899 283.175 212.769 284.309 212.769 285.707C212.769 287.106 213.899 288.239 215.293 288.239Z" />
    <path d="M215.293 298.367C216.687 298.367 217.816 297.234 217.816 295.835C217.816 294.437 216.687 293.303 215.293 293.303C213.899 293.303 212.769 294.437 212.769 295.835C212.769 297.234 213.899 298.367 215.293 298.367Z" />
  </svg>
);
