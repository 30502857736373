/* eslint-disable no-console */
import { styled } from "@linaria/react";
import { colors, withOpacity } from "../../styles/colors.styles";
import { PropsWithChildren } from "react";
import { getElementBoundingBoxFromPage } from "../../utils/boundingRect.utils";
import { debounce } from "../../utils/debounce.utils";
import { inRange } from "lodash-es";
import { font } from "../../styles/fonts.styles";
import { PageThemeProps } from "../utilities/PageTheme";
import gsap from "gsap";
import { waitForProgrammaticScrollingEnd } from "../../utils/anchorLinkScroll.utils";
import { removeOneFromArray } from "../../utils/array.utils";
import { isDevelopment } from "../../environment";

export type OnPageThemeConfig = Pick<
  PageThemeProps,
  "backgroundColor" | "textColor"
>;

type Props = OnPageThemeConfig & {
  asControlPoint?: boolean;
};

const DEBUG = isDevelopment ? false : false;

const className = "PageThemeControllerSection";

type Controller = {
  element: HTMLDivElement;
  nextController: Controller | null;
  config: OnPageThemeConfig | null;
  height: number;
  isPointController: boolean;
  isVisible: boolean;
  updateBoundingBox: () => ReturnType<typeof getElementBoundingBoxFromPage>;
  boundingBox: ReturnType<typeof getElementBoundingBoxFromPage>;
  dispose: () => void;
};

const offset = 0;

const getAllControllerEntries = () => {
  return Array.from(document.querySelectorAll<HTMLDivElement>(`.${className}`))
    .map(element => {
      if (!element) return null;
      let isVisible = false;
      const observer = new IntersectionObserver(
        entries => {
          isVisible = entries[0].isIntersecting;
        },
        { rootMargin: `-${offset}px` }
      );
      let boundingBox = getElementBoundingBoxFromPage(element);
      observer.observe(element);
      const controller: Controller = {
        element,
        nextController: null,
        get config() {
          try {
            return JSON.parse(
              decodeURIComponent(element.getAttribute("data-config") ?? "")
            ) as OnPageThemeConfig;
          } catch (e) {
            return null;
          }
        },
        get height() {
          return element.clientHeight;
        },
        get isPointController() {
          return element.clientHeight === 0;
        },
        get isVisible() {
          if (controller.isPointController) {
            return inRange(
              window.scrollY + offset + window.innerHeight,
              boundingBox.top,
              controller.nextController?.boundingBox.top ??
                document.documentElement.scrollHeight
            );
          } else return isVisible;
        },
        updateBoundingBox() {
          const newBoundingBox = getElementBoundingBoxFromPage(element);
          boundingBox = newBoundingBox;
          return boundingBox;
        },
        get boundingBox() {
          return boundingBox;
        },
        dispose: () => {
          observer.disconnect();
        },
      };
      return controller;
    })
    .filter(i => i) as Controller[];
};

let existingController = null as null | PageThemeController;

export const createPageThemeController = (
  defaultConfig: Required<OnPageThemeConfig> & {
    pathname: string;
  }
) => {
  if (existingController) existingController.dispose();
  let disposed = false;
  const entries = getAllControllerEntries();
  if (entries.length === 0) return null;
  entries.forEach((entry, i) => {
    const next = entries[i + 1];
    if (next) entry.nextController = next;
  });
  let currentTheme: OnPageThemeConfig | null = null;
  const queuedThemeRef = {
    next: defaultConfig,
  };
  const tweens: ReturnType<GSAP["fromTo"]>[] = [];
  const applyTransition = () => {
    // eslint-disable-next-line no-console
    if (DEBUG) console.log(`page theme transitioning`);
    const { next } = queuedThemeRef;
    if (
      currentTheme &&
      currentTheme.backgroundColor === next.backgroundColor &&
      currentTheme.textColor === next.textColor
    )
      return;
    currentTheme = { ...next };
    const computedStyle = getComputedStyle(document.documentElement);
    const tween = gsap.fromTo(
      document.documentElement,
      {
        "--PageBackgroundColor":
          computedStyle.backgroundColor ?? defaultConfig.backgroundColor,
        "--PageForegroundColor": computedStyle.color ?? defaultConfig.textColor,
      },
      {
        "--PageBackgroundColor":
          next.backgroundColor ?? defaultConfig.backgroundColor,
        "--PageForegroundColor": next.textColor ?? defaultConfig.textColor,
        duration: 1,
        onComplete: () => {
          removeOneFromArray(tweens, tween);
          if (window.location.pathname !== defaultConfig.pathname) dispose();
        },
      }
    );
    tweens.push(tween);
  };
  const onUpdate = (next: OnPageThemeConfig) => {
    queuedThemeRef.next = Object.assign(defaultConfig, next);
    if (DEBUG) console.log(`queuing theme...`, queuedThemeRef.next);
    waitForProgrammaticScrollingEnd(applyTransition);
  };
  const handleScroll = () => {
    const firstVisibleSection = entries.find(s => {
      return s.isVisible;
    });
    const config = Object.assign(defaultConfig, firstVisibleSection?.config);
    if (
      currentTheme?.backgroundColor !== config.backgroundColor ||
      currentTheme?.textColor !== config.textColor
    ) {
      onUpdate(config);
    }
  };
  const handleResize = debounce(() => {
    entries.forEach(section => section.updateBoundingBox());
  });
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
  handleScroll();
  setTimeout(handleResize, 1000);
  setTimeout(handleScroll);
  const dispose = () => {
    if (disposed) return;
    disposed = true;
    tweens.forEach(t => {
      t.kill();
    });
    document.documentElement.style.removeProperty("--PageBackgroundColor");
    document.documentElement.style.removeProperty("--PageForegroundColor");
    entries.forEach(s => s.dispose());
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("scroll", handleScroll);
  };
  const controller = {
    entries,
    dispose,
  };
  existingController = controller;
  return controller;
};

export type PageThemeController = ReturnType<typeof createPageThemeController>;

const ControllerWrap = styled.div`
  position: relative;
  &[data-is-control-point="true"] {
    height: 0;
  }
`;

const DebugInfo = styled.p`
  position: absolute;
  color: ${colors.white};
  background: ${withOpacity(colors.black, 0.5)};
  font-size: 12px;
  font-family: ${font("monospace")};
  white-space: nowrap;
  z-index: 1000000000;
`;

const PageThemeControllerSection = (props: PropsWithChildren<Props>) => {
  const themingConfig: OnPageThemeConfig = {
    backgroundColor: props.backgroundColor,
    textColor: props.textColor,
  };
  return (
    <ControllerWrap
      className={className}
      data-is-control-point={props.asControlPoint}
      data-config={encodeURIComponent(JSON.stringify(themingConfig))}
    >
      {DEBUG && (
        <DebugInfo>
          backgroundColor:{" "}
          <span
            style={{
              display: "inline-block",
              backgroundColor: props.backgroundColor,
              width: "1em",
              height: "1em",
            }}
          />{" "}
          {props.backgroundColor} textColor: {props.textColor}
        </DebugInfo>
      )}
      {props.children}
    </ControllerWrap>
  );
};

export const PageThemeControllerPoint = (props: OnPageThemeConfig) => {
  return <PageThemeControllerSection {...props} asControlPoint />;
};

export default PageThemeControllerSection;
