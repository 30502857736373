import { styled } from "@linaria/react";
import { isStructuredText } from "datocms-structured-text-utils";
import { useRef, useState } from "react";
import { StructuredText } from "react-datocms";
import { DatoCmsPartner } from "../../../graphql-types";
import { widthInGrid } from "../../constants/globalGrid.constants";
import {
  fromDesktopMd,
  fromPhoneLg,
  fromTabletLg,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { font } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { valueWithOptionalUnit } from "../../utils/css.utils";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { snapToNearestMultiplesOfValue } from "../../utils/math.utils";
import PageSection from "../reusableSections/PageSection";
import FlexibleDotGrid, {
  DEFAULT_CELL_SIZE,
} from "../decorations/FlexibleDotGrid";
import { useStateWithRef } from "../../utils/stateWithRef.hook";
import { colors } from "../../styles/colors.styles";

type Props = {
  partner: DatoCmsPartner;
};

const PartnerPageGriddedSectionInner = styled.div`
  position: relative;
`;

const ContentGrid = styled.div<{ gridCellSize: number }>`
  position: relative;
  display: grid;
  grid-gap: ${p => `${p.gridCellSize}px`};
  padding-top: ${p => `${p.gridCellSize * 2}px`};
  padding-bottom: ${p => `${p.gridCellSize * 2}px`};
  ${fromTabletMd} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: ". block block block block block block .";
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ". . block block block block block block block block . .";
  }
  > * {
    background-color: ${colors.lightest};
  }
`;

type BlockProps = { gridCellSize: number; minHeight: string | number };
const Block = styled.div<BlockProps>`
  padding-bottom: ${p => `${p.gridCellSize}px`};
  box-sizing: content-box;
  min-height: ${p => valueWithOptionalUnit(p.minHeight, "auto")};
  padding-top: ${p => `${p.gridCellSize * 0.5}px`};
  ${fromTabletMd} {
    grid-area: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${fromDesktopMd} {
    padding-bottom: ${p => `${p.gridCellSize * 1.5}px`};
  }
`;

const BlockContent = styled.div`
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  ${fromPhoneLg} {
    padding-left: 2em;
    padding-right: 2em;
  }
  > * {
    + * {
      margin-top: ${rSize("md")};
    }
  }
`;

const IconImg = styled.img`
  width: 12rem;
  height: 12rem;
  margin-left: -3%;
  pointer-events: none;
`;

const TextContent = styled.div`
  > * {
    + * {
      margin-top: ${rSize("md")};
    }
  }
  h1,
  h2 {
    font-family: ${font("serif")};
    font-weight: 400;
    line-height: 1.5;
  }
  h2 {
    font-size: 1.8rem;
    ${fromTabletLg} {
      font-size: 2.1rem;
    }
  }
  > * {
    ${fromTabletLg} {
      max-width: ${widthInGrid(6)};
      margin-left: auto;
      margin-right: auto;
    }
  }
  em {
    font-style: inherit;
    font-family: ${font("serif")};
    font-size: 125%;
  }
`;

const PartnerPageGriddedSection = ({ partner }: Props) => {
  const [gridCellSizeRef, setGridCellSize, gridCellSize] =
    useStateWithRef(DEFAULT_CELL_SIZE);
  const contentGridRef = useRef<HTMLDivElement>(null);
  const blockContentRef = useRef<HTMLDivElement>(null);
  const [blockMinHeight, setBlockMinHeight] = useState<string | number>(
    "unset"
  );
  useOnMount(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      setBlockMinHeight(
        snapToNearestMultiplesOfValue(
          entry.target.clientHeight,
          gridCellSizeRef.current,
          "ceil"
        )
      );
    });
    if (blockContentRef.current)
      resizeObserver.observe(blockContentRef.current);
    return () => resizeObserver.disconnect();
  });
  return (
    <PageSection>
      <PartnerPageGriddedSectionInner>
        <FlexibleDotGrid
          cover
          onFittedCellSizeRecalc={setGridCellSize}
          heightCalculationRef={contentGridRef}
        />
        <ContentGrid ref={contentGridRef} gridCellSize={gridCellSize}>
          <Block gridCellSize={gridCellSize} minHeight={blockMinHeight}>
            <BlockContent ref={blockContentRef}>
              {partner.griddedSectionImage?.url && (
                <IconImg src={partner.griddedSectionImage.url} />
              )}
              {isStructuredText(partner.griddedSectionContent) && (
                <TextContent>
                  <StructuredText data={partner.griddedSectionContent} />
                </TextContent>
              )}
            </BlockContent>
          </Block>
        </ContentGrid>
      </PartnerPageGriddedSectionInner>
    </PageSection>
  );
};

export default PartnerPageGriddedSection;
