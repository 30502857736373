import SEO from "../components/utilities/SEO";
import { colors } from "../styles/colors.styles";
import Page from "./Page.template";
import { PageComponent } from "../types/gatsby.types";
import image from "../../static/images/og/tines-customer-center.png";
import { LayoutWithSidebar } from "../components/layout/LayoutWithSidebar";
import { CustomerCenterSidebar } from "../components/customerCenter/CustomerCenterSidebar";
import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
} from "../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../styles/responsiveSizes.styles";
import { CustomerCenterPageHero } from "../components/customerCenter/CustomerCenterPageHero";
import CustomerCenterPageSection from "../components/customerCenter/CustomerCenterPageSection";
import { Link } from "@reach/router";
import { Paths } from "../utils/pathBuilders.utils";
import { graphql } from "gatsby";
import {
  CustomerCenterPageQuery,
  DatoCmsArticle,
  DatoCmsWebinar,
} from "../../graphql-types";
import WebinarEntryLink from "../components/webinars/WebinarEntryLink";
import { EventDirectoryItem } from "../types/helper.types";
import { css } from "linaria";
import ContentCardUniversitySimple from "../components/contentCards/ContentCardUniversitySimple";
import ContentCardBootcamp from "../components/contentCards/ContentCardBootcamp";
import ContentCardGetCertified from "../components/contentCards/ContentCardGetCertified";
import { ContentCardLibrarySimple } from "../components/contentCards/ContentCardLibrarySimple";
import ContentCardSlackCommunity from "../components/contentCards/ContentCardSlackCommunity";
import { ExplosionCTASection } from "../components/reusableSections/ExplosionCTASection";
import Spacing from "../components/layout/Spacing";
import ContentCardGeneric from "../components/contentCards/ContentCardGeneric";
import ArticleEntryLink from "../components/articles/ArticleEntryLink";
import tinesExplainedIcon from "../components/customerCenter/illos/CustomerCenterTinesExplainedIcon.svg";
import docsIcon from "../components/customerCenter/illos/CustomerCenterDocsIcon.svg";
import apiIcon from "../components/customerCenter/illos/CustomerCenterAPIIcon.svg";
import podcastIcon from "../components/customerCenter/illos/CustomerCenterPodcastIcon.svg";
import fridayFlowsIcon from "../components/customerCenter/illos/CustomerCenterFridayFlowsIcon.svg";
import caseStudiesIcon from "../components/customerCenter/illos/CustomerCenterCaseStudiesIcon.svg";
import socAutomationCapabilityMatrixIcon from "../components/customerCenter/illos/CustomerCenterSOCAutomationCapabilityMatrixIcon.svg";
import { CustomerCenterProductUpdatesSection } from "../components/customerCenter/CustomerCenterProductUpdatesSection";
import { CustomerCenterEventsSection } from "../components/customerCenter/CustomerCenterEventsSection";
import { CustomerCenterUsefulLinksSection } from "../components/customerCenter/CustomerCenterUsefulLinksSection";
import { useOnMount } from "../utils/lifeCycle.utils";
import { getFirstVisibleElement } from "../utils/html.utils";
import { ContentCardSolutionsByProduct } from "../components/contentCards/ContentCardSolutionsByProduct";

const BodyContent = styled.div`
  padding-bottom: ${rSize("lg")};
  > * {
    + * {
      margin-top: ${rSize("sectionPadding")};
    }
  }
`;

const WebinarEntryGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromDesktopMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const ThreeSplit = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .ContentCard {
    min-height: 15rem;
    ${fromTablet} {
      min-height: 32rem;
    }
    ${fromDesktopMd} {
      min-height: 38rem;
    }
  }
`;

const FourSplit = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  &.square {
    min-height: 252px;
  }
  &.tall {
    min-height: 360px;
  }
`;

const doubleCol = css`
  ${fromDesktopMd} {
    grid-column-end: span 2;
  }
`;

const docSectionDoubleCol = css`
  ${fromTablet} {
    grid-column-end: span 2;
  }
`;

const CustomerCenterPage: PageComponent<{
  data: CustomerCenterPageQuery;
  pageContext: {
    eventDirectoryItems: EventDirectoryItem[];
  };
}> = props => {
  const description =
    "Whether you’re an expert or just getting started, this page is a resource for links and support to help you get the most from Tines.";

  useOnMount(() => {
    const highlightActiveItemInSidebar = () => {
      const sections = Array.from(
        document.documentElement.querySelectorAll<HTMLHeadingElement>(
          ".CustomerCenterPageSection"
        ) ?? []
      );
      const firstVisibleSection = getFirstVisibleElement(sections, 100);
      document
        .querySelectorAll(".CustomerCenterSidebar a")
        .forEach(a => a.classList.remove("active"));
      if (firstVisibleSection) {
        const firstVisibleSectionId = firstVisibleSection.getAttribute("id");
        if (firstVisibleSectionId) {
          const linkInSidebar = document.querySelector(
            `.CustomerCenterSidebar a[href*="#${firstVisibleSectionId}"]`
          );
          if (linkInSidebar) linkInSidebar.classList.add("active");
        }
      }
    };

    const handleScroll = () => {
      if (window.scrollY > 32) {
        highlightActiveItemInSidebar();
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO title="Customer center" description={description} image={image} />
      <LayoutWithSidebar
        header={<CustomerCenterPageHero description={description} />}
        relative
      >
        <CustomerCenterSidebar />
        <BodyContent>
          <WebinarsSection
            webinars={props.data.webinars.nodes as DatoCmsWebinar[]}
          />
          <CustomerCenterEventsSection
            eventDirectoryItems={props.pageContext.eventDirectoryItems}
          />
          <ResourcesSection />
          <LearnTinesSection />
          <TutorialsSection
            tutorials={props.data.tutorials.nodes as DatoCmsArticle[]}
          />
          <InspirationSection />
          <DocsSection />
          <CustomerCenterProductUpdatesSection
            productUpdates={props.data.productUpdates.nodes as DatoCmsArticle[]}
          />
          <CustomerCenterUsefulLinksSection />
        </BodyContent>
      </LayoutWithSidebar>
      <Spacing size="sectionPadding" />
      <ExplosionCTASection />
    </Page>
  );
};

const WebinarsSection = (props: { webinars: DatoCmsWebinar[] }) => (
  <CustomerCenterPageSection
    title="Webinars"
    id="webinars"
    headerLink={<Link to={Paths.webinars()}>See all →</Link>}
  >
    <WebinarEntryGrid>
      {props.webinars.map((webinar, i) => (
        <WebinarEntryLink
          key={webinar.id}
          className={i === 0 ? doubleCol : undefined}
          webinar={webinar}
          featured={i === 0}
          withExcerpt
          showTopRightTag
        />
      ))}
    </WebinarEntryGrid>
  </CustomerCenterPageSection>
);

const ResourcesSection = () => (
  <CustomerCenterPageSection title="Resources" id="resources">
    <ThreeSplit>
      <ContentCardLibrarySimple large />
      <ContentCardSolutionsByProduct large />
      <ContentCardSlackCommunity large />
    </ThreeSplit>
  </CustomerCenterPageSection>
);
const LearnTinesSection = () => (
  <CustomerCenterPageSection title="Learn Tines" id="learn-tines">
    <ThreeSplit>
      <ContentCardUniversitySimple large />
      <ContentCardBootcamp large />
      <ContentCardGetCertified
        large
        overrides={{
          color: "pink",
        }}
      />
    </ThreeSplit>
  </CustomerCenterPageSection>
);

const DocsSection = () => (
  <CustomerCenterPageSection title="Documentation" id="docs">
    <FourSplit className="square">
      <ContentCardGeneric
        className={docSectionDoubleCol}
        overrides={{
          heading: "Tines Explained",
          description: "Answers and advice from the team that build Tines.",
          linkLabel: "Go to explained.tines.com",
          linkUrl: Paths.tinesExplained(),
          icon: {
            url: tinesExplainedIcon,
          },
        }}
      />
      <ContentCardGeneric
        overrides={{
          heading: "Docs",
          linkLabel: "Go to docs",
          linkUrl: Paths.docs(),
          color: "green",
          icon: {
            url: docsIcon,
          },
        }}
      />
      <ContentCardGeneric
        overrides={{
          heading: "Tines API",
          linkLabel: "API docs",
          linkUrl: Paths.apiDocs(),
          color: "pink",
          icon: {
            url: apiIcon,
          },
        }}
      />
    </FourSplit>
  </CustomerCenterPageSection>
);

const TutorialsSection = (props: { tutorials: DatoCmsArticle[] }) => (
  <CustomerCenterPageSection
    title="Tutorials"
    id="tutorials"
    headerLink={<Link to={Paths.blogCategory("tutorials")}>See all →</Link>}
  >
    <ThreeSplit>
      {props.tutorials.map(t => (
        <ArticleEntryLink key={t.id} record={t} />
      ))}
    </ThreeSplit>
  </CustomerCenterPageSection>
);

const InspirationSection = () => (
  <CustomerCenterPageSection title="Inspiration" id="inspiration">
    <FourSplit className="tall">
      <ContentCardGeneric
        overrides={{
          heading: "Tines Podcast",
          description:
            "The Future of Security Operations podcast is dedicated to empowering SecOps leaders to reimagine how their teams work.",
          linkLabel: "Listen now",
          linkUrl: Paths.podcast(),
          icon: {
            url: podcastIcon,
          },
        }}
      />
      <ContentCardGeneric
        overrides={{
          heading: "Friday Flows",
          description:
            "Join the team for weekly short videos, showcasing new and noteworthy use cases for the platform.",
          linkLabel: "Watch on YouTube",
          linkUrl: Paths.fridayFlows(),
          color: "pink",
          icon: {
            url: fridayFlowsIcon,
          },
        }}
      />
      <ContentCardGeneric
        overrides={{
          heading: "Case studies",
          description:
            "Discover how companies apply Tines to transform he way their teams operate.",
          linkLabel: "See all",
          linkUrl: Paths.caseStudies(),
          color: "green",
          icon: {
            url: caseStudiesIcon,
          },
        }}
      />
      <ContentCardGeneric
        overrides={{
          heading: "Automation capability matrix",
          description:
            "Common activities which most security operations centers can automate.",
          linkLabel: "Access now",
          linkUrl: Paths.socAutomationCapabilityMatrix(),
          color: "orange",
          icon: {
            url: socAutomationCapabilityMatrixIcon,
          },
        }}
      />
    </FourSplit>
  </CustomerCenterPageSection>
);

export const customerCenterPageQuery = graphql`
  query CustomerCenterPage {
    webinars: allDatoCmsWebinar(
      sort: { timeStart: DESC }
      limit: 2
      filter: {
        category: { slug: { in: ["webinars"] } }
        isPrivate: { ne: true }
        # exclude the two test webinars that are always in CMS
        originalId: { nin: ["72675259", "108933288"] }
      }
    ) {
      nodes {
        id: originalId
        title
        slug
        timeStart
        timeEnd
        recordedVideoLink
        excerpt
        colorTheme
        upcomingWebinarEntryBackdrop {
          url
          width
          height
        }
        category {
          slug
        }
        hosts {
          id: originalId
          name
          surname
          title
          photoIcon {
            url
            width
            height
          }
          organization {
            id: originalId
            name
          }
        }
      }
    }
    tutorials: allDatoCmsArticle(
      sort: { datePublished: DESC }
      limit: 3
      filter: {
        categories: { elemMatch: { slug: { eq: "tutorials" } } }
        hiddenInBlogIndex: { ne: true }
      }
    ) {
      nodes {
        title
        slug
        datePublished
        excerpt
        coverImage {
          url
          width
          height
        }
        categories {
          slug
        }
      }
    }
    productUpdates: allDatoCmsArticle(
      sort: { datePublished: DESC }
      limit: 2
      filter: {
        categories: { elemMatch: { slug: { eq: "product-updates" } } }
        hiddenInBlogIndex: { ne: true }
      }
    ) {
      nodes {
        title
        slug
        datePublished
        excerpt
        coverImage {
          url
          width
          height
        }
        categories {
          slug
        }
      }
    }
  }
`;

export default CustomerCenterPage;
