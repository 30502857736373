import { styled } from "@linaria/react";
import { Link } from "gatsby";
import PageBreadCrumbs from "../components/basic/PageBreadCrumbs";
import VotingBanner from "../components/hub/VotingBanner";
import SEO from "../components/utilities/SEO";
import StandardArticle from "../components/articles/StandardArticle";
import { colorsV4, withOpacity } from "../styles/colorsV4.styles";
import { PageComponent } from "../types/gatsby.types";
import { FormulasFunctionDef } from "../types/helper.types";
import { PageTree, getBreadcrumbsFromTree } from "../utils/tree.utils";
import {
  HubPageHeader,
  WiderArticlePageBody,
} from "../components/hub/HubPageComponents";
import Page from "./Page.template";
import SpaceChildren from "../components/layout/SpaceChildren";
import HubPrevNextNav from "../components/hub/HubPrevNextNav";
import ogImage from "../../static/images/og/tines-docs.png";
import LinkedHeading from "../components/utilities/LinkedHeading";
import { darkModeLinariaCSS } from "../utils/colorScheme.utils";
import { BookWithLightbulbTwoToneIcon } from "../components/icons/twoTone/BookWithLightbulbTwoToneIcon";

const FunctionsTable = styled.table`
  tbody {
    th {
      a {
        white-space: nowrap;
        code {
          color: ${colorsV4.purple};
          background-color: ${withOpacity(colorsV4.purple, 0.2)};
          ${darkModeLinariaCSS(`
            color: ${colorsV4.purple};
            background-color: ${withOpacity(colorsV4.purple, 0.2)};
          `)}
        }
        &:hover {
          text-decoration-color: currentColor;
        }
      }
    }
  }
`;

const DocsPageFormulasFunctionsIndexTemplate: PageComponent<{
  pageContext: {
    tree: PageTree;
    categories: Record<string, FormulasFunctionDef[]>;
  };
}> = props => {
  const { location } = props;
  const levels = getBreadcrumbsFromTree(
    location.pathname,
    {
      title: "Docs",
      path: "/docs",
    },
    props.pageContext.tree
  );
  return (
    <Page {...props}>
      <SEO
        title={`Functions | Docs | Tines`}
        image={ogImage}
        description="Get to know the features and concepts of Tines, in detail."
      />
      <WiderArticlePageBody generateTableOfContents supportsColorThemes>
        <PageBreadCrumbs
          icon={<BookWithLightbulbTwoToneIcon />}
          levels={levels}
        />
        <HubPageHeader>
          <h1>Functions</h1>
        </HubPageHeader>
        <SpaceChildren size="lg">
          <StandardArticle>
            {Object.entries(props.pageContext.categories).map(
              ([name, functions]) => (
                <section key={name}>
                  <LinkedHeading>{name}</LinkedHeading>
                  <FunctionsTable>
                    <thead>
                      <tr>
                        <th>Function</th>
                        <th>Summary</th>
                      </tr>
                    </thead>
                    <tbody>
                      {functions.map(fn => (
                        <tr key={fn.name}>
                          <th>
                            <Link to={`/docs/formulas/functions/${fn.slug}`}>
                              <code>{fn.name}</code>
                            </Link>
                          </th>
                          <td
                            dangerouslySetInnerHTML={{ __html: fn.summaryHtml }}
                          />
                        </tr>
                      ))}
                    </tbody>
                  </FunctionsTable>
                </section>
              )
            )}
          </StandardArticle>
          <VotingBanner />
          <HubPrevNextNav tree={props.pageContext.tree} />
        </SpaceChildren>
      </WiderArticlePageBody>
    </Page>
  );
};

export default DocsPageFormulasFunctionsIndexTemplate;
