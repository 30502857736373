import { styled } from "@linaria/react";
import AllCaps from "../typography/AllCaps";
import { getWidthPxInMaxGrid } from "../../constants/globalGrid.constants";
import { colors } from "../../styles/colors.styles";
import { serif } from "../../styles/fonts.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import WorkbenchMoneyChestIcon from "./illustrations/WorkbenchMoneyChestIcon.svg";
import WorkbenchRainbowRoadsIcon from "./illustrations/WorkbenchRainbowRoadsIcon.svg";
import WorkbenchRainbowBridgeIcon from "./illustrations/WorkbenchRainbowBridgeIcon.svg";
import {
  fromTablet,
  fromTabletLg,
  onlyPhones,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Ribbon = styled.div`
  display: grid;
  color: ${colors.green800};
  ${uptoTabletLg} {
    text-align: center;
  }
  ${fromTabletLg} {
    grid-template-columns: ${getWidthPxInMaxGrid(4)} minmax(0, 1fr);
  }
`;

const RibbonHeader = styled.header`
  position: relative;
  background-color: ${colors.green50};
  border: 1px solid ${colors.green300};
  padding: 2.8rem;
  font-size: 1.4rem;
  border-top-left-radius: ${rSize("radius")};
  ${uptoTabletLg} {
    border-top-right-radius: ${rSize("radius")};
  }
  ${fromTabletLg} {
    padding-right: 10rem;
    transform: translateY(24px);
    border-bottom-left-radius: ${rSize("radius")};
  }
  > * {
    + * {
      margin-top: 1.4rem;
    }
  }
  h3 {
    font-size: 2.6rem;
    font-family: ${serif};
    font-weight: 400;
    line-height: 1.1;
  }
  p,
  li {
    font-weight: 500;
    line-height: 1.2;
  }
  ul {
    padding-left: 1.5em;
    li + li {
      margin-top: 0.5em;
    }
  }
  ${fromTabletLg} {
    &:after {
      content: "";
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='71' height='23' viewBox='0 0 71 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71 0H0L71 23V0Z' fill='%2387D1A3'/%3E%3C/svg%3E%0A");
      width: 7.1rem;
      height: 2.3rem;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;

const RibbonContent = styled.div`
  position: relative;
  background-color: ${colors.green50};
  border: 1px solid ${colors.green300};
  border-bottom-right-radius: ${rSize("radius")};
  ${uptoTabletLg} {
    border-top: 0;
    border-bottom-left-radius: ${rSize("radius")};
  }
  ${fromTabletLg} {
    margin-left: -7.2rem;
    border-top-right-radius: ${rSize("radius")};
  }
`;

const Bullets = styled.ul`
  list-style: none;
  ${fromTablet} {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
`;

const Bullet = styled.li`
  padding: 2.4rem;
  ${onlyPhones} {
    display: grid;
    grid-template-columns: 9.6rem minmax(0, 1fr);
    text-align: left;
  }
  + li {
    ${onlyPhones} {
      border-top: 1px solid ${colors.green300};
    }
    ${fromTablet} {
      border-left: 1px solid ${colors.green300};
    }
  }
  figure {
    margin: 0;
    background-color: ${colors.green100};
    border: 1px solid ${colors.green200};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    aspect-ratio: 1;
    img {
      display: block;
      max-width: 80%;
      height: auto;
    }
    + * {
      ${onlyPhones} {
        margin-left: 1em;
      }
      ${fromTablet} {
        margin-top: 1em;
      }
    }
  }
`;

export const WorkbenchForMSSPSampleUseCaseSection = () => {
  return (
    <Ribbon>
      <RibbonHeader>
        <AllCaps as="div">For MSSPS</AllCaps>
        <h3>Simplified security alert management across clients</h3>
        <p>
          For MSSPs, Tines Workbench can be a game-changer in increasing
          operational efficiency, enabling your team, and ultimately boosting
          profit margins.
        </p>
      </RibbonHeader>
      <RibbonContent>
        <Bullets>
          <Bullet>
            <figure>
              <img src={WorkbenchMoneyChestIcon} />
            </figure>
            <p>
              <strong>Increase margins</strong> by responding to higher alert
              volumes without proportionally increasing staff and optimizing
              resource allocation across clients.
            </p>
          </Bullet>
          <Bullet>
            <figure>
              <img src={WorkbenchRainbowRoadsIcon} />
            </figure>
            <p>
              <strong>Enhance service quality</strong> with consistent
              SLA-compliant responses, update workflows centrally, and provide
              more personalized service through client-specific automation.
            </p>
          </Bullet>
          <Bullet>
            <figure>
              <img src={WorkbenchRainbowBridgeIcon} />
            </figure>
            <p>
              <strong>Enable your whole team</strong> to respond to alerts, with
              Workbench as a knowledgeable assistant, bridging experience gaps
              and ensuring accurate and efficient responses to alerts.
            </p>
          </Bullet>
        </Bullets>
      </RibbonContent>
    </Ribbon>
  );
};
