export const LogoSprinklr = () => (
  <svg width="122" height="27" viewBox="0 0 122 27" fill="currentColor">
    <title>Sprinklr</title>
    <path d="M3.30036 5.49985C3.40036 3.99985 4.70036 2.69985 6.30036 2.79985C6.80036 2.89985 7.30036 2.89985 8.10036 3.49985C11.8004 6.69985 14.0004 12.4998 13.4004 17.3998C13.3004 17.6998 13.1004 17.5998 13.0004 17.3998C12.0004 13.8998 8.60036 9.79985 5.50036 8.59985C4.00036 7.99985 3.20036 6.89985 3.30036 5.49985Z" />
    <path d="M29.7004 5.19993C29.7004 2.89993 27.8004 0.899934 25.1004 0.999934C19.9004 1.09993 12.8004 14.5999 16.9004 26.7999C17.0004 27.0999 17.2004 26.9999 17.2004 26.6999C17.7004 18.1999 22.9004 11.4999 27.4004 8.99993C29.2004 7.99993 29.7004 6.79993 29.7004 5.19993Z" />
    <path d="M1.00043 17.4999C1.10043 19.1999 2.50043 19.9999 3.90043 19.9999C6.90043 19.9999 10.6004 21.3999 12.8004 23.1999C13.0004 23.3999 13.0004 23.2999 12.9004 22.8999C12.0004 19.7999 7.20043 14.5999 3.80043 14.5999C2.00043 14.6999 0.900425 16.1999 1.00043 17.4999Z" />
    <path d="M33.1003 17.4999C33.0003 19.1999 31.6005 19.9999 30.2005 19.9999C27.2005 19.9999 23.5005 21.3999 21.3005 23.1999C21.1005 23.3999 21.1005 23.2999 21.2005 22.8999C22.1005 19.7999 26.9005 14.5999 30.3005 14.5999C32.2005 14.6999 33.2003 16.1999 33.1003 17.4999Z" />
    <path d="M40.4004 17.5999C41.1004 18.0999 42.5004 18.6999 43.9004 18.6999C45.1004 18.6999 46.0004 18.0999 46.0004 17.0999C46.0004 14.6999 39.8004 15.4999 39.8004 11.3999C39.8004 9.0999 41.8004 7.8999 44.2004 7.8999C45.9004 7.8999 46.8004 8.3999 48.0004 9.1999L47.0004 10.6999C45.9004 10.1999 45.3004 9.8999 44.1004 9.8999C42.9004 9.8999 42.0004 10.3999 42.0004 11.3999C42.0004 13.7999 48.2004 12.8999 48.2004 17.0999C48.2004 19.1999 46.5004 20.6999 43.8004 20.6999C41.8004 20.6999 40.3004 19.8999 39.4004 19.1999L40.4004 17.5999Z" />
    <path d="M56.8006 18.5999C58.7006 18.5999 60.3006 16.9999 60.3006 14.2999C60.3006 11.6999 58.9006 9.9999 56.9006 9.9999C55.1006 9.9999 53.4006 11.2999 53.4006 14.3999C53.3006 16.4999 54.6006 18.5999 56.8006 18.5999ZM51.1006 26.9999V8.1999H53.2006V9.5999C53.2006 9.9999 53.1006 10.1999 53.1006 10.1999H53.2006C53.2006 10.1999 54.3006 7.8999 57.3006 7.8999C60.6006 7.8999 62.6006 10.4999 62.6006 14.1999C62.6006 18.0999 60.3006 20.4999 57.1006 20.4999C54.4006 20.4999 53.3006 18.2999 53.3006 18.2999H53.2006C53.2006 18.2999 53.3006 18.6999 53.3006 19.3999V26.7999L51.1006 26.9999Z" />
    <path d="M72.0008 10.3999C69.6008 10.3999 67.6008 12.9999 67.6008 15.3999V20.4999H65.3008V8.19985H67.6008V10.3999C67.6008 10.8999 67.5008 11.2999 67.5008 11.2999H67.6008C68.0008 9.69985 70.2008 8.09985 72.0008 8.09985V10.3999Z" />
    <path d="M76.7004 8.19995H74.4004V20.4H76.7004V8.19995Z" />
    <path d="M88.7 20.4V13.1C88.7 11.4 88.4 10 86.4 10C84.1 10 82.3 12 82.3 14.5V20.4H80V8.20005H82.3V10C82.3 10.4 82.2 10.7 82.2 10.7H82.3C82.8 9.60005 84.3 7.80005 86.9 7.80005C89.8 7.80005 91.1 9.40005 91.1 12.4V20.2L88.7 20.4Z" />
    <path d="M102.4 20.4L98.8002 14.6H97.0002V20.4H94.7002V1.69995H97.0002V12.7H98.7002L102.1 8.29995H104.5L100.6 13.6V13.7L104.9 20.5H102.4V20.4Z" />
    <path d="M111.401 20.4C109.501 20.4 107.301 20.1 107.301 17V1.69995H109.601V16.8C109.601 18.3 110.801 18.3 111.301 18.4V20.4H111.401Z" />
    <path d="M121.001 10.2999C118.601 10.2999 116.601 12.8999 116.601 15.2999V20.3999H114.301V8.19985H116.601V10.3999C116.601 10.8999 116.501 11.2999 116.501 11.2999H116.601C117.101 9.69985 119.301 8.09985 121.101 8.09985L121.001 10.2999Z" />
    <path d="M77.0002 4.20005C77.0002 5.00005 76.4002 5.60005 75.6002 5.60005C74.8002 5.60005 74.2002 5.00005 74.2002 4.20005C74.2002 3.40005 74.8002 2.80005 75.6002 2.80005C76.4002 2.80005 77.0002 3.40005 77.0002 4.20005Z" />
  </svg>
);
