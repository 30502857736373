import { styled } from "@linaria/react";
import { colors } from "../../../styles/colors.styles";
import { withOpacity } from "../../../styles/colorsV4.styles";
import MultiplayerCursor, {
  MultiplayerCursorOuter,
} from "../../misc/MultiplayerCursor";
import FormulasCapsule, {
  FormulasCapsuleFace,
  FormulasCapsulePositioner,
  FormulasCapsuleShadow,
} from "./FormulasCapsule";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import gsap from "gsap";
import { fromTabletLg } from "../../../styles/breakpointsAndMediaQueries.styles";

const HeroCursorSet = (props: { dragBellFn: () => Promise<void> }) => {
  useOnMount(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      "#hero-cursor-1",
      { opacity: 0, x: 24, y: 24 },
      { opacity: 1, x: 0, y: 0 },
      0.6
    );
    tl.fromTo(
      "#hero-cursor-1-formulas-capsule",
      { rotate: -1 },
      { rotate: -7 },
      0.6
    );
    tl.fromTo(
      "#hero-cursor-2",
      { opacity: 0, x: 24, y: 24 },
      { opacity: 1, x: 0, y: 0 },
      0.5
    );
    tl.fromTo(
      "#hero-cursor-3",
      { opacity: 0, x: 24, y: 24 },
      { opacity: 1, x: 0, y: 0 },
      0.7
    );
    tl.to(
      "#hero-cursor-3",
      {
        scale: 0.8,
        duration: 0.1,
        onComplete: () => {
          props.dragBellFn();
        },
      },
      1.5
    );
    tl.to("#hero-cursor-3", { x: 22, y: 5, duration: 0.35 }, 1.5);
    tl.to("#hero-cursor-3", { scale: 1, duration: 0.1 }, 1.85);
  });
  return (
    <CursorSet>
      <Cursor1Positioner>
        <Cursor1WithFormulasCapsule id="hero-cursor-1">
          <FormulasCapsule
            id="hero-cursor-1-formulas-capsule"
            details={
              <>
                <p>
                  Shorten text down to the number of characters passed as a
                  parameter.
                </p>
                <p>
                  Syntax: <code>TRUNCATE(text, length, [ellipsis])</code>
                </p>
              </>
            }
          >
            TRUNCATE()
          </FormulasCapsule>
          <MultiplayerCursor color="orange" />
        </Cursor1WithFormulasCapsule>
      </Cursor1Positioner>
      <Cursor2Positioner>
        <MultiplayerCursor color="purple" id="hero-cursor-2" />
      </Cursor2Positioner>
      <Cursor3Positioner>
        <MultiplayerCursor color="green" id="hero-cursor-3" />
      </Cursor3Positioner>
    </CursorSet>
  );
};

const CursorSet = styled.div`
  ${MultiplayerCursorOuter} {
    filter: drop-shadow(1px 4px 0 ${withOpacity(colors.purple, 0.3)});
  }
`;

const Cursor1Positioner = styled.div`
  position: absolute;
  bottom: 9em;
  left: 75%;
  ${fromTabletLg} {
    bottom: 10em;
    left: 70%;
  }
  z-index: 5;
  ${FormulasCapsulePositioner} {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-60%, -80%) rotate(-7deg);
  }
  ${FormulasCapsuleFace} {
    background-color: ${colors.purple100};
  }
  ${FormulasCapsuleShadow} {
    background-color: ${colors.purple100};
  }
`;

const Cursor1WithFormulasCapsule = styled.div`
  opacity: 0;
`;

const Cursor2Positioner = styled.div`
  position: absolute;
  bottom: 5em;
  left: 5em;
  z-index: 5;
  transform: scaleX(-1);
  ${MultiplayerCursorOuter} {
    opacity: 0;
  }
`;
const Cursor3Positioner = styled.div`
  position: absolute;
  top: 3.25em;
  right: 2.5em;
  z-index: 5;
  ${MultiplayerCursorOuter} {
    opacity: 0;
  }
`;

export default HeroCursorSet;
