export const CardAGraphics = () => (
  <svg width="107" height="96" viewBox="0 0 107 96" fill="none">
    <rect width="49" height="8" rx="4" fill="url(#paint0_linear_379_6796)" />
    <rect y="12" width="105" height="19" rx="4" fill="#F3F3F3" />
    <rect
      y="42"
      width="49"
      height="8"
      rx="4"
      fill="url(#paint1_linear_379_6796)"
    />
    <rect opacity="0.06" y="54" width="68" height="19" rx="4" fill="#32313B" />
    <rect
      opacity="0.06"
      x="71"
      y="54"
      width="34"
      height="19"
      rx="4"
      fill="#32313B"
    />
    <rect
      y="84"
      width="49"
      height="8"
      rx="4"
      fill="url(#paint2_linear_379_6796)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_379_6796"
        x1="0"
        y1="8.00004"
        x2="39.2241"
        y2="8.00004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32313B" stopOpacity="0.27" />
        <stop offset="1" stopColor="#32313B" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_379_6796"
        x1="0"
        y1="50"
        x2="39.2241"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32313B" stopOpacity="0.27" />
        <stop offset="1" stopColor="#32313B" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_379_6796"
        x1="0"
        y1="92"
        x2="39.2241"
        y2="92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32313B" stopOpacity="0.27" />
        <stop offset="1" stopColor="#32313B" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export const CardBGraphics = () => (
  <svg
    width="107"
    height="96"
    viewBox="0 0 107 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="49" height="8" rx="4" fill="url(#paint0_linear_706_11851)" />
    <rect y="12" width="105" height="44" rx="4" fill="#F3F3F3" />
    <rect
      y="67"
      width="49"
      height="8"
      rx="4"
      fill="url(#paint1_linear_706_11851)"
    />
    <rect opacity="0.06" y="79" width="68" height="19" rx="4" fill="#32313B" />
    <rect
      opacity="0.06"
      x="71"
      y="79"
      width="34"
      height="19"
      rx="4"
      fill="#32313B"
    />
    <defs>
      <linearGradient
        id="paint0_linear_706_11851"
        x1="0"
        y1="8.00004"
        x2="39.2241"
        y2="8.00004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32313B" stopOpacity="0.27" />
        <stop offset="1" stopColor="#32313B" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_706_11851"
        x1="0"
        y1="75"
        x2="39.2241"
        y2="75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32313B" stopOpacity="0.27" />
        <stop offset="1" stopColor="#32313B" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export const CardCGraphics = () => (
  <svg width="103" height="65" viewBox="0 0 103 65" fill="none">
    <path
      d="M42.5 29.2174L51.2826 38L71.2826 18"
      stroke="#45BE8B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68 32.5C68 41.6127 60.6127 49 51.5 49C42.3873 49 35 41.6127 35 32.5C35 23.3873 42.3873 16 51.5 16C57.9016 16 63.4516 19.6455 66.1875 24.9738L68.3986 22.7627C65.0288 16.9271 58.723 13 51.5 13C40.7304 13 32 21.7304 32 32.5C32 43.2696 40.7304 52 51.5 52C62.2696 52 71 43.2696 71 32.5C71 30.087 70.5617 27.7764 69.7605 25.6435L67.3844 28.0195C67.7855 29.4442 68 30.9471 68 32.5Z"
      fill="#45BE8B"
    />
  </svg>
);
