import { MutableRefObject, useRef, useState } from "react";
import gsap from "gsap";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { styled } from "@linaria/react";
import {
  PrimaryColorName,
  PrimaryColorNames,
  color,
  colors,
  withOpacity,
} from "../../../styles/colors.styles";

const EscherWindowSvg = styled.svg`
  cursor: pointer;
  transition: transform 0.1s;
  .starsGroup {
    transition: transform 0.1s;
    transform-origin: 48.5px 60px;
  }
  &:active {
    transform: scale(0.95);
    .starsGroup {
      transform: scale(1.05);
    }
  }
`;

export const EscherWindow = () => {
  const chatBubble1Ref = useRef<SVGGElement>(null);
  const chatBubble2Ref = useRef<SVGGElement>(null);

  const [chatBubble1Color, setChatBubble1Color] =
    useState<PrimaryColorName>("pink");
  const [chatBubble2Color, setChatBubble2Color] =
    useState<PrimaryColorName>("purple");
  const chatBubble1ColorRef = useRef(chatBubble1Color);
  chatBubble1ColorRef.current = chatBubble1Color;
  const chatBubble2ColorRef = useRef(chatBubble2Color);
  chatBubble2ColorRef.current = chatBubble2Color;

  const tlRef = useRef<GSAPTimeline>(
    null
  ) as unknown as MutableRefObject<GSAPTimeline>;

  const handleClick = () => {
    const progress = tlRef.current.progress();
    if (progress === 1) tlRef.current.play(0);
    else tlRef.current.play();
  };

  useOnMount(() => {
    tlRef.current = gsap.timeline({ paused: true });
    tlRef.current.set(chatBubble1Ref.current, { opacity: 1, y: 0 }, 0);
    tlRef.current.set(chatBubble2Ref.current, { opacity: 1, y: 0 }, 0);
    const increaseCommentCounter = () => {
      const span = document.getElementById("hero-case-card-comment-span");
      const counter = document.getElementById("hero-case-card-comment-counter");
      if (counter) {
        const currentCount = parseInt(`${counter.innerText}`) ?? 2;
        counter.innerText = `${currentCount + 1}`;
        gsap.fromTo(
          span,
          {
            color: colors.pink400,
          },
          {
            color: withOpacity(colors.purple800, 0.5),
            duration: 2,
          }
        );
      }
    };
    tlRef.current.to(
      chatBubble1Ref.current,
      {
        y: -9,
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          increaseCommentCounter();
          setChatBubble1Color(
            PrimaryColorNames[
              (PrimaryColorNames.indexOf(chatBubble1ColorRef.current) + 1) %
                PrimaryColorNames.length
            ]
          );
        },
      },
      0
    );
    tlRef.current.to(
      chatBubble2Ref.current,
      {
        y: -9,
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          increaseCommentCounter();
          setChatBubble2Color(
            PrimaryColorNames[
              (PrimaryColorNames.indexOf(chatBubble2ColorRef.current) + 1) %
                PrimaryColorNames.length
            ]
          );
        },
      },
      0.05
    );
    tlRef.current.fromTo(
      chatBubble1Ref.current,
      {
        y: 9,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.25,
        ease: "back",
      },
      0.2
    );
    tlRef.current.fromTo(
      chatBubble2Ref.current,
      {
        y: 9,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.25,
        ease: "back",
      },
      0.25
    );
    tlRef.current.seek(0.001);
    return () => {
      tlRef.current.kill();
    };
  });
  return (
    <EscherWindowSvg
      width="97"
      height="120"
      viewBox="0 0 97 120"
      fill="none"
      onClick={handleClick}
    >
      <rect
        x="89.8613"
        y="112.861"
        width="78.2749"
        height="105.39"
        transform="rotate(180 89.8613 112.861)"
        fill="#32274B"
      />
      <g ref={chatBubble2Ref}>
        <mask id="path-2-inside-1_1327_41119" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1229 75C15.3982 75 14 76.3982 14 78.1229V86.954C14 88.6788 15.3982 90.0769 17.1229 90.0769H26.9996V95.0926L32.0154 90.0769H46.6529C48.3777 90.0769 49.7759 88.6788 49.7759 86.954V78.1229C49.7759 76.3982 48.3777 75 46.6529 75H17.1229Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1229 75C15.3982 75 14 76.3982 14 78.1229V86.954C14 88.6788 15.3982 90.0769 17.1229 90.0769H26.9996V95.0926L32.0154 90.0769H46.6529C48.3777 90.0769 49.7759 88.6788 49.7759 86.954V78.1229C49.7759 76.3982 48.3777 75 46.6529 75H17.1229Z"
          fill={color(chatBubble2Color, 200)}
        />
        <path
          d="M26.9996 90.0769H27.9996V89.0769H26.9996V90.0769ZM26.9996 95.0926H25.9996V97.5067L27.7067 95.7997L26.9996 95.0926ZM32.0154 90.0769V89.0769H31.6012L31.3083 89.3698L32.0154 90.0769ZM15 78.1229C15 76.9505 15.9505 76 17.1229 76V74C14.8459 74 13 75.8459 13 78.1229H15ZM15 86.954V78.1229H13V86.954H15ZM17.1229 89.0769C15.9505 89.0769 15 88.1265 15 86.954H13C13 89.231 14.8459 91.0769 17.1229 91.0769V89.0769ZM26.9996 89.0769H17.1229V91.0769H26.9996V89.0769ZM27.9996 95.0926V90.0769H25.9996V95.0926H27.9996ZM31.3083 89.3698L26.2925 94.3855L27.7067 95.7997L32.7225 90.7841L31.3083 89.3698ZM46.6529 89.0769H32.0154V91.0769H46.6529V89.0769ZM48.7759 86.954C48.7759 88.1265 47.8254 89.0769 46.6529 89.0769V91.0769C48.93 91.0769 50.7759 89.231 50.7759 86.954H48.7759ZM48.7759 78.1229V86.954H50.7759V78.1229H48.7759ZM46.6529 76C47.8254 76 48.7759 76.9505 48.7759 78.1229H50.7759C50.7759 75.8459 48.93 74 46.6529 74V76ZM17.1229 76H46.6529V74H17.1229V76Z"
          fill={color(chatBubble2Color, 600)}
          mask="url(#path-2-inside-1_1327_41119)"
        />
      </g>
      <g className="starsGroup">
        <path
          d="M37.8038 29.6584C37.7432 29.4426 37.5465 29.2935 37.3224 29.2935C37.0983 29.2935 36.9016 29.4426 36.841 29.6584L35.7577 33.5171L31.899 34.6003C31.6833 34.6609 31.5342 34.8576 31.5342 35.0817C31.5342 35.3058 31.6833 35.5026 31.899 35.5631L35.7577 36.6464L36.841 40.5051C36.9016 40.7208 37.0983 40.8699 37.3224 40.8699C37.5465 40.8699 37.7432 40.7208 37.8038 40.5051L38.8871 36.6464L42.7457 35.5631C42.9615 35.5026 43.1106 35.3058 43.1106 35.0817C43.1106 34.8576 42.9615 34.6609 42.7457 34.6003L38.8871 33.5171L37.8038 29.6584Z"
          fill="#FFC8A3"
          stroke="#222027"
          strokeLinejoin="round"
        />
        <path
          d="M64.4089 85.3641C64.3335 85.1731 64.1491 85.0476 63.9437 85.0476C63.7384 85.0476 63.5539 85.1731 63.4786 85.3641L62.6378 87.4959L60.506 88.3367C60.315 88.4121 60.1895 88.5965 60.1895 88.8019C60.1895 89.0072 60.315 89.1917 60.506 89.267L62.6378 90.1078L63.4786 92.2396C63.5539 92.4306 63.7384 92.5561 63.9437 92.5561C64.1491 92.5561 64.3335 92.4306 64.4089 92.2396L65.2496 90.1078L67.3815 89.267C67.5725 89.1917 67.698 89.0072 67.698 88.8019C67.698 88.5965 67.5725 88.4121 67.3815 88.3367L65.2496 87.4959L64.4089 85.3641Z"
          fill="#B0E0BD"
          stroke="#222027"
          strokeLinejoin="round"
        />
        <circle cx="54.2204" cy="40.2204" r="1.22035" fill="#FCF9F5" />
        <circle cx="59.2204" cy="51.2204" r="1.22035" fill="#FCF9F5" />
        <circle cx="46.2204" cy="65.2204" r="1.22035" fill="#FCF9F5" />
      </g>
      <path
        d="M23.3525 100.582L14.1437 109.791V19.2378H23.3525V100.582Z"
        fill="#CB5D94"
        stroke="#763359"
        strokeLinejoin="bevel"
      />
      <path
        d="M23.3525 26.4003L14.1437 35.6091V19.2378H23.3525V26.4003Z"
        fill="#763359"
        stroke="#763359"
        strokeLinejoin="bevel"
      />
      <path
        d="M77.582 19.2379L86.7908 10.0291V100.582H77.582V19.2379Z"
        fill="#CB5D94"
        stroke="#763359"
        strokeLinejoin="bevel"
      />
      <path
        d="M87 86H78V19L87 10V86Z"
        fill="#763359"
        stroke="#763359"
        strokeLinejoin="bevel"
      />
      <path
        d="M77.5824 19.2378H14.1439V109.791H96L86.7912 119H4.93507V10.029H86.7912L77.5824 19.2378Z"
        fill="#FFBFDB"
        stroke="#763359"
        strokeLinejoin="bevel"
      />
      <path
        d="M23.3527 100.582H86.7912V10.029H4.93507L14.1439 0.820206H96V109.791H14.1439L23.3527 100.582Z"
        fill="#F486B8"
        stroke="#763359"
        strokeLinejoin="bevel"
      />
      <g ref={chatBubble1Ref}>
        <rect
          x="5"
          y="56"
          width="9"
          height="6"
          fill={color(chatBubble1Color, 800)}
        />
        <mask id="path-16-inside-2_1327_41119" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6525 44C34.3772 44 35.7754 45.3982 35.7754 47.1229V55.954C35.7754 57.6788 34.3772 59.0769 32.6525 59.0769H30.6352V64.0926L25.6193 59.0769H3.12245C1.3977 59.0769 -0.000484467 57.6788 -0.000484467 55.954V47.1229C-0.000484467 45.3982 1.3977 44 3.12245 44H32.6525Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6525 44C34.3772 44 35.7754 45.3982 35.7754 47.1229V55.954C35.7754 57.6788 34.3772 59.0769 32.6525 59.0769H30.6352V64.0926L25.6193 59.0769H3.12245C1.3977 59.0769 -0.000484467 57.6788 -0.000484467 55.954V47.1229C-0.000484467 45.3982 1.3977 44 3.12245 44H32.6525Z"
          fill={color(chatBubble1Color, 200)}
        />
        <path
          d="M30.6352 59.0769H29.6352V58.0769H30.6352V59.0769ZM30.6352 64.0926H31.6352V66.5067L29.9281 64.7997L30.6352 64.0926ZM25.6193 59.0769V58.0769H26.0335L26.3264 58.3698L25.6193 59.0769ZM34.7754 47.1229C34.7754 45.9505 33.8249 45 32.6525 45V43C34.9295 43 36.7754 44.8459 36.7754 47.1229H34.7754ZM34.7754 55.954V47.1229H36.7754V55.954H34.7754ZM32.6525 58.0769C33.8249 58.0769 34.7754 57.1265 34.7754 55.954H36.7754C36.7754 58.231 34.9295 60.0769 32.6525 60.0769V58.0769ZM30.6352 58.0769H32.6525V60.0769H30.6352V58.0769ZM29.6352 64.0926V59.0769H31.6352V64.0926H29.6352ZM26.3264 58.3698L31.3423 63.3855L29.9281 64.7997L24.9122 59.7841L26.3264 58.3698ZM3.12245 58.0769H25.6193V60.0769H3.12245V58.0769ZM0.999516 55.954C0.999516 57.1265 1.94999 58.0769 3.12245 58.0769V60.0769C0.845415 60.0769 -1.00048 58.231 -1.00048 55.954H0.999516ZM0.999516 47.1229V55.954H-1.00048V47.1229H0.999516ZM3.12245 45C1.94998 45 0.999516 45.9505 0.999516 47.1229H-1.00048C-1.00048 44.8459 0.845412 43 3.12245 43V45ZM32.6525 45H3.12245V43H32.6525V45Z"
          fill="#4D3E78"
          mask="url(#path-16-inside-2_1327_41119)"
        />
      </g>
    </EscherWindowSvg>
  );
};
