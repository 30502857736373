import { styled } from "@linaria/react";
import {
  PropsWithChildren,
  ReactNode,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { LibraryTwoToneIcon } from "../icons/twoTone/LibraryTwoToneIcon";
import { css, cx } from "linaria";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import { withOpacity } from "../../styles/colorsV4.styles";
import { FlagTwoToneIcon } from "../icons/twoTone/FlagTwoToneIcon";
import { MagicWandTwoToneIcon } from "../icons/twoTone/MagicWandTwoToneIcon";
import { FolderTwoToneIcon } from "../icons/twoTone/FolderTwoToneIcon";
import {
  DatoCmsLibraryCollection,
  LibrarySidebarQuery,
} from "../../../graphql-types";
import { rSize } from "../../styles/responsiveSizes.styles";
import LibraryStoryRequestPromptCard from "./LibraryStoryRequestPromptCard";
import {
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { LibraryState } from "./WithLibraryState";
import GenericSearchBar, {
  GenericSearchBarContainer,
} from "../search/GenericSearchBar";
import { darkModeLinariaCSS } from "../../utils/colorScheme.utils";
import { TextInputStyled } from "../forms/TextInput";
import { hideScrollbarsCSS } from "../../utils/scroll.utils";
import { colors } from "../../styles/colors.styles";
import { useSiteContext } from "../../context/site.context";
import { zIndex } from "../../styles/zIndexes.styles";
import { Paths } from "../../utils/pathBuilders.utils";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { when } from "../../utils/promises.utils";
import { CommunityTwoToneIcon } from "../icons/twoTone/CommunityTwoToneIcon";
import { JigsawPieceTwoToneIcon } from "../icons/twoTone/JigsawPieceTwoToneIcon";

const LibrarySidebarAside = styled.aside`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  a {
    text-decoration: none;
  }
  position: sticky;
  top: calc(var(--pageTopFixedElementsHeight, 0px));
  ${uptoTablet} {
    z-index: ${zIndex("PageSidebar")};
  }
  ${fromTablet} {
    overflow: auto;
    top: calc(var(--pageTopFixedElementsHeight, 0px) + 2em);
    height: calc(
      100vh - var(--pageTopFixedElementsHeight, 0px) - ${rSize("gap")}
    );
    transition: top 0.25s, height 0.25s;
    margin-left: -0.75em;
    padding-left: 0.75em;
    /* padding-right: 0.75em; */
    padding-bottom: ${rSize("lg")};
  }
  ${GenericSearchBarContainer} {
    font-size: 1.6rem;
    ${fromTablet} {
      font-size: 1.4rem;
    }
  }
  ${TextInputStyled} {
    border: 2px solid transparent;
    background-color: ${withOpacity(colors.light300, 0.4)};
    border-radius: 0.75em;
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark500, 0.8)};`
    )}
    &:hover {
      border-color: ${colors.purple};
    }
  }
  [opacity="0.1"],
  [opacity="0.2"] {
    ${darkModeLinariaCSS(`opacity: 0;`)}
  }
`;

const NavContent = styled.div`
  overflow: auto;
`;

const TopBar = styled.div`
  ${uptoTablet} {
    display: grid;
    grid-gap: 0.5em;
    align-items: center;
    grid-template-columns: auto minmax(0, 1fr);
    backdrop-filter: blur(1em);
    padding: 0.5em;
    background-color: ${withOpacity(colors.lightest, 0.75)};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark700, 0.9)};`
    )}
  }
  ${fromTablet} {
    margin-bottom: 0.5em;
  }
  ${GenericSearchBarContainer} {
    flex: 1 1 100%;
  }
`;

const MobileMenuToggle = styled.label`
  appearance: none;
  background-color: transparent;
  color: inherit;
  padding: 0.5em;
  border: 0;
  flex: 0 0 auto;
  svg {
    display: block;
  }
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fromTablet} {
    display: none;
  }
`;

const NavContentList = styled.div`
  ${uptoTablet} {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    backdrop-filter: blur(1em);
    background-color: ${withOpacity(colors.lightest, 0.9)};
    ${darkModeLinariaCSS(
      `background-color: ${withOpacity(colors.dark700, 0.9)};`
    )}
    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    padding-bottom: 0.5em;
    &:after {
      content: "";
      height: 2em;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0)
      );
      transition: opacity 0.5s;
    }
  }
`;

const MobileMenuCheckboxInput = styled.input`
  position: absolute;
  left: -24px;
  opacity: 0;
  ${uptoTablet} {
    &:not(:checked) ~ ${NavContentList} {
      transform: translateX(-100%);
      &:after {
        opacity: 0;
      }
    }
    &:checked ~ ${NavContentList} {
      transform: translateX(0);
      &:after {
        opacity: 1;
      }
    }
  }
`;

const NavFooter = styled.div`
  ${uptoTablet} {
    display: none;
  }
`;

const LibrarySidebar = (props: { state: LibraryState }) => {
  const siteContext = useSiteContext();
  const { location } = siteContext;
  const librarySidebarQueryResult: LibrarySidebarQuery =
    useStaticQuery(librarySidebarQuery);
  const teams = librarySidebarQueryResult.collections.nodes.filter(
    c => c.collectionType === "Team"
  ) as DatoCmsLibraryCollection[];
  const useCases = librarySidebarQueryResult.collections.nodes.filter(
    c => c.collectionType === "Use case"
  ) as DatoCmsLibraryCollection[];
  const productFeatures = librarySidebarQueryResult.collections.nodes.filter(
    c => c.collectionType === "Product feature"
  ) as DatoCmsLibraryCollection[];
  const presentationalFormState = useState({
    query: "",
  });
  const handleSearchBarEnter = (query: string) => {
    (
      document.documentElement.querySelector(
        "#library-search-bar"
      ) as HTMLInputElement
    )?.blur();
    if (location.pathname !== Paths.library.stories()) {
      navigate(Paths.library.stories());
    }
    when(
      () =>
        !!(
          window.location.pathname.replace(/\/$/, "") ===
            Paths.library.stories() &&
          document.documentElement.querySelector("#library-search-bar")
        ),
      () => {
        if (window.innerWidth < 640) {
          scrollToHash({
            useHash: "#find-and-filter",
            doNotPushState: true,
            offsetY:
              ((document.getElementById("nav-bar")?.clientHeight ?? 0) +
                (document.getElementById("library-sidebar-nav-top-bar")
                  ?.clientHeight ?? 0)) *
              -1,
          });
        }
        siteContext.library.setQuery(query);
        presentationalFormState[1]({ query: "" });
      }
    );
  };
  return (
    <LibrarySidebarAside>
      <NavContent className={hideScrollbarsCSS}>
        <MobileMenuCheckboxInput
          id="library-sidebar-nav-toggle"
          type="checkbox"
        />
        <TopBar id="library-sidebar-nav-top-bar">
          <MobileMenuToggle htmlFor="library-sidebar-nav-toggle">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="currentColor">
              <rect x="2" y="3" width="14" height="1" rx="0.5" />
              <rect x="2" y="8" width="14" height="1" rx="0.5" />
              <rect x="2" y="13" width="14" height="1" rx="0.5" />
            </svg>
          </MobileMenuToggle>
          <GenericSearchBar
            id="library-search-bar"
            formState={presentationalFormState}
            field="query"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onEnter={handleSearchBarEnter}
            icon="left"
            placeholder="Find a story…"
          />
        </TopBar>
        <NavContentList>
          <SidebarItem
            icon={<LibraryTwoToneIcon />}
            label="Home"
            to="/library"
          />
          {teams.length > 0 && (
            <SidebarItem
              icon={<FlagTwoToneIcon />}
              label="By team"
              children={<Submenu collections={teams} />}
            />
          )}
          {useCases.length > 0 && (
            <SidebarItem
              icon={<MagicWandTwoToneIcon />}
              label="By use case"
              children={<Submenu collections={useCases} />}
            />
          )}
          {productFeatures.length > 0 && (
            <SidebarItem
              icon={<JigsawPieceTwoToneIcon />}
              label="By feature"
              children={<Submenu collections={productFeatures} />}
            />
          )}
          <SidebarItem
            icon={<CommunityTwoToneIcon />}
            label="Community"
            to="/library/community"
          />
          <SidebarItem
            icon={<FolderTwoToneIcon />}
            label="Directory"
            to="/library/stories"
          >
            <Link to="/library/stories">Stories</Link>
            <Link
              to="/library/tools"
              className={cx(
                location.pathname.match(/^\/library\/tools\//) && "active"
              )}
            >
              Tools
            </Link>
          </SidebarItem>
        </NavContentList>
      </NavContent>
      <NavFooter>
        <LibraryStoryRequestPromptCard />
      </NavFooter>
    </LibrarySidebarAside>
  );
};

const SidebarItemCss = css`
  display: block;
  summary {
    &::-webkit-details-marker {
      display: none;
    }
  }
  &:has(a[aria-current="page"], a.active) {
    summary,
    header {
      color: ${colors.purple};
    }
  }
  summary,
  header {
    cursor: pointer;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    strong {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      font-weight: 500;
      > * {
        + * {
          margin-left: 0.5em;
        }
      }
    }
    > a,
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1 1 100%;
      border-radius: 0.75em;
      padding: 1.25em 1em;
      ${fromTablet} {
        padding: 0.825em 1em;
      }
      .chevron {
        opacity: 0.3;
      }
      &:hover {
        ${fromTablet} {
          color: ${colors.purple};
          background-color: ${withOpacity(colors.purple, 0.1)};
          .chevron {
            opacity: 0.8;
          }
        }
      }
    }
  }
  a {
    &[aria-current="page"],
    &.active {
      color: ${colors.purple};
    }
  }
`;

const SidebarItemChildren = styled.div`
  padding: 0 0 0.5em 2.85rem;
  font-size: 1.4rem;
  font-weight: 500;
  a {
    padding: 1em 0.75em;
    ${fromTablet} {
      padding: 0.75em;
    }
    border-radius: 0.5em;
    display: block;
    &:hover {
      &:hover {
        color: ${colors.purple};
        background-color: ${withOpacity(colors.purple, 0.1)};
      }
    }
  }
`;

const SidebarItem = (
  props: PropsWithChildren<{ icon: ReactNode; label: string; to?: string }>
) => {
  const ref = useRef<HTMLDetailsElement | HTMLDivElement>(null);
  const headerContent = (
    <>
      <strong>
        {props.icon}
        <span>{props.label}</span>
      </strong>
      {props.children && (
        <svg
          className="chevron"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
        >
          <path d="M1 1L4 4L7 1" stroke="currentColor" />
        </svg>
      )}
    </>
  );
  useEffect(() => {
    if (ref.current?.nodeName === "DETAILS") {
      if (ref.current.querySelector(`[aria-current="page"], .active`)) {
        (ref.current as HTMLDetailsElement).open = true;
      }
    }
  });
  return createElement(props.children ? "details" : "div", {
    className: SidebarItemCss,
    ref,
    children: (
      <>
        {createElement(props.children ? "summary" : "header", {
          children: props.to ? (
            <Link to={props.to}>{headerContent}</Link>
          ) : (
            <span>{headerContent}</span>
          ),
        })}
        {props.children && (
          <SidebarItemChildren>{props.children}</SidebarItemChildren>
        )}
      </>
    ),
  });
};

const Submenu = (
  props: PropsWithChildren<{ collections: DatoCmsLibraryCollection[] }>
) => {
  return (
    <div>
      {props.children}
      {props.collections.map(c => (
        <Link
          to={Paths.library.collection(c.collectionType, c.slug)}
          key={c.id}
        >
          {c.name}
        </Link>
      ))}
    </div>
  );
};

export const librarySidebarQuery = graphql`
  query LibrarySidebar {
    collections: allDatoCmsLibraryCollection(sort: { position: ASC }) {
      nodes {
        id: originalId
        slug
        position
        collectionType
        name
      }
    }
  }
`;

export default LibrarySidebar;
