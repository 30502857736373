export const SOC2023HeroIllustrationHeaven = () => (
  <svg
    width="917"
    height="500"
    viewBox="0 0 917 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="482" cy="47" r="435" fill="url(#paint0_linear_165_32210)" />
    <path
      d="M172.225 313C168.578 313 165.319 314.632 163.116 317.197C160.914 314.632 157.654 313 154.008 313C147.376 313 142 318.376 142 325.008C142 331.638 147.376 337.014 154.008 337.014C157.656 337.014 168.58 337.014 172.225 337.014C178.858 337.014 184.233 331.637 184.233 325.008C184.233 318.375 178.858 313 172.225 313Z"
      fill="#F3ECF7"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M465.5 -387.693C232.902 -379.019 47 -187.719 47 47C47 281.719 232.902 473.019 465.5 481.693C460.025 481.897 454.525 482 449 482C208.756 482 14 287.244 14 47C14 -193.244 208.756 -388 449 -388C454.525 -388 460.025 -387.897 465.5 -387.693Z"
      fill="#D7C4FA"
    />
    <path
      d="M465.5 -387.693L465.523 -387.068C465.859 -387.081 466.125 -387.357 466.125 -387.693C466.125 -388.029 465.859 -388.305 465.523 -388.317L465.5 -387.693ZM465.5 481.693L465.523 482.317C465.859 482.305 466.125 482.029 466.125 481.693C466.125 481.357 465.859 481.081 465.523 481.068L465.5 481.693ZM47.625 47C47.625 -187.382 233.26 -378.407 465.523 -387.068L465.477 -388.317C232.544 -379.631 46.375 -188.057 46.375 47H47.625ZM465.523 481.068C233.26 472.407 47.625 281.382 47.625 47H46.375C46.375 282.057 232.544 473.631 465.477 482.317L465.523 481.068ZM465.477 481.068C460.01 481.272 454.517 481.375 449 481.375V482.625C454.532 482.625 460.041 482.522 465.523 482.317L465.477 481.068ZM449 481.375C209.101 481.375 14.625 286.899 14.625 47H13.375C13.375 287.589 208.411 482.625 449 482.625V481.375ZM14.625 47C14.625 -192.899 209.101 -387.375 449 -387.375V-388.625C208.411 -388.625 13.375 -193.589 13.375 47H14.625ZM449 -387.375C454.517 -387.375 460.01 -387.272 465.477 -387.068L465.523 -388.317C460.041 -388.522 454.532 -388.625 449 -388.625V-387.375Z"
      fill="#6C58B0"
    />
    <path
      d="M531.435 433C536.405 433 540.435 428.971 540.435 424C540.435 419.029 536.405 415 531.435 415C526.464 415 522.435 419.029 522.435 424C522.435 428.971 526.464 433 531.435 433Z"
      fill="#FCF9F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M245.573 199.814C247.413 199.814 249.126 200.334 250.593 201.224C252.527 196.359 257.266 192.914 262.821 192.914C270.091 192.914 275.983 198.808 275.983 206.077C275.983 213.346 270.09 219.239 262.821 219.239C259.124 219.239 248.79 219.239 245.573 219.239C240.208 219.239 235.86 214.89 235.86 209.527C235.86 204.163 240.208 199.814 245.573 199.814Z"
      fill="#FCF9F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M786.83 357.328C769.617 374.239 751.003 389.728 731.178 403.606C718.766 398.662 710 386.598 710 372.5C710 354 725.097 339 743.719 339C756.491 339 767.603 346.058 773.325 356.458C774.997 356.112 776.729 355.929 778.504 355.929C781.422 355.929 784.224 356.422 786.83 357.328Z"
      fill="#FCF9F5"
    />
    <path
      d="M786.83 357.328L787.268 357.774C787.422 357.623 787.487 357.402 787.44 357.191C787.393 356.98 787.24 356.809 787.036 356.738L786.83 357.328ZM731.178 403.606L730.947 404.187C731.142 404.265 731.364 404.239 731.536 404.118L731.178 403.606ZM773.325 356.458L772.778 356.759C772.91 356.999 773.183 357.125 773.452 357.07L773.325 356.458ZM786.392 356.882C769.203 373.769 750.616 389.236 730.819 403.094L731.536 404.118C751.389 390.22 770.03 374.709 787.268 357.774L786.392 356.882ZM709.375 372.5C709.375 386.865 718.307 399.152 730.947 404.187L731.409 403.026C719.225 398.172 710.625 386.332 710.625 372.5H709.375ZM743.719 338.375C724.756 338.375 709.375 353.651 709.375 372.5H710.625C710.625 354.349 725.438 339.625 743.719 339.625V338.375ZM773.873 356.156C768.045 345.564 756.727 338.375 743.719 338.375V339.625C756.255 339.625 767.162 346.552 772.778 356.759L773.873 356.156ZM778.504 355.304C776.686 355.304 774.911 355.491 773.199 355.846L773.452 357.07C775.082 356.732 776.772 356.554 778.504 356.554V355.304ZM787.036 356.738C784.364 355.809 781.493 355.304 778.504 355.304V356.554C781.351 356.554 784.084 357.035 786.625 357.918L787.036 356.738Z"
      fill="#6C58B0"
    />
    <path
      d="M395.722 321.863C389.274 321.863 383.266 323.741 378.204 326.968C373.142 323.741 367.136 321.863 360.688 321.863C354.239 321.863 348.233 323.741 343.17 326.968C338.109 323.741 332.1 321.863 325.653 321.863C307.624 321.863 293.006 336.48 293.006 354.51C293.006 372.541 307.624 387.157 325.653 387.157C332.1 387.157 338.109 385.277 343.17 382.054C348.233 385.277 354.239 387.157 360.688 387.157C367.136 387.157 373.142 385.277 378.204 382.054C383.266 385.277 389.274 387.157 395.722 387.157C413.751 387.157 428.368 372.541 428.368 354.51C428.368 336.48 413.751 321.863 395.722 321.863Z"
      fill="#F3ECF7"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M455.388 315.348C451.967 315.348 448.785 316.317 446.059 317.967C442.468 308.926 433.659 302.528 423.341 302.528C409.835 302.528 398.885 313.478 398.885 326.984C398.885 340.491 409.835 351.441 423.341 351.441C430.207 351.441 449.411 351.441 455.388 351.441C465.355 351.441 473.435 343.361 473.435 333.394C473.435 323.427 465.355 315.348 455.388 315.348Z"
      fill="#FCF9F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M413.193 349.549C413.193 349.549 418.255 358.993 427.362 358.993L428.374 350.898C428.374 350.898 418.591 351.91 413.193 349.549Z"
      fill="#6C58B0"
    />
    <path
      d="M353.9 304.295C348.359 327.967 351.986 349.059 362 351.403C372.016 353.748 384.627 336.457 390.167 312.782C395.709 289.113 392.081 268.021 382.067 265.677C372.052 263.332 359.44 280.623 353.9 304.295ZM362.582 306.327C366.639 288.998 374.158 275.942 379.376 277.165C384.597 278.388 385.539 293.425 381.485 310.751C377.428 328.08 369.911 341.135 364.691 339.913C359.472 338.692 358.528 323.656 362.582 306.327Z"
      fill="#A990F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M345.637 302.361C340.095 326.033 343.723 347.124 353.737 349.469C355.453 349.873 361.91 351.384 363.628 351.786C373.644 354.129 386.254 336.838 391.797 313.166C397.337 289.494 393.711 268.403 383.694 266.058C381.978 265.656 375.52 264.144 373.802 263.743C363.79 261.398 351.177 278.688 345.637 302.361ZM354.319 304.392C358.377 287.066 365.895 274.01 371.112 275.23C376.333 276.453 377.277 291.489 373.222 308.816C369.167 326.145 361.649 339.201 356.428 337.978C351.209 336.756 350.263 321.721 354.319 304.392Z"
      fill="#A990F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M345.637 302.361C340.095 326.033 343.723 347.124 353.737 349.469C363.751 351.815 376.364 334.523 381.904 310.851C387.444 287.179 383.818 266.089 373.802 263.743C363.79 261.398 351.177 278.688 345.637 302.361ZM354.319 304.392C358.377 287.066 365.895 274.01 371.112 275.23C376.333 276.453 377.277 291.489 373.222 308.816C369.167 326.145 361.649 339.201 356.428 337.978C351.209 336.756 350.263 321.721 354.319 304.392Z"
      fill="#C2AAFA"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M368.943 289.095L361.371 285.782C361.371 285.782 368.202 270.689 373.869 278.384L368.943 289.095Z"
      fill="#6C58B0"
    />
    <path
      d="M336.934 357.766C336.934 345.308 347.031 335.211 359.487 335.211C371.943 335.211 382.043 345.308 382.043 357.766"
      fill="#F3ECF7"
    />
    <path
      d="M336.934 357.766C336.934 345.308 347.031 335.211 359.487 335.211C371.943 335.211 382.043 345.308 382.043 357.766"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M368.286 324.187C370.17 319.761 371.886 314.53 373.224 308.816C373.578 307.306 373.88 305.822 374.157 304.353L372.524 298.144L355.239 293.432L342.517 306.042L347.074 323.366L364.359 328.081L368.286 324.187Z"
      fill="#FDA2CB"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M351.946 325.194V327.393L360.485 331.791V327.264L351.946 325.194Z"
      fill="#6C58B0"
    />
    <path
      d="M319.884 281.118C319.884 282.928 316.615 284.077 315.962 285.651C315.284 287.284 316.759 290.401 315.528 291.631C314.299 292.862 311.181 291.387 309.549 292.063C307.974 292.716 306.826 295.985 305.015 295.985C303.205 295.985 302.057 292.716 300.482 292.062C298.85 291.386 295.731 292.86 294.502 291.631C293.273 290.4 294.747 287.284 294.071 285.651C293.418 284.077 290.149 282.928 290.149 281.118C290.149 279.308 293.418 278.159 294.071 276.585C294.747 274.952 293.274 271.834 294.502 270.605C295.733 269.376 298.85 270.85 300.482 270.172C302.057 269.519 303.205 266.25 305.015 266.25C306.826 266.25 307.974 269.519 309.548 270.172C311.181 270.85 314.3 269.376 315.529 270.605C316.759 271.836 315.284 274.952 315.962 276.585C316.615 278.159 319.884 279.308 319.884 281.118Z"
      fill="#FFD29F"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M179.56 123.487C180.748 124.859 180.601 126.933 179.23 128.123L173.436 133.145C172.066 134.333 169.989 134.186 168.801 132.814L163.779 127.02C162.59 125.651 162.738 123.575 164.109 122.385L169.901 117.364C171.272 116.174 173.348 116.324 174.536 117.695L179.56 123.487Z"
      fill="#C2AAFA"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M189.461 87.8747C186.833 87.8747 184.36 88.5173 182.169 89.6337C178.746 85.4366 173.539 82.7528 167.701 82.7528C159.379 82.7528 152.334 88.2036 149.929 95.7296C148.55 95.2104 147.064 94.9131 145.503 94.9131C138.554 94.9131 132.921 100.547 132.921 107.494C132.921 114.442 138.554 120.075 145.503 120.075C149.477 120.075 185.477 120.075 189.461 120.075C198.353 120.075 205.562 112.867 205.562 103.974C205.563 95.0832 198.353 87.8747 189.461 87.8747Z"
      fill="#FCF9F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M166.694 119.903L166.345 120.602L178.109 122.581L176.361 119.67L166.694 119.903Z"
      fill="#6C58B0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M515.618 133.859C518.202 132.873 520.997 132.307 523.929 132.307C536.8 132.307 547.233 142.741 547.233 155.612C547.233 168.483 536.799 178.917 523.928 178.917H477.318H390.073C377.202 178.917 366.768 168.483 366.768 155.612C366.768 142.741 377.202 132.307 390.073 132.307C393.509 132.307 396.762 133.063 399.695 134.403C403.067 119.164 416.658 107.766 432.912 107.766C439.171 107.766 445.036 109.456 450.074 112.406C457.082 105.498 466.7 101.233 477.319 101.233C496.649 101.233 512.635 115.371 515.618 133.859Z"
      fill="#F3ECF7"
    />
    <path
      d="M515.618 133.859L515.001 133.959C515.031 134.144 515.143 134.307 515.306 134.401C515.469 134.495 515.665 134.51 515.841 134.443L515.618 133.859ZM399.695 134.403L399.435 134.971C399.607 135.05 399.805 135.047 399.974 134.962C400.143 134.878 400.264 134.722 400.305 134.538L399.695 134.403ZM450.074 112.406L449.758 112.945C450.002 113.088 450.311 113.049 450.512 112.851L450.074 112.406ZM523.929 131.682C520.916 131.682 518.046 132.263 515.395 133.275L515.841 134.443C518.359 133.482 521.078 132.932 523.929 132.932V131.682ZM547.858 155.612C547.858 142.396 537.145 131.682 523.929 131.682V132.932C536.455 132.932 546.608 143.086 546.608 155.612H547.858ZM523.928 179.542C537.144 179.542 547.858 168.828 547.858 155.612H546.608C546.608 168.138 536.453 178.292 523.928 178.292V179.542ZM477.318 179.542H523.928V178.292H477.318V179.542ZM390.073 179.542H477.318V178.292H390.073V179.542ZM366.143 155.612C366.143 168.828 376.856 179.542 390.073 179.542V178.292C377.547 178.292 367.393 168.138 367.393 155.612H366.143ZM390.073 131.682C376.856 131.682 366.143 142.396 366.143 155.612H367.393C367.393 143.086 377.547 132.932 390.073 132.932V131.682ZM399.955 133.835C396.943 132.458 393.602 131.682 390.073 131.682V132.932C393.417 132.932 396.581 133.667 399.435 134.971L399.955 133.835ZM400.305 134.538C403.615 119.579 416.958 108.391 432.912 108.391V107.141C416.359 107.141 402.519 118.749 399.085 134.268L400.305 134.538ZM432.912 108.391C439.057 108.391 444.813 110.05 449.758 112.945L450.389 111.866C445.258 108.862 439.285 107.141 432.912 107.141V108.391ZM477.319 100.608C466.529 100.608 456.755 104.943 449.635 111.96L450.512 112.851C457.408 106.054 466.871 101.858 477.319 101.858V100.608ZM516.235 133.76C513.205 114.975 496.962 100.608 477.319 100.608V101.858C496.336 101.858 512.066 115.767 515.001 133.959L516.235 133.76Z"
      fill="#6C58B0"
    />
    <path
      d="M449.413 165.248C449.413 165.248 456.412 171.295 456.412 178.931H451.003C451.003 178.931 451.957 169.702 449.413 165.248Z"
      fill="#6C58B0"
    />
    <path
      d="M441.867 179.463C435.384 179.463 430.132 184.717 430.132 191.197C430.132 197.677 435.386 202.931 441.867 202.931C448.347 202.931 453.6 197.677 453.6 191.197C453.6 184.717 448.347 179.463 441.867 179.463ZM441.867 196.972C438.678 196.972 436.093 194.385 436.093 191.198C436.093 188.009 438.678 185.425 441.867 185.425C445.055 185.425 447.641 188.009 447.641 191.198C447.641 194.385 445.056 196.972 441.867 196.972Z"
      fill="#FFDCE8"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M433.215 156.563C427.745 156.563 422.854 159.01 419.551 162.858C416.249 159.01 411.357 156.563 405.886 156.563C395.94 156.563 387.876 164.627 387.876 174.575C387.876 184.52 395.94 192.587 405.886 192.587C411.357 192.587 427.745 192.587 433.213 192.587C443.161 192.587 451.224 184.52 451.224 174.575C451.225 164.627 443.162 156.563 433.215 156.563Z"
      fill="#FCF9F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M430.299 193.346L431.175 196.845L437.302 195.096L436.8 193.096L430.299 193.346Z"
      fill="#6C58B0"
    />
    <path
      d="M603.75 280.154L603.629 280.152C594.768 280.023 587.625 272.799 587.625 263.908C587.625 254.935 594.899 247.661 603.871 247.661C605.34 247.661 606.763 247.856 608.114 248.221L608.627 248.359L608.846 247.875C611.999 240.919 619.002 236.082 627.134 236.082C636.87 236.082 644.988 243.016 646.816 252.216L646.919 252.733L647.446 252.719C647.572 252.715 647.699 252.713 647.826 252.713C654.015 252.713 659.248 256.811 660.957 262.443L661.101 262.918L661.596 262.885C661.786 262.873 661.978 262.867 662.171 262.867C666.945 262.867 670.815 266.737 670.815 271.51C670.815 276.284 666.945 280.154 662.171 280.154H603.75Z"
      fill="#F3ECF7"
      stroke="#6C58B0"
      strokeWidth="1.25"
    />
    <path
      d="M696.575 55.2806L699.185 62.3322C699.349 62.777 699.7 63.1277 700.145 63.2923L707.196 65.9016C707.523 66.0224 707.523 66.4842 707.196 66.605L700.145 69.2143C699.7 69.3789 699.349 69.7296 699.185 70.1744L696.575 77.226C696.454 77.5525 695.993 77.5525 695.872 77.226L693.263 70.1744C693.098 69.7296 692.747 69.3789 692.303 69.2143L685.251 66.605C684.924 66.4842 684.924 66.0224 685.251 65.9016L692.303 63.2923C692.747 63.1277 693.098 62.777 693.263 62.3322L695.872 55.2806C695.993 54.9541 696.454 54.9541 696.575 55.2806Z"
      fill="#FFC8A3"
      stroke="#6C58B0"
      strokeWidth="1.25"
    />
    <mask id="path-32-inside-1_165_32210" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M804.25 192.476C804.677 190.782 804.903 189.009 804.903 187.183C804.903 175.256 795.235 165.587 783.307 165.587C772.707 165.587 763.89 173.225 762.06 183.298C761.036 183.058 759.969 182.931 758.872 182.931C751.182 182.931 744.948 189.165 744.948 196.855C744.948 204.461 751.046 210.643 758.62 210.777V210.779H758.872H802.171H802.269V210.779C807.343 210.726 811.44 206.597 811.44 201.51C811.44 197.106 808.368 193.419 804.25 192.476Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M804.25 192.476C804.677 190.782 804.903 189.009 804.903 187.183C804.903 175.256 795.235 165.587 783.307 165.587C772.707 165.587 763.89 173.225 762.06 183.298C761.036 183.058 759.969 182.931 758.872 182.931C751.182 182.931 744.948 189.165 744.948 196.855C744.948 204.461 751.046 210.643 758.62 210.777V210.779H758.872H802.171H802.269V210.779C807.343 210.726 811.44 206.597 811.44 201.51C811.44 197.106 808.368 193.419 804.25 192.476Z"
      fill="#FCF9F5"
    />
    <path
      d="M804.25 192.476L803.038 192.17L802.726 193.409L803.971 193.694L804.25 192.476ZM762.06 183.298L761.775 184.515L763.055 184.815L763.29 183.521L762.06 183.298ZM758.62 210.777H759.87V209.549L758.642 209.527L758.62 210.777ZM758.62 210.779H757.37V212.029H758.62V210.779ZM802.269 210.779V212.029H803.519V210.779H802.269ZM802.269 210.779L802.256 209.529L801.019 209.541V210.779H802.269ZM803.653 187.183C803.653 188.905 803.44 190.576 803.038 192.17L805.462 192.781C805.914 190.988 806.153 189.113 806.153 187.183H803.653ZM783.307 166.837C794.544 166.837 803.653 175.946 803.653 187.183H806.153C806.153 174.566 795.925 164.337 783.307 164.337V166.837ZM763.29 183.521C765.014 174.032 773.322 166.837 783.307 166.837V164.337C772.092 164.337 762.766 172.418 760.83 183.075L763.29 183.521ZM758.872 184.181C759.872 184.181 760.844 184.297 761.775 184.515L762.345 182.081C761.228 181.819 760.065 181.681 758.872 181.681V184.181ZM746.198 196.855C746.198 189.856 751.872 184.181 758.872 184.181V181.681C750.491 181.681 743.698 188.475 743.698 196.855H746.198ZM758.642 209.527C751.749 209.405 746.198 203.778 746.198 196.855H743.698C743.698 205.144 750.344 211.88 758.598 212.027L758.642 209.527ZM759.87 210.779V210.777H757.37V210.779H759.87ZM758.872 209.529H758.62V212.029H758.872V209.529ZM802.171 209.529H758.872V212.029H802.171V209.529ZM802.269 209.529H802.171V212.029H802.269V209.529ZM801.019 210.779V210.779H803.519V210.779H801.019ZM810.19 201.51C810.19 205.911 806.646 209.483 802.256 209.529L802.282 212.028C808.041 211.969 812.69 207.283 812.69 201.51H810.19ZM803.971 193.694C807.533 194.51 810.19 197.702 810.19 201.51H812.69C812.69 196.511 809.203 192.328 804.529 191.257L803.971 193.694Z"
      fill="#6C58B0"
      mask="url(#path-32-inside-1_165_32210)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M801 210C801 205.582 797.418 202 793 202C792.309 202 791.639 202.088 791 202.252V210H801Z"
      fill="#6C58B0"
    />
    <circle
      cx="789"
      cy="207"
      r="7.375"
      fill="#F3ECF7"
      stroke="#6C58B0"
      strokeWidth="1.25"
    />
    <path
      d="M804.434 339C724.844 426.832 609.865 482 482 482C241.756 482 47 287.244 47 47C47 -193.244 241.756 -388 482 -388C686.22 -388 857.571 -247.271 904.367 -57.5M836 299.86C848.974 281.729 860.59 262.559 870.699 242.5M881.456 219.5C885.144 210.971 888.567 202.301 891.714 193.5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M156.439 219.701C154.045 219.701 151.759 220.142 149.63 220.909C145.912 208.346 134.292 199.179 120.524 199.179C118.386 199.179 116.299 199.401 114.286 199.82C107.392 187.21 94.0058 178.652 78.62 178.652C56.1869 178.652 38 196.84 38 219.272C38 241.707 56.1869 259.892 78.62 259.892C87.004 259.892 150.041 259.892 156.439 259.892C167.538 259.892 176.536 250.896 176.536 239.796C176.536 228.698 167.538 219.701 156.439 219.701Z"
      fill="#FCF9F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M102.804 157.51C94.4022 160.099 92.5538 178.296 98.6749 198.153C104.796 218.011 116.569 232.01 124.971 229.42C133.372 226.831 135.22 208.633 129.099 188.775C122.978 168.919 111.206 154.92 102.804 157.51ZM121.999 219.781C117.62 221.131 110.44 210.443 105.959 195.908C101.478 181.374 101.395 168.495 105.775 167.146C110.153 165.798 117.334 176.485 121.815 191.022C126.294 205.557 126.376 218.433 121.999 219.781Z"
      fill="#A990F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M109.735 155.373C108.294 155.817 102.878 157.486 101.438 157.93C93.0365 160.52 91.1881 178.718 97.3092 198.574C103.43 218.433 115.202 232.433 123.604 229.842C125.044 229.398 130.461 227.728 131.901 227.285C140.302 224.695 142.15 206.497 136.029 186.639C129.909 166.783 118.136 152.784 109.735 155.373ZM128.93 217.646C124.55 218.995 117.37 208.308 112.889 193.772C108.409 179.238 108.325 166.36 112.705 165.009C117.082 163.662 124.264 174.349 128.745 188.885C133.227 203.421 133.307 216.297 128.93 217.646Z"
      fill="#A990F5"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M109.736 155.373C101.334 157.963 99.4854 176.159 105.607 196.018C111.728 215.875 123.5 229.874 131.901 227.285C140.303 224.695 142.15 206.497 136.029 186.639C129.909 166.783 118.136 152.784 109.736 155.373ZM128.93 217.646C124.551 218.995 117.37 208.308 112.889 193.772C108.409 179.238 108.326 166.36 112.705 165.009C117.083 163.662 124.265 174.349 128.745 188.885C133.227 203.421 133.308 216.297 128.93 217.646Z"
      fill="#C2AAFA"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M109.58 155.301C101.178 157.892 99.3297 176.087 105.451 195.946C111.572 215.804 123.344 229.802 131.746 227.213C140.147 224.624 141.995 206.426 135.873 186.568C129.754 166.712 117.98 152.713 109.58 155.301ZM128.775 217.574C124.395 218.923 117.214 208.236 112.734 193.701C108.253 179.167 108.17 166.288 112.55 164.937C116.927 163.59 124.109 174.278 128.589 188.813C133.071 203.35 133.152 216.226 128.775 217.574Z"
      fill="#C2AAFA"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M115.375 176.704L121.636 173.432C121.636 173.432 114.922 160.964 110.544 167.86L115.375 176.704Z"
      fill="#6C58B0"
    />
    <path
      d="M104.907 234.485C104.907 224.065 113.353 215.619 123.773 215.619C134.192 215.619 142.638 224.065 142.638 234.485"
      fill="#FCF9F5"
    />
    <path
      d="M104.907 234.485C104.907 224.065 113.353 215.619 123.773 215.619C134.192 215.619 142.638 224.065 142.638 234.485"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M138.588 202.184C137.768 205.208 134.65 206.995 131.626 206.174L118.848 202.707C115.824 201.887 114.037 198.768 114.857 195.744L118.325 182.966C119.145 179.942 122.263 178.156 125.288 178.976L138.065 182.445C141.089 183.264 142.876 186.382 142.056 189.406L138.588 202.184Z"
      fill="#FE9589"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M158.5 176.92L157.753 165.608L167.174 159.304L177.345 164.311L178.093 175.622L168.672 181.926L158.5 176.92Z"
      fill="#FDBD74"
      stroke="#6C58B0"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M124.555 204.537L124.87 208.002L131.8 212.097L131.625 206.175L124.555 204.537Z"
      fill="#6C58B0"
    />
    <defs>
      <linearGradient
        id="paint0_linear_165_32210"
        x1="70.5"
        y1="216"
        x2="843"
        y2="201"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.255208" stopColor="#EADFF8" />
        <stop offset="1" stopColor="#EADFF8" stopOpacity="0.37" />
      </linearGradient>
    </defs>
  </svg>
);
