import { RuntimeStoryRecord } from "../../../../types/tines.types";
import MailIcon from "../notificationIcons/MailIcon";

export const makeProductDemoStory = () => ({
  dropZoneActionNameMatcher: "Send to product board",
  notification: {
    icon: MailIcon,
    heading: "Tines automation",
    body: "Received new user feedback",
  },
  maxEvents: 7,
  possibleTriggerValues: ["Matches existing tickets", "No match"] as [
    string,
    string
  ],
  inspectable: false,
  story: {
    standardLibVersion: "35",
    actionRuntimeVersion: "4",
    agents: [
      {
        name: "Send to product board",
        // options:
        //   '{"url":"https://ec2.us-east-1.amazonaws.com","method":"get","content_type":"form","headers":{"Authorization":"<<CREDENTIAL.aws_ec2>>"},"payload":{"Action":"StopInstances","Version":"2016-11-15","InstanceId.1":"<<find_extract_key_words.ec2_instance.instancesSet.item.instanceId>>"}}',
        position: { x: 1230, y: 1035 },
        type: "httpRequest",
      },
      {
        name: "Find extract key words",
        // options:
        //   '{"mode":"explode","path":"=find_ec2_instances_with_test_tag.instances","to":"ec2_instance","limit":"500"}',
        position: { x: 1095, y: 825 },
        type: "eventTransformation",
      },
      {
        name: "Attach to existing ticket",
        // options:
        //   '{"url":"https://ec2.us-east-1.amazonaws.com","method":"get","content_type":"form","headers":{"Authorization":"<<CREDENTIAL.aws_ec2>>"},"payload":{"Action":"StartInstances","Version":"2016-11-15","InstanceId.1":"<<find_extract_key_words.ec2_instance.instancesSet.item.instanceId>>"}}',
        position: { x: 1005, y: 1035 },
        type: "httpRequest",
      },
      {
        name: "Matches existing tickets",
        // options:
        //   '{"rules":[{"type":"field==value","value":"false","path":"<<IS_BLANK(power_on_ec2)>>"}]}',
        position: { x: 1005, y: 945 },
        type: "trigger",
      },
      {
        name: "No match",
        // options:
        //   '{"rules":[{"type":"field==value","value":"false","path":"<<IS_BLANK(power_on_ec2)>>"}]}',
        position: { x: 1230, y: 945 },
        type: "trigger",
      },
      {
        name: "Receive user feedback",
        // options:
        //   '{"path":"03c84b712523d09718b12a4caeedb934","secret":"f40f3d8072f96d6fda93860d7d34d781","verbs":"get,post"}',
        position: { x: 1095, y: 735 },
        type: "webhook",
      },
    ],
    links: [
      { sourceIdentifier: 3, receiverIdentifier: 2 },
      { sourceIdentifier: 1, receiverIdentifier: 3 },
      { sourceIdentifier: 1, receiverIdentifier: 4 },
      { sourceIdentifier: 4, receiverIdentifier: 0 },
      { sourceIdentifier: 5, receiverIdentifier: 1 },
    ],
    diagramNotes: [],
  } as unknown as RuntimeStoryRecord,
});
