import { styled } from "@linaria/react";
import Button from "../../components/forms/Button";
import Spacing from "../../components/layout/Spacing";
import BecomeAPartnerForm from "../../components/partners/BecomeAPartnerForm";
import { ExplosionCTASection } from "../../components/reusableSections/ExplosionCTASection";
import PageSection from "../../components/reusableSections/PageSection";
import HeroSection, {
  HeroSectionDescription,
  HeroSectionSplit12Col,
  HeroSectionSplitHeaderWrap,
} from "../../components/sections/HeroSection";
import AllCaps from "../../components/typography/AllCaps";
import { SectionHeading2 } from "../../components/typography/SectionHeading2";
import SEO from "../../components/utilities/SEO";
import { colors } from "../../styles/colors.styles";
import Page from "../../templates/Page.template";
import { PageComponentProps } from "../../types/gatsby.types";
import { scrollToHash } from "../../utils/anchorLinkScroll.utils";
import { BecomeAPartnerIllustration } from "../../components/illustrations/neue/BecomeAPartnerIllustration";
import {
  fromDesktopMd,
  fromTablet,
  fromTabletLg,
  onlyPhones,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { widthInGrid } from "../../constants/globalGrid.constants";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import { rSize } from "../../styles/responsiveSizes.styles";
import { serif } from "../../styles/fonts.styles";
import { LogoCode42 } from "../../components/thirdPartyLogos/LogoCode42";
import { LogoElastic36h } from "../../components/thirdPartyLogos/LogoElastic36h";
import { LogoSnowflake } from "../../components/thirdPartyLogos/LogoSnowflake";
import QuoteCardNeue from "../../components/general/QuoteCardNeue";
import {
  BuildIcon,
  EnableIcon,
  MarketIcon,
  SellIcon,
} from "../../components/partners/BecomeAPartnerPageIcons";
import { graphql } from "gatsby";
import {
  BecomeAPartnerPageQuery,
  DatoCmsCaseStudy,
} from "../../../graphql-types";
import { ContentCardCaseStudy } from "../../components/contentCards/ContentCardCaseStudy";
import ogImage from "../../../static/images/og/tines-become-a-partner.png";

const IllustrationWrap = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 100%;
    max-width: 200px;
    height: auto;
    ${fromTabletLg} {
      width: auto;
      max-width: 100%;
    }
  }
  ${onlyPhones} {
    padding: 2em 3em;
  }
  ${fromTablet} {
    align-items: center;
    justify-content: flex-end;
  }
  ${fromDesktopMd} {
    padding-right: ${widthInGrid(1, 1)};
  }
`;

const ButtonWrap = styled.div`
  ${onlyPhones} {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
`;

const SmallPrint = styled.p`
  font-size: 1.2rem;
  ${fromTablet} {
    font-size: 1.4rem;
  }
  a {
    font-weight: 700;
  }
`;

const TwoByTwo = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTablet} {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    grid-template-rows: repeat(2, minmax(auto, 1fr));
    align-items: stretch;
  }
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  grid-template-areas: "heading icon" "body body";
  grid-gap: ${rSize("gap")};
  border-radius: ${rSize("radius")};
  padding: 1.5em;
  ${onlyPhones} {
    min-height: 10em;
  }
  ${fromTablet} {
    grid-template-rows: repeat(2, minmax(auto, 1fr));
    padding: ${rSize("gap")};
  }
  &:nth-child(1) {
    background-color: ${colors.purple50};
    color: ${colors.purple800};
    border: 1px solid ${colors.purple200};
  }
  &:nth-child(2) {
    background-color: ${colors.green50};
    color: ${colors.green800};
    border: 1px solid ${colors.green200};
  }
  &:nth-child(3) {
    background-color: ${colors.pink50};
    color: ${colors.pink800};
    border: 1px solid ${colors.pink200};
  }
  &:nth-child(4) {
    background-color: ${colors.orange50};
    color: ${colors.orange800};
    border: 1px solid ${colors.orange200};
  }
  h3 {
    grid-area: heading;
    font-family: ${serif};
    font-weight: 400;
    font-size: 2.4rem;
    letter-spacing: -0.02em;
    ${fromTablet} {
      font-size: 2.8rem;
    }
  }
  p {
    grid-area: body;
    align-self: end;
    font-size: 1.4rem;
    max-width: 20em;
    line-height: 1.25;
  }
  svg {
    grid-area: icon;
    justify-self: end;
  }
`;

const QuoteGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletLg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  grid-gap: ${rSize("gap")};
  min-height: 26em;
  align-items: stretch;
`;

const PartnerCaseStudiesGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  ${fromTabletLg} {
    grid-template-columns: repeat(12, minmax(auto, 1fr));
    grid-template-rows: minmax(auto, 16em);
    > * {
      grid-column-end: span 4;
    }
  }
  > header {
    ${uptoTabletLg} {
      grid-column-end: span 2;
      text-align: center;
      padding-bottom: 2em;
    }
    ${fromTabletLg} {
      padding-right: ${rSize("lg")};
    }
  }
`;

const PageBecomeAPartner = (
  props: PageComponentProps<{
    data: BecomeAPartnerPageQuery;
  }>
) => {
  const description =
    "Together we’re on a journey to bring smart, secure workflow automation to teams from security, IT, engineering, and the entire enterprise. Join us and accelerate your growth.";
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO title="Become a partner" description={description} image={ogImage} />
      <HeroSection
        textColor={colors.purple800}
        backdrop="grid"
        backdropColor={colors.purple200}
        backgroundColor={colors.purple50}
        backdropParallax
        contentRelative
      >
        <HeroSectionSplit12Col>
          <HeroSectionSplitHeaderWrap>
            <AllCaps as="p">Become a partner</AllCaps>
            <h1>Partner with Tines to deliver outsized customer value</h1>
            <HeroSectionDescription wider>{description}</HeroSectionDescription>
            <ButtonWrap>
              <Button
                onClick={() =>
                  scrollToHash({
                    useHash: "form",
                    doNotPushState: true,
                  })
                }
                darker
              >
                Get started
              </Button>
            </ButtonWrap>
            <SmallPrint>
              Already a Partner?{" "}
              <a href="https://partners.tines.com/" {...externalLinkAttr}>
                Log in
              </a>{" "}
              to our Partner Portal.
            </SmallPrint>
          </HeroSectionSplitHeaderWrap>
          <IllustrationWrap>
            <BecomeAPartnerIllustration />
          </IllustrationWrap>
        </HeroSectionSplit12Col>
      </HeroSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <SectionHeading2 lighter centered maxWidth="13em">
          Expand your go-to-market strategy with Tines
        </SectionHeading2>
        <Spacing size="sectionPadding" />
        <TwoByTwo>
          <Card>
            <h3>Build</h3>
            <p>
              Develop solutions and services with Tines to tap into new business
              opportunities and revenue streams.
            </p>
            <BuildIcon />
          </Card>
          <Card>
            <h3>Market</h3>
            <p>
              Reach new customers with co-marketing content, campaign assets,
              and a Tines partner directory listing.
            </p>
            <MarketIcon />
          </Card>
          <Card>
            <h3>Sell</h3>
            <p>
              Deliver continuous value and close more valuable deals, faster by
              co-selling with Tines.
            </p>
            <SellIcon />
          </Card>
          <Card>
            <h3>Enable</h3>
            <p>
              Stay close to our product roadmap, deepen your knowledge, and gain
              early access to Tines launches with our suite of partner
              enablement resources.
            </p>
            <EnableIcon />
          </Card>
        </TwoByTwo>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <SectionHeading2 lighter centered maxWidth="15em">
          Our partners say it best
        </SectionHeading2>
        <Spacing size="sectionPadding" />
        <QuoteGrid>
          <QuoteCardNeue
            color="orange"
            /* cspell:disable */
            sourceName="Dave Capuano"
            /* cspell:enable */
            sourceDetails="SVP, Product Management"
            sourceLogo={<LogoCode42 />}
            fontSize="medium"
            footerLayout="space-between"
          >
            Through our recent partnership with Tines, we were able to
            seamlessly deliver integrations for our customers, massively
            reducing the workload on our engineering team.
          </QuoteCardNeue>
          <QuoteCardNeue
            color="pink"
            sourceName="Daniel Gallagher"
            sourceDetails="Security Automation Engineer"
            sourceLogo={<LogoElastic36h />}
            fontSize="medium"
            footerLayout="space-between"
          >
            I'm a big believer in giving analysts the most relevant information
            and removing any distractions at a given time. As a result, there’s
            less alert fatigue because they aren’t sorting through irrelevant
            work. This wouldn’t be possible without Tines to enable that
            decision-making.
          </QuoteCardNeue>
          <QuoteCardNeue
            color="purple"
            /* cspell:disable */
            sourceName="Alex Windle"
            /* cspell:enable */
            sourceDetails="Incident Response Analyst"
            sourceLogo={<LogoSnowflake />}
            fontSize="medium"
            footerLayout="space-between"
          >
            Beyond the product and product team's agility and speed the level of
            support from Tines is the benchmark we hold all our vendors to.
            Across the team we love the consistency in communication and
            response times.
          </QuoteCardNeue>
        </QuoteGrid>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <PartnerCaseStudiesGrid>
          <header>
            <SectionHeading2 lighter smaller>
              Our partners trust Tines with their mission-critical workflows
            </SectionHeading2>
            <Spacing size="lg" />
            <Button to="/case-studies" appearance="outlined" darker>
              More case studies
            </Button>
          </header>
          {props.data.allDatoCmsCaseStudy.nodes.map(n => (
            <ContentCardCaseStudy
              caseStudy={n as DatoCmsCaseStudy}
              key={n.id}
              large
            />
          ))}
        </PartnerCaseStudiesGrid>
      </PageSection>

      <Spacing size="sectionMargin" />

      <PageSection>
        <BecomeAPartnerForm />
      </PageSection>

      <Spacing size="sectionMargin" />

      <ExplosionCTASection />
    </Page>
  );
};

export const becomeAPartnerPageQuery = graphql`
  query becomeAPartnerPage {
    allDatoCmsCaseStudy(filter: { slug: { in: ["elastic", "snowflake"] } }) {
      nodes {
        id: originalId
        color
        slug
        title
        customer {
          logo {
            url
            width
            height
          }
        }
      }
    }
  }
`;

export default PageBecomeAPartner;
