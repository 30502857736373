import { styled } from "@linaria/react";
import LogoCanva from "../thirdPartyLogos/LogoCanva";
import LogoCoinbase from "../thirdPartyLogos/LogoCoinbase";
import LogoDatabricks from "../thirdPartyLogos/LogoDatabricks";
import { LogoElastic36h } from "../thirdPartyLogos/LogoElastic36h";
import LogoGitLab from "../thirdPartyLogos/LogoGitLab";
import LogoIntercom from "../thirdPartyLogos/LogoIntercom";
import LogoMars20h from "../thirdPartyLogos/LogoMars20h";
import LogoMcKesson from "../thirdPartyLogos/LogoMcKesson";
import { LogoOakRidge } from "../thirdPartyLogos/LogoOakRidge";
import LogoOpenTable from "../thirdPartyLogos/LogoOpenTable";
import LogoRedditForDarkBackgrounds, {
  LogoRedditForLightBackgrounds,
} from "../thirdPartyLogos/LogoReddit";
import { LogoSnowflake } from "../thirdPartyLogos/LogoSnowflake";
import { RefObject, useCallback, useRef, useState } from "react";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { maxPageContentWidth } from "../../styles/maxPageContentWidth.styles";
import { withOpacity } from "../../styles/colorsV4.styles";
import { colors } from "../../styles/colors.styles";
import { rSize, responsiveSizeMaps } from "../../styles/responsiveSizes.styles";
import gsap from "gsap";
import { LogoChegg } from "../thirdPartyLogos/LogoChegg";
import { LogoDropbox } from "../thirdPartyLogos/LogoDropbox";
import { LogoKayak } from "../thirdPartyLogos/LogoKayak";
import { LogoMyFitnessPal } from "../thirdPartyLogos/LogoMyFitnessPal";
import LogoSophos from "../thirdPartyLogos/LogoSophos";
import { LogoSprinklr } from "../thirdPartyLogos/LogoSprinklr";
import { LogoBEYONCyber } from "../thirdPartyLogos/LogoBEYONCyber";
import { cx } from "linaria";
import { getOffsetInScrollParent } from "../../utils/scroll.utils";
import {
  getPointerXYFromTouchOrMouseEvent,
  isTouchEvent,
} from "../../utils/touch.utils";
import {
  breakpoints,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import AllCaps from "../typography/AllCaps";
import { LogoCode42 } from "../thirdPartyLogos/LogoCode42";
import { LogoJamf32 } from "../thirdPartyLogos/LogoJamf32";
import { LogoNavan } from "../thirdPartyLogos/LogoNavan";
import { LogoHemato } from "../thirdPartyLogos/LogoHemato";
import { LogoFortuneBrandsInnovations } from "../thirdPartyLogos/LogoFortuneBrandsInnovations";
import { LogoNib } from "../thirdPartyLogos/LogoNib";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const Header = styled.header`
  text-align: center;
  max-width: ${maxPageContentWidth}px;
  margin: 0 auto;
  padding-bottom: ${rSize("gap")};
  padding-left: ${rSize("gap")};
  padding-right: ${rSize("gap")};
  > * {
    margin: 0 auto;
  }
`;

const MaxWidthLimiter = styled.div`
  position: relative;
  max-width: ${maxPageContentWidth}px;
  margin: 0 auto;
`;

const maskerBreakpoint =
  maxPageContentWidth + responsiveSizeMaps.widerPageMargin.desktopMd * 20;

const Masker = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: -1em;
  @media (min-width: ${maskerBreakpoint}px) {
    margin-left: ${responsiveSizeMaps.widerPageMargin.desktopMd * -10}px;
    margin-right: ${responsiveSizeMaps.widerPageMargin.desktopMd * -10}px;
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: ${responsiveSizeMaps.widerPageMargin.desktopMd * 10}px;
      display: block;
      z-index: 1;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    &.light {
      &:before {
        left: 0;
        background-image: linear-gradient(
          to right,
          ${colors.lightest},
          ${withOpacity(colors.lightest, 0)}
        );
      }
      &:after {
        right: 0;
        background-image: linear-gradient(
          to left,
          ${colors.lightest},
          ${withOpacity(colors.lightest, 0)}
        );
      }
    }
    &.dark {
      &:before {
        left: 0;
        background-image: linear-gradient(
          to right,
          ${colors.purple},
          ${withOpacity(colors.purple, 0)}
        );
      }
      &:after {
        right: 0;
        background-image: linear-gradient(
          to left,
          ${colors.purple},
          ${withOpacity(colors.purple, 0)}
        );
      }
    }
  }
`;

const Track = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const FeaturedClientLogoMarquee = (props: {
  againstBackground: "light" | "dark";
  heading?: string;
  logoSet?: "default" | "enterprise";
  fadesAwayWhenScrolledPass?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const trackMarqueeTimelineRef = useRef(gsap.timeline({ paused: true }));
  const trackMarqueeTimeline = trackMarqueeTimelineRef.current;
  useOnMount(() => {
    const setupTrackMarqueeTimeline = () => {
      const currentProgress = trackMarqueeTimeline.progress();
      trackMarqueeTimeline.clear();
      const duration = window.innerWidth < breakpoints.desktop ? 55 : 78;
      trackMarqueeTimeline.fromTo(
        trackRef.current,
        {
          x: "0%",
        },
        { x: "-50%", duration, ease: "linear" }
      );
      trackMarqueeTimeline.add(() => {
        trackMarqueeTimeline.seek(0);
      }, duration);
      if (currentProgress) trackMarqueeTimeline.progress(currentProgress);
    };
    setupTrackMarqueeTimeline();
    trackMarqueeTimeline.timeScale(0);
    trackMarqueeTimeline.play();
    gsap.to(trackMarqueeTimeline, {
      timeScale: 1,
      ease: "expo",
      duration: 3,
    });
    const handleWindowResize = () => {
      setTimeout(setupTrackMarqueeTimeline);
    };
    window.addEventListener("resize", handleWindowResize);
    const fadeOnScrollTimeline = props.fadesAwayWhenScrolledPass
      ? gsap.fromTo(
          wrapperRef.current,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            scrollTrigger: {
              trigger: wrapperRef.current,
              scrub: 1,
              start: "top 33%",
              end: "top top",
              invalidateOnRefresh: true,
            },
          }
        )
      : null;
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (trackMarqueeTimeline.paused()) {
          trackMarqueeTimeline.play();
          gsap.to(trackMarqueeTimeline, {
            timeScale: 1,
            ease: "expo",
            duration: 3,
          });
        }
      } else {
        if (!trackMarqueeTimeline.paused()) {
          gsap.to(trackMarqueeTimeline, {
            timeScale: 0,
            ease: "expo",
            duration: 0.2,
            onComplete: () => {
              trackMarqueeTimeline.pause();
            },
          });
        }
      }
    });
    if (wrapperRef.current) observer.observe(wrapperRef.current);
    return () => {
      observer.disconnect();
      window.removeEventListener("resize", handleWindowResize);
      trackMarqueeTimeline.kill();
      fadeOnScrollTimeline?.kill();
    };
  });
  const [grabbing, setGrabbing] = useState(false);
  const handlePointerDown = useCallback(
    (e: MouseEvent | TouchEvent) => {
      setGrabbing(true);
      gsap.to(trackMarqueeTimeline, {
        timeScale: 0,
        duration: 1,
      });
      let prevClientX = getPointerXYFromTouchOrMouseEvent(e).x;
      const trackScrollWidth = (trackRef.current?.clientWidth ?? 100) / 2;
      const handlePointerMove = (e: MouseEvent | TouchEvent) => {
        const deltaX = getPointerXYFromTouchOrMouseEvent(e).x - prevClientX;
        prevClientX = getPointerXYFromTouchOrMouseEvent(e).x;
        const currentPosition =
          trackMarqueeTimeline.progress() * trackScrollWidth;
        let newPosition = currentPosition - deltaX;
        if (newPosition > trackScrollWidth) newPosition -= trackScrollWidth;
        if (newPosition < 0) newPosition += trackScrollWidth;
        trackMarqueeTimeline.seek(
          (newPosition / trackScrollWidth) * trackMarqueeTimeline.duration()
        );
      };
      const end = () => {
        setGrabbing(false);
        window.removeEventListener("mousemove", handlePointerMove);
        window.removeEventListener("touchmove", handlePointerMove);
        window.removeEventListener("mouseup", end);
        window.removeEventListener("blur", end);
        gsap.to(trackMarqueeTimeline, {
          timeScale: 1,
          duration: 1,
        });
      };
      if (isTouchEvent(e)) {
        window.addEventListener("touchmove", handlePointerMove);
        window.addEventListener("touchend", end, { once: true });
        window.addEventListener("touchcancel", end, { once: true });
      } else {
        window.addEventListener("mousemove", handlePointerMove);
        window.addEventListener("mouseup", end, { once: true });
      }
      window.addEventListener("blur", end, { once: true });
    },
    [trackMarqueeTimeline]
  );
  useOnMount(() => {
    wrapperRef.current?.addEventListener("mousedown", handlePointerDown);
    wrapperRef.current?.addEventListener("touchstart", handlePointerDown);
  });
  return (
    <Wrapper ref={wrapperRef}>
      {grabbing && <style children={`:root { cursor: grabbing }`} />}
      {props.heading && (
        <Header>
          <AllCaps>{props.heading}</AllCaps>
        </Header>
      )}
      <MaxWidthLimiter>
        <Masker className={cx(props.againstBackground)}>
          <Track ref={trackRef}>
            <LogoSet
              againstBackground={props.againstBackground}
              logoSet={props.logoSet}
            />
            <LogoSet
              againstBackground={props.againstBackground}
              clientSide
              logoSet={props.logoSet}
            />
          </Track>
        </Masker>
      </MaxWidthLimiter>
    </Wrapper>
  );
};

const LogoSetWrap = styled.div`
  display: inline-flex;
  align-items: center;
  pointer-events: none;
  perspective: 100px;
  perspective-origin: 33vw 50%;
  > * {
    position: relative;
    margin: 0 1.5em;
    opacity: 0;
    padding: 1em 0;
    ${uptoDesktop} {
      margin: 0 1em;
    }
  }
  &.clientSide {
    > * {
      opacity: 1;
    }
  }
`;

const isLogoSetVisible = (ref: RefObject<HTMLDivElement>) => {
  if (!ref.current) return false;
  const rect = ref.current.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom > 0;
};

const LogoSet = (props: {
  againstBackground: "light" | "dark";
  clientSide?: boolean;
  logoSet?: "default" | "enterprise";
}) => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const handleWindowResize = () => {
      const shouldShrink = window.innerWidth < breakpoints.desktop;
      ref.current?.childNodes.forEach(logo => {
        const svg = (logo as HTMLDivElement).querySelector("svg");
        if (!svg) return;
        gsap.set(svg, {
          width:
            parseInt(`${svg.getAttribute("width")}`) * (shouldShrink ? 0.8 : 1),
          height:
            parseInt(`${svg.getAttribute("height")}`) *
            (shouldShrink ? 0.8 : 1),
        });
      });
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    setLoaded(true);

    if (!props.clientSide) {
      if (!isLogoSetVisible(ref)) {
        ref.current?.childNodes.forEach(logo => {
          gsap.set(logo, { opacity: 1 });
        });
        return;
      }
      ref.current?.childNodes.forEach((logo, i) => {
        const offset = getOffsetInScrollParent(logo as HTMLDivElement);
        if (offset.x > window.innerWidth + 60) {
          gsap.set(logo, { opacity: 1 });
          return;
        }
        const delay = Math.abs(
          (i -
            (window.innerWidth < breakpoints.tablet
              ? 2
              : window.innerWidth < breakpoints.desktop
              ? 4
              : 6)) *
            0.1
        );
        const config = { blur: 12 };
        gsap.to(config, {
          blur: 0,
          delay,
          duration: 2,
          ease: "power3.out",
          onUpdate: () => {
            gsap.set(logo, { filter: `blur(${config.blur}px)` });
          },
        });
        gsap.fromTo(
          logo,
          { opacity: 0 },
          {
            opacity: 1,
            delay,
            duration: 1,
          }
        );
        gsap.fromTo(
          logo,
          { z: 8 },
          {
            z: 0,
            delay,
            duration: 2,
            ease: "power3.out",
          }
        );
      });
    }
    window.removeEventListener("resize", handleWindowResize);
  });
  const logoReddit = (
    <div>
      {props.againstBackground === "light" ? (
        <LogoRedditForLightBackgrounds />
      ) : (
        <LogoRedditForDarkBackgrounds />
      )}
    </div>
  );
  const logoSetDefault = (
    <>
      <div style={{ top: -1 }}>
        <LogoBEYONCyber />
      </div>
      <div style={{ top: 1 }}>
        <LogoCanva />
      </div>
      <div style={{ top: 1 }}>
        <LogoChegg />
      </div>
      <div>
        <LogoCoinbase />
      </div>
      <div style={{ top: -1 }}>
        <LogoDatabricks />
      </div>
      <div style={{ top: -1 }}>
        <LogoDropbox />
      </div>
      <div>
        <LogoElastic36h />
      </div>
      <div>
        <LogoGitLab />
      </div>
      <div>
        <LogoIntercom />
      </div>
      <div>
        <LogoKayak />
      </div>
      <div>
        <LogoMars20h />
      </div>
      <div>
        <LogoMcKesson />
      </div>
      <div style={{ top: 1 }}>
        <LogoMyFitnessPal />
      </div>
      <div>
        <LogoOakRidge />
      </div>
      <div>
        <LogoOpenTable />
      </div>
      {logoReddit}
      <div>
        <LogoSnowflake />
      </div>
      <div style={{ top: -1 }}>
        <LogoSophos />
      </div>
      <div style={{ top: -1 }}>
        <LogoSprinklr />
      </div>
    </>
  );
  const logoSetEnterprise = (
    <>
      <div style={{ top: 1 }}>
        <LogoCanva />
      </div>
      <div>
        <LogoCode42 />
      </div>
      <div>
        <LogoCoinbase />
      </div>
      <div>
        <LogoDatabricks />
      </div>
      <div>
        <LogoElastic36h />
      </div>
      <div>
        <LogoFortuneBrandsInnovations />
      </div>
      <div>
        <LogoGitLab />
      </div>
      <div>
        <LogoHemato />
      </div>
      <div>
        <LogoIntercom />
      </div>
      <div>
        <LogoJamf32 />
      </div>
      <div>
        <LogoMars20h />
      </div>
      <div>
        <LogoMcKesson />
      </div>
      <div>
        <LogoNavan />
      </div>
      <div>
        <LogoNib />
      </div>
      <div>
        <LogoOakRidge />
      </div>
      {logoReddit}
      <div style={{ top: -1 }}>
        <LogoSophos />
      </div>
      <div style={{ top: -1 }}>
        <LogoSprinklr />
      </div>
    </>
  );
  return (
    <LogoSetWrap ref={ref} className={cx(props.clientSide && "clientSide")}>
      {(!props.clientSide || loaded) &&
        (props.logoSet === "enterprise" ? logoSetEnterprise : logoSetDefault)}
    </LogoSetWrap>
  );
};
