import { styled } from "@linaria/react";
import {
  fromTablet,
  fromTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { fluidFontSize } from "../../styles/helpers.styles";
import { font } from "../../styles/fonts.styles";
import { cx } from "linaria";

const SectionH2 = styled.h2`
  ${fluidFontSize(32, 46)};
  line-height: 1.1;
  letter-spacing: -0.01em;
  font-family: ${font("serif")};
  font-weight: 400;
  &.centered {
    text-align: center;
  }
  &.smallerOnDesktop {
    ${fluidFontSize(32, 36)};
  }
  &.smaller {
    ${fluidFontSize(24, 36)};
  }
  &.lighter {
    ${fromTabletMd} {
      font-weight: 300;
    }
  }
  &.blockSpan {
    span {
      ${fromTablet} {
        display: block;
      }
    }
  }
`;

export const SectionHeading2 = (
  props: React.HTMLAttributes<HTMLHeadingElement> & {
    className?: string;
    smaller?: boolean;
    smallerOnDesktop?: boolean;
    blockSpan?: boolean;
    lighter?: boolean;
    centered?: boolean;
    maxWidth?: string;
  }
) => {
  return (
    <SectionH2
      {...props}
      className={cx(
        props.className,
        props.smallerOnDesktop && "smaller",
        props.smallerOnDesktop && "smallerOnDesktop",
        props.blockSpan && "blockSpan",
        props.lighter && "lighter",
        props.centered && "centered"
      )}
      style={{
        maxWidth: props.maxWidth,
        marginLeft: props.maxWidth && props.centered ? "auto" : undefined,
        marginRight: props.maxWidth && props.centered ? "auto" : undefined,
      }}
    />
  );
};
