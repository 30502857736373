type Props = {};

const RocketIconPinkBorders = (props: Props) => {
  return (
    <svg
      width="146"
      height="146"
      viewBox="0 0 146 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.0767 138.039C112.558 138.039 132.403 137.166 132.403 136.09C132.403 135.013 112.558 134.141 88.0767 134.141C63.5957 134.141 43.75 135.013 43.75 136.09C43.75 137.166 63.5957 138.039 88.0767 138.039Z"
        fill="#45293A"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M117.38 123.598C110.519 115.074 104.282 118.193 93.0559 122.143C88.0946 123.889 79.0226 119.804 79.0226 109.409C79.0226 104.175 81.0497 93.6797 81.0497 93.6797L73.2607 93.8558L65.472 93.6797C65.472 93.6797 67.4991 104.174 67.4991 109.409C67.4991 119.804 58.4274 123.889 53.466 122.143C42.2396 118.193 36.0028 115.074 29.142 123.598C27.1058 126.128 26.0236 131.082 28.9343 136.072C28.9343 137.148 48.7802 138.021 73.2607 138.021C97.742 138.021 117.587 137.148 117.587 136.072C120.498 131.082 119.416 126.127 117.38 123.598Z"
        fill="#DAF2E8"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.2389 96.4993C80.5308 94.8059 80.7476 93.6797 80.7476 93.6797L72.9589 93.8558L65.1699 93.68C65.1699 93.68 65.387 94.8059 65.6789 96.4996C67.6154 96.9969 70.1639 97.2996 72.9586 97.2996C75.7531 97.2993 78.3021 96.9964 80.2389 96.4993Z"
        fill="#45293A"
      />
      <path
        d="M87.6014 70.0938C87.6014 70.0938 91.2296 80.2529 101.933 81.8854L103.566 110.548H101.57C101.57 110.548 92.1365 84.4253 84.5176 86.6019C84.5176 86.6019 87.0124 75.0368 87.6014 70.0938Z"
        fill="#B35980"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M58.9975 70.0938C58.9975 70.0938 55.3692 80.2529 44.6662 81.8854L43.0332 110.548H45.0293C45.0293 110.548 54.4626 84.4253 62.0816 86.6019C62.0816 86.6019 59.5871 75.0368 58.9975 70.0938Z"
        fill="#B35980"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M84.9786 35.2344H73.3227H73.2017H61.546C53.5355 57.343 63.0317 93.6921 63.0317 93.6921C63.0317 94.4046 67.612 94.9825 73.2618 94.9825C78.9121 94.9825 83.4924 94.4046 83.4924 93.6921C83.4927 93.6921 92.9886 57.343 84.9786 35.2344Z"
        fill="#EB81AF"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.3387 13.9219C73.3313 13.9252 58.7402 20.3706 58.7402 45.7341H73.3387H87.9375C87.9375 20.3706 73.3462 13.9252 73.3387 13.9219Z"
        fill="#EB81AF"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M89.9606 46.0187C89.9606 47.2375 88.5563 48.225 86.8247 48.225H60.3075C58.5759 48.225 57.1719 47.2375 57.1719 46.0187C57.1719 44.8003 58.5759 43.8125 60.3075 43.8125H86.8247C88.5563 43.8125 89.9606 44.8003 89.9606 46.0187Z"
        fill="#81D8B4"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M89.9606 50.4094C89.9606 51.6281 88.5563 52.6159 86.8247 52.6159H60.3075C58.5759 52.6159 57.1719 51.6281 57.1719 50.4094C57.1719 49.1909 58.5759 48.2031 60.3075 48.2031H86.8247C88.5563 48.2031 89.9606 49.1909 89.9606 50.4094Z"
        fill="#81D8B4"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.3386 24.6875C76.8062 24.6875 80.0894 24.4294 83.0184 23.9686C78.7815 16.3269 73.343 13.9241 73.3386 13.9219C73.3341 13.9241 67.8948 16.3272 63.6582 23.9686C66.5875 24.4294 69.8709 24.6875 73.3386 24.6875Z"
        fill="#FDB2D2"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M58.4688 52.3125H88.077V54.8275C88.077 54.8275 71.3791 54.8275 58.4688 52.3125Z"
        fill="#45293A"
      />
      <path
        d="M73.3348 37.4196C74.6998 37.4196 75.8063 36.3131 75.8063 34.9481C75.8063 33.5831 74.6998 32.4766 73.3348 32.4766C71.9698 32.4766 70.8633 33.5831 70.8633 34.9481C70.8633 36.3131 71.9698 37.4196 73.3348 37.4196Z"
        fill="#DAF2E8"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.3348 63.4743C74.6998 63.4743 75.8063 62.3678 75.8063 61.0028C75.8063 59.6378 74.6998 58.5312 73.3348 58.5312C71.9698 58.5312 70.8633 59.6378 70.8633 61.0028C70.8633 62.3678 71.9698 63.4743 73.3348 63.4743Z"
        fill="#DAF2E8"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M58.9975 72.8203C58.9975 72.8203 55.3692 82.9795 44.6662 84.6119L43.0332 113.275H45.0293C45.0293 113.275 54.4626 87.1519 62.0816 89.3285C62.0816 89.3285 59.5871 77.7637 58.9975 72.8203Z"
        fill="#FDB2D2"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M87.6014 72.8203C87.6014 72.8203 91.2296 82.9795 101.933 84.6119L103.566 113.275H101.57C101.57 113.275 92.1365 87.1519 84.5176 89.3285C84.5176 89.3285 87.0124 77.7637 87.6014 72.8203Z"
        fill="#FDB2D2"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.3223 13.9237V3.50781"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.3307 5.41927C74.3828 5.41927 75.2357 4.5664 75.2357 3.51432C75.2357 2.46225 74.3828 1.60938 73.3307 1.60938C72.2786 1.60938 71.4258 2.46225 71.4258 3.51432C71.4258 4.5664 72.2786 5.41927 73.3307 5.41927Z"
        fill="#45293A"
      />
      <path
        d="M83.4896 93.6868C83.4896 93.6868 84.2533 90.7622 85.1644 86.0703C81.6838 86.4068 77.6124 86.6006 73.259 86.6006C68.906 86.6006 64.8347 86.4068 61.3535 86.0703C62.2651 90.7622 63.0286 93.6868 63.0286 93.6868C63.0286 94.3993 67.6089 94.9772 73.2587 94.9772C78.9093 94.9772 83.4896 94.3993 83.4896 93.6868Z"
        fill="#81D8B4"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.2656 94.9704V86.5938"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M66.3516 86.4375L67.3457 94.9836"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.2238 86.4375L79.2305 94.9836"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.0539 114.07C75.0539 114.821 74.2544 115.43 73.268 115.43C72.2816 115.43 71.4824 114.821 71.4824 114.07V71.8918C71.4824 71.1408 72.2819 70.5312 73.268 70.5312C74.2544 70.5312 75.0539 71.1405 75.0539 71.8918V114.07Z"
        fill="#B35980"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.0539 114.085C75.0539 114.836 74.2544 115.445 73.268 115.445C72.2816 115.445 71.4824 114.836 71.4824 114.085V84.6967C71.4824 83.9452 72.2819 83.3359 73.268 83.3359C74.2544 83.3359 75.0539 83.9452 75.0539 84.6967V114.085Z"
        fill="#FDB2D2"
        stroke="#45293A"
        strokeWidth="1.38461"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RocketIconPinkBorders;
