import { styled } from "@linaria/react";
import {
  uptoTablet,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";

export const AlignLeft = styled.div`
  text-align: left;
`;
export const AlignCenter = styled.div`
  text-align: center;
`;
export const AlignCenterOnMobile = styled.div`
  ${uptoTablet} {
    text-align: center;
  }
`;
export const AlignRight = styled.div`
  text-align: right;
`;
export const AlignCenterUptoTabletLg = styled.div`
  ${uptoTabletLg} {
    text-align: center;
  }
`;
