export const ShapesTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M10.5 10H16L11.52 2L8.5 6.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="4.5"
      cy="4.5"
      r="4"
      transform="matrix(-1 0 0 1 11 6)"
      stroke="currentColor"
    />
    <circle
      opacity="0.1"
      cx="4.5"
      cy="4.5"
      r="4.5"
      transform="matrix(-1 0 0 1 13 8)"
      fill="currentColor"
    />
    <path
      d="M10 10H6L8.11765 7"
      stroke="#currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0.56 1.68"
    />
  </svg>
);
