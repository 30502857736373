/* eslint-disable @typescript-eslint/no-misused-promises */
import { PageComponent } from "../types/gatsby.types";
import ProductUpdatePageContent from "../components/productUpdates/ProductUpdatePageContent";
import Page from "./Page.template";
import { graphql } from "gatsby";
import {
  DatoCmsProductUpdate,
  ProductUpdateByIdQuery,
} from "../../graphql-types";
import { ProductUpdateTimelineEntry } from "../components/hub/ProductUpdateTimeline";

const HubProductUpdatePage: PageComponent<{
  data: ProductUpdateByIdQuery;
  pageContext: {
    id: string;
    timeline: ProductUpdateTimelineEntry[];
  };
}> = props => {
  return (
    <Page {...props}>
      <ProductUpdatePageContent
        id={props.pageContext.id}
        entries={[props.data.productUpdate as DatoCmsProductUpdate]}
        timeline={props.pageContext.timeline}
      />
    </Page>
  );
};

export const productUpdatePageQuery = graphql`
  query ProductUpdateById($id: String) {
    productUpdate: datoCmsProductUpdate(originalId: { eq: $id }) {
      id: originalId
      title
      slug
      date
      category {
        image {
          url
          width
          height
        }
      }
      contributors {
        id
        name
        surname
        linkedin
        photoIcon {
          url
          width
          height
        }
      }
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              url
              width
              height
            }
            caption
            link
            altTag
            showInFullPageWidth
            applyBorder
          }
          ... on DatoCmsPullQuote {
            id: originalId
            content
            source
          }
          ... on DatoCmsVideoBlock {
            id: originalId
            title
            url
            videoFile {
              video {
                mp4Url
                streamingUrl
                thumbnailUrl
              }
              width
              height
              basename
              alt
              url
            }
            autoplay
            loop
          }
          ... on DatoCmsIframeEmbed {
            id: originalId
            src
            title
          }
          ... on DatoCmsCustomHtmlBlock {
            id: originalId
            htmlContent
          }
          ... on DatoCmsChecklistBlock {
            id: originalId
            customIcon
            color
            listContent {
              value
            }
          }
          ... on DatoCmsCtaBlock {
            id: originalId
            heading
            spotIllustration {
              url
            }
            themeColor
            buttonLabel
            buttonLink
          }
          ... on DatoCmsStoryEmbed {
            id: originalId
            story {
              descriptor
              internalAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              communityAuthors {
                name
                surname
                linkedin
                organization {
                  name
                }
              }
              videoIntroEmbedUrl
              gallery {
                url
                width
                height
              }
            }
            pillText
            storyUrl
            overrideColor
            utmCampaign
            utmSource
            utmMedium
          }
          ... on DatoCmsHtmlWysiwygBlock {
            id: originalId
            content
          }
          ... on DatoCmsCalloutBanner {
            id: originalId
            content
            calloutType
          }
        }
      }
    }
  }
`;

export default HubProductUpdatePage;
