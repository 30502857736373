import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import {
  fromDesktop,
  uptoDesktop,
} from "../../styles/breakpointsAndMediaQueries.styles";
import {
  EventDirectoryItem,
  GeolocationCoords,
} from "../../types/helper.types";
import { MapController } from "../../utils/maps.utils";
import EventDirectoryItemIndex from "./EventDirectoryItemIndex";
import EventDirectoryItemDetailsPanel from "./EventDirectoryItemDetailsPanel";
import {
  getUrlQueryParams,
  removeUrlQueryParam,
  setUrlQueryParam,
} from "../../utils/urlQueryParams.utils";
import EventDirectoryMap from "./EventDirectoryMap";
import { resolveAfter } from "../../utils/promises.utils";
import Spacing from "../layout/Spacing";
import { AdminToolBarWithEditInDatoCmsButton } from "../site/AdminToolbar";

type Props = React.PropsWithChildren<{
  items: EventDirectoryItem[];
  onRetrievingUserGeolocation: (coords: GeolocationCoords) => void;
}>;

const EventDirectoryMapSection = styled.header`
  position: relative;
  font-weight: 500;
  ${uptoDesktop} {
    text-align: center;
  }
  ${fromDesktop} {
    min-height: 350px;
    height: 50vh;
    max-height: 500px;
  }
`;

const EventDirectoryMapWrapper = styled.div`
  ${uptoDesktop} {
    display: none;
  }
`;

const UpcomingEvents = (props: Props) => {
  const [ready, setReady] = useState(false);
  const [mapController, setMapController] = useState<MapController | null>(
    null
  );
  const [selectedItem, selectItem] = useState<EventDirectoryItem | null>(null);
  const mapControllerRef = useRef<MapController | null>(mapController);
  mapControllerRef.current = mapController;
  const handleSelectItem = (item: EventDirectoryItem | null) => {
    if (!item) {
      clearSelection();
      return;
    }
    selectItem(item);
    setUrlQueryParam("event", item.slug);
  };
  const handleSelectItemThroughMap = (item: EventDirectoryItem | null) => {
    handleSelectItem(item);
  };
  const handleSelectItemThroughEntry = (item: EventDirectoryItem | null) => {
    if (!item) {
      clearSelection();
      return;
    }
    handleSelectItem(item);
  };
  const clearSelection = () => {
    selectItem(null);
    removeUrlQueryParam("event");
  };
  const searchFormState = useState(() => ({
    query: "",
  }));

  const initUI = (mc: MapController) => {
    setMapController(mc);
    mc.onReady(async () => {
      await resolveAfter();
      const { s, event } = getUrlQueryParams();
      if (s) searchFormState[1]({ query: s });
      if (event) {
        const item = props.items.find(i => i.slug === event);
        if (item) handleSelectItem(item);
        else selectItem(null);
      }
      setReady(true);
    });
  };

  const onRetrievingUserGeolocation = (coords: GeolocationCoords) => {
    mapControllerRef.current?.onReady(() =>
      props.onRetrievingUserGeolocation(coords)
    );
  };

  return (
    <>
      <EventDirectoryItemIndex
        searchFormState={searchFormState}
        items={props.items}
        selectedItem={selectedItem}
        onSelectItem={handleSelectItemThroughEntry}
        onRetrievingUserGeolocation={onRetrievingUserGeolocation}
        map={
          <EventDirectoryMapSection>
            <EventDirectoryMapWrapper>
              <EventDirectoryMap
                items={props.items}
                onControllerConstructed={initUI}
                onSelectItem={handleSelectItemThroughMap}
              />
            </EventDirectoryMapWrapper>
          </EventDirectoryMapSection>
        }
        ready={ready}
      />

      {!selectedItem && (
        <AdminToolBarWithEditInDatoCmsButton itemType="event" />
      )}

      {selectedItem && (
        <EventDirectoryItemDetailsPanel
          allItems={props.items}
          item={selectedItem}
          onShouldClose={clearSelection}
          onSelectItem={handleSelectItemThroughEntry}
        />
      )}
      <Spacing size="sectionMargin" />
    </>
  );
};

export default UpcomingEvents;
