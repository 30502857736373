export const HatchingDinoEggTwoToneIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M14.75 9.75C14.75 13.2082 12.1916 15.5 9 15.5C5.80844 15.5 3.25 13.2082 3.25 9.75C3.25 7.78203 3.91781 5.6917 4.99455 4.10211C6.07811 2.50245 7.50738 1.5 9 1.5C10.4926 1.5 11.9219 2.50245 13.0054 4.10211C14.0822 5.6917 14.75 7.78203 14.75 9.75Z"
      stroke="currentColor"
    />
    <circle opacity="0.5" cx="11.5" cy="11.5" r="2.5" fill="currentColor" />
    <path
      opacity="0.5"
      d="M11.5 2.875L9.625 4.75L10.875 6L9 7.875"
      stroke="currentColor"
    />
    <circle opacity="0.5" cx="5.875" cy="5.375" r="1.875" fill="currentColor" />
  </svg>
);
