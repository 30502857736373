import { styled } from "@linaria/react";
import { externalLinkAttr } from "../../constants/externalLinks.constants";
import {
  fromDesktopMd,
  fromTablet,
  mediaFromTo,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import LogoAccel from "../thirdPartyLogos/LogoAccel";
import LogoAddition from "../thirdPartyLogos/LogoAddition";
import LogoBlossom from "../thirdPartyLogos/LogoBlossom";
import LogoFelicis from "../thirdPartyLogos/LogoFelicis";
import LogoIndexVentures from "../thirdPartyLogos/LogoIndexVentures";
import PageSection from "./PageSection";

const Header = styled.header`
  text-align: center;
`;
const LogoRow = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${fromDesktopMd} {
    justify-content: space-between;
  }
  ${mediaFromTo("tablet", "desktopMd")} {
    max-width: 700px;
  }
  margin-top: ${rSize("xl")};
  > * {
    display: block;
    &:hover {
      opacity: 0.8;
    }
    ${uptoTabletMd} {
      margin: 2.5em auto;
    }
    ${fromTablet} {
      margin: 1.5em 2.5em;
    }
    ${fromDesktopMd} {
      margin: 0.75em 1.5em;
    }
  }
`;

const InvestorListSection = () => {
  return (
    <PageSection>
      <Header>
        <h2>Backed by the best</h2>
      </Header>
      <LogoRow>
        <a href="https://www.accel.com/" {...externalLinkAttr}>
          <LogoAccel />
        </a>
        <a href="https://www.blossomcap.com/" {...externalLinkAttr}>
          <LogoBlossom />
        </a>
        <a href="https://www.felicis.com/" {...externalLinkAttr}>
          <LogoFelicis />
        </a>
        <a href="https://www.additioncapital.com/" {...externalLinkAttr}>
          <LogoAddition />
        </a>
        <a href="https://www.indexventures.com/" {...externalLinkAttr}>
          <LogoIndexVentures />
        </a>
      </LogoRow>
    </PageSection>
  );
};

export default InvestorListSection;
