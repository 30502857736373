export const PageIconClouds = () => (
  <svg
    width="123"
    height="97"
    viewBox="0 0 123 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.6606 7.8423C73.8097 3.99145 69.8853 1.33002 65.3396 1.33002C59.4727 1.33002 54.6392 5.76573 53.9721 11.4829C53.3965 11.3186 52.7882 11.2266 52.1604 11.2266C48.5565 11.2266 45.6394 14.1574 45.6394 17.7783C45.6394 21.3992 48.5565 24.33 52.1604 24.33H74.3852C78.944 24.33 82.6394 20.6172 82.6394 16.0369C82.6394 11.8903 79.6111 8.45345 75.6541 7.8423H75.6606Z"
      fill="#FFE5CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M109.347 34.1068C108.742 34.1068 108.137 34.15 107.554 34.2364C104.897 31.5148 101.182 29.83 97.078 29.83C91.9156 29.83 87.3579 32.5084 84.7443 36.5476C83.9019 36.3532 83.0163 36.2452 82.1307 36.2452C75.7802 36.2452 70.6394 41.386 70.6394 47.7365C70.6394 54.0869 75.7802 59.2277 82.1307 59.2277H109.368C116.302 59.2277 121.918 53.6117 121.918 46.6781C121.918 39.7444 116.302 34.1284 109.368 34.1284L109.347 34.1068Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M41.2554 38.0203C40.6513 38.0203 40.0565 38.0658 39.4799 38.1478C36.8533 35.4788 33.1926 33.83 29.1475 33.83C24.0499 33.83 19.5656 36.4535 16.9847 40.416C16.1519 40.2247 15.2917 40.1154 14.3948 40.1154C8.14408 40.1154 3.07397 45.162 3.07397 51.3836C3.07397 57.6053 8.14408 62.6519 14.3948 62.6519H41.2554C48.0918 62.6519 53.6286 57.1407 53.6286 50.3361C53.6286 43.5314 48.0918 38.0203 41.2554 38.0203Z"
      fill="#FFBFDB"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M72.3713 47.2568C67.4476 40.1789 59.2385 35.5331 49.9219 35.5331C35.7549 35.5331 24.1138 46.2821 22.7776 60.0281C20.81 59.1353 18.6227 58.6343 16.3165 58.6343C7.65884 58.6343 0.639404 65.6212 0.639404 74.2386C0.639404 82.856 7.65884 89.8428 16.3165 89.8428H71.4836C83.3078 89.8428 92.8897 80.3054 92.8897 68.5362C92.8897 56.7669 83.7837 47.7214 72.3805 47.2568H72.3713Z"
      fill="#FFD8CF"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M67.9417 80.287C67.9417 80.287 71.2273 85.9257 70.5317 89.8518L66.2761 89.952C66.2761 89.952 68.8844 83.3022 67.9509 80.287H67.9417Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M83.7361 68.4785C83.7361 68.4785 84.8068 73.4977 92.1924 73.4977L92.7872 70.2639L83.7361 68.4876V68.4785Z"
      fill="#483C72"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M55.9344 71.0579C50.1596 71.0579 45.2908 74.9567 43.8448 80.2492C43.2774 80.1216 42.6917 80.0488 42.0877 80.0488C37.6673 80.0488 34.0798 83.6196 34.0798 88.0194C34.0798 92.4192 37.6673 95.99 42.0877 95.99H55.9344C62.8532 95.99 68.4632 90.406 68.4632 83.5194C68.4632 76.6328 62.8532 71.0488 55.9344 71.0488V71.0579Z"
      fill="#FFE5CA"
      stroke="#483C72"
      strokeLinejoin="round"
    />
    <path
      d="M87.8378 57.8157C90.3271 57.8157 92.4778 59.2641 93.4936 61.3592C95.8274 61.5323 97.6669 63.4635 97.6669 65.8319C97.6669 68.2003 95.6443 70.3228 93.155 70.3228H87.8378C84.3693 70.3228 81.5505 67.5262 81.5505 64.0647C81.5505 60.6031 84.3601 57.8066 87.8378 57.8066V57.8157Z"
      fill="#D6EDD9"
      stroke="#483C72"
      strokeLinejoin="round"
    />
  </svg>
);
