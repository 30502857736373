import { styled } from "@linaria/react";
import { darken } from "polished";
import { CSSProperties, PropsWithChildren } from "react";
import { gridGapsPx } from "../../../constants/globalGrid.constants";
import { colorsV4, withOpacity } from "../../../styles/colorsV4.styles";
import RigidDotGrid from "../../decorations/RigidDotGrid";
import PageIcon from "../../icons/standard/page.icon";

type Props = {
  id?: string;
  className?: string;
  color: string;
};

export const PagePreviewCardStylizedWrapper = styled.div`
  background-color: ${colorsV4.white};
  border: 2px solid ${colorsV4.canvas700};
  padding: 1.6rem;
  border-radius: 2rem;
  height: 24rem;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-gap: 1.6rem;
`;
const CardHeader = styled.header`
  display: flex;
  > * {
    + * {
      margin-left: 0.8rem;
    }
  }
`;
const HeadingHold = styled.div`
  flex: 1 1 auto;
  height: 1.5rem;
  border-radius: 0.25rem;
  opacity: 0.5;
`;
const PageBodyFrame = styled.div`
  background-color: var(--ac, ${colorsV4.purple});
  border-radius: 0.8rem;
  display: flex;
  align-items: stretch;
  padding-top: ${gridGapsPx(1.5)};
  position: relative;
  overflow: hidden;
`;
const Paper = styled.div`
  position: relative;
  border-radius: 0.6rem 0.6rem 0 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 66.67%;
  background-color: ${colorsV4.white};
  width: 12rem;
  padding: 1.2rem;
  svg {
    max-width: 100%;
  }
`;
const Shade = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
`;
const PaperHeader = styled.div`
  padding-right: 0.8rem;
  padding-bottom: 0.8rem;
  > * + * {
    margin-top: 0.8rem;
  }
`;
const LogoHold = styled.div`
  height: 1.4rem;
  border-radius: 0.7rem;
  background-color: var(--ac);
  width: 3.2rem;
`;
const TextHold = styled.div`
  flex: 1 1 auto;
  height: 1.5rem;
  border-radius: 0.5rem;
  opacity: 0.25;
  background-image: linear-gradient(
    to right,
    ${colorsV4.warmBlack},
    ${withOpacity(colorsV4.warmBlack, 0)}
  );
`;

const PagePreviewCardStylized = (props: PropsWithChildren<Props>) => {
  return (
    <PagePreviewCardStylizedWrapper
      id={props.id}
      className={props.className}
      style={{ "--ac": props.color } as CSSProperties}
    >
      <CardHeader>
        <PageIcon />
        <HeadingHold
          style={{
            backgroundImage: `linear-gradient(
            to right,
            ${props.color},
            ${withOpacity(props.color, 0)}
          )`,
          }}
        />
      </CardHeader>
      <PageBodyFrame>
        <RigidDotGrid
          cellSize={18}
          offset={-18}
          color={darken(0.1, props.color)}
        />
        <Paper>
          <PaperHeader>
            <LogoHold />
            <TextHold />
          </PaperHeader>
          {props.children}
        </Paper>
        <Shade
          style={{
            backgroundImage: `radial-gradient(
          109.02% 147.62% at 50% 147.62%,
          ${props.color} 0%,
          ${withOpacity(props.color, 0)} 100%
        )`,
          }}
        />
      </PageBodyFrame>
    </PagePreviewCardStylizedWrapper>
  );
};

export default PagePreviewCardStylized;
