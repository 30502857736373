import { useState } from "react";
import { DatoCmsCaseStudy } from "../../../graphql-types";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { styled } from "@linaria/react";
import { rSize } from "../../styles/responsiveSizes.styles";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { Serif } from "../typography/Serif";
import Spacing from "../layout/Spacing";
import { fromTabletMd } from "../../styles/breakpointsAndMediaQueries.styles";
import CaseStudyBookCardLink from "../general/CaseStudyBookCardLink";
import Button from "../forms/Button";
import { Paths } from "../../utils/pathBuilders.utils";

type Props = {
  availableRecords: DatoCmsCaseStudy[];
};

const CaseStudyReadMoreSectionInner = styled.div`
  h2 {
    text-align: center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletMd} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const CaseStudyReadMoreSection = (props: Props) => {
  const [recordsToUse, setRecordsToUse] = useState([] as DatoCmsCaseStudy[]);
  useOnMount(() => {
    const randomThree = [...props.availableRecords]
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);
    setRecordsToUse(randomThree);
  });
  return (
    <PageSection>
      <CaseStudyReadMoreSectionInner>
        <SectionHeading2>
          More <Serif>case studies</Serif>
        </SectionHeading2>
        <Spacing size="sectionPadding" />
        <Grid>
          {recordsToUse.map(r => (
            <CaseStudyBookCardLink caseStudy={r} key={r.id} />
          ))}
        </Grid>
        <Spacing size="xl" />
        <ButtonWrap>
          <Button appearance="outlined" to={Paths.caseStudies()}>
            See all case studies
          </Button>
        </ButtonWrap>
      </CaseStudyReadMoreSectionInner>
    </PageSection>
  );
};

export default CaseStudyReadMoreSection;
