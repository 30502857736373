import { styled } from "@linaria/react";
import { colors } from "../../styles/colors.styles";
import { font } from "../../styles/fonts.styles";

export const WhatSticker = styled.strong`
  position: relative;
  display: inline-block;
  font-weight: inherit;
  text-transform: capitalize;
  font-size: 85%;
  margin-left: 0.625em;
  margin-right: 0.125em;
  user-select: none;
  color: white;
  font-family: ${font("sans")};
  font-weight: 600;
  b,
  &:before {
    font-style: normal;
    display: inline-block;
    border: 0.08em solid currentColor;
    padding: 0.1em 0.2em;
    border-radius: 0.3em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.1s;
  }
  b {
    background-color: ${colors.green};
    font-weight: inherit;
    transform: translate(-10%, -25%) rotate(-12deg);
    &:before {
      content: "";
      position: absolute;
      top: -0.1em;
      left: -0.1em;
      bottom: -0.1em;
      right: -0.1em;
      opacity: 0.15;
      display: block;
      border-radius: inherit;
    }
  }
  &:before {
    content: "What?!";
    background-color: ${colors.purple};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%) rotate(12deg);
  }
  &:hover {
    b {
      transform: translate(-12%, -34%) rotate(-14deg);
    }
    &:before {
      transform: translate(-48%, -72%) rotate(14deg);
    }
  }
  &:active {
    b {
      transform: translate(-11%, -33%) rotate(-13deg) scale(0.95);
    }
    &:before {
      transform: translate(-47%, -71%) rotate(13deg) scale(0.95);
    }
  }
`;

const YDWWTWhatSticker = () => {
  return (
    <WhatSticker>
      <b>what?!</b>
    </WhatSticker>
  );
};

export default YDWWTWhatSticker;
