export const SearchIcon12 = () => (
  <svg
    className="Icon SearchIcon"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.11345 4.72339C8.11345 6.59567 6.59567 8.11345 4.72339 8.11345C2.85111 8.11345 1.33333 6.59567 1.33333 4.72339C1.33333 2.85111 2.85111 1.33333 4.72339 1.33333C6.59567 1.33333 8.11345 2.85111 8.11345 4.72339ZM7.55849 8.50166C6.76887 9.0951 5.7872 9.44679 4.72339 9.44679C2.11474 9.44679 0 7.33205 0 4.72339C0 2.11474 2.11474 0 4.72339 0C7.33205 0 9.44679 2.11474 9.44679 4.72339C9.44679 5.78739 9.09498 6.76922 8.50135 7.5589L11.8076 10.8651C12.0679 11.1255 12.0679 11.5476 11.8076 11.8079C11.5472 12.0683 11.1251 12.0683 10.8648 11.8079L7.55849 8.50166Z"
    />
  </svg>
);
