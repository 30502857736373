export const BrokenRobotIcon = () => (
  <svg
    width="130"
    height="73"
    viewBox="0 0 130 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1204 46.784C12.6132 41.5524 9.54743 37.8426 7.27283 38.4976C6.56883 38.7004 4.24683 39.588 3.61203 39.7708C1.33743 40.4262 0.715427 45.1984 2.22243 50.4302C3.72983 55.6618 6.79562 59.3714 9.07002 58.7164C9.77402 58.5136 12.096 57.6262 12.7308 57.4432C15.0056 56.7878 15.6276 52.016 14.1204 46.784Z"
      fill="#FBBD74"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M12.7257 57.4408C15.0002 56.7855 15.6225 52.0134 14.1154 46.7818C12.6084 41.5503 9.54286 37.8404 7.26832 38.4956C4.99378 39.1509 4.37158 43.923 5.8786 49.1546C7.38562 54.3861 10.4512 58.096 12.7257 57.4408Z"
      fill="#F0A848"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M9.10645 51.236C9.10645 51.236 10.2448 55.3934 14.388 55.2506C14.388 55.2506 15.4439 45.447 13.0151 40.7324C13.0151 40.7324 11.1734 41.678 10.3876 43.6782C9.60205 45.6786 9.10645 51.236 9.10645 51.236Z"
      fill="#332B48"
    />
    <path
      d="M18.9817 52.927C18.9021 52.927 18.8221 52.924 18.7411 52.9178C17.1989 52.7984 15.8227 51.581 14.8661 49.4894C14.1171 47.852 13.6559 45.7472 13.6007 43.7152C13.5737 42.7188 13.6385 41.7814 13.7889 40.913C10.1079 42.574 12.3545 50.1336 12.3789 50.2112C12.6619 51.115 12.1587 52.0766 11.2549 52.3596C10.3515 52.6428 9.38953 52.1394 9.10653 51.2358C9.07293 51.1284 8.28373 48.5758 8.25193 45.721C8.20533 41.5264 9.86833 38.6304 12.9347 37.5666C13.7429 37.2862 14.5293 37.132 15.2831 37.1024C16.3823 35.5368 17.9809 34.5406 19.9683 34.278C23.7401 33.7794 27.1599 35.6656 29.8561 39.7322C31.7415 42.576 32.6231 45.5178 32.6599 45.6414C32.9283 46.5494 32.4097 47.5032 31.5017 47.7716C30.5945 48.04 29.6415 47.5226 29.3721 46.6158C29.3431 46.5186 26.3973 36.8902 20.4173 37.6774C19.8725 37.7492 19.4131 37.9218 19.0261 38.1676C19.4659 38.4724 19.8781 38.842 20.2579 39.2744C22.9417 42.3306 23.3667 48.0142 21.9417 50.8642C21.2809 52.1864 20.2115 52.927 18.9817 52.927ZM17.2445 41.1138C17.1585 41.5078 17.1029 41.9032 17.0685 42.282C16.9195 43.924 17.1425 45.7532 17.6801 47.3008C18.0929 48.4892 18.5773 49.1418 18.8615 49.3904C19.4525 48.3862 19.6635 44.7724 18.2173 42.2904C17.9267 41.7916 17.6041 41.4 17.2445 41.1138Z"
      fill="#C1AAF7"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M109.888 62.8744C111.443 61.5506 111.045 60.4672 109.003 60.4672H47.1589C45.1165 60.4672 43.4453 62.1382 43.4453 64.1808V68.448C43.4453 70.4904 45.1165 72.1616 47.1589 72.1616H95.2627C97.3053 72.1616 100.249 71.0782 101.804 69.7544L109.888 62.8744Z"
      fill="#332B48"
    />
    <path
      d="M109.888 62.8744C111.443 61.5506 111.045 60.4672 109.003 60.4672H47.1589C45.1165 60.4672 43.4453 62.1382 43.4453 64.1808V68.448C43.4453 70.4904 45.1165 72.1616 47.1589 72.1616H95.2627C97.3053 72.1616 100.249 71.0782 101.804 69.7544L109.888 62.8744Z"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M97.9114 17.5078H42.9164C40.3192 17.5078 38.1622 18.7642 36.1958 20.1526C32.2794 22.9172 26.2128 27.1536 24.4276 28.9388C22.642 30.7244 21.5376 33.1908 21.5376 35.9154V52.4202C21.5376 57.8692 25.955 62.2864 31.4042 62.2864H36.2494C36.0888 62.7018 35.997 63.1518 35.997 63.624V68.4478C35.997 70.4986 37.6596 72.1614 39.7106 72.1614H78.0924C79.0826 72.1614 79.9818 71.7738 80.6476 71.1424C81.361 70.4656 91.5588 63.2044 92.2306 62.532C92.9024 61.86 93.3184 60.9314 93.3184 59.9062V59.4512C95.7412 57.7436 102.939 52.713 104.708 51.0304C106.599 49.233 107.778 46.6934 107.778 43.8786V27.3742C107.778 21.925 103.36 17.5078 97.9114 17.5078Z"
      fill="#8D75E6"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M86.3988 26.0492H31.4042C25.955 26.0492 21.5376 30.4664 21.5376 35.9154V52.4202C21.5376 57.8692 25.955 62.2864 31.4042 62.2864H36.2494C36.0888 62.7018 35.997 63.1518 35.997 63.624V68.4478C35.997 70.4986 37.6596 72.1614 39.7106 72.1614H78.0924C80.1436 72.1614 81.8062 70.4986 81.8062 68.4478V63.624C81.8062 63.152 81.7146 62.702 81.5538 62.2864H86.399C91.848 62.2864 96.2656 57.8692 96.2656 52.4202V35.9154C96.2654 30.4664 91.8478 26.0492 86.3988 26.0492Z"
      fill="#A88FEF"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M101.974 47.2406C103.66 47.2406 105.027 43.0285 105.027 37.8326C105.027 32.6367 103.66 28.4246 101.974 28.4246C100.287 28.4246 98.9204 32.6367 98.9204 37.8326C98.9204 43.0285 100.287 47.2406 101.974 47.2406Z"
      fill="#332B48"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M101.973 28.425C101.4 28.425 100.864 28.9128 100.405 29.76C101.295 31.404 101.891 34.4034 101.891 37.8328C101.891 41.2622 101.295 44.262 100.405 45.9058C100.864 46.7526 101.4 47.2408 101.973 47.2408C103.66 47.2408 105.027 43.0284 105.027 37.8328C105.027 32.6372 103.66 28.425 101.973 28.425Z"
      fill="#7561BF"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M62.5558 24.4542C69.4608 24.4542 75.0584 22.7915 75.0584 20.7406C75.0584 18.6896 69.4608 17.027 62.5558 17.027C55.6508 17.027 50.0532 18.6896 50.0532 20.7406C50.0532 22.7915 55.6508 24.4542 62.5558 24.4542Z"
      fill="#332B48"
    />
    <path
      d="M71.5098 19.9607C71.5098 22.0495 66.5218 23.7423 60.369 23.7423C54.2158 23.7423 49.228 22.0495 49.228 19.9607C49.228 17.8721 53.521 12.7039 60.369 12.7039C67.217 12.7039 71.5098 17.8721 71.5098 19.9607Z"
      fill="#FBBD74"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.9204 14.8951C57.9204 14.8951 62.5972 17.4396 68.7864 16.2016L65.3206 13.4781C65.3206 13.4781 62.872 15.3901 57.9204 14.8951Z"
      fill="#332B48"
    />
    <path
      d="M60.3693 15.3508C64.3321 15.3508 67.5447 12.1383 67.5447 8.1754C67.5447 4.21253 64.3321 1 60.3693 1C56.4064 1 53.1938 4.21253 53.1938 8.1754C53.1938 12.1383 56.4064 15.3508 60.3693 15.3508Z"
      fill="#FBBD74"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.247 54.4647C46.0859 54.4647 50.8192 49.7314 50.8192 43.8925C50.8192 38.0537 46.0859 33.3203 40.247 33.3203C34.4081 33.3203 29.6748 38.0537 29.6748 43.8925C29.6748 49.7314 34.4081 54.4647 40.247 54.4647Z"
      fill="#332B48"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.7161 51.8954C45.779 51.8954 50.6939 46.9805 50.6939 40.9176C50.6939 34.8548 45.779 29.9398 39.7161 29.9398C33.6532 29.9398 28.7383 34.8548 28.7383 40.9176C28.7383 46.9805 33.6532 51.8954 39.7161 51.8954Z"
      fill="#7561BF"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.8582 53.6099C43.9211 53.6099 48.836 48.695 48.836 42.6321C48.836 36.5692 43.9211 31.6543 37.8582 31.6543C31.7953 31.6543 26.8804 36.5692 26.8804 42.6321C26.8804 48.695 31.7953 53.6099 37.8582 53.6099Z"
      fill="#C1AAF7"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.8584 50.0648C41.9633 50.0648 45.291 46.7371 45.291 42.6322C45.291 38.5273 41.9633 35.1996 37.8584 35.1996C33.7535 35.1996 30.4258 38.5273 30.4258 42.6322C30.4258 46.7371 33.7535 50.0648 37.8584 50.0648Z"
      fill="#FBBD74"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M39.1069 50.364L40.0681 53.2472C40.0681 53.2472 45.8355 51.926 47.6377 47.48L44.8743 45.798C44.8743 45.798 41.9905 49.2822 39.1069 50.364Z"
      fill="#332B48"
    />
    <path
      d="M77.6791 54.4647C83.518 54.4647 88.2513 49.7314 88.2513 43.8925C88.2513 38.0537 83.518 33.3203 77.6791 33.3203C71.8403 33.3203 67.1069 38.0537 67.1069 43.8925C67.1069 49.7314 71.8403 54.4647 77.6791 54.4647Z"
      fill="#332B48"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M77.1472 51.8954C83.2101 51.8954 88.125 46.9805 88.125 40.9176C88.125 34.8548 83.2101 29.9398 77.1472 29.9398C71.0844 29.9398 66.1694 34.8548 66.1694 40.9176C66.1694 46.9805 71.0844 51.8954 77.1472 51.8954Z"
      fill="#7561BF"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.2903 53.6099C81.3532 53.6099 86.2681 48.695 86.2681 42.6321C86.2681 36.5692 81.3532 31.6543 75.2903 31.6543C69.2274 31.6543 64.3125 36.5692 64.3125 42.6321C64.3125 48.695 69.2274 53.6099 75.2903 53.6099Z"
      fill="#C1AAF7"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.2905 50.0648C79.3954 50.0648 82.7231 46.7371 82.7231 42.6322C82.7231 38.5273 79.3954 35.1996 75.2905 35.1996C71.1856 35.1996 67.8579 38.5273 67.8579 42.6322C67.8579 46.7371 71.1856 50.0648 75.2905 50.0648Z"
      fill="#FBBD74"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M76.5391 50.364L77.5003 53.2472C77.5003 53.2472 83.2677 51.926 85.0697 47.48L82.3065 45.798C82.3065 45.798 79.4227 49.2822 76.5391 50.364Z"
      fill="#332B48"
    />
    <path
      d="M78.0811 66.285C78.0811 67.6522 76.9727 68.7608 75.6053 68.7608H42.0591C40.6919 68.7608 39.5835 67.6522 39.5835 66.285V62.9426C39.5835 61.5754 40.6919 60.4668 42.0591 60.4668H75.6055C76.9729 60.4668 78.0813 61.5754 78.0813 62.9426V66.285H78.0811Z"
      fill="#D7C4FA"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M81.7945 65.9138L88.2313 62.2L81.5537 62.2864L81.7945 65.9138Z"
      fill="#332B48"
    />
    <path
      d="M44.1045 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.2476 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.3911 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M56.5337 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M60.6772 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.8203 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M68.9634 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M73.1064 60.6086V68.4662"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.2881 55.1152L61.1779 60.3716L65.4635 55.3576L49.2881 55.1152Z"
      fill="#332B48"
    />
    <path
      d="M57.2307 45.5101L49.2881 55.1153L56.1961 57.0071L65.1731 55.1153L57.2307 45.5101Z"
      fill="#7561BF"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.2309 45.5101L56.1963 57.0071"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M114.796 35.8922C114.011 35.5504 113.239 35.3364 112.49 35.2486C111.515 33.603 109.997 32.4868 108.036 32.072C104.828 31.393 101.746 32.5018 99.0348 35.2822C98.9608 36.0938 98.9204 36.9482 98.9204 37.8328C98.9204 38.9036 98.9792 39.9312 99.0862 40.8902C100.667 38.2012 103.539 34.6252 107.327 35.4266C107.864 35.5402 108.309 35.7476 108.676 36.0224C108.214 36.2926 107.774 36.629 107.363 37.0312C104.451 39.8716 103.59 45.5058 104.791 48.457C105.385 49.9146 106.49 50.7506 107.824 50.7506C111.017 50.7506 113.213 46.2274 113.658 41.9716C113.762 40.9802 113.769 40.0406 113.686 39.1632C117.208 41.096 114.405 48.4686 114.376 48.5424C114.024 49.4216 114.452 50.4194 115.331 50.771C115.54 50.8544 115.755 50.894 115.967 50.894C116.648 50.894 117.292 50.4862 117.56 49.8158C117.602 49.7116 118.585 47.2272 118.837 44.3832C119.206 40.2048 117.771 37.1894 114.796 35.8922ZM109.314 45.2324C108.811 46.3854 108.278 46.9986 107.976 47.2248C107.464 46.1778 107.532 42.5588 109.165 40.1954C109.493 39.7206 109.845 39.355 110.225 39.0974C110.281 39.4966 110.306 39.8954 110.311 40.2762C110.333 41.9244 109.97 43.7308 109.314 45.2324Z"
      fill="#C1AAF7"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M98.667 34.776L100.167 33.6332L100.881 38.205L99.1778 40.946L98.667 34.776Z"
      fill="#332B48"
    />
    <path
      d="M107.908 36.5504C107.908 36.5504 110.277 34.7876 112.904 35.31"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M109.538 39.7135C109.538 39.7135 111.324 37.2494 114.255 39.569"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M110.361 39.2259L110.553 40.6674C110.553 40.6674 113.02 41.2762 113.532 42.205L113.757 39.354C113.757 39.354 111.995 38.2649 110.361 39.2259Z"
      fill="#332B48"
    />
    <path
      d="M126.57 37.4297C126.01 37.1125 124.13 35.9281 123.553 35.6005C122.042 34.7449 118.579 38.0023 115.817 42.8761C113.055 47.7501 112.04 52.3951 113.551 53.2509C114.11 53.5681 115.99 54.7525 116.568 55.0801C118.078 55.9355 121.542 52.6783 124.304 47.8045C127.066 42.9305 128.08 38.2853 126.57 37.4297Z"
      fill="#F0A848"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M116.568 55.0872C118.078 55.9432 121.542 52.686 124.305 47.812C127.067 42.9381 128.082 38.293 126.572 37.437C125.062 36.581 121.598 39.8382 118.835 44.7122C116.073 49.5862 115.058 54.2312 116.568 55.0872Z"
      fill="#FBBD74"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.8544 36.3922C15.8544 36.3922 13.8666 38.5918 13.6274 42.287"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.0429 42.6323C17.0429 42.6323 16.7663 38.707 19.8723 37.7906"
      stroke="#332B48"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.9588 42.8492C42.9588 43.4844 42.444 43.9992 41.8088 43.9992H33.9088C33.2736 43.9992 32.7588 43.4844 32.7588 42.8492C32.7588 42.214 33.2736 41.6992 33.9088 41.6992H41.8088C42.444 41.6992 42.9588 42.214 42.9588 42.8492Z"
      fill="#332B48"
    />
    <path
      d="M80.5584 42.8492C80.5584 43.4844 80.0436 43.9992 79.4084 43.9992H71.5084C70.8732 43.9992 70.3584 43.4844 70.3584 42.8492C70.3584 42.214 70.8732 41.6992 71.5084 41.6992H79.4084C80.0436 41.6992 80.5584 42.214 80.5584 42.8492Z"
      fill="#332B48"
    />
  </svg>
);
