export const ChevronIconDown = () => (
  <svg
    className="ChevronIconDown"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M9.2903 10.2294C9.00873 9.92619 8.53465 9.90863 8.23142 10.1902C7.92819 10.4718 7.91063 10.9458 8.1922 11.2491L11.439 14.7456C11.5807 14.8982 11.7797 14.985 11.988 14.985C12.1963 14.985 12.3953 14.8982 12.537 14.7456L15.7838 11.2491C16.0654 10.9458 16.0478 10.4718 15.7446 10.1902C15.4413 9.90863 14.9673 9.92619 14.6857 10.2294L11.988 13.1346L9.2903 10.2294Z" />
  </svg>
);
