import { styled } from "@linaria/react";
import {
  fromDesktopMd,
  fromTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import MediaMentionLink from "../general/MediaMentionLink";
import { graphql, useStaticQuery } from "gatsby";
import {
  AllMediaMentionsQuery,
  DatoCmsMediaMention,
} from "../../../graphql-types";

const TinesInThePressSectionInner = styled.div`
  text-align: center;
`;

const PressEntryGrid = styled.div`
  display: grid;
  grid-gap: 2.4em ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromDesktopMd} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TinesInThePressSection = () => {
  const data = useStaticQuery<AllMediaMentionsQuery>(allMediaMentionsQuery);
  return (
    <div id="tines-in-the-press">
      <TinesInThePressSectionInner>
        <PressEntryGrid>
          {data.mediaMentions.nodes.map(mm => (
            <MediaMentionLink
              key={mm.originalId}
              mediaMention={mm as DatoCmsMediaMention}
            />
          ))}
        </PressEntryGrid>
      </TinesInThePressSectionInner>
    </div>
  );
};

export const allMediaMentionsQuery = graphql`
  query AllMediaMentions {
    mediaMentions: allDatoCmsMediaMention(sort: { position: ASC }) {
      nodes {
        originalId
        heading
        url
        mediaName
        mediaLogo {
          url
        }
      }
    }
  }
`;

export default TinesInThePressSection;
