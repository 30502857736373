import { styled } from "@linaria/react";
import PageSection from "./PageSection";
import { serif } from "../../styles/fonts.styles";
import { colors } from "../../styles/colors.styles";
import { rSize } from "../../styles/responsiveSizes.styles";
import { widthInGrid } from "../../constants/globalGrid.constants";
import Button from "../forms/Button";
import { Link } from "gatsby";
import { css } from "linaria";
import Spacing from "../layout/Spacing";
import {
  fromDesktopMd,
  fromDesktopMl,
  fromTablet,
  fromTabletLg,
  mediaFromTo,
  onlyPhones,
  uptoTablet,
  uptoTabletLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import imgAssetManagement from "../../../static/images/library/icons/tines-spot-icon-asset-management.svg";
import imgCoreCapabilities from "../../../static/images/library/icons/tines-spot-icon-core-capabilities.svg";
import imgIncidentsAndAlerts from "../../../static/images/library/icons/tines-spot-icon-incidents-and-alerts.svg";
import imgThreatIntelligence from "../../../static/images/library/icons/tines-spot-icon-threat-intel.svg";
import imgTicketSyncronization from "../../../static/images/library/icons/tines-spot-icon-ticket-syncronization.svg";

const Inner = styled.div`
  background-color: ${colors.light100};
  border: 1px solid ${colors.light300};
  border-radius: ${rSize("radius")};
  padding: ${rSize("xl")} ${rSize("gap")};
  ${fromTabletLg} {
    padding: ${rSize("xl")} ${widthInGrid(1, 1)} ${widthInGrid(1, 1)};
  }
`;

const Header = styled.header`
  ${onlyPhones} {
    text-align: center;
  }
  ${fromTablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${uptoTabletLg} {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  h2 {
    font-family: ${serif};
    font-size: 2.8rem;
    font-weight: 400;
    ${uptoTablet} {
      margin-bottom: 0.75em;
    }
    ${mediaFromTo("tablet", "desktopMd")} {
      margin-right: 0.75em;
    }
    ${fromDesktopMd} {
      font-weight: 300;
      font-size: 3.6rem;
      letter-spacing: -0.02em;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  ${fromTabletLg} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${rSize("gap")};
  }
  ${fromDesktopMl} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const libraryCollectionCard = css`
  border-radius: ${rSize("radius")};
  padding: 1em;
  text-decoration: none;
  color: ${colors.white};
  font-family: ${serif};
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(auto, 1fr) auto;
  font-size: 1.8rem;
  border-width: 1px;
  border-style: solid;
  ${mediaFromTo("desktopMl", "desktopLg")} {
    font-size: 1.6rem;
  }
  p {
    line-height: 1.2;
  }
  img {
    max-width: 86px;
    align-self: end;
    justify-self: end;
    margin-top: 2em;
  }
  &:hover {
    filter: brightness(1.05);
  }
`;

const LibrarySuggestionsSection = () => {
  return (
    <PageSection>
      <Inner>
        <Header>
          <h2>See more practical examples</h2>
          <Button appearance="outlined" darker to="/library">
            Visit library →
          </Button>
        </Header>
        <Spacing size="lg" />
        <Grid>
          <Link
            className={libraryCollectionCard}
            to="/library/use-cases/incidents-and-alerts/"
            style={{
              backgroundColor: colors.red,
              borderColor: colors.red600,
            }}
          >
            <p>Incidents and alerts</p>
            <img src={imgIncidentsAndAlerts} />
          </Link>
          <Link
            className={libraryCollectionCard}
            to="/library/use-cases/asset-and-app-management/"
            style={{
              backgroundColor: colors.pink,
              borderColor: colors.pink600,
            }}
          >
            <p>Asset and app management</p>
            <img src={imgAssetManagement} />
          </Link>

          <Link
            className={libraryCollectionCard}
            to="/library/use-cases/ticket-synchronization/"
            style={{
              backgroundColor: colors.green,
              borderColor: colors.green600,
            }}
          >
            <p>Ticket synchronization</p>
            <img src={imgTicketSyncronization} />
          </Link>
          <Link
            className={libraryCollectionCard}
            to="/library/use-cases/core-capabilities/"
            style={{
              backgroundColor: colors.purple,
              borderColor: colors.purple600,
            }}
          >
            <p>Core capabilities</p>
            <img src={imgCoreCapabilities} />
          </Link>
          <Link
            className={libraryCollectionCard}
            to="/library/threat-intelligence/"
            style={{
              backgroundColor: colors.orange,
              borderColor: colors.orange600,
            }}
          >
            <p>Threat intelligence</p>
            <img src={imgThreatIntelligence} />
          </Link>
        </Grid>
      </Inner>
    </PageSection>
  );
};

export default LibrarySuggestionsSection;
