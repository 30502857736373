export const StarTreeIcon = () => (
  <svg
    width="96"
    height="99"
    viewBox="0 0 96 99"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M55.2265 32.7486C54.6589 32.7463 54.0993 32.6045 53.5996 32.335L48.3511 29.5278V29.5273C48.1231 29.4088 47.8521 29.4088 47.6241 29.5273L42.3357 32.2618C41.5688 32.6599 40.6803 32.756 39.8462 32.5305C39.0127 32.305 38.2938 31.7752 37.8319 31.0444C37.37 30.314 37.1997 29.4373 37.354 28.5872L38.4008 22.7251H38.4005C38.4371 22.4803 38.3553 22.2327 38.1804 22.0578L33.9457 17.8694C33.3321 17.2636 32.9678 16.4489 32.9251 15.5871C32.8824 14.7256 33.1638 13.8793 33.7149 13.2155C34.265 12.5515 35.0447 12.1179 35.8996 12.0007L41.7948 11.1869C42.0513 11.1506 42.2732 10.9917 42.3886 10.7604L45.0558 5.42499H45.0563C45.3392 4.84946 45.7783 4.36544 46.323 4.02708C46.8676 3.68898 47.4962 3.50976 48.1373 3.51078C48.7868 3.50773 49.4236 3.68949 49.9738 4.03394C50.5239 4.37891 50.9647 4.87284 51.2456 5.45829L53.8398 10.7939L53.8393 10.7934C53.9514 11.0265 54.1713 11.1885 54.4265 11.2271L60.3087 12.1275C61.1634 12.2567 61.938 12.7013 62.4799 13.3742C63.0214 14.0468 63.2911 14.8987 63.2347 15.7607C63.1785 16.6235 62.8008 17.4329 62.1762 18.0298L57.8879 22.1516C57.7117 22.3255 57.6276 22.5728 57.6614 22.8181L58.6286 28.6937C58.8056 29.6994 58.529 30.7317 57.8729 31.5144C57.2162 32.2964 56.2474 32.7481 55.2265 32.7486Z"
      fill="#B35980"
      stroke="#32313B"
    />
    <path
      d="M58.8268 47.1569C58.2506 46.7228 57.8962 46.0565 57.8578 45.336C57.8199 44.6156 58.1023 43.916 58.6293 43.4231L61.6704 40.518C61.7937 40.3939 61.8514 40.2185 61.8255 40.0456L61.1557 35.8984C61.0565 35.2962 61.1872 34.6787 61.522 34.1685C61.8558 33.6576 62.3698 33.2918 62.9611 33.1418C63.5529 32.992 64.179 33.0701 64.7162 33.3601L68.418 35.3501C68.5786 35.4365 68.7711 35.4386 68.9335 35.3547H68.933L72.663 33.445C73.0703 33.2338 73.5291 33.1423 73.986 33.1812C74.4428 33.2203 74.8793 33.3881 75.2446 33.6657C75.6068 33.9372 75.8854 34.3058 76.0491 34.7278C76.2129 35.15 76.2553 35.6097 76.1706 36.0548H76.1712L75.4103 40.1989C75.377 40.3787 75.4352 40.5625 75.5641 40.6918L78.5457 43.6547C78.9782 44.0838 79.2344 44.6598 79.2624 45.2681C79.2913 45.8767 79.0916 46.4741 78.7013 46.9419C78.3116 47.4101 77.76 47.7152 77.1557 47.7968L72.9874 48.3622C72.814 48.3868 72.663 48.4915 72.5797 48.6451H72.5794L70.681 52.398C70.4067 52.9435 69.9384 53.366 69.3675 53.5821C68.7965 53.7982 68.1655 53.7921 67.5994 53.5651C67.0328 53.3383 66.5724 52.9069 66.3085 52.3563L64.4853 48.5671C64.4068 48.4033 64.254 48.2884 64.0748 48.2584V48.2587L59.92 47.614C59.5241 47.554 59.1482 47.3967 58.8268 47.1569Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M68.1381 73.6915C67.4345 73.2423 66.9655 72.5054 66.8566 71.6779C66.7486 70.8497 67.0115 70.0172 67.5756 69.4012L70.8244 65.7764C70.9558 65.6226 71.0064 65.4144 70.9611 65.2174L69.8167 60.4921C69.6484 59.806 69.7435 59.082 70.0841 58.463C70.4237 57.8437 70.984 57.3752 71.6529 57.1497C72.3222 56.9237 73.0515 56.9578 73.6975 57.2443L78.1474 59.2091C78.3406 59.294 78.5628 59.279 78.7431 59.1679H78.7426L82.8753 56.6304C83.3262 56.3503 83.8471 56.2028 84.3779 56.2071C84.9084 56.2117 85.4275 56.366 85.8737 56.6538C86.316 56.9344 86.6704 57.3345 86.8971 57.8066C87.1242 58.2795 87.2139 58.8062 87.1564 59.3273H87.1569L86.6503 64.1771C86.6282 64.3874 86.7111 64.5943 86.872 64.7321L90.5784 67.8836C91.1155 68.3399 91.4628 68.9815 91.55 69.6811C91.6384 70.3804 91.4602 71.0879 91.0525 71.6629C90.6447 72.2382 90.0354 72.6396 89.3457 72.7875L84.5861 73.8138C84.3888 73.8575 84.2233 73.9917 84.141 74.1768L84.1412 74.1766L82.2865 78.6771C82.0193 79.3309 81.5165 79.8602 80.8774 80.1612C80.2376 80.4617 79.5092 80.5112 78.8358 80.2997C78.1622 80.0893 77.5915 79.6324 77.2379 79.0208L74.7949 74.8118C74.6897 74.6301 74.5023 74.5108 74.2931 74.4923V74.493L69.4415 74.1214C68.978 74.0873 68.5304 73.9394 68.1381 73.6915Z"
      fill="#B38847"
      stroke="#32313B"
    />
    <path
      d="M26.3151 74.6021C25.8962 74.8017 25.4338 74.8952 24.9701 74.8739L20.1085 74.6664V74.6656C19.8988 74.6595 19.6987 74.7557 19.5727 74.9234L16.6467 78.8121C16.2227 79.3772 15.6021 79.7628 14.9079 79.8925C14.2141 80.0221 13.4965 79.8861 12.8973 79.5117C12.2987 79.1372 11.8622 78.552 11.6743 77.8708L10.3677 73.1818L10.3679 73.1821C10.3082 72.9886 10.16 72.8358 9.96907 72.7687L5.36531 71.1845C4.69826 70.9554 4.14103 70.4844 3.8042 69.8649C3.46762 69.2456 3.37508 68.5224 3.54566 67.8381C3.71547 67.154 4.13644 66.5581 4.72418 66.1689L8.77885 63.4801C8.95476 63.3622 9.06179 63.1672 9.06484 62.9557L9.1383 58.0799H9.13882C9.14365 57.5555 9.29516 57.0432 9.57683 56.6009C9.85824 56.1591 10.2576 55.804 10.7302 55.5777C11.2073 55.3454 11.7412 55.2536 12.2684 55.3121C12.7959 55.371 13.2957 55.5792 13.7103 55.9107L17.512 58.9216H17.5115C17.677 59.0535 17.8961 59.095 18.098 59.0332L22.7501 57.6111C23.4252 57.4035 24.1535 57.4563 24.7911 57.7601C25.4284 58.0634 25.9292 58.5952 26.1928 59.2505C26.4575 59.9059 26.4658 60.636 26.2172 61.2972L24.5191 65.8532C24.4507 66.0433 24.4767 66.2558 24.5885 66.4244L27.3836 70.4094C27.8704 71.0879 28.0326 71.9464 27.8266 72.7555C27.6205 73.5637 27.0671 74.2396 26.3151 74.6021Z"
      fill="#6359B3"
      stroke="#32313B"
    />
    <path
      d="M47.7568 87.8572C46.4674 87.8572 45.3624 87.5542 45.2304 86.2435C44.4932 78.9161 41.9028 73.6476 37.5311 70.5846C30.2622 65.491 20.3508 67.9217 20.2511 67.9469C18.8901 68.2916 17.5074 67.4677 17.163 66.1069C16.8183 64.7459 17.6419 63.3632 19.0029 63.0185C19.5004 62.8924 31.293 60.0053 40.4487 66.4211C46.0863 70.3713 49.397 76.8692 50.289 85.7348C50.4298 87.1317 49.4109 87.7039 48.0143 87.8445C47.9281 87.8531 47.8419 87.8572 47.7568 87.8572Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M37.8928 47.7621C37.5715 48.0018 37.1958 48.1592 36.7989 48.2194L32.6444 48.8641L32.6441 48.8639C32.4652 48.8939 32.3119 49.0088 32.2336 49.1725L30.4104 52.9617C30.1468 53.5121 29.6859 53.9438 29.1195 54.1708C28.5534 54.3978 27.9224 54.4036 27.3514 54.1875C26.7807 53.9712 26.3122 53.5487 26.0382 53.0034L24.1395 49.2505C24.0561 49.097 23.9049 48.992 23.7317 48.9676L19.5632 48.4022C18.9592 48.3206 18.4073 48.0158 18.0176 47.5475C17.6276 47.0795 17.4273 46.4822 17.4565 45.8736C17.4847 45.265 17.741 44.6892 18.1732 44.2601L21.1548 41.2973C21.2842 41.1676 21.3419 40.9838 21.3086 40.8043L20.548 36.6602H20.5485C20.4636 36.2151 20.5058 35.7555 20.6698 35.3332C20.8337 34.911 21.1123 34.5429 21.4746 34.2711C21.8399 33.9935 22.2764 33.8257 22.7329 33.7866C23.19 33.7477 23.6489 33.8392 24.0558 34.0507L27.7861 35.9603H27.7856C27.9481 36.0442 28.1405 36.0422 28.3009 35.9558L32.003 33.9656C32.5399 33.6758 33.166 33.5975 33.7578 33.7472C34.3491 33.8972 34.8631 34.2627 35.1972 34.7737C35.5317 35.2842 35.6624 35.9016 35.5632 36.5038L34.8931 40.6513C34.8677 40.8242 34.9252 40.9996 35.0484 41.1234L38.0896 44.0285C38.6166 44.5212 38.8992 45.221 38.8614 45.9414C38.824 46.6616 38.4694 47.3279 37.8928 47.7621Z"
      fill="#B38847"
      stroke="#32313B"
    />
    <path
      d="M55.5136 97.7858C70.4642 97.7858 82.584 96.3252 82.584 94.5233C82.584 92.7214 70.4642 91.2607 55.5136 91.2607C40.563 91.2607 28.4431 92.7214 28.4431 94.5233C28.4431 96.3252 40.563 97.7858 55.5136 97.7858Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M70.1088 88.6507C70.1088 86.5799 60.1191 84.9009 47.7959 84.9009C35.4728 84.9009 25.4826 86.5797 25.4826 88.6507C25.4826 89.6729 25.4826 92.879 25.4826 93.9012C25.4826 95.972 35.4728 97.6513 47.7959 97.6513C60.1191 97.6513 70.1088 95.9723 70.1088 93.9012C70.1088 92.879 70.1088 89.6732 70.1088 88.6507Z"
      fill="#36996F"
      stroke="#32313B"
    />
    <path
      d="M47.7954 92.4017C60.1186 92.4017 70.1086 90.7224 70.1086 88.651C70.1086 86.5796 60.1186 84.9004 47.7954 84.9004C35.4722 84.9004 25.4823 86.5796 25.4823 88.651C25.4823 90.7224 35.4722 92.4017 47.7954 92.4017Z"
      fill="#81D8B4"
      stroke="#32313B"
    />
    <path
      d="M48.0821 60.5871C47.8442 60.5871 47.6027 60.5535 47.3627 60.4838C46.0062 60.0868 45.2283 58.6657 45.6249 57.309C48.2405 48.3648 53.7559 44.5967 57.9221 43.0104C62.4313 41.2937 66.3546 41.7376 66.519 41.7569C67.9225 41.9249 68.9241 43.1988 68.7561 44.602C68.5886 46.0014 67.3206 47.0012 65.9229 46.8406C65.4272 46.7862 54.3365 45.7538 50.5373 58.7456C50.2109 59.8621 49.1897 60.5868 48.0821 60.5871Z"
      fill="#2A7857"
      stroke="#32313B"
      strokeMiterlimit="10"
    />
    <path
      d="M47.7955 88.7273C51.1474 88.7273 53.8648 88.08 53.8648 87.2814C53.8648 86.4828 51.1474 85.8354 47.7955 85.8354C44.4435 85.8354 41.7262 86.4828 41.7262 87.2814C41.7262 88.08 44.4435 88.7273 47.7955 88.7273Z"
      fill="#32313B"
    />
    <path
      d="M50.6406 58.0667V28.6707C50.6406 27.2573 49.4948 26.1113 48.0814 26.1113C46.668 26.1113 45.5223 27.2573 45.5223 28.6707V47.9867C43.1492 45.3711 40.497 43.8693 38.2404 43.0103C33.7315 41.2938 29.8082 41.7372 29.6435 41.7567C28.2456 41.9243 27.2526 43.1882 27.412 44.5874C27.5717 45.9861 28.8386 46.9925 30.2378 46.8405C30.7056 46.7901 41.626 45.7585 45.5223 58.4089V85.7477C45.5223 87.1612 46.668 86.729 48.0814 86.729C49.4948 86.729 50.6406 87.1609 50.6406 85.7477V58.0736C50.6406 58.0713 50.6406 58.0692 50.6406 58.0667Z"
      fill="#36996F"
      stroke="#32313B"
    />
    <path
      d="M55.2265 30.5455C54.6589 30.5432 54.0993 30.4011 53.5996 30.1317L48.3511 27.3244V27.3239C48.1231 27.2052 47.8521 27.2052 47.6241 27.3239L42.3357 30.0584C41.5688 30.4565 40.6803 30.5524 39.8462 30.3269C39.0127 30.1017 38.2938 29.5714 37.8319 28.8413C37.37 28.1104 37.1997 27.2336 37.354 26.3838L38.4008 20.5217H38.4005C38.4371 20.2772 38.3553 20.0296 38.1804 19.8544L33.9457 15.6663C33.3321 15.06 32.9678 14.2455 32.9251 13.384C32.8824 12.5224 33.1638 11.6759 33.7149 11.0124C34.265 10.3479 35.0447 9.91475 35.8996 9.79705L41.7948 8.98357C42.0513 8.94722 42.2732 8.78808 42.3886 8.557L45.0558 3.22137H45.0563C45.3392 2.64609 45.7783 2.16182 46.323 1.82347C46.8676 1.48536 47.4962 1.3064 48.1373 1.30716C48.7868 1.30436 49.4236 1.48587 49.9738 1.83058C50.5239 2.17554 50.9647 2.66922 51.2456 3.25492L53.8398 8.59031L53.8393 8.5898C53.9514 8.82316 54.1713 8.98485 54.4265 9.02349L60.3087 9.92416C61.1634 10.053 61.938 10.4977 62.4799 11.1711C63.0214 11.8437 63.2911 12.6956 63.2347 13.5573C63.1785 14.4199 62.8008 15.2295 62.1762 15.8264L57.8879 19.948C57.7117 20.1218 57.6276 20.3689 57.6614 20.6145L58.6286 26.4898C58.8056 27.4957 58.529 28.5281 57.8729 29.3103C57.2162 30.0933 56.2474 30.5447 55.2265 30.5455Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M35.6901 46.4065C35.3685 46.6464 34.9928 46.8035 34.596 46.8638L30.4417 47.5082H30.4412C30.2625 47.5385 30.1092 47.6531 30.0309 47.8168L28.2074 51.6058C27.9438 52.1565 27.4832 52.5881 26.9165 52.8151C26.3504 53.0421 25.7195 53.0482 25.1488 52.8319C24.5781 52.6156 24.1093 52.1931 23.8353 51.648L21.9368 47.8949H21.9365C21.8534 47.7411 21.7022 47.6363 21.5288 47.6119L17.3602 47.0465C16.7565 46.9649 16.2046 46.6599 15.8149 46.1919C15.4249 45.7239 15.2246 45.1265 15.2536 44.5179C15.2818 43.9093 15.5381 43.3336 15.9705 42.9044L18.9521 39.9414C19.0818 39.812 19.1392 39.6282 19.1062 39.4484L18.3453 35.3045H18.3458C18.2612 34.8592 18.3034 34.3998 18.4673 33.9773C18.6313 33.5551 18.9099 33.1867 19.2719 32.9152C19.6375 32.6376 20.0737 32.4698 20.5305 32.4307C20.9873 32.3915 21.4462 32.4833 21.8534 32.6946L25.5835 34.6042H25.583C25.7454 34.6883 25.9378 34.6861 26.0985 34.5996L29.8006 32.6097C30.3375 32.3196 30.9636 32.2416 31.5551 32.3913C32.1464 32.541 32.6607 32.9068 32.9945 33.4178C33.3293 33.928 33.4599 34.5455 33.3608 35.1477L32.6907 39.2949C32.665 39.468 32.7227 39.6432 32.846 39.7672L35.8871 42.6723C36.4141 43.165 36.6968 43.8649 36.6587 44.5853C36.621 45.3062 36.2664 45.9723 35.6901 46.4065Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M23.773 73.5855C23.3541 73.7851 22.8916 73.8786 22.428 73.8573L17.5664 73.6498V73.649C17.3567 73.6429 17.1566 73.739 17.0305 73.9068L14.1046 77.7955C13.6805 78.3606 13.06 78.7462 12.3658 78.8759C11.672 79.0055 10.9544 78.8695 10.3552 78.4951C9.75654 78.1206 9.32006 77.5354 9.1322 76.8542L7.82555 72.1652L7.82582 72.1655C7.76608 71.972 7.61786 71.8192 7.42695 71.7521L2.82319 70.1679C2.15614 69.9388 1.59891 69.4678 1.26208 68.8483C0.925508 68.229 0.832968 67.5058 1.00354 66.8215C1.17336 66.1374 1.59433 65.5415 2.18206 65.1523L6.23673 62.4635C6.41265 62.3456 6.51967 62.1506 6.52272 61.9391L6.59619 57.0633H6.5967C6.60153 56.5389 6.75305 56.0266 7.03471 55.5843C7.31612 55.1425 7.71549 54.7874 8.18807 54.5611C8.66522 54.3288 9.19905 54.237 9.72629 54.2955C10.2538 54.3544 10.7536 54.5626 11.1682 54.8941L14.9699 57.905H14.9694C15.1349 58.0369 15.354 58.0784 15.5559 58.0166L20.2079 56.5945C20.8831 56.3869 21.6114 56.4397 22.249 56.7435C22.8863 57.0468 23.3871 57.5786 23.6507 58.2339C23.9153 58.8893 23.9237 59.6194 23.6751 60.2806L21.977 64.8366C21.9086 65.0267 21.9345 65.2392 22.0464 65.4078L24.8414 69.3928C25.3283 70.0713 25.4904 70.9298 25.2845 71.7389C25.0784 72.547 24.5249 73.223 23.773 73.5855Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M61.1147 46.3943C60.5384 45.9601 60.184 45.2938 60.1457 44.5733C60.1078 43.8529 60.3902 43.1533 60.9172 42.6604L63.9583 39.7553C64.0816 39.6312 64.1393 39.4558 64.1134 39.2829L63.4435 35.1357C63.3444 34.5335 63.4751 33.916 63.8099 33.4058C64.1436 32.8949 64.6577 32.5291 65.249 32.3791C65.8408 32.2293 66.4669 32.3074 67.004 32.5974L70.7059 34.5874C70.8665 34.6738 71.0589 34.6759 71.2214 34.592H71.2209L74.9509 32.6823C75.3582 32.4711 75.817 32.3796 76.2738 32.4185C76.7306 32.4576 77.1671 32.6254 77.5324 32.903C77.8947 33.1745 78.1733 33.5431 78.337 33.9651C78.5007 34.3873 78.5432 34.847 78.4585 35.2921H78.459L77.6982 39.4362C77.6649 39.616 77.7231 39.7998 77.852 39.9292L80.8336 42.892C81.266 43.3211 81.5223 43.8971 81.5502 44.5054C81.5792 45.114 81.3794 45.7114 80.9892 46.1792C80.5995 46.6474 80.0478 46.9525 79.4436 47.0341L75.2753 47.5995C75.1019 47.6241 74.9509 47.7288 74.8675 47.8824H74.8673L72.9688 51.6353C72.6945 52.1808 72.2263 52.6033 71.6553 52.8194C71.0844 53.0355 70.4534 53.0294 69.8873 52.8024C69.3206 52.5756 68.8603 52.1442 68.5964 51.5936L66.7732 47.8044C66.6946 47.6406 66.5419 47.5257 66.3626 47.4957V47.496L62.2078 46.8513C61.812 46.7913 61.436 46.634 61.1147 46.3943Z"
      fill="#8173E6"
      stroke="#32313B"
    />
    <path
      d="M48.2073 87.8572C49.4966 87.8572 50.6017 87.5542 50.7336 86.2435C51.4708 78.9161 54.0612 73.6476 58.4329 70.5846C65.7018 65.491 75.6133 67.9217 75.7129 67.9469C77.074 68.2916 78.4566 67.4677 78.8011 66.1069C79.1458 64.7459 78.3221 63.3632 76.9611 63.0185C76.4636 62.8924 64.671 60.0053 55.5153 66.4211C49.8777 70.3713 46.5671 76.8692 45.6751 85.7348C45.5342 87.1317 46.5531 87.7039 47.9497 87.8445C48.0359 87.8531 48.1221 87.8572 48.2073 87.8572Z"
      fill="#36996F"
      stroke="#32313B"
    />
    <path
      d="M70.9345 72.9288C70.2308 72.4796 69.7618 71.7427 69.653 70.9152C69.545 70.087 69.8078 69.2545 70.3719 68.6385L73.6207 65.0137C73.7522 64.8599 73.8028 64.6517 73.7575 64.4547L72.613 59.7294C72.4448 59.0433 72.5398 58.3193 72.8805 57.7003C73.2201 57.081 73.7804 56.6125 74.4492 56.387C75.1186 56.1611 75.8479 56.1951 76.4938 56.4816L80.9438 58.4464C81.137 58.5313 81.3592 58.5163 81.5394 58.4052H81.5389L85.6716 55.8677C86.1226 55.5876 86.6435 55.4401 87.1743 55.4444C87.7048 55.449 88.2239 55.6033 88.6701 55.8911C89.1124 56.1717 89.4667 56.5719 89.6935 57.0439C89.9205 57.5168 90.0102 58.0435 89.9528 58.5646H89.9533L89.4466 63.4145C89.4245 63.6247 89.5074 63.8316 89.6683 63.9694L93.3747 67.1209C93.9119 67.5772 94.2591 68.2188 94.3463 68.9184C94.4348 69.6177 94.2566 70.3252 93.8488 70.9002C93.4411 71.4755 92.8317 71.8769 92.1421 72.0248L87.3825 73.0511C87.1852 73.0948 87.0197 73.2291 86.9373 73.4141L86.9376 73.4139L85.0829 77.9144C84.8157 78.5682 84.3129 79.0975 83.6738 79.3985C83.0339 79.699 82.3056 79.7486 81.6322 79.537C80.9585 79.3266 80.3878 78.8697 80.0342 78.2581L77.5913 74.0491C77.486 73.8674 77.2987 73.7481 77.0894 73.7296V73.7303L72.2378 73.3587C71.7744 73.3246 71.3267 73.1767 70.9345 72.9288Z"
      fill="#FABE64"
      stroke="#32313B"
    />
    <path
      d="M45.6994 31.3976C45.6994 31.3976 46.5988 30.7232 48.0976 30.7232C49.5964 30.7232 50.5332 31.51 50.5332 31.51L50.6453 28.6623C50.6453 28.6623 49.3712 27.3135 47.91 27.3135C46.4488 27.3135 45.3997 28.8873 45.3997 28.8873L45.6994 31.3976Z"
      fill="#32313B"
    />
    <path
      d="M63.143 47.2002L65.1767 49.8059L67.5917 49.6152L66.3633 47.4963L63.143 47.2002Z"
      fill="#32313B"
    />
    <path
      d="M29.7345 49.2761L32.2337 49.1724L36.344 48.005L33.5477 47.0518L30.3701 47.306L29.7345 49.2761Z"
      fill="#32313B"
    />
    <path
      d="M16.1603 75.3448L18.8699 75.8372L20.1016 74.236L17.0305 73.9067L16.1603 75.3448Z"
      fill="#32313B"
    />
    <path
      d="M74.2155 73.7432L75.4472 75.9602L78.2799 75.5906L77.0891 73.7295L74.2155 73.7432Z"
      fill="#32313B"
    />
    <path
      d="M68.4267 68.8166L70.3719 68.6384L71.1365 67.8313L69.2889 67.3389L68.4267 68.8166Z"
      fill="#32313B"
    />
    <path
      d="M23.4272 67.7083L24.8414 69.3922L26.7525 69.3093L25.3978 67.585L23.4272 67.7083Z"
      fill="#32313B"
    />
  </svg>
);
