import { styled } from "@linaria/react";
import { widthInGrid } from "../../constants/globalGrid.constants";
import Spacing from "../layout/Spacing";
import PageSection from "../reusableSections/PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import { FedModernizeSecurityApproachIcon } from "./iconsAndIllustrations/FedModernizeSecurityApproachIcon";
import { rSize } from "../../styles/responsiveSizes.styles";
import { colors } from "../../styles/colors.styles";
import imgFedIntegrationIconGrid from "./images/tines-integrations-logo-grid.png";
import imgFedDeployedYourWayIllo from "./images/tines-deploy-anywhere.png";
import imgFedForTheWholeTeamIllo from "./images/tines-for-the-whole-team.png";
import { serif } from "../../styles/fonts.styles";
import {
  fromDesktopMd,
  fromTabletLg,
  mediaFromTo,
} from "../../styles/breakpointsAndMediaQueries.styles";

const Desc = styled.p`
  max-width: ${widthInGrid(6, 2)};
  font-size: 1.4rem;
  margin: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  ${fromTabletLg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridCard = styled.figure`
  margin: 0;
  padding-top: ${rSize("md")};
  border-radius: ${rSize("radius")};
  background-color: ${colors.purple50};
  color: ${colors.purple800};
  text-align: left;
  display: grid;
  grid-gap: ${rSize("gap")};
  grid-template-rows: minmax(auto, 1fr) auto;
  overflow: hidden;
  ${mediaFromTo("phoneLg", "tabletLg")} {
    padding-top: 0;
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 2fr;
  }
  figcaption {
    max-width: 30rem;
    padding: 1.25em;
    ${mediaFromTo("phoneLg", "tabletLg")} {
      padding-top: 2em;
    }
    ${fromDesktopMd} {
      padding: 2em;
    }
  }
  h3 {
    font-size: 2.4rem;
    ${fromDesktopMd} {
      font-size: 2.8rem;
    }
    font-family: ${serif};
    font-weight: 400;
    + * {
      margin-top: 0.75em;
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

const IllustrationWrap = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  ${mediaFromTo("phoneLg", "tabletLg")} {
    background-color: ${colors.purple100};
    padding-top: 1em;
    padding-bottom: 1em;
  }
  img {
    display: block;
    min-width: ${`${(386 / 360) * 100}%`};
    height: auto;
  }
`;

const FedModernizeSecurityApproachSection = () => {
  return (
    <PageSection centered>
      <FedModernizeSecurityApproachIcon />
      <Spacing size="1em" />
      <SectionHeading2 lighter>
        Modernize your security approach
      </SectionHeading2>
      <Spacing size="md" />
      <Desc>
        Whatever your security goals, Tines helps you reach them faster than
        traditional solutions. Security practitioners use Tines to speed up
        responses to security events, reduce the noise of duplicate alerts, and
        improve team efficiency.
      </Desc>

      <Spacing size="xl" />

      <Grid>
        <GridCard>
          <IllustrationWrap>
            <img src={imgFedIntegrationIconGrid} />
          </IllustrationWrap>
          <figcaption>
            <h3>
              Connect
              <br /> anything
            </h3>
            <p>
              Integrates with all your systems – internal or external – and
              allows them to work better together.
            </p>
          </figcaption>
        </GridCard>
        <GridCard>
          <IllustrationWrap>
            <img src={imgFedDeployedYourWayIllo} />
          </IllustrationWrap>
          <figcaption>
            <h3>
              Deployed
              <br /> your way
            </h3>
            <p>
              Runs where needed – self-hosted, on-prem, or hybrid – with
              controls defined by you.
            </p>
          </figcaption>
        </GridCard>
        <GridCard>
          <IllustrationWrap>
            <img src={imgFedForTheWholeTeamIllo} />
          </IllustrationWrap>
          <figcaption>
            <h3>
              For the
              <br /> whole team
            </h3>
            <p>
              No code or low code, any team member can build and automate
              workflows.
            </p>
          </figcaption>
        </GridCard>
      </Grid>
    </PageSection>
  );
};

export default FedModernizeSecurityApproachSection;
