export const AIAutomaticModeOrigamiIconFacingLeft = () => (
  <svg
    width="87"
    height="90"
    viewBox="0 0 87 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.2247 27.1301L86 16.2568L48.2716 43.0369L39.2247 27.1301Z"
      fill="#F0A848"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.4807 17.4171L81.5664 4.80762L48.9034 42.6151L46.4807 17.4171Z"
      fill="#FFD29F"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32.6483 44.3828L12.5148 20.9094L12.469 16.9224L41.8203 28.1181L32.6483 44.3828Z"
      fill="#F0A848"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M47.1993 37.7429L63.5 71.6251L44.4962 64.3724L47.1993 37.7429Z"
      fill="#E49307"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M50.6641 54.5166L27.433 41.4738L46.4947 17.2998L50.6641 54.5166Z"
      fill="#FBBD74"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.811 37.012L63.8594 71.5594L45.8417 67.7889L48.811 37.012Z"
      fill="#FFD29F"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M22.9727 20.8701C22.562 20.936 4.68388 27.4597 4.68388 27.4597L12.4676 16.9224L22.9727 20.8701Z"
      fill="#FBBD74"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.3094 24.058L16.1071 24.866L20.3564 22.1815L15.3094 24.058Z"
      fill="#4C1812"
      stroke="#4C1812"
      strokeWidth="0.221835"
    />
    <path
      d="M42.924 88.5656C28.1559 88.5656 16.184 87.853 16.184 86.974C16.184 86.0949 28.1559 85.3823 42.924 85.3823C57.6922 85.3823 69.6641 86.0949 69.6641 86.974C69.6641 87.853 57.6922 88.5656 42.924 88.5656Z"
      fill="#4C1812"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.2484 55.6954L46.7767 56.7145L47.1397 52.7243L45.7971 52.2209L45.2484 55.6954Z"
      fill="#4C1812"
      stroke="#4C1812"
      strokeWidth="0.221835"
    />
    <path
      d="M5.76913 54.951C5.51601 55.5923 4.78441 56.3246 4.14308 56.5771L1.4806 57.627C0.83883 57.8799 0.83883 58.2945 1.4806 58.5472L4.14308 59.5971C4.78441 59.85 5.51601 60.5821 5.76913 61.2234L6.81887 63.8854C7.07198 64.527 7.48614 64.527 7.73903 63.8854L8.78876 61.2234C9.04188 60.5821 9.77326 59.85 10.4148 59.5971L13.0773 58.5472C13.719 58.2943 13.719 57.8797 13.0773 57.627L10.4148 56.5771C9.77304 56.3246 9.04188 55.5923 8.78876 54.951L7.73903 52.2888C7.48591 51.6472 7.07176 51.6472 6.81887 52.2888L5.76913 54.951Z"
      fill="#FFC8A3"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M44.0383 2.80995C43.8771 3.21847 43.411 3.68493 43.0025 3.84574L41.3066 4.51455C40.8978 4.67564 40.8978 4.93974 41.3066 5.10069L43.0025 5.7695C43.411 5.93059 43.8771 6.39691 44.0383 6.80543L44.7069 8.50112C44.8682 8.90978 45.132 8.90978 45.2931 8.50112L45.9617 6.80543C46.123 6.39691 46.5888 5.93059 46.9975 5.7695L48.6934 5.10069C49.1022 4.9396 49.1022 4.6755 48.6934 4.51455L46.9975 3.84574C46.5887 3.68493 46.123 3.21847 45.9617 2.80995L45.2931 1.11411C45.1318 0.705452 44.868 0.705452 44.7069 1.11411L44.0383 2.80995Z"
      fill="#87D1A3"
      stroke="#4C1812"
      strokeWidth="1.10918"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
