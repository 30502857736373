import { colors } from "../../../styles/colors.styles";
import ReportV2ChapterHeaderSection from "../components/ReportV2ChapterHeaderSection";
import ReportV2ContentCard, {
  ReportV2ContentCardGrid,
} from "../components/ReportV2ContentCard";
import ReportV2Section from "../components/ReportV2Section";
import { ReportV2SectionHeadingMedium } from "../components/ReportV2SectionHeadingMedium";
import { ReportV2TextBlock } from "../components/ReportV2TextBlock";
import {
  ReportV2FullDarkSectionNavControlPoint,
  ReportV2NavPurpleControlPoint,
} from "../components/ReportV2CommonNavControlPoints";
import ReportChapterSummary from "../components/ReportChapterSummary";
import { round } from "lodash-es";
import { PianoIcon } from "./icons/PianoIcon";
import ReportV2SectionHeader from "../components/ReportV2SectionHeader";
import ReportV2StatTable from "../components/ReportV2StatTable";
import Spacing from "../../layout/Spacing";
import { LadderToSky } from "./icons/LadderToSky";
import { ReportV2DotGridGraphSimple } from "../components/ReportV2DotGridGraph";

export const SOC2023Chapter1 = () => {
  return (
    <section id="chapter-1-job-satisfaction-and-workloads">
      <ReportV2FullDarkSectionNavControlPoint />

      <ReportV2ChapterHeaderSection
        chapterNumber={1}
        largeChapterNumberStyle="dots"
        color="purple"
        icon={<PianoIcon />}
      >
        <h2>Job satisfaction and workloads</h2>
        <ReportV2TextBlock largerText>
          <p>
            Security teams enjoy the work they do and feel appreciated by the
            organization. But all is not well in the SOC — burnout and
            understaffing threaten stability and security. To better understand
            how leaders can fix the challenges at play, we first must take stock
            of how security teams are feeling today.
          </p>
        </ReportV2TextBlock>
      </ReportV2ChapterHeaderSection>

      <ReportV2NavPurpleControlPoint />

      <ReportV2Section backgroundColor={colors.lightest}>
        <ReportV2ContentCardGrid equalHeight>
          <ReportV2ContentCard
            backgroundColor={colors.purple50}
            color={colors.purple600}
          >
            <header>
              <ReportV2DotGridGraphSimple
                total={900}
                value={round(900 * 0.99)}
                rows={12}
                gap={1}
                dotSize={1.25}
                color={colors.purple}
              />
              <h3>99% are satisfied with their job</h3>
            </header>
            <Spacing />
            <p>
              Overall job satisfaction is up among security teams this year. The
              number of respondents satisfied with their current job rose from
              88% last year to 99% in 2023. 54% are very satisfied this year.
            </p>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.purple50}
            color={colors.purple600}
          >
            <header>
              <ReportV2DotGridGraphSimple
                total={900}
                value={round(900 * 0.98)}
                rows={12}
                gap={1}
                dotSize={1.25}
                color={colors.purple}
              />
              <h3>98% are engaged with their work</h3>
            </header>
            <Spacing />
            <p>
              Analysts aren’t just satisfied — they’re locked in. 50% of
              respondents are very engaged with their work, and 98% are at least
              somewhat engaged.
            </p>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.purple50}
            color={colors.purple600}
          >
            <header>
              <ReportV2DotGridGraphSimple
                total={900}
                value={round(900 * 0.99)}
                rows={12}
                gap={1}
                dotSize={1.25}
                color={colors.purple}
              />
              <h3>99% feel respected by their peers outside the SOC</h3>
            </header>
            <Spacing />
            <p>
              Security teams may sometimes feel like they are working in the
              shadows as they defend their organizations against threats, but
              their hard work does not go unnoticed. Almost all (99%) of
              respondents said they feel respected by their peers outside of the
              SOC team, and 52% feel very respected.
            </p>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.orange50}
            color={colors.orange600}
          >
            <header>
              <ReportV2DotGridGraphSimple
                total={900}
                value={round(900 * 0.63)}
                rows={12}
                gap={1}
                dotSize={1.25}
                color={colors.orange}
              />
              <h3>63% are experiencing some level of burnout at work</h3>
            </header>
            <Spacing />
            <p>
              Despite 99% saying they’re satisfied with their job, nearly two
              thirds (63%) of respondents said they feel burned out at work. One
              in five feel very burned out. We’ll uncover some of the reasons
              for this shortly, along with ways to help SOC teams join the 37%
              who say they do not feel burned out at work.
            </p>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.orange50}
            color={colors.orange600}
          >
            <header>
              <ReportV2DotGridGraphSimple
                total={900}
                value={round(900 * 0.5)}
                rows={12}
                gap={1}
                dotSize={1.25}
                color={colors.orange}
              />
              <h3>50% say their SOC team is understaffed</h3>
            </header>
            <Spacing />
            <p>
              Half of our respondents said their team is currently understaffed,
              and staffing problems are tied closely to burnout. Of those who
              felt understaffed, nearly four in five (79%) are burned out,
              compared to just 47% of those who felt they currently have the
              right amount of staff for their needs.
            </p>
          </ReportV2ContentCard>
          <ReportV2ContentCard
            backgroundColor={colors.orange50}
            color={colors.orange600}
          >
            <header>
              <ReportV2DotGridGraphSimple
                total={900}
                value={round(900 * 0.81)}
                rows={12}
                gap={1}
                dotSize={1.25}
                color={colors.orange}
              />
              <h3>For 81%, workloads have increased over the past year</h3>
            </header>
            <Spacing />
            <p>
              One of the reasons for burnout could be that 81% had more work
              than ever over the past year. This was particularly true in the
              United States, where 39% said their workload had increased
              substantially, compared to 22% in Europe. Just 2% of overall
              respondents said their workload had decreased.
            </p>
          </ReportV2ContentCard>
        </ReportV2ContentCardGrid>

        <ReportChapterSummary>
          <p>
            SOC teams love what they do. The majority of respondents report
            feeling satisfied with their jobs, engaged in their work, and
            respected by their colleagues in other departments. They also
            indicated they are paid what they deserve, with 96% feeling fairly
            compensated.
          </p>
          <p>
            However, 63% are experiencing some level of burnout at work, and
            many security teams feel understaffed and overburdened by
            ever-increasing workloads. These issues can lead to employee churn,
            even among those who love their jobs. In the next sections, we’ll
            take a closer look at the factors that have helped retain
            respondents who would otherwise look for new opportunities.
          </p>
        </ReportChapterSummary>
      </ReportV2Section>

      <ReportV2Section backgroundColor={colors.purple100}>
        <ReportV2SectionHeader icon={<LadderToSky />}>
          <ReportV2SectionHeadingMedium>
            Top three skills needed to succeed as an analyst
          </ReportV2SectionHeadingMedium>
        </ReportV2SectionHeader>
        <ReportV2StatTable
          total={900}
          color="purple"
          items={[
            {
              percentage: 15,
              heading: "Computer forensics techniques",
              description:
                "Knowing how to recover data from crashed servers and drives  after an incident is a key step in uncovering what went wrong in the failure or attack.",
            },
            {
              percentage: 14,
              heading: "Learning to code",
              description:
                "Being able to code can help in task automation, which alleviates some of the most tedious processes. There are also no-code automation options that allow teams to focus on security analysis.",
            },
            {
              percentage: 14,
              heading: "Malware analysis techniques",
              description:
                "SOC teams must be able to examine malicious software to reveal its purpose and potential impact on their systems.",
            },
            { percentage: 11, heading: "Threat hunting techniques" },
            {
              percentage: 9,
              heading: "Obtaining high-level training and certifications",
            },
            { percentage: 9, heading: "Operationalizing Mitre ATT&CK" },
            { percentage: 9, heading: "Advanced query language techniques" },
            {
              percentage: 7,
              heading: "Keeping up to date on threat actors’ TTPs",
            },
            { percentage: 7, heading: "Learning penetration testing" },
            { percentage: 4, heading: "SOAR integration" },
          ]}
        />
      </ReportV2Section>
    </section>
  );
};
