import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import { cx } from "linaria";
import { BlogCategoryNavQuery } from "../../../graphql-types";
import { useOnMount } from "../../utils/lifeCycle.utils";
import { useSiteContext } from "../../context/site.context";
import { StandardSidebarLinkList } from "../layout/StandardSidebar";

const BlogCategoryNav = () => {
  const data = useStaticQuery<BlogCategoryNavQuery>(blogCategoryNavQuery);
  const { location } = useSiteContext();
  useOnMount(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");
    if (category && data.categories.nodes.find(cat => cat.slug === category)) {
      navigate(`/blog/${category}`);
    }
  });
  return (
    <StandardSidebarLinkList>
      <Link
        className={cx(
          /^\/blog\/?(page\/\d*\/?)$/.exec(location.pathname) && "active"
        )}
        to="/blog"
      >
        All
      </Link>
      {data.categories.nodes.map(cat => (
        <Link
          key={cat.slug}
          to={`/blog/${cat.slug}`}
          className={cx(
            location.pathname.startsWith(`/blog/${cat.slug}/`) && "active"
          )}
        >
          {cat.name}
        </Link>
      ))}
    </StandardSidebarLinkList>
  );
};

export const blogCategoryNavQuery = graphql`
  query BlogCategoryNav {
    categories: allDatoCmsArticleCategory(sort: { position: ASC }) {
      nodes {
        originalId
        slug
        name
        position
      }
    }
  }
`;

export default BlogCategoryNav;
