export const GoldWreathIcon = () => (
  <svg
    width="63"
    height="87"
    viewBox="0 0 63 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.3499 77.4478H19.5568L8.49756 86.4646H51.2906L62.3499 77.4478Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M53.2263 77.7084C55.5794 78.632 58.2358 77.4732 59.1595 75.1197C60.0828 72.7667 58.9239 70.1105 56.5708 69.1867L21.563 55.4479C19.21 54.5246 16.5537 55.6833 15.6301 58.0366C14.7067 60.3898 15.8655 63.046 18.2188 63.9697L53.2263 77.7084Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M53.2263 63.6494C55.5794 64.5728 58.2358 63.4142 59.1595 61.0606C60.0828 58.7076 58.9239 56.0513 56.5708 55.1277L21.563 41.3889C19.21 40.4654 16.5537 41.6243 15.6301 43.9776C14.7067 46.3306 15.8655 48.987 18.2188 49.9107L53.2263 63.6494Z"
      fill="#6359B3"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M21.5631 77.7084C19.2099 78.632 16.5536 77.4732 15.63 75.1197C14.7067 72.7667 15.8656 70.1105 18.2187 69.1867L53.2265 55.4479C55.5795 54.5246 58.2358 55.6833 59.1594 58.0366C60.0828 60.3898 58.924 63.046 56.5707 63.9697L21.5631 77.7084Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M21.5631 63.6494C19.2099 64.5728 16.5536 63.4142 15.63 61.0606C14.7067 58.7076 15.8656 56.0513 18.2187 55.1277L53.2265 41.3889C55.5795 40.4654 58.2358 41.6243 59.1594 43.9776C60.0828 46.3306 58.924 48.987 56.5707 49.9107L21.5631 63.6494Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M37.3946 67.796C38.1354 67.796 38.7359 67.1954 38.7359 66.4546C38.7359 65.7138 38.1354 65.1133 37.3946 65.1133C36.6538 65.1133 36.0532 65.7138 36.0532 66.4546C36.0532 67.1954 36.6538 67.796 37.3946 67.796Z"
      fill="#32313B"
    />
    <path
      d="M37.3946 53.5169C38.1354 53.5169 38.7359 52.9164 38.7359 52.1756C38.7359 51.4348 38.1354 50.8342 37.3946 50.8342C36.6538 50.8342 36.0532 51.4348 36.0532 52.1756C36.0532 52.9164 36.6538 53.5169 37.3946 53.5169Z"
      fill="#32313B"
    />
    <path
      d="M19.3946 60.9593C20.1354 60.9593 20.7359 60.3588 20.7359 59.618C20.7359 58.8771 20.1354 58.2766 19.3946 58.2766C18.6538 58.2766 18.0532 58.8771 18.0532 59.618C18.0532 60.3588 18.6538 60.9593 19.3946 60.9593Z"
      fill="#32313B"
    />
    <path
      d="M55.482 60.9593C56.2228 60.9593 56.8233 60.3588 56.8233 59.618C56.8233 58.8771 56.2228 58.2766 55.482 58.2766C54.7412 58.2766 54.1406 58.8771 54.1406 59.618C54.1406 60.3588 54.7412 60.9593 55.482 60.9593Z"
      fill="#32313B"
    />
    <path
      d="M42.1669 86.1474C44.52 87.0708 47.1762 85.9121 48.0999 83.5586C49.0232 81.2056 47.8644 78.5493 45.5114 77.6257L10.5034 63.8868C8.15041 62.9636 5.49409 64.1223 4.5705 66.4756C3.64708 68.8286 4.80592 71.4849 7.15927 72.4087L42.1669 86.1474Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M42.1669 72.088C44.52 73.0114 47.1762 71.8526 48.0999 69.4992C49.0232 67.1462 47.8644 64.4897 45.5114 63.5661L10.5034 49.8272C8.15041 48.904 5.49409 50.0628 4.5705 52.416C3.64708 54.769 4.80592 57.4254 7.15927 58.3489L42.1669 72.088Z"
      fill="#8173E6"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M10.5035 86.1474C8.1505 87.0708 5.49399 85.9121 4.5704 83.5586C3.64716 81.2056 4.806 78.5493 7.15917 77.6257L42.1671 63.8868C44.5201 62.9636 47.1763 64.1223 48.0999 66.4756C49.0235 68.8286 47.8644 71.4849 45.5111 72.4087L10.5035 86.1474Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M10.5035 72.088C8.1505 73.0114 5.49399 71.8526 4.5704 69.4992C3.64716 67.1462 4.806 64.4897 7.15917 63.5661L42.1671 49.8272C44.5201 48.904 47.1763 50.0628 48.0999 52.416C49.0235 54.769 47.8644 57.4254 45.5111 58.3489L10.5035 72.088Z"
      fill="#B0A6F9"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M26.336 76.2345C27.0768 76.2345 27.6773 75.6339 27.6773 74.8931C27.6773 74.1523 27.0768 73.5518 26.336 73.5518C25.5952 73.5518 24.9946 74.1523 24.9946 74.8931C24.9946 75.6339 25.5952 76.2345 26.336 76.2345Z"
      fill="#32313B"
    />
    <path
      d="M26.336 61.9559C27.0768 61.9559 27.6773 61.3554 27.6773 60.6145C27.6773 59.8737 27.0768 59.2732 26.336 59.2732C25.5952 59.2732 24.9946 59.8737 24.9946 60.6145C24.9946 61.3554 25.5952 61.9559 26.336 61.9559Z"
      fill="#32313B"
    />
    <path
      d="M8.335 69.3978C9.07581 69.3978 9.67636 68.7972 9.67636 68.0564C9.67636 67.3156 9.07581 66.7151 8.335 66.7151C7.5942 66.7151 6.99365 67.3156 6.99365 68.0564C6.99365 68.7972 7.5942 69.3978 8.335 69.3978Z"
      fill="#32313B"
    />
    <path
      d="M44.4224 69.3978C45.1632 69.3978 45.7638 68.7972 45.7638 68.0564C45.7638 67.3156 45.1632 66.7151 44.4224 66.7151C43.6816 66.7151 43.0811 67.3156 43.0811 68.0564C43.0811 68.7972 43.6816 69.3978 44.4224 69.3978Z"
      fill="#32313B"
    />
    <path
      d="M26.5702 65.9595L21.2979 67.9366L25.9111 70.1333L31.0737 68.1562L26.5702 65.9595Z"
      fill="#32313B"
    />
    <path
      d="M60.9748 39.0515H12.0593L1 48.0683V54.2767H49.9157L60.9748 45.26V39.0515Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M60.9748 39.0515H12.0593L1 48.0683H49.9157L60.9748 39.0515Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M58.5455 40.9294H24.7963L17.9922 45.6777H52.3459L58.5455 40.9294Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.916 54.4965V48.0686"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.9053 35.0977H18.1562L11.3521 39.8459V45.667H45.7059L51.9053 40.9187V35.0977Z"
      fill="#F0946C"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.9053 35.0977H18.1562L11.3521 39.8459H45.7059L51.9053 35.0977Z"
      fill="#FFD18C"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.5835 45.6668V39.8457"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M36.2835 38.1237C41.2134 38.1237 45.21 37.6733 45.21 37.1179C45.21 36.5624 41.2134 36.1121 36.2835 36.1121C31.3535 36.1121 27.3569 36.5624 27.3569 37.1179C27.3569 37.6733 31.3535 38.1237 36.2835 38.1237Z"
      fill="#32313B"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M30.8714 37.4989C20.7197 37.4989 12.4604 29.2398 12.4604 19.088C12.4604 12.0696 16.3628 5.75736 22.6444 2.61468C23.4301 2.22174 24.3856 2.53989 24.7787 3.3256C25.1716 4.11131 24.8535 5.06678 24.0678 5.4599C18.8704 8.06022 15.6417 13.2822 15.6417 19.0881C15.6417 27.4859 22.4738 34.3178 30.8714 34.3178C39.2692 34.3178 46.1012 27.4859 46.1012 19.0881C46.1012 13.2822 42.8725 8.06022 37.6751 5.4599C36.8894 5.06678 36.5715 4.11131 36.9642 3.3256C37.3573 2.54006 38.313 2.22174 39.0987 2.61468C45.3801 5.75771 49.2825 12.0698 49.2825 19.088C49.2825 29.2397 41.0233 37.4989 30.8714 37.4989Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M24.6144 6.10269C22.4383 7.70031 19.1782 6.95769 19.1782 6.95769C19.1782 6.95769 19.4466 3.62525 21.6229 2.02745C23.7993 0.42983 27.059 1.17229 27.059 1.17229C27.059 1.17229 26.7908 4.50507 24.6144 6.10269Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M22.2224 26.3803C21.9892 29.0699 19.2819 31.032 19.2819 31.032C19.2819 31.032 16.9527 28.6335 17.1858 25.944C17.419 23.2542 20.1262 21.2922 20.1262 21.2922C20.1262 21.2922 22.4556 23.6906 22.2224 26.3803Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M19.8813 14.8233C17.6193 16.2968 14.4058 15.3734 14.4058 15.3734C14.4058 15.3734 14.8599 12.0609 17.1219 10.5874C19.3841 9.1137 22.5974 10.0371 22.5974 10.0371C22.5974 10.0371 22.1433 13.3496 19.8813 14.8233Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M15.3847 9.85987C15.8928 12.5112 13.8222 15.1365 13.8222 15.1365C13.8222 15.1365 10.9279 13.4629 10.4197 10.8114C9.91142 8.1597 11.9819 5.53491 11.9819 5.53491C11.9819 5.53491 14.8765 7.20837 15.3847 9.85987Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M41.5332 14.8233C43.7952 16.2968 47.0087 15.3734 47.0087 15.3734C47.0087 15.3734 46.5546 12.0609 44.2924 10.5874C42.0302 9.1137 38.8169 10.0371 38.8169 10.0371C38.8169 10.0371 39.271 13.3496 41.5332 14.8233Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M46.0285 9.85987C45.5204 12.5112 47.591 15.1365 47.591 15.1365C47.591 15.1365 50.4855 13.4629 50.9934 10.8114C51.5016 8.1597 49.4312 5.53491 49.4312 5.53491C49.4312 5.53491 46.5367 7.20837 46.0285 9.85987Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M15.6128 28.0655C18.216 28.7802 19.6575 31.7971 19.6575 31.7971C19.6575 31.7971 16.878 33.6551 14.2747 32.9406C11.6711 32.2258 10.23 29.209 10.23 29.209C10.23 29.209 13.0094 27.3508 15.6128 28.0655Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M13.2004 18.4159C15.491 19.8448 16.0104 23.1476 16.0104 23.1476C16.0104 23.1476 12.8159 24.1343 10.5251 22.7057C8.23428 21.277 7.71484 17.9742 7.71484 17.9742C7.71484 17.9742 10.9096 16.9874 13.2004 18.4159Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M37.1989 6.10269C39.3751 7.70031 42.6351 6.95769 42.6351 6.95769C42.6351 6.95769 42.3668 3.62525 40.1906 2.02745C38.0141 0.42983 34.7544 1.17229 34.7544 1.17229C34.7544 1.17229 35.0227 4.50507 37.1989 6.10269Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M39.5911 26.3803C39.8244 29.0699 42.5316 31.032 42.5316 31.032C42.5316 31.032 44.861 28.6335 44.6276 25.944C44.3943 23.2542 41.6873 21.2922 41.6873 21.2922C41.6873 21.2922 39.3582 23.6906 39.5911 26.3803Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M46.2015 28.0655C43.5984 28.7802 42.1567 31.7971 42.1567 31.7971C42.1567 31.7971 44.9365 33.6551 47.5397 32.9406C50.1433 32.2258 51.5844 29.209 51.5844 29.209C51.5844 29.209 48.8051 27.3508 46.2015 28.0655Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M48.6136 18.4159C46.323 19.8448 45.8032 23.1476 45.8032 23.1476C45.8032 23.1476 48.998 24.1343 51.2886 22.7057C53.5795 21.277 54.099 17.9742 54.099 17.9742C54.099 17.9742 50.9044 16.9874 48.6136 18.4159Z"
      fill="#FABE64"
      stroke="#32313B"
      strokeWidth="1.00287"
      strokeMiterlimit="10"
    />
    <path
      d="M22.1582 54.2498H30.2583L26.2083 55.9732L22.1582 54.2498Z"
      fill="#32313B"
    />
    <path
      d="M55.3345 50.4581C55.3345 50.4581 58.5228 49.5964 59.126 46.8389L56.9717 48.6485L55.3345 50.4581Z"
      fill="#32313B"
    />
  </svg>
);
