export const CloudLeftMid = () => (
  <svg
    width="99"
    height="85"
    viewBox="0 0 99 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_242_20723" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.3448 66.8126C36.9448 66.0616 36.138 65.6128 35.289 65.6696C34.5595 65.7185 33.8233 65.7433 33.0814 65.7433C15.1066 65.7433 0.535156 51.1719 0.535156 33.1971C0.535156 15.2223 15.1066 0.650879 33.0814 0.650879C45.5338 0.650879 56.3528 7.64417 61.8253 17.9176C62.2253 18.6685 63.0321 19.1174 63.8811 19.0605C64.6107 19.0117 65.3468 18.9868 66.0887 18.9868C84.0635 18.9868 98.635 33.5583 98.635 51.5331C98.635 69.5078 84.0635 84.0793 66.0887 84.0793C53.6363 84.0793 42.8173 77.086 37.3448 66.8126Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.3448 66.8126C36.9448 66.0616 36.138 65.6128 35.289 65.6696C34.5595 65.7185 33.8233 65.7433 33.0814 65.7433C15.1066 65.7433 0.535156 51.1719 0.535156 33.1971C0.535156 15.2223 15.1066 0.650879 33.0814 0.650879C45.5338 0.650879 56.3528 7.64417 61.8253 17.9176C62.2253 18.6685 63.0321 19.1174 63.8811 19.0605C64.6107 19.0117 65.3468 18.9868 66.0887 18.9868C84.0635 18.9868 98.635 33.5583 98.635 51.5331C98.635 69.5078 84.0635 84.0793 66.0887 84.0793C53.6363 84.0793 42.8173 77.086 37.3448 66.8126Z"
      fill="#F8F4F0"
    />
    <path
      d="M61.8253 17.9176L62.7079 17.4474L61.8253 17.9176ZM35.289 65.6696L35.3558 66.6674L35.289 65.6696ZM35.2222 64.6719C34.5149 64.7192 33.801 64.7433 33.0814 64.7433V66.7433C33.8456 66.7433 34.6041 66.7178 35.3558 66.6674L35.2222 64.6719ZM33.0814 64.7433C15.6589 64.7433 1.53516 50.6196 1.53516 33.1971H-0.464844C-0.464844 51.7242 14.5543 66.7433 33.0814 66.7433V64.7433ZM1.53516 33.1971C1.53516 15.7746 15.6589 1.65088 33.0814 1.65088V-0.349121C14.5543 -0.349121 -0.464844 14.67 -0.464844 33.1971H1.53516ZM33.0814 1.65088C45.1498 1.65088 55.637 8.42742 60.9427 18.3877L62.7079 17.4474C57.0686 6.86091 45.9178 -0.349121 33.0814 -0.349121V1.65088ZM63.9479 20.0583C64.6553 20.0109 65.3691 19.9868 66.0887 19.9868V17.9868C65.3245 17.9868 64.5661 18.0124 63.8143 18.0628L63.9479 20.0583ZM66.0887 19.9868C83.5112 19.9868 97.635 34.1105 97.635 51.5331H99.635C99.635 33.006 84.6158 17.9868 66.0887 17.9868V19.9868ZM97.635 51.5331C97.635 68.9555 83.5112 83.0793 66.0887 83.0793V85.0793C84.6158 85.0793 99.635 70.0601 99.635 51.5331H97.635ZM66.0887 83.0793C54.0203 83.0793 43.5331 76.3027 38.2274 66.3425L36.4623 67.2827C42.1015 77.8692 53.2524 85.0793 66.0887 85.0793V83.0793ZM60.9427 18.3877C61.5276 19.4857 62.7058 20.1415 63.9479 20.0583L63.8143 18.0628C63.3584 18.0933 62.923 17.8514 62.7079 17.4474L60.9427 18.3877ZM35.3558 66.6674C35.8117 66.6369 36.2471 66.8788 36.4623 67.2827L38.2274 66.3425C37.6425 65.2444 36.4643 64.5887 35.2222 64.6719L35.3558 66.6674Z"
      fill="#C2AAFA"
      mask="url(#path-1-inside-1_242_20723)"
    />
  </svg>
);
