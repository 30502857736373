import { ActionLink } from "../../types/tines.types";
import { getGradientDirection } from "./utils/gradient.utils";
import chroma from "chroma-js";
import { getActionColorFromType } from "./utils/actions.utils";

const ActionLinkGradientDef = ({
  id,
  link,
}: {
  id: string;
  link: ActionLink;
}) => {
  const { source, receiver } = link;
  const direction = getGradientDirection(source.position, receiver.position);
  const fromType = source.type;
  const toType = receiver.type;
  const scale = chroma
    .scale([getActionColorFromType(fromType), getActionColorFromType(toType)])
    .mode("lab")
    .padding(-0.1)
    .colors(5);
  const stops = scale.map((step, i) => (
    <stop
      key={i}
      offset={`${(i / (scale.length - 1)) * 100}%`}
      stopColor={step}
    />
  ));
  return (
    <defs>
      {(() => {
        switch (direction) {
          case "ns":
            return (
              <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
                {stops}
              </linearGradient>
            );
          case "we":
            return (
              <linearGradient id={id} x1="0%" y1="50%" x2="100%" y2="50%">
                {stops}
              </linearGradient>
            );
          case "nwse":
            return (
              <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="100%">
                {stops}
              </linearGradient>
            );
          case "nesw":
            return (
              <linearGradient id={id} x1="100%" y1="0%" x2="0%" y2="100%">
                {stops}
              </linearGradient>
            );
        }
      })()}
    </defs>
  );
};

export default ActionLinkGradientDef;
