export const OpenBookTwoToneIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    strokeLinecap="round"
  >
    <path
      d="M15.5 4.02203V12.4812C15.5 12.4911 15.4912 12.4987 15.4814 12.4973L15.3127 12.4732C13.1556 12.1651 10.9559 12.5221 9.00692 13.4965C9.00256 13.4987 8.99744 13.4987 8.99308 13.4965C7.04413 12.5221 4.84436 12.1651 2.68727 12.4732L2.51857 12.4973C2.50877 12.4987 2.5 12.4911 2.5 12.4812V4.02569C2.5 4.01095 2.51084 3.99845 2.52543 3.99637L2.87829 3.94596C4.93122 3.65268 7.02543 3.84181 8.99279 4.4976C8.99747 4.49916 9.00253 4.49916 9.00721 4.4976C10.9754 3.84155 13.0692 3.65274 15.123 3.94614L15.4782 3.99688C15.4907 3.99867 15.5 4.00939 15.5 4.02203Z"
      stroke="currentColor"
    />
    <path
      opacity="0.5"
      d="M9.9943 14.9809H8.0057C8.00193 14.9809 7.9982 14.9802 7.99469 14.9788L7.80857 14.9058C6.28305 14.3072 4.65884 14 3.02011 14C3.0081 14 2.99744 13.9923 2.99364 13.9809L2.50486 12.5146C2.50233 12.507 2.5073 12.499 2.51522 12.4978L2.68565 12.4735C4.8438 12.1652 7.04464 12.5223 8.99454 13.4973C8.99798 13.499 9.00203 13.499 9.00546 13.4973C10.9542 12.5229 13.1555 12.1651 15.3125 12.4732L15.48 12.4971C15.4904 12.4986 15.4969 12.5092 15.4936 12.5192L15.0064 13.9809C15.0026 13.9923 14.9919 14 14.9799 14C13.3412 14 11.717 14.3072 10.1914 14.9058L10.0053 14.9788C10.0018 14.9802 9.99807 14.9809 9.9943 14.9809Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path opacity="0.2" d="M9 4.5L9 13" stroke="currentColor" />
    <path
      opacity="0.2"
      d="M4.5 6.0214C5.28867 5.94213 6.75764 6.08782 7.5 6.37941"
      stroke="currentColor"
    />
    <path
      opacity="0.2"
      d="M4.5 7.90531C5.28867 7.82604 6.75764 7.97173 7.5 8.26332"
      stroke="currentColor"
    />
    <path
      opacity="0.2"
      d="M4.5 9.78971C5.28867 9.71044 6.75764 9.85613 7.5 10.1477"
      stroke="currentColor"
    />
    <path
      opacity="0.2"
      d="M13.5 6.0214C12.7113 5.94213 11.2424 6.08782 10.5 6.37941"
      stroke="currentColor"
    />
    <path
      opacity="0.2"
      d="M13.5 7.90531C12.7113 7.82604 11.2424 7.97173 10.5 8.26332"
      stroke="currentColor"
    />
    <path
      opacity="0.2"
      d="M13.5 9.78971C12.7113 9.71044 11.2424 9.85613 10.5 10.1477"
      stroke="currentColor"
    />
  </svg>
);
