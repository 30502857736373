import { styled } from "@linaria/react";
import { cover } from "polished";
import { ReactNode, RefObject } from "react";
import { GRID_COL_WIDTH_MAX } from "../../constants/globalGrid.constants";
import { colorsV4 } from "../../styles/colorsV4.styles";

type RigidDotGridOptionBag = {
  cellSize?: number;
  dotSize?: number;
  color?: string;
};

type Props = RigidDotGridOptionBag & {
  className?: string;
  children?: (options: Required<RigidDotGridOptionBag>) => ReactNode;
  innerRef?: RefObject<HTMLDivElement>;
  offset?: string | number;
  insetTop?: number;
  gridXOrigin?: "start" | "center";
};

const fallbacks = {
  cellSize: GRID_COL_WIDTH_MAX / 2,
  color: colorsV4.grey,
  dotSize: 1.25,
} as const;

export const RigidDotGridDefaults = { ...fallbacks };

const makeSvgString = (options?: RigidDotGridOptionBag) => {
  const {
    dotSize = fallbacks.dotSize,
    cellSize = fallbacks.cellSize,
    color = fallbacks.color,
  } = options || fallbacks;
  return [
    `<svg width='${cellSize}' height='${cellSize}' viewBox='0 0 ${cellSize} ${cellSize}' fill='${color}' xmlns='http://www.w3.org/2000/svg'>`,
    `<circle cx='${cellSize / 2}' cy='${cellSize / 2}' r='${dotSize}'/>`,
    `</svg>`,
  ].join("");
};
const makeCssBackgroundImageUrl = (options?: RigidDotGridOptionBag) =>
  `url("data:image/svg+xml,${encodeURIComponent(makeSvgString(options))}")`;

export const RigidDotGridLayer = styled.div`
  ${cover()};
  background-position: center top;
  overflow: hidden;
  pointer-events: none;
`;

const RigidDotGrid = ({
  className,
  dotSize = fallbacks.dotSize,
  color = fallbacks.color,
  cellSize = fallbacks.cellSize,
  innerRef,
  children,
  offset,
  gridXOrigin,
  insetTop,
}: Props) => {
  return (
    <RigidDotGridLayer
      className={className}
      ref={innerRef}
      style={{
        backgroundImage: makeCssBackgroundImageUrl({
          dotSize,
          color,
          cellSize,
        }),
        backgroundPosition: `${gridXOrigin === "start" ? "left" : "center"} ${
          insetTop ? `${insetTop}px` : "top"
        }`,
        top: offset ?? 0,
        left: offset ?? 0,
        right: offset ?? 0,
        bottom: offset ?? 0,
      }}
      children={children?.(
        Object.assign({ ...fallbacks }, { dotSize, color, cellSize })
      )}
    />
  );
};

export default RigidDotGrid;
