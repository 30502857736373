import { styled } from "@linaria/react";
import { ReactNode } from "react";
import { BrandColorNameV4 } from "../../styles/colorsV4.styles";
import { DatoCmsFaqQuestion } from "../../../graphql-types";
import { isValidFaqQuestion } from "../../utils/datocms.utils";
import FaqEntryBlock from "../blocks/FaqEntry.block";
import PageSection from "./PageSection";
import { SectionHeading2 } from "../typography/SectionHeading2";
import Spacing from "../layout/Spacing";
import { cx } from "linaria";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromTablet,
  uptoTablet,
} from "../../styles/breakpointsAndMediaQueries.styles";

export type StaticFaqRecord = {
  id?: string;
  question: ReactNode;
  answer: ReactNode;
};

type Props = {
  className?: string;
  datoRecords?: Partial<DatoCmsFaqQuestion>[];
  questionColor?: BrandColorNameV4 | string;
  staticRecords?: StaticFaqRecord[];
  sectionHeading?: ReactNode;
  headerIcon?: ReactNode;
  lighterHeading?: boolean;
};

const FaqListSectionInner = styled.div``;
const Header = styled.header`
  &.hasIcon {
    display: grid;
    grid-gap: ${rSize("lg")};
    grid-template-areas: "icon" "heading";
    align-items: center;
    ${uptoTablet} {
      text-align: center;
    }
    ${fromTablet} {
      grid-template-columns: minmax(0, 1fr) auto;
      grid-template-areas: "heading icon";
    }
    h2 {
      grid-area: heading;
    }
  }
`;
const HeaderIcon = styled.div`
  grid-area: icon;
`;

const FaqListSection = (props: Props) => {
  return (
    <PageSection className={props.className}>
      <FaqListSectionInner>
        <Header className={cx(props.headerIcon && "hasIcon")}>
          <SectionHeading2 lighter={props.lighterHeading}>
            {props.sectionHeading ?? "FAQs"}
          </SectionHeading2>
          {props.headerIcon && <HeaderIcon>{props.headerIcon}</HeaderIcon>}
        </Header>
        <Spacing size="xl" />
        {props.datoRecords?.filter(isValidFaqQuestion).map((record, i) => (
          <FaqEntryBlock
            datoRecord={record}
            key={record.id ?? i}
            questionColor={props.questionColor}
            // initialOpenState={i === 0}
          />
        ))}
        {props.staticRecords?.map((record, i) => (
          <FaqEntryBlock
            id={record.id}
            staticRecord={record}
            key={i}
            questionColor={props.questionColor}
            // initialOpenState={i === 0}
          />
        ))}
      </FaqListSectionInner>
    </PageSection>
  );
};

export default FaqListSection;
