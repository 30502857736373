import { css, cx } from "linaria";
import React, { createElement } from "react";

type Props = {
  as?: "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div";
  colorHex?: string;
  larger?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>;

const allCapsStyle = css`
  text-transform: uppercase;
  font-size: 1.15rem !important;
  font-weight: 600;
  letter-spacing: 0.1em;
  &.larger {
    font-size: 1.25rem !important;
  }
`;

const AllCaps = (props: Props) => {
  const { className, as, colorHex, style, larger, ...rest } = props;
  return createElement(as ?? "h3", {
    ...rest,
    className: cx(className, allCapsStyle, larger && "larger"),
    style: {
      ...style,
      color: colorHex,
    },
  });
};

export default AllCaps;
