export const PageIconChart = () => (
  <svg width="77" height="101" viewBox="0 0 77 101" fill="none">
    <path d="M75.7969 93.0276H2.0127V100.397H75.7969V93.0276Z" fill="#483C72" />
    <path
      d="M76.3501 13.1967H1.4502V97.4769H76.3501V13.1967Z"
      fill="#FA9589"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M69.9687 19.245H7.82617V91.3992H69.9687V19.245Z" fill="#FFE0CC" />
    <path
      d="M70.0312 74.8958H7.81348V91.3238H70.0312V74.8958Z"
      fill="#C86735"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5068 13.197L38.8963 3.33179L60.2858 13.197"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0508 4.24035C36.0508 2.71359 37.2986 1.46576 38.8254 1.46576C40.3522 1.46576 41.6 2.71359 41.6 4.24035C41.6 5.76712 40.3522 7.01495 38.8254 7.01495C37.2986 7.01495 36.0508 5.76712 36.0508 4.24035Z"
      fill="#F47E3F"
      stroke="#483C72"
      strokeMiterlimit="10"
    />
    <path
      d="M69.7602 19.0971H7.83789V20.7854H69.7602V19.0971Z"
      fill="#483C72"
    />
    <mask
      id="mask0_209_12689"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="7"
      y="19"
      width="63"
      height="73"
    >
      <path
        d="M69.9687 19.2434H7.82617V91.3976H69.9687V19.2434Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_209_12689)">
      <path
        d="M91.2704 84.3629L71.2755 65.6161L57.6373 59.3622L45.3644 46.5316L36.2772 49.7172L23.5786 58.5401L21.2884 58.8044L12.2012 61.99L6.12344 71.7084L4.8756 73.015L-2.80229 74.7179L-15.0752 87.5486H82.1684L91.2557 84.3629H91.2704Z"
        fill="#25A871"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M-15.0635 87.5489H82.1802L62.1853 68.802L48.5471 62.5481L36.2742 49.7175L19.9202 61.0801L12.1982 61.9903L5.38645 72.8978L-2.79059 74.7182L-15.0635 87.5489Z"
        fill="#D6EDD9"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M48.5566 62.5632L57.6439 59.3775"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M62.1924 68.8033L71.2796 65.6177"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M101.945 89.5895L90.7435 81.0749L83.4032 79.8564L78.6614 77.8599L74.2279 77.7131L71.3212 74.8063L66.8877 74.9678L56.215 79.2839L52.5742 82.6457L52.2512 82.954L44.6174 84.1724L35.1484 93.9202H91.272L101.945 89.6042V89.5895Z"
        fill="#FFC8A3"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M35.1543 93.9035H91.2779L80.0767 85.3889L72.7364 84.1704L67.9946 82.1739L63.5611 82.0271L60.6544 79.1204L56.2209 79.2818L52.2424 82.9519L44.6086 84.1704L35.1396 93.9182L35.1543 93.9035Z"
        fill="#FA975F"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M60.6445 79.1224L71.3172 74.8063"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M63.5576 82.0287L74.2303 77.7126"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M67.9834 82.1767L78.6561 77.8607"
        stroke="#483C72"
        strokeMiterlimit="10"
      />
      <path
        d="M26.75 90.4986L20.6135 86.7551L19.2482 83.5694L12.6567 83.3492L3.78968 77.4477L-3.93227 80.1782L-9.37873 86.0798L-14.8252 93.2291H19.028L26.75 90.4986Z"
        fill="#FFBFDB"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M-14.8252 93.2293H19.028L12.8916 89.4858L11.5263 86.3002L4.93476 86.08L-3.93227 80.1785L-9.37873 86.08L-14.8252 93.2293Z"
        fill="#EE86B7"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M4.93945 86.0657L12.6614 83.3351"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M11.5225 86.2998L19.2444 83.5692"
        stroke="#483C72"
        strokeLinejoin="round"
      />
      <path
        d="M12.8877 89.4726L20.6096 86.7421"
        stroke="#483C72"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M69.9707 19.2451H7.82812V91.3992H69.9707V19.2451Z"
      stroke="#483C72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
