import { styled } from "@linaria/react";
import PageSection from "../reusableSections/PageSection";
import { serif } from "../../styles/fonts.styles";
import { gridGapPx, widthInGrid } from "../../constants/globalGrid.constants";
import { colors, withOpacity } from "../../styles/colors.styles";
import { ConnectAnythingIcon } from "./spotIcons/ConnectAnythingIcon";
import { LightningBoltIcon } from "./spotIcons/LightningBoldIcon";
import { ProtectedBuildingIcon } from "./spotIcons/ProtectedBuildingIcon";
import { rSize } from "../../styles/responsiveSizes.styles";
import {
  fromDesktop,
  fromDesktopLg,
  fromDesktopMd,
  fromPhoneLg,
  fromTablet,
  fromTabletLg,
  fromTabletMd,
  mediaFromTo,
  uptoTablet,
  uptoTabletLg,
  uptoTabletMd,
} from "../../styles/breakpointsAndMediaQueries.styles";
import appsCardImg from "./impactSections/images/tines-apps-self-service-portals-illustration.png";
import appsCardImg2x from "./impactSections/images/tines-apps-self-service-portals-illustration@2x.png";
import casesCardImg from "./impactSections/images/tines-cases-illustration-dark-mode.png";
import casesCardImg2x from "./impactSections/images/tines-cases-illustration-dark-mode@2x.png";
import governanceCardImg from "./impactSections/images/tines-features-illustration-version-history-monitoring-notifications-role-controls.png";
import governanceCardImg2x from "./impactSections/images/tines-features-illustration-version-history-monitoring-notifications-role-controls@2x.png";
import governanceCardImgSquare from "./impactSections/images/tines-features-illustration-version-history-monitoring-notifications-role-controls-square.png";
import governanceCardImgSquare2x from "./impactSections/images/tines-features-illustration-version-history-monitoring-notifications-role-controls-square@2x.png";
import { makeSrcSet2x } from "../../utils/srcset.utils";
import { GridCanvas } from "../decorations/GridCanvas";
import { HomeForTheWholeTeamIllustration } from "./impactSections/HomeForTheWholeTeamIllustration";
import ParallaxProductGrid from "../decorations/ParallaxProductGrid";
import HomeEnterpriseCardIllustration from "./impactSections/HomeEnterpriseCardIllustration";
import { ImpactSectionsHeadingWindowRange } from "./impactSections/ImpactSectionsHeadingWindowRange";
import { css } from "linaria";

const Header = styled.header`
  position: relative;
  ${uptoTabletLg} {
    max-width: 552px;
    margin-left: auto;
    margin-right: auto;
  }
  ${uptoTabletMd} {
    margin-top: 1em;
    padding-left: 1em;
  }
  ${fromDesktop} {
    padding-left: ${widthInGrid(1, 1)};
  }
  z-index: 1;
  h2 {
    font-size: 2.8rem;
    font-weight: 400;
    ${fromPhoneLg} {
      font-size: 3.8rem;
      font-weight: 300;
    }
    ${fromTablet} {
      font-size: 4.8rem;
      font-weight: 300;
    }
    ${fromTabletLg} {
      font-size: 5.8rem;
    }
    ${fromDesktopMd} {
      font-size: 6.4rem;
    }
    font-family: ${serif};
    letter-spacing: -0.02em;
    line-height: 1.2;
  }
`;

const WithBackground = styled.span`
  display: inline-block;
  background-color: ${colors.purple};
  margin-left: -0.25em;
  margin-right: -0.25em;
  padding-left: 0.25em;
  padding-right: 0.25em;
`;

const Contents = styled.div`
  position: relative;
  --unit: 4em;
  padding-top: var(--unit);
  ${fromTablet} {
    --unit: 5em;
    padding-bottom: calc(var(--unit) * 1.375);
  }
  ${fromDesktopLg} {
    --unit: 6.5em;
  }
  ${fromTabletMd} {
    &:before {
      content: "";
      border: 1px solid ${colors.purple400};
      border-radius: ${rSize("radius")};
      position: absolute;
      top: ${64 * 1.2 * -1 * 0.475}px;
      left: calc(
        ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
      );
      right: calc(
        ${rSize("pageMargin", -1, true)} + ${rSize("widerPageMargin", 1, true)}
      );
      bottom: 0;
    }
  }
  > * {
    + * {
      margin-top: var(--unit);
    }
  }
`;

const ContentSection = styled.div`
  position: relative;
  ${uptoTabletLg} {
    max-width: 552px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ContentSectionHeader = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2em;
  ${uptoTabletMd} {
    padding-left: 1em;
  }
  ${fromTabletMd} {
    margin-bottom: 3em;
  }
  ${fromDesktop} {
    padding-left: ${widthInGrid(1, 1)};
    padding-right: ${widthInGrid(1, 1)};
  }
  padding-right: 1em;
  ${fromDesktopMd} {
    padding-right: 2em;
  }
`;

const ContentSectionTextContent = styled.div`
  margin-right: 2em;
  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    ${fromTablet} {
      font-weight: 400;
      font-family: ${serif};
      font-size: 2.8rem;
    }
  }
  p {
    max-width: 22.5em;
    margin-top: 0.5em;
    font-size: 1.4rem;
    line-height: 1.2;
    ${fromTablet} {
      font-size: 1.6rem;
      line-height: 1.4;
    }
  }
`;

const ProtectedBuildingIconWrap = styled.div`
  margin-right: -18px;
`;

const ContentCardRow = styled.div`
  display: grid;
  ${uptoTabletLg} {
    max-width: 552px;
    margin-left: auto;
    margin-right: auto;
    grid-gap: ${rSize("sectionPadding")};
  }
  ${fromTabletLg} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${rSize("gap")};
  }
  ${mediaFromTo("desktop", "desktopMd")} {
    margin-left: ${gridGapPx(-2)};
    margin-right: ${gridGapPx(-2)};
  }
`;

const ContentCard = styled.figure`
  position: relative;
  margin: 0;
  min-height: 33.7rem;
  ${fromTabletLg} {
    min-height: 36rem;
  }
  background-color: ${colors.purple50};
  color: ${colors.purple800};
  border: 1px solid ${colors.purple600};
  border-radius: 1.2rem;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  > img {
    display: block;
    object-fit: cover;
    z-index: 1;
  }
  &.fromCenter {
    > img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.cover {
    > img {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
  &.fromLeft {
    > img {
      left: 0;
    }
  }
  &.dark {
    background-color: ${colors.purple600};
    color: ${colors.white};
    border: 1px solid ${colors.purple400};
  }
  &.darker {
    background-color: ${colors.purple700};
    color: ${colors.white};
    border: 1px solid ${colors.purple400};
  }
  figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: ${rSize("gap")};
    max-width: 22.5em;
    font-size: 1.4rem;
    line-height: 1.25;
    transform: translateY(0.25em);
    z-index: 3;
  }
  &.gradient {
    &:before {
      content: "";
      position: absolute;
      top: 33%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: radial-gradient(
        84.6% 74.29% at 18.12% 106.67%,
        ${colors.purple50} 0%,
        ${withOpacity(colors.purple50, 0)} 100%
      );
    }
    &.darker {
      &:before {
        background: radial-gradient(
          84.6% 74.29% at 18.12% 106.67%,
          ${colors.purple800} 0%,
          ${withOpacity(colors.purple800, 0)} 100%
        );
      }
    }
  }
  h4 {
    font-size: inherit;
    font-weight: 700;
    margin-bottom: 0.25em;
  }
  p {
    text-wrap: balance; // supported in Chrome but not in safari
  }
`;

const MobileImage = styled.img`
  ${uptoTablet} {
    width: 100%;
    top: 0;
    bottom: auto;
    object-position: top left;
  }
  ${fromTablet} {
    display: none !important;
  }
`;
const DesktopImage = styled.img`
  ${uptoTablet} {
    display: none !important;
  }
`;

const HomeImpactSections = () => {
  return (
    <PageSection>
      <Header>
        <h2>
          Stop the <ImpactSectionsHeadingWindowRange />
          <br />
          <WithBackground>start the important work.</WithBackground>
        </h2>
      </Header>
      <Contents>
        <ContentSection>
          <ContentSectionHeader>
            <ContentSectionTextContent>
              <h3>Connect anything</h3>
              <p>
                Introduce transparent, controlled collaboration across teams and
                systems.
              </p>
            </ContentSectionTextContent>
            <ConnectAnythingIcon />
          </ContentSectionHeader>
          <ContentCardRow>
            <ContentCard className="fromCenter">
              <ParallaxProductGrid
                className={css`
                  margin-top: -4px;
                `}
              />
              <figcaption>
                <h4>Integrator</h4>
                <p>
                  Integrate any internal or external technology through your
                  Tines workflows.
                </p>
              </figcaption>
            </ContentCard>
            <ContentCard className="dark cover fromLeft">
              <GridCanvas color={colors.purple500} parallax />
              <img
                src={appsCardImg}
                srcSet={makeSrcSet2x(appsCardImg, appsCardImg2x)}
                width="550"
                height="358"
              />
              <figcaption>
                <h4>Self-service portals</h4>
                <p>
                  Invite interactions at any point in your workflows with slick,
                  intuitive apps.
                </p>
              </figcaption>
            </ContentCard>
          </ContentCardRow>
        </ContentSection>

        <ContentSection>
          <ContentSectionHeader>
            <ContentSectionTextContent>
              <h3>Build workflows</h3>
              <p>
                Empower anyone on your team to tailor workflows to your
                requirements.
              </p>
            </ContentSectionTextContent>
            <LightningBoltIcon />
          </ContentSectionHeader>
          <ContentCardRow>
            <ContentCard className="darker cover fromLeft">
              <GridCanvas color={colors.purple600} parallax />
              <img
                src={casesCardImg}
                srcSet={makeSrcSet2x(casesCardImg, casesCardImg2x)}
                width="550"
                height="358"
              />
              <figcaption>
                <h4>Powerful</h4>
                <p>
                  Solve persistent problems and unlock the power of automation.
                </p>
              </figcaption>
            </ContentCard>
            <ContentCard>
              <HomeForTheWholeTeamIllustration />
              <figcaption>
                <h4>For the whole team</h4>
                <p>
                  Instantly legible with a low learning curve. Automate in
                  minutes, not months.
                </p>
              </figcaption>
            </ContentCard>
          </ContentCardRow>
        </ContentSection>

        <ContentSection>
          <ContentSectionHeader>
            <ContentSectionTextContent>
              <h3>Do it securely</h3>
              <p>
                Built by security professionals to empower all teams to work
                securely.
              </p>
            </ContentSectionTextContent>
            <ProtectedBuildingIconWrap>
              <ProtectedBuildingIcon />
            </ProtectedBuildingIconWrap>
          </ContentSectionHeader>
          <ContentCardRow>
            <ContentCard className="gradient">
              <HomeEnterpriseCardIllustration />
              <figcaption>
                <h4>Enterprise grade</h4>
                <p>
                  Transparency and compliance, without sacrificing speed and
                  scale.
                </p>
              </figcaption>
            </ContentCard>
            <ContentCard className="darker cover fromLeft gradient">
              <GridCanvas color={colors.purple600} parallax />
              <MobileImage
                src={governanceCardImgSquare}
                srcSet={makeSrcSet2x(
                  governanceCardImgSquare,
                  governanceCardImgSquare2x
                )}
                width="337"
                height="337"
              />
              <DesktopImage
                src={governanceCardImg}
                srcSet={makeSrcSet2x(governanceCardImg, governanceCardImg2x)}
                width="550"
                height="358"
              />
              <figcaption>
                <h4>Governance</h4>
                <p>
                  Take back control of tech sprawl with effective controls and
                  governance.
                </p>
              </figcaption>
            </ContentCard>
          </ContentCardRow>
        </ContentSection>
      </Contents>
    </PageSection>
  );
};

export default HomeImpactSections;
