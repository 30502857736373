export const AIIcon = {
  ai: () => (
    <svg
      className="Icon AIIcon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99993 3.2311L8.78751 6.26214C8.32674 7.41407 7.41399 8.32682 6.26206 8.78759L3.23102 10L6.26205 11.2124C7.41399 11.6732 8.32674 12.5859 8.78751 13.7379L9.99992 16.7689L11.2123 13.7379C11.6731 12.5859 12.5859 11.6732 13.7378 11.2124L16.7688 10L13.7378 8.78757C12.5859 8.3268 11.6731 7.41405 11.2123 6.26212L9.99993 3.2311ZM10.9284 2.32119C10.5931 1.48305 9.40671 1.48305 9.07145 2.32119L7.67334 5.81647C7.33454 6.66348 6.6634 7.33462 5.81639 7.67342L2.32112 9.07153C1.48297 9.40679 1.48297 10.5932 2.32111 10.9285L5.81639 12.3266C6.66339 12.6654 7.33454 13.3365 7.67334 14.1835L9.07145 17.6788C9.40671 18.517 10.5931 18.517 10.9284 17.6788L12.3265 14.1835C12.6653 13.3365 13.3365 12.6654 14.1835 12.3266L17.6787 10.9285C18.5169 10.5932 18.5169 9.40679 17.6787 9.07153L14.1835 7.6734C13.3365 7.3346 12.6653 6.66346 12.3265 5.81645L10.9284 2.32119Z"
      />
    </svg>
  ),
};
