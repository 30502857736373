import { styled } from "@linaria/react";
import { useRef } from "react";
import {
  ColorStep,
  color,
  colors,
  primaryColorMap,
} from "../../../styles/colors.styles";
import { onChartLibraryReady } from "../../../utils/charts.utils";
import { valueWithOptionalUnit } from "../../../utils/css.utils";
import { debounce } from "../../../utils/debounce.utils";
import { useOnMount } from "../../../utils/lifeCycle.utils";
import { defaultChartColorSeries } from "./reportV2defaultChartColors";

type Props = {
  header: [entryHeader: string, valueHeader: string];
  data: [label: string, value: number][];
  colors?: string[];
  donutPercentage?: number;
  height?: number;
  seoTitle?: string;
  maxWidth?: number;
  sortByValue?: boolean;
  legendPosition?: "bottom" | "right";
  chartArea?: google.visualization.PieChartOptions["chartArea"];
  slices?: google.visualization.PieChartOptions["slices"];
  textColor?: string;
  accentColor?: string;
};

export const DonutChartV2Container = styled.figure<{
  height?: number;
  maxWidth?: number;
  accentColor?: string;
}>`
  margin: 0;
  padding: 0;
  height: ${p => valueWithOptionalUnit(p.height, 220)};
  max-width: ${p => valueWithOptionalUnit(p.maxWidth, "100%")};
  text {
    font-weight: 500;
    cursor: default;
  }
  [fill="#0011cc"] {
    fill: ${p => p.accentColor ?? colors.purple};
  }
  /** move legends down a bit */
  defs[id*="ABSTRACT_RENDERER"] + g {
    transform: translateY(10px);
    ~ g {
      &:hover {
        path {
          fill-opacity: 0.85;
        }
      }
    }
  }
  /* path[stroke-width="0"] {
    fill-opacity: 0.5;
    stroke-width: 1;
    ${defaultChartColorSeries
    .map(
      def =>
        `&[fill="${def.hex}"] { 
            stroke: ${def.hex}; 
            + text { 
              fill: ${def.darker}; 
            } 
          }`
    )
    .join("")}
  } */
  path[stroke-width="0"] {
    ${[50, 100, 200]
      .map(colorStep =>
        Object.keys(primaryColorMap)
          .map(
            colorName => `&[fill="${color(
              colorName,
              colorStep as ColorStep
            )}"] { 
            + text { 
              fill: ${color(colorName, 800)}; 
            } 
          }`
          )
          .join("")
      )
      .join("")}
    ${[colors.light400]
      .map(
        hex => `&[fill="${hex}"] { 
            + text { 
              fill: ${colors.dark500}; 
            } 
          }`
      )
      .join("")}
  }
  .google-visualization-tooltip {
    pointer-events: none;
    path {
      filter: none;
      stroke: none;
      fill: ${colors.dark500};
    }
    text {
      fill: ${colors.white};
    }
  }
`;

const DonutChartV2 = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    const drawChart = () => {
      const data = google.visualization.arrayToDataTable([
        props.header,
        ...(props.sortByValue
          ? props.data.sort((a, b) => b[1] - a[1])
          : props.data),
      ]);
      const chart = new google.visualization.PieChart(ref.current!);
      const draw = () =>
        chart.draw(data, {
          pieSliceBorderColor: "transparent",
          colors: props.colors ?? defaultChartColorSeries.map(c => c.hex),
          pieHole: props.donutPercentage ?? 0.5,
          fontName: "Roobert, sans-serif",
          // title: props.seoTitle,
          chartArea:
            props.chartArea ??
            (props.legendPosition === "right"
              ? { top: 18, left: 0, right: 0, bottom: 18 }
              : {
                  top: 18,
                  left: 0,
                  right: 0,
                  bottom: 42,
                }),
          slices: props.slices,
          backgroundColor: "transparent",
          titleTextStyle: {
            color: "transparent",
          },
          legend: {
            position: props.legendPosition ?? "bottom",
            textStyle: {
              color: props.textColor,
            },
          },
          tooltip: {
            // trigger: "selection",
          },
        });
      const debounced = debounce(draw, { fireImmediately: true });
      window.addEventListener("resize", debounced);
      draw();
      return () => window.removeEventListener("resize", debounced);
    };
    return onChartLibraryReady(drawChart);
  });
  return (
    <DonutChartV2Container
      ref={ref}
      height={props.height}
      maxWidth={props.maxWidth}
      accentColor={props.accentColor}
    />
  );
};

export default DonutChartV2;
