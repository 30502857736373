export const FedAppsAndWorkloadsIcon = () => (
  <svg
    width="68"
    height="87"
    viewBox="0 0 68 87"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M11.8799 80.1997C17.755 80.1997 22.5176 79.4583 22.5176 78.5437C22.5176 77.6291 17.755 76.8877 11.8799 76.8877C6.00486 76.8877 1.24219 77.6291 1.24219 78.5437C1.24219 79.4583 6.00486 80.1997 11.8799 80.1997Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M32.7705 70.4764C38.6456 70.4764 43.4083 69.7349 43.4083 68.8202C43.4083 67.9056 38.6456 67.1641 32.7705 67.1641C26.8955 67.1641 22.1328 67.9056 22.1328 68.8202C22.1328 69.7349 26.8955 70.4764 32.7705 70.4764Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M30.9643 31.5479C26.1883 31.5479 22.3164 32.2893 22.3164 33.2038V44.2447V56.0192V68.9008C22.3164 69.8154 26.1881 70.557 30.9643 70.557C35.7404 70.557 39.6121 69.8154 39.6121 68.9008V56.0192V44.2447V33.2038C39.6121 32.2893 35.7404 31.5479 30.9643 31.5479Z"
      fill="#F0A848"
      stroke="#332B48"
    />
    <path
      d="M27.8965 45.5118C27.8965 44.6742 27.2176 43.9951 26.3798 43.9951C25.542 43.9951 24.8633 44.6742 24.8633 45.5118C24.8633 45.7778 24.8633 46.7121 24.8633 46.9784C24.8633 47.816 25.5422 48.4951 26.3798 48.4951C27.2174 48.4951 27.8965 47.816 27.8965 46.9784C27.8965 46.7123 27.8965 45.7778 27.8965 45.5118Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M27.8965 53.7782C27.8965 52.9406 27.2176 52.2617 26.3798 52.2617C25.542 52.2617 24.8633 52.9406 24.8633 53.7782C24.8633 54.0444 24.8633 54.9787 24.8633 55.2449C24.8633 56.0826 25.5422 56.7617 26.3798 56.7617C27.2174 56.7617 27.8965 56.0826 27.8965 55.2449C27.8965 54.9787 27.8965 54.0444 27.8965 53.7782Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M37.4475 53.7782C37.4475 52.9406 36.7684 52.2617 35.9308 52.2617C35.0932 52.2617 34.4141 52.9406 34.4141 53.7782C34.4141 54.0444 34.4141 54.9787 34.4141 55.2449C34.4141 56.0826 35.0932 56.7617 35.9308 56.7617C36.7684 56.7617 37.4475 56.0826 37.4475 55.2449C37.4475 54.9787 37.4475 54.0444 37.4475 53.7782Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M27.8965 61.9444C27.8965 61.1068 27.2176 60.4277 26.3798 60.4277C25.542 60.4277 24.8633 61.1068 24.8633 61.9444C24.8633 62.2106 24.8633 63.1451 24.8633 63.411C24.8633 64.2486 25.5422 64.9277 26.3798 64.9277C27.2174 64.9277 27.8965 64.2486 27.8965 63.411C27.8965 63.1451 27.8965 62.2106 27.8965 61.9444Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M4.24842 77.4205C3.53294 77.8737 2.58566 77.6608 2.13268 76.9453C1.67951 76.2302 1.89229 75.2829 2.60778 74.8299L25.5555 60.6394C26.271 60.1865 27.2181 60.399 27.6712 61.1145C28.1244 61.83 27.9116 62.7769 27.1965 63.2303L4.24842 77.4205Z"
      fill="#75A5E0"
      stroke="#332B48"
    />
    <path
      d="M4.24842 69.2212C3.53294 69.6744 2.58566 69.4618 2.13268 68.7463C1.67951 68.0308 1.89229 67.0835 2.60778 66.6305L25.5555 52.4403C26.271 51.9871 27.2181 52.2 27.6712 52.9153C28.1244 53.6306 27.9116 54.5779 27.1965 55.0309L4.24842 69.2212Z"
      fill="#75A5E0"
      stroke="#332B48"
    />
    <path
      d="M35.6926 55.2435C34.8599 55.0898 34.3095 54.29 34.4633 53.4574C34.617 52.6245 35.4166 52.0743 36.2494 52.2279L52.1781 55.2309C53.0108 55.3845 53.5612 56.1842 53.4076 57.0169C53.2539 57.8499 52.454 58.3999 51.6215 58.2466L35.6926 55.2435Z"
      fill="#75A5E0"
      stroke="#332B48"
    />
    <path
      d="M4.24842 61.0231C3.53294 61.4761 2.58566 61.2633 2.13268 60.5478C1.67951 59.8327 1.89229 58.8854 2.60778 58.4323L25.5555 44.242C26.271 43.789 27.2181 44.0016 27.6712 44.7171C28.1244 45.4326 27.9116 46.3795 27.1965 46.8326L4.24842 61.0231Z"
      fill="#75A5E0"
      stroke="#332B48"
    />
    <path
      d="M22.3164 36.8381C22.3164 37.7527 26.1881 38.4943 30.9643 38.4943C35.7404 38.4943 39.6121 37.7527 39.6121 36.8381V18.7849C39.6121 17.8705 35.7404 17.1289 30.9643 17.1289C26.1883 17.1289 22.3164 17.8705 22.3164 18.7849V36.8381Z"
      fill="#F47E3F"
      stroke="#332B48"
    />
    <path
      d="M30.9642 20.4409C35.7402 20.4409 39.6119 19.6995 39.6119 18.7849C39.6119 17.8703 35.7402 17.1289 30.9642 17.1289C26.1881 17.1289 22.3164 17.8703 22.3164 18.7849C22.3164 19.6995 26.1881 20.4409 30.9642 20.4409Z"
      fill="#FFC8A3"
      stroke="#332B48"
    />
    <path
      d="M56.0909 74.6209C61.9659 74.6209 66.7286 73.8794 66.7286 72.9648C66.7286 72.0501 61.9659 71.3086 56.0909 71.3086C50.2158 71.3086 45.4531 72.0501 45.4531 72.9648C45.4531 73.8794 50.2158 74.6209 56.0909 74.6209Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M40.7237 86.0278C46.5987 86.0278 51.3614 85.2864 51.3614 84.3718C51.3614 83.4572 46.5987 82.7158 40.7237 82.7158C34.8486 82.7158 30.0859 83.4572 30.0859 84.3718C30.0859 85.2864 34.8486 86.0278 40.7237 86.0278Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M54.2808 35.6914C49.5047 35.6914 45.6328 36.4329 45.6328 37.3474V48.3882V60.1628V73.0444C45.6328 73.9589 49.5047 74.7006 54.2808 74.7006C59.0568 74.7006 62.9285 73.9589 62.9285 73.0444V60.1628V48.3884V37.3476C62.9283 36.4328 59.0568 35.6914 54.2808 35.6914Z"
      fill="#E269A4"
      stroke="#332B48"
    />
    <path
      d="M45.6328 53.4935C45.6328 54.4082 49.5047 55.1495 54.2808 55.1495C59.0568 55.1495 62.9285 54.4082 62.9285 53.4935V37.2703C62.9285 36.3557 59.0568 35.6143 54.2808 35.6143C49.5047 35.6143 45.6328 36.3557 45.6328 37.2703V53.4935Z"
      fill="#25A871"
      stroke="#332B48"
    />
    <path
      d="M45.6328 40.9818C45.6328 41.8961 49.5047 42.6378 54.2808 42.6378C59.0568 42.6378 62.9285 41.8961 62.9285 40.9818V31.8476C62.9285 30.933 59.0568 30.1914 54.2808 30.1914C49.5047 30.1914 45.6328 30.933 45.6328 31.8476V40.9818Z"
      fill="#8D75E6"
      stroke="#332B48"
    />
    <path
      d="M54.2806 33.5034C59.0566 33.5034 62.9283 32.762 62.9283 31.8474C62.9283 30.9328 59.0566 30.1914 54.2806 30.1914C49.5045 30.1914 45.6328 30.9328 45.6328 31.8474C45.6328 32.762 49.5045 33.5034 54.2806 33.5034Z"
      fill="#C1AAF7"
      stroke="#332B48"
    />
    <path
      d="M29.4414 84.1236C29.4414 85.0384 33.3133 85.7798 38.0893 85.7798C42.8652 85.7798 46.7371 85.0384 46.7371 84.1236V79.1337C46.7371 78.219 42.8652 77.4775 38.0893 77.4775C33.3133 77.4775 29.4414 78.219 29.4414 79.1337V84.1236Z"
      fill="#4E8FD0"
      stroke="#332B48"
    />
    <path
      d="M38.0892 80.7895C42.8652 80.7895 46.7369 80.0481 46.7369 79.1335C46.7369 78.2189 42.8652 77.4775 38.0892 77.4775C33.3131 77.4775 29.4414 78.2189 29.4414 79.1335C29.4414 80.0481 33.3131 80.7895 38.0892 80.7895Z"
      fill="#BBD2F8"
      stroke="#332B48"
    />
    <path
      d="M1 78.5016C1 79.4164 4.87171 80.1578 9.64775 80.1578C14.424 80.1578 18.2957 79.4164 18.2957 78.5016V55.6863C18.2957 54.7717 14.424 54.0303 9.64775 54.0303C4.8719 54.0303 1 54.7717 1 55.6863V78.5016Z"
      fill="#25A871"
      stroke="#332B48"
    />
    <path
      d="M1 67.4616C1 68.376 4.87171 69.1176 9.64775 69.1176C14.424 69.1176 18.2957 68.376 18.2957 67.4616V44.6462C18.2957 43.7315 14.424 42.9902 9.64775 42.9902C4.8719 42.9902 1 43.7315 1 44.6462V67.4616Z"
      fill="#8D75E6"
      stroke="#332B48"
    />
    <path
      d="M1 48.6923C1 49.607 4.87171 50.3483 9.64775 50.3483C14.424 50.3483 18.2957 49.607 18.2957 48.6923V44.2204C18.2957 43.3059 14.424 42.5645 9.64775 42.5645C4.8719 42.5645 1 43.3059 1 44.2204V48.6923Z"
      fill="#E269A4"
      stroke="#332B48"
    />
    <path
      d="M9.64775 45.8764C14.4238 45.8764 18.2955 45.135 18.2955 44.2204C18.2955 43.3059 14.4238 42.5645 9.64775 42.5645C4.87172 42.5645 1 43.3059 1 44.2204C1 45.135 4.87172 45.8764 9.64775 45.8764Z"
      fill="#FAA3CA"
      stroke="#332B48"
    />
    <path
      d="M18.2539 48.368V52.1805L20.7493 50.3782V47.1895L18.2539 48.368Z"
      fill="#332B48"
    />
    <path
      d="M18.2539 56.4783V60.291L20.7493 58.4886V55.2998L18.2539 56.4783Z"
      fill="#332B48"
    />
    <path
      d="M18.2539 64.5889V68.4015L20.7493 66.5993V63.4102L18.2539 64.5889Z"
      fill="#332B48"
    />
    <path
      d="M43.5431 14.6735C43.5431 13.4671 37.9099 12.4893 30.9611 12.4893C24.0121 12.4893 18.3789 13.4672 18.3789 14.6735C18.3789 15.1655 18.3789 22.162 18.3789 22.6539C18.3789 23.8604 24.0121 24.8382 30.9611 24.8382C37.9099 24.8382 43.5431 23.8602 43.5431 22.6539C43.5431 22.162 43.5431 15.1655 43.5431 14.6735Z"
      fill="#E269A4"
      stroke="#332B48"
    />
    <path
      d="M30.9621 17.0528C37.1608 17.0528 42.1859 16.1806 42.1859 15.1045C42.1859 14.0285 37.1608 13.1562 30.9621 13.1562C24.7633 13.1562 19.7383 14.0285 19.7383 15.1045C19.7383 16.1806 24.7633 17.0528 30.9621 17.0528Z"
      fill="#FFBFDB"
      stroke="#332B48"
    />
    <path
      d="M30.9609 16.8578C37.9097 16.8578 43.5429 15.8799 43.5429 14.6735C43.5429 13.4672 37.9097 12.4893 30.9609 12.4893C24.0121 12.4893 18.3789 13.4672 18.3789 14.6735C18.3789 15.8799 24.0121 16.8578 30.9609 16.8578Z"
      fill="#FAA3CA"
      stroke="#332B48"
    />
    <path
      d="M24.0859 24.7332C24.0859 24.7332 28.4823 25.0878 31.8149 25.0169C35.1478 24.946 39.5441 24.3789 39.5441 24.3789L39.4732 26.3643C39.4732 26.3643 30.3258 26.506 24.0859 24.7332Z"
      fill="#332B48"
    />
    <path
      d="M11.502 44.0841C13.6497 44.0841 15.3907 43.804 15.3907 43.4586C15.3907 43.1131 13.6497 42.833 11.502 42.833C9.35432 42.833 7.61328 43.1131 7.61328 43.4586C7.61328 43.804 9.35432 44.0841 11.502 44.0841Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M32.3424 15.1027C37.707 15.1027 42.0559 14.6158 42.0559 14.0152C42.0559 13.4146 37.707 12.9277 32.3424 12.9277C26.9778 12.9277 22.6289 13.4146 22.6289 14.0152C22.6289 14.6158 26.9778 15.1027 32.3424 15.1027Z"
      fill="#332B48"
      stroke="#332B48"
    />
    <path
      d="M9.64725 43.9976C13.3573 43.9976 16.3648 40.9901 16.3648 37.2801C16.3648 33.5701 13.3573 30.5625 9.64725 30.5625C5.93724 30.5625 2.92969 33.5701 2.92969 37.2801C2.92969 40.9901 5.93724 43.9976 9.64725 43.9976Z"
      fill="#25A871"
      stroke="#332B48"
    />
    <path
      d="M22.3164 13.9611C22.3164 14.6201 26.1881 15.1541 30.9643 15.1541C35.7404 15.1541 39.6121 14.6201 39.6121 13.9611V2.19259C39.6121 1.53378 35.7404 1 30.9643 1C26.1883 1 22.3164 1.53396 22.3164 2.19259V13.9611Z"
      fill="#F47E3F"
      stroke="#332B48"
    />
    <path
      d="M30.9642 3.38555C35.7402 3.38555 39.6119 2.85153 39.6119 2.19277C39.6119 1.53402 35.7402 1 30.9642 1C26.1881 1 22.3164 1.53402 22.3164 2.19277C22.3164 2.85153 26.1881 3.38555 30.9642 3.38555Z"
      fill="#FEAF7F"
      stroke="#332B48"
    />
  </svg>
);
