export const LogoBEYONCyber = () => (
  <svg width="90" height="33" viewBox="0 0 90 33" fill="currentColor">
    <title>BEYON Cyber</title>
    <path d="M43.3139 28.9743C42.3083 28.9816 41.3194 28.7164 40.4523 28.207C39.5896 27.7071 38.8706 26.9928 38.3652 26.1333C37.8506 25.2664 37.5832 24.2752 37.592 23.2671C37.5846 22.26 37.8524 21.2699 38.3667 20.4039C38.8729 19.542 39.5914 18.8239 40.4537 18.3184C41.319 17.8035 42.3086 17.5351 43.3154 17.5422C44.4022 17.5293 45.4662 17.8537 46.3608 18.4709C47.2592 19.0865 47.9703 19.9385 48.4151 20.9327L46.1298 21.5874C45.8579 21.0741 45.4617 20.6372 44.9774 20.3165C44.4819 19.9974 43.9048 19.8282 43.3154 19.8292C42.7163 19.8206 42.1266 19.9786 41.6121 20.2854C41.1115 20.5857 40.7019 21.0162 40.4271 21.5311C40.1386 22.0639 39.9903 22.6613 39.996 23.2671C39.9895 23.8698 40.138 24.4639 40.4271 24.9927C40.7034 25.505 41.1127 25.9333 41.6121 26.2325C42.127 26.5383 42.7166 26.6957 43.3154 26.6873C43.8956 26.6991 44.4671 26.5449 44.9625 26.2429C45.4409 25.9387 45.8274 25.5102 46.0809 25.0031L48.3992 25.6267C47.9637 26.6166 47.2564 27.4626 46.3593 28.0662C45.4601 28.6714 44.3978 28.9881 43.3139 28.9743Z" />
    <path d="M52.4588 28.7979L48.9736 20.3727H51.435L53.6424 26.0798L55.7354 20.3727H58.1986L53.5135 32.2032H51.1154L52.4588 28.7979Z" />
    <path d="M63.8892 28.9739C63.4509 28.9789 63.0149 28.9057 62.602 28.7577C62.219 28.6177 61.8642 28.4094 61.5548 28.1429V28.8465H59.2368V17.6545H61.5548V21.1042C61.8618 20.829 62.2171 20.6126 62.602 20.4658C63.014 20.3119 63.4495 20.2307 63.8892 20.2259C64.6382 20.2072 65.3765 20.4041 66.0163 20.7932C66.652 21.18 67.1737 21.7291 67.5271 22.384C67.8974 23.0655 68.0868 23.8304 68.0784 24.6058C68.0849 25.367 67.9007 26.1177 67.5421 26.7892C67.1953 27.4436 66.6792 27.993 66.0473 28.3799C65.3985 28.7777 64.6504 28.9837 63.8892 28.9739ZM63.6613 26.881C64.0588 26.8832 64.4488 26.775 64.7882 26.5684C65.1309 26.3649 65.4145 26.076 65.6118 25.7301C65.8082 25.3896 65.9104 25.0032 65.9081 24.6103C65.9123 24.2148 65.8101 23.8255 65.6118 23.4831C65.4112 23.1396 65.1281 22.8515 64.7882 22.6447C64.4503 22.4339 64.0598 22.323 63.6613 22.3247C63.2629 22.3228 62.872 22.4337 62.534 22.6447C62.1909 22.8538 61.9054 23.145 61.7029 23.492C61.4985 23.829 61.3903 24.2159 61.3907 24.6103C61.3903 25.0051 61.4985 25.3924 61.7029 25.7301C61.9045 26.0754 62.1904 26.3639 62.534 26.5684C62.8734 26.7752 63.2638 26.8834 63.6613 26.881Z" />
    <path d="M73.3217 28.974C72.5464 28.9801 71.7842 28.7753 71.1162 28.3815C70.4557 27.9981 69.9092 27.446 69.5328 26.7818C69.1517 26.1208 68.9525 25.3704 68.9567 24.6074C68.9506 23.8321 69.1554 23.0698 69.5492 22.4018C69.9321 21.739 70.4834 21.1892 71.1471 20.808C71.8067 20.4221 72.5576 20.2206 73.3217 20.2244C73.9906 20.2213 74.6506 20.3772 75.2473 20.6792C75.8403 20.976 76.3592 21.4022 76.7656 21.9264C77.1725 22.448 77.4542 23.0561 77.5892 23.7038C77.727 24.2812 77.7321 24.8822 77.6051 25.462H71.2362C71.3112 25.7624 71.4593 26.0396 71.667 26.2693C71.8681 26.4873 72.1179 26.6543 72.3959 26.7566C72.6898 26.8654 73.0011 26.9196 73.3142 26.9166C73.6821 26.916 74.0468 26.8539 74.3942 26.7329C74.734 26.6209 75.0471 26.4387 75.3125 26.1982L77.3267 26.7729C76.9634 27.4663 76.3864 28.0241 75.6814 28.3637C74.9562 28.7562 74.1462 28.9657 73.3217 28.974ZM75.3523 23.7142C75.2637 23.4527 75.1254 23.2107 74.945 23.0017C74.7523 22.7793 74.5151 22.5997 74.2489 22.4744C73.9536 22.3371 73.6311 22.2687 73.3053 22.2745C72.9776 22.2695 72.6528 22.3378 72.3546 22.4744C72.0851 22.5978 71.8451 22.7777 71.6511 23.0017C71.4706 23.2107 71.3323 23.4527 71.2437 23.7142H75.3523Z" />
    <path d="M78.8213 20.3726H81.1397V21.5072C81.4158 21.0617 81.8236 20.7129 82.3069 20.5089C82.8174 20.2798 83.4465 20.2235 84.1936 20.3401V22.8182C83.7333 22.6401 83.2322 22.5934 82.7466 22.6833C82.3018 22.7577 81.8939 22.9769 81.5869 23.307C81.2883 23.6363 81.129 24.0684 81.1425 24.5126V28.8467H78.8246L78.8213 20.3726Z" />
    <path d="M37.9947 3.82173H43.2205C43.8644 3.80851 44.5025 3.94597 45.0838 4.22314C45.5867 4.4628 46.0126 4.83837 46.3133 5.30739C46.5967 5.76263 46.7442 6.28941 46.7384 6.82566C46.7418 7.32797 46.6174 7.82288 46.377 8.26392C46.1474 8.6955 45.8133 9.06263 45.4053 9.33188C45.9668 9.55922 46.4439 9.95513 46.7709 10.465C47.0653 10.9398 47.2195 11.4883 47.2153 12.0469C47.2228 12.5895 47.0728 13.1227 46.7828 13.5815C46.4767 14.0519 46.0452 14.4274 45.5371 14.6657C44.9353 14.9478 44.276 15.0858 43.6115 15.0686H37.9829L37.9947 3.82173ZM43.1346 8.49647C43.3083 8.50327 43.4816 8.47402 43.6435 8.4105C43.8053 8.34703 43.9523 8.2507 44.0752 8.12761C44.1925 8.01173 44.2851 7.87317 44.3472 7.72041C44.4093 7.56764 44.4397 7.40381 44.4366 7.23891C44.4397 7.074 44.4093 6.91017 44.3472 6.75741C44.2851 6.60459 44.1925 6.46608 44.0752 6.35016C43.9526 6.22659 43.8057 6.12984 43.6438 6.06609C43.4819 6.00234 43.3085 5.973 43.1346 5.97984H40.3202V8.48606L43.1346 8.49647ZM43.456 12.8986C43.6284 12.9058 43.8005 12.8783 43.9621 12.8178C44.1236 12.7572 44.2714 12.6648 44.3965 12.5461C44.5138 12.4344 44.6064 12.2996 44.6686 12.1501C44.7309 12.0007 44.7613 11.84 44.758 11.6781C44.7612 11.5152 44.7307 11.3534 44.6686 11.2028C44.6063 11.0521 44.5138 10.9159 44.3965 10.8027C44.2727 10.6815 44.1254 10.587 43.9636 10.5248C43.8019 10.4626 43.6291 10.4342 43.456 10.4413H40.3232V12.8986H43.456Z" />
    <path d="M48.3085 3.82178H56.4549V6.05546H50.7198V8.3528H55.8109V10.5376H50.7184V12.835H56.4535V15.0687H48.3071L48.3085 3.82178Z" />
    <path d="M61.0477 11.6145L57.2246 3.82178H59.7948L62.237 8.8831L64.6782 3.82178H67.2493L63.474 11.5493V15.0687H61.0477V11.6145Z" />
    <path d="M72.7434 15.1957C71.7332 15.2035 70.7395 14.9373 69.8685 14.4255C69.0009 13.9221 68.2781 13.2026 67.7709 12.337C67.2543 11.467 66.9848 10.4724 66.9918 9.46048C66.9853 8.44906 67.2543 7.45498 67.7709 6.5854C68.2795 5.71854 69.0018 4.99644 69.8685 4.48802C70.7376 3.97084 71.7318 3.7014 72.7434 3.7089C73.7554 3.7012 74.7501 3.97065 75.6201 4.48802C76.4873 4.99689 77.2087 5.72014 77.7159 6.5884C78.2334 7.45756 78.5034 8.45183 78.4964 9.46344C78.5039 10.4744 78.2371 11.4685 77.7248 12.34C77.2209 13.2081 76.4976 13.9284 75.6276 14.4285C74.7529 14.939 73.7564 15.2041 72.7434 15.1957ZM72.7434 12.9472C73.3467 12.9552 73.9401 12.7935 74.4557 12.4806C74.9615 12.1729 75.375 11.7343 75.6525 11.2113C75.9426 10.6735 76.0917 10.0713 76.0865 9.46048C76.0912 8.84965 75.9421 8.2475 75.6525 7.70965C75.3736 7.18564 74.9606 6.74501 74.4557 6.43287C73.942 6.11501 73.3476 5.95058 72.7434 5.95887C72.1429 5.95278 71.5528 6.11712 71.0418 6.43287C70.5323 6.74258 70.1161 7.18358 69.8357 7.70965C69.5465 8.2475 69.397 8.84965 69.4021 9.46048C69.397 10.0713 69.5461 10.6735 69.8357 11.2113C70.1128 11.7347 70.5267 12.1733 71.0329 12.4806C71.5481 12.7933 72.1411 12.955 72.7434 12.9472Z" />
    <path d="M79.6582 3.82129H83.5095L87.3016 12.7545L87.2852 3.82129H89.6477V15.0682H85.9048L82.0151 5.84758V15.0696H79.6582V3.82129Z" />
    <path d="M30.8644 23.9452C30.8644 25.9915 30.0515 27.9541 28.6046 29.401C27.1576 30.848 25.1951 31.6609 23.1488 31.6609C21.1024 31.6609 19.1399 30.848 17.693 29.401C16.246 27.9541 15.4331 25.9915 15.4331 23.9452C15.4331 21.8988 16.246 19.9361 17.6929 18.4889C19.1398 17.0417 21.1023 16.2285 23.1488 16.2281C25.1952 16.2285 27.1577 17.0417 28.6047 18.4889C30.0516 19.9361 30.8644 21.8988 30.8644 23.9452Z" />
    <path d="M30.8644 8.514C30.8644 10.5603 30.0515 12.5228 28.6046 13.9698C27.1576 15.4168 25.1951 16.2297 23.1488 16.2297C21.1024 16.2297 19.1399 15.4168 17.693 13.9698C16.246 12.5228 15.4331 10.5603 15.4331 8.514C15.4331 6.46758 16.246 4.5049 17.6929 3.0577C19.1398 1.61049 21.1023 0.797268 23.1488 0.796875C25.1952 0.797268 27.1577 1.61049 28.6047 3.0577C30.0516 4.5049 30.8644 6.46758 30.8644 8.514Z" />
    <path d="M15.4328 8.514C15.4324 6.46744 14.6192 4.50476 13.1721 3.0576C11.7249 1.61045 9.76228 0.797268 7.71567 0.796875C5.6692 0.797268 3.7067 1.61049 2.25978 3.0577C0.812855 4.5049 -3.76961e-08 6.46758 0 8.514V23.9468C0 25.9931 0.812902 27.9556 2.25987 29.4026C3.70683 30.8496 5.66934 31.6625 7.71567 31.6625C9.76214 31.6625 11.7248 30.8496 13.172 29.4027C14.6192 27.9558 15.4324 25.9933 15.4328 23.9468V8.514Z" />
  </svg>
);
