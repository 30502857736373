import { styled } from "@linaria/react";
import { DatoCmsPartner } from "../../../graphql-types";
import {
  fromDesktopLg,
  fromPhoneLg,
} from "../../styles/breakpointsAndMediaQueries.styles";
import { rSize } from "../../styles/responsiveSizes.styles";

type Props = {
  className?: string;
  partner: DatoCmsPartner;
};

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rSize("radius")};
  padding: 1em;
  aspect-ratio: 7 / 5;
  max-height: 8.5em;
  margin-left: auto;
  margin-right: auto;
  ${fromPhoneLg} {
    aspect-ratio: 2 / 1;
    padding: 1em 2em;
  }
  ${fromDesktopLg} {
    padding: 1em 2.5em;
  }
  img {
    display: block;
    width: auto;
    height: auto;
    &.wide {
      max-width: 90%;
      ${fromDesktopLg} {
        max-width: 82.5%;
      }
    }
    &.tall {
      max-height: 80%;
    }
    &.regular {
      max-width: 60%;
    }
  }
`;

const PartnerLogo = ({ partner, className }: Props) => {
  const logoRatio = (partner.logo?.width ?? 1) / (partner.logo?.height ?? 1);
  return (
    <LogoContainer className={className}>
      <img
        src={partner.logo?.url ?? ""}
        alt={`${partner.name} logo`}
        className={
          logoRatio > 1.75 ? "wide" : logoRatio < 0.75 ? "tall" : "regular"
        }
      />
    </LogoContainer>
  );
};

export default PartnerLogo;
