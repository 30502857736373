import { styled } from "@linaria/react";
import {
  DatoCmsPerson,
  DatoCmsTeamHeadshotsSection,
} from "../../../../graphql-types";
import PageSection from "../../reusableSections/PageSection";
import { SectionHeading2 } from "../../typography/SectionHeading2";
import fireworksIllo from "../decorations/fireworksIllo.svg";
import { PersonEntry } from "../../general/PersonEntry";
import { rSize } from "../../../styles/responsiveSizes.styles";
import { fromTabletMd } from "../../../styles/breakpointsAndMediaQueries.styles";

const Header = styled.header`
  display: grid;
  grid-gap: ${rSize("gap")};
  align-content: center;
  grid-template-areas: "image" "text";
  text-align: center;
  ${fromTabletMd} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas: "text text image";
    text-align: left;
  }
`;

const HeaderTextContent = styled.div`
  grid-area: text;
  h2 {
    + p {
      margin-top: 0.5em;
    }
  }
`;

const HeaderImage = styled.div`
  grid-area: image;
  text-align: center;
  ${fromTabletMd} {
    text-align: right;
  }
  img {
    margin-top: -10%;
    ${fromTabletMd} {
      margin-top: -20%;
    }
  }
`;

const HeadshotsGrid = styled.div`
  display: grid;
  grid-gap: ${rSize("gap")};
  margin-top: ${rSize("lg")};
  grid-template-columns: minmax(0, 1fr);
  ${fromTabletMd} {
    &[data-columns="2"] {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    &[data-columns="3"] {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
`;

export const LPTeamHeadshotsSection = ({
  section,
}: {
  section: DatoCmsTeamHeadshotsSection;
}) => {
  return (
    <PageSection id={section.anchorOnPage}>
      <Header>
        <HeaderTextContent>
          <SectionHeading2 lighter>{section.heading}</SectionHeading2>
          <p>{section.body}</p>
        </HeaderTextContent>
        <HeaderImage>
          <img src={fireworksIllo} />
        </HeaderImage>
      </Header>
      <HeadshotsGrid data-columns={(section.team?.length ?? 0) >= 3 ? 3 : 2}>
        {section.team?.map((person, i) => (
          <PersonEntry key={i} person={person as DatoCmsPerson} framed />
        ))}
      </HeadshotsGrid>
    </PageSection>
  );
};
