import Page from "./Page.template";
import { PageComponent } from "../types/gatsby.types";
import ogImage from "../../static/images/og/tines-get-certified.png";
import SEO from "../components/utilities/SEO";
import CertificationSignupForm from "../components/forms/CertificationSignupForm";
import { styled } from "@linaria/react";
import { colors } from "../styles/colors.styles";
import { fromTablet } from "../styles/breakpointsAndMediaQueries.styles";
import { useOnMount } from "../utils/lifeCycle.utils";
import axios from "axios";
import { getCookie } from "../utils/cookies.utils";
import HeroSection, {
  HeroSectionDescription,
} from "../components/sections/HeroSection";
import AllCaps from "../components/typography/AllCaps";
import PageSection from "../components/reusableSections/PageSection";
import { GridCanvas } from "../components/decorations/GridCanvas";
import Spacing from "../components/layout/Spacing";
import { TinesCertificationIcon } from "../components/hub/university/TinesCertificationIcon";

const BackdropWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40em;
  overflow: hidden;
  border-bottom: 1px solid ${colors.purple100};
`;

const IllustrationWrap = styled.div`
  margin-bottom: 1em;
  svg {
    width: 128px;
    height: 128px;
  }
  ${fromTablet} {
    display: none;
  }
`;

const HeroInner = styled.div`
  h1 {
    max-width: 10em;
    margin-left: auto;
    margin-right: auto;
    ${fromTablet} {
      line-height: 1.1;
    }
  }
`;

const webhook =
  "https://hq.tines.io/webhook/1ec0738b5e312b8d88f5a2ca7705e39c/8fe1df2d0f8a2ba29ea12bdb243e88cb";

const PageGetCertified: PageComponent = props => {
  const description =
    "Become immersed in the Tines product with hands-on labs to learn how to automate your most important workflows.";
  useOnMount(() => {
    (async () => {
      try {
        const email = getCookie("email_address");
        await axios.post(webhook, {
          email,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
  });
  return (
    <Page {...props} navThemeColor="purple" textColor={colors.purple800}>
      <SEO title="Get certified" description={description} image={ogImage} />

      <BackdropWrap>
        <GridCanvas color={colors.purple100} parallax />
      </BackdropWrap>

      <HeroSection contentRelative>
        <HeroInner>
          <IllustrationWrap>
            <TinesCertificationIcon />
          </IllustrationWrap>
          <AllCaps as="p">Get certified</AllCaps>
          <Spacing size="md" />
          <h1>Register for a Tines certification program</h1>
          <HeroSectionDescription centered>
            {description}
          </HeroSectionDescription>
        </HeroInner>
      </HeroSection>

      <Spacing size="xxl" multiplier={1.25} />

      <PageSection>
        <CertificationSignupForm webhookUrl="https://hq.tines.io/webhook/7eafbe99414c4530c8dd898af28fdd90/997f1909bbb48376ecfd68798b01677a" />
      </PageSection>

      <Spacing size="sectionMargin" />
    </Page>
  );
};

export default PageGetCertified;
