export const RocketIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    strokeMiterlimit="10"
    strokeLinejoin="round"
  >
    <path
      d="M75.8506 117.74C95.167 117.74 110.826 117.051 110.826 116.202C110.826 115.353 95.167 114.664 75.8506 114.664C56.5341 114.664 40.875 115.353 40.875 116.202C40.875 117.051 56.5341 117.74 75.8506 117.74Z"
      fill="#32313B"
      stroke="#32313B"
    />
    <path
      d="M98.9743 106.349C93.5611 99.6233 88.6398 102.084 79.7819 105.201C75.8672 106.578 68.709 103.355 68.709 95.1534C68.709 91.0232 70.3085 82.7422 70.3085 82.7422L64.1627 82.8812L58.017 82.7422C58.017 82.7422 59.6165 91.023 59.6165 95.1534C59.6165 103.355 52.4585 106.578 48.5438 105.201C39.6857 102.084 34.7646 99.6233 29.3512 106.349C27.7445 108.345 26.8906 112.254 29.1873 116.191C29.1873 117.041 44.8465 117.729 64.1627 117.729C83.4794 117.729 99.1382 117.041 99.1382 116.191C101.435 112.254 100.581 108.345 98.9743 106.349Z"
      fill="#DAF2E8"
      stroke="#32313B"
    />
    <path
      d="M69.6713 84.967C69.9016 83.6308 70.0727 82.7422 70.0727 82.7422L63.9271 82.8812L57.7812 82.7424C57.7812 82.7424 57.9526 83.6308 58.1829 84.9672C59.7109 85.3596 61.7217 85.5984 63.9269 85.5984C66.1318 85.5982 68.1431 85.3592 69.6713 84.967Z"
      fill="#32313B"
    />
    <path
      d="M75.4801 64.1289C75.4801 64.1289 78.3429 72.1449 86.7881 73.433L88.0766 96.0489H86.5021C86.5021 96.0489 79.0585 75.4371 73.0469 77.1545C73.0469 77.1545 75.0154 68.0292 75.4801 64.1289Z"
      fill="#B35980"
      stroke="#32313B"
    />
    <path
      d="M52.909 64.1289C52.909 64.1289 50.0461 72.1449 41.601 73.433L40.3125 96.0489H41.8875C41.8875 96.0489 49.3308 75.4371 55.3424 77.1545C55.3424 77.1545 53.3742 68.0292 52.909 64.1289Z"
      fill="#B35980"
      stroke="#32313B"
    />
    <path
      d="M73.4065 36.623H64.2095H64.114H54.9173C48.5966 54.0677 56.0895 82.7486 56.0895 82.7486C56.0895 83.3108 59.7036 83.7668 64.1615 83.7668C68.6198 83.7668 72.2338 83.3108 72.2338 82.7486C72.2341 82.7486 79.7267 54.0677 73.4065 36.623Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M64.2219 19.8145C64.216 19.8171 52.7031 24.9027 52.7031 44.9156H64.2219H75.741C75.741 24.9027 64.2278 19.8171 64.2219 19.8145Z"
      fill="#EB81AF"
      stroke="#32313B"
    />
    <path
      d="M77.3404 45.1334C77.3404 46.095 76.2323 46.8742 74.866 46.8742H53.9429C52.5766 46.8742 51.4688 46.095 51.4688 45.1334C51.4688 44.172 52.5766 43.3926 53.9429 43.3926H74.866C76.2323 43.3926 77.3404 44.172 77.3404 45.1334Z"
      fill="#81D8B4"
      stroke="#32313B"
    />
    <path
      d="M77.3404 48.6041C77.3404 49.5657 76.2323 50.3451 74.866 50.3451H53.9429C52.5766 50.3451 51.4688 49.5657 51.4688 48.6041C51.4688 47.6427 52.5766 46.8633 53.9429 46.8633H74.866C76.2323 46.8633 77.3404 47.6427 77.3404 48.6041Z"
      fill="#81D8B4"
      stroke="#32313B"
    />
    <path
      d="M64.2241 28.309C66.9602 28.309 69.5508 28.1053 71.8619 27.7417C68.5188 21.7122 64.2276 19.8162 64.2241 19.8145C64.2206 19.8162 59.9288 21.7124 56.5859 27.7417C58.8973 28.1053 61.488 28.309 64.2241 28.309Z"
      fill="#FDB2D2"
      stroke="#32313B"
    />
    <path
      d="M52.4922 50.1016H75.8543V52.086C75.8543 52.086 62.679 52.086 52.4922 50.1016Z"
      fill="#32313B"
    />
    <path
      d="M64.2236 38.3534C65.3006 38.3534 66.1737 37.4803 66.1737 36.4033C66.1737 35.3262 65.3006 34.4531 64.2236 34.4531C63.1465 34.4531 62.2734 35.3262 62.2734 36.4033C62.2734 37.4803 63.1465 38.3534 64.2236 38.3534Z"
      fill="#DAF2E8"
      stroke="#32313B"
    />
    <path
      d="M64.2236 58.9081C65.3006 58.9081 66.1737 58.035 66.1737 56.9579C66.1737 55.8809 65.3006 55.0078 64.2236 55.0078C63.1465 55.0078 62.2734 55.8809 62.2734 56.9579C62.2734 58.035 63.1465 58.9081 64.2236 58.9081Z"
      fill="#DAF2E8"
      stroke="#32313B"
    />
    <path
      d="M52.909 66.2852C52.909 66.2852 50.0461 74.3012 41.601 75.5892L40.3125 98.2054H41.8875C41.8875 98.2054 49.3308 77.5933 55.3424 79.3108C55.3424 79.3108 53.3742 70.1857 52.909 66.2852Z"
      fill="#FDB2D2"
      stroke="#32313B"
    />
    <path
      d="M75.4801 66.2852C75.4801 66.2852 78.3429 74.3012 86.7881 75.5892L88.0766 98.2054H86.5021C86.5021 98.2054 79.0585 77.5933 73.0469 79.3108C73.0469 79.3108 75.0154 70.1857 75.4801 66.2852Z"
      fill="#FDB2D2"
      stroke="#32313B"
    />
    <path d="M64.2109 19.8123V11.5938" stroke="#32313B" />
    <path
      d="M64.214 13.1019C65.0441 13.1019 65.7171 12.4289 65.7171 11.5988C65.7171 10.7687 65.0441 10.0957 64.214 10.0957C63.3839 10.0957 62.7109 10.7687 62.7109 11.5988C62.7109 12.4289 63.3839 13.1019 64.214 13.1019Z"
      fill="#32313B"
    />
    <path
      d="M72.2319 82.748C72.2319 82.748 72.8345 80.4404 73.5534 76.7383C70.807 77.0038 67.5946 77.1567 64.1595 77.1567C60.7249 77.1567 57.5124 77.0038 54.7656 76.7383C55.4849 80.4404 56.0874 82.748 56.0874 82.748C56.0874 83.3102 59.7014 83.7662 64.1593 83.7662C68.6178 83.7662 72.2319 83.3102 72.2319 82.748Z"
      fill="#81D8B4"
      stroke="#32313B"
    />
    <path d="M64.1641 83.7579V77.1484" stroke="#32313B" />
    <path d="M58.7109 77.0273L59.4954 83.7706" stroke="#32313B" />
    <path d="M69.6588 77.0273L68.875 83.7706" stroke="#32313B" />
    <path
      d="M65.5837 98.8339C65.5837 99.4267 64.9528 99.9075 64.1745 99.9075C63.3962 99.9075 62.7656 99.4267 62.7656 98.8339V65.554C62.7656 64.9614 63.3964 64.4805 64.1745 64.4805C64.9528 64.4805 65.5837 64.9612 65.5837 65.554V98.8339Z"
      fill="#B35980"
      stroke="#32313B"
    />
    <path
      d="M65.5837 98.8442C65.5837 99.437 64.9528 99.9177 64.1745 99.9177C63.3962 99.9177 62.7656 99.437 62.7656 98.8442V75.6558C62.7656 75.0627 63.3964 74.582 64.1745 74.582C64.9528 74.582 65.5837 75.0627 65.5837 75.6558V98.8442Z"
      fill="#FDB2D2"
      stroke="#32313B"
    />
  </svg>
);
