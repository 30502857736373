import { isDevelopment } from "../environment";
import { getCookie } from "./cookies.utils";
import { readMarketingAttribution } from "./marketingAttribution.utils";

export enum KnownDocumentName {
  "soc2022" = "Voice of the SOC Analyst (2022)",
  "mentalHealth2022" = "Mental health (2022)",
  "playbook2022" = "No-Code Security Automation Playbook (2022)",
  "soc2023" = "Voice of the SOC (2023)",
}

export enum DocumentIDMap {
  // cspell:disable
  "essentialGuideSecurity2023" = "BmS2_1GETbekpH-CLBNClA",
  "cisoPerspectiveAI2023" = "O7z1vaHORtaMyL-r3dj4sQ",
  // cspell:enable
}

export const recordDocumentEvent = async (params: {
  type: "access" | "view" | "download";
  id?: string;
  identifier?: string;
  form?: {
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    company: string;
  };
}) => {
  if (isDevelopment && params.type === "view") return;
  await fetch(
    "https://hq.tines.io/webhook/00a65fe35eaf1d56ad1d79aff6a5a9bd/db78885cbae8892775ee4adeb0511f73",
    {
      method: "post",
      body: JSON.stringify({
        ...params,
        email: getCookie("email_address"),
        marketingAttributions: readMarketingAttribution(),
      }),
    }
  );
};
