import { css } from "linaria";
import {
  fromTablet,
  onlyPhones,
} from "../../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../../../styles/colorsV4.styles";
import { font } from "../../../../styles/fonts.styles";
import { useOnMount } from "../../../../utils/lifeCycle.utils";
import NoWrap from "../../../typography/Nowrap";
import ReportCard from "../../ReportCard";
import PercentageBar from "../../charts/PercentageBar";

const MHChartDemographics = () => {
  useOnMount(() => {});
  return (
    <ReportCard
      className={css`
        ${onlyPhones} {
          padding-top: 2em;
          text-align: center;
        }
      `}
    >
      <p>
        <strong
          className={css`
            display: block;
            font-size: 48px;
            font-family: ${font("serif")};
            font-weight: 400;
            line-height: 1;
            ${fromTablet} {
              font-size: 64px;
            }
          `}
        >
          1,027
        </strong>
        <strong
          className={css`
            font-size: 18px;
            ${onlyPhones} {
              margin-bottom: 2em;
            }
          `}
        >
          members of security teams
        </strong>
      </p>
      <div
        className={css`
          h4 {
            margin-bottom: 0.25em;
            font-size: 1.4rem;
            color: inherit;
            text-align: left;
            + * {
              margin-top: 0.25em;
            }
          }
          > * + h4 {
            margin-top: 0.5em;
          }
        `}
      >
        <h4>Gender</h4>
        <PercentageBar
          data={[
            ["Male", 549, colorsV4.purple],
            ["Female", 478, colorsV4.orange],
          ]}
          labelMinHeight="2em"
        />
        <h4>Age</h4>
        <PercentageBar
          data={[
            [<NoWrap key="label">18–24</NoWrap>, 217, colorsV4.orange],
            [<NoWrap key="label">25–34</NoWrap>, 337, colorsV4.green],
            [<NoWrap key="label">35–44</NoWrap>, 302, colorsV4.purple],
            [<NoWrap key="label">45–54</NoWrap>, 101, colorsV4.yellow],
            [<NoWrap key="label">&gt; 54</NoWrap>, 69, colorsV4.pink],
          ]}
          textAlign="right"
          labelMinHeight="2em"
        />
        <h4>Country</h4>
        <PercentageBar
          data={[
            ["🇺🇸 United States", 506],
            ["Europe", 521, colorsV4.blue],
          ]}
          labelMinHeight="2em"
        />
      </div>
    </ReportCard>
  );
};

export default MHChartDemographics;
