import { css } from "linaria";
import { lighten } from "polished";
import { CSSProperties } from "react";
import { fromDesktop } from "../../../styles/breakpointsAndMediaQueries.styles";
import { colorsV4 } from "../../../styles/colorsV4.styles";
import LinkToAnchor from "../../basic/LinkToAnchor";
import UptoDesktop from "../../mediaQueries/UptoDesktop";
import { Serif } from "../../typography/Serif";
import ReportNav from "../ReportNav";

const SOCNav = () => {
  return (
    <ReportNav
      heading={
        <>
          <Serif>Voice of the</Serif>
          <span>SOC Analyst</span>
        </>
      }
    >
      <ul>
        <UptoDesktop>
          <li>
            <LinkToAnchor to="#" className="cover-link">
              <i></i>
              <span>Cover</span>
            </LinkToAnchor>
          </li>
        </UptoDesktop>
        <li>
          <LinkToAnchor to="#introduction">
            <i></i>
            <span>A word from Eoin Hinchy</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor to="#key-findings">
            <i></i>
            <span>Key findings</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor to="#methodology-and-participant-demographics">
            <i></i>
            <span>Methodology and participant demographics</span>
          </LinkToAnchor>
        </li>
      </ul>
      <hr />
      <ol>
        <li>
          <LinkToAnchor to="#job-satisfaction-and-workloads">
            <i></i>
            <span>Job satisfaction and workloads</span>
          </LinkToAnchor>
        </li>
        <li style={{ "--ThemeColorPrimary": colorsV4.green } as CSSProperties}>
          <LinkToAnchor to="#where-time-goes">
            <i></i>
            <span>Where time goes</span>
          </LinkToAnchor>
        </li>
        <li style={{ "--ThemeColorPrimary": colorsV4.orange } as CSSProperties}>
          <LinkToAnchor to="#what-prevents-good-work">
            <i></i>
            <span>What prevents good work</span>
          </LinkToAnchor>
        </li>
        <li style={{ "--ThemeColorPrimary": colorsV4.pink } as CSSProperties}>
          <LinkToAnchor to="#manual-work-and-automation">
            <i></i>
            <span>Manual work and automation</span>
          </LinkToAnchor>
        </li>
        <li
          style={
            {
              "--ThemeColorPrimary": lighten(0.1, colorsV4.warmBlack),
            } as CSSProperties
          }
        >
          <LinkToAnchor to="#improving-retention">
            <i></i>
            <span
              className={css`
                ${fromDesktop} {
                  --ThemeColorPrimary: ${colorsV4.purple};
                }
              `}
            >
              Improving retention
            </span>
          </LinkToAnchor>
        </li>
      </ol>
      <hr />
      <ul>
        <li>
          <LinkToAnchor to="#actionable-takeaways">
            <i></i>
            <span>Actionable takeaways for security team leaders</span>
          </LinkToAnchor>
        </li>
        <li>
          <LinkToAnchor to="#conclusion">
            <i></i>
            <span>Conclusion</span>
          </LinkToAnchor>
        </li>
      </ul>
    </ReportNav>
  );
};

export default SOCNav;
