import NoWrap from "../../typography/Nowrap";
import ReportIntroSection from "../ReportIntroSection";

const NoCodePlaybookIntroSection = () => {
  return (
    <ReportIntroSection id="a-word-from-eoin-hinchy">
      <p>
        In my 15 years as a security practitioner, both working on incident
        response and overseeing security teams, I saw a major problem: there's
        too much work and not enough staff. More specifically, I saw overworked
        staff doing repetitive, mundane tasks leading not only to burnout but to
        human error that could cost a company millions.
      </p>
      <p>
        What we needed was a way to get security teams away from those
        monotonous tasks and focus on projects that could add value to the
        company and put their skills to better use.
      </p>
      <p>
        The solution? No-code automation, which gives frontline security
        analysts the ability to automate processes like phishing attack
        responses, suspicious logins, and even employee onboarding and
        offboarding with a few drag-and-drop actions.
      </p>
      <p>
        While there are no silver bullets in cybersecurity, no-code automation
        has the potential to save teams days and weeks of work, free up security
        practitioners for <NoWrap>high-impact</NoWrap> projects, and improve
        total productivity.
      </p>
      <p>
        We wrote this guide to serve as the ultimate resource that will teach
        you everything you need to know about what no-code automation is, why it
        is a critical skill that those who are at the forefront of security
        operations will adopt, and how to bring the power of no-code automation
        to your security team.
      </p>
    </ReportIntroSection>
  );
};

export default NoCodePlaybookIntroSection;
